<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
      <div class="tabs" id="tabs">
        <div class="tabs__content">
          <div class="tabs__content__item active-tab">
            <div class="content">
              <div class="row row-sm topheader">
                <div class="col-lg-12">
                  <app-employeeheader></app-employeeheader>
                </div>
              </div>
              <div class="row row-sm">
                <div class="col-lg-12">
                  <div class="row row-sm topheader">
                    <div class="col-lg-4">
                      <h4>Add New Employee </h4>
                    </div>
                    <div class="col-lg-4">
                      &nbsp;
                    </div>
                  </div>
                  <form [formGroup]="employeeForm" #overviewForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(overviewForm.value,'custom-employee')">
                    <div class="row">
                      <div class="form-group col-lg-6">
                        <label>Select Job*</label>
                        <select style="width:100%;" name="nationalityid" formControlName="jobid" class="form-control input-lg"
                          (change)="onSelectjob($event.target.value)" id="jobid" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }"
                          ngModel="{{ overviewData.jobid }}">
                          <option value="">Select Job</option>
                          <option *ngFor="let ad3 of joblist" value="{{ ad3.jobmasterid}}" [selected]="ad3.jobid == overviewData.jobid">{{
                            ad3.jobtittle }}</option>
                        </select>
                        <div *ngIf="submitted && f.jobid.errors" class="invalid-feedback">
                          <div *ngIf="f.jobid.errors.required">Please select job</div>
                        </div>
                      </div>

                      <div class="form-group col-lg-6">
                        <label>Select Candidate</label>
                        <select style="width:100%;" name="candidateid" formControlName="candidateid" class="form-control input-lg"
                          id="candidateid" [ngClass]="{ 'is-invalid': submitted && f.candidateid.errors }" ngModel="{{ overviewData.candidateid }}">
                          <option value="">Select Candidate</option>
                          <option *ngFor="let ad3 of candidatelist" value="{{ ad3.candidatemasterid}}" [selected]="ad3.candidateid == overviewData.candidateid">
                            {{ ad3.fname }} {{ ad3.mname }} {{ ad3.lname }}</option>
                        </select>
                        <div *ngIf="submitted && f.candidateid.errors" class="invalid-feedback">
                          <div *ngIf="f.candidateid.errors.required">Please select candidate</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-lg-4">
                        <label>First Name*</label>
                        <input type="fname" formControlName="fname" class="" name="fname" id="fname" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" ngModel="{{ overviewData.fname }}"
                          value="{{ overviewData.fname }}">
                        <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                          <div *ngIf="f.fname.errors.required">Please enter first name</div>
                        </div>
                      </div>

                      <div class="form-group col-lg-4">
                        <label>Middle Name</label>
                        <input type="mname" formControlName="mname" class="" name="mname" id="mname" class="form-control"
                          ngModel="{{ overviewData.mname }}" value="{{ overviewData.mname }}">
                      </div>
                      <div class="form-group col-lg-4">
                        <label>Last Name*</label>
                        <input type="lname" formControlName="lname" class="" name="lname" id="lname" class="form-control input-lg"
                          [ngClass]="{ 'is-invalid': submitted && f.lname.errors }" ngModel="{{ overviewData.lname }}"
                          value="{{ overviewData.lname }}">
                        <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                          <div *ngIf="f.lname.errors.required">Please enter last name</div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-lg-6">
                        <label>Date of Birth*</label>
                        <mat-form-field class="datecontrol">
                          <input matInput [min]="mindob" [max]="maxdob" [matDatepicker]="picker2" id="dateofbirth"
                            [ngClass]="{ 'is-invalid': submitted && f.dateofbirth.errors }" name="dateofbirth"
                            formControlName="dateofbirth" class="form-control" ngModel='{{ overviewData.dateofbirth }}'
                            [value]="overviewData.dateofbirth">
                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                          <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="submitted && f.dateofbirth.errors" class="invalid-feedback">
                          <div *ngIf="f.dateofbirth.errors.required">Please choose date of birth</div>
                        </div>
                      </div>
                      <div class="form-group col-lg-6">
                        <label>NI Number*</label>
                        <input type="ninumber" formControlName="ninumber" class="" name="ninumber" id="ninumber" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.ninumber.errors }" ngModel="{{ overviewData.ninumber }}"
                          value="{{ overviewData.ninumber }}">
                        <div *ngIf="submitted && f.ninumber.errors" class="invalid-feedback">
                          <div *ngIf="f.ninumber.errors.required">Please enter the number</div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-lg-6">
                        <label>Email*</label>
                        <input type="emailid" (focus)="focusFunction()" formControlName="emailid" class="" name="emailid"
                          id="emailid" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }"
                          ngModel="{{ overviewData.emailid }}" value="{{ overviewData.emailid }}" />
                        <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                          <div *ngIf="f.emailid.errors.required">Please enter email </div>
                        </div>
                        <div class="invalid-feedback">
                          <div class="error" *ngIf="emailexist">This email is already registered</div>
                        </div>
                      </div>

                      <div class="form-group col-lg-6">
                        <label>Mobile Number*</label>
                        <international-phone-number formControlName="phonenumber" class="form-control phonecontrol"
                          [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" [(ngModel)]="overviewData.phonenumber"
                          placeholder="Enter mobile number" (input)="validatenumber($event.target.value,'phone')"
                          [maxlength]="20" [required]="true" #mobilenumber name="phonenumber"></international-phone-number>
                        <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                          <div *ngIf="f.phonenumber.errors.required">Please enter mobile number</div>
                        </div>
                        <span *ngIf="phonecheck==false" style="color:red">Invalid format</span>
                        <span *ngIf="phonecheck==true" style="color:green">Valid format</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-lg-6">
                        <label>
                          Gender </label>
                        <input type="radio" name="gender" formControlName="gender" [(ngModel)]='overviewData.gender'
                          value="1" [checked]="overviewData.gender === true" /> Male
                        <input type="radio" name="gender" formControlName="gender" [(ngModel)]='overviewData.gender'
                          value="0" [checked]="overviewData.gender === false" /> Female
                      </div>
                      <div class="form-group col-lg-6">
                        <label>Marital Status</label>
                        <input type="radio" name="maritalstatus" formControlName="maritalstatus" [(ngModel)]='overviewData.maritalstatus'
                          value="1" [checked]="overviewData.maritalstatus === true"> Married
                        <input type="radio" name="maritalstatus" formControlName="maritalstatus" [(ngModel)]='overviewData.maritalstatus'
                          value="0" [checked]="overviewData.maritalstatus === false"> Single
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-lg-6">
                        <label>Nationality* </label>
                        <select style="width:100%;" name="nationalityid" formControlName="nationalityid" class="form-control input-lg"
                          id="nationalityid" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" ngModel="{{ overviewData.nationalityid }}">
                          <option value="">Select Nationality</option>
                          <option *ngFor="let ad3 of countrylist" value="{{ ad3.countryid}}" [selected]="ad3.countryid == overviewData.nationalityid">{{
                            ad3.countryname }}</option>
                        </select>
                        <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                          <div *ngIf="f.nationalityid.errors.required">Please select nationality</div>
                        </div>
                      </div>

                      <div class="form-group col-lg-6">
                        <label>Country of Residence*</label>
                        <select style="width:100%;" name="residenceid" formControlName="residenceid" class="form-control input-lg"
                          id="residenceid" [ngClass]="{ 'is-invalid': submitted && f.residenceid.errors }" ngModel="{{ overviewData.residenceid }}">
                          <option value="">Select Country of Residence</option>
                          <option *ngFor="let ad3 of countrylist" value="{{ ad3.countryid}}" [selected]="ad3.countryid == overviewData.residenceid">{{
                            ad3.countryname }}</option>
                        </select>
                        <div *ngIf="submitted && f.residenceid.errors" class="invalid-feedback">
                          <div *ngIf="f.residenceid.errors.required">Please select country of residence</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-lg-6">
                        <label>Country of Birth*</label>
                        <select style="width:100%;" name="countryofbirthid" formControlName="countryofbirthid" class="form-control input-lg"
                          id="countryofbirthid" [ngClass]="{ 'is-invalid': submitted && f.countryofbirthid.errors }"
                          ngModel="{{ overviewData.countryofbirthid }}">
                          <option value="">Select Country of Birth</option>
                          <option *ngFor="let ad3 of countrylist" value="{{ ad3.countryid}}" [selected]="ad3.countryid == overviewData.countryofbirthid">{{
                            ad3.countryname }}</option>
                        </select>
                        <div *ngIf="submitted && f.countryofbirthid.errors" class="invalid-feedback">
                          <div *ngIf="f.countryofbirthid.errors.required">Please select country of birth</div>
                        </div>
                      </div>

                      <div class="form-group col-lg-6">
                        <label> Place of Birth*</label>
                        <input type="placeofbirth" formControlName="placeofbirth" class="" id="placeofbirth" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.placeofbirth.errors }" name="placeofbirth" ngModel='{{ overviewData.placeofbirth }}'
                          value='{{ overviewData.placeofbirth }}'>
                        <div *ngIf="submitted && f.placeofbirth.errors" class="invalid-feedback">
                          <div *ngIf="f.placeofbirth.errors.required">Please enter place of birth</div>
                        </div>
                      </div>
                    </div>
                    <div class="row row-sm">
                      <input type="submit" class="btn btn-primary" name="updateform" [disabled]="!disableBtn" value="Add New Employee" />
                      <input type="button" class="btn btn-primary" name="updateform" value="Cancel" (click)="closeModal('custom-employee');" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>