import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { error } from 'util';
import { User } from '../../models/user.model';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../../services/customer.service';
import { CompanyService } from '../../services/company.service';
import { AlertService } from '../../services/alert.service';
// import { User} from '../../shared/user.model';
// import { EmailValidator } from '@angular/forms';

@Component({
  selector: 'app-optcheck',
  templateUrl: './optcheck.component.html',
  styleUrls: ['./optcheck.component.css']
})

export class OptcheckComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  isLoginError = false;
  alertService: any;
  // loading: boolean;
  user = new User();
  access_token = '';
  userid = '';
  name = '';
  isline = '';
  planid = '';
  error = '';
  show: any;
  ctype: any;
  message:any;
  email: any;
  mobile: any;


  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private alerts: AlertService,
    private csservices: CustomerService,
    private company: CompanyService
  ) { }

  ngOnInit() {
   
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }
    else {
      this.userService.checkaccesstokenbyid(userid).subscribe((data: any) => {
        if (data.status == false) {
         
        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('Name');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('ctype');
        localStorage.removeItem('isemail');
        localStorage.removeItem('ismobile');
        localStorage.removeItem('isadmin');
        localStorage.removeItem('ispermission');
        localStorage.removeItem('logoname');
        localStorage.removeItem('struser');
        localStorage.removeItem('menu');
          this.router.navigate(['/login']);
        }     
      });
    }

    

    this.registerForm = this.formBuilder.group({
      otp: ['', Validators.required],
      otpmobile: ['', Validators.required]
    });


    this.show = false;
    var isemail = localStorage.getItem('isemail');
    var ismobile = localStorage.getItem('ismobile');      
    var struser = localStorage.getItem('struser');      
    this.ctype = localStorage.getItem('ctype');
    var planid =  localStorage.getItem('planid');      

     console.log('Email System',isemail);

    if (isemail=='true' && ismobile == 'true')
    {     
       this.router.navigate(['/choose-plan']);
    }
    else if (planid == '0' && this.ctype == '0' && isemail=='true' ) 
    {
      this.router.navigate(['choose-plan']);
    }
    else if (struser == '' && this.ctype == '1' && isemail=='true' ) 
    {
      this.router.navigate(['payment-confirmation']);
    }
    else if(this.ctype == '2' &&  (planid == '0' || planid === null) && isemail=='true')
    {
      this.router.navigate(['payment-confirmation']);
    }
    else if (struser == '' && this.ctype == '3' && isemail=='true') 
    {
      this.router.navigate(['payment-confirmation']);
    }
   

    
    this.company.getcompanydetails().subscribe((data: any) => {  
      this.email = data.emailid;
      this.mobile  = data.phonenumber;
    });


    
   
  }


  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    var otp = '';
    otp = localStorage.getItem('otp');
    if (this.user.otp != otp) {
      this.message ='Please enter correct OTP for Email';
      this.show = true;
      return;
    } 
    else 
    {    
      
       
        this.show = false;
      
    
    }

    var otpmobile = '';
    otpmobile = localStorage.getItem('otpmobile');
    if (this.user.otpmobile != otpmobile) {
      this.message ='Please enter correct OTP for Mobile';
      this.show = true;
      return;
    } else {
      this.show = false;
    }

    this.company.otpupdate().subscribe((data: any) => {  
      localStorage.setItem('isemail', "true");
    this.company.mobileotpupdate().subscribe((data: any) => {
        localStorage.setItem('ismobile', "true");

        if(this.ctype ==0 || this.ctype ==2)
          this.router.navigate(['/choose-plan']);    
          else
          this.router.navigate(['/payment-confirmation']);
      });
 });
  }

  resedotp() {
    this.submitted = false;
    this.show = false;
    this.company.resendotp().subscribe((data: any) => {
      localStorage.setItem('otp', data.otp);
      this.alerts.success("OTP resent successfully");
      this.show = false;
    },
      (err: HttpErrorResponse) => {
        //this.alerts.error("OTP resent successfully");
        this.isLoginError = true;
      });
  }

  resedmobileotp() {
    this.submitted = false;
    this.show = false;
    this.company.resendmobileotp().subscribe((data: any) => {
      localStorage.setItem('otpmobile', data.otp);
      this.alerts.success("OTP resent successfully");
      this.show = false;
    },
      (err: HttpErrorResponse) => {
        //this.alerts.error("OTP resent successfully");
        this.isLoginError = true;
      });
  }
  


  logout() {
    if (confirm("Are you sure you want to sign out?")) {
      this.userService.logout().subscribe((data: any) => {



      localStorage.removeItem('userToken');
      localStorage.removeItem('userid');
      localStorage.removeItem('Name');
      localStorage.removeItem('isline');
      localStorage.removeItem('planid');
      localStorage.removeItem('ctype');
      localStorage.removeItem('isemail');
      localStorage.removeItem('ismobile');
      localStorage.removeItem('isadmin');
      localStorage.removeItem('ispermission');
      localStorage.removeItem('logoname');
      localStorage.removeItem('struser');
      localStorage.removeItem('menu');



        this.router.navigate(['/login']);
      });;
    }
  }
}