import { Nationality } from '../visa/nationality.model';
import { Employee } from '../employee.model';

export class Idproof {
  employeeid: number;
  employeevisadeatilid: number;
  idnumber: string;
  idtype: number;
  docexpiry: Date = new Date();
  iddocName: string;
  idbackdocName: string;
  documentprofileimage:string;
  nationality: Nationality;
  stype: number;
  isaiverify:boolean;
  isaiverifydate: Date;
  iscurrent: boolean;
  employee: Employee;
}