import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { error } from 'util';
import { User } from '../../models/user.model';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../../services/customer.service';
import { CompanyService } from '../../services/company.service';
import { AlertService } from '../../services/alert.service';
// import { User} from '../../shared/user.model';
// import { EmailValidator } from '@angular/forms';

@Component({
  selector: 'app-updateemail',
  templateUrl: './updateemail.component.html',
  styleUrls: ['./updateemail.component.css']
})

export class UpdateemailComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  isLoginError = false;
  alertService: any;
  // loading: boolean;
  user = new User();
  access_token = '';
  userid = '';
  name = '';
  isline = '';
  planid = '';
  error = '';
  show: any;
  success: any;
  ctype:any;
  showsuccess: any;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private alerts: AlertService,
    private csservices: CustomerService,
    private company: CompanyService
  ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
    this.show = false;
    this.success = true;

    // var isemail = localStorage.getItem('isemail');
    // if ( isemail == "true" || isemail =="1") 
    // {
    //   localStorage.setItem('isemail', "true");
    //   this.router.navigate(['/choose-plan']);
    // }


    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }
    else {
      this.userService.checkaccesstokenbyid(userid).subscribe((data: any) => {
        if (data.status == false) {
         
        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('Name');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('ctype');
        localStorage.removeItem('isemail');
        localStorage.removeItem('ismobile');
        localStorage.removeItem('isadmin');
        localStorage.removeItem('ispermission');
        localStorage.removeItem('logoname');
        localStorage.removeItem('struser');
        localStorage.removeItem('menu');
          this.router.navigate(['/login']);
        }     
      });
    }



    var isemail = localStorage.getItem('isemail');
    var ismobile = localStorage.getItem('ismobile');      
    var struser = localStorage.getItem('struser');      
    this.ctype = localStorage.getItem('ctype');
    var planid =  localStorage.getItem('planid');      

    if (isemail=='true')
    {     
      if (this.ctype == '0' ) 
      {
        this.router.navigate(['dashboard']);
      }
      else if(this.ctype == '1' ) 
      {
        this.router.navigate(['check-dashboard']);
      }
      else if(this.ctype == '2' ) 
      {
        this.router.navigate(['check-dashboard']);
      }
      else if(this.ctype == '3' ) 
      {
        this.router.navigate(['reference-dashboard']);
      }   
    }






  
  }
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.company.emailupdate(this.user).subscribe((data: any) => {
      if (data.status == "success") {
        this.showsuccess = true;
        this.success = false;



        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('Name');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('ctype');
        localStorage.removeItem('isemail');
        localStorage.removeItem('ismobile');
        localStorage.removeItem('isadmin');
        localStorage.removeItem('ispermission');
        localStorage.removeItem('logoname');
        localStorage.removeItem('struser');
        localStorage.removeItem('menu');






      } else {
          this.showsuccess = false;
          this.success = true;

          this.alerts.error("This email already exists. Please try another email.");
         // this.show = true;
      }
    },
      (err: HttpErrorResponse) => {
        this.show = true;
      });
  }

  resedotp() {
    this.submitted = false;
    this.show = false;
    this.company.resendotp().subscribe((data: any) => {
      localStorage.setItem('otp', data.otp);
      this.show = false;
      this.router.navigate(['/otp']);
    },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
      });
  }
  
  logout() {
    if (confirm("Are you sure you want to sign out?")) {
      this.userService.logout().subscribe((data: any) => {
      
        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('Name');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('ctype');
        localStorage.removeItem('isemail');
        localStorage.removeItem('ismobile');
        localStorage.removeItem('isadmin');
        localStorage.removeItem('ispermission');
        localStorage.removeItem('logoname');
        localStorage.removeItem('struser');
        localStorage.removeItem('menu');
        this.router.navigate(['/login']);
      });
    }
  }
}