<app-header>
</app-header>
<div class="section-wrapper p-0">
    <div class="admin_dashboard_show">

                           
        <div class="ceee">
            <div class="cardmenu">
            
                <a class="card_box_lit" [routerLink]="['/personalcredit']"  >
                    <div class="samebox">
                        <i class="lnr lnr-users"></i>
                        <div class="menu_content">
                            <h4>Personal</h4>
                            <!-- <span>lorem ipsum lorem ipsum</span> -->
                        </div>
                    </div>
                   
                    
                </a>
             
             
                <a class="card_box_lit" [routerLink]="['/businesscredit']"  >
                    <div class="samebox">
                        <i class="lnr lnr-apartment"></i>
                        <div class="menu_content">
                            <h4>Business</h4>
                            <!-- <span>lorem ipsum lorem ipsum</span> -->
                        </div>
                    </div>
                   
                    
                </a>
             
             
                <a class="card_box_lit" [routerLink]="['/aml-log-list','0']" >
                    <div class="samebox">
                        <i class="lnr lnr-hourglass"></i>
                        <div class="menu_content">
                            <h4>Personal Log</h4>
                            <!-- <span>lorem ipsum lorem ipsum</span> -->
                        </div>
                    </div>
                   
                    
                </a>
              
              
                <a class="card_box_lit" [routerLink]="['/aml-log-list','1']" >
                    <div class="samebox">
                        <i class="lnr lnr-hourglass"></i>
                        <div class="menu_content">
                            <h4>Business Log</h4>
                            <!-- <span>lorem ipsum lorem ipsum</span> -->
                        </div>
                    </div>
                   
                    
                </a>
            
             
             
            </div>
          </div>

        <!-- <a [routerLink]="['/personalcredit']" class="homeset">
            <div class="dashboard-box">
                <h2>Personal</h2>
                <p class="dash-circle"><i class="fa fa-address-book-o"></i></p>
                <p class="dash-btm">
                    <i class="fa fa-circle"></i><i class="fa fa-circle"></i><i class="fa fa-circle"></i>
                </p>
            </div>
        </a>
        <a [routerLink]="['/businesscredit']" class="homeset">
            <div class="dashboard-box">
                <h2>Business </h2>
                <p class="dash-circle"><i class="fa fa-briefcase"></i></p>
                <p class="dash-btm">
                    <i class="fa fa-circle"></i><i class="fa fa-circle"></i><i class="fa fa-circle"></i>
                </p>
            </div>
        </a>
         <a [routerLink]="['/aml-log-list','0']" class="homeset">
            <div class="dashboard-box">
                <h2>Personal Log</h2>
                <p class="dash-circle"><i class="fa fa-address-book-o"></i></p>
                <p class="dash-btm">
                    <i class="fa fa-circle"></i><i class="fa fa-circle"></i><i class="fa fa-circle"></i>
                </p>
            </div>
        </a>
        <a [routerLink]="['/aml-log-list','1']" class="homeset">
            <div class="dashboard-box">
                <h2>Business Log</h2>
                <p class="dash-circle"><i class="fa fa-address-book-o"></i></p>
                <p class="dash-btm">
                    <i class="fa fa-circle"></i><i class="fa fa-circle"></i><i class="fa fa-circle"></i>
                </p>
            </div>
        </a>  -->
   
</div>
    <div class="container">
        <div class="tabs" id="tabs">
            <div class="tabs__sontent" *ngIf="tc==true">
                <div class="tab2__wcontent__item active-tab" id="jan">
                    <div class="content">
                        



                        
                    </div>
                </div>
            </div>


            <div class="tabs__sontent" *ngIf="tc==false">
                <div class="tab2__wcontent__item active-tab" id="jan">
                    <div class="content">
                        <div class="row row-sm">
                            <div class="col-lg-12" style="margin-top:10px;">
                                &nbsp;
                            </div>
                        </div>
                        <div class="row row-sm">
                            <div class="col-lg-12" style="margin-top:10px;">
                               <p>Somthing went wrong, please contact administrator</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    </div>
</div>