import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { WorklistService } from '../services/worklist.service';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, setYear, setMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { Activity } from '../models/activity/activity.model';
import { ModalService } from '../_services/modal.service';
import { CustomerService } from '../services/customer.service';
import { Router } from '@angular/router';
import { LeaveService } from '../services/leave.service';
import { DatePipe } from '@angular/common';
import { AppGlobals } from '../app.global';


@Component({
  selector: 'app-worklist',
  templateUrl: './worklist.component.html',
  styleUrls: ['./worklist.component.css']
})

export class WorklistComponent implements OnInit {
  constructor(
    private modal: NgbModal, 
    private customer:CustomerService,
    private router: Router, 
    private leaveService: LeaveService, 
    private data: WorklistService, 
    private formBuilder: FormBuilder, 
    private modalService: ModalService,
    private datePipe: DatePipe,
  ) { }
  Userid: any;
  event: CalendarEvent;
  activity: any;
  activityformGroup: FormGroup;
  events: CalendarEvent[] = [];
  hourtypelist: any[];
  projectlist: any[];
  locationlist: any[];
  headerdate: any;
  dayexpense: any[];
  classactive: string;
  year: number;
  submitted = false;
  disableBtn: any;
  leaveavailabledate = new Date();
  linemanager: any;
  public e: any;
  editMsg: any = "";
  yearList: any;
  findLeave: any = false;

  ngOnInit() {
    this.yearList = AppGlobals.YEAR_VALUES;
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }


    this.leaveavailabledate.setDate(this.leaveavailabledate.getDate());
    this.classactive = (new Date()).getMonth().toString();
    this.year = (new Date()).getFullYear();
    this.activityformGroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      hourtype: ['', Validators.required],
      projectid: ['', Validators.required],
      locationid: ['', Validators.required],
      title: [''],
      ahour: ['', Validators.compose([Validators.required, Validators.pattern(/^[.\d]+$/), Validators.max(24), Validators.min(1)])],
      start: [''],
      end: [''],
      hourremark: ['', Validators.required],
      hourremarkid: ['', Validators.required]
    });
    this.bindalldata();
  }

  bindalldata() {
    this.data.getemployeeworklist().subscribe((data: any) => {
      data.forEach(element => {
        this.events.push({
          id: element.employeehourapplyid,
          title: element.ahour + 'h ' + element.project.projectname,
          start: new Date(element.startdate),
          end: new Date(element.enddate),
          color: { primary: element.hcolor, secondary: element.fcolor },
          allDay: element.edit,
          draggable: true,
          resizable: {
            beforeStart: true,
            afterEnd: true
          }
        });
        this.refresh.next();
      })
    });
    this.Userid = localStorage.getItem('userid');
    const sstatustype = { 'status': true, 'id': this.Userid }
    this.data.gethourtypelist(sstatustype).subscribe((hourtypelist: any) => {
      this.hourtypelist = hourtypelist;
    });
    this.data.getprojectlist(sstatustype).subscribe((projectlist: any) => {
      this.projectlist = projectlist;
    });
    this.data.getlocationlist().subscribe((locationlist: any) => {
      this.locationlist = locationlist;
    });
  }

  @ViewChild('modalContent')
  modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = true;
  get f() {
    return this.activityformGroup.controls;
  }

  onSubmit(activity, id) {

    this.submitted = true;
    this.editMsg = "";
    if (this.activityformGroup.invalid) {
      return;
    }
    const sd = new Date(activity.start);
    activity.start = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    const ed = new Date(activity.end);
    activity.end = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
    // console.log(activity.start);
    this.disableBtn = false;
    
    
    var startdate = activity.start;
    var enddate = activity.end;

    activity.startdate = this.datePipe.transform(startdate, 'dd MMM yyyy'); 
    activity.enddate = this.datePipe.transform(enddate, 'dd MMM yyyy'); 


    console.log(activity);


    this.data.activityapplyupdate(activity).subscribe((data: any) => {
      this.events = [];
      document.getElementById('closeModal').click();
      this.bindalldata();
    });
  }
  
  openlinemanagerModal(id: string) 
  { 
    this.customer.getLineEmployeeDetails().subscribe((lsmanager: any) => {
      this.linemanager = lsmanager;
    });
    this.modalService.open(id);
  }

  onDelete(id: number) {
    const deleteData = { 'id': id };
    if (confirm("Are you sure you want to delete?")) {
      this.data.deleteactivity(deleteData).subscribe((data: any) => {
        this.events = [];
        document.getElementById('closeModal').click();
        this.bindalldata();
      });
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  formatDate(date) {
    var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }

  addMonthsOfYear(inc) {
    this.classactive = inc;
    var date = this.viewDate;
    date = setMonth(date, inc);

    

    this.viewDate = date;this.events = [];
    this.bindalldata();
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  yearpop(id: string) {
    this.modalService.open(id);
  }

  onyearSubmit(leave) {
    this.year = leave;
    var date = this.viewDate;
    date = setYear(date, this.year);
    this.viewDate = date;this.events = [];
    this.bindalldata();
  }

  addNewEvent(action: string, event: CalendarEvent): void {
    this.submitted = false;
    this.editMsg = "";
    this.disableBtn = true;
    this.activity = new Activity();
    this.Userid = localStorage.getItem('userid');
    this.activity.employeeid = this.Userid;
    this.activity.start = new Date();
    this.activity.end = new Date();
    this.activity.employeehourapplyid = '0';
    this.activity.hourremarkid = '0';
    // this.activity.projectid = '0';
    this.activity.loactionid = '0';
    this.headerdate = this.formatDate(new Date());
    this.modalData = { action, event };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(action: string, event: CalendarEvent, start: string): void {
    this.findLeave = false;
    this.submitted = false;
    this.editMsg = "";
    this.Userid = localStorage.getItem('userid');
    const databydate = { 'id': this.Userid, 'exid': this.datePipe.transform(new Date(start),"dd MMM yyyy") };
    this.leaveService.checkleaveondate(databydate).subscribe((userdata: any) => {
      if(userdata && userdata.length)
      {
        this.findLeave = true;
      }
    });
    this.disableBtn = true;
    this.activity = new Activity();
    this.Userid = localStorage.getItem('userid');
    this.activity.employeeid = this.Userid;
    this.activity.start = new Date(start);
    this.activity.end = new Date(start);
    this.headerdate = this.formatDate(new Date(start));
    this.activity.employeehourapplyid = '0';
    this.activity.hourremarkid = '0';
    //this.activity.projectid = '0';
    this.activity.loactionid = '0';
    this.modalData = { action, event };
    this.modal.open(this.modalContent, { size: 'lg' });
  }


  editPopUpEvent(action: string, id: number, event: CalendarEvent) {
    this.submitted = false;
    this.editMsg = "";
    this.activity = new Activity();
    this.disableBtn = true;
    this.Userid = localStorage.getItem('userid');
    this.activity.employeeid = this.Userid;
    var employeehourapplyid = id;
    this.data.getactivitydata({ 'id': employeehourapplyid }).subscribe((data: any) => {
      this.activity.ahour = data.ahour;
      this.activity.start = new Date(data.startdate);
      this.headerdate = this.formatDate(new Date(data.startdate));
      this.activity.end = new Date(data.enddate);
      this.activity.projectid = data.projectid;
      this.activity.locationid = data.locationid;
      this.activity.employeehourapplyid = data.employeehourapplyid;
      this.activity.hourremarkid = data.hourremarksid;
      this.activity.hourremark = data.remark.hourremark;
      this.activity.hourtype = data.hourtypeid;
      this.activity.hstatus = data.hstatus;
    });
    this.modalData = { action, event };
    this.modal.open(this.modalContent, { size: 'lg' });
    this.modalService.close('event-viewer');
  }

  editEvent(action: string, event: CalendarEvent) {
    this.submitted = false;
    this.findLeave = false;
    this.editMsg = "";
    this.disableBtn = true;
    this.activity = new Activity();
    this.Userid = localStorage.getItem('userid');
    this.activity.employeeid = this.Userid;
    var employeehourapplyid = event.id;
    this.data.getactivitydata({ 'id': employeehourapplyid }).subscribe((data: any) => {
      this.activity.ahour = data.ahour;
      this.activity.start = new Date(data.startdate);
      this.headerdate = this.formatDate(new Date(data.startdate));
      this.activity.end = new Date(data.enddate);
      this.activity.projectid = data.projectid;
      this.activity.locationid = data.locationid;
      this.activity.employeehourapplyid = data.employeehourapplyid;
      this.activity.hourremarkid = data.hourremarksid;
      this.activity.hourremark = data.remark.hourremark;
      this.activity.hourtype = data.hourtypeid;
      this.activity.hstatus = data.hstatus;
      if(data.hstatus != 1)
      {
        this.disableBtn = false;
        this.editMsg = "You can not edit this expense";
      }
      const databydate = { 'id': this.Userid, 'exid': this.datePipe.transform(new Date(data.startdate),"dd MMM yyyy") };
      this.leaveService.checkleaveondate(databydate).subscribe((userdata: any) => {
        if(userdata.length)
        {
          this.findLeave = true;
        }
      });
    });
    this.modalData = { action, event };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  showAllEvents(id: string, exid: number) {
    this.Userid = localStorage.getItem('userid');
    const databydate = { 'id': this.Userid, 'exid': exid };
    this.data.getactivedatabydate(databydate).subscribe((userdata: any) => {
        this.dayexpense = userdata;
    });
    this.modalService.open(id);
  }

  closeModalWork(id: string) {
    this.modalService.close(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
}