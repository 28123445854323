<div class="signin-wrapper" *ngIf="loginform">
    <div class="new_login">
        <div class="login_section">
            <a class="login_logo" [routerLink]="['/']"><img src="/assets/img/logo.png" alt=""></a>
            <div class="signin-box">
                <form [formGroup]="registerForm" #loginForm (ngSubmit)="onSubmit()" class="login_form">
                    <div class="mg-b-30">
                        <div class="input-group form_rel">
                            <input type="text" formControlName="username" #Username [(ngModel)]="user.username"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                placeholder="Username">
                            <!-- <label for="name">Your Name</label> -->
                            <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-user"
                                        aria-hidden="true"></i></span> </div>
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">Please enter username</div>
                                <!-- <div *ngIf="f.username.errors.email">Please enter valid email</div> -->
                            </div>

                        </div>
                        <a class="viewdetails otp float-right" [routerLink]="['/forgot-username']"> Forgot Username</a>
                    </div>
                    <div class="form-group mb-1">
                        <div class="input-group">
                            <input type="password" formControlName="password" #Password [(ngModel)]="user.password"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                placeholder="Password">
                            <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-lock"
                                        aria-hidden="true"></i></span> </div>
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Please enter password</div>
                                <div *ngIf="f.password.errors.minlength">Password must be at least 8 characters long
                                </div>
                                <!-- <div *ngIf="f.password.errors.pattern">password must contain an  Lowercase, Uppercase letter, Numbers, Special characters</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="form-group dflexs">
                        <label class="label_container">Remember Me
                            <input type="checkbox" name="browser[]" value="1" data-parsley-mincheck="2"
                                data-parsley-class-handler="#cbWrapper"
                                data-parsley-errors-container="#cbErrorContainer" required=""
                                data-parsley-multiple="browser" class="remcheck">
                            <span class="checkmark"></span>
                        </label>
                        <div class="forget">
                            <a [routerLink]="['/forgotRequest']" class="fortgot">Forgot Password</a>
                        </div>
                    </div>

                    <button class="btn btn-primary btn-block btn-signin mb-1" style="width:100%"
                        type="submit">Login</button>

                    <p class="mg-b-0 usefulllink text-center"><a [routerLink]="['/joinnow']" class="">Register
                            Now</a></p>
                    <div class="button_grrr">
                        <a  class="ssotext" (click)="ssoclick()">Use single sign-on (SSO) instead</a>
                    </div>
                </form>
            </div>
        </div>
        <div class="graphic_section">
            <div class="slogan">
                <h4>Welcome To <b>Complygate</b></h4>
                <span>Commit. Control. Comply</span>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</div>
<div class="signin-wrapper" *ngIf="ssoform">
    <div class="new_login">
        <div class="login_section">
            <a class="login_logo" [routerLink]="['/']"><img src="/assets/img/logo.png" alt=""></a>
            <div class="signin-box">
                <form [formGroup]="loginSSOFormGroup" novalidate (ngSubmit)="loginssoUser()" class="login_form">
                    <div class="form-group mg-b-30">
                        <div class="input-group form_rel">
                            <input type="text" formControlName="email" #email class="form-control" placeholder="Email">
                            <!-- <label for="name">Your Name</label> -->
                            <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-user"
                                        aria-hidden="true"></i></span> </div>


                            <div *ngIf="submitted && k.email.errors" class="invalid-feedback">
                                <div *ngIf="k.email.errors.required">Please enter Email Id</div>
                                <div *ngIf="k.email.errors.email">Please enter valid email</div>
                            </div>


                            <!-- <div *ngIf="submit && k.email.errors || k.email.errors.required">
                                <span class="invalid-feedback d-block">Please enter Email Id</span>
                            </div> -->

                        </div>

                    </div>
                    <button class="btn btn-primary btn-block btn-signin" style="width:100%" type="submit">Login</button>
                    <div class="button_grrr">
                        <a routerLink="" class="ssotext" (click)="loginclick()">Back to Login</a>
                    </div>
                </form>
            </div>
        </div>
        <div class="graphic_section">
            <div class="slogan">
                <h4>Welcome To <b>Complygate</b></h4>
                <span>Commit. Control. Comply</span>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</div>