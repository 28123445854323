import { Component, OnInit, Input, Output,EventEmitter,SimpleChanges } from '@angular/core';
import { CountryService } from '../../../services/country.service';
import { MyidproofService } from '../../../services/myidproof.service';
import { ModalService } from '../../../_services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Idproof } from '../../../models/idproof/idproof.model';
import { UploadFileService } from './../../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertService } from '../../../services/alert.service';
import { AicenterService } from '../../../services/aicenter.service';

import { EmployeeService } from '../../../services/employee.service';

@Component({
  selector: 'app-aiidproof',
  templateUrl: './aiidproof.component.html',
  styleUrls: ['./aiidproof.component.css']
})
export class AiidproofComponent implements OnInit {

  
  
  employeelist: any;  
  @Input() items: Array<any>;
  defaultVal:any='';
  pageOfItems: Array<any>;
  record: any;
  idprooflist: any[];
  idproofDetail: any;
  countrylist: any;
  Userid: any;
  listcheck: any;
  selectedFiles: FileList;
  iframesrc: any;
  imgsrc: any;
  buttoninsert: any;
  filesizeerror: any;
  fileformaterror: any;
  file: any;
  filename: any;
  data: any;
  empid:any;
  disableBtn: any;
  documenttype:any;
  aipayment: boolean =false;

  filterItems = [
    {
      name: 'All idproof Details',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Waiting for review',
      id: 1,
      selected: false
    }
  ]
  submitted: any;
  idproofpopForm: FormGroup;
  idproof: Idproof;
  permission: any;
  requestperm: any;
  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private idproofService: MyidproofService, 
    private country: CountryService,  
    private aicenter:AicenterService,
    private modalService: ModalService, 
    private router: Router,
    private employee: EmployeeService,
    private alerts: AlertService
    ) { }
  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;

    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');

    this.idproofService.iddocumenttype().subscribe((documenttype: any) => {
      this.documenttype = documenttype;
    });
    
    var value3 = "{}";
    this.employee.getemployeelist(value3).subscribe((listdetails: any) => {
      this.employeelist = listdetails;
    });

    this.aicenter.getcountpayment().subscribe((data: any) => {     
      this.aipayment = data.payment;
    });


    this.loadalldata();

    


    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
  }

  
  loadalldata(){
    var data;
    if(this.empid !=''){
       data = { 'id': this.Userid, 'cstatus': [1, 2, 3], employeeid: this.empid };
    }
    else {
      data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    }
  
    this.idproofService.getallidproofListbyadmin(data).subscribe((data: any) => {     
      this.record  = data.length;

      if(this.record < 30){
        this.pageOfItems = data;
      }
      else{
           this.items =data;
      }

    });
  }
  changeEmployee(id: any) {
    this.empid = id;
    this.loadalldata();
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}



  formsubmit()
  {
    // document.getElementById('idproof-form-submit').submit();
  }




  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModal3(id: string, data: any) 
  {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.idproof = new Idproof();
    this.disableBtn = true;
    this.idproofDetail = new Idproof();
    this.idproofDetail = data;

   // console.log(data);
    this.buttoninsert = "Update";
    this.modalService.open(id);
  }

  closeModal(id: string) 
  {
    
    this.modalService.close(id);
  }


}
