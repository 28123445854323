import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ModalService } from '../../../_services/modal.service';
import { JobService } from '../../../services/job.service';
import { CandidateService } from '../../../services/candidate.service';
import { CommonService } from '../../../services/common.service';
import { ApplicantService } from '../../../services/applicant.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Jobs } from '../../../models/jobs/jobs.model';
import { Candidate, Document } from '../../../models/jobs/candidate.model';
import { Emailer } from '../../../models/emailer.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadFileService } from './../../../services/upload-file.service';
import { AlertService } from '../../../services/alert.service';
import { DomSanitizer } from '@angular/platform-browser';
import { State } from 'src/app/models/address/state.model';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.css']
})
export class CandidatesComponent implements OnInit {
  jobid: any;
  Userid: any;
  data: any;
  statusid: any =0; 

  datalist:any;
  items: Array<any>;
  
  pageOfItems: Array<any>;


  jobDetail = new Jobs();
  emailerDetail = new Emailer();
  candidateDetail = new Candidate();
  documentDetail = new Document();
  candidateformGroup: FormGroup;
  candidatemessageFormGroup: FormGroup;
  candidateintmessageformGroup: FormGroup;
  public Editor = ClassicEditor;
  jobdata = new Jobs();
  filename: any;
  selectedFiles: FileList;
  submitted: boolean;
  file: any;
  filesizeerror: any;
  fileformaterror: any;
  skilllist: any;
  tempArr: any = { "skills": [] };
 
  allskill: any;
  iframesrc: any;
  imgsrc: any;
  public searchText: string;
  ismail: any;
  candidatestatuslist: any;
  phonecheck: any;
  disableBtn: any;buttoninsert: any;
  currenDate = new Date();
  defaultVal: any = "";
  record: any;  

  constructor(
    private sanitizer: DomSanitizer,
    private alerts: AlertService,
    private uploadService: UploadFileService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private job: JobService,
    private router: Router,
    private applicantservices: ApplicantService,
    private candidate: CandidateService,
    private formBuilder: FormBuilder,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    this.jobid = this.activatedRoute.snapshot.paramMap.get("jobid");
    this.statusid = this.activatedRoute.snapshot.paramMap.get("statusid");
    
    this.ismail = false;
    this.getcandidateformgroup();

    this.candidatemessageFormGroup = this.formBuilder.group({
      candidateid: [''],
      jobid: [''],
      location: ['', Validators.required],     
      subject: ['', Validators.required],
      message: [''] ,
      interviewstime: ['', Validators.required],
      interviewetime: ['', Validators.required],
      interdate: ['', Validators.required],
      ismail: [''],
      ssid: ['']
    });

    this.emailerDetail = new Emailer();

    this.applicantservices.getcandidatestatus().subscribe((candidatestatuslistget: any) => {
      this.candidatestatuslist = candidatestatuslistget;

      console.log(candidatestatuslistget);
    });

    this.Userid = localStorage.getItem('userid');
    this.job.getjobById({ "id": this.Userid, "jobid": this.jobid }).subscribe((jobdata: any) => {
      this.jobdata = jobdata;
    });
    this.candidate.getcandidateList({ "id": this.Userid, "jobid": this.jobid,"cstatus": this.statusid }).subscribe((data: any) => {
      this.record = data.length;
      this.pageOfItems = data;
      this.datalist =data;
      // console.log(data);
      if(this.record < 30){
        this.pageOfItems = data;
      }
      else{
           this.items =data;
      }

      // this.data = data;
    });
    this.job.getjobskillList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
      var dataskillArray = [];
      data.forEach(skil => {
        dataskillArray.push({ 'jobskilldetailid': skil.jobskilldetailid, 'skillname': skil.skillname, 'check': false });
      })
      this.skilllist = dataskillArray;
    });
    
    this.filename ='Choose file';
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}

selectjoblistbystatus(sid:any){

  this.statusid = sid;
  // console.log(sid);
  this.candidate.getcandidateList({ "id": this.Userid, "jobid": this.jobid,"cstatus": this.statusid }).subscribe((data: any) => {
    this.record = data.length;
    this.pageOfItems = data;
    this.datalist =data;
    if(this.record < 30){
      this.pageOfItems = data;
    }
    else{
         this.items =data;
    }

    // this.data = data;
  });

}


  getcandidateformgroup()
  {
    
    this.candidateformGroup = this.formBuilder.group({
      candidatemasterid: [''],
      jobid: [''],
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: [''],
      joburlid: ['']
    });
  }

  get f() { return this.candidateformGroup.controls; }

  get g() { return this.candidatemessageFormGroup.controls; }

  onSubmit(value3: any, id: string) {
    value3.userid = this.Userid;
    value3.skills = this.tempArr;
    this.submitted = true;
    
   
    if (this.candidateformGroup.invalid) {
      return;
    }
    // console.log(value3);
    value3.smarks = 100 * (Number(this.tempArr.skills.length) / Number(this.skilllist.length));
    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');

     
      // console.log(this.file);
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") && (filetype[filetype.length - 1].toLowerCase() != "gif") && (filetype[filetype.length - 1].toLowerCase() != "tif") && (filetype[filetype.length - 1].toLowerCase() != "pdf") && (filetype[filetype.length - 1].toLowerCase() != "doc") && (filetype[filetype.length - 1].toLowerCase() != "docx") && (filetype[filetype.length - 1].toLowerCase() != "msword")) {
        this.fileformaterror = true;
        // console.log(filetype[filetype.length - 1]);
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.cvname = imagepath;
    }
    else {
      // value3.cvname = this.candidateDetail.cvname;
      value3.cvname = "";
    }
    this.disableBtn = false;
    this.candidate.candidatedetailsCreate(value3).subscribe((data: any) => {
      this.candidate.getcandidateList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
        this.record = data.length;
        this.datalist =data;
        this.pageOfItems = data;
        if(this.record < 30){
          this.pageOfItems = data;
        }
        else{
             this.items =data;
        }
      });
    });
    this.alerts.success('Applicant details added successfully.', true);
    this.filename = "Choose file";
    this.modalService.close(id);

  }

  statusChange(candidatestatusmaster: any, candidate: any)
  {
    if (confirm("Are you sure you want to change status?")) {
      this.candidate.candidatestatusUpdateOnly({"candidateid": candidate.candidatemasterid, "sid": candidatestatusmaster}).subscribe((data: any) => {
        this.candidate.getcandidateList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
          this.record = data.length;
          this.pageOfItems = data;
          this.datalist =data;

          if(this.record < 30){
            this.pageOfItems = data;
          }
          else{
               this.items =data;
          }
          this.alerts.success('Applicant details updated successfully.', true);
        });
      });
    }
  }

  submitStatus(emailerForm: any, modelid: any)
  {    
    if (this.candidatemessageFormGroup.invalid) {
      return;
    }

    this.candidate.candidatestatusdetailsUpdate(emailerForm).subscribe((data: any) => {
      this.candidate.getcandidateList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
        this.record = data.length;
        this.pageOfItems = data;
        this.datalist =data;
        if(this.record < 30){
          this.pageOfItems = data;
        }
        else{
             this.items =data;
        }
        this.modalService.close(modelid);
        this.alerts.success('Applicant details updated successfully.', true);
      });
    });
  }

  onChangeCategory(event, cat: any) { 
    if (event.target.checked == true) {
      if (this.tempArr.skills.indexOf(cat.jobskilldetailid) === -1) {
        this.tempArr.skills.push(cat.jobskilldetailid);
      }
    }
    else if (event.target.checked == false) {
      this.tempArr.skills.pop(cat.jobskilldetailid);
    }
  }

  onChangeinterested(sid: any, candidateid: any)
  {
    var data = {"sid": sid, "candidateid": candidateid}    ;
    // console.log(data);

    this.candidate.candidatestatusinterested(data).subscribe((data: any) => {
      this.candidate.getcandidateList({ "id": this.Userid, "jobid": this.jobid ,"cstatus": this.statusid}).subscribe((data: any) => {
        this.record = data.length;
        this.pageOfItems = data;
        this.datalist =data;

        this.alerts.success('Applicant status updated successfully', true);
        if(this.record < 30){
          this.pageOfItems = data;
        }
        else{
             this.items =data;
        }
        // this.data = data;
      });
    });
  }




  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  selectFile(event) {
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = files[0];

    this.file = this.selectedFiles;
    var filetype = this.file.type.split('/');

    if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") && (filetype[filetype.length - 1].toLowerCase() != "gif") && (filetype[filetype.length - 1].toLowerCase() != "tif") && (filetype[filetype.length - 1].toLowerCase() != "pdf") && (filetype[filetype.length - 1].toLowerCase() != "doc") && (filetype[filetype.length - 1].toLowerCase() != "docx") && (filetype[filetype.length - 1].toLowerCase() != "msword")) {
 this.fileformaterror = true;
    }
    else{
      this.fileformaterror =false;
    }
  }

  openModal(candidateid: string, modelid: string) {
    this.disableBtn = true;
    this.tempArr = { "skills": [] };
    this.candidate.getcandidateById({ "id": this.Userid, "candidateid": candidateid }).subscribe((data: any) => {
      var candskills = [];
      data.skill.forEach(ele => {
        candskills.push(ele.jobskilldetailid);
      })
      var allskill = this.skilllist;
      var newSkillArray = [];
      allskill.forEach(element => {
        if (candskills.includes(element.jobskilldetailid.toString()) == false) {
          newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': false });
        }
        else {
          this.tempArr.skills.push(element.jobskilldetailid);
          newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': true });
        }
      });
      this.skilllist = newSkillArray;
      this.candidateDetail = data;
    });
    this.buttoninsert = "Update";
    this.modalService.open(modelid);
  }

  openModal3(candidateid: string, modelid: string) {
    this.disableBtn = true;
    this.tempArr = { "skills": [] };
    this.candidate.getcandidateById({ "id": this.Userid, "candidateid": candidateid }).subscribe((data: any) => {
      var candskills = [];
      data.skill.forEach(ele => {
        candskills.push(ele.jobskilldetailid);
      })
      var allskill = this.skilllist;
      var newSkillArray = [];
      allskill.forEach(element => {
        if (candskills.includes(element.jobskilldetailid.toString()) == false) {
          newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': false });
        }
        else {
          this.tempArr.skills.push(element.jobskilldetailid);
          newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': true });
        }
      });
      this.skilllist = newSkillArray;
      this.candidateDetail = data;
    });
    this.buttoninsert = "Update";
    this.modalService.open(modelid);
  }

  openModal4(candidateid: string, modelid: string) {
    this.disableBtn = true;
    this.candidate.getcandidateById({ "id": this.Userid, "candidateid": candidateid }).subscribe((data: any) => {
      this.candidateDetail = data;
    });
    this.modalService.open(modelid);
  }
  

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;      
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  openModal5(id: string, data: any) {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  openModal6(id: string, sid: string, candidateid: string, jobid: any) {
    this.buttoninsert = "Change Status";
    this.disableBtn = true;
    this.applicantservices.getemailertemplate({ "candidateid": candidateid, "jobid": jobid, "sid": sid }).subscribe((edata: any) => {
      this.emailerDetail = edata;
      this.emailerDetail.candidateid = candidateid;
      this.emailerDetail.jobid = jobid;
      this.emailerDetail.location = "";
      this.emailerDetail.interdate = "";
      this.emailerDetail.interviewetime = "";
      this.emailerDetail.interviewstime = "";
      this.modalService.open(id);
    });
  }

  openModal7(id: string, sid: string, data: any) {
    this.disableBtn = true;
    this.modalService.open(id);
  }



  deleteFile(id: string, candidateid: string) {
    const deleteData = { 'id': id };
    if (confirm("Are you sure you want to delete?")) {
      this.candidate.deleteFile(deleteData).subscribe((data: any) => {
        this.candidate.getcandidateById({ "id": this.Userid, "candidateid": candidateid }).subscribe((data: any) => {
          this.candidateDetail = data;
          
        });
      });
    }
  }

  openModal2(id: string) {
    this.disableBtn = true;
    this.submitted = false;
    this.tempArr = { "skills": [] };
    this.candidateDetail = new Candidate();
    this.getcandidateformgroup();
    this.job.getjobskillList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
      var dataskillArray = [];
      data.forEach(skil => {
        dataskillArray.push({ 'jobskilldetailid': skil.jobskilldetailid, 'skillname': skil.skillname, 'check': false });
      })
      this.skilllist = dataskillArray;
      this.candidateDetail.candidatemasterid =0;
      this.candidateDetail.jobid = this.jobid;
      this.candidateDetail.joburlid = "";
      // console.log(this.candidateDetail);
      this.filename ='Choose file';
      this.buttoninsert = "Submit";
      this.phonecheck = "";
      this.modalService.open(id);
    });
    
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

}
