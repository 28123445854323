"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var i2 = require("@angular/common/http");
var ChecksystemService = /** @class */ (function () {
    function ChecksystemService(commonService, http) {
        this.commonService = commonService;
        this.http = http;
    }
    ChecksystemService.prototype.getIPAddress = function () {
        return this.http.get("https://api.ipify.org/?format=json");
        //return this.commonService.formpost('/check/remainitem',data);   
    };
    ChecksystemService.prototype.getbillinginformation = function (data) {
        return this.commonService.formpost('/check/remainitem', data);
    };
    ChecksystemService.prototype.gettokensystem = function () {
        return this.commonService.formpostOnlyid('/api/userloginbytoken');
    };
    ChecksystemService.prototype.gettokensystembytoken = function () {
        return this.commonService.formpostOnlyidbytoken('/api/userloginbytoken');
    };
    ChecksystemService.prototype.gettokenrefreestatus = function (data) {
        return this.commonService.formpostwithouttoken('/referee/checkrefreetoken', data);
    };
    ChecksystemService.prototype.deleteAccessToken = function () {
        return this.commonService.formpostOnlyid('/api/deleteAccessToken');
    };
    ChecksystemService.prototype.deleteAccessTokenrightoworklog = function (data) {
        return this.commonService.refereeformpost('/employee/righttoworklogtoken', data);
    };
    ChecksystemService.prototype.sendsmsurl = function (data) {
        return this.commonService.formpost('/sendsmsurl', data);
    };
    ChecksystemService.prototype.genratetokenforemployee = function (data) {
        return this.commonService.formpost('/api/genratetokenforemployee', data);
    };
    ChecksystemService.prototype.getAllServices = function () {
        return this.commonService.formpostOnlyid('/api/getCheckServices');
    };
    ChecksystemService.prototype.getAllInvoicedetails = function (data) {
        return this.commonService.formpost('/check/getcheckinvoicedetails', data);
    };
    ChecksystemService.prototype.downloadCheckInvoice = function (data) {
        return this.commonService.formpost('/check/downloadcheckinvoice', data);
    };
    ChecksystemService.prototype.getAllInvoice = function () {
        return this.commonService.formpostOnlyid('/check/getallinvoice');
    };
    ChecksystemService.prototype.validateemail = function (data) {
        return this.commonService.formget('/verifyemail/' + data);
    };
    ChecksystemService.ngInjectableDef = i0.defineInjectable({ factory: function ChecksystemService_Factory() { return new ChecksystemService(i0.inject(i1.CommonService), i0.inject(i2.HttpClient)); }, token: ChecksystemService, providedIn: "root" });
    return ChecksystemService;
}());
exports.ChecksystemService = ChecksystemService;
