"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../_services/modal.service");
var aml_model_1 = require("../../models/aml/aml.model");
var aml_service_1 = require("../../services/aml.service");
var checksystem_service_1 = require("../../services/checksystem.service");
var router_1 = require("@angular/router");
var company_service_1 = require("../../services/company.service");
var country_service_1 = require("../../services/country.service");
var alert_service_1 = require("../../services/alert.service");
var CheckamlComponent = /** @class */ (function () {
    function CheckamlComponent(amldetails, activatedRoute, check, alerts, company, country, modalService) {
        this.amldetails = amldetails;
        this.activatedRoute = activatedRoute;
        this.check = check;
        this.alerts = alerts;
        this.company = company;
        this.country = country;
        this.modalService = modalService;
        this.searchresult = [];
        this.ispaging = true;
        this.searchresultnewar = [];
        this.searchresultnewob = {};
        this.atype = false;
        this.activePage = 0;
        this.disableBtn = true;
        this.showmonitor = true;
        this.suggestions = [{ "id": "PEP", "name": "PEP" }, { "id": "SAN", "name": "Sanction" }, { "id": "RRE", "name": "Reputational Risk Exposure" },
            { "id": "INS", "name": "Insolvency (UK & Ireland)" }, { "id": "DD", "name": "Disqualified Director (UK only)" }, { "id": "POI", "name": " Profile of Interest" }, { "id": "REL", "name": "Regulatory Enforcement List" }];
    }
    CheckamlComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.downloadtext = ' <i class="fa fa-close"></i> Active Monitoring ';
        this.company.getcompanyData().subscribe(function (companydata) {
            _this.ismonitoring = companydata.ismonitoring;
            _this.companyid = companydata.companyid;
            console.log('monitoring', _this.ismonitoring);
            if (companydata.ismonitoring) {
                _this.downloadtext = ' <i class="fa fa-spinner fa-spin"></i> Active Monitoring ';
                _this.ismonitoring = companydata.ismonitoring;
            }
            else {
                _this.downloadtext = ' <i class="fa fa-close"></i> Active Monitoring ';
            }
        });
        this.country.getcountrylist().subscribe(function (country) {
            // console.log('country list',country);
            _this.countrylist = country;
        });
        this.pageload();
    };
    CheckamlComponent.prototype.pageload = function () {
        var _this = this;
        var masterarray = [];
        this.logid = this.activatedRoute.snapshot.paramMap.get("type");
        if (this.logid == 0) {
            this.type = 'aml';
        }
        if (this.logid == 2) {
            this.type = 'adverse';
        }
        if (this.logid == 3) {
            this.type = 'global';
        }
        if (this.logid == 4) {
            this.type = 'global';
        }
        if (this.logid == 1) {
            this.type = 'aml';
        }
        if (this.logid == 0 || this.logid == 2 || this.logid == 3) {
            this.atype = true;
            this.searchresultnew = new aml_model_1.Aml();
            var val = { atype: this.logid };
            this.amldetails.getCompanylogrecord(val).subscribe(function (searchRes) {
                _this.downloadtextnew = new Array(searchRes.length);
                for (var i = 0; i < searchRes.length; i++) {
                    if (searchRes[i].ismonitoring)
                        _this.downloadtextnew[i] = 'Remove Monitoring <i class="fa fa-bell-o ml-2"></i>';
                    else
                        _this.downloadtextnew[i] = 'Active Monitoring <i class="fa fa-bell-o ml-2"></i>';
                }
                searchRes.forEach(function (element) {
                    element.searchcondition = JSON.parse(element.searchcondition);
                    element.displayData = true;
                    _this.searchresult.push(element);
                });
                _this.disableBtn = true;
                _this.pageOfItems = _this.searchresult;
                _this.datalist = _this.searchresult;
                if (searchRes.length < 30) {
                    _this.pageOfItems = _this.searchresult;
                }
                else {
                    _this.items = _this.searchresult;
                }
            });
        }
        else if (this.logid == 1 || this.logid == 4) {
            this.atype = false;
            this.searchresultnew = new aml_model_1.AmlBussiness();
            var val = { atype: this.logid };
            this.amldetails.getCompanylogrecord(val).subscribe(function (searchRes) {
                _this.downloadtextnew = new Array(searchRes.length);
                for (var i = 0; i < searchRes.length; i++) {
                    if (searchRes[i].ismonitoring)
                        _this.downloadtextnew[i] = 'Remove Monitoring <i class="fa fa-bell-o ml-2"></i>';
                    else
                        _this.downloadtextnew[i] = 'Active Monitoring <i class="fa fa-bell-o ml-2"></i>';
                }
                searchRes.forEach(function (element) {
                    element.searchcondition = JSON.parse(element.searchcondition);
                    element.displayData = true;
                    _this.searchresult.push(element);
                });
                _this.datalist = _this.searchresult;
                _this.pageOfItems = _this.searchresult;
                if (searchRes.length < 30) {
                    _this.pageOfItems = _this.searchresult;
                }
                else {
                    _this.items = _this.searchresult;
                }
            });
        }
        // else if(this.logid ==2)
        // {
        //   this.atype =true;
        //   this.searchresultnew = new Aml();
        //   var val ={ atype:this.logid  }  
        //   this.amldetails.getCompanylogrecord(val).subscribe((searchRes: any) => {
        //     this.downloadtextnew = new Array(searchRes.length);
        //     for(var i=0;i<searchRes.length;i++)
        //     {
        //       if(searchRes[i].ismonitoring)
        //         this.downloadtextnew[i] = 'Remove Monitoring <i class="fa fa-bell-o ml-2"></i>';
        //       else
        //         this.downloadtextnew[i] = 'Active Monitoring <i class="fa fa-bell-o ml-2"></i>';  
        //     } 
        //     searchRes.forEach(element => {
        //       element.searchcondition = JSON.parse(element.searchcondition);
        //       element.displayData = true;
        //       this.searchresult.push(element);
        //      });
        //     this.disableBtn = true;        
        //     this.pageOfItems = this.searchresult;
        //     this.datalist = this.searchresult;
        //     if(searchRes.length < 30){
        //       this.pageOfItems = this.searchresult;
        //     }
        //     else{
        //         this.items =this.searchresult;
        //     }
        //   });
        // }
    };
    CheckamlComponent.prototype.displayActivePage = function (activePageNumber) {
        this.activePage = activePageNumber;
    };
    CheckamlComponent.prototype.searchdatapage = function (value) {
        if (value == '')
            this.ispaging = true;
        else
            this.ispaging = true;
        this.pageOfItems = this.pageOfItems;
        console.log(value);
    };
    CheckamlComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    CheckamlComponent.prototype.bindallcountry = function (val) {
        if (val) {
            return this.countrylist.filter(function (d) { return val.includes(d.iso); });
            ;
        }
        else
            return [];
        // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
    };
    CheckamlComponent.prototype.bindallpep = function (val) {
        if (val) {
            return this.suggestions.filter(function (d) { return val.includes(d.id); });
            ;
        }
        else
            return [];
        // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
    };
    CheckamlComponent.prototype.dataset = function (values) {
        this.datasetlist = "";
        if (values.PEP == true) {
            this.datasetlist = "<li><p> PEP </p> </li>";
        }
        if (values.AdverseMedia == true) {
            this.datasetlist += "<li><p> Adverse Media</p> </li>";
        }
        if (values.DisqualifiedDirector == true) {
            this.datasetlist += "<li><p>Disqualified Director (UK Only)</p> </li>";
        }
        if (values.FinancialRegulator == true) {
            this.datasetlist += "<li><p>Financial Regulator</p> </li>";
        }
        if (values.Insolvency == true) {
            this.datasetlist += "<li><p> Insolvency (UK & Ireland)</p> </li>";
        }
        if (values.LawEnforcement == true) {
            this.datasetlist += "<li><p>Law Enforcement</p> </li>";
        }
        if (values.CurrentSanctions == true) {
            this.datasetlist += "<li><p> Sanction - Current</p> </li>";
        }
        if (values.PreviousSanctions == true) {
            this.datasetlist += "<li><p> Sanction - Previous</p> </li>";
        }
        this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1);
    };
    CheckamlComponent.prototype.outputnewset = function (d) {
        var listmaster = JSON.parse(d);
        this.searchresultnew = new aml_model_1.Aml();
        this.searchresultnew = listmaster;
    };
    CheckamlComponent.prototype.outputnewBussinessset = function (d) {
        var listmaster = JSON.parse(d);
        this.searchresultnew = new aml_model_1.AmlBussiness();
        this.searchresultnew = listmaster;
    };
    CheckamlComponent.prototype.searchData = function (searchKey) {
        var _this = this;
        searchKey = searchKey.trim().toLowerCase();
        if (this.logid == 0) {
            this.searchresult.forEach(function (element, index) {
                if (element.searchcondition.Forename.trim().toLowerCase().includes(searchKey) ||
                    element.searchcondition.Middlename.trim().toLowerCase().includes(searchKey) ||
                    element.searchcondition.Surname.trim().toLowerCase().includes(searchKey) ||
                    element.searchcondition.YearOfBirth.trim().toLowerCase().includes(searchKey) ||
                    element.searchcondition.City.trim().toLowerCase().includes(searchKey) ||
                    element.searchcondition.County.trim().toLowerCase().includes(searchKey) ||
                    element.searchcondition.Postcode.trim().toLowerCase().includes(searchKey) ||
                    element.searchcondition.Country.trim().toLowerCase().includes(searchKey)) {
                    _this.searchresult[index].displayData = true;
                }
                else {
                    _this.searchresult[index].displayData = false;
                }
            });
        }
        else if (this.logid == 1) {
            this.searchresultnewar.forEach(function (element, index) {
                if (element.BusinessName.trim().toLowerCase().includes(searchKey) ||
                    element.City.trim().toLowerCase().includes(searchKey) ||
                    element.County.trim().toLowerCase().includes(searchKey) ||
                    element.Postcode.trim().toLowerCase().includes(searchKey) ||
                    element.Address.trim().toLowerCase().includes(searchKey) ||
                    element.Country.trim().toLowerCase().includes(searchKey)) {
                    _this.searchresultnewar[index].displayData = true;
                }
                else {
                    _this.searchresultnewar[index].displayData = false;
                }
            });
        }
    };
    CheckamlComponent.prototype.openModal = function (id, amlid) {
        this.amlid = amlid;
        this.modalService.open(id);
    };
    CheckamlComponent.prototype.onActive = function (id) {
        var _this = this;
        this.disableBtn = false;
        var data = { amlid: this.amlid, isstatus: true };
        this.amldetails.postActiveAmllogDetails(data).subscribe(function (searchRes) {
            _this.modalService.close(id);
            _this.alerts.success(searchRes.message, false);
            _this.pageload();
        });
    };
    CheckamlComponent.prototype.onDeactive = function (id) {
        var _this = this;
        this.disableBtn = false;
        var data = { amlid: this.amlid, isstatus: false };
        this.amldetails.postActiveAmllogDetails(data).subscribe(function (searchRes) {
            _this.modalService.close(id);
            _this.pageload();
            _this.alerts.success(searchRes.message, false);
        });
    };
    CheckamlComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return CheckamlComponent;
}());
exports.CheckamlComponent = CheckamlComponent;
