import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CompanyService } from '../../../services/company.service';
import { ChecksystemService } from '../../../services/checksystem.service';
import { CheckInvoice, CompanyDetails } from '../../../models/check/checkInvoice.model';
//import * as jspdf from 'jspdf';        
// import html2canvas from 'html2canvas-proxy';  


@Component({
  selector: 'app-checkinvoice',
  templateUrl: './checkinvoice.component.html',
  styleUrls: ['./checkinvoice.component.css']
})
export class CheckinvoiceComponent implements OnInit {

  companylocationlist: any;
  invoicelist: any = new CheckInvoice;
  invoiceid: any;
  timestamp: any;
  ctype: number;

  constructor(
    private company: CompanyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private check:ChecksystemService
  ) { }

  ngOnInit() {
    this.ctype  = parseInt(localStorage.getItem('ctype'));
    this.invoicelist.companydetails = new CompanyDetails();
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    
    this.invoiceid = this.activatedRoute.snapshot.paramMap.get("invoiceid");

    this.timestamp = function(epoch){
      return (epoch * 1000);
    };


    this.company.getcompanydefaultlocation().subscribe((locationlist: any) => {      
      this.companylocationlist = locationlist;      
    });


    var data = { 'invoiceid': this.invoiceid };
    this.check.getAllInvoicedetails(data).subscribe((invoiceData: any) => { 
      this.invoicelist = invoiceData;      
    });

  }

  captureScreen()  
  {  
    var data = document.getElementById('contentToConvert');  

    // console.log(data);
    // html2canvas(data).then(canvas => {  
    //   // Few necessary setting options  
    //   var imgWidth = 208;   
    //   var pageHeight = 295;    
    //   var imgHeight = canvas.height * imgWidth / canvas.width;  
    //   var heightLeft = imgHeight;  
  
    //   const contentDataURL = canvas.toDataURL('image/png')  
    //   let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
    //   var position = 0;  
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
    //   pdf.save('MYPdf.pdf'); // Generated PDF   
    // });  
  }  

}
