"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var modal_service_1 = require("../../_services/modal.service");
var contact_service_1 = require("../../services/contact.service");
var router_1 = require("@angular/router");
var alert_service_1 = require("../../services/alert.service");
var AdmindashboardComponent = /** @class */ (function () {
    function AdmindashboardComponent(modalService, formBuilder, contactService, router, alerts) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.contactService = contactService;
        this.router = router;
        this.alerts = alerts;
        this.defaultVal = "";
    }
    AdmindashboardComponent.prototype.ngOnInit = function () {
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.createformgroup();
        this.nrSelect = '';
        this.defaultVal = '';
    };
    Object.defineProperty(AdmindashboardComponent.prototype, "f", {
        get: function () {
            return this.visapopForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    AdmindashboardComponent.prototype.createformgroup = function () {
        this.visapopForm = this.formBuilder.group({
            fname: ['', forms_1.Validators.required],
            bussinessemail: ['', forms_1.Validators.required],
            phonenumber: ['', forms_1.Validators.required],
            querytype: ['', forms_1.Validators.required],
            subject: ['', forms_1.Validators.required],
            message: ['', forms_1.Validators.required]
        });
    };
    AdmindashboardComponent.prototype.onSubmit = function (value3, id) {
        var _this = this;
        this.submitted = true;
        if (this.visapopForm.invalid) {
            return;
        }
        value3.mailertype = 'Contact Us';
        this.contactService.contactus(value3).subscribe(function (data) {
            _this.alerts.success("Query submitted successfully", false);
            _this.modalService.close(id);
        });
    };
    AdmindashboardComponent.prototype.openModalMastersystem = function (id) {
        this.visapopForm.reset();
        this.submitted = false;
        this.modalService.open(id);
    };
    AdmindashboardComponent.prototype.closeModal = function (id) {
        this.visapopForm.reset();
        this.submitted = false;
        this.modalService.close(id);
    };
    return AdmindashboardComponent;
}());
exports.AdmindashboardComponent = AdmindashboardComponent;
