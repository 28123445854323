"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./polyfills");
var date_fns_1 = require("angular-calendar/date-adapters/date-fns");
var ɵ0 = date_fns_1.adapterFactory;
exports.ɵ0 = ɵ0;
// import { GlobalddComponent } from './checksystem/globaldd/globaldd.component';
// import { PersonalglobalamlcheckComponent } from './checksystem/globaldd/personalglobalamlcheck/personalglobalamlcheck.component';
// import { BusinessglobalamlcheckComponent } from './checksystem/globaldd/businessglobalamlcheck/businessglobalamlcheck.component';
// import { SearchbusinessglobalamlComponent } from './checksystem/globaldd/searchbusinessglobalaml/searchbusinessglobalaml.component';
// import { SearchpersonalglobalamlComponent } from './checksystem/globaldd/searchpersonalglobalaml/searchpersonalglobalaml.component';
// import { SearchpersonalglobalamldetailsComponent } from './checksystem/globaldd/searchpersonalglobalaml/searchpersonalglobalamldetails/searchpersonalglobalamldetails.component';
// import { SearchbusinessglobalamldetailsComponent } from './checksystem/globaldd/searchbusinessglobalaml/searchbusinessglobalamldetails/searchbusinessglobalamldetails.component';
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
exports.AppModule = AppModule;
