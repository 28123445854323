"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var myemployment_service_1 = require("../services/myemployment.service");
var employee_service_1 = require("../services/employee.service");
var modal_service_1 = require("../_services/modal.service");
var platform_browser_1 = require("@angular/platform-browser");
var MyemploymentComponent = /** @class */ (function () {
    function MyemploymentComponent(sanitizer, data, employeeService, modalService) {
        this.sanitizer = sanitizer;
        this.data = data;
        this.employeeService = employeeService;
        this.modalService = modalService;
        this.employeedetails = null;
    }
    MyemploymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        this.empltype = localStorage.getItem('emptype');
        var data = { 'userid': this.Userid };
        this.employeeService.getOtherInfo(data).subscribe(function (data) {
            _this.otherDetail = data;
        });
        this.data.getemploymenttList().subscribe(function (data) {
            _this.employeedetails = data;
        });
    };
    MyemploymentComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    MyemploymentComponent.prototype.openModal3 = function (id, data) {
        var fullpathArray = data.split('.');
        this.iframeempsrc = '';
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgempsrc = true;
            this.iframeempsrc = data;
        }
        else {
            this.imgempsrc = false;
            this.iframeempsrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    MyemploymentComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    return MyemploymentComponent;
}());
exports.MyemploymentComponent = MyemploymentComponent;
