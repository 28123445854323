"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var asset_service_1 = require("../services/asset.service");
var modal_service_1 = require("../_services/modal.service");
var router_1 = require("@angular/router");
var AssetComponent = /** @class */ (function () {
    function AssetComponent(dataset, router, modalService) {
        this.dataset = dataset;
        this.router = router;
        this.modalService = modalService;
        this.pager = {};
    }
    AssetComponent.prototype.ngOnInit = function () {
        this.Userid = localStorage.getItem('userid');
        if (!this.Userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.bindalldata();
    };
    AssetComponent.prototype.bindalldata = function () {
        var _this = this;
        this.dataset.getassetlistbyemployeeid({ 'employeeid': this.Userid }).subscribe(function (data) {
            _this.record = data.length;
            // this.data = data;
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.pageOfItems = data;
            }
        });
    };
    AssetComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    return AssetComponent;
}());
exports.AssetComponent = AssetComponent;
