<div class="bg">
  <app-header></app-header>

  <div class="section-wrapper p-0">
      <div class="admin_dashboard_show">
        <a _ngcontent-c4=""  [routerLink]="['/check-dashboard']"    class="backlist mb-4 mr-4" ><i _ngcontent-c4="" aria-hidden="true" class="fa fa-angle-left"></i> Back to Check Dashboard</a>
        <div class="ceee width100">
            <div class="cardmenu">
              <a class="card_box_lit" href="../../../../../assets/img/pdf/Knowledge_Centre.pdf" target="_blank">
                <div class="samebox">
                  <i class="fa fa-file-pdf-o"></i>
                  <div class="menu_content">
                      <h4>Knowledge Centre</h4>                    
                  </div>
                </div>
              </a>
                <a class="card_box_lit" href="../../../../../assets/img/pdf/How_to_request_Right_to_Work_checks.pdf" target="_blank">
                  <div class="samebox">
                    <i class="fa fa-file-pdf-o"></i>
                    <div class="menu_content">
                        <h4>How to request Right to Work checks</h4>                      
                    </div>
                  </div>
                </a>
                <a class="card_box_lit" href="../../../../../assets/img/pdf/How_to_request_an_Adverse_Credit_check.pdf" target="_blank">
                  <div class="samebox">
                    <i class="fa fa-file-pdf-o"></i>
                    <div class="menu_content">
                        <h4>How to request an Adverse Credit check</h4>                       
                    </div>
                  </div>
                </a>
                <a class="card_box_lit" href="../../../../../assets/img/pdf/How_to_request_an_AML_PEPs_&_Sanctions_check.pdf" target="_blank">
                    <div class="samebox">
                        <i class="fa fa-file-pdf-o"></i>
                        <div class="menu_content">
                            <h4>How to request an AML PEPs & Sanctions check</h4>
                        </div>
                    </div>
                </a>
                <a class="card_box_lit" href="../../../../../assets/img/pdf/How_to_request_a_Tax_Defaulters_check.pdf" target="_blank">
                    <div class="samebox">
                        <i class="fa fa-file-pdf-o"></i>
                        <div class="menu_content">
                            <h4>How to request a Tax Defaulters check</h4>
                        </div>
                    </div>
                </a>
                <a class="card_box_lit" href="../../../../../assets/img/pdf/How_to_request_a_Director_Search.pdf" target="_blank">
                  <div class="samebox">
                      <i class="fa fa-file-pdf-o"></i>
                      <div class="menu_content">
                          <h4>How to request a Director Search</h4>
                      </div>
                  </div>
              </a>
              <a class="card_box_lit" href="../../../../../assets/img/pdf/How_to_request_a_DVLA_check.pdf" target="_blank">
                <div class="samebox">
                    <i class="fa fa-file-pdf-o"></i>
                    <div class="menu_content">
                        <h4>How to request a DVLA check</h4>
                    </div>
                </div>
            </a>
            <a class="card_box_lit" href="../../../../../assets/img/pdf/How_to_request_an_Identity_check.pdf" target="_blank">
              <div class="samebox">
                  <i class="fa fa-file-pdf-o"></i>
                  <div class="menu_content">
                      <h4>How to request an Identity check</h4>
                  </div>
              </div>
          </a>
          <a class="card_box_lit" href="../../../../../assets/img/pdf/How_to_request_an_Automated_Employment_reference_report.pdf" target="_blank">
            <div class="samebox">
                <i class="fa fa-file-pdf-o"></i>
                <div class="menu_content">
                    <h4>How to request an Automated Employment reference report</h4>
                </div>
            </div>
        </a>
        <a class="card_box_lit" href="../../../../../assets/img/pdf/DBS_Guidance_Notes.pdf" target="_blank">
          <div class="samebox">
              <i class="fa fa-file-pdf-o"></i>
              <div class="menu_content">
                  <h4>DBS Guidance Notes</h4>
              </div>
          </div>
         </a>
         <a class="card_box_lit" href="../../../../../assets/img/pdf/DBS_Registration_Pack.pdf" target="_blank">
          <div class="samebox">
              <i class="fa fa-file-pdf-o"></i>
              <div class="menu_content">
                  <h4>DBS Registration Pack</h4>
              </div>
          </div>
         </a>
        </div>
     
      
        </div>
  </div>

  </div>

  <!-- <jw-modal id="Em_support" class="modal Em_support" role="dialog">

      <form [formGroup]="visapopForm" #visaForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(visaForm.value,'Em_support')">

      <div role="document" class="formgroup" >
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">
                  Support
                      </h5>
                  <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('Em_support');">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body" >
                  <div class="container">

                          
                          <div class="formgroup">
                              <div class="row row-sm mg-t-10">
                                  <div class="col-sm-6 mb-2 ">
                                      <label class="labelresize mb-0">Full Name*</label>

                                  <input type="text" formControlName="fname" name="fname" id="fname"  class="form-control"  [maxlength]="100" placeholder="Enter Full Name">
                                  <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                                      <div *ngIf="f.fname.errors.required">Please Enter Full Name</div>
                                      <div *ngIf="!f.fname.errors.required && f.fname.errors.minlength">Minimum 3 characters Required</div>
                                  </div>
                                  </div>
                                  <div class="col-sm-6 mb-2">
                                      <label class="labelresize mb-0">Business Email*</label>

                                      <input type="text" formControlName="bussinessemail" name="bussinessemail" id="bussinessemail"  class="form-control"  [maxlength]="100" placeholder="Enter Business Email">
                                       <div *ngIf="submitted && f.bussinessemail.errors" class="invalid-feedback">
                                          <div *ngIf="f.bussinessemail.errors.required">Please Enter Business Email</div>
                                          
                                      </div>
                                  </div>
                                  <div class="col-sm-6 mb-2">
                                      <label class="labelresize mb-0">Phone Number*</label>

                                      <input type="text" formControlName="phonenumber" name="phonenumber" id="phonenumber"  class="form-control"  [maxlength]="100" placeholder="Enter Phone Number">
                                       <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                          <div *ngIf="f.phonenumber.errors.required">Please Enter Phone Number</div>
                                         
                                      </div>
                                  </div>
                                  <div class="col-sm-6 mb-2">
                                      <label class="mb-0 labelresize">Query Type*</label>
                                      <select class="form-control" [(ngModel)]='nrSelect'  name="querytype" id="querytype"   formControlName="querytype">
                                      <option [value]="nrSelect">Select Query Type</option>
                                      <option value="Technical Support" >Technical Support</option>
                                      <option  value="Billing Support" >Billing Support</option>
                                      <option value="Application Support" >Application Support</option>
                                      <option value="Others" >Others</option>

                                      </select>
                                       <div *ngIf="submitted && f.querytype.errors" class="invalid-feedback">
                                          <div *ngIf="f.querytype.errors.required">Please Select Query Type</div>
                                      </div>
                                  </div>
                                  <div class="col-sm-12 mb-2">
                                      <label class="labelresize mb-0">Subject*</label>

                                      <input type="text" formControlName="subject" name="subject" id="subject" class="form-control"  [maxlength]="100" placeholder="Enter Subject">
                                       <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                          <div *ngIf="f.subject.errors.required">Please Enter Your Subject</div>
                                        
                                      </div>
                                  </div>
                                  <div class="col-sm-12">
                                      <label class="mb-0 labelresize">Your Message*</label>
                                      <textarea   class="form-control" formControlName="message"  required name="message" id="message"  maxlength="500" placeholder="Enter remarks here"></textarea>
                                      <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                          <div *ngIf="f.message.errors.required">Please Enter Your Message</div>
                                      </div>
                                  </div>


                              </div>
                          </div>


                  </div>



              </div>
               <div class="modal-footer">
                   <div class="row">
                       <div class="col-lg-12">
                          <button  type="submit" class="btn btn-primary com_btn"> Submit</button>
                          <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal" (click)="closeModal('Em_support');">Close</button>
                       </div>
                   </div>

              </div>


          </div>
      </div>

  </form>
  </jw-modal> -->
