"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var customer_service_1 = require("../../services/customer.service");
var passport_model_1 = require("../../models/passport/passport.model");
var modal_service_1 = require("../../_services/modal.service");
var country_service_1 = require("../../services/country.service");
var upload_file_service_1 = require("./../../services/upload-file.service");
var mypassport_service_1 = require("../../services/mypassport.service");
var forms_1 = require("@angular/forms");
// import { UploadFileService } from '../../services/upload-file.service';
var platform_browser_1 = require("@angular/platform-browser");
var alert_service_1 = require("../../services/alert.service");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var PassportComponent = /** @class */ (function () {
    // :any;
    // selectedFiles: FileLifilest;
    function PassportComponent(sanitizer, formBuilder, uploadService, data, country, modalService, data2, alerts) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.data = data;
        this.country = country;
        this.modalService = modalService;
        this.data2 = data2;
        this.alerts = alerts;
        this.passport = new passport_model_1.Passport;
        this.defaultVal = "";
    }
    PassportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ReForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            passportnumber: ['', forms_1.Validators.required],
            placeofissue: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            passportissue: ['', forms_1.Validators.required],
            passportexpiry: ['', forms_1.Validators.required],
        });
        this.filename = 'Choose file';
        this.data.getPassport().subscribe(function (data) {
            _this.passport = data;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.passportDetail = new passport_model_1.Passport();
        this.passportDetail.status = 0;
    };
    Object.defineProperty(PassportComponent.prototype, "f", {
        get: function () { return this.ReForm.controls; },
        enumerable: true,
        configurable: true
    });
    PassportComponent.prototype.onSubmit = function (value3, id) {
        var _this = this;
        this.submitted = true;
        if (this.ReForm.invalid) {
            return;
        }
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            var typeoffile = filetype[filetype.length - 1];
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((typeoffile != "jpeg") && (typeoffile != "jpg") && (typeoffile != "png") && (typeoffile != "gif") && (typeoffile != "tif") && (typeoffile != "pdf") && (typeoffile != "doc") && (typeoffile != "docx")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagename = imagepath;
        }
        else {
            value3.imagename = this.passportDetail.imagename;
        }
        this.data2.mypassportdetailsUpdate(value3).subscribe(function (data) {
            var userid = localStorage.getItem('userid');
            _this.data.getPassport().subscribe(function (data) {
                _this.passport = data;
            });
        });
        this.alerts.success('Passport details updated successfully', true);
        this.modalService.close(id);
    };
    PassportComponent.prototype.selectFile = function (event) {
        var files = event.target.files;
        this.filename = files[0].name;
        //var selectedFiles = files[0];
        //this.selectedFiles = selectedFiles;
    };
    PassportComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    PassportComponent.prototype.openModal3 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        //  alert(typeoffile+'asdf '+data);
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    PassportComponent.prototype.openModal = function (id, data) {
        this.passportDetail = new passport_model_1.Passport();
        this.passportDetail = data;
        this.modalService.open(id);
    };
    PassportComponent.prototype.closeModal = function (id) {
        var _this = this;
        this.data.getPassport().subscribe(function (data) {
            _this.passport = data;
        });
        this.modalService.close(id);
    };
    PassportComponent.prototype.modalOpen = function (data) {
        this.passportDetail = data;
    };
    return PassportComponent;
}());
exports.PassportComponent = PassportComponent;
