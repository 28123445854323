<div class="signin-wrapper">
  <div class="new_login">
      <div class="login_section">
          <a class="login_logo"  [routerLink]="['/']" ><img src="/assets/img/logo.png" alt=""></a>
          <a   class="header-notification float-right" data-toggle="dropdown" (click)="logout()"
              title="Logout"> <i class="fa fa-power-off"></i> </a>
          <div class="signin-box">
              <form [formGroup]="registerForm" #loginForm class="login_form" (ngSubmit)="onSubmit()" *ngIf="success">
                  <p class="text-muted text-center" id="msg" runat="server">Your current mobile number is <b>{{ mobilenumber2 }}</b>. If it's wrong then Please enter correct mobile number below</p>
                  <div class="form-group mg-b-30" *ngIf="show">
                      <p class="red">This mobile number already exists. Please try another mobile number.</p>
                  </div>

                  <div class="form-group mg-b-30">
                      <div class="input-group">
                        <international-phone-number formControlName="mobilenumber"
                        class="form-control phonecontrol"   
                        [ngClass]="{ 'is-invalid': submitted && f.mobilenumber.errors }"
                        [(ngModel)]="user.mobilenumber" placeholder="Enter mobile number"
                        (input)="validatenumber($event.target.value,'phone')" [maxlength]="20"
                        [required]="true" #mobilenumber name="mobilenumber" ></international-phone-number>
                        <div *ngIf="submitted && f.mobilenumber.errors" class="invalid-feedback">
                            <div *ngIf="f.mobilenumber.errors.required">Please enter mobile number</div>
                        </div>
                        <span class="clearfix"></span>
                        <span *ngIf="phonecheck===false" style="color:red">Invalid format</span>
                        <span *ngIf="phonecheck===true" style="color:green">Valid format</span>
                      </div>
                  </div>

                  <div class="form-group m-t-10 mb-0 row">
                      <button class="btn btn-primary btn-block btn-signin" type="submit">Submit</button>
                  </div>

                  <div class="form-group m-t-10 mb-0 row">
                      <div class="col-12 m-t-20 text-center">
                          <i class="mdi mdi-user"></i>If the above mobile number is correct, <a (click)="resedotp();"
                              class="text-muted text-center hand"
                              style="color:#ff322e !important; cursor: pointer;">Click Here</a> to get a new OTP.
                      </div>
                  </div>
              </form>

              <div class="form-group mg-b-30" *ngIf="showsuccess">
                  <p class="red">Mobile Number updated successfully <a [routerLink]="['/login']"
                          class="text-muted text-center hand" style="color:#ff322e !important;">Click Here</a> to
                      login in your account. </p>
              </div>
          </div>
      </div>
      <div class="graphic_section">
          <div class="slogan">
              <h4>Welcome To <b>Complygate</b></h4>
              <span>Commit. Control. Comply</span>
          </div>
      </div>
      <div class="clearfix"></div>
  </div>
</div>