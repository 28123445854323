<app-header></app-header>


<div class="section-wrapper">
    <div id="ContentPlaceHolder1_pnlView" class="container">
        <a [routerLink]="['/admin-list']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to  Admin Role</a>
        <div class="clearfix">&nbsp;</div>
        <div class="tabs">
            <div class="row">
                <div class="col-sm-12">
                    <div class="white_box_shadow">
                        <h5 class="hedingcolor pl-3">Manage Permissions </h5>
                        <div class="aligncenter d-flex justify-content-start wrap">

                            <ng-container  *ngFor="let employeels of menulist;  let i=index"> 
                            <div class="manage_per" *ngIf="ctype && employeels.logintype || !ctype">
                                <div class="mini-stat clearfix bg-white" >

                                    <div class="icon_show">
                                        <p class="mini-stat-icon {{ employeels.menuname }} bg-purple mr-3 mb-1"><i class="{{employeels.classname}}"></i></p>
                                        <div class="icon_text_show mb-1">
                                            <div class="mini-stat-info">
                                                <span class="counter text-purple">{{ employeels.menuname }}</span>
                                            </div>
    
                                        </div>
                                    </div>
                                   
                                    <div class="checkradio">
                                        <div class="form-check" *ngFor="let submenuname of employeels.submenu;  let i2=index">
                                            <label class="form-check-label" for="ContentPlaceHolder1_cblDashboard_0">
                                              <input id="st{{ submenuname.menuid }}" type="radio" name="st{{ employeels.menuid}}" (change)="onchange(employeels.clname, submenuname.mright)" value="0" [checked]="submenuname.ch">{{
                                                submenuname.menuname }}
                                            </label>
                                        </div>
                                    </div>

                                    <!-- <table id="ContentPlaceHolder1_cblDashboard" class="dash" style="width:100%;">
                                            <tbody>
                                                <tr *ngFor="let submenuname of employeels.submenu;  let i2=index">
                                                    <td><input id="st{{ submenuname.menuid }}" type="radio" name="st{{ employeels.menuid}}" (change)="onchange(employeels.clname, submenuname.mright)" value="0" [checked]="submenuname.ch" style="float:left">
                                                        <label for="ContentPlaceHolder1_cblDashboard_0" style="float:left; width:90%;margin-left: 2%;margin-top: -5px;">{{
                            submenuname.menuname }}</label></td>
                                                </tr>
    
                                            </tbody>
                                        </table> -->

                                </div>
                            </div></ng-container>
                        </div>


                    </div>
                </div>



                <!-- <div class="col-md-6 col-lg-6 col-xl-4" *ngFor="let employeels of menulist;  let i=index">
                    <div class="mini-stat clearfix bg-white">
                        <span class="mini-stat-icon bg-purple mr-0 float-right"><i class="{{employeels.classname}}"></i></span>
                        <div class="mini-stat-info">
                            <span class="counter text-purple">{{ employeels.menuname }}</span>
    
                        </div>
                        <div class="clearfix"></div>
                        <table id="ContentPlaceHolder1_cblDashboard" class="dash" style="width:100%;">
                            <tbody>
                                <tr *ngFor="let submenuname of employeels.submenu;  let i2=index">
                                    <td><input id="st{{ submenuname.menuid }}" type="radio" name="st{{ employeels.menuid}}" (change)="onchange(employeels.clname, submenuname.mright)" value="0" [checked]="submenuname.ch" style="float:left">
                                        <label for="ContentPlaceHolder1_cblDashboard_0" style="float:left; width:90%;margin-left: 2%;margin-top: -5px;">{{
              submenuname.menuname }}</label></td>
                                </tr>
    
                            </tbody>
                        </table>
    
                    </div>
                </div> -->
            </div>
        </div>

    </div>
</div>