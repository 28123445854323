"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var EmployeeaithankyouComponent = /** @class */ (function () {
    function EmployeeaithankyouComponent() {
    }
    EmployeeaithankyouComponent.prototype.ngOnInit = function () {
        this.ctype = parseInt(localStorage.getItem('ctype'));
    };
    return EmployeeaithankyouComponent;
}());
exports.EmployeeaithankyouComponent = EmployeeaithankyouComponent;
