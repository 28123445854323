"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var EmployeefeedbackComponent = /** @class */ (function () {
    function EmployeefeedbackComponent() {
    }
    EmployeefeedbackComponent.prototype.ngOnInit = function () {
    };
    return EmployeefeedbackComponent;
}());
exports.EmployeefeedbackComponent = EmployeefeedbackComponent;
