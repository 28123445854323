<app-header></app-header>
<div class="section-wrapper">
    <div class="container">
       
        <div class="tabs" id="tabs">
            <!-- <a [routerLink]="['/admincreditsafe']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to AML, PEPs & Sanction Dashboard</a> -->
            <div class="clearfix"></div>
            <div class="tabs__content">
                <div class="tabs__content__item active-tab">
                    <div class="content">
                      
                        <div class="topheader">
                            <div class="row row-sm topheader">
                                <div class="col-lg-5">
                                    <div>
                                        <app-checkcountshow></app-checkcountshow>                                   
                                    </div>
                                    
                                </div>                             
                                <div class="col-lg-7 text-right">
                                    <div class="dflexflex mb-mm justify-content-end align-items-center">
                                        <button type="button" class="btn com_btn btn-success" (click)="amllogredirect()">
                                            <span class="lnr lnr-history pr-1"></span> View AML, PEPs & Sanction History
                                          </button>
                                                                
                                    </div>                             
                                </div> 
                                <!-- <div class="col-lg-7 text-right">
                                    <div class="dflexflex mb-mm justify-content-end align-items-center">
                                        <a class="card_box_lit" [routerLink]="['/check-business-aml-peps-sanction']">
                                            <button type="button" class="btn com_btn btn-success">Business AML, PEPs & Sanction
                                            </button>
                                        </a>                     
                                    </div>                             
                                </div>  -->
                                                              
                            </div>
                            <div class="row">
                                <div class="col-sm-12 aligncenter mb-3 mt-3">
                                    <h5 class="hedingcolor">Personal Details </h5>                                 
                                 </div>                               
                            </div>
                         
                        <!-- (ngSubmit)="onSubmit(bussinessForm.value,'Master')" -->

                        <div class="personal_form" *ngIf="result==false">
                            <form [formGroup]="personalformGroup" #bussinessForm="ngForm" class="s12 white">
                                <input type="hidden" formControlName="Threshold" name="Threshold" id="Threshold" class="form-control" aria-describedby="emailHelp" placeholder="Business Name" [ngClass]="{ 'is-invalid': submitted && f.Threshold.errors }" [(ngModel)]="businessDetail.Threshold"
                                />

                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">First Name*</label>
                                            <input type="text" formControlName="Forename" name="Forename" id="Forename" class="form-control" aria-describedby="emailHelp" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.Forename.errors }" [(ngModel)]="businessDetail.Forename">
                                            <div *ngIf="submitted && f.Forename.errors" class="invalid-feedback">
                                                <div *ngIf="f.Forename.errors.required">Please enter First name</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Middle Name</label>
                                            <input type="text" formControlName="Middlename" name="Middlename" id="Middlename" class="form-control" aria-describedby="emailHelp" placeholder="Middle Name" [ngClass]="{ 'is-invalid': submitted && f.Middlename.errors }" [(ngModel)]="businessDetail.Middlename">


                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Last Name</label>
                                            <input type="text" formControlName="Surname" name="Surname" id="Surname" class="form-control" aria-describedby="emailHelp" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.Surname.errors }" [(ngModel)]="businessDetail.Surname">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Date of Birth</label>

                                            <mat-form-field class="datecontrol" [ngClass]="{ 'is-invalid': submitted && f.DateOfBirth.errors }">
                                                <input matInput [matDatepicker]="picker1" name="DateOfBirth" id="DateOfBirth" formControlName="DateOfBirth" class="form-control"  placeholder="Date Of Birth"  [(ngModel)]="businessDetail.DateOfBirth">
                                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                            </mat-form-field>
                                            

                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Year of Birth</label>
                                            <input type="text" formControlName="YearOfBirth" name="YearOfBirth" id="YearOfBirth" class="form-control" aria-describedby="emailHelp" placeholder="Year of Birth" [ngClass]="{ 'is-invalid': submitted && f.YearOfBirth.errors }" [(ngModel)]="businessDetail.YearOfBirth">
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Town/City</label>
                                            <input type="text" formControlName="City" name="City" id="City" class="form-control" aria-describedby="emailHelp" placeholder="Town / City" [ngClass]="{ 'is-invalid': submitted && f.City.errors }" [(ngModel)]="businessDetail.City">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">County/State</label>
                                            <input type="text" formControlName="County" name="County" id="County" class="form-control" aria-describedby="emailHelp" placeholder="County / State" [ngClass]="{ 'is-invalid': submitted && f.County.errors }" [(ngModel)]="businessDetail.County">
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Post / Zip Code</label>
                                            <input type="text" formControlName="Postcode" name="Postcode" id="Postcode" class="form-control" aria-describedby="emailHelp" placeholder="Post / Zip Code" [ngClass]="{ 'is-invalid': submitted && f.Postcode.errors }" [(ngModel)]="businessDetail.Postcode">
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Country*</label>
                                            <select class="form-control" formControlName="Country" [ngClass]="{ 'is-invalid': submitted && f.Country.errors }" [(ngModel)]="businessDetail.Country" name="Country" id="Country" placeholder="Select Country">
                                          <option [value]="defaultVal">Select Country</option>
                                          <option *ngFor="let cmp of countrylist" [value]="cmp.name">
                                            {{ cmp.name }} </option>
                                           
                                        </select>
                                        <div *ngIf="submitted && f.Country.errors" class="invalid-feedback">
                                            <div *ngIf="f.Country.errors.required">Please select country</div>
                                        </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="row radio_section">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1"><b>Datasets to search</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="checkbox_credit">Select All
                                    <input type="checkbox" [checked]="checkedAll" (change)="selectall($event.target.checked)" >
                                    <span class="checkmark"></span>
                                  </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit">PEP
                                    <input type="checkbox" formControlName="PEP"  (change)="selectallfalse($event.target.checked)"  name="PEP" id="PEP" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.PEP.errors }" [(ngModel)]="businessDetail.PEP">
                                    <span class="checkmark"></span>
                                  </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Sanction - Current
                                    <input type="checkbox" formControlName="CurrentSanctions"  (change)="selectallfalse($event.target.checked)"  name="CurrentSanctions" id="CurrentSanctions" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.CurrentSanctions.errors }" [(ngModel)]="businessDetail.CurrentSanctions" >
                                    <span class="checkmark"></span>
                                  </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Sanction - Previous
                                    <input type="checkbox" formControlName="PreviousSanctions"  (change)="selectallfalse($event.target.checked)"  name="PreviousSanctions" id="PreviousSanctions" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.PreviousSanctions.errors }" [(ngModel)]="businessDetail.PreviousSanctions">
                                    <span class="checkmark"></span>
                                  </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Financial Regulator
                                    <input type="checkbox" formControlName="FinancialRegulator"  (change)="selectallfalse($event.target.checked)"  name="FinancialRegulator" id="FinancialRegulator" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.FinancialRegulator.errors }" [(ngModel)]="businessDetail.FinancialRegulator">
                                    <span class="checkmark"></span>
                                  </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Law Enforcement
                                    <input type="checkbox" formControlName="LawEnforcement"   (change)="selectallfalse($event.target.checked)" name="LawEnforcement" id="LawEnforcement" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.LawEnforcement.errors }" [(ngModel)]="businessDetail.LawEnforcement">
                                    <span class="checkmark"></span>
                                  </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit">  Disqualified Director (UK Only)
                                    <input type="checkbox" formControlName="DisqualifiedDirector"  (change)="selectallfalse($event.target.checked)"  name="DisqualifiedDirector" id="DisqualifiedDirector" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.DisqualifiedDirector.errors }" [(ngModel)]="businessDetail.DisqualifiedDirector">
                                    <span class="checkmark"></span>
                                  </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit">   Insolvency (UK & Ireland)
                                    <input type="checkbox" formControlName="Insolvency" name="Insolvency"  (change)="selectallfalse($event.target.checked)"  id="Insolvency" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.Insolvency.errors }" [(ngModel)]="businessDetail.Insolvency">
                                    <span class="checkmark"></span>
                                  </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Adverse Media
                                    <input type="checkbox" formControlName="AdverseMedia" name="AdverseMedia"  (change)="selectallfalse($event.target.checked)"  id="AdverseMedia" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.AdverseMedia.errors }" [(ngModel)]="businessDetail.AdverseMedia">
                                    <span class="checkmark"></span>
                                  </label>

                                    </div>
                                </div>

                                <div class="row radio_section" *ngIf="error ==true">
                                    <div class="col-sm-12 text-right invalid-feedback">
                                        <label class="error" >Please select at least one dataset</label>
                                    </div>
                                </div>
                                <hr/>
                                <div class="row">
                                    <div class="col-sm-6 text-left">
                                        <span *ngIf="lesssore">Sorry, You don't have any credits. Please  <a [routerLink]="['/check-add-credit']">Click Here</a> to add more.</span>
                                    </div>
                                    <div class="col-sm-6 text-right">
                                        <input type="submit"  class="btn com_btn btn-primary" (click)="onSubmit(bussinessForm.value,'first')" [disabled]="!disableBtn" *ngIf="!lesssore" name="updateform" value="{{buttoninsert}}" />

                                        <button type="button" class="btn com_btn btn-secondary" (click)="clearpersonal()">Clear</button>
                                    </div>
                                </div>





                                <jw-modal id="pdf-payment-confirmation" class="modal sendrequestcharge" role="dialog">

                                    <div role="document" class="formgroup">
                                 
                                        <div class="modal-content">

                                 
                                            <div class="modal-body text-center">
                                 
                                                <div class="container">
                                 
                                 
                                 
                                                    <div class="row">
                                 
                                                        <div class="col-lg-12 spaceset">
                                                            <div class="check">
                                                                <span class="lnr lnr-checkmark-circle"></span>
                                                            </div>
                                                            <!-- <p class="textinfoma">Per Click Charge <span class="charge">£5</span> </p> -->
                                                            <!-- <p class="text-center short">Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs</p> -->
                                                            <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
                                                            <p class="">{{errormsg}}</p>
                                 
                                                        </div>
                                                        <div class="text-center m-auto">
                                                            <button type="submit"  class="btn com_btn btn-primary" (click)="onSubmit(bussinessForm.value,'payment')"  [disabled]="!disableBtn" *ngIf="lesssore == false"   name="updateform" value="{{buttoninsert}}" > Confirm</button>
                                                            <button type="submit"  class="btn com_btn btn-secondary"(click)="closeModal('pdf-payment-confirmation');" > Cancel</button>
                                                        </div>
                                                       
                                 
                                 
                                                    </div>
                                 
                                 
                                 
                                 
                                 
                                                </div>
                                 
                                            </div>
                                 
                                           
                                 
                                        </div>
                                 
                                    </div>
                                 
                                 </jw-modal>



                            </form>
                           
                        </div>



                        <div class="credit_data_show" *ngIf="result==true">
                            <div class="job_list_section visa_request_section" *ngFor="let personal of searchresult.matches">
                                <div class="job_detils_date width1">
                                    <div class="visa_user_pic">
                                        <img src="{{ personal.person.imageURL }}" class="img width100" *ngIf="personal.person.imageURL != ''">
                                        <img src="../../../../../assets/img/user_pic_emp.png" class="img width100" *ngIf="personal.person.imageURL == ''">
                                    </div>
                                </div>
                                <div class="job_detils_date width13">
                                    <div class="visa_user_details ">
                                        <h4>{{ personal.person.forename}} {{ personal.person.middlename}} {{ personal.person.surname}}</h4>
                                        <p class="phone text-success"> <b>{{ personal.person.gender}}</b></p>
                                        <p class="ninumber text-primary">Id - {{ personal.person.id}} </p>
                                        <span class="badge badge-danger">{{ personal.person.nationality.nationality}}</span>
                                    </div>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">Data Set</p>
                                    {{dataset(personal)}}
                                    <p class="job_profile">{{datasetlist}}</p>
                                </div>
                                <div class="job_detils_date text-center">
                                    <p class="job_address">PEP Level</p>
                                    <span class="badge badge-success">{{personal.person.pepLevel}}</span>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">Date of Birth</p>
                                    <p class="job_profile">{{ personal.person.dateOfBirth}}</p>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">Nationality</p>
                                    <p class="job_profile text-danger"><b>{{ personal.person.nationality.nationality}}</b></p>
                                </div>
                                <div class="job_detils_date aligncenter">

                                    <div id="container" class="green_text">
                                        <div class="dot"></div>
                                        <div class="pulse"></div>

                                    </div>
                                    <p class="job_profile green_text">{{ personal.score}} </p>


                                </div>
                                <div class="job_detils_date width7">

                                    <p class="job_address">
                                        <button type="submit" class="btn btn-primary com_btn" (click)="openModal('personal-pdf-viewer-visa',personal)"><i class="fa fa-file-image-o"></i></button>
                                        <!-- <button type="submit" class="btn btn-danger com_btn" ><i class="fa fa-pencil"></i></button>
                                      <button type="submit" class="btn btn-secondary com_btn"><i class="fa fa-trash-o"></i></button> -->
                                    </p>
                                </div>



                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<jw-modal id="personal-pdf-viewer-visa" class="modal" role="dialog">
    <div role="document" class="formgroup" *ngIf="bussinessdetails">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{ bussinessdetails.person.forename}} {{ bussinessdetails.person.middlename}} {{ bussinessdetails.person.surname}}
                </h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('personal-pdf-viewer-visa');">
        <span aria-hidden="true">&times;</span>
      </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-2">

                            <img src="{{ bussinessdetails.person.imageURL }}" class="img width100" *ngIf="bussinessdetails.person.imageURL != ''">
                            <img src="../../../../../assets/img/user_pic_emp.png" class="img width100" *ngIf="bussinessdetails.person.imageURL == ''">

                        </div>
                        <div class="col-lg-10  d-flex">
                            <div class="category justify-content-between align-items-center text-center d-flex width100">
                                <div class="catog_box">
                                    <p>PEP</p>
                                    <span>34</span>
                                </div>
                                <div class="catog_box">
                                    <p>Law Enforcement</p>
                                    <span>1</span>
                                </div>
                                <div class="catog_box">
                                    <p>Adverse Media</p>
                                    <span>11</span>
                                </div>
                                <div class="catog_box">
                                    <p>IDV</p>
                                    <span>9</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <hr/>
                    <div class="all_detials">
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">
                                    <div class="catog_box">
                                        <p>Name</p>
                                        <span> {{ bussinessdetails.person.forename}} {{ bussinessdetails.person.middlename}} {{ bussinessdetails.person.surname}}</span>
                                    </div>
                                    <div class="catog_box">
                                        <p>Alias</p>
                                        <p *ngFor="let companyaliases of bussinessdetails.person.aliases;">
                                            {{companyaliases.forename}} {{companyaliases.middlename}} {{companyaliases.surname}} </p>
                                    </div>
                                    <div class="catog_box">
                                        <p>Date of Birth</p>
                                        <span>{{bussinessdetails.person.dateOfBirth}}</span>
                                    </div>
                                    <div class="catog_box">
                                        <p>Nationality</p>
                                        <span>{{bussinessdetails.person.nationality.nationality}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">
                                    <div class="catog_box width25">
                                        <p>Gender</p>
                                        <span>{{bussinessdetails.gender}}</span>
                                    </div>
                                    <div class="catog_box width50">
                                        <p>Telephone</p>
                                        <span>{{bussinessdetails.person.telephoneNumber}} (Home) {{bussinessdetails.person.mobileNumber}} (Mob)</span>
                                    </div>
                                    <div class="catog_box width25">
                                        <p>PEP Level</p>
                                        <span>1</span>
                                    </div>


                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">


                                    <div class="catog_box width50">
                                        <p>Address(es)</p>
                                        <p *ngFor="let companyaddress of bussinessdetails.person.addresses;">
                                            {{companyaddress.address1}} {{companyaddress.address2}} {{companyaddress.address3}} {{companyaddress.address4}}, {{ companyaddress.city }} {{ companyaddress.county }} {{ companyaddress.country.name }}- {{ companyaddress.postcode}}
                                        </p>

                                    </div>
                                    <div class="catog_box width50">
                                        <p>Note</p>
                                        <span>
                                            <div *ngFor="let personnotes of bussinessdetails.person.notes;">
                                          <ul>
                                              <li>{{ personnotes.datasource.name}}</li>
                                              <li>{{ personnotes.text}}</li>
                                          </ul>
                                        </div>

                                      </span>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">


                                    <div class="catog_box">
                                        <p>Political Positions</p>

                                        <div *ngFor="let politic of bussinessdetails.person.politicalPositions;">
                                            {{politic.description}}

                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box">
                                        <span>Reference Id</span>
                                    </div>
                                    <div class="catog_box">
                                        <span>{{ bussinessdetails.id}}</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary com_btn"> Remove From Review List</button>
                <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">         
            <div class="modal-body">
                <div class="container text-center">
                  <div class="info_mess">
                      <div class="para">
                              <h4 class="mb-2">No credits available</h4>
                              <p class="mb-0 font20">There are no credits available to perform this action. Please add more credits.</p>
  
                          </div>                          
                      </div>
                </div>
            </div>
            <div class="modal-footer text-center">   
              <input type="button" class="btn btn-primary com_btn " name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
              <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
              <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
            </div>
        </div>
    </div>
  </jw-modal>