"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var customer_service_1 = require("../../services/customer.service");
var working_model_1 = require("../../models/employment/working.model");
var employee_service_1 = require("../../services/employee.service");
var EmploymentComponent = /** @class */ (function () {
    function EmploymentComponent(data, employeeService) {
        this.data = data;
        this.employeeService = employeeService;
        this.emp = new working_model_1.Working();
    }
    EmploymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        this.empltype = localStorage.getItem('emptype');
        var data = { 'userid': this.Userid };
        this.employeeService.getOtherInfo(data).subscribe(function (data) {
            _this.otherDetail = data;
        });
        this.empltype = localStorage.getItem('emptype');
        this.data.getEmployment().subscribe(function (data) {
            _this.emp = data;
        });
    };
    return EmploymentComponent;
}());
exports.EmploymentComponent = EmploymentComponent;
// @Component({
//   selector: 'app-employment',
//   templateUrl: './employment.component.html',
//   styleUrls: ['./employment.component.css']
// })
// export class EmploymentComponent implements OnInit {
//   title = 'rajai';
//   users: any [];
//   constructor(private data: CustomerService) { }
//   ngOnInit() {
//    const id = localStorage.getItem('userToken');
//     this.data.getEmployment(id).subscribe((data: any) => {
//       this.users = data.profile;
//       // this.users = this.users;
//     }
//     );
//   }
//   openModalDialog() {
//     const id = localStorage.getItem('userToken');
//     this.data.getEmployment(id).subscribe((data: any) => {
//       this.users = data.profile;
//       // this.users = this.users;
//     }
//     );
//     // this.display = 'block';
//   }
// }
