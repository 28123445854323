<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper p-0">
    <div class="admin_dashboard_show">                           
      <div class="ceee">
          <div class="cardmenu">
          
              <a class="card_box_lit" [routerLink]="['/ai-visa-list']"  >
                <div class="samebox">
                  <i class="lnr lnr-license"></i>
                  <div class="menu_content">
                      <h4>Visa</h4>
                      <!-- <span>lorem ipsum lorem ipsum</span> -->
                  </div>
                </div>                  
              </a>
           
         
              <a class="card_box_lit" [routerLink]="['/ai-passport-list']"  >
                <div class="samebox">
                  <i class="lnr lnr-earth"></i>
                  <div class="menu_content">
                      <h4>Passport</h4>
                      <!-- <span>lorem ipsum lorem ipsum</span> -->
                  </div>
                </div>                  
              </a>
           
            
              <a class="card_box_lit" [routerLink]="['/ai-id-proof-list']"  >
                <div class="samebox">
                  <i class="lnr lnr-picture"></i>
                  <div class="menu_content">
                      <h4>ID Proof</h4>
                      <!-- <span>lorem ipsum lorem ipsum</span> -->
                  </div>
                </div>
                 
              </a>
           
           
              <a class="card_box_lit" [routerLink]="['/ai-log-list']" >
                <div class="samebox">
                  <i class="lnr lnr-magic-wand"></i>
                  <div class="menu_content">
                      <h4>AI Logs</h4>
                      <!-- <span>lorem ipsum lorem ipsum</span> -->
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
  </div>
  </div>
  
<!-- <div class="dashboard-wrapper">
   

   <a  [routerLink]="['/ai-visa-list']"  class="homeset">
   <div class="dashboard-box">
    <h2 >Visa</h2>
      <p class="dash-circle" style="color:#fff;">
        <i class="fa fa-pencil" ></i>
      </p>
      <p class="dash-btm "><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
    
    </div></a> 

    <a  [routerLink]="['/ai-passport-list']"  class="homeset">
     <div class="dashboard-box">
        <h2>Passport</h2>
        <p class="dash-circle"><i class="fa fa-gbp"></i></p>
        <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
       </div>
      </a>
      <a  [routerLink]="['/ai-id-proof-list']"  class="homeset">
       <div class="dashboard-box">
          <h2>ID Proof</h2>
          <p class="dash-circle"><i class="fa fa-list-ul" ></i></p>
          <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
         </div>
        </a>
        <a  [routerLink]="['/ai-log-list']"  class="homeset">
          <div class="dashboard-box">
             <h2>AI Logs</h2>
             <p class="dash-circle"><i class="fa fa-list-ul" ></i></p>
             <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
            </div>
           </a>
      
  </div> 
</div>-->
