import { Injectable } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private commonService: CommonService) { }

  createCustomer(data): Observable<any> 
  {
    return this.commonService.formpost('/createcustomer', data);   
  }

  subscriptionsuccess(data): Observable<any> 
  {
    return this.commonService.formpost('/subscriptionsuccess', data);   
  }



  upgradeuserpaymentByCustomer(data): Observable<any> 
  {
    return this.commonService.formpost('/check/upgradeuserpaymentByCustomer', data);   
  }

  

  upgradeuserpaymentBysuccess(data): Observable<any> 
  {
    return this.commonService.formpost('/check/subscribepackagebysuccess', data);   
  }

  


  customerCharge(data): Observable<any> 
  {
    return this.commonService.formpost('/createcustomercharge', data);   
  }

  createSubscription(data): Observable<any>
  {
    return this.commonService.formpost('/createcustomersubscription', data);
  }

  getTotalCards(): Observable<any>
  {
    return this.commonService.formpostOnlyid('/gettotalcards');
  }

  removeCard(data): Observable<any>
  {
    return this.commonService.formpost('/removecard', data);
  }

  addnewCard(data): Observable<any>
  {
    return this.commonService.formpost('/addnewcard', data);
  }

  updateCard(data): Observable<any>
  {
    return this.commonService.formpost('/updatecard', data);
  }
  defaultCardset(data): Observable<any>
  {
    return this.commonService.formpost('/changedefaultcard', data);
  }

}
