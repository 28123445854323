"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Address = /** @class */ (function () {
    function Address() {
        this.movedin = new Date();
        this.mobilenumber = "";
        this.phonenumber = "";
        this.movedout = "";
    }
    return Address;
}());
exports.Address = Address;
