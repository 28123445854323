import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class WorklistService {

  constructor(private commonService: CommonService) { }

  getemployeeworklist(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/activity'); 
  }

  getemployeeworklistreport(data): Observable<any> {
    return this.commonService.formpost('/employee/activityreport',data); 
  }

  getemployeeworklistreportrequest(data): Observable<any> {
    return this.commonService.formpost('/employee/activityreportrequest',data); 
  }

  getemployeeworklistreportrequestbyadmin(data): Observable<any> {
    return this.commonService.formpost('/employee/activityreportrequestbyadmin',data); 
  }
  
  settinghourtypeUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/hourtypeupdatebyadmin', value);
  }

  gethourtypelist(data): Observable<any> {
    return this.commonService.formpost('/hourtype',data);
  }

  getprojectlist(data): Observable<any> {
    return this.commonService.formpost('/projectlist',data);
  }

  getlocationlist(): Observable<any> {
    return this.commonService.formpostOnlyid('/companylocationtype');
  }
  
  activityapplyupdate(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/apply-hour',value);
  }

  getactivitydata(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/hourbyid',value);
  }

  getactivedatabydate(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/activitylistbydate',value);
  }

  deleteactivity(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/activitydelete',value);
  }

  statusactivity(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/activitystatus',value);
  }

  changeAllActivityStatus(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/changeallactivitystatus',value);
  }

  hourtypeUpdateChangeStatus(value:any): Observable<any> 
  {
    return this.commonService.formpost('/company/hourtypeupdatechangestatus',value);
  }

}
