"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var MyidproofService = /** @class */ (function () {
    function MyidproofService(commonService) {
        this.commonService = commonService;
        this.modals = [];
    }
    MyidproofService.prototype.getidproofAllList = function (value) {
        return this.commonService.formpost('/employee/employeeidprooflist', value);
    };
    MyidproofService.prototype.getidproofAllListbyadmin = function (value) {
        return this.commonService.formpost('/employee/employeeidprooflistbyadmin', value);
    };
    MyidproofService.prototype.getallidproofListbyadmin = function (value) {
        return this.commonService.formpost('/employee/allemployeeidprooflistbyadmin', value);
    };
    MyidproofService.prototype.getaiapprovereject = function (value) {
        return this.commonService.formpost('/company/allailogforsuperadmin', value);
    };
    MyidproofService.prototype.downloadDrivingLicence = function (value) {
        return this.commonService.formpost('/employee/downloaddrivinglicence', value);
    };
    MyidproofService.prototype.getidproofAllApprove = function () {
        return this.commonService.formpostOnlyid('/employee/employeeidproofpprove');
    };
    MyidproofService.prototype.getidproofAllRejected = function () {
        return this.commonService.formpostOnlyid('/employee/employeeidproofrejected');
    };
    MyidproofService.prototype.deleteidproof = function (value) {
        return this.commonService.formpost('/employee/idproofdelete', value);
    };
    MyidproofService.prototype.myidproofdetailsUpdate = function (value) {
        return this.commonService.formpost('/employee/idproofupdate', value);
    };
    MyidproofService.prototype.myidproofdetailsUpdaterighttowork = function (value) {
        return this.commonService.refereeformpost('/employee/idproofupdaterighttowork', value);
    };
    MyidproofService.prototype.myidproofdetailsUpdatebyadmin = function (value) {
        return this.commonService.formpost('/employee/idproofupdatebyadmin', value);
    };
    MyidproofService.prototype.getidproofdetailsidbyadmin = function (value) {
        return this.commonService.formpost('/employee/employeeidproofdetailsbyadmin', value);
    };
    MyidproofService.prototype.idproofdetailsbyidproofid = function (value) {
        return this.commonService.formpost('/employee/idproofdetailsbyidproofid', value);
    };
    MyidproofService.prototype.idproofupdateForAI = function (value) {
        return this.commonService.formpost('/employee/idproofupdateAIDetailsbyadmin', value);
    };
    MyidproofService.prototype.idproofaipaymentstaus = function (value) {
        return this.commonService.formpost('/employee/checkidproofaipaymentstatus', value);
    };
    MyidproofService.prototype.iddocumenttype = function () {
        return this.commonService.formpostwithouttoken('/company/documenttype', { id: 1 });
    };
    MyidproofService.ngInjectableDef = i0.defineInjectable({ factory: function MyidproofService_Factory() { return new MyidproofService(i0.inject(i1.CommonService)); }, token: MyidproofService, providedIn: "root" });
    return MyidproofService;
}());
exports.MyidproofService = MyidproofService;
