"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var GraphreportService = /** @class */ (function () {
    function GraphreportService(commonService) {
        this.commonService = commonService;
    }
    GraphreportService.prototype.getdepartmentgraphList = function () {
        return this.commonService.formpostOnlyid('/graph/companydepartmentgraphreport');
    };
    GraphreportService.prototype.getemployeeagetotal = function () {
        return this.commonService.formpostOnlyid('/graph/companyagereport');
    };
    GraphreportService.prototype.getleavegraphList = function (data) {
        return this.commonService.formpost('/graph/leavegraphreport', data);
    };
    GraphreportService.prototype.getemployeeleavegraph = function (data) {
        return this.commonService.formpost('/graph/employeeleavegraphreport', data);
    };
    GraphreportService.prototype.getexpensegraphList = function (data) {
        return this.commonService.formpost('/graph/expensegraphreport', data);
    };
    GraphreportService.prototype.monthlyexpensetypereport = function (data) {
        return this.commonService.formpost('/graph/monthlyexpensegraphreport', data);
    };
    GraphreportService.prototype.gethourgraphList = function (data) {
        return this.commonService.formpost('/graph/timesheetgraphreport', data);
    };
    GraphreportService.prototype.monthlyhourtypereport = function (data) {
        return this.commonService.formpost('/graph/monthlytimesheetgraphreport', data);
    };
    GraphreportService.ngInjectableDef = i0.defineInjectable({ factory: function GraphreportService_Factory() { return new GraphreportService(i0.inject(i1.CommonService)); }, token: GraphreportService, providedIn: "root" });
    return GraphreportService;
}());
exports.GraphreportService = GraphreportService;
