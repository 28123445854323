"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../_services/modal.service");
var common_service_1 = require("../../services/common.service");
var country_service_1 = require("../../services/country.service");
var job_service_1 = require("../../services/job.service");
var forms_1 = require("@angular/forms");
var jobs_model_1 = require("../../models/jobs/jobs.model");
var ClassicEditor = require("@ckeditor/ckeditor5-build-classic");
var platform_browser_1 = require("@angular/platform-browser");
var upload_file_service_1 = require("./../../services/upload-file.service");
var country_model_1 = require("./../../models/address/country.model");
var Soc_model_1 = require("./../../models/jobs/Soc.model");
var router_1 = require("@angular/router");
// import {paginate} from 'jw-paginate';
// import * as paginate from 'jw-paginate';
// import  paginate = require('jw-paginate');
var JobsComponent = /** @class */ (function () {
    function JobsComponent(sanitizer, commonService, modalService, job, formBuilder, country, router, uploadService) {
        this.sanitizer = sanitizer;
        this.commonService = commonService;
        this.modalService = modalService;
        this.job = job;
        this.formBuilder = formBuilder;
        this.country = country;
        this.router = router;
        this.uploadService = uploadService;
        this.changePage = new core_1.EventEmitter(true);
        this.initialPage = 1;
        this.pageSize = 30;
        this.maxPages = 10;
        this.pager = {};
        this.mainawaiting = 0;
        this.mainreview = 0;
        this.maincon = 0;
        this.mainhire = 0;
        this.displayedColumns = ['jobtittle'];
        this.jobDetail = new jobs_model_1.Jobs();
        this.Editor = ClassicEditor;
        this.options = [];
        this.submitted = false;
        this.screen = new jobs_model_1.Screen();
        this.dateToday = new Date();
        this.defaultVal = "";
        this.jobst = false;
    }
    JobsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.createformgroup();
        this.jobDetail = new jobs_model_1.Jobs();
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.jobperm = this.permission.m7;
        this.mobilecheck = '';
        this.phonecheck = '';
        this.jobDetail.country = new country_model_1.Country();
        this.screen = new jobs_model_1.Screen();
        this.jobDetail.soc = new Soc_model_1.Soc();
        this.br1 = 'Choose file';
        this.br2 = 'Choose file';
        this.br3 = 'Choose file';
        this.br4 = 'Choose file';
        this.jobscreenformGroup = this.formBuilder.group({
            id: [''],
            jobmasterid: ['', forms_1.Validators.required],
            screenshotdayid: ['', forms_1.Validators.required],
            joburl1: [''],
            joburl2: [''],
            joburl3: [''],
            joburl4: ['']
        });
        this.buttoninsert = "Add New Job";
        this.country.getsoclist().subscribe(function (socdata) {
            _this.socdata = socdata;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        var countrydata = { 'id': this.jobDetail.countryid };
        this.country.getstatelist(countrydata).subscribe(function (state) {
            _this.countylist = state;
        });
        this.Userid = localStorage.getItem('userid');
        this.job.getjobList().subscribe(function (data) {
            _this.record = data.length;
            _this.datalist = data;
            _this.pageOfItems = data;
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.items = data;
            }
        });
    };
    JobsComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    JobsComponent.prototype.updatestatus = function (status) {
        if (status == 1) {
            this.mainawaiting = this.mainawaiting + 1;
        }
        else if (status == 2) {
            this.mainreview = this.mainreview + 1;
        }
        else if (status == 5) {
            this.maincon = this.maincon + 1;
        }
        else if (status == 7) {
            this.mainhire = this.mainhire + 1;
        }
    };
    JobsComponent.prototype.resetall = function () {
        this.mainawaiting = 0;
        this.mainhire = 0;
        this.maincon = 0;
        this.mainreview = 0;
    };
    JobsComponent.prototype.createformgroup = function () {
        this.jobformGroup = this.formBuilder.group({
            jobmasterid: [''],
            urldocumentid: [''],
            jobtittle: ['', forms_1.Validators.required],
            jobdate: ['', forms_1.Validators.required],
            closedate: ['', forms_1.Validators.required],
            contactname: ['', forms_1.Validators.required],
            // amountpaid: ['', Validators.compose([Validators.required, Validators.pattern(/^([0-9].[0-9]\d|[1-9]\d*)?$/)])],
            contactrole: ['', forms_1.Validators.required],
            // expensetypeid: ['', Validators.compose([Validators.required, Validators.min(1)])],
            joburl1: [''],
            joburl2: [''],
            joburl3: [''],
            joburl4: [''],
            salary: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.pattern(/^[.\d]+$/), forms_1.Validators.min(1)])],
            maxsalary: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.pattern(/^[.\d]+$/), forms_1.Validators.min(1)])],
            socid: ['', forms_1.Validators.required],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            phonenumber: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            postalcode: ['', forms_1.Validators.required],
            address1: ['', forms_1.Validators.required],
            address2: [''],
            cityname: ['', forms_1.Validators.required],
            countyname: ['', forms_1.Validators.required],
            countryid: ['', forms_1.Validators.required],
            qualification: [''],
            addposition: ['', forms_1.Validators.required],
            stypeid: ['', forms_1.Validators.required],
            jobdescreption: ['']
        });
    };
    Object.defineProperty(JobsComponent.prototype, "f", {
        get: function () { return this.jobformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobsComponent.prototype, "g", {
        get: function () { return this.jobscreenformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    JobsComponent.prototype.onSubmit = function (formjob, id) {
        var _this = this;
        // var formjob = jobformdata.value;
        console.log(this.jobformGroup);
        this.submitted = true;
        if (this.jobformGroup.invalid) {
            return;
        }
        var pinc = formjob.postalcode.split(',');
        formjob.postalcode = pinc[pinc.length - 1];
        formjob.userid = this.Userid;
        if (formjob.joburl1 != '') {
            formjob.file1 = this.file1;
        }
        if (formjob.joburl2 != '') {
            formjob.file2 = this.file2;
        }
        if (formjob.joburl3 != '') {
            formjob.file3 = this.file3;
        }
        if (formjob.joburl4 != '') {
            formjob.file4 = this.file4;
        }
        // console.log(jobformdata);
        this.disableBtn = false;
        var sd = new Date(formjob.jobdate);
        formjob.jobdate = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        var ed = new Date(formjob.closedate);
        formjob.closedate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        this.job.jobdetailsUpdate(formjob).subscribe(function (response) {
            _this.job.getjobList().subscribe(function (data) {
                // jobformdata.reset();
                _this.record = data.length;
                _this.jobDetail.countryid = 70;
                _this.submitted = false;
                _this.datalist = data;
                if (_this.record < 30) {
                    _this.pageOfItems = data;
                }
                else {
                    _this.items = data;
                }
                _this.modalService.close(id);
            });
        });
    };
    JobsComponent.prototype.addJobScreen = function (jobscreenshotformdata, id) {
        var _this = this;
        this.submitted = true;
        if (this.jobscreenformGroup.invalid) {
            return;
        }
        if (jobscreenshotformdata.joburl1 != '') {
            jobscreenshotformdata.file1 = this.file1;
        }
        if (jobscreenshotformdata.joburl2 != '') {
            jobscreenshotformdata.file2 = this.file2;
        }
        if (jobscreenshotformdata.joburl3 != '') {
            jobscreenshotformdata.file3 = this.file3;
        }
        if (jobscreenshotformdata.joburl4 != '') {
            jobscreenshotformdata.file4 = this.file4;
        }
        this.job.jobscreenshotdetailsUpdate(jobscreenshotformdata).subscribe(function (response) {
            _this.job.getjobById({ "id": _this.Userid, "jobid": response.jobmasterid }).subscribe(function (data) {
                _this.jobDetail = data;
                _this.modalService.close(id);
            });
        });
    };
    JobsComponent.prototype.jobstatus = function (datejob) {
        var newDate = new Date(datejob);
        var currentdate = new Date();
        if (currentdate <= newDate) {
            this.jobst = true;
            return true;
        }
        else {
            this.jobst = false;
            return false;
        }
    };
    JobsComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    JobsComponent.prototype.onSearchChange = function (searchValue) {
        var _this = this;
        if (searchValue.length > 4) {
            var newArray = [];
            this.commonService.getAddress(searchValue).subscribe(function (data) {
                var newdata;
                newdata = data;
                newdata.addresses.forEach(function (value) {
                    newArray.push(value + "," + searchValue);
                });
                _this.options = newArray;
            });
        }
        else {
            this.options = [];
        }
    };
    JobsComponent.prototype.displayFn = function (value) {
        if (value != null) {
            var res = value.split(",");
            if (res.length > 6) {
                return res[7];
            }
            else {
                return value;
            }
        }
    };
    JobsComponent.prototype.updatecode = function (evt, response) {
        res = [];
        if (evt.source.selected) {
            var res = response.split(",");
            this.jobDetail.address1 = res[0];
            this.jobDetail.address2 = res[1];
            this.jobDetail.cityname = res[5];
            this.jobDetail.postalcode = res[7];
            this.jobDetail.countyname = res[6];
            // const data = { 'id': this.jobDetail.countryid };
            // this.country.getstatelist(data).subscribe((state: any) => {
            //   for (var i = 0; i < state.length; i++) {
            //     if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
            //       var inde = state[i]['stateid'];
            //     }
            //   }
            //   this.jobDetail.countyid = inde;
            //   this.countylist = state;
            // });
        }
    };
    JobsComponent.prototype.transform = function (html) {
        return this.sanitizer.bypassSecurityTrustStyle(html);
    };
    JobsComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    JobsComponent.prototype.selectFile = function (event, field) {
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        var file = this.selectedFiles;
        var imagepath = this.uploadService.uploadfile(file);
        if (field == 'file1') {
            this.file1 = imagepath;
            this.br1 = 'Choose file (1)';
        }
        if (field == 'file2') {
            this.file2 = imagepath;
            this.br2 = 'Choose file (1)';
        }
        if (field == 'file3') {
            this.file3 = imagepath;
            this.br3 = 'Choose file (1)';
        }
        if (field == 'file4') {
            this.file4 = imagepath;
            this.br4 = 'Choose file (1)';
        }
    };
    JobsComponent.prototype.openModal = function (jobid, id) {
        var _this = this;
        this.buttoninsert = "Update";
        this.disableBtn = true;
        this.jobDetail = new jobs_model_1.Jobs();
        this.job.getjobById({ "id": this.Userid, "jobid": jobid }).subscribe(function (data) {
            _this.jobDetail = data;
            // console.log(this.jobDetail);
            // this.jobDetail.countryid = '';
            data.screen.forEach(function (element) {
                if (element.screenshotdayid == '1') {
                    _this.jobDetail.urldocumentid = element.id;
                }
            });
            _this.modalService.open(id);
            // this.jobDetail.jobdescreption =  this.transform(data.jobdescreption);
        });
    };
    JobsComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        // this.createformgroup();
        // console.log(this.submitted);
        this.disableBtn = true;
        this.buttoninsert = "Add New Job";
        this.jobDetail = new jobs_model_1.Jobs();
        // console.log(this.jobDetail);
        // this.jobDetail.jobtittle = "";
        // this.jobDetail.jobdate = "";
        // this.jobDetail.closedate = "";
        // this.jobDetail.contactname = "";
        // this.jobDetail.contactrole = ""; 
        // this.jobDetail.phonenumber = "";
        // this.jobDetail.joburl1 = "";
        // this.jobDetail.joburl2 = "";
        // this.jobDetail.joburl3 = "";
        // this.jobDetail.joburl4 = "";
        // this.jobDetail.emailid = "";
        this.jobDetail.countryid = 70;
        // this.jobDetail.jobdescreption = "";
        this.mobilecheck = '';
        this.phonecheck = '';
        this.jobformGroup.reset(this.jobDetail);
        // this.submitted = false;
        this.modalService.open(id);
    };
    JobsComponent.prototype.openModal3 = function (jobid, id) {
        var _this = this;
        this.buttoninsert = "Update Job";
        this.job.getjobById({ "id": this.Userid, "jobid": jobid }).subscribe(function (data) {
            _this.jobDetail = data;
        });
        this.modalService.open(id);
    };
    JobsComponent.prototype.openModal4 = function (screenshotid, id, screenumber) {
        this.buttoninsert = "Submit";
        this.screenshotid = screenshotid;
        this.file1 = "";
        this.file2 = "";
        this.file3 = "";
        this.file4 = "";
        if (screenumber != '') {
            this.disableday = true;
        }
        else {
            this.disableday = false;
        }
        this.screenumber = screenumber;
        this.modalService.open(id);
    };
    JobsComponent.prototype.openModal5 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    JobsComponent.prototype.deleteFile = function (jobid, id, documenturl) {
        var _this = this;
        var deleteData = {};
        if (documenturl == 'document1') {
            deleteData = { 'id': id, 'urldocument1': null };
        }
        else if (documenturl == 'document2') {
            deleteData = { 'id': id, 'urldocument2': null };
        }
        else if (documenturl == 'document3') {
            deleteData = { 'id': id, 'urldocument3': null };
        }
        else if (documenturl == 'document4') {
            deleteData = { 'id': id, 'urldocument4': null };
        }
        if (confirm("Are you sure you want to delete?")) {
            this.job.deleteFile(deleteData).subscribe(function (data) {
                _this.job.getjobById({ "id": _this.Userid, "jobid": jobid }).subscribe(function (data) {
                    _this.jobDetail = data;
                });
            });
        }
    };
    JobsComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return JobsComponent;
}());
exports.JobsComponent = JobsComponent;
