<style>
  .input-file-container {
    position: relative;
    width: 225px;
  }

  .js .input-file-trigger {
    display: block;
    padding: 14px 45px;
    background: #39D2B4;
    color: #fff;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
  }

  .js .input-file {
    position: absolute;
    top: 0;
    left: 0;
    width: 225px;
    opacity: 0;
    padding: 14px 0;
    cursor: pointer;
  }

  .js .input-file:hover+.input-file-trigger,
  .js .input-file:focus+.input-file-trigger,
  .js .input-file-trigger:hover,
  .js .input-file-trigger:focus {
    background: #34495E;
    color: #39D2B4;
  }
</style>

<div class="bg">
<app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
      <div class="tabs" id="tabs">
        <div class="tabs__content">
          <div class="tabs__content__item active-tab">
            <div class="content">
              <app-topmenu class="row"></app-topmenu>
              <div class="row row-sm">
                <div class="col-lg-12">
                  <form [formGroup]="EmployeeForm" #employeeForm="ngForm" class="s12 white"
                    (ngSubmit)="onSubmit(employeeForm.value)">
                    <div class="row row-sm">
                      <div class="form-group col-lg-2">
                        <label>Email</label>
                        <input type="input" formControlName="emailid" class="" name="emailid" id="fname"
                          class="form-control" />
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Phone Number</label>
                        <input type="input" formControlName="phonenumber" class="" name="phonenumber" id="phonenumber"
                          class="form-control" />
                      </div>
                      <div class="form-group col-lg-2">
                        <label>NI Number</label>
                        <input type="input" formControlName="ninumber" class="" name="ninumber" id="ninumber"
                          class="form-control" />
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Min Start Date*</label>
                        <mat-form-field class="datecontrol">
                          <input matInput [matDatepicker]="picker2" id="minstartdate" formControlName="minstartdate"
                            name="minstartdate" />
                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                          <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Max Start Date*</label>
                        <mat-form-field class="datecontrol">
                          <input matInput [matDatepicker]="picker3" id="maxstartdate" formControlName="maxstartdate"
                            name="maxstartdate" />
                          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                          <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Project List</label>
                        <select name="projectid" formControlName="projectid" class="form-control input-lg"
                          id="projectid">
                          <option value="">Select Project</option>
                          <option *ngFor="let ad3 of projectlist" value="{{ ad3.projectid}}">{{ ad3.projectname }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row row-sm">
                      <div class="form-group col-lg-2">
                        <label>Min End Date *</label>
                        <mat-form-field class="datecontrol">
                          <input matInput [matDatepicker]="picker4" id="minenddate" formControlName="minenddate"
                            name="minenddate" />
                          <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                          <mat-datepicker #picker4></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Max End Date*</label>
                        <mat-form-field class="datecontrol">
                          <input matInput [matDatepicker]="picker5" id="maxenddate" formControlName="maxenddate"
                            name="maxenddate" />
                          <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                          <mat-datepicker #picker5></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="form-group col-lg-2">
                        <label>First Name</label>
                        <input type="input" formControlName="fname" class="" name="fname" id="fname"
                          class="form-control" />
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Min Salary</label>
                        <input type="input" formControlName="minsalary" class="" name="minsalary" id="minsalary"
                          class="form-control" />
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Max Salary</label>
                        <input type="input" formControlName="maxsalary" class="" name="maxsalary" id="maxsalary"
                          class="form-control" />
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Client List</label>
                        <select name="clientid" formControlName="clientid" class="form-control input-lg" id="clientid">
                          <option value="">Select Client</option>
                          <option *ngFor="let ad3 of clientlist" value="{{ ad3.clientid}}">{{ ad3.clientname }}</option>
                        </select>
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Current</label>
                        <select name="iscurrent" formControlName="iscurrent" class="form-control input-lg"
                          id="iscurrent">
                          <option value="">All Data</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>

                    <div class="row row-sm">
                      <div class="form-group col-lg-2">
                        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                        <input type="submit" class="btn btn-primary" name="updateform" value="Search" />
                      </div>
                    </div>
                  </form>
                </div><!-- col-8 -->
              </div>

              <div class="row row-sm  mg-b-10">
                <div class="col-lg-12">
                  <a (click)="exportAsXLSX()" class="float-right"><i class="fa fa-file-excel-o"
                      style="font-size:30px;color:blue"></i></a>
                </div>
              </div>

              <div class="row row-sm">
                <div class="col-lg-12">
                  <table class="table table-striped tbreport" [mfData]="data" #mf="mfDataTable" [mfRowsOnPage]="10">
                    <thead>
                      <tr>
                        <th>
                          <mfDefaultSorter by="id">ID</mfDefaultSorter>
                        </th>
                        <th>
                          <mfDefaultSorter by="employee.fname">Name</mfDefaultSorter>
                        </th>
                        <th>
                          <mfDefaultSorter by="wemployee.fname">Line Manager</mfDefaultSorter>
                        </th>
                        <th>
                          <mfDefaultSorter by="client.fname">Client</mfDefaultSorter>
                        </th>
                        <th>
                          <mfDefaultSorter by="project.fname">Project</mfDefaultSorter>
                        </th>
                        <th>
                          <mfDefaultSorter by="salary">Salary</mfDefaultSorter>
                        </th>
                        <th>
                          <mfDefaultSorter by="hoursofworks">Hour of Work</mfDefaultSorter>
                        </th>
                        <th>
                          <mfDefaultSorter by="emprole">Employee Role</mfDefaultSorter>
                        </th>
                        <th>
                          <mfDefaultSorter by="startdate">Start Date</mfDefaultSorter>
                        </th>
                        <th>
                          <mfDefaultSorter by="enddate">End Date</mfDefaultSorter>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let employement of mf.data;  let i=index">
                        <td>{{ i +1 }}</td>
                        <td>
                          <div class="tooltip5"> {{employement.employee.fname}} {{employement.employee.mname}}
                            {{employement.employee.lname}}
                            <div class="tooltiptext">
                              <div class="dsfsadf">
                                <p><b>Email :</b> {{ employement.employee.emailid }}</p>
                                <p><b>Phone :</b> {{ employement.employee.phonenumber }}</p>
                                <p><b>NI Number :</b> {{ employement.employee.ninumber }}</p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div *ngIf="employement.wemployeeid != '0' && employement.wemployeeid != '' ">
                            <div class="tooltip5"> {{employement.wemployee.fname}} {{employement.wemployee.mname}}
                              {{employement.wemployee.lname}}
                              <div class="tooltiptext">
                                <div class="dsfsadf">
                                  <p><b>Email :</b> {{ employement.wemployee.emailid }}</p>
                                  <p><b>Phone :</b> {{ employement.wemployee.phonenumber }}</p>
                                  <p><b>NI Number :</b> {{ employement.wemployee.ninumber }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div *ngIf="employement.clientid != '0' && employement.clientid != '' ">
                            {{employement.client.clientname}}
                          </div>
                        </td>
                        <td>
                          <div *ngIf="employement.projectid != '0' && employement.projectid != '' ">
                            {{employement.project.projectname}}
                          </div>
                        </td>
                        <td>
                          <div class="tooltip5"> {{employement.salary}}
                          </div>
                        </td>
                        <td>{{employement.hoursofworks}}</td>
                        <td>
                          <div class="tooltip5"> {{employement.emprole}}
                          </div>
                        </td>
                        <td>{{employement.startdate | date: 'dd MMM yyyy'}}</td>
                        <td>{{employement.enddate | date: 'dd MMM yyyy'}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="10">
                          <mfBootstrapPaginator [rowsOnPageSet]="[10,15,20]"></mfBootstrapPaginator>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>