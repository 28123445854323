"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../_services/modal.service");
var aml_model_1 = require("../../../models/aml/aml.model");
var aml_service_1 = require("../../../services/aml.service");
var alert_service_1 = require("../../../services/alert.service");
var checkservice_service_1 = require("../../../services/checkservice.service");
var router_1 = require("@angular/router");
var AmlmonitoringComponent = /** @class */ (function () {
    function AmlmonitoringComponent(modalService, amldetails, alerts, router, checkService, activatedRoute) {
        this.modalService = modalService;
        this.amldetails = amldetails;
        this.alerts = alerts;
        this.router = router;
        this.checkService = checkService;
        this.activatedRoute = activatedRoute;
        this.disableBtn = true;
        this.atype = false;
    }
    AmlmonitoringComponent.prototype.ngOnInit = function () {
        var _this = this;
        var masterarray = [];
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        this.checkService.selectrighttoworkbyadmin().subscribe(function (righttoworkadmin) {
            if (righttoworkadmin.success != 'pass') {
                _this.router.navigate(['/check-dashboard']);
            }
        });
        this.pageload();
    };
    AmlmonitoringComponent.prototype.pageload = function () {
        var _this = this;
        this.amldetails.getAmlCompanyDetails().subscribe(function (searchRes) {
            _this.data = searchRes;
            _this.record = _this.data.length;
            _this.datalist = _this.data;
            _this.items = _this.data;
            _this.downloadtext = new Array(searchRes.length);
            for (var i = 0; i < searchRes.length; i++) {
                if (searchRes[i].ismonitoring)
                    _this.downloadtext[i] = 'Remove Monitoring <i class="fa fa-bell-o ml-2"></i>';
                else
                    _this.downloadtext[i] = 'Active Monitoring <i class="fa fa-bell-o ml-2"></i>';
            }
            _this.disableBtn = true;
            _this.searchresult = searchRes; //searchRes;   
        });
    };
    AmlmonitoringComponent.prototype.downloadAdverseCreditHistory = function (historyid, ind) {
        var _this = this;
        this.downloadtext[ind] = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        this.checkService.adversecredithistorydownload({ "id": historyid }).subscribe(function (searchRes) {
            _this.downloadtext[ind] = 'Active <i class="fa fa-bell-o ml-2"></i>';
            window.open(searchRes.response_data.Location, "_blank");
            console.log(searchRes);
        });
    };
    AmlmonitoringComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.downloadtext = new Array(pageOfItems.length);
        for (var i = 0; i < pageOfItems.length; i++) {
            if (pageOfItems[i].ismonitoring)
                this.downloadtext[i] = 'Remove Monitoring <i class="fa fa-bell-o ml-2"></i>';
            else
                this.downloadtext[i] = 'Active Monitoring <i class="fa fa-bell-o ml-2"></i>';
        }
        this.pageOfItems = pageOfItems;
    };
    AmlmonitoringComponent.prototype.outputnewset = function (d) {
        var listmaster = JSON.parse(d);
        this.searchresultnew = new aml_model_1.adverst();
        this.searchresultnew = listmaster;
    };
    AmlmonitoringComponent.prototype.outputnewBussinessset = function (d) {
        var listmaster = JSON.parse(d);
        this.searchresultnew = new aml_model_1.AmlBussiness();
        this.searchresultnew = listmaster;
    };
    AmlmonitoringComponent.prototype.openModal = function (id, companyid) {
        this.companyid = companyid;
        this.modalService.open(id);
    };
    AmlmonitoringComponent.prototype.onActive = function (id) {
        var _this = this;
        this.disableBtn = false;
        var data = { companyid: this.companyid, isstatus: true };
        this.amldetails.postActiveAmlDetails(data).subscribe(function (searchRes) {
            _this.modalService.close(id);
            _this.alerts.success(searchRes.message, false);
            _this.pageload();
        });
    };
    AmlmonitoringComponent.prototype.onDeactive = function (id) {
        var _this = this;
        this.disableBtn = false;
        var data = { companyid: this.companyid, isstatus: false };
        this.amldetails.postActiveAmlDetails(data).subscribe(function (searchRes) {
            _this.modalService.close(id);
            _this.pageload();
            _this.alerts.success(searchRes.message, false);
        });
    };
    AmlmonitoringComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return AmlmonitoringComponent;
}());
exports.AmlmonitoringComponent = AmlmonitoringComponent;
