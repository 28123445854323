import { Wemployee } from './wemployee.model';

export class Working {  
  clientcont: string;
  clientid: string;
  employeeid: any;
  employmentcontract: string;
  employeeworkingdetailsid: string;
  emprole: string;
  enddate: string;
  hoursofworks: string;
  iscurrent: any;
  ispersent: boolean;
  ninumber: string;
  projectid: string;
  rwork: string;
  salary: string;
  startdate: Date = new Date();
  totalExp: string;
  wemployee: Wemployee;
  wemployeeid: string;
  imgsrc: string;
  employeestatus: any;
  hiredate: string;
  terminationdate: string;
  terminationreason: string;
  oftorehire: string;
  regrettermination: string;
  departmentid: any; 
  divisionid: any;
  contracttypeid: string = "";
  flexiworkid: any;
  flexistartdate: any;
  flexienddate: any;
  flexi: Flexi;
}

export class Flexi {
  id: string;
  startdate: string = "";
  enddate: string = "";
}