import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ReferenceService } from '../../services/reference.service';
import { ModalService } from '../../_services/modal.service';
import { ReferenceDetails } from '../../models/reference/ReferenceDetails.model';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { DragulaService } from 'ng2-dragula'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-refconfig',
  templateUrl: './refconfig.component.html',
  styleUrls: ['./refconfig.component.css']
})
export class RefconfigComponent implements OnInit {

  createTemplateFormGroup: FormGroup;
  createquestionforTemplateFormGroup: FormGroup
  options: GlobalConfig;
  questionTemplates: any;
  questionTemplatesdetails: any;
  previousquestionTemplatesdetails: any;
  selectedtemplate: any;
  selectedquestionTemplatesdetail: any;
  submit = false
  activeprofile: number;
  selectedquestionfordeletion: any;
  subs = new Subscription();
  isedittemplate: boolean;
  iseditquestion: boolean;
  hourDetail = new ReferenceDetails();
  disableBtn:any;
  buttoninsert:any;
  submitted: any = false;

  constructor( private modalService: ModalService,
    private formBuilder: FormBuilder,
    private referencingmoduleService: ReferenceService,
    public toastrService: ToastrService,
    private dragulaService: DragulaService) {
    this.options = this.toastrService.toastrConfig;
    this.subs.add(dragulaService.dropModel(this.questionTemplatesdetails)
      .subscribe(({ el, target, source, sourceModel, targetModel, item }) => {       
        this.updateseqforquestions(targetModel)
      })
    );
  }

  updateseqforquestions(targetModel) {
    var count = 0;
    for (let i = 0; i < this.previousquestionTemplatesdetails.length; i++) {

      if (targetModel[i].id != this.previousquestionTemplatesdetails[i].id) {
        count += 1;

        var data = {
          "seq": i + 1
        }

        var updatedata = { seq: i+1, id:targetModel[i].id, templateid: targetModel[i].templateid }

        this.referencingmoduleService.updatequestionrequests(updatedata).subscribe((response1) => {
          //this.questionTemplatesdetails = response1;
          //this.previousquestionTemplatesdetails = response1;
          if (count == 2)
            this.referencingmoduleService.getquestiontemplatedetails(targetModel[0].templateid).subscribe((response1) => {
              this.questionTemplatesdetails = response1;
              this.previousquestionTemplatesdetails = response1;
            });
        });

        // if(count === 1){
        //   firstquestionid = ;
        //   firstquestionseq = i+1;
        // }else{
        //   secondquestiontid = targetModel[i].id;
        //   secondquestionseq = i+1;
        // }
      }

      var updatedata1 = { seq: i+1, id:targetModel[i].id, templateid: targetModel[i].templateid }

      this.referencingmoduleService.updatequestionrequests(updatedata1).subscribe((response1) => {
        this.questionTemplatesdetails = response1;
        this.previousquestionTemplatesdetails = response1;
      })

      // if(count === 1){
      //   firstquestionid = ;
      //   firstquestionseq = i+1;
      // }else{
      //   secondquestiontid = targetModel[i].id;
      //   secondquestionseq = i+1;
      // }

      if (count === 2)
        break;

    }

    //this.previousquestionTemplatesdetails = targetModel;


  }

  ngOnInit() {
    this.loadData();
    this.activeprofile = 0;
    this.createTemplateFormGroup = this.formBuilder.group({
      "templatecode": [""],
      "templatename": ["", [Validators.required]],
      "questioncategory": ["", [Validators.required]],
      "companyid": ''
    });

    this.createquestionforTemplateFormGroup = this.formBuilder.group({
      templateid: '',
      question: ["", [Validators.required]],
      questiontype: ["", [Validators.required]],
      questionoptions: [""],
      required: ['', Validators.required]
    });

    this.createTemplateFormGroup.get("questioncategory").patchValue("Professional");
    this.createquestionforTemplateFormGroup.get("required").patchValue("No");

  }

  get f() {
    return this.createTemplateFormGroup.controls;
  }
  get q() {
    return this.createquestionforTemplateFormGroup.controls;
  }




  loadData() {    
    this.referencingmoduleService.getquestiontemplates().subscribe((response: any) => {

      // console.log(response);
      // console.log(response.length);
       if(response.length >0){
            var templateid = (this.selectedtemplate)? this.selectedtemplate:response[0].id;
            this.questionTemplates = response;
            this.selectedtemplate = (this.selectedtemplate)? this.selectedtemplate:response[0].id;
            var data = { templateid:this.questionTemplates[0].id };
            
            this.referencingmoduleService.getquestiontemplatedetails(templateid).subscribe((response1) => {
              this.questionTemplatesdetails = response1;
              this.previousquestionTemplatesdetails = response1;
            });
        }

    });
  }

  setValidation() {
    // console.log(this.createquestionforTemplateFormGroup.controls.questiontype.value);
    if (this.createquestionforTemplateFormGroup.controls.questiontype.value == 'dropdown') {
      this.createquestionforTemplateFormGroup.get('questionoptions').setValidators([Validators.required])
    } else {
      this.createquestionforTemplateFormGroup.get('questionoptions').clearValidators();
    }
    this.createquestionforTemplateFormGroup.get('questionoptions').updateValueAndValidity();
  }


  getquestionTemplatedetails($event, questionTemplate, i) {
    this.activeprofile = i;
    this.selectedtemplate = questionTemplate;
    
    // var data = { templateid: }

    this.referencingmoduleService.getquestiontemplatedetails(this.selectedtemplate).subscribe((response1) => {
      this.questionTemplatesdetails = response1;
      this.previousquestionTemplatesdetails = response1;
    })
  }

  createNewTemplate(templatedetails,modelid) {
    this.submit = true;
    this.submitted = true;
    console.log(templatedetails);
    if(this.createTemplateFormGroup.invalid){
      return;
    }
    if (!this.isedittemplate) {
      // console.log(this.createTemplateFormGroup.value);
      this.referencingmoduleService.createNewTemplate(this.createTemplateFormGroup.value).subscribe((response: any) => {
        this.loadData();
      });
    } else{
      this.referencingmoduleService.updateTemplate({'id': this.selectedtemplate.id, data: this.createTemplateFormGroup.value}).subscribe((response: any) => {
        this.loadData();
      });
    }
    
    this.closeModal(modelid);
    // if (this.createTemplateFormGroup.valid) {
    //   if (!this.isedittemplate) {       
    //     this.referencingmoduleService.createNewTemplate(templatedetails).subscribe((response: any) => {
    //       //console.log(response);            
    //       // let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
    //       // this.openToast('Template for \'' + response.templatename + '\' is successfully created', '', options, ['success'])
    //       this.loadData();
    //       this.createTemplateFormGroup.reset();
    //       this.modalService.close(id);

    //       // this.createTemplateFormGroup.get("questioncategory").patchValue("Professional");
    //     })
    //   } else {
    //     var companyid = JSON.parse(localStorage.getItem("User_Data")).companyid;
    //     this.createTemplateFormGroup.value.companyid = companyid;
    //     this.referencingmoduleService.updateTemplate(templatedetails).subscribe((response: any) => {
    //       //console.log(response);            
    //       let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
    //       this.openToast('Template for \'' + response.templatename + '\' is successfully updated', '', options, ['success'])
    //       this.loadData();
    //       this.createTemplateFormGroup.reset();
    //       this.createTemplateFormGroup.get("questioncategory").patchValue("Professional");
    //     })
    //   }
    // }
  }

  edittemplate(questionTemplate, modelid) {
    this.submitted = false;
    this.disableBtn = true;
    this.buttoninsert = "Update Profile";
    this.selectedtemplate = questionTemplate;
    this.isedittemplate = true;
    this.createTemplateFormGroup.get("templatecode").patchValue(questionTemplate.templatecode);
    this.createTemplateFormGroup.get("templatename").patchValue(questionTemplate.templatename);
    this.createTemplateFormGroup.get("questioncategory").patchValue("Professional");
    this.modalService.open(modelid);
  }

  addtemplate(modelid) {
    this.submitted = false;
    this.disableBtn = true;
    this.buttoninsert = "Add New Profile";
    this.createTemplateFormGroup.reset();
    this.createTemplateFormGroup.get("questioncategory").patchValue("Professional");
    this.isedittemplate = false;
    this.modalService.open(modelid);
  }

  deletetemplate(selectedtemplate) {
    //console.log(this.selectedtemplate);
    if (confirm("Are you sure you want to delete?")) {
      this.referencingmoduleService.deleteTemplate({'id': selectedtemplate.id, data: {isactive: 'No'}}).subscribe((response: any) => {
        //console.log(response);            
        let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
        this.openToast('Template successfully deleted', '', options, ['success'])
        this.loadData();
      })
    }
  }



  createNewquestion(value, modeliid) {
    this.submitted = true;
    console.log(this.createquestionforTemplateFormGroup);
    if(this.createquestionforTemplateFormGroup.invalid){
      return;
    }
    this.createquestionforTemplateFormGroup.value.templateid = this.selectedtemplate;
    if (!this.iseditquestion) {
      this.referencingmoduleService.createquestionrequests(this.createquestionforTemplateFormGroup.value).subscribe((response: any) => {
        this.loadData();
      });
    } else{
      this.referencingmoduleService.updatequestionrequests({'id': this.selectedquestionTemplatesdetail.id, data: this.createquestionforTemplateFormGroup.value, templateid: this.selectedquestionTemplatesdetail.templateid}).subscribe((response: any) => {
        this.loadData();
      });
    }
    
    this.closeModal(modeliid);

    // if (!this.iseditquestion) {
    //   this.createquestionforTemplateFormGroup.value.templateid = this.selectedtemplate.id;
    //   //if (this.createTemplateFormGroup.valid) {
    //   this.referencingmoduleService.createquestionrequests(this.createquestionforTemplateFormGroup.value).subscribe((response: any) => {
    //     //console.log(response);            
    //     let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
    //     this.openToast('Question is successfully created', '', options, ['success'])
    //     this.referencingmoduleService.getquestiontemplatedetails(this.selectedtemplate.id).subscribe((response1) => {
    //       this.questionTemplatesdetails = response1;
    //       this.previousquestionTemplatesdetails = response1;
    //       this.createquestionforTemplateFormGroup.reset();
    //       this.createquestionforTemplateFormGroup.get("required").patchValue("No");
    //     })
    //   })
    // } else {
    //   this.createquestionforTemplateFormGroup.value.templateid = this.selectedtemplate.id;
    //   //if (this.createTemplateFormGroup.valid) {
    //   this.referencingmoduleService.updatequestionrequests({'id': this.selectedquestionTemplatesdetail.id, data: this.createquestionforTemplateFormGroup.value, templateid: this.selectedquestionTemplatesdetail.templateid}).subscribe((response: any) => {
    //     //console.log(response);            
    //     let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
    //     this.openToast('Question is successfully updated', '', options, ['success'])
    //     this.referencingmoduleService.getquestiontemplatedetails(this.selectedtemplate.id).subscribe((response1) => {
    //       this.questionTemplatesdetails = response1;
    //       this.previousquestionTemplatesdetails = response1;
    //       //this.createquestionforTemplateFormGroup.reset();
    //       //this.createquestionforTemplateFormGroup.get("required").patchValue("No");
    //     })
    //   })
    // }
    //}
  }

  editquestion(questionTemplatesdetail, up, modelid) {
    this.submitted = false;
    this.selectedquestionTemplatesdetail = questionTemplatesdetail;
    this.iseditquestion = true;
    this.disableBtn = true;
    this.buttoninsert = "Update Question";
    this.createquestionforTemplateFormGroup.get("templateid").patchValue(questionTemplatesdetail.templateid);
    this.createquestionforTemplateFormGroup.get("question").patchValue(questionTemplatesdetail.question);
    this.createquestionforTemplateFormGroup.get("questiontype").patchValue(questionTemplatesdetail.questiontype);
    this.createquestionforTemplateFormGroup.get("questionoptions").patchValue(questionTemplatesdetail.questionoptions);
    this.createquestionforTemplateFormGroup.get("required").patchValue(questionTemplatesdetail.required);
    this.modalService.open(modelid);

  }

  addquestion(modelid) {
    this.submitted = false;
    this.buttoninsert = "Add New Question";
    this.disableBtn = true;
    this.createquestionforTemplateFormGroup.reset();
    this.createquestionforTemplateFormGroup.get("required").patchValue("No");
    this.iseditquestion = false;
    this.modalService.open(modelid);
  }

  deletequestion(questionid) {
    //console.log(this.selectedtemplate);

    // var templateid = this.questionTemplatesdetails.find(x => x.id == this.selectedquestionfordeletion).templateid
   
    if (confirm("Are you sure you want to delete?")) {
      this.referencingmoduleService.deletequestionequests({id: questionid, data: {isactive: 'No'}, templateid: this.selectedtemplate}).subscribe((response: any) => {
        //console.log(response);            
        let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
        this.openToast('Question successfully deleted', '', options, ['success']);
        this.loadData();
        // this.referencingmoduleService.getquestiontemplatedetails(this.selectedtemplate.id).subscribe((response1) => {
        //   this.questionTemplatesdetails = response1;
        //   this.previousquestionTemplatesdetails = response1;
        // })
      })
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }


  onClear() {
    this.submit = false
    this.createTemplateFormGroup.setValue({
      "templatecode": "",
      "templatename": "",
      "questioncategory": "Professional"
    });
  }

  onClearquestion() {
    this.createquestionforTemplateFormGroup.reset();
    this.createquestionforTemplateFormGroup.get("required").patchValue("No");
  }


  openToast(message, title, options, type) {    
    this.toastrService[type](message, title, options);
  }

  selectedquestiontemplate(selectedtemplate) {
    this.selectedtemplate = selectedtemplate;
  }


  deletequestioneventcapture(e) {    
    this.selectedquestionfordeletion = e.currentTarget.attributes.id.value;
  }

  openquestiontemplate(questiontemp,type,modelid)
  {
    // console.log(questiontemp.templatecode);

    // console.log(questiontemp.templatename);
    this.disableBtn = true;
    if(type === 'new')
    {
      this.buttoninsert = "Add New Template";
      // this.hourDetail = new ReferenceDetails();
    }else{

      console.log('update');
      this.buttoninsert = "Update Template";
      // this.createTemplateFormGroup.get("templatecode").patchValue(questiontemp.templatecode);
      //  this.createTemplateFormGroup.get("templatename").patchValue(questiontemp.templatename);

      // this.hourDetail.templatecode = questiontemp.templatecode;
      // this.hourDetail.templatename = questiontemp.templatename;
    }

    this.modalService.open(modelid);

  }

  openquestiontemplatedetails(questiontempdetails,type,modelid)
  {
    this.disableBtn = true;
    if(type === 'new')
    {
      this.buttoninsert = "Add New Template";
      // this.addquestion();
      // this.hourDetail = new ReferenceDetails();
    } else{
      
      console.log('update');
      this.buttoninsert = "Update Template";
      // this.createTemplateFormGroup.get("templatecode").patchValue(questiontemp.templatecode);
      //  this.createTemplateFormGroup.get("templatename").patchValue(questiontemp.templatename);

      // this.hourDetail.question = questiontempdetails.question;
      // this.hourDetail.required = questiontempdetails.required;
      // this.hourDetail.questiontype = questiontempdetails.questiontype;
      // this.hourDetail.questionoptions = questiontempdetails.questionoptions;
      
    }

    this.modalService.open(modelid);

  }
  closeModal(id)
  {
    this.modalService.close(id);
  }

}
