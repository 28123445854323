"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../_services/modal.service");
var forms_1 = require("@angular/forms");
var expensetype_model_1 = require("../../../models/expense/expensetype.model");
var worklist_service_1 = require("../../../services/worklist.service");
var router_1 = require("@angular/router");
var SettinghourtypeComponent = /** @class */ (function () {
    function SettinghourtypeComponent(modalService, formBuilder, router, worklist) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.router = router;
        this.worklist = worklist;
        this.hourDetail = new expensetype_model_1.Hourtype();
        this.options = [];
        this.submitted = false;
    }
    SettinghourtypeComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.settingperm = this.permission.m81;
        this.Userid = localStorage.getItem('userid');
        this.hourDetail = new expensetype_model_1.Hourtype();
        this.getexpensetypegroup();
        var sstatustype = { 'status': false, 'id': this.Userid };
        this.worklist.gethourtypelist(sstatustype).subscribe(function (expensetypelist) {
            _this.data = expensetypelist;
            _this.record = _this.data.length;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    SettinghourtypeComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    SettinghourtypeComponent.prototype.getexpensetypegroup = function () {
        this.hourformGroup = this.formBuilder.group({
            id: [''],
            typename: ['', forms_1.Validators.required]
        });
    };
    SettinghourtypeComponent.prototype.getholidaytype = function () {
        this.hourformGroup = this.formBuilder.group({
            id: [''],
            typename: ['', forms_1.Validators.required]
        });
    };
    Object.defineProperty(SettinghourtypeComponent.prototype, "f", {
        get: function () { return this.hourformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    SettinghourtypeComponent.prototype.onSubmit = function (formhourtype, id) {
        var _this = this;
        // var formhourtype = settinghourtypedata.value;
        this.submitted = true;
        if (this.hourformGroup.invalid) {
            return;
        }
        this.disableBtn = false;
        formhourtype.userid = this.Userid;
        this.worklist.settinghourtypeUpdate(formhourtype).subscribe(function (response) {
            var sstatustype = { 'status': false, 'id': _this.Userid };
            _this.worklist.gethourtypelist(sstatustype).subscribe(function (expensetypelist) {
                _this.data = expensetypelist;
                _this.record = _this.data.length;
                if (_this.record < 30) {
                    _this.pageOfItems = _this.data;
                }
                else {
                    _this.items = _this.data;
                }
                _this.modalService.close(id);
            });
        });
    };
    SettinghourtypeComponent.prototype.onChangeStatus = function (evt, hourtype) {
        var _this = this;
        var cstatus;
        if (evt.target.checked) {
            cstatus = '1';
        }
        else if (!evt.target.checked) {
            cstatus = '0';
        }
        this.worklist.hourtypeUpdateChangeStatus({ 'userid': this.Userid, 'id': hourtype.id, 'cstatus': evt.target.checked }).subscribe(function (data) {
            var sstatustype = { 'status': false, 'id': _this.Userid };
            _this.worklist.gethourtypelist(sstatustype).subscribe(function (expensetypelist) {
                _this.data = expensetypelist;
            });
        });
    };
    SettinghourtypeComponent.prototype.openModal = function (id, data) {
        this.buttoninsert = "Update";
        this.disableBtn = true;
        this.hourDetail = data;
        this.modalService.open(id);
    };
    SettinghourtypeComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.hourDetail = new expensetype_model_1.Hourtype();
        this.getexpensetypegroup();
        this.modalService.open(id);
    };
    SettinghourtypeComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return SettinghourtypeComponent;
}());
exports.SettinghourtypeComponent = SettinghourtypeComponent;
