import { Component, OnInit } from '@angular/core';
import { WorklistService } from './../../../services/worklist.service';
import { ModalService } from '../../../_services/modal.service';
import { CustomerService } from '../../../services/customer.service';
import { Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
@Component({
  selector: 'app-admintimesheetrequest',
  templateUrl: './admintimesheetrequest.component.html',
  styleUrls: ['./admintimesheetrequest.component.css']
})
export class AdmintimesheetrequestComponent implements OnInit {

  data: any [];
  Userid: any;
  month: any;
  year: any;
  yearmonth: any;
  employeeid: any;
  employeelist: any;
  classactive: string;
  record: any;
  hstatus: any;
  permission: any;
  requestperm: any;
  notedetails:any;
  yearList:any;
  checkedAll: any = false;
  items: Array<any>;
  
  
  pageOfItems: Array<any>;
  monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];
  constructor(
    private dataset: WorklistService, 
    private modalService: ModalService,
    private router: Router, 
    private customer: CustomerService
    ) { }

  ngOnInit() {
    this.yearList = AppGlobals.YEAR_VALUES;
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }


    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;

    this.hstatus ='';
    this.month = (new Date()).getMonth();
    this.year = (new Date()).getFullYear();
    this.yearmonth = this.monthNames[this.month ] +' '+ (new Date()).getFullYear();

    this.classactive= ((new Date()).getMonth() +1).toString();
    
    this.customer.getEmployeeDetailsbyadmin().subscribe((data: any) => {
      this.employeelist = data;
    });

    this.bindalldata();    
  }

  selectall(val){
    if(val){
      this.data.forEach((element, index) => {
        this.data[index].isChecked = true;
      });
      // document.getElementsByClassName('activity').checked = true; 
    } else{
      this.data.forEach((element, index) => {
        this.data[index].isChecked = false;
      });
    }
  }

  selectTimesheet(val){
    if(val.checked){
      this.checkedAll = true;
      this.data.forEach((element, index) => {
        if(!element.isChecked){
          this.checkedAll = false;
        }
        if(val.value==element.employeehourapplyid){
          this.data[index].isChecked = true;
        }
        
      });
      
    } else{
      this.checkedAll = false;
      this.data.forEach((element, index) => {
        if(val.value==element.employeehourapplyid){
          this.data[index].isChecked = false;
        }
      });
    }
  }

  approveAllTimesheet(status){

    var activityIds = [];
    this.data.forEach(element => {
      if(element.isChecked){
        activityIds.push(element.employeehourapplyid);
      }      
    });


    if(activityIds.length){
      const changeData = { 'ids': activityIds, 'hstatus': status, 'userid': this.Userid, 'statusdate': new Date() };
    
     
      var msg = '';
      if (status == 2) {
        msg = 'Are you sure you want to Approve?';
  
      } else {
        msg = 'Are you sure you want to Reject?';
      }
  
      if (confirm(msg)) {
        this.dataset.changeAllActivityStatus(changeData).subscribe((data: any) => {
          this.bindalldata();
        });
      }
    }else
    {

     
      this.modalService.open('check-plan');
    }
    
  }
  
  onChange(newValue) {
    this.employeeid = newValue;
    this.bindalldata();
  }

  onChangestatus(newValue) {    
    this.hstatus = newValue;
    this.bindalldata();
  }

  bindalldata(){
    this.data = [];
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'month': this.month, 'year': this.year, 'employeeid': this.employeeid, 'hstatus': this.hstatus };
    this.dataset.getemployeeworklistreportrequestbyadmin(data).subscribe((data: any) => {
      this.record = data.activities.length;      
      // this.data = data;
      var leavefound = false;
      data.activities.forEach(act => {
        leavefound = false;
        data.leaves.forEach((leave, lindex) => {
          if((act.employeeid == leave.employeeid) && (leave.startdate <= act.startdate) && (leave.enddate >= act.startdate)){
            leavefound = true;
          }
        });
        act.leavefound = leavefound;
        act.isChecked = false;
        this.data.push(act);
      });

      console.log('testing data',this.data);
      this.record = this.data.length;

      if(this.record < 30){
        this.pageOfItems = this.data;
      } else{
        this.items =this.data;
      }
     });
  }

  
  onStatus(id: number, status: number) {
    const deleteData = { 'id': id, 'hstatus': status, 'userid': this.Userid, 'statusdate': new Date() };
    var msg = '';
    if (status == 2) {
      msg = 'Are you sure you want to Approve?';

    } else {
      msg = 'Are you sure you want to Reject?';
    }
    if (confirm(msg)) {
      this.dataset.statusactivity(deleteData).subscribe((data: any) => {
        this.bindalldata();
      });
    }
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  onyearSubmit(year) {
    this.year = year;
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ this.year;

    this.bindalldata();
    // this.modalService.close(id);
  }
  
  openModalnote (data:any,id:any){
    this.notedetails = data;
    this.modalService.open(id);
  }

  yearpop(id: string){
    this.modalService.open(id);
  }

  getDataByMonth(mon)
  {
    this.classactive=mon;    
    this.month = mon-1;
    this.yearmonth = this.monthNames[this.month ] +' '+ this.year;




    this.bindalldata();


    // const data = { 'id': this.Userid, 'month': this.month, 'year': this.year, 'employeeid': this.employeeid, 'hstatus': this.hstatus };
    // this.dataset.getemployeeworklistreportrequestbyadmin(data).subscribe((data: any) => {
      
    //   this.data = data;
    //   this.record = this.data.length;

    //   if(this.record < 30){
    //     this.pageOfItems = this.data;
    //   }
    //   else{
    //        this.items =this.data;
    //   }
    // });
  }

  closeModal(id: string){
    this.modalService.close(id);
  }

  getDataByYear(yea)
  {
    
    this.year = yea;
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ yea;
    const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    this.dataset.getemployeeworklistreportrequestbyadmin(data).subscribe((data: any) => {
      
      this.data = data;
      this.record = this.data.length;

      if(this.record < 30){
        this.pageOfItems = this.data;
      }
      else{
           this.items =this.data;
      }
    });
  }

}
