<app-header></app-header>

<div class="section-wrapper" *ngIf="reportData">
  <div class="container" >
    <div>
      <div class="user_profle_section">
        <div class="profile_box">
          <div class="overviewsshow mt-3">
            <div class="header">
              <h1 class="headprofile width100 mt-0">Primary Applicant</h1>
            </div>
            <div class="overview_details width100">
              <div class="alignbbb">
                <div class="headdd">
                  <h3 class="card-profile-name">Credit Report: {{searchingFields.title}} {{searchingFields.forename}} {{searchingFields.sirname}}</h3>
                </div>
              </div>
              <hr class="mt-0"/>
              <div class="profile_dtailsshow mb-3">
                <div class="detailsshow ">
                  <span>Name</span>
                  <p> {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.title._text}} {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.forename._text}} {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.surname._text}}</p>
                </div>
                <div class="detailsshow ">
                  <span>Current Address</span>
                  <p> {{picklist.applicant.address.fullmatches.fullmatch.addressmatch.buildingno._text}}, 
                    {{picklist.applicant.address.fullmatches.fullmatch.addressmatch.street1._text}}, 
                    {{picklist.applicant.address.fullmatches.fullmatch.addressmatch.posttown._text}}, 
                    {{picklist.applicant.address.fullmatches.fullmatch.addressmatch.postcode._text}}</p>
                </div>
                <!-- <div class="detailsshow ">
                  <span>Amount</span>
                  <p>Not Entered</p>
                </div>
                <div class="detailsshow ">
                  <span>Term</span>
                  <p>Not Entered</p>
                </div> -->
                <div class="detailsshow ">
                  <span>Date of birth</span>
                  <p>{{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.dob._text}}</p>
                </div>
              </div>
              <div class="profile_dtailsshow mb-3">
                <!-- <div class="detailsshow ">
                    <span>Number of Applicant</span>
                    <p> 1</p>
                </div> -->
                <div class="detailsshow ">
                  <span>Credit Search Purpose</span>
                  <p> {{creditrequest.purpose._text}} </p>
                </div>
                <div class="detailsshow ">
                  <span>Credit Search Ref.</span>
                  <p>{{jobdetails.searchid._text}}</p>
                </div>
                <div class="detailsshow ">
                  <span>Date & Time of Search</span>
                  <p> {{jobdetails.searchdate._text}}</p>
                </div>  
              </div>
            </div>
            <div class="overview_details ">
              <div class="alignbbb">
                <div class="headdd">
                  <h3 class="card-profile-name">Address Confirmation</h3>
                </div>
              </div>
              <hr class="mt-0"/>
              
              
              <div class="profile_dtailsshow mb-3"  *ngFor="let add of convertStringToArray(reportData.applicant.summary.address); let i=index">
                <div class="detailsshow ">
                  <span>Address</span>
                  <p><i class="lnr lnr-map-marker"></i> {{ add._text}}</p>
                </div>
                  <div class="detailsshow ">
                    <span>Level</span>
                    <p class="mb-0">Individul</p>
                  </div>
                  <div class="detailsshow ">
                    <span>Source {{add.messagecode}}</span>
                    <p class="mb-0" *ngIf="add.messagecode == '1'">Electoral Roll</p>
                    <p class="mb-0" *ngIf="add.messagecode == '2'">Electoral Roll</p>
                    <p class="mb-0" *ngIf="add.messagecode == '3'">Electoral Roll</p>
                    <p class="mb-0" *ngIf="add.messagecode == '4'">Electoral Roll</p>
                    <p class="mb-0" *ngIf="add.messagecode == '5'">Credit Data</p>
                    <p class="mb-0" *ngIf="add.messagecode == '6'">Public Information</p>
                    <p class="mb-0" *ngIf="add.messagecode == '7'">Electoral Roll</p>
                    <p class="mb-0" *ngIf="add.messagecode == '8'">No confirmation available</p>
                    <p class="mb-0" *ngIf="add.messagecode == '9'">12 months old</p>
                  </div>
              </div> 
            </div>
          </div>
        </div>
        <div class="details_box">
          <div class="overviewsshow pb-0 pt-0 pr-0 pl-0">
            <mat-tab-group [@.disabled]="true">
              <mat-tab label="Address Confirmation">
                <ng-template matTabContent>
                  <div class="overviewsshow  mt-1" >
                    <div class="header">
                      <h1 class="headprofile width100 mt-0">Address Confirmation</h1>
                    </div>

                    <ng-container *ngIf="reportData.applicant.addressconfs.addressconf === 'array'" >
                      <div class="overview_details">
                        <div class="profile_dtailsshow mb-3"  *ngFor="let add of reportData.applicant.addressconfs.addressconf; let i=index">
                          <div class="detailsshow width60">
                          <span>Address</span>
                            <p><i class="lnr lnr-map-marker"></i> {{add.address._text}}</p>
                          </div>
                          <div class="detailsshow width30">
                          <span>Level</span>
                          <p class="mb-0">Individul</p>
                          </div>
                          <div class="detailsshow width30">
                            <span>Address Type </span>
                            <span *ngIf="add.address._attributes.current == 1">Current Address  </span> 
                            <span *ngIf="add.address._attributes.current == 0"> 
                              <b *ngIf="add.address._attributes.undeclaredaddresstype =='1'">Undeclared intermediate Address Link</b>
                              <b *ngIf="add.address._attributes.undeclaredaddresstype =='2'">Undeclared forwarding Address Link</b>
                              <b *ngIf="add.address._attributes.undeclaredaddresstype =='3'">Undeclared previous Address Link</b>
                              <b *ngIf="add.address._attributes.undeclaredaddresstype =='4'">Undeclared concurrent Address Link</b>
                              <b *ngIf="add.address._attributes.undeclaredaddresstype =='5'">Undeclared miscellaneous Address Link</b> 
                            </span> 
                          </div>
                        </div>
                      </div>
                      <div class="overview_details" *ngIf="reportData.applicant.addressconfs.addressconf.length==0">
                        No Record Found
                      </div>
                    </ng-container>

                      
                    <ng-container *ngIf="reportData.applicant.addressconfs.addressconf === 'object'" >
                      <div class="overview_details">
                        <div class="profile_dtailsshow mb-3" >
                          <div class="detailsshow width60">
                            <span>Address</span>
                            <p><i class="lnr lnr-map-marker"></i> {{reportData.applicant.addressconfs.addressconf.address._text}}</p>
                          </div>
                          <div class="detailsshow width30">
                            <span>Level</span>
                            <p class="mb-0">Individul</p>
                          </div>
                          <div class="detailsshow width30">
                            <span>Address Type </span>
                            <span *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.current == 1">Current Address  </span> 
                            <span *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.current == 0"> 
                              <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='1'">Undeclared intermediate Address Link</b>
                              <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='2'">Undeclared forwarding Address Link</b>
                              <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='3'">Undeclared previous Address Link</b>
                              <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='4'">Undeclared concurrent Address Link</b>
                              <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='5'">Undeclared miscellaneous Address Link</b> 
                            </span> 
                          </div>
                        </div>
                      </div>
                      <div class="overview_details" *ngIf="!reportData.applicant.addressconfs.addressconf">
                          No Record Found
                      </div>
                    </ng-container>
                  </div>             
                </ng-template>
              </mat-tab>
              <mat-tab label="Public Information">
                <ng-template matTabContent>
                  <div class="overviewsshow  mt-1" >
                    <div class="header">
                      <h1 class="headprofile width100 mt-0">Public Information</h1>
                    </div>
                    <div class="overview_details width100">
                      <div class="alignbbb">
                        <div class="headdd">
                            <h3 class="card-profile-name">INSOLVENCY, BANKRUPTCY OR ADMINISTRATOR ORDER</h3>            
                        </div>
                        <span class="badge badge-danger" *ngIf="reportData.applicant.summary.bais.length">Yes</span>
                        <span class="badge badge-danger" *ngIf="!reportData.applicant.summary.bais.length">No</span>
                      </div>
                      <hr class="mt-0"/>
                      <div class="profile_dtailsshow mb-3" *ngIf="reportData.applicant.summary.bais.totaldischarged">
                        <div class="detailsshow width25">
                          <span>Number of Discharged BAI in last 6 Years</span>
                          <p class="text-dark" ><b>{{reportData.applicant.summary.bais.totaldischarged._text}}</b></p>
                        </div>
                        <div class="detailsshow width25"  *ngIf="reportData.applicant.summary.bais.totalactive">
                          <span>Number of Active Judgements</span>
                          <p class="text-danger"><b>{{reportData.applicant.summary.judgments.totalactive._text}}</b></p>
                        </div>
                        <div class="detailsshow width25" *ngIf="reportData.applicant.summary.bais.totalsatisfied">
                          <span>Number of Satisfied Judgements</span>
                          <p class="text-danger"><b>{{reportData.applicant.summary.judgments.totalsatisfied._text}}</b></p>
                        </div>
                        <div class="detailsshow width20" *ngIf="reportData.applicant.summary.bais.totalactiveamount">
                          <span>Total Values of active Judgements</span>
                          <p class="text-danger"><b>£ {{reportData.applicant.summary.judgments.totalactiveamount._text}}</b></p>
                        </div>
                      </div>      
                    </div>   
                  </div>             
                </ng-template>
              </mat-tab>
              <mat-tab label="Adverse Credit Searches">
                <ng-template matTabContent>
                  <div class="overviewsshow  mt-1" >
                    <div class="header">
                      <h1 class="headprofile width100 mt-0">Adverse Credit Searches</h1>
                    </div>
                    <div class="overview_details width100">
                      <div class="alignbbb">
                        <div class="headdd">
                          <h3 class="card-profile-name">NB: These counts are based on checking credit application and business searches</h3>            
                        </div>        
                      </div>
                      <hr class="mt-0"/>
                      <div class="profile_dtailsshow mb-3">
                        <div class="detailsshow width30">
                          <span>Number of Searches in last 3 Months</span>
                          <p class="text-dark"><b>{{ reportData.applicant.summary.searches.totalsearches3months._text }}</b></p>
                        </div>
                        <div class="detailsshow width30">
                          <span>Number of Searches in last 12 Months</span>
                          <p class="text-dark"><b>{{ reportData.applicant.summary.searches.totalsearches12months._text }}</b></p>
                        </div>
                        <div class="detailsshow width30">
                          <span>Number of Home Credit Searches in last 3 Months</span>
                          <p class="text-danger"><b>{{ reportData.applicant.summary.searches.totalhomecreditsearches3months._text }}</b></p>
                        </div>
                      </div>
                    </div>
                  </div>             
                </ng-template>
              </mat-tab>
              <mat-tab label="Unchecked Links">
                <ng-template matTabContent>
                  <div class="overviewsshow  mt-1" >
                    <div class="overview_details width100">
                      <div class="header">
                        <h1 class="headprofile width100 mt-0">Unchecked Links</h1>
                      </div>
                      <div class="profile_dtailsshow mb-3">
                        <div class="detailsshow width30">
                          <span>Number of Undeclared Addresses - Searches</span>
                          <p class="text-danger"><b>{{reportData.applicant.summary.links.totalundecaddressessearched._text}}</b></p>
                        </div>
                        <div class="detailsshow width40">
                          <span>Number of Undeclared Addresses - Not Searches</span>
                          <p class="text-danger"><b>{{reportData.applicant.summary.links.totalundecaddressesunsearched._text}}</b></p>
                        </div>
                        
                        <div class="detailsshow width30">
                          <span>Number of Undeclared Aliases </span>
                          <p class="text-danger"><b>{{reportData.applicant.summary.links.totalundecaliases._text}}</b></p>
                        </div>
                      </div>
                      <div class="header">
                        <h1 class="headprofile width100 mt-0">Noties</h1>
                      </div>
                      <div class="profile_dtailsshow mb-3">
                        <div class="detailsshow width30">
                          <span>Noties of Correction Present</span>
                          <p class="text-danger" *ngIf="reportData.applicant.summary.notices.nocflag._text == 1"><b>Yes</b></p>
                          <p class="text-danger" *ngIf="reportData.applicant.summary.notices.nocflag._text == 0"><b>No</b></p>
                        </div>
                        <div class="detailsshow width30" *ngIf="reportData.applicant.summary.notices.totaldisputes">
                          <span>Number of Noties of Disputes </span>
                          <p class="text-dark"><b>{{reportData.applicant.summary.notices.totaldisputes._text}}</b></p>
                        </div>       
                      </div>
                    </div>
                  </div> 
                </ng-template>
              </mat-tab>
                
              <mat-tab label="IBA">
                <ng-template matTabContent>
                  <div class="overviewsshow mt-1" *ngIf="reportData.applicant.bais">
                    <div class="header">
                      <h1 class="headprofile width100 mt-0">IBA</h1>
                    </div>
                    <div class="overview_details width100"  *ngFor="let add of reportData.applicant.bais.bai; let i=index">
                      <div class="alignbbb">
                        <div class="headdd">
                          <h3 class="card-profile-name">{{ add.name._text}}</h3>            
                        </div>
                        <p class="badge" *ngIf="reportData.applicant.summary.bais.length">
                          <span *ngIf="add.address._attributes.current == 1">Current Address:  </span> {{add.address._text}}
                          <span *ngIf="add.address._attributes.current == 0">Undeclared Forwarding Address: </span> {{add.address._text}}
                        </p>
                      </div>
                      <hr class="mt-0"/>
                      <div class="profile_dtailsshow mb-3" >
                        <div class="detailsshow width35">
                          <span>Court Name</span>
                          <p class="text-dark" ><b>{{add.courtname._text}}</b></p>
                        </div>
                        <div class="detailsshow width25"  >
                          <span>Order Type</span>
                          <p class="text-danger">
                            <b *ngIf="add.ordertype._text == 'BO'">Bankruptcy Order</b>
                            <b *ngIf="add.ordertype._text == 'IV'">IVA</b>
                          </p>
                        </div>
                        <div class="detailsshow width20" >
                          <span>Order Date</span>
                          <p class="text-danger"><b>{{add.orderdate._text}}</b></p>
                        </div>
                        <div class="detailsshow width20" >
                          <span>Order Year</span>
                          <p class="text-danger"><b>{{add.caseyear._text}}</b></p>
                        </div>
                        <div class="detailsshow width20" >
                          <span>Case Ref</span>
                          <p class="text-danger"><b>{{add.caseref._text}}</b></p>
                        </div>
                        <div class="detailsshow width20" >
                          <span>Status</span>
                          <p class="text-danger" *ngIf="add.currentstatus._text == 'A'"><b>Active</b></p>
                          <p class="text-danger" *ngIf="add.currentstatus._text == 'D'"><b>Discharged or completed</b></p>
                          <p class="text-danger" *ngIf="add.currentstatus._text == 'V'"><b>Revoked</b></p>
                        </div>
                      </div>      
                    </div>   
                  </div>
                  <div class="overviewsshow mt-1" *ngIf="!reportData.applicant.bais">
                      No Record Found
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab label="County court judgement">
                <ng-template matTabContent>
                  <div class="overviewsshow mt-1" *ngIf="reportData.applicant.judgments">
                    <div class="header">
                      <h1 class="headprofile width100 mt-0">County court judgement</h1>
                    </div>
                    <div class="overview_details width100"  *ngFor="let add of reportData.applicant.judgments.judgment; let i=index">
                      <div class="alignbbb">
                        <div class="headdd">
                          <h3 class="card-profile-name">{{ add.name._text}}</h3>            
                        </div>
                        <p class="badge" *ngIf="reportData.applicant.summary.bais.length">
                          <span *ngIf="add.address._attributes.current == 1">Current Address:  </span>
                          <span *ngIf="add.address._attributes.current == 0"> 
                              <b *ngIf="add.address._attributes.undeclaredaddresstype =='1'">Undeclared intermediate Address Link:</b>
                              <b *ngIf="add.address._attributes.undeclaredaddresstype =='2'">Undeclared forwarding Address Link:</b>
                              <b *ngIf="add.address._attributes.undeclaredaddresstype =='3'">Undeclared previous Address Link:</b>
                              <b *ngIf="add.address._attributes.undeclaredaddresstype =='4'">Undeclared concurrent Address Link:</b>
                              <b *ngIf="add.address._attributes.undeclaredaddresstype =='5'">Undeclared miscellaneous Address Link:</b> 
                          </span> {{add.address._text}}
                        </p>
                      </div>
                      <hr class="mt-0"/>
                      <div class="profile_dtailsshow mb-3" >
                        <div class="detailsshow width35">
                          <span>Court Name</span>
                          <p class="text-dark" ><b>{{add.courtname._text}}</b></p>
                        </div>
                        <div class="detailsshow width25"  >
                          <span>Court Type</span>
                          <p class="text-danger">
                            <b *ngIf="add.courttype._text == '0'">County Court Judgement</b>
                            <b *ngIf="add.courttype._text == '1'">High Court Queen's Bench</b>
                            <b *ngIf="add.courttype._text == '2'">High Court Mercantile</b>
                            <b *ngIf="add.courttype._text == '3'">High Court Chancery Division</b>
                            <b *ngIf="add.courttype._text == '4'">RCJ, Queen's Bench</b>
                            <b *ngIf="add.courttype._text == '5'">RCJ, Chancery</b>
                            <b *ngIf="add.courttype._text == '6'">RCJ, Admiralty & Commercial Court</b>
                          </p>
                        </div>
                        <div class="detailsshow width25" >
                          <span>Judgement Date</span>
                          <p class="text-danger"><b>{{add.judgmentdate._text}}</b></p>
                        </div>
                        <div class="detailsshow width15" >
                          <span>Amount</span>
                          <p class="text-danger"><b>{{add.amount._text}}</b></p>
                        </div>
                        <div class="detailsshow width25" >
                          <span>Case Number</span>
                          <p class="text-danger"><b>{{add.casenumber._text}}</b></p>
                        </div>
                        <div class="detailsshow width25" >
                          <span>Status</span>
                          <p class="text-danger" *ngIf="add.status._text == 'JG'"><b>active Judgement</b></p>
                          <p class="text-danger" *ngIf="add.status._text == 'SS'"><b>Satisfied (Paid)</b></p>
                        </div>
                      </div>      
                    </div>   
                  </div>
                  <div class="overviewsshow mt-1" *ngIf="!reportData.applicant.judgments">
                    No Record Found
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab label="Address Links">
                <ng-template matTabContent>
                  <div class="overviewsshow mt-1" *ngIf="reportData.applicant.addresslinks">
                    <div class="header">
                      <h1 class="headprofile width100 mt-0">Address Links</h1>
                    </div>
                    <div class="overview_details width100"  *ngFor="let add of convertStringToArray(reportData.applicant.addresslinks.links.link); let i=index">
                        
                        <div class="profile_dtailsshow mb-3" >
                            <div class="detailsshow width50">
                                <span>From</span>
                                <ng-container *ngFor="let add2 of reportData.applicant.addresslinks.addresses.address; let i1=index">
                                    <p class="text-dark" *ngIf="add._attributes.from == add2._attributes.addressid">                
                                        <b >{{add2._text}}</b>                    
                                    </p>
                                  </ng-container>                
                            </div>
                            <div class="detailsshow width50">
                                <span>To</span>
                                <p class="text-dark" *ngFor="let add2 of reportData.applicant.addresslinks.addresses.address; let i1=index">
                                
                                    <b *ngIf="add._attributes.to == add2._attributes.addressid">{{add2._text}}</b>
                                
                                </p>
                            </div>                                       
                        </div>   
                        <div class="profile_dtailsshow mb-3" >
                            <div class="detailsshow width25" >
                                <span>Confirmation Date</span>
                                <p class="text-danger"><b>{{add.creationdate._text}}</b></p>
                            </div>
                            <div class="detailsshow width25" >
                                <span>Earliest Confirmation</span>
                                <p class="text-danger"><b>{{add.lastconfdate._text}}</b></p>
                            </div>                            
                            <div class="detailsshow width50" >
                                <span>Supplier Details</span>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'AF'"><b>Agricultural Finance</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'BF'"><b>Bank Finance</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'BK'"><b>Bank</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'BS'"><b>Building Society</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'CA'"><b>TransUnion</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'CB'"><b>Credit Broker</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'CC'"><b>Credit Card Company</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'CD'"><b>CC Consumer Data Capture</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'CU'"><b>Credit Union</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'DC'"><b>Debt Collection Agency</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'ED'"><b>EuroDirect</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'FC'"><b>Factoring</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'FH'"><b>Finance Housing</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'FN'"><b>Finance House</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'GO'"><b>Government</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'HC'"><b>Home Credit</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'HS'"><b>Home Shopping</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'IN'"><b>Insurance Company</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'IT'"><b>Internet/On Line Service</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'LS'"><b>Leasing Company</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'MC'"><b>Mortgage Company</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'MF'"><b>Motor Vehicle Finance Company</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'MO'"><b>Mail Order</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'RC'"><b>Rental Company</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'RT'"><b>Retailer</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'SC'"><b>Securities</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'SL'"><b>Student Loan</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'ST'"><b>Stockbroker</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'TC'"><b>Telecommunications Supplier</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'UT'"><b>Utility</b></p>
                                <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'VR'"><b>Vehicle Rental</b></p>          
                            </div>
                        </div>   
                    </div>   
                  </div>
                  <div class="overviewsshow mt-1" *ngIf="!reportData.applicant.addresslinks">
                    No Record Found
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab label="Notice of Correction ">
                  <ng-template matTabContent>
                      <div class="overviewsshow mt-1" *ngIf="reportData.applicant.nocs">
                          <div class="header">
                            <h1 class="headprofile mt-0">Notice of Correction</h1>
                          </div>
                          <div class="overview_details width100"  *ngFor="let add of reportData.applicant.nocs.noc; let i=index">
                              <div class="alignbbb">
                                  <div class="headdd">
                                      <h3 class="card-profile-name">{{ add.name._text}}</h3>            
                                  </div>
                                  <p class="badge"  >
                                      <span *ngIf="add.address._attributes.current == 1">Current Address:  {{add.address._text}}</span> 
                                      <span *ngIf="add.address._attributes.current == 0">                    
                                      <b *ngIf="add.address._attributes.undeclaredaddresstype =='1'">Undeclared intermediate Address Link:</b>
                                      <b *ngIf="add.address._attributes.undeclaredaddresstype =='2'">Undeclared forwarding Address Link:</b>
                                      <b *ngIf="add.address._attributes.undeclaredaddresstype =='3'">Undeclared previous Address Link:</b>
                                      <b *ngIf="add.address._attributes.undeclaredaddresstype =='4'">Undeclared concurrent Address Link:</b>
                                      <b *ngIf="add.address._attributes.undeclaredaddresstype =='5'">Undeclared miscellaneous Address Link:</b>
                                      </span> {{add.address._text}}
                                  </p>
                              </div>
                              <hr class="mt-0"/>
                              <div class="profile_dtailsshow mb-3" >
                                  <div class="detailsshow width25">
                                      <span>Reference Number</span>
                                      <p class="text-dark" ><b>{{add.refnum._text}}</b></p>
                                  </div>            
                                  <div class="detailsshow width25" >
                                      <span>Date of Correction</span>
                                      <p class="text-danger"><b>{{add.dateraised._text}}</b></p>
                                  </div>
                                  <div class="detailsshow width55" >
                                      <span>Text</span>
                                      <p class="text-danger"><b>{{add.text._text}}</b></p>
                                  </div>
                                
                              </div>      
                          </div>   
                      </div>    
                      <div class="overviewsshow mt-1" *ngIf="!reportData.applicant.nocs">
                          No Record Found
                      </div>     
                  </ng-template>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>


    </div>
</div>
</div>