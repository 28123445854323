"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var myvisa_service_1 = require("../../../../services/myvisa.service");
var country_service_1 = require("../../../../services/country.service");
var forms_1 = require("@angular/forms");
var visa_model_1 = require("../../../../models/visa/visa.model");
var upload_file_service_1 = require("./../../../../services/upload-file.service");
var alert_service_1 = require("../../../../services/alert.service");
var ngx_webcam_1 = require("ngx-webcam");
var platform_browser_1 = require("@angular/platform-browser");
var rxjs_1 = require("rxjs");
var router_1 = require("@angular/router");
var checksystem_service_1 = require("../../../../services/checksystem.service");
var Subscription_1 = require("rxjs/Subscription");
var checkservice_service_1 = require("../../../../services/checkservice.service");
require("rxjs/add/observable/timer");
var RtwVisaComponent = /** @class */ (function () {
    function RtwVisaComponent(formBuilder, uploadService, visaService, country, datePipe, alerts, sanitizer, check, checkService, activatedRoute) {
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.visaService = visaService;
        this.country = country;
        this.datePipe = datePipe;
        this.alerts = alerts;
        this.sanitizer = sanitizer;
        this.check = check;
        this.checkService = checkService;
        this.activatedRoute = activatedRoute;
        this.performAction = new core_1.EventEmitter();
        this.wizard = 0;
        this.token_expired = false;
        this.aipayment = false;
        this.is_rtw = 0;
        this.showloader = false;
        this.showWebcam = true;
        this.allowCameraSwitch = true;
        this.multipleWebcamsAvailable = false;
        this.videoOptions = {};
        this.errors = [];
        this.webcamImage = null;
        // webcam snapshot trigger
        this.trigger = new rxjs_1.Subject();
        // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
        this.nextWebcam = new rxjs_1.Subject();
    }
    RtwVisaComponent.prototype.ngOnInit = function () {
        this.disableBtn = true;
        this.profile_image = localStorage.getItem('profile_pic');
        this.visapopForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            visanumber: ['', forms_1.Validators.required],
            visatype: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            visaexpiry: ['', forms_1.Validators.required],
            visastart: ['', forms_1.Validators.required],
            iscurrent: [''],
            stype: ['']
        });
        this.euserid = localStorage.getItem('euserid');
        this.submitted = false;
        this.disableBtn = true;
        this.visaDetail = new visa_model_1.Visa();
        this.visaDetail.nationalityid = "";
        this.visaDetail.employeeid = this.euserid; // '2';
        this.visaDetail.employeevisadeatilid = "0";
        this.accesstoken = localStorage.getItem('logid'); //this.activatedRoute.snapshot.paramMap.get("token");
        // this.accesstoken = this.activatedRoute.snapshot.paramMap.get("access_token");
        // this.activatedRoute.queryParams.subscribe(params => {
        //   this.accesstoken = params['access_token'];
        //   this.is_rtw = params['is_rtw'];
        //   localStorage.setItem('userToken', this.accesstoken);
        //   console.log(this.accesstoken);
        // this.check.gettokensystem().subscribe((data: any) => { 
        //   if (data.sussess == 'true') {
        //     localStorage.setItem('userid', data.userid);
        //     localStorage.setItem('emptype', data.emptype);
        //     localStorage.setItem('isline', data.isline);
        //     localStorage.setItem('planid', data.planid);
        //     localStorage.setItem('isemail', data.isemail);
        //     localStorage.setItem('isadmin', data.isadmin);
        //     localStorage.setItem('ispermission', data.ispermission);
        //     localStorage.setItem('logoname', data.logourl);
        //     localStorage.setItem('ctype', data.ctype);
        //     const id = localStorage.getItem('userToken');
        //     this.Userid = localStorage.getItem('userid');
        //     const data2 = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
        //     if (data.logourl == "" || this.logo == null) {
        //       this.logo = "assets/img/logo.png";
        //     }else
        //     {
        //       this.logo =data.logourl;
        //     }
        //     this.wizard = 0;
        //     this.employeemasterid = data.userid;
        //     this.visapopForm = this.formBuilder.group({
        //       id: ['', Validators.required],
        //       employeeid: ['', Validators.required],
        //       visanumber: ['', Validators.required],
        //       visatype: ['', Validators.required],
        //       nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
        //       visaexpiry: ['', Validators.required],
        //       visastart: ['', Validators.required],
        //       iscurrent: [''],
        //       stype: ['']
        //     });
        //     this.submitted = false;
        //     this.disableBtn = true;
        //     this.visaDetail = new Visa();
        //     this.visaDetail.nationalityid = "";     
        //     this.Userid = localStorage.getItem('userid');
        //     var dd =  localStorage.getItem('userid');
        //     this.visaDetail.employeeid = dd;//this.Userid;
        //     this.visaDetail.employeevisadeatilid = "0";
        //     this.filename = "Choose file";
        //     this.profilepic = "Choose file";        
        //     this.filebackname = 'Choose file';
        //   }
        //   else
        //   {
        //     this.token_expired = true;
        //   }
        // });
        // });
        this.getCountries();
    };
    RtwVisaComponent.prototype.ngOnDestroy = function () {
        if (this.subscription && this.subscription instanceof Subscription_1.Subscription) {
            this.subscription.unsubscribe();
        }
    };
    RtwVisaComponent.prototype.getCountries = function () {
        var _this = this;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
            // console.log(this.countrylist);
        });
    };
    Object.defineProperty(RtwVisaComponent.prototype, "f", {
        get: function () {
            return this.visapopForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    RtwVisaComponent.prototype.toggle = function (id, prev) {
        var _this = this;
        if (prev === void 0) { prev = ''; }
        if (id == 1) {
            this.submitted = true;
            // console.log(this.visapopForm);
            if (this.visapopForm.invalid) {
                return;
            }
        }
        if (id == 2) {
            if (this.webcamImage) {
                var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
                setTimeout(function () { return _this.frontimage = imagepath; }, 5000);
                this.webcamImage = null;
            }
        }
        else if (id == 3) {
            if (this.webcamImage) {
                var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
                setTimeout(function () { return _this.backimage = imagepath; }, 5000);
                // console.log(this.imgsrc);
                this.webcamImage = null;
            }
            if (this.profile_image) {
                if (prev) {
                    this.toggle(2);
                    return;
                }
                this.toggle(4);
                return;
            }
        }
        else if (id == 4) {
            if (this.webcamImage) {
                var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
                setTimeout(function () { return _this.profileimage = imagepath; }, 5000);
                // console.log(this.imgsrc);
                this.webcamImage = null;
            }
            if (this.profile_image) {
                this.profileimage = this.profile_image;
            }
            this.setTimer();
        }
        this.wizard = id;
    };
    RtwVisaComponent.prototype.toggle2 = function (id) {
        // if(id == 1){
        //   this.wizard2 = false;
        //   this.wizard1 = true;
        //   this.wizard3 = false;
        // }
        // if(id == 2){
        //   this.wizard3 = false;
        //   this.wizard1 = false;
        //   this.wizard2 = true;
        // }
    };
    RtwVisaComponent.prototype.onSubmit = function (value3, id, stype) {
        var _this = this;
        this.submitted = true;
        this.disableBtn = false;
        this.wizard = 6;
        value3.imagename = this.frontimage;
        value3.imagebackname = this.backimage;
        value3.visaprofileimage = this.profileimage;
        value3.isrighttowork = 1;
        value3.visastart = this.datePipe.transform(new Date(value3.visastart), "dd MMM yyyy");
        value3.visaexpiry = this.datePipe.transform(new Date(value3.visaexpiry), "dd MMM yyyy");
        value3.stype = stype;
        value3.createby = this.euserid;
        this.visaService.myvisadetailsUpdatebyadminrighttowork(value3).subscribe(function (datamain) {
            // console.log(this.docs);
            // console.log(this.accesstoken);
            var logupdate = {
                id: _this.accesstoken,
                remaindocumentlist: _this.docs,
                profileimage: value3.visaprofileimage,
                visaid: datamain.employeevisadeatilid,
                isstatus: (_this.docs.length) ? 0 : 1
            };
            _this.checkService.updatenewrighttowork(logupdate).subscribe(function (respdata) {
                localStorage.setItem('profile_pic', respdata.profileimage);
                _this.performAction.emit();
                // console.log(respdata);
            });
            // var data3 = {"employeeidproofdeatilid": datamain.employeevisadeatilid}  
            // var cid = {id: value3.countryid}
            // this.country.getcountrylistbyid(cid).subscribe((countrydata: any) => {  
            //   var countryiso = countrydata.isothree;
            //   var idtype = 'VISA';   
            //   var visalog = {
            //     employeeid: this.employeemasterid,
            //     documentid: datamain.employeevisadeatilid,
            //     documenturl: value3.imagename,
            //     documenttype: 'v'
            //   };
            //   this.visaService.visaailogupdate(visalog).subscribe((dbvisaresult: any) => {        
            //     if(dbvisaresult.success =='1'){
            //       var data = { 
            //         merchantIdScanReference: dbvisaresult.logid, 
            //         country: countryiso, 
            //         idType: idtype,
            //         customerId: this.employeemasterid,
            //         is_rtw: this.is_rtw,
            //         callbackUrl: AppGlobals.API_ENDPOINT +"/api/aiconfirmation",
            //         documenturl: value3.imagename,
            //         documentbackurl: value3.imagebackname, 
            //         imageurl: value3.visaprofileimage
            //       };  
            //       this.visaService.visadocumentupdate(data).subscribe((data5: any) => {
            // if(data5.success == true){
            // this.check.deleteAccessToken().subscribe((tokenData: any) => {
            // if(tokenData.success)
            // {
            // localStorage.removeItem('userToken');
            // localStorage.removeItem('userid');
            // localStorage.removeItem('emptype');
            // localStorage.removeItem('isline');
            // localStorage.removeItem('planid');
            // localStorage.removeItem('isemail');
            // localStorage.removeItem('isadmin');
            // localStorage.removeItem('ispermission');
            // localStorage.removeItem('logoname');
            // localStorage.removeItem('ctype');
            // this.wizard = 5;
            // }
            // });
            // }else {
            //   this.wizard = 4;
            //   this.alerts.error("something went wrong please try again");
            // }          
            //       });
            //       }
            //   });
            // });   
        });
        this.filename = "Choose file";
        this.alerts.success('Document Uploaded Successfully.', true);
    };
    RtwVisaComponent.prototype.sanitize = function (url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    };
    RtwVisaComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.fileformaterror = true;
        }
        else {
            this.fileformaterror = false;
            this.filesizeerror = false;
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.imgURL = reader.result;
            };
            // this.selectedFiles = files[0]; 
        }
    };
    RtwVisaComponent.prototype.selectbackFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filebackname = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filebacksizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.filebackformaterror = true;
            this.filebacksizeerror = false;
        }
        else {
            this.filebackformaterror = false;
            this.filebacksizeerror = false;
            this.selectedbackFiles = files[0];
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.imgbackURL = reader.result;
            };
        }
    };
    RtwVisaComponent.prototype.selectprofile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.profilepic = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.pro_pic_size_error = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.pro_pic_format_error = true;
            this.pro_pic_size_error = false;
        }
        else {
            this.pro_pic_format_error = false;
            this.pro_pic_size_error = false;
            this.selected_profile_image = files[0];
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.profile_pic_url = reader.result;
            };
        }
    };
    RtwVisaComponent.prototype.setTimer = function () {
        var _this = this;
        this.showloader = true;
        this.timer = rxjs_1.Observable.timer(5000);
        this.subscription = this.timer.subscribe(function () {
            _this.showloader = false;
        });
    };
    RtwVisaComponent.prototype.triggerSnapshot = function () {
        this.trigger.next();
    };
    RtwVisaComponent.prototype.triggerSnapshotreload = function () {
        this.webcamImage = null;
        //this.trigger.next();
    };
    RtwVisaComponent.prototype.toggleWebcam = function () {
        this.showWebcam = !this.showWebcam;
    };
    RtwVisaComponent.prototype.handleInitError = function (error) {
        this.errors.push(error);
    };
    RtwVisaComponent.prototype.showNextWebcam = function (directionOrDeviceId) {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    };
    RtwVisaComponent.prototype.handleImage = function (webcamImage) {
        // console.info('received webcam image', webcamImage);  
        this.webcamImage = webcamImage;
    };
    RtwVisaComponent.prototype.cameraWasSwitched = function (deviceId) {
        // console.log('active device: ' + deviceId);
        this.deviceId = deviceId;
    };
    Object.defineProperty(RtwVisaComponent.prototype, "triggerObservable", {
        get: function () {
            return this.trigger.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RtwVisaComponent.prototype, "nextWebcamObservable", {
        get: function () {
            return this.nextWebcam.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return RtwVisaComponent;
}());
exports.RtwVisaComponent = RtwVisaComponent;
