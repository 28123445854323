"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_model_1 = require("../../../models/employee.model");
var country_service_1 = require("../../../services/country.service");
var employee_service_1 = require("../../../services/employee.service");
var employeement_service_1 = require("../../../services/employeement.service");
var job_service_1 = require("../../../services/job.service");
var modal_service_1 = require("../../../_services/modal.service");
var project_service_1 = require("../../../services/project.service");
var leave_service_1 = require("../../../services/leave.service");
var excel_service_1 = require("../../../services/excel.service");
var app_global_1 = require("../../../app.global");
var router_1 = require("@angular/router");
var ViewleavereportComponent = /** @class */ (function () {
    function ViewleavereportComponent(excelService, formBuilder, project, leave, country, jobser, employee, employeementreport, route, modalService) {
        var _this = this;
        this.excelService = excelService;
        this.formBuilder = formBuilder;
        this.project = project;
        this.leave = leave;
        this.country = country;
        this.jobser = jobser;
        this.employee = employee;
        this.employeementreport = employeementreport;
        this.route = route;
        this.modalService = modalService;
        this.employeeDetail = new employee_model_1.Employee();
        this.exceldata = [];
        route.params.subscribe(function (params) {
            if (params['id']) {
                _this.empid = params['id'];
            }
        });
        route.queryParams.subscribe(function (params) {
            _this.employeemonth = params['month'];
            _this.employeeyear = params['year'];
            _this.hstatus = params['status'];
        });
    }
    ViewleavereportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        this.graphdisplay = "block";
        this.listdisplay = "none";
        this.employeeyear = (new Date()).getFullYear();
        this.employeemonth = '';
        this.EmployeeForm = this.formBuilder.group({
            emailid: [''],
            phonenumber: [''],
            ninumber: [''],
            minstartdate: [''],
            maxstartdate: [''],
            minenddate: [''],
            maxenddate: [''],
            clientid: [''],
            minsalary: [''],
            maxsalary: [''],
            projectid: [''],
            fname: [''],
            hstatus: [''],
            searctext: ['']
        });
        var value3 = "{}";
        this.employee.getemployeelist(value3).subscribe(function (listdetails) {
            _this.employeelist = listdetails;
        });
        // var values3 = {};
        // this.leave.leavereport(values3).subscribe((leavereport: any) => {
        //   this.data = leavereport;
        // });
        this.leavetypereport();
        this.leave.getholidaylist().subscribe(function (holidaydetails) {
            _this.holidaytype = holidaydetails;
        });
    };
    ViewleavereportComponent.prototype.showcondition = function (type) {
        if (type == 'list') {
            this.graphdisplay = "none";
            this.listdisplay = "block";
        }
        else {
            this.listdisplay = "none";
            this.graphdisplay = "block";
        }
    };
    ViewleavereportComponent.prototype.exportAsXLSX = function () {
        var _this = this;
        this.exceldata = [];
        this.data.forEach(function (obj) {
            if (obj.iscurrent == false) {
                var current = "No";
            }
            else if (obj.iscurrent == true) {
                current = "Yes";
            }
            _this.employeeleavedata = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "Title": obj.title,
                "Start Date": obj.startdate,
                "End Date": obj.enddate,
                "Leave Type": obj.holidaytype.typename
            };
            _this.exceldata.push(_this.employeeleavedata);
        });
        this.excelService.exportAsExcelFile(this.exceldata, 'sample');
    };
    ViewleavereportComponent.prototype.leavetypereport = function () {
        var _this = this;
        this.data = [];
        var empcheck = true;
        var values3 = {};
        values3 = { 'month': this.employeemonth, 'year': this.employeeyear, 'employeeid': this.empid, 'hstatus': this.hstatus };
        this.leave.leavereport(values3).subscribe(function (leavereport) {
            // this.record = leavereport.length;
            _this.data = leavereport;
            _this.record = _this.data.length;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    ViewleavereportComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    ViewleavereportComponent.prototype.changeEmployee = function (id) {
        this.empid = id;
        this.leavetypereport();
    };
    ViewleavereportComponent.prototype.changeEmployeeMonth = function (month) {
        this.employeemonth = month;
        this.leavetypereport();
    };
    ViewleavereportComponent.prototype.changeEmployeeYear = function (year) {
        this.employeeyear = year;
        this.leavetypereport();
    };
    ViewleavereportComponent.prototype.changeEmployeestatus = function (hstatus) {
        this.hstatus = hstatus;
        this.leavetypereport();
    };
    ViewleavereportComponent.prototype.openModalnote = function (data, id) {
        this.notedetails = data;
        this.modalService.open(id);
    };
    ViewleavereportComponent.prototype.openModal = function (id) {
        this.modalService.open(id);
    };
    ViewleavereportComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    ViewleavereportComponent.prototype.onSubmit = function (value3) {
    };
    return ViewleavereportComponent;
}());
exports.ViewleavereportComponent = ViewleavereportComponent;
