<style>
  .card {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);

    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    text-align: center;
  }

  .plan-card .plan-price span {
    font-size: 15px;
    vertical-align: top;
  }

  .card-block {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
  }

  .plan-card .plan-features p span {
    font-size: 12px;
    line-height: 18px !important;
    width: 100%;
    float: left;
  }

  .text-pink {
    color: #f06292;
  }

  .text-primary {
    color: #67a8e4 !important;
  }

  .text-teal {
    color: #009688;
  }

  .jw-modal {
    max-width: 30%;
    top: 30%;
  }

  .StripeElement {
    box-sizing: border-box;

    height: 40px;

    padding: 10px 12px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  .jw-modal {
    top: 30% !important;
    max-width: 440px !important;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 104px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked+.slider {
    background-color: #2196F3;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .on {
    display: none;
  }

  .on,
  .off {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 38%;
    font-size: 19px;
    font-family: Verdana, sans-serif;
  }

  input:checked+.slider .on {
    display: block;
  }

  input:checked+.slider .off {
    display: none;
  }
</style>

<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
      <div class="tabs" id="tabs">
        <div class="tabss__content">
          <div class="tabss__content__item active-tab">
            <div class="content">
              <div class="row row-sm topheader">
                <div class="col-lg-4">
                  <!-- <h4>Plans </h4> -->
                </div>
                <div class="col-lg-4">
                  &nbsp;
                </div>
                <div class="col-lg-4">
                  <h6 class="text-right float-right">
                    <a (click)="cardlistopen('custom-cardlist-1')" class="addscreensuccess">+ Payment Method</a></h6>
                </div>
              </div>
              <div class="row text-center">
                <div class="col-lg-12 pull-left">
                  <div class="onoffswitch1">
                    <input type="checkbox" name="plantype" class="onoffswitch-checkbox" id="plantype"
                      (change)="selectPlanType($event.target.checked);">
                    <label class="onoffswitch-label" for="plantype">
                      <span class="onoffswitch-inner1"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row text-center">
                <div class="col-lg-4" *ngFor="let pl of planlist; let i=index">
                  <div class="card plan-card">
                    <div class="card-block">
                      <div class="pb-3">
                        <h1><i class="ion-plane plan-icon"></i></h1>
                        <h6 class="text-uppercase text-primary">{{ pl.planname }}</h6>
                      </div>
                      <div>
                        <h1 class="plan-price padding-b-15">£ {{ pl.prices }}<span class="text-muted m-l-10">
                            <sup *ngIf="!pl.plantype">Per Month</sup><sup *ngIf="pl.plantype">Per Year</sup>
                          </span></h1>
                        <div class="plan-div-border"></div>
                      </div>
                      <div class="plan-features pb-3 mt-3 text-muted padding-t-b-30">
                        <!-- <p>{{ pl.totaluser }} admin</p> -->
                        <div *ngFor="let pl3 of pl.plande">
                          <div innerHTML="{{pl3.descname}}" class="plan-des-list"></div>
                        </div>
                        <button class="demo btn-primary btn-lg" style="background-color: gray;border: 1px solid gray;"
                          [disabled]="true" *ngIf="pl.planid < planid"
                          (click)="buySubscription(pl.stripplanid, pl.planid)">Downgrade</button>
                        <button class="demo btn-primary btn-lg" style="background-color: gray;border: 1px solid gray;"
                          [disabled]="true" *ngIf="pl.planid == planid"
                          (click)="buySubscription(pl.stripplanid, pl.planid)">Current Plan</button>
                        <button class="demo btn-primary btn-lg" [disabled]="false" *ngIf="pl.planid > planid"
                          (click)="buySubscription(pl.stripplanid, pl.planid)">Upgrade</button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-lg-4 offset-4 mt-3">
          <div class="card plan-card text-center">
            <div id="pnlCouponAllow" class="card-block">
              <p>Any Discount Coupon</p>
              <button class="demo btn-primary btn-lg" data-toggle="modal" (click)="openModal('custom-coup-1')"
                id="df">Click Here</button>
            </div>
          </div>
        </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<jw-modal id="custom-cardlist-1" role="dialog" class="modal">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="popupLabel">Payment Method</h5>
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
          (click)="closeModal('custom-cardlist-1');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row row-sm topheader">
            <div class="col-lg-4">
              <h4>&nbsp; </h4>
            </div>
            <div class="col-lg-4">
              &nbsp;
            </div>
            <div class="col-lg-4">
              <h6 class="text-right float-right">
                <a (click)="updateyourplan('custom-payment-1')" class="addscreensuccess">+ Add New Card</a></h6>
            </div>
          </div>
          <div class="row" *ngIf="cardlist">
            <div class="col-lg-4" *ngFor="let cad of cardlist; let i=index">
              <p>
                <b>Card Number: </b> xxxx-xxxx-xxxx-{{cad.last4}}
              </p>
              <p>
                <b>Expiry: </b>{{cad.exp_month}} / {{cad.exp_year}}
              </p>
              <p>
                <button (click)="removeCard(cad.id);" *ngIf="i !=0">Remove</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</jw-modal>

<jw-modal id="custom-payment-1" role="dialog" class="modal">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="popupLabel">Add New Card</h5>
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
          (click)="closeModal('custom-payment-1');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="invalidError" style="color:red">
        {{ invalidError.message }}
      </div>
      <stripe-card #stripeCard (catch)="onStripeError($event)" [(invalid)]="invalidError"
        (tokenChange)="setStripeToken($event, 'custom-payment-1')"></stripe-card>
      <button type="button" class="btn btn-primary mt-4" (click)="stripeCard.createToken(extraData)">Add Card</button>
    </div>
  </div>
</jw-modal>