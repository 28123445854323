"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var user_service_1 = require("../services/user.service");
var message_model_1 = require("../models/message.model");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var forms_1 = require("@angular/forms");
var customer_service_1 = require("../services/customer.service");
var alert_service_1 = require("../services/alert.service");
// import { User} from '../../shared/user.model';
// import { EmailValidator } from '@angular/forms';
var router_1 = require("@angular/router");
var ClientreplyComponent = /** @class */ (function () {
    function ClientreplyComponent(formBuilder, activatedRoute, userService, router, alerts, csservices) {
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.userService = userService;
        this.router = router;
        this.alerts = alerts;
        this.csservices = csservices;
        this.submitted = false;
        this.isLoginError = false;
        // loading: boolean;
        this.user = new message_model_1.Message();
        this.access_token = '';
        this.userid = '';
        this.name = '';
        this.isline = '';
        this.planid = '';
        this.error = '';
        this.isemail = '';
        this.isadmin = '';
        this.ispermission = '';
        this.msg = '';
        this.success = '0';
    }
    ClientreplyComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.registerForm = this.formBuilder.group({
            message: ['', [forms_1.Validators.required]],
            token: ['', [forms_1.Validators.required]]
        });
        this.accesstoken = this.activatedRoute.snapshot.queryParamMap.get("token");
        this.userService.checkaccessbyreply(this.accesstoken).subscribe(function (data) {
            if (data.length == '0') {
                _this.user.token = _this.accesstoken;
                _this.error = 'true';
                _this.msg = '';
                _this.alerts.error('Invalid Token Details', true);
            }
            else {
                _this.user.token = _this.accesstoken;
                _this.error = '';
                if ((data[0].logoname == "") || (data[0].logoname == null)) {
                    _this.logo = "assets/img/logo.png";
                }
                else {
                    _this.logo = data[0].logoname;
                }
                _this.msg = 'true';
            }
        });
    };
    Object.defineProperty(ClientreplyComponent.prototype, "f", {
        get: function () { return this.registerForm.controls; },
        enumerable: true,
        configurable: true
    });
    ClientreplyComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.userService.userMessageReply(this.user).subscribe(function (data) {
            _this.success = '1';
            _this.error = '';
            _this.msg = '';
        }, function (err) {
            _this.isLoginError = true;
        });
    };
    return ClientreplyComponent;
}());
exports.ClientreplyComponent = ClientreplyComponent;
