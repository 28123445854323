import { Component, OnInit } from '@angular/core';
import { CandidateService } from '../../../../services/candidate.service';
import { ActivatedRoute } from '@angular/router';
import { Candidate, Document } from '../../../../models/jobs/candidate.model';
import { ModalService } from '../../../../_services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../../services/alert.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFileService } from '../../../../services/upload-file.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-applicantdocument',
  templateUrl: './applicantdocument.component.html',
  styleUrls: ['./applicantdocument.component.css']
})
export class ApplicantdocumentComponent implements OnInit {
  applicantDetailmain= new Candidate();
  Userid: any;
  candidateid: any;
  candidateDetail = new Candidate();
  applicantdocumentlist: any;
  iframecandidatesrc: any;
  imgcandidatesrc: any;
  documentnamelist:any;
  submitted:any;
  selectedFiles: FileList;
  docperm: any;
  file: any;
  permission: any;
  documentGroup: FormGroup;

  constructor(private sanitizer: DomSanitizer,private candidate: CandidateService,
    private modalService: ModalService,
    private route: ActivatedRoute, private uploadService: UploadFileService, 
    private alerts: AlertService,
    private router: Router,
    private formBuilder: FormBuilder) { 

      route.params.subscribe(params => {
        this.Userid = localStorage.getItem('userid');
        this.candidateid = params['candidateid'];
        this.candidate.getcandidateById({ "id": this.Userid, "candidateid": this.candidateid }).subscribe((dbdocument: any) => {
          this.applicantDetailmain = dbdocument;
        });
    
      }); 

    }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.docperm = this.permission.m8;


    this.documentGroup = this.formBuilder.group({
      candidatedocumentdetailsid: [''],
      documentname: ['', Validators.required] ,
      candidateid: ['', Validators.required]     
    });


   

  }

  get f() { return this.documentGroup.controls; }

  closedocumentModal(id: any){
    this.modalService.close(id);
  }
  opendocumnentname(id: any, employeeid: any){
    var reqType = { 'documentid':id ,'candidateid':employeeid }
    this.candidate.getapplicantdocumentbyid(reqType).subscribe((data: any) => {
      this.documentnamelist = data;
      this.modalService.open('employee-doc-name-update');
    });
  }

  onRenameDocumentSubmit(data: any){
    this.submitted =true;
  
    if (this.documentGroup.invalid) {    
      return;
    }


    this.candidate.updateapplicantdocumentbyid(data).subscribe((data2: any) => {
      if(data2.susses == 'true'){
        var employeeid = data.employeeid;
        this.documentnamelist = null;
        this.Userid = localStorage.getItem('userid');
        this.candidate.getcandidateById({ "id": this.Userid, "candidateid": this.candidateid }).subscribe((data3: any) => {
          this.applicantDetailmain = data3;
          this.modalService.close('employee-doc-name-update');    
        });         
        this.alerts.success('Document name updated successfully');
      }else{
        this.alerts.error('Somthing went wrong'); this.modalService.close('employee-doc-name-update');
      }     
    });
  }


  deleteFile(id: string)
  {
    var deleteData = {};
    
    deleteData = { 'id': id, 'candidateid': this.candidateid };
    
    if (confirm("Are you sure you want to delete?")) {
      this.candidate.deletecandidatedocumentFile(deleteData).subscribe((data: any) => {
        if(data.success)
        {
          this.alerts.success("Document deleted successfully", true);
          this.candidate.getcandidateById({ "id": this.Userid, "candidateid": this.candidateid }).subscribe((dbdocument: any) => {
            this.applicantDetailmain = dbdocument;
          });
        }else{
          this.alerts.error("Something went wrong",true);
        }
        
        // this.candidate.getdocumentlist({"id": this.Userid, "candidateid": this.candidateid}).subscribe((candidatedocument: any) => {
        //   this.applicantDetailmain = candidatedocument;      
        // }); 
      });
    }
  }
  
  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  selectFile(event) {
    var files = event.target.files;
    var selectedFiles = files[0];
    this.selectedFiles = selectedFiles;

    const file = this.selectedFiles;
    var imagepath = this.uploadService.uploadfile(file);
    var documentname = selectedFiles.name.substring(0,10);

    this.file = this.selectedFiles;
    var filetype = this.file.type.split('/');
    if (this.file.size > 10000 * 1024) {
      this.alerts.error('File size should be less than 10MB',true);
      return;  
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "xls") && (filetype[filetype.length - 1] != "xlsx") && (filetype[filetype.length - 1] != "csv"))  {
      this.alerts.error('File format not supported',true);   
      return; 
    }
    var forupdate = { 'documentname': documentname, 'id': "0", 'documentimageurl': imagepath, 'candidateid': this.candidateid, 'userid': this.Userid };
    this.candidate.candidatedocumentupdatebyadmin(forupdate).subscribe((data: any) => 
    {
      this.candidate.getcandidateById({ "id": this.Userid, "candidateid": this.candidateid }).subscribe((dbdocument: any) => {
        this.applicantDetailmain = dbdocument;
      });
      // setTimeout(() => this.overviewData.imagename = imagepath, 2000);
    });
    this.alerts.success('Candidate document uploaded successfully.',true); 
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  openModalcandidate5(id: string, data: any) {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgcandidatesrc = true;
      this.iframecandidatesrc = data;
    }
    else {
      this.imgcandidatesrc = false;
      this.iframecandidatesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

}
