
<div class="overviewsshow">
    <div class="header">
        <h1 class="headprofile mt-0">Compensation </h1>
        <a (click)="openModal2('custom-compensation-info-1')" class="addscreensuccess">Add Compensation</a>
    </div>
    <div *ngFor="let filterItem of listcheck" class="form-check col-lg-2" style="padding-right:20px;">
        <label class="form-check-label billed-to">
            <input type="checkbox" (ngModelChange)="onlistchange($event,filterItem.id)" [(ngModel)]="filterItem.selected"
            [value]="filterItem.id"> {{ filterItem.name }}
        </label>
    </div>
    <div class="row">
        <div class="col-lg-6"  *ngFor="let compens of compensationlist">
            <div class="border-box">
                <div class="addhead header mb-3">
                    <h4>{{compens.nameofbank}}</h4>
                    <div class="icons_box_section">
                        <a (click)="openModal('custom-compensation-info-1',compens)" class="editprime">
                            <i class="lnr lnr-pencil"></i></a>
                        <a (click)="onDelete(compens.id)" class="deletered">
                            <i class="lnr lnr-trash"></i></a>
                    </div>
                </div>
                <div class="overview_details emp">
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3">
                            <span>Account Name</span>
                            <p>{{compens.accountname}}</p>
                        </div>
                        <div class="detailsshow width50">
                            <span>Sort Code</span>
                            <p>{{compens.sortcode}}</p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 mt-2 pr-3">
                            <span>Account Number</span>
                            <p>{{compens.accountnumber}}</p>
                        </div>
                        <div class="detailsshow width50 mt-2" *ngIf="compens.iban != null && compens.iban != ''">
                            <span>IBAN</span>
                            <p>{{compens.iban}} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="job_list_section visa_request_section"  *ngIf="!compensationlist?.length  > false">
        <div class="job_detils_date">
            <p class="job_profile">No Record Found</p>
        </div>
    </div>   
</div>


<jw-modal id="custom-compensation-info-1" class="modal" role="dialog">
    <form [formGroup]="compensationformGroup" #compensationForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(compensationForm.value,'custom-compensation-info-1')" *ngIf="compensationDetail">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="compensationDetail.id">
                    <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="compensationDetail.employeeid">
                    <h5 class="modal-title" id="popupLabel">Compensation Info</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-compensation-info-1');">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Name of Bank*</label>
                                <input type="text" formControlName="nameofbank" name="nameofbank" id="nameofbank" 
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nameofbank.errors }" 
                                    [ngModel]="compensationDetail.nameofbank" maxlength="50">
                                <div *ngIf="submitted && f.nameofbank.errors" class="invalid-feedback">
                                    <div *ngIf="f.nameofbank.errors.required">Please enter name of bank </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Account Name*</label>
                                <input type="text" formControlName="accountname" name="accountname" id="accountname" 
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nameofbank.errors }" 
                                    [ngModel]="compensationDetail.accountname" maxlength="50">
                                <div *ngIf="submitted && f.accountname.errors" class="invalid-feedback">
                                    <div *ngIf="f.accountname.errors.required">Please enter account name</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Sort Code*</label>
                                <input type="text" formControlName="sortcode" name="sortcode" id="sortcode" class="form-control" 
                                    [ngClass]="{ 'is-invalid': submitted && f.nameofbank.errors }" maxlength="20"
                                    [ngModel]="compensationDetail.sortcode">
                                <div *ngIf="submitted && f.sortcode.errors" class="invalid-feedback">
                                    <div *ngIf="f.sortcode.errors.required">Please enter sort code</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Account Number*</label>
                                <input type="text" formControlName="accountnumber" name="accountnumber" id="accountnumber" 
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nameofbank.errors }" 
                                    [ngModel]="compensationDetail.accountnumber" maxlength="20">
                                <div *ngIf="submitted && f.accountnumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.accountnumber.errors.required">Please enter account number</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>IBAN</label>
                                <input type="text" formControlName="iban" id="iban" name="iban" class="form-control" 
                                    [ngClass]="{ 'is-invalid': submitted && f.iban.errors }" [ngModel]="compensationDetail.iban"
                                    maxlength="20">
                                <div *ngIf="submitted && f.iban.errors" class="invalid-feedback">
                                    <div *ngIf="f.iban.errors.required">Please enter IBAN</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-compensation-info-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>