"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var MycosService = /** @class */ (function () {
    function MycosService(commonService) {
        this.commonService = commonService;
        this.modals = [];
    }
    MycosService.prototype.getcosList = function () {
        return this.commonService.formpostOnlyid('/employee/coslist');
        // return  this.http.get(AppGlobals.API_ENDPOINT + '/api/Tblemployecosdetails/get-employee-cos-details');
    };
    MycosService.prototype.getcosListbyadmin = function (value) {
        return this.commonService.formpost('/employee/coslistbyadmin', value);
    };
    MycosService.prototype.mycosdetailsUpdatebyadmin = function (value) {
        return this.commonService.formpost('/employee/cosupdatebyadmin', value);
    };
    MycosService.prototype.mycosDeletebyadmin = function (value) {
        return this.commonService.formpost('/employee/deleteCosbyadmin', value);
    };
    MycosService.prototype.mycosaibyadmin = function (value) {
        return this.commonService.getAI(value);
    };
    MycosService.ngInjectableDef = i0.defineInjectable({ factory: function MycosService_Factory() { return new MycosService(i0.inject(i1.CommonService)); }, token: MycosService, providedIn: "root" });
    return MycosService;
}());
exports.MycosService = MycosService;
