"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var TaxdefaulterService = /** @class */ (function () {
    function TaxdefaulterService(commonService) {
        this.commonService = commonService;
    }
    TaxdefaulterService.prototype.gettaxdefaulter = function (data) {
        return this.commonService.formpost('/taxdefaulter/searchcompany', data);
    };
    TaxdefaulterService.prototype.gettaxdefaulterbyid = function (data) {
        return this.commonService.formpost('/taxdefaulter/searchcompanybyid', data);
    };
    TaxdefaulterService.ngInjectableDef = i0.defineInjectable({ factory: function TaxdefaulterService_Factory() { return new TaxdefaulterService(i0.inject(i1.CommonService)); }, token: TaxdefaulterService, providedIn: "root" });
    return TaxdefaulterService;
}());
exports.TaxdefaulterService = TaxdefaulterService;
