import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EmployeeService } from '../../../services/employee.service';
import { ModalService } from '../../../_services/modal.service';
import { Profile, Termination } from '../../../models/overview/profile.model';
import { CountryService } from '../../../services/country.service';
import { CommonService } from '../../../services/common.service';
import { JobService } from '../../../services/job.service';
import { CandidateService } from '../../../services/candidate.service';
import { UserService } from '../../../services/user.service';
import { PermissionService } from '../../../services/permission.service';
import { PersonaltypeService } from '../../../services/personaltype.service';
import { CompanyService } from '../../../services/company.service';
import { AlertService } from '../../../services/alert.service';
import { Router } from '@angular/router';import { RotaService } from '../../../services/rota.service';
import { CustomerService } from '../../../services/customer.service';
import { WorklistService } from '../../../services/worklist.service';
import {  DatePipe } from '@angular/common';
import { Rota } from '../../../models/rota/rota.model';


import {
  startOfDay,
  endOfDay,
  setMonth,
  setYear,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';

import { AppGlobals } from '../../../app.global';

@Component({
  selector: 'app-employeerotalist',
  templateUrl: './employeerotalist.component.html',
  styleUrls: ['./employeerotalist.component.css']
})
export class EmployeerotalistComponent implements OnInit {
  rota: any;
  data: any;
  datalist:any;
  submitted: any;
  employeeForm: FormGroup;
  terminationForm: FormGroup;
  overviewData: any;
  terminationDetail: any;
  countrylist: any;
  dltypelist: any;
  dllist: any;
  joblist: any;
  candidatelist: any; timeValues: any;
  Userid: any;
  th = [];rotaList: any[];
  ismail: any;
  emailexist = false;
  public searchText: string;
  permissionstring: any;
  mindob = new Date();
  maxdob = new Date();
  phonecheck: any;
  empidexist = false;
  disableBtn: any;
  permission: any;
  empperm: any;
  defaultVal: any = "";
  record:number;
  employeelist:any=[];
  employeelistwithcom:any;
  timeslotError = false;
  timeSlotErrorMessage: any;
  month: any;
  year: number;
  items: Array<any>;
  rotaemployeelist:any;
  
  rotaformGroup: FormGroup;
  locationlist: any[];
  pageOfItems: Array<any>;

  constructor(
    private userService: UserService, 
    private employeeService: EmployeeService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private commonService: CommonService,
    private formBuilder: FormBuilder, 
    private jobdetails: JobService, 
    private rotaService: RotaService,
    private customerService: CustomerService,
    private router: Router,private datePipe: DatePipe,
    private workListService: WorklistService,
    private candidatedetails: CandidateService,
    private permissiondetails: PermissionService,
    private dltypeService: PersonaltypeService,
    private companyService: CompanyService,
    private alerts: AlertService
    ) { }

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }


    this.Userid = localStorage.getItem('userid');

    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.empperm = this.permission.m16;
    this.ismail = false;


    this.timeValues = AppGlobals.TIME_VALUES;

    this.mindob.setDate(this.mindob.getDate() - 23741);
    this.maxdob.setDate(this.maxdob.getDate() - 574);

    this.employeeForm = this.formBuilder.group({
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      empid: ['', Validators.required],
      gender: ['', Validators.required],
      placeofbirth: ['', Validators.required],
      maritalstatus: ['', Validators.required],
      ninumber: ['', Validators.required],
      jobid: [''],
      candidateid: [''],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: ['', Validators.compose([Validators.required])],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      residenceid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      countryofbirthid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      dateofbirth: ['', Validators.required],
      hiredate: ['', Validators.required],
      emprole: ['', Validators.required],
      ismail: ['']
    });

    this.terminationForm = this.formBuilder.group({
      employeeid: ['', Validators.required],
      terminationdate: ['', Validators.required],
      terminationreason: ['', Validators.required],
      oktorehire: ['', Validators.required],
      regrettermination: ['', Validators.required]
    });

    this.overviewData = new Profile();
    this.terminationDetail = new Termination();

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.jobdetails.getjobList().subscribe((jobdetails: any) => {
      this.joblist = jobdetails;
    });

    this.dltypeService.getactivepersonaltypelist().subscribe((dltypes: any) => {
      this.dltypelist = dltypes;
    });

    this.dltypeService.getdrivinglicenselist().subscribe((dlicense: any) => {
      this.dllist = dlicense;
    });

    this.permissiondetails.bindallmenu().subscribe((menudata: any) => {
      var permissionstring = "[{";
      var perarray = [];
      menudata.forEach(function (valuemenu) {
        perarray.push('"' + valuemenu.clname + '": 0');
      });
      
      var perstr = perarray.join();
      permissionstring = permissionstring + perstr + "}]";
      this.permissionstring = permissionstring;
    });

    this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
      employeedata.forEach(function (value) {
        var tg;

        if(value.permission){
          tg = JSON.parse(value.permission.toString());
          tg.forEach(function (value4) {
          });
        }
      });
      this.data = employeedata;
      this.record = this.data.length;
     this.datalist = this.data;
      if(this.record < 30){
        this.pageOfItems = this.data;
      }
      else{
           this.items =this.data;
      }     
    });


    // this.month = (new Date()).getMonth() + 1;
    // this.year = (new Date()).getFullYear();
    // this.classactive = (new Date()).getMonth().toString();
    // this.employee_id = this.activatedRoute.snapshot.paramMap.get("employeeid");
    // this.view ='week';
    // this.timeValues = AppGlobals.TIME_VALUES;
    // this.viewDate = new Date();
    this.rotaformGroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeId: ['', Validators.required],
      userId: ['', Validators.required],
      locationId: ['', Validators.required],
      title: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      starttime: ['', Validators.required],
      endtime: ['', Validators.required]
    });

    this.workListService.getlocationlist().subscribe((locationlist: any) => {
      this.locationlist = locationlist;
    });

  }

  selectemployeemaster(checked:any, employeemasterid:any)
  {

    console.log(employeemasterid);
    if(checked)
    {
      this.employeelist.push(employeemasterid);     
    }
    else
    {     
      const index = this.employeelist.indexOf(employeemasterid);
      if (index > -1) 
      {
        this.employeelist.splice(index, 1);
      }      
    }

    this.employeelistwithcom = this.employeelist.join(',')
  }

  
onChangePage(pageOfItems: Array<any>) {
  // update current page of items
  this.pageOfItems = pageOfItems;
}

  get f() { return this.employeeForm.controls; }

  
  get t() { return this.rotaformGroup.controls; }

  get g() { return this.terminationForm.controls; }

  onSubmit(employeeform1: any, modelid: any) {
    this.submitted = true;
    if (this.employeeForm.invalid) {
      return;
    }
    this.disableBtn = false;
    
    employeeform1.id = this.Userid;
    employeeform1.permission = this.permissionstring;
    this.userService.isEmailRegisterd(employeeform1.emailid).subscribe((data: any) => {
      if (data.sussess == 'success') {
        this.emailexist = true; 
        this.disableBtn = true;
      }
      else {
        this.companyService.checkcompanyemployeeid({'empid': employeeform1.empid}).subscribe((checkempid: any) => {
          if(checkempid.sussess == 'success')
          {
            this.empidexist = true; 
            this.disableBtn = true;
          }
          else
          {
            this.disableBtn = false;
            this.employeeService.employeeUpdateForm1(employeeform1).subscribe((data: any) => {
              this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
                this.data = employeedata;
                this.modalService.close(modelid);
              });
            });
          }
          
        })
        
      }
    });
  }

  onSubmitTermination(terminationform: any, modelid: any) {
    this.submitted = true;
    if (this.terminationForm.invalid) {
      return;
    }

    this.employeeService.employeeSubmitTermination(terminationform).subscribe((data: any) => {
      this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
        this.data = employeedata;
        this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
          employeedata.forEach(function (value) {
            var tg;
            tg = JSON.parse(value.permission.toString());
            tg.forEach(function (value4) {
            });
          });
          this.data = employeedata;
          this.record = this.data.length;
    
          if(this.record < 30){
            this.pageOfItems = this.data;
          }
          else{
               this.items =this.data;
          }          
          this.alerts.success('Employee has been terminated successfully');

          this.modalService.close(modelid);
        });        
      });
    });
  }

  onChangeStatus(evt: any, emp: any)
  {
    var status;
    if(evt.target.checked)
    {
      status = '1';
    }
    else if(!evt.target.checked)
    {
      status = '0';
    }
    this.employeeService.employeeUpdateChangeStatus({'userid': this.Userid, 'id': emp.employeemasterid, 'estatus': evt.target.checked}).subscribe((data: any) => {
      if(status =='1')
      {
        this.alerts.success('Employee has been activated successfully');
      }else if(status == '0')
      {
        this.alerts.error('Employee has been deactivated successfully');
      }
    });
  }

  focusFunction() {
    this.emailexist = false;
  }

  isEmailUnique(control: FormControl) {
    const q = new Promise((resolve, reject) => {
      this.userService.isEmailRegisterd(control.value).subscribe(() => {
        resolve(null);
      }, () => { resolve({ 'isEmailUnique': true }); });

    });
    return q;
  }

  onSelectjob(job_id: number) {
    const data = { 'jobid': job_id, 'id': this.Userid };
    this.candidatedetails.getselectedcandidate(data).subscribe((candidatedetails: any) => {
      return this.candidatelist = candidatedetails
    });
  }

  onSelectCandidate(candidateid: any) {
    const data = { 'candidateid': candidateid, 'id': this.Userid };
    this.candidatedetails.getselectedcandidatebyid(data).subscribe((candidatedetails: any) => {
      this.overviewData.fname = candidatedetails.fname;
      this.overviewData.mname = candidatedetails.mname;
      this.overviewData.lname = candidatedetails.lname;
      this.overviewData.phonenumber = candidatedetails.phonenumber;
      this.overviewData.emailid = candidatedetails.emailid;
      // return this.candidatelist = candidatedetails
    });
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
     
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  openModal2(id: string) {
    this.submitted = false;
    this.disableBtn = true;
    this.overviewData = new Profile();
    this.overviewData.gender = "1";
    this.overviewData.maritalstatus = "0";
    this.phonecheck = "";
    this.overviewData.jobid = "";
    this.overviewData.candidateid = "";
    this.overviewData.nationalityid = "";
    this.overviewData.residenceid = "";
    this.overviewData.countryofbirthid = "";
    this.overviewData.ismail = false;
    
    this.companyService.checkPlan({'userid': this.Userid}).subscribe((checkemployeecount: any) => {
      if(checkemployeecount.success == "true")
      {
        // console.log(this.overviewData);
        this.modalService.open(id);
      }
      else
      {
        this.modalService.open("upgrade-plan");
      }
    });
    
  }

  closeModal(id: string) {
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.modalService.close(id);
  }


  openrotasystem(empid: string, id: string)
  {

    this.rota = new Rota();
    this.rota.startDate =new Date(); //new Date().toLocaleDateString();// new Date(start);
    this.rota.endDate =new Date(); //new Date().toLocaleDateString(); //new Date(start);
    this.Userid = localStorage.getItem('userid');
    this.rota.employeeId = this.Userid;
    this.rota.locationId ="";
    this.rota.starttime = "00:00";
    this.rota.endtime = "00:00";

    this.customerService.employeedetailsrotasystem({'employeelist': empid}).subscribe((employeeData: any) => {
      console.log('employee list',employeeData);

      this.rotaemployeelist = employeeData;
      // this.employeeName = employeeData.fname + " " + employeeData.lname;
      
    });

    this.modalService.open(id);
  }


  
  onSubmitrota(rota, id) {
    this.submitted = true;
    if (this.rotaformGroup.invalid) {
      return;
    }
    this.disableBtn = false;
    let newDate = new Date(rota.startDate);
    let endDate = new Date(rota.endDate);
    // console.log(rota.startDate);
    rota.start =this.datePipe.transform(new Date(rota.startDate),"dd MMM yyyy");
    rota.end =this.datePipe.transform(new Date(rota.endDate),"dd MMM yyyy");
    // console.log(rota.startDate);
    rota.startDate = new Date(rota.startDate);
    rota.endDate = new Date(rota.endDate);
    rota.startDate.setHours(rota.starttime);
    rota.endDate.setHours(rota.endtime);
    var retVal = [];
    var checkStartDate = rota.startDate;
    var checkEndDate = rota.endDate;
    while (rota.startDate <= rota.endDate) {
      retVal.push(new Date(rota.startDate));
      rota.startDate = addDays(rota.startDate, 1);
    }
    var itemsProcessed = 0;

    /* check time slot between entry dates */
    this.rotaService.getRotaByDate({'employeeId': this.employeelistwithcom, 'startDate': checkStartDate, 'endDate': new Date(checkEndDate.getFullYear(), checkEndDate.getMonth(), checkEndDate.getDate(), 23, 59, 59)}).subscribe((data: any) => {
      var checkRecord = true;
      
      data.forEach(element => {
        if(
          ((rota.starttime > element.starttime)
           && (rota.starttime < element.endtime))
           || ((rota.endtime > element.starttime)
           && (rota.endtime < element.endtime))
        )
        {
          checkRecord = false;
        }
      });
      // console.log(checkRecord);
      // return;
      if(!checkRecord)
      {
        this.timeslotError = true;
        this.disableBtn = true;
        this.timeSlotErrorMessage = "Timeslot is overlapping. Please change."
        return;
      }
      retVal.forEach(single_dates => {
        var rotaToSave = rota;
        rotaToSave.startDate = new Date(single_dates.setHours(rotaToSave.starttime));
        rotaToSave.endDate = new Date(single_dates.setHours(rotaToSave.endtime));
        this.rotaService.rotaCreateOrUpdate(rotaToSave).subscribe((data: any) => {
          itemsProcessed++;
          if(itemsProcessed === retVal.length) {
            this.rotaList = [];
            // this.bindallData();
            document.getElementById('closeModal').click();
          }
        });
      });
    });
    
    // console.log(retVal);
    
  }


  openModelTerminate(empid: string, id: string)
  {
    this.submitted = false;
    this.terminationDetail.employeeid = empid;
    this.terminationDetail.oktorehire = "";
    this.terminationDetail.regrettermination = "";
    this.modalService.open(id);
  }

}
