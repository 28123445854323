<style>
  .card {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    text-align: center;
  }

  .plan-card .plan-price span {
    font-size: 15px;
    vertical-align: top;
  }

  .card-block {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
  }

  .plan-card .plan-features p span {
    font-size: 12px;
    line-height: 18px !important;
    width: 100%;
    float: left;
  }

  .text-pink {
    color: #f06292;
  }

  .text-primary {
    color: #67a8e4 !important;
  }

  .text-teal {
    color: #009688;
  }

  .jw-modal {
    max-width: 30%;
    top: 30%;
  }

  .StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  .jw-modal {
    top: 30% !important;
    max-width: 440px !important;
  }
</style>

<div class="slim-header">
  <div class="container">
    <div class="slim-header-left">
      <h2 class="slim-logo"><a  [routerLink]="['/']" ><img src="/assets/img/logo.png" alt=""><span></span></a></h2>
      <!-- search-box -->
    </div>
    <div class="dropdown dropdown-b"> <a  class="header-notification" data-toggle="dropdown" (click)="logout()"
        title="Logout"> <i class="fa fa-power-off"></i> </a></div>
  </div>
</div>
<div class="signin-wrapper">
  <div class="" style="width:90%;">
    <div class="row text-center">
      <div class="col-lg-12 pull-left">
        <div class="onoffswitch1">
          <input type="checkbox" name="plantype" class="onoffswitch-checkbox" id="plantype"
            (change)="selectPlanType($event.target.checked);">
          <label class="onoffswitch-label" for="plantype">
            <span class="onoffswitch-inner1"></span>
            <span class="onoffswitch-switch"></span>
          </label>
        </div>
        <!-- <label class="switch">
                <input type="checkbox" name="plantype" (change)="selectPlanType($event.target.checked);">
                <span class="slider round"><span class="on">Yearly</span><span class="off">Monthly</span></span>
              </label> -->

        <!-- <select name="plantype" (change)="selectPlanType($event.target.value);">
            <option value="0">Monthly</option>
            <option value="1">Yearly</option>
          </select> -->
      </div>
    </div>
    <div class="row text-center">
      <div class="col-lg-4" *ngFor="let pl of planlist; let i=index">
        <div class="card plan-card">
          <div class="card-block">
            <div class="pb-3">
              <h1><i class="ion-plane plan-icon"></i></h1>
              <h6 class="text-uppercase text-primary">{{ pl.planname }}</h6>
            </div>
            <div>
              <h1 class="plan-price padding-b-15">£ {{ pl.prices }}<span class="text-muted m-l-10"><sup
                    *ngIf="!pl.plantype">Per Month</sup><sup *ngIf="pl.plantype">Per Year</sup></span></h1>
              <div class="plan-div-border"></div>
            </div>
            <div class="plan-features pb-3 mt-3 text-muted padding-t-b-30">
              <!-- <p>{{ pl.totaluser }} admin</p> -->
              <div *ngFor="let pl3 of pl.plande">
                <div innerHTML="{{pl3.descname}}" class="plan-des-list"></div>
              </div>
              <button class="demo btn-primary btn-lg"
                (click)="updateyourplan(pl.stripplanid, 'custom-payment-1')">Purchase</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 offset-4 mt-3">
        <div class="card plan-card text-center">
          <div id="pnlCouponAllow" class="card-block">
            <p>Any Discount Coupon</p>
            <button class="demo btn-primary btn-lg" data-toggle="modal" (click)="openModal('custom-coup-1')"
              id="df">Click
              Here</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<jw-modal id="custom-coup-1" role="dialog" class="modal">
  <form [formGroup]="planformGroup" #expenseForm="ngForm" class="s12 white"
    (ngSubmit)="onSubmit(planformGroup.value,'custom-coup-1')">
    <div role="document" class="formgroup">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="popupLabel">Apply Coupons</h5>
          <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
            (click)="closeModal('custom-coup-1');">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="form-group col-lg-12">
                <p>{{message}}</p>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-12">
                <label>Enter your coupons*</label>
                <input type="text" formControlName="coupnsnumber" class="" name="coupnsnumber" id="coupnsnumber"
                  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.coupnsnumber.errors }"
                  ngModel="{{ planDetail.coupnsnumber }}">
                <div *ngIf="submitted && f.coupnsnumber.errors" class="invalid-feedback">
                  <div *ngIf="f.coupnsnumber.errors.required">Please enter your coupons</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <input type="submit" class="btn btn-primary" name="updateform" value="{{buttoninsert}}" />
          <input type="button" class="btn btn-primary" name="updateform" value="Cancel"
            (click)="closeModal('custom-coup-1');" />
        </div>
      </div>
    </div>
  </form>
</jw-modal>

<jw-modal id="custom-payment-1" role="dialog" class="modal">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="popupLabel">Payment</h5>
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
          (click)="closeModal('custom-payment-1');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="invalidError" style="color:red">
        {{ invalidError.message }}
      </div>

      <stripe-card #stripeCard (catch)="onStripeError($event)" [(invalid)]="invalidError"
        (tokenChange)="setStripeToken($event, 'custom-payment-1')"></stripe-card>

      <button type="button" class="btn btn-primary mt-4" (click)="stripeCard.createToken(extraData)">Pay Now</button>
    </div>
  </div>
</jw-modal>