"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var checkservice_service_1 = require("../../../services/checkservice.service");
var router_1 = require("@angular/router");
var checksystem_service_1 = require("../../../services/checksystem.service");
var process_1 = require("process");
var modal_service_1 = require("../../../_services/modal.service");
// import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
// import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
var EmployeeRightToWorkAppComponent = /** @class */ (function () {
    function EmployeeRightToWorkAppComponent(router, modalService, activatedRoute, check, checkService) {
        var _this = this;
        this.router = router;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.check = check;
        this.checkService = checkService;
        this.documentArray = [];
        this.ismobile = false;
        this.linkurl = '';
        this.routerurl = '';
        router.events.filter(function (event) { return event instanceof router_1.NavigationEnd; }).subscribe(function (events) {
            _this.routerurl = router.url;
            _this.linkurl = "https://employee.complygate.co.uk" + router.url + '?type=ukpassport';
            console.log(router.url);
        });
        if (navigator.userAgent.match(/Tablet|iPad/i)) {
            this.ismobile = true;
            // do tablet stuff
        }
        else if (navigator.userAgent.match(/Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i)) {
            this.ismobile = true;
            // do mobile stuff
        }
        else {
            this.ismobile = false;
            // do desktop stuff
        }
        //  let agent = navigator.userAgent;
        //  console.log(agent);
    }
    EmployeeRightToWorkAppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.queryParams.filter(function (params) { return params.type; }).subscribe(function (params) {
            _this.type = params.type;
            localStorage.setItem('fakecard', _this.type);
        });
        // this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
        this.accessToken = this.activatedRoute.snapshot.paramMap.get("token");
        // console.log('token mater',this.accessToken)
        this.checkService.getrighttoworktoken({ token: this.accessToken }).subscribe(function (righttoworklogtoken) {
            _this.str = new String(righttoworklogtoken.otp);
            console.log('token mater System', righttoworklogtoken);
            if (righttoworklogtoken.success == 'success') {
                localStorage.setItem('userToken1', righttoworklogtoken.token);
                localStorage.setItem('logid', righttoworklogtoken.logid);
                localStorage.setItem('euserid', righttoworklogtoken.userid);
                // localStorage.removeItem('userid');
                // localStorage.removeItem('Name');
                // localStorage.removeItem('isline');
                // localStorage.removeItem('planid');
                // localStorage.removeItem('isemail');
                // localStorage.removeItem('isadmin');
                // localStorage.removeItem('ispermission');
                // localStorage.removeItem('ctype');
                // localStorage.removeItem('logoname');
                // localStorage.removeItem('menu');
                _this.checkService.getRightToWorkLogById({ id: righttoworklogtoken.logid }).subscribe(function (righttoworklog) {
                    console.log(righttoworklog);
                    _this.newrighttoworklog = righttoworklog;
                    if (righttoworklog.remaindocumentlist) {
                        _this.documentArray = righttoworklog.remaindocumentlist.split(',');
                    }
                    if (righttoworklog.profileimage) {
                        localStorage.setItem('profile_pic', righttoworklog.profileimage);
                    }
                    else {
                        localStorage.removeItem('profile_pic');
                    }
                    _this.performAction();
                    // console.log(righttoworklog);
                });
            }
            else {
                _this.currentStep = 9;
                localStorage.removeItem('profile_pic');
                localStorage.removeItem('userToken1');
                localStorage.removeItem('euserid');
                // localStorage.removeItem('userid');
                // localStorage.removeItem('Name');
                // localStorage.removeItem('isline');
                // localStorage.removeItem('planid');
                // localStorage.removeItem('isemail');
                // localStorage.removeItem('isadmin');
                // localStorage.removeItem('ispermission');
                // localStorage.removeItem('ctype');
                // localStorage.removeItem('logoname');
                // localStorage.removeItem('menu');
            }
        });
    };
    EmployeeRightToWorkAppComponent.prototype.todotaskclicktask = function (id) {
        this.linkurl = "https://employee.complygate.co.uk" + this.routerurl + "?type=" + id;
        if (this.ismobile == false) {
            localStorage.setItem('fakecard', id);
            this.modalService.open('pdf-image-viewer-passportviewpage');
        }
        else {
            this.currentStep = 2;
        }
    };
    EmployeeRightToWorkAppComponent.prototype.contwithdevice = function () {
        this.ismobile = false;
        this.currentStep = 2;
        this.modalService.close('pdf-image-viewer-passportviewpage');
        this.modalService.close('pdf-image-viewer-passportviewpage_qr');
    };
    EmployeeRightToWorkAppComponent.prototype.copyMessage = function (val) {
        var selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    };
    EmployeeRightToWorkAppComponent.prototype.performAction = function () {
        // console.log(this.documentArray);
        var _this = this;
        if (this.documentArray.length) {
            var docid = this.documentArray[0];
            this.documentArray.splice(0, 1);
            this.docs = this.documentArray;
            if (Number(docid) == 101) {
                this.currentStep = 1;
            }
            else if (Number(docid) == 100) {
                if (this.newrighttoworklog.isrighttowork == 1) {
                    if (this.type)
                        this.currentStep = 2;
                    else
                        this.currentStep = 5;
                    if (this.type && this.ismobile == false) {
                        this.currentStep = 14;
                    }
                }
                else {
                    this.currentStep = 2;
                }
            }
            else {
                this.docid = docid;
                this.currentStep = 3;
            }
        }
        else {
            var i = 1;
            while (i < 10) {
                var logid;
                logid = localStorage.getItem('logid');
                var dd = { id: logid };
                this.check.deleteAccessTokenrightoworklog(dd).subscribe(function (tokenData) {
                    if (tokenData.success) {
                        localStorage.removeItem('userToken1');
                        // localStorage.removeItem('userid');
                        // localStorage.removeItem('emptype');
                        // localStorage.removeItem('isline');
                        // localStorage.removeItem('planid');
                        // localStorage.removeItem('isemail');
                        // localStorage.removeItem('isadmin');
                        // localStorage.removeItem('ispermission');
                        // localStorage.removeItem('logoname');
                        // localStorage.removeItem('ctype');
                        localStorage.removeItem('profile_pic');
                        _this.currentStep = 4;
                        process_1.exit;
                    }
                });
                i++;
            }
        }
    };
    EmployeeRightToWorkAppComponent.prototype.sendsms = function () {
        alert('SMS Send Successfully');
        var dd = { url: this.linkurl };
        this.check.sendsmsurl(dd).subscribe(function (tokenData) {
            // alert('SMS Send Successfully');
        });
    };
    EmployeeRightToWorkAppComponent.prototype.openmodel = function () {
        this.modalService.close('pdf-image-viewer-passportviewpage');
        this.modalService.open('pdf-image-viewer-passportviewpage_qr');
    };
    EmployeeRightToWorkAppComponent.prototype.backpopup = function () {
        this.modalService.open('pdf-image-viewer-passportviewpage');
        this.modalService.close('pdf-image-viewer-passportviewpage_qr');
    };
    EmployeeRightToWorkAppComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return EmployeeRightToWorkAppComponent;
}());
exports.EmployeeRightToWorkAppComponent = EmployeeRightToWorkAppComponent;
