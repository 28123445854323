"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".my-drop-zone[_ngcontent-%COMP%]{border:3px dotted #d3d3d3}.invalid-drag[_ngcontent-%COMP%]{border:3px dotted red}.valid-drag[_ngcontent-%COMP%]{border:3px dotted green}body[_ngcontent-%COMP%], html[_ngcontent-%COMP%]{height:100%}.previewIcon[_ngcontent-%COMP%]{width:100px;height:100px;background-size:cover;background-repeat:no-repeat}.inline-block[_ngcontent-%COMP%]{display:inline-block;margin:.2em}\n/*# sourceMappingURL=mypassport.component.css.map*/"];
exports.styles = styles;
