import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class AicenterService {
  constructor(private commonService: CommonService) { }

  getcountpayment(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/company/countaiinformation');   
  } 
    
  getDocumentcountpayment(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/company/countDocumntaiinformation');   
  }  

  getAilogType(data): Observable<any> 
  {
    return this.commonService.formpost('/company/allailogreport',data);   
  }  

  approveAiDocument(data): Observable<any>{
    return this.commonService.refereeformpost('/api/documentapproverejectbyadmin', data);
  }

  approveAisuperDocument(data): Observable<any>{
    return this.commonService.refereeformpost('/api/documentapproverejectbysuperadmin', data);
  }

  getDocumentImages(data): Observable<any>{
    return this.commonService.refereeformpost('/api/getdocumentimagesbylogid', data);
  }

}