<div class="bg">
  <app-header></app-header>
<div class="admin_dashboard_show">
   <div class="ceee">
    <div class="cardmenu">
      
          <a class="card_box_lit" [routerLink]="['/leave-request']">
            <div class="samebox">
                <i class="lnr lnr-pencil"></i>
                <div class="menu_content">
                    <h4>Leave Request</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
            </div>
             
              
          </a>
     
     
          <a class="card_box_lit" [routerLink]="['/expense-request']" >
            <div class="samebox">
                <i class="lnr lnr-gift"></i>
              <div class="menu_content">
                  <h4>Expense Request</h4>
                  <!-- <span>lorem ipsum lorem ipsum</span> -->
              </div>
            </div>
              
          </a>
     
      
          <a class="card_box_lit" [routerLink]="['/timesheet-request']">
            <div class="samebox">
                <i class="lnr lnr-calendar-full"></i>
                <div class="menu_content">
                    <h4>Timesheet Request</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
            </div>
             
              
          </a>
      
     
    </div>
   </div>
  
   <!-- <a  [routerLink]="['/leave-request']"  class="homeset">
   <div class="dashboard-box">
    <h2 >Leave Request </h2>
      <p class="dash-circle" style="color:#fff;">
        <i class="fa fa-pencil" ></i>
      </p>
      <p class="dash-btm "><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
    
    </div></a>  -->

    <!-- <a  [routerLink]="['/expense-request']"  class="homeset">
     <div class="dashboard-box">
        <h2>Expense Request</h2>
        <p class="dash-circle"><i class="fa fa-gbp"></i></p>
        <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
       </div>
      </a> -->
      <!-- <a  [routerLink]="['/timesheet-request']"  class="homeset">
       <div class="dashboard-box">
          <h2>Timesheet Request</h2>
          <p class="dash-circle"><i class="fa fa-list-ul" ></i></p>
          <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
         </div>
        </a>        -->
</div>
</div>