"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var ApplicantService = /** @class */ (function () {
    function ApplicantService(commonService) {
        this.commonService = commonService;
    }
    ApplicantService.prototype.getcandidatelist = function (data) {
        return this.commonService.formpost('/employee/candidatereport', data);
    };
    ApplicantService.prototype.getcandidatestatus = function () {
        return this.commonService.formget('/company/candidatestatus');
    };
    ApplicantService.prototype.getemailertemplate = function (value) {
        return this.commonService.formpost('/candidate/statusemailer', value);
    };
    ApplicantService.ngInjectableDef = i0.defineInjectable({ factory: function ApplicantService_Factory() { return new ApplicantService(i0.inject(i1.CommonService)); }, token: ApplicantService, providedIn: "root" });
    return ApplicantService;
}());
exports.ApplicantService = ApplicantService;
