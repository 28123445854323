import "./polyfills";
//import { ModalModule } from 'ng2-modal';
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgxCaptchaModule } from "ngx-captcha";
import { DataTableModule } from "angular-6-datatable";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { FileSelectDirective } from 'ng2-file-upload';
// import { DeviceDetectorService } from 'ngx-device-detector';

// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

import { QRCodeModule } from "angularx-qrcode";
import { RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { UserService } from "./services/user.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { UserComponent } from "./user/user.component";
import { SignInComponent } from "./user/sign-in/sign-in.component";
import { appRoutes } from "./routes";
import { AuthGuard } from "./auth/auth.guard";
import { AuthInterceptor } from "./auth/auth.interceptor";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UpdatepasswordComponent } from "./user/updatepassword/updatepassword.component";
import { CompareValidatorDirective } from "./shared/compare-validator.directive";
import { DashboardComponent } from "./user/dashboard/dashboard.component";
import { AlertComponent } from "./directives";
import { ForgotRequestpasswordComponent } from "./user/forgot-requestpassword/forgot-requestpassword.component";
import { ForgotpasswordComponent } from "./user/forgotpassword/forgotpassword.component";
import { OverviewComponent } from "./user/overview/overview.component";
import { ProfileComponent } from "./user/profile/profile.component";
import { AddressComponent } from "./user/address/address.component";
import { COSComponent } from "./user/cos/cos.component";
import { VisaComponent } from "./user/visa/visa.component";
import { PassportComponent } from "./user/passport/passport.component";
import { HeaderComponent } from "./user/header/header.component";
import { FooterComponent } from "./user/footer/footer.component";
import { CustomerService } from "./services/customer.service";
import { MycosService } from "./services/mycos.service";
import { FileuploadService } from "./services/fileupload.service";
import { DirectivesComponent } from "./directives/directives.component";
import { EmploymentComponent } from "./user/employment/employment.component";
import { UploadFileService } from "./services/upload-file.service";
import { LeaveService } from "./services/leave.service";
import { ModalService } from "./_services";
import { ModalComponent } from "./_directives";
import { ModaldirComponent } from "./directives/modaldir.component";
import { MyaddressComponent } from "./myaddress/myaddress.component";
import { TopmenuComponent } from "./shared/topmenu/topmenu.component";
import { AddressapproveComponent } from "./myaddress/addressapprove/addressapprove.component";
import { AddressrejectedComponent } from "./myaddress/addressrejected/addressrejected.component";
import { MycosComponent } from "./mycos/mycos.component";
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { InternationalPhoneNumberModule } from "ngx-international-phone-number";
import { MypassportComponent } from "./mypassport/mypassport.component";
import { MypassportapproveComponent } from "./mypassport/mypassportapprove/mypassportapprove.component";
import { MypassportrejectedComponent } from "./mypassport/mypassportrejected/mypassportrejected.component";
import { MyemploymentComponent } from "./myemployment/myemployment.component";
import { CommonService } from "./services/common.service";
import { DlDateTimePickerDateModule } from "angular-bootstrap-datetimepicker";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { ReactiveFormsModule } from "@angular/forms";
import { FlatpickrModule } from "angularx-flatpickr";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";

// import { NgxCsvParserModule } from 'ngx-csv-parser';
// import {RatingModule} from "ngx-rating";
// import {WebcamModule} from 'ngx-webcam';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AlertService } from "./services/alert.service";
import { MyvisaComponent } from "./myvisa/myvisa.component";
import { ExpenseComponent } from "./expense/expense.component";
import { CommonModule } from "@angular/common";
import { HttpModule } from "@angular/http";
import { LeaveComponent } from "./leave/leave.component";
import { WorklistComponent } from "./worklist/worklist.component";
import { ExpensereportComponent } from "./expense/expensereport/expensereport.component";
import { WorklistreportComponent } from "./worklist/worklistreport/worklistreport.component";
import { LeavereportComponent } from "./leave/leavereport/leavereport.component";
import { LeavelimitComponent } from "./leave/leavelimit/leavelimit.component";
// import { WebsiteComponent } from './website/website.component';
// import { HomeComponent } from './website/home/home.component';
import { AssetComponent } from "./asset/asset.component";
import { ApprovalComponent } from "./approval/approval.component";
import { LeaveapprovalComponent } from "./leave/leaveapproval/leaveapproval.component";
import { ExpenseapprovalComponent } from "./expense/expenseapproval/expenseapproval.component";
import { TimesheetapprovalComponent } from "./worklist/timesheetapproval/timesheetapproval.component";
import { AdminComponent } from "./admin/admin.component";
import { AdmindashboardComponent } from "./admin/admindashboard/admindashboard.component";
import { JobsComponent } from "./admin/jobs/jobs.component";
import { AddjobsComponent } from "./admin/jobs/addjobs/addjobs.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { CandidatesComponent } from "./admin/jobs/candidates/candidates.component";
import { SkillsComponent } from "./admin/jobs/skills/skills.component";
import { ListemployeeComponent } from "./admin/employee/listemployee/listemployee.component";
import { EmployeeComponent } from "./admin/employee/employee.component";
import { PersonalinfoComponent } from "./admin/employee/personalinfo/personalinfo.component";
import { EmployementComponent } from "./admin/employee/employement/employement.component";
import { AddressinfoComponent } from "./admin/employee/addressinfo/addressinfo.component";
import { CosinfoComponent } from "./admin/employee/cosinfo/cosinfo.component";
import { VisainfoComponent } from "./admin/employee/visainfo/visainfo.component";
import { PassportinfoComponent } from "./admin/employee/passportinfo/passportinfo.component";
import { ClintComponent } from "./admin/clint/clint.component";
import { ClientComponent } from "./admin/client/client.component";
import { ProjectComponent } from "./admin/project/project.component";
import { CompanylocationComponent } from "./admin/companylocation/companylocation.component";
import { CompanyprofileComponent } from "./admin/companyprofile/companyprofile.component";
import { EmployementlistComponent } from "./admin/employee/employementlist/employementlist.component";
import { EmployementinfoComponent } from "./admin/employee/employementinfo/employementinfo.component";
import { CoslistComponent } from "./admin/employee/coslist/coslist.component";
import { PassportlistComponent } from "./admin/employee/passportlist/passportlist.component";
import { VisalistComponent } from "./admin/employee/visalist/visalist.component";
import { EmployeeaddresslistComponent } from "./admin/employee/employeeaddresslist/employeeaddresslist.component";
import { AddresslistComponent } from "./myaddress/addresslist/addresslist.component";
import { ApplicantreportComponent } from "./report/applicantreport/applicantreport.component";
import { AssetreportComponent } from "./report/assetreport/assetreport.component";
import { CosreportComponent } from "./report/cosreport/cosreport.component";
import { EmployeereportComponent } from "./report/employeereport/employeereport.component";
import { ExpreportComponent } from "./report/expreport/expreport.component";
import { JobreportComponent } from "./report/jobreport/jobreport.component";
import { PassportreportComponent } from "./report/passportreport/passportreport.component";
import { TimesheetreportComponent } from "./report/timesheetreport/timesheetreport.component";
import { VisareportComponent } from "./report/visareport/visareport.component";
import { ReportdashboardComponent } from "./report/reportdashboard/reportdashboard.component";
import { AdminleavereportComponent } from "./report/adminleavereport/adminleavereport.component";
import { EmploymentreportComponent } from "./report/employmentreport/employmentreport.component";
import { ReportComponent } from "./report/report.component";
import { AdminrequestComponent } from "./admin/adminrequest/adminrequest.component";
import { AdminleaverequestComponent } from "./admin/adminrequest/adminleaverequest/adminleaverequest.component";
import { AdminexpenserequestComponent } from "./admin/adminrequest/adminexpenserequest/adminexpenserequest.component";
import { AdmintimesheetrequestComponent } from "./admin/adminrequest/admintimesheetrequest/admintimesheetrequest.component";
import { AdminsettingComponent } from "./admin/adminsetting/adminsetting.component";
import { SettingleavetypeComponent } from "./admin/adminsetting/settingleavetype/settingleavetype.component";
import { SettinghourtypeComponent } from "./admin/adminsetting/settinghourtype/settinghourtype.component";
import { SettingexpensetypeComponent } from "./admin/adminsetting/settingexpensetype/settingexpensetype.component";
import { SettingassetcategoryComponent } from "./admin/adminsetting/settingassetcategory/settingassetcategory.component";
import { AdminassetComponent } from "./admin/adminasset/adminasset.component";
import { AdmindocumentComponent } from "./admin/admindocument/admindocument.component";
import { EmployeedocumentComponent } from "./admin/admindocument/employeedocument/employeedocument.component";
import { CandidatedocumentComponent } from "./admin/admindocument/candidatedocument/candidatedocument.component";
import { SignupComponent } from "./signup/signup.component";
import { OptcheckComponent } from "./signup/optcheck/optcheck.component";
import { SelectplanComponent } from "./signup/selectplan/selectplan.component";
import { UpdateemailComponent } from "./signup/updateemail/updateemail.component";
import { PlandetailsComponent } from "./signup/plandetails/plandetails.component";
import { Module as StripeModule } from "stripe-angular";
import { AdminpermissionComponent } from "./admin/adminpermission/adminpermission.component";
import { VisarequestComponent } from "./admin/adminrequest/visarequest/visarequest.component";
import { PassportrequestComponent } from "./admin/adminrequest/passportrequest/passportrequest.component";
// import { ChartsModule } from 'ng2-charts';
import { FilterPipe } from "./pipes/filter.pipe";
import { JobdetailsComponent } from "./admin/jobs/jobdetails/jobdetails.component";
import { CandidatedetailsComponent } from "./admin/jobs/candidates/candidatedetails/candidatedetails.component";
import { SettingbonusfrequencyComponent } from "./admin/adminsetting/settingbonusfrequency/settingbonusfrequency.component";
import { SettingcurrencyComponent } from "./admin/adminsetting/settingcurrency/settingcurrency.component";
import { SettingdepartmentComponent } from "./admin/adminsetting/settingdepartment/settingdepartment.component";
import { SettingdivisionComponent } from "./admin/adminsetting/settingdivision/settingdivision.component";
import { SettingpersonaltypeComponent } from "./admin/adminsetting/settingpersonaltype/settingpersonaltype.component";
import { AddemployeeComponent } from "./admin/employee/addemployee/addemployee.component";
import { PersonalinformationComponent } from "./admin/employee/addemployee/personalinformation/personalinformation.component";
import { AddressinformationComponent } from "./admin/employee/addemployee/addressinformation/addressinformation.component";
import { ContactinformationComponent } from "./admin/employee/addemployee/contactinformation/contactinformation.component";
import { EmploymentinformationComponent } from "./admin/employee/addemployee/employmentinformation/employmentinformation.component";
import { CompensationinformationComponent } from "./admin/employee/addemployee/compensationinformation/compensationinformation.component";
import { EmployeeheaderComponent } from "./admin/employee/addemployee/employeeheader/employeeheader.component";
import { CompensationinfoComponent } from "./admin/employee/compensationinfo/compensationinfo.component";
import { ContactinfoComponent } from "./admin/employee/contactinfo/contactinfo.component";
import { MycurrentemploymentComponent } from "./admin/employee/mycurrentemployment/mycurrentemployment.component";
import { OtherinformationComponent } from "./admin/employee/otherinformation/otherinformation.component";
import { ChartexpampleComponent } from "./admin/chartexpample/chartexpample.component";
import { MycompensationComponent } from "./mycompensation/mycompensation.component";
import { MycontactinfoComponent } from "./mycontactinfo/mycontactinfo.component";
import { LeavechartComponent } from "./report/adminleavereport/leavechart/leavechart.component";
import { ExpchartComponent } from "./report/expreport/expchart/expchart.component";
import { TimesheetchartComponent } from "./report/timesheetreport/timesheetchart/timesheetchart.component";
import { UpdateplanComponent } from "./updateplan/updateplan.component";
import { ClientreplyComponent } from "./clientreply/clientreply.component";
import { ThanksComponent } from "./signup/thanks/thanks.component";
import { NotificationComponent } from "./admin/notification/notification.component";
import { UpdatestripeplanComponent } from "./updatestripeplan/updatestripeplan.component";
import { InvoiceComponent } from "./admin/invoice/invoice.component";
import { PaymentmethodComponent } from "./admin/invoice/paymentmethod/paymentmethod.component";
import { BillinginformationComponent } from "./admin/invoice/billinginformation/billinginformation.component";
import { ChangeplanComponent } from "./admin/invoice/changeplan/changeplan.component";
import { InvioceSharedComponent } from "./admin/invoice/invioce-shared/invioce-shared.component";
import { InvoicedetailsComponent } from "./admin/invoice/invoicedetails/invoicedetails.component";
import { JwPaginationComponent } from "jw-angular-pagination";
import { ApplicantdocumentComponent } from "./admin/jobs/candidates/applicantdocument/applicantdocument.component";
import { CandnoteComponent } from "./admin/jobs/candidates/candnote/candnote.component";
import { NotfoundComponent } from "./notfound/notfound.component";
import { AdminrequestdashboardComponent } from "./admin/adminrequest/adminrequestdashboard/adminrequestdashboard.component";
import { AiverificationComponent } from "./employee/listemployee/aiverification/aiverification.component";
import { EmployeeaiverificationComponent } from "./admin/employee/listemployee/employeeaiverification/employeeaiverification.component";
import { EmployeeaidetailComponent } from "./admin/employee/listemployee/employeeaiverification/employeeaidetail/employeeaidetail.component";
import { EmployeeaiuserpicchangeComponent } from "./admin/employee/listemployee/employeeaiverification/employeeaiuserpicchange/employeeaiuserpicchange.component";
import { EmployeeaipaymentComponent } from "./admin/employee/listemployee/employeeaiverification/employeeaipayment/employeeaipayment.component";
import { EmployeeaithankyouComponent } from "./admin/employee/listemployee/employeeaiverification/employeeaithankyou/employeeaithankyou.component";
import { AiwizardComponent } from "./admin/employee/listemployee/employeeaiverification/aiwizard/aiwizard.component";
import { PassportaiverifyComponent } from "./admin/passportaiverify/passportaiverify.component";
import { PassportfaceaiComponent } from "./admin/passportaiverify/passportfaceai/passportfaceai.component";
import { PassportreviewaiComponent } from "./admin/passportaiverify/passportreviewai/passportreviewai.component";
import { PassportthanksaiComponent } from "./admin/passportaiverify/passportthanksai/passportthanksai.component";
import { PassportaiwizardComponent } from "./admin/passportaiverify/passportaiwizard/passportaiwizard.component";
// import { IdaiverifyComponent } from './admin/idaiverify/idaiverify.component';
// import { FaceaiverifyComponent } from './admin/Idaiverify/faceaiverify/faceaiverify.component';
// import { ReviewaiverifyComponent } from './admin/Idaiverify/reviewaiverify/reviewaiverify.component';
// import { IdthanksaiverifyComponent } from './admin/Idaiverify/idthanksaiverify/idthanksaiverify.component';
// import { IdaiwizardComponent } from './admin/Idaiverify/idaiwizard/idaiwizard.component';
import { MyidproofComponent } from "./myidproof/myidproof.component";
import { IdprooflistComponent } from "./admin/employee/idprooflist/idprooflist.component";
import { IdproofrequestComponent } from "./admin/adminrequest/idproofrequest/idproofrequest.component";
import { IdaiverifyComponent } from "./admin/idaiverify/idaiverify.component";
import { IdaiwizardComponent } from "./admin/idaiverify/idaiwizard/idaiwizard.component";
import { FaceaiverifyComponent } from "./admin/idaiverify/faceaiverify/faceaiverify.component";
import { IdthanksaiverifyComponent } from "./admin/idaiverify/idthanksaiverify/idthanksaiverify.component";
import { ReviewaiverifyComponent } from "./admin/idaiverify/reviewaiverify/reviewaiverify.component";
import { IdfaceaiverifyComponent } from "./admin/idaiverify/idfaceaiverify/idfaceaiverify.component";
import { IdreviewaiverifyComponent } from "./admin/idaiverify/idreviewaiverify/idreviewaiverify.component";
import { IdproofthanksaiverifyComponent } from "./admin/idaiverify/idproofthanksaiverify/idproofthanksaiverify.component";
import { ManagepermissionComponent } from "./admin/adminpermission/managepermission/managepermission.component";
import { AdmincreditsafeComponent } from "./admin/admincreditsafe/admincreditsafe.component";
import { PersonalcreditComponent } from "./admin/admincreditsafe/personalcredit/personalcredit.component";
import { BusinesscreditComponent } from "./admin/admincreditsafe/businesscredit/businesscredit.component";
import { CreditsearchresultComponent } from "./admin/admincreditsafe/creditsearchresult/creditsearchresult.component";
import { BuscreditsearchresultComponent } from "./admin/admincreditsafe/buscreditsearchresult/buscreditsearchresult.component";
import { CompletebusscreditdetailsComponent } from "./admin/admincreditsafe/buscreditsearchresult/completebusscreditdetails/completebusscreditdetails.component";
import { AisystemComponent } from "./admin/aisystem/aisystem.component";
import { AivisaComponent } from "./admin/aisystem/aivisa/aivisa.component";
import { AipassportComponent } from "./admin/aisystem/aipassport/aipassport.component";
import { AiidproofComponent } from "./admin/aisystem/aiidproof/aiidproof.component";
import { AilogComponent } from "./admin/aisystem/ailog/ailog.component";
import { AmllogComponent } from "./admin/admincreditsafe/amllog/amllog.component";
import { CompletepersonaldetailsComponent } from "./admin/admincreditsafe/personalcredit/completepersonaldetails/completepersonaldetails.component";
import { UnsupportedCountryComponent } from "./admin/unsupported-country/unsupported-country.component";
import { RotaComponent } from "./admin/rota/rota.component";
import { EmployeerotalistComponent } from "./admin/rota/employeerotalist/employeerotalist.component";
import { ChecksystemComponent } from "./checksystem/checksystem.component";
import { SupportComponent } from "./support/support.component";
import { CheckemployeeComponent } from "./checksystem/checkemployee/checkemployee.component";
import { CheckpassportComponent } from "./checksystem/checkemployee/checkpassport/checkpassport.component";
import { CheckvisaComponent } from "./checksystem/checkemployee/checkvisa/checkvisa.component";
import { CheckdocumentComponent } from "./checksystem/checkemployee/checkdocument/checkdocument.component";
import { CheckemployeedetailsComponent } from "./checksystem/checkemployee/checkemployeedetails/checkemployeedetails.component";
import { CheckbillingComponent } from "./checksystem/checkbilling/checkbilling.component";
import { CheckamlComponent } from "./checksystem/checkaml/checkaml.component";
import { MyrotaComponent } from "./myrota/myrota.component";
import { CheckidlistComponent } from "./checksystem/checkidlist/checkidlist.component";
import { CheckidentitylistComponent } from "./checksystem/checkidentitylist/checkidentitylist.component";
import { CheckdocumentlistComponent } from "./checksystem/checkdocumentlist/checkdocumentlist.component";
import { PersonalamlcheckComponent } from "./checksystem/checkaml/personalamlcheck/personalamlcheck.component";
import { BusinessamlcheckComponent } from "./checksystem/checkaml/businessamlcheck/businessamlcheck.component";
import { PersonalamlcheckdetailsComponent } from "./checksystem/checkaml/personalamlcheck/personalamlcheckdetails/personalamlcheckdetails.component";
import { BusinessamlcheckdetailsComponent } from "./checksystem/checkaml/businessamlcheck/businessamlcheckdetails/businessamlcheckdetails.component";
import { AdversecreditComponent } from "./checksystem/adversecredit/adversecredit.component";
import { CheckinvoiceComponent } from "./checksystem/checkbilling/checkinvoice/checkinvoice.component";
import { CheckpaymentmethodComponent } from "./checksystem/checkbilling/checkpaymentmethod/checkpaymentmethod.component";
import { CheckbillinginformationComponent } from "./checksystem/checkbilling/checkbillinginformation/checkbillinginformation.component";
import { CheckaddcreditComponent } from "./checksystem/checkbilling/checkaddcredit/checkaddcredit.component";
import { CheckbillingsharedComponent } from "./checksystem/checkbilling/checkbillingshared/checkbillingshared.component";
import { CheckoutComponent } from "./partials/checkout/checkout.component";
import { AdversecreditreportComponent } from "./checksystem/adversecredit/adversecreditreport/adversecreditreport.component";
import { EmployeecheckvisaComponent } from "./checksystem/employeecheckvisa/employeecheckvisa.component";
import { EmployeecheckpassportComponent } from "./checksystem/employeecheckpassport/employeecheckpassport.component";
import { EmployeecheckdocumentComponent } from "./checksystem/employeecheckdocument/employeecheckdocument.component";

import { WebcamModule } from "ngx-webcam";
import { WebcamComponent } from "./checksystem/webcam/webcam.component";
import { CheckcountshowComponent } from "./checksystem/checkcountshow/checkcountshow.component";
import { CheckaddemployeeComponent } from "./checksystem/checkemployee/checkaddemployee/checkaddemployee.component";
import { CheckaivisaComponent } from "./checksystem/checkemployee/checkaivisa/checkaivisa.component";
import { CheckaipassportComponent } from "./checksystem/checkemployee/checkaipassport/checkaipassport.component";
import { CheckaidocumentComponent } from "./checksystem/checkemployee/checkaidocument/checkaidocument.component";
import { RighttoworkcheckComponent } from "./checksystem/righttoworkcheck/righttoworkcheck.component";
import { TaxdefaultComponent } from "./checksystem/taxdefault/taxdefault.component";
import { RightToWorkHistoryComponent } from "./checksystem/righttoworkcheck/right-to-work-history/right-to-work-history.component";
import { CheckpaynowComponent } from "./checksystem/checkpaynow/checkpaynow.component";
import { LeaveManagementComponent } from "./admin/adminsetting/leave-management/leave-management.component";
import { DbsComponent } from "./checksystem/dbs/dbs.component";
import { DbsHistoryComponent } from "./checksystem/dbs/dbs-history/dbs-history.component";

import { PerformanceComponent } from "./admin/performance/performance.component";
import { ObjectiveComponent } from "./admin/performance/objective/objective.component";
import { EmployeeobjectlistComponent } from "./admin/performance/objective/employeeobjectlist/employeeobjectlist.component";
import { FeedbackComponent } from "./admin/performance/objective/feedback/feedback.component";
import { RatecompetenciesComponent } from "./admin/performance/objective/ratecompetencies/ratecompetencies.component";
import { EmployeeperformanceComponent } from "./employeeperformance/employeeperformance.component";
import { EmployeeobjectiveComponent } from "./employeeperformance/employeeobjective/employeeobjective.component";
import { EmployeefeedbackComponent } from "./employeeperformance/employeefeedback/employeefeedback.component";

import { TerminationReasonComponent } from "./admin/adminsetting/termination-reason/termination-reason.component";
import { AidocumentComponent } from "./checksystem/aidocument/aidocument.component";
import { RighttoworkdetailsComponent } from "./checksystem/righttoworkcheck/righttoworkdetails/righttoworkdetails.component";
import { PayverificationComponent } from "./signup/payverification/payverification.component";
import { ConfirmationapprovalComponent } from "./signup/confirmationapproval/confirmationapproval.component";
import { CheckdocumentadminComponent } from "./checksystem/checkemployee/checkdocumentadmin/checkdocumentadmin.component";
import { CheckdocumentemployeeComponent } from "./checksystem/checkdocumentemployee/checkdocumentemployee.component";
import { AdversecredithistoryComponent } from "./checksystem/adversecredit/adversecredithistory/adversecredithistory.component";
import { DvlacheckComponent } from "./checksystem/dvlacheck/dvlacheck.component";
import { DvlahistoryComponent } from "./checksystem/dvlacheck/dvlahistory/dvlahistory.component";
import { AdversecredithistoryviewComponent } from "./checksystem/adversecredit/adversecredithistory/adversecredithistoryview/adversecredithistoryview.component";
import { RightToWorkAppComponent } from "./checksystem/righttoworkcheck/right-to-work-app/right-to-work-app.component";
import { EmployeeRightToWorkAppComponent } from "./checksystem/righttoworkcheck/employee-right-to-work-app/employee-right-to-work-app.component";
import { RtwVisaComponent } from "./checksystem/righttoworkcheck/employee-right-to-work-app/rtw-visa/rtw-visa.component";
import { RtwPassportComponent } from "./checksystem/righttoworkcheck/employee-right-to-work-app/rtw-passport/rtw-passport.component";
import { RtwDocumentComponent } from "./checksystem/righttoworkcheck/employee-right-to-work-app/rtw-document/rtw-document.component";
import { RighToWorkHistoryListComponent } from "./checksystem/righttoworkcheck/righ-to-work-history-list/righ-to-work-history-list.component";
import { UpdatemobileComponent } from "./signup/updatemobile/updatemobile.component";
import { CompanyadvertsearchComponent } from "./companyadvertsearch/companyadvertsearch.component";
import { DirectorcompanyComponent } from "./companyadvertsearch/directorcompany/directorcompany.component";
import { CompanyadverthistoryComponent } from "./companyadvertsearch/companyadverthistory/companyadverthistory.component";
import { UpgradecheckComponent } from "./companyadvertsearch/upgradecheck/upgradecheck.component";
import { CompanyadvertsummaryComponent } from "./companyadvertsearch/companyadvertsummary/companyadvertsummary.component";
import { CompanyadvertfinancialsComponent } from "./companyadvertsearch/companyadvertfinancials/companyadvertfinancials.component";
import { CompanyadvertgroupComponent } from "./companyadvertsearch/companyadvertgroup/companyadvertgroup.component";
import { LegalinformationComponent } from "./companyadvertsearch/legalinformation/legalinformation.component";
import { ReferenceComponent } from "./reference/reference.component";
import { RefnnoeComponent } from "./refnnoe/refnnoe.component";
import { ViewemployeereportComponent } from "./report/timesheetreport/viewemployeereport/viewemployeereport.component";
import { SickleaveComponent } from "./leave/sickleave/sickleave.component";
import { AdminsickleaverequestComponent } from "./admin/adminrequest/adminsickleaverequest/adminsickleaverequest.component";
import { LeavesickapprovalComponent } from "./leave/leavesickapproval/leavesickapproval.component";
import { ViewleavereportComponent } from "./report/adminleavereport/viewleavereport/viewleavereport.component";
import { ViewexpensereportComponent } from "./report/expreport/viewexpensereport/viewexpensereport.component";
import { AddreferenceComponent } from "./reference/addreference/addreference.component";
import { ConfigurationComponent } from "./configuration/configuration.component";
import { RefstatusComponent } from "./reference/refstatus/refstatus.component";
import { RefconfigComponent } from "./reference/refconfig/refconfig.component";
import { AddbulkreferenceComponent } from "./reference/addbulkreference/addbulkreference.component";
import { StatusbulkreferenceComponent } from "./reference/statusbulkreference/statusbulkreference.component";
import { UseraddreferenceComponent } from "./reference/useraddreference/useraddreference.component";
import { ReferenceresponseComponent } from "./reference/referenceresponse/referenceresponse.component";
import { Papa } from "ngx-papaparse";

import { DragulaModule } from "ng2-dragula";
import { RegisterfcaComponent } from "./registerfca/registerfca.component";
import { RegisterfcadetailsComponent } from "./registerfca/registerfcadetails/registerfcadetails.component";
import { UsernameComponent } from "./signup/username/username.component";
import { SubscriptionsuccessComponent } from "./admin/invoice/subscriptionsuccess/subscriptionsuccess.component";
import { DocumentapproverejectComponent } from "./checksystem/documentapprovereject/documentapprovereject.component";
import { DirectorsearchComponent } from "./checksystem/directorsearch/directorsearch.component";
import { DirectorhistoryComponent } from "./checksystem/directorsearch/directorhistory/directorhistory.component";

import { UserdeclinereferenceComponent } from "./reference/userdeclinereference/userdeclinereference.component";
import { RefsettingComponent } from "./reference/refsetting/refsetting.component";
import { RefreedeclineComponent } from "./reference/refreedecline/refreedecline.component";
import { NotpermissionComponent } from "./notpermission/notpermission.component";
import { UnlimitedcheckComponent } from "./checksystem/checkbilling/unlimitedcheck/unlimitedcheck.component";
import { IndividualsfcaComponent } from "./registerfca/individualsfca/individualsfca.component";
import { KnowlagecenterComponent } from "./knowlagecenter/knowlagecenter.component";
import { SubscriptioncancelComponent } from "./signup/subscriptioncancel/subscriptioncancel.component";
import { UsersubscriptionsuccessComponent } from "./signup/usersubscriptionsuccess/usersubscriptionsuccess.component";
import { UpgradepaymentsuccessComponent } from "./signup/upgradepaymentsuccess/upgradepaymentsuccess.component";
import { UpgradepaymentcancelComponent } from "./signup/upgradepaymentcancel/upgradepaymentcancel.component";
import { UnlimitedchecksuccessComponent } from "./checksystem/checkbilling/unlimitedchecksuccess/unlimitedchecksuccess.component";
import { UnlimitedcheckcancelComponent } from "./checksystem/checkbilling/unlimitedcheckcancel/unlimitedcheckcancel.component";
import { PurchasepaymentsuccessComponent } from "./checksystem/checkbilling/purchasepaymentsuccess/purchasepaymentsuccess.component";
import { PurchasepaymentcancelComponent } from "./checksystem/checkbilling/purchasepaymentcancel/purchasepaymentcancel.component";

import { SearchpersonalamlComponent } from "./checksystem/checkaml/searchpersonalaml/searchpersonalaml.component";
import { SearchpersonalamldetailsComponent } from "./checksystem/checkaml/searchpersonalaml/searchpersonalamldetails/searchpersonalamldetails.component";
import { SearchbussinessamlComponent } from "./checksystem/checkaml/searchbussinessaml/searchbussinessaml.component";
import { SearchbussinessamldetailsComponent } from "./checksystem/checkaml/searchbussinessaml/searchbussinessamldetails/searchbussinessamldetails.component";
import { RightToWorkAppAdminComponent } from "./checksystem/righttoworkcheck/right-to-work-app-admin/right-to-work-app-admin.component";
import { RighToWorkHistoryListAdminComponent } from "./checksystem/righttoworkcheck/righ-to-work-history-list-admin/righ-to-work-history-list-admin.component";
import { ScreeningRightToWorkAppComponent } from "./checksystem/righttoworkcheck/screening-right-to-work-app/screening-right-to-work-app.component";
import { ScreeningRtwDocumentComponent } from "./checksystem/righttoworkcheck/screening-right-to-work-app/screening-rtw-document/screening-rtw-document.component";
import { ScreeningRtwPassportComponent } from "./checksystem/righttoworkcheck/screening-right-to-work-app/screening-rtw-passport/screening-rtw-passport.component";
import { ScreeningRtwVisaComponent } from "./checksystem/righttoworkcheck/screening-right-to-work-app/screening-rtw-visa/screening-rtw-visa.component";
// import { ScreeningpassportComponent } from "./checksystem/screeningidentitycheck/screeningpassport/screeningpassport.component";
// import { ScreeningbrpComponent } from "./checksystem/screeningidentitycheck/screeningbrp/screeningbrp.component";
// import { ScreeningdrivinglicenceComponent } from "./checksystem/screeningidentitycheck/screeningdrivinglicence/screeningdrivinglicence.component";
import { AiapproverejectComponent } from "./checksystem/aiapprovereject/aiapprovereject.component";
import { AmlmonitoringComponent } from "./checksystem/checkaml/amlmonitoring/amlmonitoring.component";
import { CallbackComponent } from "./user/callback/callback.component";


import { AdverseMediaSearchComponent } from './checksystem/adverse-media-search/adverse-media-search.component';
import { AdverseMediaSearchHistoryComponent } from './checksystem/adverse-media-search-history/adverse-media-search-history.component';
// import { GlobalddComponent } from './checksystem/globaldd/globaldd.component';
// import { PersonalglobalamlcheckComponent } from './checksystem/globaldd/personalglobalamlcheck/personalglobalamlcheck.component';
// import { BusinessglobalamlcheckComponent } from './checksystem/globaldd/businessglobalamlcheck/businessglobalamlcheck.component';
// import { SearchbusinessglobalamlComponent } from './checksystem/globaldd/searchbusinessglobalaml/searchbusinessglobalaml.component';
// import { SearchpersonalglobalamlComponent } from './checksystem/globaldd/searchpersonalglobalaml/searchpersonalglobalaml.component';
// import { SearchpersonalglobalamldetailsComponent } from './checksystem/globaldd/searchpersonalglobalaml/searchpersonalglobalamldetails/searchpersonalglobalamldetails.component';
// import { SearchbusinessglobalamldetailsComponent } from './checksystem/globaldd/searchbusinessglobalaml/searchbusinessglobalamldetails/searchbusinessglobalamldetails.component';

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    DirectivesComponent,
    SignInComponent,
    UpdatepasswordComponent,
    CompareValidatorDirective,
    DashboardComponent,
    AlertComponent,
    JwPaginationComponent,
    // FileSelectDirective,
    ForgotRequestpasswordComponent,
    ForgotpasswordComponent,
    OverviewComponent,
    ModaldirComponent,
    ProfileComponent,
    AddressComponent,
    COSComponent,
    VisaComponent,
    PassportComponent,
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    EmploymentComponent,
    MyaddressComponent,
    TopmenuComponent,
    AddressapproveComponent,
    AddressrejectedComponent,
    MycosComponent,
    MyvisaComponent,
    MypassportComponent,
    MypassportapproveComponent,
    MypassportrejectedComponent,
    MyemploymentComponent,
    MyvisaComponent,
    ExpenseComponent,
    LeaveComponent,
    WorklistComponent,
    ReportComponent,
    ExpensereportComponent,
    WorklistreportComponent,
    LeavereportComponent,
    LeavelimitComponent,
    // WebsiteComponent,
    // HomeComponent,
    AssetComponent,
    ApprovalComponent,
    LeaveapprovalComponent,
    ExpenseapprovalComponent,
    TimesheetapprovalComponent,
    AdminComponent,
    AdmindashboardComponent,
    JobsComponent,
    AddjobsComponent,
    CandidatesComponent,
    SkillsComponent,
    ListemployeeComponent,
    EmployeeComponent,
    SupportComponent,
    PersonalinfoComponent,
    EmployementComponent,
    AddressinfoComponent,
    CosinfoComponent,
    VisainfoComponent,
    PassportinfoComponent,
    ClintComponent,
    ClientComponent,
    ProjectComponent,
    CompanylocationComponent,
    CompanyprofileComponent,
    EmployementlistComponent,
    EmployementinfoComponent,
    CoslistComponent,
    PassportlistComponent,
    VisalistComponent,
    AddresslistComponent,
    DirectorsearchComponent,
    ApplicantreportComponent,
    AssetreportComponent,
    CosreportComponent,
    EmployeeaddresslistComponent,
    EmployeereportComponent,
    ExpreportComponent,
    JobreportComponent,
    PassportreportComponent,
    TimesheetreportComponent,
    VisareportComponent,
    ThanksComponent,
    ReportdashboardComponent,
    AdminleavereportComponent,
    EmploymentreportComponent,
    AdminrequestComponent,
    AdminleaverequestComponent,
    AdminexpenserequestComponent,
    AdmintimesheetrequestComponent,
    AdminsettingComponent,
    SettingleavetypeComponent,
    SettinghourtypeComponent,
    SettingexpensetypeComponent,
    SettingassetcategoryComponent,
    AdminassetComponent,
    AdmindocumentComponent,
    EmployeedocumentComponent,
    CandidatedocumentComponent,
    SignupComponent,
    OptcheckComponent,
    SelectplanComponent,
    UpdateemailComponent,
    PlandetailsComponent,
    AdminpermissionComponent,
    VisarequestComponent,
    PassportrequestComponent,
    FilterPipe,
    JobdetailsComponent,
    CandidatedetailsComponent,
    AddemployeeComponent,
    PersonalinformationComponent,
    AddressinformationComponent,
    ContactinformationComponent,
    EmploymentinformationComponent,
    CompensationinformationComponent,
    EmployeeheaderComponent,
    SettingbonusfrequencyComponent,
    SettingcurrencyComponent,
    SettingdepartmentComponent,
    SettingdivisionComponent,
    SettingpersonaltypeComponent,
    CompensationinfoComponent,
    ContactinfoComponent,
    MycurrentemploymentComponent,
    OtherinformationComponent,
    ChartexpampleComponent,
    MycompensationComponent,
    MycontactinfoComponent,
    LeavechartComponent,
    ExpchartComponent,
    TimesheetchartComponent,
    UpdateplanComponent,
    ClientreplyComponent,
    NotificationComponent,
    UpdatestripeplanComponent,
    InvoiceComponent,
    PaymentmethodComponent,
    BillinginformationComponent,
    ChangeplanComponent,
    InvioceSharedComponent,
    InvoicedetailsComponent,
    ApplicantdocumentComponent,
    CandnoteComponent,
    NotfoundComponent,
    AdminrequestdashboardComponent,
    AiverificationComponent,
    EmployeeaiverificationComponent,
    EmployeeaidetailComponent,
    EmployeeaiuserpicchangeComponent,
    EmployeeaipaymentComponent,
    EmployeeaithankyouComponent,
    AiwizardComponent,
    PassportaiverifyComponent,
    DocumentapproverejectComponent,
    PassportfaceaiComponent,
    PassportreviewaiComponent,
    PassportthanksaiComponent,
    PassportaiwizardComponent,
    // IdaiverifyComponent,
    // FaceaiverifyComponent,
    // ReviewaiverifyComponent,
    // IdthanksaiverifyComponent,
    // IdaiwizardComponent,

    MyidproofComponent,
    IdprooflistComponent,
    IdproofrequestComponent,
    IdaiverifyComponent,
    IdaiwizardComponent,
    FaceaiverifyComponent,
    IdthanksaiverifyComponent,
    ReviewaiverifyComponent,
    IdfaceaiverifyComponent,
    IdreviewaiverifyComponent,
    IdproofthanksaiverifyComponent,
    ManagepermissionComponent,
    AdmincreditsafeComponent,
    PersonalcreditComponent,
    BusinesscreditComponent,
    CreditsearchresultComponent,
    BuscreditsearchresultComponent,
    CompletebusscreditdetailsComponent,
    AisystemComponent,
    AivisaComponent,
    AipassportComponent,
    AiidproofComponent,
    AilogComponent,
    AmllogComponent,
    CompletepersonaldetailsComponent,
    UnsupportedCountryComponent,
    RotaComponent,
    EmployeerotalistComponent,
    ChecksystemComponent,
    CheckemployeeComponent,
    CheckpassportComponent,
    CheckvisaComponent,
    CheckdocumentComponent,
    CheckemployeedetailsComponent,
    CheckbillingComponent,
    CheckamlComponent,
    MyrotaComponent,
    CheckidlistComponent,
    CheckidentitylistComponent,
    CheckdocumentlistComponent,
    PersonalamlcheckComponent,
    BusinessamlcheckComponent,
    PersonalamlcheckdetailsComponent,
    BusinessamlcheckdetailsComponent,
    AdversecreditComponent,
    CheckinvoiceComponent,
    CheckpaymentmethodComponent,
    CheckbillinginformationComponent,
    CheckaddcreditComponent,
    CheckbillingsharedComponent,
    CheckoutComponent,
    AdversecreditreportComponent,
    EmployeecheckvisaComponent,
    EmployeecheckpassportComponent,
    EmployeecheckdocumentComponent,
    WebcamComponent,
    CheckcountshowComponent,
    CheckaddemployeeComponent,
    CheckaivisaComponent,
    CheckaipassportComponent,
    CheckaidocumentComponent,
    RighttoworkcheckComponent,
    TaxdefaultComponent,
    RightToWorkHistoryComponent,
    CheckpaynowComponent,
    LeaveManagementComponent,
    DbsComponent,
    DbsHistoryComponent,
    PerformanceComponent,
    ObjectiveComponent,
    EmployeeobjectlistComponent,
    FeedbackComponent,
    RatecompetenciesComponent,
    EmployeeperformanceComponent,
    EmployeeobjectiveComponent,
    EmployeefeedbackComponent,
    TerminationReasonComponent,
    AidocumentComponent,
    RighttoworkdetailsComponent,
    PayverificationComponent,
    ConfirmationapprovalComponent,
    CheckdocumentadminComponent,
    CheckdocumentemployeeComponent,
    AdversecredithistoryComponent,
    DvlacheckComponent,
    DvlahistoryComponent,
    AdversecredithistoryviewComponent,
    RightToWorkAppComponent,
    EmployeeRightToWorkAppComponent,
    RtwVisaComponent,
    RtwPassportComponent,
    RtwDocumentComponent,
    RighToWorkHistoryListComponent,
    UpdatemobileComponent,
    CompanyadvertsearchComponent,
    DirectorcompanyComponent,
    CompanyadverthistoryComponent,
    UpgradecheckComponent,
    CompanyadvertsummaryComponent,
    CompanyadvertfinancialsComponent,
    CompanyadvertgroupComponent,
    LegalinformationComponent,
    ReferenceComponent,
    RefnnoeComponent,
    ViewemployeereportComponent,
    SickleaveComponent,
    AdminsickleaverequestComponent,
    LeavesickapprovalComponent,
    ViewleavereportComponent,
    ViewexpensereportComponent,
    AddreferenceComponent,
    ConfigurationComponent,
    RefstatusComponent,
    RefconfigComponent,
    AddbulkreferenceComponent,
    StatusbulkreferenceComponent,
    UseraddreferenceComponent,
    ReferenceresponseComponent,
    RegisterfcaComponent,
    RegisterfcadetailsComponent,
    UsernameComponent,
    SubscriptionsuccessComponent,
    DocumentapproverejectComponent,
    DirectorsearchComponent,
    DirectorhistoryComponent,
    UserdeclinereferenceComponent,
    RefsettingComponent,
    RefreedeclineComponent,
    NotpermissionComponent,
    UnlimitedcheckComponent,
    IndividualsfcaComponent,
    KnowlagecenterComponent,
    SubscriptioncancelComponent,
    UsersubscriptionsuccessComponent,
    UpgradepaymentsuccessComponent,
    UpgradepaymentcancelComponent,
    UnlimitedchecksuccessComponent,
    UnlimitedcheckcancelComponent,
    PurchasepaymentsuccessComponent,
    PurchasepaymentcancelComponent,
    SearchpersonalamlComponent,
    SearchpersonalamldetailsComponent,
    SearchbussinessamlComponent,
    SearchbussinessamldetailsComponent,
    RightToWorkAppAdminComponent,
    RighToWorkHistoryListAdminComponent,
    ScreeningRightToWorkAppComponent,
    ScreeningRtwDocumentComponent,
    ScreeningRtwPassportComponent,
    ScreeningRtwVisaComponent,
    AiapproverejectComponent,
    AmlmonitoringComponent,
    CallbackComponent,
    AdverseMediaSearchComponent,
    AdverseMediaSearchHistoryComponent,
    // GlobalddComponent,
    // PersonalglobalamlcheckComponent,
    // BusinessglobalamlcheckComponent,
    // SearchbusinessglobalamlComponent,
    // SearchpersonalglobalamlComponent,
    // SearchpersonalglobalamldetailsComponent,
    // SearchbusinessglobalamldetailsComponent,
  ],
  imports: [
    // RatingModule,
    DragulaModule.forRoot(),
    StripeModule.forRoot(),
    DataTableModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    QRCodeModule,
    // DeviceDetectorService,
    // NgxQRCodeModule,
    // DeviceDetectorService,
    HttpClientModule,
    // NgxCsvParserModule,
    WebcamModule,
    HttpModule,
    NgxCaptchaModule.forRoot({
      reCaptcha2SiteKey: "xxxx", // optional, can be overridden with 'siteKey' component property
      invisibleCaptchaSiteKey: "yyy", // optional, can be overridden with 'siteKey' component property
    }),
    // BsDropdownModule.forRoot(),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    NgbModule.forRoot(),
    NgbModalModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),

    //BsDropdownModule.forRoot(),
    InternationalPhoneNumberModule,
    //NgxIntlTelInputModule,
    //AlertsModule.forRoot(),
    DlDateTimePickerDateModule,
    CdkTableModule,
    CdkTreeModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ReactiveFormsModule,
    CKEditorModule,
    // ChartsModule
  ],
  providers: [
    // {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    UserService,
    AuthGuard,
    CustomerService,
    ModalService,
    CommonService,
    AlertService,
    UploadFileService,
    LeaveService,
    MycosService,
    FileuploadService,
    Papa,
    // DeviceDetectorService
    // ,
    // {
    //   provide : HTTP_INTERCEPTORS,
    //   useClass : AuthInterceptor,
    //   multi : true
    // }

    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
