import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Idproof } from '../../../models/idproof/idproof.model';
import { Employee } from '../../../models/employee.model';
import { MyidproofService } from '../../../services/myidproof.service';
import { AlertService } from '../../../services/alert.service';
import { UploadFileService } from '../../../services/upload-file.service';
import { CustomerService } from '../../../services/customer.service';



@Component({
  selector: 'app-idfaceaiverify',
  templateUrl: './idfaceaiverify.component.html',
  styleUrls: ['./idfaceaiverify.component.css']
})
export class IdfaceaiverifyComponent implements OnInit {

  
  submitted: any;
  idproofpopForm: FormGroup;
  idproof: Idproof;
  permission: any;
  requestperm: any;
  record: number;

  idproofdetails = new Idproof();  
  employee = new Employee();
  userId: any;
  ctype:number;
  selectedFiles: any;
  fileformaterror:any;
  filesizeerror:any;
  file:any;
  employeemasterid: any;
  documentprofileimage: any;
  nextDisable: any = true;
  data3:any;
  employeeidproofdeatilid:any;

  constructor(private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder,  
    private idproofService: MyidproofService, 
    private activatedRoute: ActivatedRoute,
    private alerts: AlertService,    
    private uploadService: UploadFileService,  
    private data: CustomerService, 
    private router: Router) { }

  ngOnInit() {

    this.userId = localStorage.getItem('userid');
    if (!this.userId) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;

    this.ctype  = parseInt(localStorage.getItem('ctype'));
    this.employeeidproofdeatilid = this.activatedRoute.snapshot.paramMap.get("idproofid");

    this.data3 = {"employeeidproofdeatilid": this.employeeidproofdeatilid}
    this.idproofService.idproofdetailsbyidproofid(this.data3).subscribe((data: any) => {     
      this.employeemasterid = data.employee.employeemasterid;
      if(data.documentprofileimage)
      {
        this.nextDisable = false;
        this.documentprofileimage = data.documentprofileimage;
      }else if(data.employee.imagename)
      {
        this.nextDisable = false;
        this.documentprofileimage = data.employee.imagename;
      }
      
      // console.log(data);

      this.idproofdetails = data;
      this.record  = data.length;

      // this.data = data;
      // this.record = data.length;
    });

  }

  
  selectFile(event)
  {
      var files = event.target.files;
      var selectedFiles = files[0];
      this.selectedFiles = selectedFiles;
      const file = this.selectedFiles;
      this.fileformaterror = false;
      this.filesizeerror = false;
      
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        this.alerts.success('File size must be less than 2 MB.',true); 
        return;
      }
      else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") ) {
        this.fileformaterror = true;
        this.alerts.success('Please choose a valid file format (jpg, png,ttf,gif)',true);         
        return;
      }
      var imagepath = this.uploadService.uploadfile(file); 
      // this.idproofdetails.employee.imagename  = imagepath;
      this.documentprofileimage = '../../../../../../assets/img/loadings.gif';
      // console.log(this.employeemasterid);
      var forupdate = { 
        'documentprofileimage': imagepath,
        'id': this.employeeidproofdeatilid,
        'createby': this.userId
      };
      this.idproofService.myidproofdetailsUpdatebyadmin(forupdate).subscribe((data: any) => 
      {
        setTimeout(() => this.documentprofileimage = imagepath, 3000);
      });     
     
  }

  onSubmit()
  {
    var checkData = {"employeeidproofdeatilid": this.employeeidproofdeatilid}
    this.idproofService.idproofdetailsbyidproofid(checkData).subscribe((data: any) => { 
      if(data.documentprofileimage)
      {
        this.router.navigate(['/idproofaipaymentdetails/' + this.employeeidproofdeatilid]);
      } else{
        var forupdate = { 
          'documentprofileimage': this.documentprofileimage,
          'id': this.employeeidproofdeatilid,
          'createby': this.userId
        };
        this.idproofService.myidproofdetailsUpdatebyadmin(forupdate).subscribe((data: any) => 
        {
          this.router.navigate(['/idproofaipaymentdetails/' + this.employeeidproofdeatilid]);
        });     
      }
    });
  }


}
