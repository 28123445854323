"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var fca_model_1 = require("../models/fca.model");
var forms_1 = require("@angular/forms");
var fcasearch_service_1 = require("../services/fcasearch.service");
var RegisterfcaComponent = /** @class */ (function () {
    // searchnewresult:any;
    function RegisterfcaComponent(formBuilder, fcaService) {
        this.formBuilder = formBuilder;
        this.fcaService = fcaService;
        this.search = new fca_model_1.Fca();
        this.submitted = false;
    }
    RegisterfcaComponent.prototype.ngOnInit = function () {
        this.registerForm = this.formBuilder.group({
            companyname: ['', forms_1.Validators.required],
            postcode: [''],
            searchtype: ['', forms_1.Validators.required]
        });
    };
    Object.defineProperty(RegisterfcaComponent.prototype, "f", {
        get: function () { return this.registerForm.controls; },
        enumerable: true,
        configurable: true
    });
    RegisterfcaComponent.prototype.onSubmit = function () {
        var _this = this;
        console.log(this.search);
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.fcaService.getFCAFirmcommonSearch(this.search).subscribe(function (data2) {
            var searchnewresult;
            var searchresultff = [];
            var post = [];
            var name;
            var pincode = '';
            for (var _i = 0, data2_1 = data2; _i < data2_1.length; _i++) {
                var ss = data2_1[_i];
                searchnewresult = {};
                post = ss['Name'].split('Postcode:');
                if (post.length == 2) {
                    pincode = post[1].replace(')', '');
                    console.log(post[1]);
                }
                name = post[0].slice(0, -1);
                searchnewresult.name = name;
                searchnewresult.status = ss['Status'];
                searchnewresult.refno = ss['Reference Number'];
                searchnewresult.type = ss['Type of business or Individual'];
                searchnewresult.postcode = pincode;
                searchresultff.push(searchnewresult);
            }
            _this.searchresult = searchresultff;
            // console.log('hum1',data2);
            //   this.fcaService.getFCAFirmDetails(this.search).subscribe((data2: any) => {
            //     console.log('hum1',data2);
            //   this.fcaService.getFCAFirmName(this.search).subscribe((data2: any) => {
            //     console.log('hum2',data2);
            //   });
            //   this.fcaService.getFCAFirmIndividuals(this.search).subscribe((data2: any) => {
            //     console.log('hum3',data2);
            //   });
            //   this.fcaService.getFCAFirmRequirements(this.search).subscribe((data2: any) => {
            //     console.log('hum4',data2);
            //   });
            //   this.fcaService.getFCAFirmPermission(this.search).subscribe((data2: any) => {
            //     console.log(data2);
            //   });
            //   this.fcaService.getFCAFirmPassport(this.search).subscribe((data2: any) => {
            //     console.log(data2);
            //   });
            //   this.fcaService.getFCAFirmRegulators(this.search).subscribe((data2: any) => {
            //     console.log(data2);
            //   });
            //   this.fcaService.getFCAFirmAddress(this.search).subscribe((data2: any) => {
            //     console.log(data2);
            //   });
            //   this.fcaService.getFCAFirmAppointedRepresentative(this.search).subscribe((data2: any) => {
            //     console.log(data2);
            //   });
            //   this.fcaService.getFCAFirmWaivers(this.search).subscribe((data2: any) => {
            //     console.log(data2);
            //   });
            //   this.fcaService.getFCAFirmExclusions(this.search).subscribe((data2: any) => {
            //     console.log(data2);
            //   });
            //   this.fcaService.getFCAFirmDisciplinaryHistory(this.search).subscribe((data2: any) => {
            //     console.log(data2);
            //   });
            // });
        });
    };
    return RegisterfcaComponent;
}());
exports.RegisterfcaComponent = RegisterfcaComponent;
