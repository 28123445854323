"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var plan_service_1 = require("../../services/plan.service");
var payment_service_1 = require("../../services/payment.service");
var plan_model_1 = require("../../models/plan.model");
var modal_service_1 = require("../../_services/modal.service");
var router_1 = require("@angular/router");
var stripe_angular_1 = require("stripe-angular");
var alert_service_1 = require("../../services/alert.service");
var user_service_1 = require("../../services/user.service");
var company_service_1 = require("../../services/company.service");
var app_global_1 = require("../../app.global");
var checksystem_service_1 = require("../../services/checksystem.service");
var FileSaver = require('file-saver');
var InvoiceComponent = /** @class */ (function () {
    function InvoiceComponent(formBuilder, check, modalService, router, alerts, plan, userService, companyService, StripeScriptTag, paymentService) {
        this.formBuilder = formBuilder;
        this.check = check;
        this.modalService = modalService;
        this.router = router;
        this.alerts = alerts;
        this.plan = plan;
        this.userService = userService;
        this.companyService = companyService;
        this.StripeScriptTag = StripeScriptTag;
        this.paymentService = paymentService;
        this.publishableKey = app_global_1.AppGlobals.API_STRIPE;
        this.planDetail = new plan_model_1.Plan();
        this.isChecked = false;
    }
    InvoiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.downloadinvoicetext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
        this.downloadcheckinvoicetext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        // var ctype  = parseInt(localStorage.getItem('ctype'));
        // if(ctype ==1){
        //   this.router.navigate(['/check-dashboard']);
        // }
        this.StripeScriptTag.setPublishableKey(this.publishableKey);
        this.timestamp = function (epoch) {
            return (epoch * 1000);
        };
        this.planformGroup = this.formBuilder.group({
            coupnsnumber: ['', forms_1.Validators.required]
        });
        var isemail = localStorage.getItem('isemail');
        this.planid = localStorage.getItem('planid');
        this.companyService.getinvoicelist().subscribe(function (data) {
            console.log(data);
            if (data.invoicelist.length) {
                _this.invoiceDetails = data.invoicelist[0];
            }
            _this.companyLocation = data.companylocation;
            _this.companyInfo = data.company;
            _this.invoicelist = data.invoicelist;
        });
        this.companyService.gettotalcards().subscribe(function (data) {
            _this.cardlist = data;
        });
        this.companyService.getnextbillingdate().subscribe(function (data) {
            _this.billinglist = data;
        });
        this.checkInvoices();
    };
    InvoiceComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    InvoiceComponent.prototype.checked = function (id) {
        this.isChecked = id;
    };
    InvoiceComponent.prototype.checkInvoices = function () {
        var _this = this;
        this.check.getAllInvoice().subscribe(function (data) {
            _this.record = data.invoicelist.length;
            _this.pageOfItems = data.invoicelist;
            if (data.invoicelist.length) {
                _this.checkInvoiceDetails = data.invoicelist[0];
            }
            if (_this.record < 5) {
                _this.pageOfItems = data.invoicelist;
            }
            else {
                _this.items = data.invoicelist;
            }
        });
    };
    InvoiceComponent.prototype.getInvoiceData = function (invoiceid) {
        var _this = this;
        var data = { 'invoiceid': invoiceid };
        this.companyService.getinvoiceretrive(data).subscribe(function (invoiceData) {
            _this.invoiceDetails = invoiceData;
        });
    };
    InvoiceComponent.prototype.downloadInvoice = function (invoiceid) {
        var _this = this;
        this.downloadinvoicetext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        var data = { 'invoiceid': invoiceid };
        this.companyService.downloadInvoice(data).subscribe(function (invoiceData) {
            _this.downloadInvoiceData = invoiceData;
            _this.downloadinvoicetext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            window.open(_this.downloadInvoiceData.response_data.Location, "_blank");
        });
    };
    InvoiceComponent.prototype.downloadCheckInvoice = function (invoiceid) {
        var _this = this;
        this.downloadcheckinvoicetext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        var data = { 'invoiceid': invoiceid };
        this.check.downloadCheckInvoice(data).subscribe(function (invoiceData) {
            _this.downloadCheckInvoiceData = invoiceData;
            _this.downloadcheckinvoicetext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            window.open(_this.downloadCheckInvoiceData.response_data.Location, "_blank");
        });
    };
    InvoiceComponent.prototype.getCheckInvoiceData = function (invoiceid) {
        var _this = this;
        var data = { 'invoiceid': invoiceid };
        this.check.getAllInvoicedetails(data).subscribe(function (invoiceData) {
            _this.checkInvoiceDetails = invoiceData;
        });
    };
    InvoiceComponent.prototype.downloadinvoice = function (invoiceid) {
        var data = { 'invoiceid': invoiceid };
        this.companyService.sendinvoicesend(data).subscribe(function (data) {
            // console.log(data);
        });
    };
    return InvoiceComponent;
}());
exports.InvoiceComponent = InvoiceComponent;
