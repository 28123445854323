"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../../_services/modal.service");
var country_service_1 = require("../../../../services/country.service");
var aml_model_1 = require("../../../../models/aml/aml.model");
var aml_service_1 = require("../../../../services/aml.service");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var SearchbussinessamldetailsComponent = /** @class */ (function () {
    function SearchbussinessamldetailsComponent(modalService, router, amldetails, sanitizer, activatedRoute, country) {
        this.modalService = modalService;
        this.router = router;
        this.amldetails = amldetails;
        this.sanitizer = sanitizer;
        this.activatedRoute = activatedRoute;
        this.country = country;
        this.resultevidancelist = [];
        this.tPEPs = 0;
        this.tCurrentSanctions = 0;
        this.tDisqualifiedDirector = 0;
        this.tFinancialRegulator = 0;
        this.tInsolvency = 0;
        this.tLawEnforcement = 0;
        this.tAdverseMedia = 0;
        this.tPreviousSanctions = 0;
        this.noresult = false;
        this.PEPs = [];
        this.CurrentSanctions = [];
        this.DisqualifiedDirector = [];
        this.FinancialRegulator = [];
        this.Insolvency = [];
        this.LawEnforcement = [];
        this.AdverseMedia = [];
        this.PreviousSanctions = [];
        this.atype = 1;
    }
    SearchbussinessamldetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.downloadtext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
        var newlist;
        var newpersonalid;
        this.bussinessdetails = new aml_model_1.amldetailsclass();
        this.personid = this.activatedRoute.snapshot.paramMap.get("bussinessid");
        newpersonalid = this.personid;
        this.searchresult = [];
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.logid = this.activatedRoute.snapshot.paramMap.get("id");
        this.resourceId = this.activatedRoute.snapshot.paramMap.get("resourceid");
        var val = {};
        if (this.resourceId)
            val = { id: this.logid, atype: 0, resourceId: this.resourceId };
        else
            val = { id: this.logid, atype: 0 };
        // var val ={ id: this.logid,atype:1}
        this.amldetails.getCompanylogrecord(val).subscribe(function (searchRes) {
            var listmaster = JSON.parse(searchRes.searchresult);
            _this.atype = searchRes.atype;
            if (searchRes.atype == '1') {
                _this.backtext = 'Back to Search Results';
            }
            else if (searchRes.atype == '4') {
                _this.backtext = 'Back to Search Results ';
            }
            var director = JSON.parse(searchRes.director);
            var opendirector = JSON.parse(searchRes.opendirector);
            if (searchRes.iscomplete === 0 && !_this.resourceId) {
                if (listmaster.results.matchCount == 0) {
                    _this.noresult = true;
                    _this.result = true;
                    _this.searchcondition = JSON.parse(searchRes.searchcondition);
                }
            }
            else {
                _this.noresult = false;
                _this.searchresult = listmaster;
                _this.bindattribute(listmaster);
                _this.directors = director;
                _this.opendirector = opendirector;
                _this.bussinessdetails = listmaster;
            }
        });
    };
    SearchbussinessamldetailsComponent.prototype.urlfind = function (url) {
        if (url != null) {
            var slashslash = url.indexOf("//") + 2;
            return url.substring(slashslash, url.indexOf('/', slashslash));
        }
        else {
            return "";
        }
    };
    SearchbussinessamldetailsComponent.prototype.bindallcountry = function (val) {
        return this.countrylist.filter(function (d) { return val.includes(d.iso); });
        // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
    };
    SearchbussinessamldetailsComponent.prototype.bindselectedevidance = function (val) {
        return this.bussinessdetails.evidences.filter(function (d) { return val.includes(d.evidenceId); });
    };
    SearchbussinessamldetailsComponent.prototype.amllogredirect = function () {
        if (this.atype == '1' && this.resourceId)
            this.router.navigate(['/search-business-aml', 'aml', this.logid]);
        else if (this.atype == '4' && this.resourceId)
            this.router.navigate(['/search-business-aml', 'global', this.logid]);
        else if (this.atype == '1')
            this.router.navigate(['/check-aml-log-list', '1']);
        else if (this.atype == '4')
            this.router.navigate(['/check-aml-log-list', '4']);
    };
    SearchbussinessamldetailsComponent.prototype.bindattribute = function (data) {
        this.AdverseMedia = [];
        this.PreviousSanctions = [];
        this.PEPs = [];
        this.LawEnforcement = [];
        this.CurrentSanctions = [];
        this.FinancialRegulator = [];
        this.DisqualifiedDirector = [];
        this.Insolvency = [];
        var rAdverseMedia = [];
        var rPreviousSanctions = [];
        var rPEPs = [];
        var rLawEnforcement = [];
        var rCurrentSanctions = [];
        var rFinancialRegulator = [];
        var rDisqualifiedDirector = [];
        var rInsolvency = [];
        var datasetlist = [];
        data.evidences.forEach(function (element2) {
            element2.datasets.forEach(function (element3) {
                datasetlist.push(element3);
            });
        });
        var unique = datasetlist.filter(function (item, i, ar) { return ar.indexOf(item) === i; });
        var evidanceobj = { name: '', values: [] };
        var evidancelist = [];
        unique.forEach(function (element) {
            evidanceobj =
                {
                    name: element,
                    values: data.evidences.filter(function (d) { return d.datasets.includes(element); })
                };
            evidancelist.push(evidanceobj);
        });
        this.resultevidancelist = evidancelist;
        this.AdverseMedia = rAdverseMedia;
        this.PreviousSanctions = rPreviousSanctions;
        this.PEPs = rPEPs;
        this.LawEnforcement = rLawEnforcement;
        this.CurrentSanctions = rCurrentSanctions;
        this.FinancialRegulator = rFinancialRegulator;
        this.DisqualifiedDirector = rDisqualifiedDirector;
        this.Insolvency = rInsolvency;
    };
    SearchbussinessamldetailsComponent.prototype.loaddatasystem = function () {
        var _this = this;
        this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        var dd = {};
        if (this.resourceId) {
            dd = { id: this.logid, atype: this.atype, resourceId: this.resourceId };
        }
        else {
            dd = { id: this.logid, atype: this.atype };
        }
        this.amldetails.generateNewbussinessPdf(dd).subscribe(function (pdfRes) {
            _this.url = pdfRes.response_data.Location;
            window.open(_this.url, "_blank");
            _this.downloadtext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
        });
    };
    SearchbussinessamldetailsComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    SearchbussinessamldetailsComponent.prototype.opendocumentlink = function (id, data) {
        // console.log("data value", data);
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgvisasrc = true;
            this.iframevisasrc = data;
        }
        else {
            this.imgvisasrc = false;
            this.iframevisasrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    SearchbussinessamldetailsComponent.prototype.openModal = function (id, data) {
        //   this.visa = new Visa();
        //   this.disableBtn = true;
        //   this.visaDetail = new Visa();
        //   this.visaDetail = data;
        //  // console.log(data);
        //   this.buttoninsert = "Update";
        this.modalService.open(id);
    };
    SearchbussinessamldetailsComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    SearchbussinessamldetailsComponent.prototype.norecordfound = function () {
        var _this = this;
        this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        var dd = {};
        if (this.resourceId) {
            dd = { id: this.logid, atype: 0, resourceId: this.resourceId };
        }
        else {
            dd = { id: this.logid, atype: this.atype };
        }
        this.amldetails.generatenorecordv3Pdf(dd).subscribe(function (pdfRes) {
            _this.url = pdfRes.response_data.Location;
            window.open(_this.url, "_blank");
            _this.downloadtext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
        });
    };
    return SearchbussinessamldetailsComponent;
}());
exports.SearchbussinessamldetailsComponent = SearchbussinessamldetailsComponent;
