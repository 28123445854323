import { Component, OnInit, OnDestroy, HostListener, Inject } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { Subscription } from 'rxjs';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
declare let $: any;
// import { NgwWowService } from 'ngx-wow';
import { filter } from 'rxjs/operators';
import './_content/app.less';
import './_content/modal.less';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ],
  animations:[ 
    trigger('fade',
    [ 
      state('void', style({ opacity : 0})),
      transition(':enter',[ animate(300)]),
      transition(':leave',[ animate(500)]),
    ]
)]
})

export class AppComponent implements OnInit, OnDestroy{
  title = 'S';
  //location3: Location;
  private wowSubscription: Subscription;
  location: any;
  routerSubscription: any;
  constructor(
    @Inject(DOCUMENT) document,
    private router: Router, 
    location: Location
    // private wowService: NgwWowService
    ){
    // this.wowService.init(); 
  }
  
  ngOnInit() {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
    // this.wowSubscription = this.wowService.itemRevealed$.subscribe(
    //   (item:HTMLElement) => {
    //     // 
    //   });
      this.recallJsFuntions();
  }
  @HostListener('window:scroll', ['$event'])
//   onWindowScroll(e) {
//     if (window.pageYOffset > 120) {
//       let element = document.getElementById('navbar');
//       element.classList.add('headersticky');
//     } else {
//      let element = document.getElementById('navbar');
//        element.classList.remove('headersticky'); 
//     }
//  }
  recallJsFuntions() {
    this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader').fadeIn('slow');
            }
        });
    this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/custom.js');
            $('.preloader').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            // window.scrollTo(0, 0);
        });
  }

  ngOnDestroy() {
    // unsubscribe (if necessary) to WOW observable to prevent memory leaks
    this.wowSubscription.unsubscribe();
  }
}