"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Companyprofile = /** @class */ (function () {
    function Companyprofile() {
    }
    return Companyprofile;
}());
exports.Companyprofile = Companyprofile;
var License = /** @class */ (function () {
    function License() {
    }
    return License;
}());
exports.License = License;
var Plan = /** @class */ (function () {
    function Plan() {
    }
    return Plan;
}());
exports.Plan = Plan;
var Countcompanydata = /** @class */ (function () {
    function Countcompanydata() {
    }
    return Countcompanydata;
}());
exports.Countcompanydata = Countcompanydata;
var companytype = /** @class */ (function () {
    function companytype() {
    }
    return companytype;
}());
exports.companytype = companytype;
