"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_service_1 = require("../../../../services/employee.service");
var modal_service_1 = require("../../../../_services/modal.service");
var profile_model_1 = require("../../../../models/overview/profile.model");
var country_service_1 = require("../../../../services/country.service");
var common_service_1 = require("../../../../services/common.service");
var job_service_1 = require("../../../../services/job.service");
var candidate_service_1 = require("../../../../services/candidate.service");
var user_service_1 = require("../../../../services/user.service");
var permission_service_1 = require("../../../../services/permission.service");
var PersonalinformationComponent = /** @class */ (function () {
    function PersonalinformationComponent(userService, employeeService, country, modalService, commonService, formBuilder, jobdetails, candidatedetails, permissiondetails) {
        this.userService = userService;
        this.employeeService = employeeService;
        this.country = country;
        this.modalService = modalService;
        this.commonService = commonService;
        this.formBuilder = formBuilder;
        this.jobdetails = jobdetails;
        this.candidatedetails = candidatedetails;
        this.permissiondetails = permissiondetails;
        this.th = [];
        this.emailexist = false;
        this.mindob = new Date();
        this.maxdob = new Date();
    }
    PersonalinformationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        this.mindob.setDate(this.mindob.getDate() - 23741);
        this.maxdob.setDate(this.maxdob.getDate() - 574);
        this.employeeForm = this.formBuilder.group({
            fname: ['', forms_1.Validators.required],
            mname: [''],
            lname: ['', forms_1.Validators.required],
            gender: ['', forms_1.Validators.required],
            placeofbirth: ['', forms_1.Validators.required],
            maritalstatus: ['', forms_1.Validators.required],
            ninumber: ['', forms_1.Validators.required],
            jobid: [''],
            candidateid: [''],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            phonenumber: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            residenceid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            countryofbirthid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            dateofbirth: ['', forms_1.Validators.required],
        });
        this.overviewData = new profile_model_1.Profile();
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.jobdetails.getjobList().subscribe(function (jobdetails) {
            _this.joblist = jobdetails;
        });
        this.permissiondetails.bindallmenu().subscribe(function (menudata) {
            var permissionstring = "[{";
            var perarray = [];
            menudata.forEach(function (valuemenu) {
                perarray.push('"' + valuemenu.clname + '": 0');
            });
            var perstr = perarray.join();
            permissionstring = permissionstring + perstr + "}]";
            _this.permissionstring = permissionstring;
        });
        this.employeeService.getlistofemployee().subscribe(function (employeedata) {
            employeedata.forEach(function (value) {
                var tg;
                tg = JSON.parse(value.permission.toString());
                tg.forEach(function (value4) {
                });
            });
            _this.data = employeedata;
        });
    };
    Object.defineProperty(PersonalinformationComponent.prototype, "f", {
        get: function () { return this.employeeForm.controls; },
        enumerable: true,
        configurable: true
    });
    PersonalinformationComponent.prototype.onSubmit = function (employeeform1, modelid) {
        var _this = this;
        this.submitted = true;
        if (this.employeeForm.invalid) {
            return;
        }
        this.disableBtn = false;
        employeeform1.id = this.Userid;
        employeeform1.permission = this.permissionstring;
        this.userService.isEmailRegisterd(employeeform1.emailid).subscribe(function (data) {
            if (data.sussess == 'sussess') {
                _this.emailexist = true;
            }
            else {
                _this.employeeService.employeeUpdateForm1(employeeform1).subscribe(function (data) {
                    _this.employeeService.getlistofemployee().subscribe(function (employeedata) {
                        _this.data = employeedata;
                        _this.modalService.close(modelid);
                    });
                });
            }
        });
    };
    PersonalinformationComponent.prototype.onChangeStatus = function (evt, emp) {
        var status;
        if (evt.target.checked) {
            status = '1';
        }
        else if (!evt.target.checked) {
            status = '0';
        }
        this.employeeService.employeeUpdateChangeStatus({ 'userid': this.Userid, 'id': emp.employeemasterid, 'estatus': evt.target.checked }).subscribe(function (data) {
        });
    };
    PersonalinformationComponent.prototype.focusFunction = function () {
        this.emailexist = false;
    };
    PersonalinformationComponent.prototype.isEmailUnique = function (control) {
        var _this = this;
        var q = new Promise(function (resolve, reject) {
            _this.userService.isEmailRegisterd(control.value).subscribe(function () {
                resolve(null);
            }, function () { resolve({ 'isEmailUnique': true }); });
        });
        return q;
    };
    PersonalinformationComponent.prototype.onSelectjob = function (job_id) {
        var _this = this;
        var userid = localStorage.getItem('userid');
        var data = { 'jobid': job_id, 'id': userid };
        this.candidatedetails.getselectedcandidate(data).subscribe(function (candidatedetails) {
            return _this.candidatelist = candidatedetails;
        });
    };
    PersonalinformationComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    PersonalinformationComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.disableBtn = true;
        this.overviewData.gender = "1";
        this.overviewData.maritalstatus = "0";
        this.modalService.open(id);
    };
    PersonalinformationComponent.prototype.closeModal = function (id) {
        var _this = this;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.modalService.close(id);
    };
    return PersonalinformationComponent;
}());
exports.PersonalinformationComponent = PersonalinformationComponent;
