import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { CommonService } from '../../../services/common.service';
import { CountryService } from '../../../services/country.service';
import { CompanyService } from '../../../services/company.service';
import { JobService } from '../../../services/job.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Jobs } from '../../../models/jobs/jobs.model';
import { MatOptionSelectionChange } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFileService } from './../../../services/upload-file.service';
import { Country } from './../../../models/address/country.model';
import { Companyprofile, License, Plan, Countcompanydata } from "./../../../models/company/companyprofile.model";
import { AlertService } from '../../../services/alert.service';
import { Router } from '@angular/router';
import { EmployeeService } from '../../../services/employee.service';
import { GraphreportService } from '../../../services/graphreport.service';

import { Companylocation } from '../../../models/location/companylocation.model';


@Component({
  selector: 'app-checkbillinginformation',
  templateUrl: './checkbillinginformation.component.html',
  styleUrls: ['./checkbillinginformation.component.css']
})
export class CheckbillinginformationComponent implements OnInit {


  Userid: any;
  joblist: any;
  data: any;
  companyprofileDetail = new Companyprofile();
  profileformGroup: FormGroup;
  public Editor = ClassicEditor;
  mobilecheck: any;
  phonecheck: any;
  socdata: any;
  countrylist: any;
  countylist: any;
  options = [];
  buttoninsert: any;
  submitted: boolean = false;
  selectedFiles: FileList;
  filename: any;
  file1: any;
  file2: any;
  file3: any;
  file4: any;
  screenumber: any;
  disableBtn: any;
  searchText:any;
  companytypelist: any;
  companylocationlist: any;
  companyplanDetails: any;
  countcompanydata = new Countcompanydata();

  locationDetail = new Companylocation();
  locationformGroup: FormGroup;
  companytype: any;
  locationperm: any;
  defaultVal: any = "";
  record:number;
  ls:any;
  items: Array<any>;

  weburl: any;
  currentDate = new Date();
  planStartDate = new Date();
  planRenewDate = new Date();
  planExpiredOn = new Date();
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  public barChartLabels = ['Employees'];
  public barChartType = 'bar';
  public barChartLegend = true;

  public barChartData = [
    
    {data: [0], label: 'Male'},
    {data: [0], label: 'Female'},
    {data: [0], label: ''}
  ];
  permission: any;
  profileperm: any;
  

  constructor(
    private sanitizer: DomSanitizer, 
    private commonService: CommonService, 
    private modalService: ModalService, 
    private employeeService: EmployeeService, 
    private graphService: GraphreportService, 
    private formBuilder: FormBuilder,
    private country: CountryService,
    private uploadService: UploadFileService,
    private company: CompanyService,
    private router: Router,
    private alerts: AlertService
    ) { }

  ngOnInit() {  

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }
    
    var isadmin = localStorage.getItem('isadmin');
    if(isadmin!='true'){
      this.router.navigate(['/permission-denied']);
    }

    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.profileperm = this.permission.m91;
   
    this.companyprofileDetail  = new Companyprofile();
    this.companyprofileDetail.license  = new License();
    this.companyprofileDetail.plan  = new Plan();
    this.countcompanydata = new Countcompanydata();
    this.Userid = localStorage.getItem('userid');

    this.getprofileformgroup();
    
    this.getlocationformgroup();

    this.company.getcompanylocation().subscribe((locationlist: any) => {   
      this.record = locationlist.length;   
      this.data = locationlist;      
    });

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    const countrydata = { 'id': 70 };
    this.country.getstatelist(countrydata).subscribe((state: any) => {
      this.countylist = state;
    });

    this.company.getcompanylocationtype().subscribe((locationTypelist: any) => {       
      this.companytype = locationTypelist;
    });

    this.weburl ='';

    // this.graphService.getdepartmentList().subscribe((locationlist: any) => {      
    //   this.companylocationlist = locationlist;      
    // });

    
    
    this.company.getcompanylocation().subscribe((locationlist: any) => {         
      this.companylocationlist = locationlist;      
    });

    this.country.getsoclist().subscribe((socdata: any) => {
      this.socdata = socdata;
    });
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.company.getcompanyType().subscribe((companytypes: any) => {
      this.companytypelist = companytypes;
    });

    this.company.getcompanyData().subscribe((companydata: any) => {
      
      this.companyprofileDetail = companydata;
      
      if(this.companyprofileDetail.websiteurl)
      {
        this.weburl = this.companyprofileDetail.websiteurl.replace('https://','').replace('http://','');
      }
      
      if(!this.companyprofileDetail.license)
      {
        this.companyprofileDetail.license  = new License();
      }
    });

    this.company.getcountCompanyData().subscribe((countcompanydata: any) => {
      this.countcompanydata = countcompanydata;
    });

    this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
      var maleArray = [];
      var femaleArray = [];
      employeedata.forEach(function (value) {
        if(value.gender == true)
        {
          maleArray.push(value.employeemasterid);
        }
        else if(value.gender == false)
        {
          femaleArray.push(value.employeemasterid);
        }
      });
      this.barChartData = [{data: [maleArray.length], label: 'Male'},
                            {data: [femaleArray.length], label: 'Female'},
                            {data: [0], label: ''}
                          ];
      //this.barChartData[1].data = [femaleArray.length];
    });

    this.company.getplanDetails().subscribe((plandata: any) => {
      var firstDateArray = [];
      var lastDateArray = [];
      var min = 0;
      var max = 0;
      var idArray = [];
      plandata.forEach(function (value) {
        idArray.push(value.planuserdetailid);
        firstDateArray[value.planuserdetailid] = value.joiningdate;
        lastDateArray[value.planuserdetailid] = value.expireddate;
      });
      min = Math.min.apply(null, idArray);
      max = Math.max.apply(null, idArray);
      this.planStartDate = new Date(firstDateArray[min]);
      var year = this.planStartDate.getFullYear();
      var month = this.planStartDate.getMonth();
      var day = this.planStartDate.getDate();
      this.planRenewDate = new Date(year + 2, month, day);
      this.planExpiredOn = new Date(lastDateArray[max]);
      this.companyplanDetails = plandata;
    });

  }

  updatecode(evt: MatOptionSelectionChange, response) {
    res = [];
    if (evt.source.selected) {
      var res = response.split(",");
      this.locationDetail.address1 = res[0];
      this.locationDetail.address2 = res[1];
      this.locationDetail.cityname = res[5];
      this.locationDetail.pincode = res[7];
      this.locationDetail.statename = res[6];
    }
  }

  getprofileformgroup()
  {
    this.profileformGroup = this.formBuilder.group({
      companylicensedetailsid: [''],  
      companyid: ['', Validators.required],
      companyname: ['', Validators.required],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      secondryemailid: ['', Validators.compose([Validators.email])],
      phonenumber: ['', Validators.compose([Validators.required])],
      sphonenumber: [''],
      companytypeid: [''],
      websiteurl: [''],
      clientname: ['', Validators.required],
      clietdesignation: ['', Validators.required]
    });
  }
  
  getlocationformgroup()
  {
    this.locationformGroup = this.formBuilder.group({
      id: [''],
      companylocationdetailsid: [''],    
      companytypeid: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      pincode: ['', Validators.required],
      cityname: ['', Validators.required],
      statename: ['', Validators.required],
      countryid: ['', Validators.required],     
      companyname: ['']      
    });
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.items = pageOfItems;
  }

  get f() { return this.profileformGroup.controls; }

  onSubmit(companyprofiledata: any, id: string)
  {
    this.submitted = true;
    if (this.profileformGroup.invalid) 
    {      
      return;
    }
    //console.log(companyprofiledata);
    this.disableBtn = false;
    companyprofiledata.userid = this.Userid;
    
    this.company.companyprofileUpdate(companyprofiledata).subscribe((response: any) => {
      this.company.getcompanyData().subscribe((companydata: any) => {
        this.companyprofileDetail = companydata;

        this.weburl =this.companyprofileDetail.websiteurl.replace('https://','').replace('http://','');
      
        if(!this.companyprofileDetail.license)
        {
          this.companyprofileDetail.license  = new License();
        }
        this.modalService.close(id);
        this.alerts.success('Profile updated successfully',true);
        
      });
    });
  }

  get g() { return this.locationformGroup.controls; }

  onLocationSubmit(formlocation: any, id: string)
  {
    console.log(this.locationformGroup)
    this.submitted = true;
    var pinc = formlocation.pincode.split(',');
    formlocation.pincode = pinc[pinc.length - 1];
    if (this.locationformGroup.invalid) {
      return;
    }
    this.disableBtn = false;
    formlocation.userid = this.Userid;

    this.company.companylocationdetailsUpdate(formlocation).subscribe((response: any) => {
      this.company.getcompanylocation().subscribe((locationlist: any) => { 
        // companylocationformdata.reset();  
        this.record = locationlist.length;
        // this.locationDetail.countryid = 70;   
        this.data = locationlist;
        this.modalService.close(id);
      });
    });

  }

  selectFile(event) {
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = files[0];
    this.file1 = this.selectedFiles;
    var filetype = this.file1.type.split('/');
    if (this.file1.size > 2000 * 1024) {
      this.alerts.error('File should be less than 2 MB.',true); 
      return;  
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.alerts.error('File should be in correct format.',true); 
      return;
    }
    
    var imagepath = this.uploadService.uploadfile(this.file1);
    var userid =localStorage.getItem('userid');
    var forupdate = { 'logoname': imagepath, 'id': userid };
    this.company.updatecompanyimage(forupdate).subscribe((data: any) => 
    {
      localStorage.setItem('logoname', imagepath);
      setTimeout(() => this.companyprofileDetail.logoname = imagepath, 3000);
      
    });
    this.alerts.success('Logo updated successfully',true); 
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  onSearchChange(searchValue) {
    if (searchValue.length > 4) {
      var newArray = [];
      this.commonService.getAddress(searchValue).subscribe((data: any) => {
        var newdata: any;
        newdata = data;
        newdata.addresses.forEach(function (value) {
          newArray.push(value + "," + searchValue);
        });
        this.options = newArray;
      });
    }
    else {
      this.options = [];
    }
  }

  displayFn(value: any) {
    if (value != null) 
    {
      var res = value.split(",");
      if (res.length > 6) 
      {
        return res[7];
      }
      else {
        return value;
      }
    }
  }

  openModal(id: string) {
    this.submitted = false;
    this.disableBtn = true;

    this.companyprofileDetail  = new Companyprofile();
    this.companyprofileDetail.license  = new License();
    this.companyprofileDetail.plan  = new Plan();
    this.countcompanydata = new Countcompanydata();

    if((this.companyprofileDetail.license.companylicensedetailsid == "") || (this.companyprofileDetail.license.companylicensedetailsid == null))
    {
      this.companyprofileDetail.license.companylicensedetailsid = "0";
    }
    

    this.company.getcompanyData().subscribe((companydata: any) => {
      this.companyprofileDetail = companydata;
      if(this.companyprofileDetail.companytypeid == "0")
      {
        this.companyprofileDetail.companytypeid = "";
      }
      if(!this.companyprofileDetail.license)
      {
        this.companyprofileDetail.license  = new License();
      }
    });

    

    this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
      var maleArray = [];
      var femaleArray = [];
      employeedata.forEach(function (value) {
        if(value.gender == true)
        {
          maleArray.push(value.employeemasterid);
        }
        else if(value.gender == false)
        {
          femaleArray.push(value.employeemasterid);
        }
      });
      this.barChartData = [{data: [maleArray.length], label: 'Male'},
                            {data: [femaleArray.length], label: 'Female'},
                            {data: [0], label: ''}
                          ];
      
    });


    this.buttoninsert = "Update";
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.company.getcompanyData().subscribe((companydata: any) => {
      this.companyprofileDetail = companydata;
      
      if(!this.companyprofileDetail.license)
      {
        this.companyprofileDetail.license  = new License();
      }
      this.modalService.close(id);
    });

    
  }

  openModal2(id: string) 
  { 
    this.submitted = false;
    this.buttoninsert = "Submit";
    this.disableBtn = true;
    this.locationDetail = new Companylocation();
    // this.getlocationformgroup();
    this.locationDetail.companytypeid = "";
    this.locationDetail.countryid = 70;
    this.locationDetail.companylocationdetailsid = "0";
    this.locationformGroup.reset(this.locationDetail);
    this.modalService.open(id);
  }

  editLocationModal(id: string, location: any) 
  { 
    this.locationDetail = new Companylocation();
    this.submitted = false;
    this.buttoninsert = "Submit";
    this.disableBtn = true;
    this.locationDetail = location;
    this.locationDetail.id = location.companylocationdetailsid;
    this.locationformGroup.reset(this.locationDetail);
    this.modalService.open(id);
  }

  openModal4(jobid: string, id: string, screenumber: number) 
  { 
    this.buttoninsert = "Submit";  
    this.disableBtn = true; 
    this.screenumber = screenumber;
    this.modalService.open(id);
  }



}
