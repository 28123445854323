"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var aml_service_1 = require("../../services/aml.service");
var router_1 = require("@angular/router");
var AdmincreditsafeComponent = /** @class */ (function () {
    function AdmincreditsafeComponent(amldetails, router) {
        this.amldetails = amldetails;
        this.router = router;
        this.tc = true;
    }
    AdmincreditsafeComponent.prototype.ngOnInit = function () {
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.Userid = localStorage.getItem('userid');
        // this.tc =false;
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.assetperm = this.permission.m16;
        // this.amldetails.getAuthenticatedetails().subscribe((data: any) => { 
        //   if(data){
        //     var db = {username: data.username, password: data.pwd}
        //     this.amldetails.getAuthenticate(db).subscribe((datatoken: any) => {
        //       var dt = new Date();
        //       dt.setMinutes( dt.getMinutes() + 55);
        //       localStorage.setItem('userTokenAML', datatoken.token);
        //       if(dt <= data.updateat){
        //       console.log(dt);
        //       if(datatoken)          
        //       {
        //         var db2 = { tokennumber: datatoken.token}
        //         this.amldetails.updateAuthenticatedetails(db2).subscribe((datatoken3: any) => {
        //         });
        //         this.tc =true;
        //       }
        //       else
        //       {
        //         this.tc =false;
        //       }
        //     }else{
        //      // localStorage.setItem('userTokenAML', data.token_number);
        //     }
        //     });
        //   } 
        // });
    };
    return AdmincreditsafeComponent;
}());
exports.AdmincreditsafeComponent = AdmincreditsafeComponent;
