"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var ZoomService = /** @class */ (function () {
    function ZoomService(commonService) {
        this.commonService = commonService;
    }
    ZoomService.prototype.sendZoomLink = function (value) {
        return this.commonService.formpost('/zoomlink', value);
    };
    ZoomService.prototype.sendZoomLinkWithIdentity = function (value) {
        return this.commonService.formpost('/zoomlinkwithidentity', value);
    };
    ZoomService.ngInjectableDef = i0.defineInjectable({ factory: function ZoomService_Factory() { return new ZoomService(i0.inject(i1.CommonService)); }, token: ZoomService, providedIn: "root" });
    return ZoomService;
}());
exports.ZoomService = ZoomService;
