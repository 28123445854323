import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../_services/modal.service';
import { ContactService } from '../../services/contact.service';
import { Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.css']
})
export class AdmindashboardComponent implements OnInit {
  permission: any;
  constructor(  private modalService: ModalService,private formBuilder: FormBuilder, 
    private contactService: ContactService,private router: Router,private alerts: AlertService) { }
  defaultVal: any = "";
  submitted: any;
  visapopForm: FormGroup;
  nrSelect:any;

  ngOnInit() {

    
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }


    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];

    
    this.createformgroup();

    this.nrSelect = '';    
    this.defaultVal ='';
    
  }

  get f() {
    return this.visapopForm.controls;
  }
  
  createformgroup()
  {
    this.visapopForm = this.formBuilder.group({     
      fname: ['', Validators.required],     
      bussinessemail: ['', Validators.required],
      phonenumber: ['', Validators.required],         
      querytype: ['', Validators.required],    
      subject: ['', Validators.required],     
      message: ['', Validators.required]
    });



  }

  
  onSubmit(value3: any, id: string) {

    this.submitted = true;
    if (this.visapopForm.invalid) {
      return;
    }

    value3.mailertype ='Contact Us';
    this.contactService.contactus(value3).subscribe((data: any) => {
      this.alerts.success("Query submitted successfully", false);
      this.modalService.close(id);
    });
  }

  openModalMastersystem(id: string) {
    this.visapopForm.reset();    
    this.submitted = false;
    this.modalService.open(id);
  }


  closeModal(id: string) 
  { this.visapopForm.reset();
    this.submitted = false;
    this.modalService.close(id);
  }
}
