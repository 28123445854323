



<div class="modal fade" id="deletereference-warning-modal" tabindex="-1" role="dialog"
aria-labelledby="modal-warning-label">
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header state modal-warning">
      <h4 class="modal-title" id="modal-warning-label"><i class="fa fa-exclamation"></i>Warning</h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
      Are you Sure, you want to delete this?
    </div>
    <div class="modal-footer">
      <button (click)="deletereference()" type="button" class="btn btn-warning" data-dismiss="modal">Delete</button>
      <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
    </div>
  </div>
</div>
</div>


<div class="bg">
  <app-header></app-header>


  <div class="section-wrapper" *ngIf="isshow">
    <div class="container">
      <a [routerLink]="['/reference-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to  Automated Reference Check</a>
      <div class="clearfix"></div>
      <div class="tabs" id="tabs"   >
        <div class="clearfix"></div>
        <div class="tabs__content">
          <div class="tabs__content__item active-tab">
            <div class="content">
              <div class="topheader">
                <ng-container >
                  <div class="row row-sm topheader">
                    <div class="col-lg-5">
                      <div>
                        <app-checkcountshow></app-checkcountshow>
                      </div>
                    </div>
                    <div class="col-lg-7 text-right">
                      <div class="dflexflex mb-mm justify-content-end align-items-center">
                      <a  [routerLink]="['/add-new-reference']"> <button type="button" class="btn com_btn btn-success"  >Create New Reference</button> </a>
                      </div>
                    </div>
                  </div>




                  <div class="job_list_section visa_request_section">
                      <div class="job_detils_date">
                        <p class="job_profile">No Record Found</p>
                      </div>
                  </div>
                </ng-container>
              </div>
           </div>
        </div>
      </div>
    </div>
 </div>
</div>






  <div class="container-fluid"  *ngIf="!isshow">
    <div class="section_wrapper_refr">
      <div class="row">
        <div class="col-sm-2 pl-0 pr-0">
          <div class="ref_left">
            <div class="reftm">
              <div class="ref_form">
                <input type="text" [(ngModel)]="candidatesname" class="form-control searchcand" placeholder="Search Candidates">
                <a>
                  <i style="cursor: pointer;" (click)="filterbycandidatesname(candidatesname)" class="fa fa-search"
                    aria-hidden="true"></i>
                </a>
              </div>
              <div class="ref_form dflects">
                <div class="filetersesd">
                  <select (change)="onfilterchange($event.target.value)" type="text" class="form-control filter" placeholder="">                    
                    <option value="DESC">Newest</option>
                    <option value="ASC">Oldest</option>
                  </select>
                  <i class="fa fa-angle-down"></i>
                </div>
                <i (click)="mobiletoggle()"  class="fa fa-envelope-o ememme"></i>
                <i (click)="userreftoggle()" class="fa fa-user ememme"></i>
                <img src="../../../../assets/img/filter.png" class="filteresr">
                <!-- <i class="fa fa-filter" aria-hidden="true"></i> -->
              </div>
            </div>

            <div class="ref_userlist refshow" *ngIf="reftoggle" [ngClass]="reftoggle == 'true' ? 'my_class1' : 'my_class2'">
              <ul>
                <li *ngFor="let referencerequest of referencerequests;let i = index"
                  [ngClass]="[i == activereferencerequest ? 'active' : '']">
                  <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
                  <img src="../../../../assets/img/clocks.png" class="clockii">
                  <div class="list"  (click)="referencerequestclick($event,i)">
                    <p class="user">{{referencerequest.candidatename}}</p>
                    <p class="email">{{referencerequest.candidateemail}}</p>
                  </div>
                  <!-- <div class="resend_email"  (click)="resendReferenceEmail($event,i)">
                    <span class="resendtext">Resend Email</span>
                  </div> -->
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-8 pl-0 pr-0" *ngIf="selectedreferencerequest">

          <div class="request_timeline" *ngIf="selectedreferencerequest.declinedbycandidate === 'Yes'">
            <div class="ref_center_box" style="padding-top: 30%;">
              <span class="sub ref_subheading" style="font-size: x-large;">We requested references from the candidate
                 {{selectedreferencerequest.candidatename}} on
                 {{selectedreferencerequest.createdon.split('T')[0]}} but they declined this request with the reason
                 {{selectedreferencerequest.declinedbycandidaterreason}}.</span>
            </div>
          </div>


          <div class="request_timeline" *ngIf="!showRefreeDetails  && selectedreferencerequest.declinedbycandidate != 'Yes'">
            <div class="ref_center_box">
              <h4>Request <span class="sub"> Timeline</span></h4>
              <span class="ref_subheading">Keep track of your requested references</span>
            </div>
            <div class="ref_content_booxoo">
              <ul>
                <li class="inverse">
                  <div class="boxssd">
                    <div class="headsd">
                      <img src="../../../../assets/img/request_white.png" class="refereddivodn">
                      <div class="detsst">
                        <h4>Reference Request Created</h4>
                        <p>{{(selectedreferencerequest.createdon)?selectedreferencerequest.createdon.split('T')[0]:''}}</p>
                      </div>

                    </div>
                    <div *ngIf="usercompanyinfo && selectedreferencerequest">
                      <p class="box_para">

                        {{selectedreferencerequest.candidatename}} has been requested  {{selectedreferencerequest.numberofreferee}} <ng-container *ngIf="selectedreferencerequest.numberofreferee >1"> references </ng-container> <ng-container *ngIf="selectedreferencerequest.numberofreferee ==1"> reference </ng-container> for the position of {{selectedreferencerequest.position}}.


                        <!-- 8 references has been requested for Rahul for the position of Project Manager -->

                        <!-- {{usercompanyinfo.fname}} <ng-container *ngIf="usercompanyinfo.mname">{{ usercompanyinfo.mname }}</ng-container> {{ usercompanyinfo.mname }} requested {{selectedreferencerequest.numberofreferee}} references for
                        <b>{{selectedreferencerequest.candidatename}}</b> for the position of a {{selectedreferencerequest.position}}. -->
                        <!-- Request email sent to {{selectedreferencerequest.candidateemail}} -->
                      </p>
                      <!-- <p class="box_para"><b>{{selectedreferencerequest.candidateemail}}</b></p> -->
                    </div>

                    <div class="compleref">
                      <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                      <p>Complete</p>
                    </div>

                    <div class="ref_footer">
                      <div class="left">

                        <ng-container *ngIf="refereedetail">
                          <ng-container *ngIf="refereedetail.length != selectedreferencerequest.numberofreferee">
                            <a style="cursor: pointer;color: #fff;" class="btn btn-dark btn-rounded transition" (click)="resendReferenceEmail_New()">Resend Email</a>
                          </ng-container>
                        </ng-container>
                       

                        <!-- <span>Actions <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </span>
                        <div class="refdrop">
                          <ul>                            
                            <li><a style="cursor: pointer;" href="#" (click)="resendReferenceEmail($event,selectedreferencerequest)">Send Reminder</a></li>
                          </ul>
                        </div> -->
                      </div>
                    </div>

                  </div>
                </li>
                <li *ngFor="let refereedet of refereedetail;let i = index">
                  <div class="boxssd">
                    <div class="headsd">
                      <i class="fa fa-file-text-o" aria-hidden="true"></i>
                      <div class="detsst">
                        <h4>Referee Added</h4>
                        <p>{{(refereedet.createdon)?refereedet.createdon.split('T')[0]:''}}</p>
                      </div>

                    </div>
                    <p class="box_para">
                      {{refereedet.refereename}} from <b> {{refereedet.refereecompanyname}} </b> was added as a referee by
                      {{selectedreferencerequest.candidatename}}. Email sent to
                    </p>
                    <p class="box_para"><b>{{refereedet.refereeemail}}</b></p>

                    <div class="ref_footer ref_footer_referee">
                      <div *ngIf="refereedet.refereedesignationcheck" class="left">
                       <img src="../../../../assets/img/clocks.png" class="clocks">
                        <p class="ml-2">Complete</p>
                      </div>
                      <div *ngIf="!refereedet.refereedesignationcheck" class="left">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                        <p class="ml-2">In Progress</p>
                      </div>

                      
                        <div class="left" *ngIf="!refereedet.refereedesignationcheck">

                          <a  style="cursor: pointer;color: #fff;" class="btn btn-dark btn-rounded transition" (click)="resendRefereeEmail(refereedet)">Resend Email</a>

                          <!-- <span>Actions <i class="fa fa-angle-down" aria-hidden="true"></i>
                          </span>
                          <div class="refdrop">
                            <ul>
                              <li><a  style="cursor: pointer;" (click)="resendRefereeEmail(refereedet)">Send Reminder</a></li>
                             
                            </ul>
                          </div> -->
                        </div>
                      
                      <!-- <div class="right">
                        <span class="dropboxshow">Delivery Log <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </span>
                      </div> -->
                    </div>
                  </div>
                </li>
                <div *ngFor="let refereedet of refereedetail;let i = index">
                  <li class="inverse" *ngIf="refereedet.refereedesignationcheck">
                    <div class="boxssd">
                      <div class="headsd">
                        <img src="../../../../assets/img/request_white.png" class="refereddivodn">
                        <div class="detsst">
                          <h4>Reference Complete</h4>
                          <p>{{refereedet.createdon.split('T')[0]}}</p>
                        </div>

                      </div>
                      <p class="box_para">
                        {{refereedet.refereename}} from <b>{{refereedet.refereecompanyname}} </b> completed a reference.
                      </p>

                      <div class="ref_footer">
                        <div class="left">
                          <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                          <p>Complete</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>

            </div>
          </div>

          <div class="row bottom-30" *ngIf="showRefreeDetails  && selectedreferencerequest.declinedbycandidate != 'Yes'">
            <div class="col">
              <div widget class="card">
                <div class="card-body widget-body">
                  <div class="row bottom-15 ">
                    <div class="col-md-12">
                      <div class="timeflex mt-2">
                        <a (click)="showRefreeDetails = false" class="back_arrow"><i class="fa fa-angle-left margin-5 mr-2"></i>Back to
                          timeline</a>
                          <div class="bton">
                            <button type="button"
                            class="btn btn-dark btn-rounded transition bottom-30"  [innerHtml]="downloadtext" (click)="downloadReferee(selectedrefereedetail)"><i class="fa fa-download mr-1" aria-hidden="true"></i> Download reference report</button>
                            <!-- <button type="button"
                            class="btn btn-info btn-rounded transition bottom-30 ml-2"><i class="fa fa-user mr-1"></i> Professional reference</button> -->
                          </div>
                      </div>

                      <hr class="mt-2 mb-2"/>
                      <div>
                        <div class="row bottom-15 refredref">

                          <div class="col-md-12 reference ">
                            <h1 class="mt-3">Reference report <span
                                class="subname"><b>{{selectedreferencerequest.candidatename}}</b></span></h1>
                            <p class="candidatejobtitle"><b>Job Title: </b> <span>{{selectedrefereedetail.candidatejobtitle}}</span></p>
                            <div class="d-flex fontset">
                              <p> <b>Start date:</b> <i class="fa fa-calendar mr-1 ml-2" aria-hidden="true"></i> {{selectedrefereedetail.jobstartdate}}</p>
                              <span class="ml-2 mr-2">|</span>
                              <p><b>End date:</b><span> <i class="fa fa-calendar mr-1 ml-2" aria-hidden="true"></i> {{selectedrefereedetail.jobenddate}}</span></p>
                            </div>
                          </div>
                          <!-- <div class="col-md-6 ">
                            <div class="d-inline-block float-right">

                              <div class="row download mt-2 mb-3">
                                <a style="cursor: pointer;" (click)="SavePDFsinglereferee(selectedrefereedetail)">  <img src="../../../../assets/img/downlaods.png" class="downloadss mr-2">Download reference report</a>
                              </div>
                            </div>
                          </div> -->
                        </div>
                        <div class="row tabs-section" style="background-color: rgb(249, 249, 251);">
                          <div class="width100 bottom-30" style="background-color: rgb(249, 249, 251);">
                            <div class="clearfix">
                              <ul class="nav nav-tabs top  w-100p">
                                <li class="nav-item">
                                  <a class="nav-link active" data-toggle="tab" href="#details">Details</a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link" data-toggle="tab" href="#questions">Questions</a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link" data-toggle="tab" href="#comments">Comments</a>
                                </li>
                                <!-- <li class="nav-item">
                                  <a class="nav-link" data-toggle="tab" href="#verification">Verification</a>
                                </li> -->
                                <li class="nav-item">
                                  <a class="nav-link" data-toggle="tab" href="#fraud-detection">Fraud Detection</a>
                                </li>
                              </ul>
                            </div>
                            <div class="tab-content pb-4 top reftab">
                              <div class="tab-pane active" id="details">
                                <h3 class="mt-3 mb-0">Referee Details</h3>
                                <p class="details">Details submitted on {{selectedrefereedetail.createdon.split('T')[0]}}</p>
                                <div class="mt-3 details-section">
                                  <img class="rounded-circle img-fluid" src="../../../../../assets/img/user_pic_emp.png"
                                  alt="Rounded circle image">

                                  <div class="boxsdeff">
                                      <div class="bososos">

                                        <div class="candidate-details refflex">
                                          <div class="usernameref">
                                            <h4 class="mt-1 mb-0"*ngIf="selectedrefereedetail.refereename">{{selectedrefereedetail.refereename}}</h4>
                                            <p class="refheading" *ngIf="selectedrefereedetail.refereedesignation">{{selectedrefereedetail.refereedesignation}}</p>
                                            <p class="refheading" *ngIf="!selectedrefereedetail.refereedesignation">N/A</p>
                                          </div>
                                          <div class="mb-3">
                                            <p class="refheading">Email</p>
                                            <p class="refdetails">{{selectedrefereedetail.refereeemail}}</p>
                                         </div>
                                          <div class="mb-3">
                                            <p class="refheading"> Phone</p>
                                            <p class="refdetails">{{selectedrefereedetail.refereephone}}</p>
                                          </div>
                                        </div>

                                      </div>
                                      <div class="alsihbox candidate-details refflex mt-3">
                                        <div class="mb-3">
                                          <p class="refheading"> Relationship to candidate</p>
                                          <p class="refdetails" *ngIf="selectedrefereedetail.refereedesignation">{{selectedrefereedetail.refereedesignation}}</p>
                                          <p class="refdetails" *ngIf="!selectedrefereedetail.refereedesignation">N/A</p>
                                        </div>
                                        <div class="mb-3">
                                          <p class="refheading">Company Name</p>
                                          <p class="refdetails">{{selectedrefereedetail.refereecompanyname}}</p>
                                        </div>
                                        <div class="mb-3">
                                          <p class="refheading"> Known candidate for</p>
                                          <p class="refdetails">{{getdatediff(selectedrefereedetail.jobstartdate,selectedrefereedetail.jobenddate)}}</p>
                                        </div>

                                      </div>
                                  </div>
                                </div>
                              </div>
                              <div class="tab-pane" id="questions">
                                <h3 class="mt-3 mb-0">Questions</h3>
                                <p class="muted">These are the questions you asked the referee about the candidate.</p>


                                <div class="row">

                                  <ng-container *ngFor="let refereeresponse of refereeresponse[''+selectedrefereedetail.id+selectedrefereedetail.referencerequestid];let i = index">

                                  <div class="col-md-12 bottom-15 mb-3" *ngIf="refereeresponse.question != 'Remark'">
                                    <p><b> Q. {{refereeresponse.question}}</b></p>
                                    <p>Ans. {{refereeresponse.response}}</p>

                                  </div>
                                </ng-container>

                                </div>


                                <!-- <div class="question-div" style="height: auto;">
                                  <ul style="height: auto;">
                                    <li  *ngFor="let refereeresponse of refereeresponse[''+selectedrefereedetail.id+selectedrefereedetail.referencerequestid];let i = index"
                                      class="mb-3">
                                      <div *ngIf="refereeresponse.question != 'Remark'">
                                        <div>
                                          <p><img src="../../../../assets/img/clocks.png" class="clocks">{{refereeresponse.question}}</p>
                                        </div>
                                        <div class="question-ans">
                                          <p>{{refereeresponse.response}}</p>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div> -->

                              </div>
                              <div class="tab-pane" id="comments">
                                <h3 class="mt-3 mb-0">Comments</h3>
                                <p class="muted">
                                  Any additional comments left by the referee about a candidate will appear here. These
                                  are
                                  separate from the questions asked in a question profile and can provide extra
                                  information
                                  about a candidate.
                                </p>
                                <div class="row">
                                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 bottom-30 typography">
                                    <div
                                      *ngFor="let refereeresponse of refereeresponse[''+selectedrefereedetail.id+selectedrefereedetail.referencerequestid];let i = index"
                                      class="">
                                      <div *ngIf="refereeresponse.question == 'Remark'">
                                        <blockquote class="blockquote">
                                          <p class="mb-0"> <i class="fa fa-quote-left"></i>
                                            {{refereeresponse.response}}
                                            <i class="fa fa-quote-right"></i>
                                          </p>
                                        </blockquote>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="tab-pane" id="verification">
                                <h3 class="mt-3 mb-0">Verification</h3>
                                <p class="muted">
                                  We use Yoti and LinkedIn to verify our referees. If a referee has used one of these
                                  verification methods, they will appear here. Toggle between the two options.
                                </p>
                                <div class="verification">
                                  <div class="row">
                                    <!-- [ngClass]="{'active': selectedItem === i}" -->
                                    <div class="col-md-2 verify-method">
                                      <div role="button" class="button " [ngClass]="{'active': showLinkedIn === true}"
                                        (click)="showLinkedIn = true">
                                        <p>Verified by </p>
                                        <img class="refwidth" src="../../../../../assets/img/user_pic_emp.png" />
                                      </div>
                                      <div role="button" class="button" [ngClass]="{'active': showLinkedIn === false}"
                                        (click)="showLinkedIn = false">
                                        <p>Verified by </p>
                                        <img class="refwidth" src="../../../../../assets/img/user_pic_emp.png" />
                                      </div>
                                    </div>
                                    <div class="col-md-8 mt-5">
                                      <div *ngIf="showLinkedIn">
                                        <p class="muted mt-3">Referee has not verified with LinkedIn</p>
                                      </div>
                                      <div *ngIf="!showLinkedIn">
                                        <p class="muted mt-3">Referee has not verified with Yoti</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="tab-pane" id="fraud-detection">
                                <h3 class="mt-3 mb-0">Fraud Detection</h3>
                                <p class="fontre14">We use enhanced methods to detect the validity of a reference, however,
                                  these
                                  are warnings and should be reviewed accordingly.</p>
                                <p class="fontre14 mb-1">
                                  <b>IP detection</b> can detect the location of a referee, upon reference completion.
                                </p>
                                <p class="fontre14 mb-1">
                                  <b>Browser session detection</b> can detect same browser of a referee, upon reference
                                  completion.
                                </p>
                                <p class="fontre14 mb-1">
                                  <b>Details amended</b> can detect any discrepancies between the details the candidate
                                  provided
                                  and
                                  the details a referee provided.
                                </p>
                                <div class="mt-3 details-section">
                                  <img src="../../../../../assets/img/user_pic_emp.png" class="img-fluid refwidth"
                                  alt="fraud detection" />
                                  <div class="boxsdeff">
                                      <div class="bososos norefborder">
                                          <div class="candidate-details refflex">
                                            <div class="mb-3">
                                              <p class="refheading"> IP fraud check</p>
                                              <p class="refdetails" *ngIf="selectedrefereedetail.candidateip != selectedrefereedetail.refereeip"
                                                class="refdetails">No fraud detected</p>
                                              <p class="refdetails" *ngIf="selectedrefereedetail.candidateip == selectedrefereedetail.refereeip"
                                                class="refdetails">Fraud detected</p>
                                            </div>
                                            <div class="mb-3">
                                              <p class="refheading"> Browser fraud check</p>
                                              <p class="refdetails" *ngIf="selectedrefereedetail.candidatemac != selectedrefereedetail.refereemac"
                                                class="refdetails">No fraud detected</p>
                                              <p class="refdetails" *ngIf="selectedrefereedetail.candidatemac == selectedrefereedetail.refereemac"
                                                class="refdetails">Fraud detected</p>
                                            </div>
                                            <div class="mb-3">
                                              <p class="refheading">Details amended</p>
                                              <p class="refdetails" *ngIf="selectedrefereedetail.refereedesignation != selectedrefereedetail.refereedesignationcheck
                                              || selectedrefereedetail.refereephone != selectedrefereedetail.refereephonecheck
                                              || selectedrefereedetail.candidatejobtitle != selectedrefereedetail.candidatedesignationcheck"
                                                class="refdetails">Details were changed by the referee.</p>

                                              <p class="refdetails" *ngIf="selectedrefereedetail.refereedesignation == selectedrefereedetail.refereedesignationcheck
                                              && selectedrefereedetail.refereephone == selectedrefereedetail.refereephonecheck
                                              && selectedrefereedetail.candidatejobtitle == selectedrefereedetail.candidatedesignationcheck"
                                                class="refdetails">No details were changed by the referee.</p>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-2 pr-0 pl-0 mobileshowref" *ngIf="refusertoggle" [ngClass]="refusertoggle == 'true' ? 'my_class1' : 'my_class2'">
          <div class="ref_right">
            <div class="ref_right_user">
              <a [routerLink]="['/reference-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to  Reference Check</a>
              <div class="clearfix"></div>

              <div class="user_pic">
                {{candidateinitials}}
              </div>
              <p class="name_can">
                {{candidatename}}
              </p>
              <p class="email">{{candidateemail}}</p>
              <p class="phone">{{candidatephone}}</p>
              <div class="ref_option_box">
                <!-- <span>
                  <img src="../../../../assets/img/delete.png" data-toggle="modal"
                  data-target="#deletereference-warning-modal" class="delete">

                </span> -->
               


                  <button type="button"
                  class="btn btn-dark btn-rounded transition bottom-30"  [innerHtml]="downloadtext2" (click)="downloadAllReferee(selectedreferencerequest.id)"><i class="fa fa-download mr-1" aria-hidden="true"></i> Download Full report</button>
                 

                  <!-- <a (click)="downloadAllReferee(selectedreferencerequest.id)"><img src="../../../../assets/img/pdfii.png" class="filess"></a> -->

              </div>
              <p class="ref_reference">References</p>
            </div>
            <div class="ref_userlist">
              <ul *ngIf="refereedetail">
                <li style="color:black;" *ngFor="let refereedet of refereedetail;let i = index"
                  [ngClass]="[i == activereferee ? 'active' : '']" (click)="showDetails($event,i,refereedet)">
                  <div class="list">
                    <p class="user">{{refereedet.refereecompanyname}}</p>
                    <p class="email">{{refereedet.candidatejobtitle}}</p>
                    <p class="user" *ngIf="!refereedet.refereecompanyname">N/A</p>
                    <p class="email" *ngIf="!refereedet.candidatejobtitle">N/A</p>
                  </div>
                  <!-- <div class="boxsksks">
                    <a (click)="downloadReferee(refereedet)" ><img *ngIf="refereedet.refereedesignationcheck" src="../../../../assets/img/downlaods.png" class="downloadss"></a>
                    <a (click)="downloadReferee(refereedet)" ><img *ngIf="!refereedet.refereedesignationcheck" src="../../../../assets/img/pdfii.png" class="downloadss"></a>
                  </div> -->

                  <!-- <div class="resend_email start-justify-content">
                    <span  class="resendtext" (click)="resendRefereeEmail(refereedet)">Resend Email</span>
                  </div> -->

                  


                </li>
              </ul>
              <div *ngIf="isEmptyObject(refereedetail)" class="list">
                <p Style="margin-left: 20px;" class="user"> References will appear here once the candidate has added their
                  referees</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

<!--
<div class="container-fluid">
  <div class="section_wrapper_refr">
    <div class="row">
      <div class="col-sm-2 pl-0 pr-0">
        <div class="ref_left">
          <div class="reftm">
            <div class="ref_form">
              <input type="text" class="form-control searchcand" placeholder="Search Candidates">
              <a>
                <i style="cursor: pointer;" class="fa fa-search"
                  aria-hidden="true"></i>
              </a>
            </div>
            <div class="ref_form dflects">
              <div class="filetersesd">
                <select  type="text" class="form-control filter" placeholder="">
                  <option value="ASC">Oldest</option>
                  <option value="DESC">Newest</option>
                </select>
                <i class="fa fa-angle-down"></i>
              </div>

              <img src="../../../../../assets/img/filter.png" class="filteresr">

            </div>
          </div>

          <div style="height: 79%; overflow-y: scroll" class="ref_userlist">
            <ul>
              <li >

                <img src="../../../../../assets/img/clocks.png" class="clockii">
                <div class="list">
                  <p class="user">utvrt</p>
                  <p class="email">AASDF@gmAOI.COM</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-sm-8 pl-0 pr-0">




        <div class="request_timeline">
          <div class="ref_center_box">
            <h4>Request <span class="sub"> Timeline</span></h4>
            <span class="ref_subheading">Keep track of your requested references</span>
          </div>
          <div class="ref_content_booxoo">
            <ul>
              <li class="inverse">
                <div class="boxssd">
                  <div class="headsd">
                    <img src="../../../../../assets/img/request_white.png" class="refereddivodn">
                    <div class="detsst">
                      <h4>Reference Request Created</h4>
                      <p>Yesterday at 14:42</p>
                    </div>

                  </div>
                  <div >
                    <p class="box_para">
                     rtreyty requested @##GRGG references for
                      <b>REWDFF</b>.
                      Request email sent to
                    </p>
                    <p class="box_para"><b>deff@gg.com</b></p>
                  </div>
                  <div class="ref_footer">
                    <div class="left">
                      <span>Actions <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                      <div class="refdrop">
                        <ul>
                          <li><a href="#">Add Referee</a></li>
                          <li><a href="#">Set Reminder</a></li>
                        </ul>
                      </div>
                    </div>
                    <div class="right">
                      <span class="dropboxshow">Delivery Log <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                      <div class="refdrop">
                        <div class="tab">
                          <div class="box active" (click)="toggle('1')" [class.active]="tab1">
                            Email
                          </div>
                          <div class="box" (click)="toggle('2')" [class.active]="tab2">
                            Text
                          </div>
                          <div class="box" (click)="toggle('3')" [class.active]="tab3">
                            Phone
                          </div>

                        </div>
                        <div class="content_box">
                          <div class="content active" [class.active]="tab1">
                            <div class="flexbox_dd">
                              <div class="codnd">
                                <p>Attempts</p>
                                <span class="num">1</span>
                              </div>
                              <div class="codnd">
                                <p>Date</p>
                                <span>Yesterday at 18:53</span>
                              </div>
                              <div class="codnd">
                                <p>Delivered</p>
                                <span><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></span>
                              </div>
                            </div>
                          </div>
                          <div class="content" [class.active]="tab2">
                            <div class="flexbox_dd">
                              <div class="codnd">
                                <p>Attempts</p>

                              </div>
                              <div class="codnd">
                                <p>Date</p>

                              </div>
                              <div class="codnd">
                                <p>Delivered</p>

                              </div>

                            </div>
                            <div class="norecord">
                              <p>No reminders have been sent yet</p>
                            </div>
                          </div>
                          <div class="content" [class.active]="tab3">
                            <div class="flexbox_dd">
                              <div class="codnd">
                                <p>Attempts</p>
                                <span class="num">13</span>
                              </div>
                              <div class="codnd">
                                <p>Date</p>
                                <span>Yesterday at 18:53</span>
                              </div>
                              <div class="codnd">
                                <p>Delivered</p>
                                <span><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="compleref">
                    <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    <p>Complete</p>
                  </div>

                </div>
              </li>
              <li>
                <div class="boxssd">
                  <div class="headsd">
                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                    <div class="detsst">
                      <h4>Referee Added</h4>
                      <p>Yesterday at 14:42</p>
                    </div>

                  </div>
                  <p class="box_para">
                    fggfdg from <b> Cgsrer </b> was added as a referee by
                    Abhi. Email sent to
                  </p>
                  <p class="box_para"><b>DRES@gmail.com</b></p>

                  <div class="ref_footer">
                    <div  class="left">
                      <i class="fa fa-clock-o" aria-hidden="true"></i>
                      <p>In Progress</p>
                    </div>
                    <div class="right">
                      <span class="dropboxshow">Delivery Log <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </li>
              <div >
                <li class="inverse" >
                  <div class="boxssd">
                    <div class="headsd">
                      <img src="../../../../../assets/img/request_white.png" class="refereddivodn">
                      <div class="detsst">
                        <h4>Reference Complete</h4>
                        <p>Yesterday at 14:42</p>
                      </div>

                    </div>
                    <p class="box_para">
                      Abhi from <b> Chainthat </b> completed a reference.
                    </p>

                    <div class="ref_footer">
                      <div class="left">
                        <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                        <p>Complete</p>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </ul>

          </div>
        </div>



      </div>
      <div class="col-sm-2 pr-0 pl-0">
        <div class="ref_right">
          <div class="ref_right_user">
            <div class="user_pic">

            </div>
            <p class="name_can">
             User Name
            </p>
            <p class="email">XDEEE@gmail.com</p>
            <p class="phone">7895445222</p>
            <div class="ref_option_box">
              <span>
                <img src="../../../../../assets/img/delete.png" data-toggle="modal"
                data-target="#deletereference-warning-modal" class="delete">

              </span>
              <span>
                <img src="../../../../../assets/img/pdfii.png" class="filess">

              </span>
            </div>
            <p class="ref_reference">References</p>
          </div>
          <div class="ref_userlist">
            <ul>
              <li style="color:black;">
                <div class="list">
                  <p class="user">User Name</p>
                  <p class="email">user@gmail.com</p>
                </div>
                <div class="boxsksks">

                  <img src="../../../../../assets/img/pdfii.png" class="downloadss">
                </div>


              </li>
            </ul>
            <div class="list">
              <p Style="margin-left: 20px;" class="user"> References will appear here once the candidate has added their
                referees</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

</div>
