import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-passportthanksai',
  templateUrl: './passportthanksai.component.html',
  styleUrls: ['./passportthanksai.component.css']
})

export class PassportthanksaiComponent implements OnInit {
  ctype:number;

  constructor() { }

  ngOnInit() {

    this.ctype  = parseInt(localStorage.getItem('ctype'));
  }
}