<div class="bg">
    <app-header></app-header>
    <!-- <div class="card-activities" style="display: none;">
        <div class="media-list">
            <div class="activity-icon">
                <a style="color:#164881; cursor: pointer;" (click)="yearpop('year-select')"><i class="fa fa-calendar"
            aria-hidden="true"></i></a>
                <jw-modal id="year-select" class="modal yearselect" role="dialog">
                    <div role="document" class="formgroup">
                        <div class="modal-content">
                            <div class="modal-body" style="padding:5px;">
                                <form #yearForm="ngForm" class="s12 white" (ngSubmit)="onyearSubmit(yearForm.value,'year-select')">
                                    <div class="">
                                        <div class="row">
                                            <div class="col-lg-12 poplist m-b-10">
                                                <select name="year" id="year" ngModel='{{ year }}'>
                          <option>2017</option>
                          <option>2018</option>
                          <option>2019</option>
                          <option>2020</option>
                        </select>
                                            </div>
                                        </div>
                                        <div class="row row-sm float-right">
                                            <button type="submit" class="btn btn-outline-secondary" style="margin-top: 5px;"><i class="fa fa-check"></i></button>
                                            <button type="button" class="btn btn-outline-secondary" id="" (click)="closeModal('year-select');" style="margin-right:15px;margin-top: 5px;"><i class="fa fa-close"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </jw-modal>
            </div>
            
        </div>
    </div> -->

    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__content">
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row space_em ">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Timesheet Request <span class="gray_text">({{yearmonth}})</span> </h5>
                                </div>
                                <div class="col-lg-6">
                                    <div class="input-group">
                                        <select name="year" id="year" class="form-control input-lg spaceright"
                                        (change)="onyearSubmit($event.target.value)" ngModel='{{ year }}'>
                                            <option value="{{y}}" *ngFor="let y of yearList">{{ y }}</option>
                                        </select>
                                        <select name="hstatus" class="form-control input-lg spaceright " id="hstatus" (change)="onChangestatus($event.target.value)">
                                    <option value=""  selected>Select Status</option>
                                    <option value="1">Pending</option>
                                    <option value="2">Approved</option>
                                    <option value="3">Rejected</option>
                                  </select>
                                        <select name="employeeid" class="form-control input-lg" id="employeeid" (change)="onChange($event.target.value)">
                                    <option value="">Select Employee</option>
                                    <option *ngFor="let ad3 of employeelist" value="{{ ad3.employeemasterid}}">{{ ad3.fname }} {{ ad3.mname }} {{ ad3.lname }}</option>
                                  </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <ul class="tabs__head montharea">
                                        <li><a [ngClass]="{'active': classactive == '1'}" (click)="getDataByMonth(1);">January </a></li>
                                        <li><a [ngClass]="{'active': classactive == '2'}" (click)="getDataByMonth(2);">February </a></li>
                                        <li><a [ngClass]="{'active': classactive == '3'}" (click)="getDataByMonth(3);">March </a></li>
                                        <li><a [ngClass]="{'active': classactive == '4'}" (click)="getDataByMonth(4);">April </a></li>
                                        <li><a [ngClass]="{'active': classactive == '5'}" (click)="getDataByMonth(5);">May</a></li>
                                        <li><a [ngClass]="{'active': classactive == '6'}" (click)="getDataByMonth(6);">June </a></li>
                                        <li><a [ngClass]="{'active': classactive == '7'}" (click)="getDataByMonth(7);">July </a></li>
                                        <li><a [ngClass]="{'active': classactive == '8'}" (click)="getDataByMonth(8);">August </a></li>
                                        <li><a [ngClass]="{'active': classactive == '9'}" (click)="getDataByMonth(9);">September </a></li>
                                        <li><a [ngClass]="{'active': classactive == '10'}" (click)="getDataByMonth(10);">October </a></li>
                                        <li><a [ngClass]="{'active': classactive == '11'}" (click)="getDataByMonth(11);">November </a></li>
                                        <li><a [ngClass]="{'active': classactive == '12'}" (click)="getDataByMonth(12);">December </a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="job_list_section employee_list_section leave_section" *ngFor="let activity of timesheetdata;  let i=index">
                                <div class="job_detils_date width20">
                                    <h5 class="job_profile font_17">{{activity.employee.fname}} {{activity.employee.mname}} {{activity.employee.lname}}</h5>
                                    <p class="email"><i class="fa fa-envelope-o"></i>{{ activity.employee.emailid }}</p>
                                    <p class="phone"> <i class="fa fa-phone"></i> {{ activity.employee.phonenumber }}</p>
                                    <p class="ninumber"> <i class="fa fa-bars"></i> NI Number {{ activity.employee.ninumber }}</p>
                                    <span class="badge badge-success" *ngIf="activity.employee.nationality">{{ activity.employee.nationality.countryname }}</span>
                                </div>
                                <div class="job_detils_date width7">
                                    <p class="job_address">Hours</p>
                                    <span class="badge badge-pill badge-danger">{{activity.ahour}} H </span>
                                </div>

                                <div class="job_detils_date width13">
                                    <p class="job_address">Hours Type</p>
                                    <h5 class="job_profile font_17">{{activity.hourtype.typename}}</h5>
                                </div>
                                <div class="job_detils_date width22">
                                    <p class="job_address">Project</p>
                                    <h5 class="job_profile font_17"> {{activity.project.projectname}}</h5>

                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address">Date</p>
                                    <h5 class="job_profile font_17"> {{activity.startdate | date: 'dd MMM yyyy'}}</h5>
                                </div>

                                <div class="job_btn visa_btn width7">
                                    <span style="color:red" *ngIf="activity.hstatus == '3'">Rejected</span>
                                    <span style="color:green" *ngIf="activity.hstatus == '2'">Approved</span>
                                    <div class="dropdown" *ngIf="activity.hstatus == '1'">
                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Action <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" (click)="onStatus(activity.employeehourapplyid, 2);">Approve</a>
                                            <a class="dropdown-item" (click)="onStatus(activity.employeehourapplyid, 3);">Reject</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="job_list_section visa_request_section" *ngIf="record == 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>