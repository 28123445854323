"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var employee_service_1 = require("../../../services/employee.service");
var customer_service_1 = require("../../../services/customer.service");
var modal_service_1 = require("../../../_services/modal.service");
var permanentaddress_model_1 = require("../../../models/overview/permanentaddress.model");
var router_1 = require("@angular/router");
var profile_model_1 = require("../../../models/overview/profile.model");
var alert_service_1 = require("../../../services/alert.service");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var country_service_1 = require("../../../services/country.service");
var forms_1 = require("@angular/forms");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var common_service_1 = require("../../../services/common.service");
var CheckemployeedetailsComponent = /** @class */ (function () {
    function CheckemployeedetailsComponent(formBuilder, activatedRoute, uploadService, data, country, employeeService, modalService, commonService, alerts) {
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.uploadService = uploadService;
        this.data = data;
        this.country = country;
        this.employeeService = employeeService;
        this.modalService = modalService;
        this.commonService = commonService;
        this.alerts = alerts;
        this.navbarOpen = false;
        this.clicked = false;
        this.sectionHeights = [];
        this.tab = 1;
        this.empltype = false;
        this.options = [];
        this.mindob = new Date();
        this.maxdob = new Date();
        this.defaultVal = "";
    }
    CheckemployeedetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.terminationDetail = new profile_model_1.Termination();
        this.empid = this.activatedRoute.snapshot.paramMap.get("id");
        this.Userid = localStorage.getItem('userid');
        this.mindob.setDate(this.mindob.getDate() - 23741);
        this.maxdob.setDate(this.maxdob.getDate() - 574);
        this.OverForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            //  employeeid: ['', Validators.required],
            fname: ['', forms_1.Validators.required],
            mname: [''],
            lname: ['', forms_1.Validators.required],
            gender: ['', forms_1.Validators.required],
            hiredate: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            placeofbirth: ['', forms_1.Validators.required],
            maritalstatus: ['', forms_1.Validators.required],
            ninumber: ['', forms_1.Validators.required],
            emprole: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            residenceid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            countryofbirthid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            dateofbirth: ['', forms_1.Validators.required],
        });
        this.terminationForm = this.formBuilder.group({
            employeeid: ['', forms_1.Validators.required],
            terminationdate: ['', forms_1.Validators.required],
            terminationreason: ['', forms_1.Validators.required],
            oktorehire: ['', forms_1.Validators.required],
            regrettermination: ['', forms_1.Validators.required]
        });
        this.permanetForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            userid: [''],
            addressid1: ['', forms_1.Validators.required],
            addressid2: [''],
            cityname: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            mobilenumber: [''],
            pincode: ['', forms_1.Validators.required],
            statename: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            phonenumber: [''],
            countryid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])]
        });
        this.overviewData = new profile_model_1.Profile();
        this.paddress = new permanentaddress_model_1.Permanentaddress();
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.data.getOverviewbyadmin({ 'id': this.empid }).subscribe(function (data) {
            _this.empltype = data.emptype;
            _this.overviewData = data;
        });
        this.data.getPaddressbyadmin({ 'id': this.empid }).subscribe(function (data) {
            if (data.addressid1 == null) {
                _this.buttoninsert = "Submit";
            }
            else {
                _this.buttoninsert = "Update";
            }
            _this.paddress = data;
        });
        // this.Userid = localStorage.getItem('userid');    
        // const data3 = { 'userid': this.Userid };
        // this.data.getOtherInfo(data3).subscribe((data: any) => {
        //   this.employement = data;
        // });
        var data5 = { 'userid': this.Userid, 'employeeid': this.empid };
        this.data.getOtherInfobyadmin(data5).subscribe(function (data) {
            _this.employement = data;
        });
        var data = { 'userid': this.empid };
        this.data.getOtherInfo(data).subscribe(function (data) {
            _this.otherDetail = data;
        });
        this.data.getEmploymentbyadmin({ 'id': this.empid }).subscribe(function (data) {
            _this.employement = data;
        });
        this.data.getVisabyadmin({ 'id': this.empid }).subscribe(function (data) {
            _this.visalist = data;
        });
    };
    Object.defineProperty(CheckemployeedetailsComponent.prototype, "g", {
        get: function () { return this.terminationForm.controls; },
        enumerable: true,
        configurable: true
    });
    CheckemployeedetailsComponent.prototype.openModelTerminateactive = function (empid, terminateid, id) {
        var _this = this;
        this.terminationDetail = new profile_model_1.Termination();
        this.submitted = false;
        var dd = { termid: terminateid };
        this.employeeService.employeeViewTermination(dd).subscribe(function (data) {
            _this.terminationDetail.employeeid = empid;
            if (data.oktorehire == true)
                _this.terminationDetail.oktorehire = "1";
            else
                _this.terminationDetail.oktorehire = "0";
            if (data.oktorehire == true)
                _this.terminationDetail.regrettermination = "1";
            else
                _this.terminationDetail.regrettermination = "0";
            _this.terminationDetail.terminationdate = data.terminationdate;
            _this.terminationDetail.terminationreason = data.terminationreason;
            _this.modalService.open(id);
        });
    };
    CheckemployeedetailsComponent.prototype.openModelTerminate = function (empid, id) {
        this.terminationDetail = new profile_model_1.Termination();
        this.submitted = false;
        this.terminationDetail.employeeid = empid;
        this.terminationDetail.oktorehire = "";
        this.terminationDetail.regrettermination = "";
        this.modalService.open(id);
    };
    CheckemployeedetailsComponent.prototype.onSelectCountry = function (country_id) {
        var _this = this;
        var data = { 'id': country_id };
        this.country.getstatelist(data).subscribe(function (state) {
            return _this.statelist = state;
        });
    };
    CheckemployeedetailsComponent.prototype.onSearchChange = function (searchValue) {
        var _this = this;
        if (searchValue.length > 4) {
            var newArray = [];
            this.commonService.getAddress(searchValue).subscribe(function (data) {
                var newdata;
                newdata = data;
                newdata.addresses.forEach(function (value) {
                    newArray.push(value + "," + searchValue);
                });
                _this.options = newArray;
            });
        }
        else {
            this.options = [];
        }
    };
    CheckemployeedetailsComponent.prototype.displayFn = function (value) {
        if (value != null) {
            var res = value.split(",");
            if (res.length > 6) {
                return res[7];
            }
            else {
                return value;
            }
        }
    };
    CheckemployeedetailsComponent.prototype.updatecode = function (evt, response) {
        res = [];
        if (evt.source.selected) {
            var res = response.split(",");
            this.paddress.addressid1 = res[0];
            this.paddress.addressid2 = res[1];
            this.paddress.cityname = res[5];
            this.paddress.pincode = res[7];
            this.paddress.statename = res[6];
        }
    };
    CheckemployeedetailsComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    CheckemployeedetailsComponent.prototype.openModal = function (id, data) {
        this.submitted = false;
        this.disableBtn = true;
        this.overviewData = new profile_model_1.Profile();
        if (data.nationalityid == null) {
            data.nationalityid = "";
        }
        if (data.residenceid == null) {
            data.residenceid = "";
        }
        if (data.countryofbirthid == null) {
            data.countryofbirthid = "";
        }
        // console.log(data);
        this.overviewData = data;
        this.modalService.open(id);
    };
    CheckemployeedetailsComponent.prototype.openModal2 = function (id, data) {
        this.submitted = false;
        this.disableBtn = true;
        this.paddress = new permanentaddress_model_1.Permanentaddress();
        this.buttoninsert = "Update";
        this.paddress = data;
        // this.paddress.employeeid = this.empid;
        // console.log(this.paddress.countryid);
        if ((this.paddress.countryid == '') || (this.paddress.countryid == null)) {
            this.paddress.countryid = '';
        }
        this.mobilecheck = "";
        this.phonecheck = "";
        this.paddress.userid = this.Userid;
        this.modalService.open(id);
    };
    CheckemployeedetailsComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        this.fileformaterror = false;
        this.filesizeerror = false;
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
            return;
        }
        else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") && (filetype[filetype.length - 1].toLowerCase() != "gif") && (filetype[filetype.length - 1].toLowerCase() != "tif") && (filetype[filetype.length - 1].toLowerCase() != "pdf") && (filetype[filetype.length - 1].toLowerCase() != "doc") && (filetype[filetype.length - 1].toLowerCase() != "docx") && (filetype[filetype.length - 1].toLowerCase() != "msword")) {
            this.fileformaterror = true;
            // console.log(filetype[filetype.length - 1]);
            return;
        }
        var file = this.selectedFiles;
        var imagepath = this.uploadService.uploadfile(file);
        var forupdate = { 'imagename': imagepath, 'id': this.empid };
        this.data.updateemployeeimage(forupdate).subscribe(function (data) {
            setTimeout(function () { return _this.overviewData.imagename = imagepath; }, 2000);
        });
        this.alerts.success('Profile picture updated successfully.', true);
    };
    Object.defineProperty(CheckemployeedetailsComponent.prototype, "f", {
        get: function () { return this.OverForm.controls; },
        enumerable: true,
        configurable: true
    });
    CheckemployeedetailsComponent.prototype.onSubmit = function (value3, id) {
        var _this = this;
        this.submitted = true;
        if (this.OverForm.invalid) {
            return;
        }
        this.disableBtn = false;
        this.data.updateemployeedetalsbyadmin(value3).subscribe(function (data) {
            _this.data.getOverviewbyadmin({ 'id': _this.empid }).subscribe(function (data) {
                _this.overviewData = data;
            });
        });
        this.alerts.success('Profile updated successfully.', true);
        this.modalService.close(id);
    };
    Object.defineProperty(CheckemployeedetailsComponent.prototype, "h", {
        get: function () { return this.permanetForm.controls; },
        enumerable: true,
        configurable: true
    });
    CheckemployeedetailsComponent.prototype.onSubmit2 = function (value3, id) {
        var _this = this;
        this.submitted = true;
        //console.log(this.permanetForm);
        if (this.permanetForm.invalid) {
            return;
        }
        var pinc = value3.pincode.split(',');
        value3.pincode = pinc[pinc.length - 1];
        value3.userid = this.Userid;
        this.data.permanentadddetalsbyadmin(value3).subscribe(function (data) {
            _this.data.getPaddressbyadmin({ 'id': _this.empid }).subscribe(function (data) {
                _this.paddress = data;
            });
        });
        this.alerts.success('Permanent Address updated successfully.', true);
        this.modalService.close(id);
    };
    CheckemployeedetailsComponent.prototype.closeModalOverview = function (id) {
        var _this = this;
        this.overviewData = new profile_model_1.Profile();
        // this.paddress = new Permanentaddress();
        // this.country.getcountrylist().subscribe((country: any) => {
        //   this.countrylist = country;
        // });
        this.data.getOverviewbyadmin({ 'id': this.empid }).subscribe(function (data) {
            if (data.addressid1 == null) {
                _this.buttoninsert = "Submit";
            }
            else {
                _this.buttoninsert = "Update";
            }
            _this.overviewData = data;
        });
        // this.data.getPaddress().subscribe((data: any) => {
        //   this.paddress = data;
        // });
        this.modalService.close(id);
    };
    CheckemployeedetailsComponent.prototype.closeModalTerminate = function (id) {
        var _this = this;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.modalService.close(id);
    };
    CheckemployeedetailsComponent.prototype.closeModalPaddress = function (id) {
        this.modalService.close(id);
    };
    CheckemployeedetailsComponent.prototype.onSubmitTermination = function (terminationform, modelid) {
        var _this = this;
        this.submitted = true;
        if (this.terminationForm.invalid) {
            return;
        }
        this.employeeService.employeeSubmitTermination(terminationform).subscribe(function (data) {
            _this.alerts.success('Employee has been terminated successfully');
            _this.modalService.close(modelid);
        });
    };
    return CheckemployeedetailsComponent;
}());
exports.CheckemployeedetailsComponent = CheckemployeedetailsComponent;
