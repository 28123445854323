import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employeefeedback',
  templateUrl: './employeefeedback.component.html',
  styleUrls: ['./employeefeedback.component.css']
})
export class EmployeefeedbackComponent implements OnInit {
  record:any;
  constructor() { }

  ngOnInit() {
  }

}
