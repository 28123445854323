<div *ngIf="otherDetail.reportingperson">
  <div *ngIf="otherDetail.reportingperson.fname != ''">
    <h3 class="slim-card-title">Employment</h3>
    <div class="billed-to">
      <h6 class="tx-gray-800" *ngIf="otherDetail.reportingperson">Reporting Manager:
        {{otherDetail.reportingperson.fname}} {{otherDetail.reportingperson.mname}}
        {{otherDetail.reportingperson.lname}}</h6>
      <p *ngIf="otherDetail.location"><b>Company Location:</b> {{otherDetail.location.cityname }}</p>
      <p><b>Division:</b> {{otherDetail.division.typename }}</p>
      <p><b>Department:</b> {{otherDetail.department.typename }}</p>
      <p *ngIf="otherDetail.client"><b>Client:</b> {{otherDetail.client.clientname }}</p>
      <p *ngIf="otherDetail.project"><b>Project:</b> {{otherDetail.project.projectname }} </p>
    </div>

    <!-- 
  <div class="billed-to">
    <h6 class="tx-gray-800">{{emp.emprole}} ({{emp.totalExp}})</h6>
    <p *ngIf="empltype == 'false'"><b>Line Manager:</b> {{emp.wemployee?.fname }} {{emp.wemployee?.mname }}
      {{emp.wemployee?.lname }}</p>
    <p><b>Start Date :</b><span>{{emp.startdate | date: 'dd MMM yyyy'}} </span></p>
    <p><b>End Date :</b><span>
        <ng-container *ngIf="emp.ispersent==true">
          Current
        </ng-container>
        <ng-container *ngIf="emp.ispersent==false">
          {{emp.enddate| date: 'dd MMM yyyy'}}
        </ng-container>
      </span></p>
  </div> -->
  </div>
</div>