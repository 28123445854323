"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var CosService = /** @class */ (function () {
    function CosService(commonService) {
        this.commonService = commonService;
    }
    CosService.prototype.getcoslist = function (data) {
        return this.commonService.formpost('/employee/cosreport', data);
    };
    CosService.ngInjectableDef = i0.defineInjectable({ factory: function CosService_Factory() { return new CosService(i0.inject(i1.CommonService)); }, token: CosService, providedIn: "root" });
    return CosService;
}());
exports.CosService = CosService;
