import { State } from './state.model';
import { Country } from './country.model';

export class Address {
  employeeid: number;
  employeeaddresdeatilid: number;
  address1: string;
  address2: string;
  cityname: string;
  pincode: string;
  country: Country;
  state: State;
  countryid: any;
  movedin: Date = new Date();
  mobilenumber: string = "";
  phonenumber: string = "";
  movedout: string = ""; 
  statename: string;
  taxid: string;
  cardname: string;
  cardid: string;
  customerid: string;
}