import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';



@Injectable({
  providedIn: 'root'
})
export class FileuploadService {

  constructor(private commonService: CommonService) { }


  
  fileuploadbynode(value: any): Observable<any> {
    // console.log(value);
    return this.commonService.formpost('/fileupload',value);      
  }

}
