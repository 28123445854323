"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var compensation_service_1 = require("../../../services/compensation.service");
var modal_service_1 = require("../../../_services/modal.service");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var alert_service_1 = require("../../../services/alert.service");
var compensation_model_1 = require("../../../models/compensation/compensation.model");
var CompensationinfoComponent = /** @class */ (function () {
    function CompensationinfoComponent(sanitizer, formBuilder, uploadService, data, modalService, activatedRoute, alerts) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.data = data;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.alerts = alerts;
    }
    CompensationinfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.empid = this.activatedRoute.snapshot.paramMap.get("id");
        this.compensationformGroup = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            nameofbank: ['', forms_1.Validators.required],
            accountname: ['', forms_1.Validators.required],
            sortcode: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            accountnumber: ['', forms_1.Validators.required],
            iban: ['']
        });
        this.onChanges();
        var id = localStorage.getItem('userToken');
        this.Userid = localStorage.getItem('userid');
        var data = { 'userid': this.Userid, 'employeeid': this.empid };
        this.data.getemployeecompensationbyadmin(data).subscribe(function (data) {
            _this.compensationlist = data;
        });
        this.compensationDetail = new compensation_model_1.Compensation();
        this.compensationDetail.sstatus = 0;
    };
    CompensationinfoComponent.prototype.onChangeDate = function (event) {
    };
    CompensationinfoComponent.prototype.onChanges = function () {
        var _this = this;
        this.formattedMessage = '';
        this.compensationformGroup.valueChanges.subscribe(function (val) {
            _this.formattedMessage = 'Hello, My name is and my email is .I would like to tell you that .';
        });
    };
    CompensationinfoComponent.prototype.onlistchange = function (type1, idval) {
        var _this = this;
        var sstatus;
        if (idval == 0 || type1 == false) {
            sstatus = [1, 2, 3];
        }
        else {
            sstatus = [idval];
        }
        var userid = localStorage.getItem('userid');
        var data = { 'id': this.empid, 'cstatus': sstatus };
        this.data.getemployeecompensationbyadmin(data).subscribe(function (data) {
            _this.compensationlist = data;
        });
    };
    Object.defineProperty(CompensationinfoComponent.prototype, "f", {
        get: function () { return this.compensationformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    CompensationinfoComponent.prototype.onSubmit = function (formcompen, id) {
        var _this = this;
        // var formcompen = value3.value;
        this.submitted = true;
        if (this.compensationformGroup.invalid) {
            return;
        }
        this.disableBtn = false;
        formcompen.userid = this.Userid;
        this.data.employeecompensationUpdatebyadmin(formcompen).subscribe(function (data) {
            var data1 = { 'userid': _this.Userid, 'employeeid': _this.empid };
            _this.data.getemployeecompensationbyadmin(data1).subscribe(function (data) {
                // value3.reset();
                return _this.compensationlist = data;
            });
        });
        this.messageSuccess = 'Compensation Info updated successfully';
        this.alerts.success(this.messageSuccess, false);
        this.filename = "Choose file";
        this.modalService.close(id);
    };
    CompensationinfoComponent.prototype.selectFile = function (event) {
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
    };
    CompensationinfoComponent.prototype.onDelete = function (compid) {
        var _this = this;
        if (confirm("Are you sure you want to delete?")) {
            var data = { 'id': compid, 'userid': this.Userid, 'emloyeeid': this.empid };
            this.data.employeecompensationDeletebyadmin(data).subscribe(function (data) {
                var data1 = { 'userid': _this.Userid, 'employeeid': _this.empid };
                _this.data.getemployeecompensationbyadmin(data1).subscribe(function (data) {
                    return _this.compensationlist = data;
                });
            });
        }
    };
    CompensationinfoComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.disableBtn = true;
        this.compensationDetail = new compensation_model_1.Compensation();
        this.buttoninsert = "Submit";
        this.compensationDetail.employeeid = this.empid;
        this.compensationDetail.id = "0";
        // this.compensationDetail.passportnumber = '';
        // this.compensationDetail.nationalityid = '';
        // this.compensationDetail.passportissue = '';
        // this.compensationDetail.passportexpiry = '';
        // this.compensationDetail.placeofissue = '';
        this.modalService.open(id);
    };
    CompensationinfoComponent.prototype.openModal = function (id, data) {
        this.disableBtn = true;
        this.compensationDetail = new compensation_model_1.Compensation();
        this.filename = "Choose file";
        this.buttoninsert = "Update";
        this.compensationDetail = data;
        this.modalService.open(id);
    };
    CompensationinfoComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    CompensationinfoComponent.prototype.openModal3 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    CompensationinfoComponent.prototype.closeModal = function (id) {
        var _this = this;
        this.compensationDetail = new compensation_model_1.Compensation();
        var data = { 'userid': this.Userid, 'employeeid': this.empid };
        this.data.getemployeecompensationbyadmin(data).subscribe(function (data) {
            _this.compensationlist = data;
        });
        this.modalService.close(id);
    };
    CompensationinfoComponent.prototype.modalOpen = function (data) {
        this.disableBtn = true;
        this.compensationDetail = new compensation_model_1.Compensation();
        this.compensationDetail = data;
    };
    return CompensationinfoComponent;
}());
exports.CompensationinfoComponent = CompensationinfoComponent;
