<div class="bg">
    <app-header></app-header>

 
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <a [routerLink]="['/request/request-list']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Request List</a>
                <div class="clearfix"></div>
                <div class="tabs__content">

                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="space_em row">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Address Proof Requests List
                                    </h5>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text" [(ngModel)]="searchText" class="form-control" placeholder="Search">
                                   
                                </div>
                            </div>
                             <ng-container *ngIf="( datalist | filter: {idnumber: searchText,docexpiry: searchText }) as pageOfItems"> 
                                  <div class="job_list_section idproof_request_section visa_request_section" *ngFor="let idproofs of pageOfItems; let i=index ;">
                                        <div class="job_detils_date ">
                                            <div class="visa_user_pic">
                                                <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                                <img *ngIf="idproofs.employee.imagename" src="{{idproofs.employee.imagename}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                                <img *ngIf="idproofs.employee.gender == true && (idproofs.employee.imagename == null || idproofs.employee.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                                <img *ngIf="idproofs.employee.gender == false && (idproofs.employee.imagename == null || idproofs.employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                            </div>
                                        </div>
                                            <div class="job_detils_date width20">
                                                <div class="visa_user_details">
                                                    <h4>{{idproofs.employee.fname}} {{idproofs.employee.mname}} {{idproofs.employee.lname}}</h4>
                                                    <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"></i> {{ idproofs.employee.emailid }}
                                                    </p>
                                                    <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i> {{ idproofs.employee.phonenumber }}</p>
                                                    <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i> NI Number {{ idproofs.employee.ninumber }} </p>
                                                    <span *ngIf="idproofs.employee.nationality" class="badge badge-success">{{ idproofs.employee.nationality.countryname }} </span>
                                                </div>
                                            </div>
                                            <div class="job_detils_date width22">
                                                <p class="job_address">Address Proof Number</p>
                                                <p class="job_profile">{{idproofs.idnumber}}</p>
                                            </div>
                                            <div class="job_detils_date width13">
                                                <p class="job_address">Document Type</p>
                                                <p class="job_profile">{{idproofs.documenttype.documentname}}</p>
                                            </div>
                                            <div class="job_detils_date width13">
                                                <p class="job_address">End Date</p>
                                                <p class="job_profile">{{idproofs.docexpiry | date: 'dd MMM yyyy'}}</p>
                                            </div>

                                            <div class="job_detils_date visa_btn">

                                                <p class="job_address">
                                                    <button type="submit" class="btn btn-secondary com_btn" (click)="openModal3('pdf-viewer-idproof',idproofs.iddocName, idproofs.idbackdocName)" *ngIf="idproofs.iddocName || idproofs.idbackdocName">
                                                        <div class="tooltip_visa">
                                                            <p >View</p>
                                                        </div> 
                                                        
                                                        <i class="fa fa-file-image-o"></i>
                                                    </button>
                                                    <button type="submit" class="btn btn-primary com_btn" (click)="openModal('custom-idproof-1',idproofs)">
                                                        <div class="tooltip_visa">
                                                            <p >Edit</p>
                                                        </div>
                                                        <i class="fa fa-pencil"></i>
                                                    </button>
                                                    <button type="submit" class="btn btn-danger com_btn" (click)="onDelete(idproofs.employeeidproofdeatilid)">
                                                        <div class="tooltip_visa">
                                                            <p >Delete</p>
                                                        </div>
                                                        <i class="fa fa-trash-o"></i>
                                                    </button>
                                                </p>
                                            </div>



                                            <div class="width100">
                                                <!-- <div class="col-lg-6 text-left">
                                                    <p class="badge badge-warning" *ngIf="idproofs.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Pending by AI </p>
                                                    <p class="badge badge-success" *ngIf="idproofs.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                                    <p class="badge badge-error" *ngIf="idproofs.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                                                </div> -->
                                                <div class="text-right">
                                                    <p class="mb-0 fontsize12"> Verify the ID using Complygate AI. <a [routerLink]="['/idproofaidetails',idproofs.employeeidproofdeatilid]">Click Here</a></p>
                                                    <!-- <p class="mb-0" *ngIf="aipayment ==true && idproofs.nationality.isid == true"> You have exhausted the free AI ID verification. To continue using it please <a [routerLink]="['/passportaidetails',idproofs.employeeidproofdeatilid]">Click Here</a></p> -->
                                                    <!-- <p class="mb-0" *ngIf="idproofs.nationality.isid == false"> The AI verification functionality for this country will be available soon.</p> -->
                                                </div>
                                            </div>

                                </div>
                                 <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>
                             </ng-container>
                          
                            <div class="job_list_section visa_request_section" *ngIf="record == 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div> -->





                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<jw-modal id="custom-idproof-1" role="dialog" class="modal">

    <form [formGroup]="idproofpopForm" #idproofForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(idproofForm.value,'custom-idproof-1','1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">

                    <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="idproofDetail.employeeid">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="idproofDetail.employeeidproofdeatilid">

                    <h5 class="modal-title" id="popupLabel">Address Proof Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-idproof-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Document Number*</label>
                                <input type="idnumber" formControlName="idnumber" name="idnumber" id="idnumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.idnumber.errors }" [(ngModel)]="idproofDetail.idnumber">
                                <div *ngIf="submitted && f.idnumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.idnumber.errors.required">Please enter document number</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Document Type*</label>
                                <select style="width:100%;" formControlName="idtype" name="idtype" id="idtype" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.idtype.errors }" [(ngModel)]="idproofDetail.idtype">
                                    <option [value]="defaultVal">Select Document Type </option>
                                    <option *ngFor="let ad3 of documenttype" [value]="ad3.id">{{ ad3.documentname }}</option>

                                    
                                  </select>
                                <div *ngIf="submitted && f.idtype.errors" class="invalid-feedback">
                                    <div *ngIf="f.idtype.errors.required">Please select document type</div>
                                </div>
                            </div>
                        </div>
                        <div class="row"> 

                            <div class="form-group col-lg-6">
                                <label>Expiry Date*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker2" id="docexpiry" name="docexpiry" formControlName="docexpiry" [ngClass]="{ 'is-invalid': submitted && f.docexpiry.errors }" class="form-control noborder-invalid datecontrol" [(ngModel)]="idproofDetail.docexpiry">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>

                                </mat-form-field>
                                <div *ngIf="submitted && f.docexpiry.errors" class="invalid-feedback">
                                    <div *ngIf="f.docexpiry.errors.required">Please Choose Expiry Date</div>
                                </div>
                            </div>

                            <div class="form-group col-lg-6">
                                <label>Issuing Country*</label>
                                <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [(ngModel)]="idproofDetail.nationalityid">
                                    <option [value]="defaultVal">Select Country</option>
                                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                    <div *ngIf="f.nationalityid.errors.required">Please select issuing country</div>
                                </div>
                            </div>

                        </div>
                        <div class="row">                            
                            <div class="col-lg-6">
                                <label>Front Side Document Upload*</label>
                                <div class="input-group mb-3" (click)="file.click()">
                                    <div class="custom-file">
                                        <input type="file" #file (change)="selectFile($event)">
                                        <label class="custom-file-label">{{filename}}</label>
                                    </div>
                                </div>
                                <div class="invalid-feedback ng-star-inserted">
                                    <div class="" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                    <div class="" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png)</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label>Back Side Document Upload</label>
                                <div class="input-group mb-3" (click)="fileback.click()">
                                    <div class="custom-file">
                                        <input type="file" #fileback (change)="selectbackFile($event)">
                                        <label class="custom-file-label">{{filebackname}}</label>
                                    </div>
                                </div>
                                <div class="invalid-feedback ng-star-inserted">
                                    <div class="" *ngIf="filebacksizeerror == true">File size must be less than 2 MB</div>
                                    <div class="" *ngIf="filebacksizeerror == true">Please choose a valid file format (jpg, png)</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">

                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(idproofForm.value,'custom-idproof-1', '1')" value="{{buttoninsert}}" />
                    <input type="submit" class="btn btn-success com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(idproofForm.value,'custom-idproof-1', '2')" value="Update & Approve" />
                    <input type="submit" class="btn btn-danger com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(idproofForm.value,'custom-idproof-1', '3')" value="Reject" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-idproof-1');" />



                </div>
            </div>
        </div>
    </form>
</jw-modal>



<jw-modal id="pdf-viewer-idproof" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-viewer-idproof');">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row">
                        <div class="twoboximg" [ngClass]="!iframeidproofbacksrc ?'col-lg-12':'col-lg-6'">
                            <div class="overlaytext">
                                <p>Front Image</p>
                            </div>
                            <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="600" frameborder="0"></iframe>
                            <img *ngIf="imgsrc == true" [src]="iframesrc" width="100%" />
                        </div>
                        <div class="col-lg-6 twoboximg" *ngIf="iframeidproofbacksrc">
                            <div class="overlaytext">
                                <p>Back Image</p>
                            </div>
                            <iframe *ngIf="imgidproofbacksrc == false" [src]="iframeidproofbacksrc" width="100%" height="250" frameborder="0"></iframe>
                            <img *ngIf="imgidproofbacksrc == true" [src]="iframeidproofbacksrc" width="100%" />
                        </div> 
                    </div>

                </div>
            </div>

        </div>
    </div>
</jw-modal>