"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CompanyadvertfinancialsComponent = /** @class */ (function () {
    function CompanyadvertfinancialsComponent() {
    }
    CompanyadvertfinancialsComponent.prototype.ngOnInit = function () {
    };
    return CompanyadvertfinancialsComponent;
}());
exports.CompanyadvertfinancialsComponent = CompanyadvertfinancialsComponent;
