"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var payment_service_1 = require("../../../services/payment.service");
var checkservice_service_1 = require("../../../services/checkservice.service");
var checksystem_service_1 = require("../../../services/checksystem.service");
var PurchasepaymentsuccessComponent = /** @class */ (function () {
    function PurchasepaymentsuccessComponent(router, activatedRoute, paymentService, check, checkService) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.paymentService = paymentService;
        this.check = check;
        this.checkService = checkService;
        this.token = '';
        this.wizard3 = false;
        this.wizard4 = false;
        this.serviceArray = [];
        this.serviceObjectArray = [];
    }
    PurchasepaymentsuccessComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.paymentstatus = this.activatedRoute.snapshot.paramMap.get("status");
        this.token = this.activatedRoute.snapshot.paramMap.get("id");
        this.ctype = parseInt(localStorage.getItem('ctype'));
        console.log(this.paymentstatus);
        console.log(this.token);
        console.log(this.ctype);
        if (this.paymentstatus == 'success') {
            this.wizard3 = true;
            this.wizard4 = false;
            this.serviceObjectArray = JSON.parse(localStorage.getItem('productstring'));
            if (this.serviceObjectArray) {
                var saveOrder = {
                    "token": this.token,
                    "data": this.serviceObjectArray,
                };
                this.checkService.completeorder(saveOrder).subscribe(function (payment) {
                    _this.serviceArray = [];
                    localStorage.removeItem('productstring');
                    _this.serviceObjectArray = [];
                    localStorage.removeItem('productstring');
                });
            }
        }
        else {
            this.wizard3 = false;
            this.wizard4 = true;
        }
    };
    return PurchasepaymentsuccessComponent;
}());
exports.PurchasepaymentsuccessComponent = PurchasepaymentsuccessComponent;
