import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../services/customer.service';
import { CountryService } from '../../../services/country.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CheckserviceService } from '../../../services/checkservice.service';
import { righttowork } from 'src/app/models/overview/profile.model';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-righ-to-work-history-list-admin',
  templateUrl: './righ-to-work-history-list-admin.component.html',
  styleUrls: ['./righ-to-work-history-list-admin.component.css']
})
export class RighToWorkHistoryListAdminComponent implements OnInit {

  empid:any;
  listemployee: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private checkService: CheckserviceService,
    private router : Router   ,
    private datePipe: DatePipe 
    ) { }

  ngOnInit() {

    this.checkService.selectrighttoworkbyadmin().subscribe((righttoworkadmin: any) => {
      if(righttoworkadmin.success == 'fail')
      {
        this.router.navigate(['/right-to-work-app-history-list',0]);
      }      
    });
    
    this.empid = this.activatedRoute.snapshot.paramMap.get("empid");

   var data = {employeeid: this.empid}
    this.checkService.getrighttoworkhistorybyemployeeadmin(data).subscribe((righttoworklog: any) => {         
        this.listemployee = righttoworklog;

        console.log(righttoworklog);
    });
  }

  completerightowork(logid){
    localStorage.SetItem('logid',logid);
    this.router.navigate(['/right-to-work-app-history']);

  }
  
  shouldDisplayDate(date: Date) : boolean 
  {    
    if (this.datePipe.transform(date, 'yyyy-MM-dd')  >=  this.datePipe.transform('10/02/2022', 'yyyy-MM-dd')) 
    { 
        return true;
    }  
    return false;
}

  viewemployeerighttoworkhistory(empid)
  { 
    localStorage.removeItem('logid');
    this.router.navigate(['/right-to-work-app-history', empid]);
  }
  completerighttoworkhistory(logid,empid)
  { 
    localStorage.setItem('logid', logid);
    // localStorage.SetItem('logid',logid); //localStorage.removeItem('logid');
    this.router.navigate(['/right-to-work-app-admin',empid ]);
  }
}
