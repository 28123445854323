import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  employeedata=[];
  employementdata=[];
  cosdata=[];
  visadata=[];
  passportdata=[];
  leavedata=[];
  expensedata=[];
  timesheetdata=[];
  assetdata=[];

  employeejson: any;
  employementjson: any;
  cosjson: any;
  visajson: any;
  passportjson: any;
  leavejson: any;
  expensejson: any;
  timesheetjson: any;
  assetjson: any;



  constructor() { }



  public employeeexcel(data: any): void {
    this.employeedata = [];
    data.forEach(obj => {
      this.employeejson = {
        "Name": obj.fname + " " + obj.mname + " " + obj.lname,
        "Email": obj.emailid,
        "Phone Number": obj.phonenumber,
        "NI Number": obj.ninumber,
        "Nationality": obj.nationality.countryname,
        "Country Of Residence": obj.residence.countryname,
        "Country Of Birth": obj.countryofbirth.countryname,
        "Place Of Birth": obj.placeofbirth
      };
      this.employeedata.push(this.employeejson);
    });
  }

  public employementexcel(data: any): void {

    this.employementdata = [];
    data.forEach(obj => {
    
      // if ((obj.wemployeeid != '0') && (obj.wemployeeid != '')) {
      //   var linemanager = obj.wemployee.fname + " " + obj.wemployee.mname + " " + obj.wemployee.lname;
      // }
      // else {
      //   var linemanager = "";
      // }

      // if ((obj.clientid != '0') && (obj.clientid != '')) {
      //   var name = obj.client.clientname;
      // }
      // else {
      //   name = "";
      // }

      // if ((obj.projectid != '0') && (obj.projectid != '')) {
      //   var project = obj.project.projectname;
      // }
      // else {
      //   project = "";
      // }

      this.employementjson = {
        "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
        // "Line Manager": linemanager,
        // "Client": name,
        // "Project": project,
        "Salary": obj.salary,
        "Hour Of Work": obj.hoursofworks,
        "Employee Role": obj.emprole,
        "Start Date ": obj.startdate,
        "End Date ": obj.enddate
      };

      this.employementdata.push(this.employementjson);
    });
  }


  public cosexcel(data: any): void {
    this.cosdata = [];
    data.forEach(obj => {
      if (obj.iscurrent == false) {
        var current = "No";
      }
      else if (obj.iscurrent == true) {
        current = "Yes";
      }
      this.cosjson = {
        "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
        "Email": obj.employee.emailid,
        "Phone Number": obj.employee.phonenumber,
        "NI Number": obj.employee.ninumber,
        "COS Number": obj.cosnumber,
        "Start Date": obj.cosstartdate,
        "End Date": obj.cosexpirydate,
        "Is Current": current
      };
      this.cosdata.push(this.cosjson);
    });
  }

  public visaexcel(data: any): void {
    this.visadata = [];
    data.forEach(obj => {
      if (obj.iscurrent == false) {
        var current = "No";
      }
      else if (obj.iscurrent == true) {
        current = "Yes";
      }
      this.visajson = {
        "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
        "Email": obj.employee.emailid,
        "Phone Number": obj.employee.phonenumber,
        "NI Number": obj.employee.ninumber,
        "VISA Number": obj.visanumber,
        "Start Date": obj.visastart,
        "End Date": obj.visaexpiry,
        "Is Current": current
      };
      this.visadata.push(this.visajson);
    });
  }


  public passportexcel(data: any): void {
    this.passportdata = [];
    data.forEach(obj =>{
      if(obj.iscurrent == false)
      {
        var current = "No";
      }
      else if(obj.iscurrent == true)
      {
        current = "Yes";
      }
      this.passportjson = {
        "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
        "Email": obj.employee.emailid,
        "Phone Number": obj.employee.phonenumber,
        "NI Number": obj.employee.ninumber,
        "Passport Number": obj.passportnumber, 
        "Nationality": obj.nationality.countryname,
        "Start Date": obj.passportissue, 
        "End Date": obj.passportexpiry, 
        "Is Current": current
      };
      this.passportdata.push(this.passportjson);     
    });  
  }

  
  public leaveexcel(data: any): void {
    this.leavedata = [];
    data.forEach(obj =>{
      if(obj.iscurrent == false)
      {
        var current = "No";
      }
      else if(obj.iscurrent == true)
      {
        current = "Yes";
      }
      this.leavejson = {
        "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
        "Email": obj.employee.emailid,
        "Phone Number": obj.employee.phonenumber,
        "NI Number": obj.employee.ninumber,
        "Title": obj.title,
        "Start Date": obj.startdate, 
        "End Date": obj.enddate, 
        "Leave Type": obj.holidaytype.typename
      };    
      
      this.leavedata.push(this.leavejson);
    });   
  }

  public timesheetexcel(data: any): void {
    this.timesheetdata = [];
    data.forEach(obj =>{
      this.timesheetjson = {
        "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
        "Email": obj.employee.emailid,
        "Phone Number": obj.employee.phonenumber,
        "NI Number": obj.employee.ninumber,
        "Hours": obj.ahour,
        "Hour Type": obj.hourtype.typename,
        "Project": obj.project.projectname,
        "Location": obj.location.address1,
        "Date": obj.startdate
      }; 
      
      this.timesheetdata.push(this.timesheetjson);
    });    
  }

  public expenseexcel(data: any): void {
    this.expensedata = [];
    data.forEach(obj =>{
      if(obj.paidby == '1')
      {
        var paidby = "Company Paid";
      }
      else if(obj.paidby == '2')
      {
        paidby = "Client Paid";
      }
      else if(obj.paidby == '3')
      {
        paidby = "Employee Paid";
      }
      this.expensejson = {
        "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
        "Email": obj.employee.emailid,
        "Phone Number": obj.employee.phonenumber,
        "NI Number": obj.employee.ninumber,
        "Project": obj.project.projectname,
        "Expense Type": obj.expensetype.typename,
        "Paid By": paidby,
        "Date": obj.startdate, 
        "Amount": obj.amountpaid, 
        "Location": obj.locationname
      };     
      this.expensedata.push(this.expensejson);
    });   
  }

  
  public exportAsExcelFileAll(excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.employeedata);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.visadata);
    const worksheet3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.passportdata);
    const worksheet4: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.employementdata);
    const worksheet5: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.cosdata);

    // const worksheet6: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.leavedata);
    // const worksheet7: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.expensedata);
    // const worksheet8: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.timesheetdata);
    // , 'Employee Leave': worksheet6, 'Employee Expense': worksheet7, 'Employee Timesheet': worksheet8
    // ,'Employee Leave','Employee Expense','Employee Timesheet'

    
    const workbook: XLSX.WorkBook = { Sheets: { 'Employee': worksheet, 'Visa': worksheet2, 'Passport': worksheet3 , 'Employment': worksheet4, 'COS': worksheet5}, SheetNames: ['Employee', 'Visa', 'Passport','Employment','COS'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.saveAsExcelFile(excelBuffer, excelFileName);
  }



  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });

    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}

