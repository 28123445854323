import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  [x: string]: any;
  private modals: any[] = [];
  constructor(private commonService: CommonService) { }

  getleavenotificationbyadmin(data: any): Observable<any> {
    return this.commonService.formpost('/admin/getleavenotificationbyadmin', data);      
  }

  getexpensenotificationbyadmin(data: any): Observable<any> {
    return this.commonService.formpost('/admin/getexpensenotificationbyadmin', data);      
  }

  getleavenotificationbydatebyadmin(data: any): Observable<any> {
    return this.commonService.formpost('/admin/getleavenotificationbydatebyadmin', data);      
  }

  getpassportexpirydatebyadmin(data: any): Observable<any> {
    return this.commonService.formpost('/admin/getpassportexpirybyadmin', data);      
  }

  getvisaexpirydatebyadmin(data: any): Observable<any> {
    return this.commonService.formpost('/admin/getvisaexpirybyadmin', data);      
  }

  getcosexpirydatebyadmin(data: any): Observable<any> {
    return this.commonService.formpost('/admin/getcosexpirybyadmin', data);      
  }

  getallnotificationbydatebyadmin(data: any): Observable<any> {
    return this.commonService.formpost('/admin/getallnotificationbydatebyadmin', data);      
  }

}
