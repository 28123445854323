"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CompensationinformationComponent = /** @class */ (function () {
    function CompensationinformationComponent() {
    }
    CompensationinformationComponent.prototype.ngOnInit = function () {
    };
    return CompensationinformationComponent;
}());
exports.CompensationinformationComponent = CompensationinformationComponent;
