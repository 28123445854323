import { Component, OnInit } from '@angular/core';
import { CheckserviceService } from '../../../../services/checkservice.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-adversecredithistoryview',
  templateUrl: './adversecredithistoryview.component.html',
  styleUrls: ['./adversecredithistoryview.component.css']
})
export class AdversecredithistoryviewComponent implements OnInit {

  reportData: any;
  historyid: any;
  searchingFields: any;
  picklist: any;
  creditrequest: any;
  jobdetails: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private checkService: CheckserviceService, 
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.historyid = params['id'];
      this.checkService.adversecredithistoryview({"id":this.historyid}).subscribe((data: any) => {
        this.reportData = data.response_data.report;
        this.searchingFields = data.response_data.search;
        this.picklist = data.response_data.picklist;
        this.creditrequest = data.response_data.creditrequest;
        this.jobdetails = data.response_data.jobdetails;
      });
    });
  }

  convertStringToArray = (object) => {

    var listMaster: any = [];
    if(typeof object === 'object'){
      console.log('fafadsfa');

      listMaster.push(object);
      return listMaster;
    }else{
      console.log(typeof object);
      object.forEach(function(entry3) { 
        listMaster.push(entry3);
      });
      return listMaster;
    }

 }
 convertStringToArrayyes = (object) => {

  var listMaster: any = [];
  if(typeof object === 'object'){ 

    console.log(object);
    
    console.log('yes');
    return 'object';
  }else{   console.log('no'); 
    return 'array';
  }

}

}
