"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var user_service_1 = require("../../services/user.service");
var user_model_1 = require("../../models/user.model");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var forms_1 = require("@angular/forms");
var customer_service_1 = require("../../services/customer.service");
var company_service_1 = require("../../services/company.service");
var alert_service_1 = require("../../services/alert.service");
// import { User} from '../../shared/user.model';
// import { EmailValidator } from '@angular/forms';
var UpdateemailComponent = /** @class */ (function () {
    function UpdateemailComponent(formBuilder, userService, router, alerts, csservices, company) {
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.router = router;
        this.alerts = alerts;
        this.csservices = csservices;
        this.company = company;
        this.submitted = false;
        this.isLoginError = false;
        // loading: boolean;
        this.user = new user_model_1.User();
        this.access_token = '';
        this.userid = '';
        this.name = '';
        this.isline = '';
        this.planid = '';
        this.error = '';
    }
    UpdateemailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.registerForm = this.formBuilder.group({
            email: ['', forms_1.Validators.required]
        });
        this.show = false;
        this.success = true;
        // var isemail = localStorage.getItem('isemail');
        // if ( isemail == "true" || isemail =="1") 
        // {
        //   localStorage.setItem('isemail', "true");
        //   this.router.navigate(['/choose-plan']);
        // }
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        else {
            this.userService.checkaccesstokenbyid(userid).subscribe(function (data) {
                if (data.status == false) {
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('userid');
                    localStorage.removeItem('Name');
                    localStorage.removeItem('isline');
                    localStorage.removeItem('planid');
                    localStorage.removeItem('ctype');
                    localStorage.removeItem('isemail');
                    localStorage.removeItem('ismobile');
                    localStorage.removeItem('isadmin');
                    localStorage.removeItem('ispermission');
                    localStorage.removeItem('logoname');
                    localStorage.removeItem('struser');
                    localStorage.removeItem('menu');
                    _this.router.navigate(['/login']);
                }
            });
        }
        var isemail = localStorage.getItem('isemail');
        var ismobile = localStorage.getItem('ismobile');
        var struser = localStorage.getItem('struser');
        this.ctype = localStorage.getItem('ctype');
        var planid = localStorage.getItem('planid');
        if (isemail == 'true') {
            if (this.ctype == '0') {
                this.router.navigate(['dashboard']);
            }
            else if (this.ctype == '1') {
                this.router.navigate(['check-dashboard']);
            }
            else if (this.ctype == '2') {
                this.router.navigate(['check-dashboard']);
            }
            else if (this.ctype == '3') {
                this.router.navigate(['reference-dashboard']);
            }
        }
    };
    Object.defineProperty(UpdateemailComponent.prototype, "f", {
        get: function () { return this.registerForm.controls; },
        enumerable: true,
        configurable: true
    });
    UpdateemailComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.company.emailupdate(this.user).subscribe(function (data) {
            if (data.status == "success") {
                _this.showsuccess = true;
                _this.success = false;
                localStorage.removeItem('userToken');
                localStorage.removeItem('userid');
                localStorage.removeItem('Name');
                localStorage.removeItem('isline');
                localStorage.removeItem('planid');
                localStorage.removeItem('ctype');
                localStorage.removeItem('isemail');
                localStorage.removeItem('ismobile');
                localStorage.removeItem('isadmin');
                localStorage.removeItem('ispermission');
                localStorage.removeItem('logoname');
                localStorage.removeItem('struser');
                localStorage.removeItem('menu');
            }
            else {
                _this.showsuccess = false;
                _this.success = true;
                _this.alerts.error("This email already exists. Please try another email.");
                // this.show = true;
            }
        }, function (err) {
            _this.show = true;
        });
    };
    UpdateemailComponent.prototype.resedotp = function () {
        var _this = this;
        this.submitted = false;
        this.show = false;
        this.company.resendotp().subscribe(function (data) {
            localStorage.setItem('otp', data.otp);
            _this.show = false;
            _this.router.navigate(['/otp']);
        }, function (err) {
            _this.isLoginError = true;
        });
    };
    UpdateemailComponent.prototype.logout = function () {
        var _this = this;
        if (confirm("Are you sure you want to sign out?")) {
            this.userService.logout().subscribe(function (data) {
                localStorage.removeItem('userToken');
                localStorage.removeItem('userid');
                localStorage.removeItem('Name');
                localStorage.removeItem('isline');
                localStorage.removeItem('planid');
                localStorage.removeItem('ctype');
                localStorage.removeItem('isemail');
                localStorage.removeItem('ismobile');
                localStorage.removeItem('isadmin');
                localStorage.removeItem('ispermission');
                localStorage.removeItem('logoname');
                localStorage.removeItem('struser');
                localStorage.removeItem('menu');
                _this.router.navigate(['/login']);
            });
        }
    };
    return UpdateemailComponent;
}());
exports.UpdateemailComponent = UpdateemailComponent;
