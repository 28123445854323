import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Employee } from '../../models/employee.model';
import { CountryService } from '../../services/country.service';
import { EmployeeService } from '../../services/employee.service';
import { VisaService } from '../../services/visa.service';
import { ExcelService } from '../../services/excel.service';

@Component({
  selector: 'app-visareport',
  templateUrl: './visareport.component.html',
  styleUrls: ['./visareport.component.css']
})

export class VisareportComponent implements OnInit {
  EmployeeForm: FormGroup;
  employeeDetail = new Employee();
  employeelist: any;
  countrylist: any;
  coslist: any;
  data: any;
  exceldata = [];
  employeevisadata: any;

  constructor(private excelService: ExcelService, private formBuilder: FormBuilder, private country: CountryService, private employee: EmployeeService, private visareport: VisaService) { }

  ngOnInit() {
    this.EmployeeForm = this.formBuilder.group({
      emailid: [''],
      phonenumber: [''],
      ninumber: [''],
      minvisastart: [''],
      maxvisastart: [''],
      minvisaexpiry: [''],
      maxvisaexpiry: [''],
      visanumber: [''],
      iscurrent: [''],
      stype: ['']
    });

    var values3 = {};
    this.visareport.getvisalist(values3).subscribe((visadetails: any) => {
      this.data = visadetails;
    });

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
  }

  exportAsXLSX(): void {
    this.exceldata = [];
    this.data.forEach(obj => {
      if (obj.iscurrent == false) {
        var current = "No";
      }
      else if (obj.iscurrent == true) {
        current = "Yes";
      }

      this.employeevisadata = {
        "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
        "VISA Number": obj.visanumber,
        "Start Date": obj.visastart,
        "End Date": obj.visaexpiry,
        "Is Current": current
      };
      this.exceldata.push(this.employeevisadata);
    });
    this.excelService.exportAsExcelFile(this.exceldata, 'sample');
  }

  onSubmit(value3: any) {
    this.visareport.getvisalist(value3).subscribe((visadetails: any) => {
      this.data = visadetails;
    });
  }
}