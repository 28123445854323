import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ModalService } from '../../../_services/modal.service';
import { CommonService } from '../../../services/common.service';
import { CountryService } from '../../../services/country.service';
import { JobService } from '../../../services/job.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Jobs } from '../../../models/jobs/jobs.model';
import { MatOptionSelectionChange } from '@angular/material';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFileService } from './../../../services/upload-file.service';
import { Country } from './../../../models/address/country.model';
import { Soc } from "./../../../models/jobs/Soc.model";
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-jobdetails',
  templateUrl: './jobdetails.component.html',
  styleUrls: ['./jobdetails.component.css']
})

export class JobdetailsComponent implements OnInit {
  Userid: any;
  joblist: any;file: any;
  data: any;
  jobDetail = new Jobs();
  jobformGroup: FormGroup;
  jobscreenformGroup: FormGroup;
  // public Editor = ClassicEditor;
  mobilecheck: any;
  phonecheck: any;
  socdata: any;
  countrylist: any;
  countylist: any;
  options = [];
  buttoninsert: any;
  submitted: boolean = false;
  selectedFiles: FileList;
  filename: any;
  file1: any;
  file2: any;
  file3: any;
  file4: any;
  skilldata:any;
  filename4: any;
  filename3: any;
  filename2: any;
  filename1: any;
  screenumber: any;
  screenshotid: any;
  disableday: boolean;
  iframesrc: any;
  imgsrc: any;
  public searchText: string;
  jobid: string;
  disableBtn: any;
  error1:boolean;
  error2:boolean;
  error3:boolean;
  error4:boolean;
  filesizeerror1:boolean;
  filesizeerror2:boolean;
  filesizeerror3:boolean;
  filesizeerror4:boolean;
  

  constructor(
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
    private modalService: ModalService,
    private job: JobService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private country: CountryService,
    private router: Router,
    private alerts: AlertService,
    private uploadService: UploadFileService
  ) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    this.jobid = this.activatedRoute.snapshot.paramMap.get("jobid");

    this.error1 =false;
    this.error2 =false;
    this.error3 =false;
    this.error4 =false;

    this.jobDetail.country = new Country();
    this.jobDetail.soc = new Soc();
    this.jobformGroup = this.formBuilder.group({
      jobmasterid: [''],
      urldocumentid: [''],
      jobtittle: ['', Validators.required],
      jobdate: [''],
      closedate: [''],
      contactname: ['', Validators.required],
      // amountpaid: ['', Validators.compose([Validators.required, Validators.pattern(/^([0-9].[0-9]\d|[1-9]\d*)?$/)])],
      contactrole: ['', Validators.required],
      // expensetypeid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      joburl1: ['', Validators.required],
      joburl2: ['', Validators.required],
      joburl3: [''],
      joburl4: [''],
      salary: ['', Validators.compose([Validators.required, Validators.min(1)])],
      maxsalary: ['', Validators.compose([Validators.required, Validators.min(1)])],
      socid: ['', Validators.required],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: ['', Validators.compose([Validators.required])],
      postalcode: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      cityname: ['', Validators.required],
      countyid: ['', Validators.required],
      countryid: ['', Validators.required],
      qualification: [''],
      addposition: ['', Validators.required],
      stypeid: ['', Validators.required],
      jobdescreption: ['']
    });

    this.jobscreenformGroup = this.formBuilder.group({
      id: [''],
      jobmasterid: ['', Validators.required],
      screenshotdayid: ['', Validators.required],
      joburl1: [''],
      joburl2: [''],
      joburl3: [''],
      joburl4: ['']
    });

    this.filename1 ='Choose file';
    this.filename2 ='Choose file';
    this.filename3 ='Choose file';
    this.filename4 ='Choose file';

    this.Userid = localStorage.getItem('userid');
    this.job.getjobById({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {

      this.jobDetail = data;

     // console.log(this.jobDetail);
    });
    this.job.getjobskillList({"id": this.Userid, "jobid": this.jobid}).subscribe((data: any) => {
      
      this.skilldata = data;
    });
    this.buttoninsert = "Add New Job";
    this.country.getsoclist().subscribe((socdata: any) => {
      this.socdata = socdata;
    });
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    const countrydata = { 'id': this.jobDetail.countryid };
    this.country.getstatelist(countrydata).subscribe((state: any) => {

      this.countylist = state;
    });

    this.job.getjobList().subscribe((data: any) => {
      this.data = data;
    });
  }
  onDelete(id: number) {
    const deleteData = { 'id': id };
    if (confirm("Are you sure you want to delete?")) {
      this.job.deleteskill(deleteData).subscribe((data: any) => {
        this.job.getjobskillList({"id": this.Userid, "jobid": this.jobid}).subscribe((data: any) => {
          this.skilldata = data;
        });
      });
    }
  }
  get f() { return this.jobformGroup.controls; }
  get g() { return this.jobscreenformGroup.controls; }


  onSubmit(jobformdata: any, id: string) {
    this.submitted = true;
    if (this.jobformGroup.invalid) {
      return;
    }
    jobformdata.userid = this.Userid;
    if (jobformdata.joburl1 != '') {
      jobformdata.file1 = this.file1;
    }
    if (jobformdata.joburl2 != '') {
      jobformdata.file2 = this.file2;
    }
    if (jobformdata.joburl3 != '') {
      jobformdata.file3 = this.file3;
    }
    if (jobformdata.joburl4 != '') {
      jobformdata.file4 = this.file4;
    }

    this.disableBtn = false;
    this.job.jobdetailsUpdate(jobformdata).subscribe((response: any) => {
      this.job.getjobList().subscribe((data: any) => {

        this.data = data;
        this.modalService.close(id);
      });
    });
  }

  addJobScreen(jobscreenshotformdata: any, id: string) {

    this.submitted = true;
    if (this.jobscreenformGroup.invalid) {
      return;
    }
    if (jobscreenshotformdata.joburl1 != '') {
      jobscreenshotformdata.file1 = this.file1;
    }
    if (jobscreenshotformdata.joburl2 != '') {
      jobscreenshotformdata.file2 = this.file2;
    }
    if (jobscreenshotformdata.joburl3 != '') {
      jobscreenshotformdata.file3 = this.file3;
    }
    if (jobscreenshotformdata.joburl4 != '') {
      jobscreenshotformdata.file4 = this.file4;
    }

    this.job.jobscreenshotdetailsUpdate(jobscreenshotformdata).subscribe((response: any) => {
      this.job.getjobById({ "id": this.Userid, "jobid": response.jobmasterid }).subscribe((data: any) => {
        this.jobDetail = data;
        this.modalService.close(id);
      });
    });
  }

  onscreenchange(jobscreensortid: number) {
    this.job.checkscreensort({ "id": this.Userid, "screenid": jobscreensortid, "jobid": this.jobid }).subscribe((datascreen: any) => {
      if (datascreen.length == 0) {
        this.screenshotid = '';
      }
      else {
        this.screenshotid = datascreen[0].id;
      }
      
      // this.file1 = "";
      // this.file2 = "";
      // this.file3 = "";
      // this.file4 = "";


      // this.filename1 ='Choose file';
      // this.filename2 ='Choose file';
      // this.filename3 ='Choose file';
      // this.filename4 ='Choose file';

      
    });
  }

  changeLabelName(lbl, val) {
    document.getElementById(lbl).innerHTML = val;
  }  

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  onSearchChange(searchValue) {
    if (searchValue.length > 4) {
      var newArray = [];
      this.commonService.getAddress(searchValue).subscribe((data: any) => {
        var newdata: any;
        newdata = data;
        newdata.addresses.forEach(function (value) {
          newArray.push(value + "," + searchValue);
        });
        this.options = newArray;
      });
    }
    else {
      this.options = [];
    }
  }

  displayFn(value: any) {
    if (value != null) {
      var res = value.split(",");
      if (res.length > 6) {
        return res[7];
      }
      else {
        return value;
      }
    }
  }

  updatecode(evt: MatOptionSelectionChange, response) {
    res = [];
    if (evt.source.selected) {
      var res = response.split(",");
      this.jobDetail.address1 = res[0];
      this.jobDetail.address2 = res[1];
      this.jobDetail.cityname = res[5];
      this.jobDetail.postalcode = res[7];
      const data = { 'id': this.jobDetail.countryid };
      this.country.getstatelist(data).subscribe((state: any) => {
        for (var i = 0; i < state.length; i++) {
          if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
            var inde = state[i]['stateid'];
          }
        }
        this.jobDetail.countyid = inde;
        this.countylist = state;
      });
    }
  }

  transform(html) {
    return this.sanitizer.bypassSecurityTrustStyle(html);
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  selectFile(event, field) {
    var files = event.target.files;
    var selectedFiles = files[0];
    this.selectedFiles = selectedFiles;

    this.filesizeerror1 =false;this.filesizeerror2 =false;this.filesizeerror3 =false;this.filesizeerror4 =false;
    this.error1 =false;this.error2 =false;this.error3 =false;this.error4 =false;



    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {

        if (field == 'file1') {
        this.filesizeerror1 = true;
        }else  if (field == 'file2') { this.filesizeerror2 = true;
        }else  if (field == 'file3') { this.filesizeerror3 = true;
        }
        else  if (field == 'file4') { this.filesizeerror4 = true;
        }

        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
        if (field == 'file1') {
          this.error1 = true;
          }else  if (field == 'file2') { this.error2 = true;
          }else  if (field == 'file3') { this.error3 = true;
          }
          else  if (field == 'file4') { this.error4 = true;
          }
        
        return;
      }     
    }


    const file = this.selectedFiles;

    var imagepath = this.uploadService.uploadfile(file);

    if (field == 'file1') {
      this.file1 = imagepath;
      this.filename1 = files[0].name;
    }
    if (field == 'file2') {
      this.file2 = imagepath;
      this.filename2 = files[0].name;
    }
    if (field == 'file3') {
      this.file3 = imagepath;
      this.filename3 = files[0].name;
    }
    if (field == 'file4') {
      this.file4 = imagepath;
      this.filename4 = files[0].name;
    }
  }

  openModal(jobid: string, id: string) {
    this.buttoninsert = "Update";
    this.disableBtn = true;
    this.job.getjobById({ "id": this.Userid, "jobid": jobid }).subscribe((data: any) => {

      data.screen.forEach(element => {
        if (element.screenshotdayid == '1') {
          this.jobDetail.urldocumentid = element.id;
        }
      });
      this.jobDetail = data;
      // this.jobDetail.jobdescreption =  this.transform(data.jobdescreption);
    });
    this.modalService.open(id);
  }

  openModal2(id: string) {
    this.submitted = false;
    this.disableBtn = true;
    this.buttoninsert = "Add New Job";
    this.jobDetail = new Jobs();
    this.modalService.open(id);
  }

  openModal3(jobid: string, id: string) {
    this.buttoninsert = "Update Job";
    this.disableBtn = true;
    this.job.getjobById({ "id": this.Userid, "jobid": jobid }).subscribe((data: any) => {

      this.jobDetail = data;
    });
    this.modalService.open(id);
  }

  openModal4(screenshotid: string, id: string, screenumber: string) {
    this.buttoninsert = "Submit";
    this.disableBtn = true;    
    this.screenshotid = screenshotid;
    this.file1 = "";
    this.file2 = "";
    this.file3 = "";
    this.file4 = "";

    this.filesizeerror1 =false;this.filesizeerror2 =false;this.filesizeerror3 =false;this.filesizeerror4 =false;
    this.error1 =false;this.error2 =false;this.error3 =false;this.error4 =false;

    this.filename1 ='Choose file';
    this.filename2 ='Choose file';
    this.filename3 ='Choose file';
    this.filename4 ='Choose file';


    if (screenumber != '') {
      this.disableday = true;
    }
    else {
      this.disableday = false;
    }
    this.screenumber = screenumber;
    this.modalService.open(id);
  }

  openModal5(id: string, data: any) {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  deleteFile(jobid: string, id: string, documenturl: string) {
    var deleteData = {};
    if (documenturl == 'document1') {
      deleteData = { 'id': id, 'urldocument1': null };
    }
    else if (documenturl == 'document2') {
      deleteData = { 'id': id, 'urldocument2': null };
    }
    else if (documenturl == 'document3') {
      deleteData = { 'id': id, 'urldocument3': null };
    }
    else if (documenturl == 'document4') {
      deleteData = { 'id': id, 'urldocument4': null };
    }

    if (confirm("Are you sure you want to delete?")) {
      this.job.deleteFile(deleteData).subscribe((data: any) => {
        this.job.getjobById({ "id": this.Userid, "jobid": jobid }).subscribe((data: any) => {
          this.alerts.success('Document deleted successfully',true);
          this.jobDetail = data;
        });
      });
    }
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

}
