import { Component, OnInit } from '@angular/core';
import { CompensationService } from '../services/compensation.service';
import { ModalService } from '../_services/modal.service';
import { FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { UploadFileService } from './../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { Compensation } from '../models/compensation/compensation.model';

@Component({
  selector: 'app-mycompensation',
  templateUrl: './mycompensation.component.html',
  styleUrls: ['./mycompensation.component.css']
})

export class MycompensationComponent implements OnInit {
  form: FormGroup;
  compensationformGroup: FormGroup;
  compensationlist: any[] = null;
  statuslist: any;
  compensationDetail: any;
  countrylist: any[];
  Userid: any;
  listcheck: any;
  myfile: any;
  file: any;
  selectedFiles: FileList;
  passnumbererror: any;
  nationalityerror: any;
  passportdateoriginerror: any;
  iframesrc: any;
  imgsrc: any;
  buttoninsert: any;
  filesizeerror: any;
  fileformaterror: any;
  formattedMessage: any;
  messageSuccess:any;
  filename: any;
  submitted: boolean;

  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private data: CompensationService, 
    private modalService: ModalService, 
    private alerts: AlertService,
    private router: Router
    ) { }

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    
    this.compensationformGroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      nameofbank: ['', Validators.required],
      accountname: ['', Validators.required],
      sortcode: ['', Validators.compose([Validators.required, Validators.min(1)])],
      accountnumber: ['', Validators.required],
      iban: ['']
    });
    
    this.onChanges();
    this.comensationlist();
    this.compensationDetail = new Compensation();
    this.compensationDetail.sstatus = 0;
  }

  comensationlist()
  {
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'userid': this.Userid };
    this.data.getemployeecompensation(data).subscribe((data: any) => {
      this.compensationlist = data;
    });
  }
  onChangeDate(event: any)
  {
    
  }
  
  onChanges(): void {
    this.formattedMessage = '';
    this.compensationformGroup.valueChanges.subscribe(val => {
      this.formattedMessage = 'Hello, My name is and my email is .I would like to tell you that .';
    });
  }

  // onlistchange(type1: any, idval: number) {
  //   var sstatus: any;
  //   if (idval == 0 || type1 == false) {
  //     sstatus = [1, 2, 3];
  //   }
  //   else {
  //     sstatus = [idval];
  //   }
  //   var userid = localStorage.getItem('userid');
  //   const data = { 'id': this.empid, 'cstatus': sstatus };
  //   this.data.getemployeecompensationbyadmin(data).subscribe((data: any) => { 
  //     this.compensationlist = data;
  //   });
  // }

  get f() { return this.compensationformGroup.controls; }

  onSubmit(value3: any, id: string) {
    this.submitted = true;
    if (this.compensationformGroup.invalid) {
      return;
    }

    value3.userid = this.Userid;
    this.data.employeecompensationUpdate(value3).subscribe((data: any) => {
      const data1 = { 'userid': this.Userid };
      this.data.getemployeecompensation(data1).subscribe((data: any) => {
        return this.compensationlist = data;
      });
    });
    this.messageSuccess = 'Compensation details updated successfully.'; 
    this.alerts.success(this.messageSuccess, false);
    this.filename = "Choose file";
    this.modalService.close(id);
  }

  selectFile(event) {
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = files[0];
  }

  onDelete(compid: number) {
    if (confirm("Are you sure you want to delete?")) {
      const data = { 'id': compid, 'userid': this.Userid };
      this.data.employeecompensationDelete(data).subscribe((data: any) => {
        const data1 = { 'userid': this.Userid };
        this.data.getemployeecompensation(data1).subscribe((data: any) => {
          return this.compensationlist = data;
        });
      });
    }
  }

  openModal2(id: string) {
    this.submitted = false;
    this.compensationDetail = new Compensation();
    this.buttoninsert = "Submit";
    this.compensationDetail.employeeid = this.Userid;
    this.compensationDetail.id = "0";
    // this.compensationDetail.passportnumber = '';
    // this.compensationDetail.nationalityid = '';
    // this.compensationDetail.passportissue = '';
    // this.compensationDetail.passportexpiry = '';
    // this.compensationDetail.placeofissue = '';
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    // console.log(data);
    this.submitted = false;
    this.compensationDetail = new Compensation();
    this.filename = "Choose file";
    this.buttoninsert = "Update";
    this.compensationDetail = data;
    this.modalService.open(id);
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModal3(id: string, data: any) {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  closeModal(id: string) {
    // this.compensationDetail = new Compensation();
    // const data = { 'userid': this.Userid };
    // this.data.getemployeecompensation(data).subscribe((data: any) => {
    //   this.compensationlist = data;
    // });
    this.modalService.close(id);
    this.comensationlist();
  }

  modalOpen(data: any) {
    this.compensationDetail = new Compensation();
    this.compensationDetail = data;
  
  }
}