"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var worklist_service_1 = require("./../../services/worklist.service");
var modal_service_1 = require("../../_services/modal.service");
var customer_service_1 = require("../../services/customer.service");
var app_global_1 = require("../../app.global");
var TimesheetapprovalComponent = /** @class */ (function () {
    function TimesheetapprovalComponent(dataset, modalService, customer) {
        this.dataset = dataset;
        this.modalService = modalService;
        this.customer = customer;
        this.monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];
    }
    TimesheetapprovalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        this.hstatus = '';
        this.month = (new Date()).getMonth();
        this.year = (new Date()).getFullYear();
        this.yearmonth = this.monthNames[this.month] + ' ' + (new Date()).getFullYear();
        this.classactive = ((new Date()).getMonth() + 1).toString();
        var dd = { j: 1 };
        this.customer.getchildemployee(dd).subscribe(function (data) {
            _this.employeelist = data;
        });
        this.bindalldata();
    };
    TimesheetapprovalComponent.prototype.onChange = function (newValue) {
        this.employeeid = newValue;
        this.bindalldata();
    };
    TimesheetapprovalComponent.prototype.onChangestatus = function (newValue) {
        this.hstatus = newValue;
        this.bindalldata();
    };
    TimesheetapprovalComponent.prototype.bindalldata = function () {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'month': this.month, 'year': this.year, 'employeeid': this.employeeid, 'hstatus': this.hstatus };
        this.dataset.getemployeeworklistreportrequest(data).subscribe(function (data) {
            // console.log(data);
            _this.record = data.length;
            _this.timesheetdata = data;
        });
    };
    TimesheetapprovalComponent.prototype.onStatus = function (id, status) {
        var _this = this;
        var deleteData = { 'id': id, 'hstatus': status, 'userid': this.Userid, 'statusdate': new Date() };
        var msg = '';
        if (status == 2) {
            msg = 'Are you sure you want to approve?';
        }
        else {
            msg = 'Are you sure you want to reject?';
        }
        if (confirm(msg)) {
            this.dataset.statusactivity(deleteData).subscribe(function (data) {
                _this.bindalldata();
            });
        }
    };
    TimesheetapprovalComponent.prototype.onyearSubmit = function (year) {
        this.year = year;
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + this.year; // (new Date()).getFullYear();
        this.bindalldata();
        // this.modalService.close(id);
    };
    TimesheetapprovalComponent.prototype.yearpop = function (id) {
        this.modalService.open(id);
    };
    TimesheetapprovalComponent.prototype.getDataByMonth = function (mon) {
        this.classactive = mon;
        this.month = mon - 1;
        this.yearmonth = this.monthNames[this.month] + ' ' + this.year;
        this.bindalldata();
        // const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
        // this.dataset.getemployeeworklistreport(data).subscribe((data: any) => {
        //   console
        //  this.timesheetdata = data;
        // });
    };
    TimesheetapprovalComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    TimesheetapprovalComponent.prototype.getDataByYear = function (yea) {
        this.year = yea;
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + yea;
        this.bindalldata();
        // const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
        // this.dataset.getemployeeworklistreport(data).subscribe((data: any) => {
        //  this.timesheetdata = data;
        // });
    };
    return TimesheetapprovalComponent;
}());
exports.TimesheetapprovalComponent = TimesheetapprovalComponent;
