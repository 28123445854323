"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var http_1 = require("@angular/common/http");
var app_global_1 = require("../app.global");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./common.service");
var PermissionService = /** @class */ (function () {
    function PermissionService(http, commonService) {
        this.http = http;
        this.commonService = commonService;
        this.rootUrl = app_global_1.AppGlobals.API_ENDPOINT + '/menumaster';
    }
    PermissionService.prototype.bindallmenu = function () {
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this.http.post(this.rootUrl, { headers: reqHeader });
        // return this.commonService.formpost('/menumaster');
    };
    PermissionService.prototype.updateemployeepermission = function (value) {
        return this.commonService.formpost('/employee/permission/updateemployeepermission', value);
    };
    PermissionService.prototype.updateisadmin = function (value) {
        return this.commonService.formpost('/company/updateisadmin', value);
    };
    PermissionService.ngInjectableDef = i0.defineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.inject(i1.HttpClient), i0.inject(i2.CommonService)); }, token: PermissionService, providedIn: "root" });
    return PermissionService;
}());
exports.PermissionService = PermissionService;
