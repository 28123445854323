import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EmployeeService } from '../../services/employee.service';
import { ModalService } from '../../_services/modal.service';
import { CountryService } from '../../services/country.service';
import { PermissionService } from '../../services/permission.service';
import { AlertService } from '../../services/alert.service';
import { ChecksystemService } from '../../services/checksystem.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Routes, RouterModule } from '@angular/router';
import { DbsService } from '../../services/dbs.service';
import { UserService } from '../../services/user.service';
import { Profile } from '../../models/overview/profile.model';
import { CommonService } from '../../services/common.service';

import { Address } from '../../models/address/address.model';


@Component({
  selector: 'app-dbs',
  templateUrl: './dbs.component.html',
  styleUrls: ['./dbs.component.css']
})
export class DbsComponent implements OnInit {
  employeeForm: FormGroup;
  data: any;
  datalist:any;
  submitted: any;
  employeeid:any;
  Userid: any;
  th = [];
  options = [];
  ismail: any;
  emailid:any;
  empid:number=0;
  emailexist = false;
  public searchText: string;
  permissionstring: any;
  mindob = new Date();
  maxdob = new Date();
  phonecheck: any;
  empidexist:boolean = false;
  disableBtn: any;
  permission: any;
  empperm: any;
  defaultVal: any = "";
  record:number;
  dbstype:any;
  items: Array<any>;
  h1tag: any = "";
  remainingcheck: any;
  dbsId: any;
  pageOfItems: Array<any>;
  countrylist: any;
  overviewData: any;
  dbsid: any;
  displayFn:any;
  samecompany:any;
  activePage:number = 0;    
  statelist: any[];
  addresslistDetail: any;
  inputbutton:any;
  tp:any; 
  employeedata:any;
  stage1:any;

  displayActivePage(activePageNumber:number){  
    this.activePage = activePageNumber  
  }  
  constructor(
    private userService: UserService, 
    private employeeService: EmployeeService, 
    private formBuilder: FormBuilder, 
    private commonService: CommonService,
    private modalService: ModalService, 
    private country: CountryService, 
    private permissiondetails: PermissionService,
    private alerts: AlertService,    
    private check: ChecksystemService,    
    private router : Router,    
    private route: ActivatedRoute,
    private dbsService: DbsService
  ) { 
    route.params.subscribe(params => {
      this.dbstype = params['type'];
      if(this.dbstype == 'basic')
      {
        this.dbsId = 2;
        this.h1tag = "Basic DBS";
      }
      else if(this.dbstype == 'standard')
      {
        this.dbsId = 3;
        this.h1tag = "Standard DBS";
      }
      else if(this.dbstype == 'enhanced')
      {
        this.dbsId = 1;
        this.h1tag = "Enhanced DBS";
      }  
      else if(this.dbstype == 'access-ni-basic')
      {
        this.dbsId = 14;
        this.h1tag = "AccessNI Basic DBS";
      }
      else if(this.dbstype == 'access-ni-standard')
      {
        this.dbsId = 15;
        this.h1tag = "AccessNI Standard DBS";
      }  
      else if(this.dbstype == 'access-ni-enhanced')
      {
        this.dbsId = 16;
        this.h1tag = "AccessNI Enhanced DBS";
      }
      else if(this.dbstype == 'scotland-basic-disclosure')
      {
        this.dbsId = 17;
        this.h1tag = "Scotland Basic Disclosure DBS";
      }  
      else if(this.dbstype == 'scotland-standard-disclosure')
      {
        this.dbsId = 18;
        this.h1tag = "Scotland Standard Disclosure DBS";
      }
      else if(this.dbstype == 'scotland-enhanced-disclosure')
      {
        this.dbsId = 19;
        this.h1tag = "Scotland Enhanced Disclosure DBS";
      }  
      else if(this.dbstype == 'social-media')
      {
        this.dbsId = 21;
        this.h1tag ='Social Media';        
      } 

      this.getRemainingChecks();    
    });
  }

  ngOnInit() {
    
    this.addresslistDetail = new Address();  
    this.Userid = localStorage.getItem('userid');

    this.inputbutton ='Send Request';
    this.empid =0;
    this.tp = 'first';
    this.empidexist = false;
   
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    //console.log(permission[0]['m114'])
    if((this.dbsId == 2 && this.permission['m109'] < 1) || (this.dbsId == 3 && this.permission['m114'] < 1) || (this.dbsId == 1 && this.permission['m119'] < 1))
    {
      this.router.navigate(['/permission-denied']);
    }

    this.check.getbillinginformation({ 'serviceid': this.dbsId }).subscribe((remaindata: any) => {
      
      if(remaindata.totalcheck == 0)
      {
        this.stage1 =2;
      }
      if(remaindata.totalcheck >0 && remaindata.remiancheck == 0)
      {
        this.stage1 =2;
      }
      if(remaindata.totalcheck >0 && remaindata.remiancheck > 0)
      {
        this.stage1 =3;
      }
      this.remainingcheck = remaindata;
    });

    this.getEmployees();
    this.formreset();

    this.overviewData = new Profile();
    this.overviewData.phonenumber = "+44";

    this.mindob.setDate(this.mindob.getDate() - 23741);
    this.maxdob.setDate(this.maxdob.getDate() - 574);

    this.permissiondetails.bindallmenu().subscribe((menudata: any) => {
      var permissionstring = "[{";
      var perarray = [];
      menudata.forEach(function (valuemenu) {
        perarray.push('"' + valuemenu.clname + '": 0');
      });
      
      var perstr = perarray.join();
      permissionstring = permissionstring + perstr + "}]";
      this.permissionstring = permissionstring;
    });
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
  
  }

  get f() { return this.employeeForm.controls; }

  getEmployees()
  {
    this.employeeService.getlistofemployeeForCheck().subscribe((employeedata: any) => {        
      console.log(employeedata);
      this.record = employeedata.length;
        this.datalist = employeedata;
        this.pageOfItems = employeedata;
      this.items =employeedata;
      if(this.record < 30){
        this.pageOfItems = employeedata;
      }
      else{
           this.items =employeedata;
      }     
    });
  }

  checkcount:any;
  getRemainingChecks()
  {
   var ts  = { 'serviceid':  this.dbsId };   
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      this.checkcount = remaindata;
    });

  }

  onAddEmployeeSubmit(employeeform1: any, modelid: any) {
    this.submitted = true;
    
   // console.log(employeeform1);
    if (this.employeeForm.invalid) {
      return;
    }
    //return;
   // console.log(this.empid)
    employeeform1.id = this.Userid;
    employeeform1.permission = this.permissionstring;

    
    this.userService.isEmailRegisterd(employeeform1.emailid).subscribe((data: any) => {
      if (data.sussess == 'success' && this.empid ==0) {
        this.emailexist = true; 
        this.disableBtn = true;
      }
      else if(this.empidexist == false && this.empid ==0) {       
          this.disableBtn = false;
          this.employeeService.checkemployeecreate(employeeform1).subscribe((dataw: any) => {
            if(dataw.susses == 'true'){
              this.modalService.close(modelid);
              this.empidexist = true;
              this.tp = 'second';this.getRemainingChecks(); 
              // this.modalService.open('sendrequestcharge');
            //  this.sendrequest(dataw.employeemasterid, 'sendrequestcharge');
              this.employeeid = dataw.employeemasterid;
              this.getEmployees();
          }
                
          else
          { 
            this.alerts.error('Somthing went wrong. please try again after sometime');}
          });
      } else if(this.empidexist == false && this.empid !=0)
      {
        this.tp = 'second';
      //  console.log('dfdadfad')
      }   
    });
  }
  
  
  onSearchChange(searchValue) {
    if (searchValue.length > 4) {
      var newArray = [];
      this.commonService.getAddress(searchValue).subscribe((data: any) => {
        var newdata: any;
        newdata = data;
        newdata.addresses.forEach(function (value) {
          newArray.push(value + "," + searchValue);
        });
        this.options = newArray;
      });
    }
    else {
      this.options = [];
    }
  }

  updatecode(evt: MatOptionSelectionChange, response) {
    res = [];
    if (evt.source.selected) {
      var res = response.split(",");
      this.addresslistDetail.address1 = res[0];
      this.addresslistDetail.address2 = res[1];
      this.addresslistDetail.statename = res[6];
      this.addresslistDetail.cityname = res[5];
     
      const data = { 'id': this.addresslistDetail.countryid };
      this.country.getstatelist(data).subscribe((state: any) => {
        for (var i = 0; i < state.length; i++) {
          if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
            var inde = state[i]['stateid'];
          }
        }
        this.addresslistDetail.stateid = inde;
        this.statelist = state;
      });
      
       this.addresslistDetail.pincode = res[7];
    }
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  
  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
     
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  onFocusOutEvent(event: any)
  {
    this.userService.isEmailRegisterdCheckForAdmin(event.target.value).subscribe((data: any) => {
      if (data.sussess == 'success') {
        this.emailexist = true; 
        this.disableBtn = false;
        this.employeeid = data.employeeid;
        if(data.samecompany)
        {
          this.samecompany = true;
        }
        // this.isFocus = true;
        // this.modalService.open('alreadyadd-request');
      }else
      {
        this.disableBtn = true;        
      }      
    });    
  }
  
  focusFunction() {
    this.emailexist = false;
  }

  closeModal(id: string) {
    this.empid =0;
    this.tp = 'first';
    this.modalService.close(id);
  }

  sendrequestcharge(employee, id)
  {

    console.log('Full Form Data Master system', employee);


    var ts = {employeeid: this.empid, requestid: this.dbsId, fname: employee.fname,mname: employee.mname, lname: employee.lname , address1: employee.address1, address2: employee.address2, cityname: employee.cityname, statename: employee.statename, pincode: employee.pincode, countryid: employee.countryid}



    this.dbsService.createdbs(ts).subscribe((remaindata: any) => {       
        this.check.getbillinginformation({ 'serviceid': this.dbsId }).subscribe((remaindata: any) => { 
          this.remainingcheck = remaindata;
        
          this.inputbutton ='Send Request';
          this.empid =0;
          this.tp = 'first';
          this.empidexist = false;


          this.getRemainingChecks(); 
          this.formreset();
          this.employeeForm.reset();
                    
          this.alerts.success(this.h1tag +' Application Request Sent Successfully');
          this.modalService.close(id);
        });
    });
  }

  updaterequestcurrent(overview)
  {


    if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
    {     
      this.modalService.open('upgrade-plan');
    }
    else
    { 

     
      this.overviewData = new Profile();
      this.overviewData = overview;
      this.addresslistDetail = new Address();
      this.addresslistDetail = overview.permanent;
      this.empid = overview.employeemasterid;      

      if(this.dbstype == 'basic')
      {
        this.inputbutton ='Send Basic DBS Applicant';        
      }
      else if(this.dbstype == 'standard')
      {
        this.inputbutton ='Send Standard DBS Applicant';        
      }
      else if(this.dbstype == 'enhanced')
      {
        this.inputbutton ='Send Enhanced DBS Applicant';        
      } 
      else if(this.dbstype == 'social-media')
      {
        this.inputbutton ='Send Social Media Request';        
      } 
           
      this.modalService.open('add-new-applicant');

    }
  }

  sendrequest(employeeid, id)
  {
    if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
    {
     
      this.modalService.open('upgrade-plan');
    }
    else
    { 
      this.tp = 'second';
      this.modalService.open(id);
      this.employeedata = employeeid;
      this.empid = employeeid.employeemasterid;
    }
  }


  uploadByAdmin(dbs, model_id)
  {
    if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
    {
      this.modalService.open('upgrade-plan');
    }
    else
    {   
      this.empid =0;
      this.disableBtn = false;

      this.overviewData = new Profile();
      this.overviewData.phonenumber = "+44";        

      this.addresslistDetail = new Address();     
      this.addresslistDetail.employeeaddresdeatilid = "0";
      this.addresslistDetail.countryid = "70";     
      this.addresslistDetail.iscurrent = '0';     
      this.phonecheck = "";
      
      this.formreset();
      
      this.modalService.open(model_id);  
      // this.router.navigate(['/check-add-applicant', this.dbstype+'-dbs']);   
    }
  }

  formreset()
  {
    
    this.employeeForm = this.formBuilder.group({
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      empid: [''],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: ['', Validators.compose([Validators.required])],
      address1: ['', Validators.required],
      address2: [''],
      cityname: ['', Validators.compose([Validators.required,])],
      pincode: ['', Validators.required],
      countryid: ['', Validators.required],
      statename: ['', Validators.compose([Validators.required])]
    });
  }

}
