import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-ratecompetencies',
  templateUrl: './ratecompetencies.component.html',
  styleUrls: ['./ratecompetencies.component.css']
})
export class RatecompetenciesComponent implements OnInit {
  starsCount: number;
  record:any;
  constructor() { }
  
  ngOnInit() {
  }

}
