<app-header>
</app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <a [routerLink]="['/company-advert-search']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Business Credit Check</a>

            <div class="clearfix"></div>
            <div class="tabs__content">
                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Search History</h5>
                            </div>

                            <!-- <div class="col-lg-6">
                              <div class="input-group">
                                  <input placeholder="Search.." class="form-control">
                              </div>

                          </div> -->
                        </div>

                        <!-- <ng-container *ngIf="( searchresult | filter: {searchcondition: searchText, companyname: searchText }) as pageOfItems">  -->


                        <div *ngFor="let sr of searchresult; let i=index;">



                        <div class="credit_data_showlog " >
                            <div class="job_list_section visa_request_section">

                              <div class="job_detils_date width13">
                                <p class="job_address">Company Code</p>
                                <p class="job_profile">{{ sr.searchcondition }}</p>
                                <!-- <p class="job_profile" *ngIf="searchresultnew.postcode"> N/A</p> -->
                              </div>

                                <div class="job_detils_date width50">
                                    <div class="visa_user_details ">
                                        <p class="job_address">Company Name</p>

                                        <h4 class="job_profile">{{ (sr.searchresult.body.report && sr.searchresult.body.report.companySummary && sr.searchresult.body.report.companySummary.businessName)?sr.searchresult.body.report.companySummary.businessName:'-' }}</h4>
                                        <!-- <p class="phone text-success"> <b>Male</b></p>
                                    <p class="ninumber text-primary">Id - 5555 </p> -->
                                        <!-- <span class="badge badge-danger">Indian</span> -->
                                    </div>
                                </div>

                                <div class="job_detils_date width30">
                                  <button type="button" *ngIf="sr.searchresult.body.report" class="btn btn-primary com_btn" [innerHtml]="downloadtext[i]" (click)="downloadcompanydata(sr.searchcondition,i)"></button>
                                    <a type="submit" *ngIf="sr.searchresult.body.report" class="btn btn-primary com_btn" [routerLink]="['/company-advert-search', sr.searchcondition ]">View Details</a>
                                </div>


                            </div>

                        </div>
                        </div>
                        <!-- </ng-container> -->





                        <div class="row" *ngIf="searchresult">
                            <div class="col-lg-12 text-left" *ngIf="searchresult.length == 0">
                                <div class="billed-to">No Record Found </div>
                            </div>
                        </div>

                    </div>


                    <!-- <p class="job_profile"  *ngIf="!searchresult"> N/A</p> -->

                        <div class="browser-screen-loading-content" *ngIf="!searchresult" >
                            <div class="loading-dots dark-gray">
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                            </div>
                            <p>Loading</p>
                        </div>



                </div>
            </div>
        </div>
    </div>
</div>
