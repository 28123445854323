"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["jw-modal[_ngcontent-%COMP%]   .jw-modal[_ngcontent-%COMP%]{top:30%!important;max-width:440px!important}\n/*# sourceMappingURL=plandetails.component.css.map*/"];
exports.styles = styles;
