import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, setYear, setMonth } from 'date-fns';
import { Subject } from 'rxjs/Subject';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { ExpenseService } from '../services/expense.service';
import { Expense } from '../models/expense/expense.model';
import { LeaveService } from '../services/leave.service';
import { ModalService } from '../_services/modal.service';
import { UploadFileService } from './../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomerService} from '../services/customer.service';
import { Router } from '@angular/router';
import { AppGlobals } from '../app.global';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.css']
})

export class ExpenseComponent {

  constructor( 
    private customer: CustomerService,
    private router: Router, 
    private modal: NgbModal, 
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer, 
    private leaveService: LeaveService, 
    private data: ExpenseService,  
    private uploadService: UploadFileService,
    private formBuilder: FormBuilder, 
    private modalService: ModalService
  ) { }

  Userid: any;
  event: CalendarEvent;
  expense: any;
  selectedFiles: FileList;
  headerdate: any;
  expenseformGroup: FormGroup;
  events: CalendarEvent[] = [];
  expensetypelist: any[];
  projectlist: any[];
  paidbylist: any[];
  dayexpense: any[];
  classactive: string;
  year: number;
  submitted = false;
  myfile: any;
  file: any;
  imgexpencesrc:any;
  iframeexpensesrc:any;
  disableBtn: any;
  leaveavailabledate = new Date();
  filesizeerror: any;
  fileformaterror: any;
  formattedMessage: any;
  filename: any;
  linemanager: any;
  editMsg: any = "";
  currencies: any;
  yearList: any;
  findLeave: any = false;

  ngOnInit() {
    this.currencies = AppGlobals.CURRENCIES;
    this.yearList = AppGlobals.YEAR_VALUES;
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }


    // this.leaveavailabledate.setDate(this.leaveavailabledate.getDate() - 31);
    this.leaveavailabledate.setDate(this.leaveavailabledate.getDate());
    this.filename = 'Choose file';
    this.classactive = (new Date()).getMonth().toString();
    this.year = (new Date()).getFullYear();
    this.formvalidation();
    this.bindalldata();
  }

  formvalidation() {
    this.expenseformGroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      projectid: ['', Validators.required],
      amountpaid: ['', Validators.compose([Validators.required,  Validators.pattern(/^[.\d]+$/),Validators.max(99999), Validators.min(1)])],
      paidby: ['', Validators.compose([Validators.required, Validators.min(1)])],
      expensetypeid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      locationname: ['', Validators.required],
      currency: ['', Validators.required],
      start: [''],
      end: [''],
      expenseremark: ['', Validators.required],
      expenseremarksid: ['', Validators.required]
    });
  }

  fromreset() {
    this.expenseformGroup = this.formBuilder.group({
      id: [''],
      employeeid: [''],
      projectid: [''],
      amountpaid: [''],
      paidby: [''],
      expensetypeid: [''],
      locationname: [''],
      currency: [''],
      start: [''],
      end: [''],
      expenseremark: [''],
      expenseremarksid: ['']
    });
  }

  bindalldata() {

    this.Userid = localStorage.getItem('userid');
    const sstatustype = { 'status': true, 'id': this.Userid } 
    this.data.getexpensetypelist(sstatustype).subscribe((expenselist: any) => {
      this.expensetypelist = expenselist;
    });

    
    this.data.getemployeeexpenselist().subscribe((data: any) => {
      data.forEach(element => {
        this.events.push({
          id: element.employeeexpenseapplyid,
          title: element.amountpaid + '-' + element.project.projectname,
          start: new Date(element.startdate),
          end: new Date(element.enddate),
          color: { primary: element.hcolor, secondary: element.fcolor },
          draggable: true,
          allDay: element.edit,
          resizable: {
            beforeStart: true,
            afterEnd: true
          }
        });
        this.refresh.next();
      });
    });

   
    this.data.getprojectlist(sstatustype).subscribe((projectlist: any) => {
      this.projectlist = projectlist;
    });
  }

  @ViewChild('modalContent')
  modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = true;
  get f() {
    return this.expenseformGroup.controls;
  }
  onSubmit(expense, id) {
    this.submitted = true;
    this.editMsg = "";
    if (this.expenseformGroup.invalid) {
      return;
    }
    
    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.name.split('.');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
        this.fileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      expense.imagename = imagepath;
    }
    else {
      expense.imagename = this.expense.imagename;
    }
    this.disableBtn = false;
    this.data.expenseapplyupdate(expense).subscribe((data: any) => {
      this.events = [];
      this.filename = 'Choose file';this.selectedFiles = null;
      document.getElementById('closeModal').click();
      this.bindalldata();
      this.formvalidation();
    });
  }

  selectFile(event) {
    this.filesizeerror = false;
      this.fileformaterror = false;
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = files[0];
    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.name.split('.');
      console.log(this.file);
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
        this.filesizeerror = false;
        this.fileformaterror = true;
        return;
      }else {
        this.filesizeerror = false;
        this.fileformaterror = false;
      }     
    }
    else {
      this.filesizeerror = false;
      this.fileformaterror = false;
    }    
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  onyearSubmit(leave) {
    this.year = leave;
    var date = this.viewDate;
    date = setYear(date, this.year);
    this.viewDate = date;
    // this.modalService.close(id);
  }

  addMonthsOfYear(inc) {
    this.classactive = inc;
    var date = this.viewDate;
    date = setMonth(date, inc);
    date = setYear(date, this.year);
    this.viewDate = date;
    this.events = [];
    this.bindalldata();
  }

  formatDate(date) {
    var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  openlinemanagerModal(id: string) 
  { 
    this.customer.getLineEmployeeDetails().subscribe((lsmanager: any) => {
      this.linemanager = lsmanager;
    });
    
    this.modalService.open(id);
  }

  onDelete(id: number) {
    const deleteData = { 'id': id };
    if (confirm("Are you sure you want to delete?")) {
      this.data.deleteexpense(deleteData).subscribe((data: any) => {
        this.events = [];
        document.getElementById('closeModal').click();
        this.bindalldata();
      });
    }
  }

  addNewEvent(action: string, event: CalendarEvent): void {
    this.editMsg = "";
    
    this.submitted = false;
    this.disableBtn = true;this.selectedFiles = null;
    this.expense = new Expense();
    this.Userid = localStorage.getItem('userid');
    this.expense.employeeid = this.Userid;
    this.expense.start = new Date();
    this.expense.end = new Date();
    this.headerdate = this.formatDate(new Date());
    this.expense.currency = 'GBP';
    this.expense.employeeexpenseapplyid = '0';
    this.expense.expenseremarksid = '0';
    this.modalData = { action, event };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(action: string, event: CalendarEvent, start: string): void {
    this.editMsg = "";
    this.findLeave = false;
    this.Userid = localStorage.getItem('userid');
    const databydate = { 'id': this.Userid, 'exid': this.datePipe.transform(new Date(start),"dd MMM yyyy") };
    this.leaveService.checkleaveondate(databydate).subscribe((userdata: any) => {
      if(userdata.length)
      {
        this.findLeave = true;
      }
    });
    this.submitted = false;
    this.disableBtn = true;this.selectedFiles = null;
    this.expense = new Expense();
    this.expense.employeeid = this.Userid;
    this.expense.start = new Date(start);
    this.expense.end = new Date(start);
    this.headerdate = this.formatDate(new Date(start));
    this.expense.currency = 'GBP';
    this.expense.employeeexpenseapplyid = '0';
    this.expense.expenseremarksid = '0';
    this.modalData = { action, event };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  editEvent(action: string, event: CalendarEvent) {
    this.submitted = false;
    this.findLeave = false;
    this.editMsg = "";
    this.disableBtn = true;
    this.expense = new Expense();
    this.Userid = localStorage.getItem('userid');
    this.expense.employeeid = this.Userid;
    var employeeexpenseapplyid = event.id;
    this.data.getexpensedata({ 'id': employeeexpenseapplyid }).subscribe((data: any) => {
      this.expense.title = data.title;
      this.expense.projectid = data.projectid;
      this.expense.expensetypeid = data.expensetypeid;
      this.expense.amountpaid = data.amountpaid;
      this.expense.start = new Date(data.startdate);
      this.expense.end = new Date(data.enddate);
      this.expense.employeeexpenseapplyid = data.employeeexpenseapplyid;
      this.headerdate = this.formatDate(new Date(data.startdate));
      this.expense.locationname = data.locationname;
      this.expense.expenseremarksid = data.expenseremarksid;
      this.expense.expenseremark = data.remark.expenseremark;
      this.expense.hourtypeid = data.hourtypeid;
      this.expense.currency = data.currency;
      this.expense.paidby = data.paidby;
      this.expense.imagename = data.imagename;
      this.expense.hstatus = data.hstatus;
      if(data.hstatus != 1)
      {
        this.disableBtn = false;
        this.editMsg = "You can not edit this expense";
      }
      const databydate = { 'id': this.Userid, 'exid': this.datePipe.transform(new Date(data.startdate),"dd MMM yyyy") };
      this.leaveService.checkleaveondate(databydate).subscribe((userdata: any) => {
        if(userdata.length)
        {
          this.findLeave = true;
        }
      });
    });
    this.modalData = { action, event };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  editEventOfList(action: string, id: any, alleventpopid: any, event: CalendarEvent) {
    this.submitted = false;
    this.editMsg = "";
    this.disableBtn = true;
    this.expense = new Expense();
    this.Userid = localStorage.getItem('userid');
    this.expense.employeeid = this.Userid;
    var employeeexpenseapplyid = id;
    this.data.getexpensedata({ 'id': employeeexpenseapplyid }).subscribe((data: any) => 
    {
      this.expense.title = data.title;
      this.expense.projectid = data.projectid;
      this.expense.expensetypeid = data.expensetypeid;
      this.expense.amountpaid = data.amountpaid;
      this.expense.start = new Date(data.startdate);
      this.expense.end = new Date(data.enddate);
      this.expense.employeeexpenseapplyid = data.employeeexpenseapplyid;
      this.headerdate = this.formatDate(new Date(data.startdate));
      this.expense.locationname = data.locationname;
      this.expense.expenseremarksid = data.expenseremarksid;
      this.expense.expenseremark = data.remark.expenseremark;
      this.expense.currency = data.currency;
      this.expense.hourtype = data.hourtypeid;
      this.expense.paidby = data.paidby;
      this.expense.hstatus = data.hstatus;
    });
    this.closeModal(alleventpopid);
    this.modalData = { action, event };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  showAllEvents(id: string, exid: number) {
    this.Userid = localStorage.getItem('userid');
    const databydate = { 'id': this.Userid, 'exid': exid };
    this.data.getexpensedatabydate(databydate).subscribe((userdata: any) => {
      this.dayexpense = userdata;
    });
    this.modalService.open(id);
  }
  
  openVisaExpenceModal3(id: string, data: any) 
  {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgexpencesrc = true;
      this.iframeexpensesrc = data;
    }
    else {
      this.imgexpencesrc = false;
      this.iframeexpensesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
  yearpop(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
}