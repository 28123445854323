<app-header>
</app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
        <a [routerLink]="['/ai-dashboard']" *ngIf="ctype ==0" class="backlist  mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to AI Dashboard</a>
        <a [routerLink]="['/check-identity-verification']" *ngIf="ctype ==1" class="backlist  mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Identity Check</a>
        <div class="clearfix"></div>
        <div class="aligncenter width60 mrled">
            <p class="mb-0">There are no credits available to perform this action. Please add more credits.</p>
            <a class="btn btn-primary com_btn" [routerLink]="['/check-add-credit']">Buy Now</a>
        </div>
        
    </div>
    </div>
</div>
<div class="aiverification_section aimain">
    <div class="ai_content_section">
        <div class="ai_content">
            <div class="ai_wizard">
                <app-passportaiwizard></app-passportaiwizard>
                <div class="content clearfix">
                    <div class="admin_pro_section aidetail mr-4">
                        <div class="cseec">
                            <div class="admin_com_logo" >
                                <img src="{{ passportdetails.employee?.imagename }}">
                            </div>
                        </div>
                        <div class="albox width100">
                            <div class="aliii">
                                <div class="ai_admin_details width28">
                                    <span class="subheading">Name</span>
                                    <span class="heading">{{ passportdetails.employee?.fname }} {{ passportdetails.employee?.mname }} {{ passportdetails.employee?.lname }}</span>
                                </div>
                                <div class="ai_admin_details width40">
                                    <span class="subheading">Email</span>
                                    <span class="heading">{{ passportdetails.employee?.emailid }}</span>
                                </div>
                                <div class="ai_admin_details width22">
                                    <span class="subheading">Phone</span>
                                    <span class="heading">{{ passportdetails.employee?.phonenumber }}</span>
                                </div>
                            </div>
                            <div class="aliii">
                                <div class="ai_admin_details width28">
                                    <span class="subheading">NI Number</span>
                                    <span class="heading">{{ passportdetails.employee?.ninumber }}</span>
                                </div>
                                <div class="ai_admin_details width40">
                                    <span class="subheading">Employee ID</span>
                                    <span class="heading">{{ passportdetails.employee?.employeeid }}</span>
                                </div>
                                <div class="ai_admin_details width22">
                                    <span class="subheading">Nationality</span>
                                    <span class="heading badge badge-danger">{{ passportdetails.employee?.nationality?.countryname }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="btnai">
                            <button type="text" class="btn btn-primary">EMP - YES58485</button>
                            <button type="text" class="btn btn-danger">IN - YES58485</button>
                        </div> -->
                    </div>

                    <div class="aidetail mr-4">

                        <div class="white_box_shadow pro_plan_details admin_pro_footer width100">
                            <h4>Passport Detail <span>{{ passportdetails.passportnumber }}</span> </h4>
                            <div class="pro_footer_content mb-4">
                                <label><i  aria-hidden="true" class="fa fa-user-o"></i>Nationality</label>
    
                                <p class="ng-star-inserted">{{ passportdetails.nationality?.countryname}}  </p>
                            </div>
                            <div class="pro_footer_content mb-4">
                                <label><i  aria-hidden="true" class="fa fa-calendar"></i>Place of Issue</label>
                                <p>{{ passportdetails.placeofissue}}</p>
                            </div>
                            <div class="pro_footer_content">
                                <label><i aria-hidden="true" class="fa fa-calendar"></i>Issue Date</label>
                                <p> {{ passportdetails.passportissue | date: 'dd MMM yyyy' }} </p>
                            </div>
                            <div class="pro_footer_content">
                                <label><i aria-hidden="true" class="fa fa-calendar"></i>Expiry Date</label>
                                <p> {{ passportdetails.passportexpiry | date: 'dd MMM yyyy' }} </p>
                            </div>
                        </div>
                    </div>
                    <div class="white_box_shadow pro_plan_details admin_pro_footer width50 visaimg rmovepadd " style="text-align: center;display: inherit;">
                        <img src="{{ passportdetails.passportdocname }}" class="" style="height: 220px;">
                        <div class="overlay_bg">
                            <input type="file" accept="image/*" (change)="selectFile($event,0)" style="display:none;" #file1>
                            <button type="submit" class="btn btn-primary com_btn width100" (click)="file1.click()">Change Passport</button>
                        </div>
                    </div> 
                    <div class="white_box_shadow pro_plan_details admin_pro_footer width50 visaimg rmovepadd " style="text-align: center;display: inherit;">
                        <img src="{{ passportdetails.passportbackdocname }}" class="" style="height: 220px;">
                        <div class="overlay_bg">
                            <input type="file" accept="image/*" (change)="selectFile($event,1)" style="display:none;" #file>
                            <button type="submit" class="btn btn-primary com_btn width100" (click)="file.click()">Change Passport</button>
                        </div>
                    </div> 
                    <div class="clearfix"></div>
                    <div class="width100 mb-2" >
                        <div class="text-left">
                            <p class="reqinfo">* Acceptable file type: JPEG/PNG, File size: Max 3MB and >500 pixel & < 3000 pixel.
                            </p>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="ai_wizard_btn mr-4">
                        <!-- <button type="submit" class="btn btn-danger com_btn float-left">Previous</button> -->
                        <a type="submit" class="btn btn-secondary com_btn float-right"  [routerLink]="['/passportaifacedetails',employeevisadeatilid]">Next</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>