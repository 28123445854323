"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var customer_service_1 = require("../../services/customer.service");
var modal_service_1 = require("../../_services/modal.service");
var address_model_1 = require("../../models/address/address.model");
var country_service_1 = require("../../services/country.service");
var myaddress_service_1 = require("../../services/myaddress.service");
var forms_1 = require("@angular/forms");
var common_service_1 = require("./../../services/common.service");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var alert_service_1 = require("../../services/alert.service");
var AddressComponent = /** @class */ (function () {
    function AddressComponent(commonService, formBuilder, data, country, modalService, data2, alerts) {
        this.commonService = commonService;
        this.formBuilder = formBuilder;
        this.data = data;
        this.country = country;
        this.modalService = modalService;
        this.data2 = data2;
        this.alerts = alerts;
        this.address = new address_model_1.Address();
        this.options = [];
        this.defaultVal = "";
    }
    AddressComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.addpopForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            address1: ['', forms_1.Validators.required],
            address2: [''],
            cityname: ['', forms_1.Validators.compose([forms_1.Validators.required,])],
            stateid: [''],
            countryid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            pincode: ['', forms_1.Validators.compose([forms_1.Validators.required,])],
            mobilenumber: [''],
            // mobilenumber: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{10}")])],
            phonenumber: [''],
            // phonenumber: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{10}")])],
            statename: ['', forms_1.Validators.required]
        });
        this.addresslistDetail = new address_model_1.Address();
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.data.getAddress().subscribe(function (data) {
            // this.addresslistDetail.country_id = data.countryid;
            // console.log(data);
            _this.address = data;
            if (data) {
                _this.addresslistDetail.country_id = data.countryid;
            }
        });
        var data = { 'id': this.addresslistDetail.country_id };
        this.country.getstatelist(data).subscribe(function (state) {
            return _this.statelist = state;
        });
    };
    Object.defineProperty(AddressComponent.prototype, "f", {
        get: function () { return this.addpopForm.controls; },
        enumerable: true,
        configurable: true
    });
    AddressComponent.prototype.onSubmit = function (value3, id) {
        var _this = this;
        this.submitted = true;
        var pinc = value3.pincode.split(',');
        value3.pincode = pinc[pinc.length - 1];
        if (this.addpopForm.invalid) {
            return;
        }
        this.data2.myaddressdetailsUpdate(value3).subscribe(function (data) {
            _this.data.getAddress().subscribe(function (data) {
                _this.address = data;
            });
            //  this.msg = "success";
        });
        this.alerts.success('Address updated successfully', true);
        this.modalService.close(id);
    };
    AddressComponent.prototype.onSearchChange = function (searchValue) {
        var _this = this;
        if (searchValue.length > 4) {
            var newArray = [];
            this.commonService.getAddress(searchValue).subscribe(function (data) {
                var newdata;
                newdata = data;
                newdata.addresses.forEach(function (value) {
                    newArray.push(value + "," + searchValue);
                });
                _this.options = newArray;
            });
        }
        else {
            this.options = [];
        }
    };
    AddressComponent.prototype.displayFn = function (value) {
        if (value != null) {
            var res = value.split(",");
            if (res.length > 6) {
                return res[7];
            }
            else {
                return value;
            }
        }
    };
    AddressComponent.prototype.updatecode = function (evt, response) {
        res = [];
        if (evt.source.selected) {
            var res = response.split(",");
            this.addresslistDetail.address1 = res[0];
            this.addresslistDetail.address2 = res[1];
            this.addresslistDetail.cityname = res[5];
            this.addresslistDetail.pincode = res[7];
            this.addresslistDetail.statename = res[6];
            // const data = { 'id': this.addresslistDetail.countryid };
            // this.country.getstatelist(data).subscribe((state: any) => {
            //   for (var i = 0; i < state.length; i++) {
            //     if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
            //       var inde = state[i]['stateid'];
            //     }
            //   }
            //   this.addresslistDetail.stateid = inde;
            //   this.statelist = state;
            // });
        }
    };
    AddressComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    AddressComponent.prototype.onSelectCountry = function (country_id) {
        var _this = this;
        var data = { 'id': country_id };
        this.country.getstatelist(data).subscribe(function (state) {
            return _this.statelist = state;
        });
    };
    AddressComponent.prototype.openModal6 = function (id, data) {
        this.modalService.open(id);
        this.addresslistDetail = new address_model_1.Address();
        this.addresslistDetail = data;
    };
    AddressComponent.prototype.closeModal = function (id) {
        var _this = this;
        // this.addresslistDetail = new Address();
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.modalService.close(id);
    };
    AddressComponent.prototype.modalOpen = function (data) {
        this.addresslistDetail = new address_model_1.Address();
        this.addresslistDetail = data;
    };
    return AddressComponent;
}());
exports.AddressComponent = AddressComponent;
