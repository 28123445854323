"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var mypassport_service_1 = require("../services/mypassport.service");
var modal_service_1 = require("../_services/modal.service");
var upload_file_service_1 = require("./../services/upload-file.service");
var platform_browser_1 = require("@angular/platform-browser");
var passport_model_1 = require("../models/passport/passport.model");
var country_service_1 = require("../services/country.service");
var forms_1 = require("@angular/forms");
var alert_service_1 = require("../services/alert.service");
var MypassportComponent = /** @class */ (function () {
    function MypassportComponent(sanitizer, formBuilder, uploadService, data, country, modalService, alerts) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.data = data;
        this.country = country;
        this.modalService = modalService;
        this.alerts = alerts;
        this.defaultVal = "";
        this.passcount = 0;
        this.filterItems = [
            {
                name: 'All',
                id: 0,
                selected: true
            },
            {
                name: 'Accepted',
                id: 2,
                selected: false
            },
            {
                name: 'Rejected',
                id: 3,
                selected: false
            },
            {
                name: 'Pending Approval',
                id: 1,
                selected: false
            }
        ];
    }
    MypassportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filerequirederror = false;
        this.filebackrequirederror = false;
        this.passcount = 0;
        this.createformgroup();
        this.onChanges();
        this.imagefirst = '';
        this.imagesecond = '';
        // this.imgpasssrc =false;
        this.filename = 'Choose file';
        this.filebackname = 'Choose file';
        var id = localStorage.getItem('userToken');
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
        this.data.getpassportListnew(data).subscribe(function (data) {
            if (data == null)
                _this.passcount = 0;
            else
                _this.passcount = data.length;
            _this.passportprofilelist = data;
        });
        this.listcheck = this.filterItems;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.passportDetail = new passport_model_1.Passport();
        this.passportDetail.sstatus = 0;
    };
    MypassportComponent.prototype.createformgroup = function () {
        this.passformGroup = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            passportnumber: ['', forms_1.Validators.required],
            placeofissue: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            passportissue: ['', forms_1.Validators.required],
            passportexpiry: ['', forms_1.Validators.required],
            iscurrent: [''],
        });
    };
    MypassportComponent.prototype.onChangeDate = function (event) {
        // this.getData(newDate);
    };
    MypassportComponent.prototype.onChanges = function () {
        var _this = this;
        this.formattedMessage = '';
        this.passformGroup.valueChanges.subscribe(function (val) {
            _this.formattedMessage = 'Hello, My name is and my email is. I would like to tell you that .';
        });
    };
    MypassportComponent.prototype.onlistchange = function (type1, idval) {
        var _this = this;
        var sstatus;
        for (var _i = 0, _a = this.filterItems; _i < _a.length; _i++) {
            var c = _a[_i];
            if (c.id == idval)
                c.selected = true;
            else
                c.selected = false;
        }
        if (idval == 0 || type1 == false) {
            sstatus = [1, 2, 3];
        }
        else {
            sstatus = [idval];
        }
        var userid = localStorage.getItem('userid');
        var data = { 'id': userid, 'cstatus': sstatus };
        this.data.getpassportListnew(data).subscribe(function (data) {
            _this.passportprofilelist = data;
        });
    };
    Object.defineProperty(MypassportComponent.prototype, "f", {
        get: function () { return this.passformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    MypassportComponent.prototype.onSubmit = function (value3, id) {
        var _this = this;
        console.log(value3);
        // this.data.mypassportdetailsUpdatefile(value3).subscribe((data: any) => {
        //   console.log(data);
        // });
        this.submitted = true;
        if (this.passformGroup.invalid) {
            if (value3.id == '0') {
                if (!this.selectedFiles)
                    this.filerequirederror = true;
                // if(!this.selectedbackFiles)
                //   this.filebackrequirederror = true;
            }
            return;
        }
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagename = imagepath;
        }
        else {
            if (value3.id == '0') {
                this.filerequirederror = true;
                return;
            }
            value3.imagename = this.passportDetail.passportdocname;
        }
        if (this.selectedbackFiles) {
            this.file = this.selectedbackFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filebacksizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
                this.filebackformaterror = true;
                return;
            }
            var imagebackpath = this.uploadService.uploadfile(this.file);
            value3.imagebackname = imagebackpath;
        }
        else {
            // if(value3.id == '0'){
            //   this.filebackrequirederror =true;
            //   return;
            // }
            if (value3.id == '0') {
                value3.imagebackname = '';
            }
            else {
                value3.imagebackname = this.passportDetail.passportdocname;
            }
        }
        var error = 0;
        if (value3.passportnumber == '') {
            this.passnumbererror = false;
            error++;
        }
        if (value3.nationalityid == '') {
            this.nationalityerror = false;
            error++;
        }
        if (value3.passportissue == null) {
            this.passportdateoriginerror = false;
            error++;
        }
        if (error > 0) {
            return false;
        }
        var sd = new Date(value3.passportissue);
        value3.passportissue = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        var ed = new Date(value3.passportexpiry);
        value3.passportexpiry = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        this.data.mypassportdetailsUpdate(value3).subscribe(function (data) {
            var sstatus;
            for (var _i = 0, _a = _this.filterItems; _i < _a.length; _i++) {
                var c = _a[_i];
                if (c.selected == true) {
                    if (c.id == 0) {
                        sstatus = [1, 2, 3];
                    }
                    else {
                        sstatus = [c.id];
                    }
                }
            }
            var userid = localStorage.getItem('userid');
            var data1 = { 'id': userid, 'cstatus': sstatus };
            _this.data.getpassportListnew(data1).subscribe(function (data) {
                return _this.passportprofilelist = data;
            });
        });
        //this.alerts.setDefaults('timeout',0);
        this.messageSuccess = 'Passport updated successfully';
        this.alerts.success(this.messageSuccess, false);
        this.filename = "Choose file";
        this.modalService.close(id);
    };
    MypassportComponent.prototype.selectFile = function (event) {
        this.filesizeerror = false;
        this.fileformaterror = false;
        this.filerequirederror = false;
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
            this.fileformaterror = true;
        }
        else {
            this.selectedFiles = files[0];
        }
    };
    MypassportComponent.prototype.selectbackFile = function (event) {
        this.filebackrequirederror = false;
        this.filebacksizeerror = false;
        this.filebackformaterror = false;
        var files = event.target.files;
        this.filebackname = files[0].name;
        this.selectedbackFiles = this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filebacksizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
            this.filebackformaterror = true;
        }
        else {
            this.selectedbackFiles = files[0];
        }
    };
    MypassportComponent.prototype.onDelete = function (empid) {
        var _this = this;
        if (confirm("Are you sure you want to delete?")) {
            var data1 = { 'id': empid, 'userid': this.Userid };
            this.data.deletePassport(data1).subscribe(function (data) {
                var sstatus;
                for (var _i = 0, _a = _this.filterItems; _i < _a.length; _i++) {
                    var c = _a[_i];
                    if (c.selected == true) {
                        if (c.id == 0) {
                            sstatus = [1, 2, 3];
                        }
                        else {
                            sstatus = [c.id];
                        }
                    }
                }
                var userid = localStorage.getItem('userid');
                var data1 = { 'id': userid, 'cstatus': sstatus };
                _this.data.getpassportListnew(data1).subscribe(function (data) {
                    return _this.passportprofilelist = data;
                });
            });
        }
    };
    MypassportComponent.prototype.openModal2 = function (id) {
        this.filesizeerror = false;
        this.fileformaterror = false;
        this.filerequirederror = false;
        this.filebackrequirederror = false;
        this.filebacksizeerror = false;
        this.filebackformaterror = false;
        this.submitted = false;
        this.passportDetail = new passport_model_1.Passport();
        this.buttoninsert = "Submit";
        this.selectedFiles = null;
        this.imagefirst = '';
        this.imagesecond = '';
        this.selectedbackFiles = null;
        this.passportDetail.iscurrent = "0";
        this.filebackname = "Choose file";
        this.filename = "Choose file";
        this.Userid = localStorage.getItem('userid');
        this.passportDetail.employeeid = this.Userid;
        this.passportDetail.nationalityid = "70";
        this.passportDetail.employeepassdeatilid = "0";
        this.modalService.open(id);
    };
    MypassportComponent.prototype.openModal = function (id, data) {
        this.filesizeerror = false;
        this.fileformaterror = false;
        this.filerequirederror = false;
        this.filebackrequirederror = false;
        this.filebacksizeerror = false;
        this.filebackformaterror = false;
        this.submitted = false;
        this.imagefirst = '';
        this.imagesecond = '';
        this.selectedFiles = null;
        this.selectedbackFiles = null;
        this.passportDetail = new passport_model_1.Passport();
        this.filebackname = "Choose file";
        this.filename = "Choose file";
        this.buttoninsert = "Update";
        this.passportDetail = data;
        if (data.iscurrent) {
            this.passportDetail.iscurrent = "1";
        }
        else {
            this.passportDetail.iscurrent = "0";
        }
        this.modalService.open(id);
    };
    MypassportComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    MypassportComponent.prototype.openModalpassportopen3 = function (id, data, backimg) {
        this.iframepasssrc = '';
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgpassportsrc = true;
            this.iframepasssrc = data;
        }
        else {
            this.imgpassportsrc = false;
            this.iframepasssrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        if (backimg) {
            var fullpathArray = backimg.split('.');
            var typeoffile = fullpathArray[fullpathArray.length - 1];
            if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
                this.imgpassportbacksrc = true;
                this.iframepassportbacksrc = backimg;
            }
            else {
                this.imgpassportbacksrc = false;
                this.iframepassportbacksrc = this.getSafeUrl("https://docs.google.com/gview?url=" + backimg + "&embedded=true");
            }
        }
        else {
            this.imgpassportbacksrc = false;
            this.iframepassportbacksrc = '';
        }
        this.modalService.open(id);
    };
    MypassportComponent.prototype.closeModal = function (id) {
        var _this = this;
        // this.passportDetail = new Passport();
        var data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
        this.data.getpassportListnew(data).subscribe(function (data) {
            if (data == null)
                _this.passcount = 0;
            else
                _this.passcount = data.length;
            _this.passportprofilelist = data;
        });
        this.modalService.close(id);
    };
    MypassportComponent.prototype.modalOpen = function (data) {
        this.passportDetail = new passport_model_1.Passport();
        this.passportDetail = data;
    };
    return MypassportComponent;
}());
exports.MypassportComponent = MypassportComponent;
