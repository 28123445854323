<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__content">
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row row-sm topheader">                            
                                <div class="col-lg-5 text-right">
                                    <app-checkcountshow></app-checkcountshow>
                                </div>
                                <div class="col-lg-7 text-right">
                                    <div class="d-flex justify-content-end align-items-center">
                                        <button class="btn com_btn btn-danger small_btn" (click)="uploadByAdmin(emptype, 'add_new', 'add-new-applicant')"> + Add New Applicant </button>
                                        <!-- <a class="boxbtn mainheading_btn ml-1"  [routerLink]="['/check-add-applicant', emptype]" > + Add New Applicant </a>                                    -->
                                        <!-- <button class="btn com_btn btn-success" *ngIf="emptype == 'visa'" [routerLink]="['/check-visa-history']"  type="button">
                                            <span class="lnr lnr-history pr-1">                                        
                                            </span> Verification History 
                                        </button>
                                        <button class="btn com_btn btn-success" *ngIf="emptype == 'passport'" [routerLink]="['/check-passport-history']"  type="button">
                                            <span class="lnr lnr-history pr-1">                                         
                                            </span> Verification History 
                                        </button> -->
                                        <button class="btn com_btn btn-success small_btn" [routerLink]="['/right-to-work-app-history-list',0]"  type="button">
                                            <span class="lnr lnr-history pr-1">                                        
                                            </span> View Report 
                                        </button>
                                    </div>                             
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 aligncenter mb-3 justify-content-between">
                                <h5 class="hedingcolor">Right to Work Checks</h5>                              
                                <input type="text" [(ngModel)]="searchText" class="form-control width35" placeholder="Search">
                                </div>
                            </div> 
                            <div class="browser-screen-loading-content" *ngIf="!pageOfItems" >
                                <div class="loading-dots dark-gray">
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>                                
                                    <i></i>
                                </div>
                                <p>Loading</p>
                            </div>
                            
                            <ng-container *ngIf="( datalist | filter: {employeeid: searchText, emprole: searchText, fname: searchText, mname: searchText, lname: searchText, emailid: searchText, phonenumber: searchText }) as pageOfItems"> 
                                <div class="job_list_section employee_list_section pb-2 pt-2 oddeven"
                                    *ngFor="let employee of pageOfItems;  let i=index">
                                    
                                    <div class="job_detils_date width22">
                                        <p class="job_address">Name</p>
                                        <h5 class="job_profile font_17">{{employee.fname}} {{employee.mname}}
                                            {{employee.lname}}</h5>
                                    </div>
                                    <div class="job_detils_date width22">
                                        <p class="job_address">Email</p>
                                        <p  class="job_profile">{{employee.emailid}}</p>
                                    </div>
                                    <div class="job_detils_date width15">
                                        <p class="job_address">Phone Number</p>
                                        <p class="job_profile">{{employee.phonenumber}}</p>
                                    </div>
                                    <div class="job_detils_date  width25 justify-content-end d-flex aligncenter" *ngIf="employee.righttowork">   
                                        
                                     
                                     
                                        <button (click)="viewemployeerighttoworkhistory(employee.employeemasterid)" *ngIf="employee.righttowork.length >0" class="btn btn-success com_btn small_btn">Applicant Right to Work Check</button>


                                        <button (click)="uploadByAdmin(emptype, 'upload_now', employee.employeemasterid)" class="btn btn-primary com_btn small_btn">Start New Check</button>
                                        
                                    </div> 

                                  
                                    
                                </div>
        
                                <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                    <div class="job_detils_date">
                                        <p class="job_profile">No Record Found</p>
                                    </div>
                                </div>  
            
                            </ng-container>
                            <!-- <div class="job_list_section visa_request_section" *ngIf="record == 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>       -->
                        
                            <!-- <div class="row">                           
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10"
                                        (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<jw-modal id="custom-send-request" role="dialog" class="modal visapassdriving">
  
    <div role="document" class="formgroup">
        <div class="modal-content">           
            <div class="modal-body">
                <div class="row">              
                    <span class="col-lg-12 text-center" style="color: green;">{{requestDocSentMsg}}</span> 
                </div>
                <div class="browser-screen-loading-content meetingloader" *ngIf="loading ==1" style="width:300px">
                    <div class="loading-dots dark-gray">
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>                                
                        <i></i>
                    </div>
                    <p>Loading</p>
                </div>
                <div class="clearfix">&nbsp;</div>
                <div class="meeting_icon">
                    <div class="icons_vpd" (click)="sendrequestcharge('visa')">
                        <img  src="../../../../../assets/img/meeting/visa.png"/>
                        <p>Visa</p>
                    </div>
                    <div class="icons_vpd" (click)="sendrequestcharge('passport')">
                        <img  src="../../../../../assets/img/meeting/passport.png"/>
                        <p>Passport</p>
                    </div>
                    <div class="icons_vpd" (click)="sendrequestcharge('document')">
                        <img  src="../../../../../assets/img/meeting/driver-licens.png"/>
                        <p>Driving Licence</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer text-center">  
                
               
                <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-send-request');"/>
            </div>
        </div>
    </div>
</jw-modal>




<jw-modal id="custom-right-to-work" role="dialog" class="modal visapassdriving">
  
    <div role="document" class="formgroup">
        <div class="modal-content">            
            <div class="modal-body" *ngIf="stage==1">
                <div class="row">              
                    <span class="col-lg-12 text-center" style="color: green;">{{requestDocSentMsg}}</span> 
                </div>
                <div class="browser-screen-loading-content meetingloader" *ngIf="loading ==1" style="width:300px">
                    <div class="loading-dots dark-gray">
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>                                
                        <i></i>
                    </div>
                    <p>Loading</p>
                </div>

                <h5 class="text-center">Please select one action</h5>
                <div class="clearfix">&nbsp;</div>
                <div class="meeting_icon">
                    <div class="icons_vpd" (click)="sendrequestchargestage('2')">
                        <img  src="../../../../../assets/img/meeting/visa.png"/>
                        <p>Right to Work Check</p>
                    </div>
                    <div class="icons_vpd" (click)="sendrequestchargestage('2')">
                        <img  src="../../../../../assets/img/meeting/passport.png"/>
                        <p>Adjusted Right To Work check due to Covid-19</p>
                    </div>
                    <!-- <div class="icons_vpd" (click)="sendrequestcharge('document')">
                        <img  src="../../../../../assets/img/meeting/driver-licens.png"/>
                        <p>Driving Licence</p>
                    </div> -->
                </div>
            </div>

            <div class="modal-body" *ngIf="stage==2" style="margin-top: 20px;">
                <div class="row">              
                    <div class="col-lg-12 text-center">
                        <input type="text" [(ngModel)]="searchTextcountry" class="form-control" placeholder="Search Your Country Name">
                    </div> 
                </div>

                <div class="row" style="height: 200px; overflow-y: scroll;">
                    <div class="col-lg-4 mt-2"  *ngFor="let listcountry of countrylist | filter: {countryname: searchTextcountry };  let i=index"> 

                        <a (click)="sendrequestchargestage('3')" > {{ listcountry.countryname }}</a>
                    </div>

                </div>

            </div>


            <div class="modal-body" *ngIf="stage==3">
                <div class="row">              
                    <div class="col-lg-12 text-center">
                        <h5 class="text-center">Please select one action</h5>
                    </div> 
                </div>

                <div class="row" style="height: 200px; overflow-y: scroll;">
                    <div class="meeting_icon">
                        <div class="icons_vpd" (click)="sendrequestchargestage('5')">
                            <img  src="../../../../../assets/img/meeting/visa.png"/>
                            <p>Can the holder present the original valid passport in person?</p>
                        </div>
                        <div class="icons_vpd" (click)="sendrequestchargestage('4')">
                            <img  src="../../../../../assets/img/meeting/passport.png"/>
                            <p>Can the individual present any of the following original document in person?</p>
                        </div>
                    </div>
                </div>

            </div>

           
            <div class="modal-body" *ngIf="stage==4">
                <div class="row">              
                    <div class="col-lg-12 text-center">
                        <h5 class="text-center">Please select one action</h5>
                    </div> 
                </div>

                <div class="row" style="height: 200px; overflow-y: scroll;">
                    <div class="meeting_icon"><div class="icons_vpd" (click)="sendrequestchargestage('5')">
                        <img  src="../../../../../assets/img/meeting/visa.png"/>
                        <p>A birth (short or long) or adoption certificate issued in the UK, together with an official document giving the person’s permanent National Insurance number and their name issued by a Government agency or a previous employer.</p>
                    </div>
                    <div class="icons_vpd" (click)="sendrequestchargestage('5')">
                        <img  src="../../../../../assets/img/meeting/passport.png"/>
                        <p>A birth (short or long) or adoption certificate issued in the Channel Islands, the Isle of Man or Ireland, together with an official document giving the person’s permanent National Insurance number and their name issued by a Government agency or a previous employer.</p>
                    </div>
                    <div class="icons_vpd" (click)="sendrequestchargestage('5')">
                        <img  src="../../../../../assets/img/meeting/passport.png"/>
                        <p>A certificate of registration or naturalisation as a British citizen, together with an official document giving the person’s permanent National Insurance number and their name issued by a Government agency or a previous employer. </p>
                    </div>
                    <div class="icons_vpd" (click)="sendrequestchargestage('5')">
                        <img  src="../../../../../assets/img/meeting/passport.png"/>
                        <p>Other</p>
                    </div>
                    </div>
                </div>
            </div>


            <div class="modal-body" *ngIf="stage==5">
                <div class="row">              
                    <div class="col-lg-12 text-center">
                        <h5 class="text-center">Please select one action</h5>
                    </div> 
                </div>

                <div class="row" style="height: 200px; overflow-y: scroll;">
                     <div class="meeting_icon"> <div class="icons_vpd" (click)="sendrequestchargestage('6')">
                        <img  src="../../../../../assets/img/meeting/visa.png"/>
                        <p>Request To Employee to upload document</p>
                    </div>
                    <div class="icons_vpd" (click)="sendrequestchargestage('6')">
                        <img  src="../../../../../assets/img/meeting/passport.png"/>
                        <p>Upload Document yourself</p>
                    </div>     
                    </div>
                </div>
            </div>

            <div class="modal-body" *ngIf="stage==6">
                <div class="row">              
                    <div class="col-lg-12 text-center">
                        <h5 class="text-center">your request send successfully</h5>
                    </div> 
                </div>
                <div class="row" style="height: 200px; overflow-y: scroll;">
                                   
                </div>
            </div>



            <div class="modal-footer text-center">
                <!-- <input type="button" class="btn btn-primary com_btn"  value="Visa" />
                <input type="button" class="btn btn-primary com_btn"  value="Passport" />
                <input type="button" class="btn btn-primary com_btn"  value="Driving Licence" /> -->
                <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-right-to-work');"/>
              </div>
        </div>
    </div>
</jw-modal>



<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
  <div role="document" class="formgroup">
      <div class="modal-content">         
          <div class="modal-body">
              <div class="container text-center">
                <div class="info_mess">
                    <div class="para">
                            <h4 class="mb-2">No credits available</h4>
                            <p class="mb-0 font20">There are no credits available to perform this action. Please add more credits.</p>

                        </div>                          
                    </div>
              </div>
          </div>
          <div class="modal-footer text-center">   
            <input type="button" class="btn btn-primary com_btn " name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
            <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
            <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
          </div>
      </div>
  </div>
</jw-modal>




<jw-modal id="sendrequestcharge" role="dialog" class="modal sendrequestcharge">

        <div role="document" class="formgroup">
            <div class="modal-content">
                <form [formGroup]="meetingformGroup" #meetingForm="ngForm" class="s12 white">
                <div class="modal-body p-4">
                    <div class="container text-center">
                        <div *ngIf="loading ==0">
                            <div class="para mb-4">
                                <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
                                <p class="mb-0 font20">The employee will receive an email containing a video link for live verification of all the documents.</p>
                                <p class="mb-0 font20">Click 'Confirm' to proceed with the 'Right to work check'.</p>
                            </div>
                            <div class="row">
                                <div class="form-group col-lg-4 text-left">
                                    <label class="meetinglabel">Meeting Start Date*</label>
                                    <mat-form-field class="datecontrol">
                                        <input matInput [matDatepicker]="picker1" name="startdate" id="startdate"  [(ngModel)]="right.startdate"  class="form-control noborder-invalid" formControlName="startdate" [min]="currentDate" [class.is-invalid]="submitted && returnForm.startdate.errors" placeholder="Start Date">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="submitted && returnForm.startdate.errors" class="invalid-feedback">
                                        <div *ngIf="returnForm.startdate.errors.required">Please Choose Start Date</div>
                                    </div>
                                </div>
                                <div class="form-group col-lg-4 text-left">
                                    <label class="meetinglabel">Meeting Start Time*</label>
                                    <select formControlName="starttime" name="starttime" id="starttime"  [(ngModel)]="right.starttime"  [ngClass]="{ 'is-invalid': submitted && returnForm.starttime.errors }" class="form-control">
                                        <option [value]="defaultValue">Select start time</option>
                                        <option *ngFor="let tval of timeValues" value="{{ tval.key }}">{{ tval.value }}</option>
                                    </select>
                                    <div *ngIf="submitted && returnForm.starttime.errors" class="invalid-feedback">
                                        <div *ngIf="returnForm.starttime.errors.required">Please enter start time</div>
                                    </div>
                                </div>
                                <div class="form-group col-lg-4 text-left">
                                    <label class="meetinglabel">Meeting End Time*</label>
                                    <select formControlName="endtime" name="endtime" id="endtime"  [(ngModel)]="right.endtime"  [ngClass]="{ 'is-invalid': submitted && returnForm.endtime.errors }" class="form-control">
                                        <option [value]="defaultValue">Select end time</option>
                                        <option *ngFor="let tval of timeValues" [disabled]="meetingformGroup.controls.starttime.value>=tval.key" value="{{ tval.key }}">{{ tval.value }}</option>
                                    </select>
                                    <div *ngIf="submitted && returnForm.endtime.errors" class="invalid-feedback">
                                        <div *ngIf="returnForm.endtime.errors.required">Please enter end time</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="browser-screen-loading-content meetingloader" *ngIf="loading ==1" style="width:300px">
                            <div class="loading-dots dark-gray">
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>                                
                                <i></i>
                            </div>
                            <p>Loading</p>
                        </div>

                    </div>
                </div>
                 <div class="modal-footer text-center" *ngIf="loading !=1">
                    <input type="submit" class="btn btn-primary com_btn" [disabled]="loading == 1" name="updateform" value="Confirm"  (click)="sendZoomLink(employeeid, 'sendrequestcharge', meetingForm.value)"  />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('sendrequestcharge');"/>
                </div> 
            </form>
            </div>
        </div>
</jw-modal>


<jw-modal id="send-request" role="dialog" class="modal">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                    (click)="closeModal('upgrade-plan');">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                          <div class="form-group col-lg-6">
                                <div class="row">You have exceeded the limit to request to Right to check or AI verification. Please add more credit go to billing section.</div>
                            </div>
                    </div>


                    <div class="row">
                        <div class="form-group col-lg-6">
                              <div class="row">You have exceeded the limit to request to Right to check or AI verification. Please add more credit go to billing section.</div>
                          </div>
                  </div>

                 </div>
            </div>
            <div class="modal-footer">
                <input type="button" class="btn btn-primary" name="updateform" value="Send Request" />
            </div>
        </div>
    </div>
  </jw-modal>


  

<jw-modal id="add-new-applicant" role="dialog" class="modal">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add New Applicant</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                    (click)="closeModal('add-new-applicant');">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <form [formGroup]="employeeForm" #overviewForm="ngForm" class="s12 white"  (ngSubmit)="onAddEmployeeSubmit(overviewForm.value,'add-new-applicant')">
      
            <div class="modal-body">
                <div class="container">
                   
                        <div class="formgroup">
                         
                          <div class="row">
                            <div class="form-group col-lg-4">
                                <label>First Name*</label>
                                <input type="fname" formControlName="fname" name="fname" id="fname" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.fname.errors }"
                                    [ngModel]="overviewData.fname" maxlength="50">
                                <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                                    <div *ngIf="f.fname.errors.required">Please enter first name</div>
                                </div>
                            </div>
                  
                            <div class="form-group col-lg-4">
                                <label>Middle Name</label>
                                <input type="mname" formControlName="mname" name="mname" id="mname" class="form-control"
                                    [ngModel]="overviewData.mname" maxlength="50">
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Last Name*</label>
                                <input type="lname" formControlName="lname" name="lname" id="lname"
                                    class="form-control input-lg"
                                    [ngClass]="{ 'is-invalid': submitted && f.lname.errors }"
                                    [ngModel]="overviewData.lname" maxlength="50">
                                <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                                    <div *ngIf="f.lname.errors.required">Please enter last name</div>
                                </div>
                            </div>
                        </div>
                  
                        <div class="row">
                          <div class="form-group col-lg-6">
                              <label>Email*</label>
                              <input type="emailid" (focusout)="onFocusOutEvent($event)"  (focus)="focusFunction()" maxlength="100"
                                  formControlName="emailid" name="emailid" id="emailid" class="form-control"
                                  [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }"
                                  [ngModel]="overviewData.emailid">
                              <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                                  <div *ngIf="f.emailid.errors.required">Please enter email </div>
                              </div>
                              <div class="invalid-feedback">
                                  <div class="error" *ngIf="emailexist">This email is already registered</div>
                              </div>
                          </div>
                          <!-- <div class="form-group col-lg-4">
                            <label>User Name*</label>
                            <input type="text" (focusout)="onUserNameOutEvent($event)"  formControlName="username" name="username" id="username"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                [(ngModel)]="overviewData.username" maxlength="70">
                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">Please enter user name</div>
                            </div>
                            <span *ngIf="username=='true'" style="color:red; width: 100%; font-size: 80%;">User name already exists.</span>
                            <span *ngIf="username=='false'" style="color:green; width: 100%; font-size: 80%;">User name available.</span>
                      
                        </div> -->
                          <div class="form-group col-lg-6">
                              <label>Mobile Number*</label>
                              <international-phone-number formControlName="phonenumber"
                                  class="form-control phonecontrol"
                                  [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }"
                                  [ngModel]="overviewData.phonenumber" placeholder="Enter mobile number"
                                  (input)="validatenumber($event.target.value,'phone')" [maxlength]="20"
                                  [required]="true" #mobilenumber name="phonenumber"></international-phone-number>
                              <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                  <div *ngIf="f.phonenumber.errors.required">Please enter mobile number</div>
                              </div>
                              <span *ngIf="phonecheck===false" style="color:red">Invalid format</span>
                              <span *ngIf="phonecheck===true" style="color:green">Valid format</span>
                          </div>
                      </div>
                        
                      </div>
                 </div>
            </div>
            <div class="modal-footer">
                
                <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Add Applicant"    />
                  
                  
                <input type="button" class="btn btn-secondary com_btn" name="updateform" (click)="closeModal('add-new-applicant')" value="Cancel" />
                
            </div>

        </form>
        </div>
    </div>
  </jw-modal>


  
<jw-modal id="alreadyadd-request" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">
           
            <div class="modal-body">
                <div class="container">
                  <div class="info_mess">                     
                      <div class="para text-left">
                          <h4 class="mar-20 red" *ngIf="samecompany">This applicant already exists in your account. </h4>
                          <h4 class="mar-20 red" *ngIf="!samecompany">This applicant already exists in our system. </h4>
                          <ul *ngIf="samecompany">
                              <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                  Send an email to the applicant to Video call for Right to Work Check.</li>
                              <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                   Once the request has been uploaded you will be notified by an email. </li>
                             
                          </ul>                           
                      </div>                          
                  </div>
                 </div>
            </div>
            <div class="modal-footer">
              <input type="button" *ngIf="samecompany" class="btn  btn-warning com_btn"  (click)="sendrequest(employeeid,'sendrequestcharge')"  name="updateform" value="Start New Check" />
              <!-- <input type="button" class="btn btn-danger com_btn" name="updateform" value="Add Biometric Residence Permit" /> -->
                <input type="button" class="btn btn-dark com_btn" (click)="closeModal('alreadyadd-request');" name="updateform" value="Cancel" />
            </div>
        </div>
    </div>
  </jw-modal>   