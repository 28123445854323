<div class="check_verification" *ngIf="token_expired">
  <div class="wizadlineshow">
      <div class="wizard_details">
          <div class="container">
              <div class="main-banner">
                  <div class="banner-form">
                      <div class="row">
                          <div class="col-sm-12">
                              <div class="tankushow">
                                  <div class="detailstext">
                                      <h1 class="tokenfont">Token Expired</h1>
                                      <p></p>
                                  </div>
                                  <img alt="shape" src="../../../../../assets/img/thanks.png">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<form [formGroup]="idproofpopForm"  #documentForm="ngForm" id="visa-form-submit" class="s12 white docvisapass" *ngIf="idproofDetail && !token_expired">
  <div class="check_verification">
      <div class="wizadlineshow">

        <div class="wizard_img_box"  *ngIf="wizard != 5">
            <div class="wizard_img" [class.active]="wizard == 1 || wizard == 2 || wizard == 3 || wizard == 4 || wizard == 5">
                <img src="../../../../../assets/img/icon/1214.png">
                <!-- <img class="widthset" [src]="imgURL" *ngIf="imgURL"> -->
            </div>
            <div class="wizard_img" [class.active]="wizard == 2 || wizard == 3 || wizard == 4 || wizard == 5">
                <img src="../../../../../assets/img/icon/1214.png">
                <!-- <img class="widthset" [src]="imgbackURL" *ngIf="imgbackURL">   -->
            </div>
            <div class="wizard_img"  [class.active]="wizard == 3 || wizard == 4 || wizard == 5">
                <img src="../../../../../assets/img/icon/1231.png">
                <!-- <img class="widthuser" [src]="profile_pic_url" *ngIf="profile_pic_url" > -->
            </div>
            <div class="wizard_img"  [class.active]="wizard == 4 || wizard == 5">
                <img src="../../../../../assets/img/icon/1111.png" >
            </div>
        </div>

        <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [ngModel]="idproofDetail.employeeid">
        <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [ngModel]="idproofDetail.employeeidproofdeatilid">
        <input type="hidden" formControlName="idtype" class="form-control" required name="idtype" id="idtype" [ngModel]="idproofDetail.idtype">

          <div id="wizard-1" *ngIf="wizard == 0" class="wizard_details">
              <div class="container">
                  <div class="main-banner">
                      <div class="overviewsshow check_support mt-4">
                          <div class="row">
                            <div class="col-sm-12">
                                <div class="header">
                                    <h1 class="headprofile mt-0 mb-0">Add {{docname}} Details</h1>
                                </div>
                                <hr/>
                            </div>
                              <div class="col-sm-12">
                                <div class="row">
                                  <div class="form-group col-lg-6">
                                      <label>{{docname}} No.*</label>
                                      <input type="text" formControlName="idnumber" placeholder="{{docname}} no" maxlength="30"
                                        name="idnumber" id="idnumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.idnumber.errors }"
                                        [(ngModel)]="idproofDetail.idnumber">
                                      <div *ngIf="submitted && f.idnumber.errors" class="invalid-feedback">
                                          <div *ngIf="f.idnumber.errors.required">This field is required</div>
                                      </div>
                                  </div>
                                  <div class="form-group col-lg-6">
                                    <label>Expiry Date*</label>
                                    <mat-form-field class="datecontrol">
                                        <input matInput [matDatepicker]="picker2" id="docexpiry" name="docexpiry" formControlName="docexpiry" [ngClass]="{ 'is-invalid': submitted && f.docexpiry.errors }" class="form-control noborder-invalid datecontrol" [(ngModel)]="idproofDetail.docexpiry">
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="submitted && f.docexpiry.errors" class="invalid-feedback">
                                        <div *ngIf="f.docexpiry.errors.required">Please choose expiry date</div>
                                    </div>
                                </div>
                              </div>
                              <div class="row">
                                  <div class="form-group col-lg-6">
                                      <label>{{docname}} Issue Country*</label>
                                      <select style="width:100%;" class="form-control" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [(ngModel)]="idproofDetail.nationalityid">
                                    <option value="">Select country </option>
                                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                                  </select>
                                      <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                          <div *ngIf="f.nationalityid.errors.required">Please select {{docname}} issuing country</div>
                                      </div>
                                  </div>

                              </div>
                              <hr/>
                                  <div class="row">
                                      <div class="col-lg-12 col-md-12 text-right aligncenter">
                                        <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                          <div class="next">
                                              <button type="button" class="btn btn-primary com_btn" (click)="toggle('1')">Next</button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div id="wizard-2"  *ngIf="wizard == 1"  class="wizard_details">
              <div class="container">
                  <div class="main-banner">
                      <div class="overviewsshow check_support mt-4">
                          <div class="row">
                            <div class="col-sm-12">
                                <div class="header">
                                    <h1 class="headprofile mt-0 mb-0">Front Side {{docname}} Image</h1>
                                </div>
                                <hr/>
                            </div>
                              <div class="col-sm-12 text-center">
                                  <webcam class="videowidth" [trigger]="triggerObservable" *ngIf="!webcamImage" (imageCapture)="handleImage($event)"
                                      [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                                      [videoOptions]="videoOptions"
                                      [imageQuality]="1"
                                      (cameraSwitched)="cameraWasSwitched($event)"
                                      (initError)="handleInitError($event)" ></webcam>

                                        <div class="snapshot" *ngIf="webcamImage">
                                          <img [src]="webcamImage.imageAsDataUrl"/>
                                        </div>

                                      <div class="camerashap_aligncenter">
                                          <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshot();"  *ngIf="!webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Capture</span></button>
                                          <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshotreload();"   *ngIf="webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Recapture </span></button>
                                      </div>
                              </div>
                          </div>
                          <hr/>
                          <div class="row">
                              <div class="col-lg-12 col-md-12 text-right aligncenter">
                                <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                  <div class="next">
                                      <button type="submit" (click)="toggle('0')" class="btn btn-dark mr-3">Previous</button>
                                      <button type="button" class="btn btn-primary" (click)="toggle('2')" *ngIf="webcamImage">Next</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div id="wizard-2"  *ngIf="wizard == 2"  class="wizard_details">
              <div class="container">
                  <div class="main-banner">
                      <div class="overviewsshow check_support mt-4">
                          <div class="row">
                            <div class="col-sm-12">
                                <div class="header">
                                    <h1 class="headprofile mt-0 mb-0">Back Side {{docname}} Image</h1>
                                </div>
                                <hr/>
                            </div>
                              <div class="col-sm-12 text-center">
                                  <webcam class="videowidth" [trigger]="triggerObservable" *ngIf="!webcamImage" (imageCapture)="handleImage($event)"
                                      [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                                      [videoOptions]="videoOptions"
                                      [imageQuality]="1"
                                      (cameraSwitched)="cameraWasSwitched($event)"
                                      (initError)="handleInitError($event)" ></webcam>

                                        <div class="snapshot" *ngIf="webcamImage">
                                          <img [src]="webcamImage.imageAsDataUrl"/>
                                        </div>

                                      <div class="camerashap_aligncenter">
                                          <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshot();"  *ngIf="!webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Capture</span></button>
                                          <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshotreload();"   *ngIf="webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Recapture </span></button>
                                      </div>
                              </div>
                          </div>
                          <hr/>
                          <div class="row">
                              <div class="col-lg-12 col-md-12 text-right aligncenter">
                                <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                  <div class="next">
                                      <button type="submit" (click)="toggle('1')" class="btn btn-dark mr-3">Previous</button>
                                      <button type="button" (click)="toggle('3')" class="btn btn-dark mr-3">Skip</button>
                                      <button type="button" class="btn btn-primary" (click)="toggle('3')"  *ngIf="webcamImage">Next</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div id="wizard-23"  *ngIf="wizard == 3"  class="wizard_details">
              <div class="container">
                  <div class="main-banner">
                      <div class="overviewsshow check_support mt-4">
                          <div class="row">
                            <div class="col-sm-12">
                                <div class="header">
                                    <h1 class="headprofile mt-0 mb-0">Upload Selfie</h1>
                                </div>
                                <hr/>
                            </div>
                              <div class="col-sm-12 text-center">
                                  <webcam class="videowidth" [trigger]="triggerObservable" *ngIf="!webcamImage" (imageCapture)="handleImage($event)"
                                      [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                                      [videoOptions]="videoOptions"
                                      [imageQuality]="1"
                                      (cameraSwitched)="cameraWasSwitched($event)"
                                      (initError)="handleInitError($event)" ></webcam>

                                        <div class="snapshot" *ngIf="webcamImage">
                                          <img [src]="webcamImage.imageAsDataUrl"/>
                                        </div>

                                      <div class="camerashap_aligncenter">
                                          <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshot();"  *ngIf="!webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Capture</span></button>
                                          <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshotreload();"   *ngIf="webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Recapture </span></button>
                                      </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="imgcent col-lg-12 col-md-12" *ngIf="profile_pic_url">
                                  <img class="width100" [src]="profile_pic_url">
                              </div>
                          </div>
                          <hr/>
                          <div class="row">
                              <div class="col-lg-12 col-md-12 text-right aligncenter">
                                <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                  <div class="next">
                                      <button type="submit" (click)="toggle('2')" class="btn btn-dark mr-3">Previous</button>
                                      <button type="button" class="btn btn-primary" (click)="toggle('4')"  >Next</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div id="wizard-23"  *ngIf="wizard == 4"  class="wizard_details">
            <div class="container">
                <div class="main-banner">
                    <div class="overviewsshow check_support mt-2">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="header">
                                    <h1 class="headprofile mt-0 mb-0">Document Upload Successful</h1>
                                </div>
                                <hr/>
                            </div>
                            <div class="col-sm-12">
                                <div class="uploaded_img" *ngIf="!showloader">
                                    <div class="img_sec">
                                        <img class="p-4" [src]="frontimage">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </div>
                                    <div class="img_sec"  *ngIf="backimage">
                                        <img class="p-4" [src]="backimage">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </div>
                                    <div class="img_sec">
                                        <img class="p-4 userself" [src]="profileimage">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div *ngIf="showloader">
                                    <div class="browser-screen-loading-content" >
                                        <div class="loading-dots dark-gray">
                                            <i></i>
                                            <i></i>
                                            <i></i>
                                            <i></i>
                                            <i></i>
                                            <i></i>
                                            <i></i>
                                        </div>
                                        <p>Loading</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr/>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 text-right aligncenter">
                                <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                <div class="next">
                                    <button type="submit" (click)="toggle('3', 'prev')" class="btn btn-dark mr-2 mt-0 com_btn">Previous</button>
                                    <button type="button" class="btn btn-primary com_btn mt-0"
                                          (click)="onSubmitDocument(documentForm.value)">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

          <div id="wizard-4"  *ngIf="wizard == 5" class="wizard_details">
              <div class="container">
                  <div class="main-banner">
                      <div class="overviewsshow check_support mt-4">
                          <div class="row">
                              <div class="col-sm-12">
                                  <div class="tankushow">
                                      <div class="detailstext">
                                          <h1>Driving Licence Uploaded Successfully</h1>
                                          <p>You do not need to take any further action </p>
                                      </div>
                                      <img alt="shape" src="../../../../../assets/img/thanks.png">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div id="wizard-5"  *ngIf="wizard == 6" class="wizard_details">
            <div class="container">
                <div class="main-banner">
                    <div class="overviewsshow check_support mt-4">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="browser-screen-loading-content" >
                                    <div class="loading-dots dark-gray">
                                        <i></i>
                                        <i></i>
                                        <i></i>
                                        <i></i>
                                        <i></i>
                                        <i></i>
                                        <i></i>
                                    </div>
                                    <p>Loading</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>
</form>
