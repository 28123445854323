import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';


@Injectable({
  providedIn: 'root'
})
export class MyidproofService {
  [x: string]: any;
  private modals: any[] = [];
  constructor(private commonService: CommonService) {}

  getidproofAllList(value: any): Observable<any> {
    return this.commonService.formpost('/employee/employeeidprooflist',value);      
  }

  getidproofAllListbyadmin(value: any): Observable<any> {
    return this.commonService.formpost('/employee/employeeidprooflistbyadmin',value);      
  }

  getallidproofListbyadmin(value: any): Observable<any> {
    return this.commonService.formpost('/employee/allemployeeidprooflistbyadmin',value);      
  }


  getaiapprovereject(value: any): Observable<any> {
    return this.commonService.formpost('/company/allailogforsuperadmin',value);      
  }

  downloadDrivingLicence(value: any): Observable<any> {
    return this.commonService.formpost('/employee/downloaddrivinglicence',value); 
  }

  getidproofAllApprove(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/employeeidproofpprove');      
  }

  getidproofAllRejected(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/employeeidproofrejected');      
  }

  deleteidproof(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/idproofdelete',value);
  }

  myidproofdetailsUpdate(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/idproofupdate',value);
  }

  myidproofdetailsUpdaterighttowork(value:any): Observable<any> {
    return  this.commonService.refereeformpost('/employee/idproofupdaterighttowork',value);
  }
  
  
  myidproofdetailsUpdatebyadmin(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/idproofupdatebyadmin',value);
  }

  getidproofdetailsidbyadmin(value: any): Observable<any> {
    return this.commonService.formpost('/employee/employeeidproofdetailsbyadmin',value);      
  }

  idproofdetailsbyidproofid(value: any): Observable<any> {
    return this.commonService.formpost('/employee/idproofdetailsbyidproofid',value);      
  }
  idproofupdateForAI(value: any): Observable<any> {
    return this.commonService.formpost('/employee/idproofupdateAIDetailsbyadmin',value);      
  }  
  idproofaipaymentstaus(value: any): Observable<any> {
    return this.commonService.formpost('/employee/checkidproofaipaymentstatus',value);      
  }
  iddocumenttype(): Observable<any> {
    return this.commonService.formpostwithouttoken('/company/documenttype',{id:1});      
  }
}
