"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var visa_model_1 = require("../../../../../models/visa/visa.model");
var employee_model_1 = require("../../../../../models/employee.model");
var myvisa_service_1 = require("../../../../../services/myvisa.service");
var alert_service_1 = require("../../../../../services/alert.service");
var upload_file_service_1 = require("../../../../../services/upload-file.service");
var customer_service_1 = require("../../../../../services/customer.service");
var EmployeeaiuserpicchangeComponent = /** @class */ (function () {
    function EmployeeaiuserpicchangeComponent(sanitizer, formBuilder, visaService, activatedRoute, alerts, uploadService, data, 
    // private country: CountryService,  
    router) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.visaService = visaService;
        this.activatedRoute = activatedRoute;
        this.alerts = alerts;
        this.uploadService = uploadService;
        this.data = data;
        this.router = router;
        this.visadetails = new visa_model_1.Visa();
        this.employee = new employee_model_1.Employee();
        this.nextDisable = true;
    }
    EmployeeaiuserpicchangeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userId = localStorage.getItem('userid');
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.requestperm = this.permission.m15;
        this.ctype = parseInt(localStorage.getItem('ctype'));
        this.employeevisadeatilid = this.activatedRoute.snapshot.paramMap.get("visaid");
        this.data3 = { "employeevisadeatilid": this.employeevisadeatilid };
        this.visaService.visadetailsbyvisaid(this.data3).subscribe(function (data) {
            _this.employeemasterid = data.employee.employeemasterid;
            // console.log(data);
            if (data.visaprofileimage) {
                _this.nextDisable = false;
                _this.visaprofileimage = data.visaprofileimage;
            }
            else if (data.employee.imagename) {
                _this.nextDisable = false;
                _this.visaprofileimage = data.employee.imagename;
            }
            _this.visadetails = data;
            _this.record = data.length;
            // this.data = data;
            // this.record = data.length;
        });
    };
    EmployeeaiuserpicchangeComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        var file = this.selectedFiles;
        this.fileformaterror = false;
        this.filesizeerror = false;
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
            this.alerts.success('File size must be less than 2 MB.', true);
            return;
        }
        else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png")) {
            this.fileformaterror = true;
            this.alerts.success('Please choose a valid file format (jpg, png,ttf,gif)', true);
            return;
        }
        var imagepath = this.uploadService.uploadfile(file);
        //  this.visadetails.employee.imagename  = imagepath;
        this.visaprofileimage = '../../../../../../../assets/img/loadings.gif';
        // console.log(this.employeemasterid);
        var forupdate = { 'visaprofileimage': imagepath, 'id': this.employeevisadeatilid, 'createby': this.userId };
        this.visaService.myvisadetailsUpdatebyadmin(forupdate).subscribe(function (data) {
            _this.nextDisable = false;
            setTimeout(function () { return _this.visaprofileimage = imagepath; }, 3000);
        });
    };
    EmployeeaiuserpicchangeComponent.prototype.onSubmit = function () {
        var _this = this;
        var checkData = { "employeevisadeatilid": this.employeevisadeatilid };
        this.visaService.visadetailsbyvisaid(checkData).subscribe(function (data) {
            if (data.visaprofileimage) {
                _this.router.navigate(['/employeeaipayment/' + _this.employeevisadeatilid]);
            }
            else {
                var forupdate = { 'visaprofileimage': _this.visaprofileimage, 'id': _this.employeevisadeatilid, 'createby': _this.userId };
                _this.visaService.myvisadetailsUpdatebyadmin(forupdate).subscribe(function (data) {
                    _this.router.navigate(['/employeeaipayment/' + _this.employeevisadeatilid]);
                });
            }
        });
    };
    return EmployeeaiuserpicchangeComponent;
}());
exports.EmployeeaiuserpicchangeComponent = EmployeeaiuserpicchangeComponent;
