import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class LeaveService {

  constructor(private commonService: CommonService) { }
  getemployeeleave(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/holiday');
  }

  getemployeeleavesick(data): Observable<any> {
    return this.commonService.formpost('/employee/holiday',data);
  }
  getemployeeleavecount(data): Observable<any> {
    return this.commonService.formpost('/employee/holidaycountbyyear',data);
  }

  getemployeeleavereport(data): Observable<any> {
    return this.commonService.formpost('/employee/holidayreport', data);
  }

  getemployeeleaveapprovedreport(data): Observable<any> {
    return this.commonService.formpost('/employee/holidayreportrequest', data);
  }

  getemployeeleaveapprovedreportbyadmin(data): Observable<any> {
    return this.commonService.formpost('/employee/holidayreportrequestbyadmin', data);
  }

  getleavetypelist(data): Observable<any> {

    return this.commonService.formpost('/holidaytype', data);
  }

  settingleavetypeUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/holidaytypeupdatebyadmin', value);
  }

  myleaveApplyUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/employee/apply-leave', value);
  }
  getleavedatabydate(value: any): Observable<any> {
    return this.commonService.formpost('/employee/leavelistbydate', value);
  }

  checkleaveondate(value: any): Observable<any> {
    return this.commonService.formpost('/employee/checkleaveofuserbydate', value);
  }

  getLeaveData(value: any): Observable<any> {
    return this.commonService.formpost('/employee/holidaybyid', value);
  }

  getleaveremarks(value: any): Observable<any> {
    return this.commonService.formpost('/employee/remarksbyholiday', value);
  }

  getTblemployeemasterList(): Observable<any> {
    return this.commonService.formget('/api/Tblemployeemasters');
  }

  deleteleave(value: any): Observable<any> {
    return this.commonService.formpost('/employee/leavedelete', value);
  }

  statusleave(value: any): Observable<any> {
    return this.commonService.formpost('/employee/leavestatus', value);
  }

  addnewremark(value: any): Observable<any> {
    return this.commonService.formpost('/employee/addnewremark', value);
  }

  checkpendingleave(value: any): Observable<any> {
    return this.commonService.formpost('/employee/checkpendingleave', value);
  }

  leavetypeUpdateChangeStatus(value: any): Observable<any> {
    return this.commonService.formpost('/company/leavetypeupdatechangestatus', value);
  }


  /// report admin


  leavereport(data): Observable<any> {
    return this.commonService.formpost('/employee/employeeleavereport', data);
  }

  getholidaylist(): Observable<any> {
    return this.commonService.formget('/company/holidaytype');
  }


}
