"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var SubscriptionsuccessComponent = /** @class */ (function () {
    function SubscriptionsuccessComponent(activatedRoute) {
        this.activatedRoute = activatedRoute;
    }
    SubscriptionsuccessComponent.prototype.ngOnInit = function () {
        this.paytext = parseInt(this.activatedRoute.snapshot.paramMap.get("id"));
        if (this.paytext === 1) {
            this.success = true;
        }
        else if (this.paytext === 0) {
            this.success = false;
        }
    };
    return SubscriptionsuccessComponent;
}());
exports.SubscriptionsuccessComponent = SubscriptionsuccessComponent;
