import { HeaderComponent } from './../header/header.component';
import { Component, OnInit, NgModule, ViewEncapsulation, HostListener } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CustomerService } from '../../services/customer.service';
import { ModalService } from '../../_services/modal.service';
import { Nationality } from '../../models/overview/nationality.model';
import { Permanentaddress } from '../../models/overview/permanentaddress.model';
import { Profile } from '../../models/overview/profile.model';
import { AlertService } from '../../services/alert.service';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { CountryService } from '../../services/country.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadFileService } from './../../services/upload-file.service';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { MatOptionSelectionChange } from '@angular/material';
import { CommonService } from '../../services/common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare let $: any;

@NgModule({
  imports: [
    InternationalPhoneNumberModule
  ]
})

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade',
      [
        state('void', style({ opacity: 0 })),
        transition(':enter', [animate(300)]),
        transition(':leave', [animate(500)])
      ]
    )
  ],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})

export class OverviewComponent implements OnInit {
  public navbarOpen = false;
  public clicked = false;
  _el: any;
  location: any;
  layoutClass: string;
  sectionHeights = [];
  tab: any = 1;
  empltype: any;
  overviewData: any;
  buttoninsert: any;
  countrylist: any;
  statelist: any[];
  OverForm: FormGroup;
  permanetForm: FormGroup;
  mobilecheck: any;
  phonecheck: any;
  options = [];
  mindob = new Date();
  maxdob = new Date();
  selectedFiles: FileList;
  prfileImage: any;
  submitted: boolean;
  Userid: string;
  addressdata: any;
  addresslist: any;
  paddress: any;
  employement: any;
  visalist: any;
  defaultValue: any = "";
  file: any;
  filesizeerror: any;
  fileformaterror: any;
  overview1: any;
  overemployeement1: any;
  overaddress1: any;
  overcompen1: any;
  overcontactinfo1: any;
  overcos1: any;
  overvisa1: any;
  overpassport1: any;
  overidproof1: any;
  overviewFormData: any;

  constructor(private formBuilder: FormBuilder, private uploadService: UploadFileService, private data: CustomerService, private country: CountryService, private modalService: ModalService, private commonService: CommonService, private http: HttpClient, private alerts: AlertService) { }
  ngOnInit() {
    this.mindob.setDate(this.mindob.getDate() - 23741);
    this.maxdob.setDate(this.maxdob.getDate() - 574);
    this.OverForm = this.formBuilder.group({
      id: ['', Validators.required],
      //  employeeid: ['', Validators.required],
      fname: ['', [Validators.required, Validators.min(3)]],
      mname: [''],
      lname: ['', [Validators.required, Validators.min(3)]],
      gender: ['', Validators.required],
      placeofbirth: ['', Validators.required],
      hiredate: [''],
      employeeid: [''],
      maritalstatus: ['', Validators.required],
      ninumber: [''],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      residenceid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      countryofbirthid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      dateofbirth: ['', Validators.required],
    });
    this.permanetForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      addressid1: ['', Validators.required],
      addressid2: [''],
      cityname: ['', Validators.compose([Validators.required,])],
      mobilenumber: [''],
      pincode: ['', Validators.required],
      statename: ['', Validators.compose([Validators.required, Validators.min(1)])],
      phonenumber: [''],
      countryid: ['', Validators.compose([Validators.required, Validators.min(1)])]
    });
    this.overviewData = new Profile();
    this.overviewFormData = new Profile();
    this.paddress = new Permanentaddress();

    this.empltype = localStorage.getItem('emptype');
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.data.getOverview().subscribe((data: any) => {
      this.overviewData = data;
    });

    this.data.getPaddress().subscribe((data: any) => {
      this.paddress = data;
    });

    this.Userid = localStorage.getItem('userid');
    const data3 = { 'userid': this.Userid };
    this.data.getOtherInfo(data3).subscribe((data: any) => {
      this.employement = data;
    });

    this.data.getVisa().subscribe((data: any) => {
      this.visalist = data;
    });
  }

  toggleNavbar = () => {
    this.navbarOpen = !this.navbarOpen;
  }
  onKeydown(event) {
    return false;
  }

  onClick(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicked = true;
  }

  @HostListener('window:click', ['$event'])
  clickedOutside(e): void {
    if (this.clicked) {
      this._el.nativeElement.querySelector('dropdown-menu').classList.toogle('show')
    }
  }

  onmousetopbottom(id, height) {
    $('html, body').animate({
      scrollTop: $('#' + id).offset().top
    }, 1000, function () {
      window.location.hash = '#' + id;
    });

    this.tab = height;
  }

  @HostListener('window:scroll', ['$event'])

  onWindowScroll(e) {
    // let element = document.getElementById('tabs');
    // console.log(window.pageYOffset +' rahul kumar tanwar');

    let overview = document.getElementById('overview');
    let overemployeement = document.getElementById('overemployment');
    let overaddress = document.getElementById('overaddress');
    let overcompen = document.getElementById('overcompensation');
    let overcontactinfo = document.getElementById('overcontactinfo');
    let overcos = document.getElementById('overcos');
    let overvisa = document.getElementById('overvisa');
    let overpassport = document.getElementById('overpassport');
    let overidproof = document.getElementById('overidproof');
    this.overview1 = overview.offsetHeight;
    this.overemployeement1 = overemployeement.offsetHeight + this.overview1;
    this.overaddress1 = overaddress.offsetHeight + this.overemployeement1;
    this.overcompen1 = overcompen.offsetHeight + this.overaddress1;
    this.overcontactinfo1 = overcontactinfo.offsetHeight + this.overcompen1;
    this.overcos1 = overcos.offsetHeight + this.overcontactinfo1;
    this.overvisa1 = overvisa.offsetHeight + this.overcos1;
    this.overpassport1 = overpassport.offsetHeight + this.overvisa1;
    this.overidproof1 = overidproof.offsetHeight + this.overpassport1;

    if (window.pageYOffset < this.overview1) {
      this.tab = 1;
    } else if (window.pageYOffset < this.overemployeement1) {
      this.tab = 2;
    } else if (window.pageYOffset < this.overaddress1) {
      this.tab = 3;
    } else if (window.pageYOffset < this.overcompen1) {
      this.tab = 4;
    } else if (window.pageYOffset < this.overcontactinfo1) {
      this.tab = 5;
    } else if (window.pageYOffset < this.overcos1) {
      this.tab = 6;
    } else if (window.pageYOffset < this.overvisa1) {
      this.tab = 7;
    } else if (window.pageYOffset < this.overpassport1) {
      this.tab = 8;
    } else if (window.pageYOffset < (this.overidproof1 - 200)) {
      this.tab = 9;
    }

    if (window.pageYOffset > 200) {
      let element = document.getElementById('employeetopmenu');
      // console.log(element+' rahul kumar tanwar');
      element.classList.add('is-sticky');
    } else {
      let element = document.getElementById('employeetopmenu');
      element.classList.remove('is-sticky');
    }
  }

  onSelectCountry(country_id: number) {
    const data = { 'id': country_id };
    this.country.getstatelist(data).subscribe((state: any) => {
      return this.statelist = state
    });
  }

  onSearchChange(searchValue) {
    if (searchValue.length > 4) {
      var newArray = [];
      this.commonService.getAddress(searchValue).subscribe((data: any) => {
        var newdata: any;
        newdata = data;
        newdata.addresses.forEach(function (value) {
          newArray.push(value + "," + searchValue);
        });
        this.options = newArray;
      });
    }
    else {
      this.options = [];
    }
  }

  displayFn(value: any) {
    if (value != null) {
      var res = value.split(",");
      if (res.length > 6) {
        return res[7];
      }
      else {
        return value;
      }
    }
  }

  updatecode(evt: MatOptionSelectionChange, response) {
    res = [];
    if (evt.source.selected) {
      var res = response.split(",");
      this.paddress.addressid1 = res[0];
      this.paddress.addressid2 = res[1];
      this.paddress.cityname = res[5];
      this.paddress.pincode = res[7];
      this.paddress.statename = res[6];
      // const data = { 'id': this.paddress.countryid };
      // this.country.getstatelist(data).subscribe((state: any) => {
      //   for (var i = 0; i < state.length; i++) {
      //     if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
      //       var inde = state[i]['stateid'];
      //     }
      //   }
      //   this.paddress.statename = inde;
      //   this.statelist = state;
      // });
    }
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  openModal(id: string, data: any) {
    this.submitted = false;
    // var tempData = data;
    this.overviewFormData = new Profile();
    if (data.nationalityid == null) {
      data.nationalityid = "";
    }
    if (data.residenceid == null) {
      data.residenceid = "";
    }
    if (data.countryofbirthid == null) {
      data.countryofbirthid = "";
    }
    if(data.maritalstatus == false){
      data.maritalstatus = "0";
    } else if(data.maritalstatus == true){
      data.maritalstatus = "1";
    }else{
      data.maritalstatus = "0";
    }

    data.id = data.employeemasterid;

    if(data.gender == false){
      data.gender = "0";
    } else if(data.gender == true){
      data.gender = "1";
    }else{
      data.gender = "0";
    }
    // console.log(data);
    this.OverForm.reset(data);
    this.overviewFormData = data;
    this.modalService.open(id);
  }

  openModal2(id: string, data: any) {
    this.submitted = false;
    this.paddress = new Permanentaddress();
    this.buttoninsert = "Update";
    // this.buttoninsert = "Submit";
    this.data.getPaddress().subscribe((data: any) => {
      this.paddress = data;
    });
    this.modalService.open(id);
  }

  selectFile(event) {
    var files = event.target.files;
    var selectedFiles = files[0];
    this.selectedFiles = selectedFiles;
    const file = this.selectedFiles;
    this.fileformaterror = false;
    this.filesizeerror = false;

    this.file = this.selectedFiles;
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      // this.filesizeerror = true;
      this.alerts.error('File size must be less than 2 MB.', true);
      return;
    }
    else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png")) {
      // this.fileformaterror = true;
      this.alerts.error('Please choose a valid file format (jpg, png)', true);
      // console.log(filetype[filetype.length - 1]);
      return;
    }
    this.overviewData.imagename = '../../../assets/img/loadings.gif';
    var imagepath = this.uploadService.uploadfile(file);
    var userid = localStorage.getItem('userid');
    var forupdate = { 'imagename': imagepath, 'id': userid };
    this.data.updateemployeeimage(forupdate).subscribe((data: any) => {
      setTimeout(() => this.overviewData.imagename = imagepath, 4000);
      this.alerts.success('Profile picture updated successfully', true);
    });
  }

  get u() { return this.OverForm.controls; }

  onOverviewSubmit(value3: any, id: any) {
    this.submitted = true;
    console.log(this.OverForm);
    if (this.OverForm.invalid) {
      return;
    }




    
    const sd = new Date(value3.dateofbirth);
    value3.dateofbirth = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());

    if(value3.hiredate)
    {
      const ed = new Date(value3.hiredate);
      value3.hiredate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
    }


    this.data.updateemployeedetals(value3).subscribe((data: any) => {
      this.data.getOverview().subscribe((data: any) => {
        this.overviewData = data;
      });
      //  this.msg = "success";
    });

    this.alerts.success('Profile updated successfully', true);
    this.modalService.close(id);
  }

  get h() { return this.permanetForm.controls; }

  onSubmit2(value3: any, id: any) {
    this.submitted = true;
    if (this.permanetForm.invalid) {
      return;
    }

    var pinc = value3.pincode.split(',');
    value3.pincode = pinc[pinc.length - 1];
    this.data.permanentadddetals(value3).subscribe((data: any) => {
      this.data.getPaddress().subscribe((data: any) => {
        this.paddress = data;
      });

    });
    this.alerts.success('Permanent address updated successfully', true);
    this.modalService.close(id);
  }

  closeModal(id: string) {
    // this.overviewData = new Profile();
    // this.paddress = new Permanentaddress();
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.data.getOverview().subscribe((data: any) => {
      if (data.addressid1 == null) { this.buttoninsert = "Submit"; }
      else { this.buttoninsert = "Update"; }
      this.overviewData = data;
    });
    this.data.getPaddress().subscribe((data: any) => {
      this.paddress = data;
    });
    this.modalService.close(id);
  }
}