"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var user_service_1 = require("../../services/user.service");
var user_model_1 = require("../../models/user.model");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var forms_1 = require("@angular/forms");
var customer_service_1 = require("../../services/customer.service");
var company_service_1 = require("../../services/company.service");
var alert_service_1 = require("../../services/alert.service");
// import { User} from '../../shared/user.model';
// import { EmailValidator } from '@angular/forms';
var OptcheckComponent = /** @class */ (function () {
    function OptcheckComponent(formBuilder, userService, router, alerts, csservices, company) {
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.router = router;
        this.alerts = alerts;
        this.csservices = csservices;
        this.company = company;
        this.submitted = false;
        this.isLoginError = false;
        // loading: boolean;
        this.user = new user_model_1.User();
        this.access_token = '';
        this.userid = '';
        this.name = '';
        this.isline = '';
        this.planid = '';
        this.error = '';
    }
    OptcheckComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        else {
            this.userService.checkaccesstokenbyid(userid).subscribe(function (data) {
                if (data.status == false) {
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('userid');
                    localStorage.removeItem('Name');
                    localStorage.removeItem('isline');
                    localStorage.removeItem('planid');
                    localStorage.removeItem('ctype');
                    localStorage.removeItem('isemail');
                    localStorage.removeItem('ismobile');
                    localStorage.removeItem('isadmin');
                    localStorage.removeItem('ispermission');
                    localStorage.removeItem('logoname');
                    localStorage.removeItem('struser');
                    localStorage.removeItem('menu');
                    _this.router.navigate(['/login']);
                }
            });
        }
        this.registerForm = this.formBuilder.group({
            otp: ['', forms_1.Validators.required],
            otpmobile: ['', forms_1.Validators.required]
        });
        this.show = false;
        var isemail = localStorage.getItem('isemail');
        var ismobile = localStorage.getItem('ismobile');
        var struser = localStorage.getItem('struser');
        this.ctype = localStorage.getItem('ctype');
        var planid = localStorage.getItem('planid');
        console.log('Email System', isemail);
        if (isemail == 'true' && ismobile == 'true') {
            this.router.navigate(['/choose-plan']);
        }
        else if (planid == '0' && this.ctype == '0' && isemail == 'true') {
            this.router.navigate(['choose-plan']);
        }
        else if (struser == '' && this.ctype == '1' && isemail == 'true') {
            this.router.navigate(['payment-confirmation']);
        }
        else if (this.ctype == '2' && (planid == '0' || planid === null) && isemail == 'true') {
            this.router.navigate(['payment-confirmation']);
        }
        else if (struser == '' && this.ctype == '3' && isemail == 'true') {
            this.router.navigate(['payment-confirmation']);
        }
        this.company.getcompanydetails().subscribe(function (data) {
            _this.email = data.emailid;
            _this.mobile = data.phonenumber;
        });
    };
    Object.defineProperty(OptcheckComponent.prototype, "f", {
        get: function () { return this.registerForm.controls; },
        enumerable: true,
        configurable: true
    });
    OptcheckComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        var otp = '';
        otp = localStorage.getItem('otp');
        if (this.user.otp != otp) {
            this.message = 'Please enter correct OTP for Email';
            this.show = true;
            return;
        }
        else {
            this.show = false;
        }
        var otpmobile = '';
        otpmobile = localStorage.getItem('otpmobile');
        if (this.user.otpmobile != otpmobile) {
            this.message = 'Please enter correct OTP for Mobile';
            this.show = true;
            return;
        }
        else {
            this.show = false;
        }
        this.company.otpupdate().subscribe(function (data) {
            localStorage.setItem('isemail', "true");
            _this.company.mobileotpupdate().subscribe(function (data) {
                localStorage.setItem('ismobile', "true");
                if (_this.ctype == 0 || _this.ctype == 2)
                    _this.router.navigate(['/choose-plan']);
                else
                    _this.router.navigate(['/payment-confirmation']);
            });
        });
    };
    OptcheckComponent.prototype.resedotp = function () {
        var _this = this;
        this.submitted = false;
        this.show = false;
        this.company.resendotp().subscribe(function (data) {
            localStorage.setItem('otp', data.otp);
            _this.alerts.success("OTP resent successfully");
            _this.show = false;
        }, function (err) {
            //this.alerts.error("OTP resent successfully");
            _this.isLoginError = true;
        });
    };
    OptcheckComponent.prototype.resedmobileotp = function () {
        var _this = this;
        this.submitted = false;
        this.show = false;
        this.company.resendmobileotp().subscribe(function (data) {
            localStorage.setItem('otpmobile', data.otp);
            _this.alerts.success("OTP resent successfully");
            _this.show = false;
        }, function (err) {
            //this.alerts.error("OTP resent successfully");
            _this.isLoginError = true;
        });
    };
    OptcheckComponent.prototype.logout = function () {
        var _this = this;
        if (confirm("Are you sure you want to sign out?")) {
            this.userService.logout().subscribe(function (data) {
                localStorage.removeItem('userToken');
                localStorage.removeItem('userid');
                localStorage.removeItem('Name');
                localStorage.removeItem('isline');
                localStorage.removeItem('planid');
                localStorage.removeItem('ctype');
                localStorage.removeItem('isemail');
                localStorage.removeItem('ismobile');
                localStorage.removeItem('isadmin');
                localStorage.removeItem('ispermission');
                localStorage.removeItem('logoname');
                localStorage.removeItem('struser');
                localStorage.removeItem('menu');
                _this.router.navigate(['/login']);
            });
            ;
        }
    };
    return OptcheckComponent;
}());
exports.OptcheckComponent = OptcheckComponent;
