import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../../services/payment.service';

import { CheckserviceService} from '../../../services/checkservice.service';
import { ChecksystemService } from '../../../services/checksystem.service';

@Component({
  selector: 'app-purchasepaymentsuccess',
  templateUrl: './purchasepaymentsuccess.component.html',
  styleUrls: ['./purchasepaymentsuccess.component.css']
})
export class PurchasepaymentsuccessComponent implements OnInit {



  ctype: any;
  token='';
  planid:any;
  wizard3:any = false;
  wizard4:any = false;
  paymentstatus:any;
  serviceArray: any = [];

  serviceObjectArray: any =[];

  constructor(private router: Router,private activatedRoute: ActivatedRoute, public paymentService: PaymentService,
    
    private check: ChecksystemService,
    private checkService: CheckserviceService,
    ) { }

  ngOnInit() {  
    
    
    this.paymentstatus = this.activatedRoute.snapshot.paramMap.get("status");
    this.token = this.activatedRoute.snapshot.paramMap.get("id");

    this.ctype  = parseInt(localStorage.getItem('ctype'));

    console.log(this.paymentstatus);
    console.log(this.token);
    console.log(this.ctype);

      if(this.paymentstatus == 'success')
      {
        this.wizard3 = true;
        this.wizard4 = false;

        this.serviceObjectArray = JSON.parse(localStorage.getItem('productstring'));


        if(this.serviceObjectArray)
        {
        var saveOrder = {   
          "token":    this.token,    
          "data": this.serviceObjectArray,          
        };
        this.checkService.completeorder(saveOrder).subscribe((payment: any) => {
         
          this.serviceArray =[];
          localStorage.removeItem('productstring');
          this.serviceObjectArray = [];
          localStorage.removeItem('productstring')
          
        });
      }
      }
      else
      {
        this.wizard3 =false;
        this.wizard4 =true;
      }
      


  }
}
