import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Employee } from '../../models/employee.model';
import { CountryService } from '../../services/country.service';
import { EmployeeService } from '../../services/employee.service';
import { JobService } from '../../services/job.service';
import { ExcelService } from '../../services/excel.service';
import { VisaService } from '../../services/visa.service';
import { PassportService } from '../../services/passport.service';
import { EmployeementService } from '../../services/employeement.service';
import { CosService } from '../../services/cos.service';
import { LeaveService } from '../../services/leave.service';
import { TimesheetService } from '../../services/timesheet.service';
import { ExpenseService } from '../../services/expense.service';
import { GraphreportService } from '../../services/graphreport.service';
import * as $ from 'jquery';
import * as CanvasJS from './../../admin/canvasjs.min';

@Component({
  selector: 'app-employeereport',
  templateUrl: './employeereport.component.html',
  styleUrls: ['./employeereport.component.css']
})

export class EmployeereportComponent implements OnInit {
  EmployeeForm: FormGroup;
  employeeDetail = new Employee();
  employeelist: any;
  departmentdatalist: any;
  countrylist: any;
  joblist: any;
  data: any;
  exceldata = [];
  listage: any;
  employeedata: any;
  display: any;
  listdisplay: any;
  graphdisplay: any;
  public searchText: string;
  record: number;
  items: Array<any>;
  pageOfItems: Array<any>;

  constructor(
    private excelService: ExcelService,
    private formBuilder: FormBuilder,
    private country: CountryService,
    private employee: EmployeeService,
    private jobser: JobService,
    private visareport: VisaService,
    private passportreport: PassportService,
    private employeementreport: EmployeementService,
    private cosreport: CosService,
    private leave: LeaveService,
    private timesheet: TimesheetService,
    private expense: ExpenseService,
    private graphReportService: GraphreportService
  ) { }

  ngOnInit() {
    this.graphdisplay = "block";
    this.listdisplay = "none";
    this.display = "none";
    this.EmployeeForm = this.formBuilder.group({
      fname: [''],
      lname: [''],
      emailid: [''],
      phonenumber: [''],
      jobid: [''],
      nationalityid: [''],
      ninumber: [''],
      searctext: ['']
    });

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    var value3 = "{}";
    this.employee.getemployeelist(value3).subscribe((listdetails: any) => {
      this.data = listdetails;
      this.record = this.data.length;

      if (this.record < 30) {
        this.pageOfItems = this.data;
      }
      else {
        this.items = this.data;
      }

      // console.log(this.data);
      var maleArray = [];
      var femaleArray = [];
      var totalArray = [];
      listdetails.forEach(function (value) {
        totalArray.push(value.employeemasterid);
        if (value.gender == true) {
          maleArray.push(value.employeemasterid);
        }
        else if (value.gender == false) {
          femaleArray.push(value.employeemasterid);
        }
      });

      var chart = new CanvasJS.Chart("chartContainer", {
        animationEnabled: true,
        title: {
          text: "Gender Profile"
        },
        data: [{
          type: "pie",
          startAngle: 240,
          yValueFormatString: "##0.00\"%\"",
          indexLabel: "{label} {y}",
          dataPoints: [
            { y: (maleArray.length / listdetails.length) * 100, label: "Male" },
            { y: (femaleArray.length / listdetails.length) * 100, label: "Female" },
            //{ y: (totalArray.length / listdetails.length) * 100, label: "Employee" },
          ]
        }]
      });
      chart.render();
    });
    this.jobser.getjoblist().subscribe((jobdetails: any) => {
      this.joblist = jobdetails;
    });

    this.graphReportService.getemployeeagetotal().subscribe((departmentdata: any) => {
      this.departmentdatalist = departmentdata;
      var malegraphdataarray = [];
      var femalegraphdataarray = [];
      var totalgraphdataarray = [];

      var tage18 = 0;
      var tage25 = 0;
      var tage35 = 0;
      var tage45 = 0;
      var tage55 = 0;

      var age18 = 0;
      var age25 = 0;
      var age35 = 0;
      var age45 = 0;
      var age55 = 0;

      var fage18 = 0;
      var fage25 = 0;
      var fage35 = 0;
      var fage45 = 0;
      var fage55 = 0;

      departmentdata.forEach((obj, index) => {
        if (obj.gender == true) {
          switch (true) {
            case (obj.agetotal >= 17 && obj.agetotal <= 25):
              age18 = age18 + 1;
              tage18 = tage18 + 1;
              break;
            case (obj.agetotal >= 26 && obj.agetotal <= 35):
              age25 = age25 + 1;
              tage25 = tage25 + 1;
              break;
            case (obj.agetotal >= 36 && obj.agetotal <= 45):
              age35 = age35 + 1;
              tage35 = tage35 + 1;
              break;
            case (obj.agetotal >= 46 && obj.agetotal <= 55):
              age45 = age45 + 1;
              tage45 = tage45 + 1;
              break;
            case (obj.agetotal >= 56 && obj.agetotal <= 75):
              age55 = age55 + 1;
              tage55 = tage55 + 1;
              break;
            default:
            // code block
          }
        }
        else {
          switch (true) {
            case (obj.agetotal >= 17 && obj.agetotal <= 25):
              fage18 = fage18 + 1;
              tage18 = tage18 + 1;
              break;
            case (obj.agetotal >= 26 && obj.agetotal <= 35):
              fage25 = fage25 + 1;
              tage25 = tage25 + 1;
              break;
            case (obj.agetotal >= 36 && obj.agetotal <= 45):
              fage35 = fage35 + 1;
              tage35 = tage35 + 1;
              break;
            case (obj.agetotal >= 46 && obj.agetotal <= 55):
              fage45 = fage45 + 1;
              tage45 = tage45 + 1;
              break;
            case (obj.agetotal >= 56 && obj.agetotal <= 75):
              fage55 = fage55 + 1;
              tage55 = tage55 + 1;
              break;
            default:
          }
        }
      });

      totalgraphdataarray.push({ x: 1, y: tage18, label: "Below 25" });
      totalgraphdataarray.push({ x: 2, y: tage25, label: "26-35" });
      totalgraphdataarray.push({ x: 3, y: tage35, label: "36-45" });
      totalgraphdataarray.push({ x: 4, y: tage45, label: "46-55" });
      totalgraphdataarray.push({ x: 5, y: tage55, label: "Above 55" });

      malegraphdataarray.push({ x: 1, y: age18, label: "Below 25" });
      malegraphdataarray.push({ x: 2, y: age25, label: "26-35" });
      malegraphdataarray.push({ x: 3, y: age35, label: "36-45" });
      malegraphdataarray.push({ x: 4, y: age45, label: "46-55" });
      malegraphdataarray.push({ x: 5, y: age55, label: "Above 55" });

      femalegraphdataarray.push({ x: 1, y: fage18, label: "Below 25" });
      femalegraphdataarray.push({ x: 2, y: fage25, label: "26-35" });
      femalegraphdataarray.push({ x: 3, y: fage35, label: "36-45" });
      femalegraphdataarray.push({ x: 4, y: fage45, label: "46-55" });
      femalegraphdataarray.push({ x: 5, y: fage55, label: "Above 55" });

      this.listage = [
        {
          typename: "Below 25",
          malecount: age18,
          femalecount: fage18,
          totalcount: age18 + fage18
        },
        {
          typename: "26-35",
          malecount: age25,
          femalecount: fage25,
          totalcount: age25 + fage25
        }, {
          typename: "36-45",
          malecount: age35,
          femalecount: fage35,
          totalcount: age35 + fage35
        }, {
          typename: "46-55",
          malecount: age45,
          femalecount: fage45,
          totalcount: age45 + fage45
        }, {
          typename: "Above 55",
          malecount: age55,
          femalecount: fage55,
          totalcount: age55 + fage55
        }];

      var data = [
        {
          type: "stackedColumn",
          showInLegend: true,
          color: "#696661",
          name: "Employee",
          yValueFormatString: "#0",
          dataPoints: totalgraphdataarray
        }
        // {
        //   type: "stackedColumn",
        //   showInLegend: true,
        //   color: "#696661",
        //   name: "Male",
        //   yValueFormatString: "#0",
        //   dataPoints: malegraphdataarray
        // },
        // {
        //   type: "stackedColumn",
        //   showInLegend: true,
        //   color: "#B6B1A8",
        //   yValueFormatString: "#0",
        //   name: "Female",
        //   dataPoints: femalegraphdataarray
        // }
      ];
      // this.departmentreportage(data);
    });

    this.graphReportService.getdepartmentgraphList().subscribe((departmentdata: any) => {
      this.departmentdatalist = departmentdata;
      var totalemparray = [];
      var malegraphdataarray = [];
      var femalegraphdataarray = [];
      departmentdata.forEach((obj, index) => {
        totalemparray.push({ x: index + 1, y: obj.count, label: obj.typename })
        // malegraphdataarray.push({ x: index + 1, y: obj.mcount, label: obj.typename });
        // femalegraphdataarray.push({ x: index + 1, y: obj.fcount, label: obj.typename });
      });
      var data = [
        {
          type: "stackedBar",
          name: "Employee",
          showInLegend: "true",
          xValueFormatString: "DD, MMM",
          yValueFormatString: "#0",
          dataPoints: totalemparray
        }
        // {
        //   type: "stackedBar",
        //   name: "Male",
        //   showInLegend: "true",
        //   xValueFormatString: "DD, MMM",
        //   yValueFormatString: "#0",
        //   dataPoints: malegraphdataarray
        // },
        // {
        //   type: "stackedBar",
        //   name: "Female",
        //   showInLegend: "true",
        //   xValueFormatString: "DD, MMM",
        //   yValueFormatString: "#0",
        //   dataPoints: femalegraphdataarray
        // }
      ];
      this.departmentreport(data);
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  showcondition(type: any) {
    if (type == 'list') {
      this.graphdisplay = "none";
      this.listdisplay = "block";
    }
    else {
      this.listdisplay = "none";
      this.graphdisplay = "block";
    }
  }

  departmentreportage(data: any) {
    var chart = new CanvasJS.Chart("chartContainer5", {
      animationEnabled: true,
      title: {
        text: "Google - Consolidated Quarterly Revenue",
        fontFamily: "arial black",
        fontColor: "#695A42"
      },
      axisX: {
        interval: 1,
        intervalType: "year"
      },
      axisY: {
        valueFormatString: "#0",
        gridColor: "#B6B1A8",
        tickColor: "#B6B1A8"
      },
      toolTip: {
        shared: true,
        content: toolTipContent
      },
      data: data
    });
    chart.render();

    function toolTipContent(e) {
      var str = "";
      var total = 0;
      var str2, str3;
      for (var i = 0; i < e.entries.length; i++) {
        var str1 = "<span style= \"color:" + e.entries[i].dataSeries.color + "\"> " + e.entries[i].dataSeries.name + "</span>: $<strong>" + e.entries[i].dataPoint.y + "</strong>bn<br/>";
        total = e.entries[i].dataPoint.y + total;
        str = str.concat(str1);
      }
      str2 = "<span style = \"color:DodgerBlue;\"><strong>" + (e.entries[0].dataPoint.x).getFullYear() + "</strong></span><br/>";
      total = Math.round(total * 100) / 100;
      str3 = "<span style = \"color:Tomato\">Total:</span><strong> $" + total + "</strong>bn<br/>";
      return (str2.concat(str)).concat(str3);
    }
  }

  departmentreport(data: any) {
    var chart = new CanvasJS.Chart("chartContainer2", {
      animationEnabled: true,
      title: {
        text: "Department Employee Ratio"
      },
      axisX: {
        valueFormatString: "DDD"
      },
      axisY: {
        prefix: ""
      },
      toolTip: {
        shared: true
      },
      legend: {
        cursor: "pointer",
        itemclick: toggleDataSeries
      },
      data: data
    });
    chart.render();

    function toggleDataSeries(e) {
      if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      }
      else {
        e.dataSeries.visible = true;
      }
      chart.render();
    }
  }

  exportAsXLSX(): void {
    this.exceldata = [];
    this.data.forEach(obj => {
      this.employeedata = {
        "Name": obj.fname + " " + obj.mname + " " + obj.lname,
        "Email": obj.emailid,
        "Phone Number": obj.phonenumber,
        "NI Number": obj.ninumber,
        "Nationality": obj.nationality.countryname,
        "Country of Residence": obj.residence.countryname,
        "Country of Birth": obj.countryofbirth.countryname,
        "Place of Birth": obj.placeofbirth
      };
      this.exceldata.push(this.employeedata);
    });
    this.excelService.exportAsExcelFile(this.exceldata, 'Employee_Report');
  }
  onexcelt() {
    this.display = "block";
    var value3 = "{}";
    this.employee.getemployeelist(value3).subscribe((listdetails: any) => {
      if (listdetails)
        this.excelService.employeeexcel(listdetails);
      this.visareport.getvisalist(value3).subscribe((visadetails: any) => {
        if (visadetails)
          this.excelService.visaexcel(visadetails);
        this.passportreport.getpassportlist(value3).subscribe((passportdetails: any) => {
          if (passportdetails)
            this.excelService.passportexcel(passportdetails);
          this.employeementreport.getemployementlist(value3).subscribe((employementetails: any) => {
            if (employementetails)
              this.excelService.employementexcel(employementetails);
            this.cosreport.getcoslist(value3).subscribe((cosdetails: any) => {
              if (cosdetails)
                this.excelService.cosexcel(cosdetails);
              // this.leave.leavereport(value3).subscribe((leavereport: any) => {
              //   if (leavereport)
              //     this.excelService.leaveexcel(leavereport);
              //   this.timesheet.gettimesheetList(value3).subscribe((timesheetDetails: any) => {
              //     if (timesheetDetails)
              //       this.excelService.timesheetexcel(timesheetDetails);
              //     this.expense.getexpenseList(value3).subscribe((expenseDetails: any) => {
              //       if (expenseDetails)
              //         this.excelService.expenseexcel(expenseDetails);
              this.display = "none";
              this.excelService.exportAsExcelFileAll('Employee_Report');
              //     });

              //   });
              // });
            });
          });
        });
      });
    });
  }

  onSubmit(value3: any) {
    this.employee.getemployeelist(value3).subscribe((listdetails: any) => {
      this.data = listdetails;
    });
  }
}