import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})

export class AssetService {
  constructor(private commonService: CommonService) { }
  getassetlist(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/employee/assetlistbyadmin');   
  }

  getassetlistbyemployeeid(formvalue: any): Observable<any> 
  {
    return this.commonService.formpost('/employee/assetlistbyemployeeid', formvalue);   
  }

  assetdetailsUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/employee/assetupdate', value);
  }
  // ASSEST

  getassetcategory(data): Observable<any> 
  {
    return this.commonService.formpost('/company/assetcategory',data);   
  }  

  settingassetcategoryUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/assetcategoryupdatebyadmin', value);
  }

  getassetreport(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/employeeassetreport',data);   
  }

  assetUpdateChangeStatus(value:any): Observable<any> 
  {
    return this.commonService.formpost('/company/assetupdatechangestatus',value);
  }
}