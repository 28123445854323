<style>
    .invalid-feedback {
        display: block !important;
    }
</style>
<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__content">
                    <!-- <div class="textrotate2">
                        <ul>
                            <li>
                                <a [routerLink]="['/my-expense']">My Expense</a>
                            </li>
                            <li>
                                <a [routerLink]="['/expense-report']">Report</a>
                            </li>
                        </ul>
                    </div> -->
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row">
                                <div class="col-lg-12">
                                    <ul class="tabs__head montharea">
                                        <li><a [ngClass]="{'active': classactive == '0'}" (click)="addMonthsOfYear(0);">January </a></li>
                                        <li><a [ngClass]="{'active': classactive == '1'}" (click)="addMonthsOfYear(1);">February </a></li>
                                        <li><a [ngClass]="{'active': classactive == '2'}" (click)="addMonthsOfYear(2);">March </a></li>
                                        <li><a [ngClass]="{'active': classactive == '3'}" (click)="addMonthsOfYear(3);">April </a></li>
                                        <li><a [ngClass]="{'active': classactive == '4'}" (click)="addMonthsOfYear(4);">May</a></li>
                                        <li><a [ngClass]="{'active': classactive == '5'}" (click)="addMonthsOfYear(5);">June </a></li>
                                        <li><a [ngClass]="{'active': classactive == '6'}" (click)="addMonthsOfYear(6);">July </a></li>
                                        <li><a [ngClass]="{'active': classactive == '7'}" (click)="addMonthsOfYear(7);">August </a></li>
                                        <li><a [ngClass]="{'active': classactive == '8'}" (click)="addMonthsOfYear(8);">September </a></li>
                                        <li><a [ngClass]="{'active': classactive == '9'}" (click)="addMonthsOfYear(9);">October </a></li>
                                        <li><a [ngClass]="{'active': classactive == '10'}" (click)="addMonthsOfYear(10);">November </a></li>
                                        <li><a [ngClass]="{'active': classactive == '11'}" (click)="addMonthsOfYear(11);">December </a></li>
                                    </ul>
                                </div>
                                                <!-- <div class="col-lg-3">
                                <ul class="nav nav-pills">
                                    <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle active" data-toggle="dropdown" href="#" role="button"
                                        aria-haspopup="true" aria-expanded="false">Dropdown</a>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="#">Action</a>
                                        <a class="dropdown-item" href="#">Another action</a>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" href="#">Separated link</a>
                                    </div>
                                    </li>
                                </ul>
                                </div> -->
                            </div>
                            <div class="row row-sm mb-4 mt-4">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-md">
                                            <ng-template #modalContent let-close="close">
                                                <div class="modal-header">
                                                    <h5 class="modal-title">Create Expense {{headerdate}}</h5>
                                                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body" style="padding:0 30px;">
                                                    <!--<div class="row row-sm ">
                                                        <div class="col-lg-12 popheader">
                                                        <img src="https://timereport.techedgegroup.com/TimereportTechedge/WebContent/img/activityForm.png" />
                                                        Expense
                                                        </div>
                                                    </div>-->
                                                    <form [formGroup]="expenseformGroup" #expenseForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(expenseForm.value,'modalContent')">
                                                        <input type="hidden" formControlName="id" class="form-control" name="id" id="id" ngModel="{{expense.employeeexpenseapplyid}}">
                                                        <input type="hidden" formControlName="expenseremarksid" name="expenseremarksid" id="expenseremarksid" ngModel="{{expense.expenseremarksid}}">
                                                        <input type="hidden" formControlName="employeeid" class="form-control" class="form-control" required name="employeeid" id="employeeid" ngModel="{{expense.employeeid}}">
                                                        <input type="hidden" formControlName="start" class="form-control" class="form-control" required name="start" id="start" ngModel="{{ expense.start }}">
                                                        <input type="hidden" formControlName="end" class="form-control" class="form-control" required name="end" id="end" ngModel="{{expense.end}}">
                                                        <div *ngIf="editMsg" class="invalid-feedback">
                                                            <div>{{editMsg}}</div>
                                                        </div>
                                                        <div *ngIf="findLeave" class="invalid-feedback">
                                                            <div>Note: You have applied a leave for this date.</div>
                                                        </div>
                                                        
                                                        <div class="formgroup">
                                                            <div class="row row-sm mg-t-10">
                                                                <div class="col-lg-12">
                                                                    <label>Project*</label>
                                                                    <select name="projectid" id="projectid" class="form-control" ngModel='{{ expense.projectid }}' [ngClass]="{ 'is-invalid': submitted && f.projectid.errors }" formControlName="projectid">
                                                                        <option value="">Select Project</option>
                                                                        <option *ngFor="let ptype of projectlist" value="{{ ptype.projectid }}">{{ptype.projectname }}</option>>
                                                                    </select>
                                                                    <div *ngIf="submitted && f.projectid.errors" class="invalid-feedback">
                                                                        <div *ngIf="f.projectid.errors.required">Please Select Project</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row row-sm mg-t-10">
                                                                <div class="col-lg-12">
                                                                    <label>Expense Type*</label>
                                                                    <select name="expensetypeid" id="expensetypeid" class="form-control" ngModel='{{ expense.expensetypeid }}' [ngClass]="{ 'is-invalid': submitted && f.expensetypeid.errors }" formControlName="expensetypeid">
                                                                        <option value="">Select Expense Type</option>
                                                                        <option *ngFor="let etype of expensetypelist" value="{{ etype.id }}">{{ etype.typename }}</option>>
                                                                    </select>
                                                                    <div *ngIf="submitted && f.expensetypeid.errors" class="invalid-feedback">
                                                                        <div *ngIf="f.expensetypeid.errors.required">Please Select Expense Type</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row row-sm mg-t-10">
                                                                <div class="col-lg-12">
                                                                    <label>Location*</label>
                                                                    <input type="text" formControlName="locationname" [ngClass]="{ 'is-invalid': submitted && f.locationname.errors }" placeholder="eg: Birmingham" name="locationname" id="locationname" class="form-control" ngModel="{{ expense.locationname }}" value="{{ expense.locationname }}"
                                                                        maxlength="30">
                                                                    <div *ngIf="submitted && f.locationname.errors" class="invalid-feedback">
                                                                        <div *ngIf="f.locationname.errors.required">Please Enter Location</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row row-sm mg-t-10">
                                                                <div class="col-lg-8 pr-2">
                                                                    <label>Amount*</label>
                                                                    <div class="gbp_box">
                                                                        <input type="text" formControlName="amountpaid" [ngClass]="{ 'is-invalid': submitted && f.amountpaid.errors }" placeholder="eg: 20.50" class="" name="amountpaid" id="amountpaid" class="form-control" ngModel="{{ expense.amountpaid }}" value="{{ expense.amountpaid }}"
                                                                            required [maxlength]="5">
                                                                        <!-- <input type="number" name="input" ng-model="example.value" min="0" max="5" required> -->
                                                                        <div *ngIf="submitted && f.amountpaid.errors" class="invalid-feedback">
                                                                            <div *ngIf="f.amountpaid.errors.required">Please Enter Amount</div>
                                                                            <div *ngIf="f.amountpaid.errors.pattern">Please Enter Valid Amount</div>
                                                                        </div>
                                                                        <!-- <div class="GBP">
                                                                            
                                                                            <input type="text" disabled class="form-control" value="GBP" style="margin-left:10px;">
                                                                        </div> -->
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4 pl-0">
                                                                    <label>Currency*</label>
                                                                    <div class="gbp_box">
                                                                        <select name="currency" class="form-control" formControlName="currency" ngModel="{{ expense.currency }}" [ngClass]="{ 'is-invalid': submitted && f.currency.errors }">
                                                                            <option *ngFor="let cur of currencies" value="{{cur.currency}}">{{ cur.currency }}</option>
                                                                        </select>
                                                                        <div *ngIf="submitted && f.currency.errors" class="invalid-feedback">
                                                                            <div *ngIf="f.currency.errors.required">Please Select Currency</div>
                                                                            <!-- <div *ngIf="f.currency.errors.pattern">Please Enter Valid Amount</div> -->
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div class="row row-sm mg-t-10">
                                                                <div class="col-lg-12">
                                                                    <label>Paid By*</label>
                                                                    <select name="paidby" id="paidby" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.paidby.errors }" ngModel='{{ expense.paidby }}' formControlName="paidby">
                                                                        <option value="">Select Paid By</option>
                                                                        <option value="1">Company Paid</option>
                                                                        <option value="2">Client Paid</option>
                                                                        <option value="3">Employee Paid</option>
                                                                    </select>
                                                                    <div *ngIf="submitted && f.paidby.errors" class="invalid-feedback">
                                                                        <div *ngIf="f.paidby.errors.required">Please Select Paid By</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row row-sm mg-t-10">
                                                                <div class="col-lg-12">
                                                                    <label>Remarks*</label>
                                                                    <textarea formControlName="expenseremark" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.expenseremark.errors }" required name="expenseremark" id="expenseremark" ngModel="{{expense.expenseremark}}" maxlength="500" placeholder="Enter remarks here"></textarea>
                                                                    <div *ngIf="submitted && f.expenseremark.errors" class="invalid-feedback">
                                                                        <div *ngIf="f.expenseremark.errors.required">Please Enter Remarks</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row row-sm mg-t-10">
                                                                <div class="col-lg-12">
                                                                    <label>Upload Document</label>
                                                                    <div class="input-group mb-3">
                                                                        <div class="custom-file" (click)="file.click()">
                                                                            <input type="file" #file (change)="selectFile($event)">
                                                                            <label class="custom-file-label">{{filename}}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="invalid-feedback" *ngIf="filesizeerror">File size must be less than 2 MB</div>
                                                                    <div class="invalid-feedback" *ngIf="fileformaterror">Please choose a valid file format (jpg, png, tif, gif, pdf, doc, docx)</div>
                                                                </div>
                                                            </div>
                                                            <div class="row row-sm modal-footer">
                                                                <button type="submit" class="btn btn-primary com_btn" [disabled]="!disableBtn">Save</button>                                                              
                                                                <button *ngIf="(expense.employeeexpenseapplyid != 0) && (expense.hstatus == 1)" type="button" class="btn btn-danger com_btn" (click)="onDelete(expense.employeeexpenseapplyid)">Delete</button>
                                                                <button type="button" class="btn btn-secondary com_btn" id="closeModal" (click)="close()">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </ng-template>
                                          
                                            <div class="floatleft">
                                                <div class="col-md-12">
                                                    <h3 class="mytimesheet_heading"><span class="redcolor_text font22">My Expense </span> <span class="graycolor_text fonts16">({{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }})</span></h3>
                                                </div>
                                            </div>
                                            <div class="floatright">
                                                <div class="col-md-12 form-inline ">
                                                    <select name="year" id="year" class="form-control yearselect" (change)="onyearSubmit($event.target.value)" ngModel='{{ year }}'>
                                                        <option value="{{y}}" *ngFor="let y of yearList">{{ y }}</option>
                                                    </select>
                                                    <button type="submit" class="btn btn-danger com_btn" (click)="addNewEvent('Add',event)">Add Expense</button>
                                                    <button type="button" class="btn btn-dark com_btn" (click)="openlinemanagerModal('line-manager')">Line Manager</button>
                                                    <a [routerLink]="['/expense-report']" routerLinkActive="active-link" class="toggle_button">
                                                        <span class="lnr lnr-list"></span>
                                                        <span class="tooltip_show">List View</span>
                                                    </a>
                                                    <a [routerLink]="['/my-expense']" routerLinkActive="active-link" class="toggle_button">
                                                        <span class="lnr lnr-calendar-full"></span>
                                                        <span class="tooltip_show">Calendar View </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                            <div [ngSwitch]="view" class="row row-sm maine222" style="margin:20px auto; width: 98%;">
                                                <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [cellTemplate]="customCellTemplate" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
                                                    class="pre_tag">
                                                </mwl-calendar-month-view>
                                            </div>
                                            <ng-template #customCellTemplate let-day="day" let-locale="locale">
                                                <div class="cal-cell-top-add">
                                                    <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                                                    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
                                                    <span class="cal-day-number-add" *ngIf="day.date <= leaveavailabledate" style="border: 1px solid; border-radius: 5px;margin-right: 5px;margin-top: 5px;" (click)="addEvent('Add',event,day.date)"><i class="fa fa-plus"></i></span>
                                                </div>
                                                <!-- <div class="cal-cell-top">
                        </div> -->
                                                <div *ngIf="day.events.length > 0">
                                                    <div *ngFor="let event of day.events; let i = index" [attr.data-index]="i">
                                                        <div *ngIf="i < 2">
                                                            <div class="cal-events">
                                                                <div class="cal-courseevent" [ngClass]="event.Type" [style.background-color]="event.color.primary" [style.color]="event.color.secondary">
                                                                    <small> <a (click)="showAllEvents('event-viewer',event.id)">{{ event.title | slice:0:20 }}{{event.title.length > 20 ? '...' : ''}}</a>
                                                                    <i *ngIf="event.allDay == true" class="fa fa-fw fa-pencil" (click)="editEvent('Edited',event)"></i></small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="i == 2">
                                                            <small *ngIf="day.events.length > 2" class="exdot" (click)="showAllEvents('event-viewer',event.id)">
                                                                <i class="fa fa-fw fa-circle"></i>
                                                                <i class="fa fa-fw fa-circle"></i>
                                                                <i class="fa fa-fw fa-circle"></i>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                                <!-- col-8 -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .poplist {
        margin-bottom: 10px;
    }
    .poplist p {
        margin-bottom: 0px;
    }
</style>
<jw-modal id="event-viewer" class="modal listofwork" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myLargeModalLabel">My Expense</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" (click)="closeModal('event-viewer');">×</span>
            </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="leave_apply_section">
                        <div class="white_box_shadow leave_width" *ngFor="let exp of dayexpense">
                            <div class="leave_app_head">
                                <div class="heading width80 mb-2 m-0">
                                    <span class="leave_type">Project</span>
                                    <h4>{{ exp.project.projectname }}</h4>
                                </div>
                                <span class="btn btn-danger" *ngIf="exp.hstatus == '3'"> <i class="fa fa-clock-o" aria-hidden="true"></i><span>Rejected</span> </span>
                                <span class="btn btn-warning" *ngIf="exp.hstatus == '1'"> <i class="fa fa-clock-o" aria-hidden="true"></i><span>Pending</span> </span>
                                <span class="btn btn-sucess" *ngIf="exp.hstatus == '2'"> <i class="fa fa-clock-o" aria-hidden="true"></i><span>Approved</span> </span>
                            </div>
                            <div class="expenbox_details_sec leave_details_sec">
                                <div class="expenbox">
                                    <div class="leave_icon">
                                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                                        <p class="leavefrom">
                                            <span>Location : </span>{{exp.locationname}}
                                        </p>
                                    </div>
                                    <div class="leave_icon">
                                        <i class="fa fa-book" aria-hidden="true"></i>
                                        <p class="leavefrom">
                                            <span>Expense type : </span>{{ exp.expensetype.typename }}
                                        </p>
                                    </div>
                                </div>
                                <div class="expenbox">
                                    <div class="leave_icon">
                                        <i class="fa fa-gbp" aria-hidden="true"></i>
                                        <p class="leavefrom">
                                            <span>Value : </span>{{ exp.amountpaid }} {{ exp.currency }}
                                        </p>
                                    </div>
                                    <div class="leave_icon">
                                        <i class="fa fa-hourglass-end" aria-hidden="true"></i>
                                        <p class="leavefrom">
                                            <span>Paid By : </span>
                                            <b *ngIf="exp.paidby == 1">Company Paid</b>
                                            <b *ngIf="exp.paidby == 2">Client Paid</b>
                                            <b *ngIf="exp.paidby == 3">Employee Paid</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="leave_description">
                                <p>
                                    <i>
                                        {{exp.remark.expenseremark }}
                                    </i>
                                </p>
                            </div>
                            <div class="leave_edit_cane_btn float-right">
                                <button class="btn btn-danger com_btn" type="submit" *ngIf="exp.hstatus == '1'" (click)="editEventOfList('Edited',exp.employeeexpenseapplyid, 'event-viewer')">Edit Expense</button>
                                <button class="btn btn-primary com_btn" *ngIf="exp.imagename !='' && exp.imagename !=null" (click)="openVisaExpenceModal3('pdf-image-viewer-myexpenceystem',exp.imagename)" type="button">View</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row-sm mg-t-10 mg-b-10 float-right">
                        <button class="btn btn-secondary com_btn" type="submit" (click)="closeModal('event-viewer');">Cancel</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="pdf-image-viewer-myexpenceystem" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-myexpenceystem');">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <iframe *ngIf="imgexpencesrc == false" [src]="iframeexpensesrc" width="100%" height="600" frameborder="0"></iframe>
                            <img *ngIf="imgexpencesrc == true" [src]="iframeexpensesrc" width="100%" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>


<jw-modal id="line-manager" class="modal listofwork" role="dialog">
    <div role="document" class="formgroup">
        <div class="bd-example-modal-lg">
            <div class="modal-dialog modal-lg">
                <div class="modal-content" >
                    <div class="modal-header">
                        <h4 class="modal-title" id="myLargeModalLabel">Line Manager</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true" (click)="closeModal('line-manager');">×</span>
                    </button>
                    </div> 
                    <div class="modal-body">
                        <div class="container">
                                  
                            <!-- <div>
                              {{linemanager.fname}}
                            </div> -->
                            <div class="job_list_section employee_list_section"  *ngIf="linemanager">
                                <div class="job_detils_date">
                                    <div class="visa_user_pic">
                                     
                                        <img *ngIf="linemanager.imagename" src="{{linemanager.imagename}}" class="img width100" alt="{{ linemanager.fname }} {{ linemanager.mname }} {{ linemanager.lname}}">
                                        <img *ngIf="linemanager.gender == true && (linemanager.imagename == null || linemanager.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ linemanager.fname }} {{ linemanager.mname }} {{ linemanager.lname}}">
                                        <img *ngIf="linemanager.gender == false && (linemanager.imagename == null || linemanager.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ linemanager.fname }} {{ linemanager.mname }} {{ linemanager.lname}}">
                                    </div>

                                </div>
                                <div class="job_detils_date width30">
                                    <h5 class="job_profile font_17">{{linemanager.fname}} {{linemanager.mname}} {{linemanager.lname}}</h5>
                                    <p class="email ellipsis"><i aria-hidden="true" class="fa fa-envelope-o"></i> {{linemanager.emailid}}</p>
                                    <p class="phone "><i aria-hidden="true" class="fa fa-phone"></i> {{linemanager.phonenumber}}</p>
                                </div>
                                <div class="job_detils_date width15">
                                    <p class="job_address"> NI Number</p>
                                    <p class="job_profile">{{linemanager.ninumber}}</p>
                                </div>
                                <div class="job_detils_date width15">
                                    <p class="job_address">Employee ID</p>
                                    <p class="job_profile"> {{linemanager.employeeid}}</p>
                                </div>
                                <div class="job_detils_date width22">
                                    <p class="job_address">Employee Role</p>
                                    <p class="badge badge-danger ellipsis width100 text-left" *ngIf="linemanager.emprole">{{linemanager.emprole}}</p>
                                    <p class="badge badge-dark ellipsis width100 text-left" *ngIf="!linemanager.emprole">N/A</p>
                                </div>
                                <!-- <div class="job_detils_date  width13 d-flex aligncenter">
                                    <a type="submit" class="btn btn-primary com_btn"  *ngIf="empperm > 2" [routerLink]="'/employeedetails/'+ employee.employeemasterid">View Details</a>
                                  

                                </div> -->
                            </div>
                            <!-- <app-mycurrentemployment></app-mycurrentemployment>  -->
                            <div class="job_list_section employee_list_section" *ngIf="!linemanager">
                                No Line Manager
                            </div>
                           
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</jw-modal>