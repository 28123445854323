import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-admindocument',
  templateUrl: './admindocument.component.html',
  styleUrls: ['./admindocument.component.css']
})
export class AdmindocumentComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
  }

}
