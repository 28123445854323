"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_service_1 = require("../../../services/employee.service");
var modal_service_1 = require("../../../_services/modal.service");
var profile_model_1 = require("../../../models/overview/profile.model");
var country_service_1 = require("../../../services/country.service");
var common_service_1 = require("../../../services/common.service");
var job_service_1 = require("../../../services/job.service");
var candidate_service_1 = require("../../../services/candidate.service");
var user_service_1 = require("../../../services/user.service");
var permission_service_1 = require("../../../services/permission.service");
var personaltype_service_1 = require("../../../services/personaltype.service");
var company_service_1 = require("../../../services/company.service");
var alert_service_1 = require("../../../services/alert.service");
var router_1 = require("@angular/router");
var ListemployeeComponent = /** @class */ (function () {
    function ListemployeeComponent(userService, employeeService, country, modalService, commonService, formBuilder, jobdetails, router, candidatedetails, permissiondetails, dltypeService, companyService, alerts) {
        this.userService = userService;
        this.employeeService = employeeService;
        this.country = country;
        this.modalService = modalService;
        this.commonService = commonService;
        this.formBuilder = formBuilder;
        this.jobdetails = jobdetails;
        this.router = router;
        this.candidatedetails = candidatedetails;
        this.permissiondetails = permissiondetails;
        this.dltypeService = dltypeService;
        this.companyService = companyService;
        this.alerts = alerts;
        this.th = [];
        this.emailexist = false;
        this.mindob = new Date();
        this.maxdob = new Date();
        this.empidexist = false;
        this.usernameexist = false;
        this.defaultVal = "";
    }
    ListemployeeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentyear = (new Date()).getFullYear();
        this.nextyear = (new Date()).getFullYear() + 1;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.Userid = localStorage.getItem('userid');
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.empperm = this.permission.m16;
        this.ismail = false;
        this.mindob.setDate(this.mindob.getDate() - 23741);
        this.maxdob.setDate(this.maxdob.getDate() - 574);
        this.employeeForm = this.formBuilder.group({
            fname: ['', forms_1.Validators.required],
            mname: [''],
            lname: ['', forms_1.Validators.required],
            empid: ['', forms_1.Validators.required],
            gender: ['', forms_1.Validators.required],
            placeofbirth: ['', forms_1.Validators.required],
            maritalstatus: ['', forms_1.Validators.required],
            ninumber: ['', forms_1.Validators.required],
            username: ['', forms_1.Validators.required],
            jobid: [''],
            candidateid: [''],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            phonenumber: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            residenceid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            countryofbirthid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            dateofbirth: ['', forms_1.Validators.required],
            hiredate: ['', forms_1.Validators.required],
            emprole: ['', forms_1.Validators.required],
            ismail: [''],
            currentyearleave: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.pattern("^[0-9]*$"), forms_1.Validators.min(1)])],
            nextyearleave: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.pattern("^[0-9]*$"), forms_1.Validators.min(1)])]
        });
        this.terminationForm = this.formBuilder.group({
            employeeid: ['', forms_1.Validators.required],
            terminationdate: ['', forms_1.Validators.required],
            terminationreason: ['', forms_1.Validators.required],
            oktorehire: ['', forms_1.Validators.required],
            regrettermination: ['', forms_1.Validators.required]
        });
        this.overviewData = new profile_model_1.Profile();
        this.terminationDetail = new profile_model_1.Termination();
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.jobdetails.getjobList().subscribe(function (jobdetails) {
            _this.joblist = jobdetails;
        });
        this.dltypeService.getactivepersonaltypelist().subscribe(function (dltypes) {
            _this.dltypelist = dltypes;
        });
        this.dltypeService.getdrivinglicenselist().subscribe(function (dlicense) {
            _this.dllist = dlicense;
        });
        this.permissiondetails.bindallmenu().subscribe(function (menudata) {
            var permissionstring = "[{";
            var perarray = [];
            menudata.forEach(function (valuemenu) {
                perarray.push('"' + valuemenu.clname + '": 0');
            });
            var perstr = perarray.join();
            permissionstring = permissionstring + perstr + "}]";
            _this.permissionstring = permissionstring;
        });
        this.getAllEmployees();
    };
    ListemployeeComponent.prototype.getAllEmployees = function () {
        var _this = this;
        this.employeeService.getlistofemployee().subscribe(function (employeedata) {
            // employeedata.forEach(function (value) {
            //   var tg;
            //   tg = JSON.parse(value.permission.toString());
            //   tg.forEach(function (value4) {
            //   });
            // });
            _this.data = employeedata;
            _this.record = _this.data.length;
            _this.datalist = _this.data;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    ListemployeeComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    Object.defineProperty(ListemployeeComponent.prototype, "f", {
        get: function () { return this.employeeForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListemployeeComponent.prototype, "g", {
        get: function () { return this.terminationForm.controls; },
        enumerable: true,
        configurable: true
    });
    ListemployeeComponent.prototype.resendwelcomemail = function (employeeid) {
        var _this = this;
        var data = { 'employeeid': employeeid };
        this.employeeService.employeewelcomeemail(data).subscribe(function (data) {
            if (data.susses == 'true') {
                _this.alerts.success('Welcome email resend successful.');
            }
            else {
                _this.alerts.error('Something went wrong. Please try again later.');
            }
        });
    };
    ListemployeeComponent.prototype.onSubmit = function (employeeform1, modelid) {
        var _this = this;
        this.submitted = true;
        if (this.employeeForm.invalid) {
            return;
        }
        this.disableBtn = false;
        employeeform1.id = this.Userid;
        employeeform1.permission = this.permissionstring;
        var sd = new Date(employeeform1.dateofbirth);
        employeeform1.dateofbirth = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        var ed = new Date(employeeform1.hiredate);
        employeeform1.hiredate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        this.userService.isCompanyEmailRegisterd(employeeform1.emailid).subscribe(function (data) {
            if (data.sussess == 'success') {
                _this.emailexist = true;
                _this.disableBtn = true;
            }
            else {
                _this.userService.isUserRegisterd(employeeform1.username).subscribe(function (data) {
                    if (data.sussess == 'success') {
                        _this.usernameexist = true;
                        _this.disableBtn = true;
                    }
                    else {
                        _this.companyService.checkcompanyemployeeid({ 'empid': employeeform1.empid }).subscribe(function (checkempid) {
                            if (checkempid.sussess == 'success') {
                                _this.empidexist = true;
                                _this.disableBtn = true;
                            }
                            else {
                                _this.disableBtn = false;
                                _this.employeeService.employeeUpdateForm1(employeeform1).subscribe(function (data) {
                                    if (employeeform1.currentyearleave != '') {
                                        var currentYearLeaveData = {
                                            'id': 0,
                                            'employeeid': data.data.employeemasterid,
                                            'leavecount': employeeform1.currentyearleave,
                                            'payrolyear': _this.currentyear,
                                            'userid': _this.Userid
                                        };
                                        _this.employeeService.saveEmployeeTotalLeaves(currentYearLeaveData).subscribe(function (data) {
                                        });
                                    }
                                    if (employeeform1.nextyearleave != '') {
                                        var nextYearLeaveData = {
                                            'id': 0,
                                            'employeeid': data.data.employeemasterid,
                                            'leavecount': employeeform1.nextyearleave,
                                            'payrolyear': _this.nextyear,
                                            'userid': _this.Userid
                                        };
                                        _this.employeeService.saveEmployeeTotalLeaves(nextYearLeaveData).subscribe(function (data) {
                                        });
                                    }
                                    _this.getAllEmployees();
                                    // this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
                                    //   this.data = employeedata;
                                    _this.modalService.close(modelid);
                                    // });
                                });
                            }
                        });
                    }
                });
            }
        });
    };
    ListemployeeComponent.prototype.onUserNameOutEvent = function (event) {
        var _this = this;
        if (event.target.value == '') {
            this.usernameexist = false;
        }
        else {
            this.usernameexist = false;
            var dbvalue = { 'username': event.target.value };
            this.userService.isUserRegisterd(event.target.value).subscribe(function (data) {
                // this.userService.isUserRegisterd(event.target.value).subscribe((data2: any) => {
                if (data.sussess == 'false') {
                    _this.usernameexist = false;
                }
                else {
                    _this.usernameexist = true;
                }
            });
        }
    };
    ListemployeeComponent.prototype.onSubmitTermination = function (terminationform, modelid) {
        var _this = this;
        this.submitted = true;
        if (this.terminationForm.invalid) {
            return;
        }
        this.employeeService.employeeSubmitTermination(terminationform).subscribe(function (data) {
            _this.employeeService.getlistofemployee().subscribe(function (employeedata) {
                _this.data = employeedata;
                _this.employeeService.getlistofemployee().subscribe(function (employeedata) {
                    employeedata.forEach(function (value) {
                        var tg;
                        tg = JSON.parse(value.permission.toString());
                        tg.forEach(function (value4) {
                        });
                    });
                    _this.data = employeedata;
                    _this.record = _this.data.length;
                    if (_this.record < 30) {
                        _this.pageOfItems = _this.data;
                    }
                    else {
                        _this.items = _this.data;
                    }
                    _this.alerts.success('Employee has been terminated successfully');
                    _this.modalService.close(modelid);
                });
            });
        });
    };
    ListemployeeComponent.prototype.onFocusOutEvent = function (event) {
        var _this = this;
        console.log('email id', event.target.value);
        this.userService.isCompanyEmailRegisterd(event.target.value).subscribe(function (data) {
            if (data.sussess == 'success') {
                _this.emailexist = true;
            }
            else {
                _this.emailexist = false;
            }
        });
    };
    ListemployeeComponent.prototype.onChangeStatus = function (evt, emp) {
        var _this = this;
        var status;
        if (evt.target.checked) {
            status = '1';
        }
        else if (!evt.target.checked) {
            status = '0';
        }
        this.employeeService.employeeUpdateChangeStatus({ 'userid': this.Userid, 'id': emp.employeemasterid, 'estatus': evt.target.checked }).subscribe(function (data) {
            if (status == '1') {
                _this.alerts.success('Employee has been activated successfully');
            }
            else if (status == '0') {
                _this.alerts.error('Employee has been deactivated successfully');
            }
        });
    };
    ListemployeeComponent.prototype.focusFunction = function () {
        this.emailexist = false;
    };
    ListemployeeComponent.prototype.isEmailUnique = function (control) {
        var _this = this;
        var q = new Promise(function (resolve, reject) {
            _this.userService.isEmailRegisterd(control.value).subscribe(function () {
                resolve(null);
            }, function () { resolve({ 'isEmailUnique': true }); });
        });
        return q;
    };
    ListemployeeComponent.prototype.onSelectjob = function (job_id) {
        var _this = this;
        var data = { 'jobid': job_id, 'id': this.Userid };
        this.candidatedetails.getselectedcandidate(data).subscribe(function (candidatedetails) {
            return _this.candidatelist = candidatedetails;
        });
    };
    ListemployeeComponent.prototype.onSelectCandidate = function (candidateid) {
        var _this = this;
        var data = { 'candidateid': candidateid, 'id': this.Userid };
        this.candidatedetails.getselectedcandidatebyid(data).subscribe(function (candidatedetails) {
            _this.overviewData.fname = candidatedetails.fname;
            _this.overviewData.mname = candidatedetails.mname;
            _this.overviewData.lname = candidatedetails.lname;
            _this.overviewData.phonenumber = candidatedetails.phonenumber;
            _this.overviewData.emailid = candidatedetails.emailid;
            // return this.candidatelist = candidatedetails
        });
    };
    ListemployeeComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    ListemployeeComponent.prototype.openModal2 = function (id) {
        var _this = this;
        this.submitted = false;
        this.disableBtn = true;
        this.overviewData = new profile_model_1.Profile();
        this.overviewData.gender = "1";
        this.overviewData.maritalstatus = "0";
        this.phonecheck = "";
        this.overviewData.jobid = "";
        this.overviewData.candidateid = "";
        this.overviewData.nationalityid = "";
        this.overviewData.residenceid = "";
        this.overviewData.countryofbirthid = "";
        this.overviewData.ismail = false;
        this.companyService.checkPlan({ 'userid': this.Userid }).subscribe(function (checkemployeecount) {
            if (checkemployeecount.success == "true") {
                // console.log(this.overviewData);
                _this.modalService.open(id);
            }
            else {
                _this.modalService.open("upgrade-plan");
            }
        });
    };
    ListemployeeComponent.prototype.closeModal = function (id) {
        var _this = this;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.modalService.close(id);
    };
    ListemployeeComponent.prototype.openModelTerminate = function (empid, id) {
        this.submitted = false;
        this.terminationDetail.employeeid = empid;
        this.terminationDetail.oktorehire = "";
        this.terminationDetail.regrettermination = "";
        this.modalService.open(id);
    };
    return ListemployeeComponent;
}());
exports.ListemployeeComponent = ListemployeeComponent;
