import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyService } from '../../services/company.service';
import { DomSanitizer } from '@angular/platform-browser';

import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  cardlist: any;
  logoimage: any;
  logoimage2: any;
  newlogo: any;
  constructor(private router: Router, public companyService: CompanyService, private userService: UserService, private sanitizer: DomSanitizer) { }

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }else {
      this.userService.checkaccesstokenbyid(userid).subscribe((data: any) => {
        if (data.status == false) {
         
        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('Name');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('ctype');
        localStorage.removeItem('isemail');
        localStorage.removeItem('ismobile');
        localStorage.removeItem('isadmin');
        localStorage.removeItem('ispermission');
        localStorage.removeItem('logoname');
        localStorage.removeItem('struser');
        localStorage.removeItem('menu');
          this.router.navigate(['/login']);
        }     
      });
    }






    var ctype  = parseInt(localStorage.getItem('ctype'));
    var struser= localStorage.getItem('struser');

    if(ctype ==0 && struser == '')
    {
      this.router.navigate(['/choose-plan']);
    }

    if(ctype ==1 || ctype ==2){
      this.router.navigate(['/check-dashboard']);
    }  
    
    // this.ctype  = parseInt(localStorage.getItem('ctype'));

    if(ctype === 3)
    {
      this.router.navigate(['/reference-dashboard']);
    }
    

    // this.logoimage = localStorage.getItem('logoname');
    //   console.log(this.logoimage2);
    //   this.logoimage2='https://complygate.s3.amazonaws.com/dev/employee/2/1590067122028_MartinSantiago.png';
    //   this.getBase64ImageFromUrl(this.logoimage2)
    //   .then(result => this.logoimage = result)
    //   .catch(err => console.error(err));
    //  console.log(this.logoimage);
    //   this.newlogo = this.getBase64ImageFromUrl(this.logoimage2);
    //   console.log(this.newlogo);
    //   this.logoimage= this.newlogo.__zone_symbol__value;

    this.companyService.checkstripecustomer().subscribe((data: any) => {
      if (data == null) {
        // this.router.navigate(['/create-customer']);
      }
      else if (data.deleted) {
        // this.router.navigate(['/create-customer']);
      }
      else {
        this.cardlist = data;
      }
    });
  }

  transform(html) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  }
  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      // console.log(blob);
      reader.readAsDataURL(blob);
    })
  }
}