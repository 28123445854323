import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  constructor(private commonService: CommonService) { }

  getcandidateList(value:any): Observable<any> 
  {
    return this.commonService.formpost('/candidatelistbyjob',value);
  }

  getcandidatebycompanyList(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/candidatelistbycompany');
  }
  candidatedetailsUpdate(value:any): Observable<any> 
  {
    return this.commonService.formpost('/updatecandidatedetails',value);
  }

  candidatedetailsCreate(value:any): Observable<any> 
  {
    return this.commonService.formpost('/createcandidatedetails',value);
  }


  

  
  candidatestatusinterested(value:any): Observable<any> 
  {
    return this.commonService.formpost('/candidate/submitisinterested',value);
  }


  candidatestatusdetailsUpdate(value:any): Observable<any> 
  {
    return this.commonService.formpost('/candidate/submitstatusemailer',value);
  }

  candidatestatusUpdateOnly(value:any): Observable<any> 
  {
    return this.commonService.formpost('/candidate/submitstatusonly',value);
  }

  getcandidateById(value:any): Observable<any> 
  {
    return this.commonService.formpost('/candidatebyid',value);
  }

  getcandidateCommunicationHistory(value: any): Observable<any>
  {
    return this.commonService.formpost('/candidate/candidatecommunicationhistory',value);
  }

  deleteFile(value:any): Observable<any> 
  {
    return this.commonService.formpost('/deletefile',value);
  }

  getselectedcandidate(value:any): Observable<any> 
  {
    return this.commonService.formpost('/company/job/selectedcandidatelist',value);
  }

  getselectedcandidatebyid(value:any): Observable<any> 
  {
    return this.commonService.formpost('/company/job/candidatebyid',value);
  }

  getdocumentlist(value:any): Observable<any> 
  {
    return this.commonService.formpost('/candidate/documentlist',value);
  }

  candidatedocumentupdatebyadmin(value:any): Observable<any> 
  {
    return this.commonService.formpost('/candidate/candidatedocumentupdate',value);
  }

  deletecandidatedocumentFile(value: any): Observable<any> {
    return this.commonService.formpost('/candidate/candidatedocumentdelete', value);
  }
  

  

  /* Candidate Notes Add and get function */

  
  getcandidatenotelist(value: any): Observable<any> {
    return this.commonService.formpost('/company/candidate/candidatenote', value);
  }

  createcandidatenote(value: any): Observable<any> {
  return this.commonService.formpost('/company/candidate/updatecandidatenote', value);
  }

  deleteCandNote(value: any): Observable<any> {
    return this.commonService.formpost('/company/candidate/deletecandidatenote', value);
  }

  /* Skill Add and delete function */

  
  candidateskilladd(value: any): Observable<any> {
    return this.commonService.formpost('/candidate/candidateskilladd', value);
  }

  candidateskilldelete(value: any): Observable<any> {
  return this.commonService.formpost('/candidate/candidateskilldelete', value);
  }


  /* Document Rename function */

  
  getapplicantdocumentbyid(value: any): Observable<any> {
    return this.commonService.formpost('/applicant/applicantdocument', value);
  }

  updateapplicantdocumentbyid(value: any): Observable<any> {
    return this.commonService.formpost('/applicant/applicantupdatedocumentname', value);
  }

}
