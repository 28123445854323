"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_service_1 = require("../../services/employee.service");
var modal_service_1 = require("../../_services/modal.service");
var profile_model_1 = require("../../models/overview/profile.model");
var permission_service_1 = require("../../services/permission.service");
var alert_service_1 = require("../../services/alert.service");
var common_service_1 = require("../../services/common.service");
var company_service_1 = require("../../services/company.service");
var router_1 = require("@angular/router");
var user_service_1 = require("../../services/user.service");
var AdminpermissionComponent = /** @class */ (function () {
    function AdminpermissionComponent(employeeService, modalService, router, company, commonService, userService, permissiondetails, formBuilder, alerts) {
        this.employeeService = employeeService;
        this.modalService = modalService;
        this.router = router;
        this.company = company;
        this.commonService = commonService;
        this.userService = userService;
        this.permissiondetails = permissiondetails;
        this.formBuilder = formBuilder;
        this.alerts = alerts;
        this.th = [];
        this.emailexist = false;
        this.samecompany = false;
        this.ismonitoring = false;
    }
    AdminpermissionComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        this.company.getcompanyData().subscribe(function (companydata) {
            _this.ismonitoring = companydata.issso;
        });
        this.username = '';
        // var ctype  = parseInt(localStorage.getItem('ctype'));
        // if(ctype ==1){
        //   this.router.navigate(['/check-dashboard']);
        // }
        this.ctype = parseInt(localStorage.getItem('ctype'));
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permissions = permission[0];
        this.userperm = this.permissions.m2;
        // this.employeeService.getlistofemployee().subscribe((companydata: any) => {
        //   this.currentcompanydata = companydata;
        // });
        this.employeeForm = this.formBuilder.group({
            fname: ['', forms_1.Validators.required],
            mname: [''],
            lname: ['', forms_1.Validators.required],
            username: ['', forms_1.Validators.required],
            empid: [''],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            phonenumber: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            ismail: ['']
        });
        this.Userid = localStorage.getItem('userid');
        this.overviewData = new profile_model_1.Profile();
        this.employeeService.getlistofemployee().subscribe(function (employeedata) {
            _this.data = employeedata;
            _this.record = _this.data.length;
            _this.datalist = _this.data;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
        this.permissiondetails.bindallmenu().subscribe(function (menudata) {
            var permissionstring = "[{";
            var perarray = [];
            if (_this.ctype != 3) {
                menudata.forEach(function (valuemenu) {
                    perarray.push('"' + valuemenu.clname + '": 0');
                });
            }
            else {
                menudata.forEach(function (valuemenu) {
                    perarray.push('"' + valuemenu.clname + '": 1');
                });
            }
            var perstr = perarray.join();
            permissionstring = permissionstring + perstr + "}]";
            _this.permissionstring = permissionstring;
        });
    };
    AdminpermissionComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    AdminpermissionComponent.prototype.focusFunction = function () {
        this.emailexist = false;
    };
    AdminpermissionComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    AdminpermissionComponent.prototype.onUserNameOutEvent = function (event) {
        var _this = this;
        if (event.target.value == '') {
            this.username = '';
        }
        else {
            this.username = '';
            var dbvalue = { 'username': event.target.value };
            this.userService.isUserRegisterd(event.target.value).subscribe(function (data) {
                // this.userService.isUserRegisterd(event.target.value).subscribe((data2: any) => {
                if (data.sussess == 'false') {
                    _this.username = 'false';
                }
                else {
                    _this.username = 'true';
                }
            });
        }
    };
    AdminpermissionComponent.prototype.onChangeStatus = function (evt, project) {
        this.employeeService.ssoUpdatestatusbyadmin({ 'employeeid': project.employeemasterid, 'issso': evt.target.checked }).subscribe(function (data) {
        });
    };
    AdminpermissionComponent.prototype.onAddEmployeeSubmit = function (employeeform1) {
        var _this = this;
        this.submitted = true;
        if (this.employeeForm.invalid) {
            return;
        }
        this.disableBtn = false;
        employeeform1.id = this.Userid;
        employeeform1.ismail = true;
        employeeform1.permission = this.permissionstring;
        employeeform1.isadmin = 1;
        var dbvalue = { 'username': employeeform1.username };
        this.userService.isUserRegisterd(dbvalue).subscribe(function (data) {
            // this.userService.isUserRegisterd(event.target.value).subscribe((data2: any) => {
            if (data.sussess == 'false') {
                _this.username = 'false';
                _this.userService.isEmailRegisterd(employeeform1.emailid).subscribe(function (data) {
                    if (data.sussess == 'success') {
                        _this.emailexist = true;
                        _this.disableBtn = true;
                    }
                    else {
                        _this.disableBtn = false;
                        _this.employeeService.checkemployeecreate(employeeform1).subscribe(function (dataw) {
                            if (dataw.susses == 'true') {
                                _this.employeeService.getlistofemployee().subscribe(function (employeedata) {
                                    if (_this.ctype != 3) {
                                        _this.router.navigate(['/managepermission', dataw.employeemasterid]);
                                    }
                                    _this.data = employeedata;
                                    _this.record = _this.data.length;
                                    _this.datalist = _this.data;
                                    if (_this.record < 30) {
                                        _this.pageOfItems = _this.data;
                                    }
                                    else {
                                        _this.items = _this.data;
                                    }
                                    _this.modalService.close('add-new-applicant');
                                });
                            }
                            else {
                                _this.alerts.error('Somthing went wrong. please try again after sometime');
                            }
                        });
                    }
                });
            }
            else {
                _this.username = 'true';
                // this.emailexist = true; 
                _this.disableBtn = true;
            }
        });
    };
    AdminpermissionComponent.prototype.uploadByAdmin = function (empid) {
        this.submitted = false;
        this.emailexist = false;
        this.phonecheck = null;
        this.employeeForm.reset({
            fname: "",
            mname: "",
            lname: "",
            emailid: "",
            phonenumber: ""
        });
        this.modalService.open(empid);
    };
    AdminpermissionComponent.prototype.onFocusOutEvent = function (event) {
        var _this = this;
        this.userService.isEmailRegisterdCheckForAdmin(event.target.value).subscribe(function (data) {
            if (data.sussess == 'success') {
                _this.emailexist = true;
                _this.disableBtn = false;
                _this.employeeid = data.employeeid;
                if (data.samecompany) {
                    _this.samecompany = true;
                }
                // this.isFocus = true;
                // this.modalService.open('alreadyadd-request');
            }
            else {
                _this.disableBtn = true;
            }
        });
    };
    AdminpermissionComponent.prototype.onchange = function (ind, subm) {
        var tg;
        tg = JSON.parse(this.permission.toString());
        tg.forEach(function (value4) {
            value4[ind] = subm;
        });
        var starry = JSON.stringify(tg);
        var dt = { "permission": starry, "employeeid": this.emplyeeid };
        this.permission = starry;
        this.permissiondetails.updateemployeepermission(dt).subscribe(function (employeedata) {
        });
    };
    AdminpermissionComponent.prototype.bindallmenu = function () {
        var _this = this;
        this.employeeService.getlistofemployee().subscribe(function (employeedata) {
            employeedata.forEach(function (value) {
                var tg;
                if (value.permission) {
                    tg = JSON.parse(value.permission.toString());
                    tg.forEach(function (value4) {
                    });
                }
            });
            _this.data = employeedata;
            _this.record = _this.data.length;
            _this.datalist = _this.data;
            _this.pageOfItems = _this.data;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    Object.defineProperty(AdminpermissionComponent.prototype, "f", {
        get: function () { return this.employeeForm.controls; },
        enumerable: true,
        configurable: true
    });
    AdminpermissionComponent.prototype.onSubmit = function (employeeform1, modelid) {
    };
    AdminpermissionComponent.prototype.addadmin = function (id) {
        var _this = this;
        if (confirm("Are you sure you want to add this user in admin list?")) {
            var prm = { "employeeid": id, "id": this.Userid, "isadmin": true };
            this.permissiondetails.updateisadmin(prm).subscribe(function (employeedata) {
                _this.alerts.success('User successfully added as Admin', true);
                _this.bindallmenu();
            });
        }
    };
    AdminpermissionComponent.prototype.removeadmin = function (id) {
        var _this = this;
        if (confirm("Are you sure you want to remove this user from admin list?")) {
            var prm = { "employeeid": id, "id": this.Userid, "isadmin": false };
            this.permissiondetails.updateisadmin(prm).subscribe(function (employeedata) {
                _this.alerts.success('User successfully removed as admin.', true);
                _this.bindallmenu();
            });
        }
    };
    AdminpermissionComponent.prototype.openModal = function (id, employee, employeeid) {
        var _this = this;
        this.submitted = false;
        this.permission = employee.permission;
        this.emplyeeid = employeeid;
        this.permissiondetails.bindallmenu().subscribe(function (menudata) {
            menudata.forEach(function (valuemenu) {
                valuemenu.submenu.forEach(function (submenudata, index) {
                    var tg;
                    tg = JSON.parse(employee.permission.toString());
                    tg.forEach(function (value4) {
                        var kdd = valuemenu.clname;
                        if (value4[kdd] == submenudata.mright) {
                            valuemenu.submenu[index].ch = true;
                        }
                        else {
                            valuemenu.submenu[index].ch = false;
                        }
                    });
                });
            });
            _this.menulist = menudata;
            // console.log(this.menulist);
        });
        this.modalService.open(id);
    };
    AdminpermissionComponent.prototype.closeModal = function (id) {
        var _this = this;
        this.employeeService.getlistofemployee().subscribe(function (employeedata) {
            // this.data = employeedata; this.datalist = this.data;   
            _this.data = employeedata;
            _this.record = _this.data.length;
            _this.datalist = _this.data;
            _this.pageOfItems = _this.data;
            _this.modalService.close(id);
        });
    };
    return AdminpermissionComponent;
}());
exports.AdminpermissionComponent = AdminpermissionComponent;
