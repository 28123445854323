"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var TerminationreasonService = /** @class */ (function () {
    function TerminationreasonService(commonService) {
        this.commonService = commonService;
    }
    TerminationreasonService.prototype.getterminationreasonlist = function () {
        return this.commonService.formget('/company/terminationreasonlist');
    };
    TerminationreasonService.prototype.updateTerminationReason = function (data) {
        return this.commonService.formpost('/company/updateterminationreason', data);
    };
    TerminationreasonService.prototype.getParentTerminationReasonList = function (data) {
        return this.commonService.formpost('/company/parentorchildterminationreason', data);
    };
    TerminationreasonService.prototype.getChildTerminationReasonList = function (data) {
        return this.commonService.formpost('/company/parentorchildterminationreason', data);
    };
    TerminationreasonService.ngInjectableDef = i0.defineInjectable({ factory: function TerminationreasonService_Factory() { return new TerminationreasonService(i0.inject(i1.CommonService)); }, token: TerminationreasonService, providedIn: "root" });
    return TerminationreasonService;
}());
exports.TerminationreasonService = TerminationreasonService;
