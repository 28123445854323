import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class DivisionService {

  constructor(private commonService: CommonService) { }

  getdivisionlistbyadmin(data): Observable<any> 
  {
    return this.commonService.formpost('/company/divisionlistbyadmin',data);   
  }  

  settingdivisionUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/company/divisionupdatebyadmin', value);
  }

  divisionUpdateChangeStatus(value:any): Observable<any> 
  {
    return this.commonService.formpost('/company/divisionupdatechangestatus',value);
  }

}
