import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { CommonService } from '../../services/common.service';
import { CountryService } from '../../services/country.service';
import { AssetService } from '../../services/asset.service';
import { ClientService } from '../../services/client.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Asset, Assetcategory } from '../../models/asset/asset.model';
import { EmployeeService } from '../../services/employee.service';
import { Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';


@Component({
  selector: 'app-adminasset',
  templateUrl: './adminasset.component.html',
  styleUrls: ['./adminasset.component.css']
})
export class AdminassetComponent implements OnInit {
  Userid: any; 
  data: any;

  
  assetDetail = new Asset();
  datalist:any;
  assetForm: FormGroup;
  employeelist: any;
  assetcategorylist: any;
  options = [];
  buttoninsert: any;
  submitted: boolean = false;
  disableBtn: any;
  public searchText: string;
  permission:  any;
  assetperm: any;
  emptyValue: any = "";
  record:number;
  
  items: Array<any>;
  
  pageOfItems: Array<any>;

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private dataset: AssetService,
    private router: Router,
    private alerts: AlertService,
    private employeeService: EmployeeService
  ) { }
  
  ngOnInit() {
    
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    

    this.Userid = localStorage.getItem('userid');

   

    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.assetperm = this.permission.m16;

    this.assetDetail = new Asset();
    this.assetDetail.category = new Assetcategory();
    
    this.getassetformgroup();

    this.dataset.getassetlist().subscribe((data: any) => {      
      this.record = data.length;
      this.data = data;
      this.datalist = data;
      this.pageOfItems = this.data;
      if(this.record < 30){
        this.pageOfItems = this.data;
      }
      else{
           this.items =this.data;
      }
    });

    this.Userid = localStorage.getItem('userid');
    const sstatustype = { 'status': true, 'id': this.Userid } 
    
    this.dataset.getassetcategory(sstatustype).subscribe((data: any) => {
      this.assetcategorylist = data;
    });

    this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
      this.employeelist = employeedata;
    });
    this.emptyValue ="";
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  getassetformgroup()
  {
    this.assetForm = this.formBuilder.group({
      id: [''],
      employeeid: ['', Validators.required],
      categoryid: ['', Validators.required],
      assetdescption: ['', Validators.required],
      serialnumber: [''],
      statdate: ['', Validators.required],
      enddate: ['']
    });
  }

  get f() { return this.assetForm.controls; }

  onSubmit(formasset: any, id: string) {
    this.submitted = true;
    // var formasset = assetformdata.value;
    if (this.assetForm.invalid) {
      return;
    }
    this.disableBtn = false;
    formasset.userid = this.Userid;


    const sd = new Date(formasset.statdate);
    formasset.statdate = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    const ed = new Date(formasset.enddate);
    formasset.enddate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());




    this.dataset.assetdetailsUpdate(formasset).subscribe((response: any) => {
      this.dataset.getassetlist().subscribe((data: any) => {
        this.record = data.length;
        this.data = data;
        this.datalist = data;
      this.pageOfItems = this.data;

        if(this.record < 30){
          this.pageOfItems = this.data;
        }
        else{
            this.items =this.data;
        }
        this.modalService.close(id);
        this.alerts.success('Assets updated successfully',true);
      });
    });
  }

  openModal2(id: string) 
  { 
    this.submitted = false;
    this.disableBtn = true;
    this.buttoninsert = "Submit";
    this.assetDetail = new Asset(); 
    
    this.getassetformgroup();
    this.emptyValue ="";  
    // this.assetDetail.categoryid = "0";
    // this.assetDetail.employeeid = "0";
    this.assetDetail.id = "0";
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.submitted = false;
    this.disableBtn = true;
    this.buttoninsert = "Update";
    this.assetDetail = new Asset();     
    this.assetDetail = data;
    this.modalService.open(id);
  }


  closeModal(id: string) {
    this.assetDetail = new Asset();     
    this.modalService.close(id);
  }

}
