"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var passport_model_1 = require("../../../models/passport/passport.model");
var employee_model_1 = require("../../../models/employee.model");
var mypassport_service_1 = require("../../../services/mypassport.service");
var alert_service_1 = require("../../../services/alert.service");
var upload_file_service_1 = require("../../../services/upload-file.service");
var customer_service_1 = require("../../../services/customer.service");
var PassportfaceaiComponent = /** @class */ (function () {
    function PassportfaceaiComponent(sanitizer, formBuilder, passportService, activatedRoute, alerts, uploadService, data, 
    // private country: CountryService,  
    router) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.passportService = passportService;
        this.activatedRoute = activatedRoute;
        this.alerts = alerts;
        this.uploadService = uploadService;
        this.data = data;
        this.router = router;
        this.passportdetails = new passport_model_1.Passport();
        this.employee = new employee_model_1.Employee();
        this.nextDisable = true;
    }
    PassportfaceaiComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userId = localStorage.getItem('userid');
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.requestperm = this.permission.m15;
        this.employeepassportdeatilid = this.activatedRoute.snapshot.paramMap.get("passportid");
        this.ctype = parseInt(localStorage.getItem('ctype'));
        this.data3 = { "employeepassdeatilid": this.employeepassportdeatilid };
        this.passportService.passportdetailsbypassportid(this.data3).subscribe(function (data) {
            _this.employeemasterid = data.employee.employeemasterid;
            if (data.passportprofileimage) {
                _this.nextDisable = false;
                _this.passportprofileimage = data.passportprofileimage;
            }
            else if (data.employee.imagename) {
                _this.nextDisable = false;
                _this.passportprofileimage = data.employee.imagename;
            }
            _this.passportdetails = data;
            _this.record = data.length;
            // this.data = data;
            // this.record = data.length;
        });
    };
    PassportfaceaiComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        var file = this.selectedFiles;
        this.fileformaterror = false;
        this.filesizeerror = false;
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
            this.alerts.success('File size must be less than 2 MB.', true);
            return;
        }
        else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png")) {
            this.fileformaterror = true;
            this.alerts.success('Please choose a valid file format (jpg, png, ttf, gif)', true);
            return;
        }
        var imagepath = this.uploadService.uploadfile(file);
        this.passportprofileimage = '../../../../../../../assets/img/loadings.gif';
        // this.passportdetails.employee.imagename  = imagepath;
        // console.log(this.employeemasterid);
        var forupdate = {
            'passportprofileimage': imagepath,
            'id': this.employeepassportdeatilid,
            'createby': this.userId
        };
        this.passportService.mypassportdetailsUpdatebyadmin(forupdate).subscribe(function (data) {
            setTimeout(function () { return _this.passportprofileimage = imagepath; }, 3000);
        });
    };
    PassportfaceaiComponent.prototype.onSubmit = function () {
        var _this = this;
        var checkData = { "employeepassdeatilid": this.employeepassportdeatilid };
        this.passportService.passportdetailsbypassportid(checkData).subscribe(function (data) {
            if (data.passportprofileimage) {
                _this.router.navigate(['/passportaipaymentdetails/' + _this.employeepassportdeatilid]);
            }
            else {
                var forupdate = { 'passportprofileimage': _this.passportprofileimage, 'id': _this.employeepassportdeatilid, 'createby': _this.userId };
                _this.passportService.mypassportdetailsUpdatebyadmin(forupdate).subscribe(function (data) {
                    _this.router.navigate(['/passportaipaymentdetails/' + _this.employeepassportdeatilid]);
                });
            }
        });
    };
    return PassportfaceaiComponent;
}());
exports.PassportfaceaiComponent = PassportfaceaiComponent;
