import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { AmlBussiness,Aml } from '../../models/aml/aml.model';
import { AmlService } from '../../services/aml.service';
import { ChecksystemService } from '../../services/checksystem.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CompanyService } from '../../services/company.service';
import { CountryService } from '../../services/country.service';
import { AlertService } from '../../services/alert.service';


@Component({
  selector: 'app-checkaml',
  templateUrl: './checkaml.component.html',
  styleUrls: ['./checkaml.component.css']
})

export class CheckamlComponent implements OnInit {
  result: boolean;

  amlid:any;
  errormsg:any;
  searchresult: any = [];
  searchresultnew: any;
  ispaging:boolean=true;
  searchresultnewar: any=[];
  searchresultnewob: any={};
  companyid:any;
  atype:boolean=false;
  countrylist:any;
  logid:any;
  datasetlist: any;
  activePage:number = 0;  
  disableBtn:boolean =true;
  pageOfItems: Array<any>;
  datalist:any;
  ismonitoring:boolean;
  downloadtext:any;
  downloadtextnew:any;
  public searchText: string;
  items: Array<any>;
  type:any;

  showmonitor:boolean =true;

  suggestions = [{"id":"PEP","name":"PEP"},{"id":"SAN","name":"Sanction"},{"id":"RRE","name":"Reputational Risk Exposure"},

  {"id":"INS","name":"Insolvency (UK & Ireland)"},{"id":"DD","name":"Disqualified Director (UK only)"},{"id":"POI","name":" Profile of Interest"},{"id": "REL","name":"Regulatory Enforcement List"} ];


  constructor( private amldetails: AmlService,    
    private activatedRoute: ActivatedRoute,
    private check: ChecksystemService,
    private alerts: AlertService,
    private company: CompanyService,
    private country: CountryService,
    private modalService: ModalService
  ) { }

  ngOnInit() {

    this.downloadtext = ' <i class="fa fa-close"></i> Active Monitoring ';
    
    this.company.getcompanyData().subscribe((companydata: any) => {

       
      this.ismonitoring =companydata.ismonitoring;
      this.companyid = companydata.companyid;

      console.log('monitoring', this.ismonitoring);
      
      if(companydata.ismonitoring)
      {
        this.downloadtext = ' <i class="fa fa-spinner fa-spin"></i> Active Monitoring ';
        this.ismonitoring =companydata.ismonitoring;
      }
      else
      {
        this.downloadtext = ' <i class="fa fa-close"></i> Active Monitoring ';
      }

    });

    
    this.country.getcountrylist().subscribe((country: any) => {
      // console.log('country list',country);
      this.countrylist = country;
    });

    this.pageload();

  }

  pageload(){


    var masterarray= [];    
    this.logid = this.activatedRoute.snapshot.paramMap.get("type");

    if(this.logid ==0)
    {
      this.type = 'aml';
    }
    if(this.logid ==2)
      {
        this.type ='adverse';
      }
      if(this.logid ==3)
        {
          this.type ='global';
        }
        if(this.logid ==4)
          {
            this.type ='global';
          } if(this.logid ==1)
            {
              this.type ='aml';
            }


    if(this.logid ==0 || this.logid == 2 || this.logid == 3)
    {
      this.atype =true;
      this.searchresultnew = new Aml();
      var val ={ atype: this.logid }  
      this.amldetails.getCompanylogrecord(val).subscribe((searchRes: any) => {


        this.downloadtextnew = new Array(searchRes.length);
        for(var i=0;i<searchRes.length;i++)
        {
          if(searchRes[i].ismonitoring)
            this.downloadtextnew[i] = 'Remove Monitoring <i class="fa fa-bell-o ml-2"></i>';
          else
            this.downloadtextnew[i] = 'Active Monitoring <i class="fa fa-bell-o ml-2"></i>';  
        } 

        searchRes.forEach(element => {
          element.searchcondition = JSON.parse(element.searchcondition);
          element.displayData = true;
          this.searchresult.push(element);
         });
         
        this.disableBtn = true;        
        this.pageOfItems = this.searchresult;
        this.datalist = this.searchresult;
        if(searchRes.length < 30){
          this.pageOfItems = this.searchresult;
        }
        else{
            this.items =this.searchresult;
        }
      });
    }
    else if(this.logid ==1 || this.logid ==4)
    {
      this.atype =false;

      this.searchresultnew = new AmlBussiness();
      var val ={atype:this.logid  }  
      this.amldetails.getCompanylogrecord(val).subscribe((searchRes: any) => {  


        this.downloadtextnew = new Array(searchRes.length);
        for(var i=0;i<searchRes.length;i++)
        {
          if(searchRes[i].ismonitoring)
            this.downloadtextnew[i] = 'Remove Monitoring <i class="fa fa-bell-o ml-2"></i>';
          else
            this.downloadtextnew[i] = 'Active Monitoring <i class="fa fa-bell-o ml-2"></i>';  
        } 

        searchRes.forEach(element => {
          element.searchcondition = JSON.parse(element.searchcondition);
          element.displayData = true;
          this.searchresult.push(element);       
        });
                 
        this.datalist = this.searchresult;
        this.pageOfItems = this.searchresult;


        if(searchRes.length < 30){
          this.pageOfItems = this.searchresult;
        }
        else{
            this.items =this.searchresult;
        }
     
      });

    }
    // else if(this.logid ==2)
    // {
    //   this.atype =true;
    //   this.searchresultnew = new Aml();
    //   var val ={ atype:this.logid  }  
    //   this.amldetails.getCompanylogrecord(val).subscribe((searchRes: any) => {


    //     this.downloadtextnew = new Array(searchRes.length);
    //     for(var i=0;i<searchRes.length;i++)
    //     {
    //       if(searchRes[i].ismonitoring)
    //         this.downloadtextnew[i] = 'Remove Monitoring <i class="fa fa-bell-o ml-2"></i>';
    //       else
    //         this.downloadtextnew[i] = 'Active Monitoring <i class="fa fa-bell-o ml-2"></i>';  
    //     } 

    //     searchRes.forEach(element => {
    //       element.searchcondition = JSON.parse(element.searchcondition);
    //       element.displayData = true;
    //       this.searchresult.push(element);
    //      });
         
    //     this.disableBtn = true;        
    //     this.pageOfItems = this.searchresult;
    //     this.datalist = this.searchresult;
    //     if(searchRes.length < 30){
    //       this.pageOfItems = this.searchresult;
    //     }
    //     else{
    //         this.items =this.searchresult;
    //     }
    //   });
    // }

  }



  
  displayActivePage(activePageNumber:number){  
    this.activePage = activePageNumber  
  }  

  searchdatapage(value:any)
  {
    if(value =='')
      this.ispaging =true;
    else
      this.ispaging =true;

      this.pageOfItems = this.pageOfItems;
    console.log(value);
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }


  
  bindallcountry(val)
  { 
    if(val)
    {
      return this.countrylist.filter(d => val.includes(d.iso));;
    }
    else
      return [];
    // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
  }



  bindallpep(val)
  { 
    if(val)
    {
      return this.suggestions.filter(d => val.includes(d.id));;
    }
    else
      return [];
    // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
  }





  

  dataset(values){
      
    this.datasetlist ="";

    if(values.PEP == true)
    {
      this.datasetlist = "<li><p> PEP </p> </li>";
    }

    if(values.AdverseMedia == true)
    {
      this.datasetlist += "<li><p> Adverse Media</p> </li>";
    }

    if(values.DisqualifiedDirector == true)
    {
      this.datasetlist += "<li><p>Disqualified Director (UK Only)</p> </li>";
    }

    if(values.FinancialRegulator == true)
    {
      this.datasetlist += "<li><p>Financial Regulator</p> </li>";
    }

    if(values.Insolvency == true)
    {
      this.datasetlist += "<li><p> Insolvency (UK & Ireland)</p> </li>";
    }

    if(values.LawEnforcement == true)
    {
      this.datasetlist += "<li><p>Law Enforcement</p> </li>";
    }

    if(values.CurrentSanctions == true)
    {
      this.datasetlist += "<li><p> Sanction - Current</p> </li>";
    }
    if(values.PreviousSanctions == true)
    {
      this.datasetlist += "<li><p> Sanction - Previous</p> </li>";
    }

    this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1); 

  }


  outputnewset(d){
    var listmaster =JSON.parse(d);
    this.searchresultnew = new Aml();
    this.searchresultnew = listmaster;
  }

  outputnewBussinessset(d){
    var listmaster =JSON.parse(d);  
    this.searchresultnew = new AmlBussiness();
    this.searchresultnew = listmaster;
  }

  searchData(searchKey:any)
  {
    searchKey = searchKey.trim().toLowerCase();
    if(this.logid == 0)
    {
      this.searchresult.forEach((element, index) => {
        if(
          element.searchcondition.Forename.trim().toLowerCase().includes(searchKey) ||
          element.searchcondition.Middlename.trim().toLowerCase().includes(searchKey) ||
          element.searchcondition.Surname.trim().toLowerCase().includes(searchKey) ||
          element.searchcondition.YearOfBirth.trim().toLowerCase().includes(searchKey) ||
          element.searchcondition.City.trim().toLowerCase().includes(searchKey) ||
          element.searchcondition.County.trim().toLowerCase().includes(searchKey) ||
          element.searchcondition.Postcode.trim().toLowerCase().includes(searchKey) || 
          element.searchcondition.Country.trim().toLowerCase().includes(searchKey)
          )
        {
          this.searchresult[index].displayData = true;
        }
        else
        {
          this.searchresult[index].displayData = false;
        }
      });
    }
    else if(this.logid == 1)
    {
      

      this.searchresultnewar.forEach((element, index) => {
        if(
          element.BusinessName.trim().toLowerCase().includes(searchKey) ||
          element.City.trim().toLowerCase().includes(searchKey) ||
          element.County.trim().toLowerCase().includes(searchKey) ||
          element.Postcode.trim().toLowerCase().includes(searchKey) || 
          element.Address.trim().toLowerCase().includes(searchKey) || 
          element.Country.trim().toLowerCase().includes(searchKey)
          )
        {
          this.searchresultnewar[index].displayData = true;
        }
        else
        {
          this.searchresultnewar[index].displayData = false;
        }


      });
    }

  }

  openModal(id: string, amlid: any) 
  {  
    this.amlid = amlid;
    this.modalService.open(id);
  }

  onActive(id: any)
  {
    this.disableBtn = false;
    var data = { amlid: this.amlid, isstatus: true}
    this.amldetails.postActiveAmllogDetails(data).subscribe((searchRes: any) => {        
        this.modalService.close(id);
        this.alerts.success(searchRes.message, false);        
        this.pageload(); 
    });
  }

  onDeactive(id: any)
  { 
    this.disableBtn = false;
    var data = { amlid: this.amlid, isstatus: false}
    this.amldetails.postActiveAmllogDetails(data).subscribe((searchRes: any) => {

      this.modalService.close(id);
      this.pageload(); 
      this.alerts.success(searchRes.message, false);
    });
  }








  closeModal(id: string) 
  { 
    this.modalService.close(id);
  }

}
