<div class="white_box_shadow mb-0 nbor_tr">
  <div class="job_note">
      <h4>Notes</h4>
      <p>Only Visible To Your Team</p>
      <div class="job_conversation">
        <form [formGroup]="candidatenoteFormGroup" #candidatenoteForm="ngForm" class="s12 white" (ngSubmit)="updatecandidatenote(candidatenoteForm.value)">
   
          <input type="hidden" formControlName="candidateid" class="form-control" name="candidateid" id="candidateid" [(ngModel)]="candidatenotesdetails.candidateid">
          <input type="hidden" formControlName="employeeid" class="form-control" name="employeeid" id="employeeid" [(ngModel)]="candidatenotesdetails.employeeid">
          
          <textarea type="text" maxlength="500" placeholder="Write your note here (Maximum 500 characters)" 
            formControlName="notes" name="notes" id="notes" class="form-control" [ngClass]="{ 'is-invalid': submitted && j.notes.errors }" 
            [(ngModel)]="candidatenotesdetails.notes">

            </textarea>

          <div *ngIf="submitted && j.notes.errors" class="invalid-feedback mb-2">
              <div *ngIf="j.notes.errors.required">Please Enter Notes</div>
          </div>
          <button type="submit" class="btn btn-warning">Save Note</button>
        </form>
      </div>
  </div>
</div>
<div class="notes_post"  >
  <div class="candidate_note" *ngFor="let candidate of candidatenotes">
    <span>{{candidate.createat | date }}</span>
    <p>{{ candidate.notes}} </p>
    <button (click)="deleteCandidateNote(candidate.candidatenotesid)" *ngIf="isadmin" class="btn btn-danger com_btn buttonresize">Delete</button>
  </div>
  <!-- <div *ngIf="candidatenotes.length ==0">No Nots History Found</div> -->
</div>