"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var TimesheetService = /** @class */ (function () {
    function TimesheetService(commonService) {
        this.commonService = commonService;
    }
    TimesheetService.prototype.getholidayType = function () {
        return this.commonService.formget('/company/holidaytype');
    };
    TimesheetService.prototype.getloactionlist = function () {
        return this.commonService.formget('/company/locationtype');
    };
    TimesheetService.prototype.gettimesheetList = function (data) {
        return this.commonService.formpost('/employee/employeehourreport', data);
    };
    TimesheetService.ngInjectableDef = i0.defineInjectable({ factory: function TimesheetService_Factory() { return new TimesheetService(i0.inject(i1.CommonService)); }, token: TimesheetService, providedIn: "root" });
    return TimesheetService;
}());
exports.TimesheetService = TimesheetService;
