import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'app-usersubscriptionsuccess',
  templateUrl: './usersubscriptionsuccess.component.html',
  styleUrls: ['./usersubscriptionsuccess.component.css']
})
export class UsersubscriptionsuccessComponent implements OnInit {


  ctype='';
  planid:any;

  constructor(private router: Router,private activatedRoute: ActivatedRoute, public paymentService: PaymentService,) { }

  ngOnInit() {  
    
    
    this.planid = this.activatedRoute.snapshot.paramMap.get("planid");    
    this.ctype = this.activatedRoute.snapshot.paramMap.get("id");

    var data = {'token': this.ctype,planid: this.planid}
    this.paymentService.subscriptionsuccess(data).subscribe((data: any) => 
    {  
      localStorage.setItem('planid', '1');
      localStorage.setItem('struser', data.struser);
      localStorage.setItem('isemail', "true");
       this.router.navigate(['/successful-payment']);         
    });

    setTimeout(() => {
      this.ctype = localStorage.getItem('ctype');      
      if (this.ctype == '0' ) 
      {
        this.router.navigate(['choose-plan']);
      }
     else
     {
      this.router.navigate(['choose-plan']);
     }
      // this.router.navigate(['/dashboard']);
    }, 9000);
  
  //5s
  }
}