"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var EmployeementService = /** @class */ (function () {
    function EmployeementService(commonService) {
        this.commonService = commonService;
    }
    EmployeementService.prototype.getemployementlist = function (data) {
        return this.commonService.formpost('/employee/employmentreport', data);
    };
    EmployeementService.ngInjectableDef = i0.defineInjectable({ factory: function EmployeementService_Factory() { return new EmployeementService(i0.inject(i1.CommonService)); }, token: EmployeementService, providedIn: "root" });
    return EmployeementService;
}());
exports.EmployeementService = EmployeementService;
