export class Companyprofile {
    companyid: number;
    companyname: string;
    clientid: any;
    phonenumber: string;
    sphonenumber: string;
    clientname: string;
    emailid: string;
    secondryemailid: string;
    websiteurl: string;
    clietdesignation: string;
    license: License;
    companytypeid: string;
    currentDate: string;
    movedoutdateerror: string;
    plan: Plan;
    logoname: string;payrollmonth:any;
    companytype: companytype;
}

export class License {
    companylicensedetailsid: string;
    applicationdate: string;
    approvaldate: string;
    cexpiredate: string;
    licensenumber: string;
}

export class Plan {
    planname: string;
    plantitle: string;
    prices: any;
    plantype: any;
    totaluser: number;
    totaljobs: number;
    totalclient: number;
    totalemployee: number;
    totalproject:number;
}

export class Countcompanydata {
    job: number;
    candidate: number;
    employee: number;
    client: number;
    project: number;
    admin: number;
}

export class companytype{
    typename:any;
}