<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
      <div class="container">
          <div class="overviewsshow mb-2">
              <div class="righttowork_flex">
                <div class="width50">
                    <h5 class="hedingcolor mb-0">Right to Work Checks</h5>
                </div>
                  <div class="width50 rightwork">
                    <app-checkcountshow></app-checkcountshow>
                  </div>
              </div>
             
           
          </div>
        <div class="user_profle_section righttowork">
            <div class="profile_box boxshadow">               
                <div class="overviewsshow pb-0 pt-2">
                  
                    <div class="userimg">
                        <img src="../../../../../assets/img/user_pic_emp.png" class="img">
                    </div>
                    <div class="name" *ngIf="employeedetails">
                        <p>{{employeedetails.fname}} {{employeedetails.mname}}
                            {{employeedetails.lname}}</p>
                        <p class="email">{{employeedetails.emailid}}</p>
                        <p class="phone">{{employeedetails.phonenumber}}</p>
                    </div>
                </div>
                
            </div>
            <div class="details_box boxshadow">
                <div class="setpshow">
                    <progress id="file" value="23" max="100"> 32% </progress>
                    
                    <ul>
                        <li class="active">
                            <p>stage one</p>
                        </li>
                        <li>
                            <p>stage two</p>
                        </li>
                        <li>
                            <p>stage three</p>
                        </li>
                        <li>
                            <p>stage four</p>
                        </li>
                        <li>
                            <p>stage five</p>
                        </li>
                        <li>
                            <p>stage six</p>
                        </li>
                    </ul>
                </div>
                <div class="overviewsshow pb-2 pt-0">
                    <div *ngIf="stage==1">
                       
                        <h4 class="heading mt-4">Please select one action</h4>
                        <div class="button_box" >
                            <div class="card_btn" (click)="sendrequestchargestage('2', 'rtw')">
                                <img src="../../../../../assets/img/meeting/visa.png">
                                <p>Right to Work Check</p>
                            </div>
                            <div class="card_btn" (click)="sendrequestchargestage('2', 'adjusted_rtw')">
                                <img src="../../../../../assets/img/meeting/passport.png">
                                <p>Adjusted Right to work check due to Covid-19</p>
                            </div>
                        </div>
                        <div class="col-lg-4 text-right pl-0">
                            <p class="pri_button"><span [routerLink]="'/right-to-work-check'"><i  aria-hidden="true" class="fa fa-angle-left mr-2"></i> Back</span></p>
                        </div>
                    </div>
                    <div *ngIf="stage==2">
                        
                        <h4 class="heading mt-4">Please select one action</h4>
                        <div class="button_box mb-4 " >
                            <p (click)="sendrequestchargestage('3', 'rtw')" class="boxright">Initial check before employment</p>
                            <p (click)="sendrequestchargestage('3', 'adjusted_rtw')" class="boxright">Follow-up check on an employee</p>
                        </div>
                        <div class="col-lg-4 text-right pl-0">
                            <p class="pri_button"><a (click)="sendrequestchargestage('1')"><i  aria-hidden="true" class="fa fa-angle-left mr-2"></i> Back</a></p>
                        </div>
                    </div>
                    <div *ngIf="stage==3" class="p-4">
                        <div class="row">
                            <div class="col-sm-4">
                                <p class="mb-0"><b>Please select one country</b></p>
                            </div>
                            <div class="col-lg-8">
                                <input type="text" [(ngModel)]="searchTextcountry" class="form-control" placeholder="Search Your Country Name">
                            </div> 
                        </div>
                   
                      <div class="row  mt-2 d-flex justify-content-start" >
                        <ng-container *ngIf="( datalist | filter: {countryname: searchTextcountry }) as countrylist"> 
                            <div class="mt-2 country_list"  *ngFor="let c of countrylist;  let i=index"> 
      
                                <p (click)="sendrequestchargestage('4', '', '', c.isrighttoworkcountry)" style="cursor: pointer;" > {{ c.countryname }}</p>
                            </div>                            
                            <div class="job_list_section visa_request_section width100"  *ngIf="countrylist.length === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>
                        </ng-container>
                         
                        
      
                      </div>
                      <div class="mt-4 text-right">
                        <p (click)="sendrequestchargestage('2')" class="pri_button p-1"><i  aria-hidden="true" class="fa fa-angle-left ml-2 mr-2"></i> Previous</p>
                    </div>
                    </div>
                    <div *ngIf="stage==4">
                        
                        <h4 class="heading mt-4">Please select one action</h4>
                        <div class="question_box">
                            <div class="question">
                                <label class="visaempcheck mr-3 mb-0 d-flex align-items-center"> 
                                    <input type="checkbox" >
                                    <span class="checkmark"></span>
                                  </label>   
                                  <p>A passport showing the holder, or a person named in the passport as the child of the holder, is a British citizen
                                    or a citizen of the UK and Colonies having the right of abode in the UK. </p>    
                            </div>
                            <div class="question">
                                <label class="visaempcheck mr-3 mb-0 d-flex align-items-center"> 
                                    <input type="checkbox" >
                                    <span class="checkmark"></span>
                                  </label>   
                                  <p>A passport showing the holder, or a person named in the passport as the child of the holder, is a British citizen
                                    or a citizen of the UK and Colonies having the right of abode in the UK. </p>    
                            </div>
                            <div class="question">
                                <label class="visaempcheck mr-3 mb-0 d-flex align-items-center"> 
                                    <input type="checkbox" >
                                    <span class="checkmark"></span>
                                  </label>   
                                  <p>A passport showing the holder, or a person named in the passport as the child of the holder, is a British citizen
                                    or a citizen of the UK and Colonies having the right of abode in the UK. </p>    
                            </div>
                            <div class="question">
                                <label class="visaempcheck mr-3 mb-0 d-flex align-items-center"> 
                                    <input type="checkbox" >
                                    <span class="checkmark"></span>
                                  </label>   
                                  <p>A passport showing the holder, or a person named in the passport as the child of the holder, is a British citizen
                                    or a citizen of the UK and Colonies having the right of abode in the UK. </p>    
                            </div>
                            <div class="question">
                                <label class="visaempcheck mr-3 mb-0 d-flex align-items-center"> 
                                    <input type="checkbox" >
                                    <span class="checkmark"></span>
                                  </label>   
                                  <p>A passport showing the holder, or a person named in the passport as the child of the holder, is a British citizen
                                    or a citizen of the UK and Colonies having the right of abode in the UK. </p>    
                            </div>
                        </div>
                        <div class="col-lg-4 text-right pl-0">
                            <p class="pri_button"><a (click)="sendrequestchargestage('3')"><i  aria-hidden="true" class="fa fa-angle-left mr-2"></i> Back</a></p>
                        </div>
                    </div>                  
                    <div *ngIf="stage==5" class="p-4">
                        <div class="col-lg-4 text-left pl-0">
                            <p (click)="sendrequestchargestage('1')" class="pri_button p-1"><i  aria-hidden="true" class="fa fa-angle-left ml-2 mr-2"></i> Previous</p>
                        </div>
                        <h4 class="heading">Please select one Action</h4>
                        <div class="button_box" >
                            <div class="card_btn" (click)="sendrequestchargestage('7', '', 'passport')">
                                <img src="../../../../../assets/img/meeting/visa.png">
                                <p>Can the holder present the original valid passport in person?</p>
                            </div>
                            <div class="card_btn" *ngIf="rtw_country==0" (click)="sendrequestchargestage('7', '', 'visa')">
                                <img src="../../../../../assets/img/meeting/passport.png">
                                <p>Can the holder present the original valid visa in person?</p>
                            </div>
                            <div class="card_btn" (click)="sendrequestchargestage('7','','document')">
                                <img src="../../../../../assets/img/meeting/passport.png">
                                <p>Can the individual present any of the following original document in person?</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body" *ngIf="stage==6">
                        <div class="row">              
                            <div class="col-lg-12 text-center">
                                <h5 class="text-center">your request send successfully</h5>
                                <button class="btn com_btn btn-success small_btn" [routerLink]="['/right-to-work-check']"  type="button">
                                    Go Back
                                </button>
                            </div> 
                        </div>
                        <div class="row" style="height: 200px; overflow-y: scroll;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
          <div class="tabs" id="tabs" style="display: none;">
              <div class="tabs__content">
                  <div class="tabs__content__item active-tab">
                  
                      <div class="content">
                          <div class="row row-sm topheader">                            
                              
                             
                          </div>
                          <div class="row">
                            <div class="col-sm-12 aligncenter mb-3">
                               
                           
                            </div>
                        </div> 
                       
                        <!-- <div class="job_list_section employee_list_section pb-2 pt-2 oddeven" *ngIf="employeedetails"> 
                              
                              <div class="job_detils_date width22">
                                <p class="job_address">Name</p>
                                  <h5 class="job_profile font_17">{{employeedetails.fname}} {{employeedetails.mname}}
                                      {{employeedetails.lname}}</h5>
                              </div>
                              <div class="job_detils_date width22">
                                  <p class="job_address">Email</p>
                                  <p  class="job_profile">{{employeedetails.emailid}}</p>
                              </div>
                              <div class="job_detils_date width15">
                                  <p class="job_address">Phone Number</p>
                                  <p class="job_profile">{{employeedetails.phonenumber}}</p>
                              </div>
                      
                    </div> -->

                        <!-- <div class="modal-body" *ngIf="stage==1">
                          <div class="row">              
                              <span class="col-lg-12 text-center" style="color: green;">{{requestDocSentMsg}}</span> 
                          </div>
                          <div class="browser-screen-loading-content meetingloader" *ngIf="loading ==1" style="width:300px">
                              <div class="loading-dots dark-gray">
                                  <i></i>
                                  <i></i>
                                  <i></i>
                                  <i></i>
                                  <i></i>
                                  <i></i>                                
                                  <i></i>
                              </div>
                              <p>Loading</p>
                          </div>
          
                          <h5 class="text-center">Please select one Action</h5>
                          <div class="clearfix">&nbsp;</div>
                          <div class="meeting_icon">
                              <div class="icons_vpd" (click)="sendrequestchargestage('2', 'rtw')">
                                  <img  src="../../../../../assets/img/meeting/visa.png"/>
                                  <p>Right to Work Check</p>
                              </div>
                              <div class="icons_vpd" (click)="sendrequestchargestage('2', 'adjusted_rtw')">
                                  <img  src="../../../../../assets/img/meeting/passport.png"/>
                                  <p>Adjusted Right to work check due to Covid-19</p>
                              </div>
                             
                          </div>
                      </div> -->
          
                      <!-- <div class="modal-body" *ngIf="stage==2" style="margin-top: 20px;">
                            <div class="row">
                                <div class="col-lg-12 text-left">
                                    <button (click)="sendrequestchargestage('1')">Previous</button>
                                </div>
                            </div>
                          <div class="row">              
                              <div class="col-lg-12 text-center">
                                  <input type="text" [(ngModel)]="searchTextcountry" class="form-control" placeholder="Search Your Country Name">
                              </div> 
                          </div>
          
                          <div class="row  mt-2" >
                              <div class="col-lg-4 mt-2"  *ngFor="let listcountry of countrylist | filter: {countryname: searchTextcountry };  let i=index"> 
          
                                  <a (click)="sendrequestchargestage('3', '', '', listcountry.isrighttoworkcountry)" style="cursor: pointer;" > {{ listcountry.countryname }}</a>
                              </div>
          
                          </div>
          
                      </div> -->
          
          
                    <!-- <div class="modal-body" *ngIf="stage==3">
                        <div class="row">
                            <div class="col-lg-12 text-left">
                                <button (click)="sendrequestchargestage('2')">Previous</button>
                            </div>
                        </div>
                        <div class="row">              
                            <div class="col-lg-12 text-center">
                                <h5 class="text-center">Please select one Action</h5>
                            </div> 
                        </div>
        
                        <div class="row" style="height: 200px; overflow-y: scroll;">
                            <div class="meeting_icon">
                                <div class="icons_vpd" (click)="sendrequestchargestage('7', '', 'passport')">
                                    <img  src="../../../../../assets/img/meeting/visa.png"/>
                                    <p>Can the holder present the original valid passport in person?</p>
                                </div>
                                <div class="icons_vpd" *ngIf="rtw_country==0" (click)="sendrequestchargestage('7', '', 'visa')">
                                    <img  src="../../../../../assets/img/meeting/visa.png"/>
                                    <p>Can the holder present the original valid visa in person?</p>
                                </div>
                                <div class="icons_vpd" (click)="sendrequestchargestage('7','','document')">
                                    <img  src="../../../../../assets/img/meeting/passport.png"/>
                                    <p>Can the individual present any of the following original document in person?</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
          
                     
                    <!-- <div class="modal-body" *ngIf="stage==4">
                        <div class="row">
                            <div class="col-lg-12 text-left">
                                <button (click)="sendrequestchargestage('3', current_stage)">Previous</button>
                            </div>
                        </div>
                        <div class="row">              
                            <div class="col-lg-12 text-center">
                                <h5 class="text-center">Please select one Action</h5>
                            </div> 
                        </div>
        
                        <div class="row" >
                            <div class="row meeting_icon">                              
                                <div class="col-lg-6">
                                    <div class="icons_vpd" (click)="sendrequestchargestage('5')">
                                        <img  src="../../../../../assets/img/meeting/visa.png"/>
                                        <p>A birth (short or long) or adoption certificate issued in the UK, together with an official document giving the person’s permanent National Insurance number and their name issued by a Government agency or a previous employer.</p>
                                    </div>
                                </div>  
                                <div class="col-lg-6">
                                    <div class="icons_vpd" (click)="sendrequestchargestage('5')">
                                        <img  src="../../../../../assets/img/meeting/passport.png"/>
                                        <p>A birth (short or long) or adoption certificate issued in the Channel Islands, the Isle of Man or Ireland, together with an official document giving the person’s permanent National Insurance number and their name issued by a Government agency or a previous employer.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="icons_vpd" (click)="sendrequestchargestage('5')">
                                        <img  src="../../../../../assets/img/meeting/passport.png"/>
                                        <p>A certificate of registration or naturalisation as a British citizen, together with an official document giving the person’s permanent National Insurance number and their name issued by a Government agency or a previous employer. </p>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="icons_vpd" (click)="sendrequestchargestage('5')">
                                        <img  src="../../../../../assets/img/meeting/passport.png"/>
                                        <p>Other</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
          
          
                    <!-- <div class="modal-body" *ngIf="stage==5">
                        <div class="row">              
                            <div class="col-lg-12 text-center">
                                <h5 class="text-center">Please select one Action</h5>
                            </div> 
                        </div>
        
                        <div class="row" style="height: 200px; overflow-y: scroll;">
                            <div class="meeting_icon"> 
                                <div class="icons_vpd" (click)="sendrequestchargestage('6')">
                                    <img  src="../../../../../assets/img/meeting/visa.png"/>
                                    <p>Request To Employee to upload document</p>
                                </div>
                                <div class="icons_vpd" (click)="sendrequestchargestage('7')">
                                    <img  src="../../../../../assets/img/meeting/passport.png"/>
                                    <p>Upload Document yourself</p>
                                </div>     
                            </div>
                        </div>
                    </div> -->
          
                    <div class="modal-body" *ngIf="stage==6">
                        <div class="row">              
                            <div class="col-lg-12 text-center">
                                <h5 class="text-center">your request send successfully</h5>
                            </div> 
                        </div>
                        <div class="row" style="height: 200px; overflow-y: scroll;">
                        </div>
                    </div>
          
                         
                    <div class="row">
                        <div class="col-lg-4 text-left" *ngIf="record == 0">
                            <div class="billed-to">No Record Found </div>
                        </div>
                        <div class="col-lg-12 text-right">
                            <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                        </div>
                    </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>





<jw-modal id="sendrequestcharge" role="dialog" class="modal sendrequestcharge">

    <div role="document" class="formgroup">
        <div class="modal-content">
            <form [formGroup]="meetingformGroup" #meetingForm="ngForm" class="s12 white">
            <div class="modal-body p-4">
                <div class="container text-center">
                    <div *ngIf="loading ==0">
                        <div class="para mb-4">
                            <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
                            <p class="mb-0 font20">The employee will receive an email containing a video link for live verification of all the documents.</p>
                            <p class="mb-0 font20">Click 'Confirm' to proceed with the 'Right to work check'.</p>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-4 text-left">
                                <label class="meetinglabel">Meeting Start Date*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker1" name="startdate" id="startdate"  [(ngModel)]="right.startdate"  class="form-control noborder-invalid" formControlName="startdate" [min]="currentDate" [class.is-invalid]="submitted && returnForm.startdate.errors" placeholder="Start Date">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && returnForm.startdate.errors" class="invalid-feedback">
                                    <div *ngIf="returnForm.startdate.errors.required">Please Choose Start Date</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 text-left">
                                <label class="meetinglabel">Meeting Start Time*</label>
                                <select formControlName="starttime" name="starttime" id="starttime"  [(ngModel)]="right.starttime"  [ngClass]="{ 'is-invalid': submitted && returnForm.starttime.errors }" class="form-control">
                                    <option [value]="defaultValue">Select start time</option>
                                    <option *ngFor="let tval of timeValues" value="{{ tval.key }}">{{ tval.value }}</option>
                                </select>
                                <div *ngIf="submitted && returnForm.starttime.errors" class="invalid-feedback">
                                    <div *ngIf="returnForm.starttime.errors.required">Please enter start time</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 text-left">
                                <label class="meetinglabel">Meeting End Time*</label>
                                <select formControlName="endtime" name="endtime" id="endtime"  [(ngModel)]="right.endtime"  [ngClass]="{ 'is-invalid': submitted && returnForm.endtime.errors }" class="form-control">
                                    <option [value]="defaultValue">Select end time</option>
                                    <option *ngFor="let tval of timeValues" [disabled]="meetingformGroup.controls.starttime.value>=tval.key" value="{{ tval.key }}">{{ tval.value }}</option>
                                </select>
                                <div *ngIf="submitted && returnForm.endtime.errors" class="invalid-feedback">
                                    <div *ngIf="returnForm.endtime.errors.required">Please enter end time</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="browser-screen-loading-content meetingloader" *ngIf="loading ==1" style="width:300px">
                        <div class="loading-dots dark-gray">
                            <i></i>
                            <i></i>
                            <i></i>
                            <i></i>
                            <i></i>
                            <i></i>                                
                            <i></i>
                        </div>
                        <p>Loading</p>
                    </div>

                </div>
            </div>
             <div class="modal-footer text-center" *ngIf="loading !=1">
                <input type="submit" class="btn btn-primary com_btn" [disabled]="loading == 1" name="updateform" value="Confirm"  (click)="sendZoomLink(employeeid, 'sendrequestcharge', meetingForm.value)"  />
                <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('sendrequestcharge');"/>
            </div> 
        </form>
        </div>
    </div>
</jw-modal>

<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">         
            <div class="modal-body">
                <div class="container text-center">
                  <div class="info_mess">
                      <div class="para">
                              <h4 class="mb-2">No credits available</h4>
                              <p class="mb-0 font20"> There are no credits available to perform this action. Please add more credits.</p>
  
                          </div>                          
                      </div>
                </div>
            </div>
            <div class="modal-footer text-center">
              <input type="button" class="btn btn-primary com_btn" name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
              <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
                <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="confirm-modal" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-body p-4">
                <div class="container text-center">
                    <div class="info_mess">
                        <div class="para">
                            <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
                            <!-- <p class="mb-0 font20">The employee will receive an email and they need to upload the document.</p> -->
                        </div>                          
                    </div>
                </div>
            </div>
            <div class="modal-footer text-center">
                <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Confirm"  (click)="uploadByAdmin(request_type,'upload_now',empid)"  />
                <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('confirm-modal');"/>
            </div>
        </div>
    </div>
</jw-modal>
