import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(private commonService: CommonService) { }
  
  getplanlist(value): Observable<any> {
    // return this.commonService.formpostOnlyid('/company/plandetails');
    return this.commonService.formpost('/company/plandetails', value);
  }
  checkcounpons(value: any): Observable<any> {
    return this.commonService.formpost('/checkcoupons', value);
  }

  updatecompanyplan(data4: any): Observable<any> {
    return this.commonService.formpost('/sucessuserplan',data4);
  }

  getplanlistwithcoupons(data4: any): Observable<any> {
    return this.commonService.formpost('/plancouponsbyuser',data4);
  }

}
