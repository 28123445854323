"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./approval.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../user/header/header.component.ngfactory");
var i3 = require("../user/header/header.component");
var i4 = require("../services/customer.service");
var i5 = require("@angular/router");
var i6 = require("../services/user.service");
var i7 = require("../services/company.service");
var i8 = require("@angular/common");
var i9 = require("./approval.component");
var styles_ApprovalComponent = [i0.styles];
var RenderType_ApprovalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApprovalComponent, data: {} });
exports.RenderType_ApprovalComponent = RenderType_ApprovalComponent;
function View_ApprovalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "div", [["class", "bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.CustomerService, i5.Router, i6.UserService, i7.CompanyService, i1.Renderer2, i1.Renderer2], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 26, "div", [["class", "admin_dashboard_show"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 25, "div", [["class", "ceee"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 24, "div", [["class", "cardmenu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "a", [["class", "card_box_lit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "samebox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "lnr lnr-pencil"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "menu_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Leave Request"])), (_l()(), i1.ɵeld(14, 0, null, null, 7, "a", [["class", "card_box_lit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(16, 1), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "samebox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "lnr lnr-gift"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "menu_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Expense Request"])), (_l()(), i1.ɵeld(22, 0, null, null, 7, "a", [["class", "card_box_lit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(24, 1), (_l()(), i1.ɵeld(25, 0, null, null, 4, "div", [["class", "samebox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, "i", [["class", "lnr lnr-calendar-full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 2, "div", [["class", "menu_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Timesheet Request"]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_2 = _ck(_v, 8, 0, "/leave-request"); _ck(_v, 7, 0, currVal_2); var currVal_5 = _ck(_v, 16, 0, "/expense-request"); _ck(_v, 15, 0, currVal_5); var currVal_8 = _ck(_v, 24, 0, "/timesheet-request"); _ck(_v, 23, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).target; var currVal_1 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 15).target; var currVal_4 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 23).target; var currVal_7 = i1.ɵnov(_v, 23).href; _ck(_v, 22, 0, currVal_6, currVal_7); }); }
exports.View_ApprovalComponent_0 = View_ApprovalComponent_0;
function View_ApprovalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-approval", [], null, null, null, View_ApprovalComponent_0, RenderType_ApprovalComponent)), i1.ɵdid(1, 114688, null, 0, i9.ApprovalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ApprovalComponent_Host_0 = View_ApprovalComponent_Host_0;
var ApprovalComponentNgFactory = i1.ɵccf("app-approval", i9.ApprovalComponent, View_ApprovalComponent_Host_0, {}, {}, []);
exports.ApprovalComponentNgFactory = ApprovalComponentNgFactory;
