"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var AssetService = /** @class */ (function () {
    function AssetService(commonService) {
        this.commonService = commonService;
    }
    AssetService.prototype.getassetlist = function () {
        return this.commonService.formpostOnlyid('/employee/assetlistbyadmin');
    };
    AssetService.prototype.getassetlistbyemployeeid = function (formvalue) {
        return this.commonService.formpost('/employee/assetlistbyemployeeid', formvalue);
    };
    AssetService.prototype.assetdetailsUpdate = function (value) {
        return this.commonService.formpost('/employee/assetupdate', value);
    };
    // ASSEST
    AssetService.prototype.getassetcategory = function (data) {
        return this.commonService.formpost('/company/assetcategory', data);
    };
    AssetService.prototype.settingassetcategoryUpdate = function (value) {
        return this.commonService.formpost('/assetcategoryupdatebyadmin', value);
    };
    AssetService.prototype.getassetreport = function (data) {
        return this.commonService.formpost('/employee/employeeassetreport', data);
    };
    AssetService.prototype.assetUpdateChangeStatus = function (value) {
        return this.commonService.formpost('/company/assetupdatechangestatus', value);
    };
    AssetService.ngInjectableDef = i0.defineInjectable({ factory: function AssetService_Factory() { return new AssetService(i0.inject(i1.CommonService)); }, token: AssetService, providedIn: "root" });
    return AssetService;
}());
exports.AssetService = AssetService;
