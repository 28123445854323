"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var payment_service_1 = require("../../../services/payment.service");
var UnlimitedchecksuccessComponent = /** @class */ (function () {
    function UnlimitedchecksuccessComponent(router, activatedRoute, paymentService) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.paymentService = paymentService;
        this.ctype = '';
    }
    UnlimitedchecksuccessComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.planid = this.activatedRoute.snapshot.paramMap.get("planid");
        this.ctype = this.activatedRoute.snapshot.paramMap.get("id");
        var data = { 'token': this.ctype, planid: this.planid };
        this.paymentService.upgradeuserpaymentBysuccess(data).subscribe(function (data) {
            // localStorage.setItem('planid', '1');
            // localStorage.setItem('struser', data.struser);
            // localStorage.setItem('isemail', "true");
            _this.ctype = localStorage.getItem('ctype');
            if (_this.ctype == '3')
                _this.router.navigate(['/reference-dashboard']);
            else
                _this.router.navigate(['/check-dashboard']);
        });
        setTimeout(function () {
            _this.ctype = localStorage.getItem('ctype');
            if (_this.ctype == '0') {
                _this.router.navigate(['choose-plan']);
            }
            else {
                _this.router.navigate(['choose-plan']);
            }
            // this.router.navigate(['/dashboard']);
        }, 9000);
        //5s
    };
    return UnlimitedchecksuccessComponent;
}());
exports.UnlimitedchecksuccessComponent = UnlimitedchecksuccessComponent;
