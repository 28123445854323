<div *ngIf="address != null">
    <h3 class="slim-card-title">Address</h3>
    <div class="billed-to">
        <a (click)="openModal6('custom-address-4',address)" class="lsview pencialedit"><i class="fa fa-pencil" ></i></a>
        <h6 class="tx-gray-800">{{address.address1}}</h6>
        <p><b>{{address.address2 }}</b></p>
        <p>{{address.cityname}}</p>
        <p><span>{{address.statename}} {{address.country?.countryname}} {{address.pincode}}</span> &nbsp;
        </p>
    </div>
</div>

<jw-modal id="custom-address-4" class="modal" role="dialog">
    <form [formGroup]="addpopForm" #addresslistForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(addresslistForm.value,'custom-address-4')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <!-- <ngx-intl-tel-input ></ngx-intl-tel-input> -->
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="addresslistDetail.employeeaddresdeatilid">
                    <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="addresslistDetail.employeeid">
                    <h5 class="modal-title" id="popupLabel">Address Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-address-4');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <!-- <div class="row">
              <div class="form-group col-lg-6">
                <label>Mobile Number *</label>
                <international-phone-number class="form-control phonecontrol" formControlName="phonenumber" 
                [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }"  [defaultCountry]="'gb'"
                 [(ngModel)]="addresslistDetail.phonenumber" placeholder="Enter phone number" 
                 (input)="validatenumber($event.target.value,'phone')" [maxlength]="20" #phonenumber 
                 name="phonenumber"></international-phone-number>
                  <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                      <div *ngIf="f.phonenumber.errors.required">Please enter Phone Number</div>
                    </div>
                <span *ngIf="phonecheck==false" style="color:red">Invalid format</span>
                <span *ngIf="phonecheck==true" style="color:green">Valid format</span>
              
              </div>
              <div class="form-group col-lg-6">
                <label>Phone Number *</label>
                <international-phone-number formControlName="mobilenumber" class="form-control phonecontrol" 
                [ngClass]="{ 'is-invalid': submitted && f.mobilenumber.errors }" placeholder="Enter mobile number"
                [(ngModel)]="addresslistDetail.mobilenumber" (input)="validatenumber($event.target.value,'mobile')"
                [maxlength]="20" [defaultCountry]="'gb'" [required]="true" #mobilenumber name="mobilenumber">
                </international-phone-number>
              <div *ngIf="submitted && f.mobilenumber.errors" class="invalid-feedback">
                <div *ngIf="f.mobilenumber.errors.required">Please enter Mobile Number</div>
              </div>
              <span *ngIf="mobilecheck==false" style="color:red">Invalid format</span>
              <span *ngIf="mobilecheck==true" style="color:green">Valid format</span>
                <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                    <div *ngIf="f.phonenumber.errors.required">Please enter the phonenumber</div>
                  </div>
              </div>
            </div> -->
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Post Code*</label>
                                <input type="pincode" formControlName="pincode" name="pincode" class="form-control pincode" [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }" [matAutocomplete]="auto" id="pincode" [(ngModel)]="addresslistDetail.pincode" (input)="onSearchChange($event.target.value)"
                                    matInput>
                                <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                    <div *ngIf="f.pincode.errors.required">Please enter post code</div>
                                </div>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="pincode">
                                    <mat-option *ngFor="let option of options" [value]="option" (onSelectionChange)="updatecode($event, option)">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Address Line 1*</label>
                                <input type="address1" formControlName="address1" name="address1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" required id="address1" [(ngModel)]="addresslistDetail.address1">
                                <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
                                    <div *ngIf="f.address1.errors.required">Please enter address</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Address Line 2</label>
                                <input type="address2" formControlName="address2" name="address2" id="address2" class="form-control" [(ngModel)]="addresslistDetail.address2">
                                <!-- <div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
                    <div *ngIf="f.address2.errors.required">Please enter Address Line 2</div>
                  </div> -->
                            </div>
                            <div class="form-group col-lg-6">
                                <label>City*</label>
                                <input type="text" formControlName="cityname" class="" name="cityname" id="cityname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.cityname.errors }" required [(ngModel)]="addresslistDetail.cityname">
                                <div *ngIf="submitted && f.cityname.errors" class="invalid-feedback">
                                    <div *ngIf="f.cityname.errors.required">Please enter city</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>County Name*</label>
                                <input type="text" formControlName="statename" name="statename" id="statename" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.statename.errors }" required [(ngModel)]="addresslistDetail.statename">
                                <!-- <select ngModel="{{ addresslistDetail.stateid }}" formControlName="stateid" class="form-control input-lg"
                  id="stateid" name="stateid" [ngClass]="{ 'is-invalid': submitted && f.stateid.errors }" ngModel="{{ addresslistDetail.stateid }}">
                  <option value="">Select State</option>
                  <option *ngFor="let state of statelist" value='{{state.stateid}}' [selected]="state.stateid == addresslistDetail.stateid">{{state.statename}}</option>
                </select> -->
                                <div *ngIf="submitted && f.statename.errors" class="invalid-feedback">
                                    <div *ngIf="f.statename.errors.required">Please enter the county name</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Country*</label>
                                <select style="width:100%;" formControlName="countryid" name="countryid" class="form-control input-lg" id="countryid" [ngClass]="{ 'is-invalid': submitted && f.countryid.errors }" [(ngModel)]="addresslistDetail.countryid">
                  <option [value]="defaultVal">Select Country</option>
                  <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                </select>
                                <div *ngIf="submitted && f.countryid.errors" class="invalid-feedback">
                                    <div *ngIf="f.countryid.errors.required">Please select country</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Update" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-address-4');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>