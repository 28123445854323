"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var leave_service_1 = require("../../services/leave.service");
var forms_1 = require("@angular/forms");
var modal_service_1 = require("../../_services/modal.service");
var alert_service_1 = require("../../services/alert.service");
var customer_service_1 = require("../../services/customer.service");
var router_1 = require("@angular/router");
var app_global_1 = require("../../app.global");
var LeavesickapprovalComponent = /** @class */ (function () {
    function LeavesickapprovalComponent(dataset, router, formBuilder, dataservice, modalService, alerts, customer) {
        this.dataset = dataset;
        this.router = router;
        this.formBuilder = formBuilder;
        this.dataservice = dataservice;
        this.modalService = modalService;
        this.alerts = alerts;
        this.customer = customer;
        this.remarksubmit = false;
        this.monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];
    }
    LeavesickapprovalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.Userid = localStorage.getItem('userid');
        this.hstatus = '';
        this.month = (new Date()).getMonth() + 1;
        this.year = (new Date()).getFullYear();
        this.isline = localStorage.getItem('isline');
        this.remarkformGroup = this.formBuilder.group({
            remarksholidayid: ['', forms_1.Validators.required],
            holidayremark: ['', forms_1.Validators.required],
            isline: ['']
        });
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + (new Date()).getFullYear();
        this.classactive = ((new Date()).getMonth() + 1).toString();
        this.customer.getchildemployee({ j: 1 }).subscribe(function (data) {
            _this.employeelist = data;
        });
        this.customer.getchildemployee({ 'id': this.Userid }).subscribe(function (data) {
            _this.childnodes = data;
        });
        this.disableBtn = true;
        this.bindalldata();
    };
    Object.defineProperty(LeavesickapprovalComponent.prototype, "g", {
        get: function () {
            return this.remarkformGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    LeavesickapprovalComponent.prototype.onChange = function (newValue) {
        this.employeeid = newValue;
        this.bindalldata();
    };
    LeavesickapprovalComponent.prototype.onChangestatus = function (newValue) {
        this.hstatus = newValue;
        this.bindalldata();
    };
    LeavesickapprovalComponent.prototype.bindalldata = function () {
        var _this = this;
        var data = { 'id': this.Userid, 'month': this.month, 'year': this.year, 'employeeid': this.employeeid, 'hstatus': this.hstatus, 'issick': 1 };
        this.dataset.getemployeeleaveapprovedreport(data).subscribe(function (data) {
            _this.record = data.length;
            _this.data = data;
        });
    };
    LeavesickapprovalComponent.prototype.closemessageModal = function (id) {
        this.modalService.close(id);
        // this.showAllEvents('event-viewer',this.remarksholidayid);   
    };
    LeavesickapprovalComponent.prototype.remarksmodel = function (id, modelid, closemodelid) {
        var _this = this;
        this.remarksubmit = false;
        this.Userid = localStorage.getItem('userid');
        var leaveData = { 'id': id };
        this.remarksholidayid = id;
        this.dataservice.getleaveremarks(leaveData).subscribe(function (remarksData) {
            _this.remarksofleave = remarksData;
            _this.holidayremarks = '';
        });
        this.modalService.open(modelid);
        this.modalService.close(closemodelid);
    };
    LeavesickapprovalComponent.prototype.onSubmitRemark = function (remark, popid) {
        var _this = this;
        this.remarksubmit = true;
        var jj = '';
        if (this.remarkformGroup.invalid) {
            return;
        }
        this.dataservice.addnewremark(remark.form.value).subscribe(function (data) {
            jj = remark.form.value.remarksholidayid;
            var leaveData = { 'id': remark.form.value.remarksholidayid };
            _this.dataservice.getleaveremarks(leaveData).subscribe(function (remarksData) {
                // remark.resetForm();
                _this.remarksubmit = false;
                _this.remarksofleave = remarksData;
                _this.remarksholidayid = jj;
            });
            _this.bindalldata();
        });
        this.alerts.success('Note sent successfully', false);
        this.closeModal(popid);
        // this.bindalldatabymonth();
        // this.showAllEvents('event-viewer', this.remarksholidayid);
    };
    LeavesickapprovalComponent.prototype.onStatus = function (id, status) {
        var _this = this;
        var deleteData = { 'id': id, 'hstatus': status, 'userid': this.Userid, 'statusdate': new Date() };
        var msg = '';
        if (status == 2) {
            msg = 'Are you sure you want to approve?';
        }
        else {
            msg = 'Are you sure you want to reject?';
        }
        if (confirm(msg)) {
            this.dataset.statusleave(deleteData).subscribe(function (data) {
                _this.bindalldata();
            });
        }
    };
    LeavesickapprovalComponent.prototype.onyearSubmit = function (year) {
        this.year = year;
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + this.year;
        this.bindalldata();
        // this.modalService.close(id);
    };
    LeavesickapprovalComponent.prototype.yearpop = function (id) {
        this.modalService.open(id);
    };
    LeavesickapprovalComponent.prototype.openModalnote = function (data, id) {
        this.notedetails = data;
        this.modalService.open(id);
    };
    LeavesickapprovalComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    LeavesickapprovalComponent.prototype.getDataByMonth = function (mon) {
        this.classactive = mon;
        this.month = mon;
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + this.year;
        this.bindalldata();
    };
    LeavesickapprovalComponent.prototype.getDataByYear = function (yea) {
        this.year = yea;
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + this.year;
        this.bindalldata();
        // const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
        // this.dataset.getemployeeleavereport(data).subscribe((data: any) => {
        //  this.data = data;
        // });
    };
    return LeavesickapprovalComponent;
}());
exports.LeavesickapprovalComponent = LeavesickapprovalComponent;
