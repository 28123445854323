"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./unsupported-country.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../user/header/header.component.ngfactory");
var i3 = require("../../user/header/header.component");
var i4 = require("../../services/customer.service");
var i5 = require("@angular/router");
var i6 = require("../../services/user.service");
var i7 = require("../../services/company.service");
var i8 = require("@angular/common");
var i9 = require("./unsupported-country.component");
var styles_UnsupportedCountryComponent = [i0.styles];
var RenderType_UnsupportedCountryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnsupportedCountryComponent, data: {} });
exports.RenderType_UnsupportedCountryComponent = RenderType_UnsupportedCountryComponent;
function View_UnsupportedCountryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.CustomerService, i5.Router, i6.UserService, i7.CompanyService, i1.Renderer2, i1.Renderer2], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "not_found nocountry"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "page404 unsupported_box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "content404 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "unsupported text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "lnr lnr-neutral"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["AI services are not yet enabled for this country! "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["They will be available soon."])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "a", [["class", "btn btn-danger"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(13, 1), (_l()(), i1.ɵted(-1, null, ["Go To Home"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_2 = _ck(_v, 13, 0, "/dashboard"); _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 12).target; var currVal_1 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_0, currVal_1); }); }
exports.View_UnsupportedCountryComponent_0 = View_UnsupportedCountryComponent_0;
function View_UnsupportedCountryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unsupported-country", [], null, null, null, View_UnsupportedCountryComponent_0, RenderType_UnsupportedCountryComponent)), i1.ɵdid(1, 114688, null, 0, i9.UnsupportedCountryComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_UnsupportedCountryComponent_Host_0 = View_UnsupportedCountryComponent_Host_0;
var UnsupportedCountryComponentNgFactory = i1.ɵccf("app-unsupported-country", i9.UnsupportedCountryComponent, View_UnsupportedCountryComponent_Host_0, {}, {}, []);
exports.UnsupportedCountryComponentNgFactory = UnsupportedCountryComponentNgFactory;
