import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Idproof } from '../../models/idproof/idproof.model';
import { UploadFileService } from '../../services/upload-file.service';
import { Employee } from '../../models/employee.model';
import { FileuploadService } from '../../services/fileupload.service';
import { MyidproofService } from '../../services/myidproof.service';
import { AlertService } from '../../services/alert.service';



@Component({
  selector: 'app-idaiverify',
  templateUrl: './idaiverify.component.html',
  styleUrls: ['./idaiverify.component.css']
})
export class IdaiverifyComponent implements OnInit {

  
  submitted: any;
  idproofpopForm: FormGroup;
  idproof: Idproof;
  permission: any;
  requestperm: any;
  record: number;

  selectedFiles: any;
  fileformaterror:any;
  filesizeerror:any;
  file:any;
  employeemasterid: any;

  idproofdetails = new Idproof();  
  employee = new Employee();
  ctype:number;
  data3:any;
  employeeidproofdeatilid:any;

  constructor(private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder,  
    private idproofService: MyidproofService, 
    private activatedRoute: ActivatedRoute,
    private filupload: FileuploadService,
    
    private uploadService: UploadFileService,  
    private alerts: AlertService,
    private router: Router) { }

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;

    this.ctype  = parseInt(localStorage.getItem('ctype'));
    this.employeeidproofdeatilid = this.activatedRoute.snapshot.paramMap.get("idproofid");

    this.data3 = {"employeeidproofdeatilid": this.employeeidproofdeatilid}
    this.idproofService.idproofdetailsbyidproofid(this.data3).subscribe((data: any) => {
      this.employeemasterid = data.employee.employeemasterid;
      this.idproofdetails = data;
      this.record  = data.length;
    });
  }

  selectFile(event,type)
  {
    type = parseInt(type);
      var files = event.target.files;
      var selectedFiles = files[0];
      this.selectedFiles = selectedFiles;
      const file = this.selectedFiles;
      this.fileformaterror = false;
      this.filesizeerror = false;
      
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        this.alerts.success('File size must be less than 2 MB.',true); 
        return;
      }
      else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") ) {
        this.fileformaterror = true;
        this.alerts.success('Please choose a valid file format (jpg, png,ttf,gif)',true);         
        return;
      }
      var imagepath = this.uploadService.uploadfile(file); 

      if(type == 0){        
        this.idproofdetails.iddocName = '../../../../../../../assets/img/loadings.gif';
      }
      else if(type == 1)
       {        
        this.idproofdetails.idbackdocName = '../../../../../../../assets/img/loadings.gif';
        }

      // this.idproofdetails.iddocName = '../../../../../../assets/img/loadings.gif';
      // this.idproofdetails.iddocName = imagepath;

      var dbimage = {"id": this.employeeidproofdeatilid, "imagename":imagepath , "employeid": this.employeemasterid, 'ttype': type};
      this.idproofService.idproofupdateForAI(dbimage).subscribe((data: any) => {

        if(type ==0){          
          setTimeout(() => this.idproofdetails.iddocName  = imagepath, 3000);        }
        else if(type ==1){
        setTimeout(() => this.idproofdetails.idbackdocName  = imagepath, 3000);        
         }

         
        // setTimeout(() => this.idproofdetails.iddocName  = imagepath, 3000);        
      });  
  }



}
