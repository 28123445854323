"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var EmployeeheaderComponent = /** @class */ (function () {
    function EmployeeheaderComponent() {
    }
    EmployeeheaderComponent.prototype.ngOnInit = function () {
    };
    return EmployeeheaderComponent;
}());
exports.EmployeeheaderComponent = EmployeeheaderComponent;
