
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Employee } from '../../../models/employee.model';
import { CountryService } from '../../../services/country.service';
import { EmployeeService } from '../../../services/employee.service';
import { EmployeementService } from '../../../services/employeement.service';
import { JobService } from '../../../services/job.service';
import { ProjectService } from '../../../services/project.service';
import { TimesheetService } from '../../../services/timesheet.service';
import { ExcelService } from '../../../services/excel.service';
import { getMonth, getYear } from 'date-fns';
import { ModalService } from '../../../_services/modal.service';
import { AppGlobals } from '../../../app.global';
import { WorklistService } from '../../../services/worklist.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-viewemployeereport',
  templateUrl: './viewemployeereport.component.html',
  styleUrls: ['./viewemployeereport.component.css']
})
export class ViewemployeereportComponent implements OnInit {
  EmployeeForm: FormGroup;
  employeeDetail = new Employee();
  employeelist: any;
  countrylist: any;
  coslist: any;
  notedetails: any;
  data: any;
  joblist: any;
  projectlist: any;
  expenselist: any;
  locationlist: any;
  exceldata = [];
  employeetimesheetdata: any;
  graphdisplay: any;
  listdisplay: any;
  public searchText: string;
  empid: any;
  employeemonth: any;
  employeeyear: any;
  Userid: any;
  hstatus: any;
  exptype: any = '';
  record: any;
  items: Array<any>;
  pageOfItems: Array<any>;
  yearList: any;

  constructor(
    private excelService: ExcelService, 
    private formBuilder: FormBuilder, 
    private project: ProjectService, 
    private timesheet: TimesheetService, 
    private country: CountryService, 
    private jobser: JobService, 
    private employee: EmployeeService, 
    private modalService: ModalService, 
    private listdata: WorklistService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private employeementreport: EmployeementService
  ) { 
    route.params.subscribe(params => {
      if(params['id']){
        this.empid = params['id'];
      }
    });
    route.queryParams.subscribe(params => {
      this.employeemonth = params['month'];
      this.employeeyear = params['year'];
      this.hstatus = params['status'];
      this.exptype = params['type'];
    });
  }

  ngOnInit() {
    this.yearList = AppGlobals.YEAR_VALUES;
    this.listdisplay = "none";
    this.graphdisplay = "block";
    this.EmployeeForm = this.formBuilder.group({
      emailid: [''],
      phonenumber: [''],
      ninumber: [''],
      minstartdate: [''],
      maxstartdate: [''],
      minenddate: [''],
      maxenddate: [''],
      hourtypeid: [''],
      minhour: [''],
      maxhour: [''],
      projectid: [''],
      fname: [''],
      hstatus: [''],
      searcText: ['']
    });

    // this.employeemonth = getMonth(new Date()) + 1;
    // this.employeeyear = getYear(new Date());
    // this.hstatus = '';
    var value3 = "{}";
    this.timesheetdetails();
    this.timesheet.getloactionlist().subscribe((locationlistdetails: any) => {
      this.locationlist = locationlistdetails;
      // this.record = this.data.length;

      // if (this.record < 30) {
      //   this.pageOfItems = this.data;
      // }
      // else {
      //   this.items = this.data;
      // }
    });

    this.Userid = localStorage.getItem('userid');
    const sstatustype = { 'status': true, 'id': this.Userid }
    this.project.getprojectlist(sstatustype).subscribe((projectdetails: any) => {
      this.projectlist = projectdetails;
    });

    var value3 = "{}";
    this.employee.getemployeelist(value3).subscribe((listdetails: any) => {
      this.employeelist = listdetails;
    });

    this.listdata.gethourtypelist({ 'status': true, 'id': this.Userid }).subscribe((expensedetails: any) => {
      this.expenselist = expensedetails;
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  showcondition(type: any) {
    if (type == 'list') {
      this.graphdisplay = "none";
      this.listdisplay = "block";
    }
    else {
      this.listdisplay = "none";
      this.graphdisplay = "block";
    }
  }

  exportAsXLSX(): void {
    this.exceldata = [];
    this.data.forEach(obj => {
      this.employeetimesheetdata = {
        "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
        "Hours": obj.ahour,
        "Hour Type": obj.hourtype.typename,
        "Project": obj.project.projectname,
        "Location": obj.location.address1,
        "Date": this.datePipe.transform(new Date(obj.startdate),"dd MMM yyyy")
      };
      this.exceldata.push(this.employeetimesheetdata);
    });
    this.excelService.exportAsExcelFile(this.exceldata, 'sample');
  }

  onSubmit(value3: any) {

  }

  timesheetdetails() {
    console.log(this.employeemonth);
    console.log(this.empid);
    // console.log(this.employeemonth);
    // console.log(this.employeemonth);
    this.timesheet.gettimesheetList({ 'month': this.employeemonth, 'year': this.employeeyear, 'employeeid': this.empid, 'hstatus': this.hstatus, 'hourtypeid': this.exptype }).subscribe((timesheetDetails: any) => {
      this.record = timesheetDetails.length;
      this.data = timesheetDetails;
    });
  }

  openModalnote(data: any, id: any) {
    this.notedetails = data;
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  changeEmployee(id: any) {
    this.empid = id;
    this.timesheetdetails();
  }

  changeEmployeeMonth(month: any) {
    this.employeemonth = month;
    this.timesheetdetails();
  }

  changeEmployeeYear(year: any) {
    this.employeeyear = year;
    this.timesheetdetails();
  }

  changeEmployeestatus(hstatus: any) {
    this.hstatus = hstatus;
    this.timesheetdetails();
  }

  changeExpenseType(exptype: any) {
    this.exptype = exptype;
    this.timesheetdetails();
  }
}