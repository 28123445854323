export class User {
     email: string;
     password: string;
     confirmPassword: string;
     oldPassword: string;
     newPassword: string;
     confirmpassword: string;
     companyname: string;
     fullname: string;
     firstname: string;
     lastname: string;
     mobilenumber: string;
     otp: string;
     otpmobile: string;
     permissionstring: string;
     chkTermCndition: boolean;
     chkcont: boolean;
     planid:number;
     isemail:true;
     ctype:number;
     username: string;
}