"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var aml_model_1 = require("../../../models/aml/aml.model");
var aml_service_1 = require("../../../services/aml.service");
var checkservice_service_1 = require("../../../services/checkservice.service");
var router_1 = require("@angular/router");
var AdversecredithistoryComponent = /** @class */ (function () {
    function AdversecredithistoryComponent(amldetails, router, checkService, activatedRoute) {
        this.amldetails = amldetails;
        this.router = router;
        this.checkService = checkService;
        this.activatedRoute = activatedRoute;
        this.atype = false;
        this.selectedpage = 1;
        this.unassignedCases = [];
    }
    AdversecredithistoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.unassignedCases = [];
        var masterarray = [];
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var tt = {};
        this.checkService.checkhistory(tt).subscribe(function (searchRes) {
            console.log('Search System', searchRes);
            _this.downloadtext = new Array(searchRes.length);
            for (var i = 0; i < searchRes.length; i++) {
                _this.downloadtext[i] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            }
            _this.searchresult = searchRes; //searchRes;  
            _this.unassignedCases = _this.paginator(_this.searchresult, _this.selectedpage, 50);
            console.log('Search System', _this.unassignedCases);
        });
    };
    AdversecredithistoryComponent.prototype.paginator = function (data, current_page, per_page_items) {
        console.log(data);
        var items;
        items = data;
        var page = current_page || 1, per_page = per_page_items || 50, offset = (page - 1) * per_page, paginatedItems = items.slice(offset).slice(0, per_page_items), total_pages = Math.ceil(items.length / per_page);
        return {
            page: page,
            perpage: per_page,
            prepage: page - 1 ? page - 1 : null,
            nextpage: (total_pages > page) ? page + 1 : null,
            total: items.length,
            totalpages: total_pages,
            data: paginatedItems,
            totalpagesarray: Array(total_pages).fill(null).map(function (x, i) { return i + 1; })
        };
    };
    AdversecredithistoryComponent.prototype.selectpage = function (pagenumber) {
        this.selectedpage = pagenumber;
        this.unassignedCases = this.paginator(this.searchresult, this.selectedpage, 50);
    };
    AdversecredithistoryComponent.prototype.downloadAdverseCreditHistory = function (historyid, ind) {
        var _this = this;
        this.downloadtext[ind] = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        this.checkService.adversecredithistorydownload({ "id": historyid }).subscribe(function (searchRes) {
            _this.downloadtext[ind] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            window.open(searchRes.response_data.Location, "_blank");
            console.log(searchRes);
        });
    };
    AdversecredithistoryComponent.prototype.outputnewset = function (d) {
        var listmaster = JSON.parse(d);
        this.searchresultnew = new aml_model_1.adverst();
        this.searchresultnew = listmaster;
    };
    AdversecredithistoryComponent.prototype.outputnewBussinessset = function (d) {
        var listmaster = JSON.parse(d);
        this.searchresultnew = new aml_model_1.AmlBussiness();
        this.searchresultnew = listmaster;
    };
    return AdversecredithistoryComponent;
}());
exports.AdversecredithistoryComponent = AdversecredithistoryComponent;
