"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./idproofthanksaiverify.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../user/header/header.component.ngfactory");
var i3 = require("../../../user/header/header.component");
var i4 = require("../../../services/customer.service");
var i5 = require("@angular/router");
var i6 = require("../../../services/user.service");
var i7 = require("../../../services/company.service");
var i8 = require("./idproofthanksaiverify.component");
var styles_IdproofthanksaiverifyComponent = [i0.styles];
var RenderType_IdproofthanksaiverifyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IdproofthanksaiverifyComponent, data: {} });
exports.RenderType_IdproofthanksaiverifyComponent = RenderType_IdproofthanksaiverifyComponent;
function View_IdproofthanksaiverifyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "div", [["class", "thanku"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.CustomerService, i5.Router, i6.UserService, i7.CompanyService, i1.Renderer2, i1.Renderer2], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 21, "div", [["class", "ai_info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 20, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Our AI is processing your request."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We'll revert to you as soon as possible over email."])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Thanks"])), (_l()(), i1.ɵeld(11, 0, null, null, 5, "button", [["class", "btn btn-primary com_btn"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(13, 1), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-angle-double-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" CONTINUE "])), (_l()(), i1.ɵeld(17, 0, null, null, 5, "div", [["class", "magnifying-container-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [["class", "magnifying"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "div", [["class", "handle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "div", [["class", "middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "div", [["class", "top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "img", [["src", "../../../../../assets/img/visa2.png"], ["style", "width: 100px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "div", [["class", "progress progg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 0, "div", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["aria-valuenow", "75"], ["class", "progress-bar progress-bar-striped progress-bar-animated"], ["role", "progressbar"], ["style", "width: 75%"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_0 = _ck(_v, 13, 0, "/ai-dashboard"); _ck(_v, 12, 0, currVal_0); }, null); }
exports.View_IdproofthanksaiverifyComponent_0 = View_IdproofthanksaiverifyComponent_0;
function View_IdproofthanksaiverifyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-idproofthanksaiverify", [], null, null, null, View_IdproofthanksaiverifyComponent_0, RenderType_IdproofthanksaiverifyComponent)), i1.ɵdid(1, 114688, null, 0, i8.IdproofthanksaiverifyComponent, [i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_IdproofthanksaiverifyComponent_Host_0 = View_IdproofthanksaiverifyComponent_Host_0;
var IdproofthanksaiverifyComponentNgFactory = i1.ɵccf("app-idproofthanksaiverify", i8.IdproofthanksaiverifyComponent, View_IdproofthanksaiverifyComponent_Host_0, {}, {}, []);
exports.IdproofthanksaiverifyComponentNgFactory = IdproofthanksaiverifyComponentNgFactory;
