
import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { AmlBussiness,Aml,adverst } from '../../../models/aml/aml.model';
import { AmlService } from '../../../services/aml.service';
import { AlertService } from '../../../services/alert.service';
import { CheckserviceService } from '../../../services/checkservice.service';
import { Router, ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-amlmonitoring',
  templateUrl: './amlmonitoring.component.html',
  styleUrls: ['./amlmonitoring.component.css']
})
export class AmlmonitoringComponent implements OnInit {
  data: any;
  result: boolean;
  searchresult: any;
  searchresultnew: any;
  disableBtn:boolean =true;
  atype:boolean=false;
  logid:any;
  datasetlist: any;
  downloadtext: any; record:number;
  items: Array<any>;datalist:any;
  companyid:any;
  pageOfItems: Array<any>;
  errormsg:any;


  constructor(  private modalService: ModalService,   
    private amldetails: AmlService,     private alerts: AlertService,
    private router: Router,    
    private checkService: CheckserviceService, 
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() 
  {
      var masterarray= [];
      var userid = localStorage.getItem('userid');
      if (!userid) {
        this.router.navigate(['/login']);
      }

      this.checkService.selectrighttoworkbyadmin().subscribe((righttoworkadmin: any) => {
        if(righttoworkadmin.success != 'pass')
        {
          this.router.navigate(['/check-dashboard']);    
        }      
      });

      this.pageload();       
  }


  pageload()
  { this.amldetails.getAmlCompanyDetails().subscribe((searchRes: any) => {

        
    this.data = searchRes;
    this.record = this.data.length;
    this.datalist = this.data;

    this.items =this.data;
    
    this.downloadtext = new Array(searchRes.length);
    for(var i=0;i<searchRes.length;i++)
    {
      if(searchRes[i].ismonitoring)
        this.downloadtext[i] = 'Remove Monitoring <i class="fa fa-bell-o ml-2"></i>';
      else
        this.downloadtext[i] = 'Active Monitoring <i class="fa fa-bell-o ml-2"></i>';

    }   
    this.disableBtn = true;
    this.searchresult = searchRes;//searchRes;   
  });  
}



  downloadAdverseCreditHistory(historyid: any, ind){
    this.downloadtext[ind] = 'Loading <i class="fa fa-spinner fa-spin"></i>';
    this.checkService.adversecredithistorydownload({"id":historyid}).subscribe((searchRes: any) => {  
      this.downloadtext[ind] = 'Active <i class="fa fa-bell-o ml-2"></i>';
      window.open(searchRes.response_data.Location, "_blank");
      console.log(searchRes); 
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items

    this.downloadtext = new Array(pageOfItems.length);
    for(var i=0;i<pageOfItems.length;i++)
    {
      if(pageOfItems[i].ismonitoring)
        this.downloadtext[i] = 'Remove Monitoring <i class="fa fa-bell-o ml-2"></i>';
      else
        this.downloadtext[i] = 'Active Monitoring <i class="fa fa-bell-o ml-2"></i>';

    }   


    this.pageOfItems = pageOfItems;
}

  outputnewset(d){
    var listmaster =JSON.parse(d);  
    this.searchresultnew = new adverst();
    this.searchresultnew = listmaster;
  }

  outputnewBussinessset(d){
    var listmaster =JSON.parse(d);  
    this.searchresultnew = new AmlBussiness();
    this.searchresultnew = listmaster;
  }

  openModal(id: string, companyid: any) 
  {  
    this.companyid = companyid;
    this.modalService.open(id);
  }

  onActive(id: any)
  {
    this.disableBtn = false;
    var data = { companyid: this.companyid, isstatus: true}
    this.amldetails.postActiveAmlDetails(data).subscribe((searchRes: any) => {
        
        this.modalService.close(id);
        this.alerts.success(searchRes.message, false);

        
        this.pageload(); 
    });
  }


  onDeactive(id: any)
  { 
    this.disableBtn = false;
    var data = { companyid: this.companyid, isstatus: false}
    this.amldetails.postActiveAmlDetails(data).subscribe((searchRes: any) => {

      this.modalService.close(id);
      this.pageload(); 
      this.alerts.success(searchRes.message, false);
    });
  }


  closeModal(id: string) 
  { 
    this.modalService.close(id);
  }

  // viewHistory(historyid: any){
  //   this.router.navigate(['/adverse-credit-history-view/'+historyid]);
  // }

}
