"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var COSComponent = /** @class */ (function () {
    function COSComponent() {
    }
    COSComponent.prototype.ngOnInit = function () {
    };
    return COSComponent;
}());
exports.COSComponent = COSComponent;
