import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../services/customer.service';
import { CountryService } from '../../../services/country.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CheckserviceService } from '../../../services/checkservice.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-righ-to-work-history-list',
  templateUrl: './righ-to-work-history-list.component.html',
  styleUrls: ['./righ-to-work-history-list.component.css']
})
export class RighToWorkHistoryListComponent implements OnInit {
  empid:any;
  listemployee: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private checkService: CheckserviceService,
    private router : Router  ,
    private datePipe: DatePipe 
    ) { }

  ngOnInit() {
    
    this.empid = this.activatedRoute.snapshot.paramMap.get("empid");

   var data = {employeeid: this.empid}
    this.checkService.getrighttoworkhistorybyemployeeid(data).subscribe((righttoworklog: any) => {         
        this.listemployee = righttoworklog;
    });
  }

  shouldDisplayDate(date: Date) : boolean 
  {    

    console.log('Current Date', this.datePipe.transform(date, 'yyyy-MM-dd'));

    console.log('System Date', this.datePipe.transform('10/02/2022', 'yyyy-MM-dd'));


    if (this.datePipe.transform(date, 'yyyy-MM-dd')  >=  this.datePipe.transform('10/02/2022', 'yyyy-MM-dd')) 
    { 
        console.log('yes');
        return true;
    }  

    console.log('False');
    return false;
}



  completerightowork(logid){
    localStorage.SetItem('logid',logid);
    this.router.navigate(['/right-to-work-app-history']);

  }

  viewemployeerighttoworkhistory(empid)
  { 
    localStorage.removeItem('logid');
    this.router.navigate(['/right-to-work-app-history', empid]);
  }
  completerighttoworkhistory(logid,empid)
  { 
    localStorage.setItem('logid', logid);
    // localStorage.SetItem('logid',logid); //localStorage.removeItem('logid');
    this.router.navigate(['/right-to-work-app',empid ]);
  }
}
