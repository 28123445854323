<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper mt-1">
        <div class="container">
            <div class="check_verification" *ngIf="token_expired">
                <div class="wizadlineshow">
                    <div class="wizard_details">
                        <div class="container">
                            <div class="main-banner">
                                <div class="banner-form">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="tankushow">
                                                <div class="detailstext">
                                                    <h1 class="tokenfont">Token Expired</h1>
                                                    <p></p>
                                                </div>
                                                <img alt="shape" src="../../../../../assets/img/thanks.png">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <form [formGroup]="idproofpopForm" #idproofForm="ngForm" class="s12 white docvisapass">
                <div class="check_verification">
                    <div class="wizadlineshow mt-2">
                        <!-- <nav class="wizards">
                            <ul>
                                <li id="wizard-header-1" [class.active]="wizard == 0 || wizard == 1 || wizard == 2">
                                    <a><span>1</span></a>
                                    <p>Rapid Screening </p>
                                </li>
                                <li id="wizard-header-2" [class.active]="wizard2 || wizard3">
                                    <a><span>2</span></a>
                                    <p>Payment</p>
                                </li>
                                <li id="wizard-header-3" [class.active]="wizard3">
                                    <a><span>3</span></a>
                                    <p>Thank You</p>
                                </li>
                            </ul>
                        </nav> -->
                        <div class="wizard_img_box"  *ngIf="wizard != 5">
                            <div class="wizard_img" [class.active]="wizard == 1 || wizard == 2 || wizard == 3 || wizard == 4 || wizard == 5">
                                <img src="../../../../../assets/img/icon/1214.png">
                                <!-- <img class="widthset" [src]="imgURL" *ngIf="imgURL"> -->
                            </div>
                            <div class="wizard_img" [class.active]="wizard == 2 || wizard == 3 || wizard == 4 || wizard == 5">
                                <img src="../../../../../assets/img/icon/1214.png">
                                <!-- <img class="widthset" [src]="imgbackURL" *ngIf="imgbackURL">   -->
                            </div>
                            <div class="wizard_img"  [class.active]="wizard == 3 || wizard == 4 || wizard == 5">
                                <img src="../../../../../assets/img/icon/1231.png">
                                <!-- <img class="widthuser" [src]="profile_pic_url" *ngIf="profile_pic_url" > -->
                            </div>
                            <div class="wizard_img"  [class.active]="wizard == 4 || wizard == 5">
                                <img src="../../../../../assets/img/icon/1111.png" >
                            </div>
                        </div>

                        <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="idproofDetail.employeeid">
                        <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="idproofDetail.employeeidproofdeatilid">
                        <input type="hidden" formControlName="idtype" class="form-control" required name="idtype" id="idtype" [(ngModel)]="idproofDetail.idtype">

                        <div id="wizard-1" *ngIf="wizard == 0" class="wizard_details width100">
                            <div class="container">
                                <div class="main-banner">
                                    <div class="overviewsshow check_support mt-1">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="header">
                                                    <h1 class="headprofile mt-0 mb-0">Add Driving Licence Details</h1>
                                                </div>
                                                <hr/>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="row">
                                                <div class="form-group col-lg-6">
                                                    <label>Driving Licence No.*</label>
                                                    <input type="idnumber" formControlName="idnumber" name="idnumber" maxlength="30"
                                                        id="idnumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.idnumber.errors }"
                                                        [(ngModel)]="idproofDetail.idnumber" placeholder="Driving Licence No.">
                                                    <div *ngIf="submitted && f.idnumber.errors" class="invalid-feedback">
                                                        <div *ngIf="f.idnumber.errors.required">Please enter driving licence no.</div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-lg-6">
                                                    <label>Expiry Date*</label>
                                                    <mat-form-field class="datecontrol">
                                                        <input matInput [matDatepicker]="picker2" id="docexpiry" name="docexpiry" formControlName="docexpiry" [ngClass]="{ 'is-invalid': submitted && f.docexpiry.errors }" class="form-control noborder-invalid datecontrol" [(ngModel)]="idproofDetail.docexpiry">
                                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                        <mat-datepicker #picker2></mat-datepicker>
                                                    </mat-form-field>
                                                    <div *ngIf="submitted && f.docexpiry.errors" class="invalid-feedback">
                                                        <div *ngIf="f.docexpiry.errors.required">Please choose expiry date</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="form-group col-lg-6">
                                                    <label>Driving Licence Issue Country*</label>
                                                    <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [(ngModel)]="idproofDetail.nationalityid">
                                                        <option [value]="defaultVal">Select country </option>
                                                        <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                                                    </select>
                                                    <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.nationalityid.errors.required">Please select driving licence issuing country</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 aligncenter">
                                                    <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                                    <div class="next text-right">
                                                        <button type="button" class="btn btn-primary com_btn" (click)="toggle('1')">Next</button>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="wizard-2"  *ngIf="wizard == 1"  class="wizard_details">
                            <div class="container">
                                <div class="main-banner">
                                    <div class="overviewsshow check_support mt-1">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="header">
                                                    <h1 class="headprofile mt-0 mb-0">Upload Front Side Driving Licence Image</h1>
                                                </div>
                                                <hr/>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="imgcent col-lg-12 col-md-12 text-center" *ngIf="imgURL">
                                                        <img class="width50 p-4" [src]="imgURL">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="input-group" (click)="file.click()">
                                                            <div class="custom-file checkvisa">
                                                                <input type="file" #file (change)="selectFile($event)">
                                                                <span class="lnr lnr-upload"></span>
                                                                <label class="custom-file-label">
                                                                Browse Image                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="invalid-feedback" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                                        <div class="invalid-feedback" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png)</div>

                                                        <!-- <div class="invalid-feedback" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png)</div> -->
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="col-sm-6">
                                                <button type="submit" class="btn btn-success camerashap"><span class="lnr lnr-camera"></span> <span class="text">Capture Your Pic</span></button>
                                            </div>  -->
                                        </div>

                                        <hr/>
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 text-right aligncenter">
                                                <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                                <div class="next">
                                                    <button type="submit" (click)="toggle('0')" class="btn btn-dark mr-2 com_btn mt-0">Previous</button>
                                                    <button type="button" class="btn btn-primary com_btn mt-0" [disabled]="(imgURL) ? false : true" (click)="toggle('2')">Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="wizard-2"  *ngIf="wizard == 2"  class="wizard_details">
                            <div class="container">
                                <div class="main-banner">
                                    <div class="overviewsshow check_support mt-1">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="header">
                                                    <h1 class="headprofile mt-0 mb-0">Upload Back Side Driving Licence Image</h1>

                                                </div>
                                                <hr/>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="imgcent col-lg-12 col-md-12 text-center" *ngIf="imgbackURL">
                                                        <img class="width50 p-4" [src]="imgbackURL">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="input-group" (click)="file1.click()">
                                                            <div class="custom-file checkvisa">
                                                                <input type="file" #file1 (change)="selectbackFile($event)">
                                                                <span class="lnr lnr-upload"></span>
                                                                <label class="custom-file-label">
                                                                    Browse Image
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="invalid-feedback" *ngIf="filebacksizeerror == true">File size must be less than 2 MB</div>
                                                        <div class="invalid-feedback" *ngIf="filebackformaterror == true">Please choose a valid file format (jpg, png)</div>

                                                        <!-- <div class="invalid-feedback" *ngIf="filebackformaterror == true">Please choose a valid file format (jpg, png)</div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-sm-6">
                                                <button type="submit" class="btn btn-success camerashap"><span class="lnr lnr-camera"></span> <span class="text">Capture Your Pic</span></button>
                                            </div> -->
                                        </div>

                                        <hr/>
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 text-right aligncenter">
                                                <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                                <div class="next">
                                                    <button type="submit" (click)="toggle('1')" class="btn btn-dark mr-2 com_btn mt-0">Previous</button>
                                                    <button type="submit" (click)="toggle('3')" class="btn btn-dark mr-2 com_btn mt-0">Skip</button>
                                                    <button type="button" class="btn btn-primary com_btn mt-0" [disabled]="(imgbackURL) ? false : true" (click)="toggle('3')">Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="wizard-23"  *ngIf="wizard == 3"  class="wizard_details">
                            <div class="container">
                                <div class="main-banner">
                                    <div class="overviewsshow check_support mt-1">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="header">
                                                    <h1 class="headprofile mt-0 mb-0">Upload Selfie</h1>
                                                      </div>
                                                <hr/>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="imgcent col-lg-12 col-md-12 text-center" *ngIf="profile_pic_url">
                                                        <img class="width50 p-4" [src]="profile_pic_url"  >
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="input-group" (click)="pro_pic.click()">
                                                            <div class="custom-file checkvisa">
                                                                <input type="file" #pro_pic (change)="selectprofile($event)">
                                                                <span class="lnr lnr-upload"></span>
                                                                <label class="custom-file-label">
                                                                    Browse Image
                                                                </label>
                                                            </div>
                                                        </div>
                                                    <div class="invalid-feedback" *ngIf="pro_pic_size_error == true">File size must be less than 2 MB</div>
                                                    <div class="invalid-feedback" *ngIf="pro_pic_format_error == true">Please choose a valid file format (jpg, png)</div>

                                                        <!-- <div class="invalid-feedback" *ngIf="pro_pic_format_error == true">Please choose a valid file format (jpg, png)</div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr/>
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 text-right aligncenter">
                                                <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                                <div class="next">
                                                    <button type="submit" (click)="toggle('2')" class="btn btn-dark mr-2 mt-0 com_btn">Previous</button>
                                                    <button type="button" class="btn btn-primary com_btn mt-0" (click)="toggle('4')">Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="wizard-23"  *ngIf="wizard == 4"  class="wizard_details">
                            <div class="container">
                                <div class="main-banner">
                                    <div class="overviewsshow check_support mt-1">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="header">
                                                    <h1 class="headprofile mt-0 mb-0">Documents Uploaded Successfully</h1>
                                                </div>
                                                <hr/>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="uploaded_img">
                                                    <div class="img_sec">
                                                        <img class="p-4" [src]="imgURL">
                                                        <i class="fa fa-check" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="img_sec">
                                                        <img class="p-4" [src]="imgbackURL">
                                                        <i class="fa fa-check" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="img_sec">
                                                        <img class="p-4 userself" [src]="profile_pic_url">
                                                        <i class="fa fa-check" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr/>
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 text-right aligncenter">
                                                <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                                <div class="next">
                                                    <button type="submit" (click)="toggle('3')" class="btn btn-dark mr-2 mt-0 com_btn">Previous</button>
                                                    <button type="button" class="btn btn-primary com_btn mt-0"
                                                        [disabled]="(profile_pic_url) ? false: true" (click)="onSubmit(idproofForm.value)">Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="wizard-4"  *ngIf="wizard == 5" class="wizard_details">
                            <div class="container">
                                <div class="main-banner">
                                    <div class="overviewsshow check_support mt-1 p-0">
                                        <div class="banner-form thankbgsss">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                  <div class="tankushow">
                                                      <img alt="shape" src="../../../../../assets/img/thankcheck.png">
                                                      <div class="detailstext">
                                                          <h4>Driving Licence Uploaded Successfully</h4>
                                                          <p>You do not need to take any further action</p>
                                                              <h1>Thank You!</h1>
                                                      </div>
                                                  </div>

                                                </div>
                                                <!-- <div class="next">
                                                    <button type="submit" (click)="toggle('2')" class="btn btn-dark mr-3">Previous</button>
                                                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" (click)="onSubmit(visaForm.value)"/>
                                                </div> -->
                                            </div>
                                            </div>
                                      </div>

                                </div>
                            </div>
                        </div>
                        <div id="wizard-5"  *ngIf="wizard == 6" class="wizard_details">
                            <div class="container">
                                <div class="main-banner">
                                    <div class="overviewsshow check_support mt-1">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="browser-screen-loading-content" >
                                                    <div class="loading-dots dark-gray">
                                                        <i></i>
                                                        <i></i>
                                                        <i></i>
                                                        <i></i>
                                                        <i></i>
                                                        <i></i>
                                                        <i></i>
                                                    </div>
                                                    <p>Loading</p>
                                                </div>
                                            </div>
                                            <!-- <div class="next">
                                                <button type="submit" (click)="toggle('2')" class="btn btn-dark mr-3">Previous</button>
                                                <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" (click)="onSubmit(visaForm.value)"/>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>
