import { Component, OnInit, ViewEncapsulation, ViewChild  } from '@angular/core';

import { ReferenceService } from '../../services/reference.service';

import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import * as FileSaver from 'file-saver';
// import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';


@Component({
  selector: 'app-statusbulkreference',
  templateUrl: './statusbulkreference.component.html',
  styleUrls: ['./statusbulkreference.component.css']
})
export class StatusbulkreferenceComponent implements OnInit {
  
  csvRecords:any;
  constructor(
    private formBuilder: FormBuilder, 
    private referencingmoduleService: ReferenceService, 
    public toastrService: ToastrService
    // private ngxCsvParser: NgxCsvParser
  ) {
    
  }

  ngOnInit() {  
    this.generateRecord();
  }

  generateRecord(){
    this.csvRecords = [];
    this.referencingmoduleService.getquestiontemplates().subscribe((response: any) => {
      this.referencingmoduleService.getbulkreferencerequests().subscribe((resdata: any) => {
        if(resdata.success){
          resdata.data.forEach((element, index) => {
            response.forEach((element2, index) => {
              if(element2.id == element.modulequestiontemplateid){
                element.questionprofile = element2.templatename;
              }
            });
            this.csvRecords.push(element);
          });
          
        }
      });
    });

  }
}

