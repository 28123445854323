<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
      <a [routerLink]="['/reference-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to  Automated Reference Check</a>
      <div class="clearfix"></div>
      <div class="tabs">
        <div class="tabs__content">
          <div class="tabs__content__item active-tab">
            <div class="content">
              <div class="row">
                <div class="col-sm-3">
                  <div class="ref_userlist configbox">
                    <h1>Question Profiles</h1>
                    <ul class="configue">
                      <li *ngFor="let questionTemplate of questionTemplates;let i = index"
                      [ngClass]="[i == activeprofile ? 'active' : '']">                     
                      <span class="textshhs"
                        (click)="getquestionTemplatedetails($event, questionTemplate.id,i)">{{questionTemplate.templatename}}</span>
                      <div class="icons_box">
                        <span data-toggle="modal" data-target="#warning-modal" class="pull-right"><i
                            (click)="deletetemplate(questionTemplate)" class="fa fa-trash-o"></i></span>
                        <span class="pull-right"><i  
                            (click)="edittemplate(questionTemplate, 'custom-profile-1')" class="fa fa-pencil"></i></span>
                      </div>
          
                    </li>
                    
                    </ul>
                    <div class="con_newpro">
                      <button type="submit" (click)="addtemplate('custom-profile-1')" class="btn btn-danger">New Profile</button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-9">
                  <div class="addQuestion">
                    <h4>Default</h4>
                    <button type="submit" (click)="addquestion('custom-profiletemplate-1')" class="btn btn-outline-dark">
                      Add New Question
                    </button>
                  </div>
                  <div class="info_bar">
                    <div class="contentbox">
                      <h5>Please Note</h5>
                      <p>Please add these questions to every reference request: Company Name, Candidate job title, Referee Job title, Candidate start date & end date, and any additional information.</p>
                    </div>
                    <i class="fa fa-close"></i>
                  </div>
                  <div class="content_list_box">

                    <div class="row mbm-20" [dragula]="'bag-container'" [(dragulaModel)]='questionTemplatesdetails'>
                      <div class="grid_box" *ngFor="let questionTemplatesdetail of questionTemplatesdetails;let i = index">
                        <div class="draggable">
                          <div class="ans_text">
                            <p>{{questionTemplatesdetail.question}}</p>
                            <div class="optionaa" *ngIf="questionTemplatesdetail.questiontype === 'dropdown'">
                              <span *ngFor="let item of questionTemplatesdetail.questionoptions.split(',')">{{item}}</span>
                            </div>
                          </div>
                          <div class="iconsbox_req">
                            <div class="requied" *ngIf="questionTemplatesdetail.required === 'Yes'"
                              [ngClass]="{'green': questionTemplatesdetail.required === 'Yes'}">
                              <span>Required</span>
                              <i class="fa fa-check"></i>
                            </div>
                            <div class="requied" *ngIf="questionTemplatesdetail.required === 'No'">
                              <span>Required</span>
                              <i class="fa fa-close"></i>
                            </div>
                            <div class="staus">
                              <span>{{questionTemplatesdetail.questiontype}}</span>
                            </div>
                            <div class="icons">
                              <i data-toggle="modal" data-target="#createquestion-modal"
                                (click)="editquestion(questionTemplatesdetail,'update','custom-profiletemplate-1')" class="fa fa-pencil"></i>
                              <i data-toggle="modal" data-target="#question-warning-modal"
                                (click)="deletequestion(questionTemplatesdetail.id)" id="{{questionTemplatesdetail.id}}"
                                class="fa fa-trash-o"></i>
                            </div>
                          </div>
                        </div>
                      </div>            
                    </div>


                   
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>





<jw-modal id="custom-profile-1" role="dialog" class="modal modalsmall">

  <form [formGroup]="createTemplateFormGroup" #createTemplateForm="ngForm" class="s12 white"  (ngSubmit)="createNewTemplate(createTemplateForm.value,'custom-profile-1')">

    <div role="document" class="formgroup">
          <div class="modal-content">
            
            <div class="modal-header">
              <h5 class="modal-title">Question Profiles</h5>
              <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
              (click)="closeModal('custom-profile-1');" >
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>



              <div class="modal-body">
                  <div class="container">                 
                      <div class="row" style="display: none;">
                          <div class="form-group width100">
                              <label>Profile Code*</label>
                              <input type="text" value="RDC" formControlName="templatecode" class="" name="templatecode" id="templatecode" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.templatecode.errors }">
                              <div *ngIf="submitted && f.templatecode.errors" class="invalid-feedback">
                                  <div *ngIf="f.templatecode.errors.required">Please enter profile code</div>
                              </div>
                          </div>
                      </div>

                      <div class="row">
                        <div class="form-group width100">
                            <label>Profile Name*</label>
                            <input type="text" formControlName="templatename" class="" name="templatename" id="templatecode" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.templatename.errors }">
                            <div *ngIf="submitted && f.templatename.errors" class="invalid-feedback">
                                <div *ngIf="f.templatename.errors.required">Please enter profile name</div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div class="modal-footer">


                  <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                  <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-profile-1');" />
              </div>
          </div>
      </div>
  </form>
</jw-modal>





<jw-modal id="custom-profiletemplate-1" role="dialog" class="modal modalsmall">
  <form [formGroup]="createquestionforTemplateFormGroup" #createquestionforTemplateForm="ngForm" class="s12 white" (ngSubmit)="createNewquestion(createquestionforTemplateForm.value,'custom-profiletemplate-1')">
      <div role="document" class="formgroup">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add New Question</h5>
              <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
              (click)="closeModal('custom-profiletemplate-1');">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>

              <div class="modal-body">
                  <div class="container">
                      <div class="row">
                          <div class="form-group width100">
                              <label>Question*</label>
                              <input type="text" formControlName="question" class="" name="question" id="question" class="form-control" [ngClass]="{ 'is-invalid': submitted && q.question.errors }" >
                              <div *ngIf="submitted && q.question.errors" class="invalid-feedback">
                                  <div *ngIf="q.question.errors.required">Please enter question</div>
                              </div>
                          </div>
                      </div>

                      <div class="row">
                        <div class="form-group width100">
                            <label>Required*</label>
                            <select formControlName="required"  class="form-control" id="exampleSelect1" [ngClass]="{ 'is-invalid': submitted && q.required.errors }" >
                              <option value="No">No</option>
                              <option value="Yes">Yes</option>
                            </select>
                            <div *ngIf="submitted && q.required.errors" class="invalid-feedback">
                                <div *ngIf="q.question.required.required">Please select Required</div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                      <div class="form-group width100">
                          <label>Question Type*</label>

                          <select formControlName="questiontype" (change)="setValidation()" class="form-control" id="exampleSelect134" [ngClass]="{ 'is-invalid': submitted && q.questiontype.errors }" >
                            <option value='short'>Short</option>
                            <option value='long'>Long</option>
                            <option value='number'>Number</option>
                            <option value='dropdown'>Dropdown</option>
                          </select>


                          <div *ngIf="submitted && q.questiontype.errors" class="invalid-feedback">
                              <div *ngIf="q.questiontype.errors.required">Please enter question type</div>
                          </div>
                      </div>
                  </div>


                  <div class="row" *ngIf="createquestionforTemplateFormGroup.controls.questiontype.value == 'dropdown'">
                    <div class="form-group width100">
                        <label>Question Options*</label>
                        <input type="text" formControlName="questionoptions" class="" name="questionoptions" id="questionoptions" class="form-control" [ngClass]="{ 'is-invalid': submitted && q.questionoptions.errors }" >
                        <div *ngIf="submitted && q.questionoptions.errors" class="invalid-feedback">
                            <div *ngIf="q.questionoptions.errors.required">Please enter question option</div>
                        </div>
                    </div>
                </div>

             

                  </div>
              </div>
              <div class="modal-footer">
                  <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                  <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-profiletemplate-1');" />
              </div>
          </div>
      </div>
  </form>
</jw-modal>
