"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../_services/modal.service");
var asset_service_1 = require("../../services/asset.service");
var forms_1 = require("@angular/forms");
var asset_model_1 = require("../../models/asset/asset.model");
var employee_service_1 = require("../../services/employee.service");
var router_1 = require("@angular/router");
var alert_service_1 = require("../../services/alert.service");
var AdminassetComponent = /** @class */ (function () {
    function AdminassetComponent(modalService, formBuilder, dataset, router, alerts, employeeService) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.dataset = dataset;
        this.router = router;
        this.alerts = alerts;
        this.employeeService = employeeService;
        this.assetDetail = new asset_model_1.Asset();
        this.options = [];
        this.submitted = false;
        this.emptyValue = "";
    }
    AdminassetComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.Userid = localStorage.getItem('userid');
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.assetperm = this.permission.m16;
        this.assetDetail = new asset_model_1.Asset();
        this.assetDetail.category = new asset_model_1.Assetcategory();
        this.getassetformgroup();
        this.dataset.getassetlist().subscribe(function (data) {
            _this.record = data.length;
            _this.data = data;
            _this.datalist = data;
            _this.pageOfItems = _this.data;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
        this.Userid = localStorage.getItem('userid');
        var sstatustype = { 'status': true, 'id': this.Userid };
        this.dataset.getassetcategory(sstatustype).subscribe(function (data) {
            _this.assetcategorylist = data;
        });
        this.employeeService.getlistofemployee().subscribe(function (employeedata) {
            _this.employeelist = employeedata;
        });
        this.emptyValue = "";
    };
    AdminassetComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    AdminassetComponent.prototype.getassetformgroup = function () {
        this.assetForm = this.formBuilder.group({
            id: [''],
            employeeid: ['', forms_1.Validators.required],
            categoryid: ['', forms_1.Validators.required],
            assetdescption: ['', forms_1.Validators.required],
            serialnumber: [''],
            statdate: ['', forms_1.Validators.required],
            enddate: ['']
        });
    };
    Object.defineProperty(AdminassetComponent.prototype, "f", {
        get: function () { return this.assetForm.controls; },
        enumerable: true,
        configurable: true
    });
    AdminassetComponent.prototype.onSubmit = function (formasset, id) {
        var _this = this;
        this.submitted = true;
        // var formasset = assetformdata.value;
        if (this.assetForm.invalid) {
            return;
        }
        this.disableBtn = false;
        formasset.userid = this.Userid;
        var sd = new Date(formasset.statdate);
        formasset.statdate = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        var ed = new Date(formasset.enddate);
        formasset.enddate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        this.dataset.assetdetailsUpdate(formasset).subscribe(function (response) {
            _this.dataset.getassetlist().subscribe(function (data) {
                _this.record = data.length;
                _this.data = data;
                _this.datalist = data;
                _this.pageOfItems = _this.data;
                if (_this.record < 30) {
                    _this.pageOfItems = _this.data;
                }
                else {
                    _this.items = _this.data;
                }
                _this.modalService.close(id);
                _this.alerts.success('Assets updated successfully', true);
            });
        });
    };
    AdminassetComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.disableBtn = true;
        this.buttoninsert = "Submit";
        this.assetDetail = new asset_model_1.Asset();
        this.getassetformgroup();
        this.emptyValue = "";
        // this.assetDetail.categoryid = "0";
        // this.assetDetail.employeeid = "0";
        this.assetDetail.id = "0";
        this.modalService.open(id);
    };
    AdminassetComponent.prototype.openModal = function (id, data) {
        this.submitted = false;
        this.disableBtn = true;
        this.buttoninsert = "Update";
        this.assetDetail = new asset_model_1.Asset();
        this.assetDetail = data;
        this.modalService.open(id);
    };
    AdminassetComponent.prototype.closeModal = function (id) {
        this.assetDetail = new asset_model_1.Asset();
        this.modalService.close(id);
    };
    return AdminassetComponent;
}());
exports.AdminassetComponent = AdminassetComponent;
