"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var payment_service_1 = require("../../../services/payment.service");
var modal_service_1 = require("../../../_services/modal.service");
var router_1 = require("@angular/router");
var stripe_angular_1 = require("stripe-angular");
var alert_service_1 = require("../../../services/alert.service");
var company_service_1 = require("../../../services/company.service");
var app_global_1 = require("../../../app.global");
var address_model_1 = require("../../../models/address/address.model");
var country_service_1 = require("../../../services/country.service");
var CheckpaymentmethodComponent = /** @class */ (function () {
    function CheckpaymentmethodComponent(formBuilder, modalService, router, alerts, companyService, StripeScriptTag, paymentService, country) {
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.router = router;
        this.alerts = alerts;
        this.companyService = companyService;
        this.StripeScriptTag = StripeScriptTag;
        this.paymentService = paymentService;
        this.country = country;
        this.publishableKey = app_global_1.AppGlobals.API_STRIPE;
        this.editCard = false;
        this.submitted = false;
        this.paymentloading = false;
        this.paymentSubmit = 0;
    }
    CheckpaymentmethodComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.paymentloading = false;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var isadmin = localStorage.getItem('isadmin');
        if (isadmin != 'true') {
            this.router.navigate(['/permission-denied']);
        }
        this.StripeScriptTag.setPublishableKey(this.publishableKey);
        this.display = 'block';
        this.addpopForm = this.formBuilder.group({
            name: ['', forms_1.Validators.required],
            address1: ['', forms_1.Validators.required],
            address2: [''],
            cityname: ['', forms_1.Validators.compose([forms_1.Validators.required,])],
            statename: ['', forms_1.Validators.required],
            pincode: ['', forms_1.Validators.required],
            stateid: [''],
            taxid: [''],
            countryid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            cardid: [''],
            customerid: ['']
        });
        this.getCurrentCompany();
        this.companyService.gettotalcards().subscribe(function (data) {
            _this.defaultcard = data.customer.default_source;
            console.log(data);
            _this.customer = data.customer;
            _this.cardlist = data.card;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.addresslistDetail = new address_model_1.Address();
        this.addresslistDetail.countryid = '70';
    };
    Object.defineProperty(CheckpaymentmethodComponent.prototype, "g", {
        get: function () { return this.addpopForm.controls; },
        enumerable: true,
        configurable: true
    });
    CheckpaymentmethodComponent.prototype.getCurrentCompany = function () {
        var _this = this;
        this.companyService.getcompanyData().subscribe(function (data) {
            _this.currentprice = data.plan.prices;
            _this.companyData = data;
        });
    };
    CheckpaymentmethodComponent.prototype.removeCard = function (id) {
        var _this = this;
        if (confirm("Do you want to remove this card?")) {
            this.cardvalue = { "id": id };
            this.paymentService.removeCard({ "id": id }).subscribe(function (data) {
                _this.companyService.gettotalcards().subscribe(function (data) {
                    _this.defaultcard = data.customer.default_source;
                    _this.cardlist = data.card;
                });
            });
        }
    };
    CheckpaymentmethodComponent.prototype.setDefaultcardCard = function (id) {
        var _this = this;
        if (confirm("Do you want to set this card to default?")) {
            this.paymentloading = true;
            this.cardvalue = { "cardid": id };
            this.paymentService.defaultCardset({ "cardid": id }).subscribe(function (data) {
                _this.companyService.gettotalcards().subscribe(function (data) {
                    _this.defaultcard = data.customer.default_source;
                    _this.customer = data.customer;
                    _this.paymentloading = false;
                    _this.cardlist = data.card;
                });
            });
        }
    };
    CheckpaymentmethodComponent.prototype.setStripeToken = function (token, formvalue, id) {
        // console.log(token);
        var _this = this;
        var country = '';
        this.countrylist.forEach(function (element) {
            if (formvalue.countryid == element.countryid) {
                country = element.countryname;
            }
        });
        var data = {
            "id": token.id,
            "customer": this.companyData.stuser,
            "cmonth": token.card.exp_month,
            "cyear": token.card.exp_year,
            "cardnumber": token.card.last4,
            "tokenid": token.card.id,
            "cardname": token.card.brand,
            "country": token.card.country,
            "cstatus": "1",
            "cardholdername": formvalue.name,
            "address1": formvalue.address1,
            "address2": formvalue.address2,
            "cityname": formvalue.cityname,
            "statename": formvalue.statename,
            "cardcountry": country,
            "postcode": formvalue.pincode,
            "taxid": formvalue.taxid
        };
        this.submitted = true;
        if (this.addpopForm.invalid) {
            return;
        }
        if (this.paymentSubmit == 0) {
            this.paymentSubmit = 1;
            this.paymentService.addnewCard(data).subscribe(function (data) {
                if (data.success == "success") {
                    _this.companyService.gettotalcards().subscribe(function (data) {
                        _this.alerts.success("Card has been successfully added.", true);
                        _this.cardlist = data.card;
                        _this.modalService.close(id);
                        _this.paymentSubmit = 0;
                        _this.StripeScriptTag.setPublishableKey(_this.publishableKey);
                        _this.getCurrentCompany();
                    });
                }
                else {
                    _this.paymentSubmit = 0;
                }
            });
        }
    };
    CheckpaymentmethodComponent.prototype.onStripeError = function (error) {
        console.error('Stripe error', error);
    };
    CheckpaymentmethodComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    CheckpaymentmethodComponent.prototype.editcard = function (id, value) {
        this.submitted = false;
        this.display = 'none';
        this.editCard = true;
        var countryid = 70;
        this.cardDetails = value;
        this.countrylist.forEach(function (element) {
            if (value.address_country == element.countryname) {
                countryid = element.countryid;
            }
        });
        this.addpopForm.reset({
            name: value.name,
            address1: value.address_line1,
            address2: value.address_line2,
            cityname: value.address_city,
            statename: value.address_state,
            pincode: value.address_zip,
            taxid: (this.customer.metadata) ? this.customer.metadata.taxid : '',
            countryid: countryid,
            cardid: value.id,
            customerid: value.customer
        });
        this.modalService.open(id);
    };
    CheckpaymentmethodComponent.prototype.updateyourplan = function (id) {
        this.editCard = false;
        this.submitted = false;
        this.display = 'block';
        this.addpopForm.reset({
            name: '',
            address1: '',
            address2: '',
            cityname: '',
            statename: '',
            pincode: '',
            taxid: (this.customer.metadata) ? this.customer.metadata.taxid : '',
            countryid: 70,
            cardid: '',
            customerid: ''
        });
        this.modalService.open(id);
    };
    CheckpaymentmethodComponent.prototype.updateCard = function (value, id) {
        var _this = this;
        this.submitted = true;
        if (this.addpopForm.invalid) {
            return;
        }
        this.countrylist.forEach(function (element) {
            if (value.countryid == element.countryid) {
                value.cardcountry = element.countryname;
            }
        });
        // console.log(value);
        this.paymentService.updateCard(value).subscribe(function (data) {
            // console.log(data);
            if (data.success == "success") {
                _this.companyService.gettotalcards().subscribe(function (data) {
                    console.log(data);
                    _this.alerts.success("Card has been successfully updated.", true);
                    _this.cardlist = data.card;
                    _this.modalService.close(id);
                    _this.getCurrentCompany();
                });
            }
        });
    };
    return CheckpaymentmethodComponent;
}());
exports.CheckpaymentmethodComponent = CheckpaymentmethodComponent;
