<app-header></app-header>
<div class="container">


  <div class="overviewsshow check_support mt-4">

        
      <!-- <div class="row row-sm">
        <div class="col-lg-8">
            <div>
                <app-checkcountshow (getRemainingChecks)="getRemainingChecks()"></app-checkcountshow>                                   
            </div>
            
        </div>                             
       
    </div> -->

    <div class="nosearch" >
      <div class="header aligncenter mb-3">
        <h1 class="headprofile mt-0 mb-0">AI Document Verification</h1>
        <button type="button" *ngIf="iframevisasrc == '' && stage ==1" class="btn btn-primary com_btn" (click)="clearData()" data-dismiss="modal">New Document Search</button> 
      </div>
      <hr/>
    
      <div class="formgroup">
        <div class="row row-sm mg-t-10" *ngIf="iframevisasrc == '' && stage ==0">
            <div class="col-lg-6">
              <label>Upload Document*</label>
                <div class="input-group mb-3" (click)="file.click()">
                    <div class="custom-file">
                        <input type="file" #file (change)="selectFile($event)">
                        <label class="custom-file-label">{{filename}}</label>
                    </div>
                    <div class="invalid-feedback" *ngIf="filerequirederror">Please select your document</div>
                    <div class="invalid-feedback" *ngIf="filesizeerror">File size must be less than 2 MB</div>
                    <div class="invalid-feedback" *ngIf="fileformaterror">Please choose a valid file format (pdf, png)</div>
                </div>
             
          </div>     
            <div class="col-lg-6">
              <label>Select Billing Organization *</label>
                <div class="input-group mb-3" >
                    <div class="custom-file">
                        <select (change)="changeOrganization($event.target.value)" class="form-control">
                            <option value="" [selected]="orgcode == ''">Select Billing Organization</option>
                            <option value="ffbf26fc-7d5b-49e4-a174-c16aed5fde5e" [selected]="orgcode == 'ffbf26fc-7d5b-49e4-a174-c16aed5fde5e'">EDF</option>
                            <option value="95d8fd28-4c2a-4fed-a21a-05715a3b2d88" [selected]="orgcode == '95d8fd28-4c2a-4fed-a21a-05715a3b2d88'">E-on</option>
                            <option value="17eb884d-e19c-4866-a75c-6e95d738ad27" [selected]="orgcode == '17eb884d-e19c-4866-a75c-6e95d738ad27'">OctoPus</option> 
                            <option value="56e2d183-5c10-412b-bfa5-d4fb8305eef2" [selected]="orgcode == '56e2d183-5c10-412b-bfa5-d4fb8305eef2'">British Gas</option>
                            <option value="54d02c21-ccec-4562-812c-b9c1452aaf88" [selected]="orgcode == '54d02c21-ccec-4562-812c-b9c1452aaf88'">OVO</option> 
                            <option value="450afd59-d420-442b-94e0-1cc48c6a64d8" [selected]="orgcode == '450afd59-d420-442b-94e0-1cc48c6a64d8'">N Power</option> 
                                                       
                                                      
                        </select>
                    </div>
                    <div class="invalid-feedback" *ngIf="orgerror == true">Please select your Billing Organization</div>        
                </div>   
                    
            </div>          
        </div>

        <div class="row" *ngIf="(iframevisasrc == '' && stage ==0) || loading ==0">
          <div class="col-sm-12 position-re">
            <div class="browser-screen-loading-content" *ngIf="loading == 0" >
              <div class="loading-dots dark-gray">
                  <i></i>
                  <i></i>
                  <i></i>
                  <i></i>
                  <i></i>
                  <i></i>                                
                  <i></i>
              </div>
              <p>Loading</p>
          </div>
          </div>
         
        </div>
        <div class="row" *ngIf="iframevisasrc == '' && stage ==0">
          <div class="col-sm-12 text-right">      
            <button  type="submit" class="btn btn-primary com_btn" (click)="submitdata()" >Submit</button>
          <button type="button" class="btn btn-secondary com_btn" (click)="clearData()" data-dismiss="modal">Clear</button> 
          </div>
        </div>

      

        <div class="row row-sm mg-t-10" *ngIf="iframevisasrc != '' && stage ==0">

          <div class="col-lg-12 mb-3">
            If you are not able to view the document <a (click)="reload()" style="color: darkred; cursor:pointer;"> Click Here</a>
          </div>
            <div class="col-lg-12">
              <div class="overlaytext"><p>Document View</p></div>
              <iframe [src]="iframevisasrc" width="100%" height="850" frameborder="0"></iframe>              
            </div>
        </div>
        <!-- <div class="row row-sm mg-t-10">
          <div class="col-lg-12">
           {{output}}        
          </div>
        </div> -->
        

    </div>
    
    <div class="row">    
    <div class="col-sm-12 text-right" *ngIf="iframevisasrc != ''"> 
      <hr/>     
         <button  type="submit" class="btn btn-primary com_btn" (click)="uploadfiletoAIverification()" > Verify Now</button>
        <button type="button" class="btn btn-secondary com_btn" (click)="clearData()" data-dismiss="modal">Clear</button> 
    </div>
  </div>

  <div class="row" *ngIf="error==true && stage ==1">
    <div class="col-lg-12">      
          <h5>Your Document Verification has: <span  style="color: rgb(255, 5, 5);"> Failed </span> </h5>       
    </div>

    <!-- <div class="col-sm-12 text-center" *ngIf="iframevisasrc == '' && stage ==1">           
      <button type="button" class="btn btn-primary com_btn" (click)="clearData()" data-dismiss="modal">New Document Search</button> 
    </div> -->

  </div>
  <div class="row" *ngIf="output && stage ==1">

    <div class="col-lg-12">
        <h5>Your Document Verification has: <span style="color: darkgreen;"> Passed</span> </h5>
    </div>
    <div class="col-lg-6">
        <div class="border-box" style="min-height: auto;">
            <div class="overview_details emp">
              <div class="profile_dtailsshow">
                <div class="detailsshow width100 mt-0 pr-3">
                    <span>Bill Date</span>
                     <p> {{output.billdate}}</p>
                </div>                  
             </div>
             <div class="profile_dtailsshow">
              <div class="detailsshow width100 mt-0 pr-3">
                  <span>Is this bill older than 3 months?</span>
                   <p> {{output.Cbill}}</p>
              </div>                  
            </div>
               
                <div class="profile_dtailsshow">
                  
                    <div class="detailsshow width50 pr-3">
                        <span>Full Name</span>
                         <p>{{output.name}}</p>
                    </div>
                    <div class="detailsshow width50 mt-0">
                      <span>Total Amount</span>
                       <p>{{output.amount }}</p>
                  </div>
                    <!-- <div class="detailsshow width50">
                        <span>Item Amount</span>
                         <p>{{output.amount }}</p>
                    </div> -->
                </div>
               <!-- <div class="profile_dtailsshow">
                     <div class="detailsshow width50 pr-3 mt-0">
                        <span>Item Subtotal</span>
                         <p>{{output.camount }}</p>
                    </div> 
                   
                </div>-->
                <div class="profile_dtailsshow">
                    <div class="detailsshow width100 mt-0 pr-3">
                        <span>Full Address</span>
                         <p> {{output.address}}</p>
                    </div>                  
                </div>
            </div>
        </div>
    </div>
    

    
</div>
</div>



  </div>
</div>

