import { Country } from "../address/country.model";
import { Soc } from "./Soc.model";

// export class Jobs {
//     id: number;
//     employeehourapplyid: number;
//     projectid: number;
//     title: number;
//     employeeid: number;
//     expensetypeid: number;
//     amountpaid: number;
//     paidby: number;
//     start: Date;
//     end: string;
//     imagename: string;
//     expenseremark: string;
//     locationname: string;
//     expenseremarksid: number;
//   }

export class Jobs {
  jobmasterid: number;
  urldocumentid: number;
  jobtittle: string;
  jobdate: string;
  closedate: string;
  contactname: string;
  contactrole: string;
  joburl1: string;
  joburl2: string;
  joburl3: string;
  joburl4: string;
  salary: number;
  maxsalary: number;
  socid: string = "";
  emailid: string;
  phonenumber: string;
  postalcode: string;
  address1: string;
  address2: string;
  cityname: string;
  countyid: string;
  countryid: number;
  qualification: string;
  addposition: string = "";
  stypeid: string = "";
  jobdescreption: string = "";
  country: Country;
  soc: Soc;
  countyname: string;
  screen: Screen;
}

export class Screen
{
  screenshotdayid: string;
  id: string;
  urldocument1: string;
  urldocument2: string;
  urldocument3: string;
  urldocument4: string;
}