import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-subscriptionsuccess',
  templateUrl: './subscriptionsuccess.component.html',
  styleUrls: ['./subscriptionsuccess.component.css']
})
export class SubscriptionsuccessComponent implements OnInit {
  
  success:any;
  paytext: number;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {


    this.paytext = parseInt(this.activatedRoute.snapshot.paramMap.get("id"));

    if(this.paytext === 1)
    {
      this.success = true;
    }
    else if(this.paytext === 0)
    {
      this.success =false;
    }

  }

}
