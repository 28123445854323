<app-header>
</app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <a style="cursor: pointer;" (click)="amllogredirect()" class="backlist mb-4 mr-0"><i aria-hidden="true" class="fa fa-angle-left"></i> {{ backtext}} </a>
           
            <div class="clearfix"></div>
            <div class="tabs__content">

                <div class="tabs__content__item active-tab"  *ngIf="!noresult">
                    <div class="content">
                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Personal Details </h5>
                            </div>

                            <div class="col-lg-6">
                                <a type="button" (click)="loaddatasystem()" style="color: #fff;" [innerHtml]="downloadtext" target="_blank" class="btn btn-primary com_btn float-right" >No Record Found <i class="fa fa-Plus ml-2"></i></a>
                                <!-- <a type="button" href="{{ url }}" *ngIf="url"   target="_blank" class="btn btn-primary com_btn float-right" >Download <i class="fa fa-file-pdf-o ml-2"></i></a> -->
                             </div>
                           
                         
                        </div>

                        <div  class="strip_show mt-2" *ngIf="bussinessdetails">
                            <div class="row mr-0 ml-0">
                                       
                                <div class="col-lg-2 mobilecentersh">
                                    <div class="circle_credit_img" *ngFor="let pfimage of bussinessdetails.profileImages">
                                        <img src="{{pfimage }}" class="img width100" >                                                        
                                    </div>

                                    <img src="../../../../../assets/img/user_pic_emp.png" class="img width100" *ngIf="!bussinessdetails.profileImages">
                                   
                                    
                                </div>
                                <div class="col-lg-10 d-flex align-items-center ">
                                    <div class="category flex-wrap justify-content-start align-items-center text-center d-flex width100">

                                        <div class="catog_box" *ngFor="let dbset of resultevidancelist">
                                            <p>{{dbset.name}}</p>
                                            <span>{{dbset.values.length}}</span>
                                        </div>

                                    </div>
        
                                </div>
                            </div>
                        </div>

                        <div class="job_tab">
                            <mat-tab-group>
                                <mat-tab label="Details">
                                    <ng-template matTabContent>
                                        <div class="strip_show mt-2" *ngIf="bussinessdetails">
                                           
                                            <div class="row mr-0 ml-0">
                                                
                                               
                                                <div class="col-sm-12">
                        
                                               
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                        <div class="job_detils_date width30">
                                                            <p class="job_address">Name</p>   
                                                                <h5 class="job_profile">{{ bussinessdetails.firstName}} {{ bussinessdetails.middleName}} {{ bussinessdetails.lastName}}</h5>
                                                               
                                                            
                                                            
                                                        
                                                        </div>
                                                        <div class="job_detils_date width30 text-center mobileleft">
                                                            <p class="job_address">Date of Birth</p>                           
                                                            <p class="job_profile"  *ngFor="let dbdob of bussinessdetails.datesOfBirthIso">
                                                                {{dbdob}}                                                            
                                                            </p>
                                                            <p class="job_profile" *ngIf="!bussinessdetails.datesOfBirthIso"> N/A</p>
                                                        </div>
                                                        <div class="job_detils_date width30 text-center mobileleft">
                                                            <p class="job_address">Nationality</p>                           


                                                            <p class="job_profile"><span class="badge badge-danger"   *ngFor="let dbnat of bindallcountry(bussinessdetails.nationalitiesIsoCodes)">{{dbnat.countryname}}</span></p>


                                                            <p class="job_profile" *ngIf="!bussinessdetails.nationalitiesIsoCodes"> N/A</p>
                                                        </div>
                                                        
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">                                                    
                                                        <div class="job_detils_date width30"  *ngFor="let dbcon of bussinessdetails.contactEntries">
                                                            <p class="job_address">{{dbcon.category}}</p>                           
                                                            <p class="job_profile">{{dbcon.value}} </p>
                                                            <!-- <p class="job_profile" *ngIf="!bussinessdetails.person.telephoneNumber"> N/A</p> -->
                                                        </div>                                                    
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                        <div class="job_detils_date width30">
                                                            <p class="job_address">Address(es)   </p>                           
                                                            <p class="job_profile"  *ngFor="let companyaddress of bussinessdetails.addresses;"><b>{{companyaddress.addressType}} - </b> {{companyaddress.line1}} {{companyaddress.line2}} , {{ companyaddress.city }} {{ companyaddress.county }} {{ companyaddress.countryIsoCode }}- {{ companyaddress.postcode}}</p>
                                                            <p class="job_profile" *ngIf="bussinessdetails.addresses == 0"> N/A</p>
                                                        </div>
<!--                                                         
                                                        <div class="job_detils_date width30 text-center ">
                                                            <p class="job_address">PEP Level   </p>                           
                                                            <p class="job_profile" ><span class="badge badge-success">{{bussinessdetails.person.pepLevel}}</span></p>
                                                            <p class="job_profile" *ngIf="!bussinessdetails.person.pepLevel"> N/A</p>
                                                        </div> -->
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                        <div class="job_detils_date width100">
                                                            <p class="job_address">Alias </p>                           
                                                            <p class="job_profile" >
                                                                <span class="aliesname" *ngFor="let companyaliases of bussinessdetails.aliases;"><b>{{companyaliases.type}} - </b>   {{companyaliases.firstName}} {{companyaliases.middleName}} {{companyaliases.lastName}}</span>
                                                             </p>
                                                            <p class="job_profile" *ngIf="bussinessdetails.aliases == 0">
                                                                <span class="aliesname">N/A</span>
                                                            </p>
                                                        </div>
                                                    
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                        <div class="job_detils_date width100">
                                                            <p class="job_address">Note </p>                           
                                                            <p class="job_profile">
                        
                                                                <span>
                                                                    <div *ngFor="let personnotes of bussinessdetails.notes;">
                                                                <ul class="note">
                                                                    <li *ngIf="personnotes.value">{{ personnotes.value}}</li>
                                                                    
                                                                </ul>
                                                                </div>
                                                                <p class="job_profile" *ngIf="bussinessdetails.notes == 0"> N/A</p>
                                                            </span>
                        
                                                            </p>
                                                        </div>
                                                    
                                                    </div>
                                                    <!-- <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                        <div class="job_detils_date width100">
                                                            <p class="job_address">Political Positions </p>                           
                                                            <div class="job_profile mt-1" >
                                                                <p class="job_profile" *ngIf="bussinessdetails.person.politicalPositions == 0"> N/A</p>
                                                                <ol>
                                                                    <li class="politicalpositions" *ngFor="let politic of bussinessdetails.person.politicalPositions;">{{politic.description}}</li>
                                                                </ol>
                                                               
                                                            </div>
                                                        </div>
                                                    
                                                    </div> -->
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                        <div class="job_detils_date width100">
                                                            <p class="job_address">Reference Id </p>                           
                                                            <p class="job_profile mt-1" >
                        
                                                                <span class="text-success"><b>{{ bussinessdetails.qrCode}}</b></span>
                                                            </p>
                                                        </div>
                                                    
                                                    </div>
                                                 </div>
                                            </div>
                                        </div>
                                     

                                    </ng-template>
                                </mat-tab>


                                <mat-tab label="Associations">
                                    <ng-template matTabContent> 
                                        
                                        <mat-tab-group class="subtab_gg">
                                        <mat-tab label="Relatives & Close Associations ({{bussinessdetails.individualLinks.length}})" >

                                            <div class="job_list_section visa_request_section" *ngFor="let linkbussiness of bussinessdetails.individualLinks">
                                                <div class="job_detils_date width30">
                                                    <div class="visa_user_details ">
                                                        <p class="job_address">Name</p>
                                                        <h4 class="text-danger">{{ linkbussiness.firstName }} {{ linkbussiness.middleName }} {{ linkbussiness.lastName }} ({{ linkbussiness.qrCode }})</h4>
                                                    </div>
                                                </div>
                                                <div class="job_detils_date width40">
                                                    <p class="job_address">Relationship</p>
                                                    <p class="job_profile">{{ linkbussiness.relationship }}</p>
                                                </div>
                                                <div class="job_detils_date width30 flexalign">
                                                    <div class="multicolorg flexwrap">
                                                        <span class="light_orange mb-2" *ngFor="let ddname of linkbussiness.datasets">{{ddname}}</span>
                            
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="job_list_section employee_list_section leave_section"  *ngIf="bussinessdetails.individualLinks.length ==0">
                                                <div class="job_detils_date">
                                                    <p class="job_profile">
                                                        No Record Found
                                                    </p> 
                                                </div>                                                                                       
                                            </div>

                                        </mat-tab>
                                        <mat-tab label="Business Associates ({{ bussinessdetails.businessLinks.length }})">

                                            <ng-container *ngFor="let businesslist of bussinessdetails.businessLinks" >
                                                <div class="job_list_section visa_request_section" *ngFor="let linkbussiness of businesslist.individualLinks">
                                                    <div class="job_detils_date width22">
                                                        <div class="visa_user_details ">
                                                            <p class="job_address">Name</p>
                                                            <h4 class="text-danger">{{ linkbussiness.firstName }} {{ linkbussiness.middleName }} {{ linkbussiness.lastName }} ({{ linkbussiness.qrCode }})</h4>
                                                        </div>
                                                    </div>
                                                    <div class="job_detils_date width50">
                                                        <p class="job_address">Relationship</p>
                                                        <p class="job_profile">{{ linkbussiness.relationship }}</p>
                                                    </div>
                                                </div>

                                            </ng-container>


                                        </mat-tab>
                                        <mat-tab label="Associated Businesses ({{ bussinessdetails.businessLinks.length }})">
                                            <ng-container  >
                                                <div class="job_list_section visa_request_section" *ngFor="let linkbussiness of bussinessdetails.businessLinks">
                                                    <div class="job_detils_date width30">
                                                        <div class="visa_user_details ">
                                                            <p class="job_address">Name</p>
                                                            <h4 class="text-danger">{{ linkbussiness.name }} ({{ linkbussiness.qrCode }})</h4>
                                                        </div>
                                                    </div>
                                                    <div class="job_detils_date width30">
                                                        <p class="job_address">Relationship</p>
                                                        <p class="job_profile">{{ linkbussiness.relationship }}</p>
                                                    </div>

                                                    <div class="job_detils_date width40 flexwrap">
                                                        <!-- <p class="job_address">Data Set</p> -->
                                                        <div class="multicolorg flexalign" >
                                                            <span class="light_orange" *ngFor="let ddname of linkbussiness.datasets">{{ddname}}</span>
                                                        </div>  
                                                        
                                                    </div>


                                                </div>

                                            </ng-container>
                                        </mat-tab>

                                    </mat-tab-group>

                                      

                                        <hr class="line" />


                                    </ng-template>
                                </mat-tab>




                                <mat-tab label="PEP Positions">
                                    <ng-template matTabContent>
                                        <h5 class="mt-2"> {{bussinessdetails.pepEntries.pepTier}}</h5>
                                    <mat-tab-group class="subtab_gg">
                                        <mat-tab label="Current Positions ({{bussinessdetails.pepEntries.current.length}})">
                                            <div class="job_list_section visa_request_section" *ngFor="let linkbussiness of bussinessdetails.pepEntries.current">
                                                <div class="job_detils_date width50">
                                                    <div class="visa_user_details">
                                                        <p class="job_address">Position</p>
                                                        <h4 class="text-danger">{{ linkbussiness.position }}</h4>
                                                    </div>
                                                </div>
                                                <div class="job_detils_date width30">
                                                    <p class="job_address">Country</p>
                                                    <p class="job_profile">
                                                    <ng-container *ngFor="let count of bindallcountry(linkbussiness.countryIsoCode)">
                                                        {{  count.countryname}}
                                                    </ng-container>    
                                                       
                                                    
                                                    </p>
                                                </div>
                                                <div class="job_detils_date width20" >
                                                    <p class="job_address">Date</p>
                                                    <p class="job_profile" *ngIf="linkbussiness.dateFromIso">{{ linkbussiness.dateFromIso }}</p>
                                                    <p class="job_profile" *ngIf="!linkbussiness.dateFromIso">N/A</p>
                                                </div>
                                            </div>
    
                                            <div class="job_list_section employee_list_section leave_section"  *ngIf="bussinessdetails.pepEntries.current.length ==0">
                                                <div class="job_detils_date">
                                                    <p class="job_profile">
                                                        No Record Found
                                                    </p> 
                                                </div>                                                                                       
                                            </div>
                                        </mat-tab>
                                        <mat-tab label="Former Positions ({{bussinessdetails.pepEntries.former.length}})">
                                            <div class="job_list_section visa_request_section " *ngFor="let linkbussiness of bussinessdetails.pepEntries.former">
                                                <div class="job_detils_date width50">
                                                    <div class="visa_user_details ">
                                                        <p class="job_address">Position</p>
                                                        <h4 class="text-danger">{{ linkbussiness.position }}</h4>
                                                    </div>
                                                </div>
                                                <div class="job_detils_date width30">
                                                    <p class="job_address">Country</p>
                                                    <p class="job_profile">
                                                        <ng-container *ngFor="let count of bindallcountry(linkbussiness.countryIsoCode)">
                                                        {{  count.countryname}}
                                                    </ng-container>    </p>
                                                </div>
                                                <div class="job_detils_date width20">
                                                    <p class="job_address">Date</p>
                                                    <p class="job_profile"  *ngIf="linkbussiness.dateFromIso">From {{ linkbussiness.dateFromIso }} <ng-container *ngIf="linkbussiness.dateToIso">To {{ linkbussiness.dateToIso }} </ng-container></p>
                                                    <p class="job_profile"  *ngIf="!linkbussiness.dateFromIso">N/A</p>
                                                </div>
                                            </div>
    
                                            <div class="job_list_section employee_list_section leave_section"  *ngIf="bussinessdetails.pepEntries.former.length ==0">
                                                <div class="job_detils_date">
                                                    <p class="job_profile">
                                                        No Record Found
                                                    </p> 
                                                </div>                                                                                       
                                            </div>

                                        </mat-tab>
                                    </mat-tab-group>

                                        

                                        <hr class="line" />


                                    </ng-template>
                                </mat-tab>



                                <mat-tab label="Rep. Risks">
                                    <ng-template matTabContent>
                                    <mat-tab-group class="subtab_gg">
                                    <mat-tab label="Reputational Risk Exposure ({{ bussinessdetails.rreEntries.length}})">
                                        <div class="job_list_section visa_request_section "  *ngFor="let linkperson of bussinessdetails.rreEntries">
                                            <div class="job_detils_date width30">
                                                <p class="job_address">Category</p>
                                                <p class="ninumber text-danger"><b>{{ linkperson.category }} frw</b></p>
                                            </div>
                                            <div class="job_detils_date width70">
                                                <div class="visa_user_details width100 ">
                                                    <p class="job_address">Sub Category </p>
                                                    <p class="job_profile width70">{{ linkperson.subcategory }}</p>
                                                    <p class="job_profile width70" *ngIf="!linkperson.subcategory">N/A</p>
                                                </div>
                                            </div>

                                            <div class="job_detils_date width100 border_line pb-4 mt-3" *ngFor="let linkpersonevent of linkperson.events">
                                                <div class="hrec_header">
                                                    <div class="visa_user_details width70 ">
                                                        <p class="job_address">Type</p>
                                                        <p class="job_profile width70">{{ linkpersonevent.type }}</p>
                                                    </div>
                                                    <div class="visa_user_details width70 ">
                                                        <p class="job_address">No Of Evidence</p>
                                                        <p class="job_profile width70">{{ linkpersonevent.evidenceIds.length }}</p>
                                                    </div>
                                                </div>
                                              
                                                <div class="job_detils_date width100  spaceoptiud" *ngFor="let linkevdance of  bindselectedevidance(linkpersonevent.evidenceIds)">
                                                    <div class="hrec_header">
                                                        <div class="visa_user_details  ">
                                                          <p class="job_profile ">{{linkevdance.title}} </p>
                                                        </div>
                                                      
                                                    </div>
                                                    <div class="spaceoptiud border_line alignline border_left">
                                                        <div class="job_detils_date width20 mb-3">
                                                            <p class="job_address">Captured</p> 
                                                            <p class="job_profile">{{linkevdance.captureDateIso}} </p>                                                         
                                                        </div>
                                                    
                                                        <!-- <div class="job_detils_date width30 mb-3">
                                                            <p class="job_address">Title</p>
                                                            <p class="job_profile">{{linkevdance.title}}  </p>                                                           
                                                        </div> -->
                                                        <div class="job_detils_date width30 mb-3">
                                                            <p class="job_address">Datasets</p>
                                                            <div class="multicolorg" >
                                                                <span class="light_orange" *ngFor="let dd3 of  linkevdance.datasets">{{dd3}}</span>
                                                            </div>                                                           
                                                                                                          
                                                        </div>
                                                        <div class="job_detils_date width100 mb-3">
                                                            <p class="job_address">Source</p>   
                                                            <p class="job_profile"> {{linkevdance.originalUrl}}</p>                                                           
                                                        </div>
                                                      
                                                        <div class="job_detils_date width100 mb-3">
                                                            <p class="job_address">Summary 
                                                            
                                                                <ng-container *ngIf="linkevdance.keywords">
                                                                    and 
                                                                    <ng-container *ngIf="linkevdance.keywords.split(',')">
                                                                            {{linkevdance.keywords.split(',').length}}
                                                                    </ng-container>  
                                                                    keyword
                                                                </ng-container>
                                                               
                                                            
                                                            </p>
                                                            <p class="job_profile"> {{linkevdance.summary}} </p> 
                                                            <p class="job_profile multicolorg text-uppercase mt-3" *ngIf="linkevdance.keywords">                                                                 
                                                                <span *ngFor="let ss of linkevdance.keywords.split(',')"> <i class="fa fa-tags"></i> {{ss}}  </span>
                                                            </p>                                                     
                                                        </div>
                                                      
                                                    </div>
                                                   
                                                </div>
                                               
                                            </div>
                                        </div>

                                        <div class="job_list_section employee_list_section leave_section"  *ngIf="bussinessdetails.rreEntries.length ==0">
                                            <div class="job_detils_date">
                                                <p class="job_profile">
                                                    No Record Found
                                                </p> 
                                            </div>                                                                                       
                                        </div>
                                        <hr class="line" />

                                    </mat-tab>
                                    </mat-tab-group>

                                    </ng-template>
                                </mat-tab>
                                <mat-tab label="Other Datasets">
                                    <ng-template matTabContent>   
                                    <mat-tab-group class="subtab_gg">
                                    <mat-tab label="Regulatory Enforcement List ({{ bussinessdetails.relEntries.length}})">
                                        <div class="job_list_section visa_request_section "  *ngFor="let linkperson of bussinessdetails.relEntries">
                                            <div class="job_detils_date width30">
                                                <p class="job_address">Category</p>
                                                <p class="ninumber text-danger"><b>{{ linkperson.category }}</b></p>
                                            </div>
                                            <div class="job_detils_date width70">
                                                <div class="visa_user_details width100 ">
                                                    <p class="job_address">Sub Category </p>
                                                    <p class="job_profile width70">{{ linkperson.subcategory }}</p>
                                                </div>
                                            </div>

                                            <div class="job_detils_date width100 border_line pb-4 mt-3" *ngFor="let linkpersonevent of linkperson.events">
                                                <div class="hrec_header">
                                                    <div class="visa_user_details width70 ">
                                                        <p class="job_address">Type</p>
                                                        <p class="job_profile width70">{{ linkpersonevent.type }}</p>
                                                    </div>
                                                    <div class="visa_user_details width70 ">
                                                        <p class="job_address">No Of Evidence</p>
                                                        <p class="job_profile width70">{{ linkpersonevent.evidenceIds.length }}</p>
                                                    </div>
                                                </div>                                              

                                                <div class="job_detils_date width100 border_line spaceoptiud" *ngFor="let linkevdance of  bindselectedevidance(linkpersonevent.evidenceIds)">
                                                    <div class="hrec_header">
                                                        <div class="visa_user_details  ">
                                                          <p class="job_profile ">{{linkevdance.title}}</p>
                                                        </div>                                                      
                                                    </div>
                                                    <div class="job_detils_date width30">
                                                        <p class="job_address">Captured</p>
                                                        <p class="job_profile">{{linkevdance.captureDateIso}} </p>                                                    
                                                    </div>
                                                
                                                    <!-- <div class="job_detils_date width30">
                                                        <p class="job_address">Title</p> 
                                                        <p class="job_profile">{{linkevdance.title}}  </p>                                                                    
                                                    </div> -->
                                                   
                                                    <div class="job_detils_date width30">
                                                        <p class="job_address">Source</p>
                                                        <p class="job_profile"> {{linkevdance.originalUrl}}</p>                                                          
                                                    </div>
                                                  
                                                    <div class="job_detils_date width30">
                                                        <p class="job_address">Datasets</p> 
                                                        <div class="multicolorg" >
                                                            <span class="light_orange" *ngFor="let dd3 of  linkevdance.datasets">{{dd3}}</span>
                                                        </div>                                                                                                                
                                                    </div>
                                                   
                                                    <div class="job_detils_date width30">
                                                        <p class="job_address">Summary 
                                                            
                                                            <ng-container *ngIf="linkevdance.keywords">
                                                                and 
                                                                <ng-container *ngIf="linkevdance.keywords.split(',')">
                                                                        {{linkevdance.keywords.split(',').length}}
                                                                </ng-container>  
                                                                keyword
                                                            </ng-container>
                                                           
                                                        
                                                        </p>
                                                        <p class="job_profile"> {{linkevdance.summary}} </p> 
                                                        <p class="job_profile multicolorg text-uppercase mt-3" *ngIf="linkevdance.keywords">                                                                 
                                                            <span *ngFor="let ss of linkevdance.keywords.split(',')"> <i class="fa fa-tags"></i> {{ss}}  </span>
                                                        </p>  
                                                
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                        </div>

                                        <div class="job_list_section employee_list_section leave_section"  *ngIf="bussinessdetails.relEntries.length ==0">
                                            <div class="job_detils_date">
                                                <p class="job_profile">
                                                    No Record Found
                                                </p> 
                                            </div>                                                                                       
                                        </div>
                                        <hr class="line" />

                                        </mat-tab>
                                    </mat-tab-group>
                                        <hr class="line" />


                                    </ng-template>
                                </mat-tab>
                                <mat-tab label="Evidence">
                                    <ng-template matTabContent>
                                    <mat-tab-group class="subtab_gg">
                                    <mat-tab label="All ({{ bussinessdetails.evidences.length}})">
                                        <ul class="accordion evidancegg">
                       
                                            <li class="accordion-item" *ngFor="let linkevdance of bussinessdetails.evidences">
                                                <div class="hrec_header">
                                                    <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>{{linkevdance.evidenceId}} : {{linkevdance.title}}  </a>
                                                </div>
                                           
                                                <div class="job_list_section visa_request_section border_line alignline spaceoptiud nolineleft">
                                                    <div class="job_detils_date width20 mb-3">
                                                        <p class="job_address">Captured</p>
                                                        <p class="job_profile">{{linkevdance.captureDateIso}} </p>                                                     
                                                    </div>
                                                   
                                                    <div class="job_detils_date width30 mb-3">
                                                        <p class="job_address">Title</p>  
                                                        <p class="job_profile">{{linkevdance.title}}  </p>
                                                        <p class="job_profile" *ngIf="linkevdance.title">N/A</p>                                                       
                                                    </div>                                                 
                                                   
                                                  
                                                    <div class="job_detils_date width30 mb-3">
                                                        <p class="job_address">Datasets</p>  
                                                        <div class="multicolorg">   
                                                            <span class="light_green"  *ngFor="let dd3 of  linkevdance.datasets"> {{dd3}}</span>                                                            
                                                        </div>                                                                                                           
                                                    </div>



                                                    <div class="job_detils_date width40 mb-3">
                                                        <p class="job_address">Source</p> 
                                                        <p class="job_profile"> {{linkevdance.originalUrl}}</p>                                                          
                                                    </div>

                                                    <div class="job_detils_date width40 mb-3" *ngIf="linkevdance.assetUrl">
                                                        <p class="job_address">Asset URL</p> 
                                                        <p class="job_profile"><a href="{{linkevdance.assetUrl}}" target="_blank">View Document</a> </p>                                                          
                                                    </div>


                                                    <div class="job_detils_date width100 mb-3">
                                                        <p class="job_address">Summary 
                                                            
                                                            <ng-container *ngIf="linkevdance.keywords">
                                                                and 
                                                                <ng-container *ngIf="linkevdance.keywords.split(',')">
                                                                        {{linkevdance.keywords.split(',').length}}
                                                                </ng-container>  
                                                                keyword
                                                            </ng-container>
                                                           
                                                        
                                                        </p>
                                                        <p class="job_profile"> {{linkevdance.summary}} </p> 
                                                        <p class="job_profile multicolorg text-uppercase mt-3" *ngIf="linkevdance.keywords">                                                                 
                                                            <span *ngFor="let ss of linkevdance.keywords.split(',')"> <i class="fa fa-tags"></i> {{ss}}  </span>
                                                        </p> 
                                                 
                                                    </div>
                                                  
                                                </div>
                                            </li>

                                        </ul>
                                        </mat-tab>




                                        <mat-tab *ngFor="let listev of resultevidancelist" label="{{listev.name}} ({{ listev.values.length}})" >
                                            <ul class="accordion evidancegg">
                           
                                                <li class="accordion-item " *ngFor="let linkevdance of listev.values">
                                                   
                                                    <div class="hrec_header">
                                                        <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>{{linkevdance.evidenceId}} : {{linkevdance.title}}  </a>
                                                    </div>
                                                    <div class="job_list_section visa_request_section border_line alignline spaceoptiud nolineleft">
                                                        <div class="job_detils_date  width20 mb-3">
                                                            <p class="job_address">Captured</p>
                                                            <p class="job_profile">{{linkevdance.captureDateIso}} </p>                                                     
                                                        </div>
                                                      
                                                        <div class="job_detils_date width30 mb-3">
                                                            <p class="job_address">Title</p> 
                                                            <p class="job_profile">{{linkevdance.title}}  </p>                                                           
                                                        </div>                                                       
                                                      
                                                        
                                                        <div class="job_detils_date width30 mb-3">
                                                            <p class="job_address">Datasets</p>
                                                            <div class="multicolorg" >
                                                                <span class="light_orange" *ngFor="let dd3 of  linkevdance.datasets">{{dd3}}</span>
                                                            </div>                                                       
                                                        </div>

                                                        <div class="job_detils_date width40 mb-3">
                                                            <p class="job_address">Source</p>   
                                                            <p class="job_profile"> {{linkevdance.originalUrl}}</p>                                                          
                                                        </div>
                                                        <div class="job_detils_date width100 mb-3">
                                                            <p class="job_address">Summary 
                                                            
                                                                <ng-container *ngIf="linkevdance.keywords">
                                                                    and 
                                                                    <ng-container *ngIf="linkevdance.keywords.split(',')">
                                                                            {{linkevdance.keywords.split(',').length}}
                                                                    </ng-container>  
                                                                    keyword
                                                                </ng-container>
                                                               
                                                            
                                                            </p>
                                                            <p class="job_profile"> {{linkevdance.summary}} </p> 
                                                            <p class="job_profile multicolorg text-uppercase mt-3" *ngIf="linkevdance.keywords">                                                                 
                                                                <span *ngFor="let ss of linkevdance.keywords.split(',')"> <i class="fa fa-tags"></i> {{ss}}  </span>
                                                            </p>                                                   
                                                        </div>
                                                        
                                                    </div>
                                                </li>
    
                                            </ul>
                                            </mat-tab>

                                        </mat-tab-group>


                                        <hr class="line" />


                                    </ng-template>
                                </mat-tab>

                                <ng-container *ngIf="directors">
                                <mat-tab label="Director - UK">


                                    <div class="job_list_section visa_request_section" *ngFor="let director of directors let i=index">
                                        <div class="job_detils_date width100">
                                            <p  >                                     
                                                {{director.title}} , 
                                                Total number of appointments {{director.appointment_count}} - 
                                                <ng-container>
                                                     Born {{(director.date_of_birth)?(director.date_of_birth.month+'/01/'+director.date_of_birth.year | date: 'MMM yyyy')+' , ':''}}, 
                                                </ng-container>
                                                
                                                
                                                {{director.address_snippet}}
                                                
                                            </p>
                                            
                            
                                                
                                        </div>
                                      
                                    </div>

                                    <!-- <div class="job_list_section employee_list_section leave_section"  *ngIf="directors.length ==0">
                                        <div class="job_detils_date">
                                            <p class="job_profile">
                                                No Record Found
                                            </p> 
                                        </div>                                                                                       
                                    </div> -->


                                   
                                </mat-tab>
                            </ng-container>

                                <ng-container *ngIf="opendirector">
                                <mat-tab label="Director - Global">


                                    <!-- *ngIf="searchcondition.countries.includes(director.officer.jurisdiction_code.toUpperCase())" -->

<ng-container *ngFor="let director of opendirector let i=index">

    <div class="job_list_section visa_request_section" >
        <div class="job_detils_date width100">
            <p>                                     
               <b> {{director.officer.name}} </b>, <b> Jurisdiction Code - </b> {{director.officer.jurisdiction_code}}, 
                
                
               <b> Position - </b> {{director.officer.position}}, 


                <ng-container *ngIf="director.officer.retrieved_at">
                    <b> Retrieved - </b>     {{director.officer.retrieved_at | date: 'dd MMM yyyy'}}, 
                </ng-container>
                <ng-container *ngIf="director.officer.start_date">
                    <b> Start Date - </b>{{director.officer.start_date | date: 'dd MMM yyyy'}}, 
                </ng-container>
                <ng-container *ngIf="director.officer.end_date">
                   <b> End Date - </b> {{director.officer.end_date | date: 'dd MMM yyyy'}}, 
                </ng-container>

                <ng-container *ngIf="director.officer.date_of_birth">
                  <b> Date Of Birth - </b>  {{director.officer.date_of_birth | date: 'dd MMM yyyy'}},
                </ng-container>

                <ng-container *ngIf="director.officer.occupation">
                <b> Occupation - </b>    {{director.officer.occupation  }},
                </ng-container>
                <ng-container *ngIf="director.officer.current_status">
                    <b> Current Status - </b>  {{director.officer.current_status  }},
                </ng-container>
                <ng-container *ngIf="director.officer.inactive">
                    <b> Inactive - </b> {{director.officer.inactive  }},
                </ng-container>
                <ng-container *ngIf="director.officer.address">
                    <b> Address - </b> {{director.officer.address  }},
                </ng-container>
                <ng-container *ngIf="director.officer.nationality">
                    <b> Nationality - </b> {{director.officer.nationality  }}, 
                </ng-container>
            </p>
            
            
            <p>
                <i>Company Information - </i>
                <ng-container *ngIf="director.officer.company">
                <b>    Company Name - </b> {{director.officer.company.name  }} , <b>Jurisdiction Code -</b> {{director.officer.company.jurisdiction_code  }} - <b>Company Number -</b> {{director.officer.company.company_number  }}
                </ng-container>


              
                
            </p>
            

                
        </div>
      
    </div>
</ng-container>

                                   


                                   
                                </mat-tab>
                            </ng-container>



                            </mat-tab-group>
                        </div>

                       
                    </div>
                </div>


                <div class="tabs__content" *ngIf="noresult">

                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row row-sm topheader">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Personal Details </h5>
                                </div>

                                <div class="col-lg-6">
                                    <a type="button" (click)="norecordfound()" style="color: #fff;" [innerHtml]="downloadtext" target="_blank" class="btn btn-primary com_btn float-right" >No Record Found <i class="fa fa-Plus ml-2"></i></a>
                                    <!-- <a type="button" href="{{ url }}" *ngIf="url"   target="_blank" class="btn btn-primary com_btn float-right" >Download <i class="fa fa-file-pdf-o ml-2"></i></a> -->
                                 </div>

                            </div>

                <div class="row">
                    <div class="col-lg-12" *ngIf="searchcondition">
                        <div class="border-box float-left width100">
                            <div class="overview_details emp width100">
                                <div class="profile_dtailsshow visastaus mb-3">
                                   <h5></h5>
                                   
                                </div>
                                <div class="profile_dtailsshow"> 
                                    <div class="detailsshow width50 pr-3">
                                        <span>Full Name</span>
                                        <p *ngIf="searchcondition.name">{{searchcondition.name}}</p>
                                        <p *ngIf="!searchcondition.name">NA</p>
                                    </div>                                     
                                    <div class="detailsshow width50 pr-3" *ngIf="searchcondition.DateOfBirth">
                                        <span>Date of Birth</span>
                                        <p>{{searchcondition.DateOfBirth | date: 'dd MMM yyyy' }}</p>
                                    </div>
                                </div>
                                <div class="profile_dtailsshow"> 
                                    <div class="detailsshow width25 pr-3"  *ngIf="searchcondition.DateOfBirth">
                                        <span>Year of Birth</span>
                                        <p *ngIf="searchcondition.DateOfBirth">{{searchcondition.DateOfBirth | date: 'yyyy' }}</p>
                                        <p *ngIf="!searchcondition.DateOfBirth">NA</p>                                        
                                    </div> 
                                    <div class="detailsshow width25 pr-3">
                                        <span>Matching Threshold Percentage</span>
                                        <p *ngIf="searchcondition.threshold">{{searchcondition.threshold}}</p>
                                        <p *ngIf="!searchcondition.threshold">NA</p>
                                    </div>
                                </div>

                                <div class="profile_dtailsshow"> 
                                    <div class="detailsshow widt50 pr-3"  *ngIf="searchcondition.countries">
                                        <span>Country</span>
                                        <p class="multicolorg">
                                            <span *ngFor="let dbset of bindallcountry(searchcondition.countries)">
                                                {{dbset.countryname }}
                                            </span>
                                        </p>                                      
                                    </div> 

                                    <div class="detailsshow width50 pr-3"  *ngIf="searchcondition.datasets">
                                        <span>Country</span>
                                        <p  class="multicolorg">
                                            <span *ngFor="let dbset of searchcondition.datasets">
                                                {{dbset}}
                                            </span>                                        
                                        </p>
                                        
                                    </div> 

                                    
                                </div>

                              
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="credit_data_show">
                    <div class="job_list_section visa_request_section" >
                        <div class="job_detils_date">
                            <p class="job_profile">No Record Found</p>
                        </div>
                    </div> 
                </div>


            </div>
        </div>
    </div>











            </div>
        </div>
    </div>
</div>



<jw-modal id="pdf-image-viewer-complete-document" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-complete-document');">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-12">

                            <iframe *ngIf="imgvisasrc == false" [src]="iframevisasrc" width="100%" height="600" frameborder="0"></iframe>
                            <img *ngIf="imgvisasrc == true" [src]="iframevisasrc" width="100%" />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</jw-modal>