import { Component, OnInit, Input, Output,EventEmitter,SimpleChanges } from '@angular/core';
import { CountryService } from '../../../services/country.service';
import { MyvisaService } from '../../../services/myvisa.service';
import { ModalService } from '../../../_services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Visa } from '../../../models/visa/visa.model';
import { UploadFileService } from './../../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertService } from '../../../services/alert.service';
import { AicenterService } from '../../../services/aicenter.service';


@Component({
  selector: 'app-visarequest',
  templateUrl: './visarequest.component.html',
  styleUrls: ['./visarequest.component.css']
})
 
 
export class VisarequestComponent implements OnInit {

  @Input() items: Array<any>;
  searchText:any;
  pageOfItems: Array<any>;
  record: any;
  visalist: any[];
  visaDetail: any;
  countrylist: any;
  Userid: any;
  listcheck: any; 
  datalist:any;
  selectedFiles: FileList;
  iframesrc: any;
  imgsrc: any;
  buttoninsert: any;
  filesizeerror: any;
  fileformaterror: any;
  file: any;
  filename: any;
  data: any;
  disableBtn: any;
  filebackrequirederror: any;
  aipayment: boolean =false;
  filerequirederror: any;
  iframevisabacksrc: any;
  imgvisabacksrc: any;
  filereuirederror: any;
  filebacksizeerror: any;
  filebackformaterror: any;
  selectedbackFiles: FileList;
  filebackname: any;

  filterItems = [
    {
      name: 'All Visa Details',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Waiting for review',
      id: 1,
      selected: false
    }
  ]
  submitted: any;
  visapopForm: FormGroup;
  visa: Visa;
  permission: any;
  requestperm: any;
  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private visaService: MyvisaService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private aicenter:AicenterService,
    private router: Router,
    private alerts: AlertService
    ) { }
  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;

    this.visapopForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      visanumber: ['', Validators.required],
      visatype: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      visaexpiry: ['', Validators.required],
      visastart: ['', Validators.required],
      iscurrent: [''],
      stype: ['']
    });
    this.filename = 'Choose file'; this.filebackname = "Choose file";  
    this.visaDetail = new Visa();
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'cstatus': [1] };



    this.aicenter.getcountpayment().subscribe((data: any) => {     
      this.aipayment = data.payment;
    });


    this.visaService.getallvisaListbyadmin(data).subscribe((data: any) => {     

      this.record  = data.length;
     this.datalist = data;
      if(this.record < 30){
        this.pageOfItems = data;
      }
      else{
           this.items =data;
      }
    });

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.listcheck = this.filterItems;
  }


  onDelete(id: number) {
    const data1 = { 'id': id };
    if (confirm("Are you sure you want to delete?")) {
      this.visaService.deletevisa(data1).subscribe((data: any) => {
        var sstatus: any;
        for (let c of this.filterItems) {
          if (c.selected == true) {
            if (c.id == 0) {
              sstatus = [1, 2, 3];
            }
            else {
              sstatus = [c.id];
            }
          }
        }
        const data2 = { 'id': this.Userid, 'cstatus': sstatus };
        this.visaService.getallvisaListbyadmin(data2).subscribe((data: any) => {
          this.record  = data.length;
          this.datalist = data;
           if(this.record < 30){
             this.pageOfItems = data;
           }
           else{
                this.items =data;
           }
        });
        this.alerts.success('Visa deleted successfully.', true);

      });
    }
  }

  onlistchange(type1: any, idval: number) {
    var sstatus: any;
    for (let c of this.filterItems) {
      if (c.id == idval)
        c.selected = true;
      else
        c.selected = false;
    }
    if (idval == 0 || type1 == false) {
      sstatus = [1, 2, 3];
    }
    else {
      sstatus = [idval];
    }
    var userid = localStorage.getItem('userid');
    const data = { 'id': userid, 'cstatus': sstatus };
    this.visaService.getallvisaListbyadmin(data).subscribe((data: any) => {
      this.data = data;
    });
  }

  get f() {
    return this.visapopForm.controls;
  }
  

  onSubmit(value3: any, id: string, stype: string) {
    this.submitted = true;
    if (this.visapopForm.invalid) {
      if(!this.selectedFiles)
        this.filerequirederror = true;
      // if(!this.selectedbackFiles)
      //   this.filebackrequirederror = true;
      return;
    }
    this.disableBtn = false;
    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
       else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.fileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.imagename = imagepath;
    }
    else {
      // if(value3.id == '0')
      // {
      //   this.filerequirederror = true;
      //   return;
      // }
      if(value3.id == '0')
      {
        value3.imagebackname = '';
       }
     else {
      value3.imagename = this.visaDetail.visadocname;
     }
     
    }

    if (this.selectedbackFiles) {
      this.file = this.selectedbackFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filebacksizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.filebackformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.imagebackname = imagepath;
    }
    else {
      if(value3.id == '0')
      {
        this.filebackrequirederror = true;
        return;
      }
      value3.imagebackname = this.visaDetail.visabackdocname;
    }

    value3.stype = stype;
    value3.createby = this.Userid;
    
    this.visaService.myvisadetailsUpdatebyadmin(value3).subscribe((data: any) => {
      var userid = localStorage.getItem('userid');
      const data1 = { 'id': userid, 'cstatus': [1] };
      this.visaService.getallvisaListbyadmin(data1).subscribe((datavisa: any) => {
        this.record  = datavisa.length;
        this.datalist = datavisa;
        if(this.record < 30){
          this.pageOfItems = datavisa;
        }
        else{
             this.items =datavisa;
        }
      });
    });
    this.filename = "Choose file"; this.filebackname = "Choose file";  
    this.alerts.success('Visa Details Updated Successfully.', true);    
    // this.visaDetail = new Visa();
    this.modalService.close(id);
  }


  selectFile(event)
   {
   var files = event.target.files;
    this.filename = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.fileformaterror = true;  this.filesizeerror = false;      
    }else{
      this.filesizeerror = false; 
      this.filereuirederror =false; 
      this.fileformaterror = false;
      this.selectedFiles = files[0];
    }
  }

  selectbackFile(event)
   {
    this.filebackrequirederror =false; 
    this.filebacksizeerror = false;  
    this.filebackformaterror = false;
    var files = event.target.files;
    this.filebackname = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filebacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filebackformaterror = true;    this.filebacksizeerror = false; 
    }else{this.filebacksizeerror = false;  
      this.filebackformaterror = false;
      this.selectedbackFiles = files[0];
    }
  }


  formsubmit()
  {
    // document.getElementById('visa-form-submit').submit();
  }

  openModal2(id: string) 
  {
    this.submitted = false;
    this.disableBtn = true;
    this.buttoninsert = "Submit";
    this.visaDetail = new Visa();    
    this.Userid = localStorage.getItem('userid');
    this.visaDetail.employeeid = this.Userid;
    this.visaDetail.employeevisadeatilid = "0";  
    this.visaDetail.iscurrent = "0";  
    // this.visaDetail.visaexpiry ='';
    // this.visaDetail.visastart = '';
    // this.visaDetail.visatype = '';
    // this.visaDetail.nationalityid = '';
    this.filename = "Choose file";    
    this.filebackname = "Choose file";  
    this.modalService.open(id);
  }



  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModal3(id: string, data: any, backimg: any ) 
  {
    
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    if(backimg)
    {
      var fullpathArray = backimg.split('.');
      var typeoffile = fullpathArray[fullpathArray.length - 1];
      if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
        this.imgvisabacksrc = true;
        this.iframevisabacksrc = backimg;
      }
      else { 
        this.imgvisabacksrc = false;
        this.iframevisabacksrc = this.getSafeUrl("https://docs.google.com/gview?url=" + backimg + "&embedded=true");
      }
    }
    else {
      this.imgvisabacksrc = false;
      this.iframevisabacksrc = '';
    }
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.visa = new Visa();
    this.disableBtn = true;
    this.visaDetail = new Visa();
    this.visaDetail = data;

   // console.log(data);
    this.buttoninsert = "Update";
    this.modalService.open(id);
    if(data.iscurrent){
      this.visaDetail.iscurrent = "1";
    } else{
      this.visaDetail.iscurrent = "0";
    }
  }

  
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  closeModal(id: string) 
  {const data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    this.visaService.getallvisaListbyadmin(data).subscribe((data: any) => {     

      this.record  = data.length;

      if(this.record < 30){
        this.pageOfItems = data;
      }
      else{
           this.items =data;
      }
      this.modalService.close(id);
      // this.data = data;
      // this.record = data.length;
    });
    
    
    
  }

}
