import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employeeaiverification',
  templateUrl: './employeeaiverification.component.html',
  styleUrls: ['./employeeaiverification.component.css']
})
export class EmployeeaiverificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
