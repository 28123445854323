import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Employee } from '../../models/employee.model';
import { CountryService } from '../../services/country.service';
import { EmployeeService } from '../../services/employee.service';
import { EmployeementService } from '../../services/employeement.service';
import { JobService } from '../../services/job.service';
import { ProjectService } from '../../services/project.service';
import { ClientService } from '../../services/client.service';
import { ExcelService } from '../../services/excel.service';

@Component({
  selector: 'app-employmentreport',
  templateUrl: './employmentreport.component.html',
  styleUrls: ['./employmentreport.component.css']
})

export class EmploymentreportComponent implements OnInit {
  EmployeeForm: FormGroup;
  employeeDetail = new Employee();
  employeelist: any;
  countrylist: any;
  coslist: any;
  data: any;
  joblist: any;
  projectlist: any;
  clientlist: any;
  exceldata = [];
  employmentdata: any;
  Userid: any;
  constructor(private excelService: ExcelService, private formBuilder: FormBuilder, private project: ProjectService, private client: ClientService, private country: CountryService, private jobser: JobService, private employee: EmployeeService, private employeementreport: EmployeementService) { }

  ngOnInit() {
    this.EmployeeForm = this.formBuilder.group({
      emailid: [''],
      phonenumber: [''],
      ninumber: [''],
      minstartdate: [''],
      maxstartdate: [''],
      minenddate: [''],
      maxenddate: [''],
      clientid: [''],
      minsalary: [''],
      maxsalary: [''],
      projectid: [''],
      fname: [''],
      iscurrent: ['']
    });
    var values3 = {};
    this.employeementreport.getemployementlist(values3).subscribe((employementetails: any) => {
      this.data = employementetails;
    });

    this.Userid = localStorage.getItem('userid');
    const sstatustype = { 'status': true, 'id': this.Userid }

    this.project.getprojectlist(sstatustype).subscribe((projectdetails: any) => {
      this.projectlist = projectdetails;
    });

    this.client.getclientlist(sstatustype).subscribe((clientdetails: any) => {
      this.clientlist = clientdetails;
    });
  }

  exportAsXLSX(): void {
    this.exceldata = [];
    this.data.forEach(obj => {
      if ((obj.wemployeeid != '0') && (obj.wemployeeid != '')) {
        var linemanager = obj.wemployee.fname + " " + obj.wemployee.mname + " " + obj.wemployee.lname;
      }
      else {
        var linemanager = "";
      }

      if ((obj.clientid != '0') && (obj.clientid != '')) {
        var name = obj.client.clientname;
      }
      else {
        name = "";
      }

      if ((obj.projectid != '0') && (obj.projectid != '')) {
        var project = obj.project.projectname;
      }
      else {
        project = "";
      }

      this.employmentdata = {
        "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
        "Line Manager": linemanager,
        "Client": name,
        "Project": project,
        "Salary": obj.salary,
        "Hour of Work": obj.hoursofworks,
        "Employee Role": obj.emprole,
        "Start Date ": obj.startdate,
        "End Date ": obj.enddate
      };
      this.exceldata.push(this.employmentdata);
    });
    this.excelService.exportAsExcelFile(this.exceldata, 'Employment_Report');
  }

  onSubmit(value3: any) {
    this.employeementreport.getemployementlist(value3).subscribe((employementetails: any) => {
      this.data = employementetails;
    });
  }
}