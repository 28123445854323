import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TerminationreasonService } from '../../../services/terminationreason.service';
import { TerminationReason } from '../../../models/setting/terminationreason.model';
import { ModalService } from '../../../_services/modal.service';
import { AppGlobals } from '../../../app.global';

@Component({
  selector: 'app-termination-reason',
  templateUrl: './termination-reason.component.html',
  styleUrls: ['./termination-reason.component.css']
})
export class TerminationReasonComponent implements OnInit {

  terminationReasonForm: FormGroup;
  data: any;
  public searchText: string;
  record:number;
  items: Array<any>;
  pageOfItems: Array<any>;
  trDetail: any;
  userid: any;
  year: any;
  yearList: any;
  submitted: any = false;
  empperm:any;
  parentCategoryList: any;
  selectOptions: any = [];

  constructor(
    private tReasonService: TerminationreasonService, 
    private formBuilder: FormBuilder, 
    private modalService: ModalService, 
  ) { }

  ngOnInit() {
    this.yearList = AppGlobals.YEAR_VALUES;
    this.year = new Date().getFullYear();
    this.userid =localStorage.getItem('userid');  
    this.terminationReasonForm = this.formBuilder.group({
      id: [''],
      title: ['', Validators.required],
      parentid: ['']
    });
    this.getParentCategories();
    // this.createTerminationReasonFormGroup();
    this.getTerminationReasonList();
    this.trDetail = new TerminationReason();
  }

  get g() { return this.terminationReasonForm.controls; }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  getParentCategories()
  {
    var value = {parentid: 0};
    this.tReasonService.getParentTerminationReasonList(value).subscribe((parentterminationreasons: any) => {
      this.parentCategoryList = parentterminationreasons;
    });
  }

  getTerminationReasonList()
  {
    this.tReasonService.getterminationreasonlist().subscribe((terminationdata: any) => {
      
      this.data = terminationdata;
      this.record = this.data.length;

      if(this.record < 30){
        this.pageOfItems = this.data;
      }
      else{
           this.items =this.data;
      }     
    });
  }

  onSubmitTerminationReason(terminationReasonData, modelId)
  {
    this.submitted = true;
    if (this.terminationReasonForm.invalid) {
      return;
    }

    var postData = {
      'id': terminationReasonData.id,
      'parentid': terminationReasonData.parentid,
      'title': terminationReasonData.title
    };

    this.tReasonService.updateTerminationReason(postData).subscribe((resp: any) => {
      if(resp.success)
      {
        this.getTerminationReasonList();
        this.modalService.close(modelId);
      }else{

      }
    });
  }

  addTerminationReason()
  {
    this.selectOptions = [];
    this.parentCategoryList.forEach(element => {
      this.selectOptions.push(element);
    });
    this.trDetail = new TerminationReason();
    this.trDetail.parentid = '0';
    this.trDetail.id = '0';
    this.trDetail.title = '';
    this.terminationReasonForm.reset(this.trDetail);
    this.modalService.open('termination-reason-model');
  }

  editTerminationReason(tr: any)
  {
    this.selectOptions = [];
    this.parentCategoryList.forEach(element => {
      if(tr.id != element.id)
      {
        this.selectOptions.push(element);
      }
    });
    
    this.trDetail = new TerminationReason();
    this.trDetail.parentid = tr.parentid;
    this.trDetail.id = tr.id;
    this.trDetail.title = tr.title;
    this.terminationReasonForm.reset(this.trDetail);
    this.modalService.open('termination-reason-model');
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  yearChange(year)
  {
    this.year  = year;
    this.getTerminationReasonList();
  }

}
