"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../_services/modal.service");
var asset_service_1 = require("../../../services/asset.service");
var forms_1 = require("@angular/forms");
var asset_model_1 = require("../../../models/asset/asset.model");
var router_1 = require("@angular/router");
var SettingassetcategoryComponent = /** @class */ (function () {
    function SettingassetcategoryComponent(modalService, asset, router, formBuilder) {
        this.modalService = modalService;
        this.asset = asset;
        this.router = router;
        this.formBuilder = formBuilder;
        this.assetDetail = new asset_model_1.Asset();
        this.assetcateDetail = new asset_model_1.Assetcategory();
        this.options = [];
        this.submitted = false;
    }
    SettingassetcategoryComponent.prototype.ngOnInit = function () {
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.settingperm = this.permission.m81;
        this.Userid = localStorage.getItem('userid');
        this.assetDetail = new asset_model_1.Asset();
        this.assetformGroup = this.formBuilder.group({
            id: [''],
            categoryname: ['', forms_1.Validators.required]
        });
        this.AllData();
    };
    SettingassetcategoryComponent.prototype.AllData = function () {
        var _this = this;
        var sstatustype = { 'status': false, 'id': this.Userid };
        this.asset.getassetcategory(sstatustype).subscribe(function (data) {
            _this.data = data;
            _this.record = _this.data.length;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    Object.defineProperty(SettingassetcategoryComponent.prototype, "f", {
        get: function () { return this.assetformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    SettingassetcategoryComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    SettingassetcategoryComponent.prototype.onSubmit = function (formassetcategory, id) {
        var _this = this;
        this.submitted = true;
        // var formassetcategory = assetcategorydata.value;
        // console.log(this.assetformGroup);
        if (this.assetformGroup.invalid) {
            return;
        }
        this.disableBtn = false;
        formassetcategory.userid = this.Userid;
        this.asset.settingassetcategoryUpdate(formassetcategory).subscribe(function (response) {
            _this.AllData();
            _this.modalService.close(id);
        });
    };
    SettingassetcategoryComponent.prototype.onChangeStatus = function (evt, asset) {
        var _this = this;
        var cstatus;
        if (evt.target.checked) {
            cstatus = '1';
        }
        else if (!evt.target.checked) {
            cstatus = '0';
        }
        this.asset.assetUpdateChangeStatus({ 'userid': this.Userid, 'id': asset.id, 'cstatus': evt.target.checked }).subscribe(function (data) {
            _this.AllData();
        });
    };
    SettingassetcategoryComponent.prototype.openModal = function (id, data) {
        this.buttoninsert = "Update";
        this.disableBtn = true;
        this.assetDetail = data;
        this.assetformGroup.reset(this.assetDetail);
        this.modalService.open(id);
    };
    SettingassetcategoryComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.assetDetail = new asset_model_1.Asset();
        this.assetDetail.id = "0";
        this.assetformGroup.reset(this.assetDetail);
        this.modalService.open(id);
    };
    SettingassetcategoryComponent.prototype.closeModal = function (id) {
        this.AllData();
        this.modalService.close(id);
    };
    return SettingassetcategoryComponent;
}());
exports.SettingassetcategoryComponent = SettingassetcategoryComponent;
