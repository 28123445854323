<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__sontent">
                    <app-invioce-shared></app-invioce-shared>
                    <div class="clearfix"></div>

                    <ng-container *ngIf="!invoicelist"> 
                    <div class="job_list_section visa_request_section" *ngIf=" record <=0">
                        <div class="job_detils_date">
                            <p class="job_profile">No Record Found</p>
                        </div>
                    </div></ng-container>

                    <div class="invoice_content" *ngIf="invoicelist">
                        <div class="header">
                            <!-- <h1 class="headprofile mt-0">Subscription Invoices</h1> -->
                            <h1 class="headprofile mt-0">Invoices</h1>
                         </div>
                        <div class="row" >
                            <div class="col-sm-12 project_list">
                            <!-- fareed -->
                            <div class="card mt-4 mb-4 ">
                                <div class="card-body p-0">
                                    <div class="inheader">
                                        <div class="invoice_inbox">
                                            <div class="inbox_heading">
                                                <!-- <h2>Subscription Invoices</h2> -->
                                                <h2>Invoices</h2>
                                            </div>
                                        </div>
                                        <div class="invoice_bill_section" *ngIf="invoiceDetails">
                                            <div class="inbox_bill_heading">
                                                <h2>{{ invoiceDetails.number }}</h2>
                                                <div class="group_btn">
                                                    <button type="button" class="btn btn-primary" (click)="downloadInvoice(invoiceDetails.id)" [innerHtml]="downloadinvoicetext"></button>
                                                
                                                </div>                                   
                                            </div>
                                        </div>
                                    </div>
                                    <div class="invoice_group">                            
                                        <div class="invoice_inbox with30">
                                        
                                            <div class="invoice_mail" *ngFor="let invoice of invoicelist; let i=index">
                                                <div class="check">
                                                    <input type="radio" name="invoicedata" class="form-control" [checked]="invoiceDetails.id && invoiceDetails.id==invoice.id" (click)="getInvoiceData(invoice.id)">
                                                </div>
                                                <div class="content_mail">
                                                    <div class="details">
                                                        <p class="person_name">Invoice</p>
                                                        <span class="invoiceid">{{ invoice.number}}</span> <span class="center_line">|</span> <span class="invoice_date">{{ timestamp(invoice.date) | date: 'dd MMM yyyy' }}</span>
                                                    </div>
                                                    <div class="price_mail">
                                                        <p class="invoice_price">£{{invoice.amount_due*.01 | number : '1.2-2'}}</p>
                                                        <span class="sent">{{invoice.status}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="invoice_bill_section width70" *ngIf="invoiceDetails">
                                            
                                            <div class="invoice_doc">
                                                <div class="invoice_box_page" id="contentToConvert">
                                                    <div class="status_sent">
                                                        <span>STATUS - {{invoiceDetails.status.toUpperCase()}}</span>
                                                    </div>
                                                    <div class="row tteep ">
                                                        <div class="col-md-12 alignivo">                                    
                                                            <div class="invoice_content_show" *ngIf="invoiceDetails">
                                                                <h4>Invoice : {{ invoiceDetails.number }}</h4>
                                                                <div class="content">
                                                                    <span>Invoice/Credit memo</span>
                                                                    <p>{{invoiceDetails.object}}</p>
                                                                </div>
                                                                <!-- <div class="content">
                                                                    <span>Invoice Number:</span>
                                                                    <p>COMP100{{ invoicelist.id }}</p>
                                                                </div> -->
                                                                <div class="content">
                                                                    <span>Date of issue:</span>
                                                                    <p>{{ timestamp(invoiceDetails.created) | date: 'dd MMM yyyy' }}</p>
                                                                    
                                                                </div>
                                                                <!-- <div class="content">
                                                                    <span>Billing Date:</span>
                                                                    <p>{{ invoicelist.createat | date: 'dd MM yyyy' }}</p>
                                                                </div> -->
                                                                <!-- <div class="content">
                                                                    <span>Order Number</span>
                                                                    <p>5039209277</p>
                                                                </div> -->
                                                                <!-- <div class="content">
                                                                    <span>Customer</span>
                                                                    <p>{{ invoiceDetails.customer }}</p>
                                                                </div>                                        -->
                                                            </div>
                                                            <div class="pl-0 billinfodd mt-2 text-right">
                                                                <img src="https://complygate.s3.amazonaws.com/dev/employee/2/1588290438622_MartinSantiago.png">
                                                                <h4>Complygate Limited</h4>
                                                                <p class="text-muted mb-1">4th Floor Colmore Gate,</p>
                                                                <p class="text-muted mb-1">2-6 Colmore Row</p>
                                                                <p class="text-muted mb-1">Birmingham, Uk - B3 2QD</p>
                                                                <p class="text-muted mb-1">VAT: GB 311 1059 64</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="invoice_details">
                                                                
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                    <hr>
                                                
                                                    <div class="row tteepp">
                                                        <div class="box_invo width100">
                                                        <div class="box width50">
                                                            <span class="head">Client Information</span>
                                                            <p class="clientname">{{invoiceDetails.customer_name}}</p>
                                                            <div class="invoice_detailsshow">
                                                                <div class="payconfirm"  *ngIf="invoiceDetails.customer_address">
                                                                    <p class="text-muted mb-1" *ngIf="invoiceDetails.customer_address.line1">{{invoiceDetails.customer_address.line1}},</p>
                                                                    <p class="text-muted mb-1" *ngIf="invoiceDetails.customer_address.line2">{{invoiceDetails.customer_address.line2}}</p>
                                                                    <p class="text-muted mb-1" >{{(invoiceDetails.customer_address.city)?invoiceDetails.customer_address.city+',':''}} {{invoiceDetails.customer_address.state}} {{invoiceDetails.customer_address.postal_code}}</p>
                                                                    <p class="text-muted mb-1" *ngIf="invoiceDetails.customer_address.country">{{invoiceDetails.customer_address.country}}</p>
                                                                    <p class="text-muted mb-1" *ngIf="invoiceDetails.customer_phone">{{invoiceDetails.customer_phone}}</p>
                                                                    <p class="text-muted mb-1" *ngIf="invoiceDetails.customer_email">{{invoiceDetails.customer_email}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="box width25">
                                                            <div class="content mb-3">
                                                                <span>Total Amount</span>
                                                                <p>£{{invoiceDetails.amount_due*.01 | number : '1.2-2'}}</p>
                                                            </div>
                                                            <div class="content">
                                                                <span>Status</span>
                                                                <p class="paid text-danger"><b>{{invoiceDetails.status}}</b></p>
                                                            </div>
                                                        </div>
                                                        <div class="box width25">
                                                            <div class="content mb-4">
                                                                <p class="duedate">Due Date: {{ timestamp(invoiceDetails.due_date) | date: 'dd MMM yyyy'}}</p>
                                                            </div>                                        
                                                        </div>
                                                        </div>
                                                        
                                                    </div>
                        
                                                    <div class="cart_option">
                                                        <div class="col-sm-12">
                                                            <div class="cart_heading">
                                                                <div class="cart_details_show">
                        
                                                                    <div class="checks_calc_amount width35 text-left">
                                                                        <span class="pricing">Item Name</span>
                                                                    </div>
                                                                    <div class="checks_calc_amount width25 text-center">
                                                                        <span class="pricing">Item Price</span>
                                                                    </div>
                        
                                                                    <div class="checks_calc_amount width25 text-center">
                                                                        <span class="pricing">Item Quantity</span>
                                                                    </div>
                                                                    <div class="checks_calc_amount width25 text-right">
                                                                        <span class="pricing">Total</span>
                                                                    </div>
                        
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div  class="col-sm-12" *ngIf="invoiceDetails">
                                                            <div class="cart_details_show" *ngFor="let serv of invoiceDetails.lines.data">
                                                                <div class="checks_name width35">
                                                                    <h4>{{ serv.description}}</h4>
                                                                </div>
                                                                <div class="value_increase width25 text-center">
                                                                    <div class="incr_decr">
                                                                        £{{serv.price.unit_amount*.01 | number : '1.2-2'}}
                                                                    </div>
                                                                </div>
                                                                <div class="checks_calc_amount width25 text-center">
                                                                    <span class="pricing">{{serv.quantity}}</span>
                                                                </div>
                                                                <div class="checks_calc_amount width25 text-right">
                                                                    <span class="pricing"> £{{serv.amount*.01 | number : '1.2-2'}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12" *ngIf="invoiceDetails">
                                                            <div class="total_amount justify-content-end">
                                                                <div class="width25">
                                                                    <div class="amount">
                                                                        <span class="total_text">Subtotal:</span>
                                                                        <span class="total_amount_show">£{{invoiceDetails.subtotal*.01 | number : '1.2-2'}}</span>
                                                                    </div>
                                                                    <div class="amount">
                                                                        <span class="total_text">Tax: </span>
                                                                        <span class="total_amount_show">£{{invoiceDetails.tax*.01 | number : '1.2-2'}}</span>
                                                                    </div>
                                                                    <hr>
                                                                    <div class="amount">
                                                                        <span class="total_text">Total amount: </span>
                                                                        <span class="total_amount_show">£{{invoiceDetails.amount_paid*.01 | number : '1.2-2'}}</span>
                                                                    </div>
                                                                </div>
                        
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                        
                                                    <div class="footer_align_ju">
                                                        <p class="text-center query">If you have any questions about this invoice, please contact </p>
                                                        <p class="text-center emphna"><span>info@complygate.co.uk</span> </p>
                                                        <p class="text-center emphna">+44 121 655 0311</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                       
                                    <div class="clearfix"></div>
                                    
                                </div>
                            </div>
                                <!-- <div class="job_list_section visa_request_section" *ngFor="let invoice of invoicelist; let i=index">
                                    <div class="job_detils_date text-center">
                                        <p class="job_address">Amount</p>
                                        <span class="badge badge-success">£{{invoice.amount_due*.01}}</span>
                                    </div>

                                    <div class="job_detils_date ">
                                        <p class="job_address">Number</p>
                                        <p class="job_profile">{{ invoice.number}}</p>
                                    </div>

                                    <div class="job_detils_date ">
                                        <p class="job_address">Customer</p>
                                        <p class="job_profile">{{ invoice.customer_email}}</p>
                                    </div>
                                    <div class="job_detils_date ">
                                        <p class="job_address">Date</p>
                                        <p class="job_profile">{{ timestamp(invoice.date) | date: 'medium' }}</p>
                                    </div>
                                    <div class="job_detils_date aligncenter">
                                        <div id="container" class="green_text">
                                            <div class="dot"></div>
                                            <div class="pulse"></div>
                                        </div>
                                        <p class="job_profile green_text">Paid </p>
                                    </div>
                                    <div class="job_detils_date width7">
                                        <p class="job_address">
                                            <a [routerLink]="['/invoicedetails', invoice.id]" class="btn btn-primary com_btn"><i class="fa fa-file-image-o" aria-hidden="true"></i></a>
                                        </p>
                                    </div>
                                </div> -->
                            </div>
                            <!-- <div class="col-sm-3 invoice_second_box">
                                <div class="bill_payment_cards">
                                    <div class="bill_pay">
                                        <h5>Payment Details</h5>
                                        <a [routerLink]="['/payment-method']" routerLinkActive="active-link" class="invoice_viewall">Edit</a>
                                        <div class="card_box">
                                            <div *ngFor="let cad of cardlist; let i=index">
                                                <div class="card_list" *ngIf="i == 0">
                                                    <div class="cards">
                                                        <img src="assets/img/visa.png" *ngIf="cad.brand == 'Visa'">
                                                        <img src="assets/img/mastercard.png" *ngIf="cad.brand == 'MasterCard'">
                                                        <div class="cardno">
                                                            <span class="cardname">xxxx xxxx xxxx {{cad.last4}}</span>
                                                            <span class="cardname_text">{{cad.brand}} - Expires {{cad.exp_month}}/{{cad.exp_year}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="billawar">Bill on the first of every month</p>
                                        <p class="billawar" *ngIf="billinglist">Next billing on <span>{{ timestamp(billinglist.created) | date: 'small' }}</span></p>
                                    </div>
                                </div>
                            </div> -->
                        </div>

                    </div>
                        <div style="display: none;" class="invoice_content" *ngIf="record >0" >
                        <div class="header">
                           <h1 class="headprofile mt-0">Check Invoices</h1>
                        </div>
                        <div class="row">
                           <div class="col-sm-12 project_list">
                              <!-- Fareed -->
                              <div class="card mt-4 mb-4 ">
                                 <div class="card-body p-0">
                                     <div class="inheader">
                                         <div class="invoice_inbox">
                                             <div class="inbox_heading">
                                                 <h2>All Invoices</h2>
                                             </div>
                                         </div>
                                         <div class="invoice_bill_section" *ngIf="checkInvoiceDetails">
                                             <div class="inbox_bill_heading">
                                                 <h2>COMP100{{ checkInvoiceDetails.id }}</h2>
                                                 <div class="group_btn">
                                                     <button type="button" class="btn btn-primary" (click)="downloadCheckInvoice(checkInvoiceDetails.id)" [innerHtml]="downloadcheckinvoicetext"></button>
                                                    
                                                 </div>                                   
                                             </div>
                                         </div>
                                     </div>
                                     <div class="invoice_group">                            
                                         <div class="invoice_inbox with30">
                                             
                                           
                                             <div class="invoice_mail" [class.myClass]="isChecked== invoice.id" *ngFor="let invoice of pageOfItems; let i=index">
                                                 <div class="check">
                                                    <input type="radio" name="checkinvoicedata" class="form-control" [checked]="checkInvoiceDetails.id && checkInvoiceDetails.id==invoice.id" (click)="getCheckInvoiceData(invoice.id)" (change)="checked(invoice.id)">                                                   
                                                 </div>
                                                 <div class="content_mail">
                                                     <div class="details">
                                                         <p class="person_name">Invoice</p>
                                                         <span class="invoiceid">COMP100{{ invoice.id }}</span> <span class="center_line">|</span> <span class="invoice_date">{{ invoice.createat | date: 'dd MMM yyyy' }}</span>
                                                     </div>
                                                     <div class="price_mail">
                                                         <p class="invoice_price">£{{ invoice.gtotal | number : '1.2-2' }}</p>
                                                         <span class="sent">Paid</span>
                                                     </div>
                                                 </div>
                                             </div>
                                             
                                         </div>
                                         <div class="invoice_bill_section width70" *ngIf="checkInvoiceDetails">
                                             
                                             <div class="invoice_doc">
                                                 <div class="invoice_box_page" id="contentToConvert">
                                                     <div class="status_sent">
                                                         <span>STATUS - PAID</span>
                                                     </div>
                                                     <div class="row tteep ">
                                                         <div class="col-md-12 alignivo">                                    
                                                             <div class="invoice_content_show" *ngIf="checkInvoiceDetails">
                                                                 <h4>Invoice : COMP100{{ checkInvoiceDetails.id }}</h4>
                                                                 <div class="content">
                                                                     <span>Invoice/Credit memo</span>
                                                                     <p>Invoice</p>
                                                                 </div>
                                                                 <!-- <div class="content">
                                                                     <span>Invoice Number:</span>
                                                                     <p>COMP100{{ invoicelist.id }}</p>
                                                                 </div> -->
                                                                 <div class="content">
                                                                     <span>Invoice Date:</span>
                                                                     <p>{{ checkInvoiceDetails.createat | date: 'dd MMM yyyy' }}</p>
                                                                 </div>
                                                                 <!-- <div class="content">
                                                                     <span>Billing Date:</span>
                                                                     <p>{{ invoicelist.createat | date: 'dd MM yyyy' }}</p>
                                                                 </div> -->
                                                                 <!-- <div class="content">
                                                                     <span>Order Number</span>
                                                                     <p>5039209277</p>
                                                                 </div> -->
                                                                 <!-- <div class="content">
                                                                     <span>Customer</span>
                                                                     <p>{{ checkInvoiceDetails.companydetails.stuser }}</p>
                                                                 </div>                                        -->
                                                             </div>
                                                             <div class="pl-0 billinfodd mt-2 text-right">
                                                                 <img src="https://complygate.s3.amazonaws.com/dev/employee/2/1588290438622_MartinSantiago.png">
                                                                 <h4>Complygate Limited</h4>
                                                                 <p class="text-muted mb-1">4th Floor Colmore Gate,</p>
                                                                 <p class="text-muted mb-1">2-6 Colmore Row</p>
                                                                 <p class="text-muted mb-1">Birmingham, Uk - B3 2QD</p>
                                                                 <p class="text-muted mb-1">VAT: GB 311 1059 64</p>
                                                             </div>
                                                         </div>
                                                         <div class="col-sm-6">
                                                             <div class="invoice_details">
                                                                 
                                                             </div>
                                                         </div>
                                                        
                                                     </div>
                                                     <hr>
                                                    
                                                     <div class="row tteepp">
                                                         <div class="box_invo width100">
                                                             <div class="box width50">
                                                                 <span class="head">Client Information</span>
                                                                 <p class="clientname" *ngIf="companyInfo">{{companyInfo.companyname}}</p>
                                                            
                                                                    <div class="invoice_detailsshow">
                                                            
                                                                        <div class="payconfirm"  *ngIf="companyLocation">
                                                                     <p class="text-muted mb-1" *ngIf="companyLocation.address1">{{companyLocation.address1}},</p>
                                                                     <p class="text-muted mb-1" *ngIf="companyLocation.address2">{{companyLocation.address2}}</p>
                                                                     <p class="text-muted mb-1" *ngIf="companyLocation.cityname">{{companyLocation.cityname}}, {{companyLocation.statename}}, {{ (companyLocation.country)?companyLocation.country.countryname:'' }} - {{companyLocation.pincode}}</p>
                                                                    </div>
                                                                     
                                                                </div>
                                                                 
                                                             </div>
                                                             <div class="box width25">
                                                                 <div class="content mb-3">
                                                                     <span>Total Amount</span>
                                                                     <p>£{{ checkInvoiceDetails.gtotal | number : '1.2-2'}}</p>
                                                                 </div>
                                                                 <div class="content">
                                                                     <span>Status</span>
                                                                     <p class="paid text-danger"><b>Paid</b></p>
                                                                 </div>
                                                             </div>
                                                             <div class="box width25">
                                                                 <div class="content mb-4">
                                                                      <p class="duedate">Due Date: {{ checkInvoiceDetails.createat | date: 'dd MMM yyyy' }}</p>
                                                                 </div> 
                                                                 <!-- <div class="content">
                                                                     <p class="downloadpdf"><a href="#">Download <i class="fa fa-file-pdf-o ml-2" aria-hidden="true"></i>
                                                                     </a></p>
                                                                </div>                                           -->
                                                             </div>
                                                         </div>
                                                         
                                                     </div>
                         
                                                     <div class="cart_option">
                                                         <div class="col-sm-12">
                                                             <div class="cart_heading">
                                                                 <div class="cart_details_show">
                         
                                                                     <div class="checks_calc_amount width35 text-left">
                                                                         <span class="pricing">Item Name</span>
                                                                     </div>
                                                                     <div class="checks_calc_amount width25 text-center">
                                                                         <span class="pricing">Item Price</span>
                                                                     </div>
                         
                                                                     <div class="checks_calc_amount width25 text-center">
                                                                         <span class="pricing">Item Quantity</span>
                                                                     </div>
                                                                     <div class="checks_calc_amount width25 text-right">
                                                                         <span class="pricing">Total</span>
                                                                     </div>
                         
                                                                 </div>
                                                             </div>
                                                         </div>
                                                         <div  class="col-sm-12" *ngIf="checkInvoiceDetails">
                                                             <div class="cart_details_show"  *ngFor="let vs of checkInvoiceDetails.checkorderdetails">
                                                                 <div class="checks_name width35">
                                                                     <h4>{{ vs.servicesname.servicename }}</h4>
                                                                 </div>
                                                                 <div class="value_increase width25 text-center">
                                                                     <div class="incr_decr">
                                                                         £{{vs.prices | number}}
                                                                     </div>
                                                                 </div>
                                                                 <div class="checks_calc_amount width25 text-center">
                                                                     <span class="pricing">{{vs.qty}}</span>
                                                                 </div>
                                                                 <div class="checks_calc_amount width25 text-right">
                                                                     <span class="pricing"> £{{vs.prices*vs.qty | number}}</span>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                         <div class="col-sm-12" *ngIf="checkInvoiceDetails">
                                                             <div class="total_amount justify-content-end">
                                                                 <div class="width25">
                                                                     <div class="amount">
                                                                         <span class="total_text">Subtotal:</span>
                                                                         <span class="total_amount_show">£{{ checkInvoiceDetails.prices | number : '1.2-2' }}</span>
                                                                     </div>
                                                                     <div class="amount">
                                                                         <span class="total_text">Tax: </span>
                                                                         <span class="total_amount_show">£{{ checkInvoiceDetails.tax | number : '1.2-2' }}</span>
                                                                     </div>
                                                                     <hr> 
                                                                     <div class="amount">
                                                                         <span class="total_text">Total amount: </span>
                                                                         <span class="total_amount_show">£{{ checkInvoiceDetails.gtotal | number : '1.2-2' }}</span>
                                                                     </div>
                                                                 </div>
                         
                                                             </div>
                                                         </div>
                                                         
                                                     </div>
                          
                                                     <div class="footer_align_ju">
                                                         <p class="text-center query">If you have any questions about this invoice, please contact </p>
                                                         <p class="text-center emphna"><span>info@complygate.co.uk</span> </p>
                                                         <p class="text-center emphna">+44 121 655 0311</p>
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>                       
                                     <div class="clearfix"></div>
                                     
                                 </div>
                             </div>
                              <!-- <div class="invoice_header">
                                 <span class="width20">Amount</span>
                                 <span class="width20">Number</span>
                                 <span class="width40">Customer</span>
                                 <span class="width40">Email</span>
                                 <span class="width30">Date</span>
                                 <span class="width25">Status</span>
                                 <span class="width10">Action</span>
                              </div>
                              <div class="invoice_body" *ngFor="let invoice of pageOfItems; let i=index">
                               <span class="width20 mobile_show">Amount</span>
                                <span class="width20 text-success"><b>£{{ invoice.gtotal | number : '1.2-2' }}</b></span>
                                <span class="width20 mobile_show">Number</span>
                                <span class="width20">COMP100{{ invoice.id}}</span>
                                <span class="width40 mobile_show">Customer</span>
                                <span class="width40">{{ invoice.companydetails.clientname}}</span>
                                <span class="width40 mobile_show">Email</span>
                                <span class="width40">{{ invoice.companydetails.emailid}}</span>
                                <span class="width30 mobile_show">Date</span>
                                <span class="width30">{{ invoice.createat | date: 'dd MMM yyyy' }}</span>
                                <span class="width25 mobile_show">Mode</span>
                                <span class="width25 text-danger invaligncenter"> 
                                   <div id="container" class="green_text">
                                   <div class="dot"></div>
                                   <div class="pulse"></div>
                               </div>                                
                               <p class="job_profile green_text">Paid </p> </span>
                               <span class="width10 mobile_show">Action</span>
                                <span class="width10">
                                   <i [routerLink]="['/check-invoice-details', invoice.id]" class="fa fa-eye p-2 pl-0" aria-hidden="true"></i>
                                   
                                </span>
                              </div>
                               <div class="job_list_section visa_request_section" *ngIf="record == 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>
                            <div class="row mt-3 pageination">
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="items" [pageSize]="10" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div> -->
                           </div>
                       </div>

                       

                        <!-- <div class="card_box">
         <div class="card_list">
            <div class="cards">
               <img src="assets/img/visa.png">
               <div class="cardno">
                  <span class="cardname">8792 xxxx xxxx 1004</span>
                  <span class="cardname_text">Card Number</span>
               </div>
               <div class="card_date">
                  <span class="date">12/2020</span>
               </div>
            </div>
         </div>
         <div class="card_list">
            <div class="cards">
               <img src="assets/img/mastercard.png">
               <div class="cardno">
                  <span class="cardname">8792 xxxx xxxx 1004</span>
                  <span class="cardname_text">Card Number</span>
               </div>
               <div class="card_date">
                  <span class="date">12/2020</span>
               </div>
            </div>
         </div>
         <div class="card_list">
            <div class="cards">
               <img src="assets/img/visa.png">
               <div class="cardno">
                  <span class="cardname">8792 xxxx xxxx 1004</span>
                  <span class="cardname_text">Card Number</span>
               </div>
               <div class="card_date">
                  <span class="date">12/2020</span>
               </div>
            </div>
         </div>
         <div class="card_list">
            <div class="cards">
               <img src="assets/img/mastercard.png">
               <div class="cardno">
                  <span class="cardname">8792 xxxx xxxx 1004</span>
                  <span class="cardname_text">Card Number</span>
               </div>
               <div class="card_date">
                  <span class="date">12/2020</span>
               </div>
            </div>
         </div>
         <div class="card_list">
            <div class="cards">
               <img src="assets/img/visa.png">
               <div class="cardno">
                  <span class="cardname">8792 xxxx xxxx 1004</span>
                  <span class="cardname_text">Card Number</span>
               </div>
               <div class="card_date">
                  <span class="date">12/2020</span>
               </div>
            </div>
         </div>
         <div class="card_list">
            <div class="cards">
               <img src="assets/img/mastercard.png">
               <div class="cardno">
                  <span class="cardname">8792 xxxx xxxx 1004</span>
                  <span class="cardname_text">Card Number</span>
               </div>
               <div class="card_date">
                  <span class="date">12/2020</span>
               </div>
            </div>
         </div>
         <div class="card_list">
            <div class="cards">
               <img src="assets/img/visa.png">
               <div class="cardno">
                  <span class="cardname">8792 xxxx xxxx 1004</span>
                  <span class="cardname_text">Card Number</span>
               </div>
               <div class="card_date">
                  <span class="date">12/2020</span>
               </div>
            </div>
         </div>
      </div> -->
                        <!-- <div class="billing_info_section">
         <div class="bill_info">
            <div class="billing_info">
               <p>Your Front plan (Billed Yearly) </p>
               <button type="submit" class="btn btn-primary upgrade">Update plan</button>
            </div>
            <h4>Plan</h4>
            <div class="billing_info">
               <p>9 Plus User Licences <span>(8 Asssigned)</span> </p>
               <p>$ 2,052.00</p>
            </div>
            <div class="billing_info">
               <p>1 Twitter channel </p>
               <p>$ 240.00</p>
            </div>
            <div class="billing_info">
               <p>1 Light User Licences <span>(0 Asssigned)</span></p>
               <p>$ 120.00</p>
            </div>
            <div class="billing_info">
               <p>50% Discount </p>
               <p class="green">- $ 1,206.00</p>
            </div>
            <hr>
            <div class="billing_info">
               <p class="total_text">Total per year </p>
               <p class="total_price">$ 1,206.00</p>
            </div>
           
         </div>
         <div class="bill_payment_cards">
            <div class="bill_pay">
            <h5>Payment Details</h5>
             <a href="#" class="invoice_viewall">Edit</a>
              <div class="card_box">
                 <div class="card_list">
                    <div class="cards">
                      <img src="assets/img/mastercard.png">
                      <div class="cardno">
                          <span class="cardname">8792 xxxx xxxx 1004</span>
                          <span class="cardname_text">MasterCard - Expires 05/21</span>
                      </div>
                      
                    </div>
                </div>
              </div>
              <p class="billawar">Bill on the first of every month.</p>
              <p class="billawar">Next billing on <span>July 01, 2020</span></p>
            </div>
         </div>
         <div class="bill_payment_invoice">
            <div class="bill_pay">
            <h5>Invoices </h5>
            <a href="#" class="invoice_viewall">View all</a>
             <div class="billing_info">
               <p class="invoice_cale">March 01,2019 </p>
               <p class="invoice_price_summ">$ 240.00  <i class="fa fa-download" aria-hidden="true"></i></p>
            </div>
              <div class="billing_info">
               <p class="invoice_cale">March 01,2019 </p>
               <p class="invoice_price_summ">$ 240.00  <i class="fa fa-download" aria-hidden="true"></i></p>
            </div>
            <div class="billing_info">
               <p class="invoice_cale">March 01,2019 </p>
               <p class="invoice_price_summ">$ 240.00  <i class="fa fa-download" aria-hidden="true"></i></p>
            </div>
            <div class="billing_info">
               <p class="invoice_cale">March 01,2019 </p>
               <p class="invoice_price_summ">$ 240.00  <i class="fa fa-download" aria-hidden="true"></i></p>
            </div>
            </div>
         </div>
         <div class="clearfix"></div>
      </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>