import { Component, OnInit } from '@angular/core';
import { ExcelService } from '../../services/excel.service';

@Component({
  selector: 'app-jobreport',
  templateUrl: './jobreport.component.html',
  styleUrls: ['./jobreport.component.css']
})

export class JobreportComponent implements OnInit {
  data: any;
  constructor(private excelService: ExcelService,) { }

  ngOnInit() {
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.data, 'sample');
  }
}