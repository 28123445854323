"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var EmployeeaiverificationComponent = /** @class */ (function () {
    function EmployeeaiverificationComponent() {
    }
    EmployeeaiverificationComponent.prototype.ngOnInit = function () {
    };
    return EmployeeaiverificationComponent;
}());
exports.EmployeeaiverificationComponent = EmployeeaiverificationComponent;
