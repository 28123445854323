"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./aisystem.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../user/header/header.component.ngfactory");
var i3 = require("../../user/header/header.component");
var i4 = require("../../services/customer.service");
var i5 = require("@angular/router");
var i6 = require("../../services/user.service");
var i7 = require("../../services/company.service");
var i8 = require("@angular/common");
var i9 = require("./aisystem.component");
var styles_AisystemComponent = [i0.styles];
var RenderType_AisystemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AisystemComponent, data: {} });
exports.RenderType_AisystemComponent = RenderType_AisystemComponent;
function View_AisystemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 38, "div", [["class", "bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.CustomerService, i5.Router, i6.UserService, i7.CompanyService, i1.Renderer2, i1.Renderer2], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 35, "div", [["class", "section-wrapper p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 34, "div", [["class", "admin_dashboard_show"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 33, "div", [["class", "ceee"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 32, "div", [["class", "cardmenu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "a", [["class", "card_box_lit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 1), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "samebox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "lnr lnr-license"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "menu_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Visa"])), (_l()(), i1.ɵeld(15, 0, null, null, 7, "a", [["class", "card_box_lit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(17, 1), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "samebox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "i", [["class", "lnr lnr-earth"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [["class", "menu_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Passport"])), (_l()(), i1.ɵeld(23, 0, null, null, 7, "a", [["class", "card_box_lit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(25, 1), (_l()(), i1.ɵeld(26, 0, null, null, 4, "div", [["class", "samebox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 0, "i", [["class", "lnr lnr-picture"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 2, "div", [["class", "menu_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ID Proof"])), (_l()(), i1.ɵeld(31, 0, null, null, 7, "a", [["class", "card_box_lit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 32).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(32, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(33, 1), (_l()(), i1.ɵeld(34, 0, null, null, 4, "div", [["class", "samebox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 0, "i", [["class", "lnr lnr-magic-wand"]], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 2, "div", [["class", "menu_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["AI Logs"]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_2 = _ck(_v, 9, 0, "/ai-visa-list"); _ck(_v, 8, 0, currVal_2); var currVal_5 = _ck(_v, 17, 0, "/ai-passport-list"); _ck(_v, 16, 0, currVal_5); var currVal_8 = _ck(_v, 25, 0, "/ai-id-proof-list"); _ck(_v, 24, 0, currVal_8); var currVal_11 = _ck(_v, 33, 0, "/ai-log-list"); _ck(_v, 32, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).target; var currVal_1 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 16).target; var currVal_4 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 24).target; var currVal_7 = i1.ɵnov(_v, 24).href; _ck(_v, 23, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 32).target; var currVal_10 = i1.ɵnov(_v, 32).href; _ck(_v, 31, 0, currVal_9, currVal_10); }); }
exports.View_AisystemComponent_0 = View_AisystemComponent_0;
function View_AisystemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-aisystem", [], null, null, null, View_AisystemComponent_0, RenderType_AisystemComponent)), i1.ɵdid(1, 114688, null, 0, i9.AisystemComponent, [i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AisystemComponent_Host_0 = View_AisystemComponent_Host_0;
var AisystemComponentNgFactory = i1.ɵccf("app-aisystem", i9.AisystemComponent, View_AisystemComponent_Host_0, {}, {}, []);
exports.AisystemComponentNgFactory = AisystemComponentNgFactory;
