export class ReferenceDetails { 
  templatecode: string;  
  templatename: string;
  question: any;
  required: string;
  questiontype: string;
  questionoptions: string;
}

export class Referencesetting { 
  isemail:boolean =false;
  issms:boolean =false;
  isemailtime:any;
  emailday:any;
  issmstime:any;
  smsday:any;
}
export class Options {
  constructor(public id: number, public name: string) { }
}