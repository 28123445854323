import { Injectable } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class CompanyadvertService {

  constructor(
    private commonService: CommonService
  ) { }

  getcompanyadvertdata(data): Observable<any> 
  {
    return this.commonService.formpost('/company/getcompanyadvertdata',data);   
  }

  downloadcompanyadvertdata(data): Observable<any> 
  {
    return this.commonService.formpost('/company/downloadcompanyadvertdata',data);   
  }

  searchcompany(data): Observable<any>
  {
    return this.commonService.formpost('/company/searchcompany',data);  
  }

  getcompanyimages(data): Observable<any> 
  {
    return this.commonService.formpost('/company/getimages',data);   
  }

  getcompanyhistoryadvertdata(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/company/getcompanyadvertdatahistorydetails');   
  }

  getcompanyhistoryview(data): Observable<any> 
  {
    return this.commonService.formpost('/company/getcompanyhistoryview',data);   
  }

  downloadcompanyimage(data): Observable<any> 
  {
    return this.commonService.formpost('/company/downloadimage',data);   
  }

}
