<!-- <div class="slim-header">
  <div class="container">
    <div class="slim-header-left">
      <h2 class="slim-logo"><a [routerLink]="['/login']"><img src="/assets/img/logo.png" alt=""><span></span></a></h2>
    </div>
  </div>
</div> -->
<div class="bg">

    <div class="signin-wrapper">
        <div class="new_login">
            <div class="login_section">
                <a class="login_logo" [routerLink]="['/login']"><img src="/assets/img/logo.png" alt=""></a>
                <div class="signin-wrapper" *ngIf="checktoken == false">
                    <div class="signin-box">
                        <h2 class="slim-logo">Invalid Token</h2>
                        <p>The passcode has expired or has been used previously. Please request a new passcode.</p>
                    </div>
                </div>
                <div class="signin-box" *ngIf="checktoken == true">
                    <form #loginForm="ngForm" [formGroup]="forgotPassword" class="login_form ng-pristine"
                        (ngSubmit)="forgotpassword(loginForm.value)">
                        <div class="form-group mg-b-30">
                            <div class="input-group">
                                <ng-container *ngIf="msg">
                                    <div class="alert alert-success">
                                        <strong>Success!</strong> {{msg}}
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="error">
                                    <div class="alert alert-danger">
                                        <strong>Danger!</strong> {{error}}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="form-group mg-b-30">
                            <div class="input-group">
                                <input type="password" class="form-control" formControlName="newPassword" #newPassword
                                    [(ngModel)]="user.password" name="password"
                                    [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }"
                                    placeholder="Password" required />
                                <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-lock"
                                            aria-hidden="true"></i></span>
                                </div>
                                <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.newPassword.errors.required">Please enter password.</div>
                                    <div *ngIf="f.newPassword.errors.minlength">Password must be at least 8 characters long
                                    </div>
                                    <div *ngIf="f.newPassword.errors.pattern">Password must be alphanumeric including
                                        Uppercase, Lowercase, Special characters and Numeric</div>
                                </div>
                                <div class="invalid-feedback">
                                    <!-- <mat-error *ngIf="forgotPassword.hasError('required', 'newPassword')">
                    Please enter your New Password
                  </mat-error> -->
                                </div>
                            </div>
                        </div>
                        <div class="form-group mg-b-30">
                            <div class="input-group">
                                <input type="password" class="form-control" formControlName="confirmPassword"
                                    [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" #confirmPassword
                                    [(ngModel)]="user.confirmPassword" name="confirmPassword"
                                    placeholder="Confirm Password" required>
                                <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-lock"
                                            aria-hidden="true"></i></span>
                                </div>
                                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.confirmPassword.errors.required">Please enter confirm password</div>
                                    <div *ngIf="f.confirmPassword.errors.minlength">Password must be at least 8
                                        characters</div>
                                </div>
                                <mat-error *ngIf="forgotPassword.hasError('notSame')" style="font-size: 80%;
                                color: #dc3545;" class="width100"> Password and confirm password are not same
                                </mat-error>
                                <!-- <div *ngIf="forgotPassword.controls['newPassword'].value!= forgotPassword.controls['confirmPassword'].value"
                  class="invalid-feedback">Password and confirm password are not same.</div> -->
                            </div>
                        </div>

                        <button class="btn btn-primary btn-block btn-signin" style="width:100%" type="submit">Reset
                            Password</button>
                        <p class="mg-b-0 usefulllink "><a [routerLink]="['/login']"
                                class="fortgot text-center col-sm-12">Back to login</a></p>
                    </form>
                    <!-- </div> -->
                </div>
                <!-- col-8 -->
            </div>
            <div class="graphic_section">
                <div class="slogan">
                    <h4>Welcome To <b>Complygate</b></h4>
                    <span>Commit. Control. Comply</span>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>