import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Passport } from '../../models/passport/passport.model';
import { UploadFileService } from '../../services/upload-file.service';
import { Employee } from '../../models/employee.model';
import { FileuploadService } from '../../services/fileupload.service';
import { MypassportService } from '../../services/mypassport.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-passportaiverify',
  templateUrl: './passportaiverify.component.html',
  styleUrls: ['./passportaiverify.component.css']
})

export class PassportaiverifyComponent implements OnInit {
  submitted: any;
  visapopForm: FormGroup;
  passport: Passport;
  permission: any;
  requestperm: any;
  record: number;
  selectedFiles: any;
  fileformaterror: any;
  filesizeerror: any;
  ctype:number;
  file: any;
  file1: any;
  employeemasterid: any;
  passportdetails = new Passport();
  employee = new Employee();
  data3: any;
  employeevisadeatilid: any;

  constructor(private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private passportService: MypassportService,
    private activatedRoute: ActivatedRoute,
    private filupload: FileuploadService,
    private uploadService: UploadFileService,
    private alerts: AlertService,
    private router: Router) { }

  ngOnInit() {
    var permission = localStorage.getItem('ispermission');

    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;

    this.ctype  = parseInt(localStorage.getItem('ctype'));

    this.employeevisadeatilid = this.activatedRoute.snapshot.paramMap.get("passportid");
    this.data3 = { "employeepassdeatilid": this.employeevisadeatilid }
    this.passportService.passportdetailsbypassportid(this.data3).subscribe((data: any) => {
      this.employeemasterid = data.employee.employeemasterid;
      this.passportdetails = data;
      this.record = data.length;
    });
  }

  selectFile(event,type) {

    type = parseInt(type);

    var files = event.target.files;
    var selectedFiles = files[0];
    this.selectedFiles = selectedFiles;
    const file = this.selectedFiles;
    this.fileformaterror = false;
    this.filesizeerror = false;

    this.file = this.selectedFiles;
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;
      this.alerts.success('File size must be less than 2 MB.', true);
      return;
    }
    else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png")) {
      this.fileformaterror = true;
      this.alerts.success('Please choose a valid file format (jpg, png, ttf, gif)', true);
      return;
    }
    var imagepath = this.uploadService.uploadfile(file);

    if(type == 0){        
      this.passportdetails.passportdocname = '../../../../../../../assets/img/loadings.gif';
    }
    else if(type == 1)
     {        
      this.passportdetails.passportbackdocname = '../../../../../../../assets/img/loadings.gif';
      }


   
    // this.passportdetails.passportdocname = imagepath;

    var dbimage = { "id": this.employeevisadeatilid, "imagename": imagepath, "employeid": this.employeemasterid, itype: type };
    this.passportService.passportupdateForAI(dbimage).subscribe((data: any) => {

      if(type ==0){          
        setTimeout(() => this.passportdetails.passportdocname  = imagepath, 3000);        }
      else if(type ==1){
      setTimeout(() => this.passportdetails.passportbackdocname  = imagepath, 3000);        
       }

      
    });
  }
}