import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cos',
  templateUrl: './cos.component.html',
  styleUrls: ['./cos.component.css']
})

export class COSComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}