"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var AiwizardComponent = /** @class */ (function () {
    function AiwizardComponent(activatedRoute) {
        this.activatedRoute = activatedRoute;
    }
    AiwizardComponent.prototype.ngOnInit = function () {
        this.employeevisadeatilid = this.activatedRoute.snapshot.paramMap.get("visaid");
    };
    AiwizardComponent.prototype.block = function () { return; };
    return AiwizardComponent;
}());
exports.AiwizardComponent = AiwizardComponent;
