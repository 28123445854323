"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var S3 = require("aws-sdk/clients/s3");
//import { FileSelectDirective } from 'ng2-file-upload';
var Buffer = require('buffer/').Buffer;
var UploadFileService = /** @class */ (function () {
    function UploadFileService() {
        //response: any;
        this.filename = {};
        this.url = {};
        this.FOLDER = 'dev/';
    }
    UploadFileService.prototype.uploadfile = function (file) {
        var userid = localStorage.getItem('userid');
        var empname = localStorage.getItem('Name');
        var fileext = file.name.split('.').pop();
        var bucket = new S3({
            accessKeyId: 'AKIARJCDXR3VSWIYBLON',
            secretAccessKey: 'T2qcxeMGQGN9ARD0vg0I+fYVjThK1ql4nJ+hX//4',
            region: 'eu-west-2'
        });
        var nm = this.getFileNameWithoutExtension(file.name.replace(' ', '')).substring(0, 20);
        var params = {
            Bucket: 'complygate',
            Key: this.FOLDER + 'employee/' + userid + '/' + nm + '_' + Date.now() + "." + fileext,
            Body: file
            // ACL: 'public-read'
        };
        var response = bucket.upload(params, function (err, data) {
            if (err) {
                return false;
            }
        });
        return "https://complygate.s3.amazonaws.com/" + params.Key;
    };
    UploadFileService.prototype.uploadfilebase64 = function (file) {
        var userid = localStorage.getItem('userid');
        var empname = localStorage.getItem('Name');
        // var fileext = file.name.split('.').pop();
        var bucket = new S3({
            accessKeyId: 'AKIARJCDXR3VSWIYBLON',
            secretAccessKey: 'T2qcxeMGQGN9ARD0vg0I+fYVjThK1ql4nJ+hX//4',
            region: 'us-east-1'
        });
        console.log(file._imageAsDataUrl.replace(/^data:image\/\w+;base64,/, ""));
        var nm = 'asfdafdasfas'; // this.getFileNameWithoutExtension(file.name).substring(0,20)
        var buf = Buffer.from(file._imageAsDataUrl.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        var params = {
            Bucket: 'complygate',
            Key: this.FOLDER + 'employee/' + userid + '/' + nm + '_' + Date.now() + ".jpeg",
            Body: buf,
            ContentEncoding: 'base64',
            ContentType: file.mimeType // 'image/jpeg'
            // ACL: 'public-read'
        };
        var response = bucket.upload(params, function (err, data) {
            if (err) {
                return false;
            }
        });
        var imgurl = ("https://complygate.s3.amazonaws.com/" + params.Key);
        return imgurl;
    };
    UploadFileService.prototype.uploadfilenotebase64 = function (file) {
        var userid = localStorage.getItem('userid');
        var empname = localStorage.getItem('Name');
        // var fileext = file.name.split('.').pop();
        var bucket = new S3({
            accessKeyId: 'AKIARJCDXR3VSWIYBLON',
            secretAccessKey: 'T2qcxeMGQGN9ARD0vg0I+fYVjThK1ql4nJ+hX//4',
            region: 'us-east-1'
        });
        // console.log(file.replace(/^data:image\/\w+;base64,/, ""));
        var nm = 'asfdafdasfas'; // this.getFileNameWithoutExtension(file.name).substring(0,20)
        var buf = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        var params = {
            Bucket: 'complygate',
            Key: this.FOLDER + 'employee/' + userid + '/' + nm + '_' + Date.now() + ".jpeg",
            Body: buf,
            ContentEncoding: 'base64',
            ContentType: file.mimeType // 'image/jpeg'
            // ACL: 'public-read'
        };
        var response = bucket.upload(params, function (err, data) {
            if (err) {
                return false;
            }
        });
        var imgurl = ("https://complygate.s3.amazonaws.com/" + params.Key);
        return imgurl;
    };
    UploadFileService.prototype.getFileNameWithoutExtension = function (name) {
        //  var name = file.getName();
        //console.log(name.length);
        var pos = name.lastIndexOf('.');
        if (pos > 0 && pos < (name.length - 1)) {
            // there is a '.' and it's not the first, or last character.
            return name.substring(0, pos);
        }
        return name;
    };
    return UploadFileService;
}());
exports.UploadFileService = UploadFileService;
