import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../../_services/modal.service';

import { Aml,AmlBussiness,amldetailsclass } from '../../../../models/aml/aml.model';
import { AmlService } from '../../../../services/aml.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';



@Component({
  selector: 'app-completepersonaldetails',
  templateUrl: './completepersonaldetails.component.html',
  styleUrls: ['./completepersonaldetails.component.css']
})

export class CompletepersonaldetailsComponent implements OnInit {

  logid: any;
  personid:any;
  result: boolean;
  searchresult: any;
  datasetlist: any;
  snippet:any;
  originalURL:any;
   bussinessdetails:any;

   imgvisasrc:any;
   iframevisasrc:any;

  tPEPs: any=0;
  tCurrentSanctions: any=0;
  tDisqualifiedDirector: any=0;
  tFinancialRegulator: any=0;
  tInsolvency: any=0;
  tLawEnforcement: any=0;
  tAdverseMedia: any=0;
  tPreviousSanctions: any=0;
  url: any;
  urlpdf:any;
  PEPs: any= [];
  CurrentSanctions: any= [];
  DisqualifiedDirector: any= [];
  FinancialRegulator: any= [];
  Insolvency: any= [];
  LawEnforcement: any= [];
  AdverseMedia: any= [];
  PreviousSanctions: any= [];
  idv: any=[];

  constructor(
    private sanitizer: DomSanitizer, 
    private modalService: ModalService,    
    private amldetails: AmlService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {

    var newlist ;
    this.url = '';
    var newpersonalid;
    this.bussinessdetails =new amldetailsclass();
    this.personid = this.activatedRoute.snapshot.paramMap.get("personid");

    newpersonalid = this.personid ;
    this.searchresult =[];
    this.logid = this.activatedRoute.snapshot.paramMap.get("id");
    var val ={ id: this.logid,atype:0}
    this.amldetails.getCompanylogrecord(val).subscribe((searchRes: any) => {  
    var listmaster =JSON.parse(searchRes.searchresult);
    if(listmaster.recordsFound >0){
      this.result =true;
    }
    this.searchresult = listmaster;
    listmaster.matches.forEach(function(entry) {

      if(entry.person.id == newpersonalid)
      {       
        newlist = entry;
        
      }    
    });

    this.bindattribute(newlist);

    this.bussinessdetails = newlist;

    this.downloadpdf();
  });


  }

  downloadpdf(){ this.url ='';
    this.personid = this.activatedRoute.snapshot.paramMap.get("personid");
    var newpersonalid;
    newpersonalid = this.personid ;
    this.logid = this.activatedRoute.snapshot.paramMap.get("id");
    var val ={ id: this.logid,atype:0}
    this.amldetails.generatePdf({ id: this.logid,atype:0, newpersonalid: newpersonalid}).subscribe((pdfRes: any) => { 
      
      this.url = pdfRes.response_data.Location;
      this.urlpdf = this.url;

    });
  }

  urlfind (url){

    if(url != null)
    {
        var slashslash = url.indexOf("//") + 2;
        return url.substring(slashslash, url.indexOf('/', slashslash));
  }else{
    return "";
  }

  }

  amllogredirect()
  {
    this.router.navigate(['/creditsearchresult',this.logid]);
  }
  
bindattribute(data){
  
  
  this.AdverseMedia = [];
  this.PreviousSanctions =[];
  this.PEPs = [];
  this.LawEnforcement = [];
  this.CurrentSanctions = [];
  this.FinancialRegulator = [];
  this.DisqualifiedDirector = [];
  this.Insolvency = [];
  this.idv =[];
  

  var rAdverseMedia = [];
  var rPreviousSanctions =[];
  var rPEPs = [];
  var rLawEnforcement = [];
  var rCurrentSanctions = [];
  var rFinancialRegulator = [];
  var rDisqualifiedDirector = [];
  var rInsolvency = [];
  var ridv =[];
  data.person.articles.forEach(function(entry2) {
    // console.log("Second Type Development", entry2);
    entry2.categories.forEach(function(entry3) { 
      
      if(entry3.name =='Adverse Media')
      {
        
        // this.tAdverseMedia =this.tAdverseMedia +1;

        rAdverseMedia.push(entry2); 

      }  else if(entry3.name =='ID/V')
      {
        // this.tPEPs =this.tPEPs +1;

        ridv.push(entry2); 

      }else if(entry3.name =='PEP')
      {
        // this.tPEPs =this.tPEPs +1;

        rPEPs.push(entry2); 

      }else if(entry3.name =='Corporate/Business')
      {

        // this.tLawEnforcement =this.tLawEnforcement +1;

        rPreviousSanctions.push(entry2); 

      }else if(entry3.name =='Law Enforcement')
      {
        // this.tLawEnforcement =this.tLawEnforcement +1;
        rLawEnforcement.push(entry2); 
      }else if(entry3.name =='Sanction')
      {
        // this.tCurrentSanctions =this.tCurrentSanctions +1;
        rCurrentSanctions.push(entry2); 
      }else if(entry3.name =='Financial Regulator')
      {
        // this.tFinancialRegulator =this.tFinancialRegulator +1;
        rFinancialRegulator.push(entry2); 
      }else if(entry3.name =='Disqualified Director')
      {
        // this.tDisqualifiedDirector =this.tDisqualifiedDirector +1;
        rDisqualifiedDirector.push(entry2); 
      }else if(entry3.name =='Insolvent')
      {
        // this.tInsolvency =this.tInsolvency +1;
        rInsolvency.push(entry2); 
      }
      
        // console.log(entry3.name); 
    });
  });


  this.AdverseMedia = rAdverseMedia;
  this.PreviousSanctions =rPreviousSanctions;
  this.PEPs = rPEPs;
  this.LawEnforcement = rLawEnforcement;
  this.CurrentSanctions = rCurrentSanctions;
  this.FinancialRegulator = rFinancialRegulator;
  this.DisqualifiedDirector = rDisqualifiedDirector;
  this.Insolvency = rInsolvency;
  this.idv = ridv;

}



getSafeUrl(url)
{
  return this.sanitizer.bypassSecurityTrustResourceUrl(url)
}

  opendocumentlink(id,data)
  {
    
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgvisasrc = true;
      this.iframevisasrc = data;
    }
    else {
      this.imgvisasrc = false;
      this.iframevisasrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

 
  openModal(id: string, data: any,url: any) {  
  
       this.snippet = data;
       this.originalURL =url;
      this.modalService.open(id);
    }
    closeModal(id: string) 
    {
      
      
      this.modalService.close(id);
    }
}
