<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
      <a [routerLink]="['/right-to-work-app-history-list-admin',0]" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Right To Work Request</a>
      <div class="overviewsshow mb-2">
        <div class="righttowork_flex">
          <div class="width50">
            <h5 class="hedingcolor mb-0">Right to Work Checks</h5>
          </div>
          <div class="width50 rightwork">
            <!-- <app-checkcountshow></app-checkcountshow> -->
          </div>
        </div>
      </div>
      <div class="user_profle_section righttowork">
        <div class="profile_box boxshadow">               
          <div class="overviewsshow pb-0 pt-2">
            <div class="userimg">
              <img src="../../../../../assets/img/user_pic_emp.png" class="img">
            </div>
            <div class="name" *ngIf="employeedetails">
              <p>{{employeedetails.fname}} {{employeedetails.mname}} {{employeedetails.lname}}</p>
              <p class="email">{{employeedetails.emailid}}</p>
              <p class="phone">{{employeedetails.phonenumber}}</p>
            </div>
          </div>
        </div>
        <div class="details_box boxshadow">
          <div class="setpshow">
            <progress id="file" [value]="progressbar" max="100"> {{progressbar}}% </progress>
            <ul>
              <li class="active"><p>stage one</p></li>
              <li [ngClass]="{ 'active': stage>2 }"><p>stage two</p></li>
              <li [ngClass]="{ 'active': stage>3 }"><p>stage three</p></li>
              <li [ngClass]="{ 'active': stage>4 }"><p>stage four</p></li>
              <li [ngClass]="{ 'active': stage>5 }"><p>stage five</p></li>
              <li [ngClass]="{ 'active': stage>6 }"><p>stage six</p></li>
            </ul>
          </div>
          <div class="overviewsshow pb-2 pt-0">
            <div *ngIf="stage==1">
              <h4 class="heading mt-4">Please select one action</h4>
              <div class="button_box" >
                <div class="card_btn" [ngClass]="{'active' : isrighttowork ==0 }" (click)="sendrequestchargestage('2', 'rtw')">
                  <img src="../../../../../assets/img/meeting/visa.png">
                  <h5>Physical/Manual</h5>
                  <p>Right to Work Check</p>
                </div>
                <div class="card_btn" [ngClass]="{'active' : isrighttowork ==1}" (click)="sendrequestchargestage('2', 'adjusted_rtw')">
                  <img src="../../../../../assets/img/meeting/passport.png">
                  <h5>Identity Document Validation Technology (IDVT)</h5>
                  <p>(British and Irish citizens only)</p>
                </div>
                <div class="card_btn" [ngClass]="{'active' : isrighttowork ==2}" (click)="sendrequestchargestage('2', 'sharecode_rtw')">
                  <img src="../../../../../assets/img/meeting/passport.png">
                  <h5>Share Code</h5>
                  <p>Right To Work Check</p>
                </div>
              </div>
              <div class="col-lg-4 text-right pl-3">
                <p class="pri_button"  [routerLink]="'/right-to-work-check'"><span><i  aria-hidden="true" class="fa fa-angle-left mr-2"></i> Back</span></p>
              </div>
            </div>
            <div *ngIf="stage==2">
              <h4 class="heading mt-4">Please select one action</h4>
              <div class="button_box mb-4 " >
                <p (click)="sendrequestchargestage('3','in')"  [ngClass]="{'active' : isrighttoworktype ==0 }"  class="boxright">Initial check before employment</p>
                <p (click)="sendrequestchargestage('3','fp')"  [ngClass]="{'active' : isrighttoworktype ==1 }"  class="boxright">Follow-up check on an employee</p>
              </div>
              <div class="col-lg-4 text-right pl-3">
                <a (click)="backstage('1')"> <p class="pri_button"><i  aria-hidden="true" class="fa fa-angle-left mr-2"></i> Back</p></a>
              </div>
            </div>
            <div *ngIf="stage==3" class="p-4">
              <div class="row">
                <div class="col-sm-4">
                  <p class="mb-0"><b>Please select one country</b></p>
                </div>
                <div class="col-lg-8">
                  <input type="text" [(ngModel)]="searchTextcountry" class="form-control" placeholder="Search Your Country Name">
                </div> 
              </div>
              <div class="row  mt-2 d-flex justify-content-start" >
                <ng-container *ngIf="( datalist | filter: {countryname: searchTextcountry }) as countrylist"> 
                  <ng-container  *ngFor="let c of countrylist;  let i=index"> 
                    <ng-container *ngIf="(c.iso == 'IE' || c.iso == 'GB') || isrighttowork !=1 ">
                    
                    <div class="mt-2 country_list"   [ngClass]="{'active' : c.countryid ==countryid }" >
                      <p (click)="sendrequestchargestage('4', c.countryid, '', c.isrighttoworkcountry)" style="cursor: pointer; display: block; width: 100%;" > {{ c.countryname }}</p>
                    </div>             
                </ng-container>
                  

                  <div class="job_list_section visa_request_section width100"  *ngIf="countrylist.length === 0">
                    <div class="job_detils_date">
                      <p class="job_profile">No Record Found</p>
                    </div>
                  </div>
                </ng-container>
                </ng-container>
              </div>
              <div class="mt-4 text-center">
                <p (click)="backstage('2')" class="pri_button p-1"><i  aria-hidden="true" class="fa fa-angle-left ml-2 mr-2"></i> Back</p>
              </div>
            </div>

            <div *ngIf="stage==10">

              <div class="row  mt-4">
                <div class="col-lg-12">
              <h4 class="heading mt-4">Share Code - Right To Work Check </h4>
              <div class="question_box">
               <p>An online right to work check will provide you with a statutory excuse against a civil penalty in the event of illegal working involving the subject of the check.</p> 



               <p>It will not be possible to conduct an online right to work check-in all circumstances, as not all individuals will have an immigration status that can be checked online.</p>
                
                
                
               <p> Currently, the online service supports checks in respect of those who hold:</p>
                
               <ul><li>A Biometric Residence Permit (BRP)</li>
                <li>A Biometric Residence Card  (BRC)</li>
                  <li>Status issued under the EU Settlement Scheme</li>
                    <li>A digital Certificate of Application to the EU Settlement Scheme issued on or before
                30 June 2021</li>
                <li>Status issued under the points-based immigration system</li>
                  <li>British National Overseas (BNO) visa or</li>
                    <li>Frontier workers permit (FWP)</li></ul>
                
                <p><b>Note:</b> You should give employees every opportunity to demonstrate their right to work. You cannot insist that they use the online service or discriminate against those who choose to
                prove their right to work by presenting you with documents which also feature on the list of acceptable document lists.
              </p>

            </div>
              </div>
            </div>
              <div class="row  mt-4">
                <div class="col-lg-6 text-center pl-3">
                  <a (click)="backstage('3')"><p class="pri_button"><i  aria-hidden="true" class="fa fa-angle-left mr-2"></i> Back</p></a>
                </div>   
                <div class="col-lg-6 text-left pl-0" *ngIf="!update">
                  <a (click)="sendrequestchargestage('13','first')" ><p class="pri_button float-right"  style="width: 110px;">Continue  <i aria-hidden="true" class="fa fa-angle-right ml-2"></i></p></a>
                </div>
                <div class="col-lg-6 text-left pl-0" *ngIf="update">
                  <a (click)="sendrequestchargestage('13','')" ><p class="pri_button float-right"  style="width: 110px;">Continue  <i aria-hidden="true" class="fa fa-angle-right ml-2"></i></p></a>
                </div>

              
              </div>
            </div>

            <div *ngIf="stage==13">

              <div class="row  mt-4">
                <div class="col-lg-12">
              <h4 class="heading mt-4">Right to Work update</h4>
              <div class="question_box text-justify">
               <p>The way in which Biometric Residence Card (BRC), Biometric Residence Permit (BRP) and Frontier Worker Permit (FWP) holders evidence their right to work is changing. From 6 April 2022, BRC, BRP and FWP holders will evidence their right to work using the Home Office online service only, presentation of a physical document will no longer be acceptable.</p>
                
               <p>This means that, from 6 April 2022, employers will no longer be able to accept or check a physical BRP, BRC or FWP as valid proof of right to work, even if it shows a later expiry date. </p>
                
               <p>Employers do not need to retrospectively check the status of BRC or BRP holders who were employed up to and including 5 April 2022.  Employers will maintain a statutory excuse against any civil penalty if the initial checks were undertaken in line with the guidance that applied at the time the check was made.</p>  

            </div>
              </div>
            </div>
              <div class="row  mt-5">
                <!-- <div class="col-lg-6 text-center pl-3">
                  <a (click)="backstage('3')"><p class="pri_button"><i  aria-hidden="true" class="fa fa-angle-left mr-2"></i> Back</p></a>
                </div>    -->
                <div class="col-lg-6 text-left pl-0" *ngIf="!update">
                  <a (click)="sendrequestchargestage('11','first')" ><p class="pri_button float-right"  style="width: 110px;">Continue  <i aria-hidden="true" class="fa fa-angle-right ml-2"></i></p></a>
                </div>
                <div class="col-lg-6 text-left pl-0" *ngIf="update">
                  <a (click)="sendrequestchargestage('11','')" ><p class="pri_button float-right"  style="width: 110px;">Continue  <i aria-hidden="true" class="fa fa-angle-right ml-2"></i></p></a>
                </div>

              
              </div>
            </div>


            <div *ngIf="stage==4">
              <h4 class="heading mt-4">You must obtain original documents from List {{qtype}} of acceptable documents. </h4>
              <div class="question_box">
                <div class="question" *ngFor="let que of countrywiseQuestionList;  let i=index">
                  <label class="visaempcheck mr-3 mb-0 d-flex align-items-center"> 
                    <input type="checkbox" [value]="que.id" [checked]="(questionselect.indexOf(que.id) !== -1)?true:false" (change)="questionselection($event,que.id)">
                    <span class="checkmark"></span>
                  </label>   
                  <p>{{que.question}}</p>    
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 text-right pl-3">
                  <a (click)="backstage('3')"><p class="pri_button"><i  aria-hidden="true" class="fa fa-angle-left mr-2"></i> Back</p></a>
                </div>
                <div class="col-lg-6 text-left pl-0" *ngIf="questionselect.length && !update">
                  <a (click)="sendrequestchargestage('5','first')"><p class="pri_button float-right">Next <i aria-hidden="true" class="fa fa-angle-right ml-2"></i></p></a>
                </div>
                <div class="col-lg-6 text-left pl-0" *ngIf="questionselect.length && update">
                  <a (click)="sendrequestchargestage('5','')"><p class="pri_button float-right">Next <i aria-hidden="true" class="fa fa-angle-right ml-2"></i></p></a>
                </div>
              </div>
            </div>    
            
              
            








            <div *ngIf="stage==11" class="p-4">

              <!-- Passport form -->
              
              <form [formGroup]="sharecodeFormGroup" #sharecodeForm="ngForm" class="s12 white" (ngSubmit)="onShareCode(sharecodeForm.value,'custom-passport-1')" *ngIf="sharcodedetails">
                <div role="document" class="formgroup">
                  <!-- <div class="modal-content"> -->
                    <div class="modal-header ">                     
                      <h5 class="modal-title" id="popupLabel">Share Code Details</h5>
                      <!-- <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-passport-1');">
                        <span aria-hidden="true">&times;</span>
                      </button> -->
                      <!-- <button type="button" *ngIf="update" (click)="sendrequestchargestage('4', currentCompany.countryid, '', currentCompany.isrighttoworkcountry)" class="pri_button float-right">Update</button> -->
                    </div>
                    <div class="modal-body no-border">
                      <div class="container">

                        <div class="row">
                          <div class="form-group col-lg-6">
                            <label>Company Name*</label>
                            <input type="text" formControlName="companyname" class="" name="companyname" id="companyname"
                              class="form-control" [ngClass]="{ 'is-invalid': submitted && k.companyname.errors }" ngModel="{{ sharcodedetails.companyname }}"
                              value="{{ sharcodedetails.companyname }}" maxlength="50">
                            <div *ngIf="submitted && k.companyname.errors" class="invalid-feedback">
                              <div *ngIf="k.companyname.errors.required">Please enter company name</div>
                            </div>
                          </div>
                          <div class="form-group col-lg-6">
                            <label>NI Number*</label>
                            <input type="text" formControlName="ninumber" class="" name="ninumber" id="ninumber"
                              class="form-control" [ngClass]="{ 'is-invalid': submitted && k.ninumber.errors }" ngModel="{{ sharcodedetails.ninumber }}"
                              value="{{ sharcodedetails.ninumber }}" maxlength="50">
                            <div *ngIf="submitted && k.ninumber.errors" class="invalid-feedback">
                              <div *ngIf="k.ninumber.errors.required">Please enter NI number</div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="form-group col-lg-6">
                            <label>Share code *</label>
                            <input type="text" formControlName="sharecode" class="" name="sharecode" id="sharecode"
                              class="form-control" [ngClass]="{ 'is-invalid': submitted && k.sharecode.errors }" ngModel="{{ sharcodedetails.sharecode }}"
                              value="{{ sharcodedetails.sharecode }}" maxlength="50">
                            <div *ngIf="submitted && k.sharecode.errors" class="invalid-feedback">
                              <div *ngIf="k.sharecode.errors.required">Please enter Share Code</div>
                            </div>
                          </div>
                          <div class="form-group col-lg-6">
                            <label>Date of Birth *</label>
                            <mat-form-field class="datecontrol">
                              <input matInput [matDatepicker]="picker1" name="dateofbirth" id="dateofbirth" 
                                [ngClass]="{ 'is-invalid': submitted && k.dateofbirth.errors }" formControlName="dateofbirth" 
                                class="form-control" ngModel='{{ sharcodedetails.dateofbirth }}' [value]="sharcodedetails.dateofbirth">
                              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                              <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="submitted && k.dateofbirth.errors" class="invalid-feedback">
                              <div *ngIf="k.dateofbirth.errors.required">Please enter Date of Birth</div>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>

                    <div class="modal-footer no-border ">
                      <input type="submit" class="btn btn-primary com_btn" name="updateform"  value="Next >" />
                      <!-- <input type="button" class="btn btn-primary" name="updateform" value="Cancel"   (click)="closeModal('custom-passport-1');"/> -->
                    </div>
                </div>
                </form>
                </div>



                <div *ngIf="stage==12">
                  <h4 class="heading mt-4">Step 1 </h4>
                  <div class="question_box ">
                    <div class="question text-center questyh">
                      <p>Please follow this link and download the report: </p>    
                      <p><a href="https://www.gov.uk/view-right-to-work">https://www.gov.uk/view-right-to-work</a></p>
                    </div>
                  </div>

                  <h4 class="heading mt-4">Step 2 </h4>
                  <div class="row">
                    <div class="col-lg-12">
                      <label>Upload online Right to Work check report</label>
                      <div class="input-group mb-3" (click)="filepdfdoc.click()">
                        <div class="custom-file">
                          <input type="file" #filepdfdoc (change)="selectPdfFile($event)">
                          <label class="custom-file-label">{{docpdffilename}}</label>
                        </div>
                      </div>
                      <div class="invalid-feedback ng-star-inserted">
                        <!-- <div class="" *ngIf="filebackrequirederror">Back side image is required</div> -->
                        <div *ngIf="pdfbackfilesizeerror">File size must be less than 2 MB</div>
                        <div *ngIf="pdfbackfileformaterror">Please choose a valid file format (jpg, png, pdf)</div>
                      </div>
                    </div>  
                  </div>


                  <div class="row">
                    <div class="col-lg-6 text-right pl-3">
                      <a (click)="backstage('11')"><p class="pri_button"><i  aria-hidden="true" class="fa fa-angle-left mr-2"></i> Back</p></a>
                    </div>
                   
                    <div class="col-lg-6 text-left pl-0" *ngIf="confirmdoc">
                      <a (click)="sendrequestchargestage('6','')"><p class="pri_button float-right">Next <i aria-hidden="true" class="fa fa-angle-right ml-2"></i></p></a>
                    </div>
                  </div>
                </div>    



            <div *ngIf="stage==5" class="p-4">

              <!-- Passport form -->
              
              <form [formGroup]="passportFormgroup" #passportForm="ngForm" class="s12 white" (ngSubmit)="onSubmitPassport(passportForm.value,'custom-passport-1')" *ngIf="passportDetail && openDocumentForm==1">
                <div role="document" class="formgroup">
                  <!-- <div class="modal-content"> -->
                    <div class="modal-header ">
                      <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" ngModel="{{passportDetail.employeepassdeatilid}}">
                      <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid"
                        ngModel="{{passportDetail.employeeid}}">
                      <h5 class="modal-title" id="popupLabel">Passport Details</h5>
                      <!-- <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-passport-1');">
                        <span aria-hidden="true">&times;</span>
                      </button> -->
                      <button type="button" *ngIf="update" (click)="sendrequestchargestage('4', currentCompany.countryid, '', currentCompany.isrighttoworkcountry)" class="pri_button float-right">Update</button>
                    </div>
                    <div class="modal-body no-border">
                      <div class="container">
                        <div class="row">
                          <div class="form-group col-lg-6">
                            <label>Passport No. *</label>
                            <input type="passportnumber" formControlName="passportnumber" class="" name="passportnumber" id="passportnumber"
                              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.passportnumber.errors }" ngModel="{{ passportDetail.passportnumber }}"
                              value="{{ passportDetail.passportnumber }}" maxlength="50">
                            <div *ngIf="submitted && f.passportnumber.errors" class="invalid-feedback">
                              <div *ngIf="f.passportnumber.errors.required">Please enter Passport No.</div>
                            </div>
                          </div>
                          <div class="form-group col-lg-6">
                            <label>Nationality *</label>
                            <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid"
                              class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }"
                              ngModel="{{ passportDetail.nationalityid }}">
                              <option value="">Select Nationality</option>
                              <option *ngFor="let ad3 of countrylist" value='{{ ad3.countryid }}' [selected]="ad3.countryid == passportDetail.nationalityid">{{
                                ad3.countryname }}</option>
                            </select>
                            <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                              <div *ngIf="f.nationalityid.errors.required">Please select Nationality</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-6">
                            <label>Passport Issue Date *</label>
                            <mat-form-field class="datecontrol">
                              <input matInput [matDatepicker]="picker1" name="passportissue" id="passportissue" 
                                [ngClass]="{ 'is-invalid': submitted && f.passportissue.errors }" formControlName="passportissue" 
                                class="form-control" ngModel='{{ passportDetail.passportissue }}' [value]="passportDetail.passportissue">
                              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                              <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="submitted && f.passportissue.errors" class="invalid-feedback">
                              <div *ngIf="f.passportissue.errors.required">Please enter Passport issue date</div>
                            </div>
                          </div>
                          <div class="form-group col-lg-6">
                            <label>Passport Expiry Date *</label>
                            <mat-form-field class="datecontrol">
                              <input matInput [matDatepicker]="picker2" id="passportexpiry" name="passportexpiry" [min]="passportFormgroup.controls.passportissue.value"
                                [ngClass]="{ 'is-invalid': submitted && f.passportexpiry.errors }" formControlName="passportexpiry" 
                                class="form-control"  ngModel='{{ passportDetail.passportexpiry }}' [value]="passportDetail.passportexpiry">
                              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                              <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="submitted && f.passportexpiry.errors" class="invalid-feedback">
                              <div *ngIf="f.passportexpiry.errors.required">Please enter Passport expiry date</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-6">
                            <label>Place of Issue of Passport *</label>
                            <input type="placeofissue" formControlName="placeofissue" class="" id="placeofissue" maxlength="50"
                              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.placeofissue.errors }" 
                              name="placeofissue" ngModel='{{ passportDetail.placeofissue }}' value="{{ passportDetail.placeofissue }}">
                            <div *ngIf="submitted && f.placeofissue.errors" class="invalid-feedback">
                              <div *ngIf="f.placeofissue.errors.required">Please enter Place of Issue</div>
                            </div>
                          </div>
                          <div class="form-group col-lg-6">
                            <label>Front Side Passport Upload*</label>
                            <div class="mb-3">
                              <div class="custom-file" (click)="frontpassport.click()">
                                <input type="file" #frontpassport (change)="selectPassportFrontFile($event)">
                                <label class="custom-file-label">{{passportfrontfilename}}</label>
                              </div>
                              <div class="col-lg-12"></div>
                            </div>
                            <div class="invalid-feedback" *ngIf="passportfrontfilerequirederror == true">Front side Passport is required.</div>
                            <div class="invalid-feedback" *ngIf="passportfrontfilesizeerror == true">File size must be less than 2 MB</div>
                            <div class="invalid-feedback" *ngIf="passportfrontfileformaterror == true">Please choose a valid file format (jpg, png)</div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-6">
                            <label>Back Side Passport Upload</label>
                            <div class="mb-3">
                              <div class="custom-file" (click)="backpassport.click()">
                                <input type="file" #backpassport (change)="selectPassportBackFile($event)">
                                <label class="custom-file-label">{{passportbackfilename}}</label>
                              </div>
                              <div class="col-lg-12"></div>
                            </div>
                            <div class="invalid-feedback" *ngIf="passportbackfilesizeerror == true">File size must be less than 2 MB</div>
                            <div class="invalid-feedback" *ngIf="passportbackfileformaterror == true">Please choose a valid file format
                              (jpg, png)</div>
                          </div>
                          <div class="form-group col-lg-6" *ngIf="!profileimage">
                            <label>Upload Selfie*</label>
                            <div class="mb-3">
                              <div class="custom-file" (click)="passportprofile.click()">
                                <input type="file" #passportprofile (change)="selectPassportProfile($event)">
                                <label class="custom-file-label">{{passportprofilename}}</label>
                              </div>
                              <div class="col-lg-12"></div>
                            </div>
                            <div class="invalid-feedback" *ngIf="passportprofilerequirederror == true">Selfie is required.</div>
                            <div class="invalid-feedback" *ngIf="passportprofilesizeerror == true">File size must be less than 2 MB</div>
                            <div class="invalid-feedback" *ngIf="passportprofileformaterror == true">Please choose a valid file format
                              (jpg, png)</div>
                          </div>
                        </div>

                        <!-- <div class="row">
                          <div class="form-group col-lg-6">
                            <label>NI Number *</label>
                            <input type="text" formControlName="ninumber" class="" id="ninumber" maxlength="50"
                              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.ninumber.errors }" 
                              name="ninumber" ngModel='{{ passportDetail.ninumber }}' value="{{ passportDetail.ninumber }}">
                            <div *ngIf="submitted && f.ninumber.errors" class="invalid-feedback">
                              <div *ngIf="f.ninumber.errors.required">Please enter Place of Issue</div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <div class="modal-footer no-border ">
                      <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="Next >" />
                      <!-- <input type="button" class="btn btn-primary" name="updateform" value="Cancel"   (click)="closeModal('custom-passport-1');"/> -->
                    </div>
                  </div>
                <!-- </div> -->
              </form>
              <!-- Passport form -->

              <!-- Visa form -->
              
              <form [formGroup]="visaFormgroup" #visaForm="ngForm" class="s12 white" (ngSubmit)="onSubmitVisa(visaForm.value)" *ngIf="visaDetail && openDocumentForm==2">
                <div role="document" class="formgroup">
                  <div class="modal-content no-border">
                    <div class="modal-header">
                      <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" 
                      id="employeeid" [(ngModel)]="visaDetail.employeeid">
                      <input type="hidden" formControlName="id" class="form-control" required name="id" id="id"
                      [(ngModel)]="visaDetail.employeevisadeatilid">
                      <h5 class="modal-title" id="popupLabel">Biometric Residence Permit Details</h5>
                      <!-- <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-visa-1');">
                        <span aria-hidden="true">&times;</span>
                      </button> -->
                      <button type="button" *ngIf="update" (click)="sendrequestchargestage('4', currentCompany.countryid, '', currentCompany.isrighttoworkcountry)" class="pri_button float-right">Update</button>
                    </div>
                    <div class="modal-body no-border">
                      <div class="container">
                        <div class="row">
                          <div class="form-group col-lg-6">
                            <label>BRP No. *</label>
                            <input type="visanumber" formControlName="visanumber" class="" name="visanumber" id="visanumber" 
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && v.visanumber.errors }" 
                            [(ngModel)]="visaDetail.visanumber">
                            <div *ngIf="submitted && v.visanumber.errors" class="invalid-feedback">
                              <div *ngIf="v.visanumber.errors.required">Please enter BRP No</div>
                            </div>
                          </div>
                          <div class="form-group col-lg-6">
                            <label>BRP Type *</label>
                            <input type="visatype" formControlName="visatype" class="" name="visatype" id="visatype" 
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && v.visatype.errors }" 
                            [(ngModel)]="visaDetail.visatype">
                            <div *ngIf="submitted && v.visatype.errors" class="invalid-feedback">
                              <div *ngIf="v.visatype.errors.required">Please enter BRP Type</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-6">
                            <label>Start Date *</label>
                            <mat-form-field class="datecontrol">
                              <input matInput [matDatepicker]="picker1" name="visastart" formControlName="visastart"
                                class="form-control" id="visastart" [(ngModel)]="visaDetail.visastart">
                              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                              <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                          </div>
                          <div class="form-group col-lg-6">
                            <label>Expiry Date *</label>
                            <mat-form-field class="datecontrol">
                              <input matInput [matDatepicker]="picker2" id="visaexpiry" name="visaexpiry" [min]="visaFormgroup.controls.visastart.value"
                              formControlName="visaexpiry" class="form-control" [(ngModel)]="visaDetail.visaexpiry">
                              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                              <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-6">
                            <label>BRP Issue Country *</label>
                            <select formControlName="nationalityid" name="nationalityid" id="nationalityid"
                              class="form-control input-lg width100" [ngClass]="{ 'is-invalid': submitted && v.nationalityid.errors }"
                              [(ngModel)]="visaDetail.nationalityid">
                              <option value="">Select BRP Country </option>
                              <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                            </select>
                            <div *ngIf="submitted && v.nationalityid.errors" class="invalid-feedback">
                              <div *ngIf="v.nationalityid.errors.required">Please select BRP issue country</div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <label>Front Side BRP Upload*</label>
                            <div class="input-group mb-3" (click)="visafront.click()">
                              <div class="custom-file">
                                <input type="file" #visafront (change)="selectVisaFrontFile($event)">
                                <label class="custom-file-label">{{visafrontfilename}}</label>
                              </div>
                            </div>
                            <div class="invalid-feedback" *ngIf="visafrontfilerequirederror == true">Front Side BRP is required.</div>
                            <div class="invalid-feedback" *ngIf="visafrontfilesizeerror == true">File size must be less than 2 MB</div>
                            <div class="invalid-feedback" *ngIf="visafrontfileformaterror == true">Please choose a valid file format
                              (jpg, png)</div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6">
                            <label>Back Side BRP Upload</label>
                            <div class="input-group mb-3" (click)="visaback.click()">
                              <div class="custom-file">
                                <input type="file" #visaback (change)="selectVisaBackFile($event)">
                                <label class="custom-file-label">{{visabackfilename}}</label>
                              </div>
                            </div>
                            <div class="invalid-feedback" *ngIf="visabackfilesizeerror == true">File size must be less than 2 MB</div>
                            <div class="invalid-feedback" *ngIf="visabackfileformaterror == true">Please choose a valid file format
                              (jpg, png)</div>
                          </div>
                          <div class="col-lg-6" *ngIf="!profileimage">
                            <label>Upload Selfie*</label>
                            <div class="input-group mb-3" (click)="visaprofile.click()">
                              <div class="custom-file">
                                <input type="file" #visaprofile (change)="selectVisaProfile($event)">
                                <label class="custom-file-label">{{visaprofilename}}</label>
                              </div>
                            </div>
                            <div class="invalid-feedback" *ngIf="visaprofilerequirederror == true">Selfie is required.</div>
                            <div class="invalid-feedback" *ngIf="visaprofilesizeerror == true">File size must be less than 2 MB</div>
                            <div class="invalid-feedback" *ngIf="visaprofileformaterror == true">Please choose a valid file format
                              (jpg, png)</div>
                          </div>
                        </div>

                        <!-- <div class="row">
                          <div class="form-group col-lg-6">
                            <label>NI Number *</label>
                            <input type="text" formControlName="ninumber" class="" id="ninumber" maxlength="50"
                              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.ninumber.errors }" 
                              name="ninumber" ngModel='{{ passportDetail.ninumber }}' value="{{ passportDetail.ninumber }}">
                            <div *ngIf="submitted && f.ninumber.errors" class="invalid-feedback">
                              <div *ngIf="f.ninumber.errors.required">Please enter Place of Issue</div>
                            </div>
                          </div>
                        </div> -->

                      </div>
                    </div>
                    <div class="modal-footer no-border">
                      <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="Next >" />
                      <!-- <input type="button" class="btn btn-primary" name="updateform" value="Cancel"   (click)="closeModal('custom-visa-1');"/> -->
                    </div>
                  </div>
                </div>
              </form>
              <!-- Visa form -->

              <!-- Document form -->
              
              <form [formGroup]="idproofFormgroup" #idproofForm="ngForm" class="s12 white" (ngSubmit)="onSubmitDocument(idproofForm.value,'custom-idproof-1')" *ngIf="documentDetail && openDocumentForm==3">
                <div role="document" class="formgroup">
                  <div class="modal-content no-border">
                    <div class="modal-header ">
                      <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="documentDetail.employeeid">
                      <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="documentDetail.employeeidproofdeatilid">
                      <h5 class="modal-title" id="popupLabel">{{docname}} Details</h5>
                      <!-- <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-idproof-1');">
                        <span aria-hidden="true">&times;</span>
                      </button> -->
                      <button type="button" *ngIf="update" (click)="sendrequestchargestage('4', currentCompany.countryid, '', currentCompany.isrighttoworkcountry)" class="pri_button float-right">Update</button>
                    </div>
                    <div class="modal-body no-border">
                      <div class="container">
                        <div class="row">
                          <div class="form-group col-lg-6">
                            <label>{{docname}} Number*</label>
                            <input type="idnumber" formControlName="idnumber" name="idnumber" id="idnumber" 
                              class="form-control" [ngClass]="{ 'is-invalid': submitted && d.idnumber.errors }" 
                              [ngModel]="documentDetail.idnumber" maxlength="30">
                            <div *ngIf="submitted && d.idnumber.errors" class="invalid-feedback">
                              <div *ngIf="d.idnumber.errors.required">Please enter {{docname}} Number</div>
                            </div>
                          </div>
                          <div class="form-group col-lg-6">
                            <label>Document Type*</label>
                            <select style="width:100%;" formControlName="idtype" name="idtype" id="idtype" 
                              class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && d.idtype.errors }" 
                              [ngModel]="documentDetail.idtype" [attr.disabled]="true">
                              <option [value]="">Select Document Type</option>
                              <option *ngFor="let ad3 of documenttype" [value]="ad3.id">{{ ad3.documentname }}</option>
                            </select>
                            <div *ngIf="submitted && d.idtype.errors" class="invalid-feedback">
                              <div *ngIf="d.idtype.errors.required">Please select document type</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-6">
                            <label>Expiry Date*</label>
                            <mat-form-field class="datecontrol">
                              <input matInput [matDatepicker]="picker2" id="docexpiry" name="docexpiry"
                                formControlName="docexpiry" [ngClass]="{ 'is-invalid': submitted && d.docexpiry.errors }" 
                                class="form-control noborder-invalid datecontrol" [ngModel]="documentDetail.docexpiry">
                              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                              <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="submitted && d.docexpiry.errors" class="invalid-feedback">
                              <div *ngIf="d.docexpiry.errors.required">Please enter Expiry date</div>
                            </div>
                          </div>
                          <div class="form-group col-lg-6">
                            <label>Issuing Country*</label>
                            <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && d.nationalityid.errors }" [ngModel]="documentDetail.nationalityid">
                              <option value="">Select Country </option>
                              <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                            </select>
                            <div *ngIf="submitted && d.nationalityid.errors" class="invalid-feedback">
                              <div *ngIf="d.nationalityid.errors.required">Please select issuing country</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6">
                            <label>Front Side ID Upload*</label>
                            <div class="input-group mb-3" (click)="docfrontfile.click()">
                              <div class="custom-file">
                                <input type="file" #docfrontfile (change)="selectDocFrontFile($event)">
                                <label class="custom-file-label">{{docfrontfilename}}</label>
                              </div>
                            </div>
                            <div class="invalid-feedback ng-star-inserted">
                              <div *ngIf="docfrontfilerequirederror">Please upload Front side ID</div>
                              <div *ngIf="docfrontfilesizeerror">File size must be less than 2 MB</div>
                              <div *ngIf="docfrontfileformaterror">Please choose a valid file format (jpg, png)</div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <label>Back Side ID Upload</label>
                            <div class="input-group mb-3" (click)="filebackdoc.click()">
                              <div class="custom-file">
                                <input type="file" #filebackdoc (change)="selectDocBackFile($event)">
                                <label class="custom-file-label">{{docbackfilename}}</label>
                              </div>
                            </div>
                            <div class="invalid-feedback ng-star-inserted">
                              <!-- <div class="" *ngIf="filebackrequirederror">Back side image is required</div> -->
                              <div *ngIf="docbackfilesizeerror">File size must be less than 2 MB</div>
                              <div *ngIf="docbackfileformaterror">Please choose a valid file format (jpg, png)</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6" *ngIf="!profileimage">
                            <label>Upload Selfie*</label>
                            <div class="input-group mb-3" (click)="filedocprofile.click()">
                              <div class="custom-file">
                                <input type="file" #filedocprofile (change)="selectDocProfile($event)">
                                <label class="custom-file-label">{{docprofilename}}</label>
                              </div>
                            </div>
                            <div class="invalid-feedback ng-star-inserted">
                              <div *ngIf="docprofilerequirederror">Please upload Selfie</div>
                              <div *ngIf="docprofilesizeerror">File size must be less than 2 MB</div>
                              <div *ngIf="docprofileformaterror">Please choose a valid file format (jpg, png)</div>
                            </div>
                          </div>
                            <!-- <div class="form-group col-lg-6">
                              <label>NI Number *</label>
                              <input type="text" formControlName="ninumber" class="" id="ninumber" maxlength="50"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.ninumber.errors }" 
                                name="ninumber" ngModel='{{ passportDetail.ninumber }}' value="{{ passportDetail.ninumber }}">
                              <div *ngIf="submitted && f.ninumber.errors" class="invalid-feedback">
                                <div *ngIf="f.ninumber.errors.required">Please enter Place of Issue</div>
                              </div>
                            </div> -->
                         
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer no-border">
                      <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                      <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Next" />
                      <!-- <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-idproof-1');" /> -->
                    </div>
                  </div>
                </div>
              </form>
              <!-- Document form -->

              <!-- <div class="col-lg-4 text-left pl-0">
                  <p (click)="sendrequestchargestage('1')" class="pri_button p-1"><i  aria-hidden="true" class="fa fa-angle-left ml-2 mr-2"></i> Previous</p>
              </div>
              <h4 class="heading">Please select one Action</h4>
              <div class="button_box" >
                <div class="card_btn" (click)="sendrequestchargestage('7', '', 'passport')">
                  <img src="../../../../../assets/img/meeting/visa.png">
                  <p>Can the holder present the original valid passport in person?</p>
                </div>
                <div class="card_btn" *ngIf="rtw_country==0" (click)="sendrequestchargestage('7', '', 'visa')">
                  <img src="../../../../../assets/img/meeting/passport.png">
                  <p>Can the holder present the original valid visa in person?</p>
                </div>
                <div class="card_btn" (click)="sendrequestchargestage('7','','document')">
                  <img src="../../../../../assets/img/meeting/passport.png">
                  <p>Can the individual present any of the following original document in person?</p>
                </div>
              </div> -->
            </div>


            <div class="modal-body" *ngIf="stage==6">


              <div class="refnotification topnavright" *ngIf="hidediv">
                <div class="refnotificationbox">
                    <div class="alignref">
                      <span>Problem with the uploaded image - edges of the document are cropped or cut off.</span>
                      <span><a style="cursor: pointer;" (click)="hidedivform()">Hide</a></span>
                    </div>
                    <p>Select the respective checkbox to resend the request for uploading documents again.</p>
                </div>
            </div>

            <div  *ngIf="employeecompanydetails">
              <div class="job_list_section idproof_request_section visa_request_section pb-2 pt-2 oddeven"  >
                <div class="job_detils_date width18 newcheckboxrighttowork">                           
                  <div class="visa_user_details">
                    <p class="job_address">Company Name</p>
                    <h4>{{employeecompanydetails.company.companyname}}</h4>                                 
                  </div>
                </div>
                <div class="job_detils_date width18">
                  <p class="job_address">Client Name</p>
                  <p class="job_profile">{{ employeecompanydetails.company.clientname }}</p>
                </div>
                <div class="job_detils_date width18">
                  <p class="job_address">Created By</p>
                  <p class="job_profile">{{employeecompanydetails.fname }} {{employeecompanydetails.lname }}</p>
                </div>
                <div class="job_detils_date width18">
                  <p class="job_address">Created Email</p>
                  <p class="job_profile">{{employeecompanydetails.emailid}}</p>
                </div>
                <div class="job_detils_date width10">
                  <p class="job_address">Created Phone</p>
                  <p class="job_profile">{{employeecompanydetails.phonenumber}}</p>
                </div>
              
              </div>
            </div>





              <div  *ngFor="let passport of passportpageOfItems ; let i=index ;">
                <div class="job_list_section idproof_request_section visa_request_section pb-2 pt-2 oddeven"  >
                  <div class="job_detils_date width18 newcheckboxrighttowork">
                  <ng-container>
                      <input type="checkbox" name="Insolvency" id="check{{passport.employeepassdeatilid}}"                       
                      (change)="resendpassport($event.target.checked,'100')" value="{{passport.employeepassdeatilid}}" class="activity">
                      <label for="check{{passport.employeepassdeatilid}}"></label>
                  </ng-container>

                    <div class="visa_user_details">
                      <p class="job_address">Name</p>
                      <h4>{{passport.employee.fname}} {{passport.employee.mname}} {{passport.employee.lname}}</h4>                                 
                    </div>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">Phone Number</p>
                    <p class="job_profile">{{ passport.employee.phonenumber }}</p>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">ID Number</p>
                    <p class="job_profile">{{passport.passportnumber}}</p>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">ID Type</p>
                    <p class="job_profile">Passport</p>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">Date of Issue</p>
                    <p class="job_profile">{{passport.passportissue | date: 'dd MMM yyyy'}}</p>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">Date of Expiry</p>
                    <p class="job_profile">{{passport.passportexpiry | date: 'dd MMM yyyy'}}</p>
                  </div>
                  
                  <div class="job_detils_date aligncenter width15 verify_img">
                    <div class="fshow">
                      <div class="visa_user_pic back">
                        <a (click)="openModal3('pdf-viewer-visa',passport.passportdocname)">  
                        <img *ngIf="passport.passportdocname" src="{{passport.passportdocname}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}"></a>
                        <img  *ngIf="!passport.passportdocname" src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                      </div>
                      <div class="tooltip_visa back"><p>Front image</p></div>
                    </div>
                    <div class="fshow">
                      <div class="visa_user_pic selfie">
                        <a (click)="openModal3('pdf-viewer-visa',passport.passportbackdocname)"> 
                          <img *ngIf="passport.passportbackdocname" src="{{passport.passportbackdocname}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                        </a>
                        <img *ngIf="!passport.passportbackdocname" src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                      </div>
                      <div class="tooltip_visa selfie"><p>Back image</p></div>
                    </div>
                    <div class="fshow">
                      <div class="visa_user_pic selfie">
                        <a (click)="openModal3('pdf-viewer-visa',passport.passportprofileimage)">  
                          <img *ngIf="passport.passportprofileimage" src="{{passport.passportprofileimage}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                        </a>
                        <img *ngIf="!passport.passportprofileimage"  src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                      </div>
                      <div class="tooltip_visa selfie"><p>Selfie</p></div>
                    </div>
                  </div>

                  <div class="width100 aligncenter">
                    <div class="pull-left">                                 
                      <p class="badge badge-warning" *ngIf="passport.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Pending by AI </p>
                      <p class="badge badge-success" *ngIf="passport.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                      <p class="badge badge-error" *ngIf="passport.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                    </div> 
                  </div>
                </div>
              </div>



              <div *ngFor="let visas of visapageOfItems; let i=index ;"  >
                <div class="job_list_section idproof_request_section visa_request_section pb-2 pt-2 oddeven">
                  <div class="job_detils_date width18 newcheckboxrighttowork">

                  <ng-container>
                      <input type="checkbox" name="Insolvency" id="check{{visas.employeevisadeatilid}}"                       
                      (change)="resendvisa($event.target.checked,'101')" value="{{visas.employeevisadeatilid}}" class="activity">
                      <label for="check{{visas.employeevisadeatilid}}"></label>
                  </ng-container>


                    <div class="visa_user_details">
                      <p class="job_address">Name</p>
                      <h4>{{visas.employee.fname}} {{visas.employee.mname}} {{visas.employee.lname}}</h4>                                 
                    </div>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">Phone Number</p>
                    <p class="job_profile">{{ visas.employee.phonenumber }}</p>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">ID Proof Number</p>
                    <p class="job_profile">{{visas.visanumber}}</p>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">ID Type</p>
                    <p class="job_profile">BRP</p>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">Date of Issue</p>
                    <p class="job_profile">{{visas.visastart | date: 'dd MMM yyyy'}}</p>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">Valid Until</p>
                    <p class="job_profile">{{visas.visaexpiry | date: 'dd MMM yyyy'}}</p>
                  </div>
                    
                  <div class="job_detils_date aligncenter width15 verify_img">
                    <div class="fshow">
                      <div class="visa_user_pic back">
                        <a (click)="openModal3('pdf-viewer-visa',visas.visadocname)">  
                          <img *ngIf="visas.visadocname" src="{{visas.visadocname}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                        </a>
                        <img *ngIf="!visas.visadocname" src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                      </div>
                      <div class="tooltip_visa back"><p>Front image</p></div>
                    </div>
                    <div class="fshow">
                      <div class="visa_user_pic selfie">
                        <a (click)="openModal3('pdf-viewer-visa',visas.visabackdocname)">   
                          <img *ngIf="visas.visabackdocname" src="{{visas.visabackdocname}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                        </a>
                        <img *ngIf="!visas.visabackdocname" src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                      </div>
                      <div class="tooltip_visa selfie"><p>Back image</p></div>
                    </div>
                    <div class="fshow">
                      <div class="visa_user_pic selfie">
                        <a (click)="openModal3('pdf-viewer-visa',visas.visaprofileimage)">  
                          <img *ngIf="visas.visaprofileimage" src="{{visas.visaprofileimage}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                        </a>
                        <img *ngIf="!visas.visaprofileimage" src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                      </div>
                      <div class="tooltip_visa selfie"><p>Selfie</p></div>
                    </div>
                  </div>

                  <div class="width100 aligncenter">
                    <div class="pull-left">     
                      <p class="badge badge-warning" *ngIf="visas.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Pending by AI </p>
                      <p class="badge badge-success" *ngIf="visas.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                      <p class="badge badge-error" *ngIf="visas.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="width100 aligncenter text-right">

                </div>
              </div>

              <div *ngFor="let idproofs of docpageOfItems; let i=index ;" >
                <div class="job_list_section idproof_request_section visa_request_section pb-2 pt-2 oddeven">
                  <div class="job_detils_date width18 newcheckboxrighttowork">

                    <ng-container>
                      <input type="checkbox" name="Insolvency" id="check{{idproofs.employeeidproofdeatilid}}"                       
                      (change)="changeidproofdetails($event.target.checked,idproofs.idtype,idproofs.employeeidproofdeatilid)" value="{{idproofs.employeeidproofdeatilid}}" class="activity">
                      <label for="check{{idproofs.employeeidproofdeatilid}}"></label>
                  </ng-container>

                    <div class="visa_user_details">
                      <p class="job_address">Name</p>
                      <h4>{{idproofs.employee.fname}} {{idproofs.employee.mname}} {{idproofs.employee.lname}}</h4>                                 
                    </div>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">Phone Number</p>
                    <p class="job_profile">{{ idproofs.employee.phonenumber }}</p>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">ID Proof Number</p>
                    <p class="job_profile">{{idproofs.idnumber}}</p>
                  </div>
                  <div class="job_detils_date width22">
                    <p class="job_address">ID Type</p>
                    <p class="job_profile">{{idproofs.documenttype.documentname}}   </p>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">Expiry Date</p>
                    <p class="job_profile">{{idproofs.docexpiry | date: 'dd MMM yyyy'}}</p>
                  </div>
                  <div class="job_detils_date aligncenter width15 verify_img">
                    <div class="fshow">
                      <div class="visa_user_pic back">
                        <a (click)="openModal3('pdf-viewer-visa',idproofs.iddocName)">  
                          <img *ngIf="idproofs.iddocName" src="{{idproofs.iddocName}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                        </a>
                        <img *ngIf="!idproofs.iddocName"  src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                      </div>
                      <div class="tooltip_visa back"><p>Front image</p></div>
                    </div>
                    <div class="fshow">
                      <div class="visa_user_pic selfie">
                        <a (click)="openModal3('pdf-viewer-visa',idproofs.idbackdocName)">  
                          <img *ngIf="idproofs.idbackdocName" src="{{idproofs.idbackdocName}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                        </a>
                        <img  *ngIf="!idproofs.idbackdocName" src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                      </div>
                      <div class="tooltip_visa selfie"><p>Back image</p></div>
                    </div>
                    <div class="fshow">
                      <div class="visa_user_pic selfie">
                        <a (click)="openModal3('pdf-viewer-visa',idproofs.idprofileimage)">
                          <img *ngIf="idproofs.idprofileimage" src="{{idproofs.idprofileimage}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                        </a>
                        <img  *ngIf="!idproofs.idprofileimage" src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                      </div>
                      <div class="tooltip_visa selfie"><p>Selfie</p></div>
                    </div>
                  </div>
                  <div class="width100 aligncenter">
                    <div class="pull-left">              
                      <p class="badge badge-warning" *ngIf="idproofs.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Pending by AI </p>
                      <p class="badge badge-success" *ngIf="idproofs.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                      <p class="badge badge-error" *ngIf="idproofs.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                    </div> 
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="isrighttowork == 1 && updatebutton == true">  
                <div class="col-lg-12 col-md-12 col-sm-12 text-right">
                  <button class="btn com_btn btn-success small_btn" (click)="updaterighttoworkdocument()" type="button">
                    Resend document request
                  </button>
                </div>
              </div>
              <div class="row">  
                <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let vs of questionlist1">
                  <div class="box_quest  border_left bgcolor">
                      <p>{{ vs.question }}</p>
                      <div class="radio_box">
                          <label class="container_radio">Yes
                              <input type="radio" name="radio{{ vs.id }}" (change)="onQuestionSelection(vs.id,1)">
                              <span class="checkmark"></span>
                            </label>
                          <label class="container_radio">No
                              <input type="radio" name="radio{{ vs.id }}"  (change)="onQuestionSelection(vs.id,2)">
                              <span class="checkmark"></span>
                            </label>
                          <label class="container_radio">N/A
                              <input type="radio" name="radio{{ vs.id }}"  (change)="onQuestionSelection(vs.id,3)">
                              <span class="checkmark"></span>
                            </label>                     
                      </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="questionError">              
                <div class="col-lg-12 text-center " style="margin-top: 30px;">
                  <span class="red" >Please answer all the questions.</span>
                </div> 
              </div>

              <div class="row">              
                <div class="col-lg-12 text-center " style="margin-top: 40px;">
                  <!-- <h5 class="text-center">your request send successfully</h5> -->
                  <button class="btn com_btn btn-success small_btn" (click)="completeprocess()" type="button" *ngIf="showfinish">
                    Finish
                  </button>
                </div> 
              </div>
              
            </div>




            <div class="modal-body" *ngIf="stage==17">
              <div class="container">
                <div class="main-banner">
                  <div class="overviewsshow pt-2">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="tankushow">
                          <div class="detailstext righttowork">
                              <h1>Your right to work is under process!</h1>
                              <p> You do not need to take any further action.</p>
                              <p>If you have correctly carried out all the steps, you will have an excuse against liability for a civil penalty if the verified person is found working for you illegally.</p>
                          </div>
                          <img alt="shape" src="../../../../../assets/img/thanks_new.png">
                        </div>
                        <a [routerLink]="['/right-to-work-check']" class="backlist mb-4 mr-0 float-left" style="padding: 0px;"> 
                          <button class="btn com_btn btn-success small_btn"  type="button" >
                            Go to Right to Work Check
                         </button>
                         </a>
                      </div>
                    </div>
                  </div>               
                </div>
              </div>
            </div>




            <div class="modal-body" *ngIf="stage==7">
              <div class="container">
                <div class="main-banner">
                  <div class="overviewsshow pt-2">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="tankushow">
                          <div class="detailstext righttowork">
                              <h1>Your right to work process is complete!</h1>
                              <p> You do not need to take any further action.</p>
                              <p>If you have correctly carried out all the steps, you will have an excuse against liability for a civil penalty if the verified person is found working for you illegally.</p>
                          </div>
                          <img alt="shape" src="../../../../../assets/img/thanks_new.png">
                        </div>
                        <a [routerLink]="['/right-to-work-app-history-list-admin',0]" class="backlist mb-4 mr-0 float-left" style="padding: 0px;"> 
                          <button class="btn com_btn btn-success small_btn"  type="button" >
                            Go to Right to Work Check
                         </button>
                         </a>
                      </div>
                    </div>
                  </div>               
                </div>
              </div>
            </div>


            <div class="modal-body" *ngIf="stage==8">
              <div class="container">
                <div class="main-banner">
                  <div class="overviewsshow mt-4">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="tankushow">
                          <div class="detailstext">
                              <h1>Right to work request created</h1>
                              <p>A secure link has been sent to the candidate to complete the process.</p>
                              <p>Once the Right to work has been completed you will be advised by email.</p>
                          </div>
                          <img alt="shape" src="../../../../../assets/img/thanks_new.png">
                        </div>
                      </div>
                    </div>
                  </div>               
                </div>
              </div>
            </div>


            <div class="modal-body" *ngIf="stage==9">
              <div class="container">
                <div class="main-banner">
                  <div class="overviewsshow mt-4">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="tankushow">
                          <div class="detailstext">
                              <h1>Already Request Sent</h1>
                              <p>Document upload request already sent to applicant sucessfully. Now, you need to wait while applicant uploads the document successfully. After that you need to complete the process.</p>

                              <button class="btn com_btn btn-success small_btn" (click)="requestsendagain()" type="button" >
                                Send Request again to Applicant 
                              </button>
                              <button *ngIf="update" class="btn com_btn btn-success small_btn" (click)="sendrequestchargestage('4', currentCompany.countryid, '', currentCompany.isrighttoworkcountry)" type="button" >Update Request</button>

                          </div>
                          <img alt="shape" src="../../../../../assets/img/thanks_new1.png">
                        </div>
                      </div>
                    </div>
                  </div>               
                </div>
              </div>
            </div>






          </div>
        </div>
      </div>
      <div class="tabs" id="tabs" style="display: none;">
        <div class="tabs__content">
          <div class="tabs__content__item active-tab">
            <div class="content">
              <div class="row row-sm topheader">
              </div>
              <div class="row">
                <div class="col-sm-12 aligncenter mb-3">
                </div>
              </div>

              <div class="modal-body" *ngIf="stage==6">
                <div class="row">              
                  <div class="col-lg-12 text-center">
                    <h5 class="text-center">Your request send successfully.</h5>
                  </div> 
                </div>
                <div class="row" style="height: 200px; overflow-y: scroll;">
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 text-left" *ngIf="record == 0">
                  <div class="billed-to">No Record Found </div>
                </div>
                <div class="col-lg-12 text-right">
                  <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>





<jw-modal id="sendrequestcharge" role="dialog" class="modal sendrequestcharge">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <form [formGroup]="meetingformGroup" #meetingForm="ngForm" class="s12 white">
        <div class="modal-body p-4">
          <div class="container text-center">
            <div *ngIf="loading ==0">
              <div class="para mb-4">
                <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
                <p class="mb-0 font20">The employee will receive an email containing a video link for live verification of all the documents.</p>
                <p class="mb-0 font20">Click 'Confirm' to proceed with the 'Right to work check'.</p>
              </div>
              <div class="row">
                <div class="form-group col-lg-4 text-left">
                  <label class="meetinglabel">Meeting Start Date*</label>
                  <mat-form-field class="datecontrol">
                    <input matInput [matDatepicker]="picker1" name="startdate" id="startdate"  [(ngModel)]="right.startdate"  class="form-control noborder-invalid" formControlName="startdate" [min]="currentDate" [class.is-invalid]="submitted && returnForm.startdate.errors" placeholder="Start Date">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                  <div *ngIf="submitted && returnForm.startdate.errors" class="invalid-feedback">
                    <div *ngIf="returnForm.startdate.errors.required">Please Choose Start Date</div>
                  </div>
                </div>
                <div class="form-group col-lg-4 text-left">
                  <label class="meetinglabel">Meeting Start Time*</label>
                  <select formControlName="starttime" name="starttime" id="starttime"  [(ngModel)]="right.starttime"  [ngClass]="{ 'is-invalid': submitted && returnForm.starttime.errors }" class="form-control">
                    <option [value]="defaultValue">Select start time</option>
                    <option *ngFor="let tval of timeValues" value="{{ tval.key }}">{{ tval.value }}</option>
                  </select>
                  <div *ngIf="submitted && returnForm.starttime.errors" class="invalid-feedback">
                    <div *ngIf="returnForm.starttime.errors.required">Please enter start time</div>
                  </div>
                </div>
                <div class="form-group col-lg-4 text-left">
                  <label class="meetinglabel">Meeting End Time*</label>
                  <select formControlName="endtime" name="endtime" id="endtime"  [(ngModel)]="right.endtime"  [ngClass]="{ 'is-invalid': submitted && returnForm.endtime.errors }" class="form-control">
                    <option [value]="defaultValue">Select end time</option>
                    <option *ngFor="let tval of timeValues" [disabled]="meetingformGroup.controls.starttime.value>=tval.key" value="{{ tval.key }}">{{ tval.value }}</option>
                  </select>
                  <div *ngIf="submitted && returnForm.endtime.errors" class="invalid-feedback">
                    <div *ngIf="returnForm.endtime.errors.required">Please enter end time</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="browser-screen-loading-content meetingloader" *ngIf="loading ==1" style="width:300px">
              <div class="loading-dots dark-gray">
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>                                
                <i></i>
              </div>
              <p>Loading</p>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center" *ngIf="loading !=1">
            <input type="submit" class="btn btn-primary com_btn" [disabled]="loading == 1" name="updateform" value="Confirm"  (click)="sendZoomLink(employeeid, 'sendrequestcharge', meetingForm.value)"  />
            <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('sendrequestcharge');"/>
        </div> 
    </form>
    </div>
  </div>
</jw-modal>

<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">         
            <div class="modal-body">
                <div class="container text-center">
                  <div class="info_mess">
                      <div class="para">
                              <h4 class="mb-2">No credits available</h4>
                              <p class="mb-0 font20"> There are no credits available to perform this action. Please add more credits.</p>
  
                          </div>                          
                      </div>
                </div>
            </div>
            <div class="modal-footer text-center">
              <input type="button" class="btn btn-primary com_btn" name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
              <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
                <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="confirm-modal" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-body p-4">
                <div class="container text-center">
                    <div class="info_mess">
                        <div class="para">
                            <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
                            <p class="mb-0 font20" *ngIf="isrighttowork ==1">The employee will receive an email and they need to upload the document.</p>
                        </div>                          
                    </div>
                </div>
            </div>
            <div class="modal-footer text-center">
                <input type="submit" class="btn btn-primary com_btn" *ngIf="isrighttowork == 2" name="updateform" value="Confirm"  (click)="sendrequestchargestage(13,'')"  />
                <input type="submit" class="btn btn-primary com_btn" *ngIf="isrighttowork != 2"  name="updateform" value="Confirm"  (click)="sendrequestchargestage(5,'')"  />
                <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('confirm-modal');"/>
            </div>
        </div>
    </div>
</jw-modal>




<jw-modal id="add-notes-to-right-to-work-2" role="dialog" class="modal">
  <div role="document" class="formgroup">
      <div class="modal-content">  
        <form [formGroup]="candidatenoteFormGroup" #candidatenoteForm="ngForm" class="s12 white" (ngSubmit)="updatecandidatenote(candidatenoteForm.value)">
          
          <div class="modal-body">
              <div class="container">
                <!-- <div class="info_mess"> -->

                  <div class="row">
                    <div class="form-group col-lg-12">
                        <label>Notes & Observation*</label>                   
                        <textarea type="text" maxlength="500" placeholder="Write your note here (Maximum 500 characters)" 
                            formControlName="notes" name="notes" id="notes" class="form-control" [ngClass]="{ 'is-invalid': submitted && j.notes.errors }" 
                            [(ngModel)]="candidatenotesdetails.notes">            
                            </textarea> 
                          <div *ngIf="submitted && j.notes.errors" class="invalid-feedback mb-2">
                            <div *ngIf="j.notes.errors.required">Please Enter Notes</div>
                          </div>    
                      </div>
                    </div>  

                    <div class="row" [ngClass]="{ 'displayhide': isrighttowork ==2 }">
                      <div class="form-group col-lg-4">
                        <label>Company Name*</label>
                        <input type="text" formControlName="companyname" placeholder="Please enter company name" class="" name="companyname" id="companyname"
                          class="form-control" [ngClass]="{ 'is-invalid': submitted && k.companyname.errors }" ngModel="{{ sharcodedetails.companyname }}"
                          value="{{ sharcodedetails.companyname }}" maxlength="50">
                        <div *ngIf="submitted && k.companyname.errors" class="invalid-feedback">
                          <div *ngIf="k.companyname.errors.required">Please enter company name</div>
                        </div>
                      </div>

                      <div class="form-group col-lg-4">
                        <label>Check conducted by (Name)*</label>
                        <input type="text" formControlName="Checkconductedby"  placeholder="Please enter Check conducted by (Name)" name="Checkconductedby" id="Checkconductedby" 
                          class="form-control" [ngClass]="{ 'is-invalid': submitted && j.Checkconductedby.errors }" 
                          [ngModel]="candidatenotesdetails.Checkconductedby" maxlength="200">                            
                          <div *ngIf="submitted && j.Checkconductedby.errors" class="invalid-feedback mb-2">
                            <div *ngIf="j.Checkconductedby.errors.required">Please Enter Check conducted by (Name)</div>
                          </div> 
                      </div>  
                      <div class="form-group col-lg-4">
                        <label>Check conducted by (Role)*</label>
                        <input type="text" formControlName="Checkconductedbyrole" placeholder="Check conducted by (Role)" name="Checkconductedbyrole" id="Checkconductedbyrole" 
                          class="form-control" [ngClass]="{ 'is-invalid': submitted && j.Checkconductedbyrole.errors }" 
                          [ngModel]="candidatenotesdetails.Checkconductedbyrole" maxlength="200">  
                          
                          <div *ngIf="submitted && j.Checkconductedbyrole.errors" class="invalid-feedback mb-2">
                            <div *ngIf="j.Checkconductedbyrole.errors.required">Please Enter Check conducted by (Role)</div>
                          </div> 
                      </div>  


                      <div class="form-group col-lg-6"   [ngClass]="{ 'displayhide': isrighttowork ==1 }">
                        <label>NI Number*</label>
                        <input type="text" formControlName="ninumber" class="" name="ninumber" id="ninumber"
                          class="form-control" [ngClass]="{ 'is-invalid': submitted && k.ninumber.errors }" ngModel="{{ sharcodedetails.ninumber }}"
                          value="{{ sharcodedetails.ninumber }}" maxlength="50">
                        <div *ngIf="submitted && k.ninumber.errors" class="invalid-feedback">
                          <div *ngIf="k.ninumber.errors.required">Please enter NI number</div>
                        </div>
                      </div>
                    </div>

                    <div class="row"  [ngClass]="{ 'displayhide': isrighttowork ==1 }">
                      <div class="form-group col-lg-6">
                        <label>Date of Birth *</label>
                        <mat-form-field class="datecontrol">
                          <input matInput [matDatepicker]="picker1" name="dateofbirth" id="dateofbirth" 
                            [ngClass]="{ 'is-invalid': submitted && k.dateofbirth.errors }" formControlName="dateofbirth" 
                            class="form-control" ngModel='{{ sharcodedetails.dateofbirth }}' [value]="sharcodedetails.dateofbirth">
                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                          <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="submitted && k.dateofbirth.errors" class="invalid-feedback">
                          <div *ngIf="k.dateofbirth.errors.required">Please enter Date of Birth</div>
                        </div>
                      </div>
                    </div>

                    
                    <div class="row"  [ngClass]="{ 'displayhide': isrighttowork ==1 }" >  
                        <div class="form-group col-lg-6">
                          <label>Share Code (shared by employee)</label>
                          <input type="text" formControlName="sharecode" name="sharecode" id="sharecode" 
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && j.sharecode.errors }" 
                            [ngModel]="candidatenotesdetails.sharecode" maxlength="16">                        
                        </div>  
                        <div class="col-lg-6">
                          <label>Upload online Right to Work check report</label>
                          <div class="input-group mb-3" (click)="filepdfdoc.click()">
                            <div class="custom-file">
                              <input type="file" #filepdfdoc (change)="selectPdfFile($event)">
                              <label class="custom-file-label">{{docpdffilename}}</label>
                            </div>
                          </div>
                          <div class="invalid-feedback ng-star-inserted">
                            <!-- <div class="" *ngIf="filebackrequirederror">Back side image is required</div> -->
                            <div *ngIf="pdfbackfilesizeerror">File size must be less than 2 MB</div>
                            <div *ngIf="pdfbackfileformaterror">Please choose a valid PDF file format.</div>
                          </div>
                        </div>  
                    </div>

                    <!-- <div class="row">  
                      <div class="form-group col-lg-6">
                        <label>Check conducted by (Name)*</label>
                        <input type="text" formControlName="Checkconductedby" name="Checkconductedby" id="Checkconductedby" 
                          class="form-control" [ngClass]="{ 'is-invalid': submitted && j.Checkconductedby.errors }" 
                          [ngModel]="candidatenotesdetails.Checkconductedby" maxlength="200">                            
                          <div *ngIf="submitted && j.Checkconductedby.errors" class="invalid-feedback mb-2">
                            <div *ngIf="j.Checkconductedby.errors.required">Please Enter Check conducted by (Name)</div>
                          </div> 
                      </div>  
                      <div class="form-group col-lg-6">
                        <label>Check conducted by (Role)*</label>
                        <input type="text" formControlName="Checkconductedbyrole" name="Checkconductedbyrole" id="Checkconductedbyrole" 
                          class="form-control" [ngClass]="{ 'is-invalid': submitted && j.Checkconductedbyrole.errors }" 
                          [ngModel]="candidatenotesdetails.Checkconductedbyrole" maxlength="200">  
                          
                          <div *ngIf="submitted && j.Checkconductedbyrole.errors" class="invalid-feedback mb-2">
                            <div *ngIf="j.Checkconductedbyrole.errors.required">Please Enter Check conducted by (Role)</div>
                          </div> 
                      </div>                                          
                    </div> -->

                    <div class="row">  
                      <div class="form-group col-lg-12">
                        <label>Signature*</label>
                        <div class="clearfix">&nbsp;</div>
                        <canvas style="border: 1px solid #000;" #canvas (touchstart)="startDrawing($event)"
                        (touchmove)="moved($event)"></canvas>

                      </div>
                      <div class="form-group col-lg-12">
                        <button (click)="clearPad()" class="btn  btn-md mt-3 btn-danger" type="button"> Clear</button>
                      </div>
                    </div>


                    <!-- </div> -->
              </div>
          </div>
          <div class="modal-footer text-center">
            <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Save"    />          
          </div>
        </form>
      </div>
  </div>
</jw-modal>
<!-- 

<jw-modal id="add-notes-to-right-to-work" role="dialog" class="modal">
  <div role="document" class="formgroup">
      <div class="modal-content">  
        <form [formGroup]="candidateshareFormGroup" #candidateshareForm="ngForm" class="s12 white" (ngSubmit)="updatecandidatenote(candidateshareForm.value)">
          
          <div class="modal-body">
              <div class="container">
               
                  <div class="row">
                    <div class="form-group col-lg-12">
                        <label>Notes & Observation*</label>                   
                        <textarea type="text" maxlength="500" placeholder="Write your note here (Maximum 500 characters)" 
                            formControlName="notes" name="notes" id="notes" class="form-control" [ngClass]="{ 'is-invalid': submitted && l.notes.errors }" 
                            [(ngModel)]="candidatenotesdetails.notes">            
                            </textarea> 
                          <div *ngIf="submitted && l.notes.errors" class="invalid-feedback mb-2">
                            <div *ngIf="l.notes.errors.required">Please Enter Notes</div>
                          </div>    
                      </div>
                    </div>  

                   

                

                    
                   




                    <div class="row">  
                      <div class="form-group col-lg-6">
                        <label>Check conducted by (Name)*</label>
                        <input type="text" formControlName="Checkconductedby" name="Checkconductedby" id="Checkconductedby" 
                          class="form-control" [ngClass]="{ 'is-invalid': submitted && l.Checkconductedby.errors }" 
                          [ngModel]="candidatenotesdetails.Checkconductedby" maxlength="200">                            
                          <div *ngIf="submitted && l.Checkconductedby.errors" class="invalid-feedback mb-2">
                            <div *ngIf="j.Checkconductedby.errors.required">Please Enter Check conducted by (Name)</div>
                          </div> 
                      </div>  
                      <div class="form-group col-lg-6">
                        <label>Check conducted by (Role)*</label>
                        <input type="text" formControlName="Checkconductedbyrole" name="Checkconductedbyrole" id="Checkconductedbyrole" 
                          class="form-control" [ngClass]="{ 'is-invalid': submitted && l.Checkconductedbyrole.errors }" 
                          [ngModel]="candidatenotesdetails.Checkconductedbyrole" maxlength="200">  
                          
                          <div *ngIf="submitted && l.Checkconductedbyrole.errors" class="invalid-feedback mb-2">
                            <div *ngIf="l.Checkconductedbyrole.errors.required">Please Enter Check conducted by (Role)</div>
                          </div> 
                      </div>                                          
                    </div>

                    <div class="row">  
                      <div class="form-group col-lg-12">
                        <label>Signature*</label>
                        <div class="clearfix">&nbsp;</div>
                        <canvas style="border: 1px solid #000;" #canvas (touchstart)="startDrawing($event)"
                        (touchmove)="moved($event)"></canvas>

                      </div>
                      <div class="form-group col-lg-12">
                        <button (click)="clearPad()" class="btn  btn-md mt-3 btn-danger"> Clear</button>
                      </div>
                    </div>


              </div>
          </div>
          <div class="modal-footer text-center">
            <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Save"    />          
          </div>
        </form>
      </div>
  </div>
</jw-modal> -->

<jw-modal id="pdf-viewer-visa" class="modal" role="dialog">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-viewer-visa');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="600" frameborder="0"></iframe>
              <img *ngIf="imgsrc == true" [src]="iframesrc" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</jw-modal>