import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class MyaddressService {
  [x: string]: any;
  private modals: any[] = [];
  constructor(private commonService: CommonService) {}

  getemploymenttList(value:any): Observable<any> 
  {
    return this.commonService.formpost('/employee/currentaddresslist',value);
  }
  getemploymenttListbyadmin(value:any): Observable<any> 
  {
    return this.commonService.formpost('/employee/currentaddresslistbyadmin',value);
  }
  getemploymenttapprove(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/employee/currentaddressapprove');
  }
  getemploymenttrejected(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/employee/currentaddressrejected');
  }
  myaddressdetailsUpdate(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/addressupdate',value);
  }
  myaddressdetailsUpdatebyadmin(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/addressupdatebyadmin',value);
  }
  myaddresschangecurrent(value:any): Observable<any> {
    return  this.commonService.changecurrentaddress('/employee/changecurrentaddress',value);
  }
  
  myaddressDelete(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/deleteAddress',value);
  }
}