"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var AiverificationComponent = /** @class */ (function () {
    function AiverificationComponent() {
    }
    AiverificationComponent.prototype.ngOnInit = function () {
    };
    return AiverificationComponent;
}());
exports.AiverificationComponent = AiverificationComponent;
