"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var myemployment_service_1 = require("../../../services/myemployment.service");
var modal_service_1 = require("../../../_services/modal.service");
var working_model_1 = require("../../../models/employment/working.model");
var wemployee_model_1 = require("../../../models/employment/wemployee.model");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var platform_browser_1 = require("@angular/platform-browser");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var employee_service_1 = require("../../../services/employee.service");
var project_service_1 = require("../../../services/project.service");
var client_service_1 = require("../../../services/client.service");
var alert_service_1 = require("../../../services/alert.service");
var department_service_1 = require("../../../services/department.service");
var division_service_1 = require("../../../services/division.service");
var company_service_1 = require("src/app/services/company.service");
var EmployementlistComponent = /** @class */ (function () {
    function EmployementlistComponent(sanitizer, data, companyService, formBuilder, modalService, activatedRoute, project, client, employeeService, uploadService, alerts, departmentService, divisionService) {
        this.sanitizer = sanitizer;
        this.data = data;
        this.companyService = companyService;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.project = project;
        this.client = client;
        this.employeeService = employeeService;
        this.uploadService = uploadService;
        this.alerts = alerts;
        this.departmentService = departmentService;
        this.divisionService = divisionService;
        this.currentvalue = false;
        this.flexistartdateerror = false;
        this.flexienddateerror = false;
        this.empenddateerror = false;
        this.defaultVal = "";
    }
    EmployementlistComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filename1 = this.filename2 = "Select File";
        this.empid = this.activatedRoute.snapshot.paramMap.get("id");
        this.Userid = localStorage.getItem('userid');
        this.empcontext = 'Employment Contract *';
        this.emprwordtext = 'Right to work *';
        // this.employeementDetail.enddate = "";
        this.iscurrentEmployment = false;
        this.employmentForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            clientid: [''],
            projectid: [''],
            employeeid: ['', forms_1.Validators.required],
            // wemployeeid: ['', Validators.required],
            // clientcont: ['', Validators.required],
            salary: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.pattern("^[0-9]*$")])],
            hoursofworks: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.pattern("^[0-9]*$")])],
            startdate: ['', forms_1.Validators.required],
            enddate: [''],
            emprole: ['', forms_1.Validators.required],
            iscurrent: [''],
            employeestatus: [''],
            hiredate: [''],
            terminationdate: [''],
            terminationreason: [''],
            oftorehire: [''],
            regrettermination: [''],
            departmentid: [''],
            divisionid: [''],
            contracttypeid: ['', forms_1.Validators.required],
            flexiworkid: ['', forms_1.Validators.required],
            flexistartdate: [''],
            flexienddate: [''],
            flexiid: ['']
        });
        this.employeementDetail = new working_model_1.Working();
        this.employeementDetail.flexi = new working_model_1.Flexi();
        this.employeementDetail.wemployee = new wemployee_model_1.Wemployee();
        this.filename = 'Choose file';
        this.empltype = localStorage.getItem('emptype');
        this.data.getemploymenttListbyadmin({ 'id': this.empid }).subscribe(function (data) {
            // console.log(data);
            _this.employeedetails = data;
        });
        this.employeeService.getlistofworkingemployee().subscribe(function (employeedata) {
            _this.wemployeelist = employeedata;
        });
        var sstatustype = { 'status': true, 'id': this.Userid };
        this.project.getprojectlist(sstatustype).subscribe(function (projectdetails) {
            _this.projectlist = projectdetails;
        });
        this.client.getclientlist(sstatustype).subscribe(function (clientdetails) {
            _this.clientlist = clientdetails;
        });
        this.departmentService.getdepartmentlistbyadmin(sstatustype).subscribe(function (data) {
            _this.departmentlist = data;
        });
        this.companyService.getemployeecontracttypelist().subscribe(function (data) {
            _this.contracttypelist = data;
        });
        this.divisionService.getdivisionlistbyadmin(sstatustype).subscribe(function (data) {
            _this.divisiondetails = data;
        });
    };
    Object.defineProperty(EmployementlistComponent.prototype, "f", {
        get: function () { return this.employmentForm.controls; },
        enumerable: true,
        configurable: true
    });
    EmployementlistComponent.prototype.onSubmit = function (formemployment, id) {
        var _this = this;
        // var formemployment = value3.value;
        this.submitted = true;
        this.filesconemerror = '';
        this.filesworkemerror = '';
        if (this.employmentForm.invalid) {
            return;
        }
        if (this.file1 != '') {
            formemployment.employmentcontract = this.file1;
        }
        else {
            formemployment.employmentcontract = this.employeementDetail.employmentcontract;
        }
        if (this.file2 != '') {
            formemployment.rwork = this.file2;
        }
        else {
            formemployment.rwork = this.employeementDetail.rwork;
        }
        if (formemployment.flexiworkid == '1') {
            if ((formemployment.flexistartdate == '') || (formemployment.flexienddate == '')) {
                if (formemployment.flexistartdate == '') {
                    this.flexistartdateerror = true;
                }
                // if(formemployment.flexienddate == '')
                // {
                //   this.flexienddateerror = true;
                // }
                return;
            }
        }
        if (formemployment.iscurrent == '0') {
            if ((formemployment.enddate == '') || (formemployment.enddate == null)) {
                this.empenddateerror = true;
                return false;
            }
        }
        if (formemployment.employmentcontract == '') {
            this.filesconemerror = true;
            return;
        }
        if (formemployment.rwork == '') {
            this.filesworkemerror = true;
            return;
        }
        this.disableBtn = false;
        formemployment.createby = this.Userid;
        formemployment.updateby = this.Userid;
        var sd = new Date(formemployment.startdate);
        formemployment.startdate = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        if (formemployment.enddate) {
            var ed = new Date(formemployment.enddate);
            formemployment.enddate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        }
        this.data.myemploymentdetailsUpdatebyadmin(formemployment).subscribe(function (data) {
            _this.data.getemploymenttListbyadmin({ 'id': _this.empid }).subscribe(function (data) {
                _this.employeedetails = data;
                _this.alerts.success('Employment Info Updated Successfully', true);
                _this.modalService.close(id);
            });
        });
        // this.employeementDetail = new Working();
        // this.employeementDetail.flexi = new Flexi();
    };
    EmployementlistComponent.prototype.selectFile = function (event, field) {
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        var file = this.selectedFiles;
        var imagepath = this.uploadService.uploadfile(file);
        if (field == 'file1') {
            this.filesconemerror = '';
            this.filename1 = selectedFiles.name.substring(0, 15);
            this.file1 = imagepath;
        }
        if (field == 'file2') {
            this.filesworkemerror = '';
            this.filename2 = selectedFiles.name.substring(0, 20);
            this.file2 = imagepath;
        }
    };
    EmployementlistComponent.prototype.openModal2 = function (id) {
        this.filename1 = this.filename2 = "Select File";
        this.file1 = this.file2 = "";
        this.disableBtn = true;
        this.currentvalue = false;
        this.submitted = false;
        this.buttoninsert = "Submit";
        this.employeementDetail = new working_model_1.Working();
        this.employeementDetail.flexi = new working_model_1.Flexi();
        this.employeementDetail.flexi.id = "0";
        this.employeementDetail.flexiworkid = "0";
        this.employeementDetail.iscurrent = "1";
        this.employeementDetail.employeeid = this.empid;
        this.employeementDetail.employeeworkingdetailsid = "0";
        this.empcontext = 'Employment Contract *';
        this.emprwordtext = 'Right to work *';
        // this.iscurrentEmployment = true;
        // this.employeementDetail.enddate = "";
        this.iscurrentEmployment = false;
        // this.employeementDetail.clientid ='';
        // this.employeementDetail.projectid = '';
        // this.employeementDetail.wemployeeid = '';
        // this.employeementDetail.clientcont = '';
        this.employeementDetail.employmentcontract = '';
        this.employeementDetail.rwork = '';
        // this.employeementDetail.flexi.startdate = "";
        // this.employeementDetail.flexi.enddate = "";
        this.filename = "Choose file";
        this.modalService.open(id);
    };
    EmployementlistComponent.prototype.openModal = function (id, data) {
        this.filename1 = this.filename2 = "Select File";
        this.file1 = this.file2 = "";
        this.disableBtn = true;
        this.employeementDetail = new working_model_1.Working();
        this.employeementDetail.flexi = new working_model_1.Flexi();
        this.filename = "Choose file";
        this.buttoninsert = "Update";
        this.empcontext = 'Employment Contract';
        this.emprwordtext = 'Right to work';
        if (!data.flexi) {
            this.employeementDetail = data;
            this.employeementDetail.flexi = new working_model_1.Flexi();
            this.employeementDetail.flexi.id = "0";
            this.employeementDetail.flexiworkid = "0";
        }
        else {
            this.employeementDetail = data;
            this.employeementDetail.flexiworkid = "1";
            this.currentvalue = true;
        }
        // if((this.employeementDetail.enddate != "") && (this.employeementDetail.enddate != null))
        // {
        //   this.iscurrentEmployment = true;
        // }
        if (data.iscurrent == false) {
            this.iscurrentEmployment = true;
            this.employeementDetail.iscurrent = "0";
        }
        else if (data.iscurrent == true) {
            this.iscurrentEmployment = false;
            this.employeementDetail.iscurrent = "1";
        }
        // console.log(this.employeementDetail);
        this.modalService.open(id);
    };
    EmployementlistComponent.prototype.closeModal = function (id) {
        var _this = this;
        // this.employeementDetail = new Working();   
        // this.employeementDetail.flexi = new Flexi();
        this.data.getemploymenttListbyadmin({ 'id': this.empid }).subscribe(function (data) {
            _this.employeedetails = data;
        });
        this.modalService.close(id);
    };
    EmployementlistComponent.prototype.flexichange = function (eve, addressid) {
        if (eve.target.value == '1') {
            this.employeementDetail.flexistartdate = "";
            this.employeementDetail.flexienddate = "";
            this.currentvalue = true;
        }
        else {
            this.currentvalue = false;
        }
    };
    EmployementlistComponent.prototype.currentemploymentchange = function (eve) {
        // console.log(eve);
        if (eve.target.value == '1') {
            this.employeementDetail.enddate = "";
            this.iscurrentEmployment = false;
        }
        else if (eve.target.value == '0') {
            this.iscurrentEmployment = true;
        }
    };
    EmployementlistComponent.prototype.focuser1 = function () {
        this.flexistartdateerror = false;
    };
    EmployementlistComponent.prototype.focuser2 = function () {
        this.flexienddateerror = false;
    };
    EmployementlistComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    EmployementlistComponent.prototype.openModalemployement3 = function (id, data) {
        this.iframeempsrc = '';
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgempsrc = true;
            this.iframeempsrc = data;
        }
        else {
            this.imgempsrc = false;
            this.iframeempsrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    return EmployementlistComponent;
}());
exports.EmployementlistComponent = EmployementlistComponent;
