import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { error } from 'util';
import { User } from '../../models/user.model';
import { CommonService } from '../../services/common.service';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../../services/customer.service';
import { CompanyService } from '../../services/company.service';
import { AlertService } from '../../services/alert.service';
// import { User} from '../../shared/user.model';
// import { EmailValidator } from '@angular/forms';

@Component({
  selector: 'app-username',
  templateUrl: './username.component.html',
  styleUrls: ['./username.component.css']
})
export class UsernameComponent implements OnInit {


  registerForm: FormGroup;
  submitted = false;
  isLoginError = false;
  alertService: any;
  // loading: boolean;
  user = new User();
  access_token = '';
  userid = '';
  name = '';
  isline = '';
  planid = '';
  error = '';
  show: any;
  success: any;
  showsuccess: any;
  phonecheck: any;

  mobilenumber2:any;


  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private alerts: AlertService,
    private commonService: CommonService,
    private csservices: CustomerService,
    private company: CompanyService
  ) { }

  ngOnInit() {



    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.user.mobilenumber = "+44"
    
    this.showsuccess = false;
    this.success = true;

  }


  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }



var dd = { email : this.user.email }
    
    this.userService.forgotuser(dd).subscribe((data: any) => {
      if (data.success == "success") {
        this.showsuccess = true;
      } else {
          this.showsuccess = false;
          this.success = true;
          this.alerts.error("This username is not registered with us");         
      }
    },
      (err: HttpErrorResponse) => {
        this.show = true;
      });
  }

  resedotp() {
    this.submitted = false;
    this.show = false;
    this.company.resendmobileotp().subscribe((data: any) => {
      localStorage.setItem('otpmobile', data.otp);
      this.show = false;
      this.router.navigate(['/otp']);
    },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
      });
  }
  
  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
     
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }
  
}