import { Component, OnInit, Input,ViewChild } from '@angular/core';
import { ChecksystemService } from '../../services/checksystem.service';
import { Router } from '@angular/router';


import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PlanService } from '../../services/plan.service';
import { PaymentService } from '../../services/payment.service';
import { Plan } from '../../models/plan.model';
import { ModalService } from '../../_services/modal.service';

import { StripeScriptTag } from "stripe-angular";
import { StripeToken } from "stripe-angular";
import { AlertService } from '../../services/alert.service';
import { UserService } from '../../services/user.service';
import { CompanyService } from '../../services/company.service';
import { AppGlobals } from '../../app.global';

@Component({
  selector: 'app-checkbilling',
  templateUrl: './checkbilling.component.html',
  styleUrls: ['./checkbilling.component.css']
})
export class CheckbillingComponent implements OnInit {

  
  private publishableKey: string =AppGlobals.API_STRIPE;
  invoicelist: any;

  
  @Input() items: Array<any>;
  
  buttoninsert: any;
  Userid: any;
  data: any;
  planDetail = new Plan();
  planformGroup: FormGroup;
  planlist: any;
  submitted: any;
  totaluser: any;
  cardlist: any;
  message: string;
  stptoken: any;
  planid: any;
  extraData: any;
  billinglist: any;
  companyData: any;
  currentprice: any;
  checkInvoiceDetails: any;
  timestamp: any;
  record: number;
  pageOfItems: Array<any>;
  downloadCheckInvoiceData: any;
  downloadcheckinvoicetext: any;
  companyInfo: any;
  companyLocation: any;

  constructor(private check: ChecksystemService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: ModalService,    
    private alerts: AlertService,
    private plan: PlanService,
    private userService: UserService,
    public companyService: CompanyService,
    public StripeScriptTag: StripeScriptTag,
    public paymentService: PaymentService
  ) { }

  ngOnInit() {
    this.downloadcheckinvoicetext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
    
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var isadmin = localStorage.getItem('isadmin');
    if(isadmin!='true'){
      this.router.navigate(['/permission-denied']);
    }

    this.check.getAllInvoice().subscribe((data: any) => {
      this.record  = data.invoicelist.length;
      this.pageOfItems = data.invoicelist;
      this.companyLocation = data.companylocation;
      this.companyInfo = data.company;
      if(data.invoicelist.length){
        this.checkInvoiceDetails = data.invoicelist[0];
      }
      if(this.record < 5){
        this.pageOfItems = data.invoicelist;
      }
      else{
           this.items = data.invoicelist;
      }
      // console.log(data);
          // this.invoicelist =data;
    });

     this.StripeScriptTag.setPublishableKey(this.publishableKey);
   
      this.timestamp = function(epoch){
          return (epoch * 1000);
      };

    // this.planformGroup = this.formBuilder.group({
    //   coupnsnumber: ['', Validators.required]
    // });

    var isemail = localStorage.getItem('isemail');
    this.planid = localStorage.getItem('planid');


    // this.companyService.getinvoicelist().subscribe((data: any) => {        
    //     this.invoicelist = data;
    //     // console.log(this.invoicelist);
    //    });

       this.companyService.gettotalcards().subscribe((data: any) => {       
           this.cardlist = data;
          });

    //       this.companyService.getnextbillingdate().subscribe((data: any) => {
    //            this.billinglist = data;
    //           });

  }

  
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  getCheckInvoiceData(invoiceid: any)
  {
    // this.checkInvoiceDetails = undefined;
    this.downloadcheckinvoicetext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
    var data = { 'invoiceid': invoiceid };
    this.check.getAllInvoicedetails(data).subscribe((invoiceData: any) => { 
      this.checkInvoiceDetails = invoiceData;      
    });
  }

  downloadCheckInvoice(invoiceid: any)
  {
    this.downloadcheckinvoicetext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
    var data = { 'invoiceid': invoiceid };
    this.check.downloadCheckInvoice(data).subscribe((invoiceData: any) => { 
      this.downloadCheckInvoiceData = invoiceData;
      this.downloadcheckinvoicetext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
      window.open(this.downloadCheckInvoiceData.response_data.Location, "_blank");
    });
  }

  // downloadinvoice(invoiceid: any){
  //   var data = {'invoiceid':invoiceid}
  //   this.companyService.sendinvoicesend(data).subscribe((data: any) => {      
  //       // console.log(data);
  //   });
  // }

}
