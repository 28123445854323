
<app-header></app-header>
<div class="section-wrapper">
  <div class="container">
    <a [routerLink]="['/reference-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to  Automated Reference Check</a>
   <div class="clearfix">&nbsp;</div>
    <div class="tabs">
      <div class="tabs__content noshadow">
        <div class="tabs__content__item nobg active-tab">
          <div class="content">
              <div class="refsettings">
                <h4>Notification Setting</h4>
                <!-- <div class="row">
                  <div class="col-sm-6">
                      <div class="setting">
                        <label class="refsetting">
                          <input type="checkbox" checked="checked">
                          <span class="checkmark"></span>
                        </label>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                        </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="setting">
                      <label class="refsetting">
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                      </label>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                      <div class="setting">
                        <label class="refsetting">
                          <input type="checkbox" checked="checked">
                          <span class="checkmark"></span>
                        </label>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                        </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="setting">
                      <label class="refsetting">
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                      </label>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                      <div class="setting">
                        <label class="refsetting">
                          <input type="checkbox" checked="checked">
                          <span class="checkmark"></span>
                        </label>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                        </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="setting">
                      <label class="refsetting">
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                      </label>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                      <div class="setting">
                        <label class="refsetting">
                          <input type="checkbox" checked="checked">
                          <span class="checkmark"></span>
                        </label>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                        </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="setting">
                      <label class="refsetting">
                        <input type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                      </label>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                      </div>
                  </div>
                </div> -->


                <form [formGroup]="addpopForm" #addresslistForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(addpopForm.value,'custom-address-1')" *ngIf="ref">


                <div class="refnotification" *ngIf="hidediv">
                    <div class="refnotificationbox">
                        <div class="alignref">
                          <span>What is this for?</span>
                          <span><a style="cursor: pointer;" (click)="hidedivform()">Hide</a></span>
                        </div>
                        <p>Choose which system notifications (E-mail and SMS) are sent to candidates, references and verifiers.</p>
                    </div>
                </div>
                <div class="reftabnotification">
                    <div class="header">
                        <h4>Enable Email Reminder</h4>
                        <div class="toggle">
                          <label class="switch">

                            <input type="checkbox"  formControlName="isemail" name="iseamil" id="isemail" 
                           [ngClass]="{ 'is-invalid': submitted && f.isemail.errors }"  (change)="consertemail($event)" 
                          [ngModel]="ref.isemail" >
                            
                            <span class="reftabslider round"></span>
                          </label>
                        </div>
                    </div>
                    <div class="contenttabsection" [hidden]="emaildiv">
                        <div class="contentref">
                              <p class="labeltext">Set frequency of reminder</p>
                              <label class="reftabradioss" *ngFor="let option of options">{{option.name}}
                                <input type="radio"  [checked]="option.id == ref.isemailtime" formControlName="isemailtime" name="isemailtime" id="isemailtime"  [ngModel]="ref.isemailtime"   value= {{option.id}}>                                   
                                <span class="checkmark"></span>
                              </label>                             
                        </div>
                        <div class="contentref">
                          <p class="labeltext">Set max number of days of reminder</p>
                          <div class="form-group">

                            <select  formControlName="emailday" class="form-control" id="emailday" name="emailday" [ngClass]="{ 'is-invalid': submitted && f.emailday.errors }"   [ngModel]="ref.emailday">
                              <option value="{{i}}" *ngFor='let in of counter(99) ;let i = index' >{{i +1}}</option>                          
                          </select>

                           
                          </div>
                    </div>
                    </div>
                </div>
                <div class="reftabnotification">
                  <div class="header">
                      <h4>Enable SMS Reminder</h4>
                      <div class="toggle">
                        <label class="switch">
                          <input type="checkbox"  formControlName="issms" name="issms" id="issms" 
                           [ngClass]="{ 'is-invalid': submitted && f.issms.errors }"   (change)="consertsms($event)"
                          [ngModel]="ref.issms" >

                          <span class="reftabslider round"></span>
                        </label>
                      </div>
                  </div>
                  <div class="contenttabsection" [hidden]="smsdiv">
                      <div class="contentref">
                            <p class="labeltext">Set frequency of reminder</p>

                            <label class="reftabradioss" *ngFor="let option of options">{{option.name}}
                              <input type="radio"  [checked]="option.id == ref.issmstime" formControlName="issmstime" name="issmstime" id="issmstime"  [ngModel]="ref.issmstime"   value= {{option.id}}>
                                 
                              <span class="checkmark"></span>
                            </label>
                           
                      </div>
                      <div class="contentref">
                        <p class="labeltext">Set max number of days of reminder</p>
                        <div class="form-group">

                          <select formControlName="smsday" class="form-control" id="smsday" name="smsday" [ngClass]="{ 'is-invalid': submitted && f.smsday.errors }"   [ngModel]="ref.smsday">
                            <option value="{{i+1}}" *ngFor='let in of counter(99) ;let i = index' >{{i+1}}</option>                          
                        </select>

                         
                        </div>
                  </div>
                  </div>
                  <div class="contenttabsection">
                    <div class="contentref">
                      <div class="row" style="width: 100%;" >
                        <div class="col-sm-12 text-right">
                          <input type="submit"  class="btn com_btn btn-primary"   name="updateform" value="Save Preferences" />
                        </div>
                        <div class="col-sm-12 text-right">
                          <label style="color: green;" class="mt-3">{{Message}}</label>
                        </div>
                      </div>
                      </div>
                  </div>




              </div>
              </form>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

