"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var leave_service_1 = require("../services/leave.service");
var date_fns_1 = require("date-fns");
var Subject_1 = require("rxjs/Subject");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var forms_1 = require("@angular/forms");
var angular_calendar_1 = require("angular-calendar");
var leave_model_1 = require("../models/leave/leave.model");
var modal_service_1 = require("../_services/modal.service");
var customer_service_1 = require("../services/customer.service");
var alert_service_1 = require("../services/alert.service");
var app_global_1 = require("../app.global");
//import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var LeaveComponent = /** @class */ (function () {
    function LeaveComponent(modal, data, formBuilder, modalService, alerts, router, datePipe, customer) {
        this.modal = modal;
        this.data = data;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.alerts = alerts;
        this.router = router;
        this.datePipe = datePipe;
        this.customer = customer;
        this.leaves = [];
        this.minstartDate = new Date();
        this.minendDate = new Date();
        this.leaveavailabledate = new Date();
        this.submitted = false;
        this.remarksubmit = false;
        this.defaultVal = "";
        this.view = angular_calendar_1.CalendarView.Month;
        this.CalendarView = angular_calendar_1.CalendarView;
        this.viewDate = new Date();
        this.refresh = new Subject_1.Subject();
        this.activeDayIsOpen = true;
    }
    LeaveComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        //this.minstartDate.setDate(this.minstartDate.getDate() + 1);
        this.leaveavailabledate.setDate(this.leaveavailabledate.getDate() - 365);
        this.classactive = (new Date()).getMonth().toString();
        this.month = (new Date()).getMonth() + 1;
        this.year = (new Date()).getFullYear();
        this.leaveformGroup = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            holidaytype: ['', forms_1.Validators.required],
            title: ['', forms_1.Validators.required],
            halfday: ['', forms_1.Validators.required],
            start: ['', forms_1.Validators.required],
            end: ['', forms_1.Validators.required],
            holidayremark: ['', forms_1.Validators.required],
            holidayremarkid: ['', forms_1.Validators.required]
        });
        this.remarkformGroup = this.formBuilder.group({
            remarksholidayid: ['', forms_1.Validators.required],
            holidayremark: ['', forms_1.Validators.required]
        });
        this.Userid = localStorage.getItem('userid');
        var sstatustype = { 'status': '1', 'id': this.Userid };
        this.data.getleavetypelist(sstatustype).subscribe(function (leavelist) {
            _this.leavetypelist = leavelist;
        });
        this.bindallData();
        this.bindalldatabymonth();
    };
    LeaveComponent.prototype.openlinemanagerModal = function (id) {
        var _this = this;
        this.customer.getLineEmployeeDetails().subscribe(function (lsmanager) {
            // console.log('>>>>>>> rahul',lsmanager);
            // console.log(lsmanager);
            _this.linemanager = lsmanager;
        });
        this.modalService.open(id);
    };
    LeaveComponent.prototype.bindalldatabymonth = function () {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
        this.data.getemployeeleavereport(data).subscribe(function (data) {
            _this.leavelistreport = data;
        });
    };
    LeaveComponent.prototype.bindallData = function () {
        var _this = this;
        this.leavecount = new leave_model_1.Leavecount();
        this.data.getemployeeleave().subscribe(function (data) {
            // console.log(data);
            _this.leaves = []; //.destroy();// =null;
            // console.log(data);
            data.forEach(function (element) {
                _this.leaves.push({
                    id: element.employeeholidayapplyid,
                    title: element.title,
                    start: new Date(element.startdate),
                    end: new Date(element.enddate),
                    color: { primary: element.hcolor, secondary: element.fcolor },
                    allDay: element.edit,
                    draggable: true,
                    resizable: {
                        beforeStart: true,
                        afterEnd: true
                    }
                });
                _this.refresh.next();
            });
            // console.log(this.leaves);
        });
        var db = { 'year': this.year };
        this.data.getemployeeleavecount(db).subscribe(function (countdata) {
            _this.leavecount = countdata;
            // console.log(countdata);
        });
    };
    LeaveComponent.prototype.showAllEvents = function (id, exid) {
        var _this = this;
        exid = this.datePipe.transform(new Date(exid), "dd MMM yyyy");
        ;
        // console.log(exid);
        this.Userid = localStorage.getItem('userid');
        var databydate = { 'id': this.Userid, 'exid': exid };
        this.data.getleavedatabydate(databydate).subscribe(function (userdata) {
            _this.dayexpense = userdata;
        });
        this.modalService.open(id);
    };
    LeaveComponent.prototype.remarksmodel = function (id, modelid, closemodelid) {
        var _this = this;
        this.remarksubmit = false;
        this.Userid = localStorage.getItem('userid');
        var leaveData = { 'id': id };
        this.remarksholidayid = id;
        this.data.getleaveremarks(leaveData).subscribe(function (remarksData) {
            // console.log(remarksData);
            _this.remarksofleave = remarksData;
        });
        this.modalService.open(modelid);
        this.modalService.close(closemodelid);
    };
    LeaveComponent.prototype.yearpop = function (id) {
        this.modalService.open(id);
    };
    LeaveComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    LeaveComponent.prototype.closemessageModal = function (id) {
        this.modalService.close(id);
        // this.showAllEvents('event-viewer',this.remarksholidayid);   
    };
    LeaveComponent.prototype.onyearSubmit = function (leave) {
        var _this = this;
        this.year = leave;
        var date = this.viewDate;
        date = date_fns_1.setYear(date, this.year);
        this.viewDate = date;
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
        this.data.getemployeeleavereport(data).subscribe(function (data) {
            _this.leavelistreport = data;
        });
        // this.modalService.close(id);
    };
    Object.defineProperty(LeaveComponent.prototype, "f", {
        get: function () {
            return this.leaveformGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeaveComponent.prototype, "g", {
        get: function () {
            return this.remarkformGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    LeaveComponent.prototype.onSubmit = function (leave, id) {
        var _this = this;
        this.submitted = true;
        // console.log(this.leaveformGroup);
        if (this.leaveformGroup.invalid) {
            return;
        }
        this.disableBtn = false;
        var newDate = new Date(leave.start);
        var endDate = new Date(leave.end);
        var sd = new Date(leave.start);
        leave.start = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        var ed = new Date(leave.end);
        leave.end = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        // leave.start =this.datePipe.transform(new Date(leave.start),"dd MMM yyyy");
        // leave.end =this.datePipe.transform(new Date(leave.end),"dd MMM yyyy");
        // console.log(leave);
        this.data.myleaveApplyUpdate(leave).subscribe(function (data) {
            // this.startPicker.value = '';
            // this.endPicker.value = '';
            //this.modalService.close(id);
            _this.leaves = [];
            document.getElementById('closeModal').click();
            _this.bindallData();
            _this.bindalldatabymonth();
        });
        this.alerts.success('Leave applied successfully', true);
    };
    LeaveComponent.prototype.onSubmitRemark = function (remark, popid) {
        var _this = this;
        this.remarksubmit = true;
        if (this.remarkformGroup.invalid) {
            return;
        }
        this.data.addnewremark(remark.form.value).subscribe(function (data) {
            var leaveData = { 'id': remark.form.value.remarksholidayid };
            _this.data.getleaveremarks(leaveData).subscribe(function (remarksData) {
                remark.resetForm();
                _this.remarksubmit = false;
                _this.remarksofleave = remarksData;
            });
            _this.leaves = [];
            _this.bindallData();
        });
        this.alerts.success('Note sent successfully', false);
        this.closeModal(popid);
        this.bindalldatabymonth();
        // this.showAllEvents('event-viewer', this.remarksholidayid);
    };
    LeaveComponent.prototype.listtoggel = function () {
    };
    LeaveComponent.prototype.onDelete = function (id, mode) {
        var _this = this;
        if (mode === void 0) { mode = ''; }
        var deleteData = { 'id': id };
        if (confirm("Are you sure you want to delete?")) {
            this.data.deleteleave(deleteData).subscribe(function (data) {
                _this.leaves = [];
                //document.getElementById('closeModal').click();
                _this.bindalldatabymonth();
                _this.bindallData();
                if (mode == 'listing') {
                    document.getElementById('closeModal').click();
                }
            });
        }
    };
    LeaveComponent.prototype.dayClicked = function (_a) {
        var date = _a.date, events = _a.events;
        if (date_fns_1.isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            if ((date_fns_1.isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
                this.activeDayIsOpen = false;
            }
            else {
                this.activeDayIsOpen = true;
            }
        }
    };
    LeaveComponent.prototype.addMonthsOfYear = function (inc) {
        this.classactive = inc;
        var date = this.viewDate;
        date = date_fns_1.setMonth(date, inc);
        date = date_fns_1.setYear(date, this.year);
        this.viewDate = date;
        this.month = inc + 1;
        this.bindallData();
        this.bindalldatabymonth();
    };
    LeaveComponent.prototype.eventTimesChanged = function (_a) {
        var event = _a.event, newStart = _a.newStart, newEnd = _a.newEnd;
        event.start = newStart;
        event.end = newEnd;
        this.handleEvent('Dropped or resized', event);
        this.refresh.next();
    };
    LeaveComponent.prototype.handleEvent = function (action, event) {
        this.modalData = { event: event, action: action };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    LeaveComponent.prototype.addNewEvent = function (action, event) {
        this.submitted = false;
        this.disableBtn = true;
        this.leave = new leave_model_1.Leave();
        this.Userid = localStorage.getItem('userid');
        this.leave.employeeid = this.Userid;
        this.leave.start = new Date(); //new Date().toLocaleDateString();// new Date(start);
        this.leave.end = new Date(); //new Date().toLocaleDateString(); //new Date(start);
        this.leave.holidaytype = "";
        this.leave.employeeholidayapplyid = '0';
        this.leave.holidayremarkid = '0';
        this.leave.halfday = 0;
        this.leaveformGroup.reset(this.leave);
        this.modalData = { action: action, event: event };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    LeaveComponent.prototype.addEvent = function (action, event, start) {
        this.submitted = false;
        this.disableBtn = true;
        // this.endPicker.value = '';
        // this.leaveformGroup.reset();
        this.leave = new leave_model_1.Leave();
        this.Userid = localStorage.getItem('userid');
        this.leave.employeeid = this.Userid;
        var newDate = new Date(start);
        this.leave.start = newDate; //new Date(start);
        this.minstartDate = this.leaveavailabledate;
        this.minendDate = newDate;
        this.leave.end = newDate; //new Date(start);
        this.leave.holidaytype = "";
        this.leave.employeeholidayapplyid = '0';
        this.leave.holidayremarkid = '0';
        this.leave.halfday = 0;
        this.leaveformGroup.reset(this.leave);
        this.modalData = { action: action, event: event };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    LeaveComponent.prototype.onDateChange = function (eventvalue) {
        // console.log(eventvalue);
        // this.minendDate = eventvalue.value;
        // this.leave.end = eventvalue.value;
    };
    LeaveComponent.prototype.editEvent = function (action, event) {
        var _this = this;
        this.submitted = false;
        this.disableBtn = true;
        this.leave = new leave_model_1.Leave();
        this.leave.start = new Date(); //new Date().toLocaleDateString();// new Date(start);
        this.leave.end = new Date(); //new Date().toLocaleDateString(); //new Date(start);
        this.Userid = localStorage.getItem('userid');
        this.leave.employeeid = this.Userid;
        var employeeholidayapplyid = event.id;
        this.minstartDate = this.leaveavailabledate;
        this.minendDate = this.leaveavailabledate;
        this.data.getLeaveData({ 'id': employeeholidayapplyid }).subscribe(function (data) {
            _this.leave.title = data.title;
            _this.leave.start = data.startdate;
            _this.leave.end = data.enddate;
            _this.leave.employeeholidayapplyid = data.employeeholidayapplyid;
            _this.leave.holidayremarkid = data.holiodayremarksid;
            _this.leave.holidayremark = data.remark.holidayremark;
            _this.leave.holidaytype = data.holidaytypeid;
            _this.leave.halfday = data.halfday;
            _this.leave.hstatus = data.hstatus;
        });
        this.modalData = { action: action, event: event };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    LeaveComponent.prototype.editEventOfList = function (action, id, alleventpopid, event) {
        var _this = this;
        this.submitted = false;
        this.disableBtn = true;
        this.leave = new leave_model_1.Leave();
        this.leave.start = new Date(); //new Date().toLocaleDateString();// new Date(start);
        this.leave.end = new Date(); //new Date().toLocaleDateString(); //new Date(start);
        this.Userid = localStorage.getItem('userid');
        this.leave.employeeid = this.Userid;
        var employeeholidayapplyid = id;
        this.data.getLeaveData({ 'id': employeeholidayapplyid }).subscribe(function (data) {
            _this.leave.title = data.title;
            _this.leave.start = data.startdate;
            _this.leave.end = data.enddate;
            _this.leave.employeeholidayapplyid = data.employeeholidayapplyid;
            _this.leave.holidayremarkid = data.holiodayremarksid;
            _this.leave.holidayremark = data.remark.holidayremark;
            _this.leave.holidaytype = data.holidaytypeid;
            _this.leave.hstatus = data.hstatus;
        });
        this.closeModal(alleventpopid);
        this.modalData = { action: action, event: event };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    LeaveComponent.prototype.titleChange = function () {
        var _this = this;
        this.leavetypelist.forEach(function (element) {
            if (element.id == _this.leave.holidaytype) {
                _this.leave.title = element.typename;
            }
        });
    };
    return LeaveComponent;
}());
exports.LeaveComponent = LeaveComponent;
