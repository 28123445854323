import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { Visa } from '../../../models/visa/visa.model';
import { Aml,AmlBussiness } from '../../../models/aml/aml.model';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { AmlService } from '../../../services/aml.service';
import {  ElementRef, HostListener, Input } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { CountryService } from '../../../services/country.service';
import { ChecksystemService } from '../../../services/checksystem.service';


import { int } from 'aws-sdk/clients/datapipeline';
import {Observable} from 'rxjs/Observable';
import {startWith} from 'rxjs/operators/startWith';
import {map} from 'rxjs/operators/map';
import { DateTime } from 'aws-sdk/clients/devicefarm';

export class State {
  constructor(public countryid: int, public countryname: string, public createat: Date,public isid: string, public iso: string, public isothree: string, public ispassport: string, public isrighttoworkcountry: string,
    public isvisa: string,
    public sstatus: string,
    public updateat: DateTime) { }
}


@Component({
  selector: 'app-searchbussinessaml',
  templateUrl: './searchbussinessaml.component.html',
  styleUrls: ['./searchbussinessaml.component.css']
})
export class SearchbussinessamlComponent implements OnInit {

  searchrecord:any =false;
  disableBtn: any;
  error: boolean;  
  buttoninsert: any;
  submitted: boolean;  
  countrySelectsString = '';
  errormsg: any;
  bussinessformGroup: FormGroup;
  businessDetail =new AmlBussiness();
  searchresult: any;
  result: boolean;
  defaultVal: string ='';
  countrylist:any;
  datasetlist: any;
  bussinessdetails:any;
  lesssore:boolean;
  checkedAll: any = false;
  resourceId:any;
  Threshold:any;
  issoe:boolean = true;
  issan:boolean= true;
  showdataset:boolean =true;
  countryerror:boolean =false;
  url:any;
  downloadtext: any;


  historytext:any;


  selectpeplist=[
    {
      "id":"PEP",
      "name":"PEP",
      "heading": "Politically exposed person",
      "description": "Any individual that is considered a Politically Exposed Person in their own capacity or by association."
    },
    {
      "id":"SAN",
      "name":"Sanctions",
      "heading": "Sanctions",
      "description": "Any individual or entity that is or has formerly been subject to sanctions at international, regional, or national levels."
    },
    {
      "id":"RRE",
      "name":"Reputational Risk Exposure",
      "heading": "Reputational Risk Exposure",
      "description": "Any individual or entity that has been reported in official or media sources to be involved in a crime that falls under one or more of the following categories – terrorism, financial crime and fraud, modern slavery, organised crime, bribery and corruption, and cybercrime."
    },
    {
      "id":"INS",
      "name":"Insolvency (UK & Ireland)",
      "heading": "Insolvency",
      "description": "Any person that has been declared as bankrupt or insolvent."
    },
    {
      "id":"POI",
      "name":"Profile of Interest",
      "heading": "Profile of Interest",
      "description": "Natural or legal persons that were profiled based on Complygate Risk Intelligence’s data model and can be linked to outdated red flags, such as expired PEP status, reputational risk, or regulatory measures no longer in force."
    },
    {
      "id": "REL",
      "name":"Regulatory Enforcement List",
      "heading": "Regulatory Enforcement List",
      "description": "Any individual or entity that has been fined or in some other way subject to action by any financial regulatory body or law enforcement authority. "
    },
    {
      "id": "SOE",
      "name": "State-Owned Enterprise",
      "heading": "State-Owned Enterprise",
      "description": "Profiles of companies in which the state exercises control or ownership."
    },
    {
      "id": "CUK",
      "name": "Company - UK",
      "heading": "State-Owned Enterprise",
      "description": "Profiles of companies in which the state exercises control or ownership."
    },
    {
      "id": "CG",
      "name": "Company - Global",
      "heading": "State-Owned Enterprise",
      "description": "Profiles of companies in which the state exercises control or ownership."
    }
  ];
  countryuserSelects = [];
  userSelects = [];
  suggestions = [{"id":"PEP-FORMER","name":"Former"},{"id":"PEP-CURRENT","name":"Current"},{"id":"PEP-LINKED","name":"Linked"}];


  peplist = [
    {
      "id":"PEP",
      "name":"PEP",
      "heading": "Politically exposed person",
      "description": "Any individual that is considered a Politically Exposed Person in their own capacity or by association."
    },
    {
      "id":"SAN",
      "name":"Sanctions",
      "heading": "Sanctions",
      "description": "Any individual or entity that is or has formerly been subject to sanctions at international, regional, or national levels."
    },
    {
      "id":"RRE",
      "name":"Reputational Risk Exposure",
      "heading": "Reputational Risk Exposure",
      "description": "Any individual or entity that has been reported in official or media sources to be involved in a crime that falls under one or more of the following categories – terrorism, financial crime and fraud, modern slavery, organised crime, bribery and corruption, and cybercrime."
    },
    {
      "id":"INS",
      "name":"Insolvency (UK & Ireland)",
      "heading": "Insolvency",
      "description": "Any person that has been declared as bankrupt or insolvent."
    },
    {
      "id":"POI",
      "name":"Profile of Interest",
      "heading": "Profile of Interest",
      "description": "Natural or legal persons that were profiled based on Complygate Risk Intelligence’s data model and can be linked to outdated red flags, such as expired PEP status, reputational risk, or regulatory measures no longer in force."
    },
    {
      "id": "REL",
      "name":"Regulatory Enforcement List",
      "heading": "Regulatory Enforcement List",
      "description": "Any individual or entity that has been fined or in some other way subject to action by any financial regulatory body or law enforcement authority. "
    },
    {
      "id": "SOE",
      "name": "State-Owned Enterprise",
      "heading": "State-Owned Enterprise",
      "description": "Profiles of companies in which the state exercises control or ownership."
    },
    {
      "id": "CUK",
      "name": "Company - UK",
      "heading": "State-Owned Enterprise",
      "description": "Profiles of companies in which the state exercises control or ownership."
    },
    {
      "id": "CG",
      "name": "Company - Global",
      "heading": "State-Owned Enterprise",
      "description": "Profiles of companies in which the state exercises control or ownership."
    }
  ];


  type:any;
  atype:any;
  show: boolean = false;
  countryshow: boolean = false;

  @Input() makeDifferent;
  
  stateCtrl: FormControl;
  filteredStates: Observable<any[]>;

  states: State[];
  logid: any;

  constructor(
    private modalService: ModalService, 
    private formBuilder: FormBuilder,
    private amldetails: AmlService,    
    private router: Router,    
    private country: CountryService, 
    private check: ChecksystemService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {

    
    this.downloadtext = 'No Record Found <i class="fa fa-plus ml-2"></i>';
    
    
    this.type = this.activatedRoute.snapshot.paramMap.get("type");
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    // this.permission = permission[0];
    
    this.issoe = true;
    this.Threshold = 80;
    this.issan = true;
    this.countryerror =false;

    if(permission[0]['m96'] < 3)
    {
      this.router.navigate(['/permission-denied']);
    }



    if(this.type == 'aml')
      {
        this.historytext ='View AML, PEPs & Sanction';
        this.atype =1;
          if(permission[0]['m96'] < 3)
          {
            this.router.navigate(['/permission-denied']);
          }
      } 
      else if(this.type == 'global')
      {
        this.historytext ='View Global Due Diligence';
        this.atype =4;
        if(permission[0]['m189'] < 3)
          {
            this.router.navigate(['/permission-denied']);
          }
      }
     



    this.error =false;
    this.buttoninsert ="Search";
    this.result =false;
    
    this.disableBtn =true;
    this.errormsg ='';
    this.defaultVal ="";
    this.bussinessformGroup = this.formBuilder.group({
      BusinessName: ['', Validators.required],
      // Country: ['', Validators.required],
      stateCtrl: [''],   
      soeSanctions:[],     
      sanctionstatus: [''],
      Threshold: [''],   
      // City: [''] ,
      // Postcode: [''],
      // Address: [''],      
      // PEP: [''],
      // PreviousSanctions: [''],
      // CurrentSanctions: [''],
      // LawEnforcement: [''],
      // FinancialRegulator: [''],
      // Insolvency: [''],
      // DisqualifiedDirector: [''],
      // AdverseMedia: ['']
    });
    this.businessDetail =new AmlBussiness(); 
    this.stateCtrl = new FormControl();

    
    this.country.getcountrylist().subscribe((country: any) => {

     


      this.countrylist = country;

      console.log('Coountry List', this.countrylist);

      this.states = country;
      
    
    this.filteredStates = this.stateCtrl.valueChanges
      .pipe(startWith(''),map(state => state ? this.filterStates(state) : this.states.slice())); 

    });

    this.logid = this.activatedRoute.snapshot.paramMap.get("id");



    this.bindandselectlist();
    
    if(this.logid){


      var val ={ id: this.logid,atype:this.atype}
  

  this.amldetails.getCompanylogrecord(val).subscribe((searchRes: any) => { 
    var listmaster =JSON.parse(searchRes.searchresult);

    console.log('list Search',listmaster);
   
    if(searchRes.iscomplete === 0)
    {
      if(listmaster.results.matchCount >0)
      {
        this.result=true;
        this.searchresult = listmaster.results;
      }
      else
      {
        localStorage.setItem('personalid', searchRes.id);    
        this.router.navigate(['/search-business-aml-details', searchRes.id]); 
      }
    }
    else
    {      
      localStorage.setItem('personalid', searchRes.id);    
      this.router.navigate(['/search-business-aml-details', searchRes.id]); 
    }
  });
}
else
{

    // this.amldetails.getCountries().subscribe((countrydata: any) => { 
    //   this.countrylist = countrydata;
    //   this.businessDetail.Country ="";
    // });

    this.lesssore =false;


    if(this.type == 'global')
      {
        var ts  = { 'serviceid': 23}; 
      } 
     else {
          var ts  = { 'serviceid': 8}; 
        }


    // var ts  = { 'serviceid': 8};    
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      // console.log(remaindata)
      if(remaindata.remiancheck <= 0)
      {
          this.lesssore =true;
      }
    });
  }
  }
  get f() { return this.bussinessformGroup.controls; }

      
  filterStates(name: string) {
    return this.states.filter(state =>
      state.countryname.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }


  
clearpersonal(){
  this.submitted = false;
  // this.bussinessformGroup.reset();
  this.businessDetail =new AmlBussiness();

  this.businessDetail.BusinessName = "";
  this.businessDetail.Country = "";
  
  this.businessDetail.Threshold = "";
  
  this.businessDetail.Postcode = "";
  this.businessDetail.Address = "";
  
  this.businessDetail.County = "";
  this.businessDetail.City = "";
  this.businessDetail.PEP = false;
  this.businessDetail.CurrentSanctions = false;
  this.businessDetail.DisqualifiedDirector =false;
  this.businessDetail.FinancialRegulator =false;
  this.businessDetail.Insolvency =false;
  this.businessDetail.LawEnforcement= false;
  this.businessDetail.AdverseMedia =false;
  this.businessDetail.PreviousSanctions =false;
  this.checkedAll = false; 
}


amllogredirect()
{

  if(this.type == 'aml')
    this.router.navigate(['/check-aml-log-list', '1']);
  else if(this.type == 'global')
    this.router.navigate(['/check-aml-log-list', '4']);
}


selectall(val){
  if(val == true){
  this.checkedAll = true;
  this.businessDetail.PEP = true;
  this.businessDetail.CurrentSanctions = true;
  this.businessDetail.DisqualifiedDirector =true;
  this.businessDetail.FinancialRegulator =true;
  this.businessDetail.Insolvency =true;
  this.businessDetail.LawEnforcement= true;
  this.businessDetail.AdverseMedia =true;
  this.businessDetail.PreviousSanctions =true;
  }
}


getPosts(val)
{

  var fakeobj = this.countrylist.filter(s => s.iso == val);

  this.countryuserSelects.find((item) => item.iso === val) ? 
  this.countryuserSelects = this.countryuserSelects.filter((item) => item.iso !== val) :
  this.countryuserSelects.push(fakeobj[0]);

  // this.countryuserSelects.push(s);
  
  if(this.countryuserSelects.length>0)
  { 
    this.countryerror =false;
  }

  this.countryshow =false;
  // this.stateCtrl = new FormControl('');

  this.stateCtrl = new FormControl();
  this.filteredStates = this.stateCtrl.valueChanges
    .pipe(startWith(''),map(state => state ? this.filterStates(state) : this.states.slice()));    
  
}


selectallfalse(val){
  if(val == false){
    this.checkedAll = false;
  }
}

  onSubmit(val,type)
  {
    this.error =false;

    this.submitted = true; 
    if (this.bussinessformGroup.invalid) {
      return;
    }


    var countrylist=[];
    this.countryuserSelects.forEach(element => {
      countrylist.push(element.iso);
    });

    
  if(countrylist.length==0)
  {
    this.countryerror = true;
    return;
  }

  var pep = [];
    
  if(this.selectpeplist.length ==0)
  {
    this.error =true;
    return ;
  } 
  else
  {
    this.selectpeplist.forEach(el => {
      pep.push(el.id);
    });
  }
    

  
  if(!this.Threshold)
  {
    val.threshold=50;
  }
  else
  {
    val.threshold = this.Threshold;
  }



    var dd = { 
      name: val.BusinessName,
      countries:countrylist,
      threshold: val.threshold,     
      gender:val.gender,
      datasets: pep,
      atype: this.atype
    }
    


    // if(val.PEP ==false && val.PreviousSanctions ==false && val.CurrentSanctions ==false && val.LawEnforcement ==false && val.FinancialRegulator ==false && val.Insolvency ==false && val.DisqualifiedDirector ==false &&  val.AdverseMedia ==false)
    // {
    //   this.error =true;
    //   return;
    // }
    
    if(type == 'first'){
      
      this.disableBtn =false;
      if(this.type == 'global')
        {
          var ts  = { 'serviceid': 23}; 
        } 
        else if(this.type == 'adverse')
          {
            var ts  = { 'serviceid': 22}; 
          }else {
            var ts  = { 'serviceid': 8}; 
          }

      // var ts  = { 'serviceid': 8};   
      this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
        // console.log(remaindata)
        if(remaindata.remiancheck <= 0)
        {   
          this.lesssore =true;
          this.modalService.open('upgrade-plan');
        }else
        {  
          this.disableBtn =true;
          this.lesssore =false;
          this.modalService.open('pdf-payment-confirmation');          
        }
      });
    }
    else if (type == 'payment')
    {

    // console.log(val);
    // var db= {};
    
    // db ={name: val.name, countries: val.countries};
      

    // if(val.houseNo != '')
    // {
    //   db['houseNo'] = val.houseNo;
    // }

    // if(val.postCode != '')
    // {
    //   db['postCode'] = val.postCode;
    // }

    // if(val.street != '')
    // {
    //   db['street'] = val.street;
    // }

    // if(val.city !=''){
    //   db['city'] = val.city;
    // }

    // console.log(db);

    // this.amldetails.getListofCompanyPreDefinedSearches().subscribe((searchRes: any) => {
      
    //   console.log(searchRes)
    
    // });
    
  this.disableBtn =false;

 this.searchrecord =true;
  this.result =true; 

  setTimeout(() => {
    this.result =false; 

    // this.searchrecord =false;

    this.amldetails.getCompanyNewSearch(dd).subscribe((searchRes: any) => { 

      this.errormsg ='';
      if(searchRes.error == ''){

        this.searchrecord =false;
        if(searchRes.result.matchCount >0)
          this.result =true;     
        else 
          this.router.navigate(['/search-business-aml-details', searchRes.id]);  

      this.disableBtn =true;
      this.searchresult = searchRes.result;

      this.logid =searchRes.id;

      }else if(searchRes.error == 'amlfail')
      {
          this.errormsg ='We have some issue in AML & PEPs check. Please contact Administrator';
        
      }else  if(searchRes.error == 'paymentfail')
      {
        this.errormsg ='Your payment fail. Please contact Administrator';
      }



    //   this.errormsg ='';
    //   this.logid =searchRes.id;

    //   if(searchRes.error == '')
    //   {
    //     if(searchRes.result.matchCount >0)
    //     {
    //       this.result =true;
    //     }        

    //   this.disableBtn =true;
    // // localStorage.setItem('personalid', searchRes.id);    
    // // this.router.navigate(['/buscreditsearchresult', searchRes.id]); 

    //   console.log(searchRes.result);

    //   this.searchresult = searchRes.result; 
    // }
    // else if(searchRes.error == 'amlfail')
    //   {
    //       this.errormsg ='We have some issue in AML & PEPs check. Please contact Administrator';
        
    //   }else  if(searchRes.error == 'paymentfail')
    //   {
    //     this.errormsg ='Your payment fail. Please contact Administrator';
    //   }
    });
  }, 9000); 
  }
    
  }


  onSubmitsearch(val,type)
  {
    this.error =false;
    this.submitted = true; 
    // if (this.bussinessformGroup.invalid) {
    //   return;
    // }
    var countrylist=[];
    this.countryuserSelects.forEach(element => {
      countrylist.push(element.iso);
    });
    


    // if(val.PEP ==false && val.PreviousSanctions ==false && val.CurrentSanctions ==false && val.LawEnforcement ==false && val.FinancialRegulator ==false && val.Insolvency ==false && val.DisqualifiedDirector ==false &&  val.AdverseMedia ==false)
    // {
    //   this.error =true;
    //   return;
    // }
    
    if(type == 'first' && this.logid ==0){
      
      this.disableBtn =false;
      var ts  = { 'serviceid': 8};   
      this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
        // console.log(remaindata)
        if(remaindata.remiancheck <= 0)
        {   
          this.lesssore =true;
          this.modalService.open('upgrade-plan');
        }else
        {  
          this.disableBtn =true;
          this.lesssore =false;
          this.resourceId =val;
          this.modalService.open('pdf-payment-confirmation');          
        }
      });
    }
    else if (type == 'payment' || this.logid != 0)
    {
      
      val.id =this.logid;


      this.router.navigate(['/search-business-aml-details', this.logid, val.resourceId]); 
      


    // console.log(val);
    // var db= {};
    
    // db ={name: val.name, countries: val.countries};
      

    // if(val.houseNo != '')
    // {
    //   db['houseNo'] = val.houseNo;
    // }

    // if(val.postCode != '')
    // {
    //   db['postCode'] = val.postCode;
    // }

    // if(val.street != '')
    // {
    //   db['street'] = val.street;
    // }

    // if(val.city !=''){
    //   db['city'] = val.city;
    // }

    // console.log(db);

    // this.amldetails.getListofCompanyPreDefinedSearches().subscribe((searchRes: any) => {
      
    //   console.log(searchRes)
    
    // });
    
/* Lock is iscomplete  */

  // this.disableBtn =false;
  //   this.amldetails.getCompanyNewSearchDetails(val).subscribe((searchRes: any) => { 


  //     console.log(searchRes);
  //     this.errormsg ='';
  //     if(searchRes.error == ''){
 
  //       localStorage.setItem('personalid', searchRes.id);    
  //      this.router.navigate(['/search-business-aml-details', searchRes.id]);    
  //       // this.searchresult = searchRes.result;


  //     }else if(searchRes.error == 'amlfail')
  //     {
  //         this.errormsg ='We have some issue in AML & PEPs check. Please contact Administrator';
        
  //     }else  if(searchRes.error == 'paymentfail')
  //     {
  //       this.errormsg ='Your payment fail. Please contact Administrator';
  //     }



  //   });

    /* End Locked complate */
   }
    
  }


  
checkpep(val)
{
  return this.selectpeplist.findIndex((item) => item.id === val.id) > -1;
}

countryselectallfalse(chk,val)
{
  if(chk ==true)
  {
    
    this.selectpeplist.push(val);   
  }
  else
  {
    this.selectpeplist =  this.selectpeplist.filter(item => item.id !== val.id);
  }


  if(this.selectpeplist.findIndex((item) => item.id === 'SOE') > -1)
    this.issoe = true;
  else
    this.issoe = false;


  if(this.selectpeplist.findIndex((item) => item.id === 'SAN') > -1)
    this.issan = true;
  else
    this.issan = false;
}




dataset(values){

  this.datasetlist ="";
  values.forEach(element_use => {    
    this.peplist.forEach(element => {
      if(element_use == element.id){
        this.datasetlist +=element.name+',';
      }      
    });
  });
  

  this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1); 

}

  

  countrysuggest() 
  {    
    this.show =false;
    if( this.countryshow)
    {
      this.countryshow = false; 
    }else{
    this.countryshow = true; 
  }
}

  iscountrySelected(s:any) {
   return this.countryuserSelects.findIndex((item) => item.iso === s.iso) > -1 ? true : false;
  }  

  selectcountrySuggestion(s) {
    this.countryuserSelects.find((item) => item.iso === s.iso) ? 
    this.countryuserSelects = this.countryuserSelects.filter((item) => item.iso !== s.iso) :
    this.countryuserSelects.push(s);
    this.countryshow =false;
  }

  deletecountrySelects(s) {
    this.countryuserSelects = this.countryuserSelects.filter((item) => item.iso !== s.iso);
    this.countryshow =false;
  }

  countryassignToNgModel() {
    this.countrySelectsString = '';
    this.countryuserSelects.map((item) => this.countrySelectsString += item.countryname + ' ');
  }


  openModal(id: string, data: any) {
    
    this.bussinessdetails = data;
   // console.log(data);
    this.buttoninsert = "Update";
    this.modalService.open(id);
  }
  closeModal(id: string) 
  {
      this.modalService.close(id);
  }


  
/**************Start Data Set Design Setup****************/

choosedatabase()
{
  this.showdataset =true;
}

clearalldataset()
{
  this.selectpeplist = [];
  this.issoe =false;
  this.issan =false;
}


selectalldataset()
{
  this.selectpeplist = [
    {
      "id":"PEP",
      "name":"PEP",
      "heading": "Politically exposed person",
      "description": "Any individual that is considered a Politically Exposed Person in their own capacity or by association."
    },
    {
      "id":"SAN",
      "name":"Sanctions",
      "heading": "Sanctions",
      "description": "Any individual or entity that is or has formerly been subject to sanctions at international, regional, or national levels."
    },
    {
      "id":"RRE",
      "name":"Reputational Risk Exposure",
      "heading": "Reputational Risk Exposure",
      "description": "Any individual or entity that has been reported in official or media sources to be involved in a crime that falls under one or more of the following categories – terrorism, financial crime and fraud, modern slavery, organised crime, bribery and corruption, and cybercrime."
    },
    {
      "id":"INS",
      "name":"Insolvency (UK & Ireland)",
      "heading": "Insolvency",
      "description": "Any person that has been declared as bankrupt or insolvent."
    },
    {
      "id":"POI",
      "name":"Profile of Interest",
      "heading": "Profile of Interest",
      "description": "Natural or legal persons that were profiled based on Complygate Risk Intelligence’s data model and can be linked to outdated red flags, such as expired PEP status, reputational risk, or regulatory measures no longer in force."
    },
    {
      "id": "DD",
      "name":"Disqualified Director",
      "heading": "Disqualified Director",
      "description": "Any individual that has been disqualified as acting as a director of a company."
    },
    {
      "id": "SOE",
      "name": "State-Owned Enterprise",
      "heading": "State-Owned Enterprise",
      "description": "Profiles of companies in which the state exercises control or ownership."
    },
    {
      "id": "OCP",
      "name": "Company House",
      "heading": "State-Owned Enterprise",
      "description": "Profiles of companies in which the state exercises control or ownership."
    }
  ];
  this.issoe =true;
  this.issan =true;
}

closedataset()
{
  this.showdataset =false;
}
/**************End Data Set Design Setup****************/


bindallpep(val)
{ 
  if(val)
  {
    return this.suggestions.filter(d => val.includes(d.id));;
  }
  else
    return [];
  // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
}
 
bindallcountry(val)
{ 
  if(val)
  {
    return this.countrylist.filter(d => val.includes(d.iso));;
  }
  else
    return [];
  // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
}



norecordfound(){    
  this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
  this.amldetails.generatenorecordv3Pdf({ id: this.logid,atype:1}).subscribe((pdfRes: any) => { 
    console.log(pdfRes.response_data.Location);
    this.url = pdfRes.response_data.Location;
    this.downloadtext = 'No Record Found <i class="fa fa-plus ml-2"></i>';
  });
}


bindandselectlist()
{

  if(this.type == 'aml')
    {
      this.peplist =  this.peplist.filter(item => item.id !== 'CUK');
      this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'CUK');

      this.peplist =  this.peplist.filter(item => item.id !== 'CG');
      this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'CG');

     

    }

   


}

}