"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_model_1 = require("../../models/employee.model");
var country_service_1 = require("../../services/country.service");
var employee_service_1 = require("../../services/employee.service");
var cos_service_1 = require("../../services/cos.service");
var excel_service_1 = require("../../services/excel.service");
var CosreportComponent = /** @class */ (function () {
    function CosreportComponent(excelService, formBuilder, country, employee, cosreport) {
        this.excelService = excelService;
        this.formBuilder = formBuilder;
        this.country = country;
        this.employee = employee;
        this.cosreport = cosreport;
        this.employeeDetail = new employee_model_1.Employee();
        this.exceldata = [];
    }
    CosreportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.EmployeeForm = this.formBuilder.group({
            emailid: [''],
            phonenumber: [''],
            ninumber: [''],
            mincosstartdate: [''],
            maxcosstartdate: [''],
            mincosexpirydate: [''],
            maxcosexpirydate: [''],
            cosnumber: [''],
            iscurrent: ['']
        });
        var values3 = {};
        this.cosreport.getcoslist(values3).subscribe(function (cosdetails) {
            _this.data = cosdetails;
        });
    };
    CosreportComponent.prototype.exportAsXLSX = function () {
        var _this = this;
        this.exceldata = [];
        this.data.forEach(function (obj) {
            if (obj.iscurrent == false) {
                var current = "No";
            }
            else if (obj.iscurrent == true) {
                current = "Yes";
            }
            _this.employeecosdata = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "COS Number": obj.cosnumber,
                "Start Date": obj.cosstartdate,
                "End Date": obj.cosexpirydate,
                "Is Current": current
            };
            _this.exceldata.push(_this.employeecosdata);
        });
        this.excelService.exportAsExcelFile(this.exceldata, 'COS_Report');
    };
    CosreportComponent.prototype.onSubmit = function (value3) {
        var _this = this;
        this.cosreport.getcoslist(value3).subscribe(function (cosdetails) {
            _this.data = cosdetails;
        });
    };
    return CosreportComponent;
}());
exports.CosreportComponent = CosreportComponent;
