<div class="bg">
  <app-header></app-header>


  <div class="section-wrapper">
    <div class="container">
      <div class="tabs" id="tabs">
        <!-- <a [routerLink]="['/request/request-list']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Request List</a> -->
        <div class="clearfix"></div>
        <div class="tabs__content">
          <div class="tabs__content__item active-tab">
            <div class="content">
              <div class="row row-sm topheader">                            
                <div class="col-lg-5 text-right">
                  <app-checkcountshow></app-checkcountshow>
                </div>
                <div class="col-lg-7 text-right">
                  <div class="d-flex justify-content-end align-items-center">
                    <!-- <a class="boxbtn mainheading_btn ml-1"   (click)="uploadByAdmin(dbstype, 'add-new-applicant')" > + Add New Applicant </a>                                    -->
                    <button class="btn com_btn btn-success"[routerLink]="['/dbs/'+dbstype]"  type="button">
                      <span class="lnr lnr-history pr-1">                                        
                      </span> Back to Applicant List 
                    </button>
                  </div>                             
                </div>
              </div>

              <div class="row">
                  <div class="col-sm-12 aligncenter mb-3" style="justify-content:space-between;">
                      <h5 class="hedingcolor">{{h1tag}} DBS History</h5>
                      <input type="text" [(ngModel)]="searchText" class="form-control width35" placeholder="Search">
                      </div>                               
              </div>
              <div class="browser-screen-loading-content" *ngIf="!pageOfItems" >
                  <div class="loading-dots dark-gray">
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>                                
                      <i></i>
                  </div>
                  <p>Loading</p>
              </div>
              <ng-container *ngIf="( datalist | filter: {visanumber: searchText,visastart: searchText,visaexpiry: searchText  }) as pageOfItems">  
              
              <div *ngFor="let visas of pageOfItems; let i=index ;">
                <div class="job_list_section visa_request_section pb-2 pt-2 oddeven mb-0">
                  <div class="job_detils_date width18">
                    <div class="visa_user_details">
                      <p class="job_address">Name</p>
                      <h4>{{visas.employee.fname}} {{visas.employee.mname}} {{visas.employee.lname}}</h4>
                    </div>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">Phone Number</p>
                    <p class="job_profile">{{ visas.employee.phonenumber }}</p>
                  </div>
                  <div class="job_detils_date width10">
                    <p class="job_address">Check Date</p>
                    <p class="job_profile">{{visas.createat | date: 'dd MMM yyyy'}}</p>
                  </div>
                  <div class="job_detils_date width15" >
                    <p class="job_address">Address 1</p>
                    <p class="job_profile" *ngIf="visas.address1">{{visas.address1}}</p>
                    <p class="job_profile text-italic" *ngIf="!visas.address1">NA</p>
                  </div>

                  <div class="job_detils_date width15">
                    <p class="job_address">Post Code</p>
                    <p class="job_profile" *ngIf="visas.pincode">{{visas.pincode}}</p>
                    <p class="job_profile  text-italic" *ngIf="!visas.pincode">NA</p>
                  </div>
                </div>
              </div>
                
              <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                <div class="job_detils_date">
                    <p class="job_profile">No Record Found</p>
                </div>
            </div>   
            
      
          </ng-container>
           <div class="job_list_section visa_request_section" *ngIf="record == 0">
                <div class="job_detils_date">
                    <p class="job_profile">No Record Found</p>
                </div>
            </div>         
          
              <!-- <div class="row">
                <div class="col-lg-12 text-right">
                  <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
  <div role="document" class="formgroup">
      <div class="modal-content">         
          <div class="modal-body">
              <div class="container text-center">
                <div class="info_mess">
                    <div class="para">
                            <h4 class="mb-2">No credits available</h4>
                            <p class="mb-0 font20">There are no credits available to perform this action. Please add more credits.</p>

                        </div>                          
                    </div>
              </div>
          </div>
          <div class="modal-footer text-center">
            <input type="button" class="btn btn-primary com_btn" name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
            <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
              <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
          </div>
      </div>
  </div>
</jw-modal>

<jw-modal id="sendrequestcharge" role="dialog" class="modal sendrequestcharge">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-body p-4">
        <div class="container text-center">
          <div class="info_mess">
            <div class="para">
              <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
              <p class="mb-0 font20">The employee will receive an email and they need to upload the document.</p>
            </div>                          
          </div>
        </div>
      </div>
      <div class="modal-footer text-center">
        <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Confirm"  (click)="sendrequestcharge(employeeid,'sendrequestcharge')"  />
        <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('sendrequestcharge');"/>
      </div>
    </div>
  </div>
</jw-modal>


<jw-modal id="add-new-applicant" role="dialog" class="modal">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-header">
          <h5 class="modal-title"></h5>
          <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
              (click)="closeModal('add-new-applicant');">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>

      <form [formGroup]="employeeForm" #overviewForm="ngForm" class="s12 white"  (ngSubmit)="onAddEmployeeSubmit(overviewForm.value,'add-new-applicant')">
    
        <div class="modal-body">
          <div class="container">
            <div class="formgroup">
              <div class="row">
                <div class="form-group col-lg-4">
                  <label>First Name*</label>
                  <input type="fname" formControlName="fname" name="fname" id="fname" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.fname.errors }"
                      [(ngModel)]="overviewData.fname" maxlength="50">
                  <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                      <div *ngIf="f.fname.errors.required">Please enter first name</div>
                  </div>
                </div>
                <div class="form-group col-lg-4">
                  <label>Middle Name</label>
                  <input type="mname" formControlName="mname" name="mname" id="mname" class="form-control"
                        [(ngModel)]="overviewData.mname" maxlength="50">
                </div>
                <div class="form-group col-lg-4">
                  <label>Last Name*</label>
                  <input type="lname" formControlName="lname" name="lname" id="lname"
                      class="form-control input-lg"
                      [ngClass]="{ 'is-invalid': submitted && f.lname.errors }"
                      [(ngModel)]="overviewData.lname" maxlength="50">
                  <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                      <div *ngIf="f.lname.errors.required">Please enter last name</div>
                  </div>
                </div>
              </div>
      
              <div class="row">
                <div class="form-group col-lg-6">
                  <label>Email*</label>
                  <input type="emailid" (focusout)="onFocusOutEvent($event)"  (focus)="focusFunction()" maxlength="100"
                      formControlName="emailid" name="emailid" id="emailid" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }"
                      [(ngModel)]="overviewData.emailid">
                  <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                      <div *ngIf="f.emailid.errors.required">Please enter email </div>
                  </div>
                  <div class="invalid-feedback">
                      <div class="error" *ngIf="emailexist">This email is already registered</div>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label>Mobile Number*</label>
                  <international-phone-number formControlName="phonenumber"
                      class="form-control phonecontrol"
                      [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }"
                      [(ngModel)]="overviewData.phonenumber" placeholder="Enter mobile number"
                      (input)="validatenumber($event.target.value,'phone')" [maxlength]="20"
                      [required]="true" #mobilenumber name="phonenumber"></international-phone-number>
                  <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                      <div *ngIf="f.phonenumber.errors.required">Please enter mobile number</div>
                  </div>
                  <span *ngIf="phonecheck===false" style="color:red">Invalid format</span>
                  <span *ngIf="phonecheck===true" style="color:green">Valid format</span>
                </div>
              </div>
            
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Add Applicant"    />
          <input type="button" class="btn btn-secondary com_btn" name="updateform" (click)="closeModal('add-new-applicant')" value="Cancel" />
        </div>
      </form>
    </div>
  </div>
</jw-modal>





