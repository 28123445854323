"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var expense_service_1 = require("../../../services/expense.service");
var employee_service_1 = require("../../../services/employee.service");
var graphreport_service_1 = require("../../../services/graphreport.service");
var date_fns_1 = require("date-fns");
var CanvasJS = require("./../../../admin/canvasjs.min");
var app_global_1 = require("../../../app.global");
var ExpchartComponent = /** @class */ (function () {
    function ExpchartComponent(expense, employee, graphReportService) {
        this.expense = expense;
        this.employee = employee;
        this.graphReportService = graphReportService;
    }
    ExpchartComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        this.employeemonth = date_fns_1.getMonth(new Date()) + 1;
        this.employeeyear = date_fns_1.getYear(new Date());
        this.hstatus = '';
        var value3 = "{}";
        this.employee.getemployeelist(value3).subscribe(function (listdetails) {
            _this.employeelist = listdetails;
            // this.record = this.data.length;
            // if (this.record < 30) {
            //   this.pageOfItems = this.data;
            // }
            // else {
            //   this.items = this.data;
            // }
        });
        this.Userid = localStorage.getItem('userid');
        var sstatustype = { 'status': true, 'id': this.Userid };
        this.expense.getexpenseType(sstatustype).subscribe(function (expensedetails) {
            _this.expenselist = expensedetails;
        });
        this.monthlyexpensetypereport();
        this.malefemalereport();
    };
    ExpchartComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    ExpchartComponent.prototype.monthlyexpensetypereport = function () {
        var _this = this;
        this.graphReportService.monthlyexpensetypereport({ 'year': this.employeeyear, 'employeeid': this.empid, 'hstatus': this.hstatus, 'expensetypeid': this.exptype }).subscribe(function (expensedata) {
            _this.employeeexpensedata = expensedata;
            var graphdataarray = [];
            expensedata.forEach(function (obj, index) {
                graphdataarray.push({ y: obj.totalamount, label: obj.monthname });
            });
            _this.getmonthlyexpensegraph(graphdataarray);
        });
    };
    ExpchartComponent.prototype.malefemalereport = function () {
        var _this = this;
        this.graphReportService.getexpensegraphList({ 'month': this.employeemonth, 'year': this.employeeyear, 'hstatus': this.hstatus }).subscribe(function (expdata) {
            _this.data = expdata;
            _this.expensecountlist = expdata;
            var totalgraphdataarray = [];
            var malegraphdataarray = [];
            var femalegraphdataarray = [];
            expdata.forEach(function (obj, index) {
                totalgraphdataarray.push({ x: index + 1, y: obj.count, label: obj.typename.substring(0, 20) });
                // malegraphdataarray.push({ x: index + 1, y: obj.mcount, label: obj.typename.substring(0,20)  });
                // femalegraphdataarray.push({ x: index + 1, y: obj.fcount, label: obj.typename.substring(0,20) });
            });
            var data = [
                {
                    type: "stackedBar",
                    name: "Employee",
                    labelFontSize: 10,
                    showInLegend: "true",
                    xValueFormatString: "DD, MMM",
                    yValueFormatString: "#0",
                    dataPoints: totalgraphdataarray
                }
                // {
                //   type: "stackedBar",
                //   name: "Male",
                //   labelFontSize: 10,
                //   showInLegend: "true",
                //   xValueFormatString: "DD, MMM",
                //   yValueFormatString: "#0",
                //   dataPoints: malegraphdataarray
                // },
                // {
                //   type: "stackedBar",
                //   name: "Female",
                //   labelFontSize: 10,
                //   showInLegend: "true",
                //   xValueFormatString: "DD, MMM",
                //   yValueFormatString: "#0",
                //   dataPoints: femalegraphdataarray
                // }
            ];
            _this.companyexensegraphreport(data);
        });
    };
    ExpchartComponent.prototype.companyexensegraphreport = function (data) {
        var chart = new CanvasJS.Chart("chartContainer2", {
            animationEnabled: true,
            title: {
                text: ""
            },
            axisX: {
                valueFormatString: "DDD",
                interval: 1,
                labelFontSize: 10
            },
            axisY: {
                prefix: ""
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                itemclick: toggleDataSeries
            },
            data: data
        });
        chart.render();
        function toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            }
            else {
                e.dataSeries.visible = true;
            }
            chart.render();
        }
    };
    ExpchartComponent.prototype.getmonthlyexpensegraph = function (data) {
        var chart = new CanvasJS.Chart("chartContainer", {
            animationEnabled: true,
            exportEnabled: true,
            title: {
                text: ""
            },
            data: [{
                    type: "column",
                    dataPoints: data
                }]
        });
        chart.render();
    };
    ExpchartComponent.prototype.changeEmployee = function (id) {
        this.empid = id;
        this.monthlyexpensetypereport();
        this.malefemalereport();
    };
    ExpchartComponent.prototype.changeEmployeeMonth = function (month) {
        this.employeemonth = month;
        this.malefemalereport();
    };
    ExpchartComponent.prototype.changeEmployeeYear = function (year) {
        this.employeeyear = year;
        this.monthlyexpensetypereport();
        this.malefemalereport();
    };
    ExpchartComponent.prototype.changeEmployeestatus = function (hstatus) {
        this.hstatus = hstatus;
        this.monthlyexpensetypereport();
        this.malefemalereport();
    };
    ExpchartComponent.prototype.changeExpenseType = function (exptype) {
        this.exptype = exptype;
        this.monthlyexpensetypereport();
        this.malefemalereport();
    };
    return ExpchartComponent;
}());
exports.ExpchartComponent = ExpchartComponent;
