import { Component, OnInit } from '@angular/core';
import { MyemploymentService } from '../services/myemployment.service';
import { EmployeeService } from '../services/employee.service';
import { ModalService } from '../_services/modal.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-myemployment',
  templateUrl: './myemployment.component.html',
  styleUrls: ['./myemployment.component.css']
})

export class MyemploymentComponent implements OnInit {
  empltype: any;
  employeedetails: any [] = null;
  otherDetail: any;
  Userid: any;
  iframeempsrc: any;
  imgempsrc: any;

  constructor(
    private sanitizer: DomSanitizer,
    private data: MyemploymentService,
    private employeeService: EmployeeService,
    private modalService: ModalService,
    ) { }

  ngOnInit() {
    this.Userid = localStorage.getItem('userid');
    this.empltype =  localStorage.getItem('emptype');
    const data = { 'userid': this.Userid };
    this.employeeService.getOtherInfo(data).subscribe((data: any) => {     
      this.otherDetail = data;
    });
    this.data.getemploymenttList().subscribe((data: any) => {
      this.employeedetails = data;
    });
  }

  closeModal(id: string) {   
    this.modalService.close(id);
  }

  openModal3(id: string, data: any) {
    var fullpathArray = data.split('.');
    this.iframeempsrc ='';
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif'))
    {
     this.imgempsrc = true;
     this.iframeempsrc = data; 
    }
    else{
     this.imgempsrc = false;
     this.iframeempsrc = this.getSafeUrl("https://docs.google.com/gview?url="+data+"&embedded=true");
    }
     this.modalService.open(id);
   }
   getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
}