import { Component, OnInit } from '@angular/core';
import { GraphreportService } from '../../../services/graphreport.service';
import { EmployeeService } from '../../../services/employee.service';
import * as $ from 'jquery';
import * as CanvasJS from './../../../admin/canvasjs.min';
import { getMonth, getYear } from 'date-fns';
import { AppGlobals } from '../../../app.global';

@Component({
  selector: 'app-leavechart',
  templateUrl: './leavechart.component.html',
  styleUrls: ['./leavechart.component.css']
})

export class LeavechartComponent implements OnInit {
  leavedatalist: any;
  employeeleavedata: any;
  data: any;
  empid: any;
  employeemonth: any;
  employeeyear: any;
  hstatus: any;
  yearList: any;
  employeelist:any;

  constructor(
    private employee: EmployeeService,
    private graphReportService: GraphreportService
  ) { }

  ngOnInit() {
    this.yearList = AppGlobals.YEAR_VALUES;
    this.employeemonth = getMonth(new Date()) + 1;
    this.employeeyear = getYear(new Date());
    this.hstatus = '1';
    this.empid = '';

    
  var value3 = "{}";
  this.employee.getemployeelist(value3).subscribe((listdetails: any) => {
    this.employeelist = listdetails;
  });
  

    var value3 = "{}";
    this.employee.getemployeelist(value3).subscribe((listdetails: any) => {
      this.data = listdetails;
    });
    this.leavetypereport();
    this.malefemalereport();
  }

  leavetypereport() {
    this.graphReportService.getemployeeleavegraph({ 'month': this.employeemonth, 'year': this.employeeyear, 'employeeid':  this.empid, 'hstatus': this.hstatus  }).subscribe((employeeleavedata: any) => {
      this.employeeleavedata = employeeleavedata;
      var graphdataarray = [];
      employeeleavedata.forEach((obj, index) => {
        graphdataarray.push({ y: obj.count, label: obj.typename });
      });
      this.getemployeeleavegraph(graphdataarray);
    });

  }

  malefemalereport() {
    this.graphReportService.getleavegraphList({ 'month': this.employeemonth, 'year': this.employeeyear,'employeeid':  this.empid, 'hstatus': this.hstatus }).subscribe((leavedata: any) => {
      this.leavedatalist = leavedata;
      // console.log(leavedata);
      var  totalgraphdataarray = [];
      var malegraphdataarray = [];
      var femalegraphdataarray = [];
      leavedata.forEach((obj, index) => {
        totalgraphdataarray.push({ x: index + 1, y: obj.count, label: obj.typename });
        // malegraphdataarray.push({ x: index + 1, y: obj.mcount, label: obj.typename });
        // femalegraphdataarray.push({ x: index + 1, y: obj.fcount, label: obj.typename });
      });
      var data = [
        {
          type: "stackedBar",
          name: "Employee",
          showInLegend: "true",
          xValueFormatString: "DD, MMM",
          yValueFormatString: "#0",
          dataPoints: totalgraphdataarray
        }
        // {
        //   type: "stackedBar",
        //   name: "Male",
        //   showInLegend: "true",
        //   xValueFormatString: "DD, MMM",
        //   yValueFormatString: "#0",
        //   dataPoints: malegraphdataarray
        // },
        // {
        //   type: "stackedBar",
        //   name: "Female",
        //   showInLegend: "true",
        //   xValueFormatString: "DD, MMM",
        //   yValueFormatString: "#0",
        //   dataPoints: femalegraphdataarray
        // }
      ];
      this.companyleavegraphreport(data);
    });
  }

  companyleavegraphreport(data: any) {
    var chart = new CanvasJS.Chart("chartContainer2", {
      animationEnabled: true,
      title: {
        text: ""
      },
      axisX: {
        valueFormatString: "DDD"
      },
      axisY: {
        prefix: ""
      },
      toolTip: {
        shared: true
      },
      legend: {
        cursor: "pointer",
        itemclick: toggleDataSeries
      },
      data: data
    });
    chart.render();

    function toggleDataSeries(e) {
      if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      }
      else {
        e.dataSeries.visible = true;
      }
      chart.render();
    }
  }

  getemployeeleavegraph(data: any) {
    var chart = new CanvasJS.Chart("chartContainer", {
      animationEnabled: true,
      exportEnabled: true,
      title: {
        text: ""
      },
      data: [{
        type: "column",
        dataPoints: data
      }]
    });
    chart.render();

  }

  changeEmployee(id: any) {
    this.empid = id;
    this.leavetypereport();
    this.malefemalereport();
  }

  changeEmployeeMonth(month: any) {
    this.employeemonth = month;
    this.leavetypereport();
    this.malefemalereport();
  }

  changeEmployeeYear(year: any) {
    this.employeeyear = year;
    this.leavetypereport();
    this.malefemalereport();
  }

  changeEmployeestatus(hstatus: any)
  {
    this.hstatus = hstatus;
    this.leavetypereport();
    this.malefemalereport();
  }
}