"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../../_services/modal.service");
var aml_model_1 = require("../../../../models/aml/aml.model");
var aml_service_1 = require("../../../../services/aml.service");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var CompletebusscreditdetailsComponent = /** @class */ (function () {
    function CompletebusscreditdetailsComponent(modalService, amldetails, sanitizer, activatedRoute, router) {
        this.modalService = modalService;
        this.amldetails = amldetails;
        this.sanitizer = sanitizer;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.tPEPs = 0;
        this.tCurrentSanctions = 0;
        this.tDisqualifiedDirector = 0;
        this.tFinancialRegulator = 0;
        this.tInsolvency = 0;
        this.tLawEnforcement = 0;
        this.tAdverseMedia = 0;
        this.tPreviousSanctions = 0;
        this.PEPs = [];
        this.CurrentSanctions = [];
        this.DisqualifiedDirector = [];
        this.FinancialRegulator = [];
        this.Insolvency = [];
        this.LawEnforcement = [];
        this.AdverseMedia = [];
        this.PreviousSanctions = [];
        this.idv = [];
    }
    CompletebusscreditdetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var newlist;
        var newpersonalid;
        this.url = '';
        this.bussinessdetails = new aml_model_1.amldetailsclass();
        this.personid = this.activatedRoute.snapshot.paramMap.get("bussinessid");
        newpersonalid = this.personid;
        this.searchresult = [];
        this.logid = this.activatedRoute.snapshot.paramMap.get("id");
        var val = { id: this.logid, atype: 1 };
        this.amldetails.generatePdf({ id: this.logid, atype: 1, newpersonalid: newpersonalid }).subscribe(function (pdfRes) {
            console.log(pdfRes.response_data.Location);
            _this.url = pdfRes.response_data.Location;
        });
        this.amldetails.getCompanylogrecord(val).subscribe(function (searchRes) {
            var listmaster = JSON.parse(searchRes.searchresult);
            if (listmaster.recordsFound > 0) {
                _this.result = true;
            }
            _this.searchresult = listmaster;
            listmaster.matches.forEach(function (entry) {
                if (entry.business.id == newpersonalid) {
                    newlist = entry;
                    // console.log(">>>>> New list 4 ", newlist);
                }
            });
            console.log('List master', newlist);
            _this.bindattribute(newlist);
            _this.bussinessdetails = newlist;
        });
        // this.companyid = this.activatedRoute.snapshot.paramMap.get("id");
        // this.amldetails.getCompanyCreditReport(this.companyid).subscribe((searchRes: any) => { 
        //   console.log(searchRes);
        //   // if(searchRes.totalSize >0){
        //   //   this.result =true;
        //   // }
        //   this.searchresult = searchRes;
        // });
    };
    CompletebusscreditdetailsComponent.prototype.genratepdf = function () {
        var _this = this;
        var newpersonalid;
        this.url = '';
        this.personid = this.activatedRoute.snapshot.paramMap.get("bussinessid");
        newpersonalid = this.personid;
        this.searchresult = [];
        this.logid = this.activatedRoute.snapshot.paramMap.get("id");
        var val = { id: this.logid, atype: 1 };
        this.amldetails.generatePdf({ id: this.logid, atype: 1, newpersonalid: newpersonalid }).subscribe(function (pdfRes) {
            _this.url = pdfRes.response_data.Location;
        });
    };
    CompletebusscreditdetailsComponent.prototype.loaddatasystem = function () {
        this.amldetails.loadamllogsystem({ id: '1' }).subscribe(function (pdfRes) {
            // this.url = pdfRes.response_data.Location;
        });
    };
    CompletebusscreditdetailsComponent.prototype.urlfind = function (url) {
        if (url != null) {
            var slashslash = url.indexOf("//") + 2;
            return url.substring(slashslash, url.indexOf('/', slashslash));
        }
        else {
            return "";
        }
    };
    CompletebusscreditdetailsComponent.prototype.amllogredirect = function () {
        this.router.navigate(['/buscreditsearchresult', this.logid]);
    };
    CompletebusscreditdetailsComponent.prototype.bindattribute = function (data) {
        this.AdverseMedia = [];
        this.PreviousSanctions = [];
        this.PEPs = [];
        this.LawEnforcement = [];
        this.CurrentSanctions = [];
        this.FinancialRegulator = [];
        this.DisqualifiedDirector = [];
        this.Insolvency = [];
        this.idv = [];
        var rAdverseMedia = [];
        var rPreviousSanctions = [];
        var rPEPs = [];
        var rLawEnforcement = [];
        var rCurrentSanctions = [];
        var rFinancialRegulator = [];
        var rDisqualifiedDirector = [];
        var rInsolvency = [];
        var ridv = [];
        data.business.articles.forEach(function (entry2) {
            // console.log("Second Type Development", entry2);
            entry2.categories.forEach(function (entry3) {
                if (entry3.name == 'Adverse Media') {
                    // this.tAdverseMedia =this.tAdverseMedia +1;
                    rAdverseMedia.push(entry2);
                }
                else if (entry3.name == 'PEP') {
                    // this.tPEPs =this.tPEPs +1;
                    rPEPs.push(entry2);
                }
                else if (entry3.name == 'ID/V') {
                    // this.tPEPs =this.tPEPs +1;
                    ridv.push(entry2);
                }
                else if (entry3.name == 'Corporate/Business') {
                    // this.tLawEnforcement =this.tLawEnforcement +1;
                    rPreviousSanctions.push(entry2);
                }
                else if (entry3.name == 'Law Enforcement') {
                    // this.tLawEnforcement =this.tLawEnforcement +1;
                    rLawEnforcement.push(entry2);
                }
                else if (entry3.name == 'Sanction') {
                    // this.tCurrentSanctions =this.tCurrentSanctions +1;
                    rCurrentSanctions.push(entry2);
                }
                else if (entry3.name == 'Financial Regulator') {
                    // this.tFinancialRegulator =this.tFinancialRegulator +1;
                    rFinancialRegulator.push(entry2);
                }
                else if (entry3.name == 'Disqualified Director') {
                    // this.tDisqualifiedDirector =this.tDisqualifiedDirector +1;
                    rDisqualifiedDirector.push(entry2);
                }
                else if (entry3.name == 'Insolvent') {
                    // this.tInsolvency =this.tInsolvency +1;
                    rInsolvency.push(entry2);
                }
                // console.log(entry3.name); 
            });
        });
        this.AdverseMedia = rAdverseMedia;
        this.PreviousSanctions = rPreviousSanctions;
        this.PEPs = rPEPs;
        this.LawEnforcement = rLawEnforcement;
        this.CurrentSanctions = rCurrentSanctions;
        this.FinancialRegulator = rFinancialRegulator;
        this.DisqualifiedDirector = rDisqualifiedDirector;
        this.Insolvency = rInsolvency;
        this.idv = ridv;
    };
    CompletebusscreditdetailsComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    CompletebusscreditdetailsComponent.prototype.opendocumentlink = function (id, data) {
        // console.log("data value", data);
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgvisasrc = true;
            this.iframevisasrc = data;
        }
        else {
            this.imgvisasrc = false;
            this.iframevisasrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    CompletebusscreditdetailsComponent.prototype.openModal = function (id, data, url) {
        this.snippet = data;
        this.originalURL = url;
        this.modalService.open(id);
    };
    CompletebusscreditdetailsComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return CompletebusscreditdetailsComponent;
}());
exports.CompletebusscreditdetailsComponent = CompletebusscreditdetailsComponent;
