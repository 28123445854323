import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PlanService } from '../../../services/plan.service';
import { PaymentService } from '../../../services/payment.service';
import { Plan } from '../../../models/plan.model';
import { ModalService } from '../../../_services/modal.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { StripeScriptTag } from "stripe-angular";
import { StripeToken } from "stripe-angular";
import { AlertService } from '../../../services/alert.service';
import { UserService } from '../../../services/user.service';
import { CompanyService } from '../../../services/company.service';
import { AppGlobals } from '../../../app.global';


@Component({
  selector: 'app-changeplan',
  templateUrl: './changeplan.component.html',
  styleUrls: ['./changeplan.component.css']
})
export class ChangeplanComponent implements OnInit {

 
  buttoninsert: any;
  Userid: any;
  data: any;
  planDetail = new Plan();
  planformGroup: FormGroup;
  planlist: any;
  submitted: any;
  totaluser: any;
  totalused: any;
  message: string;
  stptoken: any;
  planid: any;
  
  extraData: any;
  invalidError: any;
  companyData: any;
  currentprice: any;
  cardlist: any;
  ctype:any;


  plantype: number;




  private publishableKey: string =AppGlobals.API_STRIPE;
  
  //  private publishableKey: string = "pk_live_vE86NSZoPYH0MsJIdsYfQ8L6";
  //private publishableKey: string = "pk_test_dIWR2H2xFoFj3I8vXg8eS4dw";

  constructor(private formBuilder: FormBuilder,
    private modalService: ModalService,
    private router: Router,
    private alerts: AlertService,
    private plan: PlanService,
    private userService: UserService,
    public companyService: CompanyService,
    public StripeScriptTag: StripeScriptTag,    
    private activatedRoute: ActivatedRoute, 
    public paymentService: PaymentService
  ) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }


    
    if(this.activatedRoute.snapshot.paramMap.get("id"))
    {
      console.log('master user system Yes', this.plantype);

      this.plantype = parseInt(this.activatedRoute.snapshot.paramMap.get("id"));
    }
    else
    {
      this.plantype=0;
      console.log('master user system No', this.plantype);

    }

    


    





     this.ctype  = parseInt(localStorage.getItem('ctype'));
    // if(this.ctype ==1){
    //   this.router.navigate(['/check-dashboard']);
    // }
    
    this.StripeScriptTag.setPublishableKey(this.publishableKey);

    this.planformGroup = this.formBuilder.group({
      coupnsnumber: ['', Validators.required]
    });

    var isemail = localStorage.getItem('isemail');

    this.planid = localStorage.getItem('planid');
    
    console.log('Plan id on it', this.planid);
    this.companyService.getcompanyData().subscribe((data: any) => {
      this.currentprice = data.plan.prices;
      this.companyData = data;
      // console.log(this.companyData);
    });

    

    this.companyService.gettotalcards().subscribe((data: any) => {
    //   // console.log(data);
      this.cardlist = data;
     })
    
     var type;
     if(this.ctype ==0)
     {
        type = {"plantype": '0','checktype': '0'};
     }
     else 
     {
        type = {"plantype": '0','checktype': '1'};
     }
    //  else if(this.ctype == 2)
    //  {
    //     type = {"plantype": '0','checktype': '1'};
    //  }  
    
    
    this.plan.getplanlist(type).subscribe((data: any) => {
      // console.log(data);
      this.planlist = data;
    });
  }


  get f() { return this.planformGroup.controls; }

  onSubmit(settingexpensetypedata: any, id: string) {
    this.submitted = true;

    if (this.planformGroup.invalid) {
      return;
    }

    this.plan.checkcounpons(settingexpensetypedata).subscribe((data: any) => {
      if (data.lenght != 0) {
        this.totaluser = data.totaluser;
        this.totalused = data.totalused;
        if ((this.totaluser - this.totalused) != 0) {

          this.message = "Coupons Applied Successfully";
          var db = { "couponsnumber": settingexpensetypedata.coupnsnumber };
          this.plan.getplanlistwithcoupons(db).subscribe((data4: any) => {
            this.planlist = data4;
            this.modalService.close(id);
          });
        }
        else {
          this.message = "Coupons limit expire";
        }
      }
    });

  }

  onStripeInvalid(error: Error) {
    // console.log('Validation Error', error)
  }

  buySubscription(planid: any, id: any, type: any) {

    var msg='';
    if(type ==1)
      msg = "Are you sure you want to downgrade your plan?";
    else
      msg ="Are you sure you want to upgrade your plan?"

    
    if (confirm("Are you sure you want to upgrade your plan?")) {
      this.planid = planid;

      var dd = { "customer": this.companyData.stuser, "planid": this.planid, 'plantype': this.plantype };
      this.paymentService.createSubscription(dd).subscribe((data: any) => {
        if (data.status == "success") {
          this.companyService.getcompanyData().subscribe((data: any) => {
            this.currentprice = data.plan.prices;
            this.companyData = data;
            this.alerts.success("Plan Successfully Upgraded.", true);
            localStorage.setItem('planid', id);
            localStorage.setItem('isemail', 'true');
            this.router.navigate(['/successfully-payment',1]);
          });
        }
        else {
          this.router.navigate(['/successfully-payment',0]);
          this.alerts.error("Payment does not succeed", true);
        }
        //   this.paymentService.customerCharge({"token": data.id, "planid": this.planid}).subscribe((data: any) => {
        //     console.log(data);
        //     if(data.status == "succeeded")
        //     {
        //       this.paymentService.createSubscription({"customer": data.customer, "planid": this.planid}).subscribe((data: any) => {
        //         console.log(data);
        //         // this.plan.updatecompanyplan(planupdate).subscribe((data: any) => {   

        //         // });
        //       });
        //     }

        //   });
      });
    }

  }

  selectPlanType(event)
  {
    // console.log(event);
    const type = {"plantype": event};
    this.plan.getplanlist(type).subscribe((data: any) => {
      
      this.planlist = data;
    });
  }

  setStripeToken(token: StripeToken, id: any) {
    var data = {
      "id": token.id,
      "customer": this.companyData.stuser,
      "cmonth": token.card.exp_month,
      "cyear": token.card.exp_year,
      "cardnumber": token.card.last4,
      "tokenid": token.card.id,
      "cardname": token.card.brand,
      "country": token.card.country,
      "cstatus": "1"
    };

    // console.log(data);
    this.modalService.close(id);
    this.paymentService.addnewCard(data).subscribe((data: any) => {
      if (data.success == "success") {
        this.paymentService.getTotalCards().subscribe((data: any) => {
          this.alerts.success("Card has been successfully added.", true);
          this.cardlist = data.data;
        })

      }
    });
   // console.log(token.card);
  }

  onStripeError(error: Error) {
    console.error('Stripe error', error)
  }


  updateyourplan(id: string) {
    this.modalService.open(id);
  }

  cardlistopen(id: string) {
    this.paymentService.getTotalCards().subscribe((data: any) => {
      // console.log(data.data);
        this.cardlist = data.data;
    })
    this.modalService.open(id);
  }

  removeCard(id)
  {
    this.paymentService.removeCard({ "customer": this.companyData.stuser, "id": id }).subscribe((data: any) => {
      // console.log(data);
      this.paymentService.getTotalCards().subscribe((data: any) => {
        // console.log(data.data);
          this.cardlist = data.data;
      })
        // this.cardlist = data.data;
    })
  }

  openModal(id: string) {
    this.buttoninsert = "Apply Coupons";
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }


  logout() {
    if (confirm("Are you sure you want to signout?")) {
      this.userService.logout().subscribe((data: any) => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('emptype');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('isemail');
        this.router.navigate(['/login']);
      });
    }
  }


}
