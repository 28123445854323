"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// import { EmployeeMaster } from './../models/employee_master';
// import { HeaderComponent } from './user/header/header.component';
var core_1 = require("@angular/core");
var mypassport_service_1 = require("../../../services/mypassport.service");
var modal_service_1 = require("../../../_services/modal.service");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var platform_browser_1 = require("@angular/platform-browser");
var employee_service_1 = require("../../../services/employee.service");
var router_1 = require("@angular/router");
var passport_model_1 = require("../../../models/passport/passport.model");
var country_service_1 = require("../../../services/country.service");
var forms_1 = require("@angular/forms");
var alert_service_1 = require("../../../services/alert.service");
var aicenter_service_1 = require("../../../services/aicenter.service");
var AipassportComponent = /** @class */ (function () {
    function AipassportComponent(sanitizer, formBuilder, uploadService, data, country, aicenter, modalService, employee, router, alerts) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.data = data;
        this.country = country;
        this.aicenter = aicenter;
        this.modalService = modalService;
        this.employee = employee;
        this.router = router;
        this.alerts = alerts;
        this.aipayment = false;
    }
    AipassportComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.requestperm = this.permission.m15;
        this.passformGroup = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            passportnumber: ['', forms_1.Validators.required],
            placeofissue: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            passportissue: ['', forms_1.Validators.required],
            passportexpiry: ['', forms_1.Validators.required],
            iscurrent: [''],
        });
        this.aicenter.getcountpayment().subscribe(function (data) {
            _this.aipayment = data.payment;
        });
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        var value3 = "{}";
        this.employee.getemployeelist(value3).subscribe(function (listdetails) {
            _this.employeelist = listdetails;
        });
        this.loadalldata();
        this.passportDetail = new passport_model_1.Passport();
        this.passportDetail.sstatus = 0;
    };
    AipassportComponent.prototype.loadalldata = function () {
        var _this = this;
        var data;
        if (this.empid != '') {
            data = { 'id': this.Userid, 'cstatus': [1, 2, 3], employeeid: this.empid };
        }
        else {
            data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
        }
        this.data.getallpassportListbyadmin(data).subscribe(function (data) {
            _this.record = data.length;
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.items = data;
            }
        });
    };
    AipassportComponent.prototype.changeEmployee = function (id) {
        this.empid = id;
        this.loadalldata();
    };
    AipassportComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    Object.defineProperty(AipassportComponent.prototype, "f", {
        get: function () { return this.passformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    AipassportComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    AipassportComponent.prototype.openModal3 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    AipassportComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    AipassportComponent.prototype.modalOpen = function (data) {
        this.disableBtn = true;
        this.passportDetail = new passport_model_1.Passport();
        this.passportDetail = data;
    };
    return AipassportComponent;
}());
exports.AipassportComponent = AipassportComponent;
