<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
      <div class="container">
        <a [routerLink]="['/reference-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to  Automated Reference Check</a>
        <div class="clearfix"></div>
          <div class="tabs" id="tabs">
              <div class="tabs__content">
                  <div class="tabs__content__item active-tab">
                      <div class="content">
                          <div class="row row-sm topheader">

                            <div class="col-lg-6">
                               
                                  <app-checkcountshow></app-checkcountshow>                                   
                                                                
                              </div> 
                              
                              <div class="col-lg-8">
                                  <h5 class="hedingcolor mt-3">Bulk Referencing Request </h5>
                              </div> 


                              <div class="col-md-4">
                                <form action="" method="post" name="upload_excel" enctype="multipart/form-data">
                                <div class="row row-sm">


                                    <div class=" col-lg-12">
                                        <label>Select CSV File: (5000 Rows Max, <a style="color:red;cursor: pointer"
                                            href="https://complygate.s3.amazonaws.com/dev/employee/2/ref_sample_1641668561015.csv" target="_blank">Sample CSV</a>)
                                        </label>
                                        <div class="input-group">
                                          <div class="custom-file" (click)="file.click()"> 
                                              
                                            <input (change)="uploadListener($event)" #file accept=".csv"   type="file" >
                                            <!-- <input type="file" #file (change)="selectFile($event)"> -->
                                            <label class="custom-file-label">{{filename}}</label>
                                          </div>
                                          <div class="col-lg-12">
                                            
                                          </div>
                                        </div>
                        
                                        <div class="alert-danger" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                        <div class="alert-danger" *ngIf="fileformaterror == true">Please choose a valid file format
                                          (jpg, png, tif, gif, pdf, doc)</div>
                                      </div>

                                      <!-- <div class="col-lg-4">
                                        <label style="width: 100%;">&nbsp;</label>
                                       
                                    </div> -->
                                  <!-- <div class="col-md-8">
                                    <div class="form-group">
                                      <label for="filebutton">Select CSV File: (5000 Rows Max, <a style="color:red;cursor: pointer"
                                          (click)="downladsample()">Sample CSV</a>)</label>
                                     
                                    </div>
                                  </div> -->



                                  <!-- <div class="col-md-4 text-left" >
                                    <div class="form-group">
                                        <label class="width100">&nbsp;</label>
                                      
                                    </div>
                                  </div> -->
                                </div>
                              </form>
                              </div>
                          </div>
                          <div class="row"  *ngIf="csvRecordsnew.length > 0 ">
                            <div class="col-lg-6">
                                <input type="checkbox" name="Insolvency" id="selectall" [checked]="checkedAll" (change)="selectall($event.target.checked)">
                                <label for="selectall">&nbsp; Select All</label>
                            </div>                           
                        </div>
                          <div class="row row-sm">
                              <div class="col-lg-12">
                                  <div class="job_list_section" *ngFor="let ref of csvRecordsnew;  let i=index">
                                    <ng-container>
                                        <input type="checkbox" name="Insolvency"  [checked]="idArray.indexOf(i)>-1" (change)="selectTimesheet($event.target)" value="{{i}}" class="activity">
                                        <label for="check{{i}}"></label>
                                    </ng-container>
                                    <div class="job_detils_date asslist width15">
                                          <p class="job_profile"> Name</p>
                                          <p class="job_address" *ngIf="ref.candidatename">{{ref.candidatename}}</p>
                                      </div>
                                      <div class="job_detils_date asslist width15">
                                          <p class="job_profile"> Email</p>
                                          <p class="job_address" *ngIf="ref.candidateemail">{{ref.candidateemail}}</p>
                                      </div>
                                      <div class="job_detils_date asslist width15">
                                          <p class="job_profile">Phone</p>
                                          <p class="job_address" *ngIf="ref.candidatephone">{{ ref.candidatephone }}</p>
                                      </div>
                                      <div class="job_detils_date asslist width10">
                                          <p class="job_profile">Position</p>
                                          <p class="job_address" *ngIf="ref.position"> {{ ref.position }} </p>
                                      </div>
                                      <div class="job_detils_date asslist width10">
                                          <p class="job_profile">Question Profile</p>
                                          <p class="job_address" *ngIf="ref.questionprofile"> {{ ref.questionprofile }}</p>
                                      </div>
                                      <div class="job_detils_date asslist width10">
                                        <p class="job_profile">Reference Required</p>
                                        <p class="job_address" *ngIf="ref.referencesrequired"> {{ ref.referencesrequired }}</p>
                                    </div>
                                    <div class="job_detils_date asslist width10">
                                        <p class="job_profile">Message</p>
                                        <p class="job_address" *ngIf="ref.candidatemessage"> {{ ref.candidatemessage }}</p>
                                    </div>
                                  </div>                                   
                              </div>


                              <div class="job_list_section visa_request_section" style="width: 100%;text-align: center;display: block;" *ngIf="csvRecordsnew.length == 0">
                               
                                <div class="col-sm-6 text-center" *ngIf="lesssore">
                                    <span >Sorry, You don't have any credits. Please  <a [routerLink]="['/check-add-credit']">Click Here</a> to add more.</span>
                                </div>
                               
                               
                                <div class="job_detils_date"  *ngIf="!lesssore">
                                  <p class="job_profile">Please upload CSV file.</p>
                                </div>
                              </div>

                              <div class="col-lg-12 text-right mt-3" *ngIf="csvRecordsnew.length > 0 && totalcheck >= csvRecordsnew.length">
                                <button  (click)="generateRecord()" class="btn btn-primary">Generate Reference Request</button>
                              </div>

                            <div class="col-sm-12 text-left  mt-3" *ngIf="totalcheck <= csvRecordsnew.length">
                                <span >Sorry, You don't have any credits. Please  <a [routerLink]="['/check-add-credit']">Click Here</a> to add more.</span>
                            </div>

                            <div class="col-lg-8" *ngIf="csvRecordswong.length > 0 ">
                                <h5 class="hedingcolor mt-3" style="text-transform: none;">Error: format is not similar to the sample csv or reference profile is no match. </h5>
                            </div> 
                            <div class="col-lg-12"  *ngIf="csvRecordswong.length > 0">
                                <div class="job_list_section" *ngFor="let ref of csvRecordswong;  let i=index">
                                 
                                  <div class="job_detils_date asslist width15">
                                        <p class="job_profile"> Name</p>
                                        <p class="job_address" *ngIf="ref.candidatename">{{ref.candidatename}}</p>
                                    </div>
                                    <div class="job_detils_date asslist width15">
                                        <p class="job_profile"> Email</p>
                                        <p class="job_address" *ngIf="ref.candidateemail">{{ref.candidateemail}}</p>
                                    </div>
                                    <div class="job_detils_date asslist width15">
                                        <p class="job_profile">Phone</p>
                                        <p class="job_address" *ngIf="ref.candidatephone">{{ ref.candidatephone }}</p>
                                    </div>
                                    <div class="job_detils_date asslist width10">
                                        <p class="job_profile">Position</p>
                                        <p class="job_address" *ngIf="ref.position"> {{ ref.position }} </p>
                                    </div>
                                    <div class="job_detils_date asslist width10">
                                        <p class="job_profile">Question Profile</p>
                                        <p class="job_address" *ngIf="ref.questionprofile"> {{ ref.questionprofile }}</p>
                                    </div>
                                    <div class="job_detils_date asslist width10">
                                      <p class="job_profile">Reference Required</p>
                                      <p class="job_address" *ngIf="ref.referencesrequired"> {{ ref.referencesrequired }}</p>
                                  </div>
                                  <div class="job_detils_date asslist width10">
                                      <p class="job_profile">Message</p>
                                      <p class="job_address" *ngIf="ref.candidatemessage"> {{ ref.candidatemessage }}</p>
                                  </div>
                                </div>                                   
                            </div>


                              
                              <!-- col-8 -->
                          </div>
                          <!-- <div class="row">
                              
                              <div class="col-lg-12 text-right"  *ngIf="record > 30">
                                <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                               </div>
                            </div> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>