"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./callback.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./callback.component");
var i3 = require("@angular/router");
var i4 = require("ngx-toastr");
var i5 = require("../../services/alert.service");
var i6 = require("../../services/customer.service");
var styles_CallbackComponent = [i0.styles];
var RenderType_CallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CallbackComponent, data: {} });
exports.RenderType_CallbackComponent = RenderType_CallbackComponent;
function View_CallbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "container width90"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "browser-screen-loading-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "loading-dots dark-gray"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading"]))], null, null); }
exports.View_CallbackComponent_0 = View_CallbackComponent_0;
function View_CallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-callback", [], null, null, null, View_CallbackComponent_0, RenderType_CallbackComponent)), i1.ɵdid(1, 114688, null, 0, i2.CallbackComponent, [i3.ActivatedRoute, i4.ToastrService, i5.AlertService, i3.Router, i6.CustomerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CallbackComponent_Host_0 = View_CallbackComponent_Host_0;
var CallbackComponentNgFactory = i1.ɵccf("app-callback", i2.CallbackComponent, View_CallbackComponent_Host_0, {}, {}, []);
exports.CallbackComponentNgFactory = CallbackComponentNgFactory;
