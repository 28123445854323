"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var company_service_1 = require("../../services/company.service");
var platform_browser_1 = require("@angular/platform-browser");
var user_service_1 = require("../../services/user.service");
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(router, companyService, userService, sanitizer) {
        this.router = router;
        this.companyService = companyService;
        this.userService = userService;
        this.sanitizer = sanitizer;
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        else {
            this.userService.checkaccesstokenbyid(userid).subscribe(function (data) {
                if (data.status == false) {
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('userid');
                    localStorage.removeItem('Name');
                    localStorage.removeItem('isline');
                    localStorage.removeItem('planid');
                    localStorage.removeItem('ctype');
                    localStorage.removeItem('isemail');
                    localStorage.removeItem('ismobile');
                    localStorage.removeItem('isadmin');
                    localStorage.removeItem('ispermission');
                    localStorage.removeItem('logoname');
                    localStorage.removeItem('struser');
                    localStorage.removeItem('menu');
                    _this.router.navigate(['/login']);
                }
            });
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        var struser = localStorage.getItem('struser');
        if (ctype == 0 && struser == '') {
            this.router.navigate(['/choose-plan']);
        }
        if (ctype == 1 || ctype == 2) {
            this.router.navigate(['/check-dashboard']);
        }
        // this.ctype  = parseInt(localStorage.getItem('ctype'));
        if (ctype === 3) {
            this.router.navigate(['/reference-dashboard']);
        }
        // this.logoimage = localStorage.getItem('logoname');
        //   console.log(this.logoimage2);
        //   this.logoimage2='https://complygate.s3.amazonaws.com/dev/employee/2/1590067122028_MartinSantiago.png';
        //   this.getBase64ImageFromUrl(this.logoimage2)
        //   .then(result => this.logoimage = result)
        //   .catch(err => console.error(err));
        //  console.log(this.logoimage);
        //   this.newlogo = this.getBase64ImageFromUrl(this.logoimage2);
        //   console.log(this.newlogo);
        //   this.logoimage= this.newlogo.__zone_symbol__value;
        this.companyService.checkstripecustomer().subscribe(function (data) {
            if (data == null) {
                // this.router.navigate(['/create-customer']);
            }
            else if (data.deleted) {
                // this.router.navigate(['/create-customer']);
            }
            else {
                _this.cardlist = data;
            }
        });
    };
    DashboardComponent.prototype.transform = function (html) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(html);
    };
    DashboardComponent.prototype.getBase64ImageFromUrl = function (imageUrl) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var res, blob;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(imageUrl)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.blob()];
                    case 2:
                        blob = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                var reader = new FileReader();
                                reader.addEventListener("load", function () {
                                    resolve(reader.result);
                                }, false);
                                reader.onerror = function () {
                                    return reject(_this);
                                };
                                // console.log(blob);
                                reader.readAsDataURL(blob);
                            })];
                }
            });
        });
    };
    return DashboardComponent;
}());
exports.DashboardComponent = DashboardComponent;
