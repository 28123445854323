<style>
  .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid blue;
      border-right: 16px solid green;
      border-bottom: 16px solid red;
      border-left: 16px solid pink;
      width: 80px;
      height: 80px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
      position: absolute;
      left: 45%;
      top: 40%;
  }

  @-webkit-keyframes spin {
      0% {
          -webkit-transform: rotate(0deg);
      }

      100% {
          -webkit-transform: rotate(360deg);
      }
  }

  @keyframes spin {
      0% {
          transform: rotate(0deg);
      }

      100% {
          transform: rotate(360deg);
      }
  }
</style>

<div class="not_found">
  <div class="thank_youpage">
      <div class="row">
          <div class="col-sm-6  d-flex  align-items-center justify-content-center">
              <div class="thanks_text">
                  <h2><span>Payment Failed!</span></h2>
                  <!-- <h3>Please do not refresh or reload the page.</h3> -->
                  <p>If you are not redirected to the next page. Please <a [routerLink]="['/choose-plan']"> click here</a>
                  </p>
                  <!-- <div class="loader"></div> -->
              </div>
          </div>
          <div class="col-sm-6">
              <img src="../../../../../assets/img/hero-bg.png" class="img width100">
          </div>
      </div>
  </div>
</div>