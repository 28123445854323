import { Component, OnInit } from '@angular/core';
import { Fca } from '../../models/fca.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FcasearchService } from '../../services/fcasearch.service';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { AnalysisErrorList } from 'aws-sdk/clients/quicksight';

@Component({
  selector: 'app-individualsfca',
  templateUrl: './individualsfca.component.html',
  styleUrls: ['./individualsfca.component.css']
})
export class IndividualsfcaComponent implements OnInit {

  registerForm: FormGroup;

  search: any;
  submitted = false;
  Induviduals:any;
  companydetails:any;
  regulator:any;
  Appointed:any;
  filmaddress:any;

  InduvidualsDetails:any;

  constructor(
    private formBuilder: FormBuilder,private fcaService: FcasearchService,
    private activatedRoute: ActivatedRoute,) { }

  ngOnInit() {


    
    this.registerForm = this.formBuilder.group({      
      companyname: ['', Validators.required],
      postcode: ['' ],
      searchtype: ['',  Validators.required]    
    });

    this.onSubmit();

  }

  get f() { return this.registerForm.controls; }

  onSubmit(){


    this.search = this.activatedRoute.snapshot.paramMap.get("id");  
    var data = {companyname: this.search}
    
// this.fcaService.getFCAFirmcommonSearch(this.search).subscribe((data2: any) => {

  //   this.fcaService.getFCAFirmDetails(data).subscribe((data2: any) => {
  //     this.companydetails =data2;
  //   console.log('hum1',data2);

  //   this.fcaService.getFCAFirmName(data).subscribe((data2: any) => {
  //     console.log('Firm Name',data2);
  //   });
  //   this.fcaService.getFCAFirmIndividuals(data).subscribe((data2: any) => {
  //     this.Induviduals = data2;
  //     console.log('Firm Induviduals',data2);
  //   });
  //   this.fcaService.getFCAFirmRequirements(data).subscribe((data2: any) => {
  //     console.log('Firm Requirements',data2);
  //   });
  //   this.fcaService.getFCAFirmPermission(data).subscribe((data2: any) => {
  //     console.log('Firm Permission',data2);
  //   });
  //   this.fcaService.getFCAFirmPassport(data).subscribe((data2: any) => {
  //     console.log('Firm Passport',data2);
  //   });
  //   this.fcaService.getFCAFirmRegulators(data).subscribe((data2: any) => {

  //     this.regulator = data2;
  //     console.log('Firm Regulators',data2);
  //   });


  //   this.fcaService.getFCAFirmAddress(data).subscribe((data2: any) => {
  //     this.filmaddress = data2;
  //     console.log('Firm Address Master', data2);
  //   });



  //   this.fcaService.getFCAFirmAppointedRepresentative(data).subscribe((data2: any) => {
  //     this.Appointed = data2;
  //     console.log('Firm Appointed Representative',data2);
  //   });
  //   this.fcaService.getFCAFirmWaivers(data).subscribe((data2: any) => {
  //     console.log('Firm Waivers',data2);
  //   });
  //   this.fcaService.getFCAFirmExclusions(data).subscribe((data2: any) => {
  //     console.log('Firm Exclusions',data2);
  //   });
  //   this.fcaService.getFCAFirmDisciplinaryHistory(data).subscribe((data2: any) => {
  //     console.log(data2);
  //   });


  // });

   
  // });


  this.fcaService.IndividualControlledFunctions(data).subscribe((data2: any) => {
        console.log('Individual Controlled Functions',data2);
      });


      this.fcaService.IndividualDetailsFunctions(data).subscribe((data2: any) => {

        this.InduvidualsDetails = data2;
        console.log('Individual Details',data2);
      });




  }

}
