"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var candidate_service_1 = require("../../../../services/candidate.service");
var router_1 = require("@angular/router");
var candidate_model_1 = require("../../../../models/jobs/candidate.model");
var modal_service_1 = require("../../../../_services/modal.service");
var forms_1 = require("@angular/forms");
var alert_service_1 = require("../../../../services/alert.service");
var platform_browser_1 = require("@angular/platform-browser");
var upload_file_service_1 = require("../../../../services/upload-file.service");
var router_2 = require("@angular/router");
var ApplicantdocumentComponent = /** @class */ (function () {
    function ApplicantdocumentComponent(sanitizer, candidate, modalService, route, uploadService, alerts, router, formBuilder) {
        var _this = this;
        this.sanitizer = sanitizer;
        this.candidate = candidate;
        this.modalService = modalService;
        this.route = route;
        this.uploadService = uploadService;
        this.alerts = alerts;
        this.router = router;
        this.formBuilder = formBuilder;
        this.applicantDetailmain = new candidate_model_1.Candidate();
        this.candidateDetail = new candidate_model_1.Candidate();
        route.params.subscribe(function (params) {
            _this.Userid = localStorage.getItem('userid');
            _this.candidateid = params['candidateid'];
            _this.candidate.getcandidateById({ "id": _this.Userid, "candidateid": _this.candidateid }).subscribe(function (dbdocument) {
                _this.applicantDetailmain = dbdocument;
            });
        });
    }
    ApplicantdocumentComponent.prototype.ngOnInit = function () {
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.docperm = this.permission.m8;
        this.documentGroup = this.formBuilder.group({
            candidatedocumentdetailsid: [''],
            documentname: ['', forms_1.Validators.required],
            candidateid: ['', forms_1.Validators.required]
        });
    };
    Object.defineProperty(ApplicantdocumentComponent.prototype, "f", {
        get: function () { return this.documentGroup.controls; },
        enumerable: true,
        configurable: true
    });
    ApplicantdocumentComponent.prototype.closedocumentModal = function (id) {
        this.modalService.close(id);
    };
    ApplicantdocumentComponent.prototype.opendocumnentname = function (id, employeeid) {
        var _this = this;
        var reqType = { 'documentid': id, 'candidateid': employeeid };
        this.candidate.getapplicantdocumentbyid(reqType).subscribe(function (data) {
            _this.documentnamelist = data;
            _this.modalService.open('employee-doc-name-update');
        });
    };
    ApplicantdocumentComponent.prototype.onRenameDocumentSubmit = function (data) {
        var _this = this;
        this.submitted = true;
        if (this.documentGroup.invalid) {
            return;
        }
        this.candidate.updateapplicantdocumentbyid(data).subscribe(function (data2) {
            if (data2.susses == 'true') {
                var employeeid = data.employeeid;
                _this.documentnamelist = null;
                _this.Userid = localStorage.getItem('userid');
                _this.candidate.getcandidateById({ "id": _this.Userid, "candidateid": _this.candidateid }).subscribe(function (data3) {
                    _this.applicantDetailmain = data3;
                    _this.modalService.close('employee-doc-name-update');
                });
                _this.alerts.success('Document name updated successfully');
            }
            else {
                _this.alerts.error('Somthing went wrong');
                _this.modalService.close('employee-doc-name-update');
            }
        });
    };
    ApplicantdocumentComponent.prototype.deleteFile = function (id) {
        var _this = this;
        var deleteData = {};
        deleteData = { 'id': id, 'candidateid': this.candidateid };
        if (confirm("Are you sure you want to delete?")) {
            this.candidate.deletecandidatedocumentFile(deleteData).subscribe(function (data) {
                if (data.success) {
                    _this.alerts.success("Document deleted successfully", true);
                    _this.candidate.getcandidateById({ "id": _this.Userid, "candidateid": _this.candidateid }).subscribe(function (dbdocument) {
                        _this.applicantDetailmain = dbdocument;
                    });
                }
                else {
                    _this.alerts.error("Something went wrong", true);
                }
                // this.candidate.getdocumentlist({"id": this.Userid, "candidateid": this.candidateid}).subscribe((candidatedocument: any) => {
                //   this.applicantDetailmain = candidatedocument;      
                // }); 
            });
        }
    };
    ApplicantdocumentComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    ApplicantdocumentComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        var file = this.selectedFiles;
        var imagepath = this.uploadService.uploadfile(file);
        var documentname = selectedFiles.name.substring(0, 10);
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 10000 * 1024) {
            this.alerts.error('File size should be less than 10MB', true);
            return;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "xls") && (filetype[filetype.length - 1] != "xlsx") && (filetype[filetype.length - 1] != "csv")) {
            this.alerts.error('File format not supported', true);
            return;
        }
        var forupdate = { 'documentname': documentname, 'id': "0", 'documentimageurl': imagepath, 'candidateid': this.candidateid, 'userid': this.Userid };
        this.candidate.candidatedocumentupdatebyadmin(forupdate).subscribe(function (data) {
            _this.candidate.getcandidateById({ "id": _this.Userid, "candidateid": _this.candidateid }).subscribe(function (dbdocument) {
                _this.applicantDetailmain = dbdocument;
            });
            // setTimeout(() => this.overviewData.imagename = imagepath, 2000);
        });
        this.alerts.success('Candidate document uploaded successfully.', true);
    };
    ApplicantdocumentComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    ApplicantdocumentComponent.prototype.openModalcandidate5 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgcandidatesrc = true;
            this.iframecandidatesrc = data;
        }
        else {
            this.imgcandidatesrc = false;
            this.iframecandidatesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    return ApplicantdocumentComponent;
}());
exports.ApplicantdocumentComponent = ApplicantdocumentComponent;
