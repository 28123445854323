"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var MyemploymentService = /** @class */ (function () {
    function MyemploymentService(userService) {
        this.userService = userService;
    }
    MyemploymentService.prototype.getemploymenttList = function () {
        return this.userService.formpostOnlyid('/employee/workinglist');
    };
    MyemploymentService.prototype.getemploymenttListbyadmin = function (value) {
        return this.userService.formpost('/employee/workinglistbyadmin', value);
    };
    MyemploymentService.prototype.myemploymentdetailsUpdatebyadmin = function (value) {
        return this.userService.formpost('/employee/employmentupdatebyadmin', value);
    };
    MyemploymentService.ngInjectableDef = i0.defineInjectable({ factory: function MyemploymentService_Factory() { return new MyemploymentService(i0.inject(i1.CommonService)); }, token: MyemploymentService, providedIn: "root" });
    return MyemploymentService;
}());
exports.MyemploymentService = MyemploymentService;
