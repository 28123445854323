"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var NotpermissionComponent = /** @class */ (function () {
    function NotpermissionComponent() {
    }
    NotpermissionComponent.prototype.ngOnInit = function () {
    };
    return NotpermissionComponent;
}());
exports.NotpermissionComponent = NotpermissionComponent;
