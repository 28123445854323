"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var PassportService = /** @class */ (function () {
    function PassportService(commonService) {
        this.commonService = commonService;
    }
    PassportService.prototype.getpassportlist = function (data) {
        return this.commonService.formpost('/employee/passportreport', data);
    };
    PassportService.ngInjectableDef = i0.defineInjectable({ factory: function PassportService_Factory() { return new PassportService(i0.inject(i1.CommonService)); }, token: PassportService, providedIn: "root" });
    return PassportService;
}());
exports.PassportService = PassportService;
