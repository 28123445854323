import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';



@Injectable({
  providedIn: 'root'
})
export class TimesheetService {

  constructor(private commonService: CommonService) { }

  getholidayType(): Observable<any> 
  {
    return this.commonService.formget('/company/holidaytype');   
  }

  getloactionlist(): Observable<any> 
  {
    return this.commonService.formget('/company/locationtype');   
  }

  gettimesheetList(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/employeehourreport',data);   
  }

}
