"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var CompanyService = /** @class */ (function () {
    function CompanyService(commonService) {
        this.commonService = commonService;
    }
    CompanyService.prototype.checkPlan = function (value) {
        return this.commonService.formpost('/company/checkcompanyplan', value);
    };
    CompanyService.prototype.getcompanyType = function () {
        return this.commonService.formpostOnlyid('/company/companytype');
    };
    CompanyService.prototype.getcompanylocation = function () {
        return this.commonService.formpostOnlyid('/companylocation');
    };
    CompanyService.prototype.getcompanydetails = function () {
        return this.commonService.formpostOnlyid('/company/companydetails');
    };
    CompanyService.prototype.getcompanydetailsbyid = function (data) {
        return this.commonService.refereeformpost('/company/companydetailsbyid', data);
    };
    CompanyService.prototype.getinvoiceretrive = function (value) {
        return this.commonService.formpost('/invoiceretrieve', value);
    };
    CompanyService.prototype.getpaymentintent = function (value) {
        return this.commonService.formpost('/paymentintetretrive', value);
    };
    CompanyService.prototype.Creditchecksecurecard = function (value) {
        return this.commonService.formpost('/check/Creditchecksecurecard', value);
    };
    CompanyService.prototype.deleteinvoice = function (value) {
        return this.commonService.formpost('/deleteinvoice', value);
    };
    CompanyService.prototype.downloadInvoice = function (value) {
        return this.commonService.formpost('/downloadinvoice', value);
    };
    CompanyService.prototype.getcompanydefaultlocation = function () {
        return this.commonService.formpostOnlyid('/companydefaultlocation');
    };
    CompanyService.prototype.getcompanylocationtype = function () {
        return this.commonService.formpostOnlyid('/locationtype');
    };
    CompanyService.prototype.companylocationdetailsUpdate = function (value) {
        return this.commonService.formpost('/company/companylocationupdate', value);
    };
    CompanyService.prototype.companylocationDeletebyadmin = function (value) {
        return this.commonService.formpost('/company/deletecompanylocationbyadmin', value);
    };
    CompanyService.prototype.companylocationUpdatestatusbyadmin = function (value) {
        return this.commonService.formpost('/company/companylocationupdatestatusbyadmin', value);
    };
    CompanyService.prototype.emailupdate = function (value) {
        return this.commonService.formpost('/company/emailupdate', value);
    };
    CompanyService.prototype.mobileupdate = function (value) {
        return this.commonService.formpost('/company/mobileupdate', value);
    };
    CompanyService.prototype.otpupdate = function () {
        return this.commonService.formpostOnlyid('/company/otpupdate');
    };
    CompanyService.prototype.mobileotpupdate = function () {
        return this.commonService.formpostOnlyid('/company/otpmobileupdate');
    };
    CompanyService.prototype.resendotp = function () {
        return this.commonService.formpostOnlyid('/company/resendotp');
    };
    CompanyService.prototype.resendmobileotp = function () {
        return this.commonService.formpostOnlyid('/company/resendmobileotp');
    };
    CompanyService.prototype.getcompanyData = function () {
        return this.commonService.formpostOnlyid('/company/companydata');
    };
    CompanyService.prototype.gettotalcards = function () {
        return this.commonService.formpostOnlyid('/gettotalcards');
    };
    CompanyService.prototype.getnextbillingdate = function () {
        return this.commonService.formpostOnlyid('/nextbillingdate');
    };
    CompanyService.prototype.getnextcompanyinformationserviview = function () {
        return this.commonService.formpostOnlyid('/companyservicies');
    };
    CompanyService.prototype.getinvoicelist = function () {
        return this.commonService.formpostOnlyid('/getallinvoice');
    };
    CompanyService.prototype.sendinvoicesend = function (value) {
        return this.commonService.formpost('/sendinvoice', value);
    };
    CompanyService.prototype.checkstripecustomer = function () {
        return this.commonService.formpostOnlyid('/checkstripecustomer');
    };
    CompanyService.prototype.getcountCompanyData = function () {
        return this.commonService.formpostOnlyid('/company/totalcompanycount');
    };
    CompanyService.prototype.getplanDetails = function () {
        return this.commonService.formpostOnlyid('/company/companyPlanDetails');
    };
    CompanyService.prototype.companyprofileUpdate = function (value) {
        return this.commonService.formpost('/company/companyprofileupdatebyadmin', value);
    };
    CompanyService.prototype.updatecompanyimage = function (value) {
        return this.commonService.formpost('/company/companylogoupdate', value);
    };
    CompanyService.prototype.checkcompanyemployeeid = function (value) {
        return this.commonService.formpost('/company/checkcompanyemployeeid', value);
    };
    CompanyService.prototype.getemployeecontracttypelist = function () {
        return this.commonService.formpostOnlyid('/employeecontracttypelist');
    };
    CompanyService.ngInjectableDef = i0.defineInjectable({ factory: function CompanyService_Factory() { return new CompanyService(i0.inject(i1.CommonService)); }, token: CompanyService, providedIn: "root" });
    return CompanyService;
}());
exports.CompanyService = CompanyService;
