import { Component, OnInit, Input, Output,EventEmitter,SimpleChanges, OnDestroy  } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MyidproofService } from '../../services/myidproof.service';
import { CountryService } from '../../services/country.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Idproof } from '../../models/idproof/idproof.model';
import { UploadFileService } from './../../services/upload-file.service';
import { AlertService } from '../../services/alert.service';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, Observable } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ChecksystemService } from '../../services/checksystem.service';
import { AppGlobals } from '../../app.global';
import { MyvisaService } from '../../services/myvisa.service';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';

@Component({
  selector: 'app-employeecheckdocument',
  templateUrl: './employeecheckdocument.component.html',
  styleUrls: ['./employeecheckdocument.component.css']
})
export class EmployeecheckdocumentComponent implements OnInit, OnDestroy {


  logo: any;
  wizard:any = 0;

  @Input() items: Array<any>;
  idproofDetail: any;
  countrylist: any;
  Userid: any;
  selectedFiles: FileList;
  iframesrc: any;
  imgsrc: any;
  buttoninsert: any;
  filesizeerror: any;
  fileformaterror: any;
  file: any;
  filename: any;
  profilepic: any;
  pro_pic_size_error: any;
  pro_pic_format_error: any;
  profile_pic_url: any;
  ff:any;
  imgbackURL:any;
  public imagePath;
  imgURL: any;
  token_expired: any = false;
  disableBtn: any;  
  filebacksizeerror: any;
  filebackformaterror: any;
  selectedbackFiles: FileList;
  selected_profile_image: FileList;
  filebackname: any;
  submitted: any;
  idproofpopForm: FormGroup;
  visa: Idproof;
  permission: any;
  requestperm: any;
  accesstoken: string;
  employeemasterid:any;
  is_rtw: any = 0;
  frontimage:any;
  backimage:any;
  profileimage:any;
  showloader: boolean = false;
  timer: any;
  subscription: any;

  public showWebcam = true;
   public allowCameraSwitch = true;
   public multipleWebcamsAvailable = false;
   public deviceId: string;
   public videoOptions: MediaTrackConstraints = {
     // width: {ideal: 1024},
     // height: {ideal: 576}
   };
   public errors: WebcamInitError[] = [];
 
   // latest snapshot
   public webcamImage: WebcamImage = null;
 
   // webcam snapshot trigger
   private trigger: Subject<void> = new Subject<void>();
   // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
   private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();


  filterItems = [
    {
      name: 'All Visa Details',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Waiting for review',
      id: 1,
      selected: false
    }
  ]
  constructor(
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private iddetails: MyidproofService,     
    private visaService: MyvisaService, 
    private datePipe: DatePipe,
    private alerts: AlertService,
    private country: CountryService,
    private sanitizer:DomSanitizer, 
    private check: ChecksystemService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.buttoninsert = "Submit";
    this.disableBtn = true;
    this.submitted = false;
    this.disableBtn = true;
    this.buttoninsert = "Submit";
    this.idproofDetail = new Idproof();


    this.idproofpopForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      idnumber: ['', Validators.required],
      idtype: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      docexpiry: ['', Validators.required]
    });
    this.accesstoken = this.activatedRoute.snapshot.paramMap.get("access_token");
    this.activatedRoute.queryParams.subscribe(params => {
      this.accesstoken = params['access_token'];
      this.is_rtw = params['is_rtw'];
      localStorage.setItem('userToken', this.accesstoken);
      this.check.gettokensystem().subscribe((data: any) => { 
        if (data.sussess == 'true') {
          localStorage.setItem('userid', data.userid);
          localStorage.setItem('emptype', data.emptype);
          localStorage.setItem('isline', data.isline);
          localStorage.setItem('planid', data.planid);
          localStorage.setItem('isemail', data.isemail);
          localStorage.setItem('isadmin', data.isadmin);
          localStorage.setItem('ispermission', data.ispermission);
          localStorage.setItem('logoname', data.logourl);
          localStorage.setItem('ctype', data.ctype);
          const id = localStorage.getItem('userToken');
          this.Userid = localStorage.getItem('userid');
          const data2 = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
          if (data.logourl == "" || this.logo == null) {
            this.logo = "assets/img/logo.png";
          }else
          {
            this.logo =data.logourl;
          }
          this.idproofDetail.employeeid =data.userid;
          this.employeemasterid = data.userid;
          this.wizard = 0;  
          this.idproofDetail.nationalityid = "";     
          this.Userid = localStorage.getItem('userid');
          var dd =  localStorage.getItem('userid');
          this.idproofDetail.nationalityid = "";
          this.idproofDetail.employeeidproofdeatilid = "0";
          this.idproofDetail.idtype = 17;
          this.filename = "Choose file";
          this.profilepic = "Choose file";    
          this.filebackname = 'Choose file';
        }
        else
        {
          this.token_expired = true;
        }
      });
    });
    this.getCountries();
  }

  public ngOnDestroy() {
    if ( this.subscription && this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }

  getCountries()
  {
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
  }
  
  get f() {
    return this.idproofpopForm.controls;
  }

  toggle(id) {
    if(id ==1){
      this.submitted = true;
      // console.log(this.idproofpopForm);
      if (this.idproofpopForm.invalid) {
        return;
      }
    }

    if(id ==2){
      if(this.webcamImage){
        var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
        setTimeout(() => this.frontimage = imagepath, 5000);
        // console.log(this.imgsrc);
        this.webcamImage = null;
      }
    }else if(id ==3)
    {
      if(this.webcamImage){
        var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
        setTimeout(() => this.backimage = imagepath, 5000);
        // console.log(this.imgsrc);
        this.webcamImage = null;
      }
    }
    else if(id ==4)
    {
      var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
      setTimeout(() => this.profileimage = imagepath, 5000);
      this.webcamImage = null;
      this.setTimer();
    }
    this.wizard = id;
  }

  toggle2(id) {
    // if(id == 1){
    //   this.wizard2 = false;
    //   this.wizard1 = true;
    //   this.wizard3 = false;
    // }
    // if(id == 2){
    //   this.wizard3 = false;
    //   this.wizard1 = false;
    //   this.wizard2 = true;
    // }
  }

  onSubmit(value3: any, id: string, stype: string) {
    this.submitted = true;
    this.disableBtn = false;
    this.wizard = 6;
    
    value3.imagename = this.frontimage;
    value3.imagebackname = this.backimage;
    value3.idprofileimage = this.profileimage;
    value3.docexpiry = this.datePipe.transform(new Date(value3.docexpiry),"dd MMM yyyy");
    value3.isrighttowork = this.is_rtw;
    value3.stype = stype;
    value3.createby = this.Userid;  

    this.iddetails.myidproofdetailsUpdate(value3).subscribe((datamain: any) => {
      
      var data3 = {"employeeidproofdeatilid": datamain.employeeidproofdeatilid}
      this.iddetails.idproofdetailsbyidproofid(data3).subscribe((data: any) => {      
        var countryiso = data.nationality.isothree;
        var idtype = 'DRIVING_LICENSE';//data.documenttype.documentcode;     
        var visalog = {
          employeeid: this.employeemasterid,
          documentid: datamain.employeeidproofdeatilid,
          documenturl: value3.imagename,
          documenttype: 'id'
        };
        this.visaService.visaailogupdate(visalog).subscribe((dbvisaresult: any) => {        
          if(dbvisaresult.success =='1'){
            var data = { 
              merchantIdScanReference: dbvisaresult.logid, 
              country: countryiso,
              idType: idtype, 
              customerId: this.employeemasterid,
              is_rtw: this.is_rtw,
              callbackUrl: AppGlobals.API_ENDPOINT +"/api/aiconfirmation",
              documenturl: value3.imagename,
              documentbackurl: value3.imagebackname, 
              imageurl: value3.idprofileimage 
            };  
            
            this.visaService.visadocumentupdate(data).subscribe((data5: any) => {
              if(data5.success == true){
                this.check.deleteAccessToken().subscribe((tokenData: any) => {
                  if(tokenData.success)
                  { 
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('userid');
                    localStorage.removeItem('emptype');
                    localStorage.removeItem('isline');
                    localStorage.removeItem('planid');
                    localStorage.removeItem('isemail');
                    localStorage.removeItem('isadmin');
                    localStorage.removeItem('ispermission');
                    localStorage.removeItem('logoname');
                    localStorage.removeItem('ctype');
                    this.wizard = 5;
                  }
                    
                });
                
              }else {
                this.wizard = 4;
                this.alerts.error("something went wrong please try again");
              }          
            });
            }else{
              this.wizard = 4;
            }
        });
      });      
    });

    
  }

  public setTimer(){
    this.showloader   = true;
    this.timer        = Observable.timer(5000);
    this.subscription = this.timer.subscribe(() => {
        this.showloader = false;
    });
  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  selectFile(event)
  {
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = files[0];
    this.file = this.selectedFiles;
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.fileformaterror = true;    
    }else {
      this.fileformaterror = false;
      this.filesizeerror = false;
    
      var reader = new FileReader();
      this.imagePath = event.target.files;
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.imgURL = reader.result; 
      }    
      // this.selectedFiles = files[0]; 
    }
  }

  selectbackFile(event)
  {
    var files = event.target.files;
    this.filebackname = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filebacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filebackformaterror = true;
      this.filebacksizeerror = false;    
    }
    else
    {
      this.filebackformaterror = false;
      this.filebacksizeerror = false;   
      this.selectedbackFiles = files[0]; 
      var reader = new FileReader();
      this.imagePath = event.target.files;
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.imgbackURL = reader.result; 
      }    
    }
  }

  selectprofile(event)
  {
    var files = event.target.files;
    this.profilepic = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.pro_pic_size_error = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.pro_pic_format_error = true;
      this.pro_pic_size_error = false;    
    }
    else
    {
      this.pro_pic_format_error = false;
      this.pro_pic_size_error = false;   
      this.selected_profile_image = files[0]; 
      var reader = new FileReader();
      this.imagePath = event.target.files;
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.profile_pic_url = reader.result; 
      }    
    }
  }

  
  public triggerSnapshot(): void {
    this.trigger.next();
  }

  
  public triggerSnapshotreload(): void {
    this.webcamImage =null;
    
    //this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }




  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);

    // var imagepath = this.uploadService.uploadfilebase64(webcamImage);
    // this.imgsrc = imagepath;
    // console.log(imagepath);

    // let reader = new FileReader();
    //     // handle data
    //     reader.onload = event => {       
    //       console.log(event.target)
    //       if (event.target.result) {
    //         this.file.base64String = event.target.result;  
            
           
    //       }
    //     };
    //     reader.readAsDataURL(blob);
    
    // console.log(this.imgsrc);

    this.webcamImage = webcamImage;
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

}
