"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var AdversecreditreportComponent = /** @class */ (function () {
    function AdversecreditreportComponent() {
    }
    AdversecreditreportComponent.prototype.ngOnInit = function () {
    };
    return AdversecreditreportComponent;
}());
exports.AdversecreditreportComponent = AdversecreditreportComponent;
