<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
      <div class="container">
          <div class="tabs" id="tabs">
              <div class="tabs__content">
                  <div class="tabs__content__item active-tab">
                      <div class="content">
                          <div class="space_em row">
                              <div class="col-lg-7">
                                  <h5 class="hedingcolor">Employee List
                                      <a class="mainheading_btn " *ngIf="empperm > 1"
                                          (click)="openModal2('custom-employee')"> + Add New </a>
                                  </h5>
                              </div>
                              <div class="col-lg-2"> 
                                <div class="input-group">
                                  <select name="year" [(ngModel)]="year" class="form-control" (change)="yearChange($event.target.value)">
                                    <option value="{{y}}" *ngFor="let y of yearList">{{ y }}</option>
                                  </select>
                                </div>
                            </div>
                              <div class="col-lg-3">
                                  <div class="input-group">
                                      <input [(ngModel)]="searchText" placeholder="Search.." class="form-control width100">
                                  </div>
                              </div>
                             
                          </div>
                          <ng-container *ngIf="( data |  filter:{fname: searchText,mname: searchText,lname: searchText,emailid: searchText, countryname: searchText, ninumber: searchText, employeeid: searchText}) as pageOfItems">
                          <div class="job_list_section employee_list_section"
                              *ngFor="let employee of pageOfItems;  let i=index">
                              <div class="job_detils_date">
                                  <div class="visa_user_pic">
                                      <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                      <img *ngIf="employee.imagename" src="{{employee.imagename}}"
                                          class="img width100"
                                          alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                      <img *ngIf="employee.gender == true && (employee.imagename == null || employee.imagename =='') "
                                          src="../../../../../assets/img/user_pic_emp.png" class="img width100"
                                          alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                      <img *ngIf="employee.gender == false && (employee.imagename == null || employee.imagename =='')"
                                          src="../../../../../assets/img/fe.png" class="img width100"
                                          alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                  </div>

                              </div>
                              <div class="job_detils_date width22">
                                  <h5 class="job_profile font_17">{{employee.fname}} {{employee.mname}}
                                      {{employee.lname}}</h5>
                                  <p class="email"><i aria-hidden="true" class="fa fa-envelope-o"></i>
                                      {{employee.emailid}}</p>
                                  <p class="phone"><i aria-hidden="true" class="fa fa-phone"></i>
                                      {{employee.phonenumber}}</p>
                              </div>
                              <div class="job_detils_date width15">
                                  <p class="job_address">NI Number</p>
                                  <p class="job_profile text-primary">{{employee.ninumber}}</p>
                              </div>
                              <div class="job_detils_date width20">
                                  <p class="job_address">Employee ID</p>
                                  <p class="job_profile"> {{employee.employeeid}}</p>
                              </div>
                              <div class="job_detils_date width22">
                                  <p class="job_address">Nationality</p>
                                  <p class="badge badge-danger" *ngIf="employee.nationality">
                                      {{employee.nationality.countryname}} </p>
                              </div>
                              <div class="job_detils_date width10">
                                <p class="job_address">Leave Count</p>
                                <p class="job_profile"> {{employee.totalleave?employee.totalleave.leavecount:0}}</p>
                            </div>
                              <div class="job_detils_date  d-flex aligncenter">
                                  <div class="job_address">
                                    <button type="button" class="btn btn-primary com_btn" (click)="editLeaveCount(employee.employeemasterid)">
                                      <div class="tooltip_visa">
                                          <p >Edit</p>
                                      </div>											
                                      <i class="fa fa-pencil"></i>
                                    </button>
                                  </div>
                              </div>
                          </div>
                          <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                            <div class="job_detils_date">
                                <p class="job_profile">No Record Found </p>
                            </div>
                        </div>  
                        <div class="job_list_section visa_request_section" *ngIf="yearList === 0">
                            <div class="job_detils_date">
                                <p class="job_profile">No Record Found </p>
                            </div>
                        </div>  
                        </ng-container>
                        <div class="row">
                            <div class="col-lg-12 text-right">
                                <jw-pagination [items]="pageOfItems" [pageSize]="30" [maxPages]="10"
                                    (changePage)="onChangePage($event)"></jw-pagination>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<jw-modal id="custom-leave" role="dialog" class="modal modalsmall">
  <form [formGroup]="leaveForm" #leaveform="ngForm" class="s12 white"
      (ngSubmit)="onSubmitLeave(leaveform.value,'custom-leave')">
      <div role="document" class="formgroup">
          <div class="modal-content">
              <!-- <div class="modal-header">
                  <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid"
                      id="employeeid" [(ngModel)]="leaveDetail.employeeid">
                  <input type="hidden" formControlName="id" class="form-control" required name="id"
                      id="id" [(ngModel)]="leaveDetail.id">
                  <h5 class="modal-title" id="popupLabel">Leave Details</h5>
                  <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                      (click)="closeModal('custom-leave');">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div> -->
              <div class="modal-body">
                  <div class="container">
                      <div class="row">
                          
                          <div class="form-group width100">
                              <label>Leave Count*</label>
                              <input type="text" formControlName="leavecount" name="leavecount"
                                  id="leavecount" class="form-control" maxlength="3"
                                  [ngClass]="{ 'is-invalid': submitted && g.leavecount.errors }"
                                  [(ngModel)]="leaveDetail.leavecount">
                              <div *ngIf="submitted && g.leavecount.errors" class="invalid-feedback">
                                  <div *ngIf="g.leavecount.errors.required">Please enter leave count
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Save" />
                  <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel"
                      (click)="closeModal('custom-leave');" />
              </div>
          </div>
      </div>
  </form>
</jw-modal>

