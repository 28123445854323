import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { CustomerService } from '../../../services/customer.service';
import { CountryService } from '../../../services/country.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ModalService } from '../../../_services/modal.service';
import { ZoomService } from '../../../services/zoom.service';
import { Profile, Termination, righttowork } from '../../../models/overview/profile.model';
import { AppGlobals } from '../../../app.global';
import SignaturePad from 'signature_pad';
import { CheckserviceService } from '../../../services/checkservice.service';
import { Passport } from '../../../models/passport/passport.model';
import { UploadFileService } from './../../../services/upload-file.service';
import { MypassportService } from '../../../services/mypassport.service';
import { MyidproofService } from '../../../services/myidproof.service';
import { MyvisaService } from '../../../services/myvisa.service';
import { ChecksystemService } from '../../../services/checksystem.service';
import { DomSanitizer } from '@angular/platform-browser';
import { candidatenotes } from '../../../models/jobs/candidate.model';
import { Visa } from '../../../models/visa/visa.model';
import { Idproof } from '../../../models/idproof/idproof.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-right-to-work-app',
  templateUrl: './right-to-work-app.component.html',
  styleUrls: ['./right-to-work-app.component.css']
})
export class RightToWorkAppComponent implements OnInit {

  meetingformGroup: FormGroup;
  user_id: any;
  empid: any;
  loading: any = 0;  
  stage: any;
  timeValues: any;
  employeedetails: any;
  countrylist: any;
  datalist: any;
  right = new righttowork();
  current_stage: any = "";
  request_type: any = "";
  rtw_country: any = 0;
  total_stages: any = 5;
  per_stage: any = 0;
  progressbar: any = 0;
  submitted: boolean;
  questionlist: any;
  questionselect: any = [];
  countrywiseQuestionList: any = [];
  passportFormgroup: FormGroup;
  visaFormgroup: FormGroup;
  idproofFormgroup: FormGroup;
  sharecodeFormGroup: FormGroup;
  openDocumentForm: any = 0;
  selectedDocList: any = [];
  disableBtn: any;
  confirmdoc:boolean =false;
  signaturePad: SignaturePad;
  hidediv:boolean =true;

  ninumber:string;
  docprogress: any = 0;
  datetoday: any;
  // passport vars
  passportDetail: any;
  selectedpassportFrontFiles: FileList;
  selectedpassportBackFiles: FileList;
  selectedpassportProfiles: FileList;
  passportfrontfilesizeerror: any;
  passportfrontfileformaterror: any;
  passportfrontfilerequirederror: any;
  passportbackfilesizeerror: any;  
  passportbackfileformaterror: any;
  passportprofilerequirederror: any;
  passportprofilesizeerror: any;  
  passportprofileformaterror: any;
  passportfront: any;
  passportback: any;
  passportprofile: any;
  passportfrontfilename: any = "Choose file";
  passportbackfilename: any = "Choose file";
  passportprofilename: any = "Choose file";
  // passport vars

  updatebutton:boolean =false;

  // visa vars
  visaDetail: any;
  selectedvisaFrontFiles: FileList;
  selectedvisaBackFiles: FileList;
  selectedvisaProfiles: FileList;
  visafrontfilesizeerror: any;  
  visafrontfileformaterror: any;
  visafrontfilerequirederror: any;
  visabackfilesizeerror: any;  
  visabackfileformaterror: any;
  visaprofilerequirederror: any;
  visaprofilesizeerror: any;  
  visaprofileformaterror: any;
  visafront: any;
  visaback: any;
  visaprofile: any;
  visafrontfilename: any = "Choose file";
  visabackfilename: any = "Choose file";
  visaprofilename: any = "Choose file";
  // visa vars

  // document vars
  documentDetail: any;
  selecteddocFrontFiles: FileList;
  selecteddocBackFiles: FileList;
  selecteddocProfiles: FileList;
  docfrontfilesizeerror: any;  
  docfrontfileformaterror: any;
  docfrontfilerequirederror: any;
  docbackfilesizeerror: any;  
  docbackfileformaterror: any;
  docprofilerequirederror: any;
  docprofilesizeerror: any;  
  docprofileformaterror: any;
  docfront: any;
  docback: any;
  docprofile: any;
  docfrontfilename: any = "Choose file";
  docbackfilename: any = "Choose file";
  docprofilename: any = "Choose file";
  documenttype: any;
  docname: any = "";
  currentdocid: any;
  // document vars
  record:any;
  items:any;
  signatureImg: string;
  //Step 2 Check
  stepquestion:any;
  questionlist1:any;
  totalquestioncount:any;
  questionstring:any;
  showfinish:boolean =false;

  //log table variable set
  isrighttowork:any;
  isrighttoworktype:any;
  countryid:any;
  qtype:any;
  doucmentlist:any;
  remaindocumentlist:any;
  logcurrentstage:any;
  remainingcheck:any;
  logid:any;
  profileimage: any;
  questionError: any = false;
  
  visapageOfItems: Array<any>;
  passportpageOfItems: Array<any>;
  docpageOfItems: Array<any>;
  iframesrc: any;
  imgsrc: any;

  //notes for right to work check 
  candidatenoteFormGroup: FormGroup;
  candidateshareFormGroup: FormGroup;

  candidatenotesdetails = new candidatenotes();

  
  sharcodedetails = new candidatenotes();

  @ViewChild('canvas') canvasEl: ElementRef;
  //document upload code
  pdfbackfilesizeerror:any;  
  pdfbackfileformaterror:any;
  docpdffilename:any = "Choose file";
  docpdf:any;
  selectdocpdffilename: FileList;

  update: any = false;
  currentCompany: any;
  updateButton: any = false;


  updaterighttowork = {passport:false,visa:false,document:[],documentid: []};


  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private country: CountryService,
    private modalService: ModalService,     
    private customerdetails: CustomerService,
    private uploadService: UploadFileService,
    private passportService: MypassportService,
    private visaService: MyvisaService, 
    private documentService: MyidproofService, 
    private datePipe: DatePipe,
    private check:ChecksystemService,
    private checkService: CheckserviceService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    // this.sendrequestchargestage(5);
    this.datetoday = new Date();
    this.per_stage = 100/this.total_stages;
    
    this.loading = 0;
    this.documentService.iddocumenttype().subscribe((documenttype: any) => {
      // console.log(documenttype);
      this.documenttype = documenttype;
    
      this.confirmdoc = false;
      this.ninumber = '';

    var logid = localStorage.getItem('logid');
    console.log(logid);

    if(logid)
    {

      this.logid = logid;
     
     
      var righttowork ={ id: this.logid }
      this.checkService.selectrighttowork(righttowork).subscribe((righttoworklog: any) => {

        righttoworklog= righttoworklog.rightoworklog;
        
        // this.stage = 5;
        this.countryid =righttoworklog.countryid;
        this.isrighttowork = righttoworklog.isrighttowork;
        this.isrighttoworktype = righttoworklog.isrighttoworktype;
        var selectdoc =righttoworklog.remaindocumentlist; 
        var totaldoc = righttoworklog.doucmentlist.split(','); 
        this.profileimage = righttoworklog.profileimage;
        this.ninumber = righttoworklog.ninumber;

        if(righttoworklog.documentid)
        {
            var ss = righttoworklog.documentid.split(',');
            var tt=[];

            ss.forEach(element => {
              tt.push(parseInt(element));          
            });

            this.updaterighttowork.documentid=tt;//righttoworklog.documentid.split(',');
        }


      
if(this.logid){

  if(this.isrighttowork !=2){

    this.candidatenoteFormGroup = this.formBuilder.group({    
      notes: ['', Validators.required],
      sharecode: [''],
      Checkconductedby: ['', Validators.required],
      Checkconductedbyrole: ['', Validators.required],  
      companyname: ['', Validators.required],
      ninumber: ['', Validators.required],      
      dateofbirth: ['', Validators.required]      
    });
  }
  else
  {
    this.candidatenoteFormGroup = this.formBuilder.group({    
      notes: ['', Validators.required],
      sharecode: [''],
      Checkconductedby: ['', Validators.required],
      Checkconductedbyrole: ['', Validators.required],  
      companyname: [''],
      ninumber: [''],      
      dateofbirth: ['']      
    });

  }

  }



        this.selectedDocList = [];
        
        if(selectdoc){
        var splitted = selectdoc.split(",");
        splitted.forEach(queid => {                
        // splitted.forEach(function (value) {
          this.selectedDocList.push(queid);
        });
      }

        if( this.isrighttowork !=2)
          this.sendrequestchargestage(5, 'second');
        else 
          this.sendrequestchargestage(11, 'second');


          console.log('rahul kumar tanwar',this.stage);
        
          if( this.isrighttowork !=2)
              this.performAction();

        if(this.isrighttowork ==0){
          if(splitted.length==totaldoc.length){
            this.update = true;
            this.questionselect = righttoworklog.questionlist.split(',');
            this.country.getcountrylistbyid({'id': righttoworklog.countryid}).subscribe((country: any) => {
              this.currentCompany = country;
              // this.stage = 9;
              // this.sendrequestchargestage('4', country.countryid, '', country.isrighttoworkcountry);
            });
          }
          if(this.selectedDocList.length ==0 || (this.selectedDocList.length ==1 && this.selectedDocList[0]=="")){
            this.stage =7;
            if(righttoworklog.anslist == '' || righttoworklog.anslist == null){
              this.allquestionlist();
              this.stage =6;
            }   
          }
        }
        console.log(this.update)

        if(this.isrighttowork ==1){
          if(this.selectedDocList.length ==0 || (this.selectedDocList.length ==1 && this.selectedDocList[0]=="")){
            if(righttoworklog.anslist == '' || righttoworklog.anslist == null){
              this.allquestionlist();
              // this.stage =6;
              this.stage =17;
            }
          } else{
            if(splitted.length==totaldoc.length){
              this.update = true;
              this.questionselect = righttoworklog.questionlist.split(',');
              this.country.getcountrylistbyid({'id': righttoworklog.countryid}).subscribe((country: any) => {
                this.currentCompany = country;
                this.stage = 9;
                // this.sendrequestchargestage('4', country.countryid, '', country.isrighttoworkcountry);
              });
            } else{
              this.stage = 9;
            }
          }
        }
      });
    } else{
      this.stage = 1;
    }
  });
    this.progressbarIncrease();
    
    // this.checkQuestionList();

    
    // this.allquestionlist();
    this.timeValues = AppGlobals.TIME_VALUES;
    this.user_id = localStorage.getItem('userid');
    this.empid = this.activatedRoute.snapshot.paramMap.get("id");
    this.customerdetails.getOverviewbyadmin({ 'id': this.empid }).subscribe((remaindata: any) => { 
      console.log(remaindata);
      this.employeedetails = remaindata;
    });
    this.country.getcountrylist().subscribe((countries: any) => {
      this.countrylist = countries;
      this.datalist = countries;
    });

    this.checkService.rtwquestionlist().subscribe((questions: any) => {
      this.questionlist = questions;
      // console.log(questions);
    });

    this.meetingformGroup = this.formBuilder.group({
      startdate: ['', Validators.required],
      starttime: ['', Validators.required],
      endtime: ['', Validators.required]
    });

    /** passport formgroup */

    this.passportFormgroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      passportnumber: ['', Validators.required],
      placeofissue: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      passportissue: ['', Validators.required],
      passportexpiry: ['', Validators.required]
      
    });

    /** visa formgroup */

    this.visaFormgroup = this.formBuilder.group({
      employeeid: ['', Validators.required],
      id: ['', Validators.required],
      visanumber: ['', Validators.required],
      visatype: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      visaexpiry: ['', Validators.required],
      visastart: ['', Validators.required]
    });

    /** document formgroup */

    this.idproofFormgroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      idnumber: ['', Validators.required],
      idtype: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      docexpiry: ['', Validators.required]
    });


    this.candidateshareFormGroup = this.formBuilder.group({    
      notes: ['', Validators.required],
      sharecode: [''],
      Checkconductedby: ['', Validators.required],
      Checkconductedbyrole: ['', Validators.required],      
    });


if(this.logid){

  if(this.isrighttowork !=2){

    this.candidatenoteFormGroup = this.formBuilder.group({    
      notes: ['', Validators.required],
      sharecode: [''],
      Checkconductedby: ['', Validators.required],
      Checkconductedbyrole: ['', Validators.required],  
      companyname: ['', Validators.required],
      ninumber: ['', Validators.required],      
      dateofbirth: ['', Validators.required]      
    });
  }
  else
  {
    this.candidatenoteFormGroup = this.formBuilder.group({    
      notes: ['', Validators.required],
      sharecode: [''],
      Checkconductedby: ['', Validators.required],
      Checkconductedbyrole: ['', Validators.required],  
      companyname: [''],
      ninumber: [''],      
      dateofbirth: ['']      
    });

  }

  }else{
    this.candidatenoteFormGroup = this.formBuilder.group({    
      notes: ['', Validators.required],
      sharecode: [''],
      Checkconductedby: ['', Validators.required],
      Checkconductedbyrole: ['', Validators.required],  
      companyname: ['', Validators.required],
      ninumber: ['', Validators.required],      
      dateofbirth: ['', Validators.required]      
    });

  }
    /** Share Code Details */

    this.sharecodeFormGroup = this.formBuilder.group({    
      companyname: ['', Validators.required],
      ninumber: ['', Validators.required],
      sharecode: ['', Validators.required],
      dateofbirth: ['', Validators.required]     
    });

  }

  get returnForm() {
    return this.meetingformGroup.controls;
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  startDrawing(event: Event) {
    console.log(event);
    // works in device not in browser

  }
  /** Passport form Process */

  get f() { return this.passportFormgroup.controls; }

  get j() { return this.candidatenoteFormGroup.controls; }


  get l() { return this.candidateshareFormGroup.controls; }

  

  get k() { return this.sharecodeFormGroup.controls; }

  
  moved(event: Event) {

    console.log(event);
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
  }

  onSubmitPassport( value3: any,id: string) {
    this.submitted = true;
    // console.log(this.passportFormgroup);
    if (this.passportFormgroup.invalid) {
      if(!this.selectedpassportFrontFiles){
        this.passportfrontfilerequirederror = true;
      }
      if(!this.selectedpassportProfiles && !this.profileimage){
        this.passportprofilerequirederror = true;
      }
      return;
    }

    if(this.selectedpassportFrontFiles){
      this.passportfront = this.selectedpassportFrontFiles;
      var filetype = this.passportfront.type.split('/');
      var typeoffile = filetype[filetype.length - 1];
      if(this.passportfront.size > 2000*1024){
        this.passportfrontfilesizeerror = true;
        return;
      } else if((typeoffile != "jpeg") && (typeoffile != "jpg") && (typeoffile != "png")){
        this.passportfrontfileformaterror = true;
        return;
      }
      var frontpassportimagepath = this.uploadService.uploadfile(this.passportfront);
      value3.imagename = frontpassportimagepath;
    } else{
      this.passportfrontfilerequirederror = true;
      return;
    }

    if(this.selectedpassportBackFiles){
      this.passportback = this.selectedpassportBackFiles;
      var filetype = this.passportback.type.split('/');
      var typeoffile = filetype[filetype.length - 1];
      if(this.passportback.size > 2000*1024){
        this.passportbackfilesizeerror = true;
        return;
      } else if((typeoffile != "jpeg") && (typeoffile != "jpg") && (typeoffile != "png")){
        this.passportbackfileformaterror = true;
        return;
      }
      var backpassportimagepath = this.uploadService.uploadfile(this.passportback);
      value3.imagebackname = backpassportimagepath;
    }

    if(this.selectedpassportProfiles){
      this.passportprofile = this.selectedpassportProfiles;
      var filetype = this.passportprofile.type.split('/');
      var typeoffile = filetype[filetype.length - 1];
      if(this.passportprofile.size > 2000*1024){
        this.passportprofilesizeerror = true;
        return;
      } else if((typeoffile != "jpeg") && (typeoffile != "jpg") && (typeoffile != "png")){
        this.passportprofileformaterror = true;
        return;
      }
      var profilepassportimagepath = this.uploadService.uploadfile(this.passportprofile);
      value3.passportprofileimage = profilepassportimagepath;
    } else{
      if(!this.profileimage){
        this.passportprofilerequirederror = true;
        return;
      } else{
        value3.passportprofileimage = this.profileimage;
      }
    }
    value3.createby = this.user_id;
    value3.isrighttowork = 1;
    this.disableBtn = false;
    const sd = new Date(value3.passportissue);
    value3.passportissue = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    const ed = new Date(value3.passportexpiry);
    value3.passportexpiry = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());

    this.passportService.mypassportdetailsUpdatebyadmin(value3).subscribe((data: any) => {
      this.profileimage = data.passportprofileimage;
      this.selectedDocList.splice(0,1);
     
      var righttowork = {
        id: this.logid,
        remaindocumentlist: this.selectedDocList,
        passportid: data.employeepassdeatilid,
        profileimage: data.passportprofileimage
      };
      // console.log(this.logid);
      this.checkService.updatenewrighttowork(righttowork).subscribe((righttoworklog: any) => {        
        this.performAction(); 
      });
    });
    //  this.alerts.success('Passport details successfully.',true);
    // this.modalService.close(id);
  }

  selectPassportFrontFile(event)
  {
    this.passportfrontfilesizeerror = false;  
    this.passportfrontfileformaterror = false;
    this.passportfrontfilerequirederror =false;
    var files = event.target.files;
    this.passportfrontfilename = files[0].name;
    this.selectedpassportFrontFiles = this.passportfront = files[0];
    var filetype = this.passportfront.type.split('/');
    if (this.passportfront.size > 2000 * 1024) {
      this.passportfrontfilesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.passportfrontfileformaterror = true;    
    }else{
      this.selectedpassportFrontFiles = files[0];
    }
  }

  selectPassportBackFile(event)
  {
    this.passportbackfilesizeerror = false;  
    this.passportbackfileformaterror = false;
    var files = event.target.files;
    this.passportbackfilename = files[0].name;
    this.selectedpassportBackFiles = this.passportback = files[0];
    var filetype = this.passportback.type.split('/');
    if (this.passportback.size > 2000 * 1024) {
      this.passportbackfilesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.passportbackfileformaterror = true;    
    }else{
      this.selectedpassportBackFiles = files[0];
    }
  }

  selectPassportProfile(event)
  {
    this.passportprofilerequirederror =false;
    this.passportprofilesizeerror = false;  
    this.passportprofileformaterror = false;
    var files = event.target.files;
    this.passportprofilename = files[0].name;
    this.selectedpassportProfiles = this.passportprofile = files[0];
    var filetype = this.passportprofile.type.split('/');
    if (this.passportprofile.size > 2000 * 1024) {
      this.passportprofilesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.passportprofileformaterror = true;    
    }else{
      this.selectedpassportProfiles = files[0];
    }
  }

  /** Passport form Process */

  /** Visa form Process */

  get v() { return this.visaFormgroup.controls; }

  onSubmitVisa(value3: any) {
    // console.log(this.visaFormgroup);
    this.submitted = true;
    
    if (this.visaFormgroup.invalid) {
      if(!this.selectedvisaFrontFiles){
        this.visafrontfilerequirederror = true;
      }
      if(!this.selectedvisaProfiles && !this.profileimage){
        this.visaprofilerequirederror = true;
      }
      return;
    }
    if (this.selectedvisaFrontFiles) {
      this.visafront = this.selectedvisaFrontFiles;
      var filetype = this.visafront.type.split('/');
      if (this.visafront.size > 2000 * 1024) {
        this.visafrontfilesizeerror = true;
        return;
      }
       else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.visafrontfileformaterror = true;
        return;
      }
      var visafrontimagepath = this.uploadService.uploadfile(this.visafront);
      value3.imagename = visafrontimagepath;
    } else {
      this.visafrontfilerequirederror = true;
      return;
    }

    
    if (this.selectedvisaBackFiles) {
      this.visaback = this.selectedvisaBackFiles;
      var filetype = this.visaback.type.split('/');
      if (this.visaback.size > 2000 * 1024) {
        this.visabackfilesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.visabackfileformaterror = true;
        return;
      }
      var visabackimagepath = this.uploadService.uploadfile(this.visaback);
      value3.imagebackname = visabackimagepath;
    }

    if (this.selectedvisaProfiles) {
      this.visaprofile = this.selectedvisaProfiles;
      var filetype = this.visaprofile.type.split('/');
      if (this.visaprofile.size > 2000 * 1024) {
        this.visaprofilesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.visaprofileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.visaprofile);
      value3.visaprofileimage = imagepath;
    }
    else {
      if(!this.profileimage){
        this.visaprofilerequirederror = true;
        return;
      } else{
        value3.visaprofileimage = this.profileimage;
      }
    }
    // value3.visastart = this.datePipe.transform(new Date(value3.visastart),"dd MMM yyyy");
    // value3.visaexpiry = this.datePipe.transform(new Date(value3.visaexpiry),"dd MMM yyyy");
    value3.createby = this.user_id;
    value3.isrighttowork = 1;
    this.disableBtn = false;
    const sd = new Date(value3.visastart);
    value3.visastart = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    const ed = new Date(value3.visaexpiry);
    value3.visaexpiry = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());

    this.visaService.myvisadetailsUpdatebyadmin(value3).subscribe((datamain: any) => {
      this.profileimage = datamain.visaprofileimage;
      this.selectedDocList.splice(0,1);
      var righttowork ={ id: this.logid ,  remaindocumentlist: this.selectedDocList ,visaid: datamain.employeevisadeatilid, profileimage: datamain.visaprofileimage }
      this.checkService.updatenewrighttowork(righttowork).subscribe((righttoworklog: any) => {        
        this.performAction(); 
      });
    });
  }

  checkrightoworkupdate(){

    var checkvalue =0;

    this.updatebutton = false;

    if(this.updaterighttowork.passport)
    {
      checkvalue =1;
    }
    
    if(this.updaterighttowork.visa)
    {
      checkvalue =1;
    }

    if(this.updaterighttowork.document.length >0)
    {
      checkvalue =1;
    }

    if(checkvalue ==1)
    {
      this.updatebutton = true;
    }
  
  }



  updaterighttoworkdocument()
  {
    var checkvalue =0;

    if(this.updaterighttowork.passport)
    {
      checkvalue =1;
    }
    
    if(this.updaterighttowork.visa)
    {
      checkvalue =1;
    }

    if(this.updaterighttowork.document.length >0)
    {
      checkvalue =1;
    }



   var righttowork ={ id: this.logid, right: this.updaterighttowork  }

   this.checkService.resedapplicantrequestwidthdocumentupdate(righttowork).subscribe((righttoworklog: any) => { 
    this.stage =8;
   });


    // console.log(this.updaterighttowork);
  }


  changeidproofdetails(checked:any,id:any,masterid:any)
  {
    console.log(masterid);
    if(checked)
    {
      this.updaterighttowork.document.push(id);



      const index = this.updaterighttowork.documentid.indexOf(masterid);

      if (index > -1) 
      {
        this.updaterighttowork.documentid.splice(index, 1);
      } 
    }
    else
    {
      this.updaterighttowork.documentid.push(masterid);
      const index = this.updaterighttowork.document.indexOf(id);
      if (index > -1) 
      {
        this.updaterighttowork.document.splice(index, 1);
      }      
    }


    this.checkrightoworkupdate();

    // console.log(this.updaterighttowork);



  }


  resendvisa(checked:any,id:any){
    console.log(checked);
  if(checked)
    this.updaterighttowork.visa = true;
  else
    this.updaterighttowork.visa = false;

    
    this.checkrightoworkupdate();
  }

  resendpassport(checked:any,id:any){ 
    console.log(checked);
       
    if(checked)
      this.updaterighttowork.passport = true;
    else
      this.updaterighttowork.passport = false;


      
    this.checkrightoworkupdate();
  }


  selectVisaFrontFile(event){
    this.visafrontfilesizeerror = false;  
    this.visafrontfileformaterror = false;
    this.visafrontfilerequirederror = false;
    var files = event.target.files;
    this.visafrontfilename = files[0].name;
    this.selectedvisaFrontFiles = this.visafront = files[0];
    var filetype = this.visafront.type.split('/');
    if (this.visafront.size > 2000 * 1024) {
      this.visafrontfilesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.visafrontfileformaterror = true;    
    }else{
      this.selectedvisaFrontFiles = files[0];
    }
  }

  selectVisaBackFile(event){
    this.visabackfilesizeerror = false;  
    this.visabackfileformaterror = false;
    var files = event.target.files;
    this.visabackfilename = files[0].name;
    this.selectedvisaBackFiles = this.visaback = files[0];
    var filetype = this.visaback.type.split('/');
    if (this.visaback.size > 2000 * 1024) {
      this.visabackfilesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.visabackfileformaterror = true;    
    }else{
      this.selectedvisaBackFiles = files[0];
    }
  }

  selectVisaProfile(event){
    this.visaprofilerequirederror =false;
    this.visaprofilesizeerror = false;  
    this.visaprofileformaterror = false;
    var files = event.target.files;
    this.visaprofilename = files[0].name;
    this.selectedvisaProfiles = this.visaprofile = files[0];
    var filetype = this.visaprofile.type.split('/');
    if (this.visaprofile.size > 2000 * 1024) {
      this.visaprofilesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.visaprofileformaterror = true;    
    }else{
      this.selectedvisaProfiles = files[0];
    }
  }

  /** Document form process */

  get d() {
    return this.idproofFormgroup.controls;
  }

  onSubmitDocument(value3: any, id: string, stype: string) {
    
    this.submitted = true;
    // this.fileformaterror = false;
    // console.log(this.selecteddocFrontFiles);
    if (this.idproofFormgroup.invalid) {
      if(!this.selecteddocFrontFiles){
        this.docfrontfilerequirederror = true;
      }
      if(!this.selecteddocProfiles && !this.profileimage){
        this.docprofilerequirederror = true;
      }
      return;
    }
    if (this.selecteddocFrontFiles) {
      this.docfront = this.selecteddocFrontFiles;
      var filetype = this.docfront.type.split('/');
      if (this.docfront.size > 2000 * 1024) {
        this.docfrontfilesizeerror = true;
        return;
      } else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") ) {
        this.docfrontfileformaterror = true;
        return;
      }
      var frontdocimagepath = this.uploadService.uploadfile(this.docfront);
      value3.imagename = frontdocimagepath;
    } else {
      this.docfrontfilerequirederror = true;
      return;
    }

    if (this.selecteddocBackFiles) {
      this.docback = this.selecteddocBackFiles;
      var filetype = this.docback.type.split('/');
      if (this.docback.size > 2000 * 1024) {
        this.docbackfilesizeerror = true;
        return;
      } else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
        this.docbackfileformaterror = true;
        return;
      }
      var docbackimagepath =  this.uploadService.uploadfile(this.docback);
      value3.imagebackname = docbackimagepath;
    }

    if (this.selecteddocProfiles) {
      this.docprofile = this.selecteddocProfiles;
      var filetype = this.docprofile.type.split('/');
      if (this.docprofile.size > 2000 * 1024) {
        this.docprofilesizeerror = true;
        return;
      } else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.docprofileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.docprofile);
      value3.idprofileimage = imagepath;
    } else {
      if(!this.profileimage){
        this.docprofilerequirederror = true;
        return;
      } else{
        value3.idprofileimage = this.profileimage;
      }
    }

    // value3.docexpiry = this.datePipe.transform(new Date(value3.docexpiry),"dd MMM yyyy");
    value3.createby = this.user_id;
    value3.updateby = this.user_id;
    this.disableBtn = false;
    const sd = new Date(value3.docexpiry);
    value3.docexpiry = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());

    this.documentService.myidproofdetailsUpdatebyadmin(value3).subscribe((datamain: any) => { 
      // console.log(datamain);
      this.profileimage = datamain.idprofileimage;
      this.selectedDocList.splice(0,1);
      var righttowork ={ id: this.logid ,  remaindocumentlist: this.selectedDocList, documentid: datamain.employeeidproofdeatilid, profileimage: datamain.idprofileimage  }
      this.checkService.updatenewrighttowork(righttowork).subscribe((righttoworklog: any) => {      
        this.selecteddocFrontFiles = undefined;
        this.selecteddocBackFiles = undefined;
        this.performAction();
      });      
    });
  }

  selectDocFrontFile(event){
    this.docfrontfilesizeerror = false;  
    this.docfrontfileformaterror = false;
    this.docfrontfilerequirederror = false;
    var files = event.target.files;
    this.docfrontfilename = files[0].name;
    this.selecteddocFrontFiles = this.docfront = files[0];
    var filetype = this.docfront.type.split('/');
    if (this.docfront.size > 2000 * 1024) {
      this.docfrontfilesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.docfrontfileformaterror = true;    
    }else{
      this.selecteddocFrontFiles = files[0];
    }
  }

  selectDocBackFile(event){
    this.docbackfilesizeerror = false;  
    this.docbackfileformaterror = false;
    var files = event.target.files;
    this.docbackfilename = files[0].name;
    this.selecteddocBackFiles = this.docback = files[0];
    var filetype = this.docback.type.split('/');
    if (this.docback.size > 2000 * 1024) {
      this.docbackfilesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.docbackfileformaterror = true;    
    }else{
      this.selecteddocBackFiles = files[0];
    }
  }

  selectDocProfile(event){
    this.docprofilerequirederror =false;
    this.docprofilesizeerror = false;  
    this.docprofileformaterror = false;
    var files = event.target.files;
    this.docprofilename = files[0].name;
    this.selecteddocProfiles = this.docprofile = files[0];
    var filetype = this.docprofile.type.split('/');
    if (this.docprofile.size > 2000 * 1024) {
      this.docprofilesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.docprofileformaterror = true;    
    }else{
      this.selecteddocProfiles = files[0];
    }
  }
  /** Upload report*/

  selectPdfFile(event){    
    this.pdfbackfilesizeerror = false;  
    this.pdfbackfileformaterror = false;
    var files = event.target.files;
    this.docpdffilename = files[0].name;
    this.selectdocpdffilename = this.docpdf = files[0];
    var filetype = this.docpdf.type.split('/');
    if (this.docpdf.size > 2000 * 1024) {
      this.pdfbackfilesizeerror = true;    
    }
    else if (filetype[filetype.length - 1] != "pdf" )  {
      this.pdfbackfileformaterror = true;    
    }else{
      this.confirmdoc =true;
      this.selectdocpdffilename = files[0];
    }
  }


  /** Document form process */

  progressbarIncrease(){
    if(this.stage ==1){
      this.progressbar = 0;//this.progressbar - this.per_stage/2;
    } else if(this.stage==2 || this.stage==3){
      this.progressbar = this.progressbar + this.per_stage/2;
    } else if(this.stage==5){
      // this.progressbar = this.progressbar + this.per_stage/;
    } else{
      this.progressbar = this.progressbar + this.per_stage;
    }
  }

  progressbarDecrease(){
    if(this.stage ==1){
      this.progressbar = 0;//this.progressbar - this.per_stage/2;
    }
    if(this.stage==2 || this.stage==3){
      this.progressbar = this.progressbar - this.per_stage/2;
    } else{
      this.progressbar = this.progressbar - this.per_stage;
    }
  }

  backstage(stagechange){
    this.loading =1;
    this.progressbarDecrease();
    this.stage = stagechange;
    this.loading =0;
  }

  sendrequestchargestage(stagechange, type:any='', check_type:any='', rtw_country:any=''){
    if(type)
    {
      this.current_stage = type;
    }
    this.loading =1;
    if(stagechange==7){
      this.request_type = check_type;
      if(this.current_stage=='rtw'){
        this.modalService.open('confirm-modal');
      } else if(this.current_stage=='adjusted_rtw'){
        // this.sendrequest('sendrequestcharge');
      }
    } else if(stagechange==4)
    {
      this.rtw_country = rtw_country;
      if(this.rtw_country ==1)
      {
        this.qtype = 'A';
      }else
      {
        this.qtype = 'B';
      }
      this.countrywiseQuestionList = [];
      this.questionlist.forEach(que => {
        // que.selected = false;
        if(this.rtw_country == 1){
          if(que.qtype == 'A'){
            this.countrywiseQuestionList.push(que);
          }
        } else if(this.rtw_country == 0){
          if(que.qtype == 'B'){
            this.countrywiseQuestionList.push(que);
          }
        }
      });
      if(type){
        this.countryid = type;
      }


      if(this.isrighttowork ==2)
      {
        this.stage =10;
      }
      else if(this.isrighttowork ==1)
      {

        // if(type == 'first')
        // {          
          
          this.questionselect =[];
          this.questionselect.push(1);


          this.check.getbillinginformation({ 'serviceid': 4 }).subscribe((remaindata: any) => { 
            this.remainingcheck = remaindata;

            if(remaindata.remiancheck > 0){
                this.modalService.open("confirm-modal");
            }else{
              this.modalService.open("upgrade-plan");
            }
          });          
        // } 
        // else if(type == 'second')
        // {
        //   this.questionselect =null;
        //   this.questionselect.push(1);

        //   this.stage = stagechange;
        // }

        // this.stage =10;
      }
      else
      {
         this.stage = stagechange;
      }
    } 
    else if(stagechange==5){

      this.disableBtn =false;
      
        if(type == 'first')
        {          
          this.check.getbillinginformation({ 'serviceid': 4 }).subscribe((remaindata: any) => { 
            this.remainingcheck = remaindata;

            if(remaindata.remiancheck > 0){
                this.modalService.open("confirm-modal");
            }else{
              this.modalService.open("upgrade-plan");
            }
          });
          
        } else if(type == 'second'){
          this.stage = stagechange;
        } else{
          this.checkQuestionList();

          if(this.isrighttowork ==3)
          {
            this.stage =10;            
          }
          else {


          if(this.update){
            var updaterecord = { 
              employeeid: this.empid, 
              id: this.logid,
              doucmentlist: this.selectedDocList, 
              remaindocumentlist: this.selectedDocList,
              questionlist: this.questionselect  
            };
            this.checkService.updaterighttowork(updaterecord).subscribe((righttoworklog: any) => { 
              // console.log(righttoworklog);
              if(this.isrighttowork ==1){
                this.modalService.close("confirm-modal"); 
                this.stage =8;
              } else{
                this.logid = righttoworklog.data.id;
                localStorage.setItem('logid', righttoworklog.data.id);
                this.modalService.close("confirm-modal");     
                this.stage = stagechange;
              }
            });
          } else{
            var righttowork = { 
              employeeid: this.empid, 
              cemployeeid: this.user_id, 
              isrighttowork: this.isrighttowork, 
              isrighttoworktype: this.isrighttoworktype,
              countryid: this.countryid, 
              qtype: this.qtype, 
              doucmentlist: this.selectedDocList, 
              remaindocumentlist: this.selectedDocList, 
              currentstage: this.stage,
              questionlist: this.questionselect
            };
            this.checkService.insertnewrighttowork(righttowork).subscribe((righttoworklog: any) => { 
              this.update = true;
              // console.log(righttoworklog);
              // this.questionselect = righttoworklog..questionlist.split(',');
              this.country.getcountrylistbyid({'id': righttoworklog.data.countryid}).subscribe((country: any) => {
                this.currentCompany = country;
              });
              if(this.isrighttowork ==1){
                this.modalService.close("confirm-modal"); 
                this.stage =8;
              } else{
                this.logid = righttoworklog.data.id;
                localStorage.setItem('logid', righttoworklog.data.id);
                this.modalService.close("confirm-modal");     
                this.stage = stagechange;
              }
            });
            }
          }
        }
    }
    else if(stagechange==11){
      this.stage = stagechange;
    }
    else if(stagechange==13){
      
      if(type == 'first')
      {          
        this.check.getbillinginformation({ 'serviceid': 4 }).subscribe((remaindata: any) => { 
          this.remainingcheck = remaindata;
          if(remaindata.remiancheck > 0){
              this.modalService.open("confirm-modal");
          }else{
            this.modalService.open("upgrade-plan");
          }
        });        
      } else if(type == 'second'){
        this.stage = stagechange;
      } else{
        this.checkQuestionList();

        if(this.isrighttowork ==3)
        {
          this.stage =stagechange;            
        }
        else {


        if(this.update){
          var updaterecord = { 
            employeeid: this.empid, 
            id: this.logid,
            doucmentlist: this.selectedDocList, 
            remaindocumentlist: null,
            questionlist: null  
          };
          this.checkService.updaterighttowork(updaterecord).subscribe((righttoworklog: any) => { 
            // console.log(righttoworklog);
            if(this.isrighttowork ==1){
              this.modalService.close("confirm-modal"); 
              this.stage =8;
            } else{
              this.logid = righttoworklog.data.id;
              localStorage.setItem('logid', righttoworklog.data.id);
              this.modalService.close("confirm-modal");     
              this.stage = stagechange;
            }
          });
        } else{
          var righttowork = { 
            employeeid: this.empid, 
            cemployeeid: this.user_id, 
            isrighttowork: this.isrighttowork, 
            isrighttoworktype: this.isrighttoworktype,
            countryid: this.countryid, 
            qtype: this.qtype, 
            doucmentlist: this.selectedDocList, 
            remaindocumentlist: null, 
            currentstage: this.stage,
            questionlist: null
          };
          this.checkService.insertnewrighttowork(righttowork).subscribe((righttoworklog: any) => { 
            this.update = true;
            // console.log(righttoworklog);
            // this.questionselect = righttoworklog..questionlist.split(',');
            this.country.getcountrylistbyid({'id': righttoworklog.data.countryid}).subscribe((country: any) => {
              this.currentCompany = country;
            });
            if(this.isrighttowork ==1){
              this.modalService.close("confirm-modal"); 
              this.stage =8;
            } else{
              this.logid = righttoworklog.data.id;
              localStorage.setItem('logid', righttoworklog.data.id);
              this.modalService.close("confirm-modal");     
              this.stage = stagechange;
            }
          });
          }
        }
      }
  }


    else if(stagechange ==2) {
      this.stage = stagechange;
      if(type){



        this.candidatenoteFormGroup = this.formBuilder.group({    
          notes: ['', Validators.required],
          sharecode: [''],
          Checkconductedby: ['', Validators.required],
          Checkconductedbyrole: ['', Validators.required],  
          companyname: ['', Validators.required],
          ninumber: ['', Validators.required],      
          dateofbirth: ['', Validators.required]      
        });



          if(type == 'rtw')
            this.isrighttowork =0;
          else  if(type == 'adjusted_rtw')
            this.isrighttowork =1;
          else
          {

            
              this.candidatenoteFormGroup = this.formBuilder.group({    
                notes: ['', Validators.required],
                sharecode: [''],
                Checkconductedby: ['', Validators.required],
                Checkconductedbyrole: ['', Validators.required],  
                companyname: [''],
                ninumber: [''],      
                dateofbirth: ['']      
              });

              
            this.isrighttowork =2;

          }












      }
    } else if(stagechange ==3) {
      this.stage = stagechange;
      if(type){
          if(type == 'in')
            this.isrighttoworktype =0;
          else
            this.isrighttoworktype =1;
      }
    }    
    else{
       this.allquestionlist();
        this.stage = stagechange;
    }
    this.progressbarIncrease();
    this.loading =0;
  }



  /** Share Code Save */

  onShareCode(value:any, id:any){
    this.submitted = true;
    if (this.sharecodeFormGroup.invalid) {      
      return;      
    } 

    const sd = new Date(value.dateofbirth);
    value.dateofbirth = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());

    var updaterecord = { 
      employeeid: this.empid, 
      id: this.logid,
      sharecode: value.sharecode, 
      companyname: value.companyname,
      dateofbirth: value.dateofbirth,
      ninumber: value.ninumber
    };

    this.checkService.updatenewrighttowork(updaterecord).subscribe((righttoworklog: any) => { 
      this.submitted = false;
      this.stage =12;
    });
  }

  /** Check the list of questions and performing action */

  checkQuestionList()
  {
    // console.log(this.stage);
    this.selectedDocList = [];
    if(this.questionselect.length){
      // console.log(this.questionlist);
      this.questionselect.forEach(queid => {
        // console.log(queid);
        this.countrywiseQuestionList.forEach(element => {
          // console.log(element);
          if(element.id==queid){
            this.selectedDocList.push(element.documentid);
            if(element.documentid2 != 0){
              this.selectedDocList.push(element.documentid2);
            }
          }
        });
      });
      this.selectedDocList = this.selectedDocList.filter((n, i) => this.selectedDocList.indexOf(n) === i);
      // this.progressbar + this.per_stage/
      this.docprogress = this.per_stage/this.selectedDocList.length;
      this.performAction();
    }
  }

  /** Perform required action */

  performAction(){
    // console.log(this.selectedDocList);
    this.progressbar = this.progressbar + this.docprogress;
    this.disableBtn = true;
    this.submitted = false;
    if(this.selectedDocList.length){
      var docid = this.selectedDocList[0];
      // console.log(docid);
      if(Number(docid) == 100){
        this.passportDetail = new Passport();
        this.passportDetail.employeeid = this.empid;
        this.passportDetail.nationalityid = "70";
        this.passportDetail.passportissue = "";
        this.passportDetail.passportexpiry = "";
        this.passportDetail.employeepassdeatilid = "0";
        // this.passportDetail = data;
        var passport = {
          id: "0",
          employeeid: this.empid,
          passportnumber: '',
          placeofissue: '',
          passportissue: '',
          passportexpiry: '',
          nationalityid: "70"
        };
        this.passportFormgroup.reset(passport);
        this.openDocumentForm = 1;
      } else if(Number(docid) == 101){
        this.visaDetail = new Visa();
        this.visaDetail.nationalityid = "70";  
        this.visaDetail.employeeid = this.empid;
        this.visaDetail.employeevisadeatilid = "0";
        this.visaDetail.visastart = "";
        this.visaDetail.visaexpiry = "";
        var visa = {
          id: "0",
          employeeid: this.empid,
          visanumber: '',
          visatype: '',
          visastart: '',
          visaexpiry: '',
          nationalityid: "70"
        };
        this.visaFormgroup.reset(visa);
        this.openDocumentForm = 2;
      } else{
        this.docfrontfilename = "Choose file";
        this.docbackfilename = "Choose file";
        this.docprofilename = "Choose file";
        this.currentdocid = Number(docid);
        this.documenttype.forEach(element => {
          if(element.id==Number(docid)){
            this.docname = element.documentname;
          }
        });
        this.documentDetail = new Idproof();    
        this.documentDetail.employeeid = this.empid;
        this.documentDetail.employeeidproofdeatilid = "0";  
        this.documentDetail.nationalityid = "70";
        this.documentDetail.idtype = docid;
        
        var doc = {
          id: "0",
          employeeid: this.empid,
          idnumber: '',
          idtype: '',
          docexpiry: '',
          nationalityid: "70"
        };
        this.idproofFormgroup.reset(doc);
        this.openDocumentForm = 3;
      }
    } else{
      this.sendrequestchargestage(6);
    }
  }

  questionselection(event, id:any)
  {

    if(this.isrighttowork ==1)
    {
      this.questionselect.push(1);
    }
    else
    {
        if(event.target.checked){
          this.questionselect.push(id);       
        } 
        else
        {    
          this.questionselect.forEach((element, index)=>{
            if(element==id){
              this.questionselect.splice(index, 1);    
            }
          });
        }
    }
  }


  /* final question list */

  allquestionlist(){
    this.reviewDocsByAdmin();
      /* For step question */
      var db = {qtype: 4};
      this.checkService.allquestion(db).subscribe((dataquestion: any) => {
        this.totalquestioncount = dataquestion.length;
        this.questionlist1 = dataquestion;    

        var permissionstring = "[{";
        var perarray = [];
        dataquestion.forEach(function (valuemenu) {
          perarray.push('"' + valuemenu.id + '": 0');
        });

        var perstr = perarray.join();
        permissionstring = permissionstring + perstr + "}]";
        this.questionstring = permissionstring;

      });

  }

  onQuestionSelection(ind,subm){
    var tg; 
    var tg1;   
    this.questionError = false;
    tg = JSON.parse(this.questionstring.toString());
    tg.forEach(function (value4) {      
        value4[ind] = subm;      
    });
    var starry = JSON.stringify(tg);
    this.questionstring = starry;
    var checktrue=true;

    tg = JSON.parse(this.questionstring);

    // console.log(tg);
    tg.forEach(function (value4,index) { 
      if(value4[index] == 0){
        checktrue =false;
      }
    });
    this.showfinish = checktrue;

    // console.log(starry);
  }

  completeprocess(){


    


    var tg = JSON.parse(this.questionstring);
    var checktrue = false;
    tg.forEach(element =>{
      for (const key in element) {
        if(element[key] == 0){
          checktrue = true;
        }
      }      
    });

    if(checktrue){
      this.questionError = true;
      return;
    }
    
    // if(this.isrighttowork ==2)
    //     this.modalService.open('add-notes-to-right-to-work');
    // else
        this.modalService.open('add-notes-to-right-to-work-2');
    
    // var righttowork ={ id: this.logid ,  anslist: this.questionstring.toString()  }
    // this.checkService.updatenewrighttowork(righttowork).subscribe((righttoworklog: any) => {        
    //   this.modalService.open('add-notes-to-right-to-work');
    //   // localStorage.removeItem('logid');
    // });
  }


  updatecandidatenote(value)
  {

    console.log(this.candidatenoteFormGroup);
    this.submitted = true;
    if (this.candidatenoteFormGroup.invalid) {      
      return;
    }
    this.submitted = false;
    if (this.selectdocpdffilename) {
      this.docprofile = this.selectdocpdffilename;
      var filetype = this.docprofile.type.split('/');
      if (this.docprofile.size > 2000 * 1024) {
        this.pdfbackfilesizeerror = true;
        return;
      } else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png" ) && (filetype[filetype.length - 1] != "pdf" ) )  {
        this.pdfbackfileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.docprofile);
      value.pdfreport = imagepath;
    } 


    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data; 
    

    this.signatureImg = this.uploadService.uploadfilenotebase64(base64Data);

   

    var righttowork =
    { 
       id: this.logid, 
       notes: value.notes,
       anslist: this.questionstring.toString(),
       pdfreport: value.pdfreport, 
       sharecode: value.sharecode,
       Checkconductedby: value.Checkconductedby,
       Checkconductedbyrole: value.Checkconductedbyrole,
       Checkconductedbysign: this.signatureImg,
       ninumber: value.ninumber,
       companyname: value.companyname,
       dateofbirth: value.dateofbirth,
    }

    if(value.dateofbirth)
    {
      const sd = new Date(value.dateofbirth);
      righttowork.dateofbirth = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    }

    if(value.ninumber)
    {
      righttowork.ninumber = value.ninumber;
    }

    console.log('Right To work Update',righttowork);

    this.checkService.updatenewrighttowork(righttowork).subscribe((righttoworklog: any) => {  
      
      // if(this.isrighttowork ==2)
      //     this.modalService.close('add-notes-to-right-to-work');
      // else
          this.modalService.close('add-notes-to-right-to-work-2');
      
      
      this.stage=7;
    });

    
  }


  

  requestsendagain(){
    var righttowork ={ id: this.logid  }
    this.checkService.resedapplicantrequest(righttowork).subscribe((righttoworklog: any) => { 
      this.stage =8;
    });
  }

  reviewDocsByAdmin(){
    var data = {logid: this.logid}
    this.checkService.getrighttoworkdetailsbylogid(data).subscribe((righttoworklog: any) => { 
      this.visapageOfItems = righttoworklog.visa;    
      this.passportpageOfItems = righttoworklog.passport;       
      this.docpageOfItems = righttoworklog.document;
    });
  }

  openModal3(id: string, data: any) 
  {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }


onChangePage(id){

}


hidedivform(){
  this.hidediv =false;
}

closeModal(id: string) {
 
  this.modalService.close(id);
}
uploadByAdmin(is,u,i){}
}
