<div class="check_verification" *ngIf="currentStep==0">
  <div class="wizadlineshow">
    <div class="wizard_details">
      <div class="container">
        <div class="main-banner">
          <div class="banner-form">
            <div class="row">
              <div class="col-sm-12">
                <div class="tankushow">
                  <div class="detailstext">
                      <h1 class="tokenfont">Token Expired</h1>
                      <p></p>
                  </div>
                  <img alt="shape" src="../../../../../assets/img/thanks_new1.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="check_verification youphone_popup" *ngIf="currentStep==5">

  <div role="document" class="formgroup ">
    <div class="modal-content">
        <!-- <div class="modal-header">
           
            <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-passportviewpage');">
             
      <span aria-hidden="true">&times;</span>
    </button>
  
        </div> -->
        <div class="modal-body d-flex">
          <div class="contentpass">
            <div class="conwidth100">
            <div class="otp_text">
              <h2>Download ComplygateID App</h2>
              <span>Or Scan the QR code to downlaod the app</span>
            </div>
            <div class="qricons">
              <div class="qrcode">
                  <img src="/assets/img/ios.png" class="apple">
                  <img src="/assets/img/android.png" class="googleplay">
              </div>
          </div>
            <div class="andiosicons">
                <div class="iocns">
                  <a href="https://bit.ly/42O4TAD" target="_blank">
                    <img src="/assets/img/apple_play.png" class="apple">
                  </a>
                  <a href="https://bit.ly/3MnyWYG" target="_blank">
                    <img src="/assets/img/google_paly.png" class="googleplay">
                  </a>                    
                </div>
            </div>
            <!-- <div class="otp_text mt-4">
              <h2>Help Video</h2>             
            </div>
            <div class="help_video">
              <iframe width="1268" height="713" src="https://www.youtube.com/embed/6BnnhVoIcl8" title="What is Complygate | Best HR Software | Immigration Compliance Software |" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div> -->
          </div>
          </div>
          <div class="otp_downlaod_section">
            <div>
            <div class="otp_text mt-0">
              <h2>Pass Code</h2>
              <span>Use this passcode to access the ComplygateID</span>
            </div>
              <div class="otp">
                <input type="text" class="otp_box form-control" value="{{str.charAt(0)}}" readonly>
                <input type="text" class="otp_box form-control" value="{{str.charAt(1)}}" readonly>
                <input type="text" class="otp_box form-control" value="{{str.charAt(2)}}" readonly>
                <input type="text" class="otp_box form-control" value="{{str.charAt(3)}}" readonly>
                <input type="text" class="otp_box form-control" value="{{str.charAt(4)}}" readonly>
                <input type="text" class="otp_box form-control" value="{{str.charAt(5)}}" readonly>
              </div>
            <div class="passbtn text-right mt-2">
                <!-- <button type="submit" class="btn btn-danger">Receive code by SMS</button> -->
            </div>
           
          </div>
          </div>
           
           
        </div>
    </div>
</div>







<!-- 
  <div class="wizadlineshow">
    <div class="wizard_details">
      <div class="container">
        <div class="main-banner">
          <div class="banner-form">
            <div class="row">
              <div class="col-sm-12">
               

                <div class="sub_group">
                  <div class="button-group">

                    <div class="otp_downlaod_section">
                      <div class="otp_text mt-0">
                        <h2>Pass Code</h2>
                        <span>Use this passcode to access the identity app.</span>
                      </div>
                        <div class="otp">
                            <input type="text" class="otp_box form-control" value="{{str.charAt(0)}}" readonly>
                            <input type="text" class="otp_box form-control" value="{{str.charAt(1)}}" readonly>
                            <input type="text" class="otp_box form-control" value="{{str.charAt(2)}}" readonly>
                            <input type="text" class="otp_box form-control" value="{{str.charAt(3)}}" readonly>
                            <input type="text" class="otp_box form-control" value="{{str.charAt(4)}}" readonly>
                            <input type="text" class="otp_box form-control" value="{{str.charAt(5)}}" readonly>
                        </div>
                        <div class="otp_text">
                          <h2>Download APP</h2>
                          <span>Download our App to verify your identity.</span>
                        </div>
                        <div class="andiosicons">
                            <div class="iocns">
                                <img src="/assets/img/apple_play.png" class="apple">
                                <img src="/assets/img/google_paly.png" class="googleplay">
                            </div>
                        </div>
                    </div>


                  </div>
                </div>







              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->





</div>




<app-rtw-visa (performAction)="performAction()" [docs]="docs" *ngIf="currentStep==1"></app-rtw-visa>
<app-rtw-passport (performAction)="performAction()" [docs]="docs" *ngIf="currentStep==2"></app-rtw-passport>
<app-rtw-document (performAction)="performAction()" [docs]="docs" [docid]="docid" *ngIf="currentStep==3"></app-rtw-document>

<div *ngIf="currentStep == 4" class="wizard_details">
  <div class="container">
    <div class="main-banner">
      <div class="overviewsshow check_support mt-4">
        <div class="row">
          <div class="col-sm-12">
            <div class="tankushow">
              <div class="detailstext">
                  <h1>Document upload successful</h1>
                  <p>You do not need to take any further action.</p>
              </div>
              <img alt="shape" src="../../../../../assets/img/thanks_new.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="currentStep == 14" class="wizard_details">
  <div class="container">
    <div class="main-banner">
      <div class="overviewsshow check_support mt-4">
        <div class="row">
          <div class="col-sm-12">
            <div class="tankushow">
              <div class="detailstext">
                  <h1>Something's gone wrong</h1>
                  <p>The link only works on mobile devices.</p>
              </div>
              <img alt="shape" src="../../../../../assets/img/thanks_new1.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="check_verification" *ngIf="currentStep == 9">
  <div class="wizadlineshow">
      <div class="wizard_details">
          <div class="container">
              <div class="main-banner">
                  <div class="banner-form">
                      <div class="row">
                          <div class="col-sm-12">
                              <div class="tankushow">
                                  <div class="detailstext">
                                      <h1 class="tokenfont">Token Expired</h1>
                                      <p></p>
                                  </div>
                                  <img alt="shape" src="../../../../../assets/img/thanks_new1.png">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>



<!-- 
<jw-modal id="pdf-image-viewer-passportviewpage" class="modal youphone_popup" role="dialog">
  <div role="document" class="formgroup ">
      <div class="modal-content">
          <div class="modal-header">
             
              <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-passportviewpage');">
               
        <span aria-hidden="true">&times;</span>
      </button>
    
          </div>
          <div class="modal-body d-flex">
            <div class="contentpass">
              <div class="conwidth100">
              <div class="otp_text">
                <h2>Download ComplygateID App</h2>
                <span>Or Scan the QR code to downlaod the app</span>
              </div>
              <div class="qricons">
                <div class="qrcode">
                    <img src="/assets/img/qr_code.png" class="apple">
                    <img src="/assets/img/qr_code.png" class="googleplay">
                </div>
            </div>
              <div class="andiosicons">
                  <div class="iocns">
                      <img src="/assets/img/apple_play.png" class="apple">
                      <img src="/assets/img/google_paly.png" class="googleplay">
                  </div>
              </div>
              <div class="otp_text mt-4">
                <h2>Help Video</h2>             
              </div>
              <div class="help_video">
                <video controls>
                  <source src="movie.mp4" type="video/mp4">
                  <source src="movie.ogg" type="video/ogg">
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            </div>
            <div class="otp_downlaod_section">
              <div>
              <div class="otp_text mt-0">
                <h2>Pass Code</h2>
                <span>Use this passcode to access the identity app</span>
              </div>
                <div class="otp">
                  <input type="text" class="otp_box form-control" value="{{str.charAt(0)}}" readonly>
                  <input type="text" class="otp_box form-control" value="{{str.charAt(1)}}" readonly>
                  <input type="text" class="otp_box form-control" value="{{str.charAt(2)}}" readonly>
                  <input type="text" class="otp_box form-control" value="{{str.charAt(3)}}" readonly>
                  <input type="text" class="otp_box form-control" value="{{str.charAt(4)}}" readonly>
                  <input type="text" class="otp_box form-control" value="{{str.charAt(5)}}" readonly>
                </div>
              <div class="passbtn text-right mt-2">
                  <button type="submit" class="btn btn-danger">Receive code by SMS</button>
              </div>
             
            </div>
            </div>
             
           
          </div>
      </div>
  </div>
  
</jw-modal> -->



<jw-modal id="pdf-image-viewer-passportviewpage-app-selection" class="modal youphone_popup" role="dialog">
  <div role="document" class="formgroup ">
      <div class="modal-content">
          <div class="modal-header">
              <!-- <i class="fa fa-arrow-left" aria-hidden="true"></i> -->
              <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-passportviewpage');">
               
        <span aria-hidden="true">&times;</span>
      </button>
    
          </div>
          <div class="modal-body">
              <div class="youphone">
                  <h2>Continue on your phone</h2>
                  <span>Here's how to do it:</span>

                  <div class="stepbysetup">


                    
                          <!-- <ul>
                              <li>
                                  <span class="cirss">
                                      <i class="fa fa-mobile"></i>
                                  </span>
                                  <p>Send a secure link to your phone</p>
                              </li>
                              <li>
                                  <span class="cirss">
                                      <i class="fa fa-mobile"></i>
                                  </span>
                                  <p>Open the link and complete the tasks</p>
                              </li>
                              <li>
                                  <span class="cirss">
                                      <i class="fa fa-laptop"></i>
                                  </span>
                                  <p>Check back here to finish the submission</p>
                              </li>
                          </ul> -->
                  </div>
                  <div class="btndjhdjk text-center">
                      <button type="submit" class="btn btn-primary" (click)="openmodel()">Get Secure link</button>
                  </div>
             </div>
          </div>
      </div>
  </div>
</jw-modal>


<jw-modal id="pdf-image-viewer-passportviewpage_qr" class="modal youphone_popup" role="dialog">
  <div role="document" class="formgroup ">
      <div class="modal-content">
          <div class="modal-header">
              <i class="fa fa-arrow-left" aria-hidden="true" (click)="backpopup()"></i>
              <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-passportviewpage_qr');">               
        <span aria-hidden="true">&times;</span>
      </button>
    
          </div>
          <div class="modal-body">
              <div class="youphone">
                  <h2>Get your secure link</h2>
                  <span>Scan the QR code with your phone</span>

                  <div class="qrcode text-center">


                    <qrcode [qrdata]="linkurl" style="display:block ruby;"  ></qrcode>

               
<!--                                               
                            <ngx-qrcode [elementType]="elementType"

                            [errorCorrectionLevel]="correctionLevel"

                            [value]="linkurl"

                            cssClass="bshadow">

                            </ngx-qrcode> -->

                            
                      <!-- <img src="assets/image/qr.png" alt="" class=""> -->
                      <!-- <div class="qrtext"><i class="fa fa-laptop mr-2"></i><span (click)="contwithdevice()" style="cursor: pointer;">Continue on same device</span></div> -->
                  </div>
                  <div class="lineovertext">
                      <p>or choose an alternative method</p>
                  </div>
                  <div class="showotheroption">
                      <span (click)="sendsms()"><i class="fa fa-envelope mr-2"></i>Get link via SMS</span>

                      
<!-- 
                      <span class="signature"><a href="https://twitter.com/nrrrdcore">@nrrrdcore</a> &nbsp;&nbsp;|&nbsp;&nbsp; <a  href="https://getclef.com/about/">getclef.com</a></span> -->


                      

                      <span (click)="copyMessage(linkurl)" class="integration-checklist__copy-button"><i class="fa fa-link mr-2"></i>
                        
                        
                        Copy link</span>
                  </div>
                  
             </div>
          </div>
      </div>
  </div>
</jw-modal>