
<div class="check_verification" *ngIf="token_expired">
  <div class="wizadlineshow">
      <div class="wizard_details">
          <div class="container">
              <div class="main-banner">
                  <div class="banner-form">
                      <div class="row">
                          <div class="col-sm-12">
                              <div class="tankushow">
                                  <div class="detailstext">
                                      <h1 class="tokenfont">Reference complete. </h1>
                                      <p>You do not need to take any further action.</p>
                                  </div>
                                  <img alt="shape" src="../../../assets/img/thanks_new.png">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


<style>
 .wizard_img_box {
    width: 60%;
    margin: 0 auto;
    display: block;
}
.wizard_ul {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wizard_ul li {
    width: 100%;
    margin: 10px;
    margin-bottom: 0px;
}
.dflextoken {
  display: flex;
}
.form-inline label {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 5px;
}
  </style>


<div  class="s12 white docvisapass" *ngIf="!token_expired">
  <div class="check_verification">
    <div class="wizadlineshow">

      <div>
        <div class="col-sm-12 text-center">
          <img src="{{logo}}">
        </div>
      </div>

      <div class="wizard_img_box">
        <ul class="wizard_ul">
          <li *ngFor="let step of steps; let last = last" class="step" [ngClass]="{'last' : last }">
            <span class="" [class.active]="step.active" [class.valid]="step.valid">{{step.name}}</span>
          </li>
        </ul>

        <div class="col-md-3" *ngIf="referencerequestinfo && referencecompanyinfo">
          <button *ngIf="steps[steps.length-1].active && addconfirmbutton == 'Add'" class="btn com_btn btn-success small_btn pull-right"
            (click)="confirm()">{{addconfirmbutton}} &nbsp;<i class="fa fa-check arrow"></i></button>
        </div>

      </div>


      <div id="wizard-1" *ngFor="let step of steps" [hidden]="!step.active"    class="wizard_details">
        <div class="container">
            <div class="main-banner">
                <div class="overviewsshow check_support mt-4">
                    <div class="row"  *ngIf="step.name != 'Done'">
                      <div class="col-sm-12">
                          <div class="header">
                              <h1 class="headprofile mt-0 mb-0">Add Reference Details</h1>
                          </div>
                          <hr/>
                      </div>
                    </div>
                    <ng-container *ngIf="step.name=='Information'">
                      <div *ngIf="referencerequestinfo && referencecompanyinfo && refereedetailinfo" class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <h4>Hi {{refereedetailinfo.refereename}},</h4>
                          <p>{{referencecompanyinfo.companyname}} has requested an employment reference via Complygate for {{referencerequestinfo.candidatename}}, who claims to have worked at {{refereedetailinfo.refereecompanyname}} 
                            
                            <ng-container *ngIf="refereedetailinfo.jobenddate">

                            
                            between {{refereedetailinfo.jobstartdate}} and  {{refereedetailinfo.jobenddate}}
                          </ng-container>
                          <ng-container *ngIf="!refereedetailinfo.jobenddate">
                            from  {{refereedetailinfo.jobstartdate}} to present
                            </ng-container>

                          </p>

                          <p class="font14">It would be appreciated if you could please spend a few minutes completing this reference now.</p>

                          <p class="font14">  <input type="checkbox" [(ngModel)]="btndisable" (change)="consert($event)">  I agree that the information I provide must be accurate and may be disclosed to a third party such as the person to whom this reference applies. I accept the
                            <a href="https://www.complygate.co.uk/assets/pdf/Conditions_of_Use.pdf" target="_blank"  style="color:red;cursor: pointer;" >Terms and Conditions.</a>
                            Please read our  <a href="https://www.complygate.co.uk/assets/pdf/client_privacy_policy2.pdf" target="_blank" style="color:red;cursor: pointer;" >Privacy Policy.</a>
                          </p>
                        </div>
                      </div>

                      <div   class="row" style="display: none;">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div class="form-group mt-3 mt-3 dflextoken">
                            <div class="custom-file">
                              <input type="file" class="custom-file-input" id="customFile" name="filename" (change)="fileChange(input)" #input>
                              <label class="custom-file-label" for="customFile">{{file}}</label>
                            </div>
                            <span class="input-group-btn">
                              <button class="btn btn-danger ml-2" *ngIf="file"><i class="fa fa-times delete-file" (click)="removeFile()" ></i></button>
                              </span>
                              <span class="input-group-btn">
                                <button class="btn btn-primary ml-2" (click)="uploadFile($event)" type="button"><i
                                    class="fa fa-upload"></i></button>
                              </span>
                            <!-- <div class="input-group file-upload">
                              <input type="file"  class="file-upload-btn" />

                            </div> -->
                          </div>
                        </div>
                        <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <div class="form-group mt-3 mt-3">
                            <div class="input-group file-upload">
                              <input type="text" class="form-control" placeholder="Choose a file..." value="{{file}}">

                            </div>
                          </div>
                        </div> -->
                      </div>
                      <div class="row" >
                        <div class="col-sm-12 text-center">
                          <button *ngIf="!steps[steps.length-1].active" [disabled]="!btndisable" class="btn com_btn btn-success small_btn mt-3" (click)="next('Information')">Next Step</button>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="step.name=='About You'">
                      <form  [formGroup]="refereesFormGroup"  >

                        <div class="text-center"> <label   for="exampleSelect1"> Please complete the following
                            details about <strong>yourself</strong></label></div>
                        <div class="row bottom-30">
                          <div class="col-md-3"></div>

                          <div class="row col-lg-12 col-12">
                            <div class="col-12">

                              <div class="form-group mt-3 mt-4">
                                <label for="exampleSelect1">What was your job title during their employment?<span
                                    class="text-danger">*</span></label>
                                <input formControlName="refereedesignationcheck" type="text"
                                  class="form-control" id="input-5">
                                <div *ngIf="submit && refereesFormGroup.get('refereedesignationcheck').invalid">
                                  <span class="help-block text-danger">
                                    
                                    This is <strong>required</strong>
                                  </span>
                                </div>
                              </div>

                              <div class="form-group mt-3 mt-4">
                                <label for="exampleSelect1">Please provide your contact number so we can confirm the
                                  legitimacy of this reference:
                                  <span class="text-danger">*</span></label>
                                <input formControlName="refereephonecheck" type="text" class="form-control"
                                  id="input-5">
                                <div *ngIf="submit && refereesFormGroup.get('refereephonecheck').invalid">
                                  <span class="help-block text-danger">
                                    This is <strong>required</strong>
                                  </span>
                                </div>
                              </div>

                              <div class="form-group mt-3 mt-4" *ngIf="referencerequestinfo">
                                <label for="exampleSelect1">Describe your relationship with {{referencerequestinfo.candidatename}}.
                                  I was/am their:<span class="text-danger">*</span></label>
                                <select class="form-control" formControlName="candidatedesignationcheck" id="exampleSelect1">
                                  <option value="">Select</option>
                                  <option value="Worked together on the same team">Worked together on the same team</option>
                                  <option value="Managed directly">Managed directly</option>
                                  <option value="Reported directly">Reported directly</option>
                                  <option value="HR Manager">HR Manager</option>
                                  <option value="None of the above">None of the above</option>

                                  <!-- <option *ngFor="let designation of getdesignations;let i = index">{{designation.designationname}}
                                  </option> -->

                                </select>
                                <div *ngIf="submit && refereesFormGroup.get('candidatedesignationcheck').invalid">
                                  <span class="help-block text-danger">
                                    This is <strong>required</strong>
                                  </span>
                                </div>
                              </div>

                              <!-- <button type="button" (click)="addItem()">Add Item</button> -->
                            </div>
                          </div>
                        </div>
                        <div class="row" >
                          <div class="col-sm-12">
                        <button *ngIf="!steps[steps.length-1].active" class="btn com_btn btn-success small_btn" (click)="next('About You')">Next Step</button>
                </div>
                </div>
                      </form>
                      <!-- nextstep  -->


                    </ng-container>





                    <ng-container *ngIf="step.name=='About Reference'">
                      <form *ngIf="step.name=='About Reference'" [formGroup]="refereesresponseFormGroup" class="text-left">

                        <div *ngIf="referencerequestinfo && referencecompanyinfo && refereedetailinfo">
                          <div class="text-center mb-3"> <label  for="exampleSelect1"> Please confirm the details and
                              fill few questions about {{referencerequestinfo.candidatename}}</label></div>
                        </div>
                        <div class="row bottom-30">
                          <div class="col-md-3"></div>
                          <div class="col-lg-12">
                            <div>
                              <div class="row">
                                <div class="col-sm-6">

                                  <div class="form-group  mt-3">
                                    <label for="exampleSelect1">Your Name:<span class="text-danger">*</span></label>
                                    <input formControlName="refereenamecheck" type="text" placeholder="Your Name"
                                      class="form-control" id="input-5">
                                    <div *ngIf="submit2 && refereesresponseFormGroup.get('refereenamecheck').invalid"  class="invalid-feedback">
                                      <span class="help-block text-danger">
                                       Please enter your name
                                      </span>
                                    </div>
                                  </div>

                                  </div><div class="col-sm-6">

                                  <div class="form-group  mt-3">
                                    <label for="exampleSelect1">Company Name:<span class="text-danger">*</span></label>
                                    <input formControlName="companynamecheck" type="text" placeholder="Company's Name"
                                      class="form-control" id="input-5">
                                    <div *ngIf="submit2 && refereesresponseFormGroup.get('companynamecheck').invalid" class="invalid-feedback">
                                      <span class="help-block text-danger">
                                        Please enter your company name
                                      </span>
                                    </div>
                                  </div>

                                </div><div class="col-sm-12">

                                  <div class="form-group  mt-3">
                                    <label for="exampleSelect1">Their Job role during employment:<span class="text-danger">*</span></label>

                                        <input formControlName="candidatedesignationcheck" type="text" placeholder="Job role"
                                        class="form-control" id="input-5">

                                    <!-- <select class="form-control" formControlName="candidatedesignationcheck" id="exampleSelect1">
                                      <option *ngFor="let designation of getdesignations;let i = index">
                                        {{designation.designationname}}
                                      </option>
                                    </select> -->
                                    <div *ngIf="submit2 && refereesresponseFormGroup.get('candidatedesignationcheck').invalid" class="invalid-feedback">
                                      <span class="help-block text-danger">
                                       Please enter job role during employment
                                      </span>
                                    </div>
                                  </div>

                                </div><div class="col-sm-6">
                                  <div class="form-group mt-3">
                                    <label for="input-12">Their Employment Start Date:<span class="text-danger">*</span></label><br>
                                    <input type="date" formControlName="jobstartdatecheck" class="date form-control" id="input-12">
                                    <div *ngIf="submit2 && refereesresponseFormGroup.get('jobstartdatecheck').invalid" class="invalid-feedback">
                                      <span class="help-block text-danger">
                                        Please enter employment start date
                                      </span>
                                    </div>
                                  </div>

                                </div><div class="col-sm-6">
                                  <div class="form-group mt-3">
                                    <div class="row form-inline">
                                      <div class="form-group col-md-12 col-sm-12">

                                        <div class="d-flex width100 justify-content-between align-items-center">

                                          <div class="width100">
                                            <label for="input-12">Their Employment End Date:</label>
                                            <input type="date" class="date form-control width100" style="width:100%" formControlName="jobenddatecheck" id="input-12">
                                            <!-- <div *ngIf="submit && refereesresponseFormGroup.get('jobenddatecheck').invalid" class="invalid-feedback">
                                              <span class="help-block text-danger">
                                               Please enter employment end date
                                              </span>
                                            </div> -->
                                          </div>
                                        </div>


                                      </div>

                                    </div>
                                    <div class="help-block text-danger" *ngIf="refereesresponseFormGroup.errors">
                                      {{ refereesresponseFormGroup.errors?.dates }}</div>
                                  </div>

                                </div>
                                <div class="col-sm-12">
                                  <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                      <input type="checkbox" formControlName="currentjob" class="custom-control-input"
                                        id="checkboxRemember">
                                      <label class="custom-control-label" for="checkboxRemember">Current</label>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12">
                                  <div class="row">



                                  <div formArrayName="refereesresponsedetails"
                                    *ngFor="let refereesresponsedetail of refereesresponseFormGroup.get('refereesresponsedetails').controls; let i = index"
                                    class="col-sm-12">
                                    <div [formGroupName]="i">



                                      <div *ngIf="refereesresponsedetail.controls.questiontype.value == 'short'"
                                        class="form-group mt-3">

                                        <label for="input-12">{{refereesresponsedetail.controls.question.value}}</label>

                                        <input formControlName="response" type="text" placeholder="Your response"
                                          class="form-control" id="input-5">
                                        <div *ngIf="submit2 && refereesresponsedetail.get('response').invalid">
                                          <span class="help-block text-danger">
                                            This is <strong>required</strong>
                                          </span>
                                        </div>
                                      </div>

                                      <div *ngIf="refereesresponsedetail.controls.questiontype.value == 'long'" class="form-group mt-3">

                                        <label for="input-12">{{refereesresponsedetail.controls.question.value}}</label>

                                        <textarea formControlName="response" type="text" placeholder="Your response"
                                          class="form-control" id="input-5"></textarea>
                                        <div *ngIf="submit2 && refereesresponsedetail.get('response').invalid">
                                          <span class="help-block text-danger">
                                            This is <strong>required</strong>
                                          </span>
                                        </div>
                                      </div>


                                      <div *ngIf="refereesresponsedetail.controls.questiontype.value == 'number'"
                                        class="form-group mt-3">

                                        <label for="input-12">{{refereesresponsedetail.controls.question.value}}</label>

                                        <input formControlName="response" type="number" placeholder="Your response"
                                          class="form-control" id="input-5">
                                        <div *ngIf="submit2 && refereesresponsedetail.get('response').invalid">
                                          <span class="help-block text-danger">
                                            This is <strong>required</strong>
                                          </span>
                                        </div>
                                      </div>

                                      <div *ngIf="refereesresponsedetail.controls.questiontype.value == 'dropdown'"
                                        class="form-group mt-3">

                                        <label for="input-12">{{refereesresponsedetail.controls.question.value}}</label>

                                        <select class="form-control" formControlName="response" id="exampleSelect12">
                                          <option
                                            *ngFor="let option of stringtoarrar(refereesresponsedetail.controls.questionoptions.value,','); let i = index">
                                            {{option}}
                                          </option>
                                        </select>
                                        <div *ngIf="submit2 && refereesresponsedetail.get('response').invalid">
                                          <span class="help-block text-danger">
                                            This is <strong>required</strong>
                                          </span>
                                        </div>
                                      </div>




                                    </div>
                                  </div>

                                </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </form>


                    </ng-container>


                    <button *ngIf="steps[steps.length-1].active && addconfirmbutton == 'Submit'"
                        class="btn com_btn btn-success small_btn" data-toggle="modal" data-target="#noheader-modal"
                        (click)="confirm()">{{addconfirmbutton}}</button>

            </div>
        </div>
    </div>
  </div>
</div>
</div>
</div>
<ng-container *ngIf="formsubmitted">
  <!-- <div class="personal_form">
    <div class="modal-body">
      <div class="container">
        <div class="main-banner">
          <div class=" ">
            <div class="row">
              <div class="col-sm-12">
                <div class="tankushow">
                  <div class="detailstext">
                      <h1>Thank you for completing the referee</h1>
                  </div>
                  <img alt="shape" src="../../../assets/img/thanks_new.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> -->
  <div class="check_verification">
    <div class="wizadlineshow">
        <div class="wizard_details">
            <div class="container">
                <div class="main-banner">
                    <div class="banner-form">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="tankushow">
                            <div class="detailstext">
                                <h1 class="tokenfont">Thank you for completing the details.</h1>
                            </div>
                            <img alt="shape" src="../../../assets/img/thanks_new.png">
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</ng-container>

