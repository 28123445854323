
import { Component, OnInit, NgModule, ViewEncapsulation, HostListener } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { EmployeeService } from '../../services/employee.service';
import { CustomerService } from '../../services/customer.service';
import { candidatenotes } from '../../models/jobs/candidate.model';
import { ModalService } from '../../_services/modal.service';
import { Nationality } from '../../models/overview/nationality.model';
import { Permanentaddress } from '../../models/overview/permanentaddress.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Profile,Termination } from '../../models/overview/profile.model';
import { AlertService } from '../../services/alert.service';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { CountryService } from '../../services/country.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadFileService } from './../../services/upload-file.service';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { MatOptionSelectionChange } from '@angular/material';
import { CommonService } from '../../services/common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CandidateService } from '../../services/candidate.service';
import { TerminationreasonService } from '../../services/terminationreason.service';

declare let $: any;
@NgModule({
  imports: [
    InternationalPhoneNumberModule,
  ],
})

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade',
      [
        state('void', style({ opacity: 0})),
        transition(':enter', [animate(300)]),
        transition(':leave', [animate(500)])
      ]
    )
  ],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class EmployeeComponent implements OnInit {

  public navbarOpen = false;
  public clicked = false;
  _el: any;
  location: any;
  layoutClass: string;
  sectionHeights = [];
  tab: any =1;
  empltype: any = false;
  terminationDetail: any;
  overviewData: any;
  buttoninsert:any;
  countrylist: any;
  statelist: any[];
  OverForm: FormGroup;
  permanetForm: FormGroup;
  mobilecheck: any;
  phonecheck: any;
  options = [];
  mindob = new Date();
  maxdob = new Date();
  selectedFiles: FileList;
  prfileImage: any;
  submitted: boolean;
  Userid: string;
  addressdata: any;
  addresslist: any;
  paddress: any;
  employement: any;
  visalist: any;
  empid: any;
  disableBtn: any;
  defaultVal: any = "";
  file: any;
  filesizeerror: any;
  fileformaterror: any;
  candidatenotes: any;
  candidatenotesdetails: any;
  terminationForm: FormGroup;
  candidatenoteFormGroup: FormGroup;
  overview1: any ;
  current1: any;
  idproof1:any;
  department1: any;
  overemployeement1: any;
  overaddress1 : any;
  overcompen1 : any;
  overcontactinfo1: any;
  overcos1 : any;
  overvisa1: any;
  overpassport1: any;
  otherDetail: any;
  parentCategoryList: any;
  subCategoryList: any = [];
  overidproof1:any;
  terminationHistory: any;

  constructor(
    private formBuilder: FormBuilder, 
    private activatedRoute: ActivatedRoute, 
    private uploadService: UploadFileService, 
    private data: CustomerService, 
    private country: CountryService,     
    private employeeService: EmployeeService, 
    private modalService: ModalService, 
    private router: Router,
    private candidate: CandidateService,
    private commonService: CommonService, 
    private tReasonService: TerminationreasonService, 
    private alerts: AlertService
    ) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    this.candidatenoteFormGroup = this.formBuilder.group({
      candidateid: ['', Validators.required],
      employeeid: ['', Validators.required],
      notes: ['', Validators.required]
    });
    this.candidatenotesdetails = new candidatenotes();
    this.terminationDetail = new Termination();
    this.empid = this.activatedRoute.snapshot.paramMap.get("id");
    this.Userid = localStorage.getItem('userid');
    this.mindob.setDate(this.mindob.getDate() - 23741);
    this.maxdob.setDate(this.maxdob.getDate() - 574);
    this.OverForm = this.formBuilder.group({
      id: ['', Validators.required],
      //  employeeid: ['', Validators.required],
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      gender: ['', Validators.required],
      hiredate: ['', Validators.required],
      employeeid: ['', Validators.required],
      placeofbirth: ['', Validators.required],
      maritalstatus: ['', Validators.required],
      ninumber: ['', Validators.required],
      emprole: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      residenceid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      countryofbirthid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      dateofbirth: ['', Validators.required],
    });
    
    this.terminationForm = this.formBuilder.group({
      employeeid: ['', Validators.required],
      terminationdate: ['', Validators.required],
      terminationreason: ['', Validators.required],
      oktorehire: ['', Validators.required],
      regrettermination: ['', Validators.required],
      termination_category: ['', Validators.required],
      termination_subcategory: ['', Validators.required]
    });

    this.permanetForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      userid: [''],
      addressid1: ['', Validators.required],
      addressid2: [''],
      cityname: ['', Validators.compose([Validators.required])],
      mobilenumber: [''],
      pincode: ['', Validators.required],
      statename: ['', Validators.compose([Validators.required, Validators.min(1)])],
      phonenumber: [''],
      countryid: ['', Validators.compose([Validators.required, Validators.min(1)])]
    });

    this.overviewData = new Profile();
    this.paddress = new Permanentaddress();
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.tReasonService.getParentTerminationReasonList({parentid: 0, status: 1}).subscribe((parentterminationreasons: any) => {
      this.parentCategoryList = parentterminationreasons;
    });

    this.data.getOverviewbyadmin({'id': this.empid}).subscribe((data: any) => {
      this.empltype =  data.emptype;
      this.overviewData = data;
    });

    this.data.getPaddressbyadmin({'id': this.empid}).subscribe((data: any) => {
      if(data.addressid1 == null){
        this.buttoninsert = "Submit";
      } else {
        this.buttoninsert = "Update";
      }
      this.paddress = data;
    });
    
    // this.Userid = localStorage.getItem('userid');    
    // const data3 = { 'userid': this.Userid };
    // this.data.getOtherInfo(data3).subscribe((data: any) => {
    //   this.employement = data;
    // });

    this.employeeService.employeeTerminationHistory({employeeid: this.empid, userid: this.Userid}).subscribe((termHistory: any) => {
      this.terminationHistory = termHistory;
    })

    const data5 = { 'userid': this.Userid, 'employeeid': this.empid };
    this.data.getOtherInfobyadmin(data5).subscribe((data: any) => {               
      this.employement = data;
    });

    const data = { 'userid': this.empid };    
    this.data.getOtherInfo(data).subscribe((data: any) => {
      this.otherDetail = data;
    });

    this.data.getEmploymentbyadmin({'id': this.empid}).subscribe((data: any) => {
      this.employement = data;
    });

    this.data.getVisabyadmin({'id': this.empid}).subscribe((data: any) => {
      this.visalist = data;      
    });

  }

  get g() { return this.terminationForm.controls; }

  openModelTerminateactive(empid: string,terminateid: any, id: string)
  {
    this.terminationDetail = new Termination();
    this.submitted = false;
    var dd = {termid: terminateid}
    this.employeeService.employeeViewTermination(dd).subscribe((data: any) => {
      this.tReasonService.getChildTerminationReasonList({parentid: 1, status: 1, searchparentid: data.termination_category}).subscribe((childterminationreasons: any) => {
        this.subCategoryList = childterminationreasons;
        this.terminationDetail.employeeid = empid;
        if(data.oktorehire == true)
          this.terminationDetail.oktorehire = "1";
        else
          this.terminationDetail.oktorehire = "0";

        if(data.oktorehire == true)
          this.terminationDetail.regrettermination = "1";
        else
        this.terminationDetail.regrettermination = "0";
        this.terminationDetail.termination_category = data.termination_category;
        this.terminationDetail.termination_subcategory = data.termination_subcategory;
        this.terminationDetail.terminationdate = data.terminationdate;
        this.terminationDetail.terminationreason = data.terminationreason;
        this.modalService.open(id);
      });
    });
  }

  openModelTerminate(empid: string, id: string)
  {
    this.subCategoryList = [];
    this.terminationDetail = new Termination();
    this.submitted = false;
    this.terminationDetail.employeeid = empid;
    this.terminationDetail.oktorehire = "";
    this.terminationDetail.regrettermination = "";
    this.terminationDetail.termination_category = "";
    this.terminationDetail.termination_subcategory = "";
    this.modalService.open(id);
  }

  removeTermination()
  {
    this.employeeService.employeeRemoveTermination({ 'userid': this.Userid, 'employeeid': this.empid }).subscribe((data: any) => {
      if(data.success){
        this.data.getOverviewbyadmin({'id': this.empid}).subscribe((data: any) => {
          this.overviewData = data;
          this.alerts.success('Employee has removed from termination.',true); 
        });
        
      }
    })
  }

  @HostListener('window:click', ['$event'])
  clickedOutside(e): void {
    if(this.clicked){
      this._el.nativeElement.querySelector('dropdown-menu').classList.toogle('show')
    }
  }

  onmousetopbottom(id,height){    
    $('html, body').animate({
      scrollTop: $('#'+id).offset().top
    }, 1000, function(){
    window.location.hash = '#'+id;
    });
  }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(e){
    
    
  //   // let element = document.getElementById('tabs');
  //     // console.log(window.pageYOffset +' rahul kumar tanwar');

  //     let overview = document.getElementById('overview');
  //     //let current = document.getElementById('current');
  //     let department = document.getElementById('department');
  //     let overemployeement = document.getElementById('overemployeement');
  //     let overaddress = document.getElementById('overaddress');
  //     let overcompen = document.getElementById('overcompen');     
  //     let overcontactinfo = document.getElementById('overcontactinfo');
  //     let overcos = document.getElementById('overcos');      
  //     let overvisa = document.getElementById('overvisa');
  //     let overpassport = document.getElementById('overpassport');
  //     let overidproof = document.getElementById('idproof');



  //     this.overview1 = overview.offsetHeight;
  //   //  this.current1 = current.offsetHeight + this.overview1;
  //     this.department1 = department.offsetHeight + this.overview1;
  //     this.overemployeement1 = overemployeement.offsetHeight + this.department1;
  //     this.overaddress1 = overaddress.offsetHeight + this.overemployeement1;
  //     this.overcompen1 = overcompen.offsetHeight + this.overaddress1;
  //     this.overcontactinfo1 = overcontactinfo.offsetHeight + this.overcompen1;
  //     this.overcos1 = overcos.offsetHeight + this.overcontactinfo1;
  //     this.overvisa1 = overvisa.offsetHeight + this.overcos1;
  //     this.overpassport1 = overpassport.offsetHeight + (this.overvisa1-200);
  //     this.overidproof1 = overidproof.offsetHeight + this.overpassport1;

  //     if(window.pageYOffset < this.overview1)
  //     {
  //       this.tab =1;
  //     }
  //     // else if(window.pageYOffset < this.current1)
  //     // {
  //     //   this.tab =9;
  //     // }
  //     else if(window.pageYOffset < this.department1)
  //     {
  //       this.tab =10;
  //     }      
  //     else if(window.pageYOffset < this.overemployeement1)
  //     {
  //       this.tab =2;
  //     } else if(window.pageYOffset < this.overaddress1)
  //     {
  //       this.tab =3;
  //     } else if(window.pageYOffset < this.overcompen1)
  //     {
  //       this.tab =4;
  //     }  else if(window.pageYOffset < this.overcontactinfo1)
  //     {
  //       this.tab =5;
  //     }  else if(window.pageYOffset < this.overcos1)
  //     {
  //       this.tab =6;
  //     }  else if(window.pageYOffset < this.overvisa1)
  //     {
  //       this.tab =7;
  //     }  else if(window.pageYOffset < this.overpassport1)
  //     {
  //       this.tab =8;
  //     } else if(window.pageYOffset < (this.overidproof1-200))
  //     {
  //       this.tab =9;
  //     } 
      

  //   if(window.pageYOffset > 200){
  //     let element = document.getElementById('employeetopmenu');
  //     // console.log(element+' rahul kumar tanwar');
  //     element.classList.add('is-sticky');
  //   } else {
  //     let element = document.getElementById('employeetopmenu');
  //     element.classList.remove('is-sticky');
  //   }


  // }
  

  onSelectCountry(country_id: number) {
    const data = { 'id': country_id };
    this.country.getstatelist(data).subscribe((state: any) => {
      return this.statelist = state
    });
  }

  onSearchChange(searchValue) {
    if (searchValue.length > 4) {
      var newArray = [];
      this.commonService.getAddress(searchValue).subscribe((data: any) => {
        var newdata: any;
        newdata = data;
        newdata.addresses.forEach(function (value) {
          newArray.push(value + "," + searchValue);
        });
        this.options = newArray;
      });
    }
    else {
      this.options = [];
    }
  }

  displayFn(value: any) {
    if (value != null) {
      var res = value.split(",");
      if (res.length > 6) {
        return res[7];
      } else {
        return value;
      }
    }
  }

  updatecode(evt: MatOptionSelectionChange, response) {
    res = [];
    if (evt.source.selected) {
      var res = response.split(",");
      this.paddress.addressid1 = res[0];
      this.paddress.addressid2 = res[1];
      this.paddress.cityname = res[5];
      this.paddress.pincode = res[7];
      this.paddress.statename = res[6];
    }
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  openModal(id: string, data: any) {    
    this.submitted = false;
    this.disableBtn = true;
    this.overviewData = new Profile();
    if(data.nationalityid == null){
      data.nationalityid = "";
    }
    if(data.residenceid == null){
      data.residenceid = "";
    }
    if(data.countryofbirthid == null){
      data.countryofbirthid = "";
    }
    this.overviewData = data;
    this.modalService.open(id);
  }

  openModal2(id: string, data: any) {
    this.submitted = false;
    this.disableBtn = true;
    this.paddress = new Permanentaddress();
    this.buttoninsert = "Update";
    this.paddress = data;
    // this.paddress.employeeid = this.empid;
    if((this.paddress.countryid == '') || (this.paddress.countryid == null)){
      this.paddress.countryid = '';
    }
    this.mobilecheck = "";
    this.phonecheck = "";
    this.paddress.userid = this.Userid;
    this.modalService.open(id);
  }

  selectFile(event) {
    var files = event.target.files;
    var selectedFiles = files[0];
    this.selectedFiles = selectedFiles;
    this.fileformaterror = false;
    this.filesizeerror = false;
    this.file = this.selectedFiles;
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;
      return;
    } else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") && (filetype[filetype.length - 1].toLowerCase() != "gif") && (filetype[filetype.length - 1].toLowerCase() != "tif") && (filetype[filetype.length - 1].toLowerCase() != "pdf") && (filetype[filetype.length - 1].toLowerCase() != "doc") && (filetype[filetype.length - 1].toLowerCase() != "docx") && (filetype[filetype.length - 1].toLowerCase() != "msword")) {
      this.fileformaterror = true;
      return;
    }
    const file = this.selectedFiles;
    var imagepath = this.uploadService.uploadfile(file);
    var forupdate = { 'imagename': imagepath, 'id': this.empid };
    this.data.updateemployeeimage(forupdate).subscribe((data: any) => 
    {
      setTimeout(() => this.overviewData.imagename = imagepath, 2000);
    });
    this.alerts.success('Profile picture updated successfully.',true); 
  }

  get f() { return this.OverForm.controls; }

  onSubmit(value3: any, id: any) {
    this.submitted = true;
    if (this.OverForm.invalid) {
      return;
    }
    this.disableBtn = false;


    
    const sd = new Date(value3.dateofbirth);
    value3.dateofbirth = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    const ed = new Date(value3.hiredate);
    value3.hiredate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());




    this.data.updateemployeedetalsbyadmin(value3).subscribe((data: any) => {
      this.data.getOverviewbyadmin({'id': this.empid}).subscribe((data: any) => {
        this.overviewData = data;
      });
    });
    this.alerts.success('Profile updated successfully.',true); 
    this.modalService.close(id);
  }

  get h() { return this.permanetForm.controls; }

  onSubmit2(value3: any, id: any) {
    this.submitted = true;
    //console.log(this.permanetForm);
    if (this.permanetForm.invalid) {
      return;
    }
    var pinc = value3.pincode.split(',');
    value3.pincode = pinc[pinc.length - 1];
    value3.userid = this.Userid;
    this.data.permanentadddetalsbyadmin(value3).subscribe((data: any) => {
      this.data.getPaddressbyadmin({'id': this.empid}).subscribe((data: any) => {
        this.paddress = data;
      });
    });
    this.alerts.success('Permanent Address updated successfully.', true);
    this.modalService.close(id);
  }

  closeModalOverview(id: string) {
    this.overviewData = new Profile();
    // this.paddress = new Permanentaddress();
    // this.country.getcountrylist().subscribe((country: any) => {
    //   this.countrylist = country;
    // });

    this.data.getOverviewbyadmin({'id': this.empid}).subscribe((data: any) => {
      if(data.addressid1 == null)
      {this.buttoninsert = "Submit";}
      else
      {this.buttoninsert = "Update";}
      this.overviewData = data;
    });

    // this.data.getPaddress().subscribe((data: any) => {
    //   this.paddress = data;
    // });
    this.modalService.close(id);
  }

  
  closeModalTerminate(id: string) {
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.modalService.close(id);
  }

  closeModalPaddress(id: string) {
    // this.paddress = new Permanentaddress();
    // this.country.getcountrylist().subscribe((country: any) => {
    //   this.countrylist = country;
    // });

    // this.data.getOverviewbyadmin({'id': this.empid}).subscribe((data: any) => {
    //   if(data.addressid1 == null)
    //   {this.buttoninsert = "Submit";}
    //   else
    //   {this.buttoninsert = "Update";}
    //   this.overviewData = data;
    // });

    // this.data.getPaddressbyadmin({'id': this.empid}).subscribe((data: any) => {
    //   this.paddress = data;
    // });
    this.modalService.close(id);
  }

  
  onSubmitTermination(terminationform: any, modelid: any) {
    this.submitted = true;
    if (this.terminationForm.invalid) {
      return;
    }
  
    
    const sd = new Date(terminationform.terminationdate);
    terminationform.terminationdate = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    
    
    this.employeeService.employeeSubmitTermination(terminationform).subscribe((data: any) => {
      this.data.getOverviewbyadmin({'id': this.empid}).subscribe((data: any) => {
        this.employeeService.employeeTerminationHistory({employeeid: this.empid, userid: this.Userid}).subscribe((termHistory: any) => {
          this.terminationHistory = termHistory;
        });
        this.empltype =  data.emptype;
        this.overviewData = data;
        this.alerts.success('Employee has been terminated successfully');
        this.modalService.close(modelid);
      });
    });
  }

  get j() { return this.candidatenoteFormGroup.controls; }

  openNotesModel(id)
  {
    var db = { 'candidateid': this.empid }
    this.candidate.getcandidatenotelist(db).subscribe((candidatenote: any) => {
      this.candidatenotes = candidatenote;
    });   
    this.candidatenotesdetails.notes = '';
    this.candidatenotesdetails.employeeid = this.Userid;
    this.candidatenotesdetails.candidateid = this.empid;
    this.modalService.open(id);
  }

  updatecandidatenote(values: any){
    this.submitted = true;
    if (this.candidatenoteFormGroup.invalid) {
      return;
    }
    this.candidate.createcandidatenote(values).subscribe((candidatenote: any) => {
      var db = { 'candidateid': this.empid }
      this.candidate.getcandidatenotelist(db).subscribe((candidatenotem: any) => {
        this.candidatenotes = candidatenotem;    
      });
    });
  }

  changeSubcategory(reasonCategory: any)
  {
    this.tReasonService.getChildTerminationReasonList({parentid: 1, status: 1, searchparentid: reasonCategory}).subscribe((childterminationreasons: any) => {
      this.subCategoryList = childterminationreasons;
    });
  }

  onChangeStatus(evt: any, emp: any)
  {

    console.log('Employee ID details', emp);

    console.log('Employee ID details', this.empid);

    var status;
    if(evt.target.checked)
    {
      status = '1';
    }
    else if(!evt.target.checked)
    {
      status = '0';
    }
    this.employeeService.employeeUpdateChangeStatus({'userid': this.Userid, 'id':this.empid, 'estatus': status}).subscribe((data: any) => {
      if(status =='1')
      {
        this.alerts.success('Employee has been activated successfully');
      }else if(status == '0')
      {
        this.alerts.error('Employee has been deactivated successfully');
      }
    });
  }

}
