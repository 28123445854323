"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var FcasearchService = /** @class */ (function () {
    function FcasearchService(commonService) {
        this.commonService = commonService;
    }
    FcasearchService.prototype.getFCAFirmcommonSearch = function (data) {
        return this.commonService.formpost('/fcs/fcsapicommansearch', data);
    };
    FcasearchService.prototype.getFCAFirmDetails = function (data) {
        return this.commonService.formpost('/fcs/firmapi', data);
    };
    FcasearchService.prototype.getFCAFirmName = function (data) {
        return this.commonService.formpost('/fcs/firmapiname', data);
    };
    FcasearchService.prototype.getFCAFirmIndividuals = function (data) {
        return this.commonService.formpost('/fcs/firmapiIndividuals', data);
    };
    FcasearchService.prototype.getFCAFirmRequirements = function (data) {
        return this.commonService.formpost('/fcs/firmapiRequirements', data);
    };
    FcasearchService.prototype.getFCAFirmPermission = function (data) {
        return this.commonService.formpost('/fcs/firmapiPermission', data);
    };
    FcasearchService.prototype.getFCAFirmPassport = function (data) {
        return this.commonService.formpost('/fcs/firmapiPassport', data);
    };
    FcasearchService.prototype.getFCAFirmRegulators = function (data) {
        return this.commonService.formpost('/fcs/firmapiRegulators', data);
    };
    FcasearchService.prototype.getFCAFirmAddress = function (data) {
        return this.commonService.formpost('/fcs/firmapiAddress', data);
    };
    FcasearchService.prototype.getFCAFirmAppointedRepresentative = function (data) {
        return this.commonService.formpost('/fcs/firmapiAppointedRepresentative', data);
    };
    FcasearchService.prototype.getFCAFirmWaivers = function (data) {
        return this.commonService.formpost('/fcs/firmapiWaivers', data);
    };
    FcasearchService.prototype.getFCAFirmExclusions = function (data) {
        return this.commonService.formpost('/fcs/firmapiExclusions', data);
    };
    FcasearchService.prototype.getFCAFirmDisciplinaryHistory = function (data) {
        return this.commonService.formpost('/fcs/DisciplinaryHistory', data);
    };
    FcasearchService.prototype.IndividualControlledFunctions = function (data) {
        return this.commonService.formpost('/fcs/IndividualControlledFunctions', data);
    };
    FcasearchService.prototype.IndividualDetailsFunctions = function (data) {
        return this.commonService.formpost('/fcs/IndividualsDetails', data);
    };
    FcasearchService.ngInjectableDef = i0.defineInjectable({ factory: function FcasearchService_Factory() { return new FcasearchService(i0.inject(i1.CommonService)); }, token: FcasearchService, providedIn: "root" });
    return FcasearchService;
}());
exports.FcasearchService = FcasearchService;
