<div class="bg">
    <app-header></app-header>
  
  
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <!-- <a [routerLink]="['/request/request-list']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Request List</a> -->
                <div class="clearfix"></div>
                <div class="tabs__content">
  
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row row-sm topheader">                            
                                <div class="col-lg-5 text-right">
                                  <app-checkcountshow></app-checkcountshow>
                                </div>
                                <div class="col-lg-7 text-right">
                                  <div class="d-flex justify-content-end align-items-center">
                                    <a class="boxbtn mainheading_btn ml-1" (click)="uploadByAdmin('')" > + Add New Applicant </a>                                   
                                    <button class="btn com_btn btn-success"[routerLink]="['/check-employee-list/passport']"  type="button">
                                      <span class="lnr lnr-history pr-1">                                        
                                      </span> Back to Applicant List 
                                    </button>
                                  </div>                             
                              </div>
                            </div>
  
                            <div class="row">
                                <div class="col-sm-12 aligncenter mb-3" style="display: block;width: 100%;">
                                    <h5 class="hedingcolor">Passport Verification History</h5>
                                    <input type="text" [(ngModel)]="searchText" class="form-control width35" placeholder="Search" style="float: right;">
                                </div>                               
                            </div>

                            <div class="browser-screen-loading-content" *ngIf="!pageOfItems" >
                                <div class="loading-dots dark-gray">
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>                                
                                    <i></i>
                                </div>
                                <p>Loading</p>
                            </div>
                            <ng-container *ngIf="( datalist | filter: {passportnumber: searchText,passportissue: searchText,passportexpiry: searchText }) as pageOfItems"> 
                                <div class="job_list_section visa_request_section pb-2 pt-2 oddeven" *ngFor="let passport of pageOfItems; let i=index ;">
                                    <!-- <div class="job_detils_date ">
                                        <div class="visa_user_pic">
                                          
                                            <img *ngIf="passport.employee.imagename" src="{{passport.employee.imagename}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                            <img *ngIf="passport.employee.gender == true && (passport.employee.imagename == null || passport.employee.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                            <img *ngIf="passport.employee.gender == false && (passport.employee.imagename == null || passport.employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                        </div>
      
                                    </div> -->
                                    <div class="job_detils_date width18">
      
                                        <div class="visa_user_details">
                                            <p class="job_address">Name</p>
                                            <h4>{{passport.employee.fname}} {{passport.employee.mname}} {{passport.employee.lname}}</h4>
                                            <!-- <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"> {{ passport.employee.emailid }}</i>
                                            </p> -->
                                            <!-- <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i> {{ passport.employee.phonenumber }}</p> -->
                                            <!-- <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i> NI Number {{ passport.employee.ninumber }} </p> -->
                                            <!-- <span class="badge badge-success" *ngIf="passport.nationality">{{ passport.nationality.countryname }}</span> -->
      
                                        </div>
                                    </div>
                                    <div class="job_detils_date width10">
                                        <p class="job_address">Phone Number</p>
                                        <p class="job_profile"> {{ passport.employee.phonenumber }}</p>
                                    </div>
                                    <div class="job_detils_date width10">
                                        <p class="job_address">Passport Number</p>
                                        <p class="job_profile">{{passport.passportnumber}}</p>
                                    </div>
                                   
                                    <div class="job_detils_date width10">
                                        <p class="job_address">Issue Date</p>
                                        <p class="job_profile">{{passport.passportissue | date: 'dd MMM yyyy'}}</p>
                                    </div>
                                    <div class="job_detils_date width10">
                                        <p class="job_address">Expiry Date</p>
                                        <p class="job_profile">{{passport.passportexpiry | date: 'dd MMM yyyy'}}</p>
                                    </div>
                                    <div class="job_detils_date width10">
                                        <p class="job_address">Check Date</p>
                                        <p class="job_profile">{{passport.createat | date: 'dd MMM yyyy'}}</p>
                                    </div>
    
                                    <div class="job_detils_date width10">
                                        <button type="button" class="btn btn-primary com_btn" [innerHtml]="downloadpassporttext[i]" (click)="downloadPassport(passport.employeepassdeatilid, i)"></button>
                                    </div>
                                   
                                    <div class="job_detils_date aligncenter width15 verify_img">
                                        <div class="fshow">
                                            <div class="visa_user_pic back">
                                                <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                                <a (click)="openModal3('pdf-image-viewer',passport.passportdocname)">   <img *ngIf="passport.passportdocname" src="{{passport.passportdocname}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}"></a>
                                                
                                                <img *ngIf="!passport.passportdocname " src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                              
                                            </div>
                                            <div class="tooltip_visa back"><p>Front image</p></div>
                                        </div>
                                        <div class="fshow">
                                        <div class="visa_user_pic front">
                                            <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                            <a (click)="openModal3('pdf-image-viewer',passport.passportbackdocname)">   <img *ngIf="passport.passportbackdocname" src="{{passport.passportbackdocname}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}"> </a>
                                            <img *ngIf="!passport.passportbackdocname" src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                        </div>
                                        <div class="tooltip_visa front"><p>Back image</p></div>
                                        </div>
                                        <!-- <div class="visa_user_pic back">
                                           
                                            <img  *ngIf="passport.employee.imagename"  src="{{passport.employee.imagename}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                            <img *ngIf="!visas.passportbackdocname " src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                        </div> -->
                                       
                                        <div class="fshow">
                                        <div class="visa_user_pic selfie" >
                                            <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                            <a (click)="openModal3('pdf-image-viewer',passport.passportprofileimage)">   <img *ngIf="passport.passportprofileimage" src="{{passport.passportprofileimage}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}"> </a>
                                            <img *ngIf="!passport.passportprofileimage" src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                        </div>
                                        <div class="tooltip_visa selfie"><p>Selfie</p></div>
                                        </div>
                                        <!-- <div class="tooltip_visa selfie"><p>Selfie images</p></div> -->
                                    </div>
                                    <div class="width100 aligncenter">
                                        <div class="pull-left">                                 
                                            <p class="badge badge-warning mb-0" *ngIf="passport.isaiverify == '1' || passport.isaiverify == '0' "><i class="fa fa-hourglass-end"></i> Awaiting Authorisation </p>
                                            <p class="badge badge-success mb-0" *ngIf="passport.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                            <p class="badge badge-error mb-0" *ngIf="passport.isaiverify == '3'"><i class="fa fa-close"></i> Rejected by AI </p>
                                        </div> 
                                    </div>
                                </div>
                                <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                    <div class="job_detils_date">
                                        <p class="job_profile">No Record Found</p>
                                    </div>
                                </div>
                            </ng-container>
                           
                            <!-- <div class="job_list_section visa_request_section" *ngIf="record == 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div> -->
                            <div class="row">                               
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  
  
  
  <jw-modal id="custom-passport-1" class="modal" role="dialog">
  
    <form [formGroup]="passformGroup" #passportForm="ngForm" class="s12 white" *ngIf="passportDetail">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="passportDetail.employeepassdeatilid">
                    <input type="hidden" formControlName="employeeid" class="form-control" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="passportDetail.employeeid">
  
                    <h5 class="modal-title" id="popupLabel">Passport Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-passport-1');">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Passport No.*</label>
                                <input type="passportnumber" formControlName="passportnumber" name="passportnumber" id="passportnumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.passportnumber.errors }" [(ngModel)]="passportDetail.passportnumber">
                                <div *ngIf="submitted && f.passportnumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.passportnumber.errors.required">Please Enter Passport No.</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Nationality*</label>
                                <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [(ngModel)]="passportDetail.nationalityid">
                    <option [value]="">Select Nationality</option>
                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                  </select>
                                <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                    <div *ngIf="f.nationalityid.errors.required">Please Select Nationality</div>
                                    <!-- <div *ngIf="f.countryid.errors.required">Nationality</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Passport Issue Date*</label>
                                <mat-form-field class="datecontrol" [ngClass]="{ 'is-invalid': submitted && f.passportissue.errors }">
                                    <input matInput [matDatepicker]="picker1" name="passportissue" id="passportissue" class="form-control noborder-invalid" formControlName="passportissue" [(ngModel)]="passportDetail.passportissue">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.passportissue.errors" class="invalid-feedback">
                                    <div *ngIf="f.passportissue.errors.required">Please Choose Passport Issue Date</div>
                                </div>
  
  
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Passport Expiry Date*</label>
                                <mat-form-field class="datecontrol" [ngClass]="{ 'is-invalid': submitted && f.passportexpiry.errors }">
                                    <input matInput [matDatepicker]="picker2" id="passportexpiry" name="passportexpiry" class="form-control noborder-invalid" formControlName="passportexpiry" [min]="passformGroup.controls.passportissue.value" [(ngModel)]="passportDetail.passportexpiry">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
  
                                <div *ngIf="submitted && f.passportexpiry.errors" class="invalid-feedback">
                                    <div *ngIf="f.passportexpiry.errors.required">Please Choose Passport Expiry Date</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Place of Issue of Passport*</label>
                                <input type="placeofissue" formControlName="placeofissue" class="" id="placeofissue" name="placeofissue" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.placeofissue.errors }" [(ngModel)]="passportDetail.placeofissue">
                                <div *ngIf="submitted && f.placeofissue.errors" class="invalid-feedback">
                                    <div *ngIf="f.placeofissue.errors.required">Please Enter Place of Issue</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                              <label>Front Side Passport Image Upload*</label>
                              <div class="input-group mb-3">
                                  <div class="custom-file" (click)="file.click()">
                                      <input type="file" #file (change)="selectFile($event)">
                                      <label class="custom-file-label">{{filename}}</label>
                                  </div>
                              </div>
                              <div class="invalid-feedback" *ngIf="filereuirederror == true">Please select front side passport</div>
                              <div class="invalid-feedback" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                              <div class="invalid-feedback" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png)</div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-lg-6">
                              <label>Back Side Passport Image Upload</label>
                              <div class="input-group mb-3" (click)="fileback.click()">
                                  <div class="custom-file">
                                      <input type="file" #fileback (change)="selectbackFile($event)">
                                      <label class="custom-file-label">{{filebackname}}</label>
                                  </div>
                              </div>
                              <div class="invalid-feedback" *ngIf="filebacksizeerror == true">File size must be less than 2 MB</div>
                              <div class="invalid-feedback" *ngIf="filebackformaterror == true">Please choose a valid file format (jpg, png)</div>
                          </div>
                          <div class="form-group col-lg-6">
                              <div class="input-group mb-3 backsidepass">
                                 <span>This is my current passport</span>
                                  <input class="passs" type="checkbox" [checked]="passportDetail.iscurrent" formControlName="iscurrent" name="iscurrent" [(ngModel)]="passportDetail.iscurrent" />
                              </div>
                          </div>
                      </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
  
                                <div>
                                    Is this your Current Passport?
                                    <input type="checkbox" [checked]="passportDetail.iscurrent" formControlName="iscurrent" name="iscurrent" [(ngModel)]="passportDetail.iscurrent">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(passportForm.value,'custom-passport-1', '1')" value="{{buttoninsert}}" />
                    <!-- <input type="submit" class="btn btn-success com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(passportForm.value,'custom-passport-1', '2')" value="Update & Approve" />
                    <input type="submit" class="btn btn-danger com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(passportForm.value,'custom-passport-1', '3')" value="Reject" /> -->
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-passport-1');" />
  
                </div>
            </div>
        </div>
    </form>
  </jw-modal>
  
  
  <jw-modal id="pdf-image-viewer" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer');">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="container">
  
                    <div class="row">
                        <div class="col-lg-12">
  
                            <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="500" frameborder="0"></iframe>
                            <img *ngIf="imgsrc == true" [src]="iframesrc" style="width:100%;" />
                        </div>
                    </div>
  
                </div>
            </div>
  
        </div>
    </div>
  </jw-modal>


  
<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">         
            <div class="modal-body">
                <div class="container text-center">
                  <div class="info_mess">
                      <div class="para">
                              <h4 class="mb-2">No credits available</h4>
                              <p class="mb-0 font20">There are no credits available to perform this action. Please add more credits.</p>
  
                          </div>                          
                      </div>
                </div>
            </div>
            <div class="modal-footer text-center">
              <input type="button" class="btn btn-primary com_btn" name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
              <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
                <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
            </div>
        </div>
    </div>
  </jw-modal>