<div class="steps clearfix">
  <ul role="tablist">
      <li role="tab" class="first" aria-disabled="false" aria-selected="true">
              <div class="media">
                  <div class="bd-wizard-step-icon">
                      <i class="fa fa-laptop" aria-hidden="true"></i>
                  </div>
                  <div class="media-body">
                      <h5 class="bd-wizard-step-title">Upload or Verify ID</h5>
                      <p class="bd-wizard-step-subtitle">Please make sure all the details are correct. 
                      </p>
                  </div>
              </div>
              <!-- <a [routerLink]="['/idproofaidetails',employeeidproofdeatilid]" routerLinkActive="activelink"></a> -->
      </li>
      <li role="tab" class="disabled" aria-disabled="true">
              <div class="media">
                  <div class="bd-wizard-step-icon">
                      <i class="fa fa-briefcase" aria-hidden="true"></i>
                  </div>
                  <div class="media-body">
                      <h5 class="bd-wizard-step-title">Upload or Verify Image</h5>
                      <p class="bd-wizard-step-subtitle">Please make sure all the details are correct
                      </p>
                  </div>
              </div>
              <!-- <a [routerLink]="['/idproofaifacedetails',employeeidproofdeatilid]" routerLinkActive="activelink"></a> -->
      </li>
      <li role="tab" class="disabled" aria-disabled="true">
              <div class="media">
                  <div class="bd-wizard-step-icon">
                      <i class="fa fa-link" aria-hidden="true"></i>
                  </div>
                  <div class="media-body">
                      <h5 class="bd-wizard-step-title">Review & Payment</h5>
                      <p class="bd-wizard-step-subtitle">Use your free credits or pay to proceed  
                      </p>
                  </div>
              </div>
              <!-- <a [routerLink]="['/idproofaipaymentdetails',employeeidproofdeatilid]" routerLinkActive="activelink"></a> -->
      </li>
      <li role="tab" class="disabled last" aria-disabled="true">
              <div class="media">
                  <div class="bd-wizard-step-icon">
                      <i class="fa fa-check-square-o" aria-hidden="true"></i>
                  </div>
                  <div class="media-body">
                      <h5 class="bd-wizard-step-title">Result</h5>
                      <p class="bd-wizard-step-subtitle">Yes (Approved ID)<br /> No (Fraudulent ID)
                      </p>
                  </div>
              </div>
              <!-- <a [routerLink]="['/idproofaithanks',employeeidproofdeatilid]" routerLinkActive="activelink"></a> -->
      </li>
  </ul>
</div>