import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  constructor(private commonService: CommonService) { }

  getemployeeexpenselist(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/expenselist'); 
  }

  getemployeeexpensereport(data): Observable<any> {
    return this.commonService.formpost('/employee/expenselistreport',data); 
  }
  
  getemployeeexpensereportrequest(data): Observable<any> {
    return this.commonService.formpost('/employee/expenselistreportrequest',data); 
  }

  getemployeeexpensereportrequestbyadmin(data): Observable<any> {
    return this.commonService.formpost('/employee/expenselistreportrequestbyadmin',data); 
  }
  

  getexpensetypelist(data): Observable<any> {
    return this.commonService.formpost('/expensetype',data);
  }
  
  getprojectlist(data): Observable<any> {
    return this.commonService.formpost('/projectlist',data);
  }

  expensepaidby(): Observable<any> {
    return this.commonService.formpostOnlyid('/expensepaidby');
  }

  expenseapplyupdate(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/expenseapply',value);
  }

  getexpensedata(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/expensebyid',value);
  }

  getexpensedatabydate(data): Observable<any> {
    return this.commonService.formpost('/employee/expenselistbydate',data); 
  }

  deleteexpense(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/expensedelete',value);
  }

  statusexpense(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/expensestatus',value);
  }

  getexpenseType(data): Observable<any> 
  {
    return this.commonService.formpost('/company/expenselist',data);   
  }

  settinghourtypeUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/expensetypeupdatebyadmin', value);
  }

  getexpenseList(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/employeeexpensereport',data);   
  }

  expensetypeUpdateChangeStatus(value:any): Observable<any> 
  {
    return this.commonService.formpost('/company/expensetypeupdatechangestatus',value);
  }

}
