<style>
    .invalid-feedback {
        display: block !important;
    }
</style>
<div class="bg">
    <app-header></app-header>
    <!-- <div class="textrotate">
  <a [routerLink]="['/my-leave']">My Leaves</a>
  <a [routerLink]="['/my-leave-report']" style="margin-top: 110px;">Report</a>
  <a [routerLink]="['/total-leave']" style="margin-top: 200px;">Company Leave</a>
</div> -->
    <style>
        .leftbox {
            top: -30px !important;
            left: 30px !important;
        }
    </style>
    <div class="card-activities">
        <div class="media-list">
            <div class="media">
                <div class="activity-icon">
                    <!--<a style="color:#164881; cursor: pointer;" (click)="yearpop('year-select')"><i class="fa fa-calendar"
            aria-hidden="true"></i></a>
        <jw-modal id="year-select" class="modal yearselect" role="dialog">
          <div role="document" class="formgroup">
            <div class="modal-content">
              <div class="modal-body" style="padding:5px;">
                <form #yearForm="ngForm" class="s12 white" (ngSubmit)="onyearSubmit(yearForm.value,'year-select')">
                  <div class="">
                    <div class="row">
                      <div class="col-lg-12 poplist m-b-10">
                        <select name="year" id="year" ngModel='{{ year }}'>
                          <option>2017</option>
                          <option>2018</option>
                          <option>2019</option>
                          <option>2020</option>
                        </select>
                      </div>
                    </div>
                    <div class="row row-sm float-right">
                      <button type="submit" class="btn btn-outline-secondary" style="margin-right:15px;margin-top: 5px;"><i
                          class="fa fa-check"></i></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </jw-modal>-->
                </div>
            </div>
            <div class="media">
                <div class="activity-icon" style="position:relative;">
                    <!-- <a style="color:#164881; cursor: pointer;" (click)="addNewEvent('Add',event)"><i class="fa fa-plus-square"></i></a> -->
                </div>
            </div>
            <div class="media">
                <div class="activity-icon">
                    <i class="icon ion-image"></i>
                </div>
            </div>
            <div class="media">
                <div class="activity-icon">
                    <i class="icon ion-image"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="textrotate1">
                    <ul>
                        <li> <a [routerLink]="['/my-leave']">My Leaves</a></li>
                        <li><a [routerLink]="['/my-leave-report']">Report</a></li>
                        <li> <a [routerLink]="['/total-leave']">Company Leave</a></li>
                    </ul>
                </div>
                <div class="tabs__content">
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row" >
                                <div class="col-lg-12 text-left">
                                    <h5 class="hedingcolor"> Leave List</h5>
                                </div>
                            </div>
                            <div class="row row-sm" >
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-xl-4 m-b-10 mt-2 mb-2"  *ngFor="let ltype of leavetypelist">
                                            <div class="billed-to">
                                                <p><b>Leave Name </b><span>{{ltype.typename}}</span></p>
                                                <p><b>No. of Holiday</b><span>{{ltype.noofholiday }} </span></p>
                                                <p><b>Leave Type </b><span><ng-container *ngIf="ltype.htype == '1'">Yearly</ng-container><ng-container *ngIf="ltype.htype  == '0'">Monthly</ng-container></span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>