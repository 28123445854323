
<div class="overviewsshow">
    <div class="header">
        <h1 class="headprofile mt-0">Visa</h1>
        <a (click)="openModal2('custom-visa-1')" class="addscreensuccess">Add Visa</a>
    </div>
    <div class="row">
        <div *ngFor="let filterItem of listcheck" class="form-check col-lg-3" >
            <label class="form-check-label">
                <input type="radio" [checked]="filterItem.selected" (change)="onlistchange($event,filterItem.id)"
                    value="filterItem.id"> {{ filterItem.name }}  
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6" *ngFor="let vs of visalist">
            <div class="border-box">
                <div class="overview_details emp">
                    <div class="profile_dtailsshow visastaus mb-3" >
                        <div class="detailsshow">
                            <span class="badge badge-danger" *ngIf="vs.stype == '3'"><i class="fa fa-times" aria-hidden="true"></i> Rejected</span>
                            <span class="badge badge-warning" *ngIf="vs.stype == '1'"><i class="fa fa-hourglass-end" aria-hidden="true"></i> Pending</span>
                            <span class="badge badge-success" *ngIf="vs.stype == '2'"><i class="fa fa-check" aria-hidden="true"></i> Approved</span>
                        </div>
                        <div class="icons_box_section">
                            <a (click)="openModal('custom-visa-1',vs)" class="editprime" *ngIf="vs.stype == '1'"><i class="lnr lnr-pencil"></i></a>
                                <a (click)="onDelete(vs.employeevisadeatilid)" class="deletered" *ngIf="vs.stype == '1' && vs.iscurrent == false"><i class="lnr lnr-trash"></i></a>
                            </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3">
                            <span>Visa Number</span>
                                <p>{{vs.visanumber}}</p>
                        </div>
                        <div class="detailsshow width50">
                            <span>Visa Type</span>
                                <p>{{vs.visatype }}</p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3 mt-0">
                            <span>Start Date</span>
                                <p>{{vs.visastart | date: 'dd MMM yyyy'}}</p>
                        </div>
                        <div class="detailsshow width50 mt-0">
                            <span>Expiry Date</span>
                                <p>{{vs.visaexpiry | date: 'dd MMM yyyy'}}</p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 mt-0 pr-3">
                            <span>Visa Issue country</span>
                                <p *ngIf="vs.nationality"> {{vs.nationality.countryname}}</p>
                        </div>
                        <div class="detailsshow width50 mt-0">
                            <span>View Visa</span>
                                <p>  <a (click)="openModal3('pdf-image-viewer-myvisasystem',vs.visadocname,vs.visabackdocname)" *ngIf="vs.visadocname || vs.visabackdocname"><i class="fa fa-file-image-o mg-r-10" ></i>&nbsp;</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="job_list_section visa_request_section" *ngIf="!visalist?.length > false">
        <div class="job_detils_date">
            <p class="job_profile">No Record Found</p>
        </div>
    </div>    
</div>

<jw-modal id="custom-visa-1" role="dialog" class="modal">
    <form [formGroup]="visapopForm" #visaForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(visaForm.value,'custom-visa-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="visaDetail.employeeid">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="visaDetail.employeevisadeatilid">
                    <h5 class="modal-title" id="popupLabel">Visa Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-visa-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Visa No.*</label>
                                <input type="visanumber" formControlName="visanumber" name="visanumber" id="visanumber" 
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.visanumber.errors }" 
                                    [ngModel]="visaDetail.visanumber" maxlength="10">
                                <div *ngIf="submitted && f.visanumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.visanumber.errors.required">Please enter visa no.</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Visa Type*</label>
                                <input type="visatype" formControlName="visatype" name="visatype" id="visatype" 
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.visatype.errors }" 
                                    [ngModel]="visaDetail.visatype" maxlength="30">
                                <div *ngIf="submitted && f.visatype.errors" class="invalid-feedback">
                                    <div *ngIf="f.visatype.errors.required">Please enter visa type</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Start Date*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker1" name="visastart" id="visastart" class="form-control noborder-invalid" [ngClass]="{ 'is-invalid': submitted && f.visastart.errors }" formControlName="visastart" [ngModel]="visaDetail.visastart">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.visastart.errors" class="invalid-feedback">
                                    <div *ngIf="f.visastart.errors.required">Please choose start date</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Expiry Date*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker2" id="visaexpiry" name="visaexpiry" formControlName="visaexpiry" [ngClass]="{ 'is-invalid': submitted && f.visaexpiry.errors }" class="form-control noborder-invalid  datecontrol" [min]="visapopForm.controls.visastart.value" [ngModel]="visaDetail.visaexpiry">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.visaexpiry.errors" class="invalid-feedback">
                                    <div *ngIf="f.visaexpiry.errors.required">Please choose expiry date</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Visa Issue Country*</label>
                                <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [ngModel]="visaDetail.nationalityid">
                                    <option [value]="defaultVal">Select Country </option>
                                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                    <div *ngIf="f.nationalityid.errors.required">Please select visa issue country</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label>Front Side Visa Image Upload*</label>
                                <div class="input-group " (click)="file.click()">
                                    <div class="custom-file">
                                        <input type="file" #file (change)="selectFile($event)">
                                        <label class="custom-file-label">{{filename}}</label>
                                    </div>
                                </div>
                                <div class="invalid-feedback" *ngIf="filerequirederror">Please select front side visa image </div>
                                <div class="invalid-feedback" *ngIf="filesizeerror">File size must be less than 2 MB</div>
                                <div class="invalid-feedback" *ngIf="fileformaterror">Please choose a valid file format (jpg, png)</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <label>Back Side Visa Image Upload</label>
                                <div class="input-group mb-3" (click)="fileback.click()">
                                    <div class="custom-file">
                                        <input type="file" #fileback (change)="selectbackFile($event)">
                                        <label class="custom-file-label">{{filebackname}}</label>
                                    </div>
                                </div>
                                <div class="invalid-feedback" *ngIf="filebackrequirederror">Please select back side visa image </div>
                                <div class="invalid-feedback" *ngIf="filebacksizeerror == true">File size must be less than 2 MB</div>
                                <div class="invalid-feedback" *ngIf="filebackformaterror == true">Please choose a valid file format (jpg, png)</div>
                            </div>
                            <div class="form-group col-lg-6 ">
                                <label>Current Visa?</label>
                                    <div class="input-group mt-2">
                                        <label class="visapass mr-3 mb-0 d-flex align-items-center">Yes
                                            <input type="radio" value="1" formControlName="iscurrent" name="iscurrent" [ngModel]="visaDetail.iscurrent" >
                                            <span class="checkmark"></span>
                                          </label> 
                                          <label class="visapass mb-0 d-flex align-items-center">No
                                            <input type="radio" value="0" formControlName="iscurrent" name="iscurrent" [ngModel]="visaDetail.iscurrent">
                                            <span class="checkmark"></span>
                                          </label>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-visa-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>

<jw-modal id="pdf-image-viewer-myvisasystem" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-myvisasystem');">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6" [ngClass]="!iframevisabacksrc ?'col-lg-12':'col-lg-6'">
                            <div class="overlaytext"><p>Front Image</p></div>
                            <iframe *ngIf="imgvisasrc == false" [src]="iframevisasrc" width="100%" height="250" frameborder="0"></iframe>
                            <img *ngIf="imgvisasrc == true" [src]="iframevisasrc" width="100%" class="imgborder"/>
                        </div>
                        <div class="col-lg-6" *ngIf="iframevisabacksrc">
                            <div class="overlaytext"><p>Back Image</p></div>
                            <iframe *ngIf="imgvisabacksrc == false" [src]="iframevisabacksrc" width="100%" height="250" frameborder="0"></iframe>
                            <img *ngIf="imgvisabacksrc == true" [src]="iframevisabacksrc" class="imgborder" width="100%" />
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>