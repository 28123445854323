<app-header></app-header>
<div class="section-wrapper">
    <div class="container">
        <app-invioce-shared></app-invioce-shared>
        <div class="clearfix"></div>
        <div class="tabs" id="tabs">
            
            <div class="tabs__sontent">
                
            <div class="invoice_content">
                <div class="admin_pro_section">
                    <div class="cseec">
                        <div class="admin_com_logo">
                            <div class="picture_chnge">
                                <!-- <i class="fa fa-camera"></i> -->
                                <input type="file" accept="image/*" (change)="selectFile($event)" style="display:none;" #file>
                                <a target="_blank" (click)="file.click()"><i class="fa fa-camera"></i></a>
                            </div>
                            <!-- <img src="../../../../../assets/img/user_pic_emp.png"> -->
                            <img id="ContentPlaceHolder1_imgDemoLogo" src="{{ companyprofileDetail.logoname }}" *ngIf="companyprofileDetail.logoname != ''">
                            <img id="ContentPlaceHolder1_imgDemoLogo" src="../../../assets/img/demologo.png" *ngIf="companyprofileDetail.logoname == '' || companyprofileDetail.logoname == null ">
                        </div>
                    </div>
                    <div class="admin_com_details">
                        <div class="pro_com_name">
                            <h4>{{ companyprofileDetail.companyname }}</h4>
                            <button *ngIf="profileperm > 2" (click)="openModal('custom-company-1')" class="btn btn-danger" type="submit">Edit Profile</button>
                        </div>
                        <hr>
                        <div class="pro_com_add" *ngFor="let location of companylocationlist">
                            <div *ngIf="location.isdefault==true">
                                <span>Address</span>
                                <p> <span *ngIf="location.address2 != ''">{{location.address2}}</span> {{location.address1}} ,{{location.cityname}}, {{location.statename}} , {{location.country.countryname}}, {{location.pincode}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="admin_pro_footer">
                        <div class="pro_footer_content">
                            <label><i class="fa fa-globe" aria-hidden="true"></i> Website</label>
                            <p class="link">
                                <a target="_blank" href="http://{{weburl }}">{{ companyprofileDetail.websiteurl ? companyprofileDetail.websiteurl : '' }}</a>
                            </p>
                        </div>
                        <div class="pro_footer_content">
                            <label><i class="fa fa-envelope-o" aria-hidden="true"></i> Email</label>
                            <p>
                                <a class="alignemail" href="mailto:{{ companyprofileDetail.emailid }}">{{ companyprofileDetail.emailid }}</a>
                                <a class="alignemail" href="mailto:{{ companyprofileDetail.secondryemailid }}">{{
                            companyprofileDetail.secondryemailid }}</a>
                            </p>
                        </div>
                        <div class="pro_footer_content">
                            <label><i class="fa fa-mobile" aria-hidden="true"></i> Mobile No.</label>
                            <p>{{companyprofileDetail.phonenumber}}</p>
                        </div>
                        <div class="pro_footer_content">
                            <label><i class="fa fa-phone" aria-hidden="true"></i> Phone No.</label>
                            <p>{{companyprofileDetail.sphonenumber}}</p>
                        </div>
                    </div>
                </div>



                <div class="content" *ngIf="ls">
                    <div class="row row-sm topheader mb-2">
                        <div class="col-lg-6">
                            <h5 class="hedingcolor mt-4">Billing Address <a *ngIf="data?.length < 1" (click)="openModal2('custom-location-1')" class="mainheading_btn"> + Add New</a></h5>
                        </div>
                        <div class="col-lg-6">
                            <!-- <div class="input-group">
                                <input [(ngModel)]="searchText" placeholder="Search.." class="form-control">
                            </div> -->
                        </div>
                    </div>
    
                    <div class="job_list_section employee_list_section loction_section" *ngFor="let location of data | filter: {pincode: searchText,address1: searchText,address2: searchText,statename: searchText };  let i=index">
                        <div class="job_detils_date width22">
                            <h5 class="job_profile font_17">{{location.address1}} </h5>
                            <p class="email" *ngIf="location.address2 != ''">{{location.address2}}</p>
                        </div>
                        <div class="job_detils_date width13">
                            <p class="job_address"> Location Type</p>
                            <p class="job_profile">{{location.companylocationtype.companytypename}}</p>
                        </div>
                        <div class="job_detils_date width13">
                            <p class="job_address"> County</p>
                            <p class="job_profile">{{location.statename}}</p>
                        </div>
                        <div class="job_detils_date width13">
                            <p class="job_address"> Country</p>
                            <p class="job_profile">{{(location.country)?location.country.countryname:''}}</p>
                        </div>
                        <div class="job_detils_date width13">
                            <p class="job_address"> Post Code</p>
                            <p class="job_profile">{{location.pincode}}</p>
                        </div>
    
                        <div class="job_detils_date visa_btn">
                            <div class="job_address d-flex aligncenter">
                                <button type="submit" class="btn btn-primary com_btn" *ngIf="locationperm > 2" (click)="onDelete(location)"><i class="lnr lnr-trash"></i></button>
                                <button type="submit" class="btn btn-danger com_btn" (click)="editLocationModal('custom-location-1',location)"><i class="lnr lnr-pencil"></i></button>
                                <!-- <button type="submit" class="btn btn-secondary com_btn" (click)="openModal('custom-passport-1',passport)"><i class="fa fa-trash-o"></i></button> -->
                                <div class="onoffswitch" *ngIf="(!location.isdefault) && (locationperm > 2)">
                                    <label class="onoffswitch-label" for="skill{{i+1}}" class="switch">
                                        <input type="checkbox" name="{{ location.isdefault }}" type="checkbox" [checked]="location.isdefault" (change)="onChangeStatus($event, location)" class="onoffswitch-checkbox" id="skill{{i+1}}" >
                                        <span class="slider round"></span>
                                      </label>
                                </div>
                                <label *ngIf="location.isdefault" class="currentshow">Current</label>
                            </div>
                        </div>
                    </div>
                    <div class="job_list_section visa_request_section" *ngIf="record == 0">
                        <div class="job_detils_date">
                            <p class="job_profile">No Record Found</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 text-right">
                            <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                        </div>
                    </div>
                </div>

            </div>
            </div>
        </div>
    </div>
</div>


    

<jw-modal id="custom-company-1" role="dialog" class="modal" >
    <form [formGroup]="profileformGroup" #profileform="ngForm" class="s12 white" (ngSubmit)="onSubmit(profileform.value,'custom-company-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="popupLabel">Profile</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-company-1');">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row row-sm">
                            <div class="col-lg-12">
                                <div role="document" class="formgroup">
                                    <input type="hidden" formControlName="companylicensedetailsid" class="form-control" name="companylicensedetailsid" id="companylicensedetailsid" ngModel="{{companyprofileDetail.license.companylicensedetailsid}}">
                                    <input type="hidden" formControlName="companyid" class="form-control" name="companyid" id="companyid" ngModel="{{companyprofileDetail.companyid}}" />
                                    <div class="row">
                                        <div class="form-group col-lg-12">
                                            <label>Company Name*</label>
                                            <input type="text" formControlName="companyname" class="" name="companyname" id="companyname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.companyname.errors }" ngModel="{{ companyprofileDetail.companyname }}">
                                            <div *ngIf="submitted && f.companyname.errors" class="invalid-feedback">
                                                <div *ngIf="f.companyname.errors.required">Please enter company name</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-lg-6">
                                            <label>Company Type</label>
                                            <select  formControlName="companytypeid" name="companytypeid" id="socid" class="form-control " [ngClass]="{ 'is-invalid': submitted && f.companytypeid.errors }" ngModel="{{ companyprofileDetail.companytypeid }}">
                                                <option value="">Select Company Type </option>
                                                <option *ngFor="let companytype of companytypelist" value='{{ companytype.companytypeid }}'
                                                [selected]="companytype.companytypeid == companyprofileDetail.companytypeid">
                                                {{ companytype.companycode }} - {{ companytype.typename }}</option>
                                            </select>
                                            <div *ngIf="submitted && f.companytypeid.errors" class="invalid-feedback">
                                                <div *ngIf="f.companytypeid.errors.required">Please select company type</div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-6">
                                            <label>Web URL </label>
                                            <input type="text" formControlName="websiteurl" class="" name="websiteurl" id="websiteurl" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.websiteurl.errors }" ngModel="{{ companyprofileDetail.websiteurl }}">
                                            <div *ngIf="submitted && f.websiteurl.errors" class="invalid-feedback">
                                                <div *ngIf="f.websiteurl.errors.required">Please enter url</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">

                                        <div class="form-group col-lg-6">
                                            <label>Contact Name*</label>
                                            <input type="text" formControlName="clientname" class="" name="clientname" id="clientname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.clientname.errors }" ngModel="{{ companyprofileDetail.clientname }}">
                                            <div *ngIf="submitted && f.clientname.errors" class="invalid-feedback">
                                                <div *ngIf="f.clientname.errors.required">Please enter contact name</div>
                                            </div>
                                        </div>

                                        <div class="form-group col-lg-6">
                                            <label>Role*</label>
                                            <input type="text" formControlName="clietdesignation" class="" name="clietdesignation" id="clietdesignation" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.clietdesignation.errors }" ngModel="{{ companyprofileDetail.clietdesignation }}"
                                                ngModel="{{ companyprofileDetail.clietdesignation }}">
                                            <div *ngIf="submitted && f.clietdesignation.errors" class="invalid-feedback">
                                                <div *ngIf="f.clietdesignation.errors.required">Please enter role</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-lg-6">
                                            <label>Email*</label>
                                            <input type="email" formControlName="emailid" class="" name="emailid" id="emailid" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }" ngModel="{{ companyprofileDetail.emailid }}" ngModel="{{ companyprofileDetail.emailid }}">
                                            <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                                                <div *ngIf="f.emailid.errors.required">Please enter email </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-6">
                                            <label>Alternative Email </label>
                                            <input type="email" formControlName="secondryemailid" class="" name="secondryemailid" id="secondryemailid" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.secondryemailid.errors }" ngModel="{{ companyprofileDetail.secondryemailid }}" ngModel="{{ companyprofileDetail.secondryemailid }}">
                                            <div *ngIf="submitted && f.secondryemailid.errors" class="invalid-feedback">
                                                <div *ngIf="f.secondryemailid.errors.required">Please enter email </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-lg-6">
                                            <label>Mobile Number*</label>
                                            <international-phone-number formControlName="phonenumber" class="form-control phonecontrol" [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" [(ngModel)]="companyprofileDetail.phonenumber" placeholder="Enter mobile number" (input)="validatenumber($event.target.value,'mobile')"
                                                [maxlength]="20" [required]="true" #phonenumber name="phonenumber"></international-phone-number>
                                            <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                                <div *ngIf="f.phonenumber.errors.required">Please enter mobile number</div>
                                            </div>
                                            <span *ngIf="mobilecheck==false" style="color:red">Invalid format</span>
                                            <span *ngIf="mobilecheck==true" style="color:green">Valid format</span>
                                        </div>

                                        <div class="form-group col-lg-6">
                                            <label>Phone Number</label>
                                            <international-phone-number formControlName="sphonenumber" class="form-control phonecontrol" [ngClass]="{ 'is-invalid': submitted && f.sphonenumber.errors }" [(ngModel)]="companyprofileDetail.sphonenumber" placeholder="Enter mobile number" (input)="validatenumber($event.target.value,'phone')"
                                                [maxlength]="20" #sphonenumber name="sphonenumber"></international-phone-number>
                                            <div *ngIf="submitted && f.sphonenumber.errors" class="invalid-feedback">
                                                <div *ngIf="f.sphonenumber.errors.required">Please enter phone number</div>
                                            </div>
                                            <span *ngIf="phonecheck==false" style="color:red">Invalid format</span>
                                            <span *ngIf="phonecheck==true" style="color:green">Valid format</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-company-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>



<jw-modal id="custom-location-1" role="dialog" class="modal" *ngIf="ls">
    <form [formGroup]="locationformGroup" #companylocationForm="ngForm" class="s12 white" (ngSubmit)="onLocationSubmit(companylocationForm.value,'custom-location-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" id="id" [ngModel]="locationDetail.companylocationdetailsid" class="form-control" name="id">
                    <h5 class="modal-title" id="popupLabel">Location Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-location-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label>Location Type *</label>
                                <select style="width:100%;" formControlName="companytypeid" name="companytypeid" id="companytypeid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && g.companytypeid.errors }" [ngModel]="locationDetail.companytypeid">
                                <option [value]="defaultVal">Select Location Type </option>
                                <option *ngFor="let locationtype of companytype" [value]="locationtype.locationcompanytypesid">
                                    {{ locationtype.companytypename }}</option>
                                </select>
                                <div *ngIf="submitted && g.companytypeid.errors" class="invalid-feedback">
                                    <div *ngIf="g.companytypeid.errors.required">Please select location type</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Post Code*</label>
                                <input type="text" formControlName="pincode" id="pincode" name="pincode" class="form-control pincode" [ngClass]="{ 'is-invalid': submitted && g.pincode.errors }" [ngModel]="locationDetail.pincode" (input)="onSearchChange($event.target.value)" matInput
                                    [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="pincode">
                                    <mat-option *ngFor="let option of options" [value]="option" (onSelectionChange)="updatecode($event, option)">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div *ngIf="submitted && g.pincode.errors" class="invalid-feedback">
                                    <div *ngIf="g.pincode.errors.required">Please enter post code </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Address Line 1*</label>
                                <input type="text" formControlName="address1" name="address1" id="address1" class="form-control" [ngClass]="{ 'is-invalid': submitted && g.address1.errors }" [ngModel]="locationDetail.address1">
                                <div *ngIf="submitted && g.address1.errors" class="invalid-feedback">
                                    <div *ngIf="g.address1.errors.required">Please enter address</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Address Line 2 </label>
                                <input type="text" formControlName="address2" name="address2" id="address2" class="form-control" [ngClass]="{ 'is-invalid': submitted && g.address2.errors }" [ngModel]="locationDetail.address2">
                                <div *ngIf="submitted && g.address2.errors" class="invalid-feedback">
                                    <div *ngIf="g.address2.errors.required">Please enter address</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>City*</label>
                                <input type="text" formControlName="cityname" name="cityname" id="cityname" class="form-control" [ngClass]="{ 'is-invalid': submitted && g.cityname.errors }" [ngModel]="locationDetail.cityname">
                                <div *ngIf="submitted && g.cityname.errors" class="invalid-feedback">
                                    <div *ngIf="g.cityname.errors.required">Please enter city </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>County*</label>
                                <input type="text" formControlName="statename" name="statename" id="statename" class="form-control" [ngClass]="{ 'is-invalid': submitted && g.statename.errors }" [ngModel]="locationDetail.statename">
                                
                                <div *ngIf="submitted && g.statename.errors" class="invalid-feedback">
                                    <div *ngIf="g.statename.errors.required">Please select county</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label>Country*</label>
                                <select style="width:100%;" formControlName="countryid" name="countryid" id="countryid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && g.countryid.errors }" [ngModel]="locationDetail.countryid">
                                <option value="">Select Country </option>
                                <option *ngFor="let country of countrylist" value="{{country.countryid}}">{{ country.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && g.countryid.errors" class="invalid-feedback">
                                    <div *ngIf="g.countryid.errors.required">Please select country</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-location-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>