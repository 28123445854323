"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var user_model_1 = require("../../models/user.model");
var user_service_1 = require("../../services/user.service");
var alert_service_1 = require("../../services/alert.service");
var modal_service_1 = require("../../_services/modal.service");
var app_global_1 = require("../../app.global");
var stripe_angular_1 = require("stripe-angular");
var checkservice_service_1 = require("../../services/checkservice.service");
var router_1 = require("@angular/router");
var company_service_1 = require("../../services/company.service");
var address_model_1 = require("../../models/address/address.model");
var country_service_1 = require("../../services/country.service");
var platform_browser_1 = require("@angular/platform-browser");
var UpgradecheckComponent = /** @class */ (function () {
    function UpgradecheckComponent(document, router, alerts, userService, modalService, companyService, country, StripeScriptTag, formBuilder, checkService, sanitizer) {
        this.document = document;
        this.router = router;
        this.alerts = alerts;
        this.userService = userService;
        this.modalService = modalService;
        this.companyService = companyService;
        this.country = country;
        this.StripeScriptTag = StripeScriptTag;
        this.formBuilder = formBuilder;
        this.checkService = checkService;
        this.sanitizer = sanitizer;
        this.show = false;
        this.buttonName = 'Show';
        this.submitted = false;
        this.user = new user_model_1.User();
        this.access_token = '';
        this.userid = '';
        this.name = '';
        this.isline = '';
        this.planid = '';
        this.isemail = '';
        this.paymentloading = false;
        this.paymentaut = true;
        this.iframepaymentautsrc = '';
        this.address = new address_model_1.Address();
        this.isLoginError = false;
        this.wizard1 = false;
        this.wizard2 = false;
        this.publishableKey = app_global_1.AppGlobals.API_STRIPE;
        this.tab1 = false;
        this.paynow = false;
        this.changetoggle = false;
        this.coupon_applied = false;
        this.couponerror = false;
        this.paymentSubmit = 0;
        this.defaultVal = '';
    }
    UpgradecheckComponent.prototype.ngOnInit = function () {
        // var userid = localStorage.getItem('userid');
        // if (!userid) {
        //   this.router.navigate(['/login']);
        // }
        var _this = this;
        // this.ctype  = parseInt(localStorage.getItem('ctype'));
        // if(this.ctype ==1)
        // {
        //   this.companyService.getcompanydetails().subscribe((data: any) => {
        //     if (data.verfiedforcheck == false && data.stuser)
        //     { 
        //       this.router.navigate(['/approval-confirmation']);          
        //     }
        //   });
        // }
        this.errormsg = '';
        this.StripeScriptTag.setPublishableKey(this.publishableKey);
        this.wizard1 = true;
        this.tab1 = true;
        this.addpopForm = this.formBuilder.group({
            cardname: ['', forms_1.Validators.required],
            address1: ['', forms_1.Validators.required],
            address2: [''],
            cityname: ['', forms_1.Validators.compose([forms_1.Validators.required,])],
            statename: ['', forms_1.Validators.required],
            pincode: ['', forms_1.Validators.required],
            stateid: [''],
            taxid: [''],
            coupon: [''],
            countryid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])]
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.addresslistDetail = new address_model_1.Address();
        this.addresslistDetail.countryid = "70";
        this.companyService.getcompanydetails().subscribe(function (data) {
            _this.stuser = data.stuser;
        });
        this.checkService.getAllServices().subscribe(function (data) {
            _this.pricecheck = data;
        });
    };
    UpgradecheckComponent.prototype.toTop = function (id) {
        document.getElementById("content").scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    };
    UpgradecheckComponent.prototype.checkPasswords = function (group) {
        var pass = group.controls.password.value;
        var confirmPass = group.controls.confirmpassword.value;
        return pass === confirmPass ? null : { notSame: true };
    };
    Object.defineProperty(UpgradecheckComponent.prototype, "f", {
        get: function () { return this.registerForm.controls; },
        enumerable: true,
        configurable: true
    });
    /**
   * Submit function to register new company user and set cookies
   * @author Fareed Aarif
   * @createat 17/10/2020
   */
    UpgradecheckComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    UpgradecheckComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.user.isemail = true;
        this.user.planid = 1;
        this.user.ctype = 1;
        // this.user.permissionstring = this.permissionstring;
        this.userService.userjoinnow(this.user).subscribe(function (data2) {
            if (data2.sussess == 'true') {
                _this.userService.userAuthentication(_this.user).subscribe(function (data) {
                    if (data.sussess == 'true') {
                        localStorage.setItem('Token', _this.access_token);
                        if (data.sussess == 'true') {
                            localStorage.setItem('Token', data.id);
                            // if (data.isemail == false) {
                            //   // localStorage.setItem('otp', data.otp);
                            // }
                            _this.errormsg = '';
                            _this.toggle('1');
                        }
                        else {
                            _this.alerts.error(data.error, true);
                        }
                    }
                });
            }
            else {
                _this.modalService.open('custom-errormsg-1');
                _this.errormsg = data2.error;
                _this.alerts.error(data2.error, true);
            }
        }, function (err) {
            _this.isLoginError = true;
        });
    };
    UpgradecheckComponent.prototype.scroll = function (el) {
        el.scrollIntoView({ behavior: 'smooth' });
    };
    UpgradecheckComponent.prototype.toggle = function (id) {
        if (id == 1) {
            this.wizard2 = true;
            this.wizard1 = false;
            this.router.navigate(['/approval-confirmation']);
        }
        this.show = !this.show;
        if (this.show)
            this.buttonName = "Hide";
        else
            this.buttonName = "Show";
    };
    UpgradecheckComponent.prototype.setStripeToken = function (token, formvalue, id) {
        var _this = this;
        this.submitted = true;
        if (this.addpopForm.invalid) {
            return;
        }
        var country = '';
        this.countrylist.forEach(function (element) {
            if (formvalue.countryid == element.countryid) {
                country = element.countryname;
            }
        });
        this.paynow = true;
        this.stptoken = token.id;
        var amunt = parseFloat('36.00');
        var data = {
            "token": token.id,
            "cmonth": token.card.exp_month,
            "cyear": token.card.exp_year,
            "cardnumber": token.card.last4,
            "tokenid": token.card.id,
            "cardname": token.card.brand,
            "country": token.card.country,
            "cstatus": "1",
            "amount": amunt,
            "address1": formvalue.address1,
            "address2": formvalue.address2,
            "cityname": formvalue.cityname,
            "statename": formvalue.statename,
            "countryid": formvalue.countryid,
            "cardcountry": country,
            "postcode": formvalue.pincode,
            "couponcode": formvalue.coupon,
            "taxid": formvalue.taxid
        };
        if (this.paymentSubmit == 0) {
            this.checkService.upgradeuserpayment(data).subscribe(function (payment3) {
                _this.document.location.href = payment3.url;
                // if(payment3.success == true)
                // {
                //     // this.toggle('1');
                //     localStorage.removeItem('ctype');
                //     localStorage.setItem('ctype', '1' );
                //     this.wizard2 = true;
                //     this.wizard1 = false;
                //     this.paymentSubmit == 0
                //     this.modalService.close('custom-payment-1'); 
                //     // this.router.navigate(['/check-dashboard']);
                //     // this.closeModal('custom-payment-1');
                // }else{
                //   this.paymentSubmit == 0
                //   this.alerts.error("The payment method has failed. Please try again after 30 seconds.");
                // }
            });
        }
        this.paymentSubmit = 1;
    };
    UpgradecheckComponent.prototype.updatepaymentwithoutuser = function (formvalue, id) {
        var _this = this;
        console.log('test master');
        this.submitted = true;
        this.paynow = true;
        var amunt = parseFloat('36.00');
        var data = {
            "cstatus": "1",
            "amount": amunt,
            "couponcode": formvalue.coupon,
        };
        if (this.paymentSubmit == 0) {
            this.checkService.upgradeuserpayment(data).subscribe(function (payment3) {
                if (payment3.success == true) {
                    // this.toggle('1');
                    localStorage.removeItem('ctype');
                    localStorage.setItem('ctype', '1');
                    _this.wizard2 = true;
                    _this.wizard1 = false;
                    _this.modalService.close('custom-payment-2');
                }
                else {
                    _this.document.location.href = payment3.url;
                    // console.log(payment3);
                    // this.paynow = false;         
                    // this.wizard2 = true;         
                    // this.paymentaut = false;
                    // this.paymentloading = false;
                    // console.log(payment3.url);
                    // this.iframepaymentautsrc = this.getSafeUrl(payment3.url);
                    // this.modalService.open('custom-payment-25');
                    // var xy=null;
                    // var refreshId =  setInterval(() => {
                    //   var data = { 'invoiceid': payment3.paymentint };
                    //   this.companyService.getpaymentintent(data).subscribe((invoiceData: any) => {
                    //     console.log(invoiceData);
                    //     console.log(invoiceData.status);
                    //     if(invoiceData.status != 'requires_source_action' && invoiceData.status != 'processing')
                    //       {
                    //         if(invoiceData.status =='succeeded')
                    //         {
                    //           this.companyService.Creditchecksecurecard(data).subscribe((invoiceData: any) => {                  
                    //             localStorage.removeItem('ctype');
                    //             localStorage.setItem('ctype', '1' );
                    //             this.wizard2 = true;
                    //             this.wizard1 = false;
                    //             this.modalService.close('custom-payment-25'); 
                    //             this.modalService.close('custom-payment-2'); 
                    //           this.paymentSubmit=0;
                    //           this.paymentloading = false;
                    //           // this.toggle('1');
                    //           // this.wizard2 = true;
                    //           // this.wizard1 = false;
                    //         //   if(this.ctype == '1')
                    //         //     this.router.navigate(['/check-dashboard']);
                    //         //  else if(this.ctype == '2')
                    //         //     this.router.navigate(['/check-dashboard']);
                    //         //  else if(this.ctype == '3')
                    //         //     this.router.navigate(['/reference-dashboard']);
                    //           });
                    //           clearInterval(refreshId);
                    //         }
                    //     }                      
                    //   });       
                    // }, 5000);  
                    // this.alerts.error("The payment method has failed. Please try again after 30 seconds.");
                }
            });
        }
        this.paymentSubmit = 1;
    };
    Object.defineProperty(UpgradecheckComponent.prototype, "g", {
        get: function () { return this.addpopForm.controls; },
        enumerable: true,
        configurable: true
    });
    UpgradecheckComponent.prototype.openModal = function (id) {
        this.addresslistDetail = new address_model_1.Address();
        this.addresslistDetail.countryid = "70";
        this.modalService.open(id);
    };
    UpgradecheckComponent.prototype.closeModal = function (id) {
        this.paymentSubmit = 1;
        this.modalService.close(id);
    };
    UpgradecheckComponent.prototype.paymentstripe = function (id) {
        this.paynow = false;
        this.modalService.open(id);
    };
    UpgradecheckComponent.prototype.checkCoupon = function (coupon) {
        var _this = this;
        this.checkService.checkCoupon({ couponcode: coupon }).subscribe(function (couponResp) {
            if (couponResp.success) {
                _this.coupon_applied = true;
                _this.couponerror = false;
                _this.discount = couponResp.amountoff;
            }
            else {
                _this.coupon_applied = false;
                _this.couponerror = true;
            }
        });
        console.log(coupon);
    };
    Object.defineProperty(UpgradecheckComponent.prototype, "coupon", {
        get: function () { return this.addpopForm.get('coupon'); },
        enumerable: true,
        configurable: true
    });
    UpgradecheckComponent.prototype.cancelCoupon = function () {
        this.coupon.reset();
        this.coupon_applied = false;
    };
    UpgradecheckComponent.prototype.onStripeError = function (error) {
        console.error('Stripe error', error);
    };
    UpgradecheckComponent.prototype.logout = function () {
        var _this = this;
        if (confirm("Are you sure you want to sign out?")) {
            this.userService.logout().subscribe(function (data) {
                localStorage.removeItem('userToken');
                localStorage.removeItem('userid');
                localStorage.removeItem('Name');
                localStorage.removeItem('isline');
                localStorage.removeItem('planid');
                localStorage.removeItem('isemail');
                localStorage.removeItem('isadmin');
                localStorage.removeItem('ispermission');
                localStorage.removeItem('logoname');
                localStorage.removeItem('menu');
                _this.router.navigate(['/login']);
            });
            ;
        }
    };
    return UpgradecheckComponent;
}());
exports.UpgradecheckComponent = UpgradecheckComponent;
