<app-header>
</app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <a [routerLink]="['/director-search']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Director Search</a>

            <div class="clearfix"></div>
            <div class="tabs__content">
                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Search History</h5>
                            </div>
                        </div>
                        <div *ngFor="let sr of searchresult; let i=index;">
                        <div class="credit_data_showlog " >
                            <div class="job_list_section visa_request_section">

                              <div class="job_detils_date width13">
                                <p class="job_address">Director Name</p>
                                <p class="job_profile">{{ sr.searchresult.name }}</p>
                              </div>

                                <div class="job_detils_date width60">
                                    <div class="visa_user_details ">
                                        <p class="job_address">Date Of Birth</p>

                                        <h4 class="job_profile" *ngIf="sr.searchresult.date_of_birth">
                                            {{sr.searchresult.date_of_birth.month+'/01/'+ sr.searchresult.date_of_birth.year | date: 'MMMM yyyy'}}
                                         </h4>
                                    </div>
                                </div>

                                <div class="job_detils_date width20">
                                  <button type="button" class="btn btn-primary com_btn" [innerHtml]="downloadtext[i]" (click)="downloaddirectordata(sr.searchcondition,i)"></button>
                                    <a type="submit" class="btn btn-primary com_btn" [routerLink]="['/director-search', sr.searchcondition ]">View Details</a>
                                </div>


                            </div>

                        </div>
                        </div>
                        <div class="row" *ngIf="searchresult">
                            <div class="col-lg-12 text-left" *ngIf="searchresult.length == 0">
                                <div class="billed-to">No Record Found </div>
                            </div>
                        </div>

                    </div>
                        <div class="browser-screen-loading-content" *ngIf="!searchresult" >
                            <div class="loading-dots dark-gray">
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                            </div>
                            <p>Loading</p>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>
