import { Component, OnInit } from '@angular/core';
import { ExpenseService } from '../../services/expense.service';
import { ModalService } from '../../_services/modal.service';
import { Router } from '@angular/router';
import { AppGlobals } from '../../app.global';


@Component({
  selector: 'app-expensereport',
  templateUrl: './expensereport.component.html',
  styleUrls: ['./expensereport.component.css']
})
export class ExpensereportComponent implements OnInit {
  items: Array<any>;
  pageOfItems: Array<any>;
  notedetails : any;
  data: any [];
  Userid: any;
  month: any;
  year: any;
  yearmonth: any;
  classactive: string;
  record: any;
  monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];
  yearList: any;

  constructor(
    private dataset: ExpenseService,
    private router: Router,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.yearList = AppGlobals.YEAR_VALUES;
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    this.month = (new Date()).getMonth()+1;
    this.year =  (new Date()).getFullYear();
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ (new Date()).getFullYear();
    this.classactive = ((new Date()).getMonth()+1).toString();  
    this.bindalldata();  
  }

  bindalldata()
  {    
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    this.dataset.getemployeeexpensereport(data).subscribe((data: any) => {
      this.record = data.length;
      if(this.record < 30){
        this.pageOfItems = data;
      }
      else{
           this.items =data;
      }
     });
  }

  getDataByMonth(mon)
  {
    this.classactive =mon;
    this.month = mon;
    this.yearmonth = this.monthNames[mon -1 ] +' '+ (new Date()).getFullYear();
    // this.year =  this.monthNames[this.month -1 ] +' '+ this.year;
    this.bindalldata();
  }

  onyearSubmit(leave) {
    // this.year =  this.monthNames[this.month -1 ] +' '+ leave.year;
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ (new Date()).getFullYear();
    this.year = leave;
    this.bindalldata();
    // this.modalService.close(id);
  }

  yearpop(id: string){
    this.modalService.open(id);
  }

  closeModal(id: string){
    this.modalService.close(id);
  }
  openModalnote (data:any,id:any){
    this.notedetails = data;
    this.modalService.open(id);
  }
  getDataByYear(yea)
  {    
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ yea;//(new Date()).getFullYear();
    // this.year =  this.monthNames[this.month -1 ] +' '+ yea;
    // this.year = yea;
    const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    this.dataset.getemployeeexpensereport(data).subscribe((data: any) => {
      this.record = data.length;
     this.data = data;
     if(this.record < 30){
      this.pageOfItems = data;
    }
    else{
         this.items =data;
    }
    });
  }
}