<div class="container width90"  >
    
  <div class="browser-screen-loading-content">
      <div class="loading-dots dark-gray">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>                                
          <i></i>
      </div>
      <p>Loading</p>
  </div>
</div>