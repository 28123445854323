<div class="bg">
    <app-header></app-header>

    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="admin_pro_section">
                    <div class="cseec">
                        <div class="admin_com_logo">
                            <div class="picture_chnge">
                                <!-- <i class="fa fa-camera"></i> -->
                                <input type="file" accept="image/*" (change)="selectFile($event)" style="display:none;" #file>
                                <a target="_blank" (click)="file.click()"><i class="fa fa-camera"></i></a>
                            </div>
                            <!-- <img src="../../../../../assets/img/user_pic_emp.png"> -->
                            <img id="ContentPlaceHolder1_imgDemoLogo" src="{{ companyprofileDetail.logoname }}" *ngIf="companyprofileDetail.logoname != ''">
                            <img id="ContentPlaceHolder1_imgDemoLogo" src="../../../assets/img/demologo.png" *ngIf="companyprofileDetail.logoname == '' || companyprofileDetail.logoname == null ">
                        </div>
                    </div>
                    <div class="admin_com_details">
                        <div class="pro_com_name">
                            <h4>{{ companyprofileDetail.companyname }}</h4>
                            <button *ngIf="profileperm > 2" (click)="openModal('custom-company-1')" class="btn btn-danger" type="submit">Edit Profile</button>
                        </div>
                        <hr>
                        <div class="pro_com_add" *ngFor="let location of companylocationlist">
                            <div *ngIf="location.isdefault==true">
                                <span>Address</span>
                                <p> <span *ngIf="location.address2 != ''">{{location.address2}}</span> {{location.address1}} ,{{location.cityname}}, {{location.statename}} , {{location.country.countryname}}, {{location.pincode}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="admin_pro_footer">
                        <div class="pro_footer_content">
                            <label><i class="fa fa-globe" aria-hidden="true"></i> Website</label>
                            <p class="link">
                                <a target="_blank" href="http://{{weburl }}">{{companyprofileDetail.websiteurl}}</a>
                            </p>
                        </div>
                        <div class="pro_footer_content">
                            <label><i class="fa fa-envelope-o" aria-hidden="true"></i> Email</label>
                            <p>
                                <a class="alignemail" href="mailto:{{ companyprofileDetail.emailid }}">{{ companyprofileDetail.emailid }}</a>
                                <a class="alignemail" href="mailto:{{ companyprofileDetail.secondryemailid }}">{{
                            companyprofileDetail.secondryemailid }}</a>
                            </p>
                        </div>
                        <div class="pro_footer_content">
                            <label><i class="fa fa-mobile" aria-hidden="true"></i> Mobile No.</label>
                            <p>{{companyprofileDetail.phonenumber}}</p>
                        </div>
                        <div class="pro_footer_content">
                            <label><i class="fa fa-phone" aria-hidden="true"></i> Phone No.</label>
                            <p>{{companyprofileDetail.sphonenumber}}</p>
                        </div>
                    </div>
                </div>
                <div class="white_box_shadow pro_plan_details admin_pro_footer" *ngIf="ctype == 0">
                    <h4>Plan Detail</h4>
                    <div class="pro_footer_content">
                        <label><i class="fa fa-calendar" aria-hidden="true"></i> Start Date</label>
                        <p> {{planStartDate | date}}</p>
                    </div>
                    <div class="pro_footer_content">
                        <label><i class="fa fa-user-o" aria-hidden="true"></i> Plan Name</label>
                        <p *ngIf="companyprofileDetail.plan">{{companyprofileDetail.plan.planname}} </p>
                    </div>
                    <div class="pro_footer_content">
                        <label><i class="fa fa-calendar" aria-hidden="true"></i>  Payment Due On</label>
                        <p>{{planExpiredOn | date}}</p>
                    </div>
                    <div class="pro_footer_content">
                        <label><i class="fa fa-calendar" aria-hidden="true"></i>  Renewal Date</label>
                        <p> {{planRenewDate | date}}</p>
                    </div>
                </div>
                <div class="white_box_shadow pro_licence_details admin_pro_footer" *ngIf="companyprofileDetail.license && ctype == 0">
                    <h4>Licence Details</h4>
                    <div class="pro_footer_content">
                        <label>Licence No.</label>
                        <p class="link" *ngIf="companyprofileDetail.license.licensenumber">{{companyprofileDetail.license.licensenumber}}</p>
                    </div>
                    <div class="pro_footer_content">
                        <label><i class="fa fa-calendar" aria-hidden="true"></i>  Application Date</label>
                        <p *ngIf="companyprofileDetail.license.applicationdate"> {{ companyprofileDetail.license.applicationdate | date }}</p>
                    </div>
                    <div class="pro_footer_content">
                        <label><i class="fa fa-calendar" aria-hidden="true"></i>  Approval Date</label>
                        <p *ngIf="companyprofileDetail.license.approvaldate">{{ companyprofileDetail.license.approvaldate | date }}</p>
                    </div>
                    <div class="pro_footer_content">
                        <label><i class="fa fa-calendar" aria-hidden="true"></i>  Renewal Date</label>
                        <p *ngIf="companyprofileDetail.license.cexpiredate">{{ companyprofileDetail.license.cexpiredate | date }}</p>
                    </div>
                </div>

                <div class="clearfix"></div>
                <div class="tabs__content" *ngIf="ctype == 0">
                    <div class="tabs__content__item active-tab nohight">
                        <div class="content" style="min-height: auto;">
                            <div class="card-block">
                                <div class="row" style="margin-top: 30px;">
                                </div>

                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="mini-stat clearfix bg-white gfdr" style="background-color:#a154b4 !important;">
                                            <a [routerLink]="['/listjob']">
                                                <span class="mini-stat-icon bg-teal mr-0 float-left bg-white" style="background-color:#8636a7 !important;">
                                                    <span class="counter text-purple">{{countcompanydata.job}}</span>
                                                </span>
                                                <div class="mini-stat-info text-white text-center">Jobs</div>
                                                <div class="clearfix"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="mini-stat clearfix bg-white gfdr" style="background-color:#308fdf !important;">
                                            <a [routerLink]="['/listjob']">
                                                <span class="mini-stat-icon bg-teal mr-0 float-left bg-white" style="background-color:#1f72b8 !important;">
                                                    <span class="counter text-purple">{{countcompanydata.candidate}}</span>
                                                </span>
                                                <div class="mini-stat-info text-white  text-center">
                                                    Applicants
                                                </div>
                                                <div class="clearfix"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="mini-stat clearfix bg-white gfdr" style="background-color:#1bbc9d !important;">
                                            <a [routerLink]="['/employeelist']">
                                                <span class="mini-stat-icon bg-teal mr-0 float-left bg-white" style="background-color:#0ca18e !important;">
                                                    <span class="counter text-purple">{{countcompanydata.employee}}</span>
                                                </span>
                                                <div class="mini-stat-info text-white text-center">
                                                    Employees
                                                </div>
                                                <div class="clearfix"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="mini-stat clearfix bg-white gfdr" style="background-color:#a154b4 !important;">
                                            <a [routerLink]="['/clientlist']">
                                                <span class="mini-stat-icon bg-teal mr-0 float-left bg-white" style="background-color:#8636a7 !important;">
                                                    <span class="counter text-purple">{{countcompanydata.client}}</span>
                                                </span>
                                                <div class="mini-stat-info text-white text-center">
                                                    Clients
                                                </div>
                                                <div class="clearfix"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="mini-stat clearfix bg-white gfdr" style="background-color:#308fdf !important;">
                                            <a [routerLink]="['/projectlist']">
                                                <span class="mini-stat-icon bg-teal mr-0 float-left bg-white" style="background-color:#1f72b8 !important;">
                                                    <span class="counter text-purple">{{countcompanydata.project}}</span>
                                                </span>
                                                <div class="mini-stat-info text-white text-center">
                                                    Projects
                                                </div>
                                                <div class="clearfix"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="mini-stat clearfix bg-white gfdr" style="background-color:#1bbc9d !important;">
                                            <a [routerLink]="['/admin-list']">
                                                <span class="mini-stat-icon bg-teal mr-0 float-left bg-white" style="background-color:#0ca18e !important;">
                                                    <span class="counter text-purple">1</span>
                                                </span>
                                                <div class="mini-stat-info text-white text-center">
                                                    Sub Admin
                                                </div>
                                                <div class="clearfix"></div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="row">
                    <div class="col-sm-6">
                        <div>
                            <div style="display: block">
                              <canvas baseChart
                                      [datasets]="barChartData"
                                      [labels]="barChartLabels"
                                      [options]="barChartOptions"
                                      [legend]="barChartLegend"
                                      [chartType]="barChartType">
                              </canvas>
                            </div>
                          </div>
                    </div>
                </div> -->
                            </div>
                        </div>
                        <!-- col-8 -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<jw-modal id="custom-company-1" role="dialog" class="modal">

    <form [formGroup]="profileformGroup" #profileform="ngForm" class="s12 white" (ngSubmit)="onSubmit(profileform.value,'custom-company-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="popupLabel">Profile</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-company-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row row-sm">
                            <div class="col-lg-12">
                                <div role="document" class="formgroup">
                                    <input type="hidden" formControlName="companylicensedetailsid" class="form-control" name="companylicensedetailsid" id="companylicensedetailsid" ngModel="{{companyprofileDetail.license.companylicensedetailsid}}">
                                    <input type="hidden" formControlName="companyid" class="form-control" name="companyid" id="companyid" ngModel="{{companyprofileDetail.companyid}}" />
                                    <div class="row">
                                        <div class="form-group col-lg-12">
                                            <label>Company Name*</label>
                                            <input type="text" formControlName="companyname" class="" name="companyname" id="companyname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.companyname.errors }" ngModel="{{ companyprofileDetail.companyname }}">
                                            <div *ngIf="submitted && f.companyname.errors" class="invalid-feedback">
                                                <div *ngIf="f.companyname.errors.required">Please enter company name</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-lg-6">
                                            <label>Company Type</label>
                                            <select  formControlName="companytypeid" name="companytypeid" id="socid" class="form-control " [ngClass]="{ 'is-invalid': submitted && f.companytypeid.errors }" ngModel="{{ companyprofileDetail.companytypeid }}">
                                                <option value="">Select Company Type </option>
                                                <option *ngFor="let companytype of companytypelist" value='{{ companytype.companytypeid }}'
                                                [selected]="companytype.companytypeid == companyprofileDetail.companytypeid">
                                                {{ companytype.companycode }} - {{ companytype.typename }}</option>
                                            </select>
                                            <div *ngIf="submitted && f.companytypeid.errors" class="invalid-feedback">
                                                <div *ngIf="f.companytypeid.errors.required">Please select company type</div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-6">
                                            <label>Web URL </label>
                                            <input type="text" formControlName="websiteurl" class="" name="websiteurl" id="websiteurl" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.websiteurl.errors }" ngModel="{{ companyprofileDetail.websiteurl }}">
                                            <div *ngIf="submitted && f.websiteurl.errors" class="invalid-feedback">
                                                <div *ngIf="f.websiteurl.errors.required">Please enter url</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">

                                        <div class="form-group col-lg-6">
                                            <label>Contact Name*</label>
                                            <input type="text" formControlName="clientname" class="" name="clientname" id="clientname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.clientname.errors }" ngModel="{{ companyprofileDetail.clientname }}">
                                            <div *ngIf="submitted && f.clientname.errors" class="invalid-feedback">
                                                <div *ngIf="f.clientname.errors.required">Please enter contact name</div>
                                            </div>
                                        </div>

                                        <div class="form-group col-lg-6">
                                            <label>Role*</label>
                                            <input type="text" formControlName="clietdesignation" class="" name="clietdesignation" id="clietdesignation" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.clietdesignation.errors }" ngModel="{{ companyprofileDetail.clietdesignation }}"
                                                ngModel="{{ companyprofileDetail.clietdesignation }}">
                                            <div *ngIf="submitted && f.clietdesignation.errors" class="invalid-feedback">
                                                <div *ngIf="f.clietdesignation.errors.required">Please enter role</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-lg-6">
                                            <label>Email*</label>
                                            <input type="email" formControlName="emailid" class="" name="emailid" id="emailid" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }" ngModel="{{ companyprofileDetail.emailid }}" ngModel="{{ companyprofileDetail.emailid }}">
                                            <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                                                <div *ngIf="f.emailid.errors.required">Please enter email </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-6">
                                            <label>Alternative Email </label>
                                            <input type="email" formControlName="secondryemailid" class="" name="secondryemailid" id="secondryemailid" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.secondryemailid.errors }" ngModel="{{ companyprofileDetail.secondryemailid }}" ngModel="{{ companyprofileDetail.secondryemailid }}">
                                            <div *ngIf="submitted && f.secondryemailid.errors" class="invalid-feedback">
                                                <div *ngIf="f.secondryemailid.errors.required">Please enter email </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-lg-6">
                                            <label>Mobile Number*</label>
                                            <international-phone-number formControlName="phonenumber" class="form-control phonecontrol" [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" [(ngModel)]="companyprofileDetail.phonenumber" placeholder="Enter mobile number" (input)="validatenumber($event.target.value,'mobile')"
                                                [maxlength]="20" [required]="true" #phonenumber name="phonenumber"></international-phone-number>
                                            <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                                <div *ngIf="f.phonenumber.errors.required">Please enter mobile number</div>
                                            </div>
                                            <span *ngIf="mobilecheck==false" style="color:red">Invalid format</span>
                                            <span *ngIf="mobilecheck==true" style="color:green">Valid format</span>
                                        </div>

                                        <div class="form-group col-lg-6">
                                            <label>Phone Number</label>
                                            <international-phone-number formControlName="sphonenumber" class="form-control phonecontrol" [ngClass]="{ 'is-invalid': submitted && f.sphonenumber.errors }" [(ngModel)]="companyprofileDetail.sphonenumber" placeholder="Enter mobile number" (input)="validatenumber($event.target.value,'phone')"
                                                [maxlength]="20" #sphonenumber name="sphonenumber"></international-phone-number>
                                            <div *ngIf="submitted && f.sphonenumber.errors" class="invalid-feedback">
                                                <div *ngIf="f.sphonenumber.errors.required">Please enter phone number</div>
                                            </div>
                                            <span *ngIf="phonecheck==false" style="color:red">Invalid format</span>
                                            <span *ngIf="phonecheck==true" style="color:green">Valid format</span>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-lg-6">
                                            <label>Licence Number </label>
                                            <input type="text" formControlName="licensenumber" class="" name="licensenumber" id="licensenumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.licensenumber.errors }" ngModel="{{ companyprofileDetail.license.licensenumber }}" ngModel="{{ companyprofileDetail.license.licensenumber }}">
                                            <div *ngIf="submitted && f.licensenumber.errors" class="invalid-feedback">
                                                <div *ngIf="f.licensenumber.errors.required">Please enter licence number </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-6">
                                            <label>Application Date</label>
                                            <mat-form-field class="datecontrol">
                                                <input matInput [matDatepicker]="picker1" name="applicationdate" id="applicationdate" formControlName="applicationdate" [max]="currentDate" class="form-control" ngModel='{{ companyprofileDetail.license.applicationdate }}' [value]="companyprofileDetail.license.applicationdate">
                                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                            </mat-form-field>
                                            <div *ngIf="submitted && f.applicationdate.errors" class="invalid-feedback">
                                                <div *ngIf="f.applicationdate.required">Choose application date</div>
                                            </div>
                                            <!--<mat-datepicker #picker></mat-datepicker>-->
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-lg-6">
                                            <label>Approval Date</label>
                                            <mat-form-field class="datecontrol">
                                                <input matInput [matDatepicker]="picker2" name="approvaldate" id="approvaldate" formControlName="approvaldate" [max]="currentDate" class="form-control" ngModel='{{ companyprofileDetail.license.approvaldate }}' [value]="companyprofileDetail.license.approvaldate">
                                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                <mat-datepicker #picker2></mat-datepicker>
                                            </mat-form-field>
                                            <div *ngIf="submitted && f.approvaldate.errors" class="invalid-feedback">
                                                <div *ngIf="f.approvaldate.errors.required">Choose approval date</div>
                                            </div>
                                            <!--<mat-datepicker #picker></mat-datepicker>-->
                                        </div>
                                        <div class="form-group col-lg-6">
                                            <label>Expiry Date</label>
                                            <mat-form-field class="datecontrol">
                                                <input matInput [matDatepicker]="picker3" id="cexpiredate" formControlName="cexpiredate" name="cexpiredate" [min]="profileformGroup.controls.approvaldate.value" class="form-control" ngModel='{{ companyprofileDetail.license.cexpiredate }}' [value]="companyprofileDetail.license.cexpiredate">
                                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                                <mat-datepicker #picker3></mat-datepicker>
                                            </mat-form-field>
                                            <div *ngIf="submitted && f.cexpiredate.errors" class="invalid-feedback">
                                                <div *ngIf="f.cexpiredate.errors.required">Choose expiry date</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-lg-6">
                                            <label>Financial Month*</label>
                                            <select  formControlName="payrollmonth" name="payrollmonth" id="payrollmonth" class="form-control" ngModel="{{ companyprofileDetail.payrollmonth }}">
                                                <option value="">Select Financial Month</option>
                                                <option *ngFor="let month of monthList" value='{{ month.key }}'>{{ month.value }}</option>
                                            </select>
                                            <div *ngIf="submitted && f.payrollmonth.errors" class="invalid-feedback">
                                                <div *ngIf="f.payrollmonth.errors.required">Choose financial month</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-company-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>