"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../_services/modal.service");
var department_service_1 = require("../../../services/department.service");
var forms_1 = require("@angular/forms");
var department_model_1 = require("../../../models/setting/department.model");
var router_1 = require("@angular/router");
var SettingdepartmentComponent = /** @class */ (function () {
    function SettingdepartmentComponent(modalService, departmentService, router, formBuilder) {
        this.modalService = modalService;
        this.departmentService = departmentService;
        this.router = router;
        this.formBuilder = formBuilder;
        this.departmentDetail = new department_model_1.Department();
        this.options = [];
        this.submitted = false;
    }
    SettingdepartmentComponent.prototype.ngOnInit = function () {
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.settingperm = this.permission.m81;
        this.Userid = localStorage.getItem('userid');
        this.departmentDetail = new department_model_1.Department();
        this.departmentformGroup = this.formBuilder.group({
            id: [''],
            typename: ['', forms_1.Validators.required]
        });
        this.AllData();
    };
    SettingdepartmentComponent.prototype.AllData = function () {
        var _this = this;
        var sstatustype = { 'status': false, 'id': this.Userid };
        this.departmentService.getdepartmentlistbyadmin(sstatustype).subscribe(function (data) {
            _this.data = data;
            _this.record = _this.data.length;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    Object.defineProperty(SettingdepartmentComponent.prototype, "f", {
        get: function () { return this.departmentformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    SettingdepartmentComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    SettingdepartmentComponent.prototype.onSubmit = function (formdepartment, id) {
        var _this = this;
        // var formdepartment = departmentdata.value;
        this.submitted = true;
        if (this.departmentformGroup.invalid) {
            return;
        }
        this.disableBtn = false;
        formdepartment.userid = this.Userid;
        this.departmentService.settingdepartmentUpdate(formdepartment).subscribe(function (response) {
            _this.AllData();
            _this.modalService.close(id);
        });
    };
    SettingdepartmentComponent.prototype.onChangeStatus = function (evt, dep) {
        var _this = this;
        var cstatus;
        if (evt.target.checked) {
            cstatus = '1';
        }
        else if (!evt.target.checked) {
            cstatus = '0';
        }
        this.departmentService.departmentUpdateChangeStatus({ 'userid': this.Userid, 'id': dep.id, 'cstatus': evt.target.checked }).subscribe(function (data) {
            _this.AllData();
        });
    };
    SettingdepartmentComponent.prototype.openModal = function (id, data) {
        this.buttoninsert = "Update";
        this.disableBtn = true;
        this.departmentDetail = data;
        this.departmentformGroup.reset(this.departmentDetail);
        this.modalService.open(id);
    };
    SettingdepartmentComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.departmentDetail = new department_model_1.Department();
        this.departmentDetail.id = "0";
        this.departmentformGroup.reset(this.departmentDetail);
        this.modalService.open(id);
    };
    SettingdepartmentComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return SettingdepartmentComponent;
}());
exports.SettingdepartmentComponent = SettingdepartmentComponent;
