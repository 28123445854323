<div class="bg">
    <app-header></app-header>


    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <a [routerLink]="['/request/request-list']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Request List</a>
                <div class="clearfix"></div>
                <div class="tabs__content">

                    <div class="tabs__content__item active-tab">
                        <div class="content">


                            <div class="space_em row">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Expense Request <span class="gray_text"> ({{yearmonth}}) </span> </h5>

                                </div>
                                <div class="col-lg-6">
                                    <div class="input-group">
                                        <select name="year" id="year" class="form-control input-lg spaceright" ngModel='{{ year }}' (change)="onyearSubmit($event.target.value)">
                                            <option value="{{y}}" *ngFor="let y of yearList">{{ y }}</option>
                                        </select>
                                        <select name="hstatus" class="form-control input-lg spaceright" id="hstatus" (change)="onChangestatus($event.target.value)">
                                  <option value="" [selected]="true">Select Status</option>
                                  <option value="1">Pending</option>
                                  <option value="2">Approved</option>
                                  <option value="3">Rejected</option>
                                </select>
                                        <select name="employeeid" class="form-control input-lg" id="employeeid" (change)="onChange($event.target.value)">
                                  <option value="">Select Employee</option>
                                  <option *ngFor="let ad3 of employeelist" value="{{ ad3.employeemasterid}}">{{ ad3.fname }} {{ ad3.mname }} {{
                                    ad3.lname }}</option>
                                </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <ul class="tabs__head montharea">
                                        <li><a [ngClass]="{'active': classactive == '1'}" (click)="getDataByMonth(1);">January </a></li>
                                        <li><a [ngClass]="{'active': classactive == '2'}" (click)="getDataByMonth(2);">February </a></li>
                                        <li><a [ngClass]="{'active': classactive == '3'}" (click)="getDataByMonth(3);">March </a></li>
                                        <li><a [ngClass]="{'active': classactive == '4'}" (click)="getDataByMonth(4);">April </a></li>
                                        <li><a [ngClass]="{'active': classactive == '5'}" (click)="getDataByMonth(5);">May</a></li>
                                        <li><a [ngClass]="{'active': classactive == '6'}" (click)="getDataByMonth(6);">June </a></li>
                                        <li><a [ngClass]="{'active': classactive == '7'}" (click)="getDataByMonth(7);">July </a></li>
                                        <li><a [ngClass]="{'active': classactive == '8'}" (click)="getDataByMonth(8);">August </a></li>
                                        <li><a [ngClass]="{'active': classactive == '9'}" (click)="getDataByMonth(9);">September </a></li>
                                        <li><a [ngClass]="{'active': classactive == '10'}" (click)="getDataByMonth(10);">October </a></li>
                                        <li><a [ngClass]="{'active': classactive == '11'}" (click)="getDataByMonth(11);">November </a></li>
                                        <li><a [ngClass]="{'active': classactive == '12'}" (click)="getDataByMonth(12);">December </a></li>
                                    </ul>
                                </div>
                            </div>




                            <div class="job_list_section employee_list_section leave_section" *ngFor="let expense of data;  let i=index">

                                <div class="job_detils_date width20">
                                    <h5 class="job_profile font_17">{{expense.employee.fname}} {{expense.employee.mname}} {{expense.employee.lname}}</h5>
                                    <p class="email"><i class="fa fa-envelope-o"></i> {{ expense.employee.emailid }}</p>
                                    <p class="phone"> <i class="fa fa-phone"></i> {{ expense.employee.phonenumber }}</p>
                                    <p class="ninumber"> <i class="fa fa-bars"></i> NI {{ expense.employee.ninumber }}</p>
                                    <span class="badge badge-success" *ngIf="expense.employee.nationality">{{ expense.employee.nationality.countryname }}</span>
                                </div>
                                <div class="job_detils_date width7">
                                    <p class="job_address">Amount</p>
                                    <span class="badge badge-pill badge-danger"> {{expense.amountpaid}}  {{expense.currency}} </span>
                                </div>

                                <div class="job_detils_date width20">
                                    <p class="job_address">Project Name</p>
                                    <h5 class="job_profile font_17">{{expense.project.projectname}} </h5>
                                    <span class="badge badge-pill badge-secondary" *ngIf="!expense.project">N/A</span>

                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address">Expense Type</p>
                                    <h5 class="job_profile font_17" *ngIf="expense.expensetype"> {{expense.expensetype.typename}}</h5>
                                    <span class="badge badge-pill badge-secondary" *ngIf="!expense.expensetype">N/A</span>


                                </div>
                                <div class="job_detils_date width7">
                                    <p class="job_address">Date</p>
                                    <h5 class="job_profile font_17"> {{expense.startdate | date: 'dd MMM yyyy'}}</h5>



                                </div>
                                <div class="job_detils_date width7 text-center">
                                    <p class="job_address">Document</p>
                                    <i class="fa fa-file-pdf-o text-danger" (click)="openModal3('pdf-image-viewer',expense.imagename)" *ngIf="expense.imagename"></i>
                                    <span class="badge badge-pill badge-secondary" *ngIf="!expense.imagename">NO Doc</span>
                                </div>
                                <div class="job_btn visa_btn width10">
                                    <span class="text-danger" *ngIf="expense.hstatus == '3'">Rejected</span>

                                    <span class="text-success" *ngIf="expense.hstatus == '2'">Approved</span>
                                    <div class="dropdown" *ngIf="expense.hstatus == '1'">
                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Action <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" (click)="onStatus(expense.employeeexpenseapplyid, 2);">Approve</a>
                                            <a class="dropdown-item" (click)="onStatus(expense.employeeexpenseapplyid, 3);">Reject</a>

                                        </div>
                                    </div>




                                </div>
                                <div class="job_detils_date width7">
                                    <p class="job_address ">
                                        <button type="submit" class="btn btn-info com_btn" (click)="openModalnote(expense.remark.expenseremark,'note-leave-report')"> 
                                            <div class="tooltip_visa width73px"><p>Message </p></div>
                                            <i class="fa fa-comments-o "></i>
                                        </button>
                                    </p>

                                </div>
                            </div>
                            <div class="job_list_section employee_list_section leave_section" *ngIf="record == 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                                
                            </div>
                            <!-- <div class="col-lg-12 text-right">
                                <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                            </div> -->


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<jw-modal id="pdf-image-viewer" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer');">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="500" frameborder="0"></iframe>
                            <img *ngIf="imgsrc == true" [src]="iframesrc" style="width:100%;" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="note-leave-report" class="modal " role="dialog ">
    <div role="document" class="formgroup ">
        <div class="modal-content ">
            <div class="modal-header ">
                <h5 class="modal-title ">Notes</h5>
                <button type="button " class="close " data-dismiss="modal " name="close " aria-label="Close " (click)="closeModal( 'note-leave-report'); ">
                  <span aria-hidden="true ">&times;</span>
                  </button>
            </div>
            <div class="modal-body ">
                <div class="container ">
                    <div class="job_profile">
                        <div class="">
                            <p><b>Note :</b> {{notedetails}}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer ">

                <button type="button " class="btn btn-secondary com_btn " data-dismiss="modal " (click)="closeModal( 'note-leave-report');">Close</button>
            </div>
        </div>
    </div>
</jw-modal>