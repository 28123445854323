import { Component, OnInit } from '@angular/core';
import { CountryService } from '../../../services/country.service';
import { MyvisaService } from '../../../services/myvisa.service';
import { ModalService } from '../../../_services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Visa } from '../../../models/visa/visa.model';
import { UploadFileService } from './../../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
// import { Router } from '@angular/router';
import { Router, ActivatedRoute, Params } from '@angular/router';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-visalist',
  templateUrl: './visalist.component.html',
  styleUrls: ['./visalist.component.css'] 
})
export class VisalistComponent implements OnInit {
  imgvisabacksrc: any;
   iframevisabacksrc: any;
  visalistbyadmin: any[]; 
  visaDetail: any;
  countrylist: any;
  Userid: any;
  listcheck: any;
  selectedFiles: FileList;
  iframevisasrc: any;
  imgvisasrc: any;
  buttoninsert: any;
  filesizeerror: any;
  fileformaterror: any;
  file: any;
  filename: any;

  filerequirederror: any;
  filebackrequirederror: any;
  
  filebacksizeerror: any;
  filebackformaterror: any;
  selectedbackFiles: FileList;
  filebackname: any;


  empid: any;
  defaultVal: any = "";

  filterItems = [
    {
      name: 'All',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Pending Approval',
      id: 1,
      selected: false
    }
  ]
  submitted: any;
  visapopForm: FormGroup;
  visa: Visa;
  disableBtn: any;
  
  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private data: MyvisaService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private router: Router,
    private activatedRoute: ActivatedRoute, 
    private alerts: AlertService
    ) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    this.empid = this.activatedRoute.snapshot.paramMap.get("id");
    this.visapopForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      visanumber: ['', Validators.required],
      visatype: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      visaexpiry: ['', Validators.required],
      visastart: ['', Validators.required],
      iscurrent: ['']
    });

    this.filerequirederror =false;
    this.filename = 'Choose file';
    this.filebackname = 'Choose file';
    this.visaDetail = new Visa();
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.empid, 'cstatus': [1, 2, 3] };

    this.data.getvisaAllListbyadmin(data).subscribe((data: any) => {
      this.visalistbyadmin = data;
    });

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.listcheck = this.filterItems;
  }


  onDelete(id: number) {
    const data1 = { 'id': id };
    if (confirm("Are you sure you want to delete?")) {
      this.data.deletevisa(data1).subscribe((data: any) => {
        var sstatus: any;
        for (let c of this.filterItems) {
          if (c.selected == true) {
            if (c.id == 0) {
              sstatus = [1, 2, 3];
            }
            else {
              sstatus = [c.id];
            }
          }
        }
        const data2 = { 'id': this.empid, 'cstatus': sstatus };
        this.data.getvisaAllListbyadmin(data2).subscribe((data: any) => {
          this.visalistbyadmin = data;
        });
        this.alerts.success('Visa delete successfully.', true);

      });
    }
  }

  onlistchange(type1: any, idval: number) {
    var sstatus: any;
    for (let c of this.filterItems) {
      if (c.id == idval)
        c.selected = true;
      else
        c.selected = false;
    }
    if (idval == 0 || type1 == false) {
      sstatus = [1, 2, 3];
    }
    else {
      sstatus = [idval];
    }
    const data = { 'id': this.empid, 'cstatus': sstatus };
    this.data.getvisaAllListbyadmin(data).subscribe((data: any) => {
      this.visalistbyadmin = data;
    });
  }

  get f() {
    return this.visapopForm.controls;
  }

  onSubmit(formvisa: any, id: string) {
    // var formvisa = value3.value;
    this.submitted = true;
    if (this.visapopForm.invalid) {
      if(formvisa.id == '0'){
        if(!this.selectedFiles)
          this.filerequirederror = true;
        // if(!this.selectedbackFiles)
        //   this.filebackrequirederror = true;
      }
      
      return;
    }

    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.fileformaterror = true;  this.filesizeerror = false;
        return;
      }else{
        this.filerequirederror =false; this.fileformaterror = false;  this.filesizeerror = false;

      }
      var imagepath = this.uploadService.uploadfile(this.file);
      formvisa.imagename = imagepath;
    }
    else {
      if(formvisa.id == '0')
      {
        this.filerequirederror = true;
        return;
      }
      formvisa.imagename = this.visaDetail.visadocname;
    }

    
    if (this.selectedbackFiles) {
      this.file = this.selectedbackFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filebacksizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.filebackformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      formvisa.imagebackname = imagepath;
    }
    else {
      // if(formvisa.id == '0')
      // {
      //   this.filebackrequirederror = true;
      //   return;
      // }
      if(formvisa.id == '0')
      {
        formvisa.imagebackname = '';
       }
     else {
      formvisa.imagebackname = this.visaDetail.visabackdocname;
     }
    
    }


    formvisa.createby = this.Userid;
    formvisa.updateby = this.Userid;


    
    const sd = new Date(formvisa.visastart);
    formvisa.visastart = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    const ed = new Date(formvisa.visaexpiry);
    formvisa.visaexpiry = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());



    this.disableBtn = false;
    this.data.myvisadetailsUpdatebyadmin(formvisa).subscribe((data: any) => {
      var sstatus: any;
      for (let c of this.filterItems) {
        if (c.selected == true) {
          if (c.id == 0) {
            sstatus = [1, 2, 3];
          }
          else {
            sstatus = [c.id];
          }
        }
      }
      const data1 = { 'id': this.empid, 'cstatus': sstatus };
      this.data.getvisaAllListbyadmin(data1).subscribe((data: any) => {
        this.visalistbyadmin = data;
      });
    });
    this.filename = "Choose file";
    this.filebackname = "Choose file";
    this.alerts.success('Visa Info Updated Successfully', true);

    this.modalService.close(id);
  }

  visaselectfile(event)
   {
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = files[0];

    this.file = this.selectedFiles;
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.fileformaterror = true;    
    }else {
      this.fileformaterror = false;
      this.filesizeerror = false;
      // this.selectedFiles = files[0]; 
    }
  }

  visaselectbackfile(event)
   {
    var files = event.target.files;
    this.filebackname = files[0].name;
    

    this.file = files[0];//this.selectedbackFiles;
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filebacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filebackformaterror = true;
      this.filebacksizeerror = false;    
    }
    else
    {
      this.filebackformaterror = false;
      this.filebacksizeerror = false;   
      this.selectedbackFiles = files[0]; 
    }
  }


  openModal2(id: string) 
  {
    this.submitted = false;
    this.disableBtn = true;
    this.filerequirederror =false;
    this.filebackrequirederror =false;
    // this.selectFile = null;
    // this.selectbackFile = null;
    this.buttoninsert = "Submit";
    this.visaDetail = new Visa();
    this.visaDetail.employeeid = this.empid;
    this.visaDetail.employeevisadeatilid = "0";    
    // this.visaDetail.visaexpiry ='';
    // this.visaDetail.visastart = '';
    // this.visaDetail.visatype = '';
    this.visaDetail.nationalityid = '';
    this.filename = "Choose file";
    this.filebackname = "Choose file";
    this.modalService.open(id);
    this.visaDetail.iscurrent = "0";
  }

  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModalvisa3(id: string, data: any, backimg: any) 
  {
    this.iframevisasrc ='';
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1].toLowerCase();
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgvisasrc = true;
      this.iframevisasrc = data;
    }
    else {
      this.imgvisasrc = false;
      this.iframevisasrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
     if(backimg)
    {
      var fullpathArray = backimg.split('.');
      var typeoffile = fullpathArray[fullpathArray.length - 1];
      if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
        this.imgvisabacksrc = true;
        this.iframevisabacksrc = backimg;
      }
      else { 
        this.imgvisabacksrc = false;
        this.iframevisabacksrc = this.getSafeUrl("https://docs.google.com/gview?url=" + backimg + "&embedded=true");
      }
    }
    else {
      this.imgvisabacksrc = false;
      this.iframevisabacksrc = '';
    }
    this.modalService.open(id);
    
  }

  openModal(id: string, data: any) {
    this.disableBtn = true;    
    // this.selectFile =null;
    // this.selectbackFile =null;
    this.visaDetail = data;    
    this.buttoninsert = "Update";
    this.modalService.open(id);
    this.visaDetail = data;
    if(data.iscurrent){
      this.visaDetail.iscurrent = "1";
    } else{
      this.visaDetail.iscurrent = "0";
    }
  }

  closeModal(id: string) 
  {
    this.visa = new Visa();
    this.visaDetail = new Visa();
    const data = { 'id': this.empid, 'cstatus': [1, 2, 3] };
    this.data.getvisaAllListbyadmin(data).subscribe((data: any) => {
      this.visalistbyadmin = data;
    });
    this.modalService.close(id);
  }

}
