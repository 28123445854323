import { Component, OnInit } from '@angular/core';
import { MyemploymentService } from '../../../services/myemployment.service';
import { ModalService } from '../../../_services/modal.service';
import { Working, Flexi } from '../../../models/employment/working.model';
import { Wemployee } from '../../../models/employment/wemployee.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFileService } from './../../../services/upload-file.service';
import { EmployeementService } from '../../../services/employeement.service';
import { EmployeeService } from '../../../services/employee.service';
import { ProjectService } from '../../../services/project.service';
import { ClientService } from '../../../services/client.service';
import { AlertService } from '../../../services/alert.service';
import { DepartmentService } from '../../../services/department.service';
import { DivisionService } from '../../../services/division.service';
import { CompanyService } from 'src/app/services/company.service';


@Component({
  selector: 'app-employementlist',
  templateUrl: './employementlist.component.html',
  styleUrls: ['./employementlist.component.css']
})
export class EmployementlistComponent implements OnInit {
  empltype: any;
  wemployeelist: any;
  employeedetails: any[];
  submitted: any;
  employmentForm: FormGroup;
  filename: any;
  filesizeerror: any;
  fileformaterror: any;
  filesconemerror: any;
  filesworkemerror: any;
  file: any;
  employeementlist: any[];
  departmentlist: any;
  divisiondetails: any;
  employeementDetail : Working;
  empid: any;
  projectlist: any;
  clientlist: any;
  buttoninsert: any;
  selectedFiles: FileList;
  file1: any;
  file2: any;
  Userid: any;
  imgempsrc: any;
  contracttypelist: any;
  currentvalue = false;
  flexistartdateerror = false;
  flexienddateerror = false;
  empenddateerror = false;
  disableBtn: any;
  filename1: any;
  filename2: any;
  iframeempsrc: any;
  defaultVal: any = "";
  iscurrentEmployment: any;

  empcontext: string;
  emprwordtext: string;

  constructor(
    private sanitizer: DomSanitizer,
    private data: MyemploymentService, 
    private companyService: CompanyService,
    private formBuilder: FormBuilder, 
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private project: ProjectService, 
    private client: ClientService,
    private employeeService: EmployeeService,
    private uploadService: UploadFileService,
    private alerts: AlertService,
    private departmentService: DepartmentService,
    private divisionService: DivisionService
    ) { }

  ngOnInit() {
    this.filename1 = this.filename2 = "Select File";
    this.empid = this.activatedRoute.snapshot.paramMap.get("id");
    this.Userid = localStorage.getItem('userid');

    this.empcontext = 'Employment Contract *';
    this.emprwordtext = 'Right to work *';

    // this.employeementDetail.enddate = "";
      this.iscurrentEmployment = false;

    this.employmentForm = this.formBuilder.group({
      id: ['', Validators.required],
      clientid: [''],
      projectid: [''],
      employeeid: ['', Validators.required],
      // wemployeeid: ['', Validators.required],
      // clientcont: ['', Validators.required],
      salary: ['', Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])],
      hoursofworks: ['', Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])],
      startdate: ['', Validators.required],
      enddate: [''],
      emprole: ['', Validators.required],
      iscurrent: [''],
      employeestatus: [''],
      hiredate: [''],
      terminationdate: [''],
      terminationreason: [''],
      oftorehire: [''],
      regrettermination: [''],
      departmentid: [''],
      divisionid: [''],
      contracttypeid: ['', Validators.required],
      flexiworkid: ['', Validators.required],
      flexistartdate: [''],
      flexienddate: [''],
      flexiid: ['']
    });

    this.employeementDetail = new Working();
    this.employeementDetail.flexi = new Flexi();
    this.employeementDetail.wemployee = new Wemployee();
    this.filename = 'Choose file';
    this.empltype = localStorage.getItem('emptype');
    this.data.getemploymenttListbyadmin({'id': this.empid}).subscribe((data: any) => {
      // console.log(data);
      this.employeedetails = data;
    });

    this.employeeService.getlistofworkingemployee().subscribe((employeedata: any) => {
      this.wemployeelist = employeedata;
    });
    const sstatustype = { 'status': true, 'id': this.Userid } 

    this.project.getprojectlist(sstatustype).subscribe((projectdetails: any) => {    
      this.projectlist = projectdetails;
    });
    
    this.client.getclientlist(sstatustype).subscribe((clientdetails: any) => {
      this.clientlist = clientdetails;
    });
    
    this.departmentService.getdepartmentlistbyadmin(sstatustype).subscribe((data: any) => {
      this.departmentlist = data;
    });

    this.companyService.getemployeecontracttypelist().subscribe((data: any) => {
      this.contracttypelist = data;
    });
    
    this.divisionService.getdivisionlistbyadmin(sstatustype).subscribe((data: any) => {
      this.divisiondetails = data;
    });


  }


  get f() { return this.employmentForm.controls; }

  onSubmit(formemployment: any, id: string) {
    // var formemployment = value3.value;
    this.submitted = true;
    this.filesconemerror = '';
    this.filesworkemerror = '';
    if (this.employmentForm.invalid) 
    {      
      return;
    }
    
    if(this.file1 != '')
    {
      formemployment.employmentcontract = this.file1;
    }
    else
    {
      formemployment.employmentcontract = this.employeementDetail.employmentcontract;
    }

    if(this.file2 != '')
    {
      formemployment.rwork = this.file2;
    }
    else
    {
      formemployment.rwork = this.employeementDetail.rwork;
    }

    if(formemployment.flexiworkid == '1')
    {
      if((formemployment.flexistartdate == '') || (formemployment.flexienddate == ''))
      {
        if(formemployment.flexistartdate == '')
        {
          this.flexistartdateerror = true;
        }
        // if(formemployment.flexienddate == '')
        // {
        //   this.flexienddateerror = true;
        // }
        return;
      }
    }

    if(formemployment.iscurrent == '0')
    {
      if((formemployment.enddate == '') || (formemployment.enddate == null))
      {
        this.empenddateerror = true;
        
        return false;
      }
    }

    
    if(formemployment.employmentcontract == '')
    {
      this.filesconemerror = true;
     
      return;
    }

    if(formemployment.rwork == '')
    {
      this.filesworkemerror = true;     
      return;
    }


    this.disableBtn = false;
    
    formemployment.createby = this.Userid;
    formemployment.updateby = this.Userid;

    
    const sd = new Date(formemployment.startdate);
    formemployment.startdate = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());

    if(formemployment.enddate)
    {
      const ed = new Date(formemployment.enddate);
      formemployment.enddate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
    }


    this.data.myemploymentdetailsUpdatebyadmin(formemployment).subscribe((data: any) => {
      this.data.getemploymenttListbyadmin({'id': this.empid}).subscribe((data: any) => {
        this.employeedetails = data;
        this.alerts.success('Employment Info Updated Successfully',true);
        this.modalService.close(id);
      });
    });
   
    // this.employeementDetail = new Working();
    // this.employeementDetail.flexi = new Flexi();
  }

  selectFile(event,field) {
    var files = event.target.files;
    var selectedFiles = files[0];
    
    this.selectedFiles = selectedFiles;
    const file = this.selectedFiles;

    var imagepath = this.uploadService.uploadfile(file);
    
    if(field == 'file1')
    {
      this.filesconemerror = '';
    
      this.filename1 = selectedFiles.name.substring(0,15);
      this.file1 = imagepath;
    }
    if(field == 'file2')
    {
      this.filesworkemerror = '';
      this.filename2 = selectedFiles.name.substring(0,20);
      this.file2 = imagepath;
    }

  }
  
  openModal2(id: string) 
  {
    this.filename1 = this.filename2 = "Select File";
    this.file1 = this.file2 = "";
    this.disableBtn = true;
    this.currentvalue = false;
    this.submitted = false;
    this.buttoninsert = "Submit";
    this.employeementDetail = new Working();
    this.employeementDetail.flexi = new Flexi();
    this.employeementDetail.flexi.id = "0";
    this.employeementDetail.flexiworkid = "0";
    this.employeementDetail.iscurrent = "1";
    this.employeementDetail.employeeid = this.empid;
    this.employeementDetail.employeeworkingdetailsid = "0";
    this.empcontext = 'Employment Contract *';
    this.emprwordtext = 'Right to work *';

    // this.iscurrentEmployment = true;
    // this.employeementDetail.enddate = "";
      this.iscurrentEmployment = false;
    // this.employeementDetail.clientid ='';
    // this.employeementDetail.projectid = '';
    // this.employeementDetail.wemployeeid = '';
    // this.employeementDetail.clientcont = '';
    this.employeementDetail.employmentcontract ='';
    this.employeementDetail.rwork ='';
    // this.employeementDetail.flexi.startdate = "";
    // this.employeementDetail.flexi.enddate = "";
    this.filename = "Choose file";
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.filename1 = this.filename2 = "Select File";
    this.file1 = this.file2 = "";
    this.disableBtn = true;
    this.employeementDetail = new Working();
    this.employeementDetail.flexi = new Flexi();
    this.filename = "Choose file";
    this.buttoninsert = "Update";

    this.empcontext = 'Employment Contract';
    this.emprwordtext = 'Right to work';

    if(!data.flexi)
    {
      this.employeementDetail = data;
      this.employeementDetail.flexi = new Flexi();
      this.employeementDetail.flexi.id = "0";
      this.employeementDetail.flexiworkid = "0";
    }
    else
    {
      this.employeementDetail = data;
      this.employeementDetail.flexiworkid = "1";
      this.currentvalue = true;
    }
    // if((this.employeementDetail.enddate != "") && (this.employeementDetail.enddate != null))
    // {
    //   this.iscurrentEmployment = true;
    // }
    if(data.iscurrent == false)
    {
      this.iscurrentEmployment = true;
      this.employeementDetail.iscurrent = "0";
    }
    else if(data.iscurrent == true)
    {
      this.iscurrentEmployment = false;
      this.employeementDetail.iscurrent = "1";
    }
    // console.log(this.employeementDetail);
    this.modalService.open(id);
  }

  closeModal(id: string) 
  {
    // this.employeementDetail = new Working();   
    // this.employeementDetail.flexi = new Flexi();
    this.data.getemploymenttListbyadmin({'id': this.empid}).subscribe((data: any) => {
      this.employeedetails = data;
    });
    this.modalService.close(id);
  }

  flexichange(eve,addressid)
  {    
    if(eve.target.value=='1')
    {
      this.employeementDetail.flexistartdate = "";
      this.employeementDetail.flexienddate = "";
      this.currentvalue = true;
    }
    else{  
      this.currentvalue = false;
    }
  }

  currentemploymentchange(eve)
  {
    // console.log(eve);
    if(eve.target.value=='1')
    {
      this.employeementDetail.enddate = "";
      this.iscurrentEmployment = false;
    }
    else if(eve.target.value=='0'){  
      this.iscurrentEmployment = true;
    }
  }

  focuser1()
  {
    this.flexistartdateerror = false;
  }
  focuser2()
  {
    this.flexienddateerror = false;
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModalemployement3(id: string, data: any) {
    this.iframeempsrc='';
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif'))
    {
     this.imgempsrc = true;
     this.iframeempsrc = data; 
    }
    else{
     this.imgempsrc = false;
     this.iframeempsrc = this.getSafeUrl("https://docs.google.com/gview?url="+data+"&embedded=true");
    }
     this.modalService.open(id);
   }

}


