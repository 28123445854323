import { Project } from "../project/project.model";

export class Expense {
    id: number;
    employeehourapplyid: number;
    projectid: number;
    title: number;
    employeeid: number;
    expensetypeid: number;
    amountpaid: number;
    paidby: number;
    start: Date;
    end: string;
    currency: string;
    imagename: string;
    expenseremark: string;
    locationname: string;
    expenseremarksid: number;
    project: Project;
  }