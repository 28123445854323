
<div class="bg">
    <app-header></app-header>
</div>
<!-- <app-header></app-header> -->
<div class="company_adver_search"  *ngIf="mode ==0 || mode ==2">
    <div class="container">
        <div class="search_panel">
            <div class="row row-sm topheader">                            
                <div class="col-lg-5 text-right">
                    <app-checkcountshow></app-checkcountshow>
                </div>
                <div class="col-lg-7 text-right">
                    <div class="d-flex justify-content-end align-items-center">                          
                        <button class="btn com_btn btn-success small_btn" [routerLink]="['/company-advert-history']"  type="button">
                            <span class="lnr lnr-history pr-1">                                        
                            </span> Search History
                        </button>
                    </div>                             
                </div>
            </div>
            <div class="row row-sm topheader">
                <div class="col-lg-6">
                    <h5 class="hedingcolor">Advanced Company Search</h5>
                </div>                       
            </div>
            <div class="panel" *ngIf="mode==0">
                <form [formGroup]="advertFormgroup" #advertForm="ngForm" (ngSubmit)="onSubmitAdvert(advertForm.value)" *ngIf="advertDetail" class="s12 white">
                    <div class="personal_form">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Company Name</label>
                                    <input type="text" name="companyname" formControlName="companyname" id="companyname" class="form-control" aria-describedby="emailHelp" placeholder="Company Name" [ngModel]="advertDetail.companyname"  [ngClass]="{ 'is-invalid': submitted && f.companycode.errors }">
                                    <div *ngIf="submitted && f.companyname.errors" class="invalid-feedback">
                                        <div *ngIf="f.companyname.errors.required">Please enter Company Name</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Safe Number</label>
                                    <input type="text" name="safenumber" formControlName="safenumber" id="safenumber" class="form-control" aria-describedby="emailHelp" placeholder="Safe Number"  [ngClass]="{ 'is-invalid': submitted && f.safenumber.errors }" [ngModel]="advertDetail.safenumber">
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Company Number</label>
                                    <input type="text" name="companycode" formControlName="companycode" id="companycode" class="form-control" aria-describedby="emailHelp" placeholder="Company Number" [ngClass]="{ 'is-invalid': submitted && f.companycode.errors }"  [ngModel]="advertDetail.companycode">
                                    
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">VAT Number</label>
                                    <input type="text" name="vatnumber" formControlName="vatnumber" id="vatnumber" class="form-control" aria-describedby="emailHelp" placeholder="VAT Number" [ngModel]="advertDetail.vatnumber" >
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Telephone Number</label>
                                    <input type="text" name="phoneNo" formControlName="phoneNo" id="phoneNo" class="form-control" aria-describedby="emailHelp" placeholder="Phone Number" [ngClass]="{ 'is-invalid': submitted && f.phoneNo.errors }"  [ngModel]="advertDetail.PhoneNo">
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">House Name/Number</label>
                                    <input type="text" name="housenumber" formControlName="housenumber" id="housenumber" class="form-control" aria-describedby="emailHelp" placeholder="House Name/Number" [ngClass]="{ 'is-invalid': submitted && f.housenumber.errors }"  [ngModel]="advertDetail.housenumber">
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Street</label>
                                    <input type="text" name="Street" formControlName="Street" id="Street" class="form-control" aria-describedby="emailHelp" placeholder="Street" [ngClass]="{ 'is-invalid': submitted && f.Street.errors }"  [ngModel]="advertDetail.Street">
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">City / Town</label>
                                    <input type="text" name="city" formControlName="city" id="city" class="form-control" aria-describedby="emailHelp" placeholder="City / Town" [ngClass]="{ 'is-invalid': submitted && f.city.errors }"  [ngModel]="advertDetail.city">
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Post Code</label>
                                    <input type="text" name="postcode" formControlName="postcode" id="postcode" class="form-control" aria-describedby="emailHelp" placeholder="Post Code" [ngClass]="{ 'is-invalid': submitted && f.postcode.errors }"  [ngModel]="advertDetail.postcode">
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Country</label>
                                    <select formControlName="country" class="form-control input-lg  width100" [ngClass]="{ 'is-invalid': submitted && f.country.errors }"  name="country" id="country" style="width: 100%;">
                                        <option [value]="defaultVal">Select Country </option>
                                        <ng-container *ngFor="let country of countrylist">
                                            <option *ngIf="country.iso"  [value]="country.iso">{{ country.countryname }}</option> 
                                        </ng-container>               
                                    </select>
                                    <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                        <div *ngIf="f.country.errors.required">Please select country</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Company Status</label>
                                    <select formControlName="companystatus" class="form-control input-lg width100" name="companystatus" id="companystatus" style="width: 100%;">
                                        <option value="">all</option>
                                        <option value="Active">Active</option>
                                        <option value="NonActive">Non-active</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Previous Company Names</label>
                                    <select formControlName="previousName" class="form-control input-lg width100" name="previousName" id="previousName" style="width: 100%;">
                                        <option value="" disabled="" hidden="">Include previous names?</option>
                                        <option value="include">Include</option>
                                        <option value="exclude">Exclude</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="row"> 
                            <div class="col-sm-6 text-left">
                                <span *ngIf="lesssore">Sorry, You don't have any credits. Please  <a [routerLink]="['/check-add-credit']">Click Here</a> to add more.</span>
                            </div>
                            <div class="col-sm-6 text-right">
                                <!-- <button type="submit" class="btn btn-primary com_btn"   [disabled]="!disableBtn" *ngIf="!lesssore">Search</button> -->

                                <button type="button" class="btn btn-secondary com_btn" >Out of Stock</button>

                                <button type="button" (click)="clearAdverseCreditData()" class="btn btn-secondary com_btn">Cancel</button>                                    
                            </div>
                        </div>
                        <div class="row"> 
                            <div class="col-sm-12 text-left">
                                <div class="job_list_section visa_request_section" *ngIf="error === 1">
                                    <div class="job_detils_date">
                                        <p class="job_profile">Specified criteria combination is not allowed</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                       

                       
<!-- 
                    <input type="text" formControlName="companycode" name="companycode" id="companycode" 
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && v.companycode.errors }" 
                    [(ngModel)]="advertDetail.companycode">  -->
                    
                    <!-- <input class="btn btn-primary com_btn" type="submit" value="Submit"> -->
                </form>   
            </div>
            <div class="content" *ngIf="mode==2">
                <p *ngFor="let company of companies">{{company.name}} ({{company.id}})<button (click)="getDataByCompanyCode(company.id,company.name)" class="btn btn-success com_btn">search</button></p>
            </div>       
        </div>      
    </div>   
</div>
<div class="company_adver_search" *ngIf="mode ==1">
    <div class="container">
        <div class="com_risk_option">   
            <div class="row row-sm">                            
                <div class="col-lg-5 text-right">
                    <app-checkcountshow></app-checkcountshow>
                </div>
                <div class="col-lg-7 text-right">
                    <div class="d-flex justify-content-end align-items-center">                          
                        <button class="btn com_btn btn-success small_btn" (click)="chnagestatus(0)"  type="button">
                            <span class="lnr lnr-history pr-1">                                        
                            </span> Back to Business Credit Check
                        </button>
                    </div>                             
                </div>
            </div>
            <div class="leavedetails">
                <div class="leavebox greenslip">
                    <div class="boxleave text-center">
                        <h4>Risk Score</h4>
                        <p><span class="pending_leave"> {{company.profile.risk_score}}</span></p>
                    </div>
                </div>
                <div class="leavebox greenslip">
                    <div class="boxleave text-center">
                        <h4>International Score</h4>
                        <p><span class="pending_leave"> {{company.profile.international_score }}</span></p>
                    </div>
                </div>
                <div class="leavebox greenslip">
                    <div class="boxleave text-center">
                        <h4>Credit Limit</h4>
                        <p><span class="pending_leave"> £{{company.profile.credit_limit}}</span></p>
                    </div>
                </div>
                <div class="leavebox greenslip">
                    <div class="boxleave text-center">
                        <h4>Contract Limit</h4>
                        <p><span class="pending_leave"> {{(company.profile.contract_limit)?'£'+company.profile.contract_limit:'-'}}</span></p>
                    </div>
                </div>
                <div class="leavebox greenslip">
                    <div class="boxleave text-center">
                        <h4>Status </h4>
                        <p><span class="pending_leave"> {{company.profile.status}}</span></p>
                    </div>
                </div>
                <div class="leavebox yellowbb">
                    <div class="boxleave text-center">
                        <h4>DBT</h4>
                        <p><span class="pending_leave"> -</span></p>
                    </div>
                </div>
                <div class="leavebox greenslip">
                    <div class="boxleave text-center">
                        <h4>Industry DBT</h4>
                        <p><span class="pending_leave"> {{company.profile.industry_dbt}}</span></p>
                    </div>
                </div>
            </div>
            <div class="button_box">
                <button type="submit" class="btn btn-dark com_btn" style="display: none;">Monitor</button>
                <button type="submit" class="btn btn-light com_btn" style="display: none;">More Actions <i class="fa fa-sort-desc" aria-hidden="true"></i>
                </button>
            </div>
            <mat-tab-group [@.disabled]="true">             
                <mat-tab label="Summary">
                    <div >
                        <mat-tab-group [@.disabled]="true">
                            <mat-tab label="Company Information">
                            <div class="summary_sec">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="keyinfo">
                                            <h4>Key Information</h4>
                                            <div class="infodest">
                                                <p>Company Number</p>
                                                <span>{{company.summary.company_information.key_information.company_number}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p>Company Name</p>
                                                <span>{{company.summary.company_information.key_information.company_name}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p>Company Status</p>
                                                <span>{{company.summary.company_information.key_information.company_status}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p>Incorporation Date</p>
                                                <span>{{company.summary.company_information.key_information.incorporation_date | date: 'MM/dd/yyyy'}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p>Company Type</p>
                                                <span>{{company.summary.company_information.key_information.company_type}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p>VAT Number</p>
                                                <span>{{company.summary.company_information.key_information.vat_number}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p>SIC07 Code</p>
                                                <span>{{company.summary.company_information.key_information.sic07_code}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p>SIC07 Description</p>
                                                <span>{{company.summary.company_information.key_information.sic07_desc}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="keyinfo">
                                            <h4>Contact Information</h4>
                                            <div class="infodest">
                                                <p>Address</p>
                                                <span>{{company.summary.company_information.contact_information.address}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p>Website</p>
                                                <span *ngFor="let website of company.summary.company_information.contact_information.website">{{website}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p>Telephone Number</p>
                                                <span>{{company.summary.company_information.contact_information.telephone_number}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p>TPS</p>
                                                <span>N</span>
                                            </div>
                                            <div class="infodest" *ngFor="let add of company.summary.company_information.contact_information.other_addresses">
                                                <p>{{add.type}}</p>
                                                <span>{{add.simpleValue}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="keyinfo">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <h4>Additional Information</h4>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="infodest">
                                                        <p>FTSE Index</p>
                                                        <span>{{company.summary.company_information.additional_information.ftse_index}}</span>
                                                    </div>
                                                    <div class="infodest">
                                                        <p>Share Capital</p>
                                                        <span>{{(company.summary.company_information.additional_information.share_capital)?'£'+company.summary.company_information.additional_information.share_capital:'-'}}</span>
                                                    </div>
                                                    <div class="infodest">
                                                        <p>Currency</p>
                                                        <span>{{company.summary.company_information.additional_information.currency}}</span>
                                                    </div>
                                                    <div class="infodest">
                                                        <p>Principal Activity</p>
                                                        <span>{{company.summary.company_information.additional_information.principal_activity}}</span>
                                                    </div>
                                                    <div class="infodest">
                                                        <p>Charges </p>
                                                        <span *ngIf="company.summary.company_information.additional_information.charges">{{company.summary.company_information.additional_information.charges}}</span>
                                                        <span *ngIf="!company.summary.company_information.additional_information.charges">0</span>
                                                    </div>
                                                    <div class="infodest">
                                                        <p>Land Registry</p>
                                                        <span>{{company.summary.company_information.additional_information.land_registry}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="infodest">
                                                        <p>Filling Date of Accounts</p>
                                                        <span>{{company.summary.company_information.additional_information.filing_date_of_accounts | date: 'MM/dd/yyyy'}}</span>
                                                    </div>
                                                    <div class="infodest">
                                                        <p>Charity Number</p>
                                                        <span>{{company.summary.company_information.additional_information.charity_number}}</span>
                                                    </div>
                                                    <div class="infodest">
                                                        <p>Safe Number</p>
                                                        <span>{{company.summary.company_information.additional_information.safe_number}}</span>
                                                    </div>
                                                    <div class="infodest">
                                                        <p>Accountant</p>
                                                        <span *ngFor="let accountant of company.summary.company_information.additional_information.accountant">{{accountant.accountantName}}</span>
                                                    </div>
                                                    <div class="infodest">
                                                        <p>Trade Debtors</p>
                                                        <div class="moreoption">
                                                            <span>{{company.summary.company_information.additional_information.trade_debtors}}</span>
                                                            <a href="#" style="display: none;">Show More</a>
                                                        </div>
                                                    </div>
                                                    <div class="infodest">
                                                        <p>Trade Creditors</p>
                                                        <span>{{company.summary.company_information.additional_information.trade_creditors}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="keyinfo noheight">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <h4>CCJ Summary</h4>
                                                </div>
                                                <div class="col-sm-12" *ngIf="company.legal_filings.ccj_summary.exactRegistered<=0">
                                                    <p class="norecordfound">No information to display</p>
                                                </div>

                                                <div class="col-sm-12" *ngIf="company.legal_filings.ccj_summary.exactRegistered>0">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="enqu_summa">
                                                                <ul>
                                                                    <li>
                                                                        <p>Number of CCJ's</p>
                                                                        <h2>{{company.legal_filings.ccj_summary.numberOfExact}}</h2>
                                                                    </li>
                                                                    <li>
                                                                        <p>Value of CCJ's</p>
                                                                        <h2>{{company.legal_filings.ccj_summary.valueOfExact}}</h2>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <ul>
                                                                <li>
                                                                    <h2>Most Recent CCJ's</h2>
                                                                    <div class="job_list_section employee_list_section pb-2 pt-2 oddeven">
                                                                        <div class="job_detils_date width25">
                                                                        <!-- <p class="job_address">Name</p> -->
                                                                        <h5 class="job_profile font_17">{{company.legal_filings.ccj.exact_registered[0].ccjDate | date: 'MM/dd/yyyy'}}</h5>            
                                                                        </div>
                                                                        <div class="job_detils_date width15">
                                                                        <!-- <p class="job_address">Currency</p> -->
                                                                        <p  class="job_profile">{{company.legal_filings.ccj.exact_registered[0].court}}</p>             
                                                                        </div>
                                                                        <div class="job_detils_date width15">
                                                                        <!-- <p class="job_address">Share Count</p> -->
                                                                        <p class="job_profile">£{{company.legal_filings.ccj.exact_registered[0].ccjAmount}}</p>
                                                                        </div>        
                                                                        <div class="job_detils_date width15">
                                                                        <!-- <p class="job_address">Share Type</p> -->
                                                                        <p class="job_profile">{{company.legal_filings.ccj.exact_registered[0].ccjStatus}}</p>
                                                                        </div> 
                                                                        
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="keyinfo noheight">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <h4>Ultimate Holding Company</h4>
                                                </div>
                                                <div class="col-sm-12">
                                                    <p class="norecordfound">No information to display</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="company.summary.company_information.key_financials.length">
                                    <div class="col-sm-12">
                                        <div class="keyinfo">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <h4>Key Financials</h4>
                                                </div>
                                                <div class="col-sm-7 upgr_flex keyfin">
                                                    <div class="block_tables_head_financials">
                                                        <div class="first">
                                                            <h4 class="hiddenblank">Blank</h4>
                                                        </div>
                                                        <div class="second">
                                                            <p><b>Turnover</b></p>
                                                        </div>
                                                        <div class="second">
                                                            <p><b>Pre Tax Profit</b></p>
                                                        </div>
                                                        <div class="second">
                                                        <p><b>Total Shareholders Equity</b></p>
                                                        </div>
                                                        <div class="second">
                                                            <p><b>Number of Employees</b></p>
                                                        </div>
                                                    </div>
                                                    <div class="block_tables_financials colgrid-{{company.summary.company_information.local_csuk_financials.length}}" *ngFor="let financial of company.summary.company_information.local_csuk_financials">
                                                        <div class="first">
                                                            <h4><b>{{financial.yearEndDate | date: 'yyyy'}}</b></h4>
                                                        </div>
                                                        <div class="second">
                                                            <p>-<span>-</span></p>
                                                        </div>
                                                        <div class="second">
                                                            <p>-<span>-</span></p>
                                                        </div>
                                                        <div class="second">
                                                            <p class="flex">{{(financial.balanceSheet.totalShareholdersEquity)?((financial.balanceSheet.totalShareholdersEquity<0)?'-£'+financial.balanceSheet.totalShareholdersEquity*-1:'£'+financial.balanceSheet.totalShareholdersEquity):'-'}}
                                                                <span class="{{(financial.positivetotalShEq)?'red':'green'}}" *ngIf="(financial.perc_totalShEq)">
                                                                    {{financial.perc_totalShEq | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivetotalShEq)?'down':'up'}}"></i>
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <ng-template ngFor let-item [ngForOf]="company.summary.company_information.local_gaap_financials">
                                                            <div *ngIf="item.yearEndDate==financial.yearEndDate && item.profitAndLoss" class="second">
                                                                <p>{{(item.profitAndLoss.numberOfEmployees)?item.profitAndLoss.numberOfEmployees:'- -'}}</p>
                                                            </div>
                                                            </ng-template>
                                                    </div>
                                                </div>
                                                <div class="col-sm-5">
                                                    <div id="finacial_chart" style="height: 370px; width: 99%;"></div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="keyinfo">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <h4>Payment Information Summary</h4>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <h4>Invoice Data & Aged Debt</h4>
                                                            <div class="invoice_data">
                                                            <div class="data">
                                                                <p>Average Invoice Value</p>
                                                                <span>£{{company.payment_data.averageInvoiceValue}}</span>
                                                            </div>
                                                            <div class="data">
                                                                <p>Invoices Available</p>
                                                                <span>{{company.payment_data.paymentsOnFile}}</span>
                                                            </div>
                                                            <div class="data">
                                                                <p>Paid</p>
                                                                <span>{{company.payment_data.paymentsOnTime+company.payment_data.paymentsPaidLate}}</span>
                                                            </div>
                                                            <div class="data">
                                                                <p>Outstanding</p>
                                                                <span>{{company.payment_data.numberOfInvoicesOutstandingWithinTerms+company.payment_data.numberOfInvoicesOutstanding1To30Days+company.payment_data.numberOfInvoicesOutstanding31To60Days+company.payment_data.numberOfInvoicesOutstanding61To90Days+company.payment_data.numberOfInvoicesOutstanding91PlusDays}}</span>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="graph_para">
                                                                <p>Trade Payment Data is real life payment experiences gathered from selected third party partners big and small who send us trade payment information on their complete sales ledger.</p>
                                                                <p>
                                                                    The Age of Debt Graph highlights the current Age of Debt of a given company. The graph is separated into 5 categories. Generally, within terms and 0-30 days is a reasonable time for a company to pay their invoices and showcases good invoice payment whereas anything beyond that is indicative of poor invoice payment.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                </div>
                                                <div class="col-sm-6">
                                                    <div id="invoice_number_graph1" style="height: 370px; width: 99%;"></div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="keyinfo noheight">
                                            <div class="row">
                                                
                                                <div class="col-sm-12">
                                                    <div class="dbcom_flex">
                                                        <p class="norecordfound">Share your risk intelligence with over 8,000 creditsafe contributors and create your trade payment story</p>
                                                        <button type="submit" class="btn btn-danger com_btn" style="display: none;"> Share More</button>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="keyinfo noheight">
                                            <div class="row">
                                                
                                                <div class="col-sm-12">
                                                    <div class="dbcom_flex">
                                                        <h4 class="norecordfound">Does this company owe you money?</h4>
                                                        <button type="submit" class="btn btn-primary com_btn" style="display: none;"> Debt Collection</button>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="keyinfo noheight">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <h4>Commentary</h4>
                                                </div>
                                                <div class="col-sm-12">
                                                    <div class="listingshow">
                                                        <ul>
                                                            <li *ngFor="let commentary of company.summary.company_information.commentary">
                                                                <i class="fa fa-minus" *ngIf="commentary.positiveNegative=='Neutral'" aria-hidden="true"></i>
                                                                <i class="fa fa-arrow-circle-up" *ngIf="commentary.positiveNegative=='Positive'" aria-hidden="true"></i>
                                                                <i class="fa fa-arrow-circle-down red" *ngIf="commentary.positiveNegative=='Negative'" aria-hidden="true"></i>
                                                                {{commentary.commentaryText}} 
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="keyinfo noheight">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <h4>Enquiries Summary</h4>
                                                </div>
                                                <div class="col-sm-12">
                                                    <div class="enqu_summa">
                                                        <ul>
                                                            <li>
                                                                <p>Latest Enquiry Date</p>
                                                                <h2>{{company.summary.company_information.enquiries_summary.latest_enquiry_date | date: 'MM/dd/yyyy'}}</h2>
                                                            </li>
                                                            <li>
                                                            <p>Avg Report Per Month</p>
                                                            <h2>{{company.summary.company_information.enquiries_summary.avg_reports_per_month}}</h2>
                                                        </li>
                                                        <li>
                                                            <p>Enquiry (Past 3 Months)</p>
                                                            <h2>{{company.summary.company_information.enquiries_summary.enq_past_3months}}</h2>
                                                        </li>
                                                        <li>
                                                            <p>Enquiry (Past 6 Months)</p>
                                                            <h2>{{company.summary.company_information.enquiries_summary.enq_past_6months}}</h2>
                                                        </li>
                                                        <li>
                                                            <p>Enquiry (Past 9 Months)</p>
                                                            <h2>{{company.summary.company_information.enquiries_summary.enq_past_9months}}</h2>
                                                        </li>
                                                        <li>
                                                            <p>Enquiry (Past 12 Months)</p>
                                                            <h2>{{company.summary.company_information.enquiries_summary.enq_past_12months}}</h2>
                                                        </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div id="chartContainer3" style="height: 400px; width: 99%;"></div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div id="chartContainer2" style="height: 400px; width: 99%;"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <!-- </ng-template> -->
                        </mat-tab>
                        
                        <mat-tab label="Trading Address">
                            <!-- <ng-template matTabContent> -->
                            <div class="summary_sec">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="keyinfo noheight">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <h4>Trading Address Details</h4>
                                                </div>
                                                <div class="col-sm-12">
                                                    <div class="block_tables_head">
                                                        <div class="second">
                                                            <h4>Address</h4>
                                                        </div>
                                                        <div class="second">
                                                            <h4>Telephone Number</h4>
                                                        </div>
                                                        <div class="second">
                                                        <h4>TPS</h4>
                                                        </div>
                                                    </div>
                                                    <div *ngFor="let add of company.summary.company_information.contact_information.other_addresses">
                                                        <div class="block_tables width100" *ngIf="add.type=='Trading Address'">
                                                        <div class="second">
                                                            <p>{{add.simpleValue}}</p>
                                                        </div>
                                                        <div class="second">
                                                            <p>{{add.telephone}}</p>
                                                        </div>
                                                        <div class="second">
                                                            <p>N</p>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- </ng-template> -->
                        </mat-tab>
                        
                        <mat-tab label="SIC07/SIC03">
                            <!-- <ng-template matTabContent> -->
                            <div class="summary_sec">
                        
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="keyinfo noheight">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <h4>Additional SIC Codes</h4>
                                                </div>

                                                <div class="col-sm-12" *ngFor="let sic of company.summary.sic">
                                                <div *ngIf="sic.classification=='SIC03'">
                                                    <div *ngFor="let s of sic.activities">
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div class="infodest">
                                                                    <p>SIC03 Code</p>
                                                                    <span>{{s.code}}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="infodest">
                                                                    <p>SIC03 Description</p>
                                                                    <span>{{s.description}}</span>
                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div *ngIf="sic.classification=='SIC07'">
                                                    <div *ngFor="let s of sic.activities">
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div class="infodest">
                                                                    <p>SIC07 Code</p>
                                                                    <span>{{s.code}}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="infodest">
                                                                    <p>SIC07 Description</p>
                                                                    <span>{{s.description}}</span>
                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        
                            <!-- </ng-template> -->
                        </mat-tab>
                        
                        </mat-tab-group>
                    <!-- </ng-template> -->

                    </div>
                </mat-tab>
                <mat-tab label="Score/Limit">
                    <ng-template matTabContent>
                        <div class="summary_sec">

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="keyinfo noheight">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h4>Summary Information</h4>
                                            </div>
                                            <div class="col-sm-6">
                                                
                                            <div class="infodest">
                                                <p>Score</p>
                                                <span>{{company.score_limit.score}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p>Limit</p>
                                                <span>£{{company.score_limit.limit}}</span>
                                            </div>
                                            
                                            
                                            </div>
                                            <div class="col-sm-6">
                                                
                                                <div class="infodest">
                                                    <p>Change Date</p>
                                                <span>{{company.score_limit.change_date | date: 'MM/dd/yyyy'}}</span>
                                                </div>
                                                <div class="infodest">
                                                    <p>Change Date</p>
                                                    <span>{{company.score_limit.change_date | date: 'MM/dd/yyyy'}}</span>
                                                </div>                                               
                                                    
                                            </div>
                                            
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="keyinfo noheight">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h4>Score Description</h4>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="block_tables_head">
                                                    <div class="first">
                                                        <h4>Score</h4>
                                                    </div>
                                                    <div class="second">
                                                        <h4>Description</h4>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><span [class]="(company.score_limit.score<=100)?'circless green':'circless red'"></span> 71-100</p>
                                                    </div>
                                                    <div class="second">
                                                        <p>Very Low Risk</p>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><span [class]="(company.score_limit.score<=70)?'circless green':'circless red'"></span> 51-70</p>
                                                    </div>
                                                    <div class="second">
                                                        <p>Low Risk</p>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><span [class]="(company.score_limit.score<=50)?'circless green':'circless red'"></span> 30-50</p>
                                                    </div>
                                                    <div class="second">
                                                        <p>Moderate Risk</p>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><span [class]="(company.score_limit.score<=29)?'circless green':'circless red'"></span> 21-29</p>
                                                    </div>
                                                    <div class="second">
                                                        <p>High Risk</p>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><span [class]="(company.score_limit.score<=20)?'circless green':'circless red'"></span> 1-20</p>
                                                    </div>
                                                    <div class="second">
                                                        <p>Very High Risk</p>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><b>Not Scored</b></p>
                                                    </div>
                                                    <div class="second">
                                                        <p><b>Please see report for description</b></p>
                                                    </div>
                                                </div>
                                            </div>        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="keyinfo noheight">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h4>Newly Incorporated</h4>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="block_tables_head">
                                                    <div class="first">
                                                        <h4>Score</h4>
                                                    </div>
                                                    <div class="second">
                                                        <h4>Description</h4>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><span [class]="(company.score_limit.score<=100)?'circless green':'circless red'"></span> 51-100</p>
                                                    </div>
                                                    <div class="second">
                                                        <p>Low Risk</p>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><span [class]="(company.score_limit.score<=50)?'circless green':'circless red'"></span> 30-50</p>
                                                    </div>
                                                    <div class="second">
                                                        <p>Moderate Risk</p>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><span [class]="(company.score_limit.score<=29)?'circless green':'circless red'"></span> 1-29</p>
                                                    </div>
                                                    <div class="second">
                                                        <p>Caution - High Risk</p>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><b>Not Scored</b></p>
                                                    </div>
                                                    <div class="second">
                                                        <p><b>Please see report for description</b></p>
                                                    </div>
                                                </div>
                                            </div>        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="keyinfo noheight">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h4>International Score Explanation</h4>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="block_tables_head">
                                                    <div class="first">
                                                        <h4>International Score</h4>
                                                    </div>
                                                    <div class="second">
                                                        <h4>Description</h4>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><span [class]="(company.score_limit.international_score=='A' || company.score_limit.international_score=='B' || company.score_limit.international_score=='C' || company.score_limit.international_score=='D')?'circless green':'circless red'"></span> A</p>
                                                    </div>
                                                    <div class="second">
                                                        <p>Very Low Risk</p>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><span [class]="(company.score_limit.international_score=='B' || company.score_limit.international_score=='C' || company.score_limit.international_score=='D')?'circless green':'circless red'"></span> B</p>
                                                    </div>
                                                    <div class="second">
                                                        <p>Low Risk</p>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><span [class]="(company.score_limit.international_score=='C' || company.score_limit.international_score=='D')?'circless green':'circless red'"></span> C</p>
                                                    </div>
                                                    <div class="second">
                                                        <p>Moderate Risk</p>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><span [class]="(company.score_limit.international_score=='D')?'circless green':'circless red'"></span> D</p>
                                                    </div>
                                                    <div class="second">
                                                        <p>High Risk</p>
                                                    </div>
                                                </div>
                                                <div class="block_tables">
                                                    <div class="first">
                                                        <p><span class="circless yellow"></span> E</p>
                                                    </div>
                                                    <div class="second">
                                                        <p>Not Scored</p>
                                                    </div>
                                                </div>
                                            </div>        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Directors/Shareholders">
                    <ng-template matTabContent>
                        <app-directorcompany [company]="company"></app-directorcompany>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Financials">
                    <ng-template matTabContent>
                        <app-companyadvertfinancials [company]="company"></app-companyadvertfinancials>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Event History">
                    <div class="summary_sec">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="keyinfo noheight">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>Report Notes</h4>
                                        </div>
                                        <div class="col-sm-12">
                                            <p class="norecordfound">You have no notes</p>
                                            <button type="submit" class="btn btn-dark com_btn" style="display: none;">Add Note</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="keyinfo noheight">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>Status History</h4>
                                        </div>
                                        <div class="col-sm-12">
                                            <p class="norecordfound">No information to display</p>                                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="keyinfo noheight">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>Event History</h4>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="block_tables_head">
                                                <div class="first">
                                                    <h4>Date</h4>
                                                </div>
                                                <div class="second">
                                                    <h4>Description</h4>
                                                </div>
                                            </div>
                                            <div class="block_tables" *ngFor="let event of company.event_history">
                                                <div class="first">
                                                    <p>{{event.date | date: 'MM/dd/yyyy'}}</p>
                                                </div>
                                                <div class="second">
                                                    <p>{{event.description}}</p>
                                                </div>
                                            </div>
                                        </div>        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="keyinfo noheight">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>Previous Company Names</h4>
                                        </div>
                                        <div class="col-sm-12">
                                            <p class="norecordfound">No information to display</p>
                                        </div>        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="keyinfo noheight event_history">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>Score History</h4>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="block_tables_head">
                                                <div class="first">
                                                    <h4>Date</h4>
                                                </div>
                                                <div class="first">
                                                    <h4>Score</h4>
                                                </div>
                                                <div class="first">
                                                    <h4>Description</h4>
                                                </div>
                                            </div>
                                            <div class="block_tables" *ngFor="let score of company.score_history">
                                                <div class="first">
                                                    <p>{{score.date | date: 'MM/dd/yyyy'}}</p>
                                                </div>
                                                <div class="first">
                                                    <p>{{score.companyValue}}</p>
                                                </div>
                                                <div class="first">
                                                    <p>{{score.ratingDescription}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div id="score_history_graph" style="height: 400px; width: 99%;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="keyinfo noheight">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>Limit History</h4>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="block_tables_head">
                                                <div class="first">
                                                    <h4>Date</h4>
                                                </div>
                                                <div class="first">
                                                    <h4>Limit</h4>
                                                </div>
                                            </div>
                                            <div class="block_tables" *ngFor="let limit of company.limit_history">
                                                <div class="first">
                                                    <p>{{limit.date | date: 'MM/dd/yyyy'}}</p>
                                                </div>
                                                <div class="first">
                                                    <p>£{{limit.companyValue.value}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6"><div id="limit_history_graph" style="height: 400px; width: 99%;"></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Group Structure">
                    <ng-template matTabContent>
                        <app-companyadvertgroup [company]="company"></app-companyadvertgroup>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Legal Information">
                    <ng-template matTabContent>
                        <app-legalinformation [company]="company"></app-legalinformation>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Image Documents">
                    <ng-template matTabContent>
                    <div class="summary_sec">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="keyinfo noheight">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>Filter By Category</h4>
                                        </div>
                                        <div class="col-sm-3 checkallskk">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="exampleCheck1" (change)="changeFiling($event)" [checked]="true">
                                                <label class="form-check-label" for="exampleCheck1">Show Filing Type</label>
                                            </div>                                        
                                        </div>
                                        <div class="col-sm-9">
                                            <div class="radio_flex">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" (change)="changeFilingType($event)" name="exampleRadios" id="exampleRadios2" value="">
                                                    <label class="form-check-label" for="exampleRadios2">
                                                    All Images
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" (change)="changeFilingType($event)" name="exampleRadios" id="exampleRadios2" value="CapitalDocuments">
                                                    <label class="form-check-label" for="exampleRadios2">
                                                    Capital Documents
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" (change)="changeFilingType($event)" name="exampleRadios" id="exampleRadios2" value="DirectorChanges">
                                                    <label class="form-check-label" for="exampleRadios2">
                                                    Director Changes
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" (change)="changeFilingType($event)" name="exampleRadios" id="exampleRadios2" value="Mortgage">
                                                    <label class="form-check-label" for="exampleRadios2">
                                                    Charges
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" (change)="changeFilingType($event)" name="exampleRadios" id="exampleRadios2" value="AnnualAccounts">
                                                    <label class="form-check-label" for="exampleRadios2">
                                                    Annual Accounts
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" (change)="changeFilingType($event)" name="exampleRadios" id="exampleRadios2" value="ChangeOfNames">
                                                    <label class="form-check-label" for="exampleRadios2">
                                                        Change of Names
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" (change)="changeFilingType($event)" name="exampleRadios" id="exampleRadios2" value="Liquidations">
                                                    <label class="form-check-label" for="exampleRadios2">
                                                    Liquidations
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" (change)="changeFilingType($event)" name="exampleRadios" id="exampleRadios2" value="NewIncorporations">
                                                    <label class="form-check-label" for="exampleRadios2">
                                                    New Incorporation
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" (change)="changeFilingType($event)" name="exampleRadios" id="exampleRadios2" value="AnnualReturns">
                                                    <label class="form-check-label" for="exampleRadios2">
                                                    Annual Returns
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" (change)="changeFilingType($event)" name="exampleRadios" id="exampleRadios2" value="ChangeOfRegisteredOffice">
                                                    <label class="form-check-label" for="exampleRadios2">
                                                        Changes of Registered Address
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" (change)="changeFilingType($event)" name="exampleRadios" id="exampleRadios2" value="Miscellaneous">
                                                    <label class="form-check-label" for="exampleRadios2">
                                                    Miscellaneous
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="keyinfo noheight imddocument">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>Image Documents</h4>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="block_tables_head">
                                                <div class="first"><h4>Date</h4></div>
                                                <div class="first " *ngIf="hidefiling"><h4>Filing Type</h4></div>
                                                <div class="first"><h4>Description</h4></div>
                                                <div class="first"><h4>View/Download</h4></div>
                                            </div>
                                            <div class="block_tables" *ngIf="!company.image_documents.length">
                                                Sorry, no results.
                                            </div>
                                            <div class="block_tables" *ngFor="let image of company.image_documents">
                                                <div class="first">
                                                    <p>{{image.filingDate }}</p>
                                                </div>
                                                <div class="first " *ngIf="hidefiling">
                                                    <p>{{image.document.imageTypeCode}}</p>
                                                </div>
                                                <div class="first">
                                                    <p>{{image.document.imageTypeDescription}}</p>
                                                </div>
                                                <div class="first">
                                                    <p (click)="downloadImage(image.imageId)">
                                                        <i class="fa fa-download" aria-hidden="true"></i>
                                                        View ({{image.additionalInformation.pages}})
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Payment Analysis">
                    <div class="summary_sec">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="keyinfo">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>Payment Information Summary</h4>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                            <div class="col-sm-6">
                                                <h4>Invoice Data & Aged Debt</h4>
                                                <div class="invoice_data">
                                                    <div class="data">
                                                        <p>Average Invoice Value</p>
                                                        <span>£{{(company.payment_data.averageInvoiceValue)?company.payment_data.averageInvoiceValue:0}}</span>
                                                    </div>
                                                    <div class="data">
                                                    <p>Invoices Available</p>
                                                    <span>{{(company.payment_data.paymentsOnFile)?company.payment_data.paymentsOnFile:0}}</span>
                                                    </div>
                                                    <div class="data">
                                                        <p>Paid</p>
                                                        <span>{{((company.payment_data.paymentsOnTime)?company.payment_data.paymentsOnTime:0)+((company.payment_data.paymentsPaidLate)?company.payment_data.paymentsPaidLate:0)}}</span>
                                                    </div>
                                                    <div class="data">
                                                        <p>Outstanding</p>
                                                        <span>{{((company.payment_data.numberOfInvoicesOutstandingWithinTerms)?company.payment_data.numberOfInvoicesOutstandingWithinTerms:0)
                                                            +((company.payment_data.numberOfInvoicesOutstanding1To30Days)?company.payment_data.numberOfInvoicesOutstanding1To30Days:0)
                                                            +((company.payment_data.numberOfInvoicesOutstanding31To60Days)?company.payment_data.numberOfInvoicesOutstanding31To60Days:0)
                                                            +((company.payment_data.numberOfInvoicesOutstanding61To90Days)?company.payment_data.numberOfInvoicesOutstanding61To90Days:0)
                                                            +((company.payment_data.numberOfInvoicesOutstanding91PlusDays)?company.payment_data.numberOfInvoicesOutstanding91PlusDays:0)}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                                <div class="col-sm-6">
                                                    <div class="graph_para">
                                                        <p>Trade Payment Data is real life payment experiences gathered from selected third party partners big and small who send us trade payment information on their complete sales ledger.</p>
                                                        <p>
                                                            The Age of Debt Graph highlights the current Age of Debt of a given company. The graph is separated into 5 categories. Generally, within terms and 0-30 days is a reasonable time for a company to pay their invoices and showcases good invoice payment whereas anything beyond that is indicative of poor invoice payment.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="btnggg text-center">
                                                <button type="submit" class="btn {{(paymentInfoType==0)?'btn-success':'btn-basic'}}" (click)="changePaymentInfoType(0);"># of invoices</button>
                                                <button type="submit" class="btn {{(paymentInfoType==1)?'btn-success':'btn-basic'}}" (click)="changePaymentInfoType(1);">% of invoices</button>
                                            </div>
                                            <div id="invoice_number_graph" style="height: 370px; width: 99%;"></div>
                                            <div id="invoice_percent_graph" style="height: 370px; width: 99%;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">                               
                            <div class="col-sm-12">
                                <div class="keyinfo noheight">
                                    <div class="block_tables_head">
                                        <div class="first">
                                            <h4>Accounting Package</h4>
                                        </div>
                                        <div class="second">
                                            <h4>Last Updated</h4>
                                        </div>
                                        <div class="second">
                                        <h4>Status</h4>
                                        </div>
                                        <div class="second">
                                            <h4 class="hiddenblank">Status</h4>
                                        </div>
                                    </div>
                                    <div class="block_tables">                                       
                                        <div class="first">
                                            <p class="hiddenblank">Blank</p>
                                        </div>
                                        <div class="second">
                                            <p>01/01/0001</p>
                                        </div>
                                        <div class="second">
                                            <p><i class="fa fa-comments bluess" aria-hidden="true"></i>
                                                PendingAuth</p>
                                        </div>
                                        <div class="second">
                                            <button type="submit" class="btn btn-success" style="display: none;">Start Sharing</button>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div class="row">     
                            <div class="col-sm-12">
                                <div class="keyinfo noheight">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>CCJ Data Trends</h4>
                                        </div>  
                                        <div class="col-sm-12">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic</p>
                                        </div>  
                                        <div class="col-sm-12">
                                            <p class="p-4">Graph</p>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div class="row">     
                            <div class="col-sm-12">
                                <div class="keyinfo noheight">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>Regional Trade Payment Data</h4>
                                        </div>  
                                        <div class="col-sm-12">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic</p>
                                            <button type="submit" class="btn btn-basic btn_com" style="display: none;">Export</button>
                                        </div>  
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div class="row">     
                            <div class="col-sm-12">
                                <div class="keyinfo noheight">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>Accumulative Invoice Trends</h4>
                                        </div>  
                                        <div class="col-sm-12">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic</p>
                                        </div>  
                                    </div>
                                </div>
                            </div>        
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="General Information">
                    <mat-tab-group [@.disabled]="true">       
                        <mat-tab label="Charges({{all_charge_details.length}})">
                            <div class="summary_sec">
                                <div class="bogbox">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>Charge Details</h4>
                                        </div>
                                        <div class="btnggg text-center">
                                            <button type="submit" class="btn {{(chargeFlag==0)?'btn-success':'btn-basic'}}" (click)="changeChargeDetails(0);">All ({{all_charge_details.length}})</button>
                                            <button type="submit" class="btn {{(chargeFlag==1)?'btn-success':'btn-basic'}}" (click)="changeChargeDetails(1);">Outstanding ({{outstanding_charge_details.length}})</button>
                                            <button type="submit" class="btn {{(chargeFlag==2)?'btn-success':'btn-basic'}}" (click)="changeChargeDetails(2);">Satisfied ({{satisfied_charge_details.length}})</button>
                                        </div>
                                    </div>
                                    <div class="row keyinfo noheight " *ngFor="let charge of company.general_info.charge_details">
                                        <div class="col-sm-6">
                                            <div class="infodest">
                                                <p><b>Mortgage Type</b></p>
                                                <span>{{charge.mortgageType}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p><b>Date Charge Registered</b></p>
                                                <span>{{charge.dateChargeRegistered | date: 'MM/dd/yyyy'}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p><b>Status</b></p>
                                                <span>{{charge.status}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p><b>Person(s) Entitled</b></p>
                                                <span>{{charge.personsEntitled}}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="infodest">
                                                <p><b>Date Charge Created</b></p>
                                                <span>{{charge.dateChargeCreated | date: 'MM/dd/yyyy'}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p><b>Date Charge Satisfied</b></p>
                                                <span>{{(charge.dateChargeSatisfied)?(charge.dateChargeSatisfied | date: 'MM/dd/yyyy'):'-'}}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="infodest">
                                                <p><b>Amount Secured</b></p>
                                                <span>{{charge.amountSecured}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p><b>Details</b></p>
                                                <span>{{charge.details}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Land Registry(0)">
                            <div class="summary_sec">
                                <div class="bogbox">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>Land Registry</h4>
                                        </div>                                   
                                    </div>
                                    <div class="row keyinfo noheight ">
                                        <!-- <div class="col-sm-6">
                                                    
                                            <div class="infodest">
                                                <p><b>Address</b></p>
                                                <span>{{company.summary.company_information.additional_information.ftse_index}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p><b>Tenure</b></p>
                                                <span>£{{company.summary.company_information.additional_information.share_capital}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p><b>Title Number</b></p>
                                                <span>£{{company.summary.company_information.additional_information.share_capital}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p><b>Property Type</b></p>
                                                <span>£{{company.summary.company_information.additional_information.share_capital}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p><b>Estate Type</b></p>
                                                <span>£{{company.summary.company_information.additional_information.share_capital}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p><b>Price Paid</b></p>
                                                <span>£{{company.summary.company_information.additional_information.share_capital}}</span>
                                            </div>
                                        
                                        </div>
                                        <div class="col-sm-6">
                                        
                                            <div class="infodest">
                                                <p><b>Proprietorship</b></p>
                                                <span>-</span>
                                            </div>
                                            <div class="infodest">
                                                <p><b>Proprietor Address</b></p>
                                                <span>{{company.summary.company_information.additional_information.charity_number}}</span>
                                            </div>
                                            <div class="infodest">
                                                <p><b>Date Proprietor Added</b></p>
                                                <span>£{{company.summary.company_information.additional_information.share_capital}}</span>
                                            </div>  
                                            <div class="infodest">
                                                <p><b>New Build</b></p>
                                                <span>£{{company.summary.company_information.additional_information.share_capital}}</span>
                                            </div>  
                                            <div class="infodest">
                                                <p><b>Date of Transfer</b></p>
                                                <span>£{{company.summary.company_information.additional_information.share_capital}}</span>
                                            </div>  
                                        </div> -->
                                        No information to display
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Acquisitions (0)">
                            <div class="summary_sec">
                                <div class="bogbox">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>Acquisitions</h4>
                                        </div>                                   
                                    </div>
                                    <div class="row keyinfo noheight ">
                                        <div class="col-sm-6">No information to display</div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="VAT Details({{(company.summary.company_information.key_information.vat_number)?1:0}})">
                            <div class="summary_sec">
                                <div class="bogbox">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h4>VAT Details</h4>
                                        </div>                                   
                                    </div>
                                    <div class="row keyinfo noheight ">
                                        <div class="col-sm-6">
                                            {{(company.summary.company_information.key_information.vat_number)?company.summary.company_information.key_information.vat_number:'No information to display'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
  
<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">         
            <div class="modal-body">
                <div class="container text-center">
                <div class="info_mess">
                    <div class="para">
                            <h4 class="mb-2">No credits available</h4>
                            <p class="mb-0 font20">There are no credits available to perform this action. Please add more credits.</p>
                        </div>                          
                    </div>
                </div>
            </div>
            <div class="modal-footer text-center">   
            <input type="button" class="btn btn-primary com_btn " name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
            <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
            </div>
        </div>
    </div>
</jw-modal>
  

    
<jw-modal id="confirm-modal" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-body p-4">
                <div class="container text-center">
                    <div class="info_mess">
                        <div class="para">
                            <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
                            
                        </div>                          
                    </div>
                </div>
            </div>
            <div class="modal-footer text-center">
                <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Confirm"  (click)="getDataByCompanyCodesubmit(companycodemodal,companynamemodal)"  />
                <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('confirm-modal');"/>
            </div>
        </div>
    </div>
</jw-modal>

