"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var customer_service_1 = require("../../../services/customer.service");
var country_service_1 = require("../../../services/country.service");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var modal_service_1 = require("../../../_services/modal.service");
var zoom_service_1 = require("../../../services/zoom.service");
var profile_model_1 = require("../../../models/overview/profile.model");
var app_global_1 = require("../../../app.global");
var RighttoworkdetailsComponent = /** @class */ (function () {
    function RighttoworkdetailsComponent(customerdetails, activatedRoute, country, modalService, formBuilder, router, zoomService) {
        this.customerdetails = customerdetails;
        this.activatedRoute = activatedRoute;
        this.country = country;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.router = router;
        this.zoomService = zoomService;
        this.loading = 0;
        this.current_stage = "";
        this.right = new profile_model_1.righttowork();
        this.defaultVal = "";
        this.currentDate = new Date();
        this.request_type = "";
        this.rtw_country = 0;
    }
    RighttoworkdetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.timeValues = app_global_1.AppGlobals.TIME_VALUES;
        this.empid = this.activatedRoute.snapshot.paramMap.get("id");
        this.customerdetails.getOverviewbyadmin({ 'id': this.empid }).subscribe(function (remaindata) {
            _this.employeedetails = remaindata;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
            _this.datalist = country;
        });
        this.meetingformGroup = this.formBuilder.group({
            startdate: ['', forms_1.Validators.required],
            starttime: ['', forms_1.Validators.required],
            endtime: ['', forms_1.Validators.required]
        });
        this.stage = 1;
        this.loading = 0;
    };
    RighttoworkdetailsComponent.prototype.onChangePage = function (id) {
    };
    RighttoworkdetailsComponent.prototype.sendrequest = function (id) {
        if (this.remainingcheck && this.remainingcheck.remiancheck < 1) {
            this.modalService.open('upgrade-plan');
        }
        else {
            this.submitted = false;
            this.meetingformGroup.reset();
            this.modalService.open(id);
            this.defaultVal = "";
            this.right = new profile_model_1.righttowork();
            this.employeeid = this.empid; //employeeid;
        }
    };
    RighttoworkdetailsComponent.prototype.uploadByAdmin = function (emp_type, type, empid) {
        if (this.remainingcheck && this.remainingcheck.remiancheck < 1) {
            this.modalService.open('upgrade-plan');
        }
        else {
            if (type == 'upload_now') {
                if (emp_type == 'visa') {
                    this.router.navigate(['/check-ai-visa-admin', empid], { queryParams: { is_rtw: 1 } });
                }
                else if (emp_type == 'passport') {
                    this.router.navigate(['/check-ai-passport-admin', empid], { queryParams: { is_rtw: 1 } });
                }
                else if (emp_type == 'document') {
                    this.router.navigate(['/check-document-admin', empid]);
                }
            }
            else if (type == 'add_new') {
                this.router.navigate(['/check-add-applicant', emp_type]);
            }
        }
    };
    Object.defineProperty(RighttoworkdetailsComponent.prototype, "returnForm", {
        get: function () {
            return this.meetingformGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    RighttoworkdetailsComponent.prototype.sendZoomLink = function (employeeid, id, meetingform) {
        var _this = this;
        if (this.remainingcheck && this.remainingcheck.remiancheck < 1) {
            this.modalService.close(id);
            this.modalService.open('upgrade-plan');
        }
        else {
            this.submitted = true;
            if (this.meetingformGroup.invalid) {
                return;
            }
            var startdate = new Date(meetingform.startdate.setHours(meetingform.starttime));
            var enddate = new Date(meetingform.startdate.setHours(meetingform.endtime));
            this.loading = 1;
            this.zoomService.sendZoomLinkWithIdentity({
                "startdate": startdate,
                "enddate": enddate,
                "email": "fareed@complygate.co.uk",
                "employeeid": this.empid,
                "requesttype": this.request_type
            }).subscribe(function (data) {
                _this.loading = 0;
                _this.modalService.close(id);
                _this.stage = 6;
            });
            this.employeeid = this.empid; // employeeid;
        }
    };
    RighttoworkdetailsComponent.prototype.sendrequestchargestage = function (stagechange, type, check_type, rtw_country) {
        if (type === void 0) { type = ''; }
        if (check_type === void 0) { check_type = ''; }
        if (rtw_country === void 0) { rtw_country = ''; }
        if (type) {
            this.current_stage = type;
        }
        console.log(stagechange);
        console.log(type);
        console.log(check_type);
        this.loading = 1;
        if (stagechange == 7) {
            this.request_type = check_type;
            if (this.current_stage == 'rtw') {
                this.modalService.open('confirm-modal');
            }
            else if (this.current_stage == 'adjusted_rtw') {
                this.sendrequest('sendrequestcharge');
            }
        }
        else if (stagechange == 3) {
            this.rtw_country = rtw_country;
            this.stage = stagechange;
        }
        else {
            this.stage = stagechange;
        }
        // if(this.current_stage=='rtw' && )
        // {
        //   if(stagechange==6){
        //     this.sendrequest('sendrequestcharge');
        //   }else if(stagechange==7){
        //     this.uploadByAdmin('passport','upload_now',this.empid);
        //   }else{
        //   }
        // }
        this.loading = 0;
    };
    RighttoworkdetailsComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return RighttoworkdetailsComponent;
}());
exports.RighttoworkdetailsComponent = RighttoworkdetailsComponent;
