"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./contactinformation.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./contactinformation.component");
var styles_ContactinformationComponent = [i0.styles];
var RenderType_ContactinformationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactinformationComponent, data: {} });
exports.RenderType_ContactinformationComponent = RenderType_ContactinformationComponent;
function View_ContactinformationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" contactinformation works!\n"]))], null, null); }
exports.View_ContactinformationComponent_0 = View_ContactinformationComponent_0;
function View_ContactinformationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contactinformation", [], null, null, null, View_ContactinformationComponent_0, RenderType_ContactinformationComponent)), i1.ɵdid(1, 114688, null, 0, i2.ContactinformationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ContactinformationComponent_Host_0 = View_ContactinformationComponent_Host_0;
var ContactinformationComponentNgFactory = i1.ɵccf("app-contactinformation", i2.ContactinformationComponent, View_ContactinformationComponent_Host_0, {}, {}, []);
exports.ContactinformationComponentNgFactory = ContactinformationComponentNgFactory;
