import { Component, OnInit } from '@angular/core';
import { UploadFileService } from './../../services/upload-file.service';
import { MyvisaService } from '../../services/myvisa.service';
import { DomSanitizer } from '@angular/platform-browser';

import { ChecksystemService } from '../../services/checksystem.service';

@Component({
  selector: 'app-aidocument',
  templateUrl: './aidocument.component.html',
  styleUrls: ['./aidocument.component.css']
})
export class AidocumentComponent implements OnInit {

  constructor(
    private uploadService: UploadFileService,
    private data: MyvisaService,
    private sanitizer: DomSanitizer,private check: ChecksystemService) { }

    oldbill:string;
    stage:any;
  error:boolean;
  imageurl:any;
  filerequirederror: any;
  filesizeerror: any;
  fileformaterror: any;
  file: any;lesssore:any;
  checkcount:any;
  loading:any;
  filename: any;
  output:any;iframevisasrc:any;
  selectedFiles: FileList;
  result:any;
  orgerror:boolean;
  orgcode:any;

  ngOnInit() {  
    this.filename = 'Choose file';
    this.imageurl ='';
    this.orgcode ='';
    this.stage =0;
    this.orgerror = false;
    this.loading=1;
    this.error = false;
    this.iframevisasrc ='';
    this.oldbill ='';
    this.fileformaterror = false;
  }

  selectFile(event)
   {
        this.iframevisasrc='';
        this.output=null;
        // this.loading=0;
        this.filesizeerror = false; 
        this.filerequirederror =false; 
        this.fileformaterror = false;
        var files = event.target.files;
        this.filename = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
          this.filesizeerror = true;    
        }
        else if ((filetype[filetype.length - 1] != "pdf") &&  (filetype[filetype.length - 1] != "png") &&  (filetype[filetype.length - 1] != "jpg") &&  (filetype[filetype.length - 1] != "jpeg"))  {
          this.fileformaterror = true;  
        }else{ 
          var imagepath = this.uploadService.uploadfile(this.file);
          // setTimeout(() => this.loading=1, 5000);
          setTimeout(() => this.imageurl = imagepath, 2000);
          // setTimeout(() => this.iframevisasrc = this.getSafeUrl("https://docs.google.com/gview?url=" + this.imageurl + "&embedded=true"), 7000);
          // this.imageurl = imagepath;
         // ;
         this.filerequirederror = false;
          console.log(this.imageurl);
          this.selectedFiles = files[0];
        }
  }

  changeOrganization(id){
    console.log(id);
    this.orgcode= id;
    if(id != ''){
      this.orgerror = false;
    }
  }
  
  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
  clearData(){
    this.imageurl ='';    
    this.iframevisasrc='';
    this.filerequirederror = false;
    this.orgerror = false;
    this.stage =0;
    this.orgcode='';
    this.error =false;
    this.filename = 'Choose file';
  }
  getRemainingChecks()
  {
    var ts  = { 'serviceid': 8 }; 
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      this.checkcount = remaindata;
    });
  }

  submitdata(){ 
    if(this.imageurl == '' || this.orgcode == ''){
      if(this.imageurl == '')
      {
        this.filerequirederror = true;
      }
      if(this.orgcode == '')
      {
        this.orgerror = true;
      }
      return;
    }
    
    this.iframevisasrc='';
    this.output=null;
    this.loading=0;
    setTimeout(() => this.iframevisasrc = this.getSafeUrl("https://docs.google.com/gview?url=" + this.imageurl + "&embedded=true"), 7000);

    setTimeout(() => this.loading=1, 7000);

  }
  reload()
  {
    this.iframevisasrc='';
    this.stage =1;
    this.iframevisasrc =this.getSafeUrl("https://docs.google.com/gview?url=" + this.imageurl + "&embedded=true");
    this.stage=0;
  }
  uploadfiletoAIverification(){
    
     this.loading=0;   
     this.stage =1;
      if(this.imageurl != '')
      {
        this.iframevisasrc='';
        var value3 = {invoiceurl: this.imageurl, modelid: this.orgcode}
        this.data.aidocumentverification(value3).subscribe((data: any) => {

          // this.output = data; 
          // this.error = false;
          // this.loading=1;
          // this.stage =1;

          
          if(data.name || data.billdate || data.amount || data.accountnumber || data.address){
          this.output = data; 
          this.error = false;
          this.loading=1;
          this.stage =1;
          }else{
            this.error = true;
            this.stage =1;
            this.loading=1; this.output=null;
          }


        });
      }else{
        this.stage =0;
      }
  }

}
