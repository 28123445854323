export class Emailer {
    subject: string;
    message: string = "<p>starting</p>";
    location: string;
    interdate: string;
    interviewstime: string;
    interviewetime: string;    
    candidateid: string;
    jobid: string;
    ssid: string;
    ismail: boolean;
}