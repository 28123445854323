<app-header>
</app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <a [routerLink]="['/check-aml-log-list/1']" *ngIf="ctype == 0" class="backlist mb-4 mr-0"><i aria-hidden="true" class="fa fa-angle-left"></i> Search Results</a>
            <a [routerLink]="['/check-business-aml-peps-sanction']" *ngIf="ctype == 1" class="backlist mb-4 mr-0"><i aria-hidden="true" class="fa fa-angle-left"></i> Business check Search</a>

            <div class="clearfix"></div>
            <div class="tabs__content">

                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Business Details </h5>
                            </div>

                                <div class="col-lg-6">
                                    <a type="button" (click)="norecordfound()" style="color: #fff;" [innerHtml]="downloadtext" target="_blank" class="btn btn-primary com_btn float-right" >No Record Found <i class="fa fa-Plus ml-2"></i></a>
                                    <a type="button" href="{{ url }}" *ngIf="url != ''"   target="_blank" class="btn btn-primary com_btn float-right" >Download <i class="fa fa-file-pdf-o ml-2"></i></a>
                                </div>
                        </div>

                        <div class="credit_data_show" *ngIf="result==true">
                            <div class="job_list_section visa_request_section" *ngFor="let company of searchresult.matches">

                                <div class="job_detils_date width10">
                                    <p class="job_address">ID</p>
                                    <p class="ninumber text-primary">{{company.business.id}} </p>
                                    <p class="job_profile" *ngIf="!company.business.id"> N/A</p>
                                </div>
                                <div class="job_detils_date width15">
                                    <p class="job_address">Business Name</p>
                                    <h5 class="job_profile ">{{ company.business.businessName}}</h5>
                                    <p class="job_profile" *ngIf="!company.business.businessName"> N/A</p>
                                   
                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address">Data Set</p>
                                    {{dataset(company.business)}}
                                    <p class="job_profile">{{datasetlist}}</p>
                                </div>
                                <div class="job_detils_date width33">
                                    <p class="job_address">Address</p>
                                    <p class="job_profile" *ngFor="let companyaddress of company.business.addresses;  let i=index ">
                                        <span *ngIf="i ==0">
                                        {{companyaddress.address1}}, {{ companyaddress.city }} {{ companyaddress.county }}- {{ companyaddress.postcode}}
                                    </span>
                                    </p>
                                    <p class="job_profile" *ngIf="company.business.addresses == 0"> N/A</p>
                                </div>


                                <div class="job_detils_date aligncenter">
                                    <div id="container" class="green_text">
                                        <div class="dot"></div>
                                        <div class="pulse"></div>

                                    </div>
                                    <p class="job_profile green_text">{{ company.score}} </p>
                                </div>
                                <div class="job_detils_date width13">

                                    <p class="job_address">
                                        <button type="submit" class="btn btn-primary com_btn float-right" (click)="openModalMastersystem('bussiness-pdf-viewer-visa',company)"><i class="fa fa-file-image-o"></i></button>
                                        <!-- <a [routerLink]="['/completebusscreditdetails',company.id]" class="btn btn-danger com_btn"><i class="fa fa-address-card" aria-hidden="true"></i></a> -->
                                        
                                    </p>
                                </div>
                            </div>



                        </div>


                        

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<jw-modal id="bussiness-pdf-viewer-visa" class="modal" role="dialog">
    <div role="document" class="formgroup" *ngIf="bussinessdetails">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{bussinessdetails.business.businessName}}</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('bussiness-pdf-viewer-visa');">
        <span aria-hidden="true">&times;</span>
      </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="strip_show">
                        <div class="row mr-0 ml-0">
                       
                            <div class="col-lg-2 mobilecentersh">
                                <div class="circle_credit_img">
                                    <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                                </div>
                               
                                
                            </div>
                            <div class="col-lg-10 d-flex align-items-center ">
                                <div class="category flex-wrap justify-content-start align-items-center text-center d-flex width100">
                                    <div class="catog_box" *ngIf="PEPs.length>0">
                                        <p>PEP</p>
                                        <span>{{PEPs.length}}</span>
                                    </div>
                                    <div class="catog_box" *ngIf="LawEnforcement.length>0">
                                        <p>Law Enforcement</p>
                                        <span>{{LawEnforcement.length}}</span>
                                    </div>
                                    <div class="catog_box" *ngIf="AdverseMedia.length>0">
                                        <p>Adverse Media</p>
                                        <span>{{AdverseMedia.length}}</span>
                                    </div>
                                    <div class="catog_box" *ngIf="PreviousSanctions.length>0">
                                        <p>IDV</p>
                                        <span>{{PreviousSanctions.length}}</span>
                                    </div>
                                    <div class="catog_box" *ngIf="FinancialRegulator.length>0">
                                        <p>Financial Regulator</p>
                                        <span>{{FinancialRegulator.length}}</span>
                                    </div>
                                    <div class="catog_box" *ngIf="DisqualifiedDirector.length>0">
                                        <p>Disqualified Director</p>
                                        <span>{{DisqualifiedDirector.length}}</span>
                                    </div>
                                    <div class="catog_box" *ngIf="Insolvency.length>0">
                                        <p>Insolvency</p>
                                        <span>{{Insolvency.length}}</span>
                                    </div>
                                    <div class="catog_box" *ngIf="CurrentSanctions.length>0">
                                        <p>Sanctions</p>
                                        <span>{{CurrentSanctions.length}}</span>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                        <hr/>
                        <div class="row mr-0 ml-0">
                            
                           
                            <div class="col-sm-12">
    
                           
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                    <div class="job_detils_date width70">
                                        <p class="job_address">Business Name</p>   
                                            <h5 class="job_profile">{{bussinessdetails.business.businessName}}</h5>
                                            <p class="job_profile" *ngIf="!bussinessdetails.business.businessName"> N/A</p>
                                        
                                    
                                    </div>
                                   
                                   
                                    
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                
                                    <div class="job_detils_date width30">
                                        <p class="job_address">Telephone</p>                           
                                        <p class="job_profile">  {{bussinessdetails.telephoneNumber}}
                                            <!-- <span *ngIf="bussinessdetails.person.telephoneNumber != ''">    (Home) </span> -->
                                                    
                                        </p>
                                        <p class="job_profile" *ngIf="!bussinessdetails.telephoneNumber"> N/A</p>
                                    </div>
                                    <div class="job_detils_date width30">
                                        <p class="job_address">Website</p>     
                                        <p class="job_profile"> {{bussinessdetails.website}}</p>
                                        <p class="job_profile" *ngIf="!bussinessdetails.website"> N/A</p>
                                            <!-- <span *ngIf="bussinessdetails.person.mobileNumber != ''">(Mob)</span></p> -->
                                    </div>
                                   
                                
                                
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                
                                
                                    <div class="job_detils_date width70">
                                        <p class="job_address">Address(es)   </p>                           
                                        <p class="job_profile" *ngFor="let companyaddress of bussinessdetails.business.addresses;">  {{companyaddress.address1}}, {{ companyaddress.city }} {{ companyaddress.county }}- {{ companyaddress.postcode}}</p>
                                        <p class="job_profile" *ngIf="bussinessdetails.business.addresses == 0"> N/A</p>
                                    </div>
                                   
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                
                                
                                    <div class="job_detils_date width100">
                                        <p class="job_address">Alias </p>                           
                                        <p class="job_profile" >
                                            <span class="aliesname" *ngFor="let companyaliases of bussinessdetails.business.aliases;"> {{companyaliases.businessName}}</span>
                                         </p>
                                         <p class="job_profile" *ngIf="bussinessdetails.business.aliases == 0"> N/A</p>
                                       
                                    </div>
                                
                                </div>
                               
                               
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                
                                
                                    <div class="job_detils_date width100">
                                        <p class="job_address">Reference Id </p>                           
                                        <p class="job_profile mt-1" >
    
                                            <span class="text-success"><b>{{bussinessdetails.business.id }}</b></span>
                                        </p>
                                    </div>
                                
                                </div>
                             </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-lg-2">
                            <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                        </div>
                        <div class="col-lg-10  d-flex">
                            <div class="category justify-content-between align-items-center text-center d-flex width100">
                                <div class="catog_box" *ngIf="PEPs.length>0">
                                    <p>PEP</p>
                                    <span>{{PEPs.length}}</span>
                                </div>
                                <div class="catog_box" *ngIf="LawEnforcement.length>0">
                                    <p>Law Enforcement</p>
                                    <span>{{LawEnforcement.length}}</span>
                                </div>
                                <div class="catog_box" *ngIf="AdverseMedia.length>0">
                                    <p>Adverse Media</p>
                                    <span>{{AdverseMedia.length}}</span>
                                </div>
                                <div class="catog_box" *ngIf="PreviousSanctions.length>0">
                                    <p>IDV</p>
                                    <span>{{PreviousSanctions.length}}</span>
                                </div>
                                <div class="catog_box" *ngIf="FinancialRegulator.length>0">
                                    <p>Financial Regulator</p>
                                    <span>{{FinancialRegulator.length}}</span>
                                </div>
                                <div class="catog_box" *ngIf="DisqualifiedDirector.length>0">
                                    <p>Disqualified Director</p>
                                    <span>{{DisqualifiedDirector.length}}</span>
                                </div>
                                <div class="catog_box" *ngIf="Insolvency.length>0">
                                    <p>Insolvency</p>
                                    <span>{{Insolvency.length}}</span>
                                </div>
                                <div class="catog_box" *ngIf="CurrentSanctions.length>0">
                                    <p>Sanctions</p>
                                    <span>{{CurrentSanctions.length}}</span>
                                </div>
                            </div>

                        </div>
                    </div> -->
                    <!-- <hr/> -->
                    <!-- <div class="all_detials">
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">
                                    <div class="catog_box width50">
                                        <p>Business Name</p>
                                        <span>{{bussinessdetails.business.businessName}}</span>
                                    </div>
                                    <div class="catog_box width50">
                                        <p>Alias</p>
                                        <p *ngFor="let companyaliases of bussinessdetails.business.aliases;">
                                            {{companyaliases.businessName}}
                                        </p>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box width50">
                                        <p>Telephone</p>
                                        <span>{{bussinessdetails.telephoneNumber}}</span>
                                    </div>
                                    <div class="catog_box width50">
                                        <p>Website </p>
                                        <span>{{bussinessdetails.website}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">


                                    <div class="catog_box width50">
                                        <p>Addresses</p>
                                        <p *ngFor="let companyaddress of bussinessdetails.business.addresses;">
                                            {{companyaddress.address1}}, {{ companyaddress.city }} {{ companyaddress.county }}- {{ companyaddress.postcode}}
                                        </p>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>

                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box">
                                        <span>Reference Id</span>
                                    </div>
                                    <div class="catog_box">
                                        <span>{{bussinessdetails.business.id }}</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                    </div> -->

                </div>
            </div>
            <div class="modal-footer">
                <button [routerLink]="['/completebusscreditdetails',logid,bussinessdetails.business.id]" type="button" class="btn btn-primary com_btn"> View All Details</button>
                <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal" (click)="closeModal('bussiness-pdf-viewer-visa');">Close</button>
            </div> 
        </div>
    </div>
</jw-modal>