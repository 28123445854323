import { Component, ChangeDetectionStrategy,OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import {  Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {  DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Rota } from '../../models/rota/rota.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WorklistService } from '../../services/worklist.service';
import { RotaService } from '../../services/rota.service';
import { CustomerService } from '../../services/customer.service';
import { ModalService } from '../../_services/modal.service';


import {
  startOfDay,
  endOfDay,
  setMonth,
  setYear,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { AppGlobals } from '../../app.global';

import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarWeekViewBeforeRenderEvent,
  CalendarDayViewBeforeRenderEvent
} from 'angular-calendar';
import { colors } from '../../models/rota/rota.model';


@Component({
  selector: 'app-rota',
  templateUrl: './rota.component.html',
  styleUrls: ['./rota.component.css']
})
export class RotaComponent  implements OnInit {
  constructor(
    private formBuilder: FormBuilder, 
    private router: Router,
    private workListService: WorklistService,
    private activatedRoute: ActivatedRoute,
    private rotaService: RotaService,
    private datePipe: DatePipe,
    private modal: NgbModal,
    private modalService: ModalService,
    private customerService: CustomerService
  ) { }
  @Input() view: string;

  @Input() viewDate: Date;

  @Input() locale: string = 'en';

  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();

  classactive:string;
  rotaAvailableDate = new Date();
  @ViewChild('modalContent')
  modalContent: TemplateRef<any>;
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  submitted = false;
  disableBtn: any;
  rota: any;
  Userid: any;
  minstartDate = new Date();
  minendDate = new Date();
  rotaformGroup: FormGroup;
  locationlist: any[];
  rotaList: any[];
  employee_id: any;
  allRotas: CalendarEvent[] = [];
  timeValues: any;
  leaveavailabledate = new Date();
  timeslotError = false;
  timeSlotErrorMessage: any;
  month: any;
  year: number;
  allRotaList: any;
  dayexpense:any;
  employeeName: any;

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    
    // this.leaveavailabledate.setDate(this.leaveavailabledate.getDate() - 31);
    this.month = (new Date()).getMonth() + 1;
    this.year = (new Date()).getFullYear();
    this.classactive = (new Date()).getMonth().toString();
    this.employee_id = this.activatedRoute.snapshot.paramMap.get("employeeid");
    this.view ='week';
    this.timeValues = AppGlobals.TIME_VALUES;
    this.viewDate = new Date();
    this.rotaformGroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeId: ['', Validators.required],
      userId: ['', Validators.required],
      locationId: ['', Validators.required],
      title: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      starttime: ['', Validators.required],
      endtime: ['', Validators.required]
    });

    this.workListService.getlocationlist().subscribe((locationlist: any) => {
      this.locationlist = locationlist;
    });


    var emplist=[];

    emplist = this.employee_id.split(',');

    if(emplist.length ==1)
    {
        this.customerService.getOverviewbyadmin({'id': this.employee_id}).subscribe((employeeData: any) => {
          this.employeeName = employeeData.fname + " " + employeeData.lname;
          
        });
    }
    else
    {

      this.customerService.employeedetailsrotasystem({'employeelist': this.employee_id}).subscribe((employeeData: any) => {

        console.log('employee list',employeeData);
        // this.employeeName = employeeData.fname + " " + employeeData.lname;
        
      });

    }

    this.bindallData();
  }

  updatemonth(id)
  {
    this.view =id;//'week';
    this.viewDate = new Date();
    
    this.viewChange = new EventEmitter();
    this.viewDateChange = new EventEmitter();

  }

  /**
   * This function is used to trigger function call on changing the month to produce data
   * @param inc 
   * @author Fareed Aarif 
   * @createOn 16-10-2020
   */

  addMonthsOfYear(inc) {
    this.classactive = inc;
    var date = this.viewDate;
    date = setMonth(date, inc);
    date = setYear(date, this.year);
    this.viewDate = date;
    this.month = inc + 1;
    this.bindallData();
    // this.bindalldatabymonth();
  }

  //this.view = 'week';
  snapDraggedEvents = true;

  dayStartHour=1;
  //viewDate = new Date();

  events: CalendarEvent[] = [
    {
      title: 'Draggable event',
      color: colors.yellow,
      start: addHours(startOfDay(new Date()), 5),
      end: addHours(startOfDay(new Date()), 8),
    },
    {
      title: 'A non draggable event',
      color: colors.blue,
      start: new Date()
    }
  ];

  refresh: Subject<any> = new Subject();

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.refresh.next();
  }

  public segmentIsValid(date: Date) {
    // valid if time is greater than 0800 andd less than 1700
    return date.getHours() >= 8 && date.getHours() <= 17;
  }


  beforeDayViewRender(day: CalendarDayViewBeforeRenderEvent): void {
    day.body.hourGrid.forEach( hour => {
      hour.segments.forEach( segment => {
        if ((!this.segmentIsValid(segment.date))) {
          delete segment.cssClass;
          segment.cssClass = 'cal-disabled';
        }
      });
    });
  }


  beforeWeekViewRender(body: CalendarWeekViewBeforeRenderEvent): void {
    body.hourColumns.forEach(hourCol => {
      hourCol.hours.forEach(hour => {
        hour.segments.forEach(segment => {
          if (!this.segmentIsValid(segment.date)) {
            delete segment.cssClass;
            segment.cssClass = 'cal-disabled';
          }
        });
      });
    });
  }

  /**
   * Returning function to validate all controls
   */

  get f() {
    return this.rotaformGroup.controls;
  }

closeModel(id)
{
  this.modalService.close('event-viewer');
  
  // this.
}

  /**
   * Function to save rota data
   * @param rota 
   * @param id 
   * @author Fareed Aarif
   * @createOn 08/10/2020
   */

  onSubmit(rota, id) {
    this.submitted = true;
    if (this.rotaformGroup.invalid) {
      return;
    }
    this.disableBtn = false;
    let newDate = new Date(rota.startDate);
    let endDate = new Date(rota.endDate);
    // console.log(rota.startDate);
    rota.start =this.datePipe.transform(new Date(rota.startDate),"dd MMM yyyy");
    rota.end =this.datePipe.transform(new Date(rota.endDate),"dd MMM yyyy");
    // console.log(rota.startDate);
    rota.startDate = new Date(rota.startDate);
    rota.endDate = new Date(rota.endDate);
    rota.startDate.setHours(rota.starttime);
    rota.endDate.setHours(rota.endtime);
    var retVal = [];
    var checkStartDate = rota.startDate;
    var checkEndDate = rota.endDate;
    while (rota.startDate <= rota.endDate) {
      retVal.push(new Date(rota.startDate));
      rota.startDate = addDays(rota.startDate, 1);
    }
    var itemsProcessed = 0;

    /* check time slot between entry dates */
    this.rotaService.getRotaByDate({'employeeId': this.employee_id, 'startDate': checkStartDate, 'endDate': new Date(checkEndDate.getFullYear(), checkEndDate.getMonth(), checkEndDate.getDate(), 23, 59, 59)}).subscribe((data: any) => {
      var checkRecord = true;
      
      data.forEach(element => {
        if(
          ((rota.starttime > element.starttime)
           && (rota.starttime < element.endtime))
           || ((rota.endtime > element.starttime)
           && (rota.endtime < element.endtime))
        )
        {
          checkRecord = false;
        }
      });
      // console.log(checkRecord);
      // return;
      if(!checkRecord)
      {
        this.timeslotError = true;
        this.disableBtn = true;
        this.timeSlotErrorMessage = "Timeslot is overlapping. Please change."
        return;
      }
      retVal.forEach(single_dates => {
        var rotaToSave = rota;
        rotaToSave.startDate = new Date(single_dates.setHours(rotaToSave.starttime));
        rotaToSave.endDate = new Date(single_dates.setHours(rotaToSave.endtime));
        this.rotaService.rotaCreateOrUpdate(rotaToSave).subscribe((data: any) => {
          itemsProcessed++;
          if(itemsProcessed === retVal.length) {
            this.rotaList = [];
            this.bindallData();
            document.getElementById('closeModal').click();
          }
        });
      });
    });
    
    // console.log(retVal);
    
  }

  closeModal(id){
    this.modalService.close(id);
  }

  bindallData() {
    // this.leavecount = new Leavecount();
    this.rotaService.getEmployeeRota({"id":this.employee_id}).subscribe((data: any) => {
      this.allRotas = []; //.destroy();// =null;
      data.forEach(element => {
        this.allRotas.push({
          id: element.employeerotaid,
          title: element.title,
          start: new Date(element.startdate),
          end: new Date(element.enddate),
          color: { primary: element.hcolor, secondary: element.fcolor },
          // allDay: element.edit,
          draggable: false,
          resizable: {
            beforeStart: true,
            afterEnd: true
          }
        });
        // console.log(new Date());
        this.refresh.next();
      });
    });

    // var db = {'year': this.year}
    // this.data.getemployeeleavecount(db).subscribe((countdata: any) => {

    //   this.leavecount = countdata;
    //   console.log(countdata);
    // });

  }

  /**
   * Add new rota time slot Popup
   * @param action 
   * @param event 
   * @param start 
   * @author Fareed Aarif
   * @createOn 08/10/2020
   */

  addNewRota(action: string, event: CalendarEvent, start: string): void {
    this.timeslotError = false;
    this.submitted = false;
    this.disableBtn = true;
    this.rota = new Rota();
    this.Userid = localStorage.getItem('userid');
    this.rota.userId = this.Userid;
    this.rota.employeeId = this.employee_id;
    let newDate = new Date(start);
    this.rota.startDate = newDate;//new Date(start);
    this.minstartDate = this.rotaAvailableDate;
    // this.minendDate = this.leaveavailabledate;
    this.rota.endDate = newDate;//new Date(start);
    this.rota.locationId = "";
    this.rota.starttime = 0;
    this.rota.endtime = 0;
    this.rota.employeerotaid = '0';
    this.modalData = { action, event };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  /**
   * Edit Rota Time Slot Popup
   * @param action 
   * @param event 
   * @author Fareed Aarif
   * @createOn 09/10/2020
   */

  editEvent(action: string, event: CalendarEvent) {
    this.timeslotError = false;
    this.submitted = false;
    this.disableBtn = true;
    this.rota = new Rota();
    this.rota.startDate =new Date(); //new Date().toLocaleDateString();// new Date(start);
    this.rota.endDate =new Date(); //new Date().toLocaleDateString(); //new Date(start);
    this.Userid = localStorage.getItem('userid');
    this.rota.employeeId = this.Userid;
    var rotaId = event.id;
    this.minstartDate = this.rotaAvailableDate;
    this.minendDate = this.rotaAvailableDate;
    this.rotaService.getRotaData({ 'id': rotaId }).subscribe((data: any) => {
      this.rota.title = data.title;
      this.rota.startDate = data.startdate;
      this.rota.endDate = data.enddate;
      this.rota.employeerotaid = data.employeerotaid;
      this.rota.starttime = data.starttime;
      this.rota.endtime = data.endtime;
      this.rota.locationId = data.locationid;
      this.rota.employeeId = data.employeeid;
      this.rota.userId = data.userid;
      this.rota.rstatus = data.rstatus;
    });
    this.modalData = { action, event };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  /**
   * Date change function working on Start Date and End Date
   * @param eventvalue 
   */

  onDateChange(eventvalue: any) {
    // console.log(eventvalue);
    // this.minendDate = eventvalue.value;
    // this.leave.end = eventvalue.value;
  }

  showAllEvents(id: string, exid: any) {
    // this.Userid = localStorage.getItem('userid');
    exid = this.datePipe.transform(new Date(exid),"dd MMM yyyy");
    this.allRotaList = [];
    // exid = new Date(exid);
    // exid.setHours(5);
    // exid.setMinutes(30);
    var timeArray = AppGlobals.TIME_VALUES;
    const databydate = { 'id': this.employee_id, 'exid': exid };
    this.rotaService.getrotabyemployeeanddate(databydate).subscribe((rotadata: any) => {
      rotadata.forEach(element => {
        element.starttime = this.searchVal(element.starttime, timeArray);
        element.endtime = this.searchVal(element.endtime, timeArray);
        this.allRotaList.push(element);
      });
      
      
    });
    this.modalService.open(id);
  }

  searchVal(nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i].key == nameKey) {
            return myArray[i].value;
        }
    }
}

}
