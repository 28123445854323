<app-header></app-header>

<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <div class="tabs__sontent">
                <!--<app-checkbillingshared *ngIf="ctype"></app-checkbillingshared>-->
                <app-invioce-shared  ></app-invioce-shared>                
                <div class="float-right invoice_btn_back">
                    <a class="backlist" *ngIf="ctype" [routerLink]="['/check-billing-invoice']"><i  aria-hidden="true" class="fa fa-angle-left"></i> Back to Invoice List </a>
                    <a class="backlist" *ngIf="!ctype" [routerLink]="['/my-billing']"><i  aria-hidden="true" class="fa fa-angle-left"></i> Back to Invoice List </a>
                </div>
                <div class="clearfix"></div>
                <div class="card mt-4 mb-4 ">
                    <div class="card-body p-0">
                        <div class="inheader">
                            <div class="invoice_inbox">
                                <div class="inbox_heading">
                                    <h2>All Invoices</h2>
                                </div>
                            </div>
                            <div class="invoice_bill_section">
                                <div class="inbox_bill_heading">
                                    <h2>Inva - 000221</h2>
                                    <div class="group_btn">
                                        <button type="submit" class="btn btn-primary">Download <i class="fa fa-file-pdf-o ml-2"></i></button>
                                       
                                    </div>                                   
                                </div>
                            </div>
                        </div>
                        <div class="invoice_group">                            
                            <div class="invoice_inbox with30">
                               
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail active">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice_mail">
                                    <div class="check">
                                        <input type="checkbox" class="form-control">
                                    </div>
                                    <div class="content_mail">
                                        <div class="details">
                                            <p class="person_name">Merrill Kervin</p>
                                            <span class="invoiceid">INV-00240</span> <span class="center_line">|</span> <span class="invoice_date">08/01/2020</span>
                                        </div>
                                        <div class="price_mail">
                                            <p class="invoice_price">$2,200.00</p>
                                            <span class="sent">SENT</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="invoice_bill_section width70">
                                
                                <div class="invoice_doc">
                                    <div class="invoice_box_page" id="contentToConvert">
                                        <div class="status_sent">
                                            <span>STATUS - SENT</span>
                                        </div>
                                        <div class="row p-2 pr-4 pl-4 pt-4 mt-3 ">
                                            <div class="col-md-12 alignivo">                                    
                                                <div class="invoice_content_show" *ngIf="invoicelist">
                                                    <h4>Invoice : COMP100{{ invoicelist.id }}</h4>
                                                    <div class="content">
                                                        <span>Invoice/Credit memo</span>
                                                        <p>Invoice</p>
                                                    </div>
                                                    <!-- <div class="content">
                                                        <span>Invoice Number:</span>
                                                        <p>COMP100{{ invoicelist.id }}</p>
                                                    </div> -->
                                                    <div class="content">
                                                        <span>Invoice Date:</span>
                                                        <p>{{ invoicelist.createat | date: 'dd MMM yyyy' }}</p>
                                                    </div>
                                                    <!-- <div class="content">
                                                        <span>Billing Date:</span>
                                                        <p>{{ invoicelist.createat | date: 'dd MM yyyy' }}</p>
                                                    </div> -->
                                                    <!-- <div class="content">
                                                        <span>Order Number</span>
                                                        <p>5039209277</p>
                                                    </div> -->
                                                    <div class="content">
                                                        <span>Customer</span>
                                                        <p>{{ invoicelist.companydetails.stuser }}</p>
                                                    </div>                                       
                                                </div>
                                                <div class="pl-0 billinfodd mt-2 text-right">
                                                    <img src="https://complygate.s3.amazonaws.com/dev/employee/2/1588290438622_MartinSantiago.png">
                                                    <h4>Complygate Limited</h4>
                                                    <p class="text-muted mb-1">4th Floor Colmore Gate,</p>
                                                    <p class="text-muted mb-1">2-6 Colmore Row</p>
                                                    <p class="text-muted mb-1">Birmingham, Uk - B3 2QD</p>
                                                    <p class="text-muted mb-1">VAT: GB 311 1059 64</p>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="invoice_details">
                                                    
                                                </div>
                                            </div>
                                           
                                        </div>
                                        <hr>
                                       
                                        <div class="row p-2 pr-5 pl-5 pt-3">
                                            <div class="box_invo width100">
                                                <div class="box width50">
                                                    <span class="head">Client Information</span>
                                                    <p class="clientname">To, {{ invoicelist.companydetails.clientname }}</p>
                                                    <div class="invoice_detailsshow">
                                               
                                                    <div class="payconfirm"  *ngIf="companylocationlist">
                                                        <!-- <i class="fa fa-map-marker mr-2" aria-hidden="true"></i> --> 
                                                        <p class="mb-1">{{companylocationlist.address1}} </p>
                                                        <p *ngIf="companylocationlist.address2"> {{ companylocationlist.address2 }}</p> <br>
                                                        <p class="mb-1" *ngIf="companylocationlist.cityname && companylocationlist.statename">{{ companylocationlist.cityname }}, {{ companylocationlist.statename }}</p>
                                                        <p class="mb-1">{{ companylocationlist.country.countryname }} - {{ companylocationlist.pincode }}</p>
                                                    </div>
                                                    
                                                   </div>
                                                </div>
                                                <div class="box width30">
                                                    <div class="content mb-3">
                                                        <span>Total Amount</span>
                                                        <p>£ 135.60</p>
                                                    </div>
                                                    <div class="content">
                                                        <span>Status</span>
                                                        <p class="paid text-danger"><b>Paid</b></p>
                                                    </div>
                                                </div>
                                                <div class="box width25">
                                                    <div class="content mb-4">
                                                         <p class="duedate">Due Date: 20/12/2021</p>
                                                    </div> 
                                                    <div class="content">
                                                        <p class="downloadpdf"><a href="#">Download <i class="fa fa-file-pdf-o ml-2" aria-hidden="true"></i>
                                                        </a></p>
                                                   </div>                                          
                                                </div>
                                            </div>
                                            
                                        </div>
            
                                        <div class="cart_option">
                                            <div class="col-sm-12">
                                                <div class="cart_heading">
                                                    <div class="cart_details_show">
            
                                                        <div class="checks_calc_amount width35">
                                                            <span class="pricing">Item Name	</span>
                                                        </div>
                                                        <div class="checks_calc_amount">
                                                            <span class="pricing">Item Price</span>
                                                        </div>
            
                                                        <div class="checks_calc_amount">
                                                            <span class="pricing">Item Quantity	</span>
                                                        </div>
                                                        <div class="checks_calc_amount">
                                                            <span class="pricing">Total</span>
                                                        </div>
            
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="col-sm-12" *ngIf="invoicelist">
                                                <div class="cart_details_show"  *ngFor="let vs of invoicelist.checkorderdetails">
                                                    <div class="checks_name">
                                                        <h4>{{ vs.servicesname.servicename }}</h4>
                                                    </div>
                                                    <div class="value_increase">
                                                        <div class="incr_decr">
                                                            £ {{vs.prices | number}}
                                                        </div>
                                                    </div>
                                                    <div class="checks_calc_amount width7">
                                                        <span class="pricing">{{vs.qty}}</span>
                                                    </div>
                                                    <div class="checks_calc_amount">
                                                        <span class="pricing"> £ {{vs.prices*vs.qty | number}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12" *ngIf="invoicelist">
                                                <div class="total_amount justify-content-end">
                                                    <div class="width25">
                                                        <div class="amount">
                                                            <span class="total_text">Subtotal:</span>
                                                            <span class="total_amount_show">£ {{ invoicelist.prices | number : '1.2-2' }}</span>
                                                        </div>
                                                        <div class="amount">
                                                            <span class="total_text">Tax: </span>
                                                            <span class="total_amount_show">£ {{ invoicelist.tax | number : '1.2-2' }}</span>
                                                        </div>
                                                        <hr>
                                                        <div class="amount">
                                                            <span class="total_text">Total amount: </span>
                                                            <span class="total_amount_show">£ {{ invoicelist.gtotal | number : '1.2-2' }}</span>
                                                        </div>
                                                    </div>
            
                                                </div>
                                            </div>
                                            
                                        </div>
            
                                        <div class="footer_align_ju">
                                            <p class="text-center query">If you have any questions about this invoice, please contact </p>
                                            <p class="text-center emphna"><span>info@complygate.co.uk</span> </p>
                                            <p class="text-center emphna">+44 121 655 0311</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                       
                        <div class="clearfix"></div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>