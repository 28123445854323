"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var checkservice_service_1 = require("../../../../services/checkservice.service");
var router_1 = require("@angular/router");
var AdversecredithistoryviewComponent = /** @class */ (function () {
    function AdversecredithistoryviewComponent(activatedRoute, checkService) {
        this.activatedRoute = activatedRoute;
        this.checkService = checkService;
        this.convertStringToArray = function (object) {
            var listMaster = [];
            if (typeof object === 'object') {
                console.log('fafadsfa');
                listMaster.push(object);
                return listMaster;
            }
            else {
                console.log(typeof object);
                object.forEach(function (entry3) {
                    listMaster.push(entry3);
                });
                return listMaster;
            }
        };
        this.convertStringToArrayyes = function (object) {
            var listMaster = [];
            if (typeof object === 'object') {
                console.log(object);
                console.log('yes');
                return 'object';
            }
            else {
                console.log('no');
                return 'array';
            }
        };
    }
    AdversecredithistoryviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            _this.historyid = params['id'];
            _this.checkService.adversecredithistoryview({ "id": _this.historyid }).subscribe(function (data) {
                _this.reportData = data.response_data.report;
                _this.searchingFields = data.response_data.search;
                _this.picklist = data.response_data.picklist;
                _this.creditrequest = data.response_data.creditrequest;
                _this.jobdetails = data.response_data.jobdetails;
            });
        });
    };
    return AdversecredithistoryviewComponent;
}());
exports.AdversecredithistoryviewComponent = AdversecredithistoryviewComponent;
