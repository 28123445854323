import { Injectable ,NgZone} from '@angular/core';


declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private zone: NgZone) { }


  determineLocalIp() {
    var localIp = sessionStorage.getItem('LOCAL_IP');
    console.log('Local IP address');
  
    //window.RTCPeerConnection = this.getRTCPeerConnection();
    //window.RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection; 
    const pc = new RTCPeerConnection({ iceServers: [{ urls: "stun:stun.l.google.com:19302" }] });
    //pc.createDataChannel('rtc');
    pc.createOffer().then(pc.setLocalDescription.bind(pc));

    pc.onicecandidate = ice => {
      this.zone.run(() => {
        if (!ice || !ice.candidate || !ice.candidate.candidate) {
          return;
        }

        localIp = this.ipRegex.exec(ice.candidate.candidate)[1];
        sessionStorage.setItem('LOCAL_IP', localIp);
        console.log('Local IP address', localIp);
        pc.onicecandidate = () => {};
        pc.close();
        return localIp;
      });
    };
  }
  private ipRegex = new RegExp(
    /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/
  );

}
