import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';



@Injectable({
  providedIn: 'root'
})
export class MyvisaService {
  [x: string]: any;
  private modals: any[] = [];
  constructor(private commonService: CommonService) {}

  getvisaAllList(value: any): Observable<any> {
    return this.commonService.formpost('/employee/employeevislist',value);      
  }

  getvisaAllListbyadmin(value: any): Observable<any> {
    return this.commonService.formpost('/employee/employeevislistbyadmin',value);      
  }

  getallvisaListbyadmin(value: any): Observable<any> {
    return this.commonService.formpost('/employee/allemployeevislistbyadmin',value);      
  }

  downloadVisa(value: any): Observable<any> {
    return this.commonService.formpost('/employee/downloadvisa',value); 
  }

  getvisaAllApprove(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/employeevisapprove');      
  }

  getvisaAllRejected(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/employeevisarejected');      
  }

  deletevisa(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/visadelete',value);
  }

  myvisadetailsUpdate(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/visaupdate',value);
  }
  
  myvisadetailsUpdatebyadmin(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/visaupdatebyadmin',value);
  }

  myvisadetailsUpdatebyadminrighttowork(value:any): Observable<any> {
    return  this.commonService.refereeformpost('/employee/visaupdatebyadminrighttowork',value);
  }

  getvisadetailsidbyadmin(value: any): Observable<any> {
    return this.commonService.formpost('/employee/employeevisadetailsbyadmin',value);      
  }

  visadetailsbyvisaid(value: any): Observable<any> {
    return this.commonService.formpost('/employee/visadetailsbyvisaid',value);      
  }
  visaupdateForAI(value: any): Observable<any> {
    return this.commonService.formpost('/employee/visaupdateAIDetailsbyadmin',value);      
  }  
  visaaipaymentstaus(value: any): Observable<any> {
    return this.commonService.formpost('/company/countaiinformation',value);      
  }
  
  visaailogupdate(value: any): Observable<any> {
    return this.commonService.formpost('/company/aivisalogupdate',value);      
  } 

  aidocumentverification(value: any): Observable<any> {
    return this.commonService.formpost('/complygate/facerecognize',value);      
  } 
  
  visadocumentupdate(value: any): Observable<any> {
    return this.commonService.formpost('/api/checkdocument',value);      
  } 

  righttoworkdocumentcheckdeduct(value: any): Observable<any> {
    return this.commonService.formpost('/api/checkquantitydeductforrighttoworkdoc',value);      
  } 

  visaaiai(value: any): Observable<any> {
    return this.commonService.formpostForAI('https://lon.netverify.com/api/netverify/v2/performNetverify',value);      
  }
}
