import { HeaderComponent } from '../../../user/header/header.component';
// import { EmployeeMaster } from './../models/employee_master';
// import { HeaderComponent } from './user/header/header.component';
import { Component, OnInit,Input } from '@angular/core';
import { MypassportService } from '../../../services/mypassport.service';
import { ModalService } from '../../../_services/modal.service';

import { FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { UploadFileService } from './../../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';

import { EmployeeService } from '../../../services/employee.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Passport } from '../../../models/passport/passport.model';
import { CountryService } from '../../../services/country.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../services/alert.service';
import { AicenterService } from '../../../services/aicenter.service';

@Component({
  selector: 'app-aipassport',
  templateUrl: './aipassport.component.html',
  styleUrls: ['./aipassport.component.css']
})
export class AipassportComponent implements OnInit {

  
  @Input() items: Array<any>;
  employeelist: any;
  pageOfItems: Array<any>;
  record: any;
  form: FormGroup;
  passformGroup: FormGroup;
  passportlist: any[];
  statuslist: any;
  passportDetail: any;//= new Passport();  
  countrylist: any[];
  Userid: any;
  listcheck: any;
  myfile: any;
  file: any;
  selectedFiles: FileList;
  passnumbererror: any;
  nationalityerror: any;
  passportdateoriginerror: any;
  iframesrc: any;
  imgsrc: any;
  buttoninsert: any;
  filesizeerror: any;
  fileformaterror: any;
  formattedMessage: any;
  messageSuccess:any;
  filename: any;
  disableBtn: any;
  permission: any;
  requestperm: any;
  empid:any;
  aipayment: boolean =false;

  submitted: boolean;

  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private data: MypassportService, 
    private country: CountryService,     
    private aicenter:AicenterService,
    private modalService: ModalService,
    
    private employee: EmployeeService, 
    private router: Router, 
    private alerts: AlertService
  ) { }

  ngOnInit() {
    

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;

    this.passformGroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      passportnumber: ['', Validators.required],
      placeofissue: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      passportissue: ['', Validators.required],
      passportexpiry: ['', Validators.required],
      iscurrent: [''],
    });
    
    this.aicenter.getcountpayment().subscribe((data: any) => {     
      this.aipayment = data.payment;
    });

    
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
   

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    
    var value3 = "{}";
    this.employee.getemployeelist(value3).subscribe((listdetails: any) => {
      this.employeelist = listdetails;
    });
    
	  this.loadalldata();
    

    this.passportDetail = new Passport();
    this.passportDetail.sstatus = 0;
  }

  loadalldata(){
    var data;
    if(this.empid !=''){
       data = { 'id': this.Userid, 'cstatus': [1, 2, 3], employeeid: this.empid };
    }
    else {
      data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    }
  
    this.data.getallpassportListbyadmin(data).subscribe((data: any) => {
      this.record  = data.length;

      if(this.record < 30){
        this.pageOfItems = data;
      }
      else{
           this.items =data;
      }

    });
  }

  changeEmployee(id: any) {
    this.empid = id;
    this.loadalldata();
  }
  
  
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  


  get f() { return this.passformGroup.controls; }


  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModal3(id: string, data: any) {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  closeModal(id: string) {
   
    this.modalService.close(id);
  }

  modalOpen(data: any) {
    this.disableBtn = true;
    this.passportDetail = new Passport();
    this.passportDetail = data;
  }

}
