<div class="invoice_box" *ngIf="ctype ==0">
   <ul>
      <li>
         <a [routerLink]="['/my-billing']" routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-file-text-o" aria-hidden="true"></i></span> <span class="conss">My Invoice</span></a>
      </li>
      <li>
         <a [routerLink]="['/check-payment-method']" routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-credit-card" aria-hidden="true"></i></span><span class="conss">Payment Method</span></a>
      </li>
      <li>
         <a [routerLink]="['/billing-information']" routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-print" aria-hidden="true"></i></span><span class="conss">Billing Information</span></a>
      </li>     
      <li>
         <a [routerLink]="['/change-plan']"  routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-random" aria-hidden="true"></i></span><span class="conss">Change Subscription </span></a>
      </li>
      <li>
         <a [routerLink]="['/check-add-credit']"  routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-plus" aria-hidden="true"></i></span><span class="conss">Add Checks</span></a>
      </li>
   </ul>
</div>


<div class="invoice_box" *ngIf="ctype ==1">
   <ul>
      <li>
         <a [routerLink]="['/my-billing']" routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-file-text-o" aria-hidden="true"></i></span> <span class="conss">My Invoice</span></a>
      </li>
      <li>
         <a [routerLink]="['/check-payment-method']" routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-credit-card" aria-hidden="true"></i></span><span class="conss">Payment Method</span></a>
      </li>     
      <li>
         <a [routerLink]="['/check-billing-information']" routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-print" aria-hidden="true"></i></span><span class="conss">Company Profile</span></a>
      </li>
      <li>
         <a [routerLink]="['/change-plan']"  routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-random" aria-hidden="true"></i></span><span class="conss">Business Credit Check</span></a>
      </li>
      <li>
         <a [routerLink]="['/check-add-credit']"  routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-plus" aria-hidden="true"></i></span><span class="conss">Add Checks</span></a>
      </li>
   </ul>
</div>



<div class="invoice_box" *ngIf="ctype ==2">
      <ul>
         <li>
            <a [routerLink]="['/my-billing']" routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-file-text-o" aria-hidden="true"></i></span> <span class="conss">My Invoice</span></a>
         </li>
         <li>
            <a [routerLink]="['/check-payment-method']" routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-credit-card" aria-hidden="true"></i></span><span class="conss">Payment Method</span></a>
         </li>
         <li>
            <a [routerLink]="['/check-billing-information']" routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-print" aria-hidden="true"></i></span><span class="conss">Billing Information</span></a>
         </li>        
         <li>
            <a [routerLink]="['/unlimited-package']"  routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-random" aria-hidden="true"></i></span><span class="conss">Unlimited Package </span></a>
         </li> 
         <li>
            <a [routerLink]="['/check-add-credit']"  routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-plus" aria-hidden="true"></i></span><span class="conss">Add Checks</span></a>
         </li>
      </ul>
   </div>


   <div class="invoice_box" *ngIf="ctype == 3">
      <ul>
         <li>
            <a [routerLink]="['/my-billing']" routerLinkActive="active-link" ><span><i class="fa fa-file-text-o" aria-hidden="true"></i></span> <span class="conss">My Invoice</span></a>
         </li>
         <li>
            <a [routerLink]="['/check-payment-method']" routerLinkActive="active-link" ><span><i class="fa fa-credit-card" aria-hidden="true"></i></span><span class="conss">Payment Method</span></a>
         </li>
         <li>
            <a [routerLink]="['/check-billing-information']" routerLinkActive="active-link" ><span><i class="fa fa-print" aria-hidden="true"></i></span><span class="conss">Company Profile</span></a>
         </li>    
          <li>
            <a [routerLink]="['/unlimited-package']"  routerLinkActive="active-link" ><span><i class="fa fa-random" aria-hidden="true"></i></span><span class="conss">Unlimited Package </span></a>
         </li> 
         <li>
            <a [routerLink]="['/check-add-credit']"  routerLinkActive="active-link" ><span class="d-flex"><i class="fa fa-plus" aria-hidden="true"></i></span><span class="conss">Add Checks</span></a>
         </li>
      </ul>
   </div>