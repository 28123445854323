<style>
    .jw-modal {
        max-width: 50%;
    }
    .invalid-feedback {
        display: block !important;
    }
</style>
<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__content">
                    <!-- <div class="textrotate2">
                        <ul>
                            <li> <a [routerLink]="['/my-worklist']">My Timesheet</a></li>
                            <li><a [routerLink]="['/my-worklist-report']">Report</a></li>
                        </ul>
                    </div> -->
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row">
                                <div class="col-lg-12">
                                    <ul class="tabs__head montharea">
                                        <li><a [ngClass]="{'active': classactive == '0'}" (click)="addMonthsOfYear(0);">January </a></li>
                                        <li><a [ngClass]="{'active': classactive == '1'}" (click)="addMonthsOfYear(1);">February </a></li>
                                        <li><a [ngClass]="{'active': classactive == '2'}" (click)="addMonthsOfYear(2);">March </a></li>
                                        <li><a [ngClass]="{'active': classactive == '3'}" (click)="addMonthsOfYear(3);">April </a></li>
                                        <li><a [ngClass]="{'active': classactive == '4'}" (click)="addMonthsOfYear(4);">May</a></li>
                                        <li><a [ngClass]="{'active': classactive == '5'}" (click)="addMonthsOfYear(5);">June </a></li>
                                        <li><a [ngClass]="{'active': classactive == '6'}" (click)="addMonthsOfYear(6);">July </a></li>
                                        <li><a [ngClass]="{'active': classactive == '7'}" (click)="addMonthsOfYear(7);">August </a></li>
                                        <li><a [ngClass]="{'active': classactive == '8'}" (click)="addMonthsOfYear(8);">September </a></li>
                                        <li><a [ngClass]="{'active': classactive == '9'}" (click)="addMonthsOfYear(9);">October </a></li>
                                        <li><a [ngClass]="{'active': classactive == '10'}" (click)="addMonthsOfYear(10);">November </a></li>
                                        <li><a [ngClass]="{'active': classactive == '11'}" (click)="addMonthsOfYear(11);">December </a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row row-sm mb-4 mt-4" >
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-md">
                                            <ng-template #modalContent let-close="close">
                                                <div class="modal-header">
                                                    <h5 class="modal-title">Activity {{headerdate}}</h5>
                                                    <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div *ngIf="editMsg" class="invalid-feedback errorbox">
                                                    <div>{{editMsg}}</div>
                                                </div>
                                                <div *ngIf="findLeave" class="invalid-feedback errorbox">
                                                    <div>Note: You have applied a leave for this date.</div>
                                                </div>
                                                <div class="modal-body pt-0">
                                                   
                                                    <form [formGroup]="activityformGroup" #activityForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(activityForm.value,'modalContent')">
                                                        <input type="hidden" formControlName="id" class="form-control" name="id" id="id" ngModel="{{activity.employeehourapplyid}}">
                                                        <input type="hidden" formControlName="hourremarkid" class="form-control" required name="hourremarkid" id="hourremarkid" ngModel="{{activity.hourremarkid}}">
                                                        <input type="hidden" formControlName="employeeid" class="form-control" class="form-control" required name="employeeid" id="employeeid" ngModel="{{activity.employeeid}}">
                                                        <!-- <input type="hidden" formControlName="start" class="form-control" class="form-control" required name="start" id="start" ngModel="{{activity.start}}">
                                                        <input type="hidden" formControlName="end" class="form-control" class="form-control" required name="end" id="end" ngModel="{{activity.end}}"> -->
                                                     
                                                        <div class="formgroup">
                                                            <div class="row-sm mg-t-10">
                                                                <label>Hours*</label>
                                                                <input type="number" formControlName="ahour" class="" name="ahour" id="ahour" class="form-control" placeholder="eg: 10.50" [ngClass]="{ 'is-invalid': submitted && f.ahour.errors }" ngModel="{{ activity.ahour }}"  value="{{ activity.ahour }}" maxlength="5">
                                                                <div *ngIf="submitted && f.ahour.errors" class="invalid-feedback">
                                                                    <div *ngIf="f.ahour.errors.required">Please enter hours</div>
                                                                    <div *ngIf="f.ahour.errors.pattern">Please enter valid hours</div>
                                                                    <div *ngIf="f.ahour.errors.max">Maximum 24 hours allowed </div>
                                                                    <div *ngIf="f.ahour.errors.min">Minimum 1 hours</div>
                                                                </div>
                                                            </div>
                                                            <div class="row-sm mg-t-10">
                                                                <label>Hour Type*</label>
                                                                <select class="form-control" name="hourtype" id="hourtype" ngModel='{{ activity.hourtype }}' formControlName="hourtype" [ngClass]="{ 'is-invalid': submitted && f.hourtype.errors }">
                                                                    <option value="">Select Hour Type</option>
                                                                    <option *ngFor="let htype of hourtypelist" value="{{ htype.id }}">{{ htype.typename }}</option>>
                                                                </select>
                                                                <div *ngIf="submitted && f.hourtype.errors" class="invalid-feedback">
                                                                    <div *ngIf="f.hourtype.errors.required">Please select hour type</div>
                                                                </div>
                                                            </div>
                                                            <div class="row-sm mg-t-10">
                                                                <label>Project*</label>
                                                                <select class="form-control" name="projectid" id="projectid" ngModel='{{ activity.projectid }}' [ngClass]="{ 'is-invalid': submitted && f.projectid.errors }" formControlName="projectid">
                                                                    <option value="">Select Project</option>
                                                                    <option *ngFor="let ptype of projectlist" value="{{ ptype.projectid }}">{{ ptype.projectname }}</option>>
                                                                </select>
                                                                <div *ngIf="submitted && f.projectid.errors" class="invalid-feedback">
                                                                    <div *ngIf="f.projectid.errors.required">Please select project</div>
                                                                </div>
                                                            </div>
                                                            <div class="row-sm mg-t-10" [style.display]="activity.employeehourapplyid != 0 ? 'none' : 'block'">
                                                                <label>Start Date*</label>
                                                                <mat-form-field class="datecontrol ">
                                                                    <input matInput #startPicker (click)="picker.open()" [matDatepicker]="picker" (dateChange)="onDateChange($event)" [ngClass]="{ 'is-invalid': submitted && f.start.errors }" [min]="minstartDate" formControlName="start" name="start" [ngModel]="activity.start" class="form-control">
                                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                                    <mat-datepicker #picker></mat-datepicker>
                                                                </mat-form-field>
                                                                <div *ngIf="submitted && f.start.errors" class="invalid-feedback">
                                                                    <div *ngIf="f.start.errors.required">Please choose start date </div>
                                                                </div>
                                                            </div>
                                                            <div class="row-sm mg-t-10"  [style.display]="activity.employeehourapplyid != 0 ? 'none' : 'block'">
                                                                <label>End Date*</label>
                                                                <mat-form-field class="datecontrol">
                                                                    <input matInput #endPicker (click)="picker2.open()" [matDatepicker]="picker2" [ngClass]="{ 'is-invalid': submitted && f.end.errors }" formControlName="end" name="end" [ngModel]="activity.end" [min]="activity.start" class="form-control">
                                                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                                    <mat-datepicker #picker2></mat-datepicker>
                                                                </mat-form-field>
                                                                <div *ngIf="submitted && f.end.errors" class="invalid-feedback">
                                                                    <div *ngIf="f.end.errors.required">Please choose end date</div>
                                                                </div>
                                                            </div>
                                                            <div class="row-sm mg-t-10">
                                                                <label>Office Location*</label>
                                                                <select class="form-control" name="locationid" id="locationid" ngModel='{{ activity.locationid }}' [ngClass]="{ 'is-invalid': submitted && f.locationid.errors }" formControlName="locationid">
                                                                    <option value="">Select Office Location</option>
                                                                    <option *ngFor="let ltype of locationlist" value="{{ ltype.companylocationdetailsid }}">{{ltype.address1 }} - {{ltype.cityname }}, {{ltype.statename }}</option>
                                                                </select>
                                                                <div *ngIf="submitted && f.locationid.errors" class="invalid-feedback">
                                                                    <div *ngIf="f.locationid.errors.required">Please select location</div>
                                                                </div>
                                                            </div>
                                                            <div class="row-sm mg-t-10">
                                                                <label>Remarks*</label>
                                                                <textarea formControlName="hourremark" [ngClass]="{ 'is-invalid': submitted && f.hourremark.errors }" class="form-control" required name="hourremark" id="hourremark" ngModel="{{activity.hourremark}}" maxlength="800" placeholder="Enter remarks here">{{ activity.hourremark }}</textarea>
                                                                <div *ngIf="submitted && f.hourremark.errors" class="invalid-feedback">
                                                                    <div *ngIf="f.hourremark.errors.required">Please enter remarks</div>
                                                                </div>
                                                            </div>
                                                            <div class="row-sm mg-t-10 modal-footer pr-0">
                                                                <button type="submit" class="btn btn-primary com_btn" [disabled]="!disableBtn">Save</button>
                                                                <button *ngIf="(activity.employeehourapplyid != 0) && (activity.hstatus == '1')" type="button" class="btn btn-danger com_btn" (click)="onDelete(activity.employeehourapplyid)">Delete</button>
                                                                <button type="button" class="btn btn-secondary com_btn" id="closeModal" (click)="close()">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </ng-template>
                                           
                                            <div class="floatleft">
                                                <div class="col-md-12">
                                                    <h3 class="mytimesheet_heading"><span class="redcolor_text font22">My Timesheet</span> <span class="graycolor_text fonts16">({{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }})</span> </h3>
                                                </div>
                                            </div>
                                            <div class="floatright">


                                                <div class="col-md-12 form-inline ">
                                                    <select name="year" id="year" class="form-control yearselect" (change)="onyearSubmit($event.target.value)" ngModel='{{ year }}'>
                                                        <option value="{{y}}" *ngFor="let y of yearList">{{ y }}</option>
                                                    </select>
                                                    <button type="submit" class="btn btn-danger com_btn" (click)="addNewEvent('Add',event)">Add Timesheet</button>
                                                    <button type="button" class="btn btn-dark com_btn" (click)="openlinemanagerModal('line-manager')">Line Manager</button>
                                                    <a [routerLink]="['/my-worklist-report']" routerLinkActive="active-link" class="toggle_button"><span class="lnr lnr-list"></span>    <span class="tooltip_show">List View</span></a>
                                                    <a [routerLink]="['/my-worklist']" routerLinkActive="active-link" class="toggle_button"><span class="lnr lnr-calendar-full"></span>  <span class="tooltip_show">Calendar View </span></a>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                            <div [ngSwitch]="view" class="row row-sm maine222" style="margin:20px auto; width: 98%;">
                                                <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [cellTemplate]="customCellTemplate" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
                                                    class="pre_tag">
                                                </mwl-calendar-month-view>
                                            </div>
                                            <ng-template #customCellTemplate let-day="day" let-locale="locale">
                                                <div class="cal-cell-top-add">
                                                    <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                                                    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
                                                    <span class="cal-day-number-add" *ngIf="day.date <= leaveavailabledate" style="border: 1px solid; border-radius: 5px;margin-right: 5px;margin-top: 5px;" (click)="addEvent('Add',event,day.date)"><i class="fa fa-fw fa-plus"></i></span>
                                                </div>
                                                <div *ngIf="day.events.length > 0">
                                                    <div *ngFor="let event of day.events; let i = index" [attr.data-index]="i">
                                                        <div *ngIf="i < 2">
                                                            <div class="cal-events">
                                                                <div class="cal-courseevent" [ngClass]="event.Type" [style.background-color]="event.color.primary" [style.color]="event.color.secondary">
                                                                    <small [style.background-color]="event.color.primary"> <a (click)="showAllEvents('event-viewer',event.id)">{{
                                      event.title | slice:0:20 }}{{event.title.length > 20 ? '...' : ''}}</a>
                                    <i *ngIf="event.allDay == true" class="fa fa-fw fa-pencil" (click)="editEvent('Edited',event)"></i></small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="i == 2">
                                                            <small *ngIf="day.events.length > 2" class="exdot" (click)="showAllEvents('event-viewer',event.id)"><i
                                  class="fa fa-fw fa-circle"></i><i class="fa fa-fw fa-circle"></i><i class="fa fa-fw fa-circle"></i></small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                                <!-- col-8 -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .poplist {
        margin-bottom: 10px;
    }
    
    .poplist p {
        margin-bottom: 0px;
    }
</style>

<jw-modal id="event-viewer" class="modal listofwork" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Timesheet</h5>
                <button type="button" (click)="closeModal('event-viewer');" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="leave_apply_section">
                        <div class="white_box_shadow leave_width" *ngFor="let exp of dayexpense">
                            <div class="leave_app_head">
                                <div class="heading">
                                    <span class="leave_type alignmiddle">Total Hours: <span class="hours">{{ exp.ahour }}</span></span>
                                </div>
                                <span class="btn btn-danger" *ngIf="exp.hstatus == '3'"> <i class="fa fa-clock-o" aria-hidden="true"></i><span>Rejected</span> </span>
                                <span class="btn btn-warning" *ngIf="exp.hstatus == '1'"> <i class="fa fa-clock-o" aria-hidden="true"></i> <span>Pending</span> </span>
                                <span class="btn btn-sucess" *ngIf="exp.hstatus == '2'"> <i class="fa fa-clock-o" aria-hidden="true"></i> <span>Approved</span> </span>
                            </div>
                            <div class="expenbox_details_sec leave_details_sec">
                                <div class="expenbox width100">
                                    <div class="leave_icon">
                                        <i class="fa fa-address-book" aria-hidden="true"></i>
                                        <p class="leavefrom">
                                            <span>Project : </span>{{ exp.project.projectname }}
                                        </p>
                                    </div>
                                </div>
                                <div class="expenbox width100" >
                                    <div class="leave_icon">
                                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                                        <p class="leavefrom">
                                            <span>Hour type : </span>{{ exp.hourtype.typename }}
                                        </p>
                                    </div>
                                </div>
                                <div class="expenbox width100">
                                    <div class="leave_icon">
                                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                                        <p class="leavefrom">
                                            <span>Office Location : </span>{{ exp.location.address1 }} {{ exp.location.address2 }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="leave_description">
                                <p>
                                    <i>
                                        {{exp.remark.hourremark }}  
                                    </i>
                                </p>
                            </div>
                            <div class="leave_edit_cane_btn float-right">
                                <button class="btn btn-danger" type="submit" (click)="editPopUpEvent('Edited',exp.employeehourapplyid,event)" *ngIf="exp.hstatus == '1'">Edit Timesheet</button>
                                <!-- <button class="btn btn-danger" type="submit">View</button> -->
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row-sm mg-t-10 mg-b-10 float-right">
                        <button class="btn btn-secondary com_btn" type="submit" (click)="closeModal('event-viewer');">Cancel</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</jw-modal>


<jw-modal id="line-manager" class="modal listofwork" role="dialog">
    <div role="document" class="formgroup">
        <div class="bd-example-modal-lg">
            <div class="modal-dialog modal-lg">
                <div class="modal-content" >
                    <div class="modal-header">
                        <h4 class="modal-title" id="myLargeModalLabel">Line Manager</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true" (click)="closeModalWork('line-manager');">×</span>
                    </button>
                    </div>
                    <div class="modal-body">
                        <div class="container">
                                  
                            <!-- <div>
                              {{linemanager.fname}}
                            </div> -->
                            <div class="job_list_section employee_list_section" *ngIf="linemanager">
                                <div class="job_detils_date">
                                    <div class="visa_user_pic">
                                     
                                        <img *ngIf="linemanager.imagename" src="{{linemanager.imagename}}" class="img width100" alt="{{ linemanager.fname }} {{ linemanager.mname }} {{ linemanager.lname}}">
                                        <img *ngIf="linemanager.gender == true && (linemanager.imagename == null || linemanager.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ linemanager.fname }} {{ linemanager.mname }} {{ linemanager.lname}}">
                                        <img *ngIf="linemanager.gender == false && (linemanager.imagename == null || linemanager.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ linemanager.fname }} {{ linemanager.mname }} {{ linemanager.lname}}">
                                    </div>

                                </div>
                                <div class="job_detils_date width30">
                                    <h5 class="job_profile font_17">{{linemanager.fname}} {{linemanager.mname}} {{linemanager.lname}}</h5>
                                    <p class="email ellipsis"><i aria-hidden="true" class="fa fa-envelope-o"></i> {{linemanager.emailid}}</p>
                                    <p class="phone"><i aria-hidden="true" class="fa fa-phone"></i> {{linemanager.phonenumber}}</p>
                                </div>
                                <div class="job_detils_date width15">
                                    <p class="job_address"> NI Number</p>
                                    <p class="job_profile">{{linemanager.ninumber}}</p>
                                </div>
                                <div class="job_detils_date width15">
                                    <p class="job_address">Employee ID</p>
                                    <p class="job_profile"> {{linemanager.employeeid}}</p>
                                </div>
                                <div class="job_detils_date width22">
                                    <p class="job_address">Employee Role</p>
                                    <p class="badge badge-danger ellipsis width100 text-left" *ngIf="linemanager.emprole">{{linemanager.emprole}} </p>
                                    <p class="badge badge-dark ellipsis width100 text-left" *ngIf="!linemanager.emprole">N/A</p>
                                </div>
                                <!-- <div class="job_detils_date  width13 d-flex aligncenter">
                                    <a type="submit" class="btn btn-primary com_btn"  *ngIf="empperm > 2" [routerLink]="'/employeedetails/'+ employee.employeemasterid">View Details</a>
                                  

                                </div> -->
                            </div>  

                            
                            <div class="job_list_section employee_list_section" *ngIf="!linemanager">
                                No Line Manager
                            </div>

                            <!-- <app-mycurrentemployment></app-mycurrentemployment>  -->
                            
                           
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</jw-modal>