<div class="updatepass">
    <app-header></app-header>
</div>
<div class="signin-wrapper">
    <div class="new_login">
        <div class="login_section">
            <a class="login_logo"  [routerLink]="['/']" ><img src="/assets/img/logo.png" alt=""></a>
            <div class="signin-box">
                <form #loginForm [formGroup]="passwordForm" class="login_form" (ngSubmit)="Reset()">
                    <div class="form-group mg-b-30 invalid-feedback text-center" *ngIf="error==false">
                        <div class="input-group">
                            Please enter correct current password!
                        </div>
                    </div>
                    <div class="form-group mg-b-30">
                        <div class="input-group">
                            <!-- <label class="col-sm-4 form-control-label">Current Password</label> -->
                            <input type="password" formControlName="oldPassword" name="oldPassword" #oldPassword [(ngModel)]="user.oldPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.oldPassword.errors }" placeholder="Current Password" class="form-control"
                                required />
                            <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-lock"
                  aria-hidden="true"></i></span></div>
                            <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
                                <div *ngIf="f.oldPassword.errors.required">Please enter current password</div>
                                <div *ngIf="f.oldPassword.errors.minlength">Password must be minimum 8 characters long</div>
                                <div *ngIf="f.newPassword.errors.pattern">Password must contain a Lowercase, an Uppercase letter, Numbers and Special characters</div>
                            </div>
                        </div>
                    </div>
                    <!-- row -->
                    <div class="form-group mg-b-30">
                        <div class="input-group">
                            <input type="password" class="input-box" formControlName="newPassword" name="newPassword" #newPassword [(ngModel)]="user.newPassword" placeholder="New Password" class="form-control" required [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }"/>
                            <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-lock"
                  aria-hidden="true"></i></span></div>
                            <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                                <div *ngIf="f.newPassword.errors.required">Please enter new password</div>
                                <div *ngIf="f.newPassword.errors.minlength">Password must be minimum 8 characters long</div>
                                <div *ngIf="f.newPassword.errors.pattern">Password must contain a Lowercase, an Uppercase letter, Numbers and Special characters</div>
                            </div>
                            <!-- <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
              <mat-error *ngIf="passwordForm.hasError('required', 'newPassword')">
                Please enter your New Password
              </mat-error>
            </div> -->
                        </div>
                    </div>
                    <div class="form-group mg-b-30">
                        <div class="input-group">
                            <input type="password" formControlName="confirmPassword" name="confirmPassword" #confirmPassword [(ngModel)]="user.confirmPassword" placeholder="Confirm Password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"/>
                            <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-lock" aria-hidden="true"></i></span></div>
                            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                <div *ngIf="f.confirmPassword.errors.required">Please enter confirm new password</div>
                                <div *ngIf="f.confirmPassword.errors.minlength">Password must be minimum 8 characters long</div>
                                <div *ngIf="f.confirmPassword.errors.pattern">Password must contain a Lowercase, an Uppercase letter, Numbers and Special characters</div>
                            </div>
                            <div class="invalid-feedback" *ngIf="passwordForm.hasError('notSame')">
                                <mat-error >Password and confirm password are not same</mat-error>
                            </div>
                            <!-- <div   *ngIf="passwordForm.controls['newPassword'].value!= passwordForm.controls['confirmPassword'].value" class="invalid-feedback">
                  Password and confirm password are not same.</div> -->
                        </div>
                    </div>
                    <!-- <div class="form-group mg-b-30">
          <div class="input-group">
            <input type="checkbox" name="browser[]" value="1" data-parsley-mincheck="2" data-parsley-class-handler="#cbWrapper"
              data-parsley-errors-container="#cbErrorContainer" required="" data-parsley-multiple="browser" class="remcheck"><span>Remember
              me next Time</span>
          </div>
        </div>row -->
                    <!-- <div class="form-group mg-b-30">
          <div class="input-group"> -->
                    <button class="btn btn-primary btn-block btn-signin" style="width:100%" type="submit" [disabled]="!disableBtn">Update Password</button>
                    <!-- <a [routerLink]="['/login']" class="fortgot text-center col-sm-12">Back to Login</a> -->
                    <!-- </div> form-layout-footer -->
                    <!--</div> col-8 -->
                </form>
                <!-- </div> -->
            </div>
            <!-- col-8 -->
        </div>
        <div class="graphic_section">
            <div class="slogan">
                <h4>Welcome To <b>Complygate</b></h4>
                <span>Commit. Control. Comply</span>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</div>
<!-- form-layout -->