import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { EmployeeService } from '../../../services/employee.service';
import { LeaveCount } from '../../../models/setting/leavecount.model';
import { ModalService } from '../../../_services/modal.service';
import { AppGlobals } from '../../../app.global';

@Component({
  selector: 'app-leave-management',
  templateUrl: './leave-management.component.html',
  styleUrls: ['./leave-management.component.css']
})
export class LeaveManagementComponent implements OnInit {

  leaveForm: FormGroup;
  data: any;
  public searchText: string;
  record:number;
  items: Array<any>;
  pageOfItems: Array<any>;
  leaveDetail: any;
  userid: any;
  year: any;
  yearList: any;
  submitted: any = false;
  empperm:any;
  constructor(
    private employeeService: EmployeeService, 
    private formBuilder: FormBuilder, 
    private modalService: ModalService, 
  ) { }

  ngOnInit() {
    this.yearList = AppGlobals.YEAR_VALUES;
    this.year = new Date().getFullYear();
    this.userid =localStorage.getItem('userid');  
    this.createLeaveFormGroup();
    this.getAllEmployees();
    this.leaveDetail = new LeaveCount();
  }

  get g() { return this.leaveForm.controls; }

  createLeaveFormGroup()
  {
    this.leaveForm = this.formBuilder.group({
      id: [''],
      employeeid: [''],
      leavecount: ['', Validators.compose([Validators.required,  Validators.pattern(/^[.\d]+$/),Validators.max(365), Validators.min(1)])],
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  getAllEmployees()
  {
    var postData = {
      'year': 2020,
      'id': this.userid
    };
    var year = this.year;
    this.employeeService.getListOfEmployeeWithTotalLeave(postData).subscribe((employeedata: any) => {
      employeedata.forEach(function (value: any, emp_index: any) {
        var tg;
        tg = JSON.parse(value.permission.toString());
        tg.forEach(function (value4) {
        });
        if(value.remainleave)
        {
          value.remainleave.forEach(function (totalLeaveObj: any, index: any) {
            if(totalLeaveObj.payrolyear == year)
            {
              employeedata[emp_index].totalleave = totalLeaveObj;
            }
          });
        }
      });
      this.data = employeedata;
      this.record = this.data.length;

      if(this.record < 30){
        this.pageOfItems = this.data;
      }
      else{
           this.items =this.data;
      }     
    });
  }

  onSubmitLeave(leaveformData, modelId)
  {
    this.submitted = true;
    if (this.leaveForm.invalid) {
      return;
    }

    var postData = {
      'id': leaveformData.id,
      'employeeid': leaveformData.employeeid,
      'leavecount': leaveformData.leavecount,
      'payrolyear': this.year,
      'userid': this.userid
    };

    this.employeeService.saveEmployeeTotalLeaves(postData).subscribe((resp: any) => {
      if(resp.success)
      {
        this.getAllEmployees();
        this.modalService.close(modelId);
      }else{

      }
    });
  }

  editLeaveCount(employeeid: any)
  {
    this.leaveForm.reset();
    var postData = { 'employeeid': employeeid, 'year': this.year };
    this.employeeService.getEmployeeTotalLeaves(postData).subscribe((employeetotalleavedata: any) => {
      if(employeetotalleavedata)
      {
        this.leaveDetail.employeeid = employeetotalleavedata.employeeid;
        this.leaveDetail.id =  employeetotalleavedata.id;
        this.leaveDetail.leavecount =  employeetotalleavedata.leavecount;
        // console.log(employeetotalleavedata);
      }
      else{
        this.leaveDetail = new LeaveCount();
        this.leaveDetail.employeeid = employeeid;
        this.leaveDetail.id =  '0';
      }
      this.modalService.open('custom-leave');
    });
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  yearChange(year)
  {
    this.year  = year;
    this.getAllEmployees();
  }

}
