<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__content">
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="space_em row">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Job List <a (click)="openModal2('custom-visa-1')" class="mainheading_btn" *ngIf="jobperm > 1">  + Add New </a></h5>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group">
                                        <input [(ngModel)]="searchText" placeholder="Search.." class="form-control">
                                    </div>
                                </div>
                            </div>
                             <ng-container *ngIf="( datalist | filter: {jobtittle: searchText, cityname: searchText,countyname: searchText }) as pageOfItems"> 
                            <div class="job_list_section" *ngFor="let job of pageOfItems; let i=index">
                                <p *ngFor="let can of job.candidate" style="display: none;">
                                    {{ updatestatus(can.cstatus) }}
                                </p>

                                <div class="job_detils_date width33">
                                    <a [routerLink]="['/jobdetails/',job.jobmasterid]">
                                        <p class="job_profile">{{job.jobtittle}}</p>
                                    </a>
                                    <p class="job_address">{{job.cityname}}, {{job.countyname}}</p>
                                    <p class="job_create_date">Created {{job.jobdate | date: 'dd MMM yyyy'}} - Closing {{job.closedate | date: 'dd MMM yyyy'}}</p>
                                </div>
                                <div class="job_activites width40">
                                    <ul>
                                        <li>
                                            <a [routerLink]="['/listcandidate/',job.jobmasterid,0]">
                                                <span class="active_job">{{job.candidate.length}} Active</span>
                                                <p class="active_job">Applicant </p>
                                            </a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/listcandidate/',job.jobmasterid,1]">
                                                <span class="job_show_emp">{{mainawaiting}}</span>
                                                <p class="job_show_emp">Awaiting</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/listcandidate/',job.jobmasterid,2]">
                                                <span class="job_show_emp">{{mainreview}}</span>
                                                <p class="job_show_emp">Reviewed</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/listcandidate/',job.jobmasterid,5]">
                                                <span class="job_show_emp">{{maincon}}</span>
                                                <p class="job_show_emp">Contracting</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/listcandidate/',job.jobmasterid,7]">
                                                <span class="job_show_emp">{{mainhire}}</span>
                                                <p class="job_show_emp">Hired</p>
                                            </a>
                                        </li>
                                        {{ resetall() }}
                                    </ul>
                                </div>
                                <div class="job_btn width22">
                                    <div id="container">
                                        <div class="dot"></div>
                                        <div class="pulse"></div>
                                    </div>

                                    <p class="text-success" *ngIf="jobstatus(job.closedate) == true">Active Job</p>
                                    <p class="text-danger" *ngIf="jobstatus(job.closedate) == false">Expired Job</p>
                                    <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               Action <i class="fa fa-angle-down" aria-hidden="true"></i>
                              </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" *ngIf="jobperm > 2" (click)="openModal(job.jobmasterid, 'custom-visa-1')">Edit Job</a>
                                            <a class="dropdown-item" *ngIf="jobperm > 0" [routerLink]="['/jobdetails/',job.jobmasterid]">View Job</a>
                                            <a class="dropdown-item" [routerLink]="['/listskills/',job.jobmasterid]">Add Skills</a>
                                        </div>
                                    </div>

                                    <!-- <button type="submit" class="btn btn-primary">Edit Job</button>
                            <button type="submit" class="btn btn-warning">View Job</button>
                            <button type="submit" class="btn btn-danger">Add Skills</button> -->
                                </div>
                            </div>
                            <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>
                            
                        </ng-container>

                            <!-- <div class="job_list_section visa_request_section" *ngIf="record == 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div> -->
                            <!-- <div class="row">
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<jw-modal id="custom-visa-1" role="dialog" class="modal formsizeincre">
    <form [formGroup]="jobformGroup" #jobForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(jobForm.value,'custom-visa-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="jobmasterid" class="form-control" name="jobmasterid" id="jobmasterid" [(ngModel)]="jobDetail.jobmasterid">
                    <input type="hidden" formControlName="urldocumentid" class="form-control" name="urldocumentid" id="urldocumentid" [(ngModel)]="jobDetail.urldocumentid">
                    <h5 class="modal-title" id="popupLabel">Job Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-visa-1');">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label>Job Title*</label>
                                <input type="text" formControlName="jobtittle" maxlength="100" class="" name="jobtittle" id="jobtittle" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.jobtittle.errors }" [(ngModel)]="jobDetail.jobtittle">
                                <div *ngIf="submitted && f.jobtittle.errors" class="invalid-feedback">
                                    <div *ngIf="f.jobtittle.errors.required">Please enter job title</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Job Posted Date*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker1" name="jobdate" id="jobdate" ngClass="{ 'is-invalid': submitted && f.jobdate.errors }" formControlName="jobdate" class="form-control" [max]="dateToday" [(ngModel)]="jobDetail.jobdate">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.jobdate.errors" class="invalid-feedback">
                                    <div *ngIf="f.jobdate.errors.required">Please choose job posted date</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Closing Date*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker2" name="closedate" id="closedate" [ngClass]="{ 'is-invalid': submitted && f.closedate.errors }" formControlName="closedate" class="form-control noborder-invalid" [min]="jobformGroup.controls.jobdate.value" [(ngModel)]="jobDetail.closedate">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.closedate.errors" class="invalid-feedback">
                                    <div *ngIf="f.closedate.errors.required">Please choose closing date</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Contact Name*</label>
                                <input type="text" formControlName="contactname" class="" name="contactname" id="contactname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.contactname.errors }" [(ngModel)]="jobDetail.contactname">
                                <div *ngIf="submitted && f.contactname.errors" class="invalid-feedback">
                                    <div *ngIf="f.contactname.errors.required">Please enter contact name</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Role*</label>
                                <input type="text" formControlName="contactrole" class="" name="contactrole" id="contactrole" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.contactrole.errors }" [(ngModel)]="jobDetail.contactrole">
                                <div *ngIf="submitted && f.contactrole.errors" class="invalid-feedback">
                                    <div *ngIf="f.contactrole.errors.required">Please enter role</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Contact Email*</label>
                                <input type="email" formControlName="emailid" class="" name="emailid" id="emailid" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }" [(ngModel)]="jobDetail.emailid">
                                <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                                    <div *ngIf="f.emailid.errors.required">Please enter contact email </div>
                                    <div *ngIf="f.emailid.errors.email">Please enter valid email</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Phone Number*</label>
                                <international-phone-number formControlName="phonenumber" class="form-control phonecontrol" [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" [(ngModel)]="jobDetail.phonenumber" placeholder="Enter mobile number" (input)="validatenumber($event.target.value,'phone')"
                                    [maxlength]="20" [required]="true" #phonenumber name="phonenumber"></international-phone-number>
                                <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.phonenumber.errors.required">Please enter phone number</div>
                                </div>
                                <span *ngIf="phonecheck===false" style="color:#dc3545; font-size: 80%;">Invalid format</span>
                                <span *ngIf="phonecheck===true" style="color:green; font-size: 80%;">Valid format</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-4 col-md-10">
                                <label>Job URL 1</label>
                                <input type="text" formControlName="joburl1" class="" name="joburl1" id="joburl1" class="form-control" [(ngModel)]="jobDetail.joburl1">
                            </div>

                            <div class="form-group col-lg-2  col-md-2 fpupload disshshs" (click)="file1.click()">
                               
                                <div class="bootstrap-filestyle input-group">
                                    <input type="file" #file1 (change)="selectFile($event,'file1')" />
                                    <label class="btn btn-secondary mb-0">
                                    <span class="buttonText">{{ br1 }}</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-md-10">
                                <label>Job URL 2</label>
                                <input type="text" formControlName="joburl2" class="" name="joburl2" id="joburl2" class="form-control" [(ngModel)]="jobDetail.joburl2">
                            </div>
                            <div class="form-group col-lg-2 col-md-2 fpupload disshshs" (click)="file2.click()">
                              
                                <div class="bootstrap-filestyle input-group">
                                    <input type="file" #file2 (change)="selectFile($event,'file2')" />
                                    <label class="btn btn-secondary mb-0">
                                        <span class="buttonText">{{ br2 }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-4 col-md-10">
                                <label>Job URL 3</label>
                                <input type="text" formControlName="joburl3" class="" name="joburl3" id="joburl3" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.joburl3.errors }" [(ngModel)]="jobDetail.joburl3">
                                <div *ngIf="submitted && f.joburl3.errors" class="invalid-feedback">
                                    <div *ngIf="f.joburl3.errors.required">Please enter job URL 3 </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-2 col-md-2 fpupload disshshs" (click)="file3.click()">
                                
                                <div class="bootstrap-filestyle input-group">
                                    <input type="file" #file3 (change)="selectFile($event,'file3')" />
                                    <label class="btn btn-secondary mb-0">
                                        <span class="buttonText">{{ br3 }}</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-md-10">
                                <label>Job URL 4</label>
                                <input type="text" formControlName="joburl4" class="" name="joburl4" id="joburl4" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.joburl4.errors }" [(ngModel)]="jobDetail.joburl4">
                                <div *ngIf="submitted && f.joburl4.errors" class="invalid-feedback">
                                    <div *ngIf="f.joburl4.errors.required">Please enter job URL 4 </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-2 col-md-2 fpupload disshshs" (click)="file4.click()">
                               
                                <div class="bootstrap-filestyle input-group">
                                    <input type="file" #file4 (change)="selectFile($event,'file4')" />
                                    <label class="btn btn-secondary mb-0">
                                        <span class="buttonText">{{ br4 }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="example-text-input" class="col-form-label">Salary (£)*</label>
                                <div class="row">
                                    <div class="col-sm-4 mbb" >
                                        <input name="salary" formControlName="salary" [(ngModel)]="jobDetail.salary" [ngClass]="{ 'is-invalid': submitted && f.salary.errors }" type="text" maxlength="7" id="salary" class="form-control nb" required="" data-parsley-type="number" placeholder="Minimum">
                                        <div *ngIf="submitted && f.salary.errors" class="invalid-feedback">
                                            <div *ngIf="f.salary.errors.required">Minimum Salary </div>
                                            <div *ngIf="f.salary.errors.pattern">Should be a number </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4  mbb m-l-10" >
                                        <input name="maxsalary" formControlName="maxsalary" [(ngModel)]="jobDetail.maxsalary" type="text" maxlength="7" id="maxsalary" class="form-control nb" required="" data-parsley-type="number" placeholder="Maximum">
                                        <div *ngIf="submitted && f.maxsalary.errors" class="invalid-feedback">
                                            <div *ngIf="f.maxsalary.errors.required">Maximum Salary </div>
                                            <div *ngIf="f.maxsalary.errors.pattern">Should be a number </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 mbb m-l-10">
                                        <select name="stypeid" formControlName="stypeid" id="stypeid" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.socid.errors }" [(ngModel)]="jobDetail.stypeid">
                                            <option [value]="defaultVal">Type</option>
                                            <option [value]="1" >Per Month</option>
                                            <option [value]="2" >Per Year</option>
                                        </select>
                                        <div *ngIf="submitted && f.stypeid.errors" class="invalid-feedback">
                                            <div *ngIf="f.stypeid.errors.required">Please select salary type </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6" style="display:none;">
                                <label>Hours of Work Per Week <span>*</span></label>
                                <input name="ctl00$ContentPlaceHolder1$txtHoursofWorks" type="text" value="10" maxlength="2" id="ContentPlaceHolder1_txtHoursofWorks" class="form-control nb" required="" data-parsley-type="number">
                            </div>
                            <div class="col-sm-6">
                                <label>SOC Code*</label>
                                <select style="width:100%;" formControlName="socid" name="socid" id="socid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.socid.errors }" [(ngModel)]="jobDetail.socid">
                                    <option [value]="defaultVal">Select SOC Code </option>
                                    <option *ngFor="let soc of socdata" [value]="soc.socid">({{ soc.socnumber }}){{ soc.socname }}</option>
                                </select>
                                <div *ngIf="submitted && f.socid.errors" class="invalid-feedback">
                                    <div *ngIf="f.socid.errors.required">Please select SOC code</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Postcode*</label>
                                <input type="pincode" formControlName="postalcode" id="postalcode" name="postalcode" class="form-control pincode" [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" [(ngModel)]="jobDetail.postalcode" (input)="onSearchChange($event.target.value)"
                                    matInput [matAutocomplete]="auto">
                                <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                                    <div *ngIf="f.postalcode.errors.required">Please enter postcode</div>
                                </div>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="pincode">
                                    <mat-option *ngFor="let option of options" [value]="option" (onSelectionChange)="updatecode($event, option)">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Address Line 1*</label>
                                <input type="text" formControlName="address1" class="" name="address1" id="address1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" [(ngModel)]="jobDetail.address1">
                                <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
                                    <div *ngIf="f.address1.errors.required">Please enter address</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Address Line 2 </label>
                                <input type="text" formControlName="address2" class="" name="address2" id="address2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" [(ngModel)]="jobDetail.address2">
                                <div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
                                    <div *ngIf="f.address2.errors.required">Please enter address </div>
                                </div>
                            </div> 
                            <div class="form-group col-lg-6">
                                <label>City*</label>
                                <input type="text" formControlName="cityname" class="" name="cityname" id="cityname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.cityname.errors }" [(ngModel)]="jobDetail.cityname">
                                <div *ngIf="submitted && f.cityname.errors" class="invalid-feedback">
                                    <div *ngIf="f.cityname.errors.required">Please enter city </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>County*</label>
                                <input type="text" formControlName="countyname" class="" name="countyname" id="countyname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.countyname.errors }" [(ngModel)]="jobDetail.countyname">

                                <!-- <select style="width:100%;" formControlName="countyid" name="countyid" id="countyid"
                    class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.countyid.errors }"
                    ngModel="{{ jobDetail.countyid }}">
                    <option value="">Select County </option>
                    <option *ngFor="let county of countylist" value='{{ county.stateid }}'
                      [selected]="county.stateid == jobDetail.countyid">{{
                      county.statename }}</option>
                  </select> -->
                                <div *ngIf="submitted && f.countyname.errors" class="invalid-feedback">
                                    <div *ngIf="f.countyname.errors.required">Please enter county</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label>Country*</label>
                                <select style="width:100%;" formControlName="countryid" name="countryid" id="countryid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.countryid.errors }" [ngModel]="jobDetail.countryid">
                                    <option [value]="defaultVal">Select Country </option>
                                    <option *ngFor="let country of countrylist" [value]="country.countryid">{{ country.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && f.countryid.errors" class="invalid-feedback">
                                    <div *ngIf="f.countryid.errors.required">Please select country</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Qualification</label>
                                <input type="text" formControlName="qualification" class="" name="qualification" id="qualification" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.qualification.errors }" [(ngModel)]="jobDetail.qualification">
                                <div *ngIf="submitted && f.qualification.errors" class="invalid-feedback">
                                    <div *ngIf="f.qualification.errors.required">Please enter qualification </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Number of Positions*</label>
                                <select style="width:100%;" formControlName="addposition" name="addposition" id="addposition" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.addposition.errors }" [(ngModel)]="jobDetail.addposition">
                                    <option [value]="defaultVal">Select Position </option>
                                    <option [value]='1' >1</option>
                                    <option [value]='2' >2</option>
                                    <option [value]='3' >3</option>
                                    <option [value]='4' >4</option>
                                    <option [value]='5' >5</option>
                                    <option [value]='6' >6</option>
                                    <option [value]='7' >7</option>
                                    <option [value]='8' >8</option>
                                    <option [value]='9' >9</option>
                                    <option [value]='10' >10</option>
                                </select>
                                <div *ngIf="submitted && f.addposition.errors" class="invalid-feedback">
                                    <div *ngIf="f.addposition.errors.required">Please select number of positions </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label>Job Description</label>
                                <!-- [config]="{ toolbar: [ [ 'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', '-', 'Link', 'Unlink' ] ] }" -->
                                <ckeditor [editor]="Editor"  #jobdescription [formControl]="jobformGroup.controls['jobdescreption']" [(ngModel)]="jobDetail.jobdescreption" [style.height.px]="250"></ckeditor>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary com_btn" [disabled]="!disableBtn">{{buttoninsert}}</button>
                    <!-- <input type="submit" class="btn btn-primary" name="updateform" [disabled]="!disableBtn"
              value="{{buttoninsert}}" /> -->
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-visa-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>

<jw-modal id="custom-job-view" role="dialog" class="modal">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="popupLabel">View Job Details <button type="button" (click)="closeModal('custom-job-view');">Back</button><button type="button" (click)="openModal4('','custom-add-job-screen', '');">Add Job Screenshot</button></h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-job-view');">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row" *ngIf="jobDetail">
                        <div class="form-group col-lg-4">
                            <h3>{{ jobDetail.jobtittle }}</h3>
                            <p><b>Job Created On: </b>{{jobDetail.jobdate}}</p>
                            <p><b>Closing Date: </b>{{jobDetail.closedate}}</p>
                            <p><b>Qualification: </b>{{jobDetail.qualification}}</p>
                        </div>
                        <div class="form-group col-lg-4">
                            <p><b>Email: </b>{{jobDetail.emailid}}</p>
                            <p><b>Contact Name: </b>{{jobDetail.contactname}}</p>
                            <p><b>Phone No: </b>{{jobDetail.phonenumber}}</p>
                            <p><b>Salary: </b>{{jobDetail.salary}} - {{jobDetail.maxsalary}}</p>
                            <p *ngIf="stypeid==1"><b>Salary Type: </b>Per Month</p>
                            <p *ngIf="stypeid==1"><b>Salary Type: </b>Per Year</p>
                        </div>
                        <div class="form-group col-lg-4">
                            <h3>Job Address</h3>
                            <p>{{jobDetail.address1}}</p>
                            <p>{{jobDetail.address2}}</p>
                            <p>{{jobDetail.cityname}}</p>
                            <p><span *ngIf="jobDetail.country"> {{jobDetail.country.countryname}}</span> - {{jobDetail.postalcode}}
                            </p>
                        </div>

                    </div>
                    <div class="row" *ngIf="jobDetail">
                        <div class="form-group col-lg-12">
                            <p><b>SOC Code: </b> <span *ngIf="jobDetail.soc"> ({{jobDetail.soc.socnumber}})
                    {{jobDetail.soc.socname}}</span></p>
                            <div [innerHTML]="jobDetail.jobdescreption"></div>
                        </div>
                    </div>
                    <div class="row" *ngFor="let screen of jobDetail.screen">
                        <div class="form-group col-lg-12">
                            <h2 *ngIf="screen.screenshotdayid=='1'">Screenshot Day 1</h2>
                            <h2 *ngIf="screen.screenshotdayid=='2'">Screenshot Day 15</h2>
                            <h2 *ngIf="screen.screenshotdayid=='3'">Screenshot Day 30</h2>
                            <h2 *ngIf="screen.screenshotdayid=='4'">Screenshot Day 45</h2>
                            <button type="button" (click)="openModal4(screen.id,'custom-add-job-screen', screen.screenshotdayid);">Add
                  Job Screenshot</button>
                        </div>
                        <div class="form-group col-lg-3" *ngIf="screen.urldocument1!=null">
                            <img class="rounded mr-2" src="assets/img/file.png" style="width:100%;">
                            <p><i class="fa fa-file-image-o" (click)="openModal5('pdf-image-viewer', screen.urldocument1)"></i>
                                <i class="fa fa-trash" (click)="deleteFile(jobDetail.jobmasterid, screen.id, 'document1')"></i>
                                <a href="{{screen.urldocument1}}"><i class="fa fa-download"></i></a></p>
                        </div>
                        <div class="form-group col-lg-3" *ngIf="screen.urldocument2!=null">
                            <img class="rounded mr-2" src="assets/img/file.png" style="width:100%;">
                            <p><i class="fa fa-file-image-o" (click)="openModal5('pdf-image-viewer', screen.urldocument2)"></i>
                                <i class="fa fa-trash" (click)="deleteFile(jobDetail.jobmasterid, screen.id, 'document2')"></i>
                                <a href="{{screen.urldocument2}}"><i class="fa fa-download"></i></a></p>
                        </div>
                        <div class="form-group col-lg-3" *ngIf="screen.urldocument3!=null">
                            <img class="rounded mr-2" src="assets/img/file.png" style="width:100%;">
                            <p><i class="fa fa-file-image-o" (click)="openModal5('pdf-image-viewer', screen.urldocument3)"></i>
                                <i class="fa fa-trash" (click)="deleteFile(jobDetail.jobmasterid, screen.id, 'document3')"></i>
                                <a href="{{screen.urldocument3}}"><i class="fa fa-download"></i></a></p>
                        </div>
                        <div class="form-group col-lg-3" *ngIf="screen.urldocument4!=null">
                            <img class="rounded mr-2" src="assets/img/file.png" style="width:100%;">
                            <p><i class="fa fa-file-image-o" (click)="openModal5('pdf-image-viewer', screen.urldocument4)"></i>
                                <i class="fa fa-trash" (click)="deleteFile(jobDetail.jobmasterid, screen.id, 'document4')"></i>
                                <a href="{{screen.urldocument4}}"><i class="fa fa-download"></i></a></p>
                        </div>
                    </div>
                    <div class="row" *ngIf="!jobDetail">
                        <div class="form-group col-lg-3">
                            No Job Found
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-add-job-screen" role="dialog" class="modal">
    <form [formGroup]="jobscreenformGroup" #jobscreenForm="ngForm" class="s12 white" (ngSubmit)="addJobScreen(jobscreenForm.value,'custom-add-job-screen')">
        <input type="hidden" formControlName="jobmasterid" class="form-control" name="jobmasterid" id="jobmasterid" [(ngModel)]="jobDetail.jobmasterid">
        <input type="hidden" formControlName="id" class="form-control" name="id" id="id" [(ngModel)]="screenshotid">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="popupLabel">Job Screenshot <button type="button" (click)="closeModal('custom-add-job-screen');">Back</button></h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-add-job-screen');">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row" *ngIf="jobDetail">
                            <div class="form-group col-lg-12">
                                <label>Select Screenshot Day*</label>
                                <select style="width:100%;" [(ngModel)]="screenumber" disabled="{{disableday}}" formControlName="screenshotdayid" name="screenshotdayid" id="screenshotdayid" class="form-control input-lg">
                    <option [value]="">Select Screenshot Day </option>
                    <option [value]='1' [selected]="screenumber == '1'">Day 1</option>
                    <option [value]='2' [selected]="screenumber == '2'">Day 15</option>
                    <option [value]='3' [selected]="screenumber == '3'">Day 30</option>
                    <option [value]='4' [selected]="screenumber == '4'">Day 45</option>
  
                  </select>
                                <div *ngIf="submitted && g.screenshotdayid.errors" class="invalid-feedback">
                                    <div *ngIf="g.screenshotdayid.errors.required">Please select screenshot day</div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="jobDetail">
                            <div class="form-group col-lg-12" *ngIf="jobDetail.joburl1!=''">
                                <input type="textbox" formControlName="joburl1" [(ngModel)]="jobDetail.joburl1" name="joburl1" disabled>
                                <input type="file" #file1 (change)="selectFile($event,'file1')">
                            </div>
                            <div class="form-group col-lg-12" *ngIf="jobDetail.joburl2!=''">
                                <input type="textbox" formControlName="joburl2" [(ngModel)]="jobDetail.joburl2" name="joburl2" disabled>
                                <input type="file" #file2 (change)="selectFile($event,'file2')">
                            </div>
                            <div class="form-group col-lg-12" *ngIf="jobDetail.joburl3!=''">
                                <input type="textbox" formControlName="joburl3" [(ngModel)]="jobDetail.joburl3" name="joburl3" disabled>
                                <input type="file" #file3 (change)="selectFile($event,'file3')">
                            </div>
                            <div class="form-group col-lg-12" *ngIf="jobDetail.joburl4!=''">
                                <input type="textbox" formControlName="joburl4" [(ngModel)]="jobDetail.joburl4" name="joburl4" disabled>
                                <input type="file" #file4 (change)="selectFile($event,'file4')">
                            </div>
                        </div>
                        <div class="row" *ngIf="jobDetail">
                            <div class="form-group col-lg-12">
                                <!-- <p><i class="fa fa-file-image-o" (click)="openModal5('pdf-image-viewer', doc.documentimageurl)"></i> 
                            <i class="fa fa-trash" (click)="deleteFile(doc.candidatedocumentdetailsid, doc.candidateid)"></i>
                            <a href="{{doc.documentimageurl}}"><i class="fa fa-download" ></i></a></p> -->
                            </div>
                        </div>
                        <div class="row" *ngIf="!jobDetail">
                            <div class="form-group col-lg-3">
                                No Job Screen Found
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary" name="updateform" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-primary" name="updateform" value="Cancel" (click)="closeModal('custom-add-job-screen');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>

<jw-modal id="pdf-image-viewer" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer');">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="500" frameborder="0"></iframe>
                            <img *ngIf="imgsrc == true" [src]="iframesrc" style="width:100%;" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>