import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EmployeeService } from '../../../../services/employee.service';
import { ModalService } from '../../../../_services/modal.service';
import { Profile } from '../../../../models/overview/profile.model';
import { CountryService } from '../../../../services/country.service';
import { CommonService } from '../../../../services/common.service';
import { JobService } from '../../../../services/job.service';
import { CandidateService } from '../../../../services/candidate.service';
import { UserService } from '../../../../services/user.service';
import { PermissionService } from '../../../../services/permission.service';


@Component({
  selector: 'app-personalinformation',
  templateUrl: './personalinformation.component.html',
  styleUrls: ['./personalinformation.component.css']
})
export class PersonalinformationComponent implements OnInit {
  data: any;
  submitted: any;
  employeeForm: FormGroup;
  overviewData: any;
  countrylist: any;
  joblist: any;
  candidatelist: any;
  Userid: any;
  th = [];
  emailexist = false;
  public searchText: string;
  permissionstring: any;
  mindob = new Date();
  maxdob = new Date();
  phonecheck: any;
  disableBtn: any;

  constructor(
    private userService: UserService, 
    private employeeService: EmployeeService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private commonService: CommonService,
    private formBuilder: FormBuilder, 
    private jobdetails: JobService, 
    private candidatedetails: CandidateService,
    private permissiondetails: PermissionService
    ) { }

  ngOnInit() {
    this.Userid = localStorage.getItem('userid');
    this.mindob.setDate(this.mindob.getDate() - 23741);
    this.maxdob.setDate(this.maxdob.getDate() - 574);

    this.employeeForm = this.formBuilder.group({
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      gender: ['', Validators.required],
      placeofbirth: ['', Validators.required],
      maritalstatus: ['', Validators.required],
      ninumber: ['', Validators.required],
      jobid: [''],
      candidateid: [''],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: ['', Validators.compose([Validators.required])],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      residenceid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      countryofbirthid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      dateofbirth: ['', Validators.required],
    });
    this.overviewData = new Profile();

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.jobdetails.getjobList().subscribe((jobdetails: any) => {
      this.joblist = jobdetails;
    });

    this.permissiondetails.bindallmenu().subscribe((menudata: any) => {
      var permissionstring = "[{";
      var perarray = [];
      menudata.forEach(function (valuemenu) {
        perarray.push('"' + valuemenu.clname + '": 0');
      });
      
      var perstr = perarray.join();
      permissionstring = permissionstring + perstr + "}]";
      this.permissionstring = permissionstring;
    });

    this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
      employeedata.forEach(function (value) {
        var tg;
        tg = JSON.parse(value.permission.toString());
        tg.forEach(function (value4) {
        });
      });
      this.data = employeedata;
    });

  }

  get f() { return this.employeeForm.controls; }

  onSubmit(employeeform1: any, modelid: any) {
    this.submitted = true;
    if (this.employeeForm.invalid) {
      return;
    }
    this.disableBtn = false;
    employeeform1.id = this.Userid;
    employeeform1.permission = this.permissionstring;
    this.userService.isEmailRegisterd(employeeform1.emailid).subscribe((data: any) => {
      if (data.sussess == 'sussess') {
        this.emailexist = true;
      }
      else {
        this.employeeService.employeeUpdateForm1(employeeform1).subscribe((data: any) => {
          this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
            this.data = employeedata;
            this.modalService.close(modelid);
          });
        });
      }
    });
  }

  onChangeStatus(evt: any, emp: any)
  {
    var status;
    if(evt.target.checked)
    {
      status = '1';
    }
    else if(!evt.target.checked)
    {
      status = '0';
    }
    this.employeeService.employeeUpdateChangeStatus({'userid': this.Userid, 'id': emp.employeemasterid, 'estatus': evt.target.checked}).subscribe((data: any) => {

    });
  }

  focusFunction() {
    this.emailexist = false;
  }

  isEmailUnique(control: FormControl) {
    const q = new Promise((resolve, reject) => {
      this.userService.isEmailRegisterd(control.value).subscribe(() => {
        resolve(null);
      }, () => { resolve({ 'isEmailUnique': true }); });

    });
    return q;
  }

  onSelectjob(job_id: number) {
    var userid = localStorage.getItem('userid');
    const data = { 'jobid': job_id, 'id': userid };
    this.candidatedetails.getselectedcandidate(data).subscribe((candidatedetails: any) => {
      return this.candidatelist = candidatedetails
    });
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
     
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  openModal2(id: string) {
    this.submitted = false;
    this.disableBtn = true;
    this.overviewData.gender = "1";
    this.overviewData.maritalstatus = "0";
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.modalService.close(id);
  }

}
