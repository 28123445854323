"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var user_service_1 = require("../../services/user.service");
var user_model_1 = require("../../models/user.model");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var forms_1 = require("@angular/forms");
var customer_service_1 = require("../../services/customer.service");
var alert_service_1 = require("../../services/alert.service");
// import { User} from '../../shared/user.model';
// import { EmailValidator } from '@angular/forms';
var SignInComponent = /** @class */ (function () {
    function SignInComponent(formBuilder, userService, router, alerts, csservices) {
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.router = router;
        this.alerts = alerts;
        this.csservices = csservices;
        this.submitted = false;
        this.isLoginError = false;
        // loading: boolean;
        this.user = new user_model_1.User();
        this.access_token = '';
        this.userid = '';
        this.name = '';
        this.isline = '';
        this.planid = '';
        this.error = '';
        this.isemail = '';
        this.ismobile = '';
        this.isadmin = '';
        this.ispermission = '';
        this.ctype = '';
        this.loginform = false;
        this.ssoform = false;
        this.issubmitinprogress = false;
    }
    SignInComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loginform = true;
        var userid = localStorage.getItem('userid');
        if (userid) {
            this.userService.checkaccesstokenbyid(userid).subscribe(function (data) {
                if (data.status == false) {
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('userid');
                    localStorage.removeItem('Name');
                    localStorage.removeItem('isline');
                    localStorage.removeItem('planid');
                    localStorage.removeItem('isemail');
                    localStorage.removeItem('ismobile');
                    localStorage.removeItem('isadmin');
                    localStorage.removeItem('ispermission');
                    localStorage.removeItem('ctype');
                    localStorage.removeItem('logoname');
                    localStorage.removeItem('companyid');
                    localStorage.removeItem('struser');
                    localStorage.removeItem('menu');
                }
                else {
                    var isemail = localStorage.getItem('isemail');
                    var ismobile = localStorage.getItem('ismobile');
                    var struser = localStorage.getItem('struser');
                    _this.ctype = localStorage.getItem('ctype');
                    var planid = localStorage.getItem('planid');
                    if (isemail == 'false' && ismobile == 'false') {
                        _this.router.navigate(['/otp']);
                    }
                    else if (planid == '0' && _this.ctype == '0') {
                        _this.router.navigate(['choose-plan']);
                    }
                    else if (struser == '' && _this.ctype == '1') {
                        _this.router.navigate(['payment-confirmation']);
                    }
                    else if (struser == '' && _this.ctype == '2') {
                        _this.router.navigate(['payment-confirmation']);
                    }
                    else if (struser == '' && _this.ctype == '3') {
                        _this.router.navigate(['payment-confirmation']);
                    }
                    else if (_this.ctype == '0') {
                        _this.router.navigate(['dashboard']);
                    }
                    else if (_this.ctype == '1') {
                        _this.router.navigate(['check-dashboard']);
                    }
                    else if (_this.ctype == '2') {
                        _this.router.navigate(['check-dashboard']);
                    }
                    else if (_this.ctype == '3') {
                        _this.router.navigate(['reference-dashboard']);
                    }
                }
            });
        }
        this.registerForm = this.formBuilder.group({
            username: ['', [forms_1.Validators.required]],
            password: ['', [forms_1.Validators.required]]
        });
        this.loginSSOFormGroup = this.formBuilder.group({
            "email": ["", [forms_1.Validators.required, forms_1.Validators.email]]
        });
    };
    Object.defineProperty(SignInComponent.prototype, "f", {
        get: function () {
            return this.registerForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignInComponent.prototype, "k", {
        get: function () {
            return this.loginSSOFormGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    SignInComponent.prototype.ssoclick = function () {
        this.loginform = false;
        this.ssoform = true;
    };
    SignInComponent.prototype.loginclick = function () {
        this.loginform = true;
        this.ssoform = false;
    };
    SignInComponent.prototype.loginssoUser = function () {
        var _this = this;
        this.submitted = true;
        console.log("fail", this.loginSSOFormGroup.invalid);
        if (this.loginSSOFormGroup.invalid) {
            return;
        }
        console.log("success", this.submitted);
        if (this.issubmitinprogress)
            return;
        else
            this.issubmitinprogress = true;
        this.loginSSOFormGroup.value.username = this.loginSSOFormGroup.value.email;
        if (this.loginSSOFormGroup.valid) {
            this.userService.ssoUser(this.loginSSOFormGroup.value).subscribe(function (response) {
                _this.issubmitinprogress = false;
                console.log(response.login_url);
                if (response.status) {
                    window.open(response.login_url, "_self");
                }
                else {
                    _this.alerts.error(response.message, true);
                }
            });
        }
    };
    SignInComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.userService.userAuthentication(this.user).subscribe(function (data) {
            localStorage.setItem('userToken', _this.access_token);
            localStorage.setItem('userid', _this.userid);
            localStorage.setItem('Name', _this.name);
            localStorage.setItem('isline', _this.isline);
            localStorage.setItem('planid', _this.planid);
            localStorage.setItem('ctype', _this.ctype);
            localStorage.setItem('isemail', _this.isemail);
            localStorage.setItem('ismobile', _this.ismobile);
            localStorage.setItem('isadmin', data.isadmin);
            localStorage.setItem('ispermission', data.ispermission);
            localStorage.setItem('logoname', data.logourl);
            localStorage.setItem('struser', data.struser);
            localStorage.setItem('isverify', data.isverify);
            localStorage.setItem('companyid', data.companyid);
            localStorage.setItem('menu', '0');
            if (data.sussess == 'true') {
                if (data.estatus == false) {
                    _this.alerts.error('Your account is inactive. Contact your administrator to activate it', true);
                    return;
                }
                localStorage.setItem('userToken', data.id);
                localStorage.setItem('userid', data.userid);
                localStorage.setItem('emptype', data.emptype);
                localStorage.setItem('isline', data.isline);
                localStorage.setItem('planid', data.planid);
                localStorage.setItem('isemail', data.isemail);
                localStorage.setItem('ismobile', data.ismobile);
                localStorage.setItem('isadmin', data.isadmin);
                localStorage.setItem('ispermission', data.ispermission);
                localStorage.setItem('logoname', data.logourl);
                localStorage.setItem('ctype', data.ctype);
                localStorage.setItem('struser', data.struser);
                localStorage.setItem('companyid', data.companyid);
                localStorage.setItem('menu', '0');
                localStorage.setItem('isverify', data.isverify);
                if (data.isemail == false) {
                    localStorage.setItem('otp', data.otp);
                }
                if (data.ismobile == false) {
                    localStorage.setItem('otpmobile', data.otpmobile);
                }
                _this.csservices.getOverview().subscribe(function (data6) {
                    var lname = '';
                    if (data6.lname != null) {
                        lname = data6.lname;
                    }
                    localStorage.setItem('Name', data6.fname + ' ' + lname);
                    _this.alerts.success('Welcome to Complygate', true);
                    _this.router.navigate(['/' + data.url]);
                    // if(data.ctype == 0){
                    //   this.router.navigate(['/' + data.url]);
                    // }
                    // else  if(data.ctype == 2){
                    //   if (data.isemail == false){
                    //     this.router.navigate(['/otp']);
                    //   }
                    //   this.router.navigate(['/' + data.url]);
                    // }
                    // else
                    // {
                    //     if (data.isemail == false){
                    //         this.router.navigate(['/otp']);
                    //     }
                    //     else
                    //     {
                    //       if(data.stuser == ''){
                    //         this.router.navigate(['/payment-confirmation']);
                    //       }else{                
                    //         this.router.navigate(['/check-dashboard']);
                    //       }
                    //     }
                    // }
                });
            }
            else {
                _this.alerts.error(data.error, true);
            }
        }, function (err) {
            _this.isLoginError = true;
        });
    };
    return SignInComponent;
}());
exports.SignInComponent = SignInComponent;
