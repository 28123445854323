<div class="bg">
    <app-header></app-header>

    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="job_destails_secrtion">
                    <div class="back_job_list_arrow">
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                        <div class="details_text">
                            <span>{{jobdata.jobtittle }}</span>
                            <a [routerLink]="['/listcandidate/',jobid,0]">Back to Applicant List</a>
                        </div>
                    </div>
                    <div class="candidate_reviwed">
                        <div class="revied">{{totalreview}} of {{totalcandidate}} Candidates Reviewed</div>
                    </div>
                    <div class="job_view_pre_nex">
                        <a (click)="NewUrl(prvbutton)" *ngIf="prvbutton != '0'" > 
                            <button type="submit" class="btn btn-primary"><i class="fa fa-arrow-left" aria-hidden="true"></i>Prev</button>
                        </a>
                        <a  (click)="NewUrl(nextbutton)"  *ngIf="nextbutton != '0'"  >
                            <button type="submit" class="btn btn-primary">Next <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </a>
                    </div>
                </div>

                <div class="candidate_job_profile">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="white_box_shadow ">
                                <div class="userjob_name_intrested">
                                    <div class="user_jon_name">
                                        <h4>{{candidateDetail.fname}} {{candidateDetail.mname}} {{candidateDetail.lname}} </h4>
                                        <div class="edit_job"> <a (click)="openCandidateModal(candidateid,'custom-Candidate-edit-1')"> <i class="fa fa-pencil-square-o" aria-hidden="true"></i></a></div>
                                        <span class="user_email_job"><i class="fa fa-envelope-o" aria-hidden="true"></i> {{candidateDetail.emailid}}</span>
                                        <span class="job_apply_details">Job Created {{jobdata.jobdate | date}} - Closing {{jobdata.closedate | date}}</span>
                                        <span class="job_apply_details">Qualification - <b>{{jobdata.qualification}}</b></span>
                                    </div>

                                    <div class="job_intrested_pro">
                                        <p class="job_quali_score">{{smarks}} %</p>

                                        <div class="job_btn text-right job_intrested mr-2">
                                            <div class="btn-group  btn-group-toggle">
                                                <label class="btn btn-secondary green" [ngClass]="{ 'active': candidateDetail.isinterested == 1}">
                                                    <input type="radio" name="options" id="option1" autocomplete="off" (change)="onChangeinterested(1,candidateDetail.candidatemasterid)" [checked]="candidateDetail.isinterested == 1"> <i class="fa fa-check" aria-hidden="true"></i>
                                                </label>
                                                <label class="btn btn-secondary blue" [ngClass]="{ 'active': candidateDetail.isinterested == 2}">
                                                    <input type="radio" name="options" id="option2" autocomplete="off" (change)="onChangeinterested(2,candidateDetail.candidatemasterid)" [checked]="candidateDetail.isinterested == 2"> <i class="fa fa-question" aria-hidden="true"></i>
                                                </label>
                                                <label class="btn btn-secondary red" [ngClass]="{ 'active': candidateDetail.isinterested == 3}">
                                                    <input type="radio" name="options" id="option3" autocomplete="off" (change)="onChangeinterested(3,candidateDetail.candidatemasterid)" [checked]="candidateDetail.isinterested == 3"> <i class="fa fa-times" aria-hidden="true"></i>
                                                </label>
                                                <span class="tootlip apprr reducetool">Hired</span>
                                                <span class="tootlip ques reducetool">Awaiting Review</span>
                                                <span class="tootlip rejec reducetool">Rejected</span>
                                            </div>
                                        </div>
                                        <div class="action_filed">
                                            <div class="job_review_stauts">
                                                <div class="btn-group">
                                                    <span class="job_hestaus">Status</span> 
                                                    <button data-toggle="dropdown" type="button" color="primary" class="dropdown-toggle waves-light">{{ statusname}}  
                                                        <span>
                                                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                                                        </span>
                                                    </button>
                                                    <div class="dropdown-menu dropdown-menu-right">
                                                        <p *ngFor="let candidatestatus of candidatestatuslist">
                                                            <a class="dropdown-item" *ngIf="candidatestatus.emailer.length == 0" (click)="statusChange(candidatestatus.candidatestatusmasterid,candidateDetail);">{{candidatestatus.statusname}}</a>
                                                            <a class="dropdown-item" *ngIf="candidatestatus.emailer.length != 0" (click)="openModal6('custom-message-1', candidatestatus.candidatestatusmasterid,candidateDetail.candidatemasterid,candidateDetail.jobid);">{{candidatestatus.statusname}}</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>

                                <div class="job_tab">
                                    <mat-tab-group>
                                        <mat-tab label="Applicant Documents">
                                            <ng-template matTabContent>
                                                <app-applicantdocument></app-applicantdocument>
                                            </ng-template>
                                        </mat-tab>
                                        <mat-tab label="Skills">
                                            <ng-template matTabContent>
                                                <div class="job_assment">
                                                    <h5>Skills List</h5>
                                                </div>
                                                <hr class="line" />

                                                <div class="job_skills_set">
                                                    <div class="skill_list ">
                                                        <div class="colmElmProType" *ngFor="let cat of skilllist">
                                                            <input type="checkbox" value="1" id="{{cat.skillname}}" (change)="skillupdate(cat.jobskilldetailid,$event.target.checked)" [checked]="cat.check ==true" class="form-check-input">
                                                            <label for="{{cat.skillname}}">{{cat.skillname}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12" *ngIf="!skilllist">
                                                        <p class="no-data">
                                                            No Skill Found.
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </mat-tab>
                                        <mat-tab label="Applicant Communication History">
                                            <ng-template matTabContent>
                                                <div class="job_Applicant_comm" *ngIf="communicationHistory">
                                                    <div class="job_Applicant_comm" *ngIf="communicationHistory.length ==0">
                                                        <div class="col-5" >
                                                            <p class="no-data">
                                                                No Communication History Found
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div *ngFor="let com of communicationHistory; let i=index;">
                                                        <div class="card-block">
                                                            <div>
                                                                <div class="row">
                                                                    <div class="col-8">{{ i+1 }}. {{com.msubject}} </div>
                                                                    <div class="col-4 text-right">{{com.createat | date }}</div>
                                                                </div>
                                                                <hr style="border:1px solid">
                                                                <div class="card m-b-20 card-block-new" style="max-height:300px; width:100%; overflow-y:scroll; overflow-x:hidden; padding: 20px;" innerHtml="{{com.fullmessage}}">

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="!communicationHistory">
                                                        <p class="no-data">
                                                            No Communication History Found
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </mat-tab>
                                    </mat-tab-group>
                                </div>
                                <hr class="line" />
                                <div class="job_assment" *ngIf="candidateDetail.cvname">
                                    <h5>CV</h5>
                                    <a href="{{candidateDetail.cvname}}">
                                        <button type="submit" class="btn btn-primary">Download CV</button>
                                    </a>
                                </div>
                                <hr class="line" />
                                <div class="row" *ngIf="iframeresumesrc">
                                    <div class="col-lg-12">
                                        <iframe *ngIf="imgresumesrc == false" [src]="iframeresumesrc" width="100%" style="min-height:842px;" frameborder="0"></iframe>
                                        <img *ngIf="imgresumesrc == true" [src]="iframeresumesrc" style="width:100%;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <app-candnote></app-candnote>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>

<jw-modal id="pdf-image-viewer" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer');">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="500" frameborder="0"></iframe>
                            <img *ngIf="imgsrc == true" [src]="iframesrc" style="width:100%;" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>




<jw-modal id="custom-message-1" role="dialog" class="modal">

    <form [formGroup]="candidatemessageFormGroup" #candidatemessageForm="ngForm" class="s12 white" (ngSubmit)="submitStatus(candidatemessageForm.value,'custom-message-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="candidateid" class="form-control" name="candidateid" id="candidateid" ngModel="{{emailerDetail.candidateid}}">
                    <input type="hidden" formControlName="jobid" class="form-control" name="jobid" id="jobid" [(ngModel)]="emailerDetail.jobid">
                    <input type="hidden" formControlName="ssid" class="form-control" name="ssid" id="ssid" [(ngModel)]="emailerDetail.ssid">
                    <h5 class="modal-title" id="popupLabel">User Message</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-message-1');">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label>Subject *</label>
                                <input type="text" formControlName="subject" class="" name="subject" id="subject" class="form-control" [ngClass]="{ 'is-invalid': submitted && g.subject.errors }" [(ngModel)]="emailerDetail.subject">
                                <div *ngIf="submitted && g.subject.errors" class="invalid-feedback">
                                    <div *ngIf="g.subject.errors.required">Please Enter Subject</div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="emailerDetail.ssid == '8'">
                            <div class="form-group col-lg-12">
                                <label>Location *</label>
                                <input type="text" formControlName="location" name="location" id="location" class="form-control" [ngClass]="{ 'is-invalid': submitted && g.location.errors }" [(ngModel)]="emailerDetail.location">
                                <div *ngIf="submitted && g.location.errors" class="invalid-feedback">
                                    <div *ngIf="g.location.errors.required">Please Enter Location</div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="emailerDetail.ssid == '8'">
                            <div class="form-group col-lg-4">
                                <label>Date *</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker1" [min]="currenDate" name="interdate" id="interdate" [ngClass]="{ 'is-invalid': submitted && g.interdate.errors }" formControlName="interdate" class="form-control" [(ngModel)]="emailerDetail.interdate">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && g.interdate.errors" class="invalid-feedback">
                                    <div *ngIf="g.interdate.errors.required">Please Enter the Interview Date</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Start Time *</label>
                                <select name="interviewstime" formControlName="interviewstime" id="interviewstime" class="form-control">
                                    <option value="00:00">00:00</option>
                                    <option value="00:30">00:30</option>
                                    <option value="01:00">01:00</option>
                                    <option value="01:30">01:30</option>
                                    <option value="02:00">02:00</option>
                                    <option value="02:30">02:30</option>
                                    <option value="03:00">03:00</option>
                                    <option value="03:30">03:30</option>
                                    <option value="04:00">04:00</option>
                                    <option value="04:30">04:30</option>
                                    <option value="05:00">05:00</option>
                                    <option value="05:30">05:30</option>
                                    <option value="06:00">06:00</option>
                                    <option value="06:30">06:30</option>
                                    <option value="07:00">07:00</option>
                                    <option value="07:30">07:30</option>
                                    <option value="08:00">08:00</option>
                                    <option value="08:30">08:30</option>
                                    <option value="09:00">09:00</option>
                                    <option value="09:30">09:30</option>
                                    <option value="10:00">10:00</option>
                                    <option value="10:30">10:30</option>
                                    <option value="11:00">11:00</option>
                                    <option value="11:30">11:30</option>
                                    <option value="12:00">12:00</option>
                                    <option value="12:30">12:30</option>
                                    <option value="13:00">13:00</option>
                                    <option value="13:30">13:30</option>
                                    <option value="14:00">14:00</option>
                                    <option value="14:30">14:30</option>
                                    <option value="15:00">15:00</option>
                                    <option value="15:30">15:30</option>
                                    <option value="16:00">16:00</option>
                                    <option value="16:30">16:30</option>
                                    <option value="17:00">17:00</option>
                                    <option value="17:30">17:30</option>
                                    <option value="18:00">18:00</option>
                                    <option value="18:30">18:30</option>
                                    <option value="19:00">19:00</option>
                                    <option value="19:30">19:30</option>
                                    <option value="20:00">20:00</option>
                                    <option value="20:30">20:30</option>
                                    <option value="21:00">21:00</option>
                                    <option value="21:30">21:30</option>
                                    <option value="22:00">22:00</option>
                                    <option value="22:30">22:30</option>
                                    <option value="23:00">23:00</option>
                                    <option value="23:30">23:30</option>
                                </select>
                                <div *ngIf="submitted && g.location.errors" class="invalid-feedback">
                                    <div *ngIf="g.location.errors.required">Please Enter Start Time</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>End Time *</label>
                                <select name="interviewetime" formControlName="interviewetime" id="interviewetime" class="form-control">
                                    <option value="00:00">00:00</option>
                                    <option value="00:30">00:30</option>
                                    <option value="01:00">01:00</option>
                                    <option value="01:30">01:30</option>
                                    <option value="02:00">02:00</option>
                                    <option value="02:30">02:30</option>
                                    <option value="03:00">03:00</option>
                                    <option value="03:30">03:30</option>
                                    <option value="04:00">04:00</option>
                                    <option value="04:30">04:30</option>
                                    <option value="05:00">05:00</option>
                                    <option value="05:30">05:30</option>
                                    <option value="06:00">06:00</option>
                                    <option value="06:30">06:30</option>
                                    <option value="07:00">07:00</option>
                                    <option value="07:30">07:30</option>
                                    <option value="08:00">08:00</option>
                                    <option value="08:30">08:30</option>
                                    <option value="09:00">09:00</option>
                                    <option value="09:30">09:30</option>
                                    <option value="10:00">10:00</option>
                                    <option value="10:30">10:30</option>
                                    <option value="11:00">11:00</option>
                                    <option value="11:30">11:30</option>
                                    <option value="12:00">12:00</option>
                                    <option value="12:30">12:30</option>
                                    <option value="13:00">13:00</option>
                                    <option value="13:30">13:30</option>
                                    <option value="14:00">14:00</option>
                                    <option value="14:30">14:30</option>
                                    <option value="15:00">15:00</option>
                                    <option value="15:30">15:30</option>
                                    <option value="16:00">16:00</option>
                                    <option value="16:30">16:30</option>
                                    <option value="17:00">17:00</option>
                                    <option value="17:30">17:30</option>
                                    <option value="18:00">18:00</option>
                                    <option value="18:30">18:30</option>
                                    <option value="19:00">19:00</option>
                                    <option value="19:30">19:30</option>
                                    <option value="20:00">20:00</option>
                                    <option value="20:30">20:30</option>
                                    <option value="21:00">21:00</option>
                                    <option value="21:30">21:30</option>
                                    <option value="22:00">22:00</option>
                                    <option value="22:30">22:30</option>
                                    <option value="23:00">23:00</option>
                                    <option value="23:30">23:30</option>
                                </select>
                                <div *ngIf="submitted && g.location.errors" class="invalid-feedback">
                                    <div *ngIf="g.location.errors.required">Please Enter End Time</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label>Message *</label>
                                <ckeditor [editor]="Editor" #messageeditor formControlName="message" [(ngModel)]="emailerDetail.message" [style.height.px]="250"></ckeditor>
                                <div *ngIf="submitted && g.message.errors" class="invalid-feedback">
                                    <div *ngIf="g.message.errors.required">Please Enter the Message </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label style="width: auto;margin-top: 6px;float: left;margin-right: 10px;">Select to send an email to the applicant. </label>
                                <div class="onoffswitch">
                                    <input type="checkbox" name="ismail" (change)="selectall($event.target.checked)" class="onoffswitch-checkbox" id="ismail" [checked]="ismail"  [(ngModel)]="emailerDetail.ismail">
                                    <label class="onoffswitch-label" for="ismail">
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-message-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>



<jw-modal id="custom-Candidate-edit-1" role="dialog" class="modal">

    <form [formGroup]="candidateformGroup" #candidateForm="ngForm" class="s12 white" (ngSubmit)="onContactSubmit(candidateForm.value,'custom-Candidate-edit-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="candidatemasterid" class="form-control" name="candidatemasterid" id="candidatemasterid" [(ngModel)]="candidateDetail.candidatemasterid">
                    <input type="hidden" formControlName="jobid" class="form-control" name="jobid" id="jobid" [(ngModel)]="candidateDetail.jobid">
                    <h5 class="modal-title" id="popupLabel">Candidate Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-Candidate-edit-1');">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>First Name *</label>
                                <input type="text" formControlName="fname" name="fname" id="fname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" [(ngModel)]="candidateDetail.fname">
                                <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                                    <div *ngIf="f.fname.errors.required">Please Enter First Name</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Middle Name </label>
                                <input type="text" formControlName="mname" name="mname" id="mname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mname.errors }" [(ngModel)]="candidateDetail.mname">
                                <div *ngIf="submitted && f.mname.errors" class="invalid-feedback">
                                    <div *ngIf="f.mname.errors.required">Please Enter Middle Name</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Last Name *</label>
                                <input type="text" formControlName="lname" class="" name="lname" id="lname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lname.errors }" ngModel="{{ candidateDetail.lname }}">
                                <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                                    <div *ngIf="f.lname.errors.required">Please Enter Last Name</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Email *</label>
                                <input type="email" formControlName="emailid" name="emailid" id="emailid" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }" [(ngModel)]="candidateDetail.emailid">
                                <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                                    <div *ngIf="f.emailid.errors.required">Please Enter the Email </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Mobile Number</label>
                                <international-phone-number formControlName="phonenumber" class="form-control phonecontrol" [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" #phonenumber name="phonenumber" [(ngModel)]="candidateDetail.phonenumber" placeholder="Enter Mobile number"
                                    [maxlength]="20" (input)="validatenumber($event.target.value,'phone')"></international-phone-number>
                                <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.phonenumber.errors.required">Please Enter Phone Number</div>
                                </div>
                                <span *ngIf="phonecheck===false" style="color:red">Invalid format</span>
                                <span *ngIf="phonecheck===true" style="color:green">Valid format</span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Job URL </label>
                                <select style="width:100%;" formControlName="joburlid" name="joburlid" id="joburlid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.joburlid.errors }" [(ngModel)]="candidateDetail.joburlid">
                                    <option [value]="defaultVal">Select Job URL </option>
                                    <option *ngIf="(jobdata.joburl1 != '') && (jobdata.joburl1 != null)" [value]="0">
                                    {{ jobdata.joburl1 }}</option>
                                    <option *ngIf="(jobdata.joburl2 != '') && (jobdata.joburl2 != null)" [value]="1">
                                    {{ jobdata.joburl2 }}</option>
                                    <option *ngIf="(jobdata.joburl3 != '') && (jobdata.joburl3 != null)" [value]="2">
                                    {{ jobdata.joburl3 }}</option>
                                    <option *ngIf="(jobdata.joburl4 != '') && (jobdata.joburl4 != null)" [value]="3">
                                    {{ jobdata.joburl4 }}</option>
                                </select>
                                <div *ngIf="submitted && f.joburlid.errors" class="invalid-feedback">
                                    <div *ngIf="f.joburlid.errors.required">Please Select Job URL</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Upload Document</label>
                                <div class="input-group mb-3">
                                    <div class="custom-file" (click)="file.click()">
                                        <input type="file" #file (change)="selectFile($event)">
                                        <label class="custom-file-label">{{filename}}</label>
                                    </div>
                                </div>
                                <div class="alert-danger" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                <div class="alert-danger" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png, tif, gif, pdf, doc)
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-Candidate-edit-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>