"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var CompanyadvertService = /** @class */ (function () {
    function CompanyadvertService(commonService) {
        this.commonService = commonService;
    }
    CompanyadvertService.prototype.getcompanyadvertdata = function (data) {
        return this.commonService.formpost('/company/getcompanyadvertdata', data);
    };
    CompanyadvertService.prototype.downloadcompanyadvertdata = function (data) {
        return this.commonService.formpost('/company/downloadcompanyadvertdata', data);
    };
    CompanyadvertService.prototype.searchcompany = function (data) {
        return this.commonService.formpost('/company/searchcompany', data);
    };
    CompanyadvertService.prototype.getcompanyimages = function (data) {
        return this.commonService.formpost('/company/getimages', data);
    };
    CompanyadvertService.prototype.getcompanyhistoryadvertdata = function () {
        return this.commonService.formpostOnlyid('/company/getcompanyadvertdatahistorydetails');
    };
    CompanyadvertService.prototype.getcompanyhistoryview = function (data) {
        return this.commonService.formpost('/company/getcompanyhistoryview', data);
    };
    CompanyadvertService.prototype.downloadcompanyimage = function (data) {
        return this.commonService.formpost('/company/downloadimage', data);
    };
    CompanyadvertService.ngInjectableDef = i0.defineInjectable({ factory: function CompanyadvertService_Factory() { return new CompanyadvertService(i0.inject(i1.CommonService)); }, token: CompanyadvertService, providedIn: "root" });
    return CompanyadvertService;
}());
exports.CompanyadvertService = CompanyadvertService;
