<app-header>
</app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <a [routerLink]="['/adversecredit']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Adverse Credit Search</a>
          
            <div class="clearfix"></div>
            <div class="tabs__content">
                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Adverse Credit Search Log </h5>
                            </div>

                            <!-- <div class="col-lg-6">
                              <div class="input-group">
                                  <input placeholder="Search.." class="form-control">
                              </div>

                          </div> -->
                        </div>

                        <ng-container *ngIf="unassignedCases && unassignedCases.data">


                        <div *ngFor="let sr of unassignedCases.data; let i=index;">

                          {{ outputnewset(sr.searchcondition)}}

                        <div class="credit_data_showlog" >
                            <div class="job_list_section visa_request_section">

                                <div class="job_detils_date width13">
                                    <div class="visa_user_details ">
                                        <p class="job_address">Full Name</p>

                                        <h4><a [routerLink]="['/adversecredit', sr.id ]">{{ searchresultnew.title }} {{ searchresultnew.forename }} {{ searchresultnew.sirname }}</a></h4>
                                        <!-- <p class="phone text-success"> <b>Male</b></p>
                                    <p class="ninumber text-primary">Id - 5555 </p> -->
                                        <!-- <span class="badge badge-danger">Indian</span> -->
                                    </div>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Post Code</p>
                                    <p class="job_profile">{{ searchresultnew.postcode }}</p>
                                    <!-- <p class="job_profile" *ngIf="searchresultnew.postcode"> N/A</p> -->
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">House Number</p>

                                    <p class="job_profile">{{ searchresultnew.flatnumber }}&nbsp;</p>
                                    <!-- <p class="job_profile" *ngIf="searchresultnew.flatnumber"> N/A</p> -->
                                </div>
                                <div class="job_detils_date width25">
                                    <p class="job_address">Street</p>

                                    <p class="job_profile">{{ searchresultnew.street }}&nbsp;</p>
                                    <!-- <p class="job_profile" *ngIf="searchresultnew.street"> N/A</p> -->
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Town</p>

                                    <p class="job_profile">{{ searchresultnew.town }}&nbsp;</p>
                                    <!-- <p class="job_profile" *ngIf="searchresultnew.town"> N/A</p> -->
                                </div>
                               
                                <div class="job_detils_date width10">
                                    <p class="job_address">Locality</p>
                                    <p class="job_profile">{{ searchresultnew.locality }}&nbsp;</p>
                                    <!-- <p class="job_profile" *ngIf="searchresultnew.locality"> N/A</p> -->
                                </div>

                                <div class="job_detils_date ">
                                    <p class="job_address">Search Date</p>
                                    <p class="job_profile">{{ sr.createat | date: 'dd MMM yyyy'}}&nbsp;</p>
                                    <!-- <p class="job_profile" *ngIf="searchresultnew.locality"> N/A</p> -->
                                </div>
                               
                                <div class="job_detils_date width10 text-right">
                                    <button type="button" class="btn btn-primary com_btn" [innerHtml]="downloadtext[i]" (click)="downloadAdverseCreditHistory(sr.id,i)"></button>
                                </div>

                                <!-- <div class="job_detils_date ">
                                    <a type="submit" class="btn btn-primary com_btn" [routerLink]="'/adverse-credit-history-view/'+ sr.id">View Details</a>
                                </div> -->


                            </div>
                          
                        </div>
                        </div> 
                        

                        <div class="paginationbox">
                            <span class="number">                    
                    
                              <button (click)=selectpage(1) [disabled]="selectedpage == 1" class="firstpage"><i
                                  class="fa fa-angle-double-left" aria-hidden="true"></i></button>
                              <button (click)=selectpage(selectedpage-1) [disabled]="selectedpage == 1" class="firstarrow"><i
                                  class="fa fa-angle-left" aria-hidden="true"></i></button>
                              <ng-container *ngFor="let pagesarray of unassignedCases.totalpagesarray;  let i=index">
                                <a *ngIf="(pagesarray <= selectedpage+((selectedpage==1)?4:2)) && (pagesarray >= selectedpage-((selectedpage==unassignedCases.totalpagesarray.length)?4:2))"
                                  (click)=selectpage(pagesarray) [ngClass]="{ 'active':  pagesarray == selectedpage }" class="pagenumber">
                                  {{pagesarray}}
                                </a>
                                <!-- <span *ngIf="selectedcasestatusresponse.totalpagesarray.length>5">...</span> -->
                              </ng-container>
                    
                              <button (click)=selectpage(selectedpage+1) [disabled]="selectedpage == unassignedCases.totalpagesarray.length"
                                class="lastarrow"><i class="fa fa-angle-right" aria-hidden="true"></i></button>
                              <button (click)=selectpage(unassignedCases.totalpagesarray.length)
                                [disabled]="selectedpage == unassignedCases.totalpagesarray.length" class="lastpagepage"><i
                                  class="fa fa-angle-double-right" aria-hidden="true"></i></button>              
                    
                                  
                            </span>
                          </div>

                        



                        <div class="row" *ngIf="unassignedCases">
                            <div class="col-lg-12 text-left" *ngIf="unassignedCases.length == 0">
                                <div class="billed-to">No Record Found </div>
                            </div> 
                        </div>
                    </ng-container>

                      
                    </div>


                    <!-- <p class="job_profile"  *ngIf="!searchresult"> N/A</p> -->

                        <div class="browser-screen-loading-content" *ngIf="!searchresult" >
                            <div class="loading-dots dark-gray">
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>                                
                                <i></i>
                            </div>
                            <p>Loading</p>
                        </div>

                 

                </div>
            </div>
        </div>
    </div>
</div>