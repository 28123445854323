<div class="overviewsshow">
    <div class="header">
        <h1 class="headprofile mt-0">COS</h1>
        <a (click)="openModal2('custom-cos-1')" class="addscreensuccess">Add COS</a>
    </div>
    <div class="row">
        <div class="col-lg-6" *ngFor="let cos of cosDetails">
            <div class="border-box">
                <div class="addhead header mb-3">
                    <span class="badge badge-success mg-r-10" *ngIf="cos.iscurrent == true"><i class="fa fa-check" aria-hidden="true"></i> Current</span>
                   
                    <div class="icons_box_section">
                        <a (click)="openModal('custom-cos-1',cos)" class="editprime ">
                            <i class="lnr lnr-pencil"></i></a>
                        <a (click)="onDelete(cos.employecosdetailid)" class="deletered">
                            <i class="lnr lnr-trash"></i>
                        </a>
                    </div>
                </div>
                <div class="overview_details emp">
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3">
                            <span>COS Number</span>
                            <p>{{cos.cosnumber }}</p>
                        </div>
                        <div class="detailsshow width50 pr-3">
                            <span>COS Start Date</span>
                            <p>{{cos.cosstartdate | date: 'dd MMM yyyy'}}</p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3">
                            <span>COS Expiry Date</span>
                            <p>{{cos.cosexpirydate | date: 'dd MMM yyyy'}}</p>
                        </div>
                        <div class="detailsshow width50 pr-3">
                            <span>COS Document</span>
                            <p> <a (click)="openModal3('pdf-image-cos-viewer',cos.cosdocname)">
                                <i class="fa fa-file-image-o mg-r-10"></i>
                            </a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="job_list_section visa_request_section"  *ngIf="!cosDetails?.length  > false">
        <div class="job_detils_date">
            <p class="job_profile">No Record Found</p>
        </div>
    </div>        
    
</div>
<!-- <div class="row">
    <div class="col-lg-7">
        <h1 class="headprofile">COS</h1>
    </div>

    <div class="col-lg-5 float-right">
        <h6 class="text-right float-right buttonprofile">
            <a (click)="openModal2('custom-cos-1')" class="addscreensuccess">+ Add COS</a></h6>
    </div>
</div> -->

<!-- <div class="row row-sm">
   
    <div class="col-lg-4" *ngIf="!cosDetails?.length  > false">
        <div class="billed-to">
            No Record Found
        </div>
    </div>

    <div class="col-lg-4" *ngFor="let cos of cosDetails">
        <div class="billed-to">
            <p><b>COS Number: </b>{{cos.cosnumber }}

                <span class="badge badge-success mg-r-10" *ngIf="cos.iscurrent == true"><i class="fa fa-check" aria-hidden="true"></i> Current</span>



            </p>
            <p><b>COS Start Date: </b>{{cos.cosstartdate | date: 'dd MMM yyyy'}}</p>
            <p><b>COS Expiry Date: </b>{{cos.cosexpirydate | date: 'dd MMM yyyy'}}</p>
            <p><b>COS Document: </b>
                <a (click)="openModal3('pdf-image-cos-viewer',cos.cosdocname)">
                    <i class="fa fa-file-image-o mg-r-10"></i>
                </a>
            </p>

            <div class="con_icons">
                <a (click)="openModal('custom-cos-1',cos)" class="lsview">
                    <i class="fa fa-pencil mg-r-10"></i>&nbsp;</a>
                <a (click)="onDelete(cos.employecosdetailid)" class="lsview">
                    <i class="fa fa-trash-o mg-l"></i>
                </a>
            </div>
        </div>
    </div>
   
</div> -->





<jw-modal id="custom-cos-1" role="dialog" class="modal">
    <form [formGroup]="cosForm" #cosform="ngForm" class="s12 white" (ngSubmit)="onSubmit(cosform.value,'custom-cos-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="cosdetails.employeeid">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="cosdetails.employecosdetailid">

                    <h5 class="modal-title" id="popupLabel">COS Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-cos-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>COS Number *</label>
                                <input type="cosnumber" formControlName="cosnumber" class="" name="cosnumber" id="cosnumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.cosnumber.errors }" [(ngModel)]="cosdetails.cosnumber">
                                <div *ngIf="submitted && f.cosnumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.cosnumber.errors.required">Please Enter COS Number</div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <label>Upload Document</label>
                                <div class="input-group mb-6" (click)="file.click()">
                                    <div class="custom-file">
                                        <input type="file" #file (change)="selectFile($event)">
                                        <label class="custom-file-label">{{filename}}</label>
                                    </div>
                                </div>
                                <div class="alert-danger" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                <div class="alert-danger" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png, tif, gif, pdf, doc)
                                </div>
                            </div>
                        </div>

                        <div class="row">

                            <div class="form-group col-lg-6">
                                <label>COS Start Date *</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker1" name="cosstartdate" id="cosstartdate" [ngClass]="{ 'is-invalid': submitted && f.cosstartdate.errors }" formControlName="cosstartdate" class="form-control" [(ngModel)]="cosdetails.cosstartdate">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.cosstartdate.errors" class="invalid-feedback">
                                    <div *ngIf="f.cosstartdate.errors.required">Please Choose Start Date</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>COS Expiry Date *</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker2" [min]="cosForm.controls.cosstartdate.value" [ngClass]="{ 'is-invalid': submitted && f.cosexpirydate.errors }" formControlName="cosexpirydate" id="cosexpirydate" name="cosexpirydate" class="form-control  datecontrol"
                                        [(ngModel)]="cosdetails.cosexpirydate">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.cosexpirydate.errors" class="invalid-feedback">
                                    <div *ngIf="f.cosexpirydate.errors.required">Please Choose Expiry Date</div>
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <div> Is this your current COS?
                                    <!-- <input type="checkbox" [checked]="cosdetails.iscurrent" formControlName="iscurrent" name="iscurrent" [(ngModel)]="cosdetails.iscurrent" /> -->
                                    Yes <input type="radio" value="1" formControlName="iscurrent"  id="currentyes" name="iscurrent" [ngModel]="cosdetails.iscurrent" />
                                    No <input type="radio" value="0" formControlName="iscurrent"  id="currentno" name="iscurrent" [ngModel]="cosdetails.iscurrent" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary  com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-cos-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>

<jw-modal id="pdf-image-cos-viewer" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-cos-viewer');">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-12">

                            <iframe *ngIf="imgcossrc == false" [src]="iframecossrc" width="100%" height="600" frameborder="0"></iframe>
                            <img *ngIf="imgcossrc == true" [src]="iframecossrc" width="100%" />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</jw-modal>