<app-header></app-header>
<div class="container">
  <div class="overviewsshow check_support mt-4">
      <div class="header">
        <h1 class="headprofile mt-0">Add New Applicant</h1>
      </div>
      <form [formGroup]="employeeForm" #overviewForm="ngForm" class="s12 white"  (ngSubmit)="onAddEmployeeSubmit(overviewForm.value,'custom-employee')">
      
      <div class="formgroup">
       
        <div class="row">
          <div class="form-group col-lg-4">
              <label>First Name*</label>
              <input type="fname" formControlName="fname" name="fname" id="fname" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.fname.errors }"
                  [(ngModel)]="overviewData.fname" maxlength="50">
              <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                  <div *ngIf="f.fname.errors.required">Please enter first name</div>
              </div>
          </div>

          <div class="form-group col-lg-4">
              <label>Middle Name</label>
              <input type="mname" formControlName="mname" name="mname" id="mname" class="form-control"
                  [(ngModel)]="overviewData.mname" maxlength="50">
          </div>
          <div class="form-group col-lg-4">
              <label>Last Name*</label>
              <input type="lname" formControlName="lname" name="lname" id="lname"
                  class="form-control input-lg"
                  [ngClass]="{ 'is-invalid': submitted && f.lname.errors }"
                  [(ngModel)]="overviewData.lname" maxlength="50">
              <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                  <div *ngIf="f.lname.errors.required">Please enter last name</div>
              </div>
          </div>
      </div>

      <div class="row" style="display: none;">
          <div class="form-group col-lg-4">
              <label>Date of Birth*</label>
              <mat-form-field class="datecontrol">
                  <input matInput [min]="mindob" [max]="maxdob" [matDatepicker]="picker2"
                      id="dateofbirth" [ngClass]="{ 'is-invalid': submitted && f.dateofbirth.errors }"
                      name="dateofbirth" formControlName="dateofbirth"
                      class="form-control noborder-invalid" [(ngModel)]="overviewData.dateofbirth">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
              <div *ngIf="submitted && f.dateofbirth.errors" class="invalid-feedback">
                  <div *ngIf="f.dateofbirth.errors.required">Please choose date of birth</div>
              </div>
          </div>

          <div class="form-group col-lg-4">
              <label>Hire Date*</label>
              <mat-form-field class="datecontrol">
                  <input matInput [matDatepicker]="picker3" id="hiredate"
                      [ngClass]="{ 'is-invalid': submitted && f.hiredate.errors }" name="hiredate"
                      formControlName="hiredate" class="form-control noborder-invalid"
                      [(ngModel)]="overviewData.hiredate">
                  <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
              </mat-form-field>
              <div *ngIf="submitted && f.hiredate.errors" class="invalid-feedback">
                  <div *ngIf="f.hiredate.errors.required">Please choose hire date</div>
              </div>
          </div>

          <div class="form-group col-lg-4">
            <label>Employee ID*</label>
            <input type="text" formControlName="empid" name="empid" id="empid" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.empid.errors }"
                [(ngModel)]="overviewData.employeeid">
            <div *ngIf="submitted && f.empid.errors" class="invalid-feedback">
                <div *ngIf="f.empid.errors.required">Please enter employee id</div>
            </div>
            <div *ngIf="empidexist" class="invalid-feedback">
                <div>This employee id already exists</div>
            </div>
        </div>
        <div class="form-group col-lg-4">
          <label>Job Role*</label>
          <input type="text" formControlName="emprole" name="emprole" id="emprole"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emprole.errors }"
              [(ngModel)]="overviewData.emprole" maxlength="70">
          <div *ngIf="submitted && f.emprole.errors" class="invalid-feedback">
              <div *ngIf="f.emprole.errors.required">Please enter job role</div>
          </div>
      </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-6">
            <label>Email*</label>
            <input type="emailid" (focusout)="onFocusOutEvent($event)"  (focus)="focusFunction()" maxlength="100"
                formControlName="emailid" name="emailid" id="emailid" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }"
                [(ngModel)]="overviewData.emailid">
            <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                <div *ngIf="f.emailid.errors.required">Please enter email </div>
            </div>
            <div class="invalid-feedback">
                <div class="error" *ngIf="emailexist">This email is already registered</div>
            </div>
        </div>
        <!-- <div class="form-group col-lg-4">
            <label>User Name*</label>
            <input type="text" (focusout)="onUserNameOutEvent($event)"  formControlName="username" name="username" id="username"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                [(ngModel)]="overviewData.username" maxlength="70">
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Please enter user name</div>
            </div>
            <span *ngIf="username=='true'" style="color:red; width: 100%; font-size: 80%;">User name already exists.</span>
            <span *ngIf="username=='false'" style="color:green; width: 100%; font-size: 80%;">User name available.</span>
      
        </div> -->
        <div class="form-group col-lg-6 ">
            <label>Mobile Number*</label>
            <international-phone-number formControlName="phonenumber"
                class="form-control phonecontrol"
                [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }"
                [(ngModel)]="overviewData.phonenumber" placeholder="Enter mobile number"
                (input)="validatenumber($event.target.value,'phone')" [maxlength]="20"
                [required]="true" #mobilenumber name="phonenumber"></international-phone-number>
            <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                <div *ngIf="f.phonenumber.errors.required">Please enter mobile number</div>
            </div>
            <span *ngIf="phonecheck===false" style="color:red">Invalid format</span>
            <span *ngIf="phonecheck===true" style="color:green">Valid format</span>
        </div>
    </div>
      <div class="row" style="display: none;">
          <div class="form-group col-lg-6">
              <label>Gender </label>
              <div class="form-group">
                  <div class="row">
                      <div class="col-lg-6 col-6">
                          <input type="radio" name="gender" formControlName="gender"
                              [(ngModel)]='overviewData.gender' value="1"
                              [checked]="overviewData.gender === true" /> Male
                      </div>
                      <div class="col-lg-6 col-6">
                          <input type="radio" name="gender" formControlName="gender"
                              [(ngModel)]='overviewData.gender' value="0"
                              [checked]="overviewData.gender === false" /> Female
                      </div>
                  </div>

              </div>

          </div>
          <div class="form-group col-lg-6">
              <label>Marital Status</label>
              <div class="form-group">
                  <div class="row">
                      <div class="col-lg-6 col-6">
                          <input type="radio" name="maritalstatus" formControlName="maritalstatus"
                              [(ngModel)]='overviewData.maritalstatus' value="1"
                              [checked]="overviewData.maritalstatus === true"> Married
                      </div>
                      <div class="col-lg-6 col-6">
                          <input type="radio" name="maritalstatus" formControlName="maritalstatus"
                              [(ngModel)]='overviewData.maritalstatus' value="0"
                              [checked]="overviewData.maritalstatus === false"> Single
                      </div>
                  </div>
              </div>
          </div>
         
      </div>
      
      <div class="row" style="display: none;">
          <div class="form-group col-lg-6">
              <label>Nationality* </label>
              <select style="width:100%;" name="nationalityid" formControlName="nationalityid"
                  class="form-control input-lg" id="nationalityid"
                  [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }"
                  [(ngModel)]="overviewData.nationalityid">
                  <option [value]="defaultVal">Select Nationality</option>
                  <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">
                      {{ ad3.countryname }}</option>
              </select>
              <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                  <div *ngIf="f.nationalityid.errors.required">Please select nationality</div>
              </div>
          </div>
          <div class="form-group col-lg-6">
              <label>Country of Residence*</label>
              <select style="width:100%;" name="residenceid" formControlName="residenceid"
                  class="form-control input-lg" id="residenceid"
                  [ngClass]="{ 'is-invalid': submitted && f.residenceid.errors }"
                  [(ngModel)]="overviewData.residenceid">
                  <option [value]="defaultVal">Select Country of Residence</option>
                  <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">
                      {{ ad3.countryname }}</option>
              </select>
              <div *ngIf="submitted && f.residenceid.errors" class="invalid-feedback">
                  <div *ngIf="f.residenceid.errors.required">Please select country of residence</div>
              </div>
          </div>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-lg-12 mt-2 text-right">

        <input type="button" *ngIf="emptype=='visa' || emptype=='passport' || emptype=='document'" class="btn btn-secondary com_btn" name="updateform" value="Back"  [routerLink]="['/check-employee-list', emptype]"    />
        <input type="button" *ngIf="emptype=='basic-dbs' || emptype=='standard-dbs' || emptype=='enhanced-dbs'" class="btn btn-secondary com_btn" name="updateform" value="Back"  [routerLink]="['/dbs', emptype.split('-')[0]]"    />


        <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn"
                      value="Next" />
                  
      </div>
  </div>
  </form>

  </div>
</div>





<jw-modal id="alreadyadd-request" role="dialog" class="modal sendrequestcharge">
  <div role="document" class="formgroup">
      <div class="modal-content">
         
          <div class="modal-body">
              <div class="container">
                <div class="info_mess">
                    <!-- <i class="fa fa-info-circle" aria-hidden="true" ></i> -->
                    <div class="para text-left">
                        <h4 class="mar-20 red">This applicant already exists in your account. </h4>
                        <ul>
                            <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                Send an email to the applicant to upload documents. Or, upload the documents now using Upload Now button.</li>
                            <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                 Once the document has been uploaded you will be notified by an email. Alternatively, login to your admin panel to check the results. </li>
                           
                        </ul>
                         
                    </div>                          
                </div>
                  <!-- <div class="row">
                        <div class="form-group col-lg-12">
                              <div class="row">This applicant already add in your account.</div>
                          </div>
                  </div> -->


                  <!-- <div class="row">
                      <div class="form-group col-lg-12">
                            <div class="row">If you want to send request to applicant. It's will be charege one check</div>
                        </div>
                </div> -->

               </div>
          </div>
          <div class="modal-footer">


            <input type="button" class="btn btn-primary com_btn" name="updateform" (click)="sendrequest(empleeid,'alreadyadd-request')" value="Send Request To Applicant" />
            <input type="button" class="btn btn-danger com_btn" name="updateform"  (click)="addbio();"  value="Upload Now" />



              <input type="button" class="btn btn-dark com_btn" (click)="closeModal('alreadyadd-request');" name="updateform" value="Cancel" />
          </div>
      </div>
  </div>
</jw-modal>