import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Employee } from '../../models/employee.model';
import { CountryService } from '../../services/country.service';
import { ApplicantService } from '../../services/applicant.service';
import { JobService } from '../../services/job.service';
import { Applicant } from '../../models/applicant.model';
import { ExcelService } from '../../services/excel.service';

@Component({
  selector: 'app-applicantreport',
  templateUrl: './applicantreport.component.html',
  styleUrls: ['./applicantreport.component.css']
})

export class ApplicantreportComponent implements OnInit {
  EmployeeForm: FormGroup;
  applicantDetail = new Applicant();
  employeelist: any;
  countrylist: any;
  joblist: any;
  data: any;
  exceldata = [];
  candidatedata: any;
  statuslist: any;

  constructor(private excelService: ExcelService, private formBuilder: FormBuilder, private country: CountryService, private candidate: ApplicantService, private jobser: JobService) { }

  ngOnInit() {
    this.EmployeeForm = this.formBuilder.group({
      fname: [''],
      lname: [''],
      emailid: [''],
      phonenumber: [''],
      jobid: [''],
      cstatus: [''],
    });
    var value3 = "{}";
    this.candidate.getcandidatelist(value3).subscribe((listdetails: any) => {
      this.data = listdetails;
    });
    this.candidate.getcandidatestatus().subscribe((statusdetails: any) => {
      this.statuslist = statusdetails;
    });
    this.jobser.getjoblist().subscribe((jobdetails: any) => {
      this.joblist = jobdetails;
    });
  }

  exportAsXLSX(): void {
    this.exceldata = [];
    this.data.forEach(obj => {
      //var candidateData = {};
      if (obj.joburlid == '0') {
        var url = obj.job.joburl1;
      } else if (obj.joburlid == '1') {
        var url = obj.job.joburl2;
      }
      else if (obj.joburlid == '2') {
        var url = obj.job.joburl3;
      }
      else if (obj.joburlid == '3') {
        var url = obj.job.joburl4;
      }
      this.candidatedata = {
        "Name": obj.fname + " " + obj.mname + " " + obj.lname,
        "Email": obj.emailid,
        "Phone Number": obj.phonenumber,
        "Job Title": obj.job.jobtittle,
        "Job URL": url,
        "Marks": obj.smarks,
        "Status": obj.status.statusname
      };
      this.exceldata.push(this.candidatedata);
    });
    this.excelService.exportAsExcelFile(this.exceldata, 'Applicant_Report');
  }

  onSubmit(value3: any) {
    this.candidate.getcandidatelist(value3).subscribe((listdetails: any) => {
      this.data = listdetails;
    });
  }
}