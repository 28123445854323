import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { CommonService } from '../../../services/common.service';
import { CountryService } from '../../../services/country.service';
import { CompanyService } from '../../../services/company.service';
import { Router } from '@angular/router';
import { AssetService } from '../../../services/asset.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Expensetype, Holidaytype } from '../../../models/expense/expensetype.model';
import { MatOptionSelectionChange } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DomSanitizer } from '@angular/platform-browser';
import { LeaveService } from '../../../services/leave.service';


@Component({
  selector: 'app-settingleavetype',
  templateUrl: './settingleavetype.component.html',
  styleUrls: ['./settingleavetype.component.css']
})
export class SettingleavetypeComponent implements OnInit {

  Userid: any;
  data: any;
  hourDetail = new Holidaytype();
  hourformGroup: FormGroup;
  options = [];
  buttoninsert: any;
  submitted: boolean = false;
  public searchText: string;
  disableBtn: any;
  permission: any;
  settingperm: any;
  record:number;
  
  items: Array<any>;
  
  pageOfItems: Array<any>;
  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private leaveser: LeaveService
  ) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.settingperm = this.permission.m81;

    this.Userid = localStorage.getItem('userid');
    this.hourDetail = new Holidaytype();
    
    this.getleavetypeformgroup();

    this.AllData();
  }
  AllData(){

    const sstatustype = { 'status': false, 'id': this.Userid } 
    this.leaveser.getleavetypelist(sstatustype).subscribe((leavetypelist: any) => {
      this.data = leavetypelist;
      this.record = this.data.length;

      if(this.record < 30){
        this.pageOfItems = this.data;
      }
      else{
           this.items =this.data;
      }
    });
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  getleavetypeformgroup()
  {
    this.hourformGroup = this.formBuilder.group({
      id: [''],
      typename: ['', Validators.required],
      noofholiday: ['', Validators.compose([Validators.required,  Validators.pattern(/^[.\d]+$/),Validators.max(365), Validators.min(1)])],
      htype: ['']
    });
  }

  get f() { return this.hourformGroup.controls; }

  onSubmit(formleave: any, id: string) {
    // var formleave = settingleavetypedata.value;
    this.submitted = true;
    if (this.hourformGroup.invalid) {
      return;
    }
    this.disableBtn = false;
    formleave.userid = this.Userid;
    this.leaveser.settingleavetypeUpdate(formleave).subscribe((response: any) => {
      
      this.AllData();
      this.modalService.close(id);
      
    });
  }

  onChangeStatus(evt: any, leavetype: any)
  {
    var cstatus;
    if(evt.target.checked)
    {
      cstatus = '1';
    }
    else if(!evt.target.checked)
    {
      cstatus = '0';
    }
    this.leaveser.leavetypeUpdateChangeStatus({'userid': this.Userid, 'id': leavetype.id, 'cstatus': evt.target.checked}).subscribe((data: any) => {
      this.AllData();
    });
  }

  openModal(id: string, data: any) {
    this.buttoninsert = "Update";
    this.disableBtn = true;
    this.hourDetail = data;
    this.hourformGroup.reset(this.hourDetail);
    this.modalService.open(id);
  }

  openModal2(id: string) {
    this.submitted = false;
    this.buttoninsert = "Submit";
    this.disableBtn = true;
    
    // this.getleavetypeformgroup();
    this.hourDetail = new Holidaytype();
    this.hourDetail.id = 0;
    this.hourDetail.htype = "1";
    this.hourformGroup.reset(this.hourDetail);
    // console.log(this.hourDetail);
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

}
