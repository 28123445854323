import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../../_services/modal.service';

import { CountryService } from '../../../../services/country.service';
import { Aml,AmlBussiness,amldetailsclass } from '../../../../models/aml/aml.model';
import { AmlService } from '../../../../services/aml.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
declare let $: any;
@Component({
  selector: 'app-searchpersonalamldetails',
  templateUrl: './searchpersonalamldetails.component.html',
  styleUrls: ['./searchpersonalamldetails.component.css']
})
export class SearchpersonalamldetailsComponent implements OnInit {

  logid: any;
  personid:any;
  result: boolean;
  searchresult: any;
  searchcondition:any;

  datasetlist: any;

  countrylist:any;
  directors:any;
  opendirector:any;

   bussinessdetails:any;
   resultevidancelist=[];
   imgvisasrc:any;
   iframevisasrc:any;
   noresult:boolean = false;
  tPEPs: any=0;
  url:any;
  tCurrentSanctions: any=0;
  tDisqualifiedDirector: any=0;
  tFinancialRegulator: any=0;
  tInsolvency: any=0;
  tLawEnforcement: any=0;
  tAdverseMedia: any=0;
  tPreviousSanctions: any=0;
  downloadtext: any;

  PEPs: any= [];
  CurrentSanctions: any= [];
  DisqualifiedDirector: any= [];
  FinancialRegulator: any= [];
  Insolvency: any= [];
  LawEnforcement: any= [];
  AdverseMedia: any= [];
  PreviousSanctions: any= [];
  
  
  resourceId:any;
  
  backtext:any;

  atype:any =0;

  constructor(private sanitizer: DomSanitizer, private modalService: ModalService,    
    private amldetails: AmlService,  private router: Router,
    private activatedRoute: ActivatedRoute,
    private country: CountryService
    ) { }

  ngOnInit() {

    
    this.downloadtext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';

    var newlist ;
    var newpersonalid;
    this.bussinessdetails =new amldetailsclass();
    this.personid = this.activatedRoute.snapshot.paramMap.get("personid");

    newpersonalid = this.personid ;
    this.searchresult =[];

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    


    this.logid = this.activatedRoute.snapshot.paramMap.get("id");

    this.resourceId = this.activatedRoute.snapshot.paramMap.get("resourceid");

    var val ={};

    if(this.resourceId)
       val ={ id: this.logid,atype:0,resourceId:this.resourceId  }
    else
       val ={ id: this.logid,atype:0 }

    
  this.amldetails.getCompanylogrecord(val).subscribe((searchRes: any) => { 
    var listmaster =JSON.parse(searchRes.searchresult);  
    var director =JSON.parse(searchRes.director); 
    var opendirector =JSON.parse(searchRes.opendirector); 
    this.searchcondition = JSON.parse(searchRes.searchcondition);
    this.atype = searchRes.atype;

    if(searchRes.atype =='0')
    {
       this.backtext = 'Back to Search Results';
    }
    else if(searchRes.atype =='3')
    {
      this.backtext ='Back to Search Results ';
    }
    else if(searchRes.atype =='2')
    {
      this.backtext = 'Back to Search Results';
    }
    
    if(searchRes.iscomplete === 0 &&  !this.resourceId )
    {
      if(listmaster.results.matchCount ==0)
      {
        this.noresult =true;
        this.result=true;
        this.searchcondition = JSON.parse(searchRes.searchcondition);
      } 
      
    }
    else
    {
      this.noresult =false;      
      this.searchresult = listmaster;
      this.bindattribute(listmaster);
      this.directors =director;
      this.opendirector =opendirector;
      this.bussinessdetails = listmaster;

      console.log(listmaster);
    }
  });
});

  }


  
  loaddatasystem(){
    this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';

    var dd = {};

    if(this.resourceId)
    {
      dd = {id: this.logid,atype:this.atype,resourceId:this.resourceId}
    }
    else
    {
      dd = {id: this.logid,atype:this.atype}
    }

    this.amldetails.generateNewbussinessPdf(dd).subscribe((pdfRes: any) => { 
      console.log(pdfRes.response_data.Location);
      this.url = pdfRes.response_data.Location;
      window.open(this.url,"_blank");
      this.downloadtext = 'Dwonload <i class="fa fa-file-pdf-o ml-2"></i>';
    });
  }


  amllogredirect()
  {
    if(this.atype=='0' && this.resourceId)
        this.router.navigate(['/search-personal-aml','aml', this.logid]);    
    else if(this.atype=='2' && this.resourceId)
      this.router.navigate(['/search-personal-aml','adverse', this.logid]);    
    else if(this.atype=='3' && this.resourceId)
      this.router.navigate(['/search-personal-aml','global', this.logid]);    
    else if(this.atype=='0')
       this.router.navigate(['/check-aml-log-list', '0']);    
    else  if(this.atype=='2')
      this.router.navigate(['/check-aml-log-list', '2']); 
    else
      this.router.navigate(['/check-aml-log-list', '3']);
  }

  urlfind (url){

    if(url != null)
    {
        var slashslash = url.indexOf("//") + 2;
        return url.substring(slashslash, url.indexOf('/', slashslash));
  }else{
    return "";
  }


  }

  bindallcountry(val)
  {    
if(val)
{    return this.countrylist.filter(d => val.includes(d.iso));}
else return [];
    // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
  }
  bindselectedevidance(val)
  {    
    if(val){
    return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
  }else return [];
  }


bindattribute(data){
  
  
  this.AdverseMedia = [];
  this.PreviousSanctions =[];
  this.PEPs = [];
  this.LawEnforcement = [];
  this.CurrentSanctions = [];
  this.FinancialRegulator = [];
  this.DisqualifiedDirector = [];
  this.Insolvency = [];


  var rAdverseMedia = [];
  var rPreviousSanctions =[];
  var rPEPs = [];
  var rLawEnforcement = [];
  var rCurrentSanctions = [];
  var rFinancialRegulator = [];
  var rDisqualifiedDirector = [];
  var rInsolvency = [];


  
  

  var datasetlist=[];
  data.evidences.forEach(element2 => {
    element2.datasets.forEach(element3 => {
      datasetlist.push(element3);          
    });    
  });

  let unique = datasetlist.filter((item, i, ar) => ar.indexOf(item) === i);

  console.log(unique);

  var evidanceobj={ name:'',values:[] }
  var evidancelist=[]

  unique.forEach(element => {
    evidanceobj=
    { 
      name:element, 
      values: data.evidences.filter(d => d.datasets.includes(element))      
    }    
    evidancelist.push(evidanceobj);
  });


  this.resultevidancelist =evidancelist; 

console.log(this.resultevidancelist);



  // data.person.articles.forEach(function(entry2) {
  //   // console.log("Second Type Development", entry2);
  //   entry2.categories.forEach(function(entry3) { 
      
  //     if(entry3.name =='Adverse Media')
  //     {
        
  //       // this.tAdverseMedia =this.tAdverseMedia +1;

  //       rAdverseMedia.push(entry2); 

  //     }else if(entry3.name =='PEP')
  //     {
  //       // this.tPEPs =this.tPEPs +1;

  //       rPEPs.push(entry2); 

  //     }else if(entry3.name =='Corporate/Business')
  //     {

  //       // this.tLawEnforcement =this.tLawEnforcement +1;

  //       rPreviousSanctions.push(entry2); 

  //     }else if(entry3.name =='Law Enforcement')
  //     {
  //       // this.tLawEnforcement =this.tLawEnforcement +1;
  //       rLawEnforcement.push(entry2); 
  //     }else if(entry3.name =='Sanction')
  //     {
  //       // this.tCurrentSanctions =this.tCurrentSanctions +1;
  //       rCurrentSanctions.push(entry2); 
  //     }else if(entry3.name =='Financial Regulator')
  //     {
  //       // this.tFinancialRegulator =this.tFinancialRegulator +1;
  //       rFinancialRegulator.push(entry2); 
  //     }else if(entry3.name =='Disqualified Director')
  //     {
  //       // this.tDisqualifiedDirector =this.tDisqualifiedDirector +1;
  //       rDisqualifiedDirector.push(entry2); 
  //     }else if(entry3.name =='Insolvent')
  //     {
  //       // this.tInsolvency =this.tInsolvency +1;
  //       rInsolvency.push(entry2); 
  //     }
      
  //       // console.log(entry3.name); 
  //   });
  // });


  this.AdverseMedia = rAdverseMedia;
  this.PreviousSanctions =rPreviousSanctions;
  this.PEPs = rPEPs;
  this.LawEnforcement = rLawEnforcement;
  this.CurrentSanctions = rCurrentSanctions;
  this.FinancialRegulator = rFinancialRegulator;
  this.DisqualifiedDirector = rDisqualifiedDirector;
  this.Insolvency = rInsolvency;


}



getSafeUrl(url)
{
  return this.sanitizer.bypassSecurityTrustResourceUrl(url)
}

  opendocumentlink(id,data)
  {

    // console.log("data value", data);
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgvisasrc = true;
      this.iframevisasrc = data;
    }
    else {
      this.imgvisasrc = false;
      this.iframevisasrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    //   this.visa = new Visa();
    //   this.disableBtn = true;
    //   this.visaDetail = new Visa();
    //   this.visaDetail = data;
  
    //  // console.log(data);
    //   this.buttoninsert = "Update";
      this.modalService.open(id);
    }
    closeModal(id: string) 
    {
      
      
      this.modalService.close(id);
    }

    

norecordfound(){    
  this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';

  var dd = {};

  if(this.resourceId)
  {
    dd = {id: this.logid,atype:0,resourceId:this.resourceId}
  }
  else
  {
    dd = {id: this.logid,atype:this.atype}
  }


  this.amldetails.generatenorecordv3Pdf(dd).subscribe((pdfRes: any) => { 
    console.log(pdfRes.response_data.Location);
    this.url = pdfRes.response_data.Location;
    window.open(this.url,"_blank");
    this.downloadtext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
  });
}
}

