"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../_services/modal.service");
var common_service_1 = require("../../services/common.service");
var country_service_1 = require("../../services/country.service");
var company_service_1 = require("../../services/company.service");
var job_service_1 = require("../../services/job.service");
var forms_1 = require("@angular/forms");
var ClassicEditor = require("@ckeditor/ckeditor5-build-classic");
var platform_browser_1 = require("@angular/platform-browser");
var upload_file_service_1 = require("./../../services/upload-file.service");
var companylocation_model_1 = require("./../../models/location/companylocation.model");
var alert_service_1 = require("../../services/alert.service");
var router_1 = require("@angular/router");
var CompanylocationComponent = /** @class */ (function () {
    function CompanylocationComponent(sanitizer, commonService, modalService, job, formBuilder, country, company, router, uploadService, alerts) {
        this.sanitizer = sanitizer;
        this.commonService = commonService;
        this.modalService = modalService;
        this.job = job;
        this.formBuilder = formBuilder;
        this.country = country;
        this.company = company;
        this.router = router;
        this.uploadService = uploadService;
        this.alerts = alerts;
        this.locationDetail = new companylocation_model_1.Companylocation();
        this.Editor = ClassicEditor;
        this.options = [];
        this.submitted = false;
        this.defaultVal = "";
    }
    CompanylocationComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        // var ctype  = parseInt(localStorage.getItem('ctype'));
        // if(ctype ==1){
        //   this.router.navigate(['/check-dashboard']);
        // }
        this.Userid = localStorage.getItem('userid');
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.locationperm = this.permission.m10;
        this.locationDetail = new companylocation_model_1.Companylocation();
        this.getlocationformgroup();
        this.company.getcompanylocation().subscribe(function (locationlist) {
            _this.record = locationlist.length;
            _this.data = locationlist;
            _this.datalist = _this.data;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        var countrydata = { 'id': 70 };
        this.country.getstatelist(countrydata).subscribe(function (state) {
            _this.countylist = state;
        });
        this.company.getcompanylocationtype().subscribe(function (locationTypelist) {
            _this.companytype = locationTypelist;
        });
    };
    CompanylocationComponent.prototype.getlocationformgroup = function () {
        this.locationformGroup = this.formBuilder.group({
            id: [''],
            companylocationdetailsid: [''],
            companytypeid: ['', forms_1.Validators.required],
            address1: ['', forms_1.Validators.required],
            address2: [''],
            pincode: ['', forms_1.Validators.required],
            cityname: ['', forms_1.Validators.required],
            statename: ['', forms_1.Validators.required],
            countryid: ['', forms_1.Validators.required],
            companyname: ['']
        });
    };
    Object.defineProperty(CompanylocationComponent.prototype, "f", {
        get: function () { return this.locationformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    CompanylocationComponent.prototype.onSubmit = function (formlocation, id) {
        var _this = this;
        this.submitted = true;
        var pinc = formlocation.pincode.split(',');
        formlocation.pincode = pinc[pinc.length - 1];
        // console.log(this.locationformGroup);
        // var formlocation = companylocationformdata.value;
        if (this.locationformGroup.invalid) {
            return;
        }
        this.disableBtn = false;
        formlocation.userid = this.Userid;
        this.company.companylocationdetailsUpdate(formlocation).subscribe(function (response) {
            _this.company.getcompanylocation().subscribe(function (locationlist) {
                // companylocationformdata.reset();  
                _this.record = locationlist.length;
                _this.locationDetail.countryid = 70;
                _this.data = locationlist;
                _this.modalService.close(id);
            });
        });
    };
    CompanylocationComponent.prototype.onDelete = function (location) {
        var _this = this;
        if (location.isdefault) {
            this.alerts.error('You can not delete a default location.', true);
            return;
        }
        if (confirm("Are you sure you want to delete?")) {
            var data1 = { 'id': location.companylocationdetailsid, 'userid': this.Userid };
            this.company.companylocationDeletebyadmin(data1).subscribe(function (data) {
                _this.company.getcompanylocation().subscribe(function (locationlist) {
                    _this.alerts.success('Company location deleted successfully', true);
                    _this.data = locationlist;
                });
            });
        }
    };
    CompanylocationComponent.prototype.onChangeStatus = function (evt, location) {
        var _this = this;
        this.company.companylocationUpdatestatusbyadmin({ 'userid': this.Userid, 'id': location.companylocationdetailsid, 'isdefault': evt.target.checked }).subscribe(function (data) {
            _this.company.getcompanylocation().subscribe(function (locationlist) {
                _this.data = locationlist;
                _this.record = _this.data.length;
                if (_this.record < 30) {
                    _this.pageOfItems = _this.data;
                }
                else {
                    _this.items = _this.data;
                }
            });
        });
    };
    CompanylocationComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    CompanylocationComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    CompanylocationComponent.prototype.onSearchChange = function (searchValue) {
        var _this = this;
        if (searchValue.length > 4) {
            var newArray = [];
            this.commonService.getAddress(searchValue).subscribe(function (data) {
                var newdata;
                newdata = data;
                newdata.addresses.forEach(function (value) {
                    newArray.push(value + "," + searchValue);
                });
                _this.options = newArray;
            });
        }
        else {
            this.options = [];
        }
    };
    CompanylocationComponent.prototype.displayFn = function (value) {
        if (value != null) {
            var res = value.split(",");
            if (res.length > 6) {
                return res[7];
            }
            else {
                return value;
            }
        }
    };
    CompanylocationComponent.prototype.updatecode = function (evt, response) {
        res = [];
        if (evt.source.selected) {
            var res = response.split(",");
            this.locationDetail.address1 = res[0];
            this.locationDetail.address2 = res[1];
            this.locationDetail.cityname = res[5];
            this.locationDetail.pincode = res[7];
            this.locationDetail.statename = res[6];
            // console.log(this.locationDetail.pincode);
            // console.log(res[7]);
            // const data = { 'id': this.locationDetail.countryid };
            // this.country.getstatelist(data).subscribe((state: any) => {
            //   for (var i = 0; i < state.length; i++) {
            //     if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
            //       var inde = state[i]['stateid'];
            //     }
            //   }
            //   this.locationDetail.stateid = inde;
            //   this.countylist = state;
            // });
        }
    };
    CompanylocationComponent.prototype.transform = function (html) {
        return this.sanitizer.bypassSecurityTrustStyle(html);
    };
    CompanylocationComponent.prototype.selectFile = function (event, field) {
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        var file = this.selectedFiles;
        var imagepath = this.uploadService.uploadfile(file);
    };
    CompanylocationComponent.prototype.openModal = function (id, data) {
        var _this = this;
        this.submitted = false;
        this.buttoninsert = "Update";
        this.disableBtn = true;
        this.company.getcompanylocationtype().subscribe(function (locationTypelist) {
            _this.companytype = locationTypelist;
        });
        this.locationDetail = data;
        // this.locationDetail.countyid = data.stateid;
        this.modalService.open(id);
    };
    CompanylocationComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.locationDetail = new companylocation_model_1.Companylocation();
        // this.getlocationformgroup();
        this.locationDetail.companytypeid = "";
        this.locationDetail.countryid = 70;
        this.locationDetail.companylocationdetailsid = "0";
        this.modalService.open(id);
    };
    CompanylocationComponent.prototype.openModal3 = function (jobid, id) {
        var _this = this;
        this.job.getjobById({ "id": this.Userid, "jobid": jobid }).subscribe(function (data) {
            _this.locationDetail = data;
        });
        this.modalService.open(id);
    };
    CompanylocationComponent.prototype.openModal4 = function (jobid, id, screenumber) {
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.screenumber = screenumber;
        this.modalService.open(id);
    };
    CompanylocationComponent.prototype.deleteFile = function (jobid, id, documenturl) {
        var _this = this;
        var deleteData = {};
        if (documenturl == 'document1') {
            deleteData = { 'id': id, 'urldocument1': "" };
        }
        else if (documenturl == 'document2') {
            deleteData = { 'id': id, 'urldocument2': "" };
        }
        else if (documenturl == 'document3') {
            deleteData = { 'id': id, 'urldocument3': "" };
        }
        else if (documenturl == 'document4') {
            deleteData = { 'id': id, 'urldocument4': "" };
        }
        if (confirm("Are you sure you want to delete?")) {
            this.job.deleteFile(deleteData).subscribe(function (data) {
                _this.job.getjobById({ "id": _this.Userid, "jobid": jobid }).subscribe(function (data) {
                    _this.locationDetail = data;
                });
            });
        }
    };
    CompanylocationComponent.prototype.closeModal = function (id) {
        var _this = this;
        this.company.getcompanylocation().subscribe(function (locationlist) {
            _this.record = locationlist.length;
            _this.data = locationlist;
            _this.modalService.close(id);
        });
    };
    return CompanylocationComponent;
}());
exports.CompanylocationComponent = CompanylocationComponent;
