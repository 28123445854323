import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PlanService } from '../../services/plan.service';
import { PaymentService } from '../../services/payment.service';
import { Plan } from '../../models/plan.model';
import { ModalService } from '../../_services/modal.service';
import { Router } from '@angular/router';
import { StripeScriptTag } from "stripe-angular";
import { StripeToken } from "stripe-angular";
import { AlertService } from '../../services/alert.service';
import { UserService } from '../../services/user.service';
import { CompanyService } from '../../services/company.service';
import { AppGlobals } from '../../app.global';
import { ChecksystemService } from '../../services/checksystem.service';


declare var require: any
const FileSaver = require('file-saver');

declare let $: any;

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  private publishableKey: string =AppGlobals.API_STRIPE;

  @Input() items: Array<any>;
  
  buttoninsert: any;
  Userid: any;
  data: any;
  planDetail = new Plan();
  planformGroup: FormGroup;
  planlist: any;
  submitted: any;
  totaluser: any;
  cardlist: any;
  message: string;
  stptoken: any;
  planid: any;
  extraData: any;
  billinglist: any;
  companyData: any;
  currentprice: any;
  invoicelist: any;
  timestamp: any;
  record: number;
  pageOfItems: Array<any>;
  invoiceDetails: any;
  checkInvoiceDetails: any;
  downloadInvoiceData: any;
  downloadCheckInvoiceData: any;
  downloadinvoicetext: any;
  downloadcheckinvoicetext: any;
  isChecked = false;
  companyInfo: any;
  companyLocation: any;
  constructor(
    private formBuilder: FormBuilder,
    private check: ChecksystemService,
    private modalService: ModalService,
    private router: Router,
    private alerts: AlertService,
    private plan: PlanService,
    private userService: UserService,
    public companyService: CompanyService,
    public StripeScriptTag: StripeScriptTag,
    public paymentService: PaymentService
  ) { }

  ngOnInit() {
    this.downloadinvoicetext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
    this.downloadcheckinvoicetext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    // var ctype  = parseInt(localStorage.getItem('ctype'));
    // if(ctype ==1){
    //   this.router.navigate(['/check-dashboard']);
    // }

    this.StripeScriptTag.setPublishableKey(this.publishableKey);
   
    this.timestamp = function(epoch){
      return (epoch * 1000);
    };

    this.planformGroup = this.formBuilder.group({
      coupnsnumber: ['', Validators.required]
    });

    var isemail = localStorage.getItem('isemail');
    this.planid = localStorage.getItem('planid');


    this.companyService.getinvoicelist().subscribe((data: any) => {
      console.log(data);
      if(data.invoicelist.length){
        this.invoiceDetails = data.invoicelist[0];
      }
      this.companyLocation = data.companylocation;
      this.companyInfo = data.company;
      this.invoicelist = data.invoicelist;
    });

    this.companyService.gettotalcards().subscribe((data: any) => {       
      this.cardlist = data;
    });

    this.companyService.getnextbillingdate().subscribe((data: any) => {
      this.billinglist = data;
    });
    
    this.checkInvoices();

  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  checked(id){  
    this.isChecked = id;
  }
  checkInvoices()
  {
    this.check.getAllInvoice().subscribe((data: any) => { 
      this.record  = data.invoicelist.length;
      this.pageOfItems = data.invoicelist;
      if(data.invoicelist.length){
        this.checkInvoiceDetails = data.invoicelist[0];
      }
      if(this.record < 5){
        this.pageOfItems = data.invoicelist;
      } else{
        this.items =data.invoicelist;
      }
    });
  }

  getInvoiceData(invoiceid: any)
  {
    var data = { 'invoiceid': invoiceid };
    this.companyService.getinvoiceretrive(data).subscribe((invoiceData: any) => { 
      this.invoiceDetails = invoiceData;      
    });
  }

  downloadInvoice(invoiceid: any)
  {
    this.downloadinvoicetext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
    var data = { 'invoiceid': invoiceid };
    this.companyService.downloadInvoice(data).subscribe((invoiceData: any) => { 
      this.downloadInvoiceData = invoiceData; 
      this.downloadinvoicetext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
      window.open(this.downloadInvoiceData.response_data.Location, "_blank");
    });
  }

  downloadCheckInvoice(invoiceid: any)
  {
    this.downloadcheckinvoicetext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
    var data = { 'invoiceid': invoiceid };
    this.check.downloadCheckInvoice(data).subscribe((invoiceData: any) => { 
      this.downloadCheckInvoiceData = invoiceData;
      this.downloadcheckinvoicetext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
      window.open(this.downloadCheckInvoiceData.response_data.Location, "_blank");
    });
  }

  getCheckInvoiceData(invoiceid: any)
  {
    var data = { 'invoiceid': invoiceid };
    this.check.getAllInvoicedetails(data).subscribe((invoiceData: any) => { 
      this.checkInvoiceDetails = invoiceData;      
    });
  }

  downloadinvoice(invoiceid: any){
    var data = {'invoiceid':invoiceid}
    this.companyService.sendinvoicesend(data).subscribe((data: any) => {      
        // console.log(data);
    });
  }

}
