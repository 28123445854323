<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <!-- <a [routerLink]="['/request/request-list']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Request List</a> -->
            <div class="clearfix"></div>
            <div class="tabs__content">

                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="row row-sm topheader">                            
                            <div class="col-lg-5 text-right">
                           
                                <app-checkcountshow></app-checkcountshow>
                               
                            </div>
                            <div class="col-lg-7 text-right">
                              <div class="d-flex justify-content-end align-items-center">
                                <!-- <a class="boxbtn mainheading_btn ml-1"  (click)="uploadByAdmin('')" > + Add New Applicant </a>                                    -->
                                <button class="btn com_btn btn-success"[routerLink]="['/right-to-work-check']"  type="button">
                                    <span class="lnr lnr-history pr-1">                                        
                                    </span> Back to Right To Work Check
                                </button>
                              </div>                             
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-5 aligncenter mb-3">                            
                                <h5 class="hedingcolor">Right to Work check Report</h5>
                                <!-- <button class="btn com_btn btn-success" *ngIf="righttoworklogdetails" [innerHtml]="downloadtext" (click)="downloadReport();" type="button"></button> -->
                              <!-- <input type="text" [(ngModel)]="searchText" class="form-control width35" placeholder="Search"> -->
                          </div> 
                          <div class="col-sm-7 text-right mb-3">                            
                            
                            <button class="btn com_btn btn-success" *ngIf="righttoworklogdetails" [innerHtml]="downloadtext" (click)="downloadReport();" type="button"></button>
                          <!-- <input type="text" [(ngModel)]="searchText" class="form-control width35" placeholder="Search"> -->
                        </div>                              
                        </div>




                        <div class="bgwhite job_list_section idproof_request_section visa_request_section pb-2 pt-2 oddeven flexstart" *ngIf="righttoworklogdetails">                           
                            <div class="job_detils_date width25 mm">
                                <p class="job_address">Name</p>
                                <p class="job_profile" >{{ righttoworklogdetails.employee.fname }} {{ righttoworklogdetails.employee.mname }} {{ righttoworklogdetails.employee.lname }}</p>
                              
                            </div>
                            <div class="job_detils_date width25 mm">
                                <p class="job_address">Email Id</p>
                                <p class="job_profile" >{{ righttoworklogdetails.employee.emailid }}</p>
                              
                            </div>  
                            <div class="job_detils_date width25 mm">
                                <p class="job_address">Phone Number</p>
                                <p class="job_profile" >{{ righttoworklogdetails.employee.phonenumber }}</p>                              
                            </div>
                            <div class="job_detils_date width25 mm">
                                <p class="job_address">Right to Work</p>
                                <p class="job_profile" *ngIf="righttoworklogdetails.isrighttowork == 0">Normal Check</p>


                                <p class="job_profile"  *ngIf="righttoworklogdetails.isrighttowork == 1 && !shouldDisplayDate(righttoworklogdetails.createat)">Adjusted check</p>
                                    <p class="job_profile"  *ngIf="righttoworklogdetails.isrighttowork == 1 && shouldDisplayDate(righttoworklogdetails.createat) ">IDVT Check</p>

                                <!-- <p class="job_profile"  *ngIf="righttoworklogdetails.isrighttowork == 1">Covid-19 Check</p>  -->
                                
                                <p class="job_profile"  *ngIf="righttoworklogdetails.isrighttowork == 2">Share Code</p>                            
                            </div>
                            <div class="job_detils_date width25 mm">
                                <p class="job_address">Right to Work Type</p>
                                <p class="job_profile" *ngIf="righttoworklogdetails.isrighttoworktype == 0">Initial check</p>
                                <p class="job_profile"  *ngIf="righttoworklogdetails.isrighttoworktype == 1">Follow-up check</p>                               
                            </div>  
                            <div class="job_detils_date width25 mm">
                                <p class="job_address">Country</p>
                                <p class="job_profile" >{{righttoworklogdetails.nationality.countryname }}</p>                              
                            </div>
                            <div class="job_detils_date width25 mm" *ngIf="righttoworklogdetails.sharecode">
                                <p class="job_address">Share Code</p>
                                <p class="job_profile" >{{righttoworklogdetails.sharecode }}</p>                              
                            </div>
                            <div class="job_detils_date width25 mm" *ngIf="righttoworklogdetails.createat">
                                <p class="job_address">Check Date</p>
                                <p class="job_profile" >{{righttoworklogdetails.createat | date: 'dd MMM yyyy'  }}</p>                              
                            </div>

                            <div class="job_detils_date width25 mm" *ngIf="righttoworklogdetails.ninumber">
                                <p class="job_address">NI Number</p>
                                <p class="job_profile" >{{righttoworklogdetails.ninumber }}</p>                              
                            </div>


                            <div class="job_detils_date width25 mm" *ngIf="righttoworklogdetails.dateofbirth">
                                <p class="job_address">Date of Birth</p>
                                <p class="job_profile" >{{righttoworklogdetails.dateofbirth | date: 'dd MMM yyyy'  }}</p>                              
                            </div>
                            
                            <div class="job_detils_date width50 mm" *ngIf="righttoworklogdetails.companyname">
                                <p class="job_address">Company Name</p>
                                <p class="job_profile" >{{righttoworklogdetails.companyname }}</p>                              
                            </div>                          
                        </div>



                        <div class="row" *ngIf="isdecline">  
                            <div class="col-lg-12 col-md-12 col-sm-12" >
                              <div class="box_quest  border_left bgcolor">
                                  <p>Applicant declined the application.</p>                                
                              </div>
                          </div>
                        </div>


                        <div *ngIf="!isdecline">



                        <div class="row" *ngIf="!mobileapprecord">  
                            <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let vs of selectdocument">
                              <div class="box_quest  border_left bgcolor">
                                  <p>{{ vs.question }}</p>                                
                              </div>
                          </div>
                        </div>


                        <div *ngFor="let passport of passportpageOfItems ; let i=index ;">
                            <div class="job_list_section idproof_request_section visa_request_section pb-2 pt-2 oddeven"  >
                                <div class="job_detils_date width18">
                                    <div class="visa_user_details">
                                        <p class="job_address">Name</p>
                                        <h4 class="font15">{{passport.employee.fname}} {{passport.employee.mname}} {{passport.employee.lname}}</h4>                                 
                                    </div>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Phone Number</p>
                                    <p class="job_profile">{{ passport.employee.phonenumber }}</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">ID Proof Number</p>
                                    <p class="job_profile">{{passport.passportnumber}}</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">ID Type</p>
                                    <p class="job_profile">Passport</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Date of Issue</p>
                                    <p class="job_profile">{{passport.passportissue | date: 'dd MMM yyyy'}}</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Date of Expiry</p>
                                    <p class="job_profile">{{passport.passportexpiry | date: 'dd MMM yyyy'}}</p>
                                </div>
                              
                                <div class="job_detils_date aligncenter width15 verify_img">
                                    
                                    <div class="fshow">
                                        <div class="visa_user_pic back">
                                            <a (click)="openModal3('pdf-viewer-visa',passport.passportdocname)">  
                                            <img *ngIf="passport.passportdocname" src="{{passport.passportdocname}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}"></a>
                                            <img  *ngIf="!passport.passportdocname" src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                                        </div>
                                        <div class="tooltip_visa back"><p>Front image</p></div>
                                    </div>
                                    <div class="fshow">
                                        <div class="visa_user_pic selfie">
                                            <a (click)="openModal3('pdf-viewer-visa',passport.passportbackdocname)"> 
                                            <img *ngIf="passport.passportbackdocname" src="{{passport.passportbackdocname}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}"> </a>
                                            <img *ngIf="!passport.passportbackdocname" src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                                        </div>
                                        <div class="tooltip_visa selfie"><p>Back image</p></div>
                                    </div>
                                    <div class="fshow">
                                        <div class="visa_user_pic selfie">
                                            <a (click)="openModal3('pdf-viewer-visa',passport.passportprofileimage)">  
                                             <img *ngIf="passport.passportprofileimage" src="{{passport.passportprofileimage}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}"> </a>
                                            <img *ngIf="!passport.passportprofileimage"  src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                                        </div>
                                        <div class="tooltip_visa selfie"><p>Selfie</p></div>
                                    </div>
                                    </div>
    
                                <div class="width100 aligncenter">
                                    <div class="pull-left">                                 
                                        <p class="badge badge-warning" *ngIf="passport.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Pending by AI </p>
                                            <p class="badge badge-success" *ngIf="passport.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                            <p class="badge badge-error" *ngIf="passport.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                          
                                            <!-- <p class="badge badge-warning mb-0"><i class="fa fa-hourglass-end"></i> Awaiting Authorisation </p> -->
                                        <!-- <p class="badge badge-success mb-0" ><i class="fa fa-check"></i> Approved by AI </p>
                                        <p class="badge badge-error mb-0" ><i class="fa fa-close"></i> Rejected by AI </p> -->
                                    </div> 
                                </div>
                            </div>
                           
                            <!-- <div class="row">
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="passportitems" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div> -->
                         </div>



                         <div class="bgwhite job_list_section idproof_request_section visa_request_section pb-2 pt-2 oddeven noflex" *ngIf="mobileapprecord" style="border-left: 0px;"> 




                            <ng-container *ngIf="mrzdata">                             
                                <div class="d-flex justify-content-space flex-wrap" >                         
                                    <h4 class="mt-4  mb-0">MRZ Document Information</h4>
                                    <div class="job_detils_date width100 mm"  ><hr /></div>
                                <ng-container *ngFor="let passport of mrzdata ; let i=index ;"> 
                                    <ng-container *ngFor="let item of passport | keyvalue"> 
                                        <ng-container *ngIf="item.value && item.key != 'BA'  && item.key != 'AA' && item.key != 'BAC' && item.key != 'PA' && item.key != 'PACE' && item.key != 'TA' && item.key != 'facematch' && item.key != 'similar_score' && item.key != 'EF.SOD' && item.key != 'EF.COM' && item.key != 'EAC info (DG14)' && item.key != 'Machine Readable Zone (DG1)' && item.key != 'Biometry - Facial data (DG2)'">
                                            
                                            
                                            
                                            <div class="job_detils_date width25 mm">
                                                <p class="job_address">{{item.key}}</p>
                                                <p class="job_profile" >{{item.value}}</p> 
                                            </div>
    
                                        </ng-container> 
                                    </ng-container>
                                </ng-container>    
                            </div>                              
                        </ng-container>
    



                        <ng-container *ngIf="mobileapprecord.rfiddata">                             
                                <div class="d-flex justify-content-space flex-wrap" *ngIf="rfidlen > 5">                         
                                    <h4 class="mt-4  mb-0">RFID Document Information</h4>
                                <ng-container *ngFor="let passport of rfiddata ; let i=index ;"> 
                                    <ng-container *ngFor="let item of passport | keyvalue"> 
                                        <ng-container *ngIf="item.value && item.key != 'BA'  && item.key != 'AA' && item.key != 'BAC' && item.key != 'PA' && item.key != 'PACE' && item.key != 'TA' && item.key != 'facematch' && item.key != 'similar_score' && item.key != 'EF.SOD' && item.key != 'EF.COM' && item.key != 'EAC info (DG14)' && item.key != 'Machine Readable Zone (DG1)' && item.key != 'Biometry - Facial data (DG2)'">                                            
                                            <div class="job_detils_date width100 mm" *ngIf="item.key == 'Document class code'"><hr /></div>                                            
                                            <div class="job_detils_date width25 mm">
                                                <p class="job_address">{{item.key}}</p>
                                                <p class="job_profile" >{{item.value}}</p> 
                                            </div>
                                        </ng-container> 
                                    </ng-container>
                                </ng-container>    
                            </div>                              
                        </ng-container>



                     




                            <h4 class="mt-4 mb-4"  *ngIf="rfidlen > 5">RFID</h4>
                            <div class="d-flex justify-content-space flex-wrap"  *ngIf="mobileapprecord && rfidlen >5">



                            <ng-container  *ngIf="!mobileapprecord.pacedata">
                                <ng-container  *ngIf="mobileapprecord.rfiddata">
                                    <ng-container *ngFor="let passport of rfiddata ; let i=index ;"> 
                                        <ng-container *ngFor="let item of passport | keyvalue"> 
                                            <ng-container *ngIf="item.key == 'BA'  || item.key == 'AA'  || item.key == 'BAC'  ||  item.key == 'PA'  ||  item.key == 'PACE'  ||  item.key == 'TA'">
                                                <div class="job_detils_date width10 mm">
                                                <p class="job_address">{{item.key}}</p>
                                                <p class="job_profile" >
                                                    <i class="fa fa-minus gcirclesss" *ngIf="item.value"></i>
                                                    <i class="fa fa-minus graycirclesss" *ngIf="!item.value"></i>                                                   
                                                </p>
                                             </div>
                                            </ng-container> 
                                        </ng-container>
                                    </ng-container>                                    
                                </ng-container>                                
                            </ng-container>  


                            <ng-container  *ngIf="mobileapprecord.pacedata">
                                <ng-container  *ngIf="mobileapprecord.pacedata">
                                    <ng-container *ngFor="let passport of pacedata ; let i=index ;"> 
                                        <ng-container *ngFor="let item of passport | keyvalue"> 
                                            <ng-container *ngIf="item.key == 'BA'  || item.key == 'AA'  || item.key == 'BAC'  ||  item.key == 'PA'  ||  item.key == 'PACE'  ||  item.key == 'TA'">
                                                <div class="job_detils_date width10 mm">
                                                <p class="job_address">{{item.key}}</p>
                                                <p class="job_profile" >
                                                    <i class="fa fa-minus gcirclesss" *ngIf="item.value"></i>
                                                    <i class="fa fa-minus graycirclesss" *ngIf="!item.value"></i>                                                   
                                                </p>
                                             </div>
                                            </ng-container> 
                                        </ng-container>
                                    </ng-container>                                    
                                </ng-container>                                
                            </ng-container>  


                                <!-- <div class="job_detils_date width25 mm">
                                    <p class="job_address">PACE</p>
                                    <p class="job_profile"> 
                                        <i class="fa fa-minus gcirclesss"></i>
                                    </p>
                                </div>  
                                <div class="job_detils_date width25 mm">
                                    <p class="job_address">CA</p>
                                    <p class="job_profile"> 
                                        <i class="fa fa-minus gcirclesss"></i>
                                    </p>
                                </div>  
                                <div class="job_detils_date width25 mm">
                                    <p class="job_address">TA</p>
                                    <p class="job_profile"> 
                                        <i class="fa fa-minus graycirclesss"></i>
                                    </p>
                                </div>  
                                <div class="job_detils_date width25 mm">
                                    <p class="job_address">AA</p>
                                    <p class="job_profile"> 
                                        <i class="fa fa-minus graycirclesss"></i>
                                    </p>
                                </div>  
                                <div class="job_detils_date width25 mm">
                                    <p class="job_address">PA</p>
                                    <p class="job_profile" > 
                                        <i class="fa fa-minus graycirclesss"></i>
                                    </p>
                                </div>   -->
                            </div>  
                            <h4 class="mt-4 mb-4" *ngIf="rfidlen > 5">Data Group</h4>
                            <div class="d-flex justify-content-space flex-wrap"  *ngIf="mobileapprecord && rfidlen >5">


                            <ng-container  *ngIf="!mobileapprecord.pacedata">
                                <ng-container  *ngIf="mobileapprecord.rfiddata">
                                    <ng-container *ngFor="let passport of rfiddata ; let i=index ;"> 
                                        <ng-container *ngFor="let item of passport | keyvalue"> 
                                            <ng-container *ngIf="item.key == 'EF.SOD'  ||  item.key == 'EF.COM'  ||  item.key == 'EAC info (DG14)'  ||  item.key == 'Machine Readable Zone (DG1)'  ||  item.key == 'Biometry - Facial data (DG2)'">
                                                <div class="job_detils_date width25 mm">
                                                <p class="job_address">{{item.key}}</p>
                                                <p class="job_profile" *ngIf="item.value">{{item.value}}</p> 
                                                <p class="job_profile"  *ngIf="!item.value"><i>NA</i></p> 
                                            </div>
                                            </ng-container> 
                                        </ng-container>
                                    </ng-container>                                    
                                </ng-container>
                            </ng-container>


                                <ng-container  *ngIf="mobileapprecord.pacedata">
                                    <ng-container *ngFor="let passport of pacedata ; let i=index ;"> 
                                        <ng-container *ngFor="let item of passport | keyvalue"> 
                                            <ng-container *ngIf="item.key == 'EF.SOD'  ||  item.key == 'EF.COM'  ||  item.key == 'EAC info (DG14)'  ||  item.key == 'Machine Readable Zone (DG1)'  ||  item.key == 'Biometry - Facial data (DG2)'">
                                                <div class="job_detils_date width25 mm">
                                                <p class="job_address">{{item.key}}</p>
                                                <p class="job_profile" *ngIf="item.value">{{item.value}}</p> 
                                                <p class="job_profile"  *ngIf="!item.value"><i>NA</i></p> 
                                            </div>
                                            </ng-container> 
                                        </ng-container>
                                    </ng-container>                                    
                                </ng-container>

                                <!-- <div class="job_detils_date width25 mm">
                                    <p class="job_address">EF.COM</p>
                                    <p class="job_profile" >23</p>
                                </div>
                                <div class="job_detils_date width25 mm">
                                    <p class="job_address">EAC Info (DG14)</p>
                                    <p class="job_profile" >371</p>
                                </div>  
                                <div class="job_detils_date width25 mm">
                                    <p class="job_address">Machine Readable Zone (DG1)</p>
                                    <p class="job_profile" >93</p>                              
                                </div>
                                <div class="job_detils_date width25 mm">
                                    <p class="job_address">Biometry - Facial data (DG2)</p>
                                    <p class="job_profile">18320</p>
                                </div> -->
                               
                            </div> 
                            <h4 class="mt-4 mb-4" *ngIf="rfidlen > 5">Biometric verification</h4>
                            <div class="d-flex justify-content-space flex-wrap"  *ngIf="mobileapprecord && rfidlen >5">

                                <ng-container  *ngIf="mobileapprecord.rfiddata">
                                    <ng-container *ngFor="let passport of rfiddata ; let i=index ;"> 
                                        <ng-container *ngFor="let item of passport | keyvalue"> 
                                            <ng-container *ngIf="item.key == 'facematch'  ||  item.key == 'similar_score'">
                                                <div class="job_detils_date width25 mm">
                                                <p class="job_address">{{item.key}}</p>
                                                <p class="job_profile" *ngIf="item.key != 'facematch'">{{item.value}}</p>
                                                <p class="job_profile"  *ngIf="item.key == 'facematch'"> 
                                                  
                                                   <ng-container *ngIf="item.value==0">
                                                    <i   class="fa fa-close rcirclesss"></i>
                                                   </ng-container> 

                                                   <ng-container *ngIf="item.value ==1" >
                                                    
                                                    <i  class="fa fa-check gcirclesss"></i>
                                                   </ng-container> 

                                                   
                                                   
                                                
                                                
                                                </p>
                                            
                                            </div>
                                            </ng-container> 
                                        </ng-container>
                                    </ng-container>                                    
                                </ng-container>


                                <!-- <div class="job_detils_date width25 mm">
                                    <p class="job_address">Face Matching</p>
                                    <p class="job_profile  linebox">-</p>
                                </div> -->
                                <div class="job_detils_date width25 mm">
                                    <p class="job_address">Liveness Check</p>
                                    <p class="job_profile" > <i class="fa fa-check gcirclesss"></i></p>
                                </div>                                 
                            </div>  
                            <!-- <span class="optional">Optical</span> -->
                            <h4 class="mt-4 mb-4">Document Images</h4>
                            <div class="d-flex justify-content-space flex-wrap" *ngIf="mobileapprecord">

                                
                                <div class="job_detils_date width25 mm" *ngIf="mobileapprecord.documentimage">
                                    <div class="fshow">
                                        <div class="">
                                            <a (click)="openModal3('pdf-viewer-visa',mobileapprecord.documentimage)">  
                                            <img *ngIf="mobileapprecord.documentimage" src="{{mobileapprecord.documentimage}}" class="img width75" alt="" style="border: 1px solid #d1d1d1;border-radius: 8px 8px;overflow: hidden;"></a>
                                            <img *ngIf="!mobileapprecord.documentimage" src="../../../../../assets/img/icon/no_img.jpg" class="img width75">
                                        </div>
                                        <div class="width75 text-center mt-4 mb-4"><h4> Document Image</h4></div>
                                    </div>
                                </div>


                                <div class="job_detils_date width25 mm" *ngIf="mobileapprecord.selfiimage">
                                    <div class="fshow">
                                        <div class="">
                                            <a (click)="openModal3('pdf-viewer-visa',mobileapprecord.selfiimage)">  
                                            <img *ngIf="mobileapprecord.selfiimage" src="{{mobileapprecord.selfiimage}}" class="img width75" alt="" style="border: 1px solid #d1d1d1;border-radius: 8px 8px;overflow: hidden;"></a>
                                            <img *ngIf="!mobileapprecord.selfiimage" src="../../../../../assets/img/icon/no_img.jpg" class="img width75">
                                        </div>
                                        <div class="width75 text-center mt-4 mb-4"><h4>Applicant Selfi</h4></div>
                                    </div>
                                </div>


                                <div class="job_detils_date width25 mm" *ngIf="mobileapprecord.mrzimage">
                                    <div class="fshow">
                                        <div class="">
                                            <a (click)="openModal3('pdf-viewer-visa',mobileapprecord.mrzimage)">  
                                            <img *ngIf="mobileapprecord.mrzimage" src="{{mobileapprecord.mrzimage}}" class="img width75" alt="" style="border: 1px solid #d1d1d1;border-radius: 8px 8px;overflow: hidden;"></a>
                                            <img *ngIf="!mobileapprecord.mrzimage" src="../../../../../assets/img/icon/no_img.jpg" class="img width75">
                                        </div>
                                        <div class="width75 text-center mt-4 mb-4"><h4>MRZ Image</h4></div>
                                    </div>
                                </div>



                                <div class="job_detils_date width25 mm" *ngIf="mobileapprecord.rfidimage">
                                    <div class="fshow">
                                        <div class="">
                                            <a (click)="openModal3('pdf-viewer-visa',mobileapprecord.rfidimage)">  
                                            <img *ngIf="mobileapprecord.rfidimage" src="{{mobileapprecord.rfidimage}}" class="img width75" alt="" style="border: 1px solid #d1d1d1;border-radius: 8px 8px;overflow: hidden;"></a>
                                            <img *ngIf="!mobileapprecord.rfidimage" src="../../../../../assets/img/icon/no_img.jpg" class="img width75">
                                        </div>
                                        <div class="width75 text-center mt-4 mb-4"><h4>RFID Image</h4></div>
                                    </div>
                                </div>






                                <!-- <div class="job_detils_date width25 mm">
                                    <p class="job_address">Text Fields</p>
                                    <p class="job_profile" >  <i class="fa fa-check gcirclesss"></i></p>
                                </div> 
                                <div class="job_detils_date width25 mm">
                                    <p class="job_address">Image Quality</p>
                                    <p class="job_profile" >  <i class="fa fa-close rcirclesss"></i></p>
                                </div>
                                <div class="job_detils_date width25 mm">
                                    <p class="job_address">Authenticity</p>
                                    <p class="job_profile linebox" >-</p>
                                </div> 
                                <div class="job_detils_date width25 mm">
                                    <p class="job_address">Visible Digital Seal</p>
                                    <p class="job_profile linebox" >-</p>
                                </div>   
                                <div class="job_detils_date width25 mm">
                                    <p class="job_address">Basic Access Control</p>
                                    <p class="job_profile linebox" >-</p>
                                </div>                                  -->
                            </div> 
                        </div>
                        <!-- <div *ngIf="wizard == 1"> -->
                        <div *ngFor="let visas of visapageOfItems; let i=index ;"  >
                            <div class="job_list_section idproof_request_section visa_request_section pb-2 pt-2 oddeven">
                                <div class="job_detils_date width18">
                                    <div class="visa_user_details">
                                        <p class="job_address">Name</p>
                                        <h4 class="font15">{{visas.employee.fname}} {{visas.employee.mname}} {{visas.employee.lname}}</h4>                                 
                                    </div>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Phone Number</p>
                                    <p class="job_profile">{{ visas.employee.phonenumber }}</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">ID Proof Number</p>
                                    <p class="job_profile">{{visas.visanumber}}</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">ID Type</p>
                                    <p class="job_profile">BRP</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Date of Issue</p>
                                    <p class="job_profile">{{visas.visastart | date: 'dd MMM yyyy'}}</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Valid Until</p>
                                    <p class="job_profile">{{visas.visaexpiry | date: 'dd MMM yyyy'}}</p>
                                </div>
                                
                                <div class="job_detils_date aligncenter width15 verify_img">
                                    <div class="fshow">
                                        <div class="visa_user_pic back">
                                            <a (click)="openModal3('pdf-viewer-visa',visas.visadocname)">  
                                            <img *ngIf="visas.visadocname" src="{{visas.visadocname}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}"></a>
                                            <img *ngIf="!visas.visadocname" src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                                        </div>
                                        <div class="tooltip_visa back"><p>Front image</p></div>
                                    </div>
                                    <div class="fshow">
                                        <div class="visa_user_pic selfie">
                                            <a (click)="openModal3('pdf-viewer-visa',visas.visabackdocname)">   
                                            <img *ngIf="visas.visabackdocname" src="{{visas.visabackdocname}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}"></a>
                                            <img *ngIf="!visas.visabackdocname" src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                                        </div>
                                        <div class="tooltip_visa selfie"><p>Back image</p></div>
                                    </div>
                                    <div class="fshow">
                                        <div class="visa_user_pic selfie">
                                            <a (click)="openModal3('pdf-viewer-visa',visas.visaprofileimage)">  
                                            <img *ngIf="visas.visaprofileimage" src="{{visas.visaprofileimage}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}"></a>
                                            <img *ngIf="!visas.visaprofileimage" src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                                        </div>
                                        <div class="tooltip_visa selfie"><p>Selfie</p></div>
                                    </div>
                                </div>

                                <div class="width100 aligncenter">
                                    <div class="pull-left">     
                                        <p class="badge badge-warning" *ngIf="visas.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Pending by AI </p>
                                        <p class="badge badge-success" *ngIf="visas.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                        <p class="badge badge-error" *ngIf="visas.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                      
                                    </div> 
                                </div>
                            </div>
                           
                        
                         
                      </div> 

                    

                     <div  *ngFor="let idproofs of docpageOfItems; let i=index ;" >
                        <div class="job_list_section idproof_request_section visa_request_section pb-2 pt-2 oddeven">
                            <div class="job_detils_date width18">
                                <div class="visa_user_details">
                                    <p class="job_address">Name</p>
                                    <h4 class="font15">{{idproofs.employee.fname}} {{idproofs.employee.mname}} {{idproofs.employee.lname}}</h4>                                 
                                </div>
                            </div>
                            <div class="job_detils_date width10">
                                <p class="job_address">Phone Number</p>
                                <p class="job_profile">{{ idproofs.employee.phonenumber }}</p>
                            </div>
                            <div class="job_detils_date width10">
                                <p class="job_address">ID Proof Number</p>
                                <p class="job_profile">{{idproofs.idnumber}}</p>
                            </div>
                            <div class="job_detils_date width22">
                                <p class="job_address">ID Type</p>
                                <p class="job_profile">{{idproofs.documenttype.documentname}}   </p>
                            </div>
                            <div class="job_detils_date width10">
                                <p class="job_address">Expiry Date</p>
                                <p class="job_profile">{{idproofs.docexpiry | date: 'dd MMM yyyy'}}</p>
                            </div>
                            <!-- <div class="job_detils_date width10">
                                <p class="job_address">Check Date</p>
                                <p class="job_profile">{{idproofs.createat | date: 'dd MMM yyyy'}}</p>
                            </div> -->
                            <div class="job_detils_date aligncenter width15 verify_img">
                                
                                <div class="fshow">
                                    <div class="visa_user_pic back">
                                        <a (click)="openModal3('pdf-viewer-visa',idproofs.iddocName)">  
                                        <img *ngIf="idproofs.iddocName" src="{{idproofs.iddocName}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}"></a>
                                        <img *ngIf="!idproofs.iddocName"  src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                                    </div>
                                    <div class="tooltip_visa back"><p>Front image</p></div>
                                </div>
                                <div class="fshow">
                                    <div class="visa_user_pic selfie">
                                        <a (click)="openModal3('pdf-viewer-visa',idproofs.idbackdocName)">  
                                        <img *ngIf="idproofs.idbackdocName" src="{{idproofs.idbackdocName}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}"></a>
                                        <img  *ngIf="!idproofs.idbackdocName" src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                                    </div>
                                    <div class="tooltip_visa selfie"><p>Back image</p></div>
                                </div>
                                <div class="fshow">
                                    <div class="visa_user_pic selfie">
                                        <a (click)="openModal3('pdf-viewer-visa',idproofs.idprofileimage)">
                                        <img *ngIf="idproofs.idprofileimage" src="{{idproofs.idprofileimage}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}"></a>
                                        <img  *ngIf="!idproofs.idprofileimage" src="../../../../../assets/img/icon/no_img.jpg" class="img width100">
                                    </div>
                                    <div class="tooltip_visa selfie"><p>Selfie</p></div>
                                </div>
                                </div>

                            <div class="width100 aligncenter">
                                <div class="pull-left">              
                                    <p class="badge badge-warning" *ngIf="idproofs.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Pending by AI </p>
                                    <p class="badge badge-success" *ngIf="idproofs.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                    <p class="badge badge-error" *ngIf="idproofs.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                                     
                                    <!-- <p class="badge badge-warning mb-0"><i class="fa fa-hourglass-end"></i> Awaiting Authorisation </p> -->
                                    <!-- <p class="badge badge-success mb-0" ><i class="fa fa-check"></i> Approved by AI </p>
                                    <p class="badge badge-error mb-0" ><i class="fa fa-close"></i> Rejected by AI </p> -->
                                </div> 
                            </div>
                        </div>
                       
                        <!-- <div class="row">
                            <div class="col-lg-12 text-right">
                                <jw-pagination [items]="docitems" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                            </div>
                        </div> -->
                     
                   </div>

<!-- 
                        <div class="job_list_section idproof_request_section visa_request_section pb-2 pt-2 oddeven" *ngIf="righttoworklogdetails">                           
                            <div class="job_detils_date width20">
                                <p class="job_address">Right to Work</p>
                                <p class="job_profile" *ngIf="righttoworklogdetails.isrighttowork == 0">Normal Check</p>
                                <p class="job_profile"  *ngIf="righttoworklogdetails.isrighttowork == 1">Covid-19 Check</p>                            
                            </div>
                            <div class="job_detils_date width20">
                                <p class="job_address">Right to Work Type</p>
                                <p class="job_profile" *ngIf="righttoworklogdetails.isrighttoworktype == 0">Initial check</p>
                                <p class="job_profile"  *ngIf="righttoworklogdetails.isrighttoworktype == 1">Follow-up check</p>                               
                            </div>  
                            <div class="job_detils_date width20">
                                <p class="job_address">Country</p>
                                <p class="job_profile" >{{righttoworklogdetails.nationality.countryname }}</p>                              
                            </div>
                            <div class="job_detils_date width20" *ngIf="righttoworklogdetails.sharecode">
                                <p class="job_address">Share Code</p>
                                <p class="job_profile" >{{righttoworklogdetails.sharecode }}</p>                              
                            </div>
                            <div class="job_detils_date width20" *ngIf="righttoworklogdetails.createat">
                                <p class="job_address">Check Date</p>
                                <p class="job_profile" >{{righttoworklogdetails.createat | date: 'dd MMM yyyy'  }}</p>                              
                            </div>
                        </div> -->

                     



                   <div class="row" *ngIf="!mobileapprecord">  
                    <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let vs of questionlist1">
                      <div class="box_quest  border_left bgcolor">
                          <p>{{ vs.question }}</p>
                          <div class="radio_box">


                             <ng-container *ngFor="let vs2 of questionanslist;let i=index"> 

                              
                                <b class="green" style="background-color:transparent" *ngIf="vs2[vs.id] ===1" >Yes </b>
                                <b class="red" *ngIf="vs2[vs.id] ===2" >No </b>
                                <b class="green" *ngIf="vs2[vs.id] ===3"  style="background-color:transparent">NA </b>
                             </ng-container>    
                                              
                                

                          </div>
                      </div>
                  </div>
                </div>



                <div class="row" *ngIf="righttoworklogdetails">  
                    <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="righttoworklogdetails.notes">
                      <div class="box_quest  border_left bgcolor">  
                          
                        <p class="job_address"><b>Notes & Observation</b></p>


                            {{ righttoworklogdetails.notes}}         
                      </div>
                  </div>
                </div>


                <div class="row" *ngIf="righttoworklogdetails">  
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="box_quest  border_left bgcolor float-right">                     

                        <a *ngIf="righttoworklogdetails.pdfreport" href="{{righttoworklogdetails.pdfreport}}" target="_blank">


                            <button class="btn com_btn btn-success"  type="button">
                                Download Report
                            </button>
             
                        </a>
                        <button class="btn com_btn btn-success" (click)="editNotes()" type="button">
                            Edit Notes
                        </button>
                        <!-- <iframe *ngIf="righttoworklogdetails.pdfreport" [src]="righttoworklogdetails.pdfreport" width="100%" height="600" frameborder="0"></iframe>        -->
                      </div>
                  </div>
                </div>
      





                   <div class="job_list_section visa_request_section" *ngIf="passportrecord == 0 && docrecord ==0 && visarecord == 0 &&  righttoworklogdetails.isrighttowork != 2 &&  !mobileapprecord">
                        <div class="job_detils_date">
                            <p class="job_profile">No Record Found</p>
                        </div>
                    </div>
                     
</div>

                      </div>
                        
                    </div>
                  </div>
                </div>
              </div>
            </div>



  </div>


  <jw-modal id="pdf-viewer-visa" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-viewer-visa');">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-12">

                            <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="600" frameborder="0"></iframe>
                            <img *ngIf="imgsrc == true" [src]="iframesrc" width="100%" />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</jw-modal>

<jw-modal id="add-notes-to-right-to-work" role="dialog" class="modal">
    <div role="document" class="formgroup">
        <div class="modal-content">  
          <form [formGroup]="candidatenoteFormGroup" #candidatenoteForm="ngForm" class="s12 white" (ngSubmit)="updatecandidatenote(candidatenoteForm.value)">
            
            <div class="modal-body">
                <div class="container">
                  <!-- <div class="info_mess"> -->
  
                    <div class="row">
                      <div class="form-group col-lg-12">
                          <label>Notes & Observation*</label>                   
                          <textarea type="text" maxlength="500" placeholder="Write your note here (Maximum 500 characters)" 
                              formControlName="notes" name="notes" id="notes" class="form-control" [ngClass]="{ 'is-invalid': submitted && j.notes.errors }" 
                              [ngModel]="candidatenotesdetails.notes">            
                              </textarea> 
                            <div *ngIf="submitted && j.notes.errors" class="invalid-feedback mb-2">
                              <div *ngIf="j.notes.errors.required">Please Enter Notes</div>
                            </div>    
                        </div>
                      </div>  
                      
                      <div class="row">  
                          <div class="form-group col-lg-12">
                            <label>Share Code (shared by employee)</label>
                            <input type="text" formControlName="sharecode" name="sharecode" id="sharecode" 
                              class="form-control" [ngClass]="{ 'is-invalid': submitted && j.sharecode.errors }" 
                              [ngModel]="candidatenotesdetails.sharecode" maxlength="16">                        
                          </div>                      
                      </div>
  
                      <div class="row">  
                        <div class="col-lg-12">
                          <label>Upload online Right to Work check report</label>
                          <div class="input-group mb-3" (click)="filepdfdoc.click()">
                            <div class="custom-file">
                              <input type="file" #filepdfdoc (change)="selectPdfFile($event)">
                              <label class="custom-file-label">{{docpdffilename}}</label>
                            </div>
                          </div>
                          <div class="invalid-feedback ng-star-inserted">
                            <!-- <div class="" *ngIf="filebackrequirederror">Back side image is required</div> -->
                            <div *ngIf="pdfbackfilesizeerror">File size must be less than 2 MB</div>
                            <div *ngIf="pdfbackfileformaterror">Please choose a valid file format (jpg, png, pdf)</div>
                          </div>
                        </div>                     
                      </div>
  
  
                      <!-- </div> -->
                </div>
            </div>
            <div class="modal-footer text-center">
              <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Save"    />      
              <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('add-notes-to-right-to-work')"   />         
            </div>
          </form>
        </div>
    </div>
  </jw-modal>