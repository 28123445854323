"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var KnowlagecenterComponent = /** @class */ (function () {
    function KnowlagecenterComponent() {
    }
    KnowlagecenterComponent.prototype.ngOnInit = function () {
    };
    return KnowlagecenterComponent;
}());
exports.KnowlagecenterComponent = KnowlagecenterComponent;
