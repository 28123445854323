<!-- <div *ngIf="paymentDone">
  Your profile is in under review. Please wait for the approval.
</div> -->
<!-- <div *ngIf="!paymentDone">
  <button class="btn com_btn btn-primary" (click)="paymentstripe('custom-payment-1')">Pay Now</button>
</div> -->



<jw-modal id="custom-payment-1" role="dialog" class="modal">
  <div role="document" class="formgroup">
    <div class="modal-content">
    
      <div *ngIf="invalidError" style="color:red">
        {{ invalidError.message }}
      </div>

      <div class="col-lg-12 col-md-12" >  
          <label class="text-center mb-2 textinfoqer">Please enter your credit card or debit card</label>
          <div class="boxpayddd">
              
              <stripe-card #stripeCard (catch)="onStripeError($event)" [(invalid)]="invalidError"
              (tokenChange)="setStripeToken($event, 'custom-payment-1')"></stripe-card>
          </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary com_btn" (click)="stripeCard.createToken(extraData)">Pay Now</button>
        <button type="button" class="btn btn-dark com_btn" (click)="closeModal('custom-payment-1');">Cancel</button>
      </div>
     
    </div>
  </div>
</jw-modal>