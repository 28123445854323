<div class="header">
    <div class="container">
        <h1 class="headprofile mt-0 mb-2" *ngIf="wizard3 != true && ctype !=2">Buy Checks</h1>
    </div>

    <div class="cart_box_btn cartbillingoption startend"  *ngIf="serviceArray.length > 0">
      <button type="submit" class="btn btn-success checoutbutton" (click)="viewcart()"  *ngIf="!isviewcart"><span _ngcontent-c5="">Checkout</span> <span class="lnr lnr-arrow-right"></span> <p>{{ serviceArray.length }}</p> </button>
       <!-- <button type="submit" class="btn btn-danger" (click)="viewcheck()"  *ngIf="isviewcart == true || wizard3 == true">Add Other Checks</button> -->
    </div>
    <!-- <div class="cart_box_btn"  *ngIf="wizard3 == true">

    </div> -->


</div>
<div class="admin_dashboard_show">
    <!-- <div class="flexpayss">
        <div class="cardmenu blockbox">
            <div class="card_box_lit">
                <div class="samebox">
                    <i class="lnr lnr lnr-apartment"></i>

                  </div>

            </div>
            <div class="cartbox_section">
                <h4>Basic DBS</h4>
                <p class="avial">Available Credit - <span class="crediteshow">12</span></p>

             </div>
             <div class="buy_btn">
                <button type="submit" class="com_btn btn btn-dark" (click)="onFilterChange(vs.id)">Add to Cart</button>
              </div>
        </div>
        <div class="cardmenu blockbox">
            <div class="card_box_lit">
                <div class="samebox">
                    <i class="lnr lnr lnr-apartment"></i>

                  </div>

            </div>
            <div class="cartbox_section">
                <h4>Standard DBS</h4>
                <p class="avial">Available Credit - <span class="crediteshow">12</span></p>

             </div>
             <div class="buy_btn">
                <button type="submit" class="com_btn btn btn-dark" (click)="onFilterChange(vs.id)">Add to Cart</button>
              </div>
        </div>
        <div class="cardmenu blockbox">
            <div class="card_box_lit">
                <div class="samebox">
                    <i class="lnr lnr-license"></i>
                  </div>

            </div>
            <div class="cartbox_section">
                <h4>Enhanced DBS</h4>
                <p class="avial">Available Credit - <span class="crediteshow">12</span></p>

             </div>
             <div class="buy_btn">
                <button type="submit" class="com_btn btn btn-dark" (click)="onFilterChange(vs.id)">Add to Cart</button>
              </div>
        </div>
        <div class="cardmenu blockbox">
            <div class="card_box_lit">
                <div class="samebox">
                    <i class="lnr lnr-frame-expand"></i>
                  </div>

            </div>
            <div class="cartbox_section">
                <h4>
                    Right To Work</h4>
                <p class="avial">Available Credit - <span class="crediteshow">12</span></p>

             </div>
             <div class="buy_btn">
                <button type="submit" class="com_btn btn btn-dark" (click)="onFilterChange(vs.id)">Add to Cart</button>
              </div>
        </div>
        <div class="cardmenu blockbox">
            <div class="card_box_lit">
                <div class="samebox">
                    <i class="lnr lnr-file-add"></i>
                  </div>

            </div>
            <div class="cartbox_section">
                <h4>DVLA Check</h4>
                <p class="avial">Available Credit - <span class="crediteshow">12</span></p>

             </div>
             <div class="buy_btn">
                <button type="submit" class="com_btn btn btn-dark" (click)="onFilterChange(vs.id)">Add to Cart</button>
              </div>
        </div>
        <div class="cardmenu blockbox">
            <div class="card_box_lit">
                <div class="samebox">
                    <i class="lnr lnr-location"></i>
                  </div>

            </div>
            <div class="cartbox_section">
                <h4>
                    Adverse Credit</h4>
                <p class="avial">Available Credit - <span class="crediteshow">12</span></p>

             </div>
             <div class="buy_btn">
                <button type="submit" class="com_btn btn btn-dark" (click)="onFilterChange(vs.id)">Add to Cart</button>
              </div>
        </div>
        <div class="cardmenu blockbox">
            <div class="card_box_lit">
                <div class="samebox">
                    <i class="lnr lnr-file-empty"></i>
                  </div>

            </div>
            <div class="cartbox_section">
                <h4>Identity Check</h4>
                <p class="avial">Available Credit - <span class="crediteshow">12</span></p>

             </div>
             <div class="buy_btn">
                <button type="submit" class="com_btn btn btn-dark" (click)="onFilterChange(vs.id)">Add to Cart</button>
              </div>
        </div>
        <div class="cardmenu blockbox">
            <div class="card_box_lit">
                <div class="samebox">
                    <i class="lnr lnr-license"></i>
                  </div>

            </div>
            <div class="cartbox_section">
                <h4>
                    AML PEPs & Sanction</h4>
                <p class="avial">Available Credit - <span class="crediteshow">12</span></p>

             </div>
             <div class="buy_btn">
                <button type="submit" class="com_btn btn btn-dark" (click)="onFilterChange(vs.id)">Add to Cart</button>
              </div>
        </div>
        <div class="cardmenu blockbox">
            <div class="card_box_lit">
                <div class="samebox">
                    <i class="lnr lnr-store"></i>
                  </div>

            </div>
            <div class="cartbox_section">
                <h4>Tax Defaulters</h4>
                <p class="avial">Available Credit - <span class="crediteshow">12</span></p>

             </div>
             <div class="buy_btn">
                <button type="submit" class="com_btn btn btn-dark" (click)="onFilterChange(vs.id)">Add to Cart</button>
              </div>
        </div>
    </div> -->

</div>

<div class="admin_dashboard_show checkbillingbox" *ngIf="isviewcart == false">

<div class="flexpayss">
    <ng-container  *ngFor="let vs of leaves">
        <!-- *ngIf="vs.id != 10" -->

    <div class="cardmenu blockbox"  [ngClass]="{'not-active': vs.id == 10}" >
        <div class="card_box_lit" (click)="onFilterChange(vs.id)">
            <div class="samebox">
                <i class="{{vs.displayicon}}"></i>

              </div>

        </div>
        <div class="ejke">
            <div class="cartbox_section">
                <h4>{{ vs.servicename }}</h4>
                <p class="avial">Available Credit - <span class="crediteshow">{{ vs.remiancheck }}</span></p>

             </div>
             <div class="buy_btn">
                <button type="submit" class="com_btn btn btn-dark" (click)="onFilterChange(vs.id)" *ngIf="vs.id !=10"><i class="mr-2 fa fa-shopping-basket" aria-hidden="true"></i>
                  Add to Cart</button>

                  <button type="submit" class="com_btn btn btn-dark"  *ngIf="vs.id ==10"><i class="mr-2 fa fa-close" aria-hidden="true"></i>
                    Out of Stock</button>
  

              </div>
        </div>

    </div>

</ng-container>


</div>

    <!-- <div class="cardmenu" >
      <div class="card_box_lit" *ngFor="let vs of leaves"  >
        <div class="samebox" (click)="onFilterChange(vs.id)">
          <i class="lnr lnr-file-empty option1"></i>
          <i class="lnr lnr-file-empty option2"></i>
          <div class="menu_content ffcolor">
              <h4>{{ vs.servicename }}</h4>
              <span class="count_rem_value">{{ vs.totalcheck }}</span>
          </div>

        </div>
        <div class="buy_btn">
            <button type="submit" class="com_btn btn btn-dark" (click)="onFilterChange(vs.id)">Add to Cart</button>
          </div>
      </div>
      </div>      -->
    </div>

<div class="tab pricing-tab bg-color checkpricing">
    <!-- <div class="wizardhee">
        <h4>Rapid Screening Trusted Organisations</h4>
    </div>
    <p class="text-center width80 mb-4 mt-2">The cost to check your applicants will vary depending on the number and
        type of checks required. Below are the costs for each type of check, and whether there are discounts available
        based on the volume of checks:</p> -->
    <!-- <div id="tab1" class="priceshow pt-3 pb-3 mb-3">

        <div class="pricing_box" *ngFor="let vs of pricecheck" routerLink="/{{ vs.weburl }}">
            <h3>{{ vs.servicename }}</h3>
            <span>{{ vs.serdescription1 }}</span>

            <div class="pshow">
                £{{ vs.prices }}
            </div>
            <div class="check_buy">
                <button type="submit" class="pricebtn">
                    Learn More
                </button>
                <button type="submit" class="pricebtn">
                    Add to Cart
                </button>
            </div>

            <p class="text-center mt-2">{{ vs.serdescription2 }}</p>
        </div>
    </div> -->
</div>



<div class="wizadlineshow mt-1" *ngIf="isviewcart == true">
    <!-- <nav class="wizards">
        <ul>
            <li id="wizard-header-1" [class.active]="wizard1 || wizard2 || wizard3">
                <a><span>1</span></a>
                <p>Item selection</p>
            </li>
            <li id="wizard-header-2" [class.active]="wizard2 || wizard3">
                <a><span>2</span></a>
                <p>Confirmation</p>
            </li>
            <li id="wizard-header-3" [class.active]="wizard3">
                <a><span>3</span></a>
                <p>Thank you</p>
            </li>

        </ul>
    </nav> -->

    <div id="wizard-1" [class.displayblock]="wizard1" [class.dispalynone]="!changetoggle" class="wizard_details">
        <div class="container">
            <div class="main-banner3">
                <div class="banner-form">
                    <div class="row">
                        <!-- <div class="col-sm-12">
                            <div class="details text-center">
                                <h3>Rapid Screening Option</h3>
                                <div class="bar"></div>
                            </div>
                        </div> -->
                        <div class="col-sm-12">

                            <div class="row">

                                <!-- <div class="col-lg-12 col-md-12">
                                    <p>Service(s) interested in?</p>
                                </div> -->
                                <!-- <div class="col-lg-12 col-md-12">

                                    <div *ngFor="let service of services" class="form-check form-check-inline">
                                        <input class="form-check-input" (change)="onFilterChange($event)"
                                            type="checkbox" id="inlineRadio{{service.id}}" value="{{service.id}}">
                                        <label class="form-check-label"
                                            for="inlineRadio{{service.id}}">{{service.servicename}}</label>
                                    </div>

                                </div> -->


<!--
                                <div class="col-sm-12">
                                    <hr>
                                </div> -->
                                <!-- <div class="cartbillingoption">
                                    <div class="shoping_section">
                                        <div class="topsection">
                                            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                                            <span>Continue Shopping</span>
                                        </div>
                                        <hr/>
                                        <div class="heading_cart">
                                            <div class="head">
                                                <h4>Shopping Cart</h4>
                                                <span class="subheading">You have 4 items in your cart</span>
                                            </div>

                                        </div>
                                        <div class="cart_data">
                                            <div class="cart">
                                                <div class="group">
                                                    <span class="sdbs alpha">SD</span>
                                                    <div class="cart_name">
                                                        <p>Oneplus 7T Pro</p>
                                                        <span class="subdata">256 GB, Red Color</span>
                                                    </div>
                                                </div>
                                                <div class="group1">
                                                    <div class="incrdecres">
                                                        <a href="#">+</a><input type="text" class="form-control" value="1"><a href="#">-</a>
                                                    </div>
                                                </div>
                                                <div class="group2">
                                                    <p class="price">£800</p>
                                                    <a href="#" class="delete"><i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="cart">
                                                <div class="group">
                                                    <span class="alpha edbs">ED</span>
                                                    <div class="cart_name">
                                                        <p>Oneplus 7T Pro</p>
                                                        <span class="subdata">256 GB, Red Color</span>
                                                    </div>
                                                </div>
                                                <div class="group1">
                                                    <div class="incrdecres">
                                                        <a href="#">+</a><input type="text" class="form-control" value="1"><a href="#">-</a>
                                                    </div>
                                                </div>
                                                <div class="group2">
                                                    <p class="price">£800</p>
                                                    <a href="#" class="delete"><i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="cart">
                                                <div class="group">
                                                    <span class="bdbs alpha">BS</span>
                                                    <div class="cart_name">
                                                        <p>Oneplus 7T Pro</p>
                                                        <span class="subdata">256 GB, Red Color</span>
                                                    </div>
                                                </div>
                                                <div class="group1">
                                                    <div class="incrdecres">
                                                        <a href="#">+</a><input type="text" class="form-control" value="1"><a href="#">-</a>
                                                    </div>
                                                </div>
                                                <div class="group2">
                                                    <p class="price">£800</p>
                                                    <a href="#" class="delete"><i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="cart">
                                                <div class="group">
                                                    <span class="rtw alpha">RT</span>
                                                    <div class="cart_name">
                                                        <p>Oneplus 7T Pro</p>
                                                        <span class="subdata">256 GB, Red Color</span>
                                                    </div>
                                                </div>
                                                <div class="group1">
                                                    <div class="incrdecres">
                                                        <a href="#">+</a><input type="text" class="form-control" value="1"><a href="#">-</a>
                                                    </div>
                                                </div>
                                                <div class="group2">
                                                    <p class="price">£800</p>
                                                    <a href="#" class="delete"><i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="cart">
                                                <div class="group">
                                                    <span class="dvla alpha">DV</span>
                                                    <div class="cart_name">
                                                        <p>Oneplus 7T Pro</p>
                                                        <span class="subdata">256 GB, Red Color</span>
                                                    </div>
                                                </div>
                                                <div class="group1">
                                                    <div class="incrdecres">
                                                        <a href="#">+</a><input type="text" class="form-control" value="1"><a href="#">-</a>
                                                    </div>
                                                </div>
                                                <div class="group2">
                                                    <p class="price">£800</p>
                                                    <a href="#" class="delete"><i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="cart">
                                                <div class="group">
                                                    <span class="advcre alpha">AD</span>
                                                    <div class="cart_name">
                                                        <p>Oneplus 7T Pro</p>
                                                        <span class="subdata">256 GB, Red Color</span>
                                                    </div>
                                                </div>
                                                <div class="group1">
                                                    <div class="incrdecres">
                                                        <a href="#">+</a><input type="text" class="form-control" value="1"><a href="#">-</a>
                                                    </div>
                                                </div>
                                                <div class="group2">
                                                    <p class="price">£800</p>
                                                    <a href="#" class="delete"><i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="billing_section">
                                        <div class="card_box ">
                                            <div class="card_list billingggg">
                                                <div  class="cards">
                                                    <img src="assets/img/mastercard.png" >
                                                    <div class="cardno">
                                                        <span class="cardname">xxxx xxxx xxxx 4444</span>
                                                        <span class="cardname_text">MasterCard - Expires 4 2044</span>
                                                    </div>
                                                    <div class="cvvinput">
                                                        <input type="text" class="form-control" placeholder="CVV">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h4>Card Details</h4>

                                        <div class="payment_form">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Name on Card</label>
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">

                                                      </div>
                                                </div>
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Card Number</label>
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">

                                                      </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">Expiration Date</label>
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">

                                                      </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">CVV</label>
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">

                                                      </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div class="subtotal_pay">
                                            <div class="pay_value">
                                                <p>Subtotal</p>
                                                <span>£800.25
                                                </span>
                                            </div>
                                            <div class="pay_value">
                                                <p>Shipping</p>
                                                <span>£80.25
                                                </span>
                                            </div>
                                            <div class="pay_value">
                                                <p>Total (tax incl.)</p>
                                                <span>£880.50
                                                </span>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-success btn-block checoutbutton">
                                            <p>£880.50</p>
                                            <span>CheckOut</span>
                                        </button>
                                    </div>
                                </div> -->
                                <div class="cart_option" *ngIf="gtotal != 0">
                                    <div class="row">
                                        <div class="topsection width100" >
                                          <div class="conbtn"  (click)="viewcheck()" *ngIf="ctype != 3 && ctype != 2">
                                            <i class="lnr lnr-arrow-left" aria-hidden="true"></i>
                                            <span>Continue Shopping</span>
                                          </div>
                                          <hr class="mb-2 mt-2"/>
                                        </div>
                                        
                                        <div class="cartbillingoption">
                                            <div class="shoping_section">

                                                <div class="heading_cart ">
                                                    <div class="head">
                                                        <h4>Shopping Cart</h4>
                                                        <span class="subheading">You have {{serviceObjectArray.length}} item{{(serviceObjectArray.length>1)?'s':''}} in your cart</span>
                                                    </div>
                                                    <div class="sortby">
                                                        <!-- <span class="sort">Sort by:</span> <a href="#">Price <i class="fa fa-angle-down" aria-hidden="true"></i>
                                                        </a> -->
                                                    </div>
                                                </div>
                                                <div class="cart_data">
                                                    <div class="cart" *ngFor="let serv of serviceObjectArray">
                                                        <div class="group">
                                                            <span class="sdbs alpha">{{(serv.servicename.split(" ")[0].charAt(0)).toUpperCase()}}{{((serv.servicename.split(" ").length>1)?serv.servicename.split(" ")[1].charAt(0).toUpperCase():'')}}</span>
                                                            <div class="cart_name">
                                                                <p>{{serv.servicename}}</p>
                                                                <span class="subdata" *ngIf="serv.servicefee">DBS Service Fees £{{serv.taxable_amount*serv.quantity | number : '1.2-2'}} (+20% VAT)</span>
                                                            </div>
                                                        </div>
                                                        <div class="group1">
                                                            <div class="incrdecres">
                                                                <button class="plushtext" (click)="inc_qty(serv.id)">+</button>
                                                                <input type="text" class="form-control" [(value)]="serv.quantity" maxlength="3" minlength="1" (keypress)="keyPress($event)" (input)="inputVal($event.target.value,serv.id)" [disabled]="false">
                                                                <button class="plushtext" [disabled]="serv.quantity < 2" (click)="dec_qty(serv.id)">-</button>
                                                            </div>
                                                        </div>
                                                        <div class="group2">
                                                            <p class="price">£{{serv.total | number : '1.2-2'}}</p>
                                                            <button  *ngIf="ctype != 3" class="plushtext" (click)="onDeleteFilter(serv.id)" class="delete"><i class="fa fa-trash-o" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="billing_section">
                                                <h4>Order Summary</h4>
                                                <hr/>
                                                <div class="subtotal_pay">
                                                    <div class="pay_value">
                                                        <p>Subtotal</p>
                                                        <span>£{{gtotal | number : '1.2-2'}}</span>
                                                    </div>
                                                    <div class="pay_value">
                                                        <p>Tax</p>
                                                        <span>£{{tax | number : '1.2-2'}}</span>
                                                    </div>
                                                    <div class="pay_value">
                                                        <p>Total (tax incl.)</p>
                                                        <span>£{{gtotal+tax | number : '1.2-2'}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <button type="submit" class="btn btn-success btn-block checoutbutton" [disabled]="(gtotal==0)?true:false" (click)="toggle('2')">
                                                    <span><i class="mr-1 fa fa-shopping-basket"></i> Checkout</span>
                                                    <p>£{{gtotal+tax | number : '1.2-2'}}</p>
                                                </button>
                                            </div>
                                        </div>

                                        <!-- <div class="col-sm-12 col-md-12 col-lg-8">

                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="cart_heading mobilehidden">
                                                        <div class="cart_details_show">

                                                            <div class="checks_calc_amount width40 text-left">
                                                                <span class="pricing">Product Name</span>
                                                            </div>
                                                            <div class="checks_calc_amount width20 text-center">
                                                                <span class="pricing">Price</span>
                                                            </div>
                                                            <div class="checks_calc_amount width25 text-center">
                                                                <span class="pricing">Quantity</span>
                                                            </div>
                                                            <div class="checks_calc_amount width20 text-center">
                                                                <span class="pricing">Total</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngFor="let serv of serviceObjectArray" class="col-sm-12">
                                                    <div class="cart_details_show">
                                                        <p class="mobileheading"><b>Product Name</b></p>
                                                        <div class="checks_name width40">
                                                            <button aria-label="Close" class="close_popup"
                                                            (click)="onDeleteFilter(serv.id)" name="close" type="button"><span
                                                                aria-hidden="true">×</span></button>
                                                                <div class="text_bill">
                                                                    <h4> {{serv.servicename}}</h4>
                                                                </div>

                                                        </div>
                                                        <p class="mobileheading"><b>Price</b></p>
                                                        <div class="checks_calc_amount mobilecenter width20">
                                                            <span class="pricing"> £{{serv.prices - serv.servicefee}} {{(serv.servicefee)?'':' (+20% VAT)'}}</span>
                                                        </div>
                                                        <p class="mobileheading"><b>Quantity</b></p>
                                                        <div class="value_increase minspluse width25">
                                                            <div class="incr_decr pusmin">
                                                                <button type="submit" [disabled]="serv.quantity < 2" class="decrebutton" (click)="dec_qty(serv.id)">-</button>
                                                                <input class="form-control" [(value)]="serv.quantity" maxlength="3" minlength="1" (keypress)="keyPress($event)" (input)="inputVal($event.target.value,serv.id)" type="text" [disabled]="false">
                                                                <button type="submit" class="incrbutton" (click)="inc_qty(serv.id)">+</button>
                                                            </div>
                                                        </div>
                                                        <p class="mobileheading"><b>Total</b></p>
                                                        <div class="checks_calc_amount checkplanner width20">
                                                            <span class="pricing">£{{serv.total - serv.servicefee*serv.quantity | number : '1.2-2'}}</span>
                                                        </div>

                                                    </div>
                                                    <div class="cart_details_show" *ngIf="serv.servicefee">
                                                        <div class="checks_name width35">
                                                            <div class="text_bill">
                                                                <h4> DBS Service Fees</h4>
                                                            </div>
                                                        </div>
                                                        <div class="checks_calc_amount checkplanner1 width25">
                                                            <span class="pricing">£{{serv.servicefee}}  (+20% VAT)</span>
                                                        </div>
                                                        <div class="value_increase width20">
                                                            <div class="incr_decr no-flex">
                                                                {{serv.quantity}}
                                                            </div>
                                                        </div>
                                                        <div class="checks_calc_amount checkplanner width20">
                                                            <span class="pricing">£{{serv.taxable_amount*serv.quantity | number : '1.2-2'}}</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-4 boxshadowbill">
                                            <h4>Order Summary</h4>
                                            <div class="total_amount justify-content-end">
                                                <div class="width100">
                                                    <div class="amount">
                                                        <span class="total_text">Subtotal:</span>
                                                        <span class="total_amount_show">£{{gtotal | number : '1.2-2'}}</span>
                                                    </div>
                                                    <div class="amount">
                                                        <span class="total_text">Tax: </span>
                                                        <span class="total_amount_show">£{{tax | number : '1.2-2'}}</span>
                                                    </div>
                                                    <hr>
                                                    <div class="amount">
                                                        <span class="total_text">Total amount: </span>
                                                        <span class="total_amount_show">£{{gtotal+tax | number : '1.2-2'}}</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="mt-4 ">
                                                <button type="submit" class="btn btn-primary com_btn width100"
                                                    [disabled]="(gtotal==0)?true:false" (click)="toggle('2')">Next</button>
                                            </div>
                                        </div> -->

                                    </div>



                                </div>

                                <div class="col-lg-12 col-md-12" *ngIf="gtotal != 0">
                                    <div class="next">
                                        <!-- <button type="submit" (click)="toggle2('2')" class="btn btn-dark mr-3">Previous</button> -->
                                        <!-- <button type="submit" class="btn btn-primary com_btn"
                                            [disabled]="(gtotal==0)?true:false" (click)="toggle('2')">Next</button> -->
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div id="wizard-2" [class.displayblock]="wizard2" [class.dispalynone]="!changetoggle" class="wizard_details ">
        <div class="container">
            <div class="main-banner3">

                <div class="banner-form">
                    <div class="row">
                        <div class="topsection width100" (click)="toggle2('1')">
                            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                            <span>Back To Cart</span>
                            <hr/>
                        </div>

                        <div class="cartbillingoption">
                            <div class="shoping_section">
                                <!-- <div class="topsection" (click)="toggle2('1')">
                                    <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                                    <span>Back To Cart</span>
                                </div> -->

                                <div class="heading_cart ">
                                    <div class="head">
                                        <h4>Payment Confirmation</h4>
                                        <span class="subheading">You have {{serviceObjectArray.length}} items in your cart</span>
                                    </div>
                                    <div class="sortby">
                                        <!-- <span class="sort">Sort by:</span> <a href="#">Price <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        </a> -->
                                    </div>
                                </div>
                                <div class="cart_data">
                                    <div class="cart" *ngFor="let serv of serviceObjectArray">
                                        <div class="group mobilewidthset">
                                            <span class="sdbs alpha">{{(serv.servicename.split(" ")[0].charAt(0)).toUpperCase()}}{{((serv.servicename.split(" ").length>1)?serv.servicename.split(" ")[1].charAt(0).toUpperCase():'')}}</span>
                                            <div class="cart_name">
                                                <p>{{serv.servicename}}</p>
                                                <span class="subdata" *ngIf="serv.servicefee">DBS Service Fees £{{serv.taxable_amount*serv.quantity | number : '1.2-2'}} (+20% VAT)</span>
                                            </div>
                                        </div>
                                        <div class="group1">
                                            <div class="incrdecres">
                                                {{serv.quantity}}
                                            </div>
                                        </div>
                                        <div class="group2">
                                            <p class="price">£{{serv.total | number : '1.2-2'}}</p>
                                            <!-- <button (click)="onDeleteFilter(serv.id)" class="delete"><i class="fa fa-trash-o" aria-hidden="true"></i>
                                            </button> -->
                                        </div>
                                    </div>
                                </div>
<ng-container *ngIf="cardlist">


                                <div class="heading_cart mt-3" *ngIf="cardlist.length!=0">
                                    <div class="head">
                                        <h4>Payment Methods</h4>
                                    </div>
                                </div>
                               
                                
</ng-container>

                                <div class="card_strip_option" *ngFor="let card of cardlist">
                                   <div class="cardnemame">
                                       <div class="raidisedd">
                                            <input type="radio" class="form-control" name="cardid" (click)="onCheckCard(card.id)" [checked]="defaultcard.id==card.id" value="card.id">
                                       </div>
                                       <div>
                                        <label>Card Number</label>
                                        <div class="bba">
                                            <h4>xxxx xxxx xxxx {{card.last4}} </h4>
                                            <img src="assets/img/visa.png" *ngIf="card.brand == 'Visa'">
                                            <img src="assets/img/mastercard.png" *ngIf="card.brand == 'MasterCard'">
                                            <img src="assets/img/mastercard.png" *ngIf="card.brand == 'American Express'">
                                        </div>

                                       </div>

                                   </div>
                                  <div class="cardusername">
                                    <label>Card Holder Name</label>
                                    <p>{{card.name}}</p>
                                  </div>
                                  <div class="cardfate">
                                      <label>Expiry Date</label>
                                      <p>{{card.exp_month}} / {{card.exp_year}}</p>
                                  </div>
                                  <!-- <div class="cardfatecvv">
                                    <label>Enter CVV</label>
                                    <input type="text" class="form-control" placeholder="CVV">
                                </div> -->

                               </div>

                                <!-- <div class="payment_ccopt">
                                    <div class="card_box " *ngFor="let card of cardlist">
                                        <div class="card_list billingggg">
                                            <div  class="cards">
                                                <img src="assets/img/visa.png" *ngIf="card.brand == 'Visa'">
                                                <img src="assets/img/mastercard.png" *ngIf="card.brand == 'MasterCard'">
                                                <img src="assets/img/mastercard.png" *ngIf="card.brand == 'American Express'">
                                                <div class="cardno">
                                                    <span class="cardname">xxxx xxxx xxxx {{card.last4}}</span>
                                                    <span class="cardname_text">{{card.brand}} - Expires {{card.exp_month}} {{card.exp_year}}</span>
                                                </div>
                                                <div class="cvvinput">
                                                    <input type="radio" name="cardid" (click)="onCheckCard(card.id)" [checked]="defaultcard.id==card.id" value="card.id">
                                                    <input type="text" class="form-control" placeholder="CVV">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="billing_section">
                                <div class="flexpayss_b">
                                    <h4>Order Summary</h4>
                                <button (click)="addNewCard('custom-payment-1')" class="btn btn-warning com_btn">+Add New Card</button>
                                </div>

                                <div class="card_box blokly"  *ngIf="defaultcard">
                                    <div class="card_list address">
                                        <div class="cards blokly">
                                            <h6 >Billing Information</h6>
                                            <p>{{defaultcard.name}}</p>
                                           <p>{{defaultcard.address_line1}}</p>
                                           <p *ngIf="defaultcard.address_line2">{{defaultcard.address_line2}}</p>
                                           <p >{{defaultcard.address_city}}, {{defaultcard.address_state}} - {{defaultcard.address_zip}}</p>

                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="card_box " *ngFor="let card of cardlist">
                                    <div class="card_list billingggg">
                                        <div  class="cards">
                                            <img src="assets/img/visa.png" *ngIf="card.brand == 'Visa'">
                                            <img src="assets/img/mastercard.png" *ngIf="card.brand == 'MasterCard'">
                                            <img src="assets/img/mastercard.png" *ngIf="card.brand == 'American Express'">
                                            <div class="cardno">
                                                <span class="cardname">xxxx xxxx xxxx {{card.last4}}</span>
                                                <span class="cardname_text">{{card.brand}} - Expires {{card.exp_month}} {{card.exp_year}}</span>
                                            </div>
                                            <div class="cvvinput">
                                                <input type="radio" name="cardid" (click)="onCheckCard(card.id)" [checked]="defaultcard.id==card.id" value="card.id">

                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <hr/>
                                <div class="subtotal_pay">
                                    <div class="pay_value">
                                        <p>Subtotal</p>
                                        <span>£{{gtotal | number : '1.2-2'}}</span>
                                    </div>
                                    <div class="pay_value">
                                        <p>Tax</p>
                                        <span>£{{tax | number : '1.2-2'}}</span>
                                    </div>
                                    <div class="pay_value">
                                        <p>Total (tax incl.)</p>
                                        <span>£{{gtotal+tax | number : '1.2-2'}}
                                        </span>
                                    </div>
                                </div>

                                <button type="submit" class="btn btn-success btn-block checoutbutton" [disabled]="(gtotal==0)?true:false" (click)="Confirmmeassage('custom-payment-22')">
                                    <span>Checkout</span>
                                    <p>£{{gtotal+tax | number : '1.2-2'}}</p>
                                </button>
                            </div>
                        </div>
                        <!-- <div class="col-sm-12">


                            <div class="cart_option">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-8">

                                        <div class="col-sm-12">
                                            <h3 class="mb-3">Payment Confirmation
                                            </h3>
                                            <div class="bar"></div>
                                            <div class="cart_heading mobilehidden">
                                                <div class="cart_details_show">

                                                    <div class="checks_calc_amount width40 text-left">
                                                        <span class="pricing">Product Name</span>
                                                    </div>
                                                    <div class="checks_calc_amount width20 text-center">
                                                        <span class="pricing">Quantity</span>
                                                    </div>

                                                    <div class="checks_calc_amount width25 text-center">
                                                        <span class="pricing">Price</span>
                                                    </div>
                                                    <div class="checks_calc_amount width20 text-center">
                                                        <span class="pricing">Total</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div *ngFor="let serv of serviceObjectArray" class="col-sm-12">
                                            <div class="cart_details_show">
                                                <p class="mobileheading"><b>Product Name</b></p>
                                                <div class="checks_name no-flex width40">
                                                    <h4> {{serv.servicename}}</h4>
                                                </div>
                                                <p class="mobileheading"><b>Quantity</b></p>
                                                <div class="value_increase width20">
                                                    <div class="incr_decr no-flex">
                                                        {{serv.quantity}}
                                                    </div>
                                                </div>
                                                <p class="mobileheading"><b>Price</b></p>
                                                <div class="checks_calc_amount width25">
                                                    <span class="pricing ">£{{serv.prices - serv.servicefee}} {{(serv.servicefee)?'':' (+20% VAT)'}}</span>
                                                </div>
                                                <p class="mobileheading"><b>Total</b></p>
                                                <div class="checks_calc_amount checkplanner1 width20">
                                                    <span class="pricing">£{{serv.total - serv.servicefee*serv.quantity | number : '1.2-2'}}</span>
                                                </div>
                                            </div>
                                            <div class="cart_details_show" *ngIf="serv.servicefee">
                                                <div class="checks_name width40">
                                                    <h4>DBS Service Fees</h4>
                                                </div>
                                                <div class="value_increase width20">
                                                    <div class="incr_decr no-flex" >
                                                        {{serv.quantity}}
                                                    </div>
                                                </div>
                                                <div class="checks_calc_amount width25">
                                                    <span class="pricing">£{{serv.servicefee}} (+20% VAT)</span>
                                                </div>
                                                <div class="checks_calc_amount checkplanner width20">
                                                    <span class="pricing">£{{serv.taxable_amount*serv.quantity | number : '1.2-2'}}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">

                                            <div class="col-sm-6">
                                                <div class="card_box"  *ngIf="defaultcard">
                                                    <div class="card_list" style="width: 100%; display: block;">
                                                        <div class="cards" style="width: 100%; display: block;">
                                                            <h6 style="font-weight: bold;">Billing Information</h6>
                                                           <p  style="margin-bottom: 0px;">{{defaultcard.address_line1}}</p>
                                                           <p  style="margin-bottom: 0px;" *ngIf="defaultcard.address_line2">{{defaultcard.address_line2}}</p>
                                                           <p  style="margin-bottom: 0px;">{{defaultcard.address_city}}, {{defaultcard.address_state}} - {{defaultcard.address_zip}}</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-6" *ngIf="cardlist">
                                                <div class="card_box"  *ngFor="let card of cardlist">
                                                    <div class="card_list">
                                                        <div class="cards">
                                                            <input type="radio" name="cardid" (click)="onCheckCard(card.id)" [checked]="defaultcard.id==card.id" value="card.id">
                                                            <img src="assets/img/visa.png" *ngIf="card.brand == 'Visa'">
                                                            <img src="assets/img/mastercard.png" *ngIf="card.brand == 'MasterCard'">
                                                            <img src="assets/img/mastercard.png" *ngIf="card.brand == 'American Express'">
                                                            <div class="cardno">
                                                                <span class="cardname">xxxx xxxx xxxx {{card.last4}}</span>
                                                                <span class="cardname_text">{{card.brand}} - Expires {{card.exp_month}} {{card.exp_year}}</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-4 boxshadowbill preview" >

                                        <div class="total_amount justify-content-end">
                                            <div class="width100">
                                                <div class="amount">
                                                    <span class="total_text">Subtotal:</span>
                                                    <span class="total_amount_show">£{{gtotal | number : '1.2-2'}}</span>
                                                </div>
                                                <div class="amount">
                                                    <span class="total_text">Tax: </span>
                                                    <span class="total_amount_show">£{{tax | number : '1.2-2' }}</span>
                                                </div>
                                                <hr>
                                                <div class="amount">
                                                    <span class="total_text">Total amount: </span>
                                                    <span class="total_amount_show">£{{gtotal+tax | number : '1.2-2'  }}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="paymen_btn mt-4 d-flex justify-content-between" *ngIf="gtotal != 0">
                                            <button type="submit" (click)="toggle2('1')"
                                        class="btn btn-dark com_btn mr-2 width45">Previous</button>
                                    <button type="submit" class="btn btn-primary com_btn width45" [disabled]="(gtotal==0)?true:false"
                                        (click)="Confirmmeassage('custom-payment-22')">Pay Now</button>
                                        </div>
                                    </div>
                                </div>



                            </div>



                        </div> -->
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div id="wizard-3" [class.displayblock]="wizard3" [class.dispalynone]="!changetoggle" class="wizard_details">
        <div class="container">
            <div class="main-banner">
                <div class="banner-form thankbgsss">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="tankushow">

                                <img alt="shape" src="../../../../../assets/img/thankcheck.png">
                                <div class="detailstext">
                                    <h4>Payment Successful!</h4>
                                    <p>Your order has been successfully processed. Please return to the checks you have purchased and start using them instantly.</p>
                                        <h1>Thank You!</h1>


                                        <a (click)="viewcheck()"  class="option_show">
                                            <span>Buy More Checks</span>
                                            <span class="lnr lnr-arrow-right ml-2"></span>
                                        </a>

                                        <!-- <button type="submit" class="btn btn-danger"  *ngIf="wizard3 == true"></button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="wizard-4" [class.displayblock]="wizard4" [class.dispalynone]="!changetoggle" class="wizard_details">
        <div class="container">
            <div class="main-banner">
                <div class="banner-form thankbgsss">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="tankushow">

                                <img alt="shape" src="../../../../../assets/img/thankfail.png">
                                <div class="detailstext">
                                    <h4>Payment Failed!</h4>
                                    <p>Your order has been fail processed. Please return to the checks you have purchased and start using them instantly.</p>
                                        <h1>Thank You!</h1>


                                        <a (click)="viewcheck()"  class="option_show">
                                            <span>Buy More Checks</span>
                                            <span class="lnr lnr-arrow-right ml-2"></span>
                                        </a>

                                        <!-- <button type="submit" class="btn btn-danger"  *ngIf="wizard3 == true"></button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="clearfix">&nbsp;</div>
    <div class="browser-screen-loading-content" *ngIf="paymentloading" >
        <div class="loading-dots dark-gray">
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
        </div>
        <p>Please do not refresh or close the browser while we process your order.</p>
    </div>
</div>





<jw-modal id="custom-payment-1" role="dialog" class="modal paycut">
    <div role="document" class="formgroup">
      <div class="modal-content">
        <div *ngIf="invalidError" class="errormessa">
          {{ invalidError.message }}
        </div>

        <form [formGroup]="addpopForm" #addresslistForm="ngForm" class="s12 padding453 white"  *ngIf="addresslistDetail">

            <div class="row">
                <div class="col-sm-12">
                    <h5 class="billing">Billing Information</h5>
                    <hr/>
                </div>

                <div class="col-sm-7">

                    <div class="row">
                        <div class="form-group mb-2 col-lg-6 llb">
                            <label class="mb-0">Name*</label>
                            <input type="text" formControlName="name" name="name" id="name"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && g.name.errors }" required
                                [ngModel]="addresslistDetail.name" maxlength="60">
                            <div *ngIf="submitted && g.name.errors" class="invalid-feedback">
                                <div *ngIf="g.name.errors.required">Please enter name</div>
                            </div>
                        </div>
                        <div class="form-group mb-2 col-lg-6 llb">
                            <label class="mb-0">Address Line 1*</label>
                            <input type="address1" formControlName="address1" name="address1" id="address1"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && g.address1.errors }" required
                                [ngModel]="addresslistDetail.address1" maxlength="60">
                            <div *ngIf="submitted && g.address1.errors" class="invalid-feedback">
                                <div *ngIf="g.address1.errors.required">Please enter address line 1</div>
                            </div>
                        </div>
                        <div class="form-group mb-2 col-lg-6 llb">
                            <label class="mb-0">Address Line 2 </label>
                            <input type="address2" formControlName="address2" name="address2" id="address2"
                                class="form-control" [ngModel]="addresslistDetail.address2" maxlength="60">
                        </div>
                        <div class="form-group mb-2 col-lg-6 llb">
                            <label class="mb-0">City*</label>
                            <input type="text" formControlName="cityname" name="cityname" class="form-control" id="cityname" maxlength="30"
                                [ngClass]="{ 'is-invalid': submitted && g.cityname.errors }" required [ngModel]="addresslistDetail.cityname">
                            <div *ngIf="submitted && g.cityname.errors" class="invalid-feedback">
                                <div *ngIf="g.cityname.errors.required">Please enter city</div>
                            </div>
                        </div>
                        <div class="form-group mb-2 col-lg-6 llb">
                            <label class="mb-0">County*</label>
                            <input type="text" formControlName="statename" name="statename" id="statename" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && g.statename.errors }" required
                                [ngModel]="addresslistDetail.statename" maxlength="30">
                            <div *ngIf="submitted && g.statename.errors" class="invalid-feedback">
                                <div *ngIf="g.statename.errors.required">Please enter county</div>
                            </div>
                        </div>

                        <div class="form-group mb-2 col-lg-6 llb">
                            <label class="mb-0">Postcode*</label>
                            <input type="text" formControlName="pincode" name="pincode" class="form-control" id="pincode" maxlength="10"
                                [ngClass]="{ 'is-invalid': submitted && g.pincode.errors }" required [ngModel]="addresslistDetail.pincode">
                            <div *ngIf="submitted && g.pincode.errors" class="invalid-feedback">
                                <div *ngIf="g.pincode.errors.required">Please enter postcode</div>
                            </div>
                        </div>
                        <div class="form-group mb-2 col-lg-6 llb">
                            <label class="mb-0">Country*</label>
                            <select style="width:100%;" formControlName="countryid" class="form-control input-lg" id="countryid" name="countryid" [ngClass]="{ 'is-invalid': submitted && g.countryid.errors }" [ngModel]="addresslistDetail.countryid">
                                <option value="">Select Country</option>
                                <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                            </select>
                            <div *ngIf="submitted && g.countryid.errors" class="invalid-feedback">
                                <div *ngIf="g.countryid.errors.required">Please select country</div>
                            </div>
                        </div>
                        <div class="form-group mb-2 col-lg-6 llb">
                            <label class="mb-0">Tax ID</label>
                            <input type="text" formControlName="taxid" name="taxid" class="form-control" id="taxid" maxlength="10"
                                 [ngModel]="addresslistDetail.taxid">
                        </div>
                    </div>
                </div>
                <div class="col-sm-5" >
                    <div class="payment_strip"  [style.display]="display">
                        <label>Please enter card details</label>
                        <stripe-card #stripeCard (catch)="onStripeError($event)" [(invalid)]="invalidError"
                        (tokenChange)="setStripeToken($event,addresslistForm.value, 'custom-payment-1')"></stripe-card>
                        </div>
                        <div class="optionss" [style.display]="display">
                            <img src="assets/img/paycard.png">
                        </div>
                        <!-- <div class="billing_info_section" *ngIf="editCard">
                            <div class="card_box">
                                <div class="card_list">
                                    <div class="cards">
                                        <img src="assets/img/visa.png" *ngIf="cardDetails.brand == 'Visa'">
                                        <img src="assets/img/mastercard.png" *ngIf="cardDetails.brand == 'MasterCard'">
                                        <img src="assets/img/mastercard.png" *ngIf="cardDetails.brand == 'American Express'">
                                        <div class="cardno">
                                            <span class="cardname">xxxx xxxx xxxx {{cardDetails.last4}}</span>
                                            <span class="cardname_text">{{cardDetails.brand}} - Expires {{cardDetails.exp_month}} {{cardDetails.exp_year}}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>       -->
                </div>
            </div>



        <div class="modal-footer">
          <!-- <button type="button" *ngIf="editCard" class="btn btn-primary com_btn" (click)="updateCard(addresslistForm.value, 'custom-payment-1')">Update</button> -->
          <button type="button" class="btn btn-primary com_btn" (click)="stripeCard.createToken(extraData)">Submit</button>
          <button type="button" class="btn btn-dark com_btn" (click)="closeModal('custom-payment-1');">Cancel</button>
        </div>
      </form>
      </div>
    </div>
  </jw-modal>

<!--


<jw-modal id="custom-payment-1" role="dialog" class="modal">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="popupLabel">Payment</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                    (click)="closeModal('custom-payment-1');">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div *ngIf="invalidError" style="color:red">
                {{ invalidError.message }}
            </div>


            <div class="col-lg-12 col-md-12">
                <label style="margin-top:30px; margin-left: 30px;">Please enter your credit card or debit card</label>
                <div style="margin: 30px auto; width:90%;border: 1px solid #272727;padding: 10px; margin-top:0px;">

                    <stripe-card #stripeCard (catch)="onStripeError($event)" [(invalid)]="invalidError"
                        (tokenChange)="setStripeToken($event, 'custom-payment-1')"></stripe-card>
                </div>

            </div>
            <button type="button" class="btn btn-primary mt-4" [disabled]="paynow" (click)="stripeCard.createToken(extraData)">Pay Now</button>
        </div>
    </div>
</jw-modal> -->





<jw-modal id="custom-payment-25" role="dialog" class="modal">
    <div role="document" class="formgroup" *ngIf="iframepaymentautsrc">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="container">
                  <div class="info_mess">
                      <div class="para">
                              <h4 class="mb-2">Please do not refresh or close the browser while we process your order.</h4>
                              <!-- <div class="card_strip_option">                                 -->
                                <iframe  [src]="iframepaymentautsrc" width="900" height="450" frameborder="0" style="width:900px"></iframe>
                            <!-- </div>                           -->
                          </div>
                      </div>
                </div>
            </div>          
        </div>
    </div>
</jw-modal>



<jw-modal id="custom-payment-22" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="container">
                  <div class="info_mess">
                      <div class="para">
                              <h4 class="mb-2">Payment Confirmation</h4>

                              <div class="card_strip_option"  >
                                <div class="cardnemame" *ngIf="defaultcard">
                                    <div>
                                     <label>Card Number</label>
                                     <div class="bba">
                                         <h4>xxxx xxxx xxxx {{defaultcard.last4}} </h4>
                                         <img src="assets/img/visa.png" *ngIf="defaultcard.brand == 'Visa'">
                                         <img src="assets/img/mastercard.png" *ngIf="defaultcard.brand == 'MasterCard'">
                                         <img src="assets/img/mastercard.png" *ngIf="defaultcard.brand == 'American Express'">
                                     </div>
                                    </div>
                                </div>

                               <!-- <div class="cardfate">
                                   <label>Card Expires Date</label>
                                   <p>{{defaultcard.exp_month}} / {{defaultcard.exp_year}}</p>
                               </div> -->
                                <div class="cardfatecvv">
                                    <label>Enter CVV</label>
                                    <input #cardcvv name="cvv" placeholder="CVV" class="cvv" (input)="inputcvv();">
                                </div>
                            </div>
                            <p *ngIf="cvvError" class="red font12 mb-0">Please enter the correct CVV</p>
                              <p class="mb-0 font20 carconfirm"> Please confirm to continue with the payment.</p>
                          </div>
                      </div>
                </div>
            </div>
            <div class="modal-footer text-center">
              <input type="button" class="btn btn-primary com_btn" name="updateform" [disabled]="paymentcheck" value="Confirm"  (click)="paymentstripe('custom-payment-1', 'custom-payment-22', cardcvv.value)"  />
              <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-payment-22');"/>
                <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
            </div>
        </div>
    </div>
</jw-modal>
