<app-header></app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs">
            <div class="tabs__content">
                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="space_em row">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Employee List </h5>
                            </div>
                            <div class="col-lg-4">
                                <div class="input-group">
                                    <input type="text" [(ngModel)]="searchText" class="form-control" placeholder="Search">
                                    <!-- <div class="input-group-append">
                                        <button class="btn btn-success" type="submit">Go</button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="employee_list_section">
                            <ng-container *ngIf="( data |  filter:{fname: searchText,mname: searchText,lname: searchText,emprole: searchText,emailid: searchText}) as employeelist">
                                <div class="employee_list" *ngFor="let employee of employeelist; let i=index ">
                                    <div class="employee_img">
                                        <img *ngIf="employee.imagename" src="{{employee.imagename}}" class="img" alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                        <img *ngIf="employee.gender == true && (employee.imagename == null || employee.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img" alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                        <img *ngIf="employee.gender == false && (employee.imagename == null || employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img" alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                    </div>
                                    <div class="emp_details">
                                        <p class="name_em">{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}</p>
                                        <p class="profile_em">{{ employee.emprole }}</p>
                                        <p class="email_em">{{ employee.emailid }}</p>
                                    </div>
                                    <div class="emp_button">

                                        <!-- <button class="btn btn-primary" type="submit" (click)="bindallcandidatedocument('custom-emp', employee.employeemasterid);">View Document</button> -->

                                        <button class="btn btn-danger" (click)="bindallcandidatedocument('custom-emp', employee.employeemasterid);">Upload Document</button>
                                    </div>
                                </div>
                                <div class="width100 job_list_section visa_request_section ng-star-inserted" *ngIf="employeelist.length === 0">
                                    <div class="job_detils_date">
                                        <p class="job_profile">No Record Found </p>
                                    </div>
                                </div>
                            </ng-container>
                           
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<jw-modal id="custom-emp" class="modal popupaligncenter" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="popupLabel">Document Details</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-emp');">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="clearfix"></div>
                    <div class="import_image_section">
                        <div class="import_file_img" *ngFor="let document of employeedocumentlist | filter: {fname: searchText,mname: searchText,lname: searchText,emailid: searchText,countryname: searchText };  let i=index">
                            <div class="import_img">
                                <i class="fa fa-file-image-o" aria-hidden="true"></i>
                            </div>
                            <div class="import_file_name">
                                <p>{{ document.documentname }}</p>
                            </div>
                            <div class="import_right">
                                <div class="check">
                                    <a (click)="opendocumnentname(document.employeedocumentdetailsid,document.employeeid)">
                                        <i class="fa fa-edit" aria-hidden="true"></i>
                                        <div class="tooltip_visa">
                                            <p>Edit</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="import_footer">
                                <div class="footer_imp_le">
                                    <!-- <p>File Size :<span>{{getImageDimenstion(document.documentimageurl)}}</span></p> -->
                                </div>
                                <div class="footer_imp_ri">
                                    <i class="fa fa-file-image-o" *ngIf="docperm > 0" (click)="openModal5('pdf-image-viewer1', document.documentimageurl)">
                                        <div class="tooltip_visa">
                                            <p>View image</p>
                                        </div>
                                    </i>
                                    <i class="fa fa-trash" *ngIf="docperm > 2" (click)="deleteFile(document.employeedocumentdetailsid)">
                                        <div class="tooltip_visa">
                                            <p>Delete</p>
                                        </div>
                                    </i>
                                    <a *ngIf="docperm > 0" href="{{ document.documentimageurl }}"><i class="fa fa-download">
                                        <div class="tooltip_visa">
                                            <p>Download</p>
                                        </div>
                                    </i>
                                </a>
                                </div>
                                <div class="clearfix"></div>

                            </div>
                        </div>

                        <div class="job_list_section visa_request_section width100" *ngIf="record ==0">
                            <div class="job_detils_date">
                                <p class="job_profile">No Record Found</p>
                            </div>
                        </div> 
                    </div>
                    <!-- <div class="row">
             

            <div class="form-group col-lg-3 text-center" *ngFor="let document of employeedocumentlist">
              <img class="rounded mr-2" src="assets/img/file.png" style="width:100%;">
              <p> {{ document.documentname }}</p>
              <p class="iconlist">
              <i class="fa fa-file-image-o" *ngIf="docperm > 0" (click)="openModal5('pdf-image-viewer1', document.documentimageurl)"></i>
                <i class="fa fa-trash" *ngIf="docperm > 2" (click)="deleteFile(document.employeedocumentdetailsid)"></i>
                <a  *ngIf="docperm > 0" href="{{ document.documentimageurl }}"><i class="fa fa-download"></i></a></p>
            </div>
          </div> -->

                </div>
            </div>
            <div class=" modal-footer">

                <div *ngIf="docperm > 1">
                    <button (click)="file.click()" class="btn btn-primary com_btn" type="submit">
                        <input type="file" #file (change)="selectFile($event)" style="display:none;" />
                        Choose file
                    </button>

                    <!-- <div (click)="file.click()">
                        <input type="file" #file (change)="selectFile($event)" style="display:none;" />
                        <label class="btn btn-primary com_btn ">
                      <span class="buttonText">Choose file</span>
                    </label>
                    </div> -->
                </div>
                <button class="btn btn-secondary com_btn" type="submit"  (click)="closeModal('custom-emp');">Cancel</button>
                <!-- <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-emp');" /> -->
            </div>
        </div>
    </div>
</jw-modal>


<jw-modal id="pdf-image-viewer1" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer1');">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="container"> 

                    <div class="row">
                        <div class="col-lg-12">

                            <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="500" frameborder="0"></iframe>
                            <img *ngIf="imgsrc == true" [src]="iframesrc" style="width:100%;" />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</jw-modal>




<jw-modal id="employee-doc-name-update" class="modal name_rename" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">

            <div class="modal-body">
                <div class="container">
                    <form [formGroup]="documentGroup" #documentForm="ngForm" class="s12 white" (ngSubmit)="onRenameDocumentSubmit(documentForm.value)" *ngIf="documentnamelist">


                        <div class="row row-sm mg-t-10">
                            <label>Document Name*</label>
                            <input type="hidden" id="employeedocumentdetailsid" name="employeedocumentdetailsid" formControlName="employeedocumentdetailsid" [(ngModel)]="documentnamelist.employeedocumentdetailsid" />
                            <input type="hidden" id="employeeid" name="employeeid" formControlName="employeeid" [(ngModel)]="documentnamelist.employeeid" />
                            <input type="text" formControlName="documentname" name="documentname" id="documentname" [ngClass]="{ 'is-invalid': submitted && f.documentname.errors }" class="form-control" [(ngModel)]="documentnamelist.documentname" maxlength="100">
                            <div *ngIf="submitted && f.documentname.errors" class="invalid-feedback">
                                <div *ngIf="f.documentname.errors.required">Please enter document name</div>
                            </div>
                        </div>
                        <div class="row row-sm float-right">
                            <button type="submit" class="btn btn-primary com_btn btn_space">Save</button>
                            <button type="button" class="btn btn-secondary com_btn" (click)="closeModal('employee-doc-name-update');">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>

<app-header></app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs">
            <div class="tabs__content">
                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="space_em row">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Employee List </h5>
                            </div>
                            <div class="col-lg-4">
                                <div class="input-group">
                                    <input type="text" [(ngModel)]="searchText" class="form-control" placeholder="Search">
                                    <!-- <div class="input-group-append">
                                        <button class="btn btn-success" type="submit">Go</button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="employee_list_section">
                            <ng-container *ngIf="( data |  filter:{fname: searchText,mname: searchText,lname: searchText,emprole: searchText,emailid: searchText}) as employeelist">
                                <div class="employee_list" *ngFor="let employee of employeelist; let i=index ">
                                    <div class="employee_img">
                                        <img *ngIf="employee.imagename" src="{{employee.imagename}}" class="img" alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                        <img *ngIf="employee.gender == true && (employee.imagename == null || employee.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img" alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                        <img *ngIf="employee.gender == false && (employee.imagename == null || employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img" alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                    </div>
                                    <div class="emp_details">
                                        <p class="name_em">{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}</p>
                                        <p class="profile_em">{{ employee.emprole }}</p>
                                        <p class="email_em">{{ employee.emailid }}</p>
                                    </div>
                                    <div class="emp_button">

                                        <!-- <button class="btn btn-primary" type="submit" (click)="bindallcandidatedocument('custom-emp', employee.employeemasterid);">View Document</button> -->

                                        <button class="btn btn-danger" (click)="bindallcandidatedocument('custom-emp', employee.employeemasterid);">Upload Document</button>
                                    </div>
                                </div>
                                <div class="width100 job_list_section visa_request_section ng-star-inserted" *ngIf="employeelist.length === 0">
                                    <div class="job_detils_date">
                                        <p class="job_profile">No Record Found </p>
                                    </div>
                                </div>
                            </ng-container>
                           
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<jw-modal id="custom-emp" class="modal popupaligncenter" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="popupLabel">Document Details</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-emp');">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="clearfix"></div>
                    <div class="import_image_section">
                        <div class="import_file_img" *ngFor="let document of employeedocumentlist | filter: {fname: searchText,mname: searchText,lname: searchText,emailid: searchText,countryname: searchText };  let i=index">
                            <div class="import_img">
                                <i class="fa fa-file-image-o" aria-hidden="true"></i>
                            </div>
                            <div class="import_file_name">
                                <p>{{ document.documentname }}</p>
                            </div>
                            <div class="import_right">
                                <div class="check">
                                    <a (click)="opendocumnentname(document.employeedocumentdetailsid,document.employeeid)">
                                        <i class="fa fa-edit" aria-hidden="true"></i>
                                        <div class="tooltip_visa">
                                            <p>Edit</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="import_footer">
                                <div class="footer_imp_le">
                                    <!-- <p>File Size :<span>{{getImageDimenstion(document.documentimageurl)}}</span></p> -->
                                </div>
                                <div class="footer_imp_ri">
                                    <i class="fa fa-file-image-o" *ngIf="docperm > 0" (click)="openModal5('pdf-image-viewer1', document.documentimageurl)">
                                        <div class="tooltip_visa">
                                            <p>View image</p>
                                        </div>
                                    </i>
                                    <i class="fa fa-trash" *ngIf="docperm > 2" (click)="deleteFile(document.employeedocumentdetailsid)">
                                        <div class="tooltip_visa">
                                            <p>Delete</p>
                                        </div>
                                    </i>
                                    <a *ngIf="docperm > 0" href="{{ document.documentimageurl }}"><i class="fa fa-download">
                                        <div class="tooltip_visa">
                                            <p>Download</p>
                                        </div>
                                    </i>
                                </a>
                                </div>
                                <div class="clearfix"></div>

                            </div>
                        </div>

                        <div class="job_list_section visa_request_section width100" *ngIf="record ==0">
                            <div class="job_detils_date">
                                <p class="job_profile">No Record Found</p>
                            </div>
                        </div> 
                    </div>
                    <!-- <div class="row">
             

            <div class="form-group col-lg-3 text-center" *ngFor="let document of employeedocumentlist">
              <img class="rounded mr-2" src="assets/img/file.png" style="width:100%;">
              <p> {{ document.documentname }}</p>
              <p class="iconlist">
              <i class="fa fa-file-image-o" *ngIf="docperm > 0" (click)="openModal5('pdf-image-viewer1', document.documentimageurl)"></i>
                <i class="fa fa-trash" *ngIf="docperm > 2" (click)="deleteFile(document.employeedocumentdetailsid)"></i>
                <a  *ngIf="docperm > 0" href="{{ document.documentimageurl }}"><i class="fa fa-download"></i></a></p>
            </div>
          </div> -->

                </div>
            </div>
            <div class=" modal-footer">

                <div *ngIf="docperm > 1">
                    <button (click)="file.click()" class="btn btn-primary com_btn" type="submit">
                        <input type="file" #file (change)="selectFile($event)" style="display:none;" />
                        Choose file
                    </button>

                    <!-- <div (click)="file.click()">
                        <input type="file" #file (change)="selectFile($event)" style="display:none;" />
                        <label class="btn btn-primary com_btn ">
                      <span class="buttonText">Choose file</span>
                    </label>
                    </div> -->
                </div>
                <button class="btn btn-secondary com_btn" type="submit"  (click)="closeModal('custom-emp');">Cancel</button>
                <!-- <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-emp');" /> -->
            </div>
        </div>
    </div>
</jw-modal>


<jw-modal id="pdf-image-viewer1" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer1');">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="container"> 

                    <div class="row">
                        <div class="col-lg-12">

                            <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="500" frameborder="0"></iframe>
                            <img *ngIf="imgsrc == true" [src]="iframesrc" style="width:100%;" />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</jw-modal>




<jw-modal id="employee-doc-name-update" class="modal name_rename" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">

            <div class="modal-body">
                <div class="container">
                    <form [formGroup]="documentGroup" #documentForm="ngForm" class="s12 white" (ngSubmit)="onRenameDocumentSubmit(documentForm.value)" *ngIf="documentnamelist">


                        <div class="row row-sm mg-t-10">
                            <label>Document Name*</label>
                            <input type="hidden" id="employeedocumentdetailsid" name="employeedocumentdetailsid" formControlName="employeedocumentdetailsid" [(ngModel)]="documentnamelist.employeedocumentdetailsid" />
                            <input type="hidden" id="employeeid" name="employeeid" formControlName="employeeid" [(ngModel)]="documentnamelist.employeeid" />
                            <input type="text" formControlName="documentname" name="documentname" id="documentname" [ngClass]="{ 'is-invalid': submitted && f.documentname.errors }" class="form-control" [(ngModel)]="documentnamelist.documentname" maxlength="100">
                            <div *ngIf="submitted && f.documentname.errors" class="invalid-feedback">
                                <div *ngIf="f.documentname.errors.required">Please enter document name</div>
                            </div>
                        </div>
                        <div class="row row-sm float-right">
                            <button type="submit" class="btn btn-primary com_btn btn_space">Save</button>
                            <button type="button" class="btn btn-secondary com_btn" (click)="closeModal('employee-doc-name-update');">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</jw-modal>