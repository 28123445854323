import { Component, OnInit, Input } from '@angular/core';
import { MyvisaService } from '../../../services/myvisa.service';
import { MypassportService } from '../../../services/mypassport.service';
import { MyidproofService } from '../../../services/myidproof.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CheckserviceService } from '../../../services/checkservice.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from '../../../_services/modal.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UploadFileService } from './../../../services/upload-file.service';
import { candidatenotes } from '../../../models/jobs/candidate.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-right-to-work-history',
  templateUrl: './right-to-work-history.component.html',
  styleUrls: ['./right-to-work-history.component.css']
})

export class RightToWorkHistoryComponent implements OnInit {

  @Input() visaitems: Array<any>;
  passportitems: Array<any>;
  docitems: Array<any>;
  wizard:any = 1;
  Userid: any;
  visarecord: any;
  passportrecord: any;

  mobileapprecord: any;
  rfiddata =[];
  mrzdata=[];
  pacedata=[];
  rfidlen:any =0;

  isdecline: boolean =false;
  docrecord: any;
  visapageOfItems: Array<any>;
  passportpageOfItems: Array<any>;
  docpageOfItems: Array<any>;
  documenttype:any;
  logid:any;
  candidatenoteFormGroup: FormGroup;
  iframesrc: any;
  imgsrc: any;
  righttoworklogdetails:any;
  totalquestioncount:any;
  questionlist1:any;
  selectdocument:any;
  downloadtext: any = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
  //question ans list

  companymaster:any;
  checkbyemployee:any;

  
  questionanslist:any;
  submitted: boolean;
  selectdocpdffilename: FileList;
  docprofile: any;
  pdfbackfilesizeerror:any;  
  pdfbackfileformaterror:any;
  docpdffilename:any = "Choose file";
  docpdf:any;
  candidatenotesdetails = new candidatenotes();

  constructor(
    private uploadService: UploadFileService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private visaService: MyvisaService,
    private checkService: CheckserviceService,
    private passportService: MypassportService,
    private modalService: ModalService, 
    private idproofService: MyidproofService,
    private datePipe: DatePipe 
  ) { }

  ngOnInit() {
    this.wizard == 1;
    this.documenttype ='Visa';
    this.Userid = localStorage.getItem('userid');

    this.logid = this.activatedRoute.snapshot.paramMap.get("logid");
    this.getRightToWorkLog();
    this.candidatenoteFormGroup = this.formBuilder.group({    
      notes: ['', Validators.required],
      sharecode: ['']      
    });


    

    // this.getVisaRecords();
    // this.getPassportRecords();
    // this.getDocumentsRecords();
  }

  get j() { return this.candidatenoteFormGroup.controls; }

  getRightToWorkLog(){
    var data = {logid: this.logid}
    this.checkService.getrighttoworkdetailsbylogid(data).subscribe((righttoworklog: any) => { 
      
      var db = {qtype: 4};
      this.checkService.allquestion(db).subscribe((dataquestion: any) => {
        if(righttoworklog.success == 'true'){      


          this.isdecline = righttoworklog.isdecline;
          this.questionanslist = JSON.parse(righttoworklog.anslist);
          this.totalquestioncount = dataquestion.length;
          this.questionlist1 = dataquestion;  
          this.righttoworklogdetails= righttoworklog;
          this.visarecord  = righttoworklog.visa.length;
          this.visapageOfItems = righttoworklog.visa;
          this.passportrecord  = righttoworklog.passport.length;      
          this.passportpageOfItems = righttoworklog.passport;
          this.docrecord  = righttoworklog.document.length;          
          this.docpageOfItems = righttoworklog.document;
          this.selectdocument = righttoworklog.question;
          this.mobileapprecord = righttoworklog.mobileapp;




          console.log(this.mobileapprecord);

          this.companymaster  = righttoworklog.company;
          this.checkbyemployee = righttoworklog.createby;



          if(this.mobileapprecord.pacedata)
          {
            let str3: string =this.replaceAll(this.mobileapprecord.pacedata,'\n',"");               
            this.pacedata = JSON.parse(str3)
          }




          let str: string = this.replaceAll(this.mobileapprecord.rfiddata,'\n',"");  
          
          
          if(this.mobileapprecord)
            if(this.mobileapprecord.rfiddata) 
            {
              this.rfiddata =JSON.parse(str);
              this.rfidlen = this.rfiddata.length;
            } 


            str = this.replaceAll(this.mobileapprecord.mrzdata,'\n',""); 

            str = this.replaceAll( str,'[','{');
            str = this.replaceAll( str,']','}');

            str = this.replaceAll( str,'{{','[{');
            str = this.replaceAll( str,'}}','}]');

            

            console.log('MRZ', str);


            if(this.mobileapprecord)
              if(this.mobileapprecord.mrzdata) 
              {
                this.mrzdata =JSON.parse(str);
              } 
              


        } else{
          this.visarecord =0;
          this.passportrecord=0;
          this.docrecord=0;
        }
      });
    });
  }


  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }


  toggle2(id) {
    this.wizard = id;
    if(id==1){
      this.documenttype ='Visa';
    } else if(id ==2){
      this.documenttype ='Passport';
    } else{
      this.documenttype ='Document';
    }
  }


  onChangePage(pageOfItems: Array<any>, type) {
    // update current page of items
    if(type=='visa')
      this.visapageOfItems = pageOfItems;
    if(type=='passport')
      this.passportpageOfItems = pageOfItems;
  }

  getVisaRecords()
  {
    const visa_data = { 'id': this.Userid, 'cstatus': [1, 2, 3], 'isrighttowork': 1, 'isaiverify': [1, 2, 3] };
    this.visaService.getallvisaListbyadmin(visa_data).subscribe((data: any) => {     
      this.visarecord  = data.length;
      this.visapageOfItems = data;
      if(this.visarecord < 30){
        this.visapageOfItems = data;
      }
      else{
        this.visaitems =data;
      }
    });
  }

  getPassportRecords()
  {
    const data = { 'id': this.Userid, 'cstatus': [1, 2, 3], 'isrighttowork': 1, 'isaiverify': [1, 2, 3] };
    this.passportService.getallpassportListbyadmin(data).subscribe((data: any) => {
      this.passportrecord  = data.length;
      if(this.passportrecord < 30){
        this.passportpageOfItems = data;
      }
      else{
           this.passportitems =data;
      }
    });
  }

  getDocumentsRecords()
  {
    const data = { 'id': this.Userid, 'cstatus': [1], 'isrighttowork': 1, 'isaiverify': [1, 2, 3] };
    this.idproofService.getallidproofListbyadmin(data).subscribe((data: any) => {     
      this.docrecord  = data.length;
      if(this.docrecord < 30){
        this.docpageOfItems = data;
      }
      else{
           this.docitems =data;
      }
    });
  }

  openModal3(id: string, data: any) 
  {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }
  
  closeModal(id: string) 
  {
   
      this.modalService.close(id);
     
    
  }
  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  downloadReport(){
    this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
    var data = {logid: this.logid};
    this.checkService.downloadRightToWorkLog(data).subscribe((data: any) => { 
      window.open(data.response_data.Location, "_blank");
      this.downloadtext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
    });
  }

  updatecandidatenote(value)
  {
    this.submitted = true;
    if (this.candidatenoteFormGroup.invalid) {      
      return;
    }
    if (this.selectdocpdffilename) {
      this.docprofile = this.selectdocpdffilename;
      var filetype = this.docprofile.type.split('/');
      if (this.docprofile.size > 2000 * 1024) {
        this.pdfbackfilesizeerror = true;
        return;
      } else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png" ) && (filetype[filetype.length - 1] != "pdf" ) )  {
        this.pdfbackfileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.docprofile);
      value.pdfreport = imagepath;
    } 
    var righttowork = { 
      id: this.logid, 
      notes: value.notes,
      pdfreport: value.pdfreport, 
      sharecode: value.sharecode
    };
    this.checkService.updatenewrighttowork(righttowork).subscribe((righttoworklog: any) => {  
      this.getRightToWorkLog();  
      this.modalService.close('add-notes-to-right-to-work');
    });
  }
 
  shouldDisplayDate(date: Date) : boolean 
  {    
    if (this.datePipe.transform(date, 'yyyy-MM-dd')  >=  this.datePipe.transform('10/02/2022', 'yyyy-MM-dd')) 
    { 
        return true;
    }  
    return false;
}

  editNotes(){
    this.submitted = false;
    this.candidatenotesdetails.notes = this.righttoworklogdetails.notes;
    this.candidatenotesdetails.sharecode = this.righttoworklogdetails.sharecode;
    this.candidatenoteFormGroup.reset({"notes":this.righttoworklogdetails.notes, "sharecode": this.righttoworklogdetails.sharecode});
    this.modalService.open('add-notes-to-right-to-work');
  }

  selectPdfFile(event)
  {    
    this.pdfbackfilesizeerror = false;  
    this.pdfbackfileformaterror = false;
    var files = event.target.files;
    this.docpdffilename = files[0].name;
    this.selectdocpdffilename = this.docpdf = files[0];
    var filetype = this.docpdf.type.split('/');
    if (this.docpdf.size > 2000 * 1024) {
      this.pdfbackfilesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "pdf") )  {
      this.pdfbackfileformaterror = true;    
    }else{
      this.selectdocpdffilename = files[0];
    }
  }

}
