<div class="bg">
    <app-header></app-header>
    <!-- 
    <div class="card-activities">
        <div class="media-list">
            <div class="activity-icon">
                <a style="color:#164881; cursor: pointer;" (click)="yearpop('year-select')"><i class="fa fa-calendar"
            aria-hidden="true"></i></a>
                <jw-modal id="year-select" class="modal yearselect" role="dialog">
                    <div role="document" class="formgroup">
                        <div class="modal-content">
                            <div class="modal-body" style="padding:5px;">
                                <form #yearForm="ngForm" class="s12 white" (ngSubmit)="onyearSubmit(yearForm.value,'year-select')">
                                    <div class="">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <select name="year" id="year" ngModel='{{ year }}'>
                          <option>2017</option>
                          <option>2018</option>
                          <option>2019</option>
                          <option>2020</option>
                        </select>
                                            </div>
                                        </div>
                                        <div class="calender mg-t-5">
                                            <button type="submit" class="btn btn-primary com_btn">Save</button>
                                            <button type="button" class="btn btn-secondary com_btn" id="" (click)="closeModal('year-select');">Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </jw-modal>
            </div>
        </div>
    </div> -->

    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__content">
                    <!-- <div class="textrotate2">
                        <ul>
                            <li><a [routerLink]="['/my-worklist']">My Timesheet</a></li>
                            <li><a [routerLink]="['/my-worklist-report']">Report</a></li>
                        </ul>
                    </div> -->
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row">
                                <div class="col-lg-12">
                                    <ul class="tabs__head montharea">
                                        <li><a [ngClass]="{'active': classactive == '1'}" (click)="getDataByMonth(1);">January </a></li>
                                        <li><a [ngClass]="{'active': classactive == '2'}" (click)="getDataByMonth(2);">February </a></li>
                                        <li><a [ngClass]="{'active': classactive == '3'}" (click)="getDataByMonth(3);">March </a></li>
                                        <li><a [ngClass]="{'active': classactive == '4'}" (click)="getDataByMonth(4);">April </a></li>
                                        <li><a [ngClass]="{'active': classactive == '5'}" (click)="getDataByMonth(5);">May</a></li>
                                        <li><a [ngClass]="{'active': classactive == '6'}" (click)="getDataByMonth(6);">June </a></li>
                                        <li><a [ngClass]="{'active': classactive == '7'}" (click)="getDataByMonth(7);">July </a></li>
                                        <li><a [ngClass]="{'active': classactive == '8'}" (click)="getDataByMonth(8);">August </a></li>
                                        <li><a [ngClass]="{'active': classactive == '9'}" (click)="getDataByMonth(9);">September </a></li>
                                        <li><a [ngClass]="{'active': classactive == '10'}" (click)="getDataByMonth(10);">October </a></li>
                                        <li><a [ngClass]="{'active': classactive == '11'}" (click)="getDataByMonth(11);">November </a></li>
                                        <li><a [ngClass]="{'active': classactive == '12'}" (click)="getDataByMonth(12);">December </a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="floatleft mt-4">
                                <div class="col-md-12">
                                    <h3 class="my_leave_heading"><span class="redcolor_text font22">My Timesheet</span> <span class="graycolor_text fonts16"> ({{yearmonth}}) </span></h3>
                                </div>
                            </div>
                            <div class="floatright mt-4">
                                <div class="col-md-12 form-inline ">
                                    <select name="year" id="year" class="form-control yearselect" (change)="onyearSubmit($event.target.value)" [ngModel]="year">
                                        <option value="{{y}}" *ngFor="let y of yearList">{{ y }}</option>
                                    </select>
                                    <a [routerLink]="['/my-worklist-report']" routerLinkActive="active-link" class="toggle_button"><span class="lnr lnr-list"></span>   <span class="tooltip_show">List View</span></a>
                                    <a [routerLink]="['/my-worklist']" routerLinkActive="active-link" class="toggle_button"><span class="lnr lnr-calendar-full"></span>  <span class="tooltip_show">Calendar View </span></a>
                                    <!-- <button type="submit" class="btn btn-danger com_btn" (click)="addNewEvent('Add',event)">Apply Leave</button> -->
                                </div>
                            </div>

                            <div class="clearfix"></div>
                            <div class="job_list_section employee_list_section" *ngFor="let activity of pageOfItems;  let i=index">
                                <div class="job_detils_date width22">
                                    <p class="job_address">Project</p>
                                    <p class="job_profile">{{activity.project.projectname}} </p>
                                </div>
                                <div class="job_detils_date width7">
                                    <p class="job_address">Hours</p>
                                    <p class=" badge badge-danger">{{activity.ahour}} H</p>
                                </div>
                                <div class="job_detils_date width22">
                                    <p class="job_address">Hours Type</p>
                                    <p class="job_profile">{{activity.hourtype.typename}}</p>
                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address">Date</p>
                                    <p class="job_profile">{{activity.startdate | date: 'dd MMM yyyy'}}</p>
                                </div>
                                <div class="job_detils_date width7">
                                    <p class="job_address">Status</p>
                                    <span class="badge badge-danger" *ngIf="activity.hstatus == '3'">Rejected</span>
                                    <span class="badge badge-warning" *ngIf="activity.hstatus == '1'">Pending</span>
                                    <span class="badge badge-success" *ngIf="activity.hstatus == '2'">Approved</span>
                                </div>
                                <div class="job_detils_date width7">
                                    <p class="job_address ">
                                        <button type="submit" class="btn btn-info com_btn" (click)="openModalnote(activity.remark.hourremark,'note-leave-report')"> 
                                            <div class="tooltip_visa width73px">
                                                <p>Message </p>
                                            </div>
                                            <i class="fa fa-comments-o "></i>
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div class="job_list_section visa_request_section" *ngIf="record == 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>
                            <div class="col-lg-12 text-right" *ngIf="record > 30">
                                <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<jw-modal id="note-leave-report" class="modal " role="dialog ">
    <div role="document" class="formgroup ">
        <div class="modal-content ">
            <div class="modal-header ">
                <h5 class="modal-title ">Notes</h5>
                <button type="button " class="close " data-dismiss="modal " name="close " aria-label="Close " (click)="closeModal( 'note-leave-report'); ">
                <span aria-hidden="true ">&times;</span>
                </button>
            </div>
            <div class="modal-body ">
                <div class="container ">
                    <div class="job_profile">
                        <div class="">
                            <p><b>Note :</b> {{notedetails}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer ">
                <button type="button " class="btn btn-secondary com_btn " data-dismiss="modal " (click)="closeModal( 'note-leave-report');">Close</button>
            </div>
        </div>
    </div>
</jw-modal>