"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var NotificationService = /** @class */ (function () {
    function NotificationService(commonService) {
        this.commonService = commonService;
        this.modals = [];
    }
    NotificationService.prototype.getleavenotificationbyadmin = function (data) {
        return this.commonService.formpost('/admin/getleavenotificationbyadmin', data);
    };
    NotificationService.prototype.getexpensenotificationbyadmin = function (data) {
        return this.commonService.formpost('/admin/getexpensenotificationbyadmin', data);
    };
    NotificationService.prototype.getleavenotificationbydatebyadmin = function (data) {
        return this.commonService.formpost('/admin/getleavenotificationbydatebyadmin', data);
    };
    NotificationService.prototype.getpassportexpirydatebyadmin = function (data) {
        return this.commonService.formpost('/admin/getpassportexpirybyadmin', data);
    };
    NotificationService.prototype.getvisaexpirydatebyadmin = function (data) {
        return this.commonService.formpost('/admin/getvisaexpirybyadmin', data);
    };
    NotificationService.prototype.getcosexpirydatebyadmin = function (data) {
        return this.commonService.formpost('/admin/getcosexpirybyadmin', data);
    };
    NotificationService.prototype.getallnotificationbydatebyadmin = function (data) {
        return this.commonService.formpost('/admin/getallnotificationbydatebyadmin', data);
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.CommonService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
exports.NotificationService = NotificationService;
