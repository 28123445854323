"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../_services/modal.service");
var aml_model_1 = require("../../../models/aml/aml.model");
var forms_1 = require("@angular/forms");
var aml_service_1 = require("../../../services/aml.service");
var moment = require("moment");
var country_service_1 = require("../../../services/country.service");
var checksystem_service_1 = require("../../../services/checksystem.service");
var router_1 = require("@angular/router");
var startWith_1 = require("rxjs/operators/startWith");
var map_1 = require("rxjs/operators/map");
var State = /** @class */ (function () {
    function State(countryid, countryname, createat, isid, iso, isothree, ispassport, isrighttoworkcountry, isvisa, sstatus, updateat) {
        this.countryid = countryid;
        this.countryname = countryname;
        this.createat = createat;
        this.isid = isid;
        this.iso = iso;
        this.isothree = isothree;
        this.ispassport = ispassport;
        this.isrighttoworkcountry = isrighttoworkcountry;
        this.isvisa = isvisa;
        this.sstatus = sstatus;
        this.updateat = updateat;
    }
    return State;
}());
exports.State = State;
var SearchpersonalamlComponent = /** @class */ (function () {
    function SearchpersonalamlComponent(modalService, formBuilder, amldetails, router, country, check, activatedRoute) {
        var _this = this;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.amldetails = amldetails;
        this.router = router;
        this.country = country;
        this.check = check;
        this.activatedRoute = activatedRoute;
        this.userSelectsString = '';
        this.countrySelectsString = '';
        this.businessDetail = new aml_model_1.Aml();
        this.defaultVal = '';
        this.tc = true;
        this.checkedAll = false;
        this.showdataset = true;
        this.dateerror = false;
        this.countryerror = false;
        this.searchrecord = false;
        this.isbirth = true;
        this.ispep = true;
        this.issan = true;
        this.selectpeplist = [
            {
                "id": "SAN",
                "name": "Sanction",
                "heading": "Sanctions",
                "description": "Any individual or entity that is or has formerly been subject to sanctions at international, regional, or national levels."
            },
            {
                "id": "RRE",
                "name": "Reputational Risk Exposure",
                "heading": "Reputational Risk Exposure",
                "description": "Any individual or entity that has been reported in official or media sources to be involved in a crime that falls under one or more of the following categories – terrorism, financial crime and fraud, modern slavery, organised crime, bribery and corruption, and cybercrime."
            },
            {
                "id": "REL",
                "name": "Regulatory Enforcement List",
                "heading": "Regulatory Enforcement List",
                "description": "Any individual or entity that has been fined or in some other way subject to action by any financial regulatory body or law enforcement authority."
            },
            {
                "id": "INS",
                "name": "Insolvency (UK & Ireland)",
                "heading": "Insolvency",
                "description": "Any person that has been declared as bankrupt or insolvent."
            },
            {
                "id": "PEP",
                "name": "PEP",
                "heading": "Politically exposed person",
                "description": "Any individual that is considered a Politically Exposed Person in their own capacity or by association."
            },
            {
                "id": "POI",
                "name": " Profile of Interest",
                "heading": "Profile of Interest",
                "description": "Natural or legal persons that were profiled based on Complygate Risk Intelligence’s data model and can be linked to outdated red flags, such as expired PEP status, reputational risk, or regulatory measures no longer in force."
            },
            {
                "id": "DD",
                "name": "Disqualified Director (UK only)",
                "heading": "Disqualified Director",
                "description": "Any individual that has been disqualified as acting as a director of a company."
            },
            {
                "id": "DL",
                "name": "Director - UK",
                "heading": "Disqualified Director",
                "description": "Any individual that has been disqualified as acting as a director of a company."
            },
            {
                "id": "DLG",
                "name": "Director - Global",
                "heading": "Disqualified Director",
                "description": "Any individual that has been disqualified as acting as a director of a company."
            }
        ];
        this.countryuserSelects = [];
        this.userSelects = [];
        this.suggestions = [{ "id": "PEP-FORMER", "name": "Former" }, { "id": "PEP-CURRENT", "name": "Current" }, { "id": "PEP-LINKED", "name": "Linked" }];
        this.peplist = [
            {
                "id": "PEP",
                "name": "PEP",
                "heading": "Politically exposed person",
                "description": "Any individual that is considered a Politically Exposed Person in their own capacity or by association."
            },
            {
                "id": "SAN",
                "name": "Sanction",
                "heading": "Sanctions",
                "description": "Any individual or entity that is or has formerly been subject to sanctions at international, regional, or national levels."
            },
            {
                "id": "RRE",
                "name": "Reputational Risk Exposure",
                "heading": "Reputational Risk Exposure",
                "description": "Any individual or entity that has been reported in official or media sources to be involved in a crime that falls under one or more of the following categories – terrorism, financial crime and fraud, modern slavery, organised crime, bribery and corruption, and cybercrime."
            },
            {
                "id": "INS",
                "name": "Insolvency (UK & Ireland)",
                "heading": "Insolvency",
                "description": "Any person that has been declared as bankrupt or insolvent."
            },
            {
                "id": "DD",
                "name": "Disqualified Director (UK only)",
                "heading": "Disqualified Director",
                "description": "Any individual that has been disqualified as acting as a director of a company."
            },
            {
                "id": "POI",
                "name": " Profile of Interest",
                "heading": "Profile of Interest",
                "description": "Natural or legal persons that were profiled based on Complygate Risk Intelligence’s data model and can be linked to outdated red flags, such as expired PEP status, reputational risk, or regulatory measures no longer in force."
            },
            {
                "id": "REL",
                "name": "Regulatory Enforcement List",
                "heading": "Regulatory Enforcement List",
                "description": "Any individual or entity that has been fined or in some other way subject to action by any financial regulatory body or law enforcement authority."
            },
            {
                "id": "DL",
                "name": "Director - UK",
                "heading": "Director List",
                "description": "Any individual that has been disqualified as acting as a director of a company."
            },
            {
                "id": "DLG",
                "name": "Director - Global",
                "heading": "Director Details",
                "description": "Any individual that has been disqualified as acting as a director of a company."
            }
        ];
        this.show = false;
        this.countryshow = false;
        this.stateCtrl = new forms_1.FormControl();
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
            _this.states = country;
            _this.filteredStates = _this.stateCtrl.valueChanges
                .pipe(startWith_1.startWith(''), map_1.map(function (state) { return state ? _this.filterStates(state) : _this.states.slice(); }));
        });
    }
    SearchpersonalamlComponent.prototype.filterStates = function (name) {
        return this.states.filter(function (state) {
            return state.countryname.toLowerCase().indexOf(name.toLowerCase()) === 0;
        });
    };
    SearchpersonalamlComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateCtrl = new forms_1.FormControl();
        this.downloadtext = 'No Record Found <i class="fa fa-plus ml-2"></i>';
        this.type = this.activatedRoute.snapshot.paramMap.get("type");
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.showdataset = true;
        this.isbirth = false;
        this.ispep = true;
        this.issan = true;
        this.dateerror = false;
        this.countryerror = false;
        // this.permission = permission[0];
        if (this.type == 'aml') {
            this.historytext = 'View AML, PEPs & Sanction';
            this.atype = 0;
            if (permission[0]['m96'] < 3) {
                this.router.navigate(['/permission-denied']);
            }
        }
        else if (this.type == 'global') {
            this.historytext = 'View Global Due Diligence';
            this.atype = 3;
            if (permission[0]['m189'] < 3) {
                this.router.navigate(['/permission-denied']);
            }
        }
        else if (this.type == 'adverse') {
            this.historytext = 'View Adverse Media Search';
            this.atype = 2;
            if (permission[0]['m179'] < 3) {
                this.router.navigate(['/permission-denied']);
            }
        }
        this.Threshold = 80;
        this.error = false;
        this.disableBtn = true;
        this.errormsg = '';
        this.buttoninsert = "Search";
        this.result = false;
        this.defaultVal = '';
        this.personalformGroup = this.formBuilder.group({
            fullname: ['', forms_1.Validators.required],
            date: ['', [forms_1.Validators.pattern("^[0-9]*$"), forms_1.Validators.minLength(2), forms_1.Validators.maxLength(2)]],
            month: ['', [forms_1.Validators.pattern("^[0-9]*$"), forms_1.Validators.minLength(2), forms_1.Validators.maxLength(2)]],
            year: ['', [forms_1.Validators.pattern("^[0-9]*$"), forms_1.Validators.minLength(4), forms_1.Validators.maxLength(4)]],
            // Country: ['', Validators.required],      
            Threshold: [''],
            dobMatching: [''],
            gender: [''],
            YearOfBirth: [''],
            sanctionstatus: [''],
            stateCtrl: [''],
            // County: [''],
            // City: [''],          
            // PEP: [''],
            PreviousSanctions: [''],
            CurrentSanctions: [''],
            LawEnforcement: [''],
            FinancialRegulator: [''],
            Insolvency: [''],
            DisqualifiedDirector: [''],
            AdverseMedia: ['']
        });
        this.Userid = localStorage.getItem('userid');
        this.logid = this.activatedRoute.snapshot.paramMap.get("id");
        this.bindandselectlist();
        // if(this.type == 'global')
        // {
        //   this.peplist =  this.peplist.filter(item => item.id !== 'DD');
        //   this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'DD');  
        //   this.peplist =  this.peplist.filter(item => item.id !== 'DDG');
        //   this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'DDG');  
        // }
        if (this.logid) {
            var val = { id: this.logid, atype: this.atype };
            this.amldetails.getCompanylogrecord(val).subscribe(function (searchRes) {
                var listmaster = JSON.parse(searchRes.searchresult);
                if (searchRes.iscomplete === 0) {
                    if (listmaster.results.matchCount > 0) {
                        listmaster.results.matches.forEach(function (element) {
                            element.countrylist = _this.countrylist.filter(function (d) { return element.countries.includes(d.iso); });
                            element.datasetlist = _this.suggestions.filter(function (d) { return element.datasets.includes(d.id); });
                        });
                        _this.result = true;
                        _this.searchresult = listmaster.results;
                    }
                    else {
                        localStorage.setItem('personalid', searchRes.id);
                        _this.router.navigate(['/creditsearchresult-new', searchRes.id]);
                    }
                }
                else {
                    localStorage.setItem('personalid', searchRes.id);
                    _this.router.navigate(['/creditsearchresult-new', searchRes.id]);
                }
            });
        }
        else {
            this.lesssore = false;
            if (this.type == 'global') {
                var ts = { 'serviceid': 23 };
            }
            else if (this.type == 'adverse') {
                var ts = { 'serviceid': 22 };
            }
            else {
                var ts = { 'serviceid': 8 };
            }
            this.check.getbillinginformation(ts).subscribe(function (remaindata) {
                if (remaindata.remiancheck <= 0) {
                    _this.lesssore = true;
                }
            });
            var personalid = localStorage.getItem('personalid');
            if (!personalid && personalid != null) {
                this.router.navigate(['/creditsearchresult', personalid]);
            }
            this.country.getcountrylist().subscribe(function (country) {
                _this.countrylist = country;
            });
            // this.amldetails.getCountries().subscribe((countrydata: any) => { 
            //   // console.log(countrydata);
            //   this.countrylist = countrydata;
            // });
            this.clearpersonal();
        }
    };
    Object.defineProperty(SearchpersonalamlComponent.prototype, "f", {
        get: function () { return this.personalformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    SearchpersonalamlComponent.prototype.datechange = function (val) {
        if (val.date && val.month && val.year) {
            this.isbirth = true;
        }
        else {
            this.isbirth = false;
        }
    };
    SearchpersonalamlComponent.prototype.bindallpep = function (val) {
        if (val) {
            return this.suggestions.filter(function (d) { return val.includes(d.id); });
            ;
        }
        else
            return [];
        // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
    };
    SearchpersonalamlComponent.prototype.bindallcountry = function (val) {
        if (val) {
            return this.countrylist.filter(function (d) { return val.includes(d.iso); });
            ;
        }
        else
            return [];
        // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
    };
    SearchpersonalamlComponent.prototype.suggest = function () {
        this.countryshow = false;
        if (this.show) {
            this.show = false;
        }
        else {
            this.show = true;
        }
    };
    SearchpersonalamlComponent.prototype.isSelected = function (s) {
        return this.userSelects.findIndex(function (item) { return item.id === s.id; }) > -1 ? true : false;
    };
    SearchpersonalamlComponent.prototype.selectSuggestion = function (s) {
        this.userSelects.find(function (item) { return item.id === s.id; }) ?
            this.userSelects = this.userSelects.filter(function (item) { return item.id !== s.id; }) :
            this.userSelects.push(s);
        this.show = false;
    };
    SearchpersonalamlComponent.prototype.deleteSelects = function (s) {
        this.userSelects = this.userSelects.filter(function (item) { return item.id !== s.id; });
        this.show = false;
    };
    SearchpersonalamlComponent.prototype.assignToNgModel = function () {
        var _this = this;
        this.userSelectsString = '';
        this.userSelects.map(function (item) { return _this.userSelectsString += item.name + ' '; });
    };
    SearchpersonalamlComponent.prototype.countrysuggest = function () {
        this.show = false;
        if (this.countryshow) {
            this.countryshow = false;
        }
        else {
            this.countryshow = true;
        }
    };
    SearchpersonalamlComponent.prototype.iscountrySelected = function (s) {
        return this.countryuserSelects.findIndex(function (item) { return item.iso === s.iso; }) > -1 ? true : false;
    };
    SearchpersonalamlComponent.prototype.getPosts = function (val) {
        var _this = this;
        var fakeobj = this.countrylist.filter(function (s) { return s.iso == val; });
        this.countryuserSelects.find(function (item) { return item.iso === val; }) ?
            this.countryuserSelects = this.countryuserSelects.filter(function (item) { return item.iso !== val; }) :
            this.countryuserSelects.push(fakeobj[0]);
        // this.countryuserSelects.push(s);
        if (this.countryuserSelects.length > 0) {
            this.countryerror = false;
        }
        this.countryshow = false;
        // this.stateCtrl = new FormControl('');
        this.stateCtrl = new forms_1.FormControl();
        this.filteredStates = this.stateCtrl.valueChanges
            .pipe(startWith_1.startWith(''), map_1.map(function (state) { return state ? _this.filterStates(state) : _this.states.slice(); }));
        // this.personalformGroup.stateCtrl ="";
        // this.stateCtrl =
        // this.stateCtrl =''; new FormControl();
        // console.log('get option values selection',this.countryuserSelects);
    };
    SearchpersonalamlComponent.prototype.selectcountrySuggestion = function (s) {
        this.countryuserSelects.find(function (item) { return item.iso === s.iso; }) ?
            this.countryuserSelects = this.countryuserSelects.filter(function (item) { return item.iso !== s.iso; }) :
            this.countryuserSelects.push(s);
        // this.countryuserSelects.push(s);
        if (this.countryuserSelects.length > 0) {
            this.countryerror = false;
        }
        this.countryshow = false;
    };
    SearchpersonalamlComponent.prototype.deletecountrySelects = function (s) {
        this.countryuserSelects = this.countryuserSelects.filter(function (item) { return item.iso !== s.iso; });
        if (this.countryuserSelects.length == 0) {
            this.countryerror = false;
        }
        this.countryshow = false;
    };
    SearchpersonalamlComponent.prototype.countryassignToNgModel = function () {
        var _this = this;
        this.countrySelectsString = '';
        this.countryuserSelects.map(function (item) { return _this.countrySelectsString += item.countryname + ' '; });
    };
    SearchpersonalamlComponent.prototype.amllogredirect = function () {
        if (this.type == 'global') {
            this.router.navigate(['/check-aml-log-list', '3']);
        }
        else if (this.type == 'adverse') {
            this.router.navigate(['/check-aml-log-list', '2']);
        }
        else {
            this.router.navigate(['/check-aml-log-list', '0']);
        }
    };
    SearchpersonalamlComponent.prototype.selectall = function (val) {
        if (val == true) {
            this.checkedAll = true;
            this.businessDetail.PEP = true;
            this.businessDetail.CurrentSanctions = true;
            this.businessDetail.DisqualifiedDirector = true;
            this.businessDetail.FinancialRegulator = true;
            this.businessDetail.Insolvency = true;
            this.businessDetail.LawEnforcement = true;
            this.businessDetail.AdverseMedia = true;
            this.businessDetail.PreviousSanctions = true;
        }
    };
    SearchpersonalamlComponent.prototype.selectallfalse = function (val) {
        if (val == false) {
            this.checkedAll = false;
        }
    };
    SearchpersonalamlComponent.prototype.checkpep = function (val) {
        return this.selectpeplist.findIndex(function (item) { return item.id === val.id; }) > -1;
    };
    // checkInArray(val){
    //   return this.selectpeplist.indexOf(val) >= 0;
    // }
    SearchpersonalamlComponent.prototype.countryselectallfalse = function (chk, val) {
        if (chk == true) {
            this.selectpeplist.push(val);
        }
        else {
            this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== val.id; });
        }
        if (this.selectpeplist.findIndex(function (item) { return item.id === 'PEP'; }) > -1)
            this.ispep = true;
        else
            this.ispep = false;
        if (this.selectpeplist.findIndex(function (item) { return item.id === 'SAN'; }) > -1)
            this.issan = true;
        else
            this.issan = false;
    };
    SearchpersonalamlComponent.prototype.dataset = function (values) {
        var _this = this;
        this.datasetlist = "";
        values.forEach(function (element_use) {
            _this.peplist.forEach(function (element) {
                if (element_use == element.id) {
                    _this.datasetlist += element.name + ',';
                }
            });
        });
        this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1);
    };
    SearchpersonalamlComponent.prototype.clearpersonal = function () {
        this.submitted = false;
        // this.personalformGroup.reset();
        this.businessDetail = new aml_model_1.Aml();
        this.businessDetail.Forename = "";
        this.businessDetail.Country = "";
        this.businessDetail.Middlename = "";
        this.businessDetail.Threshold = "";
        this.businessDetail.Surname = "";
        this.businessDetail.Postcode = "";
        this.businessDetail.Address = "";
        this.businessDetail.DateOfBirth = "";
        this.businessDetail.YearOfBirth = "";
        this.defaultVal = "";
        this.businessDetail.County = "";
        this.businessDetail.City = "";
        this.businessDetail.PEP = true;
        this.businessDetail.CurrentSanctions = true;
        this.businessDetail.DisqualifiedDirector = true;
        this.businessDetail.FinancialRegulator = true;
        this.businessDetail.Insolvency = true;
        this.businessDetail.LawEnforcement = true;
        this.businessDetail.AdverseMedia = true;
        this.businessDetail.PreviousSanctions = true;
        this.checkedAll = true;
    };
    SearchpersonalamlComponent.prototype.CheckresourceId = function (id, type) {
        var _this = this;
        this.error = false;
        this.submitted = true;
        if (type == 'first' && this.logid == 0) {
            this.disableBtn = true;
            if (this.type == 'global') {
                var ts = { 'serviceid': 23 };
            }
            else if (this.type == 'adverse') {
                var ts = { 'serviceid': 22 };
            }
            else {
                var ts = { 'serviceid': 8 };
            }
            this.check.getbillinginformation(ts).subscribe(function (remaindata) {
                // console.log(remaindata)remaindata.remiancheck=100;
                if (remaindata.remiancheck <= 0) {
                    _this.lesssore = true;
                    _this.modalService.open('upgrade-plan');
                }
                else {
                    _this.disableBtn = true;
                    _this.lesssore = false;
                    _this.resourceId = id;
                    _this.modalService.open('pdf-payment-confirmation');
                }
            });
        }
        else if (type == 'payment' || this.logid != 0) {
            id.id = this.logid;
            this.router.navigate(['/creditsearchresult-new', this.logid, id.resourceId]);
            // var dd = { 
            //   resourceId: id,
            //   logid: this.logid
            // }    
            // console.log(dd);
            /* AML Complete report Block */
            // this.amldetails.getSearchPersonalSearchById(id).subscribe((searchRes: any) => {     
            //   console.log(searchRes);
            //   this.errormsg ='';
            //   if(searchRes.error == ''){
            //     localStorage.setItem('personalid', searchRes.id);    
            //    this.router.navigate(['/creditsearchresult-new', searchRes.id]);    
            //     // this.searchresult = searchRes.result;
            //   }else if(searchRes.error == 'amlfail')
            //   {
            //       this.errormsg ='We have some issue in AML & PEPs check. Please contact Administrator';
            //   }else  if(searchRes.error == 'paymentfail')
            //   {
            //     this.errormsg ='Your payment fail. Please contact Administrator';
            //   }
            // });
            /* AML Complete report Block */
        }
    };
    SearchpersonalamlComponent.prototype.onSubmit = function (val, type) {
        var _this = this;
        this.error = false;
        this.countryerror = false;
        this.dateerror = false;
        this.submitted = true;
        if (this.personalformGroup.invalid) {
            return;
        }
        var countrylist = [];
        this.countryuserSelects.forEach(function (element) {
            countrylist.push(element.iso);
        });
        // if(countrylist.length==0)
        // {
        //   this.countryerror = true;
        //   return;
        // }
        if (val.date || val.year || val.month) {
            this.dateerror = true;
            if (!val.date) {
                return;
            }
            else if (!val.month) {
                return;
            }
            else if (!val.year) {
                return;
            }
            if ((parseInt(val.date) >= 1 && parseInt(val.date) <= 31) && (parseInt(val.month) >= 1 && parseInt(val.month) <= 12) && (parseInt(val.year) >= 1900 && parseInt(val.year) <= 2021)) {
                var datemaster = val.date + '/' + val.month + '/' + val.year;
                var datemaster2 = val.year + '/' + val.month + '/' + val.date;
                var sd = new Date(datemaster);
                var sd2 = new Date(datemaster2);
                val.DateOfBirth = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
                val.DateOfBirth = String(moment(sd2).format("YYYY-MM-DD")); //sd2.getTime(); ///Date.UTC(sd2.getFullYear(), sd2.getMonth(), sd2.getDate());// Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
                // console.log('date',Date.UTC(sd2.getFullYear(), sd2.getMonth(), sd2.getDate()));
                // val.DateOfBirth =Date.UTC(val.year, val.month, val.date);  //Date.UTC(val.date, val.month, val.year);
            }
            else {
                return;
            }
            this.dateerror = false;
        }
        var pep = [];
        if (this.selectpeplist.length == 0) {
            this.error = true;
            return;
        }
        else {
            this.selectpeplist.forEach(function (el) {
                pep.push(el.id);
            });
        }
        if (!this.Threshold) {
            val.threshold = 50;
        }
        else {
            val.threshold = this.Threshold;
        }
        var dd = {
            name: val.fullname,
            countries: countrylist,
            threshold: val.threshold,
            dob: val.DateOfBirth,
            dobMatching: val.dobMatching,
            gender: val.gender,
            datasets: pep,
            type: this.type
        };
        if (type == 'first') {
            this.disableBtn = true;
            if (this.type == 'global') {
                var ts = { 'serviceid': 23 };
            }
            else if (this.type == 'adverse') {
                var ts = { 'serviceid': 22 };
            }
            else {
                var ts = { 'serviceid': 8 };
            }
            // var ts  = { 'serviceid': 8}; 
            this.check.getbillinginformation(ts).subscribe(function (remaindata) {
                // console.log(remaindata)remaindata.remiancheck=100;
                if (remaindata.remiancheck <= 0) {
                    _this.lesssore = true;
                    _this.modalService.open('upgrade-plan');
                }
                else {
                    _this.disableBtn = true;
                    _this.lesssore = false;
                    _this.modalService.open('pdf-payment-confirmation');
                }
            });
        }
        else if (type == 'payment') {
            this.searchrecord = true;
            this.result = true;
            setTimeout(function () {
                _this.amldetails.getSearchPersonalSearch(dd).subscribe(function (searchRes) {
                    _this.result = false;
                    _this.errormsg = '';
                    if (searchRes.error == '') {
                        if (searchRes.result.matchCount > 0)
                            _this.result = true;
                        else
                            _this.router.navigate(['/creditsearchresult-new', searchRes.id]);
                        _this.disableBtn = true;
                        searchRes.result.matches.forEach(function (element) {
                            element.countrylist = _this.countrylist.filter(function (d) { return element.countries.includes(d.iso); });
                            element.datasetlist = _this.suggestions.filter(function (d) { return element.datasets.includes(d.id); });
                        });
                        _this.searchresult = searchRes.result;
                        _this.logid = searchRes.id;
                        _this.searchrecord = false;
                    }
                    else if (searchRes.error == 'amlfail') {
                        _this.errormsg = 'We have some issue in AML & PEPs check. Please contact Administrator';
                    }
                    else if (searchRes.error == 'paymentfail') {
                        _this.errormsg = 'Your payment fail. Please contact Administrator';
                    }
                });
            }, 9000);
        }
    };
    SearchpersonalamlComponent.prototype.openModal = function (id, data) {
        this.bussinessdetails = data;
        // console.log(data);
        this.buttoninsert = "Update";
        this.modalService.open(id);
    };
    SearchpersonalamlComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    /**************Start Data Set Design Setup****************/
    SearchpersonalamlComponent.prototype.choosedatabase = function () {
        this.showdataset = true;
    };
    SearchpersonalamlComponent.prototype.clearalldataset = function () {
        this.selectpeplist = [];
        this.ispep = false;
        this.issan = false;
    };
    SearchpersonalamlComponent.prototype.selectalldataset = function () {
        this.selectpeplist = [
            {
                "id": "SAN",
                "name": "Sanction",
                "heading": "Sanctions",
                "description": "Any individual or entity that is or has formerly been subject to sanctions at international, regional, or national levels."
            },
            {
                "id": "RRE",
                "name": "Reputational Risk Exposure",
                "heading": "Reputational Risk Exposure",
                "description": "Any individual or entity that has been reported in official or media sources to be involved in a crime that falls under one or more of the following categories – terrorism, financial crime and fraud, modern slavery, organised crime, bribery and corruption, and cybercrime."
            },
            {
                "id": "REL",
                "name": "Regulatory Enforcement List",
                "heading": "Regulatory Enforcement List",
                "description": "Any individual or entity that has been fined or in some other way subject to action by any financial regulatory body or law enforcement authority."
            },
            {
                "id": "INS",
                "name": "Insolvency (UK & Ireland)",
                "heading": "Insolvency",
                "description": "Any person that has been declared as bankrupt or insolvent."
            },
            {
                "id": "PEP",
                "name": "PEP",
                "heading": "Politically exposed person",
                "description": "Any individual that is considered a Politically Exposed Person in their own capacity or by association."
            },
            {
                "id": "POI",
                "name": " Profile of Interest",
                "heading": "Profile of Interest",
                "description": "Natural or legal persons that were profiled based on Complygate Risk Intelligence’s data model and can be linked to outdated red flags, such as expired PEP status, reputational risk, or regulatory measures no longer in force."
            },
            {
                "id": "DD",
                "name": "Disqualified Director (UK only)",
                "heading": "Disqualified Director",
                "description": "Any individual that has been disqualified as acting as a director of a company."
            },
            {
                "id": "DLG",
                "name": "Director List (Rest of UK)",
                "heading": "Disqualified Director",
                "description": "Any individual that has been disqualified as acting as a director of a company."
            },
            {
                "id": "DL",
                "name": "Director List (UK)",
                "heading": "Disqualified Director",
                "description": "Any individual that has been disqualified as acting as a director of a company."
            }
        ];
        this.bindandselectlist();
        this.ispep = true;
        this.issan = true;
    };
    SearchpersonalamlComponent.prototype.closedataset = function () {
        this.showdataset = false;
    };
    /**************End Data Set Design Setup****************/
    /*************************No Record Not Found*****************************/
    SearchpersonalamlComponent.prototype.norecordfound = function () {
        var _this = this;
        this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        this.amldetails.generatenorecordv3Pdf({ id: this.logid, atype: this.atype }).subscribe(function (pdfRes) {
            _this.url = pdfRes.response_data.Location;
            _this.downloadtext = 'No Record Found <i class="fa fa-plus ml-2"></i>';
        });
    };
    SearchpersonalamlComponent.prototype.bindandselectlist = function () {
        if (this.type == 'aml') {
            var companyid = localStorage.getItem('companyid');
            if (companyid) {
                if (parseInt(companyid) != 632) {
                    this.peplist = this.peplist.filter(function (item) { return item.id !== 'REL'; });
                    this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== 'REL'; });
                }
            }
            this.peplist = this.peplist.filter(function (item) { return item.id !== 'DLG'; });
            this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== 'DLG'; });
            this.peplist = this.peplist.filter(function (item) { return item.id !== 'DL'; });
            this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== 'DL'; });
        }
        if (this.type == 'adverse') {
            this.peplist = this.peplist.filter(function (item) { return item.id !== 'PEP'; });
            this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== 'PEP'; });
            this.peplist = this.peplist.filter(function (item) { return item.id !== 'SAN'; });
            this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== 'SAN'; });
            this.peplist = this.peplist.filter(function (item) { return item.id !== 'INS'; });
            this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== 'INS'; });
            this.peplist = this.peplist.filter(function (item) { return item.id !== 'PEP'; });
            this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== 'PEP'; });
            this.peplist = this.peplist.filter(function (item) { return item.id !== 'POI'; });
            this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== 'POI'; });
            this.peplist = this.peplist.filter(function (item) { return item.id !== 'DD'; });
            this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== 'DD'; });
            this.peplist = this.peplist.filter(function (item) { return item.id !== 'DLG'; });
            this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== 'DLG'; });
            this.peplist = this.peplist.filter(function (item) { return item.id !== 'DL'; });
            this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== 'DL'; });
        }
    };
    return SearchpersonalamlComponent;
}());
exports.SearchpersonalamlComponent = SearchpersonalamlComponent;
