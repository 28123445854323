<div class="overviewsshow">
    <div class="header">
        <h1 class="headprofile mt-0">Visa</h1>
        <a (click)="openModal2('custom-visa-1')" class="addscreensuccess">+ Add Visa</a>
    </div>
    <div class="row">
        <div class="col-lg-4" *ngFor="let vs of visalistbyadmin">
            <div class="border-box">
                <div class="overview_details emp">
                    <div class="profile_dtailsshow visastaus mb-3">
                        <div class="detailsshow">
                        </div>
                        <div class="detailsshow">
                            <a (click)="openModalvisa3('pdf-image-viewer-visa',vs.visadocname)" *ngIf="vs.visadocname"><i class="fa fa-file-image-o mg-r-10" ></i>&nbsp;</a>
                            <a (click)="openModal('custom-visa-1',vs)" class=""><i class="lnr lnr-pencil mg-r-10"></i>&nbsp;</a>
                            <a (click)="onDelete(vs.employeevisadeatilid)" class="" *ngIf="vs.stype == '1' && vs.iscurrent == false"><i class="lnr lnr-trash"></i></a>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3">
                            <span>Visa Number</span>
                            <p>{{vs.visanumber}}</p>
                        </div>
                        <div class="detailsshow width50 pr-3">
                            <span>Visa Type</span>
                            <p>{{vs.visatype }}</p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3">
                            <span>Start Date</span>
                            <p>{{vs.visastart | date: 'dd MMM yyyy'}}</p>
                        </div>
                        <div class="detailsshow width50 pr-3">
                            <span>Expiry Date</span>
                            <p>{{vs.visaexpiry | date: 'dd MMM yyyy'}}</p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3">
                            <span>Visa issue country</span>
                            <p><span class="badge badge-danger" *ngIf="vs.nationality"> {{vs.nationality.countryname}} </span></p>
                        </div>
                        <div class="detailsshow width50 pr-3">
                        
                            <p class="badge badge-warning" *ngIf="vs.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Pending by AI </p>
                            <p class="badge badge-success" *ngIf="vs.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                            <p class="badge badge-error" *ngIf="vs.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                        </div>
                        
                    </div>
                    <!-- <div class="profile_dtailsshow">
                        <div class="detailsshow width100">
                        <p class="mb-0" *ngIf="aipayment ==false"> Verify the ID using Complygate AI. <a [routerLink]="['/employeeaidetail',vs.employeevisadeatilid]">Click Here</a></p>
                        <p class="mb-0" *ngIf="aipayment ==true"> You have exhausted the AI ID verification. To continue using it please <a [routerLink]="['/check-add-credit']">Click Here</a></p>                                                   
                        </div>
                        
                    </div> -->
                </div>
            </div>
        </div>
        <div class="col-lg-4 mt-4" *ngIf="!visalistbyadmin?.length > false">
            <p>No Record Found</p>
        </div>
    </div>
</div>


<jw-modal id="custom-visa-1" role="dialog" class="modal">
    <form [formGroup]="visapopForm" #visaForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(visaForm.value,'custom-visa-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="visaDetail.employeeid">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="visaDetail.employeevisadeatilid">
                    <h5 class="modal-title" id="popupLabel">Visa Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-visa-1');">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Visa No. *</label>
                                <input type="visanumber" formControlName="visanumber" class="" name="visanumber" id="visanumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.visanumber.errors }" [(ngModel)]="visaDetail.visanumber">
                                <div *ngIf="submitted && f.visanumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.visanumber.errors.required">Please Enter Visa No.</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Visa Type *</label>
                                <input type="visatype" formControlName="visatype" class="" name="visatype" id="visatype" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.visatype.errors }" [(ngModel)]="visaDetail.visatype">
                                <div *ngIf="submitted && f.visatype.errors" class="invalid-feedback">
                                    <div *ngIf="f.visatype.errors.required">Please Enter Visa Type </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Start Date *</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker1" name="visastart" id="visastart" [ngClass]="{ 'is-invalid': submitted && f.visastart.errors }" formControlName="visastart" class="form-control" [(ngModel)]="visaDetail.visastart">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.visastart.errors" class="invalid-feedback">
                                    <div *ngIf="f.visastart.errors.required">Please Choose Start Date</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Expiry Date *</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker2" id="visaexpiry" [ngClass]="{ 'is-invalid': submitted && f.visaexpiry.errors }" name="visaexpiry" formControlName="visaexpiry" [min]="visapopForm.controls.visastart.value" class="form-control  datecontrol" [(ngModel)]="visaDetail.visaexpiry">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.visaexpiry.errors" class="invalid-feedback">
                                    <div *ngIf="f.visaexpiry.errors.required">Please Choose Expiry Date</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Visa Issue Country *</label>
                                <select style="width:100%;" formControlName="nationalityid" name="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [(ngModel)]="visaDetail.nationalityid" id="nationalityid">
                                    <option [value]="defaultVal">Select Visa Country </option>
                                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                    <div *ngIf="f.nationalityid.errors.required">Please Select Visa Issue Country</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label>Front Side Visa Image Upload*</label>
                                <div class="input-group mb-3" (click)="filevisa.click()">
                                    <div class="custom-file">
                                        <input type="file" #filevisa (change)="selectVisaFileupload($event)">
                                        <label class="custom-file-label">{{filevisaname}}</label>
                                    </div>
                                </div>
                                <div class="invalid-feedback" *ngIf="filevisareuirederror == true">Please select Front Side Visa</div>
                                <div class="invalid-feedback" *ngIf="filevisasizeerror == true">File size must be less than 2 MB</div>
                                <div class="invalid-feedback" *ngIf="filevisaformaterror == true">Please choose a valid file format (jpg, png, tif, gif, pdf, doc)</div>
                                <!-- <webcam></webcam> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <label>Back Side Visa Image Upload</label>
                                <div class="input-group mb-3" (click)="filevisaback.click()">
                                    <div class="custom-file">
                                        <input type="file" #filevisaback (change)="selectvisabackFileupload($event)">
                                        <label class="custom-file-label">{{filevisabackname}}</label>
                                    </div>
                                </div>
                                <div class="invalid-feedback" *ngIf="filebackvisareuirederror == true">Please select Back Side Visa</div>
                                <div class="invalid-feedback" *ngIf="filevisabacksizeerror == true">File size must be less than 2 MB</div>
                                <div class="invalid-feedback" *ngIf="filevisabackformaterror == true">Please choose a valid file format (jpg, png)</div>
                                <!-- <webcam></webcam>
                                <div class="snapshot" *ngIf="webcamImage">
                                    <img [src]="webcamImage.imageAsDataUrl" />
                                </div> -->
                            </div>
                            <div class="form-group aligncenter col-lg-6">
                                <div class="mt-3">
                                    Is this your Current Visa?
                                    <input type="checkbox" [checked]="visaDetail.iscurrent" formControlName="iscurrent" name="iscurrent" [(ngModel)]="visaDetail.iscurrent">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-dark com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-visa-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>

<jw-modal id="pdf-image-viewer-visa" class="modal" role="dialog">
  <div role="document" class="formgroup">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-visa');">
            <span aria-hidden="true">&times;</span>
          </button>
          </div>
          <div class="modal-body">
              <div class="container">
                  <div class="row">
                      <div class="col-lg-12">
                          <iframe *ngIf="imgvisasrc == false" [src]="iframevisasrc" width="100%" height="600" frameborder="0"></iframe>
                          <img *ngIf="imgvisasrc == true" [src]="iframevisasrc" width="100%" />
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</jw-modal>


<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">         
            <div class="modal-body">
                <div class="container text-center">
                  <div class="info_mess">
                      <div class="para">
                              <h4 class="mb-2">No credits available</h4>
                              <p class="mb-0 font20">There are no credits available to perform this action. Please add more credits.</p>
  
                          </div>                          
                      </div>
                </div>
            </div>
            <div class="modal-footer text-center">
              <input type="button" class="btn btn-primary com_btn" name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
              <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
                <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
            </div>
        </div>
    </div>
  </jw-modal>