"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var app_global_1 = require("../../../../../app.global");
var platform_browser_1 = require("@angular/platform-browser");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var visa_model_1 = require("../../../../../models/visa/visa.model");
var employee_model_1 = require("../../../../../models/employee.model");
var myvisa_service_1 = require("../../../../../services/myvisa.service");
var common_service_1 = require("../../../../../services/common.service");
var aicenter_service_1 = require("../../../../../services/aicenter.service");
var alert_service_1 = require("../../../../../services/alert.service");
var checksystem_service_1 = require("../../../../../services/checksystem.service");
// import { Observable } from 'rxjs';
var Rx_1 = require("rxjs/Rx");
var EmployeeaipaymentComponent = /** @class */ (function () {
    function EmployeeaipaymentComponent(sanitizer, formBuilder, visaService, aicenter, activatedRoute, check, common, alerts, router) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.visaService = visaService;
        this.aicenter = aicenter;
        this.activatedRoute = activatedRoute;
        this.check = check;
        this.common = common;
        this.alerts = alerts;
        this.router = router;
        this.visadetails = new visa_model_1.Visa();
        this.employee = new employee_model_1.Employee();
        this.aipayment = false;
    }
    EmployeeaipaymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ispayment = true;
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.requestperm = this.permission.m15;
        this.ctype = parseInt(localStorage.getItem('ctype'));
        this.employeevisadeatilid = this.activatedRoute.snapshot.paramMap.get("visaid");
        this.data3 = { "employeevisadeatilid": this.employeevisadeatilid };
        this.visaService.visadetailsbyvisaid(this.data3).subscribe(function (data) {
            _this.logoimage2 = data.visadocname;
            _this.logoimage4 = data.visabackdocname;
            _this.logoimage3 = data.visaprofileimage;
            _this.employeemasterid = data.employee.employeemasterid;
            _this.visadetails = data;
            _this.record = data.length;
        });
        this.check.getbillinginformation({ 'serviceid': 7 }).subscribe(function (remaindata) {
            _this.remainingcheck = remaindata.remiancheck;
        });
        // this.aicenter.getcountpayment().subscribe((data: any) => {     
        //   this.aipayment = data.payment;
        // });
        this.data4 = { "employeid": this.employeemasterid };
        this.visaService.visaaipaymentstaus(this.data4).subscribe(function (data5) {
            var ct = data5.count;
            if (ct == 0) {
                _this.ispayment = false;
            }
            else {
                _this.ispayment = true;
            }
        });
    };
    EmployeeaipaymentComponent.prototype.Sendvisa = function () {
        var _this = this;
        this.data3 = { "employeevisadeatilid": this.employeevisadeatilid };
        this.visaService.visadetailsbyvisaid(this.data3).subscribe(function (data) {
            var countryiso = data.nationality.isothree;
            _this.submitted = true;
            var idtype = 'VISA'; //data.documenttype.documentcode;     
            var visalog = {
                employeeid: _this.employeemasterid,
                documentid: _this.employeevisadeatilid,
                documenturl: data.visadocname,
                documenttype: 'v'
            };
            _this.visaService.visaailogupdate(visalog).subscribe(function (dbvisaresult) {
                if (dbvisaresult.success == '1') {
                    var data = {
                        merchantIdScanReference: dbvisaresult.logid,
                        country: countryiso,
                        idType: idtype,
                        customerId: _this.employeemasterid,
                        callbackUrl: app_global_1.AppGlobals.API_ENDPOINT + "/api/aiconfirmation",
                        documenturl: data.visadocname,
                        documentbackurl: data.visabackdocname,
                        imageurl: data.visaprofileimage
                    };
                    _this.visaService.visadocumentupdate(data).subscribe(function (data5) {
                        if (data5.success == true) {
                            _this.router.navigate(['/employeeaithankyou/' + _this.employeevisadeatilid]);
                        }
                        else {
                            _this.alerts.error("something went wrong please try again");
                        }
                    });
                }
            });
        });
    };
    EmployeeaipaymentComponent.prototype.getBase64ImageFromURL = function (url) {
        var _this = this;
        return Rx_1.Observable.create(function (observer) {
            var img = new Image();
            img.crossOrigin = 'https://complygate.s3.amazonaws.com';
            img.src = url;
            img.src = url;
            if (!img.complete) {
                img.onload = function () {
                    observer.next(_this.getBase64Image(img));
                    observer.complete();
                };
                img.onerror = function (err) {
                    observer.error(err);
                };
            }
            else {
                observer.next(_this.getBase64Image(img));
                observer.complete();
            }
        });
    };
    EmployeeaipaymentComponent.prototype.getBase64Image = function (img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/jpg");
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    };
    /************ AI Required Base64 bit  ***********/
    EmployeeaipaymentComponent.prototype.transform = function (html) {
        // console.log(html);
        return this.sanitizer.bypassSecurityTrustResourceUrl(html);
    };
    EmployeeaipaymentComponent.prototype.getBase64ImageFromUrl = function (imageUrl) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var res, blob;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(imageUrl)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.blob()];
                    case 2:
                        blob = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                var reader = new FileReader();
                                reader.addEventListener("load", function () {
                                    resolve(reader.result);
                                }, false);
                                reader.onerror = function () {
                                    return reject(_this);
                                };
                                reader.readAsDataURL(blob);
                            })];
                }
            });
        });
    };
    EmployeeaipaymentComponent.prototype.closeModal = function (id) {
    };
    return EmployeeaipaymentComponent;
}());
exports.EmployeeaipaymentComponent = EmployeeaipaymentComponent;
