"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var FileuploadService = /** @class */ (function () {
    function FileuploadService(commonService) {
        this.commonService = commonService;
    }
    FileuploadService.prototype.fileuploadbynode = function (value) {
        // console.log(value);
        return this.commonService.formpost('/fileupload', value);
    };
    FileuploadService.ngInjectableDef = i0.defineInjectable({ factory: function FileuploadService_Factory() { return new FileuploadService(i0.inject(i1.CommonService)); }, token: FileuploadService, providedIn: "root" });
    return FileuploadService;
}());
exports.FileuploadService = FileuploadService;
