import { Component, OnInit } from '@angular/core';
import { CandidateService } from '../../../../services/candidate.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../services/alert.service';
import { candidatenotes } from '../../../../models/jobs/candidate.model';

@Component({
  selector: 'app-candnote',
  templateUrl: './candnote.component.html',
  styleUrls: ['./candnote.component.css']
})
export class CandnoteComponent implements OnInit {
  candidatenoteFormGroup: FormGroup;
  candidateid: any;
  jobid:any;
  Userid:any;
  candidatenotes: any;
  notes:any;
  submitted:any;
  isadmin: any=false;
  candidatenotesdetails = new candidatenotes();

  constructor( private formBuilder: FormBuilder, private candidate: CandidateService,
    private alerts: AlertService, private activatedRoute: ActivatedRoute) {
      activatedRoute.params.subscribe(params => {
        this.Userid = localStorage.getItem('userid');
        this.isadmin = localStorage.getItem('isadmin');
        // console.log(localStorage.getItem('isadmin'));
        this.jobid = params['jobid'];
        if(params['candidateid']){
          this.candidateid = params['candidateid'];
        }
        else if(params['id']) {
          this.candidateid = params['id'];
        }       
        var db = { 'candidateid': this.candidateid }
        this.candidate.getcandidatenotelist(db).subscribe((candidatenote: any) => {
          this.candidatenotes = candidatenote;
        });   
        this.candidatenotesdetails.employeeid = this.Userid;
        this.candidatenotesdetails.candidateid = this.candidateid;
      }); 
     }

  ngOnInit() {

    // this.jobid = this.activatedRoute.snapshot.paramMap.get("jobid");
    // this.candidateid = this.activatedRoute.snapshot.paramMap.get("candidateid");
    // this.Userid = localStorage.getItem('userid');
    this.candidatenoteFormGroup = this.formBuilder.group({
      candidateid: ['', Validators.required],
      employeeid: ['', Validators.required],
      notes: ['', Validators.required]
    });
  }
  
  get j() { return this.candidatenoteFormGroup.controls; }


  updatecandidatenote(values: any){
    this.submitted = true;
    if (this.candidatenoteFormGroup.invalid) {
      return;
    }
    this.candidate.createcandidatenote(values).subscribe((candidatenote: any) => {
      this.notes ='';
      var db = { 'candidateid': this.candidateid }
      this.candidate.getcandidatenotelist(db).subscribe((candidatenotem: any) => {
        this.candidatenotes = candidatenotem;
        this.submitted = false;
        this.candidatenotesdetails = new candidatenotes();
        this.candidatenotesdetails.notes = '';//this.Userid;
        this.candidatenotesdetails.employeeid = this.Userid;
        this.candidatenotesdetails.candidateid = this.candidateid;      
      });
    });
  }

  deleteCandidateNote(note_id: any){
    var deleteData = {};
    deleteData = { 'id': note_id, 'employeeid': this.candidateid };
    if (confirm("Are you sure you want to delete?")) {
      this.candidate.deleteCandNote(deleteData).subscribe((candidatenote: any) => {
        this.notes ='';
        var db = { 'candidateid': this.candidateid }
        this.candidate.getcandidatenotelist(db).subscribe((candidatenotem: any) => {
          this.candidatenotes = candidatenotem;
          this.alerts.success("Note deleted successfully")
        });
      });
    }
  }

}
