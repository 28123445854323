import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { Visa } from '../../../models/visa/visa.model';
import { Aml,AmlBussiness } from '../../../models/aml/aml.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AmlService } from '../../../services/aml.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-businesscredit',
  templateUrl: './businesscredit.component.html',
  styleUrls: ['./businesscredit.component.css']
})

export class BusinesscreditComponent implements OnInit {
  
  disableBtn: any;
  buttoninsert: any;
  submitted: boolean;  
  bussinessformGroup: FormGroup;
  businessDetail =new AmlBussiness();
  searchresult: any;
  result: boolean;
  defaultVal: string ='';
  countrylist:any;
  datasetlist: any;
  bussinessdetails:any;

  constructor(
    private modalService: ModalService, 
    private formBuilder: FormBuilder,
    private amldetails: AmlService,    
    private router: Router
  ) { }

  ngOnInit() {

    
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    

    this.buttoninsert ="Search";
    this.result =false;
    this.defaultVal ="";
    this.bussinessformGroup = this.formBuilder.group({
      BusinessName: ['', Validators.required],
      Country: ['', Validators.required],
      County: [''],        
      Threshold: [''],   
      City: [''] ,
      Postcode: [''],
      Address: [''],      
      PEP: [''],
      PreviousSanctions: [''],
      CurrentSanctions: [''],
      LawEnforcement: [''],
      FinancialRegulator: [''],
      Insolvency: [''],
      DisqualifiedDirector: [''],
      AdverseMedia: ['']
    });
    this.businessDetail =new AmlBussiness();
    
    this.amldetails.getCountries().subscribe((countrydata: any) => { 
      this.countrylist = countrydata;
      this.businessDetail.Country ="";
    });
  }
  get f() { return this.bussinessformGroup.controls; }


  
clearpersonal(){
  
  this.bussinessformGroup.reset();
  this.businessDetail =new AmlBussiness();

  this.businessDetail.BusinessName = "";
  this.businessDetail.Country = "";
  
  this.businessDetail.Threshold = "";
  
  this.businessDetail.Postcode = "";
  this.businessDetail.Address = "";
  
  this.businessDetail.County = "";
  this.businessDetail.City = "";
  this.businessDetail.PEP = false;
  this.businessDetail.CurrentSanctions = false;
  this.businessDetail.DisqualifiedDirector =false;
  this.businessDetail.FinancialRegulator =false;
  this.businessDetail.Insolvency =false;
  this.businessDetail.LawEnforcement= false;
  this.businessDetail.AdverseMedia =false;
  this.businessDetail.PreviousSanctions =false;


}

selectall(val){
  this.businessDetail.PEP = true;
  this.businessDetail.CurrentSanctions = true;
  this.businessDetail.DisqualifiedDirector =true;
  this.businessDetail.FinancialRegulator =true;
  this.businessDetail.Insolvency =true;
  this.businessDetail.LawEnforcement= true;
  this.businessDetail.AdverseMedia =true;
  this.businessDetail.PreviousSanctions =true;

}

  onSubmit(val)
  {
    this.submitted = true; 
    if (this.bussinessformGroup.invalid) {
      return;
    }

    // console.log(val);
    // var db= {};
    
    // db ={name: val.name, countries: val.countries};
      

    // if(val.houseNo != '')
    // {
    //   db['houseNo'] = val.houseNo;
    // }

    // if(val.postCode != '')
    // {
    //   db['postCode'] = val.postCode;
    // }

    // if(val.street != '')
    // {
    //   db['street'] = val.street;
    // }

    // if(val.city !=''){
    //   db['city'] = val.city;
    // }

    // console.log(db);

    // this.amldetails.getListofCompanyPreDefinedSearches().subscribe((searchRes: any) => {
      
    //   console.log(searchRes)
    
    // });
    
  this.disableBtn =false;
    this.amldetails.getCompanySearch(val).subscribe((searchRes: any) => { 
      // console.log(searchRes);
      if(searchRes.recordsFound >0){
        this.result =true;
      }

      this.disableBtn =true;
    // localStorage.setItem('personalid', searchRes.id);    
    this.router.navigate(['/buscreditsearchresult', searchRes.id]); 

      this.searchresult = searchRes;
    });

    
  }

  dataset(values){
    
    this.datasetlist ="";

    if(values.isPEP == true)
    {
      this.datasetlist = "PEP,";
    }

    if(values.isAdverseMedia == true)
    {
      this.datasetlist += " Adverse Media,";
    }

    if(values.isDisqualifiedDirector == true)
    {
      this.datasetlist += " Disqualified Director (UK Only),";
    }

    if(values.isFinancialregulator == true)
    {
      this.datasetlist += " Financial Regulator,";
    }

    if(values.isInsolvent == true)
    {
      this.datasetlist += " Insolvency (UK & Ireland),";
    }

    if(values.isLawEnforcement == true)
    {
      this.datasetlist += " Law Enforcement,";
    }

    if(values.isSanctionsCurrent == true)
    {
      this.datasetlist += " Sanction - Current,";
    }
    if(values.isSanctionsPrevious == true)
    {
      this.datasetlist += " Sanction - Previous,";
    }

    this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1);
    

  }


  openModal(id: string, data: any) {
    
    this.bussinessdetails = data;
   // console.log(data);
    this.buttoninsert = "Update";
    this.modalService.open(id);
  }
  closeModal(id: string) 
  {
      this.modalService.close(id);
  }
}