<!-- <div class="slim-header">
  <div class="container">
    <div class="slim-header-left">
      <h2 class="slim-logo"><a [routerLink]="['/login']">
          <img height="100" src="{{logo}}" alt="" />
          <span></span></a></h2>
     
    </div>
  </div>
</div> -->
<div class="signin-wrapper">
  <div class="signin-box" *ngIf="error == 'true'">
    <h2 class="slim-logo">Error</h2>
    <div>
      <p>The feature has been deactivated temporarily</p>
    </div>
  </div>
  <div class="signin-box" *ngIf="msg == 'true'"  style="border-radius: 20px;">
    <h2 class="slim-logo">Message</h2>
    <form [formGroup]="registerForm" #loginForm class="" (ngSubmit)="onSubmit()">
      <input type="hidden" formControlName="token" [(ngModel)]="user.token" id="token" name="token" />
      <div class="form-group mg-b-30">
        <div class="input-group">
          <textarea formControlName="message" #message [(ngModel)]="user.message" class="form-control" maxlength="200"
            [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
            placeholder="Please enter your message"></textarea>
          <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-mail"
                aria-hidden="true"></i></span> </div>
          <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
            <div *ngIf="f.message.errors.required">Please enter your Message</div>
          </div>
        </div>
      </div>
      <button class="btn btn-primary btn-block btn-signin" style="width:100%" type="submit">Send</button>
    </form>
  </div> 
  <div class="signin-box" *ngIf="success == '1'" style="border-radius: 20px;">
      <h2 class="slim-logo">Thank You</h2>
      <div>
        <p>Your response updated successfully. We will revert back to you.</p>
      </div>
    </div>
</div>

<style>
  .signin-box {
    width: 50%;
  }
</style>