"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ContactinformationComponent = /** @class */ (function () {
    function ContactinformationComponent() {
    }
    ContactinformationComponent.prototype.ngOnInit = function () {
    };
    return ContactinformationComponent;
}());
exports.ContactinformationComponent = ContactinformationComponent;
