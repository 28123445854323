"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var candidate_service_1 = require("../../../../services/candidate.service");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var alert_service_1 = require("../../../../services/alert.service");
var candidate_model_1 = require("../../../../models/jobs/candidate.model");
var CandnoteComponent = /** @class */ (function () {
    function CandnoteComponent(formBuilder, candidate, alerts, activatedRoute) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.candidate = candidate;
        this.alerts = alerts;
        this.activatedRoute = activatedRoute;
        this.isadmin = false;
        this.candidatenotesdetails = new candidate_model_1.candidatenotes();
        activatedRoute.params.subscribe(function (params) {
            _this.Userid = localStorage.getItem('userid');
            _this.isadmin = localStorage.getItem('isadmin');
            // console.log(localStorage.getItem('isadmin'));
            _this.jobid = params['jobid'];
            if (params['candidateid']) {
                _this.candidateid = params['candidateid'];
            }
            else if (params['id']) {
                _this.candidateid = params['id'];
            }
            var db = { 'candidateid': _this.candidateid };
            _this.candidate.getcandidatenotelist(db).subscribe(function (candidatenote) {
                _this.candidatenotes = candidatenote;
            });
            _this.candidatenotesdetails.employeeid = _this.Userid;
            _this.candidatenotesdetails.candidateid = _this.candidateid;
        });
    }
    CandnoteComponent.prototype.ngOnInit = function () {
        // this.jobid = this.activatedRoute.snapshot.paramMap.get("jobid");
        // this.candidateid = this.activatedRoute.snapshot.paramMap.get("candidateid");
        // this.Userid = localStorage.getItem('userid');
        this.candidatenoteFormGroup = this.formBuilder.group({
            candidateid: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            notes: ['', forms_1.Validators.required]
        });
    };
    Object.defineProperty(CandnoteComponent.prototype, "j", {
        get: function () { return this.candidatenoteFormGroup.controls; },
        enumerable: true,
        configurable: true
    });
    CandnoteComponent.prototype.updatecandidatenote = function (values) {
        var _this = this;
        this.submitted = true;
        if (this.candidatenoteFormGroup.invalid) {
            return;
        }
        this.candidate.createcandidatenote(values).subscribe(function (candidatenote) {
            _this.notes = '';
            var db = { 'candidateid': _this.candidateid };
            _this.candidate.getcandidatenotelist(db).subscribe(function (candidatenotem) {
                _this.candidatenotes = candidatenotem;
                _this.submitted = false;
                _this.candidatenotesdetails = new candidate_model_1.candidatenotes();
                _this.candidatenotesdetails.notes = ''; //this.Userid;
                _this.candidatenotesdetails.employeeid = _this.Userid;
                _this.candidatenotesdetails.candidateid = _this.candidateid;
            });
        });
    };
    CandnoteComponent.prototype.deleteCandidateNote = function (note_id) {
        var _this = this;
        var deleteData = {};
        deleteData = { 'id': note_id, 'employeeid': this.candidateid };
        if (confirm("Are you sure you want to delete?")) {
            this.candidate.deleteCandNote(deleteData).subscribe(function (candidatenote) {
                _this.notes = '';
                var db = { 'candidateid': _this.candidateid };
                _this.candidate.getcandidatenotelist(db).subscribe(function (candidatenotem) {
                    _this.candidatenotes = candidatenotem;
                    _this.alerts.success("Note deleted successfully");
                });
            });
        }
    };
    return CandnoteComponent;
}());
exports.CandnoteComponent = CandnoteComponent;
