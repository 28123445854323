import { int } from "aws-sdk/clients/datapipeline";

export class Aml {
   Forename: string;
   Middlename: string ='';
   Surname: string ='';    
   DateOfBirth: string ='';
   YearOfBirth: string ='';
   Address: string ='';
   PEP: boolean =false;    
   PreviousSanctions: boolean =false;    
   CurrentSanctions: boolean =false;    
   LawEnforcement: boolean =false;    
   FinancialRegulator: boolean =false;    
   Insolvency: boolean =false;    
   DisqualifiedDirector: boolean =false;    
   AdverseMedia: boolean =false;
   Threshold:any =20; 
   Country: string ='';
   County: string ='';    
   City: string ='';
   Postcode: string =''; 
   date:int;
   month:int;
   year:int;
 }

 export class AmlBussiness {
    BusinessName: string ='';
    Country: string ='';
    County: string ='';    
    City: string ='';
    Postcode: string ='';
    Address: string ='';
    PEP: boolean =false;    
    PreviousSanctions: boolean =false;    
    CurrentSanctions: boolean =false;    
    LawEnforcement: boolean =false;    
    FinancialRegulator: boolean =false;    
    Insolvency: boolean =false;    
    DisqualifiedDirector: boolean =false;    
    AdverseMedia: boolean =false;
    Threshold:any =20;
 }

 export class amldetailsclass
 {
   person: person;
   aliases: aliases;
   politicalPositions:politicalPositions;
   addresses:addresses;
   notes:notes;
 }

export class person
{
   Forename: string;
   Middlename: string ='';
   Surname: string ='';    
   DateOfBirth: string ='';
   YearOfBirth: string ='';
   Address: string ='';
   Threshold:any =20; 
   Country: string ='';
   County: string ='';    
   City: string ='';
   id:any='';
   Postcode: string =''; 
   imageURL:string ='';
}

export class aliases 
{
   Forename: string;
   Middlename: string ='';
   Surname: string ='';
}

export class addresses
{
   address1: string ='';
   address2: string ='';
   address3: string ='';
   address4: string ='';
   city: string ='';
   county: string ='';
   postcode: string ='';
   country:country;
}

export class country
{
   name:string ='';
}

export class politicalPositions
{
   description:string ='';
}

export class notes
{
   text: string ='';
}

export class adverst {
   title: string;
   Middlename: string ='';
   forename: string ='';    
   sirname: string ='';
   postcode: string ='';
   flatnumber: string ='';
 
   street: string ='';
   town: string ='';    
   locality: string ='';
 }