"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var contact_service_1 = require("../services/contact.service");
var modal_service_1 = require("../_services/modal.service");
var address_model_1 = require("../models/address/address.model");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var common_service_1 = require("./../services/common.service");
var employee_model_1 = require("../models/employee.model");
var country_service_1 = require("../services/country.service");
var forms_1 = require("@angular/forms");
var alert_service_1 = require("../services/alert.service");
var MycontactinfoComponent = /** @class */ (function () {
    function MycontactinfoComponent(commonService, sanitizer, formBuilder, data, country, modalService, alerts, router) {
        this.commonService = commonService;
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.data = data;
        this.country = country;
        this.modalService = modalService;
        this.alerts = alerts;
        this.router = router;
        this.address = new address_model_1.Address();
        this.options = [];
        this.defaultVal = "";
    }
    MycontactinfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.contactformGroup = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            kinname: [''],
            employeerelation: [''],
            workphone: ['', forms_1.Validators.required],
            mobile: [''],
            homephone: [''],
            workemail: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            personalemail: ['', forms_1.Validators.email],
            kinmobile: [''],
            kinemail: ['', forms_1.Validators.email],
            telephone: [''],
            kinaddress1: [''],
            kinaddress2: [''],
            kinpincode: [''],
            kincityname: [''],
            kincountyname: [''],
            kincountryid: [''],
        });
        this.onChanges();
        this.contactDetail = new employee_model_1.Contactinfo();
        this.filename = 'Choose file';
        this.Userid = localStorage.getItem('userid');
        var data = { 'userid': this.Userid, "employeeid": this.Userid };
        this.data.getContactInfo(data).subscribe(function (data) {
            _this.contactDetail = data;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
    };
    MycontactinfoComponent.prototype.onChangeDate = function (event) {
    };
    MycontactinfoComponent.prototype.onChanges = function () {
        var _this = this;
        this.formattedMessage = '';
        this.contactformGroup.valueChanges.subscribe(function (val) {
            _this.formattedMessage = 'Hello, My name is and my email is. I would like to tell you that.';
        });
    };
    Object.defineProperty(MycontactinfoComponent.prototype, "f", {
        get: function () { return this.contactformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    MycontactinfoComponent.prototype.onSubmit = function (value3, id) {
        var _this = this;
        this.submitted = true;
        if (this.contactformGroup.invalid) {
            return;
        }
        if (value3.kinpincode != '' && value3.kinpincode != undefined) {
            var pinc = value3.kinpincode.split(',');
            value3.kinpincode = pinc[pinc.length - 1];
        }
        value3.userid = this.Userid;
        this.data.employeeContactinfoUpdate(value3).subscribe(function (data) {
            var data1 = { 'userid': _this.Userid, "employeeid": _this.Userid };
            _this.data.getContactInfo(data1).subscribe(function (data) {
                return _this.contactDetail = data;
            });
        });
        this.messageSuccess = 'Contact info updated successfully';
        this.alerts.success(this.messageSuccess, false);
        this.modalService.close(id);
    };
    MycontactinfoComponent.prototype.selectFile = function (event) {
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
    };
    MycontactinfoComponent.prototype.onSearchChange = function (searchValue) {
        var _this = this;
        if (searchValue.length > 4) {
            var newArray = [];
            this.commonService.getAddress(searchValue).subscribe(function (data) {
                var newdata;
                newdata = data;
                newdata.addresses.forEach(function (value) {
                    newArray.push(value + "," + searchValue);
                });
                _this.options = newArray;
            });
        }
        else {
            this.options = [];
        }
    };
    MycontactinfoComponent.prototype.displayFn = function (value) {
        if (value != null) {
            var res = value.split(",");
            if (res.length > 6) {
                return res[7];
            }
            else {
                return value;
            }
        }
    };
    MycontactinfoComponent.prototype.updatecode = function (evt, response) {
        res = [];
        if (evt.source.selected) {
            var res = response.split(",");
            this.contactDetail.kinaddress1 = res[0];
            this.contactDetail.kinaddress2 = res[1];
            this.contactDetail.kincityname = res[5];
            this.contactDetail.kinpincode = res[7];
            this.contactDetail.kincountyname = res[6];
        }
    };
    MycontactinfoComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'workphone') {
                _this.workphonecheck = getdata.valid;
            }
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'homephone') {
                _this.homephonecheck = getdata.valid;
            }
        });
    };
    MycontactinfoComponent.prototype.onDelete = function (empid) {
        if (confirm("Are you sure you want to delete?")) {
            var data1 = { 'id': empid };
        }
    };
    MycontactinfoComponent.prototype.openModal2_kin = function (id) {
        this.submitted = false;
        this.contactDetail = new employee_model_1.Contactinfo();
        this.buttoninsert = "Submit";
        this.contactDetail.employeeid = this.Userid;
        this.contactDetail.id = "0";
        this.contactDetail.kincountryid = "70";
        this.modalService.open(id);
    };
    MycontactinfoComponent.prototype.openModal_kin = function (id, data) {
        this.workphonecheck = 'noval';
        this.mobilecheck = 'noval';
        this.homephonecheck = 'noval';
        this.contactDetail = new employee_model_1.Contactinfo();
        this.buttoninsert = "Update";
        // this.data.getContactInfobyadmin(data).subscribe((data: any) => {
        this.contactDetail = data;
        // });
        if (this.contactDetail.kincountryid = '0') {
            this.contactDetail.kincountryid = '';
        }
        this.modalService.open(id);
    };
    MycontactinfoComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    MycontactinfoComponent.prototype.openModal3 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    MycontactinfoComponent.prototype.closeModal = function (id) {
        var _this = this;
        // this.passportDetail = new Passport();
        var data = { 'userid': this.Userid, "employeeid": this.Userid };
        this.data.getContactInfo(data).subscribe(function (data) {
            _this.contactDetail = data;
        });
        this.modalService.close(id);
    };
    MycontactinfoComponent.prototype.modalOpen = function (data) {
        // this.passportDetail = new Passport();
        // this.passportDetail = data;
    };
    return MycontactinfoComponent;
}());
exports.MycontactinfoComponent = MycontactinfoComponent;
