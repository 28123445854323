<div class="overviewsshow">
    <div class="header">
        <h1 class="headprofile mt-0">Passport</h1>
        <a (click)="openModal2('custom-passport-1')" class="addscreensuccess">Add Passport</a>
    </div>
    <div class="row">
        <div *ngFor="let filterItem of listcheck" class="form-check col-lg-3" style="padding-right:20px;">
            <label class="form-check-label">
                <input type="radio" [checked]="filterItem.selected" (change)="onlistchange($event,filterItem.id)" 
                    value="filterItem.id"> {{ filterItem.name }}
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6" *ngFor="let ps of passportlist">
            <div class="border-box">
                <div class="overview_details emp">
                    <div class="profile_dtailsshow visastaus mb-3">
                        <div class="detailsshow">
                             <span class="badge badge-success inlinebb mg-r-10" *ngIf="ps.iscurrent == true"><i class="fa fa-check" aria-hidden="true"></i> Current</span>
                            <span class="badge badge-danger inlinebb" *ngIf="ps.cstatus == '3'"><i class="fa fa-times" aria-hidden="true"></i> Rejected</span>
                            <span class="badge badge-warning inlinebb" *ngIf="ps.cstatus == '1'"><i class="fa fa-hourglass-end" aria-hidden="true"></i> Pending</span>
                            <span class="badge badge-success inlinebb" *ngIf="ps.cstatus == '2'"><i class="fa fa-check" aria-hidden="true"></i> Approved</span>
                        </div>
                        <div class="icons_box_section">
                            <a (click)="openModalpassportview('pdf-image-viewer-passport',ps.passportdocname, ps.passportbackdocname)" 
                                *ngIf="ps.passportdocname || ps.passportbackdocname" class="editdark">
                                <i class="fa fa-file-image-o"></i></a>
                            <a (click)="openModal('custom-passport-1',ps)" class="editprime"><i class="lnr lnr-pencil "></i></a>
                            <a *ngIf="ps.cstatus == '1' && ps.iscurrent == false" (click)="onDelete(ps.employeepassdeatilid)" class="deletered"
                                class=""><i class="lnr lnr-trash"></i>
                            </a>
                       </div>
                    </div>
                    <div class="profile_dtailsshow"> 
                        <div class="detailsshow width50 pr-3">
                            <span>Passport No.</span>
                            <p>{{ps.passportnumber}}</p>
                        </div> 
                        <div class="detailsshow width50 pr-3">
                            <span>Issue Date</span>
                            <p>{{ps.passportissue | date: 'dd MMM yyyy'}}</p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3">
                            <span>Expiry Date</span>
                            <p>{{ps.passportexpiry | date: 'dd MMM yyyy'}}</p>
                        </div>
                        <div class="detailsshow width50 pr-3">
                            <span>Place of Issue</span>
                            <p>{{ps.placeofissue}} </p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3">
                            <span>Nationality</span>
                            <p class="badge badge-danger"> {{ps.nationality.countryname }}</p>
                        </div>
                        <div class="detailsshow width50 pr-3">
                            <p class="badge badge-warning" *ngIf="ps.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Pending by AI </p>
                            <p class="badge badge-success" *ngIf="ps.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                            <p class="badge badge-error" *ngIf="ps.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width100 pr-3">
                            <p>Verify the ID using Complygate AI. <a [routerLink]="['/passportaidetails',ps.employeepassdeatilid]">Click Here</a></p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="job_list_section visa_request_section" *ngIf="!passportlist?.length > false">
        <div class="job_detils_date">
            <p class="job_profile">No Record Found</p>
        </div>
    </div>      
</div>

<jw-modal id="custom-passport-1" class="modal" role="dialog">
    <form [formGroup]="passformGroup" #passportForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(passportForm.value,'custom-passport-1')" *ngIf="passportDetail">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="passportDetail.employeepassdeatilid">
                    <input type="hidden" formControlName="employeeid" class="form-control" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="passportDetail.employeeid">
                    <h5 class="modal-title" id="popupLabel">Passport Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-passport-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Passport No.*</label>
                                <input type="passportnumber" formControlName="passportnumber" name="passportnumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.passportnumber.errors }" [(ngModel)]="passportDetail.passportnumber" id="passportnumber">
                                <div *ngIf="submitted && f.passportnumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.passportnumber.errors.required">Please enter passport number</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Nationality*</label>
                                <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [(ngModel)]="passportDetail.nationalityid">
                  <option [value]="defaultVal">Select Nationality</option>
                  <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                </select>
                                <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                    <div *ngIf="f.nationalityid.errors.required">Please select nationality</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="form-group col-lg-6">
                                <label>Passport Issue Date*</label>
                                <mat-form-field class="datecontrol" [ngClass]="{ 'is-invalid': submitted && f.passportissue.errors }">
                                    <input matInput [matDatepicker]="picker1" name="passportissue" id="passportissue" formControlName="passportissue" class="form-control" [(ngModel)]="passportDetail.passportissue">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.passportissue.errors" class="invalid-feedback">
                                    <div *ngIf="f.passportissue.errors.required">Please choose passport issue date</div>
                                </div>
                            </div>

                            <div class="form-group col-lg-6">
                                <label>Passport Expiry Date*</label>
                                <mat-form-field class="datecontrol" [ngClass]="{ 'is-invalid': submitted && f.passportexpiry.errors }">
                                    <input matInput [matDatepicker]="picker2" id="passportexpiry" class="form-control" formControlName="passportexpiry" name="passportexpiry" [min]="passformGroup.controls.passportissue.value" [(ngModel)]="passportDetail.passportexpiry">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.passportexpiry.errors" class="invalid-feedback">
                                    <div *ngIf="f.passportexpiry.errors.required">Please choose passport expiry date</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Place of Issue of Passport*</label>
                                <input type="placeofissue" formControlName="placeofissue" id="placeofissue" name="placeofissue" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.placeofissue.errors }" [(ngModel)]="passportDetail.placeofissue">
                                <div *ngIf="submitted && f.placeofissue.errors" class="invalid-feedback">
                                    <div *ngIf="f.placeofissue.errors.required">Please enter place of issue</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Front Side Passport Upload*</label>
                                <div class="input-group mb-3">
                                    <div class="custom-file" (click)="file.click()">
                                        <input type="file" #file (change)="selectFile($event)">
                                        <label class="custom-file-label">{{filename}}</label>
                                    </div>
                                </div>
                                <div class="invalid-feedback" *ngIf="filereuirederror == true">Please select front side passport</div>
                                <div class="invalid-feedback" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                <div class="invalid-feedback" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png, tif, gif, pdf, doc)
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <label>Back Side Passport Upload</label>
                                <div class="input-group" (click)="fileback.click()">
                                    <div class="custom-file">
                                        <input type="file" #fileback (change)="selectbackFile($event)">
                                        <label class="custom-file-label">{{filebackname}}</label>
                                    </div>
                                </div>
                                <div class="invalid-feedback" *ngIf="filebacksizeerror == true">File size must be less than 2 MB</div>
                                <div class="invalid-feedback" *ngIf="filebackformaterror == true">Please choose a valid file format (jpg, png)</div>

                            </div>
                            <!-- <div class="form-group col-lg-6 col-lg-6 d-flex align-items-center mt-3">
                                <label class="visaempcheck mt-4"> Is this your Current Passport?
                                    <input type="checkbox" [checked]="passportDetail.iscurrent" formControlName="iscurrent" name="iscurrent" [(ngModel)]="passportDetail.iscurrent"  >
                                    <span class="checkmark"></span>
                                  </label>
                                <div>
                                </div>
                            </div> -->
                             <div class="form-group col-lg-6">
                                <label>Current Passport?</label>
                                <div class="input-group mt-2">
                                    <label class="visapass mr-3 mb-0 d-flex align-items-center">Yes
                                        <input class="passs" type="radio" value="1" formControlName="iscurrent" name="iscurrent" [ngModel]="passportDetail.iscurrent" />
                                        <span class="checkmark"></span> 
                                      </label> 
                                      <label class="visapass mb-0 d-flex align-items-center">No
                                        <input class="passs" type="radio" value="0" formControlName="iscurrent" name="iscurrent" [ngModel]="passportDetail.iscurrent" />
                                        <span class="checkmark"></span>
                                      </label>
                                </div>
                              
                                  
                                    
                                    <!-- <input type="checkbox" [checked]="passportDetail.iscurrent" formControlName="iscurrent" name="iscurrent" [(ngModel)]="passportDetail.iscurrent" /> -->
                                    <!-- Yes <input class="passs" type="radio" value="1" formControlName="iscurrent" name="iscurrent" [ngModel]="passportDetail.iscurrent" />
                                    No <input class="passs" type="radio" value="0" formControlName="iscurrent" name="iscurrent" [ngModel]="passportDetail.iscurrent" /> -->
                               
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-passport-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>



<jw-modal id="pdf-image-viewer-passport" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-passport');">
              <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
                <div class="container">
                         <div class="row">
                        <div class="twoboximg"  [ngClass]="!iframepassportbacksrc ?'col-lg-12':'col-lg-6'">
                            <div class="overlaytext">
                                <p>Front Image</p>
                            </div>
                             <iframe *ngIf="imgpassportsrc == false" [src]="iframepassportsrc" width="100%" height="600" frameborder="0"></iframe>
                            <img *ngIf="imgpassportsrc == true" [src]="iframepassportsrc" width="100%" />
                        </div>
                        <div class="col-lg-6 twoboximg" *ngIf="iframepassportbacksrc" >
                            <div class="overlaytext">
                                <p>Back Image</p> 
                            </div>
                            <iframe *ngIf="imgpassportbacksrc == false" [src]="iframepassportbacksrc" width="100%" height="250" frameborder="0"></iframe>
                            <img *ngIf="imgpassportbacksrc == true" [src]="iframepassportbacksrc" width="100%" />
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-lg-12">

                            <iframe *ngIf="imgpassportsrc == false" [src]="iframepassportsrc" width="100%" height="600" frameborder="0"></iframe>
                            <img *ngIf="imgpassportsrc == true" [src]="iframepassportsrc" width="100%" />
                        </div>
                    </div> -->

                </div>
            </div>

        </div>
    </div>
</jw-modal>
