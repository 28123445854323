<app-header>
</app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">

           <a style="cursor: pointer;" (click)="amllogredirect()" class="backlist mb-4 mr-0"><i aria-hidden="true" class="fa fa-angle-left"></i> {{backtext}} </a>

            <div class="clearfix"></div>
            <div class="tabs__content">

                <div class="tabs__content__item active-tab" *ngIf="!noresult">
                    <div class="content">
                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Business Details </h5>
                                <!-- <a href="{{ url }}" target="_blank" >Download</a> -->
                            </div>

                            <div class="col-lg-6">
                                <a type="button" (click)="loaddatasystem()" style="color: #fff;" [innerHtml]="downloadtext" target="_blank" class="btn btn-primary com_btn float-right" >No Record Found <i class="fa fa-Plus ml-2"></i></a>
                                <!-- <a type="button" href="{{ url }}" *ngIf="url"   target="_blank" class="btn btn-primary com_btn float-right" >Download <i class="fa fa-file-pdf-o ml-2"></i></a> -->
                             </div>
                        </div>


                        <div class="job_tab"  *ngIf="bussinessdetails">
                            <mat-tab-group>
                                <mat-tab label="Details">
                                    <ng-template matTabContent>
                                        <div class="strip_show mt-3"  *ngIf="bussinessdetails">
                                            <div class="row mr-0 ml-0">
                                           
                                                <!-- <div class="col-lg-2 mobilecentersh">
                                                    <div class="circle_credit_img">
                                                        <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                                                    </div>
                                                   
                                                    
                                                </div> -->
                                                <div class="col-lg-11 d-flex align-items-center ">
                                                    <div class="category flex-wrap justify-content-start align-items-center text-center d-flex width100">


                                                        <div class="catog_box" *ngFor="let dbset of resultevidancelist">
                                                            <p>{{dbset.name}}</p>
                                                            <span>{{dbset.values.length}}</span>
                                                        </div>
                                                    </div>
                        
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="row mr-0 ml-0">
                                                
                                               
                                                <div class="col-sm-12">
                        
                                               
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                        <div class="job_detils_date width70">
                                                            <p class="job_address">Business Name</p>   
                                                                <h5 class="job_profile">{{bussinessdetails.name}}</h5>
                                                                <p class="job_profile" *ngIf="!bussinessdetails.name"> N/A</p>
                                                            
                                                        
                                                        </div>
                                                       
                                                       
                                                        
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    

                                                        <div class="job_detils_date width30"  *ngFor="let dbcon of bussinessdetails.contactEntries">
                                                            <p class="job_address">{{dbcon.category}}</p>                           
                                                            <p class="job_profile">{{dbcon.value}} </p>
                                                            <!-- <p class="job_profile" *ngIf="!bussinessdetails.person.telephoneNumber"> N/A</p> -->
                                                        </div> 
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                       
                                                        <div class="job_detils_date width30">
                                                            <p class="job_address">Address(es)   </p>                           
                                                            <p class="job_profile"  *ngFor="let companyaddress of bussinessdetails.addresses;"><b>{{companyaddress.addressType}} - </b> {{companyaddress.line1}} {{companyaddress.line2}} , {{ companyaddress.city }} {{ companyaddress.county }} {{ companyaddress.countryIsoCode }}- {{ companyaddress.postcode}}</p>
                                                            <p class="job_profile" *ngIf="bussinessdetails.addresses == 0"> N/A</p>
                                                        </div>


                                                   <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                        <div class="job_detils_date width100">
                                                            <p class="job_address">Alias </p>                           
                                                            <p class="job_profile" >
                                                                <span class="aliesname" *ngFor="let companyaliases of bussinessdetails.aliases;"><b>{{companyaliases.type}} - </b>   {{companyaliases.alias}}</span>
                                                             </p>
                                                            <p class="job_profile" *ngIf="bussinessdetails.aliases == 0">
                                                                <span class="aliesname">N/A</span>
                                                            </p>
                                                        </div>                                                    
                                                    </div>


                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                        <div class="job_detils_date width100">
                                                            <p class="job_address">Note </p>                           
                                                            <p class="job_profile">
                        
                                                                <span>
                                                                    <div *ngFor="let personnotes of bussinessdetails.notes;">
                                                                <ul class="note">
                                                                    <li *ngIf="personnotes.value">{{ personnotes.value}}</li>
                                                                    
                                                                </ul>
                                                                </div>
                                                                <p class="job_profile" *ngIf="bussinessdetails.notes == 0"> N/A</p>
                                                            </span>
                        
                                                            </p>
                                                        </div>
                                                    
                                                    </div>
                                                   
                                                   
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                        <div class="job_detils_date width100">
                                                            <p class="job_address">Reference Id </p>                           
                                                            <p class="job_profile mt-1" >
                        
                                                                <span class="text-success"><b>{{bussinessdetails.qrCode }}</b></span>
                                                            </p>
                                                        </div>
                                                    
                                                    </div>
                                                 </div>
                                            </div>
                                        </div></div>
                                    </ng-template>
                                </mat-tab>



                                <mat-tab label="Associations">
                                    <ng-template matTabContent> 
                                        
                                        <mat-tab-group class="subtab_gg">
                                        <mat-tab label="Associated Individuals ({{bussinessdetails.individualLinks.length}})">

                                            <div class="job_list_section visa_request_section" *ngFor="let linkbussiness of bussinessdetails.individualLinks">
                                                <div class="job_detils_date width30">
                                                    <div class="visa_user_details ">
                                                        <p class="job_address">Name</p>
                                                        <h4 class="text-danger">{{ linkbussiness.firstName }} {{ linkbussiness.middleName }} {{ linkbussiness.lastName }} ({{ linkbussiness.qrCode }})</h4>
                                                    </div>
                                                </div>
                                                <div class="job_detils_date width50">
                                                    <p class="job_address">Relationship</p>
                                                    <p class="job_profile">{{ linkbussiness.relationship }}</p>
                                                </div>
                                                <div class="job_detils_date width20">

                                                    <div class="multicolorg" >
                                                        <span class="light_orange" *ngFor="let ddname of linkbussiness.datasets">{{ ddname }}</span>
                                                    </div>


                                                    <!-- <div class="multicolorg">                            
                                                        <span class="light_blue">PEP</span>
                                                        <span class="light_orange">SAN</span>
                                                        <span class="light_purple">RRE</span>
                                                        <span class="light_asian">INS</span>
                                                        <span class="light_green">DD</span>
                                                        <span class="dark_blue">POI</span>
                                                        <span class="light_pink">REL</span>
                                                    </div> -->
                                                </div>
                                            </div>
    
                                            <div class="job_list_section employee_list_section leave_section"  *ngIf="bussinessdetails.individualLinks.length ==0">
                                                <div class="job_detils_date">
                                                    <p class="job_profile">
                                                        No Record Found
                                                    </p> 
                                                </div>                                                                                       
                                            </div>

                                        </mat-tab>
                                        <!-- <mat-tab label="Associated Businesses ({{ bussinessdetails.businessLinks.length }})">

                                            <ng-container *ngFor="let businesslist of bussinessdetails.businessLinks" >
                                                <div class="job_list_section visa_request_section" *ngFor="let linkbussiness of businesslist.individualLinks">
                                                    <div class="job_detils_date width22">
                                                        <div class="visa_user_details ">
                                                            <p class="job_address">Name</p>
                                                            <h4 class="text-danger">{{ linkbussiness.firstName }} {{ linkbussiness.middleName }} {{ linkbussiness.lastName }} ({{ linkbussiness.qrCode }})</h4>
                                                        </div>
                                                    </div>
                                                    <div class="job_detils_date width50">
                                                        <p class="job_address">Relationship</p>
                                                        <p class="job_profile">{{ linkbussiness.relationship }}</p>
                                                    </div>
                                                </div>

                                            </ng-container>


                                        </mat-tab> -->
                                        <mat-tab label="Associated Businesses ({{ bussinessdetails.businessLinks.length }})">
                                            <ng-container  >
                                                <div class="job_list_section visa_request_section" *ngFor="let linkbussiness of bussinessdetails.businessLinks">
                                                    <div class="job_detils_date width22">
                                                        <div class="visa_user_details ">
                                                            <p class="job_address">Name</p>
                                                            <h4 class="text-danger">{{ linkbussiness.name }} ({{ linkbussiness.qrCode }})</h4>
                                                        </div>
                                                    </div>
                                                    <div class="job_detils_date width22">
                                                        <p class="job_address">Relationship</p>
                                                        <p class="job_profile">{{ linkbussiness.relationship }}</p>
                                                    </div>

                                                    <div class="job_detils_date width22">
                                                        <p class="job_address">Data Set</p>
                                                        <div class="multicolorg" >
                                                            <span class="light_orange" *ngFor="let ddname of linkbussiness.datasets">{{ ddname }}</span>
                                                        </div>
                                                        
                                                    </div>


                                                </div>

                                            </ng-container>
                                        </mat-tab>

                                    </mat-tab-group>

                                      

                                        <hr class="line" />


                                    </ng-template>
                                </mat-tab>


                                <mat-tab label="Rep. Risks">
                                    <ng-template matTabContent>

                                    <mat-tab-group class="subtab_gg">
                                    <mat-tab label="Reputational Risk Exposure ({{ bussinessdetails.rreEntries.length}})">
                                        <div class="job_list_section visa_request_section "  *ngFor="let linkperson of bussinessdetails.rreEntries">
                                            <div class="job_detils_date width30">
                                                <p class="job_address">Category</p>
                                                <p class="ninumber text-danger"><b>{{ linkperson.category }}</b></p>
                                            </div>
                                            <div class="job_detils_date width70">
                                                <div class="visa_user_details width100 ">
                                                    <p class="job_address">Sub Category </p>
                                                    <p class="job_profile width70">{{ linkperson.subcategory }}</p>
                                                    <p class="job_profile width70" *ngIf="!linkperson.subcategory">N/A</p>
                                                </div>
                                            </div>

                                            <div class="job_detils_date width100 border_line pb-4 mt-3" *ngFor="let linkpersonevent of linkperson.events">
                                                
                                                <div class="hrec_header">
                                                    <div class="visa_user_details width70 ">
                                                        <p class="job_address">Type</p>
                                                        <p class="job_profile width70">{{ linkpersonevent.type }}</p>
                                                        <p class="job_profile" *ngIf="!linkpersonevent.type">N/A </p>   
                                                    </div>
                                                    <div class="visa_user_details width70 ">
                                                        <p class="job_address">No Of Evidence</p>
                                                        <p class="job_profile width70">{{ linkpersonevent.evidenceIds.length }}</p>
                                                        <p class="job_profile" *ngIf="!linkpersonevent.evidenceIds.length">N/A </p>  
                                                    </div>
                                                </div>
                                                <div class="job_detils_date width100 spaceoptiud" *ngFor="let linkevdance of  bindselectedevidance(linkpersonevent.evidenceIds)">
                                                    <div class="hrec_header">
                                                        <div class="visa_user_details  ">
                                                          <p class="job_profile ">1</p>
                                                        </div>
                                                      
                                                    </div>
                                                    <div class="spaceoptiud border_line alignline border_left">
                                                        <div class="job_detils_date width20 mb-3">
                                                            <p class="job_address">Captured</p>
                                                            <p class="job_profile">{{linkevdance.captureDateIso}} </p> 
                                                            <p class="job_profile" *ngIf="!linkevdance.captureDateIso">N/A </p>                                                       
                                                        </div>
                                                     
    
                                                        <div class="job_detils_date width50 mb-3">
                                                            <p class="job_address">Title</p>
                                                            <p class="job_profile">{{linkevdance.title}}  </p>      
                                                            <p class="job_profile" *ngIf="!linkevdance.title">N/A </p>                                                    
                                                        </div>
                                                       
                                                        <div class="job_detils_date width30 mb-3">
                                                            <p class="job_address">Datasets</p> 
                                                            <div class="multicolorg" >
                                                                <span class="light_orange" *ngFor="let dd3 of  linkevdance.datasets">{{dd3}}</span>
                                                            </div>
                                                                                                                
                                                        </div>
                                                        <div class="job_detils_date width100 mb-3">
                                                            <p class="job_address">Source</p>
                                                            <p class="job_profile"> {{linkevdance.originalUrl}}</p>  
                                                            <p class="job_profile" *ngIf="!linkevdance.originalUrl">N/A </p>                                                         
                                                        </div>
                                                        <div class="job_detils_date width100  mb-3">
                                                            <!-- <p class="job_address">Summary and 1 keyword</p> 
                                                            <p class="job_profile"> {{linkevdance.summary}} </p> 
                                                                <p class="job_profile" > {{linkevdance.keywords}} </p>   -->
                                                                
                                                                
                                                                <p class="job_address">Summary 
                                                            
                                                                    <ng-container *ngIf="linkevdance.keywords">
                                                                        and 
                                                                        <ng-container *ngIf="linkevdance.keywords.split(',')">
                                                                                {{linkevdance.keywords.split(',').length}}
                                                                        </ng-container>  
                                                                        keyword
                                                                    </ng-container>
                                                                   
                                                                
                                                                </p>
                                                                <p class="job_profile"> {{linkevdance.summary}} </p> 
                                                                <p class="job_profile multicolorg text-uppercase mt-3" *ngIf="linkevdance.keywords">                                                                 
                                                                    <span *ngFor="let ss of linkevdance.keywords.split(',')"> <i class="fa fa-tags"></i> {{ss}}  </span>
                                                                </p> 

                                                        </div>
                                                      
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>

                                        <div class="job_list_section employee_list_section leave_section"  *ngIf="bussinessdetails.rreEntries.length ==0">
                                            <div class="job_detils_date">
                                                <p class="job_profile">
                                                    No Record Found
                                                </p> 
                                            </div>                                                                                       
                                        </div>
                                        <hr class="line" />

                                        </mat-tab>
                                    </mat-tab-group>

                                    </ng-template>
                                </mat-tab>

                                <mat-tab label="Other Datasets">
                                    <ng-template matTabContent>
                                       

                                    <mat-tab-group class="subtab_gg">
                                        
                                    <mat-tab label="Regulatory Enforcement List ({{ bussinessdetails.relEntries.length}})">
                                        <div class="job_list_section visa_request_section "  *ngFor="let linkperson of bussinessdetails.relEntries">
                                            <div class="job_detils_date width30">
                                                <p class="job_address">Category</p>
                                                <p class="ninumber text-danger"><b>{{ linkperson.category }}</b></p>
                                                <p class="job_profile" *ngIf="!linkperson.category">N/A </p>      
                                            </div>
                                            <div class="job_detils_date width70">
                                                <div class="visa_user_details width100 ">
                                                    <p class="job_address">Sub Category </p>
                                                    <p class="job_profile width70">{{ linkperson.subcategory }}</p>
                                                    <p class="job_profile width70" *ngIf="!linkperson.subcategory">N/A </p>   
                                                </div>
                                            </div>

                                            <div class="job_detils_date width100 border_line pb-4 mt-3" *ngFor="let linkpersonevent of linkperson.events">
                                               
                                                <div class="hrec_header">
                                                    <div class="visa_user_details width70 ">
                                                        <p class="job_address">Type</p>
                                                        <p class="job_profile width70">{{ linkpersonevent.type }}</p>
                                                        <p class="job_profile width70" *ngIf="!linkpersonevent.type">N/A </p>   
                                                    </div>
                                                    <div class="visa_user_details width70 ">
                                                        <p class="job_address">No Of Evidence</p>
                                                        <p class="job_profile width70">{{ linkpersonevent.evidenceIds.length }}</p>
                                                        <p class="job_profile width70" *ngIf="!linkpersonevent.evidenceIds.length">N/A </p>   
                                                    </div>
                                                </div>
                                                <div class="job_detils_date width100 spaceoptiud" *ngFor="let linkevdance of  bindselectedevidance(linkpersonevent.evidenceIds)">
                                                    <div class="hrec_header">
                                                        <div class="visa_user_details  ">
                                                          <p class="job_profile ">1</p>
                                                        </div>                                                      
                                                    </div>
                                                    <div class="spaceoptiud border_line alignline border_left">
                                                        <div class="job_detils_date width20 mb-3">
                                                            <p class="job_address">Captured</p>
                                                            <p class="job_profile">{{linkevdance.captureDateIso}} </p>
                                                            <p class="job_profile" *ngIf="!linkevdance.captureDateIso">N/A </p>                                                         
                                                        </div>
                                                        
    
                                                        <div class="job_detils_date width40 mb-3">
                                                            <p class="job_address">Title</p>   
                                                            <p class="job_profile">{{linkevdance.title}}  </p>  
                                                            <p class="job_profile" *ngIf="!linkevdance.title">N/A </p>                                                        
                                                        </div>
                                                       
                                                        <div class="job_detils_date width20 mb-3">
                                                            <p class="job_address"><b>Datasets</b></p>
                                                            <div class="multicolorg" >
                                                                <span class="light_blue" *ngFor="let dd3 of  linkevdance.datasets">{{dd3}}</span>
                                                            </div>                                                       
                                                        </div>
                                                        <div class="job_detils_date width100 mb-3">
                                                            <p class="job_address">Source</p> 
                                                            <p class="job_profile"> {{linkevdance.originalUrl}}</p>   
                                                            <p class="job_profile" *ngIf="!linkevdance.originalUrl">N/A </p>                                                           
                                                        </div>
                                                        <div class="job_detils_date width100 mb-3">
                                                            <!-- <p class="job_address"><b>Summary and 1 keyword</b></p>
                                                            <p class="job_profile"> {{linkevdance.summary}} </p> 
                                                            <p class="job_profile" > {{linkevdance.keywords}} </p>    
                                                             -->
                                                            <p class="job_address">Summary 
                                                            
                                                                <ng-container *ngIf="linkevdance.keywords">
                                                                    and 
                                                                    <ng-container *ngIf="linkevdance.keywords.split(',')">
                                                                            {{linkevdance.keywords.split(',').length}}
                                                                    </ng-container>  
                                                                    keyword
                                                                </ng-container>
                                                               
                                                            
                                                            </p>
                                                            <p class="job_profile"> {{linkevdance.summary}} </p> 
                                                            <p class="job_profile multicolorg text-uppercase mt-3" *ngIf="linkevdance.keywords">                                                                 
                                                                <span *ngFor="let ss of linkevdance.keywords.split(',')"> <i class="fa fa-tags"></i> {{ss}}  </span>
                                                            </p> 
                                                        </div>
                                                       
                                                    </div>
                                                                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div class="job_list_section employee_list_section leave_section"  *ngIf="bussinessdetails.relEntries.length ==0">
                                            <div class="job_detils_date">
                                                <p class="job_profile">
                                                    No Record Found
                                                </p> 
                                            </div>                                                                                       
                                        </div>
                                        <hr class="line" />

                                        </mat-tab>
                                    </mat-tab-group>
                                        <hr class="line" />


                                    </ng-template>
                                </mat-tab>

                                <mat-tab label="Evidence">
                                    <ng-template matTabContent>
                                    <mat-tab-group class="subtab_gg">
                                    <mat-tab label="All ({{ bussinessdetails.evidences.length}})">
                                        <ul class="accordion evidancegg">
                       
                                            <li class="accordion-item" *ngFor="let linkevdance of bussinessdetails.evidences">
                                                <div class="hrec_header">
                                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>{{linkevdance.evidenceId}} : {{linkevdance.title}}  </a>
                                                    </div>
                                                <div class="job_list_section visa_request_section border_line alignline spaceoptiud nolineleft">
                                                    <div class="job_detils_date width20 mb-3">
                                                        <p class="job_address">Captured</p>
                                                        <p class="job_profile">{{linkevdance.captureDateIso}} </p>     
                                                        <p class="job_profile" *ngIf="!linkevdance.captureDateIso">N/A </p>                                                           
                                                    </div>
                                                   
                                                    <div class="job_detils_date width30 mb-3">
                                                        <p class="job_address">Title</p>
                                                        <p class="job_profile">{{linkevdance.title}}  </p> 
                                                        <p class="job_profile" *ngIf="!linkevdance.title">N/A</p>                                                                
                                                    </div>
                                                                                                  
                                                    
                                                    <div class="job_detils_date width30 mb-3">
                                                        <p class="job_address">Datasets</p>
                                                        <div class="multicolorg" >
                                                            <span class="light_orange" *ngFor="let dd3 of  linkevdance.datasets">{{dd3}}</span>
                                                        </div> 
                                                                                                      
                                                    </div>
                                                    <div class="job_detils_date width30 mb-3">
                                                        <p class="job_address">Source</p>
                                                        <p class="job_profile">{{linkevdance.originalUrl}}</p>     
                                                        <p class="job_profile" *ngIf="!linkevdance.originalUrl">N/A </p>                                                        
                                                    </div>
                                                   
                                                    <div class="job_detils_date width100 mb-3">
                                                        <!-- <p class="job_address"><b>Summary and 1 keyword</b></p>
                                                        <p class="job_profile"> {{linkevdance.summary}} </p> 
                                                            <p class="job_profile" > {{linkevdance.keywords}} </p>  
                                                             -->
                                                            <p class="job_address">Summary 
                                                            
                                                                <ng-container *ngIf="linkevdance.keywords">
                                                                    and 
                                                                    <ng-container *ngIf="linkevdance.keywords.split(',')">
                                                                            {{linkevdance.keywords.split(',').length}}
                                                                    </ng-container>  
                                                                    keyword
                                                                </ng-container>
                                                               
                                                            
                                                            </p>
                                                            <p class="job_profile"> {{linkevdance.summary}} </p> 
                                                            <p class="job_profile multicolorg text-uppercase mt-3" *ngIf="linkevdance.keywords">                                                                 
                                                                <span *ngFor="let ss of linkevdance.keywords.split(',')"> <i class="fa fa-tags"></i> {{ss}}  </span>
                                                            </p> 
                                                    </div>
                                                  
                                                </div>
                                            </li>

                                        </ul>
                                        </mat-tab>



 
                                        <mat-tab *ngFor="let listev of resultevidancelist" label="{{listev.name}} ({{ listev.values.length}})" >
                                            <ul class="accordion evidancegg">
                           
                                                <li class="accordion-item" *ngFor="let linkevdance of listev.values">
                                                    <div class="hrec_header">
                                                    <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>{{linkevdance.evidenceId}} : {{linkevdance.title}}  </a>
                                                </div>
                                                    <div class="job_list_section visa_request_section border_line alignline spaceoptiud nolineleft">
                                                        <div class="job_detils_date width30 mb-3">
                                                            <p class="job_address">Captured</p> 
                                                            <p class="job_profile">{{linkevdance.captureDateIso}} </p> 
                                                            <p class="job_profile" *ngIf="!linkevdance.captureDateIso">N/A </p>                                                        
                                                        </div>                                                        
    
                                                        <div class="job_detils_date width30 mb-3">
                                                            <p class="job_address">Title</p>
                                                            <p class="job_profile">{{linkevdance.title}}  </p>  
                                                            <p class="job_profile" *ngIf="!linkevdance.title">N/A </p>                                                       
                                                        </div>                                                      
                                                                                                      
                                                        <div class="job_detils_date width30 mb-3">
                                                            <p class="job_address">Datasets</p>
                                                            <div class="multicolorg" >
                                                                <span class="light_orange" *ngFor="let dd3 of  linkevdance.datasets">{{dd3}}</span>
                                                            </div>
                                                                                                    
                                                        </div>
                                                        <div class="job_detils_date width30 mb-3">
                                                            <p class="job_address">Source</p>  
                                                            <p class="job_profile"> {{linkevdance.originalUrl}}</p>  
                                                            <p class="job_profile" *ngIf="!linkevdance.originalUrl">N/A </p>                                                       
                                                        </div>
                                                        <div class="job_detils_date width100 mb-3">
                                                            <!-- <p class="job_address">Summary and 1 keyword</p> 
                                                            <p class="job_profile"> {{linkevdance.summary}} </p> 
                                                            <p class="job_profile" > {{linkevdance.keywords}} </p>   -->
                                                            
                                                            <p class="job_address">Summary 
                                                            
                                                                <ng-container *ngIf="linkevdance.keywords">
                                                                    and 
                                                                    <ng-container *ngIf="linkevdance.keywords.split(',')">
                                                                            {{linkevdance.keywords.split(',').length}}
                                                                    </ng-container>  
                                                                    keyword
                                                                </ng-container>
                                                               
                                                            
                                                            </p>
                                                            <p class="job_profile"> {{linkevdance.summary}} </p> 
                                                            <p class="job_profile multicolorg text-uppercase mt-3" *ngIf="linkevdance.keywords">                                                                 
                                                                <span *ngFor="let ss of linkevdance.keywords.split(',')"> <i class="fa fa-tags"></i> {{ss}}  </span>
                                                            </p> 
                                                        </div>
                                                    
                                                    </div>
                                                </li>
    
                                            </ul>
                                            </mat-tab>

                                        </mat-tab-group>


                                        <hr class="line" />


                                    </ng-template>
                                </mat-tab>
                                <ng-container *ngIf="directors">
                                <mat-tab label="Business  - UK">


                                    <div class="job_list_section visa_request_section" *ngFor="let director of directors let i=index">
                                        <div class="job_detils_date width100">
                                            <p  >                                     
                                                {{director.title}} -  {{director.company_number}} , 
                                                <b>Type -</b> {{director.company_type}}, Status - {{director.company_status}},

                                                <b>Date Of Creation -</b> {{director.date_of_creation}}, 


                                                <ng-container *ngFor="let drid of directors.description_identifier">
                                                    <b> Description Identifier - </b> {{drid}},
                                                </ng-container>

                                                <ng-container *ngIf="director.address">
                                                    <b> Address - </b>

                                                    <ng-container *ngIf="director.address.premises">{{director.address.premises}}</ng-container>
                                                    <ng-container *ngIf="director.address.address_line_1">{{director.address.address_line_1}}</ng-container>
                                                    <ng-container *ngIf="director.address.address_line_2">{{director.address.address_line_2}}</ng-container>
                                                    <ng-container *ngIf="director.address.locality">{{director.address.locality}}</ng-container>                                                   
                                                    <ng-container *ngIf="director.address.region">{{director.address.region}}</ng-container>
                                                    <ng-container *ngIf="director.address.country">{{director.address.country}}</ng-container>
                                                    <ng-container *ngIf="director.address.postal_code">{{director.address.postal_code}}</ng-container>,

                                                </ng-container>


                                                
                                             <b> Current Address - </b>{{director.address_snippet}}
                                                
                                            </p>
                                            
                            
                                                
                                        </div>
                                      
                                    </div>

                                </mat-tab>
                            </ng-container>

                            <ng-container *ngIf="opendirector">

                                <mat-tab label="Business  - Global">


                                    <!-- *ngIf="searchcondition.countries.includes(director.officer.jurisdiction_code.toUpperCase())" -->

<ng-container *ngFor="let director of opendirector let i=index">

    <div class="job_list_section visa_request_section" >
        <div class="job_detils_date width100">
            <p>                                     
               <b> {{director.company.name}} </b>, <b> Jurisdiction Code - </b> {{director.company.jurisdiction_code.toUpperCase()}}, 
                
                
               <b> Company Number - </b> {{director.company.company_number}}, 

               <b>Incorporation Date - </b>{{director.company.incorporation_date}},

               <b>company Type - </b>{{director.company.company_type}},
               <ng-container *ngIf="director.company.current_status">
                <b>Current Status - </b>  {{director.company.current_status  }},
                </ng-container>
                <ng-container *ngIf="director.company.inactive">
                    <b>Inactive - </b> {{director.company.inactive  }},
                </ng-container>

                <ng-container *ngIf="director.company.branch">
                     <b>Branch - </b>    {{director.company.branch  }},
                </ng-container>
               
                <ng-container *ngIf="director.company.address">
                    <b>Branch Status - </b> {{director.company.branch_status  }},
                </ng-container>



               <b>Registered Address -</b> {{director.company.registered_address_in_full}},
             

                <ng-container *ngIf="director.company.created_at">
                    <b>Created - </b>     {{director.company.created_at | date: 'dd MMM yyyy'}}
                </ng-container>
                <ng-container *ngIf="director.company.updated_at">
                    <b>Updated Date - </b>{{director.company.updated_at | date: 'dd MMM yyyy'}}
                </ng-container>
                <ng-container *ngIf="director.company.retrieved_at">
                   <b>Retrieved Date - </b> {{director.company.retrieved_at | date: 'dd MMM yyyy'}}
                </ng-container>



                <ng-container *ngIf="director.company.previous_names.length >0 ">
                    <b> Previous Names - </b>
                    <ng-container *ngFor="let drid of director.company.previous_names">
                        Name - {{drid.company_name}},
                        <ng-container *ngIf="drid.start_date"><b>Start Date - </b>{{drid.start_date}}, </ng-container>
                        <ng-container *ngIf="drid.end_date"><b>End Date -  </b>{{drid.end_date}},</ng-container>
                    </ng-container>
                </ng-container>


            </p>
            

            <p>
            <i>Source - </i>

            <b> Publisher - </b> {{director.company.source.publisher }},
            <b> url - </b> {{director.company.source.url }},


            <ng-container *ngIf="director.company.source.retrieved_at">
                <b> Retrieved Date - </b> {{director.company.source.retrieved_at | date: 'dd MMM yyyy'}},
             </ng-container>


                <ng-container *ngIf="director.company.source.terms">
                    <b> Terms - </b> {{director.company.source.terms }},
                </ng-container>

                <ng-container *ngIf="director.company.source.terms_url">
                    <b> Terms URL - </b> {{director.company.source.terms }}, 
                </ng-container>


            

            </p>
            

                
        </div>
      
    </div>
</ng-container>

                                   


                                   
                                </mat-tab>

                            </ng-container>

                           

                              
                            </mat-tab-group>
                        </div>

                        <jw-modal id="document_view" class="modal" role="dialog">
                            <div role="document" class="formgroup" *ngIf="snippet">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <!-- <h5 class="modal-title">{{}}</h5> -->
                                        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('document_view');">
                                <span aria-hidden="true">&times;</span>
                              </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="container">


                                            <div class="all_detials">
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">
                                                            <div class="catog_box width50">
                                                                <p><b>Title</b></p>
                                                                <span>{{snippet.title}}</span>
                                                                <span *ngIf="!linkevdance.title">N/A </span>     
                                                            </div>
                                                            <div class="catog_box width50">
                                                                <p><b>Keywords</b></p>
                                                                <span *ngFor="let category of snippet.keywordsMatched">{{category}}</span>
                                                                
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="">
                                                                <p><b>Summary</b></p>
                                                                <p>{{snippet.summary}}</p>
                                                                <span *ngIf="!snippet.summary">N/A </span>  
                                                            </div>
                                                            <!-- <div class="catog_box width100">
                                                                <p>OriginalURL</p>
                                                                <span *ngIf="originalURL">{{originalURL}}</span>
                                                            </div> -->


                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="catog_box width50">
                                                                <p><b>Original URL</b></p>
                                                                <span><a href="{{originalURL}}" target="_blank">{{originalURL}}</a></span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>

                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="catog_box">
                                                                <span></span>
                                                            </div>
                                                            <div class="catog_box">
                                                                <span><i class="fa fa-question-circle" aria-hidden="true"></i>
                                                                   Disclaimer</span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <!-- <button type="button" class="btn btn-primary com_btn"> Remove from review list</button> -->
                                        <button type="button" class="btn btn-secondary com_btn" (click)="closeModal('document_view');" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </jw-modal>
                        <jw-modal id="business_view" class="modal" role="dialog">
                            <div role="document" class="formgroup">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Deloitte Consulting LLP</h5>
                                        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('business_view');">
                              <span aria-hidden="true">&times;</span>
                            </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="container">

                                            <div class="row">
                                                <div class="col-lg-2">
                                                    <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                                                </div>
                                                <div class="col-lg-10  d-flex">
                                                    <div class="category align-items-center text-center d-flex width100">
                                                        <div class="catog_box fourbox">
                                                            <p>PEP</p>
                                                            <span>9</span>
                                                        </div>

                                                        <div class="catog_box fourbox">
                                                            <p>Law Enforcement</p>
                                                            <span>2</span>
                                                        </div>
                                                        <div class="catog_box fourbox">
                                                            <p>Corporate</p>
                                                            <span>1</span>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="all_detials">
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">
                                                            <div class="catog_box width50">
                                                                <p>Business Name</p>
                                                                <span>Deloitte Consulting LLP</span>
                                                            </div>
                                                            <div class="catog_box width50">
                                                                <p>Alias</p>
                                                                <span>Deloitte Consulting LLP., Deloitte Consulting, Deloitte Consulting Liability Partnership</span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="catog_box width50">
                                                                <p>Addresses</p>
                                                                <span>Santa Ana California United States America  </span>
                                                            </div>
                                                            <div class="catog_box width50">
                                                                <p>Website </p>
                                                                <span>www.monitor.com</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>


                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="catog_box">
                                                                <span>Reference Id</span>
                                                            </div>
                                                            <div class="catog_box">
                                                                <span>539818</span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger com_btn"> Add to review list</button>
                                        <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </jw-modal>
                        <jw-modal id="linkperson_view" class="modal" role="dialog">
                            <div role="document" class="formgroup">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Tagg Romney</h5>
                                        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('linkperson_view');">
                              <span aria-hidden="true">&times;</span>
                            </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="container">

                                            <div class="row">
                                                <div class="col-lg-2">
                                                    <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                                                </div>
                                                <div class="col-lg-10  d-flex">
                                                    <div class="category align-items-center text-center d-flex width100">
                                                        <div class="catog_box fourbox">
                                                            <p>PEP</p>
                                                            <span>4</span>
                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="all_detials">
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">
                                                            <div class="catog_box width22">
                                                                <p>Person Name</p>
                                                                <span>Tagg Romney</span>
                                                            </div>
                                                            <div class="catog_box width7">
                                                                <p>Gender</p>
                                                                <span>Male</span>
                                                            </div>
                                                            <div class="catog_box width50">
                                                                <p>Address</p>
                                                                <span>Belmont Massachusetts United States Of America</span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="catog_box width100">
                                                                <p>Note</p>
                                                                <!-- <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</span> -->
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>


                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="catog_box">
                                                                <span>Reference Id</span>
                                                            </div>
                                                            <div class="catog_box">
                                                                <span>202979</span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger com_btn"> Add to review list</button>
                                        <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </jw-modal>
                    </div>
                </div>




                
                <div class="tabs__content" *ngIf="noresult">

                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row row-sm topheader">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Business Details </h5>
                                </div>

                                <div class="col-lg-6">
                                    <a type="button" (click)="norecordfound()" style="color: #fff;" [innerHtml]="downloadtext" target="_blank" class="btn btn-primary com_btn float-right" >No Record Found <i class="fa fa-Plus ml-2"></i></a>
                                    <!-- <a type="button" href="{{ url }}" *ngIf="url"   target="_blank" class="btn btn-primary com_btn float-right" >Download <i class="fa fa-file-pdf-o ml-2"></i></a> -->
                                 </div>

                            </div>

                <div class="row">
                    <div class="col-lg-12" *ngIf="searchcondition">
                        <div class="border-box float-left width100">
                            <div class="overview_details emp width100">
                                <div class="profile_dtailsshow visastaus mb-3">
                                   <h5></h5>
                                   
                                </div>
                                <div class="profile_dtailsshow"> 
                                    <div class="detailsshow width50 pr-3">
                                        <span>Business Name</span>
                                        <p *ngIf="searchcondition.name">{{searchcondition.name}}</p>
                                        <p *ngIf="!searchcondition.name">NA</p>
                                    </div>                                     
                                    <!-- <div class="detailsshow width50 pr-3" *ngIf="searchcondition.DateOfBirth">
                                        <span>Date of Birth</span>
                                        <p>{{searchcondition.DateOfBirth | date: 'dd MMM yyyy' }}</p>
                                    </div> -->
                                </div>
                                <div class="profile_dtailsshow"> 
                                    <!-- <div class="detailsshow width25 pr-3"  *ngIf="searchcondition.DateOfBirth">
                                        <span>Year of Birth</span>
                                        <p *ngIf="searchcondition.DateOfBirth">{{searchcondition.DateOfBirth | date: 'yyyy' }}</p>
                                        <p *ngIf="!searchcondition.DateOfBirth">NA</p>                                        
                                    </div>  -->
                                    <div class="detailsshow width25 pr-3">
                                        <span>Matching Threshold Percentage</span>
                                        <p *ngIf="searchcondition.threshold">{{searchcondition.threshold}}</p>
                                        <p *ngIf="!searchcondition.threshold">NA</p>
                                    </div>
                                </div>

                                <div class="profile_dtailsshow"> 
                                    <div class="detailsshow widt50 pr-3"  *ngIf="searchcondition.countries">
                                        <span>Country</span>
                                        <p class="multicolorg">
                                            <span *ngFor="let dbset of bindallcountry(searchcondition.countries)">
                                                {{dbset.countryname }}
                                            </span>
                                        </p>                                      
                                    </div> 

                                    <div class="detailsshow width50 pr-3"  *ngIf="searchcondition.datasets">
                                        <span>Datasets</span>
                                        <p  class="multicolorg">
                                            <span *ngFor="let dbset of searchcondition.datasets">
                                                {{dbset}}
                                            </span>                                        
                                        </p>
                                        
                                    </div> 

                                    
                                </div>

                              
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="credit_data_show">
                    <div class="job_list_section visa_request_section" >
                        <div class="job_detils_date">
                            <p class="job_profile">No Record Found</p>
                        </div>
                    </div> 
                </div>


            </div>
        </div>
    </div>




            </div>
        </div>
    </div>
</div>




<jw-modal id="pdf-image-viewer-complete-document" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-complete-document');">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-12">

                            <iframe *ngIf="imgvisasrc == false" [src]="iframevisasrc" width="100%" height="600" frameborder="0"></iframe>
                            <img *ngIf="imgvisasrc == true" [src]="iframevisasrc" width="100%" />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</jw-modal>