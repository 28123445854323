"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var myidproof_service_1 = require("../../../../services/myidproof.service");
var country_service_1 = require("../../../../services/country.service");
var forms_1 = require("@angular/forms");
var idproof_model_1 = require("../../../../models/idproof/idproof.model");
var upload_file_service_1 = require("./../../../../services/upload-file.service");
var alert_service_1 = require("../../../../services/alert.service");
var ngx_webcam_1 = require("ngx-webcam");
var platform_browser_1 = require("@angular/platform-browser");
var rxjs_1 = require("rxjs");
var router_1 = require("@angular/router");
var checksystem_service_1 = require("../../../../services/checksystem.service");
var myvisa_service_1 = require("../../../../services/myvisa.service");
var checkservice_service_1 = require("../../../../services/checkservice.service");
var Subscription_1 = require("rxjs/Subscription");
require("rxjs/add/observable/timer");
var RtwDocumentComponent = /** @class */ (function () {
    function RtwDocumentComponent(formBuilder, uploadService, iddetails, visaService, datePipe, alerts, country, sanitizer, check, checkService, activatedRoute) {
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.iddetails = iddetails;
        this.visaService = visaService;
        this.datePipe = datePipe;
        this.alerts = alerts;
        this.country = country;
        this.sanitizer = sanitizer;
        this.check = check;
        this.checkService = checkService;
        this.activatedRoute = activatedRoute;
        this.performAction = new core_1.EventEmitter();
        this.wizard = 0;
        this.token_expired = false;
        this.is_rtw = 0;
        this.showloader = false;
        this.docname = "";
        this.showWebcam = true;
        this.allowCameraSwitch = true;
        this.multipleWebcamsAvailable = false;
        this.videoOptions = {};
        this.errors = [];
        // latest snapshot
        this.webcamImage = null;
        // webcam snapshot trigger
        this.trigger = new rxjs_1.Subject();
        // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
        this.nextWebcam = new rxjs_1.Subject();
    }
    RtwDocumentComponent.prototype.ngOnInit = function () {
        var _this = this;
        // console.log(this.docid);
        this.profile_image = localStorage.getItem('profile_pic');
        this.euserid = localStorage.getItem('euserid');
        this.disableBtn = true;
        this.submitted = false;
        this.disableBtn = true;
        this.idproofDetail = new idproof_model_1.Idproof();
        this.idproofDetail.employeeidproofdeatilid = "0";
        this.idproofDetail.idtype = this.docid;
        this.idproofDetail.nationalityid = "";
        this.idproofDetail.employeeid = this.euserid; // '2';
        this.idproofpopForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            idnumber: ['', forms_1.Validators.required],
            idtype: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            docexpiry: ['', forms_1.Validators.required]
        });
        this.iddetails.iddocumenttype().subscribe(function (documenttype) {
            documenttype.forEach(function (element) {
                if (element.id == Number(_this.docid)) {
                    _this.docname = element.documentname;
                }
            });
        });
        this.accesstoken = localStorage.getItem('logid'); //this.activatedRoute.snapshot.paramMap.get("token");
        // this.activatedRoute.queryParams.subscribe(params => {
        //   this.accesstoken = params['access_token'];
        //   this.is_rtw = params['is_rtw'];
        //   localStorage.setItem('userToken', this.accesstoken);
        //   this.check.gettokensystem().subscribe((data: any) => { 
        //     if (data.sussess == 'true') {
        //       localStorage.setItem('userid', data.userid);
        //       localStorage.setItem('emptype', data.emptype);
        //       localStorage.setItem('isline', data.isline);
        //       localStorage.setItem('planid', data.planid);
        //       localStorage.setItem('isemail', data.isemail);
        //       localStorage.setItem('isadmin', data.isadmin);
        //       localStorage.setItem('ispermission', data.ispermission);
        //       localStorage.setItem('logoname', data.logourl);
        //       localStorage.setItem('ctype', data.ctype);
        //       const id = localStorage.getItem('userToken');
        //       this.Userid = localStorage.getItem('userid');
        //       const data2 = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
        //       if (data.logourl == "" || this.logo == null) {
        //         this.logo = "assets/img/logo.png";
        //       }else
        //       {
        //         this.logo =data.logourl;
        //       }
        //       this.idproofDetail.employeeid =data.userid;
        //       this.employeemasterid = data.userid;
        //       this.wizard = 0;  
        //       this.idproofDetail.nationalityid = "";     
        //       this.Userid = localStorage.getItem('userid');
        //       var dd =  localStorage.getItem('userid');
        //       this.idproofDetail.nationalityid = "";
        //       this.idproofDetail.employeeidproofdeatilid = "0";
        //       this.idproofDetail.idtype = 17;
        //       this.filename = "Choose file";
        //       this.profilepic = "Choose file";    
        //       this.filebackname = 'Choose file';
        //     }
        //     else
        //     {
        //       this.token_expired = true;
        //     }
        //   });
        // });
        this.getCountries();
    };
    RtwDocumentComponent.prototype.ngOnDestroy = function () {
        if (this.subscription && this.subscription instanceof Subscription_1.Subscription) {
            this.subscription.unsubscribe();
        }
    };
    RtwDocumentComponent.prototype.getCountries = function () {
        var _this = this;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
    };
    RtwDocumentComponent.prototype.reInitiateDocForm = function () {
        var _this = this;
        this.submitted = false;
        this.profile_image = localStorage.getItem('profile_pic');
        console.log(this.profile_image);
        this.idproofDetail = new idproof_model_1.Idproof();
        this.idproofDetail.employeeid = this.euserid;
        this.idproofDetail.employeeidproofdeatilid = "0";
        this.idproofDetail.nationalityid = "";
        this.idproofDetail.idtype = this.docid;
        this.iddetails.iddocumenttype().subscribe(function (documenttype) {
            documenttype.forEach(function (element) {
                if (element.id == Number(_this.docid)) {
                    _this.docname = element.documentname;
                }
            });
        });
        var doc = {
            id: "0",
            employeeid: this.euserid,
            idnumber: '',
            idtype: this.docid,
            docexpiry: new Date(),
            nationalityid: ""
        };
        this.idproofpopForm.reset(doc);
    };
    Object.defineProperty(RtwDocumentComponent.prototype, "f", {
        get: function () {
            return this.idproofpopForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    RtwDocumentComponent.prototype.toggle = function (id, prev) {
        var _this = this;
        if (prev === void 0) { prev = ''; }
        if (id == 1) {
            this.submitted = true;
            // console.log(this.idproofpopForm);
            if (this.idproofpopForm.invalid) {
                return;
            }
        }
        if (id == 2) {
            if (this.webcamImage) {
                var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
                setTimeout(function () { return _this.frontimage = imagepath; }, 5000);
                this.webcamImage = null;
            }
        }
        else if (id == 3) {
            if (this.webcamImage) {
                var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
                setTimeout(function () { return _this.backimage = imagepath; }, 5000);
                this.webcamImage = null;
            }
            if (this.profile_image) {
                if (prev) {
                    this.toggle(2);
                    return;
                }
                this.toggle(4);
                return;
            }
        }
        else if (id == 4) {
            if (this.webcamImage) {
                var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
                setTimeout(function () { return _this.profileimage = imagepath; }, 5000);
                this.webcamImage = null;
            }
            if (this.profile_image) {
                this.profileimage = this.profile_image;
            }
            this.setTimer();
        }
        this.wizard = id;
        // console.log(this.wizard);
    };
    RtwDocumentComponent.prototype.toggle2 = function (id) {
        // if(id == 1){
        //   this.wizard2 = false;
        //   this.wizard1 = true;
        //   this.wizard3 = false;
        // }
        // if(id == 2){
        //   this.wizard3 = false;
        //   this.wizard1 = false;
        //   this.wizard2 = true;
        // }
    };
    RtwDocumentComponent.prototype.onSubmitDocument = function (value3, id, stype) {
        var _this = this;
        this.submitted = true;
        this.disableBtn = false;
        this.wizard = 6;
        value3.imagename = this.frontimage;
        value3.imagebackname = this.backimage;
        value3.idprofileimage = this.profileimage;
        value3.idtype = this.docid;
        value3.docexpiry = this.datePipe.transform(new Date(value3.docexpiry), "dd MMM yyyy");
        value3.isrighttowork = 1;
        value3.stype = stype;
        value3.createby = this.Userid;
        this.iddetails.myidproofdetailsUpdaterighttowork(value3).subscribe(function (datamain) {
            // console.log(datamain);
            _this.wizard = 0;
            var logupdate = {
                id: _this.accesstoken,
                remaindocumentlist: _this.docs,
                profileimage: value3.idprofileimage,
                documentid: datamain.employeeidproofdeatilid,
                isstatus: (_this.docs.length) ? 0 : 1
            };
            _this.checkService.updatenewrighttowork(logupdate).subscribe(function (respdata) {
                localStorage.setItem('profile_pic', respdata.profileimage);
                _this.performAction.emit();
                _this.reInitiateDocForm();
                // console.log(respdata);
            }); // var data3 = {"employeeidproofdeatilid": datamain.employeeidproofdeatilid}
            // this.iddetails.idproofdetailsbyidproofid(data3).subscribe((data: any) => {      
            //   var countryiso = data.nationality.isothree;
            //   var idtype = 'DRIVING_LICENSE';//data.documenttype.documentcode;     
            //   var visalog = {
            //     employeeid: this.employeemasterid,
            //     documentid: datamain.employeeidproofdeatilid,
            //     documenturl: value3.imagename,
            //     documenttype: 'id'
            //   };
            //   this.visaService.visaailogupdate(visalog).subscribe((dbvisaresult: any) => {        
            //     if(dbvisaresult.success =='1'){
            //       var data = { 
            //         merchantIdScanReference: dbvisaresult.logid, 
            //         country: countryiso,
            //         idType: idtype, 
            //         customerId: this.employeemasterid,
            //         is_rtw: this.is_rtw,
            //         callbackUrl: AppGlobals.API_ENDPOINT +"/api/aiconfirmation",
            //         documenturl: value3.imagename,
            //         documentbackurl: value3.imagebackname, 
            //         imageurl: value3.idprofileimage 
            //       };  
            //       this.visaService.visadocumentupdate(data).subscribe((data5: any) => {
            //         if(data5.success == true){
            //           this.check.deleteAccessToken().subscribe((tokenData: any) => {
            //             if(tokenData.success)
            //             { 
            //               localStorage.removeItem('userToken');
            //               localStorage.removeItem('userid');
            //               localStorage.removeItem('emptype');
            //               localStorage.removeItem('isline');
            //               localStorage.removeItem('planid');
            //               localStorage.removeItem('isemail');
            //               localStorage.removeItem('isadmin');
            //               localStorage.removeItem('ispermission');
            //               localStorage.removeItem('logoname');
            //               localStorage.removeItem('ctype');
            //               this.wizard = 5;
            //             }
            //           });
            //         }else {
            //           this.wizard = 4;
            //           this.alerts.error("something went wrong please try again");
            //         }          
            //       });
            //       }else{
            //         this.wizard = 4;
            //       }
            //   });
            // });      
        });
        this.alerts.success('Document Uploaded Successfully.', true);
    };
    RtwDocumentComponent.prototype.setTimer = function () {
        var _this = this;
        this.showloader = true;
        this.timer = rxjs_1.Observable.timer(5000);
        this.subscription = this.timer.subscribe(function () {
            _this.showloader = false;
        });
    };
    RtwDocumentComponent.prototype.sanitize = function (url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    };
    RtwDocumentComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.fileformaterror = true;
        }
        else {
            this.fileformaterror = false;
            this.filesizeerror = false;
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.imgURL = reader.result;
            };
            // this.selectedFiles = files[0]; 
        }
    };
    RtwDocumentComponent.prototype.selectbackFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filebackname = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filebacksizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.filebackformaterror = true;
            this.filebacksizeerror = false;
        }
        else {
            this.filebackformaterror = false;
            this.filebacksizeerror = false;
            this.selectedbackFiles = files[0];
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.imgbackURL = reader.result;
            };
        }
    };
    RtwDocumentComponent.prototype.selectprofile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.profilepic = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.pro_pic_size_error = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.pro_pic_format_error = true;
            this.pro_pic_size_error = false;
        }
        else {
            this.pro_pic_format_error = false;
            this.pro_pic_size_error = false;
            this.selected_profile_image = files[0];
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.profile_pic_url = reader.result;
            };
        }
    };
    RtwDocumentComponent.prototype.triggerSnapshot = function () {
        this.trigger.next();
    };
    RtwDocumentComponent.prototype.triggerSnapshotreload = function () {
        this.webcamImage = null;
        //this.trigger.next();
    };
    RtwDocumentComponent.prototype.toggleWebcam = function () {
        this.showWebcam = !this.showWebcam;
    };
    RtwDocumentComponent.prototype.handleInitError = function (error) {
        this.errors.push(error);
    };
    RtwDocumentComponent.prototype.showNextWebcam = function (directionOrDeviceId) {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    };
    RtwDocumentComponent.prototype.handleImage = function (webcamImage) {
        // console.info('received webcam image', webcamImage);
        // var imagepath = this.uploadService.uploadfilebase64(webcamImage);
        // this.imgsrc = imagepath;
        // console.log(imagepath);
        // let reader = new FileReader();
        //     // handle data
        //     reader.onload = event => {       
        //       console.log(event.target)
        //       if (event.target.result) {
        //         this.file.base64String = event.target.result;  
        //       }
        //     };
        //     reader.readAsDataURL(blob);
        // console.log(this.imgsrc);
        this.webcamImage = webcamImage;
    };
    RtwDocumentComponent.prototype.cameraWasSwitched = function (deviceId) {
        // console.log('active device: ' + deviceId);
        this.deviceId = deviceId;
    };
    Object.defineProperty(RtwDocumentComponent.prototype, "triggerObservable", {
        get: function () {
            return this.trigger.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RtwDocumentComponent.prototype, "nextWebcamObservable", {
        get: function () {
            return this.nextWebcam.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return RtwDocumentComponent;
}());
exports.RtwDocumentComponent = RtwDocumentComponent;
