"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./performance.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../user/header/header.component.ngfactory");
var i3 = require("../../user/header/header.component");
var i4 = require("../../services/customer.service");
var i5 = require("@angular/router");
var i6 = require("../../services/user.service");
var i7 = require("../../services/company.service");
var i8 = require("@angular/common");
var i9 = require("./performance.component");
var styles_PerformanceComponent = [i0.styles];
var RenderType_PerformanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PerformanceComponent, data: {} });
exports.RenderType_PerformanceComponent = RenderType_PerformanceComponent;
function View_PerformanceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.CustomerService, i5.Router, i6.UserService, i7.CompanyService, i1.Renderer2, i1.Renderer2], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "section-wrapper p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "admin_dashboard_show"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "ceee"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "cardmenu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "a", [["class", "card_box_lit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 1), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "samebox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "lnr lnr-user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "menu_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Objective"]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_2 = _ck(_v, 9, 0, "/employee-object-list"); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).target; var currVal_1 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_0, currVal_1); }); }
exports.View_PerformanceComponent_0 = View_PerformanceComponent_0;
function View_PerformanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-performance", [], null, null, null, View_PerformanceComponent_0, RenderType_PerformanceComponent)), i1.ɵdid(1, 114688, null, 0, i9.PerformanceComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_PerformanceComponent_Host_0 = View_PerformanceComponent_Host_0;
var PerformanceComponentNgFactory = i1.ɵccf("app-performance", i9.PerformanceComponent, View_PerformanceComponent_Host_0, {}, {}, []);
exports.PerformanceComponentNgFactory = PerformanceComponentNgFactory;
