import { HeaderComponent } from './../header/header.component';
import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { Visa } from '../../models/visa/visa.model';
import { ModalService } from '../../_services/modal.service';
import { CountryService } from '../../services/country.service';
import { MyvisaService } from '../../services/myvisa.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFileService } from './../../services/upload-file.service';
import { AlertService } from '../../services/alert.service';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';

@Component({
  selector: 'app-visa',
  templateUrl: './visa.component.html',
  styleUrls: ['./visa.component.css']
})

export class VisaComponent implements OnInit {
  visa = new Visa();
  visaDetail = new Visa();
  countrylist: any;
  VisaForm: FormGroup;
  submitted: boolean;
  Userid: any;
  defaultValue: any = "";
  selectedFiles: FileList;
  filesizeerror: any;
  fileformaterror: any;
  file: any;
  filename: any;
  listcheck: any;
  iframesrc: any;
  imgsrc: any;

  constructor(private sanitizer: DomSanitizer, private uploadService: UploadFileService, private formBuilder: FormBuilder, private data: CustomerService, private country: CountryService, private modalService: ModalService, private data2: MyvisaService, private alerts: AlertService) { }

  ngOnInit() {
    this.VisaForm = this.formBuilder.group({
      employeeid: ['', Validators.required],
      id: ['', Validators.required],
      visanumber: ['', Validators.required],
      visatype: ['', Validators.required],
      // countryid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      visaexpiry: ['', Validators.required],
      visastart: ['', Validators.required],
    });

    this.filename = 'Choose file';
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    this.data.getVisa().subscribe((data: any) => {
      this.visa = data;
    });
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
  }

  get f() { return this.VisaForm.controls; }

  onSubmit(value3: any, id: string) {
    this.submitted = true;
    if (this.VisaForm.invalid) {
      return;
    }
    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      var typeoffile = filetype[filetype.length - 1];
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((typeoffile != "jpeg") && (typeoffile != "jpg") && (typeoffile != "png") && (typeoffile != "gif") && (typeoffile != "tif") && (typeoffile != "pdf") && (typeoffile != "doc") && (typeoffile != "docx")) {
        this.fileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.imagename = imagepath;
    }
    else {
      value3.imagename = this.visaDetail.visadocname;
    }
    this.data2.myvisadetailsUpdate(value3).subscribe((data: any) => {
      var userid = localStorage.getItem('userid');
      this.data.getVisa().subscribe((data: any) => {
        this.visa = data;
      });
    });
    this.alerts.success('Visa updated successfully', true)
    this.modalService.close(id);
  }

  selectFile(event) {
    var files = event.target.files;
    this.filename = files[0].name;
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModal3(id: string, data: any) {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];

    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.visaDetail = new Visa();
    this.visaDetail = data;
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.visa = new Visa();
    //  const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    this.data.getVisa().subscribe((data1: any) => {
      this.visa = data1;
    });

    this.modalService.close(id);
  }

  modalOpen(data: any) {
    this.visaDetail = data;
  }
}