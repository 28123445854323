<mat-tab-group>       

    <mat-tab label="Company Status(0)">
        <ng-template matTabContent>
            <div class="summary_sec">
                <div class="row">
                <div class="col-sm-12">
                    <div class="keyinfo noheight">
                        <div class="row">
                            <div class="col-sm-12">
                                <h4>Company Status</h4>
                            </div>
                            <div class="col-sm-12">
                                <p class="norecordfound">No information to display</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </ng-template>
    </mat-tab>
  
    <mat-tab label="CCJ's({{company.legal_filings.ccj.exact_registered.length}})">
      <ng-template matTabContent>
          <div class="summary_sec">
            <div class="row">
              <div class="col-sm-12">
                  <h4>Exact CCJ Details</h4>
                  <div class="keyinfo noheight" *ngIf="!(company.legal_filings.ccj.exact_registered.length)">
                      No Exact CCJ Details To Display
                  </div>
                  <div class="keyinfo noheight" *ngIf="company.legal_filings.ccj.exact_registered.length">
                      <div class="block_tables_head">
                          <div class="second">
                              <h4>Date</h4>
                          </div>
                          <div class="second">
                              <h4>Court</h4>
                          </div>
                          <div class="second">
                              <h4>Amount</h4>
                          </div>
                          <div class="second">
                              <h4>Status</h4>
                          </div>
                          <div class="second">
                              <h4>Case Number</h4>
                          </div>
                          <div class="second">
                              <h4>Date Paid</h4>
                          </div>
                          
                      </div>
                      <div class="block_tables" *ngFor="let exactccj of company.legal_filings.ccj.exact_registered">
                          <div class="second">
                              <p>{{exactccj.ccjDate | date: 'MM/dd/yyyy'}}</p>
                          </div>
                          <div class="second">
                              <p>{{exactccj.court}}</p>
                          </div>
                          <div class="second">
                              <p>£{{exactccj.ccjAmount}}</p>
                          </div>
                          <div class="second">
                              <p>{{exactccj.ccjStatus}}</p>
                          </div>
                          <div class="second">
                              <p>{{exactccj.caseNumber}}</p>
                          </div>
                          <div class="second">
                              <p>-</p>
                          </div>
                      </div>
                  </div> 
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12">
                  <h4>Possible CCJ Details</h4>
                  <div class="keyinfo noheight" *ngIf="!(company.legal_filings.ccj.possible_registered.length)">
                      No Possible CCJ Details To Display
                  </div>
                  <div class="keyinfo noheight" *ngIf="company.legal_filings.ccj.possible_registered.length">
                      <div class="block_tables_head">
                          <div class="second">
                              <h4>Company Name</h4>
                          </div>
                          <div class="second">
                              <h4>Amount</h4>
                          </div>
                          <div class="second">
                            <h4>Statement Date</h4>
                          </div>
                          
                    </div>
                </div>  
            </div>
        </div>
       
        </div>
      </ng-template>
  </mat-tab>

  <mat-tab label="Trade Creditors(0)">
    <ng-template matTabContent>
        <div class="summary_sec">
          <div class="row">
            <div class="col-sm-12">
                <div class="keyinfo noheight">
                    <div class="row">
                        <div class="col-sm-12">
                            <h4>Trade Creditors</h4>
                        </div>
                        <div class="col-sm-12">
                            <p class="norecordfound">No information to display</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </ng-template>
  </mat-tab>

  <mat-tab label="Safe Alerts">
    <ng-template matTabContent>
        <div class="summary_sec">
            <div class="bogbox">
                <div class="row">
                    <div class="col-sm-12">
                        <h4>Safe Alert Details</h4>
                    </div>                                   
                </div>
                <div class="row keyinfo noheight ">
                    <div class="col-sm-12">
                        <div class="block_tables">
                            <div class="second">
                                <p>No information to display</p>
                            </div>
                        </div>
                        <!-- <div class="block_tables">
                            <div class="second">
                                <p>£{{posccj.amount.value}}</p>
                            </div>
                            <div class="second">
                              <p>{{posccj.statementDate | date: 'MM/dd/yyyy'}}</p>
                            </div> -->
                            
                      </div>
                  </div> 
              </div>
          </div>
          <!-- </div> -->
        </ng-template>
    </mat-tab>
  
    <mat-tab label="Writs(0)">
      <ng-template matTabContent>
          <div class="summary_sec">
            <div class="row">
              <div class="col-sm-12">
                  <div class="keyinfo noheight">
                      <div class="row">
                          <div class="col-sm-12">
                              <h4>Writs</h4>
                          </div>
                          <div class="col-sm-12">
                              <p class="norecordfound">No information to display</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
        </ng-template>
    </mat-tab>
  
    <mat-tab label="Trade Debtors({{company.legal_filings.trade_debtors.length}})">
      <ng-template matTabContent>
          <div class="summary_sec">
         
          <div class="row">
              <div class="col-sm-12">
                  <h4> Trade Debtors / Bad Debt Detail</h4>
                  <div class="keyinfo noheight" *ngIf="!(company.legal_filings.trade_debtors.length)">
                      No Details found for Trade Debtors / Bad Debt Detail
                  </div>
                  <div class="keyinfo noheight" *ngIf="company.legal_filings.trade_debtors.length">
                      <div class="row">
                          <div class="col-sm-12">
                              
                          </div>
                          <div class="col-sm-12">
                             <div class="enqu_summa">
                                 <ul>
                                     <li>
                                         <p>Total Document Trade </p>
                                         <h2>{{company.legal_filings.trade_debtors.length}}</h2>
                                     </li>
                                     <li>
                                      <p>Total Value of Documented Trade</p>
                                      <h2>£{{company.legal_filings.total_trade_Amount}}</h2>
                                  </li>
                          
                                 </ul>
                             </div>
                          </div>
                         
                      </div>
                      <div class="searchbar">
                          <input type="text" class="form-control" placeholder="Search">
                          <i class="fa fa-search"></i>
                      </div>
                      <div class="clearfix"></div>
                      <div class="block_tables_head">
                          <div class="second">
                              <h4>Company Name</h4>
                          </div>
                          <div class="second">
                              <h4>Amount</h4>
                          </div>
                          <div class="second">
                            <h4>Statement Date</h4>
                          </div>
                          
                      </div>
                      <div class="block_tables" *ngFor="let debt of company.legal_filings.trade_debtors">                                       
                            <div class="second">
                                <p>{{debt.companyName}}</p>
                            </div>
                            <div class="second">
                                <p>£{{debt.amount.value}}</p>
                            </div>
                            <div class="second">
                              <p>{{debt.statementDate | date: 'MM/dd/yyyy'}}</p>
                            </div>
                            
                      </div>
                  </div>  
              </div>
          </div>
         
          </div>
        </ng-template>
    </mat-tab>
  
  
    
    <mat-tab label="Profit Warnings(0)">
      <ng-template matTabContent>
          <div class="summary_sec">
            <div class="row">
              <div class="col-sm-12">
                  <div class="keyinfo noheight">
                      <div class="row">
                          <div class="col-sm-12">
                              <h4>Profit Warnings</h4>
                          </div>
                          <div class="col-sm-12">
                              <p class="norecordfound">No information to display</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
        </ng-template>
    </mat-tab>
    
  </mat-tab-group>    