<div class="signin-wrapper">
  <div class="new_login">
    <div class="login_section">
      <div class="dflexotp_top">
        <a class="login_logo"  [routerLink]="['/']" ><img src="/assets/img/logo.png" alt=""></a>
        <a href="#" class="header-notification " data-toggle="dropdown" (click)="logout()" title="Logout"> <i
            class="fa fa-power-off"></i> </a>
      </div>
     
      <div class="signin-box">

        <form [formGroup]="registerForm" #loginForm class="login_form otp" (ngSubmit)="onSubmit()">


          <p *ngIf="show">
            {{message}}
          </p>
          <p class="text-muted text-center" id="msg" runat="server">Please check your Email {{ email}} & Mobile {{mobile}} and enter the Passcode</p>


          <div class="form-group mb-1">
            <div class="input-group">
              <input type="password" formControlName="otp" #Email [(ngModel)]="user.otp" class="form-control "
                [ngClass]="{ 'is-invalid': submitted && f.otp.errors }" placeholder="Email Passcode">
              <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-lock"
                    aria-hidden="true"></i></span>
              </div>
            
            </div>
            <div class="dflexotp">
              <div *ngIf="submitted && f.otp.errors" class="invalid-feedback width60">
                <div *ngIf="f.otp.errors.required">Please enter email Passcode</div>
              </div>
              <a class="viewdetails otp float-right"
              (click)="resedotp();"> Resend Email Passcode</a>
            </div>
           
          </div>

         


          <div class="form-group mb-1">
            <div class="input-group">
              <input type="password" formControlName="otpmobile" #Mobile [(ngModel)]="user.otpmobile" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.otpmobile.errors }" placeholder="Mobile Passcode">
              <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-lock" aria-hidden="true"></i></span>
              </div>
            
            </div>
            <div class="dflexotp">
              <div *ngIf="submitted && f.otpmobile.errors" class="invalid-feedback width60">
                <div *ngIf="f.otpmobile.errors.required">Please enter mobile Passcode</div>
              </div>
              <a class="viewdetails float-right otp"
              (click)="resedmobileotp();">Resend Mobile Passcode</a>
             
            </div>

          </div>

         

          <div class="form-group mg-b-30" style="color:#ff322e !important;" *ngIf=" ">
            <p>Please enter correct OTP</p>
          </div>

          <div class="form-group m-t-10 mb-0 mt-4 row">
            <button class="btn btn-primary btn-block btn-signin" type="submit">Confirm Registration</button>
          </div>

         

          <div class="form-group m-t-10 mb-0 row">
            <div class="m-t-20 text-center">
             
              <span class="errortext"> If you have registered using wrong email, please <a [routerLink]="['/update-email']"
                class="text-muted text-center" style="color:#ff322e !important;">click here</a> to change it</span>
            </div>
          </div>

          <div class="form-group m-t-10 mb-0 row">
            <div class="m-t-20 text-center">             
              <span class="errortext">If you have registered using wrong mobile number, please <a [routerLink]="['/update-mobile']" class="text-muted text-center" style="color:#ff322e !important;">click here</a> to change it</span>
            </div>
          </div>


        </form>
      </div>
    </div>
    <div class="graphic_section">
      <div class="slogan">
        <h4>Welcome To <b>Complygate</b></h4>
        <span>Commit. Control. Comply</span>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>