<div class="signin-wrapper">
  <div class="new_login">
    <div class="login_section">
      <a class="login_logo"  [routerLink]="['/']" ><img src="/assets/img/logo.png" alt=""></a>
      <a href="#" class="header-notification float-right" data-toggle="dropdown" (click)="logout()" title="Logout"> <i
          class="fa fa-power-off"></i> </a>
      <div class="signin-box">
        <div class="login_form">
          <h2>Under Review</h2>
          <p> Your account is under review. Please wait for the approval.</p>
          <!-- <p>Once the payment has been received and we are satisfied with initial on-boarding checks, we will send you an email confirming your account has been activated.</p> -->
          <!-- <button (click)="paymentstripe('custom-payment-1')" class="btn btn-primary btn-block btn-signin">Pay Now</button> -->
        </div>       
      </div>
    </div>
    <div class="graphic_section">
      <div class="slogan">
        <h4>Welcome To <b>Complygate</b></h4>
        <span>Commit. Control. Comply</span>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>