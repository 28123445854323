"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ReferenceDetails = /** @class */ (function () {
    function ReferenceDetails() {
    }
    return ReferenceDetails;
}());
exports.ReferenceDetails = ReferenceDetails;
var Referencesetting = /** @class */ (function () {
    function Referencesetting() {
        this.isemail = false;
        this.issms = false;
    }
    return Referencesetting;
}());
exports.Referencesetting = Referencesetting;
var Options = /** @class */ (function () {
    function Options(id, name) {
        this.id = id;
        this.name = name;
    }
    return Options;
}());
exports.Options = Options;
