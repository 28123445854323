import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { Visa } from '../../../models/visa/visa.model';
import { AmlService } from '../../../services/aml.service';

import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-creditsearchresult',
  templateUrl: './creditsearchresult.component.html',
  styleUrls: ['./creditsearchresult.component.css']
})

export class CreditsearchresultComponent implements OnInit {
  visaDetail: any;
  visa: Visa;
  disableBtn: any;
  buttoninsert: any;
  searchresult: any;

  searchcondition: any;

  result: boolean;
  logid: any;
  datasetlist: any;
  bussinessdetails:any;

  tPEPs: any=0;
  tCurrentSanctions: any=0;
  tDisqualifiedDirector: any=0;
  tFinancialRegulator: any=0;
  tInsolvency: any=0;
  tLawEnforcement: any=0;
  tAdverseMedia: any=0;
  tPreviousSanctions: any=0;

  ctype: number;
  downloadtext: any;
  PEPs: any= [];
  CurrentSanctions: any= [];
  DisqualifiedDirector: any= [];
  FinancialRegulator: any= [];
  Insolvency: any= [];
  LawEnforcement: any= [];
  AdverseMedia: any= [];
  PreviousSanctions: any= [];
  url: any;
  constructor(
    private modalService: ModalService, 
    private amldetails: AmlService,    
    private router: Router,
    private activatedRoute: ActivatedRoute
    
  ) { }

  ngOnInit() {


    
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    this.downloadtext = 'No Record Found <i class="fa fa-plus ml-2"></i>';

    // var ctype  = parseInt(localStorage.getItem('ctype'));
    // if(ctype ==1){
    //   this.router.navigate(['/check-dashboard']);
    // }
    this.url = '';
    this.logid = this.activatedRoute.snapshot.paramMap.get("id");    
    this.ctype  = parseInt(localStorage.getItem('ctype'));
    var val ={ id: this.logid,atype:0}  
    this.amldetails.getCompanylogrecord(val).subscribe((searchRes: any) => {  
    var listmaster =JSON.parse(searchRes.searchresult);
    if(listmaster.recordsFound >0){
      this.result =true;
    }else
    {
      var searchcondition = JSON.parse(searchRes.searchcondition);
      this.searchcondition = searchcondition;
      this.result = false;

      
  this.amldetails.generatenorecordPdf({ id: this.logid,atype:0}).subscribe((pdfRes: any) => { 
    console.log(pdfRes.response_data.Location);
    this.url = pdfRes.response_data.Location;
  });

    }
    this.searchresult = listmaster;
  });


  }


  norecordfound(){    
    this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
  this.amldetails.generatenorecordPdf({ id: this.logid,atype:0}).subscribe((pdfRes: any) => { 
    console.log(pdfRes.response_data.Location);
    this.url = pdfRes.response_data.Location;
    this.downloadtext = 'No Record Found <i class="fa fa-plus ml-2"></i>';
  });
  }


  genratepdf(){
   
    this.logid = this.activatedRoute.snapshot.paramMap.get("id");  
    
    var val ={ id: this.logid,atype:0}

    this.amldetails.generatenorecordPdf({ id: this.logid,atype:0}).subscribe((pdfRes: any) => { 
 
      this.url = pdfRes.response_data.Location;
    });
  }

  
dataset(values){
    
  this.datasetlist ="";

  if(values.isPEP == true)
  {
    this.datasetlist = "PEP,";
  }

  if(values.isAdverseMedia == true)
  {
    this.datasetlist += " Adverse Media,";
  }

  if(values.isDisqualifiedDirector == true)
  {
    this.datasetlist += " Disqualified Director (UK Only),";
  }

  if(values.isFinancialregulator == true)
  {
    this.datasetlist += " Financial Regulator,";
  }

  if(values.isInsolvent == true)
  {
    this.datasetlist += " Insolvency (UK & Ireland),";
  }

  if(values.isLawEnforcement == true)
  {
    this.datasetlist += " Law Enforcement,";
  }

  if(values.isSanctionsCurrent == true)
  {
    this.datasetlist += " Sanction - Current,";
  }
  if(values.isSanctionsPrevious == true)
  {
    this.datasetlist += " Sanction - Previous,";
  }

  this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1); 

}



openModalMastersystem(id: string, data: any) {


  this.AdverseMedia = [];
  this.PreviousSanctions =[];
  this.PEPs = [];
  this.LawEnforcement = [];
  this.CurrentSanctions = [];
  this.FinancialRegulator = [];
  this.DisqualifiedDirector = [];
  this.Insolvency = [];


  var rAdverseMedia = [];
  var rPreviousSanctions =[];
  var rPEPs = [];
  var rLawEnforcement = [];
  var rCurrentSanctions = [];
  var rFinancialRegulator = [];
  var rDisqualifiedDirector = [];
  var rInsolvency = [];


  

  this.bussinessdetails = data;

 // console.log(data);
  this.buttoninsert = "Update";
  this.modalService.open(id);

  // console.log("Second Type Development", data);


  data.person.articles.forEach(function(entry2) {
      
      entry2.categories.forEach(function(entry3) { 
        
        if(entry3.name =='Adverse Media')
        {
          
          // this.tAdverseMedia =this.tAdverseMedia +1;

          rAdverseMedia.push(entry2); 

        }else if(entry3.name =='PEP')
        {
          // this.tPEPs =this.tPEPs +1;

          rPEPs.push(entry2); 

        }else if(entry3.name =='Corporate/Business')
        {

          // this.tLawEnforcement =this.tLawEnforcement +1;

          rPreviousSanctions.push(entry2); 

        }else if(entry3.name =='Law Enforcement')
        {
          // this.tLawEnforcement =this.tLawEnforcement +1;
          rLawEnforcement.push(entry2); 
        }else if(entry3.name =='Sanction')
        {
          // this.tCurrentSanctions =this.tCurrentSanctions +1;
          rCurrentSanctions.push(entry2); 
        }else if(entry3.name =='Financial Regulator')
        {
          // this.tFinancialRegulator =this.tFinancialRegulator +1;
          rFinancialRegulator.push(entry2); 
        }else if(entry3.name =='Disqualified Director')
        {
          // this.tDisqualifiedDirector =this.tDisqualifiedDirector +1;
          rDisqualifiedDirector.push(entry2); 
        }else if(entry3.name =='Insolvent')
        {
          // this.tInsolvency =this.tInsolvency +1;
          rInsolvency.push(entry2); 
        }
        
          // console.log(entry3.name); 
      });
    });


    this.AdverseMedia = rAdverseMedia;
    this.PreviousSanctions =rPreviousSanctions;
    this.PEPs = rPEPs;
    this.LawEnforcement = rLawEnforcement;
    this.CurrentSanctions = rCurrentSanctions;
    this.FinancialRegulator = rFinancialRegulator;
    this.DisqualifiedDirector = rDisqualifiedDirector;
    this.Insolvency = rInsolvency;




}

  closeModal(id: string) 
  {
    
    
    this.modalService.close(id);
  }
}
