import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class CompensationService {

  constructor(private commonService: CommonService) { }

  getemployeecompensationbyadmin(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/employeecompensationlistbyadmin',data);   
  }

  getemployeecompensation(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/employeecompensationlist',data);   
  }

  employeecompensationUpdatebyadmin(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/updateemployeecompensationbyadmin',data);   
  }

  employeecompensationUpdate(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/updateemployeecompensation',data);   
  }

  employeecompensationDeletebyadmin(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/deletemployeecompensationbyadmin',data);   
  }

  employeecompensationDelete(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/deletemployeecompensation',data);   
  }

}
