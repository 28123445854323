"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var PassportthanksaiComponent = /** @class */ (function () {
    function PassportthanksaiComponent() {
    }
    PassportthanksaiComponent.prototype.ngOnInit = function () {
        this.ctype = parseInt(localStorage.getItem('ctype'));
    };
    return PassportthanksaiComponent;
}());
exports.PassportthanksaiComponent = PassportthanksaiComponent;
