
<div class="bg">
  <app-header></app-header>
</div>
<!-- <app-header></app-header> -->


<div class="company_adver_search"  *ngIf="mode ==0 || mode ==2">
  <div class="container">
      <div class="search_panel">

          <div class="row row-sm mb-2 mt-2">   
              
            <div class="col-lg-7">
                <h5 class="hedingcolor">Director New Search</h5>
            </div> 
            <div class="col-lg-5 text-right">
                <app-checkcountshow></app-checkcountshow>
            </div>
        </div>
        <hr />

              <div class="row row-sm mb-2" *ngIf="mode==0"> 
              <div class="col-lg-12 text-left">

                <div class="fca_search_box mb-2"   style="padding: 0px;">
                    <form [formGroup]="directorFormgroup" #advertForm="ngForm" (ngSubmit)="onSubmitAdvert(advertForm.value)" *ngIf="directorDetail" class="s12 white">
                      <div class="fca_search_form">


                        <div class="search_filed">                            
                            <label for="exampleInputEmail1">Director Name*</label>
                            <input type="text" formControlName="firstname" #companyname [(ngModel)]="directorDetail.firstname"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"
                            placeholder="Enter a director name " [maxlength]="200">                             
                        </div>
                        <div class="search_filed dbsearch">
                                <label for="exampleInputEmail1">Date of birth</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput placeholder="Date of Birth" [matDatepicker]="picker" id="dob" name="dob" formControlName="dob" [(ngModel)]="directorDetail.dob" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }" class="form-control noborder-invalid datecontrol">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                                    <div *ngIf="f.dob.errors.required">Please enter Date of birth</div>
                                </div>
                        </div>
                        <!-- <div class="search_filed">
                            <label for="exampleInputEmail1" style="display: block;">&nbsp;</label> -->
                              <button type="submit" class="btn btn-primary com_btn buttondirectorsearch"   [disabled]="!disableBtn" *ngIf="!lesssore">Search</button>
                              <button type="button" (click)="clearAdverseCreditData()" class="btn btn-secondary com_btn buttondirectorsearch">Cancel</button>                                    
                        <!-- </div> -->

                        <!-- <button type="submit" class="btn btn-primary">Search</button> -->
                      </div>
                      <!-- <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                        <div *ngIf="f.firstname.errors.required" class="text-left">Please enter director name</div>
                    </div> -->
                    </form>
                </div>





                
                  <!-- <div class="d-flex justify-content-end align-items-center">                          
                      <button class="btn com_btn btn-success small_btn" [routerLink]="['/director-history']"  type="button">
                          <span class="lnr lnr-history pr-1">                                        
                          </span> Search Director History
                      </button>
                  </div>                              -->
              </div>

             

          </div>

          <div class="row" *ngIf="lesssore"> 
            <div class="col-sm-12 text-right">
                <span >Sorry, You don't have any credits. Please  <a [routerLink]="['/check-add-credit']">Click Here</a> to add more.</span>
            </div>
           
        </div>

        <div class="row" *ngIf="error == 1"> 
          <div class="col-sm-12 text-left">
              <div class="job_list_section visa_request_section" >
                  <div class="job_detils_date">
                      <p class="job_profile">Specified criteria combination is not allowed</p>
                  </div>
              </div>
          </div>
      </div>

        
         


          





           


         
          <div class="content" *ngIf="mode==2 && error==0">

            <div class="row"> 
                <div class="col-sm-6 text-left">
                    <a type="button" (click)="norecordfound()" style="color: #fff;" [innerHtml]="downloadtext6" target="_blank" class="btn btn-primary com_btn" >No Record Found <i class="fa fa-Plus ml-2"></i></a>
                </div>
                <div class="col-sm-6 text-right">
                    <a  class="fca-search-back-search mb-4 mr-0" (click)="directorsearch()"><i  aria-hidden="true" class="fa fa-angle-left"></i> Back to Director Search</a>
                </div>
            </div>

            
            <ng-container *ngFor="let director of directors let i=index">

                <ng-container *ngIf="director.date_of_birth">
                    
                    
                <p *ngIf="!director.date_of_birth">                                     
                    {{director.title}} , 
                    Total number of appointments {{director.appointment_count}} - 
                    <ng-container>
                         Born {{(director.date_of_birth)?(director.date_of_birth.month+'/01/'+director.date_of_birth.year | date: 'MMM yyyy')+' , ':''}}
                    </ng-container>
                    
                    
                    {{director.address_snippet}}
                      <button (click)="getDataByDirectorCode(director.links.self)" class="btn btn-success com_btn">search</button>
                </p>
                

                    <ng-container *ngIf="month !=0 && year != 0">
                        <p *ngIf="director.date_of_birth.month == month && director.date_of_birth.year == year "> 
                            {{director.title}} , 
                            Total number of appointments {{director.appointment_count}} - 
                            
                            <ng-container>
                                Born {{(director.date_of_birth)?(director.date_of_birth.month+'/01/'+director.date_of_birth.year | date: 'MMM yyyy')+' , ':''}}
                           </ng-container>
                           
                           
                            
                            {{director.address_snippet}}
                            <button (click)="getDataByDirectorCode(director.links.self)" class="btn btn-success com_btn">search</button> 
                        </p>
                    </ng-container>


                    <ng-container *ngIf="month == 0 && year == 0">
                        <p> 
                            {{director.title}} , 
                            Total number of appointments {{director.appointment_count}} -
                            
                            <ng-container>
                                Born {{(director.date_of_birth)?(director.date_of_birth.month+'/01/'+director.date_of_birth.year | date: 'MMM yyyy')+' , ':''}}
                           </ng-container>
                           
                           {{director.address_snippet}}
                            <button (click)="getDataByDirectorCode(director.links.self)" class="btn btn-success com_btn">search</button> 
                        </p>
                    </ng-container>
                
                </ng-container>

            </ng-container>

              
          </div>       
      </div>      
  </div>   
</div>




<div class="company_adver_search"  *ngIf="mode ==0">
    <div class="container">
        <div class="search_panel">
  
            <div class="row row-sm topheader">   
                
              <div class="col-lg-6">
                  <h5 class="hedingcolor">Your Recent Director Search</h5>
              </div> 
             
          </div>


          

         
        <div *ngFor="let sr of searchresult; let i=index;">
            <div class="credit_data_showlog " >
                <div class="job_list_section visa_request_section">
    
                  <div class="job_detils_date width30">
                    <p class="job_address">Director Name</p>
                    <p class="job_profile">{{ sr.searchresult.name }}</p>
                  </div>
    
                    <div class="job_detils_date width30">
                        <div class="visa_user_details ">
                            <p class="job_address">Date Of Birth</p>
    
                            <h4 class="job_profile" *ngIf="sr.searchresult.date_of_birth">
                                {{sr.searchresult.date_of_birth.month+'/01/'+ sr.searchresult.date_of_birth.year | date: 'MMMM yyyy'}}
                             </h4>
                        </div>
                    </div>
    
                    <div class="job_detils_date width20 text-right">
                      <button type="button" class="btn btn-primary com_btn" [innerHtml]="downloadtext[i]" (click)="downloaddirectordata(sr.searchcondition,i)"></button>
                        <a type="submit" class="btn btn-primary com_btn text-white" (click)="viewsearchhistory(sr.searchcondition,i)">View Details</a>
                    </div>
    
    
                </div>
    
            </div>
            </div>
            <div class="row" *ngIf="searchresult">
                <div class="col-lg-12 text-left" *ngIf="searchresult.length == 0">
                    <div class="billed-to">No Record Found </div>
                </div>
            </div>


          </div>
          </div>
          </div>


          <div class="browser-screen-loading-content" *ngIf="searchloading==0" >
            <div class="loading-dots dark-gray">
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
            </div>
            <p>Loading</p>
        </div>


<div class="company_adver_search" *ngIf="mode ==1 && director">
  <div class="container">
      <div class="com_risk_option">   
          <div class="row row-sm topheader">                            
              <div class="col-lg-5 text-right">
                  <app-checkcountshow></app-checkcountshow>
              </div>
              <div class="col-lg-7 text-right">
                  <div class="d-flex justify-content-end align-items-center">   
                      
                    <button type="button" class="btn btn-primary com_btn" [innerHtml]="downloadtext5" (click)="downloaddirectordata(sscondition,100)"></button>

                      <button class="btn com_btn btn-success small_btn" (click)="chnagestatus(0)"  type="button">
                          <span class="lnr lnr-history pr-1">                                        
                          </span> Back to Director Search
                      </button>

                  </div>                             
              </div>
          </div>

          <div class="row row-sm">                            
            <div class="col-lg-12 text-left">
                <h4 class="ml-3">{{director.name}}</h4>
            </div>
        </div>


          
          <div class="leavedetails">
              <div class="leavebox greenslip">
                  <div class="boxleave text-center">
                      <h4>Total number of appointments</h4>
                      <p><span class="pending_leave"> {{director.total_results}}</span></p>
                  </div>
              </div>
              <div class="leavebox greenslip" *ngIf="director.date_of_birth">
                  <div class="boxleave text-center">
                      <h4>Date of birth</h4>
                      <p><span class="pending_leave"> {{director.date_of_birth.month+'/01/'+director.date_of_birth.year | date: 'MMM yyyy'}}</span></p>
                  </div>
              </div>
          </div>
          <!-- <div class="button_box">
              <button type="submit" class="btn btn-dark com_btn" style="display: none;">Monitor</button>
              <button type="submit" class="btn btn-light com_btn" style="display: none;">More Actions <i class="fa fa-sort-desc" aria-hidden="true"></i>
              </button>
          </div> -->

          <div class="summary_sec">

            <div class="row" *ngFor="let company of director.items">
                <div class="col-sm-12">
                    <div class="keyinfo noheight">
                        <div class="row">
                            <div class="col-sm-12">
                                <h4><a style="color: #1caf9a;" (click)="getCompanyData(company.links.company)">{{company.appointed_to.company_name}} ({{company.appointed_to.company_number}})</a></h4>
                            </div>
                            <div class="col-sm-6">
                                
                            <div class="infodest">
                                <p>Company status</p>
                                <span>{{company.appointed_to.company_status.charAt(0).toUpperCase() + company.appointed_to.company_status.slice(1)}}</span>
                            </div>
                            <div class="infodest">
                                <p>Role</p>
                                <span>{{company.officer_role.charAt(0).toUpperCase() + company.officer_role.slice(1)}}</span>
                            </div>
                            <div class="infodest">
                              <p>Nationality</p>
                              <span>{{company.nationality}}</span>
                          </div>
                          <div class="infodest">
                              <p>Occupation</p>
                              <span>{{company.occupation}}</span>
                          </div>
                            
                            
                            </div>
                            <div class="col-sm-6">
                                
                                <div class="infodest">
                                    <p>Correspondence address</p>
                                <span>{{company.address.premises}}, {{company.address.locality}}, {{company.address.country}}, {{company.address.postal_code}}</span>
                                </div>
                                <div class="infodest">
                                    <p>Appointed on</p>
                                    <span>{{company.appointed_on | date: 'dd MMMM yyyy'}}</span>
                                </div> 
                                <div class="infodest">
                                  <p>Country of residence</p>
                                  <span>{{company.country_of_residence}}</span>
                              </div>                                              
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


          <!-- <mat-tab-group [@.disabled]="true">             
              
              <mat-tab label="Companies">
                  <ng-template matTabContent>
                     
                  </ng-template>
              </mat-tab>
              
          </mat-tab-group> -->
      </div>
  </div>
</div>

<div class="company_adver_search" *ngIf="mode ==3">
    <div class="container">
        <div class="com_risk_option">   
            <div class="row row-sm topheader">                            
                <div class="col-lg-5 text-right">
                    <app-checkcountshow></app-checkcountshow>
                </div>
                <div class="col-lg-7 text-right">
                    <div class="d-flex justify-content-end align-items-center">                          
                        <button class="btn com_btn btn-success small_btn" (click)="chnagestatus(1)"  type="button">
                            <span class="lnr lnr-history pr-1">                                        
                            </span> Back to Direct Search Result
                        </button>
                    </div>                             
                </div>
            </div>


            <div class="row row-sm">                            
                <div class="col-lg-5 text-left">
                    <h4>{{companydata.company_name}}</h4>
                </div>
            </div>

            <div class="leavedetails">
                <div class="leavebox greenslip">
                    <div class="boxleave text-center">
                        <h4>Company number</h4>
                        <p><span class="pending_leave"> {{companydata.company_number}}</span></p>
                    </div>
                </div>
            </div> 
            
            <div class="summary_sec">
  
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="keyinfo noheight">
                                        <div class="row">
                                            <!-- <div class="col-sm-12">
                                                <h4></h4>
                                            </div> -->
                                            <div class="col-sm-6">
                                                <div class="infodest">
                                                    <p>Registered office address</p>
                                                    <span>{{companydata.registered_office_address.address_line_1}}, {{companydata.registered_office_address.locality}}, {{companydata.registered_office_address.country}}, {{companydata.registered_office_address.postal_code}}</span>
                                                </div>
                                                <div class="infodest">
                                                    <p>Company status</p>
                                                    <span>{{companydata.company_status.charAt(0).toUpperCase() + companydata.company_status.slice(1)}}</span>
                                                </div>
                                                <div class="infodest">
                                                    <p>Company type</p>
                                                    <span>{{companydata.type}}</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="infodest">
                                                    <p>Dissolved on</p>
                                                    <span>{{companydata.date_of_cessation | date: 'dd MMMM yyyy'}}</span>
                                                </div>
                                                <div class="infodest">
                                                    <p>Incorporated on</p>
                                                    <span>{{companydata.date_of_creation | date: 'dd MMMM yyyy'}}</span>
                                                </div>                                              
                                            </div>
                                        </div>
                                        <div class="row">
                                            
                                            <div class="col-sm-6">
                                                <h4>Accounts</h4>
                                                <div class="infodest">
                                                    <!-- <p>Registered office address</p> -->
                                                    <span *ngIf="companydata.accounts && companydata.accounts.last_accounts">Last accounts made up to {{(companydata.accounts.last_accounts.made_up_to)?(companydata.accounts.last_accounts.made_up_to | date: 'dd MMMM yyyy'):''}}</span>
                                                    <span *ngIf="!companydata.accounts && !companydata.accounts.last_accounts">No record found.</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <h4>Annual return</h4>
                                                <div class="infodest">
                                                    <!-- <p>Dissolved on</p> -->
                                                    <span *ngIf="companydata.annual_return">Last annual return made up to {{(companydata.annual_return.last_made_up_to)?(companydata.annual_return.last_made_up_to | date: 'dd MMMM yyyy'):''}}</span>
                                                    <span *ngIf="!companydata.annual_return">No record found.</span>
                                                </div>                                           
                                            </div>
                                            <div class="col-sm-12">
                                                <h4>Nature of business (SIC)</h4>
                                                <div class="infodest">
                                                    <!-- <p>Dissolved on</p> -->
                                                    <span *ngFor="let sic of companydata.sic_codes">{{sic}} - Other business activities</span>
                                                </div>                                           
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            <!-- <mat-tab-group [@.disabled]="true">             
                
                <mat-tab label="Overview">
                    <ng-template matTabContent>
                       
                    </ng-template>
                </mat-tab>
                
            </mat-tab-group> -->
        </div>
    </div>
  </div>

<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
  <div role="document" class="formgroup">
      <div class="modal-content">         
          <div class="modal-body">
              <div class="container text-center">
              <div class="info_mess">
                  <div class="para">
                          <h4 class="mb-2">No credits available</h4>
                          <p class="mb-0 font20">There are no credits available to perform this action. Please add more credits.</p>
                      </div>                          
                  </div>
              </div>
          </div>
          <div class="modal-footer text-center">   
          <input type="button" class="btn btn-primary com_btn " name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
          <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
          </div>
      </div>
  </div>
</jw-modal>


  
<jw-modal id="confirm-modal" role="dialog" class="modal sendrequestcharge">
  <div role="document" class="formgroup">
      <div class="modal-content">
          <div class="modal-body p-4">
              <div class="container text-center">
                  <div class="info_mess">
                      <div class="para">
                          <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
                          
                      </div>                          
                  </div>
              </div>
          </div>
          <div class="modal-footer text-center">
              <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Confirm"  (click)="getDataByDirectorCodesubmit(peopleId)"  />
              <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('confirm-modal');"/>
          </div>
      </div>
  </div>
</jw-modal>

