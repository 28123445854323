import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})

export class ApplicantService {
  constructor(private commonService: CommonService) { }

  getcandidatelist(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/candidatereport',data);   
  }

  getcandidatestatus(): Observable<any> 
  {
    return this.commonService.formget('/company/candidatestatus');   
  }

  getemailertemplate(value: any): Observable<any> 
  {
    return this.commonService.formpost('/candidate/statusemailer', value);   
  }
}