<div class="bg">
  <app-header></app-header>


  <div class="section-wrapper">
      <div class="container">
          <div class="tabs" id="tabs">
              <a [routerLink]="['/ai-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to AI Dashboard</a>
              <div class="clearfix"></div>
              <div class="tabs__content">

                  <div class="tabs__content__item active-tab">
                      <div class="content">
                          <div class="space_em row">
                              <div class="col-lg-6">
                                  <h5 class="hedingcolor">Visa List
                                  </h5>
                              </div>
                              <div class="col-lg-3">
                                <select name="employee" #employid (change)="changeEmployee($event.target.value)" class="form-control input-lg">
                                <option value="">Select Employee</option>
                            <option *ngFor="let emp of employeelist" value="{{emp.employeemasterid}}" [selected]="emp.employeemasterid==empid">
                                {{ emp.fname }} {{ emp.mname }} {{ emp.lname }} ( {{ emp.employeeid }})</option>
                            </select>
                            </div>
                          </div>
                          <div class="job_list_section visa_request_section pb-2" *ngFor="let visas of pageOfItems;">
                              <div class="job_detils_date ">
                                <div class="visa_user_pic">
                                    <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                    <img *ngIf="visas.employee.imagename" src="{{visas.employee.imagename}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                    <img *ngIf="visas.employee.gender == true && (visas.employee.imagename == null || visas.employee.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                    <img *ngIf="visas.employee.gender == false && (visas.employee.imagename == null || visas.employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                </div>
                              </div>
                              <div class="job_detils_date width22">
                                  <div class="visa_user_details">
                                      <h4>{{visas.employee.fname}} {{visas.employee.mname}} {{visas.employee.lname}}</h4>
                                      <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"></i> {{ visas.employee.emailid }}
                                      </p>
                                      <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i> {{ visas.employee.phonenumber }}</p>
                                      <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i> NI Number {{ visas.employee.ninumber }} </p>
                                      <span *ngIf="visas.employee.nationality" class="badge badge-success">{{ visas.employee.nationality.countryname }} </span>
                                  </div>
                              </div>
                              <div class="job_detils_date width15">
                                  <p class="job_address">Visa Number</p>
                                  <p class="job_profile">{{visas.visanumber}}</p>
                              </div>
                              <div class="job_detils_date width13">
                                  <p class="job_address">Start Date</p>
                                  <p class="job_profile">{{visas.visastart | date: 'dd MMM yyyy'}}</p>
                              </div>
                              <div class="job_detils_date width13">
                                  <p class="job_address">End Date</p>
                                  <p class="job_profile">{{visas.visaexpiry | date: 'dd MMM yyyy'}}</p>
                              </div>
                              <div class="job_detils_date aligncenter mob width10">

                                  <div id="container" class="green_text" *ngIf="visas.iscurrent">
                                      <div class="dot"></div>
                                      <div class="pulse"></div>

                                  </div>
                                  <p *ngIf="visas.iscurrent" class="job_profile green_text">Current </p>
                                  <div id="container" class="" *ngIf="!visas.iscurrent">
                                      <div class="dot"></div>
                                      <div class="pulse"></div>

                                  </div>
                                  <p *ngIf="!visas.iscurrent" class="job_profile">Expired </p>

                              </div>
                              <div class="job_detils_date visa_btn viewbtnsow mob">

                                  <p class="job_address">
                                      <button type="submit" class="btn btn-primary com_btn" (click)="openModal3('pdf-viewer-visa',visas.visadocname)" *ngIf="visas.visadocname"><i class="fa fa-file-image-o"></i></button>
                                      <!-- <button type="submit" class="btn btn-danger com_btn" (click)="openModal('custom-visa-1',visas)"><i class="fa fa-pencil"></i></button>
                                      <button type="submit" class="btn btn-secondary com_btn" (click)="onDelete(visas.employeevisadeatilid)"><i class="fa fa-trash-o"></i></button> -->
                                  </p>
                              </div>
                             <div class="width100">
                                <hr class="mb-2 mt-2"/>
                             </div>
                              <div class="width100">                                   
                                  <div class="pull-left">                                 
                                      <p class="badge badge-warning mb-0" *ngIf="visas.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Awaiting Authorisation </p>
                                      <p class="badge badge-success mb-0" *ngIf="visas.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                      <p class="badge badge-error mb-0" *ngIf="visas.isaiverify == '3'"><i class="fa fa-close"></i> Rejected by AI </p>
                                  </div>                                   
                                  <div class="pull-right">
                                      <p class="mb-0" *ngIf="aipayment ==false && visas.nationality.isvisa == true && visas.isaiverify != '1'"> Verify the ID using Complygate AI. <a  [routerLink]="['/employeeaidetail',visas.employeevisadeatilid]" >Click Here</a></p>                                        
                                      <p class="mb-0" *ngIf="aipayment ==true && visas.nationality.isvisa == true && visas.isaiverify != '1'"> You have exhausted the free AI ID verification. To continue using it please  <a  [routerLink]="['/employeeaidetail',visas.employeevisadeatilid]" >Click Here</a></p>  
                                      <p class="mb-0" *ngIf="visas.nationality.isvisa == false"> The AI verification functionality for this country will be available soon.</p>                                                                              
                                  </div>
                              </div>  

                          </div>
                          <div class="job_list_section visa_request_section" *ngIf="record == 0">
                            <div class="job_detils_date">
                                <p class="job_profile">No Record Found</p>
                            </div>
                        </div>

                          <div class="row">
                            
                              <div class="col-lg-12 text-right">
                                  <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                              </div>
                          </div>





                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>




<jw-modal id="custom-visa-1" role="dialog" class="modal">

  <form [formGroup]="visapopForm" #visaForm="ngForm" id="visa-form-submit" class="s12 white" *ngIf="visaDetail">
      <div role="document" class="formgroup">
          <div class="modal-content">
              <div class="modal-header">

                  <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="visaDetail.employeeid">
                  <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="visaDetail.employeevisadeatilid">


                  <h5 class="modal-title" id="popupLabel">Visa Details</h5>
                  <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-visa-1');">
          <span aria-hidden="true">&times;</span>
        </button>
              </div>
              <div class="modal-body">
                  <div class="container">
                      <div class="row">
                          <div class="form-group col-lg-6">
                              <label>Visa No. *</label>
                              <input type="visanumber" formControlName="visanumber" name="visanumber" id="visanumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.visanumber.errors }" [(ngModel)]="visaDetail.visanumber">
                              <div *ngIf="submitted && f.visanumber.errors" class="invalid-feedback">
                                  <div *ngIf="f.visanumber.errors.required">Please Enter Visa No.</div>
                              </div>
                          </div>
                          <div class="form-group col-lg-6">
                              <label>Visa Type *</label>
                              <input type="visatype" formControlName="visatype" class="" name="visatype" id="visatype" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.visatype.errors }" [(ngModel)]="visaDetail.visatype">
                              <div *ngIf="submitted && f.visatype.errors" class="invalid-feedback">
                                  <div *ngIf="f.visatype.errors.required">Please Enter Visa Type </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="form-group col-lg-6">
                              <label>Start Date *</label>
                              <mat-form-field class="datecontrol">
                                  <input matInput [matDatepicker]="picker1" name="visastart" id="visastart" class="form-control" formControlName="visastart" [ngClass]="{ 'is-invalid': submitted && f.visastart.errors }" [(ngModel)]="visaDetail.visastart">
                                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                  <mat-datepicker #picker1></mat-datepicker>
                              </mat-form-field>
                              <div *ngIf="submitted && f.visastart.errors" class="invalid-feedback">
                                  <div *ngIf="f.visastart.errors.required">Please Choose Start Date</div>
                              </div>
                          </div>
                          <div class="form-group col-lg-6">
                              <label>Expiry Date *</label>
                              <mat-form-field class="datecontrol">
                                  <input matInput [matDatepicker]="picker2" [ngClass]="{ 'is-invalid': submitted && f.visaexpiry.errors }" name="visaexpiry" formControlName="visaexpiry" [min]="visapopForm.controls.visastart.value" [(ngModel)]="visaDetail.visaexpiry" id="visaexpiry" class="form-control  datecontrol">
                                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                  <mat-datepicker #picker2></mat-datepicker>

                              </mat-form-field>
                              <div *ngIf="submitted && f.visaexpiry.errors" class="invalid-feedback">
                                  <div *ngIf="f.visaexpiry.errors.required">Please Choose Expiry Date</div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="form-group col-lg-6">
                              <label>Visa Issue Country *</label>
                              <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [(ngModel)]="visaDetail.nationalityid">
                <option [value]="">Select Visa Country </option>
                <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
              </select>
                              <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                  <div *ngIf="f.nationalityid.errors.required">Please Select Visa Issue Country</div>
                              </div>
                          </div>
                          <div class="col-lg-6">
                              <label>Upload Document</label>
                              <div class="input-group mb-3" (click)="file.click()">
                                  <!-- <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroupFileAddon01">Upload</span>
                      </div> -->
                                  <div class="custom-file">
                                      <input type="file" #file (change)="selectFile($event)">
                                      <label class="custom-file-label">{{filename}}</label>
                                  </div>
                              </div>
                              <div class="alert-danger" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                              <div class="alert-danger" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png, tif, gif, pdf, doc)
                              </div>

                          </div>
                      </div>
                      <div class="row">
                          <div class="form-group col-lg-6">
                              <div>
                                  Is this your Current Visa?
                                  <input type="checkbox" [checked]="visaDetail.iscurrent" formControlName="iscurrent" name="iscurrent" [(ngModel)]="visaDetail.iscurrent">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                  <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" (click)="onSubmit(visaForm.value,'custom-visa-1', '1')" />
                  <input type="submit" class="btn btn-success com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(visaForm.value,'custom-visa-1', '2')" value="Update & Approve" />
                  <input type="submit" class="btn btn-danger com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(visaForm.value,'custom-visa-1', '3')" value="Reject" />

                  <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-visa-1');" />

              </div>
          </div>
      </div>
  </form>
</jw-modal>

<jw-modal id="pdf-viewer-visa" class="modal" role="dialog">
  <div role="document" class="formgroup">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-viewer-visa');">
        <span aria-hidden="true">&times;</span>
      </button>
          </div>
          <div class="modal-body">
              <div class="container">

                  <div class="row">
                      <div class="col-lg-12">

                          <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="600" frameborder="0"></iframe>
                          <img *ngIf="imgsrc == true" [src]="iframesrc" width="100%" />
                      </div>
                  </div>

              </div>
          </div>

      </div>
  </div>
</jw-modal>