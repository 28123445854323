import { Component, OnInit, Input, Output,EventEmitter,SimpleChanges } from '@angular/core';
import { CountryService } from '../../../services/country.service';
import { MyidproofService } from '../../../services/myidproof.service';
import { ModalService } from '../../../_services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Idproof } from '../../../models/idproof/idproof.model';
import { UploadFileService } from './../../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertService } from '../../../services/alert.service';
import { AicenterService } from '../../../services/aicenter.service';


@Component({
  selector: 'app-idproofrequest',
  templateUrl: './idproofrequest.component.html',
  styleUrls: ['./idproofrequest.component.css']
})
export class IdproofrequestComponent implements OnInit {

  
  @Input() items: Array<any>;
  defaultVal:any=''; searchText:any;
  pageOfItems: Array<any>;
  record: any;
  datalist:any;
  idprooflist: any[];
  idproofDetail: any;
  countrylist: any;
  Userid: any;
  listcheck: any;
  selectedFiles: FileList;
  iframesrc: any;
  imgsrc: any;
  buttoninsert: any;
  filesizeerror: any;
  fileformaterror: any;
  file: any;
  filename: any;
  data: any;
  disableBtn: any;
  documenttype:any;
  aipayment: boolean =false;
  imgidproofbacksrc:any;
  iframeidproofbacksrc:any;

  filereuirederror: any;
  
  filebacksizeerror: any;
  filebackformaterror: any;
  selectedbackFiles: FileList;
  filebackname: any;  
  imagesecond: any;


  filterItems = [
    {
      name: 'All idproof Details',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Waiting for review',
      id: 1,
      selected: false
    }
  ]
  submitted: any;
  idproofpopForm: FormGroup;
  idproof: Idproof;
  permission: any;
  requestperm: any;
  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private idproofService: MyidproofService, 
    private country: CountryService,  
    private aicenter:AicenterService,
    private modalService: ModalService, 
    private router: Router,
    private alerts: AlertService
    ) { }
  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;

    this.idproofpopForm = this.formBuilder.group({      
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      idnumber: ['', Validators.required],
      idtype: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      docexpiry: ['', Validators.required]
    });
    this.filename = 'Choose file';
    this.filebackname = 'Choose file';
    this.idproofDetail = new Idproof();
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'cstatus': [1] };

    this.idproofService.iddocumenttype().subscribe((documenttype: any) => {
      this.documenttype = documenttype;
    });
    
    this.aicenter.getcountpayment().subscribe((data: any) => {     
      this.aipayment = data.payment;
    });


    this.idproofService.getallidproofListbyadmin(data).subscribe((data: any) => {     

      this.record  = data.length;
      this.datalist = data;
      if(this.record < 30){
        this.pageOfItems = data;
      }
      else{
           this.items =data;
      }

      // this.data = data;
      // this.record = data.length;
    });


    


    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.listcheck = this.filterItems;
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}

  onDelete(id: number) {
    const data1 = { 'id': id };
    if (confirm("Are you sure you want to delete?")) {
      this.idproofService.deleteidproof(data1).subscribe((data: any) => {
        var sstatus: any;
        for (let c of this.filterItems) {
          if (c.selected == true) {
            if (c.id == 0) {
              sstatus = [1];
            }
            else {
              sstatus = [c.id];
            }
          }
        }
        const data2 = { 'id': this.Userid, 'cstatus': sstatus };
        this.idproofService.getallidproofListbyadmin(data2).subscribe((data: any) => {
          this.record  = data.length;

          if(this.record < 30){
            this.pageOfItems = data;
          }
          else{
               this.items =data;
          }
        });
        this.alerts.success('Address Proof Deleted Successfully.', true);

      });
    }
  }

  onlistchange(type1: any, idval: number) {
    var sstatus: any;
    for (let c of this.filterItems) {
      if (c.id == idval)
        c.selected = true;
      else
        c.selected = false;
    }
    if (idval == 0 || type1 == false) {
      sstatus = [1];
    }
    else {
      sstatus = [idval];
    }
    var userid = localStorage.getItem('userid');
    const data = { 'id': userid, 'cstatus': sstatus };
    this.idproofService.getallidproofListbyadmin(data).subscribe((data: any) => {
      this.data = data;
    });
  }

  get f() {
    return this.idproofpopForm.controls;
  }
  

  onSubmit(value3: any, id: string, stype: string) {
    this.submitted = true;
    if (this.idproofpopForm.invalid) {
      return;
    }
    this.disableBtn = false;
    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") ) {
        this.fileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.imagename = imagepath;
    }
    else {
      value3.imagename = this.idproofDetail.iddocName;
    }
 
    if (this.selectedbackFiles) {
      this.file = this.selectedbackFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
        this.fileformaterror = true;
        return;
      }
      var imagepath =  this.uploadService.uploadfile(this.file);
      value3.imagebackname = imagepath;
    }
    else {
      if(value3.id == '0')
      {
        value3.imagebackname = '';
       }
     else {
      value3.imagebackname = this.idproofDetail.idbackdocName;
     }
     
    }


    value3.stype = stype;
    value3.createby = this.Userid;
    
    this.idproofService.myidproofdetailsUpdatebyadmin(value3).subscribe((data: any) => {
      var sstatus: any;
      for (let c of this.filterItems) {
        if (c.selected == true) {
          if (c.id == 0) {
            sstatus = [1];
          }
          else {
            sstatus = [c.id];
          }
        }
      }
      var userid = localStorage.getItem('userid');
      const data1 = { 'id': userid, 'cstatus': sstatus };
      this.idproofService.getallidproofListbyadmin(data1).subscribe((data: any) => {
        this.record  = data.length;

        if(this.record < 30){
          this.pageOfItems = data;
        }
        else{
             this.items =data;
        }
      });
    });
    this.filename = "Choose file";
    this.alerts.success('Address Proof Details updated successfully.', true);    
    // this.idproofDetail = new idproof();
    this.modalService.close(id);
  }


  selectFile(event)
   {
   var files = event.target.files;
    this.filename = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.fileformaterror = true;  
      this.filesizeerror = false;      
    }else{
      this.filesizeerror = false;  
      this.fileformaterror = false;
      this.filereuirederror =false;
      this.imagesecond =  this.uploadService.uploadfile(this.file);
      this.selectedFiles = files[0];
    }
  }

  selectbackFile(event)
   {
    var files = event.target.files;
    this.filebackname = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filebacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filebackformaterror = true;    this.filebacksizeerror = false; 
    }else{this.filebacksizeerror = false;  
      this.filebackformaterror = false;
     this.imagesecond =  this.uploadService.uploadfile(this.file);
      this.selectedbackFiles = files[0];
    }
  } 

  formsubmit()
  {
    // document.getElementById('idproof-form-submit').submit();
  }

  openModal2(id: string) 
  {
    this.submitted = false;
    this.disableBtn = true;
    this.buttoninsert = "Submit";
    this.idproofDetail = new Idproof();    
    this.Userid = localStorage.getItem('userid');
    this.idproofDetail.employeeid = this.Userid;
    this.idproofDetail.employeeidproofdeatilid = "0";    
    // this.idproofDetail.idproofexpiry ='';
    // this.idproofDetail.idproofstart = '';
    // this.idproofDetail.idprooftype = '';
    // this.idproofDetail.nationalityid = '';
    this.filename = "Choose file";    
    
    this.modalService.open(id);
  }



  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModal3(id: string, data: any, backimg:any) 
  {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    if(backimg)
    {
      var fullpathArray = backimg.split('.');
      var typeoffile = fullpathArray[fullpathArray.length - 1];
      if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
        this.imgidproofbacksrc = true;
        this.iframeidproofbacksrc = backimg;
      } 
      else {
        this.imgidproofbacksrc = false;
        this.iframeidproofbacksrc = this.getSafeUrl("https://docs.google.com/gview?url=" + backimg + "&embedded=true");
      }
    }
    else {
      this.imgidproofbacksrc = false;
      this.iframeidproofbacksrc = '';
    }
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.idproof = new Idproof();
    this.disableBtn = true;
    this.idproofDetail = new Idproof();
    this.idproofDetail = data;

   // console.log(data);
    this.buttoninsert = "Update";
    this.modalService.open(id);
  }

  closeModal(id: string) 
  {
    
    const data = { 'id': this.Userid, 'cstatus': [1] };
    this.idproofService.getallidproofListbyadmin(data).subscribe((data: any) => {
      this.data = data;
    });
    this.modalService.close(id);
  }


}
