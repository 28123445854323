"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var MyaddressService = /** @class */ (function () {
    function MyaddressService(commonService) {
        this.commonService = commonService;
        this.modals = [];
    }
    MyaddressService.prototype.getemploymenttList = function (value) {
        return this.commonService.formpost('/employee/currentaddresslist', value);
    };
    MyaddressService.prototype.getemploymenttListbyadmin = function (value) {
        return this.commonService.formpost('/employee/currentaddresslistbyadmin', value);
    };
    MyaddressService.prototype.getemploymenttapprove = function () {
        return this.commonService.formpostOnlyid('/employee/currentaddressapprove');
    };
    MyaddressService.prototype.getemploymenttrejected = function () {
        return this.commonService.formpostOnlyid('/employee/currentaddressrejected');
    };
    MyaddressService.prototype.myaddressdetailsUpdate = function (value) {
        return this.commonService.formpost('/employee/addressupdate', value);
    };
    MyaddressService.prototype.myaddressdetailsUpdatebyadmin = function (value) {
        return this.commonService.formpost('/employee/addressupdatebyadmin', value);
    };
    MyaddressService.prototype.myaddresschangecurrent = function (value) {
        return this.commonService.changecurrentaddress('/employee/changecurrentaddress', value);
    };
    MyaddressService.prototype.myaddressDelete = function (value) {
        return this.commonService.formpost('/employee/deleteAddress', value);
    };
    MyaddressService.ngInjectableDef = i0.defineInjectable({ factory: function MyaddressService_Factory() { return new MyaddressService(i0.inject(i1.CommonService)); }, token: MyaddressService, providedIn: "root" });
    return MyaddressService;
}());
exports.MyaddressService = MyaddressService;
