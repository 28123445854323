"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var forms_1 = require("@angular/forms");
var employee_model_1 = require("../../../models/employee.model");
var country_service_1 = require("../../../services/country.service");
var employee_service_1 = require("../../../services/employee.service");
var employeement_service_1 = require("../../../services/employeement.service");
var job_service_1 = require("../../../services/job.service");
var project_service_1 = require("../../../services/project.service");
var timesheet_service_1 = require("../../../services/timesheet.service");
var excel_service_1 = require("../../../services/excel.service");
var modal_service_1 = require("../../../_services/modal.service");
var app_global_1 = require("../../../app.global");
var worklist_service_1 = require("../../../services/worklist.service");
var router_1 = require("@angular/router");
var ViewemployeereportComponent = /** @class */ (function () {
    function ViewemployeereportComponent(excelService, formBuilder, project, timesheet, country, jobser, employee, modalService, listdata, datePipe, route, employeementreport) {
        var _this = this;
        this.excelService = excelService;
        this.formBuilder = formBuilder;
        this.project = project;
        this.timesheet = timesheet;
        this.country = country;
        this.jobser = jobser;
        this.employee = employee;
        this.modalService = modalService;
        this.listdata = listdata;
        this.datePipe = datePipe;
        this.route = route;
        this.employeementreport = employeementreport;
        this.employeeDetail = new employee_model_1.Employee();
        this.exceldata = [];
        this.exptype = '';
        route.params.subscribe(function (params) {
            if (params['id']) {
                _this.empid = params['id'];
            }
        });
        route.queryParams.subscribe(function (params) {
            _this.employeemonth = params['month'];
            _this.employeeyear = params['year'];
            _this.hstatus = params['status'];
            _this.exptype = params['type'];
        });
    }
    ViewemployeereportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        this.listdisplay = "none";
        this.graphdisplay = "block";
        this.EmployeeForm = this.formBuilder.group({
            emailid: [''],
            phonenumber: [''],
            ninumber: [''],
            minstartdate: [''],
            maxstartdate: [''],
            minenddate: [''],
            maxenddate: [''],
            hourtypeid: [''],
            minhour: [''],
            maxhour: [''],
            projectid: [''],
            fname: [''],
            hstatus: [''],
            searcText: ['']
        });
        // this.employeemonth = getMonth(new Date()) + 1;
        // this.employeeyear = getYear(new Date());
        // this.hstatus = '';
        var value3 = "{}";
        this.timesheetdetails();
        this.timesheet.getloactionlist().subscribe(function (locationlistdetails) {
            _this.locationlist = locationlistdetails;
            // this.record = this.data.length;
            // if (this.record < 30) {
            //   this.pageOfItems = this.data;
            // }
            // else {
            //   this.items = this.data;
            // }
        });
        this.Userid = localStorage.getItem('userid');
        var sstatustype = { 'status': true, 'id': this.Userid };
        this.project.getprojectlist(sstatustype).subscribe(function (projectdetails) {
            _this.projectlist = projectdetails;
        });
        var value3 = "{}";
        this.employee.getemployeelist(value3).subscribe(function (listdetails) {
            _this.employeelist = listdetails;
        });
        this.listdata.gethourtypelist({ 'status': true, 'id': this.Userid }).subscribe(function (expensedetails) {
            _this.expenselist = expensedetails;
        });
    };
    ViewemployeereportComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    ViewemployeereportComponent.prototype.showcondition = function (type) {
        if (type == 'list') {
            this.graphdisplay = "none";
            this.listdisplay = "block";
        }
        else {
            this.listdisplay = "none";
            this.graphdisplay = "block";
        }
    };
    ViewemployeereportComponent.prototype.exportAsXLSX = function () {
        var _this = this;
        this.exceldata = [];
        this.data.forEach(function (obj) {
            _this.employeetimesheetdata = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "Hours": obj.ahour,
                "Hour Type": obj.hourtype.typename,
                "Project": obj.project.projectname,
                "Location": obj.location.address1,
                "Date": _this.datePipe.transform(new Date(obj.startdate), "dd MMM yyyy")
            };
            _this.exceldata.push(_this.employeetimesheetdata);
        });
        this.excelService.exportAsExcelFile(this.exceldata, 'sample');
    };
    ViewemployeereportComponent.prototype.onSubmit = function (value3) {
    };
    ViewemployeereportComponent.prototype.timesheetdetails = function () {
        var _this = this;
        console.log(this.employeemonth);
        console.log(this.empid);
        // console.log(this.employeemonth);
        // console.log(this.employeemonth);
        this.timesheet.gettimesheetList({ 'month': this.employeemonth, 'year': this.employeeyear, 'employeeid': this.empid, 'hstatus': this.hstatus, 'hourtypeid': this.exptype }).subscribe(function (timesheetDetails) {
            _this.record = timesheetDetails.length;
            _this.data = timesheetDetails;
        });
    };
    ViewemployeereportComponent.prototype.openModalnote = function (data, id) {
        this.notedetails = data;
        this.modalService.open(id);
    };
    ViewemployeereportComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    ViewemployeereportComponent.prototype.changeEmployee = function (id) {
        this.empid = id;
        this.timesheetdetails();
    };
    ViewemployeereportComponent.prototype.changeEmployeeMonth = function (month) {
        this.employeemonth = month;
        this.timesheetdetails();
    };
    ViewemployeereportComponent.prototype.changeEmployeeYear = function (year) {
        this.employeeyear = year;
        this.timesheetdetails();
    };
    ViewemployeereportComponent.prototype.changeEmployeestatus = function (hstatus) {
        this.hstatus = hstatus;
        this.timesheetdetails();
    };
    ViewemployeereportComponent.prototype.changeExpenseType = function (exptype) {
        this.exptype = exptype;
        this.timesheetdetails();
    };
    return ViewemployeereportComponent;
}());
exports.ViewemployeereportComponent = ViewemployeereportComponent;
