"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Asset = /** @class */ (function () {
    function Asset() {
        this.employeeid = "";
        this.categoryid = "";
        this.statdate = new Date();
    }
    return Asset;
}());
exports.Asset = Asset;
var Assetcategory = /** @class */ (function () {
    function Assetcategory() {
    }
    return Assetcategory;
}());
exports.Assetcategory = Assetcategory;
