"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var PlanService = /** @class */ (function () {
    function PlanService(commonService) {
        this.commonService = commonService;
    }
    PlanService.prototype.getplanlist = function (value) {
        // return this.commonService.formpostOnlyid('/company/plandetails');
        return this.commonService.formpost('/company/plandetails', value);
    };
    PlanService.prototype.checkcounpons = function (value) {
        return this.commonService.formpost('/checkcoupons', value);
    };
    PlanService.prototype.updatecompanyplan = function (data4) {
        return this.commonService.formpost('/sucessuserplan', data4);
    };
    PlanService.prototype.getplanlistwithcoupons = function (data4) {
        return this.commonService.formpost('/plancouponsbyuser', data4);
    };
    PlanService.ngInjectableDef = i0.defineInjectable({ factory: function PlanService_Factory() { return new PlanService(i0.inject(i1.CommonService)); }, token: PlanService, providedIn: "root" });
    return PlanService;
}());
exports.PlanService = PlanService;
