<div class="bg">
    <app-header></app-header>

    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <a [routerLink]="['/listjob']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Job List</a>
                <div class="clearfix"></div>
                <div class="tabs__content">

                    <div class="tabs__content__item active-tab">
                        <div class="content listcandidate"> 
                            <div class="space_em row ">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor floatleft">Applicant List</h5>
                                    <a (click)="openModal2('custom-visa-1')" class="mainheading_btn"> + Add New </a>
                                </div>
                                <div class="col-lg-3">
                                    <div class="input-group">
                                        <input [(ngModel)]="searchText" placeholder="Search.." class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <select class="form-control input-lg" (change)="selectjoblistbystatus($event.target.value)">
                                        <option value="0" >All Applicants</option>
                                        <option  *ngFor="let candidatestatus of candidatestatuslist" [selected]="statusid == candidatestatus.candidatestatusmasterid"  value="{{ candidatestatus.candidatestatusmasterid}}">{{ candidatestatus.statusname }}</option>

                                    </select>
                                </div>
                            </div>

                            <ng-container *ngIf="( datalist | filter: {fname: searchText, mname: searchText,lname: searchText, v:searchText,  emailid: searchText, smarks: searchText  }) as pageOfItems"> 
                           

                            <div class="job_list_section" *ngFor="let candidate of pageOfItems;  let i=index">
                                <div class="job_detils_date width22">
                                    <p class="job_profile_user"> <a [routerLink]="['/candidatedetails/',candidate.jobid,candidate.candidatemasterid]"> {{candidate.fname}} {{candidate.mname}} {{candidate.lname}}</a></p>
                                    <p class="job_address">{{candidate.emailid}}</p>
                                    <p class="job_create_date"> </p>
                                </div>
                                <div class="job_btn width22 flex_start">
                                    <div id="container">
                                        <div class="dot"></div>
                                        <div class="pulse"></div>
                                    </div>
                                    <a href="http://{{candidate.job.joburl1.replace('https://','').replace('http://','') | slice:0:25 }}" target="_blank" class="text-danger" *ngIf="candidate.joburlid=='0'">{{candidate.job.joburl1 | slice:0:25 }}</a>
                                    <a href="http://{{candidate.job.joburl2.replace('https://','').replace('http://','') | slice:0:25 }}" target="_blank" class="text-danger" *ngIf="candidate.joburlid=='1'">{{candidate.job.joburl2 | slice:0:25 }}</a>
                                    <a href="http://{{candidate.job.joburl3.replace('https://','').replace('http://','') | slice:0:25 }}" target="_blank" class="text-danger" *ngIf="candidate.joburlid=='2'">{{candidate.job.joburl3 | slice:0:25 }}</a>
                                    <a href="http://{{candidate.job.joburl4.replace('https://','').replace('http://','') | slice:0:25 }}" target="_blank" class="text-danger" *ngIf="candidate.joburlid=='3'">{{candidate.job.joburl4 | slice:0:25 }}</a>
                                </div>
                                <div class="job_activites width13 text-center">
                                    <ul>
                                        <li>
                                            <a (click)="openModal3(candidate.candidatemasterid, 'custom-skill-view')">
                                                <span class="active_job">{{candidate.smarks}}</span>
                                                <p class="active_job">Score</p>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="job_btn lis_btn width25">
                                    <div class="job_intrested">
                                        <div class="btn-group btn-group-toggle ">
                                            <label class="btn btn-secondary positionre green" [ngClass]="{ 'active': candidate.isinterested == '1'}">
                                                <input type="radio" name="options" id="option1" autocomplete="off" (change)="onChangeinterested(1,candidate.candidatemasterid)" [checked]="candidate.isinterested == '1'"> <i class="fa fa-check" aria-hidden="true"></i>
                                            </label>
                                            <label class="btn btn-secondary blue" [ngClass]="{ 'active': candidate.isinterested == '2'}">
                                                <input type="radio" name="options" id="option2" autocomplete="off" (change)="onChangeinterested(2,candidate.candidatemasterid)" [checked]="candidate.isinterested == '2'"> <i class="fa fa-question" aria-hidden="true"></i>
                                            </label>
                                            <label class="btn btn-secondary red" [ngClass]="{ 'active': candidate.isinterested == '3'}">
                                                <input type="radio" name="options" id="option3" autocomplete="off" (change)="onChangeinterested(3,candidate.candidatemasterid)" [checked]="candidate.isinterested == '3'"> <i class="fa fa-times" aria-hidden="true"></i>
                                            </label>
                                            <span class="tootlip apprr">Hired</span>
                                            <span class="tootlip ques">Awaiting Review</span>
                                            <span class="tootlip rejec">Rejected</span>
                                        </div>
                                    </div>
                                    <div class="action_filed">
                                        <div *ngIf="!candidate.employee">
                                            <div class="btn-group mobile100w" mdbDropdown *ngIf="candidate.isinterested != '3'">
                                                <button data-toggle="dropdown" type="button" mdbBtn color="primary" class="dropdown-toggle waves-light" mdbWavesEffect>
                                                    <span *ngIf="candidate.status">
                                                        {{ candidate.status.statusname }} <i class="fa fa-angle-down" aria-hidden="true"></i>
                                                    </span>
                                                    <span *ngIf="!candidate.status">
                                                        Action 
                                                    </span>
                                                   
                                                </button>

                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <p *ngFor="let candidatestatus of candidatestatuslist">
                                                        <a class="dropdown-item" *ngIf="candidatestatus.emailer.length == 0" (click)="statusChange(candidatestatus.candidatestatusmasterid,candidate);">
                                                            {{candidatestatus.statusname}}
                                                        </a>
                                                        <a class="dropdown-item" *ngIf="candidatestatus.emailer.length != 0" (click)="openModal6('custom-message-1', candidatestatus.candidatestatusmasterid,candidate.candidatemasterid,candidate.jobid);">
                                                            {{candidatestatus.statusname}}
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn-group mobile100w" mdbDropdown *ngIf="candidate.isinterested == '3'">
                                            <button data-toggle="dropdown" type="button" mdbBtn color="primary" class="dropdown-toggle waves-light" mdbWavesEffect>
                                                <span class="text-danger">
                                                    Rejected  
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>
                            
                        </ng-container>
                            <div  class="job_list_section visa_request_section " *ngIf="record == '0'">
                                <div class="job_detils_date">
                                    <p class="job_profile"> No Record Found </p>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<jw-modal id="custom-visa-1" role="dialog" class="modal">

    <form [formGroup]="candidateformGroup" #candidateForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(candidateForm.value,'custom-visa-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="candidatemasterid" class="form-control" name="candidatemasterid" id="candidatemasterid" [(ngModel)]="candidateDetail.candidatemasterid">
                    <input type="hidden" formControlName="jobid" class="form-control" name="jobid" id="jobid" [(ngModel)]="candidateDetail.jobid">
                    <h5 class="modal-title" id="popupLabel">Candidate Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-visa-1');">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>First Name*</label>
                                <input type="text" formControlName="fname" name="fname" id="fname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" [(ngModel)]="candidateDetail.fname">
                                <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                                    <div *ngIf="f.fname.errors.required">Please Enter First Name</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Middle Name </label>
                                <input type="text" formControlName="mname" name="mname" id="mname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mname.errors }" [(ngModel)]="candidateDetail.mname">
                                <div *ngIf="submitted && f.mname.errors" class="invalid-feedback">
                                    <div *ngIf="f.mname.errors.required">Please Enter Middle Name</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Last Name*</label>
                                <input type="text" formControlName="lname" class="" name="lname" id="lname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lname.errors }" ngModel="{{ candidateDetail.lname }}">
                                <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                                    <div *ngIf="f.lname.errors.required">Please Enter Last Name</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Email*</label>
                                <input type="email" formControlName="emailid" name="emailid" id="emailid" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }" [(ngModel)]="candidateDetail.emailid">
                                <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                                    <div *ngIf="f.emailid.errors.required">Please Enter Email </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Mobile Number</label>
                                <international-phone-number formControlName="phonenumber" class="form-control phonecontrol" [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" #phonenumber name="phonenumber" [(ngModel)]="candidateDetail.phonenumber" placeholder="Enter Mobile number"
                                    [maxlength]="20" (input)="validatenumber($event.target.value,'phone')"></international-phone-number>
                                <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.phonenumber.errors.required">Please Enter Mobile Number</div>
                                </div>
                                <span *ngIf="phonecheck===false" style="color:red">Invalid format</span>
                                <span *ngIf="phonecheck===true" style="color:green">Valid format</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Job URL </label>
                                <select style="width:100%;" formControlName="joburlid" name="joburlid" id="joburlid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.joburlid.errors }" [(ngModel)]="candidateDetail.joburlid">
                                    <option [value]="defaultVal">Select Job URL </option>
                                    <option *ngIf="(jobdata.joburl1 != '') && (jobdata.joburl1 != null)" [value]="0">
                                        {{ jobdata.joburl1 }}
                                    </option>
                                    <option *ngIf="(jobdata.joburl2 != '') && (jobdata.joburl2 != null)" [value]="1">
                                        {{ jobdata.joburl2 }}
                                    </option>
                                    <option *ngIf="(jobdata.joburl3 != '') && (jobdata.joburl3 != null)" [value]="2">
                                        {{ jobdata.joburl3 }}
                                    </option>
                                    <option *ngIf="(jobdata.joburl4 != '') && (jobdata.joburl4 != null)" [value]="3">
                                        {{ jobdata.joburl4 }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f.joburlid.errors" class="invalid-feedback">
                                    <div *ngIf="f.joburlid.errors.required">Please Select Job URL</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Upload Document</label>
                                <div class="input-group mb-3">
                                    <div class="custom-file" (click)="file.click()">
                                        <input type="file" #file (change)="selectFile($event)">
                                        <label class="custom-file-label">{{filename}}</label>
                                    </div>
                                </div>
                                <div class="alert-danger" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                <div class="alert-danger" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png, tif, gif, pdf, doc)
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Skill List </label>
                        </div>
                        <div class="skill_list">
                            <div class="colmElmProType" *ngFor="let cat of skilllist">
                                <input class="form-check-input" type="checkbox" value="1" id="{{cat.jobskilldetailid}}" [checked]="cat.check" (change)="onChangeCategory($event, cat)">
                                <label for="{{cat.jobskilldetailid}}">{{ cat.skillname }} 
                                <i class="fa fa-check ng-star-inserted"></i>                         
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-visa-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>


<jw-modal id="custom-message-1" role="dialog" class="modal">

    <form [formGroup]="candidatemessageFormGroup" #candidatemessageForm="ngForm" class="s12 white" (ngSubmit)="submitStatus(candidatemessageForm.value,'custom-message-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="candidateid" class="form-control" name="candidateid" id="candidateid" ngModel="{{emailerDetail.candidateid}}">
                    <input type="hidden" formControlName="jobid" class="form-control" name="jobid" id="jobid" [(ngModel)]="emailerDetail.jobid">
                    <input type="hidden" formControlName="ssid" class="form-control" name="ssid" id="ssid" [(ngModel)]="emailerDetail.ssid">
                    <h5 class="modal-title" id="popupLabel">User Message</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-message-1');">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label>Subject*</label>
                                <input type="text" formControlName="subject" class="" name="subject" id="subject" class="form-control" [ngClass]="{ 'is-invalid': submitted && g.subject.errors }" [(ngModel)]="emailerDetail.subject">
                                <div *ngIf="submitted && g.subject.errors" class="invalid-feedback">
                                    <div *ngIf="g.subject.errors.required">Please Enter Subject</div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="emailerDetail.ssid == '8'">
                            <div class="form-group col-lg-12">
                                <label>Location*</label>
                                <input type="text" formControlName="location" name="location" id="location" class="form-control" [ngClass]="{ 'is-invalid': submitted && g.location.errors }" [(ngModel)]="emailerDetail.location">
                                <div *ngIf="submitted && g.location.errors" class="invalid-feedback">
                                    <div *ngIf="g.location.errors.required">Please Enter Location</div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="emailerDetail.ssid == '8'">
                            <div class="form-group col-lg-4">
                                <label>Date*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker1" [min]="currenDate" name="interdate" id="interdate" [ngClass]="{ 'is-invalid': submitted && g.interdate.errors }" formControlName="interdate" class="form-control" [(ngModel)]="emailerDetail.interdate">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && g.interdate.errors" class="invalid-feedback">
                                    <div *ngIf="g.interdate.errors.required">Please Enter the Interview Date</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Start Time*</label>
                                <select name="interviewstime" formControlName="interviewstime" id="interviewstime" class="form-control">
                                    <option value="00:00">00:00</option>
                                    <option value="00:30">00:30</option>
                                    <option value="01:00">01:00</option>
                                    <option value="01:30">01:30</option>
                                    <option value="02:00">02:00</option>
                                    <option value="02:30">02:30</option>
                                    <option value="03:00">03:00</option>
                                    <option value="03:30">03:30</option>
                                    <option value="04:00">04:00</option>
                                    <option value="04:30">04:30</option>
                                    <option value="05:00">05:00</option>
                                    <option value="05:30">05:30</option>
                                    <option value="06:00">06:00</option>
                                    <option value="06:30">06:30</option>
                                    <option value="07:00">07:00</option>
                                    <option value="07:30">07:30</option>
                                    <option value="08:00">08:00</option>
                                    <option value="08:30">08:30</option>
                                    <option value="09:00">09:00</option>
                                    <option value="09:30">09:30</option>
                                    <option value="10:00">10:00</option>
                                    <option value="10:30">10:30</option>
                                    <option value="11:00">11:00</option>
                                    <option value="11:30">11:30</option>
                                    <option value="12:00">12:00</option>
                                    <option value="12:30">12:30</option>
                                    <option value="13:00">13:00</option>
                                    <option value="13:30">13:30</option>
                                    <option value="14:00">14:00</option>
                                    <option value="14:30">14:30</option>
                                    <option value="15:00">15:00</option>
                                    <option value="15:30">15:30</option>
                                    <option value="16:00">16:00</option>
                                    <option value="16:30">16:30</option>
                                    <option value="17:00">17:00</option>
                                    <option value="17:30">17:30</option>
                                    <option value="18:00">18:00</option>
                                    <option value="18:30">18:30</option>
                                    <option value="19:00">19:00</option>
                                    <option value="19:30">19:30</option>
                                    <option value="20:00">20:00</option>
                                    <option value="20:30">20:30</option>
                                    <option value="21:00">21:00</option>
                                    <option value="21:30">21:30</option>
                                    <option value="22:00">22:00</option>
                                    <option value="22:30">22:30</option>
                                    <option value="23:00">23:00</option>
                                    <option value="23:30">23:30</option>
                                </select>
                                <div *ngIf="submitted && g.location.errors" class="invalid-feedback">
                                    <div *ngIf="g.location.errors.required">Please Enter Start Time</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>End Time*</label>
                                <select name="interviewetime" formControlName="interviewetime" id="interviewetime" class="form-control">
                                    <option value="00:00">00:00</option>
                                    <option value="00:30">00:30</option>
                                    <option value="01:00">01:00</option>
                                    <option value="01:30">01:30</option>
                                    <option value="02:00">02:00</option>
                                    <option value="02:30">02:30</option>
                                    <option value="03:00">03:00</option>
                                    <option value="03:30">03:30</option>
                                    <option value="04:00">04:00</option>
                                    <option value="04:30">04:30</option>
                                    <option value="05:00">05:00</option>
                                    <option value="05:30">05:30</option>
                                    <option value="06:00">06:00</option>
                                    <option value="06:30">06:30</option>
                                    <option value="07:00">07:00</option>
                                    <option value="07:30">07:30</option>
                                    <option value="08:00">08:00</option>
                                    <option value="08:30">08:30</option>
                                    <option value="09:00">09:00</option>
                                    <option value="09:30">09:30</option>
                                    <option value="10:00">10:00</option>
                                    <option value="10:30">10:30</option>
                                    <option value="11:00">11:00</option>
                                    <option value="11:30">11:30</option>
                                    <option value="12:00">12:00</option>
                                    <option value="12:30">12:30</option>
                                    <option value="13:00">13:00</option>
                                    <option value="13:30">13:30</option>
                                    <option value="14:00">14:00</option>
                                    <option value="14:30">14:30</option>
                                    <option value="15:00">15:00</option>
                                    <option value="15:30">15:30</option>
                                    <option value="16:00">16:00</option>
                                    <option value="16:30">16:30</option>
                                    <option value="17:00">17:00</option>
                                    <option value="17:30">17:30</option>
                                    <option value="18:00">18:00</option>
                                    <option value="18:30">18:30</option>
                                    <option value="19:00">19:00</option>
                                    <option value="19:30">19:30</option>
                                    <option value="20:00">20:00</option>
                                    <option value="20:30">20:30</option>
                                    <option value="21:00">21:00</option>
                                    <option value="21:30">21:30</option>
                                    <option value="22:00">22:00</option>
                                    <option value="22:30">22:30</option>
                                    <option value="23:00">23:00</option>
                                    <option value="23:30">23:30</option>
                                </select>
                                <div *ngIf="submitted && g.location.errors" class="invalid-feedback">
                                    <div *ngIf="g.location.errors.required">Please Enter End Time</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label>Message*</label>
                                <ckeditor [editor]="Editor" #messageeditor formControlName="message" [(ngModel)]="emailerDetail.message" [style.height.px]="250"></ckeditor>
                                <div *ngIf="submitted && g.message.errors" class="invalid-feedback">
                                    <div *ngIf="g.message.errors.required">Please Enter the Message </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label style="width: auto;margin-top: 6px;float: left;margin-right: 10px;">Select to send an email to the applicant. </label>
                                <div class="onoffswitch">
                                    <input type="checkbox" name="ismail" formControlName="ismail" class="onoffswitch-checkbox" id="ismail" [(ngModel)]="emailerDetail.ismail" [checked]="ismail">
                                    <label class="onoffswitch-label" for="ismail">
                                        <span class="onoffswitch-inner"></span>
                                        <span class="onoffswitch-switch"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-message-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>


<jw-modal id="custom-skill-view" role="dialog" class="modal">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="popupLabel">Skill List</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-skill-view');">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <h5 class="skill_list_head">Matched Skills</h5>
                    <div class="skill_list matched">
                        <div class="colmElmProType" *ngFor="let cat of skilllist">
                            <label *ngIf="cat.check" class="skill_blue">
                                 {{cat.skillname}}
                            </label>
                        </div>
                    </div>
                    <h5 class="skill_list_head">Unmatched Skills</h5>
                    <div class="skill_list matched">
                        <div class="colmElmProType" *ngFor="let cat of skilllist">
                            <label *ngIf="!cat.check" class="skill_trans">
                             {{cat.skillname}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-document-view" role="dialog" class="modal">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="popupLabel">Applicant Document List</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-document-view');">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="import_image_section" *ngIf="candidateDetail.document">
                        <div class="import_file_img " *ngFor="let doc of candidateDetail.document">
                            <div class="import_img">
                                <i aria-hidden="true" class="fa fa-file-image-o"></i>
                            </div>
                            <div class="import_file_name">
                                <p>{{doc.documentname}}</p>
                            </div>
                            <div class="import_right">
                                <div class="check">
                                    <a>
                                        <i aria-hidden="true" class="fa fa-edit"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="import_footer">
                                <div class="footer_imp_le">
                                    <p>File Size:<span>284 KB</span></p>
                                </div>
                                <div class="footer_imp_ri">
                                    <i class="fa fa-file-image-o" (click)="openModal5('pdf-image-viewer', doc.documentimageurl)"></i>
                                    <i class="fa fa-trash" (click)="deleteFile(doc.candidatedocumentdetailsid, doc.candidateid)"></i>
                                    <a href="{{doc.documentimageurl}}"><i class="fa fa-download"></i></a>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-lg-3" *ngIf="!candidateDetail.document">
                        No Document Found
                    </div>
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="pdf-image-viewer" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer');">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="500" frameborder="0"></iframe>
                            <img *ngIf="imgsrc == true" [src]="iframesrc" style="width:100%;" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>