import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Visa } from '../../../../../models/visa/visa.model';
import { UploadFileService } from '../../../../../services/upload-file.service';
import { Employee } from '../../../../../models/employee.model';
import { FileuploadService } from '../../../../../services/fileupload.service';
import { MyvisaService } from '../../../../../services/myvisa.service';
import { AlertService } from '../../../../../services/alert.service';


@Component({
  selector: 'app-employeeaidetail',
  templateUrl: './employeeaidetail.component.html',
  styleUrls: ['./employeeaidetail.component.css']
})
export class EmployeeaidetailComponent implements OnInit {

  submitted: any;
  visapopForm: FormGroup;
  visa: Visa;
  permission: any;
  requestperm: any;
  record: number;

  selectedFiles: any;
  fileformaterror:any;
  filesizeerror:any;
  file:any;
  file1:any;
  employeemasterid: any;
  ctype:number;
  visadetails = new Visa();  
  employee = new Employee();
  
  data3:any;
  employeevisadeatilid:any;

  constructor(private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder,  
    private visaService: MyvisaService, 
    private activatedRoute: ActivatedRoute,
    private filupload: FileuploadService,
    private uploadService: UploadFileService,  
    private alerts: AlertService,
    private router: Router) { }

  ngOnInit() {
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;
    this.ctype  = parseInt(localStorage.getItem('ctype'));
    this.employeevisadeatilid = this.activatedRoute.snapshot.paramMap.get("visaid");

    this.data3 = {"employeevisadeatilid": this.employeevisadeatilid}
    this.visaService.visadetailsbyvisaid(this.data3).subscribe((data: any) => {
      this.employeemasterid = data.employee.employeemasterid;
      this.visadetails = data;
      this.record  = data.length;
    });

  }

  selectFile(event,type)
  {

    type = parseInt(type);
      var files = event.target.files;
      var selectedFiles = files[0];
      this.selectedFiles = selectedFiles;
      const file = this.selectedFiles;
      this.fileformaterror = false;
      this.filesizeerror = false;
      
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');


      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        this.alerts.success('File size must be less than 2 MB.',true); 
        return;
      }
      else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") ) {
        this.fileformaterror = true;
        this.alerts.success('Please choose a valid file format (jpg, png,ttf,gif)',true);         
        return;
      }
      var imagepath = this.uploadService.uploadfile(file); 
      if(type == 0){        
        this.visadetails.visadocname = '../../../../../../../assets/img/loadings.gif';
      }
      else if(type == 1)
       {        
        this.visadetails.visabackdocname = '../../../../../../../assets/img/loadings.gif';
        }

      var dbimage = {"id": this.employeevisadeatilid, "imagename":imagepath , "employeid": this.employeemasterid, itype: type};
      this.visaService.visaupdateForAI(dbimage).subscribe((data: any) => {

        if(type ==0){          
          setTimeout(() => this.visadetails.visadocname  = imagepath, 3000);        }
        else if(type ==1){
        setTimeout(() => this.visadetails.visabackdocname  = imagepath, 3000);        
         }
      });  
  }


}
