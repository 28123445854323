"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_model_1 = require("../../models/employee.model");
var country_service_1 = require("../../services/country.service");
var employee_service_1 = require("../../services/employee.service");
var employeement_service_1 = require("../../services/employeement.service");
var job_service_1 = require("../../services/job.service");
var project_service_1 = require("../../services/project.service");
var client_service_1 = require("../../services/client.service");
var excel_service_1 = require("../../services/excel.service");
var EmploymentreportComponent = /** @class */ (function () {
    function EmploymentreportComponent(excelService, formBuilder, project, client, country, jobser, employee, employeementreport) {
        this.excelService = excelService;
        this.formBuilder = formBuilder;
        this.project = project;
        this.client = client;
        this.country = country;
        this.jobser = jobser;
        this.employee = employee;
        this.employeementreport = employeementreport;
        this.employeeDetail = new employee_model_1.Employee();
        this.exceldata = [];
    }
    EmploymentreportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.EmployeeForm = this.formBuilder.group({
            emailid: [''],
            phonenumber: [''],
            ninumber: [''],
            minstartdate: [''],
            maxstartdate: [''],
            minenddate: [''],
            maxenddate: [''],
            clientid: [''],
            minsalary: [''],
            maxsalary: [''],
            projectid: [''],
            fname: [''],
            iscurrent: ['']
        });
        var values3 = {};
        this.employeementreport.getemployementlist(values3).subscribe(function (employementetails) {
            _this.data = employementetails;
        });
        this.Userid = localStorage.getItem('userid');
        var sstatustype = { 'status': true, 'id': this.Userid };
        this.project.getprojectlist(sstatustype).subscribe(function (projectdetails) {
            _this.projectlist = projectdetails;
        });
        this.client.getclientlist(sstatustype).subscribe(function (clientdetails) {
            _this.clientlist = clientdetails;
        });
    };
    EmploymentreportComponent.prototype.exportAsXLSX = function () {
        var _this = this;
        this.exceldata = [];
        this.data.forEach(function (obj) {
            if ((obj.wemployeeid != '0') && (obj.wemployeeid != '')) {
                var linemanager = obj.wemployee.fname + " " + obj.wemployee.mname + " " + obj.wemployee.lname;
            }
            else {
                var linemanager = "";
            }
            if ((obj.clientid != '0') && (obj.clientid != '')) {
                var name = obj.client.clientname;
            }
            else {
                name = "";
            }
            if ((obj.projectid != '0') && (obj.projectid != '')) {
                var project = obj.project.projectname;
            }
            else {
                project = "";
            }
            _this.employmentdata = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "Line Manager": linemanager,
                "Client": name,
                "Project": project,
                "Salary": obj.salary,
                "Hour of Work": obj.hoursofworks,
                "Employee Role": obj.emprole,
                "Start Date ": obj.startdate,
                "End Date ": obj.enddate
            };
            _this.exceldata.push(_this.employmentdata);
        });
        this.excelService.exportAsExcelFile(this.exceldata, 'Employment_Report');
    };
    EmploymentreportComponent.prototype.onSubmit = function (value3) {
        var _this = this;
        this.employeementreport.getemployementlist(value3).subscribe(function (employementetails) {
            _this.data = employementetails;
        });
    };
    return EmploymentreportComponent;
}());
exports.EmploymentreportComponent = EmploymentreportComponent;
