import { Component, OnInit } from '@angular/core';
import { AssetService } from '../services/asset.service';
import { ModalService } from '../_services/modal.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.css']
})

export class AssetComponent implements OnInit {
  data: any [];
  Userid: any;
  month: any;
  year: any;
  yearmonth: any;
  classactive: string;
  record: number;
  public searchText: string;
  pager: any = {};
  pageOfItems: Array<any>;
  items: Array<any>;

  constructor(
    private dataset: AssetService, 
    private router: Router,
    private modalService: ModalService
  ) { }

  ngOnInit() {

    this.Userid = localStorage.getItem('userid');
    if (!this.Userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    
    this.bindalldata();    
  }

  bindalldata()
  {
    this.dataset.getassetlistbyemployeeid({'employeeid': this.Userid}).subscribe((data: any) => {  
       this.record = data.length;
     // this.data = data;
      if(this.record < 30){
        this.pageOfItems = data;
      }
      else{
           this.pageOfItems =data;
      }
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}
}