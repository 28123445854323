"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FileSaver = require("file-saver");
var XLSX = require("xlsx");
var i0 = require("@angular/core");
var EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
var EXCEL_EXTENSION = '.xlsx';
var ExcelService = /** @class */ (function () {
    function ExcelService() {
        this.employeedata = [];
        this.employementdata = [];
        this.cosdata = [];
        this.visadata = [];
        this.passportdata = [];
        this.leavedata = [];
        this.expensedata = [];
        this.timesheetdata = [];
        this.assetdata = [];
    }
    ExcelService.prototype.employeeexcel = function (data) {
        var _this = this;
        this.employeedata = [];
        data.forEach(function (obj) {
            _this.employeejson = {
                "Name": obj.fname + " " + obj.mname + " " + obj.lname,
                "Email": obj.emailid,
                "Phone Number": obj.phonenumber,
                "NI Number": obj.ninumber,
                "Nationality": obj.nationality.countryname,
                "Country Of Residence": obj.residence.countryname,
                "Country Of Birth": obj.countryofbirth.countryname,
                "Place Of Birth": obj.placeofbirth
            };
            _this.employeedata.push(_this.employeejson);
        });
    };
    ExcelService.prototype.employementexcel = function (data) {
        var _this = this;
        this.employementdata = [];
        data.forEach(function (obj) {
            // if ((obj.wemployeeid != '0') && (obj.wemployeeid != '')) {
            //   var linemanager = obj.wemployee.fname + " " + obj.wemployee.mname + " " + obj.wemployee.lname;
            // }
            // else {
            //   var linemanager = "";
            // }
            // if ((obj.clientid != '0') && (obj.clientid != '')) {
            //   var name = obj.client.clientname;
            // }
            // else {
            //   name = "";
            // }
            // if ((obj.projectid != '0') && (obj.projectid != '')) {
            //   var project = obj.project.projectname;
            // }
            // else {
            //   project = "";
            // }
            _this.employementjson = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                // "Line Manager": linemanager,
                // "Client": name,
                // "Project": project,
                "Salary": obj.salary,
                "Hour Of Work": obj.hoursofworks,
                "Employee Role": obj.emprole,
                "Start Date ": obj.startdate,
                "End Date ": obj.enddate
            };
            _this.employementdata.push(_this.employementjson);
        });
    };
    ExcelService.prototype.cosexcel = function (data) {
        var _this = this;
        this.cosdata = [];
        data.forEach(function (obj) {
            if (obj.iscurrent == false) {
                var current = "No";
            }
            else if (obj.iscurrent == true) {
                current = "Yes";
            }
            _this.cosjson = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "Email": obj.employee.emailid,
                "Phone Number": obj.employee.phonenumber,
                "NI Number": obj.employee.ninumber,
                "COS Number": obj.cosnumber,
                "Start Date": obj.cosstartdate,
                "End Date": obj.cosexpirydate,
                "Is Current": current
            };
            _this.cosdata.push(_this.cosjson);
        });
    };
    ExcelService.prototype.visaexcel = function (data) {
        var _this = this;
        this.visadata = [];
        data.forEach(function (obj) {
            if (obj.iscurrent == false) {
                var current = "No";
            }
            else if (obj.iscurrent == true) {
                current = "Yes";
            }
            _this.visajson = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "Email": obj.employee.emailid,
                "Phone Number": obj.employee.phonenumber,
                "NI Number": obj.employee.ninumber,
                "VISA Number": obj.visanumber,
                "Start Date": obj.visastart,
                "End Date": obj.visaexpiry,
                "Is Current": current
            };
            _this.visadata.push(_this.visajson);
        });
    };
    ExcelService.prototype.passportexcel = function (data) {
        var _this = this;
        this.passportdata = [];
        data.forEach(function (obj) {
            if (obj.iscurrent == false) {
                var current = "No";
            }
            else if (obj.iscurrent == true) {
                current = "Yes";
            }
            _this.passportjson = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "Email": obj.employee.emailid,
                "Phone Number": obj.employee.phonenumber,
                "NI Number": obj.employee.ninumber,
                "Passport Number": obj.passportnumber,
                "Nationality": obj.nationality.countryname,
                "Start Date": obj.passportissue,
                "End Date": obj.passportexpiry,
                "Is Current": current
            };
            _this.passportdata.push(_this.passportjson);
        });
    };
    ExcelService.prototype.leaveexcel = function (data) {
        var _this = this;
        this.leavedata = [];
        data.forEach(function (obj) {
            if (obj.iscurrent == false) {
                var current = "No";
            }
            else if (obj.iscurrent == true) {
                current = "Yes";
            }
            _this.leavejson = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "Email": obj.employee.emailid,
                "Phone Number": obj.employee.phonenumber,
                "NI Number": obj.employee.ninumber,
                "Title": obj.title,
                "Start Date": obj.startdate,
                "End Date": obj.enddate,
                "Leave Type": obj.holidaytype.typename
            };
            _this.leavedata.push(_this.leavejson);
        });
    };
    ExcelService.prototype.timesheetexcel = function (data) {
        var _this = this;
        this.timesheetdata = [];
        data.forEach(function (obj) {
            _this.timesheetjson = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "Email": obj.employee.emailid,
                "Phone Number": obj.employee.phonenumber,
                "NI Number": obj.employee.ninumber,
                "Hours": obj.ahour,
                "Hour Type": obj.hourtype.typename,
                "Project": obj.project.projectname,
                "Location": obj.location.address1,
                "Date": obj.startdate
            };
            _this.timesheetdata.push(_this.timesheetjson);
        });
    };
    ExcelService.prototype.expenseexcel = function (data) {
        var _this = this;
        this.expensedata = [];
        data.forEach(function (obj) {
            if (obj.paidby == '1') {
                var paidby = "Company Paid";
            }
            else if (obj.paidby == '2') {
                paidby = "Client Paid";
            }
            else if (obj.paidby == '3') {
                paidby = "Employee Paid";
            }
            _this.expensejson = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "Email": obj.employee.emailid,
                "Phone Number": obj.employee.phonenumber,
                "NI Number": obj.employee.ninumber,
                "Project": obj.project.projectname,
                "Expense Type": obj.expensetype.typename,
                "Paid By": paidby,
                "Date": obj.startdate,
                "Amount": obj.amountpaid,
                "Location": obj.locationname
            };
            _this.expensedata.push(_this.expensejson);
        });
    };
    ExcelService.prototype.exportAsExcelFileAll = function (excelFileName) {
        var worksheet = XLSX.utils.json_to_sheet(this.employeedata);
        var worksheet2 = XLSX.utils.json_to_sheet(this.visadata);
        var worksheet3 = XLSX.utils.json_to_sheet(this.passportdata);
        var worksheet4 = XLSX.utils.json_to_sheet(this.employementdata);
        var worksheet5 = XLSX.utils.json_to_sheet(this.cosdata);
        // const worksheet6: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.leavedata);
        // const worksheet7: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.expensedata);
        // const worksheet8: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.timesheetdata);
        // , 'Employee Leave': worksheet6, 'Employee Expense': worksheet7, 'Employee Timesheet': worksheet8
        // ,'Employee Leave','Employee Expense','Employee Timesheet'
        var workbook = { Sheets: { 'Employee': worksheet, 'Visa': worksheet2, 'Passport': worksheet3, 'Employment': worksheet4, 'COS': worksheet5 }, SheetNames: ['Employee', 'Visa', 'Passport', 'Employment', 'COS'] };
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    };
    ExcelService.prototype.exportAsExcelFile = function (json, excelFileName) {
        var worksheet = XLSX.utils.json_to_sheet(json);
        var worksheet2 = XLSX.utils.json_to_sheet(json);
        var workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    };
    ExcelService.prototype.saveAsExcelFile = function (buffer, fileName) {
        var data = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    };
    ExcelService.ngInjectableDef = i0.defineInjectable({ factory: function ExcelService_Factory() { return new ExcelService(); }, token: ExcelService, providedIn: "root" });
    return ExcelService;
}());
exports.ExcelService = ExcelService;
