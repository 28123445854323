import { Component, OnInit } from '@angular/core';
import { MypassportService } from '../../../services/mypassport.service';
import { ModalService } from '../../../_services/modal.service';
import { FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { UploadFileService } from './../../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Passport } from '../../../models/passport/passport.model';
import { CountryService } from '../../../services/country.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../services/alert.service';

import { ChecksystemService } from '../../../services/checksystem.service';
@Component({
  selector: 'app-checkpassport',
  templateUrl: './checkpassport.component.html',
  styleUrls: ['./checkpassport.component.css']
})
export class CheckpassportComponent implements OnInit {

  
  form: FormGroup;
  passformGroup: FormGroup;
  passportlist: any[];
  statuslist: any;
  passportDetail: any;
  countrylist: any[];
  Userid: any;
  listcheck: any;
  myfile: any;
  file: any;
  selectedpassportFiles: FileList;
  passnumbererror: any;
  nationalityerror: any;
  passportdateoriginerror: any;
  iframesrc: any;
  iframepassportsrc:any;
  imgpassportsrc:any;
  imgsrc: any;
  buttoninsert: any;
  filepassportsizeerror: any;
  filepassportreuirederror: any;
  filepassportformaterror: any;
  formattedMessage: any;
  messageSuccess:any;
  filename: any;
  empid: any;
  disableBtn: any;
  defaultVal: any = "";
  filepassortname: any;
  filepassportbackname: any;
  selectpassportbackFile:any;
  ramainingChecks:any;
  filepassportbacksizeerror: any;
  filepassportbackformaterror: any;
  selectedpassportbackFiles: FileList;
  filebackname: any;

  aipayment: boolean =false;

  imagefront: any;  
  imageback:any;

  filterItems = [
    {
      name: 'All Passport Details',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Waiting for review',
      id: 1,
      selected: false
    }
  ]
  submitted: boolean;

  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private data: MypassportService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private activatedRoute: ActivatedRoute, 
    private router: Router, 
    private alerts: AlertService,    
    private check: ChecksystemService
    ) { }

  ngOnInit() {
    this.empid = this.activatedRoute.snapshot.paramMap.get("id");
    this.passformGroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      passportnumber: ['', Validators.required],
      placeofissue: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      passportissue: ['', Validators.required],
      passportexpiry: ['', Validators.required],
      iscurrent: [''],
    });

    


    this.check.getbillinginformation({ 'serviceid': 7 }).subscribe((remaindata: any) => { 
      this.ramainingChecks = remaindata;
    });



    this.onChanges();
  
    this.imagefront ='';
    this.filepassortname = 'Choose file';
    this.filepassportbackname = 'Choose file';
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.empid, 'cstatus': [1, 2, 3] };

    this.data.getpassportListnewbyadmin(data).subscribe((data: any) => {
      this.passportlist = data;
    });

    this.listcheck = this.filterItems;

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.imgpassportsrc =false;
    this.passportDetail = new Passport();
    this.passportDetail.sstatus = 0;
  }
  
  onChangeDate(event: any)
  {
    
  }
  
  onChanges(): void {
    this.formattedMessage = '';
    this.passformGroup.valueChanges.subscribe(val => {
      this.formattedMessage = 'Hello, My name is and my email is .I would like to tell you that .';
    });
  }

  onlistchange(type1: any, idval: number) {
    var sstatus: any;
    for (let c of this.filterItems) {
      if (c.id == idval)
        c.selected = true;
      else
        c.selected = false;
    }

    if (idval == 0 || type1 == false) {
      sstatus = [1, 2, 3];
    }
    else {
      sstatus = [idval];
    }

    var userid = localStorage.getItem('userid');
    const data = { 'id': this.empid, 'cstatus': sstatus };
    this.data.getpassportListnewbyadmin(data).subscribe((data: any) => {
      
      this.passportlist = data;
    });

  }
  get f() { return this.passformGroup.controls; }
  onSubmit(formpassport: any, id: string) {
    // var formpassport = value3.value;
    this.submitted = true;
    if (this.passformGroup.invalid) {
      return;
    }

    if (this.selectedpassportFiles) {
      this.file = this.selectedpassportFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filepassportsizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {

        this.filepassportformaterror = true;
        return;
      }
      var imagepath =this.uploadService.uploadfile(this.file);
      formpassport.imagename = imagepath;
    }
    else {
      formpassport.imagename = this.passportDetail.passportdocname;
    }


    if(formpassport.imagename =='' || formpassport.imagename == null)
    {
      this.filepassportreuirederror =true;
      return;
    }
    

    if (this.selectedpassportbackFiles) {
      this.file = this.selectedpassportbackFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {

        this.filepassportsizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {

        this.filepassportformaterror = true;
        return;
      }
      var imagepath2 = this.uploadService.uploadfile(this.file);
      formpassport.imagebackname = imagepath2;
    }
    else {
      formpassport.imagebackname = this.passportDetail.passportdocname;
    }

    var error = 0;
    if (formpassport.passportnumber == '') {
      this.passnumbererror = false;
      error++;
    }
    if (formpassport.nationalityid == '') {
      this.nationalityerror = false;
      error++;
    }
    if (formpassport.passportissue == null) {
      this.passportdateoriginerror = false;
      error++;
    }
    if (error > 0) {
      return false;
    }
    formpassport.createby = this.Userid;
    formpassport.updateby = this.Userid;
    this.disableBtn = false;
    this.data.mypassportdetailsUpdatebyadmin(formpassport).subscribe((data: any) => {
      var sstatus: any;
      for (let c of this.filterItems) {
        if (c.selected == true) {
          if (c.id == 0) {
            sstatus = [1, 2, 3];
          }
          else {
            sstatus = [c.id];
          }
        }
      }

      var userid = localStorage.getItem('userid');
      const data1 = { 'id': this.empid, 'cstatus': sstatus };
      this.data.getpassportListnewbyadmin(data1).subscribe((data: any) => {
        // value3.reset();
        return this.passportlist = data;
      });
    });
    this.messageSuccess = 'Passport details successfully.'; 
    this.alerts.success(this.messageSuccess, false);
    this.filepassortname = "Choose file";
    this.modalService.close(id);
  }

  
  
  selectpassportFileupload(event)
   {
    var files = event.target.files;
    this.filepassortname = files[0].name;
    

    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filepassportsizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filepassportformaterror = true;  this.filepassportsizeerror = false;      
    }else{
      
      this.filepassportsizeerror = false;  
      this.filepassportformaterror = false;
      this.filepassportreuirederror =false;
    //  this.imagefront = this.uploadService.uploadfile(this.file);
      this.selectedpassportFiles = files[0];
    }
  }


  selectpassportbackFileupload(event)
   {
    var files = event.target.files;
    this.filepassportbackname = files[0].name;
    

    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filepassportbacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filepassportbackformaterror = true;    this.filepassportbacksizeerror = false; 
    }else{this.filepassportbacksizeerror = false;  
      this.filepassportbackformaterror = false;
      this.imageback = this.uploadService.uploadfile(this.file);
      this.selectedpassportbackFiles = files[0];
    }
  }

  onDelete(empid: number) {
    if (confirm("Are you sure you want to delete?")) {
      const data1 = { 'id': empid };
      this.data.deletePassport(data1).subscribe((data: any) => {
        var sstatus: any;
        for (let c of this.filterItems) {
          if (c.selected == true) {
            if (c.id == 0) {
              sstatus = [1, 2, 3];
            }
            else {
              sstatus = [c.id];
            }
          }
        }
        var userid = localStorage.getItem('userid');
        const data1 = { 'id': this.empid, 'cstatus': sstatus };
        this.data.getpassportListnewbyadmin(data1).subscribe((data: any) => {
          return this.passportlist = data;
        });
      })
    }
  }

  openModal2(id: string) {
    this.submitted = false;
    this.disableBtn = true;
    this.passportDetail = new Passport();
    this.selectedpassportFiles = null;
    this.selectpassportbackFile =null;
    this.buttoninsert = "Submit";
    this.filepassortname = "Choose file";
    this.filepassportbackname = "Choose file";
    this.filepassportreuirederror =false;
    this.passportDetail.employeeid = this.empid;
    this.passportDetail.employeepassdeatilid = "0";
    // this.passportDetail.passportnumber = '';
    this.passportDetail.nationalityid = '';
    // this.passportDetail.passportissue = '';
    // this.passportDetail.passportexpiry = '';
    // this.passportDetail.placeofissue = '';
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.disableBtn = true;
    this.selectedpassportFiles = null;
    this.selectpassportbackFile =null;
    this.passportDetail = new Passport();
    this.filepassortname = "Choose file";
    this.filepassportbackname = "Choose file";
    this.buttoninsert = "Update";
    this.filepassportreuirederror =false;
    this.passportDetail = data;
    this.modalService.open(id);
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModalpassportview(id: string, data: any) {

    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];

    // console.log(typeoffile);
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgpassportsrc = true;
      this.iframepassportsrc = data;
    }
    else {
      this.imgpassportsrc = false;
      this.iframepassportsrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.passportDetail = new Passport();
    const data = { 'id': this.empid, 'cstatus': [1, 2, 3] };
    this.data.getpassportListnewbyadmin(data).subscribe((data: any) => {
      this.passportlist = data;
    });
    this.modalService.close(id);
  }

  modalOpen(data: any) {
    this.disableBtn = true;
    this.passportDetail = new Passport();
    this.passportDetail = data;
  }

  
  uploadByAdmin(emp_type, type, empid)
  {
    if(this.ramainingChecks && this.ramainingChecks.remiancheck < 1)
    {
      this.modalService.open('upgrade-plan');
    }
    else
    {
      if(type == 'upload_now')
      {
        if(emp_type == 'visa')
        {
          this.router.navigate(['/check-ai-visa-admin', empid]);
        }     
      
    }
  }
}

}
