import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../../../services/employee.service';
import { ModalService } from '../../../_services/modal.service';
import { UploadFileService } from './../../../services/upload-file.service';
import { AlertService } from '../../../services/alert.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-employeedocument',
  templateUrl: './employeedocument.component.html',
  styleUrls: ['./employeedocument.component.css']
})
export class EmployeedocumentComponent implements OnInit {
  employeelist: any;
  documentlist: any;
  Userid: any;
  employeeid: any;
  employeedocumentlist: any;
  record: number;
  selectedFiles: FileList;
  iframesrc: any;
  imgsrc: any;
  permission: any;
  docperm: any;
  documentnamelist: any;
  submitted: any;
  searchText:any;
  documentGroup: FormGroup;
  file: any;
  data: any;

  constructor( 
    private sanitizer: DomSanitizer, 
    private employeeService: EmployeeService,
    private uploadService: UploadFileService, 
    private modalService: ModalService,
    private router: Router,
    private alerts: AlertService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() { 

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.docperm = this.permission.m8;
    this.record = 0;
    
    this.documentGroup = this.formBuilder.group({
      employeedocumentdetailsid: [''],
      documentname: ['', Validators.required] ,
      employeeid: ['', Validators.required]     
    });

    this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
      this.employeelist = employeedata;
      this.data = employeedata;
    });
  }
  get f() { return this.documentGroup.controls; }
  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  bindallcandidatedocument(id: string, employeeid: any) {
    this.employeeid = employeeid;
    this.Userid = localStorage.getItem('userid');
    this.employeeService.getdocumentlist({"id": this.Userid, "employeeid": employeeid}).subscribe((candidatedocument: any) => {
      this.record = candidatedocument.length;
      this.employeedocumentlist = candidatedocument;      
    }); 
    this.modalService.open(id);
  }


  getFileNameWithoutExtension(name) {
    //  var name = file.getName();

    //console.log(name.length);

      var pos = name.lastIndexOf('.');
      if (pos > 0 && pos < (name.length - 1)) {
          // there is a '.' and it's not the first, or last character.
          return name.substring(0,  pos);
      }
      return name;
  }


  selectFile(event) {
    var files = event.target.files;
    var selectedFiles = files[0];
    this.selectedFiles = selectedFiles;
    this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
    if ((filetype[filetype.length - 1] != "jpeg")
     && (filetype[filetype.length - 1] != "jpg") 
     && (filetype[filetype.length - 1] != "png") 
     && (filetype[filetype.length - 1] != "doc")
     && (filetype[filetype.length - 1] != "pdf")  )  {
      this.alerts.error('Supported File Formats (jpeg, jpg, png, doc, pdf)');
      return;
    }
    const file = this.selectedFiles;
    var imagepath = this.uploadService.uploadfile(file);

    

    var documentname = this.getFileNameWithoutExtension(selectedFiles.name).substring(0,20);
    var forupdate = { 'documentname': documentname, 'id': "0", 'documentimageurl': imagepath, 'employeeid': this.employeeid, 'userid': this.Userid };    
    this.employeeService.employeedocumentupdatebyadmin(forupdate).subscribe((data: any) => 
    {
      this.employeeService.getdocumentlist({"id": this.Userid, "employeeid": this.employeeid}).subscribe((candidatedocument: any) => {
        
        this.record = candidatedocument.length;
        this.employeedocumentlist = candidatedocument;      
      }); 
      // setTimeout(() => this.overviewData.imagename = imagepath, 2000);
    });
    this.alerts.success('Document uploaded successfully',true); 
  }

  deleteFile(id: string)
  {
    var deleteData = {};
    deleteData = { 'id': id, 'employeeid': this.employeeid };
    if (confirm("Are you sure you want to delete?")) {
      this.employeeService.deleteemployeedocumentFile(deleteData).subscribe((data: any) => {
        this.employeeService.getdocumentlist({"id": this.Userid, "employeeid": this.employeeid}).subscribe((candidatedocument: any) => {
          this.alerts.error('Document deleted successfully',true); 
          this.record = candidatedocument.length;
          this.employeedocumentlist = candidatedocument;      
        }); 
      });
    }
  }

  openModal5(id: string, data: any) {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  closeModal(id: string){
    this.modalService.close(id);
  }

  opendocumnentname(id: any, employeeid: any){
    var reqType = { 'documentid':id ,'employeeid':employeeid }
    this.employeeService.getemployeedocumentbyid(reqType).subscribe((data: any) => {
      this.documentnamelist = data;
      this.modalService.open('employee-doc-name-update');
    });
  }

  onRenameDocumentSubmit(data: any){
    this.submitted =true;
  
    if (this.documentGroup.invalid) {
    
      return;
    }

    // console.log(data);

    this.employeeService.updateemployeedocumentbyid(data).subscribe((data2: any) => {
      if(data2.susses == 'true'){
        var employeeid = data.employeeid;
        this.documentnamelist = null;
        this.Userid = localStorage.getItem('userid');
        this.employeeService.getdocumentlist({"id": this.Userid, "employeeid": employeeid}).subscribe((candidatedocument: any) => {
          this.record = candidatedocument.length;
          this.employeedocumentlist = candidatedocument;  
          this.modalService.close('employee-doc-name-update');    
        }); 
        
        this.alerts.success('Document name updated successfully');
      }else{
        this.alerts.error('Somthing went wrong'); this.modalService.close('employee-doc-name-update');
      }
     
    });
  }

}
