import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaxdefaulterService } from '../../services/taxdefaulter.service';
import { AlertService } from '../../services/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ModalService } from '../../_services/modal.service';
import { ChecksystemService } from '../../services/checksystem.service';
@Component({
  selector: 'app-taxdefault',
  templateUrl: './taxdefault.component.html',
  styleUrls: ['./taxdefault.component.css']
})
export class TaxdefaultComponent implements OnInit {
  @Output("getRemainingChecks")
  getRemainingChecks: EventEmitter<any> = new EventEmitter();
  permission: any;
  defaultVal: any = "";
  submitted: any;
  taxFormgroup: FormGroup;
  nrSelect:any;
  lesssore:boolean;
  listview:boolean;
  remainingcheck: any;
  pageOfItems:any;
  record:any;
  checkcount:any;
  companytype:any;
  employeeid:any;
  disabled:any;
  
  constructor(
    private router: Router,
    private formBuilder: FormBuilder, 
    private taxService: TaxdefaulterService,
    private alerts: AlertService,   
    private check: ChecksystemService,    
    private modalService: ModalService 
  ) { }

  ngOnInit() {
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    // this.permission = permission[0];
    if(permission[0]['m134'] < 1)
    {
      this.router.navigate(['/permission-denied']);
    }
    this.submitted = false;
    this.createformgroup();


    this.listview = false;
    this.lesssore =false;
    this.submitted = false;
    var ts  = { 'serviceid': 9};    
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      this.remainingcheck = remaindata;
      if(remaindata.remiancheck <= 0)
      {
          this.lesssore =true;
      }
    });

    this.getRemainingCheck();
  }

  get f() {
    return this.taxFormgroup.controls;
  }

  createformgroup()
  {
    this.taxFormgroup = this.formBuilder.group({     
      bussinessname: ['', Validators.required],     
      businesstrade: ['', Validators.required]     
    });
  }

  onSubmit(supportFormValues: any, id: string) {
    this.submitted = true;
    this.disabled = false;
    if (this.taxFormgroup.invalid) {
      return;
    }

    this.disabled = true;
    
    // this.submitted = true;
    this.taxService.gettaxdefaulter(supportFormValues).subscribe((data: any) => {
      this.pageOfItems = data;
      if(data.length ==0){
        this.record =0;
      } else{
        this.record =2; 
      }
      this.listview = true;      
      this.submitted = false;
      this.createformgroup();
    });
  }

  
  getRemainingCheck()
  {
    var ts  = { 'serviceid': 8 }; 
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      this.checkcount = remaindata;
    });
  }

  clearData()
  {
    this.disabled = false;
    this.submitted = false;
    this.createformgroup();
  }

  newSearch(){
    this.disabled = false;
    var ts  = { 'serviceid': 9};    
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      this.remainingcheck = remaindata;
      if(remaindata.remiancheck <= 0)
      {
          this.lesssore =true;
      } this.listview = false; 
    });   
  }


  
  closeModal(id: string) {  
    this.modalService.close(id);
  }
  sendrequest(employeeid, id)
  {
    if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
    {
      this.modalService.open('upgrade-plan');
    }
    else
    {
      this.modalService.open(id);
       this.employeeid = employeeid;
    }
  }

  sendrequestcharge(employeeid, id)
  {

    var ts = {id: employeeid}
    this.taxService.gettaxdefaulterbyid(ts).subscribe((data: any) => {

      if(data.remiancheck ==0)
      {
        this.modalService.open('upgrade-plan');
        
      }else
      {
        this.getRemainingCheck();
        this.getRemainingChecks.emit();
        this.modalService.open('max-defaulter-bussiness');
        this.companytype = data;
      }
      this.modalService.close(id);
      
    });
    

  }


}
                                                                          