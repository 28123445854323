"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var PerformanceComponent = /** @class */ (function () {
    function PerformanceComponent() {
    }
    PerformanceComponent.prototype.ngOnInit = function () {
    };
    return PerformanceComponent;
}());
exports.PerformanceComponent = PerformanceComponent;
