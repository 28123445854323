"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var DepartmentService = /** @class */ (function () {
    function DepartmentService(commonService) {
        this.commonService = commonService;
    }
    DepartmentService.prototype.getdepartmentlistbyadmin = function (data) {
        return this.commonService.formpost('/company/departmentlistbyadmin', data);
    };
    DepartmentService.prototype.settingdepartmentUpdate = function (value) {
        return this.commonService.formpost('/company/departmentupdatebyadmin', value);
    };
    DepartmentService.prototype.departmentUpdateChangeStatus = function (value) {
        return this.commonService.formpost('/company/departmentupdatechangestatus', value);
    };
    DepartmentService.ngInjectableDef = i0.defineInjectable({ factory: function DepartmentService_Factory() { return new DepartmentService(i0.inject(i1.CommonService)); }, token: DepartmentService, providedIn: "root" });
    return DepartmentService;
}());
exports.DepartmentService = DepartmentService;
