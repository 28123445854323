<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="" id="tabs">
                <a [routerLink]="['/listjob']" class="backlist mb-4 mr-0">
                    <i class="fa fa-angle-left" aria-hidden="true"></i> Back to Job List</a>
                <div class="clearfix"></div>
                <div class="tabs__content">

                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row row-sm topheader">
                                <div class="col-lg-8">
                                    <h5 class="hedingcolor floatleft">Job Skill List</h5>
                                    <a (click)="openModal2('custom-visa-1')" class="mainheading_btn"> + Add New </a>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group">
                                        <input [(ngModel)]="searchText" placeholder="Search.." class="form-control">
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <ng-container *ngIf="( data |  filter:{skillname:searchText}) as result">
                                    <div class="col-lg-3 col-md-6" *ngFor="let skill of result; let i = index">
                                        <div class="job_list_section idproof_request_section visa_request_section">


                                            <div class="job_detils_date width75">
                                                <p class="job_address">Job SKills</p>
                                                <p class="job_profile">{{skill.skillname}}</p>
                                            </div>

                                            <div class="job_detils_date width25">
                                                <p class="job_address ">
                                                    <button type="submit" class="btn btn-danger com_btn" (click)="onDelete(skill.jobskilldetailid)"> <i class="fa fa-trash"></i></button>
                                                </p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <p *ngIf="result.length === 0">No Records Found</p>  
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<jw-modal id="custom-visa-1" role="dialog" class="modal">

    <form [formGroup]="skillFormGroup" #skillForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(skillForm.value,'custom-visa-1')">
        <input type="hidden" name="jobid" #skilljobid id="jobid" formControlName="jobid" ngModel="{{jobid}}">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="popupLabel">Skills</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-visa-1');">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label>Skills*</label>
                                <textarea name="skill" #skill id="skill" formControlName="skill" ngModel="" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.skill.errors }"></textarea>
                                <div *ngIf="submitted && f.skill.errors" class="invalid-feedback">
                                    <div *ngIf="f.skill.errors.required">Please enter skill</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="Submit" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-visa-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>