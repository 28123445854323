"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var RefnnoeComponent = /** @class */ (function () {
    function RefnnoeComponent() {
    }
    RefnnoeComponent.prototype.ngOnInit = function () {
    };
    return RefnnoeComponent;
}());
exports.RefnnoeComponent = RefnnoeComponent;
