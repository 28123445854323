"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../_services/modal.service");
var common_service_1 = require("../../../services/common.service");
var country_service_1 = require("../../../services/country.service");
var company_service_1 = require("../../../services/company.service");
var forms_1 = require("@angular/forms");
var platform_browser_1 = require("@angular/platform-browser");
var upload_file_service_1 = require("../../../services/upload-file.service");
var companyprofile_model_1 = require("../../../models/company/companyprofile.model");
var alert_service_1 = require("../../../services/alert.service");
var router_1 = require("@angular/router");
var employee_service_1 = require("../../../services/employee.service");
var graphreport_service_1 = require("../../../services/graphreport.service");
var BillinginformationComponent = /** @class */ (function () {
    function BillinginformationComponent(sanitizer, commonService, modalService, employeeService, graphService, formBuilder, country, uploadService, company, router, alerts) {
        this.sanitizer = sanitizer;
        this.commonService = commonService;
        this.modalService = modalService;
        this.employeeService = employeeService;
        this.graphService = graphService;
        this.formBuilder = formBuilder;
        this.country = country;
        this.uploadService = uploadService;
        this.company = company;
        this.router = router;
        this.alerts = alerts;
        this.companyprofileDetail = new companyprofile_model_1.Companyprofile();
        this.countcompanydata = new companyprofile_model_1.Countcompanydata();
        this.currentDate = new Date();
        this.planStartDate = new Date();
        this.planRenewDate = new Date();
        this.planExpiredOn = new Date();
    }
    BillinginformationComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        this.ctype = parseInt(localStorage.getItem('ctype'));
        console.log(this.ctype);
        // if(ctype ==1){
        //   this.router.navigate(['/check-dashboard']);
        // }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.profileperm = this.permission.m91;
        this.companyprofileDetail = new companyprofile_model_1.Companyprofile();
        this.companyprofileDetail.license = new companyprofile_model_1.License();
        this.companyprofileDetail.plan = new companyprofile_model_1.Plan();
        this.countcompanydata = new companyprofile_model_1.Countcompanydata();
        this.Userid = localStorage.getItem('userid');
        this.company.getcompanylocation().subscribe(function (locationlist) {
            _this.companylocationlist = locationlist;
        });
        this.company.getcountCompanyData().subscribe(function (countcompanydata) {
            _this.countcompanydata = countcompanydata;
        });
        this.company.getnextbillingdate().subscribe(function (countcompanydata3) {
            //  console.log(countcompanydata3);
        });
        this.company.getcompanyData().subscribe(function (companydata) {
            _this.companyprofileDetail = companydata;
            if (companydata.websiteurl)
                _this.weburl = companydata.websiteurl.replace('https://', '').replace('http://', '');
            else
                _this.weburl = '';
            if (!_this.companyprofileDetail.license) {
                _this.companyprofileDetail.license = new companyprofile_model_1.License();
            }
        });
        this.company.getplanDetails().subscribe(function (plandata) {
            var firstDateArray = [];
            var lastDateArray = [];
            var min = 0;
            var max = 0;
            var idArray = [];
            plandata.forEach(function (value) {
                idArray.push(value.planuserdetailid);
                firstDateArray[value.planuserdetailid] = value.joiningdate;
                lastDateArray[value.planuserdetailid] = value.expireddate;
            });
            min = Math.min.apply(null, idArray);
            max = Math.max.apply(null, idArray);
            _this.planStartDate = new Date(firstDateArray[min]);
            var year = _this.planStartDate.getFullYear();
            var month = _this.planStartDate.getMonth();
            var day = _this.planStartDate.getDate();
            _this.planRenewDate = new Date(year + 2, month, day);
            _this.planExpiredOn = new Date(lastDateArray[max]);
            _this.companyplanDetails = plandata;
        });
    };
    return BillinginformationComponent;
}());
exports.BillinginformationComponent = BillinginformationComponent;
