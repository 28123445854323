<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__sontent">
                    <app-invioce-shared></app-invioce-shared>
                    <div class="clearfix"></div>
                    <div class="invoice_content">
                        <div class="row row-sm topheader">
                            <div class="col-lg-12">
                                <h6 class="text-right payment_heading">
                                    <span>Payment Cards</span>
                                    <a (click)="updateyourplan('custom-payment-1')" class="addscreensuccess">+ Add New Card</a></h6>
                            </div>
                        </div>
                        <div class="billing_info_section">
                            <div class="card_box">
                                <div class="card_list" *ngFor="let cad of cardlist; let i=index">
                                    <div class="cards">
                                        <img src="assets/img/visa.png" *ngIf="cad.brand == 'Visa'">
                                        <img src="assets/img/mastercard.png" *ngIf="cad.brand == 'MasterCard'">
                                        <img src="assets/img/mastercard.png" *ngIf="cad.brand == 'American Express'">
                                        <div class="cardno">
                                            <span class="cardname">xxxx xxxx xxxx {{cad.last4}}</span>
                                            <span class="cardname_text">{{cad.brand}} - Expires {{cad.exp_month}} {{cad.exp_year}}</span>
                                        </div>
                                        <div class="card_date">
                                            <span class="date">
                                                <i class="fa fa-trash-o" aria-hidden="true" (click)="removeCard(cad.id);" *ngIf="cad.id  != cad.cardid"></i>

                    
                                                    <i class="fa fa-pencil-square-o" aria-hidden="true" (click)="removeCard(cad.id);"></i>
                                                </span>

                                            <!-- <button (click)="removeCard(cad.id);" *ngIf="i !=0">remove</button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<jw-modal id="custom-payment-1" role="dialog" class="modal paycut">
    <div role="document" class="formgroup">
      <div class="modal-content">       
        <div *ngIf="invalidError" class="errormessa">
          {{ invalidError.message }}
        </div>
  
        <form [formGroup]="addpopForm" #addresslistForm="ngForm" class="s12 padding453 white"  *ngIf="addresslistDetail">
      
          
            <div class="row">
                <div class="col-sm-12">
                    <h5 class="billing">Billing Information</h5>
                    <hr/>
                </div>
                <div class="col-sm-7">
                   
           <div class="row">
            <div class="form-group mb-2 col-lg-6 llb">
              <label class="mb-0">Card Holder Name*</label>
              <input type="text" formControlName="cardname" name="cardname" id="cardname" 
                  class="form-control" [ngClass]="{ 'is-invalid': submitted && g.cardname.errors }" required 
                  [ngModel]="addresslistDetail.cardname" maxlength="60">
              <div *ngIf="submitted && g.cardname.errors" class="invalid-feedback">
                  <div *ngIf="g.cardname.errors.required">Please enter address line 1</div>
              </div>
            </div>
            <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">City*</label>
                <input type="text" formControlName="cityname" name="cityname" class="form-control" id="cityname" maxlength="30"
                    [ngClass]="{ 'is-invalid': submitted && g.cityname.errors }" required [ngModel]="addresslistDetail.cityname">
                <div *ngIf="submitted && g.cityname.errors" class="invalid-feedback">
                    <div *ngIf="g.cityname.errors.required">Please enter city</div>
                </div>
            </div>
            <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">County Name*</label>
                <input type="text" formControlName="statename" name="statename" id="statename" class="form-control" 
                    [ngClass]="{ 'is-invalid': submitted && g.statename.errors }" required 
                    [ngModel]="addresslistDetail.statename" maxlength="30">
                <div *ngIf="submitted && g.statename.errors" class="invalid-feedback">
                    <div *ngIf="g.statename.errors.required">Please enter county</div>
                </div>
            </div>
            <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">Postcode*</label>
                <input type="text" formControlName="pincode" name="pincode" class="form-control" id="pincode" maxlength="10"
                    [ngClass]="{ 'is-invalid': submitted && g.pincode.errors }" required [ngModel]="addresslistDetail.pincode">
                <div *ngIf="submitted && g.pincode.errors" class="invalid-feedback">
                    <div *ngIf="g.pincode.errors.required">Please enter city</div>
                </div>
              </div>
              <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">Country*</label>
                <select style="width:100%;" formControlName="countryid" class="form-control input-lg" id="countryid" name="countryid" [ngClass]="{ 'is-invalid': submitted && g.countryid.errors }" (change)="onSelectCountry($event.target.value)" [ngModel]="addresslistDetail.countryid">
                    <option [value]="defaultVal">Select Country</option>
                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                </select>
                <div *ngIf="submitted && g.countryid.errors" class="invalid-feedback">
                    <div *ngIf="g.countryid.errors.required">Please select country</div>
                </div>
            </div>    
            <div class="form-group mb-2 col-lg-6 llb">
              <label class="mb-0">Tax ID</label>
              <input type="text" formControlName="taxid" name="taxid" class="form-control" id="taxid" maxlength="10"
                   [ngModel]="addresslistDetail.taxid">            
            </div> 
            <div class="form-group mb-2 col-lg-12 llb">
                <label class="mb-0">Address Line 1*</label>
                <input type="text" formControlName="address1" name="address1" id="address1" 
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && g.address1.errors }" required 
                    [ngModel]="addresslistDetail.address1" maxlength="60">
                <div *ngIf="submitted && g.address1.errors" class="invalid-feedback">
                    <div *ngIf="g.address1.errors.required">Please enter address line 1</div>
                </div>
              </div>
              <div class="form-group mb-2 col-lg-12 llb">
                <label class="mb-0">Address Line 2 </label>
                <input type="address2" formControlName="address2" name="address2" id="address2" 
                    class="form-control" [ngModel]="addresslistDetail.address2" maxlength="60">
            </div>
            <div class="col-sm-12 mt-3">
                <h5 class="billing">Billing Information</h5>
                <hr/>
            </div>
            <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">Card Holder Name*</label>
                <input type="text" formControlName="cardname" name="cardname" id="cardname" 
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && g.cardname.errors }" required 
                    [ngModel]="addresslistDetail.cardname" maxlength="60">
                <div *ngIf="submitted && g.cardname.errors" class="invalid-feedback">
                    <div *ngIf="g.cardname.errors.required">Please enter address line 1</div>
                </div>
              </div>
              <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">City*</label>
                <input type="text" formControlName="cityname" name="cityname" class="form-control" id="cityname" maxlength="30"
                    [ngClass]="{ 'is-invalid': submitted && g.cityname.errors }" required [ngModel]="addresslistDetail.cityname">
                <div *ngIf="submitted && g.cityname.errors" class="invalid-feedback">
                    <div *ngIf="g.cityname.errors.required">Please enter city</div>
                </div>
            </div>
            <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">County Name*</label>
                <input type="text" formControlName="statename" name="statename" id="statename" class="form-control" 
                    [ngClass]="{ 'is-invalid': submitted && g.statename.errors }" required 
                    [ngModel]="addresslistDetail.statename" maxlength="30">
                <div *ngIf="submitted && g.statename.errors" class="invalid-feedback">
                    <div *ngIf="g.statename.errors.required">Please enter county</div>
                </div>
            </div>
            <div class="form-group mb-2 col-lg-6 llb">
              <label class="mb-0">Postcode*</label>
              <input type="text" formControlName="pincode" name="pincode" class="form-control" id="pincode" maxlength="10"
                  [ngClass]="{ 'is-invalid': submitted && g.pincode.errors }" required [ngModel]="addresslistDetail.pincode">
              <div *ngIf="submitted && g.pincode.errors" class="invalid-feedback">
                  <div *ngIf="g.pincode.errors.required">Please enter city</div>
              </div>
            </div>
            <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">Country*</label>
                <select style="width:100%;" formControlName="countryid" class="form-control input-lg" id="countryid" name="countryid" [ngClass]="{ 'is-invalid': submitted && g.countryid.errors }" (change)="onSelectCountry($event.target.value)" [ngModel]="addresslistDetail.countryid">
                    <option [value]="defaultVal">Select Country</option>
                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                </select>
                <div *ngIf="submitted && g.countryid.errors" class="invalid-feedback">
                    <div *ngIf="g.countryid.errors.required">Please select country</div>
                </div>
            </div>
    
            <div class="form-group mb-2 col-lg-6 llb">
              <label class="mb-0">Tax ID</label>
              <input type="text" formControlName="taxid" name="taxid" class="form-control" id="taxid" maxlength="10"
                   [ngModel]="addresslistDetail.taxid">            
          </div> 
          <div class="form-group mb-2 col-lg-12 llb">
            <label class="mb-0">Address Line 1*</label>
            <input type="text" formControlName="address1" name="address1" id="address1" 
                class="form-control" [ngClass]="{ 'is-invalid': submitted && g.address1.errors }" required 
                [ngModel]="addresslistDetail.address1" maxlength="60">
            <div *ngIf="submitted && g.address1.errors" class="invalid-feedback">
                <div *ngIf="g.address1.errors.required">Please enter address line 1</div>
            </div>
          </div>  
          <div class="form-group mb-2 col-lg-12 llb">
              
            <label class="mb-0">Address Line 2 </label>
            <input type="address2" formControlName="address2" name="address2" id="address2" 
                class="form-control" [ngModel]="addresslistDetail.address2" maxlength="60">
                 </div>
            </div>

                </div>
                <div class="col-sm-5">
                    <div class="payment_strip">
                        <label class="labettext">Please enter card details</label>                  
                        <stripe-card class="setdesign" #stripeCard (catch)="onStripeError($event)" [(invalid)]="invalidError"
                        (tokenChange)="setStripeToken($event,addresslistForm.value, 'custom-payment-1')"></stripe-card>  
                    </div>
                    <div class="optionss">
                        <img src="assets/img/paycard.png">     
                    </div>  
                </div>

            <div class="form-group col-lg-12 mb-3">
                 
             </div>
           </div> 
           
         
        

          
         
      
        <div class="modal-footer">
          <button type="button" class="btn btn-primary com_btn" (click)="stripeCard.createToken(extraData)">Pay Now</button>
          <button type="button" class="btn btn-dark com_btn" (click)="closeModal('custom-payment-1');">Cancel</button>
        </div>
      </form>
      </div>
    </div>
  </jw-modal>
