<app-header>
</app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
           
            <a style="cursor: pointer;" (click)="amllogredirect()" class="backlist mb-4 mr-0"><i aria-hidden="true" class="fa fa-angle-left"></i> Personal Credit Search</a>
           
            <div class="clearfix"></div>
            <div class="tabs__content">

                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Personal Details </h5>
                            </div>
                            <div class="col-lg-6">
                                <a type="submit" href="{{ url }}" *ngIf="url != ''"   target="_blank" class="btn btn-primary com_btn float-right" >Download <i class="fa fa-file-pdf-o ml-2"></i></a>
                               
                             <!-- <a (click)="downloadpdf()" >Download PDF</a>  -->
                            </div>
                            <!-- <div class="col-lg-6">
                              <div class="input-group">
                                  <input placeholder="Search.." class="form-control">
                              </div>

                          </div> -->
                        </div>

                        <div class="job_tab">
                            <mat-tab-group>
                                <mat-tab label="Details">
                                    <ng-template matTabContent>
                                        <div class="strip_show mt-2" *ngIf="bussinessdetails.person">
                                            <div class="row mr-0 ml-0">
                                           
                                                <div class="col-lg-2 mobilecentersh">
                                                    <div class="circle_credit_img">
                                                        <img src="{{bussinessdetails.person.imageURL }}" class="img width100" *ngIf="bussinessdetails.person.imageURL != '' || bussinessdetails.person.imageURL != null">
                                                        <img src="../../../../../assets/img/user_pic_emp.png" class="img width100" *ngIf="bussinessdetails.person.imageURL == '' || bussinessdetails.person.imageURL == null">
                                                    </div>
                                                   
                                                    
                                                </div>
                                                <div class="col-lg-10 d-flex align-items-center ">
                                                    <div class="category flex-wrap justify-content-start align-items-center text-center d-flex width100">
                                                        <div class="catog_box" *ngIf="PEPs.length>0">
                                                            <p>PEP</p>
                                                            <span>{{PEPs.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="LawEnforcement.length>0">
                                                            <p>Law Enforcement</p>
                                                            <span>{{LawEnforcement.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="AdverseMedia.length>0">
                                                            <p>Adverse Media</p>
                                                            <span>{{AdverseMedia.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="PreviousSanctions.length>0">
                                                            <p>Corporate/Business</p>
                                                            <span>{{PreviousSanctions.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="idv.length>0">
                                                            <p>IDV</p>
                                                            <span>{{idv.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="FinancialRegulator.length>0">
                                                            <p>Financial Regulator</p>
                                                            <span>{{FinancialRegulator.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="DisqualifiedDirector.length>0">
                                                            <p>Disqualified Director</p>
                                                            <span>{{DisqualifiedDirector.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="Insolvency.length>0">
                                                            <p>Insolvency</p>
                                                            <span>{{Insolvency.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="CurrentSanctions.length>0">
                                                            <p>Sanctions</p>
                                                            <span>{{CurrentSanctions.length}}</span>
                                                        </div>
                                                    </div>
                        
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="row mr-0 ml-0">
                                                
                                               
                                                <div class="col-sm-12">
                        
                                               
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                        <div class="job_detils_date width30">
                                                            <p class="job_address">Name</p>   
                                                                <h5 class="job_profile">{{ bussinessdetails.person.forename}} {{ bussinessdetails.person.middlename}} {{ bussinessdetails.person.surname}}</h5>
                                                               
                                                            
                                                            
                                                        
                                                        </div>
                                                        <div class="job_detils_date width30 text-center mobileleft">
                                                            <p class="job_address">Date of Birth</p>                           
                                                            <p class="job_profile">{{bussinessdetails.person.dateOfBirth}}</p>
                                                            <p class="job_profile" *ngIf="!bussinessdetails.person.dateOfBirth"> N/A</p>
                                                        </div>
                                                        <div class="job_detils_date width30 text-center mobileleft">
                                                            <p class="job_address">Nationality</p>                           
                                                            <p class="job_profile"><span class="badge badge-danger">{{bussinessdetails.person.nationality.nationality}}</span></p>
                                                            <p class="job_profile" *ngIf="!bussinessdetails.person.nationality.nationality"> N/A</p>
                                                        </div>
                                                        
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                        <div class="job_detils_date width30">
                                                            <p class="job_address">Telephone</p>                           
                                                            <p class="job_profile">{{bussinessdetails.person.telephoneNumber}}  
                                                                <!-- <span *ngIf="bussinessdetails.person.telephoneNumber != ''">    (Home) </span> -->
                                                                        
                                                            </p>
                                                            <p class="job_profile" *ngIf="!bussinessdetails.person.telephoneNumber"> N/A</p>
                                                        </div>
                                                        <div class="job_detils_date width30">
                                                            <p class="job_address">Fax</p>                           
                                                            <p class="job_profile">{{bussinessdetails.person.faxNumber}}  
                                                                <!-- <span *ngIf="bussinessdetails.person.telephoneNumber != ''">    (Home) </span> -->
                                                                        
                                                            </p>
                                                            <p class="job_profile" *ngIf="!bussinessdetails.person.faxNumber"> N/A</p>
                                                        </div>
                                                        <div class="job_detils_date width30 text-center mobileleft">
                                                            <p class="job_address">Home</p>     
                                                            <p class="job_profile">   {{bussinessdetails.person.mobileNumber}} </p>
                                                            <p class="job_profile" *ngIf="!bussinessdetails.person.mobileNumber"> N/A</p>
                                                                <!-- <span *ngIf="bussinessdetails.person.mobileNumber != ''">(Mob)</span></p> -->
                                                        </div>
                                                        <div class="job_detils_date width30 text-center mobileleft">
                                                            <p class="job_address">Gender</p>                           
                                                            <p class="job_profile">{{bussinessdetails.person.gender}}</p>
                                                            <p class="job_profile" *ngIf="!bussinessdetails.person.gender"> N/A</p>
                                                        </div>
                                                    
                                                    
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                        <div class="job_detils_date width30">
                                                            <p class="job_address">Address(es)   </p>                           
                                                            <p class="job_profile"  *ngFor="let companyaddress of bussinessdetails.person.addresses;">{{companyaddress.address1}} {{companyaddress.address2}} {{companyaddress.address3}} {{companyaddress.address4}} {{ companyaddress.city }} {{ companyaddress.county }} {{ companyaddress.country.name }} {{ companyaddress.postcode}}</p>
                                                            <p class="job_profile" *ngIf="bussinessdetails.person.addresses == 0"> N/A</p>
                                                        </div>
                                                        <div class="job_detils_date width30 text-center ">
                                                            <p class="job_address">PEP Level   </p>                           
                                                            <p class="job_profile" ><span class="badge badge-success">{{bussinessdetails.person.pepLevel}}</span></p>
                                                            <p class="job_profile" *ngIf="!bussinessdetails.person.pepLevel"> N/A</p>
                                                        </div>
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                        <div class="job_detils_date width100">
                                                            <p class="job_address">Alias </p>                           
                                                            <p class="job_profile" >
                                                                <span class="aliesname" *ngFor="let companyaliases of bussinessdetails.person.aliases;">{{companyaliases.forename}} {{companyaliases.middlename}} {{companyaliases.surname}}</span>
                                                             </p>
                                                            <p class="job_profile" *ngIf="bussinessdetails.person.aliases == 0">
                                                                <span class="aliesname">N/A</span>
                                                            </p>
                                                        </div>
                                                    
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                        <div class="job_detils_date width100">
                                                            <p class="job_address">Note </p>                           
                                                            <p class="job_profile">
                        
                                                                <span>
                                                                    <div *ngFor="let personnotes of bussinessdetails.person.notes;">
                                                                <ul class="note">
                                                                    <li *ngIf="personnotes.datasource != null ">{{ personnotes.datasource.name}}</li>
                                                                    <li>{{ personnotes.text}}</li>
                                                                </ul>
                                                                </div>
                                                                <p class="job_profile" *ngIf="bussinessdetails.person.notes == 0"> N/A</p>
                                                            </span>
                        
                                                            </p>
                                                        </div>
                                                    
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                        <div class="job_detils_date width100">
                                                            <p class="job_address">Political Positions </p>                           
                                                            <div class="job_profile mt-1" >
                                                                <p class="job_profile" *ngIf="bussinessdetails.person.politicalPositions == 0"> N/A</p>
                                                                <ol>
                                                                    <li class="politicalpositions" *ngFor="let politic of bussinessdetails.person.politicalPositions;">{{politic.description}}, {{politic.country.name}} <p *ngIf="politic.from"  style="display: contents;"> from {{politic.from}}</p> <p *ngIf="politic.to"  style="display: contents;"> to {{politic.to}}</p> </li>
                                                                </ol>
                                                               
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                        <div class="job_detils_date width100">
                                                            <p class="job_address">Reference Id </p>                           
                                                            <p class="job_profile mt-1" >
                        
                                                                <span class="text-success"><b>{{ bussinessdetails.person.id}}</b></span>
                                                            </p>
                                                        </div>
                                                    
                                                    </div>
                                                 </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </mat-tab>
                                <mat-tab label="Businesses ({{(bussinessdetails.person && bussinessdetails.person.linkedBusinesses)?bussinessdetails.person.linkedBusinesses.length:0}})">

                                    <ng-template matTabContent>

                                        
                                        <ng-container *ngIf="bussinessdetails.person.linkedBusinesses">
                                            <div class="job_list_section visa_request_section" *ngFor="let linkbussiness of bussinessdetails.person.linkedBusinesses">


                                                <div class="job_detils_date width22">
                                                    <div class="visa_user_details ">
                                                        <p class="job_address">Business Name</p>
                                                        <h4 class="text-danger">{{ linkbussiness.businessName }}</h4>
                                                    </div>
                                                </div>
                                                <div class="job_detils_date width50">
                                                    <p class="job_address">Position</p>
                                                    <p class="job_profile">{{ linkbussiness.position }}</p>
                                                </div>
                                            </div>
                                        </ng-container>
                                        
                                        <div class="job_list_section employee_list_section leave_section"  *ngIf="bussinessdetails.person.linkedBusinesses && bussinessdetails.person.linkedBusinesses.length <=0">
                                            <div class="job_detils_date">
                                                <p class="job_profile">
                                                    No Record Found
                                                </p> 
                                            </div>                                                                                       
                                        </div>

                                        <hr class="line" />


                                    </ng-template>
                                </mat-tab>
                                <mat-tab label="Linked Persons ({{(bussinessdetails.person && bussinessdetails.person.linkedPersons)?bussinessdetails.person.linkedPersons.length:0}})">
                                    <ng-template matTabContent>

                                        
                                        <div class="job_list_section visa_request_section "  *ngFor="let linkperson of bussinessdetails.person.linkedPersons">

                                            <div class="job_detils_date width30">
                                                <p class="job_address">Name</p>
                                                <p class="ninumber text-danger"><b>{{ linkperson.name }}</b></p>
                                            </div>
                                            <div class="job_detils_date width70">
                                                <div class="visa_user_details width100 ">
                                                    <p class="job_address">Connection </p>
                                                    <p class="job_profile width70">{{ linkperson.association }}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="job_list_section employee_list_section leave_section"  *ngIf="bussinessdetails.person.linkedPersons.length <=0">
                                            <div class="job_detils_date">
                                                <p class="job_profile">
                                                    No Record Found
                                                </p> 
                                            </div>                                                                                       
                                        </div>
                                        <hr class="line" />


                                    </ng-template>
                                </mat-tab>
                                <mat-tab label="Documents ({{(bussinessdetails.person && bussinessdetails.person.articles)?bussinessdetails.person.articles.length:0}})">
                                    <ng-template matTabContent>
                                        <div class="job_list_section visa_request_section"  *ngFor="let document of bussinessdetails.person.articles">
                                            <div class="job_detils_date width30">
                                                <p class="job_address">Name</p>
                                                <p class="ninumber text-primary">{{urlfind( document.originalURL)}}
                                                </p>
                                            </div>
                                            <div class="job_detils_date width30">
                                                <div class="visa_user_details ">
                                                    <p class="job_address">Category</p>
                                                    <p class="job_profile" > <span *ngFor="let category of document.categories"> {{ category.name }}</span> </p>
                                                </div>
                                            </div>
                                            <div class="job_detils_date width13">
                                                <p class="job_address">Creation Date</p>
                                                <p class="job_profile">{{ document.dateCollected }}</p>
                                            </div>
                                            <div class="job_detils_date width7">

                                                <p class="job_address" *ngIf="document.c6URL" >
                                                    <button type="submit" class="btn btn-danger com_btn" (click)="opendocumentlink('pdf-image-viewer-complete-document', document.c6URL)"><i class="fa fa-file-text-o" aria-hidden="true"></i>
                                                    </button>
                                                </p>
                                          <!-- <button type="submit" class="btn btn-secondary com_btn"><i class="fa fa-trash-o"></i></button>  -->
                                                

                                                <button type="submit" class="btn btn-danger com_btn" *ngIf="document.snippet" (click)="openModal('document_view',document.snippet,document.originalURL )" ><i class="fa fa-file-text-o"></i></button>
                                            </div>
                                            
                                        </div>
                                        <div class="job_list_section employee_list_section leave_section"  *ngIf="bussinessdetails.person.articles.length <=0">
                                            <div class="job_detils_date">
                                                <p class="job_profile">
                                                    No Record Found
                                                </p> 
                                            </div>                                                                                       
                                        </div>
                                        <hr class="line" />
                                    </ng-template>
                                </mat-tab>
                            </mat-tab-group>
                        </div>

                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<jw-modal id="document_view" class="modal" role="dialog">
    <div role="document" class="formgroup" *ngIf="snippet">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h5 class="modal-title">{{}}</h5> -->
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('document_view');">
        <span aria-hidden="true">&times;</span>
      </button>
            </div>
            <div class="modal-body">
                <div class="container">


                    <div class="all_detials">
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">
                                    <div class="catog_box width50">
                                        <p><b>Title</b></p>
                                        <span>{{snippet.title}}</span><span *ngIf="!snippet.title"> N/A</span>
                                    </div>
                                    <div class="catog_box width50">
                                        <p><b>Keywords</b></p>
                                        <p *ngFor="let category of snippet.keywordsMatched">
                                            <span *ngIf="category"> N/A</span>
                                            {{category}}
                                        </p>
                                        <!-- <span *ngIf="snippet.keywordsMatched.length <=0 "> N/A</span> -->
                                    
                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="">
                                        <p><b>Summary</b></p>
                                        <p>{{snippet.summary}}</p>
                                    </div>
                                    <!-- <div class="catog_box width100">
                                        <p>OriginalURL</p>
                                        <span *ngIf="originalURL">{{originalURL}}</span>
                                    </div> -->


                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box width50">
                                        <p><b>Original URL</b></p>
                                        <span><a href="{{originalURL}}" target="_blank">{{originalURL}}</a></span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>

                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box">
                                        <span></span>
                                    </div>
                                    <div class="catog_box">
                                        <span><i class="fa fa-question-circle" aria-hidden="true"></i>
                                           Disclaimer</span>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-primary com_btn"> Remove from review list</button> -->
                <button type="button" class="btn btn-secondary com_btn" (click)="closeModal('document_view');" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="business_view" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Monitor Group</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('business_view');">
      <span aria-hidden="true">&times;</span>
    </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="strip_show mt-2" *ngIf="bussinessdetails.person">
                        <div class="row mr-0 ml-0">
                       
                            <div class="col-lg-2">
                                <div class="circle_credit_img">
                                    <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                                </div>
                               
                                
                            </div>
                            <div class="col-lg-10 d-flex align-items-center ">
                                <div class="category flex-wrap justify-content-start align-items-center text-center d-flex width100">
                                    <div class="catog_box fourbox">
                                        <p>PEP</p>
                                        <span>9</span>
                                    </div>
                                    <div class="catog_box fourbox">
                                        <p>Adverse Media </p>
                                        <span>3</span>
                                    </div>
                                    <div class="catog_box fourbox">
                                        <p>IDV</p>
                                        <span>6</span>
                                    </div>
                                    <div class="catog_box fourbox">
                                        <p>Corporate Rating</p>
                                        <span>2</span>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                        <hr/>
                        <div class="row mr-0 ml-0">
                            
                           
                            <div class="col-sm-12">
    
                           
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                    <div class="job_detils_date width30">
                                        <p class="job_address">Name</p>   
                                            <h5 class="job_profile">{{ bussinessdetails.person.forename}} {{ bussinessdetails.person.middlename}} {{ bussinessdetails.person.surname}}</h5>
                                        
                                        
                                    
                                    </div>
                                    <div class="job_detils_date width30 text-center">
                                        <p class="job_address">Date of Birth</p>                           
                                        <p class="job_profile">{{bussinessdetails.person.dateOfBirth}}</p>
                                    </div>
                                    <div class="job_detils_date width30 text-center">
                                        <p class="job_address">Nationality</p>                           
                                        <p class="job_profile"><span class="badge badge-danger">{{bussinessdetails.person.nationality.nationality}}</span></p>
                                    </div>
                                    
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                
                                    <div class="job_detils_date width30">
                                        <p class="job_address">Telephone</p>                           
                                        <p class="job_profile">{{bussinessdetails.person.telephoneNumber}}  
                                            <!-- <span *ngIf="bussinessdetails.person.telephoneNumber != ''">    (Home) </span> -->
                                                    
                                        </p>
                                    </div>
                                    <div class="job_detils_date width30 text-center">
                                        <p class="job_address">Home</p>     
                                        <p class="job_profile">   {{bussinessdetails.person.mobileNumber}} </p>
                                        
                                            <!-- <span *ngIf="bussinessdetails.person.mobileNumber != ''">(Mob)</span></p> -->
                                    </div>
                                    <div class="job_detils_date width30 text-center">
                                        <p class="job_address">Gender</p>                           
                                        <p class="job_profile">{{bussinessdetails.person.gender}}</p>
                                    </div>
                                
                                
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                
                                
                                    <div class="job_detils_date width30">
                                        <p class="job_address">Address(es)   </p>                           
                                        <p class="job_profile"  *ngFor="let companyaddress of bussinessdetails.person.addresses;">{{companyaddress.address1}} {{companyaddress.address2}} {{companyaddress.address3}} {{companyaddress.address4}}, {{ companyaddress.city }} {{ companyaddress.county }} {{ companyaddress.country.name }}- {{ companyaddress.postcode}}</p>
                                    </div>
                                    <div class="job_detils_date width30 text-center">
                                        <p class="job_address">PEP Level   </p>                           
                                        <p class="job_profile" ><span class="badge badge-success">{{bussinessdetails.person.pepLevel}}</span></p>
                                    </div>
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                
                                
                                    <div class="job_detils_date width100">
                                        <p class="job_address">Alias </p>                           
                                        <p class="job_profile" >
                                            <span class="aliesname" *ngFor="let companyaliases of bussinessdetails.person.aliases;">{{companyaliases.forename}} {{companyaliases.middlename}} {{companyaliases.surname}}</span>
                                         </p>
                                        <p class="job_profile" *ngIf="bussinessdetails.person.aliases == null">
                                            <span class="aliesname">N/A</span>
                                        </p>
                                    </div>
                                
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                    <div class="job_detils_date width100">
                                        <p class="job_address">Note </p>                           
                                        <p class="job_profile">
    
                                            <span>
                                                <div *ngFor="let personnotes of bussinessdetails.person.notes;">
                                            <ul class="note">
                                                <li *ngIf="personnotes.datasource != null ">{{ personnotes.datasource.name}}</li>
                                                <li>{{ personnotes.text}}</li>
                                            </ul>
                                            </div>
    
                                        </span>
    
                                        </p>
                                    </div>
                                
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                
                                
                                    <div class="job_detils_date width100">
                                        <p class="job_address">Political Positions </p>                           
                                        <div class="job_profile mt-1" >
                                            <ol>
                                                <li class="politicalpositions" *ngFor="let politic of bussinessdetails.person.politicalPositions;">{{politic.description}}</li>
                                            </ol>
                                           
                                        </div>
                                    </div>
                                
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                
                                
                                    <div class="job_detils_date width100">
                                        <p class="job_address">Reference Id </p>                           
                                        <p class="job_profile mt-1" >
    
                                            <span class="text-success"><b>{{ bussinessdetails.person.id}}</b></span>
                                        </p>
                                    </div>
                                
                                </div>
                             </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2">
                            <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                        </div>
                        <div class="col-lg-10  d-flex">
                            <div class="category align-items-center text-center d-flex width100">
                                <div class="catog_box fourbox">
                                    <p>PEP</p>
                                    <span>9</span>
                                </div>
                                <div class="catog_box fourbox">
                                    <p>Adverse Media </p>
                                    <span>3</span>
                                </div>
                                <div class="catog_box fourbox">
                                    <p>IDV</p>
                                    <span>6</span>
                                </div>
                                <div class="catog_box fourbox">
                                    <p>Corporate Rating</p>
                                    <span>2</span>
                                </div>

                            </div>

                        </div>
                    </div>
                    <hr/>
                    <div class="all_detials">
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">
                                    <div class="catog_box width50">
                                        <p>Business Name</p>
                                        <span>Monitor Group</span>
                                    </div>
                                    <div class="catog_box width50">
                                        <p>Alias</p>
                                        <span>Monitor Company Inc, Monitor Inc,Monitor Company Group LP, Monitor Company Group Limited Partnership. </span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box width50">
                                        <p>Addresses</p>
                                        <span>Two canal park Cambridge 02141 Massachusettls United State of America
                                        </span>
                                    </div>
                                    <div class="catog_box width50">
                                        <p>Website </p>
                                        <span>www.monitor.com</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr/>


                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box">
                                        <span>Reference Id</span>
                                    </div>
                                    <div class="catog_box">
                                        <span>788878</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger com_btn"> Add to review list</button>
                <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="linkperson_view" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Sir Richard Dearlove</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('linkperson_view');">
      <span aria-hidden="true">&times;</span>
    </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-2">
                            <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                        </div>
                        <div class="col-lg-10  d-flex">
                            <div class="category align-items-center text-center d-flex  width100">
                                <div class="catog_box fourbox">
                                    <p>PEP</p>
                                    <span>4</span>
                                </div>
                                <div class="catog_box fourbox">
                                    <p>IDV</p>
                                    <span>3</span>
                                </div>

                            </div>

                        </div>
                    </div>
                    <hr/>
                    <div class="all_detials">
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center text-center d-flex  width100">
                                    <div class="catog_box width22">
                                        <p>Person Name</p>
                                        <span>Sir Richard Dearlove</span>
                                    </div>
                                    <div class="catog_box width7">
                                        <p>Gender</p>
                                        <span>Male</span>
                                    </div>
                                    <div class="catog_box width15">
                                        <p>Date of Birth</p>
                                        <span>23/01/1945</span>
                                    </div>
                                    <div class="catog_box width15 text-center">
                                        <p>PEP Level</p>
                                        <span class="badge badge-success colorwhite">1</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">
                                    <div class="catog_box width15">
                                        <p>Nationality</p>
                                        <span class="text-danger">British</span>
                                    </div>
                                    <div class="catog_box width60">
                                        <p>Political Position</p>
                                        <span>Former Head of the Secret Intelligence services(MI6), United Kindom from 1999 to August 2004.</span>
                                    </div>


                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box width100">
                                        <p>Note</p>
                                        <span>PEP Tier 1</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>


                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box">
                                        <span>Reference Id</span>
                                    </div>
                                    <div class="catog_box">
                                        <span>1226286</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button"  [routerLink]="['/creditsearchresult',personal.id]" class="btn btn-danger com_btn"> Add to review list</button> -->
                <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</jw-modal>



<jw-modal id="pdf-image-viewer-complete-document" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-complete-document');">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-12">

                            <iframe *ngIf="imgvisasrc == false" [src]="iframevisasrc" width="100%" height="600" frameborder="0"></iframe>
                            <img *ngIf="imgvisasrc == true" [src]="iframevisasrc" width="100%" />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</jw-modal>