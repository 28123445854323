"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var myvisa_service_1 = require("../../../services/myvisa.service");
var country_service_1 = require("../../../services/country.service");
var forms_1 = require("@angular/forms");
var visa_model_1 = require("../../../models/visa/visa.model");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var alert_service_1 = require("../../../services/alert.service");
var platform_browser_1 = require("@angular/platform-browser");
var app_global_1 = require("../../../app.global");
var router_1 = require("@angular/router");
var CheckaivisaComponent = /** @class */ (function () {
    function CheckaivisaComponent(formBuilder, uploadService, visaService, datePipe, country, alerts, sanitizer, activatedRoute, router) {
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.visaService = visaService;
        this.datePipe = datePipe;
        this.country = country;
        this.alerts = alerts;
        this.sanitizer = sanitizer;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.wizard = 0;
        this.token_expired = false;
        this.aipayment = false;
        this.is_rtw = 0;
        this.filterItems = [
            {
                name: 'All Visa Details',
                id: 0,
                selected: true
            },
            {
                name: 'Accepted',
                id: 2,
                selected: false
            },
            {
                name: 'Rejected',
                id: 3,
                selected: false
            },
            {
                name: 'Waiting for review',
                id: 1,
                selected: false
            }
        ];
    }
    CheckaivisaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.activatedRoute.queryParams.subscribe(function (params) {
            if (params['is_rtw']) {
                _this.is_rtw = params['is_rtw'];
            }
        });
        this.activatedRoute.params.subscribe(function (params) {
            _this.empid = params['id'];
            _this.employeemasterid = params['id'];
        });
        if (this.is_rtw == 0 || this.is_rtw == 1) {
        }
        else {
            this.router.navigate(['/loginqq']);
        }
        this.getCountries();
        this.visapopForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            visanumber: ['', forms_1.Validators.required],
            visatype: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            visaexpiry: ['', forms_1.Validators.required],
            visastart: ['', forms_1.Validators.required],
            iscurrent: [''],
            stype: ['']
        });
        this.submitted = false;
        this.disableBtn = true;
        this.buttoninsert = "Submit";
        this.visaDetail = new visa_model_1.Visa();
        this.visaDetail.nationalityid = "";
        this.Userid = localStorage.getItem('userid');
        var dd = this.Userid; // localStorage.getItem('userid');
        this.visaDetail.employeeid = this.empid; //this.Userid;
        this.visaDetail.employeevisadeatilid = "0";
        this.filename = "Choose file";
        this.profilepic = "Choose file";
        this.filebackname = 'Choose file';
    };
    CheckaivisaComponent.prototype.getCountries = function () {
        var _this = this;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
            console.log(_this.countrylist);
        });
    };
    Object.defineProperty(CheckaivisaComponent.prototype, "f", {
        get: function () {
            return this.visapopForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    CheckaivisaComponent.prototype.toggle = function (id) {
        if (id == 1) {
            this.submitted = true;
            console.log(this.visapopForm);
            if (this.visapopForm.invalid) {
                return;
            }
        }
        this.wizard = id;
    };
    CheckaivisaComponent.prototype.toggle_skip = function (id) {
        this.imgbackURL = null;
        this.wizard = id;
    };
    CheckaivisaComponent.prototype.toggle2 = function (id) {
        // if(id == 1){
        //   this.wizard2 = false;
        //   this.wizard1 = true;
        //   this.wizard3 = false;
        // }
        // if(id == 2){
        //   this.wizard3 = false;
        //   this.wizard1 = false;
        //   this.wizard2 = true;
        // }
    };
    CheckaivisaComponent.prototype.onSubmit = function (value3, id, stype) {
        var _this = this;
        this.submitted = true;
        this.disableBtn = false;
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagename = imagepath;
        }
        else {
            value3.imagename = this.visaDetail.visadocname;
        }
        if (this.selectedbackFiles) {
            this.file = this.selectedbackFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filebacksizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.filebackformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagebackname = imagepath;
        }
        else {
            value3.imagebackname = this.visaDetail.visabackdocname;
        }
        if (this.selected_profile_image) {
            this.file = this.selected_profile_image;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filebacksizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.filebackformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.visaprofileimage = imagepath;
        }
        else {
            value3.visaprofileimage = this.visaDetail.visaprofileimage;
        }
        value3.visastart = this.datePipe.transform(new Date(value3.visastart), "dd MMM yyyy");
        value3.visaexpiry = this.datePipe.transform(new Date(value3.visaexpiry), "dd MMM yyyy");
        this.wizard = 6;
        value3.stype = stype;
        value3.createby = this.Userid;
        value3.isrighttowork = this.is_rtw;
        this.visaService.myvisadetailsUpdatebyadmin(value3).subscribe(function (datamain) {
            var data3 = { "employeeidproofdeatilid": datamain.employeevisadeatilid };
            var cid = { id: value3.countryid };
            _this.country.getcountrylistbyid(cid).subscribe(function (countrydata) {
                var countryiso = countrydata.isothree;
                var idtype = 'VISA';
                var visalog = {
                    employeeid: _this.employeemasterid,
                    documentid: datamain.employeevisadeatilid,
                    documenturl: value3.imagename,
                    documenttype: 'v'
                };
                _this.visaService.visaailogupdate(visalog).subscribe(function (dbvisaresult) {
                    if (dbvisaresult.success == '1') {
                        var data = {
                            merchantIdScanReference: dbvisaresult.logid,
                            country: countryiso,
                            idType: idtype,
                            customerId: _this.employeemasterid,
                            is_rtw: _this.is_rtw,
                            callbackUrl: app_global_1.AppGlobals.API_ENDPOINT + "/api/aiconfirmation",
                            documenturl: value3.imagename,
                            documentbackurl: value3.imagebackname,
                            imageurl: value3.visaprofileimage
                        };
                        _this.visaService.visadocumentupdate(data).subscribe(function (data5) {
                            if (data5.success == true) {
                                _this.wizard = 5;
                            }
                            else {
                                _this.wizard = 4;
                                _this.alerts.error("something went wrong please try again");
                            }
                        });
                    }
                    else {
                        _this.wizard = 4;
                        _this.alerts.error("something went wrong please try again");
                    }
                });
            });
            // this.wizard = 4;
        });
        this.filename = "Choose file";
    };
    CheckaivisaComponent.prototype.sanitize = function (url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    };
    CheckaivisaComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.fileformaterror = true;
        }
        else {
            this.fileformaterror = false;
            this.filesizeerror = false;
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.imgURL = reader.result;
            };
            // this.selectedFiles = files[0]; 
        }
    };
    CheckaivisaComponent.prototype.selectbackFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filebackname = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filebacksizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.filebackformaterror = true;
            this.filebacksizeerror = false;
        }
        else {
            this.filebackformaterror = false;
            this.filebacksizeerror = false;
            this.selectedbackFiles = files[0];
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.imgbackURL = reader.result;
            };
        }
    };
    CheckaivisaComponent.prototype.selectprofile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.profilepic = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.pro_pic_size_error = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.pro_pic_format_error = true;
            this.pro_pic_size_error = false;
        }
        else {
            this.pro_pic_format_error = false;
            this.pro_pic_size_error = false;
            this.selected_profile_image = files[0];
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.profile_pic_url = reader.result;
            };
        }
    };
    return CheckaivisaComponent;
}());
exports.CheckaivisaComponent = CheckaivisaComponent;
