<div class="check_verification" *ngIf="token_expired !=1">
  <div class="wizadlineshow">
      <div class="wizard_details">
          <div class="container">
              <div class="main-banner">
                  <div class="banner-form">
                      <div class="row">
                          <div class="col-sm-12">
                              <div class="tankushow">
                                  <div class="detailstext">
                                      <h1 class="tokenfont">Document Verified</h1>
                                      <p></p>
                                  </div>
                                  <img alt="shape" src="../../../../../assets/img/thanks.png">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div id="visa-form-submit" class="s12 white docvisapass" *ngIf="token_expired ==1" >
  <div class="check_verification">
      <div class="wizadlineshow">

  <div id="wizard-23"  *ngIf="buttonshow =='Yes'"  class="wizard_details">
    <div class="container">
        <div class="main-banner">
            <div class="overviewsshow check_support mt-2">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="header text-center">
                            <h1 class="headprofile mt-0 mb-0 width100">AI Document Verification</h1>
                        </div>
                        <hr/>
                    </div>
                  </div>




                  <div class="row  mb-5"  *ngIf="mode =='p'">
                    <div class="col-lg-12">
                        <div class="border-box">
                            <div class="overview_details emp">                                
                                <div class="profile_dtailsshow">
                                    <div class="detailsshow width50 pr-3">
                                        <span>Passport No.</span>
                                        <p>{{vs.passportnumber}}</p>
                                    </div>
                                    <div class="detailsshow width50 pr-0">
                                        <span>Nationality</span>
                                        <p>{{vs.nationality.countryname }}</p>
                                    </div>
                                </div>
                                <div class="profile_dtailsshow">
                                    <div class="detailsshow width50 pr-0 mt-0">
                                        <span>Issue Date</span>
                                        <p>{{vs.passportissue | date: 'dd MMM yyyy'}}</p>
                                    </div>
                                    <div class="detailsshow width50 mt-0">
                                        <span>Expiry Date</span>
                                        <p>{{vs.passportexpiry | date: 'dd MMM yyyy'}}</p>
                                    </div>
                                </div>
                                <div class="profile_dtailsshow">
                                    <div class="detailsshow width50 pr-3 mt-0">
                                        <span>Place of Issue</span>
                                        <p>{{vs.placeofissue}}</p>
                                    </div>                         
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>





                    <div class="row mb-5" *ngIf="mode =='v'">
                      <div class="col-lg-12">
                          <div class="border-box">
                              <div class="overview_details emp">
                                 
                                  <div class="profile_dtailsshow">
                                      <div class="detailsshow width50 pr-3">
                                          <span>Visa Number</span>
                                              <p>{{vs.visanumber}}</p>
                                      </div>
                                      <div class="detailsshow width50">
                                          <span>Visa Type</span>
                                              <p>{{vs.visatype }}</p>
                                      </div>
                                  </div>
                                  <div class="profile_dtailsshow">
                                      <div class="detailsshow width50 pr-3 mt-0">
                                          <span>Start Date</span>
                                              <p>{{vs.visastart | date: 'dd MMM yyyy'}}</p>
                                      </div>
                                      <div class="detailsshow width50 mt-0">
                                          <span>Expiry Date</span>
                                              <p>{{vs.visaexpiry | date: 'dd MMM yyyy'}}</p>
                                      </div>
                                  </div>
                                  <div class="profile_dtailsshow">
                                      <div class="detailsshow width50 mt-0 pr-3">
                                          <span>Visa Issue country</span>
                                              <p> {{vs.nationality.countryname}}</p>
                                      </div>                                     
                                  </div>
                              </div>
                          </div>
                      </div>                      
                  </div>



                  <div class="row mb-5"  *ngIf="mode =='id'">
                    <div class="col-lg-12" >
                        <div class="border-box">
                            <div class="overview_details emp">
                               
                                <div class="profile_dtailsshow">
                                    <div class="detailsshow width50 pr-3">
                                        <span>Document Number</span>
                                        <p>{{vs.idnumber}}</p>
                                    </div>
                                    <div class="detailsshow width50 pr-3">
                                        <span>Document Type</span>
                                        <p>{{vs.documenttype.documentname }}</p>
                                    </div>
                                </div>
                                <div class="profile_dtailsshow">
                                    <div class="detailsshow width50 pr-3 mt-0">
                                        <span>Expiry Date</span>
                                        <p>{{vs.docexpiry | date: 'dd MMM yyyy'}}</p>
                                    </div>
                                    <div class="detailsshow width50 pr-0 mt-0">
                                        <span>Issuing Country</span>
                                        <p>{{vs.nationality.countryname}}</p>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>

                  <div class="row">
                    <div class="col-sm-12">
                        <div class="uploaded_img" >
                            <div class="img_sec">
                                <img class="p-4" [src]="frontimage" (click)="openModalidproof(frontimage)">
                                <i class="fa fa-check" aria-hidden="true"></i>
                            </div>
                            <div class="img_sec">
                                <img class="p-4" [src]="backimage"  (click)="openModalidproof(backimage)">
                                <i class="fa fa-check" aria-hidden="true"></i>
                            </div>
                            <div class="img_sec">
                                <img class="p-4 userself" [src]="profile"  (click)="openModalidproof(profile)">
                                <i class="fa fa-check" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div *ngIf="showloader">
                            <div class="browser-screen-loading-content" >
                                <div class="loading-dots dark-gray">
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                </div>
                                <p>Loading</p>
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>
                <div class="row">
                    <div class="col-lg-12 col-md-12 text-right aligncenter">
                        <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                        <div class="next">
                            <button type="submit" (click)="rejectdoc()" class="btn btn-dark mr-2 mt-0 ">Reject </button>
                            <button type="button" class="btn btn-primary mt-0" (click)="approvedoc()">Approve</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>



<jw-modal id="pdf-image-viewer-myidproofsystem" class="modal" role="dialog">
  <div role="document" class="formgroup">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-myidproofsystem');">
                      <span aria-hidden="true">&times;</span>
                    </button>
          </div>
          <div class="modal-body">
              <div class="container">
                  <div class="row">
                      <!-- <div class="col-lg-12">
                          <iframe *ngIf="imgidproofsrc == false" [src]="iframeidproofsrc" width="100%" height="600" frameborder="0"></iframe>
                          <img *ngIf="imgidproofsrc == true" [src]="iframeidproofsrc" width="100%" />
                      </div> -->
                      <div class="modal-body">
                          <div class="container">
                              <div class="row">
                                  <div class="col-lg-12">
                                      <!-- <div class="overlaytext">
                                          <p>Front Image</p>
                                      </div> -->
                                      <!-- <iframe *ngIf="imgidproofsrc == false" [src]="iframeidproofsrc" width="100%" height="600" frameborder="0"></iframe> -->
                                      <img *ngIf="imgidproofsrc == true" [src]="iframeidproofsrc" width="100%" />
                                  </div>
                                  
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</jw-modal>
