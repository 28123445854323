"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var AmlService = /** @class */ (function () {
    function AmlService(commonService) {
        this.commonService = commonService;
    }
    /*********Get AML User Name and password ******/
    AmlService.prototype.getAuthenticatedetails = function () {
        return this.commonService.formpostOnlyid('/aml/findamldetails');
    };
    AmlService.prototype.updateAuthenticatedetails = function (value) {
        return this.commonService.formpost('/aml/updatetoken', value);
    };
    AmlService.prototype.getAuthenticate = function (data) {
        return this.commonService.formpostForWithoutToken('/authenticate', data);
    };
    AmlService.prototype.getCountries = function () {
        return this.commonService.formpostOnlyid('/aml/findcountrylist');
    };
    AmlService.prototype.getCompanySearch = function (data) {
        return this.commonService.formpost('/aml/getcompanylist', data);
    };
    AmlService.prototype.getCompanyNewSearch = function (data) {
        return this.commonService.formpost('/aml/getcompanynewlist', data);
    };
    AmlService.prototype.getCompanyNewSearchDetails = function (data) {
        return this.commonService.formpost('/aml/getcompanynewdetails', data);
    };
    AmlService.prototype.getPersonalSearch = function (data) {
        return this.commonService.formpost('/aml/getpersonallist', data);
    };
    AmlService.prototype.getSearchPersonalSearch = function (data) {
        return this.commonService.formpost('/aml/getsearchpersonallist', data);
    };
    AmlService.prototype.getSearchPersonalSearchById = function (data) {
        return this.commonService.formpost('/aml/getsearchpersonalbyId', data);
    };
    AmlService.prototype.getCompanyCreditReport = function (id) {
        return this.commonService.formgetForAML('/companies/' + id);
    };
    AmlService.prototype.getCompanylogrecord = function (value) {
        return this.commonService.formpost('/aml/findalldetailsbyrecord', value);
    };
    AmlService.prototype.generatePdf = function (value) {
        return this.commonService.formpost('/aml/downloadalldetailsbyrecord', value);
    };
    AmlService.prototype.generateNewbussinessPdf = function (value) {
        return this.commonService.formpost('/aml/donwload_new_bussiness_report', value);
    };
    AmlService.prototype.loadamllogsystem = function (value) {
        return this.commonService.formpost('/aml/bussinesssearchrecord', value);
    };
    AmlService.prototype.generatenorecordPdf = function (value) {
        return this.commonService.formpost('/aml/downloadalldetailsbynorecord', value);
    };
    AmlService.prototype.generatenorecordv3Pdf = function (value) {
        return this.commonService.formpost('/aml/downloadalldetailsv3norecord', value);
    };
    AmlService.prototype.getCompanySearchCriteria = function (data) {
        return this.commonService.formpostForAML('/companies/searchcriteria', data);
    };
    AmlService.prototype.getCompanyReportJSON = function (data) {
        return this.commonService.formpostForAML('/companies/schema/{countryCode}', data);
    };
    AmlService.prototype.getCompanyComplianceSearchCriteria = function (data) {
        return this.commonService.formpostForAML('/compliancetemp/companies/searchCriteria', data);
    };
    AmlService.prototype.getListofCompanyPreDefinedSearches = function () {
        return this.commonService.formgetForAML('/compliancetemp/companies/predefinedSearches');
    };
    AmlService.prototype.getCompanyComplianceSearch = function (data) {
        return this.commonService.formgetForAMLParam('/compliancetemp/companies/c-100-fullFile', data);
    };
    AmlService.prototype.getIndividualsComplianceSearchCriteria = function (data) {
        return this.commonService.formgetForAML('/compliancetemp/people/searchCriteria');
    };
    AmlService.prototype.getListofIndividualsPreDefinedSearches = function (data) {
        return this.commonService.formgetForAML('/compliancetemp/people/predefinedSearches');
    };
    AmlService.prototype.getIndividualPersonComplianceSearch = function (data) {
        return this.commonService.formgetForAML('/compliancetemp/people/{predefinedSearch}');
    };
    AmlService.prototype.getBankMatch = function (data) {
        return this.commonService.formgetForAML('/localSolutions/GB/bankmatch');
    };
    AmlService.prototype.getListDecisionTrees = function (data) {
        return this.commonService.formgetForAML('/decisionEngine/GUID');
    };
    AmlService.prototype.getRunDecisionTree = function (data) {
        return this.commonService.formpostForAML('/decisionEngine/{provenirId}', data);
    };
    AmlService.prototype.getAmlCompanyDetails = function () {
        return this.commonService.formpostOnlyid('/amlmonitoring/getallcompany');
    };
    AmlService.prototype.postActiveAmlDetails = function (data) {
        return this.commonService.formpost('/amlmonitoring/activemonitoring', data);
    };
    AmlService.prototype.postDeactiveAmlDetails = function (data) {
        return this.commonService.formpost('/amlmonitoring/deactivemonitoring', data);
    };
    AmlService.prototype.postActiveAmllogDetails = function (data) {
        return this.commonService.formpost('/amlmonitoring/activemonitoringlogid', data);
    };
    AmlService.prototype.postDeactiveAmllogDetails = function (data) {
        return this.commonService.formpost('/amlmonitoring/deactivemonitoringlogid', data);
    };
    AmlService.ngInjectableDef = i0.defineInjectable({ factory: function AmlService_Factory() { return new AmlService(i0.inject(i1.CommonService)); }, token: AmlService, providedIn: "root" });
    return AmlService;
}());
exports.AmlService = AmlService;
