<app-header>
</app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <a [routerLink]="['/search-personal-aml','aml']" *ngIf="ctype == 0" class="backlist mb-4 mr-0"><i aria-hidden="true" class="fa fa-angle-left"></i> Personal Credit Search</a>
            <a [routerLink]="['/search-personal-aml','aml']" *ngIf="ctype == 1" class="backlist mb-4 mr-0"><i aria-hidden="true" class="fa fa-angle-left"></i> Personal Check Search</a>
            
            
            <div class="clearfix"></div>
            <div class="tabs__content">

                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Personal Details </h5>
                            </div>

                            <div class="col-lg-6">
                                <a type="button" (click)="norecordfound()" style="color: #fff;" [innerHtml]="downloadtext" target="_blank" class="btn btn-primary com_btn float-right" >No Record Found <i class="fa fa-Plus ml-2"></i></a>
                                <a type="button" href="{{ url }}" *ngIf="url != ''"   target="_blank" class="btn btn-primary com_btn float-right" >Download <i class="fa fa-file-pdf-o ml-2"></i></a>
                          </div>
                         
                        </div>

                       
                        <div class="credit_data_show"  *ngIf="result==true">
                            <div class="job_list_section visa_request_section"  *ngFor="let personal of searchresult.matches">
                                <div class="job_detils_date">
                                    <div class="visa_user_pic">
                                        <img src="{{ personal.person.imageURL }}" class="img width100" *ngIf="personal.person.imageURL != null ">
                                        <img src="../../../../../assets/img/user_pic_emp.png" class="img width100" *ngIf="personal.person.imageURL == null">
                                    </div>
                                </div>
                                <div class="job_detils_date width20">
                                   
                                        <h5 class="job_profile ">{{ personal.person.forename}} {{ personal.person.middlename}} {{ personal.person.surname}}</h5>
                                        <p class="phone text-success"> <b>{{ personal.person.gender}}</b></p>
                                        <p class="ninumber text-primary">Id - {{ personal.person.id}} </p>
                                        <span class="badge badge-danger">{{ personal.person.nationality.nationality}}</span>
                                   
                                </div>
                                <div class="job_detils_date width20">
                                    <p class="job_address">Data Set</p>
                                    {{dataset(personal.person)}}   
                                    <p class="job_profile">{{datasetlist}}</p>
                                </div>
                                <div class="job_detils_date width7">
                                    <p class="job_address">PEP Level</p>
                                    <span class="badge badge-success">{{personal.person.pepLevel}}</span>
                                    <p class="job_profile" *ngIf="!personal.person.pepLevel"> N/A</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Date of Birth</p>
                                    <p class="job_profile">{{ personal.person.dateOfBirth  | date: 'dd MMM yyyy' }}</p>
                                    <p class="job_profile" *ngIf="!personal.person.dateOfBirth"> N/A</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Nationality</p>
                                    <p class="job_profile text-danger"><b>{{ personal.person.nationality.nationality}}</b></p>
                                    <p class="job_profile" *ngIf="!personal.person.nationality.nationality"> N/A</p>
                                </div>
                                <div class="job_detils_date aligncenter width7">

                                    <div id="container" class="green_text">
                                        <div class="dot"></div>
                                        <div class="pulse"></div>

                                    </div>
                                    <p class="job_profile green_text m-0">{{ personal.score}} </p>


                                </div>
                                <div class="job_detils_date width7">

                                    <p class="job_address">
                                        <button type="submit" class="btn btn-primary com_btn" (click)="openModalMastersystem('personal-pdf-viewer-visa',personal)"><i class="fa fa-file-image-o"></i></button>
                                        <!-- <button type="submit" class="btn btn-danger com_btn" ><i class="fa fa-pencil"></i></button>
                                      <button type="submit" class="btn btn-secondary com_btn"><i class="fa fa-trash-o"></i></button> -->
                                    </p>
                                </div>



                            </div>
                           
                        </div>






                        <div class="row"  *ngIf="result==false">
                            <div class="col-lg-12" *ngIf="searchcondition">
                                <div class="border-box float-left width100">
                                    <div class="overview_details emp width100">
                                        <div class="profile_dtailsshow visastaus mb-3">
                                           <h5></h5>
                                           
                                        </div>
                                        <div class="profile_dtailsshow"> 
                                            <div class="detailsshow width25 pr-3">
                                                <span>First Name</span>
                                                <p *ngIf="searchcondition.Forename">{{searchcondition.Forename}}</p>
                                                <p *ngIf="!searchcondition.Forename">NA</p>
                                            </div> 
                                            <div class="detailsshow width25 pr-3">
                                                <span>Middle Nam</span>
                                                <p *ngIf="searchcondition.Middlename">{{searchcondition.Middlename}}</p>
                                                <p *ngIf="!searchcondition.Middlename">NA</p>
                                                
                                            </div>
                                            <div class="detailsshow width25 pr-3">
                                                <span>Last Name</span>
                                                <p *ngIf="searchcondition.Surname">{{searchcondition.Surname}}</p>
                                                <p *ngIf="!searchcondition.Surname">NA</p>
                                                
                                            </div>
                                            <div class="detailsshow width25 pr-3">
                                                <span>Date of Birth</span>
                                                <p>{{searchcondition.DateOfBirth | date: 'dd MMM yyyy' }}</p>
                                            </div>
                                        </div>
                                        <div class="profile_dtailsshow"> 
                                            <div class="detailsshow width25 pr-3">
                                                <span>Year of Birth</span>
                                                <p *ngIf="searchcondition.YearOfBirth">{{searchcondition.YearOfBirth}}</p>
                                                <p *ngIf="!searchcondition.YearOfBirth">NA</p>
                                                
                                            </div> 
                                            <div class="detailsshow width25 pr-3">
                                                <span>Town/City</span>
                                                <p *ngIf="searchcondition.City">{{searchcondition.City}}</p>
                                                <p *ngIf="!searchcondition.City">NA</p>
                                                
                                                
                                            </div>
                                            <div class="detailsshow width25 pr-3">
                                                <span>County/State</span>
                                                <p *ngIf="searchcondition.County">{{searchcondition.County    }}</p>
                                                <p *ngIf="!searchcondition.County">NA</p>
                                                
                                            </div>
                                            <div class="detailsshow width25 pr-3">
                                                <span>Post / Zip Code</span>
                                                <p *ngIf="searchcondition.Postcode">{{searchcondition.Postcode}}</p>
                                                <p *ngIf="!searchcondition.Postcode">NA</p>
                                               
                                            </div>
                                        </div>

                                        <div class="profile_dtailsshow"> 
                                            <div class="detailsshow width25 pr-3">
                                                <span>PEP</span>
                                                <p *ngIf="searchcondition.PEP">Yes</p>
                                                <p *ngIf="!searchcondition.PEP">No</p>

                                               
                                            </div> 
                                            <div class="detailsshow width25 pr-3">
                                                <span>Sanction - Current</span>
                                                <p *ngIf="searchcondition.CurrentSanctions">Yes</p>
                                                <p *ngIf="!searchcondition.CurrentSanctions">No</p>

                                                
                                            </div>
                                            <div class="detailsshow width25 pr-3">
                                                <span>Sanction - Previous</span>
                                                <p *ngIf="searchcondition.PreviousSanctions">Yes</p>
                                                <p *ngIf="!searchcondition.PreviousSanctions">No</p>

                                                
                                            </div>
                                            <div class="detailsshow width25 pr-3">
                                                <span>Financial Regulator</span>
                                                <p *ngIf="searchcondition.FinancialRegulator">Yes</p>
                                                <p *ngIf="!searchcondition.FinancialRegulator">No</p>


                                            </div>
                                        </div>

                                        <div class="profile_dtailsshow"> 
                                            <div class="detailsshow width25 pr-3">
                                                <span>Law Enforcement</span>
                                                <p *ngIf="searchcondition.LawEnforcement">Yes</p>
                                                <p *ngIf="!searchcondition.LawEnforcement">No</p>

                                            </div> 
                                            <div class="detailsshow width25 pr-3">
                                                <span>Disqualified Director (UK Only)</span>
                                                <p *ngIf="searchcondition.DisqualifiedDirector">Yes</p>
                                                <p *ngIf="!searchcondition.DisqualifiedDirector">No</p>


                                            </div>
                                            <div class="detailsshow width25 pr-3">
                                                <span>Insolvency (UK & Ireland)</span>

                                                <p *ngIf="searchcondition.Insolvency">Yes</p>
                                                <p *ngIf="!searchcondition.Insolvency">No</p>


                                            </div>
                                            <div class="detailsshow width25 pr-3">
                                                <span>Adverse Media</span>
                                                <p *ngIf="searchcondition.AdverseMedia">Yes</p>
                                                <p *ngIf="!searchcondition.AdverseMedia">No</p>

                                            </div>
                                        </div>

                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="credit_data_show"  *ngIf="result==false">
                            <div class="job_list_section visa_request_section" >
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div> 
                        </div>

                        

                        

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<jw-modal id="personal-pdf-viewer-visa" class="modal" role="dialog">
    <div role="document" class="formgroup" *ngIf="bussinessdetails">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{ bussinessdetails.person.forename}} {{ bussinessdetails.person.middlename}} {{ bussinessdetails.person.surname}}
                    </h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('personal-pdf-viewer-visa');">
        <span aria-hidden="true">&times;</span>
      </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="strip_show">
                        <div class="row mr-0 ml-0">
                       
                            <div class="col-lg-2 mobilecentersh">
                                <div class="circle_credit_img">
                                    <img src="{{ bussinessdetails.person.imageURL }}" class="img width100" *ngIf="bussinessdetails.person.imageURL != '' || bussinessdetails.person.imageURL != null">
                                    <img src="../../../../../assets/img/user_pic_emp.png" class="img width100" *ngIf="bussinessdetails.person.imageURL == '' || bussinessdetails.person.imageURL == null">
                                </div>
                               
                                
                            </div>
                            <div class="col-lg-10 d-flex align-items-center ">
                                <div class="category flex-wrap justify-content-start align-items-center text-center d-flex width100">
                                    <div class="catog_box" *ngIf="PEPs.length>0">
                                        <p>PEP</p>
                                        <span>{{PEPs.length}}</span>
                                    </div>
                                    <div class="catog_box" *ngIf="LawEnforcement.length>0">
                                        <p>Law Enforcement</p>
                                        <span>{{LawEnforcement.length}}</span>
                                    </div>
                                    <div class="catog_box" *ngIf="AdverseMedia.length>0">
                                        <p>Adverse Media</p>
                                        <span>{{AdverseMedia.length}}</span>
                                    </div>
                                    <div class="catog_box" *ngIf="PreviousSanctions.length>0">
                                        <p>IDV</p>
                                        <span>{{PreviousSanctions.length}}</span>
                                    </div>
                                    <div class="catog_box" *ngIf="FinancialRegulator.length>0">
                                        <p>Financial Regulator</p>
                                        <span>{{FinancialRegulator.length}}</span>
                                    </div>
                                    <div class="catog_box" *ngIf="DisqualifiedDirector.length>0">
                                        <p>Disqualified Director</p>
                                        <span>{{DisqualifiedDirector.length}}</span>
                                    </div>
                                    <div class="catog_box" *ngIf="Insolvency.length>0">
                                        <p>Insolvency</p>
                                        <span>{{Insolvency.length}}</span>
                                    </div>
                                    <div class="catog_box" *ngIf="CurrentSanctions.length>0">
                                        <p>Sanctions</p>
                                        <span>{{CurrentSanctions.length}}</span>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                        <hr/>
                        <div class="row mr-0 ml-0">
                            
                           
                            <div class="col-sm-12">
    
                           
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                    <div class="job_detils_date width30">
                                        <p class="job_address">Name</p>   
                                            <h5 class="job_profile">{{ bussinessdetails.person.forename}} {{ bussinessdetails.person.middlename}} {{ bussinessdetails.person.surname}}</h5>
                                        
                                        
                                    
                                    </div>
                                    <div class="job_detils_date width30 text-center mobileleft">
                                        <p class="job_address">Date of Birth</p>                           
                                        <p class="job_profile">{{bussinessdetails.person.dateOfBirth}}</p>
                                        <p class="job_profile" *ngIf="!bussinessdetails.person.dateOfBirth"> N/A</p>
                                    </div>
                                    <div class="job_detils_date width30 text-center mobileleft">
                                        <p class="job_address">Nationality</p>                           
                                        <p class="job_profile"><span class="badge badge-danger">{{bussinessdetails.person.nationality.nationality}}</span></p>
                                        <p class="job_profile" *ngIf="!bussinessdetails.person.nationality.nationality"> N/A</p>
                                    </div>
                                    
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                
                                    <div class="job_detils_date width30">
                                        <p class="job_address">Telephone</p>                           
                                        <p class="job_profile">{{bussinessdetails.person.telephoneNumber}}  
                                            <!-- <span *ngIf="bussinessdetails.person.telephoneNumber != ''">    (Home) </span> -->
                                                    
                                        </p>
                                        <p class="job_profile" *ngIf="!bussinessdetails.person.telephoneNumber"> N/A</p>
                                    </div>
                                    <div class="job_detils_date width30 text-center mobileleft" >
                                        <p class="job_address">Home</p>     
                                        <p class="job_profile">   {{bussinessdetails.person.mobileNumber}} </p>
                                        <p class="job_profile" *ngIf="!bussinessdetails.person.mobileNumber"> N/A</p>
                                            <!-- <span *ngIf="bussinessdetails.person.mobileNumber != ''">(Mob)</span></p> -->
                                    </div>
                                    <div class="job_detils_date width30 text-center mobileleft">
                                        <p class="job_address">Gender</p>                           
                                        <p class="job_profile">{{bussinessdetails.person.gender}}</p>
                                        <p class="job_profile" *ngIf="!bussinessdetails.person.gender"> N/A</p>
                                    </div>
                                
                                
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                
                                
                                    <div class="job_detils_date width30">
                                        <p class="job_address">Address(es)   </p>                           
                                        <p class="job_profile"  *ngFor="let companyaddress of bussinessdetails.person.addresses;">{{companyaddress.address1}} {{companyaddress.address2}} {{companyaddress.address3}} {{companyaddress.address4}}, {{ companyaddress.city }} {{ companyaddress.county }} {{ companyaddress.country.name }}- {{ companyaddress.postcode}}</p>
                                        <p class="job_profile" *ngIf="bussinessdetails.person.addresses == 0"> N/A</p>
                                    </div>
                                    <div class="job_detils_date width30 text-center mobileleft">
                                        <p class="job_address">PEP Level   </p>                           
                                        <p class="job_profile" ><span class="badge badge-success">{{bussinessdetails.person.pepLevel}}</span></p>
                                        <p class="job_profile" *ngIf="!bussinessdetails.person.pepLevel"> N/A</p>
                                    </div>
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                
                                
                                    <div class="job_detils_date width100">
                                        <p class="job_address">Alias </p>                           
                                        <p class="job_profile" >
                                            <span class="aliesname" *ngFor="let companyaliases of bussinessdetails.person.aliases;">{{companyaliases.forename}} {{companyaliases.middlename}} {{companyaliases.surname}}</span>
                                         </p>
                                        <p class="job_profile" *ngIf="bussinessdetails.person.aliases == 0">
                                            <span class="aliesname">N/A</span>
                                        </p>
                                    </div>
                                
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                    <div class="job_detils_date width100">
                                        <p class="job_address">Note </p>                           
                                        <p class="job_profile">
    
                                            <span>
                                                <div *ngFor="let personnotes of bussinessdetails.person.notes;">
                                            <ul class="note">
                                                <li *ngIf="personnotes.datasource != null ">{{ personnotes.datasource.name}}</li>
                                                <li>{{ personnotes.text}}</li>
                                            </ul>
                                            </div>
                                            
    
                                        </span>
                                        
    
                                        </p>
                                        <p class="job_profile" *ngIf="bussinessdetails.person.notes == 0"> N/A</p>
                                    </div>
                                
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                
                                
                                    <div class="job_detils_date width100">
                                        <p class="job_address">Political Positions </p>                           
                                        <div class="job_profile mt-1" >
                                            <p *ngIf="bussinessdetails.person.politicalPositions == 0">N/A</p>
                                            <ol>
                                                <li class="politicalpositions" *ngFor="let politic of bussinessdetails.person.politicalPositions;">{{politic.description}}</li>
                                            </ol>
                                            
                                               
                                           
                                           
                                        </div>
                                    </div>
                                
                                </div>
                                <div class="job_list_section visa_request_section innerdetailsshow width100">
                                
                                
                                    <div class="job_detils_date width100">
                                        <p class="job_address">Reference Id </p>                           
                                        <p class="job_profile mt-1" >
    
                                            <span class="text-success"><b>{{ bussinessdetails.person.id}}</b></span>
                                        </p>
                                    </div>
                                
                                </div>
                             </div>
                        </div>
                    </div>
                 
                   

                    
                    <!-- <div class="all_detials">
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">
                                    <div class="catog_box">
                                        <p>Name</p>
                                        <span> {{ bussinessdetails.person.forename}} {{ bussinessdetails.person.middlename}} {{ bussinessdetails.person.surname}}</span>
                                    </div>
                                    <div class="catog_box">
                                        <p>Alias</p>
                                        <p *ngFor="let companyaliases of bussinessdetails.person.aliases;">
                                             {{companyaliases.forename}} {{companyaliases.middlename}} {{companyaliases.surname}}                                        </p>
                                    </div>
                                    <div class="catog_box">
                                        <p>Date of Birth</p>
                                        <span>{{bussinessdetails.person.dateOfBirth}}</span>
                                    </div>
                                    <div class="catog_box">
                                        <p>Nationality</p>
                                        <span>{{bussinessdetails.person.nationality.nationality}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">
                                    <div class="catog_box width25">
                                        <p>Gender</p>
                                        <span>{{bussinessdetails.person.gender}}</span>
                                    </div>
                                    <div class="catog_box width50">
                                        <p>Telephone</p>
                                        <span>{{bussinessdetails.person.telephoneNumber}} 
                                            
                                        <span *ngIf="bussinessdetails.person.telephoneNumber != ''">    (Home) </span>
                                            
                                            
                                        
                                        </span>
                                    </div>
                                    <div class="catog_box width50">
                                        <p>Home</p>
                                        <span>
                                            {{bussinessdetails.person.mobileNumber}} 
                                        
                                            <span *ngIf="bussinessdetails.person.mobileNumber != ''">(Mob)</span>
                                        
                                        </span>
                                    </div>
                                    <div class="catog_box width25">
                                        <p>PEP Level</p>
                                        <span>{{bussinessdetails.person.pepLevel}}</span>
                                    </div>


                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">


                                    <div class="catog_box width50">
                                        <p>Address(es)</p>
                                        <p *ngFor="let companyaddress of bussinessdetails.person.addresses;">
                                            {{companyaddress.address1}} {{companyaddress.address2}} {{companyaddress.address3}} {{companyaddress.address4}}, {{ companyaddress.city }} {{ companyaddress.county }} {{ companyaddress.country.name }}- {{ companyaddress.postcode}}
                                        </p>
                                        
                                    </div>
                                    <div class="catog_box width50">
                                        <p>Note</p>
                                        <span>
                                            <div *ngFor="let personnotes of bussinessdetails.person.notes;">
                                          <ul>
                                              <li *ngIf="personnotes.datasource != null ">{{ personnotes.datasource.name}}</li>
                                              <li>{{ personnotes.text}}</li>
                                          </ul>
                                        </div>

                                      </span>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">


                                    <div class="catog_box">
                                        <p>Political Positions</p>

                                        <div *ngFor="let politic of bussinessdetails.person.politicalPositions;">
                                                {{politic.description}}

                                        </div>
                                      
                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box">
                                        <span>Reference Id</span>
                                    </div>
                                    <div class="catog_box">
                                        <span>{{ bussinessdetails.person.id}}</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                    </div> -->

                </div>
            </div>
             <div class="modal-footer">
                <button [routerLink]="['/completepersonaldetails',logid,bussinessdetails.person.id]" type="button" class="btn btn-primary com_btn"> View All Details</button>
                <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal" (click)="closeModal('personal-pdf-viewer-visa');">Close</button>
            </div> 
        </div>
    </div>
</jw-modal> 