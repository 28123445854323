"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var DirectorcompanyComponent = /** @class */ (function () {
    function DirectorcompanyComponent() {
    }
    DirectorcompanyComponent.prototype.ngOnInit = function () {
    };
    return DirectorcompanyComponent;
}());
exports.DirectorcompanyComponent = DirectorcompanyComponent;
