import { Component, OnInit,Renderer2,ElementRef,ViewChild} from '@angular/core';
import { UserService } from '../../services/user.service';
import { CustomerService } from '../../services/customer.service';
import { Router } from '@angular/router';
import { Profile } from '../../models/overview/profile.model';
import { CompanyService } from '../../services/company.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],  
})
 
export class HeaderComponent implements OnInit {

  @ViewChild('megamenu') megamenu: ElementRef;  
  @ViewChild('megamenuopen') megamenuopen: ElementRef; 
  @ViewChild('megamenu1') megamenu1: ElementRef;  
  @ViewChild('megamenuopen1') megamenuopen1: ElementRef;
  
  
  Name: any;
  isline: any;
  companyData: any;
  logo: any;
  ctype: number;
  isadmin: any;
  permission:any;
  showmenuoo:any;
  public href: string = "";
  menu:any;

  public show:boolean = false;
  public rightshow:boolean = false;
  public rightshow2:boolean = false;


  public buttonName:any = 'Show';
  public innerWidth: any;
  constructor(   
    private data: CustomerService,
    private router: Router,
    private userService: UserService,
    private companyService: CompanyService,
    private renderer: Renderer2,
    private renderer1: Renderer2
    
  ) { 
    this.renderer.listen('window', 'click',(e:Event)=>{
    if(this.megamenuopen && e.target !== this.megamenuopen.nativeElement && e.target!== this.megamenu.nativeElement){
      this.rightshow = false;
      }
    });

    // this.renderer1.listen('window', 'click',(e:Event)=>{
    //   if(e.target !== this.megamenuopen1.nativeElement && e.target!== this.megamenu1.nativeElement){
    //     this.rightshow2 = false;
    //     }
    //   });
} 
  overviewData = new Profile();

  ngOnInit() {
    
   var userid = localStorage.getItem('userid');
   this.menu= localStorage.getItem('menu');

  if (!userid) {
    this.router.navigate(['/login']);
  }
  else {
    this.userService.checkaccesstokenbyid(userid).subscribe((data: any) => {
      if (data.status == false) {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('Name');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('isemail');
        localStorage.removeItem('isadmin');
        localStorage.removeItem('ispermission');
        localStorage.removeItem('ctype');
        localStorage.removeItem('logoname');
        localStorage.removeItem('menu');
        this.router.navigate(['/login']);
      }     
    });
  }

  this.data.getloginbytokencheck().subscribe((data: any) => {
    if(data.success == '1')
    {
      this.router.navigate(['/' + data.url]);
    }   
  });



  console.log('verify system',localStorage.getItem('isverify'));


  var isverify =  localStorage.getItem('isverify'); 
  this.ctype  = parseInt(localStorage.getItem('ctype'));

  console.log('Is verify',isverify);
  if(this.ctype ==1)
  {    
    if(isverify === '0')
    {this.router.navigate(['/payment-confirmation']);}

  }

  // console.log(this.ctype);
 
  // if(this.ctype ==1)
  // { 
  //   this.companyService.getcompanydetails().subscribe((data: any) => {
  //     if (!data.stuser) {               
  //       this.router.navigate(['/payment-confirmation']);
  //     }else if (data.verfiedforcheck === false ||  data.verfiedforcheck === 0)
  //     { 
  //       this.router.navigate(['/approval-confirmation']);          
  //     }
  //   });
  // }


  // if(this.ctype ==3)
  // { 
  //   this.companyService.getcompanydetails().subscribe((data: any) => {
  //     if (!data.stuser) {               
  //       this.router.navigate(['/payment-confirmation']) ;
  //     }
  //   });
  // }


    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    // console.log(this.permission);



    if( innerWidth > 414){
      this.show = true;
    }
    this.Name = localStorage.getItem('Name');
    

    this.data.getOverview().subscribe((data: any) => {
      this.overviewData = data;
    });
    
    

    

    this.innerWidth = window.innerWidth;
    var planid = localStorage.getItem('planid');
    // if (planid == '0' || planid == null || planid == undefined) {
    //   this.router.navigate(['/choose-plan']);
    // }



    this.isadmin = localStorage.getItem('isadmin');
    // console.log(this.isadmin);
    this.isline = localStorage.getItem('isline');
    this.logo = localStorage.getItem('logoname');

    if ((this.logo == "") || (this.logo == null)) {
      this.logo = "assets/img/logo.png";
    }
    // else
    // {
    //   this.logo = data.logoname;
    // }

    this.data.getOverview().subscribe((data: any) => {
      this.overviewData = data;
      if (this.overviewData.emailid == undefined) {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('Name');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('isemail');
        localStorage.removeItem('isadmin');
        localStorage.removeItem('ispermission');
        localStorage.removeItem('ctype');
        localStorage.removeItem('logoname');
        localStorage.removeItem('menu');
        this.router.navigate(['/login']);
      }
    });
  }  

  menuchange(id)
  {
    localStorage.setItem('menu', id);
    if(id == 1)
    {
      this.router.navigate(['/admindashboard']);
    }
    else
    {
      this.router.navigate(['/dashboard']);
    }  
  }

  toggle() {
    if( innerWidth == 768){
      this.show = !this.show;

    }
    else if ( innerWidth == 414){
      this.show = !this.show;
    }
  
  }
  righttoggle(id) {
    this.rightshow = !this.rightshow;
    this.rightshow2 = false;
  }
  righttoggle2(id) {
    this.rightshow2 = !this.rightshow2;
    this.rightshow = false;
  }
  logout() {

    if (confirm("Are you sure you want to sign out?")) {
      this.userService.logout().subscribe((data: any) => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('Name');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('isemail');
        localStorage.removeItem('isadmin');
        localStorage.removeItem('ispermission');
        localStorage.removeItem('logoname');
        localStorage.removeItem('menu');
        this.router.navigate(['/login']);
      });;
    }
  }
}