import { Component, OnInit } from '@angular/core';
// const jwt = require('jsonwebtoken');
// var fs = require('fs');
// const publicKey = fs.readFileSync('domain.crt');
import * as jwt_decode from 'jwt-decode';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
// import { ConstantService } from 'src/app/shared/constant/constant.service';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { AlertService } from '../../services/alert.service';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  token:any;
  access_token = '';
  userid = '';
  name = '';
  isline = '';
  planid = '';
  error = '';
  isemail = '';
  ismobile = '';
  isadmin = '';
  ispermission = '';
  ctype='';

  constructor(
    private activatedRoute: ActivatedRoute,
    public toastrService: ToastrService,
    private alerts: AlertService,
    private router: Router,
    private csservices: CustomerService
  ) { }

  ngOnInit(): void {


  // var token ='eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoicmFodWxAbnV0c3NvZnR3YXJlLmNvbSIsImlhdCI6MTY4ODMxMzU3NSwiZXhwIjoxNjg4MzE3MTc1fQ.Z0vwb5a3w4uRsXuwpADkj0suf0HbihPpOhIXDqmi8XmMMWwZkUP8zQmMsHFL3coZ1fUVKoXjvVOgOov0FXeBNowi8A_t_5B-f1kyTNyktjExr-vvEfqQm0loIVZVMn5t--pzIziLT0l7w7DVJe5nqJ0Gau7OzBBPBT7SiQv3lvJ_OzPSn_Rba4wkk1j2UDs0hRBLgWuTnJAPF-ztYzSllvX37_PjemudqK-CXsWTaSyQx0o8P5vlbvCducanCwGlywTvyYeGlz2LV2V86eBJR7n7B5ixs6TB_VCa0Rm5SQcUdLOskBx3Rlb56RbiHrzL4AIwKUtpLr0gEUC0uJEW9w';


  this.token = jwt_decode(this.activatedRoute.snapshot.queryParams['token']);

  console.log(this.token);
  localStorage.setItem('userToken', this.access_token);
  localStorage.setItem('userid', this.userid);
  localStorage.setItem('Name', this.name);
  localStorage.setItem('isline', this.isline);
  localStorage.setItem('planid', this.planid);
  localStorage.setItem('ctype', this.ctype);
  localStorage.setItem('isemail', this.isemail);
  localStorage.setItem('ismobile', this.ismobile);
  localStorage.setItem('isadmin', this.token.isadmin);
  localStorage.setItem('ispermission', this.token.ispermission);
  localStorage.setItem('logoname', this.token.logourl);
  localStorage.setItem('struser', this.token.struser);
  localStorage.setItem('menu', '0');
    // const decoded = jwt.verify(token, publicKey)
    // console.log(jwt_decode(this.token))
    if(this.token.id){
      if (this.token.estatus == false) 
        {
          this.alerts.error('Your account is inactive. Contact your administrator to activate it', true);
          this.router.navigate(['/']);
          return;
        }

        localStorage.setItem('userToken', this.token.id);
        localStorage.setItem('userid', this.token.userid);
        localStorage.setItem('emptype', this.token.emptype);
        localStorage.setItem('isline', this.token.isline);
        localStorage.setItem('planid', this.token.planid);
        localStorage.setItem('isemail', this.token.isemail);
        localStorage.setItem('ismobile', this.token.ismobile);
        localStorage.setItem('isadmin', this.token.isadmin);
        localStorage.setItem('ispermission', this.token.ispermission);
        localStorage.setItem('logoname', this.token.logourl);
        localStorage.setItem('ctype', this.token.ctype);
        localStorage.setItem('struser', this.token.struser);
        localStorage.setItem('menu', '0');
        
        if (this.token.isemail == false) {
          localStorage.setItem('otp', this.token.otp);
        }
        if(this.token.ismobile == false)
        {
          localStorage.setItem('otpmobile', this.token.otpmobile);
        }

        this.csservices.getOverview().subscribe((data6: any) => {
          var lname = '';
          if (data6.lname != null) {
            lname = data6.lname;
          }
          localStorage.setItem('Name', data6.fname + ' ' + lname);
          this.alerts.success('Welcome to Complygate', true);
          this.router.navigate(['/' + this.token.url]);
        });
    } else {
      this.alerts.error(this.token.error, true);
      this.router.navigate(['/']);
    }
  }

  openToast(message, title, options, type) {
    this.toastrService[type](message, title, options);
  }

}
