import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { AssetService } from '../../../services/asset.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Asset,Assetcategory } from '../../../models/asset/asset.model';
import { Router } from '@angular/router';


@Component({
  selector: 'app-settingassetcategory',
  templateUrl: './settingassetcategory.component.html',
  styleUrls: ['./settingassetcategory.component.css']
})
export class SettingassetcategoryComponent implements OnInit {

  Userid: any;
  data: any;
  assetDetail = new Asset();
  assetcateDetail = new Assetcategory();
  assetformGroup: FormGroup;
  options = [];
  buttoninsert: any;
  submitted: boolean = false;
  disableBtn: any;
  public searchText: string;
  permission: any;
  settingperm: any;
  record:number;
  
  items: Array<any>;
  
  pageOfItems: Array<any>;
  
  constructor(
    private modalService: ModalService,
    private asset: AssetService,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }


    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.settingperm = this.permission.m81;

    this.Userid = localStorage.getItem('userid');
    this.assetDetail = new Asset();
    this.assetformGroup = this.formBuilder.group({
      id: [''],
      categoryname: ['', Validators.required]
    });

    
   
    this.AllData();
  }

  AllData(){
    const sstatustype = { 'status': false, 'id': this.Userid } 
    this.asset.getassetcategory(sstatustype).subscribe((data: any) => {
      this.data = data;
      this.record = this.data.length;

      if(this.record < 30){
        this.pageOfItems = this.data;
      }
      else{
           this.items =this.data;
      }
    });
  }

  get f() { return this.assetformGroup.controls; }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  onSubmit(formassetcategory: any, id: string) {
    this.submitted = true;
    // var formassetcategory = assetcategorydata.value;
    // console.log(this.assetformGroup);
    if (this.assetformGroup.invalid) {
      return;
    }
    this.disableBtn = false;
    formassetcategory.userid = this.Userid;
    this.asset.settingassetcategoryUpdate(formassetcategory).subscribe((response: any) => {
      
      this.AllData();
      this.modalService.close(id);
    });

  }

  onChangeStatus(evt: any, asset: any)
  {
    var cstatus;
    if(evt.target.checked)
    {
      cstatus = '1';
    }
    else if(!evt.target.checked)
    {
      cstatus = '0';
    }
    this.asset.assetUpdateChangeStatus({'userid': this.Userid, 'id': asset.id, 'cstatus': evt.target.checked}).subscribe((data: any) => {
      
    
      this.AllData();
    });
  }

  openModal(id: string, data: any) {
    this.buttoninsert = "Update";
    this.disableBtn = true;
    this.assetDetail = data;
    this.assetformGroup.reset(this.assetDetail);
    this.modalService.open(id);
  }

  openModal2(id: string) {
    this.submitted = false;
    this.buttoninsert = "Submit";
    this.disableBtn = true;
    this.assetDetail = new Asset();
    this.assetDetail.id = "0";
    this.assetformGroup.reset(this.assetDetail);
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.AllData();
    this.modalService.close(id);
  }

}
