"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var MypassportService = /** @class */ (function () {
    function MypassportService(commonService) {
        this.commonService = commonService;
        this.modals = [];
    }
    MypassportService.prototype.getpassportList = function () {
        return this.commonService.formpostOnlyid('/employee/employeepassportlist');
    };
    MypassportService.prototype.getpassportListnew = function (value) {
        return this.commonService.formpost('/employee/employeepassportlistnew', value);
    };
    MypassportService.prototype.getpassportListnewbyadmin = function (value) {
        return this.commonService.formpost('/employee/employeepassportlistnewbyadmin', value);
    };
    MypassportService.prototype.getallpassportListbyadmin = function (value) {
        return this.commonService.formpost('/employee/employeeallpassportlistbyadmin', value);
    };
    MypassportService.prototype.downloadPassport = function (value) {
        return this.commonService.formpost('/employee/downloadpassport', value);
    };
    MypassportService.prototype.getpassportApprove = function () {
        return this.commonService.formpostOnlyid('/employee/employeepassportapprove');
    };
    MypassportService.prototype.getpassportRejected = function () {
        return this.commonService.formpostOnlyid('/employee/employeepassportrejected');
    };
    MypassportService.prototype.mypassportdetailsUpdatefile = function (value) {
        return this.commonService.formpost('/fileuploadFortesting', value);
    };
    MypassportService.prototype.mypassportdetailsUpdate = function (value) {
        return this.commonService.formpost('/employee/passportupdate', value);
    };
    MypassportService.prototype.mypassportdetailsUpdaterighttowork = function (value) {
        return this.commonService.refereeformpost('/employee/passportupdaterighttowork', value);
    };
    MypassportService.prototype.mypassportdetailsUpdatebyadmin = function (value) {
        return this.commonService.formpost('/employee/passportupdatebyadmin', value);
    };
    MypassportService.prototype.deletePassport = function (value) {
        return this.commonService.formpost('/employee/deletePassport', value);
    };
    MypassportService.prototype.mypassportdetailsCreate = function (value) {
        return this.commonService.formpost('/employee/insertpassportdetails', value);
    };
    MypassportService.prototype.passportdetailsbypassportid = function (value) {
        return this.commonService.formpost('/employee/passportdetailsbypassportid', value);
    };
    MypassportService.prototype.passportupdateForAI = function (value) {
        return this.commonService.formpost('/employee/passportupdateAIDetailsbyadmin', value);
    };
    MypassportService.prototype.passportaipaymentstaus = function (value) {
        return this.commonService.formpost('/employee/checkpassportaipaymentstatus', value);
    };
    MypassportService.ngInjectableDef = i0.defineInjectable({ factory: function MypassportService_Factory() { return new MypassportService(i0.inject(i1.CommonService)); }, token: MypassportService, providedIn: "root" });
    return MypassportService;
}());
exports.MypassportService = MypassportService;
