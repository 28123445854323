<div class="bg">
    <app-header></app-header>


    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <a [routerLink]="['/request/request-list']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Request List</a>
                <div class="clearfix"></div>
                <div class="tabs__content">

                    <div class="tabs__content__item active-tab">
                        <div class="content">


                            <div class="space_em row">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Passport Request List
                                    </h5>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text" [(ngModel)]="searchText" class="form-control" placeholder="Search">
                                   
                                </div>
                            </div>
                             <ng-container *ngIf="( datalist | filter: {passportnumber: searchText,passportissue: searchText,passportexpiry: searchText  }) as pageOfItems"> 
                                <div class="job_list_section visa_request_section" *ngFor="let passport of pageOfItems; let i=index ;">
                                        <div class="job_detils_date">
                                        <div class="visa_user_pic">
                                            <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                            <img *ngIf="passport.employee.imagename" src="{{passport.employee.imagename}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                            <img *ngIf="passport.employee.gender == true && (passport.employee.imagename == null || passport.employee.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                            <img *ngIf="passport.employee.gender == false && (passport.employee.imagename == null || passport.employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                        </div>

                                    </div>
                                    <div class="job_detils_date width22">

                                        <div class="visa_user_details">
                                            <h4>{{passport.employee.fname}} {{passport.employee.mname}} {{passport.employee.lname}}</h4>
                                            <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"> {{ passport.employee.emailid }}</i>
                                            </p>
                                            <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i> {{ passport.employee.phonenumber }}</p>
                                            <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i> NI Number {{ passport.employee.ninumber }} </p>
                                            <span class="badge badge-success" *ngIf="passport.nationality">{{ passport.nationality.countryname }}</span>

                                        </div>
                                    </div>
                                    <div class="job_detils_date width15">
                                        <p class="job_address">Passport Number</p>
                                        <p class="job_profile">{{passport.passportnumber}}</p>
                                    </div>
                                    <div class="job_detils_date width13">
                                        <p class="job_address">Issue Date</p>
                                        <p class="job_profile">{{passport.passportissue | date: 'dd MMM yyyy'}}</p>
                                    </div>
                                    <div class="job_detils_date width13">
                                        <p class="job_address">Expiry Date</p>
                                        <p class="job_profile">{{passport.passportexpiry | date: 'dd MMM yyyy'}}</p>
                                    </div>
                                    <div class="job_detils_date aligncenter width10">

                                        <div id="container" class="green_text" *ngIf="passport.iscurrent">
                                            <div class="dot"></div>
                                            <div class="pulse"></div>

                                        </div>
                                        <p class="job_profile green_text" *ngIf="passport.iscurrent">Active </p>
                                        <div id="container" class="" *ngIf="!passport.iscurrent">
                                            <div class="dot"></div>
                                            <div class="pulse"></div>

                                        </div>
                                        <p class="job_profile" *ngIf="!passport.iscurrent">Inactive </p>

                                    </div>
                                    <div class="job_detils_date visa_btn width15">

                                        <p class="job_address">
                                            <button type="submit" class="btn btn-secondary com_btn" (click)="openModal3('pdf-image-viewer',passport.passportdocname, passport.passportbackdocname)" *ngIf="passport.passportdocname || passport.passportbackdocname">
                                                <div class="tooltip_visa">
                                                    <p >View</p>
                                                </div>
                                                
                                                <i class="fa fa-file-image-o"></i>
                                            </button>
                                            <button type="submit" class="btn btn-primary com_btn" (click)="openModal('custom-passport-1',passport)">
                                                <div class="tooltip_visa">
                                                    <p >Edit</p>
                                                </div>
                                                <i class="fa fa-pencil"></i>
                                            </button>
                                            <button type="submit" class="btn btn-danger com_btn" (click)="onDelete(passport.employeepassdeatilid)">
                                                <div class="tooltip_visa">
                                                    <p >Delete</p>
                                                </div>
                                                <i class="fa fa-trash-o"></i>
                                            </button>
                                        </p>
                                    </div>
                                    <div class="width100">
                                        <!-- <div class="col-lg-6 text-left">
                                            <p class="badge badge-warning" *ngIf="passport.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Pending by AI </p>
                                            <p class="badge badge-success" *ngIf="passport.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                            <p class="badge badge-error" *ngIf="passport.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                                        </div> -->
                                        <div class="text-right">
                                            <p class="mb-0 fontsize12"> Verify the ID using Complygate AI. <a [routerLink]="['/passportaidetails',passport.employeepassdeatilid]">Click Here</a></p>
                                            <!-- <p class="mb-0" *ngIf="aipayment ==true && passport.nationality.ispassport == true"> You have exhausted the free AI ID verification. To continue using it please <a [routerLink]="['/passportaidetails',passport.employeepassdeatilid]">Click Here</a></p> -->
                                            <!-- <p class="mb-0" *ngIf="passport.nationality.ispassport == false"> The AI verification functionality for this country will be available soon.</p> -->
                                        </div>
                                    </div>
                                </div>
                                  <div class="job_list_section visa_request_section"  *ngIf="pageOfItems.length === 0">
                                    <div class="job_detils_date">
                                        <p class="job_profile">No Record Found</p>
                                    </div>
                                 </div>
                             </ng-container>
                          
                            <div class="job_list_section visa_request_section" *ngIf="record === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>
                            <!-- <div class="row">
                               
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div> -->






                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<jw-modal id="custom-passport-1" class="modal" role="dialog">

    <form [formGroup]="passformGroup" #passportForm="ngForm" class="s12 white" *ngIf="passportDetail">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="passportDetail.employeepassdeatilid">
                    <input type="hidden" formControlName="employeeid" class="form-control" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="passportDetail.employeeid">

                    <h5 class="modal-title" id="popupLabel">Passport Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-passport-1');">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Passport No.*</label>
                                <input type="passportnumber" formControlName="passportnumber" name="passportnumber" id="passportnumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.passportnumber.errors }" [ngModel]="passportDetail.passportnumber">
                                <div *ngIf="submitted && f.passportnumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.passportnumber.errors.required">Please Enter Passport No.</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Nationality*</label>
                                <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [ngModel]="passportDetail.nationalityid">
                    <option [value]="">Select Nationality</option>
                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                  </select>
                                <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                    <div *ngIf="f.nationalityid.errors.required">Please Select Nationality</div>
                                    <!-- <div *ngIf="f.countryid.errors.required">Nationality</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Passport Issue Date*</label>
                                <mat-form-field class="datecontrol" [ngClass]="{ 'is-invalid': submitted && f.passportissue.errors }">
                                    <input matInput [matDatepicker]="picker1" name="passportissue" id="passportissue" class="form-control noborder-invalid" formControlName="passportissue" [ngModel]="passportDetail.passportissue">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.passportissue.errors" class="invalid-feedback">
                                    <div *ngIf="f.passportissue.errors.required">Please Choose Passport Issue Date</div>
                                </div>


                            </div>
                            <div class="form-group col-lg-6">
                                <label>Passport Expiry Date*</label>
                                <mat-form-field class="datecontrol" [ngClass]="{ 'is-invalid': submitted && f.passportexpiry.errors }">
                                    <input matInput [matDatepicker]="picker2" id="passportexpiry" name="passportexpiry" class="form-control noborder-invalid" formControlName="passportexpiry" [min]="passformGroup.controls.passportissue.value" [ngModel]="passportDetail.passportexpiry">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>

                                <div *ngIf="submitted && f.passportexpiry.errors" class="invalid-feedback">
                                    <div *ngIf="f.passportexpiry.errors.required">Please Choose Passport Expiry Date</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Place of Issue of Passport*</label>
                                <input type="placeofissue" formControlName="placeofissue" class="" id="placeofissue" name="placeofissue" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.placeofissue.errors }" [ngModel]="passportDetail.placeofissue">
                                <div *ngIf="submitted && f.placeofissue.errors" class="invalid-feedback">
                                    <div *ngIf="f.placeofissue.errors.required">Please Enter Place of Issue</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Front Side Passport Image Upload*</label>
                                <div class="input-group">
                                    <div class="custom-file" (click)="file.click()">
                                        <input type="file" #file (change)="selectFile($event)">
                                        <label class="custom-file-label">{{filename}}</label>
                                    </div>
                                </div>
                                <div class="invalid-feedback" *ngIf="filereuirederror == true">Please select front side passport</div>
                                <div class="invalid-feedback" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                <div class="invalid-feedback" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png)</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <label>Back Side Passport Image Upload</label>
                                <div class="input-group" (click)="fileback.click()">
                                    <div class="custom-file">
                                        <input type="file" #fileback (change)="selectbackFile($event)">
                                        <label class="custom-file-label">{{filebackname}}</label>
                                    </div>
                                </div>
                                <div class="invalid-feedback" *ngIf="filebacksizeerror == true">File size must be less than 2 MB</div>
                                <div class="invalid-feedback" *ngIf="filebackformaterror == true">Please choose a valid file format (jpg, png)</div>
                            </div>
                            <div class="form-group col-lg-6 ">
                                <label>Current Visa?</label>
                                <div class="input-group mt-2">
                                    <label class="visapass mr-3 mb-0 d-flex align-items-center">Yes
                                        <input type="radio" value="1" formControlName="iscurrent" name="iscurrent" [ngModel]="passportDetail.iscurrent" >
                                        <span class="checkmark"></span> 
                                      </label> 
                                      <label class="visapass mb-0 d-flex align-items-center">No
                                        <input type="radio" value="0" formControlName="iscurrent" name="iscurrent" [ngModel]="passportDetail.iscurrent" >
                                        <span class="checkmark"></span>
                                      </label>
                                </div>
                            </div>
                            <!-- <div class="form-group col-lg-6 col-lg-6 d-flex align-items-center mt-3">
                                <label class="visaempcheck mt-4">
                                     Current Passport
                                 <input class="passs" type="checkbox" [checked]="passportDetail.iscurrent" formControlName="iscurrent" name="iscurrent" [(ngModel)]="passportDetail.iscurrent" />
                                 <span _ngcontent-c16="" class="checkmark"></span>
                                </label>
                               
                            </div> -->
                        </div>
                        <!-- <div class="row">
                            <div class="form-group col-lg-6">

                                <div>
                                    Is this your Current Passport?
                                    <input type="checkbox" [checked]="passportDetail.iscurrent" formControlName="iscurrent" name="iscurrent" [(ngModel)]="passportDetail.iscurrent">
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(passportForm.value,'custom-passport-1', '1')" value="{{buttoninsert}}" />
                    <input type="submit" class="btn btn-success com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(passportForm.value,'custom-passport-1', '2')" value="Update & Approve" />
                    <input type="submit" class="btn btn-danger com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(passportForm.value,'custom-passport-1', '3')" value="Reject" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-passport-1');" />

                </div>
            </div>
        </div>
    </form>
</jw-modal>


<jw-modal id="pdf-image-viewer" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer');">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row"> 
                        <div class="twoboximg" [ngClass]="!iframepassportbacksrc ?'col-lg-12':'col-lg-6'">
                            <div class="overlaytext">
                                <p>Front Image</p>
                            </div>
                            <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="500" frameborder="0"></iframe>
                            <img *ngIf="imgsrc == true" [src]="iframesrc" style="width:100%;" />
                        </div>
                        <div class="col-lg-6 twoboximg" *ngIf="iframepassportbacksrc">
                            <div class="overlaytext">
                                <p>Back Image</p> 
                            </div>
                            <iframe *ngIf="imgpassportbacksrc == false" [src]="iframepassportbacksrc" width="100%" height="250" frameborder="0"></iframe>
                            <img *ngIf="imgpassportbacksrc == true" [src]="iframepassportbacksrc" width="100%" />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</jw-modal>