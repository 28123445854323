import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { AmlBussiness,Aml } from '../../../models/aml/aml.model';
import { AmlService } from '../../../services/aml.service';

import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-amllog',
  templateUrl: './amllog.component.html',
  styleUrls: ['./amllog.component.css']
})
export class AmllogComponent implements OnInit {

  result: boolean;
  searchresult: any;
  searchresultnew: any;
  atype:boolean=false;
  logid:any;
  datasetlist: any;

  constructor( private amldetails: AmlService,    
private router: Router,    
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

var masterarray= [];
    

      var userid = localStorage.getItem('userid');
      if (!userid) {
        this.router.navigate(['/login']);
      }

      var ctype  = parseInt(localStorage.getItem('ctype'));
      if(ctype ==1){
        this.router.navigate(['/check-dashboard']);
      }


    this.logid = this.activatedRoute.snapshot.paramMap.get("type");

    // console.log('type check',this.logid);

    if(this.logid ==0)
    {
      this.atype =true;
      this.searchresultnew = new Aml();
    var val ={ atype:0 }  
    this.amldetails.getCompanylogrecord(val).subscribe((searchRes: any) => {  
      
      console.log(searchRes);
      this.searchresult = searchRes;//searchRes;   
    });


    }else 
    {
      this.atype =false;

      this.searchresultnew = new AmlBussiness();
    var val ={atype:1 }  
    this.amldetails.getCompanylogrecord(val).subscribe((searchRes: any) => {  
      console.log(searchRes);
      this.searchresult = searchRes; 
    });

    }






  }

  
dataset(values){
    
  this.datasetlist ="";

  if(values.PEP == true)
  {
    this.datasetlist = "<li><p> PEP </p> </li>";
  }

  if(values.AdverseMedia == true)
  {
    this.datasetlist += " <li><p> Adverse Media</p> </li>";
  }

  if(values.DisqualifiedDirector == true)
  {
    this.datasetlist += " <li><p>Disqualified Director (UK Only)</p> </li>";
  }

  if(values.FinancialRegulator == true)
  {
    this.datasetlist += " <li><p>Financial Regulator</p> </li>";
  }

  if(values.Insolvency == true)
  {
    this.datasetlist += "<li><p> Insolvency (UK & Ireland)</p> </li>";
  }

  if(values.LawEnforcement == true)
  {
    this.datasetlist += " <li><p>Law Enforcement</p> </li>";
  }

  if(values.CurrentSanctions == true)
  {
    this.datasetlist += "<li><p> Sanction - Current</p> </li>";
  }
  if(values.PreviousSanctions == true)
  {
    this.datasetlist += "<li><p> Sanction - Previous</p> </li>";
  }

  this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1); 

}


  outputnewset(d){

    // console.log(">>>>> Updae Code", d);
    var listmaster =JSON.parse(d);  
    // console.log(listmaster);
    this.searchresultnew = new Aml();
    this.searchresultnew = listmaster;

  }
  outputnewBussinessset(d){

    // console.log(">>>>> Updae Code", d);
    var listmaster =JSON.parse(d);  
    // console.log(listmaster);

    this.searchresultnew = new AmlBussiness();
    this.searchresultnew = listmaster;

  }
}
