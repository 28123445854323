<app-header></app-header>
<div class="section-wrapper">
    <div class="container">

        <div class="tabs" id="tabs">
            <!-- <a [routerLink]="['/admincreditsafe']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to AML, PEPs & Sanction Dashboard</a> -->
            <div class="clearfix"></div>
            <div class="tabs__content">
                <div class="tabs__content__item active-tab">
                    <div class="content">

                        <div class="topheader">
                            <div class="row row-sm topheader">
                                <div class="col-lg-5">
                                    <div>
                                        <app-checkcountshow></app-checkcountshow>
                                    </div>

                                </div>
                                <div class="col-lg-7 text-right">
                                    <div class="dflexflex mb-mm justify-content-end align-items-center">
                                        <button type="button" class="btn com_btn btn-success"
                                            (click)="amllogredirect()">
                                            <span class="lnr lnr-history pr-1"></span> {{historytext}} History
                                        </button>

                                    </div>
                                </div>
                                <!-- <div class="col-lg-7 text-right">
                                    <div class="dflexflex mb-mm justify-content-end align-items-center">
                                        <a class="card_box_lit" [routerLink]="['/check-business-aml-peps-sanction']">
                                            <button type="button" class="btn com_btn btn-success">Business AML, PEPs & Sanction
                                            </button>
                                        </a>                     
                                    </div>                             
                                </div>  -->

                            </div>
                            <div class="row" *ngIf="result==false">
                                <div class="col-sm-12 aligncenter mb-3 mt-3">
                                    <h5 class="hedingcolor">Personal Details </h5>
                                </div>
                            </div>
                            <div class="row" *ngIf="result==true">
                                <div class="col-sm-6 aligncenter mb-3 mt-3">
                                    <h5 class="hedingcolor">Personal Search Details </h5>
                                </div>


                                <div class="col-lg-6">
                                    <a type="button" (click)="norecordfound()" style="color: #fff;"
                                        [innerHtml]="downloadtext" target="_blank"
                                        class="btn btn-primary com_btn float-right">No Record Found <i
                                            class="fa fa-Plus ml-2"></i></a>
                                    <a type="button" href="{{ url }}" *ngIf="url" target="_blank"
                                        class="btn btn-primary com_btn float-right">Download <i
                                            class="fa fa-file-pdf-o ml-2"></i></a>
                                </div>
                            </div>



                            <!-- (ngSubmit)="onSubmit(bussinessForm.value,'Master')" -->


                            <div class="personal_form" *ngIf="result==false">


                                <form [formGroup]="personalformGroup" #bussinessForm="ngForm" class="s12 white">
                                    <!-- <input type="hidden" formControlName="Threshold" name="Threshold" id="Threshold" class="form-control" aria-describedby="emailHelp" placeholder="Business Name" [ngClass]="{ 'is-invalid': submitted && f.Threshold.errors }" [(ngModel)]="businessDetail.Threshold"
                                /> -->




                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="multicolorg">
                                                <span *ngFor="let dataset of selectpeplist"
                                                    class="has-tooltip north">{{dataset.id}}
                                                    <span class="tooltip">
                                                        <h4>{{dataset.heading}}</h4>

                                                        <p>{{dataset.description}}</p>
                                                    </span>

                                                </span>
                                                <button class="btn com_btn btn-dark " type="button"
                                                    (click)="choosedatabase()" [disabled]="showdataset">Choose
                                                    Datasets

                                                </button>
                                                <div *ngIf="showdataset" class="ml-2">
                                                    <button class="btn com_btn btn-success" type="button"
                                                        (click)="selectalldataset()"
                                                        [disabled]="selectpeplist.length ==7"
                                                        style="margin-right:10px;">Select All</button>
                                                    <button class="btn com_btn btn-danger" type="button"
                                                        (click)="clearalldataset()"
                                                        [disabled]="selectpeplist.length ==0">Clear All</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    <div class="row radio_section" *ngIf="showdataset" style="margin-top:20px ;">
                                        <div class="col-sm-11">
                                            <div class="row">
                                                <div class="col-sm-3 col-md-4 col-lg-3 mb-3"
                                                    *ngFor="let user of peplist">
                                                    <label class="checkbox_credit">{{user.name}}

                                                        <!-- <span class="tooltip">
                                                            <h4>{{user.heading}}</h4>
                                                            <p>{{user.description}}</p>
                                                        </span> -->
                                                        <input type="checkbox"
                                                            [checked]="checkpep(user)"
                                                            (change)="countryselectallfalse($event.target.checked,user)"
                                                            name="PEP" id="PEP" class="form-control"
                                                            aria-describedby="emailHelp" />
                                                        <span class="checkmark"></span>
                                                        <span class="tool-tip">{{user.name}}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <button type="button" (click)="closedataset()"
                                                class="btn com_btn btn-success">Close</button>
                                        </div>
                                    </div>


                                    <div class="row" style="margin-top: 30px;">
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Full Name*</label>

                                                <input type="text" formControlName="fullname" name="fullname"
                                                    id="fullname" class="form-control" aria-describedby="emailHelp"
                                                    placeholder="Full Name"
                                                    [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }"
                                                    [(ngModel)]="businessDetail.fullname">
                                                <div *ngIf="submitted && f.fullname.errors" class="invalid-feedback">
                                                    <div *ngIf="f.fullname.errors.required">Please enter full name</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Date of Birth</label>

                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <input type="text" formControlName="date"
                                                            (change)="datechange(bussinessForm.value)" name="date"
                                                            id="date" class="form-control" placeholder="DD"
                                                            [ngClass]="{ 'is-invalid': submitted && f.date.errors }"
                                                            [(ngModel)]="businessDetail.date">

                                                    </div>
                                                    <div class="col-sm-3">
                                                        <input type="text" formControlName="month"
                                                            (change)="datechange(bussinessForm.value)" name="month"
                                                            id="month" class="form-control" placeholder="MM"
                                                            [ngClass]="{ 'is-invalid': submitted && f.month.errors }"
                                                            [(ngModel)]="businessDetail.month">

                                                    </div>
                                                    <div class="col-sm-6">
                                                        <input type="text" formControlName="year"
                                                            (change)="datechange(bussinessForm.value)" name="year"
                                                            id="year" class="form-control" placeholder="YYYY"
                                                            [ngClass]="{ 'is-invalid': submitted && f.year.errors }"
                                                            [(ngModel)]="businessDetail.year">
                                                    </div>

                                                </div>


                                                <div *ngIf="dateerror" class="invalid-feedback">
                                                    <div>Please enter correct date</div>
                                                </div>

                                                <!-- <div *ngIf="f.date.errors.pattern || f.month.errors.pattern || f.year.errors.pattern">Enter valid date.</div> -->



                                                <!-- <mat-form-field class="datecontrol" [ngClass]="{ 'is-invalid': submitted && f.DateOfBirth.errors }">
                                              <input matInput [matDatepicker]="picker1" name="DateOfBirth" id="DateOfBirth" formControlName="DateOfBirth" class="form-control"  placeholder="Date Of Birth"  [(ngModel)]="businessDetail.DateOfBirth">
                                              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                              <mat-datepicker #picker1></mat-datepicker>
                                          </mat-form-field>       -->



                                            </div>
                                        </div>

                                        <div class="col-sm-4">
                                            <div class="form-group ddd">
                                                <label for="exampleInputEmail1">Country or Nationality</label>

                                                <mat-form-field class="example-full-width">
                                                    <input name="Country" id="Country" matInput placeholder="Country"
                                                        class="suggestte" [matAutocomplete]="auto"
                                                        [formControl]="stateCtrl">
                                                    <span class="lnr lnr-chevron-down alignarrow"></span>
                                                    <mat-autocomplete #auto="matAutocomplete"
                                                        (optionSelected)='getPosts($event.option.value)'>
                                                        <ng-container *ngFor="let state of filteredStates | async">
                                                            <mat-option [value]="state.iso"
                                                                *ngIf="!iscountrySelected(state) && state.iso !=''">
                                                                <span>{{ state.countryname }}</span>
                                                            </mat-option>
                                                        </ng-container>
                                                    </mat-autocomplete>
                                                </mat-form-field>



                                                <div class="autocomplete">
                                                    <!-- <div class="chips-input-container">
                                                
                                                <span class="lnr lnr-chevron-down"></span>
                                            </div>     -->
                                                    <div class="userflex">
                                                        <div class="user-chip" *ngFor="let user of countryuserSelects">
                                                            {{user.countryname}}
                                                            <span (click)="deletecountrySelects(user)"
                                                                class="delete-icon-chip">&#10006;</span>
                                                        </div>

                                                    </div>

                                                    <div class="autocomplete-items" *ngIf="countryshow">
                                                        <ng-container *ngFor="let s of countrylist">
                                                            <div *ngIf="!iscountrySelected(s) && s.iso !=''"
                                                                [ngClass]="iscountrySelected(s) ? 'selected-suggestion' : ''"
                                                                (click)="selectcountrySuggestion(s)">{{ s.countryname }}
                                                            </div>
                                                        </ng-container>
                                                    </div>


                                                    <div *ngIf="countryerror" class="invalid-feedback">
                                                        <div>Please select atleast one country</div>
                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">

                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Matching Threshold Percentage</label>

                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <mat-slider min="50" formControlName="Threshold"
                                                            name="Threshold" id="Threshold" max="100" step="1"
                                                            value="50" [(ngModel)]="Threshold"
                                                            style="width: 100%;"></mat-slider>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <input type="text" formControlName="Threshold" name="Threshold"
                                                            readonly id="Threshold" class="form-control" placeholder=""
                                                            [ngClass]="{ 'is-invalid': submitted && f.Threshold.errors }"
                                                            [(ngModel)]="Threshold" value="50">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-2" [style.display]="isbirth ? 'block' : 'none'">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Birth Year +/- Range</label>
                                                <select class="form-control" formControlName="dobMatching"
                                                    [ngClass]="{ 'is-invalid': submitted && f.dobMatching.errors }"
                                                    [(ngModel)]="businessDetail.dobMatching" name="dobMatching"
                                                    id="dobMatching" placeholder="Select Country">
                                                    <option value="sameYear" selected>Same Year</option>
                                                    <option value="exact">Exact Match</option>
                                                    <option value="withinOneYear">Within 1 Year</option>
                                                    <option value="withinTwoYears">Within 2 Year</option>
                                                    <option value="withinThreeYears">Within 3 Year</option>
                                                    <option value="withinFourYears">Within 4 Year</option>
                                                    <option value="withinFiveYears">Within 5 Year</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-sm-2">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Gender</label>
                                                <select class="form-control" formControlName="gender"
                                                    [ngClass]="{ 'is-invalid': submitted && f.gender.errors }"
                                                    [(ngModel)]="businessDetail.gender" name="gender" id="gender"
                                                    placeholder="Select Country">
                                                    <option value="" selected>Any</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-sm-3" *ngIf="ispep">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">PEP Status</label>


                                                <div class="autocompletew">
                                                    <div class="chips-input-container">
                                                        <input name="suggestion" type="text" placeholder="Any"
                                                            (click)="suggest()" class="form-control suggestte"
                                                            readonly />
                                                        <span class="lnr lnr-chevron-down"></span>
                                                    </div>
                                                    <div class="userflex">
                                                        <div class="user-chip" *ngFor="let user of userSelects">
                                                            {{user.name}}
                                                            <span (click)="deleteSelects(user)"
                                                                class="delete-icon-chip">&#10006;</span>
                                                        </div>
                                                    </div>


                                                    <div class="autocomplete-items" *ngIf="show">
                                                        <ng-container *ngFor="let s of suggestions">
                                                            <div *ngIf="!isSelected(s)"
                                                                [ngClass]="isSelected(s) ? 'selected-suggestion' : ''"
                                                                (click)="selectSuggestion(s)">{{ s.name }}</div>
                                                        </ng-container>
                                                    </div>
                                                </div>

                                                <!-- <select class="form-control" formControlName="dobMatching" [ngClass]="{ 'is-invalid': submitted && f.dobMatching.errors }" [(ngModel)]="businessDetail.dobMatching" name="dobMatching" id="dobMatching" placeholder="Select Country">
                                          <option value="PEP">Any</option>
                                          <option value="PEP-FORMER">Former</option>
                                          <option value="PEP-CURRENT">Current</option>
                                          <option value="PEP-LINKED">Linked</option>                                        
                                        </select>                                             -->
                                            </div>
                                        </div>


                                        <div class="col-sm-2" *ngIf="issan">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Sanction Status</label>
                                                <select class="form-control" formControlName="sanctionstatus"
                                                    [ngClass]="{ 'is-invalid': submitted && f.sanctionstatus.errors }"
                                                    [(ngModel)]="businessDetail.sanctionstatus" name="sanctionstatus"
                                                    id="sanctionstatus" placeholder="Select Sanction">
                                                    <option value="SAN" selected>Any</option>
                                                    <option value="SAN-CURRENT">Current</option>
                                                    <option value="SAN-FORMER">Former</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row radio_section" *ngIf="error ==true">
                                        <div class="col-sm-12 text-right invalid-feedback">
                                            <label class="error">Please select at least one dataset</label>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-6 text-left">
                                            <span *ngIf="lesssore">Sorry, You don't have any credits. Please <a
                                                    [routerLink]="['/check-add-credit']">Click Here</a> to add
                                                more.</span>
                                        </div>
                                        <div class="col-sm-6 text-right">
                                            <input type="submit" class="btn com_btn btn-primary"
                                                (click)="onSubmit(bussinessForm.value,'first')" [disabled]="!disableBtn"
                                                *ngIf="!lesssore" name="updateform" value="{{buttoninsert}}" />

                                            <button type="button" class="btn com_btn btn-secondary"
                                                (click)="clearpersonal()">Clear</button>
                                        </div>
                                    </div>




                                    <jw-modal id="pdf-payment-confirmation" class="modal sendrequestcharge"
                                        role="dialog">
                                        <div role="document" class="formgroup">
                                            <div class="modal-content">
                                                <div class="modal-body text-center">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-lg-12 spaceset">
                                                                <div class="check">
                                                                    <span class="lnr lnr-checkmark-circle"></span>
                                                                </div>
                                                                <!-- <p class="textinfoma">Per Click Charge <span class="charge">£5</span> </p> -->
                                                                <!-- <p class="text-center short">Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs</p> -->
                                                                <h4 class="mb-2">You will now be charged. The fee will
                                                                    be deducted from your available balance.</h4>
                                                                <p class="">{{errormsg}}</p>
                                                            </div>
                                                            <div class="text-center m-auto">
                                                                <button type="submit" class="btn com_btn btn-primary"
                                                                    (click)="onSubmit(bussinessForm.value,'payment')"
                                                                    [disabled]="!disableBtn" *ngIf="lesssore == false"
                                                                    name="updateform" value="{{buttoninsert}}">
                                                                    Confirm</button>
                                                                <button type="submit" class="btn com_btn btn-secondary"
                                                                    (click)="closeModal('pdf-payment-confirmation');">
                                                                    Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </jw-modal>

                                </form>
                            </div>



                            <div class="credit_data_show" *ngIf="result==true && searchresult">
                                <div class="job_list_section visa_request_section" 
                                    *ngFor="let personal of searchresult.matches">
                                    <div class="job_detils_date">
                                        <div class="visa_user_pic">
                                            <img src="{{ personal.profileImage }}" class="img width100"
                                                *ngIf="personal.profileImage">
                                            <img src="../../../../../assets/img/user_pic_emp.png" class="img width100"
                                                *ngIf="!personal.profileImage">
                                        </div>
                                    </div>
                                    <div class="job_detils_date width15">
                                        <div class="visa_user_details ">
                                            <h4>{{ personal.name}} </h4>
                                            <p class="phone text-success"> <b>{{ personal.gender}}</b></p>
                                            <p class="ninumber text-primary">Id - {{ personal.qrCode}} </p>
                                            <!-- <span class="badge badge-danger" *ngFor="let cn of personal.countries">{{ cn}}</span> -->
                                        </div>
                                    </div>

                                    <div class="job_detils_date width10">
                                        <p class="job_address">Date of Birth</p>
                                        <p class="job_profile" *ngIf="personal.datesOfBirth.length >0">
                                            <ng-container *ngFor="let dd2 of personal.datesOfBirth">
                                                {{dd2}}
                                            </ng-container>
                                            <!-- {{ personal.datesOfBirth}} -->
                                        </p>

                                        <p class="job_profile " *ngIf="personal.datesOfBirth.length ==0">
                                            NA
                                        </p>

                                    </div>

                                    <div class="job_detils_date width20">
                                        <p class="job_address">Data Set</p>
                                        <!-- {{dataset(personal.datasets)}} -->
                                        <p class="job_profile text-danger multicolorg flexwrap" *ngIf="personal.datasets.length > 0">
                                            <span class="mb-2" *ngFor="let dd of personal.datasets">
                                                {{dd}}
                                            </span>
                                        </p>
                                        <p class="job_profile text-danger multicolorg flexwrap" *ngIf="personal.datasetlist.length > 0 && personal.datasets.length == 0">
                                            <span class="mb-2" *ngFor="let dd of personal.datasetlist">
                                                {{dd}}
                                            </span>
                                        </p>
                                        <!-- <p class="job_profile" *ngIf="personal.dataset.length > 0">
                                            <ng-container *ngFor="let dd of personal.dataset">
                                                {{dd.name}}
                                            </ng-container>
                                        </p> -->


                                        <p class="job_profile " *ngIf="personal.datasetlist.length ==0 && personal.datasets.length ==0">
                                            NA
                                        </p>

                                    </div>
                                    <!-- <div class="job_detils_date text-center">
                                    <p class="job_address">PEP Level</p>
                                    <span class="badge badge-success">{{personal.person.pepLevel}}</span>
                                </div> -->
                                   
                                    <div class="job_detils_date width30">
                                        <p class="job_address">Nationality</p>
                                        <p class="job_profile text-danger multicolorg flexwrap"
                                            *ngIf="personal.countrylist.length >0">
                                            <span class="mb-2"
                                                *ngFor="let cn of  personal.countrylist">{{cn.countryname}}</span>
                                        </p>

                                        <p class="job_profile " *ngIf="personal.countrylist.length ==0">
                                            NA
                                        </p>
                                    </div>

                                    <div class="job_detils_date aligncenter width7" *ngIf="personal.score">

                                        <div id="container_new" class="green_text">
                                            <div class="dot"></div>
                                            <div class="pulse"></div>
                                        </div>

                                        <p class="job_profile green_text">{{ personal.score}} </p>


                                    </div>
                                    <div class="job_detils_date width7">

                                        <p class="job_address">
                                            <button type="submit" class="btn btn-primary com_btn"
                                                (click)="CheckresourceId(personal,'payment')"><i
                                                    class="fa fa-file-image-o"></i></button>
                                            <!-- <button type="submit" class="btn btn-danger com_btn" ><i class="fa fa-pencil"></i></button>
                                      <button type="submit" class="btn btn-secondary com_btn"><i class="fa fa-trash-o"></i></button> -->
                                        </p>
                                    </div>



                                </div>

                            </div>


                        </div>
                    </div>
                    <div class="overflow_box_show" *ngIf="searchrecord">
                        <div class="rels">
                            <div class="loadercenterimg">
                                    <img src="../../../../../assets/img/loader.gif" /> 
                            </div>

                            <h2 class="codee" data-text="We are searching 5000+ databases to get the best result for you.">We are searching 5000+ databases to get the best result for you.</h2>
                        </div>
                  </div> 


                </div>
            </div>
        </div>
    </div>

    <jw-modal id="personal-pdf-viewer-visa" class="modal" role="dialog">
        <div role="document" class="formgroup" *ngIf="bussinessdetails">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ bussinessdetails.person.forename}} {{ bussinessdetails.person.middlename}} {{
                        bussinessdetails.person.surname}}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                        (click)="closeModal('personal-pdf-viewer-visa');">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container">

                        <div class="row">
                            <div class="col-lg-2">

                                <img src="{{ bussinessdetails.person.imageURL }}" class="img width100"
                                    *ngIf="bussinessdetails.person.imageURL != ''">
                                <img src="../../../../../assets/img/user_pic_emp.png" class="img width100"
                                    *ngIf="bussinessdetails.person.imageURL == ''">

                            </div>
                            <div class="col-lg-10  d-flex">
                                <div
                                    class="category justify-content-between align-items-center text-center d-flex width100">
                                    <div class="catog_box">
                                        <p>PEP</p>
                                        <span>34</span>
                                    </div>
                                    <div class="catog_box">
                                        <p>Law Enforcement</p>
                                        <span>1</span>
                                    </div>
                                    <div class="catog_box">
                                        <p>Adverse Media</p>
                                        <span>11</span>
                                    </div>
                                    <div class="catog_box">
                                        <p>IDV</p>
                                        <span>9</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr />
                        <div class="all_detials">
                            <div class="row">

                                <div class="col-lg-12  d-flex">
                                    <div class="category justify-content-between align-items-center d-flex width100">
                                        <div class="catog_box">
                                            <p>Name</p>
                                            <span> {{ bussinessdetails.person.forename}} {{
                                                bussinessdetails.person.middlename}} {{
                                                bussinessdetails.person.surname}}</span>
                                        </div>
                                        <div class="catog_box">
                                            <p>Alias</p>
                                            <p *ngFor="let companyaliases of bussinessdetails.person.aliases;">
                                                {{companyaliases.forename}} {{companyaliases.middlename}}
                                                {{companyaliases.surname}} </p>
                                        </div>
                                        <div class="catog_box">
                                            <p>Date of Birth</p>
                                            <span>{{bussinessdetails.person.dateOfBirth}}</span>
                                        </div>
                                        <div class="catog_box">
                                            <p>Nationality</p>
                                            <span>{{bussinessdetails.person.nationality.nationality}}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <hr />
                            <div class="row">

                                <div class="col-lg-12  d-flex">
                                    <div class="category justify-content-between align-items-center d-flex width100">
                                        <div class="catog_box width25">
                                            <p>Gender</p>
                                            <span>{{bussinessdetails.gender}}</span>
                                        </div>
                                        <div class="catog_box width50">
                                            <p>Telephone</p>
                                            <span>{{bussinessdetails.person.telephoneNumber}} (Home)
                                                {{bussinessdetails.person.mobileNumber}} (Mob)</span>
                                        </div>
                                        <div class="catog_box width25">
                                            <p>PEP Level</p>
                                            <span>1</span>
                                        </div>


                                    </div>

                                </div>
                            </div>
                            <hr />
                            <div class="row">

                                <div class="col-lg-12  d-flex">
                                    <div class="category justify-content-between align-items-center d-flex width100">


                                        <div class="catog_box width50">
                                            <p>Address(es)</p>
                                            <p *ngFor="let companyaddress of bussinessdetails.person.addresses;">
                                                {{companyaddress.address1}} {{companyaddress.address2}}
                                                {{companyaddress.address3}} {{companyaddress.address4}}, {{
                                                companyaddress.city }} {{ companyaddress.county }} {{
                                                companyaddress.country.name }}- {{ companyaddress.postcode}}
                                            </p>

                                        </div>
                                        <div class="catog_box width50">
                                            <p>Note</p>
                                            <span>
                                                <div *ngFor="let personnotes of bussinessdetails.person.notes;">
                                                    <ul>
                                                        <li>{{ personnotes.datasource.name}}</li>
                                                        <li>{{ personnotes.text}}</li>
                                                    </ul>
                                                </div>

                                            </span>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="col-lg-12  d-flex">
                                    <div class="category justify-content-between align-items-center d-flex width100">


                                        <div class="catog_box">
                                            <p>Political Positions</p>

                                            <div *ngFor="let politic of bussinessdetails.person.politicalPositions;">
                                                {{politic.description}}

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                            <hr />
                            <div class="row">

                                <div class="col-lg-12  d-flex">
                                    <div class="category justify-content-between align-items-center d-flex width100">

                                        <div class="catog_box">
                                            <span>Reference Id</span>
                                        </div>
                                        <div class="catog_box">
                                            <span>{{ bussinessdetails.id}}</span>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <hr />
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary com_btn"> Remove From Review List</button>
                    <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </jw-modal>

    <jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="container text-center">
                        <div class="info_mess">
                            <div class="para">
                                <h4 class="mb-2">No credits available</h4>
                                <p class="mb-0 font20">There are no credits available to perform this action. Please add
                                    more credits.</p>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <input type="button" class="btn btn-primary com_btn " name="updateform" value="Add credits"
                        [routerLink]="['/check-add-credit']" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel"
                        (click)="closeModal('upgrade-plan');" />
                    <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
                </div>
            </div>
        </div>
    </jw-modal>