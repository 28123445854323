import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../services/user.service';
import { ModalService } from '../_services/modal.service';
import { User } from '../models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../services/customer.service';
import { AlertService } from '../services/alert.service';
import { CommonService } from '../services/common.service';
import { PermissionService } from '../services/permission.service';
import { ThrowStmt } from '@angular/compiler';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})

export class SignupComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  isLoginError = false;
  alertService: any;

  user = new User();
  access_token = '';
  userid = '';
  name = '';
  otp = '';
  isline = '';
  error = '';
  phonecheck: any;
  mobilecheck: any;
  username:any;
  emailcheck:any;
  permissionstring: any;
  planid = '';
  isemail = '';
  stage:any;

  type:any;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private modalService: ModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alerts: AlertService,
    private csservices: CustomerService,
    private commonService: CommonService,
    private permissiondetails: PermissionService
  ) { }
  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (userid) {
      this.router.navigate(['/dashboard']);
    }

    this.username = '';
    var type  = this.activatedRoute.snapshot.paramMap.get("id");
    if(type)
    {
      if(type == 'hr-software')
      {
        this.type = 0;
      }
      else if(type == 'compliance-centre')
      {
        this.type = 1;
      }
      else if(type == 'bussiness-credit-score-report')
      {

        this.type = 1;
        // this.stage =1;
        // this.type = 2;
      }
      else if(type == 'automated-reference')
      {
        // this.type = 3;
        this.type = 1;
      }      
            
        this.stage =2;

        if(type == 'bussiness-credit-score-report')
        {
          this.type = 1;
          // this.stage =1;
          // this.type = 2;
        }


    }
    else
    {
      this.stage =1;
    }



    

    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      confirmpassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      mobilenumber: ['', Validators.required],
      companyname: ['', Validators.required],
      username: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      chkTermCndition: [false, Validators.required],
      chkcont: [false, Validators.required]
    } ,{ validator: this.checkPasswords });

    this.permissiondetails.bindallmenu().subscribe((menudata: any) => {
      //console.log(menudata);
      var permissionstring = "[{";
      var perarray = [];
      menudata.forEach(function (valuemenu) {
        perarray.push('"' + valuemenu.clname + '": 3');
      });

      var perstr = perarray.join();
      permissionstring = permissionstring + perstr + "}]";
      this.permissionstring = permissionstring;
    });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  let pass = group.controls.password.value;
  let confirmPass = group.controls.confirmpassword.value;
  return pass === confirmPass ? null : { notSame: true }
}

  get f() { return this.registerForm.controls; }

  newregistation(id){
      this.stage =2;
      this.type = id;    
  }

  newbackregistation()
  {
    this.stage =1;
  }
  
  onUserNameOutEvent(event: any)
  {        
    if(event.target.value == '')
    {
        this.username ='';
    }else
    {
        this.username ='';
        var dbvalue = { 'username': event.target.value}
        this.userService.isUserRegisterd(event.target.value).subscribe((data: any) => {
      // this.userService.isUserRegisterd(event.target.value).subscribe((data2: any) => {

        if (data.sussess == 'false') {
          this.username = 'false';
        }else
        {
          this.username = 'true';
        }
      });
    }
  }

  onEmailOutEvent(event: any)
  {    
    if(event.target.value == '')
    {
        this.emailcheck ='';
    }
    else
    {
        this.emailcheck ='';
        var dbvalue = { 'email': event.target.value}
        this.userService.isEmailRegisterd(event.target.value).subscribe((data: any) => { 
        if (data.sussess == 'false') {
          this.emailcheck = 'false';
        }else
        {
          this.emailcheck = 'true';
        }
      });
    }
  }



  onSubmit() {
    this.submitted = true;  
    if (this.registerForm.invalid) { return; }
    this.user.permissionstring = this.permissionstring;
    if(this.type ==1){
      this.user.planid =1;
      this.user.ctype = 1;    
      this.user.isemail =true;
    }else if(this.type ==2){
      this.user.planid =0;
      this.user.ctype = 2;    
    }else if(this.type ==3)
    {
      this.user.planid =1;
      this.user.ctype = 3;    
    }

    this.userService.userjoinnow(this.user).subscribe((data2: any) => {
      if (data2.sussess == 'true') {
        this.userService.userAuthentication(this.user).subscribe((data: any) => {
          if (data.sussess == 'true') {
            localStorage.setItem('userToken', this.access_token);
            localStorage.setItem('userid', this.userid);
            localStorage.setItem('Name', this.name);
            localStorage.setItem('isline', this.isline);
            localStorage.setItem('planid', this.planid);
            localStorage.setItem('isemail', this.isemail);
            localStorage.setItem('isadmin', data.isadmin);
            localStorage.setItem('ispermission', data.ispermission);
            localStorage.setItem('logoname', data.logourl);
            localStorage.setItem('struser','');
            localStorage.setItem('menu', '0');
            localStorage.setItem('ctype',this.type );
        
            if (data.sussess == 'true') {
              localStorage.setItem('userToken', data.id);
              localStorage.setItem('userid', data.userid);
              localStorage.setItem('emptype', data.emptype);
              localStorage.setItem('isline', data.isline);
              localStorage.setItem('planid', data.planid);
              localStorage.setItem('isemail', data.isemail);
              localStorage.setItem('ismobile', data.ismobile);
              localStorage.setItem('isadmin', data.isadmin);
              localStorage.setItem('ispermission', data.ispermission);
              localStorage.setItem('logoname', data.logourl);
              localStorage.setItem('struser', data.struser);
              localStorage.setItem('menu', '0');
              localStorage.setItem('ctype', data.ctype);
              // console.log(data);

              if (data.isemail == false) {
                localStorage.setItem('otp', data.otp);
              }

              if (data.ismobile == false) {
                localStorage.setItem('otpmobile', data.otpmobile);
              }


              this.csservices.getOverview().subscribe((data6: any) => {
                var lname = '';
                if (data6.lname != null) {
                  lname = data6.lname;
                }
                localStorage.setItem('Name', data6.fname + ' ' + lname);
                this.alerts.success('Welcome to Complygate', true);
                this.router.navigate(['/' + data.url]);
              });
            }
            else {
              this.alerts.error(data.error, true);
            }
          }
        })
      }else
      {
        this.alerts.error(data2.error, true);
      }
    },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
      });
  }




  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }
}