"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./dashboard.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../header/header.component.ngfactory");
var i3 = require("../header/header.component");
var i4 = require("../../services/customer.service");
var i5 = require("@angular/router");
var i6 = require("../../services/user.service");
var i7 = require("../../services/company.service");
var i8 = require("@angular/common");
var i9 = require("./dashboard.component");
var i10 = require("@angular/platform-browser");
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
exports.RenderType_DashboardComponent = RenderType_DashboardComponent;
function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 54, "div", [["class", "bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.CustomerService, i5.Router, i6.UserService, i7.CompanyService, i1.Renderer2, i1.Renderer2], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 51, "div", [["class", "section-wrapper p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 50, "div", [["class", "admin_dashboard_show"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 49, "div", [["class", "ceee"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 48, "div", [["class", "cardmenu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "a", [["class", "card_box_lit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 1), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "samebox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "lnr lnr-user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "menu_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["My Profile"])), (_l()(), i1.ɵeld(15, 0, null, null, 7, "a", [["class", "card_box_lit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(17, 1), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "samebox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "i", [["class", "lnr lnr-pencil"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [["class", "menu_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["My Leave"])), (_l()(), i1.ɵeld(23, 0, null, null, 7, "a", [["class", "card_box_lit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(25, 1), (_l()(), i1.ɵeld(26, 0, null, null, 4, "div", [["class", "samebox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 0, "i", [["class", "lnr lnr-gift"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 2, "div", [["class", "menu_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["My Expense"])), (_l()(), i1.ɵeld(31, 0, null, null, 7, "a", [["class", "card_box_lit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 32).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(32, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(33, 1), (_l()(), i1.ɵeld(34, 0, null, null, 4, "div", [["class", "samebox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 0, "i", [["class", "lnr lnr-calendar-full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 2, "div", [["class", "menu_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["My Timesheet"])), (_l()(), i1.ɵeld(39, 0, null, null, 7, "a", [["class", "card_box_lit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 40).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(40, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(41, 1), (_l()(), i1.ɵeld(42, 0, null, null, 4, "div", [["class", "samebox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(43, 0, null, null, 0, "i", [["class", "lnr lnr-frame-expand"]], null, null, null, null, null)), (_l()(), i1.ɵeld(44, 0, null, null, 2, "div", [["class", "menu_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(45, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["My Asset"])), (_l()(), i1.ɵeld(47, 0, null, null, 7, "a", [["class", "card_box_lit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 48).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(48, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(49, 1), (_l()(), i1.ɵeld(50, 0, null, null, 4, "div", [["class", "samebox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(51, 0, null, null, 0, "i", [["class", "lnr lnr-laptop-phone"]], null, null, null, null, null)), (_l()(), i1.ɵeld(52, 0, null, null, 2, "div", [["class", "menu_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(53, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Rota"]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_2 = _ck(_v, 9, 0, "/overview"); _ck(_v, 8, 0, currVal_2); var currVal_5 = _ck(_v, 17, 0, "/my-leave"); _ck(_v, 16, 0, currVal_5); var currVal_8 = _ck(_v, 25, 0, "/my-expense"); _ck(_v, 24, 0, currVal_8); var currVal_11 = _ck(_v, 33, 0, "/my-worklist"); _ck(_v, 32, 0, currVal_11); var currVal_14 = _ck(_v, 41, 0, "/my-asset"); _ck(_v, 40, 0, currVal_14); var currVal_17 = _ck(_v, 49, 0, "/my-rota"); _ck(_v, 48, 0, currVal_17); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).target; var currVal_1 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 16).target; var currVal_4 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 24).target; var currVal_7 = i1.ɵnov(_v, 24).href; _ck(_v, 23, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 32).target; var currVal_10 = i1.ɵnov(_v, 32).href; _ck(_v, 31, 0, currVal_9, currVal_10); var currVal_12 = i1.ɵnov(_v, 40).target; var currVal_13 = i1.ɵnov(_v, 40).href; _ck(_v, 39, 0, currVal_12, currVal_13); var currVal_15 = i1.ɵnov(_v, 48).target; var currVal_16 = i1.ɵnov(_v, 48).href; _ck(_v, 47, 0, currVal_15, currVal_16); }); }
exports.View_DashboardComponent_0 = View_DashboardComponent_0;
function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 114688, null, 0, i9.DashboardComponent, [i5.Router, i7.CompanyService, i6.UserService, i10.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DashboardComponent_Host_0 = View_DashboardComponent_Host_0;
var DashboardComponentNgFactory = i1.ɵccf("app-dashboard", i9.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
exports.DashboardComponentNgFactory = DashboardComponentNgFactory;
