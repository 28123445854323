<div class="bg">
  <app-header></app-header>


  <div class="section-wrapper">
      <div class="container">
          <div class="tabs" id="tabs">
              <!-- <a [routerLink]="['/request/request-list']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Request List</a> -->
              <div class="clearfix"></div>
              <div class="tabs__content">

                  <div class="tabs__content__item active-tab">


                   


                    <div class="content" *ngIf="type == 'p'">
                      <div class="row row-sm topheader">                            
                      <div class="col-lg-5 text-right">                        
                      </div>
                      <div class="col-lg-7 text-right">
                        <div class="d-flex justify-content-end align-items-center">
                          <button type="button" class="btn btn-danger com_btn"  (click)="uploadByAdmin('p')">Passport AI Request</button>
                          <button type="button" class="btn btn-primary com_btn"  (click)="uploadByAdmin('v')">Visa AI Request</button>
                          <button type="button" class="btn btn-primary com_btn"  (click)="uploadByAdmin('id')">Document AI Request</button> 
                        </div>                             
                    </div>
                  </div>
                      <div class="row">
                          <div class="col-sm-12 aligncenter mb-3" style="display: block;width: 100%;">
                              <h5 class="hedingcolor" style="width: 80%; float: left;">Passport Verification History</h5>
                              <input type="text" [(ngModel)]="searchText" class="form-control width35" placeholder="Search" style="float: right; width: 18%;">
                          </div>                               
                      </div>

                      <div class="browser-screen-loading-content" *ngIf="!pageOfItems" >
                          <div class="loading-dots dark-gray">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>                                
                              <i></i>
                          </div>
                          <p>Loading</p>
                      </div>
                      <ng-container *ngIf="( datalist | filter: {passportnumber: searchText,passportissue: searchText,passportexpiry: searchText }) as pageOfItems"> 
                          <div class="job_list_section visa_request_section pb-2 pt-2 oddeven" *ngFor="let passport of pageOfItems; let i=index ;">
                             
                              <div class="job_detils_date width18">

                                  <div class="visa_user_details">
                                      <p class="job_address">Name</p>
                                      <h4>{{passport.employee.fname}} {{passport.employee.mname}} {{passport.employee.lname}}</h4>
                                    
                                  </div>
                              </div>
                              <div class="job_detils_date width10">
                                  <p class="job_address">Phone Number</p>
                                  <p class="job_profile"> {{ passport.employee.phonenumber }}</p>
                              </div>
                              <div class="job_detils_date width10">
                                  <p class="job_address">Passport Number</p>
                                  <p class="job_profile">{{passport.passportnumber}}</p>
                              </div>
                             
                              <div class="job_detils_date width10">
                                  <p class="job_address">Issue Date</p>
                                  <p class="job_profile">{{passport.passportissue | date: 'dd MMM yyyy'}}</p>
                              </div>
                              <div class="job_detils_date width10">
                                  <p class="job_address">Expiry Date</p>
                                  <p class="job_profile">{{passport.passportexpiry | date: 'dd MMM yyyy'}}</p>
                              </div>
                              <div class="job_detils_date width10">
                                  <p class="job_address">Check Date</p>
                                  <p class="job_profile">{{passport.createat | date: 'dd MMM yyyy'}}</p>
                              </div>

                              <div class="job_detils_date width10">
                                  <button type="button" class="btn btn-primary com_btn" [innerHtml]="downloaddltext[i]" (click)="downloadPassport(passport.employeepassdeatilid, i)"></button>
                              </div>
                             
                              <div class="job_detils_date aligncenter width15 verify_img">
                                  <div class="fshow">
                                      <div class="visa_user_pic back">
                                          
                                          <a (click)="openModal3('pdf-viewer-idproof',passport.passportdocname)">   <img *ngIf="passport.passportdocname" src="{{passport.passportdocname}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}"></a>
                                          
                                          <img *ngIf="!passport.passportdocname " src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                        
                                      </div>
                                      <div class="tooltip_visa back"><p>Front image</p></div>
                                  </div>
                                  <div class="fshow">
                                  <div class="visa_user_pic front">
                                      
                                      <a (click)="openModal3('pdf-viewer-idproof',passport.passportbackdocname)">   <img *ngIf="passport.passportbackdocname" src="{{passport.passportbackdocname}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}"> </a>
                                      <img *ngIf="!passport.passportbackdocname" src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                  </div>
                                  <div class="tooltip_visa front"><p>Back image</p></div>
                                  </div>
                                
                                 
                                  <div class="fshow">
                                  <div class="visa_user_pic selfie" >
                                      <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                      <a (click)="openModal3('pdf-viewer-idproof',passport.passportprofileimage)">   <img *ngIf="passport.passportprofileimage" src="{{passport.passportprofileimage}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}"> </a>
                                      <img *ngIf="!passport.passportprofileimage" src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                  </div>
                                  <div class="tooltip_visa selfie"><p>Selfie</p></div>
                                  </div>
                                 
                              </div>
                              <div class="width100">

                                <div class="pull-left" *ngIf="passport.isaiverify == '1' || passport.isaiverify == '0' ">    
                                  <button type="button" class="btn btn-primary mr-2  mt-0" style="width: 150px;line-height: 15px;" (click)="approvedoc(passport.employeepassdeatilid,'p')">Approve</button>                                   
                                  <button type="submit" (click)="rejectdoc(passport.employeepassdeatilid,'p')"  style="width: 150px;line-height: 15px;" class="btn btn-danger mt-0 ">Reject </button>                                  
                                </div> 


                                <div class="pull-right mt-1"> 
                                  <p class="badge badge-warning mb-0" *ngIf="passport.isaiverify == '1' || passport.isaiverify == '0'"><i class="fa fa-hourglass-end"></i>Awaiting Authorisation </p>
                                  <p class="badge badge-success mb-0" *ngIf="passport.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                  <p class="badge badge-error mb-0" *ngIf="passport.isaiverify == '3'"><i class="fa fa-close"></i> Rejected by AI </p>
                                </div>

                                
                              </div>
                          </div>
                          <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                              <div class="job_detils_date">
                                  <p class="job_profile">No Record Found</p>
                              </div>
                          </div>
                      </ng-container>
                     
                    
                      <!-- <div class="row">                               
                          <div class="col-lg-12 text-right">
                              <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                          </div>
                      </div> -->
                   </div>






                   <div class="content"  *ngIf="type == 'v'"> 
                   
                    <div class="row row-sm topheader">                            
                      <div class="col-lg-5 text-right">                        
                      </div>
                      <div class="col-lg-7 text-right">
                        <div class="d-flex justify-content-end align-items-center">
                          <button type="button" class="btn btn-primary com_btn"  (click)="uploadByAdmin('p')">Passport AI Request</button>
                          <button type="button" class="btn btn-danger com_btn"  (click)="uploadByAdmin('v')">Visa AI Request</button>
                          <button type="button" class="btn btn-primary com_btn"  (click)="uploadByAdmin('id')">Document AI Request</button> 
                        </div>                             
                    </div>
                  </div>


                    <div class="row">
                        <div class="col-sm-12 aligncenter mb-3" style="display: block;">
                            <h5 class="hedingcolor" style="width: 80%; float: left;">BRP Verification History</h5>
                            <input type="text" [(ngModel)]="searchText" class="form-control width35" placeholder="Search" style="width: 20%; float: right;">
                            </div>                               
                    </div>
                    <div class="browser-screen-loading-content" *ngIf="!pageOfItems" >
                        <div class="loading-dots dark-gray">
                            <i></i>
                            <i></i>
                            <i></i>
                            <i></i>
                            <i></i>
                            <i></i>                                
                            <i></i>
                        </div>
                        <p>Loading</p>
                    </div>
                    <ng-container *ngIf="( datalist | filter: {visanumber: searchText,visastart: searchText,visaexpiry: searchText }) as pageOfItems"> 
                        <div *ngFor="let visas of pageOfItems; let i=index ;">
                            <div class="job_list_section visa_request_section pb-2 pt-2 oddeven mb-0">
                                <!-- <div class="text-left ppsss">
                                    <p class="badge badge-warning" *ngIf="visas.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Pending by AI </p>
                                    <p class="badge badge-success" *ngIf="visas.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                    <p class="badge badge-error" *ngIf="visas.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                                </div> -->
                                <!-- <div class="job_detils_date ">
                                    <div class="visa_user_pic">
                                        <img src="{{employee.imagename}}" class="img width100">
                                        <img *ngIf="visas.employee.imagename" src="{{visas.employee.imagename}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                        <img *ngIf="visas.employee.gender == true && (visas.employee.imagename == null || visas.employee.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                        <img *ngIf="visas.employee.gender == false && (visas.employee.imagename == null || visas.employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                    </div>
                                </div> -->
                                <div class="job_detils_date width18">
                                    <div class="visa_user_details">
                                        <p class="job_address">Name</p>
                                        <h4>{{visas.employee.fname}} {{visas.employee.mname}} {{visas.employee.lname}}</h4>
                                        <!-- <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"></i> {{ visas.employee.emailid }}
                                        </p> -->
                                        <!-- <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i> {{ visas.employee.phonenumber }}</p> -->
                                        <!-- <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i> NI Number {{ visas.employee.ninumber }} </p> -->
                                        <!-- <span *ngIf="visas.employee.nationality" class="badge badge-success">{{ visas.employee.nationality.countryname }} </span> -->
                                    </div>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Phone Number</p>
                                    <p class="job_profile">{{ visas.employee.phonenumber }}</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">BRP Number</p>
                                    <p class="job_profile">{{visas.visanumber}}</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Start Date</p>
                                    <p class="job_profile">{{visas.visastart | date: 'dd MMM yyyy'}}</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">End Date</p>
                                    <p class="job_profile">{{visas.visaexpiry | date: 'dd MMM yyyy'}}</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Check Date</p>
                                    <p class="job_profile">{{visas.createat | date: 'dd MMM yyyy'}}</p>
                                </div>
                                <div class="job_detils_date aligncenter width10">
                                    <button type="button" class="btn btn-primary com_btn" [innerHtml]="downloaddltext[i]" (click)="downloadVisa(visas.employeevisadeatilid,i)"></button>
                                    <!-- <div id="container" class="green_text" *ngIf="visas.iscurrent">
                                        <div class="dot"></div>
                                        <div class="pulse"></div>
  
                                    </div>
                                    <p *ngIf="visas.iscurrent" class="job_profile green_text">Current </p>
                                    <div id="container" class="" *ngIf="!visas.iscurrent">
                                        <div class="dot"></div>
                                        <div class="pulse"></div>
  
                                    </div>
                                    <p *ngIf="!visas.iscurrent" class="job_profile">Expired </p> -->
  
                                </div>
                                
                                <div class="job_detils_date aligncenter width15 verify_img">
                                    <div class="fshow">
                                        <div class="visa_user_pic front">
                                            <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                          <a (click)="openModal3('pdf-viewer-visa',visas.visadocname)">  <img *ngIf="visas.visadocname" src="{{visas.visadocname}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}"></a>
                                            <img *ngIf="!visas.visadocname" src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                          
                                        </div>
                                        <div class="tooltip_visa front"><p>Front image</p></div>
                                    </div>
                                    <div class="fshow">
                                        <div class="visa_user_pic back">
                                            <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                            <a (click)="openModal3('pdf-viewer-idproof',visas.visabackdocname)">   <img *ngIf="visas.visabackdocname" src="{{visas.visabackdocname}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}"></a>

                                            <img *ngIf="!visas.visabackdocname " src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                          
                                        </div>
                                        <div class="tooltip_visa back"><p>Back image</p></div>
                                    </div>
                                    <div class="fshow">
                                        <div class="visa_user_pic selfie">
                                            <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                            <a (click)="openModal3('pdf-viewer-idproof',visas.visaprofileimage)">  <img *ngIf="visas.visaprofileimage" src="{{visas.visaprofileimage}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}"></a>
                                            <img *ngIf="!visas.visaprofileimage " src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                            
                                        </div>
                                        <div class="tooltip_visa selfie"><p>Selfie</p></div>
                                    </div>
                                </div>
                                <div class="width100">  
                                  
                                  <div class="pull-left" *ngIf="visas.isaiverify == '1' || visas.isaiverify == '0' ">    
                                    <button type="button" class="btn btn-primary mr-2  mt-0" style="width: 150px;line-height: 15px;" (click)="approvedoc(visas.employeevisadeatilid,'v')">Approve</button>                                   
                                    <button type="submit" (click)="rejectdoc(visas.employeevisadeatilid,'v')"  style="width: 150px;line-height: 15px;" class="btn btn-danger mt-0 ">Reject </button>                                  
                                  </div> 
  
  
                                  <div class="pull-right mt-1"> 
                                    <p class="badge badge-warning mb-0" *ngIf="visas.isaiverify == '1' || visas.isaiverify == '0'"><i class="fa fa-hourglass-end"></i>Awaiting Authorisation </p>
                                    <p class="badge badge-success mb-0" *ngIf="visas.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                    <p class="badge badge-error mb-0" *ngIf="visas.isaiverify == '3'"><i class="fa fa-close"></i> Rejected by AI </p>
                                  </div>


                                 
    
                                </div> 
                            </div>
                           
                          </div>
                          <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                            <div class="job_detils_date">
                                <p class="job_profile">No Record Found</p>
                            </div>
                        </div>
                    </ng-container> 
                </div>




                      <div class="content"  *ngIf="type == 'id'">
                         
                        <div class="row row-sm topheader">                            
                          <div class="col-lg-5 text-right">                        
                          </div>
                          <div class="col-lg-7 text-right">
                            <div class="d-flex justify-content-end align-items-center">
                              <button type="button" class="btn btn-primary com_btn"  (click)="uploadByAdmin('p')">Passport AI Request</button>
                              <button type="button" class="btn btn-primary com_btn"  (click)="uploadByAdmin('v')">Visa AI Request</button>
                              <button type="button" class="btn btn-danger com_btn"  (click)="uploadByAdmin('id')">Document AI Request</button> 
                            </div>                             
                        </div>
                      </div>


                          <div class="row">
                              <div class="col-sm-12 aligncenter mb-3" style="display: block;">                                 
                                    <h5 class="hedingcolor" style="width: 80%; float: left;">Driving Licence Verification History</h5>
                                  <input type="text" [(ngModel)]="searchText" class="form-control width35" placeholder="Search" style="width: 18%; float: left;">
                              </div>                               
                          </div>
                          <div class="browser-screen-loading-content" *ngIf="!pageOfItems" >
                              <div class="loading-dots dark-gray">
                                  <i></i>
                                  <i></i>
                                  <i></i>
                                  <i></i>
                                  <i></i>
                                  <i></i>                                
                                  <i></i>
                              </div>
                              <p>Loading</p>
                          </div>
                          <ng-container *ngIf="( datalist | filter: {idnumber: searchText,docexpiry: searchText}) as pageOfItems"> 
                              <div class="job_list_section idproof_request_section visa_request_section  pb-2 pt-2 oddeven" *ngFor="let idproofs of pageOfItems; let i=index ;">
                                 
                                  <div class="job_detils_date width18">
                                      <div class="visa_user_details">
                                          <p class="job_address">Name</p>
                                          <h4>{{idproofs.employee.fname}} {{idproofs.employee.mname}} {{idproofs.employee.lname}}</h4>
                                            </div>
                                  </div>
                                  <div class="job_detils_date width10">
                                      <p class="job_address">Phone Number</p>
                                      <p class="job_profile">  {{ idproofs.employee.phonenumber }}</p>
                                  </div>
                                  <div class="job_detils_date width10">
                                      <p class="job_address">ID Proof Number</p>
                                      <p class="job_profile">{{idproofs.idnumber}}</p>
                                  </div>
                                  <div class="job_detils_date width10">
                                      <p class="job_address">ID Type</p>
                                      <p class="job_profile" *ngIf="idproofs.documenttype">{{idproofs.documenttype.documentname}}</p>
                                  </div>
                                  <div class="job_detils_date width10">
                                      <p class="job_address">End Date</p>
                                      <p class="job_profile">{{idproofs.docexpiry | date: 'dd MMM yyyy'}}</p>
                                  </div>
                                  <div class="job_detils_date width10">
                                      <p class="job_address">Check Date</p>
                                      <p class="job_profile">{{idproofs.createat | date: 'dd MMM yyyy'}}</p>
                                  </div>
                                  <div class="job_detils_date width10">
                                      <button type="button" class="btn btn-primary com_btn" [innerHtml]="downloaddltext[i]" (click)="downloadDrivingLicence(idproofs.employeeidproofdeatilid, i)"></button>
                                  </div>
                                  <div class="job_detils_date aligncenter width15 verify_img">
                                    
                                      <div class="fshow">
                                          <div class="visa_user_pic back">
                                              
                                              <a (click)="openModal3('pdf-viewer-idproof',idproofs.iddocName)">  <img *ngIf="idproofs.iddocName" src="{{idproofs.iddocName}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}"></a>
                                              <img *ngIf="!idproofs.iddocName" src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                          </div>
                                          <div class="tooltip_visa back"><p>Front image</p></div>
                                      </div>
                                      <div class="fshow">
                                          <div class="visa_user_pic selfie">
                                             
                                              <a (click)="openModal3('pdf-viewer-idproof',idproofs.idbackdocName)">   <img *ngIf="idproofs.idbackdocName" src="{{idproofs.idbackdocName}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}"></a>
                                              <img *ngIf="!idproofs.idbackdocName" src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                          </div>
                                          <div class="tooltip_visa selfie"><p>Back image</p></div>
                                      </div>
                                      <div class="fshow">
                                          <div class="visa_user_pic selfie">
                                              <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                              <a (click)="openModal3('pdf-viewer-idproof',idproofs.idprofileimage)">  <img *ngIf="idproofs.idprofileimage" src="{{idproofs.idprofileimage}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}"></a>
                                              <img *ngIf="!idproofs.idprofileimage" src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                          </div>
                                          <div class="tooltip_visa selfie"><p>Selfie</p></div>
                                      </div>
                                      </div>
  
                                  <div class="width100">

                                    <div class="pull-left" *ngIf="idproofs.isaiverify == '1' || idproofs.isaiverify == '0' ">    
                                      <button type="button" class="btn btn-primary mr-2  mt-0" style="width: 150px;line-height: 15px;" (click)="approvedoc(idproofs.employeeidproofdeatilid,'id')">Approve</button>                                   
                                      <button type="submit" (click)="rejectdoc(idproofs.employeeidproofdeatilid,'id')"  style="width: 150px;line-height: 15px;" class="btn btn-danger mt-0 ">Reject </button>                                  
                                    </div> 
    
    
                                    <div class="pull-right mt-1"> 
                                      <p class="badge badge-warning mb-0" *ngIf="idproofs.isaiverify == '1' || idproofs.isaiverify == '0'"><i class="fa fa-hourglass-end"></i>Awaiting Authorisation </p>
                                      <p class="badge badge-success mb-0" *ngIf="idproofs.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                      <p class="badge badge-error mb-0" *ngIf="idproofs.isaiverify == '3'"><i class="fa fa-close"></i> Rejected by AI </p>
                                    </div>

                                    

                                  </div>                                
                              </div>
                              <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                  <div class="job_detils_date">
                                      <p class="job_profile">No Record Found</p>
                                  </div>
                              </div>
                          </ng-container> 
                        

                          <!-- <div class="row">
                              <div class="col-lg-12 text-right">
                                  <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                              </div>
                          </div> -->





                      </div>














                  </div>
              </div>
          </div>
      </div>
  </div>
</div>






<jw-modal id="pdf-viewer-idproof" class="modal" role="dialog">
  <div role="document" class="formgroup">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-viewer-idproof');">
        <span aria-hidden="true">&times;</span>
      </button>
          </div>
          <div class="modal-body">
              <div class="container">

                  <div class="row">
                      <div class="col-lg-12">

                          <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="600" frameborder="0"></iframe>
                          <img *ngIf="imgsrc == true" [src]="iframesrc" width="100%" />
                      </div>
                  </div>

              </div>
          </div>

      </div>
  </div>
</jw-modal>


