"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var AdminrequestComponent = /** @class */ (function () {
    function AdminrequestComponent(router) {
        this.router = router;
    }
    AdminrequestComponent.prototype.ngOnInit = function () {
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
    };
    return AdminrequestComponent;
}());
exports.AdminrequestComponent = AdminrequestComponent;
