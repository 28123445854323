<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <a [routerLink]="['/report-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true"
                        class="fa fa-angle-left"></i> Back to Report Dashboard</a>
                <div class="clearfix"></div>
                <div class="tabs__content">
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row row-sm  mg-t-10">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Employee List</h5>
                                </div>
                                <div class="col-lg-6 d-flex justify-content-end mb-3">
                                    <button type="submit" (click)="onexcelt();" class="btn btn-primary com_btn">
                                      Download <i class="ml-2 fa fa-file-excel-o"></i>
                                    </button>
                                    <a (click)="showcondition('list');" class="faright toggle_button"><i class="fa fa-list"></i>
                                      <span  class="tooltip_show">List View</span>
                                    </a>
                                    <a (click)="showcondition('graph');" class="faright toggle_button"><i class="fa fa-bar-chart"></i>
                                      <span  class="tooltip_show">Graph View</span>
                                    </a>
                                  </div>

                                <!-- <div class="col-lg-8">

                                    <a (click)="onexcelt();" class="float-right faright"><i
                                            class="fa fa-file-excel-o"></i></a>
                                    <a (click)="showcondition('list');" class="float-right faright"><i
                                            class="fa fa-list mg-b-10"></i></a>
                                    <a (click)="showcondition('graph');" class="float-right faright"><i
                                            class="fa fa-bar-chart mg-b-10"></i></a>
                                </div> -->
                            </div>
                            <div [style.display]="listdisplay">
                                <form [formGroup]="EmployeeForm" #employeeForm="ngForm" class="s12 white">
                                    <div class="row row-sm topheader">
                                        <div class="col-lg-9">
                                            &nbsp;
                                        </div>
                                        <div class="col-lg-3" style="display:none;">

                                            <select name="jobid" formControlName="jobid"
                                                (onChange)="onSubmit(employeeForm.value)" class="form-control input-lg"
                                                id="jobid">
                                                <option value="">Select Job</option>
                                                <option *ngFor="let ad3 of joblist" value="{{ ad3.jobmasterid}}">{{ad3.jobtittle }}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-3">
                                            <input [(ngModel)]="searchText" formControlName="searctext"
                                                placeholder="Search.." class="form-control">
                                        </div>
                                    </div>
                                </form>
                                <div class="job_list_section idproof_request_section visa_request_section"
                                    *ngFor="let emp of pageOfItems | filter: {fname: searchText,lname: searchText,emailid: searchText,phonenumber: searchText, ninumber: searchText,placeofbirth: searchText };  let i=index">
                                    <div class="job_detils_date width1">
                                        <div class="visa_user_pic">
                                            <img *ngIf="emp.imagename" src="{{emp.imagename}}" class="img width100"
                                                alt="{{ emp.fname }} {{ emp.mname }} {{ emp.lname}}">
                                            <img *ngIf="emp.gender == true && (emp.imagename == null || emp.imagename =='') "
                                                src="../../../../../assets/img/user_pic_emp.png" class="img width100"
                                                alt="{{ emp.fname }} {{ emp.mname }} {{ emp.lname}}">
                                            <img *ngIf="emp.gender == false && (emp.imagename == null || emp.imagename =='')"
                                                src="../../../../../assets/img/fe.png" class="img width100"
                                                alt="{{ emp.fname }} {{ emp.mname }} {{ emp.lname}}">
                                        </div>
                                    </div>
                                    <div class="job_detils_date width20">
                                        <div class="visa_user_details">
                                            <h4> {{emp.fname}} {{emp.mname}} {{emp.lname}}</h4>
                                            <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"></i>
                                                {{emp.emailid}}
                                            </p>
                                            <p class="phone"><i class="fa fa-phone"
                                                    aria-hidden="true"></i>{{emp.phonenumber}}</p>
                                            <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i>
                                                {{emp.ninumber}} </p>
                                        </div>
                                    </div>
                                    <div class="job_detils_date width22">
                                        <p class="job_address">Location</p>
                                        <p class="job_profile" *ngIf="emp.employeedetails">
                                            <span *ngIf="emp.employeedetails.location">
                                            {{emp.employeedetails.location.address1}}
                                          </span>
                                         
                                        </p>
                                        <p class="job_profile" *ngIf="!emp.employeedetails">
                                            <span>N/A</span>
                                        </p>
                                    </div>
                                    <div class="job_detils_date width7">
                                        <p class="job_address">Division</p>
                                        <p class="job_profile" *ngIf="emp.employeedetails"> <span *ngIf="emp.employeedetails.division">
                                            {{emp.employeedetails.division.typename}}
                                          </span></p>
                                          <p class="job_profile" *ngIf="!emp.employeedetails">
                                            <span>N/A</span>
                                        </p>
                                    </div>

                                    <div class="job_detils_date width15">
                                        <p class="job_address">Report To</p>
                                        <p class="job_profile" *ngIf="emp.employeedetails"> <span *ngIf="emp.employeedetails.reportingperson">
                                            {{emp.employeedetails.reportingperson.fname}} {{emp.employeedetails.reportingperson.mname}}
                                            {{emp.employeedetails.reportingperson.lname}}
                                          </span></p>
                                          <p class="job_profile" *ngIf="!emp.employeedetails">
                                            <span>N/A</span>
                                        </p>
                                    </div>
                                    <div class="job_detils_date width7">
                                        <p class="job_address">Department</p>
                                        <p class="job_profile" *ngIf="emp.employeedetails"> <span *ngIf="emp.employeedetails.department">
                                            {{emp.employeedetails.department.typename}}
                                          </span></p>
                                          <p class="job_profile" *ngIf="!emp.employeedetails">
                                            <span>N/A</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="job_list_section visa_request_section" *ngIf="record == 0">
                                    <div class="job_detils_date">
                                        <p class="job_profile">No Record Found</p>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-lg-12 text-right">
                                        <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10"
                                            (changePage)="onChangePage($event)"></jw-pagination>
                                    </div>
                                </div> -->
                            </div>
                            <div [style.display]="graphdisplay">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div id="chartContainer" style="height: 400px; width: 100%;"></div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 pr-2 pl-2"
                                        *ngFor="let emp of departmentdatalist ;  let i=index">
                                        <div class="job_list_section idproof_request_section visa_request_section ">

                                            <div class="job_detils_date width22">
                                                <div class="visa_user_details">
                                                    <p class="job_address">Department</p>
                                                    <span>{{emp.typename}}</span>
                                                </div>
                                            </div>
                                            <div class="job_detils_date width22 text-center">
                                                <p class="job_address">Male</p>
                                                <span class="badge badge-primary">{{emp.mcount}}</span>
                                            </div>
                                            <div class="job_detils_date width22 text-center">
                                                <p class="job_address">Female</p>
                                                <span class="badge badge-danger">{{emp.fcount}}</span>
                                            </div>
                                            <div class="job_detils_date width22 text-center">
                                                <p class="job_address">Employee</p>
                                                <span class="badge badge-info">{{emp.count}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-12 text-right">
                                        <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10"
                                            (changePage)="onChangePage($event)"></jw-pagination>
                                    </div> -->
                                </div>
                                <div class="row mapborderstyle">
                                    <div class="col-lg-12">

                                        <div id="chartContainer2" style="height: 400px; width: 99%;"></div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 pr-2 pl-2" *ngFor="let emp of listage;  let i=index">
                                        <div class="job_list_section idproof_request_section visa_request_section ">

                                            <div class="job_detils_date width22">
                                                <div class="visa_user_details">
                                                    <p class="job_address">Age Group</p>
                                                    <span> {{emp.typename}}</span>
                                                </div>
                                            </div>
                                            <div class="job_detils_date width22 text-center">
                                                <p class="job_address">Male</p>
                                                <span class="badge badge-primary">{{emp.malecount}}</span>
                                            </div>
                                            <div class="job_detils_date width22 text-center">
                                                <p class="job_address">Female</p>
                                                <span class="badge badge-danger">{{emp.femalecount}}</span>
                                            </div>
                                            <div class="job_detils_date width22 text-center">
                                                <p class="job_address">Employee</p>
                                                <span class="badge badge-info">{{emp.totalcount}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-12 text-right">
                                        <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10"
                                            (changePage)="onChangePage($event)"></jw-pagination>
                                    </div> -->
                                </div>
                                <!-- <div class="row mapborderstyle">
        <div class="col-lg-12">  
          <div id="chartContainer5" style="height: 400px; width: 99%;"></div>
        </div>
      </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>