<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <a [routerLink]="['/our-setting']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i>  Back to settings list</a>
                <div class="clearfix"></div>
                <div class="tabs__content">
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="space_em row">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Division List <a (click)="openModal2('custom-division-1')" *ngIf="settingperm > 1" class="mainheading_btn">  + Add New </a></h5>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group">
                                        <input [(ngModel)]="searchText" placeholder="Search.." class="form-control">
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="( data  | filter: {typename: searchText}) as pageOfItems">
                            <div class="job_list_section employee_list_section leave_section width50lef" *ngFor="let division of pageOfItems;  let i=index">
                                <div class="job_detils_date">
                                    <p class="job_address">Division Name</p>
                                    <h5 class="job_profile font_17">{{division.typename}} </h5>
                                </div>
                                <div class="job_detils_date">
                                    <div class="job_address d-flex aligncenter">
                                        <button type="submit" class="btn btn-primary com_btn" *ngIf="settingperm > 2" (click)="openModal('custom-division-1',division)">
                                            <div class="tooltip_visa">
                                                <p >Edit</p>
                                            </div>											
                                            <i class="fa fa-pencil"></i>
                                        </button>
                                        <!-- <button type="submit" class="btn btn-secondary com_btn" (click)="openModal('custom-passport-1',passport)"><i class="fa fa-trash-o"></i></button> -->
                                        <div class="onoffswitch" *ngIf="settingperm > 2">
                                            <label class="onoffswitch-label" for="hour{{i+1}}" class="switch">
                                                <input type="checkbox"  name="onoffswitch" class="onoffswitch-checkbox"  id="hour{{i+1}}" [checked]="division.cstatus" (change)="onChangeStatus($event, division)">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>
                            
                        </ng-container>
                        <div class="job_list_section visa_request_section" *ngIf="record == 0">
                            <div class="job_detils_date">
                                <p class="job_profile">No Record Found</p>
                            </div>
                        </div>
                            <div class="row">
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<jw-modal id="custom-division-1" role="dialog" class="modal modalsmall">
    <form [formGroup]="divisionformGroup" #divisionForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(divisionForm.value,'custom-division-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <!-- <div class="modal-header">
                    <input type="hidden" formControlName="id" class="form-control" name="id" id="id" [(ngModel)]="divisionDetail.id" />
                    <h5 class="modal-title" id="popupLabel">Division Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-division-1');">
              <span aria-hidden="true">&times;</span>
            </button>
                </div> -->
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group width100">
                                <label>Division Name*</label>
                                <input type="text" formControlName="typename" name="typename" id="typename" class="form-control" 
                                [ngClass]="{ 'is-invalid': submitted && f.typename.errors }" maxlength="30" [ngModel]="divisionDetail.typename">
                                <div *ngIf="submitted && f.typename.errors" class="invalid-feedback">
                                    <div *ngIf="f.typename.errors.required">Please enter division name</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-division-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>