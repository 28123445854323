"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var core_1 = require("@angular/core");
var myaddress_service_1 = require("../../../services/myaddress.service");
// import { ModalService } from '../../services/modal.service';
var modal_service_1 = require("../../../_services/modal.service");
var country_service_1 = require("../../../services/country.service");
var common_service_1 = require("../../../services/common.service");
// import { AlertPromise } from 'selenium-webdriver';
//import { Observable } from 'rxjs/Observable';
var router_1 = require("@angular/router");
var address_model_1 = require("../../../models/address/address.model");
var forms_1 = require("@angular/forms");
var alert_service_1 = require("../../../services/alert.service");
var EmployeeaddresslistComponent = /** @class */ (function () {
    function EmployeeaddresslistComponent(formBuilder, data, country, modalService, commonService, http, alerts, activatedRoute) {
        this.formBuilder = formBuilder;
        this.data = data;
        this.country = country;
        this.modalService = modalService;
        this.commonService = commonService;
        this.http = http;
        this.alerts = alerts;
        this.activatedRoute = activatedRoute;
        this.address = new address_model_1.Address();
        this.address2 = 0;
        this.myControl = new forms_1.FormControl();
        this.options = [];
        this.filterItems = [];
        this.currentDate = new Date();
    }
    EmployeeaddresslistComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.empid = this.activatedRoute.snapshot.paramMap.get("id");
        this.Userid = localStorage.getItem('userid');
        this.currentdatevalue = false;
        this.addpopForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            address1: ['', forms_1.Validators.required],
            address2: [''],
            cityname: ['', forms_1.Validators.compose([forms_1.Validators.required,])],
            //  mobilenumber: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{10}")])],
            //  mobilenumber: ['', Validators.required],
            movedin: ['', forms_1.Validators.required],
            movedout: [''],
            pincode: ['', forms_1.Validators.required],
            iscurrent: [''],
            statename: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            // phonenumber: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{10}")])],
            // phonenumber: [''],
            countryid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])]
        });
        var id = localStorage.getItem('userToken');
        var data = { 'id': this.empid, 'cstatus': [1, 2, 3] };
        this.data.getemploymenttListbyadmin(data).subscribe(function (data) {
            // console.log(data);
            _this.addresslist = data;
            _this.addressdata = data;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.listcheck = this.filterItems;
    };
    Object.defineProperty(EmployeeaddresslistComponent.prototype, "f", {
        get: function () { return this.addpopForm.controls; },
        enumerable: true,
        configurable: true
    });
    EmployeeaddresslistComponent.prototype.onSubmit = function (formaddress, id) {
        var _this = this;
        // var formaddress = value3.value;
        this.submitted = true;
        this.movedoutdateerror = "";
        // console.log(this.addpopForm);
        if (formaddress.iscurrent == 0 || formaddress.iscurrent == undefined) {
            formaddress.iscurrent = false;
        }
        if (formaddress.iscurrent == 1) {
            formaddress.iscurrent = true;
        }
        if ((formaddress.iscurrent == false) && (formaddress.movedout == '')) {
            this.movedoutdateerror = "Please select Moved Out Date";
        }
        if (this.addpopForm.invalid || this.movedoutdateerror != "") {
            return;
        }
        var pinc = formaddress.pincode.split(',');
        formaddress.pincode = pinc[pinc.length - 1];
        if ((formaddress.iscurrent == true)) {
            this.data.myaddresschangecurrent(formaddress).subscribe(function (data) {
            });
        }
        this.disableBtn = false;
        formaddress.createby = this.Userid;
        formaddress.updateby = this.Userid;
        var sd = new Date(formaddress.movedin);
        formaddress.movedin = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        if (formaddress.movedout) {
            var ed = new Date(formaddress.movedout);
            formaddress.movedout = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        }
        this.data.myaddressdetailsUpdatebyadmin(formaddress).subscribe(function (data) {
            var data1 = { 'id': _this.empid, 'cstatus': [1, 2, 3] };
            _this.data.getemploymenttListbyadmin(data1).subscribe(function (data) {
                var data2 = { 'id': data.countryid };
                _this.country.getstatelist(data2).subscribe(function (state) {
                    _this.statelist = state;
                });
                _this.addressdata = data;
                _this.addresslist = data;
            });
        });
        this.alerts.success('Address Updated Successfully', true);
        this.modalService.close(id);
        // this.addresslistDetail = new Address();
    };
    EmployeeaddresslistComponent.prototype.number = function (input) {
        var isnumber = input.value > 0;
        return isnumber ? null : true;
    };
    EmployeeaddresslistComponent.prototype.onSelectCountry = function (country_id) {
        var _this = this;
        var data = { 'id': country_id };
        this.country.getstatelist(data).subscribe(function (state) {
            return _this.statelist = state;
        });
    };
    EmployeeaddresslistComponent.prototype.onlistchange = function (type1, idval) {
        var _this = this;
        var sstatus;
        for (var _i = 0, _a = this.filterItems; _i < _a.length; _i++) {
            var c = _a[_i];
            if (c.id == idval)
                c.selected = true;
            else
                c.selected = false;
        }
        if (idval == 0 || type1 == false) {
            sstatus = [1, 2, 3];
        }
        else {
            sstatus = [idval];
        }
        var data = { 'id': this.empid, 'cstatus': sstatus };
        this.data.getemploymenttListbyadmin(data).subscribe(function (data) {
            _this.addresslist = data;
            _this.addressdata = data;
        });
    };
    EmployeeaddresslistComponent.prototype.onSearchChange = function (searchValue) {
        var _this = this;
        if (searchValue.length > 4) {
            var newArray = [];
            this.commonService.getAddress(searchValue).subscribe(function (data) {
                var newdata;
                newdata = data;
                newdata.addresses.forEach(function (value) {
                    newArray.push(value + "," + searchValue);
                });
                _this.options = newArray;
            });
        }
        else {
            this.options = [];
        }
    };
    EmployeeaddresslistComponent.prototype.displayFn = function (value) {
        if (value != null) {
            var res = value.split(",");
            if (res.length > 6) {
                return res[7];
            }
            else {
                return value;
            }
        }
    };
    EmployeeaddresslistComponent.prototype.updatecode = function (evt, response) {
        var _this = this;
        res = [];
        if (evt.source.selected) {
            var res = response.split(",");
            this.addresslistDetail.address1 = res[0];
            this.addresslistDetail.address2 = res[1];
            this.addresslistDetail.cityname = res[5];
            this.addresslistDetail.pincode = res[7];
            var data = { 'id': this.addresslistDetail.countryid };
            this.country.getstatelist(data).subscribe(function (state) {
                for (var i = 0; i < state.length; i++) {
                    if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
                        var inde = state[i]['stateid'];
                    }
                }
                _this.addresslistDetail.stateid = inde;
                _this.statelist = state;
            });
        }
    };
    EmployeeaddresslistComponent.prototype.onDelete = function (empid) {
        var _this = this;
        if (confirm("Are you sure you want to delete?")) {
            var data1 = { 'id': empid };
            this.data.myaddressDelete(data1).subscribe(function (data) {
                var sstatus;
                for (var _i = 0, _a = _this.filterItems; _i < _a.length; _i++) {
                    var c = _a[_i];
                    if (c.selected == true) {
                        if (c.id == 0) {
                            sstatus = [1, 2, 3];
                        }
                        else {
                            sstatus = [c.id];
                        }
                    }
                }
                var data1 = { 'id': _this.empid, 'cstatus': sstatus };
                _this.data.getemploymenttListbyadmin(data1).subscribe(function (data) {
                    return _this.addressdata = data;
                });
            });
        }
    };
    EmployeeaddresslistComponent.prototype.currentaddresschange = function (eve, addressid) {
        if (eve.target.value == 1) {
            this.addresslistDetail.movedout = "";
            this.currentdatevalue = false;
        }
        else {
            this.currentdatevalue = true;
        }
    };
    EmployeeaddresslistComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    EmployeeaddresslistComponent.prototype.openModal = function (id, data) {
        var _this = this;
        this.disableBtn = true;
        if (data.iscurrent == true) {
            this.currentdatevalue = false;
        }
        else {
            this.currentdatevalue = true;
        }
        this.buttoninsert = "Update";
        this.addresslistDetail = data;
        // console.log(this.addresslistDetail);
        if (this.addresslistDetail.iscurrent === true) {
            this.addresslistDetail.iscurrent = '1';
        }
        if (this.addresslistDetail.iscurrent === false) {
            this.addresslistDetail.iscurrent = '0';
        }
        var data2 = { 'id': data.countryid };
        this.country.getstatelist(data2).subscribe(function (state) {
            _this.statelist = state;
        });
        this.modalService.open(id);
    };
    EmployeeaddresslistComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.disableBtn = true;
        // this.addpopForm.invalid == false;
        this.addresslistDetail = new address_model_1.Address();
        this.buttoninsert = "Submit";
        this.addresslistDetail.employeeid = this.empid;
        this.addresslistDetail.employeeaddresdeatilid = "0";
        this.addresslistDetail.countryid = "70";
        this.currentdatevalue = true;
        this.addresslistDetail.iscurrent = '0';
        this.mobilecheck = "";
        this.phonecheck = "";
        // this.addresslistDetail.movedout ='';
        // this.addresslistDetail.address2 ='';
        // this.addresslistDetail.pincode ='';
        // this.addresslistDetail.cityname ='';
        // this.addresslistDetail.stateid ='';
        // this.addresslistDetail.phonenumber ='';
        // this.addresslistDetail.mobilenumber ='';
        this.modalService.open(id);
    };
    EmployeeaddresslistComponent.prototype.closeModal = function (id) {
        var _this = this;
        // this.addresslistDetail = new Address();
        // console.log(this.addresslistDetail);
        var data1 = { 'id': this.empid, 'cstatus': [1, 2, 3] };
        this.data.getemploymenttListbyadmin(data1).subscribe(function (data) {
            var data2 = { 'id': data.countryid };
            _this.country.getstatelist(data2).subscribe(function (state) {
                _this.statelist = state;
            });
            _this.addressdata = data;
            _this.addresslist = data;
        });
        // this.addresslistDetail = new Address();
        this.modalService.close(id);
    };
    EmployeeaddresslistComponent.prototype.modalOpen = function (data) {
        this.addresslistDetail = data;
    };
    return EmployeeaddresslistComponent;
}());
exports.EmployeeaddresslistComponent = EmployeeaddresslistComponent;
