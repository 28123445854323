
<app-header></app-header>
<div class="section-wrapper">
  <div class="container">
      <div class="tabs" id="tabs">
          <div class="tabs__sontent">
            <!--<app-checkbillingshared *ngIf="ctype"></app-checkbillingshared>-->
            <app-invioce-shared  ></app-invioce-shared>

            
              <div class="clearfix"></div>                            
                            <div class="container" *ngIf="wizard1">
                              <div class="overviewsshow check_support upgr_p30 mt-4">
                                <img src="../../../assets/img/righdupgra.png" class="upfixrighimg"/>                                    
                                <img src="../../../assets/img/bottomleftupgra.png" class="bottomleftupgra"/>
                                
                                <div class="row row-sm">                            
                                  <div class="col-lg-6 text-left upgr_flex paddupgra60">
                                    <div class="up_content">
                                      <h2 *ngIf="ctype ==3">Unlimited Automated Reference Check</h2>
                                      <h2 *ngIf="ctype ==2">Unlimited Business Credit Check</h2>

                                      <p *ngIf="ctype ==3">Please pay £500.00 to access Unlimited Reference check.  </p>
                                      <p  *ngIf="ctype ==2">Please pay £300.00 to access Unlimited Company Credit check.</p>

                                      <p style="font-size: 11px;">Cancel anytime after 12 months.</p>
                              
                                      <!-- <div class="cartbillingoption"  *ngIf="ctype ==2" >
                                        <button class="btn btn-success btn-block checoutbutton" (click)="paymentstripe('custom-payment-1')"  *ngIf="stuser == ''"   ><span  >Pay Now</span><p  >£300.00</p></button>
                                        <button class="btn btn-success btn-block checoutbutton" (click)="paymentstripe('custom-payment-2')" *ngIf="stuser != ''"  ><span  >Pay Now</span><p  >£300.00</p></button>
                                      </div>  -->

                                      <div class="cartbillingoption"  *ngIf="ctype ==2" >
                                        <button class="btn btn-success btn-block checoutbutton" style="background-color: gray;border: 0px;text-align: center;justify-content:center;"  > Out of Stock</button>
                                        <!-- <button class="btn btn-success btn-block checoutbutton" (click)="paymentstripe('custom-payment-2')" *ngIf="stuser != ''"  ><span  >Pay Now</span><p  >£300.00</p></button> -->
                                      </div> 
                                      
                                      <div class="cartbillingoption"  *ngIf="ctype ==3">
                                        <button class="btn btn-success btn-block checoutbutton" (click)="paymentstripe('custom-payment-1')"  *ngIf="stuser == ''"   ><span  >Pay Now</span><p  >£500.00</p></button>
                                        <button class="btn btn-success btn-block checoutbutton" (click)="paymentstripe('custom-payment-2')" *ngIf="stuser != ''"  ><span  >Pay Now</span><p  >£500.00</p></button>
                                      </div> 


                                    </div>
                                  
                                  </div>

                                  <div class="col-lg-6 text-center">
                                      <img src="../../../assets/img/upgrade.png" />
                                  </div>

                                  </div>      
                                </div>
                            </div>




                            <div class="container"  *ngIf="wizard2">
                              <div class="overviewsshow check_support upgr_p30 mt-4 main-banner" style="padding: 0px;">
                                  <div class="banner-form thankbgsss">
                            
                            
                            <div class="row">
                              <div class="col-sm-12">
                                  <div class="tankushow" >
                            
                                      <img alt="shape" src="../../../assets/img/thankcheck.png">
                                      <div class="detailstext">
                                          <h4>Payment Successful!</h4>
                                          <p>Your order has been successfully processed. Please return to the checks you have purchased and start using them instantly.</p>
                                          <h1>Thank You!</h1>
                                      </div>
                                  </div>
                            
                            
                                 
                              </div>
                            </div>
                            
                            
                            
                            </div>
                            
                            </div>
                            </div>


                            <div class="container"  *ngIf="wizard3">
                              <div class="overviewsshow check_support upgr_p30 mt-4 main-banner" style="padding: 0px;">
                                  <div class="banner-form thankbgsss">
                            
                            
                            <div class="row">
                              <div class="col-sm-12">
                                  <div class="tankushow" >
                            
                                      <img alt="shape" src="../../../assets/img/thankcheck.png">
                                      <div class="detailstext">
                                          <h4>You are already subscribed with unlimited package!</h4>
                                          <!-- <p>Your order has been successfully processed. Please return to the checks you have purchased and start using them instantly.</p> -->
                                          <!-- <h1>Thank You!</h1> -->
                                      </div>
                                  </div>
                            
                            
                                 
                              </div>
                            </div>
                            
                            
                            
                            </div>
                            
                            </div>
                            </div>



                          </div>

                        </div>      
                      </div>
                  </div>








<jw-modal id="custom-payment-1" role="dialog" class="modal paycut">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <h5 class="modal-title" id="popupLabel">Payment</h5>
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
          (click)="closeModal('custom-payment-1');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <div *ngIf="invalidError" class="errormessa">
        {{ invalidError.message }}
      </div>



      <form [formGroup]="addpopForm" #addresslistForm="ngForm" class="s12 padding453 white"  *ngIf="addresslistDetail">
         
         <div class="row">
           <div class="col-sm-12">
              <h5 class="billing">Billing Information</h5>
              <hr/>
           </div>
           <div class="col-sm-7">
              <div class="row">                
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">Card Holder Name*</label>
                  <input type="text" formControlName="cardname" name="cardname" id="cardname" 
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && g.cardname.errors }" required 
                      [ngModel]="addresslistDetail.cardname" maxlength="60">
                  <div *ngIf="submitted && g.cardname.errors" class="invalid-feedback">
                      <div *ngIf="g.cardname.errors.required">Please enter card holder name</div>
                  </div>
                </div>
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">Address Line 1*</label>
                  <input type="address1" formControlName="address1" name="address1" id="address1" 
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && g.address1.errors }" required 
                      [ngModel]="addresslistDetail.address1" maxlength="60">
                  <div *ngIf="submitted && g.address1.errors" class="invalid-feedback">
                      <div *ngIf="g.address1.errors.required">Please enter address line 1</div>
                  </div>
                </div>
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">Address Line 2 </label>
                  <input type="address2" formControlName="address2" name="address2" id="address2" 
                      class="form-control" [ngModel]="addresslistDetail.address2" maxlength="60">
                </div>
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">City*</label>
                  <input type="text" formControlName="cityname" name="cityname" class="form-control" id="cityname" maxlength="30"
                      [ngClass]="{ 'is-invalid': submitted && g.cityname.errors }" required [ngModel]="addresslistDetail.cityname">
                  <div *ngIf="submitted && g.cityname.errors" class="invalid-feedback">
                      <div *ngIf="g.cityname.errors.required">Please enter city</div>
                  </div>
                </div>
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">County*</label>
                  <input type="text" formControlName="statename" name="statename" id="statename" class="form-control" 
                      [ngClass]="{ 'is-invalid': submitted && g.statename.errors }" required 
                      [ngModel]="addresslistDetail.statename" maxlength="30">
                  <div *ngIf="submitted && g.statename.errors" class="invalid-feedback">
                      <div *ngIf="g.statename.errors.required">Please enter county</div>
                  </div>
              </div>
              <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">Postcode*</label>
                <input type="text" formControlName="pincode" name="pincode" class="form-control" id="pincode" maxlength="10"
                    [ngClass]="{ 'is-invalid': submitted && g.pincode.errors }" required [ngModel]="addresslistDetail.pincode">
                <div *ngIf="submitted && g.pincode.errors" class="invalid-feedback">
                    <div *ngIf="g.pincode.errors.required">Please enter postcode</div>
                </div>
              </div>
              <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">Country*</label>
                <select style="width:100%;" formControlName="countryid" class="form-control input-lg" id="countryid" name="countryid" [ngClass]="{ 'is-invalid': submitted && g.countryid.errors }"  [ngModel]="addresslistDetail.countryid">
                  <option [value]="defaultVal">Select Country</option>
                  <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
              </select>
                <div *ngIf="submitted && g.countryid.errors" class="invalid-feedback">
                    <div *ngIf="g.countryid.errors.required">Please select country</div>
                </div>
            </div>
            <div class="form-group mb-2 col-lg-6 llb">
              <label class="mb-0">Tax ID</label>
              <input type="text" formControlName="taxid" name="taxid" class="form-control" id="taxid" maxlength="10"
                   [ngModel]="addresslistDetail.taxid">            
              </div>  
              </div>
           </div>
           <div class="col-sm-5">
            
             <div class="coupon_box_code">
              <p *ngIf="couponerror" class="couponerror">Coupon Code invalid</p>
              <p *ngIf="coupon_applied" class="couponerror_green">Coupon Applied successfully</p>
               <div class="copon_delps">
                <input type="text" formControlName="coupon" class="form-control norlbordr" placeholder="Enter Coupon code">              
                <button type="button" *ngIf="!coupon_applied" class="btn btn-primary" (click)="checkCoupon(addpopForm.controls.coupon.value)">Apply</button>
                <button type="button" *ngIf="coupon_applied" class="btn btn-danger" (click)="cancelCoupon()">Cancel</button>
              </div>
            
              
            
              <div class="paymentpay_option" *ngIf="ctype ==3">
                <p>Payment Amount</p>
                <p>£500.00</p>
              </div>
              <div class="paymentpay_option" *ngIf="ctype ==2">
                <p>Payment Amount</p>
                <p>£300.00</p>
              </div>

              <div *ngIf="coupon_applied" class="paymentpay_option">
                <p>Coupon Discount  </p>
                <p>£{{discount/100 | number : '1.2-2'}}</p>
              </div>

              <div class="paymentpay_option" *ngIf="coupon_applied == false && ctype==2">
                <p>VAT Amount</p>
                <p >£60.00</p>   
              </div>
              <div class="paymentpay_option" *ngIf="coupon_applied == false  && ctype==3">
                <p>VAT Amount</p>
                <p >£60.00</p>   
              </div>

              <div class="paymentpay_option" *ngIf="coupon_applied == true && ctype==2">
                <p>VAT Amount</p>
                <p>£60.00</p>   
              </div>
              <div class="paymentpay_option" *ngIf="coupon_applied == true && ctype==3">
                <p>VAT Amount</p>
                <p>£100.00</p>   
              </div>

              <div class="paymentpay_option">
                <p>Total Payable Amount  </p>
                <p *ngIf="ctype ==2">£{{(coupon_applied)?((300.00-discount/100)+3 | number : '1.2-2'):360.00}}</p>
                <p *ngIf="ctype ==3">£{{(coupon_applied)?((500.00-discount/100)+3 | number : '1.2-2'):560.00}}</p>
              </div>


          </div>  
            <div class="payment_strip">
              <label>Please enter your credit card or debit card</label>                
              <stripe-card #stripeCard (catch)="onStripeError($event)" [(invalid)]="invalidError"
              (tokenChange)="setStripeToken($event,addresslistForm.value, 'custom-payment-1')"></stripe-card>
            </div>
            <div class="optionss" *ngIf="!editCard">
              <img src="assets/img/paycard.png">     
            </div>
           
           </div>
         </div>


        
        

      <div class="modal-footer">
        <button type="button" class="btn btn-primary com_btn" (click)="stripeCard.createToken(extraData)">Pay Now</button>
        <button type="button" class="btn btn-dark com_btn" (click)="closeModal('custom-payment-1');">Cancel</button>
      </div>


        
    </form>


      
     
    </div>
  </div>
</jw-modal>



<jw-modal id="custom-payment-2" role="dialog" class="modal paycut">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <h5 class="modal-title" id="popupLabel">Payment</h5>
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
          (click)="closeModal('custom-payment-1');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <div *ngIf="invalidError" class="errormessa">
        {{ invalidError.message }}
      </div>



      <form [formGroup]="addpopForm" #addresslistForm="ngForm" class="s12 padding453 white"  >
         
         <div class="row">
           <div class="col-sm-12">
              <h5 class="billing">Billing Information</h5>
              <hr/>
           </div>
         
           <div class="col-sm-12">
            
             <div class="coupon_box_code">
              <p *ngIf="couponerror" class="couponerror">Coupon Code invalid</p>
              <p *ngIf="coupon_applied" class="couponerror_green">Coupon Applied successfully</p>
               <div class="copon_delps">
                <input type="text" formControlName="coupon" class="form-control norlbordr" placeholder="Enter Coupon code">              
                <button type="button" *ngIf="!coupon_applied" class="btn btn-primary" (click)="checkCoupon(addpopForm.controls.coupon.value)">Apply</button>
                <button type="button" *ngIf="coupon_applied" class="btn btn-danger" (click)="cancelCoupon()">Cancel</button>
              </div>
            
              
            
              <div class="paymentpay_option" *ngIf="ctype ==3">
                <p>Payment Amount</p>
                <p>£500.00</p>
              </div>
              <div class="paymentpay_option" *ngIf="ctype ==2">
                <p>Payment Amount</p>
                <p>£300.00</p>
              </div>

              <div *ngIf="coupon_applied" class="paymentpay_option">
                <p>Coupon Discount  </p>
                <p>£{{discount/100 | number : '1.2-2'}}</p>
              </div>

              <div class="paymentpay_option" *ngIf="coupon_applied == false && ctype==2">
                <p>VAT Amount</p>
                <p >£60.00</p>   
              </div>
              <div class="paymentpay_option" *ngIf="coupon_applied == false  && ctype==3">
                <p>VAT Amount</p>
                <p >£60.00</p>   
              </div>

              <div class="paymentpay_option" *ngIf="coupon_applied == true && ctype==2">
                <p>VAT Amount</p>
                <p>£60.00</p>   
              </div>
              <div class="paymentpay_option" *ngIf="coupon_applied == true && ctype==3">
                <p>VAT Amount</p>
                <p>£100.00</p>   
              </div>

              <div class="paymentpay_option">
                <p>Total Payable Amount  </p>
                <p *ngIf="ctype ==2">£{{(coupon_applied)?((300.00-discount/100)+3 | number : '1.2-2'):360.00}}</p>
                <p *ngIf="ctype ==3">£{{(coupon_applied)?((500.00-discount/100)+3 | number : '1.2-2'):560.00}}</p>
              </div>
              
          </div>  
           
         
           </div>
         </div>


        
        

      <div class="modal-footer">
        <button type="button" class="btn btn-primary com_btn" (click)="updatepaymentwithoutuser(addresslistForm.value,extraData)">Pay Now</button>
        <button type="button" class="btn btn-dark com_btn" (click)="closeModal('custom-payment-2');">Cancel</button>
      </div>


        
    </form>


      
     
    </div>
  </div>
</jw-modal>