"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var reference_service_1 = require("../../services/reference.service");
var modal_service_1 = require("../../_services/modal.service");
var ReferenceDetails_model_1 = require("../../models/reference/ReferenceDetails.model");
var forms_1 = require("@angular/forms");
var ngx_toastr_1 = require("ngx-toastr");
var ng2_dragula_1 = require("ng2-dragula");
var rxjs_1 = require("rxjs");
var RefconfigComponent = /** @class */ (function () {
    function RefconfigComponent(modalService, formBuilder, referencingmoduleService, toastrService, dragulaService) {
        var _this = this;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.referencingmoduleService = referencingmoduleService;
        this.toastrService = toastrService;
        this.dragulaService = dragulaService;
        this.submit = false;
        this.subs = new rxjs_1.Subscription();
        this.hourDetail = new ReferenceDetails_model_1.ReferenceDetails();
        this.submitted = false;
        this.options = this.toastrService.toastrConfig;
        this.subs.add(dragulaService.dropModel(this.questionTemplatesdetails)
            .subscribe(function (_a) {
            var el = _a.el, target = _a.target, source = _a.source, sourceModel = _a.sourceModel, targetModel = _a.targetModel, item = _a.item;
            _this.updateseqforquestions(targetModel);
        }));
    }
    RefconfigComponent.prototype.updateseqforquestions = function (targetModel) {
        var _this = this;
        var count = 0;
        for (var i = 0; i < this.previousquestionTemplatesdetails.length; i++) {
            if (targetModel[i].id != this.previousquestionTemplatesdetails[i].id) {
                count += 1;
                var data = {
                    "seq": i + 1
                };
                var updatedata = { seq: i + 1, id: targetModel[i].id, templateid: targetModel[i].templateid };
                this.referencingmoduleService.updatequestionrequests(updatedata).subscribe(function (response1) {
                    //this.questionTemplatesdetails = response1;
                    //this.previousquestionTemplatesdetails = response1;
                    if (count == 2)
                        _this.referencingmoduleService.getquestiontemplatedetails(targetModel[0].templateid).subscribe(function (response1) {
                            _this.questionTemplatesdetails = response1;
                            _this.previousquestionTemplatesdetails = response1;
                        });
                });
                // if(count === 1){
                //   firstquestionid = ;
                //   firstquestionseq = i+1;
                // }else{
                //   secondquestiontid = targetModel[i].id;
                //   secondquestionseq = i+1;
                // }
            }
            var updatedata1 = { seq: i + 1, id: targetModel[i].id, templateid: targetModel[i].templateid };
            this.referencingmoduleService.updatequestionrequests(updatedata1).subscribe(function (response1) {
                _this.questionTemplatesdetails = response1;
                _this.previousquestionTemplatesdetails = response1;
            });
            // if(count === 1){
            //   firstquestionid = ;
            //   firstquestionseq = i+1;
            // }else{
            //   secondquestiontid = targetModel[i].id;
            //   secondquestionseq = i+1;
            // }
            if (count === 2)
                break;
        }
        //this.previousquestionTemplatesdetails = targetModel;
    };
    RefconfigComponent.prototype.ngOnInit = function () {
        this.loadData();
        this.activeprofile = 0;
        this.createTemplateFormGroup = this.formBuilder.group({
            "templatecode": [""],
            "templatename": ["", [forms_1.Validators.required]],
            "questioncategory": ["", [forms_1.Validators.required]],
            "companyid": ''
        });
        this.createquestionforTemplateFormGroup = this.formBuilder.group({
            templateid: '',
            question: ["", [forms_1.Validators.required]],
            questiontype: ["", [forms_1.Validators.required]],
            questionoptions: [""],
            required: ['', forms_1.Validators.required]
        });
        this.createTemplateFormGroup.get("questioncategory").patchValue("Professional");
        this.createquestionforTemplateFormGroup.get("required").patchValue("No");
    };
    Object.defineProperty(RefconfigComponent.prototype, "f", {
        get: function () {
            return this.createTemplateFormGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RefconfigComponent.prototype, "q", {
        get: function () {
            return this.createquestionforTemplateFormGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    RefconfigComponent.prototype.loadData = function () {
        var _this = this;
        this.referencingmoduleService.getquestiontemplates().subscribe(function (response) {
            // console.log(response);
            // console.log(response.length);
            if (response.length > 0) {
                var templateid = (_this.selectedtemplate) ? _this.selectedtemplate : response[0].id;
                _this.questionTemplates = response;
                _this.selectedtemplate = (_this.selectedtemplate) ? _this.selectedtemplate : response[0].id;
                var data = { templateid: _this.questionTemplates[0].id };
                _this.referencingmoduleService.getquestiontemplatedetails(templateid).subscribe(function (response1) {
                    _this.questionTemplatesdetails = response1;
                    _this.previousquestionTemplatesdetails = response1;
                });
            }
        });
    };
    RefconfigComponent.prototype.setValidation = function () {
        // console.log(this.createquestionforTemplateFormGroup.controls.questiontype.value);
        if (this.createquestionforTemplateFormGroup.controls.questiontype.value == 'dropdown') {
            this.createquestionforTemplateFormGroup.get('questionoptions').setValidators([forms_1.Validators.required]);
        }
        else {
            this.createquestionforTemplateFormGroup.get('questionoptions').clearValidators();
        }
        this.createquestionforTemplateFormGroup.get('questionoptions').updateValueAndValidity();
    };
    RefconfigComponent.prototype.getquestionTemplatedetails = function ($event, questionTemplate, i) {
        var _this = this;
        this.activeprofile = i;
        this.selectedtemplate = questionTemplate;
        // var data = { templateid: }
        this.referencingmoduleService.getquestiontemplatedetails(this.selectedtemplate).subscribe(function (response1) {
            _this.questionTemplatesdetails = response1;
            _this.previousquestionTemplatesdetails = response1;
        });
    };
    RefconfigComponent.prototype.createNewTemplate = function (templatedetails, modelid) {
        var _this = this;
        this.submit = true;
        this.submitted = true;
        console.log(templatedetails);
        if (this.createTemplateFormGroup.invalid) {
            return;
        }
        if (!this.isedittemplate) {
            // console.log(this.createTemplateFormGroup.value);
            this.referencingmoduleService.createNewTemplate(this.createTemplateFormGroup.value).subscribe(function (response) {
                _this.loadData();
            });
        }
        else {
            this.referencingmoduleService.updateTemplate({ 'id': this.selectedtemplate.id, data: this.createTemplateFormGroup.value }).subscribe(function (response) {
                _this.loadData();
            });
        }
        this.closeModal(modelid);
        // if (this.createTemplateFormGroup.valid) {
        //   if (!this.isedittemplate) {       
        //     this.referencingmoduleService.createNewTemplate(templatedetails).subscribe((response: any) => {
        //       //console.log(response);            
        //       // let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
        //       // this.openToast('Template for \'' + response.templatename + '\' is successfully created', '', options, ['success'])
        //       this.loadData();
        //       this.createTemplateFormGroup.reset();
        //       this.modalService.close(id);
        //       // this.createTemplateFormGroup.get("questioncategory").patchValue("Professional");
        //     })
        //   } else {
        //     var companyid = JSON.parse(localStorage.getItem("User_Data")).companyid;
        //     this.createTemplateFormGroup.value.companyid = companyid;
        //     this.referencingmoduleService.updateTemplate(templatedetails).subscribe((response: any) => {
        //       //console.log(response);            
        //       let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
        //       this.openToast('Template for \'' + response.templatename + '\' is successfully updated', '', options, ['success'])
        //       this.loadData();
        //       this.createTemplateFormGroup.reset();
        //       this.createTemplateFormGroup.get("questioncategory").patchValue("Professional");
        //     })
        //   }
        // }
    };
    RefconfigComponent.prototype.edittemplate = function (questionTemplate, modelid) {
        this.submitted = false;
        this.disableBtn = true;
        this.buttoninsert = "Update Profile";
        this.selectedtemplate = questionTemplate;
        this.isedittemplate = true;
        this.createTemplateFormGroup.get("templatecode").patchValue(questionTemplate.templatecode);
        this.createTemplateFormGroup.get("templatename").patchValue(questionTemplate.templatename);
        this.createTemplateFormGroup.get("questioncategory").patchValue("Professional");
        this.modalService.open(modelid);
    };
    RefconfigComponent.prototype.addtemplate = function (modelid) {
        this.submitted = false;
        this.disableBtn = true;
        this.buttoninsert = "Add New Profile";
        this.createTemplateFormGroup.reset();
        this.createTemplateFormGroup.get("questioncategory").patchValue("Professional");
        this.isedittemplate = false;
        this.modalService.open(modelid);
    };
    RefconfigComponent.prototype.deletetemplate = function (selectedtemplate) {
        var _this = this;
        //console.log(this.selectedtemplate);
        if (confirm("Are you sure you want to delete?")) {
            this.referencingmoduleService.deleteTemplate({ 'id': selectedtemplate.id, data: { isactive: 'No' } }).subscribe(function (response) {
                //console.log(response);            
                var options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
                _this.openToast('Template successfully deleted', '', options, ['success']);
                _this.loadData();
            });
        }
    };
    RefconfigComponent.prototype.createNewquestion = function (value, modeliid) {
        var _this = this;
        this.submitted = true;
        console.log(this.createquestionforTemplateFormGroup);
        if (this.createquestionforTemplateFormGroup.invalid) {
            return;
        }
        this.createquestionforTemplateFormGroup.value.templateid = this.selectedtemplate;
        if (!this.iseditquestion) {
            this.referencingmoduleService.createquestionrequests(this.createquestionforTemplateFormGroup.value).subscribe(function (response) {
                _this.loadData();
            });
        }
        else {
            this.referencingmoduleService.updatequestionrequests({ 'id': this.selectedquestionTemplatesdetail.id, data: this.createquestionforTemplateFormGroup.value, templateid: this.selectedquestionTemplatesdetail.templateid }).subscribe(function (response) {
                _this.loadData();
            });
        }
        this.closeModal(modeliid);
        // if (!this.iseditquestion) {
        //   this.createquestionforTemplateFormGroup.value.templateid = this.selectedtemplate.id;
        //   //if (this.createTemplateFormGroup.valid) {
        //   this.referencingmoduleService.createquestionrequests(this.createquestionforTemplateFormGroup.value).subscribe((response: any) => {
        //     //console.log(response);            
        //     let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
        //     this.openToast('Question is successfully created', '', options, ['success'])
        //     this.referencingmoduleService.getquestiontemplatedetails(this.selectedtemplate.id).subscribe((response1) => {
        //       this.questionTemplatesdetails = response1;
        //       this.previousquestionTemplatesdetails = response1;
        //       this.createquestionforTemplateFormGroup.reset();
        //       this.createquestionforTemplateFormGroup.get("required").patchValue("No");
        //     })
        //   })
        // } else {
        //   this.createquestionforTemplateFormGroup.value.templateid = this.selectedtemplate.id;
        //   //if (this.createTemplateFormGroup.valid) {
        //   this.referencingmoduleService.updatequestionrequests({'id': this.selectedquestionTemplatesdetail.id, data: this.createquestionforTemplateFormGroup.value, templateid: this.selectedquestionTemplatesdetail.templateid}).subscribe((response: any) => {
        //     //console.log(response);            
        //     let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
        //     this.openToast('Question is successfully updated', '', options, ['success'])
        //     this.referencingmoduleService.getquestiontemplatedetails(this.selectedtemplate.id).subscribe((response1) => {
        //       this.questionTemplatesdetails = response1;
        //       this.previousquestionTemplatesdetails = response1;
        //       //this.createquestionforTemplateFormGroup.reset();
        //       //this.createquestionforTemplateFormGroup.get("required").patchValue("No");
        //     })
        //   })
        // }
        //}
    };
    RefconfigComponent.prototype.editquestion = function (questionTemplatesdetail, up, modelid) {
        this.submitted = false;
        this.selectedquestionTemplatesdetail = questionTemplatesdetail;
        this.iseditquestion = true;
        this.disableBtn = true;
        this.buttoninsert = "Update Question";
        this.createquestionforTemplateFormGroup.get("templateid").patchValue(questionTemplatesdetail.templateid);
        this.createquestionforTemplateFormGroup.get("question").patchValue(questionTemplatesdetail.question);
        this.createquestionforTemplateFormGroup.get("questiontype").patchValue(questionTemplatesdetail.questiontype);
        this.createquestionforTemplateFormGroup.get("questionoptions").patchValue(questionTemplatesdetail.questionoptions);
        this.createquestionforTemplateFormGroup.get("required").patchValue(questionTemplatesdetail.required);
        this.modalService.open(modelid);
    };
    RefconfigComponent.prototype.addquestion = function (modelid) {
        this.submitted = false;
        this.buttoninsert = "Add New Question";
        this.disableBtn = true;
        this.createquestionforTemplateFormGroup.reset();
        this.createquestionforTemplateFormGroup.get("required").patchValue("No");
        this.iseditquestion = false;
        this.modalService.open(modelid);
    };
    RefconfigComponent.prototype.deletequestion = function (questionid) {
        //console.log(this.selectedtemplate);
        var _this = this;
        // var templateid = this.questionTemplatesdetails.find(x => x.id == this.selectedquestionfordeletion).templateid
        if (confirm("Are you sure you want to delete?")) {
            this.referencingmoduleService.deletequestionequests({ id: questionid, data: { isactive: 'No' }, templateid: this.selectedtemplate }).subscribe(function (response) {
                //console.log(response);            
                var options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
                _this.openToast('Question successfully deleted', '', options, ['success']);
                _this.loadData();
                // this.referencingmoduleService.getquestiontemplatedetails(this.selectedtemplate.id).subscribe((response1) => {
                //   this.questionTemplatesdetails = response1;
                //   this.previousquestionTemplatesdetails = response1;
                // })
            });
        }
    };
    RefconfigComponent.prototype.ngOnDestroy = function () {
        this.subs.unsubscribe();
    };
    RefconfigComponent.prototype.onClear = function () {
        this.submit = false;
        this.createTemplateFormGroup.setValue({
            "templatecode": "",
            "templatename": "",
            "questioncategory": "Professional"
        });
    };
    RefconfigComponent.prototype.onClearquestion = function () {
        this.createquestionforTemplateFormGroup.reset();
        this.createquestionforTemplateFormGroup.get("required").patchValue("No");
    };
    RefconfigComponent.prototype.openToast = function (message, title, options, type) {
        this.toastrService[type](message, title, options);
    };
    RefconfigComponent.prototype.selectedquestiontemplate = function (selectedtemplate) {
        this.selectedtemplate = selectedtemplate;
    };
    RefconfigComponent.prototype.deletequestioneventcapture = function (e) {
        this.selectedquestionfordeletion = e.currentTarget.attributes.id.value;
    };
    RefconfigComponent.prototype.openquestiontemplate = function (questiontemp, type, modelid) {
        // console.log(questiontemp.templatecode);
        // console.log(questiontemp.templatename);
        this.disableBtn = true;
        if (type === 'new') {
            this.buttoninsert = "Add New Template";
            // this.hourDetail = new ReferenceDetails();
        }
        else {
            console.log('update');
            this.buttoninsert = "Update Template";
            // this.createTemplateFormGroup.get("templatecode").patchValue(questiontemp.templatecode);
            //  this.createTemplateFormGroup.get("templatename").patchValue(questiontemp.templatename);
            // this.hourDetail.templatecode = questiontemp.templatecode;
            // this.hourDetail.templatename = questiontemp.templatename;
        }
        this.modalService.open(modelid);
    };
    RefconfigComponent.prototype.openquestiontemplatedetails = function (questiontempdetails, type, modelid) {
        this.disableBtn = true;
        if (type === 'new') {
            this.buttoninsert = "Add New Template";
            // this.addquestion();
            // this.hourDetail = new ReferenceDetails();
        }
        else {
            console.log('update');
            this.buttoninsert = "Update Template";
            // this.createTemplateFormGroup.get("templatecode").patchValue(questiontemp.templatecode);
            //  this.createTemplateFormGroup.get("templatename").patchValue(questiontemp.templatename);
            // this.hourDetail.question = questiontempdetails.question;
            // this.hourDetail.required = questiontempdetails.required;
            // this.hourDetail.questiontype = questiontempdetails.questiontype;
            // this.hourDetail.questionoptions = questiontempdetails.questionoptions;
        }
        this.modalService.open(modelid);
    };
    RefconfigComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return RefconfigComponent;
}());
exports.RefconfigComponent = RefconfigComponent;
