<div class="content">
  <div class="row" style="margin-top:10px; margin-bottom: 10px;">
    <div class="col-lg-9">
      <h5><span style="color:#FD342D;">Applicant List </span> </h5>
    </div>
    <div class="col-lg-3">
      <select name="jobid" class="form-control input-lg" id="jobid" (change)="onChange($event.target.value)">
        <option value="">Select Job</option>
        <option *ngFor="let ad3 of joblist" value="{{ ad3.jobmasterid}}">{{ ad3.jobtittle }}</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col-3" *ngFor="let employee of candidatelist;">
      <div class="product-list-box">
        <div class="text-center">
          <a (click)="bindallcandidatedocument('custom-cand', employee.candidatemasterid);">
            <input type="image" class="rounded mr-2" src="../../../../../assets/img/folder.png" />
          </a>
        </div>
        <h5 class="mb-0  text-primary text-center font-14">{{ employee.fname }} {{ employee.mname }} {{ employee.lname
          }}</h5>
        <p class="font-14 text-center">{{ employee.emailid }}</p>
      </div>
    </div>
  </div>
</div>




<jw-modal id="custom-cand" class="modal" role="dialog">

  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="popupLabel">Candidate Document Details</h5>
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-cand');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">

          <div class="row">
            <div class="col-lg-10">
              &nbsp;
            </div>
            <div class="form-group col-lg-2" *ngIf="docperm > 1">
              <div class="bootstrap-filestyle input-group" (click)="file.click()">
                <input type="file" accept="image/*"  #file (change)="selectFile($event)" style="display:none;" />
                <label class="btn btn-secondary ">
                  <span class="buttonText">Choose file</span>
                </label>
              </div>
            </div>
          </div>

          
          <div class="row">
            <div class="form-group col-lg-3 text-center" *ngFor="let document of candidatedocumentlist">
              <img class="rounded mr-2" src="assets/img/file.png" style="width:100%;">
              <p> {{ document.documentname }}</p>
              <p class="iconlist"><i class="fa fa-file-image-o" *ngIf="docperm > 0" (click)="openModalcandidate5('pdf-image-document-viewer', document.documentimageurl)"></i>
                <i  *ngIf="docperm > 2" class="fa fa-trash" (click)="deleteFile(document.candidatedocumentdetailsid)"></i>
                <a  *ngIf="docperm > 0" href="{{ document.documentimageurl }}"><i class="fa fa-download"></i></a></p>
            </div>
          </div>

        </div>
      </div>
      <div class="form-group modal-footer">

        <input type="button" class="btn btn-primary" name="updateform" value="Cancel" (click)="closeModal('custom-cand');" />
      </div>
    </div>
  </div>

</jw-modal>

<jw-modal id="pdf-image-document-viewer" class="modal" role="dialog">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">

          <div class="row">
            <div class="col-lg-12">

              <iframe *ngIf="imgcandidatesrc == false" [src]="iframecandidatesrc" width="100%" height="500" frameborder="0"></iframe>
              <img *ngIf="imgcandidatesrc == true" [src]="iframecandidatesrc" style="width:100%;" />
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</jw-modal>