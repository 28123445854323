import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MypassportService } from '../../../services/mypassport.service';
import { CountryService } from '../../../services/country.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadFileService } from './../../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Passport } from '../../../models/passport/passport.model';
import { ActivatedRoute } from '@angular/router';
import { MyvisaService } from '../../../services/myvisa.service';
import { AlertService } from '../../../services/alert.service';
import { AppGlobals } from '../../../app.global';
import { MyidproofService } from '../../../services/myidproof.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-checkaipassport',
  templateUrl: './checkaipassport.component.html',
  styleUrls: ['./checkaipassport.component.css']
})

export class CheckaipassportComponent implements OnInit 
{
  logo: any;
  wizard:any = 0;
  employeemasterid:any;
  @Input() items: Array<any>;
  form: FormGroup;
  passformGroup: FormGroup;
  passportlist: any[];
  statuslist: any;
  passportDetail: any;
  countrylist: any[];
  Userid: any;
  listcheck: any;
  myfile: any;
  file: any;
  selectedFiles: FileList;
  passnumbererror: any;
  nationalityerror: any;
  passportdateoriginerror: any;
  iframesrc: any;
  iframepassportsrc:any;
  imgpassportsrc:any;
  imgsrc: any;
  buttoninsert: any;
  filesizeerror: any;
  filereuirederror: any;
  fileformaterror: any;
  formattedMessage: any;
  messageSuccess:any;
  filename: any;
  empid: any;
  disableBtn: any;
  defaultVal: any = "";
  imgURL: any;
  public imagePath;
  selected_profile_image: FileList;
  profilepic:any;
  submitted:boolean =false;
  imgbackURL:any;
  pro_pic_size_error: any;
  pro_pic_format_error: any;
  profile_pic_url: any;
  filebacksizeerror: any;
  filebackformaterror: any;
  selectedbackFiles: FileList;
  filebackname: any;
  imagefront: any;  
  imageback:any;
  is_rtw: any = 0;
  filterItems = [
    {
      name: 'All Visa Details',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Waiting for review',
      id: 1,
      selected: false
    }
  ]
  constructor(
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private data: MypassportService,     
    private datePipe: DatePipe,
    private country: CountryService,
    private sanitizer:DomSanitizer,    
    private alerts: AlertService,
    private visaService: MyvisaService, 
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.buttoninsert = "Submit";
    this.disableBtn = true;
    this.activatedRoute.queryParams.subscribe(params => {
      if(params['is_rtw']){
        this.is_rtw = params['is_rtw'];
      }
    });
    this.activatedRoute.params.subscribe(params => {
      this.empid = params['id'];
      this.employeemasterid = params['id'];
    });
    // console.log(this.is_rtw!=0 || this.is_rtw!=1);
    if(this.is_rtw==0 || this.is_rtw==1){
      
    }else{
      this.router.navigate(['/loginqq']);
    }

    this.getCountries();
    this.passportDetail = new Passport();
    this.passformGroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      passportnumber: ['', Validators.required],
      placeofissue: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      passportissue: ['', Validators.required],
      passportexpiry: ['', Validators.required],
      iscurrent: [''],
    });
    
    this.passportDetail.sstatus = 0;
    this.submitted = false;
    this.disableBtn = true;
    this.buttoninsert = "Submit";
    this.passportDetail.nationalityid = "";     
    this.Userid = localStorage.getItem('userid');
    // this.employeemasterid = this.Userid;
    var dd = this.Userid;// localStorage.getItem('userid');
    this.passportDetail.employeeid = this.empid;//this.Userid;
    this.passportDetail.employeepassdeatilid = "0";
    this.filename = "Choose file";
    this.profilepic = "Choose file";    
    this.filebackname = 'Choose file';
  }

  getCountries()
  {
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
      console.log(this.countrylist);
    });
  }
  
  get f() {
    return this.passformGroup.controls;
  }

  toggle(id) {
    if(id ==1){
      this.submitted = true;
      console.log(this.passformGroup);
      if (this.passformGroup.invalid) {
        return;
      }
    }

    this.wizard = id;
  }
  toggle_skip(id)
  {
    this.imgbackURL = null;
    this.wizard =id;
  }

  toggle2(id) {
    // if(id == 1){
    //   this.wizard2 = false;
    //   this.wizard1 = true;
    //   this.wizard3 = false;
    // }
    // if(id == 2){
    //   this.wizard3 = false;
    //   this.wizard1 = false;
    //   this.wizard2 = true;
    // }
  }

  onSubmit(formpassport: any, id: string, stype: string) 
  {
    this.submitted = true;
    if (this.passformGroup.invalid) {
      return;
    }

    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {

        this.fileformaterror = true;
        return;
      }
      var imagepath =this.uploadService.uploadfile(this.file);
      formpassport.imagename = imagepath;
    }
    else {
      formpassport.imagename = this.passportDetail.passportdocname;
    }

    if(formpassport.imagename =='' || formpassport.imagename == null)
    {
      this.filereuirederror =true;
      return;
    }
    
    if (this.selectedbackFiles) {
      this.file = this.selectedbackFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {

        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {

        this.fileformaterror = true;
        return;
      }
      var imagepath2 = this.uploadService.uploadfile(this.file);
      formpassport.imagebackname = imagepath2;
    }
    else {
      formpassport.imagebackname = this.passportDetail.passportdocname;
    }

    if (this.selected_profile_image) {
      this.file = this.selected_profile_image;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filebacksizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.filebackformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      formpassport.passportprofileimage = imagepath;
    }
    else {
      formpassport.passportprofileimage = this.passportDetail.passportprofileimage;
    }

    var error = 0;
    if (formpassport.passportnumber == '') {
      this.passnumbererror = false;
      error++;
    }
    if (formpassport.nationalityid == '') {
      this.nationalityerror = false;
      error++;
    }
    if (formpassport.passportissue == null) {
      this.passportdateoriginerror = false;
      error++;
    }
    if (error > 0) {
      return false;
    }
    
    formpassport.passportissue =this.datePipe.transform(new Date(formpassport.passportissue),"dd MMM yyyy");
    formpassport.passportexpiry =this.datePipe.transform(new Date(formpassport.passportexpiry),"dd MMM yyyy");
    formpassport.createby = this.Userid;
    formpassport.updateby = this.Userid;
    formpassport.isrighttowork = this.is_rtw;
    this.disableBtn = false;
    this.wizard = 6;
    this.data.mypassportdetailsUpdatebyadmin(formpassport).subscribe((datamain: any) => {
      var data3 = { "employeepassdeatilid": datamain.employeepassdeatilid }
      var cid = {id: formpassport.countryid}
      this.country.getcountrylistbyid(cid).subscribe((countrydata: any) => {  
        var countryiso = countrydata.isothree;
        var idtype = 'PASSPORT';     
        var visalog = {
          employeeid: this.employeemasterid,
          documentid: datamain.employeepassdeatilid,
          documenturl: formpassport.imagename,
          documenttype: 'p'
        };
    
        this.visaService.visaailogupdate(visalog).subscribe((dbvisaresult: any) => {     
          if(dbvisaresult.success =='1'){
            var data = { 
              merchantIdScanReference: dbvisaresult.logid, 
              country: countryiso, 
              idType: idtype, 
              customerId: this.employeemasterid, 
              is_rtw: this.is_rtw,
              callbackUrl: AppGlobals.API_ENDPOINT +"/api/aiconfirmation",
              documenturl: formpassport.imagename,
              documentbackurl: formpassport.imagebackname, 
              imageurl: formpassport.passportprofileimage 
            };  
            this.visaService.visadocumentupdate(data).subscribe((data5: any) => {
              if(data5.success == true){
                this.wizard = 5;
              }else {
                this.wizard = 4;
                this.alerts.error("something went wrong please try again");
              }          
            });
            }else {
              this.wizard = 4;
                this.alerts.error("something went wrong please try again");
            }
        });
    
      });
    });
    this.filename = "Choose file";      
  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  selectFile(event)
  {
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = files[0];
    this.file = this.selectedFiles;
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.fileformaterror = true;    
    }else {
      this.fileformaterror = false;
      this.filesizeerror = false;
      var reader = new FileReader();
      this.imagePath = event.target.files;
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.imgURL = reader.result; 
      }
    }
  }

  selectbackFile(event)
  {
    var files = event.target.files;
    this.filebackname = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filebacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filebackformaterror = true;
      this.filebacksizeerror = false;    
    }
    else
    {
      this.filebackformaterror = false;
      this.filebacksizeerror = false;   
      this.selectedbackFiles = files[0]; 
      var reader = new FileReader();
      this.imagePath = event.target.files;
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.imgbackURL = reader.result; 
      }    
    }
  }

  selectprofile(event)
  {
    var files = event.target.files;
    this.profilepic = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.pro_pic_size_error = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.pro_pic_format_error = true;
      this.pro_pic_size_error = false;    
    }
    else
    {
      this.pro_pic_format_error = false;
      this.pro_pic_size_error = false;   
      this.selected_profile_image = files[0]; 
      var reader = new FileReader();
      this.imagePath = event.target.files;
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.profile_pic_url = reader.result; 
      }    
    }
  }

}
