"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var reference_service_1 = require("../../services/reference.service");
var forms_1 = require("@angular/forms");
var ngx_toastr_1 = require("ngx-toastr");
// import { NgxCsvParser,NgxCSVParserError } from 'ngx-csv-parser';
var ngx_papaparse_1 = require("ngx-papaparse");
var checksystem_service_1 = require("../../services/checksystem.service");
var CsvData = /** @class */ (function () {
    function CsvData() {
    }
    return CsvData;
}());
exports.CsvData = CsvData;
var AddbulkreferenceComponent = /** @class */ (function () {
    function AddbulkreferenceComponent(formBuilder, referencingmoduleService, toastrService, papa, check) {
        this.formBuilder = formBuilder;
        this.referencingmoduleService = referencingmoduleService;
        this.toastrService = toastrService;
        this.papa = papa;
        this.check = check;
        // lines = []; //for headings
        // linesR = []; // for rows
        this.totalCheck = 0;
        this.remainingCheck = 0;
        this.totalcredit = 0;
        this.usedCheck = 0;
        this.makeRequest = false;
        this.count = 0;
        this.record = 0;
        this.questionTemplatesNew2 = [];
        this.csvRecordsnew = [];
        this.csvRecordswong = [];
        this.csvRecords = [];
        this.uploadCSV = [];
        this.header = true;
        this.idArray = [];
        this.checkedAll = true;
        this.options = this.toastrService.toastrConfig;
    }
    AddbulkreferenceComponent.prototype.ngOnInit = function () {
        this.remaincheck();
        // this.questiontemplate();
        this.token = localStorage.getItem('User_Token');
        this.filename = 'Choose file';
        var companyid = JSON.parse(localStorage.getItem("User_Data")).companyid;
        // this.referencingmoduleService.getCompniesCreditSummary(companyid).subscribe((response: any) => {
        //   response.listcompanychecks.filter(ele => {
        //     if (ele.moduleid === 34) {
        //       this.totalcredit = ele.totalcreditavailable
        //       this.remainingCheck = ele.totalcreditavailable
        //     }
        //   })
        //   this.referencingmoduleService.listCredits({
        //     companyid: companyid,
        //     moduleid: 34
        //   }).subscribe(response => {
        //     if (response.length > 0) {
        //       response.forEach(ele => {
        //         this.totalCheck += (ele.credit + ele.debit)
        //       })
        //     }
        //     // else{
        //     //   this.totalCheck = this.remainingCheck
        //     // }
        //     if (this.totalCheck > this.remainingCheck) {
        //       this.usedCheck = this.totalCheck - this.remainingCheck
        //     }
        //   })
        // });
    };
    AddbulkreferenceComponent.prototype.remaincheck = function () {
        var _this = this;
        var ts = { 'serviceid': 11 };
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            _this.totalcheck = remaindata.remiancheck;
            if (remaindata.remiancheck <= 0) {
                _this.lesssore = true;
            }
        });
    };
    // questiontemplate()
    // {
    //   this.referencingmoduleService.getquestiontemplates().subscribe((response: any) => {
    //     this.questionTemplates = response;
    //     this.csvRecords.forEach((element, index) => {
    //         this.questionTemplatesNew.push(element.templatename);
    //     });
    //   });
    // }
    AddbulkreferenceComponent.prototype.downladsample = function () {
        // window.open(data.response_data.Location, "_blank");
        // this.referencingmoduleService.downloadfile('bulkreferencerequestsample.csv' ).subscribe((data: any) => {
        //   const blob = new Blob([data], { type: 'application/octet-stream' });
        //   const fileName = 'sample reference request.csv';
        //   FileSaver.saveAs(blob, fileName);
        // })
    };
    AddbulkreferenceComponent.prototype.generateRecord = function () {
        var _this = this;
        if (this.idArray.length) {
            console.log(this.uploadCSV);
            var dataToUpload = [];
            this.csvRecordsnew.forEach(function (element, index) {
                if (_this.idArray.indexOf(index) > -1) {
                    _this.questionTemplates.forEach(function (element2, index) {
                        if (element2.templatename == element.questionprofile) {
                            element.modulequestiontemplateid = element2.id;
                        }
                    });
                    if (element.referencesrequired) {
                        element.numberofreferee = element.referencesrequired;
                    }
                    dataToUpload.push(element);
                }
            });
            console.log(dataToUpload);
            this.check.getbillinginformation({ 'serviceid': 11 }).subscribe(function (remaindata) {
                console.log(remaindata.remiancheck);
                _this.record = 1;
                // if(remaindata.remiancheck >= this.uploadCSV.length){
                _this.referencingmoduleService.createBulkRequests({ data: dataToUpload }).subscribe(function (bulkreqdata) {
                    console.log(bulkreqdata);
                });
                // } else{
                // }
                // this.remainingcheck = remaindata;
                // if(remaindata.remiancheck > 0){
                //     this.modalService.open("confirm-modal");
                // }else{
                //   this.modalService.open("upgrade-plan");
                // }
            });
            // for (let i = 0; i < this.csvRecords.length; i++) {
            //   if (this.remainingCheck > 0) {
            //     this.csvRecords[i]["companyid"] = JSON.parse(localStorage.getItem("User_Data")).companyid;
            //     this.csvRecords[i]["userid"] = JSON.parse(localStorage.getItem("User_Data")).userid;
            //     this.remainingCheck = this.remainingCheck - 1
            //     this.totalCheck = this.totalCheck + 1
            //     this.count = this.count + 1
            //     this.makeRequest = true
            //   } else {
            //     this.makeRequest = false
            //     this.remainingCheck = this.totalcredit
            //   }
            // }
            // if (this.makeRequest) {
            //   this.referencingmoduleService.createbulkreferencerequests(this.csvRecords).subscribe(
            //     data => {
            //       let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
            //       this.openToast('Bulk reference generation request is submitted successfully', '', options, ['success'])
            //     },
            //     error => {
            //       let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
            //       this.openToast(error.error.error.message, '', options, ['error'])
            //     }
            //   );
            // } else {
            //   this.makeRequest = false
            //   let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
            //   if (this.count === 0) {
            //     this.openToast('Credits not available', '', options, ['error'])
            //   } else {
            //     this.openToast(`You can make only ${this.count} request and you are trying to make ${this.csvRecords.length} request's`, '', options, ['error'])
            //   }
            // }
        }
        else {
        }
    };
    AddbulkreferenceComponent.prototype.uploadListener = function (evt) {
        var _this = this;
        this.csvRecordsnew = [];
        this.csvRecordswong = [];
        var questionTemplatesNew = [];
        this.referencingmoduleService.getquestiontemplates().subscribe(function (response) {
            _this.questionTemplates = response;
            _this.questionTemplates.forEach(function (element2, index) {
                questionTemplatesNew.push(element2.templatename);
            });
            // console.log(questionTemplatesNew);
            var files = evt.target.files; // FileList object
            var file = files[0];
            var reader = new FileReader();
            reader.readAsText(file);
            reader.onload = function (event) {
                var csv = event.target.result; // Content of CSV file
                _this.papa.parse(csv, {
                    skipEmptyLines: true,
                    header: true,
                    complete: function (results) {
                        // console.log(results);
                        _this.csvRecords = results.data;
                        _this.csvRecords.forEach(function (element, index) {
                            // console.log(element);
                            // console.log(element.questionprofile);
                            // console.log(questionTemplatesNew);
                            if (element.questionprofile) {
                                // console.log(element.questionprofile);
                                if (questionTemplatesNew.indexOf(element.questionprofile) !== -1
                                    && element.candidatename != ''
                                    && element.candidateemail != ''
                                    && _this.validateEmail(element.candidateemail)
                                    && element.referencesrequired != ''
                                    && element.position != '') {
                                    console.log('yes');
                                    element.isactive = 'Yes';
                                    _this.csvRecordsnew.push(element);
                                }
                                else {
                                    // console.log(this.validateEmail(element.candidateemail));
                                    // console.log(questionTemplatesNew.indexOf(element.questionprofile));
                                    // console.log(element.candidatename);
                                    // console.log(element.candidateemail);
                                    // console.log(element.referencesrequired);
                                    // console.log(element.position);
                                    _this.csvRecordswong.push(element);
                                }
                            }
                            else {
                                _this.csvRecordswong.push(element);
                            }
                        });
                        _this.uploadCSV = _this.csvRecordsnew; // results.data;
                        for (var i = 0; i < results.data.length; i++) {
                            _this.idArray.push(i);
                            //   let orderDetails = {
                            //     order_id: results.data[i].Address,
                            //     age: results.data[i].Age
                            //   };
                            //   this.csvRecords.push(results.data[i]);
                        }
                        // console.log(this.idArray);
                        // console.log('Parsed: k', results.data);
                    }
                });
            };
        });
    };
    // selectall(val){
    //   if(val){
    //     this.uploadCSV = [];
    //   } else{
    //     this.uploadCSV = this.csvRecords;
    //   }
    // }
    AddbulkreferenceComponent.prototype.selectTimesheet = function (val) {
        if (val.checked) {
            this.checkedAll = true;
            this.idArray.push(val.value);
            // this.uploadCSV.push(val.value);
        }
        else {
            this.checkedAll = false;
            this.idArray.splice(val.value, 1);
            // this.uploadCSV.splice(val.value, 1);
        }
    };
    // uploadListener($event: any): void {
    //   const files = $event.srcElement.files;
    //   this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
    //     .pipe().subscribe((result: Array<any>) => {
    //       console.log('Result', result);
    //       this.csvRecords = result;
    //       //this.csvRecords.pop();
    //     }, (error: NgxCSVParserError) => {
    //       console.log('Error', error);
    //     });
    // }
    // parseFile(): void {
    //   const files = this.fileinput.srcElement.files;
    //   // Parse the file you want to select for the operation along with the configuration
    //   this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
    //     .pipe().subscribe((result: Array<any>) => {
    //       console.log('Result', result);
    //       this.csvRecords = result;
    //       //this.csvRecords.pop();
    //     }, (error: NgxCSVParserError) => {
    //       console.log('Error', error);
    //     });
    // }
    AddbulkreferenceComponent.prototype.openToast = function (message, title, options, type) {
        //const opt = JSON.parse(JSON.stringify(this.options));  if we use ngModel as in template
        this.toastrService[type](message, title, options);
    };
    AddbulkreferenceComponent.prototype.validateEmail = function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    return AddbulkreferenceComponent;
}());
exports.AddbulkreferenceComponent = AddbulkreferenceComponent;
