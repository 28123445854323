import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})

export class AmlService {
  constructor(private commonService: CommonService) { }

  /*********Get AML User Name and password ******/
  getAuthenticatedetails(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/aml/findamldetails');   
  }

  updateAuthenticatedetails(value): Observable<any> 
  {
    return this.commonService.formpost('/aml/updatetoken',value);   
  }

  getAuthenticate(data): Observable<any> 
  {
    return this.commonService.formpostForWithoutToken('/authenticate',data);   
  }

  getCountries(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/aml/findcountrylist');   
  }

  getCompanySearch(data): Observable<any> 
  {
    return this.commonService.formpost('/aml/getcompanylist',data);   
  }


  getCompanyNewSearch(data): Observable<any> 
  {
    return this.commonService.formpost('/aml/getcompanynewlist',data);   
  }


  getCompanyNewSearchDetails(data): Observable<any> 
  {
    return this.commonService.formpost('/aml/getcompanynewdetails',data);   
  }


  getPersonalSearch(data): Observable<any> 
  {
    return this.commonService.formpost('/aml/getpersonallist',data);   
  }


  getSearchPersonalSearch(data): Observable<any> 
  {
    return this.commonService.formpost('/aml/getsearchpersonallist',data);   
  }

  getSearchPersonalSearchById(data): Observable<any> 
  {
    return this.commonService.formpost('/aml/getsearchpersonalbyId',data);   
  }



  getCompanyCreditReport(id): Observable<any> 
  {
    return this.commonService.formgetForAML('/companies/'+ id);   
  }
  
  getCompanylogrecord(value): Observable<any> 
  {
    return this.commonService.formpost('/aml/findalldetailsbyrecord',value);   
  }

  generatePdf(value): Observable<any>
  {
    return this.commonService.formpost('/aml/downloadalldetailsbyrecord',value);
  }

  
  generateNewbussinessPdf(value): Observable<any>
  {
    return this.commonService.formpost('/aml/donwload_new_bussiness_report',value);
  }

  loadamllogsystem(value): Observable<any>
  {
    return this.commonService.formpost('/aml/bussinesssearchrecord',value);
  }

  generatenorecordPdf(value): Observable<any>
  {
    return this.commonService.formpost('/aml/downloadalldetailsbynorecord',value);
  }

  generatenorecordv3Pdf(value): Observable<any>
  {
    return this.commonService.formpost('/aml/downloadalldetailsv3norecord',value);
  }

  getCompanySearchCriteria(data): Observable<any> 
  {
    return this.commonService.formpostForAML('/companies/searchcriteria',data);   
  }

  getCompanyReportJSON(data): Observable<any> 
  {
    return this.commonService.formpostForAML('/companies/schema/{countryCode}',data);   
  }

  getCompanyComplianceSearchCriteria(data): Observable<any> 
  {
    return this.commonService.formpostForAML('/compliancetemp/companies/searchCriteria',data);   
  }

  getListofCompanyPreDefinedSearches(): Observable<any> 
  {
    return this.commonService.formgetForAML('/compliancetemp/companies/predefinedSearches');   
  }

  getCompanyComplianceSearch(data): Observable<any> 
  {
    return this.commonService.formgetForAMLParam('/compliancetemp/companies/c-100-fullFile', data);   
  }

  getIndividualsComplianceSearchCriteria(data): Observable<any> 
  {
    return this.commonService.formgetForAML('/compliancetemp/people/searchCriteria');   
  }

  getListofIndividualsPreDefinedSearches(data): Observable<any> 
  {
    return this.commonService.formgetForAML('/compliancetemp/people/predefinedSearches');   
  }

  getIndividualPersonComplianceSearch(data): Observable<any> 
  {
    return this.commonService.formgetForAML('/compliancetemp/people/{predefinedSearch}');   
  }

  getBankMatch(data): Observable<any> 
  {
    return this.commonService.formgetForAML('/localSolutions/GB/bankmatch');   
  }

  getListDecisionTrees(data): Observable<any> 
  {
    return this.commonService.formgetForAML('/decisionEngine/GUID');   
  }

  getRunDecisionTree(data): Observable<any> 
  {
    return this.commonService.formpostForAML('/decisionEngine/{provenirId}',data);   
  }




  getAmlCompanyDetails(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/amlmonitoring/getallcompany');   
  }


  postActiveAmlDetails(data): Observable<any> 
  {
    return this.commonService.formpost('/amlmonitoring/activemonitoring',data);   
  }
  postDeactiveAmlDetails(data): Observable<any> 
  {
    return this.commonService.formpost('/amlmonitoring/deactivemonitoring',data);   
  }




  postActiveAmllogDetails(data): Observable<any> 
  {
    return this.commonService.formpost('/amlmonitoring/activemonitoringlogid',data);   
  }
  postDeactiveAmllogDetails(data): Observable<any> 
  {
    return this.commonService.formpost('/amlmonitoring/deactivemonitoringlogid',data);   
  }



}