<div *ngIf="passport">
  <h3 class="slim-card-title passportspace">Passport</h3>
  <div class="billed-to">
    <h6 class="tx-gray-800">Passport Number: {{passport.passportnumber}}
    </h6>
    <p><b>Nationality:</b> {{ passport.nationality?.countryname }}</p>
    <p><b>Issue Date: </b>{{passport.passportissue | date: 'dd MMM yyyy'}}</p>
    <p><b>Expiry Date: </b>{{passport.passportexpiry | date: 'dd MMM yyyy'}}</p>
    <p><b>Place of Passport: </b> {{passport.placeofissue}} &nbsp;
      <a class="viewdetails" (click)="openModal3('pdf-image-viewer-2',passport.passportdocname)"
        *ngIf="passport.passportdocname"> <i class="fa fa-file-image-o mg-r-10"></i> &nbsp;</a>
      <!-- <a class="viewdetails" (click)="openModal('custom-passport-1',passport)"> <i class="fa fa-pencil " ></i>&nbsp;</a> -->
      <!-- <i class="fa fa-trash-o mg-l" *ngIf="ps.cstatus != '1'" (click)="onDelete(ps.employeepassdeatilid)"></i> -->
      <!-- <i class="fa fa-pencil-square-o" (click)="openModal('custom-passport-1',passport)"></i></p> -->
  </div>
</div>


<jw-modal id="custom-passport-1" class="modal" role="dialog">
  <form [formGroup]="ReForm" #passportForm="ngForm" class="s12 white"
    (ngSubmit)="onSubmit(passportForm.value,'custom-passport-1')" *ngIf="passportDetail">
    <div role="document" class="formgroup">
      <div class="modal-content">
        <div class="modal-header">
          <input type="hidden" formControlName="id" class="form-control" required name="id" id="id"
            [(ngModel)]="passportDetail.employeepassdeatilid">
          <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid"
            id="employeeid" [(ngModel)]="passportDetail.employeeid">
          <h5 class="modal-title" id="popupLabel">Passport Details</h5>
          <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
            (click)="closeModal('custom-passport-1');">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Passport No.*</label>
                <input type="passportnumber" formControlName="passportnumber" name="passportnumber" id="passportnumber"
                  [ngClass]="{ 'is-invalid': submitted && f.passportnumber.errors }" class="form-control"
                  [(ngModel)]="passportDetail.passportnumber">
                <div *ngIf="submitted && f.passportnumber.errors" class="invalid-feedback">
                  <div *ngIf="f.passportnumber.errors.required">Please enter passprot number</div>
                </div>
              </div>
              <div class="form-group col-lg-6">
                <label>Nationality*</label>
                <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid"
                  class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }"
                  [(ngModel)]="passportDetail.nationalityid">
                  <option [value]="defaultVal">Select Nationality</option>
                  <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                </select>
                <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                  <div *ngIf="f.nationalityid.errors.required">Please select nationality</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Passport Issue Date*</label>
                <mat-form-field class="datecontrol">
                  <input matInput [matDatepicker]="picker1" name="passportissue" id="passportissue"
                    [ngClass]="{ 'is-invalid': submitted && f.passportissue.errors }" formControlName="passportissue"
                    class="form-control" [(ngModel)]="passportDetail.passportissue">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group col-lg-6">
                <label>Passport expiry date*</label>
                <mat-form-field class="datecontrol">
                  <input matInput [matDatepicker]="picker2" id="passportexpiry" name="passportexpiry"
                    [ngClass]="{ 'is-invalid': submitted && f.passportexpiry.errors }" formControlName="passportexpiry"
                    [(ngModel)]="passportDetail.passportexpiry">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Place of Issue of Passport*</label>
                <input type="placeofissue" formControlName="placeofissue" id="placeofissue" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.placeofissue.errors }" name="placeofissue"
                  [(ngModel)]="passportDetail.placeofissue">
                <div *ngIf="submitted && f.placeofissue.errors" class="invalid-feedback">
                  <div *ngIf="f.placeofissue.errors.required">Please enter place of issue</div>
                </div>
              </div>
              <div class="form-group col-lg-6">
                <label>Passport</label>
                <!-- <div class="input-file-container">
                  <input type="file" (change)="selectFile($event)">
                </div> -->
                <div class="input-group mb-3">
                  <!-- <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupFileAddon01">Upload</span>
                  </div> -->
                  <div class="custom-file" (click)="file.click()">
                    <input type="file" #file (change)="selectFile($event)">
                    <label class="custom-file-label">{{filename}}</label>
                  </div>
                  <div class="col-lg-12">
                  </div>
                </div>
                <div class="alert-danger" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                <div class="alert-danger" *ngIf="fileformaterror == true">Please choose a valid file format
                  (jpg, png, tif, gif, pdf, doc)</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <input type="submit" class="btn btn-primary" name="updateform" value="Update" />
          <input type="button" class="btn btn-primary" name="updateform" value="Cancel"
            (click)="closeModal('custom-passport-1');" />
        </div>
      </div>
    </div>
  </form>
</jw-modal>

<jw-modal id="pdf-image-viewer-2" class="modal" role="dialog">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
          (click)="closeModal('pdf-image-viewer-2');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-lg-12  text-center">
              <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="400" frameborder="0"></iframe>
              <img *ngIf="imgsrc == true" [src]="iframesrc" class="imgresponsive" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</jw-modal>