<app-header></app-header>
<div class="section-wrapper">
  <div class="container">
      <div class="tabs" id="tabs">
          <div class="tabs__sontent">
            <!--<app-checkbillingshared *ngIf="ctype"></app-checkbillingshared>-->
            <app-invioce-shared  ></app-invioce-shared>

            
              <div class="clearfix"></div>
              <div class="overviewsshow mt-4">
                <div class=" upgraderequest" *ngIf="ctype == 3"  >To access Complygate Compliance Centre, please upgrade your account. <button   class="btn btn-primary com_btn small_btn ng-star-inserted float-right"  [routerLink]="['/upgrade-your-membership']">Upgrade Your Account</button></div>

                <div class=" upgraderequest" *ngIf="ctype == 2"  >To access Complygate Compliance Centre, please upgrade your account. <button   class="btn btn-primary com_btn small_btn ng-star-inserted float-right"  [routerLink]="['/upgrade-your-membership']">Upgrade Your Account</button></div>
                  <!-- <div class="toast_mess" *ngIf="!showAddCheck && paymentDone">
                      <div class="icon">
                        <i class="fa fa-info" aria-hidden="true"></i>
                      </div>
                      <div >
                        <h4>
                           Information
                          </h4>
                          <p>
                            Your profile is in under review. Please wait for the approval.
                          </p>
                      </div> 
                  </div> -->


                  <!-- <div class="toast_mess" *ngIf="!showAddCheck && !paymentDone">
                    <div class="icon">
                      <i class="fa fa-info" aria-hidden="true"></i>
                    </div>
                    <div class="aligncenter width100">
                      <div>
                        <h4>
                           Alert
                          </h4>
                          <p>
                              Please proceed and make a payment of £2 in order to validate your identity. This £2 will be refunded within 48 hrs.
                          </p>                       
                      </div> 
                      <button class="btn com_btn btn-danger" (click)="paymentstripe('custom-payment-12')">Pay Now</button> 
                    </div>
                   
                  </div> -->


               
                    <app-checkout (getRemainingChecks)="getRemainingChecks()" ></app-checkout>
                    <app-checkpaynow *ngIf="!showAddCheck"></app-checkpaynow>
              </div>
         
          </div>
      </div>
  </div>
</div>



<jw-modal id="custom-payment-12" role="dialog" class="modal">
    <div role="document" class="formgroup">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h5 class="modal-title" id="popupLabel">Payment</h5>
          <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
            (click)="closeModal('custom-payment-1');">
            <span aria-hidden="true">&times;</span>
          </button>
        </div> -->
        <div *ngIf="invalidError" class="errormessa">
          {{ invalidError.message }}
        </div>
  
  
        <div class="col-lg-12 col-md-12" >  
            <label class="text-center mb-2 textinfoqer">Please enter your credit card or debit card</label>
            <div class="boxpayddd checkaddcredit">
                
                <stripe-card #stripeCard (catch)="onStripeError12($event)" [(invalid)]="invalidError"
                (tokenChange)="setStripeToken12($event, 'custom-payment-12')"></stripe-card>
            </div>
  
        </div>
  
        <div class="modal-footer">
          <button type="button" class="btn btn-primary com_btn" (click)="stripeCard.createToken(extraData)">Pay Now</button>
          <button type="button" class="btn btn-dark com_btn" (click)="closeModal('custom-payment-12');">Cancel</button>
        </div>
       
      </div>
    </div>
  </jw-modal>