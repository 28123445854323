import { Component, OnInit } from '@angular/core';
import { checkbiling } from '../../../models/plan.model';
import { ChecksystemService } from '../../../services/checksystem.service';
import { CompanyService } from '../../../services/company.service';
import { StripeToken } from "stripe-angular";
import { StripeScriptTag } from "stripe-angular";
import { AppGlobals } from '../../../app.global';
import { CheckserviceService} from '../../../services/checkservice.service';
import { ModalService } from '../../../_services/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkaddcredit',
  templateUrl: './checkaddcredit.component.html',
  styleUrls: ['./checkaddcredit.component.css']
})
export class CheckaddcreditComponent implements OnInit {
  paynow = false;
  stptoken: any;
  serviceArraycredit: any;
  remainitem = new checkbiling();
  leaves: any; 
  invalidError:any;
  extraData:any;
  
  showAddCheck: any = false;
  ctype: number;
  paymentDone: any = false;
  private publishableKey: string = AppGlobals.API_STRIPE;

  constructor(
    private check: ChecksystemService,
    private checkService: CheckserviceService,
    public StripeScriptTag: StripeScriptTag,
    private companyService: CompanyService,
    private router: Router,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }
    
    var isadmin = localStorage.getItem('isadmin');
    if(isadmin!='true'){
      this.router.navigate(['/permission-denied']);
    }

    this.StripeScriptTag.setPublishableKey(this.publishableKey);
    this.ctype  = parseInt(localStorage.getItem('ctype'));
    this.remainitem = new checkbiling();
    this.getRemainingChecks();
  }

  
  paymentstripe(id){
    this.paynow = false;
    this.modalService.open(id);
  }

  getRemainingChecks()
  {
    this.companyService.getcompanyData().subscribe((data: any) => {


      // console.log('Card System', data);

      // this.showAddCheck = true;
      if(data.stuser)
      {
        this.paymentDone = true;
      }
      if(data.verifiedforcheck==1)
      {
        this.showAddCheck = true;
      }
    });
    
    var ts ={};
    this.check.getAllServices().subscribe((data: any) => { 
      this.serviceArraycredit = data;
      this.leaves = []; //.destroy();// =null;
      this.serviceArraycredit.forEach(element => {
        ts  = { 'serviceid': element.id };
        this.check.getbillinginformation(ts).subscribe((remaindata: any) => {
          
          this.leaves.push({
            orderby: element.orderby,
            id: element.id,            
            servicename: element.servicename,
            totalcheck: remaindata.totalcheck,
            usedcheck: remaindata.usedcheck,
            remiancheck: remaindata.remiancheck         
          });
          this.leaves.sort((a,b)=> {return a.orderby>b.orderby });  
        });
      });
    });
  }

  
  closeModal(id: string) {
    this.modalService.close(id);
  }

  

  setStripeToken12(token: StripeToken, id: any) {
    this.paynow = true;
    this.stptoken = token.id;
    var amunt =parseFloat('2');
    var data = {
      "token": token.id,
      "cmonth": token.card.exp_month,
      "cyear": token.card.exp_year,
      "cardnumber": token.card.last4,
      "tokenid": token.card.id,
      "cardname": token.card.brand,
      "country": token.card.country,
      "cstatus": "1",
      "amount": amunt
    };

    this.checkService.registereduserpayment(data).subscribe((payment3: any) => {
      if(payment3.success == true)
      {
        this.closeModal('custom-payment-1');
        this.getRemainingChecks();
      }
      
    });
  }

  
  onStripeError12(error: Error) {
    console.error('Stripe error', error)
  }

  // }

  // binddata(id){
  //   this.remainitem = new checkbiling();
  //   var ts = { 'serviceid': id };
  //   console.log(ts);

  //   this.check.getbillinginformation(ts).subscribe((remaindata: any) => {      
  //     this.remainitem = remaindata;
  //     // console.log(data);
  //   });  
  // }


}
