import { Component, OnInit, Input, Output,EventEmitter,SimpleChanges  } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MyvisaService } from '../../../services/myvisa.service';
import { CountryService } from '../../../services/country.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Visa } from '../../../models/visa/visa.model';
import { UploadFileService } from './../../../services/upload-file.service';
import { AlertService } from '../../../services/alert.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppGlobals } from '../../../app.global';
import { Router, ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-checkaivisa',
  templateUrl: './checkaivisa.component.html',
  styleUrls: ['./checkaivisa.component.css']
})
export class CheckaivisaComponent implements OnInit {


  logo: any;
  wizard:any = 0;

  @Input() items: Array<any>;
  searchText:any;
  pageOfItems: Array<any>;
  record: any;
  visalist: any[];
  visaDetail: any;
  countrylist: any;
  Userid: any;
  empid: any;
  listcheck: any;
  selectedFiles: FileList;
  iframesrc: any;
  imgsrc: any;
  buttoninsert: any;
  filesizeerror: any;
  fileformaterror: any;
  file: any;
  filename: any;
  profilepic: any;
  pro_pic_size_error: any;
  pro_pic_format_error: any;
  profile_pic_url: any;
  ff:any;
  imgbackURL:any;
  public imagePath;
  imgURL: any;
  token_expired: any = false;
  data: any;
  disableBtn: any;  
  aipayment: boolean =false;  
  filebacksizeerror: any;
  filebackformaterror: any;
  selectedbackFiles: FileList;
  selected_profile_image: FileList;
  filebackname: any;
  submitted: any;
  visapopForm: FormGroup;
  visa: Visa;
  permission: any;
  requestperm: any;
  accesstoken: string;
  employeemasterid:any;
  is_rtw: any = 0;
  filterItems = [
    {
      name: 'All Visa Details',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Waiting for review',
      id: 1,
      selected: false
    }
  ]
  constructor(
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private visaService: MyvisaService, 
    private datePipe: DatePipe,
    private country: CountryService, 
    private alerts: AlertService,
    private sanitizer:DomSanitizer, 
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.buttoninsert = "Submit";
    this.disableBtn = true;

    this.activatedRoute.queryParams.subscribe(params => {
      if(params['is_rtw']){
        this.is_rtw = params['is_rtw'];
      }
    });

    this.activatedRoute.params.subscribe(params => {
      this.empid = params['id'];
      this.employeemasterid = params['id'];
    });

    if(this.is_rtw==0 || this.is_rtw==1){
      
    }else{
      this.router.navigate(['/loginqq']);
    }

    this.getCountries();

    this.visapopForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      visanumber: ['', Validators.required],
      visatype: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      visaexpiry: ['', Validators.required],
      visastart: ['', Validators.required],
      iscurrent: [''],
      stype: ['']
    });

    this.submitted = false;
    this.disableBtn = true;
    this.buttoninsert = "Submit";
    this.visaDetail = new Visa();
    this.visaDetail.nationalityid = "";     
    this.Userid = localStorage.getItem('userid');
    var dd = this.Userid;// localStorage.getItem('userid');
    this.visaDetail.employeeid = this.empid;//this.Userid;
    this.visaDetail.employeevisadeatilid = "0";
    this.filename = "Choose file";
    this.profilepic = "Choose file";    
    
    this.filebackname = 'Choose file';
    
  }

  getCountries()
  {
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
      console.log(this.countrylist);
    });
  }
  
  get f() {
    return this.visapopForm.controls;
  }

  toggle(id) {
    if(id ==1){
      this.submitted = true;
      console.log(this.visapopForm);
      if (this.visapopForm.invalid) {
        return;
      }
    }
    this.wizard = id;
  }
  toggle_skip(id)
  {
    this.imgbackURL = null;
    this.wizard =id;
  }
  toggle2(id) {
    // if(id == 1){
    //   this.wizard2 = false;
    //   this.wizard1 = true;
    //   this.wizard3 = false;
    // }
    // if(id == 2){
    //   this.wizard3 = false;
    //   this.wizard1 = false;
    //   this.wizard2 = true;
    // }
  }

  onSubmit(value3: any, id: string, stype: string) {
    this.submitted = true;
    this.disableBtn = false;
    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
       else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.fileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.imagename = imagepath;
    }
    else {
      value3.imagename = this.visaDetail.visadocname;
    }

    
    if (this.selectedbackFiles) {
      this.file = this.selectedbackFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filebacksizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.filebackformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.imagebackname = imagepath;
    }
    else {
      value3.imagebackname = this.visaDetail.visabackdocname;
    }

    if (this.selected_profile_image) {
      this.file = this.selected_profile_image;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filebacksizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.filebackformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.visaprofileimage = imagepath;
    }
    else {
      value3.visaprofileimage = this.visaDetail.visaprofileimage;
    }
    value3.visastart =this.datePipe.transform(new Date(value3.visastart),"dd MMM yyyy");
    value3.visaexpiry =this.datePipe.transform(new Date(value3.visaexpiry),"dd MMM yyyy");
    this.wizard =6;
    value3.stype = stype;
    value3.createby = this.Userid;
    value3.isrighttowork = this.is_rtw;
    this.visaService.myvisadetailsUpdatebyadmin(value3).subscribe((datamain: any) => {
      var data3 = {"employeeidproofdeatilid": datamain.employeevisadeatilid}
      var cid = {id: value3.countryid}
      this.country.getcountrylistbyid(cid).subscribe((countrydata: any) => {      
        var countryiso = countrydata.isothree;
        var idtype = 'VISA';    
        var visalog = {
          employeeid: this.employeemasterid,
          documentid: datamain.employeevisadeatilid,
          documenturl: value3.imagename,
          documenttype: 'v'
        };
        this.visaService.visaailogupdate(visalog).subscribe((dbvisaresult: any) => {        
          if(dbvisaresult.success =='1'){
            var data = { 
              merchantIdScanReference: dbvisaresult.logid, 
              country: countryiso, 
              idType: idtype,
              customerId: this.employeemasterid,
              is_rtw: this.is_rtw,
              callbackUrl: AppGlobals.API_ENDPOINT +"/api/aiconfirmation",
              documenturl: value3.imagename,
              documentbackurl: value3.imagebackname, 
              imageurl: value3.visaprofileimage 
            };  
            this.visaService.visadocumentupdate(data).subscribe((data5: any) => {
              if(data5.success == true){
                this.wizard = 5;
              }else {
                this.wizard = 4;
                this.alerts.error("something went wrong please try again");
              }          
            });
          }else {
            this.wizard = 4;
              this.alerts.error("something went wrong please try again");
          }
        });
      });   
      // this.wizard = 4;
    });
    this.filename = "Choose file";
    
  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  selectFile(event)
  {
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = files[0];
    this.file = this.selectedFiles;
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.fileformaterror = true;    
    }else {
      this.fileformaterror = false;
      this.filesizeerror = false;
      var reader = new FileReader();
      this.imagePath = event.target.files;
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.imgURL = reader.result; 
      }    
      // this.selectedFiles = files[0]; 
    }
  }

  selectbackFile(event)
  {
    var files = event.target.files;
    this.filebackname = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filebacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filebackformaterror = true;
      this.filebacksizeerror = false;    
    }
    else
    {
      this.filebackformaterror = false;
      this.filebacksizeerror = false;   
      this.selectedbackFiles = files[0]; 
      var reader = new FileReader();
      this.imagePath = event.target.files;
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.imgbackURL = reader.result; 
      }    
    }
  }

  selectprofile(event)
  {
    var files = event.target.files;
    this.profilepic = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.pro_pic_size_error = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.pro_pic_format_error = true;
      this.pro_pic_size_error = false;    
    }
    else
    {
      this.pro_pic_format_error = false;
      this.pro_pic_size_error = false;   
      this.selected_profile_image = files[0]; 
      var reader = new FileReader();
      this.imagePath = event.target.files;
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.profile_pic_url = reader.result; 
      }    
    }
  }

}
