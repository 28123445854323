import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { CommonService } from '../../services/common.service';
import { CountryService } from '../../services/country.service';
import { CompanyService } from '../../services/company.service';
import { JobService } from '../../services/job.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Jobs } from '../../models/jobs/jobs.model';
import { MatOptionSelectionChange } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFileService } from './../../services/upload-file.service';
import { Companylocation } from './../../models/location/companylocation.model';
import { Country } from './../../models/address/country.model';
import { Client } from "./../../models/client/client.model";
import { AlertService } from '../../services/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-companylocation',
  templateUrl: './companylocation.component.html',
  styleUrls: ['./companylocation.component.css']
})

export class CompanylocationComponent implements OnInit {
  Userid: any;
  joblist: any;
  data: any;
  companytype: any;
  datalist:any;
  locationDetail = new Companylocation();
  locationformGroup: FormGroup;
  public Editor = ClassicEditor;
  mobilecheck: any;
  phonecheck: any;
  locationdata: any;
  countrylist: any;
  countylist: any;
  options = [];
  buttoninsert: any;
  submitted: boolean = false;
  selectedFiles: FileList;
  filename: any;
  file1: any;
  file2: any;
  file3: any;
  file4: any;
  screenumber: any;
  public searchText: string;
  disableBtn: any;
  permission: any;
  locationperm: any;
  defaultVal: any = "";
  record:number;
  
  items: Array<any>;
  
  pageOfItems: Array<any>;
  
  constructor(
    private sanitizer: DomSanitizer, 
    private commonService: CommonService, 
    private modalService: ModalService, 
    private job: JobService, 
    private formBuilder: FormBuilder,
    private country: CountryService,
    private company: CompanyService,
    private router: Router,
    private uploadService: UploadFileService,
    private alerts: AlertService  
    ) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    // var ctype  = parseInt(localStorage.getItem('ctype'));
    // if(ctype ==1){
    //   this.router.navigate(['/check-dashboard']);
    // }

    
    this.Userid = localStorage.getItem('userid');

    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.locationperm = this.permission.m10;

    this.locationDetail = new Companylocation();
    
    this.getlocationformgroup();

    this.company.getcompanylocation().subscribe((locationlist: any) => {   
      this.record = locationlist.length;   
      this.data = locationlist;     
      this.datalist = this.data; 
    });

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    const countrydata = { 'id': 70 };
    this.country.getstatelist(countrydata).subscribe((state: any) => {
      this.countylist = state;
    });

    this.company.getcompanylocationtype().subscribe((locationTypelist: any) => {       
      this.companytype = locationTypelist;
    });
  }

  getlocationformgroup()
  {
    this.locationformGroup = this.formBuilder.group({
      id: [''],
      companylocationdetailsid: [''],    
      companytypeid: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      pincode: ['', Validators.required],
      cityname: ['', Validators.required],
      statename: ['', Validators.required],
      countryid: ['', Validators.required],     
      companyname: ['']      
    });
  }

  get f() { return this.locationformGroup.controls; }

  onSubmit(formlocation: any, id: string)
  {
    this.submitted = true;
    var pinc = formlocation.pincode.split(',');
    formlocation.pincode = pinc[pinc.length - 1];
    // console.log(this.locationformGroup);
    // var formlocation = companylocationformdata.value;
    if (this.locationformGroup.invalid) 
    {      
      return;
    }
    this.disableBtn = false;
    formlocation.userid = this.Userid;

    this.company.companylocationdetailsUpdate(formlocation).subscribe((response: any) => {
      this.company.getcompanylocation().subscribe((locationlist: any) => { 
        // companylocationformdata.reset();  
        this.record = locationlist.length;
        this.locationDetail.countryid = 70;   
        this.data = locationlist;
        this.modalService.close(id);
      });
    });

  }

  onDelete(location: any)
  {
    if(location.isdefault)
    {
      this.alerts.error('You can not delete a default location.',true); 
      return;
    }
    if (confirm("Are you sure you want to delete?")) {
      const data1 = { 'id': location.companylocationdetailsid, 'userid': this.Userid };
      this.company.companylocationDeletebyadmin(data1).subscribe((data: any) => {
        this.company.getcompanylocation().subscribe((locationlist: any) => {      
          this.alerts.success('Company location deleted successfully',true); 
          this.data = locationlist;
        });
      })
    }
  }

  onChangeStatus(evt: any, location: any)
  {
    this.company.companylocationUpdatestatusbyadmin({'userid': this.Userid, 'id': location.companylocationdetailsid, 'isdefault': evt.target.checked}).subscribe((data: any) => {
      this.company.getcompanylocation().subscribe((locationlist: any) => {      
        this.data = locationlist;
        this.record = this.data.length;
       
        if(this.record < 30){
          this.pageOfItems = this.data;
        }
        else{
             this.items =this.data;
        }
      });
    });
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  onSearchChange(searchValue) {
    if (searchValue.length > 4) {
      var newArray = [];
      this.commonService.getAddress(searchValue).subscribe((data: any) => {
        var newdata: any;
        newdata = data;
        newdata.addresses.forEach(function (value) {
          newArray.push(value + "," + searchValue);
        });
        this.options = newArray;
      });
    }
    else {
      this.options = [];
    }
  }

  displayFn(value: any) {
    
    if (value != null) {
      var res = value.split(",");
      if (res.length > 6) {        
        return res[7];
      }
      else {
        return value;
      }
    }
  }

  updatecode(evt: MatOptionSelectionChange, response) {
    res = [];
    if (evt.source.selected) {
      var res = response.split(",");
      this.locationDetail.address1 = res[0];
      this.locationDetail.address2 = res[1];
      this.locationDetail.cityname = res[5];
      this.locationDetail.pincode = res[7];
      this.locationDetail.statename = res[6];
      // console.log(this.locationDetail.pincode);
      // console.log(res[7]);
      // const data = { 'id': this.locationDetail.countryid };
      // this.country.getstatelist(data).subscribe((state: any) => {
      //   for (var i = 0; i < state.length; i++) {
      //     if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
      //       var inde = state[i]['stateid'];
      //     }
      //   }
      //   this.locationDetail.stateid = inde;
      //   this.countylist = state;
      // });
    }
  }

  transform(html) {
    return this.sanitizer.bypassSecurityTrustStyle(html);
  }

  selectFile(event,field) {
    var files = event.target.files;
    var selectedFiles = files[0];
    this.selectedFiles = selectedFiles;
    const file = this.selectedFiles;
    var imagepath = this.uploadService.uploadfile(file);
  }

  openModal(id: string, data: any) 
  { 
    this.submitted = false;
    this.buttoninsert = "Update";
    this.disableBtn = true;

    this.company.getcompanylocationtype().subscribe((locationTypelist: any) => {       
      this.companytype = locationTypelist;
    });
    
    this.locationDetail = data;

    // this.locationDetail.countyid = data.stateid;
    this.modalService.open(id);
  }

  openModal2(id: string) 
  { 
    this.submitted = false;
    this.buttoninsert = "Submit";
    this.disableBtn = true;
    this.locationDetail = new Companylocation();
    // this.getlocationformgroup();
    this.locationDetail.companytypeid = "";
    this.locationDetail.countryid = 70;
    this.locationDetail.companylocationdetailsid = "0";
    this.modalService.open(id);
  }

  openModal3(jobid: string, id: string) 
  { 
    this.job.getjobById({"id": this.Userid, "jobid": jobid}).subscribe((data: any) => {
     
      this.locationDetail = data;
    });
    this.modalService.open(id);
  }

  openModal4(jobid: string, id: string, screenumber: number) 
  { 
    this.buttoninsert = "Submit";  
    this.disableBtn = true; 
    this.screenumber = screenumber;
    this.modalService.open(id);
  }

  deleteFile(jobid: string, id: string, documenturl: string)
  {
    var deleteData = {};
    if(documenturl == 'document1')
    {
      deleteData = { 'id': id, 'urldocument1': "" };
    }
    else if(documenturl == 'document2')
    {
      deleteData = { 'id': id, 'urldocument2': "" };
    }
    else if(documenturl == 'document3')
    {
      deleteData = { 'id': id, 'urldocument3': "" };
    }
    else if(documenturl == 'document4')
    {
      deleteData = { 'id': id, 'urldocument4': "" };
    }
    
    if (confirm("Are you sure you want to delete?")) {
      this.job.deleteFile(deleteData).subscribe((data: any) => {
        this.job.getjobById({"id": this.Userid, "jobid": jobid}).subscribe((data: any) => {          
          this.locationDetail = data;
        });
      });
    }
  }

  closeModal(id: string) 
  { 
    
    this.company.getcompanylocation().subscribe((locationlist: any) => {   
      this.record = locationlist.length;   
      this.data = locationlist;      
      this.modalService.close(id);
    });
    
  }

}
