import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { error } from 'util';
import { User } from '../../models/user.model';
import { CommonService } from '../../services/common.service';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../../services/customer.service';
import { CompanyService } from '../../services/company.service';
import { AlertService } from '../../services/alert.service';
// import { User} from '../../shared/user.model';
// import { EmailValidator } from '@angular/forms';

@Component({
  selector: 'app-updatemobile',
  templateUrl: './updatemobile.component.html',
  styleUrls: ['./updatemobile.component.css']
})
export class UpdatemobileComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  isLoginError = false;
  alertService: any;
  // loading: boolean;
  user = new User();
  access_token = '';
  userid = '';
  name = '';
  isline = '';
  planid = '';
  error = '';
  show: any;
  success: any;
  showsuccess: any;
  phonecheck: any;

  mobilenumber2:any;


  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private alerts: AlertService,
    private commonService: CommonService,
    private csservices: CustomerService,
    private company: CompanyService
  ) { }

  ngOnInit() {


  var userid = localStorage.getItem('userid');
  if (!userid) {
    this.router.navigate(['/login']);
  }
  else {
    this.userService.checkaccesstokenbyid(userid).subscribe((data: any) => {
      if (data.status == false) {
       
      localStorage.removeItem('userToken');
      localStorage.removeItem('userid');
      localStorage.removeItem('Name');
      localStorage.removeItem('isline');
      localStorage.removeItem('planid');
      localStorage.removeItem('ctype');
      localStorage.removeItem('isemail');
      localStorage.removeItem('ismobile');
      localStorage.removeItem('isadmin');
      localStorage.removeItem('ispermission');
      localStorage.removeItem('logoname');
      localStorage.removeItem('struser');
      localStorage.removeItem('menu');
        this.router.navigate(['/login']);
      }     
    });
  }


    this.registerForm = this.formBuilder.group({
      mobilenumber: ['', Validators.required]
    });

    this.user.mobilenumber = "+44"
    
    this.show = false;
    this.success = true;


    this.company.getcompanydetails().subscribe((data: any) => {
      console.log(data.phonenumber);
      this.mobilenumber2 = data.phonenumber;
      var ismobile = localStorage.getItem('ismobile');
      console.log(ismobile);
      if ( ismobile == "true" || ismobile =="1") {
        localStorage.setItem('ismobile', "true");
        this.router.navigate(['/choose-plan']);
      }
    });

  }


  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    console.log(this.user);

    this.company.mobileupdate(this.user).subscribe((data: any) => {
      if (data.status == "success") {

        this.company.resendmobileotp().subscribe((data: any) => {
          localStorage.setItem('otpmobile', data.otp);
        this.showsuccess = true;
        this.success = false;       
        this.router.navigate(['/otp']);
        });
      } else {
          this.showsuccess = false;
          this.success = true;

          this.alerts.error("This mobille number already exists. Please try another mobile number.");
         // this.show = true;
      }
    },
      (err: HttpErrorResponse) => {
        this.show = true;
      });
  }

  resedotp() {
    this.submitted = false;
    this.show = false;
    this.company.resendmobileotp().subscribe((data: any) => {
      localStorage.setItem('otpmobile', data.otp);
      this.show = false;
      this.router.navigate(['/otp']);
    },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
      });
  }
  
  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
     
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }
  logout() {
    if (confirm("Are you sure you want to sign out?")) {
      this.userService.logout().subscribe((data: any) => {
       
      localStorage.removeItem('userToken');
      localStorage.removeItem('userid');
      localStorage.removeItem('Name');
      localStorage.removeItem('isline');
      localStorage.removeItem('planid');
      localStorage.removeItem('ctype');
      localStorage.removeItem('isemail');
      localStorage.removeItem('ismobile');
      localStorage.removeItem('isadmin');
      localStorage.removeItem('ispermission');
      localStorage.removeItem('logoname');
      localStorage.removeItem('struser');
      localStorage.removeItem('menu');

        this.router.navigate(['/login']);
      });
    }
  }
}