import { Component, OnInit } from '@angular/core';
import { ChecksystemService } from '../../services/checksystem.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkcountshow',
  templateUrl: './checkcountshow.component.html',
  styleUrls: ['./checkcountshow.component.css']
})
export class CheckcountshowComponent implements OnInit {

  
  public href: string = "";
  checkcount:any;

  constructor(
    private router: Router,    
    private check: ChecksystemService
    ) { }

  ngOnInit() {
    this.href = this.router.url;
    console.log(this.href); 
    var ts;

    if(this.href == '/check-personal-aml-peps-sanction' || this.href == '/check-business-aml-peps-sanction' || this.href == '/search-personal-aml' || this.href == '/search-business-aml/aml'  ){
      ts  = { 'serviceid': 8 };    
    } else if(this.href == '/check-id-verification' || this.href == '/check-identity-verification' || this.href == '/check-document-verification' ){
      ts  = { 'serviceid': 7 };    
    } else if(
      this.href == '/right-to-work-check' || 
      this.href == '/right-to-work-app-history' || 
      this.href.split('/')[1]=='right-to-work-check-details' || 
      this.href.split('/')[1]=='right-to-work-app' ||
      this.href.split('/')[1]=='right-to-work-app-history-list' ||
      this.href.split('/')[1]=='right-to-work-app-history'
    ){
      ts  = { 'serviceid': 4 };    
    } else if(this.href == '/tax-defaulter-check'){
      ts  = { 'serviceid': 9 };    
    } else if(this.href == '/dbs/basic' || this.href == '/dbs-history/basic'){
      ts  = { 'serviceid': 2 };
    } else if(this.href == '/dbs/standard' || this.href == '/dbs-history/standard'){
      ts  = { 'serviceid': 3 };
    } else if(this.href == '/dbs/enhanced' || this.href == '/dbs-history/enhanced'){
      ts  = { 'serviceid': 1 };
    }else if(this.href == '/adversecredit' || this.href.split('/')[1]=='adversecredit' ){
      ts  = { 'serviceid': 6 };
    }
    else if(this.href == '/dvla-check' || this.href == '/dvla-check-history'){
      ts  = { 'serviceid': 5 };
    } 
    else if(this.href == '/add-new-reference' || this.href == '/add-bulk-reference' || this.href == '/reference-status' || this.href == '/bulk-reference-status'){
      ts  = { 'serviceid': 11 };
    }
    else if(this.href == '/company-advert-search' || 
      this.href == '/dvla-check-history' ||
      this.href.split('/')[1]=='company-advert-search'
    )
    {
      ts  = { 'serviceid': 10 };
    } else if(this.href == '/director-search' ){
      ts  = { 'serviceid': 12 };
    } else if(this.href == '/search-personal-aml/adverse' ){
      ts  = { 'serviceid': 22 };    
    } else if(this.href == '/search-personal-aml/global' || this.href == '/search-business-aml/global' ){
      ts  = { 'serviceid': 23 };    
    } 
    
    
    else {
      ts  = { 'serviceid': 7 };    
    }
    

    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      this.checkcount = remaindata;
    });

    console.log(this.router.url);



  }

  getRemainingChecks(){
    var ts;

    if(this.href == '/check-personal-aml-peps-sanction' || this.href == '/check-business-aml-peps-sanction' ){
      ts  = { 'serviceid': 8 };    
    } else if(this.href == '/check-id-verification' || this.href == '/check-identity-verification' || this.href == '/check-document-verification' ){
      ts  = { 'serviceid': 7 };    
    } else if(
      this.href == '/right-to-work-check' || 
      this.href == '/right-to-work-app-history' || 
      this.href.split('/')[1]=='right-to-work-check-details' || 
      this.href.split('/')[1]=='right-to-work-app' ||
      this.href.split('/')[1]=='right-to-work-app-history-list' ||
      this.href.split('/')[1]=='right-to-work-app-history'
    ){
      ts  = { 'serviceid': 4 };    
    } else if(this.href == '/tax-defaulter-check'){
      ts  = { 'serviceid': 9 };    
    } else if(this.href == '/dbs/basic' || this.href == '/dbs-history/basic'){
      ts  = { 'serviceid': 2 };
    } else if(this.href == '/dbs/standard' || this.href == '/dbs-history/standard'){
      ts  = { 'serviceid': 3 };
    } else if(this.href == '/dbs/enhanced' || this.href == '/dbs-history/enhanced'){
      ts  = { 'serviceid': 1 };
    }else if(this.href == '/adversecredit' || this.href.split('/')[1]=='adversecredit' ){
      ts  = { 'serviceid': 6 };
    }else if(this.href == '/dvla-check' || this.href == '/dvla-check-history'){
      ts  = { 'serviceid': 5 };
    }  else {
      ts  = { 'serviceid': 7 };    
    }
    

    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      this.checkcount = remaindata;
    });
  }

}
