<div class="bg">
  <app-header></app-header>
<div class="section-wrapper p-0">
<div class="admin_dashboard_show">
  <div class="ceee">
    <div class="cardmenu">
      
        <a class="card_box_lit" [routerLink]="['/employee-object-list']">
          <div class="samebox">
            <i class="lnr lnr-user"></i>
            <div class="menu_content">
                <h4>Objective</h4>
            </div>
          </div>            
        </a> 
        <!-- <a  class="card_box_lit" [routerLink]="['/my-leave']">
          <div class="samebox">
            <i class="lnr lnr-pencil"></i>
            <div class="menu_content">
                <h4>My Leave</h4>
            </div>
          </div>
            
            
        </a>
        <a class="card_box_lit" [routerLink]="['/my-expense']">
          <div class="samebox">
            <i class="lnr lnr-gift"></i>
            <div class="menu_content">
                <h4>My Expense</h4>
            </div>
          </div>
            
            
        </a>
        <a class="card_box_lit" [routerLink]="['/my-worklist']">
          <div class="samebox">
            <i class="lnr lnr-calendar-full"></i>
            <div class="menu_content">
                <h4>My Timesheet</h4>
            </div>
          </div>
            
            
        </a>
        <a class="card_box_lit" [routerLink]="['/my-asset']">
          <div class="samebox">
            <i class="lnr lnr-frame-expand"></i>
            <div class="menu_content">
                <h4>My Asset</h4>
            </div>
          </div>
           
            
        </a>
        <a class="card_box_lit" [routerLink]="['/my-rota']">
          <div class="samebox">
              <i class="lnr lnr-laptop-phone"></i>
              <div class="menu_content">
                  <h4>Rota</h4>
              </div>
          </div>
          
        </a> -->
  </div>
  </div>
</div>
</div>
</div>