"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var country_service_1 = require("../../services/country.service");
var applicant_service_1 = require("../../services/applicant.service");
var job_service_1 = require("../../services/job.service");
var applicant_model_1 = require("../../models/applicant.model");
var excel_service_1 = require("../../services/excel.service");
var ApplicantreportComponent = /** @class */ (function () {
    function ApplicantreportComponent(excelService, formBuilder, country, candidate, jobser) {
        this.excelService = excelService;
        this.formBuilder = formBuilder;
        this.country = country;
        this.candidate = candidate;
        this.jobser = jobser;
        this.applicantDetail = new applicant_model_1.Applicant();
        this.exceldata = [];
    }
    ApplicantreportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.EmployeeForm = this.formBuilder.group({
            fname: [''],
            lname: [''],
            emailid: [''],
            phonenumber: [''],
            jobid: [''],
            cstatus: [''],
        });
        var value3 = "{}";
        this.candidate.getcandidatelist(value3).subscribe(function (listdetails) {
            _this.data = listdetails;
        });
        this.candidate.getcandidatestatus().subscribe(function (statusdetails) {
            _this.statuslist = statusdetails;
        });
        this.jobser.getjoblist().subscribe(function (jobdetails) {
            _this.joblist = jobdetails;
        });
    };
    ApplicantreportComponent.prototype.exportAsXLSX = function () {
        var _this = this;
        this.exceldata = [];
        this.data.forEach(function (obj) {
            //var candidateData = {};
            if (obj.joburlid == '0') {
                var url = obj.job.joburl1;
            }
            else if (obj.joburlid == '1') {
                var url = obj.job.joburl2;
            }
            else if (obj.joburlid == '2') {
                var url = obj.job.joburl3;
            }
            else if (obj.joburlid == '3') {
                var url = obj.job.joburl4;
            }
            _this.candidatedata = {
                "Name": obj.fname + " " + obj.mname + " " + obj.lname,
                "Email": obj.emailid,
                "Phone Number": obj.phonenumber,
                "Job Title": obj.job.jobtittle,
                "Job URL": url,
                "Marks": obj.smarks,
                "Status": obj.status.statusname
            };
            _this.exceldata.push(_this.candidatedata);
        });
        this.excelService.exportAsExcelFile(this.exceldata, 'Applicant_Report');
    };
    ApplicantreportComponent.prototype.onSubmit = function (value3) {
        var _this = this;
        this.candidate.getcandidatelist(value3).subscribe(function (listdetails) {
            _this.data = listdetails;
        });
    };
    return ApplicantreportComponent;
}());
exports.ApplicantreportComponent = ApplicantreportComponent;
