import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employeeaithankyou',
  templateUrl: './employeeaithankyou.component.html',
  styleUrls: ['./employeeaithankyou.component.css']
})
export class EmployeeaithankyouComponent implements OnInit {
  ctype:number;
  constructor() { }

  ngOnInit() {
    this.ctype  = parseInt(localStorage.getItem('ctype'));
  }

}
