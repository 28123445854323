import { Component, OnInit } from '@angular/core';
import { CheckserviceService } from '../../../services/checkservice.service';
import { Router, ActivatedRoute, Params,NavigationEnd   } from '@angular/router';
import { ChecksystemService } from '../../../services/checksystem.service';
import { exit } from 'process';
import { ModalService } from '../../../_services/modal.service';
// import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

// import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

@Component({
  selector: 'app-employee-right-to-work-app',
  templateUrl: './employee-right-to-work-app.component.html',
  styleUrls: ['./employee-right-to-work-app.component.css']
})
export class EmployeeRightToWorkAppComponent implements OnInit {

  currentStep: any;
  documentArray: any = [];
  accessToken: any;
  docid: any;
  profile_pic: any;  
  docs: any;
  newrighttoworklog: any;
  ismobile: boolean=false;
  linkurl = '';
  routerurl='';
  type:any;
  str: String;

  constructor(
    private router: Router,
    private modalService: ModalService, 
    private activatedRoute: ActivatedRoute,
    private check: ChecksystemService,
    private checkService: CheckserviceService,
    // private deviceDetectorService: DeviceDetectorService
  ) { 

    router.events.filter(event => event instanceof NavigationEnd).subscribe(events => 
     {

      this.routerurl= router.url;
         this.linkurl = "https://employee.complygate.co.uk"+ router.url+'?type=ukpassport';          
         console.log(router.url);
     });

     if (navigator.userAgent.match(/Tablet|iPad/i))
      {
        this.ismobile =true;
          // do tablet stuff
      } else if(navigator.userAgent.match(/Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i) )
      {
        this.ismobile =true;
          // do mobile stuff
      } else {
        this.ismobile =false;
          // do desktop stuff
      }   

    //  let agent = navigator.userAgent;

    //  console.log(agent);
  }


  ngOnInit() {



    this.activatedRoute.queryParams.filter(params => params.type).subscribe(params => {      
      this.type =params.type; 
      localStorage.setItem('fakecard', this.type);
    });

    // this.deviceInfo = this.deviceDetectorService.getDeviceInfo();

    this.accessToken = this.activatedRoute.snapshot.paramMap.get("token");

    

    // console.log('token mater',this.accessToken)

    this.checkService.getrighttoworktoken({token: this.accessToken}).subscribe((righttoworklogtoken: any) => {  



      this.str = new String(righttoworklogtoken.otp);

      console.log('token mater System',righttoworklogtoken)

      

      if(righttoworklogtoken.success == 'success')
    {
          localStorage.setItem('userToken1', righttoworklogtoken.token);
          localStorage.setItem('logid', righttoworklogtoken.logid);
          localStorage.setItem('euserid', righttoworklogtoken.userid);



          // localStorage.removeItem('userid');
          // localStorage.removeItem('Name');
          // localStorage.removeItem('isline');
          // localStorage.removeItem('planid');
          // localStorage.removeItem('isemail');
          // localStorage.removeItem('isadmin');
          // localStorage.removeItem('ispermission');
          // localStorage.removeItem('ctype');
          // localStorage.removeItem('logoname');
          // localStorage.removeItem('menu');

          this.checkService.getRightToWorkLogById({id: righttoworklogtoken.logid}).subscribe((righttoworklog: any) => {
             console.log(righttoworklog);

             this.newrighttoworklog = righttoworklog;



            if(righttoworklog.remaindocumentlist){
              this.documentArray = righttoworklog.remaindocumentlist.split(',');
            }


            if(righttoworklog.profileimage){
                   localStorage.setItem('profile_pic', righttoworklog.profileimage);
              }
              else
              {
                localStorage.removeItem('profile_pic');
              }

            this.performAction();
            // console.log(righttoworklog);
          });
        }else{

          this.currentStep = 9;
          
          localStorage.removeItem('profile_pic');
          localStorage.removeItem('userToken1');
          localStorage.removeItem('euserid');
          // localStorage.removeItem('userid');
          // localStorage.removeItem('Name');
          // localStorage.removeItem('isline');
          // localStorage.removeItem('planid');
          // localStorage.removeItem('isemail');
          // localStorage.removeItem('isadmin');
          // localStorage.removeItem('ispermission');
          // localStorage.removeItem('ctype');
          // localStorage.removeItem('logoname');
          // localStorage.removeItem('menu');

            
        }
        });    
   
      

  }
  todotaskclicktask(id)
  {    

    this.linkurl = "https://employee.complygate.co.uk"+ this.routerurl +"?type="+ id;  


    if(this.ismobile == false)
    {
      localStorage.setItem('fakecard', id);
      this.modalService.open('pdf-image-viewer-passportviewpage');
    }else{
      this.currentStep = 2;
    }


  }

  contwithdevice()
  {
    this.ismobile =false;
    this.currentStep = 2;

    this.modalService.close('pdf-image-viewer-passportviewpage');
    this.modalService.close('pdf-image-viewer-passportviewpage_qr');
  }


  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  performAction(){
    // console.log(this.documentArray);

    if(this.documentArray.length){
      var docid = this.documentArray[0];
      this.documentArray.splice(0,1);
      this.docs = this.documentArray;
      if(Number(docid) == 101){
        this.currentStep = 1;
      } else if(Number(docid) == 100){

        if(this.newrighttoworklog.isrighttowork ==1)
        {



          if(this.type)
            this.currentStep = 2;
          else
            this.currentStep =5;



            if(this.type && this.ismobile == false )
            {
              this.currentStep = 14;
            }
        }
        else
        {
          this.currentStep = 2;
        }
      } else{
        this.docid = docid;
        this.currentStep = 3;
      }
    } else{
      var i = 1;
      while(i < 10){

        var logid;

        logid=localStorage.getItem('logid');
        var dd ={id:logid }

        this.check.deleteAccessTokenrightoworklog(dd).subscribe((tokenData: any) => {
          if(tokenData.success){
            localStorage.removeItem('userToken1');
            // localStorage.removeItem('userid');
            // localStorage.removeItem('emptype');
            // localStorage.removeItem('isline');
            // localStorage.removeItem('planid');
            // localStorage.removeItem('isemail');
            // localStorage.removeItem('isadmin');
            // localStorage.removeItem('ispermission');
            // localStorage.removeItem('logoname');
            // localStorage.removeItem('ctype');
            localStorage.removeItem('profile_pic');
            this.currentStep = 4;
            exit;
          }
          
        });
        i++;
      }
      
      
    }
  }


  sendsms()
  {
    alert('SMS Send Successfully');
    var dd={url:  this.linkurl }    
    this.check.sendsmsurl(dd).subscribe((tokenData: any) => {
      // alert('SMS Send Successfully');
    });
  }

  openmodel()
  {
    this.modalService.close('pdf-image-viewer-passportviewpage');
    this.modalService.open('pdf-image-viewer-passportviewpage_qr');

  }
  backpopup(){
    this.modalService.open('pdf-image-viewer-passportviewpage');
    this.modalService.close('pdf-image-viewer-passportviewpage_qr');
  }

  closeModal(id)
  {
    this.modalService.close(id);
  }

}
