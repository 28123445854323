"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var ClientService = /** @class */ (function () {
    function ClientService(commonService) {
        this.commonService = commonService;
    }
    ClientService.prototype.getclientlist = function (data) {
        return this.commonService.formpost('/company/clientlistbyadmin', data);
    };
    ClientService.prototype.clientdetailsUpdate = function (value) {
        return this.commonService.formpost('/company/clientupdate', value);
    };
    ClientService.prototype.clientdetailsUpdatestatusbyadmin = function (value) {
        return this.commonService.formpost('/company/clientupdatestatusbyadmin', value);
    };
    ClientService.ngInjectableDef = i0.defineInjectable({ factory: function ClientService_Factory() { return new ClientService(i0.inject(i1.CommonService)); }, token: ClientService, providedIn: "root" });
    return ClientService;
}());
exports.ClientService = ClientService;
