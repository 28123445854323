"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var DirectorService = /** @class */ (function () {
    function DirectorService(commonService) {
        this.commonService = commonService;
    }
    DirectorService.prototype.getdirectordata = function (data) {
        return this.commonService.formpost('/director/getdirectordata', data);
    };
    DirectorService.prototype.getcompanydata = function (data) {
        return this.commonService.formpost('/director/getcompanydata', data);
    };
    DirectorService.prototype.getNorecordcompanydata = function (data) {
        return this.commonService.formpost('/director/downloadnorecorddirector', data);
    };
    DirectorService.prototype.searchdirector = function (data) {
        return this.commonService.formpost('/director/searchdirector', data);
    };
    // getcompanyimages(data): Observable<any> 
    // {
    //   return this.commonService.formpost('/company/getimages',data);   
    // }
    DirectorService.prototype.getdirectorhistory = function () {
        return this.commonService.formpostOnlyid('/director/getdirectorhistory');
    };
    DirectorService.prototype.getdirectorhistoryview = function (data) {
        return this.commonService.formpost('/director/getdirectorhistoryview', data);
    };
    DirectorService.prototype.downloaddirectordata = function (data) {
        return this.commonService.formpost('/director/downloaddirector', data);
    };
    DirectorService.ngInjectableDef = i0.defineInjectable({ factory: function DirectorService_Factory() { return new DirectorService(i0.inject(i1.CommonService)); }, token: DirectorService, providedIn: "root" });
    return DirectorService;
}());
exports.DirectorService = DirectorService;
