import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-passportaiwizard',
  templateUrl: './passportaiwizard.component.html',
  styleUrls: ['./passportaiwizard.component.css']
})

export class PassportaiwizardComponent implements OnInit {
  employeepassportdeatilid: any;
  
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.employeepassportdeatilid = this.activatedRoute.snapshot.paramMap.get("passportid");
  }
}