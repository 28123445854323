import { Component, OnInit } from '@angular/core';
import { CountryService } from '../../../services/country.service';
import { MyidproofService } from '../../../services/myidproof.service';
import { ModalService } from '../../../_services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Idproof } from '../../../models/idproof/idproof.model';
import { UploadFileService } from './../../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
// import { Router } from '@angular/router';
import { Router, ActivatedRoute, Params } from '@angular/router';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { AlertService } from '../../../services/alert.service';

@Component({ 
  selector: 'app-idprooflist',
  templateUrl: './idprooflist.component.html',
  styleUrls: ['./idprooflist.component.css']
})
export class IdprooflistComponent implements OnInit {
 imgidproofbacksrc:any;
  iframeidproofbacksrc:any;
  idprooflistbyadmin: any[]; 
  idproofDetail: any;
  countrylist: any;
  Userid: any;
  listcheck: any;
  selectedFiles: FileList;
  iframeidproofsrc: any;
  imgidproofsrc: any;
  buttoninsert: any;
  filesizeerror: any;
  fileformaterror: any;
  file: any;
  filename: any;
  documenttype:any;
  empid: any;
  defaultVal: any = "";
  filereuirederror: any; 
  imagesecond: any;
  filebacksizeerror: any;
  filebackformaterror: any;
  selectedbackFiles: FileList;
  filebackname: any;
  formattedMessage: any;
  filterItems = [
    {
      name: 'All',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Pending Approval',
      id: 1,
      selected: false
    }
  ]
  submitted: any;
  idproofpopForm: FormGroup;
  idproof: Idproof
  disableBtn: any;
  
  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private data: MyidproofService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private activatedRoute: ActivatedRoute, 
    private alerts: AlertService
    ) { }

  ngOnInit() {
    this.empid = this.activatedRoute.snapshot.paramMap.get("id");
    this.idproofpopForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      idnumber: ['', Validators.required],
      idtype: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      docexpiry: ['', Validators.required]
    });
    this.filename = 'Choose file';
    this.idproofDetail = new Idproof();
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.empid, 'cstatus': [1, 2, 3] };

    this.data.getidproofAllListbyadmin(data).subscribe((data: any) => {
      this.idprooflistbyadmin = data;
    });

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    
    this.data.iddocumenttype().subscribe((documenttype: any) => {
      this.documenttype = documenttype;
    });

    this.listcheck = this.filterItems;
  }


  onDelete(id: number) {
    const data1 = { 'id': id, 'userid': this.Userid };
    if (confirm("Are you sure you want to delete?")) {
      this.data.deleteidproof(data1).subscribe((data: any) => {
        var sstatus: any;
        for (let c of this.filterItems) {
          if (c.selected == true) {
            if (c.id == 0) {
              sstatus = [1, 2, 3];
            }
            else {
              sstatus = [c.id];
            }
          }
        }
        const data2 = { 'id': this.empid, 'cstatus': sstatus };
        this.data.getidproofAllListbyadmin(data2).subscribe((data: any) => {
          this.idprooflistbyadmin = data;
        });
        this.alerts.success('Address Proof deleted successfully', true);

      });
    }
  }

  onlistchange(type1: any, idval: number) {
    var sstatus: any;
    for (let c of this.filterItems) {
      if (c.id == idval)
        c.selected = true;
      else
        c.selected = false;
    }
    if (idval == 0 || type1 == false) {
      sstatus = [1, 2, 3];
    }
    else {
      sstatus = [idval];
    }
    const data = { 'id': this.empid, 'cstatus': sstatus };
    this.data.getidproofAllListbyadmin(data).subscribe((data: any) => {
      this.idprooflistbyadmin = data;
    });
  }

  get f() {
    return this.idproofpopForm.controls;
  }

  onSubmit(formidproof: any, id: string) {
    // var formidproof = value3.value;
    this.submitted = true;
    if (this.idproofpopForm.invalid) {
      if(formidproof.id == '0'){
        if(!this.selectedFiles)
          this.filereuirederror = true;
      }
      return;
    }

    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
        this.fileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      formidproof.imagename = imagepath;
    }
    else {
      if(formidproof.id == '0'){
        this.filereuirederror = true;
        return;
      }
      formidproof.imagename = this.idproofDetail.idproofdocname;
    }

    
    if (this.selectedbackFiles) {
      this.file = this.selectedbackFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
        this.fileformaterror = true;
        return;
      }
      var imagepath =  this.uploadService.uploadfile(this.file);
      formidproof.imagebackname = imagepath;
    }
    else {
      if(formidproof.id == '0')
      {
        formidproof.imagebackname = '';
       }
     else {
      formidproof.imagebackname = this.idproofDetail.idbackdocName;
     }
      
    }

    formidproof.createby = this.Userid;
    formidproof.updateby = this.Userid;

    
    const sd = new Date(formidproof.docexpiry);
    formidproof.docexpiry = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    

    this.disableBtn = false;
    this.data.myidproofdetailsUpdatebyadmin(formidproof).subscribe((data: any) => {
      var sstatus: any;
      for (let c of this.filterItems) {
        if (c.selected == true) {
          if (c.id == 0) {
            sstatus = [1, 2, 3];
          }
          else {
            sstatus = [c.id];
          }
        }
      }
      const data1 = { 'id': this.empid, 'cstatus': sstatus };
      this.data.getidproofAllListbyadmin(data1).subscribe((data: any) => {
        this.idprooflistbyadmin = data;
      });
    });
    this.filename = "Choose file";
    this.alerts.success('Address Proof Updated Successfully', true);
    this.modalService.close(id);
  }

  selectFile(event)
  {
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.fileformaterror = true;  this.filesizeerror = false;      
    }else{
      this.filesizeerror = false;  
      this.fileformaterror = false;
      this.filereuirederror =false;
      this.imagesecond =  this.uploadService.uploadfile(this.file);
      this.selectedFiles = files[0];
    }
  }

  selectbackFile(event)
   {
    var files = event.target.files;
    this.filebackname = files[0].name;
    this.selectedbackFiles = this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filebacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filebackformaterror = true;    this.filebacksizeerror = false; 
    }else{this.filebacksizeerror = false;  
      this.filebackformaterror = false;
     this.imagesecond =  this.uploadService.uploadfile(this.file);
      this.selectedbackFiles = files[0];
    }
  }

  openModal2(id: string) 
  {
    this.filesizeerror = false;  
    this.fileformaterror = false;
    this.filereuirederror =false;
    this.filebacksizeerror = false;  
    this.filebackformaterror = false;
    this.selectedFiles = null;
    this.selectedbackFiles = null;
    this.submitted = false;
    this.disableBtn = true;
    this.buttoninsert = "Submit";
    this.idproofDetail = new Idproof();
    this.idproofDetail.employeeid = this.empid;
    this.idproofDetail.employeeidproofdeatilid = "0";    
    // this.idproofDetail.idproofexpiry ='';
    // this.idproofDetail.idproofstart = '';
    // this.idproofDetail.idprooftype = '';
    this.idproofDetail.nationalityid = '';
    this.filename = "Choose file";
    this.filebackname = "Choose file";
    this.modalService.open(id);
  }

  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModalidproof3(id: string, data: any, backimg:any) 
  {
    this.iframeidproofsrc ='';
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgidproofsrc = true;
      this.iframeidproofsrc = data;
    }
    else {
      this.imgidproofsrc = false;
      this.iframeidproofsrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
     if(backimg)
    {
      var fullpathArray = backimg.split('.');
      var typeoffile = fullpathArray[fullpathArray.length - 1];
      if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
        this.imgidproofbacksrc = true;
        this.iframeidproofbacksrc = backimg;
      } 
      else {
        this.imgidproofbacksrc = false;
        this.iframeidproofbacksrc = this.getSafeUrl("https://docs.google.com/gview?url=" + backimg + "&embedded=true");
      }
    }
    else {
      this.imgidproofbacksrc = false;
      this.iframeidproofbacksrc = '';
    }
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.filesizeerror = false;  
    this.fileformaterror = false;
    this.filereuirederror =false;
    this.filebacksizeerror = false;  
    this.filebackformaterror = false;
    this.filename = "Choose file";
    this.filebackname = "Choose file";
    this.selectedFiles = null;
    this.selectedbackFiles = null;
    this.disableBtn = true;    
    this.idproofDetail = data;    
    this.buttoninsert = "Update";
    this.modalService.open(id);
  }

  closeModal(id: string) 
  {
    this.idproof = new Idproof();
    this.idproofDetail = new Idproof();
    const data = { 'id': this.empid, 'cstatus': [1, 2, 3] };
    this.data.getidproofAllListbyadmin(data).subscribe((data: any) => {
      this.idprooflistbyadmin = data;
    });
    this.modalService.close(id);
  }
}
