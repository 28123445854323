<div class="bg">
<app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__content">
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="space_em row">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Project List<a (click)="openModal_project('custom-project-1')"
                                            class="mainheading_btn " *ngIf="projectperm > 1"> + Add New </a></h5>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group">
                                        <input [(ngModel)]="searchText" placeholder="Search.." class="form-control">
                                    </div>
                                </div>
                            </div>
                        <ng-container *ngIf="( data |  filter:{projectname: searchText,contactperson: searchText,emailid: searchText,mobilenumber: searchText}) as pageOfItems">
                            <div class="job_list_section employee_list_section"
                                *ngFor="let project of pageOfItems; let i=index">
                                <div class="job_detils_date width28">
                                    <h5 class="job_profile font_17">{{project.projectname}}</h5>
                                    <p class="email"><i aria-hidden="true" class="fa fa-envelope-o"></i>
                                        {{project.emailid}}</p>
                                    <p class="phone"><i aria-hidden="true" class="fa fa-phone"></i>
                                        {{project.mobilenumber }}</p>
                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address"> Project Owner</p>
                                    <p class="job_profile">{{project.contactperson}}</p>
                                </div>

                                <div class="job_detils_date visa_btn">
                                    <div class="job_address d-flex aligncenter">
                                        <button type="submit" class="btn btn-secondary com_btn" *ngIf="projectperm > 0"
                                            (click)="openModal_details('custom-project-view-1',project)">
                                            <div class="tooltip_visa">
                                                <p >Details</p>
                                            </div>
                                            <i class="fa fa-clipboard"></i></button>

                                        <button type="submit" class="btn btn-primary com_btn" *ngIf="projectperm > 2"
                                            (click)="openModal_details('custom-project-1',project)" >
                                            <div class="tooltip_visa">
                                                <p >Edit</p>
                                            </div>
                                            <i class="fa fa-pencil"></i></button>

                                        <!-- <button type="submit" class="btn btn-secondary com_btn" (click)="openModal('custom-passport-1',passport)"><i class="fa fa-trash-o"></i></button> -->
                                        <div class="onoffswitch" *ngIf="projectperm > 2">
                                            <label class="onoffswitch-label" for="skill{{i+1}}" class="switch">
                                                <input type="checkbox" name="{{ project.pstatus }}" type="checkbox"
                                                    id="skill{{i+1}}" [checked]="project.pstatus"
                                                    (change)="onChangeStatus($event, project)">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>
                        </ng-container>
                        <!-- <div class="job_list_section visa_request_section" *ngIf="record === 0">
                            <div class="job_detils_date">
                                <p class="job_profile">No Record Found</p>
                            </div>
                        </div> -->

                            <!-- <div class="row">
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="items" [pageSize]="3" [maxPages]="10"
                                        (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<jw-modal id="custom-project-1" role="dialog" class="modal">
    <form [formGroup]="projectFormGroup" #projectForm="ngForm" class="s12 white"
        (ngSubmit)="onSubmit(projectForm.value,'custom-project-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" class="form-control" name="id" id="id"
                        [ngModel]="projectDetail.projectid">
                    <h5 class="modal-title" id="popupLabel">Project Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                        (click)="closeModal('custom-project-1');">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label>Project Name*</label>
                                <input type="text" formControlName="projectname" name="projectname" id="projectname"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.projectname.errors }"
                                    [ngModel]="projectDetail.projectname">
                                <div *ngIf="submitted && f.projectname.errors" class="invalid-feedback">
                                    <div *ngIf="f.projectname.errors.required">Please enter project name</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Client Name* </label>
                                <select style="width:100%;" formControlName="clientid" name="clientid" id="clientid"
                                    class="form-control input-lg"
                                    [ngClass]="{ 'is-invalid': submitted && f.clientid.errors }"
                                    [ngModel]="projectDetail.clientid">
                                    <option [value]="emptyValue">Select Client Name </option>
                                    <option *ngFor="let clientlist of clientdata" [value]="clientlist.clientid">
                                        {{ clientlist.companyname }} ({{clientlist.clientname}})</option>
                                </select>
                                <div *ngIf="submitted && f.clientid.errors" class="invalid-feedback">
                                    <div *ngIf="f.clientid.errors.required">Please select client name</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Web URL</label>
                                <input type="text" formControlName="weburl" name="weburl" id="weburl"
                                    class="form-control" [ngModel]="projectDetail.weburl">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Project Owner*</label>
                                <input type="text" formControlName="contactperson" name="contactperson"
                                    id="contactperson" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.contactperson.errors }"
                                    [ngModel]="projectDetail.contactperson">
                                <div *ngIf="submitted && f.contactperson.errors" class="invalid-feedback">
                                    <div *ngIf="f.contactperson.errors.required">Please enter project owner </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Role </label>
                                <input type="text" formControlName="contactrole" name="contactrole" id="contactrole"
                                    class="form-control" [ngModel]="projectDetail.contactrole">
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Email*</label>
                                <input type="email" formControlName="emailid" class="" name="emailid" id="emailid"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }"
                                    [ngModel]="projectDetail.emailid">
                                <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                                    <div *ngIf="f.emailid.errors.required">Please enter contact email </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Alternative Email</label>
                                <input type="email" formControlName="alteremailid" class="" name="alteremailid"
                                    id="alteremailid" class="form-control" [ngModel]="projectDetail.alteremailid">
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Mobile Number*</label>
                                <international-phone-number formControlName="mobilenumber"
                                    class="form-control phonecontrol" [ngModel]="projectDetail.mobilenumber"
                                    placeholder="Enter Mobile number"
                                    (input)="validatenumber($event.target.value,'mobile')" [maxlength]="20"
                                    [required]="true" #mobilenumber name="mobilenumber"></international-phone-number>
                                <div *ngIf="submitted && f.mobilenumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.mobilenumber.errors.required">Please enter mobile number</div>
                                </div>
                                <span *ngIf="mobilecheck===false" style="color:red">Invalid format</span>
                                <span *ngIf="mobilecheck===true" style="color:green">Valid format</span>
                            </div>

                            <div class="form-group col-lg-6">
                                <label>Phone Number</label>
                                <international-phone-number formControlName="phonenumber"
                                    class="form-control phonecontrol" [ngModel]="projectDetail.phonenumber"
                                    placeholder="Enter Phone number"
                                    (input)="validatenumber($event.target.value,'phone')" [maxlength]="20" #phonenumber
                                    name="phonenumber"></international-phone-number>
                                <span *ngIf="phonecheck===false" style="color:red">Invalid format</span>
                                <span *ngIf="phonecheck===true" style="color:green">Valid format</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn"
                        value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel"
                        (click)="closeModal('custom-project-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>

<jw-modal id="custom-project-view-1" role="dialog" class="modal">
    <div role="document" class="formgroup" *ngIf="projectDetail">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="popupLabel">Project Details</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                    (click)="closeModal('custom-project-view-1');">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="white_box_shadow pro_plan_details width100 admin_pro_footer">
                        <h4>Project Details</h4>
                        <div class="pro_footer_content">
                            <label><i class="fa fa-building-o" aria-hidden="true"></i>Project Name</label>
                            <p>{{ projectDetail.projectname}}</p>
                        </div>
                        <div class="pro_footer_content" *ngIf="projectDetail.weburl">
                            <label><i class="fa fa-globe" aria-hidden="true"></i>Website</label>
                            <p><a href="http://{{weburl}}" target="_blank">{{projectDetail.weburl}}</a></p>
                        </div>
                        <div class="pro_footer_content">
                            <label><i class="fa fa-building-o" aria-hidden="true"></i>Project Owner</label>
                            <p>{{projectDetail.contactperson}}</p>
                        </div>
                        <div class="pro_footer_content"
                            *ngIf="(projectDetail.contactrole != '') && (projectDetail.contactrole != null)">
                            <label><i class="fa fa-address-book-o" aria-hidden="true"></i>Role</label>
                            <p>{{projectDetail.contactrole}}</p>
                        </div>
                    </div>
                    <div class="white_box_shadow pro_plan_details width100 admin_pro_footer">
                        <h4>Contact Details</h4>
                        <div class="pro_footer_content">
                            <label><i class="fa fa-envelope-o" aria-hidden="true"></i>Email</label>
                            <p><a href="mailto:{{projectDetail.emailid}}">{{projectDetail.emailid}}</a></p>
                        </div>
                        <div class="pro_footer_content" *ngIf="projectDetail.alteremailid">
                            <label><i class="fa fa-envelope-o" aria-hidden="true"></i>Alternate Email</label>
                            <p><a href="mailto:{{projectDetail.alteremailid}}">{{projectDetail.alteremailid}}</a></p>
                        </div>
                        <div class="pro_footer_content">
                            <label><i class="fa fa-mobile" aria-hidden="true"></i>Mobile Number</label>
                            <p><a href="mailto:{{projectDetail.mobilenumber}}">{{projectDetail.mobilenumber}}</a></p>
                        </div>
                        <div class="pro_footer_content" *ngIf="projectDetail.phonenumber">
                            <label><i class="fa fa-phone" aria-hidden="true"></i>Phone Number</label>
                            <p>{{projectDetail.phonenumber}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel"
                    (click)="closeModal('custom-project-view-1');" />
            </div>
        </div>
    </div>
</jw-modal>