<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
      <div class="container">
        <a [routerLink]="['/reference-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to  Automated Reference Check</a>
        <div class="clearfix"></div>
          <div class="tabs" id="tabs">
              <div class="tabs__content">
                  <div class="tabs__content__item active-tab">
                      <div class="content">
                          <div class="row row-sm topheader">
                              <div class="col-lg-6">
                                  <h5 class="hedingcolor mt-3">Bulk Referencing Request Status</h5>
                              </div> 
                            <div class="col-lg-6">
                               
                                  <app-checkcountshow></app-checkcountshow>                                   
                                                                
                              </div> 
                          </div>
                        
                          <div class="row row-sm">
                              <div class="col-lg-12">
                                  <div class="job_list_section" *ngFor="let ref of csvRecords;  let i=index">
                                   
                                    <div class="job_detils_date asslist width15">
                                          <p class="job_profile"> Name</p>
                                          <p class="job_address" *ngIf="ref.candidatename">{{ref.candidatename}}</p>
                                      </div>
                                      <div class="job_detils_date asslist width15">
                                          <p class="job_profile"> Email</p>
                                          <p class="job_address" *ngIf="ref.candidateemail">{{ref.candidateemail}}</p>
                                      </div>
                                      <div class="job_detils_date asslist width15">
                                          <p class="job_profile">Phone</p>
                                          <p class="job_address" *ngIf="ref.candidatephone">{{ ref.candidatephone }}</p>
                                      </div>
                                      <div class="job_detils_date asslist width10">
                                          <p class="job_profile">Position</p>
                                          <p class="job_address" *ngIf="ref.position"> {{ ref.position }} </p>
                                      </div>
                                      <div class="job_detils_date asslist width10">
                                          <p class="job_profile">Question Profile</p>
                                          <p class="job_address" *ngIf="ref.questionprofile"> {{ ref.questionprofile }}</p>
                                      </div>
                                      <div class="job_detils_date asslist width10">
                                        <p class="job_profile">Reference Required</p>
                                        <p class="job_address" *ngIf="ref.numberofreferee"> {{ ref.numberofreferee }}</p>
                                    </div>
                                    <!-- <div class="job_detils_date asslist width10">
                                        <p class="job_profile">Message</p>
                                        <p class="job_address" *ngIf="ref.candidatemessage"> {{ ref.candidatemessage }}</p>
                                    </div> -->
                                    <div class="job_detils_date asslist width10">
                                      <p class="job_profile">Status</p>
                                      <p class="job_address" *ngIf="ref.status"> {{ ref.status }}</p>
                                    </div>
                                    <!-- <div class="job_detils_date asslist width10">
                                      <p class="job_profile">Remark</p>
                                      <p class="job_address" *ngIf="ref.remark"> {{ ref.remark }}</p>
                                    </div> -->
                                  </div>                                   
                              </div>


                             <ng-container *ngIf="csvRecords">
                              <div class="job_list_section visa_request_section" *ngIf="csvRecords.length === 0">
                                <div class="job_detils_date">
                                  <p class="job_profile">No Record Found</p>
                                </div>
                              </div>
                            </ng-container>

                           
                         


                              
                              <!-- col-8 -->
                          </div>
                          <!-- <div class="row">
                              
                              <div class="col-lg-12 text-right"  *ngIf="record > 30">
                                <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                               </div>
                            </div> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>










