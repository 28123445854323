<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
      <div class="tabs" id="tabs">
        <a [routerLink]="['/report-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true"
            class="fa fa-angle-left"></i>Back to Report Dashboard</a>
        <div class="clearfix"></div>
        <div class="tabs__content">
          <div class="tabs__content__item active-tab">
            <div class="content">
              <form [formGroup]="EmployeeForm" #employeeForm="ngForm" class="s12 white"
                (ngSubmit)="onSubmit(employeeForm.value)">
                <div class="space_em row">
                  <div class="col-lg-6">
                    <h5 class="hedingcolor">Asset List</h5>
                  </div>
                  <div class="col-lg-5 d-flex">                   
                    <div class="input-group">
                      <input [(ngModel)]="searchText" formControlName="searcText" placeholder="Search.."
                        class="form-control">
                        <button type="button" (click)="exportAsXLSX()" class="btn btn-primary com_btn ml-3">
                          Download <i class="fa fa-file-excel-o ml-2"></i>                   
                        </button>
                    </div>
                  </div>
                </div>
              </form>

              <div class="row row-sm  mg-b-10">
                <div class="col-lg-12">
                 
                </div>
              </div>
               <ng-container *ngIf="( datalist | filter: {  serialnumber: searchText }) as pageOfItems"> 
                     <div class="job_list_section idproof_request_section visa_request_section" *ngFor="let asset of pageOfItems;  let i=index">
                        <div class="job_detils_date width1">
                          <div class="visa_user_pic">
                            <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                          </div>
                        </div>
                        <div class="job_detils_date width20">
                          <div class="visa_user_details">
                            <h4 *ngIf="asset.employee"> {{asset.employee.fname}} {{asset.employee.mname}}
                              {{asset.employee.lname}}</h4>
                            <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"></i>{{ asset.employee.emailid }}
                            </p>
                            <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i> {{ asset.employee.phonenumber }}
                            </p>
                            <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i> NI Number - 
                              {{ asset.employee.ninumber }}</p>
                            <span class="badge badge-success"> </span>
                          </div>
                        </div>
                        <div class="job_detils_date width7">
                          <p class="job_address">Serial Number</p>
                          <p class="job_profile">{{asset.serialnumber}}</p>
                        </div>
                        <div class="job_detils_date width15">
                          <p class="job_address">Description</p>
                          <p class="job_profile">{{ asset.assetdescption }}</p>
                        </div>
                        <div class="job_detils_date width10">
                          <p class="job_address">Category Name</p>
                          <p class="job_profile"> {{asset.category.categoryname}}</p>
                        </div>
                        <div class="job_detils_date width10">
                          <p class="job_address">Allocation Date</p>
                          <p class="job_profile"> {{asset.statdate | date: 'dd MMM yyyy'}}</p>
                        </div>
                        <div class="job_detils_date width10">
                          <p class="job_address">Submission Date</p>
                          <p class="job_profile"> {{asset.enddate | date: 'dd MMM yyyy'}}</p>
                        </div>
                      </div>
               
                 <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                    <div class="job_detils_date">
                        <p class="job_profile">No Record Found</p>
                    </div>
                </div>      
               </ng-container>
                <div class="job_list_section visa_request_section" *ngIf="record == 0">
                    <div class="job_detils_date">
                        <p class="job_profile">No Record Found</p>
                    </div>
                </div>      
              <div class="row">
                <div class="col-lg-12 text-right">
                  <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)">
                  </jw-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>