<header class="complygate_header">
    <div class="container">
            <div class="main_header">
                <div class="hederflex">
                    <div class="mobile_toggle" (click)="toggle()">
                        <span class="lnr lnr-menu"></span>
                    </div>
                    <div class="logo">
                        <a class="navbar-brand" [routerLink]="['/overview']" *ngIf="ctype == 0">
                            <img height="45" src="{{logo}}"  alt="" *ngIf="logo != '' && logo != 'null'" />
                            <img height="45" src="assets/img/demologo.png"  alt="" *ngIf="logo == '' || logo == 'null'" />
                            <span></span></a>
                         
    
                            <a class="navbar-brand" [routerLink]="['/check-dashboard']" *ngIf="ctype ==1">
                            <img height="45" src="{{logo}}"  alt="" *ngIf="logo != '' && logo != 'null'"   />
                            <img height="45" src="assets/img/demologo.png"  alt="" *ngIf="logo == '' || logo == 'null'" />                            
                            <span></span></a>


                            <a class="navbar-brand" [routerLink]="['/check-dashboard']" *ngIf="ctype ==2">
                                <img height="45" src="{{logo}}"  alt="" *ngIf="logo != '' && logo != 'null'"   />
                                <img height="45" src="assets/img/demologo.png"  alt="" *ngIf="logo == '' || logo == 'null'" />                            
                                <span></span></a>


                                <a class="navbar-brand" [routerLink]="['/reference-dashboard']" *ngIf="ctype ==3">
                                    <img height="45" src="{{logo}}"  alt="" *ngIf="logo != '' && logo != 'null'"   />
                                    <img height="45" src="assets/img/demologo.png"  alt="" *ngIf="logo == '' || logo == 'null'" />                            
                                    <span></span></a>
                            
                    </div>
                </div>
              
                <div class="right_menu">

                    <a [routerLink]="['/check-add-credit']" *ngIf="isadmin == 'true'"><button type="submit" class="com_btn btn btn-danger borderradius25">Buy Now</button></a>

                    <!-- <a [routerLink]="['/change-plan']" *ngIf="isadmin == 'true'  && ctype == 2"><button type="submit" class="com_btn btn btn-danger borderradius25">Buy Now</button></a> -->

                    
                    <div class="notification_show pluseicons" *ngIf="isadmin == 'true'" >
                        <span class="lnr lnr-cross" (click)="righttoggle(1)" #megamenuopen></span>
                        <div class="mega_menu" #megamenu   [ngClass]="rightshow ? 'mega_menutra' : 'mega_menu'">
                            <div class="d-flex justify-content-center width100" *ngIf="rightshow">
                                
                                <div class="box">
                                    <!-- <h4>Company Profile</h4> -->
                                    <nav>
                                        <ul>
                                            <li *ngIf="ctype==0">
                                                <a [routerLink]="['/ourprofile']"  *ngIf="isadmin == 'true' &&  permission.m91 > 0"  routerLinkActive="active-link">
                                                    <span class="lnr lnr-users"></span> Company profile 
                                                </a>
                                            </li>
                                            <li *ngIf="ctype==1">
                                                <a [routerLink]="['/check-billing-invoice']"  *ngIf="isadmin == 'true' &&  permission.m86 > 0" routerLinkActive="active-link">
                                                    <span class="lnr lnr-printer"></span> Billing
                                                </a>
                                            </li>
                                            <li *ngIf="ctype==0">
                                                <a [routerLink]="['/my-billing']"  *ngIf="isadmin == 'true' &&  permission.m86 > 0" routerLinkActive="active-link">
                                                    <span class="lnr lnr-printer"></span> Billing
                                                </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/admin-list']"  *ngIf="isadmin == 'true' &&  permission.m6 > 0"  routerLinkActive="active-link">
                                                    <span class="lnr lnr-laptop-phone"></span> Admin Role
                                                </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/check-add-credit']"  *ngIf="isadmin == 'true' &&  permission.m86 > 0"  routerLinkActive="active-link">
                                                    <span class="lnr lnr-license"></span> Buy Check 
                                                </a>
                                            </li>                                             
                                       

                                        </ul>
                                    </nav>
                                </div>
                                <div class="box">
                                    <!-- <h4>Create new</h4> -->
                                    <nav>
                                        <ul>
                                            <li *ngIf="ctype==0">
                                                <a [routerLink]="['/request/request-list']"  *ngIf="isadmin == 'true' &&  permission.m15 > 0"  routerLinkActive="active-link">
                                                    <span class="lnr lnr-pencil"></span> Request 
                                                </a>
                                            </li>
                                            
                                            <li *ngIf="ctype==0">
                                                <a [routerLink]="['/our-location']"  *ngIf="isadmin == 'true' &&  permission.m10 > 0"  routerLinkActive="active-link">
                                                    <span class="lnr lnr-map-marker"></span> Location
                                                </a>
                                            </li>  
                                            <li *ngIf="ctype==0">
                                                <a [routerLink]="['/our-setting']"   *ngIf="isadmin == 'true' &&  permission.m81 > 0" routerLinkActive="active-link">
                                                    <span class="lnr lnr-cog"></span> Setting 
                                                </a>
                                            </li>
                                            <li *ngIf="ctype==0">
                                                <a [routerLink]="['/report-dashboard']"  *ngIf="isadmin == 'true' &&  permission.m11 > 0"  routerLinkActive="active-link">
                                                    <span class="lnr lnr-book"></span> Report
                                                </a>
                                            </li>
                                            <li *ngIf="ctype==1 || ctype==3">
                                                <a [routerLink]="['/check-billing-information']"  *ngIf="isadmin == 'true' &&  permission.m11 > 0"  routerLinkActive="active-link">
                                                    <span class="lnr lnr-book"></span> Company profile
                                                </a>
                                            </li>
                                            <li *ngIf="ctype==1">
                                                <a [routerLink]="['/payment-method']"  *ngIf="isadmin == 'true' &&  permission.m11 > 0"  routerLinkActive="active-link">
                                                    <span class="lnr lnr-book"></span> Payment method
                                                </a>
                                            </li>
                                            <li *ngIf="ctype==1">
                                                <a [routerLink]="['/check-support']"  *ngIf="isadmin == 'true' &&  permission.m11 > 0"  routerLinkActive="active-link">
                                                    <span class="lnr lnr-book"></span> Support
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="notification_show"  *ngIf="ctype == 0" [routerLink]="['/notification']">
                        <span class="lnr lnr-alarm"></span>
                        <span class="noti_show_count" style="display: none;">1</span>
                        <div class="tooltip_visa">
                            <p>Notification</p>
                        </div>
                    </div>
                    <div class="notification_show d-none d-sm-block">
                        <a (click)="logout()">  <span class="lnr lnr-power-switch"></span></a>
                        <div class="tooltip_visa">
                            <p >Logout</p>
                        </div>
                    </div>
                    <div class="user_profile" >
                       
                        <div class="user_name">
                            <ul >
                                <li>
                                    <div class="user_pic" >

                                        <img alt="" [src]="overviewData.imagename"  (click)="righttoggle2(2)" #megamenuopen1 height="45"
                                        *ngIf="overviewData.imagename !='' && overviewData.imagename !=null" />
                                    <img alt="" src="../../../assets/img/no-image-icon-hi.png" (click)="righttoggle2(2)" #megamenuopen1 height="45"
                                        *ngIf="overviewData.imagename =='' || overviewData.imagename == null" />
            
                                        <span class="lnr lnr-chevron-down"></span>
                                    </div>
                                   <div #megamenu1>
                                    <div class="submenu" *ngIf="rightshow2" >
                                        <div class="set_center_pic">
                                             <img alt="" [src]="overviewData.imagename" height="45"
                                             *ngIf="overviewData.imagename !='' && overviewData.imagename !=null" />
                                         <img alt="" src="../../../assets/img/no-image-icon-hi.png" height="45"
                                             *ngIf="overviewData.imagename =='' || overviewData.imagename == null" />
                                        </div>
                                        <div class="user_details text-center">
                                            <p class="name">{{Name}}</p>
                                            <p class="email">{{ overviewData.emailid }}</p>
                                        </div>
                                         <div class="text-center border-top mt-3 primaryfontfam font14">
                                             <a [routerLink]="['/update']" routerLinkActive="active-link"> 
                                                 Change Password
                                             </a>
                                         </div>
                                         <div class="text-center border-top primaryfontfam font14">
                                             <a (click)="logout()">
                                                Logout 
                                             </a>
                                         </div>
 
                                     </div>
                                   </div >
                                  
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
            </div>
    </div>
            <!-- *ngIf="isadmin == 'true'" -->
                <div class="main_menu animated fadeInDown" *ngIf="show" id="navbar">
                    <div class="show_admindash" *ngIf="ctype == 0 && isadmin == 'true' ">
                        <a (click)="menuchange('1')" style="cursor: pointer;" *ngIf="menu == '0'"> 
                             <p>Admin Dashboard</p>
                        </a>    
                        <a (click)="menuchange('0')"  *ngIf="menu == '1'"  style="cursor: pointer;"> 
                            <p>Employee Dashboard</p>
                       </a>    
                    </div>
                    <nav>

                        <ul *ngIf="ctype == 3">
                            <li routerLinkActive="active-link">
                                <a [routerLink]="['/reference-dashboard']" >
                                    <span class="lnr lnr-home"></span>
                                    <span class="menu_name">Home</span>                                  
                                </a>                                
                            </li> 
                            <li routerLinkActive="active-link">
                                <a [routerLink]="['/add-new-reference']"  >
                                    <span class="lnr lnr-history"></span>
                                    <span class="menu_name">New Reference Request</span>                                  
                                </a>                                
                            </li> 
                            <li routerLinkActive="active-link">
                                <a  [routerLink]="['/reference-status']"  >
                                    <span class="lnr lnr-clock"></span>
                                    <span class="menu_name">Reference Status</span>                                  
                                </a>                                
                            </li> 
                            <li routerLinkActive="active-link">
                                <a [routerLink]="['/reference-setting']" >
                                    <span class="lnr lnr-gift"></span>
                                    <span class="menu_name">Reference Profile</span>                                  
                                </a>                                
                            </li> 
                            <li routerLinkActive="active-link">
                                <a  [routerLink]="['/add-bulk-reference']" >
                                    <span class="lnr lnr-frame-expand"></span>
                                    <span class="menu_name">Bulk Referencing Request </span>                                  
                                </a>                                
                            </li> 
                            <li routerLinkActive="active-link">
                                <a  [routerLink]="['/bulk-reference-status']" >
                                    <span class="lnr lnr-apartment"></span>
                                    <span class="menu_name"> Bulk Referencing Request Status</span>                                  
                                </a>                                
                            </li> 

                        </ul>


                        <ul *ngIf="ctype == 1 || ctype == 2">
                            <li routerLinkActive="active-link">
                                <a [routerLink]="['/check-dashboard']" >
                                    <span class="lnr lnr-home"></span>
                                    <span class="menu_name">Home</span>                                  
                                </a>                                
                            </li>                           

                            <li routerLinkActive="active-link">
                                <!-- <span class="lnr lnr-magic-wand"></span> -->
                                <a >
                                    <span class="lnr lnr-file-empty"></span>
                                <span class="menu_name">Identity Verification</span>
                                <span class="lnr lnr-chevron-down"></span>
                                </a>

                                <ul class="submenu">                                  
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-license"></span>
                                        <a [routerLink]="['/check-employee-list', 'visa']"  [ngClass]="{'not-active_menu': ctype == 2}">                                          
                                            <span class="menu_name">BRP Check</span>
                                            <!-- <span class="lnr lnr-chevron-down"></span> -->
                                        </a>                                       
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-earth"></span>
                                        <a [routerLink]="['/check-employee-list', 'passport']"  [ngClass]="{'not-active_menu': ctype == 2}">                                          
                                            <span class="menu_name">Passport Check</span>
                                            <!-- <span class="lnr lnr-chevron-down"></span> -->
                                        </a>                                       
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-file-empty"></span>
                                        <a [routerLink]="['/check-employee-list','document']"  [ngClass]="{'not-active_menu': ctype == 2}">                                           
                                            <span class="menu_name">Driving Licence Check</span>
                                            <!-- <span class="lnr lnr-chevron-down"></span> -->
                                        </a>
                                       
                                    </li>
                                </ul>

                            </li>
                            <li routerLinkActive="active-link">
                                <a>
                                    <span class="lnr lnr-file-empty"></span>
                                    <span class="menu_name">AML, PEPs & Sanction Check</span>
                                    <span class="lnr lnr-chevron-down"></span>
                                </a>
                                <ul class="submenu">
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-checkmark-circle"></span>
                                        <a [routerLink]="['/search-personal-aml','aml']" [ngClass]="{'not-active_menu': ctype == 2}">
                                            Personal Check
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-briefcase"></span>
                                        <a [routerLink]="['/search-business-aml','aml']" [ngClass]="{'not-active_menu': ctype == 2}">
                                            Business Check
                                        </a>
                                    </li>
                                 
                                </ul>
                            </li>
                            <li routerLinkActive="active-link">
                                <a [routerLink]="['/admincreditsafe']"  class="not-active_menu">
                                    <span class="lnr lnr-database"></span>
                                    <span class="menu_name"> DBS</span>
                                    <span class="lnr lnr-chevron-down"></span>
                                </a>
                                <ul class="submenu">
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-database"></span>
                                        <a [routerLink]="['/dbs/basic']" [ngClass]="{'not-active_menu': ctype == 2}">
                                            Basic DBS
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-database"></span>
                                        <a [routerLink]="['/dbs/standard']" [ngClass]="{'not-active_menu': ctype == 2}">
                                            Standard DBS
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-database"></span>
                                        <a [routerLink]="['/dbs/enhanced']" [ngClass]="{'not-active_menu': ctype == 2}">
                                            Enhanced DBS 
                                        </a>
                                    </li>
                                    
                                 
                                </ul>
                            </li>
                           
                            <li routerLinkActive="active-link">
                                <a   [routerLink]="['/right-to-work-check']"  [ngClass]="{'not-active_menu': ctype == 2}" >
                                    <span class="lnr lnr-hand"></span>
                                    <span class="menu_name">Right To Work Check</span>
                                    <!-- <span class="lnr lnr-chevron-down"></span> -->
                                </a>
                               
                            </li>
                            <li routerLinkActive="active-link" >
                                <a    [routerLink]="['/dvla-check']"   [ngClass]="{'not-active_menu': ctype == 2}">
                                    <span class="lnr lnr-file-add"></span>
                                    <span class="menu_name">DVLA Check</span>
                                    <!-- <span class="lnr lnr-chevron-down"></span> -->
                                </a>
                               
                            </li>
                            <li routerLinkActive="active-link">
                                <a [routerLink]="['/tax-defaulter-check']"   [ngClass]="{'not-active_menu': ctype == 2}">
                                    <span class="lnr lnr-store"></span>
                                    <span class="menu_name"> Tax Defaulter</span>
                                    <!-- <span class="lnr lnr-chevron-down"></span> -->
                                </a>
                               
                            </li>
                            <li routerLinkActive="active-link">
                                <a   [routerLink]="['/adversecredit']"     [ngClass]="{'not-active_menu': ctype == 2}">
                                    <span class="lnr lnr-location"></span>
                                    <span class="menu_name">Adverse Credit</span>
                                    <!-- <span class="lnr lnr-chevron-down"></span> -->
                                </a>
                               
                            </li>
                            <li routerLinkActive="active-link">
                                <a   [routerLink]="['/company-advert-search']"    >
                                    <span class="lnr lnr-location"></span>
                                    <span class="menu_name">Business Credit</span>
                                </a>
                            </li>

                            <li routerLinkActive="active-link">
                                <a   [routerLink]="['/reference-dashboard']"   [ngClass]="{'not-active_menu': ctype == 2}"  >
                                    <span class="lnr lnr-store"></span>
                                    <span class="menu_name">Automated Reference</span>
                                </a>
                            </li>

                        </ul>


                        <ul *ngIf="ctype == 0">
                            <li *ngIf="isadmin == 'false'" routerLinkActive="active-link">
                                <a [routerLink]="['/dashboard']">
                                    <span class="lnr lnr-user"></span>
                                    <span class="menu_name">Home</span>
                                </a>
                            </li>
                            <li *ngIf="isadmin == 'true' && menu == '1'" routerLinkActive="active-link">
                                <a [routerLink]="['/admindashboard']">
                                    <span class="lnr lnr-user"></span>
                                    <span class="menu_name">Home</span>
                                </a>
                            </li>
                            <li *ngIf="isadmin == 'true' && menu == '0'" routerLinkActive="active-link">
                                <a [routerLink]="['/dashboard']">
                                    <span class="lnr lnr-user"></span>
                                    <span class="menu_name">Home</span>
                                </a>
                            </li>

                            <li routerLinkActive="active-link" *ngIf="menu == '0'">
                                <a [routerLink]="['/my-leave']" >
                                    <span class="lnr lnr-calendar-full"></span>
                                    <span class="menu_name">Leave</span>
                                    <!-- <span class="lnr lnr-chevron-down"></span> -->
                                </a>                               
                            </li>
                            <li routerLinkActive="active-link" *ngIf="menu == '0'">
                                <a [routerLink]="['/my-expense']">
                                    <span class="lnr lnr-gift"></span>
                                    <span class="menu_name">Expense</span>                                    
                                </a>                              
                            </li>
                            <li routerLinkActive="active-link" *ngIf="menu == '0'">
                                <a [routerLink]="['/my-worklist']">
                                    <span class="lnr lnr-clock"></span>
                                    <span class="menu_name">Timesheet</span>
                                    <!-- <span class="lnr lnr-chevron-down"></span> -->
                                </a>                               
                            </li>
                            <li routerLinkActive="active-link" *ngIf="menu == '0'">
                                <a [routerLink]="['/my-asset']">
                                    <span class="lnr lnr-briefcase"></span>
                                    <span class="menu_name">Asset</span>
                                </a>                               
                            </li>
                            <li routerLinkActive="active-link" *ngIf="menu == '0'">
                                <a [routerLink]="['/my-rota']">
                                    <span class="lnr lnr-sync"></span>
                                    <span class="menu_name">Rota</span>
                                </a>                               
                            </li>
                            <li  *ngIf="isline == 'true' && isadmin=='false'" routerLinkActive="active-link">
                                <a href="#" [routerLink]="['/new-request']">
                                    <span class="lnr lnr-pencil"></span>
                                    <span class="menu_name">Request</span>
                                    <span class="lnr lnr-chevron-down"></span>
                                </a>
                                <ul class="submenu">
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-calendar-full"></span>
                                        <a [routerLink]="['/leave-request']">
                                            Leave
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-calendar-full"></span>
                                        <a [routerLink]="['/leave-sick-request']">
                                            Sick Leave
                                        </a>
                                    </li>

                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-gift"></span>
                                        <a [routerLink]="['/expense-request']">
                                            Expense
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-clock"></span>
                                        <a [routerLink]="['/timesheet-request']">
                                            Timesheet
                                        </a>
                                    </li>                                  
                                </ul>
                            </li>
                            

                            <li routerLinkActive="active-link" *ngIf="isadmin == 'true' &&  permission.m7 > 0 && menu == '1'">
                                <a [routerLink]="['/listjob']">
                                    <span class="lnr lnr-briefcase"></span>
                                    <span class="menu_name">Job</span>                                    
                                </a>                              
                            </li>

                         

                            <li routerLinkActive="active-link" *ngIf="isadmin == 'true' &&  permission.m7 > 0 && menu == '1'">
                                <a [routerLink]="['/projectlist']">
                                    <span class="lnr lnr-apartment"></span>
                                    <span class="menu_name">Project</span>                                    
                                </a>                              
                            </li>

                            <li routerLinkActive="active-link" *ngIf="isadmin == 'true' &&  permission.m7 > 0 && menu == '1'">
                                <a [routerLink]="['/clientlist']">
                                    <span class="lnr lnr-menu"></span>
                                    <span class="menu_name">Client</span>                                    
                                </a>                              
                            </li>


                            <!-- <li  *ngIf="isadmin == 'true' &&  permission.m7 > 0 && menu =='1'" routerLinkActive="active-link">
                                <a >
                                    <span class="lnr lnr-briefcase"></span>
                                    <span class="menu_name">Job</span>
                                    <span class="lnr lnr-chevron-down"></span>
                                </a>
                                <ul class="submenu">
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-briefcase"></span>
                                        <a [routerLink]="['/listjob']">
                                            Add/View Jobs
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-file-add"></span>
                                        <a [routerLink]="['/projectlist']">
                                            Add/View Project
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-users"></span>
                                        <a [routerLink]="['/clientlist']">
                                            Add/View Client
                                        </a>
                                    </li>
                                </ul>
                            </li> -->

                            <li routerLinkActive="active-link" *ngIf="isadmin == 'true' &&  permission.m4 > 0 && menu == '1'">
                                <a [routerLink]="['/employeelist']">
                                    <span class="lnr lnr-users"></span>
                                    <span class="menu_name">Employee</span>                                    
                                </a>                              
                            </li>

                            <li routerLinkActive="active-link" *ngIf="isadmin == 'true' &&  permission.m4 > 0 && menu == '1'">
                                <a [routerLink]="['/employee-documentlist']">
                                    <span class="lnr lnr-file-add"></span>
                                    <span class="menu_name">Document</span>                                    
                                </a>                              
                            </li>

                            <li routerLinkActive="active-link" *ngIf="isadmin == 'true' &&  permission.m4 > 0 && menu == '1'">
                                <a [routerLink]="['/asset-list']">
                                    <span class="lnr lnr-frame-expand"></span>
                                    <span class="menu_name">Asset</span>                                    
                                </a>                              
                            </li>

                            <li routerLinkActive="active-link" *ngIf="isadmin == 'true' &&  permission.m4 > 0 && menu == '1'">
                                <a [routerLink]="['/employee-rota-list']">
                                    <span class="lnr lnr-laptop-phone"></span>
                                    <span class="menu_name">Rota</span>                                    
                                </a>                              
                            </li>

                            <!-- <li  *ngIf="isadmin == 'true' && permission.m4 > 0  && menu =='1'" routerLinkActive="active-link">
                                <a>
                                    <span class="lnr lnr-users"></span>
                                    <span class="menu_name">Employee</span>
                                    <span class="lnr lnr-chevron-down"></span>
                                </a>
                                <ul class="submenu">
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-user"></span>
                                        <a [routerLink]="['/employeelist']" >
                                            Add/View Employee
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-book"></span>
                                        <a [routerLink]="['/employee-documentlist']">
                                            Add/View Document
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-frame-expand"></span>
                                        <a [routerLink]="['/asset-list']" >
                                            Add/View Asset
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-sync"></span>
                                        <a [routerLink]="['/employee-rota-list']" >
                                            Add/View Rota
                                        </a>
                                    </li>
                                    
                                </ul>
                            </li> -->
                            <li  *ngIf="isadmin == 'true' && menu =='1'" routerLinkActive="active-link">
                                <a  [routerLink]="['/request/request-list']">
                                    <span class="lnr lnr-pencil"></span>
                                    <span class="menu_name">Request</span>
                                    <span class="lnr lnr-chevron-down"></span>
                                </a>
                                <ul class="submenu">                                  
                                    <li *ngIf="isadmin == 'true' " routerLinkActive="active-link">  
                                        <span class="lnr lnr-license"></span>                                     
                                        <a [routerLink]="['/request/visa-request-list']">
                                            Visa 
                                        </a>
                                    </li>
                                    <li  *ngIf="isadmin == 'true' " routerLinkActive="active-link">
                                        <span class="lnr lnr-earth"></span>
                                        <a [routerLink]="['/request/passport-request-list']">
                                            Passport 
                                        </a>
                                    </li>
                                    <li  *ngIf="isadmin == 'true' " routerLinkActive="active-link">
                                        <span class="lnr lnr-map"></span>
                                        <a [routerLink]="['/request/addressproof-request-list']">
                                            Address Proof 
                                        </a>
                                    </li>
                                    <li  *ngIf="isadmin == 'true' " routerLinkActive="active-link">
                                        <span class="lnr lnr-calendar-full"></span>
                                        <a [routerLink]="['/request/leave-request-list']">
                                            Leave Request
                                        </a>
                                    </li>
                                    <li  *ngIf="isadmin == 'true' " routerLinkActive="active-link">
                                        <span class="lnr lnr-calendar-full"></span>
                                        <a [routerLink]="['/request/leave-sick-request-list']">
                                            Sick Leave Request
                                        </a>
                                    </li>
                                    <li  *ngIf="isadmin == 'true' " routerLinkActive="active-link">
                                        <span class="lnr lnr-gift"></span>
                                        <a [routerLink]="['/request/expense-request-list']" >
                                            Expense Request
                                        </a>
                                    </li>
                                    <li  *ngIf="isadmin == 'true' " routerLinkActive="active-link">
                                        <span class="lnr lnr-clock"></span>
                                        <a [routerLink]="['/request/timesheet-request-list']">
                                            Timesheet Request
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li  *ngIf="isadmin == 'true' && menu == '1'" routerLinkActive="active-link">
                                <a [routerLink]="['/check-dashboard']">
                                    <span class="lnr lnr-license"></span>
                                    <span class="menu_name">Check</span>
                                    <span class="lnr lnr-chevron-down"></span>
                                </a>
                                <ul class="submenu">
                                    <li routerLinkActive="active-link" [attr.disabled]="permission.m101 < 1" *ngIf="permission.m101 > 0">
                                        <span class="lnr lnr-file-empty"></span>
                                        <a [routerLink]="['/check-dashboard',2]">
                                            Identity Verification
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link" [attr.disabled]="permission.m96 < 1" *ngIf="permission.m96 > 1">
                                        <span class="lnr lnr-license"></span>
                                        <a [routerLink]="['/check-dashboard',3]">
                                            AML & PEPs Check
                                        </a>
                                    </li>
                                   
                                    <li routerLinkActive="active-link" [attr.disabled]="permission.m109 < 1" *ngIf="permission.m109 > 0">
                                        <span class="lnr lnr-apartment"></span>
                                        <a [routerLink]="['/dbs','basic']">
                                            Basic DBS
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-apartment"></span>
                                        <a [routerLink]="['/dbs','standard']" [attr.disabled]="permission.m114 < 1" *ngIf="permission.m114 > 0">
                                            Standard DBS
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-license"></span>
                                        <a [routerLink]="['/dbs','enhanced']" [attr.disabled]="permission.m119 < 1" *ngIf="permission.m119 > 0">
                                            Enhanced DBS 
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link" >
                                        <span class="lnr lnr-frame-expand"></span>
                                        <a [routerLink]="['/right-to-work-check']" [attr.disabled]="permission.m124 < 1" *ngIf="permission.m124 > 0">
                                            Right To Work Check
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link" [attr.disabled]="permission.m129 < 1"  *ngIf="permission.m129 > 0" >
                                        <span class="lnr lnr-file-add"></span>
                                        <a   [routerLink]="['/dvla-check']" > 
                                           DVLA Check
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link" [attr.disabled]="permission.m134 < 1" *ngIf="permission.m134 > 0">
                                        <span class="lnr lnr-store"></span>
                                        <a [routerLink]="['/tax-defaulter-check']" >
                                            Tax Defaulter Check
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link" [attr.disabled]="permission.m139 < 1" *ngIf="permission.m139 > 0">
                                        <span class="lnr lnr-location"></span>
                                        <a [routerLink]="['/adversecredit']">
                                            Adverse Credit Check
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link" [attr.disabled]="permission.m139 < 1" *ngIf="permission.m139 > 0">
                                        <span class="lnr lnr-frame-expand"></span>
                                        <a [routerLink]="['/company-advert-search']">
                                            Business Credit Check
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link" [attr.disabled]="permission.m139 < 1" *ngIf="permission.m139 > 0">
                                        <span class="lnr lnr-file-add"></span>
                                        <a [routerLink]="['/reference-dashboard']">
                                            Automated Reference Check
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link" [attr.disabled]="permission.m139 < 1">
                                        <span class="lnr lnr-location"></span>
                                        <a [routerLink]="['/ai-document-verification']">
                                            AI Document Verification Check
                                        </a>
                                    </li>
                                </ul>
                            </li>                           
                            <li  *ngIf="isadmin == 'true' && permission.m86 > 0 && menu == '1'" routerLinkActive="active-link">
                                <a href="#" [routerLink]="['/our-setting']">
                                    <span class="lnr lnr-cog"></span>
                                    <span class="menu_name">Setting </span>
                                    <span class="lnr lnr-chevron-down"></span>
                                </a>
                                <ul class="submenu">
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-calendar-full"></span>
                                        <a [routerLink]="['/setting/leave-type-list']">
                                            Leave Type 
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-history"></span>
                                        <a [routerLink]="['/setting/hour-type-list']">
                                            Hour Type
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-gift"></span>
                                        <a [routerLink]="['/setting/expense-type-list']">
                                            Expense Type
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-frame-expand"></span>
                                        <a [routerLink]="['/setting/asset-category-list']">
                                            Asset Category
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-apartment"></span>
                                        <a [routerLink]="['/setting/department-type-list']">
                                            Department
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-inbox"></span>
                                        <a [routerLink]="['/setting/division-type-list']">
                                            Division
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-inbox"></span>
                                        <a [routerLink]="['/setting/leave-management']">
                                            Leave Management
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-inbox"></span>
                                        <a [routerLink]="['/setting/termination-reason']">
                                            Termination Reason
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li  *ngIf="isadmin == 'true' && permission.m11 > 0 && menu == '1'" routerLinkActive="active-link">
                                <a href="#" [routerLink]="['/report-dashboard']"  >
                                    <span class="lnr lnr-book"></span>
                                    <span class="menu_name">Report</span>
                                    <span class="lnr lnr-chevron-down"></span>
                                </a>
                                <ul class="submenu">
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-users"></span>
                                        <a [routerLink]="['/employeereport']" >
                                            Employee
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-calendar-full"></span>
                                        <a [routerLink]="['/report/leave-report-list']">
                                            Leave
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-gift"></span>
                                        <a [routerLink]="['/expreport']" >
                                            Expense
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-clock"></span>
                                        <a [routerLink]="['/timesheetreport']"  >
                                            Timesheet
                                        </a>
                                    </li>
                                    <li routerLinkActive="active-link">
                                        <span class="lnr lnr-frame-expand"></span>
                                        <a [routerLink]="['/assetreport']" >
                                            Asset
                                        </a>
                                    </li>
                                   
                                </ul>
                            </li>
                          
                        </ul>
                       
                    </nav>
                </div>
                <!-- <div class="main_menu" *ngIf="show">
                    <nav>
                        <ul>
                            <li>
                                <a [routerLink]="['/overview']">
                                    <span class="lnr lnr-user"></span>
                                    <span class="menu_name">My Profile</span>                                  
                                </a>                                
                            </li>
                            <li>
                                <a [routerLink]="['/my-leave']" >
                                    <span class="lnr lnr-calendar-full"></span>
                                    <span class="menu_name">My Leave</span>
                                    
                                </a>
                               
                            </li>
                            <li>
                                <a [routerLink]="['/my-expense']">
                                    <span class="lnr lnr-gift"></span>
                                    <span class="menu_name">My Expense</span>
                                    
                                </a>
                              
                            </li>
                            <li>
                                <a [routerLink]="['/my-worklist']">
                                    <span class="lnr lnr-clock"></span>
                                    <span class="menu_name">My Timesheet</span>
                                 
                                </a>
                               
                            </li>
                            <li>
                                <a [routerLink]="['/my-asset']">
                                    <span class="lnr lnr-briefcase"></span>
                                    <span class="menu_name">My Asset</span>
                                    
                                </a>
                               
                            </li>
                            <li>
                                <a [routerLink]="['/myrota']">
                                    <span class="lnr lnr-sync"></span>
                                    <span class="menu_name">My Rota System</span>
                                  
                                </a>
                               
                            </li>
                            <li>
                                <a href="#">
                                    <span class="lnr lnr-pencil"></span>
                                    <span class="menu_name">Requests</span>
                                    <span class="lnr lnr-chevron-down"></span>
                                </a>
                                <ul class="submenu">
                                    <li>
                                        <a [routerLink]="['/clientlist']" *ngIf="permission.m3 > 0">
                                            Client
                                        </a>
                                    </li>
                                    <li>
                                       
                                        <a [routerLink]="['/projectlist']" *ngIf="permission.m5 > 0">
                                            Project
                                        </a>
                                    </li>
                                    <li>
                                       
                                        <a [routerLink]="['/asset-list']"  *ngIf="permission.m16 > 0">
                                            Asset
                                        </a>
                                    </li>
                                    <li>                                       
                                        <a [routerLink]="['/employee-documentlist']"  *ngIf="permission.m8 > 0">
                                            Document
                                        </a>
                                    </li>
                                    <li>                                       
                                        <a [routerLink]="['/our-location']" *ngIf="permission.m10 > 0">
                                            Location
                                        </a>
                                    </li>
                                    <li>                                       
                                        <a [routerLink]="['/my-billing']" *ngIf="permission.m86 > 0">
                                            My Billing
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li *ngIf="permission.m7 > 0">
                                <a [routerLink]="['/listjob']">
                                    <span class="lnr lnr-briefcase"></span>
                                    <span class="menu_name">Job</span>
                                  
                                </a>
                               
                            </li>
                            <li *ngIf="permission.m4 > 0">
                                <a [routerLink]="['/employeelist']" >
                                    <span class="lnr lnr-users"></span>
                                    <span class="menu_name">Employee</span>
                                    <span class="lnr lnr-chevron-down"></span>
                                </a>
                                <ul class="submenu">
                                    <li>
                                        <a [routerLink]="['/clientlist']" *ngIf="permission.m3 > 0">
                                            Client
                                        </a>
                                    </li>
                                    <li>
                                       
                                        <a [routerLink]="['/projectlist']" *ngIf="permission.m5 > 0">
                                            Project
                                        </a>
                                    </li>
                                    <li>
                                       
                                        <a [routerLink]="['/asset-list']"  *ngIf="permission.m16 > 0">
                                            Asset
                                        </a>
                                    </li>
                                    <li>                                       
                                        <a [routerLink]="['/employee-documentlist']"  *ngIf="permission.m8 > 0">
                                            Document
                                        </a>
                                    </li>
                                    <li>                                       
                                        <a [routerLink]="['/our-location']" *ngIf="permission.m10 > 0">
                                            Location
                                        </a>
                                    </li>
                                    <li>                                       
                                        <a [routerLink]="['/my-billing']" *ngIf="permission.m86 > 0">
                                            My Billing
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li *ngIf="permission.m15 > 0">
                                <a [routerLink]="['/request/request-list']"  >
                                    <span class="lnr lnr-thumbs-up"></span>
                                    <span class="menu_name">Request</span>
                                 
                                </a>
                               
                            </li>
                            <li *ngIf="permission.m81 > 0">
                                <a [routerLink]="['/our-setting']" >
                                    <span class="lnr lnr-cog"></span>
                                    <span class="menu_name">Setting</span>
                                   
                                </a>
                                
                            </li>
                            <li *ngIf="permission.m11 > 0">
                                <a [routerLink]="['/report-dashboard']" >
                                    <span class="lnr lnr-pie-chart"></span>
                                    <span class="menu_name">Reports</span>
                                   
                                </a>
                             
                            </li>
                            <li *ngIf="permission.m2 > 0">
                                <a [routerLink]="['/admin-list']" >
                                    <span class="lnr lnr-power-switch"></span>
                                    <span class="menu_name">Admin</span>
                                   
                                </a>
                               
                            </li>
                            <li *ngIf="permission.m96 > 0">
                                <a [routerLink]="['/admincreditsafe']" >
                                    <span class="lnr lnr-license"></span>
                                    <span class="menu_name">AML, PEPs & Sanction</span>
                                   
                                </a>
                               
                            </li>
                          
                        </ul>
                    </nav>
                </div> -->
           
           
    
</header>
<!-- <div class="slim-header">
    <nav class="navbar navbar-expand-md navbar-light bg-light">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="slim-logo">
            <a class="navbar-brand" [routerLink]="['/dashboard']"><img height="45" src="{{logo}}"
                    alt="" /><span></span></a>
        </div>
        <a href="#" class="header-notification logoutalign show_mobile" data-toggle="dropdown" (click)="logout()"
            title="Logout"> <i class="fa fa-power-off"></i> </a>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav mr-auto">
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a [routerLink]="['/dashboard']" class="logged-user" data-toggle="dropdown"> <i
                            class="fa fa-home"></i> <span>Home</span> </a>
                </li>

                <li class="nav-item">
                    <a [routerLink]="['/check-dashboard']" class="logged-user" data-toggle="dropdown"> <i
                            class="fa fa-gavel"></i>
                        <span>Our Check</span> </a>
                </li>


                <li class="nav-item" *ngIf="isadmin=='true'">
                    <a [routerLink]="['/admindashboard']" class="logged-user" data-toggle="dropdown"> <i
                            class="fa fa-gavel"></i>
                        <span>Admin Dashboard</span> </a>
                </li>
                <li class="nav-item" *ngIf="isline=='true'">
                    <a [routerLink]="['/new-request']" class="logged-user" data-toggle="dropdown"> <i
                            class="fa fa-gavel"></i>
                        <span>Requests</span> </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link dropdown-toggle logged-user" href="#" id="navbarDropdownMenuLink" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-user"></i> <span> {{ Name }} <i class="fa fa-angle-down"></i></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" href="#">{{ overviewData.fname}} {{ overviewData.mname }}
                            {{ overviewData.lname}}</a>
                        <a class="dropdown-item" href="#">{{ overviewData.emailid}}</a>
                        <a class="dropdown-item" href="#">{{ overviewData.phonenumber}}</a>
                        <a class="logged-user show_desk" [routerLink]="['/update']">Change Password</a>
                    </div>
                </li>
                <li class="nav-item show_desk">
                    <a href="#" class="header-notification" data-toggle="dropdown" (click)="logout()" title="Logout"> <i
                            class="fa fa-power-off"></i> </a>
                </li>
                <li class="nav-item show_mobile">
                    <a class="logged-user" [routerLink]="['/update']"><i class="fa fa-key" aria-hidden="true"></i>
                        <span>Change Password</span></a>
                </li>
            </ul>
        </div>
    </nav>
</div> -->