import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topmenu',
  templateUrl: './topmenu.component.html',
  styleUrls: ['./topmenu.component.css']
})

export class TopmenuComponent implements OnInit {
  empltype: any;
  constructor() { }

  ngOnInit() {
    this.empltype =  localStorage.getItem('emptype');
    // alert(this.empltype);
    
  }
}