import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Adverse } from '../../models/adverse';
import { CheckserviceService } from '../../services/checkservice.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ChecksystemService } from '../../services/checksystem.service';
import { ModalService } from '../../_services/modal.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-adversecredit',
  templateUrl: './adversecredit.component.html',
  styleUrls: ['./adversecredit.component.css']
})
export class AdversecreditComponent implements OnInit {

  adCredFormGroup: FormGroup;
  addressForm: FormGroup;
  submitted:any;
  adCredModel: any;
  shortAdd: any = false;
  responseData: any;
  applicantDetails: any;
  applicantAddressDetails: any;
  applicantSearchAddresses: any = [];
  adFormVal: any={};
  wizard: any;
  reportData:any;
  lesssore:boolean;
  disableBtn:boolean;
  errormsg:any;
  error:any;
  searchingFields: any;
  picklist: any;
  creditrequest: any;
  jobdetails: any;
  addressconfirm: any;
  downloadtext: any;
  historyid: any;
  id:any;
  links: any;
  IBA: any;
  judgment: any;
  nocs:any;
  searchFields: any;
  searchrecord=false;
  paymentsubmit:any =0;

  constructor(
    private modalService: ModalService, 
    private router: Router,
    private alerts: AlertService,
    private formBuilder: FormBuilder,
    private checkService: CheckserviceService,    
    private check: ChecksystemService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    // this.permission = permission[0];
    this.downloadtext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';

    
    this.error =false;
    this.disableBtn =true;
   
    this.wizard =0;
    if(permission[0]['m139'] < 1)
    {
      this.router.navigate(['/permission-denied']);
    }
    this.submitted = false;
    this.adCredModel = new Adverse();
    this.adCredModel.addressoption = 'option2';
    this.shortAdd = true;
    this.adCredFormGroup = this.formBuilder.group({
      reference: [''],
      title: ['', Validators.required],
      forename: ['', Validators.required],     
      middlename: [''],     
      sirname: ['', Validators.required],
      dob: ['', Validators.required],         
      housenumber: [''],    
      housename: [''],     
      postcode: ['', Validators.required],
      addressoption: [''],
      autosearch: [''],
      hho: [''],
      flatnumber: [''],
      inlineRadioOptions: [''],
      street: [''],
      town: [''],
      locality: ['']
    });

    this.addressForm = this.formBuilder.group({
      addpicklist: ['']
    });

    var ts  = { 'serviceid': 6};    
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      if(remaindata.remiancheck <= 0)
      {
          this.lesssore =true;
      }
    });

    
    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    console.log('adverst credit', this.id)
    if(this.id){
      this.bindallcheckhistory(this.id);
    }

  }

  get g() {
    return this.addressForm.controls;
  }

  get f() {
    return this.adCredFormGroup.controls;
  }

  closeModal(id: string) 
  { 
    this.modalService.close(id);
  }

  clearAdverseCreditData()
  {
    this.adCredFormGroup.reset(
      {
        reference: '',
        title: '',
        forename: '',     
        middlename: '',     
        sirname: '',
        dob: '',         
        housenumber: '',    
        housename: '',     
        postcode: '',
        addressoption: 'option2',
        autosearch: '',
        hho: '',
        flatnumber: '',
        inlineRadioOptions: '',
        street: '',
        town: '',
        locality: ''
      }
    );
    this.submitted = false; 
  }

  onSubmit(adverseCredit: any, type: any)
  {


    this.paymentsubmit=0;

    this.error =false;
    this.submitted = true; 
    this.adFormVal = adverseCredit;
    this.submitted = true;
    if (this.adCredFormGroup.invalid) {
      return;
    }

    if(type == 'first'){
      this.disableBtn =true;
      var ts  = { 'serviceid': 6 }; 
      this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
        if(remaindata.remiancheck <= 0){   
          this.lesssore =true;
          this.modalService.open('upgrade-plan');
        } else{  
          this.disableBtn =true;
          this.lesssore =false;
          this.modalService.open('pdf-payment-confirmation');          
        }
      }); 
    } else if (type == 'payment'){

      
      if(this.paymentsubmit ==0){
        this.paymentsubmit =1;
        this.disableBtn =false;


        
    this.searchrecord =true;
     this.modalService.close('pdf-payment-confirmation');


    setTimeout(() => {



    const sd = new Date(adverseCredit.dob);
    adverseCredit.dob = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    
    this.checkService.addUpdateAdverseCredit(adverseCredit).subscribe((data: any) => {      


        if(data.success){

          
    this.searchrecord =false;
    


          if('historyid' in data){
            this.historyid = data.historyid;
          }
          var return_response = data.resp["soap:Envelope"]["soap:Body"];          
          if('Search07aResponse' in return_response){
            this.responseData = return_response["Search07aResponse"]["SearchResult"];

            this.errormsg  ='';
            
            if(this.responseData.creditrequest.applicant.name){
              this.applicantDetails = this.responseData.creditrequest.applicant.name;
            }
            if(this.responseData.creditrequest.applicant.address){
              this.applicantAddressDetails = this.responseData.creditrequest.applicant.address;
            }
            if(this.responseData.picklist.applicant.address.fullmatches.fullmatch){
              this.applicantSearchAddresses = this.responseData.picklist.applicant.address.fullmatches.fullmatch;
              if(this.applicantSearchAddresses.length){          
                this.wizard =1 ; this.disableBtn =true;         
              } else{

                
                this.wizard =2

                this.reportData = this.responseData.creditreport;


                if(this.reportData.applicant.addresslinks)
                {
                  if(this.reportData.applicant.addresslinks.links)
                  { 
                    this.links =  this.reportData.applicant.addresslinks.links.link;
                  }
                }
    
    
                if(this.responseData.creditreport.applicant.summary)
                  this.addressconfirm = this.responseData.creditreport.applicant.summary.address;
    
    
    
                // this.links =  this.reportData.applicant.addresslinks.links.link;
                
                if(this.reportData.applicant.bais)
                   this.IBA = this.reportData.applicant.bais.bai;
    
                
                   if(this.reportData.applicant.judgments){
                   if(this.reportData.applicant.judgments.judgment){
                    this.judgment = this.reportData.applicant.judgments.judgment;
                  }
                  }
    
               if(this.reportData.applicant.nocs)
                    this.nocs = this.reportData.applicant.nocs.noc;
    
    
    
                this.picklist = this.responseData.picklist;
                this.creditrequest = this.responseData.creditrequest;
                this.jobdetails = this.responseData.jobdetails;
    
                this.paymentsubmit=0;
                this.disableBtn =true; 
     
              }        
            } else{
              this.paymentsubmit=1;
              this.wizard =3;
              this.responseData = return_response["Search07aResponse"]["SearchResult"];
              this.picklist = this.responseData.picklist;
              this.creditrequest = this.responseData.creditrequest;
              this.jobdetails = this.responseData.jobdetails;                 
              this.errormsg  ='No record found for this search';

              this.alerts.error("No record found for this search.", true);
              this.disableBtn =true; 
               this.modalService.close('pdf-payment-confirmation'); 
            }
          } 
          else if('soap:Fault' in return_response){

            this.paymentsubmit=1;
            this.wizard =3;
            this.responseData = return_response["Search07aResponse"]["SearchResult"];
            this.picklist = this.responseData.picklist;
            this.creditrequest = this.responseData.creditrequest;
            this.jobdetails = this.responseData.jobdetails;   
            this.errormsg  = 'No record found for this search';this.disableBtn =true; 
            this.alerts.error("No record found for this search.", true);
             this.modalService.close('pdf-payment-confirmation');             
          }

          
        } 
        
        else{

          this.paymentsubmit=1;
          this.wizard =3;
          this.responseData = return_response["Search07aResponse"]["SearchResult"];
          this.picklist = this.responseData.picklist;
          this.creditrequest = this.responseData.creditrequest;this.disableBtn =true; 
          this.jobdetails = this.responseData.jobdetails;   
          this.errormsg  = 'We have some issue in Adverse Credit check. Please contact Administrator';
          this.alerts.error("No record found for this search.", true);
          // this.modalService.close('pdf-payment-confirmation');
        }
        
        
      });



    }, 9000); 
      
    }





    }
  }


  bindallcheckhistory(id){

    var dd = {id: id}

    this.historyid =id; 

    console.log('update code',id);
    this.checkService.checkhistory(dd).subscribe((data: any) => {

      console.log('script',data);
    if(data.success){
      this.searchFields = data.search;


      if('historyid' in data){
        this.historyid = data.historyid;
      }
     
      var return_response = data.resp["soap:Envelope"]["soap:Body"];
      
      if('Search07aResponse' in return_response){
        this.responseData = return_response["Search07aResponse"]["SearchResult"];

        if(this.responseData.creditrequest.applicant.name){
          this.applicantDetails = this.responseData.creditrequest.applicant.name;
        }

        if(this.responseData.creditrequest.applicant.address){
          this.applicantAddressDetails = this.responseData.creditrequest.applicant.address;
        }


        console.log('applicant name', this.responseData.picklist.applicant.address.fullmatches.fullmatch);


        if(this.responseData.picklist.applicant.address.fullmatches.fullmatch)
        {


          this.applicantSearchAddresses = this.responseData.picklist.applicant.address.fullmatches.fullmatch;
        
          
          if(this.applicantSearchAddresses.length){          
            this.wizard =1          
          } 
          else
          {

            console.log('Response Data', this.responseData);



            this.reportData = this.responseData.creditreport;

            if(this.reportData)
            {

            if(this.reportData.applicant.addresslinks)
            {
              if(this.reportData.applicant.addresslinks.links)
              { 
                this.links =  this.reportData.applicant.addresslinks.links.link;
              }
            }


            if(this.responseData.creditreport.applicant.summary)
              this.addressconfirm = this.responseData.creditreport.applicant.summary.address;



            // this.links =  this.reportData.applicant.addresslinks.links.link;
            
            if(this.reportData.applicant.bais)
               this.IBA = this.reportData.applicant.bais.bai;   

               if(this.reportData.applicant.judgments){
               if(this.reportData.applicant.judgments.judgment){
                this.judgment = this.reportData.applicant.judgments.judgment;
              }
              }

           if(this.reportData.applicant.nocs)
                this.nocs = this.reportData.applicant.nocs.noc;

                this.picklist = this.responseData.picklist;
                this.creditrequest = this.responseData.creditrequest;
                this.jobdetails = this.responseData.jobdetails;

                this.wizard =2
            }

          }        
        } else{
          this.wizard =3;
          this.responseData = return_response["Search07aResponse"]["SearchResult"];
          this.picklist = this.responseData.picklist;
          this.creditrequest = this.responseData.creditrequest;
          this.jobdetails = this.responseData.jobdetails;
          
          this.alerts.error("No record found for this search 2.", true);
          this.modalService.close('pdf-payment-confirmation'); 
        }
      } else if('soap:Fault' in return_response){
        this.wizard =3;
        this.responseData = return_response["Search07aResponse"]["SearchResult"];
        this.picklist = this.responseData.picklist;
        this.creditrequest = this.responseData.creditrequest;
        this.jobdetails = this.responseData.jobdetails;       

        this.alerts.error("No record found for this search 3.", true);
        this.modalService.close('pdf-payment-confirmation'); 
        
      }
    } else{
      this.wizard =3;
      this.responseData = return_response["Search07aResponse"]["SearchResult"];
      this.picklist = this.responseData.picklist;
      this.creditrequest = this.responseData.creditrequest;
      this.jobdetails = this.responseData.jobdetails;
     
      this.alerts.error("No record found for this search. 4", true);
      this.modalService.close('pdf-payment-confirmation'); 
      
    }
  });
  }
  
  checkValues(values : any[]){
    return values.some(v=>v[1].cuesData&&v[1].cuesData.length); //if any array has cuesData, some will return true
 }

  downloadAdverseCreditHistory(historyid: any, ind){
    this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
    this.checkService.adversecredithistorydownload({"id":historyid}).subscribe((searchRes: any) => {  
      this.downloadtext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
      window.open(searchRes.response_data.Location, "_blank");
      
    });
  }

  backwizard(id){
    if(id==0){
      this.submitted = false;
      this.adCredFormGroup.reset({
        reference: '',
        title: '',
        forename: '',     
        middlename: '',     
        sirname: '',
        dob: '',         
        housenumber: '',    
        housename: '',     
        postcode: '',
        addressoption: '',
        autosearch: '',
        hho: '',
        flatnumber: '',
        inlineRadioOptions: '',
        street: '',
        town: '',
        locality: ''
      }); 
    }
    this.wizard =id;this.errormsg  ='';
    this.paymentsubmit =0;this.disableBtn =true; 
  }

  changeAddress(value)
  {
    if(value=='option1')
    {
      this.shortAdd = false;
    } else if(value=='option2')
    {
      this.shortAdd = true;
    }
    
  }

  addresschangesubmit(addressSubmit: any)
  {

    console.log(addressSubmit);
    this.adFormVal.id = this.historyid;

    this.adFormVal.reference = this.adFormVal.reference;
    this.adFormVal.title = this.adFormVal.title;
    this.adFormVal.forename = this.adFormVal.forename;
    this.adFormVal.middlename = this.adFormVal.middlename;
    this.adFormVal.sirname = this.adFormVal.sirname;
    this.adFormVal.dob = this.adFormVal.dob;
    this.adFormVal.addressoption = this.adFormVal.addressoption;
    this.adFormVal.autosearch = this.adFormVal.autosearch;
    



    console.log('Update System Master',this.applicantSearchAddresses[addressSubmit.addpicklist]);


    if(this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.abodeno)
      this.adFormVal.flatnumber = this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.abodeno._text;

    if(this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.buildingno)
      this.adFormVal.buildingno = this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.buildingno._text;


    if(this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.buildingname)
      this.adFormVal.buildingname = this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.buildingname._text;


    if(this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.locality)
      this.adFormVal.locality = this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.locality._text;
    
    if(this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.street1)
      this.adFormVal.street1 = this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.street1._text;

    if(this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.posttown)  
      this.adFormVal.posttown = this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.posttown._text;

    if(this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.postcode)  
      this.adFormVal.postcode = this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.postcode._text;
    

    

    this.checkService.addUpdateAdverseCredit(this.adFormVal).subscribe((data: any) => {

      this.applicantSearchAddresses =null;

    if(data.success){
      this.searchFields = data.search;


      if('historyid' in data){
        this.historyid = data.historyid;
      }
     
      var return_response = data.resp["soap:Envelope"]["soap:Body"];
      
      if('Search07aResponse' in return_response){
        this.responseData = return_response["Search07aResponse"]["SearchResult"];

        if(this.responseData.creditrequest.applicant.name){
          this.applicantDetails = this.responseData.creditrequest.applicant.name;
        }

        if(this.responseData.creditrequest.applicant.address){
          this.applicantAddressDetails = this.responseData.creditrequest.applicant.address;
        }


        console.log('login master', this.responseData.picklist.applicant.address.fullmatches.fullmatch);


        if(this.responseData.picklist.applicant.address.fullmatches.fullmatch)
        {


          this.applicantSearchAddresses = this.responseData.picklist.applicant.address.fullmatches.fullmatch;
        
          
          if(this.applicantSearchAddresses.length){          
            this.wizard =1          
          } 
          else
          {


            this.reportData = this.responseData.creditreport;


            if(this.reportData.applicant.addresslinks)
            {
              if(this.reportData.applicant.addresslinks.links)
              { 
                this.links =  this.reportData.applicant.addresslinks.links.link;
              }
            }


            if(this.responseData.creditreport.applicant.summary)
              this.addressconfirm = this.responseData.creditreport.applicant.summary.address;



            // this.links =  this.reportData.applicant.addresslinks.links.link;
            
            if(this.reportData.applicant.bais)
               this.IBA = this.reportData.applicant.bais.bai;            
               if(this.reportData.applicant.judgments){
               if(this.reportData.applicant.judgments.judgment){
                this.judgment = this.reportData.applicant.judgments.judgment;
              }
              }

           if(this.reportData.applicant.nocs)
                this.nocs = this.reportData.applicant.nocs.noc;
                this.picklist = this.responseData.picklist;
                this.creditrequest = this.responseData.creditrequest;
                this.jobdetails = this.responseData.jobdetails;

                this.wizard =2
          }        
        } else{
          this.wizard =3;
          this.responseData = return_response["Search07aResponse"]["SearchResult"];
          this.picklist = this.responseData.picklist;
          this.creditrequest = this.responseData.creditrequest;
          this.jobdetails = this.responseData.jobdetails;
          
          this.alerts.error("No record found for this search.", true);
          this.modalService.close('pdf-payment-confirmation'); 
        }
      } else if('soap:Fault' in return_response){
        this.wizard =3;
        this.responseData = return_response["Search07aResponse"]["SearchResult"];
        this.picklist = this.responseData.picklist;
        this.creditrequest = this.responseData.creditrequest;
        this.jobdetails = this.responseData.jobdetails;       

        this.alerts.error("No record found for this search.", true);
        this.modalService.close('pdf-payment-confirmation'); 
        
      }
    } else{
      this.wizard =3;
      this.responseData = return_response["Search07aResponse"]["SearchResult"];
      this.picklist = this.responseData.picklist;
      this.creditrequest = this.responseData.creditrequest;
      this.jobdetails = this.responseData.jobdetails;
     
      this.alerts.error("No record found for this search.", true);
      this.modalService.close('pdf-payment-confirmation'); 
      
    }





      // if(data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"])
      // {
      //   this.responseData = data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"];
      //   if(this.responseData.creditrequest.applicant.name)
      //   {
      //     this.applicantDetails = this.responseData.creditrequest.applicant.name;
      //   }
      //   if(this.responseData.creditrequest.applicant.address)
      //   {
      //     this.applicantAddressDetails = this.responseData.creditrequest.applicant.address;
      //   }
      //   if(this.responseData.picklist.applicant.address.fullmatches.fullmatch)
      //   {
      //     this.applicantSearchAddresses = this.responseData.picklist.applicant.address.fullmatches.fullmatch;

      //     if(this.applicantSearchAddresses.length)
      //     {          
      //       this.wizard =1          
      //     }else
      //     {
      //       this.reportData = data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"].creditreport;
      //       // this.searchingFields = data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"].search;
      //       this.picklist = data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"].picklist;
      //       this.creditrequest = data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"].creditrequest;
      //       this.jobdetails = data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"].jobdetails;
      //       console.log(this.reportData);
            
      //       this.wizard =2
      //     }        
      //   }      
      // }      
      // console.log(data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"]);
    });





  }


  convertStringToArray = (object) => {

    console.log('check object master', typeof object);
    var listMaster: any = [];
    if(typeof object === 'object'){
      listMaster.push(object);
      return listMaster;
    }else{
      console.log(typeof object);
      object.forEach(function(entry3) { 
        listMaster.push(entry3);
      });
      return listMaster;
    }

 }
 convertStringToArrayyes = (object) => {

  var listMaster: any = [];
  if(typeof object === 'object'){ 

    console.log(object);
    
    console.log('yes');
    return 'object';
  }else{   console.log('no'); 
    return 'array';
  }

}



 

}
