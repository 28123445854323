import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { UserService } from '../services/user.service';
import { error } from 'util';
import { Message } from '../models/message.model';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../services/customer.service';
import { AlertService } from '../services/alert.service';
// import { User} from '../../shared/user.model';
// import { EmailValidator } from '@angular/forms';


import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-clientreply',
  templateUrl: './clientreply.component.html',
  styleUrls: ['./clientreply.component.css']
})

export class ClientreplyComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  isLoginError = false;
  alertService: any;
  // loading: boolean;
  user = new Message();
  access_token = '';
  userid = '';
  name = '';
  isline = '';
  planid = '';
  error = '';
  isemail = '';
  isadmin = '';
  ispermission = '';
  accesstoken: string;
  msg = '';
  success: string = '0';
  logo: any;

  constructor(private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private userService: UserService, private router: Router, private alerts: AlertService, private csservices: CustomerService, ) { }
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      message: ['', [Validators.required]],
      token: ['', [Validators.required]]
    });

    this.accesstoken = this.activatedRoute.snapshot.queryParamMap.get("token");
        this.userService.checkaccessbyreply(this.accesstoken).subscribe((data: any) => {    
      if (data.length == '0') 
      {
        this.user.token = this.accesstoken;
        this.error = 'true';
        this.msg = '';
        this.alerts.error('Invalid Token Details', true);       
      } else {
        this.user.token = this.accesstoken;
        this.error = '';
        if((data[0].logoname == "") || (data[0].logoname == null))
        {
          this.logo = "assets/img/logo.png";          
        }
        else
        {
          this.logo = data[0].logoname;
        }
        this.msg = 'true';
      }
    });
  }
  get f() { return this.registerForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.userService.userMessageReply(this.user).subscribe((data: any) => {
      this.success = '1';
      this.error ='';
      this.msg ='';
    },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
      });
  }
}