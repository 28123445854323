<div class="bg">
  <app-header></app-header>


  <div class="section-wrapper">
      <div class="container">
          <div class="tabs" id="tabs">
              <a [routerLink]="['/ai-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to AI Dashboard</a>
              <div class="clearfix"></div>
              <div class="tabs__content">
                <div class="tabs__content__item active-tab">
                    <div   class="content">
                <div class="space_em row mb-0">
                    <div class="col-lg-6">
                        <h5 class="hedingcolor">All AI Log
                        </h5>
                    </div>
                    <div class="col-lg-3 mb-2">
                        <select name="employee" #documentid (change)="changeDocumentType($event.target.value)" class="form-control input-lg">
                            <option value="">Select Document Type</option>
                            <option value="v">Visa List</option>
                            <option value="p">Passport List</option>
                            <option value="id">ID List</option>
                        </select>
                    </div>

                    <div class="col-lg-3 mb-2">
                        <select name="employee" #employid (change)="changeEmployee($event.target.value)" class="form-control input-lg">
                            <option value="">Select Employee</option>
                          <option *ngFor="let emp of employeelist" value="{{emp.employeemasterid}}" [selected]="emp.employeemasterid==empid">
                            {{ emp.fname }} {{ emp.mname }} {{ emp.lname }} ( {{ emp.employeeid }})</option>
                        </select>
                    </div>
                </div></div>

                  <div class="" *ngIf="documenttype == '' || documenttype == 'v'">
                      <div class="content">
                          <div class="space_em row">
                              <div class="col-lg-6">
                                  <h5 class="hedingcolor">Visa List
                                  </h5>
                              </div>
                              <div class="col-lg-6">
                              </div>
                          </div>
                          <div class="job_list_section visa_request_section pb-2" *ngFor="let visas of pageOfItemsvisa;">
                              <div class="job_detils_date ">
                                <div class="visa_user_pic">
                                    <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                    <img *ngIf="visas.employee.imagename" src="{{visas.employee.imagename}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                    <img *ngIf="visas.employee.gender == true && (visas.employee.imagename == null || visas.employee.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                    <img *ngIf="visas.employee.gender == false && (visas.employee.imagename == null || visas.employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                </div>
                              </div>
                              <div class="job_detils_date width22">
                                  <div class="visa_user_details">
                                      <h4>{{visas.employee.fname}} {{visas.employee.mname}} {{visas.employee.lname}}</h4>
                                      <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"></i> {{ visas.employee.emailid }}
                                      </p>
                                      <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i> {{ visas.employee.phonenumber }}</p>
                                      <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i> NI Number {{ visas.employee.ninumber }} </p>
                                      <span *ngIf="visas.employee.nationality" class="badge badge-success">{{ visas.employee.nationality.countryname }} </span>
                                  </div>
                              </div>
                              <div class="job_detils_date width15">
                                  <p class="job_address">Visa Number</p>
                                  <p class="job_profile">{{visas.visanumber}}</p>
                              </div>
                              <div class="job_detils_date width13">
                                  <p class="job_address">Start Date</p>
                                  <p class="job_profile">{{visas.visastart | date: 'dd MMM yyyy'}}</p>
                              </div>
                              <div class="job_detils_date width13">
                                  <p class="job_address">End Date</p>
                                  <p class="job_profile">{{visas.visaexpiry | date: 'dd MMM yyyy'}}</p>
                              </div>
                              <div class="job_detils_date aligncenter width10 mob">

                                  <div id="container" class="green_text" *ngIf="visas.iscurrent">
                                      <div class="dot"></div>
                                      <div class="pulse"></div>
                                  </div>
                                  <p *ngIf="visas.iscurrent" class="job_profile green_text">Current </p>
                                  <div id="container" class="" *ngIf="!visas.iscurrent">
                                      <div class="dot"></div>
                                      <div class="pulse"></div>

                                  </div>
                                  <p *ngIf="!visas.iscurrent" class="job_profile">Expired </p>

                              </div>
                              <div class="job_detils_date visa_btn viewbtnsow mob">

                                  <p class="job_address">
                                      <button type="submit" class="btn btn-primary com_btn" (click)="openModal3('pdf-viewer-visa',visas.visadocname)" *ngIf="visas.visadocname"><i class="fa fa-file-image-o"></i></button>
                                      <!-- <button type="submit" class="btn btn-danger com_btn" (click)="openModal('custom-visa-1',visas)"><i class="fa fa-pencil"></i></button>
                                      <button type="submit" class="btn btn-secondary com_btn" (click)="onDelete(visas.employeevisadeatilid)"><i class="fa fa-trash-o"></i></button> -->
                                  </p>
                              </div>
                              <div class="width100">
                                  <hr class="mb-2 mt-2"/>
                              </div>
                              <div class="width100">                                   
                                  <div class="pull-left">                                 
                                      <p class="badge badge-warning mb-0" *ngIf="visas.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Awaiting Authorisation </p>
                                      <p class="badge badge-success mb-0" *ngIf="visas.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                      <p class="badge badge-error mb-0" *ngIf="visas.isaiverify == '3'"><i class="fa fa-close"></i> Rejected by AI </p>
                                  </div>                                   
                                  <div class="pull-right">
                                      <p class="mb-0" *ngIf="aipayment ==false && visas.nationality.isvisa == true  && visas.isaiverify != '1'"> Verify the ID using Complygate AI. <a  [routerLink]="['/employeeaidetail',visas.employeevisadeatilid]" >Click Here</a></p>                                        
                                      <p class="mb-0" *ngIf="aipayment ==true && visas.nationality.isvisa == true  && visas.isaiverify != '1'"> You have exhausted the free AI ID verification. To continue using it please  <a  [routerLink]="['/employeeaidetail',visas.employeevisadeatilid]" >Click Here</a></p>  
                                      <p class="mb-0" *ngIf="visas.nationality.isvisa == false"> The AI verification functionality for this country will be available soon.</p>                                                                              
                                  </div>
                              </div>  

                          </div>
                          <div class="job_list_section visa_request_section" *ngIf="recordvisa == 0">
                            <div class="job_detils_date">
                                <p class="job_profile">No Record Found</p>
                            </div>
                        </div>

                          <div class="row">
                             
                              <div class="col-lg-12 text-right">
                                  <jw-pagination [items]="itemsvisa" [pageSize]="30" [maxPages]="10" (changePage)="onChangePagevisa($event)"></jw-pagination>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class=""  *ngIf="documenttype == '' || documenttype == 'p'">
                    <div class="content">
                        <div class="space_em row">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Passport List
                                </h5>
                            </div>
                            <div class="col-lg-6">
                            </div>
                        </div>

                        <div class="job_list_section visa_request_section pb-2" *ngFor="let passport of pageOfItemspassport;">
                            <div class="job_detils_date ">
                                <div class="visa_user_pic">
                                    <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                    <img *ngIf="passport.employee.imagename" src="{{passport.employee.imagename}}" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                    <img *ngIf="passport.employee.gender == true && (passport.employee.imagename == null || passport.employee.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                    <img *ngIf="passport.employee.gender == false && (passport.employee.imagename == null || passport.employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ passport.employee.fname }} {{ passport.employee.mname }} {{ passport.employee.lname}}">
                                </div>

                            </div>
                            <div class="job_detils_date width22">

                                <div class="visa_user_details">
                                    <h4>{{passport.employee.fname}} {{passport.employee.mname}} {{passport.employee.lname}}</h4>
                                    <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"> {{ passport.employee.emailid }}</i>
                                    </p>
                                    <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i> {{ passport.employee.phonenumber }}</p>
                                    <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i> NI Number {{ passport.employee.ninumber }} </p>
                                    <span class="badge badge-success" *ngIf="passport.nationality">{{ passport.nationality.countryname }}</span>

                                </div>
                            </div>
                            <div class="job_detils_date width15">
                                <p class="job_address">Passport Number</p>
                                <p class="job_profile">{{passport.passportnumber}}</p>
                            </div>
                            <div class="job_detils_date width13 ">
                                <p class="job_address">Issue Date</p>
                                <p class="job_profile">{{passport.passportissue | date: 'dd MMM yyyy'}}</p>
                            </div>
                            <div class="job_detils_date width13">
                                <p class="job_address">Expiry Date</p>
                                <p class="job_profile">{{passport.passportexpiry | date: 'dd MMM yyyy'}}</p>
                            </div>
                            <div class="job_detils_date aligncenter mob width10">

                                <div id="container" class="green_text" *ngIf="passport.iscurrent">
                                    <div class="dot"></div>
                                    <div class="pulse"></div>

                                </div>
                                <p class="job_profile green_text" *ngIf="passport.iscurrent">Active </p>
                                <div id="container" class="" *ngIf="!passport.iscurrent">
                                    <div class="dot"></div>
                                    <div class="pulse"></div>

                                </div>
                                <p class="job_profile" *ngIf="!passport.iscurrent">Inactive </p>

                            </div>
                            <div class="job_detils_date visa_btn viewbtnsow mob">

                                <p class="job_address">
                                    <button type="submit" class="btn btn-primary com_btn" (click)="openModal3('pdf-image-viewer',passport.passportdocname)" *ngIf="passport.passportdocname"><i class="fa fa-file-image-o"></i></button>
                                    <!-- <button type="submit" class="btn btn-danger com_btn" (click)="openModal('custom-passport-1',passport)"><i class="fa fa-pencil"></i></button>
                                    <button type="submit" class="btn btn-secondary com_btn" (click)="onDelete(passport.employeepassdeatilid)"><i class="fa fa-trash-o"></i></button> -->
                                </p>
                            </div>
                            <div class="width100">      
                                <hr class="mb-2 mt-2" />
                            </div>
                            <div class="width100">                                   
                                <div class="pull-left">                                 
                                    <p class="badge badge-warning mb-0" *ngIf="passport.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Awaiting Authorisation </p>
                                    <p class="badge badge-success mb-0" *ngIf="passport.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                    <p class="badge badge-error mb-0" *ngIf="passport.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                                </div>                                   
                                <div class="pull-right">
                                    <p class="mb-0" *ngIf="aipayment ==false && passport.nationality.ispassport == true  && passport.isaiverify != '1'"> Verify the ID using Complygate AI. <a  [routerLink]="['/passportaidetails',passport.employeepassdeatilid]" >Click Here</a></p>                                        
                                    <p class="mb-0" *ngIf="aipayment ==true && passport.nationality.ispassport == true  && passport.isaiverify != '1'"> You have exhausted the free AI ID verification. To continue using it please  <a  [routerLink]="['/passportaidetails',passport.employeepassdeatilid]" >Click Here</a></p>  
                                    <p class="mb-0" *ngIf="passport.nationality.ispassport == false"> The AI verification functionality for this country will be available soon.</p>                                                                                                                 
                                

                                   </div>
                            </div>
                        </div>
                        <div class="job_list_section visa_request_section" *ngIf="recordpassport == 0">
                            <div class="job_detils_date">
                                <p class="job_profile">No Record Found</p>
                            </div>
                        </div>
                        <div class="row">
                        
                          <div class="col-lg-12 text-right">
                              <jw-pagination [items]="itemspassport" [pageSize]="30" [maxPages]="10" (changePage)="onChangePagepassport($event)"></jw-pagination>
                          </div>
                      </div>

                    </div>
                </div>

                <div class=""  *ngIf="documenttype == '' || documenttype == 'id'">
                  <div class="content">
                      <div class="space_em row">
                          <div class="col-lg-6">
                              <h5 class="hedingcolor">ID proof List
                              </h5>
                          </div>
                          <div class="col-lg-6">
                          </div>
                      </div>
                      <div class="job_list_section idproof_request_section visa_request_section pb-2" *ngFor="let idproofs of pageOfItemsid;">
                          <div class="job_detils_date ">
                            <div class="visa_user_pic">
                                <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                <img *ngIf="idproofs.employee.imagename" src="{{idproofs.employee.imagename}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                <img *ngIf="idproofs.employee.gender == true && (idproofs.employee.imagename == null || idproofs.employee.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                <img *ngIf="idproofs.employee.gender == false && (idproofs.employee.imagename == null || idproofs.employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                            </div>
                          </div>
                          <div class="job_detils_date width22">
                              <div class="visa_user_details">
                                  <h4>{{idproofs.employee.fname}} {{idproofs.employee.mname}} {{idproofs.employee.lname}}</h4>
                                  <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"></i> {{ idproofs.employee.emailid }}
                                  </p>
                                  <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i> {{ idproofs.employee.phonenumber }}</p>
                                  <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i> NI Number {{ idproofs.employee.ninumber }} </p>
                                  <span *ngIf="idproofs.employee.nationality" class="badge badge-success">{{ idproofs.employee.nationality.countryname }} </span>
                              </div>
                          </div>
                          <div class="job_detils_date width15">
                              <p class="job_address">ID Proof Number</p>
                              <p class="job_profile">{{idproofs.idnumber}}</p>
                          </div>
                          <div class="job_detils_date width13">
                              <p class="job_address">ID Type</p>
                              <p class="job_profile">{{idproofs.documenttype.documentname}}</p>
                          </div>
                          <div class="job_detils_date width13">
                              <p class="job_address">End Date</p>
                              <p class="job_profile">{{idproofs.docexpiry | date: 'dd MMM yyyy'}}</p>
                          </div>

                          <div class="job_detils_date visa_btn">
                              <p class="job_address">
                                  <button type="submit" class="btn btn-primary com_btn" (click)="openModal3('pdf-viewer-idproof',idproofs.iddocName)" *ngIf="idproofs.iddocName"><i class="fa fa-file-image-o"></i></button>
                                  <!-- <button type="submit" class="btn btn-danger com_btn" (click)="openModal('custom-idproof-1',idproofs)"><i class="fa fa-pencil"></i></button>
                                  <button type="submit" class="btn btn-secondary com_btn" (click)="onDelete(idproofs.employeeidproofdeatilid)"><i class="fa fa-trash-o"></i></button> -->
                              </p>
                          </div>

                          
                          <div class="width100">      
                            <hr class="mb-2 mt-2" />
                        </div>
                          <div class="width100" >                                   
                              <div class="pull-left">                                 
                                  <p class="badge badge-warning mb-0" *ngIf="idproofs.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Awaiting Authorisation </p>
                                  <p class="badge badge-success mb-0" *ngIf="idproofs.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                  <p class="badge badge-error mb-0" *ngIf="idproofs.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                              </div>                                   
                              <div class="pull-right">
                                  <p class="mb-0" *ngIf="aipayment ==false && idproofs.nationality.isid == true  && idproofs.isaiverify != '1'"> Verify the ID using Complygate AI.  <a  [routerLink]="['/idproofaidetails',idproofs.employeeidproofdeatilid]" >Click Here</a></p>                                        
                                  <p class="mb-0" *ngIf="aipayment ==true && idproofs.nationality.isid == true && idproofs.isaiverify != '1'"> You have exhausted the free AI ID verification. To continue using it please  <a  [routerLink]="['/passportaidetails',idproofs.employeeidproofdeatilid]" >Click Here</a></p>                                          
                                  <p class="mb-0" *ngIf="idproofs.nationality.isid == false"> The AI verification functionality for this country will be available soon.</p>                                                                                                                                                                                            
                              </div>
                          </div>

                      </div>
                      <div class="job_list_section idproof_request_section visa_request_section" *ngIf="recordid == 0">
                        <div class="job_detils_date">
                            <p class="job_profile">No Record Found</p>
                        </div>
                      
                      </div>

                      <div class="row">

                          <div class="col-lg-12 text-right">
                              <jw-pagination [items]="itemsid" [pageSize]="30" [maxPages]="10" (changePage)="onChangePageid($event)"></jw-pagination>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
              </div>
          </div>
      </div>
  </div>
</div>

<jw-modal id="pdf-viewer-visa" class="modal" role="dialog">
  <div role="document" class="formgroup">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-viewer-visa');">
        <span aria-hidden="true">&times;</span>
      </button>
          </div>
          <div class="modal-body">
              <div class="container">
                  <div class="row">
                      <div class="col-lg-12">
                          <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="600" frameborder="0"></iframe>
                          <img *ngIf="imgsrc == true" [src]="iframesrc" width="100%" />
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</jw-modal>