
  <div class="bg">
    <app-header></app-header>
  
    <div class="section-wrapper">
      <div class="container">
        <div class="tabs" id="tabs">
  
          <div class="tabs__content">
            <div class="tabs__content__item active-tab" id="jan">
              <div class="content">
                <div class="row row-sm">
                  <div class="col-lg-12" style="margin-top:10px;">
                    &nbsp;
                  </div>
                </div>
                
                <mat-tab-group>
                  <mat-tab label="Employee Document">
                    <ng-template matTabContent>
                      <app-employeedocument></app-employeedocument>
                    </ng-template>
                  </mat-tab>
                  <mat-tab label="Applicant Document">
                    <ng-template matTabContent>
                      <app-candidatedocument></app-candidatedocument>
                    </ng-template>
                  </mat-tab>               
                </mat-tab-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>