import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refnnoe',
  templateUrl: './refnnoe.component.html',
  styleUrls: ['./refnnoe.component.css']
})
export class RefnnoeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
