import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReferenceService } from '../../services/reference.service';
import { AlertService } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChecksystemService } from '../../services/checksystem.service';


@Component({
  selector: 'app-refreedecline',
  templateUrl: './refreedecline.component.html',
  styleUrls: ['./refreedecline.component.css']
})
export class RefreedeclineComponent implements OnInit {


  token: any;
  rd: string;
  createNewRequestFormGroup: FormGroup;
  cd: string;
  declinereason: any;
  logo:any;
  token_expired: any = false;
  decline_expired: any = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private referencingmoduleService:ReferenceService,
    private alerts: AlertService,  
    private check: ChecksystemService, private formBuilder: FormBuilder,  
  ) {
    
  }

  ngOnInit() {
    this.token=this.activatedRoute.snapshot.queryParams['token']
    this.rd=this.activatedRoute.snapshot.queryParams['rd']
    // this.cd=this.activatedRoute.snapshot.queryParams['cd'] 
    localStorage.setItem('userToken1',this.token);

    this.token_expired =false;
    this.decline_expired =false;


    var datanew = {'token': this.token,id: this.rd}

    this.check.gettokenrefreestatus(datanew).subscribe((data: any) => {       
     

      console.log(data.success);
      if (data.success == true || data.success == 'true') {   
        
        
        console.log(data);


        if (data.logourl == "" || data.logourl == null)  
        {
          this.logo = "assets/img/logo.png";
        }
        else
        {
          this.logo =data.logourl;
        } 
        this.token_expired =false;
        this.decline_expired =false;              
      }

      else
      {
        this.token_expired = true;
      }
    });

  }

  declineclicked(){
    var data={
      "declinedbycandidate": "Yes",
      "declinedbycandidaterreason": this.declinereason,
      "id": this.rd,
      "token": this.token
    }
    this.referencingmoduleService.declinerefreerequest(data).subscribe((response: any)=>{
        this.alerts.success('Refrence request has been declined successfully');
        this.decline_expired = true;






    });
    
  }

}
