<app-header></app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <div class="tabs__sontent">
                <app-invioce-shared></app-invioce-shared>
                <div class="clearfix"></div>
                <div class="invoice_content_trans">
                    <div class="billing_info_section">
                        <div class="row">
                            <div class="col-md-4">
                                <h3 class="slim-card-title">Contact Detail</h3>
                                <div class="billed-to">
                                    <div class="dflex_box">
                                        <label>Email</label>
                                        <p><a href="mailto:{{ companyprofileDetail.emailid }}">{{ companyprofileDetail.emailid }}</a></p>
                                    </div>
                                    <div class="dflex_box">
                                        <label>Alternative Email</label>
                                        <p><a href="mailto:{{ companyprofileDetail.secondryemailid }}">{{ companyprofileDetail.secondryemailid }}</a></p>
                                    </div>
                                    <div class="dflex_box">
                                        <label>Mobile Number</label>
                                        <p>{{companyprofileDetail.phonenumber}}</p>
                                    </div>
                                    <div class="dflex_box">
                                        <label>Phone Number</label>
                                        <p>{{companyprofileDetail.sphonenumber}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <h3 class="slim-card-title">Licence Details</h3>
                                <div class="billed-to">
                                    <div class="dflex_box">
                                        <label>Licence No.</label>
                                        <p *ngIf="companyprofileDetail.license">{{companyprofileDetail.license.licensenumber}}</p>
                                    </div>
                                    <div class="dflex_box">
                                        <label>Licence Date</label>
                                        <p *ngIf="companyprofileDetail.license">{{ companyprofileDetail.license.applicationdate | date }}</p>
                                    </div>
                                    <div class="dflex_box">
                                        <label>Approval Date</label>
                                        <p *ngIf="companyprofileDetail.license">{{ companyprofileDetail.license.approvaldate | date }}</p>
                                    </div>
                                    <div class="dflex_box">
                                        <label>Renewal Date</label>
                                        <p *ngIf="companyprofileDetail.license"> {{ companyprofileDetail.license.cexpiredate | date }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <h3 class="slim-card-title">Plan Details</h3>
                                <div class="billed-to">
                                    <div class="dflex_box">
                                        <label>Start Date</label>
                                        <p> {{planStartDate | date}}</p>
                                    </div>
                                    <div class="dflex_box" *ngIf="companyprofileDetail.plan">
                                        <label>Plan Name</label>
                                        <p> {{companyprofileDetail.plan.planname}}</p>
                                    </div>
                                    <div class="dflex_box">
                                        <label>Payment Due On</label>
                                        <p>{{planExpiredOn | date}}</p>
                                    </div>
                                    <div class="dflex_box">
                                        <label>Renewal Date</label>
                                        <p> {{planRenewDate | date}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-7" *ngIf="ctype ==0">
                                <div class="bill_info">
                                    <div class="billing_info">
                                        <h4>Subscription Plan</h4>
                                        <a [routerLink]="['/change-plan']"> <button type="submit"
                                                class="btn btn-primary upgrade com_btn">Upgrade</button></a>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="billing_info">
                                                <p>Plan Name </p>
                                                <p>{{ companyprofileDetail.plan?.plantitle}}</p>
                                            </div>
                                            <div class="billing_info">
                                                <p>{{ companyprofileDetail.plan?.planname}}</p>
                                                <p class="green">£ {{ companyprofileDetail.plan?.prices}}</p>
                                            </div>
                                            <div class="billing_info">
                                                <p>Plan Type</p>
                                                <p *ngIf="companyprofileDetail.plan?.plantype==0">Monthly</p>
                                                <p *ngIf="companyprofileDetail.plan?.plantype==1">Yearly</p>
                                            </div>
                                            <div class="billing_info">
                                                <p>Max Employees </p>
                                                <p>{{ companyprofileDetail.plan?.totalemployee}}</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="billing_info">
                                                <p>Max Admins</p>
                                                <p>{{ companyprofileDetail.plan?.totaluser}}</p>
                                            </div>
                                            <div class="billing_info">
                                                <p>Max Jobs</p>
                                                <p>{{ companyprofileDetail.plan?.totaljobs}}</p>
                                            </div>
                                            <div class="billing_info">
                                                <p>Max Projects</p>
                                                <p>{{ companyprofileDetail.plan?.totalproject}}</p>
                                            </div>
                                            <div class="billing_info">
                                                <p>Max Clients</p>
                                                <p>{{ companyprofileDetail.plan?.totalclient}}</p>
                                            </div>
                                        </div>
                                    </div>                                 

                                </div>
                            </div>

                            <div class="col-lg-7" *ngIf="ctype === 2">
                                <div class="bill_info">
                                    <div class="billing_info">
                                        <h4>Subscription Plan</h4>
                                        <a [routerLink]="['/change-plan']"> <button type="submit"
                                                class="btn btn-primary upgrade com_btn">Upgrade</button></a>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="billing_info">
                                                <p>Plan Name </p>
                                                <p>{{ companyprofileDetail.plan?.plantitle}}</p>
                                            </div>
                                            <div class="billing_info">
                                                <p>{{ companyprofileDetail.plan?.planname}}</p>
                                                <p class="green">£ {{ companyprofileDetail.plan?.prices}}</p>
                                            </div>
                                            <div class="billing_info">
                                                <p>Plan Type</p>
                                                <p *ngIf="companyprofileDetail.plan?.plantype==0">Monthly</p>
                                                <p *ngIf="companyprofileDetail.plan?.plantype==1">Yearly</p>
                                            </div>
                                            <div class="billing_info">
                                                <p>No Of Check </p>
                                                <p>{{ companyprofileDetail.plan?.totaluser}}</p>
                                            </div>
                                        </div>
                                       
                                    </div>                                 

                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div *ngFor="let location of companylocationlist">
                                            <div class="list-group fullalign" *ngIf="location.isdefault==true">
                                                <h6 class="per-address">Billing Address</h6>
                                                <div class="list-group-item">
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <p class="mg-b-0"><i
                                                                    class="fa fa-map-marker tx-primary tx-15"></i>
                                                                {{location.address1}} </p>
                                                        </div>
                                                        <!-- media-body -->
                                                        <div class="d-flex mg-l-10 mg-t-5 wd-20">
                                                        </div>
                                                        <!-- d-flex -->
                                                    </div>
                                                    <!-- media -->
                                                </div>
                                                <div class="list-group-item"
                                                    *ngIf="location.addressid2 !='' && location.addressid2 != null">
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <p class="mg-b-0"> <i
                                                                    class="fa fa-map-marker tx-primary tx-15"></i>
                                                                {{ location.addressid2 }} </p>
                                                        </div>
                                                        <!-- media-body -->
                                                        <div class="d-flex mg-l-10 mg-t-5 wd-20">
                                                        </div>
                                                        <!-- d-flex -->
                                                    </div>
                                                    <!-- media -->
                                                </div>
                                                <div class="list-group-item">
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <p class="mg-b-0"> <i
                                                                    class="fa fa-map-marker tx-primary tx-15"></i>
                                                                {{ location.cityname }}, {{ location.statename }} </p>
                                                        </div>
                                                        <!-- media-body -->
                                                        <div class="d-flex mg-l-10 mg-t-5 wd-20">
                                                        </div>
                                                        <!-- d-flex -->
                                                    </div>
                                                    <!-- media -->
                                                </div>
                                                <div class="list-group-item">
                                                    <div class="media">
                                                        <div class="media-body">

                                                            <p class="mg-b-0"> <i
                                                                    class="fa fa-map-marker tx-primary tx-15"></i>
                                                                {{ location.country.countryname }} -
                                                                {{ location.pincode }} </p>
                                                        </div>
                                                        <!-- media-body -->
                                                        <div class="d-flex mg-l-10 mg-t-5 wd-20">

                                                        </div>
                                                        <!-- d-flex -->
                                                    </div>
                                                    <!-- media -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-4">
                                        <div class="billed-to">
                                            <h5>{{ companyprofileDetail.companyname }}</h5>
                                            <div class="dflex_box">
                                                <label>Website</label>
                                                <p><a target="_blank" href="http://{{ weburl }}">
                                                        {{ weburl  }}</a></p>
                                            </div>

                                            <div class="dflex_box">
                                                <label>Company Type</label>
                                                <p>{{ companyprofileDetail.companytype?.typename }}</p>
                                            </div>
                                            <div class="dflex_box">
                                                <label>Contact Name</label>
                                                <p>{{companyprofileDetail.clientname}}</p>
                                            </div>
                                            <div class="dflex_box">
                                                <label>Role</label>
                                                <p>{{companyprofileDetail.clietdesignation}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="ctype == 1">
                            <div class="col-lg-3">
                                <h3 class="slim-card-title">Employee Details</h3>
                                <div class="bill_info">
                                    <div class="billing_info">
                                        <p>Max Employees </p>
                                        <p>{{ companyprofileDetail.plan?.totalemployee}}</p>
                                    </div>
                                    <div class="billing_info">
                                        <p>Total Used</p>
                                        <p class="green"> {{ countcompanydata.employee}}</p>
                                    </div>
                                    <hr>
                                    <div class="billing_info">
                                        <p class="total_text">Employees Remain </p>
                                        <p class="total_price">
                                            {{companyprofileDetail.plan?.totalemployee - countcompanydata.employee}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <h3 class="slim-card-title">Job Details</h3>
                                <div class="bill_info">
                                    <div class="billing_info">
                                        <p>Max Jobs </p>
                                        <p>{{ companyprofileDetail.plan?.totaljobs}}</p>
                                    </div>
                                    <div class="billing_info">
                                        <p>Total Used</p>
                                        <p class="green">{{countcompanydata.job}}</p>
                                    </div>
                                    <hr>
                                    <div class="billing_info">
                                        <p class="total_text">Jobs Remain </p>
                                        <p class="total_price">
                                            {{companyprofileDetail.plan?.totaljobs - countcompanydata.job}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <h3 class="slim-card-title">Client Details</h3>
                                <div class="bill_info">
                                    <div class="billing_info">
                                        <p>Max Clients </p>
                                        <p>{{ companyprofileDetail.plan?.totalclient}}</p>
                                    </div>
                                    <div class="billing_info">
                                        <p>Total Used</p>
                                        <p class="green"> {{ countcompanydata.client}}</p>
                                    </div>
                                    <hr>
                                    <div class="billing_info">
                                        <p class="total_text">Clients Remain </p>
                                        <p class="total_price">
                                            {{companyprofileDetail.plan?.totalclient - countcompanydata.client}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <h3 class="slim-card-title">Project Details</h3>
                                <div class="bill_info">
                                    <div class="billing_info">
                                        <p>Max Projects </p>
                                        <p>{{ companyprofileDetail.plan?.totalproject}}</p>
                                    </div>
                                    <div class="billing_info">
                                        <p>Total Used</p>
                                        <p class="green"> {{ countcompanydata.project}}</p>
                                    </div>
                                    <hr>
                                    <div class="billing_info">
                                        <p class="total_text">Projects Remain </p>
                                        <p class="total_price">
                                            {{companyprofileDetail.plan?.totalproject - countcompanydata.project}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="bill_payment_cards">
            <div class="bill_pay">
            <h5>Payment Details</h5>
             <a href="#" class="invoice_viewall">Edit</a>
              <div class="card_box">
                 <div class="card_list">
                    <div class="cards">
                      <img src="assets/img/mastercard.png">
                      <div class="cardno">
                          <span class="cardname">8792 xxxx xxxx 1004</span>
                          <span class="cardname_text">MasterCard - Expires 05/21</span>
                      </div>
                      
                    </div>
                </div>
              </div>
              <p class="billawar">Bill on the first of every month.</p>
              <p class="billawar">Next billing on <span>July 01, 2020</span></p>
            </div>
         </div>
         <div class="bill_payment_invoice">
            <div class="bill_pay">
            <h5>Invoices </h5>
            <a href="#" class="invoice_viewall">View all</a>
             <div class="billing_info">
               <p class="invoice_cale">March 01,2019 </p>
               <p class="invoice_price_summ">$ 240.00  <i class="fa fa-download" aria-hidden="true"></i></p>
            </div>
              <div class="billing_info">
               <p class="invoice_cale">March 01,2019 </p>
               <p class="invoice_price_summ">$ 240.00  <i class="fa fa-download" aria-hidden="true"></i></p>
            </div>
            <div class="billing_info">
               <p class="invoice_cale">March 01,2019 </p>
               <p class="invoice_price_summ">$ 240.00  <i class="fa fa-download" aria-hidden="true"></i></p>
            </div>
            <div class="billing_info">
               <p class="invoice_cale">March 01,2019 </p>
               <p class="invoice_price_summ">$ 240.00  <i class="fa fa-download" aria-hidden="true"></i></p>
            </div>
            </div>
         </div> -->
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>