"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var leave_service_1 = require("../../services/leave.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var router_1 = require("@angular/router");
var LeavelimitComponent = /** @class */ (function () {
    function LeavelimitComponent(modal, router, data) {
        this.modal = modal;
        this.router = router;
        this.data = data;
    }
    LeavelimitComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.Userid = localStorage.getItem('userid');
        var sstatustype = { 'status': '1', 'id': this.Userid };
        this.data.getleavetypelist(sstatustype).subscribe(function (leavelist) {
            _this.leavetypelist = leavelist;
        });
    };
    return LeavelimitComponent;
}());
exports.LeavelimitComponent = LeavelimitComponent;
