import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-knowlagecenter',
  templateUrl: './knowlagecenter.component.html',
  styleUrls: ['./knowlagecenter.component.css']
})
export class KnowlagecenterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
