"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var notification_service_1 = require("../../services/notification.service");
var date_fns_1 = require("date-fns");
var rxjs_1 = require("rxjs");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var forms_1 = require("@angular/forms");
var angular_calendar_1 = require("angular-calendar");
var modal_service_1 = require("../../_services/modal.service");
var router_1 = require("@angular/router");
var alert_service_1 = require("../../services/alert.service");
var common_1 = require("@angular/common");
var NotificationComponent = /** @class */ (function () {
    function NotificationComponent(modal, notificationService, formBuilder, router, datePipe, modalService, alerts) {
        this.modal = modal;
        this.notificationService = notificationService;
        this.formBuilder = formBuilder;
        this.router = router;
        this.datePipe = datePipe;
        this.modalService = modalService;
        this.alerts = alerts;
        this.leaves = [];
        this.minstartDate = new Date();
        this.minendDate = new Date();
        this.leaveavailabledate = new Date();
        this.submitted = false;
        this.remarksubmit = false;
        this.defaultVal = "";
        this.activeDayIsOpen = true;
        this.view = angular_calendar_1.CalendarView.Month;
        this.CalendarView = angular_calendar_1.CalendarView;
        this.viewDate = new Date();
        this.inc = 1;
        this.refresh = new rxjs_1.Subject();
    }
    NotificationComponent.prototype.ngOnInit = function () {
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.leaveavailabledate.setDate(this.leaveavailabledate.getDate() - 31);
        this.classactive = (new Date()).getMonth().toString();
        this.month = (new Date()).getMonth() + 1;
        this.year = (new Date()).getFullYear();
        this.leaveformGroup = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            holidaytype: ['', forms_1.Validators.required],
            title: ['', forms_1.Validators.required],
            start: ['', forms_1.Validators.required],
            end: ['', forms_1.Validators.required],
            holidayremark: ['', forms_1.Validators.required],
            holidayremarkid: ['', forms_1.Validators.required]
        });
        this.remarkformGroup = this.formBuilder.group({
            remarksholidayid: ['', forms_1.Validators.required],
            holidayremark: ['', forms_1.Validators.required]
        });
        this.Userid = localStorage.getItem('userid');
        var sstatustype = { 'status': '1', 'id': this.Userid };
        // this.notificationService.getleavenotificationbyadmin().subscribe((leavelist: any) => {      
        //   this.leavetypelist = leavelist;
        // });
        this.bindallData();
        // this.bindalldatabymonth();
    };
    // bindalldatabymonth() {
    //   this.Userid = localStorage.getItem('userid');
    //   const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    //   this.data.getemployeeleavereport(data).subscribe((data: any) => {
    //     this.leavelistreport = data;
    //   });
    // }
    NotificationComponent.prototype.bindallData = function () {
        var _this = this;
        var data1 = { 'id': this.Userid };
        this.notificationService.getleavenotificationbyadmin(data1).subscribe(function (data) {
            // console.log(data);
            var i = 1;
            if (data != null) {
                data.forEach(function (element) {
                    _this.leaves.push({
                        id: _this.inc,
                        title: element.title,
                        start: new Date(element.startdate),
                        end: new Date(element.enddate),
                        color: { primary: element.hcolor, secondary: element.fcolor },
                        allDay: element.edit,
                        draggable: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true
                        }
                    });
                    _this.inc++;
                    _this.refresh.next();
                });
            }
        });
        this.notificationService.getexpensenotificationbyadmin(data1).subscribe(function (data) {
            // console.log(data);
            if (data != null) {
                data.forEach(function (element) {
                    _this.leaves.push({
                        id: _this.inc,
                        title: element.amountpaid + '-' + element.project.projectname,
                        start: new Date(element.startdate),
                        end: new Date(element.enddate),
                        color: { primary: element.hcolor, secondary: element.fcolor },
                        allDay: element.edit,
                        draggable: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true
                        }
                    });
                    _this.inc++;
                    _this.refresh.next();
                });
            }
        });
        this.notificationService.getpassportexpirydatebyadmin(data1).subscribe(function (data) {
            // console.log(data);
            if (data != null) {
                data.forEach(function (element) {
                    _this.leaves.push({
                        id: _this.inc,
                        title: 'Passport -' + element.employee.fname,
                        start: new Date(element.passportexpiry),
                        end: new Date(element.passportexpiry),
                        color: { primary: element.hcolor, secondary: element.fcolor },
                        allDay: element.edit,
                        draggable: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true
                        }
                    });
                    _this.inc++;
                    _this.refresh.next();
                });
            }
        });
        this.notificationService.getvisaexpirydatebyadmin(data1).subscribe(function (data) {
            // console.log(data);
            if (data != null) {
                data.forEach(function (element) {
                    _this.leaves.push({
                        id: _this.inc,
                        title: 'Visa -' + element.employee.fname,
                        start: new Date(element.visaexpiry),
                        end: new Date(element.visaexpiry),
                        color: { primary: element.hcolor, secondary: element.fcolor },
                        allDay: element.edit,
                        draggable: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true
                        }
                    });
                    _this.inc++;
                    _this.refresh.next();
                });
            }
        });
        this.notificationService.getcosexpirydatebyadmin(data1).subscribe(function (data) {
            // console.log(data);
            if (data != null) {
                data.forEach(function (element) {
                    _this.leaves.push({
                        id: _this.inc,
                        title: 'Cos -' + element.employee.fname,
                        start: new Date(element.cosexpirydate),
                        end: new Date(element.cosexpirydate),
                        color: { primary: element.hcolor, secondary: element.fcolor },
                        allDay: element.edit,
                        draggable: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true
                        }
                    });
                    _this.inc++;
                    _this.refresh.next();
                });
            }
        });
    };
    NotificationComponent.prototype.addMonthsOfYear = function (inc) {
        this.classactive = inc;
        var date = this.viewDate;
        date = date_fns_1.setMonth(date, inc);
        date = date_fns_1.setYear(date, this.year);
        this.viewDate = date;
        this.month = inc + 1;
        this.bindalldatabymonth();
    };
    NotificationComponent.prototype.closemessageModal = function (id) {
    };
    NotificationComponent.prototype.bindalldatabymonth = function () {
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
        // this.data.getemployeeleavereport(data).subscribe((data: any) => {
        //   this.leavelistreport = data;
        // });
    };
    NotificationComponent.prototype.dayClicked = function (_a) {
        var date = _a.date, events = _a.events;
        if (date_fns_1.isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            if ((date_fns_1.isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
                this.activeDayIsOpen = false;
            }
            else {
                this.activeDayIsOpen = true;
            }
        }
    };
    NotificationComponent.prototype.showAllEvents = function (id, data) {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        data = this.datePipe.transform(new Date(data), "dd MMM yyyy");
        var databydate = { 'id': this.Userid, 'startdate': data };
        this.notificationService.getallnotificationbydatebyadmin(databydate).subscribe(function (notificationdata) {
            _this.notifications = notificationdata;
        });
        this.modalService.open(id);
    };
    NotificationComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return NotificationComponent;
}());
exports.NotificationComponent = NotificationComponent;
