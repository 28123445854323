import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AppGlobals } from '../../app.global';
import { User } from '../../models/user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { CustomerService } from '../../services/customer.service';
import { Profile } from '../../models/overview/profile.model';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-updatepassword',
  templateUrl: './updatepassword.component.html',
  styleUrls: ['./updatepassword.component.css']
})

export class UpdatepasswordComponent implements OnInit {
  user = new User();
  isLoginError = false;
  msg: string = null;
  alertService: any;
  passwordForm: FormGroup;
  loader: boolean;
  submitted: any;
  disableBtn: any;
  error: boolean;
  // loading: boolean;
  constructor(private userService: UserService, private data: CustomerService, private router: Router, private fb: FormBuilder, private alerts: AlertService) {
    this.passwordForm = this.fb.group({
      oldPassword: [null, [Validators.required, Validators.minLength(6)]],
      newPassword: [null, [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      confirmPassword: [null, [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]]
    }, { validator: this.checkPasswords });
  }
  overviewData = new Profile();
  ngOnInit() {
    this.submitted = false;
    this.disableBtn = true;
    this.data.getOverview().subscribe((data: any) => {
      this.overviewData = data;
    });
  }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.newPassword.value;
    let confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true }
  }
  get f() { return this.passwordForm.controls; }

  Reset() {
    this.submitted = true;
    if (this.passwordForm.invalid) {
      return;
    }
    this.disableBtn = false;

    if (this.passwordForm.valid && this.passwordForm.controls['newPassword'].value == this.passwordForm.controls['confirmPassword'].value) {
      const resetapi = AppGlobals.API_ENDPOINT + '/api/Users/change-password';
      this.userService.resetPassword(resetapi, this.user).subscribe((data2: any) => {
        this.data.updatepassword().subscribe((data3: any) => {
          this.alerts.success('Password changed successfully', true);
          this.error = false;
          this.router.navigate(['/dashboard']);
          this.msg = 'success';
        });
      },
        (err: HttpErrorResponse) => {


          console.log(err);
          
          this.alerts.error("Please enter correct current password", true);
          this.error = false;
          this.isLoginError = true;
          this.disableBtn = true;
        });
    }
    else {
      this.error = false;
      this.disableBtn = true;
    }
  }
}