"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var PaymentService = /** @class */ (function () {
    function PaymentService(commonService) {
        this.commonService = commonService;
    }
    PaymentService.prototype.createCustomer = function (data) {
        return this.commonService.formpost('/createcustomer', data);
    };
    PaymentService.prototype.subscriptionsuccess = function (data) {
        return this.commonService.formpost('/subscriptionsuccess', data);
    };
    PaymentService.prototype.upgradeuserpaymentByCustomer = function (data) {
        return this.commonService.formpost('/check/upgradeuserpaymentByCustomer', data);
    };
    PaymentService.prototype.upgradeuserpaymentBysuccess = function (data) {
        return this.commonService.formpost('/check/subscribepackagebysuccess', data);
    };
    PaymentService.prototype.customerCharge = function (data) {
        return this.commonService.formpost('/createcustomercharge', data);
    };
    PaymentService.prototype.createSubscription = function (data) {
        return this.commonService.formpost('/createcustomersubscription', data);
    };
    PaymentService.prototype.getTotalCards = function () {
        return this.commonService.formpostOnlyid('/gettotalcards');
    };
    PaymentService.prototype.removeCard = function (data) {
        return this.commonService.formpost('/removecard', data);
    };
    PaymentService.prototype.addnewCard = function (data) {
        return this.commonService.formpost('/addnewcard', data);
    };
    PaymentService.prototype.updateCard = function (data) {
        return this.commonService.formpost('/updatecard', data);
    };
    PaymentService.prototype.defaultCardset = function (data) {
        return this.commonService.formpost('/changedefaultcard', data);
    };
    PaymentService.ngInjectableDef = i0.defineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.inject(i1.CommonService)); }, token: PaymentService, providedIn: "root" });
    return PaymentService;
}());
exports.PaymentService = PaymentService;
