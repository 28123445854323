"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var CompensationService = /** @class */ (function () {
    function CompensationService(commonService) {
        this.commonService = commonService;
    }
    CompensationService.prototype.getemployeecompensationbyadmin = function (data) {
        return this.commonService.formpost('/employee/employeecompensationlistbyadmin', data);
    };
    CompensationService.prototype.getemployeecompensation = function (data) {
        return this.commonService.formpost('/employee/employeecompensationlist', data);
    };
    CompensationService.prototype.employeecompensationUpdatebyadmin = function (data) {
        return this.commonService.formpost('/employee/updateemployeecompensationbyadmin', data);
    };
    CompensationService.prototype.employeecompensationUpdate = function (data) {
        return this.commonService.formpost('/employee/updateemployeecompensation', data);
    };
    CompensationService.prototype.employeecompensationDeletebyadmin = function (data) {
        return this.commonService.formpost('/employee/deletemployeecompensationbyadmin', data);
    };
    CompensationService.prototype.employeecompensationDelete = function (data) {
        return this.commonService.formpost('/employee/deletemployeecompensation', data);
    };
    CompensationService.ngInjectableDef = i0.defineInjectable({ factory: function CompensationService_Factory() { return new CompensationService(i0.inject(i1.CommonService)); }, token: CompensationService, providedIn: "root" });
    return CompensationService;
}());
exports.CompensationService = CompensationService;
