"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var http_1 = require("@angular/common/http");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./common.service");
var ReferenceService = /** @class */ (function () {
    function ReferenceService(httpClients, commonService) {
        this.httpClients = httpClients;
        this.commonService = commonService;
    }
    ReferenceService.prototype.getemplatelistBycompany = function () {
        return this.commonService.formget('/company/referenceprofilelist');
    };
    ReferenceService.prototype.createNewRequest = function (data) {
        return this.commonService.formpost('/reference/referencerequest', data);
    };
    ReferenceService.prototype.createBulkRequests = function (data) {
        return this.commonService.formpost('/reference/bulkreferencerequest', data);
    };
    ReferenceService.prototype.getreferencerequestall = function (data) {
        return this.commonService.formpost('/reference/referencerequestbyadmin', data);
    };
    ReferenceService.prototype.downloadReferee = function (data) {
        return this.commonService.formpost('/referee/downloadreferee', data);
    };
    ReferenceService.prototype.downloadAllReferee = function (data) {
        return this.commonService.formpost('/referee/downloadallreferee', data);
    };
    ReferenceService.prototype.updatereferencerequest = function (data) {
        return this.commonService.formpost('/referencerequestpatchcall', data);
    };
    ReferenceService.prototype.getrefereedetail = function (data) {
        return this.commonService.refereeformget('/reference/referee/' + data);
    };
    ReferenceService.prototype.referencecreatedby = function (data) {
        return this.commonService.formpost('/company/referencecreatedby', data);
    };
    ReferenceService.prototype.getrefereeresponse = function (data) {
        return this.commonService.formget('/reference/refereeresponse/' + data);
    };
    ReferenceService.prototype.getrefereesetting = function () {
        return this.commonService.formget('/company/referencesetting');
    };
    ReferenceService.prototype.postrefereesetting = function (data) {
        return this.commonService.formpost('/company/createandupdatereferencesetting', data);
    };
    ReferenceService.prototype.listCredits = function (data) {
        return this.commonService.formpost('/referee', data);
    };
    // downloadfile(filename, token): Observable<ArrayBuffer> {
    //   let params = new HttpParams();
    //   params = params.append('access_token', token);
    //   return this.httpClients.get(this.apiRoutes.downloadfile + '/' + filename, { params: params, responseType: 'arraybuffer' }).pipe(
    //     map((file: ArrayBuffer) => {
    //       return file;
    //     })
    //   );
    // }
    ReferenceService.prototype.getreferee = function (data) {
        return this.commonService.formpostwithouttoken('/referee/getallrefereedata', data);
    };
    ReferenceService.prototype.getdesignations = function () {
        return this.commonService.formgetwithouttoken('/getdesignations');
    };
    ReferenceService.prototype.getreferencerequest = function (rd) {
        return this.commonService.refereeformget('/reference/referencerequest/' + rd);
    };
    ReferenceService.prototype.getcompanydetail = function (data) {
        return this.commonService.formpost('/referee', data);
    };
    ReferenceService.prototype.createbulkreferencerequests = function (data) {
        return this.commonService.formpost('/referee', data);
    };
    ReferenceService.prototype.getCompniesCreditSummary = function (data) {
        return this.commonService.formpost('/referee', data);
    };
    ReferenceService.prototype.getbulkreferencerequests = function () {
        return this.commonService.formget('/reference/bulkreferencerequest');
    };
    ReferenceService.prototype.createreferee = function (data) {
        return this.commonService.refereeformpost('/reference/referee', data);
    };
    // getreferencerequest(data): Observable<any> {
    //   return this.commonService.formpost('/referee',data);
    // }
    ReferenceService.prototype.getrefereedetailbyId = function (data) {
        return this.commonService.formpost('/referee', data);
    };
    ReferenceService.prototype.updatereferee = function (data) {
        return this.commonService.formpatchwithouttoken('/reference/referee', data);
    };
    ReferenceService.prototype.createrefereeresponse = function (data) {
        return this.commonService.formpostwithouttoken('/reference/refereeresponse', data);
    };
    ReferenceService.prototype.updatequestionrequests = function (data) {
        return this.commonService.formpatch('/reference/modulequestiontemplatedetail', data);
    };
    ReferenceService.prototype.postquestiontemplates = function (data) {
        return this.commonService.formpost('/reference/modulequestiontemplate', data);
    };
    ReferenceService.prototype.getquestiontemplatedetails = function (data) {
        return this.commonService.formget('/reference/modulequestiontemplatedetail/' + data);
    };
    ReferenceService.prototype.createquestionrequests = function (data) {
        console.log(data);
        return this.commonService.formpost('/reference/modulequestionadd', data);
    };
    ReferenceService.prototype.deletequestionequests = function (data) {
        return this.commonService.formpatch('/reference/modulequestiontemplatedetail', data);
    };
    ReferenceService.prototype.getquestiontemplates = function () {
        return this.commonService.formget('/reference/modulequestiontemplate');
    };
    ReferenceService.prototype.createNewTemplate = function (data) {
        return this.commonService.formpost('/reference/modulequestiontemplate', data);
    };
    ReferenceService.prototype.updateTemplate = function (data) {
        return this.commonService.formpatch('/reference/modulequestiontemplate', data);
    };
    ReferenceService.prototype.deleteTemplate = function (data) {
        return this.commonService.formpatch('/reference/modulequestiontemplate', data);
    };
    ReferenceService.prototype.resendReferenceEmail = function (data) {
        return this.commonService.formpost('/referece/resendemail', data);
    };
    ReferenceService.prototype.resendRefereeEmail = function (data) {
        return this.commonService.refereeformpost('/referee/resendemail', data);
    };
    ReferenceService.prototype.declinereferencerequest = function (data) {
        return this.commonService.refereeformpatch('/company/referencerequest', data);
    };
    ReferenceService.prototype.declinerefreerequest = function (data) {
        return this.commonService.refereeformpatch('/company/refreedecline', data);
    };
    ReferenceService.ngInjectableDef = i0.defineInjectable({ factory: function ReferenceService_Factory() { return new ReferenceService(i0.inject(i1.HttpClient), i0.inject(i2.CommonService)); }, token: ReferenceService, providedIn: "root" });
    return ReferenceService;
}());
exports.ReferenceService = ReferenceService;
