<div class="signin-wrapper">
  <div class="new_login">
      <div class="login_section">
          <a class="login_logo"  [routerLink]="['/']" ><img src="/assets/img/logo.png" alt=""></a>
          <!-- <a   class="header-notification float-right" data-toggle="dropdown" (click)="logout()"
              title="Logout"> <i class="fa fa-power-off"></i> </a> -->
          <div class="signin-box">
              <form [formGroup]="registerForm" #loginForm class="login_form" (ngSubmit)="onSubmit()" *ngIf="!showsuccess">
                  <!-- <p class="text-muted text-center" id="msg" runat="server">Your current mobile number is <b>{{ email2 }}</b>. If it's wrong then Please enter correct mobile number below</p> -->
                  <!-- <div class="form-group mg-b-30" *ngIf="show">
                      <p class="red">This mobile number already exists. Please try another mobile number.</p>
                  </div> -->

                  <div class="form-group mg-b-30">
                      <div class="input-group">
                        <input type="email" formControlName="email" #Email [(ngModel)]="user.email"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                        placeholder="Please Enter Email">
                      <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-lock"
                                aria-hidden="true"></i></span>
                             
                        </div>
                        <span class="clearfix"></span>
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Please enter Email</div>
                          <div *ngIf="f.email.errors.email">Please enter valid email</div>
                       </div>
                      </div>
                  </div>
                  <div class="form-group m-t-10 mb-0 row">
                      <button class="btn btn-primary btn-block btn-signin" type="submit">Submit</button>
                      <p class="mg-b-0 usefulllink col-sm-12"><a [routerLink]="['/login']" class="fortgot text-center col-sm-12">Back to login</a></p>
                  </div>               
              </form>

              <div class="form-group mg-b-30" *ngIf="showsuccess">
                  <p class="red">Check your email for a username.
                    <a [routerLink]="['/login']"
                          class="text-muted text-center hand" style="color:#ff322e !important;">Click Here</a> to log in to your account.</p>
              </div>
          </div>
      </div>
      <div class="graphic_section">
          <div class="slogan">
              <h4>Welcome To <b>Complygate</b></h4>
              <span>Commit. Control. Comply</span>
          </div>
      </div>
      <div class="clearfix"></div>
  </div>
</div>