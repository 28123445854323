import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobService } from '../../../../services/job.service';
import { CandidateService } from '../../../../services/candidate.service';
import { Jobs } from '../../../../models/jobs/jobs.model';
import { Candidate, Document,candidatenotes,status } from '../../../../models/jobs/candidate.model';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from '../../../../_services/modal.service';
import { ApplicantService } from '../../../../services/applicant.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Emailer } from '../../../../models/emailer.model';
import { AlertService } from '../../../../services/alert.service';
import { UploadFileService } from './../../../../services/upload-file.service';
import { CommonService } from '../../../../services/common.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Router } from '@angular/router';

@Component({
  selector: 'app-candidatedetails',
  templateUrl: './candidatedetails.component.html', 
  styleUrls: ['./candidatedetails.component.css']
})
export class CandidatedetailsComponent implements OnInit {

  jobid: string;
  candidateid: any;
  Userid: any;
  skilllist: any;
  tempArr: any = { "skills": [] };
  jobdata = new Jobs();
  candidateDetail = new Candidate();
  communicationHistory: any;
  imgsrc: any;
  iframesrc: any;
  record: number;
  file: any;
  filesizeerror: any;
  fileformaterror: any;
  phonecheck: any;
  defaultVal: any = "";
  selectedFiles: FileList;
  ismail: boolean;
  emailerDetail = new Emailer();
  disableBtn: any;buttoninsert: any;
  documentDetail = new Document();
  candidateformGroup: FormGroup;
  candidatemessageFormGroup: FormGroup;
  candidateintmessageformGroup: FormGroup;
  data:any;
  public Editor = ClassicEditor;
  imgresumesrc: any;
  iframeresumesrc: any;
  submitted: boolean;
  smarks:any;
  prvbutton: any;
  nextbutton: any;
  currentbutton: any;

  candidatestatuslist: any;

  statusname: any='';
  totalpr: any=0;
  totalcandidate: any=0;
  totalreview: any =0;

  
  filename: any;

  constructor(
    private sanitizer: DomSanitizer,
    private job: JobService,
    private alerts: AlertService,
    private applicantservices: ApplicantService,
    private candidate: CandidateService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private uploadService: UploadFileService,
    private modalService: ModalService,
    private commonService: CommonService, 
    private router: Router,
    private route: ActivatedRoute
  ) { route.params.subscribe(params => {

    this.jobdata = new Jobs();
    this.jobid = params['jobid'];
    if(!isNaN(Number(params['candidateid'])))
    {
      this.candidateid = params['candidateid'];
    } else{
      this.router.navigate(['/404']);
    }
    
    this.Userid = localStorage.getItem('userid');

    this.Pageloadtimesetup();

  }); }

  ngOnInit() {

    this.ismail = false;
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    

    this.getcandidateformgroup();

    this.candidatemessageFormGroup = this.formBuilder.group({
      candidateid: [''],
      jobid: [''],
      location: [''],     
      subject: ['', Validators.required],
      message: [''] ,
      interviewstime: [''],
      interviewetime: [''],
      interdate: [''],
      ismail: [''],
      ssid: ['']
    });


  }

  Pageloadtimesetup(){

    var cand = this.candidateid;
    var indexlist;


    this.applicantservices.getcandidatestatus().subscribe((candidatestatuslistget: any) => {
      this.candidatestatuslist = candidatestatuslistget;
    });


    var totalreview=0;
    var totalcandidate=0;
    var totalpr=0;
    

    this.job.getjobById({ "id": this.Userid, "jobid": this.jobid }).subscribe((jobdata: any) => {
      this.jobdata = jobdata;
      

      
      jobdata.candidate.forEach(function (job, index) {        
          if(job.candidatemasterid == cand )
          {
            indexlist = index;          
          }       
          if(job.cstatus >0){
           totalreview = totalreview+1;
          }
        });
        this.totalcandidate = jobdata.candidate.length;

        this.totalreview =totalreview;

        this.totalpr = 100 * (Number(totalreview) / Number(this.totalcandidate));

        this.totalpr =this.totalpr.toFixed(0);

        
        if(indexlist == 0 && jobdata.candidate.length >1)
        {
          this.prvbutton =0;
          this.nextbutton = jobdata.candidate[indexlist+1].candidatemasterid;
        }else
        if(indexlist == (jobdata.candidate.length-1) && jobdata.candidate.length >1)
        {
          this.prvbutton = jobdata.candidate[indexlist-1].candidatemasterid;
          this.nextbutton = 0;
        }else {
          this.prvbutton = jobdata.candidate[indexlist-1].candidatemasterid;
          this.nextbutton = jobdata.candidate[indexlist+1].candidatemasterid;
        }

        // console.log(this.prvbutton +' rahul kumar tanwar '+ this.nextbutton);
        
    });

    this.totalpr  =totalpr;
    this.totalreview =totalreview
    this.totalcandidate =totalcandidate;
    

    this.job.getjobskillList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
      var dataskillArray = [];
      data.forEach(skil => {
        dataskillArray.push({ 'jobskilldetailid': skil.jobskilldetailid, 'skillname': skil.skillname, 'check': false });
      })
      this.skilllist = dataskillArray;
      this.showSkills(this.candidateid);
    });

    this.candidate.getcandidateCommunicationHistory({"id": this.Userid, "candidateid": this.candidateid}).subscribe((data: any) => {
      this.communicationHistory = data;
    });




  }

  perstangeofdiv(){
    return this.totalpr;
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;      
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }
  

  
  selectFile(event) {
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = files[0];

    this.file = this.selectedFiles;
    var filetype = this.file.type.split('/');

    if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") && (filetype[filetype.length - 1].toLowerCase() != "gif") && (filetype[filetype.length - 1].toLowerCase() != "tif") && (filetype[filetype.length - 1].toLowerCase() != "pdf") && (filetype[filetype.length - 1].toLowerCase() != "doc") && (filetype[filetype.length - 1].toLowerCase() != "docx") && (filetype[filetype.length - 1].toLowerCase() != "msword")) {
 this.fileformaterror = true;
    }
    else{
      this.fileformaterror =false;
    }
  }

  getcandidateformgroup()
  {    
    this.candidateformGroup = this.formBuilder.group({
      candidatemasterid: [''],
      jobid: [''],
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: [''],
      joburlid: ['']
    });
  }

  
  get f() { return this.candidateformGroup.controls; }
  
  showpage(data: any) {
    
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1].toLowerCase();
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgresumesrc = true;
      this.iframeresumesrc = data;
    }
    else {
      this.imgresumesrc = false;
      this.iframeresumesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
  }

  
  get g() { return this.candidatemessageFormGroup.controls; }
  
  onChangeinterested(sid: any, candidateid: any)
  {
    var data = {"sid": sid, "candidateid": candidateid}    ;
    

    this.candidate.candidatestatusinterested(data).subscribe((data: any) => {
      this.candidate.getcandidateById({ "id": this.Userid, "candidateid": this.candidateid }).subscribe((data: any) => {
        this.candidateDetail = data;
      })
    });
  }

  skillupdate(jobcandidateskillid: any,check: boolean){

    if(check == true){
      
      var data5 = {'candidateid': this.candidateid, 'jobskilldetailid':jobcandidateskillid,'jobid': this.jobid };
      this.candidate.candidateskilladd(data5).subscribe((data3: any) => {
        this.smarks = data3.smarks;

        this.showSkills(this.candidateid);
        // this.job.getjobskillList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
        //   var dataskillArray = [];
        //   data.forEach(skil => {
        //     dataskillArray.push({ 'jobskilldetailid': skil.jobskilldetailid, 'skillname': skil.skillname, 'check': false });
        //   })
        //   this.skilllist = dataskillArray;
        //   this.showSkills(this.candidateid);
        // });

      });

    }else if(check == false)
    {
      var data6 = {'candidateid': this.candidateid, 'jobskilldetailid':jobcandidateskillid,'jobid': this.jobid };
      this.candidate.candidateskilldelete(data6).subscribe((data2: any) => {

        this.smarks = data2.smarks;this.showSkills(this.candidateid);
        // this.job.getjobskillList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
        //   var dataskillArray = [];
        //   data.forEach(skil => {
        //     dataskillArray.push({ 'jobskilldetailid': skil.jobskilldetailid, 'skillname': skil.skillname, 'check': false });
        //   })
        //   this.skilllist = dataskillArray;
        //   this.showSkills(this.candidateid);
        // });

      });
    }
  }


  showSkills(candidateid: string) {
    this.tempArr = { "skills": [] };
    this.candidate.getcandidateById({ "id": this.Userid, "candidateid": candidateid }).subscribe((data: any) => {
      this.smarks = data.smarks;
      var candskills = [];
      data.skill.forEach(ele => {
        candskills.push(ele.jobskilldetailid);
      })
      var allskill = this.skilllist;
      var newSkillArray = [];
      allskill.forEach(element => {
        if (candskills.includes(element.jobskilldetailid.toString()) == false) {
          newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': false });
        }
        else {
          this.tempArr.skills.push(element.jobskilldetailid);
          newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': true });
        }
      });
      this.skilllist = newSkillArray;
      this.candidateDetail = data;
      console.log(data);
     
      this.statusname = data.status.statusname;
      if(data.cvname)
      {
        this.showpage(data.cvname);
      }
      
    });
  }


  
  openCandidateModal(candidateid: string, modelid: string) {
    this.disableBtn = true;
    this.tempArr = { "skills": [] };
    this.candidate.getcandidateById({ "id": this.Userid, "candidateid": candidateid }).subscribe((data: any) => {
      var candskills = [];
      data.skill.forEach(ele => {
        candskills.push(ele.jobskilldetailid);
      })
      var allskill = this.skilllist;
      var newSkillArray = [];
      allskill.forEach(element => {
        if (candskills.includes(element.jobskilldetailid.toString()) == false) {
          newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': false });
        }
        else {
          this.tempArr.skills.push(element.jobskilldetailid);
          newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': true });
        }
      });
      this.skilllist = newSkillArray;
      this.candidateDetail = data;
    });
    this.buttoninsert = "Update";
    this.modalService.open(modelid);
  }

  
  openModal6(id: string, sid: string, candidateid: string, jobid: any) {
    this.buttoninsert = "Change Status";
    this.disableBtn = true;
    this.applicantservices.getemailertemplate({ "candidateid": candidateid, "jobid": jobid, "sid": sid }).subscribe((edata: any) => {
      this.emailerDetail = edata;
      this.emailerDetail.candidateid = candidateid;
      this.emailerDetail.jobid = jobid;
      this.emailerDetail.location = "";
      this.emailerDetail.interdate = "";
      this.emailerDetail.interviewetime = "";
      this.emailerDetail.interviewstime = "";
      this.modalService.open(id);
    });
  }
  openModal5(id: string, data: any) {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  closeModal(id: string) {
    this.showSkills(this.candidateid);
    this.modalService.close(id);
  }


  
  statusChange(candidatestatusmaster: any, candidate: any)
  {
    if (confirm("Are you sure you want to change status?")) {
      this.candidate.candidatestatusUpdateOnly({"candidateid": candidate.candidatemasterid, "sid": candidatestatusmaster}).subscribe((data: any) => {
       
       
       this.showSkills(candidate.candidatemasterid);
        // this.candidate.getcandidateList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
        //   this.data = data;
        //   this.statusname = data.status.statusname;
          this.alerts.success('Candidate details updated successfully', true);
        // });
      });
    }
  }

  
selectall(val){
    this.ismail = val;

}

  submitStatus(emailerForm: any, modelid: any)
  {    
    if (this.candidatemessageFormGroup.invalid) {
      return;
    }


    emailerForm.ismail = this.ismail;
    this.candidate.candidatestatusdetailsUpdate(emailerForm).subscribe((data: any) => {
      
      // console.log(emailerForm.candidateid);
      this.showSkills(emailerForm.candidateid);
      this.modalService.close(modelid);
        this.alerts.success('Candidate details updated successfully', true);


      // this.candidate.getcandidateList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
      //   this.record = data.length;
      //   this.data = data;

      //   console.log(data);
      //   this.statusname = data.status.statusname;
        
      // });
    });
  }


  
  onContactSubmit(value3: any, id: string) {
    value3.userid = this.Userid;
    value3.skills =null;// this.tempArr;
    this.submitted = true;
    
   
    if (this.candidateformGroup.invalid) {
      return;
    }
    // console.log(value3);
    value3.smarks = null;// 100 * (Number(this.tempArr.skills.length) / Number(this.skilllist.length));
    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');

     
      // console.log(this.file);
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") && (filetype[filetype.length - 1].toLowerCase() != "gif") && (filetype[filetype.length - 1].toLowerCase() != "tif") && (filetype[filetype.length - 1].toLowerCase() != "pdf") && (filetype[filetype.length - 1].toLowerCase() != "doc") && (filetype[filetype.length - 1].toLowerCase() != "docx") && (filetype[filetype.length - 1].toLowerCase() != "msword")) {
        this.fileformaterror = true;
        // console.log(filetype[filetype.length - 1]);
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.cvname = imagepath;
    }
    else {
      // value3.cvname = this.candidateDetail.cvname;
      value3.cvname = "";
    }
    this.disableBtn = false;
    this.candidate.candidatedetailsUpdate(value3).subscribe((data: any) => {
      this.candidate.getcandidateList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
        this.record = data.length;
        this.data = data;
      });
    });
    this.alerts.success('Candidate details updated successfully', true);
    this.filename = "Choose file";
    this.modalService.close(id);

  }

  NewUrl(id: any){
    this.router.navigate(['/candidatedetails/' + this.jobid +'/'+id]);

  }


}
