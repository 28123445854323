<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__content">
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row row-sm topheader">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Asset List</h5>
                                </div>                              
                            </div>
                            <div class="row row-sm">
                                <div class="col-lg-12">
                                    <div class="job_list_section" *ngFor="let asset of pageOfItems  | filter: {serialnumber: searchText,assetdescption: searchText,categoryname: searchText };  let i=index">
                                        <div class="job_detils_date asslist width15">
                                            <p class="job_profile">Serial Number</p>
                                            <p class="job_address">{{asset.serialnumber}}</p>
                                        </div>
                                        <div class="job_detils_date asslist width15">
                                            <p class="job_profile">Category Name</p>
                                            <p class="job_address">{{asset.category.categoryname}}</p>
                                        </div>
                                        <div class="job_detils_date asslist width50">
                                            <p class="job_profile">Description</p>
                                            <p class="job_address">{{ asset.assetdescption }}</p>
                                        </div>
                                        <div class="job_detils_date asslist width10">
                                            <p class="job_profile">Allocation Date</p>
                                            <p class="job_address"> {{asset.statdate | date: 'dd MMM yyyy'}} </p>
                                        </div>
                                        <div class="job_detils_date asslist width10">
                                            <p class="job_profile">Return Date</p>
                                            <p class="job_address"> {{asset.enddate | date: 'dd MMM yyyy'}}</p>
                                            <p class="job_address" *ngIf="!asset.enddate ">N/A</p>
                                        </div>
                                    </div> 
                                    <div class="job_list_section visa_request_section" *ngIf="record == 0">
                                        <div class="job_detils_date">
                                            <p class="job_profile">No Record Found</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- col-8 -->
                            </div>
                            <div class="row">
                                
                                <div class="col-lg-12 text-right"  *ngIf="record > 30">
                                  <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                 </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>