import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, setYear, setMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarEvent, CalendarEventAction,  CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { Leave } from '../../models/leave/leave.model';
import { ModalService } from '../../_services/modal.service';
import { Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  constructor(
    private modal: NgbModal, 
    private notificationService: NotificationService, 
    private formBuilder: FormBuilder,
    private router: Router, 
    private datePipe: DatePipe,
    private modalService: ModalService,
    private alerts: AlertService
  ) { }

  Userid: any;
  event: CalendarEvent;
  leave: any;
  leaveformGroup: FormGroup;
  remarkformGroup: FormGroup;
  leaves: CalendarEvent[] = [];
  leavetypelist: any[];
  leavelistreport: any[];
  classactive: string;
  notifications: any[];
  year: number;
  minstartDate = new Date();
  minendDate = new Date();
  leaveavailabledate = new Date();
  submitted = false;
  remarksubmit = false;
  remarksofleave: any;
  remarksholidayid: any;
  month: any;
  disableBtn: any;
  defaultVal: any = "";
  @ViewChild('modalContent')
  modalContent: TemplateRef<any>;
  activeDayIsOpen: boolean = true;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  inc = 1;
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  refresh: Subject<any> = new Subject();

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    
    this.leaveavailabledate.setDate(this.leaveavailabledate.getDate() - 31);
    this.classactive = (new Date()).getMonth().toString();
    this.month = (new Date()).getMonth() + 1;
    this.year = (new Date()).getFullYear();
    this.leaveformGroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      holidaytype: ['', Validators.required],
      title: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
      holidayremark: ['', Validators.required],
      holidayremarkid: ['', Validators.required]
    });

    this.remarkformGroup = this.formBuilder.group({
      remarksholidayid: ['', Validators.required],
      holidayremark: ['', Validators.required]
      });
      this.Userid = localStorage.getItem('userid');
      const sstatustype = { 'status': '1', 'id': this.Userid } 
    // this.notificationService.getleavenotificationbyadmin().subscribe((leavelist: any) => {      
    //   this.leavetypelist = leavelist;
    // });


    this.bindallData();
    // this.bindalldatabymonth();
  }

  // bindalldatabymonth() {
  //   this.Userid = localStorage.getItem('userid');
  //   const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
  //   this.data.getemployeeleavereport(data).subscribe((data: any) => {
  //     this.leavelistreport = data;
  //   });
  // }

  bindallData() {
    const data1 = { 'id': this.Userid };
    this.notificationService.getleavenotificationbyadmin(data1).subscribe((data: any) => {
      // console.log(data);
      var i = 1;
      if(data != null){
        data.forEach(element => {
          this.leaves.push({
            id: this.inc,
            title: element.title,
            start: new Date(element.startdate),
            end: new Date(element.enddate),
            color: { primary: element.hcolor, secondary: element.fcolor },
            allDay: element.edit,
            draggable: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            }
          });
          this.inc++;
          this.refresh.next();
        });
      }
      
    });


    
    this.notificationService.getexpensenotificationbyadmin(data1).subscribe((data: any) => {
      // console.log(data);
      if(data != null){
        data.forEach(element => {
          this.leaves.push({
            id: this.inc,
            title: element.amountpaid + '-' + element.project.projectname,
            start: new Date(element.startdate),
            end: new Date(element.enddate),
            color: { primary: element.hcolor, secondary: element.fcolor },
            allDay: element.edit,
            draggable: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            }
          });
          this.inc++;
          this.refresh.next();
        });
      }
    });
    this.notificationService.getpassportexpirydatebyadmin(data1).subscribe((data: any) => {
      // console.log(data);
      if(data != null){
        data.forEach(element => {
          this.leaves.push({
            id: this.inc,
            title: 'Passport -' + element.employee.fname,
            start: new Date(element.passportexpiry),
            end: new Date(element.passportexpiry),
            color: { primary: element.hcolor, secondary: element.fcolor },
            allDay: element.edit,
            draggable: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            }
          });
          this.inc++;
          this.refresh.next();
        });
      }
    });
    this.notificationService.getvisaexpirydatebyadmin(data1).subscribe((data: any) => {
      // console.log(data);
      if(data != null){
        data.forEach(element => {
          this.leaves.push({
            id: this.inc,
            title: 'Visa -' + element.employee.fname,
            start: new Date(element.visaexpiry),
            end: new Date(element.visaexpiry),
            color: { primary: element.hcolor, secondary: element.fcolor },
            allDay: element.edit,
            draggable: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            }
          });
          this.inc++;
          this.refresh.next();
        });
      }
    });
    this.notificationService.getcosexpirydatebyadmin(data1).subscribe((data: any) => {
      // console.log(data);
      if(data != null){
        data.forEach(element => {
          this.leaves.push({
            id: this.inc,
            title: 'Cos -' + element.employee.fname,
            start: new Date(element.cosexpirydate),
            end: new Date(element.cosexpirydate),
            color: { primary: element.hcolor, secondary: element.fcolor },
            allDay: element.edit,
            draggable: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            }
          });
          this.inc++
          this.refresh.next();
        });
      }
    });
    
  }

  addMonthsOfYear(inc) {
    this.classactive = inc;
    var date = this.viewDate;
    date = setMonth(date, inc);
    date = setYear(date, this.year);
    this.viewDate = date;

    this.month = inc + 1;
    this.bindalldatabymonth();


  }
  closemessageModal(id: string){

  }

  bindalldatabymonth() {
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    // this.data.getemployeeleavereport(data).subscribe((data: any) => {
    //   this.leavelistreport = data;
    // });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;

      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  showAllEvents(id: string, data: any) {
    this.Userid = localStorage.getItem('userid');
    
    data =this.datePipe.transform(new Date(data),"dd MMM yyyy");
    const databydate = { 'id': this.Userid, 'startdate': data };
    this.notificationService.getallnotificationbydatebyadmin(databydate).subscribe((notificationdata: any) => {
     
      this.notifications = notificationdata;
    });
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

}
