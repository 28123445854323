<style>
  .invalid-feedback {
      display: block !important;
  }
</style>
<div class="bg">
  <app-header></app-header>
  <style>
      .leftbox {
          top: -30px !important;
          left: 30px !important;
      }
  </style>
  <div class="card-activities">
      <div class="media-list">
          <div class="media">
              <div class="activity-icon">
                  <i class="icon ion-image"></i>
              </div>
          </div>
          <div class="media">
              <div class="activity-icon">
                  <i class="icon ion-image"></i>
              </div>
          </div>
      </div>
  </div>
  <div class="section-wrapper">
      <div class="container">
          <div class="tabs" id="tabs">
              <div class="textrotate1">
                  <ul>
                      <li> <a [routerLink]="['/my-leave']">My Leave</a></li>
                      <li><a [routerLink]="['/my-leave-report']">Report</a></li>
                      <li> <a [routerLink]="['/total-leave']">Company Leave</a></li>
                  </ul>
              </div>
              <div class="tabs__content">
                  <div class="tabs__content__item active-tab">
                      <div class="content">
                          <div class="row">
                              <div class="col-lg-12">
                                  <ul class="tabs__head montharea">
                                      <li><a [ngClass]="{'active': classactive == '0'}" (click)="addMonthsOfYear(0);">January </a></li>
                                      <li><a [ngClass]="{'active': classactive == '1'}" (click)="addMonthsOfYear(1);">February </a></li>
                                      <li><a [ngClass]="{'active': classactive == '2'}" (click)="addMonthsOfYear(2);">March </a></li>
                                      <li><a [ngClass]="{'active': classactive == '3'}" (click)="addMonthsOfYear(3);">April </a></li>
                                      <li><a [ngClass]="{'active': classactive == '4'}" (click)="addMonthsOfYear(4);">May</a></li>
                                      <li><a [ngClass]="{'active': classactive == '5'}" (click)="addMonthsOfYear(5);">June </a></li>
                                      <li><a [ngClass]="{'active': classactive == '6'}" (click)="addMonthsOfYear(6);">July </a></li>
                                      <li><a [ngClass]="{'active': classactive == '7'}" (click)="addMonthsOfYear(7);">August </a></li>
                                      <li><a [ngClass]="{'active': classactive == '8'}" (click)="addMonthsOfYear(8);">September </a></li>
                                      <li><a [ngClass]="{'active': classactive == '9'}" (click)="addMonthsOfYear(9);">October </a></li>
                                      <li><a [ngClass]="{'active': classactive == '10'}" (click)="addMonthsOfYear(10);">November </a></li>
                                      <li><a [ngClass]="{'active': classactive == '11'}" (click)="addMonthsOfYear(11);">December </a></li>
                                  </ul>
                              </div>
                          </div>
                          <div class="row row-sm" >
                              <div class="col-lg-12">
                                  <div class="row">
                                      <div class="col-md">
                                          <ng-template #modalContent let-close="close">
                                              <div class="modal-header">
                                                  <h5 class="modal-title">Apply Leave</h5>
                                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                                                    <span aria-hidden="true">&times;</span>
                                                  </button>
                                              </div>
                                              <div class="modal-body" style="padding:0 30px;">
                                                  <form [formGroup]="leaveformGroup" #leaveForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(leaveForm.value,'modalContent')" *ngIf="leave">
                                                      <input type="hidden" formControlName="id" class="form-control" name="id" id="id" [(ngModel)]="leave.employeeholidayapplyid">
                                                      <input type="hidden" formControlName="holidayremarkid" class="form-control" required name="holidayremarkid" id="holidayremarkid" [(ngModel)]="leave.holidayremarkid">
                                                      <input type="hidden" formControlName="employeeid" class="form-control" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="leave.employeeid">
                                                      <div class="formgroup">
                                                          <div class="row row-sm mg-t-10"> 
                                                              <label class="d-flex justify-content-between"><span>Title*</span> </label>
                                                              <input type="text" formControlName="title" name="title" id="title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" class="form-control" [(ngModel)]="leave.title" [maxlength]="100" placeholder="Enter title here">
                                                              <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                                                  <div *ngIf="f.title.errors.required">Please enter title</div>
                                                                  <div *ngIf="!f.title.errors.required && f.title.errors.minlength">Minimum 3 characters required</div>
                                                              </div>
                                                          </div>
                                                          <div class="row row-sm mg-t-10"  style="display: none;">

                                                
                                                                      <div class="col-lg-6 col-6">
                                                                          <input type="radio" formControlName="halfday" [value]="0" name="halfday" [(ngModel)]="leave.halfday"> Full Day </div>
                                                                          <div class="col-lg-6 col-6"><input type="radio" formControlName="halfday" [value]="1" name="halfday" [(ngModel)]="leave.halfday"> Half Day </div>
                                                                     
                                                              
                                                          </div>
                                                          <div class="row row-sm mg-t-10">
                                                              <label>Start Date*</label>
                                                              <mat-form-field class="datecontrol">
                                                                  <input matInput #startPicker (click)="picker.open()" [matDatepicker]="picker" (dateChange)="onDateChange($event)" [ngClass]="{ 'is-invalid': submitted && f.start.errors }" [min]="minstartDate" formControlName="start" name="start" [ngModel]="leave.start" class="form-control">
                                                                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                                  <mat-datepicker #picker></mat-datepicker>
                                                              </mat-form-field>
                                                              <div *ngIf="submitted && f.start.errors" class="invalid-feedback">
                                                                  <div *ngIf="f.start.errors.required">Please choose start date </div>
                                                              </div>
                                                          </div>
                                                          <div class="row row-sm mg-t-10">
                                                              <label>End Date*</label>
                                                              <mat-form-field class="datecontrol">
                                                                  <input matInput #endPicker (click)="picker2.open()" [matDatepicker]="picker2" [ngClass]="{ 'is-invalid': submitted && f.end.errors }" formControlName="end" name="end" [ngModel]="leave.end" [min]="leave.start" class="form-control">
                                                                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                                  <mat-datepicker #picker2></mat-datepicker>
                                                              </mat-form-field>
                                                              <div *ngIf="submitted && f.end.errors" class="invalid-feedback">
                                                                  <div *ngIf="f.end.errors.required">Please choose end date</div>
                                                              </div>
                                                          </div>
                                                          <div class="row row-sm mg-t-10"  style="display: none;">
                                                              <label>Leave Type*</label>
                                                              <select class="form-control" (change)="titleChange()" name="holidaytype" id="holidaytype" [(ngModel)]="leave.holidaytype" [ngClass]="{ 'is-invalid': submitted && f.holidaytype.errors }" formControlName="holidaytype">
                                                              <option [value]="defaultVal">Select leave type</option>
                                                              <option *ngFor="let ltype of leavetypelist" [value]="ltype.id">{{ ltype.typename }}</option>
                                                              </select>
                                                              <div *ngIf="submitted && f.holidaytype.errors" class="invalid-feedback">
                                                                  <div *ngIf="f.holidaytype.errors.required">Please select leave type</div>
                                                              </div>
                                                          </div>
                                                          <div class="row row-sm mg-t-10">
                                                            <label>Medical Certificate</label>
                                                            <div class="input-group mb-3" (click)="file.click()">
                                                              <div class="custom-file">
                                                                <input type="file" #file (change)="selectFile($event)">
                                                                <label class="custom-file-label">{{filename}}</label>
                                                              </div>
                                                            </div>
                                                            <div class="alert-danger" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                                            <div class="alert-danger" *ngIf="fileformaterror == true">Please choose a valid file format
                                                              (jpg, png, tif, gif, pdf, doc)</div>
                                                          </div>
                                                        
                                                          <div class="row row-sm mg-t-10">
                                                              <label>Remarks*</label>
                                                              <textarea formControlName="holidayremark" [ngClass]="{ 'is-invalid': submitted && f.holidayremark.errors }" class="form-control" required name="holidayremark" id="holidayremark" [(ngModel)]="leave.holidayremark" maxlength="500" placeholder="Enter remarks here">{{ leave.holidayremark }}</textarea>
                                                              <div *ngIf="submitted && f.holidayremark.errors" class="invalid-feedback">
                                                                  <div *ngIf="f.holidayremark.errors.required">Please enter remarks</div>
                                                              </div>
                                                          </div>
                                                          <!-- <div class="row row-sm mg-t-10 mg-b-10">
                                                              <button type="submit" class="btn btn-outline-secondary"><i class="fa fa-check"></i></button>
                                                              <button type="button" class="btn btn-outline-secondary"><i class="fa fa-close"></i></button>
                                                          </div> -->
                                                          <div class="modal-footer row mg-t-10">
                                                              <div class="row">
                                                                  <button type="submit" class="btn btn-primary com_btn" [disabled]="!disableBtn">Save</button>
                                                                  <button type="button" class="btn btn-secondary com_btn" data-dismiss="modal" id="closeModal" (click)="close()">Close</button>
                                                                  <button *ngIf="(leave.employeeholidayapplyid != 0) && (leave.hstatus == '1')" type="button" class="btn btn-danger com_btn" (click)="onDelete(leave.employeeholidayapplyid)">Delete</button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </form>
                                              </div>
                                          </ng-template>

                                         
                                          <div class="leavedetails mb-4" style="display: none;">
                                              <div class="leavebox cleave">
                                                  <div class="boxleave text-center">
                                                      <h4>Pending Leave</h4>
                                                     
                                                      <p><span class="pending_leave">{{leavecount.ptotal}}</span></p>
                                                  </div>
                                                 
                                              </div>
                                              <div class="leavebox pleave">
                                                  <div class="boxleave text-center">
                                                      <h4>Approved Leave</h4>
                                                     
                                                      <p><span class="pending_leave">{{leavecount.atotal}}</span></p>
                                                  </div>
                                                 
                                              </div>
                                              <div class="leavebox vleave">
                                                  <div class="boxleave text-center">
                                                      <h4> Rejected Leave</h4>
                                                     
                                                      <p><span class="pending_leave">{{leavecount.rtotal}}</span></p>
                                                  </div>
                                                 
                                              </div>
                                              <div class="leavebox cpleave">
                                                  <div class="boxleave text-center">
                                                      <h4>Total Leave</h4>
                                                     
                                                      <p><span class="pending_leave">{{leavecount.totalleave}}</span></p>
                                                  </div>
                                                 
                                              </div>
                                              <div class="leavebox wleave">
                                                  <div class="boxleave text-center">
                                                      <h4>Available Leave</h4>
                                                     
                                                      <p><span class="pending_leave">{{(leavecount.totalleave)? (leavecount.totalleave - leavecount.atotal) : ''}}</span></p>
                                                  </div>
                                                 
                                              </div>
                                          </div>


                                          <div class="floatleft">
                                              <div class="col-md-12">
                                                  <h3 class="my_leave_heading"><span class="redcolor_text font22">My Sick Leave </span> <span class="graycolor_text fonts16">({{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }})</span></h3>
                                              </div>
                                          </div>
                                          <div class="floatright">
                                              <div class="col-md-12 form-inline ">
                                                  <select name="year" id="year" class="form-control yearselect" (change)="onyearSubmit($event.target.value)" ngModel='{{ year }}'>
                                                      <option value="{{y}}" *ngFor="let y of yearList">{{ y }}</option>
                                                  </select>
                                                  <!-- <a type="submit" class="btn btn-danger com_btn" [routerLink]="['/sick-leave']" >Apply Sick Leave</a> -->
                                                  <button type="submit" class="btn btn-danger com_btn" (click)="addNewEvent('Add',event)">Apply Leave</button>
                                                  <button type="button" class="btn btn-dark com_btn" (click)="openlinemanagerModal('line-manager')">Line Manager</button>
                                              </div>
                                          </div>
                                          <div class="clearfix"></div>
                                        
                                         

                                          <div [ngSwitch]="view" class="row row-sm maine222" style="margin:20px auto; width: 98%;">
                                              <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="leaves" [refresh]="refresh" [cellTemplate]="customCellTemplate" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
                                                  class="pre_tag">
                                              </mwl-calendar-month-view>
                                          </div>
                                          <ng-template #customCellTemplate let-day="day" let-locale="locale">
                                              <div class="cal-cell-top-add">
                                                  <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                                                  <span class="cal-day-badge" (click)="showAllEvents('event-viewer',event.id)" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
                                                  <span class="cal-day-number-add" *ngIf="day.date >= leaveavailabledate" style="border: 1px solid; border-radius: 5px;margin-right: 5px;margin-top: 5px;" (click)="addEvent('Add',event,day.date)"><i class="fa fa-fw fa-plus"></i></span>
                                              </div>
                                              <div *ngIf="day.events.length > 0">
                                                  <div *ngFor="let event of day.events; let i = index" [attr.data-index]="i">
                                                      <div class="boxrelative" *ngIf="i < 2">
                                                          <div class="cal-events">
                                                              <div class="cal-courseevent" [ngClass]="event.Type" [style.background-color]="event.color.primary" [style.color]="event.color.secondary">
                                                                  <small>
                                                                      <a (click)="showAllEvents('event-viewer',event.id)">
                                                                          {{ event.title | slice:0:20 }}{{event.title.length > 20 ? '...' : ''}}
                                                                      </a>
                                                                      <i *ngIf="event.allDay == true" class="fa fa-fw fa-pencil" (click)="editEvent('Edited',event)"></i>
                                                                  </small>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div *ngIf="i == 2">
                                                          <small *ngIf="day.events.length > 2" class="exdot" (click)="showAllEvents('event-viewer',day.date)">
                                                              <i class="fa fa-fw fa-circle"></i>
                                                              <i class="fa fa-fw fa-circle"></i>
                                                              <i class="fa fa-fw fa-circle"></i>
                                                          </small>
                                                      </div>
                                                  </div>
                                              </div>
                                          </ng-template>
                                      </div>
                                  </div>
                                  <div class="row cal_list_details" *ngFor="let leave of leavelistreport;  let i=index">
                                      <div class="col-lg-12">
                                          <p class="comheading">{{ leave.totalday }} leave taken
                                              <span *ngIf="leave.tcount != '0'">from  <b> {{leave.startdate | date: 'dd MMM yyyy'}} </b> to <b>{{leave.enddate | date: 'dd MMM yyyy'}}  </b></span>
                                              <span *ngIf="leave.tcount == '0'">on  <b> {{leave.startdate | date: 'dd MMM yyyy'}} </b> </span>
                                          </p>
                                          <span class="stus_show badge badge-danger" *ngIf="leave.hstatus == '3'"><i class="fa fa-close mr-1"></i>Rejected</span>
                                          <span class="stus_show badge badge-warning" *ngIf="leave.hstatus == '1'"><i class="fa fa-clock-o mr-1"></i>Pending</span>
                                          <span class="stus_show badge badge-success" *ngIf="leave.hstatus == '2'"><i class="fa fa-check mr-1"></i>Approved</span>
                                      </div>
                                      <ul class="commentlist">
                                          <li><i class="fa fa-comment"></i> <a (click)="remarksmodel(leave.employeeholidayapplyid, 'remarks-viewer', 'event-viewer');" class="curpointer"> {{ leave.remarkdetails.length }} comments</a></li>
                                          <li *ngIf="(leave.employeeholidayapplyid != 0) && (leave.hstatus == '1')">
                                              <a class="curpointer" (click)="onDelete(leave.employeeholidayapplyid)">Delete Request</a>
                                          </li>
                                          <li *ngIf="(leave.employeeholidayapplyid != 0) && (leave.hstatus == '1')"><a class="curpointer" (click)="editEventOfList('Edited',leave.employeeholidayapplyid, 'event-viewer')">Edit</a></li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<jw-modal id="event-viewer" class="modal listofwork" role="dialog">
  <div role="document" class="formgroup">
      <div class="bd-example-modal-lg">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title" id="myLargeModalLabel">Leave</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" (click)="closeModal('event-viewer');">×</span>
                  </button>
                  </div>
                  <div class="modal-body">
                      <div class="container">
                          

                          <div class="leave_apply_section">
                              <div class="white_box_shadow leave_width" *ngFor="let lv of dayexpense">
                                  <div class="leave_app_head">
                                      <div class="heading width80 mb-3">
                                          <span class="leave_type">{{ lv.holidaytype.typename }} </span>
                                          <h4>{{ lv.title }} </h4>
                                      </div>
                                      <span class="btn btn-danger" *ngIf="lv.hstatus == '3'"><i class="fa fa-clock-o" aria-hidden="true"></i><span>Rejected</span> </span>
                                      <span class="btn btn-warning" *ngIf="lv.hstatus == '1'"><i class="fa fa-clock-o" aria-hidden="true"></i><span>Pending</span></span>
                                      <span class="btn btn-sucess" *ngIf="lv.hstatus == '2'"><i class="fa fa-clock-o" aria-hidden="true"></i><span>Approved</span></span>
                                  </div>
                                  <!-- <div class="leave_details_sec">
                                      <div class="leave_icon">
                                          <img _ngcontent-c5="" class="img" src="../../../../../assets/img/user_pic_emp.png" alt="Rahul Tanwar">
                                      </div>
                                      <p class="leavefrom" *ngFor="let rem of lv.remarkdetails">
                                          <span>Name :</span> <b *ngIf="rem.comuser">{{rem.comuser.fname}} {{rem.comuser.mname}} {{rem.comuser.lname}} </b>
                                      </p>
                                  </div> -->
                                  <div class="leave_details_sec">
                                      <div class="leave_icon">
                                          <i class="fa fa-calendar" aria-hidden="true"></i>
                                      </div>
                                      <p class="leavefrom"><span>Leave From :</span> {{lv.startdate | date: 'dd MMM yyyy'}} - {{lv.enddate | date: 'dd MMM yyyy'}}</p>
                                  </div>
                                  <div class="leave_description">
                                      <p *ngFor="let rem of lv.remarkdetails">
                                          <i> {{ rem.holidayremark }} 
                                              <div class="note_footer">
                                                  <span>{{rem.createat | date: 'dd MMM yyyy'}}</span>  
                                                  <span><b *ngIf="rem.comuser">{{rem.comuser.fname}} {{rem.comuser.mname}} {{rem.comuser.lname}} </b></span> 
                                              </div>
                                          </i>
                                      </p>
                                  </div>
                                  <div class="leave_edit_cane_btn float-right">
                                      <button (click)="editEventOfList('Edited',lv.employeeholidayapplyid, 'event-viewer')" type="submit" class="btn btn-danger com_btn" *ngIf="lv.hstatus == '1'">Edit Leave</button>
                                      <button (click)="remarksmodel(lv.employeeholidayapplyid, 'remarks-viewer', 'event-viewer');" type="submit" class="btn btn-primary com_btn" *ngIf="lv.hstatus == '1'">Add Comment</button>
                                  </div>
                              </div>
                          </div>
                          <!-- <div class="row-sm mg-t-10 mg-b-10 float-right">
                              <button class="btn btn-secondary com_btn" type="submit" (click)="closeModal('event-viewer');">Cancel</button>
                          </div> -->
                          <!-- <div class="row row-sm">
                              <button type="button" style="background-color:#094008;margin-left: 15px;color: #fff;" class="btn btn-outline-secondary"><i class="fa fa-check"></i></button>
                          </div> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</jw-modal>

<jw-modal id="remarks-viewer" class="modal listofwork" role="dialog">
  <div role="document" class="formgroup ">
      <div class="modal-content">
          <div class="modal-body">
              <div class="container">
                  <form [formGroup]="remarkformGroup" #remarkForm="ngForm" class="s12 white" (ngSubmit)="onSubmitRemark(remarkForm,'remarks-viewer')" *ngIf="remarksofleave">
                      <input type="hidden" formControlName="remarksholidayid" class="form-control" required name="remarksholidayid" ngModel='{{ remarksholidayid }}' [value]="remarksholidayid">
                      <div class="row row-sm mg-t-10">
                          <div class="col-lg-12 poplist m-b-10">
                              <label>Remarks *</label>
                              <textarea [ngClass]="{ 'is-invalid': remarksubmit && g.holidayremark.errors }" class="form-control" formControlName="holidayremark" required name="holidayremarkchat" id="holidayremarks" required maxlength="200" placeholder="Maximum 200 characters allowed"></textarea>
                              <div *ngIf="remarksubmit && g.holidayremark.errors" class="invalid-feedback">
                                  <div *ngIf="g.holidayremark.errors.required">Please enter remarks</div>
                              </div>
                          </div>
                      </div>
                      <div class="row-sm float-right">
                          <div class="poplist m-b-10">
                              <button type="submit" class="btn btn-success com_btn">Add Comment</button>
                          </div>
                      </div>
                      <div class="clearfix"></div>
                  </form>
                  <div class="leave_description white_box_shadow noheight"  *ngFor="let rem of remarksofleave">
                      <p class="mb-0">
                          <i>
                              {{ rem.holidayremark }}
                              <hr class="dashed mt-2 mb-2"/>
                              <div class="note_footer pl-0 pr-0">
                                  <span>
                                      {{rem.createat | date: 'dd MMM yyyy'}}
                                  </span>
                                  <span>
                                      <b *ngIf="rem.comuser">
                                          {{rem.comuser.fname}}
                                      </b>
                                  </span>
                              </div>
                          </i>
                      </p>
                  </div>
                  <!-- <div class="job_list_section" *ngFor="let rem of remarksofleave">
                      <div class="job_detils_date">
                          <h4 class="job_profile" *ngIf="rem.comuser">{{rem.comuser.fname}} {{ rem.comuser.mname }} {{rem.comuser.lname}}<span class="date_show">{{rem.createat | date: 'dd MMM yyyy'}}</span></h4>
                          <p class="job_address fs"> {{ rem.holidayremark }}</p>
                      </div>
                  </div> -->
                  <div class="row-sm float-right">
                      <!-- <button type="button" class="btn btn-primary com_btn" (click)="closeModal('remarks-viewer');">Save</button> -->
                      <button type="button" class="btn btn-secondary com_btn" (click)="closemessageModal('remarks-viewer');">Cancel</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</jw-modal>

<jw-modal id="line-manager" class="modal listofwork" role="dialog">
  <div role="document" class="formgroup">
      <div class="bd-example-modal-lg">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title" id="myLargeModalLabel">Line Manager</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" (click)="closeModal('line-manager');">×</span>
                  </button>
                  </div>
                  <div class="modal-body">
                      <div class="container">
                                
                          <!-- <div>
                            {{linemanager.fname}}
                          </div> -->
                          <div class="job_list_section employee_list_section"  *ngIf="linemanager"> 
                              <div class="job_detils_date">
                                  <div class="visa_user_pic"> 
                                   
                                      <img *ngIf="linemanager.imagename" src="{{linemanager.imagename}}" class="img width100" alt="{{ linemanager.fname }} {{ linemanager.mname }} {{ linemanager.lname}}">
                                      <img *ngIf="linemanager.gender == true && (linemanager.imagename == null || linemanager.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ linemanager.fname }} {{ linemanager.mname }} {{ linemanager.lname}}">
                                      <img *ngIf="linemanager.gender == false && (linemanager.imagename == null || linemanager.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ linemanager.fname }} {{ linemanager.mname }} {{ linemanager.lname}}">
                                  </div>

                              </div>
                              <div class="job_detils_date width30">
                                  <h5 class="job_profile font_17">{{linemanager.fname}} {{linemanager.mname}} {{linemanager.lname}}</h5>
                                  <p class="email ellipsis"><i aria-hidden="true" class="fa fa-envelope-o"></i> <span>{{linemanager.emailid}}</span></p>
                                  <p class="phone"><i aria-hidden="true" class="fa fa-phone"></i> <span>{{linemanager.phonenumber}}</span></p>
                              </div>
                              <div class="job_detils_date width15">
                                  <p class="job_address"> NI Number</p>
                                  <p class="job_profile">{{linemanager.ninumber}}</p>
                              </div>
                              <div class="job_detils_date width15">
                                  <p class="job_address">Employee ID</p>
                                  <p class="job_profile"> {{linemanager.employeeid}}</p>
                              </div>
                              <div class="job_detils_date width22">
                                  <p class="job_address">Employee Role</p>
                                  <p class="badge badge-danger ellipsis width100 text-left" *ngIf="linemanager.emprole">{{linemanager.emprole}} </p>
                                  <p class="badge badge-dark ellipsis width100 text-left" *ngIf="!linemanager.emprole">N/A</p>
                              </div>
                              <!-- <div class="job_detils_date  width13 d-flex aligncenter">
                                  <a type="submit" class="btn btn-primary com_btn"  *ngIf="empperm > 2" [routerLink]="'/employeedetails/'+ employee.employeemasterid">View Details</a>
                                

                              </div> -->
                          </div>
                          <!-- <app-mycurrentemployment></app-mycurrentemployment>  -->
                          <div class="job_list_section employee_list_section" *ngIf="!linemanager">
                              No Line Manager
                          </div>
                         
                        
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</jw-modal>