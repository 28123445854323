"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var RatecompetenciesComponent = /** @class */ (function () {
    function RatecompetenciesComponent() {
    }
    RatecompetenciesComponent.prototype.ngOnInit = function () {
    };
    return RatecompetenciesComponent;
}());
exports.RatecompetenciesComponent = RatecompetenciesComponent;
