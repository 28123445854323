"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var user_service_1 = require("../../services/user.service");
var company_service_1 = require("../../services/company.service");
var ConfirmationapprovalComponent = /** @class */ (function () {
    function ConfirmationapprovalComponent(companyService, router, userService) {
        this.companyService = companyService;
        this.router = router;
        this.userService = userService;
    }
    ConfirmationapprovalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ctype = parseInt(localStorage.getItem('ctype'));
        if (this.ctype == 1) {
            this.companyService.getcompanydetails().subscribe(function (data) {
                if (data.stuser == '') {
                    _this.router.navigate(['/payment-confirmation']);
                }
                else if (data.verifiedforcheck == true) {
                    localStorage.setItem('isverify', "1");
                    console.log(data.verifiedforcheck);
                    _this.router.navigate(['/check-dashboard']);
                }
            });
        }
    };
    ConfirmationapprovalComponent.prototype.logout = function () {
        var _this = this;
        if (confirm("Are you sure you want to sign out?")) {
            this.userService.logout().subscribe(function (data) {
                localStorage.removeItem('userToken');
                localStorage.removeItem('userid');
                localStorage.removeItem('Name');
                localStorage.removeItem('isline');
                localStorage.removeItem('planid');
                localStorage.removeItem('isemail');
                localStorage.removeItem('isadmin');
                localStorage.removeItem('ispermission');
                localStorage.removeItem('logoname');
                localStorage.removeItem('menu');
                _this.router.navigate(['/login']);
            });
            ;
        }
    };
    return ConfirmationapprovalComponent;
}());
exports.ConfirmationapprovalComponent = ConfirmationapprovalComponent;
