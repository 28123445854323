"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../_services/modal.service");
var aml_model_1 = require("../../../models/aml/aml.model");
var forms_1 = require("@angular/forms");
var aml_service_1 = require("../../../services/aml.service");
var router_1 = require("@angular/router");
var BusinesscreditComponent = /** @class */ (function () {
    function BusinesscreditComponent(modalService, formBuilder, amldetails, router) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.amldetails = amldetails;
        this.router = router;
        this.businessDetail = new aml_model_1.AmlBussiness();
        this.defaultVal = '';
    }
    BusinesscreditComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.buttoninsert = "Search";
        this.result = false;
        this.defaultVal = "";
        this.bussinessformGroup = this.formBuilder.group({
            BusinessName: ['', forms_1.Validators.required],
            Country: ['', forms_1.Validators.required],
            County: [''],
            Threshold: [''],
            City: [''],
            Postcode: [''],
            Address: [''],
            PEP: [''],
            PreviousSanctions: [''],
            CurrentSanctions: [''],
            LawEnforcement: [''],
            FinancialRegulator: [''],
            Insolvency: [''],
            DisqualifiedDirector: [''],
            AdverseMedia: ['']
        });
        this.businessDetail = new aml_model_1.AmlBussiness();
        this.amldetails.getCountries().subscribe(function (countrydata) {
            _this.countrylist = countrydata;
            _this.businessDetail.Country = "";
        });
    };
    Object.defineProperty(BusinesscreditComponent.prototype, "f", {
        get: function () { return this.bussinessformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    BusinesscreditComponent.prototype.clearpersonal = function () {
        this.bussinessformGroup.reset();
        this.businessDetail = new aml_model_1.AmlBussiness();
        this.businessDetail.BusinessName = "";
        this.businessDetail.Country = "";
        this.businessDetail.Threshold = "";
        this.businessDetail.Postcode = "";
        this.businessDetail.Address = "";
        this.businessDetail.County = "";
        this.businessDetail.City = "";
        this.businessDetail.PEP = false;
        this.businessDetail.CurrentSanctions = false;
        this.businessDetail.DisqualifiedDirector = false;
        this.businessDetail.FinancialRegulator = false;
        this.businessDetail.Insolvency = false;
        this.businessDetail.LawEnforcement = false;
        this.businessDetail.AdverseMedia = false;
        this.businessDetail.PreviousSanctions = false;
    };
    BusinesscreditComponent.prototype.selectall = function (val) {
        this.businessDetail.PEP = true;
        this.businessDetail.CurrentSanctions = true;
        this.businessDetail.DisqualifiedDirector = true;
        this.businessDetail.FinancialRegulator = true;
        this.businessDetail.Insolvency = true;
        this.businessDetail.LawEnforcement = true;
        this.businessDetail.AdverseMedia = true;
        this.businessDetail.PreviousSanctions = true;
    };
    BusinesscreditComponent.prototype.onSubmit = function (val) {
        var _this = this;
        this.submitted = true;
        if (this.bussinessformGroup.invalid) {
            return;
        }
        // console.log(val);
        // var db= {};
        // db ={name: val.name, countries: val.countries};
        // if(val.houseNo != '')
        // {
        //   db['houseNo'] = val.houseNo;
        // }
        // if(val.postCode != '')
        // {
        //   db['postCode'] = val.postCode;
        // }
        // if(val.street != '')
        // {
        //   db['street'] = val.street;
        // }
        // if(val.city !=''){
        //   db['city'] = val.city;
        // }
        // console.log(db);
        // this.amldetails.getListofCompanyPreDefinedSearches().subscribe((searchRes: any) => {
        //   console.log(searchRes)
        // });
        this.disableBtn = false;
        this.amldetails.getCompanySearch(val).subscribe(function (searchRes) {
            // console.log(searchRes);
            if (searchRes.recordsFound > 0) {
                _this.result = true;
            }
            _this.disableBtn = true;
            // localStorage.setItem('personalid', searchRes.id);    
            _this.router.navigate(['/buscreditsearchresult', searchRes.id]);
            _this.searchresult = searchRes;
        });
    };
    BusinesscreditComponent.prototype.dataset = function (values) {
        this.datasetlist = "";
        if (values.isPEP == true) {
            this.datasetlist = "PEP,";
        }
        if (values.isAdverseMedia == true) {
            this.datasetlist += " Adverse Media,";
        }
        if (values.isDisqualifiedDirector == true) {
            this.datasetlist += " Disqualified Director (UK Only),";
        }
        if (values.isFinancialregulator == true) {
            this.datasetlist += " Financial Regulator,";
        }
        if (values.isInsolvent == true) {
            this.datasetlist += " Insolvency (UK & Ireland),";
        }
        if (values.isLawEnforcement == true) {
            this.datasetlist += " Law Enforcement,";
        }
        if (values.isSanctionsCurrent == true) {
            this.datasetlist += " Sanction - Current,";
        }
        if (values.isSanctionsPrevious == true) {
            this.datasetlist += " Sanction - Previous,";
        }
        this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1);
    };
    BusinesscreditComponent.prototype.openModal = function (id, data) {
        this.bussinessdetails = data;
        // console.log(data);
        this.buttoninsert = "Update";
        this.modalService.open(id);
    };
    BusinesscreditComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return BusinesscreditComponent;
}());
exports.BusinesscreditComponent = BusinesscreditComponent;
