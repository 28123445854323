import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { Visa } from '../../../models/visa/visa.model';
import { Aml,AmlBussiness } from '../../../models/aml/aml.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AmlService } from '../../../services/aml.service';
import { Router } from '@angular/router';

import { ChecksystemService } from '../../../services/checksystem.service';


@Component({
  selector: 'app-businessamlcheck',
  templateUrl: './businessamlcheck.component.html',
  styleUrls: ['./businessamlcheck.component.css']
})
export class BusinessamlcheckComponent implements OnInit {
  
  disableBtn: any;
  error: boolean;  
  buttoninsert: any;
  submitted: boolean;  
  
  errormsg: any;
  bussinessformGroup: FormGroup;
  businessDetail =new AmlBussiness();
  searchresult: any;
  result: boolean;
  defaultVal: string ='';
  countrylist:any;
  datasetlist: any;
  bussinessdetails:any;
  lesssore:boolean;
  checkedAll: any = false;

  constructor(
    private modalService: ModalService, 
    private formBuilder: FormBuilder,
    private amldetails: AmlService,    
    private router: Router,    
    private check: ChecksystemService
  ) { }

  ngOnInit() {
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    // this.permission = permission[0];
    if(permission[0]['m96'] < 3)
    {
      this.router.navigate(['/permission-denied']);
    }
    this.error =false;
    this.buttoninsert ="Search";
    this.result =false;
    
    this.disableBtn =true;
    this.errormsg ='';
    this.defaultVal ="";
    this.bussinessformGroup = this.formBuilder.group({
      BusinessName: ['', Validators.required],
      Country: ['', Validators.required],
      County: [''],        
      Threshold: [''],   
      City: [''] ,
      Postcode: [''],
      Address: [''],      
      PEP: [''],
      PreviousSanctions: [''],
      CurrentSanctions: [''],
      LawEnforcement: [''],
      FinancialRegulator: [''],
      Insolvency: [''],
      DisqualifiedDirector: [''],
      AdverseMedia: ['']
    });
    this.businessDetail =new AmlBussiness();
    
    this.amldetails.getCountries().subscribe((countrydata: any) => { 
      this.countrylist = countrydata;
      this.businessDetail.Country ="";
    });

    
    var ts  = { 'serviceid': 8};    
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      // console.log(remaindata)
      if(remaindata.remiancheck <= 0)
      {
          this.lesssore =true;
      }
    });

  }
  get f() { return this.bussinessformGroup.controls; }


  
clearpersonal(){
  this.submitted = false;
  // this.bussinessformGroup.reset();
  this.businessDetail =new AmlBussiness();

  this.businessDetail.BusinessName = "";
  this.businessDetail.Country = "";
  
  this.businessDetail.Threshold = "";
  
  this.businessDetail.Postcode = "";
  this.businessDetail.Address = "";
  
  this.businessDetail.County = "";
  this.businessDetail.City = "";
  this.businessDetail.PEP = false;
  this.businessDetail.CurrentSanctions = false;
  this.businessDetail.DisqualifiedDirector =false;
  this.businessDetail.FinancialRegulator =false;
  this.businessDetail.Insolvency =false;
  this.businessDetail.LawEnforcement= false;
  this.businessDetail.AdverseMedia =false;
  this.businessDetail.PreviousSanctions =false;
  this.checkedAll = false;


}
amllogredirect()
{
  this.router.navigate(['/check-aml-log-list', '1']);
}


selectall(val){
  if(val == true){
  this.checkedAll = true;
  this.businessDetail.PEP = true;
  this.businessDetail.CurrentSanctions = true;
  this.businessDetail.DisqualifiedDirector =true;
  this.businessDetail.FinancialRegulator =true;
  this.businessDetail.Insolvency =true;
  this.businessDetail.LawEnforcement= true;
  this.businessDetail.AdverseMedia =true;
  this.businessDetail.PreviousSanctions =true;
  }
}

selectallfalse(val){
  if(val == false){
    this.checkedAll = false;
  }
}

  onSubmit(val,type)
  {
    this.error =false;
    this.submitted = true; 
    if (this.bussinessformGroup.invalid) {
      return;
    }
  
    if(val.PEP ==false && val.PreviousSanctions ==false && val.CurrentSanctions ==false && val.LawEnforcement ==false && val.FinancialRegulator ==false && val.Insolvency ==false && val.DisqualifiedDirector ==false &&  val.AdverseMedia ==false)
    {
      this.error =true;
      return;
    }
    
    if(type == 'first'){
      
      this.disableBtn =false;
      var ts  = { 'serviceid': 8};   
      this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
        // console.log(remaindata)
        if(remaindata.remiancheck <= 0)
        {   
          this.lesssore =true;
          this.modalService.open('upgrade-plan');
        }else
        {  
          this.disableBtn =true;
          this.lesssore =false;
          this.modalService.open('pdf-payment-confirmation');          
        }
      });
  
     
    }
    else if (type == 'payment')
    {

    // console.log(val);
    // var db= {};
    
    // db ={name: val.name, countries: val.countries};
      

    // if(val.houseNo != '')
    // {
    //   db['houseNo'] = val.houseNo;
    // }

    // if(val.postCode != '')
    // {
    //   db['postCode'] = val.postCode;
    // }

    // if(val.street != '')
    // {
    //   db['street'] = val.street;
    // }

    // if(val.city !=''){
    //   db['city'] = val.city;
    // }

    // console.log(db);

    // this.amldetails.getListofCompanyPreDefinedSearches().subscribe((searchRes: any) => {
      
    //   console.log(searchRes)
    
    // });
    
  this.disableBtn =false;
    this.amldetails.getCompanySearch(val).subscribe((searchRes: any) => { 

      this.errormsg ='';
      if(searchRes.error == ''){
      // console.log(searchRes);
      if(searchRes.recordsFound >0){
        this.result =true;
      }

      this.disableBtn =true;
    // localStorage.setItem('personalid', searchRes.id);    
    this.router.navigate(['/buscreditsearchresult', searchRes.id]); 

      this.searchresult = searchRes; 
    }
    else if(searchRes.error == 'amlfail')
      {
          this.errormsg ='We have some issue in AML & PEPs check. Please contact Administrator';
        
      }else  if(searchRes.error == 'paymentfail')
      {
        this.errormsg ='Your payment fail. Please contact Administrator';
      }
    });
  }
    
  }

  dataset(values){
    
    this.datasetlist ="";

    if(values.isPEP == true)
    {
      this.datasetlist = "PEP,";
    }

    if(values.isAdverseMedia == true)
    {
      this.datasetlist += " Adverse Media,";
    }

    if(values.isDisqualifiedDirector == true)
    {
      this.datasetlist += " Disqualified Director (UK Only),";
    }

    if(values.isFinancialregulator == true)
    {
      this.datasetlist += " Financial Regulator,";
    }

    if(values.isInsolvent == true)
    {
      this.datasetlist += " Insolvency (UK & Ireland),";
    }

    if(values.isLawEnforcement == true)
    {
      this.datasetlist += " Law Enforcement,";
    }

    if(values.isSanctionsCurrent == true)
    {
      this.datasetlist += " Sanction - Current,";
    }
    if(values.isSanctionsPrevious == true)
    {
      this.datasetlist += " Sanction - Previous,";
    }

    this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1);
    

  }


  openModal(id: string, data: any) {
    
    this.bussinessdetails = data;
   // console.log(data);
    this.buttoninsert = "Update";
    this.modalService.open(id);
  }
  closeModal(id: string) 
  {
      this.modalService.close(id);
  }
}