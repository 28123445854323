<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
      <div class="container">
          <div class="tabs" id="tabs">
              <div class="tabs__wcontent">
                  <div class="tabs2__content__item active-tab">
                      <div class="content">                         
                          <div class="">
                            <div class="profile_box show-hight">
                                <div id="overview">
                                    <div class="overviewsshow">
                                        <div class="cseec">
                                            <div class="admin_com_logo">
                                                <!-- <div class="picture_chnge">                                                   
                                                    <input type="file" accept="image/*" style="display:none;" #file>
                                                    <a target="_blank"  class="cameraicon"><i class="fa fa-camera"></i></a>
                                                </div> -->
                                                <img src="../../../../../assets/img/user_pic_emp.png">
                                                <!-- <img alt="" [src]="overviewData.imagename"
                                                    *ngIf="overviewData.imagename !='' && overviewData.imagename !=null" />
                                                <img alt="" src="../../../assets/img/no-image-icon-hi.png"
                                                    *ngIf="overviewData.imagename =='' || overviewData.imagename == null" /> -->
                                            </div>
                                        </div>
                                        <div class="overview_details">
                                            <div class="alignbbb">
                                                <div class="headdd width100">
                                                    <h3 class="card-profile-name text-left d-flex justify-content-between">Ben Maxwell Santiago  &nbsp;
                                                        <span>ED10065</span>
                                                      </h3>                                                      
                                                      </div>
                                               
                                            </div>
                                            <hr class="mt-0" />
                                            <div class="profile_dtailsshow mb-3">
                                                <div class="detailsshow width25">
                                                    <span>Email</span>
                                                    <p>rahul21@mailinator.com</p>
                                                </div>
                                                <div class="detailsshow width20 ">
                                                    <span>Mobile</span>
                                                    <p> +919350283562 </p>
                                                </div>
                                                
                                                <div class="detailsshow width20">
                                                    <span>Gender</span>
                                                    <p>
                                                        <ng-container>
                                                            Male
                                                        </ng-container>                                                       
                                                    </p>
                                                </div>
                                                <div class="detailsshow width20">
                                                    <span>Hire Date</span>
                                                    <p>05 Mar 2019</p>
                                                </div>
                                                <div class="detailsshow width20 ">
                                                    <span>NI Number</span>
                                                    <p>5874188</p>
                                                </div>                                          
                                            </div>
                                          
                                            <!-- <div class="alignbbb  mt-2">
                                                <h3 class="card-profile-name text-left">Permanent Address
                                                    
                                                    </h3>
                                            </div>
                                            <hr class="mt-0" />
                                            <div class="profile_dtailsshow">
                                                <div class="detailsshow width100">
                                                    <p><i class="lnr lnr-map-marker"></i>A - 22585, Street Main, Near Medical Store, London, United kingdom, Antigua & Barbuda - 210011</p>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>                                
                                </div>
                            </div>
                            <div class="details_box show-hight">
                              <div class="overviewsshow pb-4 pt-2">    
                                <div class="row">
                                  <div class="col-sm-12">
                                    <div  class="form-inline d-flex justify-content-end mt-2">
                                        <select class="form-control yearselect">
                                          <option>2018</option>
                                          <option>2019</option>
                                          <option>2020</option>
                                          <option>2021</option>
                                        </select>
                                      </div>
                                  </div>
                                </div>                           
                                <div class="row row-sm">
                                  <div class="col-lg-12">
                                   
                                    <h3 class="quarter">Rate Competencies 1</h3>
                                    <div class="feedback_box">                  
                                        <div class="feedback">
                                            
                                            <div class="feedback_details">
                                                <div class="user_pic">
                                                    C
                                                </div>
                                              <p class="details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                           <div class="rating">
                                            <!-- <rating [(ngModel)]="model.rating"
                                            [max]="5"
                                            iconClass="fa-star"
                                            fullIcon="★"
                                            emptyIcon="☆"
                                            [readonly]="false"
                                            [disabled]="false"
                                            [required]="true"
                                            [float]="true"
                                            [titles]="['one', 'two', 'three', 'four', 'five']">
                                    </rating> -->
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                           </div>
                                            <div class="comment_box">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">Reply</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                                
                                                </div>
                                                <div class="text-right">
                                                    <button type="submit" class="btn com_btn btn-primary"> Finish</button>
                                                </div>
                                            </div>
                                            <div class="name">
                                                <div class="feedback_name">
                                                    
                                                </div>
                                                <div class="write_name">
                                                    <span class="name_show">Chris M</span><span class="date_show">12/12/2021 12:00 PM</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                       
                                    </div>  
                                    <h3 class="quarter">Rate Competencies 2</h3>
                                    <div class="feedback_box">                  
                                        <div class="feedback">
                                            
                                            <div class="feedback_details">
                                                <div class="user_pic">
                                                    C
                                                </div>
                                              <p class="details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                            <div class="rating">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                               </div>
                                            <div class="comment_box">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">Reply</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                                
                                                </div>
                                                <div class="text-right">
                                                    <button type="submit" class="btn com_btn btn-primary"> Finish</button>
                                                </div>
                                            </div>
                                            <div class="name">
                                                <div class="feedback_name">
                                                    
                                                </div>
                                                <div class="write_name">
                                                    <span class="name_show">Chris M</span><span class="date_show">12/12/2021 12:00 PM</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                       
                                    </div>  
                                    <h3 class="quarter">Rate Competencies 3</h3>
                                    <div class="feedback_box">                  
                                        <div class="feedback">
                                            
                                            <div class="feedback_details">
                                                <div class="user_pic">
                                                    C
                                                </div>
                                              <p class="details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                            <div class="rating">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                               </div>
                                            <div class="comment_box">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">Reply</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                                
                                                </div>
                                                <div class="text-right">
                                                    <button type="submit" class="btn com_btn btn-primary"> Finish</button>
                                                </div>
                                            </div>
                                            <div class="name">
                                                <div class="feedback_name">
                                                    
                                                </div>
                                                <div class="write_name">
                                                    <span class="name_show">Chris M</span><span class="date_show">12/12/2021 12:00 PM</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                       
                                    </div>  
                                    <h3 class="quarter">Rate Competencies 4</h3>
                                    <div class="feedback_box">                  
                                        <div class="feedback">
                                            
                                            <div class="feedback_details">
                                                <div class="user_pic">
                                                    C
                                                </div>
                                              <p class="details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                            <div class="rating">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                               </div>
                                            <div class="comment_box">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">Reply</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                                
                                                </div>
                                                <div class="text-right">
                                                    <button type="submit" class="btn com_btn btn-primary"> Finish</button>
                                                </div>
                                            </div>
                                            <div class="name">
                                                <div class="feedback_name">
                                                    
                                                </div>
                                                <div class="write_name">
                                                    <span class="name_show">Chris M</span><span class="date_show">12/12/2021 12:00 PM</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                       
                                    </div>  
                                    <h3 class="quarter">Rate Competencies 5</h3>
                                    <div class="feedback_box">                  
                                        <div class="feedback">
                                            
                                            <div class="feedback_details">
                                                <div class="user_pic">
                                                    C
                                                </div>
                                              <p class="details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                            <div class="rating">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                               </div>
                                            <div class="comment_box">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">Reply</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                                
                                                </div>
                                                <div class="text-right">
                                                    <button type="submit" class="btn com_btn btn-primary"> Finish</button>
                                                </div>
                                            </div>
                                            <div class="name">
                                                <div class="feedback_name">
                                                    
                                                </div>
                                                <div class="write_name">
                                                    <span class="name_show">Chris M</span><span class="date_show">12/12/2021 12:00 PM</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                       
                                    </div>  
                                    <h3 class="quarter">Rate Competencies 6</h3>
                                    <div class="feedback_box">                  
                                        <div class="feedback">
                                            
                                            <div class="feedback_details">
                                                <div class="user_pic">
                                                    C
                                                </div>
                                              <p class="details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                            <div class="rating">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                               </div>
                                            <div class="comment_box">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">Reply</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                                
                                                </div>
                                                <div class="text-right">
                                                    <button type="submit" class="btn com_btn btn-primary"> Finish</button>
                                                </div>
                                            </div>
                                            <div class="name">
                                                <div class="feedback_name">
                                                    
                                                </div>
                                                <div class="write_name">
                                                    <span class="name_show">Chris M</span><span class="date_show">12/12/2021 12:00 PM</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                       
                                    </div>  
                                    <div class="job_list_section visa_request_section">
                                        <div class="job_detils_date asslist width10">
                                            <p class="job_profile">Serial Number</p>
                                            <p class="job_address">1</p>
                                        </div>
                                        <div class="job_detils_date asslist width50">
                                            <p class="job_profile">Objective </p>
                                            <p class="job_address">Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text</p>
                                        </div>
                                        <div class="job_detils_date asslist width10">
                                            <p class="job_profile">Year</p>
                                            <p class="job_address">2018</p>
                                        </div>   
                                        <div class="job_detils_date asslist width10">
                                          <p class="job_profile">Rating</p>
                                          <p class="job_address">
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                          </p>
                                      </div>                                        
                                      </div> 
                                      <div class="job_list_section visa_request_section">
                                        <div class="job_detils_date asslist width10">
                                            <p class="job_profile">Serial Number</p>
                                            <p class="job_address">2</p>
                                        </div>
                                        <div class="job_detils_date asslist width50">
                                            <p class="job_profile">Objective </p>
                                            <p class="job_address">Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text</p>
                                        </div>
                                        <div class="job_detils_date asslist width10">
                                            <p class="job_profile">Allocation Year</p>
                                            <p class="job_address">2019</p>
                                        </div>   
                                        <div class="job_detils_date asslist width10">
                                          <p class="job_profile">Rating</p>
                                          <p class="job_address">
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                          </p>
                                      </div>                                        
                                      </div> 
                                      <div class="job_list_section visa_request_section">
                                        <div class="job_detils_date asslist width10">
                                            <p class="job_profile">Serial Number</p>
                                            <p class="job_address">3</p>
                                        </div>
                                        <div class="job_detils_date asslist width50">
                                            <p class="job_profile">Objective </p>
                                            <p class="job_address">Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text</p>
                                        </div>
                                        <div class="job_detils_date asslist width10">
                                            <p class="job_profile">Allocation Year</p>
                                            <p class="job_address">2020</p>
                                        </div>   
                                        <div class="job_detils_date asslist width10">
                                          <p class="job_profile">Rating</p>
                                          <p class="job_address">
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                          </p>
                                      </div>                                        
                                      </div> 
                                      <div class="job_list_section visa_request_section">
                                        <div class="job_detils_date asslist width10">
                                            <p class="job_profile">Serial Number</p>
                                            <p class="job_address">4</p>
                                        </div>
                                        <div class="job_detils_date asslist width50">
                                            <p class="job_profile">Objective </p>
                                            <p class="job_address">Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text</p>
                                        </div>
                                        <div class="job_detils_date asslist width10">
                                            <p class="job_profile">Allocation Year</p>
                                            <p class="job_address">2021</p>
                                        </div>   
                                        <div class="job_detils_date asslist width10">
                                          <p class="job_profile">Rating</p>
                                          <p class="job_address">
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                          </p>
                                      </div>                                        
                                      </div>                                  
                                    <!-- <hr/>
                                    <div class="button_prima text-right">
                                        <button type="submit" class="btn btn-success com_btn">Send Appraisal</button>
                                    </div> -->
                                    <!-- <div class="comment_box">
                                        <div class="form-group">
                                            <label for="exampleFormControlTextarea1">Reply</label>
                                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                        
                                        </div>
                                        <div class="text-right">
                                            <button type="submit" class="btn com_btn btn-primary"> Send</button>
                                        </div>
                                    </div> -->
                                  </div>  
                                                              
                                </div>
                                <div class="row">
                                    
                                    <div class="col-lg-12 text-right"  *ngIf="record > 30">
                                      <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

