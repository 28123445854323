"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_service_1 = require("../../services/employee.service");
var modal_service_1 = require("../../_services/modal.service");
var profile_model_1 = require("../../models/overview/profile.model");
var country_service_1 = require("../../services/country.service");
var common_service_1 = require("../../services/common.service");
var job_service_1 = require("../../services/job.service");
var candidate_service_1 = require("../../services/candidate.service");
var user_service_1 = require("../../services/user.service");
var permission_service_1 = require("../../services/permission.service");
var personaltype_service_1 = require("../../services/personaltype.service");
var company_service_1 = require("../../services/company.service");
var alert_service_1 = require("../../services/alert.service");
var checksystem_service_1 = require("../../services/checksystem.service");
var router_1 = require("@angular/router");
var zoom_service_1 = require("../../services/zoom.service");
var CheckemployeeComponent = /** @class */ (function () {
    function CheckemployeeComponent(userService, employeeService, country, modalService, commonService, activatedRoute, formBuilder, jobdetails, candidatedetails, permissiondetails, dltypeService, companyService, alerts, check, router, route, zoomService) {
        var _this = this;
        this.userService = userService;
        this.employeeService = employeeService;
        this.country = country;
        this.modalService = modalService;
        this.commonService = commonService;
        this.activatedRoute = activatedRoute;
        this.formBuilder = formBuilder;
        this.jobdetails = jobdetails;
        this.candidatedetails = candidatedetails;
        this.permissiondetails = permissiondetails;
        this.dltypeService = dltypeService;
        this.companyService = companyService;
        this.alerts = alerts;
        this.check = check;
        this.router = router;
        this.route = route;
        this.zoomService = zoomService;
        this.th = [];
        this.emailexist = false;
        this.mindob = new Date();
        this.maxdob = new Date();
        this.empidexist = false;
        this.defaultVal = "";
        this.h1tag = "";
        this.employeereq = false;
        route.params.subscribe(function (params) {
            _this.emptype = params['type'];
            if (_this.emptype == 'visa') {
                _this.h1tag = "BRP";
            }
            else if (_this.emptype == 'passport') {
                _this.h1tag = "Passport";
            }
            else if (_this.emptype == 'document') {
                _this.h1tag = "Driving Licence";
            }
            // console.log(this.emptype);
        });
    }
    CheckemployeeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        // console.log(this.permission);
        if (this.permission['m101'] < 1) {
            this.router.navigate(['/permission-denied']);
        }
        // this.emptype = this.activatedRoute.snapshot.paramMap.get("type");
        this.check.getbillinginformation({ 'serviceid': 7 }).subscribe(function (remaindata) {
            _this.remainingcheck = remaindata;
        });
        this.mindob.setDate(this.mindob.getDate() - 23741);
        this.maxdob.setDate(this.maxdob.getDate() - 574);
        this.employeeForm = this.formBuilder.group({
            fname: ['', forms_1.Validators.required],
            mname: [''],
            lname: ['', forms_1.Validators.required],
            empid: ['', forms_1.Validators.required],
            gender: ['', forms_1.Validators.required],
            maritalstatus: ['', forms_1.Validators.required],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            phonenumber: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            residenceid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            dateofbirth: ['', forms_1.Validators.required],
            hiredate: ['', forms_1.Validators.required],
            emprole: ['', forms_1.Validators.required]
        });
        this.terminationForm = this.formBuilder.group({
            employeeid: ['', forms_1.Validators.required],
            terminationdate: ['', forms_1.Validators.required],
            terminationreason: ['', forms_1.Validators.required],
            oktorehire: ['', forms_1.Validators.required],
            regrettermination: ['', forms_1.Validators.required]
        });
        this.overviewData = new profile_model_1.Profile();
        this.terminationDetail = new profile_model_1.Termination();
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.jobdetails.getjobList().subscribe(function (jobdetails) {
            _this.joblist = jobdetails;
        });
        this.dltypeService.getactivepersonaltypelist().subscribe(function (dltypes) {
            _this.dltypelist = dltypes;
        });
        this.dltypeService.getdrivinglicenselist().subscribe(function (dlicense) {
            _this.dllist = dlicense;
        });
        this.permissiondetails.bindallmenu().subscribe(function (menudata) {
            var permissionstring = "[{";
            var perarray = [];
            menudata.forEach(function (valuemenu) {
                perarray.push('"' + valuemenu.clname + '": 0');
            });
            var perstr = perarray.join();
            permissionstring = permissionstring + perstr + "}]";
            _this.permissionstring = permissionstring;
        });
        this.getEmployees();
    };
    CheckemployeeComponent.prototype.getEmployees = function () {
        var _this = this;
        this.employeeService.getlistofemployeeForCheck().subscribe(function (employeedata) {
            // employeedata.forEach(function (value) {
            //   var tg;
            //   console.log(value.permission);
            //   tg = JSON.parse(value.permission.toString());
            //   tg.forEach(function (value4) {
            //   });
            // });
            _this.data = employeedata;
            _this.record = _this.data.length;
            _this.datalist = _this.data;
            _this.pageOfItems = _this.data;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    CheckemployeeComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    Object.defineProperty(CheckemployeeComponent.prototype, "f", {
        get: function () { return this.employeeForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckemployeeComponent.prototype, "g", {
        get: function () { return this.terminationForm.controls; },
        enumerable: true,
        configurable: true
    });
    CheckemployeeComponent.prototype.onVisaSubmit = function (employeeform1, modelid) {
        var _this = this;
        this.submitted = true;
        if (this.employeeForm.invalid) {
            return;
        }
        this.disableBtn = false;
        employeeform1.id = this.Userid;
        employeeform1.permission = this.permissionstring;
        this.userService.isEmailRegisterd(employeeform1.emailid).subscribe(function (data) {
            if (data.sussess == 'success') {
                _this.emailexist = true;
                _this.disableBtn = true;
            }
            else {
                _this.companyService.checkcompanyemployeeid({ 'empid': employeeform1.empid }).subscribe(function (checkempid) {
                    if (checkempid.sussess == 'success') {
                        _this.empidexist = true;
                        _this.disableBtn = true;
                    }
                    else {
                        _this.disableBtn = false;
                        _this.employeeService.checkemployeecreate(employeeform1).subscribe(function (data) {
                            _this.modalService.close(modelid);
                            _this.getEmployees();
                        });
                    }
                });
            }
        });
    };
    CheckemployeeComponent.prototype.onSubmitTermination = function (terminationform, modelid) {
        var _this = this;
        this.submitted = true;
        if (this.terminationForm.invalid) {
            return;
        }
        this.employeeService.employeeSubmitTermination(terminationform).subscribe(function (data) {
            _this.employeeService.getlistofemployeeForCheck().subscribe(function (employeedata) {
                _this.data = employeedata;
                _this.employeeService.getlistofemployeeForCheck().subscribe(function (employeedata) {
                    employeedata.forEach(function (value) {
                        var tg;
                        tg = JSON.parse(value.permission.toString());
                        tg.forEach(function (value4) {
                        });
                    });
                    _this.data = employeedata;
                    _this.record = _this.data.length;
                    if (_this.record < 30) {
                        _this.pageOfItems = _this.data;
                    }
                    else {
                        _this.items = _this.data;
                    }
                    _this.alerts.success('Employee has been terminated successfully');
                    _this.modalService.close(modelid);
                });
            });
        });
    };
    CheckemployeeComponent.prototype.onChangeStatus = function (evt, emp) {
        var _this = this;
        var status;
        if (evt.target.checked) {
            status = '1';
        }
        else if (!evt.target.checked) {
            status = '0';
        }
        this.employeeService.employeeUpdateChangeStatus({ 'userid': this.Userid, 'id': emp.employeemasterid, 'estatus': evt.target.checked }).subscribe(function (data) {
            if (status == '1') {
                _this.alerts.success('Employee has been activated successfully');
            }
            else if (status == '0') {
                _this.alerts.error('Employee has been deactivated successfully');
            }
        });
    };
    CheckemployeeComponent.prototype.focusFunction = function () {
        this.emailexist = false;
    };
    CheckemployeeComponent.prototype.isEmailUnique = function (control) {
        var _this = this;
        var q = new Promise(function (resolve, reject) {
            _this.userService.isEmailRegisterd(control.value).subscribe(function () {
                resolve(null);
            }, function () { resolve({ 'isEmailUnique': true }); });
        });
        return q;
    };
    CheckemployeeComponent.prototype.onSelectjob = function (job_id) {
        var _this = this;
        var data = { 'jobid': job_id, 'id': this.Userid };
        this.candidatedetails.getselectedcandidate(data).subscribe(function (candidatedetails) {
            return _this.candidatelist = candidatedetails;
        });
    };
    CheckemployeeComponent.prototype.onSelectCandidate = function (candidateid) {
        var _this = this;
        var data = { 'candidateid': candidateid, 'id': this.Userid };
        this.candidatedetails.getselectedcandidatebyid(data).subscribe(function (candidatedetails) {
            _this.overviewData.fname = candidatedetails.fname;
            _this.overviewData.mname = candidatedetails.mname;
            _this.overviewData.lname = candidatedetails.lname;
            _this.overviewData.phonenumber = candidatedetails.phonenumber;
            _this.overviewData.emailid = candidatedetails.emailid;
            // return this.candidatelist = candidatedetails
        });
    };
    CheckemployeeComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    CheckemployeeComponent.prototype.openModal2 = function (id) {
        var _this = this;
        this.submitted = false;
        this.disableBtn = true;
        this.overviewData = new profile_model_1.Profile();
        this.overviewData.gender = "1";
        this.overviewData.maritalstatus = "0";
        this.phonecheck = "";
        this.overviewData.jobid = "";
        this.overviewData.candidateid = "";
        this.overviewData.nationalityid = "";
        this.overviewData.residenceid = "";
        this.overviewData.countryofbirthid = "";
        this.overviewData.ismail = false;
        this.companyService.checkPlan({ 'userid': this.Userid }).subscribe(function (checkemployeecount) {
            if (checkemployeecount.success == "true") {
                _this.modalService.open(id);
            }
            else {
                _this.modalService.open("upgrade-plan");
            }
        });
    };
    CheckemployeeComponent.prototype.closeModal = function (id) {
        var _this = this;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.modalService.close(id);
    };
    CheckemployeeComponent.prototype.openModelTerminate = function (empid, id) {
        this.submitted = false;
        this.terminationDetail.employeeid = empid;
        this.terminationDetail.oktorehire = "";
        this.terminationDetail.regrettermination = "";
        this.modalService.open(id);
    };
    CheckemployeeComponent.prototype.sendrequestcharge = function (employeeid, id) {
        var _this = this;
        var ts = { employeeid: employeeid, requesttype: this.emptype };
        this.check.genratetokenforemployee(ts).subscribe(function (remaindata) {
            _this.alerts.success('Application Request Sent Successfully');
            _this.modalService.close(id);
        });
    };
    CheckemployeeComponent.prototype.sendrequest = function (employeeid, id, emp_req) {
        if (emp_req === void 0) { emp_req = ''; }
        if (this.remainingcheck && this.remainingcheck.remiancheck < 1) {
            this.modalService.open('upgrade-plan');
        }
        else {
            if (emp_req) {
                this.employeereq = false;
            }
            else {
                this.employeereq = true;
            }
            this.modalService.open(id);
            this.employeeid = employeeid;
        }
    };
    // confirmUploadByAdmin()
    // {
    //   if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
    //   {
    //     this.modalService.open('upgrade-plan');
    //   }
    //   else
    //   {
    //     this.employeeid = employeeid;
    //     this.modalService.open('sendrequestcharge');
    //   }
    // }
    CheckemployeeComponent.prototype.uploadByAdmin = function (emp_type, type, empid) {
        if (type == 'upload_now') {
            if (emp_type == 'visa') {
                this.router.navigate(['/check-ai-visa-admin', empid]);
            }
            else if (emp_type == 'passport') {
                this.router.navigate(['/check-ai-passport-admin', empid]);
            }
            else if (emp_type == 'document') {
                this.router.navigate(['/check-ai-document-admin', empid]);
            }
        }
        else if (type == 'add_new') {
            if (this.remainingcheck && this.remainingcheck.remiancheck < 1) {
                this.modalService.open('upgrade-plan');
            }
            else {
                this.router.navigate(['/check-add-applicant', emp_type]);
                // this.router.navigate(['/check-add-applicant', 'passport']);
            }
            // upgrade-plan
        }
    };
    return CheckemployeeComponent;
}());
exports.CheckemployeeComponent = CheckemployeeComponent;
