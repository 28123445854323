<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__content">
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="space_em row">
                                <div class="col-lg-8">
                                    <h5 class="hedingcolor">Client List <a (click)="openModal2('custom-client-1')" class="mainheading_btn" *ngIf="clientperm > 1"> + Add New </a></h5>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group">
                                        <input [(ngModel)]="searchText" placeholder="Search.." class="form-control">
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="( datalist |  filter:{companyname: searchText,clientname: searchText,emailid: searchText,phonenumber: searchText }) as pageOfItems">
                      
                            <div class="job_list_section employee_list_section" *ngFor="let client of pageOfItems; let i=index ">
                                <div class="job_detils_date width40">
                                    <h5 class="job_profile font_17">{{client.companyname}}</h5>
                                    <p class="email"><i aria-hidden="true" class="fa fa-envelope-o"></i> {{client.emailid}}</p>
                                    <p class="phone"><i aria-hidden="true" class="fa fa-phone"></i> {{client.phonenumber }}</p>
                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address"> Client Name</p>
                                    <p class="job_profile">{{client.clientname}}</p>
                                </div>
                                <div class="job_detils_date visa_btn">
                                    <div class="job_address d-flex aligncenter">
                                        <button type="submit" class="btn btn-secondary com_btn" *ngIf="clientperm > 0" (click)="openModal('custom-client-view-1',client)">
                                            <div class="tooltip_visa">
                                                <p >Details</p>
                                            </div>
                                            <i class="fa fa-clipboard"></i></button>
                                        <button type="submit" class="btn btn-primary com_btn" *ngIf="clientperm > 2" (click)="openModal('custom-client-1',client)">
                                            <div class="tooltip_visa">
                                                <p >Edit</p>
                                            </div>											
                                            <i class="fa fa-pencil"></i></button>
                                        <!-- <button type="submit" class="btn btn-secondary com_btn" (click)="openModal('custom-passport-1',passport)"><i class="fa fa-trash-o"></i></button> -->
                                        <div class="onoffswitch" *ngIf="clientperm > 2">
                                            <label class="onoffswitch-label" for="skill{{i+1}}" class="switch">
                                                <input type="checkbox"  name="skill{{i+1}}" type="checkbox" [checked]="client.cstatus=='1'" (change)="onChangeStatus($event, client)" id="skill{{i+1}}">
                                                <span class="slider round"></span>
                                              </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>
                        </ng-container>
                            <!-- <div class="job_list_section visa_request_section" *ngIf="record === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div> -->
                            <!-- <div class="row">
                                <div class="col-lg-12 text-right"  *ngIf="record > 30">
                                    <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<jw-modal id="custom-client-1" role="dialog" class="modal">
    <form [formGroup]="clientformGroup" #clientForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(clientForm.value,'custom-client-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" class="form-control" name="id" id="id" [ngModel]="FormClient.clientid">
                    <h5 class="modal-title" id="popupLabel">Client Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-client-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label>Company Name*</label>
                                <input type="text" formControlName="companyname" class="" name="companyname" id="companyname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.companyname.errors }" [ngModel]="FormClient.companyname">
                                <div *ngIf="submitted && f.companyname.errors" class="invalid-feedback">
                                    <div *ngIf="f.companyname.errors.required">Please enter company name</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Company Type</label>
                                <select style="width:100%;" formControlName="companytypeid" name="companytypeid" id="companytypeid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.companytypeid.errors }" [ngModel]="FormClient.companytypeid">
                                    <option [value]="emptyValue">Select Company Type </option>
                                    <option *ngFor="let companytype1 of companytype" [value]="companytype1.companytypeid">
                                        {{ companytype1.companycode }} - {{ companytype1.typename }}</option>
                                </select>
                                <div *ngIf="submitted && f.companytypeid.errors" class="invalid-feedback">
                                    <div *ngIf="f.companytypeid.errors.required">Please select company type</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Web URL </label>
                                <input type="text" formControlName="websiteurl" name="websiteurl" id="websiteurl" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.websiteurl.errors }" [ngModel]="FormClient.websiteurl">
                                <div *ngIf="submitted && f.websiteurl.errors" class="invalid-feedback">
                                    <div *ngIf="f.websiteurl.errors.required">Please enter contact name</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Contact Name*</label>
                                <input type="text" formControlName="clientname" name="clientname" id="clientname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.clientname.errors }" [ngModel]="FormClient.clientname">
                                <div *ngIf="submitted && f.clientname.errors" class="invalid-feedback">
                                    <div *ngIf="f.clientname.errors.required">Please enter contact name</div>
                                </div>
                            </div>

                            <div class="form-group col-lg-6">
                                <label>Role*</label>
                                <input type="text" formControlName="clietdesignation" name="clietdesignation" id="clietdesignation" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.clietdesignation.errors }" [ngModel]="FormClient.clietdesignation">
                                <div *ngIf="submitted && f.clietdesignation.errors" class="invalid-feedback">
                                    <div *ngIf="f.clietdesignation.errors.required">Please enter role</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Email*</label>
                                <input type="email" formControlName="emailid" name="emailid" id="emailid" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }" [ngModel]="FormClient.emailid">
                                <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                                    <div *ngIf="f.emailid.errors.required">Please enter email </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Alternative Email </label>
                                <input type="email" formControlName="secondryemailid" name="secondryemailid" id="secondryemailid" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.secondryemailid.errors }" [ngModel]="FormClient.secondryemailid">
                                <div *ngIf="submitted && f.secondryemailid.errors" class="invalid-feedback">
                                    <div *ngIf="f.secondryemailid.errors.required">Please enter email </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Mobile Number*</label>
                                <international-phone-number formControlName="phonenumber" class="form-control phonecontrol" [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" [ngModel]="FormClient.phonenumber" placeholder="Enter mobile number" (input)="validatenumber($event.target.value,'mobile')"
                                    [maxlength]="20" [required]="true" #phonenumber name="phonenumber"></international-phone-number>
                                <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.phonenumber.errors.required">Please enter mobile number</div>
                                </div>
                                <span *ngIf="mobilecheck===false" style="color:red">Invalid format</span>
                                <span *ngIf="mobilecheck===true" style="color:green">Valid format</span>
                            </div>

                            <div class="form-group col-lg-6">
                                <label>Phone Number</label>
                                <international-phone-number formControlName="sphonenumber" class="form-control phonecontrol" [ngClass]="{ 'is-invalid': submitted && f.sphonenumber.errors }" [ngModel]="FormClient.sphonenumber" placeholder="Enter mobile number" (input)="validatenumber($event.target.value,'phone')"
                                    [maxlength]="20" #phonenumber name="sphonenumber"></international-phone-number>
                                <!-- <div *ngIf="submitted && f.sphonenumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.sphonenumber.errors.required">Please enter phone number</div>
                                </div> -->
                                <span *ngIf="phonecheck===false" style="color:red">Invalid format</span>
                                <span *ngIf="phonecheck===true" style="color:green">Valid format</span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Postcode*</label>
                                <input type="text" formControlName="pincode" id="pincode" name="pincode" class="form-control pincode" [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }" [ngModel]="FormClient.pincode" (input)="onSearchChange($event.target.value)" matInput
                                    [matAutocomplete]="auto" (maxlength)="7">
                                <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                    <div *ngIf="f.pincode.errors.required">Please enter postcode</div>
                                </div>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="pincode">
                                    <mat-option *ngFor="let option of options" [value]="option" (onSelectionChange)="updatecode($event, option)">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Address Line 1*</label>
                                <input type="text" formControlName="address1" name="address1" id="address1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" [ngModel]="FormClient.address1">
                                <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
                                    <div *ngIf="f.address1.errors.required">Please enter address</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Address Line 2 </label>
                                <input type="text" formControlName="address2" name="address2" id="address2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" [ngModel]="FormClient.address2">
                                <div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
                                    <div *ngIf="f.address2.errors.required">Please enter address</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>City*</label>
                                <input type="text" formControlName="cityname" class="" name="cityname" id="cityname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.cityname.errors }" [ngModel]="FormClient.cityname">
                                <div *ngIf="submitted && f.cityname.errors" class="invalid-feedback">
                                    <div *ngIf="f.cityname.errors.required">Please enter city</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>County*</label>
                                <input type="text" formControlName="statename" name="statename" id="statename" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.statename.errors }" [ngModel]="FormClient.statename">
                                <div *ngIf="submitted && f.statename.errors" class="invalid-feedback">
                                    <div *ngIf="f.statename.errors.required">Please enter county</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label>Country*</label>
                                <select style="width:100%;" formControlName="countryid" name="countryid" id="countryid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.countryid.errors }" [ngModel]="FormClient.countryid">
                  <option [value]="">Select Country </option>
                  <option *ngFor="let country of countrylist" [value]="country.countryid">{{ country.countryname }}
                  </option>
                </select>
                                <div *ngIf="submitted && f.countryid.errors" class="invalid-feedback">
                                    <div *ngIf="f.countryid.errors.required">Please select country</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-client-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>

<jw-modal id="custom-client-view-1" role="dialog" class="modal">
    <div role="document" class="formgroup" *ngIf="clientDetail.companytype">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="popupLabel">Client Details</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-client-view-1');">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="white_box_shadow pro_plan_details width100 admin_pro_footer">
                        <h4>Company Detail</h4>
                        <div class="pro_footer_content mb-3">
                            <label><i class="fa fa-building-o" aria-hidden="true"></i>Company Name</label>
                            <p>{{clientDetail.companyname}}</p>
                        </div>
                        <div class="pro_footer_content mb-3" *ngIf="clientDetail.websiteurl">
                            <label><i class="fa fa-globe" aria-hidden="true"></i>Website</label>
                            <p><a href="http://{{clientDetail.websiteurl.replace('https://','').replace('http://','') }}" target="_blank">{{clientDetail.websiteurl}}</a></p>
                        </div>
                        <div class="pro_footer_content mb-3">
                            <label><i class="fa fa-building-o" aria-hidden="true"></i> Company Type </label>
                            <p>{{clientDetail.companytype.typename}}</p>
                        </div>
                        <div class="pro_footer_content mb-3">
                            <label><i class="fa fa-address-book-o" aria-hidden="true"></i> Address </label>
                            <p>{{clientDetail.address1}}</p>
                        </div>
                        <div class="pro_footer_content  mb-3 width100">
                            <label><i class="fa fa-address-book-o" aria-hidden="true"></i> Full Address </label>
                            <p>{{clientDetail.address2}} {{clientDetail.cityname}}, {{clientDetail.statename}} {{clientDetail.country.countryname}} - {{clientDetail.pincode}}</p>
                        </div>
                    </div>
                    <div class="white_box_shadow pro_plan_details width100 admin_pro_footer">
                        <h4>Contact Detail</h4>
                        <div class="pro_footer_content mb-3">
                            <label><i class="fa fa-building-o" aria-hidden="true"></i> Contact Name</label>
                            <p>{{clientDetail.clientname}}</p>
                        </div>
                        <div class="pro_footer_content mb-3">
                            <label><i class="fa fa-globe" aria-hidden="true"></i>Role</label>
                            <p>{{clientDetail.clietdesignation}}</p>
                        </div>
                        <div class="pro_footer_content mb-3">
                            <label><i class="fa fa-envelope-o" aria-hidden="true"></i>Email</label>
                            <p>{{clientDetail.emailid}}</p>
                        </div>
                        <div class="pro_footer_content mb-3" *ngIf="clientDetail.secondryemailid">
                            <label><i class="fa fa-envelope-o" aria-hidden="true"></i>Alternate Email</label>
                            <p>{{clientDetail.secondryemailid}}</p>
                        </div>
                        <div class="pro_footer_content mb-3">
                            <label><i class="fa fa-phone" aria-hidden="true"></i>Mobile Number </label>
                            <p>{{clientDetail.phonenumber}}</p>
                        </div>
                        <div class="pro_footer_content mb-3" *ngIf="clientDetail.sphonenumber">
                            <label><i class="fa fa-mobile" aria-hidden="true"></i>Phone Number </label>
                            <p>{{clientDetail.sphonenumber}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">

                            <!-- <div class="card mt-6">
                                <div class="card-block jdets">
                                    <h6><b> </b><span class="text-uppercase"></span></h6>
                                    <p ><b></b></p>
                                    <p><b></b> </p>
                                    <p class="m-b-0"><b> </b></p>
                                    <p class="m-b-0"></p>
                                    <p class="m-b-0"></p>
                                    <p class="m-b-0"></p>
                                </div>
                            </div> -->
                        </div>

                        <!-- <div class="col-md-6">
                            <div class="card mt-6">
                                <div class="card-block jdets">
                                    <p><b> : </b></p>
                                    <p><b> : </b></p>
                                    <p><b> : </b></p>
                                    <p ><b>: </b></p>
                                    <p><b>: </b></p>
                                    <p ><b> : </b></p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <input type="button" class="btn btn-primary com_btn" name="cancel" value="Cancel" (click)="closeModal('custom-client-view-1');" />
            </div>
        </div>
    </div>

</jw-modal>