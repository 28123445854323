export class Expensetype {
    id: number;
    typename: string;
    htype: number;
  }

  export class Hourtype {
    id: number;
    typename: string;
  }

  export class Holidaytype {
    id: number;
    typename: string;
    htype: string;
    noofholiday: number;
  }