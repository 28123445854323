import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class TerminationreasonService {

  constructor(
    private commonService: CommonService
  ) { }

  getterminationreasonlist(): Observable<any> {
    return this.commonService.formget('/company/terminationreasonlist');
  }

  updateTerminationReason(data: any): Observable<any> {
    return this.commonService.formpost('/company/updateterminationreason', data);
  }

  getParentTerminationReasonList(data: any): Observable<any> {
    return this.commonService.formpost('/company/parentorchildterminationreason', data);
  }

  getChildTerminationReasonList(data: any): Observable<any> {
    return this.commonService.formpost('/company/parentorchildterminationreason', data);
  }

}
