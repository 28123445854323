"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var terminationreason_service_1 = require("../../../services/terminationreason.service");
var terminationreason_model_1 = require("../../../models/setting/terminationreason.model");
var modal_service_1 = require("../../../_services/modal.service");
var app_global_1 = require("../../../app.global");
var TerminationReasonComponent = /** @class */ (function () {
    function TerminationReasonComponent(tReasonService, formBuilder, modalService) {
        this.tReasonService = tReasonService;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.submitted = false;
        this.selectOptions = [];
    }
    TerminationReasonComponent.prototype.ngOnInit = function () {
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        this.year = new Date().getFullYear();
        this.userid = localStorage.getItem('userid');
        this.terminationReasonForm = this.formBuilder.group({
            id: [''],
            title: ['', forms_1.Validators.required],
            parentid: ['']
        });
        this.getParentCategories();
        // this.createTerminationReasonFormGroup();
        this.getTerminationReasonList();
        this.trDetail = new terminationreason_model_1.TerminationReason();
    };
    Object.defineProperty(TerminationReasonComponent.prototype, "g", {
        get: function () { return this.terminationReasonForm.controls; },
        enumerable: true,
        configurable: true
    });
    TerminationReasonComponent.prototype.onChangePage = function (pageOfItems) {
        this.pageOfItems = pageOfItems;
    };
    TerminationReasonComponent.prototype.getParentCategories = function () {
        var _this = this;
        var value = { parentid: 0 };
        this.tReasonService.getParentTerminationReasonList(value).subscribe(function (parentterminationreasons) {
            _this.parentCategoryList = parentterminationreasons;
        });
    };
    TerminationReasonComponent.prototype.getTerminationReasonList = function () {
        var _this = this;
        this.tReasonService.getterminationreasonlist().subscribe(function (terminationdata) {
            _this.data = terminationdata;
            _this.record = _this.data.length;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    TerminationReasonComponent.prototype.onSubmitTerminationReason = function (terminationReasonData, modelId) {
        var _this = this;
        this.submitted = true;
        if (this.terminationReasonForm.invalid) {
            return;
        }
        var postData = {
            'id': terminationReasonData.id,
            'parentid': terminationReasonData.parentid,
            'title': terminationReasonData.title
        };
        this.tReasonService.updateTerminationReason(postData).subscribe(function (resp) {
            if (resp.success) {
                _this.getTerminationReasonList();
                _this.modalService.close(modelId);
            }
            else {
            }
        });
    };
    TerminationReasonComponent.prototype.addTerminationReason = function () {
        var _this = this;
        this.selectOptions = [];
        this.parentCategoryList.forEach(function (element) {
            _this.selectOptions.push(element);
        });
        this.trDetail = new terminationreason_model_1.TerminationReason();
        this.trDetail.parentid = '0';
        this.trDetail.id = '0';
        this.trDetail.title = '';
        this.terminationReasonForm.reset(this.trDetail);
        this.modalService.open('termination-reason-model');
    };
    TerminationReasonComponent.prototype.editTerminationReason = function (tr) {
        var _this = this;
        this.selectOptions = [];
        this.parentCategoryList.forEach(function (element) {
            if (tr.id != element.id) {
                _this.selectOptions.push(element);
            }
        });
        this.trDetail = new terminationreason_model_1.TerminationReason();
        this.trDetail.parentid = tr.parentid;
        this.trDetail.id = tr.id;
        this.trDetail.title = tr.title;
        this.terminationReasonForm.reset(this.trDetail);
        this.modalService.open('termination-reason-model');
    };
    TerminationReasonComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    TerminationReasonComponent.prototype.yearChange = function (year) {
        this.year = year;
        this.getTerminationReasonList();
    };
    return TerminationReasonComponent;
}());
exports.TerminationReasonComponent = TerminationReasonComponent;
