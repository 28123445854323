"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var modal_service_1 = require("../../../_services/modal.service");
var ObjectiveComponent = /** @class */ (function () {
    function ObjectiveComponent(modalService, formBuilder) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
    }
    ObjectiveComponent.prototype.ngOnInit = function () {
        this.employeeForm = this.formBuilder.group({
            fname: ['', forms_1.Validators.required],
            mname: [''],
            lname: ['', forms_1.Validators.required],
            empid: ['', forms_1.Validators.required],
            gender: ['', forms_1.Validators.required],
            placeofbirth: ['', forms_1.Validators.required],
            maritalstatus: ['', forms_1.Validators.required],
            ninumber: ['', forms_1.Validators.required],
            jobid: [''],
            candidateid: [''],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            phonenumber: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            residenceid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            countryofbirthid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            dateofbirth: ['', forms_1.Validators.required],
            hiredate: ['', forms_1.Validators.required],
            emprole: ['', forms_1.Validators.required],
            ismail: ['']
        });
    };
    Object.defineProperty(ObjectiveComponent.prototype, "f", {
        get: function () { return this.employeeForm.controls; },
        enumerable: true,
        configurable: true
    });
    ObjectiveComponent.prototype.openModal2 = function (id) {
        this.modalService.open(id);
    };
    ObjectiveComponent.prototype.onSelectjob = function (d) { };
    ObjectiveComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return ObjectiveComponent;
}());
exports.ObjectiveComponent = ObjectiveComponent;
