"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var payment_service_1 = require("../../../services/payment.service");
var modal_service_1 = require("../../../_services/modal.service");
var router_1 = require("@angular/router");
var stripe_angular_1 = require("stripe-angular");
var alert_service_1 = require("../../../services/alert.service");
var company_service_1 = require("../../../services/company.service");
var app_global_1 = require("../../../app.global");
var address_model_1 = require("../../../models/address/address.model");
var country_service_1 = require("../../../services/country.service");
var PaymentmethodComponent = /** @class */ (function () {
    function PaymentmethodComponent(formBuilder, modalService, router, alerts, companyService, country, StripeScriptTag, paymentService) {
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.router = router;
        this.alerts = alerts;
        this.companyService = companyService;
        this.country = country;
        this.StripeScriptTag = StripeScriptTag;
        this.paymentService = paymentService;
        this.publishableKey = app_global_1.AppGlobals.API_STRIPE;
        this.address = new address_model_1.Address();
    }
    PaymentmethodComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.router.navigate(['check-payment-method']);
        this.StripeScriptTag.setPublishableKey(this.publishableKey);
        this.addpopForm = this.formBuilder.group({
            cardname: ['', forms_1.Validators.required],
            address1: ['', forms_1.Validators.required],
            address2: [''],
            cityname: ['', forms_1.Validators.compose([forms_1.Validators.required,])],
            statename: ['', forms_1.Validators.required],
            pincode: ['', forms_1.Validators.required],
            stateid: [''],
            taxid: [''],
            countryid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])]
        });
        this.addresslistDetail = new address_model_1.Address();
        this.addresslistDetail.countryid = "70";
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.companyService.getcompanyData().subscribe(function (data) {
            _this.currentprice = data.plan.prices;
            _this.companyData = data;
            // console.log(this.companyData);
        });
        this.companyService.gettotalcards().subscribe(function (data) {
            _this.defaultcard = data.customer.default_source;
            _this.cardlist = data.card;
            // this.cardlist = data;
        });
    };
    Object.defineProperty(PaymentmethodComponent.prototype, "g", {
        get: function () { return this.addpopForm.controls; },
        enumerable: true,
        configurable: true
    });
    PaymentmethodComponent.prototype.removeCard = function (id) {
        var _this = this;
        this.cardvalue = { "id": id };
        //  console.log(id);
        this.paymentService.removeCard({ "id": id }).subscribe(function (data) {
            // console.log(data);
            _this.companyService.gettotalcards().subscribe(function (data) {
                _this.cardlist = data;
            });
            // this.cardlist = data.data;
        });
    };
    PaymentmethodComponent.prototype.setStripeToken = function (token, formvalue, id) {
        var _this = this;
        var data = {
            "id": token.id,
            "customer": this.companyData.stuser,
            "cmonth": token.card.exp_month,
            "cyear": token.card.exp_year,
            "cardnumber": token.card.last4,
            "tokenid": token.card.id,
            "cardname": token.card.brand,
            "country": token.card.country,
            "cstatus": "1",
            "cardholdername": formvalue.cardname,
            "address1": formvalue.address1,
            "address2": formvalue.address2,
            "cityname": formvalue.cityname,
            "statename": formvalue.statename,
            "countryid": formvalue.countryid,
            "postcode": formvalue.pincode,
            "taxid": formvalue.taxid
        };
        // console.log(data);
        this.modalService.close(id);
        this.paymentService.addnewCard(data).subscribe(function (data) {
            if (data.success == "success") {
                _this.paymentService.getTotalCards().subscribe(function (data) {
                    _this.alerts.success("Card has been successfully added.", true);
                    _this.cardlist = data;
                });
            }
        });
        // console.log(token.card);
    };
    PaymentmethodComponent.prototype.onStripeError = function (error) {
        console.error('Stripe error', error);
    };
    PaymentmethodComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    PaymentmethodComponent.prototype.updateyourplan = function (id) {
        this.modalService.open(id);
    };
    return PaymentmethodComponent;
}());
exports.PaymentmethodComponent = PaymentmethodComponent;
