"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var upload_file_service_1 = require("./../../services/upload-file.service");
var myvisa_service_1 = require("../../services/myvisa.service");
var platform_browser_1 = require("@angular/platform-browser");
var checksystem_service_1 = require("../../services/checksystem.service");
var AidocumentComponent = /** @class */ (function () {
    function AidocumentComponent(uploadService, data, sanitizer, check) {
        this.uploadService = uploadService;
        this.data = data;
        this.sanitizer = sanitizer;
        this.check = check;
    }
    AidocumentComponent.prototype.ngOnInit = function () {
        this.filename = 'Choose file';
        this.imageurl = '';
        this.orgcode = '';
        this.stage = 0;
        this.orgerror = false;
        this.loading = 1;
        this.error = false;
        this.iframevisasrc = '';
        this.oldbill = '';
        this.fileformaterror = false;
    };
    AidocumentComponent.prototype.selectFile = function (event) {
        var _this = this;
        this.iframevisasrc = '';
        this.output = null;
        // this.loading=0;
        this.filesizeerror = false;
        this.filerequirederror = false;
        this.fileformaterror = false;
        var files = event.target.files;
        this.filename = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "jpeg")) {
            this.fileformaterror = true;
        }
        else {
            var imagepath = this.uploadService.uploadfile(this.file);
            // setTimeout(() => this.loading=1, 5000);
            setTimeout(function () { return _this.imageurl = imagepath; }, 2000);
            // setTimeout(() => this.iframevisasrc = this.getSafeUrl("https://docs.google.com/gview?url=" + this.imageurl + "&embedded=true"), 7000);
            // this.imageurl = imagepath;
            // ;
            this.filerequirederror = false;
            console.log(this.imageurl);
            this.selectedFiles = files[0];
        }
    };
    AidocumentComponent.prototype.changeOrganization = function (id) {
        console.log(id);
        this.orgcode = id;
        if (id != '') {
            this.orgerror = false;
        }
    };
    AidocumentComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    AidocumentComponent.prototype.clearData = function () {
        this.imageurl = '';
        this.iframevisasrc = '';
        this.filerequirederror = false;
        this.orgerror = false;
        this.stage = 0;
        this.orgcode = '';
        this.error = false;
        this.filename = 'Choose file';
    };
    AidocumentComponent.prototype.getRemainingChecks = function () {
        var _this = this;
        var ts = { 'serviceid': 8 };
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            _this.checkcount = remaindata;
        });
    };
    AidocumentComponent.prototype.submitdata = function () {
        var _this = this;
        if (this.imageurl == '' || this.orgcode == '') {
            if (this.imageurl == '') {
                this.filerequirederror = true;
            }
            if (this.orgcode == '') {
                this.orgerror = true;
            }
            return;
        }
        this.iframevisasrc = '';
        this.output = null;
        this.loading = 0;
        setTimeout(function () { return _this.iframevisasrc = _this.getSafeUrl("https://docs.google.com/gview?url=" + _this.imageurl + "&embedded=true"); }, 7000);
        setTimeout(function () { return _this.loading = 1; }, 7000);
    };
    AidocumentComponent.prototype.reload = function () {
        this.iframevisasrc = '';
        this.stage = 1;
        this.iframevisasrc = this.getSafeUrl("https://docs.google.com/gview?url=" + this.imageurl + "&embedded=true");
        this.stage = 0;
    };
    AidocumentComponent.prototype.uploadfiletoAIverification = function () {
        var _this = this;
        this.loading = 0;
        this.stage = 1;
        if (this.imageurl != '') {
            this.iframevisasrc = '';
            var value3 = { invoiceurl: this.imageurl, modelid: this.orgcode };
            this.data.aidocumentverification(value3).subscribe(function (data) {
                // this.output = data; 
                // this.error = false;
                // this.loading=1;
                // this.stage =1;
                if (data.name || data.billdate || data.amount || data.accountnumber || data.address) {
                    _this.output = data;
                    _this.error = false;
                    _this.loading = 1;
                    _this.stage = 1;
                }
                else {
                    _this.error = true;
                    _this.stage = 1;
                    _this.loading = 1;
                    _this.output = null;
                }
            });
        }
        else {
            this.stage = 0;
        }
    };
    return AidocumentComponent;
}());
exports.AidocumentComponent = AidocumentComponent;
