"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var worklist_service_1 = require("./../../services/worklist.service");
var modal_service_1 = require("../../_services/modal.service");
var app_global_1 = require("../../app.global");
var WorklistreportComponent = /** @class */ (function () {
    function WorklistreportComponent(dataset, modalService) {
        this.dataset = dataset;
        this.modalService = modalService;
        this.monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];
    }
    WorklistreportComponent.prototype.ngOnInit = function () {
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        this.month = (new Date()).getMonth();
        console.log(this.month);
        this.year = (new Date()).getFullYear();
        this.yearmonth = this.monthNames[this.month] + ' ' + this.year;
        this.classactive = ((new Date()).getMonth() + 1).toString();
        this.bindalldata();
    };
    WorklistreportComponent.prototype.bindalldata = function () {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
        this.dataset.getemployeeworklistreport(data).subscribe(function (data) {
            _this.record = data.length;
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.items = data;
            }
            // this.data = data;
        });
    };
    WorklistreportComponent.prototype.onyearSubmit = function (year) {
        this.year = year;
        var month = this.month;
        console.log(month);
        this.yearmonth = this.monthNames[month] + ' ' + this.year;
        this.bindalldata();
        // this.modalService.close(id);
    };
    WorklistreportComponent.prototype.yearpop = function (id) {
        this.modalService.open(id);
    };
    WorklistreportComponent.prototype.getDataByMonth = function (mon) {
        // console.log(this.year);
        this.classactive = mon;
        this.month = mon - 1;
        this.yearmonth = this.monthNames[this.month] + ' ' + this.year;
        this.bindalldata();
        // const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
        // this.dataset.getemployeeworklistreport(data).subscribe((data: any) => {
        //  this.data = data;
        // });
    };
    WorklistreportComponent.prototype.openModalnote = function (data, id) {
        this.notedetails = data;
        this.modalService.open(id);
    };
    WorklistreportComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    WorklistreportComponent.prototype.getDataByYear = function (yea) {
        this.year = yea;
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + yea;
        this.bindalldata();
        // const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
        // this.dataset.getemployeeworklistreport(data).subscribe((data: any) => {
        //  this.data = data;
        // });
    };
    return WorklistreportComponent;
}());
exports.WorklistreportComponent = WorklistreportComponent;
