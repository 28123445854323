"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var contact_service_1 = require("../services/contact.service");
var alert_service_1 = require("../services/alert.service");
var router_1 = require("@angular/router");
var SupportComponent = /** @class */ (function () {
    function SupportComponent(router, formBuilder, contactService, alerts) {
        this.router = router;
        this.formBuilder = formBuilder;
        this.contactService = contactService;
        this.alerts = alerts;
        this.defaultVal = "";
    }
    SupportComponent.prototype.ngOnInit = function () {
        this.createformgroup();
        this.submitted = false;
    };
    Object.defineProperty(SupportComponent.prototype, "f", {
        get: function () {
            return this.supportFormgroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    SupportComponent.prototype.createformgroup = function () {
        this.supportFormgroup = this.formBuilder.group({
            fname: ['', forms_1.Validators.required],
            bussinessemail: ['', forms_1.Validators.required],
            phonenumber: ['', forms_1.Validators.required],
            querytype: ['', forms_1.Validators.required],
            subject: ['', forms_1.Validators.required],
            message: ['', forms_1.Validators.required]
        });
    };
    SupportComponent.prototype.onSubmit = function (supportFormValues, id) {
        var _this = this;
        this.submitted = true;
        if (this.supportFormgroup.invalid) {
            return;
        }
        // this.submitted = true;
        supportFormValues.mailertype = 'Contact Us';
        this.contactService.contactus(supportFormValues).subscribe(function (data) {
            _this.submitted = false;
            _this.createformgroup();
            _this.alerts.success("Query submitted successfully", false);
            // this.router.navigate(['/check-dashboard']);
            // this.modalService.close(id);
        });
    };
    SupportComponent.prototype.clearData = function () {
        this.submitted = false;
        this.createformgroup();
    };
    return SupportComponent;
}());
exports.SupportComponent = SupportComponent;
