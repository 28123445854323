import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';
import { HttpClient, HttpHandler, HttpParams, HttpHeaders } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class ReferenceService {

  
  constructor(private httpClients: HttpClient, private commonService: CommonService) { }

  getemplatelistBycompany(): Observable<any> {
    return this.commonService.formget('/company/referenceprofilelist');
  }

  createNewRequest(data): Observable<any> {
    return this.commonService.formpost('/reference/referencerequest',data);
  }

  createBulkRequests(data): Observable<any> {
    return this.commonService.formpost('/reference/bulkreferencerequest',data);
  }

  getreferencerequestall(data): Observable<any> {
    return this.commonService.formpost('/reference/referencerequestbyadmin', data);
  }

  downloadReferee(data): Observable<any> {
    return this.commonService.formpost('/referee/downloadreferee', data);
  }

  downloadAllReferee(data): Observable<any> {
    return this.commonService.formpost('/referee/downloadallreferee', data);
  }

  updatereferencerequest(data): Observable<any> {
    return this.commonService.formpost('/referencerequestpatchcall',data);
  }

  getrefereedetail(data): Observable<any> {
    return this.commonService.refereeformget('/reference/referee/'+data);
  }

  referencecreatedby(data): Observable<any> {
    return this.commonService.formpost('/company/referencecreatedby',data);
  }


  getrefereeresponse(data): Observable<any> {
    return this.commonService.formget('/reference/refereeresponse/'+data);
  }

  getrefereesetting(): Observable<any> {
    return this.commonService.formget('/company/referencesetting');
  }
  postrefereesetting(data): Observable<any> {
    return this.commonService.formpost('/company/createandupdatereferencesetting', data);
  }

  listCredits(data): Observable<any> {
    return this.commonService.formpost('/referee',data);
  }

  // downloadfile(filename, token): Observable<ArrayBuffer> {
  //   let params = new HttpParams();
  //   params = params.append('access_token', token);
  //   return this.httpClients.get(this.apiRoutes.downloadfile + '/' + filename, { params: params, responseType: 'arraybuffer' }).pipe(
  //     map((file: ArrayBuffer) => {
  //       return file;
  //     })
  //   );
  // }
  getreferee(data): Observable<any> {
    return this.commonService.formpostwithouttoken('/referee/getallrefereedata',data);
  }
  getdesignations(): Observable<any> {
    return this.commonService.formgetwithouttoken('/getdesignations');
  }
  getreferencerequest(rd): Observable<any> {
    return this.commonService.refereeformget('/reference/referencerequest/'+ rd);
  }

  getcompanydetail(data): Observable<any> {
    return this.commonService.formpost('/referee',data);
  }

  createbulkreferencerequests(data): Observable<any> {
    return this.commonService.formpost('/referee',data);
  }
  getCompniesCreditSummary(data): Observable<any> {
    return this.commonService.formpost('/referee',data);
  }

  getbulkreferencerequests(): Observable<any> {

    return this.commonService.formget('/reference/bulkreferencerequest');
  }

  createreferee(data): Observable<any> {
    return this.commonService.refereeformpost('/reference/referee',data);
  }
  

  // getreferencerequest(data): Observable<any> {
  //   return this.commonService.formpost('/referee',data);
  // }

  

  getrefereedetailbyId(data): Observable<any> {
    return this.commonService.formpost('/referee',data);
  }


  updatereferee(data): Observable<any> {
    return this.commonService.formpatchwithouttoken('/reference/referee',data);
  }

  createrefereeresponse(data): Observable<any> {
    return this.commonService.formpostwithouttoken('/reference/refereeresponse',data);
  }
  

  updatequestionrequests(data): Observable<any> {
    return this.commonService.formpatch('/reference/modulequestiontemplatedetail',data);
  }


  postquestiontemplates(data): Observable<any> {
    return this.commonService.formpost('/reference/modulequestiontemplate',data);
  }


  getquestiontemplatedetails(data): Observable<any> {
    return this.commonService.formget('/reference/modulequestiontemplatedetail/'+data);
  }

  createquestionrequests(data): Observable<any> {
    console.log(data);
    return this.commonService.formpost('/reference/modulequestionadd',data);
  }


  deletequestionequests(data): Observable<any> {
    return this.commonService.formpatch('/reference/modulequestiontemplatedetail',data);
  }

  getquestiontemplates(): Observable<any> {
    return this.commonService.formget('/reference/modulequestiontemplate');
  }

  createNewTemplate(data): Observable<any> {
    return this.commonService.formpost('/reference/modulequestiontemplate',data);
  }

  updateTemplate(data): Observable<any> {
    return this.commonService.formpatch('/reference/modulequestiontemplate',data);
  }
  
  deleteTemplate(data): Observable<any> {
    return this.commonService.formpatch('/reference/modulequestiontemplate',data);
  }

  resendReferenceEmail(data): Observable<any> {
    return this.commonService.formpost('/referece/resendemail', data);
  }

  resendRefereeEmail(data): Observable<any> {
    return this.commonService.refereeformpost('/referee/resendemail', data);
  }

  declinereferencerequest(data): Observable<any> {
    return this.commonService.refereeformpatch('/company/referencerequest', data);
  } 

  declinerefreerequest(data): Observable<any> {
    return this.commonService.refereeformpatch('/company/refreedecline', data);
  } 

}
