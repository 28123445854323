import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { CommonService } from '../../services/common.service';
import { ProjectService } from '../../services/project.service';
import { ClientService } from '../../services/client.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Project } from "./../../models/project/project.model";
import { Router } from '@angular/router';

import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})

export class ProjectComponent implements OnInit {
  Userid: any;
  joblist: any;
  data: any;
  projectDetail: any;
  projectFormGroup: FormGroup;
  mobilecheck: any;
  phonecheck: any;
  clientdata: any;
  countrylist: any;
  countylist: any;
  options = [];
  buttoninsert: any;
  submitted: boolean = false;
  selectedFiles: FileList;
  filename: any;
  screenumber: any;
  public searchText: string;
  disableBtn: any;
  permission: any;
  projectperm: any;
  emptyValue: any = "";
  // record:number;
  items: Array<any>;
  pageOfItems: Array<any>;
  record: any;
  weburl: any;

  constructor(
    private commonService: CommonService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private project: ProjectService,
    private router: Router,
    private alerts: AlertService,
    private client: ClientService
  ) { }

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }


    this.Userid = localStorage.getItem('userid');
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.projectperm = this.permission.m5;
    this.projectDetail = new Project();
    this.projectFormGroup = this.formBuilder.group({
      id: [''],
      projectid: [''],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      alteremailid: [''],
      mobilenumber: ['', Validators.compose([Validators.required])],
      phonenumber: [''],
      clientid: ['', Validators.required],
      contactperson: ['', Validators.required],
      contactrole: [''],
      projectname: ['', Validators.required],
      weburl: ['']
    });
    const sstatustype = { 'status': false, 'id': this.Userid }
    
    this.project.getprojectlist(sstatustype).subscribe((data: any) => {
      this.record = data.length;
      this.data = data;
      this.record = this.data.length;
      this.pageOfItems = this.data;
      if (this.record < 3) {
        this.pageOfItems = this.data;
      }
      else {
        this.items = this.data;
      }
    });

    const sstatustype2 = { 'status': true, 'id': this.Userid }

    this.client.getclientlist(sstatustype2).subscribe((clientdata: any) => {
      this.clientdata = clientdata;
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  

  get f() { return this.projectFormGroup.controls; }

  onSubmit(formpro: any, id: string) {
   
    this.submitted = true;
    if (this.projectFormGroup.invalid) {
      return;
    }
    this.disableBtn = false;
    formpro.userid = this.Userid;
    this.project.projectdetailsUpdate(formpro).subscribe((response: any) => {

      const sstatustype = { 'status': true, 'id': this.Userid }
      this.project.getprojectlist(sstatustype).subscribe((data: any) => {
        this.record = data.length;
        this.data = data;
        this.record = this.data.length;

        if (this.record < 30) {
          this.pageOfItems = this.data;
        }
        else {
          this.items = this.data;
        }
        this.alerts.success('Project updated successfully',true);
        this.modalService.close(id);
      });
    });
  }

  onChangeStatus(evt: any, project: any) {
    this.project.projectdetailsUpdatestatusbyadmin({ 'userid': this.Userid, 'id': project.projectid, 'pstatus': evt.target.checked }).subscribe((data: any) => {
    });
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  deleteProject(projectid: string) {
    if (confirm("Are you sure you want to delete?")) {
      var deletedata = { "id": projectid };
      this.project.deleteProject(deletedata).subscribe((data: any) => {
        const sstatustype = { 'status': false, 'id': this.Userid }
        this.project.getprojectlist(sstatustype).subscribe((data: any) => {
          this.data = data;
        });
      });
    }
  }

  openModal(id: string, data: any) {
    this.submitted = false;
    this.buttoninsert = "Update";
    this.disableBtn = true;
    data.id = data.projectid;
    this.projectFormGroup.reset(data);
    if(data.weburl)
    {
      this.weburl = data.weburl.replace('https://', '').replace('http://', '');
    }
    this.modalService.open(id);
  }

  openModal_details(id: string, data: any){
    this.projectDetail = data;
    this.buttoninsert = "Update";
    this.disableBtn = true;
    this.submitted = true;
    this.modalService.open(id);
  }


  openModal_project(id: string) {
    this.projectDetail = new Project();
    this.submitted = false;
    this.buttoninsert = "Submit";
    this.disableBtn = true;
    this.mobilecheck = '';
    this.phonecheck = '';
    this.projectDetail.id = "0";
    this.projectDetail.mobilenumber = "+44";    
    this.projectFormGroup.reset(this.projectDetail);
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
}