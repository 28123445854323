import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { Visa } from '../../models/visa/visa.model';
import { Aml,AmlBussiness } from '../../models/aml/aml.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AmlService } from '../../services/aml.service';
import { Router } from '@angular/router';
import { ReferenceService } from '../../services/reference.service';
import { ChecksystemService } from '../../services/checksystem.service';
import { CommonService } from '../../services/common.service';
import { AlertService } from '../../services/alert.service';
import { EmployeeService } from '../../services/employee.service';
import { isThisMinute } from 'date-fns';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-addreference',
  templateUrl: './addreference.component.html',
  styleUrls: ['./addreference.component.css']
})
export class AddreferenceComponent implements OnInit {


  totalCheck = 0
  remainingCheck = 0
  usedCheck = 0
  createNewRequestFormGroup: FormGroup;
  submit = false;
  questionTemplatesLists: any;
  isvalidemail:boolean = true;
  stage: any =0;
  visaDetail: any;
  visa: Visa;
  disableBtn: any;
  defaultValue:any ='';
  result:any;
  buttoninsert: any;
  lesssore:boolean;
  Userid: any;
  record:number;  
  items: Array<any>;
  pageOfItems: Array<any>;
  datalist:any;
  data: any;
  phonecheck: any;
  mobilecheck: any;
  errormsg: any;
  error: boolean;  
  submitted: boolean;
  defaultVal: string ='';

  constructor(
    private modalService: ModalService,     
    private formBuilder: FormBuilder,
    private employeeService: EmployeeService, 
    private amldetails: AmlService,
    private router: Router,
    private userService: UserService,
    private alerts: AlertService,
    private commonService: CommonService,
    private check: ChecksystemService,
    private reference: ReferenceService
  ) { }

  ngOnInit() {
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    // this.permission = permission[0];
    // if(permission[0]['m96'] < 3)
    // {
    //   this.router.navigate(['/permission-denied']);
    // }

    this.stage =3;
    this.error =false;
    this.disableBtn =true;
    this.errormsg ='';
    this.buttoninsert ="Search";
    this.result =false;
    this.defaultVal ="";
   
    this.createNewRequestFormGroup = this.formBuilder.group({
      candidatename: ["", Validators.required],
      candidateemail: ["", [Validators.required, Validators.email]],
      candidatephone: ["", [Validators.required]],
      position: ["", [Validators.required]],
      questionprofile: ["", [Validators.required]],
      referencerequest: ["", [Validators.required]],
      candidatemessage: [""],
      companyid: [""],
      userid: [""]
    });

    
    this.Userid = localStorage.getItem('userid');

    this.lesssore =false;
    
    var ts  = { 'serviceid': 11};    
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      if(remaindata.remiancheck <= 0)
      {
          this.lesssore =true;
      }
    });


    this.reference.getemplatelistBycompany().subscribe((referencelist: any) => { 
      this.questionTemplatesLists = referencelist;
    });

    this.createNewRequestFormGroup.setValue({
      "candidatename": '',
      "candidateemail": '',
      "candidatephone": '',
      "position": "",
      "questionprofile": "",
      "referencerequest": "",
      "candidatemessage": "",
      "companyid":'',
      "userid":''
    });

    // this.createNewRequestFormGroup.questionprofile.value ='';

    this.createNewRequestFormGroup.get("questionprofile").valueChanges
      .subscribe(f => {
        f='';
        //console.log(f)
      })

    this.createNewRequestFormGroup.get("referencerequest").valueChanges
      .subscribe(f => {
        f='';
        //console.log(f)
      });

      this.getEmployees();
  }

  get f() { return this.createNewRequestFormGroup.controls; }
  
  createnewrequest(emplyeeselectlist:any){
    this.stage =0;
    this.submit =false;
    

    this.createNewRequestFormGroup.setValue({
      "candidatename": emplyeeselectlist.fname +((emplyeeselectlist.mname)?' ' + emplyeeselectlist.mname:'') +((emplyeeselectlist.lname)? ' '+emplyeeselectlist.lname:''),
      "candidateemail": emplyeeselectlist.emailid,
      "candidatephone": emplyeeselectlist.phonenumber,
      "position": "",
      "questionprofile": "",
      "referencerequest": "",
      "candidatemessage": "",
      "companyid":emplyeeselectlist.companyid,
      "userid":emplyeeselectlist.employeemasterid
    });

  }

  existingapplicent()
  {
    this.stage=3;
    this.getEmployees();
    this.createNewRequestFormGroup.setValue({
      "candidatename": "",
      "candidateemail": "",
      "candidatephone": "",
      "position": "",
      "questionprofile": "",
      "referencerequest": "",
      "candidatemessage": "",
      "companyid":0,
      "userid":0
    });

  }

  getEmployees()
  {
    this.employeeService.getlistofemployeeForCheck().subscribe((employeedata: any) => {
      this.data = employeedata;
      this.record = this.data.length;
      this.datalist = this.data;
      this.pageOfItems = this.data;
      if(this.record < 30){
        this.pageOfItems = this.data;
      } else{
        this.items =this.data;
      }     
    });
  }


  createNewRequest() {
    this.submit = true;
    this.error =false;
    this.submitted = true;
    if (this.createNewRequestFormGroup.invalid) {
      return;
    }
    this.disableBtn = false;

    var newrequest = this.createNewRequestFormGroup.value;
    let userData = {
      companyid: newrequest.companyid,
      userid: newrequest.userid,
      position: newrequest.position,
      candidatename: newrequest.candidatename,
      candidateemail: newrequest.candidateemail,
      candidatephone: newrequest.candidatephone,
      modulequestiontemplateid: newrequest.questionprofile,
      numberofreferee: newrequest.referencerequest,
      candidatemessage: newrequest.candidatemessage,
      isactive: "Yes",
      moduleid: 0
    };
    var ts  = { 'serviceid': 11};    



    // this.userService.isCompanyEmailRegisterd(newrequest.candidateemail).subscribe((data: any) => {
    //   if (data.sussess == 'success') {
    //     this.isvalidemail = false;this.disableBtn = true;
    //   }else{
        // this.isvalidemail =true;
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      if(remaindata.remiancheck > 0){ 
        if (this.isvalidemail){             
          this.reference.createNewRequest(userData).subscribe((response: any) => {
            if(response.susses == 'true')
            {
              this.disableBtn = true;
              this.stage =1;
            } else{this.disableBtn = true;
              this.alerts.error('Somethink is wrong, please try again sometime or contact to administrator. ', true);
            }
          },error => {
            
            this.disableBtn = true;
          });
        }
      } else{
        this.disableBtn = true;
      }
    });
  // }

  // });


  }
 

  newrequest()
  {
    this.submit = false
    this.createNewRequestFormGroup.setValue({
      "candidatename": "",
      "candidateemail": "",
      "candidatephone": "",
      "position": "",
      "questionprofile": "",
      "referencerequest": "",
      "candidatemessage": "",
      "companyid":1,
      "userid":1
    });
    this.stage =0;
  }
  
  onClear() {
    this.submit = false
    this.createNewRequestFormGroup.setValue({
      "candidatename": "",
      "candidateemail": "",
      "candidatephone": "",
      "position": "",
      "questionprofile": "",
      "referencerequest": "",
      "candidatemessage": "",
      "companyid":1,
      "userid":1
    });
    this.disableBtn =true;
  }

  openModal(id: string, data: any) {
    this.buttoninsert = "Update";
    this.modalService.open(id);
  }

  closeModal(id: string) 
  {    
    this.modalService.close(id);
  }
  
  validateemail(event: any) {    

    // this.userService.isCompanyEmailRegisterd(event.target.value).subscribe((data: any) => {
    //   if (data.sussess == 'success') {
    //     this.isvalidemail = false;
    //   }else{this.isvalidemail =true;}
    // });







    // this.check.validateemail(event.target.value).subscribe((response) => {
    //   if (response.status === "valid")
    //     this.isvalidemail = true;
    //   else
    //     this.isvalidemail = false;
    // });
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

}
