export class Adverse {
    reference: string;
    title: string;
    forename: string;
    middlename:string;
    surname:string;
    dob:string;
    housenumber: string;
    housename: string;
    postcode: string;
    addressoption: string;
    autosearch: string;
    hho: string;
    flatnumber: string;
    inlineRadioOptions: string;
    street: string;
    town: string;
    locality: string;
}