
<div class="bg">
  <app-header></app-header>

  <div class="section-wrapper">
    <div class="container">
      <div class="tabs" id="tabs">

        <div class="tabs__content">
          <div class="tabs__content__item active-tab" id="jan">
            <div class="content">
              <div class="row row-sm">
                <div class="col-lg-12" style="margin-top:10px;">
                  &nbsp;
                </div>
              </div>
              <mat-tab-group>
                <mat-tab label="Visa Request">
                  <ng-template matTabContent>
                    <app-visarequest></app-visarequest>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Passport Request">
                  <ng-template matTabContent>
                    <app-passportrequest></app-passportrequest>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Leave Request">
                  <ng-template matTabContent>
                    <app-adminleaverequest></app-adminleaverequest>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Expense Request">
                  <ng-template matTabContent>
                    <app-adminexpenserequest></app-adminexpenserequest>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Timesheet Request ">
                  <ng-template matTabContent>
                    <app-admintimesheetrequest></app-admintimesheetrequest>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>