import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Employee } from '../../../models/employee.model';
import { CountryService } from '../../../services/country.service';
import { EmployeeService } from '../../../services/employee.service';
import { EmployeementService } from '../../../services/employeement.service';
import { JobService } from '../../../services/job.service';
import { ModalService } from '../../../_services/modal.service';
import { ProjectService } from '../../../services/project.service';
import { LeaveService } from '../../../services/leave.service';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';
import { ExcelService } from '../../../services/excel.service';
import { AppGlobals } from '../../../app.global';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-viewleavereport',
  templateUrl: './viewleavereport.component.html',
  styleUrls: ['./viewleavereport.component.css']
})
export class ViewleavereportComponent implements OnInit {

  EmployeeForm: FormGroup;
  employeeDetail = new Employee();
  employeelist: any;
  countrylist: any;
  coslist: any;
  data: any;
  joblist: any;
  projectlist: any;
  holidaytype: any;
  exceldata = [];
  employeeleavedata: any;
  listdisplay: any;
  graphdisplay: any;
  notedetails: any;
  public searchText: string;
  record: number;
  empid: any;
  employeemonth: any;
  employeeyear: any;
  hstatus: any;
  notelist: any;
  items: Array<any>;
  pageOfItems: Array<any>;
  yearList: any;

  constructor(
    private excelService: ExcelService,
    private formBuilder: FormBuilder,
    private project: ProjectService,
    private leave: LeaveService,
    private country: CountryService,
    private jobser: JobService,
    private employee: EmployeeService,
    private employeementreport: EmployeementService,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) { 
    route.params.subscribe(params => {
      if(params['id']){
        this.empid = params['id'];
      }
    });
    route.queryParams.subscribe(params => {
      this.employeemonth = params['month'];
      this.employeeyear = params['year'];
      this.hstatus = params['status'];
    });
  }

  ngOnInit() {
    this.yearList = AppGlobals.YEAR_VALUES;
    this.graphdisplay = "block";
    this.listdisplay = "none";
    this.employeeyear = (new Date()).getFullYear();
    this.employeemonth = '';
    this.EmployeeForm = this.formBuilder.group({
      emailid: [''],
      phonenumber: [''],
      ninumber: [''],
      minstartdate: [''],
      maxstartdate: [''],
      minenddate: [''],
      maxenddate: [''],
      clientid: [''],
      minsalary: [''],
      maxsalary: [''],
      projectid: [''],
      fname: [''],
      hstatus: [''],
      searctext: ['']
    });

    var value3 = "{}";
    this.employee.getemployeelist(value3).subscribe((listdetails: any) => {
      this.employeelist = listdetails;
    });
    
    // var values3 = {};

    // this.leave.leavereport(values3).subscribe((leavereport: any) => {
    //   this.data = leavereport;
    // });
    this.leavetypereport();
    this.leave.getholidaylist().subscribe((holidaydetails: any) => {
      this.holidaytype = holidaydetails;
    });
  }

  showcondition(type: any) {
    if (type == 'list') {
      this.graphdisplay = "none";
      this.listdisplay = "block";
    }
    else {
      this.listdisplay = "none";
      this.graphdisplay = "block";
    }
  }

  exportAsXLSX(): void {
    this.exceldata = [];
    this.data.forEach(obj => {
      if (obj.iscurrent == false) {
        var current = "No";
      }
      else if (obj.iscurrent == true) {
        current = "Yes";
      }
      this.employeeleavedata = {
        "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
        "Title": obj.title,
        "Start Date": obj.startdate,
        "End Date": obj.enddate,
        "Leave Type": obj.holidaytype.typename
      };
      this.exceldata.push(this.employeeleavedata);
    });
    this.excelService.exportAsExcelFile(this.exceldata, 'sample');
  }
  leavetypereport() {
    this.data = [];
    var empcheck = true;
    var values3 = {};
    values3 = { 'month': this.employeemonth, 'year': this.employeeyear, 'employeeid': this.empid, 'hstatus': this.hstatus }
    this.leave.leavereport(values3).subscribe((leavereport: any) => {
      // this.record = leavereport.length;
      this.data = leavereport;
      this.record = this.data.length;
      
      if (this.record < 30) {
        this.pageOfItems = this.data;
      }
      else {
        this.items = this.data;
      }
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  changeEmployee(id: any) {
    this.empid = id;
    this.leavetypereport();
  }

  changeEmployeeMonth(month: any) {
    this.employeemonth = month;
    this.leavetypereport();
  }

  changeEmployeeYear(year: any) {
    this.employeeyear = year;
    this.leavetypereport();
  }

  changeEmployeestatus(hstatus: any) {
    this.hstatus = hstatus;
    this.leavetypereport();
  }

  openModalnote(data: any, id: any) {
    this.notedetails = data;
    this.modalService.open(id);
  }
  openModal(id: any) {
    this.modalService.open(id);
  }

  closeModal(id: any) {
    this.modalService.close(id);
  }
  onSubmit(value3: any) {
  }
}
