import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { AmlBussiness,Aml,adverst } from '../../../models/aml/aml.model';
import { AmlService } from '../../../services/aml.service';
import { CompanyadvertService } from '../../../services/companyadvert.service';
import { CheckserviceService } from '../../../services/checkservice.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Director } from '../../../models/director/director.model';
import { DirectorService } from '../../../services/director.service';

@Component({
  selector: 'app-directorhistory',
  templateUrl: './directorhistory.component.html',
  styleUrls: ['./directorhistory.component.css']
})
export class DirectorhistoryComponent implements OnInit {

  result: boolean;
  searchresult: any;
  searchresultnew: any;
  atype:boolean=false;
  logid:any;
  datasetlist: any;
  downloadtext: any;
  searchText:any;

  constructor(
    private amldetails: AmlService,    
    private router: Router,    
    private directorService: DirectorService,
    private companyadvert: CompanyadvertService,
    private checkService: CheckserviceService, 
    private companyadvertService: CompanyadvertService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    var masterarray= [];
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }
    var tt={}
    this.directorService.getdirectorhistory().subscribe((searchRes: any) => {  
      this.downloadtext = new Array(searchRes.length);
      for(var i=0;i<searchRes.length;i++)
      {
        this.downloadtext[i] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
      }
      searchRes.forEach((element,index) => {
        searchRes[index].searchresult = JSON.parse(element.searchresult);
      });
      console.log(searchRes);
      this.searchresult = searchRes;//searchRes;   
    });   
  }

  downloaddirectordata(directorcode: any, ind){
    this.downloadtext[ind] = 'Loading <i class="fa fa-spinner fa-spin"></i>';
    this.directorService.downloaddirectordata({"directorcode":directorcode }).subscribe((searchRes: any) => {  
      this.downloadtext[ind] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
      window.open(searchRes.response_data.Location, "_blank");
      console.log(searchRes); 
    });
  }

  outputnewset(d){
    var listmaster =JSON.parse(d);  
    this.searchresultnew = new adverst();
    this.searchresultnew = listmaster;
  }

  outputnewBussinessset(d){
    var listmaster =JSON.parse(d);  
    this.searchresultnew = new AmlBussiness();
    this.searchresultnew = listmaster;
  }

}
