
<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper" >
    <div class="container">
      <div class="tabs" id="tabs">
        <div class="tabs__wcontent">
          <div class="tabs2__content__item active-tab" id="jan">
            <div class="content">
                <div class="row row-sm topheader">
                    <div class="col-lg-8">                  
                    </div>
                    <div class="col-lg-4 float-right text-right">
                      <a [routerLink]="['/check-employee-list']" class="back-to-employee"><span class="lnr lnr-chevron-left"></span> Back to Employee List</a>
                    </div>
                  </div>
                  <div id="overview">
                    <div class="overviewsshow">
                      <div class="cseec">
                        <div class="admin_com_logo">
                            <div class="picture_chnge">
                              <input type="file" accept="image/*" (change)="selectFile($event)" style="display:none;" #file>
                                <a target="_blank" (click)="file.click()" class="text-black-50 cameraicon"><i class="fa fa-camera"></i></a>
                            </div>
                            <img alt="" [src]="overviewData.imagename" *ngIf="overviewData.imagename !='' && overviewData.imagename !=null" />
                            <img alt="" src="../../../assets/img/no-image-icon-hi.png" *ngIf="overviewData.imagename =='' || overviewData.imagename == null" />
                            <div class="alert-danger" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                            <div class="alert-danger" *ngIf="fileformaterror == true">Please choose a valid file format
                              (jpg, png, tif, gif, pdf, doc)</div>
                        </div>
                    </div>
                    <div class="overview_details">
                      <div class="alignbbb">
                        <div class="headdd">
                          <h3 class="card-profile-name">{{ overviewData.fname}} {{ overviewData.mname }} {{overviewData.lname}} <span *ngIf="overviewData.employeeid != null"> ({{overviewData.employeeid}})</span>&nbsp;
                            <i class="fa fa-pencil tx-15" (click)="openModal('custom-overview-1',overviewData)" aria-hidden="true"></i>
                          </h3>
                        </div>
                        <!-- <div class="dflexsh">
                          <div class="employee_list_section ">
                            <div class="onoffswitch">
                              <label class="onoffswitch-label" for="skill" class="switch">
                                <input type="checkbox"  name="employee.estatus" [checked]="" id="skill" >
                                <span class="slider round"></span>
                              </label>
                          </div>
                          </div>
                        </div> -->
                      </div>
                      <hr class="mt-0"/>
                      <div class="profile_dtailsshow mb-3">
                        <div class="detailsshow width25">
                          <span>Email</span>
                          <p>{{ overviewData.emailid }}</p>
                        </div>
                        <div class="detailsshow width20">
                          <span>Mobile Number</span>
                          <p>{{overviewData.phonenumber }}</p>
                        </div>
                         
                        <div class="detailsshow width10">
                          <span>Gender</span>
                          <p>
                            <ng-container *ngIf="overviewData.gender==true">
                              Male
                            </ng-container>
                            <ng-container *ngIf="overviewData.gender==false">
                              Female
                            </ng-container>
                          </p>
                        </div>
                        <div class="detailsshow width15">
                          <span>Marital Status</span>
                          <p>
                            <ng-container *ngIf="overviewData.maritalstatus==true">
                              Married
                            </ng-container>
                            <ng-container *ngIf="overviewData.maritalstatus==false">
                              Single
                            </ng-container>
                          </p>
                        </div>
                        <div class="detailsshow width20">
                          <span>Date of Birth</span>
                          <p>{{overviewData.dateofbirth | date: 'dd MMM yyyy' }}</p>
                        </div>
                        <div class="detailsshow width20">
                          <span>Date of Joining</span>
                          <p>{{ overviewData.hiredate | date: 'dd MMM yyyy' }}</p>
                        </div>
                      </div>
                      <div class="profile_dtailsshow">
                        <div class="detailsshow width25">
                          <span>Nationality</span>
                          <p class="badge badge-danger">{{ overviewData?.nationality?.countryname }}</p>
                        </div>
                        <div class="detailsshow width25">
                          <span>Country of Residence </span>
                          <p>{{overviewData?.residence?.countryname}}</p>
                        </div>
                        <div class="detailsshow width20">
                          <span>Employee Role</span>
                          <p>{{ overviewData.emprole }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="overvisa">                            
                  <app-checkvisa></app-checkvisa>
                </div>
                <div id="overpassport">                              
                  <app-checkpassport></app-checkpassport>
                </div>
                <div id="idproof">                              
                  <app-checkdocument></app-checkdocument>
                </div>
                <div style="height: 50px;">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
<jw-modal id="custom-overview-1" class="modal" role="dialog">
  <form [formGroup]="OverForm" #overviewForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(overviewForm.value,'custom-overview-1')">
    <div role="document" class="formgroup">
      <div class="modal-content">
        <div class="modal-header">
          <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="overviewData.employeemasterid">
          <h5 class="modal-title" id="popupLabel">Employee Details</h5>
          <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModalOverview('custom-overview-1');">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="form-group col-lg-4">
                <label>First Name *</label>
                <input type="fname" formControlName="fname" name="fname" id="fname" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" [(ngModel)]="overviewData.fname">
                <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                  <div *ngIf="f.fname.errors.required">Please Enter First Name</div>
                </div>
              </div>

              <div class="form-group col-lg-4">
                <label>Middle Name</label>
                <input type="mname" formControlName="mname" class="" name="mname" id="mname" class="form-control"
                  [(ngModel)]="overviewData.mname">
              </div>
              <div class="form-group col-lg-4">
                <label>Last Name *</label>
                <input type="lname" formControlName="lname" class="" name="lname" id="lname" class="form-control input-lg"
                  [ngClass]="{ 'is-invalid': submitted && f.lname.errors }" [(ngModel)]="overviewData.lname">
                <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                  <div *ngIf="f.lname.errors.required">Please Enter Last Name</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-4">
                <label>Date of Birth*</label>
                <mat-form-field class="datecontrol">
                  <input matInput [min]="mindob" [max]="maxdob" [matDatepicker]="picker2" id="dateofbirth" [ngClass]="{ 'is-invalid': submitted && f.dateofbirth.errors }"
                    name="dateofbirth" formControlName="dateofbirth" class="form-control" [(ngModel)]="overviewData.dateofbirth">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                <div *ngIf="submitted && f.dateofbirth.errors" class="invalid-feedback">
                  <div *ngIf="f.dateofbirth.errors.required">Please Choose Date of Birth</div>
                </div>
              </div>
              <div class="form-group col-lg-4">
                  <label>Hire Date* </label>
                  
                  <mat-form-field  class="datecontrol">
                    <input matInput  [matDatepicker]="picker3" id="hiredate" [ngClass]="{ 'is-invalid': submitted && f.hiredate.errors }"
                      name="hiredate" formControlName="hiredate" class="form-control" [(ngModel)]="overviewData.hiredate">
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                  </mat-form-field>
                  <div *ngIf="submitted && f.hiredate.errors" class="invalid-feedback">
                    <div *ngIf="f.hiredate.errors.required">Please Choose Date of joining</div>
                    </div>
                </div>
              <div class="form-group col-lg-4">
                <label>NI Number *</label>
                <input type="ninumber" formControlName="ninumber" class="" name="ninumber" id="ninumber" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.ninumber.errors }" [(ngModel)]="overviewData.ninumber">
                <div *ngIf="submitted && f.ninumber.errors" class="invalid-feedback">
                  <div *ngIf="f.ninumber.errors.required">Please Enter NI Number</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Employee ID *</label>
                <input type="text" formControlName="employeeid" name="employeeid" id="employeeid" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.employeeid.errors }" [(ngModel)]="overviewData.employeeid">
                <div *ngIf="submitted && f.employeeid.errors" class="invalid-feedback">
                  <div *ngIf="f.employeeid.errors.required">Please Enter Employee Id</div>

                </div>
              </div>  
              <div class="form-group col-lg-6">
                <label>Job Role*</label>
                <input type="text" formControlName="emprole" name="emprole" id="emprole" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.emprole.errors }" [(ngModel)]="overviewData.emprole">
                <div *ngIf="submitted && f.emprole.errors" class="invalid-feedback">
                  <div *ngIf="f.emprole.errors.required">Please Enter Job Role</div>

                </div>
              </div>

            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Gender </label>
                <div class="form-group">
                  <div class="row">
                    <div class="col-lg-6 col-6">
                      <input type="radio" name="gender" formControlName="gender" [(ngModel)]='overviewData.gender' value="1" [checked]="overviewData.gender === true" /> Male
                    </div>
                    <div class="col-lg-6 col-6">
                      <input type="radio" name="gender" formControlName="gender" [(ngModel)]='overviewData.gender' value="0" [checked]="overviewData.gender === false" /> Female
                    </div>    
                  </div>
                </div>
              </div>
              <div class="form-group col-lg-6">
                <label>Marital Status</label>
                <div class="form-group">
                  <div class="row">
                    <div class="col-lg-6 col-6">
                      <input type="radio" name="maritalstatus" formControlName="maritalstatus" [(ngModel)]='overviewData.maritalstatus' value="1" [checked]="overviewData.maritalstatus === true"> Married
                    </div>
                    <div class="col-lg-6 col-6">
                      <input type="radio" name="maritalstatus" formControlName="maritalstatus" [(ngModel)]='overviewData.maritalstatus' value="0" [checked]="overviewData.maritalstatus === false"> Single
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Nationality * </label>
                <select style="width:100%;" name="nationalityid" formControlName="nationalityid" class="form-control input-lg" 
                  id="nationalityid" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" 
                  [(ngModel)]="overviewData.nationalityid">
                  <option [value]="defaultVal">Select Nationality</option>
                  <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                </select>
                <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                  <div *ngIf="f.nationalityid.errors.required">Please Select Nationality</div>
                </div>
              </div>
              <div class="form-group col-lg-6">
                <label>Country of Residence *</label>
                <select style="width:100%;" name="residenceid" formControlName="residenceid" class="form-control input-lg"
                  id="residenceid" [ngClass]="{ 'is-invalid': submitted && f.residenceid.errors }" 
                  [(ngModel)]="overviewData.residenceid">
                  <option [value]="defaultVal">Select Country Of Residence</option>
                  <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                </select>
                <div *ngIf="submitted && f.residenceid.errors" class="invalid-feedback">
                  <div *ngIf="f.residenceid.errors.required">Please Select Country of Residence</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Country of Birth *</label>
                <select style="width:100%;" name="countryofbirthid" formControlName="countryofbirthid" 
                  class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.countryofbirthid.errors }" 
                  (change)="onSelectCountry($event.target.value)" [(ngModel)]="overviewData.countryofbirthid" id="countryofbirthid">
                  <option [value]="defaultVal">Select Country Of Birth</option>
                  <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                </select>
                <div *ngIf="submitted && f.countryofbirthid.errors" class="invalid-feedback">
                  <div *ngIf="f.countryofbirthid.errors.required">Please Select Country of Birth</div>
                </div>
              </div>
              <div class="form-group col-lg-6">
                <label> Place of Birth *</label>
                <input type="placeofbirth" formControlName="placeofbirth" class="" id="placeofbirth"
                  [ngClass]="{ 'is-invalid': submitted && f.placeofbirth.errors }" [(ngModel)]="overviewData.placeofbirth"
                  name="placeofbirth" class="form-control">
                <div *ngIf="submitted && f.placeofbirth.errors" class="invalid-feedback">
                  <div *ngIf="f.placeofbirth.errors.required">Please Enter Place of Birth</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group modal-footer">
          <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="Update" />
          <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModalOverview('custom-overview-1');" />
        </div>
      </div>
    </div>
  </form>
</jw-modal>
  
<jw-modal id="custom-overview-2" class="modal" role="dialog">
  <form [formGroup]="permanetForm" #permanentForm="ngForm" class="s12 white" (ngSubmit)="onSubmit2(permanentForm.value,'custom-overview-2')">
    <div role="document" class="formgroup">
      <div class="modal-content">
        <div class="modal-header">
          <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" 
          [(ngModel)]="paddress.id">
          <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" 
          id="employeeid" [(ngModel)]="paddress.employeeid">
          <h5 class="modal-title" id="popupLabel">Permanent Address Details</h5>
          <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModalPaddress('custom-overview-2');">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Postcode *</label>
                <input type="pincode" formControlName="pincode" class="" id="pincode" name="pincode" class="form-control pincode"
                  [ngClass]="{ 'is-invalid': submitted && h.pincode.errors }" [(ngModel)]="paddress.pincode" (input)="onSearchChange($event.target.value)"
                  matInput [matAutocomplete]="auto">
                <div *ngIf="submitted && h.pincode.errors" class="invalid-feedback">
                  <div *ngIf="h.pincode.errors.required">Please enter postcode</div>
                </div>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option *ngFor="let option of options" [value]="option" (onSelectionChange)="updatecode($event, option)">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
              </div>
              <div class="form-group col-lg-6">
                <label>Address Line 1 *</label>
                <input type="addressid1" formControlName="addressid1" class="" name="addressid1" id="addressid1"
                  [ngClass]="{ 'is-invalid': submitted && h.addressid1.errors }" class="form-control" 
                  [(ngModel)]="paddress.addressid1">
                <div *ngIf="submitted && h.addressid1.errors" class="invalid-feedback">
                  <div *ngIf="h.addressid1.errors.required">Please Enter Address Line 1</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Address Line 2</label>
                <input type="addressid2" formControlName="addressid2" class="" name="addressid2" id="addressid2" class="form-control"
                  [(ngModel)]="paddress.addressid2">
              </div>
              <div class="form-group col-lg-6">
                <label>City *</label>
                <input type="cityname" formControlName="cityname" class="" name="cityname" id="cityname" [ngClass]="{ 'is-invalid': submitted && h.cityname.errors }"
                  class="form-control" [(ngModel)]="paddress.cityname">
                <div *ngIf="submitted && h.cityname.errors" class="invalid-feedback">
                  <div *ngIf="h.cityname.errors.required">Please Enter City</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>County Name *</label>
                <input type="cityname" formControlName="statename" name="statename" id="statename" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && h.statename.errors }" [(ngModel)]="paddress.statename">
                <div *ngIf="submitted && h.statename.errors" class="invalid-feedback">
                  <div *ngIf="h.statename.errors.required">Please enter the County</div>
                </div>
              </div>
              <div class="form-group col-lg-6">
                <label>Country *</label>
                <select style="width:100%;" formControlName="countryid" name="countryid" class="form-control input-lg"
                  id="countryid" [ngClass]="{ 'is-invalid': submitted && h.countryid.errors }" (change)="onSelectCountry($event.target.value)"
                  [(ngModel)]="paddress.countryid">
                  <option [value]="defaultVal">Select Country</option>
                  <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                </select>
                <div *ngIf="submitted && h.countryid.errors" class="invalid-feedback">
                  <div *ngIf="h.countryid.errors.required">Please Select the Country</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <input type="submit" class="btn btn-primary com_btn" name="updateform" value="{{buttoninsert}}" />
          <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModalPaddress('custom-overview-2');" />
        </div>
      </div>
    </div>
  </form>
</jw-modal>
  
  
  
<jw-modal id="custom-terminate-1" role="dialog" class="modal">
  <form [formGroup]="terminationForm" #terminationform="ngForm" class="s12 white" (ngSubmit)="onSubmitTermination(terminationform.value,'custom-terminate-1')">
    <div role="document" class="formgroup">
      <div class="modal-content">
        <div class="modal-header">
          <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid"
              id="employeeid" [(ngModel)]="terminationDetail.employeeid">
          <h5 class="modal-title" id="popupLabel">Termination Details</h5>
          <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
              (click)="closeModalTerminate('custom-terminate-1');">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Termination Date </label>
                <mat-form-field class="datecontrol">
                  <input matInput [matDatepicker]="picker4" id="terminationdate" [ngClass]="{ 'is-invalid': submitted && g.terminationdate.errors }"
                    name="terminationdate" formControlName="terminationdate"class="form-control noborder-invalid" [(ngModel)]="terminationDetail.terminationdate">
                  <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                  <mat-datepicker #picker4></mat-datepicker>
                </mat-form-field>
                <div *ngIf="submitted && g.terminationdate.errors" class="invalid-feedback">
                  <div *ngIf="g.terminationdate.errors.required">Please choose termination date</div>
                </div>
              </div>
              <div class="form-group col-lg-6">
                <label>Termination Reason*</label>
                <input type="text" formControlName="terminationreason" name="terminationreason"
                    id="terminationreason" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && g.terminationreason.errors }"
                    [(ngModel)]="terminationDetail.terminationreason">
                <div *ngIf="submitted && g.terminationreason.errors" class="invalid-feedback">
                  <div *ngIf="g.terminationreason.errors.required">Please enter termination reason
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Eligible For Rehire*</label>
                <select style="width:100%;" formControlName="oktorehire" name="oktorehire"
                    id="oktorehire" class="form-control input-lg" [(ngModel)]="terminationDetail.oktorehire"
                    [ngClass]="{ 'is-invalid': submitted && g.oktorehire.errors }">
                  <option [value]="defaultVal">Select eligible for rehire</option>
                  <option [value]="1">Yes </option>
                  <option [value]="0">No</option>
                </select>
                <div *ngIf="submitted && g.oktorehire.errors" class="invalid-feedback">
                  <div *ngIf="g.oktorehire.errors.required">Please select employement status</div>
                </div>
              </div>
              <div class="form-group col-lg-6">
                <label>Regret Termination*</label>
                <select style="width:100%;" formControlName="regrettermination" name="regrettermination"
                    id="regrettermination" class="form-control input-lg"
                    [ngClass]="{ 'is-invalid': submitted && g.regrettermination.errors }"
                    [(ngModel)]="terminationDetail.regrettermination">
                  <option [value]="defaultVal">Select regret termination</option>
                  <option [value]="1">Yes </option>
                  <option [value]="0">No</option>
                </select>
                <div *ngIf="submitted && g.regrettermination.errors" class="invalid-feedback">
                  <div *ngIf="g.regrettermination.errors.required">Please select regret termination
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Terminate" />
          <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel"
                (click)="closeModalTerminate('custom-terminate-1');" />
        </div>
      </div>
    </div>
  </form>
</jw-modal>
