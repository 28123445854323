"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CheckInvoice = /** @class */ (function () {
    function CheckInvoice() {
        this.id = "";
        this.createat = "";
        this.prices = "";
        this.tax = "";
        this.gtotal = "";
    }
    return CheckInvoice;
}());
exports.CheckInvoice = CheckInvoice;
var CompanyDetails = /** @class */ (function () {
    function CompanyDetails() {
        this.stuser = "";
        this.clientname = "";
    }
    return CompanyDetails;
}());
exports.CompanyDetails = CompanyDetails;
