"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var UnlimitedcheckcancelComponent = /** @class */ (function () {
    function UnlimitedcheckcancelComponent(router) {
        this.router = router;
        this.ctype = '';
    }
    UnlimitedcheckcancelComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.ctype = localStorage.getItem('ctype');
            //   if (this.ctype == '0' ) 
            //   {
            //     this.router.navigate(['upgrade-your-membership']);
            //   }
            //  else{
            _this.router.navigate(['login']);
            //  }
            // this.router.navigate(['/dashboard']);
        }, 9000); //5s
    };
    return UnlimitedcheckcancelComponent;
}());
exports.UnlimitedcheckcancelComponent = UnlimitedcheckcancelComponent;
