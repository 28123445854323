import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { EmployeeService } from '../../../services/employee.service';
import { ModalService } from '../../../_services/modal.service';
import { Profile } from '../../../models/overview/profile.model';
import { PermissionService } from '../../../services/permission.service';
import { AlertService } from '../../../services/alert.service';
import { CustomerService } from '../../../services/customer.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
@Component({
  selector: 'app-managepermission',
  templateUrl: './managepermission.component.html',
  styleUrls: ['./managepermission.component.css']
})
export class ManagepermissionComponent implements OnInit {

  empid: any;
  data: any;
  submitted: any;
  employeeForm: FormGroup;
  overviewData: any;
  countrylist: any;
  joblist: any;
  candidatelist: any;
  employeeid: any;
  th = [];
  emailexist = false;
  permission: any;
  emplyeeid: any;
  menulist: any;
  public searchText:string;
  Userid: any;
  permissions: any;
  userperm: any;
  currentcompanydata: any;
  ctype: any;

  constructor(
    private employeeService: EmployeeService,
    private modalService: ModalService,private activatedRoute: ActivatedRoute, 
    private customerser: CustomerService, 
    private router: Router,
    private permissiondetails: PermissionService,
    private alerts: AlertService
  ) { }

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    this.ctype  = parseInt(localStorage.getItem('ctype'));
    // if(ctype ==1){
    //   this.router.navigate(['/check-dashboard']);
    // }


    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    // console.log(permission);
    
    this.permissions = permission[0];
    this.userperm = this.permissions.m2;


    this.empid = this.activatedRoute.snapshot.paramMap.get("adminid");
    this.customerser.getOverviewbyadmin({'id': this.empid}).subscribe((data2: any) => {

      var permission3 =  data2.permission;
      this.overviewData = data2;


      this.permission = data2.permission;

      this.emplyeeid = this.empid;
  
      this.permissiondetails.bindallmenu().subscribe((menudata: any) => {
        console.log(menudata);
        menudata.forEach(function (valuemenu) {
          valuemenu.submenu.forEach(function (submenudata, index) {

            var tg;
            tg = JSON.parse(permission3);
  
            tg.forEach(function (value4) {
              var kdd = valuemenu.clname;
              if (value4[kdd] == submenudata.mright) {
                valuemenu.submenu[index].ch = true;
              }
              else {
                valuemenu.submenu[index].ch = false;
              }
            });
          });
        });
        this.menulist = menudata;
        
      });
    });  

   
  }

  onchange(ind:any, subm: any) {
    var tg;   
    tg = JSON.parse(this.permission.toString());
    tg.forEach(function (value4) {      
        value4[ind] = subm;      
    });
    var starry = JSON.stringify(tg);
    var dt = {"permission": starry, "employeeid": this.emplyeeid};
    this.permission = starry;

    console.log('This permison master', dt);
    
    this.permissiondetails.updateemployeepermission(dt).subscribe((employeedata: any) => {
      

    });
  }

  bindallmenu() {
    this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
      employeedata.forEach(function (value) {
        var tg;
        tg = JSON.parse(value.permission.toString());
        tg.forEach(function (value4) {
        });
      });
      this.data = employeedata;
    });
  }

  get f() { return this.employeeForm.controls; }

  onSubmit(employeeform1: any, modelid: any) {

  }


  addadmin(id: string) {
    if (confirm("Are you sure you want to add this user in admin list?")) {
      var prm = { "employeeid": id, "id": this.Userid, "isadmin": true }
      this.permissiondetails.updateisadmin(prm).subscribe((employeedata: any) => {
        this.alerts.success('User successfully updated as admin.',true);
        this.bindallmenu();
      });
    }
  }

  removeadmin(id: string) {
    if (confirm("Are you sure you want to remove this user from admin list?")) {
      var prm = { "employeeid": id, "id": this.Userid, "isadmin": false }
      this.permissiondetails.updateisadmin(prm).subscribe((employeedata: any) => {
        this.alerts.success('User successfully removed as admin.',true);
        this.bindallmenu();
      });
    }
  }

  openModal(id: string, employee: any, employeeid: number) {
    this.submitted = false;

    this.permission = employee.permission;

    this.emplyeeid = employeeid;

    this.permissiondetails.bindallmenu().subscribe((menudata: any) => {
      menudata.forEach(function (valuemenu) {
        valuemenu.submenu.forEach(function (submenudata, index) {
          
          var tg;
          tg = JSON.parse(employee.permission.toString());

          tg.forEach(function (value4) {
            var kdd = valuemenu.clname;
            if (value4[kdd] == submenudata.mright) {
              valuemenu.submenu[index].ch = true;
            }
            else {
              valuemenu.submenu[index].ch = false;
            }
          });
        });
      });
      this.menulist = menudata;
      // console.log(this.menulist);
    });
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
      this.data = employeedata;
      this.modalService.close(id);
    });
    
  }
}
