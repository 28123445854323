"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_service_1 = require("../../../services/employee.service");
var leavecount_model_1 = require("../../../models/setting/leavecount.model");
var modal_service_1 = require("../../../_services/modal.service");
var app_global_1 = require("../../../app.global");
var LeaveManagementComponent = /** @class */ (function () {
    function LeaveManagementComponent(employeeService, formBuilder, modalService) {
        this.employeeService = employeeService;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.submitted = false;
    }
    LeaveManagementComponent.prototype.ngOnInit = function () {
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        this.year = new Date().getFullYear();
        this.userid = localStorage.getItem('userid');
        this.createLeaveFormGroup();
        this.getAllEmployees();
        this.leaveDetail = new leavecount_model_1.LeaveCount();
    };
    Object.defineProperty(LeaveManagementComponent.prototype, "g", {
        get: function () { return this.leaveForm.controls; },
        enumerable: true,
        configurable: true
    });
    LeaveManagementComponent.prototype.createLeaveFormGroup = function () {
        this.leaveForm = this.formBuilder.group({
            id: [''],
            employeeid: [''],
            leavecount: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.pattern(/^[.\d]+$/), forms_1.Validators.max(365), forms_1.Validators.min(1)])],
        });
    };
    LeaveManagementComponent.prototype.onChangePage = function (pageOfItems) {
        this.pageOfItems = pageOfItems;
    };
    LeaveManagementComponent.prototype.getAllEmployees = function () {
        var _this = this;
        var postData = {
            'year': 2020,
            'id': this.userid
        };
        var year = this.year;
        this.employeeService.getListOfEmployeeWithTotalLeave(postData).subscribe(function (employeedata) {
            employeedata.forEach(function (value, emp_index) {
                var tg;
                tg = JSON.parse(value.permission.toString());
                tg.forEach(function (value4) {
                });
                if (value.remainleave) {
                    value.remainleave.forEach(function (totalLeaveObj, index) {
                        if (totalLeaveObj.payrolyear == year) {
                            employeedata[emp_index].totalleave = totalLeaveObj;
                        }
                    });
                }
            });
            _this.data = employeedata;
            _this.record = _this.data.length;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    LeaveManagementComponent.prototype.onSubmitLeave = function (leaveformData, modelId) {
        var _this = this;
        this.submitted = true;
        if (this.leaveForm.invalid) {
            return;
        }
        var postData = {
            'id': leaveformData.id,
            'employeeid': leaveformData.employeeid,
            'leavecount': leaveformData.leavecount,
            'payrolyear': this.year,
            'userid': this.userid
        };
        this.employeeService.saveEmployeeTotalLeaves(postData).subscribe(function (resp) {
            if (resp.success) {
                _this.getAllEmployees();
                _this.modalService.close(modelId);
            }
            else {
            }
        });
    };
    LeaveManagementComponent.prototype.editLeaveCount = function (employeeid) {
        var _this = this;
        this.leaveForm.reset();
        var postData = { 'employeeid': employeeid, 'year': this.year };
        this.employeeService.getEmployeeTotalLeaves(postData).subscribe(function (employeetotalleavedata) {
            if (employeetotalleavedata) {
                _this.leaveDetail.employeeid = employeetotalleavedata.employeeid;
                _this.leaveDetail.id = employeetotalleavedata.id;
                _this.leaveDetail.leavecount = employeetotalleavedata.leavecount;
                // console.log(employeetotalleavedata);
            }
            else {
                _this.leaveDetail = new leavecount_model_1.LeaveCount();
                _this.leaveDetail.employeeid = employeeid;
                _this.leaveDetail.id = '0';
            }
            _this.modalService.open('custom-leave');
        });
    };
    LeaveManagementComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    LeaveManagementComponent.prototype.yearChange = function (year) {
        this.year = year;
        this.getAllEmployees();
    };
    return LeaveManagementComponent;
}());
exports.LeaveManagementComponent = LeaveManagementComponent;
