"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".manage_per[_ngcontent-%COMP%]   .icon_show[_ngcontent-%COMP%]   .Basic.bg-purple[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{color:#2eb77d}.manage_per[_ngcontent-%COMP%]   .icon_show[_ngcontent-%COMP%]   .Standard.bg-purple[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{color:#94c047}.manage_per[_ngcontent-%COMP%]   .icon_show[_ngcontent-%COMP%]   .Enhanced.bg-purple[_ngcontent-%COMP%]   i[_ngcontent-%COMP%], .manage_per[_ngcontent-%COMP%]   .icon_show[_ngcontent-%COMP%]   .Right.bg-purple[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{color:#faa711}.manage_per[_ngcontent-%COMP%]   .icon_show[_ngcontent-%COMP%]   .DVLA.bg-purple[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{color:#0180ff}.manage_per[_ngcontent-%COMP%]   .icon_show[_ngcontent-%COMP%]   .Tax.bg-purple[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{color:#faa711}.manage_per[_ngcontent-%COMP%]   .icon_show[_ngcontent-%COMP%]   .Adverse.bg-purple[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{color:#2eb77d}\n/*# sourceMappingURL=adminpermission.component.css.map*/"];
exports.styles = styles;
