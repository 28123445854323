import { Component, OnInit } from '@angular/core';
import { CountryService } from '../../../services/country.service';
import { MyvisaService } from '../../../services/myvisa.service';
import { ModalService } from '../../../_services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Visa } from '../../../models/visa/visa.model';
import { UploadFileService } from './../../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
// import { Router } from '@angular/router';

import { ChecksystemService } from '../../../services/checksystem.service';

import { Router, ActivatedRoute, Params } from '@angular/router';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-checkvisa',
  templateUrl: './checkvisa.component.html',
  styleUrls: ['./checkvisa.component.css']
})
export class CheckvisaComponent implements OnInit {

  

  visalistbyadmin: any[]; 
  visaDetail: any;
  countrylist: any;
  Userid: any;
  listcheck: any;
  selectedFiles: FileList;
  iframevisasrc: any;
  imgvisasrc: any;
  buttoninsert: any;
  filesizeerror: any;
  fileformaterror: any;
  file: any;
  filename: any;
  selectvisabackFile:any;
  filereuirederror: any;
  selectVisaFile:any;
  aipayment: boolean =false;
  filevisaname:any;
  filevisareuirederror:any;
  filebackvisareuirederror:any;
  filevisabacksizeerror: any;
  filevisabackformaterror: any;
  selectedvisabackFiles: FileList;
  filevisabackname: any;
  filevisasizeerror:any;
  ramainingChecks:any;

  filevisaformaterror:any;

  empid: any;
  defaultVal: any = "";

  filterItems = [
    {
      name: 'All Visa Details',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Waiting for review',
      id: 1,
      selected: false
    }
  ]
  submitted: any;
  visapopForm: FormGroup;
  visa: Visa;
  disableBtn: any;
  
  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private data: MyvisaService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private activatedRoute: ActivatedRoute, 
    private alerts: AlertService,    
    private check: ChecksystemService,
    private router : Router
    ) { }

  ngOnInit() {
    this.empid = this.activatedRoute.snapshot.paramMap.get("id");
    this.visapopForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      visanumber: ['', Validators.required],
      visatype: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      visaexpiry: ['', Validators.required],
      visastart: ['', Validators.required],
      iscurrent: ['']
    });

    this.filebackvisareuirederror =false;
    this.filevisareuirederror =false;

    this.filereuirederror =false;
    this.filevisaname = 'Choose file';
    this.filevisabackname = 'Choose file';
    this.visaDetail = new Visa();



   

    this.check.getbillinginformation({ 'serviceid': 7 }).subscribe((remaindata: any) => { 
      this.ramainingChecks = remaindata;
    });

    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.empid, 'cstatus': [1, 2, 3] };


console.log(this.empid);
    this.data.getvisaAllListbyadmin(data).subscribe((data: any) => {
      this.visalistbyadmin = data;
    });

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.listcheck = this.filterItems;
  }


  onDelete(id: number) {
    const data1 = { 'id': id };
    if (confirm("Are you sure you want to delete?")) {
      this.data.deletevisa(data1).subscribe((data: any) => {
        var sstatus: any;
        for (let c of this.filterItems) {
          if (c.selected == true) {
            if (c.id == 0) {
              sstatus = [1, 2, 3];
            }
            else {
              sstatus = [c.id];
            }
          }
        }
        const data2 = { 'id': this.empid, 'cstatus': sstatus };
        this.data.getvisaAllListbyadmin(data2).subscribe((data: any) => {
          this.visalistbyadmin = data;
        });
        this.alerts.success('Visa delete successfully.', true);

      });
    }
  }

  onlistchange(type1: any, idval: number) {
    var sstatus: any;
    for (let c of this.filterItems) {
      if (c.id == idval)
        c.selected = true;
      else
        c.selected = false;
    }
    if (idval == 0 || type1 == false) {
      sstatus = [1, 2, 3];
    }
    else {
      sstatus = [idval];
    }
    const data = { 'id': this.empid, 'cstatus': sstatus };
    this.data.getvisaAllListbyadmin(data).subscribe((data: any) => {
      this.visalistbyadmin = data;
    });
  }

  get f() {
    return this.visapopForm.controls;
  }

  onSubmit(formvisa: any, id: string) {
    // var formvisa = value3.value;
    this.submitted = true;
    if (this.visapopForm.invalid) {
      return;
    }

    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.fileformaterror = true;  this.filesizeerror = false;
        return;
      }else{
        this.filereuirederror =false; this.fileformaterror = false;  this.filesizeerror = false;

      }
      var imagepath = this.uploadService.uploadfile(this.file);
      formvisa.imagename = imagepath;
    }
    else {
      formvisa.imagename = this.visaDetail.visadocname;
    }

    
    if (this.selectedvisabackFiles) {
      this.file = this.selectedvisabackFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filevisabacksizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.filevisabackformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      formvisa.imagebackname = imagepath;
    }
    else {
      formvisa.imagebackname = this.visaDetail.visabackdocname;
    }

    console.log("rahul", formvisa.imagebackname);
    console.log("Tanwar", formvisa.imagename);

    if(formvisa.imagebackname =='')
    {
      this.filebackvisareuirederror =true;
      return;
    }

    if(formvisa.imagename ==''){
      this.filevisareuirederror =true;
      return;
    }

    formvisa.createby = this.Userid;
    formvisa.updateby = this.Userid;
    this.disableBtn = false;
    this.data.myvisadetailsUpdatebyadmin(formvisa).subscribe((data: any) => {
      var sstatus: any;
      for (let c of this.filterItems) {
        if (c.selected == true) {
          if (c.id == 0) {
            sstatus = [1, 2, 3];
          }
          else {
            sstatus = [c.id];
          }
        }
      }
      const data1 = { 'id': this.empid, 'cstatus': sstatus };
      this.data.getvisaAllListbyadmin(data1).subscribe((data: any) => {
        this.visalistbyadmin = data;
      });
    });
    this.filename = "Choose file";
    this.filevisabackname = "Choose file";
    this.alerts.success('Visa update successfully.', true);

    this.modalService.close(id);
  }

  selectVisaFileupload(event)
   {
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = files[0];

    this.file = this.selectedFiles;
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.fileformaterror = true;    
    }else {
      this.fileformaterror = false;
      this.filesizeerror = false;
    
    this.filevisareuirederror =false;

      this.filevisaname = this.filename;//files[0]; 
    }
  }

  selectvisabackFileupload(event)
   {
    var files = event.target.files;
    this.filevisabackname = files[0].name;    

    this.file = files[0];//this.selectedvisabackFiles;
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filevisabacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filevisabackformaterror = true;
      this.filevisabacksizeerror = false;    
    }
    else
    {
      this.filevisabackformaterror = false;
      this.filevisabacksizeerror = false;     this.filebackvisareuirederror =false;
      this.selectedvisabackFiles = files[0]; 
    }
  }


  openModal2(id: string) 
  {
    this.submitted = false;
    this.disableBtn = true;
    this.filereuirederror =false;
    this.selectVisaFile =null;
    this.selectvisabackFile =null;
    this.buttoninsert = "Submit";
    this.visaDetail = new Visa();
    this.visaDetail.employeeid = this.empid;
    this.visaDetail.employeevisadeatilid = "0";       
    this.visaDetail.nationalityid = '';
    this.filevisaname = "Choose file";
    this.filevisabackname = "Choose file";
    this.modalService.open(id);
  }

  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModalvisa3(id: string, data: any) 
  {
    this.iframevisasrc ='';
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgvisasrc = true;
      this.iframevisasrc = data;
    }
    else {
      this.imgvisasrc = false;
      this.iframevisasrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.disableBtn = true;    
    this.selectVisaFile =null;
    this.selectvisabackFile =null;
    this.visaDetail = data;       
    this.filevisaname = "Choose file";
    this.filevisabackname = "Choose file";
    this.buttoninsert = "Update";
    this.modalService.open(id);
  }

  closeModal(id: string) 
  {
    this.visa = new Visa();
    this.visaDetail = new Visa();
    const data = { 'id': this.empid, 'cstatus': [1, 2, 3] };
    this.data.getvisaAllListbyadmin(data).subscribe((data: any) => {
      this.visalistbyadmin = data;
    });
    this.modalService.close(id);
  }

  uploadByAdmin(emp_type, type, empid)
  {
    if(this.ramainingChecks && this.ramainingChecks.remiancheck < 1)
    {
      this.modalService.open('upgrade-plan');
    }
    else
    {
      if(type == 'upload_now')
      {
        if(emp_type == 'visa')
        {
          this.router.navigate(['/check-ai-visa-admin', empid]);
        }     
      
    }
  }
}

}
