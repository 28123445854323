"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var employee_service_1 = require("../../../services/employee.service");
var modal_service_1 = require("../../../_services/modal.service");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var alert_service_1 = require("../../../services/alert.service");
var platform_browser_1 = require("@angular/platform-browser");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var EmployeedocumentComponent = /** @class */ (function () {
    function EmployeedocumentComponent(sanitizer, employeeService, uploadService, modalService, router, alerts, formBuilder) {
        this.sanitizer = sanitizer;
        this.employeeService = employeeService;
        this.uploadService = uploadService;
        this.modalService = modalService;
        this.router = router;
        this.alerts = alerts;
        this.formBuilder = formBuilder;
    }
    EmployeedocumentComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.docperm = this.permission.m8;
        this.record = 0;
        this.documentGroup = this.formBuilder.group({
            employeedocumentdetailsid: [''],
            documentname: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required]
        });
        this.employeeService.getlistofemployee().subscribe(function (employeedata) {
            _this.employeelist = employeedata;
            _this.data = employeedata;
        });
    };
    Object.defineProperty(EmployeedocumentComponent.prototype, "f", {
        get: function () { return this.documentGroup.controls; },
        enumerable: true,
        configurable: true
    });
    EmployeedocumentComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    EmployeedocumentComponent.prototype.bindallcandidatedocument = function (id, employeeid) {
        var _this = this;
        this.employeeid = employeeid;
        this.Userid = localStorage.getItem('userid');
        this.employeeService.getdocumentlist({ "id": this.Userid, "employeeid": employeeid }).subscribe(function (candidatedocument) {
            _this.record = candidatedocument.length;
            _this.employeedocumentlist = candidatedocument;
        });
        this.modalService.open(id);
    };
    EmployeedocumentComponent.prototype.getFileNameWithoutExtension = function (name) {
        //  var name = file.getName();
        //console.log(name.length);
        var pos = name.lastIndexOf('.');
        if (pos > 0 && pos < (name.length - 1)) {
            // there is a '.' and it's not the first, or last character.
            return name.substring(0, pos);
        }
        return name;
    };
    EmployeedocumentComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if ((filetype[filetype.length - 1] != "jpeg")
            && (filetype[filetype.length - 1] != "jpg")
            && (filetype[filetype.length - 1] != "png")
            && (filetype[filetype.length - 1] != "doc")
            && (filetype[filetype.length - 1] != "pdf")) {
            this.alerts.error('Supported File Formats (jpeg, jpg, png, doc, pdf)');
            return;
        }
        var file = this.selectedFiles;
        var imagepath = this.uploadService.uploadfile(file);
        var documentname = this.getFileNameWithoutExtension(selectedFiles.name).substring(0, 20);
        var forupdate = { 'documentname': documentname, 'id': "0", 'documentimageurl': imagepath, 'employeeid': this.employeeid, 'userid': this.Userid };
        this.employeeService.employeedocumentupdatebyadmin(forupdate).subscribe(function (data) {
            _this.employeeService.getdocumentlist({ "id": _this.Userid, "employeeid": _this.employeeid }).subscribe(function (candidatedocument) {
                _this.record = candidatedocument.length;
                _this.employeedocumentlist = candidatedocument;
            });
            // setTimeout(() => this.overviewData.imagename = imagepath, 2000);
        });
        this.alerts.success('Document uploaded successfully', true);
    };
    EmployeedocumentComponent.prototype.deleteFile = function (id) {
        var _this = this;
        var deleteData = {};
        deleteData = { 'id': id, 'employeeid': this.employeeid };
        if (confirm("Are you sure you want to delete?")) {
            this.employeeService.deleteemployeedocumentFile(deleteData).subscribe(function (data) {
                _this.employeeService.getdocumentlist({ "id": _this.Userid, "employeeid": _this.employeeid }).subscribe(function (candidatedocument) {
                    _this.alerts.error('Document deleted successfully', true);
                    _this.record = candidatedocument.length;
                    _this.employeedocumentlist = candidatedocument;
                });
            });
        }
    };
    EmployeedocumentComponent.prototype.openModal5 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    EmployeedocumentComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    EmployeedocumentComponent.prototype.opendocumnentname = function (id, employeeid) {
        var _this = this;
        var reqType = { 'documentid': id, 'employeeid': employeeid };
        this.employeeService.getemployeedocumentbyid(reqType).subscribe(function (data) {
            _this.documentnamelist = data;
            _this.modalService.open('employee-doc-name-update');
        });
    };
    EmployeedocumentComponent.prototype.onRenameDocumentSubmit = function (data) {
        var _this = this;
        this.submitted = true;
        if (this.documentGroup.invalid) {
            return;
        }
        // console.log(data);
        this.employeeService.updateemployeedocumentbyid(data).subscribe(function (data2) {
            if (data2.susses == 'true') {
                var employeeid = data.employeeid;
                _this.documentnamelist = null;
                _this.Userid = localStorage.getItem('userid');
                _this.employeeService.getdocumentlist({ "id": _this.Userid, "employeeid": employeeid }).subscribe(function (candidatedocument) {
                    _this.record = candidatedocument.length;
                    _this.employeedocumentlist = candidatedocument;
                    _this.modalService.close('employee-doc-name-update');
                });
                _this.alerts.success('Document name updated successfully');
            }
            else {
                _this.alerts.error('Somthing went wrong');
                _this.modalService.close('employee-doc-name-update');
            }
        });
    };
    return EmployeedocumentComponent;
}());
exports.EmployeedocumentComponent = EmployeedocumentComponent;
