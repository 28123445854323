<div class="row topheader">
    <div class="col-lg-2">
        <select name="hstatus" class="form-control input-lg" (change)="changeEmployeestatus($event.target.value)">
            <option value="">Select Status</option>
            <option value="1">Pending</option>
            <option value="2">Approved</option>
            <option value="3">Rejected</option>
        </select>
    </div>
    <div class="col-lg-3">
        <select name="employee" #employid (change)="changeEmployee($event.target.value)" class="form-control input-lg">
            <option value="">Select Employee</option>
            <option *ngFor="let emp of employeelist" value="{{emp.employeemasterid}}"
                [selected]="emp.employeemasterid==empid">
                {{ emp.fname }} {{ emp.mname }} {{ emp.lname }} ( {{ emp.employeeid }})</option>
        </select>
    </div>
    <div class="col-lg-3">
        <select name="exptype" #expid (change)="changeExpenseType($event.target.value)" class="form-control input-lg">
            <option value="">Select Hour Type</option>
            <option *ngFor="let exptyp of expenselist" value="{{exptyp.id}}" [selected]="exptyp.id==exptype">
                {{ exptyp.typename }}</option>
        </select>
    </div>
    <div class="col-lg-2">
        <select name="empmonth" #empmonth (change)="changeEmployeeMonth($event.target.value)"
            class="form-control input-lg">
            <option value="">Select Month</option>
            <option value="1" [selected]="employeemonth=='1'">January</option>
            <option value="2" [selected]="employeemonth=='2'">February</option>
            <option value="3" [selected]="employeemonth=='3'">March</option>
            <option value="4" [selected]="employeemonth=='4'">April</option>
            <option value="5" [selected]="employeemonth=='5'">May</option>
            <option value="6" [selected]="employeemonth=='6'">June</option>
            <option value="7" [selected]="employeemonth=='7'">July</option>
            <option value="8" [selected]="employeemonth=='8'">August</option>
            <option value="9" [selected]="employeemonth=='9'">September</option>
            <option value="10" [selected]="employeemonth=='10'">October</option>
            <option value="11" [selected]="employeemonth=='11'">November</option>
            <option value="12" [selected]="employeemonth=='12'">December</option>
        </select>
    </div>
    <div class="col-lg-2">
        <select name="empyear" #empyear (change)="changeEmployeeYear($event.target.value)"
            class="form-control input-lg" ngModel="{{employeeyear}}">
            <option value="{{y}}" *ngFor="let y of yearList">{{ y }}</option>
        </select>
    </div>
</div>

<div class="row">
    <div class="col-lg-12">
        <div id="chartContainer" style="width:99%; height: 600px;"></div>
    </div>
</div>

<div class="row">
    <div class="col-lg-4 pr-2 pl-2" *ngFor="let expense of data ;  let i=index">
        <div class="job_list_section idproof_request_section visa_request_section ">
            <div class="job_detils_date width50">
                <div class="visa_user_details">
                    <p class="job_address">Hour Type</p>
                    <span> {{expense.typename}}</span>
                </div>
            </div>
            <div class="job_detils_date width50 text-center">
                <p class="job_address">Total Employee</p>
                <span class="badge badge-danger" *ngIf="expense.count > 0">{{expense.count}}</span>
                <span class="badge badge-secondary" *ngIf="expense.count == 0">0</span>
            </div>
        </div>
    </div>
</div>

<div class="row mapborderstyle">
    <div class="col-lg-12">
        <div id="chartContainer2" style="width:99%; height: 1000px;"></div>
    </div>
</div>