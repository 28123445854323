"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var core_2 = require("@angular/core");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var rota_model_1 = require("../models/rota/rota.model");
var forms_1 = require("@angular/forms");
var worklist_service_1 = require("../services/worklist.service");
var rota_service_1 = require("../services/rota.service");
var date_fns_1 = require("date-fns");
var app_global_1 = require("../app.global");
var MyrotaComponent = /** @class */ (function () {
    function MyrotaComponent(formBuilder, workListService, activatedRoute, rotaService, router, datePipe, modal) {
        this.formBuilder = formBuilder;
        this.workListService = workListService;
        this.activatedRoute = activatedRoute;
        this.rotaService = rotaService;
        this.router = router;
        this.datePipe = datePipe;
        this.modal = modal;
        this.locale = 'en';
        this.viewChange = new core_2.EventEmitter();
        this.viewDateChange = new core_2.EventEmitter();
        this.rotaAvailableDate = new Date();
        this.submitted = false;
        this.minstartDate = new Date();
        this.minendDate = new Date();
        this.allRotas = [];
        this.leaveavailabledate = new Date();
        this.timeslotError = false;
        //this.view = 'week';
        this.snapDraggedEvents = true;
        this.dayStartHour = 1;
        //viewDate = new Date();
        this.events = [];
        this.refresh = new rxjs_1.Subject();
    }
    MyrotaComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        // this.leaveavailabledate.setDate(this.leaveavailabledate.getDate() - 31);
        this.month = (new Date()).getMonth() + 1;
        this.year = (new Date()).getFullYear();
        this.classactive = (new Date()).getMonth().toString();
        this.employee_id = localStorage.getItem('userid');
        this.view = 'week';
        this.timeValues = app_global_1.AppGlobals.TIME_VALUES;
        this.viewDate = new Date();
        this.rotaformGroup = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeId: ['', forms_1.Validators.required],
            userId: ['', forms_1.Validators.required],
            locationId: ['', forms_1.Validators.required],
            title: ['', forms_1.Validators.required],
            startDate: ['', forms_1.Validators.required],
            endDate: ['', forms_1.Validators.required],
            starttime: ['', forms_1.Validators.required],
            endtime: ['', forms_1.Validators.required]
        });
        this.workListService.getlocationlist().subscribe(function (locationlist) {
            _this.locationlist = locationlist;
        });
        this.bindallData();
    };
    MyrotaComponent.prototype.updatemonth = function (id) {
        this.view = id; //'week';
        this.viewDate = new Date();
        this.viewChange = new core_2.EventEmitter();
        this.viewDateChange = new core_2.EventEmitter();
    };
    /**
     * This function is used to trigger function call on changing the month to produce data
     * @param inc
     * @author Fareed Aarif
     * @createOn 16-10-2020
     */
    MyrotaComponent.prototype.addMonthsOfYear = function (inc) {
        this.classactive = inc;
        var date = this.viewDate;
        date = date_fns_1.setMonth(date, inc);
        date = date_fns_1.setYear(date, this.year);
        this.viewDate = date;
        this.month = inc + 1;
        this.bindallData();
        // this.bindalldatabymonth();
    };
    MyrotaComponent.prototype.eventTimesChanged = function (_a) {
        var event = _a.event, newStart = _a.newStart, newEnd = _a.newEnd;
        event.start = newStart;
        event.end = newEnd;
        this.refresh.next();
    };
    MyrotaComponent.prototype.segmentIsValid = function (date) {
        // valid if time is greater than 0800 andd less than 1700
        return date.getHours() >= 8 && date.getHours() <= 17;
    };
    MyrotaComponent.prototype.beforeDayViewRender = function (day) {
        var _this = this;
        day.body.hourGrid.forEach(function (hour) {
            hour.segments.forEach(function (segment) {
                if ((!_this.segmentIsValid(segment.date))) {
                    delete segment.cssClass;
                    segment.cssClass = 'cal-disabled';
                }
            });
        });
    };
    MyrotaComponent.prototype.beforeWeekViewRender = function (body) {
        var _this = this;
        body.hourColumns.forEach(function (hourCol) {
            hourCol.hours.forEach(function (hour) {
                hour.segments.forEach(function (segment) {
                    if (!_this.segmentIsValid(segment.date)) {
                        delete segment.cssClass;
                        segment.cssClass = 'cal-disabled';
                    }
                });
            });
        });
    };
    Object.defineProperty(MyrotaComponent.prototype, "f", {
        /**
         * Returning function to validate all controls
         */
        get: function () {
            return this.rotaformGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Function to save rota data
     * @param rota
     * @param id
     * @author Fareed Aarif
     * @createOn 16-10-2020
     */
    MyrotaComponent.prototype.onSubmit = function (rota, id) {
        var _this = this;
        this.submitted = true;
        if (this.rotaformGroup.invalid) {
            return;
        }
        this.disableBtn = false;
        var newDate = new Date(rota.startDate);
        var endDate = new Date(rota.endDate);
        rota.start = this.datePipe.transform(new Date(rota.startDate), "dd MMM yyyy");
        rota.end = this.datePipe.transform(new Date(rota.endDate), "dd MMM yyyy");
        rota.startDate = new Date(rota.startDate);
        rota.endDate = new Date(rota.endDate);
        rota.startDate.setHours(rota.starttime);
        rota.endDate.setHours(rota.endtime);
        var retVal = [];
        var checkStartDate = rota.startDate;
        var checkEndDate = rota.endDate;
        while (rota.startDate <= rota.endDate) {
            retVal.push(new Date(rota.startDate));
            rota.startDate = date_fns_1.addDays(rota.startDate, 1);
        }
        var itemsProcessed = 0;
        /* check time slot between entry dates */
        this.rotaService.getRotaByDate({ 'startDate': checkStartDate, 'endDate': new Date(checkEndDate.getFullYear(), checkEndDate.getMonth(), checkEndDate.getDate(), 23, 59, 59) }).subscribe(function (data) {
            var checkRecord = true;
            data.forEach(function (element) {
                if (((rota.starttime >= element.starttime)
                    && (rota.starttime <= element.endtime))
                    || ((rota.endtime >= element.starttime)
                        && (rota.endtime <= element.endtime))) {
                    checkRecord = false;
                }
            });
            if (!checkRecord) {
                _this.timeslotError = true;
                _this.disableBtn = true;
                _this.timeSlotErrorMessage = "Timeslot is overlapping. Please change.";
                return;
            }
            retVal.forEach(function (single_dates) {
                var rotaToSave = rota;
                rotaToSave.startDate = new Date(single_dates.setHours(rotaToSave.starttime));
                rotaToSave.endDate = new Date(single_dates.setHours(rotaToSave.endtime));
                _this.rotaService.rotaCreateOrUpdate(rotaToSave).subscribe(function (data) {
                    itemsProcessed++;
                    if (itemsProcessed === retVal.length) {
                        _this.rotaList = [];
                        _this.bindallData();
                        document.getElementById('closeModal').click();
                    }
                });
            });
        });
        console.log(retVal);
    };
    MyrotaComponent.prototype.closeModal = function (id) {
    };
    MyrotaComponent.prototype.bindallData = function () {
        var _this = this;
        // this.leavecount = new Leavecount();
        this.rotaService.getEmployeeRota({ "id": this.employee_id }).subscribe(function (data) {
            _this.allRotas = []; //.destroy();// =null;
            data.forEach(function (element) {
                _this.allRotas.push({
                    id: element.employeerotaid,
                    title: element.title,
                    start: new Date(element.startdate),
                    end: new Date(element.enddate),
                    color: { primary: element.hcolor, secondary: element.fcolor },
                    // allDay: element.edit,
                    draggable: false,
                    resizable: {
                        beforeStart: true,
                        afterEnd: true
                    }
                });
                // console.log(new Date());
                _this.refresh.next();
            });
        });
        // var db = {'year': this.year}
        // this.data.getemployeeleavecount(db).subscribe((countdata: any) => {
        //   this.leavecount = countdata;
        //   console.log(countdata);
        // });
    };
    /**
     * Add new rota time slot Popup
     * @param action
     * @param event
     * @param start
     * @author Fareed Aarif
     * @createOn 16-10-2020
     */
    MyrotaComponent.prototype.addNewRota = function (action, event, start) {
        this.timeslotError = false;
        this.submitted = false;
        this.disableBtn = true;
        this.rota = new rota_model_1.Rota();
        this.Userid = localStorage.getItem('userid');
        this.rota.userId = this.Userid;
        this.rota.employeeId = this.employee_id;
        var newDate = new Date(start);
        this.rota.startDate = newDate; //new Date(start);
        this.minstartDate = this.rotaAvailableDate;
        // this.minendDate = this.leaveavailabledate;
        this.rota.endDate = newDate; //new Date(start);
        this.rota.locationId = "";
        this.rota.starttime = 0;
        this.rota.endtime = 0;
        this.rota.employeerotaid = '0';
        this.modalData = { action: action, event: event };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    /**
     * Edit Rota Time Slot Popup
     * @param action
     * @param event
     * @author Fareed Aarif
     * @createOn 16-10-2020
     */
    MyrotaComponent.prototype.editEvent = function (action, event) {
        var _this = this;
        this.timeslotError = false;
        this.submitted = false;
        this.disableBtn = true;
        this.rota = new rota_model_1.Rota();
        this.rota.startDate = new Date(); //new Date().toLocaleDateString();// new Date(start);
        this.rota.endDate = new Date(); //new Date().toLocaleDateString(); //new Date(start);
        this.Userid = localStorage.getItem('userid');
        this.rota.employeeId = this.Userid;
        var rotaId = event.id;
        this.minstartDate = this.rotaAvailableDate;
        this.minendDate = this.rotaAvailableDate;
        this.rotaService.getRotaData({ 'id': rotaId }).subscribe(function (data) {
            _this.rota.title = data.title;
            _this.rota.startDate = data.startdate;
            _this.rota.endDate = data.enddate;
            _this.rota.employeerotaid = data.employeerotaid;
            _this.rota.starttime = data.starttime;
            _this.rota.endtime = data.endtime;
            _this.rota.locationId = data.locationid;
            _this.rota.employeeId = data.employeeid;
            _this.rota.userId = data.userid;
            _this.rota.rstatus = data.rstatus;
        });
        this.modalData = { action: action, event: event };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    /**
     * Date change function working on Start Date and End Date
     * @param eventvalue
     */
    MyrotaComponent.prototype.onDateChange = function (eventvalue) {
        // console.log(eventvalue);
        // this.minendDate = eventvalue.value;
        // this.leave.end = eventvalue.value;
    };
    return MyrotaComponent;
}());
exports.MyrotaComponent = MyrotaComponent;
