<app-header>
</app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <a [routerLink]="['/adversecredit']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Adverse Credit Search</a>
          
            <div class="clearfix"></div>
            <div class="tabs__content">
                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Company List</h5>
                            </div>

                            <!-- <div class="col-lg-6">
                              <div class="input-group">
                                  <input placeholder="Search.." class="form-control">
                              </div>

                          </div> -->

                          <!-- <div class="col-lg-6">
                            <div class="input-group">
                                <input [(ngModel)]="searchText" placeholder="Search.." class="form-control">
                            </div>
                           </div> -->

                        </div>

                        <!-- <ng-container *ngIf="( items | filter: {companyname: searchText,clientname: searchText,emailid: searchText,phonenumber: searchText,clietdesignation: searchText }) as pageOfItems">  -->


                        <div *ngFor="let sr of pageOfItems; let i=index;">                        

                        <div class="credit_data_showlog" >
                            <div class="job_list_section visa_request_section">

                                <div class="job_detils_date width13">
                                    <div class="visa_user_details ">
                                        <p class="job_address">Company Name</p>
                                        <h4><a [routerLink]="['/adversecredit', sr.id ]">{{ sr.companyname }} </a></h4>                                        
                                    </div>
                                </div>
                                <div class="job_detils_date width10">
                                  <p class="job_address">Client Name</p>

                                  <p class="job_profile">{{ sr.clientname }}&nbsp;</p>
                                  <!-- <p class="job_profile" *ngIf="searchresultnew.street"> N/A</p> -->
                              </div>
                              <div class="job_detils_date width10">
                                <p class="job_address">Email </p>

                                <p class="job_profile">{{ sr.emailid }}&nbsp;</p>
                                <!-- <p class="job_profile" *ngIf="searchresultnew.flatnumber"> N/A</p> -->
                            </div>

                            <div class="job_detils_date width10">
                              <p class="job_address">Phone Number</p>

                              <p class="job_profile">{{ sr.phonenumber }}&nbsp;</p>
                              <!-- <p class="job_profile" *ngIf="searchresultnew.flatnumber"> N/A</p> -->
                          </div>
                                <div class="job_detils_date text-center">
                                    <p class="job_address">Total</p>
                                    <p class="job_profile">{{ sr.totalcase }}</p>
                                    <!-- <p class="job_profile" *ngIf="searchresultnew.postcode"> N/A</p> -->
                                </div>
                                
                                <div class="job_detils_date text-center ">
                                  <p class="job_address">Used</p>
                                  <p class="job_profile">{{ sr.usedcase }}</p>
                                  <!-- <p class="job_profile" *ngIf="searchresultnew.postcode"> N/A</p> -->
                              </div>

                              <div class="job_detils_date text-center">
                                <p class="job_address">Remain</p>
                                <p class="job_profile">{{ sr.remaincase }}</p>
                                <!-- <p class="job_profile" *ngIf="searchresultnew.postcode"> N/A</p> -->
                            </div>
                              
                                

                                <div class="job_detils_date">
                                    <p class="job_address">Create Date</p>
                                    <p class="job_profile">{{ sr.createat | date: 'dd MMM yyyy'}}&nbsp;</p>
                                    <!-- <p class="job_profile" *ngIf="searchresultnew.locality"> N/A</p> -->
                                </div>
                               
                                <div class="job_detils_date width13 text-right">
                                    <button type="button" *ngIf="sr.ismonitoring" class="btn btn-danger com_btn" [innerHtml]="downloadtext[i]" (click)="openModal('pdf-payment-confirmation-fail',sr.companyid)"></button>

                                    <button type="button" *ngIf="!sr.ismonitoring" class="btn btn-primary com_btn" [innerHtml]="downloadtext[i]" (click)="openModal('pdf-payment-confirmation',sr.companyid)"></button>


                                </div>

                                <!-- <div class="job_detils_date ">
                                    <a type="submit" class="btn btn-primary com_btn" [routerLink]="'/adverse-credit-history-view/'+ sr.id">View Details</a>
                                </div> -->


                            </div>
                          
                        </div>
                        </div> 

                        <div class="row">
                          <div class="col-lg-12 text-right">
                              <jw-pagination [items]="items" [pageSize]="100" [maxPages]="10"
                                  (changePage)="onChangePage($event)"></jw-pagination>
                          </div>
                      </div>


                      <!-- </ng-container> -->
                        <div class="row" *ngIf="searchresult">
                            <div class="col-lg-12 text-left" *ngIf="searchresult.length == 0">
                                <div class="billed-to">No Record Found </div>
                            </div> 
                        </div>
                      
                    </div>


                    <!-- <p class="job_profile"  *ngIf="!searchresult"> N/A</p> -->

                        <div class="browser-screen-loading-content" *ngIf="!searchresult" >
                            <div class="loading-dots dark-gray">
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>                                
                                <i></i>
                            </div>
                            <p>Loading</p>
                        </div>

                 

                </div>
            </div>
        </div>
    </div>
</div>



<jw-modal id="pdf-payment-confirmation" class="modal sendrequestcharge" role="dialog">
  <div role="document" class="formgroup">                         
      <div class="modal-content">                         
          <div class="modal-body text-center">                         
              <div class="container">
                  <div class="row">                         
                      <div class="col-lg-12 spaceset">
                          <div class="check">
                              <span class="lnr lnr-checkmark-circle"></span>
                          </div>
                          <!-- <p class="textinfoma">Per Click Charge <span class="charge">£5</span> </p> -->
                          <!-- <p class="text-center short">Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs</p> -->
                          <h4 class="mb-2">Are you sure to active the AML monitoring </h4>
                          <p class="">{{errormsg}}</p>                         
                      </div>
                      <div class="text-center m-auto">
                          <button type="submit"  class="btn com_btn btn-primary" (click)="onActive('pdf-payment-confirmation')"  [disabled]="!disableBtn"     name="updateform"  > Confirm</button>
                          <button type="submit"  class="btn com_btn btn-secondary"(click)="closeModal('pdf-payment-confirmation');" > Cancel</button>
                      </div>                           
                  </div>                         
              </div>                         
          </div>        
      </div>                         
  </div>                         
</jw-modal>



<jw-modal id="pdf-payment-confirmation-fail" class="modal sendrequestcharge" role="dialog">
  <div role="document" class="formgroup">                         
      <div class="modal-content">                         
          <div class="modal-body text-center">                         
              <div class="container">
                  <div class="row">                         
                      <div class="col-lg-12 spaceset">
                          <div class="check">
                              <span class="lnr lnr-checkmark-circle"></span>
                          </div>
                          <!-- <p class="textinfoma">Per Click Charge <span class="charge">£5</span> </p> -->
                          <!-- <p class="text-center short">Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs</p> -->
                          <h4 class="mb-2">Are you sure to Deactive the AML monitoring </h4>
                          <p class="">{{errormsg}}</p>                         
                      </div>
                      <div class="text-center m-auto">
                          <button type="submit"  class="btn com_btn btn-primary" (click)="onDeactive('pdf-payment-confirmation-fail')"  [disabled]="!disableBtn"  name="updateform"  > Confirm</button>
                          <button type="submit"  class="btn com_btn btn-secondary"(click)="closeModal('pdf-payment-confirmation-fail');" > Cancel</button>
                      </div>                           
                  </div>                         
              </div>                         
          </div>        
      </div>                         
  </div>                         
</jw-modal>