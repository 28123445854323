import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient   } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';


@Injectable({
  providedIn: 'root'
})
export class ChecksystemService {

 
  constructor(private commonService: CommonService,private http:HttpClient) { }


  getIPAddress(): Observable<any> 
  {
    return this.http.get("https://api.ipify.org/?format=json");  
    
    //return this.commonService.formpost('/check/remainitem',data);   
  }  


  getbillinginformation(data): Observable<any> 
  {
    return this.commonService.formpost('/check/remainitem',data);   
  }  


  gettokensystem(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/api/userloginbytoken');   
  }  
  gettokensystembytoken(): Observable<any> 
  {
    return this.commonService.formpostOnlyidbytoken('/api/userloginbytoken');   
  }  

  gettokenrefreestatus(data): Observable<any> 
  {
    return this.commonService.formpostwithouttoken('/referee/checkrefreetoken',data);   
  }  


  deleteAccessToken(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/api/deleteAccessToken');   
  } 


  deleteAccessTokenrightoworklog(data): Observable<any> 
  {
    return this.commonService.refereeformpost('/employee/righttoworklogtoken',data);   
  } 

  sendsmsurl(data): Observable<any> 
  {
    return this.commonService.formpost('/sendsmsurl',data);   
  } 

  

  genratetokenforemployee(data): Observable<any> 
  {
    return this.commonService.formpost('/api/genratetokenforemployee',data);   
  }  

  getAllServices(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/api/getCheckServices');   
  }  

  getAllInvoicedetails(data): Observable<any> 
  {
    return this.commonService.formpost('/check/getcheckinvoicedetails',data);
  }

  downloadCheckInvoice(data): Observable<any>
  {
    return this.commonService.formpost('/check/downloadcheckinvoice',data);
  }

  getAllInvoice(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/check/getallinvoice');   
  }  

  validateemail(data): Observable<any> 
  {
    return this.commonService.formget('/verifyemail/'+data);   
  }  
 

}
