"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".noflex[_ngcontent-%COMP%]{display:block!important}.gcirclesss[_ngcontent-%COMP%]{width:30px;height:30px;background:green;border-radius:50%;display:flex;align-items:center;justify-content:center;color:#fff;font-size:16px}.rcirclesss[_ngcontent-%COMP%]{width:30px;height:30px;background:#d80d3f;border-radius:50%;display:flex;align-items:center;justify-content:center;color:#fff;font-size:16px}.linebox[_ngcontent-%COMP%]{font-size:16px}.graycirclesss[_ngcontent-%COMP%]{width:30px;height:30px;background:#616161;border-radius:50%;display:flex;align-items:center;justify-content:center;color:#fff;font-size:16px}\n/*# sourceMappingURL=right-to-work-history.component.css.map*/"];
exports.styles = styles;
