<div class="bg">
  <app-header></app-header>

  <div class="section-wrapper p-0">
    <div class="admin_dashboard_show">

      <div class=" upgraderequest" *ngIf="ctype == 2 || ctype == 3"  >To access Complygate Compliance Centre, please upgrade your account. <button   class="btn btn-primary com_btn small_btn ng-star-inserted float-right"  [routerLink]="['/upgrade-your-membership']">Upgrade Your Account</button></div>
     <div class="ceee">
        <div class="cardmenu">
         
            <a class="card_box_lit"  [routerLink]="['/add-new-reference']" >
              <div class="samebox">
                <i class="lnr lnr-history"></i>
                <div class="menu_content">
                    <h4>New Reference Request</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
                
                
            </a>
         
         
            <a class="card_box_lit" [routerLink]="['/reference-status']"  >
              <div class="samebox">
                <i class="lnr lnr-clock"></i>
                <div class="menu_content">
                    <h4>Reference Status</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>                             
            </a>
         
         
            <a class="card_box_lit" [routerLink]="['/reference-setting']">
              <div class="samebox">
                <i class="lnr lnr-gift"></i>
                <div class="menu_content">
                    <h4>Reference Profile</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
            </a>
          
          
             <a class="card_box_lit" [routerLink]="['/add-bulk-reference']">
              <div class="samebox">
                <i class="lnr lnr-frame-expand"></i>
                <div class="menu_content">
                    <h4>Bulk Referencing Request </h4>
                     
                </div>                
              </div>               
            </a>
         
          
            <a class="card_box_lit" [routerLink]="['/bulk-reference-status']">
              <div class="samebox">
                <i class="lnr lnr-apartment"></i>
                <div class="menu_content">
                    <h4> Bulk Referencing Request Status</h4>
                   
                </div>
              </div>
            </a>
         
         
            <a class="card_box_lit" [routerLink]="['/reference-notification-setting']">
              <div class="samebox">
                <i class="lnr lnr-cog"></i>
                <div class="menu_content">
                    <h4>Notification Setting</h4>
                    
                </div>
              </div>
            </a>
         
        </div>
      </div>
     
</div>
  
</div>