import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EmployeeService } from '../../../services/employee.service';
import { ModalService } from '../../../_services/modal.service';
import { Profile, Termination } from '../../../models/overview/profile.model';
import { CountryService } from '../../../services/country.service';
import { CommonService } from '../../../services/common.service';
import { JobService } from '../../../services/job.service';
import { CandidateService } from '../../../services/candidate.service';
import { UserService } from '../../../services/user.service';
import { PermissionService } from '../../../services/permission.service';
import { PersonaltypeService } from '../../../services/personaltype.service';
import { CompanyService } from '../../../services/company.service';
import { AlertService } from '../../../services/alert.service';
import { ChecksystemService } from '../../../services/checksystem.service';
import { Router, ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-checkaddemployee',
  templateUrl: './checkaddemployee.component.html',
  styleUrls: ['./checkaddemployee.component.css']
})
export class CheckaddemployeeComponent implements OnInit {
  username:any;
  mindob = new Date();
  maxdob = new Date();
  employeeForm: FormGroup;
  phonecheck: any;
  permissionstring: any;  
  Userid: any;
  ismail: any;
  emailexist = false;
  disableBtn: any;
  submitted: any;
  empidexist = false;
  isFocus: boolean = true;
  emptype:any;
  overviewData: any;
  defaultVal:any;
  countrylist:any;
  empleeid:any;
  
  constructor(private userService: UserService, 
    private employeeService: EmployeeService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private commonService: CommonService,
    private formBuilder: FormBuilder, 
    private jobdetails: JobService, 
    private candidatedetails: CandidateService,
    private permissiondetails: PermissionService,
    private dltypeService: PersonaltypeService,
    private companyService: CompanyService,
    private alerts: AlertService,    
    private check: ChecksystemService,
    private route: ActivatedRoute,
     private router: Router
    ) {

      route.params.subscribe(params => {
        this.emptype = params['type'];
        
        console.log(this.emptype);
      }); }

  ngOnInit() {  
    this.disableBtn = true;
  this.defaultVal =''; this.username ='';
  
    this.employeeForm = this.formBuilder.group({
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],      
      // username: ['', Validators.required],
      empid: [''],
      gender: ['', Validators.required],
      maritalstatus: [''],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: ['', Validators.compose([Validators.required])],
      nationalityid: [''],
      residenceid: [''],
      dateofbirth: [''],
      hiredate: [''],
      emprole: ['']
    });


    this.mindob.setDate(this.mindob.getDate() - 23741);
    this.maxdob.setDate(this.maxdob.getDate() - 574);





    this.permissiondetails.bindallmenu().subscribe((menudata: any) => {
      var permissionstring = "[{";
      var perarray = [];
      menudata.forEach(function (valuemenu) {
        perarray.push('"' + valuemenu.clname + '": 0');
      });
      
      var perstr = perarray.join();
      permissionstring = permissionstring + perstr + "}]";
      this.permissionstring = permissionstring;
    });
    
    this.overviewData = new Profile();
    this.overviewData.gender = "1";
    this.overviewData.maritalstatus = "0";
    this.phonecheck = "";
    this.overviewData.jobid = "";
    this.overviewData.candidateid = "";
    this.overviewData.nationalityid = "";
    this.overviewData.residenceid = "";
    this.overviewData.phonenumber = "+44";
    this.overviewData.countryofbirthid = "";
    this.overviewData.ismail = false;
    this.isFocus = false;
  }

  
  get f() { return this.employeeForm.controls; }
  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
     
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }
  onFocusOutEvent(event: any)
  {
    this.userService.isCompanyEmailRegisterd(event.target.value).subscribe((data: any) => {
      if (data.sussess == 'success') {
        this.emailexist = true; 
        this.disableBtn = false;
        this.isFocus = true;
        this.empleeid = data.employeeid;
        this.modalService.open('alreadyadd-request');
      }else
      {
        this.disableBtn = true;
        
      }      
    });    
  }

  
  onUserNameOutEvent(event: any)
  {       
    if(event.target.value == '')
    {
        this.username ='';
    }else
    {

      console.log('rahul kumar tanwar');
      this.username ='';
      var dbvalue = { 'username': event.target.value}
      this.userService.isUserRegisterd(event.target.value).subscribe((data: any) => {
    // this.userService.isUserRegisterd(event.target.value).subscribe((data2: any) => {

      if (data.sussess == 'false') {
        this.username = 'false';
      }else
      {
        this.username = 'true';
      }
    });
  }
  }

  focusFunction() {
    this.emailexist = false;
  }
  
  onAddEmployeeSubmit(employeeform1: any, modelid: any) {
    this.submitted = true;
    if (this.employeeForm.invalid) {
      return;
    }

    this.disableBtn = false;
    
    employeeform1.id = this.Userid;
    employeeform1.permission = this.permissionstring;



    
    // this.userService.isCompanyEmailRegisterd(employeeform1.emailid).subscribe((data2: any) => {

    //   if (data2.sussess == 'success') {
    //     this.username = 'true';
    //     this.disableBtn = true;
    //   }else{

    //     this.username = '';


    this.userService.isCompanyEmailRegisterd(employeeform1.emailid).subscribe((data: any) => {
      if (data.sussess == 'success') {
        this.emailexist = true; 
        this.disableBtn = true;
      }
      else {       
          this.disableBtn = false;
          this.employeeService.checkemployeecreate(employeeform1).subscribe((dataw: any) => {
            if(dataw.susses == 'true'){
              if(this.emptype == 'visa'){
                this.router.navigate(['/check-ai-visa-admin', dataw.employeemasterid]);
              } else if(this.emptype == 'passport'){
                this.router.navigate(['/check-ai-passport-admin', dataw.employeemasterid]);
              } else if(this.emptype == 'document'){
                this.router.navigate(['/check-ai-document-admin', dataw.employeemasterid]);
              } else if(this.emptype == 'basic-dbs'){
                this.router.navigate(['/dbs/basic']);
              } else if(this.emptype == 'standard-dbs'){
                this.router.navigate(['/dbs/standard']);
              } else if(this.emptype == 'enhanced-dbs'){
                this.router.navigate(['/dbs/enhanced']);
              }
            }else
            { this.alerts.error('Somthing went wrong. please try again after sometime');}
            
          });
      }
    });

//   }
// });




  }

  addbio(){

    if(this.emptype == 'visa'){
      this.router.navigate(['/check-ai-visa-admin', this.empleeid]);
    } else if(this.emptype == 'passport'){
      this.router.navigate(['/check-ai-passport-admin',  this.empleeid]);
    } else if(this.emptype == 'document'){
      this.router.navigate(['/check-ai-document-admin', this.empleeid]);
    } else if(this.emptype == 'basic-dbs'){
      this.router.navigate(['/dbs/basic']);
    } else if(this.emptype == 'standard-dbs'){
      this.router.navigate(['/dbs/standard']);
    } else if(this.emptype == 'enhanced-dbs'){
      this.router.navigate(['/dbs/enhanced']);
    }
 
  }

  sendrequest(employeeid, id)
  {

    var ts = {employeeid: employeeid, requesttype: this.emptype}
    this.check.genratetokenforemployee(ts).subscribe((remaindata: any) => { 
      this.alerts.success('Application Request Sent Successfully');
      this.modalService.close(id);
      
    });

  }

  closeModal(id)
  {
    this.disableBtn = true;
    this.overviewData.email ='';
    this.modalService.close(id);
  }
}
