<style>
  .card {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    text-align: center;
  }

  .plan-card .plan-price span {
    font-size: 15px;
    vertical-align: top;
  }

  .card-block {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
  }

  .plan-card .plan-features p span {
    font-size: 12px;
    line-height: 18px !important;
    width: 100%;
    float: left;
  }

  .text-pink {
    color: #f06292;
  }

  .text-primary {
    color: #67a8e4 !important;
  }

  .text-teal {
    color: #009688;
  }
/*
  .jw-modal {
    max-width: 30%;
    top: 30%;
  } */

  h5.billing{
    margin-bottom: 10px;
    border-bottom: 1px dotted #c30f24;
    width: 100%;
    font-size: 1rem;
    color: #c30f24;
    padding-bottom: 6px;
    text-align: center;
  }
  .StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid #000;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  /* .jw-modal {
    top: 30% !important;
    max-width: 440px !important;
  } */
</style>
<header class="complygate_header pt-2 pb-2">
  <div class="container">
          <div class="main_header">
              <div class="mobile_toggle" (click)="toggle()">
                  <span class="lnr lnr-menu"></span>
              </div>
              <div class="logo">
                <a  [routerLink]="['/']" ><img src="/assets/img/logo.png" alt=""><span></span></a>

              </div>
              <div class="right_menu">
                <button class="demo btn btn-success com_btn mr-2" data-toggle="modal" (click)="openModal('custom-coup-1')"
            id="df">Any Discount Coupon</button>
                <div class="onoffswitch1 mb-0" *ngIf="">
                  <input type="checkbox" name="plantype" class="onoffswitch-checkbox" id="plantype"
                    (change)="selectPlanType($event.target.checked);">
                  <label class="onoffswitch-label no-border" for="plantype">
                    <span class="onoffswitch-inner1"></span>
                    <span class="onoffswitch-switch no-border"></span>
                  </label>
                </div>
                  <div class="notification_show d-none d-sm-block">
                      <a (click)="logout()">  <span class="lnr lnr-power-switch"></span></a>
                      <div class="tooltip_visa">
                          <p >Logout</p>
                      </div>
                  </div>
              </div>
          </div>
  </div>




</header>
<!-- <div class="slim-header">
  <div class="container">
    <div class="slim-header-left">
      <h2 class="slim-logo"><a href="#"><img src="/assets/img/logo.png" alt=""><span></span></a></h2>

    </div>
    <div class="dropdown dropdown-b"> <a href="#" class="header-notification" data-toggle="dropdown" (click)="logout()"
        title="Logout"> <i class="fa fa-power-off"></i> </a></div>
  </div>
</div> -->
<div class="signin-wrapper payment">
  <div class="width100">
    <!-- <div class="row text-center">
      <div class="col-lg-12 pull-left">
        <div class="onoffswitch1">
          <input type="checkbox" name="plantype" class="onoffswitch-checkbox" id="plantype"
            (change)="selectPlanType($event.target.checked);">
          <label class="onoffswitch-label" for="plantype">
            <span class="onoffswitch-inner1"></span>
            <span class="onoffswitch-switch"></span>
          </label>
        </div>

      </div>
    </div> -->
    <div class="payment_plan mt-4">
      <div class="row">
          <div class="col-sm-4 mb-3" *ngFor="let pl of planlist; let i=index">
            <div class="plan">
                <div class="plan_header" [ngClass]="pl.planid == 2 || pl.planid == 5 ?'popular':''">
                  <span class="most_popular" *ngIf="pl.planid == 2 || pl.planid == 5">Most Popular</span>
                    <p class="plan_name">{{ pl.plantitle }}</p>
                    <div class="price">£ {{ pl.prices }}
                      <span class="sub" *ngIf="!pl.plantype">Per Month</span>
                      <span class="sub" *ngIf="pl.plantype">Per Year</span>
                    </div>
                    <p class="employeebef">{{ pl.planname }}</p>
                </div>
                <div class="plan_details">
                    <ul>
                      <li *ngFor="let pl3 of pl.plande"><img src="../../../../../assets/img/check.png"> <div innerHTML="{{pl3.descname}}"></div> </li>
                    </ul>
                </div>
                <div class="plan_btn">
                  <button type="submit" class="btn btn-primary com_btn"
                  (click)="updateyourplan(pl.stripplanid, 'custom-payment-1',pl.planid)">Purchase </button>
                </div>
            </div>
          </div>
          <!-- <div class="col-sm-4">
            <div class="plan">
                <div class="plan_header popular">
                  <span class="most_popular">Most Popular</span>
                    <p class="plan_name">Medium</p>
                    <div class="price">£ 500 <span class="sub">Per Month</span></div>
                    <p class="employeebef">26-75 EMPLOYEES</p>
                </div>
                <div class="plan_details">
                    <ul>
                      <li><img src="../../../../../assets/img/check.png"> No setup charge</li>
                      <li><img src="../../../../../assets/img/check.png"> 3 Admins</li>
                      <li><img src="../../../../../assets/img/check.png"> All Modules</li>
                      <li><img src="../../../../../assets/img/check.png"> Tier 2 Sponsor Compliance</li>
                      <li><img src="../../../../../assets/img/check.png"> Mobile Apps</li>
                      <li><img src="../../../../../assets/img/check.png"> No Training fees</li>
                      <li><img src="../../../../../assets/img/check.png"> Free support: 18 months</li>
                      <li><img src="../../../../../assets/img/check.png"> Storage 5GB</li>
                    </ul>
                </div>
                <div class="plan_btn">
                  <button type="submit" class="btn btn-primary com_btn">Upgrade Plan </button>
                </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="plan">
                <div class="plan_header">
                    <p class="plan_name">Large</p>
                    <div class="price">£ 600 <span class="sub">Per Month</span></div>
                    <p class="employeebef">1-25 EMPLOYEES</p>
                </div>
                <div class="plan_details">
                    <ul>
                      <li><img src="../../../../../assets/img/check.png"> No setup charge</li>
                      <li><img src="../../../../../assets/img/check.png"> 10 Admins</li>
                      <li><img src="../../../../../assets/img/check.png"> All Modules</li>
                      <li><img src="../../../../../assets/img/check.png"> Tier 2 Sponsor Compliance</li>
                      <li><img src="../../../../../assets/img/check.png"> Mobile Apps</li>
                      <li><img src="../../../../../assets/img/check.png"> No Training fees</li>
                      <li><img src="../../../../../assets/img/check.png"> Free support: 12 months</li>
                      <li><img src="../../../../../assets/img/check.png"> Storage 10GB</li>
                    </ul>
                </div>
                <div class="plan_btn">
                  <button type="submit" class="btn btn-primary com_btn">Upgrade Plan </button>
                </div>
            </div>
          </div> -->
      </div>
    </div>
    <!-- <div class="col-lg-4 offset-4 mt-3">
      <div class="card plan-card text-center">
        <div id="pnlCouponAllow" class="card-block">
          <p>Any Discount Coupon</p>
          <button class="demo btn-primary btn-lg" data-toggle="modal" (click)="openModal('custom-coup-1')"
            id="df">Click Here</button>
        </div>
      </div>
    </div> -->

  </div>
</div>

<jw-modal id="custom-coup-1" role="dialog" class="modal ">
  <form [formGroup]="planformGroup" #expenseForm="ngForm" class="s12 white"
    (ngSubmit)="onSubmit(planformGroup.value,'custom-coup-1')">
    <div role="document" class="formgroup">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="popupLabel">Apply Coupons</h5>
          <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
            (click)="closeModal('custom-coup-1');">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="form-group col-lg-12">
                <p>{{message}}</p>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-12">
                <label>Enter your coupons*</label>
                <input type="text" formControlName="coupnsnumber" class="" name="coupnsnumber" id="coupnsnumber"
                  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.coupnsnumber.errors }"
                  ngModel="{{ planDetail.coupnsnumber }}">
                <div *ngIf="submitted && f.coupnsnumber.errors" class="invalid-feedback">
                  <div *ngIf="f.coupnsnumber.errors.required">Please enter your coupons</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <input type="button" class="btn btn-primary com_btn" name="updateform" *ngIf="cpnumber !=''" (click)="removecoupons()" value="Remove Coupons" />

          <input type="submit" class="btn btn-primary com_btn" name="updateform"  *ngIf="cpnumber ==''"  value="{{buttoninsert}}" />
          <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel"
            (click)="closeModal('custom-coup-1');" />
        </div>
      </div>
    </div>
  </form>
</jw-modal>

<jw-modal id="custom-payment-1" role="dialog" class="modal paycut">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="popupLabel">Payment</h5>
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
          (click)="closeModal('custom-payment-1');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="invalidError" class="text-center mt-2" style="color:red">
        {{ invalidError.message }}
      </div>

      <form [formGroup]="addpopForm" #addresslistForm="ngForm" class="s12 padding453 white"  *ngIf="addresslistDetail">

      <div class="row">
        <div class="col-sm-12">
          <h5 class="">Billing Information</h5>
          <hr>
        </div>
        <div class="col-sm-12">
            <div class="row">
              <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">Card Holder Name*</label>
                <input type="text" formControlName="cardname" name="cardname" id="cardname" 
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && g.cardname.errors }" required 
                    [ngModel]="addresslistDetail.cardname" maxlength="60">
                <div *ngIf="submitted && g.cardname.errors" class="invalid-feedback">
                    <div *ngIf="g.cardname.errors.required">Please enter card holder name</div>
                </div>
              </div>
              <div class="form-group mb-2 col-lg-6">
                <label class="mb-0">Address Line 1*</label>
                <input type="address1" formControlName="address1" name="address1" id="address1"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && g.address1.errors }" required
                    [ngModel]="addresslistDetail.address1" maxlength="60">
                <div *ngIf="submitted && g.address1.errors" class="invalid-feedback">
                    <div *ngIf="g.address1.errors.required">Please enter address line 1</div>
                </div>
              </div>
              <div class="form-group mb-2 col-lg-6">
                <label class="mb-0">Address Line 2 </label>
                <input type="address2" formControlName="address2" name="address2" id="address2"
                    class="form-control" [ngModel]="addresslistDetail.address2" maxlength="60">
              </div>
              <div class="form-group mb-2 col-lg-6">
                <label class="mb-0">City*</label>
                <input type="text" formControlName="cityname" name="cityname" class="form-control" id="cityname" maxlength="30"
                    [ngClass]="{ 'is-invalid': submitted && g.cityname.errors }" required [ngModel]="addresslistDetail.cityname">
                <div *ngIf="submitted && g.cityname.errors" class="invalid-feedback">
                    <div *ngIf="g.cityname.errors.required">Please enter city</div>
                </div>
              </div>
              <div class="form-group mb-2 col-lg-6">
                <label class="mb-0">County Name*</label>
                <input type="text" formControlName="statename" name="statename" id="statename" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && g.statename.errors }" required
                    [ngModel]="addresslistDetail.statename" maxlength="30">
                <div *ngIf="submitted && g.statename.errors" class="invalid-feedback">
                    <div *ngIf="g.statename.errors.required">Please enter county</div>
                </div>
               </div>
               <div class="form-group mb-2 col-lg-6">
                <label class="mb-0">Postcode*</label>
                <input type="text" formControlName="pincode" name="pincode" class="form-control" id="pincode" maxlength="10"
                    [ngClass]="{ 'is-invalid': submitted && g.pincode.errors }" required [ngModel]="addresslistDetail.pincode">
                <div *ngIf="submitted && g.pincode.errors" class="invalid-feedback">
                    <div *ngIf="g.pincode.errors.required">Please enter postcode</div>
                </div>
               </div>
               <div class="form-group mb-2 col-lg-6">
                <label class="mb-0">Country*</label>
                <select style="width:100%;" formControlName="countryid" class="form-control input-lg" id="countryid" name="countryid" [ngClass]="{ 'is-invalid': submitted && g.countryid.errors }" (change)="onSelectCountry($event.target.value)" [ngModel]="addresslistDetail.countryid">
                    <option [value]="defaultVal">Select Country</option>
                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                </select>
                <div *ngIf="submitted && g.countryid.errors" class="invalid-feedback">
                    <div *ngIf="g.countryid.errors.required">Please select country</div>
                </div>
                </div>
                <div class="form-group mb-2 col-lg-6">
                  <label class="mb-0">Tax ID</label>
                  <input type="text" formControlName="taxid" name="taxid" class="form-control" id="taxid" maxlength="10"
                       [ngModel]="addresslistDetail.taxid">

              </div>
            </div>
        </div>
        <div class="col-sm-5" style="display: none;">
          <div class="payment_strip">
            <label>Enter Your Card Details</label>
            <stripe-card class="setdesign" #stripeCard (catch)="onStripeError($event)" [(invalid)]="invalidError"
              (tokenChange)="setStripeToken($event,addresslistForm.value, 'custom-payment-1')"></stripe-card>
          </div>
          <div class="optionss">
            <img src="assets/img/paycard.png">
          </div>
         </div>
       </div>
       <div class="modal-footer">

        
        <!-- <button type="button" class="btn btn-primary com_btn" (click)="stripeCard.createToken(extraData)">Pay Now</button> -->
        <button type="button" class="btn btn-primary com_btn" (click)="purchasepayment(addresslistForm.value, 'custom-payment-1')">Pay Now</button>
       </div>

  </form>
    </div>
  </div>
</jw-modal>






<jw-modal id="custom-payment-25" role="dialog" class="modal">
  <div role="document" class="formgroup" *ngIf="iframepaymentautsrc">
      <div class="modal-content">
          <div class="modal-body p-0">
              <div class="container">
                <div class="info_mess">
                    <div class="para">
                            <h4 class="mb-2">Please do not refresh or close the browser while we process your order.</h4>
                            <!-- <div class="card_strip_option">                                 -->
                              <iframe  [src]="iframepaymentautsrc" width="900" height="450" frameborder="0" style="width:900px"></iframe>
                          <!-- </div>                           -->
                        </div>
                    </div>
              </div>
          </div>          
      </div>
  </div>
</jw-modal>