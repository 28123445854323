import { State } from '../address/state.model';
import { Country } from '../address/country.model';
import { Address } from '../address/address.model';

export class Companylocation 
{  
  id: number;
  companylocationdetailsid: string;
  employeeid: number;
  employeeaddresdeatilid: number;
  address1: string;
  address2: string;
  cityname: string;
  pincode: string;
  stateid: number;
  statename: string;
  countryid: number;
  Address: Address;
  country: Country;
  county: State;
  companytypeid: string;
}