"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var country_service_1 = require("../../../services/country.service");
var myvisa_service_1 = require("../../../services/myvisa.service");
var modal_service_1 = require("../../../_services/modal.service");
var forms_1 = require("@angular/forms");
var visa_model_1 = require("../../../models/visa/visa.model");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var alert_service_1 = require("../../../services/alert.service");
var aicenter_service_1 = require("../../../services/aicenter.service");
var VisarequestComponent = /** @class */ (function () {
    function VisarequestComponent(sanitizer, formBuilder, uploadService, visaService, country, modalService, aicenter, router, alerts) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.visaService = visaService;
        this.country = country;
        this.modalService = modalService;
        this.aicenter = aicenter;
        this.router = router;
        this.alerts = alerts;
        this.aipayment = false;
        this.filterItems = [
            {
                name: 'All Visa Details',
                id: 0,
                selected: true
            },
            {
                name: 'Accepted',
                id: 2,
                selected: false
            },
            {
                name: 'Rejected',
                id: 3,
                selected: false
            },
            {
                name: 'Waiting for review',
                id: 1,
                selected: false
            }
        ];
    }
    VisarequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.requestperm = this.permission.m15;
        this.visapopForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            visanumber: ['', forms_1.Validators.required],
            visatype: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            visaexpiry: ['', forms_1.Validators.required],
            visastart: ['', forms_1.Validators.required],
            iscurrent: [''],
            stype: ['']
        });
        this.filename = 'Choose file';
        this.filebackname = "Choose file";
        this.visaDetail = new visa_model_1.Visa();
        var id = localStorage.getItem('userToken');
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'cstatus': [1] };
        this.aicenter.getcountpayment().subscribe(function (data) {
            _this.aipayment = data.payment;
        });
        this.visaService.getallvisaListbyadmin(data).subscribe(function (data) {
            _this.record = data.length;
            _this.datalist = data;
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.items = data;
            }
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.listcheck = this.filterItems;
    };
    VisarequestComponent.prototype.onDelete = function (id) {
        var _this = this;
        var data1 = { 'id': id };
        if (confirm("Are you sure you want to delete?")) {
            this.visaService.deletevisa(data1).subscribe(function (data) {
                var sstatus;
                for (var _i = 0, _a = _this.filterItems; _i < _a.length; _i++) {
                    var c = _a[_i];
                    if (c.selected == true) {
                        if (c.id == 0) {
                            sstatus = [1, 2, 3];
                        }
                        else {
                            sstatus = [c.id];
                        }
                    }
                }
                var data2 = { 'id': _this.Userid, 'cstatus': sstatus };
                _this.visaService.getallvisaListbyadmin(data2).subscribe(function (data) {
                    _this.record = data.length;
                    _this.datalist = data;
                    if (_this.record < 30) {
                        _this.pageOfItems = data;
                    }
                    else {
                        _this.items = data;
                    }
                });
                _this.alerts.success('Visa deleted successfully.', true);
            });
        }
    };
    VisarequestComponent.prototype.onlistchange = function (type1, idval) {
        var _this = this;
        var sstatus;
        for (var _i = 0, _a = this.filterItems; _i < _a.length; _i++) {
            var c = _a[_i];
            if (c.id == idval)
                c.selected = true;
            else
                c.selected = false;
        }
        if (idval == 0 || type1 == false) {
            sstatus = [1, 2, 3];
        }
        else {
            sstatus = [idval];
        }
        var userid = localStorage.getItem('userid');
        var data = { 'id': userid, 'cstatus': sstatus };
        this.visaService.getallvisaListbyadmin(data).subscribe(function (data) {
            _this.data = data;
        });
    };
    Object.defineProperty(VisarequestComponent.prototype, "f", {
        get: function () {
            return this.visapopForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    VisarequestComponent.prototype.onSubmit = function (value3, id, stype) {
        var _this = this;
        this.submitted = true;
        if (this.visapopForm.invalid) {
            if (!this.selectedFiles)
                this.filerequirederror = true;
            // if(!this.selectedbackFiles)
            //   this.filebackrequirederror = true;
            return;
        }
        this.disableBtn = false;
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagename = imagepath;
        }
        else {
            // if(value3.id == '0')
            // {
            //   this.filerequirederror = true;
            //   return;
            // }
            if (value3.id == '0') {
                value3.imagebackname = '';
            }
            else {
                value3.imagename = this.visaDetail.visadocname;
            }
        }
        if (this.selectedbackFiles) {
            this.file = this.selectedbackFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filebacksizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.filebackformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagebackname = imagepath;
        }
        else {
            if (value3.id == '0') {
                this.filebackrequirederror = true;
                return;
            }
            value3.imagebackname = this.visaDetail.visabackdocname;
        }
        value3.stype = stype;
        value3.createby = this.Userid;
        this.visaService.myvisadetailsUpdatebyadmin(value3).subscribe(function (data) {
            var userid = localStorage.getItem('userid');
            var data1 = { 'id': userid, 'cstatus': [1] };
            _this.visaService.getallvisaListbyadmin(data1).subscribe(function (datavisa) {
                _this.record = datavisa.length;
                _this.datalist = datavisa;
                if (_this.record < 30) {
                    _this.pageOfItems = datavisa;
                }
                else {
                    _this.items = datavisa;
                }
            });
        });
        this.filename = "Choose file";
        this.filebackname = "Choose file";
        this.alerts.success('Visa Details Updated Successfully.', true);
        // this.visaDetail = new Visa();
        this.modalService.close(id);
    };
    VisarequestComponent.prototype.selectFile = function (event) {
        var files = event.target.files;
        this.filename = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.fileformaterror = true;
            this.filesizeerror = false;
        }
        else {
            this.filesizeerror = false;
            this.filereuirederror = false;
            this.fileformaterror = false;
            this.selectedFiles = files[0];
        }
    };
    VisarequestComponent.prototype.selectbackFile = function (event) {
        this.filebackrequirederror = false;
        this.filebacksizeerror = false;
        this.filebackformaterror = false;
        var files = event.target.files;
        this.filebackname = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filebacksizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.filebackformaterror = true;
            this.filebacksizeerror = false;
        }
        else {
            this.filebacksizeerror = false;
            this.filebackformaterror = false;
            this.selectedbackFiles = files[0];
        }
    };
    VisarequestComponent.prototype.formsubmit = function () {
        // document.getElementById('visa-form-submit').submit();
    };
    VisarequestComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.disableBtn = true;
        this.buttoninsert = "Submit";
        this.visaDetail = new visa_model_1.Visa();
        this.Userid = localStorage.getItem('userid');
        this.visaDetail.employeeid = this.Userid;
        this.visaDetail.employeevisadeatilid = "0";
        this.visaDetail.iscurrent = "0";
        // this.visaDetail.visaexpiry ='';
        // this.visaDetail.visastart = '';
        // this.visaDetail.visatype = '';
        // this.visaDetail.nationalityid = '';
        this.filename = "Choose file";
        this.filebackname = "Choose file";
        this.modalService.open(id);
    };
    VisarequestComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    VisarequestComponent.prototype.openModal3 = function (id, data, backimg) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        if (backimg) {
            var fullpathArray = backimg.split('.');
            var typeoffile = fullpathArray[fullpathArray.length - 1];
            if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
                this.imgvisabacksrc = true;
                this.iframevisabacksrc = backimg;
            }
            else {
                this.imgvisabacksrc = false;
                this.iframevisabacksrc = this.getSafeUrl("https://docs.google.com/gview?url=" + backimg + "&embedded=true");
            }
        }
        else {
            this.imgvisabacksrc = false;
            this.iframevisabacksrc = '';
        }
        this.modalService.open(id);
    };
    VisarequestComponent.prototype.openModal = function (id, data) {
        this.visa = new visa_model_1.Visa();
        this.disableBtn = true;
        this.visaDetail = new visa_model_1.Visa();
        this.visaDetail = data;
        // console.log(data);
        this.buttoninsert = "Update";
        this.modalService.open(id);
        if (data.iscurrent) {
            this.visaDetail.iscurrent = "1";
        }
        else {
            this.visaDetail.iscurrent = "0";
        }
    };
    VisarequestComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    VisarequestComponent.prototype.closeModal = function (id) {
        var _this = this;
        var data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
        this.visaService.getallvisaListbyadmin(data).subscribe(function (data) {
            _this.record = data.length;
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.items = data;
            }
            _this.modalService.close(id);
            // this.data = data;
            // this.record = data.length;
        });
    };
    return VisarequestComponent;
}());
exports.VisarequestComponent = VisarequestComponent;
