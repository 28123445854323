"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./thanks.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/router");
var i3 = require("@angular/common");
var i4 = require("./thanks.component");
var styles_ThanksComponent = [".loader[_ngcontent-%COMP%] {\n        border: 16px solid #f3f3f3;\n        border-radius: 50%;\n        border-top: 16px solid blue;\n        border-right: 16px solid green;\n        border-bottom: 16px solid red;\n        border-left: 16px solid pink;\n        width: 80px;\n        height: 80px;\n        -webkit-animation: spin 2s linear infinite;\n        animation: spin 2s linear infinite;\n        position: absolute;\n        left: 45%;\n        top: 40%;\n    }\n\n    @-webkit-keyframes spin {\n        0% {\n            -webkit-transform: rotate(0deg);\n        }\n\n        100% {\n            -webkit-transform: rotate(360deg);\n        }\n    }\n\n    @keyframes spin {\n        0% {\n            transform: rotate(0deg);\n        }\n\n        100% {\n            transform: rotate(360deg);\n        }\n    }", i0.styles];
var RenderType_ThanksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ThanksComponent, data: {} });
exports.RenderType_ThanksComponent = RenderType_ThanksComponent;
function View_ThanksComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "not_found"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "thank_youpage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "col-sm-6  d-flex  align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "thanks_text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Thank you"])), (_l()(), i1.ɵted(-1, null, [" for the Payment"])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If you are not redirected to the next page. Please "])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(13, 1), (_l()(), i1.ɵted(-1, null, [" click here"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "img", [["class", "img width100"], ["src", "../../../../../assets/img/hero-bg.png"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = _ck(_v, 13, 0, "/dashboard"); _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 12).target; var currVal_1 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_0, currVal_1); }); }
exports.View_ThanksComponent_0 = View_ThanksComponent_0;
function View_ThanksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-thanks", [], null, null, null, View_ThanksComponent_0, RenderType_ThanksComponent)), i1.ɵdid(1, 114688, null, 0, i4.ThanksComponent, [i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ThanksComponent_Host_0 = View_ThanksComponent_Host_0;
var ThanksComponentNgFactory = i1.ɵccf("app-thanks", i4.ThanksComponent, View_ThanksComponent_Host_0, {}, {}, []);
exports.ThanksComponentNgFactory = ThanksComponentNgFactory;
