"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../../_services/modal.service");
var country_service_1 = require("../../../../services/country.service");
var aml_model_1 = require("../../../../models/aml/aml.model");
var aml_service_1 = require("../../../../services/aml.service");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var SearchpersonalamldetailsComponent = /** @class */ (function () {
    function SearchpersonalamldetailsComponent(sanitizer, modalService, amldetails, router, activatedRoute, country) {
        this.sanitizer = sanitizer;
        this.modalService = modalService;
        this.amldetails = amldetails;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.country = country;
        this.resultevidancelist = [];
        this.noresult = false;
        this.tPEPs = 0;
        this.tCurrentSanctions = 0;
        this.tDisqualifiedDirector = 0;
        this.tFinancialRegulator = 0;
        this.tInsolvency = 0;
        this.tLawEnforcement = 0;
        this.tAdverseMedia = 0;
        this.tPreviousSanctions = 0;
        this.PEPs = [];
        this.CurrentSanctions = [];
        this.DisqualifiedDirector = [];
        this.FinancialRegulator = [];
        this.Insolvency = [];
        this.LawEnforcement = [];
        this.AdverseMedia = [];
        this.PreviousSanctions = [];
        this.atype = 0;
    }
    SearchpersonalamldetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.downloadtext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
        var newlist;
        var newpersonalid;
        this.bussinessdetails = new aml_model_1.amldetailsclass();
        this.personid = this.activatedRoute.snapshot.paramMap.get("personid");
        newpersonalid = this.personid;
        this.searchresult = [];
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
            _this.logid = _this.activatedRoute.snapshot.paramMap.get("id");
            _this.resourceId = _this.activatedRoute.snapshot.paramMap.get("resourceid");
            var val = {};
            if (_this.resourceId)
                val = { id: _this.logid, atype: 0, resourceId: _this.resourceId };
            else
                val = { id: _this.logid, atype: 0 };
            _this.amldetails.getCompanylogrecord(val).subscribe(function (searchRes) {
                var listmaster = JSON.parse(searchRes.searchresult);
                var director = JSON.parse(searchRes.director);
                var opendirector = JSON.parse(searchRes.opendirector);
                _this.searchcondition = JSON.parse(searchRes.searchcondition);
                _this.atype = searchRes.atype;
                if (searchRes.atype == '0') {
                    _this.backtext = 'Back to Search Results';
                }
                else if (searchRes.atype == '3') {
                    _this.backtext = 'Back to Search Results ';
                }
                else if (searchRes.atype == '2') {
                    _this.backtext = 'Back to Search Results';
                }
                if (searchRes.iscomplete === 0 && !_this.resourceId) {
                    if (listmaster.results.matchCount == 0) {
                        _this.noresult = true;
                        _this.result = true;
                        _this.searchcondition = JSON.parse(searchRes.searchcondition);
                    }
                }
                else {
                    _this.noresult = false;
                    _this.searchresult = listmaster;
                    _this.bindattribute(listmaster);
                    _this.directors = director;
                    _this.opendirector = opendirector;
                    _this.bussinessdetails = listmaster;
                    console.log(listmaster);
                }
            });
        });
    };
    SearchpersonalamldetailsComponent.prototype.loaddatasystem = function () {
        var _this = this;
        this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        var dd = {};
        if (this.resourceId) {
            dd = { id: this.logid, atype: this.atype, resourceId: this.resourceId };
        }
        else {
            dd = { id: this.logid, atype: this.atype };
        }
        this.amldetails.generateNewbussinessPdf(dd).subscribe(function (pdfRes) {
            console.log(pdfRes.response_data.Location);
            _this.url = pdfRes.response_data.Location;
            window.open(_this.url, "_blank");
            _this.downloadtext = 'Dwonload <i class="fa fa-file-pdf-o ml-2"></i>';
        });
    };
    SearchpersonalamldetailsComponent.prototype.amllogredirect = function () {
        if (this.atype == '0' && this.resourceId)
            this.router.navigate(['/search-personal-aml', 'aml', this.logid]);
        else if (this.atype == '2' && this.resourceId)
            this.router.navigate(['/search-personal-aml', 'adverse', this.logid]);
        else if (this.atype == '3' && this.resourceId)
            this.router.navigate(['/search-personal-aml', 'global', this.logid]);
        else if (this.atype == '0')
            this.router.navigate(['/check-aml-log-list', '0']);
        else if (this.atype == '2')
            this.router.navigate(['/check-aml-log-list', '2']);
        else
            this.router.navigate(['/check-aml-log-list', '3']);
    };
    SearchpersonalamldetailsComponent.prototype.urlfind = function (url) {
        if (url != null) {
            var slashslash = url.indexOf("//") + 2;
            return url.substring(slashslash, url.indexOf('/', slashslash));
        }
        else {
            return "";
        }
    };
    SearchpersonalamldetailsComponent.prototype.bindallcountry = function (val) {
        if (val) {
            return this.countrylist.filter(function (d) { return val.includes(d.iso); });
        }
        else
            return [];
        // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
    };
    SearchpersonalamldetailsComponent.prototype.bindselectedevidance = function (val) {
        if (val) {
            return this.bussinessdetails.evidences.filter(function (d) { return val.includes(d.evidenceId); });
        }
        else
            return [];
    };
    SearchpersonalamldetailsComponent.prototype.bindattribute = function (data) {
        this.AdverseMedia = [];
        this.PreviousSanctions = [];
        this.PEPs = [];
        this.LawEnforcement = [];
        this.CurrentSanctions = [];
        this.FinancialRegulator = [];
        this.DisqualifiedDirector = [];
        this.Insolvency = [];
        var rAdverseMedia = [];
        var rPreviousSanctions = [];
        var rPEPs = [];
        var rLawEnforcement = [];
        var rCurrentSanctions = [];
        var rFinancialRegulator = [];
        var rDisqualifiedDirector = [];
        var rInsolvency = [];
        var datasetlist = [];
        data.evidences.forEach(function (element2) {
            element2.datasets.forEach(function (element3) {
                datasetlist.push(element3);
            });
        });
        var unique = datasetlist.filter(function (item, i, ar) { return ar.indexOf(item) === i; });
        console.log(unique);
        var evidanceobj = { name: '', values: [] };
        var evidancelist = [];
        unique.forEach(function (element) {
            evidanceobj =
                {
                    name: element,
                    values: data.evidences.filter(function (d) { return d.datasets.includes(element); })
                };
            evidancelist.push(evidanceobj);
        });
        this.resultevidancelist = evidancelist;
        console.log(this.resultevidancelist);
        // data.person.articles.forEach(function(entry2) {
        //   // console.log("Second Type Development", entry2);
        //   entry2.categories.forEach(function(entry3) { 
        //     if(entry3.name =='Adverse Media')
        //     {
        //       // this.tAdverseMedia =this.tAdverseMedia +1;
        //       rAdverseMedia.push(entry2); 
        //     }else if(entry3.name =='PEP')
        //     {
        //       // this.tPEPs =this.tPEPs +1;
        //       rPEPs.push(entry2); 
        //     }else if(entry3.name =='Corporate/Business')
        //     {
        //       // this.tLawEnforcement =this.tLawEnforcement +1;
        //       rPreviousSanctions.push(entry2); 
        //     }else if(entry3.name =='Law Enforcement')
        //     {
        //       // this.tLawEnforcement =this.tLawEnforcement +1;
        //       rLawEnforcement.push(entry2); 
        //     }else if(entry3.name =='Sanction')
        //     {
        //       // this.tCurrentSanctions =this.tCurrentSanctions +1;
        //       rCurrentSanctions.push(entry2); 
        //     }else if(entry3.name =='Financial Regulator')
        //     {
        //       // this.tFinancialRegulator =this.tFinancialRegulator +1;
        //       rFinancialRegulator.push(entry2); 
        //     }else if(entry3.name =='Disqualified Director')
        //     {
        //       // this.tDisqualifiedDirector =this.tDisqualifiedDirector +1;
        //       rDisqualifiedDirector.push(entry2); 
        //     }else if(entry3.name =='Insolvent')
        //     {
        //       // this.tInsolvency =this.tInsolvency +1;
        //       rInsolvency.push(entry2); 
        //     }
        //       // console.log(entry3.name); 
        //   });
        // });
        this.AdverseMedia = rAdverseMedia;
        this.PreviousSanctions = rPreviousSanctions;
        this.PEPs = rPEPs;
        this.LawEnforcement = rLawEnforcement;
        this.CurrentSanctions = rCurrentSanctions;
        this.FinancialRegulator = rFinancialRegulator;
        this.DisqualifiedDirector = rDisqualifiedDirector;
        this.Insolvency = rInsolvency;
    };
    SearchpersonalamldetailsComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    SearchpersonalamldetailsComponent.prototype.opendocumentlink = function (id, data) {
        // console.log("data value", data);
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgvisasrc = true;
            this.iframevisasrc = data;
        }
        else {
            this.imgvisasrc = false;
            this.iframevisasrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    SearchpersonalamldetailsComponent.prototype.openModal = function (id, data) {
        //   this.visa = new Visa();
        //   this.disableBtn = true;
        //   this.visaDetail = new Visa();
        //   this.visaDetail = data;
        //  // console.log(data);
        //   this.buttoninsert = "Update";
        this.modalService.open(id);
    };
    SearchpersonalamldetailsComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    SearchpersonalamldetailsComponent.prototype.norecordfound = function () {
        var _this = this;
        this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        var dd = {};
        if (this.resourceId) {
            dd = { id: this.logid, atype: 0, resourceId: this.resourceId };
        }
        else {
            dd = { id: this.logid, atype: this.atype };
        }
        this.amldetails.generatenorecordv3Pdf(dd).subscribe(function (pdfRes) {
            console.log(pdfRes.response_data.Location);
            _this.url = pdfRes.response_data.Location;
            window.open(_this.url, "_blank");
            _this.downloadtext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
        });
    };
    return SearchpersonalamldetailsComponent;
}());
exports.SearchpersonalamldetailsComponent = SearchpersonalamldetailsComponent;
