<div class="check_verification" *ngIf="token_expired">
  <div class="wizadlineshow">
    <div class="wizard_details">
      <div class="container">
        <div class="main-banner">
          <div class="banner-form">
            <div class="row">
              <div class="col-sm-12">
                <div class="tankushow">
                  <div class="detailstext">
                      <h1 class="tokenfont">Token Expired</h1>
                      <p></p>
                  </div>
                  <img alt="shape" src="../../../../../assets/img/thanks.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<form [formGroup]="passformGroup"  #passportForm="ngForm" id="visa-form-submit" class="s12 white docvisapass" *ngIf="passportDetail && !token_expired">
  <div class="check_verification">
    <div class="wizadlineshow">
      <!-- <div class="wizard_img_box"  *ngIf="wizard != 5">
        <div class="wizard_img" [class.active]="wizard == 1 || wizard == 2 || wizard == 3 || wizard == 4 || wizard == 5">
            <img src="../../../../../assets/img/icon/1214.png">
        </div>
        <div class="wizard_img" [class.active]="wizard == 2 || wizard == 3 || wizard == 4 || wizard == 5">
            <img src="../../../../../assets/img/icon/1214.png">
        </div>
        <div class="wizard_img"  [class.active]="wizard == 3 || wizard == 4 || wizard == 5">
            <img src="../../../../../assets/img/icon/1231.png">
        </div>
        <div class="wizard_img"  [class.active]="wizard == 4 || wizard == 5">
            <img src="../../../../../assets/img/icon/1111.png" >
        </div>
      </div> -->
      <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="passportDetail.employeeid">
      <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="passportDetail.employeepassportdeatilid">

      <div id="wizard-1" *ngIf="wizard == 0" class="wizard_details">
        <div class="container">
          <div class="main-banner">
            <div class="overviewsshow check_support mt-4">
              <div class="row">
                <div class="col-sm-12">
                    <div class="header" *ngIf="fakeid">
                        <h1 class="headprofile mt-0 mb-0" *ngIf="fakeid =='ukpassport'">UK Passport</h1>
                        <h1 class="headprofile mt-0 mb-0" *ngIf="fakeid =='irish'">Irish Passport</h1>
                        <h1 class="headprofile mt-0 mb-0" *ngIf="fakeid =='irishcard'">Irish Passport Cards</h1>
                    </div>
                    <div class="header" *ngIf="!fakeid">
                      <h1 class="headprofile mt-0 mb-0" >Add Passport Details</h1>                      
                  </div>
                    <hr/>
                </div>
                <div class="col-sm-12">
                  <div class="row">
                    <div class="form-group col-lg-6">
                        <label>Passport No.*</label>
                        <input type="passportnumber" formControlName="passportnumber" maxlength="50"
                          placeholder="Please enter passport no" class="" name="passportnumber" id="passportnumber"
                          [ngClass]="{ 'is-invalid': submitted && f.passportnumber.errors }"
                          [(ngModel)]="passportDetail.passportnumber" class="form-control">
                        <div *ngIf="submitted && f.passportnumber.errors" class="invalid-feedback">
                            <div *ngIf="f.passportnumber.errors.required">Please enter passport no.</div>
                        </div>
                    </div>
                    <div class="form-group col-lg-6">
                        <label>Nationality*</label>
                        <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [(ngModel)]="passportDetail.nationalityid">
                          <option [value]="defaultVal">Select Nationality</option>
                          <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                      </select>
                        <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                            <div *ngIf="f.nationalityid.errors.required">Please select nationality</div>
                          </div>
                    </div>
                  </div>
                  <div class="row">
                      <div class="form-group col-lg-6">
                          <label>Passport Issue Date*</label>
                          <mat-form-field class="datecontrol" [ngClass]="{ 'is-invalid': submitted && f.passportissue.errors }">
                              <input matInput [matDatepicker]="picker1" name="passportissue" id="passportissue"
                                formControlName="passportissue" class="form-control" [(ngModel)]="passportDetail.passportissue">
                              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                              <mat-datepicker #picker1></mat-datepicker>
                          </mat-form-field>
                          <div *ngIf="submitted && f.passportissue.errors" class="invalid-feedback">
                              <div *ngIf="f.passportissue.errors.required">Please choose passport issue date</div>
                          </div>
                      </div>
                      <div class="form-group col-lg-6">
                          <label>Passport Expiry Date*</label>
                          <mat-form-field class="datecontrol">
                              <input matInput [matDatepicker]="picker2" class="form-control" id="passportexpiry" formControlName="passportexpiry" name="passportexpiry" [ngClass]="{ 'is-invalid': submitted && f.passportexpiry.errors }" [min]="passformGroup.controls.passportissue.value" [(ngModel)]="passportDetail.passportexpiry">
                              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                              <mat-datepicker #picker2></mat-datepicker>
                          </mat-form-field>
                          <div *ngIf="submitted && f.passportexpiry.errors" class="invalid-feedback">
                              <div *ngIf="f.passportexpiry.errors.required">Please choose passport expiry date</div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="form-group col-lg-6">
                          <label>Place of Issue of Passport*</label>
                          <input type="placeofissue" formControlName="placeofissue" id="placeofissue"
                            placeholder="Please enter place of issue" name="placeofissue" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.placeofissue.errors }"
                            [(ngModel)]="passportDetail.placeofissue" maxlength="50">
                          <div *ngIf="submitted && f.placeofissue.errors" class="invalid-feedback">
                              <div *ngIf="f.placeofissue.errors.required">Please enter place of issue</div>
                          </div>
                      </div>
                      <div class="form-group col-lg-6" *ngIf="fakeid =='ukpassport'">
                        <label >NI Number</label>                        
                        <input type="text" formControlName="ninumber" id="ninumber"
                          placeholder="Please enter NI Number" name="ninumber" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.ninumber.errors }"
                          [(ngModel)]="passportDetail.ninumber" maxlength="50">
                        <div *ngIf="submitted && f.ninumber.errors" class="invalid-feedback">
                            <div *ngIf="f.ninumber.errors.required">Please enter NI Number</div>
                        </div>
                    </div>

                    <div class="form-group col-lg-6" *ngIf="fakeid !='ukpassport'">
                      <label>PPS Number</label>                      
                      <input type="text" formControlName="ninumber" id="ninumber"
                        placeholder="Please enter PPS Number" name="ninumber" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.ninumber.errors }"
                        [(ngModel)]="passportDetail.ninumber" maxlength="50">
                      <div *ngIf="submitted && f.ninumber.errors" class="invalid-feedback">
                          <div *ngIf="f.ninumber.errors.required">Please enter PPS Number</div>
                      </div>
                  </div>
                  </div>
                  <hr/>
                  <div class="row">
                      <div class="col-lg-12 col-md-12 text-right aligncenter">
                        <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                          <div class="next">
                              <button type="button" class="btn btn-primary com_btn" (click)="toggle('1')">Next</button>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <div id="wizard-2"  *ngIf="wizard == 1"  class="wizard_details">
        <div class="container">
          <div class="main-banner">
            <div class="overviewsshow check_support mt-4">
              <div class="row">
                <div class="col-sm-12">
                    <div class="details text-center">
                      <h3 class="headprofile mt-0  w-100" *ngIf="fakeid =='ukpassport'">UK Passport Image Guidelines</h3>
                        <h3 class="headprofile mt-0  w-100" *ngIf="fakeid =='irish'">Irish Passport Image Guidelines</h3>
                        <h3 class="headprofile mt-0  w-100" *ngIf="fakeid =='irishcard'">Irish Passport Cards Image Guidelines</h3>
                        <div class="bar"></div>
                    </div>
                </div>

                <div class="col-sm-12 text-center"  *ngIf="fakeid =='ukpassport'">
                  <div class="row">
                    <div class="form-group col-lg-6">
                      <img src="../../../../../assets/img/ukpassport/cut.jpg" class="img-responsive" />
                      <h5>Show all details - including the bottom 2 line</h5>
                    </div>
                    <div class="form-group col-lg-6">
                      <img src="../../../../../assets/img/ukpassport/Blur.jpg" class="img-responsive"  />
                      <h5>All details must be clear - nothing blurry</h5>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-6">
                      <img src="../../../../../assets/img/ukpassport/light.jpg"  class="img-responsive" />
                      <h5>Move away from direct light - no glare</h5>
                    </div>
                    <div class="form-group col-lg-6">
                      <img src="../../../../../assets/img/ukpassport/Right.jpg"  class="img-responsive" />
                      <h5>The photo should clearly show your document</h5>
                    </div>
                  </div>
                </div>




                <div class="col-sm-12 text-center" *ngIf="fakeid =='irish'">
                  <div class="row">
                    <div class="form-group col-lg-6">
                      <img src="../../../../../assets/img/landpass/cut.jpg" class="img-responsive" />
                      <h5>Show all details - including the bottom 2 line</h5>
                    </div>
                    <div class="form-group col-lg-6">
                      <img src="../../../../../assets/img/landpass/Blur.jpg" class="img-responsive"  />
                      <h5>All details must be clear - nothing blurry</h5>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-6">
                      <img src="../../../../../assets/img/landpass/light.jpg"  class="img-responsive" />
                      <h5>Move away from direct light - no glare</h5>
                    </div>
                    <div class="form-group col-lg-6">
                      <img src="../../../../../assets/img/landpass/Right.jpg"  class="img-responsive" />
                      <h5>The photo should clearly show your document</h5>
                    </div>
                  </div>
                </div>


                <div class="col-sm-12 text-center" *ngIf="fakeid =='irishcard'">
                  <div class="row">
                    <div class="form-group col-lg-6">
                      <img src="../../../../../assets/img/landpasscard/cut.jpg" class="img-responsive" />
                      <h5>Show all details - including the bottom 2 line</h5>
                    </div>
                    <div class="form-group col-lg-6">
                      <img src="../../../../../assets/img/landpasscard/Blur.jpg" class="img-responsive"  />
                      <h5>All details must be clear - nothing blurry</h5>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-6">
                      <img src="../../../../../assets/img/landpasscard/light.jpg"  class="img-responsive" />
                      <h5>Move away from direct light - no glare</h5>
                    </div>
                    <div class="form-group col-lg-6">
                      <img src="../../../../../assets/img/landpasscard/Right.jpg"  class="img-responsive" />
                      <h5>The photo should clearly show your document</h5>
                    </div>
                  </div>
                </div>



              </div>
              <hr/>
              <div class="row">
                <div class="col-lg-12 col-md-12 text-right aligncenter">
                  <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                  <div class="next">
                      <button type="submit" (click)="toggle('0')" class="btn btn-dark mr-2 com_btn">Previous</button>
                      <button type="button" class="btn btn-primary com_btn" (click)="toggle('2')" >Next</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="wizard-2"  *ngIf="wizard == 2"  class="wizard_details">
        <div class="container">
          <div class="main-banner">
            <div class="overviewsshow check_support mt-4">
              <div class="row">
                <div class="col-sm-12">
                    <div class="details text-center">
                        <h3>Front Side Passport Image</h3>
                        <div class="bar"></div>
                    </div>
                </div>

                <div class="col-sm-12 text-center">
                  <webcam class="videowidth" [trigger]="triggerObservable" *ngIf="!webcamImage" (imageCapture)="handleImage($event)"
                    [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions"
                    [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)" ></webcam>

                  <div class="snapshot" *ngIf="webcamImage">
                      <img [src]="webcamImage.imageAsDataUrl"/>
                  </div>
                  <div class="camerashap_aligncenter">
                      <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshot();"  *ngIf="!webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Capture</span></button>
                      <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshotreload();"   *ngIf="webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Recapture </span></button>
                  </div>
                </div>
              </div>
              <hr/>
              <div class="row">
                <div class="col-lg-12 col-md-12 text-right aligncenter">
                  <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                  <div class="next">
                      <button type="submit" (click)="toggle('1')" class="btn btn-dark mr-2 com_btn">Previous</button>
                      <button type="button" class="btn btn-primary com_btn" (click)="toggle('3')" *ngIf="webcamImage">Next</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="wizard-2"  *ngIf="wizard == 3"  class="wizard_details">
        <div class="container">
          <div class="main-banner">
            <div class="overviewsshow check_support mt-4">
              <div class="row">
                <div class="col-sm-12">
                  <div class="details text-center">
                    <h3>Back Side Passport Image</h3>
                    <div class="bar"></div>
                  </div>
                </div>
                <div class="col-sm-12 text-center">
                  <webcam class="videowidth" [trigger]="triggerObservable" *ngIf="!webcamImage" (imageCapture)="handleImage($event)"
                    [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions"
                    [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)" ></webcam>

                    <div class="snapshot" *ngIf="webcamImage">
                      <img [src]="webcamImage.imageAsDataUrl"/>
                    </div>
                    <div class="camerashap_aligncenter">
                      <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshot();"  *ngIf="!webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Capture</span></button>
                      <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshotreload();"   *ngIf="webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Recapture </span></button>
                    </div>
                </div>
              </div>
              <hr/>
              <div class="row">
                <div class="col-lg-12 col-md-12 text-right aligncenter">
                  <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                  <div class="next">
                      <button type="submit" (click)="toggle('2')" class="btn btn-dark mr-2 com_btn">Previous</button>
                      <button type="button" (click)="toggle('4')" class="btn btn-dark mr-2 com_btn">Skip</button>
                      <button type="button" class="btn btn-primary com_btn" (click)="toggle('4')"  *ngIf="webcamImage">Next</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      
      <div id="wizard-2"  *ngIf="wizard == 4"  class="wizard_details">
        <div class="container">
          <div class="main-banner">
            <div class="overviewsshow check_support mt-4">
              <div class="row">
                <div class="col-sm-12">
                    <div class="details text-center">
                        <h3>Selfie Sample Image </h3>
                        <div class="bar"></div>
                    </div>
                </div>

                <div class="col-sm-12 text-center">
                  <div class="row">
                    <div class="form-group col-lg-12">
                     <p>Your face should fit the frame. Please make sure your face is fully visible and eyes are open. Do not wear glasses, hat or anything that could obscure your face. Please look straight into the camera and press the capture button.</p>
                    </div>                    
                  </div>
                  <div class="clearfix">&nbsp;</div>
                  <div class="row">
                    <div class="form-group col-lg-12">
                      <img src="../../../../../assets/img/self.jpg"  class="img-responsive" />                      
                    </div>                   
                  </div>
                </div>



              </div>
              <hr/>
              <div class="row">
                <div class="col-lg-12 col-md-12 text-right aligncenter">
                  <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                  <div class="next">
                      <button type="submit" (click)="toggle('3')" class="btn btn-dark mr-2 com_btn">Previous</button>
                      <button type="button" class="btn btn-primary com_btn" (click)="toggle('5')" >Next</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="wizard-23"  *ngIf="wizard == 5"  class="wizard_details">
        <div class="container">
          <div class="main-banner">
            <div class="overviewsshow check_support mt-4">
              <div class="row">
                <div class="col-sm-12">
                  <div class="details text-center">
                    <h3>Upload Selfie</h3>
                    <div class="bar"></div>
                  </div>
                </div>

                <div class="col-sm-12 text-center">
                  <webcam class="videowidth" [trigger]="triggerObservable" *ngIf="!webcamImage" (imageCapture)="handleImage($event)"
                    [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions"
                    [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)" ></webcam>

                  <div class="snapshot" *ngIf="webcamImage">
                    <img [src]="webcamImage.imageAsDataUrl"/>
                  </div>

                  <div class="camerashap_aligncenter">
                    <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshot();"  *ngIf="!webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Capture</span></button>
                    <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshotreload();"   *ngIf="webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Recapture </span></button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="imgcent col-lg-12 col-md-12" *ngIf="profile_pic_url">
                    <img class="width100" [src]="profile_pic_url"  >
                </div>
              </div>
              <hr/>
              <div class="row">
                <div class="col-lg-12 col-md-12 text-right aligncenter">
                  <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                  <div class="next">
                      <button type="submit" (click)="toggle('4')" class="btn btn-dark mr-2 com_btn">Previous</button>
                      <button type="button" class="btn btn-primary com_btn" (click)="toggle('6')" *ngIf="webcamImage">Next</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="wizard-23"  *ngIf="wizard == 6"  class="wizard_details">
        <div class="container">
          <div class="main-banner">
            <div class="overviewsshow check_support mt-2">
              <div class="row">
                <div class="col-sm-12">
                  <div class="header">
                      <h1 class="headprofile mt-0 mb-0">Passport Upload Successful</h1>
                  </div>
                  <hr/>
                </div>
                <div class="col-sm-12">
                  <div class="uploaded_img" *ngIf="!showloader">
                    <div class="img_sec">
                        <img [src]="frontimage"/>
                        <i class="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <div class="img_sec" *ngIf="backimage">
                        <img [src]="backimage"/>
                        <i class="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <div class="img_sec">
                        <img [src]="profileimage"/>
                        <i class="fa fa-check" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div *ngIf="showloader">
                    <div class="browser-screen-loading-content" >
                      <div class="loading-dots dark-gray">
                          <i></i>
                          <i></i>
                          <i></i>
                          <i></i>
                          <i></i>
                          <i></i>
                          <i></i>
                      </div>
                      <p>Loading</p>
                    </div>
                  </div>
                </div>
              </div>

              <hr/>
              <div class="row">
                  <div class="col-lg-12 col-md-12 text-right aligncenter">
                      <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                      <div class="next">
                          <button type="submit" (click)="toggle('5')" class="btn btn-dark mr-2 mt-0 com_btn">Previous</button>
                          <button type="button" class="btn btn-primary com_btn mt-0"  (click)="onSubmitPassport(passportForm.value)" >Next</button>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="wizard-4"  *ngIf="wizard == 7" class="wizard_details">
          <div class="container">
              <div class="main-banner">
                  <div class="overviewsshow check_support mt-4">
                      <div class="row">
                          <div class="col-sm-12">
                              <div class="tankushow">
                                  <div class="detailstext">
                                      <h1>Passport Upload Successful</h1>
                                      <p>You do not need to take any further action </p>
                                  </div>
                                  <img alt="shape" src="../../../../../assets/img/thanks.png">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div id="wizard-5"  *ngIf="wizard == 8" class="wizard_details">
        <div class="container">
            <div class="main-banner">
                <div class="overviewsshow check_support mt-4">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="browser-screen-loading-content" >
                                <div class="loading-dots dark-gray">
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                </div>
                                <p>Loading</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</form>



