<div class="overviewsshow">
    <div class="header">
        <h1 class="headprofile mt-0">Contact</h1>
        <div>
            <div  *ngIf="contactDetail.id=='0'">
                <a (click)="openModal2('custom-contact-form-1')" class="addscreensuccess">Add Contact</a>
            </div>
            <div *ngIf="contactDetail.id != '0'">
                <a (click)="openModal('custom-contact-form-1', contactDetail)" class="addscreensuccess">Edit Details</a>
            </div>
        </div>
    </div>
    <div *ngIf="contactDetail.id!='0'">
        <div class="header">
            <h4>Employee Contact</h4>
        </div>
        <div class="overview_details emp">
            <div class="profile_dtailsshow">
                <div class="detailsshow width20">
                    <span>Work Phone</span>
                    <p> {{contactDetail.workphone}}</p>
                </div>
                <div class="detailsshow width20">
                    <span>Work Email</span>
                    <p> {{contactDetail.workemail}}</p>
                </div>
                <div class="detailsshow width20" *ngIf="contactDetail.homephone != '' && contactDetail.homephone != null">
                    <span>Home Phone</span>
                    <p> {{contactDetail.homephone}}</p>
                </div>
                <div class="detailsshow width20" *ngIf="contactDetail.personalemail != '' && contactDetail.personalemail != null">
                    <span>Personal Email</span>
                    <p>{{contactDetail.personalemail}}</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="contactDetail.id!='0'">
        <div class="header">
            <h4>Next of Kin details</h4>
        </div>
        <div class="overview_details emp">
            <div class="profile_dtailsshow">
                <div class="detailsshow width20" *ngIf="(contactDetail.kinname != '') && (contactDetail.kinname != null)">
                    <span>Kin Name</span>
                    <p> {{contactDetail.kinname}}</p>
                </div>
                <div class="detailsshow width20" *ngIf="(contactDetail.employeerelation != '') && (contactDetail.employeerelation != null)">
                    <span>Relation</span>
                    <p> {{contactDetail.employeerelation}}</p>
                </div>
                <div class="detailsshow width20" *ngIf="(contactDetail.kinemail != '') && (contactDetail.kinemail != null)">
                    <span>Kin Email</span>
                    <p> {{contactDetail.kinemail}}</p>
                </div>
                <div class="detailsshow width20" *ngIf="(contactDetail.kinmobile != '') && (contactDetail.kinmobile != null)">
                    <span>Kin Mobile</span>
                    <p>{{contactDetail.kinmobile}}</p>
                </div>
                <div class="detailsshow width20" *ngIf="(contactDetail.telephone != '') && (contactDetail.telephone != null)">
                    <span>Kin Telephone</span>
                    <p>{{contactDetail.telephone}}</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="contactDetail.id!='0'">
        <div class="header">
            <h4>Address</h4>
        </div>
        <div class="overview_details emp">
            <div class="profile_dtailsshow">
                <div class="detailsshow width100" *ngIf="(contactDetail.kinname != '') && (contactDetail.kinname != null)">
                    <p> <i class="fa fa-map-marker"></i> <span *ngIf="(contactDetail.kinaddress1 != '') && (contactDetail.kinaddress1 != null)">{{contactDetail.kinaddress1}}</span>
                        <span *ngIf="(contactDetail.kinaddress2 != '') && (contactDetail.kinaddress2 != null)">{{ contactDetail.kinaddress2}}</span>
                        <span *ngIf="(contactDetail.kincityname != '') && (contactDetail.kincityname != null)">{{contactDetail.kincityname}}, <span *ngIf="(contactDetail.kincountyname != '') && (contactDetail.kincountyname != null)">{{contactDetail.kincountyname}}</span></span>
                        <span *ngIf="(contactDetail.countryname != '') && (contactDetail.countryname != null)">{{contactDetail.countryname?.countryname}} - <span *ngIf="(contactDetail.kinpincode != '') && (contactDetail.kinpincode != null)">{{contactDetail.kinpincode}}</span></span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="job_list_section visa_request_section" *ngIf="contactDetail.id == '0'">
        <div class="job_detils_date">
            <p class="job_profile">No Record Found</p>
        </div>
    </div>  
</div>
<!-- <div class="row">
    <div class="col-lg-7">
        <h1 class="headprofile">Contact</h1>
    </div>
    <div class="col-lg-5" *ngIf="contactDetail.id=='0'">
        <h6 class="text-right float-right">
            <a (click)="openModal2('custom-contact-form-1')" class="addscreensuccess">+ Add Contact</a></h6>
    </div>
    <div class="col-lg-5" *ngIf="contactDetail.id != '0'">
        <h6 class="text-right float-right buttonprofile">
            <a (click)="openModal('custom-contact-form-1', contactDetail)" class="addscreensuccess">+ Edit Details</a></h6>
    </div>
</div> -->
<!-- 
<div class="row row-sm ml-0 mr-0 mt-4">
  <div class="col-lg-12" *ngIf="contactDetail.id=='0'">
    <h6 class="text-right float-right">
      <a (click)="openModal2('custom-contact-form-1')" class="addscreensuccess">+ Add New</a></h6>
  </div>
  <div class="col-lg-12" *ngIf="contactDetail.id != '0'">
    <h6 class="text-right float-right">
      <a (click)="openModal('custom-contact-form-1', contactDetail)" class="addscreensuccess">+ Edit Details</a></h6>
  </div>
</div> -->
<!-- <div class="row row-sm ">
    <div class="col-lg-4" *ngIf="contactDetail.id == '0'">
        <div class="billed-to">
            No Record Found
        </div>
    </div>
    <div class="col-lg-4 mb-4" *ngIf="contactDetail.id!='0'">
        <div class="billed-to contactinfo">
            <h6 class="tx-gray-800">Work Phone: {{contactDetail.workphone}}</h6>
            <p *ngIf="contactDetail.homephone != '' && contactDetail.homephone != null"><b>Home Phone: </b>{{contactDetail.homephone}}</p>
            <p><b>Work Email: </b>{{contactDetail.workemail}}</p>
            <p *ngIf="contactDetail.personalemail != '' && contactDetail.personalemail != null"><b>Personal Email: </b>{{contactDetail.personalemail}}</p>
        </div>
    </div>
    <div class="col-lg-4 mb-4" *ngIf="contactDetail.id!='0'">
        <div class="billed-to contactinfo">
            <h5>Next of Kin details</h5>
            <h6 class="tx-gray-800" *ngIf="(contactDetail.kinname != '') && (contactDetail.kinname != null)">Kin Name: {{contactDetail.kinname}}</h6>
            <p *ngIf="(contactDetail.employeerelation != '') && (contactDetail.employeerelation != null)"><b>Relation: </b>{{contactDetail.employeerelation}}</p>
            <p *ngIf="(contactDetail.kinemail != '') && (contactDetail.kinemail != null)"><b>Kin Email: </b>{{contactDetail.kinemail}}</p>
            <p *ngIf="(contactDetail.kinmobile != '') && (contactDetail.kinmobile != null)"><b>Kin Mobile: </b>{{contactDetail.kinmobile}}</p>
            <p *ngIf="(contactDetail.telephone != '') && (contactDetail.telephone != null)"><b>Kin Telephone: </b>{{contactDetail.telephone}}</p>
        </div>
    </div>
    <div class="col-lg-4 mb-4" *ngIf="contactDetail.id!='0'">
        <div class="billed-to contactinfo">
            <h5>Address:</h5>
            <div class="addressdetails">
                <p *ngIf="(contactDetail.kinaddress1 != '') && (contactDetail.kinaddress1 != null)">{{contactDetail.kinaddress1}}</p>
                <p *ngIf="(contactDetail.kinaddress2 != '') && (contactDetail.kinaddress2 != null)">{{ contactDetail.kinaddress2}}</p>
                <p *ngIf="(contactDetail.kincityname != '') && (contactDetail.kincityname != null)">{{contactDetail.kincityname}}, <label *ngIf="(contactDetail.kincountyname != '') && (contactDetail.kincountyname != null)">{{contactDetail.kincountyname}}</label></p>
                <p *ngIf="(contactDetail.countryname != '') && (contactDetail.countryname != null)">{{contactDetail.countryname?.countryname}} - <label *ngIf="(contactDetail.kinpincode != '') && (contactDetail.kinpincode != null)">{{contactDetail.kinpincode}}</label></p>
            </div>
        </div>
    </div>
</div> -->
<jw-modal id="custom-contact-form-1" class="modal" role="dialog">
    <form [formGroup]="contactformGroup" #contactForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(contactForm.value,'custom-contact-form-1')" *ngIf="contactDetail">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="contactDetail.id">
                    <input type="hidden" formControlName="employeeid" class="form-control" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="contactDetail.employeeid">
                    <h5 class="modal-title" id="popupLabel">Contact Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-contact-form-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <!-- <h3>Employee Contact</h3> -->
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Work Phone*</label>
                                <international-phone-number class="form-control phonecontrol" formControlName="workphone" [ngClass]="{ 'is-invalid': submitted && f.workphone.errors }" [(ngModel)]="contactDetail.workphone" placeholder="Enter phone number" (input)="validatenumber($event.target.value,'workphone')"
                                    [maxlength]="20" #workphone name="workphone"></international-phone-number>
                                <div *ngIf="submitted && f.workphone.errors" class="invalid-feedback">
                                    <div *ngIf="f.workphone.errors.required">Please enter phone number</div>
                                </div>
                                <span *ngIf="workphonecheck==false" style="color:red">Invalid format</span>
                                <span *ngIf="workphonecheck==true" style="color:green">Valid format</span>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Home Phone</label>
                                <international-phone-number class="form-control phonecontrol" formControlName="homephone" [ngClass]="{ 'is-invalid': submitted && f.homephone.errors }" [(ngModel)]="contactDetail.homephone" placeholder="Enter phone number" (input)="validatenumber($event.target.value,'homephone')"
                                    [maxlength]="20" #homephone name="homephone"></international-phone-number>
                                <div *ngIf="submitted && f.homephone.errors" class="invalid-feedback">
                                    <div *ngIf="f.homephone.errors.required">Please enter phone number</div>
                                </div>
                                <span *ngIf="homephonecheck==false" style="color:red">Invalid format</span>
                                <span *ngIf="homephonecheck==true" style="color:green">Valid format</span>
                            </div>

                            <!-- <div class="form-group col-lg-6">
                <label>Mobile *</label>
                <international-phone-number class="form-control phonecontrol" formControlName="mobile"
                  [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" [(ngModel)]="contactDetail.mobile"
                  placeholder="Enter phone number" (input)="validatenumber($event.target.value,'mobile')"
                  [maxlength]="20" #mobile name="mobile"></international-phone-number>
                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                  <div *ngIf="f.mobile.errors.required">Please Enter Phone Number</div>
                </div>
                <span *ngIf="mobilecheck==false" style="color:red">Invalid format</span>
                <span *ngIf="mobilecheck==true" style="color:green">Valid format</span>
              </div> -->
                        </div>
                        <div class="row">

                            <!-- <div class="form-group col-lg-6">
                <label>Home Phone</label>
                <international-phone-number class="form-control phonecontrol" formControlName="homephone"
                  [ngClass]="{ 'is-invalid': submitted && f.homephone.errors }" [(ngModel)]="contactDetail.homephone"
                  placeholder="Enter phone number" (input)="validatenumber($event.target.value,'homephone')"
                  [maxlength]="20" #homephone name="homephone"></international-phone-number>
                <div *ngIf="submitted && f.homephone.errors" class="invalid-feedback">
                  <div *ngIf="f.homephone.errors.required">Please Enter Phone Number</div>
                </div>
                <span *ngIf="homephonecheck==false" style="color:red">Invalid format</span>
                <span *ngIf="homephonecheck==true" style="color:green">Valid format</span>
              </div> -->

                            <div class="form-group col-lg-6">
                                <label>Work Email*</label>
                                <input type="text" formControlName="workemail" class="" name="workemail" id="workemail" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.workemail.errors }" [(ngModel)]="contactDetail.workemail">
                                <div *ngIf="submitted && f.workemail.errors" class="invalid-feedback">
                                    <div *ngIf="f.workemail.errors.required">Please enter work email</div>
                                    <div *ngIf="f.workemail.errors.email">Please enter valid work email</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Personal Email </label>
                                <input type="text" formControlName="personalemail" class="" name="personalemail" id="personalemail" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.personalemail.errors }" [(ngModel)]="contactDetail.personalemail">
                                <div *ngIf="submitted && f.personalemail.errors" class="invalid-feedback">
                                    <div *ngIf="f.personalemail.errors.email">Please enter valid personal email</div>
                                </div>
                            </div>
                        </div>
                        <h5 style="border-bottom: 1px solid;padding-bottom: 5px; margin-bottom: 10px;">Next of Kin details</h5>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Name</label>
                                <input type="text" formControlName="kinname" class="" name="kinname" id="kinname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.kinname.errors }" [(ngModel)]="contactDetail.kinname">
                                <div *ngIf="submitted && f.kinname.errors" class="invalid-feedback">
                                    <div *ngIf="f.kinname.errors.required">Please enter the kin name</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Relation</label>
                                <input type="text" formControlName="employeerelation" class="" name="employeerelation" id="employeerelation" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.employeerelation.errors }" [(ngModel)]="contactDetail.employeerelation">
                                <div *ngIf="submitted && f.employeerelation.errors" class="invalid-feedback">
                                    <div *ngIf="f.employeerelation.errors.required">Please enter the relation </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Email</label>
                                <input type="text" formControlName="kinemail" class="" name="kinemail" id="kinemail" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.kinemail.errors }" [(ngModel)]="contactDetail.kinemail">
                                <div *ngIf="submitted && f.kinemail.errors" class="invalid-feedback">
                                    <div *ngIf="f.kinemail.errors.email">Please enter valid email</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Mobile</label>
                                <international-phone-number class="form-control phonecontrol" formControlName="kinmobile" [ngClass]="{ 'is-invalid': submitted && f.kinmobile.errors }" [(ngModel)]="contactDetail.kinmobile" placeholder="Enter Kin Mobile" (input)="validatenumber($event.target.value,'kinmobile')"
                                    [maxlength]="20" #kinmobile name="kinmobile"></international-phone-number>
                                <div *ngIf="submitted && f.kinmobile.errors" class="invalid-feedback">
                                    <div *ngIf="f.kinmobile.errors.required">Please enter phone number</div>
                                </div>
                                <span *ngIf="kinmobilecheck==false" style="color:red">Invalid format</span>
                                <span *ngIf="kinmobilecheck==true" style="color:green">Valid format</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Postcode</label>
                                <input type="text" formControlName="kinpincode" id="kinpincode" name="kinpincode" class="form-control kinpincode" [ngClass]="{ 'is-invalid': submitted && f.kinpincode.errors }" [(ngModel)]="contactDetail.kinpincode" (input)="onSearchChange($event.target.value)"
                                    matInput [matAutocomplete]="auto" style=" border: 1px solid #d1d1d1;">
                                <div *ngIf="submitted && f.kinpincode.errors" class="invalid-feedback">
                                    <div *ngIf="f.kinpincode.errors.required">Please enter postcode</div>
                                </div>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="pincode">
                                    <mat-option *ngFor="let option of options" [value]="option" (onSelectionChange)="updatecode($event, option)">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Telephone</label>
                                <international-phone-number class="form-control phonecontrol" formControlName="telephone" [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }" [(ngModel)]="contactDetail.telephone" placeholder="Enter phone number" (input)="validatenumber($event.target.value,'telephone')"
                                    [maxlength]="20" #telephone name="telephone"></international-phone-number>
                                <div *ngIf="submitted && f.telephone.errors" class="invalid-feedback">
                                    <div *ngIf="f.telephone.errors.required">Please enter phone number</div>
                                </div>
                                <span *ngIf="telephonecheck==false" style="color:red">Invalid format</span>
                                <span *ngIf="telephonecheck==true" style="color:green">Valid format</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Address Line 1 *</label>
                                <input type="text" formControlName="kinaddress1" class="" name="kinaddress1" id="kinaddress1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.kinaddress1.errors }" [(ngModel)]="contactDetail.kinaddress1">
                                <div *ngIf="submitted && f.kinaddress1.errors" class="invalid-feedback">
                                    <div *ngIf="f.kinaddress1.errors.required">Please enter address </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Address Line 2</label>
                                <input type="text" formControlName="kinaddress2" class="" name="kinaddress2" id="kinaddress2" class="form-control" [(ngModel)]="contactDetail.kinaddress2">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>City</label>
                                <input type="text" formControlName="kincityname" class="" name="kincityname" id="kincityname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.kincityname.errors }" [(ngModel)]="contactDetail.kincityname">
                                <div *ngIf="submitted && f.kincityname.errors" class="invalid-feedback">
                                    <div *ngIf="f.kincityname.errors.required">Please enter city </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>County</label>
                                <input type="text" formControlName="kincountyname" class="" name="kincountyname" id="kincountyname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.kincountyname.errors }" [(ngModel)]="contactDetail.kincountyname">
                                <div *ngIf="submitted && f.kincountyname.errors" class="invalid-feedback">
                                    <div *ngIf="f.kincountyname.errors.required">Please enter county </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Country</label>
                                <select style="width:100%;" formControlName="kincountryid" name="kincountryid" class="form-control input-lg" id="kincountryid" [ngClass]="{ 'is-invalid': submitted && f.kincountryid.errors }" [(ngModel)]="contactDetail.kincountryid">
                                    <option [value]="defaultVal">Select Country</option>
                                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && f.kincountryid.errors" class="invalid-feedback">
                                    <div *ngIf="f.kincountryid.errors.required">Please select country</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-primary" name="updateform" value="Cancel" (click)="closeModal('custom-contact-form-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>