export class status {
  statusname: string;
}

export class candidatenotes {
 employeeid: number;
 candidateid: number;
 notes: string;
 sharecode: string;
 Checkconductedby:string;
 Checkconductedbyrole:string;
 Checkconductedbysign:string;
 companyname:string;
 dateofbirth:string;
 ninumber:string;
}

export class Candidate {
    candidatemasterid: number;
    fname: string;
    mname: string;
    lname: string;
    emailid: string;
    phonenumber: string;
    joburlid: string;
    cvname: string;
    jobid: number;
    message: string;
    subject: string;
    location: string;
    endtime: string;
    starttime: string;
    intdate: Date;
    document: Document;
    smarks: string;
    isinterested: number;
    status:status;
  }

  export class Document {
    documentimageurl: string;
    candidatedocumentdetailsid: string;
    candidateid: string;
  }