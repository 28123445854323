import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { DepartmentService } from '../../../services/department.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Department } from '../../../models/setting/department.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';


@Component({
  selector: 'app-settingdepartment',
  templateUrl: './settingdepartment.component.html',
  styleUrls: ['./settingdepartment.component.css']
})
export class SettingdepartmentComponent implements OnInit {

  Userid: any;
  data: any;
  departmentDetail = new Department();
  departmentformGroup: FormGroup;
  options = [];
  buttoninsert: any;
  submitted: boolean = false;
  disableBtn: any;
  public searchText: string;
  permission: any;
  settingperm: any;
  record:number;
  
  items: Array<any>;
  
  pageOfItems: Array<any>;
  constructor(
    private modalService: ModalService,
    private departmentService: DepartmentService,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.settingperm = this.permission.m81;

    this.Userid = localStorage.getItem('userid');
    this.departmentDetail = new Department();
    this.departmentformGroup = this.formBuilder.group({
      id: [''],
      typename: ['', Validators.required]
    });

    this.AllData();
    

  }

  AllData(){
    const sstatustype = { 'status': false, 'id': this.Userid } 
    this.departmentService.getdepartmentlistbyadmin(sstatustype).subscribe((data: any) => {
      this.data = data;
      this.record = this.data.length;

      if(this.record < 30){
        this.pageOfItems = this.data;
      }
      else{
           this.items =this.data;
      }
    });
  }

  get f() { return this.departmentformGroup.controls; }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  
  onSubmit(formdepartment: any, id: string) {
    // var formdepartment = departmentdata.value;
    this.submitted = true;
    if (this.departmentformGroup.invalid) {
      return;
    }
    this.disableBtn = false;
    formdepartment.userid = this.Userid;
    this.departmentService.settingdepartmentUpdate(formdepartment).subscribe((response: any) => {
      this.AllData();
        this.modalService.close(id);
     
    });

  }

  onChangeStatus(evt: any, dep: any) {
    var cstatus;
    if (evt.target.checked) {
      cstatus = '1';
    }
    else if (!evt.target.checked) {
      cstatus = '0';
    }
    this.departmentService.departmentUpdateChangeStatus({ 'userid': this.Userid, 'id': dep.id, 'cstatus': evt.target.checked }).subscribe((data: any) => {
      this.AllData();
    });
  }

  openModal(id: string, data: any) {
    this.buttoninsert = "Update";
    this.disableBtn = true;
    this.departmentDetail = data;
    this.departmentformGroup.reset(this.departmentDetail);
    this.modalService.open(id);
  }

  openModal2(id: string) {
    this.submitted = false;
    this.buttoninsert = "Submit";
    this.disableBtn = true;
    this.departmentDetail = new Department();
    this.departmentDetail.id = "0";
    this.departmentformGroup.reset(this.departmentDetail);
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

}
