<app-header></app-header>
<div class="section-wrapper">
  <div class="container">
      <div class="tabs" id="tabs">
          <div class="tabs__sontent">
            <app-invioce-shared></app-invioce-shared>            
              <div class="clearfix"></div>
              <div class="overviewsshow mt-4">
                <div class=" upgraderequest" *ngIf="ctype == 3"  >To access Complygate Compliance Centre, please upgrade your account. <button   class="btn btn-primary com_btn small_btn ng-star-inserted float-right"  [routerLink]="['/upgrade-your-membership']">Upgrade Your Account</button></div>
                <div class=" upgraderequest" *ngIf="ctype == 2"  >To access Complygate Compliance Centre, please upgrade your account. <button   class="btn btn-primary com_btn small_btn ng-star-inserted float-right"  [routerLink]="['/upgrade-your-membership']">Upgrade Your Account</button></div>              

               
              
                <div class="header">
                  <div class="container">
                      <h1 class="headprofile mt-0 mb-2" *ngIf="wizard3 != true">Buy Checks</h1>
                  </div>
              
                  <div class="cart_box_btn cartbillingoption startend"  *ngIf="serviceArray.length > 0">
                    <button type="submit" class="btn btn-success checoutbutton" (click)="viewcart()"  *ngIf="!isviewcart"><span _ngcontent-c5="">Checkout</span> <span class="lnr lnr-arrow-right"></span> <p>{{ serviceArray.length }}</p> </button>                    
                  </div>
                  
              
              
              </div>
              
              
              
              
              <div class="wizadlineshow mt-1">
                <div id="wizard-3" [class.displayblock]="wizard3"  class="wizard_details" *ngIf="wizard3">
                      <div class="container">
                          <div class="main-banner">
                              <div class="banner-form thankbgsss">
                                  <div class="row">
                                      <div class="col-sm-12">
                                          <div class="tankushow">
              
                                              <img alt="shape" src="../../../../../assets/img/thankcheck.png">
                                              <div class="detailstext">
                                                  <h4>Payment Successful!</h4>
                                                  <p>Your order has been successfully processed. Please return to the checks you have purchased and start using them instantly.</p>
                                                      <h1>Thank You!</h1>
              
              
                                                      <a [routerLink]="['/check-add-credit']"   class="option_show">
                                                          <span>Buy More Checks</span>
                                                          <span class="lnr lnr-arrow-right ml-2"></span>
                                                      </a>
              
                                                      <!-- <button type="submit" class="btn btn-danger"  *ngIf="wizard3 == true"></button> -->
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              
                  <div id="wizard-4" [class.displayblock]="wizard4"  class="wizard_details" *ngIf="wizard4">
                      <div class="container">
                          <div class="main-banner">
                              <div class="banner-form thankbgsss">
                                  <div class="row">
                                      <div class="col-sm-12">
                                          <div class="tankushow">
              
                                              <img alt="shape" src="../../../../../assets/img/thankfail.png">
                                              <div class="detailstext">
                                                  <h4>Payment Failed!</h4>
                                                  <p>Your order has been fail processed. Please return to the checks you have purchased and start using them instantly.</p>
                                                      <h1>Thank You!</h1>
              
              
                                                      <a [routerLink]="['/check-add-credit']"   class="option_show">
                                                          <span>Buy More Checks</span>
                                                          <span class="lnr lnr-arrow-right ml-2"></span>
                                                      </a>
              
                                                      <!-- <button type="submit" class="btn btn-danger"  *ngIf="wizard3 == true"></button> -->
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              
                 
              
              
              




                    
              </div>
         
          </div>
      </div>
  </div>
</div>
