<div class="bg">
    <app-header></app-header>
<div class="section-wrapper p-0">
  <div class="admin_dashboard_show">
    <div class="ceee">
      <div class="cardmenu">
        
          <a class="card_box_lit" [routerLink]="['/overview']">
            <div class="samebox">
              <i class="lnr lnr-user"></i>
              <div class="menu_content">
                  <h4>My Profile</h4>
                  <!-- <span>lorem ipsum lorem ipsum</span> -->
              </div>
            </div>
              
          </a>
       
        
          <a  class="card_box_lit" [routerLink]="['/my-leave']">
            <div class="samebox">
              <i class="lnr lnr-pencil"></i>
              <div class="menu_content">
                  <h4>My Leave</h4>
                  <!-- <span>lorem ipsum lorem ipsum</span> -->
              </div>
            </div>
              
              
          </a>
        
        
          <a class="card_box_lit" [routerLink]="['/my-expense']">
            <div class="samebox">
              <i class="lnr lnr-gift"></i>
              <div class="menu_content">
                  <h4>My Expense</h4>
                  <!-- <span>lorem ipsum lorem ipsum</span> -->
              </div>
            </div>
              
              
          </a>
      
        
          <a class="card_box_lit" [routerLink]="['/my-worklist']">
            <div class="samebox">
              <i class="lnr lnr-calendar-full"></i>
              <div class="menu_content">
                  <h4>My Timesheet</h4>
                  <!-- <span>lorem ipsum lorem ipsum</span> -->
              </div>
            </div>
              
              
          </a>
        
       
          <a class="card_box_lit" [routerLink]="['/my-asset']">
            <div class="samebox">
              <i class="lnr lnr-frame-expand"></i>
              <div class="menu_content">
                  <h4>My Asset</h4>
                  <!-- <span>lorem ipsum lorem ipsum</span> -->
              </div>
            </div>
             
              
          </a>
          <a class="card_box_lit" [routerLink]="['/my-rota']">
            <div class="samebox">
                <i class="lnr lnr-laptop-phone"></i>
                <div class="menu_content">
                    <h4>Rota</h4>
                
                </div>
            </div>
            
          </a>
    </div>
    </div>
  </div>
</div>

  <!-- <div class="dashboard-wrapper">
      <a  [routerLink]="['/overview']" class="homeset">
    <div class="dashboard-box">
      <h2>My Profile</h2>
      <p class="dash-circle"><i class="fa fa-user" ></i></p>  
      <p class="dash-btm">          
        <i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i>
      </p>
     </div>
    </a>

     <a  [routerLink]="['/my-leave']"  class="homeset">
     <div class="dashboard-box">
      <h2 >My Leave</h2>
        <p class="dash-circle" style="color:#fff;">
          <i class="fa fa-pencil" ></i>
        </p>
        <p class="dash-btm "><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
      
      </div></a> 

      <a  [routerLink]="['/expense']"  class="homeset">
       <div class="dashboard-box">
          <h2>My Expense</h2>
          <p class="dash-circle"><i class="fa fa-gbp"></i></p>
          <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
         </div>
        </a>
        <a  [routerLink]="['/my-worklist']"  class="homeset">
         <div class="dashboard-box">
            <h2>My Timesheet</h2>
            <p class="dash-circle"><i class="fa fa-list-ul" ></i></p>
            <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
           </div>
          </a>
          <a  [routerLink]="['/my-asset']"  class="homeset">
            <div class="dashboard-box">
               <h2>My Asset</h2>
               <p class="dash-circle"><i class="fa fa-briefcase" ></i></p>
               <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
              </div>
             </a>
    </div> -->
  </div>