"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var plan_service_1 = require("../../../services/plan.service");
var payment_service_1 = require("../../../services/payment.service");
var plan_model_1 = require("../../../models/plan.model");
var modal_service_1 = require("../../../_services/modal.service");
var router_1 = require("@angular/router");
var stripe_angular_1 = require("stripe-angular");
var alert_service_1 = require("../../../services/alert.service");
var user_service_1 = require("../../../services/user.service");
var company_service_1 = require("../../../services/company.service");
var app_global_1 = require("../../../app.global");
var ChangeplanComponent = /** @class */ (function () {
    //  private publishableKey: string = "pk_live_vE86NSZoPYH0MsJIdsYfQ8L6";
    //private publishableKey: string = "pk_test_dIWR2H2xFoFj3I8vXg8eS4dw";
    function ChangeplanComponent(formBuilder, modalService, router, alerts, plan, userService, companyService, StripeScriptTag, activatedRoute, paymentService) {
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.router = router;
        this.alerts = alerts;
        this.plan = plan;
        this.userService = userService;
        this.companyService = companyService;
        this.StripeScriptTag = StripeScriptTag;
        this.activatedRoute = activatedRoute;
        this.paymentService = paymentService;
        this.planDetail = new plan_model_1.Plan();
        this.publishableKey = app_global_1.AppGlobals.API_STRIPE;
    }
    ChangeplanComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        if (this.activatedRoute.snapshot.paramMap.get("id")) {
            console.log('master user system Yes', this.plantype);
            this.plantype = parseInt(this.activatedRoute.snapshot.paramMap.get("id"));
        }
        else {
            this.plantype = 0;
            console.log('master user system No', this.plantype);
        }
        this.ctype = parseInt(localStorage.getItem('ctype'));
        // if(this.ctype ==1){
        //   this.router.navigate(['/check-dashboard']);
        // }
        this.StripeScriptTag.setPublishableKey(this.publishableKey);
        this.planformGroup = this.formBuilder.group({
            coupnsnumber: ['', forms_1.Validators.required]
        });
        var isemail = localStorage.getItem('isemail');
        this.planid = localStorage.getItem('planid');
        console.log('Plan id on it', this.planid);
        this.companyService.getcompanyData().subscribe(function (data) {
            _this.currentprice = data.plan.prices;
            _this.companyData = data;
            // console.log(this.companyData);
        });
        this.companyService.gettotalcards().subscribe(function (data) {
            //   // console.log(data);
            _this.cardlist = data;
        });
        var type;
        if (this.ctype == 0) {
            type = { "plantype": '0', 'checktype': '0' };
        }
        else {
            type = { "plantype": '0', 'checktype': '1' };
        }
        //  else if(this.ctype == 2)
        //  {
        //     type = {"plantype": '0','checktype': '1'};
        //  }  
        this.plan.getplanlist(type).subscribe(function (data) {
            // console.log(data);
            _this.planlist = data;
        });
    };
    Object.defineProperty(ChangeplanComponent.prototype, "f", {
        get: function () { return this.planformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    ChangeplanComponent.prototype.onSubmit = function (settingexpensetypedata, id) {
        var _this = this;
        this.submitted = true;
        if (this.planformGroup.invalid) {
            return;
        }
        this.plan.checkcounpons(settingexpensetypedata).subscribe(function (data) {
            if (data.lenght != 0) {
                _this.totaluser = data.totaluser;
                _this.totalused = data.totalused;
                if ((_this.totaluser - _this.totalused) != 0) {
                    _this.message = "Coupons Applied Successfully";
                    var db = { "couponsnumber": settingexpensetypedata.coupnsnumber };
                    _this.plan.getplanlistwithcoupons(db).subscribe(function (data4) {
                        _this.planlist = data4;
                        _this.modalService.close(id);
                    });
                }
                else {
                    _this.message = "Coupons limit expire";
                }
            }
        });
    };
    ChangeplanComponent.prototype.onStripeInvalid = function (error) {
        // console.log('Validation Error', error)
    };
    ChangeplanComponent.prototype.buySubscription = function (planid, id, type) {
        var _this = this;
        var msg = '';
        if (type == 1)
            msg = "Are you sure you want to downgrade your plan?";
        else
            msg = "Are you sure you want to upgrade your plan?";
        if (confirm("Are you sure you want to upgrade your plan?")) {
            this.planid = planid;
            var dd = { "customer": this.companyData.stuser, "planid": this.planid, 'plantype': this.plantype };
            this.paymentService.createSubscription(dd).subscribe(function (data) {
                if (data.status == "success") {
                    _this.companyService.getcompanyData().subscribe(function (data) {
                        _this.currentprice = data.plan.prices;
                        _this.companyData = data;
                        _this.alerts.success("Plan Successfully Upgraded.", true);
                        localStorage.setItem('planid', id);
                        localStorage.setItem('isemail', 'true');
                        _this.router.navigate(['/successfully-payment', 1]);
                    });
                }
                else {
                    _this.router.navigate(['/successfully-payment', 0]);
                    _this.alerts.error("Payment does not succeed", true);
                }
                //   this.paymentService.customerCharge({"token": data.id, "planid": this.planid}).subscribe((data: any) => {
                //     console.log(data);
                //     if(data.status == "succeeded")
                //     {
                //       this.paymentService.createSubscription({"customer": data.customer, "planid": this.planid}).subscribe((data: any) => {
                //         console.log(data);
                //         // this.plan.updatecompanyplan(planupdate).subscribe((data: any) => {   
                //         // });
                //       });
                //     }
                //   });
            });
        }
    };
    ChangeplanComponent.prototype.selectPlanType = function (event) {
        var _this = this;
        // console.log(event);
        var type = { "plantype": event };
        this.plan.getplanlist(type).subscribe(function (data) {
            _this.planlist = data;
        });
    };
    ChangeplanComponent.prototype.setStripeToken = function (token, id) {
        var _this = this;
        var data = {
            "id": token.id,
            "customer": this.companyData.stuser,
            "cmonth": token.card.exp_month,
            "cyear": token.card.exp_year,
            "cardnumber": token.card.last4,
            "tokenid": token.card.id,
            "cardname": token.card.brand,
            "country": token.card.country,
            "cstatus": "1"
        };
        // console.log(data);
        this.modalService.close(id);
        this.paymentService.addnewCard(data).subscribe(function (data) {
            if (data.success == "success") {
                _this.paymentService.getTotalCards().subscribe(function (data) {
                    _this.alerts.success("Card has been successfully added.", true);
                    _this.cardlist = data.data;
                });
            }
        });
        // console.log(token.card);
    };
    ChangeplanComponent.prototype.onStripeError = function (error) {
        console.error('Stripe error', error);
    };
    ChangeplanComponent.prototype.updateyourplan = function (id) {
        this.modalService.open(id);
    };
    ChangeplanComponent.prototype.cardlistopen = function (id) {
        var _this = this;
        this.paymentService.getTotalCards().subscribe(function (data) {
            // console.log(data.data);
            _this.cardlist = data.data;
        });
        this.modalService.open(id);
    };
    ChangeplanComponent.prototype.removeCard = function (id) {
        var _this = this;
        this.paymentService.removeCard({ "customer": this.companyData.stuser, "id": id }).subscribe(function (data) {
            // console.log(data);
            _this.paymentService.getTotalCards().subscribe(function (data) {
                // console.log(data.data);
                _this.cardlist = data.data;
            });
            // this.cardlist = data.data;
        });
    };
    ChangeplanComponent.prototype.openModal = function (id) {
        this.buttoninsert = "Apply Coupons";
        this.modalService.open(id);
    };
    ChangeplanComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    ChangeplanComponent.prototype.logout = function () {
        var _this = this;
        if (confirm("Are you sure you want to signout?")) {
            this.userService.logout().subscribe(function (data) {
                localStorage.removeItem('userToken');
                localStorage.removeItem('userid');
                localStorage.removeItem('emptype');
                localStorage.removeItem('isline');
                localStorage.removeItem('planid');
                localStorage.removeItem('isemail');
                _this.router.navigate(['/login']);
            });
        }
    };
    return ChangeplanComponent;
}());
exports.ChangeplanComponent = ChangeplanComponent;
