import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';


@Injectable({
  providedIn: 'root'
})
export class MycosService {
  [x: string]: any;
  private modals: any[] = [];
  constructor(private commonService: CommonService) {}

  getcosList(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/coslist');
    // return  this.http.get(AppGlobals.API_ENDPOINT + '/api/Tblemployecosdetails/get-employee-cos-details');
  }

  getcosListbyadmin(value: any): Observable<any> {
    return this.commonService.formpost('/employee/coslistbyadmin',value);
  }

  mycosdetailsUpdatebyadmin(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/cosupdatebyadmin',value);
  }

  mycosDeletebyadmin(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/deleteCosbyadmin',value);
  }

  mycosaibyadmin(value:any): Observable<any> {
    return  this.commonService.getAI(value);
  }

 
}
