import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscriptioncancel',
  templateUrl: './subscriptioncancel.component.html',
  styleUrls: ['./subscriptioncancel.component.css']
})
export class SubscriptioncancelComponent implements OnInit {

  ctype='';
  constructor(private router: Router) { }

  ngOnInit() {    
    setTimeout(() => {
      this.ctype = localStorage.getItem('ctype');      
      if (this.ctype == '0' ) 
      {
        this.router.navigate(['choose-plan']);
      }
     else{
      this.router.navigate(['login']);
     }
      // this.router.navigate(['/dashboard']);
  }, 9000);  //5s
  }
}