<div class="bg">
  <app-header></app-header>


  <div class="section-wrapper">
      <div class="container">
          <div class="tabs" id="tabs">

              <div class="tabs__content">

                  <div class="tabs__content__item active-tab">
                      <div class="content">
                          <div class="space_em row">
                              <div class="col-lg-6">
                                  <h5 class="hedingcolor">Employee List
                                      <!-- <a class="mainheading_btn " *ngIf="empperm > 1" (click)="openModal2('custom-employee')"> + Add New </a> -->
                                  </h5>

                              </div>

                              <div class="col-lg-4">
                                  <div class="input-group">
                                      <input [(ngModel)]="searchText" placeholder="Search.." class="form-control">
                                  </div>

                              </div>
                          </div>
                          <ng-container *ngIf="( datalist |filter: {fname: searchText,mname: searchText,lname: searchText,emailid: searchText,countryname: searchText }) as pageOfItems"> 
                            <div class="job_list_section employee_list_section" *ngFor="let employee of pageOfItems; let i=index">
                                <div class="job_detils_date">

                                    <ng-container>
                                        <input type="checkbox" name="Insolvency" id="check{{employee.employeemasterid}}"                       
                                        (change)="selectemployeemaster($event.target.checked,employee.employeemasterid)" value="{{employee.employeemasterid}}" class="activity">
                                        <label for="check{{employee.employeemasterid}}"></label>
                                    </ng-container>

                                    <div class="visa_user_pic">
                                        <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                        <img *ngIf="employee.imagename" src="{{employee.imagename}}" class="img width100" alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                        <img *ngIf="employee.gender == true && (employee.imagename == null || employee.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                        <img *ngIf="employee.gender == false && (employee.imagename == null || employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                    </div>
                                </div>
                                <div class="job_detils_date width22">
                                    <h5 class="job_profile font_17">{{employee.fname}} {{employee.mname}} {{employee.lname}}</h5>
                                    <p class="email"><i aria-hidden="true" class="fa fa-envelope-o"></i> {{employee.emailid}}</p>
                                    <p class="phone"><i aria-hidden="true" class="fa fa-phone"></i> {{employee.phonenumber}}</p>
                                </div>
                                <div class="job_detils_date width15">
                                    <p class="job_address"> NI Number</p>
                                    <p class="job_profile text-primary">{{employee.ninumber}}</p>
                                </div>
                                <div class="job_detils_date width20">
                                    <p class="job_address">Employee ID</p>
                                    <p class="job_profile"> {{employee.employeeid}}</p>
                                </div>
                                <div class="job_detils_date width22">
                                    <p class="job_address">Nationality</p>
                                    <p class="badge badge-danger" *ngIf="employee.nationality">{{employee.nationality.countryname}} </p>
                                </div>
                                <div class="job_detils_date  width13 d-flex aligncenter">
                                    <a type="submit" class="btn btn-primary com_btn"  *ngIf="empperm > 2" [routerLink]="'/rota-by-employee/'+ employee.employeemasterid">View Rota</a>
                                    <!-- <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Action <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" *ngIf="empperm > 2" [routerLink]="'/employeedetails/'+ employee.employeemasterid">View Details</a>
                                            <a class="dropdown-item" *ngIf="(empperm > 2) && (employee.isadmin ==false) && (employee.terminiationid == null || employee.terminiationid == '') " (click)="openModelTerminate(employee.employeemasterid, 'custom-terminate-1')">Terminate</a>
                                         
  
                                        </div>
                                    </div> -->
                                    <!-- <div class="onoffswitch" *ngIf="(employee.isadmin == false) && (empperm > 2)">
                                        <label class="onoffswitch-label" for="skill{{i+1}}" class="switch">
                                            <input type="checkbox"  name="{{ employee.estatus }}" [checked]="employee.estatus" id="skill{{i+1}}" (change)="onChangeStatus($event, employee)">
                                            <span class="slider round"></span>
                                          </label>
                                    </div> -->
                                    <!-- <input type="checkbox" name="{{ employee.estatus }}" [checked]="employee.estatus" id="skill{{i+1}}" (change)="onChangeStatus($event, employee)" class="onoffswitch-checkbox">
                                        <label class="onoffswitch-label" for="skill{{i+1}}">
                                                <span class="onoffswitch-inner"></span>
                                                <span class="onoffswitch-switch"></span>
                                            </label> -->
  
                                </div>
                            </div>
                            <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>      
                        
                          </ng-container>



                          <!-- <div class="job_list_section visa_request_section" *ngIf="record == 0">
                            <div class="job_detils_date">
                                <p class="job_profile">No Record Found</p>
                            </div>
                             </div>       -->
                          <div class="row">
                              <div class="col-lg-12 text-center mt-5">

                                <a type="submit" class="btn btn-primary com_btn text-white"  *ngIf="empperm > 2 && employeelist.length >0" (click)="openrotasystem(employeelistwithcom,'event-viewer-rota')"   >Set Rota to all selected Employee</a>

                                  <!-- <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination> -->
                              </div>
                          </div>

                      </div>
                  </div>
              </div> 
          </div>
      </div>
  </div>
</div>




<jw-modal id="event-viewer-rota" class="modal listofwork" role="dialog">
    <div role="document" class="formgroup">
        <div class="bd-example-modal-lg">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="myLargeModalLabel">Rota</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true" (click)="closeModal('event-viewer-rota');">×</span>
                    </button>
                    </div>
                    <div class="modal-body">
                        <div class="container">
                            
  
                            <div class="leave_apply_section">
                                <div class="white_box_shadow leave_width">
                                    <form [formGroup]="rotaformGroup" #rotaForm="ngForm" class="s12 white" (ngSubmit)="onSubmitrota(rotaForm.value,'modalContent')" *ngIf="rota">
                                        <input type="hidden" formControlName="id" class="form-control" name="id" id="id" [(ngModel)]="rota.employeerotaid">
                                        <input type="hidden" formControlName="userId" class="form-control" required name="userId" id="userId" [(ngModel)]="rota.userId">
                                        <input type="hidden" formControlName="employeeId" class="form-control" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="rota.employeeId">
                                        <div class="formgroup">

                                            <div class="row row-sm mg-t-10">
                                               <div style="width: 100%; height: 100px;overflow-y: scroll;border: 1px solid #d1d1d1;padding: 10px;">
                                                <p style="width: 100%; margin-bottom: 0px;" *ngFor="let employee of rotaemployeelist" >
                                                    <!-- <ng-container>
                                                        <input type="checkbox" name="Insolvency" id="check{{employee.employeemasterid}}"     checked="checked"                   
                                                        (change)="selectemployeemaster($event.target.checked,employee.employeemasterid)" value="{{employee.employeemasterid}}" class="activity">
                                                        <label for="check{{employee.employeemasterid}}"></label>
                                                    </ng-container> -->

                                                {{ employee.fname}} {{ employee.mname}} {{ employee.lname}}
                                                </p>
                                                </div>
                                            </div>


                                            <div class="row row-sm mg-t-10">
                                                <label class="d-flex justify-content-between"><span>Title*</span> </label>
                                                <input type="text" formControlName="title" name="title" id="title" [ngClass]="{ 'is-invalid': submitted && t.title.errors }" class="form-control" [(ngModel)]="rota.title" [maxlength]="100" placeholder="Enter title here">
                                                <div *ngIf="submitted && t.title.errors" class="invalid-feedback">
                                                    <div *ngIf="t.title.errors.required">Please enter title</div>
                                                    <div *ngIf="!t.title.errors.required && t.title.errors.minlength">Minimum 3 characters required</div>
                                                </div>
                                            </div>

                                            <div class="row row-sm mg-t-10">
                                              <label class="d-flex justify-content-between"><span>Start Time*</span> </label>
                                              <select formControlName="starttime" name="starttime" id="starttime" [ngClass]="{ 'is-invalid': submitted && t.starttime.errors }" class="form-control" [(ngModel)]="rota.starttime">
                                                <option *ngFor="let tval of timeValues" value="{{ tval.key }}">{{ tval.value }}</option>
                                              </select>
                                              <div *ngIf="submitted && t.starttime.errors" class="invalid-feedback">
                                                  <div *ngIf="t.starttime.errors.required">Please enter start time</div>
                                              </div>
                                            </div>

                                            <div class="row row-sm mg-t-10">
                                              <label class="d-flex justify-content-between"><span>End Time*</span> </label>
                                              <select formControlName="endtime" name="endtime" id="endtime" [ngClass]="{ 'is-invalid': submitted && t.endtime.errors }" class="form-control" [(ngModel)]="rota.endtime">
                                                <option *ngFor="let tval of timeValues" [disabled]="rotaformGroup.controls.starttime.value>=tval.key" value="{{ tval.key }}">{{ tval.value }}</option>
                                              </select>
                                              <div *ngIf="submitted && t.endtime.errors" class="invalid-feedback">
                                                  <div *ngIf="t.endtime.errors.required">Please enter end time</div>
                                              </div>
                                            </div>

                                            <div class="row row-sm mg-t-10">
                                                <label>Start Date*</label>
                                                <mat-form-field class="datecontrol">
                                                    <input matInput [matDatepicker]="picker" class="form-control"  (dateChange)="onDateChange($event)" [ngClass]="{ 'is-invalid': submitted && t.startDate.errors }" [min]="minstartDate" formControlName="startDate" id="start" name="start" [(ngModel)]="rota.startDate">
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                                <div *ngIf="submitted && t.startDate.errors" class="invalid-feedback">
                                                    <div *ngIf="t.startDate.errors.required">Please choose start date </div>
                                                </div>
                                            </div>

                                            <div class="row row-sm mg-t-10">
                                              <label>End Date*</label>
                                              <mat-form-field class="datecontrol">
                                                  <input matInput [matDatepicker]="picker2" class="form-control"   [ngClass]="{ 'is-invalid': submitted && t.endDate.errors }" formControlName="endDate" id="end" name="end" [(ngModel)]="rota.endDate" [min]="rotaformGroup.controls.startDate.value">
                                                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                  <mat-datepicker #picker2></mat-datepicker>
                                              </mat-form-field>
                                              <div *ngIf="submitted && t.endDate.errors" class="invalid-feedback">
                                                  <div *ngIf="t.endDate.errors.required">Please choose end date</div>
                                              </div>
                                            </div>

                                            <div class="row row-sm mg-t-10">
                                              <label>Office Location*</label>
                                              <select class="form-control" name="locationid" id="locationid" [(ngModel)]="rota.locationId" [ngClass]="{ 'is-invalid': submitted && t.locationId.errors }" formControlName="locationId">
                                                <option value="">Select Office Location</option>
                                                <option *ngFor="let ltype of locationlist" value="{{ ltype.companylocationdetailsid }}">{{ltype.address1 }} - {{ltype.cityname }}, {{ltype.statename }}</option>

                                              </select>
                                              <div *ngIf="submitted && t.locationId.errors" class="invalid-feedback">
                                                  <div *ngIf="t.locationId.errors.required">Please select location</div>
                                              </div>
                                            </div>
                                            
                                            <div class="modal-footer row mg-t-10">
                                                <div class="row invalid-feedback" *ngIf="timeslotError">
                                                    <div>{{timeSlotErrorMessage}}</div>
                                                </div>
                                                <div class="row">
                                                    <button type="submit" class="btn btn-primary com_btn" >Save</button>
                                                    <button type="button" class="btn btn-secondary com_btn" data-dismiss="modal" id="closeModal" (click)="close()">Close</button>
                                                    <!-- <button *ngIf="(rota.employeeholidayapplyid != 0) && (rota.hstatus == '1')" type="button" class="btn btn-danger com_btn" (click)="onDelete(rota.employeeholidayapplyid)">Delete</button> -->
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </jw-modal>
  