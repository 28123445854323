"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".card[_ngcontent-%COMP%]   .card-body[_ngcontent-%COMP%]{border-top:0;border-left:0;border-right:0}.mt-10[_ngcontent-%COMP%]{margin-top:20px;margin-bottom:20px;border:1px solid #d1d1d1!important}\n/*# sourceMappingURL=useraddreference.component.css.map*/"];
exports.styles = styles;
