
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Component, OnInit, NgModule } from '@angular/core';
import { MyaddressService } from '../../../services/myaddress.service';
// import { ModalService } from '../../services/modal.service';
import { ModalService } from '../../../_services/modal.service';
import { CountryService } from '../../../services/country.service';
import { CommonService } from '../../../services/common.service';
// import { AlertPromise } from 'selenium-webdriver';
//import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Address } from '../../../models/address/address.model';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { MatOptionSelectionChange } from '@angular/material';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { truncate } from 'fs';
import { AlertService } from '../../../services/alert.service';


@NgModule({
  imports: [
    InternationalPhoneNumberModule,
  ],
})
@Component({
  selector: 'app-employeeaddresslist',
  templateUrl: './employeeaddresslist.component.html',
  styleUrls: ['./employeeaddresslist.component.css']
})
export class EmployeeaddresslistComponent implements OnInit {

  address = new Address();
  addresslist: any[];
  addressdata: any[];
  addressDetail: any;
  countrylist: any[];
  Userid: any;
  buttoninsert:any;
  listcheck: any;
  mobilecheck: any;
  phonecheck: any;
  statelist: any[];
  users: any[];
  initialpin: string;
  addresslistDetail: any;
  countrylistjson: any[];
  address2 = 0;
  myControl = new FormControl();
  options = [];
  currentdatevalue: any;
  movedoutdateerror: any;
  filterItems = []
  addpopForm: FormGroup;
  submitted: any;
  currentDate = new Date();
  empid: any;
  disableBtn: any;


  constructor(
    private formBuilder: FormBuilder, 
    private data: MyaddressService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private commonService: CommonService, 
    private http: HttpClient,
    private alerts: AlertService,
    private activatedRoute: ActivatedRoute
    ) { }

  ngOnInit() {
    this.empid = this.activatedRoute.snapshot.paramMap.get("id");
    this.Userid = localStorage.getItem('userid');

    this.currentdatevalue = false;
    this.addpopForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      cityname: ['', Validators.compose([Validators.required,])],
      //  mobilenumber: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{10}")])],
    //  mobilenumber: ['', Validators.required],
     movedin: ['', Validators.required],
     movedout: [''],
      pincode: ['', Validators.required],
      iscurrent: [''],
      statename: ['', Validators.compose([Validators.required])],
      // phonenumber: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{10}")])],
      // phonenumber: [''],
      countryid: ['', Validators.compose([Validators.required, Validators.min(1)])]
    });

    const id = localStorage.getItem('userToken');
    const data = { 'id': this.empid, 'cstatus': [1, 2, 3] };
    this.data.getemploymenttListbyadmin(data).subscribe((data: any) => {
      // console.log(data);
      this.addresslist = data;
      this.addressdata = data;
    });
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.listcheck = this.filterItems;
  }

  get f() { return this.addpopForm.controls; }
  onSubmit(formaddress: any, id: string) 
  {
    // var formaddress = value3.value;
    this.submitted = true;
    this.movedoutdateerror = "";
    // console.log(this.addpopForm);
    

    
    if(formaddress.iscurrent == 0 || formaddress.iscurrent == undefined)
    {
      formaddress.iscurrent= false;
    }
    if(formaddress.iscurrent == 1)
    {
      formaddress.iscurrent= true;
    }

    if((formaddress.iscurrent == false) && (formaddress.movedout == ''))
    { 
      this.movedoutdateerror = "Please select Moved Out Date";
    }

    if (this.addpopForm.invalid || this.movedoutdateerror != "") 
    {      
      return;
    }

    var pinc = formaddress.pincode.split(',');
    formaddress.pincode = pinc[pinc.length - 1];
    
    if((formaddress.iscurrent == true) )
    {      
      this.data.myaddresschangecurrent(formaddress).subscribe((data: any) => {

      });      
    }
    
    this.disableBtn = false;
    formaddress.createby = this.Userid;
    formaddress.updateby = this.Userid;

    
    const sd = new Date(formaddress.movedin);
    formaddress.movedin = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());

    if(formaddress.movedout)
    {
      const ed = new Date(formaddress.movedout);
      formaddress.movedout = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
    }

    this.data.myaddressdetailsUpdatebyadmin(formaddress).subscribe((data: any) => {
      const data1 = { 'id': this.empid, 'cstatus': [1, 2, 3] };
      this.data.getemploymenttListbyadmin(data1).subscribe((data: any) => {
        const data2 = { 'id': data.countryid };
        this.country.getstatelist(data2).subscribe((state: any) => {
          this.statelist = state
        });
        this.addressdata = data;
        this.addresslist = data;
      });
    });
    this.alerts.success('Address Updated Successfully',true);
    this.modalService.close(id);
    // this.addresslistDetail = new Address();
  }

  number(input: FormControl) {
    const isnumber = input.value > 0;
    return isnumber ? null : true;
  }

  onSelectCountry(country_id: number) {
    const data = { 'id': country_id };
    this.country.getstatelist(data).subscribe((state: any) => {
      return this.statelist = state
    });
  }

  onlistchange(type1: any, idval: number) {
    var sstatus: any;
    for (let c of this.filterItems) {
      if (c.id == idval)
        c.selected = true;
      else
        c.selected = false;
    }

    if (idval == 0 || type1 == false) {
      sstatus = [1, 2, 3];
    }
    else {
      sstatus = [idval];
    }
    const data = { 'id': this.empid, 'cstatus': sstatus };

    this.data.getemploymenttListbyadmin(data).subscribe((data: any) => {
      
      this.addresslist = data;
      this.addressdata = data;
    });

  }

  onSearchChange(searchValue) {
    if (searchValue.length > 4) {
      var newArray = [];
      this.commonService.getAddress(searchValue).subscribe((data: any) => {
        var newdata: any;
        newdata = data;
        newdata.addresses.forEach(function (value) {
          newArray.push(value + "," + searchValue);
        });
        this.options = newArray;
      });
    }
    else {
      this.options = [];
    }
  }

  displayFn(value: any) {
    if (value != null) {
      var res = value.split(",");
      if (res.length > 6) {
        return res[7];
      }
      else {
        return value;
      }
    }
  }

  updatecode(evt: MatOptionSelectionChange, response) {
    res = [];
    if (evt.source.selected) {
      var res = response.split(",");
      this.addresslistDetail.address1 = res[0];
      this.addresslistDetail.address2 = res[1];
      this.addresslistDetail.cityname = res[5];
      this.addresslistDetail.pincode = res[7];
      const data = { 'id': this.addresslistDetail.countryid };
      this.country.getstatelist(data).subscribe((state: any) => {
        for (var i = 0; i < state.length; i++) {
          if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
            var inde = state[i]['stateid'];
          }
        }
        this.addresslistDetail.stateid = inde;
        this.statelist = state;
      });
    }
  }

  onDelete(empid: number)
  {
    if (confirm("Are you sure you want to delete?")) {
      const data1 = { 'id': empid };
      this.data.myaddressDelete(data1).subscribe((data: any) => {
        var sstatus: any;
        for (let c of this.filterItems) {
          if (c.selected == true) {
            if (c.id == 0) {
              sstatus = [1, 2, 3];
            }
            else {
              sstatus = [c.id];
            }
          }
        }
        const data1 = { 'id': this.empid, 'cstatus': sstatus };
        this.data.getemploymenttListbyadmin(data1).subscribe((data: any) => {
          
          return this.addressdata = data;
        });
      })
    }
  }

  currentaddresschange(eve,addressid)
  {    
    if(eve.target.value==1)
    {
      this.addresslistDetail.movedout = "";
      this.currentdatevalue = false;
    }
    else{  
      this.currentdatevalue = true;
    }
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  openModal(id: string, data: any) {
    this.disableBtn = true;
    if(data.iscurrent == true)
    {
      this.currentdatevalue = false;
    }
    else
    {
      this.currentdatevalue = true;
    }
    this.buttoninsert = "Update";
    this.addresslistDetail = data;
    // console.log(this.addresslistDetail);
    if(this.addresslistDetail.iscurrent === true)
    {
      this.addresslistDetail.iscurrent = '1';
    }
    if(this.addresslistDetail.iscurrent === false)
    {
      this.addresslistDetail.iscurrent = '0';
    }
    const data2 = { 'id': data.countryid };
    this.country.getstatelist(data2).subscribe((state: any) => {
      this.statelist = state
    });
    this.modalService.open(id);
  }

  openModal2(id: string) {
    this.submitted = false;
    this.disableBtn = true;
    // this.addpopForm.invalid == false;
    this.addresslistDetail = new Address();
    this.buttoninsert = "Submit";
    this.addresslistDetail.employeeid = this.empid;
    this.addresslistDetail.employeeaddresdeatilid = "0";
    this.addresslistDetail.countryid = "70";
    this.currentdatevalue = true;
    this.addresslistDetail.iscurrent = '0';
    this.mobilecheck = "";
    this.phonecheck = "";
    // this.addresslistDetail.movedout ='';
    // this.addresslistDetail.address2 ='';
    // this.addresslistDetail.pincode ='';
    // this.addresslistDetail.cityname ='';
    // this.addresslistDetail.stateid ='';
    // this.addresslistDetail.phonenumber ='';
    // this.addresslistDetail.mobilenumber ='';
    this.modalService.open(id);
  }

  closeModal(id: string) {
    // this.addresslistDetail = new Address();
    // console.log(this.addresslistDetail);
      const data1 = { 'id': this.empid, 'cstatus': [1, 2, 3] };
      this.data.getemploymenttListbyadmin(data1).subscribe((data: any) => {
        const data2 = { 'id': data.countryid };
        this.country.getstatelist(data2).subscribe((state: any) => {
          this.statelist = state
        });
        this.addressdata = data;
        this.addresslist = data;
      });
      // this.addresslistDetail = new Address();
    
    this.modalService.close(id);

  }

  modalOpen(data: any) {
    this.addresslistDetail = data;
  }

}



