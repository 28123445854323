import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Idproof } from '../../../models/idproof/idproof.model';
import { Employee } from '../../../models/employee.model';
import { MyidproofService } from '../../../services/myidproof.service';
import { CommonService } from '../../../services/common.service';
import { MyvisaService } from '../../../services/myvisa.service';
import { AppGlobals } from '../../../app.global';
import { AicenterService } from '../../../services/aicenter.service';
import { AlertService } from '../../../services/alert.service';
import { ChecksystemService } from '../../../services/checksystem.service';


@Component({
  selector: 'app-idreviewaiverify',
  templateUrl: './idreviewaiverify.component.html',
  styleUrls: ['./idreviewaiverify.component.css']
})
export class IdreviewaiverifyComponent implements OnInit {

  submitted: any;
  idproofpopForm: FormGroup;
  idproof: Idproof;
  permission: any;
  requestperm: any;
  record: number;
  ctype:number;
  ispayment: boolean;
  remainingcheck: any;
  employeemasterid: any;
  logoimage3:any;
  idproofdetails = new Idproof();  
  employee = new Employee();
  logoimage2:any;logoimage4:any;
  logoimage:any;
  data3:any;
  data4:any;

  aipayment: boolean =false;
  employeeidproofdeatilid:any;

  constructor(private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder,  
    private idproofService: MyidproofService, 
    private check: ChecksystemService,    
    private activatedRoute: ActivatedRoute,       
    private visaService: MyvisaService,    
    private aicenter:AicenterService,
    private common: CommonService,  
    private alerts: AlertService,
    private router: Router) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    this.ispayment =true;
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;

    this.ctype  = parseInt(localStorage.getItem('ctype'));
    this.employeeidproofdeatilid = this.activatedRoute.snapshot.paramMap.get("idproofid");

    this.data3 = {"employeeidproofdeatilid": this.employeeidproofdeatilid}
    this.idproofService.idproofdetailsbyidproofid(this.data3).subscribe((data: any) => { 
      this.logoimage2 = data.iddocName;
      this.logoimage4 = data.idbackdocName;
      this.logoimage3 = data.employee.imagename;
      this.employeemasterid =data.employee.employeemasterid;
      this.idproofdetails = data;
      this.record  = data.length;
    });

    this.check.getbillinginformation({ 'serviceid': 7 }).subscribe((remaindata: any) => { 
      this.remainingcheck = remaindata.remiancheck;
    });

    // this.aicenter.getcountpayment().subscribe((data: any) => {     
    //   this.aipayment = data.payment;
    // });


    this.data4 ={ "employeid": this.employeemasterid}
    this.idproofService.idproofaipaymentstaus(this.data4).subscribe((data5: any) => { 
      var ct = data5.count;
      if(ct ==0)
      {
        this.ispayment =false;
      }else
      {
        this.ispayment =true;
      }      
    });
  }


  Sendidproof(){

    this.data3 = {"employeeidproofdeatilid": this.employeeidproofdeatilid}
    this.idproofService.idproofdetailsbyidproofid(this.data3).subscribe((data: any) => {      
      var countryiso = data.nationality.isothree;
      this.submitted = true;
      
      var idtype = 'DRIVING_LICENSE';//data.documenttype.documentcode;     
      var visalog = {
        employeeid: this.employeemasterid,
        documentid: data.employeeidproofdeatilid,
        documenturl: data.imagename,
        documenttype: 'id'
      };

      this.visaService.visaailogupdate(visalog).subscribe((dbvisaresult: any) => {        
        if(dbvisaresult.success =='1'){
          var data = { 
            merchantIdScanReference:dbvisaresult.logid,
            country : countryiso,
            idType :idtype,
            customerId:  this.employeemasterid,
            callbackUrl: AppGlobals.API_ENDPOINT +"/api/aiconfirmation",
            documenturl: data.imagename,
            documentbackurl: data.imagebackname,
            imageurl: data.idprofileimage
          };  

          this.visaService.visadocumentupdate(data).subscribe((data5: any) => {
            if(data5.success == true){
              this.router.navigate(['/idproofaithanks/' + this.employeeidproofdeatilid]);
            }else {
              this.alerts.error("something went wrong please try again");
            }          
          });
          }else{
            this.alerts.error("something went wrong please try again");
          }
      });
    });

  }


  Sendidproof2(){
    this.getBase64ImageFromUrl(this.logoimage2)
    .then(result => this.facetime(result))
    .catch(err => console.error(err));
  }

  facetime(imagebase64)
  { 
    this.getBase64ImageFromUrl(this.logoimage3)
    .then(result => this.sendtoAI(imagebase64,result))
    .catch(err => console.error(err));
    
  }

  sendtoAI(imagebase64,faceimage64){

    var fullpathArray = this.logoimage2.split('.');

    var eximage = fullpathArray[fullpathArray.length - 1]; 
    var faceimage = fullpathArray[fullpathArray.length - 1]; 

    var data = { merchantIdScanReference:this.employeemasterid , frontsideImage :imagebase64, faceImage: faceimage64, country: 'uk', idType:'idproof', frontsideImageMimeType: 'image/'+eximage ,faceImageMimeType: 'image/'+faceimage, customerId: 'fadsfas',
    callbackUrl: "https://www.complygate.co.uk/aiconfirmation", firstName: this.idproofdetails.employee.fname, lastName: this.idproofdetails.employee.lname, expiry: this.idproofdetails.docexpiry, number:this.idproofdetails.idnumber   }
    

  
   this.router.navigate(['/idproofaithanks', this.employeemasterid ]);

  }



  closeModal(id){

  }

  /************ AI Required Base64 bit  ***********/

  transform(html){
    return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  }


  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      // console.log(blob);
      reader.readAsDataURL(blob);
    })
  }



}
