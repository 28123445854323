"use strict";
//import { Nationality } from './nationality.model';
Object.defineProperty(exports, "__esModule", { value: true });
var Leave = /** @class */ (function () {
    function Leave() {
    }
    return Leave;
}());
exports.Leave = Leave;
var Leavecount = /** @class */ (function () {
    function Leavecount() {
    }
    return Leavecount;
}());
exports.Leavecount = Leavecount;
