
<div class="bg">
<app-header></app-header>
<div class="section-wrapper" >
  <div class="container">
    <div class="tabs" id="tabs">
      <div class="tabs__wcontent">
        <div class="tabs2__content__item active-tab" id="jan">
          <div class="content">

              <!-- <div class="row row-sm">
                  <div class="col-lg-8">                  
                  </div>
                  <div class="col-lg-4 float-right text-right">
                   
                  </div>
                </div> -->

              <ul class="tabs__head" id="employeetopmenu">
                  <!-- (scroll)="onWindowScroll($event)" <li><a (click)='onmousetopbottom("overview","overview1")' [className]="tab==1 ? 'active-link' : 'inactive'"  >Overview</a></li>                  
                 
                  <li><a  (click)='onmousetopbottom("department",department1)' [className]="tab==10 ? 'active-link' : 'inactive'" >Department</a></li>                  
                  <li><a  (click)='onmousetopbottom("overemployeement",overemployeement1)' [className]="tab==2 ? 'active-link' : 'inactive'" >Employment</a></li>
                  <li><a  (click)='onmousetopbottom("overaddress",overaddress1)' [className]="tab==3 ? 'active-link' : 'inactive'" >Address </a></li>
                  <li><a  (click)='onmousetopbottom("overcompen",overcompen1)'  [className]="tab==4 ? 'active-link' : 'inactive'" >Compensation </a></li>
                  <li><a  (click)='onmousetopbottom("overcontactinfo",overcontactinfo1)'  [className]="tab==5 ? 'active-link' : 'inactive'" >Contact </a></li>
                  <li><a  (click)='onmousetopbottom("overcos",overcos1)' *ngIf="empltype == false"  [className]="tab==6 ? 'active-link' : 'inactive'"  >COS</a></li>
                  <li><a  (click)='onmousetopbottom("overvisa",overvisa1)' *ngIf="empltype == false"  [className]="tab==7 ? 'active-link' : 'inactive'"  >Visa</a></li>
                  <li><a  (click)='onmousetopbottom("overpassport",overpassport1)' *ngIf="empltype == false"  [className]="tab==8 ? 'active-link' : 'inactive'"  >Passport</a></li>             
                  <li><a  (click)='onmousetopbottom("idproof",idproof1)' *ngIf="empltype == false"  [className]="tab==9 ? 'active-link' : 'inactive'"  >Address Proof</a></li>   -->
                
                
                  <!-- <li class="employee_more_detail_menu">
                    <a>
                    <span class="menu_name">More</span>
                    <span class="lnr lnr-chevron-down ml-2"></span>
                    </a>
                    <ul class="submenu">                                  
                        <li routerLinkActive="active-link">
                            <a  (click)='openNotesModel("custom-update-notes")'  [className]="tab==9 ? 'active-link' : 'inactive'">                                          
                                <span class="menu_name">Send Note</span>
                            </a>                                       
                        </li>
                     
                        <li routerLinkActive="active-link">
                            <a (click)='onmousetopbottom("more",idproof1)'  [className]="tab==9 ? 'active-link' : 'inactive'" >                                           
                                <span class="menu_name">Active</span>
                            </a>
                           
                        </li>
                    </ul>

                </li> -->
                </ul>

                <div class="user_profle_section">
                    <div class="profile_box">
                      
                      <div id="overview">
                        <div class="d-flex justify-content-between align-items-center mt-2 pr-3 pl-2">
                          <a [routerLink]="['/employeelist']" class="backlist mr-0"><span class="lnr lnr-chevron-left"></span> Back to Employee List</a>
                          <div class="active-inactive employee_list_section">
                              <div class="onoffswitch">
                                <label class="onoffswitch-label" for="skill" class="switch">
                                    <input type="checkbox"  name="empstatus"  (change)="onChangeStatus($event,empid)" [checked]="overviewData.estatus" id="skill" >
                                    <span class="slider round"></span>
                                  </label> 
                              </div>
                            
                            </div>
                            
                        </div>
                        
                        <div class="overviewsshow">                        
                          <div class="cseec">
      
                            <div class="admin_com_logo">
                                <div class="picture_chnge">
                                  <input type="file" accept="image/*" (change)="selectFile($event)" style="display:none;" #file>
                                    <a target="_blank" (click)="file.click()" class="text-black-50 cameraicon"><i class="fa fa-camera"></i></a>
                                </div>
                                <img alt="" [src]="overviewData.imagename" *ngIf="overviewData.imagename !='' && overviewData.imagename !=null" />
                                <img alt="" src="../../../assets/img/no-image-icon-hi.png" *ngIf="overviewData.imagename =='' || overviewData.imagename == null" />
                                <div class="alert-danger" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                <div class="alert-danger" *ngIf="fileformaterror == true">Please choose a valid file format
                                  (jpg, png, tif, gif, pdf, doc)</div>
                            </div>
                        </div>
                        <div class="overview_details">
                          <div class="alignbbb">
                            <div class="headdd width100">
                              <h3 class="card-profile-name d-flex justify-content-center text-left">{{ overviewData.fname}} {{ overviewData.mname }}
                                {{overviewData.lname}} <span *ngIf="overviewData.employeeid != null"> ({{overviewData.employeeid}})</span>&nbsp;<i class="fa fa-pencil tx-15" (click)="openModal('custom-overview-1',overviewData)"
                                  aria-hidden="true"></i></h3>
                                  <div class="buttonprofile mt-2 mb-2">
                                    <div *ngIf="!paddress.addressid1" class="mt-2 mb-2">
                                      <a (click)="openModal2('custom-overview-2',paddress)" class="addscreensuccess">Permanent Address</a>
                                    </div>   
                                  </div>
      
                            </div>
                          
                          
                          </div>
                          <hr class="mt-0"/>
                          <div class="profile_dtailsshow mb-3">
                            <div class="detailsshow ">
                              <span>Email</span>
                              <p>{{ overviewData.emailid }}</p>
                            </div>
                            <div class="detailsshow ">
                              <span>Mobile Number</span>
                              <p>{{overviewData.phonenumber }}</p>
                            </div>
                             
                              <div class="detailsshow ">
                                <span>Gender</span>
                                <p>
                                  <ng-container *ngIf="overviewData.gender==true">
                                    Male
                                  </ng-container>
                                  <ng-container *ngIf="overviewData.gender==false">
                                    Female
                                  </ng-container>
                                </p>
                              </div>
                              <div class="detailsshow ">
                                <span>Marital Status</span>
                                <p>
                                  <ng-container *ngIf="overviewData.maritalstatus==true">
                                    Married
                                  </ng-container>
                                  <ng-container *ngIf="overviewData.maritalstatus==false">
                                    Single
                                  </ng-container>
                                </p>
                              </div>
                              <div class="detailsshow ">
                                <span>Date of Birth</span>
                                <p>{{overviewData.dateofbirth | date: 'dd MMM yyyy' }}</p>
                              </div>
                              <div class="detailsshow ">
                                <span>Date of Joining</span>
                                <p>{{ overviewData.hiredate | date: 'dd MMM yyyy' }}</p>
                              </div>
                             
                          </div>
                          <div class="profile_dtailsshow">
                              <div class="detailsshow ">
                                <span>Nationality</span>
                                <p class="badge badge-danger">{{ overviewData?.nationality?.countryname }}</p>
                              </div>
                              <div class="detailsshow ">
                                <span>Place of Birth</span>
                                <p>{{overviewData.placeofbirth }}</p>
                              </div>
                              <div class="detailsshow ">
                                <span>Country of Residence </span>
                                <p>{{overviewData?.residence?.countryname}}</p>
                              </div>
                              <div class="detailsshow ">
                                <span>Country of Birth</span>
                                <p> {{overviewData?.countryofbirth?.countryname }}</p>
                              </div>
                              <div class="detailsshow ">
                                <span>NI Number</span>
                                <p>{{ overviewData.ninumber }}</p>
                              </div>
                              <div class="detailsshow">
                                <span>Employee Role</span>
                                <p>{{ overviewData.emprole }}</p>
                            </div>
                            
                          </div>
                          <div *ngIf="paddress.addressid1">
                            <div class="alignbbb  mt-2" > 
                              <div class="headdd">
                                <h3 class="card-profile-name">Permanent Address  <span class="fa fa-pencil" (click)="openModal2('custom-overview-2',paddress)"
                                  aria-hidden="true"></span></h3>
        
                              </div>
                            </div>
                            <hr class="mt-0"/>
                              <div class="profile_dtailsshow">
                                  <div class="detailsshow ">
                                      <p><i class="lnr lnr-map-marker"></i> {{ paddress.addressid1 }}, {{ paddress.addressid2 }}, {{ paddress.cityname }}, {{ paddress.statename }}, {{
                                        paddress?.country?.countryname }} - {{ paddress.pincode }} </p>
                                  </div>
                                
                              </div>
                          </div>
                          <!-- <div class="col-lg-12" *ngIf="!otherDetail && !visalist">
                            <p>
                                No Record Found
                            </p>
                        </div> -->
                        </div>
                        </div>
      
      
                        <!-- <div class="row row-sm">
                          <div class="col-lg-8">
                            <div class="card card-profile">
                              <div class="card-body32">
                                <div class="media">
      
      
                                  <div class="row">
                                  <div class="col-lg-3">
                                    <div class="avatar">
                                      <a target="_blank" (click)="file.click()" class="text-black-50 cameraicon"><i class="fa fa-camera"></i></a>
                                      <img alt="" [src]="overviewData.imagename" *ngIf="overviewData.imagename !='' && overviewData.imagename !=null" />
                                      <img alt="" src="../../../assets/img/no-image-icon-hi.png" *ngIf="overviewData.imagename =='' || overviewData.imagename == null" />
                                    </div>
                                    <div class="info">
                                      <div class="title">
                                        <input type="file" accept="image/*" (change)="selectFile($event)" style="display:none;"
                                          #file>
                                        
                                      </div>
                                      <div class="alert-danger" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                            <div class="alert-danger" *ngIf="fileformaterror == true">Please choose a valid file format
                              (jpg, png, tif, gif, pdf, doc)</div>
                                    </div>
                                  </div>
      
                                  <div class="col-lg-9">
                                      <h3 class="card-profile-name">{{ overviewData.fname}} {{ overviewData.mname }}
                                          {{overviewData.lname}} <span *ngIf="overviewData.employeeid != null"> ({{overviewData.employeeid}})</span>&nbsp;<i class="fa fa-pencil tx-15" (click)="openModal('custom-overview-1',overviewData)"
                                            aria-hidden="true"></i></h3>
      
                                      <div class="row">
      
                                  <div class="col-lg-6">
                                    <div class="media-body">
                                      
                                      <p class="card-profile-position"><strong>Email:</strong> {{ overviewData.emailid }}</p>
                                      <p class="card-profile-position"><strong>Mobile Number:</strong> {{
                                        overviewData.phonenumber }}</p>
                                      <p class="card-profile-position"><strong>Date of Birth:</strong> {{
                                        overviewData.dateofbirth | date: 'dd MMM yyyy' }}</p>
                                      <p class="card-profile-position"><strong>Date of Joining:</strong> {{
                                          overviewData.hiredate | date: 'dd MMM yyyy' }}</p>
                                      <p class="card-profile-position"><strong>Gender:</strong>
                                        <ng-container *ngIf="overviewData.gender==true">
                                          Male
                                        </ng-container>
                                        <ng-container *ngIf="overviewData.gender==false">
                                          Female
                                        </ng-container>
                                      </p>
                                      <p class="card-profile-position"><strong>Marital Status:</strong>
                                        <ng-container *ngIf="overviewData.maritalstatus==true">
                                          Married
                                        </ng-container>
                                        <ng-container *ngIf="overviewData.maritalstatus==false">
                                          Single
                                        </ng-container>
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-lg-6">
                                    <div class="media-body">
                                      <h3 class="card-profile-name">&nbsp;</h3>
                                      <p class="card-profile-position"><strong>Nationality:</strong> {{
                                        overviewData?.nationality?.countryname }}</p>
                                      <p class="mg-b-0"><strong>Country of Residence :</strong> {{
                                        overviewData?.residence?.countryname}} </p>
                                      <p class="card-profile-position"><strong>Country of Birth:</strong> {{
                                        overviewData?.countryofbirth?.countryname }}</p>
                                      <p class="card-profile-position"><strong>Place of Birth:</strong> {{
                                        overviewData.placeofbirth }}</p>
                                      <p class="card-profile-position"><strong>NI Number:</strong> {{
                                        overviewData.ninumber }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                                </div> 
                                </div>
                              </div>
                            </div>
                          </div>-->
                          <!-- <div class="col-lg-4">
                            <div class="list-group" *ngIf="!paddress.addressid1">
                                <h6 class="text-right float-right buttonprofile">
                                    <a (click)="openModal2('custom-overview-2',paddress)" class="addscreensuccess">+ Permanent Address</a></h6>
                                
                            
                            </div>
                            
                            <div class="list-group fullalign" *ngIf="paddress.addressid1">
                            <i class="fa fa-pencil tx-15 colorred" (click)="openModal2('custom-overview-2',paddress)"
                                      aria-hidden="true"></i>
                                      <h6 class="per-address">Permanent Address</h6>
                              <div class="list-group-item">
                                <div class="media">
                                  <div class="media-body">
                                    <p class="mg-b-0"><i class="fa fa-map-marker tx-primary tx-15"></i> {{ paddress.addressid1 }} </p>
                                  </div>
                                  
                                </div>
                              </div>
                              <div class="list-group-item" *ngIf="paddress.addressid2 !=''">
                                <div class="media">
                                  <div class="media-body">
                                    <p class="mg-b-0"><i class="fa fa-map-marker tx-primary tx-15"></i> {{ paddress.addressid2 }} </p>
                                  </div>
                                
                                </div>
                              </div>
                              <div class="list-group-item">
                                <div class="media">
                                  <div class="media-body">
                                    <p class="mg-b-0"> <i class="fa fa-map-marker tx-primary tx-15"></i> {{ paddress.cityname }}, {{ paddress.statename }}, {{
                                      paddress?.country?.countryname }} - {{ paddress.pincode }}  </p>
                                  </div>
                                
                                </div>
                              </div>
                              <div class="list-group-item" *ngIf="paddress.phonenumber != '' && paddress.phonenumber != null">
                                <div class="media">
                                  <div class="media-body">
                                    <p class="mg-b-0"> {{paddress.phonenumber}}
                                      <ng-container *ngIf="paddress.mobilenumber !=''">
                                        , {{paddress.mobilenumber}}
                                      </ng-container>
                                    </p>
                                  </div>
                                  <div class="d-flex mg-l-10 mg-t-5 wd-20">
                                    <i class="fa fa-mobile tx-primary tx-15"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>-->
                      </div>
                    </div>
                    <div class="details_box">
                      <div class="overviewsshow pb-0 pt-0 pr-0 pl-0">
                        <mat-tab-group [@.disabled]="true">
                            <!-- <mat-tab label="Overview">
                                <ng-template matTabContent>
                                    <app-applicantdocument></app-applicantdocument>
                                </ng-template>
                            </mat-tab> -->
                            <mat-tab label="Department">
                                <ng-template matTabContent>
                                  <app-mycurrentemployment></app-mycurrentemployment> 
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Employment"> 
                                <ng-template matTabContent>
                                  <app-employementlist></app-employementlist> 
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Address">
                                <ng-template matTabContent>
                                  <app-employeeaddresslist></app-employeeaddresslist>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Compensation">
                                <ng-template matTabContent>
                                  <app-compensationinfo></app-compensationinfo>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Contact">
                                <ng-template matTabContent>
                                  <app-contactinfo></app-contactinfo>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="COS">
                                <ng-template matTabContent>
                                  <app-coslist></app-coslist>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Visa">
                                <ng-template matTabContent>
                                  <app-visalist></app-visalist>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Passport">
                                <ng-template matTabContent>
                                  <app-passportlist></app-passportlist>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Address Proof">
                              <ng-template matTabContent>
                                <app-idprooflist></app-idprooflist>
                              </ng-template>
                          </mat-tab>  
                          <mat-tab label="Remark">
                            <ng-template matTabContent>
                              <div class="height29"></div>
                              <app-candnote></app-candnote>
                            </ng-template>
                        </mat-tab> 
                          <mat-tab label="Termination">
                            <ng-template matTabContent>
                              <div class="overviewsshow">
                                <div class="header">
                                    <h1 class="headprofile mt-0 width50">
                                      Termination
                                    </h1>
                                    <div class="employee_list_section d-flex align-items-center justify-content-between">
                                      <!-- <div class="onoffswitch">
                                        <label class="onoffswitch-label" for="skill" class="switch">
                                            <input type="checkbox"  name="employee.estatus" [checked]="" id="skill" >
                                            <span class="slider round"></span>
                                          </label> 
                                      </div> -->
                                      <a *ngIf="overviewData.terminationid == null || overviewData.terminationid == ''" (click)="openModelTerminate(empid, 'custom-terminate-1')" class="btn btn-dark ml-2 fontbtn"><span class="lnr lnr-power-switch"></span> Terminate</a>
                                      <a *ngIf="overviewData.terminationid != null && overviewData.terminationid != ''" (click)="openModelTerminateactive(empid, overviewData.terminationid, 'custom-terminate-1')" class="btn btn-dark ml-2 fontbtn text_dark"><span class="lnr lnr-power-switch"></span> Edit Termination</a>
                                      <a *ngIf="overviewData.terminationid != null && overviewData.terminationid != ''" (click)="removeTermination()" [attr.disabled]="false" class="addscreensuccess widthauto">Remove Termination</a>
                                    </div>                         
                                </div>
                                <div class="row">
                                  <div class="col-lg-6" *ngFor="let th of terminationHistory">
                                    <div class="border-box">
                                      <div class="overview_details emp">
                                        <div class="profile_dtailsshow">
                                          <div class="detailsshow width50 pr-3">
                                            <span>Termination Category</span>
                                            <p  *ngIf="th.category">{{(th.category)?th.category.title:''}}</p>
                                            <p *ngIf="!th.category">N/A</p>
                                          </div>
                                          <div class="detailsshow width50 pr-3">
                                            <span>Termination Subcategory</span>
                                            <p *ngIf="th.subcategory">{{(th.subcategory)?th.subcategory.title:''}}</p>
                                            <p *ngIf="!th.subcategory">N/A</p>
                                          </div>
                                        </div>
                                        <div class="profile_dtailsshow">
                                          <div class="detailsshow width50 pr-3">
                                            <span>Eligible For Rehire</span>
                                            <p>{{ (th.oktorehire)?'YES':'NO' }}</p>
                                          </div>
                                          <div class="detailsshow width50 pr-3">
                                            <span>Regret Termination</span>
                                            <p>{{ (th.regrettermination)?'YES':'NO' }}</p>
                                          </div>
                                        </div>
                                        <div class="profile_dtailsshow">
                                          <div class="detailsshow width50 pr-3">
                                            <span>Termination Date</span>
                                            <p>{{ th.terminationdate | date: 'dd MMM yyyy' }}</p>
                                          </div>                                   
                                        </div>
                                        <div class="profile_dtailsshow">
                                          <div class="detailsshow width50 pr-3">
                                            <span>Remarks</span>
                                            <p>{{ th.terminationreason }}</p>
                                          </div>                                   
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> 
                                <div class="job_list_section visa_request_section" *ngIf="!terminationHistory?.length > false">
                                  <div class="job_detils_date">
                                      <p class="job_profile">No Record Found</p>
                                  </div>
                              </div> 
                            </div>
                            </ng-template>
                        </mat-tab>
                        </mat-tab-group>
                      
                    </div>
                    </div>
                </div>

                <!-- <div id="department">         
                   
                </div>

                <div id="overemployeement">         
                   
                  </div>

                <div id="overaddress">                    
                   
                  </div>
                
                <div id="overcompen">
                   
                </div>

                <div id="overcontactinfo">                             
                 
                </div>

                <div id="overcos">
                   
                </div>
                  
                <div id="overvisa">                            
                  
                  </div>
                <div id="overpassport">                              
                   
                </div>

                <div id="idproof">                              
                
              </div> -->
              <!-- <div id="terminate">                              
                 
            </div>
              
                  <div style="height: 50px;">&nbsp;</div> -->
                   

<!-- 
              <mat-tab-group>
                <mat-tab label="Current Details">
                  <ng-template matTabContent>
                    <div class="row ml-0 mr-0 mg-t-20">
                      <div class="col-md" *ngIf="employement">
                        <app-employementinfo></app-employementinfo>
                      </div>
                      <div class="col-md">
                        <app-addressinfo></app-addressinfo>
                      </div>
                    </div>
                    <div class="row ml-0 mr-0 mg-t-40 mg-b-40">
                      <div class="col-md" *ngIf="visalist">
                        <app-visainfo></app-visainfo>
                      </div>
                      <div class="col-md">
                        <app-passportinfo></app-passportinfo>
                      </div>
                    </div>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Department Details">
                  <ng-template matTabContent>
                    <app-mycurrentemployment></app-mycurrentemployment>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Employment">
                  <ng-template matTabContent>
                    <app-employementlist></app-employementlist>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Address ">
                  <ng-template matTabContent>
                    <app-employeeaddresslist></app-employeeaddresslist>
                  </ng-template>
                </mat-tab>
                <mat-tab label="COS ">
                  <ng-template matTabContent>
                    <app-coslist></app-coslist>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Visa ">
                  <ng-template matTabContent>
                    <app-visalist></app-visalist>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Passport">
                  <ng-template matTabContent>
                    <app-passportlist></app-passportlist>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Compensation Info">
                  <ng-template matTabContent>
                    <app-compensationinfo></app-compensationinfo>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Contact Info">
                  <ng-template matTabContent>
                    <app-contactinfo></app-contactinfo>
                  </ng-template>
                </mat-tab> -->
                
                <!-- <mat-tab label="Other Information">
                  <ng-template matTabContent>
                    <app-otherinformation></app-otherinformation>
                  </ng-template>
                </mat-tab> -->
              <!-- </mat-tab-group> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<jw-modal id="custom-overview-1" class="modal" role="dialog">
<form [formGroup]="OverForm" #overviewForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(overviewForm.value,'custom-overview-1')">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-header">
        <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="overviewData.employeemasterid">
        <h5 class="modal-title" id="popupLabel">Employee Details</h5>
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModalOverview('custom-overview-1');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="form-group col-lg-4">
              <label>First Name *</label>
              <input type="fname" formControlName="fname" name="fname" id="fname" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" [(ngModel)]="overviewData.fname">
              <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                <div *ngIf="f.fname.errors.required">Please Enter First Name</div>
              </div>
            </div>

            <div class="form-group col-lg-4">
              <label>Middle Name</label>
              <input type="mname" formControlName="mname" class="" name="mname" id="mname" class="form-control"
                [(ngModel)]="overviewData.mname">
            </div>
            <div class="form-group col-lg-4">
              <label>Last Name *</label>
              <input type="lname" formControlName="lname" class="" name="lname" id="lname" class="form-control input-lg"
                [ngClass]="{ 'is-invalid': submitted && f.lname.errors }" [(ngModel)]="overviewData.lname">
              <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                <div *ngIf="f.lname.errors.required">Please Enter Last Name</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-4">
              <label>Date of Birth*</label>
              <mat-form-field class="datecontrol">
                <input matInput [min]="mindob" [max]="maxdob" [matDatepicker]="picker2" id="dateofbirth" [ngClass]="{ 'is-invalid': submitted && f.dateofbirth.errors }"
                  name="dateofbirth" formControlName="dateofbirth" class="form-control" [(ngModel)]="overviewData.dateofbirth">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
              <div *ngIf="submitted && f.dateofbirth.errors" class="invalid-feedback">
                <div *ngIf="f.dateofbirth.errors.required">Please Choose Date of Birth</div>
              </div>
            </div>
            <div class="form-group col-lg-4">
                <label>Hire Date* </label>
                
                <mat-form-field  class="datecontrol">
                  <input matInput  [matDatepicker]="picker3" id="hiredate" [ngClass]="{ 'is-invalid': submitted && f.hiredate.errors }"
                   name="hiredate" formControlName="hiredate" class="form-control" [(ngModel)]="overviewData.hiredate">
                  <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>
                <div *ngIf="submitted && f.hiredate.errors" class="invalid-feedback">
                  <div *ngIf="f.hiredate.errors.required">Please Choose Date of joining</div>
                 </div>
              </div>
            <div class="form-group col-lg-4">
              <label>NI Number *</label>
              <input type="ninumber" formControlName="ninumber" class="" name="ninumber" id="ninumber" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.ninumber.errors }" [(ngModel)]="overviewData.ninumber">
              <div *ngIf="submitted && f.ninumber.errors" class="invalid-feedback">
                <div *ngIf="f.ninumber.errors.required">Please Enter NI Number</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6">
              <label>Employee ID *</label>
              <input type="text" formControlName="employeeid" name="employeeid" id="employeeid" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.employeeid.errors }" [(ngModel)]="overviewData.employeeid">
              <div *ngIf="submitted && f.employeeid.errors" class="invalid-feedback">
                <div *ngIf="f.employeeid.errors.required">Please Enter Employee Id</div>

              </div>
            </div>  
            <div class="form-group col-lg-6">
              <label>Job Role*</label>
              <input type="text" formControlName="emprole" name="emprole" id="emprole" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.emprole.errors }" [(ngModel)]="overviewData.emprole">
              <div *ngIf="submitted && f.emprole.errors" class="invalid-feedback">
                <div *ngIf="f.emprole.errors.required">Please Enter Job Role</div>

              </div>
            </div>

          </div>
          <div class="row">
            <div class="form-group col-lg-6">
              <label>
                Gender </label>
                <div class="form-group">
                  <div class="row">
                      <div class="col-lg-6 col-6">
                    <input type="radio" name="gender" formControlName="gender" [(ngModel)]='overviewData.gender' value="1" [checked]="overviewData.gender === true" /> Male
                    </div>
                    
                    <div class="col-lg-6 col-6">
                        <input type="radio" name="gender" formControlName="gender" [(ngModel)]='overviewData.gender' value="0" [checked]="overviewData.gender === false" /> Female
                    </div>    
                  </div>    

              </div>

            </div>
            <div class="form-group col-lg-6">
              <label>Marital Status</label>
              <div class="form-group">
                <div class="row">
                    <div class="col-lg-6 col-6">

                      <input type="radio" name="maritalstatus" formControlName="maritalstatus" [(ngModel)]='overviewData.maritalstatus' value="1" [checked]="overviewData.maritalstatus === true"> Married
                      </div>
                      <div class="col-lg-6 col-6">

                      <input type="radio" name="maritalstatus" formControlName="maritalstatus" [(ngModel)]='overviewData.maritalstatus' value="0" [checked]="overviewData.maritalstatus === false"> Single
                      </div>
                      </div>
                      
                  </div>
            </div>
          
          </div>

          <div class="row">
            <div class="form-group col-lg-6">
              <label>Nationality * </label>
              <select style="width:100%;" name="nationalityid" formControlName="nationalityid" class="form-control input-lg" 
                id="nationalityid" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" 
                [(ngModel)]="overviewData.nationalityid">
                <option [value]="defaultVal">Select Nationality</option>
                <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
              </select>
              <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                <div *ngIf="f.nationalityid.errors.required">Please Select Nationality</div>
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label>Country of Residence *</label>
              <select style="width:100%;" name="residenceid" formControlName="residenceid" class="form-control input-lg"
                id="residenceid" [ngClass]="{ 'is-invalid': submitted && f.residenceid.errors }" 
                [(ngModel)]="overviewData.residenceid">
                <option [value]="defaultVal">Select Country Of Residence</option>
                <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
              </select>
              <div *ngIf="submitted && f.residenceid.errors" class="invalid-feedback">
                <div *ngIf="f.residenceid.errors.required">Please Select Country of Residence</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6">
              <label>Country of Birth *</label>
              <select style="width:100%;" name="countryofbirthid" formControlName="countryofbirthid" 
                class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.countryofbirthid.errors }" 
                (change)="onSelectCountry($event.target.value)" [(ngModel)]="overviewData.countryofbirthid" id="countryofbirthid">
                <option [value]="defaultVal">Select Country Of Birth</option>
                <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
              </select>
              <div *ngIf="submitted && f.countryofbirthid.errors" class="invalid-feedback">
                <div *ngIf="f.countryofbirthid.errors.required">Please Select Country of Birth</div>
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label> Place of Birth *</label>
              <input type="placeofbirth" formControlName="placeofbirth" class="" id="placeofbirth"
                [ngClass]="{ 'is-invalid': submitted && f.placeofbirth.errors }" [(ngModel)]="overviewData.placeofbirth"
                name="placeofbirth" class="form-control">
              <div *ngIf="submitted && f.placeofbirth.errors" class="invalid-feedback">
                <div *ngIf="f.placeofbirth.errors.required">Please Enter Place of Birth</div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="form-group modal-footer">
        <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="Update" />
        <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModalOverview('custom-overview-1');" />

      </div>
    </div>
  </div>

</form>

</jw-modal>







<jw-modal id="custom-overview-2" class="modal" role="dialog">

<form [formGroup]="permanetForm" #permanentForm="ngForm" class="s12 white" (ngSubmit)="onSubmit2(permanentForm.value,'custom-overview-2')">

  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-header">
        <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" 
        [(ngModel)]="paddress.id">
        <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" 
        id="employeeid" [(ngModel)]="paddress.employeeid">

        <h5 class="modal-title" id="popupLabel">Permanent Address Details</h5>
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModalPaddress('custom-overview-2');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">

          <!-- <div class="row">
            <div class="form-group col-lg-6">
              <label>Mobile Number *</label>
              <international-phone-number formControlName="mobilenumber" class="form-control phonecontrol" [ngClass]="{ 'is-invalid': submitted && h.mobilenumber.errors }"
                [(ngModel)]="paddress.mobilenumber" placeholder="Enter mobile number" (input)="validatenumber($event.target.value,'mobile')"
                [maxlength]="20" [required]="true" #mobilenumber name="mobilenumber"></international-phone-number>
              <div *ngIf="submitted && h.mobilenumber.errors" class="invalid-feedback">
                <div *ngIf="h.mobilenumber.errors.required">Please Enter Mobile Number</div>
              </div>

              <span *ngIf="mobilecheck===false" style="color:red">Invalid format</span>
              <span *ngIf="mobilecheck===true" style="color:green">Valid format</span>

            </div>
            <div class="form-group col-lg-6">
              <label>Phone Number *</label>
              <international-phone-number formControlName="phonenumber" class="form-control phonecontrol" [ngClass]="{ 'is-invalid': submitted && h.phonenumber.errors }"
                [(ngModel)]="paddress.phonenumber" placeholder="Enter phone number" (input)="validatenumber($event.target.value,'phone')"
                [maxlength]="20" [required]="true" #phonenumber name="phonenumber"></international-phone-number>
              <div *ngIf="submitted && h.phonenumber.errors" class="invalid-feedback">
                <div *ngIf="h.phonenumber.errors.required">Please Enter Phone Number</div>
              </div>
              <span *ngIf="phonecheck===false" style="color:red">Invalid format</span>
              <span *ngIf="phonecheck===true" style="color:green">Valid format</span>

            </div>
          </div> -->

          <div class="row">

            <div class="form-group col-lg-6">
              <label>Postcode *</label>
              <input type="pincode" formControlName="pincode" class="" id="pincode" name="pincode" class="form-control pincode"
                [ngClass]="{ 'is-invalid': submitted && h.pincode.errors }" [(ngModel)]="paddress.pincode" (input)="onSearchChange($event.target.value)"
                matInput [matAutocomplete]="auto">
              <div *ngIf="submitted && h.pincode.errors" class="invalid-feedback">
                <div *ngIf="h.pincode.errors.required">Please Enter Postcode</div>
              </div>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of options" [value]="option" (onSelectionChange)="updatecode($event, option)">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </div>

            <div class="form-group col-lg-6">
              <label>Address Line 1 *</label>
              <input type="addressid1" formControlName="addressid1" class="" name="addressid1" id="addressid1"
                [ngClass]="{ 'is-invalid': submitted && h.addressid1.errors }" class="form-control" 
                [(ngModel)]="paddress.addressid1">
              <div *ngIf="submitted && h.addressid1.errors" class="invalid-feedback">
                <div *ngIf="h.addressid1.errors.required">Please Enter Address Line 1</div>
              </div>
            </div>

          </div>
          <div class="row">

            <div class="form-group col-lg-6">
              <label>Address Line 2</label>
              <input type="addressid2" formControlName="addressid2" class="" name="addressid2" id="addressid2" class="form-control"
                [(ngModel)]="paddress.addressid2">

            </div>

            <div class="form-group col-lg-6">
              <label>City *</label>
              <input type="cityname" formControlName="cityname" class="" name="cityname" id="cityname" [ngClass]="{ 'is-invalid': submitted && h.cityname.errors }"
                class="form-control" [(ngModel)]="paddress.cityname">
              <div *ngIf="submitted && h.cityname.errors" class="invalid-feedback">
                <div *ngIf="h.cityname.errors.required">Please Enter City</div>
              </div>
            </div>


          </div>
          <div class="row">
            <div class="form-group col-lg-6">
              <label>County Name *</label>
              <input type="cityname" formControlName="statename" name="statename" id="statename" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && h.statename.errors }" [(ngModel)]="paddress.statename">


              <div *ngIf="submitted && h.statename.errors" class="invalid-feedback">
                <div *ngIf="h.statename.errors.required">Please enter the County</div>
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label>Country *</label>
              <select style="width:100%;" formControlName="countryid" name="countryid" class="form-control input-lg"
                id="countryid" [ngClass]="{ 'is-invalid': submitted && h.countryid.errors }" (change)="onSelectCountry($event.target.value)"
                [(ngModel)]="paddress.countryid">
                <option [value]="defaultVal">Select Country</option>
                <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
              </select>
              <div *ngIf="submitted && h.countryid.errors" class="invalid-feedback">
                <div *ngIf="h.countryid.errors.required">Please Select the Country</div>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div class="modal-footer">


        <input type="submit" class="btn btn-primary com_btn" name="updateform" value="{{buttoninsert}}" />
        <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModalPaddress('custom-overview-2');" />

      </div>
    </div>
  </div>

</form>

</jw-modal>



<jw-modal id="custom-terminate-1" role="dialog" class="modal">
  <form [formGroup]="terminationForm" #terminationform="ngForm" class="s12 white"
      (ngSubmit)="onSubmitTermination(terminationform.value,'custom-terminate-1')">
      <div role="document" class="formgroup">
          <div class="modal-content">
              <div class="modal-header">
                  <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid"
                      id="employeeid" [(ngModel)]="terminationDetail.employeeid">
                  <h5 class="modal-title" id="popupLabel">Termination Details</h5>
                  <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                      (click)="closeModalTerminate('custom-terminate-1');">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                  <div class="container">
                      
                      <div class="row">
                        <div class="form-group col-lg-6">
                            <label>Termination Category*</label>
                            <select formControlName="termination_category" id="termination_category" name="termination_category" class="form-control"
                              (change)="changeSubcategory($event.target.value)" [ngClass]="{ 'is-invalid': submitted && g.termination_category.errors }"
                              [(ngModel)]="terminationDetail.termination_category">
                              <option value="">Select Category</option>
                              <option *ngFor="let cat of parentCategoryList" value="{{cat.id}}">{{cat.title}}</option>
                            </select>
                            <div *ngIf="submitted && g.termination_category.errors" class="invalid-feedback">
                                <div *ngIf="g.termination_category.errors.required">Please choose termination category</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Termination Subcategory*</label>
                            <select formControlName="termination_subcategory" name="termination_subcategory" [ngClass]="{ 'is-invalid': submitted && g.termination_subcategory.errors }" class="form-control"
                            [(ngModel)]="terminationDetail.termination_subcategory">
                              <option value="">Select Subcategory</option>
                              <option *ngFor="let subcat of subCategoryList" value="{{subcat.id}}">{{subcat.title}}</option>
                            </select>
                            <div *ngIf="submitted && g.termination_subcategory.errors" class="invalid-feedback">
                                <div *ngIf="g.termination_subcategory.errors.required">Please choose termination subcategory
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="row">
                          <div class="form-group col-lg-6">
                              <label>Eligible For Rehire*</label>
                              <select style="width:100%;" formControlName="oktorehire" name="oktorehire"
                                  id="oktorehire" class="form-control input-lg"
                                  [(ngModel)]="terminationDetail.oktorehire"
                                  [ngClass]="{ 'is-invalid': submitted && g.oktorehire.errors }">
                                  <option [value]="defaultVal">Select eligible for rehire</option>
                                  <option [value]="1">Yes </option>
                                  <option [value]="0">No</option>
                              </select>
                              <div *ngIf="submitted && g.oktorehire.errors" class="invalid-feedback">
                                  <div *ngIf="g.oktorehire.errors.required">Please select employement status</div>
                              </div>
                          </div>
                          <div class="form-group col-lg-6">
                              <label>Regret Termination*</label>
                              <select style="width:100%;" formControlName="regrettermination" name="regrettermination"
                                  id="regrettermination" class="form-control input-lg"
                                  [ngClass]="{ 'is-invalid': submitted && g.regrettermination.errors }"
                                  [(ngModel)]="terminationDetail.regrettermination">
                                  <option [value]="defaultVal">Select regret termination</option>
                                  <option [value]="1">Yes </option>
                                  <option [value]="0">No</option>
                              </select>
                              <div *ngIf="submitted && g.regrettermination.errors" class="invalid-feedback">
                                  <div *ngIf="g.regrettermination.errors.required">Please select regret termination
                                  </div>
                              </div>
                          </div>
                          
                      </div>
                      <div class="row">
                        <div class="form-group col-lg-6">
                            <label>Termination Date* </label>
                            <mat-form-field class="datecontrol">
                                <input matInput (click)="picker4.open()" [matDatepicker]="picker4" id="terminationdate"
                                    [ngClass]="{ 'is-invalid': submitted && g.terminationdate.errors }"
                                    name="terminationdate" formControlName="terminationdate"
                                    class="form-control noborder-invalid"
                                    [(ngModel)]="terminationDetail.terminationdate">
                                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                <mat-datepicker #picker4></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="submitted && g.terminationdate.errors" class="invalid-feedback">
                                <div *ngIf="g.terminationdate.errors.required">Please choose termination date</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-6">
                          <label>Remarks*</label>
                          <textarea type="text" formControlName="terminationreason" name="terminationreason"
                              id="terminationreason" class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && g.terminationreason.errors }"
                              [(ngModel)]="terminationDetail.terminationreason"></textarea>
                          <div *ngIf="submitted && g.terminationreason.errors" class="invalid-feedback">
                              <div *ngIf="g.terminationreason.errors.required">Please enter termination reason
                              </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Terminate" />
                  <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel"
                      (click)="closeModalTerminate('custom-terminate-1');" />
              </div>
          </div>
      </div>
  </form>
</jw-modal>

<jw-modal id="custom-update-notes" role="dialog" class="modal">
  <form [formGroup]="candidatenoteFormGroup" #candidatenoteForm="ngForm" class="s12 white" (ngSubmit)="updatecandidatenote(candidatenoteForm.value)">
   
      <div role="document" class="formgroup">
          <div class="modal-content">
              <div class="modal-header">
                <input type="hidden" formControlName="candidateid" class="form-control" name="candidateid" id="candidateid" [(ngModel)]="candidatenotesdetails.candidateid">
                <input type="hidden" formControlName="employeeid" class="form-control" name="employeeid" id="employeeid" [(ngModel)]="candidatenotesdetails.employeeid">
                
                  <h5 class="modal-title" id="popupLabel">Notes</h5>
                  <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                      (click)="closeModalTerminate('custom-update-notes');">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                  <div class="container">
                      <div class="row">
                          <div class="form-group col-lg-12">
                              <label>Notes </label>
                              <textarea type="text" placeholder="Write your note here (Maximum 500 characters)"   formControlName="notes" class="" name="notes" id="notes" class="form-control" [ngClass]="{ 'is-invalid': submitted && j.notes.errors }" [(ngModel)]="candidatenotesdetails.notes">

                              </textarea>
                  
                            <div *ngIf="submitted && j.notes.errors" class="invalid-feedback">
                                <div *ngIf="j.notes.errors.required">Please Enter Notes</div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-warning">Save Note</button>
                  <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel"
                      (click)="closeModalTerminate('custom-update-notes');" />
              </div>
          </div>
      </div>
  </form>
  <div class="notes_post"  >
    <div class="candidate_note" *ngFor="let candidate of candidatenotes">
      <span>{{candidate.createat | date }}</span>
      <p>{{ candidate.notes}} </p>
    </div>
    <!-- <div *ngIf="candidatenotes.length ==0">No Nots History Found</div> -->
  </div>
</jw-modal>
