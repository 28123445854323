import { Component, OnInit } from '@angular/core';
import { Fca } from '../models/fca.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FcasearchService } from '../services/fcasearch.service';

@Component({
  selector: 'app-registerfca',
  templateUrl: './registerfca.component.html',
  styleUrls: ['./registerfca.component.css']
})
export class RegisterfcaComponent implements OnInit {
  registerForm: FormGroup;

  search = new Fca();
  submitted = false;
  searchresult:any;
  // searchnewresult:any;




  constructor(
    private formBuilder: FormBuilder,private fcaService: FcasearchService,) { }

  ngOnInit() {


    
    this.registerForm = this.formBuilder.group({      
      companyname: ['', Validators.required],
      postcode: ['' ],
      searchtype: ['',  Validators.required]    
    });


  }

  get f() { return this.registerForm.controls; }

  onSubmit(){


    console.log(this.search);
    this.submitted = true;
  
    if (this.registerForm.invalid) { return; }

    this.fcaService.getFCAFirmcommonSearch(this.search).subscribe((data2: any) => {
      var searchnewresult;
      var searchresultff=[];
      var post=[];
      var name;
      var pincode='';
     for(let ss of data2)
     {
        searchnewresult ={};
        post = ss['Name'].split('Postcode:');
        if(post.length ==2)
        {
          pincode = post[1].replace(')','');
          console.log(post[1])
        }
        name = post[0].slice(0, -1);      

        searchnewresult.name = name;
        searchnewresult.status = ss['Status'];
        searchnewresult.refno = ss['Reference Number'];
        searchnewresult.type = ss['Type of business or Individual'];
        searchnewresult.postcode = pincode;

        searchresultff.push(searchnewresult);
    }


    this.searchresult =searchresultff;

      

      // console.log('hum1',data2);


  //   this.fcaService.getFCAFirmDetails(this.search).subscribe((data2: any) => {



  //     console.log('hum1',data2);
        
  //   this.fcaService.getFCAFirmName(this.search).subscribe((data2: any) => {
  //     console.log('hum2',data2);
  //   });
  //   this.fcaService.getFCAFirmIndividuals(this.search).subscribe((data2: any) => {
  //     console.log('hum3',data2);
  //   });
  //   this.fcaService.getFCAFirmRequirements(this.search).subscribe((data2: any) => {
  //     console.log('hum4',data2);
  //   });
  //   this.fcaService.getFCAFirmPermission(this.search).subscribe((data2: any) => {
  //     console.log(data2);
  //   });
  //   this.fcaService.getFCAFirmPassport(this.search).subscribe((data2: any) => {
  //     console.log(data2);
  //   });
  //   this.fcaService.getFCAFirmRegulators(this.search).subscribe((data2: any) => {
  //     console.log(data2);
  //   });
  //   this.fcaService.getFCAFirmAddress(this.search).subscribe((data2: any) => {
  //     console.log(data2);
  //   });
  //   this.fcaService.getFCAFirmAppointedRepresentative(this.search).subscribe((data2: any) => {
  //     console.log(data2);
  //   });
  //   this.fcaService.getFCAFirmWaivers(this.search).subscribe((data2: any) => {
  //     console.log(data2);
  //   });
  //   this.fcaService.getFCAFirmExclusions(this.search).subscribe((data2: any) => {
  //     console.log(data2);
  //   });
  //   this.fcaService.getFCAFirmDisciplinaryHistory(this.search).subscribe((data2: any) => {
  //     console.log(data2);
  //   });


  // });

    });


  }

}
