"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var WorklistService = /** @class */ (function () {
    function WorklistService(commonService) {
        this.commonService = commonService;
    }
    WorklistService.prototype.getemployeeworklist = function () {
        return this.commonService.formpostOnlyid('/employee/activity');
    };
    WorklistService.prototype.getemployeeworklistreport = function (data) {
        return this.commonService.formpost('/employee/activityreport', data);
    };
    WorklistService.prototype.getemployeeworklistreportrequest = function (data) {
        return this.commonService.formpost('/employee/activityreportrequest', data);
    };
    WorklistService.prototype.getemployeeworklistreportrequestbyadmin = function (data) {
        return this.commonService.formpost('/employee/activityreportrequestbyadmin', data);
    };
    WorklistService.prototype.settinghourtypeUpdate = function (value) {
        return this.commonService.formpost('/hourtypeupdatebyadmin', value);
    };
    WorklistService.prototype.gethourtypelist = function (data) {
        return this.commonService.formpost('/hourtype', data);
    };
    WorklistService.prototype.getprojectlist = function (data) {
        return this.commonService.formpost('/projectlist', data);
    };
    WorklistService.prototype.getlocationlist = function () {
        return this.commonService.formpostOnlyid('/companylocationtype');
    };
    WorklistService.prototype.activityapplyupdate = function (value) {
        return this.commonService.formpost('/employee/apply-hour', value);
    };
    WorklistService.prototype.getactivitydata = function (value) {
        return this.commonService.formpost('/employee/hourbyid', value);
    };
    WorklistService.prototype.getactivedatabydate = function (value) {
        return this.commonService.formpost('/employee/activitylistbydate', value);
    };
    WorklistService.prototype.deleteactivity = function (value) {
        return this.commonService.formpost('/employee/activitydelete', value);
    };
    WorklistService.prototype.statusactivity = function (value) {
        return this.commonService.formpost('/employee/activitystatus', value);
    };
    WorklistService.prototype.changeAllActivityStatus = function (value) {
        return this.commonService.formpost('/employee/changeallactivitystatus', value);
    };
    WorklistService.prototype.hourtypeUpdateChangeStatus = function (value) {
        return this.commonService.formpost('/company/hourtypeupdatechangestatus', value);
    };
    WorklistService.ngInjectableDef = i0.defineInjectable({ factory: function WorklistService_Factory() { return new WorklistService(i0.inject(i1.CommonService)); }, token: WorklistService, providedIn: "root" });
    return WorklistService;
}());
exports.WorklistService = WorklistService;
