import { Nationality } from './nationality.model';
import { Employee } from '../employee.model';

export class Passport {
  // passportprofilelist : any;
  sstatus: number;
  employeepassdeatilid: number;
  employeeid: number;
  passportnumber: string;
  visatype: string;
  passportissue: Date = new Date();
  passportexpiry: Date = new Date();
  placeofissue: string;
  nationality: Nationality;
  iscurrent: boolean;
  passportdocname:string;
  passportbackdocname:string;
  passportprofileimage:string;
  employee:Employee;
  ninumber:string;
}