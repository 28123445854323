"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var PassportaiwizardComponent = /** @class */ (function () {
    function PassportaiwizardComponent(activatedRoute) {
        this.activatedRoute = activatedRoute;
    }
    PassportaiwizardComponent.prototype.ngOnInit = function () {
        this.employeepassportdeatilid = this.activatedRoute.snapshot.paramMap.get("passportid");
    };
    return PassportaiwizardComponent;
}());
exports.PassportaiwizardComponent = PassportaiwizardComponent;
