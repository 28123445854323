"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_service_1 = require("../../services/employee.service");
var modal_service_1 = require("../../_services/modal.service");
var country_service_1 = require("../../services/country.service");
var permission_service_1 = require("../../services/permission.service");
var alert_service_1 = require("../../services/alert.service");
var checksystem_service_1 = require("../../services/checksystem.service");
var router_1 = require("@angular/router");
var dbs_service_1 = require("../../services/dbs.service");
var user_service_1 = require("../../services/user.service");
var profile_model_1 = require("../../models/overview/profile.model");
var common_service_1 = require("../../services/common.service");
var address_model_1 = require("../../models/address/address.model");
var DbsComponent = /** @class */ (function () {
    function DbsComponent(userService, employeeService, formBuilder, commonService, modalService, country, permissiondetails, alerts, check, router, route, dbsService) {
        var _this = this;
        this.userService = userService;
        this.employeeService = employeeService;
        this.formBuilder = formBuilder;
        this.commonService = commonService;
        this.modalService = modalService;
        this.country = country;
        this.permissiondetails = permissiondetails;
        this.alerts = alerts;
        this.check = check;
        this.router = router;
        this.route = route;
        this.dbsService = dbsService;
        this.th = [];
        this.options = [];
        this.empid = 0;
        this.emailexist = false;
        this.mindob = new Date();
        this.maxdob = new Date();
        this.empidexist = false;
        this.defaultVal = "";
        this.h1tag = "";
        this.activePage = 0;
        route.params.subscribe(function (params) {
            _this.dbstype = params['type'];
            if (_this.dbstype == 'basic') {
                _this.dbsId = 2;
                _this.h1tag = "Basic DBS";
            }
            else if (_this.dbstype == 'standard') {
                _this.dbsId = 3;
                _this.h1tag = "Standard DBS";
            }
            else if (_this.dbstype == 'enhanced') {
                _this.dbsId = 1;
                _this.h1tag = "Enhanced DBS";
            }
            else if (_this.dbstype == 'access-ni-basic') {
                _this.dbsId = 14;
                _this.h1tag = "AccessNI Basic DBS";
            }
            else if (_this.dbstype == 'access-ni-standard') {
                _this.dbsId = 15;
                _this.h1tag = "AccessNI Standard DBS";
            }
            else if (_this.dbstype == 'access-ni-enhanced') {
                _this.dbsId = 16;
                _this.h1tag = "AccessNI Enhanced DBS";
            }
            else if (_this.dbstype == 'scotland-basic-disclosure') {
                _this.dbsId = 17;
                _this.h1tag = "Scotland Basic Disclosure DBS";
            }
            else if (_this.dbstype == 'scotland-standard-disclosure') {
                _this.dbsId = 18;
                _this.h1tag = "Scotland Standard Disclosure DBS";
            }
            else if (_this.dbstype == 'scotland-enhanced-disclosure') {
                _this.dbsId = 19;
                _this.h1tag = "Scotland Enhanced Disclosure DBS";
            }
            else if (_this.dbstype == 'social-media') {
                _this.dbsId = 21;
                _this.h1tag = 'Social Media';
            }
            _this.getRemainingChecks();
        });
    }
    DbsComponent.prototype.displayActivePage = function (activePageNumber) {
        this.activePage = activePageNumber;
    };
    DbsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.addresslistDetail = new address_model_1.Address();
        this.Userid = localStorage.getItem('userid');
        this.inputbutton = 'Send Request';
        this.empid = 0;
        this.tp = 'first';
        this.empidexist = false;
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        //console.log(permission[0]['m114'])
        if ((this.dbsId == 2 && this.permission['m109'] < 1) || (this.dbsId == 3 && this.permission['m114'] < 1) || (this.dbsId == 1 && this.permission['m119'] < 1)) {
            this.router.navigate(['/permission-denied']);
        }
        this.check.getbillinginformation({ 'serviceid': this.dbsId }).subscribe(function (remaindata) {
            if (remaindata.totalcheck == 0) {
                _this.stage1 = 2;
            }
            if (remaindata.totalcheck > 0 && remaindata.remiancheck == 0) {
                _this.stage1 = 2;
            }
            if (remaindata.totalcheck > 0 && remaindata.remiancheck > 0) {
                _this.stage1 = 3;
            }
            _this.remainingcheck = remaindata;
        });
        this.getEmployees();
        this.formreset();
        this.overviewData = new profile_model_1.Profile();
        this.overviewData.phonenumber = "+44";
        this.mindob.setDate(this.mindob.getDate() - 23741);
        this.maxdob.setDate(this.maxdob.getDate() - 574);
        this.permissiondetails.bindallmenu().subscribe(function (menudata) {
            var permissionstring = "[{";
            var perarray = [];
            menudata.forEach(function (valuemenu) {
                perarray.push('"' + valuemenu.clname + '": 0');
            });
            var perstr = perarray.join();
            permissionstring = permissionstring + perstr + "}]";
            _this.permissionstring = permissionstring;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
    };
    Object.defineProperty(DbsComponent.prototype, "f", {
        get: function () { return this.employeeForm.controls; },
        enumerable: true,
        configurable: true
    });
    DbsComponent.prototype.getEmployees = function () {
        var _this = this;
        this.employeeService.getlistofemployeeForCheck().subscribe(function (employeedata) {
            console.log(employeedata);
            _this.record = employeedata.length;
            _this.datalist = employeedata;
            _this.pageOfItems = employeedata;
            _this.items = employeedata;
            if (_this.record < 30) {
                _this.pageOfItems = employeedata;
            }
            else {
                _this.items = employeedata;
            }
        });
    };
    DbsComponent.prototype.getRemainingChecks = function () {
        var _this = this;
        var ts = { 'serviceid': this.dbsId };
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            _this.checkcount = remaindata;
        });
    };
    DbsComponent.prototype.onAddEmployeeSubmit = function (employeeform1, modelid) {
        var _this = this;
        this.submitted = true;
        // console.log(employeeform1);
        if (this.employeeForm.invalid) {
            return;
        }
        //return;
        // console.log(this.empid)
        employeeform1.id = this.Userid;
        employeeform1.permission = this.permissionstring;
        this.userService.isEmailRegisterd(employeeform1.emailid).subscribe(function (data) {
            if (data.sussess == 'success' && _this.empid == 0) {
                _this.emailexist = true;
                _this.disableBtn = true;
            }
            else if (_this.empidexist == false && _this.empid == 0) {
                _this.disableBtn = false;
                _this.employeeService.checkemployeecreate(employeeform1).subscribe(function (dataw) {
                    if (dataw.susses == 'true') {
                        _this.modalService.close(modelid);
                        _this.empidexist = true;
                        _this.tp = 'second';
                        _this.getRemainingChecks();
                        // this.modalService.open('sendrequestcharge');
                        //  this.sendrequest(dataw.employeemasterid, 'sendrequestcharge');
                        _this.employeeid = dataw.employeemasterid;
                        _this.getEmployees();
                    }
                    else {
                        _this.alerts.error('Somthing went wrong. please try again after sometime');
                    }
                });
            }
            else if (_this.empidexist == false && _this.empid != 0) {
                _this.tp = 'second';
                //  console.log('dfdadfad')
            }
        });
    };
    DbsComponent.prototype.onSearchChange = function (searchValue) {
        var _this = this;
        if (searchValue.length > 4) {
            var newArray = [];
            this.commonService.getAddress(searchValue).subscribe(function (data) {
                var newdata;
                newdata = data;
                newdata.addresses.forEach(function (value) {
                    newArray.push(value + "," + searchValue);
                });
                _this.options = newArray;
            });
        }
        else {
            this.options = [];
        }
    };
    DbsComponent.prototype.updatecode = function (evt, response) {
        var _this = this;
        res = [];
        if (evt.source.selected) {
            var res = response.split(",");
            this.addresslistDetail.address1 = res[0];
            this.addresslistDetail.address2 = res[1];
            this.addresslistDetail.statename = res[6];
            this.addresslistDetail.cityname = res[5];
            var data = { 'id': this.addresslistDetail.countryid };
            this.country.getstatelist(data).subscribe(function (state) {
                for (var i = 0; i < state.length; i++) {
                    if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
                        var inde = state[i]['stateid'];
                    }
                }
                _this.addresslistDetail.stateid = inde;
                _this.statelist = state;
            });
            this.addresslistDetail.pincode = res[7];
        }
    };
    DbsComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    DbsComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    DbsComponent.prototype.onFocusOutEvent = function (event) {
        var _this = this;
        this.userService.isEmailRegisterdCheckForAdmin(event.target.value).subscribe(function (data) {
            if (data.sussess == 'success') {
                _this.emailexist = true;
                _this.disableBtn = false;
                _this.employeeid = data.employeeid;
                if (data.samecompany) {
                    _this.samecompany = true;
                }
                // this.isFocus = true;
                // this.modalService.open('alreadyadd-request');
            }
            else {
                _this.disableBtn = true;
            }
        });
    };
    DbsComponent.prototype.focusFunction = function () {
        this.emailexist = false;
    };
    DbsComponent.prototype.closeModal = function (id) {
        this.empid = 0;
        this.tp = 'first';
        this.modalService.close(id);
    };
    DbsComponent.prototype.sendrequestcharge = function (employee, id) {
        var _this = this;
        console.log('Full Form Data Master system', employee);
        var ts = { employeeid: this.empid, requestid: this.dbsId, fname: employee.fname, mname: employee.mname, lname: employee.lname, address1: employee.address1, address2: employee.address2, cityname: employee.cityname, statename: employee.statename, pincode: employee.pincode, countryid: employee.countryid };
        this.dbsService.createdbs(ts).subscribe(function (remaindata) {
            _this.check.getbillinginformation({ 'serviceid': _this.dbsId }).subscribe(function (remaindata) {
                _this.remainingcheck = remaindata;
                _this.inputbutton = 'Send Request';
                _this.empid = 0;
                _this.tp = 'first';
                _this.empidexist = false;
                _this.getRemainingChecks();
                _this.formreset();
                _this.employeeForm.reset();
                _this.alerts.success(_this.h1tag + ' Application Request Sent Successfully');
                _this.modalService.close(id);
            });
        });
    };
    DbsComponent.prototype.updaterequestcurrent = function (overview) {
        if (this.remainingcheck && this.remainingcheck.remiancheck < 1) {
            this.modalService.open('upgrade-plan');
        }
        else {
            this.overviewData = new profile_model_1.Profile();
            this.overviewData = overview;
            this.addresslistDetail = new address_model_1.Address();
            this.addresslistDetail = overview.permanent;
            this.empid = overview.employeemasterid;
            if (this.dbstype == 'basic') {
                this.inputbutton = 'Send Basic DBS Applicant';
            }
            else if (this.dbstype == 'standard') {
                this.inputbutton = 'Send Standard DBS Applicant';
            }
            else if (this.dbstype == 'enhanced') {
                this.inputbutton = 'Send Enhanced DBS Applicant';
            }
            else if (this.dbstype == 'social-media') {
                this.inputbutton = 'Send Social Media Request';
            }
            this.modalService.open('add-new-applicant');
        }
    };
    DbsComponent.prototype.sendrequest = function (employeeid, id) {
        if (this.remainingcheck && this.remainingcheck.remiancheck < 1) {
            this.modalService.open('upgrade-plan');
        }
        else {
            this.tp = 'second';
            this.modalService.open(id);
            this.employeedata = employeeid;
            this.empid = employeeid.employeemasterid;
        }
    };
    DbsComponent.prototype.uploadByAdmin = function (dbs, model_id) {
        if (this.remainingcheck && this.remainingcheck.remiancheck < 1) {
            this.modalService.open('upgrade-plan');
        }
        else {
            this.empid = 0;
            this.disableBtn = false;
            this.overviewData = new profile_model_1.Profile();
            this.overviewData.phonenumber = "+44";
            this.addresslistDetail = new address_model_1.Address();
            this.addresslistDetail.employeeaddresdeatilid = "0";
            this.addresslistDetail.countryid = "70";
            this.addresslistDetail.iscurrent = '0';
            this.phonecheck = "";
            this.formreset();
            this.modalService.open(model_id);
            // this.router.navigate(['/check-add-applicant', this.dbstype+'-dbs']);   
        }
    };
    DbsComponent.prototype.formreset = function () {
        this.employeeForm = this.formBuilder.group({
            fname: ['', forms_1.Validators.required],
            mname: [''],
            lname: ['', forms_1.Validators.required],
            empid: [''],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            phonenumber: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            address1: ['', forms_1.Validators.required],
            address2: [''],
            cityname: ['', forms_1.Validators.compose([forms_1.Validators.required,])],
            pincode: ['', forms_1.Validators.required],
            countryid: ['', forms_1.Validators.required],
            statename: ['', forms_1.Validators.compose([forms_1.Validators.required])]
        });
    };
    return DbsComponent;
}());
exports.DbsComponent = DbsComponent;
