"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var modal_service_1 = require("../../../_services/modal.service");
var job_service_1 = require("../../../services/job.service");
var candidate_service_1 = require("../../../services/candidate.service");
var common_service_1 = require("../../../services/common.service");
var applicant_service_1 = require("../../../services/applicant.service");
var forms_1 = require("@angular/forms");
var jobs_model_1 = require("../../../models/jobs/jobs.model");
var candidate_model_1 = require("../../../models/jobs/candidate.model");
var emailer_model_1 = require("../../../models/emailer.model");
var ClassicEditor = require("@ckeditor/ckeditor5-build-classic");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var alert_service_1 = require("../../../services/alert.service");
var platform_browser_1 = require("@angular/platform-browser");
var CandidatesComponent = /** @class */ (function () {
    function CandidatesComponent(sanitizer, alerts, uploadService, modalService, activatedRoute, job, router, applicantservices, candidate, formBuilder, commonService) {
        this.sanitizer = sanitizer;
        this.alerts = alerts;
        this.uploadService = uploadService;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.job = job;
        this.router = router;
        this.applicantservices = applicantservices;
        this.candidate = candidate;
        this.formBuilder = formBuilder;
        this.commonService = commonService;
        this.statusid = 0;
        this.jobDetail = new jobs_model_1.Jobs();
        this.emailerDetail = new emailer_model_1.Emailer();
        this.candidateDetail = new candidate_model_1.Candidate();
        this.documentDetail = new candidate_model_1.Document();
        this.Editor = ClassicEditor;
        this.jobdata = new jobs_model_1.Jobs();
        this.tempArr = { "skills": [] };
        this.currenDate = new Date();
        this.defaultVal = "";
    }
    CandidatesComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.jobid = this.activatedRoute.snapshot.paramMap.get("jobid");
        this.statusid = this.activatedRoute.snapshot.paramMap.get("statusid");
        this.ismail = false;
        this.getcandidateformgroup();
        this.candidatemessageFormGroup = this.formBuilder.group({
            candidateid: [''],
            jobid: [''],
            location: ['', forms_1.Validators.required],
            subject: ['', forms_1.Validators.required],
            message: [''],
            interviewstime: ['', forms_1.Validators.required],
            interviewetime: ['', forms_1.Validators.required],
            interdate: ['', forms_1.Validators.required],
            ismail: [''],
            ssid: ['']
        });
        this.emailerDetail = new emailer_model_1.Emailer();
        this.applicantservices.getcandidatestatus().subscribe(function (candidatestatuslistget) {
            _this.candidatestatuslist = candidatestatuslistget;
            console.log(candidatestatuslistget);
        });
        this.Userid = localStorage.getItem('userid');
        this.job.getjobById({ "id": this.Userid, "jobid": this.jobid }).subscribe(function (jobdata) {
            _this.jobdata = jobdata;
        });
        this.candidate.getcandidateList({ "id": this.Userid, "jobid": this.jobid, "cstatus": this.statusid }).subscribe(function (data) {
            _this.record = data.length;
            _this.pageOfItems = data;
            _this.datalist = data;
            // console.log(data);
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.items = data;
            }
            // this.data = data;
        });
        this.job.getjobskillList({ "id": this.Userid, "jobid": this.jobid }).subscribe(function (data) {
            var dataskillArray = [];
            data.forEach(function (skil) {
                dataskillArray.push({ 'jobskilldetailid': skil.jobskilldetailid, 'skillname': skil.skillname, 'check': false });
            });
            _this.skilllist = dataskillArray;
        });
        this.filename = 'Choose file';
    };
    CandidatesComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    CandidatesComponent.prototype.selectjoblistbystatus = function (sid) {
        var _this = this;
        this.statusid = sid;
        // console.log(sid);
        this.candidate.getcandidateList({ "id": this.Userid, "jobid": this.jobid, "cstatus": this.statusid }).subscribe(function (data) {
            _this.record = data.length;
            _this.pageOfItems = data;
            _this.datalist = data;
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.items = data;
            }
            // this.data = data;
        });
    };
    CandidatesComponent.prototype.getcandidateformgroup = function () {
        this.candidateformGroup = this.formBuilder.group({
            candidatemasterid: [''],
            jobid: [''],
            fname: ['', forms_1.Validators.required],
            mname: [''],
            lname: ['', forms_1.Validators.required],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            phonenumber: [''],
            joburlid: ['']
        });
    };
    Object.defineProperty(CandidatesComponent.prototype, "f", {
        get: function () { return this.candidateformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CandidatesComponent.prototype, "g", {
        get: function () { return this.candidatemessageFormGroup.controls; },
        enumerable: true,
        configurable: true
    });
    CandidatesComponent.prototype.onSubmit = function (value3, id) {
        var _this = this;
        value3.userid = this.Userid;
        value3.skills = this.tempArr;
        this.submitted = true;
        if (this.candidateformGroup.invalid) {
            return;
        }
        // console.log(value3);
        value3.smarks = 100 * (Number(this.tempArr.skills.length) / Number(this.skilllist.length));
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            // console.log(this.file);
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") && (filetype[filetype.length - 1].toLowerCase() != "gif") && (filetype[filetype.length - 1].toLowerCase() != "tif") && (filetype[filetype.length - 1].toLowerCase() != "pdf") && (filetype[filetype.length - 1].toLowerCase() != "doc") && (filetype[filetype.length - 1].toLowerCase() != "docx") && (filetype[filetype.length - 1].toLowerCase() != "msword")) {
                this.fileformaterror = true;
                // console.log(filetype[filetype.length - 1]);
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.cvname = imagepath;
        }
        else {
            // value3.cvname = this.candidateDetail.cvname;
            value3.cvname = "";
        }
        this.disableBtn = false;
        this.candidate.candidatedetailsCreate(value3).subscribe(function (data) {
            _this.candidate.getcandidateList({ "id": _this.Userid, "jobid": _this.jobid }).subscribe(function (data) {
                _this.record = data.length;
                _this.datalist = data;
                _this.pageOfItems = data;
                if (_this.record < 30) {
                    _this.pageOfItems = data;
                }
                else {
                    _this.items = data;
                }
            });
        });
        this.alerts.success('Applicant details added successfully.', true);
        this.filename = "Choose file";
        this.modalService.close(id);
    };
    CandidatesComponent.prototype.statusChange = function (candidatestatusmaster, candidate) {
        var _this = this;
        if (confirm("Are you sure you want to change status?")) {
            this.candidate.candidatestatusUpdateOnly({ "candidateid": candidate.candidatemasterid, "sid": candidatestatusmaster }).subscribe(function (data) {
                _this.candidate.getcandidateList({ "id": _this.Userid, "jobid": _this.jobid }).subscribe(function (data) {
                    _this.record = data.length;
                    _this.pageOfItems = data;
                    _this.datalist = data;
                    if (_this.record < 30) {
                        _this.pageOfItems = data;
                    }
                    else {
                        _this.items = data;
                    }
                    _this.alerts.success('Applicant details updated successfully.', true);
                });
            });
        }
    };
    CandidatesComponent.prototype.submitStatus = function (emailerForm, modelid) {
        var _this = this;
        if (this.candidatemessageFormGroup.invalid) {
            return;
        }
        this.candidate.candidatestatusdetailsUpdate(emailerForm).subscribe(function (data) {
            _this.candidate.getcandidateList({ "id": _this.Userid, "jobid": _this.jobid }).subscribe(function (data) {
                _this.record = data.length;
                _this.pageOfItems = data;
                _this.datalist = data;
                if (_this.record < 30) {
                    _this.pageOfItems = data;
                }
                else {
                    _this.items = data;
                }
                _this.modalService.close(modelid);
                _this.alerts.success('Applicant details updated successfully.', true);
            });
        });
    };
    CandidatesComponent.prototype.onChangeCategory = function (event, cat) {
        if (event.target.checked == true) {
            if (this.tempArr.skills.indexOf(cat.jobskilldetailid) === -1) {
                this.tempArr.skills.push(cat.jobskilldetailid);
            }
        }
        else if (event.target.checked == false) {
            this.tempArr.skills.pop(cat.jobskilldetailid);
        }
    };
    CandidatesComponent.prototype.onChangeinterested = function (sid, candidateid) {
        var _this = this;
        var data = { "sid": sid, "candidateid": candidateid };
        // console.log(data);
        this.candidate.candidatestatusinterested(data).subscribe(function (data) {
            _this.candidate.getcandidateList({ "id": _this.Userid, "jobid": _this.jobid, "cstatus": _this.statusid }).subscribe(function (data) {
                _this.record = data.length;
                _this.pageOfItems = data;
                _this.datalist = data;
                _this.alerts.success('Applicant status updated successfully', true);
                if (_this.record < 30) {
                    _this.pageOfItems = data;
                }
                else {
                    _this.items = data;
                }
                // this.data = data;
            });
        });
    };
    CandidatesComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    CandidatesComponent.prototype.selectFile = function (event) {
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") && (filetype[filetype.length - 1].toLowerCase() != "gif") && (filetype[filetype.length - 1].toLowerCase() != "tif") && (filetype[filetype.length - 1].toLowerCase() != "pdf") && (filetype[filetype.length - 1].toLowerCase() != "doc") && (filetype[filetype.length - 1].toLowerCase() != "docx") && (filetype[filetype.length - 1].toLowerCase() != "msword")) {
            this.fileformaterror = true;
        }
        else {
            this.fileformaterror = false;
        }
    };
    CandidatesComponent.prototype.openModal = function (candidateid, modelid) {
        var _this = this;
        this.disableBtn = true;
        this.tempArr = { "skills": [] };
        this.candidate.getcandidateById({ "id": this.Userid, "candidateid": candidateid }).subscribe(function (data) {
            var candskills = [];
            data.skill.forEach(function (ele) {
                candskills.push(ele.jobskilldetailid);
            });
            var allskill = _this.skilllist;
            var newSkillArray = [];
            allskill.forEach(function (element) {
                if (candskills.includes(element.jobskilldetailid.toString()) == false) {
                    newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': false });
                }
                else {
                    _this.tempArr.skills.push(element.jobskilldetailid);
                    newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': true });
                }
            });
            _this.skilllist = newSkillArray;
            _this.candidateDetail = data;
        });
        this.buttoninsert = "Update";
        this.modalService.open(modelid);
    };
    CandidatesComponent.prototype.openModal3 = function (candidateid, modelid) {
        var _this = this;
        this.disableBtn = true;
        this.tempArr = { "skills": [] };
        this.candidate.getcandidateById({ "id": this.Userid, "candidateid": candidateid }).subscribe(function (data) {
            var candskills = [];
            data.skill.forEach(function (ele) {
                candskills.push(ele.jobskilldetailid);
            });
            var allskill = _this.skilllist;
            var newSkillArray = [];
            allskill.forEach(function (element) {
                if (candskills.includes(element.jobskilldetailid.toString()) == false) {
                    newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': false });
                }
                else {
                    _this.tempArr.skills.push(element.jobskilldetailid);
                    newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': true });
                }
            });
            _this.skilllist = newSkillArray;
            _this.candidateDetail = data;
        });
        this.buttoninsert = "Update";
        this.modalService.open(modelid);
    };
    CandidatesComponent.prototype.openModal4 = function (candidateid, modelid) {
        var _this = this;
        this.disableBtn = true;
        this.candidate.getcandidateById({ "id": this.Userid, "candidateid": candidateid }).subscribe(function (data) {
            _this.candidateDetail = data;
        });
        this.modalService.open(modelid);
    };
    CandidatesComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    CandidatesComponent.prototype.openModal5 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    CandidatesComponent.prototype.openModal6 = function (id, sid, candidateid, jobid) {
        var _this = this;
        this.buttoninsert = "Change Status";
        this.disableBtn = true;
        this.applicantservices.getemailertemplate({ "candidateid": candidateid, "jobid": jobid, "sid": sid }).subscribe(function (edata) {
            _this.emailerDetail = edata;
            _this.emailerDetail.candidateid = candidateid;
            _this.emailerDetail.jobid = jobid;
            _this.emailerDetail.location = "";
            _this.emailerDetail.interdate = "";
            _this.emailerDetail.interviewetime = "";
            _this.emailerDetail.interviewstime = "";
            _this.modalService.open(id);
        });
    };
    CandidatesComponent.prototype.openModal7 = function (id, sid, data) {
        this.disableBtn = true;
        this.modalService.open(id);
    };
    CandidatesComponent.prototype.deleteFile = function (id, candidateid) {
        var _this = this;
        var deleteData = { 'id': id };
        if (confirm("Are you sure you want to delete?")) {
            this.candidate.deleteFile(deleteData).subscribe(function (data) {
                _this.candidate.getcandidateById({ "id": _this.Userid, "candidateid": candidateid }).subscribe(function (data) {
                    _this.candidateDetail = data;
                });
            });
        }
    };
    CandidatesComponent.prototype.openModal2 = function (id) {
        var _this = this;
        this.disableBtn = true;
        this.submitted = false;
        this.tempArr = { "skills": [] };
        this.candidateDetail = new candidate_model_1.Candidate();
        this.getcandidateformgroup();
        this.job.getjobskillList({ "id": this.Userid, "jobid": this.jobid }).subscribe(function (data) {
            var dataskillArray = [];
            data.forEach(function (skil) {
                dataskillArray.push({ 'jobskilldetailid': skil.jobskilldetailid, 'skillname': skil.skillname, 'check': false });
            });
            _this.skilllist = dataskillArray;
            _this.candidateDetail.candidatemasterid = 0;
            _this.candidateDetail.jobid = _this.jobid;
            _this.candidateDetail.joburlid = "";
            // console.log(this.candidateDetail);
            _this.filename = 'Choose file';
            _this.buttoninsert = "Submit";
            _this.phonecheck = "";
            _this.modalService.open(id);
        });
    };
    CandidatesComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return CandidatesComponent;
}());
exports.CandidatesComponent = CandidatesComponent;
