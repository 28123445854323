import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Employee } from '../../../models/employee.model';
import { CountryService } from '../../../services/country.service';
import { EmployeeService } from '../../../services/employee.service';
import { EmployeementService } from '../../../services/employeement.service';
import { JobService } from '../../../services/job.service';
import { ModalService } from '../../../_services/modal.service';
import { ProjectService } from '../../../services/project.service';
import { ExpenseService } from '../../../services/expense.service';
import { ExcelService } from '../../../services/excel.service';
import { AppGlobals } from '../../../app.global';
import { getMonth, getYear } from 'date-fns';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-viewexpensereport',
  templateUrl: './viewexpensereport.component.html',
  styleUrls: ['./viewexpensereport.component.css']
})
export class ViewexpensereportComponent implements OnInit {
  EmployeeForm: FormGroup;
  employeeDetail = new Employee();
  employeelist: any;
  countrylist: any;
  coslist: any;
  data: any;
  joblist: any;
  projectlist: any;
  expenselist: any;
  exceldata = [];
  employeeexpensedata: any;
  graphdisplay: any;
  listdisplay: any;
  public searchText: string;
  exptype: any;
  record: any;
  notedetails: any;
  empid: any;
  employeemonth: any;
  employeeyear: any;
  hstatus: any;
  Userid: any;
  items: Array<any>;
  pageOfItems: Array<any>;
  yearList: any;

  constructor(
    private excelService: ExcelService,
    private formBuilder: FormBuilder,
    private project: ProjectService,
    private expense: ExpenseService,
    private country: CountryService,
    private jobser: JobService,
    private employee: EmployeeService,
    private employeementreport: EmployeementService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) { 
    route.params.subscribe(params => {
      if(params['id']){
        this.empid = params['id'];
      }
    });
    route.queryParams.subscribe(params => {
      this.employeemonth = params['month'];
      this.employeeyear = params['year'];
      this.hstatus = params['status'];
      this.exptype = params['type'];
    });
  }

  ngOnInit() {
    this.employeemonth = getMonth(new Date()) + 1;
    this.yearList = AppGlobals.YEAR_VALUES;
    this.employeeyear = getYear(new Date());
    this.graphdisplay = "block";
    this.listdisplay = "none";
    this.EmployeeForm = this.formBuilder.group({
      emailid: [''],
      phonenumber: [''],
      ninumber: [''],
      minstartdate: [''],
      maxstartdate: [''],
      minenddate: [''],
      maxenddate: [''],
      expensetypeid: [''],
      minamount: [''],
      maxamount: [''],
      projectid: [''],
      fname: [''],
      hstatus: [''],
      searctext: ['']
    });
    // var values3 = {};
    // this.expense.getexpenseList(values3).subscribe((expenseDetails: any) => {
    //   this.data = expenseDetails;
    // });

    this.getexpensereport();
    this.Userid = localStorage.getItem('userid');
    const sstatustype = { 'status': true, 'id': this.Userid }
    this.project.getprojectlist(sstatustype).subscribe((projectdetails: any) => {
      this.projectlist = projectdetails;
    });

    var value3 = "{}";
    this.employee.getemployeelist(value3).subscribe((listdetails: any) => {
      this.employeelist = listdetails;
    });

    this.expense.getexpenseType(sstatustype).subscribe((expensedetails: any) => {
      this.expenselist = expensedetails;
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  showcondition(type: any) {
    if (type == 'list') {
      this.graphdisplay = "none";
      this.listdisplay = "block";
    }
    else {
      this.listdisplay = "none";
      this.graphdisplay = "block";
    }
  }

  exportAsXLSX(): void {
    this.exceldata = [];
    this.data.forEach(obj => {
      if (obj.paidby == '1') {
        var paidby = "Company Paid";
      }
      else if (obj.paidby == '2') {
        paidby = "Client Paid";
      }
      else if (obj.paidby == '3') {
        paidby = "Employee Paid";
      }
      var typename = "";
      if (obj.expensetype) {
        typename = obj.expensetype.typename;
      }
      this.employeeexpensedata = {
        "Name": obj.employee.fname + " " + obj.employee.lname,
        "Project": obj.project.projectname,
        "Expense Type": typename,
        "Paid By": paidby,
        "Date": this.datePipe.transform(new Date(obj.startdate),"dd MMM yyyy"),       
        "Currency Type": obj.currency,
        "Amount": obj.amountpaid,
        "Location": obj.locationname
      };
      this.exceldata.push(this.employeeexpensedata);
    });
    this.excelService.exportAsExcelFile(this.exceldata, 'sample');
  }

  onSubmit(value3: any) {
  }

  getexpensereport() {
    this.expense.getexpenseList({ 'year': this.employeeyear, 'month': this.employeemonth, 'employeeid': this.empid, 'hstatus': this.hstatus, 'expensetypeid': this.exptype }).subscribe((expenseDetails: any) => {
      this.record = expenseDetails.length;
      this.data = expenseDetails;
      this.record = this.data.length;

      if (this.record < 30) {
        this.pageOfItems = this.data;
      }
      else {
        this.items = this.data;
      }
    });
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  openModalnote(data: any, id: any) {
    this.notedetails = data;
    this.modalService.open(id);
  }

  changeEmployee(id: any) {
    this.empid = id;
    this.getexpensereport();
  }

  changeEmployeeMonth(month: any) {
    this.employeemonth = month;
    this.getexpensereport();
  }

  changeEmployeeYear(year: any) {
    this.employeeyear = year;
    this.getexpensereport();
  }

  changeEmployeestatus(hstatus: any) {
    this.hstatus = hstatus;
    this.getexpensereport();
  }

  changeExpenseType(exptype: any) {
    this.exptype = exptype;
    this.getexpensereport();
  }
}
