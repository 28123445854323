"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var AicenterService = /** @class */ (function () {
    function AicenterService(commonService) {
        this.commonService = commonService;
    }
    AicenterService.prototype.getcountpayment = function () {
        return this.commonService.formpostOnlyid('/company/countaiinformation');
    };
    AicenterService.prototype.getDocumentcountpayment = function () {
        return this.commonService.formpostOnlyid('/company/countDocumntaiinformation');
    };
    AicenterService.prototype.getAilogType = function (data) {
        return this.commonService.formpost('/company/allailogreport', data);
    };
    AicenterService.prototype.approveAiDocument = function (data) {
        return this.commonService.refereeformpost('/api/documentapproverejectbyadmin', data);
    };
    AicenterService.prototype.approveAisuperDocument = function (data) {
        return this.commonService.refereeformpost('/api/documentapproverejectbysuperadmin', data);
    };
    AicenterService.prototype.getDocumentImages = function (data) {
        return this.commonService.refereeformpost('/api/getdocumentimagesbylogid', data);
    };
    AicenterService.ngInjectableDef = i0.defineInjectable({ factory: function AicenterService_Factory() { return new AicenterService(i0.inject(i1.CommonService)); }, token: AicenterService, providedIn: "root" });
    return AicenterService;
}());
exports.AicenterService = AicenterService;
