import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { error } from 'util';
import { User } from '../../models/user.model';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../../services/customer.service';
import { AlertService } from '../../services/alert.service';
// import { User} from '../../shared/user.model';
// import { EmailValidator } from '@angular/forms';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit {
  registerForm: FormGroup;
  loginSSOFormGroup: FormGroup;
  submitted = false;
  isLoginError = false;
  alertService: any;
  // loading: boolean;
  user = new User();
  access_token = '';
  userid = '';
  name = '';
  isline = '';
  planid = '';
  error = '';
  isemail = '';
  ismobile = '';
  isadmin = '';
  ispermission = '';
  ctype = '';
  loginform:any = false;
  ssoform:any = false;
  issubmitinprogress: boolean = false;

  constructor(
    private formBuilder: FormBuilder, 
    private userService: UserService, 
    private router: Router, 
    private alerts: AlertService, 
    private csservices: CustomerService
  ) { }

  ngOnInit() {
    this.loginform = true;
    var userid = localStorage.getItem('userid');
    if(userid){
      this.userService.checkaccesstokenbyid(userid).subscribe((data: any) => {
        if(data.status == false){
          localStorage.removeItem('userToken');
          localStorage.removeItem('userid');
          localStorage.removeItem('Name');
          localStorage.removeItem('isline');
          localStorage.removeItem('planid');
          localStorage.removeItem('isemail');
          localStorage.removeItem('ismobile');
          localStorage.removeItem('isadmin');
          localStorage.removeItem('ispermission');
          localStorage.removeItem('ctype');
          localStorage.removeItem('logoname');
          localStorage.removeItem('companyid');
          localStorage.removeItem('struser');
          localStorage.removeItem('menu');          
        } else{
          var isemail = localStorage.getItem('isemail');
          var ismobile = localStorage.getItem('ismobile');      
          var struser = localStorage.getItem('struser');      
          this.ctype = localStorage.getItem('ctype');
          var planid =  localStorage.getItem('planid');      

          if (isemail=='false' && ismobile == 'false'){            
             this.router.navigate(['/otp']);
          } else if (planid == '0' && this.ctype == '0' ){
            this.router.navigate(['choose-plan']);
          } else if (struser == '' && this.ctype == '1' ){
            this.router.navigate(['payment-confirmation']);
          } else if (struser == '' && this.ctype == '2' ){
            this.router.navigate(['payment-confirmation']);
          } else if (struser == '' && this.ctype == '3' ){
            this.router.navigate(['payment-confirmation']);
          } else if (this.ctype == '0' ){
            this.router.navigate(['dashboard']);
          } else if(this.ctype == '1' ){
            this.router.navigate(['check-dashboard']);
          } else if(this.ctype == '2' ){
            this.router.navigate(['check-dashboard']);
          } else if(this.ctype == '3' ){
            this.router.navigate(['reference-dashboard']);
          }        
        }     
      });      
    }
    this.registerForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
    this.loginSSOFormGroup = this.formBuilder.group({
      "email": ["", [Validators.required, Validators.email]]
    });
  }

  get f() { 
    return this.registerForm.controls;
  }

  get k(){
    return this.loginSSOFormGroup.controls
  }

  ssoclick(){
    this.loginform =false;
    this.ssoform=true;
  }

  loginclick(){
    this.loginform =true;
    this.ssoform=false;
  }

  loginssoUser() {

    this.submitted = true;


    console.log("fail", this.loginSSOFormGroup.invalid);

    if (this.loginSSOFormGroup.invalid) {
      return;
    }

    console.log("success", this.submitted);


    if(this.issubmitinprogress)
      return;
    else
      this.issubmitinprogress = true;

    this.loginSSOFormGroup.value.username = this.loginSSOFormGroup.value.email

    if (this.loginSSOFormGroup.valid) {
      this.userService.ssoUser(this.loginSSOFormGroup.value).subscribe((response: any) => {
        this.issubmitinprogress = false;
        console.log(response.login_url);
        if(response.status){
          window.open(response.login_url,"_self");
        } else{
          this.alerts.error(response.message, true);
        }
      });
    }


  }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.userService.userAuthentication(this.user).subscribe((data: any) => {
      localStorage.setItem('userToken', this.access_token);
      localStorage.setItem('userid', this.userid);
      localStorage.setItem('Name', this.name);
      localStorage.setItem('isline', this.isline);
      localStorage.setItem('planid', this.planid);
      localStorage.setItem('ctype', this.ctype);
      localStorage.setItem('isemail', this.isemail);
      localStorage.setItem('ismobile', this.ismobile);
      localStorage.setItem('isadmin', data.isadmin);
      localStorage.setItem('ispermission', data.ispermission);
      localStorage.setItem('logoname', data.logourl);
      localStorage.setItem('struser', data.struser);
      localStorage.setItem('isverify', data.isverify);
      localStorage.setItem('companyid', data.companyid);
      localStorage.setItem('menu', '0');



      if (data.sussess == 'true'){
        if (data.estatus == false){
          this.alerts.error('Your account is inactive. Contact your administrator to activate it', true);
          return;
        }

        localStorage.setItem('userToken', data.id);
        localStorage.setItem('userid', data.userid);
        localStorage.setItem('emptype', data.emptype);
        localStorage.setItem('isline', data.isline);
        localStorage.setItem('planid', data.planid);
        localStorage.setItem('isemail', data.isemail);
        localStorage.setItem('ismobile', data.ismobile);
        localStorage.setItem('isadmin', data.isadmin);
        localStorage.setItem('ispermission', data.ispermission);
        localStorage.setItem('logoname', data.logourl);
        localStorage.setItem('ctype', data.ctype);
        localStorage.setItem('struser', data.struser);
        localStorage.setItem('companyid', data.companyid);
        localStorage.setItem('menu', '0');
        localStorage.setItem('isverify', data.isverify);


        if (data.isemail == false){
          localStorage.setItem('otp', data.otp);
        }
        if(data.ismobile == false){
          localStorage.setItem('otpmobile', data.otpmobile);
        }

        this.csservices.getOverview().subscribe((data6: any) => {
          var lname = '';
          if (data6.lname != null){
            lname = data6.lname;
          }
          localStorage.setItem('Name', data6.fname + ' ' + lname);
          this.alerts.success('Welcome to Complygate', true);
          this.router.navigate(['/' + data.url]);






          // if(data.ctype == 0){
          //   this.router.navigate(['/' + data.url]);
          // }
          // else  if(data.ctype == 2){
          //   if (data.isemail == false){
          //     this.router.navigate(['/otp']);
          //   }
          //   this.router.navigate(['/' + data.url]);
          // }
          // else
          // {
          //     if (data.isemail == false){
          //         this.router.navigate(['/otp']);
          //     }
          //     else
          //     {
          //       if(data.stuser == ''){
          //         this.router.navigate(['/payment-confirmation']);
          //       }else{                
          //         this.router.navigate(['/check-dashboard']);
          //       }
          //     }
          // }

          
        });
      } else{
        this.alerts.error(data.error, true);
      }
    },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
      });
  }
}