<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
      <div class="tabs">
        <div class="tabs__content noshadow">
          <div class="tabs__content__item nobg active-tab">
            <div class="fca_search_box">


              <form [formGroup]="registerForm" #loginForm (ngSubmit)="onSubmit()">
                <div class="fca_search_form">
                  <div class="search_filed">
                    <input type="text" formControlName="companyname" #companyname [(ngModel)]="search.companyname"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.companyname.errors }"
                    placeholder="Enter a name or reference number" [maxlength]="200">
                    
                    
                    <i class="fa fa-search"></i>

                    <div *ngIf="submitted && f.companyname.errors" class="invalid-feedback">
                      <div *ngIf="f.companyname.errors.required">Please enter company name</div>
                  </div>

                  </div>
                  <div class="search_filed">
                   
                  <input type="text" formControlName="postcode" #postcode [(ngModel)]="search.postcode"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.companyname.errors }"
                    placeholder="Postcode or town (optional)" [maxlength]="20">                  

                    <i class="fa fa-map-marker"></i>
                  </div>
                  <div class="search_radio">
                    <div class="form-check form-check-inline">
                      <input type="radio" name="searchtype" formControlName="searchtype"  class="form-check-input"
                      [ngModel]='search.searchtype' value="0">

                      <!-- <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"> -->
                      <label class="form-check-label" for="inlineRadio1">Firms</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input type="radio" name="searchtype" formControlName="searchtype"  class="form-check-input"
                      [ngModel]='search.searchtype' value="1">
                      
                      <!-- <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"> -->
                      <label class="form-check-label" for="inlineRadio1">Individuals</label>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary">Search</button>
                </div>
              </form>



            </div>
            <div class="content">





            


              <div class="fca_list_box" *ngFor="let comresult of searchresult">
                <div class="iconstext width50">
                  <i class="fa fa-home"></i>
                  <h4><a [routerLink]="['/individualsfcadetails-fca',comresult.refno]" >{{ comresult.name}}</a></h4>
                </div>
                <div class="fca_list">
                  <p>Reference number</p>
                  <span>{{comresult.refno}}</span>
                </div>
                <div class="fca_list">
                  <p>Type of business</p>
                  <span>{{ comresult.type}}</span>
                </div>
                <div class="fca_list">
                  <p>Post Code</p>
                  <span>{{ comresult.postcode}}</span>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
