import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppGlobals } from '../../../app.global';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Passport } from '../../../models/passport/passport.model';
import { Employee } from '../../../models/employee.model';
import { MypassportService } from '../../../services/mypassport.service';
import { CommonService } from '../../../services/common.service';
import { MyvisaService } from '../../../services/myvisa.service';
import { AicenterService } from '../../../services/aicenter.service';
import { AlertService } from '../../../services/alert.service';
import { ChecksystemService } from '../../../services/checksystem.service';

@Component({
  selector: 'app-passportreviewai',
  templateUrl: './passportreviewai.component.html',
  styleUrls: ['./passportreviewai.component.css']
})

export class PassportreviewaiComponent implements OnInit {
  submitted: any;
  visapopForm: FormGroup;
  visa: Passport;
  permission: any;
  requestperm: any;
  record: number;
  ispayment: boolean;
  aipayment: boolean = false;
  employeemasterid: any;
  logoimage3: any;
  passportdetails = new Passport();
  employee = new Employee();
  logoimage2: any;
  logoimage: any;
  logoimage4:any;
  data3: any;
  data4: any;
  employeevisadeatilid: any;
  ctype:number;
  remainingcheck: any;

  constructor(private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private visaService: MyvisaService,
    private passportService: MypassportService,
    private activatedRoute: ActivatedRoute,
    private check: ChecksystemService,    
    private aicenter: AicenterService,
    private common: CommonService,
    private alerts: AlertService,
    private router: Router) { }

  ngOnInit() {
    this.ispayment = true;
    var permission = localStorage.getItem('ispermission');
    this.ctype  = parseInt(localStorage.getItem('ctype'));

    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;
    this.employeevisadeatilid = this.activatedRoute.snapshot.paramMap.get("passportid");
    this.data3 = { "employeepassdeatilid": this.employeevisadeatilid }
    this.passportService.passportdetailsbypassportid(this.data3).subscribe((data: any) => {
      this.logoimage2 = data.passportdocname;
      this.logoimage4 = data.passportbackdocname;
      this.logoimage3 = data.passportprofileimage;
      this.employeemasterid = data.employee.employeemasterid;
      this.passportdetails = data;
      this.record = data.length;
    });

    this.check.getbillinginformation({ 'serviceid': 7 }).subscribe((remaindata: any) => { 
      this.remainingcheck = remaindata.remiancheck;
    });

    // this.aicenter.getcountpayment().subscribe((data: any) => {
    //   this.aipayment = data.payment;
    // });

    this.data4 = { "employeid": this.employeemasterid }
    this.passportService.passportaipaymentstaus(this.data4).subscribe((data5: any) => {
      var ct = data5.count;
      if (ct == 0) {
        this.ispayment = false;
      } else {
        this.ispayment = true;
      }
    });
  }

  Sendvisa() {
    this.data3 = { "employeepassdeatilid": this.employeevisadeatilid }
    this.passportService.passportdetailsbypassportid(this.data3).subscribe((data: any) => {
      var countryiso = data.nationality.isothree;
      this.submitted = true;
      
      var idtype = 'PASSPORT';     
      var visalog = {
        employeeid: this.employeemasterid,
        documentid: data.employeepassdeatilid,
        documenturl: data.imagename,
        documenttype: 'p'
      };
  
      this.visaService.visaailogupdate(visalog).subscribe((dbvisaresult: any) => {     

        if(dbvisaresult.success =='1'){
          var data = { 
            merchantIdScanReference:dbvisaresult.logid,
            country : countryiso,
            idType : idtype,
            customerId: this.employeemasterid,
            callbackUrl: AppGlobals.API_ENDPOINT +"/api/aiconfirmation",
            documenturl: data.imagename,
            documentbackurl: data.imagebackname,
            imageurl: data.passportprofileimage
          };
          
          this.visaService.visadocumentupdate(data).subscribe((data5: any) => {
            if (data5.success == true) {
              this.router.navigate(['/passportaithanks/' + this.employeevisadeatilid]);
            } else {
              this.alerts.error("something went wrong please try again");
            }
      
          });
          }else {
            this.alerts.error("something went wrong please try again");
          }
      });
    
    });
  }
  
  Sendvisa_old() {
    this.getBase64ImageFromUrl(this.logoimage2)
      .then(result => this.facetime(result))
      .catch(err => console.error(err));
  }

  facetime(imagebase64) {
    this.getBase64ImageFromUrl(this.logoimage3)
      .then(result => this.sendtoAI(imagebase64, result))
      .catch(err => console.error(err));
  }

  sendtoAI(imagebase64, faceimage64) {
    var fullpathArray = this.logoimage2.split('.');

    var eximage = fullpathArray[fullpathArray.length - 1]; 
    var faceimage = fullpathArray[fullpathArray.length - 1]; 

    var data = { merchantIdScanReference:this.employeemasterid , frontsideImage :imagebase64, faceImage: faceimage64, country: 'uk', idType:'PASSPORT', frontsideImageMimeType: 'image/'+eximage ,faceImageMimeType: 'image/'+faceimage, customerId: 'fadsfas',
    callbackUrl: "https://www.complygate.co.uk/aiconfirmation", firstName: this.passportdetails.employee.fname, lastName: this.passportdetails.employee.lname, expiry: this.passportdetails.passportexpiry, number:this.passportdetails.passportnumber   }
    

  //  console.log(this.common.formpostForAI("https://lon.netverify.com/api/netverify/v2/performNetverify",data));
   
   this.router.navigate(['/passportaithanks', this.employeevisadeatilid ]);

    //console.log(data);
  }

  /************ AI Required Base64 bit  ***********/

  transform(html) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  }

  closeModal(id) {
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      // console.log(blob);
      reader.readAsDataURL(blob);
    })
  }
}