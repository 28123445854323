
<div class="overviewsshow">
    <div class="header">
    <h1 class="headprofile mt-0">Address Proof </h1>
    <a (click)="openModal2('custom-idproof-1')" class="addscreensuccess">Add Address Proof</a>
    </div>
    <div class="row">
        <!-- <div  class="form-check col-lg-1">&nbsp;</div> -->
        <div *ngFor="let filterItem of listcheck" class="form-check col-lg-3">
            <label class="form-check-label">
                <input type="radio" [checked]="filterItem.selected" (change)="onlistchange($event,filterItem.id)"
                    value="filterItem.id"> {{ filterItem.name }}  
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 idprofheight" *ngFor="let vs of idprooflist">
            <div class="border-box">
                <div class="overview_details emp">
                    <div class="profile_dtailsshow visastaus mb-3">
                        <div class="detailsshow">
                            <span class="badge badge-danger" *ngIf="vs.stype == '3'"><i class="fa fa-times" aria-hidden="true"></i> Rejected</span>
                            <span class="badge badge-warning" *ngIf="vs.stype == '1'"><i class="fa fa-hourglass-end" aria-hidden="true"></i> Pending</span>
                            <span class="badge badge-success" *ngIf="vs.stype == '2'"><i class="fa fa-check" aria-hidden="true"></i> Approved</span>
                        </div>
                        <div class="icons_box_section "> 
                            <a (click)="openModal('custom-idproof-1',vs)" class="editprime" *ngIf="vs.stype == '1'"><i class="lnr lnr-pencil"></i></a>
                            <a (click)="onDelete(vs.employeeidproofdeatilid)" class="deletered" *ngIf="vs.stype == '1'"><i class="lnr lnr-trash"></i></a>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3">
                            <span>Document Number</span>
                            <p>{{vs.idnumber}}</p>
                        </div>
                        <div class="detailsshow width50 pr-3">
                            <span>Document Type</span>
                            <p>{{vs.documenttype.documentname }}</p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3 mt-0">
                            <span>Expiry Date</span>
                            <p>{{vs.docexpiry | date: 'dd MMM yyyy'}}</p>
                        </div>
                        <div class="detailsshow width50 pr-0 mt-0">
                            <span>Issuing Country</span>
                            <p>{{vs.nationality.countryname}}</p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3 mt-0" *ngIf="vs.iddocName">
                            <span>View Address Proof </span>
                            <p><a (click)="openModalidproof('pdf-image-viewer-myidproofsystem',vs.iddocName,vs.idbackdocName)" *ngIf="vs.iddocName || vs.idbackdocName"><i class="fa fa-picture-o mg-r-10" ></i>&nbsp;</a></p>
                        </div>
                        <!-- <div class="detailsshow width50 pr-3 mt-0" *ngIf="vs.idbackdocName">
                            <span>View Back Address Proof </span>
                            <p><a (click)="openModalidproof('pdf-image-viewer-myidproofsystem',vs.idbackdocName)"><i class="fa fa-picture-o mg-r-10" ></i>&nbsp;</a></p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="job_list_section visa_request_section" *ngIf="!idprooflist?.length > false">
        <div class="job_detils_date">
            <p class="job_profile">No Record Found</p>
        </div>
    </div> 
</div>


<jw-modal id="custom-idproof-1" role="dialog" class="modal">
    <form [formGroup]="idproofpopForm" #idproofForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(idproofForm.value,'custom-idproof-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="idproofDetail.employeeid">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="idproofDetail.employeeidproofdeatilid">
                    <h5 class="modal-title" id="popupLabel">Address Proof Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-idproof-1');">
                        <span aria-hidden="true">&times;</span>
                      </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Document Number*</label>
                                <input type="idnumber" formControlName="idnumber" name="idnumber" id="idnumber" 
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.idnumber.errors }" 
                                    [ngModel]="idproofDetail.idnumber" maxlength="30">
                                <div *ngIf="submitted && f.idnumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.idnumber.errors.required">Please enter document number</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Document Type*</label>
                                <select style="width:100%;" formControlName="idtype" name="idtype" id="idtype" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.idtype.errors }" [ngModel]="idproofDetail.idtype">
                                    <option [value]="defaultVal">Select Document Type</option>
                                    <option *ngFor="let ad3 of documenttype" [value]="ad3.id">{{ ad3.documentname }}</option>
                                </select>
                                <div *ngIf="submitted && f.idtype.errors" class="invalid-feedback">
                                    <div *ngIf="f.idtype.errors.required">Please select document type</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Expiry Date*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker2" id="docexpiry" name="docexpiry" formControlName="docexpiry" [ngClass]="{ 'is-invalid': submitted && f.docexpiry.errors }" class="form-control noborder-invalid datecontrol" [ngModel]="idproofDetail.docexpiry">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.docexpiry.errors" class="invalid-feedback">
                                    <div *ngIf="f.docexpiry.errors.required">Please choose expiry date</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Issuing Country*</label>
                                <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [ngModel]="idproofDetail.nationalityid">
                              <option [value]="defaultVal">Select Country </option>
                              <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                            </select>
                                <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                    <div *ngIf="f.nationalityid.errors.required">Please select issuing country</div>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            
                            <div class="col-lg-6">
                                <label>Front Side Document Image Upload*</label>
                                <div class="input-group mb-3" (click)="file.click()">
                                    <div class="custom-file">
                                        <input type="file" #file (change)="selectFile($event)">
                                        <label class="custom-file-label">{{filename}}</label>
                                    </div>
                                </div>
                                <div class="invalid-feedback ng-star-inserted">
                                    <div class="" *ngIf="filerequirederror">Front side Document image is required</div>
                                    <div class="" *ngIf="filesizeerror">File size must be less than 2 MB</div>
                                    <div class="" *ngIf="fileformaterror">Please choose a valid file format (jpg, png)</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label>Back Side Document Image Upload</label>
                                <div class="input-group mb-3" (click)="fileback.click()">
                                    <div class="custom-file">
                                        <input type="file" #fileback (change)="selectbackFile($event)">
                                        <label class="custom-file-label">{{filebackname}}</label>
                                    </div>
                                </div>
                                <div class="invalid-feedback ng-star-inserted">
                                    <div class="" *ngIf="filebackrequirederror">Back side Document image is required</div>
                                    <div class="" *ngIf="filebacksizeerror">File size must be less than 2 MB</div>
                                    <div class="" *ngIf="filebackformaterror">Please choose a valid file format (jpg, png)</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-idproof-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>

<jw-modal id="pdf-image-viewer-myidproofsystem" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-myidproofsystem');">
                        <span aria-hidden="true">&times;</span>
                      </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <!-- <div class="col-lg-12">
                            <iframe *ngIf="imgidproofsrc == false" [src]="iframeidproofsrc" width="100%" height="600" frameborder="0"></iframe>
                            <img *ngIf="imgidproofsrc == true" [src]="iframeidproofsrc" width="100%" />
                        </div> -->
                        <div class="modal-body">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6" [ngClass]="!iframeidproofbacksrc ?'col-lg-12':'col-lg-6'">
                                        <div class="overlaytext">
                                            <p>Front Image</p>
                                        </div>
                                        <iframe *ngIf="imgidproofsrc == false" [src]="iframeidproofsrc" width="100%" height="600" frameborder="0"></iframe>
                                        <img *ngIf="imgidproofsrc == true" [src]="iframeidproofsrc" width="100%" />
                                    </div>
                                    <div class="col-lg-6" *ngIf="iframeidproofbacksrc">
                                        <div class="overlaytext">
                                            <p>Back Image</p>
                                        </div>
                                        <iframe *ngIf="imgidproofbacksrc == false" [src]="iframeidproofbacksrc" width="100%" height="250" frameborder="0"></iframe>
                                        <img *ngIf="imgidproofbacksrc == true" [src]="iframeidproofbacksrc" width="100%" />
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>