"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var modal_service_1 = require("../../../_services/modal.service");
var router_1 = require("@angular/router");
var alert_service_1 = require("../../../services/alert.service");
var checksystem_service_1 = require("../../../services/checksystem.service");
var dbs_service_1 = require("../../../services/dbs.service");
var profile_model_1 = require("../../../models/overview/profile.model");
var employee_service_1 = require("../../../services/employee.service");
var user_service_1 = require("../../../services/user.service");
var permission_service_1 = require("../../../services/permission.service");
var common_service_1 = require("../../../services/common.service");
var DbsHistoryComponent = /** @class */ (function () {
    function DbsHistoryComponent(userService, employeeService, modalService, formBuilder, commonService, router, route, check, permissiondetails, alerts, dbsService) {
        var _this = this;
        this.userService = userService;
        this.employeeService = employeeService;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.commonService = commonService;
        this.router = router;
        this.route = route;
        this.check = check;
        this.permissiondetails = permissiondetails;
        this.alerts = alerts;
        this.dbsService = dbsService;
        this.h1tag = "";
        this.emailexist = false;
        route.params.subscribe(function (params) {
            _this.dbstype = params['type'];
            if (_this.dbstype == 'basic') {
                _this.h1tag = "Basic";
            }
            else if (_this.dbstype == 'standard') {
                _this.h1tag = "Standard";
            }
            else if (_this.dbstype == 'enhanced') {
                _this.h1tag = "Enhanced";
            }
            else if (_this.dbstype == 'access-ni-basic') {
                _this.h1tag = "AccessNI Basic";
            }
            else if (_this.dbstype == 'access-ni-standard') {
                _this.h1tag = "AccessNI Standard";
            }
            else if (_this.dbstype == 'access-ni-enhanced') {
                _this.h1tag = "AccessNI Enhanced";
            }
            else if (_this.dbstype == 'scotland-basic-disclosure') {
                _this.h1tag = "Scotland Basic Disclosure";
            }
            else if (_this.dbstype == 'scotland-standard-disclosure') {
                _this.h1tag = "Scotland Standard Disclosure";
            }
            else if (_this.dbstype == 'scotland-enhanced-disclosure') {
                _this.h1tag = "Scotland Enhanced Disclosure";
            }
        });
    }
    DbsHistoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.requestperm = this.permission.m15;
        var id = localStorage.getItem('userToken');
        this.Userid = localStorage.getItem('userid');
        this.employeeForm = this.formBuilder.group({
            fname: ['', forms_1.Validators.required],
            mname: [''],
            lname: ['', forms_1.Validators.required],
            empid: [''],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            phonenumber: ['', forms_1.Validators.compose([forms_1.Validators.required])]
        });
        this.overviewData = new profile_model_1.Profile();
        this.overviewData.phonenumber = "+44";
        if (this.dbstype == 'basic') {
            this.dbsid = 2;
        }
        else if (this.dbstype == 'standard') {
            this.dbsid = 3;
        }
        else if (this.dbstype == 'enhanced') {
            this.dbsid = 1;
        }
        else if (this.dbstype == 'access-ni-basic') {
            this.dbsid = 14;
            this.h1tag = "AccessNI Basic";
        }
        else if (this.dbstype == 'access-ni-standard') {
            this.dbsid = 15;
            this.h1tag = "AccessNI Standard";
        }
        else if (this.dbstype == 'access-ni-enhanced') {
            this.dbsid = 16;
            this.h1tag = "AccessNI Enhanced";
        }
        else if (this.dbstype == 'scotland-basic-disclosure') {
            this.dbsid = 17;
            this.h1tag = "Scotland Basic Disclosure";
        }
        else if (this.dbstype == 'scotland-standard-disclosure') {
            this.dbsid = 18;
            this.h1tag = "Scotland Standard Disclosure";
        }
        else if (this.dbstype == 'scotland-enhanced-disclosure') {
            this.dbsid = 19;
            this.h1tag = "Scotland Enhanced Disclosure";
        }
        this.check.getbillinginformation({ 'serviceid': this.dbsid }).subscribe(function (remaindata) {
            _this.remainingcheck = remaindata;
        });
        this.check.getbillinginformation({ 'serviceid': this.dbsid }).subscribe(function (remaindata) {
            _this.ramainingChecks = remaindata;
        });
        this.getAllDbsHistory();
        this.permissiondetails.bindallmenu().subscribe(function (menudata) {
            var permissionstring = "[{";
            var perarray = [];
            menudata.forEach(function (valuemenu) {
                perarray.push('"' + valuemenu.clname + '": 0');
            });
            var perstr = perarray.join();
            permissionstring = permissionstring + perstr + "}]";
            _this.permissionstring = permissionstring;
        });
    };
    DbsHistoryComponent.prototype.getAllDbsHistory = function () {
        var _this = this;
        var data = { 'dbstype': this.dbsid };
        this.dbsService.getalldbsListbyadmin(data).subscribe(function (data) {
            _this.record = data.length;
            _this.pageOfItems = data;
            _this.datalist = data;
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.items = data;
            }
        });
    };
    Object.defineProperty(DbsHistoryComponent.prototype, "f", {
        get: function () { return this.employeeForm.controls; },
        enumerable: true,
        configurable: true
    });
    DbsHistoryComponent.prototype.onAddEmployeeSubmit = function (employeeform1, modelid) {
        var _this = this;
        this.submitted = true;
        console.log(this.employeeForm);
        if (this.employeeForm.invalid) {
            return;
        }
        this.disableBtn = false;
        employeeform1.id = this.Userid;
        employeeform1.permission = this.permissionstring;
        this.userService.isEmailRegisterd(employeeform1.emailid).subscribe(function (data) {
            if (data.sussess == 'success') {
                _this.emailexist = true;
                _this.disableBtn = true;
            }
            else {
                _this.disableBtn = false;
                _this.employeeService.checkemployeecreate(employeeform1).subscribe(function (dataw) {
                    if (dataw.susses == 'true') {
                        _this.modalService.close(modelid);
                        // this.modalService.open('sendrequestcharge');
                        _this.sendrequest(dataw.employeemasterid, 'sendrequestcharge');
                        _this.employeeid = dataw.employeemasterid;
                    }
                    else {
                        _this.alerts.error('Somthing went wrong. please try again after sometime');
                    }
                });
            }
        });
    };
    DbsHistoryComponent.prototype.sendrequestcharge = function (employeeid, id) {
        var _this = this;
        var ts = { employeeid: employeeid, requestid: this.dbsid };
        this.dbsService.createdbs(ts).subscribe(function (remaindata) {
            _this.getAllDbsHistory();
            _this.alerts.success(_this.h1tag + ' Request Sent Successfully');
            _this.modalService.close(id);
        });
    };
    DbsHistoryComponent.prototype.sendrequest = function (employeeid, id) {
        if (this.remainingcheck && this.remainingcheck.remiancheck < 1) {
            this.modalService.open('upgrade-plan');
        }
        else {
            this.modalService.open(id);
            this.employeeid = employeeid;
        }
    };
    DbsHistoryComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    DbsHistoryComponent.prototype.focusFunction = function () {
        this.emailexist = false;
    };
    DbsHistoryComponent.prototype.onFocusOutEvent = function (event) {
        var _this = this;
        this.userService.isEmailRegisterdCheckForAdmin(event.target.value).subscribe(function (data) {
            if (data.sussess == 'success') {
                _this.emailexist = true;
                _this.disableBtn = false;
                _this.employeeid = data.employeeid;
                if (data.samecompany) {
                    _this.samecompany = true;
                }
                // this.isFocus = true;
                // this.modalService.open('alreadyadd-request');
            }
            else {
                _this.disableBtn = true;
            }
        });
    };
    DbsHistoryComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    DbsHistoryComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    DbsHistoryComponent.prototype.uploadByAdmin = function (dbs, model_id) {
        if (this.ramainingChecks && this.ramainingChecks.remiancheck < 1) {
            this.modalService.open('upgrade-plan');
        }
        else {
            this.modalService.open(model_id);
            // this.router.navigate(['/check-add-applicant', this.dbstype+'-dbs']);   
        }
    };
    return DbsHistoryComponent;
}());
exports.DbsHistoryComponent = DbsHistoryComponent;
