import { Residence } from './residence.model';
import { Nationality } from './nationality.model';
import { Countryofbirth } from './countryofbirth.model';

export class Profile {
  nationality: Nationality;
  countryofbirth: Countryofbirth;
  dateofbirth: string;
  placeofbirth: string;
  hiredate: string;
  username:any;
  employeeid: string;
  fname: string;
  mname: string;
  lname: string;
  gender: boolean;
  emprole: string;
  ismail: boolean;
  maritalstatus: boolean;
  emailid: string;
  phonenumber: string;
  residence: Residence;
  imagename: string;
  jobid: string;
}

export class Termination {
  id: string;
  employeeid: string;
  terminationdate: string;
  terminationreason: string;
  oktorehire: string;
  regrettermination: string;
  termination_category: string;
  termination_subcategory: string;
}

export class righttowork {
  startdate: Date;
  starttime:string ="";
  endtime:string ="";
}