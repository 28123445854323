<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
      <div class="tabs" id="tabs">
        <div class="tabs__content">
          <div class="tabs__content__item active-tab">
            <div class="content">
              <div class="row row-sm topheader">                            
                <div class="col-lg-5 text-right">
                  <div class="checkshow_btn">
                    <button type="button" class="btn com_btn btn-primary" *ngIf="checkcount">
                      Total check <span class="badge badge-light">{{ checkcount.totalcheck }}</span>
                    </button>
                    <button type="button" class="btn com_btn btn-danger"  *ngIf="checkcount">
                      Used check<span class="badge badge-light">{{ checkcount.usedcheck }}</span>
                    </button>
                    <button type="button" class="btn com_btn btn-dark"  *ngIf="checkcount">
                      Remaining check <span class="badge badge-light">{{ checkcount.remiancheck }}</span>
                    </button>
                  </div>

                  
                  <!-- <app-checkcountshow (getRemainingChecks)="getRemainingChecks()" ></app-checkcountshow> -->
                </div>
                <div class="col-lg-7 text-right">
                  <div class="dflexflex mb-mm justify-content-end align-items-center">
                    <button class="boxbtn mainheading_btn ml-1" (click)="uploadByAdmin(dbstype, 'add-new-applicant')"> + Add New Applicant </button>
                    <button class="btn com_btn btn-success" [routerLink]="['/dbs-history/'+dbstype]"  type="button">
                      <span class="lnr lnr-history pr-1">                                        
                      </span> {{ h1tag }}  History 
                    </button>
                  </div>                             
                </div>
              </div>
                <div class="row">
                  <div class="col-sm-12 aligncenter mb-3">
                    <h5 class="hedingcolor">{{ h1tag }} Checks</h5>                              
                    <!-- <input type="text" [(ngModel)]="searchText" class="form-control width35" placeholder="Search"> -->
                  </div>
              </div> 
              <div class="browser-screen-loading-content" *ngIf="!pageOfItems" >
                  <div class="loading-dots dark-gray">
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>                                
                      <i></i>
                  </div>
                  <p>Loading</p>
              </div>


              
               <ng-container *ngIf="( datalist | filter: {employeeid: searchText, emprole: searchText, fname: searchText, mname: searchText, lname: searchText, emailid: searchText, phonenumber: searchText }) as pageOfItems">  
                   <div class="job_list_section employee_list_section pb-2 pt-2 oddeven"
                    *ngFor="let employee of pageOfItems; let i=index" >
                    <div class="job_detils_date width22">
                      <p class="job_address">Name</p>
                        <h5 class="job_profile font_17">{{employee.fname}} {{employee.mname}}
                            {{employee.lname}}</h5>
                    </div>
                    <div class="job_detils_date width20">
                        <p class="job_address">Email</p>
                        <p  class="job_profile">{{employee.emailid}}</p>
                    </div>
                    <div class="job_detils_date width15">
                        <p class="job_address">Phone Number</p>
                        <p class="job_profile">{{employee.phonenumber}}</p>
                    </div>


                    
                    <div class="job_detils_date width20" *ngIf="!employee.permanent">
                      <p class="job_address" >Address 1</p>
                      <p class="job_profile" >NA</p>
                    </div>
                    <div class="job_detils_date width10"  *ngIf="!employee.permanent">
                      <p class="job_address" >Post Code</p>
                      <p class="job_profile" >NA</p>
                    </div>


                    <div class="job_detils_date width20" *ngIf="employee.permanent">
                      <p class="job_address" >Address 1</p>
                      <p class="job_profile" *ngIf="employee.permanent.addressid1">{{employee.permanent.addressid1}}</p>
                      <p class="job_profile" *ngIf="!employee.permanent.addressid1">NA</p>
                    </div>

                    <div class="job_detils_date width10"  *ngIf="employee.permanent">
                      <p class="job_address" >Post Code</p>
                      <p class="job_profile" *ngIf="employee.permanent.pincode">{{employee.permanent.pincode}}</p>
                      <p class="job_profile" *ngIf="!employee.permanent.pincode">NA</p>

                    </div>
                   

                      <div class="job_detils_date  width10 justify-content-end d-flex aligncenter">
                        <a  class="btn btn-warning com_btn small_btn" (click)="updaterequestcurrent(employee)" >{{ inputbutton }}</a>                               

                           <!-- <a  class="btn btn-warning com_btn small_btn" (click)="sendrequest(employee,'sendrequestcharge')" >New Request</a>                                 -->
                          <!-- <button class="btn btn-primary com_btn small_btn" (click)="uploadByAdmin(emptype, 'upload_now', employee.employeemasterid)">Upload Now</button> -->
                       </div>                               
                </div>
                <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                    <div class="job_detils_date">
                        <p class="job_profile">No Record Found</p>
                    </div>
                </div>   
                
              <div class="row" style="display: none;">                   
                  <div class="col-lg-12 text-right">
                    <jw-pagination [items]="pageOfItems" [pageSize]="3" [maxPages]="10"  (changePage)="onChangePage($event)"></jw-pagination>
                  </div>
              </div>    
              </ng-container>



              <!-- Start Old DBS Check-->
                <!-- <div class="job_list_section visa_request_section text-center" >

                <div class="width100" *ngIf="stage1 ==1">
                  <p class="job_profile text-center mb-1 mt-3">Please buy {{ h1tag }}  DBS check.</p>
                  <p class="job_profile text-center mb-1">We'll send your Login details of our online DBS eBroker System on your registered email.</p>
                  <p class="job_profile">Where you can create applicants and submit Online DBS checks.</p>
                    <div class="dbs_flex">
                        <a [routerLink]="['/check-add-credit']" class="option_show">
                          <span>Add Checks</span>
                          <span class="lnr lnr-arrow-right"></span>
                        </a>
                    </div>
                </div>
                <div class="width100" *ngIf="stage1 ==2">
                  <p class="job_profile text-center mb-1 mt-3">Please buy {{ h1tag }}  DBS check.</p>
                  <p class="job_profile text-center">Please continue to use the online DBS eBroker System after you have made the purchase.</p>
                  <div class="dbs_flex" >
                    <a [routerLink]="['/check-add-credit']" class="option_show">
                      <span>Add Checks</span>
                      <span class="lnr lnr-arrow-right"></span>
                    </a>
                  </div>   
                </div>


                 <div class="width100" *ngIf="stage1 ==3">
                  <p class="job_profile text-center mb-1 mt-3">Please buy {{ h1tag }}  DBS check.</p>
                  <p class="job_profile text-center">Please continue to use the online DBS eBroker System after you have made the purchase.</p>
                    <div class="dbs_flex" >
                      <a [routerLink]="['/check-add-credit']" class="option_show">
                          <span>Buy {{ h1tag }} DBS Check</span>
                          <span class="lnr lnr-arrow-right"></span>
                      </a>
                      <a href="https://secure.mapitandgo.co.uk/sites/cbs/ebulk2000/index.php?C=COMPLYGATE&LP=https://secure.mapitandgo.co.uk/sites/complygate/index.html" target="_blank" class="option_show">
                        <span>Login to Complygate DBS Portal</span>
                        <span class="lnr lnr-arrow-right"></span>
                    </a>
                  </div>
                 </div>  -->

                 <!-- End Old DBS Check-->
                
                
                    <!-- <div class="job_detils_date" *ngIf="stage1 ==1">
                          <h5></h5>
                          <p class="job_profile">Please buy {{ h1tag }}  DBS check.</p>
                          <p class="job_profile">We'll send your Login details of our online DBS eBroker System on your registered email.</p>
                          <p class="job_profile">Where you can create applicants and submit Online DBS checks.</p>
                          <a  [routerLink]="['/check-add-credit']"><button class="btn btn-primary btn-block btn-signin">Add Checks</button></a>
                    </div>
                    <div class="job_detils_date" *ngIf="stage1 ==2">
                      <h5></h5>
                      <p class="job_profile">Please buy {{ h1tag }}  DBS check.</p>
                      <p class="job_profile">Please continue to use the online DBS eBroker System after you have made the purchase.</p>
                      <a  [routerLink]="['/check-add-credit']"><button class="btn btn-primary btn-block btn-signin">Add Checks</button></a>
                      
                    </div>

                    <div class="job_detils_date" *ngIf="stage1 ==3">
                      <h5></h5>
                      <p class="job_profile">Please buy {{ h1tag }}  DBS check.</p>
                      <p class="job_profile">Please continue to use the online DBS eBroker System after you have made the purchase.</p>
                      
                      <a  [routerLink]="['/check-add-credit']"><button class="btn btn-primary btn-block btn-signin">Add Checks</button></a>

                      <a  href="https://secure.mapitandgo.co.uk/sites/cbs/ebulk2000/index.php?C=COMPLYGATE&LP=https://secure.mapitandgo.co.uk/sites/complygate/index.html" target="_blank"><button class="btn btn-primary btn-block btn-signin">Login to Complygate DBS Portal</button></a>
                    </div> -->
                <!-- </div>                     -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
  <div role="document" class="formgroup">
    <div class="modal-content">         
      <div class="modal-body">
        <div class="container text-center">
          <div class="info_mess">
            <div class="para">
              <h4 class="mb-2">No credits available</h4>
              <p class="mb-0 font20"> There are no credits available to perform this action. Please add more credits.</p>
            </div>                          
          </div>
        </div>
      </div>
      <div class="modal-footer text-center">
        <input type="button" class="btn btn-primary com_btn" name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
        <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
      </div>
    </div>
  </div>
</jw-modal>



<jw-modal id="sendrequestcharge" role="dialog" class="modal sendrequestcharge">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-body p-4">
        <div class="container text-center">
          <div class="info_mess">
            <div class="para">
              <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
              <p class="mb-0 font20">The employee will receive an email and they need to upload the document.</p>
            </div>                          
          </div>
        </div>
      </div>
      <div class="modal-footer text-center">
        <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Confirm"  (click)="sendrequestcharge(employeedata,'sendrequestcharge')"  />
        <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('sendrequestcharge');"/>
      </div>
    </div>
  </div>
</jw-modal>




<jw-modal id="send-request" role="dialog" class="modal">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
            (click)="closeModal('upgrade-plan');">
            <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="form-group col-lg-6">
              <div class="row">You have exceeded the limit to request to Right to check or AI verification. Please add more credit go to billing section.</div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6">
              <div class="row">You have exceeded the limit to request to Right to check or AI verification. Please add more credit go to billing section.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <input type="button" class="btn btn-primary" name="updateform" value="Send Request" />
      </div>
    </div>
  </div>
</jw-modal>



<jw-modal id="add-new-applicant" role="dialog" class="modal">
  <div role="document" class="formgroup"  [ngStyle]="{'display': (tp=='first') ? 'block' : 'none'}">
    <div class="modal-content">
      <div class="modal-header">
          <h5 class="modal-title"> Add New Applicant</h5>
          <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
              (click)="closeModal('add-new-applicant');">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>

      <form [formGroup]="employeeForm" #overviewForm="ngForm" class="s12 white"  (ngSubmit)="onAddEmployeeSubmit(overviewForm.value,'add-new-applicant')">
    
        <div class="modal-body">
          <div class="container">
            <div class="formgroup">


              <div class="row">
                <div class="form-group col-lg-4">
                  <label>First Name*</label>
                  <input type="fname" formControlName="fname" name="fname" id="fname" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" [attr.disabled]="empid !=0 ? '' : null"
                      [(ngModel)]="overviewData.fname" maxlength="50">
                  <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                      <div *ngIf="f.fname.errors.required">Please enter first name</div>
                  </div>
                </div>
                <div class="form-group col-lg-4">
                  <label>Middle Name</label>
                  <input type="mname" formControlName="mname" name="mname" id="mname" class="form-control"
                        [(ngModel)]="overviewData.mname" maxlength="50"   [attr.disabled]="empid !=0 ? '' : null">
                </div>
                <div class="form-group col-lg-4">
                  <label>Last Name*</label>
                  <input type="lname" formControlName="lname" name="lname" id="lname" [attr.disabled]="empid !=0 ? '' : null"
                      class="form-control input-lg"
                      [ngClass]="{ 'is-invalid': submitted && f.lname.errors }"
                      [(ngModel)]="overviewData.lname" maxlength="50">
                  <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                      <div *ngIf="f.lname.errors.required">Please enter last name</div>
                  </div>
                </div>
              </div>
      
              <div class="row">
                <div class="form-group col-lg-6">
                  <label>Email*</label>
                  <input type="emailid" (focusout)="onFocusOutEvent($event)"  (focus)="focusFunction()" maxlength="100"
                      formControlName="emailid" name="emailid" id="emailid" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }"  [attr.disabled]="empid !=0 ? '' : null"
                      [(ngModel)]="overviewData.emailid">
                  <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                      <div *ngIf="f.emailid.errors.required">Please enter email </div>
                  </div>
                  <div class="invalid-feedback">
                      <div class="error" *ngIf="emailexist">This email is already registered</div>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label>Mobile Number*</label>
                  <international-phone-number formControlName="phonenumber"
                      class="form-control phonecontrol"  [disabled]="empid !=0 ? '' : null"
                      [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }"
                      [(ngModel)]="overviewData.phonenumber" placeholder="Enter mobile number"
                      (input)="validatenumber($event.target.value,'phone')" [maxlength]="20"
                      [required]="true" #mobilenumber name="phonenumber"></international-phone-number>
                  <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                      <div *ngIf="f.phonenumber.errors.required">Please enter mobile number</div>
                  </div>
                  <span *ngIf="phonecheck===false" style="color:red">Invalid format</span>
                  <span *ngIf="phonecheck===true" style="color:green">Valid format</span>
                </div>
              </div>     

                <div class="row">
                  <div class="form-group col-lg-6">
                      <label>Postcode*</label>
                      <input type="pincode" formControlName="pincode" id="pincode" name="pincode" class="form-control pincode" [ngModel]="addresslistDetail.pincode" (input)="onSearchChange($event.target.value)" matInput [matAutocomplete]="auto">
                      <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                          <div *ngIf="f.pincode.errors.required">Please enter postcode</div>
                      </div>
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="pincode">
                          <mat-option *ngFor="let option of options" [value]="option" (onSelectionChange)="updatecode($event, option)">
                              {{option}}
                          </mat-option>
                      </mat-autocomplete>
                  </div>
                  <div class="form-group col-lg-6">
                      <label>Address Line 1*</label>
                      <input type="address1" formControlName="address1" class="" name="address1" id="address1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" required [(ngModel)]="addresslistDetail.addressid1">
                      <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
                          <div *ngIf="f.address1.errors.required">Please enter address</div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="form-group col-lg-6">
                      <label>Address Line 2 </label>
                      <input type="address2" formControlName="address2" class="" name="address2" id="address2" class="form-control" [(ngModel)]="addresslistDetail.addressid2">
                  </div>
                  <div class="form-group col-lg-6">
                      <label>City*</label>
                      <input type="cityname" formControlName="cityname" class="" name="cityname" id="cityname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.cityname.errors }" required [(ngModel)]="addresslistDetail.cityname">
                      <div *ngIf="submitted && f.cityname.errors" class="invalid-feedback">
                          <div *ngIf="f.cityname.errors.required">Please enter city</div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="form-group col-lg-6">
                      <label>County Name*</label>
                      <input type="text" formControlName="statename" class="" name="statename" id="statename" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.statename.errors }" required [(ngModel)]="addresslistDetail.statename">
                    
                      <div *ngIf="submitted && f.statename.errors" class="invalid-feedback">
                          <div *ngIf="f.statename.errors.required">Please enter the county</div>
                      </div>
                  </div>
                  <div class="form-group col-lg-6">
                      <label>Country*</label>
                      <select style="width:100%;" formControlName="countryid" name="countryid" class="form-control input-lg" id="countryid" [ngClass]="{ 'is-invalid': submitted && f.countryid.errors }"   [(ngModel)]="addresslistDetail.countryid">
                        <option [value]="">Select Country</option>
                        <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                      </select>
                      <div *ngIf="submitted && f.countryid.errors" class="invalid-feedback">
                          <div *ngIf="f.countryid.errors.required">Please select country</div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <input type="submit" class="btn btn-primary com_btn" name="updateform" value="{{ inputbutton }}"    />
          <input type="button" class="btn btn-secondary com_btn" name="updateform" (click)="closeModal('add-new-applicant')" value="Cancel" />
        </div>
      </form>
    </div>
  </div>


  <div role="document" class="formgroup" [ngStyle]="{'display': (tp=='second') ? 'block' : 'none'}">
    <div class="modal-content">
      <div class="modal-body p-4">
        <div class="container text-center">
          <div class="info_mess">
            <div class="para">
              <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
              <p class="mb-0 font20">The employee will receive an email and they need to upload the document.</p>
            </div>                          
          </div>
        </div>
      </div>
      <div class="modal-footer text-center">
        <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Confirm"  (click)="sendrequestcharge(overviewForm.value,'add-new-applicant')"  />
        <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('add-new-applicant');"/>
      </div>
    </div>
  </div>
</jw-modal>

