import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Employee } from '../../models/employee.model';
import { CountryService } from '../../services/country.service';
import { EmployeeService } from '../../services/employee.service';
import { EmployeementService } from '../../services/employeement.service';
import { JobService } from '../../services/job.service';
import { AssetService } from '../../services/asset.service';
import { ClientService } from '../../services/client.service';
import { ExcelService } from '../../services/excel.service';

@Component({
  selector: 'app-assetreport',
  templateUrl: './assetreport.component.html',
  styleUrls: ['./assetreport.component.css']
})

export class AssetreportComponent implements OnInit {
  EmployeeForm: FormGroup;
  employeeDetail = new Employee();
  employeelist: any;
  countrylist: any;
  coslist: any;
  data: any;
  datalist:any;
  joblist: any;
  assetcategory: any;
  clientlist: any;
  exceldata = [];
  employeeassetdata: any;
  public searchText: string;
  Userid: any;
  record: any;
  items: Array<any>;
  pageOfItems: Array<any>;

  constructor(
    private excelService: ExcelService, 
    private formBuilder: FormBuilder, 
    private asset: AssetService, 
    private client: ClientService, 
    private country: CountryService, 
    private jobser: JobService, 
    private employee: EmployeeService, 
    private employeementreport: EmployeementService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.EmployeeForm = this.formBuilder.group({
      emailid: [''],
      phonenumber: [''],
      ninumber: [''],
      minstartdate: [''],
      maxstartdate: [''],
      minenddate: [''],
      maxenddate: [''],
      categoryid: [''],
      serialnumber: [''],
      fname: [''],
      searcText: ['']
    });
    var values3 = {};
    this.asset.getassetreport(values3).subscribe((assetlist: any) => {
      this.record = assetlist.length;
      this.data = assetlist;   
      this.record = this.data.length;
      this.datalist = this.data;
      if (this.record < 30) {
        this.pageOfItems = this.data;
      }
      else {
        this.items = this.data;
      }
    });
    this.Userid = localStorage.getItem('userid');
    const sstatustype = { 'status': true, 'id': this.Userid }
    this.asset.getassetcategory(sstatustype).subscribe((assetcategory: any) => {
      this.assetcategory = assetcategory;
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  exportAsXLSX(): void {
    this.exceldata = [];
    this.data.forEach(obj => {
      this.employeeassetdata = {
        "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
        "Serial Number": obj.serialnumber,
        "Category Name": obj.categoryid.categoryname,
        "Allocation Date": this.datePipe.transform(new Date(obj.statdate),"dd MMM yyyy"),
        "Submission Date": (obj.enddate)?this.datePipe.transform(new Date(obj.enddate),"dd MMM yyyy"):'',
        "Description": obj.assetdescption
      };
      this.exceldata.push(this.employeeassetdata);
    });
    this.excelService.exportAsExcelFile(this.exceldata, 'Asset_Report');
  }

  onSubmit(value3: any) {
    this.asset.getassetreport(value3).subscribe((assetlist: any) => {
      this.record = assetlist.length;
      this.data = assetlist;
    });
  }
}