"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var plan_model_1 = require("../../../models/plan.model");
var checksystem_service_1 = require("../../../services/checksystem.service");
var company_service_1 = require("../../../services/company.service");
var stripe_angular_1 = require("stripe-angular");
var app_global_1 = require("../../../app.global");
var checkservice_service_1 = require("../../../services/checkservice.service");
var modal_service_1 = require("../../../_services/modal.service");
var router_1 = require("@angular/router");
var CheckaddcreditComponent = /** @class */ (function () {
    function CheckaddcreditComponent(check, checkService, StripeScriptTag, companyService, router, modalService) {
        this.check = check;
        this.checkService = checkService;
        this.StripeScriptTag = StripeScriptTag;
        this.companyService = companyService;
        this.router = router;
        this.modalService = modalService;
        this.paynow = false;
        this.remainitem = new plan_model_1.checkbiling();
        this.showAddCheck = false;
        this.paymentDone = false;
        this.publishableKey = app_global_1.AppGlobals.API_STRIPE;
    }
    CheckaddcreditComponent.prototype.ngOnInit = function () {
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var isadmin = localStorage.getItem('isadmin');
        if (isadmin != 'true') {
            this.router.navigate(['/permission-denied']);
        }
        this.StripeScriptTag.setPublishableKey(this.publishableKey);
        this.ctype = parseInt(localStorage.getItem('ctype'));
        this.remainitem = new plan_model_1.checkbiling();
        this.getRemainingChecks();
    };
    CheckaddcreditComponent.prototype.paymentstripe = function (id) {
        this.paynow = false;
        this.modalService.open(id);
    };
    CheckaddcreditComponent.prototype.getRemainingChecks = function () {
        var _this = this;
        this.companyService.getcompanyData().subscribe(function (data) {
            // console.log('Card System', data);
            // this.showAddCheck = true;
            if (data.stuser) {
                _this.paymentDone = true;
            }
            if (data.verifiedforcheck == 1) {
                _this.showAddCheck = true;
            }
        });
        var ts = {};
        this.check.getAllServices().subscribe(function (data) {
            _this.serviceArraycredit = data;
            _this.leaves = []; //.destroy();// =null;
            _this.serviceArraycredit.forEach(function (element) {
                ts = { 'serviceid': element.id };
                _this.check.getbillinginformation(ts).subscribe(function (remaindata) {
                    _this.leaves.push({
                        orderby: element.orderby,
                        id: element.id,
                        servicename: element.servicename,
                        totalcheck: remaindata.totalcheck,
                        usedcheck: remaindata.usedcheck,
                        remiancheck: remaindata.remiancheck
                    });
                    _this.leaves.sort(function (a, b) { return a.orderby > b.orderby; });
                });
            });
        });
    };
    CheckaddcreditComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    CheckaddcreditComponent.prototype.setStripeToken12 = function (token, id) {
        var _this = this;
        this.paynow = true;
        this.stptoken = token.id;
        var amunt = parseFloat('2');
        var data = {
            "token": token.id,
            "cmonth": token.card.exp_month,
            "cyear": token.card.exp_year,
            "cardnumber": token.card.last4,
            "tokenid": token.card.id,
            "cardname": token.card.brand,
            "country": token.card.country,
            "cstatus": "1",
            "amount": amunt
        };
        this.checkService.registereduserpayment(data).subscribe(function (payment3) {
            if (payment3.success == true) {
                _this.closeModal('custom-payment-1');
                _this.getRemainingChecks();
            }
        });
    };
    CheckaddcreditComponent.prototype.onStripeError12 = function (error) {
        console.error('Stripe error', error);
    };
    return CheckaddcreditComponent;
}());
exports.CheckaddcreditComponent = CheckaddcreditComponent;
