"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var myvisa_service_1 = require("../../services/myvisa.service");
var country_service_1 = require("../../services/country.service");
var forms_1 = require("@angular/forms");
var visa_model_1 = require("../../models/visa/visa.model");
var upload_file_service_1 = require("./../../services/upload-file.service");
var alert_service_1 = require("../../services/alert.service");
var ngx_webcam_1 = require("ngx-webcam");
var platform_browser_1 = require("@angular/platform-browser");
var rxjs_1 = require("rxjs");
var router_1 = require("@angular/router");
// import { DeviceDetectorService } from 'ngx-device-detector';
var checksystem_service_1 = require("../../services/checksystem.service");
var app_global_1 = require("../../app.global");
var Subscription_1 = require("rxjs/Subscription");
require("rxjs/add/observable/timer");
// import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
var EmployeecheckvisaComponent = /** @class */ (function () {
    function EmployeecheckvisaComponent(formBuilder, uploadService, visaService, country, datePipe, alerts, sanitizer, check, activatedRoute) {
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.visaService = visaService;
        this.country = country;
        this.datePipe = datePipe;
        this.alerts = alerts;
        this.sanitizer = sanitizer;
        this.check = check;
        this.activatedRoute = activatedRoute;
        this.wizard = 0;
        this.deviceInfo = null;
        // elementType = NgxQrcodeElementTypes.URL;
        // correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
        this.linkurl = '';
        this.token_expired = false;
        this.aipayment = false;
        this.is_rtw = 0;
        this.showloader = false;
        this.showWebcam = true;
        this.allowCameraSwitch = true;
        this.multipleWebcamsAvailable = false;
        this.videoOptions = {};
        this.errors = [];
        this.webcamImage = null;
        // webcam snapshot trigger
        this.trigger = new rxjs_1.Subject();
        // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
        this.nextWebcam = new rxjs_1.Subject();
        this.filterItems = [
            {
                name: 'All Visa Details',
                id: 0,
                selected: true
            },
            {
                name: 'Accepted',
                id: 2,
                selected: false
            },
            {
                name: 'Rejected',
                id: 3,
                selected: false
            },
            {
                name: 'Waiting for review',
                id: 1,
                selected: false
            }
        ];
    }
    EmployeecheckvisaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.accesstoken = this.activatedRoute.snapshot.paramMap.get("access_token");
        this.activatedRoute.queryParams.subscribe(function (params) {
            _this.accesstoken = params['access_token'];
            _this.is_rtw = params['is_rtw'];
            localStorage.setItem('userToken', _this.accesstoken);
            console.log(_this.accesstoken);
            _this.check.gettokensystem().subscribe(function (data) {
                _this.linkurl = 'https://www.complygate.co.uk/';
                if (data.sussess == 'true') {
                    localStorage.setItem('userid', data.userid);
                    localStorage.setItem('emptype', data.emptype);
                    localStorage.setItem('isline', data.isline);
                    localStorage.setItem('planid', data.planid);
                    localStorage.setItem('isemail', data.isemail);
                    localStorage.setItem('isadmin', data.isadmin);
                    localStorage.setItem('ispermission', data.ispermission);
                    localStorage.setItem('logoname', data.logourl);
                    localStorage.setItem('ctype', data.ctype);
                    var id = localStorage.getItem('userToken');
                    _this.Userid = localStorage.getItem('userid');
                    var data2 = { 'id': _this.Userid, 'cstatus': [1, 2, 3] };
                    if (data.logourl == "" || _this.logo == null) {
                        _this.logo = "assets/img/logo.png";
                    }
                    else {
                        _this.logo = data.logourl;
                    }
                    _this.wizard = 0;
                    _this.employeemasterid = data.userid;
                    _this.visapopForm = _this.formBuilder.group({
                        id: ['', forms_1.Validators.required],
                        employeeid: ['', forms_1.Validators.required],
                        visanumber: ['', forms_1.Validators.required],
                        visatype: ['', forms_1.Validators.required],
                        nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
                        visaexpiry: ['', forms_1.Validators.required],
                        visastart: ['', forms_1.Validators.required],
                        iscurrent: [''],
                        stype: ['']
                    });
                    _this.submitted = false;
                    _this.disableBtn = true;
                    _this.buttoninsert = "Submit";
                    _this.visaDetail = new visa_model_1.Visa();
                    _this.visaDetail.nationalityid = "";
                    _this.Userid = localStorage.getItem('userid');
                    var dd = localStorage.getItem('userid');
                    _this.visaDetail.employeeid = dd; //this.Userid;
                    _this.visaDetail.employeevisadeatilid = "0";
                    _this.filename = "Choose file";
                    _this.profilepic = "Choose file";
                    _this.filebackname = 'Choose file';
                }
                else {
                    _this.token_expired = true;
                }
            });
        });
        this.getCountries();
    };
    // get device(): any {
    //   return this.deviceService.getDeviceInfo();
    // }
    // get isMobile(): boolean {
    //   return this.deviceService.isMobile();
    // }
    // get isTablet(): boolean {
    //   return this.deviceService.isTablet();
    // }
    // get isDesktop(): boolean {
    //   return this.deviceService.isDesktop();
    // }
    EmployeecheckvisaComponent.prototype.ngOnDestroy = function () {
        if (this.subscription && this.subscription instanceof Subscription_1.Subscription) {
            this.subscription.unsubscribe();
        }
    };
    EmployeecheckvisaComponent.prototype.epicFunction = function () {
        // console.log('hello `Home` component');
        // this.deviceInfo = this.deviceService.getDeviceInfo();
        // const isMobile = this.deviceService.isMobile();
        // const isTablet = this.deviceService.isTablet();
        // const isDesktopDevice = this.deviceService.isDesktop();
        // console.log(this.deviceInfo);
        // console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
        // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
        // console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
    };
    EmployeecheckvisaComponent.prototype.getCountries = function () {
        var _this = this;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
            console.log(_this.countrylist);
        });
    };
    Object.defineProperty(EmployeecheckvisaComponent.prototype, "f", {
        get: function () {
            return this.visapopForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    EmployeecheckvisaComponent.prototype.toggle = function (id) {
        var _this = this;
        if (id == 1) {
            this.submitted = true;
            console.log(this.visapopForm);
            if (this.visapopForm.invalid) {
                return;
            }
        }
        if (id == 2) {
            if (this.webcamImage) {
                var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
                setTimeout(function () { return _this.frontimage = imagepath; }, 5000);
                console.log(this.imgsrc);
                this.webcamImage = null;
            }
        }
        else if (id == 3) {
            if (this.webcamImage) {
                var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
                setTimeout(function () { return _this.backimage = imagepath; }, 5000);
                console.log(this.imgsrc);
                this.webcamImage = null;
            }
        }
        else if (id == 4) {
            var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
            setTimeout(function () { return _this.profileimage = imagepath; }, 5000);
            console.log(this.imgsrc);
            this.webcamImage = null;
            this.setTimer();
        }
        this.wizard = id;
    };
    EmployeecheckvisaComponent.prototype.toggle2 = function (id) {
        // if(id == 1){
        //   this.wizard2 = false;
        //   this.wizard1 = true;
        //   this.wizard3 = false;
        // }
        // if(id == 2){
        //   this.wizard3 = false;
        //   this.wizard1 = false;
        //   this.wizard2 = true;
        // }
    };
    EmployeecheckvisaComponent.prototype.onSubmit = function (value3, id, stype) {
        var _this = this;
        this.submitted = true;
        this.disableBtn = false;
        this.wizard = 6;
        value3.imagename = this.frontimage;
        value3.imagebackname = this.backimage;
        value3.visaprofileimage = this.profileimage;
        value3.isrighttowork = this.is_rtw;
        value3.visastart = this.datePipe.transform(new Date(value3.visastart), "dd MMM yyyy");
        value3.visaexpiry = this.datePipe.transform(new Date(value3.visaexpiry), "dd MMM yyyy");
        value3.stype = stype;
        value3.createby = this.Userid;
        this.visaService.myvisadetailsUpdatebyadmin(value3).subscribe(function (datamain) {
            var data3 = { "employeeidproofdeatilid": datamain.employeevisadeatilid };
            var cid = { id: value3.countryid };
            _this.country.getcountrylistbyid(cid).subscribe(function (countrydata) {
                var countryiso = countrydata.isothree;
                var idtype = 'VISA';
                var visalog = {
                    employeeid: _this.employeemasterid,
                    documentid: datamain.employeevisadeatilid,
                    documenturl: value3.imagename,
                    documenttype: 'v'
                };
                _this.visaService.visaailogupdate(visalog).subscribe(function (dbvisaresult) {
                    if (dbvisaresult.success == '1') {
                        var data = {
                            merchantIdScanReference: dbvisaresult.logid,
                            country: countryiso,
                            idType: idtype,
                            customerId: _this.employeemasterid,
                            is_rtw: _this.is_rtw,
                            callbackUrl: app_global_1.AppGlobals.API_ENDPOINT + "/api/aiconfirmation",
                            documenturl: value3.imagename,
                            documentbackurl: value3.imagebackname,
                            imageurl: value3.visaprofileimage
                        };
                        _this.visaService.visadocumentupdate(data).subscribe(function (data5) {
                            if (data5.success == true) {
                                _this.check.deleteAccessToken().subscribe(function (tokenData) {
                                    // if(tokenData.success)
                                    // {
                                    localStorage.removeItem('userToken');
                                    localStorage.removeItem('userid');
                                    localStorage.removeItem('emptype');
                                    localStorage.removeItem('isline');
                                    localStorage.removeItem('planid');
                                    localStorage.removeItem('isemail');
                                    localStorage.removeItem('isadmin');
                                    localStorage.removeItem('ispermission');
                                    localStorage.removeItem('logoname');
                                    localStorage.removeItem('ctype');
                                    _this.wizard = 5;
                                    // }
                                });
                            }
                            else {
                                _this.wizard = 4;
                                _this.alerts.error("something went wrong please try again");
                            }
                        });
                    }
                });
            });
        });
        this.filename = "Choose file";
        this.alerts.success('BRP Uploaded Successfully.', true);
    };
    EmployeecheckvisaComponent.prototype.sanitize = function (url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    };
    EmployeecheckvisaComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.fileformaterror = true;
        }
        else {
            this.fileformaterror = false;
            this.filesizeerror = false;
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.imgURL = reader.result;
            };
            // this.selectedFiles = files[0]; 
        }
    };
    EmployeecheckvisaComponent.prototype.selectbackFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filebackname = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filebacksizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.filebackformaterror = true;
            this.filebacksizeerror = false;
        }
        else {
            this.filebackformaterror = false;
            this.filebacksizeerror = false;
            this.selectedbackFiles = files[0];
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.imgbackURL = reader.result;
            };
        }
    };
    EmployeecheckvisaComponent.prototype.selectprofile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.profilepic = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.pro_pic_size_error = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.pro_pic_format_error = true;
            this.pro_pic_size_error = false;
        }
        else {
            this.pro_pic_format_error = false;
            this.pro_pic_size_error = false;
            this.selected_profile_image = files[0];
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.profile_pic_url = reader.result;
            };
        }
    };
    EmployeecheckvisaComponent.prototype.setTimer = function () {
        var _this = this;
        this.showloader = true;
        this.timer = rxjs_1.Observable.timer(5000);
        this.subscription = this.timer.subscribe(function () {
            _this.showloader = false;
        });
    };
    EmployeecheckvisaComponent.prototype.triggerSnapshot = function () {
        this.trigger.next();
    };
    EmployeecheckvisaComponent.prototype.triggerSnapshotreload = function () {
        this.webcamImage = null;
        //this.trigger.next();
    };
    EmployeecheckvisaComponent.prototype.toggleWebcam = function () {
        this.showWebcam = !this.showWebcam;
    };
    EmployeecheckvisaComponent.prototype.handleInitError = function (error) {
        this.errors.push(error);
    };
    EmployeecheckvisaComponent.prototype.showNextWebcam = function (directionOrDeviceId) {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    };
    EmployeecheckvisaComponent.prototype.handleImage = function (webcamImage) {
        console.info('received webcam image', webcamImage);
        this.webcamImage = webcamImage;
    };
    EmployeecheckvisaComponent.prototype.cameraWasSwitched = function (deviceId) {
        console.log('active device: ' + deviceId);
        this.deviceId = deviceId;
    };
    Object.defineProperty(EmployeecheckvisaComponent.prototype, "triggerObservable", {
        get: function () {
            return this.trigger.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeecheckvisaComponent.prototype, "nextWebcamObservable", {
        get: function () {
            return this.nextWebcam.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return EmployeecheckvisaComponent;
}());
exports.EmployeecheckvisaComponent = EmployeecheckvisaComponent;
