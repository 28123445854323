"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var DivisionService = /** @class */ (function () {
    function DivisionService(commonService) {
        this.commonService = commonService;
    }
    DivisionService.prototype.getdivisionlistbyadmin = function (data) {
        return this.commonService.formpost('/company/divisionlistbyadmin', data);
    };
    DivisionService.prototype.settingdivisionUpdate = function (value) {
        return this.commonService.formpost('/company/divisionupdatebyadmin', value);
    };
    DivisionService.prototype.divisionUpdateChangeStatus = function (value) {
        return this.commonService.formpost('/company/divisionupdatechangestatus', value);
    };
    DivisionService.ngInjectableDef = i0.defineInjectable({ factory: function DivisionService_Factory() { return new DivisionService(i0.inject(i1.CommonService)); }, token: DivisionService, providedIn: "root" });
    return DivisionService;
}());
exports.DivisionService = DivisionService;
