<app-header>
</app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <a [routerLink]="['/check-business-aml-peps-sanction']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to AML, PEPs & Sanction Dashboard</a>
          
            <div class="clearfix"></div>
            
            <div class="tabs__content">

                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Business Details </h5>
                            </div>
                        </div>

                        <div class="personal_form" *ngIf="result==false">
                            <form [formGroup]="bussinessformGroup" #bussinessForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(bussinessForm.value)">

                                <input type="hidden" formControlName="Threshold" name="Threshold" id="Threshold" class="form-control" aria-describedby="emailHelp" placeholder="Business Name" [ngClass]="{ 'is-invalid': submitted && f.Threshold.errors }" [(ngModel)]="businessDetail.Threshold"
                                />
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Business Name</label>
                                            <input type="text" formControlName="BusinessName" name="BusinessName" id="BusinessName" class="form-control" aria-describedby="emailHelp" placeholder="Business Name" [ngClass]="{ 'is-invalid': submitted && f.BusinessName.errors }" [(ngModel)]="businessDetail.BusinessName">
                                            <div *ngIf="submitted && f.BusinessName.errors" class="invalid-feedback">
                                                <div *ngIf="f.BusinessName.errors.required">Please enter First name</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Town/City</label>
                                            <input type="text" formControlName="City" name="City" id="City" class="form-control" aria-describedby="emailHelp" placeholder="City Name" [ngClass]="{ 'is-invalid': submitted && f.City.errors }" [(ngModel)]="businessDetail.City">

                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">County/State</label>
                                            <input type="text" formControlName="County" name="County" id="County" class="form-control" aria-describedby="emailHelp" placeholder="County / State" [ngClass]="{ 'is-invalid': submitted && f.County.errors }" [(ngModel)]="businessDetail.County">

                                            <!-- <input type="text" formControlName="province" name="province" id="province" class="form-control"   aria-describedby="emailHelp" placeholder="Country / State"  [ngClass]="{ 'is-invalid': submitted && f.province.errors }" [(ngModel)]="businessDetail.province"> -->

                                        </div>
                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Post/Post Code</label>
                                            <input type="text" formControlName="Postcode" name="Postcode" id="Postcode" class="form-control" aria-describedby="emailHelp" placeholder="Post / Zip Code" [ngClass]="{ 'is-invalid': submitted && f.Postcode.errors }" [(ngModel)]="businessDetail.Postcode">

                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Country</label>
                                            <select class="form-control" formControlName="Country" [ngClass]="{ 'is-invalid': submitted && f.Country.errors }" [(ngModel)]="businessDetail.Country" name="Country" id="Country" placeholder="Select Country">
                                          <option [value]="defaultVal">Select Country</option>

                                          <option *ngFor="let cmp of countrylist" [value]="cmp.name">
                                            {{ cmp.name }} </option>

                                            
                                        </select>

                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address</label>
                                            <input type="text" formControlName="Address" name="Address" id="Address" class="form-control" aria-describedby="emailHelp" placeholder="Address" [ngClass]="{ 'is-invalid': submitted && f.Address.errors }" [(ngModel)]="businessDetail.Address">

                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                    <!-- <div class="col-sm-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Address 2</label>
                                        <input type="text" formControlName="houseNo" name="houseNo" id="houseNo" class="form-control"   aria-describedby="emailHelp" placeholder="Address 2"  [ngClass]="{ 'is-invalid': submitted && f.houseNo.errors }" [(ngModel)]="businessDetail.houseNo">

                                    </div>
                                </div> -->

                                </div>
                                <div class="row radio_section">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1"><b>Datasets to search</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="checkbox_credit">Select All
                                        <input type="checkbox" (change)="selectall($event.target.checked)" >
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3">
                                        <label class="checkbox_credit">PEP
                                        <input type="checkbox" formControlName="PEP" name="PEP" id="PEP" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.PEP.errors }" [(ngModel)]="businessDetail.PEP">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3">
                                        <label class="checkbox_credit"> Sanction - Current
                                        <input type="checkbox" formControlName="CurrentSanctions" name="CurrentSanctions" id="CurrentSanctions" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.CurrentSanctions.errors }" [(ngModel)]="businessDetail.CurrentSanctions" >
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3">
                                        <label class="checkbox_credit"> Sanction - Previous
                                        <input type="checkbox" formControlName="PreviousSanctions" name="PreviousSanctions" id="PreviousSanctions" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.PreviousSanctions.errors }" [(ngModel)]="businessDetail.PreviousSanctions">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3">
                                        <label class="checkbox_credit"> Financial Regulator
                                        <input type="checkbox" formControlName="FinancialRegulator" name="FinancialRegulator" id="FinancialRegulator" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.FinancialRegulator.errors }" [(ngModel)]="businessDetail.FinancialRegulator">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3">
                                        <label class="checkbox_credit"> Law Enforcement
                                        <input type="checkbox" formControlName="LawEnforcement" name="LawEnforcement" id="LawEnforcement" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.LawEnforcement.errors }" [(ngModel)]="businessDetail.LawEnforcement">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3">
                                        <label class="checkbox_credit">  Disqualified Director (UK Only)
                                        <input type="checkbox" formControlName="DisqualifiedDirector" name="DisqualifiedDirector" id="DisqualifiedDirector" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.DisqualifiedDirector.errors }" [(ngModel)]="businessDetail.DisqualifiedDirector">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3">
                                        <label class="checkbox_credit">   Insolvency (UK & Ireland)
                                        <input type="checkbox" formControlName="Insolvency" name="Insolvency" id="Insolvency" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.Insolvency.errors }" [(ngModel)]="businessDetail.Insolvency">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3">
                                        <label class="checkbox_credit"> Adverse Media
                                        <input type="checkbox" formControlName="AdverseMedia" name="AdverseMedia" id="AdverseMedia" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.AdverseMedia.errors }" [(ngModel)]="businessDetail.AdverseMedia">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-sm-12 text-right">
                                        <input type="submit" class="btn com_btn btn-primary" name="updateform" value="{{buttoninsert}}" />

                                        <!-- <a [routerLink]="['/buscreditsearchresult']" class="btn com_btn btn-primary"><i class="fa fa-search"> </i> Search</a> -->
                                        <button type="button" class="btn com_btn btn-secondary" (click)="clearpersonal()"><i class="fa fa-close"> </i> Clear</button>
                                    </div>
                                </div>


                            </form>
                            <hr/>
                        </div>





                        <div class="credit_data_show" *ngIf="result==true">
                            <div class="job_list_section visa_request_section" *ngFor="let company of searchresult.matches">

                                <div class="job_detils_date width13">
                                    <p class="job_address">ID</p>
                                    <p class="ninumber text-primary">{{company.business.id}} </p>
                                </div>
                                <div class="job_detils_date width15">
                                    <div class="visa_user_details ">
                                        <p class="job_address">Business Name</p>
                                        <h4>{{ company.business.businessName}}</h4>
                                    </div>
                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address">Data Set</p>
                                    {{dataset(company.business)}}
                                    <p class="job_profile">{{datasetlist}}</p>
                                </div>
                                <div class="job_detils_date width33">
                                    <p class="job_address">Address</p>
                                    <p class="job_profile" *ngFor="let companyaddress of company.business.addresses;  let i=index ">
                                        <span *ngIf="i ==0">
                                        {{companyaddress.address1}}, {{ companyaddress.city }} {{ companyaddress.county }}- {{ companyaddress.postcode}}
                                    </span>
                                    </p>
                                </div>


                                <div class="job_detils_date aligncenter">
                                    <div id="container" class="green_text">
                                        <div class="dot"></div>
                                        <div class="pulse"></div>

                                    </div>
                                    <p class="job_profile green_text">{{ company.score}} </p>
                                </div>
                                <div class="job_detils_date width13">

                                    <p class="job_address">
                                        <button type="submit" class="btn btn-primary com_btn" (click)="openModal('bussiness-pdf-viewer-visa',company)"><i class="fa fa-file-image-o"></i></button>
                                        <a [routerLink]="['/completebusscreditdetails',company.id]" class="btn btn-danger com_btn"><i class="fa fa-address-card" aria-hidden="true"></i>
                                        </a>
                                    </p>
                                </div>
                            </div>



                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<jw-modal id="bussiness-pdf-viewer-visa" class="modal" role="dialog">
    <div role="document" class="formgroup" *ngIf="bussinessdetails">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{bussinessdetails.business.businessName}}</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('bussiness-pdf-viewer-visa');">
        <span aria-hidden="true">&times;</span>
      </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-2">
                            <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                        </div>
                        <div class="col-lg-10  d-flex">
                            <div class="category justify-content-between align-items-center text-center d-flex width100">
                                <div class="catog_box">
                                    <p>PEP</p>
                                    <span>9</span>
                                </div>

                                <div class="catog_box">
                                    <p>Adverse Media</p>
                                    <span>11</span>
                                </div>
                                <div class="catog_box">
                                    <p>IDV</p>
                                    <span>9</span>
                                </div>
                                <div class="catog_box">
                                    <p>Law Enforcement</p>
                                    <span>2</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <hr/>
                    <div class="all_detials">
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">
                                    <div class="catog_box width50">
                                        <p>Business Name</p>
                                        <span>{{bussinessdetails.business.businessName}}</span>
                                    </div>
                                    <div class="catog_box width50">
                                        <p>Alias</p>
                                        <p *ngFor="let companyaliases of bussinessdetails.person.aliases;">
                                            {{companyaliases.businessName}}
                                        </p>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box width50">
                                        <p>Telephone</p>
                                        <span>{{bussinessdetails.telephoneNumber}}</span>
                                    </div>
                                    <div class="catog_box width50">
                                        <p>Website </p>
                                        <span>{{bussinessdetails.website}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">


                                    <div class="catog_box width50">
                                        <p>Addresses</p>
                                        <p *ngFor="let companyaddress of bussinessdetails.business.addresses;">
                                            {{companyaddress.address1}}, {{ companyaddress.city }} {{ companyaddress.county }}- {{ companyaddress.postcode}}
                                        </p>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>

                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box">
                                        <span>Reference Id</span>
                                    </div>
                                    <div class="catog_box">
                                        <span>{{bussinessdetails.business.id }}</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary com_btn"> Remove from review list</button>
                <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</jw-modal>


<jw-modal id="pdf-viewer-visa" class="modal charge" role="dialog">

       <div role="document" class="formgroup">
    
           <div class="modal-content">
    
               <div class="modal-header">
    
                   <h5 class="modal-title">Charges</h5>
    
                   <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-viewer-visa');">
    
           <span aria-hidden="true">&times;</span>
    
         </button>
    
               </div>
    
               <div class="modal-body">
    
                   <div class="container">
    
    
    
                       <div class="row">
    
                           <div class="col-lg-12">
    
                               <p>Per Click Charge <span class="charge">£2</span> </p>
    
    
    
                           </div>
    
    
    
                       </div>
    
    
    
    
    
                   </div>
    
               </div>
    
               <div class="modal-footer">
    
                   <a [routerLink]="['/creditsearchresult']" class="btn com_btn btn-primary"><i class="fa fa-search"> </i> Search</a>
    
                   <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal">Close</button>
    
               </div>
    
           </div>
    
       </div>
    
    </jw-modal>