import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { EmployeeService } from '../../services/employee.service';
import { ModalService } from '../../_services/modal.service';
import { Profile } from '../../models/overview/profile.model';
import { PermissionService } from '../../services/permission.service';
import { AlertService } from '../../services/alert.service';
import { CommonService } from '../../services/common.service';
import { CompanyService } from '../../services/company.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-adminpermission',
  templateUrl: './adminpermission.component.html',
  styleUrls: ['./adminpermission.component.css']
})
export class AdminpermissionComponent implements OnInit {


  data: any;
  submitted: any;
  employeeForm: FormGroup;
  overviewData: any;
  countrylist: any;
  joblist: any;
  candidatelist: any;
  employeeid: any;
  ctype: number;
  th = [];
  emailexist = false;
  permission: any;
  emplyeeid: any;
  menulist: any;
  public searchText:string;
  Userid: any;
  permissions: any;
  userperm: any;
  currentcompanydata: any;
  record:number;
  disableBtn: any;
  samecompany: any = false;
  mobilecheck: any;
  items: Array<any>;
  permissionstring: any;
  phonecheck:any;
  datalist:any;
  pageOfItems: Array<any>;
  ismonitoring:any=false;
  username:any;


  constructor(
    private employeeService: EmployeeService,
    private modalService: ModalService,
    private router: Router,private company: CompanyService,
    private commonService: CommonService, 
    private userService: UserService, 
    private permissiondetails: PermissionService,
    private formBuilder: FormBuilder, 
    private alerts: AlertService
  ) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }


    this.company.getcompanyData().subscribe((companydata: any) => {

       
      this.ismonitoring =companydata.issso;

    });

    this.username = '';
    // var ctype  = parseInt(localStorage.getItem('ctype'));
    // if(ctype ==1){
    //   this.router.navigate(['/check-dashboard']);
    // }

    this.ctype  = parseInt(localStorage.getItem('ctype'));
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);    
    this.permissions = permission[0];
    this.userperm = this.permissions.m2;

    // this.employeeService.getlistofemployee().subscribe((companydata: any) => {
    //   this.currentcompanydata = companydata;
    // });

    this.employeeForm = this.formBuilder.group({
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      username: ['', Validators.required],
      empid: [''],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: ['', Validators.compose([Validators.required])],
      ismail: ['']
    });

    this.Userid = localStorage.getItem('userid');
    this.overviewData = new Profile();
    this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
      this.data = employeedata;
      this.record = this.data.length;
      this.datalist = this.data;
      if(this.record < 30){
        this.pageOfItems = this.data;
      } else{
           this.items =this.data;
      }
    });

    this.permissiondetails.bindallmenu().subscribe((menudata: any) => {
      var permissionstring = "[{";
      var perarray = [];
      if(this.ctype != 3)
      {
        menudata.forEach(function (valuemenu) {
          perarray.push('"' + valuemenu.clname + '": 0');
        });
    }
    else
    {
      menudata.forEach(function (valuemenu) {
        perarray.push('"' + valuemenu.clname + '": 1');
      });
    }
      var perstr = perarray.join();
      permissionstring = permissionstring + perstr + "}]";
      this.permissionstring = permissionstring;
    });

  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  focusFunction() {
    this.emailexist = false;
  }
  
  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  onUserNameOutEvent(event: any)
  {        
    if(event.target.value == '')
    {
        this.username ='';
    }else
    {
        this.username ='';
        var dbvalue = { 'username': event.target.value}
        this.userService.isUserRegisterd(event.target.value).subscribe((data: any) => {
      // this.userService.isUserRegisterd(event.target.value).subscribe((data2: any) => {

        if (data.sussess == 'false') {
          this.username = 'false';
        }else
        {
          this.username = 'true';
        }
      });
    }
  }


  onChangeStatus(evt: any, project: any) {
    this.employeeService.ssoUpdatestatusbyadmin({ 'employeeid': project.employeemasterid, 'issso': evt.target.checked }).subscribe((data: any) => {
    });
  }


  onAddEmployeeSubmit(employeeform1: any) {
    this.submitted = true;
    if (this.employeeForm.invalid) {
      return;
    }

    this.disableBtn = false;
    employeeform1.id = this.Userid;
    employeeform1.ismail =true;
    employeeform1.permission = this.permissionstring;
    employeeform1.isadmin =1;


    var dbvalue = { 'username': employeeform1.username}
    this.userService.isUserRegisterd(dbvalue).subscribe((data: any) => {
  // this.userService.isUserRegisterd(event.target.value).subscribe((data2: any) => {
    if (data.sussess == 'false') {
      this.username = 'false';
    this.userService.isEmailRegisterd(employeeform1.emailid).subscribe((data: any) => {
      if (data.sussess == 'success') {
        this.emailexist = true; 
        this.disableBtn = true;
      } else {       
        this.disableBtn = false;
        this.employeeService.checkemployeecreate(employeeform1).subscribe((dataw: any) => {
          if(dataw.susses == 'true'){           
            this.employeeService.getlistofemployee().subscribe((employeedata: any) => {

              if(this.ctype !=3)
              {
                   this.router.navigate(['/managepermission', dataw.employeemasterid]);
              }

              this.data = employeedata;
              this.record = this.data.length;
              this.datalist = this.data;
              if(this.record < 30){
                this.pageOfItems = this.data;
              }
              else{
                    this.items =this.data;
              }

              this.modalService.close('add-new-applicant');

            });
        } else{
          this.alerts.error('Somthing went wrong. please try again after sometime');}
        });
      }
    });
  }else
  {
    this.username = 'true'; 
    // this.emailexist = true; 
    this.disableBtn = true;
  }
});
  }
  
  uploadByAdmin(empid)
  {
    this.submitted = false;
    this.emailexist =false;
    this.phonecheck =null;
    this.employeeForm.reset({
      fname: "",
      mname: "",
      lname: "",
      emailid: "",
      phonenumber: ""
    });
    this.modalService.open(empid);   
  }
  
  onFocusOutEvent(event: any)
  {
    this.userService.isEmailRegisterdCheckForAdmin(event.target.value).subscribe((data: any) => {
      if (data.sussess == 'success') {
        this.emailexist = true; 
        this.disableBtn = false;
        this.employeeid = data.employeeid;
        if(data.samecompany){
          this.samecompany = true;
        }
        // this.isFocus = true;
        // this.modalService.open('alreadyadd-request');
      } else{
        this.disableBtn = true;        
      }      
    });    
  }
  
  onchange(ind:any, subm: any) {
    var tg;
    tg = JSON.parse(this.permission.toString());
    tg.forEach(function (value4) {
      value4[ind] = subm;
    });
    var starry = JSON.stringify(tg);
    var dt = {"permission": starry, "employeeid": this.emplyeeid};
    this.permission = starry;
    this.permissiondetails.updateemployeepermission(dt).subscribe((employeedata: any) => {
      

    });

  }

  bindallmenu() {
    this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
      employeedata.forEach(function (value) {
        var tg;
        if(value.permission){
        tg = JSON.parse(value.permission.toString());
        tg.forEach(function (value4) {
        });}
      });
      this.data = employeedata;
      this.record = this.data.length;
      this.datalist = this.data;
      this.pageOfItems = this.data;

      if(this.record < 30){
        this.pageOfItems = this.data;
      } else{
        this.items =this.data;
      }

    });
  }

  get f() { return this.employeeForm.controls; }

  onSubmit(employeeform1: any, modelid: any) {

  }

  addadmin(id: string) {
    if (confirm("Are you sure you want to add this user in admin list?")) {
      var prm = { "employeeid": id, "id": this.Userid, "isadmin": true }
      this.permissiondetails.updateisadmin(prm).subscribe((employeedata: any) => {
        this.alerts.success('User successfully added as Admin',true);
        this.bindallmenu();
      });
    }
  }

  removeadmin(id: string) {
    if (confirm("Are you sure you want to remove this user from admin list?")) {
      var prm = { "employeeid": id, "id": this.Userid, "isadmin": false }
      this.permissiondetails.updateisadmin(prm).subscribe((employeedata: any) => {
        this.alerts.success('User successfully removed as admin.',true);
        this.bindallmenu();
      });
    }
  }

  openModal(id: string, employee: any, employeeid: number) {
    this.submitted = false;

    this.permission = employee.permission;

    this.emplyeeid = employeeid;

    this.permissiondetails.bindallmenu().subscribe((menudata: any) => {
      menudata.forEach(function (valuemenu) {
        valuemenu.submenu.forEach(function (submenudata, index) {
          
          var tg;
          tg = JSON.parse(employee.permission.toString());

          tg.forEach(function (value4) {
            var kdd = valuemenu.clname;
            if (value4[kdd] == submenudata.mright) {
              valuemenu.submenu[index].ch = true;
            }
            else {
              valuemenu.submenu[index].ch = false;
            }
          });
        });
      });
      this.menulist = menudata;
      // console.log(this.menulist);
    });
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
      // this.data = employeedata; this.datalist = this.data;   
      
      this.data = employeedata;
      this.record = this.data.length;
      this.datalist = this.data;
     
        this.pageOfItems = this.data;
   
      this.modalService.close(id);
    });
    
  }
}
