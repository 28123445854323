"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var worklist_service_1 = require("../services/worklist.service");
var date_fns_1 = require("date-fns");
var rxjs_1 = require("rxjs");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var forms_1 = require("@angular/forms");
var angular_calendar_1 = require("angular-calendar");
var activity_model_1 = require("../models/activity/activity.model");
var modal_service_1 = require("../_services/modal.service");
var customer_service_1 = require("../services/customer.service");
var router_1 = require("@angular/router");
var leave_service_1 = require("../services/leave.service");
var common_1 = require("@angular/common");
var app_global_1 = require("../app.global");
var WorklistComponent = /** @class */ (function () {
    function WorklistComponent(modal, customer, router, leaveService, data, formBuilder, modalService, datePipe) {
        this.modal = modal;
        this.customer = customer;
        this.router = router;
        this.leaveService = leaveService;
        this.data = data;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.datePipe = datePipe;
        this.events = [];
        this.submitted = false;
        this.leaveavailabledate = new Date();
        this.editMsg = "";
        this.findLeave = false;
        this.view = angular_calendar_1.CalendarView.Month;
        this.CalendarView = angular_calendar_1.CalendarView;
        this.viewDate = new Date();
        this.refresh = new rxjs_1.Subject();
        this.activeDayIsOpen = true;
    }
    WorklistComponent.prototype.ngOnInit = function () {
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.leaveavailabledate.setDate(this.leaveavailabledate.getDate());
        this.classactive = (new Date()).getMonth().toString();
        this.year = (new Date()).getFullYear();
        this.activityformGroup = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            hourtype: ['', forms_1.Validators.required],
            projectid: ['', forms_1.Validators.required],
            locationid: ['', forms_1.Validators.required],
            title: [''],
            ahour: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.pattern(/^[.\d]+$/), forms_1.Validators.max(24), forms_1.Validators.min(1)])],
            start: [''],
            end: [''],
            hourremark: ['', forms_1.Validators.required],
            hourremarkid: ['', forms_1.Validators.required]
        });
        this.bindalldata();
    };
    WorklistComponent.prototype.bindalldata = function () {
        var _this = this;
        this.data.getemployeeworklist().subscribe(function (data) {
            data.forEach(function (element) {
                _this.events.push({
                    id: element.employeehourapplyid,
                    title: element.ahour + 'h ' + element.project.projectname,
                    start: new Date(element.startdate),
                    end: new Date(element.enddate),
                    color: { primary: element.hcolor, secondary: element.fcolor },
                    allDay: element.edit,
                    draggable: true,
                    resizable: {
                        beforeStart: true,
                        afterEnd: true
                    }
                });
                _this.refresh.next();
            });
        });
        this.Userid = localStorage.getItem('userid');
        var sstatustype = { 'status': true, 'id': this.Userid };
        this.data.gethourtypelist(sstatustype).subscribe(function (hourtypelist) {
            _this.hourtypelist = hourtypelist;
        });
        this.data.getprojectlist(sstatustype).subscribe(function (projectlist) {
            _this.projectlist = projectlist;
        });
        this.data.getlocationlist().subscribe(function (locationlist) {
            _this.locationlist = locationlist;
        });
    };
    Object.defineProperty(WorklistComponent.prototype, "f", {
        get: function () {
            return this.activityformGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    WorklistComponent.prototype.onSubmit = function (activity, id) {
        var _this = this;
        this.submitted = true;
        this.editMsg = "";
        if (this.activityformGroup.invalid) {
            return;
        }
        var sd = new Date(activity.start);
        activity.start = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        var ed = new Date(activity.end);
        activity.end = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        // console.log(activity.start);
        this.disableBtn = false;
        var startdate = activity.start;
        var enddate = activity.end;
        activity.startdate = this.datePipe.transform(startdate, 'dd MMM yyyy');
        activity.enddate = this.datePipe.transform(enddate, 'dd MMM yyyy');
        console.log(activity);
        this.data.activityapplyupdate(activity).subscribe(function (data) {
            _this.events = [];
            document.getElementById('closeModal').click();
            _this.bindalldata();
        });
    };
    WorklistComponent.prototype.openlinemanagerModal = function (id) {
        var _this = this;
        this.customer.getLineEmployeeDetails().subscribe(function (lsmanager) {
            _this.linemanager = lsmanager;
        });
        this.modalService.open(id);
    };
    WorklistComponent.prototype.onDelete = function (id) {
        var _this = this;
        var deleteData = { 'id': id };
        if (confirm("Are you sure you want to delete?")) {
            this.data.deleteactivity(deleteData).subscribe(function (data) {
                _this.events = [];
                document.getElementById('closeModal').click();
                _this.bindalldata();
            });
        }
    };
    WorklistComponent.prototype.dayClicked = function (_a) {
        var date = _a.date, events = _a.events;
        if (date_fns_1.isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            if ((date_fns_1.isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0) {
                this.activeDayIsOpen = false;
            }
            else {
                this.activeDayIsOpen = true;
            }
        }
    };
    WorklistComponent.prototype.formatDate = function (date) {
        var monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        return day + ' ' + monthNames[monthIndex] + ' ' + year;
    };
    WorklistComponent.prototype.addMonthsOfYear = function (inc) {
        this.classactive = inc;
        var date = this.viewDate;
        date = date_fns_1.setMonth(date, inc);
        this.viewDate = date;
        this.events = [];
        this.bindalldata();
    };
    WorklistComponent.prototype.eventTimesChanged = function (_a) {
        var event = _a.event, newStart = _a.newStart, newEnd = _a.newEnd;
        event.start = newStart;
        event.end = newEnd;
        this.handleEvent('Dropped or resized', event);
        this.refresh.next();
    };
    WorklistComponent.prototype.handleEvent = function (action, event) {
        this.modalData = { event: event, action: action };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    WorklistComponent.prototype.yearpop = function (id) {
        this.modalService.open(id);
    };
    WorklistComponent.prototype.onyearSubmit = function (leave) {
        this.year = leave;
        var date = this.viewDate;
        date = date_fns_1.setYear(date, this.year);
        this.viewDate = date;
        this.events = [];
        this.bindalldata();
    };
    WorklistComponent.prototype.addNewEvent = function (action, event) {
        this.submitted = false;
        this.editMsg = "";
        this.disableBtn = true;
        this.activity = new activity_model_1.Activity();
        this.Userid = localStorage.getItem('userid');
        this.activity.employeeid = this.Userid;
        this.activity.start = new Date();
        this.activity.end = new Date();
        this.activity.employeehourapplyid = '0';
        this.activity.hourremarkid = '0';
        // this.activity.projectid = '0';
        this.activity.loactionid = '0';
        this.headerdate = this.formatDate(new Date());
        this.modalData = { action: action, event: event };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    WorklistComponent.prototype.addEvent = function (action, event, start) {
        var _this = this;
        this.findLeave = false;
        this.submitted = false;
        this.editMsg = "";
        this.Userid = localStorage.getItem('userid');
        var databydate = { 'id': this.Userid, 'exid': this.datePipe.transform(new Date(start), "dd MMM yyyy") };
        this.leaveService.checkleaveondate(databydate).subscribe(function (userdata) {
            if (userdata && userdata.length) {
                _this.findLeave = true;
            }
        });
        this.disableBtn = true;
        this.activity = new activity_model_1.Activity();
        this.Userid = localStorage.getItem('userid');
        this.activity.employeeid = this.Userid;
        this.activity.start = new Date(start);
        this.activity.end = new Date(start);
        this.headerdate = this.formatDate(new Date(start));
        this.activity.employeehourapplyid = '0';
        this.activity.hourremarkid = '0';
        //this.activity.projectid = '0';
        this.activity.loactionid = '0';
        this.modalData = { action: action, event: event };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    WorklistComponent.prototype.editPopUpEvent = function (action, id, event) {
        var _this = this;
        this.submitted = false;
        this.editMsg = "";
        this.activity = new activity_model_1.Activity();
        this.disableBtn = true;
        this.Userid = localStorage.getItem('userid');
        this.activity.employeeid = this.Userid;
        var employeehourapplyid = id;
        this.data.getactivitydata({ 'id': employeehourapplyid }).subscribe(function (data) {
            _this.activity.ahour = data.ahour;
            _this.activity.start = new Date(data.startdate);
            _this.headerdate = _this.formatDate(new Date(data.startdate));
            _this.activity.end = new Date(data.enddate);
            _this.activity.projectid = data.projectid;
            _this.activity.locationid = data.locationid;
            _this.activity.employeehourapplyid = data.employeehourapplyid;
            _this.activity.hourremarkid = data.hourremarksid;
            _this.activity.hourremark = data.remark.hourremark;
            _this.activity.hourtype = data.hourtypeid;
            _this.activity.hstatus = data.hstatus;
        });
        this.modalData = { action: action, event: event };
        this.modal.open(this.modalContent, { size: 'lg' });
        this.modalService.close('event-viewer');
    };
    WorklistComponent.prototype.editEvent = function (action, event) {
        var _this = this;
        this.submitted = false;
        this.findLeave = false;
        this.editMsg = "";
        this.disableBtn = true;
        this.activity = new activity_model_1.Activity();
        this.Userid = localStorage.getItem('userid');
        this.activity.employeeid = this.Userid;
        var employeehourapplyid = event.id;
        this.data.getactivitydata({ 'id': employeehourapplyid }).subscribe(function (data) {
            _this.activity.ahour = data.ahour;
            _this.activity.start = new Date(data.startdate);
            _this.headerdate = _this.formatDate(new Date(data.startdate));
            _this.activity.end = new Date(data.enddate);
            _this.activity.projectid = data.projectid;
            _this.activity.locationid = data.locationid;
            _this.activity.employeehourapplyid = data.employeehourapplyid;
            _this.activity.hourremarkid = data.hourremarksid;
            _this.activity.hourremark = data.remark.hourremark;
            _this.activity.hourtype = data.hourtypeid;
            _this.activity.hstatus = data.hstatus;
            if (data.hstatus != 1) {
                _this.disableBtn = false;
                _this.editMsg = "You can not edit this expense";
            }
            var databydate = { 'id': _this.Userid, 'exid': _this.datePipe.transform(new Date(data.startdate), "dd MMM yyyy") };
            _this.leaveService.checkleaveondate(databydate).subscribe(function (userdata) {
                if (userdata.length) {
                    _this.findLeave = true;
                }
            });
        });
        this.modalData = { action: action, event: event };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    WorklistComponent.prototype.showAllEvents = function (id, exid) {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        var databydate = { 'id': this.Userid, 'exid': exid };
        this.data.getactivedatabydate(databydate).subscribe(function (userdata) {
            _this.dayexpense = userdata;
        });
        this.modalService.open(id);
    };
    WorklistComponent.prototype.closeModalWork = function (id) {
        this.modalService.close(id);
    };
    WorklistComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return WorklistComponent;
}());
exports.WorklistComponent = WorklistComponent;
