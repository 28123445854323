<div class="bg">
  <app-header></app-header>

  <div class="section-wrapper p-0">
      <div class="admin_dashboard_show">
        <a _ngcontent-c4="" class="backlist mb-4 mr-4" *ngIf="dashboard != 1"  (click)="dashboardchange('1')" ><i _ngcontent-c4="" aria-hidden="true" class="fa fa-angle-left"></i> Back to Check Dashboard</a>



<div class=" upgraderequest" *ngIf="ctype == 2"  >To access Complygate Compliance Centre, please upgrade your account. <button   class="btn btn-primary com_btn small_btn ng-star-inserted float-right"  [routerLink]="['/upgrade-your-membership']">Upgrade Your Account</button></div>


        <div class="ceee width100">

            <div class="cardmenu" *ngIf="dashboard==1">
                <a class="card_box_lit" (click)="dashboardchange('2')" *ngIf="permission.m101 > 0" [ngClass]="{'not-active': ctype == 2}">
                  <div class="samebox">
                    <i class="lnr lnr-file-empty"></i>
                    <div class="menu_content">
                        <h4>Identity Verification</h4>
                        <span class="count_rem_value">{{ identityCheck }}</span>
                    </div>
                  </div>
                </a>
                <a class="card_box_lit" (click)="dashboardchange('3')" *ngIf="permission.m96 > 1" [ngClass]="{'not-active': ctype == 2}">
                  <div class="samebox">
                    <i class="lnr lnr-license"></i>
                    <div class="menu_content">
                        <h4>AML, PEPs And Sanction Check</h4>
                        <span class="count_rem_value">{{ amlPepCheck }}</span>
                    </div>
                  </div>
                </a>
                <a class="card_box_lit" (click)="dashboardchange('4')" *ngIf="permission.m189 > 1" [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                      <i class="lnr lnr-license"></i>
                      <div class="menu_content">
                          <h4>Global Due Diligence </h4>
                          <span class="count_rem_value">{{ globalpepcheck }}</span>
                      </div>
                    </div>
                </a>
                <a class="card_box_lit"    [routerLink]="['/search-personal-aml','adverse']"    *ngIf="permission.m179 > 0"  [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-laptop-phone"></i>
                        <div class="menu_content">
                            <span class="count_rem_value">
                                {{ advesemedia }}</span>
                            <h4>Adverse Media Search</h4>
                            <!-- <span>Coming Soon</span> -->
                        </div>
                    </div>
                </a>
                <a class="card_box_lit" [routerLink]="['/dbs/basic']" *ngIf="permission.m109 > 0" [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-apartment"></i>
                        <div class="menu_content">
                            <h4> Basic DBS</h4>
                            <span class="count_rem_value">{{ basicDbs }}</span>
                        </div>
                    </div>
                </a>
                <a class="card_box_lit" [routerLink]="['/dbs/standard']" *ngIf="permission.m114 > 0" [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-apartment"></i>
                        <div class="menu_content">
                            <h4> Standard DBS</h4>
                            <span class="count_rem_value">{{ standardDbs }}</span>
                        </div>
                    </div>
                </a>
                <a class="card_box_lit" [routerLink]="['/dbs/enhanced']" *ngIf="permission.m119 > 0" [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-license"></i>
                    <div class="menu_content">
                        <h4> Enhanced DBS </h4>
                        <span class="count_rem_value">{{ enhancedDbs }}</span>
                    </div>
                    </div>
                </a>

                <a class="card_box_lit  " [routerLink]="['/right-to-work-check']" *ngIf="permission.m124 > 0" [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-frame-expand"></i>
                    <div class="menu_content">
                        <h4> Right To Work Check</h4>
                        <span class="count_rem_value">{{ rtw }}</span>
                    </div>
                    </div>
                </a>
                
                <a  class="card_box_lit"   [routerLink]="['/dvla-check']"  *ngIf="permission.m129 > 0" [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-file-add"></i>
                        <div class="menu_content">
                            <h4> DVLA Check</h4>
                            <span class="count_rem_value">{{ dvla }}</span>
                        </div>
                    </div>
                </a>

                <a  class="card_box_lit" [routerLink]="['/tax-defaulter-check']" *ngIf="permission.m134 > 0" [ngClass]="{'not-active': ctype == 2}">
                  <div class="samebox">
                      <i class="lnr lnr-file-add"></i>
                      <div class="menu_content">
                          <h4> Tax Defaulters Check</h4>
                          <span class="count_rem_value">{{ taxDefaulter }}</span>
                      </div>
                  </div>
                </a>

                <a class="card_box_lit" [routerLink]="['/adversecredit']" *ngIf="permission.m139 > 0" [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-location"></i>
                        <div class="menu_content">
                          <span class="count_rem_value">{{ adverseCredit }}</span>
                            <h4> Adverse Credit Check</h4>
                        </div>
                    </div>
                </a>
               <!-- <a class="card_box_lit" [routerLink]="['/ai-document-verification']"  [ngClass]="{'not-active': ctype == 2}">
                  <div class="samebox">
                      <i class="lnr lnr-file-empty"></i>
                      <div class="menu_content">
                        <span class="count_rem_value">
                           0</span>
                          <h4> AI Document Verification</h4>

                      </div></div>
                  </a> -->

                 


            <a class="card_box_lit" [routerLink]="['/reference-dashboard']"  *ngIf="permission.m184 > 0"  [ngClass]="{'not-active': ctype == 2}">
                <div class="samebox">
                    <i class="lnr lnr-file-empty"></i>
                    <div class="menu_content">
                        <span class="count_rem_value">
                        {{ refcredit }}</span>
                        <h4> Automated Reference Check</h4>
                        <!-- <span>Coming Soon</span> -->
                    </div></div>
                </a>

               <a class="card_box_lit"    [routerLink]="['/director-search']"  [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-laptop-phone"></i>
                        <div class="menu_content">
                            <span class="count_rem_value">
                                {{ dircredit }}</span>
                            <h4>Director Search</h4>
                            <!-- <span>Coming Soon</span> -->
                        </div>
                    </div>
                </a>


                <!-- New Check include-->

                <a class="card_box_lit"    [routerLink]="['/dbs/access-ni-basic']"  *ngIf="permission.m144 > 0"  [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-laptop-phone"></i>
                        <div class="menu_content">
                            <span class="count_rem_value">
                                {{ basicniDbs }}</span>
                            <h4>AccessNI Basic Check</h4>
                            <!-- <span>Coming Soon</span> -->
                        </div>
                    </div>
                </a>

                <a class="card_box_lit"  [routerLink]="['/dbs/access-ni-standard']"  *ngIf="permission.m149 > 0"  [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-laptop-phone"></i>
                        <div class="menu_content">
                            <span class="count_rem_value">
                                {{ standardniDbs }}</span>
                            <h4>AccessNI Standard Check</h4>
                            <!-- <span>Coming Soon</span> -->
                        </div>
                    </div>
                </a>

                <a class="card_box_lit"    [routerLink]="['/dbs/access-ni-enhanced']"   *ngIf="permission.m154 > 0"  [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-laptop-phone"></i>
                        <div class="menu_content">
                            <span class="count_rem_value">
                                {{ enhancedniDbs }}</span>
                            <h4>AccessNI Enhanced Check</h4>
                            <!-- <span>Coming Soon</span> -->
                        </div>
                    </div>
                </a>

                <a class="card_box_lit"    [routerLink]="['/dbs/scotland-basic-disclosure']"  *ngIf="permission.m160 > 0"  [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-laptop-phone"></i>
                        <div class="menu_content">
                            <span class="count_rem_value">
                                {{ basicSDbs }}</span>
                            <h4>Scotland Basic Disclosure</h4>
                            <!-- <span>Coming Soon</span> -->
                        </div>
                    </div>
                </a>

                <a class="card_box_lit"   [routerLink]="['/dbs/scotland-standard-disclosure']"   *ngIf="permission.m1 > 0"  [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-laptop-phone"></i>
                        <div class="menu_content">
                            <span class="count_rem_value">
                                {{ standardSDbs }}</span>
                            <h4>Scotland Standard Disclosure</h4>
                            <!-- <span>Coming Soon</span> -->
                        </div>
                    </div>
                </a>

                <a class="card_box_lit"    [routerLink]="['/dbs/scotland-enhanced-disclosure']"   *ngIf="permission.m170 > 0"  [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-laptop-phone"></i>
                        <div class="menu_content">
                            <span class="count_rem_value">
                                {{ enhancedSDbs }}</span>
                            <h4>Scotland Enhanced Disclosure</h4>
                            <!-- <span>Coming Soon</span> -->
                        </div>
                    </div>
                </a>

                <a class="card_box_lit"    [routerLink]="['/dbs/social-media']"  *ngIf="permission.m174 > 0"  [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-laptop-phone"></i>
                        <div class="menu_content">
                            <span class="count_rem_value">
                                {{ socilmedia }}</span>
                            <h4>Social Media Check</h4>
                            <!-- <span>Coming Soon</span> -->
                        </div>
                    </div>
                </a>

              






                <a [routerLink]="['/register-fca']"   class="card_box_lit not-active" >
                    <div class="samebox">
                        <i class="lnr lnr-printer"></i>
                        <div class="menu_content">
                            <span class="count_rem_value">
                                {{ fcacredit }}</span>
                            <h4>FCA Search</h4>
                             <!-- <span>Coming Soon</span> -->
                        </div>
                    </div>
                <!-- </div> -->
            </a>   
            <a class="card_box_lit not-active" [routerLink]="['/company-advert-search']" >
                <div class="samebox">
                    <i class="lnr lnr-file-empty"></i>
                    <div class="menu_content">
                      <span class="count_rem_value">
                        {{ companycredit }}</span>
                        <h4> Business Credit Check</h4>
                    </div>
                </div>
               </a>

                <!-- End New Check -->

                <a class="card_box_lit"    [routerLink]="['/knwlage-center']"  [ngClass]="{'not-active': ctype == 2}">
                    <div class="samebox">
                        <i class="lnr lnr-book"></i>
                        <div class="menu_content">                           
                            <h4>Knowledge Centre</h4>
                            <!-- <span>Coming Soon</span> -->
                        </div>
                    </div>
                </a>

            
            <a [routerLink]="['/right-to-work-app-history-list-admin/0']"   class="card_box_lit" *ngIf="admindetails">
                <div class="samebox">
                    <i class="lnr lnr-printer"></i>
                    <div class="menu_content">
                        <!-- <span class="count_rem_value">
                          &nbsp;</span> -->
                        <h4>Right To Work Request</h4>
                         <!-- <span>Coming Soon</span> -->
                    </div>
                </div>
            <!-- </div> -->
        </a>         


        <a [routerLink]="['/ai-approve-rejected']"   class="card_box_lit" *ngIf="admindetails">
            <div class="samebox">
                <i class="lnr lnr-printer"></i>
                <div class="menu_content">
                    <!-- <span class="count_rem_value">
                      &nbsp;</span> -->
                    <h4>Document Request</h4>
                     <!-- <span>Coming Soon</span> -->
                </div>
            </div>
        <!-- </div> -->
    </a> 

    <a [routerLink]="['/aml-monitoring']"   class="card_box_lit" *ngIf="admindetails">
        <div class="samebox">
            <i class="lnr lnr-license"></i>
            <div class="menu_content">
                <!-- <span class="count_rem_value">
                  &nbsp;</span> -->
                <h4>AML Monitoring</h4>
                 <!-- <span>Coming Soon</span> -->
            </div>
        </div>
    <!-- </div> -->
</a> 
    
    

        
      </div>
      <div class="cardmenu" *ngIf="dashboard==2">
            <a class="card_box_lit" [routerLink]="['/check-employee-list', 'visa']" [ngClass]="{'not-active': ctype == 2}">
            <div class="samebox">
                <i class="lnr lnr-license"></i>
                <div class="menu_content">
                    <h4>BRP Check</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
            </div>
            </a>
            <a class="card_box_lit" [routerLink]="['/check-employee-list', 'passport']"  [ngClass]="{'not-active': ctype == 2}">
            <div class="samebox">
                <i class="lnr lnr-earth"></i>
                <div class="menu_content">
                    <h4>Passport Check</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
            </div>
            </a>
            <a class="card_box_lit" [routerLink]="['/check-employee-list','document']" [ngClass]="{'not-active': ctype == 2}">
            <div class="samebox">
                <i class="lnr lnr-book"></i>
                <div class="menu_content">
                    <h4>Driving Licence Check</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
            </div>
            </a>
        </div>

        <div class="cardmenu" *ngIf="dashboard==3">

            <!-- <a class="card_box_lit" [routerLink]="['/check-personal-aml-peps-sanction']" [ngClass]="{'not-active': ctype == 2}">
                <div class="samebox">
                    <i class="lnr lnr-license"></i>
                <div class="menu_content">
                    <h4>Personal AML, PEPs & Sanction </h4>
                </div>
                </div>
            </a>

            <a class="card_box_lit" [routerLink]="['/check-business-aml-peps-sanction']" [ngClass]="{'not-active': ctype == 2}">
                <div class="samebox">
                    <i class="lnr lnr-license"></i>
                <div class="menu_content">
                    <h4>Business AML, PEPs & Sanction</h4>
                    
                </div>
                </div>
            </a> -->

            <a class="card_box_lit" [routerLink]="['/search-personal-aml','aml']" [ngClass]="{'not-active': ctype == 2}"   >
                <div class="samebox">
                    <i class="lnr lnr-license"></i>
                <div class="menu_content">
                    <h4>Personal AML, PEPs & Sanction</h4>                    
                </div>
                </div>
            </a>


            <a class="card_box_lit" [routerLink]="['/search-business-aml','aml']" [ngClass]="{'not-active': ctype == 2}"  >
                <div class="samebox">
                    <i class="lnr lnr-license"></i>
                <div class="menu_content">
                    <h4>Business AML, PEPs & Sanction</h4>
                </div>
                </div>
            </a>




        </div>



        
  <div class="cardmenu" *ngIf="dashboard==4">

    <a class="card_box_lit" [routerLink]="['/search-personal-aml','global']" [ngClass]="{'not-active': ctype == 2}"   >
        <div class="samebox">
            <i class="lnr lnr-license"></i>
        <div class="menu_content">
            <h4>Individual</h4>                    
        </div>
        </div>
    </a>


    <a class="card_box_lit" [routerLink]="['/search-business-aml','global']" [ngClass]="{'not-active': ctype == 2}"  >
        <div class="samebox">
            <i class="lnr lnr-license"></i>
        <div class="menu_content">
            <h4>Business</h4>
        </div>
        </div>
    </a>
</div>

        </div>
        </div>

  </div>




  <jw-modal id="Em_support" class="modal Em_support" role="dialog">

      <form [formGroup]="visapopForm" #visaForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(visaForm.value,'Em_support')">

      <div role="document" class="formgroup" >
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">
                  Support
                      </h5>
                  <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('Em_support');">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body" >
                  <div class="container">

                          <!-- <input type="hidden" formControlName="id" class="form-control" name="id" id="id" [(ngModel)]="leave.employeeholidayapplyid">
                          <input type="hidden" formControlName="holidayremarkid" class="form-control" required name="holidayremarkid" id="holidayremarkid" [(ngModel)]="leave.holidayremarkid">
                          <input type="hidden" formControlName="employeeid" class="form-control" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="leave.employeeid"> -->
                          <div class="formgroup">
                              <div class="row row-sm mg-t-10">
                                  <div class="col-sm-6 mb-2 ">
                                      <label class="labelresize mb-0">Full Name*</label>

                                  <input type="text" formControlName="fname" name="fname" id="fname"  class="form-control"  [maxlength]="100" placeholder="Enter Full Name">
                                  <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                                      <div *ngIf="f.fname.errors.required">Please Enter Full Name</div>
                                      <div *ngIf="!f.fname.errors.required && f.fname.errors.minlength">Minimum 3 characters Required</div>
                                  </div>
                                  </div>
                                  <div class="col-sm-6 mb-2">
                                      <label class="labelresize mb-0">Business Email*</label>

                                      <input type="text" formControlName="bussinessemail" name="bussinessemail" id="bussinessemail"  class="form-control"  [maxlength]="100" placeholder="Enter Business Email">
                                       <div *ngIf="submitted && f.bussinessemail.errors" class="invalid-feedback">
                                          <div *ngIf="f.bussinessemail.errors.required">Please Enter Business Email</div>
                                          <!-- <div *ngIf="!f.bussinessemail.errors.required && f.bussinessemail.errors.minlength">Minimum 3 characters Required</div> -->
                                      </div>
                                  </div>
                                  <div class="col-sm-6 mb-2">
                                      <label class="labelresize mb-0">Phone Number*</label>

                                      <input type="text" formControlName="phonenumber" name="phonenumber" id="phonenumber"  class="form-control"  [maxlength]="100" placeholder="Enter Phone Number">
                                       <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                          <div *ngIf="f.phonenumber.errors.required">Please Enter Phone Number</div>
                                          <!-- <div *ngIf="!f.phonenumber.errors.required && f.phonenumber.errors.minlength">Minimum 3 characters Required</div> -->
                                      </div>
                                  </div>
                                  <div class="col-sm-6 mb-2">
                                      <label class="mb-0 labelresize">Query Type*</label>
                                      <select class="form-control" [(ngModel)]='nrSelect'  name="querytype" id="querytype"   formControlName="querytype">
                                      <option [value]="nrSelect">Select Query Type</option>
                                      <option value="Technical Support" >Technical Support</option>
                                      <option  value="Billing Support" >Billing Support</option>
                                      <option value="Application Support" >Application Support</option>
                                      <option value="Others" >Others</option>

                                      <!-- <option *ngFor="let ltype of leavetypelist" [value]="ltype.id">{{ ltype.typename }}</option> -->
                                      </select>
                                       <div *ngIf="submitted && f.querytype.errors" class="invalid-feedback">
                                          <div *ngIf="f.querytype.errors.required">Please Select Query Type</div>
                                      </div>
                                  </div>
                                  <div class="col-sm-12 mb-2">
                                      <label class="labelresize mb-0">Subject*</label>

                                      <input type="text" formControlName="subject" name="subject" id="subject" class="form-control"  [maxlength]="100" placeholder="Enter Subject">
                                       <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                          <div *ngIf="f.subject.errors.required">Please Enter Your Subject</div>
                                          <!-- <div *ngIf="!f.subject.errors.required && f.subject.errors.minlength">Minimum 3 characters Required</div> -->
                                      </div>
                                  </div>
                                  <div class="col-sm-12">
                                      <label class="mb-0 labelresize">Your Message*</label>
                                      <textarea   class="form-control" formControlName="message"  required name="message" id="message"  maxlength="500" placeholder="Enter remarks here"></textarea>
                                      <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                          <div *ngIf="f.message.errors.required">Please Enter Your Message</div>
                                      </div>
                                  </div>


                              </div>
                          </div>


                  </div>



              </div>
               <div class="modal-footer">
                   <div class="row">
                       <div class="col-lg-12">
                          <button  type="submit" class="btn btn-primary com_btn"> Submit</button>
                          <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal" (click)="closeModal('Em_support');">Close</button>
                       </div>
                   </div>

              </div>


          </div>
      </div>

  </form>
  </jw-modal>
