import { Component, OnInit } from '@angular/core';
import { WorklistService } from './../../services/worklist.service';
import { ModalService } from '../../_services/modal.service';
import { CustomerService } from '../../services/customer.service';
import { AppGlobals } from '../../app.global';

@Component({
  selector: 'app-timesheetapproval',
  templateUrl: './timesheetapproval.component.html',
  styleUrls: ['./timesheetapproval.component.css']
})

export class TimesheetapprovalComponent implements OnInit {
  timesheetdata: any[];
  Userid: any;
  month: any;
  year: any;
  yearmonth: any;
  employeeid: any;
  employeelist: any;
  classactive: string;
  record: any;
  hstatus: any;
  monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];
  yearList: any;

  constructor(private dataset: WorklistService, private modalService: ModalService, private customer: CustomerService) { }

  ngOnInit() {
    this.yearList = AppGlobals.YEAR_VALUES;

    this.hstatus = '';
    this.month = (new Date()).getMonth();
    this.year = (new Date()).getFullYear();
    this.yearmonth = this.monthNames[this.month] + ' ' + (new Date()).getFullYear();
    this.classactive = ((new Date()).getMonth() + 1).toString();

    var dd ={j:1};
    this.customer.getchildemployee(dd).subscribe((data: any) => {
      this.employeelist = data;
    });
    this.bindalldata();
  }


  onChange(newValue) {
    this.employeeid = newValue;
    this.bindalldata();
  }

  onChangestatus(newValue) {
    this.hstatus = newValue;
    this.bindalldata();
  }

  bindalldata() {
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'month': this.month, 'year': this.year, 'employeeid': this.employeeid, 'hstatus': this.hstatus };
    this.dataset.getemployeeworklistreportrequest(data).subscribe((data: any) => {

      // console.log(data);
      this.record = data.length;      

      
      this.timesheetdata = data;
    });
  }

  onStatus(id: number, status: number) {
    const deleteData = { 'id': id, 'hstatus': status, 'userid': this.Userid, 'statusdate': new Date() };
    var msg = '';
    if (status == 2) {
      msg = 'Are you sure you want to approve?';
    } else {
      msg = 'Are you sure you want to reject?';
    }

    if (confirm(msg)) {
      this.dataset.statusactivity(deleteData).subscribe((data: any) => {
        this.bindalldata();
      });
    }
  }

  onyearSubmit(year) {
    this.year = year;
    this.yearmonth = this.monthNames[this.month - 1] + ' ' + this.year;// (new Date()).getFullYear();
    this.bindalldata();
    // this.modalService.close(id);
  }

  yearpop(id: string) {
    this.modalService.open(id);
  }

  getDataByMonth(mon) {
    this.classactive = mon;
    this.month = mon - 1;
    this.yearmonth = this.monthNames[this.month] + ' ' + this.year;
    this.bindalldata();
    // const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    // this.dataset.getemployeeworklistreport(data).subscribe((data: any) => {
    //   console
    //  this.timesheetdata = data;
    // });
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  getDataByYear(yea) {
    this.year = yea;
    this.yearmonth = this.monthNames[this.month - 1] + ' ' + yea;
    this.bindalldata();
    // const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    // this.dataset.getemployeeworklistreport(data).subscribe((data: any) => {
    //  this.timesheetdata = data;
    // });
  }
}