"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var user_service_1 = require("../../services/user.service");
var customer_service_1 = require("../../services/customer.service");
var router_1 = require("@angular/router");
var profile_model_1 = require("../../models/overview/profile.model");
var company_service_1 = require("../../services/company.service");
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(data, router, userService, companyService, renderer, renderer1) {
        var _this = this;
        this.data = data;
        this.router = router;
        this.userService = userService;
        this.companyService = companyService;
        this.renderer = renderer;
        this.renderer1 = renderer1;
        this.href = "";
        this.show = false;
        this.rightshow = false;
        this.rightshow2 = false;
        this.buttonName = 'Show';
        this.overviewData = new profile_model_1.Profile();
        this.renderer.listen('window', 'click', function (e) {
            if (_this.megamenuopen && e.target !== _this.megamenuopen.nativeElement && e.target !== _this.megamenu.nativeElement) {
                _this.rightshow = false;
            }
        });
        // this.renderer1.listen('window', 'click',(e:Event)=>{
        //   if(e.target !== this.megamenuopen1.nativeElement && e.target!== this.megamenu1.nativeElement){
        //     this.rightshow2 = false;
        //     }
        //   });
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        this.menu = localStorage.getItem('menu');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        else {
            this.userService.checkaccesstokenbyid(userid).subscribe(function (data) {
                if (data.status == false) {
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('userid');
                    localStorage.removeItem('Name');
                    localStorage.removeItem('isline');
                    localStorage.removeItem('planid');
                    localStorage.removeItem('isemail');
                    localStorage.removeItem('isadmin');
                    localStorage.removeItem('ispermission');
                    localStorage.removeItem('ctype');
                    localStorage.removeItem('logoname');
                    localStorage.removeItem('menu');
                    _this.router.navigate(['/login']);
                }
            });
        }
        this.data.getloginbytokencheck().subscribe(function (data) {
            if (data.success == '1') {
                _this.router.navigate(['/' + data.url]);
            }
        });
        console.log('verify system', localStorage.getItem('isverify'));
        var isverify = localStorage.getItem('isverify');
        this.ctype = parseInt(localStorage.getItem('ctype'));
        console.log('Is verify', isverify);
        if (this.ctype == 1) {
            if (isverify === '0') {
                this.router.navigate(['/payment-confirmation']);
            }
        }
        // console.log(this.ctype);
        // if(this.ctype ==1)
        // { 
        //   this.companyService.getcompanydetails().subscribe((data: any) => {
        //     if (!data.stuser) {               
        //       this.router.navigate(['/payment-confirmation']);
        //     }else if (data.verfiedforcheck === false ||  data.verfiedforcheck === 0)
        //     { 
        //       this.router.navigate(['/approval-confirmation']);          
        //     }
        //   });
        // }
        // if(this.ctype ==3)
        // { 
        //   this.companyService.getcompanydetails().subscribe((data: any) => {
        //     if (!data.stuser) {               
        //       this.router.navigate(['/payment-confirmation']) ;
        //     }
        //   });
        // }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        // console.log(this.permission);
        if (innerWidth > 414) {
            this.show = true;
        }
        this.Name = localStorage.getItem('Name');
        this.data.getOverview().subscribe(function (data) {
            _this.overviewData = data;
        });
        this.innerWidth = window.innerWidth;
        var planid = localStorage.getItem('planid');
        // if (planid == '0' || planid == null || planid == undefined) {
        //   this.router.navigate(['/choose-plan']);
        // }
        this.isadmin = localStorage.getItem('isadmin');
        // console.log(this.isadmin);
        this.isline = localStorage.getItem('isline');
        this.logo = localStorage.getItem('logoname');
        if ((this.logo == "") || (this.logo == null)) {
            this.logo = "assets/img/logo.png";
        }
        // else
        // {
        //   this.logo = data.logoname;
        // }
        this.data.getOverview().subscribe(function (data) {
            _this.overviewData = data;
            if (_this.overviewData.emailid == undefined) {
                localStorage.removeItem('userToken');
                localStorage.removeItem('userid');
                localStorage.removeItem('Name');
                localStorage.removeItem('isline');
                localStorage.removeItem('planid');
                localStorage.removeItem('isemail');
                localStorage.removeItem('isadmin');
                localStorage.removeItem('ispermission');
                localStorage.removeItem('ctype');
                localStorage.removeItem('logoname');
                localStorage.removeItem('menu');
                _this.router.navigate(['/login']);
            }
        });
    };
    HeaderComponent.prototype.menuchange = function (id) {
        localStorage.setItem('menu', id);
        if (id == 1) {
            this.router.navigate(['/admindashboard']);
        }
        else {
            this.router.navigate(['/dashboard']);
        }
    };
    HeaderComponent.prototype.toggle = function () {
        if (innerWidth == 768) {
            this.show = !this.show;
        }
        else if (innerWidth == 414) {
            this.show = !this.show;
        }
    };
    HeaderComponent.prototype.righttoggle = function (id) {
        this.rightshow = !this.rightshow;
        this.rightshow2 = false;
    };
    HeaderComponent.prototype.righttoggle2 = function (id) {
        this.rightshow2 = !this.rightshow2;
        this.rightshow = false;
    };
    HeaderComponent.prototype.logout = function () {
        var _this = this;
        if (confirm("Are you sure you want to sign out?")) {
            this.userService.logout().subscribe(function (data) {
                localStorage.removeItem('userToken');
                localStorage.removeItem('userid');
                localStorage.removeItem('Name');
                localStorage.removeItem('isline');
                localStorage.removeItem('planid');
                localStorage.removeItem('isemail');
                localStorage.removeItem('isadmin');
                localStorage.removeItem('ispermission');
                localStorage.removeItem('logoname');
                localStorage.removeItem('menu');
                _this.router.navigate(['/login']);
            });
            ;
        }
    };
    return HeaderComponent;
}());
exports.HeaderComponent = HeaderComponent;
