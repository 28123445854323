"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var plan_model_1 = require("../../models/plan.model");
var checksystem_service_1 = require("../../services/checksystem.service");
var stripe_angular_1 = require("stripe-angular");
var app_global_1 = require("../../app.global");
var checkservice_service_1 = require("../../services/checkservice.service");
var modal_service_1 = require("../../_services/modal.service");
var platform_browser_1 = require("@angular/platform-browser");
// import { Stripe , loadStripe } from "@stripe/stripe-js";
var company_service_1 = require("../../services/company.service");
var country_service_1 = require("../../services/country.service");
var address_model_1 = require("../../models/address/address.model");
var payment_service_1 = require("../../services/payment.service");
var alert_service_1 = require("../../services/alert.service");
var CheckoutComponent = /** @class */ (function () {
    function CheckoutComponent(document, formBuilder, StripeScriptTag, check, checkService, modalService, companyService, paymentService, alerts, country, sanitizer) {
        this.document = document;
        this.formBuilder = formBuilder;
        this.StripeScriptTag = StripeScriptTag;
        this.check = check;
        this.checkService = checkService;
        this.modalService = modalService;
        this.companyService = companyService;
        this.paymentService = paymentService;
        this.alerts = alerts;
        this.country = country;
        this.sanitizer = sanitizer;
        this.getRemainingChecks = new core_1.EventEmitter();
        this.iscustomer = false;
        this.remainitem = new plan_model_1.checkbiling();
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = false;
        this.tab4 = false;
        this.wizard1 = false;
        this.wizard2 = false;
        this.wizard3 = false;
        this.wizard4 = false;
        this.paymentaut = true;
        this.iframepaymentautsrc = '';
        this.serviceArray = [];
        this.serviceObjectArray = [];
        this.gtotal = 0;
        this.tax = 0;
        this.tax_rate = 20;
        this.paynow = false;
        this.showAddCheck = false;
        this.paymentloading = false;
        this.paymentDone = false;
        this.publishableKey = app_global_1.AppGlobals.API_STRIPE;
        this.cvvError = false;
        this.submitted = false;
        this.paymentcheck = false;
    }
    CheckoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log('select All check');
        // this.iframepaymentautsrc = this.getSafeUrl('http://localhost:4200/check-add-credit');
        this.errormsg = '';
        this.StripeScriptTag.setPublishableKey(this.publishableKey);
        this.wizard1 = true;
        this.tab1 = true;
        this.isviewcart = false;
        this.paymentloading = false;
        this.changetoggle = false;
        this.getRemainingCheckss();
        this.remainitem = new plan_model_1.checkbiling();
        this.checkService.getAllServices().subscribe(function (services) {
            _this.services = services;
            _this.selectedService();
            console.log('services list', _this.services);
            _this.ctype = parseInt(localStorage.getItem('ctype'));
            if (_this.ctype === 3) {
                _this.onFilterChange(11);
                _this.isviewcart = true;
            }
            else if (_this.ctype === 2) {
                // this.onFilterChange(10);
                _this.isviewcart = true;
            }
        });
        this.addpopForm = this.formBuilder.group({
            name: ['', forms_1.Validators.required],
            address1: ['', forms_1.Validators.required],
            address2: [''],
            cityname: ['', forms_1.Validators.compose([forms_1.Validators.required,])],
            statename: ['', forms_1.Validators.required],
            pincode: ['', forms_1.Validators.required],
            stateid: [''],
            taxid: [''],
            countryid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            cardid: [''],
            customerid: ['']
        });
        this.companyService.gettotalcards().subscribe(function (data) {
            data.card.forEach(function (card) {
                if (card.id == data.customer.default_source) {
                    _this.defaultcard = card;
                }
            });
            _this.cardlist = data.card;
            _this.customerData = data.customer;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.addresslistDetail = new address_model_1.Address();
        this.addresslistDetail.countryid = '70';
    };
    CheckoutComponent.prototype.viewcart = function () {
        this.isviewcart = true;
    };
    CheckoutComponent.prototype.viewcheck = function () {
        this.paymentcheck = false;
        if (this.ctype === 3) {
            this.wizard3 = false;
            this.onFilterChange(11);
            this.isviewcart = true;
        }
        else {
            this.selectedService();
            this.isviewcart = false;
            this.toggle(1);
        }
    };
    CheckoutComponent.prototype.keyPress = function (event) {
        var pattern = /[0-9]/;
        var inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    };
    CheckoutComponent.prototype.getRemainingCheckss = function () {
        var _this = this;
        this.companyService.getcompanyData().subscribe(function (data) {
            if (data.stuser) {
                _this.paymentDone = true;
            }
        });
        this.companyService.getcompanyData().subscribe(function (data) {
            if (data.verifiedforcheck == 1) {
                _this.showAddCheck = true;
            }
        });
        var ts = {};
        this.check.getAllServices().subscribe(function (data) {
            _this.serviceArraycredit = data;
            console.log('system', data);
            _this.leaves = [];
            _this.serviceArraycredit.forEach(function (element) {
                ts = { 'serviceid': element.id };
                _this.check.getbillinginformation(ts).subscribe(function (remaindata) {
                    _this.leaves.push({
                        orderby: element.orderby,
                        id: element.id,
                        servicename: element.servicename,
                        totalcheck: remaindata.totalcheck,
                        usedcheck: remaindata.usedcheck,
                        remiancheck: remaindata.remiancheck,
                        displayicon: element.displayicon
                    });
                    console.log('leaves', data);
                    _this.leaves.sort(function (a, b) { return a.orderby > b.orderby; });
                });
            });
        });
    };
    Object.defineProperty(CheckoutComponent.prototype, "g", {
        get: function () { return this.addpopForm.controls; },
        enumerable: true,
        configurable: true
    });
    CheckoutComponent.prototype.selectedService = function () {
        var _this = this;
        this.services.forEach(function (element) {
            if (_this.serviceArray.indexOf(element.id) > -1) {
                var exist = false;
                _this.serviceObjectArray.forEach(function (checkEle) {
                    if (checkEle.id == element.id) {
                        exist = true;
                    }
                });
                if (!exist) {
                    element.quantity = 1;
                    element.total = element.prices;
                    if (element.servicefee > 0) {
                        element.taxable_amount = element.servicefee;
                    }
                    else {
                        element.taxable_amount = element.prices;
                    }
                    _this.tax = _this.tax + (element.taxable_amount) * _this.tax_rate / 100;
                    _this.gtotal = _this.gtotal + element.total;
                    _this.serviceObjectArray.push(element);
                }
            }
            else {
                _this.serviceObjectArray.forEach(function (checkEle, index) {
                    if (checkEle.id == element.id) {
                        _this.tax = _this.tax - (checkEle.taxable_amount * checkEle.quantity) * _this.tax_rate / 100;
                        _this.gtotal = _this.gtotal - ((checkEle.prices) * checkEle.quantity);
                        _this.serviceObjectArray.splice(index, 1);
                    }
                });
            }
            if (_this.serviceObjectArray.length == 0) {
                _this.wizard1 = true;
                _this.isviewcart = false;
            }
        });
        // console.log(this.serviceObjectArray);
    };
    CheckoutComponent.prototype.onFilterChange = function (eve) {
        var index = this.serviceArray.indexOf(Number(eve), 0);
        if (index > -1) {
            this.serviceArray.splice(index, 1);
        }
        this.serviceArray.push(Number(eve));
        // if(eve.target.checked == false)
        // // {
        //   const index = this.serviceArray.indexOf(Number(eve), 0);
        //   if (index > -1) {
        //     this.serviceArray.splice(index, 1);
        //   }
        // }
        // else
        // {
        // }
        this.selectedService();
    };
    CheckoutComponent.prototype.onDeleteFilter = function (id) {
        // const ele = <HTMLInputElement> document.getElementById("inlineRadio"+id);
        // ele.checked = false;
        var index = this.serviceArray.indexOf(Number(id), 0);
        this.serviceArray.splice(index, 1);
        this.selectedService();
    };
    CheckoutComponent.prototype.inputVal = function (qty, id) {
        var _this = this;
        this.serviceObjectArray.forEach(function (checkEle, index) {
            if (checkEle.id == id) {
                if (qty == '' || qty == 0) {
                    qty = 1;
                }
                _this.tax = _this.tax - _this.serviceObjectArray[index].taxable_amount * _this.serviceObjectArray[index].quantity * _this.tax_rate / 100;
                _this.serviceObjectArray[index].quantity = qty;
                _this.gtotal = _this.gtotal - _this.serviceObjectArray[index].total;
                _this.serviceObjectArray[index].total = _this.serviceObjectArray[index].quantity * _this.serviceObjectArray[index].prices;
                _this.tax = _this.tax + _this.serviceObjectArray[index].taxable_amount * _this.serviceObjectArray[index].quantity * _this.tax_rate / 100;
                _this.gtotal = _this.gtotal + _this.serviceObjectArray[index].total;
            }
        });
    };
    CheckoutComponent.prototype.inc_qty = function (id) {
        var _this = this;
        this.serviceObjectArray.forEach(function (checkEle, index) {
            if (checkEle.id == id) {
                _this.serviceObjectArray[index].quantity++;
                _this.tax = _this.tax + (_this.serviceObjectArray[index].taxable_amount) * _this.tax_rate / 100;
                _this.serviceObjectArray[index].total = _this.serviceObjectArray[index].quantity * _this.serviceObjectArray[index].prices;
                _this.gtotal = _this.gtotal + _this.serviceObjectArray[index].prices;
            }
        });
    };
    CheckoutComponent.prototype.dec_qty = function (id) {
        var _this = this;
        this.serviceObjectArray.forEach(function (checkEle, index) {
            if (checkEle.id == id) {
                _this.serviceObjectArray[index].quantity--;
                _this.tax = _this.tax - (_this.serviceObjectArray[index].taxable_amount) * _this.tax_rate / 100;
                _this.serviceObjectArray[index].total = _this.serviceObjectArray[index].quantity * _this.serviceObjectArray[index].prices;
                _this.gtotal = _this.gtotal - _this.serviceObjectArray[index].prices;
            }
        });
    };
    CheckoutComponent.prototype.toggle = function (id) {
        var _this = this;
        if (id == 1) {
            this.wizard2 = false;
            this.wizard1 = true;
            this.wizard3 = false;
            this.wizard4 = false;
            this.checkService.getAllServices().subscribe(function (services) {
                _this.services = services;
                _this.selectedService();
            });
        }
        if (id == 2) {
            this.wizard2 = true;
            this.wizard1 = false;
            this.wizard3 = false;
            this.wizard4 = false;
        }
        if (id == 3) {
            this.wizard3 = true;
            this.wizard1 = false;
            this.wizard2 = false;
            this.wizard4 = false;
        }
        if (id == 4) {
            this.wizard3 = false;
            this.wizard4 = true;
            this.wizard1 = false;
            this.wizard2 = false;
        }
    };
    CheckoutComponent.prototype.toggle2 = function (id) {
        if (id == 1) {
            this.wizard2 = false;
            this.wizard1 = true;
            this.wizard3 = false;
        }
        if (id == 2) {
            this.wizard3 = false;
            this.wizard1 = false;
            this.wizard2 = true;
        }
    };
    // async sessPay(id){
    //   const stripe = await this.stripePromise;
    //   stripe.redirectToCheckout({
    //           sessionId: id
    //         }).then(function (result) {
    //           console.log(result);
    //         });
    // }
    CheckoutComponent.prototype.paymentstripe = function (id, conID, cvv) {
        var _this = this;
        if (cvv.length != 3) {
            this.cvvError = true;
            return;
        }
        if (this.paynow == false) {
            this.modalService.close(conID);
            this.wizard1 = false;
            this.wizard2 = false;
            this.wizard3 = false;
            this.paymentcheck = true;
            this.paymentloading = true;
            this.paynow = false;
            this.checkService.checkrestripecustomer().subscribe(function (payment) {
                if (payment.success == true) {
                    var default_card;
                    if (_this.defaultcard) {
                        default_card = _this.defaultcard.id;
                    }
                    _this.checkService.checkrepayment({ "cardid": default_card, "cvv": cvv, "amount": parseFloat(_this.gtotal + _this.tax), "products": _this.serviceObjectArray, "tax_rate": _this.tax_rate }).subscribe(function (payment3) {
                        if (payment3.success == false) {
                            localStorage.setItem('productstring', JSON.stringify(_this.serviceObjectArray));
                            _this.document.location.href = payment3.url;
                            _this.paynow = false;
                            _this.iscustomer = true;
                            _this.wizard2 = true;
                            _this.paymentcheck = false;
                            _this.paymentaut = false;
                            _this.paymentloading = false;
                        }
                        // var card = payment3.card;
                        // var saveOrder = {
                        //   "token": "",
                        //   "data": this.serviceObjectArray,
                        //   "prices": this.gtotal,
                        //   "tax": this.tax,
                        //   "cityname": this.defaultcard.address_city,
                        //   "country": this.defaultcard.address_country,
                        //   "address1": this.defaultcard.address_line1,
                        //   "address2": this.defaultcard.address_line2,
                        //   "statename": this.defaultcard.address_state,
                        //   "postcode": this.defaultcard.address_zip,
                        //   "brand": this.defaultcard.brand,
                        //   "last4": this.defaultcard.last4,
                        //   "expmonth": this.defaultcard.exp_month,
                        //   "expyear": this.defaultcard.exp_year,
                        //   "fullname": this.defaultcard.name
                        // };
                        // this.checkService.completeorder(saveOrder).subscribe((payment: any) => {
                        //   this.tax = 0;
                        //   this.gtotal = 0;
                        //   this.getRemainingChecks.emit();
                        //   this.serviceArray =[];
                        //   this.getRemainingCheckss();
                        //   this.serviceObjectArray = [];
                        //   this.modalService.close(conID);
                        //   this.toggle('3'); 
                        //   this.paymentloading = false;
                        // });
                    });
                }
                else {
                    _this.iscustomer = false;
                    _this.modalService.open(id);
                }
            });
        }
    };
    CheckoutComponent.prototype.setStripeToken = function (token, formvalue, id) {
        var _this = this;
        if (this.paynow == false) {
            this.submitted = true;
            if (this.addpopForm.invalid) {
                return;
            }
            var country = '';
            this.countrylist.forEach(function (element) {
                if (formvalue.countryid == element.countryid) {
                    country = element.countryname;
                }
            });
            // this.wizard1 = false;
            // this.wizard2 = false;
            // this.wizard3 = false;
            // this.paymentloading = true;
            // this.paynow = true;
            this.stptoken = token.id;
            var amunt = parseFloat(this.gtotal + this.tax);
            var data = {
                "id": token.id,
                "customer": this.customerData.id,
                "cmonth": token.card.exp_month,
                "cyear": token.card.exp_year,
                "cardnumber": token.card.last4,
                "tokenid": token.card.id,
                "cardname": token.card.brand,
                "country": token.card.country,
                "cstatus": "1",
                "cardholdername": formvalue.name,
                "address1": formvalue.address1,
                "address2": formvalue.address2,
                "cityname": formvalue.cityname,
                "statename": formvalue.statename,
                "cardcountry": country,
                "postcode": formvalue.pincode,
                "taxid": formvalue.taxid,
                "amount": amunt
            };
            this.paymentService.addnewCard(data).subscribe(function (newCardData) {
                if (newCardData.success == "success") {
                    _this.companyService.gettotalcards().subscribe(function (data) {
                        // console.log(data);
                        data.card.forEach(function (card) {
                            if (card.id == newCardData.data.id) {
                                _this.defaultcard = card;
                            }
                        });
                        _this.alerts.success("Card has been successfully added.", true);
                        _this.cardlist = data.card;
                        _this.modalService.close(id);
                    });
                }
                else {
                    _this.alerts.error(newCardData.message, true);
                }
            });
            // this.checkService.orderpayment(data).subscribe((payment3: any) => {
            //   console.log(payment3);
            //   if(payment3.success == true)
            //   {
            //     var card = payment3.card;
            //     var saveOrder = {
            //       "token": "",
            //       "data": this.serviceObjectArray,
            //       "prices": this.gtotal,
            //       "tax": this.tax,
            //       "cityname": card.address_city,
            //       "country": card.address_country,
            //       "address1": card.address_line1,
            //       "address2": card.address_line2,
            //       "statename": card.address_state,
            //       "postcode": card.address_zip,
            //       "brand": card.brand,
            //       "last4": card.last4,
            //       "expmonth": card.exp_month,
            //       "expyear": card.exp_year,
            //       "fullname": card.name
            //     };
            //     this.checkService.completeorder(saveOrder).subscribe((payment: any) => {
            //       this.getRemainingChecks.emit();
            //       this.serviceObjectArray = [];
            //       this.serviceArray =[];
            //         this.toggle('3'); this.paymentloading = false;
            //         this.closeModal('custom-payment-1');
            //     });
            //   }
            // });
        }
    };
    CheckoutComponent.prototype.checkinovoice = function (id) {
        var data = { 'invoiceid': id };
        this.companyService.getinvoiceretrive(data).subscribe(function (invoiceData) {
            console.log(invoiceData);
            return invoiceData;
        });
    };
    CheckoutComponent.prototype.checkpaymentintent = function (id) {
        var data = { 'invoiceid': id };
        this.companyService.getpaymentintent(data).subscribe(function (invoiceData) {
            console.log(invoiceData);
            return invoiceData;
        });
    };
    CheckoutComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    CheckoutComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    CheckoutComponent.prototype.Confirmmeassage = function (id) {
        var _this = this;
        if (this.paynow == false) {
            this.wizard1 = false;
            this.wizard2 = false;
            this.wizard3 = false;
            this.paymentcheck = true;
            this.paymentloading = true;
            this.paynow = true;
            this.checkService.checkrestripecustomer().subscribe(function (payment) {
                if (payment.success == true) {
                    var default_card;
                    if (_this.defaultcard) {
                        default_card = _this.defaultcard.id;
                    }
                    _this.checkService.checkrepayment({ "cardid": default_card, "cvv": '123', "amount": parseFloat(_this.gtotal + _this.tax), "products": _this.serviceObjectArray, "tax_rate": _this.tax_rate }).subscribe(function (payment3) {
                        if (payment3.success == false) {
                            localStorage.setItem('productstring', JSON.stringify(_this.serviceObjectArray));
                            _this.document.location.href = payment3.url;
                            _this.paynow = false;
                            _this.iscustomer = true;
                            _this.wizard2 = true;
                            _this.paymentcheck = false;
                            _this.paymentaut = false;
                            _this.paymentloading = false;
                        }
                    });
                }
                else {
                    _this.iscustomer = false;
                    _this.modalService.open(id);
                }
            });
        }
    };
    CheckoutComponent.prototype.onCheckCard = function (cardid) {
        var _this = this;
        this.cardlist.forEach(function (card) {
            if (card.id == cardid) {
                _this.defaultcard = card;
                // this.paymentService.defaultCardset({ "cardid": cardid }).subscribe((data: any) => {
                // });        
            }
        });
    };
    CheckoutComponent.prototype.inputcvv = function () {
        this.cvvError = false;
    };
    CheckoutComponent.prototype.addNewCard = function (id) {
        this.submitted = false;
        this.display = 'block';
        this.addpopForm.reset({
            name: '',
            address1: '',
            address2: '',
            cityname: '',
            statename: '',
            pincode: '',
            taxid: (this.customerData.metadata) ? this.customerData.metadata.taxid : '',
            countryid: 70,
            cardid: '',
            customerid: ''
        });
        this.modalService.open(id);
    };
    return CheckoutComponent;
}());
exports.CheckoutComponent = CheckoutComponent;
