import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../_services/modal.service';
import { ContactService } from '../services/contact.service';
import { ChecksystemService } from '../services/checksystem.service';
import { AlertService } from '../services/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { UserService } from '../services/user.service';
import { CheckserviceService } from '../services/checkservice.service';

@Component({
  selector: 'app-checksystem',
  templateUrl: './checksystem.component.html',
  styleUrls: ['./checksystem.component.css']
})
export class ChecksystemComponent implements OnInit {
  permission: any;
  defaultVal: any = "";
  submitted: any;
  visapopForm: FormGroup;
  nrSelect:any;
  dashboard:any=1;
  remainCheck: any;
  identity:number =0;
  aml:number =0;
  amlPepCheck: any = 0;
  globalpepcheck: any =0;
  identityCheck:any = 0;
  rtw:any = 0;
  dvla:any =0;
  refcredit:any=0;

  taxDefaulter:any = 0;
  adverseCredit:any = 0;
  basicDbs: any = 0;
  standardDbs: any = 0;
  enhancedDbs: any = 0;
  dircredit:any=0;
  fcacredit:any=0;
  admindetails:boolean =false;
  companycredit: any = 0;
  ctype: number;


  basicniDbs: any = 0;
  standardniDbs: any = 0;
  enhancedniDbs: any = 0;

  socilmedia: any =0;
  basicSDbs: any = 0;
  standardSDbs: any = 0;
  enhancedSDbs: any = 0;
  advesemedia: any =0;

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder, 
    private check: ChecksystemService,
    private contactService: ContactService,
    private alerts: AlertService,
    private router : Router,   
    private userService: UserService,
    private checkService: CheckserviceService,
    private route: ActivatedRoute,
  ) { 
    route.params.subscribe(params => {
      if(params['id']){
           this.dashboard = params['id'];
        }else{this.dashboard =1;}
    });
  }

  
  

  ngOnInit() {

    this.checkService.selectrighttoworkbyadmin().subscribe((righttoworkadmin: any) => {
      if(righttoworkadmin.success == 'pass')
      {
        this.admindetails = true;        
      }      
    });



    
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }else {
      this.userService.checkaccesstokenbyid(userid).subscribe((data: any) => {
        if (data.status == false) {
         
        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('Name');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('ctype');
        localStorage.removeItem('isemail');
        localStorage.removeItem('ismobile');
        localStorage.removeItem('isadmin');
        localStorage.removeItem('ispermission');
        localStorage.removeItem('logoname');
        localStorage.removeItem('struser');
        localStorage.removeItem('menu');
          this.router.navigate(['/login']);
        }     
      });
    }

    
    this.ctype  = parseInt(localStorage.getItem('ctype'));
    if(this.ctype === 3)
    {
      this.router.navigate(['/reference-dashboard']);
    }
    
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];

    console.log('this persimon master', this.permission)
    
    
    this.nrSelect = "";
    this.createformgroup();

    this.amlPepCheck = this.getRemainingChecks(8);
    this.globalpepcheck = this.getRemainingChecks(23);
    this.identityCheck = this.getRemainingChecks(7);
    this.rtw = this.getRemainingChecks(4);
    this.taxDefaulter = this.getRemainingChecks(9);
    this.adverseCredit = this.getRemainingChecks(6);
    this.basicDbs = this.getRemainingChecks(2);
    this.standardDbs = this.getRemainingChecks(3);
    this.enhancedDbs = this.getRemainingChecks(1);
    this.dvla = this.getRemainingChecks(5);
    this.companycredit = this.getRemainingChecks(10);
     this.refcredit = this.getRemainingChecks(11);
     this.dircredit = this.getRemainingChecks(12);
     this.fcacredit = this.getRemainingChecks(13);

    this.basicniDbs = this.getRemainingChecks(14);
    this.standardniDbs = this.getRemainingChecks(15);
    this.enhancedniDbs = this.getRemainingChecks(16);

    this.basicSDbs = this.getRemainingChecks(17);
    this.standardSDbs = this.getRemainingChecks(18);
    this.enhancedSDbs = this.getRemainingChecks(19);
    this.advesemedia  = this.getRemainingChecks(22);

    this.socilmedia  = this.getRemainingChecks(21);

  }
  getRemainingChecks(service_id)
  {
    this.check.getbillinginformation({ 'serviceid': service_id }).subscribe((remaindata: any) => { 
      if(service_id==8)
        this.amlPepCheck = remaindata.remiancheck;
      if(service_id==7)
        this.identityCheck = remaindata.remiancheck;
      if(service_id==4)
        this.rtw = remaindata.remiancheck;
      if(service_id==9)
        this.taxDefaulter = remaindata.remiancheck;
      if(service_id==6)
        this.adverseCredit = remaindata.remiancheck;
      if(service_id==2)
        this.basicDbs = remaindata.remiancheck;
      if(service_id==3)
        this.standardDbs = remaindata.remiancheck;
      if(service_id==1)
        this.enhancedDbs = remaindata.remiancheck;
      if(service_id==5)
        this.dvla = remaindata.remiancheck;
      if(service_id==10)
        this.companycredit = remaindata.remiancheck;
      if(service_id==11)
        this.refcredit = remaindata.remiancheck;
      if(service_id==12)
        this.dircredit = remaindata.remiancheck;
      if(service_id==13)
        this.fcacredit = remaindata.remiancheck;
      if(service_id==14)
        this.basicniDbs = remaindata.remiancheck;
      if(service_id==15)
        this.standardniDbs = remaindata.remiancheck;
      if(service_id==16)
        this.enhancedniDbs = remaindata.remiancheck;
      if(service_id==17)
        this.basicSDbs = remaindata.remiancheck;
      if(service_id==18)
        this.standardSDbs = remaindata.remiancheck;
      if(service_id==19)
        this.enhancedSDbs = remaindata.remiancheck;      
      if(service_id==22)
        this.advesemedia = remaindata.remiancheck;  
      if(service_id==21)
        this.socilmedia = remaindata.remiancheck;
      if(service_id==23)
        this.globalpepcheck = remaindata.remiancheck;
    });
  }

  get f() {
    return this.visapopForm.controls;
  }
  
  createformgroup()
  {
    this.visapopForm = this.formBuilder.group({     
      fname: ['', Validators.required],     
      bussinessemail: ['', Validators.required],
      phonenumber: ['', Validators.required],         
      querytype: ['', Validators.required],    
      subject: ['', Validators.required],     
      message: ['', Validators.required]
    });



  }
  dashboardchange(id)
  {
    this.dashboard =id;

  }
  
  onSubmit(value3: any, id: string) {

    this.submitted = true;
    if (this.visapopForm.invalid) {
      return;
    }

    value3.mailertype ='Contact Us';
    this.contactService.contactus(value3).subscribe((data: any) => {
      this.alerts.success("Query submitted successfully", false);
      this.modalService.close(id);
    });
  }

  openModalMastersystem(id: string) {
    this.visapopForm.reset();    
    this.submitted = false;
    this.modalService.open(id);
  }


  closeModal(id: string) 
  { this.visapopForm.reset();
    this.submitted = false;
    this.modalService.close(id);
  }
}
