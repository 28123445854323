import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class RotaService {

  constructor(private commonService: CommonService) { }

  rotaCreateOrUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/employee/create-update-rota', value);
  }

  getEmployeeRota(value: any): Observable<any> {
    return this.commonService.formpost('/employee/get-all-rota-by-employee', value);
  }

  getRotaData(value: any): Observable<any> {
    return this.commonService.formpost('/employee/rota-by-id', value);
  }

  getRotaByDate(value: any): Observable<any> {
    return this.commonService.formpost('/employee/rota-by-date', value);
  }

  getrotabyemployeeanddate(value: any): Observable<any> {
    return this.commonService.formpost('/employee/get-all-rota-by-employee-and-date', value);
  }

}
