<!-- <style>
  .cdk-overlay-pane {
    width: 700px !important;
  }

  .form-control:focus {
    border-color: #fff !important;
    background-color: #fff !important;
    border: 0px !important;
  }
</style> -->
<div class="overviewsshow">
    <div class="header">
        <h1 class="headprofile mt-0">Address</h1>
        <a (click)="openModal2('custom-address-1')" class="addscreensuccess">Add Address</a>
    </div>
    <div class="row">
        <div class="col-lg-6"  *ngFor="let ad of addressdata">
            <div class="border-box">
                <div class="overview_details emp">
                    <div class="profile_dtailsshow visastaus mb-3">
                        <div class="detailsshow ">                           
                            <p><b>{{ad.address1}}</b>  <span class="badge badge-success" *ngIf="ad.stype == '2' && ad.iscurrent == true">(Current)</span></p>
                        </div>
                        <div class="icons_box_section">                           
                            <p>
                                <a (click)="openModal('custom-address-1',ad)" class="editprime "><i class="lnr lnr-pencil"></i></a>
                                <a *ngIf="ad.stype == '1'" (click)="onDelete(ad.employeeaddresdeatilid)" class="deletered ">
                                    <i class="lnr lnr-trash "></i>
                                </a>
                            </p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow dflex">
                            <span class="lnr lnr-map-marker"></span> 
                            <div >
                                <p class="mb-0">{{ad.address2 }}</p>
                                <p class="mb-0">{{ad.cityname}}</p>
                                <p >{{ad.statename}} {{ad.country.countryname}} -{{ad.pincode}}
                            </div>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3"  *ngIf="(ad.movedin != '') && (ad.movedin != null)">
                            <span>Moved In</span>
                            <p>{{ad.movedin| date: 'dd MMM yyyy'}}</p>
                        </div>
                        <div class="detailsshow width50 pr-3" *ngIf="(ad.movedout != '') && (ad.movedout != null)">
                            <span>Moved Out</span>
                            <p>{{ad.movedout| date: 'dd MMM yyyy'}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
      </div>
      <div class="job_list_section visa_request_section" *ngIf="!addressdata?.length  > false">
        <div class="job_detils_date">
            <p class="job_profile">No Record Found</p>
        </div>
    </div> 
</div>
<!-- <div class="row">
    <div class="col-lg-7">
        <h1 class="headprofile">Address</h1>
    </div>
    <div class="col-lg-5 float-right">
        <h6 class="text-right float-right buttonprofile">
            <a (click)="openModal2('custom-address-1')" class="addscreensuccess">+ Add Address</a></h6>
    </div>
</div> -->
<!-- 
<div class="row row-sm ml-0 mr-0 mt-4">
  <div class="col-lg-12">
    <h6 class="text-right float-right">
      <a (click)="openModal2('custom-address-1')" class="addscreensuccess">+ Add New</a></h6>
  </div>
</div> -->

<!-- <div class="row row-sm">
    <div class="col-lg-4" *ngIf="!addressdata?.length  > false">
        <div class="billed-to">
            No Record Found
        </div>
    </div> -->

    <!-- <div class="col-lg-4 mt-4" *ngFor="let ad of addressdata">
        <div class="billed-to">
            <h6 class="tx-gray-800">{{ad.address1}}
                <span style="color:green" *ngIf="ad.stype == '2' && ad.iscurrent == true">(Current)</span>
            </h6>
            <p>{{ad.address2 }}</p>
            <p>{{ad.cityname}}</p>
            <p><span>{{ad.statename}} {{ad.country.countryname}} -{{ad.pincode}}</span>
                <a (click)="openModal('custom-address-1',ad)" class="lsview">
                    <i class="fa fa-pencil mg-r-10"></i>&nbsp;</a>
                <a *ngIf="ad.stype == '1'" (click)="onDelete(ad.employeeaddresdeatilid)" class="lsview">
                    <i class="fa fa-trash-o mg-l"></i>
                </a>
            </p>
            <br>
            <p *ngIf="(ad.movedin != '') && (ad.movedin != null)"><b>Moved In : </b>{{ad.movedin| date: 'dd MMM yyyy'}}</p>
            <p *ngIf="(ad.movedout != '') && (ad.movedout != null)"><b>Moved Out : </b>{{ad.movedout| date: 'dd MMM yyyy'}}</p>
        </div>
    </div> -->
<!-- </div> -->

<jw-modal id="custom-address-1" class="modal" role="dialog">
    <form [formGroup]="addpopForm" #addresslistForm="ngForm" class="s12 white" *ngIf="addresslistDetail" (ngSubmit)="onSubmit(addresslistForm.value,'custom-address-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="addresslistDetail.employeeaddresdeatilid" formControlName="id" />
                    <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="addresslistDetail.employeeid" formControlName="employeeid" />
                    <h5 class="modal-title" id="popupLabel">Address Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-address-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                      
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Postcode*</label>
                                <input type="pincode" formControlName="pincode" id="pincode" name="pincode" class="form-control pincode" [(ngModel)]="addresslistDetail.pincode" (input)="onSearchChange($event.target.value)" matInput [matAutocomplete]="auto">
                                <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                    <div *ngIf="f.pincode.errors.required">Please enter postcode</div>
                                </div>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="pincode">
                                    <mat-option *ngFor="let option of options" [value]="option" (onSelectionChange)="updatecode($event, option)">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Address Line 1*</label>
                                <input type="address1" formControlName="address1" class="" name="address1" id="address1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" required [(ngModel)]="addresslistDetail.address1">
                                <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
                                    <div *ngIf="f.address1.errors.required">Please enter address</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Address Line 2 </label>
                                <input type="address2" formControlName="address2" class="" name="address2" id="address2" class="form-control" [(ngModel)]="addresslistDetail.address2">
                            </div>
                            <div class="form-group col-lg-6">
                                <label>City*</label>
                                <input type="cityname" formControlName="cityname" class="" name="cityname" id="cityname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.cityname.errors }" required [(ngModel)]="addresslistDetail.cityname">
                                <div *ngIf="submitted && f.cityname.errors" class="invalid-feedback">
                                    <div *ngIf="f.cityname.errors.required">Please enter city</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>County Name*</label>
                                <input type="text" formControlName="statename" class="" name="statename" id="statename" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.statename.errors }" required [(ngModel)]="addresslistDetail.statename">
                              
                                <div *ngIf="submitted && f.statename.errors" class="invalid-feedback">
                                    <div *ngIf="f.statename.errors.required">Please enter the county</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Country*</label>
                                <select style="width:100%;" formControlName="countryid" name="countryid" class="form-control input-lg" id="countryid" [ngClass]="{ 'is-invalid': submitted && f.countryid.errors }" (change)="onSelectCountry($event.target.value)" [(ngModel)]="addresslistDetail.countryid">
                  <option [value]="">Select Country</option>
                  <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                </select>
                                <div *ngIf="submitted && f.countryid.errors" class="invalid-feedback">
                                    <div *ngIf="f.countryid.errors.required">Please select country</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- <div class="form-group col-lg-6 col-lg-6 d-flex align-items-center">
                                <label class="visaempcheck "> Is this your Current Address?
                                    <input type="checkbox" [checked]="addresslistDetail.iscurrent" formControlName="iscurrent" (change)="currentaddresschange($event,addresslistDetail.employeeaddresdeatilid)" name="iscurrent" [(ngModel)]="addresslistDetail.iscurrent" id="iscurrent" />
                                    <span class="checkmark"></span>
                                  </label>
                                <div>
                                </div>
                            </div> -->
                            <div class="form-group col-lg-6">
                                <div>
                                    <b>Current Address?</b>
                                        <!-- <input type="checkbox" [checked]="addresslistDetail.iscurrent" formControlName="iscurrent" (change)="currentaddresschange($event,addresslistDetail.employeeaddresdeatilid)" id="iscurrent" name="iscurrent" [ngModel]="addresslistDetail.iscurrent" /> -->
                                        Yes <input type="radio" value="1" formControlName="iscurrent" (change)="currentaddresschange($event,addresslistDetail.employeeaddresdeatilid)" id="currentyes" name="iscurrent" [ngModel]="addresslistDetail.iscurrent" />
                                        No <input type="radio" value="0" formControlName="iscurrent" (change)="currentaddresschange($event,addresslistDetail.employeeaddresdeatilid)" id="currentno" name="iscurrent" [ngModel]="addresslistDetail.iscurrent" />
                                    <!-- <input type="checkbox" [checked]="addresslistDetail.iscurrent" formControlName="iscurrent" (change)="currentaddresschange($event,addresslistDetail.employeeaddresdeatilid)" name="iscurrent" [(ngModel)]="addresslistDetail.iscurrent" id="iscurrent" /> -->
                                </div>
                            </div> 
                            <div class="form-group col-lg-6">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Moved In*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker1" name="movedin" id="movedin" formControlName="movedin" [max]="currentDate" class="form-control" [(ngModel)]="addresslistDetail.movedin">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.movedin.errors" class="invalid-feedback">
                                    <div *ngIf="f.movedin.errors.required">Please enter the date</div>
                                </div>
                            </div>
                            <div *ngIf="currentdatevalue" class="form-group col-lg-6">
                                <label>Moved Out*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker2" id="movedout" formControlName="movedout" name="movedout" [min]="addpopForm.controls.movedin.value" class="form-control" [(ngModel)]="addresslistDetail.movedout">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                                <div class="invalid-feedback" style="display:block;">
                                    <div *ngIf="movedoutdateerror!=''">{{movedoutdateerror}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary  com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-address-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>