import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class PersonaltypeService {

  constructor(private commonService: CommonService) { }

  getpersonaltypelistbyadmin(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/company/personaltypelistbyadmin');   
  }  

  settingpersonaltypeUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/company/personaltypeupdatebyadmin', value);
  }

  personaltypeUpdateChangeStatus(value:any): Observable<any> 
  {
    return this.commonService.formpost('/company/personaltypeupdatechangestatus',value);
  }

  getactivepersonaltypelist(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/company/activepersonaltypelist');   
  }  

  getdrivinglicenselist(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/company/drivinglicenselist');   
  }  

}
