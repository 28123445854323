import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';

import { ReferenceService } from '../../services/reference.service';


import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import * as FileSaver from 'file-saver';
// import { NgxCsvParser,NgxCSVParserError } from 'ngx-csv-parser';
import { Papa } from 'ngx-papaparse';
import { ChecksystemService } from '../../services/checksystem.service';

export class CsvData {
  public id: any;
  public min: any;
  public max: any;
  public score: any;
}
@Component({
  selector: 'app-addbulkreference',
  templateUrl: './addbulkreference.component.html',
  styleUrls: ['./addbulkreference.component.css']
})
export class AddbulkreferenceComponent implements OnInit {

  // lines = []; //for headings
  // linesR = []; // for rows
  totalCheck = 0
  remainingCheck = 0
  totalcredit = 0
  usedCheck = 0
  makeRequest = false
  count = 0
  token: any;
  lesssore:boolean;
  options: GlobalConfig;
  fileinput: any;
  record:any =0;
  questionTemplates: any;
  filesizeerror:any;
  fileformaterror:any;
  questionTemplatesNew2: any[]=[];

  csvRecordsnew: any[] = [];
  csvRecordswong: any[] =[];
  csvRecords: any[] = [];
  uploadCSV: any[] = [];
  header = true;
  idArray: any[] = [];
  totalcheck:any;
  filename:any;
  checkedAll: any = true;

  constructor(private formBuilder: FormBuilder, private referencingmoduleService: ReferenceService
    , public toastrService: ToastrService,
    private papa: Papa,
    private check:ChecksystemService,
    // private ngxCsvParser: NgxCsvParser
    ) {
    this.options = this.toastrService.toastrConfig;
  }



  ngOnInit() {



    this.remaincheck();

    // this.questiontemplate();



    this.token = localStorage.getItem('User_Token');
    this.filename ='Choose file';
    var companyid = JSON.parse(localStorage.getItem("User_Data")).companyid;
    // this.referencingmoduleService.getCompniesCreditSummary(companyid).subscribe((response: any) => {
    //   response.listcompanychecks.filter(ele => {
    //     if (ele.moduleid === 34) {
    //       this.totalcredit = ele.totalcreditavailable
    //       this.remainingCheck = ele.totalcreditavailable
    //     }
    //   })
    //   this.referencingmoduleService.listCredits({
    //     companyid: companyid,
    //     moduleid: 34
    //   }).subscribe(response => {
    //     if (response.length > 0) {
    //       response.forEach(ele => {
    //         this.totalCheck += (ele.credit + ele.debit)
    //       })
    //     }
    //     // else{
    //     //   this.totalCheck = this.remainingCheck
    //     // }

    //     if (this.totalCheck > this.remainingCheck) {
    //       this.usedCheck = this.totalCheck - this.remainingCheck
    //     }
    //   })
    // });


  }


  remaincheck(){
    
    
    var ts  = { 'serviceid': 11};    
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 

      this.totalcheck = remaindata.remiancheck;
      if(remaindata.remiancheck <= 0)
      {
          this.lesssore =true;
      }
    });

  }
  // questiontemplate()
  // {
  //   this.referencingmoduleService.getquestiontemplates().subscribe((response: any) => {
  //     this.questionTemplates = response;


  //     this.csvRecords.forEach((element, index) => {

  //         this.questionTemplatesNew.push(element.templatename);

  //     });
  //   });
  // }

  downladsample() {

    // window.open(data.response_data.Location, "_blank");
    // this.referencingmoduleService.downloadfile('bulkreferencerequestsample.csv' ).subscribe((data: any) => {
    //   const blob = new Blob([data], { type: 'application/octet-stream' });
    //   const fileName = 'sample reference request.csv';
    //   FileSaver.saveAs(blob, fileName);
    // })
  }


  generateRecord() {
    if(this.idArray.length){
      console.log(this.uploadCSV);
      var dataToUpload = [];
      this.csvRecordsnew.forEach((element, index) => {
        if(this.idArray.indexOf(index)>-1){
          this.questionTemplates.forEach((element2, index) => {
            if(element2.templatename == element.questionprofile){
              element.modulequestiontemplateid = element2.id;
            }
          });
          if(element.referencesrequired){
            element.numberofreferee = element.referencesrequired;
          }
          dataToUpload.push(element);
        }
      });
      console.log(dataToUpload);
      this.check.getbillinginformation({ 'serviceid': 11 }).subscribe((remaindata: any) => { 
        console.log(remaindata.remiancheck);
        this.record =1;
        // if(remaindata.remiancheck >= this.uploadCSV.length){
          this.referencingmoduleService.createBulkRequests({data: dataToUpload}).subscribe((bulkreqdata: any) => {
            console.log(bulkreqdata);
          });
        // } else{
  
        // }
        // this.remainingcheck = remaindata;
  
        // if(remaindata.remiancheck > 0){
        //     this.modalService.open("confirm-modal");
        // }else{
        //   this.modalService.open("upgrade-plan");
        // }
      });
  
      // for (let i = 0; i < this.csvRecords.length; i++) {
      //   if (this.remainingCheck > 0) {
      //     this.csvRecords[i]["companyid"] = JSON.parse(localStorage.getItem("User_Data")).companyid;
      //     this.csvRecords[i]["userid"] = JSON.parse(localStorage.getItem("User_Data")).userid;
      //     this.remainingCheck = this.remainingCheck - 1
      //     this.totalCheck = this.totalCheck + 1
      //     this.count = this.count + 1
      //     this.makeRequest = true
      //   } else {
      //     this.makeRequest = false
      //     this.remainingCheck = this.totalcredit
      //   }
  
      // }
      // if (this.makeRequest) {
      //   this.referencingmoduleService.createbulkreferencerequests(this.csvRecords).subscribe(
      //     data => {
      //       let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
      //       this.openToast('Bulk reference generation request is submitted successfully', '', options, ['success'])
      //     },
      //     error => {
      //       let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
      //       this.openToast(error.error.error.message, '', options, ['error'])
      //     }
      //   );
      // } else {
      //   this.makeRequest = false
      //   let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
      //   if (this.count === 0) {
      //     this.openToast('Credits not available', '', options, ['error'])
      //   } else {
      //     this.openToast(`You can make only ${this.count} request and you are trying to make ${this.csvRecords.length} request's`, '', options, ['error'])
      //   }
  
      // }
    } else{
      
    }
    
  }

  uploadListener(evt: any) {




    
    this.csvRecordsnew =[];
    this.csvRecordswong =[];
    var questionTemplatesNew =[];

    this.referencingmoduleService.getquestiontemplates().subscribe((response: any) => {
      this.questionTemplates = response;
    this.questionTemplates.forEach((element2, index) => {
        questionTemplatesNew.push(element2.templatename);
    });

    // console.log(questionTemplatesNew);

    var files = evt.target.files; // FileList object
    var file = files[0];
    var reader = new FileReader();
    reader.readAsText(file);

    reader.onload = (event: any) => {
      var csv = event.target.result; // Content of CSV file
      this.papa.parse(csv, {
        skipEmptyLines: true,
        header: true,
        complete: (results) => {
          // console.log(results);
          this.csvRecords = results.data;

          this.csvRecords.forEach((element, index) => {
            // console.log(element);
            // console.log(element.questionprofile);
            // console.log(questionTemplatesNew);
            if(element.questionprofile){
              // console.log(element.questionprofile);
              if(questionTemplatesNew.indexOf(element.questionprofile) !== -1 
                  && element.candidatename != ''  
                  && element.candidateemail != ''
                  && this.validateEmail(element.candidateemail)
                  && element.referencesrequired != '' 
                  && element.position != ''
              ){
                console.log('yes');
                element.isactive = 'Yes';
                this.csvRecordsnew.push(element);
              } else{
                // console.log(this.validateEmail(element.candidateemail));
                // console.log(questionTemplatesNew.indexOf(element.questionprofile));
                // console.log(element.candidatename);
                // console.log(element.candidateemail);
                // console.log(element.referencesrequired);
                // console.log(element.position);
                this.csvRecordswong.push(element);     
              }
            } else{
              this.csvRecordswong.push(element);     
            }
          });


          this.uploadCSV = this.csvRecordsnew;// results.data;



          for (let i = 0; i < results.data.length; i++) {
            this.idArray.push(i);
          //   let orderDetails = {
          //     order_id: results.data[i].Address,
          //     age: results.data[i].Age
          //   };
          //   this.csvRecords.push(results.data[i]);
          }
          // console.log(this.idArray);
          // console.log('Parsed: k', results.data);
        }
      });
    }
  });
  }

  // selectall(val){
  //   if(val){
  //     this.uploadCSV = [];
  //   } else{
  //     this.uploadCSV = this.csvRecords;
  //   }
  // }

  selectTimesheet(val){
    if(val.checked){
      this.checkedAll = true;
      this.idArray.push(val.value);
      // this.uploadCSV.push(val.value);
    } else{
      this.checkedAll = false;
      this.idArray.splice(val.value, 1);
      // this.uploadCSV.splice(val.value, 1);
    }
  }

  // uploadListener($event: any): void {
  //   const files = $event.srcElement.files;
  //   this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
  //     .pipe().subscribe((result: Array<any>) => {

  //       console.log('Result', result);
  //       this.csvRecords = result;
  //       //this.csvRecords.pop();
  //     }, (error: NgxCSVParserError) => {
  //       console.log('Error', error);
  //     });
  // }
  // parseFile(): void {
  //   const files = this.fileinput.srcElement.files;

  //   // Parse the file you want to select for the operation along with the configuration
  //   this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
  //     .pipe().subscribe((result: Array<any>) => {

  //       console.log('Result', result);
  //       this.csvRecords = result;
  //       //this.csvRecords.pop();
  //     }, (error: NgxCSVParserError) => {
  //       console.log('Error', error);
  //     });

  // }


  openToast(message, title, options, type) {
    //const opt = JSON.parse(JSON.stringify(this.options));  if we use ngModel as in template
    this.toastrService[type](message, title, options);
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}

