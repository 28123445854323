import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../services/contact.service';
import { AlertService } from '../services/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  permission: any;
  defaultVal: any = "";
  submitted: any;
  supportFormgroup: FormGroup;
  nrSelect:any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder, 
    private contactService: ContactService,
    private alerts: AlertService
  ) { }

  ngOnInit() {
   
    this.createformgroup(); this.submitted = false;
  }

  get f() {
    return this.supportFormgroup.controls;
  }

  createformgroup()
  {
    this.supportFormgroup = this.formBuilder.group({     
      fname: ['', Validators.required],     
      bussinessemail: ['', Validators.required],
      phonenumber: ['', Validators.required],         
      querytype: ['', Validators.required],    
      subject: ['', Validators.required],     
      message: ['', Validators.required]
    });
  }

  onSubmit(supportFormValues: any, id: string) {
    this.submitted = true;
    if (this.supportFormgroup.invalid) {
      return;
    }
    // this.submitted = true;
    supportFormValues.mailertype ='Contact Us';
    this.contactService.contactus(supportFormValues).subscribe((data: any) => {
      this.submitted = false;
      this.createformgroup();
      this.alerts.success("Query submitted successfully", false);
      // this.router.navigate(['/check-dashboard']);
      // this.modalService.close(id);
    });
  }

  clearData()
  {
    this.submitted = false;
    this.createformgroup();
  }
}
                                                                          