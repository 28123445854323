import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class CheckserviceService {

  constructor(private commonService: CommonService) { }

  rtwquestionlist(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/check/questionlist');   
  }

  rtwquestionbyid(data): Observable<any> 
  {
    return this.commonService.formpost('/check/questionlist', data);   
  }

  getAllServices(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/api/getCheckServices');   
  }

  getstripedefaultcard(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/stripe/getdefaultcard');   
  }


  orderpayment(data): Observable<any> 
  {
    return this.commonService.formpost('/check/orderpayment',data);   
  }

  completeorder(data): Observable<any> 
  {
    return this.commonService.formpost('/check/completeorder',data);   
  }
  checkrestripecustomer(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/check/stripecustomercheck');   
  }

  registereduserpayment(data): Observable<any> 
  {
    return this.commonService.formpost('/check/registereduserpayment',data);   
  }

  registereduserpayment_new(data): Observable<any> 
  {
    return this.commonService.formpost('/check/registereduserpayment_new',data);   
  }
  
  upgradeuserpayment(data): Observable<any> 
  {
    return this.commonService.formpost('/check/upgradeuserpayment',data);   
  }
  
  subscripepackagepayment(data): Observable<any> 
  {
    return this.commonService.formpost('/check/subscribepackage',data);   
  }

  subscripepackagepaymentbystripe(data): Observable<any> 
  {
    return this.commonService.formpost('/check/subscribepackagebystripe',data);   
  }


  
  checkrepayment(data): Observable<any> 
  {
    return this.commonService.formpost('/check/repayment',data);   
  }

  addUpdateAdverseCredit(data): Observable<any>
  {
    return this.commonService.formpost('/company/searchtest',data); 
  }

  checkhistory(data): Observable<any>
  {
    return this.commonService.formpost('/company/adversesearchhistory',data); 
  }

  

  adversecredithistorydownload(data): Observable<any>
  {
    return this.commonService.formpost('/company/downloadadversecreditreport',data); 
  }

  adversecredithistoryview(data): Observable<any>
  {
    return this.commonService.formpost('/company/viewadversecreditreport',data); 
  }

  allquestion(data): Observable<any> 
  {
    return this.commonService.formpost('/question/allquestion',data);   
  }

  insertnewrighttowork(data): Observable<any>
  {
    return this.commonService.formpost('/employee/righttoworklog',data);  
  }

  updaterighttowork(data): Observable<any>
  {
    return this.commonService.formpost('/employee/updaterighttoworklog',data);  
  }

  updatenewrighttowork(data): Observable<any>
  {
    return this.commonService.refereeformpost('/employee/updatelogsystem',data);  
  }

  selectrighttoworkbyadmin(): Observable<any>
  {
    return this.commonService.formpostOnlyid('/admin/righttoworkadminpermission');  
  }

  selectrighttowork(data): Observable<any>
  {
    return this.commonService.formpost('/employee/righttoworkbyid',data);  
  }
  getRightToWorkLogById(data): Observable<any>
  {
    return this.commonService.refereeformpost('/employee/righttoworklogbyid',data);  
  }

  resedapplicantrequest(data): Observable<any>
  {
    return this.commonService.formpost('/employee/applicantrequestsendagain',data);  
  }


  resedapplicantrequestwidthdocumentupdatebysuperadmin(data): Observable<any>
  {
    return this.commonService.formpost('/company/righttoworkresendrequestbysuperadmin',data);  
  }

  resedapplicantrequestwidthdocumentupdate(data): Observable<any>
  {
    return this.commonService.formpost('/company/righttoworkresendrequest',data);  
  }

  getrighttoworktoken(data): Observable<any>
  {
    return this.commonService.formpostForWithoutTokenForrighttowork('/employee/getrighttoworktoken',data);  
  }

  getrighttoworkhistorybyemployeeid(data): Observable<any>
  {
    return this.commonService.formpost('/employee/righttoworkhistorybyid',data);  
  }

  getrighttoworkhistorybyemployeeadmin(data): Observable<any>
  {
    return this.commonService.formpost('/employee/righttoworkhistorybyadmin',data);  
  }

  getrighttoworkdetailsbylogid(data): Observable<any>
  {
    return this.commonService.formpost('/employee/righttoworkdetailsbylogid',data);  
  }

  getrighttoworkdetailsbylogidsuperadmin(data): Observable<any>
  {
    return this.commonService.formpost('/employee/righttoworkdetailsbylogidbysuperadmin',data);  
  }

  downloadRightToWorkLog(data): Observable<any>
  {
    return this.commonService.formpost('/download/downloadrighttowork',data);  
  }

  checkCoupon(data): Observable<any>
  {
    return this.commonService.formpost('/payment/checkcoupon',data);
  }

  /** Screening APIs */

  checkqrtoken(data): Observable<any>
  {
    return this.commonService.formpostreferencingapi('/screening/api/checkqrtoken',data);  
  }

  updatescreeningpassport(data): Observable<any>
  {
    return this.commonService.formpostreferencingapi('/screening/api/updatepassport',data);  
  }

  updatescreeningbrp(data): Observable<any>
  {
    return this.commonService.formpostreferencingapi('/screening/api/updatebrp',data);  
  }

  updatescreeningdl(data): Observable<any>
  {
    return this.commonService.formpostreferencingapi('/screening/api/updatedl',data);  
  }


  /** Screening APIs */

}
