"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var ThanksComponent = /** @class */ (function () {
    function ThanksComponent(router) {
        this.router = router;
        this.ctype = '';
    }
    ThanksComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.ctype = localStorage.getItem('ctype');
            if (_this.ctype == '0') {
                _this.router.navigate(['dashboard']);
            }
            if (_this.ctype == '1') {
                _this.router.navigate(['check-dashboard']);
            }
            if (_this.ctype == '2') {
                _this.router.navigate(['check-dashboard']);
            }
            if (_this.ctype == '3') {
                _this.router.navigate(['reference-dashboard']);
            }
            // this.router.navigate(['/dashboard']);
        }, 9000); //5s
    };
    return ThanksComponent;
}());
exports.ThanksComponent = ThanksComponent;
