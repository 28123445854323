"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var HelperService = /** @class */ (function () {
    function HelperService(zone) {
        this.zone = zone;
        this.ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);
    }
    HelperService.prototype.determineLocalIp = function () {
        var _this = this;
        var localIp = sessionStorage.getItem('LOCAL_IP');
        console.log('Local IP address');
        //window.RTCPeerConnection = this.getRTCPeerConnection();
        //window.RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection; 
        var pc = new RTCPeerConnection({ iceServers: [{ urls: "stun:stun.l.google.com:19302" }] });
        //pc.createDataChannel('rtc');
        pc.createOffer().then(pc.setLocalDescription.bind(pc));
        pc.onicecandidate = function (ice) {
            _this.zone.run(function () {
                if (!ice || !ice.candidate || !ice.candidate.candidate) {
                    return;
                }
                localIp = _this.ipRegex.exec(ice.candidate.candidate)[1];
                sessionStorage.setItem('LOCAL_IP', localIp);
                console.log('Local IP address', localIp);
                pc.onicecandidate = function () { };
                pc.close();
                return localIp;
            });
        };
    };
    HelperService.ngInjectableDef = i0.defineInjectable({ factory: function HelperService_Factory() { return new HelperService(i0.inject(i0.NgZone)); }, token: HelperService, providedIn: "root" });
    return HelperService;
}());
exports.HelperService = HelperService;
