import { Country } from './country.model';

export class Permanentaddress {
  addressid1:string;
  addressid2:string;
  cityname:string;
  phonenumber:string;
  mobilenumber:string;
  countryname: Country;
  statename: string;
  countryid: any;
}