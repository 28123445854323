import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../services/customer.service';
import { CountryService } from '../../../services/country.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ModalService } from '../../../_services/modal.service';
import { ZoomService } from '../../../services/zoom.service';
import { Profile, Termination,righttowork } from '../../../models/overview/profile.model';
import { AppGlobals } from '../../../app.global';


@Component({
  selector: 'app-righttoworkdetails',
  templateUrl: './righttoworkdetails.component.html',
  styleUrls: ['./righttoworkdetails.component.css']
})
export class RighttoworkdetailsComponent implements OnInit {

  constructor(
    private customerdetails: CustomerService,
    private activatedRoute: ActivatedRoute, 
    private country: CountryService ,    
    private modalService: ModalService,     
    private formBuilder: FormBuilder, 
    private router : Router,    
    private zoomService: ZoomService
  ) { }

  loading: any = 0;  
  stage: any;
  current_stage: any = "";
  employeedetails: any;
  empid: any;
  countrylist: any;
  datalist:any;
  searchTextcountry: any;
  record: any;
  items: any;
  sendLinkEmployeeId: any;
  meetingformGroup: FormGroup;
  submitted: any;
  remainingcheck: any;  
  employeeid: any;
  right = new righttowork();  
  defaultVal: any = "";
  currentDate = new Date();
  timeValues: any;
  request_type: any="";
  rtw_country: any=0;

  ngOnInit() {
    this.timeValues = AppGlobals.TIME_VALUES;
    this.empid = this.activatedRoute.snapshot.paramMap.get("id");
    this.customerdetails.getOverviewbyadmin({ 'id': this.empid }).subscribe((remaindata: any) => { 
      this.employeedetails = remaindata;
    });
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
      this.datalist = country;
    });
    this.meetingformGroup = this.formBuilder.group({
      startdate: ['', Validators.required],
      starttime: ['', Validators.required],
      endtime: ['', Validators.required]
    });

    this.stage =1;
    this.loading =0;
  }
  onChangePage(id){

  }

  sendrequest(id)
  {
    if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
    {
      this.modalService.open('upgrade-plan');
    }
    else
    {
      this.submitted = false;
      this.meetingformGroup.reset();
      this.modalService.open(id);
      this.defaultVal="";
      this.right = new righttowork();
      this.employeeid = this.empid;//employeeid;
    }
  }

  uploadByAdmin(emp_type, type, empid)
  {
    if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
    {
      this.modalService.open('upgrade-plan');
    }
    else
    {
      if(type == 'upload_now')
      {
        if(emp_type == 'visa')
        {
          this.router.navigate(['/check-ai-visa-admin', empid], { queryParams: { is_rtw: 1 } });
        }
        else if(emp_type == 'passport')
        {
          this.router.navigate(['/check-ai-passport-admin', empid], { queryParams: { is_rtw: 1 } });
        }
        else if(emp_type == 'document')
        {
          this.router.navigate(['/check-document-admin', empid]);
        }
      }
      else if(type == 'add_new')
      {
        this.router.navigate(['/check-add-applicant', emp_type]);
      }
      
    }
  }

  get returnForm() {
    return this.meetingformGroup.controls;
  }

  sendZoomLink(employeeid, id, meetingform)
  {
    if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
    {
      this.modalService.close(id);
      this.modalService.open('upgrade-plan');
    }
    else
    {
      this.submitted = true;
      if (this.meetingformGroup.invalid) {
        return;
      }
      var startdate = new Date(meetingform.startdate.setHours(meetingform.starttime));
      var enddate = new Date(meetingform.startdate.setHours(meetingform.endtime));
      this.loading =1;
      this.zoomService.sendZoomLinkWithIdentity({
        "startdate": startdate, 
        "enddate": enddate, 
        "email":"fareed@complygate.co.uk", 
        "employeeid": this.empid, 
        "requesttype": this.request_type
      }).subscribe((data: any) => {
        this.loading = 0;
        this.modalService.close(id);
        this.stage = 6;
      });
      this.employeeid =this.empid;// employeeid;
    }
  }

  sendrequestchargestage(stagechange, type:any='', check_type:any='', rtw_country:any=''){
    if(type)
    {
      this.current_stage = type;
    }
    console.log(stagechange);
    console.log(type);
    console.log(check_type);
    this.loading =1;
    if(stagechange==7){
      this.request_type = check_type;
      if(this.current_stage=='rtw'){
        this.modalService.open('confirm-modal');
      } else if(this.current_stage=='adjusted_rtw'){
        this.sendrequest('sendrequestcharge');
      }
    } else if(stagechange==3){
      this.rtw_country = rtw_country;
      this.stage =stagechange;
    } else{
      this.stage =stagechange;
    }
    // if(this.current_stage=='rtw' && )
    // {
    //   if(stagechange==6){
    //     this.sendrequest('sendrequestcharge');
    //   }else if(stagechange==7){
    //     this.uploadByAdmin('passport','upload_now',this.empid);
    //   }else{
        
    //   }
    // }
    
    
    this.loading =0;
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

}
