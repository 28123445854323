"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../_services/modal.service");
var common_service_1 = require("../../services/common.service");
var country_service_1 = require("../../services/country.service");
var company_service_1 = require("../../services/company.service");
var router_1 = require("@angular/router");
var client_service_1 = require("../../services/client.service");
var forms_1 = require("@angular/forms");
var ClassicEditor = require("@ckeditor/ckeditor5-build-classic");
var platform_browser_1 = require("@angular/platform-browser");
var upload_file_service_1 = require("./../../services/upload-file.service");
var country_model_1 = require("./../../models/address/country.model");
var client_model_1 = require("./../../models/client/client.model");
var companytype_model_1 = require("./../../models/company/companytype.model");
var alert_service_1 = require("../../services/alert.service");
var ClientComponent = /** @class */ (function () {
    function ClientComponent(sanitizer, commonService, modalService, client, formBuilder, country, alerts, router, uploadService, companyService) {
        this.sanitizer = sanitizer;
        this.commonService = commonService;
        this.modalService = modalService;
        this.client = client;
        this.formBuilder = formBuilder;
        this.country = country;
        this.alerts = alerts;
        this.router = router;
        this.uploadService = uploadService;
        this.companyService = companyService;
        this.clientDetail = new client_model_1.Client();
        this.Editor = ClassicEditor;
        this.options = [];
        this.submitted = false;
        this.emptyValue = "";
    }
    ClientComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.Userid = localStorage.getItem('userid');
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.clientperm = this.permission.m3;
        // console.log(this.clientperm);
        this.clientDetail = new client_model_1.Client();
        this.FormClient = new client_model_1.Client();
        this.clientDetail.country = new country_model_1.Country();
        this.clientDetail.companytype = new companytype_model_1.Companytype();
        this.clientformGroup = this.formBuilder.group({
            id: [''],
            clientid: [''],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            secondryemailid: ['', forms_1.Validators.compose([forms_1.Validators.email])],
            phonenumber: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            sphonenumber: [''],
            pincode: ['', forms_1.Validators.required],
            clientname: ['', forms_1.Validators.required],
            clietdesignation: ['', forms_1.Validators.required],
            websiteurl: [''],
            address1: ['', forms_1.Validators.required],
            address2: [''],
            cityname: ['', forms_1.Validators.required],
            statename: ['', forms_1.Validators.required],
            countryid: ['', forms_1.Validators.required],
            companyname: ['', forms_1.Validators.required],
            companytypeid: ['', forms_1.Validators.required]
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
            // this.clientDetail.countryid = "70";
        });
        this.companyService.getcompanyType().subscribe(function (state) {
            _this.companytype = state;
        });
        var sstatustype = { 'status': false, 'id': this.Userid };
        this.client.getclientlist(sstatustype).subscribe(function (data) {
            _this.record = data.length;
            _this.datalist = data;
            // this.record = data.length;
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.items = data;
            }
            // this.data = data;
            // this.clientDetail.countryid = "70";
        });
    };
    Object.defineProperty(ClientComponent.prototype, "f", {
        get: function () { return this.clientformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    ClientComponent.prototype.onSubmit = function (formclient, id) {
        var _this = this;
        this.submitted = true;
        // var formclient = clientformdata.value;
        if (this.clientformGroup.invalid) {
            return;
        }
        this.disableBtn = false;
        formclient.userid = this.Userid;
        this.client.clientdetailsUpdate(formclient).subscribe(function (response) {
            var sstatustype = { 'status': false, 'id': _this.Userid };
            _this.client.getclientlist(sstatustype).subscribe(function (data) {
                // clientformdata.reset();
                _this.record = data.length;
                _this.datalist = data;
                if (_this.record < 30) {
                    _this.pageOfItems = data;
                }
                else {
                    _this.items = data;
                }
                // this.data = data;
                // this.clientDetail.countryid = "70";
                _this.alerts.success('Client updated successfully', true);
                _this.modalService.close(id);
            });
        });
    };
    ClientComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    ClientComponent.prototype.onSearchChange = function (searchValue) {
        var _this = this;
        if (searchValue.length > 4) {
            var newArray = [];
            this.commonService.getAddress(searchValue).subscribe(function (data) {
                var newdata;
                newdata = data;
                newdata.addresses.forEach(function (value) {
                    newArray.push(value + "," + searchValue);
                });
                _this.options = newArray;
            });
        }
        else {
            this.options = [];
        }
    };
    ClientComponent.prototype.displayFn = function (value) {
        if (value != null) {
            var res = value.split(",");
            if (res.length > 6) {
                return res[7];
            }
            else {
                return value;
            }
        }
    };
    ClientComponent.prototype.updatecode = function (evt, response) {
        res = [];
        if (evt.source.selected) {
            var res = response.split(",");
            this.FormClient.address1 = res[0];
            this.FormClient.address2 = res[1];
            this.FormClient.cityname = res[5];
            this.FormClient.pincode = res[7];
            this.FormClient.statename = res[6];
        }
    };
    ClientComponent.prototype.transform = function (html) {
        return this.sanitizer.bypassSecurityTrustStyle(html);
    };
    ClientComponent.prototype.selectFile = function (event, field) {
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        var file = this.selectedFiles;
        var imagepath = this.uploadService.uploadfile(file);
    };
    ClientComponent.prototype.onChangeStatus = function (evt, client) {
        var status = '';
        if (evt.target.checked) {
            status = '1';
        }
        else if (!evt.target.checked) {
            status = '0';
        }
        this.client.clientdetailsUpdatestatusbyadmin({ 'userid': this.Userid, 'id': client.clientid, 'cstatus': status }).subscribe(function (data) {
        });
    };
    ClientComponent.prototype.openModal = function (id, data) {
        var _this = this;
        this.buttoninsert = "Update";
        this.disableBtn = true;
        this.FormClient = new client_model_1.Client();
        data.id = data.clientid;
        this.FormClient = data;
        this.clientformGroup.reset(this.FormClient);
        this.clientDetail = data;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.modalService.open(id);
    };
    ClientComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.FormClient = new client_model_1.Client();
        this.FormClient.countryid = "70";
        this.FormClient.clientid = "0";
        // this.clientformGroup.get('companyname').setValue('');
        // this.clientformGroup.get('companytypeid').setValue('');
        // this.clientformGroup.get('websiteurl').setValue('');
        // this.clientformGroup.get('clientname').setValue('');
        // this.clientformGroup.get('clietdesignation').setValue('');
        // this.clientformGroup.get('emailid').setValue('');
        this.mobilecheck = '';
        this.phonecheck = '';
        this.clientformGroup.reset(this.FormClient);
        this.modalService.open(id);
    };
    ClientComponent.prototype.openModal3 = function (jobid, id) {
        this.disableBtn = true;
        this.modalService.open(id);
    };
    ClientComponent.prototype.openModal4 = function (jobid, id, screenumber) {
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.screenumber = screenumber;
        this.modalService.open(id);
    };
    ClientComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return ClientComponent;
}());
exports.ClientComponent = ClientComponent;
