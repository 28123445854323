<!-- <div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
      <div class="tabs" id="tabs">
        <div class="tabs__content">
          <div class="tabs__content__item active-tab">
            <div class="content">
              <app-topmenu class="row"></app-topmenu> -->
              <div class="overviewsshow">
                <div class="header">
                  <h1 class="headprofile mt-0">Address Proof </h1>
                  <a (click)="openModal2('custom-idproof-1')" class="addscreensuccess"> + Add Address Proof</a>
                </div>
                <!-- <div class="row"> -->
                  <!-- <div  class="form-check col-lg-1">&nbsp;</div> -->
                  <!-- <div *ngFor="let filterItem of listcheck" class="form-check col-lg-2">
                      <label class="form-check-label">
                                              <input type="checkbox" (ngModelChange)="onlistchange($event,filterItem.id)" [(ngModel)]="filterItem.selected"
                                                [value]="filterItem.id"> {{
                                              filterItem.name }}  
                                            </label>
                  </div>
              </div> -->
              <div class="row">
                  <div class="col-lg-4" *ngFor="let vs of idprooflist">
                      <div class="border-box">
                          <div class="overview_details emp">
                              <div class="profile_dtailsshow visastaus mb-3">
                                  <div class="detailsshow">
                                      <!-- <span class="badge badge-danger" *ngIf="vs.stype == '3'"><i class="fa fa-times" aria-hidden="true"></i> Rejected</span>
                                      <span class="badge badge-warning" *ngIf="vs.stype == '1'"><i class="fa fa-hourglass-end" aria-hidden="true"></i> Pending</span>
                                      <span class="badge badge-success" *ngIf="vs.stype == '2'"><i class="fa fa-check" aria-hidden="true"></i> Approved</span> -->
                                  </div>
                                  <div class="detailsshow">
                                      <a (click)="openModal('custom-idproof-1',vs)" class="" *ngIf="vs.stype == '1'"><i class="lnr lnr-pencil mg-r-10"></i>&nbsp;</a>
                                      <a (click)="onDelete(vs.employeeidproofdeatilid)" class="" *ngIf="vs.stype == '1'"><i class="lnr lnr-trash mg-l"></i></a>
                                  </div>
                              </div>
                              <div class="profile_dtailsshow">
                                  <div class="detailsshow width50 pr-3">
                                      <span>ID Number</span>
                                      <p>{{vs.idnumber}}</p>
                                  </div>
                                  <div class="detailsshow width50 pr-3">
                                      <span>ID Type</span>
                                      <p>{{vs.documenttype.documentname }}</p>
                                  </div>
                              </div>
                              <div class="profile_dtailsshow">
                                  <div class="detailsshow width50 pr-3 mt-0">
                                      <span>Expiry Date</span>
                                      <p>{{vs.docexpiry | date: 'dd MMM yyyy'}}</p>
                                  </div>
                                  <div class="detailsshow width50 pr-3 mt-0">
                                      <span>ID Issuing Country</span>
                                      <p>{{vs.nationality.countryname}}</p>
                                  </div>
                              </div>
                              <div class="profile_dtailsshow">
                                  <div class="detailsshow width50 pr-3 mt-0">
                                      <span>View Front Address Proof </span>
                                      <p><a (click)="openModalidproof('pdf-image-viewer-myidproofsystem',vs.iddocName)" *ngIf="vs.iddocName != ''"><i class="fa fa-file-image-o mg-r-10" ></i>&nbsp;</a></p>
                                  </div>
                                  <div class="detailsshow width50 pr-3 mt-0">
                                      <span>View Back Address Proof </span>
                                      <p><a (click)="openModalidproof('pdf-image-viewer-myidproofsystem',vs.idbackdocName)" *ngIf="vs.idbackdocName != ''"><i class="fa fa-file-image-o mg-r-10" ></i>&nbsp;</a></p>
                                  </div>
                              </div>

                              <div class="profile_dtailsshow">
                                <div class="detailsshow width100 pr-3">
                                  <p class="mb-0" *ngIf="aipayment ==false"> Verify the ID using Complygate AI. <a [routerLink]="['/idproofaidetails',vs.employeeidproofdeatilid]">Click Here</a></p>
                                  <p class="mb-0" *ngIf="aipayment ==true"> You have exhausted the AI ID verification. To continue using it please <a [routerLink]="['/check-add-credit']">Click Here</a></p>
                                 
                                  
                                </div>
                                
                            </div>

                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 mt-4" *ngIf="!idprooflist?.length > false">
                      <p>No Record Found</p>
              </div>
            </div>
<!-- <div class="row">
  <div class="col-lg-7">
      <h1 class="headprofile">Address Proof </h1>
  </div>
  <div class="col-lg-5 float-right">
      <h6 class="text-right float-right buttonprofile">
          <a (click)="openModal2('custom-idproof-1')" class="addscreensuccess">+ Add Address Proof</a></h6>
  </div>
</div> -->
<!-- <div class="row row-sm">
  <div *ngFor="let filterItem of listcheck" class="form-check col-lg-2" style="padding-right:20px;">
      <label class="form-check-label">
                              <input type="checkbox" (ngModelChange)="onlistchange($event,filterItem.id)" [(ngModel)]="filterItem.selected"
                                [value]="filterItem.id"> {{
                              filterItem.name }}  
                            </label>
  </div>
</div> -->
<div class="row row-sm">
  <!-- <div class="col-lg-4 mt-4" *ngIf="!idprooflist?.length > false">
      <div class="billed-to">
          No Record Found
      </div>
  </div> -->
  <!-- <div class="col-lg-4 mt-4" *ngFor="let vs of idprooflist">
      <div class="billed-to">
          <h6 class="tx-gray-800 float-left">ID Number: {{vs.idnumber}}
              <span class="badge badge-danger" *ngIf="vs.stype == '3'"><i class="fa fa-times" aria-hidden="true"></i> Rejected</span>
              <span class="badge badge-warning" *ngIf="vs.stype == '1'"><i class="fa fa-hourglass-end" aria-hidden="true"></i> Pending</span>
              <span class="badge badge-success" *ngIf="vs.stype == '2'"><i class="fa fa-check" aria-hidden="true"></i> Approved</span>
          </h6>
          <div class="float-right">
              <a (click)="openModal('custom-idproof-1',vs)" class="red_text" *ngIf="vs.stype == '1'"><i class="fa fa-pencil mg-r-10"></i>&nbsp;</a>
              <a (click)="onDelete(vs.employeeidproofdeatilid)" class="red_text" *ngIf="vs.stype == '1'"><i class="fa fa-trash-o mg-l"></i></a>
          </div>
          <p><b>ID Type:</b> {{vs.documenttype.documentname }}</p>
          <p><b>Expiry Date:</b> {{vs.docexpiry | date: 'dd MMM yyyy'}}</p>
          <p><b>ID issue country: </b> {{vs.nationality.countryname}} &nbsp;
              <a (click)="openModalidproof('pdf-image-viewer-myidproofsystem',vs.iddocName)" *ngIf="vs.iddocName != ''"><i class="fa fa-file-image-o mg-r-10" ></i>&nbsp;</a>
          </p>
          <p class="badge badge-warning" *ngIf="vs.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Pending by AI </p>
          <p class="badge badge-success" *ngIf="vs.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
          <p class="badge badge-error" *ngIf="vs.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
      </div>
  </div> -->
  <!-- </div>
                          </div> -->

  <!-- col-8 -->
</div>
<!--
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

<jw-modal id="custom-idproof-1" role="dialog" class="modal">
  <form [formGroup]="idproofpopForm" #idproofForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(idproofForm.value,'custom-idproof-1')">
      <div role="document" class="formgroup">
          <div class="modal-content">
              <div class="modal-header">
                  <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="idproofDetail.employeeid">
                  <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="idproofDetail.employeeidproofdeatilid">
                  <h5 class="modal-title" id="popupLabel">Address Proof Details</h5>
                  <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-idproof-1');">
                      <span aria-hidden="true">&times;</span>
                    </button>
              </div>
              <div class="modal-body">
                  <div class="container">
                      <div class="row">
                          <div class="form-group col-lg-6">
                              <label>Address Proof No.*</label>
                              <input type="idnumber" formControlName="idnumber" name="idnumber" id="idnumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.idnumber.errors }" [(ngModel)]="idproofDetail.idnumber">
                              <div *ngIf="submitted && f.idnumber.errors" class="invalid-feedback">
                                  <div *ngIf="f.idnumber.errors.required">Please enter address proof no.</div>
                              </div>
                          </div>
                          <div class="form-group col-lg-6">
                              <label>Address Proof Type*</label>
                              <select style="width:100%;" formControlName="idtype" name="idtype" id="idtype" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.idtype.errors }" [(ngModel)]="idproofDetail.idtype">
                                              <option [value]="defaultVal">Select address proof type</option>
                                              <option *ngFor="let ad3 of documenttype" [value]="ad3.id">{{ ad3.documentname }}</option>
                                            </select>
                              <div *ngIf="submitted && f.idtype.errors" class="invalid-feedback">
                                  <div *ngIf="f.idtype.errors.required">Please select address proof type</div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="form-group col-lg-6">
                              <label>Expiry Date*</label>
                              <mat-form-field class="datecontrol">
                                  <input matInput [matDatepicker]="picker2" id="docexpiry" name="docexpiry" formControlName="docexpiry" [ngClass]="{ 'is-invalid': submitted && f.docexpiry.errors }" class="form-control noborder-invalid datecontrol" [(ngModel)]="idproofDetail.docexpiry">
                                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                  <mat-datepicker #picker2></mat-datepicker>
                              </mat-form-field>
                              <div *ngIf="submitted && f.docexpiry.errors" class="invalid-feedback">
                                  <div *ngIf="f.docexpiry.errors.required">Please choose expiry date</div>
                              </div>
                          </div>
                          <div class="form-group col-lg-6">
                              <label>Address Proof Issue Country*</label>
                              <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [(ngModel)]="idproofDetail.nationalityid">
                            <option [value]="defaultVal">Select address proof  issue country </option>
                            <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                          </select>
                              <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                  <div *ngIf="f.nationalityid.errors.required">Please select address proof issuing country</div>
                              </div>
                          </div>

                      </div>
                      <div class="row">
                          
                          <div class="col-lg-6">
                              <label>Front Side Address Proof Image Upload*</label>
                              <div class="input-group mb-3" (click)="filedocument.click()">
                                  <div class="custom-file">
                                      <input type="file" #filedocument (change)="selectdocumentFileupload($event)">
                                      <label class="custom-file-label">{{filedocumentname}}</label>
                                  </div>
                              </div>
                                  <div class="invalid-feedback" *ngIf="filedocumentreuirederror == true">Please select front side passport</div>                              
                                  <div class="invalid-feedback" *ngIf="filedocumentsizeerror == true">File size must be less than 2 MB</div>
                                  <div class="invalid-feedback" *ngIf="filedocumentformaterror == true">Please choose a valid file format (jpg, png)</div>
                             
                          </div>
                          <div class="col-lg-6">
                              <label>Back Side Address Proof Image Upload*</label>
                              <div class="input-group mb-3" (click)="filedocumentback.click()">
                                  <div class="custom-file">
                                      <input type="file" #filedocumentback (change)="selectdocumentbackFileupload($event)">
                                      <label class="custom-file-label">{{filedocumentbackname}}</label>
                                  </div>
                              </div>
                                  <div class="invalid-feedback" *ngIf="filedocumentbackreuirederror == true">Please select front side passport</div>                                                               
                                  <div class="invalid-feedback" *ngIf="filedocumentbackbacksizeerror == true">File size must be less than 2 MB</div>
                                  <div class="invalid-feedback" *ngIf="filedocumentbackbacksizeerror == true">Please choose a valid file format (jpg, png)</div>
                              
                          </div>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                  <input type="submit" class="btn btn-primary com_btn" name="updateform" value="{{buttoninsert}}" />
                  <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-idproof-1');" />
              </div>
          </div>
      </div>
  </form>
</jw-modal>

<jw-modal id="pdf-image-viewer-myidproofsystem" class="modal" role="dialog">
  <div role="document" class="formgroup">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-myidproofsystem');">
                      <span aria-hidden="true">&times;</span>
                    </button>
          </div>
          <div class="modal-body">
              <div class="container">
                  <div class="row">
                      <!-- <div class="col-lg-12">
                          <iframe *ngIf="imgidproofsrc == false" [src]="iframeidproofsrc" width="100%" height="600" frameborder="0"></iframe>
                          <img *ngIf="imgidproofsrc == true" [src]="iframeidproofsrc" width="100%" />
                      </div> -->
                      <div class="modal-body">
                          <div class="container">
                              <div class="row">
                                  <div class="col-lg-12">
                                      <iframe *ngIf="imgidproofsrc == false" [src]="iframeidproofsrc" width="100%" height="600" frameborder="0"></iframe>
                                      <img *ngIf="imgidproofsrc == true" [src]="iframeidproofsrc" width="100%" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</jw-modal>



<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">         
            <div class="modal-body">
                <div class="container text-center">
                  <div class="info_mess">
                      <div class="para">
                              <h4 class="mb-2">No credits available</h4>
                              <p class="mb-0 font20">There are no credits available to perform this action. Please add more credits.</p>
  
                          </div>                          
                      </div>
                </div>
            </div>
            <div class="modal-footer text-center">
              <input type="button" class="btn btn-primary com_btn" name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
              <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
                <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
            </div>
        </div>
    </div>
  </jw-modal>