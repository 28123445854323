import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.css']
})

export class ThanksComponent implements OnInit {

  ctype='';
  constructor(private router: Router) { }

  ngOnInit() {


    



    setTimeout(() => {
      this.ctype = localStorage.getItem('ctype');      
      if (this.ctype == '0' ) 
      {
        this.router.navigate(['dashboard']);
      }
      if (this.ctype == '1' ) 
      {
        this.router.navigate(['check-dashboard']);
      }
      if (this.ctype == '2' ) 
      {
        this.router.navigate(['check-dashboard']);
      }

      if(this.ctype == '3' ) 
      {
        this.router.navigate(['reference-dashboard']);
      }     
      // this.router.navigate(['/dashboard']);
  }, 9000);  //5s
  }
}