import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EmployeeService } from '../../services/employee.service';
import { ModalService } from '../../_services/modal.service';
import { Profile, Termination } from '../../models/overview/profile.model';
import { CountryService } from '../../services/country.service';
import { CommonService } from '../../services/common.service';
import { JobService } from '../../services/job.service';
import { CandidateService } from '../../services/candidate.service'; 
import { UserService } from '../../services/user.service';
import { PermissionService } from '../../services/permission.service';
import { PersonaltypeService } from '../../services/personaltype.service';
import { CompanyService } from '../../services/company.service';
import { AlertService } from '../../services/alert.service';
import { ChecksystemService } from '../../services/checksystem.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Routes, RouterModule } from '@angular/router';
import { ZoomService } from '../../services/zoom.service';

@Component({
  selector: 'app-checkemployee',
  templateUrl: './checkemployee.component.html',
  styleUrls: ['./checkemployee.component.css']
})
export class CheckemployeeComponent implements OnInit {

  data: any;
  datalist:any;
  submitted: any;
  employeeForm: FormGroup;
  terminationForm: FormGroup;
  overviewData: any;
  terminationDetail: any;
  countrylist: any;
  dltypelist: any;
  dllist: any;
  joblist: any;
  employeeid:any;
  candidatelist: any;
  Userid: any;
  th = [];
  ismail: any;
  emailexist = false;
  public searchText: string;
  permissionstring: any;
  mindob = new Date();
  maxdob = new Date();
  phonecheck: any;
  empidexist = false;
  disableBtn: any;
  permission: any;
  empperm: any;
  defaultVal: any = "";
  record:number;
  emptype:any;
  items: Array<any>;
  h1tag: any = "";
  remainingcheck: any;
  employeereq: any = false;
  pageOfItems: Array<any>;

  constructor(
    private userService: UserService, 
    private employeeService: EmployeeService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute, 
    private formBuilder: FormBuilder, 
    private jobdetails: JobService, 
    private candidatedetails: CandidateService,
    private permissiondetails: PermissionService,
    private dltypeService: PersonaltypeService,
    private companyService: CompanyService,
    private alerts: AlertService,    
    private check: ChecksystemService,    
    private router : Router,    
    private route: ActivatedRoute,
    private zoomService: ZoomService
    ) {

      route.params.subscribe(params => {
        this.emptype = params['type'];
        if(this.emptype == 'visa')
        {
          this.h1tag = "BRP";
        }
        else if(this.emptype == 'passport')
        {
          this.h1tag = "Passport";
        }
        else if(this.emptype == 'document')
        {
          this.h1tag = "Driving Licence";
        }
        // console.log(this.emptype);
      });
     }

  ngOnInit() {
    this.Userid = localStorage.getItem('userid');
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    // console.log(this.permission);
    if(this.permission['m101'] < 1)
    {
      this.router.navigate(['/permission-denied']);
    }
    // this.emptype = this.activatedRoute.snapshot.paramMap.get("type");
    this.check.getbillinginformation({ 'serviceid': 7 }).subscribe((remaindata: any) => { 
      this.remainingcheck = remaindata;
    });

    this.mindob.setDate(this.mindob.getDate() - 23741);
    this.maxdob.setDate(this.maxdob.getDate() - 574);

    this.employeeForm = this.formBuilder.group({
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      empid: ['', Validators.required],
      gender: ['', Validators.required],
      maritalstatus: ['', Validators.required],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: ['', Validators.compose([Validators.required])],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      residenceid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      dateofbirth: ['', Validators.required],
      hiredate: ['', Validators.required],
      emprole: ['', Validators.required]
    });

    this.terminationForm = this.formBuilder.group({
      employeeid: ['', Validators.required],
      terminationdate: ['', Validators.required],
      terminationreason: ['', Validators.required],
      oktorehire: ['', Validators.required],
      regrettermination: ['', Validators.required]
    });

    this.overviewData = new Profile();
    this.terminationDetail = new Termination();

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.jobdetails.getjobList().subscribe((jobdetails: any) => {
      this.joblist = jobdetails;
    });

    this.dltypeService.getactivepersonaltypelist().subscribe((dltypes: any) => {
      this.dltypelist = dltypes;
    });

    this.dltypeService.getdrivinglicenselist().subscribe((dlicense: any) => {
      this.dllist = dlicense;
    });

    this.permissiondetails.bindallmenu().subscribe((menudata: any) => {
      var permissionstring = "[{";
      var perarray = [];
      menudata.forEach(function (valuemenu) {
        perarray.push('"' + valuemenu.clname + '": 0');
      });
      
      var perstr = perarray.join();
      permissionstring = permissionstring + perstr + "}]";
      this.permissionstring = permissionstring;
    });

    this.getEmployees();

  }

  getEmployees()
  {
    this.employeeService.getlistofemployeeForCheck().subscribe((employeedata: any) => {
      // employeedata.forEach(function (value) {
      //   var tg;
      //   console.log(value.permission);
      //   tg = JSON.parse(value.permission.toString());
      //   tg.forEach(function (value4) {
      //   });
      // });
      this.data = employeedata;
      this.record = this.data.length;
      this.datalist = this.data;
      this.pageOfItems = this.data;

      if(this.record < 30){
        this.pageOfItems = this.data;
      }
      else{
           this.items =this.data;
      }     
    });
  }

  
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  get f() { return this.employeeForm.controls; }

  get g() { return this.terminationForm.controls; }

  onVisaSubmit(employeeform1: any, modelid: any) {
    this.submitted = true;
    if (this.employeeForm.invalid) {
      return;
    }
    this.disableBtn = false;
    
    employeeform1.id = this.Userid;
    employeeform1.permission = this.permissionstring;
    this.userService.isEmailRegisterd(employeeform1.emailid).subscribe((data: any) => {
      if (data.sussess == 'success') {
        this.emailexist = true; 
        this.disableBtn = true;
      }
      else {
        this.companyService.checkcompanyemployeeid({'empid': employeeform1.empid}).subscribe((checkempid: any) => {
          if(checkempid.sussess == 'success')
          {
            this.empidexist = true; 
            this.disableBtn = true;
          }
          else
          {
            this.disableBtn = false;
            this.employeeService.checkemployeecreate(employeeform1).subscribe((data: any) => {
              this.modalService.close(modelid);
              this.getEmployees();
            });
          }
          
        })
        
      }
    });
  }

  onSubmitTermination(terminationform: any, modelid: any) {
    this.submitted = true;
    if (this.terminationForm.invalid) {
      return;
    }

    this.employeeService.employeeSubmitTermination(terminationform).subscribe((data: any) => {
      this.employeeService.getlistofemployeeForCheck().subscribe((employeedata: any) => {
        this.data = employeedata;
        this.employeeService.getlistofemployeeForCheck().subscribe((employeedata: any) => {
          employeedata.forEach(function (value) {
            var tg;
            tg = JSON.parse(value.permission.toString());
            tg.forEach(function (value4) {
            });
          });
          this.data = employeedata;
          this.record = this.data.length;
    
          if(this.record < 30){
            this.pageOfItems = this.data;
          }
          else{
               this.items =this.data;
          }          
          this.alerts.success('Employee has been terminated successfully');

          this.modalService.close(modelid);
        });        
      });
    });
  }

  onChangeStatus(evt: any, emp: any)
  {
    var status;
    if(evt.target.checked)
    {
      status = '1';
    }
    else if(!evt.target.checked)
    {
      status = '0';
    }
    this.employeeService.employeeUpdateChangeStatus({'userid': this.Userid, 'id': emp.employeemasterid, 'estatus': evt.target.checked}).subscribe((data: any) => {
      if(status =='1')
      {
        this.alerts.success('Employee has been activated successfully');
      }else if(status == '0')
      {
        this.alerts.error('Employee has been deactivated successfully');
      }
    });
  }
  
  focusFunction() {
    this.emailexist = false;
  }

  isEmailUnique(control: FormControl) {
    const q = new Promise((resolve, reject) => {
      this.userService.isEmailRegisterd(control.value).subscribe(() => {
        resolve(null);
      }, () => { resolve({ 'isEmailUnique': true }); });

    });
    return q;
  }

  onSelectjob(job_id: number) {
    const data = { 'jobid': job_id, 'id': this.Userid };
    this.candidatedetails.getselectedcandidate(data).subscribe((candidatedetails: any) => {
      return this.candidatelist = candidatedetails
    });
  }

  onSelectCandidate(candidateid: any) {
    const data = { 'candidateid': candidateid, 'id': this.Userid };
    this.candidatedetails.getselectedcandidatebyid(data).subscribe((candidatedetails: any) => {
      this.overviewData.fname = candidatedetails.fname;
      this.overviewData.mname = candidatedetails.mname;
      this.overviewData.lname = candidatedetails.lname;
      this.overviewData.phonenumber = candidatedetails.phonenumber;
      this.overviewData.emailid = candidatedetails.emailid;
      // return this.candidatelist = candidatedetails
    });
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
     
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  openModal2(id: string) {
    this.submitted = false;
    this.disableBtn = true;
    this.overviewData = new Profile();
    this.overviewData.gender = "1";
    this.overviewData.maritalstatus = "0";
    this.phonecheck = "";
    this.overviewData.jobid = "";
    this.overviewData.candidateid = "";
    this.overviewData.nationalityid = "";
    this.overviewData.residenceid = "";
    this.overviewData.countryofbirthid = "";
    this.overviewData.ismail = false;
    
    this.companyService.checkPlan({'userid': this.Userid}).subscribe((checkemployeecount: any) => {
      if(checkemployeecount.success == "true")
      {
        this.modalService.open(id);
      }
      else
      {
        this.modalService.open("upgrade-plan");
      }
    });
    
  }

  closeModal(id: string) {
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.modalService.close(id);
  }

  openModelTerminate(empid: string, id: string)
  {
    this.submitted = false;
    this.terminationDetail.employeeid = empid;
    this.terminationDetail.oktorehire = "";
    this.terminationDetail.regrettermination = "";
    this.modalService.open(id);
  }

  sendrequestcharge(employeeid, id)
  {
    var ts = {employeeid: employeeid, requesttype: this.emptype}
    this.check.genratetokenforemployee(ts).subscribe((remaindata: any) => { 
      this.alerts.success('Application Request Sent Successfully');
      this.modalService.close(id);
    });
  }

  sendrequest(employeeid, id, emp_req: any='')
  {
    if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
    {
      this.modalService.open('upgrade-plan');
    }
    else
    {
      if(emp_req){
        this.employeereq = false;
      } else{
        this.employeereq = true;
      }
      
      this.modalService.open(id);
      this.employeeid = employeeid;
    }
  }

  // confirmUploadByAdmin()
  // {
  //   if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
  //   {
  //     this.modalService.open('upgrade-plan');
  //   }
  //   else
  //   {
  //     this.employeeid = employeeid;
  //     this.modalService.open('sendrequestcharge');
  //   }
  // }

  uploadByAdmin(emp_type, type, empid)
  {
    if(type == 'upload_now')
    {
      if(emp_type == 'visa')
      {
        this.router.navigate(['/check-ai-visa-admin', empid]);
      }
      else if(emp_type == 'passport')
      {
        this.router.navigate(['/check-ai-passport-admin', empid]);
      }
      else if(emp_type == 'document')
      {
        this.router.navigate(['/check-ai-document-admin', empid]);
      }
    }
    else if(type == 'add_new')
    {


      if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
      {
        this.modalService.open('upgrade-plan');
      }
      else
      {
        this.router.navigate(['/check-add-applicant', emp_type]);
        // this.router.navigate(['/check-add-applicant', 'passport']);
      }
      // upgrade-plan

     




    }      
  }

  // sendZoomLink(emailId)
  // {

  //   this.zoomService.sendZoomLink({"email":"fareed@complygate.co.uk"}).subscribe((data: any) => {
     
  //       console.log(data);
        
      
  //   });
  // }

}

