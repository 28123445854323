<mat-tab-group>       

  <mat-tab label="Group Structure">
    <ng-template matTabContent>
      <div class="summary_sec">
        <div class="row">
          <div class="col-sm-6">
              <div class="keyinfo noheight">
                  <h4>Group Structure Statistics</h4>
                      <div class="infodest">
                          <p>Group</p>
                          <span>{{company.group_structure_stats.total_group_companies}} companies in {{company.group_structure_stats.total_group_countries}} countries</span>
                      </div>
                      <div class="infodest">
                          <p>Linkages</p>
                          <span>0 companies in 0 countries</span>
                      </div>
                    
              </div>
          </div>
          <div class="col-sm-6">
              <div class="keyinfo noheight">
                  <h4>Group Structure Summary</h4>
                      <div class="infodest">
                          <p>Holding Company</p>
                          <span>{{company.group_structure_summary.holding_company}}</span>
                      </div>
                      <div class="infodest">
                          <p>Ownership Status</p>
                          <span>{{this.company.group_structure_summary.ownership_status}}</span>
                      </div>
                      <div class="infodest">
                          <p>Ultimate Holding Company</p>
                          <span>{{company.group_structure_summary.ultimate_holding_company}}</span>
                      </div>
                   
                     
              </div>
          </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
            <div class="keyinfo noheight">
                <div class="row">
                    <div class="col-sm-12">
                        <h4>Group Structure</h4>
                    </div>
                    <div class="col-sm-12" *ngIf="company.group_structure.length">
                        <div class="block_tables_head">
                            <div class="first">
                                <h4>Company Name</h4>
                            </div>
                            <div class="first">
                                <h4>Safe Number</h4>
                            </div>
                            <div class="first">
                                <h4>Registered Number</h4>
                            </div>
                            <div class="first">
                                <h4>Latest Key Financials</h4>
                            </div>
                            <div class="first">
                                <h4>Consol. Accounts</h4>
                            </div>
                            <div class="first">
                                <h4>Score</h4>
                            </div>
                            <div class="first">
                                <h4>Limit</h4>
                            </div>
                            <div class="first">
                                <h4>Turnover</h4>
                            </div>
                        </div>
                        <div class="block_tables" *ngFor="let comp of company.group_structure">
                            <div class="first">
                                <p>{{comp.companyName}}</p>
                            </div>
                            <div class="first">
                                <p>{{comp.safeNumber}}</p>
                            </div>
                            <div class="first">
                                <p>{{comp.registeredNumber}}</p>
                            </div>
                            <div class="first">
                                <p>{{comp.latestAnnualAccounts | date: 'MM/dd/yyyy'}}</p>
                            </div>
                            <div class="first">
                                <p></p>
                            </div>
                            <div class="first">
                                <p></p>
                            </div>
                            <div class="first">
                                <p></p>
                            </div>
                            <div class="first">
                                <p></p>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-sm-12" *ngIf="!company.group_structure.length">
                        <p class="norecordfound">No Group Structure information to display </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

      </div>
   </ng-template>
  </mat-tab>

  <mat-tab label="Linkages">
    <ng-template matTabContent>
        <div class="summary_sec">
          <div class="row">
            <div class="col-sm-12">
                <div class="keyinfo noheight">
                    <div class="row">
                        <div class="col-sm-12">
                            <h4>Linkages</h4>
                        </div>
                        <div class="col-sm-12">
                            <p class="norecordfound">There is no data to display</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </ng-template>
  </mat-tab>

  
</mat-tab-group>    