<div class="check_verification" *ngIf="token_expired">
  <div class="wizadlineshow">
    <div class="wizard_details">
      <div class="container">
        <div class="main-banner">
          <div class="banner-form">
            <div class="row">
              <div class="col-sm-12">
                <div class="tankushow">
                  <div class="detailstext">
                      <h1 class="tokenfont">Token Expired</h1>
                      <p></p>
                  </div>
                  <img alt="shape" src="../../../../../assets/img/thanks.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<form [formGroup]="visapopForm"  #visaForm="ngForm" id="visa-form-submit" class="s12 white docvisapass" *ngIf="visaDetail && !token_expired">
  <div class="check_verification">
    <div class="wizadlineshow">
      <div class="wizard_img_box"  *ngIf="wizard != 5">
        <div class="wizard_img" [class.active]="wizard == 1 || wizard == 2 || wizard == 3 || wizard == 4 || wizard == 5">
          <img src="../../../../../assets/img/icon/1214.png">
        </div>
        <div class="wizard_img" [class.active]="wizard == 2 || wizard == 3 || wizard == 4 || wizard == 5">
          <img src="../../../../../assets/img/icon/1214.png">
        </div>
        <div class="wizard_img"  [class.active]="wizard == 3 || wizard == 4 || wizard == 5">
          <img src="../../../../../assets/img/icon/1231.png">
        </div>
        <div class="wizard_img"  [class.active]="wizard == 4 || wizard == 5">
          <img src="../../../../../assets/img/icon/1111.png" >
        </div>
      </div>
      <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="visaDetail.employeeid">
      <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="visaDetail.employeevisadeatilid">

      <div id="wizard-1" *ngIf="wizard == 0" class="wizard_details">
          <div class="container">
              <div class="main-banner">
                  <div class="overviewsshow check_support mt-4">
                      <div class="row">
                          <div class="col-sm-12">
                              <div class="header">
                                  <h1 class="headprofile mt-0 mb-0">Add BRP Details</h1>
                              </div>
                              <hr/>
                          </div>
                          <div class="col-sm-12">
                              <div class="row">
                              <div class="form-group col-lg-6">
                                  <label>BRP No.*</label>
                                  <input type="visanumber" placeholder="Please Enter BRP No" formControlName="visanumber"
                                      name="visanumber" id="visanumber" class="form-control" maxlength="10"
                                      [ngClass]="{ 'is-invalid': submitted && f.visanumber.errors }"
                                      [(ngModel)]="visaDetail.visanumber">
                                  <div *ngIf="submitted && f.visanumber.errors" class="invalid-feedback">
                                      <div *ngIf="f.visanumber.errors.required">Please Enter BRP No.</div>
                                  </div>
                              </div>
                              <div class="form-group col-lg-6">
                                  <label>BRP Type*</label>
                                  <input type="visatype" formControlName="visatype" placeholder="Please Enter BRP Type"
                                      class="" name="visatype" id="visatype" class="form-control" maxlength="30"
                                      [ngClass]="{ 'is-invalid': submitted && f.visatype.errors }"
                                      [(ngModel)]="visaDetail.visatype">
                                  <div *ngIf="submitted && f.visatype.errors" class="invalid-feedback">
                                      <div *ngIf="f.visatype.errors.required">Please Enter BRP Type </div>
                                  </div>
                              </div>
                              </div>
                              <div class="row">
                                  <div class="form-group col-lg-6">
                                      <label>Start Date*</label>
                                      <mat-form-field class="datecontrol">
                                          <input matInput [matDatepicker]="picker1" name="visastart" id="visastart" class="form-control noborder-invalid" formControlName="visastart" [ngClass]="{ 'is-invalid': submitted && f.visastart.errors }" [(ngModel)]="visaDetail.visastart">
                                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                          <mat-datepicker #picker1></mat-datepicker>
                                      </mat-form-field>
                                      <div *ngIf="submitted && f.visastart.errors" class="invalid-feedback">
                                          <div *ngIf="f.visastart.errors.required">Please Choose Start Date</div>
                                      </div>
                                  </div>
                                  <div class="form-group col-lg-6">
                                      <label>Expiry Date *</label>
                                      <mat-form-field class="datecontrol">
                                          <input matInput [matDatepicker]="picker2" [ngClass]="{ 'is-invalid': submitted && f.visaexpiry.errors }" name="visaexpiry" formControlName="visaexpiry" [min]="visapopForm.controls.visastart.value" [(ngModel)]="visaDetail.visaexpiry" id="visaexpiry" class="form-control noborder-invalid datecontrol">
                                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                          <mat-datepicker #picker2></mat-datepicker>
                                      </mat-form-field>
                                      <div *ngIf="submitted && f.visaexpiry.errors" class="invalid-feedback">
                                          <div *ngIf="f.visaexpiry.errors.required">Please Choose Expiry Date</div>
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="form-group col-lg-6">
                                      <label>BRP Issue Country*</label>
                                      <select formControlName="nationalityid" name="nationalityid" id="nationalityid" class="width100 form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [(ngModel)]="visaDetail.nationalityid">
                                      <option value="">Select BRP Country </option>
                                      <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                                  </select>
                                      <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                          <div *ngIf="f.nationalityid.errors.required">Please Select BRP Issue Country</div>
                                      </div>
                                  </div>
                              </div>
                              <hr/>
                              <div class="row">
                                  <div class="col-lg-12 col-md-12 text-right aligncenter">
                                      <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                      <div class="next">
                                          <button type="button" class="btn btn-primary com_btn" (click)="toggle('1')">Next</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div id="wizard-2"  *ngIf="wizard == 1"  class="wizard_details">
        <div class="container">
          <div class="main-banner">
            <div class="overviewsshow check_support mt-4">
              <div class="row">
                <div class="col-sm-12">
                  <div class="header">
                    <h1 class="headprofile mt-0 mb-0">Front Side BRP Image</h1>
                  </div>
                  <hr/>
                </div>
                <div class="col-sm-12 text-center">
                  <webcam class="videowidth" [trigger]="triggerObservable" *ngIf="!webcamImage" (imageCapture)="handleImage($event)"
                    [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                    [videoOptions]="videoOptions"
                    [imageQuality]="1"
                    (cameraSwitched)="cameraWasSwitched($event)"
                    (initError)="handleInitError($event)" ></webcam>
                  <div class="snapshot" *ngIf="webcamImage">
                    <img [src]="webcamImage.imageAsDataUrl"/>
                  </div>
                  <div class="camerashap_aligncenter">
                      <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshot();"  *ngIf="!webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Capture </span></button>
                      <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshotreload();"   *ngIf="webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Recapture  </span></button>
                  </div>
                </div>
              </div>
              <hr/>
              <div class="row">
                <div class="col-lg-12 col-md-12 text-right aligncenter">
                  <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                  <div class="next">
                    <button type="submit" (click)="toggle('0')" class="btn btn-dark mr-2 com_btn">Previous</button>
                    <button type="button" class="btn btn-primary com_btn" (click)="toggle('2')" *ngIf="webcamImage">Next</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="wizard-2"  *ngIf="wizard == 2"  class="wizard_details">
        <div class="container">
          <div class="main-banner">
            <div class="overviewsshow check_support mt-4">
              <div class="row">
                  <div class="col-sm-12">
                    <div class="header">
                      <h1 class="headprofile mt-0 mb-0">Back Side BRP Image</h1>
                    </div>
                    <hr/>
                  </div>
                  <div class="col-sm-12 text-center">
                    <webcam class="videowidth" [trigger]="triggerObservable" *ngIf="!webcamImage" (imageCapture)="handleImage($event)"
                      [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                      [videoOptions]="videoOptions"
                      [imageQuality]="1"
                      (cameraSwitched)="cameraWasSwitched($event)"
                      (initError)="handleInitError($event)" ></webcam>

                    <div class="snapshot" *ngIf="webcamImage">
                      <img [src]="webcamImage.imageAsDataUrl"/>
                    </div>

                    <div class="camerashap_aligncenter">
                      <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshot();"  *ngIf="!webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Capture</span></button>
                      <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshotreload();"   *ngIf="webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Recapture</span></button>
                    </div>
                  </div>
              </div>
              <hr/>
              <div class="row">
                <div class="col-lg-12 col-md-12 text-right aligncenter">
                  <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                  <div class="next">
                    <button type="submit" (click)="toggle('1')" class="btn btn-dark mr-2 com_btn">Previous</button>
                    <button type="button" (click)="toggle('3')" class="btn btn-dark mr-2 com_btn">Skip</button>
                    <button type="button" class="btn btn-primary com_btn" (click)="toggle('3')"  *ngIf="webcamImage">Next</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="wizard-23"  *ngIf="wizard == 3"  class="wizard_details">
        <div class="container">
          <div class="main-banner">
            <div class="overviewsshow check_support mt-4">
              <div class="row">
                <div class="col-sm-12">
                  <div class="header">
                    <h1 class="headprofile mt-0 mb-0">Upload Selfie</h1>
                  </div>
                  <hr/>
                </div>
                <div class="col-sm-12 text-center">
                  <webcam class="videowidth" [trigger]="triggerObservable" *ngIf="!webcamImage" (imageCapture)="handleImage($event)"
                      [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                      [videoOptions]="videoOptions"
                      [imageQuality]="1"
                      (cameraSwitched)="cameraWasSwitched($event)"
                      (initError)="handleInitError($event)" ></webcam>

                  <div class="snapshot" *ngIf="webcamImage">
                    <img [src]="webcamImage.imageAsDataUrl"/>
                  </div>
                  <div class="camerashap_aligncenter">
                    <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshot();"  *ngIf="!webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Capture</span></button>
                    <button class="actionBtn btn btn-success camerashap" (click)="triggerSnapshotreload();"   *ngIf="webcamImage" ><span class="lnr lnr-camera"></span> <span class="text">Recapture </span></button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="imgcent col-lg-12 col-md-12" *ngIf="profile_pic_url">
                  <img class="width100" [src]="profile_pic_url"  >
                </div>
              </div>
              <hr/>
              <div class="row">
                <div class="col-lg-12 col-md-12 text-right aligncenter">
                  <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                  <div class="next">
                    <button type="submit" (click)="toggle('2')" class="btn btn-dark mr-2 com_btn">Previous</button>
                    <button type="button" class="btn btn-primary com_btn" (click)="toggle('4')" *ngIf="webcamImage">Next</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="wizard-22"  *ngIf="wizard == 4"  class="wizard_details">
          <div class="container">
              <div class="main-banner">
                  <div class="overviewsshow check_support mt-2">
                      <div class="row">
                          <div class="col-sm-12">
                              <div class="header">
                                  <h1 class="headprofile mt-0 mb-0">BRP Uploaded Successfully</h1>
                              </div>
                              <hr/>
                          </div>
                          <div class="col-sm-12">
                              <div class="uploaded_img" *ngIf="!showloader">
                                  <div class="img_sec">
                                      <img [src]="frontimage"/>
                                      <i class="fa fa-check" aria-hidden="true"></i>
                                  </div>
                                  <div class="img_sec" *ngIf="backimage">
                                      <img [src]="backimage"/>
                                      <i class="fa fa-check" aria-hidden="true"></i>
                                  </div>
                                  <div class="img_sec">
                                      <img [src]="profileimage"/>
                                      <i class="fa fa-check" aria-hidden="true"></i>
                                  </div>
                              </div>
                              <div *ngIf="showloader">
                                  <div class="browser-screen-loading-content" >
                                      <div class="loading-dots dark-gray">
                                          <i></i>
                                          <i></i>
                                          <i></i>
                                          <i></i>
                                          <i></i>
                                          <i></i>
                                          <i></i>
                                      </div>
                                      <p>Loading</p>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <hr/>
                      <div class="row">
                          <div class="col-lg-12 col-md-12 text-right aligncenter">
                              <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                              <div class="next">
                                  <button type="submit" (click)="toggle('3', 'prev')" class="btn btn-dark mr-2 mt-0 com_btn">Previous</button>
                                  <button type="button" class="btn btn-primary com_btn mt-0" (click)="onSubmit(visaForm.value)">Next</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div id="wizard-4"  *ngIf="wizard == 5" class="wizard_details">
          <div class="container">
              <div class="main-banner">
                  <div class="overviewsshow check_support mt-4">
                      <div class="row">
                          <div class="col-sm-12">
                              <div class="tankushow">
                                  <div class="detailstext">
                                      <h1>BRP Uploaded Successfully</h1>
                                      <p>You do not need to take any further action </p>
                                  </div>
                                  <img alt="shape" src="../../../../../assets/img/thanks.png">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div id="wizard-5"  *ngIf="wizard == 6" class="wizard_details">
          <div class="container">
              <div class="main-banner">
                  <div class="banner-form">
                      <div class="row">
                          <div class="col-sm-12">
                              <div class="browser-screen-loading-content" >
                                  <div class="loading-dots dark-gray">
                                      <i></i>
                                      <i></i>
                                      <i></i>
                                      <i></i>
                                      <i></i>
                                      <i></i>
                                      <i></i>
                                  </div>
                                  <p>Loading</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</form>
