import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { DivisionService } from '../../../services/division.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Division } from '../../../models/setting/division.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';


@Component({
  selector: 'app-settingdivision',
  templateUrl: './settingdivision.component.html',
  styleUrls: ['./settingdivision.component.css']
})
export class SettingdivisionComponent implements OnInit {

  Userid: any;
  data: any;
  divisionDetail = new Division();
  divisionformGroup: FormGroup;
  options = [];
  buttoninsert: any;
  submitted: boolean = false;
  disableBtn: any;
  public searchText: string;
  permission: any;
  settingperm: any;
  record:number;
  
  items: Array<any>;
  
  pageOfItems: Array<any>;
  constructor(private sanitizer: DomSanitizer,
    private modalService: ModalService,
    private divisionService: DivisionService,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.settingperm = this.permission.m81;

    this.Userid = localStorage.getItem('userid');
    this.divisionDetail = new Division();
    this.divisionformGroup = this.formBuilder.group({
      id: [''],
      typename: ['', Validators.required]
    });
    this.AllData();
   

  }

  AllData(){
    const sstatustype = { 'status': false, 'id': this.Userid } 
    this.divisionService.getdivisionlistbyadmin(sstatustype).subscribe((data: any) => {
      this.data = data;
      this.record = this.data.length;

      if(this.record < 30){
        this.pageOfItems = this.data;
      }
      else{
           this.items =this.data;
      }
    });
  }

  get f() { return this.divisionformGroup.controls; }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  onSubmit(formdivision: any, id: string) {
    // var formdivision = divisiondata.value;
    this.submitted = true;
    if (this.divisionformGroup.invalid) {
      return;
    }
    this.disableBtn = false;
    formdivision.userid = this.Userid;
    this.divisionService.settingdivisionUpdate(formdivision).subscribe((response: any) => {

      this.AllData();
      this.modalService.close(id);
   
    });

  }

  onChangeStatus(evt: any, division: any)
  {
    var cstatus;
    if(evt.target.checked)
    {
      cstatus = '1';
    }
    else if(!evt.target.checked)
    {
      cstatus = '0';
    }
    this.divisionService.divisionUpdateChangeStatus({'userid': this.Userid, 'id': division.id, 'cstatus': evt.target.checked}).subscribe((data: any) => {
      this.AllData();
      
    });
  }

  openModal(id: string, data: any) {
    this.buttoninsert = "Update";
    this.disableBtn = true;
    this.divisionDetail = data;
    this.divisionformGroup.reset(this.divisionDetail);
    this.modalService.open(id);
  }

  openModal2(id: string) {
    this.submitted = false;
    this.buttoninsert = "Submit";
    this.disableBtn = true;
    this.divisionDetail = new Division();
    this.divisionDetail.id = "0";
    this.divisionformGroup.reset(this.divisionDetail);
    this.modalService.open(id);
  }

  closeModal(id: string) {this.AllData();
    this.modalService.close(id);
  }

}
