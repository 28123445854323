<ul class="nav nav-tabs" id="myTab">
  <div class="liner"></div>

  <li>
    <a data-toggle="tab" routerlink="/personal" routerlinkactive="active" title="personal" href="#/personal" class="active">
      <span class="round-tabs one">
        <i class="glyphicon glyphicon-user"></i>
      </span>
    </a>
  </li>

  <li>
    <a data-toggle="tab" routerlink="/work" routerlinkactive="active" title="work" href="#/work" class="">
      <span class="round-tabs two">
        <i class="glyphicon glyphicon-tasks"></i>
      </span>
    </a>
  </li>

  <li>
    <a data-toggle="tab" routerlink="/address" routerlinkactive="active" title="address" href="#/address" class="">
      <span class="round-tabs three">
        <i class="glyphicon glyphicon-home"></i>
      </span>
    </a>
  </li>

  <li>
    <a data-toggle="tab" routerlink="/result" routerlinkactive="active" title="completed" href="#/result">
      <span class="round-tabs four">
        <i class="glyphicon glyphicon-ok"></i>
      </span>
    </a>
  </li>
</ul>