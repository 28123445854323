"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var IdaiwizardComponent = /** @class */ (function () {
    function IdaiwizardComponent(activatedRoute, router) {
        this.activatedRoute = activatedRoute;
        this.router = router;
    }
    IdaiwizardComponent.prototype.ngOnInit = function () {
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.employeeidproofdeatilid = this.activatedRoute.snapshot.paramMap.get("idproofid");
    };
    return IdaiwizardComponent;
}());
exports.IdaiwizardComponent = IdaiwizardComponent;
