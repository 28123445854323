import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private commonService: CommonService) { }

  getContactInfobyadmin(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/getemployeecontactinfobyadmin',data);   
  }

  getContactInfo(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/getemployeecontactinfo',data);   
  }
  
  contactus(data): Observable<any> 
  {
    return this.commonService.formpost('/contact-us-download',data);   
  }

  employeeContactinfoUpdatebyadmin(value:any): Observable<any> 
  {
    return this.commonService.formpost('/employee/employeecontactinfoupdatebyadmin',value);
  }

  employeeContactinfoUpdate(value:any): Observable<any> 
  {
    return this.commonService.formpost('/employee/employeecontactinfoupdate',value);
  }

}
