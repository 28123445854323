"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var myvisa_service_1 = require("../../../services/myvisa.service");
var mypassport_service_1 = require("../../../services/mypassport.service");
var myidproof_service_1 = require("../../../services/myidproof.service");
var router_1 = require("@angular/router");
var checkservice_service_1 = require("../../../services/checkservice.service");
var platform_browser_1 = require("@angular/platform-browser");
var modal_service_1 = require("../../../_services/modal.service");
var forms_1 = require("@angular/forms");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var candidate_model_1 = require("../../../models/jobs/candidate.model");
var common_1 = require("@angular/common");
var RightToWorkHistoryComponent = /** @class */ (function () {
    function RightToWorkHistoryComponent(uploadService, formBuilder, sanitizer, activatedRoute, visaService, checkService, passportService, modalService, idproofService, datePipe) {
        this.uploadService = uploadService;
        this.formBuilder = formBuilder;
        this.sanitizer = sanitizer;
        this.activatedRoute = activatedRoute;
        this.visaService = visaService;
        this.checkService = checkService;
        this.passportService = passportService;
        this.modalService = modalService;
        this.idproofService = idproofService;
        this.datePipe = datePipe;
        this.wizard = 1;
        this.rfiddata = [];
        this.mrzdata = [];
        this.pacedata = [];
        this.rfidlen = 0;
        this.isdecline = false;
        this.downloadtext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
        this.docpdffilename = "Choose file";
        this.candidatenotesdetails = new candidate_model_1.candidatenotes();
    }
    RightToWorkHistoryComponent.prototype.ngOnInit = function () {
        this.wizard == 1;
        this.documenttype = 'Visa';
        this.Userid = localStorage.getItem('userid');
        this.logid = this.activatedRoute.snapshot.paramMap.get("logid");
        this.getRightToWorkLog();
        this.candidatenoteFormGroup = this.formBuilder.group({
            notes: ['', forms_1.Validators.required],
            sharecode: ['']
        });
        // this.getVisaRecords();
        // this.getPassportRecords();
        // this.getDocumentsRecords();
    };
    Object.defineProperty(RightToWorkHistoryComponent.prototype, "j", {
        get: function () { return this.candidatenoteFormGroup.controls; },
        enumerable: true,
        configurable: true
    });
    RightToWorkHistoryComponent.prototype.getRightToWorkLog = function () {
        var _this = this;
        var data = { logid: this.logid };
        this.checkService.getrighttoworkdetailsbylogid(data).subscribe(function (righttoworklog) {
            var db = { qtype: 4 };
            _this.checkService.allquestion(db).subscribe(function (dataquestion) {
                if (righttoworklog.success == 'true') {
                    _this.isdecline = righttoworklog.isdecline;
                    _this.questionanslist = JSON.parse(righttoworklog.anslist);
                    _this.totalquestioncount = dataquestion.length;
                    _this.questionlist1 = dataquestion;
                    _this.righttoworklogdetails = righttoworklog;
                    _this.visarecord = righttoworklog.visa.length;
                    _this.visapageOfItems = righttoworklog.visa;
                    _this.passportrecord = righttoworklog.passport.length;
                    _this.passportpageOfItems = righttoworklog.passport;
                    _this.docrecord = righttoworklog.document.length;
                    _this.docpageOfItems = righttoworklog.document;
                    _this.selectdocument = righttoworklog.question;
                    _this.mobileapprecord = righttoworklog.mobileapp;
                    console.log(_this.mobileapprecord);
                    _this.companymaster = righttoworklog.company;
                    _this.checkbyemployee = righttoworklog.createby;
                    if (_this.mobileapprecord.pacedata) {
                        var str3 = _this.replaceAll(_this.mobileapprecord.pacedata, '\n', "");
                        _this.pacedata = JSON.parse(str3);
                    }
                    var str = _this.replaceAll(_this.mobileapprecord.rfiddata, '\n', "");
                    if (_this.mobileapprecord)
                        if (_this.mobileapprecord.rfiddata) {
                            _this.rfiddata = JSON.parse(str);
                            _this.rfidlen = _this.rfiddata.length;
                        }
                    str = _this.replaceAll(_this.mobileapprecord.mrzdata, '\n', "");
                    str = _this.replaceAll(str, '[', '{');
                    str = _this.replaceAll(str, ']', '}');
                    str = _this.replaceAll(str, '{{', '[{');
                    str = _this.replaceAll(str, '}}', '}]');
                    console.log('MRZ', str);
                    if (_this.mobileapprecord)
                        if (_this.mobileapprecord.mrzdata) {
                            _this.mrzdata = JSON.parse(str);
                        }
                }
                else {
                    _this.visarecord = 0;
                    _this.passportrecord = 0;
                    _this.docrecord = 0;
                }
            });
        });
    };
    RightToWorkHistoryComponent.prototype.replaceAll = function (string, search, replace) {
        return string.split(search).join(replace);
    };
    RightToWorkHistoryComponent.prototype.toggle2 = function (id) {
        this.wizard = id;
        if (id == 1) {
            this.documenttype = 'Visa';
        }
        else if (id == 2) {
            this.documenttype = 'Passport';
        }
        else {
            this.documenttype = 'Document';
        }
    };
    RightToWorkHistoryComponent.prototype.onChangePage = function (pageOfItems, type) {
        // update current page of items
        if (type == 'visa')
            this.visapageOfItems = pageOfItems;
        if (type == 'passport')
            this.passportpageOfItems = pageOfItems;
    };
    RightToWorkHistoryComponent.prototype.getVisaRecords = function () {
        var _this = this;
        var visa_data = { 'id': this.Userid, 'cstatus': [1, 2, 3], 'isrighttowork': 1, 'isaiverify': [1, 2, 3] };
        this.visaService.getallvisaListbyadmin(visa_data).subscribe(function (data) {
            _this.visarecord = data.length;
            _this.visapageOfItems = data;
            if (_this.visarecord < 30) {
                _this.visapageOfItems = data;
            }
            else {
                _this.visaitems = data;
            }
        });
    };
    RightToWorkHistoryComponent.prototype.getPassportRecords = function () {
        var _this = this;
        var data = { 'id': this.Userid, 'cstatus': [1, 2, 3], 'isrighttowork': 1, 'isaiverify': [1, 2, 3] };
        this.passportService.getallpassportListbyadmin(data).subscribe(function (data) {
            _this.passportrecord = data.length;
            if (_this.passportrecord < 30) {
                _this.passportpageOfItems = data;
            }
            else {
                _this.passportitems = data;
            }
        });
    };
    RightToWorkHistoryComponent.prototype.getDocumentsRecords = function () {
        var _this = this;
        var data = { 'id': this.Userid, 'cstatus': [1], 'isrighttowork': 1, 'isaiverify': [1, 2, 3] };
        this.idproofService.getallidproofListbyadmin(data).subscribe(function (data) {
            _this.docrecord = data.length;
            if (_this.docrecord < 30) {
                _this.docpageOfItems = data;
            }
            else {
                _this.docitems = data;
            }
        });
    };
    RightToWorkHistoryComponent.prototype.openModal3 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    RightToWorkHistoryComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    RightToWorkHistoryComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    RightToWorkHistoryComponent.prototype.downloadReport = function () {
        var _this = this;
        this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        var data = { logid: this.logid };
        this.checkService.downloadRightToWorkLog(data).subscribe(function (data) {
            window.open(data.response_data.Location, "_blank");
            _this.downloadtext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
        });
    };
    RightToWorkHistoryComponent.prototype.updatecandidatenote = function (value) {
        var _this = this;
        this.submitted = true;
        if (this.candidatenoteFormGroup.invalid) {
            return;
        }
        if (this.selectdocpdffilename) {
            this.docprofile = this.selectdocpdffilename;
            var filetype = this.docprofile.type.split('/');
            if (this.docprofile.size > 2000 * 1024) {
                this.pdfbackfilesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "pdf")) {
                this.pdfbackfileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.docprofile);
            value.pdfreport = imagepath;
        }
        var righttowork = {
            id: this.logid,
            notes: value.notes,
            pdfreport: value.pdfreport,
            sharecode: value.sharecode
        };
        this.checkService.updatenewrighttowork(righttowork).subscribe(function (righttoworklog) {
            _this.getRightToWorkLog();
            _this.modalService.close('add-notes-to-right-to-work');
        });
    };
    RightToWorkHistoryComponent.prototype.shouldDisplayDate = function (date) {
        if (this.datePipe.transform(date, 'yyyy-MM-dd') >= this.datePipe.transform('10/02/2022', 'yyyy-MM-dd')) {
            return true;
        }
        return false;
    };
    RightToWorkHistoryComponent.prototype.editNotes = function () {
        this.submitted = false;
        this.candidatenotesdetails.notes = this.righttoworklogdetails.notes;
        this.candidatenotesdetails.sharecode = this.righttoworklogdetails.sharecode;
        this.candidatenoteFormGroup.reset({ "notes": this.righttoworklogdetails.notes, "sharecode": this.righttoworklogdetails.sharecode });
        this.modalService.open('add-notes-to-right-to-work');
    };
    RightToWorkHistoryComponent.prototype.selectPdfFile = function (event) {
        this.pdfbackfilesizeerror = false;
        this.pdfbackfileformaterror = false;
        var files = event.target.files;
        this.docpdffilename = files[0].name;
        this.selectdocpdffilename = this.docpdf = files[0];
        var filetype = this.docpdf.type.split('/');
        if (this.docpdf.size > 2000 * 1024) {
            this.pdfbackfilesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "pdf")) {
            this.pdfbackfileformaterror = true;
        }
        else {
            this.selectdocpdffilename = files[0];
        }
    };
    return RightToWorkHistoryComponent;
}());
exports.RightToWorkHistoryComponent = RightToWorkHistoryComponent;
