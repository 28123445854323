import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';



@Injectable({
  providedIn: 'root'
})
export class CosService {

  constructor(private commonService: CommonService) { }

  getcoslist(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/cosreport',data);   
  }
}
