"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var reference_service_1 = require("../../services/reference.service");
var alert_service_1 = require("../../services/alert.service");
var checksystem_service_1 = require("../../services/checksystem.service");
var UserdeclinereferenceComponent = /** @class */ (function () {
    function UserdeclinereferenceComponent(activatedRoute, referencingmoduleService, alerts, check) {
        this.activatedRoute = activatedRoute;
        this.referencingmoduleService = referencingmoduleService;
        this.alerts = alerts;
        this.check = check;
        this.token_expired = false;
        this.decline_expired = false;
        this.stage = 0;
    }
    UserdeclinereferenceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.token = this.activatedRoute.snapshot.queryParams['token'];
        this.rd = this.activatedRoute.snapshot.queryParams['rd'];
        // this.cd=this.activatedRoute.snapshot.queryParams['cd'] 
        localStorage.setItem('userToken1', this.token);
        console.log(this.token);
        this.check.gettokensystembytoken().subscribe(function (data) {
            console.log(data);
            if (data.sussess == 'true') {
                _this.stage = 0;
                if (data.logourl == "" || data.logourl == null) {
                    _this.logo = "assets/img/logo.png";
                }
                else {
                    _this.logo = data.logourl;
                }
            }
            else {
                _this.stage = 1;
                _this.token_expired = true;
                // this.token_expired = false;
            }
        });
    };
    UserdeclinereferenceComponent.prototype.declineclicked = function () {
        var _this = this;
        var data = {
            "declinedbycandidate": "Yes",
            "declinedbycandidaterreason": this.declinereason,
            "id": this.rd
        };
        this.referencingmoduleService.declinereferencerequest(data).subscribe(function (response) {
            _this.alerts.success('Refrence request has been declined successfully');
            _this.stage = 2;
            _this.decline_expired = true;
        });
    };
    return UserdeclinereferenceComponent;
}());
exports.UserdeclinereferenceComponent = UserdeclinereferenceComponent;
