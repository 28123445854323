<div class="bg">
    <app-header></app-header>
  
  
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <!-- <a [routerLink]="['/request/request-list']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Request List</a> -->
                <div class="clearfix"></div>
                <div class="tabs__content">
  
                    <div class="tabs__content__item active-tab">
                        <div class="content"> 
                            <div class="row row-sm topheader">                            
                                <div class="col-lg-5 text-right">
                                  <app-checkcountshow></app-checkcountshow>
                                </div>
                                <div class="col-lg-7 text-right">
                                  <div class="d-flex justify-content-end align-items-center">
                                    <a class="boxbtn mainheading_btn ml-1"   (click)="uploadByAdmin('')" > + Add New Applicant </a>                                   
                                    <button class="btn com_btn btn-success"[routerLink]="['/check-employee-list/visa']"  type="button">
                                      <span class="lnr lnr-history pr-1">                                        
                                      </span> Back to Applicant List 
                                  </button>
                                
  
                                  </div>                             
                              </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 aligncenter mb-3">
                                    <h5 class="hedingcolor">BRP Verification History</h5>
                                    <input type="text" [(ngModel)]="searchText" class="form-control width35" placeholder="Search">
                                    </div>                               
                            </div>
                            <div class="browser-screen-loading-content" *ngIf="!pageOfItems" >
                                <div class="loading-dots dark-gray">
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>                                
                                    <i></i>
                                </div>
                                <p>Loading</p>
                            </div>
                            <ng-container *ngIf="( datalist | filter: {visanumber: searchText,visastart: searchText,visaexpiry: searchText }) as pageOfItems"> 
                                <div *ngFor="let visas of pageOfItems; let i=index ;">
                                    <div class="job_list_section visa_request_section pb-2 pt-2 oddeven mb-0">
                                        <!-- <div class="text-left ppsss">
                                            <p class="badge badge-warning" *ngIf="visas.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Pending by AI </p>
                                            <p class="badge badge-success" *ngIf="visas.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                            <p class="badge badge-error" *ngIf="visas.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                                        </div> -->
                                        <!-- <div class="job_detils_date ">
                                            <div class="visa_user_pic">
                                                <img src="{{employee.imagename}}" class="img width100">
                                                <img *ngIf="visas.employee.imagename" src="{{visas.employee.imagename}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                                <img *ngIf="visas.employee.gender == true && (visas.employee.imagename == null || visas.employee.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                                <img *ngIf="visas.employee.gender == false && (visas.employee.imagename == null || visas.employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                            </div>
                                        </div> -->
                                        <div class="job_detils_date width18">
                                            <div class="visa_user_details">
                                                <p class="job_address">Name</p>
                                                <h4>{{visas.employee.fname}} {{visas.employee.mname}} {{visas.employee.lname}}</h4>
                                                <!-- <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"></i> {{ visas.employee.emailid }}
                                                </p> -->
                                                <!-- <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i> {{ visas.employee.phonenumber }}</p> -->
                                                <!-- <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i> NI Number {{ visas.employee.ninumber }} </p> -->
                                                <!-- <span *ngIf="visas.employee.nationality" class="badge badge-success">{{ visas.employee.nationality.countryname }} </span> -->
                                            </div>
                                        </div>
                                        <div class="job_detils_date width10">
                                            <p class="job_address">Phone Number</p>
                                            <p class="job_profile">{{ visas.employee.phonenumber }}</p>
                                        </div>
                                        <div class="job_detils_date width10">
                                            <p class="job_address">BRP Number</p>
                                            <p class="job_profile">{{visas.visanumber}}</p>
                                        </div>
                                        <div class="job_detils_date width10">
                                            <p class="job_address">Start Date</p>
                                            <p class="job_profile">{{visas.visastart | date: 'dd MMM yyyy'}}</p>
                                        </div>
                                        <div class="job_detils_date width10">
                                            <p class="job_address">End Date</p>
                                            <p class="job_profile">{{visas.visaexpiry | date: 'dd MMM yyyy'}}</p>
                                        </div>
                                        <div class="job_detils_date width10">
                                            <p class="job_address">Check Date</p>
                                            <p class="job_profile">{{visas.createat | date: 'dd MMM yyyy'}}</p>
                                        </div>
                                        <div class="job_detils_date aligncenter width10">
                                            <button type="button" class="btn btn-primary com_btn" [innerHtml]="downloadvisatext[i]" (click)="downloadVisa(visas.employeevisadeatilid,i)"></button>
                                            <!-- <div id="container" class="green_text" *ngIf="visas.iscurrent">
                                                <div class="dot"></div>
                                                <div class="pulse"></div>
          
                                            </div>
                                            <p *ngIf="visas.iscurrent" class="job_profile green_text">Current </p>
                                            <div id="container" class="" *ngIf="!visas.iscurrent">
                                                <div class="dot"></div>
                                                <div class="pulse"></div>
          
                                            </div>
                                            <p *ngIf="!visas.iscurrent" class="job_profile">Expired </p> -->
          
                                        </div>
                                        
                                        <div class="job_detils_date aligncenter width15 verify_img">
                                            <div class="fshow">
                                                <div class="visa_user_pic front">
                                                    <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                                  <a (click)="openModal3('pdf-viewer-visa',visas.visadocname)">  <img *ngIf="visas.visadocname" src="{{visas.visadocname}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}"></a>
                                                    <img *ngIf="!visas.visadocname" src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                                  
                                                </div>
                                                <div class="tooltip_visa front"><p>Front image</p></div>
                                            </div>
                                            <div class="fshow">
                                                <div class="visa_user_pic back">
                                                    <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                                    <a (click)="openModal3('pdf-viewer-visa',visas.visabackdocname)">   <img *ngIf="visas.visabackdocname" src="{{visas.visabackdocname}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}"></a>
        
                                                    <img *ngIf="!visas.visabackdocname " src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                                  
                                                </div>
                                                <div class="tooltip_visa back"><p>Back image</p></div>
                                            </div>
                                            <div class="fshow">
                                                <div class="visa_user_pic selfie">
                                                    <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                                    <a (click)="openModal3('pdf-viewer-visa',visas.visaprofileimage)">  <img *ngIf="visas.visaprofileimage" src="{{visas.visaprofileimage}}" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}"></a>
                                                    <img *ngIf="!visas.visaprofileimage " src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ visas.employee.fname }} {{ visas.employee.mname }} {{ visas.employee.lname}}">
                                                    
                                                </div>
                                                <div class="tooltip_visa selfie"><p>Selfie</p></div>
                                            </div>
                                        </div>
                                        <div class="width100">    
                                            <div class="pull-left">                                 
                                                <p class="badge badge-warning mb-0" *ngIf="visas.isaiverify == '1' || visas.isaiverify == '0'"><i class="fa fa-hourglass-end"></i>Awaiting Authorisation </p>
                                                <p class="badge badge-success mb-0" *ngIf="visas.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                                <p class="badge badge-danger mb-0" *ngIf="visas.isaiverify == '3'"><i class="fa fa-close"></i> Rejected by AI </p>
                                            </div> 
            
                                        </div> 
                                    </div>
                                   
                                  </div>
                                  <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                    <div class="job_detils_date">
                                        <p class="job_profile">No Record Found</p>
                                    </div>
                                </div>
                            </ng-container>                      
                            
                                <!-- <div class="job_list_section visa_request_section" *ngIf="record == 0">
                                    <div class="job_detils_date">
                                        <p class="job_profile">No Record Found</p>
                                    </div>
                                </div> -->
                            
                                <!-- <div class="row">
                                    
                                    <div class="col-lg-12 text-right">
                                        <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                    </div>
                                </div> -->
  
  
  
  
  
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
  
  
  
    <jw-modal id="custom-visa-1" role="dialog" class="modal">
  
        <form [formGroup]="visapopForm" #visaForm="ngForm" id="visa-form-submit" class="s12 white" *ngIf="visaDetail">
            <div role="document" class="formgroup">
                <div class="modal-content">
                    <div class="modal-header">
  
                        <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="visaDetail.employeeid">
                        <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="visaDetail.employeevisadeatilid">
  
  
                        <h5 class="modal-title" id="popupLabel">Visa Details</h5>
                        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-visa-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                    </div>
                    <div class="modal-body">
                        <div class="container">
                            <div class="row">
                                <div class="form-group col-lg-6">
                                    <label>Visa No.*</label>
                                    <input type="visanumber" formControlName="visanumber" name="visanumber" id="visanumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.visanumber.errors }" [(ngModel)]="visaDetail.visanumber">
                                    <div *ngIf="submitted && f.visanumber.errors" class="invalid-feedback">
                                        <div *ngIf="f.visanumber.errors.required">Please Enter Visa No.</div>
                                    </div>
                                </div>
                                <div class="form-group col-lg-6">
                                    <label>Visa Type*</label>
                                    <input type="visatype" formControlName="visatype" class="" name="visatype" id="visatype" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.visatype.errors }" [(ngModel)]="visaDetail.visatype">
                                    <div *ngIf="submitted && f.visatype.errors" class="invalid-feedback">
                                        <div *ngIf="f.visatype.errors.required">Please Enter Visa Type </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-lg-6">
                                    <label>Start Date*</label>
                                    <mat-form-field class="datecontrol">
                                        <input matInput [matDatepicker]="picker1" name="visastart" id="visastart" class="form-control noborder-invalid" formControlName="visastart" [ngClass]="{ 'is-invalid': submitted && f.visastart.errors }" [(ngModel)]="visaDetail.visastart">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="submitted && f.visastart.errors" class="invalid-feedback">
                                        <div *ngIf="f.visastart.errors.required">Please Choose Start Date</div>
                                    </div>
                                </div>
                                <div class="form-group col-lg-6">
                                    <label>Expiry Date *</label>
                                    <mat-form-field class="datecontrol">
                                        <input matInput [matDatepicker]="picker2" [ngClass]="{ 'is-invalid': submitted && f.visaexpiry.errors }" name="visaexpiry" formControlName="visaexpiry" [min]="visapopForm.controls.visastart.value" [(ngModel)]="visaDetail.visaexpiry" id="visaexpiry" class="form-control noborder-invalid datecontrol">
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
  
                                    </mat-form-field>
                                    <div *ngIf="submitted && f.visaexpiry.errors" class="invalid-feedback">
                                        <div *ngIf="f.visaexpiry.errors.required">Please Choose Expiry Date</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-lg-6">
                                    <label>Visa Issue Country*</label>
                                    <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [(ngModel)]="visaDetail.nationalityid">
                                      <option [value]="">Select Visa Country </option>
                                      <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                                  </select>
                                    <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                        <div *ngIf="f.nationalityid.errors.required">Please Select Visa Issue Country</div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label>Front Side Document Image Upload</label>
                                    <div class="input-group mb-3 width22" (click)="file.click()">                                     
                                        <div class="custom-file">
                                            <input type="file" #file (change)="selectFile($event)">
                                            <label class="custom-file-label">{{filename}}</label>
                                        </div>
                                    </div>
                                    <!-- <div class="input-group mb-3 width22" (click)="file.click()">                                     
                                      <div class="custom-file">
                                          <button type="submit" class="btn btn-primary com_btn" (click)="openmodelweb('camlist-show')"  ><i class="fa fa-file-image-o"></i></button>
                                      </div>
                                  </div> -->
                                    
                                    <div class="alert-danger" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                    <div class="alert-danger" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png, tif, gif, pdf, doc)</div>
                                    <div class="invalid-feedback" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png)</div>
                                </div>
                            </div>
  
                            <div class="row">
                              <div class="col-lg-6">
                                  <label>Back Side Document Image Upload</label>
                                  <div class="input-group mb-3 width22" (click)="fileback.click()">
                                      <div class="custom-file">
                                          <input type="file" #fileback (change)="selectbackFile($event)">
                                          <label class="custom-file-label">{{filebackname}}</label>
                                      </div>
                                  </div>
                                  <div class="invalid-feedback" *ngIf="filebacksizeerror == true">File size must be less than 2 MB</div>
                                  <div class="invalid-feedback" *ngIf="filebackformaterror == true">Please choose a valid file format (jpg, png)</div>
                                  <!-- <div class="input-group mb-3 width22" (click)="file.click()">                                     
                                      <div class="custom-file">
                                          <button type="submit" class="btn btn-primary com_btn" (click)="openmodelweb('camlist-show')"  ><i class="fa fa-file-image-o"></i></button>
                                      </div>
                                  </div> -->
                              </div>
                              <div class="form-group col-lg-6">
                                  <div>
                                      Is this your Current Visa?
                                      <input type="checkbox" [checked]="visaDetail.iscurrent" formControlName="iscurrent" name="iscurrent" [(ngModel)]="visaDetail.iscurrent">
                                  </div>
                              </div>
                          </div>
  
  
  
                            <div class="row">
                                <div class="form-group col-lg-6">
                                    <div>
                                        Is this your Current Visa?
                                        <input type="checkbox" [checked]="visaDetail.iscurrent" formControlName="iscurrent" name="iscurrent" [(ngModel)]="visaDetail.iscurrent">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                        <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" (click)="onSubmit(visaForm.value,'custom-visa-1', '1')" />
                        <!-- <input type="submit" class="btn btn-success com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(visaForm.value,'custom-visa-1', '2')" value="Update & Approve" />
                        <input type="submit" class="btn btn-danger com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(visaForm.value,'custom-visa-1', '3')" value="Reject" /> -->
  
                        <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-visa-1');" />
  
                    </div>
                </div>
            </div>
        </form>
    </jw-modal>
  
    <jw-modal id="pdf-viewer-visa" class="modal" role="dialog">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-viewer-visa');">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container">
  
                        <div class="row">
                            <div class="col-lg-12">
  
                                <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="600" frameborder="0"></iframe>
                                <img *ngIf="imgsrc == true" [src]="iframesrc" width="100%" />
                            </div>
                        </div>
  
                    </div>
                </div>
  
            </div>
        </div>
    </jw-modal>
  
  
    
    <!-- <jw-modal id="camlist-show" class="modal" role="dialog">
      <div role="document" class="formgroup" *ngIf="visaDetail">
          <div class="modal-content">
              <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-viewer-visa');">
        <span aria-hidden="true">&times;</span>
      </button>
              </div>
              <div class="modal-body">
                  <div class="container">
                      <div class="row">
                          <div class="col-lg-12">
                             <app-webcam></app-webcam>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </jw-modal> -->

  
<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">         
            <div class="modal-body">
                <div class="container text-center">
                  <div class="info_mess">
                      <div class="para">
                              <h4 class="mb-2">No credits available</h4>
                              <p class="mb-0 font20">There are no credits available to perform this action. Please add more credits.</p>
  
                          </div>                          
                      </div>
                </div>
            </div>
            <div class="modal-footer text-center">
              <input type="button" class="btn btn-primary com_btn" name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
              <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
                <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
            </div>
        </div>
    </div>
  </jw-modal>



