"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.environment = {
    production: true,
    APIEndpoint: "https://service.complygate.co.uk",
    websiteurl: "https://employee.complygate.co.uk",
    stripkey: "pk_live_51BGLGAJJM2G6tpaj3Z9FVQrxZWRWkflHEmTzXiV1EqgpAXrVwJMjWamA13dKZmukxCuHvfPEkTRqw8W6Q6ErfZ8q00f1qIdBFe",
    amlurl: "https://www.kyc6.com/",
    amlkey: "ffcac440-fcfd-439c-bf00-f15206f66f8f",
};
