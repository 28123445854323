"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var customer_service_1 = require("../../services/customer.service");
var modal_service_1 = require("../../_services/modal.service");
var permanentaddress_model_1 = require("../../models/overview/permanentaddress.model");
var profile_model_1 = require("../../models/overview/profile.model");
var alert_service_1 = require("../../services/alert.service");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var country_service_1 = require("../../services/country.service");
var forms_1 = require("@angular/forms");
var upload_file_service_1 = require("./../../services/upload-file.service");
var common_service_1 = require("../../services/common.service");
var http_1 = require("@angular/common/http");
var OverviewComponent = /** @class */ (function () {
    function OverviewComponent(formBuilder, uploadService, data, country, modalService, commonService, http, alerts) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.data = data;
        this.country = country;
        this.modalService = modalService;
        this.commonService = commonService;
        this.http = http;
        this.alerts = alerts;
        this.navbarOpen = false;
        this.clicked = false;
        this.sectionHeights = [];
        this.tab = 1;
        this.options = [];
        this.mindob = new Date();
        this.maxdob = new Date();
        this.defaultValue = "";
        this.toggleNavbar = function () {
            _this.navbarOpen = !_this.navbarOpen;
        };
    }
    OverviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mindob.setDate(this.mindob.getDate() - 23741);
        this.maxdob.setDate(this.maxdob.getDate() - 574);
        this.OverForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            //  employeeid: ['', Validators.required],
            fname: ['', [forms_1.Validators.required, forms_1.Validators.min(3)]],
            mname: [''],
            lname: ['', [forms_1.Validators.required, forms_1.Validators.min(3)]],
            gender: ['', forms_1.Validators.required],
            placeofbirth: ['', forms_1.Validators.required],
            hiredate: [''],
            employeeid: [''],
            maritalstatus: ['', forms_1.Validators.required],
            ninumber: [''],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            residenceid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            countryofbirthid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            dateofbirth: ['', forms_1.Validators.required],
        });
        this.permanetForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            addressid1: ['', forms_1.Validators.required],
            addressid2: [''],
            cityname: ['', forms_1.Validators.compose([forms_1.Validators.required,])],
            mobilenumber: [''],
            pincode: ['', forms_1.Validators.required],
            statename: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            phonenumber: [''],
            countryid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])]
        });
        this.overviewData = new profile_model_1.Profile();
        this.overviewFormData = new profile_model_1.Profile();
        this.paddress = new permanentaddress_model_1.Permanentaddress();
        this.empltype = localStorage.getItem('emptype');
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.data.getOverview().subscribe(function (data) {
            _this.overviewData = data;
        });
        this.data.getPaddress().subscribe(function (data) {
            _this.paddress = data;
        });
        this.Userid = localStorage.getItem('userid');
        var data3 = { 'userid': this.Userid };
        this.data.getOtherInfo(data3).subscribe(function (data) {
            _this.employement = data;
        });
        this.data.getVisa().subscribe(function (data) {
            _this.visalist = data;
        });
    };
    OverviewComponent.prototype.onKeydown = function (event) {
        return false;
    };
    OverviewComponent.prototype.onClick = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.clicked = true;
    };
    OverviewComponent.prototype.clickedOutside = function (e) {
        if (this.clicked) {
            this._el.nativeElement.querySelector('dropdown-menu').classList.toogle('show');
        }
    };
    OverviewComponent.prototype.onmousetopbottom = function (id, height) {
        $('html, body').animate({
            scrollTop: $('#' + id).offset().top
        }, 1000, function () {
            window.location.hash = '#' + id;
        });
        this.tab = height;
    };
    OverviewComponent.prototype.onWindowScroll = function (e) {
        // let element = document.getElementById('tabs');
        // console.log(window.pageYOffset +' rahul kumar tanwar');
        var overview = document.getElementById('overview');
        var overemployeement = document.getElementById('overemployment');
        var overaddress = document.getElementById('overaddress');
        var overcompen = document.getElementById('overcompensation');
        var overcontactinfo = document.getElementById('overcontactinfo');
        var overcos = document.getElementById('overcos');
        var overvisa = document.getElementById('overvisa');
        var overpassport = document.getElementById('overpassport');
        var overidproof = document.getElementById('overidproof');
        this.overview1 = overview.offsetHeight;
        this.overemployeement1 = overemployeement.offsetHeight + this.overview1;
        this.overaddress1 = overaddress.offsetHeight + this.overemployeement1;
        this.overcompen1 = overcompen.offsetHeight + this.overaddress1;
        this.overcontactinfo1 = overcontactinfo.offsetHeight + this.overcompen1;
        this.overcos1 = overcos.offsetHeight + this.overcontactinfo1;
        this.overvisa1 = overvisa.offsetHeight + this.overcos1;
        this.overpassport1 = overpassport.offsetHeight + this.overvisa1;
        this.overidproof1 = overidproof.offsetHeight + this.overpassport1;
        if (window.pageYOffset < this.overview1) {
            this.tab = 1;
        }
        else if (window.pageYOffset < this.overemployeement1) {
            this.tab = 2;
        }
        else if (window.pageYOffset < this.overaddress1) {
            this.tab = 3;
        }
        else if (window.pageYOffset < this.overcompen1) {
            this.tab = 4;
        }
        else if (window.pageYOffset < this.overcontactinfo1) {
            this.tab = 5;
        }
        else if (window.pageYOffset < this.overcos1) {
            this.tab = 6;
        }
        else if (window.pageYOffset < this.overvisa1) {
            this.tab = 7;
        }
        else if (window.pageYOffset < this.overpassport1) {
            this.tab = 8;
        }
        else if (window.pageYOffset < (this.overidproof1 - 200)) {
            this.tab = 9;
        }
        if (window.pageYOffset > 200) {
            var element = document.getElementById('employeetopmenu');
            // console.log(element+' rahul kumar tanwar');
            element.classList.add('is-sticky');
        }
        else {
            var element = document.getElementById('employeetopmenu');
            element.classList.remove('is-sticky');
        }
    };
    OverviewComponent.prototype.onSelectCountry = function (country_id) {
        var _this = this;
        var data = { 'id': country_id };
        this.country.getstatelist(data).subscribe(function (state) {
            return _this.statelist = state;
        });
    };
    OverviewComponent.prototype.onSearchChange = function (searchValue) {
        var _this = this;
        if (searchValue.length > 4) {
            var newArray = [];
            this.commonService.getAddress(searchValue).subscribe(function (data) {
                var newdata;
                newdata = data;
                newdata.addresses.forEach(function (value) {
                    newArray.push(value + "," + searchValue);
                });
                _this.options = newArray;
            });
        }
        else {
            this.options = [];
        }
    };
    OverviewComponent.prototype.displayFn = function (value) {
        if (value != null) {
            var res = value.split(",");
            if (res.length > 6) {
                return res[7];
            }
            else {
                return value;
            }
        }
    };
    OverviewComponent.prototype.updatecode = function (evt, response) {
        res = [];
        if (evt.source.selected) {
            var res = response.split(",");
            this.paddress.addressid1 = res[0];
            this.paddress.addressid2 = res[1];
            this.paddress.cityname = res[5];
            this.paddress.pincode = res[7];
            this.paddress.statename = res[6];
            // const data = { 'id': this.paddress.countryid };
            // this.country.getstatelist(data).subscribe((state: any) => {
            //   for (var i = 0; i < state.length; i++) {
            //     if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
            //       var inde = state[i]['stateid'];
            //     }
            //   }
            //   this.paddress.statename = inde;
            //   this.statelist = state;
            // });
        }
    };
    OverviewComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    OverviewComponent.prototype.openModal = function (id, data) {
        this.submitted = false;
        // var tempData = data;
        this.overviewFormData = new profile_model_1.Profile();
        if (data.nationalityid == null) {
            data.nationalityid = "";
        }
        if (data.residenceid == null) {
            data.residenceid = "";
        }
        if (data.countryofbirthid == null) {
            data.countryofbirthid = "";
        }
        if (data.maritalstatus == false) {
            data.maritalstatus = "0";
        }
        else if (data.maritalstatus == true) {
            data.maritalstatus = "1";
        }
        else {
            data.maritalstatus = "0";
        }
        data.id = data.employeemasterid;
        if (data.gender == false) {
            data.gender = "0";
        }
        else if (data.gender == true) {
            data.gender = "1";
        }
        else {
            data.gender = "0";
        }
        // console.log(data);
        this.OverForm.reset(data);
        this.overviewFormData = data;
        this.modalService.open(id);
    };
    OverviewComponent.prototype.openModal2 = function (id, data) {
        var _this = this;
        this.submitted = false;
        this.paddress = new permanentaddress_model_1.Permanentaddress();
        this.buttoninsert = "Update";
        // this.buttoninsert = "Submit";
        this.data.getPaddress().subscribe(function (data) {
            _this.paddress = data;
        });
        this.modalService.open(id);
    };
    OverviewComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        var file = this.selectedFiles;
        this.fileformaterror = false;
        this.filesizeerror = false;
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            // this.filesizeerror = true;
            this.alerts.error('File size must be less than 2 MB.', true);
            return;
        }
        else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png")) {
            // this.fileformaterror = true;
            this.alerts.error('Please choose a valid file format (jpg, png)', true);
            // console.log(filetype[filetype.length - 1]);
            return;
        }
        this.overviewData.imagename = '../../../assets/img/loadings.gif';
        var imagepath = this.uploadService.uploadfile(file);
        var userid = localStorage.getItem('userid');
        var forupdate = { 'imagename': imagepath, 'id': userid };
        this.data.updateemployeeimage(forupdate).subscribe(function (data) {
            setTimeout(function () { return _this.overviewData.imagename = imagepath; }, 4000);
            _this.alerts.success('Profile picture updated successfully', true);
        });
    };
    Object.defineProperty(OverviewComponent.prototype, "u", {
        get: function () { return this.OverForm.controls; },
        enumerable: true,
        configurable: true
    });
    OverviewComponent.prototype.onOverviewSubmit = function (value3, id) {
        var _this = this;
        this.submitted = true;
        console.log(this.OverForm);
        if (this.OverForm.invalid) {
            return;
        }
        var sd = new Date(value3.dateofbirth);
        value3.dateofbirth = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        if (value3.hiredate) {
            var ed = new Date(value3.hiredate);
            value3.hiredate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        }
        this.data.updateemployeedetals(value3).subscribe(function (data) {
            _this.data.getOverview().subscribe(function (data) {
                _this.overviewData = data;
            });
            //  this.msg = "success";
        });
        this.alerts.success('Profile updated successfully', true);
        this.modalService.close(id);
    };
    Object.defineProperty(OverviewComponent.prototype, "h", {
        get: function () { return this.permanetForm.controls; },
        enumerable: true,
        configurable: true
    });
    OverviewComponent.prototype.onSubmit2 = function (value3, id) {
        var _this = this;
        this.submitted = true;
        if (this.permanetForm.invalid) {
            return;
        }
        var pinc = value3.pincode.split(',');
        value3.pincode = pinc[pinc.length - 1];
        this.data.permanentadddetals(value3).subscribe(function (data) {
            _this.data.getPaddress().subscribe(function (data) {
                _this.paddress = data;
            });
        });
        this.alerts.success('Permanent address updated successfully', true);
        this.modalService.close(id);
    };
    OverviewComponent.prototype.closeModal = function (id) {
        var _this = this;
        // this.overviewData = new Profile();
        // this.paddress = new Permanentaddress();
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.data.getOverview().subscribe(function (data) {
            if (data.addressid1 == null) {
                _this.buttoninsert = "Submit";
            }
            else {
                _this.buttoninsert = "Update";
            }
            _this.overviewData = data;
        });
        this.data.getPaddress().subscribe(function (data) {
            _this.paddress = data;
        });
        this.modalService.close(id);
    };
    return OverviewComponent;
}());
exports.OverviewComponent = OverviewComponent;
