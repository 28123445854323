import { AppGlobals } from '../app.global';
import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Response } from "@angular/http";
// import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { map } from 'rxjs-compat/operator/map';
import { User } from '../models/user.model';

import { CommonService } from '../services/common.service';

@Injectable()
export class UserService implements OnInit {
  readonly rootUrl = AppGlobals.API_ENDPOINT + '/login';
  readonly ssologin = AppGlobals.API_ENDPOINT + '/ssologin';
  readonly logoutUrl = AppGlobals.API_ENDPOINT + '/logout';
  readonly joinnowUrl = AppGlobals.API_ENDPOINT + '/joinnow';
  
  readonly forgotusername = AppGlobals.API_ENDPOINT + '/forget-username-request';
  readonly forgotRequestApi = AppGlobals.API_ENDPOINT + '/forget-password-request';
  // readonly forgotPasswordApi = AppGlobals.API_ENDPOINT + '/api/Users/reset-password';
  readonly checkaccess = AppGlobals.API_ENDPOINT + '/checkaccesstoken';
  readonly checkaccesstokenusingid = AppGlobals.API_ENDPOINT + '/checkaccesstokenbyid';
  readonly checkmessageaccess = AppGlobals.API_ENDPOINT + '/checkaccesstokenbyusers';
  readonly checkemailexist = AppGlobals.API_ENDPOINT + '/checkemailexist';
  readonly checkCompanyemailexist = AppGlobals.API_ENDPOINT + '/checkCompanyemailexist';
  readonly checkemailexistforadmin = AppGlobals.API_ENDPOINT + '/checkemailexistcheckforadmin';
  readonly UserMessageReply = AppGlobals.API_ENDPOINT + '/usermessagereply';
  readonly forgotPasswordApi = AppGlobals.API_ENDPOINT + '/reset-password-bytoken';

  readonly checkUserNamedApi = AppGlobals.API_ENDPOINT + '/checkusername';

  


  constructor(
    private http: HttpClient, 
    private commonService: CommonService
  ) { }
  ngOnInit() {
    // subscribe to router event
  }

  userAuthentication(data) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.rootUrl, JSON.stringify(data), { headers: reqHeader });
  }

  ssoUser(data) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.ssologin, JSON.stringify(data), { headers: reqHeader });
  }

  userMessageReply(data) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.UserMessageReply, JSON.stringify(data), { headers: reqHeader });
  }

  userjoinnow(data) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.joinnowUrl, JSON.stringify(data), { headers: reqHeader });
  }

  forgotuser(data) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.forgotusername, JSON.stringify(data), { headers: reqHeader });
  }


  
  

  resetPassword(resetapi, data) {
    const access_token = localStorage.getItem('userToken');
    // const data = {'oldPassword': oldPassword, 'newPassword': newPassword};
    const reqHeader = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'access_token': access_token,
        'Authorization': access_token
      });
    return this.http.post(resetapi, JSON.stringify(data), { headers: reqHeader });
  }


  checkUserName(data) {
    // const access_token = localStorage.getItem('userToken');
    // const data = {'oldPassword': oldPassword, 'newPassword': newPassword};
    return this.commonService.formpost('/checkusername',data);
    // const reqHeader = new HttpHeaders(
    //   {
    //     'Content-Type': 'application/json',
    //     'access_token': access_token,
    //     'Authorization': access_token
    //   });
    //   console.log(this.checkUserNamedApi);
    // return this.http.post(this.checkUserNamedApi, data, { headers: reqHeader });
  }


  logout() {
    
    const access_token = localStorage.getItem('userToken');


    const reqHeader = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'access_token': access_token,
        'Authorization': access_token
      });    


    if(access_token != null)
       return this.commonService.formpostOnlyid('/logout');// this.http.post(AppGlobals.API_ENDPOINT + '/logout', { headers: reqHeader });
       else{
       const reqHeader2 = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.logoutUrl, { headers: reqHeader2 });
  }
   
  }

  forgotRequest(email) {
    const data = { 'username': email };
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.forgotRequestApi, JSON.stringify(data), { headers: reqHeader });
  }

  isEmailRegisterdCheckForAdmin(email) {
    const data = { 'email': email };
    const access_token = localStorage.getItem('userToken');
    const reqHeader = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'access_token': access_token,
        'Authorization': access_token
      });  
    return this.http.post(this.checkemailexistforadmin, JSON.stringify(data), { headers: reqHeader });
  }

  isEmailRegisterd(email) {
    const data = { 'email': email };
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.checkemailexist, JSON.stringify(data), { headers: reqHeader });
  }

  isCompanyEmailRegisterd(email) {
    const access_token = localStorage.getItem('userToken');
    const data = { 'email': email };
    const reqHeader = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'access_token': access_token,
        'Authorization': access_token
      });  

    // const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.checkCompanyemailexist, JSON.stringify(data), { headers: reqHeader });
  }


  isUserRegisterd(email) {
    const data = { 'username': email };
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.checkUserNamedApi, JSON.stringify(data), { headers: reqHeader });
  }


  checkaccesstoken(access_token){
    const data = { 'id': access_token };
    return this.http.post(this.checkaccess,data);
  }

  checkaccesstokenbyid(userid){
    const data = { 'id': userid };

    const access_token = localStorage.getItem('userToken');
    // const data = {'oldPassword': oldPassword, 'newPassword': newPassword};
    const reqHeader = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'access_token': access_token,
        'Authorization': access_token
      });
    return this.http.post(this.checkaccesstokenusingid,data, { headers: reqHeader });
  }

  checkaccessbyreply(access_token){
    const data = { 'id': access_token };
    return this.http.post(this.checkmessageaccess,data);
  }

  forgotPassword(newPassword, access_token) {
    const access_tokenl = localStorage.getItem('userToken');
    const data = { 'newPassword': newPassword };
    const reqHeader = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'access_token': access_token
      });
    return this.http.post(this.forgotPasswordApi + "?access_token=" + access_token, JSON.stringify(data), { headers: reqHeader });
  }
}

