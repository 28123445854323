import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class FcasearchService {

  constructor(private commonService: CommonService) { }

  getFCAFirmcommonSearch(data): Observable<any> {
    return this.commonService.formpost('/fcs/fcsapicommansearch',data);
  }

  getFCAFirmDetails(data): Observable<any> {
    return this.commonService.formpost('/fcs/firmapi',data);
  }
  getFCAFirmName(data): Observable<any> {
    return this.commonService.formpost('/fcs/firmapiname',data);
  }
  getFCAFirmIndividuals(data): Observable<any> {
    return this.commonService.formpost('/fcs/firmapiIndividuals',data);
  }
  getFCAFirmRequirements(data): Observable<any> {
    return this.commonService.formpost('/fcs/firmapiRequirements',data);
  }
  getFCAFirmPermission(data): Observable<any> {
    return this.commonService.formpost('/fcs/firmapiPermission',data);
  }
  getFCAFirmPassport(data): Observable<any> {
    return this.commonService.formpost('/fcs/firmapiPassport',data);
  }
  getFCAFirmRegulators(data): Observable<any> {
    return this.commonService.formpost('/fcs/firmapiRegulators',data);
  }
  getFCAFirmAddress(data): Observable<any> {
    return this.commonService.formpost('/fcs/firmapiAddress',data);
  }
  getFCAFirmAppointedRepresentative(data): Observable<any> {
    return this.commonService.formpost('/fcs/firmapiAppointedRepresentative',data);
  }
  getFCAFirmWaivers(data): Observable<any> {
    return this.commonService.formpost('/fcs/firmapiWaivers',data);
  }
  getFCAFirmExclusions(data): Observable<any> {
    return this.commonService.formpost('/fcs/firmapiExclusions',data);
  }
  getFCAFirmDisciplinaryHistory(data): Observable<any> {
    return this.commonService.formpost('/fcs/DisciplinaryHistory',data);
  }
 

  IndividualControlledFunctions(data): Observable<any> {
    return this.commonService.formpost('/fcs/IndividualControlledFunctions',data);
  }

  IndividualDetailsFunctions(data): Observable<any> {
    return this.commonService.formpost('/fcs/IndividualsDetails',data);
  }

  // getFCAFirmDisciplinaryHistory(data): Observable<any> {
  //   return this.commonService.formpost('/fcs/DisciplinaryHistory',data);
  // }


}
