import { Component, OnInit } from '@angular/core';
import { AppGlobals } from '../../../../../app.global';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Visa } from '../../../../../models/visa/visa.model';
import { Employee } from '../../../../../models/employee.model';
import { MyvisaService } from '../../../../../services/myvisa.service';
import { CommonService } from '../../../../../services/common.service';
import { AicenterService } from '../../../../../services/aicenter.service';
import { AlertService } from '../../../../../services/alert.service';
import { ChecksystemService } from '../../../../../services/checksystem.service';
import { Base64 } from 'aws-sdk/clients/ecr';
// import { Observable } from 'rxjs';
import { Observable, Observer } from 'rxjs/Rx';

@Component({
  selector: 'app-employeeaipayment',
  templateUrl: './employeeaipayment.component.html',
  styleUrls: ['./employeeaipayment.component.css']
})
export class EmployeeaipaymentComponent implements OnInit {

  visa64Image: any;
  visaback64Image:any;
  profile64Image: any;
  
  submitted: any;
  visapopForm: FormGroup;
  visa: Visa;
  permission: any;
  requestperm: any;
  record: number;
  ctype:number;

  ispayment: boolean;
  remainingcheck: any;
  employeemasterid: any;
  logoimage3:any;
  visadetails = new Visa();  
  employee = new Employee();
  logoimage2:any;
  logoimage:any;
  logoimage4:any;
  data3:any;
  data4:any;

  
  aipayment: boolean =false;
  employeevisadeatilid:any;

  constructor(private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder,  
    private visaService: MyvisaService,     
    private aicenter:AicenterService,
    private activatedRoute: ActivatedRoute,
    private check: ChecksystemService,    
    private common: CommonService,  
    private alerts: AlertService,
    private router: Router) { }

  ngOnInit() {
    this.ispayment =true;
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;

    this.ctype  = parseInt(localStorage.getItem('ctype'));

    this.employeevisadeatilid = this.activatedRoute.snapshot.paramMap.get("visaid");

    this.data3 = {"employeevisadeatilid": this.employeevisadeatilid}
    this.visaService.visadetailsbyvisaid(this.data3).subscribe((data: any) => { 
      this.logoimage2 = data.visadocname;
      this.logoimage4 = data.visabackdocname;
      this.logoimage3 = data.visaprofileimage;

      this.employeemasterid =data.employee.employeemasterid;
      this.visadetails = data;
      this.record  = data.length;
    });
    this.check.getbillinginformation({ 'serviceid': 7 }).subscribe((remaindata: any) => { 
      this.remainingcheck = remaindata.remiancheck;
    });
    
    // this.aicenter.getcountpayment().subscribe((data: any) => {     
    //   this.aipayment = data.payment;
    // });


    this.data4 ={ "employeid": this.employeemasterid}
    this.visaService.visaaipaymentstaus(this.data4).subscribe((data5: any) => { 
      var ct = data5.count;
      if(ct ==0)
      {
        this.ispayment =false;
      }else
      {
        this.ispayment =true;
      }      
    });
  }

  Sendvisa(){
    this.data3 = {"employeevisadeatilid": this.employeevisadeatilid}
    this.visaService.visadetailsbyvisaid(this.data3).subscribe((data: any) => {
      var countryiso = data.nationality.isothree;
      this.submitted = true;
      var idtype = 'VISA';//data.documenttype.documentcode;     
      var visalog = {
        employeeid: this.employeemasterid,
        documentid: this.employeevisadeatilid,
        documenturl: data.visadocname,
        documenttype: 'v'
      };
      this.visaService.visaailogupdate(visalog).subscribe((dbvisaresult: any) => {        
        if(dbvisaresult.success =='1'){
          var data = { 
            merchantIdScanReference:dbvisaresult.logid,
            country : countryiso,
            idType : idtype,
            customerId:  this.employeemasterid,
            callbackUrl: AppGlobals.API_ENDPOINT +"/api/aiconfirmation",
            documenturl: data.visadocname,
            documentbackurl: data.visabackdocname,
            imageurl: data.visaprofileimage
          };  
          
          this.visaService.visadocumentupdate(data).subscribe((data5: any) => {
            if(data5.success == true){
              this.router.navigate(['/employeeaithankyou/' + this.employeevisadeatilid]);
            }else {
              this.alerts.error("something went wrong please try again");
            }          
          });
        }
      });
    });

  }



  
  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      let img = new Image();
      
      img.crossOrigin = 'https://complygate.s3.amazonaws.com';
      img.src = url;  img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/jpg");
    
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }


  /************ AI Required Base64 bit  ***********/

  transform(html){

    // console.log(html);
    return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  }


  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      
      reader.readAsDataURL(blob);
    })
  }

  closeModal(id){

  }


}
