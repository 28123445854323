"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var checksystem_service_1 = require("../../services/checksystem.service");
var router_1 = require("@angular/router");
var CheckcountshowComponent = /** @class */ (function () {
    function CheckcountshowComponent(router, check) {
        this.router = router;
        this.check = check;
        this.href = "";
    }
    CheckcountshowComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.href = this.router.url;
        console.log(this.href);
        var ts;
        if (this.href == '/check-personal-aml-peps-sanction' || this.href == '/check-business-aml-peps-sanction' || this.href == '/search-personal-aml' || this.href == '/search-business-aml/aml') {
            ts = { 'serviceid': 8 };
        }
        else if (this.href == '/check-id-verification' || this.href == '/check-identity-verification' || this.href == '/check-document-verification') {
            ts = { 'serviceid': 7 };
        }
        else if (this.href == '/right-to-work-check' ||
            this.href == '/right-to-work-app-history' ||
            this.href.split('/')[1] == 'right-to-work-check-details' ||
            this.href.split('/')[1] == 'right-to-work-app' ||
            this.href.split('/')[1] == 'right-to-work-app-history-list' ||
            this.href.split('/')[1] == 'right-to-work-app-history') {
            ts = { 'serviceid': 4 };
        }
        else if (this.href == '/tax-defaulter-check') {
            ts = { 'serviceid': 9 };
        }
        else if (this.href == '/dbs/basic' || this.href == '/dbs-history/basic') {
            ts = { 'serviceid': 2 };
        }
        else if (this.href == '/dbs/standard' || this.href == '/dbs-history/standard') {
            ts = { 'serviceid': 3 };
        }
        else if (this.href == '/dbs/enhanced' || this.href == '/dbs-history/enhanced') {
            ts = { 'serviceid': 1 };
        }
        else if (this.href == '/adversecredit' || this.href.split('/')[1] == 'adversecredit') {
            ts = { 'serviceid': 6 };
        }
        else if (this.href == '/dvla-check' || this.href == '/dvla-check-history') {
            ts = { 'serviceid': 5 };
        }
        else if (this.href == '/add-new-reference' || this.href == '/add-bulk-reference' || this.href == '/reference-status' || this.href == '/bulk-reference-status') {
            ts = { 'serviceid': 11 };
        }
        else if (this.href == '/company-advert-search' ||
            this.href == '/dvla-check-history' ||
            this.href.split('/')[1] == 'company-advert-search') {
            ts = { 'serviceid': 10 };
        }
        else if (this.href == '/director-search') {
            ts = { 'serviceid': 12 };
        }
        else if (this.href == '/search-personal-aml/adverse') {
            ts = { 'serviceid': 22 };
        }
        else if (this.href == '/search-personal-aml/global' || this.href == '/search-business-aml/global') {
            ts = { 'serviceid': 23 };
        }
        else {
            ts = { 'serviceid': 7 };
        }
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            _this.checkcount = remaindata;
        });
        console.log(this.router.url);
    };
    CheckcountshowComponent.prototype.getRemainingChecks = function () {
        var _this = this;
        var ts;
        if (this.href == '/check-personal-aml-peps-sanction' || this.href == '/check-business-aml-peps-sanction') {
            ts = { 'serviceid': 8 };
        }
        else if (this.href == '/check-id-verification' || this.href == '/check-identity-verification' || this.href == '/check-document-verification') {
            ts = { 'serviceid': 7 };
        }
        else if (this.href == '/right-to-work-check' ||
            this.href == '/right-to-work-app-history' ||
            this.href.split('/')[1] == 'right-to-work-check-details' ||
            this.href.split('/')[1] == 'right-to-work-app' ||
            this.href.split('/')[1] == 'right-to-work-app-history-list' ||
            this.href.split('/')[1] == 'right-to-work-app-history') {
            ts = { 'serviceid': 4 };
        }
        else if (this.href == '/tax-defaulter-check') {
            ts = { 'serviceid': 9 };
        }
        else if (this.href == '/dbs/basic' || this.href == '/dbs-history/basic') {
            ts = { 'serviceid': 2 };
        }
        else if (this.href == '/dbs/standard' || this.href == '/dbs-history/standard') {
            ts = { 'serviceid': 3 };
        }
        else if (this.href == '/dbs/enhanced' || this.href == '/dbs-history/enhanced') {
            ts = { 'serviceid': 1 };
        }
        else if (this.href == '/adversecredit' || this.href.split('/')[1] == 'adversecredit') {
            ts = { 'serviceid': 6 };
        }
        else if (this.href == '/dvla-check' || this.href == '/dvla-check-history') {
            ts = { 'serviceid': 5 };
        }
        else {
            ts = { 'serviceid': 7 };
        }
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            _this.checkcount = remaindata;
        });
    };
    return CheckcountshowComponent;
}());
exports.CheckcountshowComponent = CheckcountshowComponent;
