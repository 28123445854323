<app-header></app-header>
<div class="container">
  <!-- <div class="overviewsshow mt-4">
    <div class="header">
      <h1 class="headprofile mt-0">Primary Applicant</h1>
    </div>   
  </div> -->
  <div class="overviewsshow mt-3">
      <div class="header">
        <h1 class="headprofile mt-0">Primary Applicant</h1>
      </div>
      <div class="overview_details width100">
          <div class="alignbbb">
              <div class="headdd">
                  <h3 class="card-profile-name">Credit Report: Tinker Bell</h3>
              </div>
          </div>
          <hr class="mt-0"/>
          <div class="profile_dtailsshow mb-3">
              <div class="detailsshow width25">
                  <span>Name</span>
                  <p> Tinker Bell bcb</p>
              </div>
              <div class="detailsshow width25">
                <span>Current Address</span>
                <p> 107, X99LG</p>
            </div>
            <div class="detailsshow width25">
              <span>Amount</span>
              <p>Not Entered</p>
          </div>
          <div class="detailsshow width25">
            <span>Term</span>
            <p>Not Entered</p>
        </div>
        <div class="detailsshow width25">
          <span>Date of birth</span>
          <p>12/12/1990</p>
      </div>
          </div>
          <div class="profile_dtailsshow mb-3">
            <div class="detailsshow width20">
                <span>Number of Applicant</span>
                <p> 1</p>
            </div>
            <div class="detailsshow width20">
              <span>Credit Search Purpose</span>
              <p> Test Purpose</p>
          </div>
          <div class="detailsshow width40">
            <span>Credit Search Ref.</span>
            <p>CFRE45 HYSR89 KDJGD 898090CB HGHDGJHD IUHKUKJD HJKJLK</p>
        </div>
        <div class="detailsshow width20">
          <span>Date & Time of Search</span>
          <p> 5/12/2020 19:10:40</p>
      </div>  
        </div>
      </div>
      <div class="overview_details width100">
        <div class="alignbbb">
            <div class="headdd">
                <h3 class="card-profile-name">Address Confirmation</h3>
            </div>
        </div>
        <hr class="mt-0"/>
        <div class="profile_dtailsshow mb-3">
            <div class="detailsshow width60">
              <span>Address</span>
                <p><i class="lnr lnr-map-marker"></i> 4987, Chemical Close Test Town X9 GH8</p>
            </div>
            <div class="detailsshow width20">
              <span>Level</span>
              <p class="mb-0">Individul</p>
          </div>
          <div class="detailsshow width20">
            <span>Source</span>
            <p class="mb-0">Public Information</p>
        </div>
        </div>
        <div class="profile_dtailsshow mb-3">
          <div class="detailsshow width60">
            <span>Address</span>
              <p><i class="lnr lnr-map-marker"></i> 107, Global Ave Town X98 TTR8</p>
          </div>
          <div class="detailsshow width20">
            <span>Level</span>
            <p>Individul</p>
        </div>
        <div class="detailsshow width20">
          <span>Source</span>
          <p>Electronic Roll</p>
      </div>
      </div>
    </div>
  </div>
  <div class="overviewsshow mt-1">
    <div class="header">
      <h1 class="headprofile mt-0">Impaired credit History</h1>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <div class="border-box impairedminhe">
            <div class="overview_details emp">
                <div class="profile_dtailsshow">
                    <div class="detailsshow width100 impaired_credit">
                        <p class="mb-0 pr-1">1 or more CCJ with a Total Values greater than 600 in last 36 month</p>
                        <span class="badge badge-success">Yes</span>
                    </div>
                </div>
            </div>
        </div>

      </div>
      <div class="col-sm-3">
        <div class="border-box impairedminhe">
            <div class="overview_details emp">
                <div class="profile_dtailsshow">
                    <div class="detailsshow width100 impaired_credit">
                        <p class="mb-0 pr-1">1 or more CCJ with a Total Values greater than 600 in last 36 month</p>
                        <span class="badge badge-success">Yes</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="border-box impairedminhe">
            <div class="overview_details emp">
                <div class="profile_dtailsshow">
                    <div class="detailsshow width100 impaired_credit">
                        <p class="mb-0 pr-1">1 or more CCJ with a Total Values greater than 600 in last 36 month</p>
                        <span class="badge badge-success">Yes</span>
                    </div>
                  
                
                </div>
            </div>
        </div>

      </div>
    </div>
  
</div>
<div class="overviewsshow mt-1">
  <div class="header">
    <h1 class="headprofile mt-0">Public Information</h1>
  </div>
  <div class="overview_details width100">
      <div class="alignbbb">
          <div class="headdd">
              <h3 class="card-profile-name">Current BAI Record</h3>            
          </div>
          <span class="badge badge-danger">Yes</span>
      </div>
      <hr class="mt-0"/>
      <div class="profile_dtailsshow mb-3">
          <div class="detailsshow width25">
              <span>Number of Discharged BAI in last 6 Years</span>
              <p class="text-dark"><b>0</b></p>
          </div>
          <div class="detailsshow width25">
            <span>Number of Active Judgements</span>
            <p class="text-danger"><b>1</b></p>
        </div>
        <div class="detailsshow width25">
          <span>Number of Satisfied Judgements</span>
          <p class="text-danger"><b>1</b></p>
      </div>
      <div class="detailsshow width20">
        <span>Total Values of active Judgements</span>
        <p class="text-danger"><b>£ 2,000</b></p>
    </div>
      </div>
    
  </div>
 
</div>
<div class="overviewsshow mt-1">
  <div class="header">
    <h1 class="headprofile mt-0">Credit Searches</h1>
  </div>
  <div class="overview_details width100">
      <div class="alignbbb">
          <div class="headdd">
              <h3 class="card-profile-name">NB These counts are based on checking credit application and business searches</h3>            
          </div>        
      </div>
      <hr class="mt-0"/>
      <div class="profile_dtailsshow mb-3">
          <div class="detailsshow width25">
              <span>Number of Searches in last 3 Months</span>
              <p class="text-dark"><b>0</b></p>
          </div>
          <div class="detailsshow width25">
            <span>Number of Searches in last 12 Months</span>
            <p class="text-dark"><b>0</b></p>
        </div>
        
      <div class="detailsshow width25">
        <span>Number of Home Credit Searches in last 3 Months</span>
        <p class="text-danger"><b>0</b></p>
    </div>
      </div>
    
  </div>
 
</div>
<div class="overviewsshow mt-1">
  <div class="header">
    <h1 class="headprofile mt-0">Unchecked Links</h1>
  </div>
  <div class="overview_details width100">
     
      <div class="profile_dtailsshow mb-3">
          <div class="detailsshow width25">
              <span>Number of Undeclared Addresses - Searches</span>
              <p class="text-danger"><b>1</b></p>
          </div>
          <div class="detailsshow width25">
            <span>Number of Undeclared Addresses - Not Searches</span>
            <p class="text-danger"><b>0</b></p>
        </div>
        
      <div class="detailsshow width25">
        <span>Number of Undeclared Aliases </span>
        <p class="text-danger"><b>0</b></p>
    </div>
      </div>
    
  </div>
 
</div>
<div class="overviewsshow mt-1">
  <div class="header">
    <h1 class="headprofile mt-0">Noties</h1>
  </div>
  <div class="overview_details width100">
     
      <div class="profile_dtailsshow mb-3">
          <div class="detailsshow width25">
              <span>Noties of Correction Present</span>
              <p class="text-danger"><b>Yes</b></p>
          </div>
          <div class="detailsshow width25">
            <span>Number of Noties of Disputes </span>
            <p class="text-dark"><b>0</b></p>
        </div>       
      
      </div>
    
  </div>
 
</div>
<div class="overviewsshow mt-1">
  <div class="header">
    <h1 class="headprofile mt-0">Third Party data</h1>
  </div>
  <div class="overview_details width100">
     
      <div class="profile_dtailsshow mb-3">
          <div class="detailsshow width25">
              <span>Aleart Decision</span>
              <p class="text-dark"><b>No</b></p>
          </div>
          <div class="detailsshow width25">
            <span>Alert Reviews </span>
            <p class="text-dark"><b>No</b></p>
        </div>       
        <div class="detailsshow width25">
          <span>House Hold Over </span>
          <p class="text-dark">Not Requied</p>
      </div>    
      </div>
    
  </div>
 
</div>
<div class="overviewsshow mt-1 aligncenter">
  <h4 class="credit_text">Credit Score</h4>
    <p class="credit_value">603</p>
</div>
</div>
