"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var country_service_1 = require("../../services/country.service");
var myidproof_service_1 = require("../../services/myidproof.service");
var mypassport_service_1 = require("../../services/mypassport.service");
var myvisa_service_1 = require("../../services/myvisa.service");
var modal_service_1 = require("../../_services/modal.service");
var forms_1 = require("@angular/forms");
var idproof_model_1 = require("../../models/idproof/idproof.model");
var upload_file_service_1 = require("./../../services/upload-file.service");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var alert_service_1 = require("../../services/alert.service");
var aicenter_service_1 = require("../../services/aicenter.service");
var checksystem_service_1 = require("../../services/checksystem.service");
var AiapproverejectComponent = /** @class */ (function () {
    function AiapproverejectComponent(sanitizer, formBuilder, uploadService, idproofService, visaproofService, passportproofService, country, aicenter, modalService, router, alerts, check) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.idproofService = idproofService;
        this.visaproofService = visaproofService;
        this.passportproofService = passportproofService;
        this.country = country;
        this.aicenter = aicenter;
        this.modalService = modalService;
        this.router = router;
        this.alerts = alerts;
        this.check = check;
        this.defaultVal = '';
        this.aipayment = false;
        this.filterItems = [
            {
                name: 'All idproof Details',
                id: 0,
                selected: true
            },
            {
                name: 'Accepted',
                id: 2,
                selected: false
            },
            {
                name: 'Rejected',
                id: 3,
                selected: false
            },
            {
                name: 'Waiting for review',
                id: 1,
                selected: false
            }
        ];
    }
    AiapproverejectComponent.prototype.ngOnInit = function () {
        // this.downloaddltext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.requestperm = this.permission.m15;
        this.type = 'p';
        this.updaterecord();
    };
    AiapproverejectComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    AiapproverejectComponent.prototype.onDelete = function (id) {
        var _this = this;
        var data1 = { 'id': id };
        if (confirm("Are you sure you want to delete?")) {
            this.idproofService.deleteidproof(data1).subscribe(function (data) {
                var sstatus;
                for (var _i = 0, _a = _this.filterItems; _i < _a.length; _i++) {
                    var c = _a[_i];
                    if (c.selected == true) {
                        if (c.id == 0) {
                            sstatus = [1];
                        }
                        else {
                            sstatus = [c.id];
                        }
                    }
                }
                var data2 = { 'id': _this.Userid, 'cstatus': sstatus };
                _this.idproofService.getallidproofListbyadmin(data2).subscribe(function (data) {
                    _this.record = data.length;
                    if (_this.record < 30) {
                        _this.pageOfItems = data;
                    }
                    else {
                        _this.items = data;
                    }
                });
                _this.alerts.success('Address Proof Deleted Successfully.', true);
            });
        }
    };
    AiapproverejectComponent.prototype.onlistchange = function (type1, idval) {
        var _this = this;
        var sstatus;
        for (var _i = 0, _a = this.filterItems; _i < _a.length; _i++) {
            var c = _a[_i];
            if (c.id == idval)
                c.selected = true;
            else
                c.selected = false;
        }
        if (idval == 0 || type1 == false) {
            sstatus = [1];
        }
        else {
            sstatus = [idval];
        }
        var userid = localStorage.getItem('userid');
        var data = { 'id': userid, 'cstatus': sstatus };
        this.idproofService.getallidproofListbyadmin(data).subscribe(function (data) {
            _this.data = data;
        });
    };
    Object.defineProperty(AiapproverejectComponent.prototype, "f", {
        get: function () {
            return this.idproofpopForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    AiapproverejectComponent.prototype.onSubmit = function (value3, id, stype) {
        var _this = this;
        this.submitted = true;
        if (this.idproofpopForm.invalid) {
            return;
        }
        this.disableBtn = false;
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagename = imagepath;
        }
        else {
            value3.imagename = this.idproofDetail.iddocName;
        }
        if (this.selectedbackFiles) {
            this.file = this.selectedbackFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagebackname = imagepath;
        }
        else {
            value3.imagebackname = this.idproofDetail.idbackdocName;
        }
        value3.stype = stype;
        value3.createby = this.Userid;
        this.idproofService.myidproofdetailsUpdatebyadmin(value3).subscribe(function (data) {
            var sstatus;
            for (var _i = 0, _a = _this.filterItems; _i < _a.length; _i++) {
                var c = _a[_i];
                if (c.selected == true) {
                    if (c.id == 0) {
                        sstatus = [1];
                    }
                    else {
                        sstatus = [c.id];
                    }
                }
            }
            var userid = localStorage.getItem('userid');
            var data1 = { 'id': userid, 'cstatus': sstatus };
            _this.idproofService.getallidproofListbyadmin(data1).subscribe(function (data) {
                _this.record = data.length;
                if (_this.record < 30) {
                    _this.pageOfItems = data;
                }
                else {
                    _this.items = data;
                }
            });
        });
        this.filename = "Choose file";
        this.filebackname = 'Choose file';
        this.alerts.success('Address Proof Details updated successfully.', true);
        // this.idproofDetail = new idproof();
        this.modalService.close(id);
    };
    AiapproverejectComponent.prototype.selectFile = function (event) {
        var files = event.target.files;
        this.filename = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.fileformaterror = true;
            this.filesizeerror = false;
        }
        else {
            this.filesizeerror = false;
            this.fileformaterror = false;
            this.filereuirederror = false;
            this.imagesecond = this.uploadService.uploadfile(this.file);
            this.selectedFiles = files[0];
        }
    };
    AiapproverejectComponent.prototype.selectbackFile = function (event) {
        var files = event.target.files;
        this.filebackname = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filebacksizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.filebackformaterror = true;
            this.filebacksizeerror = false;
        }
        else {
            this.filebacksizeerror = false;
            this.filebackformaterror = false;
            this.imagesecond = this.uploadService.uploadfile(this.file);
            this.selectedbackFiles = files[0];
        }
    };
    AiapproverejectComponent.prototype.formsubmit = function () {
        // document.getElementById('idproof-form-submit').submit();
    };
    AiapproverejectComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.disableBtn = true;
        this.buttoninsert = "Submit";
        this.idproofDetail = new idproof_model_1.Idproof();
        this.Userid = localStorage.getItem('userid');
        this.idproofDetail.employeeid = this.Userid;
        this.idproofDetail.employeeidproofdeatilid = "0";
        // this.idproofDetail.idproofexpiry ='';
        // this.idproofDetail.idproofstart = '';
        // this.idproofDetail.idprooftype = '';
        // this.idproofDetail.nationalityid = '';
        this.filename = "Choose file";
        this.filebackname = 'Choose file';
        this.modalService.open(id);
    };
    AiapproverejectComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    AiapproverejectComponent.prototype.openModal3 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    AiapproverejectComponent.prototype.openModal = function (id, data) {
        this.idproof = new idproof_model_1.Idproof();
        this.disableBtn = true;
        this.idproofDetail = new idproof_model_1.Idproof();
        this.idproofDetail = data;
        // console.log(data);
        this.buttoninsert = "Update";
        this.modalService.open(id);
    };
    AiapproverejectComponent.prototype.closeModal = function (id) {
        var _this = this;
        var data = { 'id': this.Userid, 'cstatus': [1] };
        this.idproofService.getallidproofListbyadmin(data).subscribe(function (data) {
            _this.data = data;
        });
        this.modalService.close(id);
    };
    AiapproverejectComponent.prototype.updaterecord = function () {
        var _this = this;
        var dataT = {};
        this.idproofService.getaiapprovereject(dataT).subscribe(function (data) {
            if (_this.type == 'p')
                data = data.passport;
            else if (_this.type == 'v')
                data = data.visa;
            else
                data = data.document;
            _this.record = data.length;
            _this.datalist = data;
            _this.downloaddltext = new Array(data.length);
            _this.downloadpassporttext = new Array(data.length);
            for (var i = 0; i < data.length; i++) {
                _this.downloadpassporttext[i] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            }
            console.log(data);
            for (var i = 0; i < data.length; i++) {
                _this.downloaddltext[i] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            }
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.items = data;
            }
        });
    };
    AiapproverejectComponent.prototype.uploadByAdmin = function (empid) {
        this.type = empid;
        this.updaterecord();
    };
    AiapproverejectComponent.prototype.downloadDrivingLicence = function (drivinglicencehistoryid, ind) {
        var _this = this;
        this.downloaddltext[ind] = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        this.idproofService.downloadDrivingLicence({ 'drivinglicenceid': drivinglicencehistoryid }).subscribe(function (data) {
            _this.downloaddltext[ind] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            window.open(data.response_data.Location, "_blank");
            console.log(data);
        });
    };
    AiapproverejectComponent.prototype.downloadPassport = function (passporthistoryid, ind) {
        var _this = this;
        console.log(passporthistoryid);
        this.downloaddltext[ind] = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        this.passportproofService.downloadPassport({ 'passportid': passporthistoryid }).subscribe(function (data) {
            _this.downloaddltext[ind] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            window.open(data.response_data.Location, "_blank");
            console.log(data);
        });
    };
    AiapproverejectComponent.prototype.downloadVisa = function (visahistoryid, ind) {
        var _this = this;
        this.downloaddltext[ind] = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        this.visaproofService.downloadVisa({ 'visaid': visahistoryid }).subscribe(function (data) {
            _this.downloaddltext[ind] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            window.open(data.response_data.Location, "_blank");
            console.log(data);
        });
    };
    AiapproverejectComponent.prototype.approvedoc = function (id, documenttype) {
        var _this = this;
        console.log('ID master', id);
        this.aicenter.approveAisuperDocument({ 'jumioIdScanReference': id, 'verificationStatus': 'APPROVED_VERIFIED', 'documenttype': documenttype }).subscribe(function (data) {
            _this.alerts.success("Document has been approved successfully");
            _this.updaterecord();
        });
    };
    AiapproverejectComponent.prototype.rejectdoc = function (id, documenttype) {
        var _this = this;
        this.aicenter.approveAisuperDocument({ 'jumioIdScanReference': id, 'verificationStatus': '', 'documenttype': documenttype }).subscribe(function (data) {
            _this.alerts.success("Document has been rejected successfully");
            _this.updaterecord();
        });
    };
    return AiapproverejectComponent;
}());
exports.AiapproverejectComponent = AiapproverejectComponent;
