"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var aml_model_1 = require("../../models/aml/aml.model");
var aml_service_1 = require("../../services/aml.service");
var companyadvert_service_1 = require("../../services/companyadvert.service");
var checkservice_service_1 = require("../../services/checkservice.service");
var router_1 = require("@angular/router");
var CompanyadverthistoryComponent = /** @class */ (function () {
    function CompanyadverthistoryComponent(amldetails, router, companyadvert, checkService, companyadvertService, activatedRoute) {
        this.amldetails = amldetails;
        this.router = router;
        this.companyadvert = companyadvert;
        this.checkService = checkService;
        this.companyadvertService = companyadvertService;
        this.activatedRoute = activatedRoute;
        this.atype = false;
    }
    CompanyadverthistoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        var masterarray = [];
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var tt = {};
        this.companyadvert.getcompanyhistoryadvertdata().subscribe(function (searchRes) {
            _this.downloadtext = new Array(searchRes.length);
            for (var i = 0; i < searchRes.length; i++) {
                _this.downloadtext[i] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            }
            searchRes.forEach(function (element, index) {
                searchRes[index].searchresult = JSON.parse(element.searchresult);
            });
            console.log(searchRes);
            _this.searchresult = searchRes; //searchRes;   
        });
    };
    CompanyadverthistoryComponent.prototype.downloadcompanydata = function (companycode, ind) {
        var _this = this;
        this.downloadtext[ind] = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        this.companyadvertService.downloadcompanyadvertdata({ "companycode": companycode, "companyname": '' }).subscribe(function (searchRes) {
            _this.downloadtext[ind] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            window.open(searchRes.response_data.Location, "_blank");
            console.log(searchRes);
        });
    };
    CompanyadverthistoryComponent.prototype.outputnewset = function (d) {
        var listmaster = JSON.parse(d);
        this.searchresultnew = new aml_model_1.adverst();
        this.searchresultnew = listmaster;
    };
    CompanyadverthistoryComponent.prototype.outputnewBussinessset = function (d) {
        var listmaster = JSON.parse(d);
        this.searchresultnew = new aml_model_1.AmlBussiness();
        this.searchresultnew = listmaster;
    };
    return CompanyadverthistoryComponent;
}());
exports.CompanyadverthistoryComponent = CompanyadverthistoryComponent;
