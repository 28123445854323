"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var reference_service_1 = require("../../services/reference.service");
var forms_1 = require("@angular/forms");
var ngx_toastr_1 = require("ngx-toastr");
// import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
var StatusbulkreferenceComponent = /** @class */ (function () {
    function StatusbulkreferenceComponent(formBuilder, referencingmoduleService, toastrService
    // private ngxCsvParser: NgxCsvParser
    ) {
        this.formBuilder = formBuilder;
        this.referencingmoduleService = referencingmoduleService;
        this.toastrService = toastrService;
    }
    StatusbulkreferenceComponent.prototype.ngOnInit = function () {
        this.generateRecord();
    };
    StatusbulkreferenceComponent.prototype.generateRecord = function () {
        var _this = this;
        this.csvRecords = [];
        this.referencingmoduleService.getquestiontemplates().subscribe(function (response) {
            _this.referencingmoduleService.getbulkreferencerequests().subscribe(function (resdata) {
                if (resdata.success) {
                    resdata.data.forEach(function (element, index) {
                        response.forEach(function (element2, index) {
                            if (element2.id == element.modulequestiontemplateid) {
                                element.questionprofile = element2.templatename;
                            }
                        });
                        _this.csvRecords.push(element);
                    });
                }
            });
        });
    };
    return StatusbulkreferenceComponent;
}());
exports.StatusbulkreferenceComponent = StatusbulkreferenceComponent;
