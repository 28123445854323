<div class="bg">
    <app-header></app-header>

    <div class="section-wrapper p-0">
        <div class="admin_dashboard_show">
            <div class="ceee">
                <div class="cardmenu">                
                    <a class="card_box_lit" [routerLink]="['/ourprofile']" *ngIf="permission.m91 > 0">
                        <div class="samebox">
                            <i class="lnr lnr-user"></i>
                            <div class="menu_content">
                                <h4>Profile</h4>
                                <!-- <span>lorem ipsum lorem ipsum</span> -->
                            </div>
                        </div>
                        
                        
                    </a>
                    <a class="card_box_lit" [routerLink]="['/listjob']" *ngIf="permission.m7 > 0">
                        <div class="samebox">
                            <i class="lnr lnr-briefcase"></i>
                            <div class="menu_content">
                                <h4>Job</h4>
                                <!-- <span>lorem ipsum lorem ipsum</span> -->
                            </div>
                        </div>
                    </a>
                    <a class="card_box_lit"  [routerLink]="['/employeelist']"  *ngIf="permission.m4 > 0">
                        <div class="samebox">
                            <i class="lnr lnr-users"></i>
                            <div class="menu_content">
                                <h4>Employee</h4>
                                <!-- <span>lorem ipsum lorem ipsum</span> -->
                            </div>
                        </div>
                       
                        
                    </a>
                    <a class="card_box_lit" [routerLink]="['/clientlist']" *ngIf="permission.m3 > 0">
                        <div class="samebox">
                            <i class="lnr lnr-menu"></i>
                            <div class="menu_content">
                                <h4>Client</h4>
                                <!-- <span>lorem ipsum lorem ipsum</span> -->
                            </div>
                        </div>
                        
                    </a>
                    <a class="card_box_lit" [routerLink]="['/projectlist']" *ngIf="permission.m5 > 0">
                        <div class="samebox">
                            <i class="lnr lnr-apartment"></i>
                            <div class="menu_content">
                                <h4>Project</h4>
                                <!-- <span>lorem ipsum lorem ipsum</span> -->
                            </div>
                        </div>
                    </a>
                    <a class="card_box_lit" [routerLink]="['/asset-list']"  *ngIf="permission.m16 > 0">
                        <div class="samebox">
                            <i class="lnr lnr-frame-expand"></i>
                        <div class="menu_content">
                            <h4>Asset</h4>
                            <!-- <span>lorem ipsum lorem ipsum</span> -->
                        </div>
                        </div>
                    </a>
                    <a class="card_box_lit" [routerLink]="['/employee-rota-list']">
                        <div class="samebox">
                            <i class="lnr lnr-laptop-phone"></i>
                            <div class="menu_content">
                                <h4>Rota System</h4>
                            
                            </div>
                        </div>
                        
                    </a>
                    <a class="card_box_lit" [routerLink]="['/check-dashboard']">
                        <div class="samebox">
                            <i class="lnr lnr-license"></i>
                        <div class="menu_content">
                            <h4>Check</h4>
                            <!-- <span>lorem ipsum lorem ipsum</span> -->
                        </div>
                        </div>
                        
                        
                    </a>
                    <a  class="card_box_lit" [routerLink]="['/employee-documentlist']"  *ngIf="permission.m8 > 0">
                        <div class="samebox">
                            <i class="lnr lnr-file-add"></i>
                            <div class="menu_content">
                                <h4>Document</h4>
                                <!-- <span>lorem ipsum lorem ipsum</span> -->
                            </div>
                        </div>
                        
                    </a>
                    <a class="card_box_lit" [routerLink]="['/our-location']" *ngIf="permission.m10 > 0">
                        <div class="samebox">
                            <i class="lnr lnr-location"></i>
                            <div class="menu_content">
                                <h4>Location</h4>
                                <!-- <span>lorem ipsum lorem ipsum</span> -->
                            </div>
                        </div>
                    </a>
                    <a class="card_box_lit" [routerLink]="['/request/request-list']"  *ngIf="permission.m15 > 0">
                        <div class="samebox">
                            <i class="lnr lnr-thumbs-up"></i>
                            <div class="menu_content">
                                <h4>Request</h4>
                                <!-- <span>lorem ipsum lorem ipsum</span> -->
                            </div>
                        </div>
                    </a>
                    <a class="card_box_lit" [routerLink]="['/our-setting']"  *ngIf="permission.m81 > 0">
                        <div class="samebox">
                            <i class="lnr lnr-cog"></i>
                            <div class="menu_content">
                                <h4>Setting</h4>
                                <!-- <span>lorem ipsum lorem ipsum</span> -->
                            </div>
                        </div>
                       
                        
                    </a>
                    <a class="card_box_lit" [routerLink]="['/report-dashboard']"  *ngIf="permission.m11 > 0">
                        <div class="samebox">
                            <i class="lnr lnr-pie-chart"></i>
                            <div class="menu_content">
                                <h4>Reports</h4>
                                <!-- <span>lorem ipsum lorem ipsum</span> -->
                            </div>
                        </div>
                    </a>
                    <a class="card_box_lit" [routerLink]="['/admin-list']"  *ngIf="permission.m2 > 0">
                        <div class="samebox">
                            <i class="lnr lnr-power-switch"></i>
                            <div class="menu_content">
                                <h4>Admin</h4>
                                <!-- <span>lorem ipsum lorem ipsum</span> -->
                            </div>
                        </div>
                       
                        
                    </a>
                    <a class="card_box_lit" [routerLink]="['/notification']">
                        <div class="samebox">
                            <i class="lnr lnr-alarm"></i>
                        <div class="menu_content">
                            <h4>Notifications</h4>
                            <!-- <span>lorem ipsum lorem ipsum</span> -->
                        </div>
                        </div>
                        
                        
                    </a>
                    <a [routerLink]="['/my-billing']" class="card_box_lit" *ngIf="permission.m86 > 0">
                        <div class="samebox">
                            <i class="lnr lnr-printer"></i>
                            <div class="menu_content">
                                <h4>My Billing</h4>
                                <!-- <span>lorem ipsum lorem ipsum</span> -->
                            </div>
                        </div>
                       
                        
                    </a>
                   
                  
                    <a class="card_box_lit" (click)="openModalMastersystem('Em_support')">
                        <div class="samebox">
                        <i class="lnr lnr-laptop-phone"></i>
                        <div class="menu_content">
                            <h4>Support</h4>
                          
                        </div>
                    </div>
                        
                    </a>
                  
                
                </div>
            </div>
           
        </div>
    </div>
    <jw-modal id="Em_support" class="modal Em_support" role="dialog">

        <form [formGroup]="visapopForm" #visaForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(visaForm.value,'Em_support')">
 
        <div role="document" class="formgroup" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                    Support
                        </h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('Em_support');">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" >
                    <div class="container">
                       
                            <!-- <input type="hidden" formControlName="id" class="form-control" name="id" id="id" [(ngModel)]="leave.employeeholidayapplyid">
                            <input type="hidden" formControlName="holidayremarkid" class="form-control" required name="holidayremarkid" id="holidayremarkid" [(ngModel)]="leave.holidayremarkid">
                            <input type="hidden" formControlName="employeeid" class="form-control" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="leave.employeeid"> -->
                            <div class="formgroup">
                                <div class="row row-sm mg-t-10">
                                    <div class="col-sm-6 mb-2 ">
                                        <label class="labelresize mb-0">Full Name*</label>
                                      
                                    <input type="text" formControlName="fname" name="fname" id="fname"  class="form-control"  [maxlength]="100" placeholder="Enter Full Name">
                                    <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                                        <div *ngIf="f.fname.errors.required">Please Enter Full Name</div>
                                        <div *ngIf="!f.fname.errors.required && f.fname.errors.minlength">Minimum 3 characters Required</div>
                                    </div>  
                                    </div>
                                    <div class="col-sm-6 mb-2">
                                        <label class="labelresize mb-0">Business Email*</label>
                                      
                                        <input type="text" formControlName="bussinessemail" name="bussinessemail" id="bussinessemail"  class="form-control"  [maxlength]="100" placeholder="Enter Business Email">
                                         <div *ngIf="submitted && f.bussinessemail.errors" class="invalid-feedback">
                                            <div *ngIf="f.bussinessemail.errors.required">Please Enter Business Email</div>
                                            <!-- <div *ngIf="!f.bussinessemail.errors.required && f.bussinessemail.errors.minlength">Minimum 3 characters Required</div> -->
                                        </div> 
                                    </div>
                                    <div class="col-sm-6 mb-2">
                                        <label class="labelresize mb-0">Phone Number*</label>
                                      
                                        <input type="text" formControlName="phonenumber" name="phonenumber" id="phonenumber"  class="form-control"  [maxlength]="100" placeholder="Enter Phone Number">
                                         <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                            <div *ngIf="f.phonenumber.errors.required">Please Enter Phone Number</div>
                                            <!-- <div *ngIf="!f.phonenumber.errors.required && f.phonenumber.errors.minlength">Minimum 3 characters Required</div> -->
                                        </div> 
                                    </div>
                                    <div class="col-sm-6 mb-2">
                                        <label class="mb-0 labelresize">Query Type*</label>
                                        <select class="form-control"   name="querytype" id="querytype"   formControlName="querytype">
                                        <option value="" selected >Select Query Type</option>
                                        <option value="Technical Support">Technical Support</option>
                                        <option value="Billing Support" >Billing Support</option>
                                        <option value="Application Support">Application Support</option>
                                        <option value="Others" >Others</option>

                                        <!-- <option *ngFor="let ltype of leavetypelist" [value]="ltype.id">{{ ltype.typename }}</option> -->
                                        </select>
                                         <div *ngIf="submitted && f.querytype.errors" class="invalid-feedback">
                                            <div *ngIf="f.querytype.errors.required">Please Select Query Type</div>
                                        </div> 
                                    </div>
                                    <div class="col-sm-12 mb-2">
                                        <label class="labelresize mb-0">Subject*</label>
                                      
                                        <input type="text" formControlName="subject" name="subject" id="subject" class="form-control"  [maxlength]="100" placeholder="Enter Subject">
                                         <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                            <div *ngIf="f.subject.errors.required">Please Enter Your Subject</div>
                                            <!-- <div *ngIf="!f.subject.errors.required && f.subject.errors.minlength">Minimum 3 characters Required</div> -->
                                        </div> 
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="mb-0 labelresize">Your Message*</label>
                                        <textarea rows="4" cols="50"  class="form-control" formControlName="message"  required name="message" id="message"  maxlength="500" placeholder="Enter remarks here"></textarea>
                                        <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                            <div *ngIf="f.message.errors.required">Please Enter Your Message</div>
                                        </div> 
                                    </div>


                                </div>
                            </div>
    
                       
                    </div>

                   

                </div>
                 <div class="modal-footer">
                     <div class="row">
                         <div class="col-lg-12">
                            <button  type="submit" class="btn btn-primary com_btn"> Submit</button>
                            <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal" (click)="closeModal('Em_support');">Close</button>
                         </div>
                     </div>
                  
                </div> 


            </div>
        </div>

    </form>
    </jw-modal> 