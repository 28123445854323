"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var adverse_1 = require("../../models/adverse");
var checkservice_service_1 = require("../../services/checkservice.service");
var router_1 = require("@angular/router");
var checksystem_service_1 = require("../../services/checksystem.service");
var modal_service_1 = require("../../_services/modal.service");
var alert_service_1 = require("../../services/alert.service");
var AdversecreditComponent = /** @class */ (function () {
    function AdversecreditComponent(modalService, router, alerts, formBuilder, checkService, check, activatedRoute) {
        this.modalService = modalService;
        this.router = router;
        this.alerts = alerts;
        this.formBuilder = formBuilder;
        this.checkService = checkService;
        this.check = check;
        this.activatedRoute = activatedRoute;
        this.shortAdd = false;
        this.applicantSearchAddresses = [];
        this.adFormVal = {};
        this.searchrecord = false;
        this.paymentsubmit = 0;
        this.convertStringToArray = function (object) {
            console.log('check object master', typeof object);
            var listMaster = [];
            if (typeof object === 'object') {
                listMaster.push(object);
                return listMaster;
            }
            else {
                console.log(typeof object);
                object.forEach(function (entry3) {
                    listMaster.push(entry3);
                });
                return listMaster;
            }
        };
        this.convertStringToArrayyes = function (object) {
            var listMaster = [];
            if (typeof object === 'object') {
                console.log(object);
                console.log('yes');
                return 'object';
            }
            else {
                console.log('no');
                return 'array';
            }
        };
    }
    AdversecreditComponent.prototype.ngOnInit = function () {
        var _this = this;
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        // this.permission = permission[0];
        this.downloadtext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
        this.error = false;
        this.disableBtn = true;
        this.wizard = 0;
        if (permission[0]['m139'] < 1) {
            this.router.navigate(['/permission-denied']);
        }
        this.submitted = false;
        this.adCredModel = new adverse_1.Adverse();
        this.adCredModel.addressoption = 'option2';
        this.shortAdd = true;
        this.adCredFormGroup = this.formBuilder.group({
            reference: [''],
            title: ['', forms_1.Validators.required],
            forename: ['', forms_1.Validators.required],
            middlename: [''],
            sirname: ['', forms_1.Validators.required],
            dob: ['', forms_1.Validators.required],
            housenumber: [''],
            housename: [''],
            postcode: ['', forms_1.Validators.required],
            addressoption: [''],
            autosearch: [''],
            hho: [''],
            flatnumber: [''],
            inlineRadioOptions: [''],
            street: [''],
            town: [''],
            locality: ['']
        });
        this.addressForm = this.formBuilder.group({
            addpicklist: ['']
        });
        var ts = { 'serviceid': 6 };
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            if (remaindata.remiancheck <= 0) {
                _this.lesssore = true;
            }
        });
        this.id = this.activatedRoute.snapshot.paramMap.get("id");
        console.log('adverst credit', this.id);
        if (this.id) {
            this.bindallcheckhistory(this.id);
        }
    };
    Object.defineProperty(AdversecreditComponent.prototype, "g", {
        get: function () {
            return this.addressForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdversecreditComponent.prototype, "f", {
        get: function () {
            return this.adCredFormGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    AdversecreditComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    AdversecreditComponent.prototype.clearAdverseCreditData = function () {
        this.adCredFormGroup.reset({
            reference: '',
            title: '',
            forename: '',
            middlename: '',
            sirname: '',
            dob: '',
            housenumber: '',
            housename: '',
            postcode: '',
            addressoption: 'option2',
            autosearch: '',
            hho: '',
            flatnumber: '',
            inlineRadioOptions: '',
            street: '',
            town: '',
            locality: ''
        });
        this.submitted = false;
    };
    AdversecreditComponent.prototype.onSubmit = function (adverseCredit, type) {
        var _this = this;
        this.paymentsubmit = 0;
        this.error = false;
        this.submitted = true;
        this.adFormVal = adverseCredit;
        this.submitted = true;
        if (this.adCredFormGroup.invalid) {
            return;
        }
        if (type == 'first') {
            this.disableBtn = true;
            var ts = { 'serviceid': 6 };
            this.check.getbillinginformation(ts).subscribe(function (remaindata) {
                if (remaindata.remiancheck <= 0) {
                    _this.lesssore = true;
                    _this.modalService.open('upgrade-plan');
                }
                else {
                    _this.disableBtn = true;
                    _this.lesssore = false;
                    _this.modalService.open('pdf-payment-confirmation');
                }
            });
        }
        else if (type == 'payment') {
            if (this.paymentsubmit == 0) {
                this.paymentsubmit = 1;
                this.disableBtn = false;
                this.searchrecord = true;
                this.modalService.close('pdf-payment-confirmation');
                setTimeout(function () {
                    var sd = new Date(adverseCredit.dob);
                    adverseCredit.dob = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
                    _this.checkService.addUpdateAdverseCredit(adverseCredit).subscribe(function (data) {
                        if (data.success) {
                            _this.searchrecord = false;
                            if ('historyid' in data) {
                                _this.historyid = data.historyid;
                            }
                            var return_response = data.resp["soap:Envelope"]["soap:Body"];
                            if ('Search07aResponse' in return_response) {
                                _this.responseData = return_response["Search07aResponse"]["SearchResult"];
                                _this.errormsg = '';
                                if (_this.responseData.creditrequest.applicant.name) {
                                    _this.applicantDetails = _this.responseData.creditrequest.applicant.name;
                                }
                                if (_this.responseData.creditrequest.applicant.address) {
                                    _this.applicantAddressDetails = _this.responseData.creditrequest.applicant.address;
                                }
                                if (_this.responseData.picklist.applicant.address.fullmatches.fullmatch) {
                                    _this.applicantSearchAddresses = _this.responseData.picklist.applicant.address.fullmatches.fullmatch;
                                    if (_this.applicantSearchAddresses.length) {
                                        _this.wizard = 1;
                                        _this.disableBtn = true;
                                    }
                                    else {
                                        _this.wizard = 2;
                                        _this.reportData = _this.responseData.creditreport;
                                        if (_this.reportData.applicant.addresslinks) {
                                            if (_this.reportData.applicant.addresslinks.links) {
                                                _this.links = _this.reportData.applicant.addresslinks.links.link;
                                            }
                                        }
                                        if (_this.responseData.creditreport.applicant.summary)
                                            _this.addressconfirm = _this.responseData.creditreport.applicant.summary.address;
                                        // this.links =  this.reportData.applicant.addresslinks.links.link;
                                        if (_this.reportData.applicant.bais)
                                            _this.IBA = _this.reportData.applicant.bais.bai;
                                        if (_this.reportData.applicant.judgments) {
                                            if (_this.reportData.applicant.judgments.judgment) {
                                                _this.judgment = _this.reportData.applicant.judgments.judgment;
                                            }
                                        }
                                        if (_this.reportData.applicant.nocs)
                                            _this.nocs = _this.reportData.applicant.nocs.noc;
                                        _this.picklist = _this.responseData.picklist;
                                        _this.creditrequest = _this.responseData.creditrequest;
                                        _this.jobdetails = _this.responseData.jobdetails;
                                        _this.paymentsubmit = 0;
                                        _this.disableBtn = true;
                                    }
                                }
                                else {
                                    _this.paymentsubmit = 1;
                                    _this.wizard = 3;
                                    _this.responseData = return_response["Search07aResponse"]["SearchResult"];
                                    _this.picklist = _this.responseData.picklist;
                                    _this.creditrequest = _this.responseData.creditrequest;
                                    _this.jobdetails = _this.responseData.jobdetails;
                                    _this.errormsg = 'No record found for this search';
                                    _this.alerts.error("No record found for this search.", true);
                                    _this.disableBtn = true;
                                    _this.modalService.close('pdf-payment-confirmation');
                                }
                            }
                            else if ('soap:Fault' in return_response) {
                                _this.paymentsubmit = 1;
                                _this.wizard = 3;
                                _this.responseData = return_response["Search07aResponse"]["SearchResult"];
                                _this.picklist = _this.responseData.picklist;
                                _this.creditrequest = _this.responseData.creditrequest;
                                _this.jobdetails = _this.responseData.jobdetails;
                                _this.errormsg = 'No record found for this search';
                                _this.disableBtn = true;
                                _this.alerts.error("No record found for this search.", true);
                                _this.modalService.close('pdf-payment-confirmation');
                            }
                        }
                        else {
                            _this.paymentsubmit = 1;
                            _this.wizard = 3;
                            _this.responseData = return_response["Search07aResponse"]["SearchResult"];
                            _this.picklist = _this.responseData.picklist;
                            _this.creditrequest = _this.responseData.creditrequest;
                            _this.disableBtn = true;
                            _this.jobdetails = _this.responseData.jobdetails;
                            _this.errormsg = 'We have some issue in Adverse Credit check. Please contact Administrator';
                            _this.alerts.error("No record found for this search.", true);
                            // this.modalService.close('pdf-payment-confirmation');
                        }
                    });
                }, 9000);
            }
        }
    };
    AdversecreditComponent.prototype.bindallcheckhistory = function (id) {
        var _this = this;
        var dd = { id: id };
        this.historyid = id;
        console.log('update code', id);
        this.checkService.checkhistory(dd).subscribe(function (data) {
            console.log('script', data);
            if (data.success) {
                _this.searchFields = data.search;
                if ('historyid' in data) {
                    _this.historyid = data.historyid;
                }
                var return_response = data.resp["soap:Envelope"]["soap:Body"];
                if ('Search07aResponse' in return_response) {
                    _this.responseData = return_response["Search07aResponse"]["SearchResult"];
                    if (_this.responseData.creditrequest.applicant.name) {
                        _this.applicantDetails = _this.responseData.creditrequest.applicant.name;
                    }
                    if (_this.responseData.creditrequest.applicant.address) {
                        _this.applicantAddressDetails = _this.responseData.creditrequest.applicant.address;
                    }
                    console.log('applicant name', _this.responseData.picklist.applicant.address.fullmatches.fullmatch);
                    if (_this.responseData.picklist.applicant.address.fullmatches.fullmatch) {
                        _this.applicantSearchAddresses = _this.responseData.picklist.applicant.address.fullmatches.fullmatch;
                        if (_this.applicantSearchAddresses.length) {
                            _this.wizard = 1;
                        }
                        else {
                            console.log('Response Data', _this.responseData);
                            _this.reportData = _this.responseData.creditreport;
                            if (_this.reportData) {
                                if (_this.reportData.applicant.addresslinks) {
                                    if (_this.reportData.applicant.addresslinks.links) {
                                        _this.links = _this.reportData.applicant.addresslinks.links.link;
                                    }
                                }
                                if (_this.responseData.creditreport.applicant.summary)
                                    _this.addressconfirm = _this.responseData.creditreport.applicant.summary.address;
                                // this.links =  this.reportData.applicant.addresslinks.links.link;
                                if (_this.reportData.applicant.bais)
                                    _this.IBA = _this.reportData.applicant.bais.bai;
                                if (_this.reportData.applicant.judgments) {
                                    if (_this.reportData.applicant.judgments.judgment) {
                                        _this.judgment = _this.reportData.applicant.judgments.judgment;
                                    }
                                }
                                if (_this.reportData.applicant.nocs)
                                    _this.nocs = _this.reportData.applicant.nocs.noc;
                                _this.picklist = _this.responseData.picklist;
                                _this.creditrequest = _this.responseData.creditrequest;
                                _this.jobdetails = _this.responseData.jobdetails;
                                _this.wizard = 2;
                            }
                        }
                    }
                    else {
                        _this.wizard = 3;
                        _this.responseData = return_response["Search07aResponse"]["SearchResult"];
                        _this.picklist = _this.responseData.picklist;
                        _this.creditrequest = _this.responseData.creditrequest;
                        _this.jobdetails = _this.responseData.jobdetails;
                        _this.alerts.error("No record found for this search 2.", true);
                        _this.modalService.close('pdf-payment-confirmation');
                    }
                }
                else if ('soap:Fault' in return_response) {
                    _this.wizard = 3;
                    _this.responseData = return_response["Search07aResponse"]["SearchResult"];
                    _this.picklist = _this.responseData.picklist;
                    _this.creditrequest = _this.responseData.creditrequest;
                    _this.jobdetails = _this.responseData.jobdetails;
                    _this.alerts.error("No record found for this search 3.", true);
                    _this.modalService.close('pdf-payment-confirmation');
                }
            }
            else {
                _this.wizard = 3;
                _this.responseData = return_response["Search07aResponse"]["SearchResult"];
                _this.picklist = _this.responseData.picklist;
                _this.creditrequest = _this.responseData.creditrequest;
                _this.jobdetails = _this.responseData.jobdetails;
                _this.alerts.error("No record found for this search. 4", true);
                _this.modalService.close('pdf-payment-confirmation');
            }
        });
    };
    AdversecreditComponent.prototype.checkValues = function (values) {
        return values.some(function (v) { return v[1].cuesData && v[1].cuesData.length; }); //if any array has cuesData, some will return true
    };
    AdversecreditComponent.prototype.downloadAdverseCreditHistory = function (historyid, ind) {
        var _this = this;
        this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        this.checkService.adversecredithistorydownload({ "id": historyid }).subscribe(function (searchRes) {
            _this.downloadtext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            window.open(searchRes.response_data.Location, "_blank");
        });
    };
    AdversecreditComponent.prototype.backwizard = function (id) {
        if (id == 0) {
            this.submitted = false;
            this.adCredFormGroup.reset({
                reference: '',
                title: '',
                forename: '',
                middlename: '',
                sirname: '',
                dob: '',
                housenumber: '',
                housename: '',
                postcode: '',
                addressoption: '',
                autosearch: '',
                hho: '',
                flatnumber: '',
                inlineRadioOptions: '',
                street: '',
                town: '',
                locality: ''
            });
        }
        this.wizard = id;
        this.errormsg = '';
        this.paymentsubmit = 0;
        this.disableBtn = true;
    };
    AdversecreditComponent.prototype.changeAddress = function (value) {
        if (value == 'option1') {
            this.shortAdd = false;
        }
        else if (value == 'option2') {
            this.shortAdd = true;
        }
    };
    AdversecreditComponent.prototype.addresschangesubmit = function (addressSubmit) {
        var _this = this;
        console.log(addressSubmit);
        this.adFormVal.id = this.historyid;
        this.adFormVal.reference = this.adFormVal.reference;
        this.adFormVal.title = this.adFormVal.title;
        this.adFormVal.forename = this.adFormVal.forename;
        this.adFormVal.middlename = this.adFormVal.middlename;
        this.adFormVal.sirname = this.adFormVal.sirname;
        this.adFormVal.dob = this.adFormVal.dob;
        this.adFormVal.addressoption = this.adFormVal.addressoption;
        this.adFormVal.autosearch = this.adFormVal.autosearch;
        console.log('Update System Master', this.applicantSearchAddresses[addressSubmit.addpicklist]);
        if (this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.abodeno)
            this.adFormVal.flatnumber = this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.abodeno._text;
        if (this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.buildingno)
            this.adFormVal.buildingno = this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.buildingno._text;
        if (this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.buildingname)
            this.adFormVal.buildingname = this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.buildingname._text;
        if (this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.locality)
            this.adFormVal.locality = this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.locality._text;
        if (this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.street1)
            this.adFormVal.street1 = this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.street1._text;
        if (this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.posttown)
            this.adFormVal.posttown = this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.posttown._text;
        if (this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.postcode)
            this.adFormVal.postcode = this.applicantSearchAddresses[addressSubmit.addpicklist].addressmatch.postcode._text;
        this.checkService.addUpdateAdverseCredit(this.adFormVal).subscribe(function (data) {
            _this.applicantSearchAddresses = null;
            if (data.success) {
                _this.searchFields = data.search;
                if ('historyid' in data) {
                    _this.historyid = data.historyid;
                }
                var return_response = data.resp["soap:Envelope"]["soap:Body"];
                if ('Search07aResponse' in return_response) {
                    _this.responseData = return_response["Search07aResponse"]["SearchResult"];
                    if (_this.responseData.creditrequest.applicant.name) {
                        _this.applicantDetails = _this.responseData.creditrequest.applicant.name;
                    }
                    if (_this.responseData.creditrequest.applicant.address) {
                        _this.applicantAddressDetails = _this.responseData.creditrequest.applicant.address;
                    }
                    console.log('login master', _this.responseData.picklist.applicant.address.fullmatches.fullmatch);
                    if (_this.responseData.picklist.applicant.address.fullmatches.fullmatch) {
                        _this.applicantSearchAddresses = _this.responseData.picklist.applicant.address.fullmatches.fullmatch;
                        if (_this.applicantSearchAddresses.length) {
                            _this.wizard = 1;
                        }
                        else {
                            _this.reportData = _this.responseData.creditreport;
                            if (_this.reportData.applicant.addresslinks) {
                                if (_this.reportData.applicant.addresslinks.links) {
                                    _this.links = _this.reportData.applicant.addresslinks.links.link;
                                }
                            }
                            if (_this.responseData.creditreport.applicant.summary)
                                _this.addressconfirm = _this.responseData.creditreport.applicant.summary.address;
                            // this.links =  this.reportData.applicant.addresslinks.links.link;
                            if (_this.reportData.applicant.bais)
                                _this.IBA = _this.reportData.applicant.bais.bai;
                            if (_this.reportData.applicant.judgments) {
                                if (_this.reportData.applicant.judgments.judgment) {
                                    _this.judgment = _this.reportData.applicant.judgments.judgment;
                                }
                            }
                            if (_this.reportData.applicant.nocs)
                                _this.nocs = _this.reportData.applicant.nocs.noc;
                            _this.picklist = _this.responseData.picklist;
                            _this.creditrequest = _this.responseData.creditrequest;
                            _this.jobdetails = _this.responseData.jobdetails;
                            _this.wizard = 2;
                        }
                    }
                    else {
                        _this.wizard = 3;
                        _this.responseData = return_response["Search07aResponse"]["SearchResult"];
                        _this.picklist = _this.responseData.picklist;
                        _this.creditrequest = _this.responseData.creditrequest;
                        _this.jobdetails = _this.responseData.jobdetails;
                        _this.alerts.error("No record found for this search.", true);
                        _this.modalService.close('pdf-payment-confirmation');
                    }
                }
                else if ('soap:Fault' in return_response) {
                    _this.wizard = 3;
                    _this.responseData = return_response["Search07aResponse"]["SearchResult"];
                    _this.picklist = _this.responseData.picklist;
                    _this.creditrequest = _this.responseData.creditrequest;
                    _this.jobdetails = _this.responseData.jobdetails;
                    _this.alerts.error("No record found for this search.", true);
                    _this.modalService.close('pdf-payment-confirmation');
                }
            }
            else {
                _this.wizard = 3;
                _this.responseData = return_response["Search07aResponse"]["SearchResult"];
                _this.picklist = _this.responseData.picklist;
                _this.creditrequest = _this.responseData.creditrequest;
                _this.jobdetails = _this.responseData.jobdetails;
                _this.alerts.error("No record found for this search.", true);
                _this.modalService.close('pdf-payment-confirmation');
            }
            // if(data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"])
            // {
            //   this.responseData = data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"];
            //   if(this.responseData.creditrequest.applicant.name)
            //   {
            //     this.applicantDetails = this.responseData.creditrequest.applicant.name;
            //   }
            //   if(this.responseData.creditrequest.applicant.address)
            //   {
            //     this.applicantAddressDetails = this.responseData.creditrequest.applicant.address;
            //   }
            //   if(this.responseData.picklist.applicant.address.fullmatches.fullmatch)
            //   {
            //     this.applicantSearchAddresses = this.responseData.picklist.applicant.address.fullmatches.fullmatch;
            //     if(this.applicantSearchAddresses.length)
            //     {          
            //       this.wizard =1          
            //     }else
            //     {
            //       this.reportData = data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"].creditreport;
            //       // this.searchingFields = data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"].search;
            //       this.picklist = data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"].picklist;
            //       this.creditrequest = data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"].creditrequest;
            //       this.jobdetails = data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"].jobdetails;
            //       console.log(this.reportData);
            //       this.wizard =2
            //     }        
            //   }      
            // }      
            // console.log(data["soap:Envelope"]["soap:Body"]["Search07aResponse"]["SearchResult"]);
        });
    };
    return AdversecreditComponent;
}());
exports.AdversecreditComponent = AdversecreditComponent;
