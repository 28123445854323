"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// export class Jobs {
//     id: number;
//     employeehourapplyid: number;
//     projectid: number;
//     title: number;
//     employeeid: number;
//     expensetypeid: number;
//     amountpaid: number;
//     paidby: number;
//     start: Date;
//     end: string;
//     imagename: string;
//     expenseremark: string;
//     locationname: string;
//     expenseremarksid: number;
//   }
var Jobs = /** @class */ (function () {
    function Jobs() {
        this.socid = "";
        this.addposition = "";
        this.stypeid = "";
        this.jobdescreption = "";
    }
    return Jobs;
}());
exports.Jobs = Jobs;
var Screen = /** @class */ (function () {
    function Screen() {
    }
    return Screen;
}());
exports.Screen = Screen;
