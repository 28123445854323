"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../_services/modal.service");
var common_service_1 = require("../../services/common.service");
var project_service_1 = require("../../services/project.service");
var client_service_1 = require("../../services/client.service");
var forms_1 = require("@angular/forms");
var project_model_1 = require("./../../models/project/project.model");
var router_1 = require("@angular/router");
var alert_service_1 = require("../../services/alert.service");
var ProjectComponent = /** @class */ (function () {
    function ProjectComponent(commonService, modalService, formBuilder, project, router, alerts, client) {
        this.commonService = commonService;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.project = project;
        this.router = router;
        this.alerts = alerts;
        this.client = client;
        this.options = [];
        this.submitted = false;
        this.emptyValue = "";
    }
    ProjectComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.Userid = localStorage.getItem('userid');
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.projectperm = this.permission.m5;
        this.projectDetail = new project_model_1.Project();
        this.projectFormGroup = this.formBuilder.group({
            id: [''],
            projectid: [''],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            alteremailid: [''],
            mobilenumber: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            phonenumber: [''],
            clientid: ['', forms_1.Validators.required],
            contactperson: ['', forms_1.Validators.required],
            contactrole: [''],
            projectname: ['', forms_1.Validators.required],
            weburl: ['']
        });
        var sstatustype = { 'status': false, 'id': this.Userid };
        this.project.getprojectlist(sstatustype).subscribe(function (data) {
            _this.record = data.length;
            _this.data = data;
            _this.record = _this.data.length;
            _this.pageOfItems = _this.data;
            if (_this.record < 3) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
        var sstatustype2 = { 'status': true, 'id': this.Userid };
        this.client.getclientlist(sstatustype2).subscribe(function (clientdata) {
            _this.clientdata = clientdata;
        });
    };
    ProjectComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    Object.defineProperty(ProjectComponent.prototype, "f", {
        get: function () { return this.projectFormGroup.controls; },
        enumerable: true,
        configurable: true
    });
    ProjectComponent.prototype.onSubmit = function (formpro, id) {
        var _this = this;
        this.submitted = true;
        if (this.projectFormGroup.invalid) {
            return;
        }
        this.disableBtn = false;
        formpro.userid = this.Userid;
        this.project.projectdetailsUpdate(formpro).subscribe(function (response) {
            var sstatustype = { 'status': true, 'id': _this.Userid };
            _this.project.getprojectlist(sstatustype).subscribe(function (data) {
                _this.record = data.length;
                _this.data = data;
                _this.record = _this.data.length;
                if (_this.record < 30) {
                    _this.pageOfItems = _this.data;
                }
                else {
                    _this.items = _this.data;
                }
                _this.alerts.success('Project updated successfully', true);
                _this.modalService.close(id);
            });
        });
    };
    ProjectComponent.prototype.onChangeStatus = function (evt, project) {
        this.project.projectdetailsUpdatestatusbyadmin({ 'userid': this.Userid, 'id': project.projectid, 'pstatus': evt.target.checked }).subscribe(function (data) {
        });
    };
    ProjectComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    ProjectComponent.prototype.deleteProject = function (projectid) {
        var _this = this;
        if (confirm("Are you sure you want to delete?")) {
            var deletedata = { "id": projectid };
            this.project.deleteProject(deletedata).subscribe(function (data) {
                var sstatustype = { 'status': false, 'id': _this.Userid };
                _this.project.getprojectlist(sstatustype).subscribe(function (data) {
                    _this.data = data;
                });
            });
        }
    };
    ProjectComponent.prototype.openModal = function (id, data) {
        this.submitted = false;
        this.buttoninsert = "Update";
        this.disableBtn = true;
        data.id = data.projectid;
        this.projectFormGroup.reset(data);
        if (data.weburl) {
            this.weburl = data.weburl.replace('https://', '').replace('http://', '');
        }
        this.modalService.open(id);
    };
    ProjectComponent.prototype.openModal_details = function (id, data) {
        this.projectDetail = data;
        this.buttoninsert = "Update";
        this.disableBtn = true;
        this.submitted = true;
        this.modalService.open(id);
    };
    ProjectComponent.prototype.openModal_project = function (id) {
        this.projectDetail = new project_model_1.Project();
        this.submitted = false;
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.mobilecheck = '';
        this.phonecheck = '';
        this.projectDetail.id = "0";
        this.projectDetail.mobilenumber = "+44";
        this.projectFormGroup.reset(this.projectDetail);
        this.modalService.open(id);
    };
    ProjectComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return ProjectComponent;
}());
exports.ProjectComponent = ProjectComponent;
