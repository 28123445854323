"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Profile = /** @class */ (function () {
    function Profile() {
    }
    return Profile;
}());
exports.Profile = Profile;
var Termination = /** @class */ (function () {
    function Termination() {
    }
    return Termination;
}());
exports.Termination = Termination;
var righttowork = /** @class */ (function () {
    function righttowork() {
        this.starttime = "";
        this.endtime = "";
    }
    return righttowork;
}());
exports.righttowork = righttowork;
