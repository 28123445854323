<mat-tab-group>       

  <mat-tab label="Profit & Loss">
    <ng-template matTabContent>
      <div class="summary_sec">
       <div class="row">
           <div class="col-sm-12">
               <div class="keyinfo noheight">
                   <div class="row">
                       <div class="col-sm-12">
                           <h4>Profit & Loss</h4>
                       </div>
                       <div class="col-sm-12 upgr_flex ">
                           <div class="block_tables_head_financials">
                               <div class="first">
                                   <h4 class="hiddenblank">Blank</h4>
                               </div>
                               <div class="second">
                                   <p>Weeks</p>
                               </div>
                               <div class="second">
                                   <p>Currency</p>
                               </div>
                               <div class="second">
                                <p>Consolidated A/Cs</p>
                                </div>
                               <div class="second">
                                   <p><b>Turnover</b></p>
                               </div>
                               <div class="second">
                                   <p>Export</p>
                               </div>
                               <div class="second">
                                   <p>Cost of Sales</p>
                               </div>
                               <div class="second">
                                   <p>Gross Profit</p>
                               </div>
                               <div class="second">
                                   <p>Wages & Salaries</p>
                               </div>
                               <div class="second">
                                   <p>Directors & Emoluments</p>
                               </div>
                               
                               <div class="second">
                                   <p><b>Operating Profit</b></p>
                               </div>
                               <div class="second">
                                   <p>Depreciation</p>
                               </div>
                               <!-- <div class="second">
                                   <p>Depreciation</p>
                               </div> -->
                               <div class="second">
                                   <p>Audit Fee</p>
                               </div>
                               <div class="second">
                                   <p>Interest Expenses</p>
                               </div>
                               <div class="second">
                                   <p><b>Pre Tax Profit</b></p>
                               </div>
                               <div class="second">
                                   <p>Taxation</p>
                               </div>
                           </div>
                           <div class="block_tables_financials colgrid-{{company.summary.company_information.local_csuk_financials.length}}" *ngFor="let financial of company.summary.company_information.local_csuk_financials">
                               <div class="first">
                                   <h4>{{financial.yearEndDate | date: 'MM/dd/yyyy'}}</h4>
                               </div>
                               <div class="second">
                                   <p>{{financial.numberOfWeeks}}</p>
                               </div>
                               <div class="second">
                                   <p>{{financial.currency}}</p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.consolidatedAccounts)?'Yes':'No'}}</p>
                               </div>
                               <!-- <div class="second">
                                   <p>- -</p>
                               </div> -->
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.profitAndLoss.directorsRemuneration)?((financial.profitAndLoss.directorsRemuneration<0)?'-£'+financial.profitAndLoss.directorsRemuneration*-1:'£'+financial.profitAndLoss.directorsRemuneration):'- -'}}</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                    <p class="flex">{{(financial.profitAndLoss.depreciation<0)?'-£'+financial.profitAndLoss.depreciation*-1:'£'+financial.profitAndLoss.depreciation}}
                                        <span class="{{(financial.positive)?'red':'green'}}" *ngIf="(financial.perc_fluctuation)">
                                           {{financial.perc_fluctuation | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positive)?'down':'up'}}"></i>
                                        </span>
                                    </p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                           </div>
                           <!-- <div class="block_tables_financials">
                               <div class="first">
                                   <h4>31/3/2017</h4>
                               </div>
                               <div class="second">
                                   <p>GBP</p>
                               </div>
                               <div class="second">
                                   <p>No</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>£298</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                           </div> -->
                       </div>        
                   </div>
               </div>
           </div>
       </div>
      </div>
   </ng-template>
  </mat-tab>

  <mat-tab label="Balance Sheet">
    <ng-template matTabContent>
      <div class="summary_sec">
       <div class="row">
           <div class="col-sm-12">
               <div class="keyinfo noheight">
                   <div class="row">
                       <div class="col-sm-12">
                           <h4>Balance Sheet</h4>
                       </div>
                       <div class="col-sm-12 upgr_flex">
                           <div class="block_tables_head_financials">
                               <div class="first">
                                <h4 class="hiddenblank">Blank</h4>
                               </div>
                               <div class="second">
                                   <p>Tangible Assets</p>
                               </div>
                               <div class="second">
                                   <p>Intangible Assets</p>
                               </div>
                               <div class="second">
                                   <p><b>Total Fixed Assets</b></p>
                               </div>
                               <div class="second">
                                   <p>Stock</p>
                               </div>
                               <div class="second">
                                   <p>Trade Debtors</p>
                               </div>
                               <div class="second">
                                   <p>Other Debtors</p>
                               </div>
                               <div class="second">
                                   <p>Cash</p>
                               </div>
                               <div class="second">
                                   <p>Miscellaneous Current Assets</p>
                               </div>
                               
                               <div class="second">
                                   <p><b>Total Current Assets</b></p>
                               </div>
                               <div class="second">
                                   <p>Trade Creditors</p>
                               </div>
                               <div class="second">
                                   <p>Bank Loans & Overdrafts</p>
                               </div>
                               <div class="second">
                                   <p>Other Short Term Finance</p>
                               </div>
                               <div class="second">
                                   <p>Miscellaneous Current Liabilities</p>
                               </div>
                               <div class="second">
                                   <p>Total Current Liabilities</p>
                               </div>
                               <div class="second">
                                   <p>Bank Loans & Overdrafts LTL</p>
                               </div>
                               <div class="second">
                                <p>Other Long Term Finance</p>
                            </div>
                            <div class="second">
                                <p><b>Total Long Term Liabilities</b></p>
                            </div>
                           </div>
                           <div class="block_tables_financials colgrid-{{company.summary.company_information.local_csuk_financials.length}}" *ngFor="let financial of company.summary.company_information.local_csuk_financials; let i=index">
                               <div class="first">
                                   <h4>{{financial.yearEndDate | date: 'MM/dd/yyyy'}}</h4>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.tangibleAssets<0)?'-£'+financial.balanceSheet.tangibleAssets*-1:'£'+financial.balanceSheet.tangibleAssets}}
                                    <span class="{{(financial.positivetangAsset)?'red':'green'}}" *ngIf="(financial.perc_tangibleAssets)">
                                        {{financial.perc_tangibleAssets | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivetangAsset)?'down':'up'}}"></i>
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.intangibleAssets<0)?'-£'+financial.balanceSheet.intangibleAssets*-1:'£'+financial.balanceSheet.intangibleAssets}}
                                    <span class="{{(financial.positiveintangAsset)?'red':'green'}}" *ngIf="(financial.perc_intangibleAssets)">
                                        {{financial.perc_intangibleAssets | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positiveintangAsset)?'down':'up'}}"></i>
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.totalFixedAssets<0)?'-£'+financial.balanceSheet.totalFixedAssets*-1:'£'+financial.balanceSheet.totalFixedAssets}}
                                    <span class="{{(financial.positivetotalFixAsset)?'red':'green'}}" *ngIf="(financial.perc_totalFixAssets)">
                                        {{financial.perc_totalFixAssets | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivetotalFixAsset)?'down':'up'}}"></i>
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.stock<0)?'-£'+financial.balanceSheet.stock*-1:'£'+financial.balanceSheet.stock}}
                                    <span class="{{(financial.positivestock)?'red':'green'}}" *ngIf="(financial.perc_stock)">
                                        {{financial.perc_stock | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivestock)?'down':'up'}}"></i>
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.tradeDebtors<0)?'-£'+financial.balanceSheet.tradeDebtors*-1:'£'+financial.balanceSheet.tradeDebtors}}
                                    <span class="{{(financial.positivetradedebt)?'red':'green'}}" *ngIf="(financial.perc_tradedebt)">
                                        {{financial.perc_tradedebt | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivetradedebt)?'down':'up'}}"></i>
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.otherDebtors<0)?'-£'+financial.balanceSheet.otherDebtors*-1:'£'+financial.balanceSheet.otherDebtors}}
                                    <span class="{{(financial.positiveotherdebt)?'red':'green'}}" *ngIf="(financial.perc_otherdebt)">
                                        {{financial.perc_otherdebt | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positiveotherdebt)?'down':'up'}}"></i>
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.cash<0)?'-£'+financial.balanceSheet.cash*-1:'£'+financial.balanceSheet.cash}}
                                    <span class="{{(financial.positivecash)?'red':'green'}}" *ngIf="(financial.perc_cash)">
                                        {{financial.perc_cash | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivecash)?'down':'up'}}"></i>
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.miscCurrentAssets)?((financial.balanceSheet.miscCurrentAssets<0)?'-£'+financial.balanceSheet.miscCurrentAssets*-1:'£'+financial.balanceSheet.miscCurrentAssets):'- -'}}
                                    <span class="{{(financial.positivemisCurAsset)?'red':'green'}}" *ngIf="(financial.perc_misCurAsset)">
                                        {{financial.perc_misCurAsset | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivemisCurAsset)?'down':'up'}}"></i>
                                     </span>
                                     <span *ngIf="!(financial.perc_misCurAsset)&&(company.summary.company_information.local_csuk_financials.length-1)!=i">- -</span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.totalCurrentAssets<0)?'-£'+financial.balanceSheet.totalCurrentAssets*-1:'£'+financial.balanceSheet.totalCurrentAssets}}
                                    <span class="{{(financial.positivetotCurAsset)?'red':'green'}}" *ngIf="(financial.perc_totCurAsset)">
                                        {{financial.perc_totCurAsset | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivetotCurAsset)?'down':'up'}}"></i>
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.tradeCreditors<0)?'-£'+financial.balanceSheet.tradeCreditors*-1:'£'+financial.balanceSheet.tradeCreditors}}
                                    <span class="{{(financial.positivetradeCreditors)?'red':'green'}}" *ngIf="(financial.perc_tradeCreditors)">
                                        {{financial.perc_tradeCreditors | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivetradeCreditors)?'down':'up'}}"></i>
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.bankBorrowingsCurrent<0)?'-£'+financial.balanceSheet.bankBorrowingsCurrent*-1:'£'+financial.balanceSheet.bankBorrowingsCurrent}}
                                    <span class="{{(financial.positivebankBorCur)?'red':'green'}}" *ngIf="(financial.perc_bankBorCur)">
                                        {{financial.perc_bankBorCur | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivebankBorCur)?'down':'up'}}"></i>
                                     </span>
                                     <span *ngIf="!(financial.perc_bankBorCur)&&(company.summary.company_information.local_csuk_financials.length-1)!=i">- -</span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.otherShortTermFinance<0)?'-£'+financial.balanceSheet.otherShortTermFinance*-1:'£'+financial.balanceSheet.otherShortTermFinance}}
                                    <span class="{{(financial.positiveothShortTermFin)?'red':'green'}}" *ngIf="(financial.perc_othShortTermFin)">
                                        {{financial.perc_othShortTermFin | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positiveothShortTermFin)?'down':'up'}}"></i>
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.miscCurrentLiabilities<0)?'-£'+financial.balanceSheet.miscCurrentLiabilities*-1:'£'+financial.balanceSheet.miscCurrentLiabilities}}
                                    <span class="{{(financial.positivemisCurLia)?'red':'green'}}" *ngIf="(financial.perc_misCurLia)">
                                        {{financial.perc_misCurLia | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivemisCurLia)?'down':'up'}}"></i>
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.totalCurrentLiabilities<0)?'-£'+financial.balanceSheet.totalCurrentLiabilities*-1:'£'+financial.balanceSheet.totalCurrentLiabilities}}
                                    <span class="{{(financial.positivetotCurLia)?'red':'green'}}" *ngIf="(financial.perc_totCurLia)">
                                        {{financial.perc_totCurLia | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivetotCurLia)?'down':'up'}}"></i>
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.otherFinancials.bankOverdraftAndLTL<0)?'-£'+financial.otherFinancials.bankOverdraftAndLTL*-1:'£'+financial.otherFinancials.bankOverdraftAndLTL}}
                                    <span class="{{(financial.positivebankOveLtl)?'red':'green'}}" *ngIf="(financial.perc_bankOveLtl)">
                                        {{financial.perc_bankOveLtl | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivebankOveLtl)?'down':'up'}}"></i>
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                <p>{{(financial.balanceSheet.otherLongTermFinance<0)?'-£'+financial.balanceSheet.otherLongTermFinance*-1:'£'+financial.balanceSheet.otherLongTermFinance}}
                                    <span class="{{(financial.positiveothLongTermFin)?'red':'green'}}" *ngIf="(financial.perc_othLongTermFin)">
                                        {{financial.perc_othLongTermFin | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positiveothLongTermFin)?'down':'up'}}"></i>
                                     </span>
                                     <span *ngIf="!(financial.perc_othLongTermFin)&&(company.summary.company_information.local_csuk_financials.length-1)!=i">- -</span>
                                </p>
                            </div>
                            <div class="second">
                                <p>{{(financial.balanceSheet.totalLongTermLiabilities<0)?'-£'+financial.balanceSheet.totalLongTermLiabilities*-1:'£'+financial.balanceSheet.totalLongTermLiabilities}}
                                    <span class="{{(financial.positivetotLongTermLia)?'red':'green'}}" *ngIf="(financial.perc_totLongTermLia)">
                                        {{financial.perc_totLongTermLia | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivetotLongTermLia)?'down':'up'}}"></i>
                                     </span>
                                </p>
                            </div>
                           </div>
                       </div>        
                   </div>
               </div>
           </div>
       </div>
      </div>
   </ng-template>
  </mat-tab>

  <mat-tab label="Capital & Reserves">
    <ng-template matTabContent>
      <div class="summary_sec">
       <div class="row">
           <div class="col-sm-12">
               <div class="keyinfo noheight">
                   <div class="row">
                       <div class="col-sm-12">
                           <h4>Capital & Reserves
                        </h4>
                       </div>
                       <div class="col-sm-12 upgr_flex">
                           <div class="block_tables_head_financials">
                               <div class="first">
                                <h4 class="hiddenblank">Blank</h4>
                               </div>
                               <div class="second">
                                   <p>Called Up Share Capital</p>
                               </div>
                               <div class="second">
                                   <p>P & L Account Reserve</p>
                               </div>
                               <div class="second">
                                   <p>Revaluation Reserve</p>
                               </div>
                               <div class="second">
                                   <p>Sundry Reserves</p>
                               </div>
                               <div class="second">
                                   <p><b>Shareholder Funds</b></p>
                               </div>
                           </div>
                           <div class="block_tables_financials colgrid-{{company.summary.company_information.local_csuk_financials.length}}" *ngFor="let financial of company.summary.company_information.local_csuk_financials; let i=index">
                               <div class="first">
                                   <h4>{{financial.yearEndDate | date: 'MM/dd/yyyy'}}</h4>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.issuedShareCapital<0)?'-£'+financial.balanceSheet.issuedShareCapital*-1:'£'+financial.balanceSheet.issuedShareCapital}}
                                    <span class="{{(financial.positivecallUpShareCap<0)?'':(financial.positivecallUpShareCap)?'red':'green'}}" *ngIf="(financial.perc_callUpShareCap)">
                                        {{financial.perc_callUpShareCap | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivetotLongTermLia)?'down':'up'}}"></i>
                                     </span>
                                     <span class="" *ngIf="!(financial.perc_callUpShareCap)&&(company.summary.company_information.local_csuk_financials.length-1)!=i">
                                        £{{0 | number : '1.1-1'}}%
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.revenueReserves<0)?'-£'+financial.balanceSheet.revenueReserves*-1:'£'+financial.balanceSheet.revenueReserves}}
                                    <span class="{{(financial.positiverevReserv)?'red':'green'}}" *ngIf="(financial.perc_revReserv)">
                                        {{financial.perc_revReserv | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positiverevReserv)?'down':'up'}}"></i>
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.revaluationReserve)?((financial.balanceSheet.revaluationReserve<0)?'-£'+financial.balanceSheet.revaluationReserve*-1:'£'+financial.balanceSheet.revaluationReserve):'- -'}}
                                    <span class="{{(financial.positiverevalReserve<0)?'':(financial.positiverevalReserve)?'red':'green'}}" *ngIf="(financial.perc_revalReserve)">
                                        {{financial.perc_revalReserve | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positiverevalReserve)?'down':'up'}}"></i>
                                     </span>
                                     <span class="" *ngIf="!(financial.perc_revalReserve)">
                                        {{0 | number : '1.1-1'}}%
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.otherReserves)?((financial.balanceSheet.otherReserves<0)?'-£'+financial.balanceSheet.otherReserves*-1:'£'+financial.balanceSheet.otherReserves):'- -'}}
                                    <span class="{{(financial.positiveothReserve<0)?'':(financial.positiveothReserve)?'red':'green'}}" *ngIf="(financial.perc_othReserve)">
                                        {{financial.perc_othReserve | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positiveothReserve)?'down':'up'}}"></i>
                                     </span>
                                     <span class="" *ngIf="!(financial.perc_othReserve)">
                                        {{0 | number : '1.1-1'}}%
                                     </span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.totalShareholdersEquity<0)?'-£'+financial.balanceSheet.totalShareholdersEquity*-1:'£'+financial.balanceSheet.totalShareholdersEquity}}
                                    <span class="{{(financial.positivetotalShEq)?'red':'green'}}" *ngIf="(financial.perc_totalShEq)">
                                        {{financial.perc_totalShEq | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivetotalShEq)?'down':'up'}}"></i>
                                     </span>
                                     <span *ngIf="!(financial.perc_totalShEq)&&(company.summary.company_information.local_csuk_financials.length-1)!=i">- -</span>
                                   </p>
                               </div>
                               
                           </div>
                       </div>        
                   </div>
               </div>
           </div>
       </div>
      </div>
   </ng-template>
  </mat-tab>

  <mat-tab label="Other Financial Items">
    <ng-template matTabContent>
      <div class="summary_sec">
       <div class="row">
           <div class="col-sm-12">
               <div class="keyinfo noheight">
                   <div class="row">
                       <div class="col-sm-12">
                           <h4>Other Financial Items</h4>
                       </div>
                       <div class="col-sm-12 upgr_flex">
                           <div class="block_tables_head_financials">
                               <div class="first">
                                <h4 class="hiddenblank">Blank</h4>
                               </div>
                               <div class="second">
                                   <p><b>Net Worth</b></p>
                               </div>
                               <div class="second">
                                   <p><b>Working Capital</b></p>
                               </div>
                               <div class="second">
                                   <p><b>Total Assets</b></p>
                               </div>
                               <div class="second">
                                   <p><b>Total Liabilities</b></p>
                               </div>
                               <div class="second">
                                   <p><b>Net Assets</b></p>
                               </div>
                               
                           </div>
                           <div class="block_tables_financials colgrid-{{company.summary.company_information.local_csuk_financials.length}}" *ngFor="let financial of company.summary.company_information.local_csuk_financials; let i=index">
                               <div class="first">
                                   <h4>{{financial.yearEndDate | date: 'MM/dd/yyyy'}}</h4>
                               </div>
                               <div class="second">
                                   <p>{{(financial.otherFinancials.netWorth<0)?'-£'+financial.otherFinancials.netWorth*-1:'£'+financial.otherFinancials.netWorth}}
                                    <span class="{{(financial.positivenetWorth)?'red':'green'}}" *ngIf="(financial.perc_netWorth)">
                                        {{financial.perc_netWorth | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivenetWorth)?'down':'up'}}"></i>
                                     </span>
                                     <span *ngIf="!(financial.perc_netWorth)&&(company.summary.company_information.local_csuk_financials.length-1)!=i">- -</span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.otherFinancials.workingCapital<0)?'-£'+financial.otherFinancials.workingCapital*-1:'£'+financial.otherFinancials.workingCapital}}
                                    <span class="{{(financial.positiveworkCap)?'red':'green'}}" *ngIf="(financial.perc_workCap)">
                                        {{((financial.perc_workCap<0)?financial.perc_workCap*-1:financial.perc_workCap) | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positiveworkCap)?'down':'up'}}"></i>
                                     </span>
                                     <span *ngIf="!(financial.perc_workCap)&&(company.summary.company_information.local_csuk_financials.length-1)!=i">- -</span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.totalAssets<0)?'-£'+financial.balanceSheet.totalAssets*-1:'£'+financial.balanceSheet.totalAssets}}
                                    <span class="{{(financial.positivetotAsset)?'red':'green'}}" *ngIf="(financial.perc_totAsset)">
                                        {{financial.perc_totAsset | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivetotAsset)?'down':'up'}}"></i>
                                     </span>
                                     <span *ngIf="!(financial.perc_totAsset)&&(company.summary.company_information.local_csuk_financials.length-1)!=i">- -</span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.totalLiabilities<0)?'-£'+financial.balanceSheet.totalLiabilities*-1:'£'+financial.balanceSheet.totalLiabilities}}
                                    <span class="{{(financial.positivetotLia)?'red':'green'}}" *ngIf="(financial.perc_totLia)">
                                        {{financial.perc_totLia | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivetotLia)?'down':'up'}}"></i>
                                     </span>
                                     <span *ngIf="!(financial.perc_totLia)&&(company.summary.company_information.local_csuk_financials.length-1)!=i">- -</span>
                                   </p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.balanceSheet.totalShareholdersEquity<0)?'-£'+financial.balanceSheet.totalShareholdersEquity*-1:'£'+financial.balanceSheet.totalShareholdersEquity}}
                                    <span class="{{(financial.positivetotShEq)?'red':'green'}}" *ngIf="(financial.perc_totShEq)">
                                        {{financial.perc_totShEq | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivetotShEq)?'down':'up'}}"></i>
                                     </span>
                                     <span *ngIf="!(financial.perc_totShEq)&&(company.summary.company_information.local_csuk_financials.length-1)!=i">- -</span>
                                   </p>
                               </div>
                           </div>
                           
                       </div>        
                   </div>
               </div>
           </div>
       </div>
      </div>
   </ng-template>
  </mat-tab>

  <mat-tab label="Cashflow">
    <ng-template matTabContent>
      <div class="summary_sec">
       <div class="row">
           <div class="col-sm-12">
               <div class="keyinfo noheight">
                   <div class="row">
                       <div class="col-sm-12">
                           <h4>Cashflow</h4>
                       </div>
                       <div class="col-sm-12 upgr_flex">
                           <div class="block_tables_head_financials">
                               <div class="first">
                                <h4 class="hiddenblank">Blank</h4>
                               </div>
                               <div class="second">
                                   <p>Net Cashflow from Operations</p>
                               </div>
                               <div class="second">
                                   <p>Net Cashflow before Financing</p>
                               </div>
                               <div class="second">
                                   <p>Net Cashflow from Financing</p>
                               </div>
                               <div class="second">
                                   <p>Increase in Cash</p>
                               </div>
                           </div>
                           <div class="block_tables_financials colgrid-{{company.summary.company_information.local_csuk_financials.length}}" *ngFor="let financial of company.summary.company_information.local_csuk_financials">
                               <div class="first">
                                   <h4>{{financial.yearEndDate | date: 'MM/dd/yyyy'}}</h4>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                           </div>
                       </div>        
                   </div>
               </div>
           </div>
       </div>
      </div>
   </ng-template>
  </mat-tab>

  <mat-tab label="Miscellaneous">
    <ng-template matTabContent>
      <div class="summary_sec">
       <div class="row">
           <div class="col-sm-12">
               <div class="keyinfo noheight">
                   <div class="row">
                       <div class="col-sm-12">
                           <h4>Miscellaneous</h4>
                       </div>
                       <div class="col-sm-12 upgr_flex">
                           <div class="block_tables_head_financials">
                               <div class="first">
                                <h4 class="hiddenblank">Blank</h4>
                               </div>
                               <div class="second">
                                   <p>Contingent Liabilities</p>
                               </div>
                               <div class="second">
                                   <p>Capital Employed</p>
                               </div>
                               <div class="second">
                                   <p>Number of Employees</p>
                               </div>
                           </div>
                           <div class="block_tables_financials colgrid-{{company.summary.company_information.local_csuk_financials.length}}" *ngFor="let financial of company.summary.company_information.local_csuk_financials">
                               <div class="first">
                                   <h4>{{financial.yearEndDate | date: 'MM/dd/yyyy'}}</h4>
                               </div>
                               <div class="second">
                                   <p>{{(financial.otherFinancials.contingentLiabilities)?'Yes':'No'}}</p>
                               </div>
                               <div class="second">
                                   <p>{{(financial.otherFinancials.capitalEmployed<0)?'-£'+financial.otherFinancials.capitalEmployed*-1:'£'+financial.otherFinancials.capitalEmployed}}
                                    <span class="{{(financial.positivecapEmp)?'red':'green'}}" *ngIf="(financial.perc_capEmp)">
                                        {{financial.perc_capEmp | number : '1.1-1'}}%<i class="fa fa-sort-{{(financial.positivecapEmp)?'down':'up'}}"></i>
                                     </span>
                                     <span *ngIf="!(financial.perc_capEmp)&&(company.summary.company_information.local_csuk_financials.length-1)!=i">- -</span>
                                   </p>
                               </div>
                               <ng-template ngFor let-item [ngForOf]="company.summary.company_information.local_gaap_financials">
                                <div *ngIf="item.yearEndDate==financial.yearEndDate" class="second">
                                    <p>{{(item.profitAndLoss.numberOfEmployees)?item.profitAndLoss.numberOfEmployees:'- -'}}</p>
                                </div>
                                </ng-template>
                           </div>
                       </div>        
                   </div>
               </div>
           </div>
       </div>
      </div>
   </ng-template>
  </mat-tab>

  <mat-tab label="Ratios">
    <ng-template matTabContent>
      <div class="summary_sec">
       <div class="row">
           <div class="col-sm-12">
               <div class="keyinfo noheight">
                   <div class="row">
                       <div class="col-sm-12">
                           <h4>Ratios</h4>
                       </div>
                       <div class="col-sm-12 upgr_flex">
                           <div class="block_tables_head_financials">
                               <div class="first">
                                   <h4>Date of Accounts</h4>
                               </div>
                               <div class="second">
                                   <p>Pre-tax profit margin %</p>
                               </div>
                               <div class="second">
                                   <p>Current Ratio</p>
                               </div>
                               <div class="second">
                                   <p>Sales/NetWorking Capital</p>
                               </div>
                               <div class="second">
                                   <p>Gearing %</p>
                               </div>
                               <div class="second">
                                   <p>Equity in %</p>
                               </div>
                               <div class="second">
                                   <p>Creditor Days</p>
                               </div>
                               <div class="second">
                                   <p>Debtor Days</p>
                               </div>
                               <div class="second">
                                   <p>Liquidity/Acid Test</p>
                               </div>
                               
                               <div class="second">
                                   <p>Return On Capital Employed %</p>
                               </div>
                               <div class="second">
                                   <p>Return On Total Assets Employed %</p>
                               </div>
                               <div class="second">
                                   <p>Current Debt Ratio</p>
                               </div>
                               <div class="second">
                                   <p>Total Debt Ratio</p>
                               </div>
                               <div class="second">
                                   <p>Stock Turnover Ratio %</p>
                               </div>
                               <div class="second">
                                   <p>Return On Net Assets Employed %</p>
                               </div>
                           </div>
                           <div class="block_tables_financials colgrid-{{company.summary.company_information.local_csuk_financials.length}}" *ngFor="let financial of company.summary.company_information.local_csuk_financials">
                               <div class="first">
                                   <h4>{{financial.yearEndDate | date: 'MM/dd/yyyy'}}</h4>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>{{financial.ratios.currentRatio}}</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>{{financial.ratios.gearing}}</p>
                               </div>
                               <div class="second">
                                   <p>{{financial.ratios.equityInPercentage}}</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>{{financial.ratios.liquidityRatioOrAcidTest}}</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>{{financial.ratios.currentDebtRatio}}</p>
                               </div>
                               <div class="second">
                                   <p>{{financial.ratios.totalDebtRatio}}</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                               <div class="second">
                                   <p>- -</p>
                               </div>
                           </div>
                       </div>        
                   </div>
               </div>
           </div>
       </div>
      </div>
   </ng-template>
  </mat-tab>


</mat-tab-group>    