"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var myidproof_service_1 = require("../../../services/myidproof.service");
var country_service_1 = require("../../../services/country.service");
var forms_1 = require("@angular/forms");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var platform_browser_1 = require("@angular/platform-browser");
var idproof_model_1 = require("../../../models/idproof/idproof.model");
var router_1 = require("@angular/router");
var app_global_1 = require("../../../app.global");
var myvisa_service_1 = require("../../../services/myvisa.service");
var alert_service_1 = require("../../../services/alert.service");
var CheckaidocumentComponent = /** @class */ (function () {
    function CheckaidocumentComponent(formBuilder, uploadService, datePipe, data, country, visaService, sanitizer, alerts, activatedRoute) {
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.datePipe = datePipe;
        this.data = data;
        this.country = country;
        this.visaService = visaService;
        this.sanitizer = sanitizer;
        this.alerts = alerts;
        this.activatedRoute = activatedRoute;
        this.wizard = 0;
        this.token_expired = false;
        this.aipayment = false;
        this.defaultVal = "";
        this.filterItems = [
            {
                name: 'All Visa Details',
                id: 0,
                selected: true
            },
            {
                name: 'Accepted',
                id: 2,
                selected: false
            },
            {
                name: 'Rejected',
                id: 3,
                selected: false
            },
            {
                name: 'Waiting for review',
                id: 1,
                selected: false
            }
        ];
    }
    CheckaidocumentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.activatedRoute.params.subscribe(function (params) {
            _this.empid = params['id'];
        });
        this.idproofDetail = new idproof_model_1.Idproof();
        this.getCountries();
        this.idproofpopForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            idnumber: ['', forms_1.Validators.required],
            idtype: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            docexpiry: ['', forms_1.Validators.required]
        });
        this.submitted = false;
        this.disableBtn = true;
        this.buttoninsert = "Submit";
        this.idproofDetail = new idproof_model_1.Idproof();
        this.idproofDetail.nationalityid = "";
        this.Userid = localStorage.getItem('userid');
        this.idproofDetail.employeeid = this.empid; //this.Userid;
        this.idproofDetail.employeeidproofdeatilid = "0";
        this.idproofDetail.idtype = 17;
        this.filename = "Choose file";
        this.profilepic = "Choose file";
        this.filebackname = 'Choose file';
    };
    CheckaidocumentComponent.prototype.getCountries = function () {
        var _this = this;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
    };
    Object.defineProperty(CheckaidocumentComponent.prototype, "f", {
        get: function () {
            return this.idproofpopForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    CheckaidocumentComponent.prototype.toggle = function (id) {
        if (id == 1) {
            this.submitted = true;
            console.log(this.idproofpopForm);
            if (this.idproofpopForm.invalid) {
                return;
            }
        }
        this.wizard = id;
    };
    CheckaidocumentComponent.prototype.toggle2 = function (id) {
        // if(id == 1){
        //   this.wizard2 = false;
        //   this.wizard1 = true;
        //   this.wizard3 = false;
        // }
        // if(id == 2){
        //   this.wizard3 = false;
        //   this.wizard1 = false;
        //   this.wizard2 = true;
        // }
    };
    CheckaidocumentComponent.prototype.onSubmit = function (value3, id, stype) {
        var _this = this;
        this.submitted = true;
        this.fileformaterror = false;
        if (this.idproofpopForm.invalid) {
            return;
        }
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagename = imagepath;
        }
        else {
            value3.imagename = this.idproofDetail.iddocName;
        }
        if (this.selectedbackFiles) {
            this.file = this.selectedbackFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagebackname = imagepath;
        }
        else {
            value3.imagebackname = this.idproofDetail.idbackdocName;
        }
        if (value3.imagename == '') {
            this.filevisareuirederror = true;
            return;
        }
        if (value3.imagebackname == '') {
            this.filebackvisareuirederror = true;
            return;
        }
        if (this.selected_profile_image) {
            this.file = this.selected_profile_image;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filebacksizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.filebackformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.idprofileimage = imagepath;
        }
        else {
            value3.idprofileimage = this.idproofDetail.documentprofileimage;
        }
        value3.docexpiry = this.datePipe.transform(new Date(value3.docexpiry), "dd MMM yyyy");
        value3.createby = this.Userid;
        value3.updateby = this.Userid;
        this.wizard = 6;
        this.data.myidproofdetailsUpdatebyadmin(value3).subscribe(function (datamain) {
            var data3 = { "employeeidproofdeatilid": datamain.employeeidproofdeatilid };
            _this.data.idproofdetailsbyidproofid(data3).subscribe(function (data) {
                var countryiso = data.nationality.isothree;
                var idtype = 'DRIVING_LICENSE'; //data.documenttype.documentcode;     
                var visalog = {
                    employeeid: _this.empid,
                    documentid: datamain.employeeidproofdeatilid,
                    documenturl: value3.imagename,
                    documenttype: 'id'
                };
                _this.visaService.visaailogupdate(visalog).subscribe(function (dbvisaresult) {
                    if (dbvisaresult.success == '1') {
                        var data = {
                            merchantIdScanReference: dbvisaresult.logid,
                            country: countryiso,
                            idType: idtype,
                            customerId: _this.empid,
                            is_rtw: 0,
                            callbackUrl: app_global_1.AppGlobals.API_ENDPOINT + "/api/aiconfirmation",
                            documenturl: value3.imagename,
                            documentbackurl: value3.imagebackname,
                            imageurl: value3.idprofileimage
                        };
                        _this.visaService.visadocumentupdate(data).subscribe(function (data5) {
                            if (data5.success == true) {
                                _this.wizard = 5;
                            }
                            else {
                                _this.wizard = 4;
                                _this.alerts.error("something went wrong please try again");
                            }
                        });
                    }
                    else {
                        _this.wizard = 4;
                        _this.alerts.error("something went wrong please try again");
                    }
                });
            });
        });
    };
    CheckaidocumentComponent.prototype.sanitize = function (url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    };
    CheckaidocumentComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.fileformaterror = true;
        }
        else {
            this.fileformaterror = false;
            this.filesizeerror = false;
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.imgURL = reader.result;
            };
        }
    };
    CheckaidocumentComponent.prototype.selectbackFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filebackname = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filebacksizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.filebackformaterror = true;
            this.filebacksizeerror = false;
        }
        else {
            this.filebackformaterror = false;
            this.filebacksizeerror = false;
            this.selectedbackFiles = files[0];
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.imgbackURL = reader.result;
            };
        }
    };
    CheckaidocumentComponent.prototype.selectprofile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.profilepic = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.pro_pic_size_error = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.pro_pic_format_error = true;
            this.pro_pic_size_error = false;
        }
        else {
            this.pro_pic_format_error = false;
            this.pro_pic_size_error = false;
            this.selected_profile_image = files[0];
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.profile_pic_url = reader.result;
            };
        }
    };
    return CheckaidocumentComponent;
}());
exports.CheckaidocumentComponent = CheckaidocumentComponent;
