"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var modal_service_1 = require("../../../_services/modal.service");
var job_service_1 = require("../../../services/job.service");
var forms_1 = require("@angular/forms");
var SkillsComponent = /** @class */ (function () {
    function SkillsComponent(modalService, router, formBuilder, job, activatedRoute) {
        this.modalService = modalService;
        this.router = router;
        this.formBuilder = formBuilder;
        this.job = job;
        this.activatedRoute = activatedRoute;
    }
    SkillsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.submitted = false;
        this.skillFormGroup = this.formBuilder.group({
            jobid: ['', forms_1.Validators.required],
            skill: ['', forms_1.Validators.required]
        });
        this.jobid = this.activatedRoute.snapshot.paramMap.get("jobid");
        this.Userid = localStorage.getItem('userid');
        this.job.getjobskillList({ "id": this.Userid, "jobid": this.jobid }).subscribe(function (data) {
            _this.data = data;
        });
    };
    Object.defineProperty(SkillsComponent.prototype, "f", {
        get: function () { return this.skillFormGroup.controls; },
        enumerable: true,
        configurable: true
    });
    SkillsComponent.prototype.onSubmit = function (skill, modelid) {
        var _this = this;
        // console.log(this.skillFormGroup);
        this.submitted = true;
        if (this.skillFormGroup.invalid) {
            return;
        }
        // var skill = skill.form.value;
        var value = [];
        var skillInsert = '';
        var skillCheck = false;
        var skillarray = skill.skill.split("\n");
        skillarray.forEach(function (element) {
            skillInsert = element.trim();
            skillCheck = false;
            if (skillInsert != '') {
                _this.data.forEach(function (sk) {
                    if (sk.skillname.toLowerCase() == skillInsert) {
                        skillCheck = true;
                    }
                });
                if (!skillCheck) {
                    value.push({ 'skillname': skillInsert, 'jobid': skill.jobid });
                }
            }
        });
        this.disableBtn = false;
        this.job.addnewskill({ 'data': value }).subscribe(function (data) {
            _this.job.getjobskillList({ "id": _this.Userid, "jobid": _this.jobid }).subscribe(function (data) {
                // skill.resetForm();        
                _this.data = data;
                _this.modalService.close(modelid);
            });
        });
    };
    SkillsComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.disableBtn = true;
        this.skillFormGroup.reset({ "skill": "", "jobid": this.jobid });
        this.modalService.open(id);
    };
    SkillsComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    SkillsComponent.prototype.onDelete = function (id) {
        var _this = this;
        var deleteData = { 'id': id };
        if (confirm("Are you sure you want to delete?")) {
            this.job.deleteskill(deleteData).subscribe(function (data) {
                _this.job.getjobskillList({ "id": _this.Userid, "jobid": _this.jobid }).subscribe(function (data) {
                    _this.data = data;
                });
            });
        }
    };
    return SkillsComponent;
}());
exports.SkillsComponent = SkillsComponent;
