"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var alert_service_1 = require("../services/alert.service");
var core_1 = require("@angular/core");
var AlertComponent = /** @class */ (function () {
    function AlertComponent(alertService) {
        this.alertService = alertService;
    }
    AlertComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.alertService.getMessage().subscribe(function (message) {
            _this.message = message;
            setTimeout(function () {
                _this.message = '';
            }, 3000);
        });
    };
    AlertComponent.prototype.onclose = function () {
        this.message = '';
    };
    AlertComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return AlertComponent;
}());
exports.AlertComponent = AlertComponent;
