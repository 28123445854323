"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var AddressinformationComponent = /** @class */ (function () {
    function AddressinformationComponent() {
    }
    AddressinformationComponent.prototype.ngOnInit = function () {
    };
    return AddressinformationComponent;
}());
exports.AddressinformationComponent = AddressinformationComponent;
