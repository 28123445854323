import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { Visa } from '../../../models/visa/visa.model';
import { Aml,AmlBussiness } from '../../../models/aml/aml.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AmlService } from '../../../services/aml.service';
import { Router } from '@angular/router';

import { ChecksystemService } from '../../../services/checksystem.service';


@Component({
  selector: 'app-personalamlcheck',
  templateUrl: './personalamlcheck.component.html',
  styleUrls: ['./personalamlcheck.component.css']
})
export class PersonalamlcheckComponent implements OnInit {
  visaDetail: any;
  visa: Visa;
  disableBtn: any;
  buttoninsert: any;

  errormsg: any;

  error: boolean;  
  submitted: boolean;  
  personalformGroup: FormGroup;
  businessDetail =new Aml();
  searchresult: any;
  result: boolean;
  defaultVal: string ='';
  countrylist:any;
  datasetlist: any;
  bussinessdetails:any;
  lesssore:boolean;
  Userid: any; 
  permission:  any;
  assetperm: any;
  tc:boolean=true;
  checkedAll: any = false;

  constructor(
    private modalService: ModalService,     
    private formBuilder: FormBuilder,
    private amldetails: AmlService,
    private router: Router,
    private check: ChecksystemService
  ) { }

  ngOnInit() {
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    // this.permission = permission[0];
    if(permission[0]['m96'] < 3)
    {
      this.router.navigate(['/permission-denied']);
    }

    this.error =false;
    this.disableBtn =true;
    this.errormsg ='';
    this.buttoninsert ="Search";
    this.result =false;
    this.defaultVal ='';
    this.personalformGroup = this.formBuilder.group({
      Forename: ['', Validators.required],
      Country: ['', Validators.required],
      Middlename: [''],        
      Threshold: [''],   
      Surname: [''] ,
      Postcode: [''],
      Address: [''], 
      DateOfBirth: [''],
      YearOfBirth: [''],
      County: [''],
      City: [''],          
      PEP: [''],
      PreviousSanctions: [''],
      CurrentSanctions: [''],
      LawEnforcement: [''],
      FinancialRegulator: [''],
      Insolvency: [''],
      DisqualifiedDirector: [''],
      AdverseMedia: ['']
    });

    
    this.Userid = localStorage.getItem('userid');

    this.lesssore =false;
    
    var ts  = { 'serviceid': 8};    
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      if(remaindata.remiancheck <= 0)
      {
          this.lesssore =true;
      }
    });


    var personalid = localStorage.getItem('personalid');
    if (!personalid && personalid != null) {
      this.router.navigate(['/creditsearchresult', personalid]);
    }


    this.amldetails.getCountries().subscribe((countrydata: any) => { 
      // console.log(countrydata);
      this.countrylist = countrydata;
    });



  }

  get f() { return this.personalformGroup.controls; }

 
amllogredirect()
{
  this.router.navigate(['/check-aml-log-list', '0']);
}

selectall(val){

  if(val == true){
  this.checkedAll = true;
  this.businessDetail.PEP = true;
  this.businessDetail.CurrentSanctions = true;
  this.businessDetail.DisqualifiedDirector =true;
  this.businessDetail.FinancialRegulator =true;
  this.businessDetail.Insolvency =true;
  this.businessDetail.LawEnforcement= true;
  this.businessDetail.AdverseMedia =true;
  this.businessDetail.PreviousSanctions =true;
}

}


selectallfalse(val){
  if(val == false){
    this.checkedAll = false;
  }
}


dataset(values){
    
  this.datasetlist ="";

  if(values.isPEP == true)
  {
    this.datasetlist = "PEP,";
  }

  if(values.isAdverseMedia == true)
  {
    this.datasetlist += " Adverse Media,";
  }

  if(values.isDisqualifiedDirector == true)
  {
    this.datasetlist += " Disqualified Director (UK Only),";
  }

  if(values.isFinancialregulator == true)
  {
    this.datasetlist += " Financial Regulator,";
  }

  if(values.isInsolvent == true)
  {
    this.datasetlist += " Insolvency (UK & Ireland),";
  }

  if(values.isLawEnforcement == true)
  {
    this.datasetlist += " Law Enforcement,";
  }

  if(values.isSanctionsCurrent == true)
  {
    this.datasetlist += " Sanction - Current,";
  }
  if(values.isSanctionsPrevious == true)
  {
    this.datasetlist += " Sanction - Previous,";
  }

  this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1); 

}

clearpersonal(){
  this.submitted = false; 
  // this.personalformGroup.reset();
  this.businessDetail = new Aml();

  this.businessDetail.Forename = "";
  this.businessDetail.Country = "";
  this.businessDetail.Middlename = "";
  this.businessDetail.Threshold = "";
  this.businessDetail.Surname = "";
  this.businessDetail.Postcode = "";
  this.businessDetail.Address = "";
  this.businessDetail.DateOfBirth = "";
  this.businessDetail.YearOfBirth = "";
  this.defaultVal = "";
  this.businessDetail.County = "";
  this.businessDetail.City = "";
  
  this.businessDetail.PEP = false;
  this.businessDetail.CurrentSanctions = false;
  this.businessDetail.DisqualifiedDirector =false;
  this.businessDetail.FinancialRegulator =false;
  this.businessDetail.Insolvency =false;
  this.businessDetail.LawEnforcement= false;
  this.businessDetail.AdverseMedia =false;
  this.businessDetail.PreviousSanctions =false;
  this.checkedAll = false;
}

oldsearch(){


}

onSubmit(val, type)
{
  this.error =false;
  this.submitted = true; 
  if (this.personalformGroup.invalid) {
    return;
  }

  if(val.PEP ==false && val.PreviousSanctions ==false && val.CurrentSanctions ==false && val.LawEnforcement ==false && val.FinancialRegulator ==false && val.Insolvency ==false && val.DisqualifiedDirector ==false &&  val.AdverseMedia ==false)
  {
    this.error =true;
    return;
  }
  
  if(type == 'first'){
    this.disableBtn =true;
      var ts  = { 'serviceid': 8}; 
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      // console.log(remaindata)
      if(remaindata.remiancheck <= 0)
      {   
        this.lesssore =true;
        this.modalService.open('upgrade-plan');
      }else
      {  
        this.disableBtn =true;
        this.lesssore =false;
        this.modalService.open('pdf-payment-confirmation');          
      }
    });   
  
  }
  else if (type == 'payment')
  {

    if(val.DateOfBirth)
    {
      const sd = new Date(val.DateOfBirth);
      val.DateOfBirth = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    }
    
    
    this.amldetails.getPersonalSearch(val).subscribe((searchRes: any) => {     
      this.errormsg ='';
      if(searchRes.error == ''){
        if(searchRes.recordsFound >0){
          this.result =true;
        }        
        this.disableBtn =true;
        localStorage.setItem('personalid', searchRes.id);    
        this.router.navigate(['/creditsearchresult', searchRes.id]);    
        this.searchresult = searchRes;
      }else if(searchRes.error == 'amlfail')
      {
          this.errormsg ='We have some issue in AML & PEPs check. Please contact Administrator';
        
      }else  if(searchRes.error == 'paymentfail')
      {
        this.errormsg ='Your payment fail. Please contact Administrator';
      }
    });
  }  
}

  openModal(id: string, data: any) {
    this.bussinessdetails = data;

   // console.log(data);
    this.buttoninsert = "Update";
    this.modalService.open(id);
  }
  closeModal(id: string) 
  {
    
    
    this.modalService.close(id);
  }
}
