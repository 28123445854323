import { Component, OnInit } from '@angular/core';
import { CountryService } from '../../../services/country.service';
import { MyidproofService } from '../../../services/myidproof.service';
import { ModalService } from '../../../_services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Idproof } from '../../../models/idproof/idproof.model';
import { UploadFileService } from './../../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
// import { Router } from '@angular/router';
import { Router, ActivatedRoute, Params } from '@angular/router';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { AlertService } from '../../../services/alert.service';

import { ChecksystemService } from '../../../services/checksystem.service';

@Component({
  selector: 'app-checkdocument',
  templateUrl: './checkdocument.component.html',
  styleUrls: ['./checkdocument.component.css']
})
export class CheckdocumentComponent implements OnInit {
  idprooflist: any[] = null;
  idproofDetail: any;
  countrylist: any;
  documenttype: any;
  Userid: any;
  listcheck: any;
  selectedFiles: FileList;
  iframeidproofsrc: any;
  filevisareuirederror:any;
  filebackvisareuirederror:any;
  filesizeerror: any;
  filereuirederror: any;
  fileformaterror: any;
  formattedMessage: any;

  imgidproofsrc: any;
  buttoninsert: any;

  ramainingChecks:any;
  filebacksizeerror: any;
  filebackformaterror: any;
  selectedbackFiles: FileList;
  filedocumentbackname: any;

  filedocumentreuirederror:any;
  filedocumentsizeerror:any;
  filedocumentformaterror:any;
  filedocumentbackbacksizeerror:any;
  filedocumentbackreuirederror:any;
  
  
  imagesecond: any;
  file: any;
  filedocumentname: any;
  defaultVal: any = "";
  filterItems = [
    {
      name: 'All Address Proof',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Waiting for review',
      id: 1,
      selected: false
    }
  ]
  submitted: any;
  idproofpopForm: FormGroup;
  idproof: Idproof;
  aipayment: boolean =false;

  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private data: MyidproofService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private router: Router,
    private alerts: AlertService,    
    private check: ChecksystemService
  ) { }

  ngOnInit() {
    this.defaultVal ="";
    this.createformgroup();
    this.filedocumentname = 'Choose file';
    this.filedocumentbackname = 'Choose file';
    this.idproofDetail = new Idproof();
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    this.data.getidproofAllList(data).subscribe((data: any) => {      
      this.idprooflist = data;
    });


    

    this.check.getbillinginformation({ 'serviceid': 7 }).subscribe((remaindata: any) => { 
      this.ramainingChecks = remaindata;
    });



    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.data.iddocumenttype().subscribe((documenttype: any) => {
      this.documenttype = documenttype;
    });
    this.listcheck = this.filterItems;
  }

  createformgroup()
  {
    this.idproofpopForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      idnumber: ['', Validators.required],
      idtype: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      docexpiry: ['', Validators.required]
    });
  }

  onDelete(id: number) {
    const data1 = { 'id': id, 'userid': this.Userid };
    if (confirm("Are you sure you want to delete?")) {
      this.data.deleteidproof(data1).subscribe((data: any) => {
        var sstatus: any;
        for (let c of this.filterItems) {
          if (c.selected == true) {
            if (c.id == 0) {
              sstatus = [1, 2, 3];
            }
            else {
              sstatus = [c.id];
            }
          }
        }
        const data2 = { 'id': this.Userid, 'cstatus': sstatus };
        this.data.getidproofAllList(data2).subscribe((data: any) => {
          this.idprooflist = data;
        });
        this.alerts.success('Address Proof Deleted Successfully.', true);
      });
    }
  }

  onlistchange(type1: any, idval: number) {
    var sstatus: any;
    for (let c of this.filterItems) {
      if (c.id == idval)
        c.selected = true;
      else
        c.selected = false;
    }
    if (idval == 0 || type1 == false) {
      sstatus = [1, 2, 3];
    }
    else {
      sstatus = [idval];
    }
    var userid = localStorage.getItem('userid');
    const data = { 'id': userid, 'cstatus': sstatus };
    this.data.getidproofAllList(data).subscribe((data: any) => {
      this.idprooflist = data;
    });
  }

  get f() {
    return this.idproofpopForm.controls;
  }
  
  onSubmit(value3: any, id: string) {
    this.submitted = true;
    this.fileformaterror = false;
    this.filevisareuirederror = false;
    this.filebackvisareuirederror = false;

    if (this.idproofpopForm.invalid) {
      return;
    }
    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") ) {
        this.fileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.imagename = imagepath;
    }
    else {
      value3.imagename = this.idproofDetail.iddocName;
    }

    if (this.selectedbackFiles) {
      this.file = this.selectedbackFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
        this.fileformaterror = true;
        return;
      }
      var imagepath =  this.uploadService.uploadfile(this.file);
      value3.imagebackname = imagepath;
    }
    else {
      value3.imagebackname = this.idproofDetail.idbackdocName;
    }

    if(value3.imagename ==''){
      this.filevisareuirederror = true;
      return;
    }

    if(value3.imagebackname==''){
      this.filebackvisareuirederror = true;
      return;
    }





    this.data.myidproofdetailsUpdate(value3).subscribe((data: any) => {
      var sstatus: any;
      for (let c of this.filterItems) {
        if (c.selected == true) {
          if (c.id == 0) {
            sstatus = [1, 2, 3];
          }
          else {
            sstatus = [c.id];
          }
        }
      }
      var userid = localStorage.getItem('userid');
      const data1 = { 'id': userid, 'cstatus': sstatus };
      this.data.getidproofAllList(data1).subscribe((data: any) => {
        this.idprooflist = data; 
      });
    });
    this.filedocumentname = "Choose file";
    this.alerts.success('ID Proof updated successfully.', true);    
    // this.idproofDetail = new idproof();
    this.modalService.close(id);
  }


  
  selectdocumentFileupload(event)
   {
    var files = event.target.files;
    this.filedocumentname = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.fileformaterror = true;  this.filesizeerror = false;      
    }else{
      this.filesizeerror = false;  
      this.fileformaterror = false;
      this.filereuirederror =false;
      this.imagesecond =  this.uploadService.uploadfile(this.file);
      this.selectedFiles = files[0];
    }
  }

  selectdocumentbackFileupload(event)
   {
    var files = event.target.files;
    this.filedocumentbackname = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filebacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filebackformaterror = true;    this.filebacksizeerror = false; 
    }else{this.filebacksizeerror = false;  
      this.filebackformaterror = false;
     this.imagesecond =  this.uploadService.uploadfile(this.file);
      this.selectedbackFiles = files[0];
    }
  }

  // selectFile(event)
  //  {
  //   var files = event.target.files;
  //   this.filedocumentname = files[0].name;
  //   this.selectedFiles = files[0];
  // }

  openModal2(id: string) 
  {
    // this.createformgroup();
    this.submitted = false;
    this.buttoninsert = "Submit";this.selectedFiles = null;
    this.fileformaterror = false;
    this.idproofDetail = new Idproof();    
    this.Userid = localStorage.getItem('userid');
    this.idproofDetail.employeeid = this.Userid;
    this.idproofDetail.employeeidproofdeatilid = "0";  
    this.idproofDetail.nationalityid = "70";  
    this.idproofDetail.idtype ='';
    this.filedocumentname = "Choose file";
    this.filedocumentbackname = 'Choose file';
    this.modalService.open(id);
  }

  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModalidproof(id: string, data: any) 
  {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgidproofsrc = true;
      this.iframeidproofsrc = data;
    }
    else {
      this.imgidproofsrc = false;
      this.iframeidproofsrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.submitted = false;this.selectedFiles = null;
    this.fileformaterror = false;
    this.idproofDetail = new Idproof();  
    // console.log(data);  
    this.idproofDetail = data;
    this.buttoninsert = "Update"; 
    this.filedocumentname = "Choose file";
    this.filedocumentbackname = 'Choose file';
    this.modalService.open(id);
  }

  closeModal(id: string) 
  {
    this.idproof = new Idproof();
    // this.idproofDetail = new idproof();
    const data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    this.data.getidproofAllList(data).subscribe((data: any) => {
      this.idprooflist = data;
    });
    this.modalService.close(id);
  }
  
  uploadByAdmin(emp_type, type, empid)
  {
    if(this.ramainingChecks && this.ramainingChecks.remiancheck < 1)
    {
      this.modalService.open('upgrade-plan');
    }
    else
    {
      if(type == 'upload_now')
      {
        if(emp_type == 'visa')
        {
          this.router.navigate(['/check-ai-visa-admin', empid]);
        }     
      
    }
  }
}
}