"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var environment_1 = require("../environments/environment");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
// import { NgwWowService } from 'ngx-wow';
var operators_1 = require("rxjs/operators");
require("./_content/app.less");
require("./_content/modal.less");
var AppComponent = /** @class */ (function () {
    function AppComponent(document, router, location
    // private wowService: NgwWowService
    ) {
        this.router = router;
        this.title = 'S';
        // this.wowService.init(); 
    }
    AppComponent.prototype.ngOnInit = function () {
        if (environment_1.environment.production) {
            if (location.protocol === 'http:') {
                window.location.href = location.href.replace('http', 'https');
            }
        }
        // this.wowSubscription = this.wowService.itemRevealed$.subscribe(
        //   (item:HTMLElement) => {
        //     // 
        //   });
        this.recallJsFuntions();
    };
    //   onWindowScroll(e) {
    //     if (window.pageYOffset > 120) {
    //       let element = document.getElementById('navbar');
    //       element.classList.add('headersticky');
    //     } else {
    //      let element = document.getElementById('navbar');
    //        element.classList.remove('headersticky'); 
    //     }
    //  }
    AppComponent.prototype.recallJsFuntions = function () {
        var _this = this;
        this.router.events
            .subscribe(function (event) {
            if (event instanceof router_1.NavigationStart) {
                $('.preloader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
            .pipe(operators_1.filter(function (event) { return event instanceof router_1.NavigationEnd || event instanceof router_1.NavigationCancel; }))
            .subscribe(function (event) {
            $.getScript('../assets/js/custom.js');
            $('.preloader').fadeOut('slow');
            _this.location = _this.router.url;
            if (!(event instanceof router_1.NavigationEnd)) {
                return;
            }
            // window.scrollTo(0, 0);
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        // unsubscribe (if necessary) to WOW observable to prevent memory leaks
        this.wowSubscription.unsubscribe();
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;
