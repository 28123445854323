import { Component, OnInit } from '@angular/core';
import { LeaveService } from '../../../services/leave.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../../_services/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../services/alert.service';
import { CustomerService } from '../../../services/customer.service';
import { Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-adminsickleaverequest',
  templateUrl: './adminsickleaverequest.component.html',
  styleUrls: ['./adminsickleaverequest.component.css']
})

export class AdminsickleaverequestComponent implements OnInit {
  data: any [];
  Userid: any;
  month: any;
  notedetails : any;
  year: any;
  remarkformGroup: FormGroup;
  yearmonth: any;
  classactive: string;
  remarksubmit = false;
  remarksholidayid: any;
  remarksofleave: any;
  employeelist: any;
  employeeid: any;
  isline: any;
  holidayremarks: any;
  record: number;
  hstatus: any;
  disableBtn: any;
  permission: any;
  requestperm: any;
  yearList: any;
  iframesrc: any;
  imgsrc: any;
  items: Array<any>;
  
  pageOfItems: Array<any>;
  monthNames = 
  [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  constructor(
    private dataset: LeaveService,
    private formBuilder: FormBuilder,
    private dataservice: LeaveService,
    private modalService: ModalService,
    private alerts: AlertService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private customer: CustomerService
    ) { }

  ngOnInit() {
    this.yearList = AppGlobals.YEAR_VALUES;
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;

    this.hstatus ='';
    this.month = (new Date()).getMonth()+1;
    this.year = (new Date()).getFullYear();
    this.isline = localStorage.getItem('isline');
    this.remarkformGroup = this.formBuilder.group({
      remarksholidayid: ['', Validators.required],
      holidayremark: ['', Validators.required],
      isline: ['']
    });

    this.yearmonth = this.monthNames[this.month -1 ] +' '+ (new Date()).getFullYear();


    this.classactive =((new Date()).getMonth()+1).toString();
    
    this.customer.getEmployeeDetailsbyadmin().subscribe((data: any) => {      
      this.employeelist = data;
     
    });
    this.bindalldata();
  }

  get g() 
  {
    return this.remarkformGroup.controls;
  }

  onChange(newValue) {    
    this.employeeid = newValue;
    this.bindalldata();
  }
  onChangestatus(newValue) {    
    this.hstatus = newValue;
    this.bindalldata();
  }
  bindalldata()
  {
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'month': this.month, 'year': this.year, 'employeeid': this.employeeid, 'hstatus': this.hstatus, 'issick': 1 };

    this.dataset.getemployeeleaveapprovedreportbyadmin(data).subscribe((data: any) => {
      this.record = data.length;
        this.data = data;
        this.record = this.data.length;

        if(this.record < 30){
          this.pageOfItems = this.data;
        }
        else{
             this.items =this.data;
        }
    });
  }
  openModalnote (data:any,id:any){
    this.notedetails = data;
    this.modalService.open(id);
  }
  closemessageModal(id: string) {
    this.modalService.close(id);
    // this.showAllEvents('event-viewer',this.remarksholidayid);   
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  
  remarksmodel(id: number, modelid: string, closemodelid: string) {
   
    this.remarksubmit = false;
    this.disableBtn = true;
    this.Userid = localStorage.getItem('userid');
    const leaveData = { 'id': id };
    this.remarksholidayid = id;
    this.dataservice.getleaveremarks(leaveData).subscribe((remarksData: any) => {
      this.remarksofleave = remarksData;
      this.holidayremarks ='';
    });
    
    this.modalService.open(modelid);
    this.modalService.close(closemodelid);
  }


  onSubmitRemark(remark, popid, status) {    
    this.remarksubmit = true;
    console.log(this.remarkformGroup)
    var jj='';
    if (this.remarkformGroup.invalid) {      
      return;
    }   
    this.disableBtn = false;
    remark.form.value.status = status;
    console.log(remark.form.value);
    this.dataservice.addnewremark(remark.form.value).subscribe((data: any) => {
      jj= remark.form.value.remarksholidayid;
      const leaveData = { 'id': remark.form.value.remarksholidayid };
      this.dataservice.getleaveremarks(leaveData).subscribe((remarksData: any) => {
        this.remarkformGroup.reset();
        this.remarksubmit = false;
        this.remarksofleave = remarksData;
        this.remarksholidayid=jj;
      });      
      this.bindalldata();
    });
     this.alerts.success('Note Sent Successfully.', false);
    this.closeModal(popid);
    // this.bindalldatabymonth();

   // this.showAllEvents('event-viewer', this.remarksholidayid);
  }

  onStatus(id: number, status: number) {  

    const deleteData = { 'id': id, 'hstatus': status, 'userid': this.Userid, 'statusdate': new Date() };
    var msg = '';
    if (status == 2) {
      msg = 'Are you sure you want to Approve?';

    } else {
      msg = 'Are you sure you want to Reject?';
    }

    if (confirm(msg)) {
      this.dataset.statusleave(deleteData).subscribe((data: any) => {
        this.bindalldata();
      });
    }

  }

  onyearSubmit(year) {
    this.year = year;
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ this.year ;

    this.bindalldata();
    // this.modalService.close(id);
  }

  yearpop(id: string){
    this.modalService.open(id);
  }

  closeModal(id: string){
    this.modalService.close(id);
  }

  getDataByMonth(mon)
  {
    this.classactive =mon;
    this.month = mon;
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ this.year ;
    this.bindalldata();
  }

  getDataByYear(yea)
  {
    
    this.year = yea;
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ this.year ;

    this.bindalldata();
    // const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    // this.dataset.getemployeeleaveapprovedreportbyadmin(data).subscribe((data: any) => {
      
    //  this.data = data;
    // });
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModal3(id: string, data: any) {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];

    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

}
