
<div class="overviewsshow">
    <div class="header">
    <h1 class="headprofile mt-0">Address</h1>
    <a (click)="openModal2('custom-address-1')" class="addscreensuccess">Add Address</a>
    </div>
    <div *ngFor="let filterItem of listcheck" class="form-check col-lg-2" >
    <label class="form-check-label billed-to">
        <input type="checkbox" (ngModelChange)="onlistchange($event,filterItem.id)" [(ngModel)]="filterItem.selected"
            [value]="filterItem.id"> {{ filterItem.name }}
    </label>
</div>
<div class="row">
    <div class="col-sm-6" *ngFor="let ad of addressdata">
        <div class="border-box">
            <div class="overview_details emp">
                <div class="visastaus mb-3">
                    <div class="detailsshow"> 
                        <p><b>{{ad.address1}}</b>
                            <span style="color:green" *ngIf="ad.stype == '2' && ad.iscurrent == true">(Current)</span>
                        </p>
                    <div class="icons_box_section">
                        <a (click)="openModal('custom-address-1',ad)" class="editprime"><i class="lnr lnr-pencil"></i></a>
                        <a *ngIf="ad.stype == '1'" (click)="onDelete(ad.employeeaddresdeatilid)" class="deletered"><i class="lnr lnr-trash"></i></a>
                    </div>
                </div>
                <div class="detailsshow dflex">
                    <span class="lnr lnr-map-marker"></span>
                    <div>
                    <p class="mb-0">{{ad.address2 }}</p>
                    <p class="mb-0">{{ad.cityname}},{{ad.statename}}  </p>
                    <p>{{ad.country.countryname}} -{{ad.pincode}}</p>
                    </div>
                    </div>
                </div>
        </div>
    </div>
</div>

</div>
<div class="job_list_section visa_request_section" *ngIf="!addressdata?.length > false">
    <div class="job_detils_date">
        <p class="job_profile">No Record Found</p>
    </div>
</div>  

<jw-modal id="custom-address-1" class="modal" role="dialog">
    <form [formGroup]="addpopForm" #addresslistForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(addresslistForm.value,'custom-address-1')" *ngIf="addresslistDetail">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="addresslistDetail.employeeaddresdeatilid" formControlName="id" />
                    <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="addresslistDetail.employeeid" formControlName="employeeid" />
                    <h5 class="modal-title" id="popupLabel">Address Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-address-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Postcode*</label>
                                <input type="pincode" formControlName="pincode" id="pincode" name="pincode" class="form-control pincode" 
                                    [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }" [ngModel]="addresslistDetail.pincode" 
                                    (input)="onSearchChange($event.target.value)" matInput [matAutocomplete]="auto" maxlength="10">
                                <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                    <div *ngIf="f.pincode.errors.required">Please enter postcode</div>
                                </div>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="pincode">
                                    <mat-option *ngFor="let option of options" [value]="option" (onSelectionChange)="updatecode($event, option)">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Address Line 1*</label>
                                <input type="address1" formControlName="address1" name="address1" id="address1" 
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" required 
                                    [ngModel]="addresslistDetail.address1" maxlength="60">
                                <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
                                    <div *ngIf="f.address1.errors.required">Please enter address line 1</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Address Line 2 </label>
                                <input type="address2" formControlName="address2" name="address2" id="address2" 
                                    class="form-control" [ngModel]="addresslistDetail.address2" maxlength="60">
                            </div>
                            <div class="form-group col-lg-6">
                                <label>City*</label>
                                <input type="text" formControlName="cityname" name="cityname" class="form-control" id="cityname" maxlength="30"
                                    [ngClass]="{ 'is-invalid': submitted && f.cityname.errors }" required [ngModel]="addresslistDetail.cityname">
                                <div *ngIf="submitted && f.cityname.errors" class="invalid-feedback">
                                    <div *ngIf="f.cityname.errors.required">Please enter city</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>County Name*</label>
                                <input type="text" formControlName="statename" name="statename" id="statename" class="form-control" 
                                    [ngClass]="{ 'is-invalid': submitted && f.statename.errors }" required 
                                    [ngModel]="addresslistDetail.statename" maxlength="30">
                                <div *ngIf="submitted && f.statename.errors" class="invalid-feedback">
                                    <div *ngIf="f.statename.errors.required">Please enter county</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Country*</label>
                                <select style="width:100%;" formControlName="countryid" class="form-control input-lg" id="countryid" name="countryid" [ngClass]="{ 'is-invalid': submitted && f.countryid.errors }" (change)="onSelectCountry($event.target.value)" [ngModel]="addresslistDetail.countryid">
                                    <option [value]="defaultVal">Select Country</option>
                                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && f.countryid.errors" class="invalid-feedback">
                                    <div *ngIf="f.countryid.errors.required">Please select country</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6 d-flex align-items-center mt-1">
                                <div class="input-group backsidepass">
                                   <span class="mr-3">Current Address?</span>
                                   <label class="visapass mr-3 mb-0 d-flex align-items-center">Yes
                                    <input type="radio" value="1" formControlName="iscurrent" (change)="currentaddresschange($event,addresslistDetail.employeeaddresdeatilid)" id="currentyes" name="iscurrent" [ngModel]="addresslistDetail.iscurrent" />
                                    <span class="checkmark"></span> 
                                  </label> 
                                  <label class="visapass mb-0 d-flex align-items-center">No
                                    <input type="radio" value="0" formControlName="iscurrent" (change)="currentaddresschange($event,addresslistDetail.employeeaddresdeatilid)" id="currentno" name="iscurrent" [ngModel]="addresslistDetail.iscurrent" />
                                    <span class="checkmark"></span>
                                  </label>
                                    <!-- <input class="passs" type="checkbox" [checked]="passportDetail.iscurrent" formControlName="iscurrent" name="iscurrent" [ngModel]="passportDetail.iscurrent" /> -->
                                    <!-- <input class="passs ml-2 mr-2" type="radio" value="1" formControlName="iscurrent" name="iscurrent" [ngModel]="passportDetail.iscurrent" /> Yes
                                    <input class="passs ml-3 mr-2" type="radio" value="0" formControlName="iscurrent" name="iscurrent" [ngModel]="passportDetail.iscurrent" />  No -->
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <div>
                                   <!-- <b>Current Address?</b> -->
                                    <!-- <input type="checkbox" [checked]="addresslistDetail.iscurrent" formControlName="iscurrent" (change)="currentaddresschange($event,addresslistDetail.employeeaddresdeatilid)" id="iscurrent" name="iscurrent" [ngModel]="addresslistDetail.iscurrent" /> -->
                                    <!-- Yes <input type="radio" value="1" formControlName="iscurrent" (change)="currentaddresschange($event,addresslistDetail.employeeaddresdeatilid)" id="currentyes" name="iscurrent" [ngModel]="addresslistDetail.iscurrent" />
                                    No <input type="radio" value="0" formControlName="iscurrent" (change)="currentaddresschange($event,addresslistDetail.employeeaddresdeatilid)" id="currentno" name="iscurrent" [ngModel]="addresslistDetail.iscurrent" /> -->
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                            </div>
                           
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Moved In*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker1" name="movedin" id="movedin" formControlName="movedin" (click)="picker1.open()" (keyup)="retFalse()" [max]="currentDate" class="form-control" [ngModel]="addresslistDetail.movedin">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.movedin.errors" class="invalid-feedback">
                                    <div *ngIf="f.movedin.errors.required">Please enter Moved In date</div>
                                </div>
                                <!--<mat-datepicker #picker></mat-datepicker>-->
                            </div>
                            <div *ngIf="currentdatevalue == true" class="form-group col-lg-6">
                                <label>Moved Out*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker2" id="movedout" formControlName="movedout" name="movedout" (click)="picker2.open()" (keyup)="retFalse()" [min]="addpopForm.controls.movedin.value" [max]="currentDate" class="form-control" [ngModel]="addresslistDetail.movedout">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                                <div class="invalid-feedback" style="display:block;">
                                    <div *ngIf="movedoutdateerror!=''">{{movedoutdateerror}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-address-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>