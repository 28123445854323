import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Passport } from '../../../models/passport/passport.model';
import { Employee } from '../../../models/employee.model';
import { MypassportService } from '../../../services/mypassport.service';
import { AlertService } from '../../../services/alert.service';
import { UploadFileService } from '../../../services/upload-file.service';
import { CustomerService } from '../../../services/customer.service';

@Component({
  selector: 'app-passportfaceai',
  templateUrl: './passportfaceai.component.html',
  styleUrls: ['./passportfaceai.component.css']
})

export class PassportfaceaiComponent implements OnInit {  
  submitted: any;
  visapopForm: FormGroup;
  visa: Passport;
  permission: any;
  requestperm: any;
  record: number;
  passportdetails = new Passport();  
  employee = new Employee();
  selectedFiles: any;
  fileformaterror:any;
  filesizeerror:any;
  file:any;
  employeemasterid: any;
  data3:any;
  employeepassportdeatilid:any;
  ctype:number;
  nextDisable: any = true;
  passportprofileimage: any;
  userId: any;

  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder,  
    private passportService: MypassportService, 
    private activatedRoute: ActivatedRoute,
    private alerts: AlertService,    
    private uploadService: UploadFileService,  
    private data: CustomerService, 
    // private country: CountryService,  
    private router: Router) { }

  ngOnInit() {
    this.userId = localStorage.getItem('userid');
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;

    this.employeepassportdeatilid = this.activatedRoute.snapshot.paramMap.get("passportid");
    this.ctype  = parseInt(localStorage.getItem('ctype'));
    this.data3 = {"employeepassdeatilid": this.employeepassportdeatilid}
    this.passportService.passportdetailsbypassportid(this.data3).subscribe((data: any) => {     
      this.employeemasterid = data.employee.employeemasterid;

      if(data.passportprofileimage)
      {
        this.nextDisable = false;
        this.passportprofileimage = data.passportprofileimage;
      }else if(data.employee.imagename)
      {
        this.nextDisable = false;
        this.passportprofileimage = data.employee.imagename;
      }
      this.passportdetails = data;
      this.record  = data.length;
      // this.data = data;
      // this.record = data.length;
    });
  }

  selectFile(event)
  {
      var files = event.target.files;
      var selectedFiles = files[0];
      this.selectedFiles = selectedFiles;
      const file = this.selectedFiles;
      this.fileformaterror = false;
      this.filesizeerror = false;
      
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        this.alerts.success('File size must be less than 2 MB.',true); 
        return;
      }
      else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") ) {
        this.fileformaterror = true;
        this.alerts.success('Please choose a valid file format (jpg, png, ttf, gif)',true);         
        return;
      }
      var imagepath = this.uploadService.uploadfile(file); 
      this.passportprofileimage = '../../../../../../../assets/img/loadings.gif';
      // this.passportdetails.employee.imagename  = imagepath;
      // console.log(this.employeemasterid);
      var forupdate = { 
        'passportprofileimage': imagepath,
        'id': this.employeepassportdeatilid,
        'createby': this.userId
      };
      this.passportService.mypassportdetailsUpdatebyadmin(forupdate).subscribe((data: any) => 
      {
        setTimeout(() => this.passportprofileimage = imagepath, 3000);
      });     
  }

  onSubmit()
  {
    var checkData = {"employeepassdeatilid": this.employeepassportdeatilid}
    this.passportService.passportdetailsbypassportid(checkData).subscribe((data: any) => { 
      if(data.passportprofileimage)
      {
        this.router.navigate(['/passportaipaymentdetails/' + this.employeepassportdeatilid]);
      } else{
        var forupdate = { 'passportprofileimage': this.passportprofileimage, 'id': this.employeepassportdeatilid, 'createby': this.userId };
        this.passportService.mypassportdetailsUpdatebyadmin(forupdate).subscribe((data: any) => {
          this.router.navigate(['/passportaipaymentdetails/' + this.employeepassportdeatilid]);
        });     
      }
    });
  }

}