"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var RotaService = /** @class */ (function () {
    function RotaService(commonService) {
        this.commonService = commonService;
    }
    RotaService.prototype.rotaCreateOrUpdate = function (value) {
        return this.commonService.formpost('/employee/create-update-rota', value);
    };
    RotaService.prototype.getEmployeeRota = function (value) {
        return this.commonService.formpost('/employee/get-all-rota-by-employee', value);
    };
    RotaService.prototype.getRotaData = function (value) {
        return this.commonService.formpost('/employee/rota-by-id', value);
    };
    RotaService.prototype.getRotaByDate = function (value) {
        return this.commonService.formpost('/employee/rota-by-date', value);
    };
    RotaService.prototype.getrotabyemployeeanddate = function (value) {
        return this.commonService.formpost('/employee/get-all-rota-by-employee-and-date', value);
    };
    RotaService.ngInjectableDef = i0.defineInjectable({ factory: function RotaService_Factory() { return new RotaService(i0.inject(i1.CommonService)); }, token: RotaService, providedIn: "root" });
    return RotaService;
}());
exports.RotaService = RotaService;
