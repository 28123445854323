<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
      <div class="tabs">
        <div class="tabs__content noshadow">
          <div class="tabs__content__item nobg active-tab">
            <div class="fca_search_box">
             
              <div class="checkshow_btn width100 justify-content-between">
                <app-checkcountshow></app-checkcountshow>               
              </div>
            </div>
            <div class="content">
           
              <div class="fca_list_box_details" *ngIf="companydetails">
                <div class="iconstext">
                  <div class="d-flex justify-content-between align-items-center width100 mb-4">
                    <div class="fcatext">
                      <i class="fa fa-home"></i>
                      <h4>{{companydetails['Organisation Name']}}</h4>
                    </div>
                    <button type="submit" class="btn btn-dark"><i class="fa fa-print"></i> Print Page</button>
                  </div>

                  </div>
                  <span class="ref">
                    Reference number: {{ companydetails.FRN}}
                  </span>
                  <p class="ref_details">This firm is authorised for specific activities and product types. It’s important to check the full record for what regulated activities this firm has permission to do.</p>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <h4>Content</h4>
                    <div class="fca_list">
                        <ul>
                          <li>Who is this firm? <i class="fa fa-angle-right"></i></li>
                          <li>How are customers protected?</li>
                          <li>What can this firm do in the UK?</li>
                          <li>Who is involved with activities at this firm? <i class="fa fa-angle-right"></i></li>
                          <li>What can this firm do in the European Economic Area?</li>
                          <li>Who is this firm connected to?</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-9 borderrefr">
                  <div class="fca_right_content">
                      <h3>Who is this firm?</h3>
                      <div class="detailsref">
                        <div class="accordion" id="accordionExample">
                          <div class="card">
                            <div class="card-header d-flex justify-content-between align-items-center width100" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              <div class="">
                                <h2> Firm details</h2>
                                <p>Check details about this firm's place of business, contact details, etc.</p>

                              </div>
                              <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                              <div class="card-body">
                                <p class="short_des">The FCA expects that a firm will confirm at least annually that these details are accurate.

                                  <b>Details last confirmed: 28 Jun 2021</b></p>

                                  <div class="firm_details_box">
                                    <div class="firm_details">
                                      <h4>Address</h4>
                                      <p>First Floor
                                        Berkeley Square House <br>
                                        Berkeley Square
                                        London<br>
                                        W1J 6BDW 1 J 6 B D
                                        UNITED KINGDOM</p>
                                    </div>
                                    <div class="firm_details">
                                      <h4>Firm reference number</h4>
                                      <p>426749</p>
                                    </div>
                                  </div>
                                  <div class="firm_details_box">
                                    <div class="firm_details">
                                      <h4>Phone</h4>
                                      <p>4402074999040</p>
                                    </div>
                                    <div class="firm_details">
                                      <h4>Email</h4>
                                      <p>info@providentia-capital.com</p>
                                    </div>
                                  </div>
                                  <div class="firm_details_box">
                                    <div class="firm_details">
                                      <h4>Website</h4>
                                      <p>www.providentia-capital.com</p>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div class="card">
                            <div class="card-header d-flex justify-content-between align-items-center width100" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              <div class="">
                                <h2>Firm Satus</h2>
                                <p>Check this firm’s status and any additional regulatory information.</p>
                              </div>
                              <i class="fa fa-angle-down" aria-hidden="true"></i>

                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                              <div class="card-body">
                                <div class="firm_details_box pt-4">
                                  <div class="firm_details">
                                    <h4>Status</h4>
                                    <p>Authorised</p>
                                    <p>Since 12/07/2005</p>
                                    <p class="shortpara">This firm is authorised for <b>specific activities and product types. It’s important to check the full record for what regulated activities this firm has permission to do.</b></p>
                                  </div>
                                  <div class="firm_details">
                                    <h4>Type</h4>
                                    <p>Regulated</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card">
                            <div class="card-header d-flex justify-content-between align-items-center width100" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              <div class="">
                                <h2>Trading names</h2>
                                <p>This firm currently trades under 1 trading names.</p>
                              </div>
                              <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                              <div class="card-body p-4">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">First</th>
                                      <th scope="col">Last</th>
                                      <th scope="col">Handle</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th scope="row">1</th>
                                      <td>Mark</td>
                                      <td>Otto</td>
                                      <td>@mdo</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">2</th>
                                      <td>Jacob</td>
                                      <td>Thornton</td>
                                      <td>@fat</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">3</th>
                                      <td>Larry</td>
                                      <td>the Bird</td>
                                      <td>@twitter</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>


                          <div class="card">
                            <div class="card-header d-flex justify-content-between align-items-center width100" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              <div class="">
                                <h2>Trading names</h2>
                                <p>This firm currently trades under 1 trading names.</p>
                              </div>
                              <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                              <div class="card-body p-4">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">First</th>
                                      <th scope="col">Last</th>
                                      <th scope="col">Handle</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th scope="row">1</th>
                                      <td>Mark</td>
                                      <td>Otto</td>
                                      <td>@mdo</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">2</th>
                                      <td>Jacob</td>
                                      <td>Thornton</td>
                                      <td>@fat</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">3</th>
                                      <td>Larry</td>
                                      <td>the Bird</td>
                                      <td>@twitter</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>


                          <div class="card">
                            <div class="card-header d-flex justify-content-between align-items-center width100" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              <div class="">
                                <h2>Individuals</h2>
                                <p>Individuals currently and previously involved in regulated activities at this firm.</p>
                              </div>
                              <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                              <div class="card-body p-4">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Name</th>
                                      <th scope="col">Individual reference number</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Role</th> 
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr  *ngFor="let ap of Induviduals; let i =index">
                                      <th scope="row">{{i+1}}</th>
                                      <td>{{ap.Name}}</td>
                                      <td>{{ap.IRN}}</td>
                                      <td>{{ap.Status}}</td>
                                      <td>@mdo</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">2</th>
                                      <td>Jacob</td>
                                      <td>Thornton</td>
                                      <td>@fat</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">3</th>
                                      <td>Larry</td>
                                      <td>the Bird</td>
                                      <td>@twitter</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>


                          <div class="card" *ngIf="Appointed">
                            <div class="card-header d-flex justify-content-between align-items-center width100" id="headingAppointed" data-toggle="collapse" data-target="#collapseAppointed" aria-expanded="false" aria-controls="collapseAppointed">
                              <div class="">
                                <h2>Appointed representatives and agents</h2>
                                <p>This firm is responsible for regulated activities of the firms listed below.</p>
                              </div>
                              <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </div>
                            <div id="collapseAppointed" class="collapse" aria-labelledby="headingAppointed" data-parent="#accordionAppointed">
                              <div class="card-body p-4" *ngIf="Appointed.CurrentAppointedRepresentatives">

                                <h2>Current</h2>
                                <table class="table">
                                  <thead>
                                    <thead>
                                      <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Firm Name</th>
                                        <th scope="col">Insurance Distribution</th>
                                        <th scope="col">Tied Agent</th>
                                        <th scope="col">EEA Tied Agent</th>
                                        <th scope="col">AR Relationship</th>
                                        <th scope="col">Firm reference number</th>
                                        <th scope="col">Effective from</th>
                                        <th scope="col">Effective to</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let ap of Appointed.CurrentAppointedRepresentatives; let i =index">
                                        <th scope="row">{{i+1}}</th>
                                        <td>{{ap['Name']}}</td>
                                        <td>{{ap['Insurance Distribution']}}</td>
                                        <td>{{ap['Tied Agent']}}</td>
                                        <td>{{ap['EEA Tied Agent']}}</td>
                                        <td>{{ap['Record SubType']}}</td>
                                        <td>{{ap['FRN']}}</td>
                                        <td>{{ap['Effective Date']}}</td>
                                        <td>{{ap['Termination Date']}}</td>
                                        
                                      </tr>
                                   
                                  </tbody>
                                </table>
                              </div>


                              <div class="card-body p-4" *ngIf="Appointed.PreviousAppointedRepresentatives">

                                <h2>Previous</h2>
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Firm Name</th>
                                      <th scope="col">Insurance Distribution</th>
                                      <th scope="col">Tied Agent</th>
                                      <th scope="col">EEA Tied Agent</th>
                                      <th scope="col">AR Relationship</th>
                                      <th scope="col">Firm reference number</th>
                                      <th scope="col">Effective from</th>
                                      <th scope="col">Effective to</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let ap of Appointed.PreviousAppointedRepresentatives; let i =index">
                                      <th scope="row">{{i+1}}</th>
                                      <td>{{ap['Name']}}</td>
                                      <td>{{ap['Insurance Distribution']}}</td>
                                      <td>{{ap['Tied Agent']}}</td>
                                      <td>{{ap['EEA Tied Agent']}}</td>
                                      <td>{{ap['Record SubType']}}</td>
                                      <td>{{ap['FRN']}}</td>
                                      <td>{{ap['Effective Date']}}</td>
                                      <td>{{ap['Termination Date']}}</td>
                                      
                                    </tr>
                                  
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>


                          <div class="card">
                            <div class="card-header d-flex justify-content-between align-items-center width100" id="headingRegulator" data-toggle="collapse" data-target="#collapseRegulator" aria-expanded="false" aria-controls="collapseThree">
                              <div class="">
                                <h2>Regulators</h2>
                                <p>Current and former regulators of this firm.</p>
                              </div>
                              <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </div>
                            <div id="collapseRegulator" class="collapse" aria-labelledby="headingRegulator" data-parent="#headingRegulator">
                              <div class="card-body p-4">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Regulator</th>
                                      <th scope="col">Effective from</th>
                                      <th scope="col">Effective to</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let reg of regulator;let i=index;">
                                      <th scope="row">{{i+1}}</th>
                                      <td>{{reg['Regulator Name']}}</td>
                                      <td><ng-container *ngIf="reg['Effective Date']">
                                        {{reg['Effective Date']}}
                                      </ng-container>
                                        </td>
                                      <td><ng-container *ngIf="reg['Termination Date']">
                                        {{reg['Termination Date']}}
                                      </ng-container>
                                        </td>
                                      <!-- <td>@mdo</td> -->
                                    </tr>
                                   
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
