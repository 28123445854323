"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".questyh[_ngcontent-%COMP%]{display:block!important}.topnavright[_ngcontent-%COMP%]{margin-top:40px}.refnotification[_ngcontent-%COMP%]   .refnotificationbox[_ngcontent-%COMP%]{padding:20px;background:#e2f6ff;border-radius:3px;margin-bottom:40px}.refnotification[_ngcontent-%COMP%]   .refnotificationbox[_ngcontent-%COMP%]   .alignref[_ngcontent-%COMP%]{display:flex;align-items:center;justify-content:space-between;color:#00b0ff;font-weight:600;margin-bottom:10px}.refnotification[_ngcontent-%COMP%]   .refnotificationbox[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{color:#00b0ff}\n/*# sourceMappingURL=right-to-work-app-admin.component.css.map*/"];
exports.styles = styles;
