"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var plan_service_1 = require("../services/plan.service");
var payment_service_1 = require("../services/payment.service");
var plan_model_1 = require("../models/plan.model");
var modal_service_1 = require("../_services/modal.service");
var router_1 = require("@angular/router");
var stripe_angular_1 = require("stripe-angular");
var alert_service_1 = require("../services/alert.service");
var user_service_1 = require("../services/user.service");
var company_service_1 = require("../services/company.service");
var app_global_1 = require("../app.global");
var UpdatestripeplanComponent = /** @class */ (function () {
    //private publishableKey: string = "pk_test_dIWR2H2xFoFj3I8vXg8eS4dw";
    function UpdatestripeplanComponent(formBuilder, modalService, router, alerts, plan, companyService, userService, StripeScriptTag, paymentService) {
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.router = router;
        this.alerts = alerts;
        this.plan = plan;
        this.companyService = companyService;
        this.userService = userService;
        this.StripeScriptTag = StripeScriptTag;
        this.paymentService = paymentService;
        this.planDetail = new plan_model_1.Plan();
        this.publishableKey = app_global_1.AppGlobals.API_STRIPE; // "pk_live_vE86NSZoPYH0MsJIdsYfQ8L6";
    }
    UpdatestripeplanComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.StripeScriptTag.setPublishableKey(this.publishableKey);
        this.planformGroup = this.formBuilder.group({
            coupnsnumber: ['', forms_1.Validators.required]
        });
        var isemail = localStorage.getItem('isemail');
        var planid = localStorage.getItem('planid');
        // if(isemail == null && isemail == '0')
        // {      
        //   this.router.navigate(['/otp']);      
        // }
        // else if(planid != null && planid != '0')
        // {
        //   this.router.navigate(['/dashboard']);            
        // }
        this.companyService.checkstripecustomer().subscribe(function (data) {
            // console.log(data);
            if (data != null) {
                if (data.deleted) {
                    //this.router.navigate(['/create-customer']);
                }
                else {
                    _this.router.navigate(['/dashboard']);
                }
            }
        });
        var type = { "plantype": '0' };
        this.plan.getplanlist(type).subscribe(function (data) {
            // console.log(data);
            _this.planlist = data;
        });
    };
    Object.defineProperty(UpdatestripeplanComponent.prototype, "f", {
        get: function () { return this.planformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    UpdatestripeplanComponent.prototype.onSubmit = function (settingexpensetypedata, id) {
        var _this = this;
        this.submitted = true;
        if (this.planformGroup.invalid) {
            return;
        }
        this.plan.checkcounpons(settingexpensetypedata).subscribe(function (data) {
            if (data.lenght != 0) {
                _this.totaluser = data.totaluser;
                _this.totalused = data.totalused;
                if ((_this.totaluser - _this.totalused) != 0) {
                    _this.message = "Coupons applied successfully";
                    var db = { "couponsnumber": settingexpensetypedata.coupnsnumber };
                    _this.plan.getplanlistwithcoupons(db).subscribe(function (data4) {
                        _this.planlist = data4;
                        _this.modalService.close(id);
                    });
                }
                else {
                    _this.message = "Coupons limit expire";
                }
            }
        });
    };
    UpdatestripeplanComponent.prototype.onStripeInvalid = function (error) {
        // console.log('Validation Error', error)
    };
    UpdatestripeplanComponent.prototype.setStripeToken = function (token, id) {
        var _this = this;
        this.stptoken = token.id;
        var planupdate = { "planid": this.planid };
        // console.log('Stripe token', token);
        var data = {
            "token": token.id,
            "cmonth": token.card.exp_month,
            "cyear": token.card.exp_year,
            "cardnumber": token.card.last4,
            "tokenid": token.card.id,
            "cardname": token.card.brand,
            "country": token.card.country,
            "cstatus": "1",
            "planid": this.planid
        };
        this.paymentService.createCustomer(data).subscribe(function (data) {
            // console.log(data);
            if (data.status == "success") {
                _this.alerts.success("Plan successfully created", true);
                localStorage.setItem('planid', "1");
                localStorage.setItem('isemail', "true");
                _this.router.navigate(['/successful-payment']);
                _this.modalService.close(id);
            }
            else {
                _this.alerts.error("Payment did not succeed", true);
            }
            //   this.paymentService.customerCharge({"token": data.id, "planid": this.planid}).subscribe((data: any) => {
            //     console.log(data);
            //     if(data.status == "succeeded")
            //     {
            //       this.paymentService.createSubscription({"customer": data.customer, "planid": this.planid}).subscribe((data: any) => {
            //         console.log(data);
            //         // this.plan.updatecompanyplan(planupdate).subscribe((data: any) => {   
            //         // });
            //       });
            //     }
            //   });
        });
    };
    UpdatestripeplanComponent.prototype.selectPlanType = function (event) {
        var _this = this;
        // console.log(event);
        var type = { "plantype": event };
        this.plan.getplanlist(type).subscribe(function (data) {
            _this.planlist = data;
        });
    };
    UpdatestripeplanComponent.prototype.onStripeError = function (error) {
        console.error('Stripe error', error);
    };
    UpdatestripeplanComponent.prototype.updateyourplan = function (planid, id) {
        // console.log(planid);
        this.planid = planid;
        this.modalService.open(id);
    };
    UpdatestripeplanComponent.prototype.openModal = function (id) {
        this.buttoninsert = "Apply Coupons";
        this.modalService.open(id);
    };
    UpdatestripeplanComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    UpdatestripeplanComponent.prototype.logout = function () {
        var _this = this;
        if (confirm("Are you sure you want to sign out?")) {
            this.userService.logout().subscribe(function (data) {
                localStorage.removeItem('userToken');
                localStorage.removeItem('userid');
                localStorage.removeItem('emptype');
                localStorage.removeItem('isline');
                localStorage.removeItem('planid');
                localStorage.removeItem('isemail');
                _this.router.navigate(['/login']);
            });
        }
    };
    return UpdatestripeplanComponent;
}());
exports.UpdatestripeplanComponent = UpdatestripeplanComponent;
