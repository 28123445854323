<!-- <div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
      <div class="tabs" id="tabs">
        <div class="tabs__content">
          <div class="tabs__content__item active-tab">
            <div class="content">
              <app-topmenu class="row"></app-topmenu> -->
              <div class="overviewsshow">
                <div class="fldldd">
                  <div class="header width25">
                    <h1 class="headprofile mt-0">Employment</h1>
                  </div>
                  <div class="profile_dtailsshow linemanagersss" *ngIf="otherDetail!=null">
                    <div class="detailsshow"  *ngIf="otherDetail.reportingperson">
                        <span class="">Reporting Manager</span>
                        <p>{{otherDetail.reportingperson.fname}} {{otherDetail.reportingperson.mname}} {{otherDetail.reportingperson.lname}}</p>
                    </div>
                    </div> 

                </div>
                
                  <div class="overview_details emp"> 
                        <div class="profile_dtailsshow" *ngIf="otherDetail!=null">
                            <!-- <div class="detailsshow width25"  *ngIf="otherDetail.reportingperson">
                                <span>Reporting Manager</span>
                                <p>{{otherDetail.reportingperson.fname}} {{otherDetail.reportingperson.mname}} {{otherDetail.reportingperson.lname}}</p>
                            </div> -->
                            <div class="detailsshow width25" *ngIf="otherDetail.location">
                                <span>Company Location</span>
                                <p>  {{otherDetail.location.cityname }}</p>
                            </div>
                            <div class="detailsshow width25">
                                <span>Division</span>
                                <p> {{otherDetail.division.typename }}</p>
                            </div>
                            <div class="detailsshow width20">
                                <span>Department</span>
                                <p> {{otherDetail.department.typename }}</p>
                            </div>
                            <div class="detailsshow width20" *ngIf="otherDetail.client">
                                <span>Client</span>
                                <p>{{otherDetail.client.clientname }} </p>
                            </div>
                            <div class="detailsshow width30" *ngIf="otherDetail.project">
                                <span>Project</span>
                                <p> {{otherDetail.project.projectname }}</p>
                            </div>
                        </div>
                </div>
                <hr/>
                <div class="" *ngFor="let employee2 of employeedetails">
                  <div class="header">
                    <h4>{{employee2.emprole}} ({{employee2.totalExp}})<span class="badge badge-success" *ngIf="employee2.iscurrent == true">Current</span></h4>
                  </div>
                  <div class="overview_details emp">
                        <div class="profile_dtailsshow">
                          <div class="detailsshow width15"  *ngIf="empltype == false" >
                            <span>Line Manager </span>
                            <p>{{employee2.wemployee.fname }}
                              {{employee2.wemployee.mname }} {{employee2.wemployee.lname }}</p>
                        </div>
                            <div class="detailsshow width15" >
                                <span>Hours per week</span>
                                <p>{{employee2.hoursofworks}} </p>
                            </div>
                            <div class="detailsshow width15">
                                <span>Salary per Annum </span>
                                <p> {{employee2.salary}} </p>
                            </div>
                            <div class="detailsshow width10" *ngIf="employee2.flexi">
                                <span>Flexi Work </span>
                                <p> {{employee2.flexi.startdate| date: 'dd MMM yyyy'}} To
                                  {{employee2.flexi.enddate| date: 'dd MMM yyyy'}}</p>
                            </div>
                            <div class="detailsshow width10"  *ngIf="!employee2.flexi">
                                <span>Flexi Work</span>
                                <p> No</p>
                            </div>
                            <div class="detailsshow width15">
                                <span>Start Date</span>
                                <p>{{employee2.startdate | date: 'dd MMM yyyy'}}</p>
                            </div>
                            <div class="detailsshow width15" *ngIf="employee2.enddate">
                                <span>End Date</span>
                                <p> 
                                   <ng-container *ngIf="employee2.iscurrent==true">Current</ng-container>
                                <ng-container *ngIf="employee2.iscurrent==false">
                                  {{employee2.enddate| date: 'dd MMM yyyy'}}
                                </ng-container>
                              </p>
                            </div>
                            <div class="detailsshow width15 text-center"  *ngIf="employee2.employmentcontract != null">
                              <span>Employment Contract</span>
                              <p> 
                                <a (click)="openModal3('pdf-image-viewer',employee2.employmentcontract)">
                                <i class="fa fa-file-image-o"></i>
                              </a>
                            </p>
                          </div>
                          <div class="detailsshow width15 text-center" *ngIf="employee2.rwork != null">
                            <span>Right To Work</span>
                            <p> <a (click)="openModal3('pdf-image-viewer',employee2.rwork)">
                              <i class="fa fa-file-image-o"></i>
                            </a>
                          </p>
                        </div>
                        </div>    
                </div>
                </div>
                <div class="job_list_section visa_request_section" *ngIf="otherDetail == null">
                    <div class="job_detils_date">
                        <p class="job_profile">No Record Found</p>
                    </div>
                </div>              
              </div>
              <!-- <div class="row">
                  <div class="col-lg-12">
                    <h1 class="headprofile">Employment</h1>
                  </div>                 
             </div> -->
              <div class="row row-sm"> 
                  <!-- <div class="col-lg-4" *ngIf="otherDetail == null">
                      <div class="billed-to">
                          No Record Found
                        </div>
                  </div> -->
                  <!-- <div class="row " *ngIf="otherDetail!=null">
                    <div class="col-lg-5 mb-4">
                      <div class="billed-to">
                          <p *ngIf="otherDetail.reportingperson"><b>Reporting Manager:</b> {{otherDetail.reportingperson.fname}} {{otherDetail.reportingperson.mname}} {{otherDetail.reportingperson.lname}}</p>  
                        <p *ngIf="otherDetail.location"><b>Company Location:</b> {{otherDetail.location.cityname }}</p>
                      </div>
                    </div>
                    <div class="col-lg-3 mb-4">
                      <div class="billed-to">
                        <p><b>Division:</b> {{otherDetail.division.typename }}</p>
                        <p><b>Department:</b> {{otherDetail.department.typename }}</p>
                      </div>
                    </div>
                    <div class="col-lg-4 mb-4">
                      <div class="billed-to">
                        <p *ngIf="otherDetail.client"><b>Client:</b> {{otherDetail.client.clientname }}</p>
                        <p *ngIf="otherDetail.project"><b>Project:</b> {{otherDetail.project.projectname }} </p>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="row" >
                    <div class="col-md-6 marg_bott" *ngFor="let employee2 of employeedetails">
                      <div class="billed-to">
                        <h6 class="tx-gray-800">{{employee2.emprole}} ({{employee2.totalExp}})
                          <span style="color:green" *ngIf="employee2.iscurrent == true">(Current)</span>
                        </h6>
                        <p *ngIf="empltype == false">
                          <b>Line Manager :</b> {{employee2.wemployee.fname }}
                          {{employee2.wemployee.mname }} {{employee2.wemployee.lname }}</p>
                        <p><b>Hours per week :</b><span>{{employee2.hoursofworks}} </span></p>
                        <p><b>Salary per Annum :</b><span>{{employee2.salary}} </span></p>
                        <p *ngIf="employee2.flexi"><b>Flexi Work :</b>
                          <span>{{employee2.flexi.startdate| date: 'dd MMM yyyy'}} To
                            {{employee2.flexi.enddate| date: 'dd MMM yyyy'}}</span>
                        </p>
                        <p *ngIf="!employee2.flexi"><b>Flexi Work :</b><span>No</span></p>
                        <p *ngIf="employee2.employmentcontract != null"><b>Employment Contract : </b>
                          <a (click)="openModal3('pdf-image-viewer',employee2.employmentcontract)">
                            <i class="fa fa-file-image-o mg-r-10"></i>
                          </a>
                        </p>
                        <p *ngIf="employee2.rwork != null"><b>Right To Work : </b>
                          <a (click)="openModal3('pdf-image-viewer',employee2.rwork)">
                            <i class="fa fa-file-image-o mg-r-10"></i>
                          </a>
                        </p>
                        <p><b>Start Date :</b><span>{{employee2.startdate | date: 'dd MMM yyyy'}} </span></p>
                        <p><b>End Date :</b><span>
                            <ng-container *ngIf="employee2.iscurrent==true">
                              Current
                            </ng-container>
                            <ng-container *ngIf="employee2.iscurrent==false">
                              {{employee2.enddate| date: 'dd MMM yyyy'}}
                            </ng-container>
                          </span></p>
                      </div>
                    </div>
                  </div> -->
              </div>
<!--               
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <script src="assets/js/index.js"></script>
</div> -->

<jw-modal id="pdf-image-viewer" class="modal" role="dialog">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
          (click)="closeModal('pdf-image-viewer');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <iframe *ngIf="imgempsrc == false" [src]="iframeempsrc" width="100%" height="600" frameborder="0"></iframe>
              <img *ngIf="imgempsrc == true" [src]="iframeempsrc" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</jw-modal>