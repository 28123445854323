<mat-tab-group>             
  <mat-tab label="Directors/Shareholders">
    <ng-template matTabContent>
      <div class="summary_sec">
        <div class="row">
          <div class="col-sm-12">
            <div class="keyinfo noheight">
              <div class="row">
                <div class="col-sm-12">
                  <h4>Directors/Shareholders Summary</h4>
                </div>
                <div class="col-sm-6">
                  <div class="infodest">
                    <p>Current Directors</p>
                    <span>{{company.directors.current_directors.length}}</span>
                  </div>
                  <div class="infodest">
                    <p>Persons with Significant Control</p>
                    <span>{{company.directors.active_person_signi_cont.length}}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="infodest">
                    <p>Previous Directors/Company Secretaries</p>
                    <span>{{company.directors.prev_directors.length}}</span>
                  </div>
                  <div class="infodest">
                    <p>Current Secretaries</p>
                    <span>{{company.directors.current_secratories.length}}</span>
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="keyinfo noheight">
              <div class="row">
                <div class="col-sm-12">
                  <h4>Current Directors</h4>
                </div>
                <div class="col-sm-12">
                  <div class="keyinfo noheight" *ngFor="let director of company.directors.current_directors">
                    <div class="row">
                      <div class="col-sm-6">                                      
                        <div class="infodest">
                          <p>Title</p>
                          <span>{{director.title}}</span>
                        </div>
                        <div class="infodest">
                          <p>Name</p>
                          <span>{{director.name}}</span>
                        </div>
                        <div class="infodest">
                          <p>Date of Birth</p>
                          <span>{{(director.dateOfBirth)?(director.dateOfBirth | date: 'MM/dd/yyyy'):'-'}}</span>
                        </div>
                        <div class="infodest">
                          <p>Latest Address</p>
                          <span>{{director.address.street}}, {{director.address.city}}</span>
                        </div>
                        <div class="infodest">
                          <p>Postcode</p>
                          <span>{{director.address.postalCode}}</span>
                        </div>
                      </div>
                      <div class="col-sm-6">                                     
                        <div class="infodest">
                          <p>Function</p>
                          <span *ngFor="let pos of director.positions">{{pos.positionName}}</span>
                        </div>
                        <div class="infodest">
                          <p>Nationality</p>
                          <span>{{director.nationality}}</span>
                        </div>  
                        <div class="infodest">
                          <p>Present Appointments</p>
                          <span>{{director.additionalData.presentAppointments}}</span>
                        </div>
                        <div class="infodest">
                          <p>Appointment Date</p>
                          <span *ngFor="let pos of director.positions">{{pos.dateAppointed | date: 'MM/dd/yyyy'}}</span>
                        </div>
                        <div class="infodest">
                          <p>Occupation</p>
                          <span>{{(director.additionalData.occupation)?director.additionalData.occupation:'-'}}</span>
                        </div>                               
                      </div>
                    </div>
                  </div>
                </div>        
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="keyinfo noheight">
              <div class="row">
                <div class="col-sm-12">
                  <h4>Current Company Secretary</h4>
                </div>
                <div class="col-sm-12">
                  <div class="keyinfo noheight" *ngFor="let director of company.directors.current_secratories">
                    <div class="row">
                      <div class="col-sm-6">                                      
                        <div class="infodest">
                          <p>Title</p>
                          <span>{{director.title}}</span>
                        </div>
                        <div class="infodest">
                          <p>Name</p>
                          <span>{{director.name}}</span>
                        </div>
                        <div class="infodest">
                          <p>Date of Birth</p>
                          <span>{{(director.dateOfBirth)?(director.dateOfBirth | date: 'MM/dd/yyyy'):'-'}}</span>
                        </div>
                        <div class="infodest">
                          <p>Latest Address</p>
                          <span>{{director.address.street}}, {{director.address.city}}</span>
                        </div>
                        <div class="infodest">
                          <p>Postcode</p>
                          <span>{{director.address.postalCode}}</span>
                        </div>
                      </div>
                      <div class="col-sm-6">                                     
                        <div class="infodest">
                          <p>Function</p>
                          <span *ngFor="let pos of director.positions">{{pos.positionName}}</span>
                        </div>
                        <div class="infodest">
                          <p>Nationality</p>
                          <span>{{director.nationality}}</span>
                        </div>  
                        <div class="infodest">
                          <p>Present Appointments</p>
                          <span>{{director.additionalData.presentAppointments}}</span>
                        </div>
                        <div class="infodest">
                          <p>Appointment Date</p>
                          <span *ngFor="let pos of director.positions">{{pos.dateAppointed | date: 'MM/dd/yyyy'}}</span>
                        </div>
                        <div class="infodest">
                          <p>Occupation</p>
                          <span>{{(director.additionalData.occupation)?director.additionalData.occupation:'-'}}</span>
                        </div>                               
                      </div>
                    </div>
                  </div>
                </div>        
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="keyinfo noheight">
              <div class="row">
                <div class="col-sm-12">
                  <h4>Directors/Shareholders Summary</h4>
                </div>
                <div class="col-sm-12">
                  <div class="job_list_section employee_list_section pb-2 pt-2 oddeven" *ngFor="let shareholder of company.directors.top_shareholders" >
                    <div class="job_detils_date width25">
                      <p class="job_address">Name</p>
                      <h5 class="job_profile font_17">{{shareholder.name}}</h5>            
                    </div>
                    <div class="job_detils_date width15">
                      <p class="job_address">Currency</p>
                      <p  class="job_profile">{{shareholder.currency}}</p>             
                    </div>
                    <div class="job_detils_date width15">
                      <p class="job_address">Share Count</p>
                      <p class="job_profile">{{shareholder.totalNumberOfSharesOwned}}</p>
                    </div>        
                    <div class="job_detils_date width15">
                      <p class="job_address">Share Type</p>
                      <p class="job_profile">{{shareholder.shareType}}</p>
                    </div> 
                    <div class="job_detils_date width15">
                      <p class="job_address">Nominal Value</p>
                      <p class="job_profile">1</p>
                    </div> 
                    <div class="job_detils_date width15">
                      <p class="job_address">% of Share Count</p>
                      <p class="job_profile">{{shareholder.percentSharesHeld}}</p>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="PSC">
    <ng-template matTabContent>
      <div class="summary_sec">
        <div class="row">
          <div class="col-sm-12">
            <div class="keyinfo noheight">
              <div class="row">
                <div class="col-sm-12">
                  <h4>PSC Summary</h4>
                </div>
                <div class="col-sm-12">
                  <div class="block_tables_head">
                    <div class="second">
                      <h4>&nbsp;</h4>
                    </div>
                    <div class="first">
                      <h4><span class="circless green"></span> Active</h4>
                    </div>
                    <div class="second">
                      <h4><span class="circless red"></span>Ceased</h4>
                    </div>
                    <div class="second">
                      <h4><span class="circless"></span>Total</h4>
                    </div>
                  </div>
                  <div class="block_tables">
                    <div class="second">
                      <p><span class="circless red"></span> Persons with Significant Control</p>
                    </div>
                    <div class="first">
                      <p>{{company.directors.active_person_signi_cont.length}}</p>
                    </div>
                    <div class="second">
                      <p>{{company.directors.ceased_person_signi_cont.length}}</p>
                    </div>
                    <div class="second">
                      <p>{{company.directors.active_person_signi_cont.length + company.directors.ceased_person_signi_cont.length}}</p>
                    </div>
                  </div>
                  <div class="block_tables">
                    <div class="second">
                      <p><span class="circless red"></span> Protected Persons with Significant Control</p>
                    </div>
                    <div class="first">
                      <p>0</p>
                    </div>
                    <div class="second">
                      <p>0</p>
                    </div>
                    <div class="second">
                      <p>0</p>
                    </div>
                  </div>
                  <div class="block_tables">
                    <div class="second">
                      <p><span class="circless green"></span> Statements</p>
                    </div>
                    <div class="first">
                      <p>0</p>
                    </div>
                    <div class="second">
                      <p>0</p>
                    </div>
                    <div class="second">
                      <p>0</p>
                    </div>
                  </div>
                </div>        
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="keyinfo noheight">
              <div class="row">
                <div class="col-sm-12">
                  <h4>Active Persons With Significant Control</h4>
                </div>
                <div class="col-sm-12">
                  <div class="keyinfo noheight" *ngFor="let act_psc of company.directors.active_person_signi_cont">
                    <div class="row">
                      <div class="col-sm-6">                                      
                        <div class="infodest">
                          <p>Name</p>
                          <span>{{act_psc.title}} {{act_psc.name}}</span>
                        </div>
                        <div class="infodest">
                          <p>Address</p>
                          <span>{{act_psc.address.simpleValue}}</span>
                        </div>
                        <div class="infodest">
                          <p>Post Code</p>
                          <span>{{act_psc.address.postalCode}}</span>
                        </div>
                        <div class="infodest">
                          <p>Date of Birth</p>
                          <span>{{act_psc.dateOfBirth | date: 'MM/dd/yyyy'}}</span>
                        </div>
                        <div class="infodest">
                          <p>Nature Of Control</p>
                          <ul class="nopadlss">
                            <li *ngFor="let noc of act_psc.natureOfControl.split(',')">{{noc.replaceAll('-', ' ')}}</li>
                          </ul>
                        </div>
                        
                      </div>
                      <div class="col-sm-6">                                     
                        <div class="infodest">
                          <p>Kind</p>
                          <span>{{act_psc.kind.replaceAll('-', ' ')}}</span>
                        </div>
                        <div class="infodest">
                          <p>Country of Residence</p>
                          <span>{{act_psc.countryOfResidence}}</span>
                        </div>  
                        <div class="infodest">
                          <p>Nationality</p>
                          <span>{{act_psc.nationality}}</span>
                        </div>
                        <div class="infodest">
                          <p>Notified On</p>
                          <span>{{act_psc.notifiedOn | date: 'MM/dd/yyyy'}}</span>
                        </div>                               
                      </div>
                    </div>
                  </div>
                  <p *ngIf="!company.directors.active_person_signi_cont.length">No information to display</p> 
                </div>        
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="keyinfo noheight">
              <div class="row">
                <div class="col-sm-12">
                  <h4>Ceased Persons With Significant Control</h4>
                </div>
                <div class="col-sm-12">
                  <div class="keyinfo noheight">
                    <div class="row">
                      <div class="col-sm-12">   
                        <div class="keyinfo noheight" *ngFor="let act_psc of company.directors.ceased_person_signi_cont">
                          <div class="row">
                            <div class="col-sm-6">                                      
                              <div class="infodest">
                                <p>Name</p>
                                <span>{{act_psc.title}} {{act_psc.name}}</span>
                              </div>
                              <div class="infodest">
                                <p>Address</p>
                                <span>{{(act_psc.address)?act_psc.address.simpleValue:'-'}}</span>
                              </div>
                              <div class="infodest">
                                <p>Post Code</p>
                                <span>{{(act_psc.address)?act_psc.address.postalCode:'-'}}</span>
                              </div>
                              <div class="infodest">
                                <p>Date of Birth</p>
                                <span>{{act_psc.dateOfBirth | date: 'MM/dd/yyyy'}}</span>
                              </div>
                              <div class="infodest">
                                <p>Nature Of Control</p>
                                <ul *ngIf="act_psc.natureOfControl">
                                  <li *ngFor="let noc of act_psc.natureOfControl.split(',')">{{noc.replaceAll('-', ' ')}}</li>
                                </ul>
                                <span *ngIf="!act_psc.natureOfControl">-</span>
                              </div>
                            </div>
                            <div class="col-sm-6">                                     
                              <div class="infodest">
                                <p>Kind</p>
                                <span>{{act_psc.kind.replaceAll('-', ' ')}}</span>
                              </div>
                              <div class="infodest">
                                <p>Country of Residence</p>
                                <span>{{act_psc.countryOfResidence}}</span>
                              </div>  
                              <div class="infodest">
                                <p>Nationality</p>
                                <span>{{act_psc.nationality}}</span>
                              </div>
                              <div class="infodest">
                                <p>Notified On</p>
                                <span>{{act_psc.notifiedOn | date: 'MM/dd/yyyy'}}</span>
                              </div>                               
                            </div>
                          </div>
                        </div>
                        <p *ngIf="!company.directors.ceased_person_signi_cont.length">No information to display</p>                                   
                      </div>
                    </div>
                  </div>
                </div>        
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="keyinfo noheight">
              <div class="row">
                <div class="col-sm-12">
                  <h4>Active Protected Persons With Significant Control</h4>
                </div>
                <div class="col-sm-12">
                  <div class="keyinfo noheight">
                    <div class="row">
                      <div class="col-sm-12">   
                        <p>No information to display</p>                                   
                      </div>
                    </div>
                  </div>
                </div>        
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="keyinfo noheight">
              <div class="row">
                <div class="col-sm-12">
                  <h4>Ceased Protected Persons With Significant Control</h4>
                </div>
                <div class="col-sm-12">
                  <div class="keyinfo noheight">
                    <div class="row">
                      <div class="col-sm-12">   
                        <p>No information to display</p>                                   
                      </div>
                    </div>
                  </div>
                </div>        
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="keyinfo noheight">
              <div class="row">
                <div class="col-sm-12">
                  <h4>Active Statements</h4>
                </div>
                <div class="col-sm-12">
                  <div class="keyinfo noheight">
                    <div class="row">
                      <div class="col-sm-12">   
                        <p>No information to display</p>                                   
                      </div>
                    </div>
                  </div>
                </div>        
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="keyinfo noheight">
              <div class="row">
                <div class="col-sm-12">
                  <h4>Ceased Statements</h4>
                </div>
                <div class="col-sm-12">
                  <div class="keyinfo noheight">
                    <div class="row">
                      <div class="col-sm-12">   
                        <p>No information to display</p>                                   
                      </div>
                    </div>
                  </div>
                </div>        
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab label="Previous Directors/Company Secretaries">
    <ng-template matTabContent>
      <div class="summary_sec">
        <div class="row">
          <div class="col-sm-12">
            <div class="keyinfo noheight">
              <div class="row">
                <div class="col-sm-12">
                  <h4>Directors/Shareholders Summary</h4>
                </div>
                <div class="col-sm-6">
                  <div class="infodest">
                    <p>Current Directors</p>
                    <span>{{company.directors.current_directors.length}}</span>
                  </div>
                  <div class="infodest">
                    <p>Persons With Significant Control</p>
                    <span>{{company.directors.active_person_signi_cont.length}}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="infodest">
                    <p>Previous Directors/Company Secretaries</p>
                    <span>{{company.directors.prev_directors.length}}</span>
                  </div>
                  <div class="infodest">
                    <p>Current Secretaries</p>
                    <span>{{company.directors.current_secratories.length}}</span>
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="keyinfo noheight">
              <div class="row">
                <div class="col-sm-12">
                    <h4>Previous Directors</h4>
                </div>
                <div class="col-sm-12" *ngIf="company.directors.prev_directors.length">
                  <div class="block_tables_head">
                    <div class="second">
                      <h4>Name</h4>
                    </div>
                    <div class="second">
                      <h4><span class="circless green"></span>Current Active Appointments</h4>
                    </div>
                    <div class="second">
                      <h4><span class="circless"></span>Previous Directorships</h4>
                    </div>
                    <div class="second">
                      <h4><span class="circless red"></span>Dissolved Directorships</h4>
                    </div>
                  </div>
                  <div class="block_tables" *ngFor="let prev_dir of company.directors.prev_directors">
                    <div class="second">
                      <p>{{prev_dir.name}}</p>
                    </div>
                    <div class="second">
                      <p>{{prev_dir.additionalData.presentAppointments}}</p>
                    </div>
                    <div class="second">
                      <p>{{prev_dir.additionalData.previousAppointments}}</p>
                    </div>
                    <div class="second">
                      <p>{{prev_dir.additionalData.dissolvedAppointments}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" *ngIf="!company.directors.prev_directors.length">
                  <p>No information to display</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>