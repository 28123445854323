"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var ProjectService = /** @class */ (function () {
    function ProjectService(commonService) {
        this.commonService = commonService;
    }
    ProjectService.prototype.getprojectlist = function (data) {
        return this.commonService.formpost('/projectlist', data);
    };
    ProjectService.prototype.getprojectlistbyclient = function (data) {
        return this.commonService.formpost('/projectlistbyclient', data);
    };
    ProjectService.prototype.projectdetailsUpdate = function (value) {
        return this.commonService.formpost('/projectupdate', value);
    };
    ProjectService.prototype.projectdetailsUpdatestatusbyadmin = function (value) {
        return this.commonService.formpost('/projectupdatestatusbyadmin', value);
    };
    ProjectService.prototype.deleteProject = function (value) {
        return this.commonService.formpost('/projectdeletebyadmin', value);
    };
    ProjectService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectService_Factory() { return new ProjectService(i0.inject(i1.CommonService)); }, token: ProjectService, providedIn: "root" });
    return ProjectService;
}());
exports.ProjectService = ProjectService;
