"use strict";
// import { Component, OnInit } from '@angular/core';
Object.defineProperty(exports, "__esModule", { value: true });
// @Component({
//   selector: 'app-reference',
//   templateUrl: './reference.component.html',
//   styleUrls: ['./reference.component.css']
// })
// export class ReferenceComponent implements OnInit {
//   tab1: any = false;
//   tab2: any = false;
//   tab3: any = false;
//   constructor() { }
//   ngOnInit() {
//     this.tab1 = true;
//   }
//   toggle(id) {
//     if (id == 1) {
//       this.tab1 = true;
//       this.tab2 = false;
//       this.tab3 = false;
//     }
//     if (id == 2) {
//       this.tab1 = false;
//       this.tab2 = true;
//       this.tab3 = false;
//     }
//     if (id == 3) {
//       this.tab1 = false;
//       this.tab2 = false;
//       this.tab3 = true;
//     }
//   }
// }
var core_1 = require("@angular/core");
// import { AppConfig } from "../../../app.config";
var reference_service_1 = require("../services/reference.service");
// import { ReferencingmoduleService } from 'src/app/services/common/referencingmodule/referencingmodule.service';
var forms_1 = require("@angular/forms");
var ngx_toastr_1 = require("ngx-toastr");
var jspdf_1 = require("jspdf");
var ReferenceComponent = /** @class */ (function () {
    function ReferenceComponent(formBuilder, referencingmoduleService, toastrService) {
        this.formBuilder = formBuilder;
        this.referencingmoduleService = referencingmoduleService;
        this.toastrService = toastrService;
        this.refereeresponse = {};
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = false;
        this.showRefreeDetails = false;
        this.showLinkedIn = true;
        this.options = this.toastrService.toastrConfig;
    }
    ReferenceComponent.prototype.ngOnInit = function () {
        // this.tab1 = true;
        // this.loadData();
        // this.activereferencerequest=0;
        // this.activereferee=-1;
        this.ctype = parseInt(localStorage.getItem('ctype'));
    };
    ReferenceComponent.prototype.loadData = function () {
        var companyid = JSON.parse(localStorage.getItem("User_Data")).companyid;
        var compuserid = JSON.parse(localStorage.getItem("User_Data")).userid;
        // var token = localStorage.getItem("User_Token");
        // this.referencingmoduleService.getcompanydetail(token,companyid).subscribe((response: any)=>{
        //   this.companyinfo = response;     
        // });
        // this.referencingmoduleService.userdetailget(compuserid).subscribe((response: any)=>{
        //   this.usercompanyinfo = response;     
        // });
        this.getreferencerequestall(companyid, compuserid, null, null);
        this.disablepdftemplate = true;
    };
    ReferenceComponent.prototype.filterbycandidatesname = function (candidatesname) {
        var companyid = JSON.parse(localStorage.getItem("User_Data")).companyid;
        var compuserid = JSON.parse(localStorage.getItem("User_Data")).userid;
        var token = localStorage.getItem("User_Token");
        this.getreferencerequestall(companyid, compuserid, null, candidatesname);
    };
    ReferenceComponent.prototype.onfilterchange = function (order) {
        var companyid = JSON.parse(localStorage.getItem("User_Data")).companyid;
        var compuserid = JSON.parse(localStorage.getItem("User_Data")).userid;
        var token = localStorage.getItem("User_Token");
        this.getreferencerequestall(companyid, compuserid, order, null);
    };
    ReferenceComponent.prototype.getreferencerequestall = function (companyid, compuserid, order, candidatesname) {
        var _this = this;
        var data = { order: order, candidatesname: candidatesname };
        this.referencingmoduleService.getreferencerequestall(companyid).subscribe(function (response) {
            _this.referencerequests = response;
            if (_this.referencerequests.length > 0) {
                _this.getcandidateinfo(0);
                _this.refereeinforandresponse(_this.referencerequests[0].id);
                _this.selectedreferencerequest = _this.referencerequests[0];
            }
        });
    };
    ReferenceComponent.prototype.deletereference = function () {
        // this.referencingmoduleService.deletereferencerequest(this.selectedreferencerequest.id).subscribe((response: any)=>{
        //   let options = JSON.parse("{\"maxOpened\":0,\"autoDismiss\":false,\"newestOnTop\":true,\"preventDuplicates\":false,\"countDuplicates\":false,\"resetTimeoutOnDuplicate\":false,\"includeTitleDuplicates\":false,\"iconClasses\":{\"error\":\"toast-error\",\"info\":\"toast-info\",\"success\":\"toast-success\",\"warning\":\"toast-warning\"},\"closeButton\":true,\"disableTimeOut\":false,\"timeOut\":5000,\"extendedTimeOut\":1000,\"enableHtml\":false,\"progressBar\":true,\"toastClass\":\"ngx-toastr\",\"positionClass\":\"toast-top-center\",\"titleClass\":\"toast-title\",\"messageClass\":\"toast-message\",\"easing\":\"ease-in\",\"easeTime\":300,\"tapToDismiss\":true,\"onActivateTick\":false,\"progressAnimation\":\"decreasing\"}");
        //     this.openToast('Reference request is successfully deleted', '', options, ['success']);
        //     this.loadData();
        // });
        var data = {
            "declinedbyuser": "Yes",
            "declinedbyuserrreason": "Request owner declined",
            id: this.selectedreferencerequest.id
        };
        var whereclause = "{\"id\":\"" + this.selectedreferencerequest.id + "\"}";
        this.referencingmoduleService.updatereferencerequest(whereclause).subscribe(function (response) {
        });
    };
    ReferenceComponent.prototype.openToast = function (message, title, options, type) {
        //const opt = JSON.parse(JSON.stringify(this.options));  if we use ngModel as in template
        this.toastrService[type](message, title, options);
    };
    ReferenceComponent.prototype.SavePDFsinglereferee = function (refereedet) {
        refereedet.jobstartdate = refereedet.jobstartdate.split('T')[0];
        refereedet.jobenddate = refereedet.jobenddate == null ? 'Present' : refereedet.jobenddate.split('T')[0];
        this.selectedrefereedetail = refereedet;
        // let content=this.content.nativeElement;  
        // let doc = new jsPDF();  
        // let _elementHandlers =  
        // {  
        //   '#editor':function(element,renderer){  
        //     return true;  
        //   }  
        // };  
        // doc.html(content.innerHTML,{
        //   callback: function (doc) {
        //     doc.save('test1.pdf');
        //   },
        //   x: 10,
        //   y: 10
        // });  
        // //doc.save('test.pdf');  
        var doc = new jspdf_1.jsPDF();
        doc.setFontSize(13);
        doc.setFont("times", "bold");
        doc.setTextColor('#000000');
        doc.text("Private and confidential reference report", 68, 40);
        doc.setTextColor('#808080');
        doc.text("Generated by Complygate STAGING", 71, 48);
        doc.setFontSize(15);
        doc.setFillColor('#E6F2FF');
        doc.rect(9, 57, 191, 13, 'F');
        doc.setTextColor('#40E0D0');
        doc.text("CANDIDATE DETAILS", 81, 65);
        doc.setFontSize(12);
        doc.setTextColor('#000000');
        doc.text(this.selectedreferencerequest.candidatename, 12, 90);
        doc.setTextColor('#808080');
        doc.text(this.selectedreferencerequest.candidateemail, 12, 98);
        doc.setTextColor('#808080');
        doc.text(this.selectedreferencerequest.candidatephone, 12, 106);
        doc.setTextColor('#000000');
        doc.text("" + this.selectedreferencerequest.numberofreferee, 150, 104);
        doc.setTextColor('#808080');
        doc.text("references provided", 154, 104);
        doc.setTextColor('#808080');
        doc.text("Each reference is shown below on a separate page.", 63, 134);
        doc.line(8, 155, 197, 155);
        doc.addPage();
        doc.setFont("times", "bold");
        doc.setFillColor('#1A0033');
        doc.rect(9, 6, 191, 12, 'F');
        doc.setTextColor('#40E0D0');
        doc.text("REFERENCE 1", 90, 14);
        doc.setFontSize(12);
        doc.setTextColor('#000000');
        doc.text(refereedet.refereedesignation, 50, 27);
        doc.text(refereedet.refereecompanyname, 50, 33);
        doc.text(refereedet.jobstartdate + "   -   " + refereedet.jobenddate, 118, 30);
        doc.setFontSize(15);
        doc.setFillColor('#E6F2FF');
        doc.rect(9, 48, 191, 17, 'F');
        doc.setTextColor('#40E0D0');
        doc.text("REFEREE DETAILS", 85, 54);
        doc.setTextColor('#000000');
        doc.text("Professional Reference", 83, 60);
        doc.setFontSize(12);
        doc.setTextColor('#000000');
        doc.text("Referee Name: ", 12, 75);
        doc.setTextColor('#808080');
        doc.text(refereedet.refereename, 42, 75);
        doc.setTextColor('#000000');
        doc.text("Referee Job Title:", 118, 75);
        doc.setTextColor('#808080');
        doc.text(refereedet.refereedesignation, 154, 75);
        doc.setTextColor('#000000');
        doc.text("Referee Email:", 12, 83);
        doc.setTextColor('#808080');
        doc.text(refereedet.refereeemail, 42, 83);
        doc.setTextColor('#000000');
        doc.text("Reference Completed:", 118, 83);
        doc.setTextColor('#808080');
        doc.text("28th June 2021", 162, 83);
        doc.setTextColor('#000000');
        doc.text("Relationship to candidate:", 12, 91);
        doc.setTextColor('#808080');
        doc.text(refereedet.refereedesignationcheck, 62, 91);
        doc.setTextColor('#000000');
        doc.text("Referee Phone:", 118, 91);
        doc.setTextColor('#808080');
        doc.text(refereedet.refereephone, 149, 91);
        doc.setFontSize(15);
        doc.setFillColor('#E6F2FF');
        doc.rect(9, 102, 191, 10, 'F');
        doc.setTextColor('#40E0D0');
        doc.text("REFEREE QUESTIONS", 82, 109);
        var startposition = 123;
        var refereeresponsedetail = this.refereeresponse["" + refereedet.id + refereedet.referencerequestid];
        for (var i = 0; i < refereeresponsedetail.length; i++) {
            if (refereeresponsedetail[i].question != 'Remark') {
                doc.setFontSize(12);
                doc.setTextColor('#000000');
                doc.text(refereeresponsedetail[i].question, 12, startposition);
                doc.setTextColor('#808080');
                doc.text(refereeresponsedetail[i].response, 12, startposition + 7);
                startposition += 17;
                if (startposition > 250) {
                    doc.addPage();
                    startposition = 6;
                }
            }
        }
        // doc.setTextColor('#000000');
        // doc.text("What was their reason for leaving? ",12,140);
        // doc.setTextColor('#808080');
        // doc.text("New employment opportunity ",12,147);
        // doc.setTextColor('#000000');
        // doc.text("Please confirm the general duties and responsibilities of the role ",12,157);
        // doc.setTextColor('#808080');
        // doc.text("FYI",12,164);
        startposition -= 3;
        doc.setFontSize(15);
        doc.setFillColor('#E6F2FF');
        doc.rect(9, startposition, 191, 10, 'F');
        doc.setTextColor('#40E0D0');
        startposition += 7;
        if (startposition > 250) {
            doc.addPage();
            startposition = 6;
        }
        doc.text("ADDITIONAL COMMENTS", 78, startposition);
        doc.setFontSize(12);
        doc.setTextColor('#000000');
        startposition += 7;
        if (startposition > 250) {
            doc.addPage();
            startposition = 6;
        }
        for (var i = 0; i < refereeresponsedetail.length; i++) {
            if (refereeresponsedetail[i].question === 'Remark') {
                doc.text(refereeresponsedetail[i].response, 12, startposition);
            }
        }
        doc.setFontSize(10);
        doc.setTextColor('#808080');
        startposition += 21;
        if (startposition > 250) {
            doc.addPage();
            startposition = 6;
        }
        doc.text(refereedet.refereename + "(" + refereedet.refereeemail + ") has agreed that the information provided is a) accurate and b) they", 15, startposition);
        startposition += 8;
        if (startposition > 250) {
            doc.addPage();
            startposition = 6;
        }
        doc.text("consent under the Data Protection Legislation to this information being disclosed to a third party such as the", 12, startposition);
        startposition += 8;
        if (startposition > 250) {
            doc.addPage();
            startposition = 6;
        }
        doc.text("person to whom this reference applies. Confirmed on", 12, startposition);
        doc.setTextColor('#000000');
        startposition += 8;
        if (startposition > 250) {
            doc.addPage();
            startposition = 6;
        }
        doc.text("28th Jun 2021 at 14:49pm.", 94, startposition);
        startposition += 10;
        if (startposition > 250) {
            doc.addPage();
            startposition = 6;
        }
        doc.line(8, startposition, 197, startposition);
        doc.setFontSize(12);
        doc.setTextColor('#000000');
        startposition += 29;
        if (startposition > 250) {
            doc.addPage();
            startposition = 6;
        }
        doc.text("This reference is not a substitute or replacement for a DBS check", 53, startposition);
        startposition += 10;
        if (startposition > 250) {
            doc.addPage();
            startposition = 6;
        }
        doc.text("Reference collected by www.complygate.co.uk ", 66, startposition);
        doc.save(this.selectedreferencerequest.candidatename + "'s reference by " + refereedet.refereename + ".pdf");
    };
    ReferenceComponent.prototype.refereeinforandresponse = function (referencerequestsid) {
        var _this = this;
        this.referencingmoduleService.getrefereedetail(referencerequestsid).subscribe(function (response) {
            _this.refereedetail = response;
            var data = {};
            var _loop_1 = function (i) {
                data = { referencerequestid: _this.refereedetail[i].referencerequestid, id: _this.refereedetail[i].id };
                _this.referencingmoduleService.getrefereeresponse(data).subscribe(function (response) {
                    _this.refereeresponse["" + _this.refereedetail[i].id + _this.refereedetail[i].referencerequestid] = response;
                });
            };
            for (var i = 0; i < _this.refereedetail.length; i++) {
                _loop_1(i);
            }
        });
    };
    ReferenceComponent.prototype.isEmptyObject = function (obj) {
        return (obj && (Object.keys(obj).length === 0));
    };
    ReferenceComponent.prototype.referencerequestclick = function (e, i) {
        this.activereferencerequest = i;
        //console.log('h');
        this.getcandidateinfo(i);
        this.refereeinforandresponse(this.referencerequests[i].id);
        this.selectedreferencerequest = this.referencerequests[i];
    };
    ReferenceComponent.prototype.getcandidateinfo = function (i) {
        if (this.referencerequests.length > 0) {
            this.candidateinitials = this.getnameinitials(this.referencerequests[i].candidatename);
            this.candidatename = this.referencerequests[i].candidatename;
            this.candidateemail = this.referencerequests[i].candidateemail;
            this.candidatephone = this.referencerequests[i].candidatephone;
        }
    };
    ReferenceComponent.prototype.getnameinitials = function (name) {
        //return name.map((n, i)=>(i==0||i==name.length-1)&&n[0]).filter(n=>n).join("");
        return name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
    };
    ReferenceComponent.prototype.toggle = function (id) {
        if (id == 1) {
            this.tab1 = true;
            this.tab2 = false;
            this.tab3 = false;
        }
        if (id == 2) {
            this.tab1 = false;
            this.tab2 = true;
            this.tab3 = false;
        }
        if (id == 3) {
            this.tab1 = false;
            this.tab2 = false;
            this.tab3 = true;
        }
    };
    ReferenceComponent.prototype.showDetails = function (e, i, refereedet) {
        this.showRefreeDetails = true;
        this.activereferee = i;
        refereedet.jobstartdate = refereedet.jobstartdate.split('T')[0];
        refereedet.jobenddate = refereedet.jobenddate == null ? 'Present' : refereedet.jobenddate.split('T')[0];
        this.selectedrefereedetail = refereedet;
    };
    ReferenceComponent.prototype.getdatediff = function (startdate, enddate) {
        if (enddate === null || enddate === 'Present')
            enddate = new Date();
        return this.calcDate(startdate, enddate);
    };
    ReferenceComponent.prototype.calcDate = function (date1, date2) {
        var d2 = new Date(date2);
        var d1 = new Date(date1);
        var diff = Math.floor(d2.getTime() - d1.getTime());
        var day = 1000 * 60 * 60 * 24;
        var days = Math.floor(diff / day);
        var months = Math.floor(days / 31);
        var years = Math.floor(months / 12);
        // var message = date2.toDateString();
        // message += " was "
        // message += days + " days " 
        // message += months + " months "
        // message += years + " years ago \n"
        return years + " years " + months + " months ";
    };
    return ReferenceComponent;
}());
exports.ReferenceComponent = ReferenceComponent;
