"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_model_1 = require("../../models/employee.model");
var country_service_1 = require("../../services/country.service");
var employee_service_1 = require("../../services/employee.service");
var visa_service_1 = require("../../services/visa.service");
var excel_service_1 = require("../../services/excel.service");
var VisareportComponent = /** @class */ (function () {
    function VisareportComponent(excelService, formBuilder, country, employee, visareport) {
        this.excelService = excelService;
        this.formBuilder = formBuilder;
        this.country = country;
        this.employee = employee;
        this.visareport = visareport;
        this.employeeDetail = new employee_model_1.Employee();
        this.exceldata = [];
    }
    VisareportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.EmployeeForm = this.formBuilder.group({
            emailid: [''],
            phonenumber: [''],
            ninumber: [''],
            minvisastart: [''],
            maxvisastart: [''],
            minvisaexpiry: [''],
            maxvisaexpiry: [''],
            visanumber: [''],
            iscurrent: [''],
            stype: ['']
        });
        var values3 = {};
        this.visareport.getvisalist(values3).subscribe(function (visadetails) {
            _this.data = visadetails;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
    };
    VisareportComponent.prototype.exportAsXLSX = function () {
        var _this = this;
        this.exceldata = [];
        this.data.forEach(function (obj) {
            if (obj.iscurrent == false) {
                var current = "No";
            }
            else if (obj.iscurrent == true) {
                current = "Yes";
            }
            _this.employeevisadata = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "VISA Number": obj.visanumber,
                "Start Date": obj.visastart,
                "End Date": obj.visaexpiry,
                "Is Current": current
            };
            _this.exceldata.push(_this.employeevisadata);
        });
        this.excelService.exportAsExcelFile(this.exceldata, 'sample');
    };
    VisareportComponent.prototype.onSubmit = function (value3) {
        var _this = this;
        this.visareport.getvisalist(value3).subscribe(function (visadetails) {
            _this.data = visadetails;
        });
    };
    return VisareportComponent;
}());
exports.VisareportComponent = VisareportComponent;
