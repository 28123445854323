import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CompanyadvertService } from '../services/companyadvert.service';
import { Companyadverse } from '../models/companyadverse/companyadverse.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as CanvasJS from './../admin/canvasjs.min';
import { DatePipe } from '@angular/common';
import { ModalService } from '../_services/modal.service';
import { ChecksystemService } from '../services/checksystem.service';

import { CountryService } from '../services/country.service';
@Component({
  selector: 'app-companyadvertsearch',
  templateUrl: './companyadvertsearch.component.html',
  styleUrls: ['./companyadvertsearch.component.css']
})
export class CompanyadvertsearchComponent implements OnInit {

  // _el: any;
  countrylist: any;
  advertFormgroup: FormGroup;
  advertDetail: any;
  companies: any;
  defaultVal: any = "";
  error:any =0;
  mode:any = 1;
  company: any;
  financial_chart: any;
  score_history_chart: any;
  limit_history_chart: any;
  number_invoice_chart: any;
  number_invoice_chart1: any;
  percent_invoice_chart: any;
  satisfied_charge_details: any = [];
  outstanding_charge_details: any = [];
  all_charge_details: any = [];
  chargeFlag: any = 0;
  paymentInfoType: any = 0;
  disableBtn:boolean;
  lesssore:boolean;
  companycodemodal:any;
  companynamemodal:any;
  submitted:any;
  hidefiling: any = true;
  imageDocsCopy: any = [];

  constructor(
    private _el: ElementRef,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private companyadvertService: CompanyadvertService,
    public datepipe: DatePipe,
    private modalService: ModalService,     
    private check: ChecksystemService,
    private country: CountryService
  ) { 
    route.params.subscribe(params => {
      // console.log(params);
      if(params['id']){
        this.mode = 1;
        this.getDataByCompanyCodehistory(params['id'], '', 1);
      } else{
        this.mode = 0;
      }
    });
  }

  ngOnInit() {
    this.disableBtn =true;
    this.submitted = false;
    
    var ts  = { 'serviceid': 10};    
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      if(remaindata.remiancheck <= 0)
      {
          this.lesssore =true;
      }
    });

    
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;

  //    this.defaultVal ='GB';
    });

    this.company = {};
    this.company = {
                      profile: {},
                      summary: {
                        company_information: {
                          key_information: {},
                          contact_information: {},
                          additional_information: {},
                          key_financials: [],
                          local_csuk_financials: [],
                          local_gaap_financials: [],
                          commentary: [],
                          enquiries_summary: {}
                        },
                        sic: []
                      },
                      score_limit: {},
                      directors: {
                        current_directors: [],
                        current_secratories: [],
                        prev_directors: [],
                        active_person_signi_cont: [],
                        ceased_person_signi_cont: [],
                        top_shareholders: []
                      },
                      event_history: [],
                      score_history: [],
                      limit_history: [],
                      other_information:{
                        advisors: []
                      },
                      group_structure: [],
                      group_structure_stats: {},
                      group_structure_summary: {},
                      general_info: {
                        charge_details: []
                      },
                      payment_data: {},
                      legal_filings: {
                        trade_debtors: [],
                        total_trade_Amount: 0,
                        ccj_summary: {},
                        ccj: {
                          exact_registered: [],
                          possible_registered: []
                        }
                      },
                      image_documents: []
                  };
    // this.company['summary']['company_information']['key_information'] = [];
    // this.company['summary']['company_information']['contact_information'] = [];
    this.advertDetail = new Companyadverse();
    this.advertFormgroup = this.formBuilder.group({
      companycode: [''],
      companyname: ['', Validators.required],
      safenumber: [''],
      vatnumber: [''],
      phoneNo: [''],
      housenumber: [''],
      Street: [''],
      city: [''],
      postcode: [''],
      companystatus: [''],
      previousName: [''],
      country: ['', Validators.required]
    });

    if(this.score_history_chart == undefined && document.getElementById("score_history_graph") && this.company.score_history) {
      var score_history_data = [{
        type: "line",
        showInLegend: true,
        name: "Unique Visit",
        lineDashType: "dash",
        dataPoints: [
          { x: new Date(2017, 0, 3), y: 510 },
          { x: new Date(2017, 0, 4), y: 560 },
          { x: new Date(2017, 0, 5), y: 540 },
          { x: new Date(2017, 0, 6), y: 558 },
          { x: new Date(2017, 0, 7), y: 544 },
          { x: new Date(2017, 0, 8), y: 693 },
          { x: new Date(2017, 0, 9), y: 657 },
          { x: new Date(2017, 0, 10), y: 663 },
          { x: new Date(2017, 0, 11), y: 639 },
          { x: new Date(2017, 0, 12), y: 673 },
          { x: new Date(2017, 0, 13), y: 660 },
          { x: new Date(2017, 0, 14), y: 562 },
          { x: new Date(2017, 0, 15), y: 643 },
          { x: new Date(2017, 0, 16), y: 570 }
        ]
      }];
      this.render_score_history_graph(score_history_data);
    }
    if(this.limit_history_chart == undefined && document.getElementById("limit_history_graph") && this.company.limit_history) {
      var limits = [];
      this.company.limit_history.forEach(element => {
        limits.push({x: element.date, y: element.companyValue.value})
      });
      var limit_history_data = [{
        type: "line",
        showInLegend: true,
        name: "Unique Visit",
        lineDashType: "dash",
        dataPoints: limits
      }];
      this.render_limit_history_graph(limit_history_data);
    }
    
    if(this.number_invoice_chart == undefined && document.getElementById("invoice_number_graph") && this.company.payment_data) {
      var paymentInfoNumberInvoice = [{
        type: "stackedColumn",
        showInLegend: true,
        color: "#696661",
        name: "Q1",
        dataPoints: [
          { y: 0, x: 'Within Terms' },
          { y: 0, x: '0-30 Days' },
          { y: 0, x: '31-60 Days' },
          { y: 0, x: '61-90 Days' },
          { y: 0, x: '90+ Days' }
        ]
        },
        {        
          type: "stackedColumn",
          showInLegend: true,
          name: "Q2",
          color: "#EDCA93",
          dataPoints: [
            { y: 0, x: 'Within Terms' },
            { y: 0, x: '0-30 Days' },
            { y: 0, x: '31-60 Days' },
            { y: 0, x: '61-90 Days' },
            { y: 0, x: '90+ Days' }
          ]
        }
      ];
      this.renderPaymentInfoNumberInvoiceChart(paymentInfoNumberInvoice);
    }

    if(this.number_invoice_chart1 == undefined && document.getElementById("invoice_number_graph1") && this.company.payment_data) {
      var paymentInfoNumberInvoice1 = [{
        type: "stackedColumn",
        showInLegend: true,
        color: "#409a6a",
        name: "Paid",
        dataPoints: [
          { y: this.company.payment_data.numberOfInvoicesPaidWithinTerms, label: 'Within Terms' },
          { y: this.company.payment_data.numberOfInvoicesPaid1To30Days, label: '0-30 Days' },
          { y: this.company.payment_data.numberOfInvoicesPaid31To60Days, label: '31-60 Days' },
          { y: this.company.payment_data.numberOfInvoicesPaid61To90Days, label: '61-90 Days' },
          { y: this.company.payment_data.numberOfInvoicesPaid91PlusDays, label: '90+ Days' }
        ]
        },
        {        
          type: "stackedColumn",
          showInLegend: true,
          name: "Outstanding",
          color: "#ee2e24",
          dataPoints: [
            { y: this.company.payment_data.numberOfInvoicesOutstandingWithinTerms, label: 'Within Terms' },
            { y: this.company.payment_data.numberOfInvoicesOutstanding1To30Days, label: '0-30 Days' },
            { y: this.company.payment_data.numberOfInvoicesOutstanding31To60Days, label: '31-60 Days' },
            { y: this.company.payment_data.numberOfInvoicesOutstanding61To90Days, label: '61-90 Days' },
            { y: this.company.payment_data.numberOfInvoicesOutstanding91PlusDays, label: '90+ Days' }
          ]
        }
      ];
      console.log(paymentInfoNumberInvoice1);
      this.renderPaymentInfoNumberInvoiceChart1(paymentInfoNumberInvoice1);
    }

    if(this.percent_invoice_chart == undefined && document.getElementById("invoice_percent_graph") && this.company.payment_data){
      var paymentInfoPercentInvoice = [{
        type: "stackedColumn100",
        name: "Real-Time",
        showInLegend: true,
        xValueFormatString: "YYYY",
        yValueFormatString: "#,##0\"%\"",
        dataPoints: [
          { y: 50, label: 'Within Terms' },
          { y: 50, label: '0-30 Days' },
          { y: 50, label: '31-60 Days' },
          { y: 50, label: '61-90 Days' },
          { y: 50, label: '90+ Days' }
        ]
        },
        {        
          type: "stackedColumn",
          showInLegend: true,
          name: "Outstanding",
          color: "#ee2e24",
          dataPoints: [
            { y: 50, label: 'Within Terms' },
            { y: 50, label: '0-30 Days' },
            { y: 50, label: '31-60 Days' },
            { y: 50, label: '61-90 Days' },
            { y: 50, label: '90+ Days' }
          ]
        }
      ];
      this.renderPaymentInfoPercentInvoiceChart(paymentInfoPercentInvoice);
    }

    
  }

  get f() { return this.advertFormgroup.controls; }

  onSubmitAdvert(advert: any){
    this.submitted = true;
    if (this.advertFormgroup.invalid) {
      return;
    }
    this.error =0;
    var dd = { safeNo: advert.safenumber,regNo:advert.companycode, name:advert.companyname, vatNo: advert.vatnumber,phoneNo: advert.phoneNo, houseNo: advert.housenumber, street: advert.Street, city: advert.city, postCode: advert.postcode, countries: advert.country,status : advert.companystatus }

    console.log(dd);
    this.companyadvertService.searchcompany(dd).subscribe((companydata: any) => { 
      
     
      console.log(companydata);
      if(companydata.data.statusCode ==400){
        this.error =1;

      }else
      {
        this.mode =2;
       
      this.companies = companydata.data.body.companies;
      }

      



    });
  }


  getDataByCompanyCodehistory(companycode: any, companyname: any = '', history: any = 0){
    

    this.companycodemodal = companycode;
    this.companynamemodal = companyname;
    localStorage.setItem('companycode', companycode);
    localStorage.setItem('companyname', companyname);


    this.mode = 1;

      if(history){
        this.companyadvertService.getcompanyhistoryview({"companycode": companycode, "companyname":companyname }).subscribe((companydata: any) => { 
          this.bindCompanyData(companydata);
        });
      } else{
        this.companyadvertService.getcompanyadvertdata({"companycode": companycode, "companyname":companyname }).subscribe((companydata: any) => { 
          this.bindCompanyData(companydata);
        });
      }


  
  }


  getDataByCompanyCode(companycode: any, companyname: any = '', history: any = 0){
    

    this.companycodemodal = companycode;
    this.companynamemodal = companyname;
    localStorage.setItem('companycode', companycode);
    localStorage.setItem('companyname', companyname);
    var ts  = { 'serviceid': 10}; 
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => {     
      if(remaindata.remiancheck <= 0){
        this.modalService.open('upgrade-plan');
      } else{
        this.modalService.open('confirm-modal');
        
        // this.mode = 1;

        // if(history){
        //   this.companyadvertService.getcompanyhistoryview({"companycode": companycode, "companyname":companyname }).subscribe((companydata: any) => { 
        //     this.bindCompanyData(companydata);
        //   });
        // } else{
        //   this.companyadvertService.getcompanyadvertdata({"companycode": companycode, "companyname":companyname }).subscribe((companydata: any) => { 
        //     this.bindCompanyData(companydata);
        //   });
        // }
        
      }
      
    });
  }

  getDataByCompanyCodesubmit(companycode: any, companyname: any = '', history: any = 0)
  {
    localStorage.setItem('companycode', companycode);
    localStorage.setItem('companyname', companyname);

    this.modalService.close('confirm-modal');
          this.modalService.close('upgrade-plan');

          
    var ts  = { 'serviceid': 10}; 
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => {     
      if(remaindata.remiancheck <= 0){
        this.modalService.open('upgrade-plan');
      } else{       
        this.mode = 1;
        if(history){

          
          this.companyadvertService.getcompanyhistoryview({"companycode": companycode, "companyname":companyname }).subscribe((companydata: any) => { 
            this.bindCompanyData(companydata);
          });
        } else{
          this.companyadvertService.getcompanyadvertdata({"companycode": companycode, "companyname":companyname }).subscribe((companydata: any) => { 
            this.bindCompanyData(companydata);
          });
        }
        
      }
      
    });


  }

  bindCompanyData(companydata){
    // console.log(companydata.data);
      if(companydata.image_data){
        // console.log(companydata.image_data);
      } else{
        // this.companyadvertService.getcompanyimages({"companycode": companycode, "log_id": companydata.log_id }).subscribe((companyimagedata: any) => { 
        //   console.log(companyimagedata.image_data);
        // });
      }

      if(companydata.data.body.report.companySummary){
        this.company.profile.company_name = companydata.data.body.report.companySummary.businessName;
        this.company.profile.company_number = companydata.data.body.report.companySummary.companyNumber;
        this.company.profile.risk_score = companydata.data.body.report.companySummary.creditRating.providerValue.value;
        this.company.profile.status = companydata.data.body.report.companySummary.companyStatus.status;
        this.company.summary.company_information.key_information.company_number = companydata.data.body.report.companySummary.companyRegistrationNumber;
        this.company.summary.company_information.key_information.company_name = companydata.data.body.report.companySummary.businessName;
        this.company.summary.company_information.key_information.company_status = companydata.data.body.report.companySummary.companyStatus.description;
        this.company.summary.company_information.key_information.sic07_code = (companydata.data.body.report.companySummary.mainActivity.classification=='SIC07')?companydata.data.body.report.companySummary.mainActivity.code:'';
        this.company.summary.company_information.key_information.sic07_desc = (companydata.data.body.report.companySummary.mainActivity.classification=='SIC07')?companydata.data.body.report.companySummary.mainActivity.description:'';
        this.company.summary.company_information.contact_information.tps = companydata.data.body.report.companySummary.businessName;
      }
      
      this.company.profile.international_score = companydata.data.body.report.creditScore.currentCreditRating.commonValue;
      this.company.profile.credit_limit = companydata.data.body.report.creditScore.currentCreditRating.creditLimit.value;
      this.company.profile.contract_limit = (companydata.data.body.report.creditScore.currentContractLimit)?companydata.data.body.report.creditScore.currentContractLimit.value:'';
      
      this.company.profile.dbt = '-';
      this.company.profile.industry_dbt = companydata.data.body.report.paymentData.industryDBT;
      
      this.company.summary.company_information.key_information.incorporation_date = companydata.data.body.report.companyIdentification.basicInformation.companyRegistrationDate;
      if(companydata.data.body.report.companyIdentification.basicInformation.legalForm){
        this.company.summary.company_information.key_information.company_type = companydata.data.body.report.companyIdentification.basicInformation.legalForm.description;
      } else{
        this.company.summary.company_information.key_information.company_type = '-';
      }
      
      this.company.summary.company_information.key_information.vat_number = companydata.data.body.report.companyIdentification.basicInformation.vatRegistrationNumber;
      
    
      this.company.summary.company_information.contact_information.address = companydata.data.body.report.contactInformation.mainAddress.simpleValue;
      this.company.summary.company_information.contact_information.website = companydata.data.body.report.contactInformation.websites;
      this.company.summary.company_information.contact_information.telephone_number = companydata.data.body.report.companyIdentification.basicInformation.contactAddress.telephone;
      
      this.company.summary.company_information.contact_information.other_addresses = companydata.data.body.report.contactInformation.otherAddresses;
      
      this.company.summary.company_information.additional_information.ftse_index = "-";
      if(companydata.data.body.report.shareCapitalStructure){
        this.company.summary.company_information.additional_information.share_capital = companydata.data.body.report.shareCapitalStructure.issuedShareCapital.value;
        this.company.summary.company_information.additional_information.currency = companydata.data.body.report.shareCapitalStructure.issuedShareCapital.currency;
      } else{
        this.company.summary.company_information.additional_information.share_capital = '';
        this.company.summary.company_information.additional_information.currency = '-';
      }
      
      this.company.summary.company_information.additional_information.principal_activity = "-";


      if(companydata.data.body.report.additionalInformation.mortgageDetails)
      {
        this.company.summary.company_information.additional_information.charges = companydata.data.body.report.additionalInformation.mortgageDetails.count;
        // this.company.general_info.charge_details = companydata.data.body.report.additionalInformation.mortgageDetails;
      }

      if(companydata.data.body.report.additionalInformation.mortgageDetails){
        companydata.data.body.report.additionalInformation.mortgageDetails.forEach(element => {
          if(element.dateChargeSatisfied){
            this.satisfied_charge_details.push(element);
          } else{
            this.outstanding_charge_details.push(element);
          }
          this.all_charge_details.push(element);
        });
      }
      this.company.general_info.charge_details = this.all_charge_details;

      if(companydata.data.body.report.additionalInformation.landRegistry){
        this.company.summary.company_information.additional_information.land_registry = companydata.data.body.report.additionalInformation.landRegistry.registeredLandAndProperty;
      } else{
        this.company.summary.company_information.additional_information.land_registry = '-';
      }
      
      this.company.summary.company_information.additional_information.filing_date_of_accounts = companydata.data.body.report.additionalInformation.miscellaneous.filingDateOfAccounts;
      this.company.summary.company_information.additional_information.charity_number = "-";
      this.company.summary.company_information.additional_information.safe_number = companydata.data.body.report.companySummary.companyNumber;
      this.company.summary.company_information.additional_information.accountant = (companydata.data.body.report.otherInformation)?companydata.data.body.report.otherInformation.advisors:[];
      this.company.summary.company_information.additional_information.trade_debtors = "No";
      this.company.summary.company_information.additional_information.trade_creditors = "No";

      this.company.summary.sic = companydata.data.body.report.companyIdentification.activityClassifications;

      if(companydata.data.body.report.financialStatements){
        this.company.summary.company_information.key_financials = companydata.data.body.report.financialStatements;
      }

      if(companydata.data.body.report.localFinancialStatements){
        var local_financials = [];
        local_financials = companydata.data.body.report.localFinancialStatements;
        local_financials.forEach(element => {
          if(element.type=='LocalFinancialsCSUK'){
            this.company.summary.company_information.local_csuk_financials.push(element);
          }
          if(element.type=='LocalFinancialsGAAP'){
            this.company.summary.company_information.local_gaap_financials.push(element);
          }
        });
      }

      this.company.summary.company_information.local_csuk_financials.forEach((element, ind) => {
        if(this.company.summary.company_information.local_csuk_financials.length-1 != ind){
          var deficiation = this.company.summary.company_information.local_csuk_financials[ind+1].profitAndLoss.depreciation-element.profitAndLoss.depreciation;
          if(deficiation<0){
            this.company.summary.company_information.local_csuk_financials[ind].positive = 0;
            deficiation = deficiation*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positive = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_fluctuation = (deficiation)*100/this.company.summary.company_information.local_csuk_financials[ind+1].profitAndLoss.depreciation;

          var tangAsset = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.tangibleAssets-element.balanceSheet.tangibleAssets;
          if(tangAsset<0){
            this.company.summary.company_information.local_csuk_financials[ind].positivetangAsset = 0;
            tangAsset = tangAsset*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positivetangAsset = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_tangibleAssets = (tangAsset)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.tangibleAssets;

          var intangAsset = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.intangibleAssets-element.balanceSheet.intangibleAssets;
          if(intangAsset<0){
            this.company.summary.company_information.local_csuk_financials[ind].positiveintangAsset = 0;
            intangAsset = intangAsset*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positiveintangAsset = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_intangibleAssets = (intangAsset)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.intangibleAssets;

          var totalFixAsset = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalFixedAssets-element.balanceSheet.totalFixedAssets;
          if(totalFixAsset<0){
            this.company.summary.company_information.local_csuk_financials[ind].positivetotalFixAsset = 0;
            totalFixAsset = totalFixAsset*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positivetotalFixAsset = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_totalFixAssets = (totalFixAsset)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalFixedAssets;

          var stock = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.stock-element.balanceSheet.stock;
          if(stock<0){
            this.company.summary.company_information.local_csuk_financials[ind].positivestock = 0;
            stock = stock*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positivestock = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_stock = (stock)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.stock;

          var tradedebt = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.tradeDebtors-element.balanceSheet.tradeDebtors;
          if(tradedebt<0){
            this.company.summary.company_information.local_csuk_financials[ind].positivetradedebt = 0;
            tradedebt = tradedebt*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positivetradedebt = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_tradedebt = (tradedebt)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.tradeDebtors;

          var otherdebt = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.otherDebtors-element.balanceSheet.otherDebtors;
          if(otherdebt<0){
            this.company.summary.company_information.local_csuk_financials[ind].positiveotherdebt = 0;
            otherdebt = otherdebt*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positiveotherdebt = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_otherdebt = (otherdebt)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.otherDebtors;

          var cash = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.cash-element.balanceSheet.cash;
          if(cash<0){
            this.company.summary.company_information.local_csuk_financials[ind].positivecash = 0;
            cash = cash*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positivecash = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_cash = (cash)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.cash;

          if(this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.miscCurrentAssets>0){
            var misCurAsset = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.miscCurrentAssets-element.balanceSheet.miscCurrentAssets;
            if(misCurAsset<0){
              this.company.summary.company_information.local_csuk_financials[ind].positivemisCurAsset = 0;
              misCurAsset = misCurAsset*-1;
            } else{
              this.company.summary.company_information.local_csuk_financials[ind].positivemisCurAsset = 1;
            }
            this.company.summary.company_information.local_csuk_financials[ind].perc_misCurAsset = (misCurAsset)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.miscCurrentAssets;
          }

          var totCurAsset = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalCurrentAssets-element.balanceSheet.totalCurrentAssets;
          if(totCurAsset<0){
            this.company.summary.company_information.local_csuk_financials[ind].positivetotCurAsset = 0;
            totCurAsset = totCurAsset*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positivetotCurAsset = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_totCurAsset = (totCurAsset)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalCurrentAssets;

          var tradeCreditors = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.tradeCreditors-element.balanceSheet.tradeCreditors;
          if(tradeCreditors<0){
            this.company.summary.company_information.local_csuk_financials[ind].positivetradeCreditors = 0;
            tradeCreditors = tradeCreditors*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positivetradeCreditors = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_tradeCreditors = (tradeCreditors)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.tradeCreditors;

          if(this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.bankBorrowingsCurrent>0){
            var bankBorCur = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.bankBorrowingsCurrent-element.balanceSheet.bankBorrowingsCurrent;
            if(bankBorCur<0){
              this.company.summary.company_information.local_csuk_financials[ind].positivebankBorCur = 0;
              bankBorCur = bankBorCur*-1;
            } else{
              this.company.summary.company_information.local_csuk_financials[ind].positivebankBorCur = 1;
            }
            this.company.summary.company_information.local_csuk_financials[ind].perc_bankBorCur = (bankBorCur)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.bankBorrowingsCurrent;
          }

          var othShortTermFin = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.otherShortTermFinance-element.balanceSheet.otherShortTermFinance;
          if(othShortTermFin<0){
            this.company.summary.company_information.local_csuk_financials[ind].positiveothShortTermFin = 0;
            othShortTermFin = othShortTermFin*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positiveothShortTermFin = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_othShortTermFin = (othShortTermFin)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.otherShortTermFinance;

          var misCurLia = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.miscCurrentLiabilities-element.balanceSheet.miscCurrentLiabilities;
          if(misCurLia<0){
            this.company.summary.company_information.local_csuk_financials[ind].positivemisCurLia = 0;
            misCurLia = misCurLia*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positivemisCurLia = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_misCurLia = (misCurLia)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.miscCurrentLiabilities;

          var totCurLia = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalCurrentLiabilities-element.balanceSheet.totalCurrentLiabilities;
          if(totCurLia<0){
            this.company.summary.company_information.local_csuk_financials[ind].positivetotCurLia = 0;
            totCurLia = totCurLia*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positivetotCurLia = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_totCurLia = (totCurLia)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalCurrentLiabilities;

          var bankOveLtl = this.company.summary.company_information.local_csuk_financials[ind+1].otherFinancials.bankOverdraftAndLTL-element.otherFinancials.bankOverdraftAndLTL;
          if(bankOveLtl<0){
            this.company.summary.company_information.local_csuk_financials[ind].positivebankOveLtl = 0;
            bankOveLtl = bankOveLtl*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positivebankOveLtl = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_bankOveLtl = (bankOveLtl)*100/this.company.summary.company_information.local_csuk_financials[ind+1].otherFinancials.bankOverdraftAndLTL;

          if(this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.otherLongTermFinance>0){
            var othLongTermFin = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.otherLongTermFinance-element.balanceSheet.otherLongTermFinance;
            if(othLongTermFin<0){
              this.company.summary.company_information.local_csuk_financials[ind].positiveothLongTermFin = 0;
              othLongTermFin = othLongTermFin*-1;
            } else{
              this.company.summary.company_information.local_csuk_financials[ind].positiveothLongTermFin = 1;
            }
            this.company.summary.company_information.local_csuk_financials[ind].perc_othLongTermFin = (othLongTermFin)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.otherLongTermFinance;
          }

          var totLongTermLia = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalLongTermLiabilities-element.balanceSheet.totalLongTermLiabilities;
          if(totLongTermLia<0){
            this.company.summary.company_information.local_csuk_financials[ind].positivetotLongTermLia = 0;
            totLongTermLia = totLongTermLia*-1;
          } else{
            this.company.summary.company_information.local_csuk_financials[ind].positivetotLongTermLia = 1;
          }
          this.company.summary.company_information.local_csuk_financials[ind].perc_totLongTermLia = (totLongTermLia)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalLongTermLiabilities;

          // this.company.summary.company_information.key_financials.forEach((obj, index) => {
          //   if(obj.yearEndDate == element.yearEndDate){
              // this.company.summary.company_information.local_csuk_financials[ind].calledUpShareCapital = obj.balanceSheet.calledUpShareCapital;
              var callUpShareCap = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.issuedShareCapital-element.balanceSheet.issuedShareCapital;
              if(callUpShareCap<0){
                this.company.summary.company_information.local_csuk_financials[ind].positivecallUpShareCap = 0;
                callUpShareCap = callUpShareCap*-1;
              } else if(callUpShareCap==0){
                this.company.summary.company_information.local_csuk_financials[ind].positivecallUpShareCap = 1;
              } else{
                this.company.summary.company_information.local_csuk_financials[ind].positivecallUpShareCap = -1;
              }
              this.company.summary.company_information.local_csuk_financials[ind].perc_callUpShareCap = (callUpShareCap)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.issuedShareCapital;
          //   }
          // });
          
          if(this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.revenueReserves>0){
            var revReserv = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.revenueReserves-element.balanceSheet.revenueReserves;
            if(revReserv<0){
              this.company.summary.company_information.local_csuk_financials[ind].positiverevReserv = 0;
              revReserv = revReserv*-1;
            } else{
              this.company.summary.company_information.local_csuk_financials[ind].positiverevReserv = 1;
            }
            this.company.summary.company_information.local_csuk_financials[ind].perc_revReserv = (revReserv)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.revenueReserves;
          }

          if(this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.revaluationReserve>0){
            var revalReserve = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.revaluationReserve-element.balanceSheet.revaluationReserve;
            if(revalReserve<0){
              this.company.summary.company_information.local_csuk_financials[ind].positiverevalReserve = 0;
              revalReserve = revalReserve*-1;
            } else if(revalReserve==0){
              this.company.summary.company_information.local_csuk_financials[ind].positiverevalReserve = 1;
            } else{
              this.company.summary.company_information.local_csuk_financials[ind].positiverevalReserve = -1;
            }
            this.company.summary.company_information.local_csuk_financials[ind].perc_revalReserve = (revalReserve)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.revaluationReserve;
          }

          if(this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.otherReserves>0){
            var othReserve = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.otherReserves-element.balanceSheet.otherReserves;
            if(othReserve<0){
              this.company.summary.company_information.local_csuk_financials[ind].positiveothReserve = 0;
              othReserve = othReserve*-1;
            } else if(othReserve==0){
              this.company.summary.company_information.local_csuk_financials[ind].positiveothReserve = 1;
            } else{
              this.company.summary.company_information.local_csuk_financials[ind].positiveothReserve = -1;
            }
            this.company.summary.company_information.local_csuk_financials[ind].perc_othReserve = (othReserve)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.otherReserves;
          }

          if(this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalShareholdersEquity>0){
            var totalShEq = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalShareholdersEquity-element.balanceSheet.totalShareholdersEquity;
            if(totalShEq<0){
              this.company.summary.company_information.local_csuk_financials[ind].positivetotalShEq = 0;
              totalShEq = totalShEq*-1;
            } else{
              this.company.summary.company_information.local_csuk_financials[ind].positivetotalShEq = 1;
            }
            this.company.summary.company_information.local_csuk_financials[ind].perc_totalShEq = (totalShEq)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalShareholdersEquity;
          }

          if(this.company.summary.company_information.local_csuk_financials[ind+1].otherFinancials.netWorth>0){
            var netWorth = this.company.summary.company_information.local_csuk_financials[ind+1].otherFinancials.netWorth-element.otherFinancials.netWorth;
            if(netWorth<0){
              this.company.summary.company_information.local_csuk_financials[ind].positivenetWorth = 0;
              netWorth = netWorth*-1;
            } else{
              this.company.summary.company_information.local_csuk_financials[ind].positivenetWorth = 1;
            }
            this.company.summary.company_information.local_csuk_financials[ind].perc_netWorth = (netWorth)*100/this.company.summary.company_information.local_csuk_financials[ind+1].otherFinancials.netWorth;
          }

          if(this.company.summary.company_information.local_csuk_financials[ind+1].otherFinancials.workingCapital!=0){
            var workCap = this.company.summary.company_information.local_csuk_financials[ind+1].otherFinancials.workingCapital-element.otherFinancials.workingCapital;
            if(workCap<0){
              this.company.summary.company_information.local_csuk_financials[ind].positiveworkCap = 0;
              workCap = workCap*-1;
            } else{
              this.company.summary.company_information.local_csuk_financials[ind].positiveworkCap = 1;
            }
            this.company.summary.company_information.local_csuk_financials[ind].perc_workCap = (workCap)*100/this.company.summary.company_information.local_csuk_financials[ind+1].otherFinancials.workingCapital;
          }

          if(this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalAssets>0){
            var totAsset = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalAssets-element.balanceSheet.totalAssets;
            if(totAsset<0){
              this.company.summary.company_information.local_csuk_financials[ind].positivetotAsset = 0;
              totAsset = totAsset*-1;
            } else{
              this.company.summary.company_information.local_csuk_financials[ind].positivetotAsset = 1;
            }
            this.company.summary.company_information.local_csuk_financials[ind].perc_totAsset = (totAsset)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalAssets;
          }

          if(this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalLiabilities>0){
            var totLia = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalLiabilities-element.balanceSheet.totalLiabilities;
            if(totLia<0){
              this.company.summary.company_information.local_csuk_financials[ind].positivetotLia = 0;
              totLia = totLia*-1;
            } else{
              this.company.summary.company_information.local_csuk_financials[ind].positivetotLia = 1;
            }
            this.company.summary.company_information.local_csuk_financials[ind].perc_totLia = (totLia)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalLiabilities;
          }

          if(this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalShareholdersEquity>0){
            var totShEq = this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalShareholdersEquity-element.balanceSheet.totalShareholdersEquity;
            if(totShEq<0){
              this.company.summary.company_information.local_csuk_financials[ind].positivetotShEq = 0;
              totShEq = totShEq*-1;
            } else{
              this.company.summary.company_information.local_csuk_financials[ind].positivetotShEq = 1;
            }
            this.company.summary.company_information.local_csuk_financials[ind].perc_totShEq = (totShEq)*100/this.company.summary.company_information.local_csuk_financials[ind+1].balanceSheet.totalShareholdersEquity;
          }

          if(this.company.summary.company_information.local_csuk_financials[ind+1].otherFinancials.capitalEmployed>0){
            var capEmp = this.company.summary.company_information.local_csuk_financials[ind+1].otherFinancials.capitalEmployed-element.otherFinancials.capitalEmployed;
            if(capEmp<0){
              this.company.summary.company_information.local_csuk_financials[ind].positivecapEmp = 0;
              capEmp = capEmp*-1;
            } else{
              this.company.summary.company_information.local_csuk_financials[ind].positivecapEmp = 1;
            }
            this.company.summary.company_information.local_csuk_financials[ind].perc_capEmp = (capEmp)*100/this.company.summary.company_information.local_csuk_financials[ind+1].otherFinancials.capitalEmployed;
          }

        }
      });

      this.company.other_information.advisors = (companydata.data.body.report.otherInformation)?companydata.data.body.report.otherInformation.advisors:[];

      this.company.summary.company_information.commentary = companydata.data.body.report.additionalInformation.commentaries;

      if(companydata.data.body.report.additionalInformation.enquiriesTrend){
        this.company.summary.company_information.enquiries_summary.latest_enquiry_date = companydata.data.body.report.additionalInformation.enquiriesTrend.latestEnquiryDate;
        this.company.summary.company_information.enquiries_summary.avg_reports_per_month = companydata.data.body.report.additionalInformation.enquiriesTrend.monthlyAverage;
        this.company.summary.company_information.enquiries_summary.enq_past_3months = companydata.data.body.report.additionalInformation.enquiriesTrend.months1to3;
        this.company.summary.company_information.enquiries_summary.enq_past_6months = this.company.summary.company_information.enquiries_summary.enq_past_3months
                                                                  +companydata.data.body.report.additionalInformation.enquiriesTrend.months4to6;
        this.company.summary.company_information.enquiries_summary.enq_past_9months = this.company.summary.company_information.enquiries_summary.enq_past_6months
                                                                  +companydata.data.body.report.additionalInformation.enquiriesTrend.months7to9;
        this.company.summary.company_information.enquiries_summary.enq_past_12months = this.company.summary.company_information.enquiries_summary.enq_past_9months
                                                                  +companydata.data.body.report.additionalInformation.enquiriesTrend.months10to12;
      
      
        var enquiry_array = [];
        enquiry_array.push({ x: 1, y: this.company.summary.company_information.enquiries_summary.enq_past_3months, label: '3 Months' });
        enquiry_array.push({ x: 2, y: this.company.summary.company_information.enquiries_summary.enq_past_6months, label: '6 Months' });
        enquiry_array.push({ x: 3, y: this.company.summary.company_information.enquiries_summary.enq_past_9months, label: '9 Months' });
          
        var data1 = [
          {
            type: "stackedBar",
            name: "Number of Enqueries",
            showInLegend: "true",
            xValueFormatString: "DD, MMM",
            yValueFormatString: "#0",
            dataPoints: enquiry_array
          }
        ];
        this.render_enquiry_graph(data1);

        var enquiries_trend = companydata.data.body.report.additionalInformation.enquiriesTrend;
        var monthly_data = [
          {   
            type: "spline",  
            name: "Enquiries Summary",        
            showInLegend: true,
            dataPoints: [
              {label:"6-02-16", y: enquiries_trend.month1},     
              {label: "7-02-16", y: enquiries_trend.month2},     
              {label: "8-02-16", y: enquiries_trend.month3}, 
              {label: "9-02-16", y: enquiries_trend.month4}, 
              {label: "10-02-16", y: enquiries_trend.month5},     
              {label: "11-02-16", y: enquiries_trend.month6},     
              {label: "12-02-16", y: enquiries_trend.month7},
              {label: "12-02-16", y: enquiries_trend.month8},
              {label: "12-02-16", y: enquiries_trend.month9},
              {label: "12-02-16", y: enquiries_trend.month10},
              {label: "12-02-16", y: enquiries_trend.month11},
              {label: "12-02-16", y: enquiries_trend.month12}        
            ]
          }
        ];
  
        this.render_monthly_breakdown(monthly_data);
      }

      this.company.score_limit.score = companydata.data.body.report.creditScore.currentCreditRating.providerValue.value;
      this.company.score_limit.change_date = companydata.data.body.report.creditScore.latestRatingChangeDate;
      this.company.score_limit.limit = companydata.data.body.report.creditScore.currentCreditRating.creditLimit.value;
      this.company.score_limit.international_score = companydata.data.body.report.creditScore.currentCreditRating.commonValue;

      // this.company.directors.director_summary.current_directors = 0;
      // this.company.directors.director_summary.current_secratories = 0;
      // this.company.directors.director_summary.prev_directors = 0;
      // this.company.directors.director_summary.person_signi_cont = 0;

      if(companydata.data.body.report.directors.currentDirectors){
        companydata.data.body.report.directors.currentDirectors.forEach((director) => {
          director.positions.forEach((position) => {
            if(position.positionName == 'Director'){
              this.company.directors.current_directors.push(director);
              // this.company.directors.director_summary.current_directors++;
            } else if(position.positionName == 'Company Secretary'){
              this.company.directors.current_secratories.push(director);
              // this.company.directors.director_summary.current_secratories++;
            }
          });
        });
      }

      if(companydata.data.body.report.directors.previousDirectors){
        this.company.directors.prev_directors = companydata.data.body.report.directors.previousDirectors;
      }

      if(companydata.data.body.report.additionalInformation.personsWithSignificantControl){
        if(companydata.data.body.report.additionalInformation.personsWithSignificantControl.activePSC){
          this.company.directors.active_person_signi_cont = companydata.data.body.report.additionalInformation.personsWithSignificantControl.activePSC;
        }
  
        if(companydata.data.body.report.additionalInformation.personsWithSignificantControl.ceasedPSC){
          this.company.directors.ceased_person_signi_cont = companydata.data.body.report.additionalInformation.personsWithSignificantControl.ceasedPSC;
        }
      }
      
      if(companydata.data.body.report.shareCapitalStructure){
        if(companydata.data.body.report.shareCapitalStructure.shareHolders){
          this.company.directors.top_shareholders = companydata.data.body.report.shareCapitalStructure.shareHolders;
        }
      }

      if(companydata.data.body.report.additionalInformation.companyHistory){
        this.company.event_history = companydata.data.body.report.additionalInformation.companyHistory;
      }

      if(companydata.data.body.report.additionalInformation.ratingHistory){
        this.company.score_history = companydata.data.body.report.additionalInformation.ratingHistory;
      }

      // if(companydata.data.body.report.additionalInformation.ratingHistory){
      //   this.company.score_history = companydata.data.body.report.additionalInformation.ratingHistory;
      // }

    
      
      if(companydata.data.body.report.additionalInformation.creditLimitHistory){
        this.company.limit_history = companydata.data.body.report.additionalInformation.creditLimitHistory;
      } else{
        if(companydata.data.body.report.additionalInformation.ratingHistory){
          this.company.limit_history = companydata.data.body.report.additionalInformation.ratingHistory;
        }
      }

      // Group Structure

      if(companydata.data.body.report.extendedGroupStructure){
        this.company.group_structure = companydata.data.body.report.extendedGroupStructure;
      }

      this.company.group_structure_stats.total_group_companies = this.company.group_structure.length;
      this.company.group_structure_stats.total_group_countries = 0;
      this.company.group_structure_summary.holding_company = '';
      this.company.group_structure_summary.ownership_status = '';
      this.company.group_structure_summary.ultimate_holding_company = '';
      var countries_array = [];
      this.company.group_structure.forEach(element => {
        if(!countries_array.includes(element.country)){
          countries_array.push(element.country);
        }
        if(element.level==0){
          if(companydata.data.body.report.companyIdentification.basicInformation.companyRegistrationNumber==element.registeredNumber){
            this.company.group_structure_summary.ownership_status = companydata.data.body.report.companyIdentification.basicInformation.ownershipType;
          }
          this.company.group_structure_summary.holding_company = element.companyName;
          this.company.group_structure_summary.ultimate_holding_company = element.companyName;
        }
      });
      this.company.group_structure_stats.total_group_countries = countries_array.length;
      // console.log(companydata.data.body.report.paymentData);
      this.company.payment_data = companydata.data.body.report.paymentData;

      if(companydata.data.body.report.badDebtDetails){
        this.company.legal_filings.trade_debtors = companydata.data.body.report.badDebtDetails;
        this.company.legal_filings.trade_debtors.forEach(element => {
          this.company.legal_filings.total_trade_Amount += element.amount.value;
        });
      }

      if(companydata.data.body.report.negativeInformation){
        if(companydata.data.body.report.negativeInformation.ccjSummary){
          this.company.legal_filings.ccj_summary = companydata.data.body.report.negativeInformation.ccjSummary;
        }
        if(companydata.data.body.report.negativeInformation.countyCourtJudgements){
          if(companydata.data.body.report.negativeInformation.countyCourtJudgements.registered && companydata.data.body.report.negativeInformation.countyCourtJudgements.registered.exact){
            this.company.legal_filings.ccj.exact_registered = companydata.data.body.report.negativeInformation.countyCourtJudgements.registered.exact;
          }
          if(companydata.data.body.report.negativeInformation.countyCourtJudgements.registered && companydata.data.body.report.negativeInformation.countyCourtJudgements.registered.possible){
            this.company.legal_filings.ccj.possible_registered = companydata.data.body.report.negativeInformation.countyCourtJudgements.registered.possible;
          }
        }
      }
      // console.log(this.company);
      
      if(companydata.image_data.statusCode==200){
        if(companydata.image_data.body.data){
          this.company.image_documents = companydata.image_data.body.data;
          this.imageDocsCopy = companydata.image_data.body.data;
        }
      }
      
      console.log(this.company.image_documents);
  }

  downloadImage(imageId){
    if(imageId){
      this.companyadvertService.downloadcompanyimage({"imageid": imageId }).subscribe((imagedata: any) => { 
        console.log(imagedata);
      });
    }
  }

  ngDoCheck(){
    /* Check https://angular.io/guide/lifecycle-hooks#docheck for informaton about ngDoCheck */
    if(this.financial_chart == undefined && document.getElementById("finacial_chart") && this.company.summary.company_information.key_financials.length){
      var  totalgraphdataarray = [];
      this.company.summary.company_information.key_financials.forEach((obj, index) => {
        totalgraphdataarray.push({ label: this.datepipe.transform(obj.yearEndDate, 'yyyy'), y: obj.balanceSheet.totalShareholdersEquity });
      });
      this.renderKeyFinancialChart(totalgraphdataarray);
    }
   
    if(this.score_history_chart == undefined && document.getElementById("score_history_graph") && this.company.score_history) {
      var scores = [];
      var count = 1;
      this.company.score_history.forEach(element => {
        scores.push({x: new Date(element.date), y: element.companyValue});
        if(count==10){
          return false;
        } else{
          count++;
        }
      });
      var score_history_data = [{
        type: "line",
        showInLegend: true,
        name: "Unique Visit",
        lineDashType: "dash",
        dataPoints: scores
      }];
      this.render_score_history_graph(score_history_data);
    } 

    if(this.limit_history_chart == undefined && document.getElementById("limit_history_graph") && this.company.limit_history) {
      var limits = [];
      this.company.limit_history.forEach(element => {
        limits.push({x: new Date(element.date), y: (element.companyValue.value)?element.companyValue.value:element.companyValue})
      });
      var limit_history_data = [{
        type: "line",
        showInLegend: true,
        name: "Unique Visit",
        lineDashType: "dash",
        dataPoints: limits
      }];
      this.render_limit_history_graph(limit_history_data);
    }
    
    if(this.number_invoice_chart == undefined &&  document.getElementById("invoice_number_graph")  &&  Object.keys(this.company.payment_data).length !== 0){
      var paymentInfoNumberInvoice = [{
        type: "stackedColumn",
        showInLegend: true,
        color: "#409a6a",
        name: "Paid",
        dataPoints: [
          { y: this.company.payment_data.numberOfInvoicesPaidWithinTerms, label: 'Within Terms' },
          { y: this.company.payment_data.numberOfInvoicesPaid1To30Days, label: '0-30 Days' },
          { y: this.company.payment_data.numberOfInvoicesPaid31To60Days, label: '31-60 Days' },
          { y: this.company.payment_data.numberOfInvoicesPaid61To90Days, label: '61-90 Days' },
          { y: this.company.payment_data.numberOfInvoicesPaid91PlusDays, label: '90+ Days' }
        ]
        },
        {        
          type: "stackedColumn",
          showInLegend: true,
          name: "Outstanding",
          color: "#ee2e24",
          dataPoints: [
            { y: this.company.payment_data.numberOfInvoicesOutstandingWithinTerms, label: 'Within Terms' },
            { y: this.company.payment_data.numberOfInvoicesOutstanding1To30Days, label: '0-30 Days' },
            { y: this.company.payment_data.numberOfInvoicesOutstanding31To60Days, label: '31-60 Days' },
            { y: this.company.payment_data.numberOfInvoicesOutstanding61To90Days, label: '61-90 Days' },
            { y: this.company.payment_data.numberOfInvoicesOutstanding91PlusDays, label: '90+ Days' }
          ]
        }
      ];
      this.renderPaymentInfoNumberInvoiceChart(paymentInfoNumberInvoice);
    }

    if(this.number_invoice_chart1 == undefined && document.getElementById("invoice_number_graph1") && Object.keys(this.company.payment_data).length !== 0){
      // console.log(this.company.payment_data);
      var paymentInfoNumberInvoice2 = [{
        type: "stackedColumn",
        showInLegend: true,
        color: "#409a6a",
        name: "Paid",
        dataPoints: [
          { y: this.company.payment_data.numberOfInvoicesPaidWithinTerms, label: 'Within Terms' },
          { y: this.company.payment_data.numberOfInvoicesPaid1To30Days, label: '0-30 Days' },
          { y: this.company.payment_data.numberOfInvoicesPaid31To60Days, label: '31-60 Days' },
          { y: this.company.payment_data.numberOfInvoicesPaid61To90Days, label: '61-90 Days' },
          { y: this.company.payment_data.numberOfInvoicesPaid91PlusDays, label: '90+ Days' }
        ]
        },
        {        
          type: "stackedColumn",
          showInLegend: true,
          name: "Outstanding",
          color: "#ee2e24",
          dataPoints: [
            { y: this.company.payment_data.numberOfInvoicesOutstandingWithinTerms, label: 'Within Terms' },
            { y: this.company.payment_data.numberOfInvoicesOutstanding1To30Days, label: '0-30 Days' },
            { y: this.company.payment_data.numberOfInvoicesOutstanding31To60Days, label: '31-60 Days' },
            { y: this.company.payment_data.numberOfInvoicesOutstanding61To90Days, label: '61-90 Days' },
            { y: this.company.payment_data.numberOfInvoicesOutstanding91PlusDays, label: '90+ Days' }
          ]
        }
      ];
      // console.log('pro');
      // console.log(paymentInfoNumberInvoice2);
      this.renderPaymentInfoNumberInvoiceChart1(paymentInfoNumberInvoice2);
    }

    if(this.percent_invoice_chart == undefined && document.getElementById("invoice_percent_graph") && this.company.payment_data){
      var paymentInfoPercentInvoice = [
        {        
          type: "stackedColumn",
          showInLegend: true,
          name: "Outstanding",
          color: "#ee2e24",
          dataPoints: [
            { y: Math.round(this.company.payment_data.numberOfInvoicesOutstandingWithinTerms*100/(this.company.payment_data.numberOfInvoicesPaidWithinTerms+this.company.payment_data.numberOfInvoicesOutstandingWithinTerms)), label: 'Within Terms' },
            { y: Math.round(this.company.payment_data.numberOfInvoicesOutstanding1To30Days*100/(this.company.payment_data.numberOfInvoicesPaid1To30Days+this.company.payment_data.numberOfInvoicesOutstanding1To30Days)), label: '0-30 Days' },
            { y: Math.round(this.company.payment_data.numberOfInvoicesOutstanding31To60Days*100/(this.company.payment_data.numberOfInvoicesPaid31To60Days+this.company.payment_data.numberOfInvoicesOutstanding31To60Days)), label: '31-60 Days' },
            { y: Math.round(this.company.payment_data.numberOfInvoicesOutstanding61To90Days*100/(this.company.payment_data.numberOfInvoicesPaid61To90Days+this.company.payment_data.numberOfInvoicesOutstanding61To90Days)), label: '61-90 Days' },
            { y: Math.round(this.company.payment_data.numberOfInvoicesOutstanding91PlusDays*100/(this.company.payment_data.numberOfInvoicesPaid91PlusDays+this.company.payment_data.numberOfInvoicesOutstanding91PlusDays)), label: '90+ Days' }
          ]
        },
        {
        type: "stackedColumn100",
        name: "Paid",
        showInLegend: true,
        color: "#409a6a",
        yValueFormatString: "#,##0\"%\"",
        dataPoints: [
          { y: Math.round(this.company.payment_data.numberOfInvoicesPaidWithinTerms*100/(this.company.payment_data.numberOfInvoicesPaidWithinTerms+this.company.payment_data.numberOfInvoicesOutstandingWithinTerms)), label: 'Within Terms' },
          { y: Math.round(this.company.payment_data.numberOfInvoicesPaid1To30Days*100/(this.company.payment_data.numberOfInvoicesPaid1To30Days+this.company.payment_data.numberOfInvoicesOutstanding1To30Days)), label: '0-30 Days' },
          { y: Math.round(this.company.payment_data.numberOfInvoicesPaid31To60Days*100/(this.company.payment_data.numberOfInvoicesPaid31To60Days+this.company.payment_data.numberOfInvoicesOutstanding31To60Days)), label: '31-60 Days' },
          { y: Math.round(this.company.payment_data.numberOfInvoicesPaid61To90Days*100/(this.company.payment_data.numberOfInvoicesPaid61To90Days+this.company.payment_data.numberOfInvoicesOutstanding61To90Days)), label: '61-90 Days' },
          { y: Math.round(this.company.payment_data.numberOfInvoicesPaid91PlusDays*100/(this.company.payment_data.numberOfInvoicesPaid91PlusDays+this.company.payment_data.numberOfInvoicesOutstanding91PlusDays)), label: '90+ Days' }
        ]
        }
      ];
      this.renderPaymentInfoPercentInvoiceChart(paymentInfoPercentInvoice);
      document.getElementById("invoice_percent_graph").style.display = 'none';
    }
  }

  renderKeyFinancialChart(data: any){
    this.financial_chart = new CanvasJS.Chart("finacial_chart", {
      animationEnabled: true,
      exportEnabled: true,
      title: {
        text: ""
      },
      data: [{
        type: "column",
        dataPoints: data
      }]
    });
    this.financial_chart.render();
  }

  render_enquiry_graph(data: any){
    var chart = new CanvasJS.Chart("chartContainer2", {
      animationEnabled: true,
      title: {
        text: ""
      },
      axisX: {
        valueFormatString: "DDD"
      },
      axisY: {
        prefix: ""
      },
      toolTip: {
        shared: true
      },
      data: data
    });
    chart.render();
  }

  render_monthly_breakdown(data: any){
    var chart = new CanvasJS.Chart("chartContainer3", {
      title:{
        text: "Monthly Breakdown"             
      }, 
      animationEnabled: true,     
      axisY:{
        titleFontFamily: "arial",
        titleFontSize: 10,
        includeZero: false
      },
      toolTip: {
        shared: true
      },
      data: data
    });
    chart.render();
  }

  render_score_history_graph(data){
    this.score_history_chart = new CanvasJS.Chart("score_history_graph", {
      animationEnabled: true,
      theme: "light2",
      title:{
        text: "Site Traffic"
      },
      axisX:{
        valueFormatString: "MMM YYYY",
        crosshair: {
          enabled: true,
          snapToDataPoint: true
        }
      },
      axisY: {
        title: "",
        includeZero: true,
        crosshair: {
          enabled: true
        }
      },
      toolTip:{
        shared:true
      },
      data: data
    });
    this.score_history_chart.render();
  }

  render_limit_history_graph(data){
    this.limit_history_chart = new CanvasJS.Chart("limit_history_graph", {
      animationEnabled: true,
      theme: "light2",
      title:{
        text: "Site Traffic"
      },
      axisX:{
        valueFormatString: "MMM YYYY"
      },
      axisY: {
        title: "",
        includeZero: true
      },
      toolTip:{
        shared:true
      },
      data: data
    });
    this.limit_history_chart.render();
  }

  renderPaymentInfoNumberInvoiceChart(data){
    this.number_invoice_chart = new CanvasJS.Chart("invoice_number_graph", {
      animationEnabled: true,
      title:{
        text: "Age of Debt",
        fontFamily: "arial black",
        fontColor: "#695A42",
        fontSize: 12
      },
      axisX: {
      },
      axisY:{
        valueFormatString:"#0"
      },
      data: data
    });
    this.number_invoice_chart.render();
  }

  renderPaymentInfoNumberInvoiceChart1(data){
    this.number_invoice_chart1 = new CanvasJS.Chart("invoice_number_graph1", {
      animationEnabled: true,
      title:{
        text: "Age of Debt",
        fontFamily: "arial black",
        fontColor: "#695A42",
        fontSize: 12
      },
      axisX: {
      },
      axisY:{
        valueFormatString:"#0"
      },
      data: data
    });
    this.number_invoice_chart1.render();
  }

  renderPaymentInfoPercentInvoiceChart(data){
    this.percent_invoice_chart = new CanvasJS.Chart("invoice_percent_graph", {
      animationEnabled: true,
      title:{
        text: "Age of Debt",
        fontFamily: "arial black",
        fontColor: "#695A42",
        fontSize: 12
      },
      axisX: {
      },
      axisY: {
        suffix: "%"
      },
      toolTip: {
        shared: true
      },
      legend: {
        reversed: true,
        verticalAlign: "center",
        horizontalAlign: "right"
      },
      data: data
    });
    this.percent_invoice_chart.render();
  }

  changeChargeDetails(status: any = 0){
    if(status==2){
      this.company.general_info.charge_details = this.satisfied_charge_details;
      this.chargeFlag = 2;
    } else if(status==1){
      this.company.general_info.charge_details = this.outstanding_charge_details;
      this.chargeFlag = 1;
    } else{
      this.company.general_info.charge_details = this.all_charge_details;
      this.chargeFlag = 0;
    }
  }

  changePaymentInfoType(status: any = 0){
    this.paymentInfoType = status;
    if(status == 0){
      document.getElementById("invoice_percent_graph").style.display = 'none';
      document.getElementById("invoice_number_graph").style.display = 'block';
    } else if(status == 1){
      document.getElementById("invoice_number_graph").style.display = 'none';
      document.getElementById("invoice_percent_graph").style.display = 'block';
    }
    
  }

  chnagestatus(id){
    this.mode = id;
  }

  closeModal(id: string) 
  {
    this.modalService.close(id);
  }

  downloadcompanydata(companycode: any, companyname: any = ''){
    this.companyadvertService.downloadcompanyadvertdata({"companycode": companycode, "companyname":companyname }).subscribe((companydata: any) => {
      console.log(companydata);
    });
  }

  changeFiling(e){
    if(e.target.checked){
      this.hidefiling = true;
    } else{
      this.hidefiling = false;
    }
  }

  changeFilingType(e){
    var imageArray = [];
    this.imageDocsCopy.forEach(element => {
      if(e.target.value==''){
        imageArray.push(element);
      }
      if(element.document.imageType == e.target.value){
        imageArray.push(element);
      }
    });
    this.company.image_documents = imageArray;
  }

}
