<app-header></app-header>
<div class="section-wrapper"  *ngIf="wizard == 0">
    <div class="container">
       
        <div class="tabs" id="tabs">
          
            <div class="clearfix"></div>
            <div class="tabs__content">
                <div class="tabs__content__item active-tab">
                    <div class="content">

                        <div class="row row-sm topheader">
                            <div class="col-lg-5">
                                <div>
                                    <app-checkcountshow></app-checkcountshow>                                   
                                </div>
                                
                            </div>                             
                            <div class="col-lg-7 text-right">
                                <div class="d-flex justify-content-end align-items-center">
                                    <a  [routerLink]="['/adversecredit-history']" class="btn com_btn btn-success">
                                        <span class="lnr lnr-history pr-1"></span> View Adverse Credit Search History
                                    </a>
                                             
                                </div> 

                            </div>                               
                        </div>

                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Credit Search - Applicant Details </h5>
                            </div>                       
                        </div>
                      

                        <form [formGroup]="adCredFormGroup" #adCredForm="ngForm" class="s12 white">

                        <div class="personal_form">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Your reference</label>
                                        <input type="text" name="reference" formControlName="reference" id="reference" class="form-control" aria-describedby="emailHelp" placeholder="Your reference" [ngModel]="adCredModel.reference">
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Title*</label>
                                        <input type="text" name="title" formControlName="title" id="title" class="form-control" aria-describedby="emailHelp" placeholder="Title"  [ngClass]="{ 'is-invalid': submitted && f.title.errors }" [ngModel]="adCredModel.title">
                                        <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                            <div *ngIf="f.title.errors.required">Please enter title</div>
                                        </div>
                                   
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Forename*</label>
                                        <input type="text" name="forename" formControlName="forename" id="forename" class="form-control" aria-describedby="emailHelp" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.forename.errors }"  [ngModel]="adCredModel.forename">
                                        <div *ngIf="submitted && f.forename.errors" class="invalid-feedback">
                                            <div *ngIf="f.forename.errors.required">Please enter forename</div>
                                        </div>
                                    
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Middle Name/Initials</label>
                                        <input type="text" name="middlename" formControlName="middlename" id="middlename" class="form-control" aria-describedby="emailHelp" placeholder="Middle Name" [ngModel]="adCredModel.middlename" >
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Surname*</label>
                                        <input type="text" name="sirname" formControlName="sirname" id="sirname" class="form-control" aria-describedby="emailHelp" placeholder="Surname" [ngClass]="{ 'is-invalid': submitted && f.sirname.errors }"  [ngModel]="adCredModel.sirname">
                                        <div *ngIf="submitted && f.sirname.errors" class="invalid-feedback">
                                            <div *ngIf="f.sirname.errors.required">Please enter Surname</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Date of birth*</label>
                                        <mat-form-field class="datecontrol">
                                            <input matInput [matDatepicker]="picker" id="dob" name="dob" formControlName="dob" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }" class="form-control noborder-invalid datecontrol" [ngModel]="adCredModel.dob">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                        <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                                            <div *ngIf="f.dob.errors.required">Please enter Date of birth</div>
                                        </div>
                                        <!-- <input type="text" name="dob" id="dob" class="form-control" aria-describedby="emailHelp" placeholder="Date of birth" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }"  [ngModel]="adCredModel.dob"> -->
                                    </div>
                                </div>
                            </div>
                          
                            <hr/>
                             <div class="row">
                                <div class="col-sm-2">
                                    <label for="exampleInputEmail1" class="width100">Select</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio"  checked name="inlineRadioOptions" formControlName="inlineRadioOptions" [ngModel]="adCredModel.inlineRadioOptions" id="inlineRadio1" value="option1">
                                        <label class="form-check-label" for="inlineRadio1">Opt In</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" formControlName="inlineRadioOptions" [ngModel]="adCredModel.inlineRadioOptions" id="inlineRadio2" value="option2">
                                        <label class="form-check-label" for="inlineRadio2">Opt Out</label>
                                      </div>
                                </div>  
                                <div class="col-sm-2" style="display: none;">
                                    <label for="exampleInputEmail1" class="width100">Select</label>
                                    <label class="checkbox_credit">HHO Required
                                        <input type="checkbox" name="hho" id="hho"   formControlName="hho" class="form-control" [ngModel]="adCredModel.hho" aria-describedby="emailHelp" placeholder="Address 1" >
                                        <span class="checkmark"></span>
                                      </label>
                                </div>  
                                
                                <div class="col-sm-3">
                                    <div class="form-group mb-0">
                                        <label for="exampleInputEmail1" class="width100">Auto Search Undeclared Addresses</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"  checked formControlName="autosearch" name="autosearch" [ngModel]="adCredModel.autosearch" id="inlineRadio3" value="option1">
                                            <label class="form-check-label" for="inlineRadio3">Yes</label>
                                          </div>
                                          <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" formControlName="autosearch" name="autosearch" [ngModel]="adCredModel.autosearch" id="inlineRadio4" value="option2">
                                            <label class="form-check-label" for="inlineRadio4">No</label>
                                          </div>
                                    </div>  
                                   
                                </div>
                                <div class="col-sm-5">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="width100">Select Address Option</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" formControlName="addressoption" name="addressoption" [ngModel]="adCredModel.addressoption" id="inlineRadio5" (change)="changeAddress($event.target.value)" value="option1">
                                            <label class="form-check-label" for="inlineRadio5">Long Addresses Entry </label>
                                          </div>
                                          <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" formControlName="addressoption" name="addressoption" [ngModel]="adCredModel.addressoption" id="inlineRadio6" (change)="changeAddress($event.target.value)" value="option2">
                                            <label class="form-check-label" for="inlineRadio6">Short Addresses Entry</label>
                                          </div>
                                    </div>  
                                   
                                </div>
                            </div> 
                            <hr>
                            <div class="row">
                               <div class="col-sm-4" *ngIf="!shortAdd">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Flat Number/Name</label>
                                        <input type="text" name="flatnumber" formControlName="flatnumber" id="flatnumber" class="form-control" aria-describedby="emailHelp" placeholder="Flat Number/Name" [ngModel]="adCredModel.flatnumber" >
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">House Number</label>
                                        <input type="text" name="housenumber" formControlName="housenumber" id="housenumber" class="form-control" aria-describedby="emailHelp" placeholder="House Number" [ngModel]="adCredModel.housenumber" >
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">House Name</label>
                                        <input type="text" name="housename" formControlName="housename" id="housename" class="form-control" aria-describedby="emailHelp" placeholder="House Name" [ngModel]="adCredModel.housename" >
                                    </div>
                                </div>  
                                <div class="col-sm-4" *ngIf="!shortAdd">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Street 1</label>
                                        <input type="text" name="street" formControlName="street" id="street" class="form-control" aria-describedby="emailHelp" placeholder="street 1" [ngModel]="adCredModel.street" >
                                    </div>
                                </div>
                                <div class="col-sm-4" *ngIf="!shortAdd">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Town</label>
                                        <input type="text" name="town" formControlName="town" id="town" class="form-control" aria-describedby="emailHelp" placeholder="Town" [ngModel]="adCredModel.town" >
                                    </div>
                                </div>
                               
                             
                                <div class="col-sm-4" *ngIf="!shortAdd">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Locality</label>
                                        <input type="text" name="locality" formControlName="locality" id="locality" class="form-control" aria-describedby="emailHelp" placeholder="Locality" [ngModel]="adCredModel.locality" >
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Postcode*</label>
                                        <input type="text" name="postcode" formControlName="postcode" id="postcode" class="form-control" aria-describedby="emailHelp"  [ngClass]="{ 'is-invalid': submitted && f.postcode.errors }" placeholder="Postcode" [ngModel]="adCredModel.postcode" >

                                        <div *ngIf="submitted && f.postcode.errors" class="invalid-feedback">
                                            <div *ngIf="f.postcode.errors.required">Please enter postcode</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                                      
                            <hr/>
                            <div class="row"> 
                            <div class="col-sm-6 text-left">
                                <span *ngIf="lesssore">Sorry, You don't have any credits. Please  <a [routerLink]="['/check-add-credit']">Click Here</a> to add more.</span>
                            </div>
                            <div class="col-sm-6 text-right">
                                    <button type="submit" class="btn btn-primary com_btn"  (click)="onSubmit(adCredFormGroup.value,'first')" [disabled]="!disableBtn" *ngIf="!lesssore">Search</button>
                                    <button type="button" (click)="clearAdverseCreditData()" class="btn btn-secondary com_btn">Cancel</button>                                    
                                </div>
                            </div>
                        </div>



                        

                        <jw-modal id="pdf-payment-confirmation" class="modal sendrequestcharge" role="dialog">
                            <div role="document" class="formgroup">                         
                                <div class="modal-content">                         
                                    <div class="modal-body text-center">                         
                                        <div class="container">
                                            <div class="row">                         
                                                <div class="col-lg-12 spaceset">
                                                    <div class="check">
                                                        <span class="lnr lnr-checkmark-circle"></span>
                                                    </div>
                                                    <!-- <p class="textinfoma">Per Click Charge <span class="charge">£5</span> </p> -->
                                                    <!-- <p class="text-center short">Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs</p> -->
                                                    <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
                                                    <p class="">{{errormsg}}</p>                         
                                                </div>
                                                <div class="text-center m-auto">
                                                    <button type="submit"  class="btn com_btn btn-primary" (click)="onSubmit(adCredFormGroup.value,'payment')"  [disabled]="!disableBtn" *ngIf="lesssore == false"   name="updateform" value="{{buttoninsert}}" > Confirm</button>
                                                    <button type="submit"  class="btn com_btn btn-secondary"(click)="closeModal('pdf-payment-confirmation');" > Cancel</button>
                                                </div>
                                            </div>
                                        </div>                         
                                    </div>
                                </div>                         
                            </div>                         
                         </jw-modal>

                    </form>


                        </div>

                        <div class="overflow_box_show" *ngIf="searchrecord">
                            <div class="rels">

                                <div class="loadercenterimg">
                                        <img src="../../../../../assets/img/loader.gif" />
                                </div>
                                
                                <h2 class="codee" data-text="The adverse credit check will not impact the credit score of the subject.">
                                    The adverse credit check will not impact the credit score of the subject.</h2>
                            </div>
                      </div> 



                </div>
            </div>
        </div>



        
     </div>
</div>


<div class="section-wrapper" >
    <div class="container" >
       
        <div class="tabs" id="tabs" *ngIf="wizard == 1">
          
            <div class="clearfix"></div>
            <div class="tabs__content" *ngIf="applicantSearchAddresses">
                <div class="tabs__content__item active-tab" style="min-height: auto;">
                    <div class="content">
                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Credit Search - Address List </h5>
                            </div>                       
                        </div>
                        <!-- <div class="overviewsshow mt-4">
                        <div class="header">
                            <h1 class="headprofile mt-0">Primary Applicant</h1>
                        </div>   
                        </div> -->
                        <form #addressForm="ngForm" class="s12 white">
                           <div class="job_list_section"  *ngFor="let add of applicantSearchAddresses; let i=index"> 
                            <div class="row row-sm">
                             

                                
                                    <div  class="col-lg-12">
                                        <!-- <span style="padding-left: 30px;"> -->
                                        <input class="form-check-input" type="radio" name="addpicklist"   [ngModel]="i"  value="{{i}}" >
                                        
                                        <ng-container *ngIf="add.addressmatch.abodeno">    {{ add.addressmatch.abodeno._text }}, </ng-container>
                                        <ng-container *ngIf="add.addressmatch.buildingno">    {{ add.addressmatch.buildingno._text }}, </ng-container>
                                        <ng-container *ngIf="add.addressmatch.buildingname">    {{ add.addressmatch.buildingname._text }}, </ng-container>
                                        <ng-container *ngIf="add.addressmatch.street1"> {{ add.addressmatch.street1._text }}, </ng-container>
                                            <ng-container *ngIf="add.addressmatch.posttown"> {{ add.addressmatch.posttown._text }}, </ng-container>
                                                <ng-container *ngIf="add.addressmatch.postcode">{{ add.addressmatch.postcode._text }}</ng-container>
                                    <!-- </span> -->
                                    </div>
                            </div>
                        </div>  

                            <button type="submit" class="btn btn-primary com_btn" (click)="addresschangesubmit(addressForm.value)" >Submit</button>
                            <button type="button" class="btn btn-secondary com_btn" (click)="backwizard(0)">Cancel</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
  
        <div *ngIf="wizard == 2">
            <div class="row row-sm topheader">
                <div class="col-lg-5">
                    <div>
                        <app-checkcountshow></app-checkcountshow>                                   
                    </div>
                    
                </div>    
                
                <div class="col-lg-3 text-right">
                    <div class="d-flex justify-content-end align-items-center" *ngIf="historyid">
                        <button type="button" class="btn btn-primary com_btn" [innerHtml]="downloadtext" (click)="downloadAdverseCreditHistory(historyid,i)"></button>                          
                    </div> 
                </div>
                <div class="col-lg-4 text-right">
                    <div class="d-flex justify-content-end align-items-center">
                        <button class="btn com_btn btn-success" (click)="backwizard(0)">
                            <span class="lnr lnr-history pr-1"></span> Back To Adverse Credit Search
                        </button>
                    </div>   
                    
                </div>                               
            </div>
            <div class="user_profle_section">
                
                <div class="profile_box">
                    <div class="overviewsshow mt-3">
                        <div class="header">
                        <h1 class="headprofile width100 mt-0">Primary Applicant</h1>
                        </div>
                        <div class="overview_details width100">
                            <div class="alignbbb">
                                <div class="headdd" *ngIf="picklist.applicant.address">
                                    <h3 class="card-profile-name" *ngIf="searchFields">Credit Report: 
                                        {{searchFields.title}} {{searchFields.forename}} {{searchFields.sirname}}
                                        <!-- {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.title._text}} {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.forename._text}} {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.Date of birth._text}} -->
                                    </h3>
                                </div>
                            </div>
                            <hr class="mt-0"/>
                            <div class="profile_dtailsshow mb-3" *ngIf="picklist.applicant.address"> 
                                <div class="detailsshow "  *ngIf="picklist.applicant.address.fullmatches.fullmatch.name">
                                    <span>Name</span>
                                    <p> {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.title._text}} {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.forename._text}} {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.surname._text}}</p>
                                </div>
                                <div class="detailsshow ">
                                <span>Current Address</span>
                                <p>
                                    <ng-container *ngIf="picklist.applicant.address.fullmatches.fullmatch.addressmatch.buildingno">
                                        {{(picklist.applicant.address.fullmatches.fullmatch.addressmatch.buildingno._text)?picklist.applicant.address.fullmatches.fullmatch.addressmatch.buildingno._text:''}}, 
                                    </ng-container>
                                   
                                    <ng-container *ngIf="picklist.applicant.address.fullmatches.fullmatch.addressmatch.street1">  {{(picklist.applicant.address.fullmatches.fullmatch.addressmatch.street1._text)?picklist.applicant.address.fullmatches.fullmatch.addressmatch.street1._text:''}}, </ng-container>
                                        <ng-container *ngIf="picklist.applicant.address.fullmatches.fullmatch.addressmatch.posttown">  {{(picklist.applicant.address.fullmatches.fullmatch.addressmatch.posttown._text)?picklist.applicant.address.fullmatches.fullmatch.addressmatch.posttown._text:''}}, </ng-container>
                                            <ng-container *ngIf="picklist.applicant.address.fullmatches.fullmatch.addressmatch.postcode"> {{(picklist.applicant.address.fullmatches.fullmatch.addressmatch.postcode._text)?picklist.applicant.address.fullmatches.fullmatch.addressmatch.postcode._text:''}}</ng-container></p>
                              
                                </div>
                                <!-- <div class="detailsshow ">
                                    <span>Amount</span>
                                    <p>Not Entered</p>
                                </div>
                                <div class="detailsshow ">
                                    <span>Term</span>
                                    <p>Not Entered</p>
                                </div> -->
                                <div class="detailsshow "  *ngIf="picklist.applicant.address.fullmatches.fullmatch.name">
                                    <span>Date of birth</span>
                                    <p *ngIf="picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.dob">{{(picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.dob._text)?picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.dob._text:''}}</p>

                                    <p *ngIf="!picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.dob">{{searchFields.dob  | date: 'dd MMM yyyy'}}</p>

                                </div>
                            </div>
                            <div class="profile_dtailsshow mb-3">
                            <!-- <div class="detailsshow ">
                                <span>Number of Applicant</span>
                                <p> 1</p>
                            </div> -->
                            <div class="detailsshow ">
                                <span>Credit Search Purpose</span>
                                <p *ngIf="creditrequest.purpose._text == 'EC'"> Employment Vetting</p>
                                <p *ngIf="creditrequest.purpose._text == 'TC'"> Tenant  Vetting</p>
                            </div>
                            <div class="detailsshow ">
                            <span>Credit Search Ref.</span>
                            <p>{{jobdetails.searchid._text}}</p>
                        </div>
                        <div class="detailsshow ">
                            <span>Date & Time of Search</span>
                            <p> {{jobdetails.searchdate._text}}</p>
                        </div>  
                        </div>
                        </div>
                        <div class="overview_details " *ngIf="reportData.applicant.summary.address">
                        <div class="alignbbb">
                            <div class="headdd">
                                <h3 class="card-profile-name">Address Confirmation</h3>
                            </div>
                        </div>
                        <hr class="mt-0"/>
                        
                        <ng-container *ngIf="reportData.applicant.summary.address.length" >
                            <div class="profile_dtailsshow mb-3"  *ngFor="let add of  reportData.applicant.summary.address; let i=index">
                                <div class="detailsshow ">
                                    <span>Address</span>
                                    <p><i class="lnr lnr-map-marker"></i> {{ add._text}}</p>
                                </div>
                                <!-- <div class="detailsshow ">
                                    <span>Level</span>
                                    <p class="mb-0">Individul</p>
                                    </div> -->
                                    <!-- <div class="detailsshow ">
                                        <span>Source {{add.messagecode}}</span>
                                        <p class="mb-0" *ngIf="add.messagecode == '1'">Electoral Roll</p>
                                        <p class="mb-0" *ngIf="add.messagecode == '2'">Electoral Roll</p>
                                        <p class="mb-0" *ngIf="add.messagecode == '3'">Electoral Roll</p>
                                        <p class="mb-0" *ngIf="add.messagecode == '4'">Electoral Roll</p>
                                        <p class="mb-0" *ngIf="add.messagecode == '5'">Credit Data</p>
                                        <p class="mb-0" *ngIf="add.messagecode == '6'">Public Information</p>
                                        <p class="mb-0" *ngIf="add.messagecode == '7'">Electoral Roll</p>
                                        <p class="mb-0" *ngIf="add.messagecode == '8'">No confirmation available</p>
                                        <p class="mb-0" *ngIf="add.messagecode == '9'">12 months old</p>
                                    </div> -->
                            </div> 
                        </ng-container>   
                        
                        <ng-container *ngIf="!reportData.applicant.summary.address.length" >
                            <div class="profile_dtailsshow mb-3">
                                <div class="detailsshow ">
                                    <span>Address</span>
                                    <p><i class="lnr lnr-map-marker"></i> {{ reportData.applicant.summary.address._text}}</p>
                                </div>                               
                            </div> 
                        </ng-container>   
                        
                        

                    </div>
                    </div>
                </div>
                <div class="details_box">
                    <div class="overviewsshow pb-0 pt-0 pr-0 pl-0">
                        <mat-tab-group [@.disabled]="true">
                            <!-- <mat-tab label="Impaired credit History">
                                <ng-template matTabContent>
                                    <div class="d-flex justify-content-between ng-star-inserted ma50">
                                        <div class="col-sm-4">
                                        <div class="border-box impairedminhe">
                                            <div class="overview_details emp">
                                                <div class="profile_dtailsshow">
                                                    <div class="detailsshow width100 impaired_credit">
                                                        <p class="mb-0 pr-1">1 or more CCJ with a Total Values greater than 600 in last 36 month</p>
                                                        <span class="badge badge-success">Yes</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                
                                        </div>
                                        <div class="col-sm-4">
                                        <div class="border-box impairedminhe">
                                            <div class="overview_details emp">
                                                <div class="profile_dtailsshow">
                                                    <div class="detailsshow width100 impaired_credit">
                                                        <p class="mb-0 pr-1">1 or more CCJ with a Total Values greater than 600 in last 36 month</p>
                                                        <span class="badge badge-success">Yes</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                
                                        </div>
                                        <div class="col-sm-4">
                                        <div class="border-box impairedminhe">
                                            <div class="overview_details emp">
                                                <div class="profile_dtailsshow">
                                                    <div class="detailsshow width100 impaired_credit">
                                                        <p class="mb-0 pr-1">1 or more CCJ with a Total Values greater than 600 in last 36 month</p>
                                                        <span class="badge badge-success">Yes</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                
                                        </div>
                                    </div>                            
                                </ng-template>
                            </mat-tab> -->
                            <mat-tab label="Address Confirmation">
                                <ng-template matTabContent>
                                    <div class="overviewsshow  mt-1" >
                                        <div class="header">
                                            <h1 class="headprofile width100 mt-0">Address Confirmation</h1>
                                        </div>

                                        <ng-container *ngIf="reportData.applicant.addressconfs" >
                                            
                                        <div class="overview_details" *ngIf="reportData.applicant.addressconfs.addressconf.length">
                                            <div class="profile_dtailsshow mb-3"  *ngFor="let add of reportData.applicant.addressconfs.addressconf; let i=index">
                                                <div class="detailsshow width60">
                                                    <span>Address</span>
                                                    <p><i class="lnr lnr-map-marker"></i> {{add.address._text}}</p>
                                                </div>
                                                <!-- <div class="detailsshow width30">
                                                <span>Level</span>
                                                <p class="mb-0">Individul</p>
                                                </div> -->
                                                <div class="detailsshow width30">
                                                    <span>Address Type </span>
                                                    <span *ngIf="add.address._attributes.current == 1">Current Address  </span> 
                                                    <span *ngIf="add.address._attributes.current == 0"> 
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='1'">Undeclared intermediate Address Link</b>
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='2'">Undeclared forwarding Address Link</b>
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='3'">Undeclared previous Address Link</b>
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='4'">Undeclared concurrent Address Link</b>
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='5'">Undeclared miscellaneous Address Link</b>
                                                    </span> 
                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div class="overview_details" *ngIf="!reportData.applicant.addressconfs.addressconf.length">
                                            <div class="profile_dtailsshow mb-3"  >
                                                <div class="detailsshow width60">
                                                    <span>Address</span>
                                                    <p><i class="lnr lnr-map-marker"></i> {{reportData.applicant.addressconfs.addressconf.address._text}}</p>
                                                </div>
                                                <!-- <div class="detailsshow width30">
                                                <span>Level</span>
                                                <p class="mb-0">Individul</p>
                                                </div> -->
                                                <div class="detailsshow width30">
                                                    <span>Address Type </span>
                                                    <span *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.current == 1">Current Address  </span> 
                                                    <span *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.current == 0"> 
                                                        <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='1'">Undeclared intermediate Address Link</b>
                                                        <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='2'">Undeclared forwarding Address Link</b>
                                                        <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='3'">Undeclared previous Address Link</b>
                                                        <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='4'">Undeclared concurrent Address Link</b>
                                                        <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='5'">Undeclared miscellaneous Address Link</b>
                                                    </span> 
                                    
                                                </div>
                                            </div>
                                        </div>



                                        <div class="overview_details" *ngIf="reportData.applicant.addressconfs.addressconf.length==0">
                                            No Record Found
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="!reportData.applicant.addressconfs" >
                                        <div class="overview_details" >
                                            No Record Found
                                        </div>


                                    </ng-container>


                                    
                                    <!-- <ng-container *ngIf="reportData.applicant.addressconfs.addressconf === 'object'" >
                                        <div class="overview_details">
                                            <div class="profile_dtailsshow mb-3" >
                                            <div class="detailsshow width60">
                                            <span>Address</span>
                                                <p><i class="lnr lnr-map-marker"></i> {{reportData.applicant.addressconfs.addressconf.address._text}}</p>
                                            </div>
                                            <div class="detailsshow width30">
                                            <span>Level</span>
                                            <p class="mb-0">Individul</p>
                                            </div>
                                            <div class="detailsshow width30">
                                                <span>Address Type </span>
                                                <span *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.current == 1">Current Address  </span> 
                                                <span *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.current == 0"> 
                                                    <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='1'">Undeclared intermediate Address Link</b>
                                                    <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='2'">Undeclared forwarding Address Link</b>
                                                    <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='3'">Undeclared previous Address Link</b>
                                                    <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='4'">Undeclared concurrent Address Link</b>
                                                    <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='5'">Undeclared miscellaneous Address Link</b> </span> 
                                
                                            </div>
                                            </div>
                                        </div>
                                        <div class="overview_details" *ngIf="!reportData.applicant.addressconfs.addressconf">
                                            No Record Found
                                        </div>
                                    </ng-container> -->

                                    </div>             
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Public Information">
                                <ng-template matTabContent>
                                    <div class="overviewsshow  mt-1" >
                                        <div class="header">
                                            <h1 class="headprofile width100 mt-0">Public Information</h1>
                                        </div>
                                        <div class="overview_details width100">
                                            <div class="alignbbb">
                                                <div class="headdd">
                                                    <h3 class="card-profile-name">INSOLVENCY, BANKRUPTCY OR ADMINISTRATOR ORDER</h3>            
                                                </div>
                                                <span class="badge badge-danger" *ngIf="reportData.applicant.summary.bais.length">Yes</span>
                                                <span class="badge badge-danger" *ngIf="!reportData.applicant.summary.bais.length">No</span>
                                            </div>
                                            <hr class="mt-0"/>
                                            <div class="profile_dtailsshow mb-3" *ngIf="reportData.applicant.summary.bais.totaldischarged">
                                                <div class="detailsshow width25">
                                                    <span>Number of Discharged BAI in last 6 Years</span>
                                                    <p class="text-dark" ><b>{{reportData.applicant.summary.bais.totaldischarged._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width25"  *ngIf="reportData.applicant.summary.bais.totalactive">
                                                <span>Number of Active Judgements</span>
                                                <p class="text-danger"><b>{{reportData.applicant.summary.judgments.totalactive._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width25" *ngIf="reportData.applicant.summary.bais.totalsatisfied">
                                                    <span>Number of Satisfied Judgements</span>
                                                    <p class="text-danger"><b>{{reportData.applicant.summary.judgments.totalsatisfied._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width20" *ngIf="reportData.applicant.summary.bais.totalactiveamount">
                                                <span>Total Values of active Judgements</span>
                                                <p class="text-danger"><b>£ {{reportData.applicant.summary.judgments.totalactiveamount._text}}</b></p>
                                                </div>
                                            </div>      
                                        </div>   
                                    </div>             
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Adverse Credit Searches">
                                <ng-template matTabContent>
                                    <div class="overviewsshow  mt-1" >
                                        <div class="header">
                                            <h1 class="headprofile width100 mt-0">Adverse Credit Searches</h1>
                                        </div>
                                        <div class="overview_details width100">
                                            <div class="alignbbb">
                                                <div class="headdd">
                                                    <h3 class="card-profile-name">NB: These counts are based on checking credit application and business searches</h3>            
                                                </div>        
                                            </div>
                                            <hr class="mt-0"/>
                                            <div class="profile_dtailsshow mb-3">
                                                <div class="detailsshow width30">
                                                    <span>Number of Searches in last 3 Months</span>
                                                    <p class="text-dark"><b>{{ reportData.applicant.summary.searches.totalsearches3months._text }}</b></p>
                                                </div>
                                                <div class="detailsshow width30">
                                                <span>Number of Searches in last 12 Months</span>
                                                <p class="text-dark"><b>{{ reportData.applicant.summary.searches.totalsearches12months._text }}</b></p>
                                            </div>
                                            
                                            <div class="detailsshow width30">
                                            <span>Number of Home Credit Searches in last 3 Months</span>
                                            <p class="text-danger"><b>{{ reportData.applicant.summary.searches.totalhomecreditsearches3months._text }}</b></p>
                                            </div>
                                            </div>
                                        
                                        </div>
                                    </div>             
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Unchecked Links">
                                <ng-template matTabContent>
                                    <div class="overviewsshow  mt-1" >
                                        <div class="overview_details width100">
                                            <div class="header">
                                                <h1 class="headprofile width100 mt-0">Unchecked Links</h1>
                                            </div>
                                            <div class="profile_dtailsshow mb-3">
                                                <div class="detailsshow width30">
                                                    <span>Number of Undeclared Addresses - Searches</span>
                                                    <p class="text-danger"><b>{{reportData.applicant.summary.links.totalundecaddressessearched._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width40">
                                                <span>Number of Undeclared Addresses - Not Searches</span>
                                                <p class="text-danger"><b>{{reportData.applicant.summary.links.totalundecaddressesunsearched._text}}</b></p>
                                            </div>
                                            
                                            <div class="detailsshow width30">
                                            <span>Number of Undeclared Aliases </span>
                                            <p class="text-danger"><b>{{reportData.applicant.summary.links.totalundecaliases._text}}</b></p>
                                        </div>
                                            </div>
                                            <div class="header">
                                                <h1 class="headprofile width100 mt-0">Notices</h1>
                                            </div>
                                            <div class="profile_dtailsshow mb-3">
                                                <div class="detailsshow width30">
                                                    <span>Notices of Correction Present</span>
                                                    <p class="text-danger" *ngIf="reportData.applicant.summary.notices.nocflag._text == 1"><b>Yes</b></p>
                                                    <p class="text-danger" *ngIf="reportData.applicant.summary.notices.nocflag._text == 0"><b>No</b></p>
                                                </div>
                                                <div class="detailsshow width30" *ngIf="reportData.applicant.summary.notices.totaldisputes">
                                                <span>Number of Notices of Disputes </span>
                                                <p class="text-dark"><b>{{reportData.applicant.summary.notices.totaldisputes._text}}</b></p>
                                            </div>       
                                            
                                            </div>
                                        </div>
                                    </div> 
                                    
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Electoral Roll">
                                <ng-template matTabContent>
                                    <div class="overviewsshow mt-1">
                                        <div class="header">
                                            <h1 class="headprofile mt-0">Electoral Roll</h1>
                                        </div>
                                        <ng-container *ngIf="reportData.applicant.addressconfs.addressconf">

                                            <ng-container *ngIf="reportData.applicant.addressconfs.addressconf.length">

                                            <div class="overview_details mt-1 width100" *ngFor="let add of reportData.applicant.addressconfs.addressconf;">
                                                <div class="alignbbb">
                                                    <div class="headdd" *ngIf="add.address">
                                                        <h3 class="card-profile-name">{{ add.address._text}}</h3>            
                                                    </div>
                                                    <p class="badge"  *ngIf="add.address" >
                                                        <span *ngIf="add.address._attributes.current == 1">Current Address</span> 
                                                        <span *ngIf="add.address._attributes.current == 0">                    
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='1'">Undeclared intermediate Address Link</b>
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='2'">Undeclared forwarding Address Link</b>
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='3'">Undeclared previous Address Link</b>
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='4'">Undeclared concurrent Address Link</b>
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='5'">Undeclared miscellaneous Address Link</b>
                                                        </span> 
                                                    </p>
                                                </div>
                                                <hr class="mt-0"/>
                                                <ng-container *ngIf="add.resident">
                                                    <ng-container *ngIf="add.resident.length">

                                                        <div class="profile_dtailsshow mb-3"   *ngFor="let add2 of add.resident;" >
                                                            <div class="detailsshow width25" *ngIf="add2.name">
                                                                <span>Name</span>
                                                                <p class="text-dark" ><b>{{add2.name._text}}</b></p>
                                                            </div> 

                                                            <ng-container *ngIf="add2.erhistory.length">
                                                                <div class=" width75">
                                                                    <div class="profile_dtailsshow"   *ngFor="let add3 of add2.erhistory;" >
                                                                        <div class="detailsshow width25">
                                                                            <span>Start Date</span>
                                                                            <p class="text-dark" ><b>{{add3.startdate._text}}</b></p>
                                                                        </div>            
                                                                        <div class="detailsshow width25"   >
                                                                            <span>End Date</span>
                                                                            <p class="text-danger" *ngIf="add3.enddate"><b>{{add3.enddate._text}}</b></p>
                                                                            <p class="text-danger" *ngIf="!add3.enddate"><b>NA</b></p>
                                                                        </div>
                                                                    
                                                                        <div class="detailsshow width25" *ngIf="add3.optout" >
                                                                            <span>Output</span>
                                                                            <p class="text-danger"><b>Not Opted Out  </b></p>
                                                                        </div> 
                                                                        <div class="detailsshow width25" *ngIf="add3.rollingroll" >
                                                                            <span>Rolling</span>
                                                                            <p class="text-danger" *ngIf="add3.rollingroll._text ==0"><b>Annual Electoral Roll</b></p>
                                                                            <p class="text-danger" *ngIf="add3.rollingroll._text ==1"><b>Rolling Electoral Roll</b></p>
                                                                        </div>  
                                                                    </div> 
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="!add2.erhistory.length">
                                                                <div class="profile_dtailsshow width75"  >
                                                                    <div class="detailsshow width25">
                                                                        <span>Start Date</span>
                                                                        <p class="text-dark" ><b>{{add2.erhistory.startdate._text}}</b></p>
                                                                    </div>            
                                                                    <div class="detailsshow width25" >
                                                                        <span>End Date</span>
                                                                        <p class="text-danger" *ngIf="add2.erhistory.enddate"  ><b>{{add2.erhistory.enddate._text}}</b></p>
                                                                        <p class="text-danger" *ngIf="!add2.erhistory.enddate"  ><b>NA</b></p>
                                                                    </div>
                                                                    <div class="detailsshow width25" *ngIf="add2.erhistory.optout" >
                                                                        <span>Output</span>
                                                                        <p class="text-danger"><b>Not Opted Out  </b></p>
                                                                    </div> 
                                                                    <div class="detailsshow width25" *ngIf="add2.erhistory.rollingroll" >
                                                                        <span>Rolling</span>
                                                                        <p class="text-danger" *ngIf="add2.erhistory.rollingroll._text ==0"><b>Annual Electoral Roll</b></p>
                                                                        <p class="text-danger" *ngIf="add2.erhistory.rollingroll._text ==1"><b>Rolling Electoral Roll</b></p>
                                                                    </div>  
                                                                </div>
                                                            </ng-container>                       
                                                        </div>      
                                                    </ng-container>
                                                    <ng-container *ngIf="!add.resident.length">

                                                        <div class="profile_dtailsshow mb-3" >
                                                            <div class="detailsshow width25" *ngIf="add.resident.name">
                                                                <span>Name</span>
                                                                <p class="text-dark" ><b>{{add.resident.name._text}}</b></p>
                                                            </div> 

                                                            <ng-container *ngIf="add.resident.erhistory.length">
                                                                <div class=" width75">
                                                                    <div class="profile_dtailsshow"   *ngFor="let add3 of add.resident.erhistory;" >
                                                                        <div class="detailsshow width25">
                                                                            <span>Start Date</span>
                                                                            <p class="text-dark" ><b>{{add3.startdate._text}}</b></p>
                                                                        </div>            
                                                                        <div class="detailsshow width25"   >
                                                                            <span>End Date</span>
                                                                            <p class="text-danger" *ngIf="add3.enddate"><b>{{add3.enddate._text}}</b></p>
                                                                            <p class="text-danger" *ngIf="!add3.enddate"><b>NA</b></p>
                                                                        </div>
                                                                    
                                                                        <div class="detailsshow width25" *ngIf="add3.optout" >
                                                                            <span>Output</span>
                                                                            <p class="text-danger"><b>Not Opted Out  </b></p>
                                                                        </div> 
                                                                        <div class="detailsshow width25" *ngIf="add3.rollingroll" >
                                                                            <span>Rolling</span>
                                                                            <p class="text-danger" *ngIf="add3.rollingroll._text ==0"><b>Annual Electoral Roll</b></p>
                                                                            <p class="text-danger" *ngIf="add3.rollingroll._text ==1"><b>Rolling Electoral Roll</b></p>
                                                                            
                                                                        </div>  
                                                                    </div> 
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="!add.resident.erhistory.length">
                                                                <div class="profile_dtailsshow width75"  >
                                                                    <div class="detailsshow width25">
                                                                        <span>Start Date</span>
                                                                        <p class="text-dark" ><b>{{add.resident.erhistory.startdate._text}}</b></p>
                                                                    </div>            
                                                                    <div class="detailsshow width25" >
                                                                        <span>End Date</span>
                                                                        <p class="text-danger" *ngIf="add.resident.erhistory.enddate"  ><b>{{add.resident.erhistory.enddate._text}}</b></p>
                                                                        <p class="text-danger" *ngIf="!add.resident.erhistory.enddate"  ><b>NA</b></p>
                                                                    </div>
                                                                
                                                                    <div class="detailsshow width25" *ngIf="add.resident.erhistory.optout" >
                                                                        <span>Output</span>
                                                                        <p class="text-danger"><b>Not Opted Out  </b></p>
                                                                    </div> 
                                                                    <div class="detailsshow width25" *ngIf="add.resident.erhistory.rollingroll" >
                                                                        <span>Rolling</span>
                                                                        <p class="text-danger" *ngIf="add.resident.erhistory.rollingroll._text ==0"><b>Annual Electoral Roll</b></p>
                                                                        <p class="text-danger" *ngIf="add.resident.erhistory.rollingroll._text ==1"><b>Rolling Electoral Roll</b></p>
                                                                    </div>  
                                                                </div>
                                                            </ng-container>

                                                                                                
                                                        </div>      
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="!add.resident">
                                                    No Record Found
                                                </ng-container>
                                            </div>  
                                        </ng-container>



                                        <ng-container *ngIf="!reportData.applicant.addressconfs.addressconf.length">


                                            <div class="overview_details mt-1 width100">
                                                <div class="alignbbb">
                                                    <div class="headdd" *ngIf="reportData.applicant.addressconfs.addressconf.address">
                                                        <h3 class="card-profile-name">{{ reportData.applicant.addressconfs.addressconf.address._text}}</h3>            
                                                    </div>
                                                    <p class="badge"  *ngIf="reportData.applicant.addressconfs.addressconf.address" >
                                                        <span *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.current == 1">Current Address</span> 
                                                        <span *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.current == 0">                    
                                                        <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='1'">Undeclared intermediate Address Link</b>
                                                        <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='2'">Undeclared forwarding Address Link</b>
                                                        <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='3'">Undeclared previous Address Link</b>
                                                        <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='4'">Undeclared concurrent Address Link</b>
                                                        <b *ngIf="reportData.applicant.addressconfs.addressconf.address._attributes.undeclaredaddresstype =='5'">Undeclared miscellaneous Address Link</b>
                                                        </span> 
                                                    </p>
                                                </div>
                                                <hr class="mt-0"/>
                                                <ng-container *ngIf="reportData.applicant.addressconfs.addressconf.resident">
                                                    <ng-container *ngIf="reportData.applicant.addressconfs.addressconf.resident.length">

                                                        <div class="profile_dtailsshow mb-3"   *ngFor="let add2 of reportData.applicant.addressconfs.addressconf.resident;" >
                                                            <div class="detailsshow width25" *ngIf="add2.name">
                                                                <span>Name</span>
                                                                <p class="text-dark" ><b>{{add2.name._text}}</b></p>
                                                            </div> 

                                                            <ng-container *ngIf="add2.erhistory.length">
                                                                <div class=" width75">
                                                                    <div class="profile_dtailsshow"   *ngFor="let add3 of add2.erhistory;" >
                                                                        <div class="detailsshow width25">
                                                                            <span>Start Date</span>
                                                                            <p class="text-dark" ><b>{{add3.startdate._text}}</b></p>
                                                                        </div>            
                                                                        <div class="detailsshow width25"   >
                                                                            <span>End Date</span>
                                                                            <p class="text-danger" *ngIf="add3.enddate"><b>{{add3.enddate._text}}</b></p>
                                                                            <p class="text-danger" *ngIf="!add3.enddate"><b>NA</b></p>
                                                                        </div>
                                                                    
                                                                        <div class="detailsshow width25" *ngIf="add3.optout" >
                                                                            <span>Output</span>
                                                                            <p class="text-danger"><b>Not Opted Out  </b></p>
                                                                        </div> 
                                                                        <div class="detailsshow width25" *ngIf="add3.rollingroll" >
                                                                            <span>Rolling</span>
                                                                            <p class="text-danger" *ngIf="add3.rollingroll._text ==0"><b>Annual Electoral Roll</b></p>
                                                                            <p class="text-danger" *ngIf="add3.rollingroll._text ==1"><b>Rolling Electoral Roll</b></p>
                                                                        </div>  
                                                                    </div> 
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="!add2.erhistory.length">
                                                                <div class="profile_dtailsshow width75"  >
                                                                    <div class="detailsshow width25">
                                                                        <span>Start Date</span>
                                                                        <p class="text-dark" ><b>{{add2.erhistory.startdate._text}}</b></p>
                                                                    </div>            
                                                                    <div class="detailsshow width25" >
                                                                        <span>End Date</span>
                                                                        <p class="text-danger" *ngIf="add2.erhistory.enddate"  ><b>{{add2.erhistory.enddate._text}}</b></p>
                                                                        <p class="text-danger" *ngIf="!add2.erhistory.enddate"  ><b>NA</b></p>
                                                                    </div>
                                                                    <div class="detailsshow width25" *ngIf="add2.erhistory.optout" >
                                                                        <span>Output</span>
                                                                        <p class="text-danger"><b>Not Opted Out  </b></p>
                                                                    </div> 
                                                                    <div class="detailsshow width25" *ngIf="add2.erhistory.rollingroll" >
                                                                        <span>Rolling</span>
                                                                        <p class="text-danger" *ngIf="add2.erhistory.rollingroll._text ==0"><b>Annual Electoral Roll</b></p>
                                                                        <p class="text-danger" *ngIf="add2.erhistory.rollingroll._text ==1"><b>Rolling Electoral Roll</b></p>
                                                                    </div>  
                                                                </div>
                                                            </ng-container>                       
                                                        </div>      
                                                    </ng-container>
                                                    <ng-container *ngIf="!reportData.applicant.addressconfs.addressconf.resident.length">

                                                        <div class="profile_dtailsshow mb-3" >
                                                            <div class="detailsshow width25" *ngIf="reportData.applicant.addressconfs.addressconf.resident.name">
                                                                <span>Name</span>
                                                                <p class="text-dark" ><b>{{reportData.applicant.addressconfs.addressconf.resident.name._text}}</b></p>
                                                            </div> 

                                                            <ng-container *ngIf="reportData.applicant.addressconfs.addressconf.resident.erhistory.length">
                                                                <div class=" width75">
                                                                    <div class="profile_dtailsshow"   *ngFor="let add3 of reportData.applicant.addressconfs.addressconf.resident.erhistory;" >
                                                                        <div class="detailsshow width25">
                                                                            <span>Start Date</span>
                                                                            <p class="text-dark" ><b>{{add3.startdate._text}}</b></p>
                                                                        </div>            
                                                                        <div class="detailsshow width25"   >
                                                                            <span>End Date</span>
                                                                            <p class="text-danger" *ngIf="add3.enddate"><b>{{add3.enddate._text}}</b></p>
                                                                            <p class="text-danger" *ngIf="!add3.enddate"><b>NA</b></p>
                                                                        </div>
                                                                    
                                                                        <div class="detailsshow width25" *ngIf="add3.optout" >
                                                                            <span>Output</span>
                                                                            <p class="text-danger"><b>Not Opted Out  </b></p>
                                                                        </div> 
                                                                        <div class="detailsshow width25" *ngIf="add3.rollingroll" >
                                                                            <span>Rolling</span>
                                                                            <p class="text-danger" *ngIf="add3.rollingroll._text ==0"><b>Annual Electoral Roll</b></p>
                                                                            <p class="text-danger" *ngIf="add3.rollingroll._text ==1"><b>Rolling Electoral Roll</b></p>
                                                                            
                                                                        </div>  
                                                                    </div> 
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="!reportData.applicant.addressconfs.addressconf.resident.erhistory.length">
                                                                <div class="profile_dtailsshow width75"  >
                                                                    <div class="detailsshow width25">
                                                                        <span>Start Date</span>
                                                                        <p class="text-dark" ><b>{{reportData.applicant.addressconfs.addressconf.resident.erhistory.startdate._text}}</b></p>
                                                                    </div>            
                                                                    <div class="detailsshow width25" >
                                                                        <span>End Date</span>
                                                                        <p class="text-danger" *ngIf="reportData.applicant.addressconfs.addressconf.resident.erhistory.enddate"  ><b>{{reportData.applicant.addressconfs.addressconf.resident.erhistory.enddate._text}}</b></p>
                                                                        <p class="text-danger" *ngIf="!reportData.applicant.addressconfs.addressconf.resident.erhistory.enddate"  ><b>NA</b></p>
                                                                    </div>
                                                                
                                                                    <div class="detailsshow width25" *ngIf="reportData.applicant.addressconfs.addressconf.resident.erhistory.optout" >
                                                                        <span>Output</span>
                                                                        <p class="text-danger"><b>Not Opted Out  </b></p>
                                                                    </div> 
                                                                    <div class="detailsshow width25" *ngIf="reportData.applicant.addressconfs.addressconf.resident.erhistory.rollingroll" >
                                                                        <span>Rolling</span>
                                                                        <p class="text-danger" *ngIf="reportData.applicant.addressconfs.addressconf.resident.erhistory.rollingroll._text ==0"><b>Annual Electoral Roll</b></p>
                                                                        <p class="text-danger" *ngIf="reportData.applicant.addressconfs.addressconf.resident.erhistory.rollingroll._text ==1"><b>Rolling Electoral Roll</b></p>
                                                                    </div>  
                                                                </div>
                                                            </ng-container>

                                                                                                
                                                        </div>      
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="!reportData.applicant.addressconfs.addressconf.resident">
                                                    No Record Found
                                                </ng-container>
                                            </div>  


                                        </ng-container>











                                        </ng-container>
                                    </div>    
                                    <div class="overviewsshow mt-1" *ngIf="!reportData.applicant.addressconfs.addressconf">
                                        No Record Found
                                    </div>     
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="BAI">
                                <ng-template matTabContent>
                                    <div class="overviewsshow mt-1" *ngIf="IBA">
                                        <div class="header">
                                            <h1 class="headprofile width100 mt-0">BAI</h1>
                                        </div>


                                        <ng-container *ngIf="!IBA.length">

                                        <div class="overview_details width100" >
                                            <div class="alignbbb">
                                                <div class="headdd" *ngIf="IBA.name">
                                                    <h3 class="card-profile-name">{{ IBA.name._text}}</h3>            
                                                </div>
                                                <p class="badge" *ngIf="reportData.applicant.summary.bais.length">
                                                    <span *ngIf="IBA.address._attributes.current == 1">Current Address:  </span> {{IBA.address._text}}
                                                    <span *ngIf="IBA.address._attributes.current == 0">Undeclared Forwarding Address: </span> {{IBA.address._text}}
                                    
                                                </p>
                                            </div>
                                            <hr class="mt-0"/>
                                            <div class="profile_dtailsshow mb-3" >
                                                <div class="detailsshow width35" *ngIf="IBA.courtname">
                                                    <span>Court Name</span>
                                                    <p class="text-dark" ><b>{{IBA.courtname._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width25"   *ngIf="IBA.ordertype">
                                                <span>Order Type</span>
                                                <p class="text-danger"><b *ngIf="IBA.ordertype._text == 'BO'">Bankruptcy Order</b>
                                                    <b *ngIf="IBA.ordertype._text == 'IV'">IVA</b></p>
                                                </div>
                                                <div class="detailsshow width20"  *ngIf="IBA.orderdate">
                                                    <span>Order Date</span>
                                                    <p class="text-danger"><b>{{IBA.orderdate._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width20"   *ngIf="IBA.caseyear">
                                                    <span>Order Year</span>
                                                    <p class="text-danger"><b>{{IBA.caseyear._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width20"   *ngIf="IBA.caseref">
                                                    <span>Case Ref</span>
                                                    <p class="text-danger"><b>{{IBA.caseref._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width20"   *ngIf="IBA.currentstatus">
                                                <span>Status</span>
                                                <p class="text-danger" *ngIf="IBA.currentstatus._text == 'A'"><b>Active</b></p>
                                                <p class="text-danger" *ngIf="IBA.currentstatus._text == 'D'"><b>Discharged or completed</b></p>
                                                <p class="text-danger" *ngIf="IBA.currentstatus._text == 'V'"><b>Revoked</b></p>
                                                </div>
                                            </div>      
                                        </div> 

                                    </ng-container>

                                        <ng-container *ngIf="IBA.length">


                                        <div class="overview_details width100"  *ngFor="let add of reportData.applicant.bais.bai; let i=index">
                                            <div class="alignbbb">
                                                <div class="headdd">
                                                    <h3 class="card-profile-name">{{ add.name._text}}</h3>            
                                                </div>
                                                <p class="badge" *ngIf="reportData.applicant.summary.bais.length">
                                                    <span *ngIf="add.address._attributes.current == 1">Current Address:  </span> {{add.address._text}}
                                                    <span *ngIf="add.address._attributes.current == 0">Undeclared Forwarding Address: </span> {{add.address._text}}
                                    
                                                </p>
                                            </div>
                                            <hr class="mt-0"/>
                                            <div class="profile_dtailsshow mb-3" >
                                                <div class="detailsshow width35">
                                                    <span>Court Name</span>
                                                    <p class="text-dark" ><b>{{add.courtname._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width25"  >
                                                <span>Order Type</span>
                                                <p class="text-danger"><b *ngIf="add.ordertype._text == 'BO'">Bankruptcy Order</b>
                                                    <b *ngIf="add.ordertype._text == 'IV'">IVA</b></p>
                                                </div>
                                                <div class="detailsshow width20" >
                                                    <span>Order Date</span>
                                                    <p class="text-danger"><b>{{add.orderdate._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width20" >
                                                    <span>Order Year</span>
                                                    <p class="text-danger"><b>{{add.caseyear._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width20" >
                                                    <span>Case Ref</span>
                                                    <p class="text-danger"><b>{{add.caseref._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width20" >
                                                <span>Status</span>
                                                <p class="text-danger" *ngIf="add.currentstatus._text == 'A'"><b>Active</b></p>
                                                <p class="text-danger" *ngIf="add.currentstatus._text == 'D'"><b>Discharged or completed</b></p>
                                                <p class="text-danger" *ngIf="add.currentstatus._text == 'V'"><b>Revoked</b></p>
                                                </div>
                                            </div>      
                                        </div>   

                                    </ng-container>



                                    </div>
                                    <div class="overviewsshow mt-1" *ngIf="!IBA">
                                        No Record Found
                                    </div>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="County court judgement">
                                <ng-template matTabContent>
                                    <div class="overviewsshow mt-1" *ngIf="judgment">
                                        <div class="header">
                                            <h1 class="headprofile width100 mt-0">County Court Judgement</h1>
                                        </div>



                                        <ng-container *ngIf="!judgment.length">
                                        <div class="overview_details width100" >
                                            <div class="alignbbb" *ngIf="judgment.name">
                                                <div class="headdd">
                                                    <h3 class="card-profile-name">{{ judgment.name._text}}</h3>            
                                                </div>
                                                <p class="badge" *ngIf="reportData.applicant.summary.bais.length">
                                                    <span *ngIf="judgment.address._attributes.current == 1">Current Address:  </span> {{add.address._text}}
                                                    <span *ngIf="judgment.address._attributes.current == 0"> 
                                                        <b *ngIf="judgment.address._attributes.undeclaredaddresstype =='1'">Undeclared intermediate Address Link:</b>
                                                        <b *ngIf="judgment.address._attributes.undeclaredaddresstype =='2'">Undeclared forwarding Address Link:</b>
                                                        <b *ngIf="judgment.address._attributes.undeclaredaddresstype =='3'">Undeclared previous Address Link:</b>
                                                        <b *ngIf="judgment.address._attributes.undeclaredaddresstype =='4'">Undeclared concurrent Address Link:</b>
                                                        <b *ngIf="judgment.address._attributes.undeclaredaddresstype =='5'">Undeclared miscellaneous Address Link:</b> </span> {{add.address._text}}
                                    
                                                </p>
                                            </div>
                                            <hr class="mt-0"/>
                                            <div class="profile_dtailsshow mb-3"  *ngIf="judgment.courtname">
                                                <div class="detailsshow width35">
                                                    <span>Court Name</span>
                                                    <p class="text-dark" ><b>{{judgment.courtname._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width25"  *ngIf="judgment.courttype" >
                                                <span>Court Type</span>
                                                <p class="text-danger">
                                                    <b *ngIf="judgment.courttype._text == '0'">County Court Judgement</b>
                                                    <b *ngIf="judgment.courttype._text == '1'">High Court Queen's Bench</b>
                                                    <b *ngIf="judgment.courttype._text == '2'">High Court Mercantile</b>
                                                    <b *ngIf="judgment.courttype._text == '3'">High Court Chancery Division</b>
                                                    <b *ngIf="judgment.courttype._text == '4'">RCJ, Queen's Bench</b>
                                                    <b *ngIf="judgment.courttype._text == '5'">RCJ, Chancery</b>
                                                    <b *ngIf="judgment.courttype._text == '6'">RCJ, Admiralty & Commercial Court</b>
                                                    </p>
                                                </div>
                                                <div class="detailsshow width25"  *ngIf="judgment.judgmentdate" >
                                                    <span>Judgement Date</span>
                                                    <p class="text-danger"><b>{{judgment.judgmentdate._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width15"  *ngIf="judgment.amount" >
                                                    <span>Amount</span>
                                                    <p class="text-danger"><b>{{judgment.amount._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width25"  *ngIf="judgment.casenumber" >
                                                    <span>Case Number</span>
                                                    <p class="text-danger"><b>{{judgment.casenumber._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width25" *ngIf="judgment.status" >
                                                <span>Status</span>
                                                <p class="text-danger" *ngIf="judgment.status._text == 'JG'"><b>active Judgement</b></p>
                                                <p class="text-danger" *ngIf="judgment.status._text == 'SS'"><b>Satisfied (Paid)</b></p>
                                                </div>
                                            </div>      
                                        </div> 
                                    </ng-container>



                                    <ng-container *ngIf="judgment.length">
                                        <div class="overview_details width100"  *ngFor="let add of reportData.applicant.judgments.judgment; let i=index">
                                            <div class="alignbbb">
                                                <div class="headdd">
                                                    <h3 class="card-profile-name">{{ add.name._text}}</h3>            
                                                </div>
                                                <p class="badge" *ngIf="reportData.applicant.summary.bais.length">
                                                    <span *ngIf="add.address._attributes.current == 1">Current Address:  </span> {{add.address._text}}
                                                    <span *ngIf="add.address._attributes.current == 0"> 
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='1'">Undeclared intermediate Address Link:</b>
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='2'">Undeclared forwarding Address Link:</b>
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='3'">Undeclared previous Address Link:</b>
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='4'">Undeclared concurrent Address Link:</b>
                                                        <b *ngIf="add.address._attributes.undeclaredaddresstype =='5'">Undeclared miscellaneous Address Link:</b> </span> {{add.address._text}}
                                    
                                                </p>
                                            </div>
                                            <hr class="mt-0"/>
                                            <div class="profile_dtailsshow mb-3" >
                                                <div class="detailsshow width35">
                                                    <span>Court Name</span>
                                                    <p class="text-dark" ><b>{{add.courtname._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width25"  >
                                                <span>Court Type</span>
                                                <p class="text-danger">
                                                    <b *ngIf="add.courttype._text == '0'">County Court Judgement</b>
                                                    <b *ngIf="add.courttype._text == '1'">High Court Queen's Bench</b>
                                                    <b *ngIf="add.courttype._text == '2'">High Court Mercantile</b>
                                                    <b *ngIf="add.courttype._text == '3'">High Court Chancery Division</b>
                                                    <b *ngIf="add.courttype._text == '4'">RCJ, Queen's Bench</b>
                                                    <b *ngIf="add.courttype._text == '5'">RCJ, Chancery</b>
                                                    <b *ngIf="add.courttype._text == '6'">RCJ, Admiralty & Commercial Court</b>
                                                    </p>
                                                </div>
                                                <div class="detailsshow width25" >
                                                    <span>Judgement Date</span>
                                                    <p class="text-danger"><b>{{add.judgmentdate._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width15" >
                                                    <span>Amount</span>
                                                    <p class="text-danger"><b>{{add.amount._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width25" >
                                                    <span>Case Number</span>
                                                    <p class="text-danger"><b>{{add.casenumber._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width25" >
                                                <span>Status</span>
                                                <p class="text-danger" *ngIf="add.status._text == 'JG'"><b>active Judgement</b></p>
                                                <p class="text-danger" *ngIf="add.status._text == 'SS'"><b>Satisfied (Paid)</b></p>
                                                </div>
                                            </div>      
                                        </div> 
                                        </ng-container>

                                        






                                    </div>
                                    <div class="overviewsshow mt-1" *ngIf="!judgment">
                                        No Record Found
                                    </div>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Address Links">
                                <ng-template matTabContent>
                                    <div class="overviewsshow mt-1" *ngIf="links">
                                        <div class="header">
                                            <h1 class="headprofile width100 mt-0">Address Links</h1>
                                        </div>


                                        <ng-container *ngIf="!links.length">
                                            <div class="overview_details width100">
                                           
                                            <div class="profile_dtailsshow mb-3" >
                                                <div class="detailsshow width50">
                                                    <span>From</span>
                                                    <ng-container *ngFor="let add2 of reportData.applicant.addresslinks.addresses.address; let i1=index">

                                                        <ng-container *ngIf="links._attributes">
                                                        <p class="text-dark" *ngIf="links._attributes.from == add2._attributes.addressid">                
                                                            <b >{{add2._text}}</b>                    
                                                        </p></ng-container> 
                                                    </ng-container>                
                                                </div>
                                                <div class="detailsshow width50">
                                                    <span>To</span>
                                                    <p class="text-dark" *ngFor="let add2 of reportData.applicant.addresslinks.addresses.address; let i1=index">
                                                    
                                                        <ng-container *ngIf="links._attributes">
                                                            <b *ngIf="links._attributes.to == add2._attributes.addressid">{{add2._text}}</b>
                                                        </ng-container>
                                                    </p>
                                                </div>                                       
                                            </div>   
                                            <div class="profile_dtailsshow mb-3" *ngIf="links.creationdate" >
                                                <div class="detailsshow width25" >
                                                    <span>Confirmation Date</span>
                                                    <p class="text-danger"><b>{{links.creationdate._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width25"  *ngIf="links.lastconfdate">
                                                    <span>Earliest Confirmation</span>
                                                    <p class="text-danger"><b>{{links.lastconfdate._text}}</b></p>
                                                </div>                            
                                                <div class="detailsshow width50" *ngIf="links.supplierdetails" >
                                                    <span>Supplier Details</span>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'AF'"><b>Agricultural Finance</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'BF'"><b>Bank Finance</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'BK'"><b>Bank</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'BS'"><b>Building Society</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'CA'"><b>TransUnion</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'CB'"><b>Credit Broker</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'CC'"><b>Credit Card Company</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'CD'"><b>CC Consumer Data Capture</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'CU'"><b>Credit Union</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'DC'"><b>Debt Collection Agency</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'ED'"><b>EuroDirect</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'FC'"><b>Factoring</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'FH'"><b>Finance Housing</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'FN'"><b>Finance House</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'GO'"><b>Government</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'HC'"><b>Home Credit</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'HS'"><b>Home Shopping</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'IN'"><b>Insurance Company</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'IT'"><b>Internet/On Line Service</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'LS'"><b>Leasing Company</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'MC'"><b>Mortgage Company</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'MF'"><b>Motor Vehicle Finance Company</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'MO'"><b>Mail Order</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'RC'"><b>Rental Company</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'RT'"><b>Retailer</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'SC'"><b>Securities</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'SL'"><b>Student Loan</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'ST'"><b>Stockbroker</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'TC'"><b>Telecommunications Supplier</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'UT'"><b>Utility</b></p>
                                                    <p class="text-danger" *ngIf="links.supplierdetails.suppliertypecode._text == 'VR'"><b>Vehicle Rental</b></p>          
                                                </div>
                                            </div>   
                                        </div>   


                                    </ng-container>


                                        <ng-container *ngIf="links.length">
                                            <div class="overview_details width100"  *ngFor="let add of links;">
                                            <!-- <div class="alignbbb">
                                                <div class="headdd">
                                                    <h3 class="card-profile-name">{{ add.name._text}}</h3>            
                                                </div>           
                                            </div>
                                            <hr class="mt-0"/> -->
                                            <div class="profile_dtailsshow mb-3" >
                                                <div class="detailsshow width50">
                                                    <span>From</span>
                                                    <ng-container *ngFor="let add2 of reportData.applicant.addresslinks.addresses.address; let i1=index">

                                                        <ng-container *ngIf="add._attributes">
                                                        <p class="text-dark" *ngIf="add._attributes.from == add2._attributes.addressid">                
                                                            <b >{{add2._text}}</b>                    
                                                        </p></ng-container> 
                                                    </ng-container>                
                                                </div>
                                                <div class="detailsshow width50">
                                                    <span>To</span>
                                                    <p class="text-dark" *ngFor="let add2 of reportData.applicant.addresslinks.addresses.address; let i1=index">
                                                    
                                                        <ng-container *ngIf="add._attributes">
                                                            <b *ngIf="add._attributes.to == add2._attributes.addressid">{{add2._text}}</b>
                                                        </ng-container>
                                                    </p>
                                                </div>                                       
                                            </div>   
                                            <div class="profile_dtailsshow mb-3" *ngIf="add.creationdate" >
                                                <div class="detailsshow width25" >
                                                    <span>Confirmation Date</span>
                                                    <p class="text-danger"><b>{{add.creationdate._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width25"  *ngIf="add.lastconfdate">
                                                    <span>Earliest Confirmation</span>
                                                    <p class="text-danger"><b>{{add.lastconfdate._text}}</b></p>
                                                </div>                            
                                                <div class="detailsshow width50" *ngIf="add.supplierdetails" >
                                                    <span>Supplier Details</span>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'AF'"><b>Agricultural Finance</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'BF'"><b>Bank Finance</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'BK'"><b>Bank</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'BS'"><b>Building Society</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'CA'"><b>TransUnion</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'CB'"><b>Credit Broker</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'CC'"><b>Credit Card Company</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'CD'"><b>CC Consumer Data Capture</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'CU'"><b>Credit Union</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'DC'"><b>Debt Collection Agency</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'ED'"><b>EuroDirect</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'FC'"><b>Factoring</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'FH'"><b>Finance Housing</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'FN'"><b>Finance House</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'GO'"><b>Government</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'HC'"><b>Home Credit</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'HS'"><b>Home Shopping</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'IN'"><b>Insurance Company</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'IT'"><b>Internet/On Line Service</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'LS'"><b>Leasing Company</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'MC'"><b>Mortgage Company</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'MF'"><b>Motor Vehicle Finance Company</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'MO'"><b>Mail Order</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'RC'"><b>Rental Company</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'RT'"><b>Retailer</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'SC'"><b>Securities</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'SL'"><b>Student Loan</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'ST'"><b>Stockbroker</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'TC'"><b>Telecommunications Supplier</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'UT'"><b>Utility</b></p>
                                                    <p class="text-danger" *ngIf="add.supplierdetails.suppliertypecode._text == 'VR'"><b>Vehicle Rental</b></p>          
                                                </div>
                                            </div>   
                                        </div>   


                                    </ng-container>




                                    </div>
                                    <div class="overviewsshow mt-1" *ngIf="!links">
                                        No Record Found
                                    </div>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Notice of Correction ">
                                <ng-template matTabContent>
                                    <div class="overviewsshow mt-1" *ngIf="nocs">
                                        <div class="header">
                                        <h1 class="headprofile mt-0">Notice of Correction</h1>
                                        </div>
                                        


                                        

                                        <ng-container *ngIf="!nocs.length">

                                        <div class="overview_details width100">
                                            <div class="alignbbb">
                                                <div class="headdd" *ngIf="nocs.name">
                                                    <h3 class="card-profile-name">{{ nocs.name._text}}</h3>            
                                                </div>
                                                <p class="badge"  *ngIf="nocs.address" >
                                                    <span *ngIf="nocs.address._attributes.current == 1">Current Address:  {{nocs.address._text}}</span> 
                                                    <span *ngIf="nocs.address._attributes.current == 0">                    
                                                    <b *ngIf="nocs.address._attributes.undeclaredaddresstype =='1'">Undeclared intermediate Address Link:</b>
                                                    <b *ngIf="nocs.address._attributes.undeclaredaddresstype =='2'">Undeclared forwarding Address Link:</b>
                                                    <b *ngIf="nocs.address._attributes.undeclaredaddresstype =='3'">Undeclared previous Address Link:</b>
                                                    <b *ngIf="nocs.address._attributes.undeclaredaddresstype =='4'">Undeclared concurrent Address Link:</b>
                                                    <b *ngIf="nocs.address._attributes.undeclaredaddresstype =='5'">Undeclared miscellaneous Address Link:</b>
                                                    </span> {{nocs.address._text}}
                                                </p>
                                            </div>
                                            <hr class="mt-0"/>
                                            <div class="profile_dtailsshow mb-3"  *ngIf="nocs.refnum" >
                                                <div class="detailsshow width25">
                                                    <span>Reference Number</span>
                                                    <p class="text-dark" ><b>{{nocs.refnum._text}}</b></p>
                                                </div>            
                                                <div class="detailsshow width25" *ngIf="nocs.dateraised"  >
                                                    <span>Date of Correction</span>
                                                    <p class="text-danger"><b>{{nocs.dateraised._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width55" *ngIf="nocs.text" >
                                                    <span>Text</span>
                                                    <p class="text-danger"><b>{{nocs.text._text}}</b></p>
                                                </div>
                                            
                                            </div>      
                                        </div>  
                                        
                                        </ng-container>



                                        

                                        <ng-container *ngIf="nocs.length">
                                        <div class="overview_details width100"  *ngFor="let add of reportData.applicant.nocs.noc; let i=index">
                                            <div class="alignbbb">
                                                <div class="headdd">
                                                    <h3 class="card-profile-name">{{ add.name._text}}</h3>            
                                                </div>
                                                <p class="badge"  >
                                                    <span *ngIf="add.address._attributes.current == 1">Current Address:  {{add.address._text}}</span> 
                                                    <span *ngIf="add.address._attributes.current == 0">                    
                                                    <b *ngIf="add.address._attributes.undeclaredaddresstype =='1'">Undeclared intermediate Address Link:</b>
                                                    <b *ngIf="add.address._attributes.undeclaredaddresstype =='2'">Undeclared forwarding Address Link:</b>
                                                    <b *ngIf="add.address._attributes.undeclaredaddresstype =='3'">Undeclared previous Address Link:</b>
                                                    <b *ngIf="add.address._attributes.undeclaredaddresstype =='4'">Undeclared concurrent Address Link:</b>
                                                    <b *ngIf="add.address._attributes.undeclaredaddresstype =='5'">Undeclared miscellaneous Address Link:</b>
                                                    </span> {{add.address._text}}
                                                </p>
                                            </div>
                                            <hr class="mt-0"/>
                                            <div class="profile_dtailsshow mb-3" >
                                                <div class="detailsshow width25">
                                                    <span>Reference Number</span>
                                                    <p class="text-dark" ><b>{{add.refnum._text}}</b></p>
                                                </div>            
                                                <div class="detailsshow width25" >
                                                    <span>Date of Correction</span>
                                                    <p class="text-danger"><b>{{add.dateraised._text}}</b></p>
                                                </div>
                                                <div class="detailsshow width55" >
                                                    <span>Text</span>
                                                    <p class="text-danger"><b>{{add.text._text}}</b></p>
                                                </div>
                                            
                                            </div>      
                                        </div>  
                                        </ng-container>

                                        
                                    </div>    
                                    <div class="overviewsshow mt-1" *ngIf="!nocs">
                                        No Record Found
                                    </div>     
                                </ng-template>
                            </mat-tab>


                           

                        </mat-tab-group>
                    </div>
                </div>
            </div>
        


        </div>



        <div *ngIf="wizard == 3">
            <div class="row row-sm topheader">
                <div class="col-lg-5">
                    <div>
                        <app-checkcountshow></app-checkcountshow>                                   
                    </div>
                    
                </div>    
                
                <div class="col-lg-3 text-right">
                    <div class="d-flex justify-content-end align-items-center" *ngIf="historyid">
                        <button type="button" class="btn btn-primary com_btn" [innerHtml]="downloadtext" (click)="downloadAdverseCreditHistory(historyid,i)"></button>                          
                    </div> 
                </div>
                <div class="col-lg-4 text-right">
                    <div class="d-flex justify-content-end align-items-center">
                        <button class="btn com_btn btn-success" (click)="backwizard(0)">
                            <span class="lnr lnr-history pr-1"></span> Back To Adverse Credit Search
                        </button>
                    </div>   
                    
                </div>                               
            </div>
            <div class="user_profle_section">
                
                <div class="profile_box">
                    <div class="overviewsshow mt-3">
                        <div class="header">
                        <h1 class="headprofile width100 mt-0">Primary Applicant</h1>
                        </div>
                        <div class="overview_details width100">
                            <div class="alignbbb">
                                <div class="headdd" *ngIf="picklist.applicant.address">
                                    <h3 class="card-profile-name" *ngIf="searchFields">Credit Report: 
                                        {{searchFields.title}} {{searchFields.forename}} {{searchFields.sirname}}
                                        <!-- {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.title._text}} {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.forename._text}} {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.surname._text}} -->
                                    </h3>
                                </div>
                            </div>
                            <hr class="mt-0"/>
                            <div class="profile_dtailsshow mb-3" *ngIf="picklist.applicant.address"> 

                                <ng-container  *ngIf="picklist.applicant.address.fullmatches.fullmatch">
                                <div class="detailsshow "  *ngIf="picklist.applicant.address.fullmatches.fullmatch.name">
                                    <span>Name</span>
                                    <p> {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.title._text}} {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.forename._text}} {{picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.surname._text}}</p>
                                </div>
                            </ng-container>


                                <div class="detailsshow ">
                                <span>Current Address</span>
                                <p>

                                    <ng-container  *ngIf="picklist.applicant.address.fullmatches.fullmatch">
                                        <ng-container *ngIf="picklist.applicant.address.fullmatches.fullmatch.addressmatch.buildingno">
                                            {{(picklist.applicant.address.fullmatches.fullmatch.addressmatch.buildingno._text)?picklist.applicant.address.fullmatches.fullmatch.addressmatch.buildingno._text:''}}, 
                                        </ng-container>
                                    </ng-container>
                                   

                                    
                                <ng-container  *ngIf="picklist.applicant.address.fullmatches.fullmatch">
                                    <ng-container *ngIf="picklist.applicant.address.fullmatches.fullmatch.addressmatch.street1">  {{(picklist.applicant.address.fullmatches.fullmatch.addressmatch.street1._text)?picklist.applicant.address.fullmatches.fullmatch.addressmatch.street1._text:''}}, </ng-container>
                                        <ng-container *ngIf="picklist.applicant.address.fullmatches.fullmatch.addressmatch.posttown">  {{(picklist.applicant.address.fullmatches.fullmatch.addressmatch.posttown._text)?picklist.applicant.address.fullmatches.fullmatch.addressmatch.posttown._text:''}}, </ng-container>
                                            <ng-container *ngIf="picklist.applicant.address.fullmatches.fullmatch.addressmatch.postcode"> {{(picklist.applicant.address.fullmatches.fullmatch.addressmatch.postcode._text)?picklist.applicant.address.fullmatches.fullmatch.addressmatch.postcode._text:''}}</ng-container>
                                        </ng-container>
                                        
                                        </p>
                              
                                </div>
                                <!-- <div class="detailsshow ">
                                    <span>Amount</span>
                                    <p>Not Entered</p>
                                </div>
                                <div class="detailsshow ">
                                    <span>Term</span>
                                    <p>Not Entered</p>
                                </div> 
                                <div class="detailsshow "  *ngIf="picklist.applicant.address.fullmatches.fullmatch.name">
                                    <span>Date of birth</span>
                                    <p>{{(picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.dob._text)?picklist.applicant.address.fullmatches.fullmatch.name.namematches.namematch.dob._text:''}}</p>
                                </div>-->
                            </div>
                            <div class="profile_dtailsshow mb-3">
                            <!-- <div class="detailsshow ">
                                <span>Number of Applicant</span>
                                <p> 1</p>
                            </div> -->
                            <div class="detailsshow ">
                                <span>Credit Search Purpose</span>
                                <p *ngIf="creditrequest.purpose._text == 'EC'"> Employment Vetting</p>
                                <p *ngIf="creditrequest.purpose._text == 'TC'"> Tenant  Vetting</p>
                            </div>
                            <div class="detailsshow ">
                            <span>Credit Search Ref.</span>
                            <p>{{jobdetails.searchid._text}}</p>
                        </div>
                        <div class="detailsshow ">
                            <span>Date & Time of Search</span>
                            <p> {{jobdetails.searchdate._text}}</p>
                        </div>  
                        </div>
                        </div>
                       
                    </div>
                </div>
                <div class="details_box">
                    <div class="overviewsshow pb-0 pt-10 pr-0 pl-0">
                    
                <div class="row">
                        <div class="col-sm-12 aligncenter mb-3">
                        <div class="job_list_section visa_request_section" style="width: 50%; margin:0 auto;">
                            <div  class="job_detils_date">
                                <p  class="job_profile">No Record Found</p>
                            </div>
                        </div>
                    </div>
                </div>
                        
                       
                    
                    
                    </div>
                </div>
            </div>
        
        
        </div>


    </div>
</div>

 

  <jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">         
            <div class="modal-body">
                <div class="container text-center">
                  <div class="info_mess">
                      <div class="para">
                              <h4 class="mb-2">No credits available</h4>
                              <p class="mb-0 font20">There are no credits available to perform this action. Please add more credits.</p>
  
                          </div>                          
                      </div>
                </div>
            </div>
            <div class="modal-footer text-center">   
              <input type="button" class="btn btn-primary com_btn " name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
              <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
              <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
            </div>
        </div>
    </div>
  </jw-modal>