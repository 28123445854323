"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../_services/modal.service");
var forms_1 = require("@angular/forms");
var aml_service_1 = require("../../services/aml.service");
var router_1 = require("@angular/router");
var reference_service_1 = require("../../services/reference.service");
var checksystem_service_1 = require("../../services/checksystem.service");
var common_service_1 = require("../../services/common.service");
var alert_service_1 = require("../../services/alert.service");
var employee_service_1 = require("../../services/employee.service");
var user_service_1 = require("../../services/user.service");
var AddreferenceComponent = /** @class */ (function () {
    function AddreferenceComponent(modalService, formBuilder, employeeService, amldetails, router, userService, alerts, commonService, check, reference) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.employeeService = employeeService;
        this.amldetails = amldetails;
        this.router = router;
        this.userService = userService;
        this.alerts = alerts;
        this.commonService = commonService;
        this.check = check;
        this.reference = reference;
        this.totalCheck = 0;
        this.remainingCheck = 0;
        this.usedCheck = 0;
        this.submit = false;
        this.isvalidemail = true;
        this.stage = 0;
        this.defaultValue = '';
        this.defaultVal = '';
    }
    AddreferenceComponent.prototype.ngOnInit = function () {
        var _this = this;
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        // this.permission = permission[0];
        // if(permission[0]['m96'] < 3)
        // {
        //   this.router.navigate(['/permission-denied']);
        // }
        this.stage = 3;
        this.error = false;
        this.disableBtn = true;
        this.errormsg = '';
        this.buttoninsert = "Search";
        this.result = false;
        this.defaultVal = "";
        this.createNewRequestFormGroup = this.formBuilder.group({
            candidatename: ["", forms_1.Validators.required],
            candidateemail: ["", [forms_1.Validators.required, forms_1.Validators.email]],
            candidatephone: ["", [forms_1.Validators.required]],
            position: ["", [forms_1.Validators.required]],
            questionprofile: ["", [forms_1.Validators.required]],
            referencerequest: ["", [forms_1.Validators.required]],
            candidatemessage: [""],
            companyid: [""],
            userid: [""]
        });
        this.Userid = localStorage.getItem('userid');
        this.lesssore = false;
        var ts = { 'serviceid': 11 };
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            if (remaindata.remiancheck <= 0) {
                _this.lesssore = true;
            }
        });
        this.reference.getemplatelistBycompany().subscribe(function (referencelist) {
            _this.questionTemplatesLists = referencelist;
        });
        this.createNewRequestFormGroup.setValue({
            "candidatename": '',
            "candidateemail": '',
            "candidatephone": '',
            "position": "",
            "questionprofile": "",
            "referencerequest": "",
            "candidatemessage": "",
            "companyid": '',
            "userid": ''
        });
        // this.createNewRequestFormGroup.questionprofile.value ='';
        this.createNewRequestFormGroup.get("questionprofile").valueChanges
            .subscribe(function (f) {
            f = '';
            //console.log(f)
        });
        this.createNewRequestFormGroup.get("referencerequest").valueChanges
            .subscribe(function (f) {
            f = '';
            //console.log(f)
        });
        this.getEmployees();
    };
    Object.defineProperty(AddreferenceComponent.prototype, "f", {
        get: function () { return this.createNewRequestFormGroup.controls; },
        enumerable: true,
        configurable: true
    });
    AddreferenceComponent.prototype.createnewrequest = function (emplyeeselectlist) {
        this.stage = 0;
        this.submit = false;
        this.createNewRequestFormGroup.setValue({
            "candidatename": emplyeeselectlist.fname + ((emplyeeselectlist.mname) ? ' ' + emplyeeselectlist.mname : '') + ((emplyeeselectlist.lname) ? ' ' + emplyeeselectlist.lname : ''),
            "candidateemail": emplyeeselectlist.emailid,
            "candidatephone": emplyeeselectlist.phonenumber,
            "position": "",
            "questionprofile": "",
            "referencerequest": "",
            "candidatemessage": "",
            "companyid": emplyeeselectlist.companyid,
            "userid": emplyeeselectlist.employeemasterid
        });
    };
    AddreferenceComponent.prototype.existingapplicent = function () {
        this.stage = 3;
        this.getEmployees();
        this.createNewRequestFormGroup.setValue({
            "candidatename": "",
            "candidateemail": "",
            "candidatephone": "",
            "position": "",
            "questionprofile": "",
            "referencerequest": "",
            "candidatemessage": "",
            "companyid": 0,
            "userid": 0
        });
    };
    AddreferenceComponent.prototype.getEmployees = function () {
        var _this = this;
        this.employeeService.getlistofemployeeForCheck().subscribe(function (employeedata) {
            _this.data = employeedata;
            _this.record = _this.data.length;
            _this.datalist = _this.data;
            _this.pageOfItems = _this.data;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    AddreferenceComponent.prototype.createNewRequest = function () {
        var _this = this;
        this.submit = true;
        this.error = false;
        this.submitted = true;
        if (this.createNewRequestFormGroup.invalid) {
            return;
        }
        this.disableBtn = false;
        var newrequest = this.createNewRequestFormGroup.value;
        var userData = {
            companyid: newrequest.companyid,
            userid: newrequest.userid,
            position: newrequest.position,
            candidatename: newrequest.candidatename,
            candidateemail: newrequest.candidateemail,
            candidatephone: newrequest.candidatephone,
            modulequestiontemplateid: newrequest.questionprofile,
            numberofreferee: newrequest.referencerequest,
            candidatemessage: newrequest.candidatemessage,
            isactive: "Yes",
            moduleid: 0
        };
        var ts = { 'serviceid': 11 };
        // this.userService.isCompanyEmailRegisterd(newrequest.candidateemail).subscribe((data: any) => {
        //   if (data.sussess == 'success') {
        //     this.isvalidemail = false;this.disableBtn = true;
        //   }else{
        // this.isvalidemail =true;
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            if (remaindata.remiancheck > 0) {
                if (_this.isvalidemail) {
                    _this.reference.createNewRequest(userData).subscribe(function (response) {
                        if (response.susses == 'true') {
                            _this.disableBtn = true;
                            _this.stage = 1;
                        }
                        else {
                            _this.disableBtn = true;
                            _this.alerts.error('Somethink is wrong, please try again sometime or contact to administrator. ', true);
                        }
                    }, function (error) {
                        _this.disableBtn = true;
                    });
                }
            }
            else {
                _this.disableBtn = true;
            }
        });
        // }
        // });
    };
    AddreferenceComponent.prototype.newrequest = function () {
        this.submit = false;
        this.createNewRequestFormGroup.setValue({
            "candidatename": "",
            "candidateemail": "",
            "candidatephone": "",
            "position": "",
            "questionprofile": "",
            "referencerequest": "",
            "candidatemessage": "",
            "companyid": 1,
            "userid": 1
        });
        this.stage = 0;
    };
    AddreferenceComponent.prototype.onClear = function () {
        this.submit = false;
        this.createNewRequestFormGroup.setValue({
            "candidatename": "",
            "candidateemail": "",
            "candidatephone": "",
            "position": "",
            "questionprofile": "",
            "referencerequest": "",
            "candidatemessage": "",
            "companyid": 1,
            "userid": 1
        });
        this.disableBtn = true;
    };
    AddreferenceComponent.prototype.openModal = function (id, data) {
        this.buttoninsert = "Update";
        this.modalService.open(id);
    };
    AddreferenceComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    AddreferenceComponent.prototype.validateemail = function (event) {
        // this.userService.isCompanyEmailRegisterd(event.target.value).subscribe((data: any) => {
        //   if (data.sussess == 'success') {
        //     this.isvalidemail = false;
        //   }else{this.isvalidemail =true;}
        // });
        // this.check.validateemail(event.target.value).subscribe((response) => {
        //   if (response.status === "valid")
        //     this.isvalidemail = true;
        //   else
        //     this.isvalidemail = false;
        // });
    };
    AddreferenceComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    return AddreferenceComponent;
}());
exports.AddreferenceComponent = AddreferenceComponent;
