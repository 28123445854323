"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var expense_service_1 = require("../../services/expense.service");
var modal_service_1 = require("../../_services/modal.service");
var router_1 = require("@angular/router");
var app_global_1 = require("../../app.global");
var ExpensereportComponent = /** @class */ (function () {
    function ExpensereportComponent(dataset, router, modalService) {
        this.dataset = dataset;
        this.router = router;
        this.modalService = modalService;
        this.monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];
    }
    ExpensereportComponent.prototype.ngOnInit = function () {
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.month = (new Date()).getMonth() + 1;
        this.year = (new Date()).getFullYear();
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + (new Date()).getFullYear();
        this.classactive = ((new Date()).getMonth() + 1).toString();
        this.bindalldata();
    };
    ExpensereportComponent.prototype.bindalldata = function () {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
        this.dataset.getemployeeexpensereport(data).subscribe(function (data) {
            _this.record = data.length;
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.items = data;
            }
        });
    };
    ExpensereportComponent.prototype.getDataByMonth = function (mon) {
        this.classactive = mon;
        this.month = mon;
        this.yearmonth = this.monthNames[mon - 1] + ' ' + (new Date()).getFullYear();
        // this.year =  this.monthNames[this.month -1 ] +' '+ this.year;
        this.bindalldata();
    };
    ExpensereportComponent.prototype.onyearSubmit = function (leave) {
        // this.year =  this.monthNames[this.month -1 ] +' '+ leave.year;
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + (new Date()).getFullYear();
        this.year = leave;
        this.bindalldata();
        // this.modalService.close(id);
    };
    ExpensereportComponent.prototype.yearpop = function (id) {
        this.modalService.open(id);
    };
    ExpensereportComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    ExpensereportComponent.prototype.openModalnote = function (data, id) {
        this.notedetails = data;
        this.modalService.open(id);
    };
    ExpensereportComponent.prototype.getDataByYear = function (yea) {
        var _this = this;
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + yea; //(new Date()).getFullYear();
        // this.year =  this.monthNames[this.month -1 ] +' '+ yea;
        // this.year = yea;
        var data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
        this.dataset.getemployeeexpensereport(data).subscribe(function (data) {
            _this.record = data.length;
            _this.data = data;
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.items = data;
            }
        });
    };
    return ExpensereportComponent;
}());
exports.ExpensereportComponent = ExpensereportComponent;
