"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var app_global_1 = require("../app.global");
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
// import { Response } from "@angular/http";
// import { Observable } from 'rxjs/Observable';
require("rxjs/add/operator/map");
var common_service_1 = require("../services/common.service");
var UserService = /** @class */ (function () {
    function UserService(http, commonService) {
        this.http = http;
        this.commonService = commonService;
        this.rootUrl = app_global_1.AppGlobals.API_ENDPOINT + '/login';
        this.ssologin = app_global_1.AppGlobals.API_ENDPOINT + '/ssologin';
        this.logoutUrl = app_global_1.AppGlobals.API_ENDPOINT + '/logout';
        this.joinnowUrl = app_global_1.AppGlobals.API_ENDPOINT + '/joinnow';
        this.forgotusername = app_global_1.AppGlobals.API_ENDPOINT + '/forget-username-request';
        this.forgotRequestApi = app_global_1.AppGlobals.API_ENDPOINT + '/forget-password-request';
        // readonly forgotPasswordApi = AppGlobals.API_ENDPOINT + '/api/Users/reset-password';
        this.checkaccess = app_global_1.AppGlobals.API_ENDPOINT + '/checkaccesstoken';
        this.checkaccesstokenusingid = app_global_1.AppGlobals.API_ENDPOINT + '/checkaccesstokenbyid';
        this.checkmessageaccess = app_global_1.AppGlobals.API_ENDPOINT + '/checkaccesstokenbyusers';
        this.checkemailexist = app_global_1.AppGlobals.API_ENDPOINT + '/checkemailexist';
        this.checkCompanyemailexist = app_global_1.AppGlobals.API_ENDPOINT + '/checkCompanyemailexist';
        this.checkemailexistforadmin = app_global_1.AppGlobals.API_ENDPOINT + '/checkemailexistcheckforadmin';
        this.UserMessageReply = app_global_1.AppGlobals.API_ENDPOINT + '/usermessagereply';
        this.forgotPasswordApi = app_global_1.AppGlobals.API_ENDPOINT + '/reset-password-bytoken';
        this.checkUserNamedApi = app_global_1.AppGlobals.API_ENDPOINT + '/checkusername';
    }
    UserService.prototype.ngOnInit = function () {
        // subscribe to router event
    };
    UserService.prototype.userAuthentication = function (data) {
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this.http.post(this.rootUrl, JSON.stringify(data), { headers: reqHeader });
    };
    UserService.prototype.ssoUser = function (data) {
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this.http.post(this.ssologin, JSON.stringify(data), { headers: reqHeader });
    };
    UserService.prototype.userMessageReply = function (data) {
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this.http.post(this.UserMessageReply, JSON.stringify(data), { headers: reqHeader });
    };
    UserService.prototype.userjoinnow = function (data) {
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this.http.post(this.joinnowUrl, JSON.stringify(data), { headers: reqHeader });
    };
    UserService.prototype.forgotuser = function (data) {
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this.http.post(this.forgotusername, JSON.stringify(data), { headers: reqHeader });
    };
    UserService.prototype.resetPassword = function (resetapi, data) {
        var access_token = localStorage.getItem('userToken');
        // const data = {'oldPassword': oldPassword, 'newPassword': newPassword};
        var reqHeader = new http_1.HttpHeaders({
            'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': access_token
        });
        return this.http.post(resetapi, JSON.stringify(data), { headers: reqHeader });
    };
    UserService.prototype.checkUserName = function (data) {
        // const access_token = localStorage.getItem('userToken');
        // const data = {'oldPassword': oldPassword, 'newPassword': newPassword};
        return this.commonService.formpost('/checkusername', data);
        // const reqHeader = new HttpHeaders(
        //   {
        //     'Content-Type': 'application/json',
        //     'access_token': access_token,
        //     'Authorization': access_token
        //   });
        //   console.log(this.checkUserNamedApi);
        // return this.http.post(this.checkUserNamedApi, data, { headers: reqHeader });
    };
    UserService.prototype.logout = function () {
        var access_token = localStorage.getItem('userToken');
        var reqHeader = new http_1.HttpHeaders({
            'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': access_token
        });
        if (access_token != null)
            return this.commonService.formpostOnlyid('/logout'); // this.http.post(AppGlobals.API_ENDPOINT + '/logout', { headers: reqHeader });
        else {
            var reqHeader2 = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
            return this.http.post(this.logoutUrl, { headers: reqHeader2 });
        }
    };
    UserService.prototype.forgotRequest = function (email) {
        var data = { 'username': email };
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this.http.post(this.forgotRequestApi, JSON.stringify(data), { headers: reqHeader });
    };
    UserService.prototype.isEmailRegisterdCheckForAdmin = function (email) {
        var data = { 'email': email };
        var access_token = localStorage.getItem('userToken');
        var reqHeader = new http_1.HttpHeaders({
            'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': access_token
        });
        return this.http.post(this.checkemailexistforadmin, JSON.stringify(data), { headers: reqHeader });
    };
    UserService.prototype.isEmailRegisterd = function (email) {
        var data = { 'email': email };
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this.http.post(this.checkemailexist, JSON.stringify(data), { headers: reqHeader });
    };
    UserService.prototype.isCompanyEmailRegisterd = function (email) {
        var access_token = localStorage.getItem('userToken');
        var data = { 'email': email };
        var reqHeader = new http_1.HttpHeaders({
            'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': access_token
        });
        // const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this.http.post(this.checkCompanyemailexist, JSON.stringify(data), { headers: reqHeader });
    };
    UserService.prototype.isUserRegisterd = function (email) {
        var data = { 'username': email };
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this.http.post(this.checkUserNamedApi, JSON.stringify(data), { headers: reqHeader });
    };
    UserService.prototype.checkaccesstoken = function (access_token) {
        var data = { 'id': access_token };
        return this.http.post(this.checkaccess, data);
    };
    UserService.prototype.checkaccesstokenbyid = function (userid) {
        var data = { 'id': userid };
        var access_token = localStorage.getItem('userToken');
        // const data = {'oldPassword': oldPassword, 'newPassword': newPassword};
        var reqHeader = new http_1.HttpHeaders({
            'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': access_token
        });
        return this.http.post(this.checkaccesstokenusingid, data, { headers: reqHeader });
    };
    UserService.prototype.checkaccessbyreply = function (access_token) {
        var data = { 'id': access_token };
        return this.http.post(this.checkmessageaccess, data);
    };
    UserService.prototype.forgotPassword = function (newPassword, access_token) {
        var access_tokenl = localStorage.getItem('userToken');
        var data = { 'newPassword': newPassword };
        var reqHeader = new http_1.HttpHeaders({
            'Content-Type': 'application/json',
            'access_token': access_token
        });
        return this.http.post(this.forgotPasswordApi + "?access_token=" + access_token, JSON.stringify(data), { headers: reqHeader });
    };
    return UserService;
}());
exports.UserService = UserService;
