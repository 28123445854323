import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PlanService } from '../services/plan.service';
import { PaymentService } from '../services/payment.service';
import { Plan } from '../models/plan.model';
import { ModalService } from '../_services/modal.service';
import { Router } from '@angular/router';
import { StripeScriptTag } from "stripe-angular";
import { StripeToken } from "stripe-angular";
import { AlertService } from '../services/alert.service';
import { UserService } from '../services/user.service';
import { CompanyService } from '../services/company.service';
import { AppGlobals } from '../app.global';

@Component({
  selector: 'app-updatestripeplan',
  templateUrl: './updatestripeplan.component.html',
  styleUrls: ['./updatestripeplan.component.css']
})

export class UpdatestripeplanComponent implements OnInit {
  buttoninsert: any;
  Userid: any;
  data: any;
  planDetail = new Plan();
  planformGroup: FormGroup;
  planlist: any;
  submitted: any;
  totaluser: any;
  totalused: any;
  message: string;
  stptoken: any;
  planid: any;
  extraData: any;
  invalidError: any;
  
 private publishableKey: string =AppGlobals.API_STRIPE;// "pk_live_vE86NSZoPYH0MsJIdsYfQ8L6";
//private publishableKey: string = "pk_test_dIWR2H2xFoFj3I8vXg8eS4dw";

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private router: Router,
    private alerts: AlertService,
    private plan: PlanService,    
    public companyService: CompanyService,
    private userService: UserService,
    public StripeScriptTag: StripeScriptTag,
    public paymentService: PaymentService
    ) { }

  ngOnInit() {
    this.StripeScriptTag.setPublishableKey( this.publishableKey );
    this.planformGroup = this.formBuilder.group({
      coupnsnumber: ['', Validators.required]
    });

    var isemail = localStorage.getItem('isemail');
    var planid = localStorage.getItem('planid');

    // if(isemail == null && isemail == '0')
    // {      
    //   this.router.navigate(['/otp']);      
    // }
    // else if(planid != null && planid != '0')
    // {
    //   this.router.navigate(['/dashboard']);            
    // }

    this.companyService.checkstripecustomer().subscribe((data: any) => {
      // console.log(data);
      if(data != null)
      {
        if(data.deleted)
        {
          //this.router.navigate(['/create-customer']);
        }
        else{
        this.router.navigate(['/dashboard']);
        }
      } 
    });

    const type = {"plantype": '0'};
    this.plan.getplanlist(type).subscribe((data: any) => {
      // console.log(data);
      this.planlist = data;
    });
  }

  get f() { return this.planformGroup.controls; }

  onSubmit(settingexpensetypedata: any, id: string) {
    this.submitted = true;
    if (this.planformGroup.invalid) {
      return;
    }   
    
    this.plan.checkcounpons(settingexpensetypedata).subscribe((data: any) => {      
      if (data.lenght != 0) {
        this.totaluser = data.totaluser;
        this.totalused = data.totalused;
        if ((this.totaluser - this.totalused) != 0) { 
          this.message = "Coupons applied successfully";
          var db = { "couponsnumber": settingexpensetypedata.coupnsnumber };
          this.plan.getplanlistwithcoupons(db).subscribe((data4: any) => {
            this.planlist = data4;
            this.modalService.close(id);
          });
        }
        else {
          this.message = "Coupons limit expire";
        }
      }
    });
  }

  onStripeInvalid( error:Error ){
    // console.log('Validation Error', error)
  }
 
  setStripeToken( token:StripeToken, id: any ){
    this.stptoken = token.id;
    var planupdate = {"planid": this.planid};
    // console.log('Stripe token', token);
    var data = {
      "token": token.id,
      "cmonth": token.card.exp_month, 
      "cyear": token.card.exp_year, 
      "cardnumber": token.card.last4, 
      "tokenid": token.card.id, 
      "cardname": token.card.brand, 
      "country": token.card.country, 
      "cstatus": "1",
      "planid": this.planid
    };
    this.paymentService.createCustomer(data).subscribe((data: any) => {
      // console.log(data);
      if(data.status == "success")
      {
        this.alerts.success("Plan successfully created", true);
        localStorage.setItem('planid', "1");
        localStorage.setItem('isemail', "true");
        this.router.navigate(['/successful-payment']);
        this.modalService.close(id);
      }
      else
      {
        this.alerts.error("Payment did not succeed", true);
      }
    //   this.paymentService.customerCharge({"token": data.id, "planid": this.planid}).subscribe((data: any) => {
    //     console.log(data);
    //     if(data.status == "succeeded")
    //     {
    //       this.paymentService.createSubscription({"customer": data.customer, "planid": this.planid}).subscribe((data: any) => {
    //         console.log(data);
    //         // this.plan.updatecompanyplan(planupdate).subscribe((data: any) => {   
  
    //         // });
    //       });
    //     }
        
    //   });
    });
  }

  selectPlanType(event)
  {
    // console.log(event);
    const type = {"plantype": event};
    this.plan.getplanlist(type).subscribe((data: any) => {
      this.planlist = data;
    });
  }
 
  onStripeError( error:Error ){
    console.error('Stripe error', error)
  }
  
  updateyourplan(planid: string, id: string) {
    // console.log(planid);
    this.planid = planid;
    this.modalService.open(id);
  }

  openModal(id: string) {
    this.buttoninsert = "Apply Coupons";
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
  
  logout() {
    if (confirm("Are you sure you want to sign out?")) {
      this.userService.logout().subscribe((data: any) => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('emptype');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('isemail');
        this.router.navigate(['/login']);
      });
    }
  }
}