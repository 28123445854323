import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Expensetype,Hourtype } from '../../../models/expense/expensetype.model';
import { WorklistService } from '../../../services/worklist.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settinghourtype',
  templateUrl: './settinghourtype.component.html',
  styleUrls: ['./settinghourtype.component.css']
})
export class SettinghourtypeComponent implements OnInit {
  Userid: any;
  data: any;
  hourDetail = new Hourtype();
  hourformGroup: FormGroup;
  options = [];
  buttoninsert: any;
  submitted: boolean = false;
  public searchText: string;
  disableBtn: any;
  permission: any;
  settingperm: any;
  record:number;
  
  items: Array<any>;
  
  pageOfItems: Array<any>;
  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private worklist: WorklistService
  ) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.settingperm = this.permission.m81;

    this.Userid = localStorage.getItem('userid');
    this.hourDetail = new Hourtype();
    this.getexpensetypegroup();

    const sstatustype = { 'status': false, 'id': this.Userid } 
    this.worklist.gethourtypelist(sstatustype).subscribe((expensetypelist: any) => {
      this.data = expensetypelist;
      this.record = this.data.length;

      if(this.record < 30){
        this.pageOfItems = this.data;
      }
      else{
           this.items =this.data;
      }
    });
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  getexpensetypegroup()
  {
    this.hourformGroup = this.formBuilder.group({
      id: [''],
      typename: ['', Validators.required]
    });
  }

  getholidaytype()
  {
    this.hourformGroup = this.formBuilder.group({
      id: [''],
      typename: ['', Validators.required]
    });
  }
  get f() { return this.hourformGroup.controls; }

  onSubmit(formhourtype: any, id: string) {
    // var formhourtype = settinghourtypedata.value;
    this.submitted = true;
    if (this.hourformGroup.invalid) {
      return;
    }  
    this.disableBtn = false;  
    formhourtype.userid = this.Userid;
    this.worklist.settinghourtypeUpdate(formhourtype).subscribe((response: any) => {
      const sstatustype = { 'status': false, 'id': this.Userid } 
      this.worklist.gethourtypelist(sstatustype).subscribe((expensetypelist: any) => {
        this.data = expensetypelist;
        this.record = this.data.length;
  
        if(this.record < 30){
          this.pageOfItems = this.data;
        }
        else{
             this.items =this.data;
        }
        this.modalService.close(id);
      });
    });
  }

  onChangeStatus(evt: any, hourtype: any)
  {
    var cstatus;
    if(evt.target.checked)
    {
      cstatus = '1';
    }
    else if(!evt.target.checked)
    {
      cstatus = '0';
    }
    this.worklist.hourtypeUpdateChangeStatus({'userid': this.Userid, 'id': hourtype.id, 'cstatus': evt.target.checked}).subscribe((data: any) => {
      const sstatustype = { 'status': false, 'id': this.Userid } 
      this.worklist.gethourtypelist(sstatustype).subscribe((expensetypelist: any) => {
        this.data = expensetypelist;
      });
    });
  }

  openModal(id: string, data: any) {
    this.buttoninsert = "Update";
    this.disableBtn = true;
    this.hourDetail = data;
    this.modalService.open(id);
  }

  openModal2(id: string) {
    this.submitted = false;
    this.buttoninsert = "Submit";
    this.disableBtn = true;
    this.hourDetail = new Hourtype();
    this.getexpensetypegroup();
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

}
