import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReferenceService } from '../../services/reference.service';
import { AlertService } from '../../services/alert.service';

import { ChecksystemService } from '../../services/checksystem.service';

@Component({
  selector: 'app-userdeclinereference',
  templateUrl: './userdeclinereference.component.html',
  styleUrls: ['./userdeclinereference.component.css']
})
export class UserdeclinereferenceComponent implements OnInit {

  token: any;
  rd: string;
  cd: string;
  declinereason: any;
  logo:any;
  token_expired: any = false;
  decline_expired: any = false;

  stage: any =0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private referencingmoduleService:ReferenceService,
    private alerts: AlertService,  
    private check: ChecksystemService,  
  ) {
    
  }

  ngOnInit() {
    this.token=this.activatedRoute.snapshot.queryParams['token']
    this.rd=this.activatedRoute.snapshot.queryParams['rd']
    // this.cd=this.activatedRoute.snapshot.queryParams['cd'] 
    localStorage.setItem('userToken1',this.token);

    console.log(this.token);

    this.check.gettokensystembytoken().subscribe((data: any) => { 
      console.log(data);
      if (data.sussess == 'true') {

        this.stage=0;
       
        if (data.logourl == "" || data.logourl == null)  {
          this.logo = "assets/img/logo.png";
        }else
        {
          this.logo =data.logourl;
        }               
      }
      else
      {

        this.stage=1;
        this.token_expired = true;
        // this.token_expired = false;
      }
    });

  }

  declineclicked(){
    var data={
      "declinedbycandidate": "Yes",
      "declinedbycandidaterreason": this.declinereason,
      "id": this.rd
    }
    this.referencingmoduleService.declinereferencerequest(data).subscribe((response: any)=>{
        this.alerts.success('Refrence request has been declined successfully');
        this.stage=2;
        this.decline_expired = true;
    });
    
  }

}
