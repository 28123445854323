import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-idaiwizard',
  templateUrl: './idaiwizard.component.html',
  styleUrls: ['./idaiwizard.component.css']
})
export class IdaiwizardComponent implements OnInit {
  employeeidproofdeatilid:any;
  constructor( private activatedRoute: ActivatedRoute,private router: Router) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    this.employeeidproofdeatilid = this.activatedRoute.snapshot.paramMap.get("idproofid");
  }

}
