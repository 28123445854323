import { AccessToken } from './../../shared/sdk/models/BaseModels';
import { HeaderComponent } from './../header/header.component';
import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { ModalService } from '../../services/modal.service';
import { Working } from '../../models/employment/working.model';
import { EmployeeService } from '../../services/employee.service';

@Component({
  selector: 'app-employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.css']
})

export class EmploymentComponent implements OnInit {
  emp = new Working();
  empltype: any;
  otherDetail: any;
  Userid: any;

  constructor(private data: CustomerService,
    private employeeService: EmployeeService) { }

  ngOnInit() {    
    this.Userid = localStorage.getItem('userid');
    this.empltype =  localStorage.getItem('emptype');
    const data = { 'userid': this.Userid };
    this.employeeService.getOtherInfo(data).subscribe((data: any) => {
      this.otherDetail = data;
    });

    this.empltype =  localStorage.getItem('emptype');
    this.data.getEmployment().subscribe((data: any) => {
      this.emp = data;
    });
  }
}

// @Component({
//   selector: 'app-employment',
//   templateUrl: './employment.component.html',
//   styleUrls: ['./employment.component.css']
// })
// export class EmploymentComponent implements OnInit {
//   title = 'rajai';

//   users: any [];

//   constructor(private data: CustomerService) { }

//   ngOnInit() {
//    const id = localStorage.getItem('userToken');
//     this.data.getEmployment(id).subscribe((data: any) => {
//       this.users = data.profile;
//       // this.users = this.users;
//     }
//     );
//   }
//   openModalDialog() {
//     const id = localStorage.getItem('userToken');
//     this.data.getEmployment(id).subscribe((data: any) => {
//       this.users = data.profile;
//       // this.users = this.users;
//     }
//     );
//     // this.display = 'block';
//   }
// }
