import { Component, OnInit } from '@angular/core';
import { CountryService } from '../services/country.service';
import { MyidproofService } from '../services/myidproof.service';
import { ModalService } from '../_services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Idproof } from '../models/idproof/idproof.model';
import { UploadFileService } from './../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-myidproof',
  templateUrl: './myidproof.component.html',
  styleUrls: ['./myidproof.component.css']
})
export class MyidproofComponent implements OnInit {
  idprooflist: any[] = null;
  idproofDetail: any;
  countrylist: any;
  documenttype: any;
  Userid: any;
  listcheck: any;
  selectedFiles: FileList;
  iframeidproofsrc: any;
  filerequirederror: any = false;
  filesizeerror: any;
  filereuirederror: any;
  fileformaterror: any;
  formattedMessage: any;
  imgidproofbacksrc:any;
  iframeidproofbacksrc:any;
  imgidproofsrc: any;
  buttoninsert: any;
  filebackrequirederror: any = false;
  filebacksizeerror: any;
  filebackformaterror: any;
  selectedbackFiles: FileList;
  filebackname: any;
  imagesecond: any;
  file: any;
  filename: any;
  defaultVal: any = "";
  filterItems = [
    {
      name: 'All',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Pending Approval',
      id: 1,
      selected: false
    }
  ]
  submitted: any;
  idproofpopForm: FormGroup;
  idproof: Idproof;

  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private data: MyidproofService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private router: Router,
    private alerts: AlertService
  ) { }

  ngOnInit() {
    this.defaultVal ="";
    this.createformgroup();
    this.filename = 'Choose file';
    this.filebackname = 'Choose file';
    this.idproofDetail = new Idproof();
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    this.data.getidproofAllList(data).subscribe((data: any) => {      
      this.idprooflist = data;
    });

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.data.iddocumenttype().subscribe((documenttype: any) => {
      this.documenttype = documenttype;
    });
    this.listcheck = this.filterItems;
  }

  createformgroup()
  {
    this.idproofpopForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      idnumber: ['', Validators.required],
      idtype: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      docexpiry: ['', Validators.required]
    });
  }

  onDelete(id: number) {
    const data1 = { 'id': id, 'userid': this.Userid };
    if (confirm("Are you sure you want to delete?")) {
      this.data.deleteidproof(data1).subscribe((data: any) => {
        var sstatus: any;
        for (let c of this.filterItems) {
          if (c.selected == true) {
            if (c.id == 0) {
              sstatus = [1, 2, 3];
            }
            else {
              sstatus = [c.id];
            }
          }
        }
        const data2 = { 'id': this.Userid, 'cstatus': sstatus };
        this.data.getidproofAllList(data2).subscribe((data: any) => {
          this.idprooflist = data;
        });
        this.alerts.success('Address Proof deleted successfully.', true);
      });
    }
  }

  onlistchange(type1: any, idval: number) {
    var sstatus: any;
    for (let c of this.filterItems) {
      if (c.id == idval)
        c.selected = true;
      else
        c.selected = false;
    }
    if (idval == 0 || type1 == false) {
      sstatus = [1, 2, 3];
    }
    else {
      sstatus = [idval];
    }
    var userid = localStorage.getItem('userid');
    const data = { 'id': userid, 'cstatus': sstatus };
    this.data.getidproofAllList(data).subscribe((data: any) => {
      this.idprooflist = data;
    });
  }

  get f() {
    return this.idproofpopForm.controls;
  }
  
  onSubmit(value3: any, id: string) {
    console.log(value3);
    this.submitted = true;
    this.fileformaterror = false;
    
    if (this.idproofpopForm.invalid) {
      if(value3.id == '0')
      {
        if(!this.selectedFiles)
          this.filerequirederror = true;
        // if(!this.selectedbackFiles)
        //   this.filebackrequirederror = true;
      }
      return;
    }
    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") ) {
        this.fileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.imagename = imagepath;
    }
    else {
      if(value3.id == '0')
      {
        this.filerequirederror = true;
        return;
      }
      value3.imagename = this.idproofDetail.iddocName;
    }

    if (this.selectedbackFiles) {
      this.file = this.selectedbackFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filebacksizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
        this.filebackformaterror = true;
        return;
      }
      var imagepath =  this.uploadService.uploadfile(this.file);
      value3.imagebackname = imagepath;
    }
    else {
      if(value3.id == '0')
        {
         value3.imagebackname = '';
         }
       else {
        value3.imagebackname = this.idproofDetail.idbackdocName;
       }
      // if(value3.id == '0')
      // {
      //   this.filebackrequirederror = true;
      //   return;
      // }
     
    }

    
    const sd = new Date(value3.docexpiry);
    value3.docexpiry = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
   

    this.data.myidproofdetailsUpdate(value3).subscribe((data: any) => {
      var sstatus: any;
      for (let c of this.filterItems) {
        if (c.selected == true) {
          if (c.id == 0) {
            sstatus = [1, 2, 3];
          }
          else {
            sstatus = [c.id];
          }
        }
      }
      var userid = localStorage.getItem('userid');
      const data1 = { 'id': userid, 'cstatus': sstatus };
      this.data.getidproofAllList(data1).subscribe((data: any) => {
        this.idprooflist = data; 
      });
    });
    this.filename = "Choose file";
    this.alerts.success('Address Proof updated successfully.', true);    
    // this.idproofDetail = new idproof();
    this.modalService.close(id);
  }


  
  selectFile(event)
   {
    this.filerequirederror = false;
    this.filesizeerror = false; 
    this.fileformaterror = false;
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.fileformaterror = true;  
    }else{
      this.selectedFiles = files[0];
    }
  }

  selectbackFile(event)
   {
     this.filebackrequirederror = false;
     this.filebackformaterror = false;    
     this.filebacksizeerror = false; 
    var files = event.target.files;
    this.filebackname = files[0].name;
    this.selectedbackFiles = this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filebacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filebackformaterror = true;
    }else{
      this.selectedbackFiles = files[0];
    }
  }

  // selectFile(event)
  //  {
  //   var files = event.target.files;
  //   this.filename = files[0].name;
  //   this.selectedFiles = files[0];
  // }

  openModal2(id: string) 
  {
    this.filerequirederror = false;
    this.filesizeerror = false; 
    this.fileformaterror = false;
    this.filebackrequirederror = false;
     this.filebackformaterror = false;    
     this.filebacksizeerror = false; 
    // this.createformgroup();
    this.submitted = false;
    this.buttoninsert = "Submit";this.selectedFiles = null;
    this.fileformaterror = false;
    this.idproofDetail = new Idproof();    
    this.Userid = localStorage.getItem('userid');
    this.idproofDetail.employeeid = this.Userid;
    this.idproofDetail.employeeidproofdeatilid = "0";  
    this.idproofDetail.nationalityid = "70";  
    this.idproofDetail.idtype ='';
    // this.idproofDetail.idproofexpiry ='';
    // this.idproofDetail.idproofstart = '';
    // this.idproofDetail.idprooftype = '';
    // this.idproofDetail.nationalityid = '';
    this.filename = "Choose file";
    this.filebackname = 'Choose file';
    this.modalService.open(id);
  }

  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModalidproof(id: string, data: any, backimg:any) 
  {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgidproofsrc = true;
      this.iframeidproofsrc = data;
    }
    else {
      this.imgidproofsrc = false;
      this.iframeidproofsrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    if(backimg)
    {
      var fullpathArray = backimg.split('.');
      var typeoffile = fullpathArray[fullpathArray.length - 1];
      if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
        this.imgidproofbacksrc = true;
        this.iframeidproofbacksrc = backimg;
      } 
      else {
        this.imgidproofbacksrc = false;
        this.iframeidproofbacksrc = this.getSafeUrl("https://docs.google.com/gview?url=" + backimg + "&embedded=true");
      }
    }
    else {
      this.imgidproofbacksrc = false;
      this.iframeidproofbacksrc = '';
    }
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.filerequirederror = false;
    this.filesizeerror = false; 
    this.fileformaterror = false;
    this.filebackrequirederror = false;
     this.filebackformaterror = false;    
     this.filebacksizeerror = false; 
    this.submitted = false;
    this.selectedFiles = null;
    this.fileformaterror = false;
    this.idproofDetail = new Idproof();  
    // console.log(data);  
    this.idproofDetail = data;
    this.buttoninsert = "Update";
    this.filename = "Choose file";
    this.filebackname = 'Choose file';
    this.modalService.open(id);
  }

  closeModal(id: string) 
  {
    this.idproof = new Idproof();
    // this.idproofDetail = new idproof();
    const data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    this.data.getidproofAllList(data).subscribe((data: any) => {
      this.idprooflist = data;
    });
    this.modalService.close(id);
  }
}