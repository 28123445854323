<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
      <div class="tabs">
        <div class="tabs__content">
          <div class="tabs__content__item  active-tab">
            <div class="content">
              <div class="row">
                <div class="col-sm-3">
                  <div class="ref_userlist configbox">
                    <h1>Question Profiles</h1>
                    <ul class="configue">
                      <li>
                        <span class="textshhs">Template 234455</span>
                        <div class="icons_box">
                          <span class="pull-right"><i class="fa fa-trash-o"></i></span>
                          <span class="pull-right"><i class="fa fa-pencil"></i></span>
                        </div>
                      </li>
                      <li>
                        <span class="textshhs">Template 234455</span>
                        <div class="icons_box">
                          <span class="pull-right"><i class="fa fa-trash-o"></i></span>
                          <span class="pull-right"><i class="fa fa-pencil"></i></span>
                        </div>
                      </li>
                      <li>
                        <span class="textshhs">Template 234455</span>
                        <div class="icons_box">
                          <span class="pull-right"><i class="fa fa-trash-o"></i></span>
                          <span class="pull-right"><i class="fa fa-pencil"></i></span>
                        </div>
                      </li>
                      <li class="active">
                        <span class="textshhs">Template 234455</span>
                        <div class="icons_box">
                          <span class="pull-right"><i class="fa fa-trash-o"></i></span>
                          <span class="pull-right"><i class="fa fa-pencil"></i></span>
                        </div>
                      </li>
                      <li>
                        <span class="textshhs">Template 234455</span>
                        <div class="icons_box">
                          <span class="pull-right"><i class="fa fa-trash-o"></i></span>
                          <span class="pull-right"><i class="fa fa-pencil"></i></span>
                        </div>
                      </li>
                      <li>
                        <span class="textshhs">Template 234455</span>
                        <div class="icons_box">
                          <span class="pull-right"><i class="fa fa-trash-o"></i></span>
                          <span class="pull-right"><i class="fa fa-pencil"></i></span>
                        </div>
                      </li>
                    </ul>
                    <div class="con_newpro">
                      <button type="submit" class="btn btn-danger">New Profile</button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-9">
                  <div class="addQuestion">
                    <h4>Default</h4>
                    <button type="submit" class="btn btn-outline-dark">
                      Add New Question
                    </button>
                  </div>
                  <div class="info_bar">
                    <div class="contentbox">
                      <h5>Please Note</h5>
                      <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. </p>
                    </div>
                    <i class="fa fa-close"></i>
                  </div>
                  <div class="content_list_box">
                    <div class="grid_box">
                      <div class="draggable">
                        <div class="ans_text">
                          <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly </p>
                          <div class="optionaa">
                            <span>Yes</span>
                          </div>
                        </div>
                        <div class="iconsbox_req">
                          <div class="requied">
                            <span>Requied</span>
                            <i class="fa fa-check"></i>
                          </div>
                          <div class="staus">
                            <span>Dropdown</span>
                          </div>
                          <div class="icons">
                            <i data-toggle="modal" data-target="#createquestion-modal" class="fa fa-pencil"></i>
                            <i data-toggle="modal" data-target="#question-warning-modal" class="fa fa-trash-o"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid_box">
                      <div class="draggable">
                        <div class="ans_text">
                          <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly </p>
                          <div class="optionaa">
                            <span>Yes</span>
                          </div>
                        </div>
                        <div class="iconsbox_req">
                          <div class="requied">
                            <span>Requied</span>
                            <i class="fa fa-check"></i>
                          </div>
                          <div class="staus">
                            <span>Dropdown</span>
                          </div>
                          <div class="icons">
                            <i data-toggle="modal" data-target="#createquestion-modal" class="fa fa-pencil"></i>
                            <i data-toggle="modal" data-target="#question-warning-modal" class="fa fa-trash-o"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid_box">
                      <div class="draggable">
                        <div class="ans_text">
                          <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly </p>
                          <div class="optionaa">
                            <span>Yes</span>
                          </div>
                        </div>
                        <div class="iconsbox_req">
                          <div class="requied">
                            <span>Requied</span>
                            <i class="fa fa-check"></i>
                          </div>
                          <div class="staus">
                            <span>Dropdown</span>
                          </div>
                          <div class="icons">
                            <i data-toggle="modal" data-target="#createquestion-modal" class="fa fa-pencil"></i>
                            <i data-toggle="modal" data-target="#question-warning-modal" class="fa fa-trash-o"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid_box">
                      <div class="draggable">
                        <div class="ans_text">
                          <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly </p>
                          <div class="optionaa">
                            <span>Yes</span>
                          </div>
                        </div>
                        <div class="iconsbox_req">
                          <div class="requied">
                            <span>Requied</span>
                            <i class="fa fa-check"></i>
                          </div>
                          <div class="staus">
                            <span>Dropdown</span>
                          </div>
                          <div class="icons">
                            <i data-toggle="modal" data-target="#createquestion-modal" class="fa fa-pencil"></i>
                            <i data-toggle="modal" data-target="#question-warning-modal" class="fa fa-trash-o"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid_box">
                      <div class="draggable">
                        <div class="ans_text">
                          <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly </p>
                          <div class="optionaa">
                            <span>Yes</span>
                          </div>
                        </div>
                        <div class="iconsbox_req">
                          <div class="requied">
                            <span>Requied</span>
                            <i class="fa fa-check"></i>
                          </div>
                          <div class="staus">
                            <span>Dropdown</span>
                          </div>
                          <div class="icons">
                            <i data-toggle="modal" data-target="#createquestion-modal" class="fa fa-pencil"></i>
                            <i data-toggle="modal" data-target="#question-warning-modal" class="fa fa-trash-o"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid_box">
                      <div class="draggable">
                        <div class="ans_text">
                          <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly </p>
                          <div class="optionaa">
                            <span>Yes</span>
                          </div>
                        </div>
                        <div class="iconsbox_req">
                          <div class="requied">
                            <span>Requied</span>
                            <i class="fa fa-check"></i>
                          </div>
                          <div class="staus">
                            <span>Dropdown</span>
                          </div>
                          <div class="icons">
                            <i data-toggle="modal" data-target="#createquestion-modal" class="fa fa-pencil"></i>
                            <i data-toggle="modal" data-target="#question-warning-modal" class="fa fa-trash-o"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid_box">
                      <div class="draggable">
                        <div class="ans_text">
                          <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly </p>
                          <div class="optionaa">
                            <span>Yes</span>
                          </div>
                        </div>
                        <div class="iconsbox_req">
                          <div class="requied">
                            <span>Requied</span>
                            <i class="fa fa-check"></i>
                          </div>
                          <div class="staus">
                            <span>Dropdown</span>
                          </div>
                          <div class="icons">
                            <i data-toggle="modal" data-target="#createquestion-modal" class="fa fa-pencil"></i>
                            <i data-toggle="modal" data-target="#question-warning-modal" class="fa fa-trash-o"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid_box">
                      <div class="draggable">
                        <div class="ans_text">
                          <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly </p>
                          <div class="optionaa">
                            <span>Yes</span>
                          </div>
                        </div>
                        <div class="iconsbox_req">
                          <div class="requied">
                            <span>Requied</span>
                            <i class="fa fa-check"></i>
                          </div>
                          <div class="staus">
                            <span>Dropdown</span>
                          </div>
                          <div class="icons">
                            <i data-toggle="modal" data-target="#createquestion-modal" class="fa fa-pencil"></i>
                            <i data-toggle="modal" data-target="#question-warning-modal" class="fa fa-trash-o"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid_box">
                      <div class="draggable">
                        <div class="ans_text">
                          <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly </p>
                          <div class="optionaa">
                            <span>Yes</span>
                          </div>
                        </div>
                        <div class="iconsbox_req">
                          <div class="requied">
                            <span>Requied</span>
                            <i class="fa fa-check"></i>
                          </div>
                          <div class="staus">
                            <span>Dropdown</span>
                          </div>
                          <div class="icons">
                            <i data-toggle="modal" data-target="#createquestion-modal" class="fa fa-pencil"></i>
                            <i data-toggle="modal" data-target="#question-warning-modal" class="fa fa-trash-o"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid_box">
                      <div class="draggable">
                        <div class="ans_text">
                          <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly </p>
                          <div class="optionaa">
                            <span>Yes</span>
                          </div>
                        </div>
                        <div class="iconsbox_req">
                          <div class="requied">
                            <span>Requied</span>
                            <i class="fa fa-check"></i>
                          </div>
                          <div class="staus">
                            <span>Dropdown</span>
                          </div>
                          <div class="icons">
                            <i data-toggle="modal" data-target="#createquestion-modal" class="fa fa-pencil"></i>
                            <i data-toggle="modal" data-target="#question-warning-modal" class="fa fa-trash-o"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
