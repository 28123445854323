<div class="signin-wrapper">
    <div class="new_login">
        <div class="login_section">
            <div class="dflexotp_top">
                <a class="login_logo"  [routerLink]="['/']" ><img src="/assets/img/logo.png" alt=""></a>
                <a href="#" class="header-notification float-right" data-toggle="dropdown" (click)="logout()"
                    title="Logout"> <i class="fa fa-power-off"></i> </a>
            </div>
           
            <div class="signin-box">
                <form [formGroup]="registerForm" #loginForm class="login_form" (ngSubmit)="onSubmit()" *ngIf="success">
                    <p class="text-muted text-center" id="msg" runat="server">Please enter correct email below</p>
                    <div class="form-group mg-b-30" *ngIf="show">
                        <p class="red">This email already exists. Please try another email.</p>
                    </div>

                    <div class="form-group mg-b-30">
                        <div class="input-group">
                            <input type="email" formControlName="email" #Email [(ngModel)]="user.email"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                placeholder="Please Enter Email">
                            <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-lock"
                                        aria-hidden="true"></i></span>
                            </div>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Please enter Email</div>
                                <div *ngIf="f.email.errors.email">Please enter valid email</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group m-t-10 mb-0 row">
                        <button class="btn btn-primary btn-block btn-signin" type="submit">Submit</button>
                    </div>

                    <div class="form-group m-t-10 mb-0 row">
                        <div class="col-12 m-t-20 text-center">
                            <i class="mdi mdi-user"></i>If the above email is correct, <a (click)="resedotp();"
                                class="text-muted text-center hand"
                                style="color:#ff322e !important; cursor: pointer;">Click Here</a> to get a new OTP.
                        </div>
                    </div>
                </form>

                <div class="form-group mg-b-30" *ngIf="showsuccess">
                    <p class="red">Email updated successfully <a [routerLink]="['/login']"
                            class="text-muted text-center hand" style="color:#ff322e !important;">Click Here</a> to
                        login in your account. </p>
                </div>
            </div>
        </div>
        <div class="graphic_section">
            <div class="slogan">
                <h4>Welcome To <b>Complygate</b></h4>
                <span>Commit. Control. Comply</span>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</div>