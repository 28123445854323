<!-- <div class="slim-header">
  <div class="container">
    <div class="slim-header-left">
      <h2 class="slim-logo"><a [routerLink]="['/login']"><img src="/assets/img/logo.png" alt=""><span></span></a></h2>
    </div>
  </div>
</div> -->
<div class="bg">
    <div class="signin-wrapper">
        <div class="new_login">
            <div class="login_section">
                <a class="login_logo"  [routerLink]="['/']" ><img src="/assets/img/logo.png" alt=""></a>
                <div class="signin-box">
                    <form [formGroup]="registerForm" #loginForm="ngForm" class="login_form" (ngSubmit)="forgotRequest(username.value)">
                        <!-- <div class="form-group mg-b-30">
              <div class="input-group">
                <ng-container *ngIf="msg">
                  <div class="alert alert-success">
                    <strong>Success!</strong> {{msg}}
                  </div>
                </ng-container>
                <ng-container *ngIf="error">
                  <div class="alert alert-danger">
                    <strong>Error </strong> {{error}} 
                  </div>
                </ng-container>
              </div>
            </div> -->
                        <div class="form-group mg-b-30">
                            <div class="input-group">
                                <input type="text" formControlName="username" #username name="username" [(ngModel)]="user.username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" class="form-control" placeholder="Please enter registered username." required>
                                <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-user" aria-hidden="true"></i></span>
                                </div>
                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required">Please enter registered username</div>
                                    
                                </div>
                            </div>
                        </div>
                        <!-- row -->
                        <button class="btn btn-primary btn-block btn-signin" style="width:100%" type="submit">Reset Password</button>
                        <p class="mg-b-0 usefulllink "><a [routerLink]="['/login']" class="fortgot text-center col-sm-12">Back to login</a></p>
                    </form>
                    <!-- </div> -->
                </div>
                <!-- col-8 -->
            </div>
            <div class="graphic_section">
                <div class="slogan">
                    <h4>Welcome To <b>Complygate</b></h4>
                    <span>Commit. Control. Comply</span>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>