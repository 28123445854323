
// import { EmployeeMaster } from './../models/employee_master';
// import { HeaderComponent } from './user/header/header.component';
import { Component, OnInit } from '@angular/core';
import { MycosService } from '../../../services/mycos.service';
import { ModalService } from '../../../_services/modal.service';
import { Cos } from '../../../models/cos/cos.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFileService } from './../../../services/upload-file.service';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-coslist',
  templateUrl: './coslist.component.html',
  styleUrls: ['./coslist.component.css']
})
export class CoslistComponent implements OnInit {

  empltype: any;
  employeedetails: any[];
  submitted: any;
  cosForm: FormGroup;
  filename: any;
  filesizeerror: any;
  fileformaterror: any;
  file: any;
  employeementlist: any[];
  employeementDetail: Cos;
  cosDetails: any;
  cosdetails: any;
  empid: any;
  buttoninsert: any;
  Userid: any;
  selectedFiles: FileList;
  imgcossrc: boolean;
  disableBtn: any;
  iframecossrc: any;
  isfile: any;
  imagepath: any;


constructor(
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder, 
    private modalService: ModalService, 
    private data: MycosService,
    private alerts: AlertService,
    private uploadService: UploadFileService, 
    private activatedRoute: ActivatedRoute
    ) { }

  ngOnInit() {
    this.Userid = localStorage.getItem('userid');
    this.isfile =false;
    this.empid = this.activatedRoute.snapshot.paramMap.get("id");
    this.cosForm = this.formBuilder.group({
      id: ['', Validators.required],
      iscurrent: [''],
      cosnumber: ['', Validators.required],
      cosstartdate: ['', Validators.required],
      cosexpirydate: ['', Validators.required],
      cosdocname: [''],
      employeeid: ['', Validators.required]
    });

    this.cosdetails = new Cos();
    this.data.getcosListbyadmin({'id': this.empid}).subscribe((data: any) => {      
      this.cosDetails = data;
    });
  }

  get f() { return this.cosForm.controls; }

  onSubmit(formcos: any, id: string) 
  {
    // var formcos = value3.value;
    this.submitted = true;
    if(formcos.iscurrent == 0)
    {
      formcos.iscurrent= false;
    }
    if(formcos.iscurrent == 1)
    {
      formcos.iscurrent= true;
    }
    
    if (this.cosForm.invalid) 
    {      
      return;
    }
    this.disableBtn = false;
    if(this.selectedFiles)
    {
      // this.file = this.selectedFiles;
      // var filetype = this.file.type.split('/');
      // var typeoffile = filetype[filetype.length - 1];
      // if(this.file.size > 2000*1024)
      // {
      //   this.filesizeerror = true;
      //   return;
      // }
      // else if((typeoffile != "jpeg") && (typeoffile != "jpg") && (typeoffile != "png") && (typeoffile != "gif") && (typeoffile != "tif") && (typeoffile != "pdf") && (typeoffile != "doc")  && (typeoffile != "docx"))
      // {
      //   this.fileformaterror = true;
      //   return;
      // }
      // var imagepath = this.uploadService.uploadfile(this.file);
      // formcos.cosdocname = imagepath;
      formcos.cosdocname = this.imagepath;
    }
    else
    {
      formcos.cosdocname = this.cosdetails.cosdocname;
    }
    formcos.createby = this.Userid;
    formcos.updateby = this.Userid;
   

    
    const sd = new Date(formcos.cosstartdate);
    formcos.cosstartdate = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    const ed = new Date(formcos.cosexpirydate);
    formcos.cosexpirydate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());




    this.data.mycosdetailsUpdatebyadmin(formcos).subscribe((data: any) => {
      this.data.getcosListbyadmin({'id': this.empid}).subscribe((data: any) => {
        // value3.reset();
        this.cosDetails = data;
      });
    });
    this.alerts.success('COS Info Updated Successfully',true);
    this.modalService.close(id);
    // this.cosdetails = new Cos();
  }

  selectFile(event) {
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = files[0];

    this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      var typeoffile = filetype[filetype.length - 1];
      if(this.file.size > 2000*1024)
      {
        this.filesizeerror = true;
        return;
      }
      else if((typeoffile != "jpeg") && (typeoffile != "jpg") && (typeoffile != "png") && (typeoffile != "gif") && (typeoffile != "tif") && (typeoffile != "pdf") && (typeoffile != "doc")  && (typeoffile != "docx"))
      {
        this.fileformaterror = true;
        return;
      }
      this.imagepath = this.uploadService.uploadfile(this.file);
      var dd = {'url': this.imagepath}
      this.data.mycosaibyadmin(dd).subscribe((data: any) => {
        this.isfile = true;
        // console.log(data);
      });
      
  }

  openModal(id: string, data: any) {
    this.submitted = false;
    this.buttoninsert = "Update";
    this.disableBtn = true;
    this.cosdetails = data;
    if(this.cosdetails.iscurrent === true)
    {
      this.cosdetails.iscurrent = 1;
    }
    if(this.cosdetails.iscurrent === false)
    {
      this.cosdetails.iscurrent = 0;
    }
    this.modalService.open(id);
  }

  openModal2(id: string) {
    this.submitted = false;
    this.disableBtn = true;
    this.filename = "Choose File";
    // this.cosForm.invalid == false;
    this.cosdetails = new Cos();
    this.buttoninsert = "Submit";
    this.cosdetails.employeeid = this.empid;
    this.cosdetails.employecosdetailid = "0";
    this.cosdetails.iscurrent = 0;
    // this.cosDetails.cosstartdate = new Date();
    // this.cosdetails.cosnumber ='';
    // this.cosdetails.cosstartdate ='';
    // this.cosdetails.cosexpirydate ='';
    // this.cosdetails.cosdocname ='';
    this.cosdetails.iscurrent = false;
    this.modalService.open(id);
  }

  onDelete(cosid: number)
  {
    if (confirm("Are you sure you want to delete?")) {
      const data1 = { 'id': cosid };
      this.data.mycosDeletebyadmin(data1).subscribe((data: any) => {
        this.data.getcosListbyadmin({'id': this.empid}).subscribe((data: any) => {
          this.cosDetails = data;
        });
      })
    }
  }

  closeModal(id: string) {
    // this.employeementDetail = new Cos();
    this.data.getcosListbyadmin({'id': this.empid}).subscribe((data: any) => {
      this.cosDetails = data;
    });
    this.modalService.close(id);
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModal3(id: string, data: any) {
    this.iframecossrc = '';
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif'))
    {
     this.imgcossrc = true;
     this.iframecossrc = data; 
    }
    else{
     this.imgcossrc = false;
     this.iframecossrc = this.getSafeUrl("https://docs.google.com/gview?url="+data+"&embedded=true");
    }
     this.modalService.open(id);
   }


}

