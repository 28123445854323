"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./checkaddcredit.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/router");
var i3 = require("../../checkpaynow/checkpaynow.component.ngfactory");
var i4 = require("../../checkpaynow/checkpaynow.component");
var i5 = require("../../../_services/modal.service");
var i6 = require("stripe-angular/StripeScriptTag");
var i7 = require("../../../services/company.service");
var i8 = require("../../../services/checkservice.service");
var i9 = require("../../../user/header/header.component.ngfactory");
var i10 = require("../../../user/header/header.component");
var i11 = require("../../../services/customer.service");
var i12 = require("../../../services/user.service");
var i13 = require("../../../admin/invoice/invioce-shared/invioce-shared.component.ngfactory");
var i14 = require("../../../admin/invoice/invioce-shared/invioce-shared.component");
var i15 = require("@angular/common");
var i16 = require("../../../partials/checkout/checkout.component.ngfactory");
var i17 = require("../../../partials/checkout/checkout.component");
var i18 = require("@angular/forms");
var i19 = require("../../../services/checksystem.service");
var i20 = require("../../../services/payment.service");
var i21 = require("../../../services/alert.service");
var i22 = require("../../../services/country.service");
var i23 = require("@angular/platform-browser");
var i24 = require("../../../_directives/modal.component.ngfactory");
var i25 = require("../../../_directives/modal.component");
var i26 = require("../../../../../node_modules/stripe-angular/components/StripeCard.component.ngfactory");
var i27 = require("stripe-angular/components/StripeCard.component");
var i28 = require("./checkaddcredit.component");
var styles_CheckaddcreditComponent = [i0.styles];
var RenderType_CheckaddcreditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckaddcreditComponent, data: {} });
exports.RenderType_CheckaddcreditComponent = RenderType_CheckaddcreditComponent;
function View_CheckaddcreditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", " upgraderequest"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["To access Complygate Compliance Centre, please upgrade your account. "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["class", "btn btn-primary com_btn small_btn ng-star-inserted float-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(-1, null, ["Upgrade Your Account"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, "/upgrade-your-membership"); _ck(_v, 3, 0, currVal_0); }, null); }
function View_CheckaddcreditComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", " upgraderequest"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["To access Complygate Compliance Centre, please upgrade your account. "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["class", "btn btn-primary com_btn small_btn ng-star-inserted float-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(-1, null, ["Upgrade Your Account"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, "/upgrade-your-membership"); _ck(_v, 3, 0, currVal_0); }, null); }
function View_CheckaddcreditComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-checkpaynow", [], null, null, null, i3.View_CheckpaynowComponent_0, i3.RenderType_CheckpaynowComponent)), i1.ɵdid(1, 114688, null, 0, i4.CheckpaynowComponent, [i5.ModalService, i6.StripeScriptTag, i7.CompanyService, i8.CheckserviceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_CheckaddcreditComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "errormessa"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invalidError.message; _ck(_v, 1, 0, currVal_0); }); }
function View_CheckaddcreditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i9.View_HeaderComponent_0, i9.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i10.HeaderComponent, [i11.CustomerService, i2.Router, i12.UserService, i7.CompanyService, i1.Renderer2, i1.Renderer2], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "section-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "tabs"], ["id", "tabs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "tabs__sontent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-invioce-shared", [], null, null, null, i13.View_InvioceSharedComponent_0, i13.RenderType_InvioceSharedComponent)), i1.ɵdid(7, 114688, null, 0, i14.InvioceSharedComponent, [i2.Router], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "overviewsshow mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckaddcreditComponent_1)), i1.ɵdid(11, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckaddcreditComponent_2)), i1.ɵdid(13, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-checkout", [], null, [[null, "getRemainingChecks"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getRemainingChecks" === en)) {
        var pd_0 = (_co.getRemainingChecks() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_CheckoutComponent_0, i16.RenderType_CheckoutComponent)), i1.ɵdid(15, 114688, null, 0, i17.CheckoutComponent, [i15.DOCUMENT, i18.FormBuilder, i6.StripeScriptTag, i19.ChecksystemService, i8.CheckserviceService, i5.ModalService, i7.CompanyService, i20.PaymentService, i21.AlertService, i22.CountryService, i23.DomSanitizer], null, { getRemainingChecks: "getRemainingChecks" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckaddcreditComponent_3)), i1.ɵdid(17, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 16, "jw-modal", [["class", "modal"], ["id", "custom-payment-12"], ["role", "dialog"]], null, null, null, i24.View_ModalComponent_0, i24.RenderType_ModalComponent)), i1.ɵdid(19, 245760, null, 0, i25.ModalComponent, [i5.ModalService, i1.ElementRef], { id: [0, "id"] }, null), (_l()(), i1.ɵeld(20, 0, null, 0, 14, "div", [["class", "formgroup"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 13, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckaddcreditComponent_4)), i1.ɵdid(23, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 5, "div", [["class", "col-lg-12 col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "label", [["class", "text-center mb-2 textinfoqer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please enter your credit card or debit card"])), (_l()(), i1.ɵeld(27, 0, null, null, 2, "div", [["class", "boxpayddd checkaddcredit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "stripe-card", [], null, [[null, "catch"], [null, "invalidChange"], [null, "tokenChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("catch" === en)) {
        var pd_0 = (_co.onStripeError12($event) !== false);
        ad = (pd_0 && ad);
    } if (("invalidChange" === en)) {
        var pd_1 = ((_co.invalidError = $event) !== false);
        ad = (pd_1 && ad);
    } if (("tokenChange" === en)) {
        var pd_2 = (_co.setStripeToken12($event, "custom-payment-12") !== false);
        ad = (pd_2 && ad);
    } return ad; }, i26.View_StripeCard_0, i26.RenderType_StripeCard)), i1.ɵdid(29, 114688, [["stripeCard", 4]], 0, i27.StripeCard, [i1.ElementRef, i6.StripeScriptTag], { invalid: [0, "invalid"] }, { catcher: "catch", invalidChange: "invalidChange", tokenChange: "tokenChange" }), (_l()(), i1.ɵeld(30, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "button", [["class", "btn btn-primary com_btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 29).createToken(_co.extraData) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Pay Now"])), (_l()(), i1.ɵeld(33, 0, null, null, 1, "button", [["class", "btn btn-dark com_btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal("custom-payment-12") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 7, 0); var currVal_0 = (_co.ctype == 3); _ck(_v, 11, 0, currVal_0); var currVal_1 = (_co.ctype == 2); _ck(_v, 13, 0, currVal_1); _ck(_v, 15, 0); var currVal_2 = !_co.showAddCheck; _ck(_v, 17, 0, currVal_2); var currVal_3 = "custom-payment-12"; _ck(_v, 19, 0, currVal_3); var currVal_4 = _co.invalidError; _ck(_v, 23, 0, currVal_4); var currVal_5 = _co.invalidError; _ck(_v, 29, 0, currVal_5); }, null); }
exports.View_CheckaddcreditComponent_0 = View_CheckaddcreditComponent_0;
function View_CheckaddcreditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-checkaddcredit", [], null, null, null, View_CheckaddcreditComponent_0, RenderType_CheckaddcreditComponent)), i1.ɵdid(1, 114688, null, 0, i28.CheckaddcreditComponent, [i19.ChecksystemService, i8.CheckserviceService, i6.StripeScriptTag, i7.CompanyService, i2.Router, i5.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CheckaddcreditComponent_Host_0 = View_CheckaddcreditComponent_Host_0;
var CheckaddcreditComponentNgFactory = i1.ɵccf("app-checkaddcredit", i28.CheckaddcreditComponent, View_CheckaddcreditComponent_Host_0, {}, {}, []);
exports.CheckaddcreditComponentNgFactory = CheckaddcreditComponentNgFactory;
