"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var core_1 = require("@angular/core");
var myaddress_service_1 = require("../services/myaddress.service");
// import { ModalService } from '../../services/modal.service';
var modal_service_1 = require("../_services/modal.service");
var country_service_1 = require("../services/country.service");
var common_service_1 = require("../services/common.service");
// import { AlertPromise } from 'selenium-webdriver';
var address_model_1 = require("../models/address/address.model");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var alert_service_1 = require("../services/alert.service");
var MyaddressComponent = /** @class */ (function () {
    function MyaddressComponent(formBuilder, data, country, modalService, commonService, http, router, alerts) {
        this.formBuilder = formBuilder;
        this.data = data;
        this.country = country;
        this.modalService = modalService;
        this.commonService = commonService;
        this.http = http;
        this.router = router;
        this.alerts = alerts;
        this.address = new address_model_1.Address();
        this.addresslist = null;
        this.addressdata = null;
        this.address2 = 0;
        this.myControl = new forms_1.FormControl();
        this.options = [];
        this.filterItems = [];
        this.currentDate = new Date();
        this.defaultVal = "";
    }
    MyaddressComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.currentdatevalue = true;
        this.addpopForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            address1: ['', forms_1.Validators.required],
            address2: [''],
            cityname: ['', forms_1.Validators.compose([forms_1.Validators.required,])],
            //  mobilenumber: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{10}")])],
            mobilenumber: [''],
            movedin: ['', forms_1.Validators.required],
            movedout: [''],
            statename: ['', forms_1.Validators.required],
            pincode: ['', forms_1.Validators.required],
            iscurrent: [''],
            stateid: [''],
            // phonenumber: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{10}")])],
            phonenumber: [''],
            countryid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])]
        });
        var id = localStorage.getItem('userToken');
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
        this.data.getemploymenttList(data).subscribe(function (data) {
            _this.addresslist = data;
            _this.addressdata = data;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.listcheck = this.filterItems;
    };
    Object.defineProperty(MyaddressComponent.prototype, "f", {
        get: function () { return this.addpopForm.controls; },
        enumerable: true,
        configurable: true
    });
    MyaddressComponent.prototype.onSubmit = function (value3, id) {
        var _this = this;
        this.submitted = true;
        this.movedoutdateerror = "";
        //console.log(value3);
        if (!value3.iscurrent) {
            value3.iscurrent = false;
        }
        if (value3.iscurrent) {
            value3.iscurrent = true;
        }
        if (!value3.iscurrent && !value3.movedout) {
            this.movedoutdateerror = "Please select Moved Out Date";
        }
        if (this.addpopForm.invalid || this.movedoutdateerror != "") {
            return;
        }
        var pinc = value3.pincode.split(',');
        value3.pincode = pinc[pinc.length - 1];
        if ((value3.iscurrent == true)) {
            this.data.myaddresschangecurrent(value3).subscribe(function (data) {
            });
        }
        var sd = new Date(value3.movedin);
        value3.movedin = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        if (value3.movedout) {
            var ed = new Date(value3.movedout);
            value3.movedout = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        }
        this.data.myaddressdetailsUpdate(value3).subscribe(function (data) {
            _this.Userid = localStorage.getItem('userid');
            var data1 = { 'id': _this.Userid, 'cstatus': [1, 2, 3] };
            _this.data.getemploymenttList(data1).subscribe(function (data) {
                var data2 = { 'id': data.countryid };
                _this.country.getstatelist(data2).subscribe(function (state) {
                    _this.statelist = state;
                });
                _this.addressdata = data;
                _this.addresslist = data;
            });
        });
        this.alerts.success('Address updated successfully', true);
        this.modalService.close(id);
        // this.addresslistDetail = new Address();
    };
    MyaddressComponent.prototype.number = function (input) {
        var isnumber = input.value > 0;
        return isnumber ? null : true;
    };
    MyaddressComponent.prototype.onSelectCountry = function (country_id) {
        var _this = this;
        var data = { 'id': country_id };
        this.country.getstatelist(data).subscribe(function (state) {
            return _this.statelist = state;
        });
    };
    MyaddressComponent.prototype.onlistchange = function (type1, idval) {
        var _this = this;
        var sstatus;
        for (var _i = 0, _a = this.filterItems; _i < _a.length; _i++) {
            var c = _a[_i];
            if (c.id == idval)
                c.selected = true;
            else
                c.selected = false;
        }
        if (idval == 0 || type1 == false) {
            sstatus = [1, 2, 3];
        }
        else {
            sstatus = [idval];
        }
        var userid = localStorage.getItem('userid');
        var data = { 'id': userid, 'cstatus': sstatus };
        this.data.getemploymenttList(data).subscribe(function (data) {
            _this.addresslist = data;
            _this.addressdata = data;
        });
    };
    MyaddressComponent.prototype.onSearchChange = function (searchValue) {
        var _this = this;
        if (searchValue.length > 4) {
            var newArray = [];
            this.commonService.getAddress(searchValue).subscribe(function (data) {
                var newdata;
                newdata = data;
                newdata.addresses.forEach(function (value) {
                    newArray.push(value + "," + searchValue);
                });
                _this.options = newArray;
            });
        }
        else {
            this.options = [];
        }
    };
    MyaddressComponent.prototype.displayFn = function (value) {
        if (value != null) {
            var res = value.split(",");
            if (res.length > 6) {
                return res[7];
            }
            else {
                return value;
            }
        }
    };
    MyaddressComponent.prototype.updatecode = function (evt, response) {
        res = [];
        if (evt.source.selected) {
            var res = response.split(",");
            this.addresslistDetail.address1 = res[0];
            this.addresslistDetail.address2 = res[1];
            this.addresslistDetail.cityname = res[5];
            this.addresslistDetail.pincode = res[7];
            this.addresslistDetail.statename = res[6];
            // const data = { 'id': this.addresslistDetail.countryid };
            // this.country.getstatelist(data).subscribe((state: any) => {
            //   for (var i = 0; i < state.length; i++) {
            //     if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
            //       var inde = state[i]['stateid'];
            //     }
            //   }
            //   this.addresslistDetail.stateid = inde;
            //   this.statelist = state;
            // });
        }
    };
    MyaddressComponent.prototype.onDelete = function (empid) {
        var _this = this;
        if (confirm("Are you sure you want to delete?")) {
            var data1 = { 'id': empid };
            this.data.myaddressDelete(data1).subscribe(function (data) {
                var sstatus;
                for (var _i = 0, _a = _this.filterItems; _i < _a.length; _i++) {
                    var c = _a[_i];
                    if (c.selected == true) {
                        if (c.id == 0) {
                            sstatus = [1, 2, 3];
                        }
                        else {
                            sstatus = [c.id];
                        }
                    }
                }
                var userid = localStorage.getItem('userid');
                var data1 = { 'id': userid, 'cstatus': sstatus };
                _this.data.getemploymenttList(data1).subscribe(function (data) {
                    return _this.addressdata = data;
                });
            });
        }
    };
    MyaddressComponent.prototype.currentaddresschange = function (eve, addressid) {
        if (eve.target.value == 1) {
            this.addresslistDetail.movedout = "";
            this.currentdatevalue = false;
        }
        else {
            this.currentdatevalue = true;
        }
    };
    MyaddressComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    MyaddressComponent.prototype.openModal = function (id, data) {
        var _this = this;
        if (data.iscurrent == true) {
            // data.iscurrent = 1;
            this.currentdatevalue = false;
        }
        else {
            // data.iscurrent = 0;
            this.currentdatevalue = true;
        }
        this.movedoutdateerror = "";
        this.buttoninsert = "Update";
        this.addresslistDetail = data;
        if (this.addresslistDetail.iscurrent === true) {
            this.addresslistDetail.iscurrent = 1;
        }
        if (this.addresslistDetail.iscurrent === false) {
            this.addresslistDetail.iscurrent = 0;
        }
        var data2 = { 'id': data.countryid };
        this.country.getstatelist(data2).subscribe(function (state) {
            _this.statelist = state;
        });
        this.modalService.open(id);
    };
    MyaddressComponent.prototype.openModal2 = function (id) {
        this.movedoutdateerror = "";
        this.submitted = false;
        this.addpopForm.invalid == false;
        this.addresslistDetail = new address_model_1.Address();
        this.buttoninsert = "Submit";
        this.Userid = localStorage.getItem('userid');
        this.addresslistDetail.employeeid = this.Userid;
        this.addresslistDetail.employeeaddresdeatilid = "0";
        this.addresslistDetail.countryid = "";
        this.addresslistDetail.iscurrent = '1';
        this.currentdatevalue = false;
        // this.addresslistDetail.address1 ='';
        // this.addresslistDetail.address2 ='';
        // this.addresslistDetail.pincode ='';
        // this.addresslistDetail.cityname ='';
        // this.addresslistDetail.stateid ='';
        // this.addresslistDetail.phonenumber ='';
        // this.addresslistDetail.mobilenumber ='';
        this.modalService.open(id);
    };
    MyaddressComponent.prototype.closeModal = function (id) {
        var _this = this;
        // this.addresslistDetail = new Address();
        this.Userid = localStorage.getItem('userid');
        var data1 = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
        this.data.getemploymenttList(data1).subscribe(function (data) {
            var data2 = { 'id': data.countryid };
            _this.country.getstatelist(data2).subscribe(function (state) {
                _this.statelist = state;
            });
            _this.addressdata = data;
            _this.addresslist = data;
        });
        // this.addresslistDetail = new Address();
        this.modalService.close(id);
    };
    MyaddressComponent.prototype.modalOpen = function (data) {
        this.addresslistDetail = data;
    };
    MyaddressComponent.prototype.retFalse = function (e) {
        return false;
    };
    return MyaddressComponent;
}());
exports.MyaddressComponent = MyaddressComponent;
