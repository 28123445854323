import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { Visa } from '../../../models/visa/visa.model';
import { Aml,AmlBussiness } from '../../../models/aml/aml.model';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { AmlService } from '../../../services/aml.service';
import * as moment from 'moment';
import { CountryService } from '../../../services/country.service';
import { ChecksystemService } from '../../../services/checksystem.service';
import {  ElementRef, HostListener, Input } from '@angular/core';
import { ThrowStmt } from '@angular/compiler';
import { int } from 'aws-sdk/clients/datapipeline';
import { Router, ActivatedRoute, Params } from '@angular/router';

import {Observable} from 'rxjs/Observable';
import {startWith} from 'rxjs/operators/startWith';
import {map} from 'rxjs/operators/map';
import { DateTime } from 'aws-sdk/clients/devicefarm';

export class State {
  constructor(public countryid: int, public countryname: string, public createat: Date,public isid: string, public iso: string, public isothree: string, public ispassport: string, public isrighttoworkcountry: string,
    public isvisa: string,
    public sstatus: string,
    public updateat: DateTime) { }
}


@Component({
  selector: 'app-searchpersonalaml',
  templateUrl: './searchpersonalaml.component.html',
  styleUrls: ['./searchpersonalaml.component.css']
})
export class SearchpersonalamlComponent implements OnInit {


  visaDetail: any;
  visa: Visa;
  disableBtn: any;
  buttoninsert: any;
  Threshold:int;
  errormsg: any;
  userSelectsString = '';
  countrySelectsString = '';
  resourceId:any;
  error: boolean;  
  submitted: boolean;  
  personalformGroup: FormGroup;
  businessDetail =new Aml();
  searchresult: any;
  result: boolean;
  defaultVal: string ='';
  countrylist:any;
  datasetlist: any;
  bussinessdetails:any;
  lesssore:boolean;
  Userid: any; 
  permission:  any;
  assetperm: any;
  tc:boolean=true;
  checkedAll: any = false;
  url: any;
  showdataset:boolean =true;

  type:any;
  atype:any;historytext:any;


  downloadtext: any;
  dateerror:boolean = false;
  countryerror:boolean =false;
  searchrecord=false;
  isbirth:boolean=true;
  ispep:boolean = true;
  issan:boolean= true;

  selectpeplist=[
    {
      "id":"SAN",
      "name":"Sanction",
      "heading": "Sanctions",
      "description": "Any individual or entity that is or has formerly been subject to sanctions at international, regional, or national levels."
    },
    {
      "id":"RRE",
      "name":"Reputational Risk Exposure",
      "heading": "Reputational Risk Exposure",
      "description": "Any individual or entity that has been reported in official or media sources to be involved in a crime that falls under one or more of the following categories – terrorism, financial crime and fraud, modern slavery, organised crime, bribery and corruption, and cybercrime."
    },
    {
      "id": "REL",
      "name":"Regulatory Enforcement List",
      "heading": "Regulatory Enforcement List",
      "description": "Any individual or entity that has been fined or in some other way subject to action by any financial regulatory body or law enforcement authority."
    },
    {
      "id":"INS",
      "name":"Insolvency (UK & Ireland)",
      "heading": "Insolvency",
      "description": "Any person that has been declared as bankrupt or insolvent."
    },
    {
      "id":"PEP",
      "name":"PEP",
      "heading": "Politically exposed person",
      "description": "Any individual that is considered a Politically Exposed Person in their own capacity or by association."
    },
    {
      "id":"POI",
      "name":" Profile of Interest",
      "heading": "Profile of Interest",
      "description": "Natural or legal persons that were profiled based on Complygate Risk Intelligence’s data model and can be linked to outdated red flags, such as expired PEP status, reputational risk, or regulatory measures no longer in force."
    },
    {
      "id":"DD",
      "name":"Disqualified Director (UK only)",
      "heading": "Disqualified Director",
      "description": "Any individual that has been disqualified as acting as a director of a company."
    },
    {
      "id":"DL",
      "name":"Director - UK",
      "heading": "Disqualified Director",
      "description": "Any individual that has been disqualified as acting as a director of a company."
    },
    {
      "id":"DLG",
      "name":"Director - Global",
      "heading": "Disqualified Director",
      "description": "Any individual that has been disqualified as acting as a director of a company."
    }
  ];
  countryuserSelects = [];
  userSelects = [];
  suggestions = [{"id":"PEP-FORMER","name":"Former"},{"id":"PEP-CURRENT","name":"Current"},{"id":"PEP-LINKED","name":"Linked"}];

  peplist = [
    {
      "id":"PEP",
      "name":"PEP",
      "heading": "Politically exposed person",
      "description": "Any individual that is considered a Politically Exposed Person in their own capacity or by association."
    },
    {
      "id":"SAN",
      "name":"Sanction",
      "heading": "Sanctions",
      "description": "Any individual or entity that is or has formerly been subject to sanctions at international, regional, or national levels."
    },
    {
      "id":"RRE",
      "name":"Reputational Risk Exposure",
      "heading": "Reputational Risk Exposure",
      "description": "Any individual or entity that has been reported in official or media sources to be involved in a crime that falls under one or more of the following categories – terrorism, financial crime and fraud, modern slavery, organised crime, bribery and corruption, and cybercrime."
    },
    {
      "id":"INS",
      "name":"Insolvency (UK & Ireland)",
      "heading": "Insolvency",
      "description": "Any person that has been declared as bankrupt or insolvent."
    },
    {
      "id":"DD",
      "name":"Disqualified Director (UK only)",
      "heading": "Disqualified Director",
      "description": "Any individual that has been disqualified as acting as a director of a company."
    },
    {
      "id":"POI",
      "name":" Profile of Interest",
      "heading": "Profile of Interest",
      "description": "Natural or legal persons that were profiled based on Complygate Risk Intelligence’s data model and can be linked to outdated red flags, such as expired PEP status, reputational risk, or regulatory measures no longer in force."
    },
    {
      "id": "REL",
      "name":"Regulatory Enforcement List",
      "heading": "Regulatory Enforcement List",
      "description": "Any individual or entity that has been fined or in some other way subject to action by any financial regulatory body or law enforcement authority."
    },
    {
      "id":"DL",
      "name":"Director - UK",
      "heading": "Director List",
      "description": "Any individual that has been disqualified as acting as a director of a company."
    },
    {
      "id":"DLG",
      "name":"Director - Global",
      "heading": "Director Details",
      "description": "Any individual that has been disqualified as acting as a director of a company."
    }
  ];

  show: boolean = false;
  countryshow: boolean = false;
  logid: any;
  
  @Input() makeDifferent;
  
  stateCtrl: FormControl;
  filteredStates: Observable<any[]>;

  states: State[];

  constructor(
    private modalService: ModalService,     
    private formBuilder: FormBuilder,
    private amldetails: AmlService,
    private router: Router, 
    private country: CountryService, 
    private check: ChecksystemService,private activatedRoute: ActivatedRoute
    
  ) {

    this.stateCtrl = new FormControl();
    
    this.country.getcountrylist().subscribe((country: any) => {
     
      this.countrylist = country;
      this.states = country;
      this.filteredStates = this.stateCtrl.valueChanges
      .pipe(startWith(''),map(state => state ? this.filterStates(state) : this.states.slice()));     
    });  
    
  }

      
  filterStates(name: string) {
    return this.states.filter(state =>
      state.countryname.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  

  ngOnInit() {

    this.stateCtrl = new FormControl();
    
    this.downloadtext = 'No Record Found <i class="fa fa-plus ml-2"></i>';
    this.type = this.activatedRoute.snapshot.paramMap.get("type");

    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);


    this.showdataset =true;
    this.isbirth =false;
    this.ispep = true;
    this.issan = true;


    this.dateerror = false;
    this.countryerror =false;


    // this.permission = permission[0];

    if(this.type == 'aml')
    {
      this.historytext ='View AML, PEPs & Sanction';
      this.atype =0;
        if(permission[0]['m96'] < 3)
        {
          this.router.navigate(['/permission-denied']);
        }
    } 
    else if(this.type == 'global')
    {
      this.historytext ='View Global Due Diligence';
      this.atype =3;
      if(permission[0]['m189'] < 3)
        {
          this.router.navigate(['/permission-denied']);
        }
    }
    else if(this.type == 'adverse')
    {
        this.historytext ='View Adverse Media Search';
        this.atype =2;
        if(permission[0]['m179'] < 3)
        {
            this.router.navigate(['/permission-denied']);
        }
    }




    this.Threshold = 80;

    this.error =false;
    this.disableBtn =true;
    this.errormsg ='';
    this.buttoninsert ="Search";
    this.result =false;
    this.defaultVal ='';
    
    this.personalformGroup = this.formBuilder.group({
      fullname: ['', Validators.required],
      date: ['', [ Validators.pattern("^[0-9]*$"),Validators.minLength(2), Validators.maxLength(2)]],
      month: ['', [ Validators.pattern("^[0-9]*$"),Validators.minLength(2), Validators.maxLength(2)]],
      year: ['', [ Validators.pattern("^[0-9]*$"),Validators.minLength(4), Validators.maxLength(4)]],
      // Country: ['', Validators.required],      
      Threshold: [''],   
      dobMatching: [''],
      gender: [''],      
      YearOfBirth: [''],
      sanctionstatus: [''],
      stateCtrl: [''],
      // County: [''],
      // City: [''],          
      // PEP: [''],
      PreviousSanctions: [''],
      CurrentSanctions: [''],
      LawEnforcement: [''],
      FinancialRegulator: [''],
      Insolvency: [''],
      DisqualifiedDirector: [''],
      AdverseMedia: ['']
    });

    
    this.Userid = localStorage.getItem('userid');   

    this.logid = this.activatedRoute.snapshot.paramMap.get("id");
    

    this.bindandselectlist();


    // if(this.type == 'global')
    // {
          
    //   this.peplist =  this.peplist.filter(item => item.id !== 'DD');
    //   this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'DD');  

    //   this.peplist =  this.peplist.filter(item => item.id !== 'DDG');
    //   this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'DDG');  
    // }


    if(this.logid){

    var val ={ id: this.logid,atype:this.atype}
 
  
  this.amldetails.getCompanylogrecord(val).subscribe((searchRes: any) => { 
    var listmaster =JSON.parse(searchRes.searchresult);

     
   
    if(searchRes.iscomplete === 0)
    {
      if(listmaster.results.matchCount >0)
      {
        listmaster.results.matches.forEach(element => {
          element.countrylist = this.countrylist.filter(d => element.countries.includes(d.iso));
          element.datasetlist = this.suggestions.filter(d => element.datasets.includes(d.id)); 
        });
        this.result=true;
        this.searchresult = listmaster.results;
      }
      else
      {
        localStorage.setItem('personalid', searchRes.id);    
        this.router.navigate(['/creditsearchresult-new', searchRes.id]); 
      }
    }
    else
    {      
      localStorage.setItem('personalid', searchRes.id);    
      this.router.navigate(['/creditsearchresult-new', searchRes.id]); 
    }
  });
}
else
{
    this.lesssore =false;
    
   
      if(this.type == 'global')
      {
        var ts  = { 'serviceid': 23}; 
      } 
      else if(this.type == 'adverse')
        {
          var ts  = { 'serviceid': 22}; 
        }else {
          var ts  = { 'serviceid': 8}; 
        }


       
    
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      if(remaindata.remiancheck <= 0)
      {
          this.lesssore =true;
      }
    });


    var personalid = localStorage.getItem('personalid');
    if (!personalid && personalid != null) {
      this.router.navigate(['/creditsearchresult', personalid]);
    }

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    // this.amldetails.getCountries().subscribe((countrydata: any) => { 
    //   // console.log(countrydata);
    //   this.countrylist = countrydata;
    // });
    this.clearpersonal();
    }
  }


  get f() { return this.personalformGroup.controls; }

 datechange(val)
 {
  if(val.date && val.month && val.year)
  {
    this.isbirth = true;
  }else
  {
    this.isbirth =false;
  }
 }
  
  
 bindallpep(val)
 { 
   if(val)
   {
     return this.suggestions.filter(d => val.includes(d.id));;
   }
   else
     return [];
   // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
 }
  
 bindallcountry(val)
 { 
   if(val)
   {
     return this.countrylist.filter(d => val.includes(d.iso));;
   }
   else
     return [];
   // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
 }



suggest() 
{    
    this.countryshow = false;
    if( this.show)
    {
      this.show = false; 
    }else{
    this.show = true; 
    }
}

  isSelected(s:any) {
   return this.userSelects.findIndex((item) => item.id === s.id) > -1 ? true : false;
  }  

  selectSuggestion(s) {
    this.userSelects.find((item) => item.id === s.id) ? 
    this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
    this.userSelects.push(s);
    this.show =false;
  }

  deleteSelects(s) {
    this.userSelects = this.userSelects.filter((item) => item.id !== s.id);
    this.show =false;
  }

  assignToNgModel() {
    this.userSelectsString = '';
    this.userSelects.map((item) => this.userSelectsString += item.name + ' ');
  }



  countrysuggest() 
  {    
    this.show =false;
    if( this.countryshow)
    {
      this.countryshow = false; 
    }else{
    this.countryshow = true; 
  }
}



  iscountrySelected(s:any) {
   return this.countryuserSelects.findIndex((item) => item.iso === s.iso) > -1 ? true : false;
  }  


  getPosts(val)
  {

    var fakeobj = this.countrylist.filter(s => s.iso == val);

    this.countryuserSelects.find((item) => item.iso === val) ? 
    this.countryuserSelects = this.countryuserSelects.filter((item) => item.iso !== val) :
    this.countryuserSelects.push(fakeobj[0]);

    // this.countryuserSelects.push(s);
    
    if(this.countryuserSelects.length>0)
    { 
      this.countryerror =false;
    }

    this.countryshow =false;
    // this.stateCtrl = new FormControl('');

    this.stateCtrl = new FormControl();
    this.filteredStates = this.stateCtrl.valueChanges
      .pipe(startWith(''),map(state => state ? this.filterStates(state) : this.states.slice()));     
    

    // this.personalformGroup.stateCtrl ="";
    // this.stateCtrl =
    // this.stateCtrl =''; new FormControl();

    // console.log('get option values selection',this.countryuserSelects);
  }

  selectcountrySuggestion(s) {
    this.countryuserSelects.find((item) => item.iso === s.iso) ? 
    this.countryuserSelects = this.countryuserSelects.filter((item) => item.iso !== s.iso) :
    this.countryuserSelects.push(s);

    // this.countryuserSelects.push(s);
    
    if(this.countryuserSelects.length>0)
    { 
      this.countryerror =false;
    }

    this.countryshow =false;
  }

  deletecountrySelects(s) {
    this.countryuserSelects = this.countryuserSelects.filter((item) => item.iso !== s.iso);

    if(this.countryuserSelects.length==0)
    { 
      this.countryerror =false;
    }

    this.countryshow =false;
  }

  countryassignToNgModel() {
    this.countrySelectsString = '';
    this.countryuserSelects.map((item) => this.countrySelectsString += item.countryname + ' ');
  }



amllogredirect()
{

  if(this.type == 'global')
    {
      this.router.navigate(['/check-aml-log-list', '3']);
    } 
    else if(this.type == 'adverse')
      {
        this.router.navigate(['/check-aml-log-list', '2']);
      }
      else {
        this.router.navigate(['/check-aml-log-list', '0']);
      }

  


}

selectall(val){

  if(val == true){
  this.checkedAll = true;
  this.businessDetail.PEP = true;
  this.businessDetail.CurrentSanctions = true;
  this.businessDetail.DisqualifiedDirector =true;
  this.businessDetail.FinancialRegulator =true;
  this.businessDetail.Insolvency =true;
  this.businessDetail.LawEnforcement= true;
  this.businessDetail.AdverseMedia =true;
  this.businessDetail.PreviousSanctions =true;
}

}


selectallfalse(val)
{
  if(val == false){
    this.checkedAll = false;
  }
}

checkpep(val)
{
  return this.selectpeplist.findIndex((item) => item.id === val.id) > -1;
}

// checkInArray(val){
//   return this.selectpeplist.indexOf(val) >= 0;
// }

countryselectallfalse(chk,val)
{
  if(chk ==true)
  {    
    this.selectpeplist.push(val);   
  }
  else
  {
    this.selectpeplist =  this.selectpeplist.filter(item => item.id !== val.id);
  }




    if(this.selectpeplist.findIndex((item) => item.id === 'PEP') > -1)
      this.ispep = true;
    else
      this.ispep = false;


    if(this.selectpeplist.findIndex((item) => item.id === 'SAN') > -1)
      this.issan = true;
    else
      this.issan = false;

}




dataset(values){

  this.datasetlist ="";
  values.forEach(element_use => {    
    this.peplist.forEach(element => {
      if(element_use == element.id){
        this.datasetlist +=element.name+',';
      }      
    });
  });

  this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1); 
}

clearpersonal(){
  this.submitted = false; 
  // this.personalformGroup.reset();
  this.businessDetail = new Aml();

  this.businessDetail.Forename = "";
  this.businessDetail.Country = "";
  this.businessDetail.Middlename = "";
  this.businessDetail.Threshold = "";
  this.businessDetail.Surname = "";
  this.businessDetail.Postcode = "";
  this.businessDetail.Address = "";
  this.businessDetail.DateOfBirth = "";
  this.businessDetail.YearOfBirth = "";
  this.defaultVal = "";
  this.businessDetail.County = "";
  this.businessDetail.City = "";
  
  this.businessDetail.PEP = true;
  this.businessDetail.CurrentSanctions = true;
  this.businessDetail.DisqualifiedDirector =true;
  this.businessDetail.FinancialRegulator =true;
  this.businessDetail.Insolvency =true;
  this.businessDetail.LawEnforcement= true;
  this.businessDetail.AdverseMedia =true;
  this.businessDetail.PreviousSanctions =true;
  this.checkedAll = true;
}



CheckresourceId(id, type)
{
  this.error =false;
  this.submitted = true; 
  
  
  if(type == 'first' && this.logid ==0){
    this.disableBtn =true;

    if(this.type == 'global')
      {
        var ts  = { 'serviceid': 23}; 
      } 
      else if(this.type == 'adverse')
        {
          var ts  = { 'serviceid': 22}; 
        }else {
          var ts  = { 'serviceid': 8}; 
        }

      

      this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      // console.log(remaindata)remaindata.remiancheck=100;
      if(remaindata.remiancheck <= 0)
      {   
        this.lesssore =true;
        this.modalService.open('upgrade-plan');
      }else
      {  
        this.disableBtn =true;
        this.lesssore =false;
        this.resourceId =id;
        this.modalService.open('pdf-payment-confirmation');          
      }
    });     
  }
  else if (type == 'payment' || this.logid != 0)
  {


    id.id =this.logid;


    this.router.navigate(['/creditsearchresult-new', this.logid, id.resourceId]); 

    // var dd = { 
    //   resourceId: id,
    //   logid: this.logid
    // }    
    // console.log(dd);


    /* AML Complete report Block */

    // this.amldetails.getSearchPersonalSearchById(id).subscribe((searchRes: any) => {     

    //   console.log(searchRes);
    //   this.errormsg ='';
    //   if(searchRes.error == ''){
 
    //     localStorage.setItem('personalid', searchRes.id);    
    //    this.router.navigate(['/creditsearchresult-new', searchRes.id]);    
    //     // this.searchresult = searchRes.result;


    //   }else if(searchRes.error == 'amlfail')
    //   {
    //       this.errormsg ='We have some issue in AML & PEPs check. Please contact Administrator';
        
    //   }else  if(searchRes.error == 'paymentfail')
    //   {
    //     this.errormsg ='Your payment fail. Please contact Administrator';
    //   }



    // });

    /* AML Complete report Block */

    
  }  
}



onSubmit(val, type)
{
  this.error =false;
  this.countryerror = false;
  this.dateerror = false;
  this.submitted = true; 


  if (this.personalformGroup.invalid) {
    return;
  }



 var countrylist=[];
  this.countryuserSelects.forEach(element => {
    countrylist.push(element.iso);
  });


  // if(countrylist.length==0)
  // {
  //   this.countryerror = true;
  //   return;
  // }

  
  if(val.date || val.year || val.month)
  {
    this.dateerror = true;
    if(!val.date){
      return;
    } else if(!val.month){
      return;
    } else if(!val.year){
      return;
    }

   

    if((parseInt(val.date) >=1 && parseInt(val.date) <=31) && (parseInt(val.month) >=1 && parseInt(val.month) <=12) && (parseInt(val.year) >=1900 && parseInt(val.year) <=2021))
    {

      var datemaster = val.date+'/'+ val.month+'/'+ val.year;

      var datemaster2 =  val.year +'/'+  val.month +'/'+ val.date;

      
      const sd = new Date(datemaster);

      const sd2 = new Date(datemaster2);

      

      val.DateOfBirth  = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());


      val.DateOfBirth  = String(moment(sd2).format("YYYY-MM-DD")); //sd2.getTime(); ///Date.UTC(sd2.getFullYear(), sd2.getMonth(), sd2.getDate());// Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());

      // console.log('date',Date.UTC(sd2.getFullYear(), sd2.getMonth(), sd2.getDate()));




      // val.DateOfBirth =Date.UTC(val.year, val.month, val.date);  //Date.UTC(val.date, val.month, val.year);
    } else{
      return;
    }
    
    this.dateerror = false;    
  }


  var pep = [];
  if(this.selectpeplist.length ==0){
    this.error =true;
    return ;
  } else{
    this.selectpeplist.forEach(el => {
      pep.push(el.id);
    })
  }


    if(!this.Threshold)
    {
      val.threshold=50;
    }
    else
    {
      val.threshold = this.Threshold;
    }



  
  var dd = { 
    name: val.fullname,
    countries:countrylist,
    threshold: val.threshold,
    dob: val.DateOfBirth,
    dobMatching:val.dobMatching,
    gender:val.gender,
    datasets: pep,
    type: this.type
  }



  if(type == 'first'){
    this.disableBtn =true;
      
    if(this.type == 'global')
      {
        var ts  = { 'serviceid': 23}; 
      } 
      else if(this.type == 'adverse')
        {
          var ts  = { 'serviceid': 22}; 
        }else {
          var ts  = { 'serviceid': 8}; 
        }

    
    // var ts  = { 'serviceid': 8}; 
    
    
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      // console.log(remaindata)remaindata.remiancheck=100;
      if(remaindata.remiancheck <= 0)
      {   
        this.lesssore =true;
        this.modalService.open('upgrade-plan');
      }else
      {  
        this.disableBtn =true;
        this.lesssore =false;        
        this.modalService.open('pdf-payment-confirmation');          
      }
    });     
  }
  else if (type == 'payment')
  {


    this.searchrecord=true;
    this.result =true; 


    setTimeout(() => {
    this.amldetails.getSearchPersonalSearch(dd).subscribe((searchRes: any) => { 
      this.result =false; 
      this.errormsg ='';
      if(searchRes.error == ''){

        if(searchRes.result.matchCount >0)
          this.result =true;     
        else 
          this.router.navigate(['/creditsearchresult-new', searchRes.id]);  

      this.disableBtn =true;

      searchRes.result.matches.forEach(element => {
        element.countrylist = this.countrylist.filter(d => element.countries.includes(d.iso));
        element.datasetlist = this.suggestions.filter(d => element.datasets.includes(d.id)); 
      });

      this.searchresult = searchRes.result;

      this.logid =searchRes.id;

      this.searchrecord=false;

      }else if(searchRes.error == 'amlfail')
      {
          this.errormsg ='We have some issue in AML & PEPs check. Please contact Administrator';
        
      }else  if(searchRes.error == 'paymentfail')
      {
        this.errormsg ='Your payment fail. Please contact Administrator';
      }
    }); 
  
  }, 9000); 

  }


}

  openModal(id: string, data: any) {
    this.bussinessdetails = data;
   // console.log(data);
    this.buttoninsert = "Update";
    this.modalService.open(id);
  }
  closeModal(id: string) 
  { 
    this.modalService.close(id);
  }


/**************Start Data Set Design Setup****************/

choosedatabase()
{
  this.showdataset =true;
}

clearalldataset()
{
  this.selectpeplist = [];
  this.ispep =false;
  this.issan =false;
}





selectalldataset()
{

  this.selectpeplist=[
    {
      "id":"SAN",
      "name":"Sanction",
      "heading": "Sanctions",
      "description": "Any individual or entity that is or has formerly been subject to sanctions at international, regional, or national levels."
    },
    {
      "id":"RRE",
      "name":"Reputational Risk Exposure",
      "heading": "Reputational Risk Exposure",
      "description": "Any individual or entity that has been reported in official or media sources to be involved in a crime that falls under one or more of the following categories – terrorism, financial crime and fraud, modern slavery, organised crime, bribery and corruption, and cybercrime."
    },
    {
      "id": "REL",
      "name":"Regulatory Enforcement List",
      "heading": "Regulatory Enforcement List",
      "description": "Any individual or entity that has been fined or in some other way subject to action by any financial regulatory body or law enforcement authority."
    },
    {
      "id":"INS",
      "name":"Insolvency (UK & Ireland)",
      "heading": "Insolvency",
      "description": "Any person that has been declared as bankrupt or insolvent."
    },
    {
      "id":"PEP",
      "name":"PEP",
      "heading": "Politically exposed person",
      "description": "Any individual that is considered a Politically Exposed Person in their own capacity or by association."
    },
    {
      "id":"POI",
      "name":" Profile of Interest",
      "heading": "Profile of Interest",
      "description": "Natural or legal persons that were profiled based on Complygate Risk Intelligence’s data model and can be linked to outdated red flags, such as expired PEP status, reputational risk, or regulatory measures no longer in force."
    },
    {
      "id":"DD",
      "name":"Disqualified Director (UK only)",
      "heading": "Disqualified Director",
      "description": "Any individual that has been disqualified as acting as a director of a company."
    },
    {
      "id":"DLG",
      "name":"Director List (Rest of UK)",
      "heading": "Disqualified Director",
      "description": "Any individual that has been disqualified as acting as a director of a company."
    },
    {
      "id":"DL",
      "name":"Director List (UK)",
      "heading": "Disqualified Director",
      "description": "Any individual that has been disqualified as acting as a director of a company."
    }
  ];
  this.bindandselectlist();
  this.ispep =true;
  this.issan =true;
}

closedataset()
{
  this.showdataset =false;
}
/**************End Data Set Design Setup****************/

/*************************No Record Not Found*****************************/


norecordfound(){    
  this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
  this.amldetails.generatenorecordv3Pdf({ id: this.logid,atype:this.atype}).subscribe((pdfRes: any) => { 
   
    this.url = pdfRes.response_data.Location;
    this.downloadtext = 'No Record Found <i class="fa fa-plus ml-2"></i>';
  });
}


bindandselectlist()
{

  if(this.type == 'aml')
    {

      var companyid = localStorage.getItem('companyid');

      if(companyid)
       {
            if( parseInt(companyid) != 632)
              {
                this.peplist =  this.peplist.filter(item => item.id !== 'REL');
                this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'REL');
             }
       }


      this.peplist =  this.peplist.filter(item => item.id !== 'DLG');
      this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'DLG');

      this.peplist =  this.peplist.filter(item => item.id !== 'DL');
      this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'DL');

    }
  

    if(this.type == 'adverse')
    {
      this.peplist =  this.peplist.filter(item => item.id !== 'PEP');
      this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'PEP');

      this.peplist =  this.peplist.filter(item => item.id !== 'SAN');
      this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'SAN');

      this.peplist =  this.peplist.filter(item => item.id !== 'INS');
      this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'INS');

      this.peplist =  this.peplist.filter(item => item.id !== 'PEP');
      this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'PEP');     
      
      
      this.peplist =  this.peplist.filter(item => item.id !== 'POI');
      this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'POI');  

      this.peplist =  this.peplist.filter(item => item.id !== 'DD');
      this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'DD');  

      this.peplist =  this.peplist.filter(item => item.id !== 'DLG');
      this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'DLG');  

      this.peplist =  this.peplist.filter(item => item.id !== 'DL');
      this.selectpeplist =  this.selectpeplist.filter(item => item.id !== 'DL');

    }


}


}
