

<div class="check_verification" *ngIf="token_expired && decline_expired ==false">
  <div class="wizadlineshow">
      <div class="wizard_details">
          <div class="container">
              <div class="main-banner">
                  <div class="banner-form">
                      <div class="row">
                          <div class="col-sm-12">
                              <div class="tankushow">
                                  <div class="detailstext">
                                      <h1 class="tokenfont">Token Expired</h1>
                                      <p></p>
                                  </div>
                                  <img alt="shape" src="../../assets/img/thanks.png">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


<div class="check_verification" *ngIf="decline_expired">
  <div class="wizadlineshow">
      <div class="wizard_details">
          <div class="container">
              <div class="main-banner">
                  <div class="banner-form">
                      <div class="row">
                          <div class="col-sm-12">
                              <div class="tankushow">
                                  <div class="detailstext">
                                      <h1 class="tokenfont">Referee request has been declined successfully.</h1>
                                      <p></p>
                                  </div>
                                  <img alt="shape" src="../../assets/img/thanks.png">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


<div class="check_verification"  *ngIf="!token_expired && decline_expired ==false">
  <div class="wizadlineshow">
      <div class="wizard_details">
          <div class="container">
              <div class="main-banner">
                  <div class="banner-form">
                          <div class="row centeraligndecline">
                             
                            <div class="col-12">
                              <div class="declogo">
                                <img alt="" src="{{logo}}">
                              </div>
                              <div class="card">
                                <div class="declinereferencerequest">

                                      <h4>Decline Reference Request</h4>
                                      <div class="form-group">
                                        <label for="textarea-1">Please provide reason for declining this reference request</label>
                                        <textarea [(ngModel)]="declinereason" class="form-control" id="textarea-1" rows="5" cols="8"></textarea>
                                      </div>
                                      <button type="button" (click)="declineclicked()" class="btn btn-outline-danger btn-rounded transition">Decline</button>
                                </div>
                              </div>
                            </div>
                          </div>
                       </div>
                    </div>
                </div>
            </div>
      </div>
</div>


<div class="modal fade" id="noheader-modal" tabindex="-1" role="dialog" style="display: none;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        Thank you, All Referees were added, please close the window
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>
<div class="card-body " style="height: 100%;">

  <div class="d-inline-block">

</div>
</div>
