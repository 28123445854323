<style>
    .invalid-feedback {
        display: block !important;
    }
</style>
<div class="bg">
    <app-header></app-header>
    <style>
        .leftbox {
            top: -30px !important;
            left: 30px !important;
        }
    </style>
    <div class="card-activities">
    </div>

    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__content">
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row">
                                <div class="col-lg-12">
                                    <ul class="tabs__head montharea">
                                        <li><a [ngClass]="{'active': classactive == '0'}"
                                                (click)="addMonthsOfYear(0);">January </a></li>
                                        <li><a [ngClass]="{'active': classactive == '1'}"
                                                (click)="addMonthsOfYear(1);">February </a></li>
                                        <li><a [ngClass]="{'active': classactive == '2'}"
                                                (click)="addMonthsOfYear(2);">March </a></li>
                                        <li><a [ngClass]="{'active': classactive == '3'}"
                                                (click)="addMonthsOfYear(3);">April </a></li>
                                        <li><a [ngClass]="{'active': classactive == '4'}"
                                                (click)="addMonthsOfYear(4);">May</a></li>
                                        <li><a [ngClass]="{'active': classactive == '5'}"
                                                (click)="addMonthsOfYear(5);">June </a></li>
                                        <li><a [ngClass]="{'active': classactive == '6'}"
                                                (click)="addMonthsOfYear(6);">July </a></li>
                                        <li><a [ngClass]="{'active': classactive == '7'}"
                                                (click)="addMonthsOfYear(7);">August </a></li>
                                        <li><a [ngClass]="{'active': classactive == '8'}"
                                                (click)="addMonthsOfYear(8);">September </a></li>
                                        <li><a [ngClass]="{'active': classactive == '9'}"
                                                (click)="addMonthsOfYear(9);">October </a></li>
                                        <li><a [ngClass]="{'active': classactive == '10'}"
                                                (click)="addMonthsOfYear(10);">November </a></li>
                                        <li><a [ngClass]="{'active': classactive == '11'}"
                                                (click)="addMonthsOfYear(11);">December </a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row row-sm" style="margin-top:30px; margin-bottom: 30px;">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-md">
                                            <ng-template #modalContent let-close="close">
                                                <div class="modal-body" style="padding:0 30px;">
                                                    <div class="row row-sm ">
                                                    </div>
                                                </div>
                                            </ng-template>

                                            <div class="row text-center">
                                                <div class="col-lg-12">
                                                    <h3 class="my_leave_heading"><span
                                                            class="redcolor_text font22">Notifications </span> <span
                                                            class="graycolor_text fonts16">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</span>
                                                    </h3>
                                                </div>
                                            </div>

                                            <div [ngSwitch]="view" class="row row-sm maine222"
                                                style="margin:20px auto; width: 98%;">
                                                <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
                                                    [viewDate]="viewDate" [events]="leaves" [refresh]="refresh"
                                                    [cellTemplate]="customCellTemplate"
                                                    (dayClicked)="dayClicked($event.day)"
                                                    (eventClicked)="handleEvent('Clicked', $event.event)"
                                                    (eventTimesChanged)="eventTimesChanged($event)" class="pre_tag">
                                                </mwl-calendar-month-view>
                                            </div>

                                            <ng-template #customCellTemplate let-day="day" let-locale="locale">
                                                <div class="cal-cell-top-add">
                                                    <span
                                                        class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                                                    <span class="cal-day-badge"
                                                        *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
                                                    <span class="cal-day-number-add eyeicon"
                                                        *ngIf="day.date >= leaveavailabledate"
                                                        (click)="showAllEvents('event-viewer', day.date)"><i
                                                            class="fa fa-fw fa-eye"></i></span>
                                                </div>
                                                <div *ngIf="day.events.length > 0">
                                                    <div *ngFor="let event of day.events; let i = index"
                                                        [attr.data-index]="i">
                                                        <div *ngIf="i < 2">
                                                            <div class="cal-events">
                                                                <div class="cal-courseevent" [ngClass]="event.Type"
                                                                    [style.background-color]="event.color.primary"
                                                                    [style.color]="event.color.secondary">
                                                                    <small> <a
                                                                            (click)="showAllEvents('event-viewer', day.date )">{{ event.title |
                                      slice:0:20 }}{{event.title.length > 20 ? '...' : ''}}</a>
                                                                        <i *ngIf="event.allDay == true"
                                                                            class="fa fa-fw fa-pencil"
                                                                            (click)="editEvent('Edited',event)"></i></small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="i == 2">
                                                            <small *ngIf="day.events.length > 2" class="exdot"
                                                                (click)="showAllEvents('event-viewer', day.date)"><i
                                                                    class="fa fa-fw fa-circle"></i><i
                                                                    class="fa fa-fw fa-circle"></i><i
                                                                    class="fa fa-fw fa-circle"></i></small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngFor="let leave of leavelistreport;  let i=index">
                                <div class="col-lg-8">
                                    <p class="comheading">{{ leave.totalday}} Leave taken
                                        <span *ngIf="leave.tcount != '0'">from <b>
                                                {{leave.startdate | date: 'dd MMM yyyy'}} </b> to
                                            <b>{{leave.enddate | date: 'dd MMM yyyy'}} </b></span>
                                        <span *ngIf="leave.tcount == '0'">on <b>
                                                {{leave.startdate | date: 'dd MMM yyyy'}} </b> </span>
                                    </p>
                                    <ul class="commentlist">
                                        <li><i class="fa fa-comment"></i> <a
                                                (click)="remarksmodel(leave.employeeholidayapplyid, 'remarks-viewer', 'event-viewer');"
                                                class="curpointer"> {{ leave.remarkdetails.length }} comments</a></li>
                                        <li *ngIf="(leave.employeeholidayapplyid != 0) && (leave.hstatus == '1')">
                                            <a class="curpointer"
                                                (click)="onDelete(leave.employeeholidayapplyid)">Delete Request</a>
                                        </li>
                                        <li *ngIf="(leave.employeeholidayapplyid != 0) && (leave.hstatus == '1')"><a
                                                class="curpointer"
                                                (click)="editEventOfList('Edited',leave.employeeholidayapplyid, 'event-viewer')">Edit</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-4 ">
                                    <h5 class="text-right"> <span style="color:red"
                                            *ngIf="leave.hstatus == '3'">Rejected</span>
                                        <span style="color:green" *ngIf="leave.hstatus == '1'">Pending</span>
                                        <span style="color:green" *ngIf="leave.hstatus == '2'">Approved</span>
                                    </h5>
                                    <!-- <span class="text-right aprby"> Approved by Roberto Tomè</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<jw-modal id="event-viewer" class="modal listofwork" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Notification</h5>
                <button type="button" class="close" (click)="closeModal('event-viewer');" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <mat-tab-group>
                    <mat-tab label="Leaves Pending">
                        <ng-template matTabContent>
                            <div class="leave_panding" *ngIf="notifications.leaves.length>0">
                                <div class="job_list_section" *ngFor="let leav of notifications.leaves">
                                    <div class="job_detils_date width30">
                                        <p class="job_address">Emp Name</p>
                                        <span>{{leav.employee.fname}}</span>
                                    </div>
                                    <div class="job_detils_date width70">
                                        <p class="job_address">Title</p>
                                        <span>{{leav.title}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="job_list_section leave_pandingno" *ngIf="notifications.leaves.length==0">
                                No Record Found
                            </div>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Expenses">
                        <ng-template matTabContent>
                            <div class="leave_panding" *ngIf="notifications.expense.length>0">
                                <div class="job_list_section" *ngFor="let exp of notifications.expense">
                                    <div class="job_detils_date width30">
                                        <p class="job_address">Emp Name</p>
                                        <span>{{exp.employee.fname}}</span>
                                    </div>
                                    <div class="job_detils_date width60">
                                        <p class="job_address">Project Name</p>
                                        <span>{{exp.project.projectname}}</span>
                                    </div>
                                    <div class="job_detils_date width10 text-center">
                                        <p class="job_address">Amount</p>
                                        <span class="badge badge-danger"><i class="fa fa-gbp"></i>
                                            {{exp.amountpaid}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div *ngIf="notifications.expense.length>0">
                                <table>
                                    <thead>
                                        <th>Amount</th>
                                        <th>Emp Name</th>
                                        <th>Project Name</th>
                                        <th></th>
                                    </thead>

                                    <body>
                                        <tr *ngFor="let exp of notifications.expense">
                                            <td>{{exp.amountpaid}}</td>
                                            <td>{{exp.employee.fname}}</td>
                                            <td>{{exp.project.projectname}}</td>
                                            <td></td>
                                        </tr>
                                    </body>
                                </table>
                            </div> -->
                            <div class="job_list_section leave_pandingno" *ngIf="notifications.expense.length==0">
                                No Record Found
                            </div>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Passports to Expire">
                        <ng-template matTabContent>
                            <div class="leave_panding" *ngIf="notifications.passport.length>0">
                                <div class="job_list_section" *ngFor="let pas of notifications.passport">
                                    <div class="job_detils_date width30">
                                        <p class="job_address">Emp Name</p>
                                        <span>{{exp.employee.fname}}</span>
                                    </div>
                                    <div class="job_detils_date width70">
                                        <p class="job_address">Passport Number</p>
                                        <span>{{exp.project.projectname}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div *ngIf="notifications.expense.length>0">
                                <table>
                                    <thead>
                                        <th>Passport Number</th>
                                        <th>Emp Name</th>
                                        <th></th>
                                        <th></th>
                                    </thead>

                                    <body>
                                        <tr *ngFor="let pas of notifications.passport">
                                            <td>{{pas.passportnumber}}</td>
                                            <td>{{pas.employee.fname}}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </body>
                                </table>
                            </div> -->
                            <div class="job_list_section leave_pandingno" *ngIf="notifications.passport.length==0">
                                No Record Found
                            </div>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Visa to Expire">
                        <ng-template matTabContent>
                            <div class="leave_panding" *ngIf="notifications.visa.length>0">
                                <div class="job_list_section" *ngFor="let visa of notifications.visa">
                                    <div class="job_detils_date width30">
                                        <p class="job_address">Emp Name</p>
                                        <span>{{visa.employee.fname}}</span>
                                    </div>
                                    <div class="job_detils_date width70">
                                        <p class="job_address">Visa Number</p>
                                        <span>{{visa.visanumber}}</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="notifications.visa.length>0">
                                <table>
                                    <thead>
                                        <th>Visa Number</th>
                                        <th>Emp Name</th>
                                        <th></th>
                                        <th></th>
                                    </thead>

                                    <body>
                                        <tr *ngFor="let visa of notifications.visa">
                                            <td>{{visa.visanumber}}</td>
                                            <td>{{visa.employee.fname}}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </body>
                                </table>
                            </div>
                            <div class="job_list_section leave_pandingno" *ngIf="notifications.visa.length==0">
                                No Record Found
                            </div>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="COS to Expire">
                        <ng-template matTabContent>
                            <div class="leave_panding" *ngIf="notifications.cos.length>0">
                                <div class="job_list_section" *ngFor="let cos of notifications.cos">
                                    <div class="job_detils_date width30">
                                        <p class="job_address">Emp Name</p>
                                        <span>{{cos.employee.fname}}</span>
                                    </div>
                                    <div class="job_detils_date width70">
                                        <p class="job_address">COS Number</p>
                                        <span>{{cos.cosnumber}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div *ngIf="notifications.cos.length>0">
                                <table>
                                    <thead>
                                        <th>COS Number</th>
                                        <th>Emp Name</th>
                                        <th></th>
                                        <th></th>
                                    </thead>

                                    <body>
                                        <tr *ngFor="let cos of notifications.cos">
                                            <td>{{cos.cosnumber}}</td>
                                            <td>{{cos.employee.fname}}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </body>
                                </table>
                            </div> -->
                            <div class="job_list_section leave_pandingno" *ngIf="notifications.cos.length==0">
                                No Record Found
                            </div>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary com_btn" data-dismiss="modal"
                    (click)="closeModal('event-viewer');">Close</button>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="remarks-viewer" class="modal listofwork" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container">
                    <form [formGroup]="remarkformGroup" #remarkForm="ngForm" class="s12 white"
                        (ngSubmit)="onSubmitRemark(remarkForm,'remarks-viewer')" *ngIf="remarksofleave">
                        <input type="hidden" formControlName="remarksholidayid" class="form-control" required
                            name="remarksholidayid" ngModel='{{ remarksholidayid }}' [value]="remarksholidayid">
                        <div class="row row-sm mg-t-10">
                            <div class="col-lg-12 poplist m-b-10">
                                <label>Remarks*</label>
                                <textarea [ngClass]="{ 'is-invalid': remarksubmit && g.holidayremark.errors }"
                                    class="form-control" formControlName="holidayremark" required
                                    name="holidayremarkchat" id="holidayremarks"></textarea>
                                <div *ngIf="remarksubmit && g.holidayremark.errors" class="invalid-feedback">
                                    <div *ngIf="g.holidayremark.errors.required">Please Enter Remarks</div>
                                </div>
                            </div>
                        </div>
                        <div class="row row-sm">
                            <div class="poplist m-b-10">
                                <button type="submit" class="btn btn-outline-secondary"
                                    style="background-color:#094008; color: #fff;">Add
                                    Comment</button>
                            </div>
                        </div>
                    </form>

                    <div class="row">
                        <div class="col-lg-12 poplist m-b-10" *ngFor="let rem of remarksofleave">
                            <p><b>Notes <b *ngIf="rem.comuser">({{rem.comuser.fname}})</b> :</b>
                                <span>{{rem.createat | date: 'dd MMM yyyy'}}</span> <br /> {{ rem.holidayremark }}</p>
                        </div>
                    </div>
                    <div class="row row-sm">
                        <button type="button" style="background-color:#094008; margin-left: 15px; color: #fff;"
                            class="btn btn-outline-secondary" (click)="closeModal('remarks-viewer');"><i
                                class="fa fa-check"></i></button>
                        <button type="button" style="background-color:#094008;margin-left: 15px;color: #fff;"
                            class="btn btn-outline-secondary" (click)="closemessageModal('remarks-viewer');"><i
                                class="fa fa-close"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>