import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { AlertService } from '../../services/alert.service';
import { ModalService } from '../../_services/modal.service'; 
import { HttpErrorResponse } from '@angular/common/http';
import { AppGlobals } from '../../app.global';
import { StripeScriptTag } from "stripe-angular";
import { DOCUMENT } from '@angular/common';
import { StripeToken } from "stripe-angular";
import { CheckserviceService} from '../../services/checkservice.service';
import { Router } from '@angular/router';
import { CompanyService } from '../../services/company.service';
import { Address } from '../../models/address/address.model';
import { CountryService } from '../../services/country.service';
import { DomSanitizer } from '@angular/platform-browser';
import { parse } from 'querystring';


@Component({
  selector: 'app-payverification',
  templateUrl: './payverification.component.html',
  styleUrls: ['./payverification.component.css']
})
export class PayverificationComponent implements OnInit {

  public show:boolean = false;
  public buttonName:any = 'Show';
  submitted:any = false;
  registerForm: FormGroup;
  user = new User();
  access_token = '';
  paymentloading:any =false;
  userid = '';
  name = '';
  isline = '';
  planid = '';
  paymentaut:any =true;
  iframepaymentautsrc:any ='';
  isemail = '';
  errormsg: any;
  address = new Address();
  addpopForm: FormGroup;
  isLoginError = false;
  wizard1:any = false;
  wizard2:any = false;
  private publishableKey: string = AppGlobals.API_STRIPE;
  tab1:any = false;
  paynow = false;
  stptoken: any;
  changetoggle : any = false;
  invalidError:any;
  pricecheck:any;
  extraData:any;
  countrylist:any;
  token:any;
  addresslistDetail: any;
  coupon_applied: any = false;
  ctype: any;
  couponerror: any = false;
  discount: any;
  paymentSubmit: any = 0;
  defaultVal: any = '';

  constructor(@Inject(DOCUMENT) private document: Document,
    private router: Router,
    private alerts: AlertService,
    private userService: UserService,
    private modalService: ModalService,    
    private companyService: CompanyService,
    private country: CountryService, 
    public StripeScriptTag: StripeScriptTag,
    private formBuilder: FormBuilder,
    private checkService: CheckserviceService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {

   
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }
    else {
      this.userService.checkaccesstokenbyid(userid).subscribe((data: any) => {
        if (data.status == false) {         
        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('Name');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('ctype');
        localStorage.removeItem('isemail');
        localStorage.removeItem('ismobile');
        localStorage.removeItem('isadmin');
        localStorage.removeItem('ispermission');
        localStorage.removeItem('logoname');
        localStorage.removeItem('struser');
        localStorage.removeItem('menu');
        localStorage.removeItem('isverify');

        this.router.navigate(['/login']);

        }     
      });
    }

    
    // localStorage.removeItem('struser');
    var isemail = localStorage.getItem('isemail');
    var ismobile = localStorage.getItem('ismobile');      
    var struser = localStorage.getItem('struser');      
    var isverify =   localStorage.getItem('isverify'); 

    this.ctype = parseInt(localStorage.getItem('ctype'));
    var planid =  parseInt(localStorage.getItem('planid'));      



    if (isemail=='false' && ismobile == 'false')
    {            
       this.router.navigate(['/otp']);
    }
    else if (planid == 0 && this.ctype == 0 && struser == '' ) 
    {
      this.router.navigate(['choose-plan']);
    }
    else if (struser != '' && this.ctype == 1 && isverify == '1') 
    {
      this.router.navigate(['check-dashboard']);
    }
    else if (struser != '' && this.ctype == 2 )     
    {
      this.router.navigate(['check-dashboard']);
    }
    else if (struser != '' && this.ctype == 3 ) 
    {
      this.router.navigate(['check-dashboard']);
    }
    // else if(struser != '' && isverify == true)
    // {
    //   this.router.navigate(['check-dashboard']);
    // }







    // this.ctype  = parseInt(localStorage.getItem('ctype'));

    // if(this.ctype ==1)
    // {
    //   this.companyService.getcompanydetails().subscribe((data: any) => {
    //     if (data.verfiedforcheck == false && data.stuser)
    //     { 
    //       this.router.navigate(['/approval-confirmation']);          
    //     }
    //   });
    // }


    this.errormsg ='';
    this.StripeScriptTag.setPublishableKey(this.publishableKey);
    this.wizard1 = true;
    this.tab1 = true;


    
    this.addpopForm = this.formBuilder.group({
      cardname: ['', Validators.required],    
      address1: ['', Validators.required],
      address2: [''],
      cityname: ['', Validators.compose([Validators.required,])],   
      statename: ['', Validators.required],
      pincode: ['', Validators.required],     
      stateid: [''], 
      taxid: [''],
      coupon: [''],
      countryid: ['', Validators.compose([Validators.required, Validators.min(1)])]
    });


    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    
    this.addresslistDetail = new Address();
    this.addresslistDetail.countryid = "70";



    this.checkService.getAllServices().subscribe((data: any) => {      
      this.pricecheck = data;
    });
    
  }

  toTop(id) {
    document.getElementById("content").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
  }

  checkPasswords(group: FormGroup) { 
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmpassword.value;
    return pass === confirmPass ? null : { notSame: true }
  }

  get f() { return this.registerForm.controls; }

    /**
   * Submit function to register new company user and set cookies
   * @author Fareed Aarif 
   * @createat 17/10/2020
   */

  onSubmit() {
    
    this.submitted = true;
  
    if (this.registerForm.invalid) { return; }

    this.user.isemail =true;
    this.user.planid =1;
    this.user.ctype = 1;
    // this.user.permissionstring = this.permissionstring;
    this.userService.userjoinnow(this.user).subscribe((data2: any) => {
      if (data2.sussess == 'true') {
        this.userService.userAuthentication(this.user).subscribe((data: any) => {
          if (data.sussess == 'true') {
            localStorage.setItem('Token', this.access_token);        

            if (data.sussess == 'true') {
              localStorage.setItem('Token', data.id);
         
              // if (data.isemail == false) {
              //   // localStorage.setItem('otp', data.otp);
              // }

              this.errormsg ='';
              this.toggle('1');
            }
            else {
              this.alerts.error(data.error, true);
            }
          }
        })
      }else
      {
        this.modalService.open('custom-errormsg-1');
        this.errormsg =data2.error;
         this.alerts.error(data2.error, true);
      }
    },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
      });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

  toggle(id) {
    if(id == 1){
      this.wizard2 = true;
      this.wizard1 = false;
      this.router.navigate(['/approval-confirmation']);
    }
    this.show = !this.show;
    if(this.show)  
      this.buttonName = "Hide";
    else
      this.buttonName = "Show";
  }
  

  update_payment(formvalue:any) {
    
    
    this.submitted = true;  
    if (this.addpopForm.invalid) 
    {      
      return;
    }

    var country = '';
    this.countrylist.forEach(element => {
      if(formvalue.countryid == element.countryid){
        country = element.countryname;
      }
    });


    this.paynow = true;
    // this.stptoken = token.id;
    var amunt =parseFloat('36.00');
    var data = {
      // "token": token.id,
      // "cmonth": token.card.exp_month,
      // "cyear": token.card.exp_year,
      // "cardnumber": token.card.last4,
      // "tokenid": token.card.id,
      // "cardname": token.card.brand,
      // "country": token.card.country,
      "cstatus": "1",
      "amount": amunt,
      "address1": formvalue.address1,
      "address2": formvalue.address2,
      "cityname": formvalue.cityname,
      "statename": formvalue.statename,
      "countryid": formvalue.countryid,
      "cardcountry": country,
      "postcode": formvalue.pincode,
      "couponcode": formvalue.coupon,
      "taxid": formvalue.taxid,
      "cardholdername": formvalue.cardname
    };


    if(this.paymentSubmit == 0){
      this.paymentloading =true;

      this.checkService.registereduserpayment_new(data).subscribe((payment3: any) => {
        if(payment3.success == true)
        {
            localStorage.setItem('struser', payment3.struser); 
            this.paymentloading = false;
            this.toggle('1');
            this.wizard2 = true;
            this.wizard1 = false;

            if(this.ctype == '1')
              this.router.navigate(['/check-dashboard']);
            else if(this.ctype == '2')
              this.router.navigate(['/check-dashboard']);
            else if(this.ctype == '3')
              this.router.navigate(['/reference-dashboard']);

            
        }else if(payment3.success == false)
        {
          if(payment3.url)
          {
            this.document.location.href = payment3.url;
          }
          else
          {

          this.paynow = false;         
          this.wizard2 = true;         
          this.paymentaut = false;
          this.paymentloading = false;

          console.log(payment3.url);
          this.iframepaymentautsrc = this.getSafeUrl(payment3.url);

          this.modalService.open('custom-payment-25');

          var xy=null;
          var refreshId =  setInterval(() => {
            var data = { 'invoiceid': payment3.paymentint };
            this.companyService.getpaymentintent(data).subscribe((invoiceData: any) => {
              console.log(invoiceData);
              console.log(invoiceData.status);
              if(invoiceData.status != 'requires_source_action' && invoiceData.status != 'processing')
                {

                  if(invoiceData.status =='succeeded')
                  {
                    this.companyService.Creditchecksecurecard(data).subscribe((invoiceData: any) => {                  

                    this.paymentSubmit=0;
                    this.paymentloading = false;
                    this.toggle('1');
                    this.wizard2 = true;
                    this.wizard1 = false;

                  //   if(this.ctype == '1')
                  //     this.router.navigate(['/check-dashboard']);
                  //  else if(this.ctype == '2')
                  //     this.router.navigate(['/check-dashboard']);
                  //  else if(this.ctype == '3')
                  //     this.router.navigate(['/reference-dashboard']);
                    });

                    clearInterval(refreshId);
                  }


                
              }                      
            });       
          }, 5000);   

          }       
        }        
        else
        {           
          this.paymentSubmit=0;
          this.paymentloading = false;
          this.alerts.error("The payment method has failed. Please try again after 30 seconds.");
        }
        
      });
    }
    this.paymentSubmit = 1;
    
  }



  setStripeToken(token: StripeToken,formvalue:any, id: any) {
    
    
    this.submitted = true;  
    if (this.addpopForm.invalid) 
    {      
      return;
    }

    var country = '';
    this.countrylist.forEach(element => {
      if(formvalue.countryid == element.countryid){
        country = element.countryname;
      }
    });


    this.paynow = true;
    this.stptoken = token.id;
    var amunt =parseFloat('36.00');
    var data = {
      "token": token.id,
      "cmonth": token.card.exp_month,
      "cyear": token.card.exp_year,
      "cardnumber": token.card.last4,
      "tokenid": token.card.id,
      "cardname": token.card.brand,
      "country": token.card.country,
      "cstatus": "1",
      "amount": amunt,
      "address1": formvalue.address1,
      "address2": formvalue.address2,
      "cityname": formvalue.cityname,
      "statename": formvalue.statename,
      "countryid": formvalue.countryid,
      "cardcountry": country,
      "postcode": formvalue.pincode,
      "couponcode": formvalue.coupon,
      "taxid": formvalue.taxid,
      "cardholdername": formvalue.cardname
    };
    if(this.paymentSubmit == 0){
      this.paymentloading =true;

      this.checkService.registereduserpayment(data).subscribe((payment3: any) => {
        if(payment3.success == true)
        {
          localStorage.setItem('struser', payment3.struser); 
            this.paymentloading = false;
            this.toggle('1');
            this.wizard2 = true;
            this.wizard1 = false;

            if(this.ctype == '1')
              this.router.navigate(['/check-dashboard']);
            else if(this.ctype == '2')
              this.router.navigate(['/check-dashboard']);
            else if(this.ctype == '3')
              this.router.navigate(['/reference-dashboard']);

            
        }else if(payment3.success == false)
        {
          if(payment3.url)
          {
            this.document.location.href = payment3.url;
          }
          else
          {

          this.paynow = false;         
          this.wizard2 = true;         
          this.paymentaut = false;
          this.paymentloading = false;

          console.log(payment3.url);
          this.iframepaymentautsrc = this.getSafeUrl(payment3.url);
          this.modalService.open('custom-payment-25');

          var xy=null;
          var refreshId =  setInterval(() => {
            var data = { 'invoiceid': payment3.paymentint };
            this.companyService.getpaymentintent(data).subscribe((invoiceData: any) => {
              console.log(invoiceData);
              console.log(invoiceData.status);
              if(invoiceData.status != 'requires_source_action' && invoiceData.status != 'processing')
                {

                  if(invoiceData.status =='succeeded')
                  {
                    this.companyService.Creditchecksecurecard(data).subscribe((invoiceData: any) => {                  

                    this.paymentSubmit=0;
                    this.paymentloading = false;
                    this.toggle('1');
                    this.wizard2 = true;
                    this.wizard1 = false;

                  //   if(this.ctype == '1')
                  //     this.router.navigate(['/check-dashboard']);
                  //  else if(this.ctype == '2')
                  //     this.router.navigate(['/check-dashboard']);
                  //  else if(this.ctype == '3')
                  //     this.router.navigate(['/reference-dashboard']);
                    });

                    clearInterval(refreshId);
                  }


                
              }                      
            });       
          }, 5000);   

          }       
        }        
        else
        {           
          this.paymentSubmit=0;
          this.paymentloading = false;
          this.alerts.error("The payment method has failed. Please try again after 30 seconds.");
        }
        
      });
    }
    this.paymentSubmit = 1;
    
  }

  get g() { return this.addpopForm.controls; }


  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
  openModal(id: string) {
    this.addresslistDetail = new Address();
    this.addresslistDetail.countryid = "70";
    
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  paymentstripe(id){
    this.paynow = false;
    this.modalService.open(id);
  }

  checkCoupon(coupon){
    this.checkService.checkCoupon({couponcode: coupon}).subscribe((couponResp: any) => {
      if(couponResp.success){
        this.coupon_applied = true;
        this.couponerror = false;
        this.discount = couponResp.amountoff;
      } else{
        this.coupon_applied = false;
        this.couponerror = true;
      }
      
    });
    console.log(coupon);
  }

  get coupon(): any { return this.addpopForm.get('coupon'); }

  cancelCoupon(){
    this.coupon.reset();
    this.coupon_applied = false;
  }

  onStripeError(error: Error) {
    console.error('Stripe error', error)
  }

  logout() {
    if (confirm("Are you sure you want to sign out?")) {
      this.userService.logout().subscribe((data: any) => {
      
        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('Name');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('ctype');
        localStorage.removeItem('isemail');
        localStorage.removeItem('ismobile');
        localStorage.removeItem('isadmin');
        localStorage.removeItem('ispermission');
        localStorage.removeItem('logoname');
        localStorage.removeItem('struser');
        localStorage.removeItem('menu');
        localStorage.removeItem('isverify');

        this.router.navigate(['/login']);
      });;
    }
  }
}
