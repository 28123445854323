"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TopmenuComponent = /** @class */ (function () {
    function TopmenuComponent() {
    }
    TopmenuComponent.prototype.ngOnInit = function () {
        this.empltype = localStorage.getItem('emptype');
        // alert(this.empltype);
    };
    return TopmenuComponent;
}());
exports.TopmenuComponent = TopmenuComponent;
