import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aiverification',
  templateUrl: './aiverification.component.html',
  styleUrls: ['./aiverification.component.css']
})
export class AiverificationComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
