"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var MyvisaService = /** @class */ (function () {
    function MyvisaService(commonService) {
        this.commonService = commonService;
        this.modals = [];
    }
    MyvisaService.prototype.getvisaAllList = function (value) {
        return this.commonService.formpost('/employee/employeevislist', value);
    };
    MyvisaService.prototype.getvisaAllListbyadmin = function (value) {
        return this.commonService.formpost('/employee/employeevislistbyadmin', value);
    };
    MyvisaService.prototype.getallvisaListbyadmin = function (value) {
        return this.commonService.formpost('/employee/allemployeevislistbyadmin', value);
    };
    MyvisaService.prototype.downloadVisa = function (value) {
        return this.commonService.formpost('/employee/downloadvisa', value);
    };
    MyvisaService.prototype.getvisaAllApprove = function () {
        return this.commonService.formpostOnlyid('/employee/employeevisapprove');
    };
    MyvisaService.prototype.getvisaAllRejected = function () {
        return this.commonService.formpostOnlyid('/employee/employeevisarejected');
    };
    MyvisaService.prototype.deletevisa = function (value) {
        return this.commonService.formpost('/employee/visadelete', value);
    };
    MyvisaService.prototype.myvisadetailsUpdate = function (value) {
        return this.commonService.formpost('/employee/visaupdate', value);
    };
    MyvisaService.prototype.myvisadetailsUpdatebyadmin = function (value) {
        return this.commonService.formpost('/employee/visaupdatebyadmin', value);
    };
    MyvisaService.prototype.myvisadetailsUpdatebyadminrighttowork = function (value) {
        return this.commonService.refereeformpost('/employee/visaupdatebyadminrighttowork', value);
    };
    MyvisaService.prototype.getvisadetailsidbyadmin = function (value) {
        return this.commonService.formpost('/employee/employeevisadetailsbyadmin', value);
    };
    MyvisaService.prototype.visadetailsbyvisaid = function (value) {
        return this.commonService.formpost('/employee/visadetailsbyvisaid', value);
    };
    MyvisaService.prototype.visaupdateForAI = function (value) {
        return this.commonService.formpost('/employee/visaupdateAIDetailsbyadmin', value);
    };
    MyvisaService.prototype.visaaipaymentstaus = function (value) {
        return this.commonService.formpost('/company/countaiinformation', value);
    };
    MyvisaService.prototype.visaailogupdate = function (value) {
        return this.commonService.formpost('/company/aivisalogupdate', value);
    };
    MyvisaService.prototype.aidocumentverification = function (value) {
        return this.commonService.formpost('/complygate/facerecognize', value);
    };
    MyvisaService.prototype.visadocumentupdate = function (value) {
        return this.commonService.formpost('/api/checkdocument', value);
    };
    MyvisaService.prototype.righttoworkdocumentcheckdeduct = function (value) {
        return this.commonService.formpost('/api/checkquantitydeductforrighttoworkdoc', value);
    };
    MyvisaService.prototype.visaaiai = function (value) {
        return this.commonService.formpostForAI('https://lon.netverify.com/api/netverify/v2/performNetverify', value);
    };
    MyvisaService.ngInjectableDef = i0.defineInjectable({ factory: function MyvisaService_Factory() { return new MyvisaService(i0.inject(i1.CommonService)); }, token: MyvisaService, providedIn: "root" });
    return MyvisaService;
}());
exports.MyvisaService = MyvisaService;
