"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_model_1 = require("../../models/employee.model");
var country_service_1 = require("../../services/country.service");
var employee_service_1 = require("../../services/employee.service");
var passport_service_1 = require("../../services/passport.service");
var excel_service_1 = require("../../services/excel.service");
var PassportreportComponent = /** @class */ (function () {
    function PassportreportComponent(excelService, formBuilder, country, employee, passportreport) {
        this.excelService = excelService;
        this.formBuilder = formBuilder;
        this.country = country;
        this.employee = employee;
        this.passportreport = passportreport;
        this.employeeDetail = new employee_model_1.Employee();
        this.exceldata = [];
    }
    PassportreportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.EmployeeForm = this.formBuilder.group({
            emailid: [''],
            phonenumber: [''],
            ninumber: [''],
            minpassportissue: [''],
            maxpassportissue: [''],
            minpassportexpiry: [''],
            maxpassportexpiry: [''],
            passportnumber: [''],
            iscurrent: [''],
            cstatus: ['']
        });
        var values3 = {};
        this.passportreport.getpassportlist(values3).subscribe(function (passportdetails) {
            _this.data = passportdetails;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
    };
    PassportreportComponent.prototype.exportAsXLSX = function () {
        var _this = this;
        this.exceldata = [];
        this.data.forEach(function (obj) {
            if (obj.iscurrent == false) {
                var current = "No";
            }
            else if (obj.iscurrent == true) {
                current = "Yes";
            }
            _this.employeepassportdata = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "Passport Number": obj.passportnumber,
                "Nationality": obj.nationality.countryname,
                "Start Date": obj.passportissue,
                "End Date": obj.passportexpiry,
                "Is Current": current
            };
            _this.exceldata.push(_this.employeepassportdata);
        });
        this.excelService.exportAsExcelFile(this.exceldata, 'sample');
    };
    PassportreportComponent.prototype.onSubmit = function (value3) {
        var _this = this;
        this.passportreport.getpassportlist(value3).subscribe(function (passportdetails) {
            _this.data = passportdetails;
        });
    };
    return PassportreportComponent;
}());
exports.PassportreportComponent = PassportreportComponent;
