import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Employee } from '../../models/employee.model';
import { CountryService } from '../../services/country.service';
import { EmployeeService } from '../../services/employee.service';
import { PassportService } from '../../services/passport.service';
import { ExcelService } from '../../services/excel.service';

@Component({
  selector: 'app-passportreport',
  templateUrl: './passportreport.component.html',
  styleUrls: ['./passportreport.component.css']
})

export class PassportreportComponent implements OnInit {
  EmployeeForm: FormGroup;
  employeeDetail = new Employee();
  employeelist: any;
  countrylist: any;
  coslist: any;
  data: any;
  exceldata = [];
  employeepassportdata: any;

  constructor(private excelService: ExcelService, private formBuilder: FormBuilder, private country: CountryService, private employee: EmployeeService, private passportreport: PassportService) { }

  ngOnInit() {
    this.EmployeeForm = this.formBuilder.group({
      emailid: [''],
      phonenumber: [''],
      ninumber: [''],
      minpassportissue: [''],
      maxpassportissue: [''],
      minpassportexpiry: [''],
      maxpassportexpiry: [''],
      passportnumber: [''],
      iscurrent: [''],
      cstatus: ['']
    });
    var values3 = {};
    this.passportreport.getpassportlist(values3).subscribe((passportdetails: any) => {
      this.data = passportdetails;
    });
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
  }

  exportAsXLSX(): void {
    this.exceldata = [];
    this.data.forEach(obj => {
      if (obj.iscurrent == false) {
        var current = "No";
      }
      else if (obj.iscurrent == true) {
        current = "Yes";
      }
      this.employeepassportdata = {
        "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
        "Passport Number": obj.passportnumber,
        "Nationality": obj.nationality.countryname,
        "Start Date": obj.passportissue,
        "End Date": obj.passportexpiry,
        "Is Current": current
      };
      this.exceldata.push(this.employeepassportdata);
    });
    this.excelService.exportAsExcelFile(this.exceldata, 'sample');
  }

  onSubmit(value3: any) {
    this.passportreport.getpassportlist(value3).subscribe((passportdetails: any) => {
      this.data = passportdetails;
    });
  }
}