import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { CompanyService } from '../../services/company.service';
@Component({
  selector: 'app-confirmationapproval',
  templateUrl: './confirmationapproval.component.html',
  styleUrls: ['./confirmationapproval.component.css']
})
export class ConfirmationapprovalComponent implements OnInit {

  ctype: number;
  constructor(
    private companyService: CompanyService,
    private router: Router,
    private userService: UserService,) { }

  ngOnInit() {    
    this.ctype  = parseInt(localStorage.getItem('ctype'));
    if(this.ctype ==1)
    {
      this.companyService.getcompanydetails().subscribe((data: any) => {
        if (data.stuser == '') {        
          this.router.navigate(['/payment-confirmation']);
        }else if (data.verifiedforcheck == true)
        {           
          localStorage.setItem('isverify', "1");
          console.log(data.verifiedforcheck);
          this.router.navigate(['/check-dashboard']);          
        }
      });
    }
  }
  logout() {
    if (confirm("Are you sure you want to sign out?")) {
      this.userService.logout().subscribe((data: any) => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('Name');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('isemail');
        localStorage.removeItem('isadmin');
        localStorage.removeItem('ispermission');
        localStorage.removeItem('logoname');
        localStorage.removeItem('menu');
        this.router.navigate(['/login']);
      });;
    }
  }

}
