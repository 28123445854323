"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var customer_service_1 = require("../../../services/customer.service");
var country_service_1 = require("../../../services/country.service");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var modal_service_1 = require("../../../_services/modal.service");
var profile_model_1 = require("../../../models/overview/profile.model");
var app_global_1 = require("../../../app.global");
var signature_pad_1 = require("signature_pad");
var checkservice_service_1 = require("../../../services/checkservice.service");
var passport_model_1 = require("../../../models/passport/passport.model");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var mypassport_service_1 = require("../../../services/mypassport.service");
var myidproof_service_1 = require("../../../services/myidproof.service");
var myvisa_service_1 = require("../../../services/myvisa.service");
var checksystem_service_1 = require("../../../services/checksystem.service");
var platform_browser_1 = require("@angular/platform-browser");
var candidate_model_1 = require("../../../models/jobs/candidate.model");
var visa_model_1 = require("../../../models/visa/visa.model");
var idproof_model_1 = require("../../../models/idproof/idproof.model");
var common_1 = require("@angular/common");
var RightToWorkAppAdminComponent = /** @class */ (function () {
    function RightToWorkAppAdminComponent(router, formBuilder, activatedRoute, country, modalService, customerdetails, uploadService, passportService, visaService, documentService, datePipe, check, checkService, sanitizer) {
        this.router = router;
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.country = country;
        this.modalService = modalService;
        this.customerdetails = customerdetails;
        this.uploadService = uploadService;
        this.passportService = passportService;
        this.visaService = visaService;
        this.documentService = documentService;
        this.datePipe = datePipe;
        this.check = check;
        this.checkService = checkService;
        this.sanitizer = sanitizer;
        this.loading = 0;
        this.right = new profile_model_1.righttowork();
        this.current_stage = "";
        this.request_type = "";
        this.rtw_country = 0;
        this.total_stages = 5;
        this.per_stage = 0;
        this.progressbar = 0;
        this.questionselect = [];
        this.countrywiseQuestionList = [];
        this.openDocumentForm = 0;
        this.selectedDocList = [];
        this.confirmdoc = false;
        this.hidediv = true;
        this.docprogress = 0;
        this.passportfrontfilename = "Choose file";
        this.passportbackfilename = "Choose file";
        this.passportprofilename = "Choose file";
        // passport vars
        this.updatebutton = false;
        this.visafrontfilename = "Choose file";
        this.visabackfilename = "Choose file";
        this.visaprofilename = "Choose file";
        this.docfrontfilename = "Choose file";
        this.docbackfilename = "Choose file";
        this.docprofilename = "Choose file";
        this.docname = "";
        this.showfinish = false;
        this.questionError = false;
        this.candidatenotesdetails = new candidate_model_1.candidatenotes();
        this.sharcodedetails = new candidate_model_1.candidatenotes();
        this.docpdffilename = "Choose file";
        this.update = false;
        this.updateButton = false;
        this.updaterighttowork = { passport: false, visa: false, document: [], documentid: [] };
    }
    RightToWorkAppAdminComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.checkService.selectrighttoworkbyadmin().subscribe(function (righttoworkadmin) {
            if (righttoworkadmin.success == 'fail') {
                _this.router.navigate(['/right-to-work-app-history-list', 0]);
            }
        });
        // this.sendrequestchargestage(5);
        this.datetoday = new Date();
        this.per_stage = 100 / this.total_stages;
        this.loading = 0;
        this.documentService.iddocumenttype().subscribe(function (documenttype) {
            // console.log(documenttype);
            _this.documenttype = documenttype;
            _this.confirmdoc = false;
            _this.ninumber = '';
            var logid = localStorage.getItem('logid');
            console.log(logid);
            if (logid) {
                _this.logid = logid;
                var righttowork = { id: _this.logid };
                _this.checkService.selectrighttowork(righttowork).subscribe(function (righttoworklog) {
                    _this.employeecompanydetails = righttoworklog.cemployee;
                    righttoworklog = righttoworklog.rightoworklog;
                    // this.stage = 5;
                    _this.countryid = righttoworklog.countryid;
                    _this.isrighttowork = righttoworklog.isrighttowork;
                    _this.isrighttoworktype = righttoworklog.isrighttoworktype;
                    var selectdoc = righttoworklog.remaindocumentlist;
                    var totaldoc = righttoworklog.doucmentlist.split(',');
                    _this.profileimage = righttoworklog.profileimage;
                    _this.ninumber = righttoworklog.ninumber;
                    if (righttoworklog.documentid) {
                        var ss = righttoworklog.documentid.split(',');
                        var tt = [];
                        ss.forEach(function (element) {
                            tt.push(parseInt(element));
                        });
                        _this.updaterighttowork.documentid = tt; //righttoworklog.documentid.split(',');
                    }
                    if (_this.logid) {
                        if (_this.isrighttowork != 2) {
                            _this.candidatenoteFormGroup = _this.formBuilder.group({
                                notes: ['', forms_1.Validators.required],
                                sharecode: [''],
                                Checkconductedby: ['', forms_1.Validators.required],
                                Checkconductedbyrole: ['', forms_1.Validators.required],
                                companyname: ['', forms_1.Validators.required],
                                ninumber: [''],
                                dateofbirth: ['']
                            });
                        }
                        else {
                            _this.candidatenoteFormGroup = _this.formBuilder.group({
                                notes: ['', forms_1.Validators.required],
                                sharecode: [''],
                                Checkconductedby: ['', forms_1.Validators.required],
                                Checkconductedbyrole: ['', forms_1.Validators.required],
                                companyname: [''],
                                ninumber: [''],
                                dateofbirth: ['']
                            });
                        }
                    }
                    _this.selectedDocList = [];
                    if (selectdoc) {
                        var splitted = selectdoc.split(",");
                        splitted.forEach(function (queid) {
                            // splitted.forEach(function (value) {
                            _this.selectedDocList.push(queid);
                        });
                    }
                    if (_this.isrighttowork != 2)
                        _this.sendrequestchargestage(5, 'second');
                    else
                        _this.sendrequestchargestage(11, 'second');
                    if (_this.isrighttowork != 2)
                        _this.performAction();
                    if (_this.isrighttowork == 0) {
                        if (splitted.length == totaldoc.length) {
                            _this.update = true;
                            _this.questionselect = righttoworklog.questionlist.split(',');
                            _this.country.getcountrylistbyid({ 'id': righttoworklog.countryid }).subscribe(function (country) {
                                _this.currentCompany = country;
                                // this.stage = 9;
                                // this.sendrequestchargestage('4', country.countryid, '', country.isrighttoworkcountry);
                            });
                        }
                        if (_this.selectedDocList.length == 0 || (_this.selectedDocList.length == 1 && _this.selectedDocList[0] == "")) {
                            _this.stage = 7;
                            if (righttoworklog.anslist == '' || righttoworklog.anslist == null) {
                                _this.allquestionlist();
                                _this.stage = 6;
                            }
                        }
                    }
                    console.log(_this.update);
                    if (_this.isrighttowork == 1) {
                        if (_this.selectedDocList.length == 0 || (_this.selectedDocList.length == 1 && _this.selectedDocList[0] == "")) {
                            if (righttoworklog.anslist == '' || righttoworklog.anslist == null) {
                                _this.allquestionlist();
                                _this.stage = 6;
                                // this.stage =17;
                            }
                        }
                        else {
                            if (splitted.length == totaldoc.length) {
                                _this.update = true;
                                _this.questionselect = righttoworklog.questionlist.split(',');
                                _this.country.getcountrylistbyid({ 'id': righttoworklog.countryid }).subscribe(function (country) {
                                    _this.currentCompany = country;
                                    _this.stage = 9;
                                    // this.sendrequestchargestage('4', country.countryid, '', country.isrighttoworkcountry);
                                });
                            }
                            else {
                                _this.stage = 9;
                            }
                        }
                    }
                });
            }
            else {
                _this.stage = 1;
            }
        });
        this.progressbarIncrease();
        // this.checkQuestionList();
        // this.allquestionlist();
        this.timeValues = app_global_1.AppGlobals.TIME_VALUES;
        this.user_id = localStorage.getItem('userid');
        this.empid = this.activatedRoute.snapshot.paramMap.get("id");
        this.customerdetails.getOverviewRighttoworkbyadmin({ 'id': this.empid }).subscribe(function (remaindata) {
            console.log(remaindata);
            _this.employeedetails = remaindata;
        });
        this.country.getcountrylist().subscribe(function (countries) {
            _this.countrylist = countries;
            _this.datalist = countries;
        });
        this.checkService.rtwquestionlist().subscribe(function (questions) {
            _this.questionlist = questions;
            // console.log(questions);
        });
        this.meetingformGroup = this.formBuilder.group({
            startdate: ['', forms_1.Validators.required],
            starttime: ['', forms_1.Validators.required],
            endtime: ['', forms_1.Validators.required]
        });
        /** passport formgroup */
        this.passportFormgroup = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            passportnumber: ['', forms_1.Validators.required],
            placeofissue: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            passportissue: ['', forms_1.Validators.required],
            passportexpiry: ['', forms_1.Validators.required]
        });
        /** visa formgroup */
        this.visaFormgroup = this.formBuilder.group({
            employeeid: ['', forms_1.Validators.required],
            id: ['', forms_1.Validators.required],
            visanumber: ['', forms_1.Validators.required],
            visatype: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            visaexpiry: ['', forms_1.Validators.required],
            visastart: ['', forms_1.Validators.required]
        });
        /** document formgroup */
        this.idproofFormgroup = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            idnumber: ['', forms_1.Validators.required],
            idtype: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            docexpiry: ['', forms_1.Validators.required]
        });
        this.candidateshareFormGroup = this.formBuilder.group({
            notes: ['', forms_1.Validators.required],
            sharecode: [''],
            Checkconductedby: ['', forms_1.Validators.required],
            Checkconductedbyrole: ['', forms_1.Validators.required],
        });
        if (this.logid) {
            if (this.isrighttowork != 2) {
                this.candidatenoteFormGroup = this.formBuilder.group({
                    notes: ['', forms_1.Validators.required],
                    sharecode: [''],
                    Checkconductedby: ['', forms_1.Validators.required],
                    Checkconductedbyrole: ['', forms_1.Validators.required],
                    companyname: ['', forms_1.Validators.required],
                    ninumber: ['', forms_1.Validators.required],
                    dateofbirth: ['', forms_1.Validators.required]
                });
            }
            else {
                this.candidatenoteFormGroup = this.formBuilder.group({
                    notes: ['', forms_1.Validators.required],
                    sharecode: [''],
                    Checkconductedby: ['', forms_1.Validators.required],
                    Checkconductedbyrole: ['', forms_1.Validators.required],
                    companyname: [''],
                    ninumber: [''],
                    dateofbirth: ['']
                });
            }
        }
        else {
            this.candidatenoteFormGroup = this.formBuilder.group({
                notes: ['', forms_1.Validators.required],
                sharecode: [''],
                Checkconductedby: ['', forms_1.Validators.required],
                Checkconductedbyrole: ['', forms_1.Validators.required],
                companyname: ['', forms_1.Validators.required],
                ninumber: ['', forms_1.Validators.required],
                dateofbirth: ['', forms_1.Validators.required]
            });
        }
        /** Share Code Details */
        this.sharecodeFormGroup = this.formBuilder.group({
            companyname: ['', forms_1.Validators.required],
            ninumber: ['', forms_1.Validators.required],
            sharecode: ['', forms_1.Validators.required],
            dateofbirth: ['', forms_1.Validators.required]
        });
    };
    Object.defineProperty(RightToWorkAppAdminComponent.prototype, "returnForm", {
        get: function () {
            return this.meetingformGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    RightToWorkAppAdminComponent.prototype.ngAfterViewInit = function () {
        this.signaturePad = new signature_pad_1.default(this.canvasEl.nativeElement);
    };
    RightToWorkAppAdminComponent.prototype.startDrawing = function (event) {
        console.log(event);
        // works in device not in browser
    };
    Object.defineProperty(RightToWorkAppAdminComponent.prototype, "f", {
        /** Passport form Process */
        get: function () { return this.passportFormgroup.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RightToWorkAppAdminComponent.prototype, "j", {
        get: function () { return this.candidatenoteFormGroup.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RightToWorkAppAdminComponent.prototype, "l", {
        get: function () { return this.candidateshareFormGroup.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RightToWorkAppAdminComponent.prototype, "k", {
        get: function () { return this.sharecodeFormGroup.controls; },
        enumerable: true,
        configurable: true
    });
    RightToWorkAppAdminComponent.prototype.moved = function (event) {
        console.log(event);
        // works in device not in browser
    };
    RightToWorkAppAdminComponent.prototype.clearPad = function () {
        this.signaturePad.clear();
    };
    RightToWorkAppAdminComponent.prototype.onSubmitPassport = function (value3, id) {
        var _this = this;
        this.submitted = true;
        // console.log(this.passportFormgroup);
        if (this.passportFormgroup.invalid) {
            if (!this.selectedpassportFrontFiles) {
                this.passportfrontfilerequirederror = true;
            }
            if (!this.selectedpassportProfiles && !this.profileimage) {
                this.passportprofilerequirederror = true;
            }
            return;
        }
        if (this.selectedpassportFrontFiles) {
            this.passportfront = this.selectedpassportFrontFiles;
            var filetype = this.passportfront.type.split('/');
            var typeoffile = filetype[filetype.length - 1];
            if (this.passportfront.size > 2000 * 1024) {
                this.passportfrontfilesizeerror = true;
                return;
            }
            else if ((typeoffile != "jpeg") && (typeoffile != "jpg") && (typeoffile != "png")) {
                this.passportfrontfileformaterror = true;
                return;
            }
            var frontpassportimagepath = this.uploadService.uploadfile(this.passportfront);
            value3.imagename = frontpassportimagepath;
        }
        else {
            this.passportfrontfilerequirederror = true;
            return;
        }
        if (this.selectedpassportBackFiles) {
            this.passportback = this.selectedpassportBackFiles;
            var filetype = this.passportback.type.split('/');
            var typeoffile = filetype[filetype.length - 1];
            if (this.passportback.size > 2000 * 1024) {
                this.passportbackfilesizeerror = true;
                return;
            }
            else if ((typeoffile != "jpeg") && (typeoffile != "jpg") && (typeoffile != "png")) {
                this.passportbackfileformaterror = true;
                return;
            }
            var backpassportimagepath = this.uploadService.uploadfile(this.passportback);
            value3.imagebackname = backpassportimagepath;
        }
        if (this.selectedpassportProfiles) {
            this.passportprofile = this.selectedpassportProfiles;
            var filetype = this.passportprofile.type.split('/');
            var typeoffile = filetype[filetype.length - 1];
            if (this.passportprofile.size > 2000 * 1024) {
                this.passportprofilesizeerror = true;
                return;
            }
            else if ((typeoffile != "jpeg") && (typeoffile != "jpg") && (typeoffile != "png")) {
                this.passportprofileformaterror = true;
                return;
            }
            var profilepassportimagepath = this.uploadService.uploadfile(this.passportprofile);
            value3.passportprofileimage = profilepassportimagepath;
        }
        else {
            if (!this.profileimage) {
                this.passportprofilerequirederror = true;
                return;
            }
            else {
                value3.passportprofileimage = this.profileimage;
            }
        }
        value3.createby = this.user_id;
        value3.isrighttowork = 1;
        this.disableBtn = false;
        var sd = new Date(value3.passportissue);
        value3.passportissue = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        var ed = new Date(value3.passportexpiry);
        value3.passportexpiry = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        this.passportService.mypassportdetailsUpdatebyadmin(value3).subscribe(function (data) {
            _this.profileimage = data.passportprofileimage;
            _this.selectedDocList.splice(0, 1);
            var righttowork = {
                id: _this.logid,
                remaindocumentlist: _this.selectedDocList,
                passportid: data.employeepassdeatilid,
                profileimage: data.passportprofileimage
            };
            // console.log(this.logid);
            _this.checkService.updatenewrighttowork(righttowork).subscribe(function (righttoworklog) {
                _this.performAction();
            });
        });
        //  this.alerts.success('Passport details successfully.',true);
        // this.modalService.close(id);
    };
    RightToWorkAppAdminComponent.prototype.selectPassportFrontFile = function (event) {
        this.passportfrontfilesizeerror = false;
        this.passportfrontfileformaterror = false;
        this.passportfrontfilerequirederror = false;
        var files = event.target.files;
        this.passportfrontfilename = files[0].name;
        this.selectedpassportFrontFiles = this.passportfront = files[0];
        var filetype = this.passportfront.type.split('/');
        if (this.passportfront.size > 2000 * 1024) {
            this.passportfrontfilesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.passportfrontfileformaterror = true;
        }
        else {
            this.selectedpassportFrontFiles = files[0];
        }
    };
    RightToWorkAppAdminComponent.prototype.selectPassportBackFile = function (event) {
        this.passportbackfilesizeerror = false;
        this.passportbackfileformaterror = false;
        var files = event.target.files;
        this.passportbackfilename = files[0].name;
        this.selectedpassportBackFiles = this.passportback = files[0];
        var filetype = this.passportback.type.split('/');
        if (this.passportback.size > 2000 * 1024) {
            this.passportbackfilesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.passportbackfileformaterror = true;
        }
        else {
            this.selectedpassportBackFiles = files[0];
        }
    };
    RightToWorkAppAdminComponent.prototype.selectPassportProfile = function (event) {
        this.passportprofilerequirederror = false;
        this.passportprofilesizeerror = false;
        this.passportprofileformaterror = false;
        var files = event.target.files;
        this.passportprofilename = files[0].name;
        this.selectedpassportProfiles = this.passportprofile = files[0];
        var filetype = this.passportprofile.type.split('/');
        if (this.passportprofile.size > 2000 * 1024) {
            this.passportprofilesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.passportprofileformaterror = true;
        }
        else {
            this.selectedpassportProfiles = files[0];
        }
    };
    Object.defineProperty(RightToWorkAppAdminComponent.prototype, "v", {
        /** Passport form Process */
        /** Visa form Process */
        get: function () { return this.visaFormgroup.controls; },
        enumerable: true,
        configurable: true
    });
    RightToWorkAppAdminComponent.prototype.onSubmitVisa = function (value3) {
        var _this = this;
        // console.log(this.visaFormgroup);
        this.submitted = true;
        if (this.visaFormgroup.invalid) {
            if (!this.selectedvisaFrontFiles) {
                this.visafrontfilerequirederror = true;
            }
            if (!this.selectedvisaProfiles && !this.profileimage) {
                this.visaprofilerequirederror = true;
            }
            return;
        }
        if (this.selectedvisaFrontFiles) {
            this.visafront = this.selectedvisaFrontFiles;
            var filetype = this.visafront.type.split('/');
            if (this.visafront.size > 2000 * 1024) {
                this.visafrontfilesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.visafrontfileformaterror = true;
                return;
            }
            var visafrontimagepath = this.uploadService.uploadfile(this.visafront);
            value3.imagename = visafrontimagepath;
        }
        else {
            this.visafrontfilerequirederror = true;
            return;
        }
        if (this.selectedvisaBackFiles) {
            this.visaback = this.selectedvisaBackFiles;
            var filetype = this.visaback.type.split('/');
            if (this.visaback.size > 2000 * 1024) {
                this.visabackfilesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.visabackfileformaterror = true;
                return;
            }
            var visabackimagepath = this.uploadService.uploadfile(this.visaback);
            value3.imagebackname = visabackimagepath;
        }
        if (this.selectedvisaProfiles) {
            this.visaprofile = this.selectedvisaProfiles;
            var filetype = this.visaprofile.type.split('/');
            if (this.visaprofile.size > 2000 * 1024) {
                this.visaprofilesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.visaprofileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.visaprofile);
            value3.visaprofileimage = imagepath;
        }
        else {
            if (!this.profileimage) {
                this.visaprofilerequirederror = true;
                return;
            }
            else {
                value3.visaprofileimage = this.profileimage;
            }
        }
        // value3.visastart = this.datePipe.transform(new Date(value3.visastart),"dd MMM yyyy");
        // value3.visaexpiry = this.datePipe.transform(new Date(value3.visaexpiry),"dd MMM yyyy");
        value3.createby = this.user_id;
        value3.isrighttowork = 1;
        this.disableBtn = false;
        var sd = new Date(value3.visastart);
        value3.visastart = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        var ed = new Date(value3.visaexpiry);
        value3.visaexpiry = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        this.visaService.myvisadetailsUpdatebyadmin(value3).subscribe(function (datamain) {
            _this.profileimage = datamain.visaprofileimage;
            _this.selectedDocList.splice(0, 1);
            var righttowork = { id: _this.logid, remaindocumentlist: _this.selectedDocList, visaid: datamain.employeevisadeatilid, profileimage: datamain.visaprofileimage };
            _this.checkService.updatenewrighttowork(righttowork).subscribe(function (righttoworklog) {
                _this.performAction();
            });
        });
    };
    RightToWorkAppAdminComponent.prototype.checkrightoworkupdate = function () {
        var checkvalue = 0;
        this.updatebutton = false;
        if (this.updaterighttowork.passport) {
            checkvalue = 1;
        }
        if (this.updaterighttowork.visa) {
            checkvalue = 1;
        }
        if (this.updaterighttowork.document.length > 0) {
            checkvalue = 1;
        }
        if (checkvalue == 1) {
            this.updatebutton = true;
        }
    };
    RightToWorkAppAdminComponent.prototype.updaterighttoworkdocument = function () {
        var _this = this;
        if (confirm("Are you sure you want to Resend document request?")) {
            var checkvalue = 0;
            if (this.updaterighttowork.passport) {
                checkvalue = 1;
            }
            if (this.updaterighttowork.visa) {
                checkvalue = 1;
            }
            if (this.updaterighttowork.document.length > 0) {
                checkvalue = 1;
            }
            var righttowork = { id: this.logid, right: this.updaterighttowork };
            this.checkService.resedapplicantrequestwidthdocumentupdatebysuperadmin(righttowork).subscribe(function (righttoworklog) {
                _this.stage = 8;
            });
        }
        // console.log(this.updaterighttowork);
    };
    RightToWorkAppAdminComponent.prototype.changeidproofdetails = function (checked, id, masterid) {
        console.log(masterid);
        if (checked) {
            this.updaterighttowork.document.push(id);
            var index = this.updaterighttowork.documentid.indexOf(masterid);
            if (index > -1) {
                this.updaterighttowork.documentid.splice(index, 1);
            }
        }
        else {
            this.updaterighttowork.documentid.push(masterid);
            var index = this.updaterighttowork.document.indexOf(id);
            if (index > -1) {
                this.updaterighttowork.document.splice(index, 1);
            }
        }
        this.checkrightoworkupdate();
        // console.log(this.updaterighttowork);
    };
    RightToWorkAppAdminComponent.prototype.resendvisa = function (checked, id) {
        console.log(checked);
        if (checked)
            this.updaterighttowork.visa = true;
        else
            this.updaterighttowork.visa = false;
        this.checkrightoworkupdate();
    };
    RightToWorkAppAdminComponent.prototype.resendpassport = function (checked, id) {
        console.log(checked);
        if (checked)
            this.updaterighttowork.passport = true;
        else
            this.updaterighttowork.passport = false;
        this.checkrightoworkupdate();
    };
    RightToWorkAppAdminComponent.prototype.selectVisaFrontFile = function (event) {
        this.visafrontfilesizeerror = false;
        this.visafrontfileformaterror = false;
        this.visafrontfilerequirederror = false;
        var files = event.target.files;
        this.visafrontfilename = files[0].name;
        this.selectedvisaFrontFiles = this.visafront = files[0];
        var filetype = this.visafront.type.split('/');
        if (this.visafront.size > 2000 * 1024) {
            this.visafrontfilesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.visafrontfileformaterror = true;
        }
        else {
            this.selectedvisaFrontFiles = files[0];
        }
    };
    RightToWorkAppAdminComponent.prototype.selectVisaBackFile = function (event) {
        this.visabackfilesizeerror = false;
        this.visabackfileformaterror = false;
        var files = event.target.files;
        this.visabackfilename = files[0].name;
        this.selectedvisaBackFiles = this.visaback = files[0];
        var filetype = this.visaback.type.split('/');
        if (this.visaback.size > 2000 * 1024) {
            this.visabackfilesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.visabackfileformaterror = true;
        }
        else {
            this.selectedvisaBackFiles = files[0];
        }
    };
    RightToWorkAppAdminComponent.prototype.selectVisaProfile = function (event) {
        this.visaprofilerequirederror = false;
        this.visaprofilesizeerror = false;
        this.visaprofileformaterror = false;
        var files = event.target.files;
        this.visaprofilename = files[0].name;
        this.selectedvisaProfiles = this.visaprofile = files[0];
        var filetype = this.visaprofile.type.split('/');
        if (this.visaprofile.size > 2000 * 1024) {
            this.visaprofilesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.visaprofileformaterror = true;
        }
        else {
            this.selectedvisaProfiles = files[0];
        }
    };
    Object.defineProperty(RightToWorkAppAdminComponent.prototype, "d", {
        /** Document form process */
        get: function () {
            return this.idproofFormgroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    RightToWorkAppAdminComponent.prototype.onSubmitDocument = function (value3, id, stype) {
        var _this = this;
        this.submitted = true;
        // this.fileformaterror = false;
        // console.log(this.selecteddocFrontFiles);
        if (this.idproofFormgroup.invalid) {
            if (!this.selecteddocFrontFiles) {
                this.docfrontfilerequirederror = true;
            }
            if (!this.selecteddocProfiles && !this.profileimage) {
                this.docprofilerequirederror = true;
            }
            return;
        }
        if (this.selecteddocFrontFiles) {
            this.docfront = this.selecteddocFrontFiles;
            var filetype = this.docfront.type.split('/');
            if (this.docfront.size > 2000 * 1024) {
                this.docfrontfilesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.docfrontfileformaterror = true;
                return;
            }
            var frontdocimagepath = this.uploadService.uploadfile(this.docfront);
            value3.imagename = frontdocimagepath;
        }
        else {
            this.docfrontfilerequirederror = true;
            return;
        }
        if (this.selecteddocBackFiles) {
            this.docback = this.selecteddocBackFiles;
            var filetype = this.docback.type.split('/');
            if (this.docback.size > 2000 * 1024) {
                this.docbackfilesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.docbackfileformaterror = true;
                return;
            }
            var docbackimagepath = this.uploadService.uploadfile(this.docback);
            value3.imagebackname = docbackimagepath;
        }
        if (this.selecteddocProfiles) {
            this.docprofile = this.selecteddocProfiles;
            var filetype = this.docprofile.type.split('/');
            if (this.docprofile.size > 2000 * 1024) {
                this.docprofilesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.docprofileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.docprofile);
            value3.idprofileimage = imagepath;
        }
        else {
            if (!this.profileimage) {
                this.docprofilerequirederror = true;
                return;
            }
            else {
                value3.idprofileimage = this.profileimage;
            }
        }
        // value3.docexpiry = this.datePipe.transform(new Date(value3.docexpiry),"dd MMM yyyy");
        value3.createby = this.user_id;
        value3.updateby = this.user_id;
        this.disableBtn = false;
        var sd = new Date(value3.docexpiry);
        value3.docexpiry = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        this.documentService.myidproofdetailsUpdatebyadmin(value3).subscribe(function (datamain) {
            // console.log(datamain);
            _this.profileimage = datamain.idprofileimage;
            _this.selectedDocList.splice(0, 1);
            var righttowork = { id: _this.logid, remaindocumentlist: _this.selectedDocList, documentid: datamain.employeeidproofdeatilid, profileimage: datamain.idprofileimage };
            _this.checkService.updatenewrighttowork(righttowork).subscribe(function (righttoworklog) {
                _this.selecteddocFrontFiles = undefined;
                _this.selecteddocBackFiles = undefined;
                _this.performAction();
            });
        });
    };
    RightToWorkAppAdminComponent.prototype.selectDocFrontFile = function (event) {
        this.docfrontfilesizeerror = false;
        this.docfrontfileformaterror = false;
        this.docfrontfilerequirederror = false;
        var files = event.target.files;
        this.docfrontfilename = files[0].name;
        this.selecteddocFrontFiles = this.docfront = files[0];
        var filetype = this.docfront.type.split('/');
        if (this.docfront.size > 2000 * 1024) {
            this.docfrontfilesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.docfrontfileformaterror = true;
        }
        else {
            this.selecteddocFrontFiles = files[0];
        }
    };
    RightToWorkAppAdminComponent.prototype.selectDocBackFile = function (event) {
        this.docbackfilesizeerror = false;
        this.docbackfileformaterror = false;
        var files = event.target.files;
        this.docbackfilename = files[0].name;
        this.selecteddocBackFiles = this.docback = files[0];
        var filetype = this.docback.type.split('/');
        if (this.docback.size > 2000 * 1024) {
            this.docbackfilesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.docbackfileformaterror = true;
        }
        else {
            this.selecteddocBackFiles = files[0];
        }
    };
    RightToWorkAppAdminComponent.prototype.selectDocProfile = function (event) {
        this.docprofilerequirederror = false;
        this.docprofilesizeerror = false;
        this.docprofileformaterror = false;
        var files = event.target.files;
        this.docprofilename = files[0].name;
        this.selecteddocProfiles = this.docprofile = files[0];
        var filetype = this.docprofile.type.split('/');
        if (this.docprofile.size > 2000 * 1024) {
            this.docprofilesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.docprofileformaterror = true;
        }
        else {
            this.selecteddocProfiles = files[0];
        }
    };
    /** Upload report*/
    RightToWorkAppAdminComponent.prototype.selectPdfFile = function (event) {
        this.pdfbackfilesizeerror = false;
        this.pdfbackfileformaterror = false;
        var files = event.target.files;
        this.docpdffilename = files[0].name;
        this.selectdocpdffilename = this.docpdf = files[0];
        var filetype = this.docpdf.type.split('/');
        if (this.docpdf.size > 2000 * 1024) {
            this.pdfbackfilesizeerror = true;
        }
        else if (filetype[filetype.length - 1] != "pdf") {
            this.pdfbackfileformaterror = true;
        }
        else {
            this.confirmdoc = true;
            this.selectdocpdffilename = files[0];
        }
    };
    /** Document form process */
    RightToWorkAppAdminComponent.prototype.progressbarIncrease = function () {
        if (this.stage == 1) {
            this.progressbar = 0; //this.progressbar - this.per_stage/2;
        }
        else if (this.stage == 2 || this.stage == 3) {
            this.progressbar = this.progressbar + this.per_stage / 2;
        }
        else if (this.stage == 5) {
            // this.progressbar = this.progressbar + this.per_stage/;
        }
        else {
            this.progressbar = this.progressbar + this.per_stage;
        }
    };
    RightToWorkAppAdminComponent.prototype.progressbarDecrease = function () {
        if (this.stage == 1) {
            this.progressbar = 0; //this.progressbar - this.per_stage/2;
        }
        if (this.stage == 2 || this.stage == 3) {
            this.progressbar = this.progressbar - this.per_stage / 2;
        }
        else {
            this.progressbar = this.progressbar - this.per_stage;
        }
    };
    RightToWorkAppAdminComponent.prototype.backstage = function (stagechange) {
        this.loading = 1;
        this.progressbarDecrease();
        this.stage = stagechange;
        this.loading = 0;
    };
    RightToWorkAppAdminComponent.prototype.sendrequestchargestage = function (stagechange, type, check_type, rtw_country) {
        var _this = this;
        if (type === void 0) { type = ''; }
        if (check_type === void 0) { check_type = ''; }
        if (rtw_country === void 0) { rtw_country = ''; }
        if (type) {
            this.current_stage = type;
        }
        this.loading = 1;
        if (stagechange == 7) {
            this.request_type = check_type;
            if (this.current_stage == 'rtw') {
                this.modalService.open('confirm-modal');
            }
            else if (this.current_stage == 'adjusted_rtw') {
                // this.sendrequest('sendrequestcharge');
            }
        }
        else if (stagechange == 4) {
            this.rtw_country = rtw_country;
            if (this.rtw_country == 1) {
                this.qtype = 'A';
            }
            else {
                this.qtype = 'B';
            }
            this.countrywiseQuestionList = [];
            this.questionlist.forEach(function (que) {
                // que.selected = false;
                if (_this.rtw_country == 1) {
                    if (que.qtype == 'A') {
                        _this.countrywiseQuestionList.push(que);
                    }
                }
                else if (_this.rtw_country == 0) {
                    if (que.qtype == 'B') {
                        _this.countrywiseQuestionList.push(que);
                    }
                }
            });
            if (type) {
                this.countryid = type;
            }
            if (this.isrighttowork == 2) {
                this.stage = 10;
            }
            else if (this.isrighttowork == 1) {
                // if(type == 'first')
                // {          
                this.questionselect = [];
                this.questionselect.push(1);
                this.check.getbillinginformation({ 'serviceid': 4 }).subscribe(function (remaindata) {
                    _this.remainingcheck = remaindata;
                    if (remaindata.remiancheck > 0) {
                        _this.modalService.open("confirm-modal");
                    }
                    else {
                        _this.modalService.open("upgrade-plan");
                    }
                });
                // } 
                // else if(type == 'second')
                // {
                //   this.questionselect =null;
                //   this.questionselect.push(1);
                //   this.stage = stagechange;
                // }
                // this.stage =10;
            }
            else {
                this.stage = stagechange;
            }
        }
        else if (stagechange == 5) {
            this.disableBtn = false;
            if (type == 'first') {
                this.check.getbillinginformation({ 'serviceid': 4 }).subscribe(function (remaindata) {
                    _this.remainingcheck = remaindata;
                    if (remaindata.remiancheck > 0) {
                        _this.modalService.open("confirm-modal");
                    }
                    else {
                        _this.modalService.open("upgrade-plan");
                    }
                });
            }
            else if (type == 'second') {
                this.stage = stagechange;
            }
            else {
                this.checkQuestionList();
                if (this.isrighttowork == 3) {
                    this.stage = 10;
                }
                else {
                    if (this.update) {
                        var updaterecord = {
                            employeeid: this.empid,
                            id: this.logid,
                            doucmentlist: this.selectedDocList,
                            remaindocumentlist: this.selectedDocList,
                            questionlist: this.questionselect
                        };
                        this.checkService.updaterighttowork(updaterecord).subscribe(function (righttoworklog) {
                            // console.log(righttoworklog);
                            if (_this.isrighttowork == 1) {
                                _this.modalService.close("confirm-modal");
                                _this.stage = 8;
                            }
                            else {
                                _this.logid = righttoworklog.data.id;
                                localStorage.setItem('logid', righttoworklog.data.id);
                                _this.modalService.close("confirm-modal");
                                _this.stage = stagechange;
                            }
                        });
                    }
                    else {
                        var righttowork = {
                            employeeid: this.empid,
                            cemployeeid: this.user_id,
                            isrighttowork: this.isrighttowork,
                            isrighttoworktype: this.isrighttoworktype,
                            countryid: this.countryid,
                            qtype: this.qtype,
                            doucmentlist: this.selectedDocList,
                            remaindocumentlist: this.selectedDocList,
                            currentstage: this.stage,
                            questionlist: this.questionselect
                        };
                        this.checkService.insertnewrighttowork(righttowork).subscribe(function (righttoworklog) {
                            _this.update = true;
                            // console.log(righttoworklog);
                            // this.questionselect = righttoworklog..questionlist.split(',');
                            _this.country.getcountrylistbyid({ 'id': righttoworklog.data.countryid }).subscribe(function (country) {
                                _this.currentCompany = country;
                            });
                            if (_this.isrighttowork == 1) {
                                _this.modalService.close("confirm-modal");
                                _this.stage = 8;
                            }
                            else {
                                _this.logid = righttoworklog.data.id;
                                localStorage.setItem('logid', righttoworklog.data.id);
                                _this.modalService.close("confirm-modal");
                                _this.stage = stagechange;
                            }
                        });
                    }
                }
            }
        }
        else if (stagechange == 11) {
            this.stage = stagechange;
        }
        else if (stagechange == 13) {
            if (type == 'first') {
                this.check.getbillinginformation({ 'serviceid': 4 }).subscribe(function (remaindata) {
                    _this.remainingcheck = remaindata;
                    if (remaindata.remiancheck > 0) {
                        _this.modalService.open("confirm-modal");
                    }
                    else {
                        _this.modalService.open("upgrade-plan");
                    }
                });
            }
            else if (type == 'second') {
                this.stage = stagechange;
            }
            else {
                this.checkQuestionList();
                if (this.isrighttowork == 3) {
                    this.stage = stagechange;
                }
                else {
                    if (this.update) {
                        var updaterecord = {
                            employeeid: this.empid,
                            id: this.logid,
                            doucmentlist: this.selectedDocList,
                            remaindocumentlist: null,
                            questionlist: null
                        };
                        this.checkService.updaterighttowork(updaterecord).subscribe(function (righttoworklog) {
                            // console.log(righttoworklog);
                            if (_this.isrighttowork == 1) {
                                _this.modalService.close("confirm-modal");
                                _this.stage = 8;
                            }
                            else {
                                _this.logid = righttoworklog.data.id;
                                localStorage.setItem('logid', righttoworklog.data.id);
                                _this.modalService.close("confirm-modal");
                                _this.stage = stagechange;
                            }
                        });
                    }
                    else {
                        var righttowork = {
                            employeeid: this.empid,
                            cemployeeid: this.user_id,
                            isrighttowork: this.isrighttowork,
                            isrighttoworktype: this.isrighttoworktype,
                            countryid: this.countryid,
                            qtype: this.qtype,
                            doucmentlist: this.selectedDocList,
                            remaindocumentlist: null,
                            currentstage: this.stage,
                            questionlist: null
                        };
                        this.checkService.insertnewrighttowork(righttowork).subscribe(function (righttoworklog) {
                            _this.update = true;
                            // console.log(righttoworklog);
                            // this.questionselect = righttoworklog..questionlist.split(',');
                            _this.country.getcountrylistbyid({ 'id': righttoworklog.data.countryid }).subscribe(function (country) {
                                _this.currentCompany = country;
                            });
                            if (_this.isrighttowork == 1) {
                                _this.modalService.close("confirm-modal");
                                _this.stage = 8;
                            }
                            else {
                                _this.logid = righttoworklog.data.id;
                                localStorage.setItem('logid', righttoworklog.data.id);
                                _this.modalService.close("confirm-modal");
                                _this.stage = stagechange;
                            }
                        });
                    }
                }
            }
        }
        else if (stagechange == 2) {
            this.stage = stagechange;
            if (type) {
                this.candidatenoteFormGroup = this.formBuilder.group({
                    notes: ['', forms_1.Validators.required],
                    sharecode: [''],
                    Checkconductedby: ['', forms_1.Validators.required],
                    Checkconductedbyrole: ['', forms_1.Validators.required],
                    companyname: ['', forms_1.Validators.required],
                    ninumber: ['', forms_1.Validators.required],
                    dateofbirth: ['', forms_1.Validators.required]
                });
                if (type == 'rtw')
                    this.isrighttowork = 0;
                else if (type == 'adjusted_rtw')
                    this.isrighttowork = 1;
                else {
                    this.candidatenoteFormGroup = this.formBuilder.group({
                        notes: ['', forms_1.Validators.required],
                        sharecode: [''],
                        Checkconductedby: ['', forms_1.Validators.required],
                        Checkconductedbyrole: ['', forms_1.Validators.required],
                        companyname: [''],
                        ninumber: [''],
                        dateofbirth: ['']
                    });
                    this.isrighttowork = 2;
                }
            }
        }
        else if (stagechange == 3) {
            this.stage = stagechange;
            if (type) {
                if (type == 'in')
                    this.isrighttoworktype = 0;
                else
                    this.isrighttoworktype = 1;
            }
        }
        else {
            this.allquestionlist();
            this.stage = stagechange;
        }
        this.progressbarIncrease();
        this.loading = 0;
    };
    /** Share Code Save */
    RightToWorkAppAdminComponent.prototype.onShareCode = function (value, id) {
        var _this = this;
        this.submitted = true;
        if (this.sharecodeFormGroup.invalid) {
            return;
        }
        var sd = new Date(value.dateofbirth);
        value.dateofbirth = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        var updaterecord = {
            employeeid: this.empid,
            id: this.logid,
            sharecode: value.sharecode,
            companyname: value.companyname,
            dateofbirth: value.dateofbirth,
            ninumber: value.ninumber
        };
        this.checkService.updatenewrighttowork(updaterecord).subscribe(function (righttoworklog) {
            _this.submitted = false;
            _this.stage = 12;
        });
    };
    /** Check the list of questions and performing action */
    RightToWorkAppAdminComponent.prototype.checkQuestionList = function () {
        var _this = this;
        // console.log(this.stage);
        this.selectedDocList = [];
        if (this.questionselect.length) {
            // console.log(this.questionlist);
            this.questionselect.forEach(function (queid) {
                // console.log(queid);
                _this.countrywiseQuestionList.forEach(function (element) {
                    // console.log(element);
                    if (element.id == queid) {
                        _this.selectedDocList.push(element.documentid);
                        if (element.documentid2 != 0) {
                            _this.selectedDocList.push(element.documentid2);
                        }
                    }
                });
            });
            this.selectedDocList = this.selectedDocList.filter(function (n, i) { return _this.selectedDocList.indexOf(n) === i; });
            // this.progressbar + this.per_stage/
            this.docprogress = this.per_stage / this.selectedDocList.length;
            this.performAction();
        }
    };
    /** Perform required action */
    RightToWorkAppAdminComponent.prototype.performAction = function () {
        var _this = this;
        // console.log(this.selectedDocList);
        this.progressbar = this.progressbar + this.docprogress;
        this.disableBtn = true;
        this.submitted = false;
        if (this.selectedDocList.length) {
            var docid = this.selectedDocList[0];
            // console.log(docid);
            if (Number(docid) == 100) {
                this.passportDetail = new passport_model_1.Passport();
                this.passportDetail.employeeid = this.empid;
                this.passportDetail.nationalityid = "70";
                this.passportDetail.passportissue = "";
                this.passportDetail.passportexpiry = "";
                this.passportDetail.employeepassdeatilid = "0";
                // this.passportDetail = data;
                var passport = {
                    id: "0",
                    employeeid: this.empid,
                    passportnumber: '',
                    placeofissue: '',
                    passportissue: '',
                    passportexpiry: '',
                    nationalityid: "70"
                };
                this.passportFormgroup.reset(passport);
                this.openDocumentForm = 1;
            }
            else if (Number(docid) == 101) {
                this.visaDetail = new visa_model_1.Visa();
                this.visaDetail.nationalityid = "70";
                this.visaDetail.employeeid = this.empid;
                this.visaDetail.employeevisadeatilid = "0";
                this.visaDetail.visastart = "";
                this.visaDetail.visaexpiry = "";
                var visa = {
                    id: "0",
                    employeeid: this.empid,
                    visanumber: '',
                    visatype: '',
                    visastart: '',
                    visaexpiry: '',
                    nationalityid: "70"
                };
                this.visaFormgroup.reset(visa);
                this.openDocumentForm = 2;
            }
            else {
                this.docfrontfilename = "Choose file";
                this.docbackfilename = "Choose file";
                this.docprofilename = "Choose file";
                this.currentdocid = Number(docid);
                this.documenttype.forEach(function (element) {
                    if (element.id == Number(docid)) {
                        _this.docname = element.documentname;
                    }
                });
                this.documentDetail = new idproof_model_1.Idproof();
                this.documentDetail.employeeid = this.empid;
                this.documentDetail.employeeidproofdeatilid = "0";
                this.documentDetail.nationalityid = "70";
                this.documentDetail.idtype = docid;
                var doc = {
                    id: "0",
                    employeeid: this.empid,
                    idnumber: '',
                    idtype: '',
                    docexpiry: '',
                    nationalityid: "70"
                };
                this.idproofFormgroup.reset(doc);
                this.openDocumentForm = 3;
            }
        }
        else {
            this.sendrequestchargestage(6);
        }
    };
    RightToWorkAppAdminComponent.prototype.questionselection = function (event, id) {
        var _this = this;
        if (this.isrighttowork == 1) {
            this.questionselect.push(1);
        }
        else {
            if (event.target.checked) {
                this.questionselect.push(id);
            }
            else {
                this.questionselect.forEach(function (element, index) {
                    if (element == id) {
                        _this.questionselect.splice(index, 1);
                    }
                });
            }
        }
    };
    /* final question list */
    RightToWorkAppAdminComponent.prototype.allquestionlist = function () {
        var _this = this;
        this.reviewDocsByAdmin();
        /* For step question */
        var db = { qtype: 4 };
        this.checkService.allquestion(db).subscribe(function (dataquestion) {
            _this.totalquestioncount = dataquestion.length;
            _this.questionlist1 = dataquestion;
            var permissionstring = "[{";
            var perarray = [];
            dataquestion.forEach(function (valuemenu) {
                perarray.push('"' + valuemenu.id + '": 0');
            });
            var perstr = perarray.join();
            permissionstring = permissionstring + perstr + "}]";
            _this.questionstring = permissionstring;
        });
    };
    RightToWorkAppAdminComponent.prototype.onQuestionSelection = function (ind, subm) {
        var tg;
        var tg1;
        this.questionError = false;
        tg = JSON.parse(this.questionstring.toString());
        tg.forEach(function (value4) {
            value4[ind] = subm;
        });
        var starry = JSON.stringify(tg);
        this.questionstring = starry;
        var checktrue = true;
        tg = JSON.parse(this.questionstring);
        // console.log(tg);
        tg.forEach(function (value4, index) {
            if (value4[index] == 0) {
                checktrue = false;
            }
        });
        this.showfinish = checktrue;
        // console.log(starry);
    };
    RightToWorkAppAdminComponent.prototype.completeprocess = function () {
        var tg = JSON.parse(this.questionstring);
        var checktrue = false;
        tg.forEach(function (element) {
            for (var key in element) {
                if (element[key] == 0) {
                    checktrue = true;
                }
            }
        });
        if (checktrue) {
            this.questionError = true;
            return;
        }
        // if(this.isrighttowork ==2)
        //     this.modalService.open('add-notes-to-right-to-work');
        // else
        this.modalService.open('add-notes-to-right-to-work-2');
        // var righttowork ={ id: this.logid ,  anslist: this.questionstring.toString()  }
        // this.checkService.updatenewrighttowork(righttowork).subscribe((righttoworklog: any) => {        
        //   this.modalService.open('add-notes-to-right-to-work');
        //   // localStorage.removeItem('logid');
        // });
    };
    RightToWorkAppAdminComponent.prototype.updatecandidatenote = function (value) {
        var _this = this;
        console.log(this.candidatenoteFormGroup);
        this.submitted = true;
        if (this.candidatenoteFormGroup.invalid) {
            return;
        }
        this.submitted = false;
        if (this.selectdocpdffilename) {
            this.docprofile = this.selectdocpdffilename;
            var filetype = this.docprofile.type.split('/');
            if (this.docprofile.size > 2000 * 1024) {
                this.pdfbackfilesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "pdf")) {
                this.pdfbackfileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.docprofile);
            value.pdfreport = imagepath;
        }
        var base64Data = this.signaturePad.toDataURL();
        this.signatureImg = base64Data;
        this.signatureImg = this.uploadService.uploadfilenotebase64(base64Data);
        var righttowork = {
            id: this.logid,
            notes: value.notes,
            anslist: this.questionstring.toString(),
            pdfreport: value.pdfreport,
            sharecode: value.sharecode,
            Checkconductedby: value.Checkconductedby,
            Checkconductedbyrole: value.Checkconductedbyrole,
            Checkconductedbysign: this.signatureImg,
            ninumber: value.ninumber,
            companyname: value.companyname,
            dateofbirth: value.dateofbirth,
        };
        if (value.dateofbirth) {
            var sd = new Date(value.dateofbirth);
            righttowork.dateofbirth = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        }
        if (value.ninumber) {
            righttowork.ninumber = value.ninumber;
        }
        console.log('Right To work Update', righttowork);
        this.checkService.updatenewrighttowork(righttowork).subscribe(function (righttoworklog) {
            // if(this.isrighttowork ==2)
            //     this.modalService.close('add-notes-to-right-to-work');
            // else
            _this.modalService.close('add-notes-to-right-to-work-2');
            _this.stage = 7;
        });
    };
    RightToWorkAppAdminComponent.prototype.requestsendagain = function () {
        var _this = this;
        var righttowork = { id: this.logid };
        this.checkService.resedapplicantrequest(righttowork).subscribe(function (righttoworklog) {
            _this.stage = 8;
        });
    };
    RightToWorkAppAdminComponent.prototype.reviewDocsByAdmin = function () {
        var _this = this;
        var data = { logid: this.logid };
        this.checkService.getrighttoworkdetailsbylogidsuperadmin(data).subscribe(function (righttoworklog) {
            _this.visapageOfItems = righttoworklog.visa;
            _this.passportpageOfItems = righttoworklog.passport;
            _this.docpageOfItems = righttoworklog.document;
        });
    };
    RightToWorkAppAdminComponent.prototype.openModal3 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    RightToWorkAppAdminComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    RightToWorkAppAdminComponent.prototype.onChangePage = function (id) {
    };
    RightToWorkAppAdminComponent.prototype.hidedivform = function () {
        this.hidediv = false;
    };
    RightToWorkAppAdminComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    RightToWorkAppAdminComponent.prototype.uploadByAdmin = function (is, u, i) { };
    return RightToWorkAppAdminComponent;
}());
exports.RightToWorkAppAdminComponent = RightToWorkAppAdminComponent;
