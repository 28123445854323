"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// import { EmployeeMaster } from './../models/employee_master';
// import { HeaderComponent } from './user/header/header.component';
var core_1 = require("@angular/core");
var mycos_service_1 = require("../../../services/mycos.service");
var modal_service_1 = require("../../../_services/modal.service");
var cos_model_1 = require("../../../models/cos/cos.model");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var platform_browser_1 = require("@angular/platform-browser");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var alert_service_1 = require("../../../services/alert.service");
var CoslistComponent = /** @class */ (function () {
    function CoslistComponent(sanitizer, formBuilder, modalService, data, alerts, uploadService, activatedRoute) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.data = data;
        this.alerts = alerts;
        this.uploadService = uploadService;
        this.activatedRoute = activatedRoute;
    }
    CoslistComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        this.isfile = false;
        this.empid = this.activatedRoute.snapshot.paramMap.get("id");
        this.cosForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            iscurrent: [''],
            cosnumber: ['', forms_1.Validators.required],
            cosstartdate: ['', forms_1.Validators.required],
            cosexpirydate: ['', forms_1.Validators.required],
            cosdocname: [''],
            employeeid: ['', forms_1.Validators.required]
        });
        this.cosdetails = new cos_model_1.Cos();
        this.data.getcosListbyadmin({ 'id': this.empid }).subscribe(function (data) {
            _this.cosDetails = data;
        });
    };
    Object.defineProperty(CoslistComponent.prototype, "f", {
        get: function () { return this.cosForm.controls; },
        enumerable: true,
        configurable: true
    });
    CoslistComponent.prototype.onSubmit = function (formcos, id) {
        var _this = this;
        // var formcos = value3.value;
        this.submitted = true;
        if (formcos.iscurrent == 0) {
            formcos.iscurrent = false;
        }
        if (formcos.iscurrent == 1) {
            formcos.iscurrent = true;
        }
        if (this.cosForm.invalid) {
            return;
        }
        this.disableBtn = false;
        if (this.selectedFiles) {
            // this.file = this.selectedFiles;
            // var filetype = this.file.type.split('/');
            // var typeoffile = filetype[filetype.length - 1];
            // if(this.file.size > 2000*1024)
            // {
            //   this.filesizeerror = true;
            //   return;
            // }
            // else if((typeoffile != "jpeg") && (typeoffile != "jpg") && (typeoffile != "png") && (typeoffile != "gif") && (typeoffile != "tif") && (typeoffile != "pdf") && (typeoffile != "doc")  && (typeoffile != "docx"))
            // {
            //   this.fileformaterror = true;
            //   return;
            // }
            // var imagepath = this.uploadService.uploadfile(this.file);
            // formcos.cosdocname = imagepath;
            formcos.cosdocname = this.imagepath;
        }
        else {
            formcos.cosdocname = this.cosdetails.cosdocname;
        }
        formcos.createby = this.Userid;
        formcos.updateby = this.Userid;
        var sd = new Date(formcos.cosstartdate);
        formcos.cosstartdate = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        var ed = new Date(formcos.cosexpirydate);
        formcos.cosexpirydate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        this.data.mycosdetailsUpdatebyadmin(formcos).subscribe(function (data) {
            _this.data.getcosListbyadmin({ 'id': _this.empid }).subscribe(function (data) {
                // value3.reset();
                _this.cosDetails = data;
            });
        });
        this.alerts.success('COS Info Updated Successfully', true);
        this.modalService.close(id);
        // this.cosdetails = new Cos();
    };
    CoslistComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        var typeoffile = filetype[filetype.length - 1];
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
            return;
        }
        else if ((typeoffile != "jpeg") && (typeoffile != "jpg") && (typeoffile != "png") && (typeoffile != "gif") && (typeoffile != "tif") && (typeoffile != "pdf") && (typeoffile != "doc") && (typeoffile != "docx")) {
            this.fileformaterror = true;
            return;
        }
        this.imagepath = this.uploadService.uploadfile(this.file);
        var dd = { 'url': this.imagepath };
        this.data.mycosaibyadmin(dd).subscribe(function (data) {
            _this.isfile = true;
            // console.log(data);
        });
    };
    CoslistComponent.prototype.openModal = function (id, data) {
        this.submitted = false;
        this.buttoninsert = "Update";
        this.disableBtn = true;
        this.cosdetails = data;
        if (this.cosdetails.iscurrent === true) {
            this.cosdetails.iscurrent = 1;
        }
        if (this.cosdetails.iscurrent === false) {
            this.cosdetails.iscurrent = 0;
        }
        this.modalService.open(id);
    };
    CoslistComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.disableBtn = true;
        this.filename = "Choose File";
        // this.cosForm.invalid == false;
        this.cosdetails = new cos_model_1.Cos();
        this.buttoninsert = "Submit";
        this.cosdetails.employeeid = this.empid;
        this.cosdetails.employecosdetailid = "0";
        this.cosdetails.iscurrent = 0;
        // this.cosDetails.cosstartdate = new Date();
        // this.cosdetails.cosnumber ='';
        // this.cosdetails.cosstartdate ='';
        // this.cosdetails.cosexpirydate ='';
        // this.cosdetails.cosdocname ='';
        this.cosdetails.iscurrent = false;
        this.modalService.open(id);
    };
    CoslistComponent.prototype.onDelete = function (cosid) {
        var _this = this;
        if (confirm("Are you sure you want to delete?")) {
            var data1 = { 'id': cosid };
            this.data.mycosDeletebyadmin(data1).subscribe(function (data) {
                _this.data.getcosListbyadmin({ 'id': _this.empid }).subscribe(function (data) {
                    _this.cosDetails = data;
                });
            });
        }
    };
    CoslistComponent.prototype.closeModal = function (id) {
        var _this = this;
        // this.employeementDetail = new Cos();
        this.data.getcosListbyadmin({ 'id': this.empid }).subscribe(function (data) {
            _this.cosDetails = data;
        });
        this.modalService.close(id);
    };
    CoslistComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    CoslistComponent.prototype.openModal3 = function (id, data) {
        this.iframecossrc = '';
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgcossrc = true;
            this.iframecossrc = data;
        }
        else {
            this.imgcossrc = false;
            this.iframecossrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    return CoslistComponent;
}());
exports.CoslistComponent = CoslistComponent;
