
import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DirectorService } from '../../services/director.service';
import { Director } from '../../models/director/director.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as CanvasJS from './../../admin/canvasjs.min';
import { DatePipe } from '@angular/common';
import { ModalService } from '../../_services/modal.service';
import { ChecksystemService } from '../../services/checksystem.service';
import { AmlBussiness,Aml,adverst } from '../../models/aml/aml.model';
import { CountryService } from '../../services/country.service';

@Component({
  selector: 'app-directorsearch',
  templateUrl: './directorsearch.component.html',
  styleUrls: ['./directorsearch.component.css']
})
export class DirectorsearchComponent implements OnInit {

  countrylist: any;
  directorFormgroup: FormGroup;
  directorDetail: any;
  directors: any = [];
  defaultVal: any = "";
  error:number =0;
  mode:any = 1;
  company: any;
  // i: number=0;
  director: any;
  companydata: any;
  satisfied_charge_details: any = [];
  outstanding_charge_details: any = [];
  all_charge_details: any = [];
  chargeFlag: any = 0;
  paymentInfoType: any = 0;
  disableBtn:boolean;
  lesssore:boolean;
  peopleId:any;
  submitted:any;
  hidefiling: any = true;
  imageDocsCopy: any = [];
  searchresult: any;
  searchresultnew: any;
  downloadtext: any;
  searchText:any;
  sscondition:any;
  downloadtext5:any;
  downloadtext6:any;
  searchloading:any = 5;
  formsubmit:number=0;
  month:number;
  year:number;

  nameset ={ name: '', dob: '' }



  constructor(
    private _el: ElementRef,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    // private companyadvertService: CompanyadvertService,
    private directorService: DirectorService,
    public datepipe: DatePipe,
    private modalService: ModalService,     
    private check: ChecksystemService,
    private country: CountryService
  ) { 
    route.params.subscribe(params => {
      // console.log(params);
      if(params['id']){
        console.log('Post Code',params['id']);
        this.getDataByDirectorhistory(params['id'], 1);
      } else{
        this.mode = 0;
      }
    });
  }

  ngOnInit() {
    this.disableBtn =true;
    this.submitted = false;
    
    var ts  = { 'serviceid': 12};    
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      if(remaindata.remiancheck <= 0)
      {
          this.lesssore =true;
      }
    });

    
  //   this.country.getcountrylist().subscribe((country: any) => {
  //     this.countrylist = country;

  // //    this.defaultVal ='GB';
  //   });
    this.directorDetail = new Director();
    this.directorFormgroup = this.formBuilder.group({
      firstname: ['', Validators.required],
      dob: [''],
      // country: ['', Validators.required]
    });

    
this.downloadtext6 = 'No Record Found <i class="fa fa-file-pdf-o ml-2"></i>';

this.downloadtext5 = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
  
this.searchhistory();
    
  }


  searchhistory()
  {
    var tt={}
    this.directorService.getdirectorhistory().subscribe((searchRes: any) => {  
      this.downloadtext = new Array(searchRes.length);
      for(var i=0;i<searchRes.length;i++)
      {
        this.downloadtext[i] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
      }
      searchRes.forEach((element,index) => {
        searchRes[index].searchresult = JSON.parse(element.searchresult);
      });
      console.log(searchRes);
      this.searchresult = searchRes;//searchRes;   
    });  

  }

  viewsearchhistory(searchcondition:any){
    this.sscondition = searchcondition;
    this.getDataByDirectorhistory(searchcondition, 1);
  }


  clearAdverseCreditData()
  {
    this.directorFormgroup.reset({firstname: ''});
    this.submitted = false; 
  }


  get f() { return this.directorFormgroup.controls; }


  directorsearch()
  {
    this.mode=0;this.searchhistory();
  }

  
  onSubmitAdvert(advert: any){
    this.submitted = true;
    if (this.directorFormgroup.invalid) {
      return;
    }
    this.error =0;

    this.month =0;
    this.year =0;
    
    this.formsubmit =0;
    // var dd = { 
    //   firstname: advert.firstname,
    //   lastname:advert.lastname, 
    //   dob:advert.dob, 
    //   countries: advert.country 
    // };

    if(advert.dob)
    {

      console.log('Month', advert.dob);

      var tt= new Date(advert.dob);

      console.log('System master', tt);

      this.month =parseInt(tt.getMonth().toString())+1;
      console.log('Month', this.month);
      this.year =parseInt(tt.getFullYear().toString());
      console.log('Year', this.year);



      const sd = new Date(advert.dob);
      advert.dob = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());




      this.nameset.dob =advert.dob;// this.month +' '+ this.year;

    }


    this.nameset.name = advert.firstname;

    var dd = { 
      firstname: advert.firstname,
      lastname:''
    };

    if(this.formsubmit ==0){

    this.formsubmit =1;
    console.log(dd);
    this.directorService.searchdirector(dd).subscribe((directordata: any) => {   
      
      console.log(directordata.data.statusCode);

      console.log(directordata.data.body.items.length);
      this.formsubmit =0;
      if(directordata.data.statusCode ==400){
        this.error =1;
      }else
      {
        this.mode =2;       
        this.directors = directordata.data.body.items;


        // if(directordata.data.body.items)
        // {
        //   this.mode =2;       
        //   this.directors = directordata.data.body.items;
        //   // if(directordata.data.body.items.length ==0)
        //   // {
        //   //   this.error =1;
        //   // }else
        //   // {
        //   //     this.mode =2;       
        //   //     this.directors = directordata.data.body.items;
        //   // }

        // }else{

        //   this.error =0;
        // }
        
        
      }
    });


  }


  }


  getDataByDirectorhistory(directorcode: any, history: any = 0){
    this.peopleId = directorcode;
    localStorage.setItem('peopleId', directorcode);
     console.log('Directory Code',directorcode);
    // console.log(history);
    if(history){
      this.directorService.getdirectorhistoryview({"peopleId": directorcode }).subscribe((directordata: any) => { 
        // console.log(directordata.data);
        this.bindDirectorData(directordata.data);
        this.mode = 1;
      });
    } else{
      this.directorService.getdirectordata({"peopleId": directorcode }).subscribe((directordata: any) => { 
        this.bindDirectorData(directordata.data);
      });
    }
  }

  downloaddirectordata(directorcode: any, ind){
    this.downloadtext[ind] = 'Loading <i class="fa fa-spinner fa-spin"></i>';
    this.downloadtext5 = 'Loading <i class="fa fa-spinner fa-spin"></i>';
    this.directorService.downloaddirectordata({"directorcode":directorcode }).subscribe((searchRes: any) => {  
      this.downloadtext[ind] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
      this.downloadtext5 = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
      window.open(searchRes.response_data.Location, "_blank");
      console.log(searchRes); 
    });
  }

  outputnewset(d){
    var listmaster =JSON.parse(d);  
    this.searchresultnew = new adverst();
    this.searchresultnew = listmaster;
  }

  outputnewBussinessset(d){
    var listmaster =JSON.parse(d);  
    this.searchresultnew = new AmlBussiness();
    this.searchresultnew = listmaster;
  }

  getDataByDirectorCode(peopleId: any){
    this.peopleId = peopleId;
    localStorage.setItem('peopleId', peopleId);
    this.sscondition = peopleId;
    var ts  = { 'serviceid': 12}; 
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => {     
      if(remaindata.remiancheck <= 0){
        this.modalService.open('upgrade-plan');
      } else{
        this.modalService.open('confirm-modal');
      }
    });
  }

  getDataByDirectorCodesubmit(directorcode: any, history: any = 0)
  {
    localStorage.setItem('peopleId', directorcode);
    this.modalService.close('confirm-modal');
    this.modalService.close('upgrade-plan');

    var ts  = { 'serviceid': 12}; 
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => {     
      if(remaindata.remiancheck <= 0){
        this.modalService.open('upgrade-plan');


      } else{       
        this.searchloading =0;
      
        this.mode = 1;

        if(history){
          this.directorService.getdirectorhistoryview({"peopleId": directorcode }).subscribe((directordata: any) => { 
            this.bindDirectorData(directordata.data);
            this.searchloading =5;
          });
        } else{
          this.directorService.getdirectordata({"peopleId": directorcode }).subscribe((directordata: any) => { 
            this.searchloading =5;
            this.bindDirectorData(directordata.data);
            this.mode = 1;
          });
        }
      }
    });
  }

  bindDirectorData(directordata){
    this.director = directordata;
    console.log(directordata);
  }

  // downloadImage(imageId){
  //   if(imageId){
  //     this.companyadvertService.downloadcompanyimage({"imageid": imageId }).subscribe((imagedata: any) => { 
  //       console.log(imagedata);
  //     });
  //   }
  // }

  ngDoCheck(){
    
  }

  changeChargeDetails(status: any = 0){
    if(status==2){
      this.company.general_info.charge_details = this.satisfied_charge_details;
      this.chargeFlag = 2;
    } else if(status==1){
      this.company.general_info.charge_details = this.outstanding_charge_details;
      this.chargeFlag = 1;
    } else{
      this.company.general_info.charge_details = this.all_charge_details;
      this.chargeFlag = 0;
    }
  }


  chnagestatus(id){
    this.mode = id;

    var ts  = { 'serviceid': 12};    
    this.check.getbillinginformation(ts).subscribe((remaindata: any) => { 
      if(remaindata.remiancheck <= 0)
      {
          this.lesssore =true;
      }
    });
    this.searchhistory();

  }

  closeModal(id: string) 
  {
    this.modalService.close(id);
  }

  // downloadcompanydata(companycode: any, companyname: any = ''){
  //   this.companyadvertService.downloadcompanyadvertdata({"companycode": companycode, "companyname":companyname }).subscribe((companydata: any) => {
  //     console.log(companydata);
  //   });
  // }

  getCompanyData(companyId){
    this.directorService.getcompanydata({"companylink": companyId }).subscribe((companydata: any) => { 
      console.log(companydata);
      
      this.companydata = companydata.data.body;
      this.mode = 3;
    });
  }

  norecordfound(){

    this.downloadtext6 = 'Loading <i class="fa fa-spinner fa-spin"></i>';

    this.directorService.getNorecordcompanydata(this.nameset).subscribe((companydata: any) => { 
      this.downloadtext6 = 'No Record Found <i class="fa fa-file-pdf-o ml-2"></i>';
      window.open(companydata.response_data.Location, "_blank");
    });


  }

  // updatevalue()
  // {
  //   this.i =1;
  // }  

}
