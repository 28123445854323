"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var checkservice_service_1 = require("../../../services/checkservice.service");
var common_1 = require("@angular/common");
var RighToWorkHistoryListComponent = /** @class */ (function () {
    function RighToWorkHistoryListComponent(activatedRoute, checkService, router, datePipe) {
        this.activatedRoute = activatedRoute;
        this.checkService = checkService;
        this.router = router;
        this.datePipe = datePipe;
    }
    RighToWorkHistoryListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.empid = this.activatedRoute.snapshot.paramMap.get("empid");
        var data = { employeeid: this.empid };
        this.checkService.getrighttoworkhistorybyemployeeid(data).subscribe(function (righttoworklog) {
            _this.listemployee = righttoworklog;
        });
    };
    RighToWorkHistoryListComponent.prototype.shouldDisplayDate = function (date) {
        console.log('Current Date', this.datePipe.transform(date, 'yyyy-MM-dd'));
        console.log('System Date', this.datePipe.transform('10/02/2022', 'yyyy-MM-dd'));
        if (this.datePipe.transform(date, 'yyyy-MM-dd') >= this.datePipe.transform('10/02/2022', 'yyyy-MM-dd')) {
            console.log('yes');
            return true;
        }
        console.log('False');
        return false;
    };
    RighToWorkHistoryListComponent.prototype.completerightowork = function (logid) {
        localStorage.SetItem('logid', logid);
        this.router.navigate(['/right-to-work-app-history']);
    };
    RighToWorkHistoryListComponent.prototype.viewemployeerighttoworkhistory = function (empid) {
        localStorage.removeItem('logid');
        this.router.navigate(['/right-to-work-app-history', empid]);
    };
    RighToWorkHistoryListComponent.prototype.completerighttoworkhistory = function (logid, empid) {
        localStorage.setItem('logid', logid);
        // localStorage.SetItem('logid',logid); //localStorage.removeItem('logid');
        this.router.navigate(['/right-to-work-app', empid]);
    };
    return RighToWorkHistoryListComponent;
}());
exports.RighToWorkHistoryListComponent = RighToWorkHistoryListComponent;
