"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var expense_service_1 = require("./../../../services/expense.service");
var modal_service_1 = require("../../../_services/modal.service");
var forms_1 = require("@angular/forms");
var customer_service_1 = require("../../../services/customer.service");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var app_global_1 = require("../../../app.global");
var AdminexpenserequestComponent = /** @class */ (function () {
    function AdminexpenserequestComponent(sanitizer, dataset, formBuilder, modalService, router, customer) {
        this.sanitizer = sanitizer;
        this.dataset = dataset;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.router = router;
        this.customer = customer;
        this.isSelected = false;
        this.monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];
        this.orders = [
            { id: 100, name: 'order 1' },
            { id: 200, name: 'order 2' },
            { id: 300, name: 'order 3' },
            { id: 400, name: 'order 4' }
        ];
        var controls = this.orders.map(function (c) { return new forms_1.FormControl(false); });
        controls[0].setValue(true); // Set the first checkbox to true (checked)
        this.form = this.formBuilder.group({
            orders: new forms_1.FormArray(controls)
        });
    }
    AdminexpenserequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.requestperm = this.permission.m15;
        // this.passformGroup = this.formBuilder.group({
        //   employeeexpenseapplyid: [''],
        // });
        this.hstatus = '';
        this.Userid = localStorage.getItem('userid');
        this.month = (new Date()).getMonth() + 1;
        this.year = (new Date()).getFullYear();
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + (new Date()).getFullYear();
        this.classactive = ((new Date()).getMonth() + 1).toString();
        this.customer.getEmployeeDetailsbyadmin().subscribe(function (data) {
            _this.employeelist = data;
        });
        this.bindalldata();
    };
    AdminexpenserequestComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    AdminexpenserequestComponent.prototype.openModalnote = function (data, id) {
        this.notedetails = data;
        this.modalService.open(id);
    };
    AdminexpenserequestComponent.prototype.onChange = function (newValue) {
        this.employeeid = newValue;
        this.bindalldata();
    };
    AdminexpenserequestComponent.prototype.onChangestatus = function (newValue) {
        this.hstatus = newValue;
        this.bindalldata();
    };
    AdminexpenserequestComponent.prototype.selectAll = function () {
        for (var i = 0; i < this.data.length; i++) {
            this.data[i].selected = this.selectedAll;
        }
    };
    AdminexpenserequestComponent.prototype.checkIfAllSelected = function () {
        this.selectedAll = this.data.every(function (item) {
            return item.selected == true;
        });
    };
    AdminexpenserequestComponent.prototype.submit = function () {
    };
    AdminexpenserequestComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    AdminexpenserequestComponent.prototype.openModal3 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    AdminexpenserequestComponent.prototype.bindalldata = function () {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'month': this.month, 'year': this.year, 'employeeid': this.employeeid, 'hstatus': this.hstatus };
        this.dataset.getemployeeexpensereportrequestbyadmin(data).subscribe(function (data) {
            _this.record = data.length;
            _this.data = data;
            _this.record = _this.data.length;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    AdminexpenserequestComponent.prototype.getDataByMonth = function (mon) {
        this.classactive = mon;
        this.month = mon;
        this.yearmonth = this.monthNames[mon - 1] + ' ' + (new Date()).getFullYear();
        this.bindalldata();
    };
    AdminexpenserequestComponent.prototype.onyearSubmit = function (year) {
        this.year = year;
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + this.year;
        this.bindalldata();
        // this.modalService.close(id);
    };
    AdminexpenserequestComponent.prototype.yearpop = function (id) {
        this.modalService.open(id);
    };
    AdminexpenserequestComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    AdminexpenserequestComponent.prototype.onStatus = function (id, status) {
        var _this = this;
        var deleteData = { 'id': id, 'hstatus': status, 'userid': this.Userid, 'statusdate': new Date() };
        var msg = '';
        if (status == 2) {
            msg = 'Are you sure you want to Approve?';
        }
        else {
            msg = 'Are you sure you want to Reject?';
        }
        if (confirm(msg)) {
            this.dataset.statusexpense(deleteData).subscribe(function (data) {
                _this.bindalldata();
            });
        }
    };
    AdminexpenserequestComponent.prototype.getDataByYear = function (yea) {
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + yea;
        this.bindalldata();
    };
    return AdminexpenserequestComponent;
}());
exports.AdminexpenserequestComponent = AdminexpenserequestComponent;
