"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var director_service_1 = require("../../services/director.service");
var director_model_1 = require("../../models/director/director.model");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var modal_service_1 = require("../../_services/modal.service");
var checksystem_service_1 = require("../../services/checksystem.service");
var aml_model_1 = require("../../models/aml/aml.model");
var country_service_1 = require("../../services/country.service");
var DirectorsearchComponent = /** @class */ (function () {
    function DirectorsearchComponent(_el, formBuilder, route, 
    // private companyadvertService: CompanyadvertService,
    directorService, datepipe, modalService, check, country) {
        var _this = this;
        this._el = _el;
        this.formBuilder = formBuilder;
        this.route = route;
        this.directorService = directorService;
        this.datepipe = datepipe;
        this.modalService = modalService;
        this.check = check;
        this.country = country;
        this.directors = [];
        this.defaultVal = "";
        this.error = 0;
        this.mode = 1;
        this.satisfied_charge_details = [];
        this.outstanding_charge_details = [];
        this.all_charge_details = [];
        this.chargeFlag = 0;
        this.paymentInfoType = 0;
        this.hidefiling = true;
        this.imageDocsCopy = [];
        this.searchloading = 5;
        this.formsubmit = 0;
        this.nameset = { name: '', dob: '' };
        route.params.subscribe(function (params) {
            // console.log(params);
            if (params['id']) {
                console.log('Post Code', params['id']);
                _this.getDataByDirectorhistory(params['id'], 1);
            }
            else {
                _this.mode = 0;
            }
        });
    }
    DirectorsearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.disableBtn = true;
        this.submitted = false;
        var ts = { 'serviceid': 12 };
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            if (remaindata.remiancheck <= 0) {
                _this.lesssore = true;
            }
        });
        //   this.country.getcountrylist().subscribe((country: any) => {
        //     this.countrylist = country;
        // //    this.defaultVal ='GB';
        //   });
        this.directorDetail = new director_model_1.Director();
        this.directorFormgroup = this.formBuilder.group({
            firstname: ['', forms_1.Validators.required],
            dob: [''],
        });
        this.downloadtext6 = 'No Record Found <i class="fa fa-file-pdf-o ml-2"></i>';
        this.downloadtext5 = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
        this.searchhistory();
    };
    DirectorsearchComponent.prototype.searchhistory = function () {
        var _this = this;
        var tt = {};
        this.directorService.getdirectorhistory().subscribe(function (searchRes) {
            _this.downloadtext = new Array(searchRes.length);
            for (var i = 0; i < searchRes.length; i++) {
                _this.downloadtext[i] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            }
            searchRes.forEach(function (element, index) {
                searchRes[index].searchresult = JSON.parse(element.searchresult);
            });
            console.log(searchRes);
            _this.searchresult = searchRes; //searchRes;   
        });
    };
    DirectorsearchComponent.prototype.viewsearchhistory = function (searchcondition) {
        this.sscondition = searchcondition;
        this.getDataByDirectorhistory(searchcondition, 1);
    };
    DirectorsearchComponent.prototype.clearAdverseCreditData = function () {
        this.directorFormgroup.reset({ firstname: '' });
        this.submitted = false;
    };
    Object.defineProperty(DirectorsearchComponent.prototype, "f", {
        get: function () { return this.directorFormgroup.controls; },
        enumerable: true,
        configurable: true
    });
    DirectorsearchComponent.prototype.directorsearch = function () {
        this.mode = 0;
        this.searchhistory();
    };
    DirectorsearchComponent.prototype.onSubmitAdvert = function (advert) {
        var _this = this;
        this.submitted = true;
        if (this.directorFormgroup.invalid) {
            return;
        }
        this.error = 0;
        this.month = 0;
        this.year = 0;
        this.formsubmit = 0;
        // var dd = { 
        //   firstname: advert.firstname,
        //   lastname:advert.lastname, 
        //   dob:advert.dob, 
        //   countries: advert.country 
        // };
        if (advert.dob) {
            console.log('Month', advert.dob);
            var tt = new Date(advert.dob);
            console.log('System master', tt);
            this.month = parseInt(tt.getMonth().toString()) + 1;
            console.log('Month', this.month);
            this.year = parseInt(tt.getFullYear().toString());
            console.log('Year', this.year);
            var sd = new Date(advert.dob);
            advert.dob = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
            this.nameset.dob = advert.dob; // this.month +' '+ this.year;
        }
        this.nameset.name = advert.firstname;
        var dd = {
            firstname: advert.firstname,
            lastname: ''
        };
        if (this.formsubmit == 0) {
            this.formsubmit = 1;
            console.log(dd);
            this.directorService.searchdirector(dd).subscribe(function (directordata) {
                console.log(directordata.data.statusCode);
                console.log(directordata.data.body.items.length);
                _this.formsubmit = 0;
                if (directordata.data.statusCode == 400) {
                    _this.error = 1;
                }
                else {
                    _this.mode = 2;
                    _this.directors = directordata.data.body.items;
                    // if(directordata.data.body.items)
                    // {
                    //   this.mode =2;       
                    //   this.directors = directordata.data.body.items;
                    //   // if(directordata.data.body.items.length ==0)
                    //   // {
                    //   //   this.error =1;
                    //   // }else
                    //   // {
                    //   //     this.mode =2;       
                    //   //     this.directors = directordata.data.body.items;
                    //   // }
                    // }else{
                    //   this.error =0;
                    // }
                }
            });
        }
    };
    DirectorsearchComponent.prototype.getDataByDirectorhistory = function (directorcode, history) {
        var _this = this;
        if (history === void 0) { history = 0; }
        this.peopleId = directorcode;
        localStorage.setItem('peopleId', directorcode);
        console.log('Directory Code', directorcode);
        // console.log(history);
        if (history) {
            this.directorService.getdirectorhistoryview({ "peopleId": directorcode }).subscribe(function (directordata) {
                // console.log(directordata.data);
                _this.bindDirectorData(directordata.data);
                _this.mode = 1;
            });
        }
        else {
            this.directorService.getdirectordata({ "peopleId": directorcode }).subscribe(function (directordata) {
                _this.bindDirectorData(directordata.data);
            });
        }
    };
    DirectorsearchComponent.prototype.downloaddirectordata = function (directorcode, ind) {
        var _this = this;
        this.downloadtext[ind] = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        this.downloadtext5 = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        this.directorService.downloaddirectordata({ "directorcode": directorcode }).subscribe(function (searchRes) {
            _this.downloadtext[ind] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            _this.downloadtext5 = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            window.open(searchRes.response_data.Location, "_blank");
            console.log(searchRes);
        });
    };
    DirectorsearchComponent.prototype.outputnewset = function (d) {
        var listmaster = JSON.parse(d);
        this.searchresultnew = new aml_model_1.adverst();
        this.searchresultnew = listmaster;
    };
    DirectorsearchComponent.prototype.outputnewBussinessset = function (d) {
        var listmaster = JSON.parse(d);
        this.searchresultnew = new aml_model_1.AmlBussiness();
        this.searchresultnew = listmaster;
    };
    DirectorsearchComponent.prototype.getDataByDirectorCode = function (peopleId) {
        var _this = this;
        this.peopleId = peopleId;
        localStorage.setItem('peopleId', peopleId);
        this.sscondition = peopleId;
        var ts = { 'serviceid': 12 };
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            if (remaindata.remiancheck <= 0) {
                _this.modalService.open('upgrade-plan');
            }
            else {
                _this.modalService.open('confirm-modal');
            }
        });
    };
    DirectorsearchComponent.prototype.getDataByDirectorCodesubmit = function (directorcode, history) {
        var _this = this;
        if (history === void 0) { history = 0; }
        localStorage.setItem('peopleId', directorcode);
        this.modalService.close('confirm-modal');
        this.modalService.close('upgrade-plan');
        var ts = { 'serviceid': 12 };
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            if (remaindata.remiancheck <= 0) {
                _this.modalService.open('upgrade-plan');
            }
            else {
                _this.searchloading = 0;
                _this.mode = 1;
                if (history) {
                    _this.directorService.getdirectorhistoryview({ "peopleId": directorcode }).subscribe(function (directordata) {
                        _this.bindDirectorData(directordata.data);
                        _this.searchloading = 5;
                    });
                }
                else {
                    _this.directorService.getdirectordata({ "peopleId": directorcode }).subscribe(function (directordata) {
                        _this.searchloading = 5;
                        _this.bindDirectorData(directordata.data);
                        _this.mode = 1;
                    });
                }
            }
        });
    };
    DirectorsearchComponent.prototype.bindDirectorData = function (directordata) {
        this.director = directordata;
        console.log(directordata);
    };
    // downloadImage(imageId){
    //   if(imageId){
    //     this.companyadvertService.downloadcompanyimage({"imageid": imageId }).subscribe((imagedata: any) => { 
    //       console.log(imagedata);
    //     });
    //   }
    // }
    DirectorsearchComponent.prototype.ngDoCheck = function () {
    };
    DirectorsearchComponent.prototype.changeChargeDetails = function (status) {
        if (status === void 0) { status = 0; }
        if (status == 2) {
            this.company.general_info.charge_details = this.satisfied_charge_details;
            this.chargeFlag = 2;
        }
        else if (status == 1) {
            this.company.general_info.charge_details = this.outstanding_charge_details;
            this.chargeFlag = 1;
        }
        else {
            this.company.general_info.charge_details = this.all_charge_details;
            this.chargeFlag = 0;
        }
    };
    DirectorsearchComponent.prototype.chnagestatus = function (id) {
        var _this = this;
        this.mode = id;
        var ts = { 'serviceid': 12 };
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            if (remaindata.remiancheck <= 0) {
                _this.lesssore = true;
            }
        });
        this.searchhistory();
    };
    DirectorsearchComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    // downloadcompanydata(companycode: any, companyname: any = ''){
    //   this.companyadvertService.downloadcompanyadvertdata({"companycode": companycode, "companyname":companyname }).subscribe((companydata: any) => {
    //     console.log(companydata);
    //   });
    // }
    DirectorsearchComponent.prototype.getCompanyData = function (companyId) {
        var _this = this;
        this.directorService.getcompanydata({ "companylink": companyId }).subscribe(function (companydata) {
            console.log(companydata);
            _this.companydata = companydata.data.body;
            _this.mode = 3;
        });
    };
    DirectorsearchComponent.prototype.norecordfound = function () {
        var _this = this;
        this.downloadtext6 = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        this.directorService.getNorecordcompanydata(this.nameset).subscribe(function (companydata) {
            _this.downloadtext6 = 'No Record Found <i class="fa fa-file-pdf-o ml-2"></i>';
            window.open(companydata.response_data.Location, "_blank");
        });
    };
    return DirectorsearchComponent;
}());
exports.DirectorsearchComponent = DirectorsearchComponent;
