<!-- <style>
  .input-file-container {
      position: relative;
      width: 225px;
    } 
    .js .input-file-trigger {
      display: block;
      padding: 14px 45px;
      background: #39D2B4;
      color: #fff;
      font-size: 1em;
      transition: all .4s;
      cursor: pointer;
    }
    .js .input-file {
      position: absolute;
      top: 0; left: 0;
      width: 225px;
      opacity: 0;
      padding: 14px 0;
      cursor: pointer;
    }
    .js .input-file:hover + .input-file-trigger,
    .js .input-file:focus + .input-file-trigger,
    .js .input-file-trigger:hover,
    .js .input-file-trigger:focus {
      background: #34495E;
      color: #39D2B4;
    }
    
    </style>

     -->
<div class="overviewsshow">
    <div class="header">
        <h1 class="headprofile mt-0">Department</h1>
        <div *ngIf="otherDetail==null">
        <a (click)="openModal2('custom-other-1')" class="addscreensuccess">+ Add Department</a>
    </div>
    </div>
<div *ngIf="otherDetail!=null">
    <div class="header" *ngIf="otherDetail.reportingperson">
        <h4>Reporting Manager: {{otherDetail.reportingperson.fname}} {{otherDetail.reportingperson.mname}} {{otherDetail.reportingperson.lname}}

            <a (click)="openModal('custom-other-1',otherDetail)" class=""><i class="lnr lnr-pencil mg-r-10"></i>&nbsp;</a></h4>
    </div>
    <div class="overview_details emp">
        <div class="profile_dtailsshow">
            <div class="detailsshow width25" *ngIf="otherDetail.location">
                <span>Company Location</span>
                <p> {{otherDetail.location.cityname }}</p>
            </div>
            <div class="detailsshow width15">
                <span>Division</span>
                <p>{{otherDetail.division.typename }}</p>
            </div>
            <div class="detailsshow width20">
                <span>Department</span>
                <p> {{otherDetail.department.typename }}</p>
            </div>
            <div class="detailsshow width20" *ngIf="otherDetail.client">
                <span>Client</span>
                <p>{{ otherDetail.client.companyname }}</p>
            </div>
            <div class="detailsshow width20" *ngIf="otherDetail.project">
                <span>Project</span>
                <p>{{otherDetail.project.projectname }}</p>
            </div>
            <div class="detailsshow width20" *ngIf="otherDetail.isclientcontact">
                <span>Client Contract</span>
                <p *ngIf="otherDetail.isclientcontact==0">No</p>
                <p *ngIf="otherDetail.isclientcontact == 1">Yes</p>
            </div>

        </div>
        
    </div>
    <div *ngIf="terminationDetail!=null">
        <div class="header"  *ngIf="terminationDetail.terminationdate">
            <h4 >Termination Date: {{terminationDetail.terminationdate | date}}</h4>
        </div>
        <div class="overview_details emp">
            <div class="profile_dtailsshow">
                <div class="detailsshow width15">
                    <span>Eligible for rehire</span>
                    <p><span *ngIf="terminationDetail.oktorehire">YES</span><span *ngIf="!terminationDetail.oktorehire">NO</span></p>
                </div>
                <div class="detailsshow width15">
                    <span>Regret Termination</span>
                    <p><span *ngIf="terminationDetail.regrettermination">YES</span><span *ngIf="!terminationDetail.regrettermination">NO</span></p>
                </div>
                <div class="detailsshow width30">
                    <span>Reason</span>
                    <p>{{terminationDetail.terminationreason }}</p>
                </div>
            </div>
        </div>
    </div>
   
</div>
<div class="job_list_section visa_request_section" *ngIf="otherDetail ==null">
    <div class="job_detils_date">
        <p class="job_profile">No Record Found</p>
    </div>
</div> 
</div>


<!-- <div class="row">
    <div class="col-lg-7">
        <h1 class="headprofile">Department</h1>
    </div>
    <div class="col-lg-5 float-right" *ngIf="otherDetail==null">
        <h6 class="text-right float-right buttonprofile" data-toggle="modal" data-target="#passport">
            <a (click)="openModal2('custom-other-1')" class="addscreensuccess">+ Add Department</a></h6>
    </div>
</div> -->



<div class="row row-sm">


    <!-- <div class="col-lg-4" *ngIf="otherDetail ==null">
        <div class="billed-to">
            No Record Found
        </div>
    </div> -->
    <!-- <div class="col-lg-6" *ngIf="otherDetail!=null">
        <div class="billed-to">
            <h6 class="tx-gray-800" *ngIf="otherDetail.reportingperson">Reporting Manager: {{otherDetail.reportingperson.fname}} {{otherDetail.reportingperson.mname}} {{otherDetail.reportingperson.lname}}

                <a (click)="openModal('custom-other-1',otherDetail)" class="lsview"><i class="fa fa-pencil mg-r-10"></i>&nbsp;</a>
            </h6>
            <p *ngIf="otherDetail.location"><b>Company Location:</b> {{otherDetail.location.cityname }}</p>
            <p><b>Division:</b> {{otherDetail.division.typename }}</p>
            <p><b>Department:</b> {{otherDetail.department.typename }}</p>
            <p *ngIf="otherDetail.client"><b>Client:</b> {{ otherDetail.client.companyname }}</p>
            <p *ngIf="otherDetail.project"><b>Project:</b> {{otherDetail.project.projectname }} &nbsp;

              
            </p>
        </div>
    </div> -->
    <!-- <div class="col-lg-6" *ngIf="terminationDetail!=null">
        <div class="billed-to">
            <h6 class="tx-gray-800" *ngIf="terminationDetail.terminationdate">Termination Date: {{terminationDetail.terminationdate | date}}</h6>
            <p><b>Eligible for rehire:</b> <span *ngIf="terminationDetail.oktorehire">YES</span><span *ngIf="!terminationDetail.oktorehire">NO</span></p>
            <p><b>Regret Termination:</b> <span *ngIf="terminationDetail.regrettermination">YES</span><span *ngIf="!terminationDetail.regrettermination">NO</span></p>
            <p><b>Reason:</b> {{terminationDetail.terminationreason }}</p>
        </div>
    </div> -->
</div>

<jw-modal id="custom-other-1" class="modal" role="dialog">
    <form [formGroup]="otherinfoGroup" #otherinfoForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(otherinfoForm.value,'custom-other-1')" *ngIf="otherDetail">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="otherDetail.id">
                    <input type="hidden" formControlName="employeeid" class="form-control" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="otherDetail.employeeid">
                    <h5 class="modal-title" id="popupLabel">Department</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-other-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                            
                                {{errMessage}}
                            </div>
                        </div>
                        <div class="row">

                            <div class="form-group col-lg-6">
                                <label>Location*</label>
                                <select style="width:100%;" formControlName="locationid" name="locationid" id="locationid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.locationid.errors }" [(ngModel)]="otherDetail.locationid">
                                    <option [value]="defaultVal">Select Location</option>
                                    <option *ngFor="let loc of companylocationlist" [value]="loc.companylocationdetailsid">  {{loc.address1 }} - {{loc.cityname }}, {{loc.statename }}</option>
                                </select>
                                <div *ngIf="submitted && f.locationid.errors" class="invalid-feedback">
                                    <div *ngIf="f.locationid.errors.required">Please select location</div>
                                </div>
                            </div>

                            <div class="form-group col-lg-6">
                                <label>Division*</label>
                                <select style="width:100%;" formControlName="divisionid" name="divisionid" id="divisionid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.divisionid.errors }" [(ngModel)]="otherDetail.divisionid">
                                    <option [value]="defaultVal">Select Division</option>
                                    <option *ngFor="let divi of divisionlist" [value]="divi.id" >{{ divi.typename }}</option>
                                </select>
                                <div *ngIf="submitted && f.divisionid.errors" class="invalid-feedback">
                                    <div *ngIf="f.divisionid.errors.required">Please select division</div>
                                </div>
                            </div>

                        </div>

                        <div class="row">

                            <div class="form-group col-lg-6">
                                <label>Report To*</label>
                                <select style="width:100%;" formControlName="reportto" name="reportto" id="reportto" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.reportto.errors }" [(ngModel)]="otherDetail.reportto">
                                    <option [value]="defaultVal">Select Location</option>
                                    <option *ngFor="let wemp of wemployeelist" [value]="wemp.employeemasterid">
                                    {{ wemp.fname }} {{ wemp.mname }} {{ wemp.lname }}</option>
                                </select>
                                <div *ngIf="submitted && f.reportto.errors" class="invalid-feedback">
                                    <div *ngIf="f.reportto.errors.required">Please select an option</div>
                                </div>
                            </div>

                            <div class="form-group col-lg-6">
                                <label>Department*</label>
                                <select style="width:100%;" formControlName="departmentid" name="departmentid" id="departmentid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.departmentid.errors }" [(ngModel)]="otherDetail.departmentid">
                                    <option [value]="defaultVal">Select Department</option>
                                    <option *ngFor="let dep of departmentlist" [value]="dep.id">{{ dep.typename }}</option>
                                </select>
                                <div *ngIf="submitted && f.departmentid.errors" class="invalid-feedback">
                                    <div *ngIf="f.departmentid.errors.required">Please select department</div>
                                </div>
                            </div>

                        </div>

                        <div class="row">

                            <div class="form-group col-lg-6">
                                <label>Client</label>
                                <select style="width:100%;" formControlName="clientid" name="clientid" id="clientid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.clientid.errors }" [(ngModel)]="otherDetail.clientid" (ngModelChange)="getProjectList($event)">
                                    <option [value]="defaultVal">Select Client</option>
                                    <option *ngFor="let cli of clientlist" [value]="cli.clientid">{{ cli.companyname }} ({{ cli.clientname }})</option>
                                </select>
                                <div *ngIf="submitted && f.clientid.errors" class="invalid-feedback">
                                    <div *ngIf="f.clientid.errors.required">Please select client</div>
                                </div>
                            </div>

                            <div class="form-group col-lg-6">
                                <label>Project</label>
                                <select style="width:100%;" formControlName="projectid" name="projectid" id="projectid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.projectid.errors }" [(ngModel)]="otherDetail.projectid">
                                    <option [value]="defaultVal">Select Project</option>
                                    <option *ngFor="let proj of projectlist" [value]="proj.projectid">{{ proj.projectname }}</option>
                                </select>
                                <div *ngIf="submitted && f.projectid.errors" class="invalid-feedback">
                                    <div *ngIf="f.projectid.errors.required">Please select project</div>
                                </div>
                            </div>

                        </div>

                        <div class="row">

                            <div class="form-group col-lg-6">
                                <label>Client contract*</label>
                                <select style="width:100%;" formControlName="isclientcontact" name="isclientcontact" id="isclientcontact" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.isclientcontact.errors }" [(ngModel)]="otherDetail.isclientcontact">
                                    <option [value]="defaultVal">Select Client contract</option>
                                    <option [value]="0">No</option>
                                    <option [value]="1">Yes</option>
                                </select>
                                <div *ngIf="submitted && f.isclientcontact.errors" class="invalid-feedback">
                                    <div *ngIf="f.isclientcontact.errors.required">Please select client contract</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-other-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>