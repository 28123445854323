import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { CommonService } from '../../services/common.service';
import { CountryService } from '../../services/country.service';
import { CompanyService } from '../../services/company.service';
import { Router } from '@angular/router';
import { ClientService } from '../../services/client.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Jobs } from '../../models/jobs/jobs.model';
import { MatOptionSelectionChange } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFileService } from './../../services/upload-file.service';
import { Country } from './../../models/address/country.model';
import { Client } from "./../../models/client/client.model";
import { Companytype } from "./../../models/company/companytype.model";

import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  
  items: Array<any>;
  pageOfItems: Array<any>;

  Userid: any;
  joblist: any;
  datalist: any;
  clientDetail = new Client();
  clientformGroup: FormGroup;
  public Editor = ClassicEditor;
  mobilecheck: any;
  phonecheck: any;
  socdata: any;
  countrylist: any;
  countylist: any;
  options = [];
  buttoninsert: any;
  submitted: boolean = false;
  selectedFiles: FileList;
  filename: any;
  file1: any;
  file2: any;
  file3: any;
  file4: any;
  screenumber: any;  
  statelist: any[];
  companytype: any;
  public searchText: string;
  disableBtn: any;
  permission: any;
  clientperm: any;
  emptyValue: any = "";
  FormClient: any;
  record: any;

  constructor(private sanitizer: DomSanitizer, 
    private commonService: CommonService, 
    private modalService: ModalService, 
    private client: ClientService, 
    private formBuilder: FormBuilder,
    private country: CountryService,
    private alerts: AlertService,
    private router: Router,
    private uploadService: UploadFileService,
    private companyService: CompanyService
    ) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }


    this.Userid = localStorage.getItem('userid');
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.clientperm = this.permission.m3;
    // console.log(this.clientperm);
    this.clientDetail = new Client();
    this.FormClient = new Client();
    this.clientDetail.country = new Country();
    this.clientDetail.companytype = new Companytype();
    
    this.clientformGroup = this.formBuilder.group({
      id: [''], 
      clientid: [''],        
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      secondryemailid: ['', Validators.compose([Validators.email])],
      phonenumber: ['', Validators.compose([Validators.required])],
      sphonenumber: [''],
      pincode: ['', Validators.required],
      clientname: ['', Validators.required],
      clietdesignation: ['', Validators.required],
      websiteurl: [''],
      address1: ['', Validators.required],
      address2: [''],
      cityname: ['', Validators.required],
      statename: ['', Validators.required],
      countryid: ['', Validators.required],     
      companyname: ['', Validators.required],
      companytypeid: ['', Validators.required]
    });

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
      // this.clientDetail.countryid = "70";
    });

    this.companyService.getcompanyType().subscribe((state: any) => {        
      this.companytype = state;
    });
    const sstatustype = { 'status': false, 'id': this.Userid } 
    this.client.getclientlist(sstatustype).subscribe((data: any) => {
      this.record = data.length;
      this.datalist = data;
      // this.record = data.length;

      if(this.record < 30){
        this.pageOfItems = data;
      }
      else{
           this.items =data;
      }

      // this.data = data;
      // this.clientDetail.countryid = "70";
    });

  }

  get f() { return this.clientformGroup.controls; }

  onSubmit(formclient: any, id: string)
  {
    this.submitted = true;
    // var formclient = clientformdata.value;
    if (this.clientformGroup.invalid) 
    {      
      return;
    }
    this.disableBtn = false;
    formclient.userid = this.Userid;
    this.client.clientdetailsUpdate(formclient).subscribe((response: any) => {
      const sstatustype = { 'status': false, 'id': this.Userid } 
      this.client.getclientlist(sstatustype).subscribe((data: any) => {
        // clientformdata.reset();
        this.record = data.length;
        this.datalist = data;
        if(this.record < 30){
          this.pageOfItems = data;
        }
        else{
             this.items =data;
        }
     
        // this.data = data;
        // this.clientDetail.countryid = "70";
        this.alerts.success('Client updated successfully',true);
        this.modalService.close(id);
      });
    });
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  onSearchChange(searchValue) {
    if (searchValue.length > 4) {
      var newArray = [];
      this.commonService.getAddress(searchValue).subscribe((data: any) => {
        var newdata: any;
        newdata = data;
        newdata.addresses.forEach(function (value) {
          newArray.push(value + "," + searchValue);
        });
        this.options = newArray;
      });
    }
    else {
      this.options = [];
    }
  }

  displayFn(value: any) {
    if (value != null) {
      var res = value.split(",");
      if (res.length > 6) {
        return res[7];
      }
      else {
        return value;
      }
    }
  }

  updatecode(evt: MatOptionSelectionChange, response) {
    res = [];
    if (evt.source.selected) {
      var res = response.split(",");
      this.FormClient.address1 = res[0];
      this.FormClient.address2 = res[1];
      this.FormClient.cityname = res[5];     
      this.FormClient.pincode = res[7];
      this.FormClient.statename =res[6];
    }
  }


  transform(html) {
    return this.sanitizer.bypassSecurityTrustStyle(html);
  }

  selectFile(event,field) {
    var files = event.target.files;
    var selectedFiles = files[0];
    this.selectedFiles = selectedFiles;
    const file = this.selectedFiles;
    var imagepath = this.uploadService.uploadfile(file);
  }

  onChangeStatus(evt: any, client: any)
  {
    var status = '';
    if(evt.target.checked)
    {
      status = '1';
    }
    else if(!evt.target.checked)
    {
      status = '0';
    }
    this.client.clientdetailsUpdatestatusbyadmin({'userid': this.Userid, 'id': client.clientid, 'cstatus': status}).subscribe((data: any) => {

    });
  }

  openModal(id: string, data: any) 
  { 
    this.buttoninsert = "Update";
    this.disableBtn = true;
    this.FormClient = new Client();
    data.id = data.clientid;
    this.FormClient = data; 
    this.clientformGroup.reset(this.FormClient); 
    this.clientDetail = data; 
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.modalService.open(id);
  }

  openModal2(id: string) 
  { 
    this.submitted = false;
    this.buttoninsert = "Submit";
    this.disableBtn = true;    
    this.FormClient = new Client();
    this.FormClient.countryid = "70";
    this.FormClient.clientid = "0";
    // this.clientformGroup.get('companyname').setValue('');
    // this.clientformGroup.get('companytypeid').setValue('');
    // this.clientformGroup.get('websiteurl').setValue('');
    // this.clientformGroup.get('clientname').setValue('');
    // this.clientformGroup.get('clietdesignation').setValue('');
    // this.clientformGroup.get('emailid').setValue('');
    this.mobilecheck='';
    this.phonecheck='';
    this.clientformGroup.reset(this.FormClient);

    this.modalService.open(id);
  }

  openModal3(jobid: string, id: string) 
  {  
    this.disableBtn = true;
    this.modalService.open(id);
  }

  openModal4(jobid: string, id: string, screenumber: number) 
  { 
    this.buttoninsert = "Submit"; 
    this.disableBtn = true; 
    this.screenumber = screenumber;  
    this.modalService.open(id);
  }

  closeModal(id: string) 
  { 
    this.modalService.close(id);
  }

}
