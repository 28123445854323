"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Expensetype = /** @class */ (function () {
    function Expensetype() {
    }
    return Expensetype;
}());
exports.Expensetype = Expensetype;
var Hourtype = /** @class */ (function () {
    function Hourtype() {
    }
    return Hourtype;
}());
exports.Hourtype = Hourtype;
var Holidaytype = /** @class */ (function () {
    function Holidaytype() {
    }
    return Holidaytype;
}());
exports.Holidaytype = Holidaytype;
