"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var user_service_1 = require("../../services/user.service");
var app_global_1 = require("../../app.global");
var user_model_1 = require("../../models/user.model");
var forms_1 = require("@angular/forms");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var customer_service_1 = require("../../services/customer.service");
var profile_model_1 = require("../../models/overview/profile.model");
var alert_service_1 = require("../../services/alert.service");
var UpdatepasswordComponent = /** @class */ (function () {
    // loading: boolean;
    function UpdatepasswordComponent(userService, data, router, fb, alerts) {
        this.userService = userService;
        this.data = data;
        this.router = router;
        this.fb = fb;
        this.alerts = alerts;
        this.user = new user_model_1.User();
        this.isLoginError = false;
        this.msg = null;
        this.overviewData = new profile_model_1.Profile();
        this.passwordForm = this.fb.group({
            oldPassword: [null, [forms_1.Validators.required, forms_1.Validators.minLength(6)]],
            newPassword: [null, [forms_1.Validators.required, forms_1.Validators.minLength(8), forms_1.Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
            confirmPassword: [null, [forms_1.Validators.required, forms_1.Validators.minLength(8), forms_1.Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]]
        }, { validator: this.checkPasswords });
    }
    UpdatepasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.submitted = false;
        this.disableBtn = true;
        this.data.getOverview().subscribe(function (data) {
            _this.overviewData = data;
        });
    };
    UpdatepasswordComponent.prototype.checkPasswords = function (group) {
        var pass = group.controls.newPassword.value;
        var confirmPass = group.controls.confirmPassword.value;
        return pass === confirmPass ? null : { notSame: true };
    };
    Object.defineProperty(UpdatepasswordComponent.prototype, "f", {
        get: function () { return this.passwordForm.controls; },
        enumerable: true,
        configurable: true
    });
    UpdatepasswordComponent.prototype.Reset = function () {
        var _this = this;
        this.submitted = true;
        if (this.passwordForm.invalid) {
            return;
        }
        this.disableBtn = false;
        if (this.passwordForm.valid && this.passwordForm.controls['newPassword'].value == this.passwordForm.controls['confirmPassword'].value) {
            var resetapi = app_global_1.AppGlobals.API_ENDPOINT + '/api/Users/change-password';
            this.userService.resetPassword(resetapi, this.user).subscribe(function (data2) {
                _this.data.updatepassword().subscribe(function (data3) {
                    _this.alerts.success('Password changed successfully', true);
                    _this.error = false;
                    _this.router.navigate(['/dashboard']);
                    _this.msg = 'success';
                });
            }, function (err) {
                console.log(err);
                _this.alerts.error("Please enter correct current password", true);
                _this.error = false;
                _this.isLoginError = true;
                _this.disableBtn = true;
            });
        }
        else {
            this.error = false;
            this.disableBtn = true;
        }
    };
    return UpdatepasswordComponent;
}());
exports.UpdatepasswordComponent = UpdatepasswordComponent;
