export class Asset {
    id: string;
    employeeid: string = "";
    categoryid: string = "";
    assetdescption: string;
    serialnumber: string;
    statdate: Date = new Date();
    enddate: any;
    categoryname: string;
    category: Assetcategory
 }

 export class Assetcategory {
     id: number;
    categoryname: string;
 }
 