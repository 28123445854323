import { Component, OnInit } from '@angular/core';
import { MypassportService } from '../services/mypassport.service';
import { ModalService } from '../_services/modal.service';
import { UploadFileService } from './../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Passport } from '../models/passport/passport.model';
import { CountryService } from '../services/country.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-mypassport',
  templateUrl: './mypassport.component.html',
  styleUrls: ['./mypassport.component.css']
})

export class MypassportComponent implements OnInit {
  form: FormGroup;
  passformGroup: FormGroup; 
  passportprofilelist: any[];
  statuslist: any;
  passportDetail: any;//= new Passport();  
  countrylist: any[];
  Userid: any;
  listcheck: any;
  myfile: any;
  file: any;
  imgpassportbacksrc:any;
  iframepassportbacksrc:any;
  selectedFiles: FileList;
  passnumbererror: any;
  nationalityerror: any;
  passportdateoriginerror: any;
  iframepasssrc: any;
  imgpassportsrc: any;
  buttoninsert: any;
  filesizeerror: any;
  filerequirederror: any;
  fileformaterror: any;
  formattedMessage: any;
  messageSuccess:any;
  filename: any;
  defaultVal: any = "";
  passcount: any =0;
  filebackrequirederror: any;
  filebacksizeerror: any;
  filebackformaterror: any;
  selectedbackFiles: FileList;
  filebackname: any;
  imagefirst: any;
  imagesecond: any;
  filterItems = [
    {
      name: 'All',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Pending Approval',
      id: 1,
      selected: false
    }
  ]
  submitted: boolean;

  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private data: MypassportService, 
    private country: CountryService, 
    private modalService: ModalService,
    private alerts: AlertService
  ) { }

  ngOnInit() {
    this.filerequirederror = false;
    this.filebackrequirederror = false;
    this.passcount =0;
    this.createformgroup();
    this.onChanges();
    this.imagefirst ='';
    this.imagesecond ='';
	  // this.imgpasssrc =false;
    this.filename = 'Choose file';
    this.filebackname = 'Choose file';
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    this.data.getpassportListnew(data).subscribe((data: any) => {

      if(data == null)
        this.passcount =0;
      else
      this.passcount =data.length
      this.passportprofilelist = data;
    });

    this.listcheck = this.filterItems;
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.passportDetail = new Passport();
    this.passportDetail.sstatus = 0;
  }

  createformgroup()
  {
    this.passformGroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      passportnumber: ['', Validators.required],
      placeofissue: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      passportissue: ['', Validators.required],
      passportexpiry: ['', Validators.required],
      iscurrent: [''],
    });
  }

  onChangeDate(event: any)
  {
    // this.getData(newDate);
  }
  
  onChanges(): void {
    this.formattedMessage = '';
    this.passformGroup.valueChanges.subscribe(val => {
      this.formattedMessage = 'Hello, My name is and my email is. I would like to tell you that .';
    });
  }

  onlistchange(type1: any, idval: number) {
    var sstatus: any;
    for (let c of this.filterItems) {
      if (c.id == idval)
        c.selected = true;
      else
        c.selected = false;
    }
    if (idval == 0 || type1 == false) {
      sstatus = [1, 2, 3];
    }
    else {
      sstatus = [idval];
    }
    var userid = localStorage.getItem('userid');
    const data = { 'id': userid, 'cstatus': sstatus };
    this.data.getpassportListnew(data).subscribe((data: any) => {
      this.passportprofilelist = data;
    });
  }

  get f() { return this.passformGroup.controls; }

  onSubmit(value3: any, id: string) {
    console.log(value3);
    // this.data.mypassportdetailsUpdatefile(value3).subscribe((data: any) => {
    //   console.log(data);
    // });
    this.submitted = true;
    if (this.passformGroup.invalid) {
      if(value3.id == '0')
      {
        if(!this.selectedFiles)
          this.filerequirederror = true;
        // if(!this.selectedbackFiles)
        //   this.filebackrequirederror = true;
      }
      return;
    }

    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
        this.fileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.imagename = imagepath;
    }
    else {
      if(value3.id == '0'){
        this.filerequirederror =true;
        return;
      }
      value3.imagename = this.passportDetail.passportdocname;
    }

    if (this.selectedbackFiles) {
      this.file = this.selectedbackFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filebacksizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
        this.filebackformaterror = true;
        return;
      }
      var imagebackpath =  this.uploadService.uploadfile(this.file);
      value3.imagebackname = imagebackpath;
    }
    else {
      // if(value3.id == '0'){
      //   this.filebackrequirederror =true;
      //   return;
      // }
      if(value3.id == '0')
        {
         value3.imagebackname = '';
         }
       else {
        value3.imagebackname = this.passportDetail.passportdocname;
       }
     
    }
    var error = 0;
    if (value3.passportnumber == '') {
      this.passnumbererror = false;
      error++;
    }
    if (value3.nationalityid == '') {
      this.nationalityerror = false;
      error++;
    }
    if (value3.passportissue == null) {
      this.passportdateoriginerror = false;
      error++;
    }
    if (error > 0) {
      return false;
    }

    
    const sd = new Date(value3.passportissue);
    value3.passportissue = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    const ed = new Date(value3.passportexpiry);
    value3.passportexpiry = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());



    this.data.mypassportdetailsUpdate(value3).subscribe((data: any) => {
      var sstatus: any;
      for (let c of this.filterItems) {
        if (c.selected == true) {
          if (c.id == 0) {
            sstatus = [1, 2, 3];
          }
          else {
            sstatus = [c.id];
          }
        }
      }
      var userid = localStorage.getItem('userid');
      const data1 = { 'id': userid, 'cstatus': sstatus };
      this.data.getpassportListnew(data1).subscribe((data: any) => {
        return this.passportprofilelist = data;
      });
    });
    //this.alerts.setDefaults('timeout',0);
    this.messageSuccess = 'Passport updated successfully'; 
    this.alerts.success(this.messageSuccess, false);
    this.filename = "Choose file";
    this.modalService.close(id);
  }

  selectFile(event)
   {
    this.filesizeerror = false;  
    this.fileformaterror = false;
    this.filerequirederror =false;
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
      this.fileformaterror = true;    
    }else{
      this.selectedFiles = files[0];
    }
  }

  selectbackFile(event)
   {
    this.filebackrequirederror = false;
    this.filebacksizeerror = false;  
    this.filebackformaterror = false;
    var files = event.target.files;
    this.filebackname = files[0].name;
    this.selectedbackFiles = this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filebacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx"))  {
      this.filebackformaterror = true;
    }else{
      this.selectedbackFiles = files[0];
    }
  }

  onDelete(empid: number) {
    if (confirm("Are you sure you want to delete?")) {
      const data1 = { 'id': empid, 'userid': this.Userid };
      this.data.deletePassport(data1).subscribe((data: any) => {
        var sstatus: any;
        for (let c of this.filterItems) {
          if (c.selected == true) {
            if (c.id == 0) {
              sstatus = [1, 2, 3];
            }
            else {
              sstatus = [c.id];
            }
          }
        }
        var userid = localStorage.getItem('userid');
        const data1 = { 'id': userid, 'cstatus': sstatus };
        this.data.getpassportListnew(data1).subscribe((data: any) => {
          return this.passportprofilelist = data;
        });
      })
    }
  }

  openModal2(id: string) {
    this.filesizeerror = false;  
    this.fileformaterror = false;
    this.filerequirederror =false;
    this.filebackrequirederror = false;
    this.filebacksizeerror = false;  
    this.filebackformaterror = false;
    this.submitted = false;
    this.passportDetail = new Passport();
    this.buttoninsert = "Submit";
    this.selectedFiles = null;
    this.imagefirst ='';
    this.imagesecond ='';
    this.selectedbackFiles =null;
    this.passportDetail.iscurrent = "0"; 
    this.filebackname = "Choose file";
    this.filename = "Choose file";
    this.Userid = localStorage.getItem('userid');
    this.passportDetail.employeeid = this.Userid;
    this.passportDetail.nationalityid = "70";
    this.passportDetail.employeepassdeatilid = "0";
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.filesizeerror = false;  
    this.fileformaterror = false;
    this.filerequirederror =false;
    this.filebackrequirederror = false;
    this.filebacksizeerror = false;  
    this.filebackformaterror = false;
    this.submitted = false;
    this.imagefirst ='';
    this.imagesecond ='';
    this.selectedFiles = null;
    this.selectedbackFiles =null;
    this.passportDetail = new Passport();
    this.filebackname = "Choose file";
    this.filename = "Choose file";
    this.buttoninsert = "Update";
    this.passportDetail = data;
    if(data.iscurrent){
      this.passportDetail.iscurrent = "1";
    } else{
      this.passportDetail.iscurrent = "0";
    }
    this.modalService.open(id);
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModalpassportopen3(id: string, data: any,  backimg: any) {
    this.iframepasssrc ='';    
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];    
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgpassportsrc = true;
      this.iframepasssrc = data;
    }
    else {
      this.imgpassportsrc = false;
      this.iframepasssrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    if(backimg)
    {
      var fullpathArray = backimg.split('.');
      var typeoffile = fullpathArray[fullpathArray.length - 1];
      if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
        this.imgpassportbacksrc = true;
        this.iframepassportbacksrc = backimg;
      }
      else {
        this.imgpassportbacksrc = false;
        this.iframepassportbacksrc = this.getSafeUrl("https://docs.google.com/gview?url=" + backimg + "&embedded=true");
      }
    }
    else {
      this.imgpassportbacksrc = false;
      this.iframepassportbacksrc = '';
    }
    this.modalService.open(id);
  }

  closeModal(id: string) {
    // this.passportDetail = new Passport();
    const data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    this.data.getpassportListnew(data).subscribe((data: any) => {
      if(data == null)
      this.passcount = 0;
    else
      this.passcount = data.length
      this.passportprofilelist = data;
    });
    this.modalService.close(id);
  }

  modalOpen(data: any) {
    this.passportDetail = new Passport();
    this.passportDetail = data;
  }
}