"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var job_service_1 = require("../../../../services/job.service");
var candidate_service_1 = require("../../../../services/candidate.service");
var jobs_model_1 = require("../../../../models/jobs/jobs.model");
var candidate_model_1 = require("../../../../models/jobs/candidate.model");
var platform_browser_1 = require("@angular/platform-browser");
var modal_service_1 = require("../../../../_services/modal.service");
var applicant_service_1 = require("../../../../services/applicant.service");
var forms_1 = require("@angular/forms");
var emailer_model_1 = require("../../../../models/emailer.model");
var alert_service_1 = require("../../../../services/alert.service");
var upload_file_service_1 = require("./../../../../services/upload-file.service");
var common_service_1 = require("../../../../services/common.service");
var ClassicEditor = require("@ckeditor/ckeditor5-build-classic");
var router_2 = require("@angular/router");
var CandidatedetailsComponent = /** @class */ (function () {
    function CandidatedetailsComponent(sanitizer, job, alerts, applicantservices, candidate, activatedRoute, formBuilder, uploadService, modalService, commonService, router, route) {
        var _this = this;
        this.sanitizer = sanitizer;
        this.job = job;
        this.alerts = alerts;
        this.applicantservices = applicantservices;
        this.candidate = candidate;
        this.activatedRoute = activatedRoute;
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.modalService = modalService;
        this.commonService = commonService;
        this.router = router;
        this.route = route;
        this.tempArr = { "skills": [] };
        this.jobdata = new jobs_model_1.Jobs();
        this.candidateDetail = new candidate_model_1.Candidate();
        this.defaultVal = "";
        this.emailerDetail = new emailer_model_1.Emailer();
        this.documentDetail = new candidate_model_1.Document();
        this.Editor = ClassicEditor;
        this.statusname = '';
        this.totalpr = 0;
        this.totalcandidate = 0;
        this.totalreview = 0;
        route.params.subscribe(function (params) {
            _this.jobdata = new jobs_model_1.Jobs();
            _this.jobid = params['jobid'];
            if (!isNaN(Number(params['candidateid']))) {
                _this.candidateid = params['candidateid'];
            }
            else {
                _this.router.navigate(['/404']);
            }
            _this.Userid = localStorage.getItem('userid');
            _this.Pageloadtimesetup();
        });
    }
    CandidatedetailsComponent.prototype.ngOnInit = function () {
        this.ismail = false;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.getcandidateformgroup();
        this.candidatemessageFormGroup = this.formBuilder.group({
            candidateid: [''],
            jobid: [''],
            location: [''],
            subject: ['', forms_1.Validators.required],
            message: [''],
            interviewstime: [''],
            interviewetime: [''],
            interdate: [''],
            ismail: [''],
            ssid: ['']
        });
    };
    CandidatedetailsComponent.prototype.Pageloadtimesetup = function () {
        var _this = this;
        var cand = this.candidateid;
        var indexlist;
        this.applicantservices.getcandidatestatus().subscribe(function (candidatestatuslistget) {
            _this.candidatestatuslist = candidatestatuslistget;
        });
        var totalreview = 0;
        var totalcandidate = 0;
        var totalpr = 0;
        this.job.getjobById({ "id": this.Userid, "jobid": this.jobid }).subscribe(function (jobdata) {
            _this.jobdata = jobdata;
            jobdata.candidate.forEach(function (job, index) {
                if (job.candidatemasterid == cand) {
                    indexlist = index;
                }
                if (job.cstatus > 0) {
                    totalreview = totalreview + 1;
                }
            });
            _this.totalcandidate = jobdata.candidate.length;
            _this.totalreview = totalreview;
            _this.totalpr = 100 * (Number(totalreview) / Number(_this.totalcandidate));
            _this.totalpr = _this.totalpr.toFixed(0);
            if (indexlist == 0 && jobdata.candidate.length > 1) {
                _this.prvbutton = 0;
                _this.nextbutton = jobdata.candidate[indexlist + 1].candidatemasterid;
            }
            else if (indexlist == (jobdata.candidate.length - 1) && jobdata.candidate.length > 1) {
                _this.prvbutton = jobdata.candidate[indexlist - 1].candidatemasterid;
                _this.nextbutton = 0;
            }
            else {
                _this.prvbutton = jobdata.candidate[indexlist - 1].candidatemasterid;
                _this.nextbutton = jobdata.candidate[indexlist + 1].candidatemasterid;
            }
            // console.log(this.prvbutton +' rahul kumar tanwar '+ this.nextbutton);
        });
        this.totalpr = totalpr;
        this.totalreview = totalreview;
        this.totalcandidate = totalcandidate;
        this.job.getjobskillList({ "id": this.Userid, "jobid": this.jobid }).subscribe(function (data) {
            var dataskillArray = [];
            data.forEach(function (skil) {
                dataskillArray.push({ 'jobskilldetailid': skil.jobskilldetailid, 'skillname': skil.skillname, 'check': false });
            });
            _this.skilllist = dataskillArray;
            _this.showSkills(_this.candidateid);
        });
        this.candidate.getcandidateCommunicationHistory({ "id": this.Userid, "candidateid": this.candidateid }).subscribe(function (data) {
            _this.communicationHistory = data;
        });
    };
    CandidatedetailsComponent.prototype.perstangeofdiv = function () {
        return this.totalpr;
    };
    CandidatedetailsComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    CandidatedetailsComponent.prototype.selectFile = function (event) {
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") && (filetype[filetype.length - 1].toLowerCase() != "gif") && (filetype[filetype.length - 1].toLowerCase() != "tif") && (filetype[filetype.length - 1].toLowerCase() != "pdf") && (filetype[filetype.length - 1].toLowerCase() != "doc") && (filetype[filetype.length - 1].toLowerCase() != "docx") && (filetype[filetype.length - 1].toLowerCase() != "msword")) {
            this.fileformaterror = true;
        }
        else {
            this.fileformaterror = false;
        }
    };
    CandidatedetailsComponent.prototype.getcandidateformgroup = function () {
        this.candidateformGroup = this.formBuilder.group({
            candidatemasterid: [''],
            jobid: [''],
            fname: ['', forms_1.Validators.required],
            mname: [''],
            lname: ['', forms_1.Validators.required],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            phonenumber: [''],
            joburlid: ['']
        });
    };
    Object.defineProperty(CandidatedetailsComponent.prototype, "f", {
        get: function () { return this.candidateformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    CandidatedetailsComponent.prototype.showpage = function (data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1].toLowerCase();
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgresumesrc = true;
            this.iframeresumesrc = data;
        }
        else {
            this.imgresumesrc = false;
            this.iframeresumesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
    };
    Object.defineProperty(CandidatedetailsComponent.prototype, "g", {
        get: function () { return this.candidatemessageFormGroup.controls; },
        enumerable: true,
        configurable: true
    });
    CandidatedetailsComponent.prototype.onChangeinterested = function (sid, candidateid) {
        var _this = this;
        var data = { "sid": sid, "candidateid": candidateid };
        this.candidate.candidatestatusinterested(data).subscribe(function (data) {
            _this.candidate.getcandidateById({ "id": _this.Userid, "candidateid": _this.candidateid }).subscribe(function (data) {
                _this.candidateDetail = data;
            });
        });
    };
    CandidatedetailsComponent.prototype.skillupdate = function (jobcandidateskillid, check) {
        var _this = this;
        if (check == true) {
            var data5 = { 'candidateid': this.candidateid, 'jobskilldetailid': jobcandidateskillid, 'jobid': this.jobid };
            this.candidate.candidateskilladd(data5).subscribe(function (data3) {
                _this.smarks = data3.smarks;
                _this.showSkills(_this.candidateid);
                // this.job.getjobskillList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
                //   var dataskillArray = [];
                //   data.forEach(skil => {
                //     dataskillArray.push({ 'jobskilldetailid': skil.jobskilldetailid, 'skillname': skil.skillname, 'check': false });
                //   })
                //   this.skilllist = dataskillArray;
                //   this.showSkills(this.candidateid);
                // });
            });
        }
        else if (check == false) {
            var data6 = { 'candidateid': this.candidateid, 'jobskilldetailid': jobcandidateskillid, 'jobid': this.jobid };
            this.candidate.candidateskilldelete(data6).subscribe(function (data2) {
                _this.smarks = data2.smarks;
                _this.showSkills(_this.candidateid);
                // this.job.getjobskillList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
                //   var dataskillArray = [];
                //   data.forEach(skil => {
                //     dataskillArray.push({ 'jobskilldetailid': skil.jobskilldetailid, 'skillname': skil.skillname, 'check': false });
                //   })
                //   this.skilllist = dataskillArray;
                //   this.showSkills(this.candidateid);
                // });
            });
        }
    };
    CandidatedetailsComponent.prototype.showSkills = function (candidateid) {
        var _this = this;
        this.tempArr = { "skills": [] };
        this.candidate.getcandidateById({ "id": this.Userid, "candidateid": candidateid }).subscribe(function (data) {
            _this.smarks = data.smarks;
            var candskills = [];
            data.skill.forEach(function (ele) {
                candskills.push(ele.jobskilldetailid);
            });
            var allskill = _this.skilllist;
            var newSkillArray = [];
            allskill.forEach(function (element) {
                if (candskills.includes(element.jobskilldetailid.toString()) == false) {
                    newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': false });
                }
                else {
                    _this.tempArr.skills.push(element.jobskilldetailid);
                    newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': true });
                }
            });
            _this.skilllist = newSkillArray;
            _this.candidateDetail = data;
            console.log(data);
            _this.statusname = data.status.statusname;
            if (data.cvname) {
                _this.showpage(data.cvname);
            }
        });
    };
    CandidatedetailsComponent.prototype.openCandidateModal = function (candidateid, modelid) {
        var _this = this;
        this.disableBtn = true;
        this.tempArr = { "skills": [] };
        this.candidate.getcandidateById({ "id": this.Userid, "candidateid": candidateid }).subscribe(function (data) {
            var candskills = [];
            data.skill.forEach(function (ele) {
                candskills.push(ele.jobskilldetailid);
            });
            var allskill = _this.skilllist;
            var newSkillArray = [];
            allskill.forEach(function (element) {
                if (candskills.includes(element.jobskilldetailid.toString()) == false) {
                    newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': false });
                }
                else {
                    _this.tempArr.skills.push(element.jobskilldetailid);
                    newSkillArray.push({ 'jobskilldetailid': element.jobskilldetailid, 'skillname': element.skillname, 'check': true });
                }
            });
            _this.skilllist = newSkillArray;
            _this.candidateDetail = data;
        });
        this.buttoninsert = "Update";
        this.modalService.open(modelid);
    };
    CandidatedetailsComponent.prototype.openModal6 = function (id, sid, candidateid, jobid) {
        var _this = this;
        this.buttoninsert = "Change Status";
        this.disableBtn = true;
        this.applicantservices.getemailertemplate({ "candidateid": candidateid, "jobid": jobid, "sid": sid }).subscribe(function (edata) {
            _this.emailerDetail = edata;
            _this.emailerDetail.candidateid = candidateid;
            _this.emailerDetail.jobid = jobid;
            _this.emailerDetail.location = "";
            _this.emailerDetail.interdate = "";
            _this.emailerDetail.interviewetime = "";
            _this.emailerDetail.interviewstime = "";
            _this.modalService.open(id);
        });
    };
    CandidatedetailsComponent.prototype.openModal5 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    CandidatedetailsComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    CandidatedetailsComponent.prototype.closeModal = function (id) {
        this.showSkills(this.candidateid);
        this.modalService.close(id);
    };
    CandidatedetailsComponent.prototype.statusChange = function (candidatestatusmaster, candidate) {
        var _this = this;
        if (confirm("Are you sure you want to change status?")) {
            this.candidate.candidatestatusUpdateOnly({ "candidateid": candidate.candidatemasterid, "sid": candidatestatusmaster }).subscribe(function (data) {
                _this.showSkills(candidate.candidatemasterid);
                // this.candidate.getcandidateList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
                //   this.data = data;
                //   this.statusname = data.status.statusname;
                _this.alerts.success('Candidate details updated successfully', true);
                // });
            });
        }
    };
    CandidatedetailsComponent.prototype.selectall = function (val) {
        this.ismail = val;
    };
    CandidatedetailsComponent.prototype.submitStatus = function (emailerForm, modelid) {
        var _this = this;
        if (this.candidatemessageFormGroup.invalid) {
            return;
        }
        emailerForm.ismail = this.ismail;
        this.candidate.candidatestatusdetailsUpdate(emailerForm).subscribe(function (data) {
            // console.log(emailerForm.candidateid);
            _this.showSkills(emailerForm.candidateid);
            _this.modalService.close(modelid);
            _this.alerts.success('Candidate details updated successfully', true);
            // this.candidate.getcandidateList({ "id": this.Userid, "jobid": this.jobid }).subscribe((data: any) => {
            //   this.record = data.length;
            //   this.data = data;
            //   console.log(data);
            //   this.statusname = data.status.statusname;
            // });
        });
    };
    CandidatedetailsComponent.prototype.onContactSubmit = function (value3, id) {
        var _this = this;
        value3.userid = this.Userid;
        value3.skills = null; // this.tempArr;
        this.submitted = true;
        if (this.candidateformGroup.invalid) {
            return;
        }
        // console.log(value3);
        value3.smarks = null; // 100 * (Number(this.tempArr.skills.length) / Number(this.skilllist.length));
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            // console.log(this.file);
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") && (filetype[filetype.length - 1].toLowerCase() != "gif") && (filetype[filetype.length - 1].toLowerCase() != "tif") && (filetype[filetype.length - 1].toLowerCase() != "pdf") && (filetype[filetype.length - 1].toLowerCase() != "doc") && (filetype[filetype.length - 1].toLowerCase() != "docx") && (filetype[filetype.length - 1].toLowerCase() != "msword")) {
                this.fileformaterror = true;
                // console.log(filetype[filetype.length - 1]);
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.cvname = imagepath;
        }
        else {
            // value3.cvname = this.candidateDetail.cvname;
            value3.cvname = "";
        }
        this.disableBtn = false;
        this.candidate.candidatedetailsUpdate(value3).subscribe(function (data) {
            _this.candidate.getcandidateList({ "id": _this.Userid, "jobid": _this.jobid }).subscribe(function (data) {
                _this.record = data.length;
                _this.data = data;
            });
        });
        this.alerts.success('Candidate details updated successfully', true);
        this.filename = "Choose file";
        this.modalService.close(id);
    };
    CandidatedetailsComponent.prototype.NewUrl = function (id) {
        this.router.navigate(['/candidatedetails/' + this.jobid + '/' + id]);
    };
    return CandidatedetailsComponent;
}());
exports.CandidatedetailsComponent = CandidatedetailsComponent;
