<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper payment">
    <div class="container">
      <div class="tabs" id="tabs">
        <div class="tabs__sontent">
          <app-invioce-shared></app-invioce-shared>
          <div class="clearfix"></div>
          <div class="invoice_contentfff">
            <div class="row text-center">

            </div>
            <div class="payment_plan mt-4">
              <div class="row">
                <div class="col-lg-12 pull-left" *ngIf="ctype == 0" >
                  <div class="onoffswitch1">
                    <input type="checkbox" name="plantype" class="onoffswitch-checkbox" id="plantype"
                      (change)="selectPlanType($event.target.checked);">
                    <label class="onoffswitch-label" for="plantype">
                      <span class="onoffswitch-inner1"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
                  <!-- <div class="col-sm-4" *ngFor="let pl of planlist; let i=index">
                    <div class="plan">
                        <div class="plan_header" [ngClass]="pl.planid == 2 || pl.planid == 5 ?'popular':''">
                          <span class="most_popular" *ngIf="pl.planid == 2 || pl.planid == 5">Most Popular</span>
                            <p class="plan_name">{{ pl.plantitle }}</p>
                            <div class="price">£ {{ pl.prices }}
                              <span class="sub" *ngIf="!pl.plantype">Per Month</span>
                              <span class="sub" *ngIf="pl.plantype">Per Year</span>
                            </div>
                            <p class="employeebef">{{ pl.planname }}</p>
                        </div>
                        <div class="plan_details">
                            <ul>
                              <li *ngFor="let pl3 of pl.plande"><img src="../../../../../assets/img/check.png"> <div innerHTML="{{pl3.descname}}"></div> </li>
                            </ul>
                        </div>
                        <div class="plan_btn">
                          <button type="submit" class="btn btn-primary com_btn"
                          (click)="updateyourplan(pl.stripplanid, 'custom-payment-1')">Purchase </button>
                        </div>
                    </div>
                  </div> -->
                  
                  <div class="col-sm-4" *ngFor="let pl of planlist; let i=index">
                    <div class="plan  mb-4">
                        <div class="plan_header"  [ngClass]="pl.planid == 2 || pl.planid == 5 || pl.planid == 12 ?'popular':''">
                          <span class="most_popular" *ngIf="pl.planid == 2 || pl.planid == 5 || pl.planid == 12">Most Popular</span>
                            <p class="plan_name">{{ pl.plantitle }}</p>
                            <div class="price">£ {{ pl.prices }}
                              <span class="sub" *ngIf="!pl.plantype">Per Month</span>
                              <span class="sub" *ngIf="pl.plantype">Per Year</span>
                            </div>
                            <p class="employeebef">{{ pl.planname }}</p>
                        </div>
                        <div class="plan_details">
                          <ul>
                            <li *ngFor="let pl3 of pl.plande"><img src="../../../../../assets/img/check.png"> <div class="  " innerHTML="{{pl3.descname}}"></div> </li>
                          </ul>
                      </div>
                        <div class="plan_btn" *ngIf="ctype == 0">
                          <button class="demo btn-primary btn-lg" style="background-color: gray;border: 1px solid gray;"
                          [disabled]="true" *ngIf="pl.planid < planid"
                          (click)="buySubscription(pl.stripplanid, pl.planid,1)">Downgrade</button>
                        <button class="demo btn-primary btn-lg" style="background-color: gray;border: 1px solid gray;"
                          [disabled]="true" *ngIf="pl.planid == planid"
                          (click)="buySubscription(pl.stripplanid, pl.planid,0)">Current Plan</button>
                        <button class="demo btn-primary btn-lg" [disabled]="false" *ngIf="pl.planid > planid"
                          (click)="buySubscription(pl.stripplanid, pl.planid,0)">Upgrade</button>
                        </div>


                        <div class="plan_btn" *ngIf="ctype == 2 || ctype == 1 || ctype == 3">
                            <button class="demo btn-primary btn-lg"  
                              *ngIf="pl.planid < planid" [disabled]="true" 
                            (click)="buySubscription(pl.stripplanid, pl.planid,1)">Downgrade</button>
                            <button class="demo btn-primary btn-lg" style="background-color: gray;border: 1px solid gray;"
                              [disabled]="true" *ngIf="pl.planid == planid"
                              (click)="buySubscription(pl.stripplanid, pl.planid,0)">Current Plan</button>
                            <button class="demo btn-primary btn-lg" [disabled]="false" *ngIf="pl.planid > planid"
                              (click)="buySubscription(pl.stripplanid, pl.planid,0)">Upgrade</button>
                        </div>



                    </div>
                  </div>
                  <!-- <div class="col-sm-4">
                    <div class="plan">
                        <div class="plan_header">
                            <p class="plan_name">Large</p>
                            <div class="price">£ 600 <span class="sub">Per Month</span></div>
                            <p class="employeebef">1-25 EMPLOYEES</p>
                        </div>
                        <div class="plan_details">
                            <ul>
                              <li><img src="../../../../../assets/img/check.png"> No setup charge</li>
                              <li><img src="../../../../../assets/img/check.png"> 10 Admins</li>
                              <li><img src="../../../../../assets/img/check.png"> All Modules</li>
                              <li><img src="../../../../../assets/img/check.png"> Tier 2 Sponsor Compliance</li>
                              <li><img src="../../../../../assets/img/check.png"> Mobile Apps</li>
                              <li><img src="../../../../../assets/img/check.png"> No Training fees</li>
                              <li><img src="../../../../../assets/img/check.png"> Free support: 12 months</li>
                              <li><img src="../../../../../assets/img/check.png"> Storage 10GB</li>
                            </ul>
                        </div>
                        <div class="plan_btn">
                          <button type="submit" class="btn btn-primary com_btn">Upgrade Plan </button>
                        </div>
                    </div>
                  </div> -->
              </div>
            </div>
            <div class="row text-center">
              <!-- <div class="col-lg-4" *ngFor="let pl of planlist; let i=index">
                <div class="card plan-card">
                  <div class="card-block">
                    <div color="purple" *ngIf="pl.planid == 2 || pl.planid == 5"
                      class="StyledPricingPlansCardBanner-sc-1bdxjtk-1 cKdVxM">
                      <h5 data-component-id="global.heading" mode="light" class="StyledHeading-sc-1tjze98-0 faeNDi">Most
                        popular</h5>
                    </div>

                    <div class="pb-3">
                      <h1 class="plan-price padding-b-15 purple">{{ pl.plantitle }}</h1>
                      <h1><i class="ion-plane plan-icon"></i></h1>
                      <h6 class="text-uppercase text-primary">{{ pl.planname }}</h6>
                    </div>
                    <div>
                      <h1 class="plan-price padding-b-15">£ {{ pl.prices }}<span class="text-muted m-l-10">
                          <sup class="subclass" *ngIf="!pl.plantype">Per Month</sup><sup class="subclass"
                            *ngIf="pl.plantype">Per Year</sup>
                        </span></h1>
                      <div class="plan-div-border"></div>
                    </div>
                    <div class="plan-features pb-3 mt-3 text-muted padding-t-b-30">
                      <div *ngFor="let pl3 of pl.plande">
                        <div innerHTML="{{pl3.descname}}" class="plan-des-list"></div>
                      </div>
                      <button class="demo btn-primary btn-lg" style="background-color: gray;border: 1px solid gray;"
                        [disabled]="true" *ngIf="pl.planid < planid"
                        (click)="buySubscription(pl.stripplanid, pl.planid)">Downgrade</button>
                      <button class="demo btn-primary btn-lg" style="background-color: gray;border: 1px solid gray;"
                        [disabled]="true" *ngIf="pl.planid == planid"
                        (click)="buySubscription(pl.stripplanid, pl.planid)">Current Plan</button>
                      <button class="demo btn-primary btn-lg" [disabled]="false" *ngIf="pl.planid > planid"
                        (click)="buySubscription(pl.stripplanid, pl.planid)">Upgrade</button>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <jw-modal id="custom-cardlist-1" role="dialog" class="modal">
    <div role="document" class="formgroup">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="popupLabel">Payment Method</h5>
          <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
            (click)="closeModal('custom-cardlist-1');">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="container">
            <div class="row row-sm topheader">
              <div class="col-lg-4">
                <h4>&nbsp; </h4>
              </div>
              <div class="col-lg-4">
                &nbsp;
              </div>
              <div class="col-lg-4">
                <h6 class="text-right float-right">
                  <a (click)="updateyourplan('custom-payment-1')" class="addscreensuccess">+ Add New Card</a></h6>
              </div>
            </div>

            <div class="row" *ngIf="cardlist">
              <div class="col-lg-4" *ngFor="let cad of cardlist; let i=index">
                <p>
                  <b>Card Number: </b> xxxx-xxxx-xxxx-{{cad.last4}}
                </p>
                <p>
                  <b>Expiry: </b>{{cad.exp_month}} / {{cad.exp_year}}
                </p>
                <p>
                  <button (click)="removeCard(cad.id);" *ngIf="i !=0">remove</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </jw-modal> -->

  <jw-modal id="custom-payment-1" role="dialog" class="modal">
    <div role="document" class="formgroup">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="popupLabel">Add New Card</h5>
          <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
            (click)="closeModal('custom-payment-1');">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div *ngIf="invalidError" style="color:red">
          {{ invalidError.message }}
        </div>

        <stripe-card #stripeCard (catch)="onStripeError($event)" [(invalid)]="invalidError"
          (tokenChange)="setStripeToken($event, 'custom-payment-1')"></stripe-card>
        <button type="button" class="btn btn-primary mt-4" (click)="stripeCard.createToken(extraData)">Add Card</button>
      </div>
    </div>
  </jw-modal>
