"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Cos = /** @class */ (function () {
    function Cos() {
        this.cosstartdate = new Date();
        this.cosexpirydate = new Date();
        // iscurrent: number;    
    }
    return Cos;
}());
exports.Cos = Cos;
