

<div class="container">
    <div class="row">
        <div class="col-sm-8 mt-3">
            <img alt="" src="/assets/img/logo.png">
        </div>
        <div class="col-sm-4 mt-3 float-right">
            <div class="registation_options1 float-right">
                <a [routerLink]="['/login']"  style="cursor: pointer;" class="backbuttonsss">
                    <span class="lnr lnr-arrow-left"></span>
                    <span>Back to Login
                    </span>

                </a>
                <!-- <a (click)="newregistation(1)" class="option_show" [ngClass]="{'active' : type ==1 }">
                    <span>Registration Employment Screening
                    </span>
                    <span class="lnr lnr-arrow-right"></span>
                </a> -->
            </div>
        </div>

    </div>
</div>




<div class="registation_options" *ngIf="stage == 1">
    <a (click)="newregistation(0)" class="option_show">
        <span>Register HR Software </span>
        <span class="lnr lnr-arrow-right"></span>
    </a>
    <a (click)="newregistation(1)" class="option_show">
        <span>Compliance Centre <br />1 Platform - 20 Checks </span>
        <span class="lnr lnr-arrow-right"></span>
    </a>

    <!-- <a (click)="newregistation(2)" class="option_show not-active">
        <span>Business Credit Reports
        </span>
        <span class="lnr lnr-arrow-right"></span>
    </a>

    <a (click)="newregistation(3)" class="option_show">
        <span>Automated Reference</span>
        <span class="lnr lnr-arrow-right"></span>
    </a>     -->
</div>




<div class="signup-wrapper registation-wrapper" *ngIf="stage == 2">
    <div class="new_login">
        <div class="graphic_section width60">

            <h5 *ngIf="type==0" class="signupheading">Register HR Software</h5>
            <h5 *ngIf="type==1" class="signupheading">Compliance Centre (1 Platform - 20 Checks) </h5>
            <h5 *ngIf="type==2" class="signupheading">Business Credit Reports</h5>
            <h5 *ngIf="type==3" class="signupheading">Automated Reference</h5>

          <a (click)="newbackregistation()" style="cursor: pointer;" class="registrationbuttonsss">
            <span class="lnr lnr-arrow-left"></span>

            <span>Registration Option
            </span>

        </a>
            <!-- <a  class="login_logo" href="#"><img src="/assets/img/logo.png" alt=""></a> -->
            <div class="signin-box">


                <form [formGroup]="registerForm" #loginForm class="login_form" (ngSubmit)="onSubmit()">
                    <!-- <p>Please fill the below details to begin the sign up process</p> -->
                    <div class="row">
                        <div class="col-lg-12">

                            <div class="registation_options1 float-right">

                                <!-- <a (click)="newregistation(1)" class="option_show" [ngClass]="{'active' : type ==1 }">
                                    <span>Registration Employment Screening
                                    </span>
                                    <span class="lnr lnr-arrow-right"></span>
                                </a> -->
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-lg-6">
                    <div class="form-group mg-b-30">
                        <div class="input-group">
                            <input type="text" formControlName="companyname" #Email [(ngModel)]="user.companyname"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.companyname.errors }"
                                placeholder="Company Name " [maxlength]="200">
                            <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-building"
                                        aria-hidden="true"></i></span>
                            </div>
                            <div *ngIf="submitted && f.companyname.errors" class="invalid-feedback">
                                <div *ngIf="f.companyname.errors.required">Please enter company name</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-lg-6">
                    <div class="form-group mg-b-30">
                        <div class="input-group">
                            <input type="text" (focusout)="onUserNameOutEvent($event)" formControlName="username" #Username [(ngModel)]="user.username"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                placeholder="Username " [maxlength]="200" >
                            <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-user"
                                        aria-hidden="true"></i></span>
                            </div>
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">Please enter username</div>
                            </div>
                            <span *ngIf="username=='true'" style="color:red; width: 100%; font-size: 80%;">Username already exists.</span>
                            <span *ngIf="username=='false'" style="color:green; width: 100%; font-size: 80%;">Username available.</span>
                        </div>
                    </div>

                </div>



            </div>



                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group mg-b-30">
                                <div class="input-group">
                                    <input type="text" formControlName="firstname" #firstname
                                        [(ngModel)]="user.firstname" class="form-control" [maxlength]="100"
                                        [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"
                                        placeholder="First Name">
                                    <div class="input-group-append"> <span class="input-group-text"><i
                                                class="fa fa-user" aria-hidden="true"></i></span>
                                    </div>
                                    <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstname.errors.required">Please enter first name</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group mg-b-30">
                                <div class="input-group">
                                    <input type="text" formControlName="lastname" #lastname [(ngModel)]="user.lastname"
                                        class="form-control" [maxlength]="100"
                                        [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"
                                        placeholder="Last Name">
                                    <div class="input-group-append"> <span class="input-group-text"><i
                                                class="fa fa-user" aria-hidden="true"></i></span>
                                    </div>
                                    <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                                        <div *ngIf="f.lastname.errors.required">Please enter last name</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group  mg-b-30">
                                <div class="input-group">
                                    <input type="email" (focusout)="onEmailOutEvent($event)" formControlName="email" #Email [(ngModel)]="user.email"
                                        class="form-control" [maxlength]="100"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email">
                                    <div class="input-group-append"> <span class="input-group-text"><i
                                                class="fa fa-envelope" aria-hidden="true"></i></span>
                                    </div>
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Please enter email</div>
                                        <div *ngIf="f.email.errors.email">Please enter valid email</div>
                                    </div>
                                    <span *ngIf="emailcheck=='true'" style="color:red; width: 100%; font-size: 80%;">Email already exists.</span>
                                    <!-- <span *ngIf="emailcheck=='false'" style="color:green; width: 100%; font-size: 80%;">User name available.</span> -->
                             


                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group mg-b-30">
                                <div class="input-group">
                                    <international-phone-number class="form-control phonecontrol"
                                        formControlName="mobilenumber"
                                        [ngClass]="{ 'is-invalid': submitted && f.mobilenumber.errors }"
                                        [(ngModel)]="user.mobilenumber" placeholder="Enter Mobile number"
                                        (input)="validatenumber($event.target.value,'phone')" [maxlength]="20"
                                        [defaultCountry]="'gb'" #mobilenumber name="mobilenumber">
                                    </international-phone-number>
                                    <div class="input-group-append"> <span class="input-group-text"><i
                                                class="fa fa-phone" aria-hidden="true"></i></span>
                                    </div>
                                    <div *ngIf="submitted && f.mobilenumber.errors" class="invalid-feedback">
                                        <div *ngIf="f.mobilenumber.errors.required">Please enter mobile number</div>
                                    </div>
                                    <span *ngIf="phonecheck==false"
                                        style="color:red; width: 100%; font-size: 80%;">Invalid Format</span>
                                    <span *ngIf="phonecheck==true"
                                        style="color:green; width: 100%; font-size: 80%;">Valid Format</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group mg-b-30">
                                <div class="input-group">
                                    <input type="password" formControlName="password" #Password
                                        [(ngModel)]="user.password" class="form-control" [maxlength]="50"
                                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                        placeholder="Password">
                                    <div class="input-group-append"> <span class="input-group-text"><i
                                                class="fa fa-lock" aria-hidden="true"></i></span>
                                    </div>
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">Please enter password</div>
                                        <div *ngIf="f.password.errors.minlength">Password must be at least 8 characters
                                            long
                                        </div>
                                        <div *ngIf="f.password.errors.pattern">Password must be Alphanumeric including
                                            Uppercase, Lowercase and Special characters</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group mg-b-30">
                                <div class="input-group">
                                    <input type="password" formControlName="confirmpassword" #Password
                                        [(ngModel)]="user.confirmpassword" [maxlength]="50" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }"
                                        placeholder="Confirm Password">
                                    <div class="input-group-append"> <span class="input-group-text"><i
                                                class="fa fa-lock" aria-hidden="true"></i></span>
                                    </div>
                                    <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
                                        <div *ngIf="f.confirmpassword.errors.required">Please re-enter confirm Password
                                        </div>
                                        <div *ngIf="f.confirmpassword.errors.minlength">Password must be at least 8
                                            characters long</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 invalid-feedback" *ngIf="registerForm.hasError('notSame')">Passwords Are
                            Not Same</div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group noticereg">
                                <input type="checkbox" id="chkTermCndition" [(ngModel)]="user.chkTermCndition"
                                    formControlName="chkTermCndition"
                                    [ngClass]="{ 'is-invalid': submitted && f.chkTermCndition.errors }" required> I
                                confirm that I have read the
                                <a href="https://www.complygate.co.uk/assets/pdf/client_privacy_policy2.pdf" target="_blank">Privacy Policy</a> and I agree to the
                                Website's <a href="https://www.complygate.co.uk/assets/pdf/Conditions_of_Use.pdf" target="_blank"> Conditions of Use</a> and
                                <a href="https://www.complygate.co.uk/assets/pdf/Conditions_of_Use.pdf" target="_blank">Complygate Licence
                                    Agreement</a>.

                                <div *ngIf="submitted && f.chkTermCndition.errors" class="invalid-feedback">
                                    <div *ngIf="f.chkTermCndition.errors.required">Please accept the above to continue</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">

                            <div class="form-group noticereg">
                                <input type="checkbox" id="chkcont" [(ngModel)]="user.chkcont" formControlName="chkcont"
                                    [ngClass]="{ 'is-invalid': submitted && f.chkcont.errors }" required> I understand that Complygate does not, in any way, replace immigration advice.
                                <div *ngIf="submitted && f.chkcont.errors" class="invalid-feedback">
                                    <div *ngIf="f.chkcont.errors.required">Please accept the above to continue</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button class="btn btn-primary btn-block btn-signin" style="width:100%" type="submit">Register
                    </button>
                    <!-- <p class="mg-b-0 usefulllink float-left"><a [routerLink]="['/forgotRequest']" class="fortgot col-sm-12" >Forgot Password</a></p> -->
                    <p class="mg-b-0 usefulllink text-center"><a [routerLink]="['/login']" class="fortgot col-sm-12">Log
                            In</a></p>
                </form>
            </div>
        </div>
        <div class="login_section width40 ">
            <div class="slogan">
                <h4>Welcome To <b>Complygate</b></h4>
                <span>Commit. Control. Comply</span>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>

</div>

<jw-modal id="custom-privacy-1" role="dialog" class="modal">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="popupLabel">Privacy Policy</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                    (click)="closeModal('custom-privacy-1');">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <p><a href="http://WWW.COMPLYGATE.CO.UK" target="_blank">WWW.COMPLYGATE.CO.UK</a> is
                                committed to safeguarding your privacy online. It is very important to us that you
                                should be able to use and enjoy our websites without having
                                to compromise your privacy in any way. This policy may change from time to time so
                                please check our websites regularly.</p>

                            <p><strong>Changes to the privacy notice and your duty to inform us of changes</strong></p>
                            <p>
                                The data protection law in the UK has changed on 25 May 2018. This privacy notice sets
                                out most of your rights under the new laws. It is important that the personal data we
                                hold about you is accurate and current. Please keep us informed if your personal
                                data changes during your relationship with us.</p>
                            <p>
                                <strong>Third-party links</strong>
                            </p>
                            <p>
                                These websites may include links to third-party websites, plug-ins and applications.
                                Clicking on those links or enabling those connections may allow third parties to collect
                                or share data about you. We do not control these third-party websites and are
                                not responsible for their privacy statements. When you leave our website, we encourage
                                you to read the privacy notice of every website you visit.</p>
                            <p>
                                These websites are not intended for children and we do not knowingly collect data
                                relating to children.
                            </p>
                            <p>
                                It is important that you read this privacy notice together with any other privacy notice
                                or fair processing notice we may provide on specific occasions when we are collecting or
                                processing personal data about you so that you are fully aware of how and
                                why we are using your data. This privacy notice supplements the other notices and is not
                                intended to override them.</p>
                            <p>
                                <strong>Who is collecting your information?</strong>
                            </p>
                            <p>
                                Our websites are operated by Complygate Limited, whose registered office is at 11
                                Kingfisher Business Park, Arthur Street, Redditch, Worcestershire, United Kingdom, B98
                                8LG and trading at 65 Church Street, Birmingham, B32DP.
                            </p>
                            <p><strong>Controller</strong></p>
                            <p>
                                Complygate Limited, whose registered office is at 11 Kingfisher Business Park, Arthur
                                Street, Redditch, Worcestershire, United Kingdom, B98 8LG is the controller and
                                responsible for your personal data (collectively referred to as &quot;Complygate&quot;,
                                we&quot;, &quot;us&quot; or &quot;our&quot; in this privacy notice).
                            </p>
                            <p>
                                We have appointed a data protection officer (DPO) who is responsible for overseeing
                                questions in relation to this privacy notice. If you have any questions about this
                                privacy notice, including any requests to exercise your legal rights, please contact
                                the DPO using the details set out below.</p>
                            <p><strong>Contact details</strong></p>
                            <p>
                                Our full details are:</p>
                            <p>
                                Full name of legal entity: C<a name="_Hlk517439100" id="_Hlk517439100">omplygate</a>
                                Limited</p>
                            <p>
                                Name or title of DPO: Mr John Holt </p>
                            <p>
                                Email address: info@complygate.co.uk </p>
                            <p>
                                Postal address: Complygate Limited, 65 Church Street, Birmingham B3 2DP</p>
                            <p>
                                Telephone number: 0121 655 0311</p>
                            <p>
                                You have the right to make a complaint at any time to the Information Commissioner's
                                Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk).
                                We would, however, appreciate the chance to deal with your concerns before you
                                approach the ICO so please contact us in the first instance.</p>
                            <p><strong>Changes to the privacy notice and your duty to inform us of changes</strong></p>
                            <p>
                                This version was last updated on 22 June 2018 and historic versions are archived and can
                                be obtained by contacting us.</p>
                            <p>
                                <strong>What personally identifiable information is collected from you?</strong>
                            </p>
                            <p>
                                Personal information may be gathered when you submit information to us or register for
                                services via our websites. This may include asking you for your name and address,
                                including an email address and your profession or employer. The more accurate the
                                information you volunteer, the better we are able to respond to your enquiries and
                                customise your experience of our web sites.</p>
                            <p>
                                Complygate occasionally asks users to complete surveys that we use for research
                                purposes. Whenever we collect personal information we include a link to this Privacy
                                Policy on the page in question.</p>
                            <p><strong>The data we collect about you</strong></p>
                            <p>Personal data, or personal information, means any information about an individual from
                                which that person can be identified. It does not include data where the identity has
                                been removed (anonymous data).</p>
                            <p>
                                We may collect, use, store and transfer different kinds of personal data about you which
                                we have grouped together follows:</p>
                            <ul>
                                <li>Identity Data includes first name, maiden name, last name, username or similar
                                    identifier, marital status, title, date of birth and gender.</li>
                                <li>Contact Data includes billing address, delivery address, email address and telephone
                                    numbers.</li>
                                <li>Financial Data includes bank account and payment card details.</li>
                                <li>Transaction Data includes details about payments to and from you and other details
                                    of products and services you have purchased from us.</li>
                                <li>Technical Data includes internet protocol (IP) address, your login data, browser
                                    type and version, time zone setting and location, browser plug-in types and
                                    versions, operating system and platform and other technology on
                                    the devices you use to access this website.</li>
                                <li>Profile Data includes your username and password, purchases or orders made by you,
                                    your interests, preferences, feedback and survey responses.</li>
                                <li>Usage Data includes information about how you use our website, products and
                                    services.</li>
                                <li>Marketing and Communications Data includes your preferences in receiving marketing
                                    from us and our third parties and your communication preferences.</li>
                            </ul>
                            <p>We also collect, use and share Aggregated Data such as statistical or demographic data
                                for any purpose. Aggregated Data may be derived from your personal data but is not
                                considered personal data in law as this data does not
                                directly or indirectly reveal your identity. For example, we may aggregate your Usage
                                Data to calculate the percentage of users accessing a specific website feature. However,
                                if we combine or connect Aggregated Data with
                                your personal data so that it can directly or indirectly identify you, we treat the
                                combined data as personal data which will be used in accordance with this privacy
                                notice.</p>
                            <p>
                                We do not collect any Special Categories of Personal Data about you (this includes
                                details about your race or ethnicity, religious or philosophical beliefs, sex life,
                                sexual orientation, political opinions, trade union membership, information about your
                                health and genetic and biometric data). Nor do we collect any information about criminal
                                convictions and offences.</p>
                            <p><strong>If you fail to provide personal data</strong></p>
                            <p>
                                Where we need to collect personal data by law, or under the terms of a contract we have
                                with you and you fail to provide that data when requested, we may not be able to perform
                                the contract we have or are trying to enter into with you (for example, to
                                provide you with goods or services). In this case, we may have to cancel a product or
                                service you have with us but we will notify you if this is the case at the time.
                                Complygate would not be liable for any loss of your
                                business due to non performance of the contract.</p>
                            <p><strong>How is your personal data collected?</strong></p>
                            <p>We use different methods to collect data from and about you including through:</p>
                            <ul>
                                <li>Direct interactions. You may give us your Identity, Contact and Financial Data by
                                    filling in forms or by corresponding with us by post, phone, email or otherwise.
                                    This includes personal data you provide when you:
                                </li>
                                <li>apply for our products or services;</li>
                                <li>create an account on our website;</li>
                                <li>subscribe to our service or publications;</li>
                                <li>request marketing to be sent to you;</li>
                                <li>enter a competition, promotion or survey; or</li>
                                <li>give us some feedback.</li>
                                <li>Automated technologies or interactions. As you interact with our website, we may
                                    automatically collect Technical Data about your equipment, browsing actions and
                                    patterns. We collect this personal data by using cookies,
                                    server logs and other similar technologies.</li>
                            </ul>
                            <p>We may also receive Technical Data about you if you visit other websites employing our
                                cookies. Please see our cookie policy for further details.</p>
                            <ul>
                                <li>Third parties or publicly available sources. We may receive personal data about you
                                    from various third parties and public sources as set out below . Technical Data from
                                    the following parties:</li>
                                <li>analytics providers such as Google based outside the EU;</li>
                                <li>advertising networks such as LinkedIn, Facebook and other social media platforms
                                    based inside OR outside the EU: and</li>
                                <li>search information providers such as Google, Bing, Duckpile etc. based inside OR
                                    outside the EU.
                                </li>
                                <li>Contact, Financial and Transaction Data from providers of technical, payment and
                                    delivery services such as Stripe, PayPal etc. based inside OR outside the EU.</li>
                                <li>Identity and Contact Data from publicly availably sources such as Companies House
                                    and the Electoral Register based inside the EU.</li>
                                <li>Tier 2 Sponsor list based in the UK. </li>
                            </ul>
                            <p><strong>How we use your personal data</strong></p>
                            <p>
                                We will only use your personal data when the law allows us to. Most commonly, we will
                                use your personal data in the following circumstances:</p>
                            <ul>
                                <li>Where we need to perform the contract we are about to enter into or have entered
                                    into with you.</li>
                                <li>Where it is necessary for our legitimate interests (or those of a third party) and
                                    your interests and fundamental rights do not override those interests.</li>
                                <li>Where we need to comply with a legal or regulatory obligation.</li>
                            </ul>
                            <p>Generally we do not rely on consent as a legal basis for processing your personal data
                                other than in relation to sending third party direct marketing communications to you via
                                email or text message. You have the right to withdraw
                                consent to marketing at any time by contacting us.</p>
                            <p><strong>Purposes for which we will use your personal data</strong></p>

                            <p>We have set out below, in a table format, a description of all the ways we plan to use
                                your personal data, and which of the legal bases we rely on to do so.</p>
                            <p>
                                We have also identified what our legitimate interests are where appropriate. Note that
                                we may process your personal data for more than one lawful ground depending on the
                                specific purpose for which we are using your data.
                            </p>
                            <p><strong>Marketing</strong></p>
                            <p>
                                We strive to provide you with choices regarding certain personal data uses, particularly
                                around marketing and advertising.
                            </p>
                            <p>
                                <strong>Promotional offers from us</strong>
                            </p>
                            <p>
                                We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on
                                what we think you may want or need, or what may be of interest to you. This is how we
                                decide which products, services and offers may be relevant for you (we call this
                                marketing).
                            </p>
                            <p>
                                You will receive marketing communications from us if you have requested information from
                                us or purchased goods or services from us or if you provided us with your details when
                                you entered a competition or registered for a promotion and, in each case,
                                you have not opted out of receiving that marketing.</p>
                            <p><strong>Third-party marketing</strong></p>
                            <p>
                                We will get your express opt-in consent before we share your personal data with any
                                company outside the Complygate group of companies for marketing purposes.</p>
                            <p><strong>Opting out</strong></p>
                            <p>You can ask us or third parties to stop sending you marketing messages at any time by
                                logging into the website and checking or unchecking relevant boxes to adjust your
                                marketing preferences or by following the opt-out links
                                on any marketing message sent to you or] by contacting us at any time.</p>
                            <p>
                                Where you opt out of receiving these marketing messages, this will not apply to personal
                                data provided to us as a result of a product/service purchase, warranty registration,
                                product/service experience or other transactions.
                            </p>
                            <p><strong>Cookies</strong></p>
                            <p> You can set your browser to refuse all or some browser cookies, or to alert you when
                                websites set or access cookies. If you disable or refuse cookies, please note that some
                                parts of this website may become inaccessible or not
                                function properly. For more information about the cookies we use.</p>
                            <p><strong>Change of purpose</strong></p>
                            <p>We will only use your personal data for the purposes for which we collected it, unless we
                                reasonably consider that we need to use it for another reason and that reason is
                                compatible with the original purpose. If you wish to
                                get an explanation as to how the processing for the new purpose is compatible with the
                                original purpose, please contact us.</p>
                            <p>
                                If we need to use your personal data for an unrelated purpose, we will notify you and we
                                will explain the legal basis which allows us to do so.</p>
                            <p>
                                Please note that we may process your personal data without your knowledge or consent, in
                                compliance with the above rules, where this is required or permitted by law.</p>
                            <p><strong>Disclosures of your personal data</strong></p>
                            <p>We may have to share your personal data with the parties for example, third parties to
                                whom we may choose to sell, transfer, or merge parts of our business or our assets or
                                Internal Third Parties, and External Third Parties
                                (please refer to the glossary below). Alternatively, we may seek to acquire other
                                businesses or merge with them. If a change happens to our business, then the new owners
                                may use your personal data in the same way as set
                                out in this privacy notice.</p>
                            <p>
                                We require all third parties to respect the security of your personal data and to treat
                                it in accordance with the law. We do not allow our third-party service providers to use
                                your personal data for their own purposes and only permit them to process your
                                personal data for specified purposes and in accordance with our instructions.
                            </p>
                            <p><strong>International transfers</strong></p>
                            <p>We do not transfer your personal data outside the European Economic Area (EEA). </p>
                            <p><strong>Data security</strong></p>
                            <p>We have put in place appropriate security measures to prevent your personal data from
                                being accidentally lost, used or accessed in an unauthorised way, altered or disclosed.
                                In addition, we limit access to your personal data
                                to those employees, agents, contractors and other third parties who have a business need
                                to know. They will only process your personal data on our instructions and they are
                                subject to a duty of confidentiality.
                            </p>
                            <p>
                                We have put in place procedures to deal with any suspected personal data breach and will
                                notify you and any applicable regulator of a breach where we are legally required to do
                                so.</p>
                            <p><strong>Data retention</strong></p>
                            <p>How long will you use my personal data for?</p>
                            <p>We will only retain your personal data for as long as necessary to fulfil the purposes we
                                collected it for, including for the purposes of satisfying any legal, accounting, or
                                reporting requirements.</p>
                            <p>
                                To determine the appropriate retention period for personal data, we consider the amount,
                                nature, and sensitivity of the personal data, the potential risk of harm from
                                unauthorised use or disclosure of your personal data, the purposes for which we process
                                your personal data and whether we can achieve those purposes through other means, and
                                the applicable legal requirements.</p>
                            <p>
                                By law we have to keep basic information about our customers (including Contact,
                                Identity, Financial and Transaction Data) for six years after they cease being customers
                                for tax purposes.</p>
                            <p><strong>In some circumstances you can ask us to delete your data</strong></p>
                            <p>In some circumstances we may anonymise your personal data (so that it can no longer be
                                associated with you) for research or statistical purposes in which case we may use this
                                information indefinitely without further notice
                                to you.</p>
                            <p><strong>Your legal rights</strong></p>
                            <p> Under certain circumstances, you have rights under data protection laws in relation to
                                your personal data. Please click on the links below to find out more about these rights:
                            </p>
                            <p><a target="_blank"
                                    href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/</a>
                            </p>
                            <p>
                                The GDPR provides the following rights for individuals:</p>
                            <ul>
                                <li>The right to be informed</li>
                                <li>The right of access</li>
                                <li>The right to rectification</li>
                                <li>The right to erasure</li>
                                <li>The right to restrict&nbsp;processing</li>
                                <li>The right to data portability</li>
                                <li>The right to object</li>
                                <li>Rights in relation to automated decision making and profiling.</li>
                            </ul>
                            <p> If you wish to exercise any of the rights set out above, please contact us.</p>
                            <p><strong>No fee usually required</strong></p>
                            <p>
                                You will not have to pay a fee to access your personal data (or to exercise any of the
                                other rights). However, we may charge a reasonable fee if your request is clearly
                                unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your
                                request in these circumstances.</p>
                            <p><strong>What we may need from you</strong></p>
                            <p>We may need to request specific information from you to help us confirm your identity and
                                ensure your right to access your personal data (or to exercise any of your other
                                rights). This is a security measure to ensure that personal
                                data is not disclosed to any person who has no right to receive it. We may also contact
                                you to ask you for further information in relation to your request to speed up our
                                response.</p>
                            <p><strong>Time limit to respond</strong></p>
                            <p> We try to respond to all legitimate requests within one month. Occasionally it may take
                                us longer than a month if your request is particularly complex or you have made a number
                                of requests. In this case, we will notify you
                                and keep you updated.</p>
                            <p><strong>Glossary</strong></p>
                            <p><strong>LAWFUL BASIS</strong></p>
                            <p>Legitimate Interest means the interest of our business in conducting and managing our
                                business to enable us to give you the best service/product and the best and most secure
                                experience. We make sure we consider and balance
                                any potential impact on you (both positive and negative) and your rights before we
                                process your personal data for our legitimate interests. We do not use your personal
                                data for activities where our interests are overridden
                                by the impact on you (unless we have your consent or are otherwise required or permitted
                                to by law). You can obtain further information about how we assess our legitimate
                                interests against any potential impact on you in
                                respect of specific activities by contacting us.</p>
                            <p>
                                Performance of Contract means processing your data where it is necessary for the
                                performance of a contract to which you are a party or to take steps at your request
                                before entering into such a contract.</p>
                            <p>
                                Comply with a legal or regulatory obligation means processing your personal data where
                                it is necessary for compliance with a legal or regulatory obligation that we are subject
                                to.</p>
                            <p>
                                <strong>THIRD PARTIES</strong>
                            </p>
                            <p>
                                <strong>Internal Third Parties</strong>
                            </p>
                            <p>
                                Our staff, employees and agents.</p>
                            <p>
                                <strong>External Third Parties</strong>
                            </p>
                            <ul>
                                <li>Service providers acting as processors who provide IT and system administration
                                    services.</li>
                                <li>Professional advisers acting as processors or joint controllers including lawyers,
                                    bankers, auditors and insurers based in the UK who provide consultancy, banking,
                                    legal, insurance and accounting services.
                                </li>
                                <li>HM Revenue &amp; Customs, regulators and other authorities acting as processors or
                                    joint controllers based in the United Kingdom who require reporting of processing
                                    activities in certain circumstances.</li>
                            </ul>
                            <p><strong>YOUR LEGAL RIGHTS</strong></p>
                            <p>
                                <strong>You have the right to:</strong>
                            </p>
                            <p>
                                Request access to your personal data (commonly known as a &quot;data subject access
                                request&quot;). This enables you to receive a copy of the personal data we hold about
                                you and to check that we are lawfully processing it.</p>
                            <p>
                                Request correction of the personal data that we hold about you. This enables you to have
                                any incomplete or inaccurate data we hold about you corrected, though we may need to
                                verify the accuracy of the new data you provide to us.</p>
                            <p>
                                Request erasure of your personal data. This enables you to ask us to delete or remove
                                personal data where there is no good reason for us continuing to process it. You also
                                have the right to ask us to delete or remove your personal data where you have
                                successfully exercised your right to object to processing (see below), where we may have
                                processed your information unlawfully or where we are required to erase your personal
                                data to comply with local law. Note, however,
                                that we may not always be able to comply with your request of erasure for specific legal
                                reasons which will be notified to you, if applicable, at the time of your request.
                            </p>
                            <p>
                                Object to processing of your personal data where we are relying on a legitimate interest
                                (or those of a third party) and there is something about your particular situation which
                                makes you want to object to processing on this ground as you feel it impacts
                                on your fundamental rights and freedoms. You also have the right to object where we are
                                processing your personal data for direct marketing purposes. In some cases, we may
                                demonstrate that we have compelling legitimate grounds
                                to process your information which override your rights and freedoms.
                            </p>
                            <p>
                                <strong>Request restriction of processing of your personal data</strong>
                            </p>
                            <p>
                                This enables you to ask us to suspend the processing of your personal data in the
                                following scenarios: (a) if you want us to establish the data's accuracy; (b) where our
                                use of the data is unlawful but you do not want us to erase it; (c) where you need
                                us to hold the data even if we no longer require it as you need it to establish,
                                exercise or defend legal claims; or (d) you have objected to our use of your data but we
                                need to verify whether we have overriding legitimate
                                grounds to use it.</p>
                            <p>
                                Request the transfer of your personal data to you or to a third party. We will provide
                                to you, or a third party you have chosen, your personal data in a structured, commonly
                                used, machine-readable format. Note that this right only applies to automated
                                information which you initially provided consent for us to use or where we used the
                                information to perform a contract with you.</p>
                            <p>
                                Withdraw consent at any time where we are relying on consent to process your personal
                                data. However, this will not affect the lawfulness of any processing carried out before
                                you withdraw your consent. If you withdraw your consent, we may not be able to
                                provide certain products or services to you. We will advise you if this is the case at
                                the time you withdraw your consent.</p>
                            <p>
                                <strong>Cookies Policy</strong>
                            </p>
                            <p>
                                <strong>What are cookies and how does Complygate use them?</strong>
                            </p>
                            <p>
                                A cookie is a small text file containing information that a web site transfers to your
                                computer&rsquo;s hard disk for record-keeping purposes. Cookies can make a web site more
                                useful by allowing the site to personalise information for visitors and by
                                storing information about customer preferences on a particular site. The use of cookies
                                is an industry standard, and many major web sites use them to provide useful features
                                for their customers. The cookie information does
                                not include personal data such as name, age, phone number, e-mail address or mailing
                                address.</p>
                            <p>
                                The Complygate website only uses non-persistent, essential cookies in order for the site
                                to operate as you expect. These cookies are temporary and contain no personally
                                identifiable information.</p>
                            <p>
                                Some of Complygate.co.uk online services also use cookies to maintain session states in
                                ASP.NET. These cookies are temporary and contain no personally identifiable information.
                            </p>
                            <p>
                                <strong>How does Complygate software use your information?</strong>
                            </p>
                            <p>
                                Complygate's primary goal in collecting personal information concerning you is to enable
                                us to respond to your enquiries as promptly and accurately as possible.</p>
                            <p>
                                We undertake research on our users&rsquo; demographics, interests and behaviour based on
                                the information provided to us upon registration, from our server log files or from
                                surveys. We do this to better understand and serve you. This research is compiled
                                and analysed on an aggregated basis.</p>
                            <p>
                                <strong>With whom does Complygate share your information?</strong>
                            </p>
                            <p>
                                When you are on Complygate.co.uk website and are asked for personal information, you may
                                not be sharing that information with Complygate.co.uk alone. This is because some
                                services are offered jointly with a partner company. In order to provide this co-branded
                                service to you, it is necessary for us to share your personal information with those
                                partner companies.</p>
                            <p>
                                Your information will not be shared with any third party without your written consent.
                            </p>
                            <p>
                                When you join one of our email newsletter services, the emails you receive may include
                                advertising material and links to advertisers&rsquo; web sites. This does not mean that
                                your details have been passed to the advertiser in question. Furthermore, at
                                the end of each newsletter you will find either a hyperlink or a description of a route
                                to stop receiving further editions of that newsletter, should you so wish.</p>
                            <p>
                                <strong>What kind of security precautions are in place to protect the loss, misuse, or
                                    alteration of
                                    your
                                    information?</strong>
                            </p>
                            <p>
                                Unfortunately, no data transmission over the Internet can be guaranteed to be 100%
                                secure. As a result, whilst we continually strive to protect your personal information
                                and appreciate your need for security, Complygate software can neither ensure nor
                                warrant the security of any information you transmit to us or from our online services,
                                and you do so at your own risk. Once we receive your transmission, we make our best
                                effort to ensure its security on our systems.</p>
                            <p>
                                <strong>What else you should know about your online privacy?</strong>
                            </p>
                            <p>
                                Please keep in mind that whenever you voluntarily disclose personal information online –
                                for example on message boards, through email, or in chat areas – that information can be
                                collected and used by others. In short, if you post personal information
                                online that is accessible to the public, you may receive unsolicited messages from other
                                parties in return. Ultimately, you are solely responsible for maintaining the secrecy of
                                your passwords and/or any account information.
                                Complygate Limited staff will NEVER compromise your security by asking for your password
                                online. Please be careful and responsible when you are online.</p>
                            <p>
                                If you have any requests concerning your personal information or any queries with regard
                                to these practices, please contact us by e-mail:&nbsp;info@complygate.co.uk </p>
                            <p>
                                Complygate Limited is registered in England and Wales with company number 11382934, the
                                registered office of which is at 11 Kingfisher Business Park, Arthur Street, Redditch,
                                Worcestershire, United Kingdom, B98 8LG.
                            </p>
                            <p>&nbsp;</p>
                        </div>
                        <r /div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="custom-terms-1" role="dialog" class="modal">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="popupLabel">Terms Of Use</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                    (click)="closeModal('custom-terms-1');">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2>General Terms:</h2>
                            <p>Please read these Website General Terms of Use (&ldquo;General Terms&rdquo;) carefully
                                before using this website (the &ldquo;Site&rdquo;) and its services (the
                                &ldquo;Services&rdquo;). Using the Site and Services indicates
                                that you accept these General Terms. If you do not accept these General Terms, do not
                                use the Site and Services.</p>
                            <p>
                                If we change these General Terms, we will post the revised document here with an updated
                                effective date. If we make significant changes to these General Terms, we may also
                                notify you by other means such as sending an email or posting a notice on our home
                                page.
                            </p>
                            <h4>Information About Us</h4>
                            <p>The Site and its services are operated by Complygate Limited, trading as ComplyGate
                                (&ldquo;we&rdquo;, &ldquo;our&rdquo; or &ldquo;us&rdquo;) (Company registration number:
                                11382934 whose registered office is at 11 Kingfisher
                                Business Park, Arthur Street, Redditch, Worcestershire, United Kingdom, B98 8LG). You
                                can contact us using the following email address: <a
                                    href="mailto:info@complygate.co.uk">info@complygate.co.uk</a>
                            </p>
                            <h5>Access to the Site and Services</h5>
                            <ul>
                                <li>Subject to any contractual obligations we may owe to any users of our paid services,
                                    we shall not be liable if for any reason the Site and Services are unavailable at
                                    any time or for any period.</li>
                                <li>From time to time, we may restrict access to all or some parts of the Site and
                                    Services to users who have registered with us.</li>
                                <li>If you choose, or you are provided with, a user identification code, password or any
                                    other piece of information as part of our security procedures, you must treat such
                                    information as confidential, and you must not disclose
                                    it to any third party.</li>
                                <li>We reserve the right to restrict or deny you access to all or some parts of the Site
                                    and Services if, in our opinion, you have failed to comply with these General Terms.
                                </li>
                            </ul>


                            <h5>By using our Site you accept these General Terms</h5>
                            <p>
                                By using our Site, you confirm that you accept these General Terms of use and that you
                                agree to comply with them.
                            </p>
                            <p>
                                If you do not agree to these General Terms, you must not use our Site.</p>
                            <p>
                                We recommend that you print a copy of these General Terms for your future reference.</p>
                            <p>
                                There are other terms that may apply to you.</p>
                            <p>
                                These General Terms of use refer to the following additional terms, which also apply to
                                your use of our Site:
                            </p>
                            <ul>
                                <li>Our Privacy Policy [<a target="_blank"
                                        href="https://www.complygate.co.uk/terms-of-use" target="_top">Click Here</a>].
                                </li>
                            </ul>
                            <h5>We may make changes to these General Terms</h5>
                            <p> We amend these General Terms from time to time. Every time you wish to use our Site,
                                please check these General Terms to ensure you understand the terms that apply at that
                                time. These General Terms were most recently updated
                                on 22 June 2018.</p>

                            <h5>We may make changes to our site</h5>
                            <p> We may update and change our site from time to time to reflect changes to our products,
                                our users' needs, fix any bugs and our business priorities.</p>
                            <p>
                                We may suspend or withdraw our Site at any time.</p>
                            <p>
                                Our Site is made available free of charge.</p>
                            <p>
                                We do not guarantee that our Site, or any content on it, will always be available or be
                                uninterrupted. We may suspend or withdraw or restrict the availability of all or any
                                part of our Site for business and operational reasons. We will try to give you
                                reasonable notice of any suspension or withdrawal.</p>
                            <p>
                                You are also responsible for ensuring that all persons who access our Site through your
                                internet connection are aware of these terms of use and other applicable terms and
                                conditions, and that they comply with them.
                            </p>
                            <h5>Our Site is only for users in the UK</h5>
                            <p>Our Site is directed to people residing in the United Kingdom. We do not represent that
                                content available on or through our Site is appropriate for use or available in other
                                locations.</p>
                            <p>
                                <strong>You must keep your account details safe</strong>
                            </p>
                            <p>
                                If you choose, or you are provided with, a user identification code, password or any
                                other piece of information as part of our security procedures, you must treat such
                                information as confidential. You must not disclose it to any third party.</p>
                            <p>
                                We have the right to disable any user identification code or password, whether chosen by
                                you or allocated by us, at any time, if in our reasonable opinion you have failed to
                                comply with any of the provisions of these terms of use.</p>
                            <p>
                                If you know or suspect that anyone other than you knows your user identification code or
                                password, you must promptly notify us at <strong><a
                                        href="mailto:info@complygate.co.uk">info@complygate.co.uk</a></strong><strong>.
                                </strong></p>
                            <p>
                                <strong>Do not rely on information on this Site</strong>
                            </p>
                            <p>
                                The content on our Site is provided for general information only. It is not intended to
                                amount to advice on which you should rely. You must obtain professional or specialist
                                advice before taking, or refraining from, any action on the basis of the content
                                on our Site.</p>
                            <p>
                                Although we make reasonable efforts to update the information on our site, we make no
                                representations, warranties or guarantees, whether express or implied, that the content
                                on our Site is accurate, complete or up to date.</p>
                            <h5>Intellectual Property and Permitted Use</h5>
                            <ul>
                                <li>We are the owner or licensee of all intellectual property rights in the Site and the
                                    material published on it. Those works are protected by copyright laws and treaties
                                    around the world. All such legal rights are reserved.
                                </li>
                                <li>You may print and download extracts from the Site and Services for personal
                                    non-commercial use on the following basis:</li>
                                <ul>
                                    <li>no documents or related graphics are modified in any way;</li>
                                    <li>no graphics are used separately from accompanying text; and</li>
                                    <li>no copyright and trade mark notices are removed.</li>
                                </ul>
                                <li>You agree not to:</li>
                                <ul>
                                    <li>use the Site and Services for commercial purposes without obtaining our prior
                                        written agreement;
                                    </li>
                                    <li>copy, reproduce, distribute, republish, download, display, post or transmit in
                                        any form or by any means any content of the Site and Services except as
                                        permitted above.</li>
                                </ul>
                            </ul>
                            <h5>Material Submitted by You</h5>
                            <ul>
                                <li>Except for information that identifies you personally (e.g. your name, address,
                                    telephone number, email address and CV), any material which you submit will be
                                    considered non-confidential and non-proprietary such that we
                                    shall have the right to use, copy, distribute and disclose it to third parties for
                                    any purpose.
                                </li>
                                <li>You agree not to submit any material:</li>
                                <ul>
                                    <li>that is false, misleading, defamatory, discriminatory, threatening, offensive,
                                        abusive, likely to cause someone anxiety or distress, encourages violence or
                                        racial or religious hatred, blasphemous, pornographic, in breach
                                        of confidence, in breach of privacy; or</li>
                                    <li>that infringes any intellectual property rights, such as copyright and trade
                                        marks. This means generally that you must own the rights in everything you
                                        submit or must obtain permission from the rights owner to submit
                                        the material; or</li>
                                    <li>which is technically harmful (including, without limitation, computer viruses,
                                        logic bombs, Trojan horses, worms, harmful components, corrupted data or other
                                        malicious software or harmful data); or
                                    </li>
                                    <li>which encourages or teaches conduct that is a criminal offence, gives rise to
                                        civil liability, or is otherwise unlawful.</li>
                                </ul>
                                <li>We are not obliged to use material submitted by you and we may remove from the Site,
                                    the Services and our database any of the material submitted by you at our sole
                                    discretion.</li>
                                <li>We will fully co-operate with any law enforcement authorities or court order
                                    requesting or directing us to disclose the identity of anyone submitting material in
                                    breach of clause 4.2.</li>
                                <li>We will not be responsible, or liable to any third party, for the content or
                                    accuracy of any materials submitted by you.</li>
                                <li>We are not responsible for websites we link to.</li>
                                <li>This Site may include information and materials uploaded by other users of the site,
                                    including to bulletin boards and chat rooms. This information and these materials
                                    have not been verified or approved by us. The views
                                    expressed by other users on our site do not represent our views or values.</li>
                                <li>If you wish to complain about information and materials uploaded by other users
                                    please contact us on info@complygate.co.uk.
                                </li>
                            </ul>
                            <h5>Data Protection and Privacy</h5>
                            <ul>
                                <li>We use and process your personal information in accordance with our Privacy Policy.
                                </li>
                                <li>We comply with the Data Protection Legislation which for the purposes of this clause
                                    shall mean (i) the General Data Protection Regulation (EU) 2016/679) (GDPR) and any
                                    national implementing laws, regulations and secondary
                                    legislation, as amended or updated from time to time in the UK and (ii) any
                                    successor legislation to the GDPR or the Data Protection Act 1998.</li>
                            </ul>
                            <h5>Third Party Content and Links</h5>
                            <ul>
                                <li>We are not liable or responsible for the third party content on the Site and in the
                                    Services. Third party content includes, for example, material posted by other users
                                    of the Site and Services, job vacancy advertisements
                                    and display advertising.</li>
                                <li>Where the Site and Services contain links to other sites and resources which are
                                    provided by third parties, these links and resources are provided for your
                                    information only and you access them at your own risk. We are not
                                    liable or responsible for the content of third party sites or resources.</li>
                                <li>We have no control over the contents of those sites or resources.</li>
                                <li>Where our Site contains links to other sites and resources provided by third
                                    parties, these links are provided for your information only. Such links should not
                                    be interpreted as approval by us of those linked websites or
                                    information you may obtain from them.</li>
                            </ul>
                            <h5>Our Liability</h5>
                            <ul>
                                <li>The material contained on the Site and in the Services is for information purposes
                                    only and does not constitute advice. You should carry out your own check in respect
                                    of any information on the Site and in the Services and
                                    use your own judgement before doing or not doing anything on the basis of what you
                                    see. Unless expressly stated in writing by us or required by law, we give no
                                    warranties of any kind in relation to the materials on
                                    the Site and in the Services.</li>
                                <li>We are not liable for:</li>
                                <ul>
                                    <li>any action you may take as a result of relying on any information/materials
                                        provided on the Site and in the Services or for any loss or damage suffered by
                                        you as a result of you taking such action; or
                                    </li>
                                    <li>any dealings you have with third parties (e.g. other users or advertisers) that
                                        take place using or facilitated by the Site and Services; or</li>
                                    <li>any liability for losses which are not a foreseeable or likely consequence of
                                        (i) your use of the Site and Services, or (ii) a breach by us of these General
                                        Terms.</li>
                                </ul>
                                <li>We are not responsible if you cannot use the Site and Services properly or at all
                                    because of any event outside our control (e.g. the performance of your or our
                                    internet service provider, your browser, online breach or the
                                    internet.)
                                </li>
                                <li>The Site and Services rely in part on software to work. Whilst we monitor the Site
                                    and Services and try to fix bugs promptly, we do not guarantee that the Site and
                                    Services will be error free, available all the time and/or
                                    free from viruses.</li>
                                <li>In particular:</li>
                                <ul>
                                    <li>If you are a business user:</li>
                                    <ul>
                                        <li>We exclude all implied conditions, warranties, representations or other
                                            terms that may apply to our site or any content on it.</li>
                                        <li>We will not be liable to you for any loss or damage, whether in contract,
                                            tort (including negligence), breach of statutory duty, or otherwise, even if
                                            foreseeable, arising under or in connection with:
                                        </li>
                                        <ul>
                                            <li>use of, or inability to use, our site; or</li>
                                            <li>use of or reliance on any content displayed on our site.</li>
                                        </ul>
                                        <li>In particular, we will not be liable for:</li>
                                        <ul>
                                            <li>loss of profits, sales, business, or revenue;</li>
                                            <li>business interruption;</li>
                                            <li>loss of anticipated savings;</li>
                                            <li>loss of business opportunity, goodwill or reputation; or</li>
                                            <li>any indirect or consequential loss or damage.</li>
                                        </ul>
                                    </ul>
                                    <li>If you are a consumer user:</li>
                                    <ul>
                                        <li>Please note that we only provide our site for domestic and private use. You
                                            agree not to use our site for any commercial or business purposes, and we
                                            have no liability to you for any loss of profit, loss of business,
                                            business interruption, or loss of business opportunity.</li>
                                        <li>If defective digital content that we have supplied, damages a device or
                                            digital content belonging to you and this is caused by our failure to use
                                            reasonable care and skill, we will either repair the damage or pay
                                            you compensation. However, we will not be liable for damage that you could
                                            have avoided by following our advice to apply an update offered to you free
                                            of charge or for damage that was caused by you failing to
                                            correctly follow installation instructions or to have in place the minimum
                                            system requirements advised by us.</li>
                                    </ul>
                                </ul>
                            </ul>

                            <h5>How we may use your personal information</h5>
                            <p>We will only use your personal information as set out in our <a target="_blank"
                                    href="https://www.complygate.co.uk/terms-of-use"> Privacy
                                    Policy
                                </a>.</p>

                            <ul>
                                <li>Nothing in these General Terms affects any liability which we may have for death or
                                    personal injury arising from our negligence, fraud or any other liability which
                                    cannot be excluded or limited by law.
                                </li>
                            </ul>
                            <p>We are not responsible for viruses and you must not introduce them.</p>
                            <p>
                                We do not guarantee that our Site will be secure or free from bugs or viruses.</p>
                            <p>
                                You are responsible for configuring your information technology, computer programmes and
                                platform to access our Site. You should use your own virus protection software.</p>
                            <p>
                                You must not misuse our Site by knowingly introducing viruses, trojans, worms, logic
                                bombs or other material that is malicious or technologically harmful. You must not
                                attempt to gain unauthorised access to our Site, the server on which our Site is stored
                                or any server, computer or database connected to our Site. You must not attack our Site
                                via a denial-of-service attack or a distributed denial-of service attack. By breaching
                                this provision, you would commit a criminal
                                offence under the Computer Misuse Act 1990. We will report any such breach to the
                                relevant law enforcement authorities and we will co-operate with those authorities by
                                disclosing your identity to them. In the event of such
                                a breach, your right to use our Site will cease immediately.
                            </p>
                            <h5>Validity of these General Terms</h5>
                            <p>If any part or provision of these General Terms is found to be unlawful or unenforceable,
                                this shall not affect the validity of any other part or provision.</p>
                            <h5>Applicable Law and Jurisdiction</h5>
                            <p><strong>Which country's laws apply to any disputes?</strong></p>
                            <p>
                                If you are a consumer, please note that these terms of use, their subject matter and
                                their formation, are governed by English law. You and we both agree that the courts of
                                England and Wales will have exclusive jurisdiction except that if you are a resident
                                of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are
                                resident of Scotland, you may also bring proceedings in Scotland.</p>
                            <p>
                                If you are a business, these terms of use, their subject matter and their formation (and
                                any non-contractual disputes or claims) are governed by English law. We both agree to
                                the exclusive jurisdiction of the courts of England and Wales.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</jw-modal>

<jw-modal id="custom-license-1" role="dialog" class="modal">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="popupLabel">ComplyGate License Agreement</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                    (click)="closeModal('custom-license-1');">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h5>Conditions of Use:</h5>
                            <p> <strong>Complygate Licence Agreement:</strong></p>
                            <p>Please read this Licence Agreement carefully before using the Complygate software.</p>
                            <p>
                                <u>BY USING THIS SOFTWARE, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS LICENCE AGREEMENT,
                                    THAT YOU
                                    UNDERSTAND
                                    IT,
                                    AND THAT YOU AGREE TO BE BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THE TERMS AND
                                    CONDITIONS OF THIS
                                    LICENCE
                                    AGREEMENT, YOU MUST DECLINE TO USE THIS SOFTWARE. THE PURCHASE OF A LICENCE IS
                                    REQUIRED FOR USE OF THE
                                    SOFTWARE AND USE WITHOUT PURCHASE OF A LICENCE IS ILLEGAL. COMPLYGATE LIMITED WILL
                                    PROSECUTE WITHIN
                                    THE
                                    FULL
                                    MEANS OF THE LAW ANY BREACH OF THIS LICENCE AGREEMENT.</u>
                            </p>

                            <style>
                                .dnu li {
                                    list-style: decimal;
                                }
                            </style>

                            <ul class="dnu">
                                <li>&ldquo;The software&rdquo; means ANY Complygate software published and owned by
                                    Complygate Limited.
                                </li>
                                <li>&ldquo;Documentation&rdquo; means any material supplied as part of the software or
                                    with the software or any other material supplied or owned by Complygate Limited.
                                </li>
                                <li>&ldquo;You&rdquo; means the end user of the software, having purchased a relevant
                                    software key, licence or subscription.
                                </li>
                                <li>&ldquo;Licence&rdquo; means a licence of use granted at the discretion of Complygate
                                    Limited.</li>
                                <li>&ldquo;Commencement period&rdquo; means the period for which you hold a valid
                                    licence starting with the date on which the licence was issued.</li>
                                <li>&ldquo;Complygate Limited&rdquo; means Complygate Limited Company Registration
                                    Number 11382934 whose registered office is at 11 Kingfisher Business Park, Arthur
                                    Street, Redditch, Worcestershire, United Kingdom, B98 8LG
                                    who are the registered Owner of the Software. </li>


                                <li>&ldquo;Owner&rdquo; means Complygate Limited.</li>
                            </ul>

                            <h5>1. Grant of Licence</h5>

                            <p>For Registered Users Upon agreeing to these terms Complygate Limited, publishers of this
                                Software and Documentation, grants you a non-exclusive, non-transferable Licence to use
                                the programme with which this licence is distributed
                                for the period of 24 months, for a fee which is published on our website
                                (www.complygate.co.uk) including any documentation files accompanying the Software,
                                through an internet browser to support up to the number of simultaneous
                                users for which you have paid the Licence fee, provided that: (i) the Software is NOT
                                modified; (ii) all copyright notices are maintained on the Software; and (iii) you agree
                                to be bound by the terms of this Licence Agreement.
                                The Software and Documentation shall be used only by you, only for your own personal or
                                internal business use or for the benefit of any other person or entity.
                            </p>
                            <p>
                                <u>THE SOFTWARE MUST NOT BE USED FOR ANY COMMERCIAL ACTIVITY FOR WHICH A FINANCIAL OR
                                    OTHER GAIN IS MADE
                                    WITHOUT THE EXPRESS WRITTEN PERMISSION OF COMPLYGATE LIMITED.</u>
                            </p>

                            <h5>2. Ownership</h5>

                            <p>You have no ownership rights in the Software and Documentation. You have a licence to use
                                the Software and Documentation only, as long as this Licence Agreement remains in full
                                force and effect. Ownership of the Software, Documentation
                                and all intellectual property rights therein and appertaining to the Software, shall
                                remain at all times the property of Complygate Limited. Any other use of the Software by
                                any person, business, corporation, government
                                organisation or any other entity is strictly forbidden and is a breach of this Licence
                                Agreement.
                            </p>

                            <h5>3. Copyright and Intellectual Property</h5>

                            <p>You acknowledge that all Intellectual Property Rights in the Software and any maintenance
                                releases belong and shall belong to the Owner or the relevant third-party owners (as the
                                case may be), and You shall have no rights in
                                or to the Software other than the right to use it in accordance with the terms of this
                                Licence.
                            </p>
                            <p>
                                <u>Copyright © 2018 Complygate Limited</u>
                            </p>
                            <p>
                                The Software and Documentation contains material that is protected by Copyright. All
                                rights granted to you herein are expressly reserved by Complygate Limited. You may not
                                remove any proprietary notice of Complygate Limited from any copy of the Software
                                or Documentation.</p>

                            <p>(a) Ownership of copyright </p>

                            <p>We own the copyright in: (i) this website; and (ii) the material on this website
                                (including, without limitation, the text, computer code, artwork, photographs, images,
                                music, audio material, video material and audio-visual
                                material on this website).</p>

                            <p>(b) Copyright licence</p>

                            <p>We grant to you a non-exclusive revocable licence to: (a) view this website and the
                                material on this website on a computer or mobile device via a web browser;</p>
                            <p>
                                (i) copy and store this website and the material on this website in your web browser
                                cache memory; and (ii) print pages from this website for your own personal and
                                non-commercial use.</p>
                            <p>
                                We do not grant you any other rights in relation to this website or the material on this
                                website. In other words, all other rights are reserved. For the avoidance of doubt, you
                                must not adapt, edit, change, transform, publish, republish, distribute, redistribute,
                                broadcast, rebroadcast, or show or play in public this website or the material on this
                                website (in any form or media) without our prior written permission.</p>

                            <p>(c) Data mining</p>

                            <p>The automated and/or systematic collection of data from this website is prohibited.</p>

                            <li>(d) Permissions</li>

                            <p>You may request permission to use the copyright materials on this website by writing to
                                info@complygate.co.uk
                            </p>

                            <p>(e) Enforcement of copyright</p>

                            <p>We take the protection of our copyright very seriously. If we discover that you have used
                                our copyright materials in contravention of the licence above, we may bring legal
                                proceedings against you, seeking monetary damages and/or
                                an injunction to stop you using those materials. You could also be ordered to pay legal
                                costs.
                            </p>
                            <p>
                                If you become aware of any use of our copyright materials that contravenes or may
                                contravene the licence above, please report this by email to <a
                                    href="mailto:info@complygate.co.uk">info@complygate.co.uk</a>
                            </p>

                            <p>(f) Infringing material</p>

                            <p>If you become aware of any material on our website that you believe infringes your or any
                                other person's copyright, please report this by email to <a
                                    href="mailto:info@complygate.co.uk">info@complygate.co.uk</a>
                            </p>

                            <h5>4. Restrictions</h5>

                            <p>You may not publish, display, disclose, rent, lease, modify, loan, distribute, or create
                                derivative works based on the Software or Documentation or any part thereof. You may not
                                reverse engineer, decompile, translate, adapt,
                                or disassemble the Software and Documentation, nor shall you attempt to create the
                                source code from the object code for the Software. You may not transmit the Software
                                over any network or between any devices, although you
                                may use the Software to make such transmissions of other materials.</p>

                            <h5>5. Confidentiality</h5>

                            <p>You acknowledge that the Software and Documentation contains proprietary trade secrets of
                                Complygate Limited and you hereby agree to maintain the confidentiality of the Software
                                using at least as great a degree of care as you
                                use to maintain the confidentiality of your own most confidential information. You agree
                                to reasonably communicate the terms and conditions of this Software Licence Agreement to
                                those persons employed by you who come into
                                contact with the Software and Documentation, and to use reasonable best efforts to
                                ensure their compliance with such terms and conditions, including, without limitation,
                                not knowingly permitting such persons to use any
                                portion of the Software for the purpose of deriving the source code.</p>

                            <h5>6. Limited Warranty</h5>

                            <p>Complygate Limited warrants for a period of thirty (30) days after purchase that the
                                software will operate substantially in accordance with the documentation. Should the
                                software not so operate, your exclusive remedy, and Complygate
                                Limited&rsquo;s sole obligation under this warranty, shall be, at Complygate
                                Limited&rsquo;s sole discretion, correction of the defect or refund of the purchase
                                price paid for the software. Any use by you of the software
                                is at your own risk. This limited warranty is the only warranty provided by Complygate
                                Limited regarding the software. Except for the limited warranty above, the software is
                                provided &quot;as is&quot; to the maximum extent
                                permitted by law. Complygate Limited disclaims all other warranties of any kind, either
                                expressed or implied, including, without limitation, implied warranties of
                                merchantability and fitness for a particular purpose. Complygate
                                Limited does not warrant that the functions and information contained in the Software
                                and Documentation will meet any requirements or needs you may have, or that the software
                                will operate error free, or in an uninterrupted
                                fashion, or that any defects or errors in the software will be corrected, or that the
                                software is compatible with any particular platform. Some jurisdictions do not allow the
                                waiver or exclusion of implied warranties so
                                they may not apply to you.
                            </p>

                            <h5>7. Limitation of Liability</h5>

                            <p>In no event will Complygate Limited be liable to you or any third party for any
                                incidental or consequential damages (including, without limitation, indirect, special,
                                punitive, or exemplary damages for loss of business, loss
                                of profits, business interruption, or loss of business information) arising out of the
                                use of or inability to use the software, or for any claim by any other party, even if
                                Complygate Limited has been advised of the possibility
                                of such damages. Complygate Limited aggregate liability with respect to its obligations
                                under this agreement or otherwise with respect to the software and documentation or
                                otherwise shall not exceed the amount of the Licence
                                fee paid by you for the software and documentation.</p>

                            <h5>8.Export Restrictions</h5>

                            <p>This Licence agreement is expressly made subject to any laws, regulations, orders, or
                                other restrictions on the export from the United Kingdom of the software or information
                                about such software which may be imposed from time
                                to time by the government of the United Kingdom. You shall not export the software,
                                documentation, or information about the software and documentation without consent of
                                Complygate Limited and compliance with such laws,
                                regulations, orders, or other restrictions.</p>

                            <h5>9. Service charge</h5>

                            <p>You agree to pay such charges as Complygate Limited deems fit to impose as a service
                                charge for the provision of the Software and Documentation. The total sum of this amount
                                will be clearly stated to you before the commencement
                                period and may be paid in a single payment at the beginning of the commencement period
                                or on a monthly basis at the discretion of Complygate Limited. A licence to the software
                                and documentation will be granted upon payment
                                of the service charge or upon the acceptance of monthly payment agreement between you
                                and Complygate Limited. Any payment made for the purposes of a service charge is
                                non-refundable. If monthly payment is being made the
                                full amount is payable at any time upon request from Complygate Limited and may not be
                                discounted if service is terminated before the end of the commencement period. If such
                                is the case and this agreement is terminated
                                before the full service charge has been paid by you to Complygate Limited the full
                                amount of service charge is payable as if it were paid in full at the beginning of the
                                commencement period immediately.
                            </p>

                            <h5>10. Termination</h5>

                            <p>This Licence Agreement is effective until it is terminated. You may terminate this
                                Licence Agreement at any time by returning to Complygate Limited all copies of the
                                Software and Documentation in your possession or under your
                                control. If you decide to terminate this agreement you will be liable for any service
                                charge outstanding in respect of a commencement period.</p>
                            <p>
                                Complygate Limited may terminate this Licence Agreement for any reason, with immediate
                                effect by giving you notice in writing including, but not limited to, if Complygate
                                Limited finds that you have violated any of the terms of this Licence Agreement.
                                Upon notification of termination, you agree to destroy or return to Complygate Limited
                                all copies of the Software and Documentation and to certify in writing that all known
                                copies, including backup copies, have been destroyed.
                                All provisions relating to confidentiality, proprietary rights, and non-disclosure shall
                                survive the termination of this Software Licence Agreement. If Complygate Limited decide
                                to terminate this agreement due to the above
                                you will be liable for any service charge outstanding in respect of a commencement
                                period.</p>

                            <h5>11. Renewal</h5>

                            <p>Your licence to this service will last for an initial term of twenty-four (24) months and
                                will then automatically terminate unless you give us notice in writing at least thirty
                                (30) days before the end of the term in which
                                case it will continue for a further period of six months. Thereafter the service will
                                last for periods of six months and will automatically terminate unless you give us
                                notice in writing at least 30 days before the end
                                of each six month period in which case the service will continue for another six month
                                term.
                            </p>

                            <h5>12. Disclaimer</h5>

                            <p>The information and material included in this software by no means conveys legal advice
                                or gives specific requirements. Any action taken by you or any other party in light of
                                information provided in this software or by Complygate
                                Limited is entirely at your discretion and Complygate Limited accepts no responsibility
                                for any loss or damage liable to you or any third party for any incidental or
                                consequential damages (including, without limitation,
                                indirect, special, punitive, or exemplary damages for loss of business, loss of profits,
                                business interruption, or loss of business information) arising out of the use of the
                                software.</p>

                            <h5>13. General</h5>

                            <p>This Licence Agreement shall be construed, interpreted and governed by the laws of
                                England and Wales without regard to conflicts of law provisions thereof. The exclusive
                                forum for any disputes arising out of or relating to
                                this Licence Agreement shall be the courts of England and Wales unless you are resident
                                in Northern Ireland in which case you may commence suit in the courts of Northern
                                Ireland or if you are resident in Scotland in which
                                case you may commence suit in the courts of Scotland. This Licence Agreement shall be
                                the entire Agreement between the parties hereto. Any waiver or modification of this
                                Licence Agreement shall only be effective if it is
                                in writing and signed by both parties hereto. If any part of this Licence Agreement is
                                found invalid or unenforceable by a court of competent jurisdiction, the remainder of
                                this Licence Agreement shall be interpreted so
                                as to reasonably affect the intention of the parties.</p>
                        </div>
                        <s /div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
