<app-header>
</app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <!-- <a [routerLink]="['/admincreditsafe']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to AML, PEPs & Sanction Dashboard</a> -->
          
            <div class="clearfix"></div>
            
            <div class="tabs__content">

                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="row row-sm topheader">                            
                            <div class="col-lg-5 col-md-7 col-sm-12">
                                <div>
                                    <app-checkcountshow></app-checkcountshow>    
                                </div>                                
                            </div>
                            <div class="col-lg-7 col-md-5 text-right">
                              <div class="dflexflex mb-mm justify-content-end align-items-center">
                                <button type="button" class="btn com_btn btn-success" (click)="amllogredirect()"><span class="lnr lnr-history"></span> View AML, PEPs & Sanction History</button>                               
                                                          
                              </div>                             
                          </div>
                            <!-- <div class="col-lg-7 text-right">
                                <div class="dflexflex mb-mm justify-content-end align-items-center">
                                    <a class="card_box_lit" [routerLink]="['/check-personal-aml-peps-sanction']">
                                        <button type="button" class="btn com_btn btn-success">Personal AML, PEPs & Sanction
                                        </button>
                                    </a>                     
                                </div>                             
                            </div>  -->
                        </div>
                        <div class="row">
                            <div class="col-sm-12 aligncenter mb-3 mt-3">
                                <h5 class="hedingcolor">Business Details</h5>
                             
                             </div>                               
                        </div>
                        
                        <div class="personal_form" *ngIf="result==false">
                            <form [formGroup]="bussinessformGroup" #bussinessForm="ngForm" class="s12 white">

                                <input type="hidden" formControlName="Threshold" name="Threshold" id="Threshold" class="form-control" aria-describedby="emailHelp" placeholder="Business Name" [ngClass]="{ 'is-invalid': submitted && f.Threshold.errors }" [(ngModel)]="businessDetail.Threshold"
                                />
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Business Name*</label>
                                            <input type="text" formControlName="BusinessName" name="BusinessName" id="BusinessName" class="form-control" aria-describedby="emailHelp" placeholder="Business Name" [ngClass]="{ 'is-invalid': submitted && f.BusinessName.errors }" [(ngModel)]="businessDetail.BusinessName">
                                            <div *ngIf="submitted && f.BusinessName.errors" class="invalid-feedback">
                                                <div *ngIf="f.BusinessName.errors.required">Please enter business name</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Town/City</label>
                                            <input type="text" formControlName="City" name="City" id="City" class="form-control" aria-describedby="emailHelp" placeholder="City Name" [ngClass]="{ 'is-invalid': submitted && f.City.errors }" [(ngModel)]="businessDetail.City">

                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">County/State</label>
                                            <input type="text" formControlName="County" name="County" id="County" class="form-control" aria-describedby="emailHelp" placeholder="County / State" [ngClass]="{ 'is-invalid': submitted && f.County.errors }" [(ngModel)]="businessDetail.County">

                                            <!-- <input type="text" formControlName="province" name="province" id="province" class="form-control"   aria-describedby="emailHelp" placeholder="Country / State"  [ngClass]="{ 'is-invalid': submitted && f.province.errors }" [(ngModel)]="businessDetail.province"> -->

                                        </div>
                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Post / Zip Code</label>
                                            <input type="text" formControlName="Postcode" name="Postcode" id="Postcode" class="form-control" aria-describedby="emailHelp" placeholder="Post / Zip Code" [ngClass]="{ 'is-invalid': submitted && f.Postcode.errors }" [(ngModel)]="businessDetail.Postcode">

                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Country*</label>
                                            <select class="form-control" formControlName="Country" [ngClass]="{ 'is-invalid': submitted && f.Country.errors }" [(ngModel)]="businessDetail.Country" name="Country" id="Country" placeholder="Select Country">
                                                <option [value]="defaultVal">Select Country</option>
                                                <option *ngFor="let cmp of countrylist" [value]="cmp.name">
                                                    {{ cmp.name }} </option>
                                            </select>
                                            <div *ngIf="submitted && f.Country.errors" class="invalid-feedback">
                                                <div *ngIf="f.Country.errors.required">Please select country</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address</label>
                                            <input type="text" formControlName="Address" name="Address" id="Address" class="form-control" aria-describedby="emailHelp" placeholder="Address" [ngClass]="{ 'is-invalid': submitted && f.Address.errors }" [(ngModel)]="businessDetail.Address">

                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                    <!-- <div class="col-sm-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Address 2</label>
                                        <input type="text" formControlName="houseNo" name="houseNo" id="houseNo" class="form-control"   aria-describedby="emailHelp" placeholder="Address 2"  [ngClass]="{ 'is-invalid': submitted && f.houseNo.errors }" [(ngModel)]="businessDetail.houseNo">

                                    </div>
                                </div> -->

                                </div>
                                <div class="row radio_section">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1"><b>Datasets to search</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="checkbox_credit">Select All
                                        <input type="checkbox" [checked]="checkedAll" (change)="selectall($event.target.checked)" >
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit">PEP
                                        <input type="checkbox" formControlName="PEP" name="PEP" id="PEP" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.PEP.errors }" [(ngModel)]="businessDetail.PEP" (change)="selectallfalse($event.target.checked)">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Sanction - Current
                                        <input type="checkbox" formControlName="CurrentSanctions" name="CurrentSanctions" id="CurrentSanctions" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.CurrentSanctions.errors }"  (change)="selectallfalse($event.target.checked)" [(ngModel)]="businessDetail.CurrentSanctions" >
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Sanction - Previous
                                        <input type="checkbox" formControlName="PreviousSanctions" name="PreviousSanctions" id="PreviousSanctions" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.PreviousSanctions.errors }"  (change)="selectallfalse($event.target.checked)"  [(ngModel)]="businessDetail.PreviousSanctions">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Financial Regulator
                                        <input type="checkbox" formControlName="FinancialRegulator" name="FinancialRegulator" id="FinancialRegulator" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.FinancialRegulator.errors }"  (change)="selectallfalse($event.target.checked)"  [(ngModel)]="businessDetail.FinancialRegulator">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Law Enforcement
                                        <input type="checkbox" formControlName="LawEnforcement" name="LawEnforcement" id="LawEnforcement" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.LawEnforcement.errors }"  (change)="selectallfalse($event.target.checked)"  [(ngModel)]="businessDetail.LawEnforcement">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit">  Disqualified Director (UK Only)
                                        <input type="checkbox" formControlName="DisqualifiedDirector" name="DisqualifiedDirector" id="DisqualifiedDirector" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.DisqualifiedDirector.errors }"  (change)="selectallfalse($event.target.checked)"  [(ngModel)]="businessDetail.DisqualifiedDirector">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit">   Insolvency (UK & Ireland)
                                        <input type="checkbox" formControlName="Insolvency" name="Insolvency" id="Insolvency" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.Insolvency.errors }"   (change)="selectallfalse($event.target.checked)" [(ngModel)]="businessDetail.Insolvency">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Adverse Media
                                        <input type="checkbox" formControlName="AdverseMedia" name="AdverseMedia" id="AdverseMedia" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.AdverseMedia.errors }"   (change)="selectallfalse($event.target.checked)" [(ngModel)]="businessDetail.AdverseMedia">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                </div>
                                <div class="row radio_section" *ngIf="error ==true">
                                    <div class="col-sm-12 text-right invalid-feedback">
                                        <label class="error" >Please select at least one dataset</label>
                                    </div>
                                </div>
                                
                                <hr/>
                                <div class="row">
                                    <div class="col-sm-6 text-left">
                                        <span *ngIf="lesssore">
                                            Sorry, You don't have any credits. Please <a [routerLink]="['/check-add-credit']">Click Here</a> to add more.
                                        </span>
                                    </div>
                                    
                                    <div class="col-sm-12 text-right">
                                        <input type="submit" class="btn com_btn btn-primary"  *ngIf="!lesssore" (click)="onSubmit(bussinessForm.value,'first')"  [disabled]="!disableBtn"  name="updateform" value="{{buttoninsert}}" />

                                        <!-- <a [routerLink]="['/buscreditsearchresult']" class="btn com_btn btn-primary"><i class="fa fa-search"> </i> Search</a> -->
                                        <button type="button" class="btn com_btn btn-secondary" (click)="clearpersonal()">Clear</button>
                                    </div>
                                </div>



                                
                                <jw-modal id="pdf-payment-confirmation" class="modal sendrequestcharge" role="dialog">

                                    <div role="document" class="formgroup">
                                 
                                        <div class="modal-content">

                                 
                                            <div class="modal-body text-center">
                                 
                                                <div class="container">
                                 
                                 
                                 
                                                    <div class="row">
                                 
                                                        <div class="col-lg-12 spaceset">
                                                            <div class="check">
                                                                <span class="lnr lnr-checkmark-circle"></span>
                                                            </div>
                                                            <!-- <p class="textinfoma">Per Click Charge <span class="charge">£5</span> </p> -->
                                                            <!-- <p class="text-center short">Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs</p> -->
                                                            <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
                                                            <p class="">{{errormsg}}</p>
                                 
                                                        </div>
                                                        <div class="text-center m-auto">
                                                            <button type="submit"  class="btn com_btn btn-primary" (click)="onSubmit(bussinessForm.value,'payment')"  [disabled]="!disableBtn" *ngIf="lesssore == false" name="updateform" value="{{buttoninsert}}" > Confirm</button>
                                                            <button type="submit"  class="btn com_btn btn-secondary"(click)="closeModal('pdf-payment-confirmation');" > Cancel</button>
                                                        </div>
                                                       
                                 
                                 
                                                    </div>
                                 
                                 
                                 
                                 
                                 
                                                </div>
                                 
                                            </div>
                                 
                                           
                                 
                                        </div>
                                 
                                    </div>
                                 
                                 </jw-modal>

                            </form>
                          
                        </div>





                        <div class="credit_data_show" *ngIf="result==true">
                            <div class="job_list_section visa_request_section" *ngFor="let company of searchresult.matches">

                                <div class="job_detils_date width13">
                                    <p class="job_address">ID</p>
                                    <p class="ninumber text-primary">{{company.business.id}} </p>
                                </div>
                                <div class="job_detils_date width15">
                                    <div class="visa_user_details ">
                                        <p class="job_address">Business Name</p>
                                        <h4>{{ company.business.businessName}}</h4>
                                    </div>
                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address">Data Set</p>
                                    {{dataset(company.business)}}
                                    <p class="job_profile">{{datasetlist}}</p>
                                </div>
                                <div class="job_detils_date width33">
                                    <p class="job_address">Address</p>
                                    <p class="job_profile" *ngFor="let companyaddress of company.business.addresses;  let i=index ">
                                        <span *ngIf="i ==0">
                                        {{companyaddress.address1}}, {{ companyaddress.city }} {{ companyaddress.county }}- {{ companyaddress.postcode}}
                                    </span>
                                    </p>
                                </div>


                                <div class="job_detils_date aligncenter">
                                    <div id="container" class="green_text">
                                        <div class="dot"></div>
                                        <div class="pulse"></div>

                                    </div>
                                    <p class="job_profile green_text">{{ company.score}} </p>
                                </div>
                                <div class="job_detils_date width13">

                                    <p class="job_address">
                                        <button type="submit" class="btn btn-primary com_btn" (click)="openModal('bussiness-pdf-viewer-visa',company)"><i class="fa fa-file-image-o"></i></button>
                                        <a [routerLink]="['/completebusscreditdetails',company.id]" class="btn btn-danger com_btn"><i class="fa fa-address-card" aria-hidden="true"></i>
                                        </a>
                                    </p>
                                </div>
                            </div>



                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<jw-modal id="bussiness-pdf-viewer-visa" class="modal" role="dialog">
    <div role="document" class="formgroup" *ngIf="bussinessdetails">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{bussinessdetails.business.businessName}}</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('bussiness-pdf-viewer-visa');">
        <span aria-hidden="true">&times;</span>
      </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-2">
                            <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                        </div>
                        <div class="col-lg-10  d-flex">
                            <div class="category justify-content-between align-items-center text-center d-flex width100">
                                <div class="catog_box">
                                    <p>PEP</p>
                                    <span>9</span>
                                </div>

                                <div class="catog_box">
                                    <p>Adverse Media</p>
                                    <span>11</span>
                                </div>
                                <div class="catog_box">
                                    <p>IDV</p>
                                    <span>9</span>
                                </div>
                                <div class="catog_box">
                                    <p>Law Enforcement</p>
                                    <span>2</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <hr/>
                    <div class="all_detials">
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">
                                    <div class="catog_box width50">
                                        <p>Business Name</p>
                                        <span>{{bussinessdetails.business.businessName}}</span>
                                    </div>
                                    <div class="catog_box width50">
                                        <p>Alias</p>
                                        <p *ngFor="let companyaliases of bussinessdetails.person.aliases;">
                                            {{companyaliases.businessName}}
                                        </p>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box width50">
                                        <p>Telephone</p>
                                        <span>{{bussinessdetails.telephoneNumber}}</span>
                                    </div>
                                    <div class="catog_box width50">
                                        <p>Website </p>
                                        <span>{{bussinessdetails.website}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">


                                    <div class="catog_box width50">
                                        <p>Addresses</p>
                                        <p *ngFor="let companyaddress of bussinessdetails.business.addresses;">
                                            {{companyaddress.address1}}, {{ companyaddress.city }} {{ companyaddress.county }}- {{ companyaddress.postcode}}
                                        </p>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>

                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box">
                                        <span>Reference Id</span>
                                    </div>
                                    <div class="catog_box">
                                        <span>{{bussinessdetails.business.id }}</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary com_btn"> Remove from review list</button>
                <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</jw-modal>



    
<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
      <div class="modal-content">         
        <div class="modal-body">
          <div class="container text-center">
            <div class="info_mess">
              <div class="para">
                <h4 class="mb-2">No credits available</h4>
                <p class="mb-0 font20"> There are no credits available to perform this action. Please add more credits.</p>
              </div>                          
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <input type="button" class="btn btn-primary com_btn" name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
          <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
        </div>
      </div>
    </div>
  </jw-modal>