import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ViewChild,Inject  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PlanService } from '../../services/plan.service';
import { PaymentService } from '../../services/payment.service';
import { Plan } from '../../models/plan.model';
import { ModalService } from '../../_services/modal.service';
import { Router } from '@angular/router';
import { StripeScriptTag } from "stripe-angular";
import { StripeToken } from "stripe-angular";
import { AlertService } from '../../services/alert.service';
import { UserService } from '../../services/user.service';
import { AppGlobals } from '../../app.global';
import { Address } from '../../models/address/address.model';
import { CountryService } from '../../services/country.service';
import { AngleUnits } from 'aws-sdk/clients/groundstation';
import { DomSanitizer } from '@angular/platform-browser';
import { CompanyService } from '../../services/company.service';

@Component({
  selector: 'app-plandetails',
  templateUrl: './plandetails.component.html',
  styleUrls: ['./plandetails.component.css']
})
export class PlandetailsComponent implements OnInit {
  buttoninsert: any;
  Userid: any;
  data: any;
  planDetail = new Plan();
  planformGroup: FormGroup;
  planlist: any;
  submitted: any;
  totaluser: any;
  totalused: any;
  message: string;
  stptoken: any;
  planid: any;
  stripeplanid: any;
  addresslistDetail: any;
  address = new Address();
  addpopForm: FormGroup;
  cpnumber:any;
  ctype:any;
  paymentSubmit: any = 0;
  extraData: any;plantype:any;
  invalidError: any;
  countrylist:any;
  private publishableKey: string = AppGlobals.API_STRIPE;
  // private publishableKey:string = "pk_live_vE86NSZoPYH0MsJIdsYfQ8L6";

  paymentloading:any =false;
  paymentaut:any =true;
  iframepaymentautsrc:any ='';


  constructor(@Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private router: Router,
    private alerts: AlertService,
    private plan: PlanService,
    private country: CountryService, 
    private companyService: CompanyService,
    private userService: UserService,
    public StripeScriptTag: StripeScriptTag,
    public paymentService: PaymentService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.StripeScriptTag.setPublishableKey(this.publishableKey);
   
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }
    else {
      this.userService.checkaccesstokenbyid(userid).subscribe((data: any) => {
        if (data.status == false) {
         
        localStorage.removeItem('userToken');
        localStorage.removeItem('userid');
        localStorage.removeItem('Name');
        localStorage.removeItem('isline');
        localStorage.removeItem('planid');
        localStorage.removeItem('ctype');
        localStorage.removeItem('isemail');
        localStorage.removeItem('ismobile');
        localStorage.removeItem('isadmin');
        localStorage.removeItem('ispermission');
        localStorage.removeItem('logoname');
        localStorage.removeItem('struser');
        localStorage.removeItem('menu');
          this.router.navigate(['/login']);
        }     
      });
    }

    

    this.cpnumber='';
    this.plantype =0;
    this.planformGroup = this.formBuilder.group({
      coupnsnumber: ['', Validators.required]
    });

    this.addpopForm = this.formBuilder.group({  
      cardname: ['', Validators.required],   
      address1: ['', Validators.required],
      address2: [''],
      cityname: ['', Validators.compose([Validators.required,])],   
      statename: ['', Validators.required],
      pincode: ['', Validators.required],     
      stateid: [''], 
      taxid: [''],       
      countryid: ['', Validators.compose([Validators.required, Validators.min(1)])]
    });

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    
    this.addresslistDetail = new Address();
    this.addresslistDetail.countryid = "70";
    
    

    var isemail = localStorage.getItem('isemail');
    var ismobile = localStorage.getItem('ismobile');      
    var struser = localStorage.getItem('struser');      
    this.ctype = localStorage.getItem('ctype');
    var planid =  localStorage.getItem('planid');      

    if (isemail=='false' && ismobile == 'false')
    {            
       this.router.navigate(['/otp']);
    }
    else if (planid != '0' && this.ctype == '0' ) 
    {
      this.router.navigate(['dashboard']);
    }
    else if (struser == '' && this.ctype == '1' ) 
    {
      this.router.navigate(['payment-confirmation']);
    }
    else if(this.ctype == '2' &&  struser != '' )
    {
      this.router.navigate(['check-dashboard']);
    }
    else if (struser != '' && this.ctype == '3' ) 
    {
      this.router.navigate(['reference-dashboard']);
    }
    else if (planid != '0' ) 
    {
      this.router.navigate(['dashboard']);
    } 
    else if (struser == '' && this.ctype == '2' ) 
    {
      this.router.navigate(['payment-confirmation']);
    }


    // var isemail = localStorage.getItem('isemail');
    // var planid = localStorage.getItem('planid');

    // this.ctype = localStorage.getItem('ctype');

    // console.log('plan id', planid);

    // if(!!planid)    
    // {
    //   planid = '0';
    // }


    // if (isemail == null || isemail == '0' || isemail == 'false') {
    //   this.router.navigate(['/otp']);
    // }    
    // else if (planid != null && planid != '0' && this.ctype == '0') {
    //   this.router.navigate(['/dashboard']);
    // }
    // else if (planid != null && planid != '0' && this.ctype == '2') {
    //   console.log('plan id 1', planid);
    //    this.router.navigate(['/check-dashboard']);
    // }
    // else if(this.ctype == '1' )
    // {
    //   console.log('plan id 2', planid);

    //    this.router.navigate(['/check-dashboard']);
    // }




    const type = { "plantype": '0','checktype': '0' };
    if(this.ctype == 2){
      type.checktype = '1';
    }
    this.plan.getplanlist(type).subscribe((data: any) => {    
      this.planlist = data;
    });
  }

  
  get g() { return this.addpopForm.controls; }

  toggle(){
    
  }
  get f() { return this.planformGroup.controls; }

  onSubmit(settingexpensetypedata: any, id: string) 
  {

    this.submitted = true;
    if (this.planformGroup.invalid) {
      return;
    }

    this.plan.checkcounpons(settingexpensetypedata).subscribe((data: any) => {      
      if (data.success == 'success') {
        if (data.lenght != 0) {
          this.totaluser = data.totaluser;
          this.totalused = data.totalused;
          if ((this.totaluser - this.totalused) != 0) {
            this.message = "Coupons applied successfully";
            this.cpnumber = settingexpensetypedata.coupnsnumber;
            var db = { "couponsnumber": settingexpensetypedata.coupnsnumber, 'plantype': this.plantype ,'checktype': '0' };
            if(this.ctype == 2){
              db.checktype = '1';
            }

            this.plan.getplanlistwithcoupons(db).subscribe((data4: any) => {
              this.planlist = data4;
              this.modalService.close(id);
            });
          }
          else {this.cpnumber='';
            this.message = "Coupons limit expired";
          }
        }
      } else {
        
        this.cpnumber='';
        // const type = { "plantype": this.plantype  };

        
    const type = { "plantype": '0','checktype': '0' };
    if(this.ctype == 2){
      type.checktype = '1';
    }

      this.plan.getplanlist(type).subscribe((data: any) => {
        this.planlist = data;
      });
        this.message = "Invalid coupon details. Please try another coupon.";
        this.planformGroup.reset();
        this.submitted = false;
      }
    });
  }

  onStripeInvalid( error:Error ){
    // console.log('Validation Error', error)
  }


  removecoupons()
  {
    this.cpnumber='';
    this.message ='';this.planformGroup.reset();
    // const type = { "plantype": this.plantype  };

    
    const type = { "plantype": '0','checktype': '0' };
    if(this.ctype == 2){
      type.checktype = '1';
    }

      this.plan.getplanlist(type).subscribe((data: any) => {
        this.planlist = data;
      });
      this.modalService.close('custom-coup-1');
  }

  purchasepayment(formvalue:any, id: any)
  {


    this.submitted = true;  
    if (this.addpopForm.invalid) 
    {      
      return;
    }

    localStorage.setItem('tempplanid', this.planid);


    this.stptoken = '';
    var planupdate = { "planid": this.stripeplanid };
    // console.log('Stripe token', token);
    var data = {
      "token": '',
      "cmonth": '',
      "cyear": '',
      "cardnumber": '',
      "tokenid": '',
      "cardname": '',
      "country": '',
      "cstatus": "1",
      "planid": this.stripeplanid,
      "address1": formvalue.address1,
      "address2": formvalue.address2,
      "cityname": formvalue.cityname,
      "statename": formvalue.statename,
      "countryid": formvalue.countryid,
      "postcode": formvalue.pincode,
      "taxid": formvalue.taxid,      
      "cardholdername": formvalue.cardname,
      "coupons": this.cpnumber
    };

    if(this.paymentSubmit == 0){

    this.paymentService.createCustomer(data).subscribe((data: any) => {

      localStorage.setItem('tempplanid', this.planid);
      
      this.document.location.href = data.url;
    });
  }
  this.paymentSubmit = 1;





  }




  setStripeToken(token: StripeToken,formvalue:any, id: any) {




    this.submitted = true;  
    if (this.addpopForm.invalid) 
    {      
      return;
    }

    localStorage.setItem('tempplanid', this.planid);


    this.stptoken = token.id;
    var planupdate = { "planid": this.stripeplanid };
    // console.log('Stripe token', token);
    var data = {
      "token": token.id,
      "cmonth": token.card.exp_month,
      "cyear": token.card.exp_year,
      "cardnumber": token.card.last4,
      "tokenid": token.card.id,
      "cardname": token.card.brand,
      "country": token.card.country,
      "cstatus": "1",
      "planid": this.stripeplanid,
      "address1": formvalue.address1,
      "address2": formvalue.address2,
      "cityname": formvalue.cityname,
      "statename": formvalue.statename,
      "countryid": formvalue.countryid,
      "postcode": formvalue.pincode,
      "taxid": formvalue.taxid,      
      "cardholdername": formvalue.cardname,
      "coupons": this.cpnumber
    };

    if(this.paymentSubmit == 0){

    this.paymentService.createCustomer(data).subscribe((data: any) => {

      localStorage.setItem('tempplanid', this.planid);
      
      this.document.location.href = data.url;
      // this.router.navigate([data.url]);


      // console.log(data);
      // if (data.status == "success") {
      //   this.alerts.success("Plan successfully created", true);
      //   localStorage.setItem('planid', this.planid);
      //   localStorage.setItem('struser', data.struser);
      //   localStorage.setItem('isemail', "true");
      //   this.router.navigate(['/successful-payment']);
      //   this.modalService.close(id);
      // }
      // else {

        
      //   console.log(data.url);
      //   this.iframepaymentautsrc = this.getSafeUrl(data.url);
      //   this.modalService.open('custom-payment-25');

      //   var xy=null;
      //   var refreshId =  setInterval(() => {
      //     var data = { 'invoiceid': data.paymentint };
      //     this.companyService.getpaymentintent(data).subscribe((invoiceData: any) => {
      //       console.log(invoiceData);
      //       console.log(invoiceData.status);
      //       if(invoiceData.status != 'requires_source_action' && invoiceData.status != 'processing')
      //         {
      //           if(invoiceData.status =='succeeded')
      //           {
                  
      //             this.modalService.close('custom-payment-1'); 
      //             this.modalService.close('custom-payment-2'); 
      //             this.paymentSubmit=0;
      //             this.paymentloading = false;
                 
                

      //             clearInterval(refreshId);
      //           }


              
      //       }                      
      //     });       
      //   }, 5000);  






      //   this.paymentSubmit = 0;
      //   this.alerts.error("something went wrong "+ data.message, true);




      // }   
    });
  }
  this.paymentSubmit = 1;




  }

  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  selectPlanType(event) {
    // console.log(event);

    this.plantype =event;


    if(this.cpnumber != ''){
    var dd = { 'coupnsnumber': this.cpnumber }
    this.plan.checkcounpons(dd).subscribe((data: any) => {      
      if (data.success == 'success') {
        if (data.lenght != 0) {
          this.totaluser = data.totaluser;
          this.totalused = data.totalused;
          if ((this.totaluser - this.totalused) != 0) {
            this.message = "Coupons applied successfully";

           // this.cpnumber = settingexpensetypedata.coupnsnumber;
            
            var db = { "couponsnumber": this.cpnumber, 'plantype': this.plantype  };
            this.plan.getplanlistwithcoupons(db).subscribe((data4: any) => {
              this.planlist = data4;
             
            });
          }
         
        }
      }
    });
  }else{
    const type = { "plantype": event };
    this.plan.getplanlist(type).subscribe((data: any) => {
      this.planlist = data;
    });
  }


    
  }

  onStripeError(error: Error) {
    console.error('Stripe error', error)
  }

  updateyourplan(stripeplanid: string, id: string,planid: number) {
    // console.log(planid);
    this.stripeplanid =stripeplanid;
    
    this.planid = planid;

    this.modalService.open(id);
  }

  openModal(id: string) {
    this.buttoninsert = "Apply Coupons";
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  logout() {
    if (confirm("Are you sure you want to sign out?")) {
      this.userService.logout().subscribe((data: any) => {
     
      localStorage.removeItem('userToken');
      localStorage.removeItem('userid');
      localStorage.removeItem('Name');
      localStorage.removeItem('isline');
      localStorage.removeItem('planid');
      localStorage.removeItem('ctype');
      localStorage.removeItem('isemail');
      localStorage.removeItem('ismobile');
      localStorage.removeItem('isadmin');
      localStorage.removeItem('ispermission');
      localStorage.removeItem('logoname');
      localStorage.removeItem('struser');
      localStorage.removeItem('menu');
        this.router.navigate(['/login']);
      });
    }
  }
}