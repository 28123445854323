"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var user_service_1 = require("../../services/user.service");
var user_model_1 = require("../../models/user.model");
var forms_1 = require("@angular/forms");
var customer_service_1 = require("../../services/customer.service");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var alert_service_1 = require("../../services/alert.service");
var ForgotpasswordComponent = /** @class */ (function () {
    // confirmerror: string;
    function ForgotpasswordComponent(formBuilder, csdata, userService, router, activatedRoute, alerts) {
        this.formBuilder = formBuilder;
        this.csdata = csdata;
        this.userService = userService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.alerts = alerts;
        this.user = new user_model_1.User();
        this.isLoginError = false;
        this.submitted = false;
        this.msg = null;
        this.access_token = '';
    }
    ForgotpasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.forgotPassword = this.formBuilder.group({
            newPassword: [null, [forms_1.Validators.required, forms_1.Validators.minLength(8), forms_1.Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
            confirmPassword: [null, [forms_1.Validators.required, forms_1.Validators.minLength(8), forms_1.Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]]
        }, { validator: this.checkPasswords });
        this.accesstoken = this.activatedRoute.snapshot.paramMap.get("access_token");
        this.activatedRoute.queryParams.subscribe(function (params) {
            _this.accesstoken = params['access_token'];
            _this.userService.checkaccesstoken(_this.accesstoken).subscribe(function (data) {
                console.log(_this.accesstoken);
                if (data == null) {
                    _this.alerts.error('Invalid token details', true);
                    // console.log(this.accesstoken);
                    _this.checktoken = false;
                }
                else {
                    _this.checktoken = true;
                }
            });
            //  this.page = +params['page'] || 0;
        });
        // this.alerts.success(this.accesstoken);
        //  console.log(this.accesstoken +'rahul');
    };
    ForgotpasswordComponent.prototype.checkPasswords = function (group) {
        var pass = group.controls.newPassword.value;
        var confirmPass = group.controls.confirmPassword.value;
        return pass === confirmPass ? null : { notSame: true };
    };
    Object.defineProperty(ForgotpasswordComponent.prototype, "f", {
        get: function () { return this.forgotPassword.controls; },
        enumerable: true,
        configurable: true
    });
    ForgotpasswordComponent.prototype.forgotpassword = function (value2) {
        var _this = this;
        this.submitted = true;
        if (this.forgotPassword.invalid) {
            return;
        }
        var newPassword = value2.newPassword;
        this.access_token = this.activatedRoute.snapshot.paramMap.get("access_token");
        this.activatedRoute.queryParams.subscribe(function (params) {
            _this.access_token = params['access_token'];
            _this.userService.forgotPassword(newPassword, _this.access_token).subscribe(function (data) {
                if (data.success == 'true') {
                    // this.csdata.updatepassword().subscribe((data2: any) => {
                    _this.alerts.success('Password has been reset successfully', true);
                    _this.router.navigate(['/login']);
                    _this.msg = 'Password has been reset successfully';
                }
                else {
                    _this.alerts.error('Unable to update password', true);
                    // this.router.navigate(['/login']);
                    _this.msg = 'Unable to update password';
                }
                // });
            }, function (err) {
                _this.isLoginError = true;
                _this.alerts.error('Unable to update password' + err, true);
                // this.error = 'Unable to Update Password?';
            });
        });
    };
    return ForgotpasswordComponent;
}());
exports.ForgotpasswordComponent = ForgotpasswordComponent;
