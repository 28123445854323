"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CanvasJS = require("./../canvasjs.min");
var ChartexpampleComponent = /** @class */ (function () {
    function ChartexpampleComponent() {
    }
    ChartexpampleComponent.prototype.ngOnInit = function () {
        // let chart7 = new CanvasJS.Chart("chartContainer7", {
        //   animationEnabled: true,
        //   title: {
        //     text: "Email Categories",
        //     horizontalAlign: "left"
        //   },
        //   data: [{
        //     type: "doughnut",
        //     dataPoints: [
        //       { y: 67, label: "Inbox" },
        //       { y: 28, label: "Archives" },
        //       { y: 10, label: "Labels" },
        //       { y: 7, label: "Drafts" },
        //       { y: 15, label: "Trash" },
        //       { y: 6, label: "Spam" }
        //     ]
        //   }]
        // });
        // chart7.render();
        // let chart = new CanvasJS.Chart("chartContainer", {
        //   animationEnabled: true,
        //   exportEnabled: true,
        //   title: {
        //     text: "Basic Column Chart in Angular"
        //   },
        //   data: [{
        //     type: "column",
        //     dataPoints: [
        //       { y: 71, label: "Apple" },
        //       { y: 55, label: "Mango" },
        //       { y: 50, label: "Orange" },
        //       { y: 65, label: "Banana" },
        //       { y: 95, label: "Pineapple" },
        //       { y: 68, label: "Pears" },
        //       { y: 28, label: "Grapes" },
        //       { y: 34, label: "Lychee" },
        //       { y: 14, label: "Jackfruit" }
        //     ]
        //   }]
        // });
        // chart.render();
        // let chart1 = new CanvasJS.Chart("chartContainer1", {
        //   theme: "light1",
        //   animationEnabled: true,
        //   exportEnabled: true,
        //   title: {
        //     text: "Monthly Expense"
        //   },
        //   data: [{
        //     type: "pie",
        //     showInLegend: true,
        //     toolTipContent: "<b>{name}</b>: ${y} (#percent%)",
        //     indexLabel: "{name} - #percent%",
        //     dataPoints: [
        //       { y: 450, name: "Food" },
        //       { y: 120, name: "Insurance" },
        //       { y: 300, name: "Traveling" },
        //       { y: 800, name: "Housing" },
        //       { y: 150, name: "Education" },
        //       { y: 150, name: "Shopping" },
        //       { y: 250, name: "Others" }
        //     ]
        //   }]
        // });
        // chart1.render();
        // let dataPoints = [];
        // let y = 0;
        // for (var i = 0; i < 10000; i++) {
        //   y += Math.round(5 + Math.random() * (-5 - 5));
        //   dataPoints.push({ y: y });
        // }
        // let chart2 = new CanvasJS.Chart("chartContainer2", {
        //   zoomEnabled: true,
        //   animationEnabled: true,
        //   exportEnabled: true,
        //   title: {
        //     text: "Performance Demo - 10000 DataPoints"
        //   },
        //   subtitles: [{
        //     text: "Try Zooming and Panning"
        //   }],
        //   data: [
        //     {
        //       type: "line",
        //       dataPoints: dataPoints
        //     }]
        // });
        // chart2.render();
        // let dataPoints2 = [];
        // let dpsLength = 0;
        // let chart3 = new CanvasJS.Chart("chartContainer2", {
        //   exportEnabled: true,
        //   title: {
        //     text: "Live Chart with Data-Points from External JSON"
        //   },
        //   data: [{
        //     type: "spline",
        //     dataPoints: dataPoints2,
        //   }]
        // });
        // $.getJSON("https://canvasjs.com/services/data/datapoints.php?xstart=1&ystart=25&length=20&type=json&callback=?", function (data) {
        //   $.each(data, function (key, value) {
        //     dataPoints2.push({ x: value[0], y: parseInt(value[1]) });
        //   });
        //   dpsLength = dataPoints2.length;
        //   chart3.render();
        //   updateChart();
        // });
        // function updateChart() {
        //   $.getJSON("https://canvasjs.com/services/data/datapoints.php?xstart=" + (dpsLength + 1) + "&ystart=" + (dataPoints2[dataPoints.length - 1].y) + "&length=1&type=json&callback=?", function (data) {
        //     $.each(data, function (key, value) {
        //       dataPoints2.push({
        //         x: parseInt(value[0]),
        //         y: parseInt(value[1])
        //       });
        //       dpsLength++;
        //     });
        //     if (dataPoints2.length > 20) {
        //       dataPoints.shift();
        //     }
        //     chart3.render();
        //     setTimeout(function () { updateChart() }, 1000);
        //   });
        // }
        // let dataPoints3 = [];
        // let dpsLength2 = 0;
        // let chart5 = new CanvasJS.Chart("chartContainer3", {
        //   exportEnabled: true,
        //   title: {
        //     text: "Live Chart with Data-Points from External JSON"
        //   },
        //   data: [{
        //     type: "spline",
        //     dataPoints: dataPoints3,
        //   }]
        // });
        // $.getJSON("https://canvasjs.com/services/data/datapoints.php?xstart=1&ystart=25&length=20&type=json&callback=?", function (data) {
        //   $.each(data, function (key, value) {
        //     dataPoints3.push({ x: value[0], y: parseInt(value[1]) });
        //   });
        //   dpsLength2 = dataPoints3.length;
        //   chart5.render();
        //   updateChart3();
        // });
        // function updateChart3() {
        //   $.getJSON("https://canvasjs.com/services/data/datapoints.php?xstart=" + (dpsLength2 + 1) + "&ystart=" + (dataPoints3[dataPoints.length - 1].y) + "&length=1&type=json&callback=?", function (data) {
        //     $.each(data, function (key, value) {
        //       dataPoints3.push({
        //         x: parseInt(value[0]),
        //         y: parseInt(value[1])
        //       });
        //       dpsLength++;
        //     });
        //     if (dataPoints.length > 20) {
        //       dataPoints3.shift();
        //     }
        //     chart5.render();
        //     setTimeout(function () { updateChart() }, 1000);
        //   });
        // }
        var chart8 = new CanvasJS.Chart("chartContainer8", {
            animationEnabled: true,
            title: {
                text: "Crude Oil Reserves vs Production, 2016"
            },
            axisY: {
                title: "Billions of Barrels",
                titleFontColor: "#4F81BC",
                lineColor: "#4F81BC",
                labelFontColor: "#4F81BC",
                tickColor: "#4F81BC"
            },
            axisY2: {
                title: "Millions of Barrels/day",
                titleFontColor: "#C0504E",
                lineColor: "#C0504E",
                labelFontColor: "#C0504E",
                tickColor: "#C0504E"
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                itemclick: toggleDataSeries
            },
            data: [{
                    type: "column",
                    name: "Proven Oil Reserves (bn)",
                    legendText: "Proven Oil Reserves",
                    showInLegend: true,
                    dataPoints: [
                        { label: "Saudi", y: 266.21 },
                        { label: "Venezuela", y: 302.25 },
                        { label: "Iran", y: 157.20 },
                        { label: "Iraq", y: 148.77 },
                        { label: "Kuwait", y: 101.50 },
                        { label: "UAE", y: 97.8 }
                    ]
                },
                {
                    type: "column",
                    name: "Oil Production (million/day)",
                    legendText: "Oil Production",
                    axisYType: "secondary",
                    showInLegend: true,
                    dataPoints: [
                        { label: "Saudi", y: 10.46 },
                        { label: "Venezuela", y: 2.27 },
                        { label: "Iran", y: 3.99 },
                        { label: "Iraq", y: 4.45 },
                        { label: "Kuwait", y: 2.92 },
                        { label: "UAE", y: 3.1 }
                    ]
                }]
        });
        chart8.render();
        function toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            }
            else {
                e.dataSeries.visible = true;
            }
            chart8.render();
        }
        var chart = new CanvasJS.Chart("chartContainer", {
            animationEnabled: true,
            title: {
                text: "Olympic Medals of all Times (till 2016 Olympics)"
            },
            axisY: {
                title: "Medals"
            },
            legend: {
                cursor: "pointer",
                itemclick: toggleDataSeries
            },
            toolTip: {
                shared: true,
                content: toolTipFormatter
            },
            data: [{
                    type: "bar",
                    showInLegend: true,
                    name: "Gold",
                    color: "gold",
                    dataPoints: [
                        { y: 243, label: "Italy" },
                        { y: 236, label: "China" },
                        { y: 243, label: "France" },
                        { y: 273, label: "Great Britain" },
                        { y: 269, label: "Germany" },
                        { y: 196, label: "Russia" },
                        { y: 1118, label: "USA" }
                    ]
                },
                {
                    type: "bar",
                    showInLegend: true,
                    name: "Silver",
                    color: "silver",
                    dataPoints: [
                        { y: 212, label: "Italy" },
                        { y: 186, label: "China" },
                        { y: 272, label: "France" },
                        { y: 299, label: "Great Britain" },
                        { y: 270, label: "Germany" },
                        { y: 165, label: "Russia" },
                        { y: 896, label: "USA" }
                    ]
                },
                {
                    type: "bar",
                    showInLegend: true,
                    name: "Bronze",
                    color: "#A57164",
                    dataPoints: [
                        { y: 236, label: "Italy" },
                        { y: 172, label: "China" },
                        { y: 309, label: "France" },
                        { y: 302, label: "Great Britain" },
                        { y: 285, label: "Germany" },
                        { y: 188, label: "Russia" },
                        { y: 788, label: "USA" }
                    ]
                }]
        });
        chart.render();
        function toolTipFormatter(e) {
            var str = "";
            var total = 0;
            var str3;
            var str2;
            for (var i = 0; i < e.entries.length; i++) {
                var str1 = "<span style= \"color:" + e.entries[i].dataSeries.color + "\">" + e.entries[i].dataSeries.name + "</span>: <strong>" + e.entries[i].dataPoint.y + "</strong> <br/>";
                total = e.entries[i].dataPoint.y + total;
                str = str.concat(str1);
            }
            str2 = "<strong>" + e.entries[0].dataPoint.label + "</strong> <br/>";
            str3 = "<span style = \"color:Tomato\">Total: </span><strong>" + total + "</strong><br/>";
            return (str2.concat(str)).concat(str3);
        }
    };
    return ChartexpampleComponent;
}());
exports.ChartexpampleComponent = ChartexpampleComponent;
