"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var InvioceSharedComponent = /** @class */ (function () {
    function InvioceSharedComponent(router) {
        this.router = router;
    }
    InvioceSharedComponent.prototype.ngOnInit = function () {
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        this.ctype = parseInt(localStorage.getItem('ctype'));
        console.log('ctype check system', this.ctype);
        // if(this.ctype ==1){
        //   this.router.navigate(['/check-dashboard']);
        // }
    };
    return InvioceSharedComponent;
}());
exports.InvioceSharedComponent = InvioceSharedComponent;
