<!-- <div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
      <div class="container">
        <div class="tabs" id="tabs">
        <div class="tabs__content">
          <div class="tabs__content__item active-tab">
            <div class="content">
                <app-topmenu class="row"></app-topmenu> -->
              <div class="overviewsshow">
                  <div class="header">
                    <h1 class="headprofile mt-0">COS </h1>
                  </div>
                  <div class="row">
                    <div class="col-lg-6"  *ngFor="let cos of cosdetails">
                        <div class="border-box">
                            <div class="overview_details emp">
                                <div class="profile_dtailsshow">
                                    <div class="detailsshow width50 pr-3">
                                        <span>COS Number</span>
                                        <p>{{cos.cosnumber }} <span class="badge badge-success" *ngIf="cos.iscurrent == true">Current</span></p>
                                    </div>
                                    <div class="detailsshow width50">
                                        <span>COS Start Date</span>
                                        <p>{{cos.cosstartdate | date: 'dd MMM yyyy'}}</p>
                                    </div>
                                </div>
                                <div class="profile_dtailsshow">
                                  <div class="detailsshow width50 pr-3">
                                      <span>COS Expiry Date</span>
                                      <p>{{cos.cosexpirydate | date: 'dd MMM yyyy'}}</p>
                                  </div>
                                  <div class="detailsshow width50 pr-3">
                                    <span>COS Document</span>
                                    <p> <a (click)="openModal_cosdocument('pdf-image-cos-viewer',cos.cosdocname)">
                                        <i class="fa fa-file-image-o mg-r-10"></i>
                                    </a></p>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="job_list_section visa_request_section" *ngIf="!cosdetails?.length  > false">
                    <div class="job_detils_date">
                        <p class="job_profile">No Record Found</p>
                    </div>
                </div>  
              </div>
                   
                <!-- <div class="row">
                    <div class="col-lg-12">
                      <h1 class="headprofile">COS </h1>
                    </div>                  
               </div> -->
              <!-- <div class="row row-sm">
            <div class="col-lg-12">
              <div class="row">
                  <div class="col-lg-4" *ngIf="!cosdetails?.length  > false">
                      <div class="billed-to">
                          No Record Found
                        </div>
                  </div>
                  <div class="col-md-4 marg_bott"  *ngFor="let cos of cosdetails">
                      <div class="billed-to">
                          <p><b>COS Number: </b>{{cos.cosnumber }} <span style="color:green" *ngIf="cos.iscurrent == true">(Current)</span></p>
                          <p><b>COS Start Date: </b>{{cos.cosstartdate | date: 'dd MMM yyyy'}}</p>
                          <p><b>COS Expiry Date: </b>{{cos.cosexpirydate | date: 'dd MMM yyyy'}}</p>                          
                        </div>
                  </div>
              </div>
            </div>
          </div> -->
            <!-- </div>
          </div>
          </div>
        </div>
      </div>
      </div>
    </div> -->

    
<jw-modal id="pdf-image-cos-viewer" class="modal" role="dialog">
  <div role="document" class="formgroup">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-cos-viewer');">
        <span aria-hidden="true">&times;</span>
      </button>
          </div>
          <div class="modal-body">
              <div class="container">

                  <div class="row">
                      <div class="col-lg-12">

                          <iframe *ngIf="imgcossrc == false" [src]="iframecossrc" width="100%" height="600" frameborder="0"></iframe>
                          <img *ngIf="imgcossrc == true" [src]="iframecossrc" width="100%" />
                      </div>
                  </div>

              </div>
          </div>

      </div>
  </div>
</jw-modal>