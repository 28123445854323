<div class="bg">
  <app-header></app-header>
  
  <div class="section-wrapper">
      <div class="container">
          <div class="tabs" id="tabs">
              <div class="tabs__content">
                  <div class="tabs__content__item active-tab">
                      <div class="content">
                          <div class="space_em row">
                              <div class="col-lg-6">
                                  <h5 class="hedingcolor">Sick Leave Request <span class="gray_text"> ({{yearmonth}})</span></h5>
                              </div>
                              <div class="col-lg-6">
                                  <div class="input-group">
                                      <select name="year" id="year" class="form-control input-lg spaceright"
                                          (change)="onyearSubmit($event.target.value)" ngModel='{{ year }}'>
                                          <option value="{{y}}" *ngFor="let y of yearList">{{ y }}</option>
                                      </select>
                                      <select name="hstatus" class="form-control input-lg spaceright " id="hstatus" (change)="onChangestatus($event.target.value)">
                      <option value=""  selected>Select Status</option>
                      <option value="1">Pending</option>
                      <option value="2">Approved</option>
                      <option value="3">Rejected</option>
                    </select>
                      <select name="employeeid" class="form-control input-lg " id="employeeid" (change)="onChange($event.target.value)">
                      <option value="">Select Employee</option>
                      <option *ngFor="let ad3 of employeelist" value="{{ ad3.employeemasterid}}">{{ ad3.fname }} {{
                        ad3.mname }} {{ ad3.lname }}</option>
                    </select>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-lg-12">
                                  <ul class="tabs__head montharea">
                                      <li><a [ngClass]="{'active': classactive == '1'}" (click)="getDataByMonth(1);">January </a></li>
                                      <li><a [ngClass]="{'active': classactive == '2'}" (click)="getDataByMonth(2);">February </a></li>
                                      <li><a [ngClass]="{'active': classactive == '3'}" (click)="getDataByMonth(3);">March </a></li>
                                      <li><a [ngClass]="{'active': classactive == '4'}" (click)="getDataByMonth(4);">April </a></li>
                                      <li><a [ngClass]="{'active': classactive == '5'}" (click)="getDataByMonth(5);">May</a></li>
                                      <li><a [ngClass]="{'active': classactive == '6'}" (click)="getDataByMonth(6);">June </a></li>
                                      <li><a [ngClass]="{'active': classactive == '7'}" (click)="getDataByMonth(7);">July </a></li>
                                      <li><a [ngClass]="{'active': classactive == '8'}" (click)="getDataByMonth(8);">August </a></li>
                                      <li><a [ngClass]="{'active': classactive == '9'}" (click)="getDataByMonth(9);">September </a></li>
                                      <li><a [ngClass]="{'active': classactive == '10'}" (click)="getDataByMonth(10);">October </a></li>
                                      <li><a [ngClass]="{'active': classactive == '11'}" (click)="getDataByMonth(11);">November </a></li>
                                      <li><a [ngClass]="{'active': classactive == '12'}" (click)="getDataByMonth(12);">December </a></li>
                                  </ul>
                              </div>
                          </div>
                          <div class="job_list_section employee_list_section leave_section" *ngFor="let leave of data;  let i=index">
                              <div class="job_detils_date width20">
                                  <h5 class="job_profile font_17">{{leave.employee.fname}} {{leave.employee.mname}} {{leave.employee.lname}} </h5>
                                  <p class="email"><i class="fa fa-envelope-o"></i>{{ leave.employee.emailid }}</p>
                                  <p class="phone"> <i class="fa fa-phone"></i> {{ leave.employee.phonenumber }}</p>
                                  <p class="ninumber" *ngIf="(leave.employee.ninumber != '') && (leave.employee.ninumber != null)"> <i class="fa fa-bars"></i>NI Number {{ leave.employee.ninumber }}</p>
                                  <span class="badge badge-success" *ngIf="leave.employee.nationality">{{ leave.employee.nationality.countryname }}</span>
                              </div>
                              <div class="job_detils_date width15">
                                  <p class="job_address">Title</p>
                                  <h5 class="job_profile font_17">{{leave.title}} </h5>
                              </div>
                              <div class="job_detils_date width20">
                                  <p class="job_address">Holiday Type</p>
                                  <h5 class="job_profile font_17">{{leave.holidaytype.typename}} </h5>
                              </div>
                              <div class="job_detils_date width13">
                                  <p class="job_address">Start Date</p>
                                  <h5 class="job_profile font_17"> {{leave.startdate | date: 'dd MMM yyyy'}}</h5>
                              </div>
                              <div class="job_detils_date width13">
                                  <p class="job_address">End Date</p>
                                  <h5 class="job_profile font_17"> {{leave.enddate | date: 'dd MMM yyyy'}}</h5>
                              </div>
                              <div class="job_btn visa_btn width7">
                                  <span style="color:red" *ngIf="leave.hstatus == '3'">Rejected</span>
                                  <span style="color:green" *ngIf="leave.hstatus == '2'">Approved</span>
                                  <div class="dropdown" *ngIf="leave.hstatus == '1'">
                                      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       Action <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <a class="dropdown-item" (click)="onStatus(leave.employeeholidayapplyid, 2);">Approve</a>
                                          <a class="dropdown-item" (click)="onStatus(leave.employeeholidayapplyid, 3);">Reject</a>
                                          <a class="dropdown-item" (click)="remarksmodel(leave.employeeholidayapplyid, 'remarks-viewer', 'event-viewer');">Send Note</a>
                                      </div>
                                  </div>
                              </div>
                              <div class="job_detils_date width7">
                                  <p class="job_address ">
                                      <button type="submit" class="btn btn-info com_btn" (click)="openModalnote(leave.remarkdetails,'note-leave-report')"> <i class="fa fa-comments-o "></i></button>
                                  </p>
                              </div>
                          </div>
                          <div class="job_list_section visa_request_section" *ngIf="record == 0">
                              <div class="job_detils_date">
                                  <p class="job_profile">No Record Found</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<jw-modal id="remarks-viewer" class="modal listofwork" role="dialog">
  <div role="document" class="formgroup">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Note</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal('remarks-viewer');">
                <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
              <div class="container">
                  <form [formGroup]="remarkformGroup" #remarkForm="ngForm" class="s12 white" (ngSubmit)="onSubmitRemark(remarkForm,'remarks-viewer')" *ngIf="remarksofleave">
                      <input type="hidden" formControlName="remarksholidayid" class="form-control" required name="remarksholidayid" ngModel='{{ remarksholidayid }}'>
                      <input type="hidden" formControlName="isline" class="form-control" required name="isline" ngModel='{{ isline }}'>
                      <div class="row row-sm mg-t-10">
                          <div class="col-lg-12 poplist m-b-10">
                              <label>Remarks*</label>
                              <textarea [ngClass]="{ 'is-invalid': remarksubmit && g.holidayremark.errors }" class="form-control" formControlName="holidayremark" ngModel='{{ holidayremarks }}' required name="holidayremarks" id="holidayremarks" placeholder="Enter remarks here"></textarea>
                              <div *ngIf="remarksubmit && g.holidayremark.errors" class="invalid-feedback">
                                  <div *ngIf="g.holidayremark.errors.required">Please enter remarks</div>
                              </div>
                          </div>
                      </div>
                      <div class="float-right mb-3">
                          <div class="poplist">
                              <button type="submit" class="btn btn-success com_btn">Add Comment</button>
                          </div>
                      </div>
                  </form>
                  <div class="leave_description white_box_shadow noheight" *ngFor="let rem of remarksofleave">
                      <p class="mb-0">
                          <i>
                              {{ rem.holidayremark }}
                              <hr class="dashed mt-2 mb-2"/>
                              <div class="note_footer pl-0 pr-0">
                                  <span>
                                      {{rem.createat | date: 'dd MMM yyyy'}}
                                  </span>
                                  <span>
                                      <b *ngIf="rem.comuser">
                                          {{rem.comuser.fname}}
                                      </b>
                                  </span>
                              </div>
                          </i>
                      </p>
                  </div>
                  <!-- <div class="row row-sm">
                      <button type="button" [disabled]="!disableBtn" style="background-color:#094008; margin-left: 15px; color: #fff;" class="btn btn-outline-secondary" (click)="closeModal('remarks-viewer');"><i class="fa fa-check"></i></button>
                      <button type="button" style="background-color:#094008;margin-left: 15px;color: #fff;" class="btn btn-outline-secondary" (click)="closemessageModal('remarks-viewer');"><i class="fa fa-close"></i></button>
                  </div> -->
              </div>
          </div>
          <div class="modal-footer">
              <!-- <button type="button" class="btn btn-primary com_btn" (click)="closemessageModal('remarks-viewer');">Save changes</button> -->
              <button type="button" class="btn btn-secondary com_btn" data-dismiss="modal" [disabled]="!disableBtn" (click)="closeModal('remarks-viewer');">Close</button>
          </div>
      </div>
  </div>
</jw-modal>

<jw-modal id="note-leave-report" class="modal " role="dialog ">
  <div role="document" class="formgroup ">
      <div class="modal-content ">
          <div class="modal-header ">
              <h5 class="modal-title ">Notes</h5>
              <button type="button " class="close " data-dismiss="modal " name="close " aria-label="Close " (click)="closeModal( 'note-leave-report'); ">
                <span aria-hidden="true ">&times;</span>
                </button>
          </div>
          <div class="modal-body ">
              <div class="container ">
                  <div class="job_profile">
                      <div class="">
                          <p *ngFor="let rd of notedetails "><b>Note :</b> {{ rd.holidayremark }}</p>
                      </div>
                  </div>

              </div>
          </div>
          <div class="modal-footer ">
              <button type="button " class="btn btn-secondary com_btn " data-dismiss="modal " (click)="closeModal( 'note-leave-report');">Close</button>
          </div>
      </div>
  </div>
</jw-modal>