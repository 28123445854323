"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_model_1 = require("../../models/employee.model");
var country_service_1 = require("../../services/country.service");
var employee_service_1 = require("../../services/employee.service");
var employeement_service_1 = require("../../services/employeement.service");
var job_service_1 = require("../../services/job.service");
var modal_service_1 = require("../../_services/modal.service");
var project_service_1 = require("../../services/project.service");
var leave_service_1 = require("../../services/leave.service");
var excel_service_1 = require("../../services/excel.service");
var app_global_1 = require("../../app.global");
var AdminleavereportComponent = /** @class */ (function () {
    function AdminleavereportComponent(excelService, formBuilder, project, leave, country, jobser, employee, employeementreport, modalService) {
        this.excelService = excelService;
        this.formBuilder = formBuilder;
        this.project = project;
        this.leave = leave;
        this.country = country;
        this.jobser = jobser;
        this.employee = employee;
        this.employeementreport = employeementreport;
        this.modalService = modalService;
        this.employeeDetail = new employee_model_1.Employee();
        this.exceldata = [];
    }
    AdminleavereportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        this.graphdisplay = "block";
        this.listdisplay = "none";
        this.employeeyear = (new Date()).getFullYear();
        this.employeemonth = '';
        this.EmployeeForm = this.formBuilder.group({
            emailid: [''],
            phonenumber: [''],
            ninumber: [''],
            minstartdate: [''],
            maxstartdate: [''],
            minenddate: [''],
            maxenddate: [''],
            clientid: [''],
            minsalary: [''],
            maxsalary: [''],
            projectid: [''],
            fname: [''],
            hstatus: [''],
            searctext: ['']
        });
        var value3 = "{}";
        this.employee.getemployeelist(value3).subscribe(function (listdetails) {
            _this.employeelist = listdetails;
        });
        // var values3 = {};
        // this.leave.leavereport(values3).subscribe((leavereport: any) => {
        //   this.data = leavereport;
        // });
        this.leavetypereport();
        this.leave.getholidaylist().subscribe(function (holidaydetails) {
            _this.holidaytype = holidaydetails;
        });
    };
    AdminleavereportComponent.prototype.showcondition = function (type) {
        if (type == 'list') {
            this.graphdisplay = "none";
            this.listdisplay = "block";
        }
        else {
            this.listdisplay = "none";
            this.graphdisplay = "block";
        }
    };
    AdminleavereportComponent.prototype.exportAsXLSX = function () {
        var _this = this;
        this.exceldata = [];
        this.data.forEach(function (obj) {
            if (obj.iscurrent == false) {
                var current = "No";
            }
            else if (obj.iscurrent == true) {
                current = "Yes";
            }
            _this.employeeleavedata = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "Title": obj.title,
                "Start Date": obj.startdate,
                "End Date": obj.enddate,
                "Leave Type": obj.holidaytype.typename
            };
            _this.exceldata.push(_this.employeeleavedata);
        });
        this.excelService.exportAsExcelFile(this.exceldata, 'sample');
    };
    AdminleavereportComponent.prototype.leavetypereport = function () {
        var _this = this;
        this.data = [];
        var empcheck = true;
        var values3 = {};
        values3 = { 'month': this.employeemonth, 'year': this.employeeyear, 'employeeid': this.empid, 'hstatus': this.hstatus };
        this.leave.leavereport(values3).subscribe(function (leavereport) {
            // this.record = leavereport.length;
            // this.data = leavereport;
            // this.record = this.data.length;
            _this.record = leavereport.length;
            // console.log(leavereport);
            leavereport.forEach(function (leave) {
                empcheck = true;
                _this.data.forEach(function (emp, index) {
                    if (leave.employeeid == emp.employeemasterid) {
                        empcheck = false;
                        _this.data[index].leaverecords.push(leave);
                        if (leave.hstatus == '1') {
                            _this.data[index].pendingleave++;
                        }
                        else if (leave.hstatus == '2') {
                            _this.data[index].approvedleave++;
                        }
                        else if (leave.hstatus == '3') {
                            _this.data[index].rejectleave++;
                        }
                        _this.data[index].totalleave++;
                    }
                });
                if (empcheck) {
                    leave.employee.leaverecords = [leave];
                    leave.employee.pendingleave = 0;
                    leave.employee.approvedleave = 0;
                    leave.employee.rejectleave = 0;
                    leave.employee.totalleave = 0;
                    if (leave.hstatus == '1') {
                        leave.employee.pendingleave++;
                    }
                    else if (leave.hstatus == '2') {
                        leave.employee.approvedleave++;
                    }
                    else if (leave.hstatus == '3') {
                        leave.employee.rejectleave++;
                    }
                    leave.employee.totalleave++;
                    _this.data.push(leave.employee);
                }
            });
            // if (this.record < 30) {
            //   this.pageOfItems = this.data;
            // }
            // else {
            //   this.items = this.data;
            // }
        });
    };
    AdminleavereportComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    AdminleavereportComponent.prototype.changeEmployee = function (id) {
        this.empid = id;
        this.leavetypereport();
    };
    AdminleavereportComponent.prototype.changeEmployeeMonth = function (month) {
        this.employeemonth = month;
        this.leavetypereport();
    };
    AdminleavereportComponent.prototype.changeEmployeeYear = function (year) {
        this.employeeyear = year;
        this.leavetypereport();
    };
    AdminleavereportComponent.prototype.changeEmployeestatus = function (hstatus) {
        this.hstatus = hstatus;
        this.leavetypereport();
    };
    AdminleavereportComponent.prototype.openModalnote = function (data, id) {
        this.notedetails = data;
        this.modalService.open(id);
    };
    AdminleavereportComponent.prototype.openModal = function (id) {
        //   this.visa = new Visa();
        //   this.disableBtn = true;
        //   this.visaDetail = new Visa();
        //   this.visaDetail = data;
        //  // console.log(data);
        //   this.buttoninsert = "Update";
        this.modalService.open(id);
    };
    AdminleavereportComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    AdminleavereportComponent.prototype.onSubmit = function (value3) {
    };
    return AdminleavereportComponent;
}());
exports.AdminleavereportComponent = AdminleavereportComponent;
