//import { Nationality } from './nationality.model';

export class Leave {
  id: number;
  employeeholidayapplyid: number;
  title: number;
  employeeid: number;
  holidaytype: number;
  halfday: number;
  start: Date;
  end: Date;
  holidayremark: string;
  holidayremarkid: number;
  issick: boolean;
}

export class Leavecount {

  ptotal:number;
}