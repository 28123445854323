"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var company_service_1 = require("../../../services/company.service");
var checksystem_service_1 = require("../../../services/checksystem.service");
var checkInvoice_model_1 = require("../../../models/check/checkInvoice.model");
//import * as jspdf from 'jspdf';        
// import html2canvas from 'html2canvas-proxy';  
var CheckinvoiceComponent = /** @class */ (function () {
    function CheckinvoiceComponent(company, router, activatedRoute, check) {
        this.company = company;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.check = check;
        this.invoicelist = new checkInvoice_model_1.CheckInvoice;
    }
    CheckinvoiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ctype = parseInt(localStorage.getItem('ctype'));
        this.invoicelist.companydetails = new checkInvoice_model_1.CompanyDetails();
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        this.invoiceid = this.activatedRoute.snapshot.paramMap.get("invoiceid");
        this.timestamp = function (epoch) {
            return (epoch * 1000);
        };
        this.company.getcompanydefaultlocation().subscribe(function (locationlist) {
            _this.companylocationlist = locationlist;
        });
        var data = { 'invoiceid': this.invoiceid };
        this.check.getAllInvoicedetails(data).subscribe(function (invoiceData) {
            _this.invoicelist = invoiceData;
        });
    };
    CheckinvoiceComponent.prototype.captureScreen = function () {
        var data = document.getElementById('contentToConvert');
        // console.log(data);
        // html2canvas(data).then(canvas => {  
        //   // Few necessary setting options  
        //   var imgWidth = 208;   
        //   var pageHeight = 295;    
        //   var imgHeight = canvas.height * imgWidth / canvas.width;  
        //   var heightLeft = imgHeight;  
        //   const contentDataURL = canvas.toDataURL('image/png')  
        //   let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
        //   var position = 0;  
        //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
        //   pdf.save('MYPdf.pdf'); // Generated PDF   
        // });  
    };
    return CheckinvoiceComponent;
}());
exports.CheckinvoiceComponent = CheckinvoiceComponent;
