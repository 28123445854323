"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Visa = /** @class */ (function () {
    function Visa() {
        this.visastart = new Date();
        this.visaexpiry = new Date();
    }
    return Visa;
}());
exports.Visa = Visa;
