import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppGlobals } from '../app.global';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  readonly rootUrl = AppGlobals.API_ENDPOINT + '/menumaster';
  
  constructor(private http: HttpClient, private commonService: CommonService) { }
  

  bindallmenu(): Observable<any> {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.rootUrl, { headers: reqHeader });

    // return this.commonService.formpost('/menumaster');
  }

  updateemployeepermission(value: any): Observable<any> {
    return this.commonService.formpost('/employee/permission/updateemployeepermission',value);
  }

  updateisadmin(value: any): Observable<any> {
    return this.commonService.formpost('/company/updateisadmin',value);
  }
  
}
