"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var employee_service_1 = require("../../services/employee.service");
var customer_service_1 = require("../../services/customer.service");
var candidate_model_1 = require("../../models/jobs/candidate.model");
var modal_service_1 = require("../../_services/modal.service");
var permanentaddress_model_1 = require("../../models/overview/permanentaddress.model");
var router_1 = require("@angular/router");
var profile_model_1 = require("../../models/overview/profile.model");
var alert_service_1 = require("../../services/alert.service");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var country_service_1 = require("../../services/country.service");
var forms_1 = require("@angular/forms");
var upload_file_service_1 = require("./../../services/upload-file.service");
var common_service_1 = require("../../services/common.service");
var candidate_service_1 = require("../../services/candidate.service");
var terminationreason_service_1 = require("../../services/terminationreason.service");
var EmployeeComponent = /** @class */ (function () {
    function EmployeeComponent(formBuilder, activatedRoute, uploadService, data, country, employeeService, modalService, router, candidate, commonService, tReasonService, alerts) {
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.uploadService = uploadService;
        this.data = data;
        this.country = country;
        this.employeeService = employeeService;
        this.modalService = modalService;
        this.router = router;
        this.candidate = candidate;
        this.commonService = commonService;
        this.tReasonService = tReasonService;
        this.alerts = alerts;
        this.navbarOpen = false;
        this.clicked = false;
        this.sectionHeights = [];
        this.tab = 1;
        this.empltype = false;
        this.options = [];
        this.mindob = new Date();
        this.maxdob = new Date();
        this.defaultVal = "";
        this.subCategoryList = [];
    }
    EmployeeComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.candidatenoteFormGroup = this.formBuilder.group({
            candidateid: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            notes: ['', forms_1.Validators.required]
        });
        this.candidatenotesdetails = new candidate_model_1.candidatenotes();
        this.terminationDetail = new profile_model_1.Termination();
        this.empid = this.activatedRoute.snapshot.paramMap.get("id");
        this.Userid = localStorage.getItem('userid');
        this.mindob.setDate(this.mindob.getDate() - 23741);
        this.maxdob.setDate(this.maxdob.getDate() - 574);
        this.OverForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            //  employeeid: ['', Validators.required],
            fname: ['', forms_1.Validators.required],
            mname: [''],
            lname: ['', forms_1.Validators.required],
            gender: ['', forms_1.Validators.required],
            hiredate: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            placeofbirth: ['', forms_1.Validators.required],
            maritalstatus: ['', forms_1.Validators.required],
            ninumber: ['', forms_1.Validators.required],
            emprole: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            residenceid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            countryofbirthid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            dateofbirth: ['', forms_1.Validators.required],
        });
        this.terminationForm = this.formBuilder.group({
            employeeid: ['', forms_1.Validators.required],
            terminationdate: ['', forms_1.Validators.required],
            terminationreason: ['', forms_1.Validators.required],
            oktorehire: ['', forms_1.Validators.required],
            regrettermination: ['', forms_1.Validators.required],
            termination_category: ['', forms_1.Validators.required],
            termination_subcategory: ['', forms_1.Validators.required]
        });
        this.permanetForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            userid: [''],
            addressid1: ['', forms_1.Validators.required],
            addressid2: [''],
            cityname: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            mobilenumber: [''],
            pincode: ['', forms_1.Validators.required],
            statename: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            phonenumber: [''],
            countryid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])]
        });
        this.overviewData = new profile_model_1.Profile();
        this.paddress = new permanentaddress_model_1.Permanentaddress();
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.tReasonService.getParentTerminationReasonList({ parentid: 0, status: 1 }).subscribe(function (parentterminationreasons) {
            _this.parentCategoryList = parentterminationreasons;
        });
        this.data.getOverviewbyadmin({ 'id': this.empid }).subscribe(function (data) {
            _this.empltype = data.emptype;
            _this.overviewData = data;
        });
        this.data.getPaddressbyadmin({ 'id': this.empid }).subscribe(function (data) {
            if (data.addressid1 == null) {
                _this.buttoninsert = "Submit";
            }
            else {
                _this.buttoninsert = "Update";
            }
            _this.paddress = data;
        });
        // this.Userid = localStorage.getItem('userid');    
        // const data3 = { 'userid': this.Userid };
        // this.data.getOtherInfo(data3).subscribe((data: any) => {
        //   this.employement = data;
        // });
        this.employeeService.employeeTerminationHistory({ employeeid: this.empid, userid: this.Userid }).subscribe(function (termHistory) {
            _this.terminationHistory = termHistory;
        });
        var data5 = { 'userid': this.Userid, 'employeeid': this.empid };
        this.data.getOtherInfobyadmin(data5).subscribe(function (data) {
            _this.employement = data;
        });
        var data = { 'userid': this.empid };
        this.data.getOtherInfo(data).subscribe(function (data) {
            _this.otherDetail = data;
        });
        this.data.getEmploymentbyadmin({ 'id': this.empid }).subscribe(function (data) {
            _this.employement = data;
        });
        this.data.getVisabyadmin({ 'id': this.empid }).subscribe(function (data) {
            _this.visalist = data;
        });
    };
    Object.defineProperty(EmployeeComponent.prototype, "g", {
        get: function () { return this.terminationForm.controls; },
        enumerable: true,
        configurable: true
    });
    EmployeeComponent.prototype.openModelTerminateactive = function (empid, terminateid, id) {
        var _this = this;
        this.terminationDetail = new profile_model_1.Termination();
        this.submitted = false;
        var dd = { termid: terminateid };
        this.employeeService.employeeViewTermination(dd).subscribe(function (data) {
            _this.tReasonService.getChildTerminationReasonList({ parentid: 1, status: 1, searchparentid: data.termination_category }).subscribe(function (childterminationreasons) {
                _this.subCategoryList = childterminationreasons;
                _this.terminationDetail.employeeid = empid;
                if (data.oktorehire == true)
                    _this.terminationDetail.oktorehire = "1";
                else
                    _this.terminationDetail.oktorehire = "0";
                if (data.oktorehire == true)
                    _this.terminationDetail.regrettermination = "1";
                else
                    _this.terminationDetail.regrettermination = "0";
                _this.terminationDetail.termination_category = data.termination_category;
                _this.terminationDetail.termination_subcategory = data.termination_subcategory;
                _this.terminationDetail.terminationdate = data.terminationdate;
                _this.terminationDetail.terminationreason = data.terminationreason;
                _this.modalService.open(id);
            });
        });
    };
    EmployeeComponent.prototype.openModelTerminate = function (empid, id) {
        this.subCategoryList = [];
        this.terminationDetail = new profile_model_1.Termination();
        this.submitted = false;
        this.terminationDetail.employeeid = empid;
        this.terminationDetail.oktorehire = "";
        this.terminationDetail.regrettermination = "";
        this.terminationDetail.termination_category = "";
        this.terminationDetail.termination_subcategory = "";
        this.modalService.open(id);
    };
    EmployeeComponent.prototype.removeTermination = function () {
        var _this = this;
        this.employeeService.employeeRemoveTermination({ 'userid': this.Userid, 'employeeid': this.empid }).subscribe(function (data) {
            if (data.success) {
                _this.data.getOverviewbyadmin({ 'id': _this.empid }).subscribe(function (data) {
                    _this.overviewData = data;
                    _this.alerts.success('Employee has removed from termination.', true);
                });
            }
        });
    };
    EmployeeComponent.prototype.clickedOutside = function (e) {
        if (this.clicked) {
            this._el.nativeElement.querySelector('dropdown-menu').classList.toogle('show');
        }
    };
    EmployeeComponent.prototype.onmousetopbottom = function (id, height) {
        $('html, body').animate({
            scrollTop: $('#' + id).offset().top
        }, 1000, function () {
            window.location.hash = '#' + id;
        });
    };
    // @HostListener('window:scroll', ['$event'])
    // onWindowScroll(e){
    //   // let element = document.getElementById('tabs');
    //     // console.log(window.pageYOffset +' rahul kumar tanwar');
    //     let overview = document.getElementById('overview');
    //     //let current = document.getElementById('current');
    //     let department = document.getElementById('department');
    //     let overemployeement = document.getElementById('overemployeement');
    //     let overaddress = document.getElementById('overaddress');
    //     let overcompen = document.getElementById('overcompen');     
    //     let overcontactinfo = document.getElementById('overcontactinfo');
    //     let overcos = document.getElementById('overcos');      
    //     let overvisa = document.getElementById('overvisa');
    //     let overpassport = document.getElementById('overpassport');
    //     let overidproof = document.getElementById('idproof');
    //     this.overview1 = overview.offsetHeight;
    //   //  this.current1 = current.offsetHeight + this.overview1;
    //     this.department1 = department.offsetHeight + this.overview1;
    //     this.overemployeement1 = overemployeement.offsetHeight + this.department1;
    //     this.overaddress1 = overaddress.offsetHeight + this.overemployeement1;
    //     this.overcompen1 = overcompen.offsetHeight + this.overaddress1;
    //     this.overcontactinfo1 = overcontactinfo.offsetHeight + this.overcompen1;
    //     this.overcos1 = overcos.offsetHeight + this.overcontactinfo1;
    //     this.overvisa1 = overvisa.offsetHeight + this.overcos1;
    //     this.overpassport1 = overpassport.offsetHeight + (this.overvisa1-200);
    //     this.overidproof1 = overidproof.offsetHeight + this.overpassport1;
    //     if(window.pageYOffset < this.overview1)
    //     {
    //       this.tab =1;
    //     }
    //     // else if(window.pageYOffset < this.current1)
    //     // {
    //     //   this.tab =9;
    //     // }
    //     else if(window.pageYOffset < this.department1)
    //     {
    //       this.tab =10;
    //     }      
    //     else if(window.pageYOffset < this.overemployeement1)
    //     {
    //       this.tab =2;
    //     } else if(window.pageYOffset < this.overaddress1)
    //     {
    //       this.tab =3;
    //     } else if(window.pageYOffset < this.overcompen1)
    //     {
    //       this.tab =4;
    //     }  else if(window.pageYOffset < this.overcontactinfo1)
    //     {
    //       this.tab =5;
    //     }  else if(window.pageYOffset < this.overcos1)
    //     {
    //       this.tab =6;
    //     }  else if(window.pageYOffset < this.overvisa1)
    //     {
    //       this.tab =7;
    //     }  else if(window.pageYOffset < this.overpassport1)
    //     {
    //       this.tab =8;
    //     } else if(window.pageYOffset < (this.overidproof1-200))
    //     {
    //       this.tab =9;
    //     } 
    //   if(window.pageYOffset > 200){
    //     let element = document.getElementById('employeetopmenu');
    //     // console.log(element+' rahul kumar tanwar');
    //     element.classList.add('is-sticky');
    //   } else {
    //     let element = document.getElementById('employeetopmenu');
    //     element.classList.remove('is-sticky');
    //   }
    // }
    EmployeeComponent.prototype.onSelectCountry = function (country_id) {
        var _this = this;
        var data = { 'id': country_id };
        this.country.getstatelist(data).subscribe(function (state) {
            return _this.statelist = state;
        });
    };
    EmployeeComponent.prototype.onSearchChange = function (searchValue) {
        var _this = this;
        if (searchValue.length > 4) {
            var newArray = [];
            this.commonService.getAddress(searchValue).subscribe(function (data) {
                var newdata;
                newdata = data;
                newdata.addresses.forEach(function (value) {
                    newArray.push(value + "," + searchValue);
                });
                _this.options = newArray;
            });
        }
        else {
            this.options = [];
        }
    };
    EmployeeComponent.prototype.displayFn = function (value) {
        if (value != null) {
            var res = value.split(",");
            if (res.length > 6) {
                return res[7];
            }
            else {
                return value;
            }
        }
    };
    EmployeeComponent.prototype.updatecode = function (evt, response) {
        res = [];
        if (evt.source.selected) {
            var res = response.split(",");
            this.paddress.addressid1 = res[0];
            this.paddress.addressid2 = res[1];
            this.paddress.cityname = res[5];
            this.paddress.pincode = res[7];
            this.paddress.statename = res[6];
        }
    };
    EmployeeComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    EmployeeComponent.prototype.openModal = function (id, data) {
        this.submitted = false;
        this.disableBtn = true;
        this.overviewData = new profile_model_1.Profile();
        if (data.nationalityid == null) {
            data.nationalityid = "";
        }
        if (data.residenceid == null) {
            data.residenceid = "";
        }
        if (data.countryofbirthid == null) {
            data.countryofbirthid = "";
        }
        this.overviewData = data;
        this.modalService.open(id);
    };
    EmployeeComponent.prototype.openModal2 = function (id, data) {
        this.submitted = false;
        this.disableBtn = true;
        this.paddress = new permanentaddress_model_1.Permanentaddress();
        this.buttoninsert = "Update";
        this.paddress = data;
        // this.paddress.employeeid = this.empid;
        if ((this.paddress.countryid == '') || (this.paddress.countryid == null)) {
            this.paddress.countryid = '';
        }
        this.mobilecheck = "";
        this.phonecheck = "";
        this.paddress.userid = this.Userid;
        this.modalService.open(id);
    };
    EmployeeComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        this.fileformaterror = false;
        this.filesizeerror = false;
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
            return;
        }
        else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") && (filetype[filetype.length - 1].toLowerCase() != "gif") && (filetype[filetype.length - 1].toLowerCase() != "tif") && (filetype[filetype.length - 1].toLowerCase() != "pdf") && (filetype[filetype.length - 1].toLowerCase() != "doc") && (filetype[filetype.length - 1].toLowerCase() != "docx") && (filetype[filetype.length - 1].toLowerCase() != "msword")) {
            this.fileformaterror = true;
            return;
        }
        var file = this.selectedFiles;
        var imagepath = this.uploadService.uploadfile(file);
        var forupdate = { 'imagename': imagepath, 'id': this.empid };
        this.data.updateemployeeimage(forupdate).subscribe(function (data) {
            setTimeout(function () { return _this.overviewData.imagename = imagepath; }, 2000);
        });
        this.alerts.success('Profile picture updated successfully.', true);
    };
    Object.defineProperty(EmployeeComponent.prototype, "f", {
        get: function () { return this.OverForm.controls; },
        enumerable: true,
        configurable: true
    });
    EmployeeComponent.prototype.onSubmit = function (value3, id) {
        var _this = this;
        this.submitted = true;
        if (this.OverForm.invalid) {
            return;
        }
        this.disableBtn = false;
        var sd = new Date(value3.dateofbirth);
        value3.dateofbirth = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        var ed = new Date(value3.hiredate);
        value3.hiredate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        this.data.updateemployeedetalsbyadmin(value3).subscribe(function (data) {
            _this.data.getOverviewbyadmin({ 'id': _this.empid }).subscribe(function (data) {
                _this.overviewData = data;
            });
        });
        this.alerts.success('Profile updated successfully.', true);
        this.modalService.close(id);
    };
    Object.defineProperty(EmployeeComponent.prototype, "h", {
        get: function () { return this.permanetForm.controls; },
        enumerable: true,
        configurable: true
    });
    EmployeeComponent.prototype.onSubmit2 = function (value3, id) {
        var _this = this;
        this.submitted = true;
        //console.log(this.permanetForm);
        if (this.permanetForm.invalid) {
            return;
        }
        var pinc = value3.pincode.split(',');
        value3.pincode = pinc[pinc.length - 1];
        value3.userid = this.Userid;
        this.data.permanentadddetalsbyadmin(value3).subscribe(function (data) {
            _this.data.getPaddressbyadmin({ 'id': _this.empid }).subscribe(function (data) {
                _this.paddress = data;
            });
        });
        this.alerts.success('Permanent Address updated successfully.', true);
        this.modalService.close(id);
    };
    EmployeeComponent.prototype.closeModalOverview = function (id) {
        var _this = this;
        this.overviewData = new profile_model_1.Profile();
        // this.paddress = new Permanentaddress();
        // this.country.getcountrylist().subscribe((country: any) => {
        //   this.countrylist = country;
        // });
        this.data.getOverviewbyadmin({ 'id': this.empid }).subscribe(function (data) {
            if (data.addressid1 == null) {
                _this.buttoninsert = "Submit";
            }
            else {
                _this.buttoninsert = "Update";
            }
            _this.overviewData = data;
        });
        // this.data.getPaddress().subscribe((data: any) => {
        //   this.paddress = data;
        // });
        this.modalService.close(id);
    };
    EmployeeComponent.prototype.closeModalTerminate = function (id) {
        var _this = this;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.modalService.close(id);
    };
    EmployeeComponent.prototype.closeModalPaddress = function (id) {
        // this.paddress = new Permanentaddress();
        // this.country.getcountrylist().subscribe((country: any) => {
        //   this.countrylist = country;
        // });
        // this.data.getOverviewbyadmin({'id': this.empid}).subscribe((data: any) => {
        //   if(data.addressid1 == null)
        //   {this.buttoninsert = "Submit";}
        //   else
        //   {this.buttoninsert = "Update";}
        //   this.overviewData = data;
        // });
        // this.data.getPaddressbyadmin({'id': this.empid}).subscribe((data: any) => {
        //   this.paddress = data;
        // });
        this.modalService.close(id);
    };
    EmployeeComponent.prototype.onSubmitTermination = function (terminationform, modelid) {
        var _this = this;
        this.submitted = true;
        if (this.terminationForm.invalid) {
            return;
        }
        var sd = new Date(terminationform.terminationdate);
        terminationform.terminationdate = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        this.employeeService.employeeSubmitTermination(terminationform).subscribe(function (data) {
            _this.data.getOverviewbyadmin({ 'id': _this.empid }).subscribe(function (data) {
                _this.employeeService.employeeTerminationHistory({ employeeid: _this.empid, userid: _this.Userid }).subscribe(function (termHistory) {
                    _this.terminationHistory = termHistory;
                });
                _this.empltype = data.emptype;
                _this.overviewData = data;
                _this.alerts.success('Employee has been terminated successfully');
                _this.modalService.close(modelid);
            });
        });
    };
    Object.defineProperty(EmployeeComponent.prototype, "j", {
        get: function () { return this.candidatenoteFormGroup.controls; },
        enumerable: true,
        configurable: true
    });
    EmployeeComponent.prototype.openNotesModel = function (id) {
        var _this = this;
        var db = { 'candidateid': this.empid };
        this.candidate.getcandidatenotelist(db).subscribe(function (candidatenote) {
            _this.candidatenotes = candidatenote;
        });
        this.candidatenotesdetails.notes = '';
        this.candidatenotesdetails.employeeid = this.Userid;
        this.candidatenotesdetails.candidateid = this.empid;
        this.modalService.open(id);
    };
    EmployeeComponent.prototype.updatecandidatenote = function (values) {
        var _this = this;
        this.submitted = true;
        if (this.candidatenoteFormGroup.invalid) {
            return;
        }
        this.candidate.createcandidatenote(values).subscribe(function (candidatenote) {
            var db = { 'candidateid': _this.empid };
            _this.candidate.getcandidatenotelist(db).subscribe(function (candidatenotem) {
                _this.candidatenotes = candidatenotem;
            });
        });
    };
    EmployeeComponent.prototype.changeSubcategory = function (reasonCategory) {
        var _this = this;
        this.tReasonService.getChildTerminationReasonList({ parentid: 1, status: 1, searchparentid: reasonCategory }).subscribe(function (childterminationreasons) {
            _this.subCategoryList = childterminationreasons;
        });
    };
    EmployeeComponent.prototype.onChangeStatus = function (evt, emp) {
        var _this = this;
        console.log('Employee ID details', emp);
        console.log('Employee ID details', this.empid);
        var status;
        if (evt.target.checked) {
            status = '1';
        }
        else if (!evt.target.checked) {
            status = '0';
        }
        this.employeeService.employeeUpdateChangeStatus({ 'userid': this.Userid, 'id': this.empid, 'estatus': status }).subscribe(function (data) {
            if (status == '1') {
                _this.alerts.success('Employee has been activated successfully');
            }
            else if (status == '0') {
                _this.alerts.error('Employee has been deactivated successfully');
            }
        });
    };
    return EmployeeComponent;
}());
exports.EmployeeComponent = EmployeeComponent;
