

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ModalService } from '../../_services/modal.service';

@Component({
  selector: 'app-employeeobjective',
  templateUrl: './employeeobjective.component.html',
  styleUrls: ['./employeeobjective.component.css']
})
export class EmployeeobjectiveComponent implements OnInit {
  employeeForm: FormGroup;
  record:any;
  submitted:any;empidexist:any;

  constructor(private modalService: ModalService,   private formBuilder: FormBuilder, ) { }

  ngOnInit() {
    this.employeeForm = this.formBuilder.group({
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      empid: ['', Validators.required],
      gender: ['', Validators.required],
      placeofbirth: ['', Validators.required],
      maritalstatus: ['', Validators.required],
      ninumber: ['', Validators.required],
      jobid: [''],
      candidateid: [''],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: ['', Validators.compose([Validators.required])],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      residenceid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      countryofbirthid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      dateofbirth: ['', Validators.required],
      hiredate: ['', Validators.required],
      emprole: ['', Validators.required],
      ismail: ['']
    });
  }

  
  get f() { return this.employeeForm.controls; }
  openModal2(id: string) {
    this.modalService.open(id);
    
  }onSelectjob(d){}
  closeModal(id: string) {    
    this.modalService.close(id);
  }
}
