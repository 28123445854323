<app-header></app-header>
<div class="not_found nocountry">
  <div class="page404 unsupported_box">
    <div class="content404 text-center">
      <div class="unsupported text-center">
        <span class="lnr lnr-neutral"></span>
      </div>
      <p class="text-center">AI services are not yet enabled for this country! </p>
      <p class="text-center">They will be available soon.</p>
      <a class="btn btn-danger" [routerLink]="['/dashboard']">Go To Home</a>
    </div>
  </div>
</div>