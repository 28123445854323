"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var aml_model_1 = require("../../../models/aml/aml.model");
var aml_service_1 = require("../../../services/aml.service");
var router_1 = require("@angular/router");
var AmllogComponent = /** @class */ (function () {
    function AmllogComponent(amldetails, router, activatedRoute) {
        this.amldetails = amldetails;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.atype = false;
    }
    AmllogComponent.prototype.ngOnInit = function () {
        var _this = this;
        var masterarray = [];
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.logid = this.activatedRoute.snapshot.paramMap.get("type");
        // console.log('type check',this.logid);
        if (this.logid == 0) {
            this.atype = true;
            this.searchresultnew = new aml_model_1.Aml();
            var val = { atype: 0 };
            this.amldetails.getCompanylogrecord(val).subscribe(function (searchRes) {
                console.log(searchRes);
                _this.searchresult = searchRes; //searchRes;   
            });
        }
        else {
            this.atype = false;
            this.searchresultnew = new aml_model_1.AmlBussiness();
            var val = { atype: 1 };
            this.amldetails.getCompanylogrecord(val).subscribe(function (searchRes) {
                console.log(searchRes);
                _this.searchresult = searchRes;
            });
        }
    };
    AmllogComponent.prototype.dataset = function (values) {
        this.datasetlist = "";
        if (values.PEP == true) {
            this.datasetlist = "<li><p> PEP </p> </li>";
        }
        if (values.AdverseMedia == true) {
            this.datasetlist += " <li><p> Adverse Media</p> </li>";
        }
        if (values.DisqualifiedDirector == true) {
            this.datasetlist += " <li><p>Disqualified Director (UK Only)</p> </li>";
        }
        if (values.FinancialRegulator == true) {
            this.datasetlist += " <li><p>Financial Regulator</p> </li>";
        }
        if (values.Insolvency == true) {
            this.datasetlist += "<li><p> Insolvency (UK & Ireland)</p> </li>";
        }
        if (values.LawEnforcement == true) {
            this.datasetlist += " <li><p>Law Enforcement</p> </li>";
        }
        if (values.CurrentSanctions == true) {
            this.datasetlist += "<li><p> Sanction - Current</p> </li>";
        }
        if (values.PreviousSanctions == true) {
            this.datasetlist += "<li><p> Sanction - Previous</p> </li>";
        }
        this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1);
    };
    AmllogComponent.prototype.outputnewset = function (d) {
        // console.log(">>>>> Updae Code", d);
        var listmaster = JSON.parse(d);
        // console.log(listmaster);
        this.searchresultnew = new aml_model_1.Aml();
        this.searchresultnew = listmaster;
    };
    AmllogComponent.prototype.outputnewBussinessset = function (d) {
        // console.log(">>>>> Updae Code", d);
        var listmaster = JSON.parse(d);
        // console.log(listmaster);
        this.searchresultnew = new aml_model_1.AmlBussiness();
        this.searchresultnew = listmaster;
    };
    return AmllogComponent;
}());
exports.AmllogComponent = AmllogComponent;
