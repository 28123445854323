import { Component, OnInit } from '@angular/core';
import { ExpenseService } from '../../../services/expense.service';
import { EmployeeService } from '../../../services/employee.service';
import { GraphreportService } from '../../../services/graphreport.service';
import { getMonth, getYear } from 'date-fns';
import * as CanvasJS from './../../../admin/canvasjs.min';
import { exists } from 'fs';
import { AppGlobals } from '../../../app.global';

@Component({
  selector: 'app-expchart',
  templateUrl: './expchart.component.html',
  styleUrls: ['./expchart.component.css']
})

export class ExpchartComponent implements OnInit {
  expenselist: any;
  employeelist: any;
  empid: any;
  employeemonth: any;
  employeeyear: any;
  data: any;
  employeeexpensedata: any;
  hstatus: any;
  exptype: any;
  Userid: any;
  record: number;
  items: Array<any>;
  pageOfItems: Array<any>;
  yearList: any;
  expensecountlist: any;

  constructor(
    private expense: ExpenseService,
    private employee: EmployeeService,
    private graphReportService: GraphreportService
  ) { }

  ngOnInit() {
    this.yearList = AppGlobals.YEAR_VALUES;
    this.employeemonth = getMonth(new Date()) + 1;
    this.employeeyear = getYear(new Date());
    this.hstatus = '';
    var value3 = "{}";
    this.employee.getemployeelist(value3).subscribe((listdetails: any) => {
      this.employeelist = listdetails;

      // this.record = this.data.length;
      // if (this.record < 30) {
      //   this.pageOfItems = this.data;
      // }
      // else {
      //   this.items = this.data;
      // }
    });

    this.Userid = localStorage.getItem('userid');
    const sstatustype = { 'status': true, 'id': this.Userid }
    this.expense.getexpenseType(sstatustype).subscribe((expensedetails: any) => {
      this.expenselist = expensedetails;
    
     
    
    
    });

    this.monthlyexpensetypereport();
    this.malefemalereport();
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  monthlyexpensetypereport() {
    this.graphReportService.monthlyexpensetypereport({ 'year': this.employeeyear, 'employeeid': this.empid, 'hstatus': this.hstatus, 'expensetypeid': this.exptype }).subscribe((expensedata: any) => {
      this.employeeexpensedata = expensedata;
      var graphdataarray = [];
      expensedata.forEach((obj, index) => {
        graphdataarray.push({ y: obj.totalamount, label: obj.monthname });
      });
      this.getmonthlyexpensegraph(graphdataarray);
    });
  }

  malefemalereport() {
    this.graphReportService.getexpensegraphList({ 'month': this.employeemonth, 'year': this.employeeyear, 'hstatus': this.hstatus }).subscribe((expdata: any) => {
      this.data = expdata;

     this.expensecountlist = expdata;

      var totalgraphdataarray = [];
      var malegraphdataarray = [];
      var femalegraphdataarray = [];
      expdata.forEach((obj, index) => {
        totalgraphdataarray.push({ x: index + 1, y: obj.count, label: obj.typename.substring(0, 20) });
        // malegraphdataarray.push({ x: index + 1, y: obj.mcount, label: obj.typename.substring(0,20)  });
        // femalegraphdataarray.push({ x: index + 1, y: obj.fcount, label: obj.typename.substring(0,20) });
      });

      var data = [
        {
          type: "stackedBar",
          name: "Employee",
          labelFontSize: 10,
          showInLegend: "true",
          xValueFormatString: "DD, MMM",
          yValueFormatString: "#0",
          dataPoints: totalgraphdataarray
        }
        // {
        //   type: "stackedBar",
        //   name: "Male",
        //   labelFontSize: 10,
        //   showInLegend: "true",
        //   xValueFormatString: "DD, MMM",
        //   yValueFormatString: "#0",
        //   dataPoints: malegraphdataarray
        // },
        // {
        //   type: "stackedBar",
        //   name: "Female",
        //   labelFontSize: 10,
        //   showInLegend: "true",
        //   xValueFormatString: "DD, MMM",
        //   yValueFormatString: "#0",
        //   dataPoints: femalegraphdataarray
        // }
      ];
      this.companyexensegraphreport(data);
    });
  }

  companyexensegraphreport(data: any) {
    var chart = new CanvasJS.Chart("chartContainer2", {
      animationEnabled: true,
      title: {
        text: ""
      },
      axisX: {
        valueFormatString: "DDD",
        interval: 1,
        labelFontSize: 10
      },
      axisY: {
        prefix: ""
      },
      toolTip: {
        shared: true
      },
      legend: {
        cursor: "pointer",
        itemclick: toggleDataSeries
      },
      data: data
    });
    chart.render();

    function toggleDataSeries(e) {
      if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      }
      else {
        e.dataSeries.visible = true;
      }
      chart.render();
    }
  }

  getmonthlyexpensegraph(data: any) {
    var chart = new CanvasJS.Chart("chartContainer", {
      animationEnabled: true,
      exportEnabled: true,
      title: {
        text: ""
      },
      data: [{
        type: "column",
        dataPoints: data
      }]
    });
    chart.render();
  }

  changeEmployee(id: any) {
    this.empid = id;
    this.monthlyexpensetypereport();
    this.malefemalereport();
  }

  changeEmployeeMonth(month: any) {
    this.employeemonth = month;
    this.malefemalereport();
  }

  changeEmployeeYear(year: any) {
    this.employeeyear = year;
    this.monthlyexpensetypereport();
    this.malefemalereport();
  }

  changeEmployeestatus(hstatus: any) {
    this.hstatus = hstatus;
    this.monthlyexpensetypereport();
    this.malefemalereport();
  }

  changeExpenseType(exptype: any) {
    this.exptype = exptype;
    this.monthlyexpensetypereport();
    this.malefemalereport();
  }
}