"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../_services/modal.service");
var common_service_1 = require("../../services/common.service");
var country_service_1 = require("../../services/country.service");
var company_service_1 = require("../../services/company.service");
var forms_1 = require("@angular/forms");
var ClassicEditor = require("@ckeditor/ckeditor5-build-classic");
var platform_browser_1 = require("@angular/platform-browser");
var upload_file_service_1 = require("./../../services/upload-file.service");
var companyprofile_model_1 = require("./../../models/company/companyprofile.model");
var alert_service_1 = require("../../services/alert.service");
var router_1 = require("@angular/router");
var employee_service_1 = require("../../services/employee.service");
var graphreport_service_1 = require("../../services/graphreport.service");
var app_global_1 = require("../../app.global");
var CompanyprofileComponent = /** @class */ (function () {
    function CompanyprofileComponent(sanitizer, commonService, modalService, employeeService, graphService, formBuilder, country, uploadService, company, router, alerts) {
        this.sanitizer = sanitizer;
        this.commonService = commonService;
        this.modalService = modalService;
        this.employeeService = employeeService;
        this.graphService = graphService;
        this.formBuilder = formBuilder;
        this.country = country;
        this.uploadService = uploadService;
        this.company = company;
        this.router = router;
        this.alerts = alerts;
        this.companyprofileDetail = new companyprofile_model_1.Companyprofile();
        this.Editor = ClassicEditor;
        this.options = [];
        this.submitted = false;
        this.countcompanydata = new companyprofile_model_1.Countcompanydata();
        this.currentDate = new Date();
        this.planStartDate = new Date();
        this.planRenewDate = new Date();
        this.planExpiredOn = new Date();
        this.barChartOptions = {
            scaleShowVerticalLines: false,
            responsive: true
        };
        this.barChartLabels = ['Employees'];
        this.barChartType = 'bar';
        this.barChartLegend = true;
        this.barChartData = [
            { data: [0], label: 'Male' },
            { data: [0], label: 'Female' },
            { data: [0], label: '' }
        ];
    }
    CompanyprofileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.monthList = app_global_1.AppGlobals.MONTHS;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        this.ctype = ctype;
        // if(ctype ==1){
        //   this.router.navigate(['/check-dashboard']);
        // }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.profileperm = this.permission.m91;
        this.companyprofileDetail = new companyprofile_model_1.Companyprofile();
        this.companyprofileDetail.license = new companyprofile_model_1.License();
        this.companyprofileDetail.plan = new companyprofile_model_1.Plan();
        this.countcompanydata = new companyprofile_model_1.Countcompanydata();
        this.Userid = localStorage.getItem('userid');
        this.profileformGroup = this.formBuilder.group({
            companylicensedetailsid: ['', forms_1.Validators.required],
            companyid: ['', forms_1.Validators.required],
            companyname: ['', forms_1.Validators.required],
            licensenumber: [''],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            secondryemailid: ['', forms_1.Validators.compose([forms_1.Validators.email])],
            phonenumber: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            sphonenumber: [''],
            companytypeid: [''],
            websiteurl: [''],
            clientname: ['', forms_1.Validators.required],
            clietdesignation: ['', forms_1.Validators.required],
            applicationdate: [''],
            approvaldate: [''],
            cexpiredate: [''],
            payrollmonth: ['', forms_1.Validators.required]
        });
        this.weburl = '';
        // this.graphService.getdepartmentList().subscribe((locationlist: any) => {      
        //   this.companylocationlist = locationlist;      
        // });
        this.company.getcompanylocation().subscribe(function (locationlist) {
            _this.companylocationlist = locationlist;
        });
        this.country.getsoclist().subscribe(function (socdata) {
            _this.socdata = socdata;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.company.getcompanyType().subscribe(function (companytypes) {
            _this.companytypelist = companytypes;
        });
        this.company.getcompanyData().subscribe(function (companydata) {
            _this.companyprofileDetail = companydata;
            _this.weburl = _this.companyprofileDetail.websiteurl.replace('https://', '').replace('http://', '');
            if (!_this.companyprofileDetail.license) {
                _this.companyprofileDetail.license = new companyprofile_model_1.License();
            }
        });
        this.company.getcountCompanyData().subscribe(function (countcompanydata) {
            _this.countcompanydata = countcompanydata;
        });
        this.employeeService.getlistofemployee().subscribe(function (employeedata) {
            var maleArray = [];
            var femaleArray = [];
            employeedata.forEach(function (value) {
                if (value.gender == true) {
                    maleArray.push(value.employeemasterid);
                }
                else if (value.gender == false) {
                    femaleArray.push(value.employeemasterid);
                }
            });
            _this.barChartData = [{ data: [maleArray.length], label: 'Male' },
                { data: [femaleArray.length], label: 'Female' },
                { data: [0], label: '' }
            ];
            //this.barChartData[1].data = [femaleArray.length];
        });
        this.company.getplanDetails().subscribe(function (plandata) {
            var firstDateArray = [];
            var lastDateArray = [];
            var min = 0;
            var max = 0;
            var idArray = [];
            plandata.forEach(function (value) {
                idArray.push(value.planuserdetailid);
                firstDateArray[value.planuserdetailid] = value.joiningdate;
                lastDateArray[value.planuserdetailid] = value.expireddate;
            });
            min = Math.min.apply(null, idArray);
            max = Math.max.apply(null, idArray);
            _this.planStartDate = new Date(firstDateArray[min]);
            var year = _this.planStartDate.getFullYear();
            var month = _this.planStartDate.getMonth();
            var day = _this.planStartDate.getDate();
            _this.planRenewDate = new Date(year + 2, month, day);
            _this.planExpiredOn = new Date(lastDateArray[max]);
            _this.companyplanDetails = plandata;
        });
    };
    Object.defineProperty(CompanyprofileComponent.prototype, "f", {
        get: function () { return this.profileformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    CompanyprofileComponent.prototype.onSubmit = function (companyprofiledata, id) {
        var _this = this;
        this.submitted = true;
        if (this.profileformGroup.invalid) {
            return;
        }
        this.disableBtn = false;
        companyprofiledata.userid = this.Userid;
        this.company.companyprofileUpdate(companyprofiledata).subscribe(function (response) {
            _this.company.getcompanyData().subscribe(function (companydata) {
                _this.companyprofileDetail = companydata;
                _this.weburl = _this.companyprofileDetail.websiteurl.replace('https://', '').replace('http://', '');
                if (!_this.companyprofileDetail.license) {
                    _this.companyprofileDetail.license = new companyprofile_model_1.License();
                }
                _this.modalService.close(id);
                _this.alerts.success('Profile updated successfully', true);
            });
        });
    };
    CompanyprofileComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        var file = this.selectedFiles;
        var imagepath = this.uploadService.uploadfile(file);
        var userid = localStorage.getItem('userid');
        var forupdate = { 'logoname': imagepath, 'id': userid };
        this.company.updatecompanyimage(forupdate).subscribe(function (data) {
            localStorage.setItem('logoname', imagepath);
            setTimeout(function () { return _this.companyprofileDetail.logoname = imagepath; }, 3000);
        });
        this.alerts.success('Profile picture updated successfully', true);
    };
    CompanyprofileComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    CompanyprofileComponent.prototype.onSearchChange = function (searchValue) {
        var _this = this;
        if (searchValue.length > 4) {
            var newArray = [];
            this.commonService.getAddress(searchValue).subscribe(function (data) {
                var newdata;
                newdata = data;
                newdata.addresses.forEach(function (value) {
                    newArray.push(value + "," + searchValue);
                });
                _this.options = newArray;
            });
        }
        else {
            this.options = [];
        }
    };
    CompanyprofileComponent.prototype.displayFn = function (value) {
        if (value != null) {
            var res = value.split(",");
            if (res.length > 6) {
                return res[7];
            }
            else {
                return value;
            }
        }
    };
    CompanyprofileComponent.prototype.openModal = function (id) {
        var _this = this;
        this.submitted = false;
        this.disableBtn = true;
        this.companyprofileDetail = new companyprofile_model_1.Companyprofile();
        this.companyprofileDetail.license = new companyprofile_model_1.License();
        this.companyprofileDetail.plan = new companyprofile_model_1.Plan();
        this.countcompanydata = new companyprofile_model_1.Countcompanydata();
        if ((this.companyprofileDetail.license.companylicensedetailsid == "") || (this.companyprofileDetail.license.companylicensedetailsid == null)) {
            this.companyprofileDetail.license.companylicensedetailsid = "0";
        }
        this.company.getcompanyData().subscribe(function (companydata) {
            _this.companyprofileDetail = companydata;
            if (!_this.companyprofileDetail.license) {
                _this.companyprofileDetail.license = new companyprofile_model_1.License();
            }
        });
        this.employeeService.getlistofemployee().subscribe(function (employeedata) {
            var maleArray = [];
            var femaleArray = [];
            employeedata.forEach(function (value) {
                if (value.gender == true) {
                    maleArray.push(value.employeemasterid);
                }
                else if (value.gender == false) {
                    femaleArray.push(value.employeemasterid);
                }
            });
            _this.barChartData = [{ data: [maleArray.length], label: 'Male' },
                { data: [femaleArray.length], label: 'Female' },
                { data: [0], label: '' }
            ];
        });
        this.buttoninsert = "Update";
        this.modalService.open(id);
    };
    CompanyprofileComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return CompanyprofileComponent;
}());
exports.CompanyprofileComponent = CompanyprofileComponent;
