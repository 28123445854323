"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var CandidateService = /** @class */ (function () {
    function CandidateService(commonService) {
        this.commonService = commonService;
    }
    CandidateService.prototype.getcandidateList = function (value) {
        return this.commonService.formpost('/candidatelistbyjob', value);
    };
    CandidateService.prototype.getcandidatebycompanyList = function () {
        return this.commonService.formpostOnlyid('/candidatelistbycompany');
    };
    CandidateService.prototype.candidatedetailsUpdate = function (value) {
        return this.commonService.formpost('/updatecandidatedetails', value);
    };
    CandidateService.prototype.candidatedetailsCreate = function (value) {
        return this.commonService.formpost('/createcandidatedetails', value);
    };
    CandidateService.prototype.candidatestatusinterested = function (value) {
        return this.commonService.formpost('/candidate/submitisinterested', value);
    };
    CandidateService.prototype.candidatestatusdetailsUpdate = function (value) {
        return this.commonService.formpost('/candidate/submitstatusemailer', value);
    };
    CandidateService.prototype.candidatestatusUpdateOnly = function (value) {
        return this.commonService.formpost('/candidate/submitstatusonly', value);
    };
    CandidateService.prototype.getcandidateById = function (value) {
        return this.commonService.formpost('/candidatebyid', value);
    };
    CandidateService.prototype.getcandidateCommunicationHistory = function (value) {
        return this.commonService.formpost('/candidate/candidatecommunicationhistory', value);
    };
    CandidateService.prototype.deleteFile = function (value) {
        return this.commonService.formpost('/deletefile', value);
    };
    CandidateService.prototype.getselectedcandidate = function (value) {
        return this.commonService.formpost('/company/job/selectedcandidatelist', value);
    };
    CandidateService.prototype.getselectedcandidatebyid = function (value) {
        return this.commonService.formpost('/company/job/candidatebyid', value);
    };
    CandidateService.prototype.getdocumentlist = function (value) {
        return this.commonService.formpost('/candidate/documentlist', value);
    };
    CandidateService.prototype.candidatedocumentupdatebyadmin = function (value) {
        return this.commonService.formpost('/candidate/candidatedocumentupdate', value);
    };
    CandidateService.prototype.deletecandidatedocumentFile = function (value) {
        return this.commonService.formpost('/candidate/candidatedocumentdelete', value);
    };
    /* Candidate Notes Add and get function */
    CandidateService.prototype.getcandidatenotelist = function (value) {
        return this.commonService.formpost('/company/candidate/candidatenote', value);
    };
    CandidateService.prototype.createcandidatenote = function (value) {
        return this.commonService.formpost('/company/candidate/updatecandidatenote', value);
    };
    CandidateService.prototype.deleteCandNote = function (value) {
        return this.commonService.formpost('/company/candidate/deletecandidatenote', value);
    };
    /* Skill Add and delete function */
    CandidateService.prototype.candidateskilladd = function (value) {
        return this.commonService.formpost('/candidate/candidateskilladd', value);
    };
    CandidateService.prototype.candidateskilldelete = function (value) {
        return this.commonService.formpost('/candidate/candidateskilldelete', value);
    };
    /* Document Rename function */
    CandidateService.prototype.getapplicantdocumentbyid = function (value) {
        return this.commonService.formpost('/applicant/applicantdocument', value);
    };
    CandidateService.prototype.updateapplicantdocumentbyid = function (value) {
        return this.commonService.formpost('/applicant/applicantupdatedocumentname', value);
    };
    CandidateService.ngInjectableDef = i0.defineInjectable({ factory: function CandidateService_Factory() { return new CandidateService(i0.inject(i1.CommonService)); }, token: CandidateService, providedIn: "root" });
    return CandidateService;
}());
exports.CandidateService = CandidateService;
