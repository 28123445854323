import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PlanService } from '../../../services/plan.service';
import { PaymentService } from '../../../services/payment.service';
import { Plan } from '../../../models/plan.model';
import { ModalService } from '../../../_services/modal.service';
import { Router } from '@angular/router';
import { StripeScriptTag, StripeToken } from "stripe-angular";
import { AlertService } from '../../../services/alert.service';
import { UserService } from '../../../services/user.service';
import { CompanyService } from '../../../services/company.service';
import { AppGlobals } from '../../../app.global';
import { Address } from '../../../models/address/address.model';
import { CountryService } from '../../../services/country.service';

@Component({
  selector: 'app-checkpaymentmethod',
  templateUrl: './checkpaymentmethod.component.html',
  styleUrls: ['./checkpaymentmethod.component.css']
})
export class CheckpaymentmethodComponent implements OnInit {

  private publishableKey: string = AppGlobals.API_STRIPE;
  currentprice:any;
  cardlist: any;
  companyData:any;
  cardvalue: any;
  extraData: any;
  invalidError: any;
  defaultcard:any;
  addpopForm: FormGroup;
  addresslistDetail:any;
  countrylist: any;
  editCard: any = false;
  cardDetails: any;
  submitted: any = false;
  display:any;
  customer: any;
  paymentloading = false;
  paymentSubmit: any = 0;


  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private router: Router,
    private alerts: AlertService,
    public companyService: CompanyService,
    public StripeScriptTag: StripeScriptTag, 
    public paymentService: PaymentService,
    private country: CountryService
  ) { }

  ngOnInit() {
    this.paymentloading = false;
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var isadmin = localStorage.getItem('isadmin');
    if(isadmin!='true'){
      this.router.navigate(['/permission-denied']);
    }

    this.StripeScriptTag.setPublishableKey(this.publishableKey);

    this.display = 'block';
    
    this.addpopForm = this.formBuilder.group({
      name: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      cityname: ['', Validators.compose([Validators.required,])],   
      statename: ['', Validators.required],
      pincode: ['', Validators.required],     
      stateid: [''], 
      taxid: [''],       
      countryid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      cardid: [''],
      customerid: ['']
    });
    this.getCurrentCompany();


    this.companyService.gettotalcards().subscribe((data: any) => {
        this.defaultcard = data.customer.default_source; 
        console.log(data);
        this.customer =  data.customer;        
        this.cardlist =data.card;
     });



     this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

      this.addresslistDetail = new Address();    
      this.addresslistDetail.countryid ='70';
  }

  get g() { return this.addpopForm.controls; }
  getCurrentCompany()
  {
    this.companyService.getcompanyData().subscribe((data: any) => {
      this.currentprice = data.plan.prices;
      this.companyData = data;      
    });
  }  
  
  removeCard(id:any)
  {
    if(confirm("Do you want to remove this card?")){
      this.cardvalue = {"id": id} ;
      this.paymentService.removeCard({ "id": id }).subscribe((data: any) => {
        this.companyService.gettotalcards().subscribe((data: any) => {
          this.defaultcard = data.customer.default_source; 
            this.cardlist = data.card;
        });
      })
    }
  }


  setDefaultcardCard(id:any)
  {
    if(confirm("Do you want to set this card to default?")){
      this.paymentloading = true;
      this.cardvalue = {"cardid": id} ;
      this.paymentService.defaultCardset({ "cardid": id }).subscribe((data: any) => {
        this.companyService.gettotalcards().subscribe((data: any) => {
          this.defaultcard = data.customer.default_source;          
          this.customer =  data.customer;      this.paymentloading = false;  
          this.cardlist =data.card;
        });
      })
    }
  }

  setStripeToken(token: StripeToken,formvalue:any, id: any) {
    // console.log(token);



    var country = '';
    this.countrylist.forEach(element => {
      if(formvalue.countryid == element.countryid){
        country = element.countryname;
      }
    });
    var data = {
      "id": token.id,
      "customer": this.companyData.stuser,
      "cmonth": token.card.exp_month,
      "cyear": token.card.exp_year,
      "cardnumber": token.card.last4,
      "tokenid": token.card.id,
      "cardname": token.card.brand,
      "country": token.card.country,
      "cstatus": "1",
      "cardholdername": formvalue.name,
      "address1": formvalue.address1,
      "address2": formvalue.address2,
      "cityname": formvalue.cityname,
      "statename": formvalue.statename,
      "cardcountry": country,
      "postcode": formvalue.pincode,
      "taxid": formvalue.taxid
    };
    this.submitted = true;
    if(this.addpopForm.invalid){
      return;
    }
    if(this.paymentSubmit == 0){
      this.paymentSubmit = 1;
    this.paymentService.addnewCard(data).subscribe((data: any) => {
      if (data.success == "success") {
        this.companyService.gettotalcards().subscribe((data: any) => {
          this.alerts.success("Card has been successfully added.", true);
          this.cardlist = data.card;          
          this.modalService.close(id);
          this.paymentSubmit = 0;
          this.StripeScriptTag.setPublishableKey(this.publishableKey);
          this.getCurrentCompany();
        });
      }else
      {
        this.paymentSubmit = 0;
      }
    });
  }
  }
  
  onStripeError(error: Error) {
    console.error('Stripe error', error)
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  editcard(id: string,value:any){
    this.submitted = false;
    this.display = 'none';
    this.editCard = true;
    var countryid = 70;
    this.cardDetails = value;
    this.countrylist.forEach(element => {
      if(value.address_country == element.countryname){
        countryid = element.countryid;
      }
    });
    this.addpopForm.reset({
      name: value.name,
      address1: value.address_line1,
      address2: value.address_line2,
      cityname: value.address_city,   
      statename: value.address_state,
      pincode: value.address_zip,     
      taxid: (this.customer.metadata)?this.customer.metadata.taxid:'',       
      countryid: countryid,
      cardid: value.id,
      customerid: value.customer
    });
    this.modalService.open(id);
  }

  updateyourplan(id: string) {
    this.editCard = false;
    this.submitted = false;
    this.display = 'block';
    this.addpopForm.reset({
      name: '',
      address1: '',
      address2: '',
      cityname: '',   
      statename: '',
      pincode: '',     
      taxid: (this.customer.metadata)?this.customer.metadata.taxid:'',       
      countryid: 70,
      cardid: '',
      customerid: ''
    });
    this.modalService.open(id);
  }

  updateCard(value: any, id){
    this.submitted = true;
    if(this.addpopForm.invalid){
      return;
    }
    this.countrylist.forEach(element => {
      if(value.countryid == element.countryid){
        value.cardcountry = element.countryname;
      }
    });
    // console.log(value);
    this.paymentService.updateCard(value).subscribe((data: any) => {
      // console.log(data);
      if (data.success == "success") {
        this.companyService.gettotalcards().subscribe((data: any) => {
          console.log(data);
          this.alerts.success("Card has been successfully updated.", true);
          this.cardlist = data.card;          
          this.modalService.close(id);
          this.getCurrentCompany();
        });
      }
    });
  }


}
