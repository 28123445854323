<app-header>
</app-header>
<div  class="section-wrapper">
    <div   class="container">
        <div   class="tabs" id="tabs">
            <a [routerLink]="['/ai-dashboard']" *ngIf="ctype ==0" class="backlist  mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to AI Dashboard</a>
            <a [routerLink]="['/check-identity-verification']" *ngIf="ctype ==1" class="backlist  mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Identity Check</a>
            <div class="clearfix"></div>
            <div class="aligncenter width60 mrled">
                <p class="mb-0">There are no credits available to perform this action. Please add more credits.</p>
                <a class="btn btn-primary com_btn" [routerLink]="['/check-add-credit']">Buy Now</a>
            </div>
            
    </div>
    </div>
</div>
<div class="aiverification_section aimain">
    <div class="ai_content_section">
        <div class="ai_content">
            <div class="ai_wizard">
                <app-passportaiwizard></app-passportaiwizard>
                <div class="content clearfix">
                    <div class="admin_pro_section aidetail mr-4">
                        <div class="cseec">
                            <div class="admin_com_logo" >
                                <img src="{{ passportdetails.employee?.imagename }}">
                            </div>
                        </div>
                        <div class="albox width100">
                            <div class="aliii">
                                <div class="ai_admin_details width28">
                                    <span class="subheading">Name</span>
                                    <span class="heading">{{ passportdetails.employee?.fname }} {{ passportdetails.employee?.mname }} {{ passportdetails.employee?.lname }}</span>
                                </div>
                                <div class="ai_admin_details width40">
                                    <span class="subheading">Email</span>
                                    <span class="heading">{{ passportdetails.employee?.emailid }}</span>
                                </div>
                                <div class="ai_admin_details width22">
                                    <span class="subheading">Phone</span>
                                    <span class="heading">{{ passportdetails.employee?.phonenumber }}</span>
                                </div>
                            </div>
                            <div class="aliii">
                                <div class="ai_admin_details width28">
                                    <span class="subheading">NI Number</span>
                                    <span class="heading">{{ passportdetails.employee?.ninumber }}</span>
                                </div>
                                <div class="ai_admin_details width40">
                                    <span class="subheading">Employee ID</span>
                                    <span class="heading">{{ passportdetails.employee?.employeeid }}</span>
                                </div>
                                <div class="ai_admin_details width22">
                                    <span class="subheading">Nationality</span>
                                    <span class="heading badge badge-danger">{{ passportdetails.employee?.nationality?.countryname }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="btnai">
                            <button type="text" class="btn btn-primary">EMP - YES58485</button>
                            <button type="text" class="btn btn-danger">IN - YES58485</button>
                        </div> -->
                    </div>
                    <div class="white_box_shadow pro_plan_details admin_pro_footer width32 visaimg rmovepadd">
                        <img src="{{ passportdetails.passportdocname }}" class="width100 imageai_align" style="height:220px; display: initial;">
                        <!-- <div class="overlay_bg">
                          <button type="submit" class="btn btn-primary com_btn">Change Visa</button>
                      </div> -->
                    </div>
                    <div class="white_box_shadow pro_plan_details admin_pro_footer width32 visaimg rmovepadd">
                        <img src="{{ passportdetails.passportbackdocname }}" class="width100 imageai_align" style="height:220px; display: initial;">
                        <!-- <div class="overlay_bg">
                          <button type="submit" class="btn btn-primary com_btn">Change Visa</button>
                      </div> -->
                    </div>
                    <div class="white_box_shadow pro_plan_details admin_pro_footer width32 visaimg rmovepadd">
                        <img src="{{ logoimage3 }}" class="width100 imageai_align" style="height:220px; display: initial;">
                        
                    </div>

                    <div class="clearfix"></div>
                    <div class="mb-2">
                        <div class="payment_text float-left">
                            <p class="reqinfo">Your package includes 25 free AI ID verification checks. Any additional checks are chargeable</p>
                        </div>
                        <!-- <div class="payment_text_btn float-right">
                            <button type="submit" class="btn btn-danger com_btn">Add Card</button>
                        </div> -->
                    </div>
                    <div class="clearfix"></div>
                    <!-- <div class="card_box">
                        <div class="card_list">
                            <div class="cards">
                                <img src="assets/img/mastercard.png">
                                <div class="cardno">
                                    <span class="cardname">xxxx xxxx xxxx 4444</span>
                                    <span class="cardname_text">MasterCard - Expires 2 2022</span>
                                </div>
                                <div _ngcontent-c1="" class="card_date"><span _ngcontent-c1="" class="date"></span></div>
                            </div>
                        </div>
                    </div> -->
                    <div class="ai_wizard_btn mr-4">
                        <button type="submit" [routerLink]="['/passportaifacedetails',employeevisadeatilid]"  class="btn btn-danger com_btn float-left">Previous</button>
                        <!-- <button type="submit" (click)="Sendvisa()" *ngIf="aipayment ==true" class="btn btn-secondary com_btn float-right">Pay Now (£10) </button> -->
                        <button type="submit" (click)="Sendvisa()" *ngIf="remainingcheck > 0" class="btn btn-secondary com_btn float-right">Process using AI</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<jw-modal id="payment-fail" class="modal" role="dialog">
    <div role="document" class="formgroup">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-passport');">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </jw-modal>