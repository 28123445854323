"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Plan = /** @class */ (function () {
    function Plan() {
    }
    return Plan;
}());
exports.Plan = Plan;
var checkbiling = /** @class */ (function () {
    function checkbiling() {
        this.totalcheck = 0;
        this.usedcheck = 0;
        this.remiancheck = 0;
    }
    return checkbiling;
}());
exports.checkbiling = checkbiling;
