import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { CommonService } from '../../../services/common.service';
import { CountryService } from '../../../services/country.service';
import { CompanyService } from '../../../services/company.service';
import { Router } from '@angular/router';
import { AssetService } from '../../../services/asset.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Expensetype } from '../../../models/expense/expensetype.model';
import { MatOptionSelectionChange } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DomSanitizer } from '@angular/platform-browser';
import { ExpenseService } from '../../../services/expense.service';
@Component({
  selector: 'app-settingexpensetype',
  templateUrl: './settingexpensetype.component.html',
  styleUrls: ['./settingexpensetype.component.css']
})
export class SettingexpensetypeComponent implements OnInit {

  Userid: any;
  data: any;
  expenseDetail = new Expensetype();
  expenseformGroup: FormGroup;
  options = [];
  buttoninsert: any;
  submitted: boolean = false;
  public searchText: string;
  disableBtn: any;
  permission: any;
  settingperm: any;
  record:number;
  
  items: Array<any>;
  
  pageOfItems: Array<any>;
  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private expense: ExpenseService
  ) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.settingperm = this.permission.m81;

    this.Userid = localStorage.getItem('userid');
    this.expenseDetail = new Expensetype();
    this.getexpensetypeformgroup();
    const sstatustype = { 'status': false, 'id': this.Userid } 
    this.expense.getexpenseType(sstatustype).subscribe((expensetypedetails: any) => {
      this.data = expensetypedetails;
      
      this.record = this.data.length;

      if(this.record < 30){
        this.pageOfItems = this.data;
      }
      else{
           this.items =this.data;
      }
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  getexpensetypeformgroup()
  {
    this.expenseformGroup = this.formBuilder.group({
      id: [''],
      typename: ['', Validators.required]
    });
  }

  get f() { return this.expenseformGroup.controls; }

  onSubmit(formexp: any, id: string) {
    // var formexp = settingexpensetypedata.value;
    this.submitted = true;
    if (this.expenseformGroup.invalid) {
      return;
    }
    this.disableBtn = false;
    formexp.userid = this.Userid;
    this.expense.settinghourtypeUpdate(formexp).subscribe((response: any) => {
      const sstatustype = { 'status': false, 'id': this.Userid } 
      this.expense.getexpenseType(sstatustype).subscribe((expensetypedetails: any) => {
        // settingexpensetypedata.reset();
        this.data = expensetypedetails;
        this.modalService.close(id);
      });
    });
  }

  onChangeStatus(evt: any, expensetype: any)
  {
    var cstatus;
    if(evt.target.checked)
    {
      cstatus = '1';
    }
    else if(!evt.target.checked)
    {
      cstatus = '0';
    }
    this.expense.expensetypeUpdateChangeStatus({'userid': this.Userid, 'id': expensetype.id, 'cstatus': evt.target.checked}).subscribe((data: any) => {
      const sstatustype = { 'status': false, 'id': this.Userid } 
      this.expense.getexpenseType(sstatustype).subscribe((expensetypedetails: any) => {
        this.data = expensetypedetails;
      });
    });
  }

  openModal(id: string, data: any) {
    this.buttoninsert = "Update";
    this.disableBtn = true;
    this.expenseDetail = data;
    this.expenseformGroup.reset(this.expenseDetail);
    this.modalService.open(id);
  }

  openModal2(id: string) {
    this.buttoninsert = "Submit";
    this.submitted = false;
    this.disableBtn = true;
    this.expenseDetail = new Expensetype();
    this.expenseDetail.id = 0;
    this.expenseformGroup.reset(this.expenseDetail);
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

}
