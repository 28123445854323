<div *ngIf="visa">
  <h3 class="slim-card-title">Visa</h3>
  <div class="billed-to">
    <h6 class="tx-gray-800">Visa Number: {{visa.visanumber}}
    </h6>
    <p><b>Visa Type:</b> {{visa.visatype }}</p>
    <p><b>Start Date:</b>{{visa.visastart | date: 'dd MMM yyyy'}}</p>
    <p><b>Expiry Date:</b>{{visa.visaexpiry | date: 'dd MMM yyyy'}}</p>
    <p><b>Visa issue country: </b> {{visa.nationality?.countryname}} &nbsp;
     <a class="viewdetails" (click)="openModal3('pdf-image-viewer',visa.visadocname)" *ngIf="visa.visadocname"><i class="fa fa-file-image-o mg-r-10" ></i>&nbsp;</a> 
     <!-- <a class="viewdetails" (click)="openModal('custom-visa-1',visa)"><i class="fa fa-pencil" ></i>&nbsp;</a> -->
      <!-- <i class="fa fa-trash-o mg-l" *ngIf="vs.stype == '1'" (click)="onDelete(vs.employeevisadeatilid)"></i> -->
      <!-- <i class="fa fa-pencil-square-o" aria-hidden="true" data-toggle="modal" data-target="#visadata" (click)="openModal('custom-visa-1',visa)"></i></p> -->
  </div>
</div>
<jw-modal id="custom-visa-1" class="modal" role="dialog">
  <form [formGroup]="VisaForm" #visaForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(visaForm.value,'custom-visa-1')"
    *ngIf="visaDetail">
    <div role="document" class="formgroup">
      <div class="modal-content">
        <div class="modal-header">
          <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid"
            [(ngModel)]="visaDetail.employeeid">
          <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" 
          [(ngModel)]="visaDetail.employeevisadeatilid">
          <h5 class="modal-title" id="popupLabel">Visa Details</h5>
          <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-visa-1');">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Visa No.*</label>
                <input type="visanumber" formControlName="visanumber" class="" name="visanumber" id="visanumber" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.visanumber.errors }" [(ngModel)]="visaDetail.visanumber">
                <div *ngIf="submitted && f.visanumber.errors" class="invalid-feedback">
                  <div *ngIf="f.visanumber.errors.required">Please enter visa number</div>
                </div>
              </div>
              <div class="form-group col-lg-6">
                <label>Visa Type*</label>
                <input type="visatype" formControlName="visatype" class="" name="visatype" id="visatype" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.visatype.errors }" [(ngModel)]="visaDetail.visatype">
                <div *ngIf="submitted && f.visatype.errors" class="invalid-feedback">
                  <div *ngIf="f.visatype.errors.required">Please enter visa type</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Start Date*</label>
                <mat-form-field class="datecontrol">
                  <input matInput [matDatepicker]="picker1" name="visastart" id="visastart" formControlName="visastart"
                    class="form-control" [(ngModel)]="visaDetail.visastart">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group col-lg-6">
                <label>Expiry Date*</label>
                <mat-form-field class="datecontrol">
                  <input matInput [matDatepicker]="picker2" id="visaexpiry" name="visaexpiry" formControlName="visaexpiry"
                    class="form-control" [(ngModel)]="visaDetail.visaexpiry">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Visa Issue Country*</label>
                <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid"
                  class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }"
                  [(ngModel)]="visaDetail.nationalityid" >
                  <option [value]="defaultValue">Select Visa Country </option>
                  <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                </select>
                <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                  <div *ngIf="f.nationalityid.errors.required">Please select visa issue country</div>
                </div>
              </div>
              <div class="col-lg-6">
                <label>VISA</label>
                <div class="input-group mb-3" (click)="file.click()">
                  <div class="custom-file">
                    <input type="file" #file (change)="selectFile($event)">
                    <label class="custom-file-label">{{filename}}</label>
                  </div>
                </div>
                <div class="alert-danger" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                <div class="alert-danger" *ngIf="fileformaterror == true">Please choose a valid file format
                  (jpg, png, tif, gif, pdf, doc)</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <input type="submit" class="btn btn-primary" name="updateform" value="Update" />
          <input type="button" class="btn btn-primary" name="updateform" value="Cancel"   (click)="closeModal('custom-visa-1');"/>
        </div>
      </div>
    </div>
  </form>
</jw-modal>
<jw-modal id="pdf-image-viewer" class="modal" role="dialog">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 text-center">
              <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="400" frameborder="0"></iframe>
              <img *ngIf="imgsrc == true" [src]="iframesrc" class="imgresponsive"  />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</jw-modal>