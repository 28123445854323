<style>
    .mini-stat {
        border: 1px solid rgba(112, 112, 112, 0.12);
        padding: 10px 15px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        margin-bottom: 20px;
    }
    
    .mini-stat-icon {
        width: 30px;
        height: 30px;
        display: inline-block;
        line-height: 27px;
        text-align: center;
        font-size: 14px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        float:left;
        margin-right: 10px;
        color: #ffffff;
    }
    
    .bg-purple {
        background-color: #6d60b0;
    }
    
    .mini-stat-info {
        font-size: 13px;
        padding-top: 2px;
        width: 80%;
        float: left;
    }
    
    .mini-stat-info span {
        display: block;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 20px;
    }
</style>


<div class="bg">
    <app-header></app-header>


    <div class="section-wrapper">
        <div class="container">

           
            <div class="tabs" id="tabs">

                <div class="tabs__content">

                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="space_em row">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Admin List </h5>
                                </div>

                               
                                <div class="col-lg-2">
                                    <button class="btn com_btn btn-danger small_btn" (click)="uploadByAdmin('add-new-applicant')"> + Add New Admin </button>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group">
                                        <input [(ngModel)]="searchText" placeholder="Search.." class="form-control">
                                    </div>
                                </div>

                            </div>

                            <ng-container *ngIf="( datalist | filter: {fname: searchText,mname: searchText,lname: searchText,emailid: searchText,countryname: searchText,phonenumber: searchText  }) as pageOfItems"> 
                         
                            <div class="job_list_section employee_list_section leave_section" *ngFor="let employee of pageOfItems; let i=index">

                                <div class="job_detils_date width20">
                                    <p class="job_address">Name</p>
                                    <h5 class="job_profile font_17">{{employee.fname}} {{employee.mname}} {{employee.lname}}</h5>

                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address">Mobile Number</p>
                                    <p class="job_profile">{{employee.phonenumber}} </p>
                                </div>

                                <div class="job_detils_date width22">
                                    <p class="job_address">Email</p>
                                    <p class="job_profile">{{employee.emailid}}</p>
                                </div>
                                <!-- <div class="job_detils_date width13">
                                    <p class="job_address">Nationality</p>
                                    <span class="badge badge-pill badge-danger" *ngIf="employee.nationality"> {{employee.nationality.countryname}}</span>

                                </div> -->
                                <div class="job_detils_date width13">
                                    <p class="job_address">Action</p>
                                    <a (click)="addadmin(employee.employeemasterid)" class="text-success" *ngIf="(employee.isadmin==false) && (employee.employeemasterid != employee.company.userid)"><b>Add
                                      Admin</b></a>
                                    <a (click)="removeadmin(employee.employeemasterid)" *ngIf="(employee.isadmin==true) && (employee.employeemasterid != employee.company.userid)" class="text-danger"><b>Remove Admin</b></a>
                                </div>
                                <div class="job_detils_date width7 text-center" *ngIf="ctype !=3 && ctype !=2">
                                    <p class="job_address">Permission</p>
                                    <p class="job_profile">
                                        <!-- <i class="fa fa-lock font26" (click)="openModal('custom-menu',employee, employee.employeemasterid)"></i> -->
                                        <a [routerLink]="['/managepermission',employee.employeemasterid ]"> <i class="fa fa-lock font26"></i></a>
                                    </p>
                                </div>

                                <div class="job_detils_date width7 text-center" *ngIf="ismonitoring" >

                                    <p class="job_address" >SSO Enable</p>
                                    <div class="onoffswitch" >
                                        <label class="onoffswitch-label" for="skill{{i+1}}" class="switch">
                                            <input type="checkbox" name="{{ employee.issso }}" type="checkbox"
                                                id="skill{{i+1}}" [checked]="employee.issso"
                                                (change)="onChangeStatus($event, employee)">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>


                                    <!-- <h5 class="job_profile font_17" *ngIf="employee.employeemasterid == employee.company.userid">&nbsp;</h5> -->

                                </div>
                            </div>


                            <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>  
                               
                         </ng-container>


                            <!-- <div class="row">
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<jw-modal id="custom-menu" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="popupLabel">Manage Permissions</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-menu');">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div id="ContentPlaceHolder1_pnlView" class="container">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-xl-4" *ngFor="let employeels of menulist;  let i=index">
                                <div class="mini-stat clearfix bg-white">
                                    <span class="mini-stat-icon bg-purple mr-0 float-right"><i class="{{employeels.classname}}"></i></span>
                                    <div class="mini-stat-info">
                                        <span class="counter text-purple">{{ employeels.menuname }}</span>

                                    </div>
                                    <div class="clearfix"></div>
                                    <table id="ContentPlaceHolder1_cblDashboard" class="dash" style="width:100%;">
                                        <tbody>
                                            <tr *ngFor="let submenuname of employeels.submenu;  let i2=index">
                                                <td><input id="st{{ submenuname.menuid }}" type="radio" name="st{{ employeels.menuid}}" (change)="onchange(employeels.clname, submenuname.mright)" value="0" [checked]="submenuname.ch" style="float:left">
                                                    <label for="ContentPlaceHolder1_cblDashboard_0" style="float:left; width:90%;margin-left: 2%;margin-top: -5px;">{{ submenuname.menuname }}</label></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>



<jw-modal id="add-new-applicant" role="dialog" class="modal">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add New Admin</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                    (click)="closeModal('add-new-applicant');">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <form [formGroup]="employeeForm" #overviewForm="ngForm" class="s12 white"  (ngSubmit)="onAddEmployeeSubmit(overviewForm.value)">
      
            <div class="modal-body">
                <div class="container">
                   
                        <div class="formgroup">
                           
                          <div class="row">
                            <div class="form-group col-lg-4">
                                <label>First Name*</label>
                                <input type="fname" formControlName="fname" name="fname" id="fname" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.fname.errors }"
                                    [ngModel]="overviewData.fname" maxlength="50">
                                <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                                    <div *ngIf="f.fname.errors.required">Please enter first name</div>
                                </div>
                            </div>
                  
                            <div class="form-group col-lg-4">
                                <label>Middle Name</label>
                                <input type="mname" formControlName="mname" name="mname" id="mname" class="form-control"
                                    [ngModel]="overviewData.mname" maxlength="50">
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Last Name*</label>
                                <input type="lname" formControlName="lname" name="lname" id="lname"
                                    class="form-control input-lg"
                                    [ngClass]="{ 'is-invalid': submitted && f.lname.errors }"
                                    [ngModel]="overviewData.lname" maxlength="50">
                                <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                                    <div *ngIf="f.lname.errors.required">Please enter last name</div>
                                </div>
                            </div>
                        </div>
                  
                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>Username*</label>
                                    <div class="input-group">
                                        <input type="text" (focusout)="onUserNameOutEvent($event)" formControlName="username" #Username [(ngModel)]="overviewData.username"
                                            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                            placeholder="Username " [maxlength]="200" >
                                       
                                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                            <div *ngIf="f.username.errors.required">Please enter username</div>
                                        </div>
                                        <span *ngIf="username=='true'" style="color:red; width: 100%; font-size: 80%;">Username already exists.</span>
                                        <span *ngIf="username=='false'" style="color:green; width: 100%; font-size: 80%;">Username available.</span>
                                    </div>
                                          
                            </div>
                          <div class="form-group col-lg-4">
                              <label>Email*</label>
                              <input type="emailid" (focusout)="onFocusOutEvent($event)"  (focus)="focusFunction()" maxlength="100"
                                  formControlName="emailid" name="emailid" id="emailid" class="form-control"
                                  [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }"
                                  [ngModel]="overviewData.emailid">
                              <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                                  <div *ngIf="f.emailid.errors.required">Please enter email </div>
                              </div>
                              <div class="invalid-feedback">
                                  <div class="error" *ngIf="emailexist">This email is already registered</div>
                              </div>
                          </div>
                          <div class="form-group col-lg-4">
                              <label>Mobile Number*</label>
                              <international-phone-number formControlName="phonenumber"
                                  class="form-control phonecontrol"
                                  [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }"
                                  [ngModel]="overviewData.phonenumber" placeholder="Enter mobile number"
                                  (input)="validatenumber($event.target.value,'phone')" [maxlength]="20"
                                  [required]="true" #mobilenumber name="phonenumber"></international-phone-number>
                              <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                  <div *ngIf="f.phonenumber.errors.required">Please enter mobile number</div>
                              </div>
                              <span *ngIf="phonecheck===false" style="color:red">Invalid format</span>
                              <span *ngIf="phonecheck===true" style="color:green">Valid format</span>
                          </div>
                      </div>
                        
                      </div>
                 </div>
            </div>
            <div class="modal-footer">
                
                <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Add Admin"    />
                  
                  
                <input type="button" class="btn btn-secondary com_btn" name="updateform" (click)="closeModal('add-new-applicant')" value="Cancel" />
                
            </div>

        </form>
        </div>
    </div>
  </jw-modal>


  
<jw-modal id="alreadyadd-request" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">
           
            <div class="modal-body">
                <div class="container">
                  <div class="info_mess">                     
                      <div class="para text-left">
                          <h4 class="mar-20 red" *ngIf="samecompany">This applicant already exists in your account. </h4>
                          <h4 class="mar-20 red" *ngIf="!samecompany">This applicant already exists in our system. </h4>
                          <ul *ngIf="samecompany">
                              <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                  Send an email to the applicant to Video call for Right to Work Check.</li>
                              <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                   Once the request has been uploaded you will be notified by an email. </li>
                             
                          </ul>                           
                      </div>                          
                  </div>
                 </div>
            </div>
            <div class="modal-footer">
              <input type="button" *ngIf="samecompany" class="btn  btn-warning com_btn"  (click)="sendrequest(employeeid,'sendrequestcharge')"  name="updateform" value="Start New Check" />
              <!-- <input type="button" class="btn btn-danger com_btn" name="updateform" value="Add Biometric Residence Permit" /> -->
                <input type="button" class="btn btn-dark com_btn" (click)="closeModal('alreadyadd-request');" name="updateform" value="Cancel" />
            </div>
        </div>
    </div>
  </jw-modal>   