import { Component, OnInit } from '@angular/core';
import { ContactService } from '../services/contact.service';
import { ModalService } from '../_services/modal.service';
import { Address } from '../models/address/address.model';
import { UploadFileService } from './../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CommonService } from './../services/common.service';
import { Contactinfo } from '../models/employee.model';
import { MatOptionSelectionChange } from '@angular/material';
import { CountryService } from '../services/country.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-mycontactinfo',
  templateUrl: './mycontactinfo.component.html',
  styleUrls: ['./mycontactinfo.component.css']
})

export class MycontactinfoComponent implements OnInit {
  form: FormGroup;
  contactformGroup: FormGroup;
  address = new Address();
  countrylist: any[];
  contactDetail: any;
  Userid: any;
  file: any;
  selectedFiles: FileList;
  iframesrc: any;
  imgsrc: any;
  buttoninsert: any;
  formattedMessage: any;
  messageSuccess:any;
  filename: any;
  options = [];
  workphonecheck: any;
  mobilecheck: any;
  homephonecheck: any;
  submitted: boolean;
  defaultVal: any = "";
  listcheck: any;
  
  constructor(
    private commonService: CommonService, 
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder,
    private data: ContactService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private alerts: AlertService,
    private router: Router
    ) { }

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    
    this.contactformGroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      kinname: [''],
      employeerelation: [''],
      workphone: ['', Validators.required],
      mobile: [''],
      homephone: [''],
      workemail: ['', Validators.compose([Validators.required, Validators.email])],
      personalemail: ['', Validators.email],
      kinmobile: [''],
      kinemail: ['', Validators.email],
      telephone: [''],
      kinaddress1: [''],
      kinaddress2: [''],
      kinpincode: [''],
      kincityname: [''],
      kincountyname: [''],
      kincountryid: [''],
    });

    this.onChanges();
    this.contactDetail = new Contactinfo();
    this.filename = 'Choose file';
    this.Userid = localStorage.getItem('userid');
    const data = { 'userid': this.Userid, "employeeid": this.Userid };
    this.data.getContactInfo(data).subscribe((data: any) => {
      
      this.contactDetail = data;
    });

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
  }
  onChangeDate(event: any)
  {
    
  }
  
  onChanges(): void {
    this.formattedMessage = '';
    this.contactformGroup.valueChanges.subscribe(val => {
      this.formattedMessage = 'Hello, My name is and my email is. I would like to tell you that.';
    });
  }

  get f() { return this.contactformGroup.controls; }

  onSubmit(value3: any, id: string) {
    this.submitted = true;
    if (this.contactformGroup.invalid) {
      return;
    }
    if(value3.kinpincode != '' && value3.kinpincode != undefined)
    {
      var pinc = value3.kinpincode.split(',');
      value3.kinpincode = pinc[pinc.length - 1];
    }
    value3.userid = this.Userid;

    this.data.employeeContactinfoUpdate(value3).subscribe((data: any) => {
      const data1 = { 'userid': this.Userid, "employeeid": this.Userid };
      this.data.getContactInfo(data1).subscribe((data: any) => {
        return this.contactDetail = data;
      });
    });
    this.messageSuccess = 'Contact info updated successfully'; 
    this.alerts.success(this.messageSuccess, false);
    this.modalService.close(id);
  }

  selectFile(event) {
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = files[0];
  }

  onSearchChange(searchValue) {
    if (searchValue.length > 4) {
      var newArray = [];
      this.commonService.getAddress(searchValue).subscribe((data: any) => {
        var newdata: any;
        newdata = data;
        newdata.addresses.forEach(function (value) {
          newArray.push(value + "," + searchValue);
        });
        this.options = newArray;
      });
    }
    else {
      this.options = [];
    }
  }

  displayFn(value: any) {
    if (value != null) {
      var res = value.split(",");
      if (res.length > 6) {
        return res[7];
      }
      else {
        return value;
      }
    }
  }

  updatecode(evt: MatOptionSelectionChange, response) {
    res = [];
    if (evt.source.selected) {
      var res = response.split(",");
      this.contactDetail.kinaddress1 = res[0];
      this.contactDetail.kinaddress2 = res[1];
      this.contactDetail.kincityname = res[5];
      this.contactDetail.kinpincode = res[7];
      this.contactDetail.kincountyname = res[6];
    }
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'workphone') {
        this.workphonecheck = getdata.valid;
      }
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'homephone') {
        this.homephonecheck = getdata.valid;
      }
    });
  }

  onDelete(empid: number) {
    if (confirm("Are you sure you want to delete?")) {
      const data1 = { 'id': empid };
    }
  }

  openModal2_kin(id: string) {
    this.submitted = false;
    this.contactDetail = new Contactinfo();
     this.buttoninsert = "Submit";
    this.contactDetail.employeeid = this.Userid;
    this.contactDetail.id = "0";
    this.contactDetail.kincountryid = "70";
    this.modalService.open(id);
  }

  openModal_kin(id: string, data: any) 
  {
    this.workphonecheck = 'noval';
    this.mobilecheck = 'noval';
    this.homephonecheck = 'noval';
    this.contactDetail = new Contactinfo();
    this.buttoninsert = "Update";
    // this.data.getContactInfobyadmin(data).subscribe((data: any) => {
      this.contactDetail = data;
    // });
  
    if(this.contactDetail.kincountryid = '0')
    {
      this.contactDetail.kincountryid = '';
    }
    this.modalService.open(id);
  }

  getSafeUrl(url) 
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModal3(id: string, data: any) 
  {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  closeModal(id: string) 
  {
    // this.passportDetail = new Passport();
    const data = { 'userid': this.Userid, "employeeid": this.Userid  };
    this.data.getContactInfo(data).subscribe((data: any) => {
      this.contactDetail = data;
    });
    this.modalService.close(id);
  }

  modalOpen(data: any) 
  {
    // this.passportDetail = new Passport();
    // this.passportDetail = data;
  }
}