<app-header></app-header>
<div class="container">


  <div class="overviewsshow check_support mt-4">

        
      <div class="row row-sm">
        <div class="col-lg-8">
            <div>
                <app-checkcountshow  ></app-checkcountshow>                                   
            </div>
            
        </div>                             
        <div class="col-lg-4 text-right">
          <button type="button" class="btn btn-secondary com_btn" (click)="newSearch()" data-dismiss="modal" *ngIf="listview">New Search</button>                        
        </div>                               
    </div>

    <div class="nosearch mt-3"   *ngIf="!listview">
      <div class="header aligncenter mb-3">
        <h1 class="headprofile mt-0 mb-0">Tax Defaulters Check</h1>
      </div>
      <hr/>
      <form [formGroup]="taxFormgroup" #taxForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(taxForm.value,'Em_support')">
      <div class="formgroup">
        <div class="row row-sm mg-t-10">
            <div class="col-sm-6 mb-2 ">
                <label class="labelresize mb-0">Business Name*</label>
              
            <input type="text" formControlName="bussinessname" [ngClass]="{ 'is-invalid': submitted && f.bussinessname.errors }" name="bussinessname" id="bussinessname"  class="form-control"  [maxlength]="100" placeholder="Enter Business Name">
            <div *ngIf="submitted && f.bussinessname.errors" class="invalid-feedback">
                <div *ngIf="f.bussinessname.errors.required">Please Enter Business Name</div>
                <div *ngIf="!f.bussinessname.errors.required && f.bussinessname.errors.minlength">Minimum 3 characters Required</div>
            </div>  
            </div>
            <div class="col-sm-6 mb-2">
                <label class="labelresize mb-0">Business Trade or Occupation*</label>
              
                <input type="text" formControlName="businesstrade" [ngClass]="{ 'is-invalid': submitted && f.businesstrade.errors }" name="businesstrade" id="businesstrade"  class="form-control"  [maxlength]="100" placeholder="Enter Business Trade or Occupation">
                 <div *ngIf="submitted && f.businesstrade.errors" class="invalid-feedback">
                    <div *ngIf="f.businesstrade.errors.required">Please Enter Business Trade or Occupation</div>
                    <!-- <div *ngIf="!f.bussinessemail.errors.required && f.bussinessemail.errors.minlength">Minimum 3 characters Required</div> -->
                </div> 
            </div>
           


        </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-sm-6 text-left">
        <span *ngIf="lesssore">Sorry, You don't have any credits. Please  <a [routerLink]="['/check-add-credit']">Click Here</a> to add more.</span>
    </div>
    <div class="col-sm-6 text-right">

      
         <button  type="submit" class="btn btn-primary com_btn" *ngIf="!lesssore"  [disabled]="disabled"> Submit</button>
        <button type="button" class="btn btn-secondary com_btn" (click)="clearData()" data-dismiss="modal">Clear</button> 
      </div>
  </div>
  </form>
</div>

  <div class="taxdefaulter mt-3" *ngIf="listview">


    <div class="row">
      <div class="col-sm-12 aligncenter mb-3">
        <h5 class="hedingcolor">Tax Defaulters List</h5>                              
        <input type="text" [(ngModel)]="searchText" class="form-control width35"  *ngIf="record != 0" placeholder="Search">
      </div>
  </div> 
  <div class="browser-screen-loading-content" *ngIf="!pageOfItems" >
      <div class="loading-dots dark-gray">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>                                
          <i></i>
      </div>
      <p>Loading</p>
  </div>

  <div class="job_list_section employee_list_section pb-2 pt-2 oddeven"
  *ngFor="let employee of pageOfItems | filter: {bussinessname: searchText, businesstrade: searchText };  let i=index">
  
  <div class="job_detils_date width7">
    <p class="job_address">S. No.</p>
      <h5 class="job_profile font_17">{{ i+1 }}</h5>
     
  </div>
  <div class="job_detils_date width22">
    <p class="job_address">Business Name</p>
      <h5 class="job_profile font_17">{{employee.bussinessname}}</h5>
     
  </div>
  <div class="job_detils_date width22">
      <p class="job_address">Business Trade or Occupation</p>
      <p  class="job_profile">{{employee.businesstrade }}</p>
       
  </div>
  <div class="job_detils_date width22">
    <p class="job_address">Period of Default</p>
    <p  class="job_profile">{{employee.periodofdefault }}</p>     
</div>
  

   <div class="job_detils_date  width25 justify-content-end d-flex aligncenter">       
        <a  class="btn btn-warning com_btn" (click)="sendrequest(employee.id,'sendrequestcharge')" >View Defaulter Details</a> 
       </div>                               
 
</div>
<div class="row">
  <div class="col-lg-4 text-left" *ngIf="record == 0">
      <div class="billed-to">No Record Found </div>
  </div> 
</div>


<div class="row">
  <div class="col-sm-6 text-right">
    &nbsp;
  </div>
  <div class="col-sm-6 text-right">
   
  </div> 
</div>


  </div>



  </div>
</div>


<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
  <div role="document" class="formgroup">
      <div class="modal-content">         
          <div class="modal-body">
              <div class="container text-center">
                <div class="info_mess">
                    <div class="para">
                            <h4 class="mb-2">Sorry, No credits available</h4>
                            <p class="mb-0 font20">There are no credits available to perform this action. Please add more credits.</p>

                        </div>                          
                    </div>
              </div>
          </div>
          <div class="modal-footer text-center">
            <input type="button" class="btn btn-primary com_btn" name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
            <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
              <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
          </div>
      </div>
  </div>
</jw-modal>



<jw-modal id="sendrequestcharge" role="dialog" class="modal sendrequestcharge">

  <div role="document" class="formgroup">
      <div class="modal-content">
          <div class="modal-body p-4">
              <div class="container text-center">
                  <div class="info_mess">
                      <div class="para">
                          <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
                          <p class="mb-0 font20">Please click on Confirm button to continue the request.</p>
                      </div>                          
                  </div>
                  
              </div>
          </div>
          <div class="modal-footer text-center">
              <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Confirm"  (click)="sendrequestcharge(employeeid,'sendrequestcharge')"  />
              <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('sendrequestcharge');"/>
          </div>
      </div>
  </div>
</jw-modal>



<jw-modal id="max-defaulter-bussiness" role="dialog" class="modal sendrequestcharge">
  <div role="document" class="formgroup">
      <div class="modal-content">         
          <div class="modal-body">
              <div class="container">
                
                <div class="job_list_section employee_list_section" *ngIf="companytype">
                  <div class="job_detils_date width33">
                    <p class="job_address"> Bussiness Name</p>
                    <p class="job_profile text-primary">{{companytype.bussinessname}}</p>
                </div>                 
                  <div class="job_detils_date width33">
                      <p class="job_address"> Business Trade </p>
                      <p class="job_profile text-primary">{{companytype.businesstrade }}</p>
                  </div>
                 
                  <div class="job_detils_date width33">
                    <p class="job_address">Period of Default</p>
                    <p class="job_profile"> {{companytype.periodofdefault}}</p>
                </div>
                <div class="job_detils_date width33 mt-3">
                  <p class="job_address">Total AmountTax</p>
                  <p class="badge badge-danger">{{companytype.totalamounttax}} </p>
                </div>

                <div class="job_detils_date width33 mt-3">
                    <p class="job_address">Total Amount Penalties</p>
                    <p class="badge badge-danger">{{companytype.totalamountpenalties}} </p>
                </div>
                 
                <div class="job_detils_date width33 mt-3">
                  <p class="job_address">Other information</p>
                  <p class="job_profile">{{companytype.otherinformation}} </p>
              </div>

              <div class="job_detils_date width100 mt-3">
                <p class="job_address">Business Address</p>
                <p class="job_profile"> {{companytype.bussinessaddress}}</p>
            </div>

              </div>





              </div>
          </div>
          <div class="modal-footer text-center">
            <!-- <input type="button" class="btn btn-primary com_btn" name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  /> -->
            <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('max-defaulter-bussiness');"/>
              <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
          </div>
      </div>
  </div>
</jw-modal>