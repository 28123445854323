"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../_services/modal.service");
var aml_model_1 = require("../../../models/aml/aml.model");
var forms_1 = require("@angular/forms");
var aml_service_1 = require("../../../services/aml.service");
var router_1 = require("@angular/router");
var country_service_1 = require("../../../services/country.service");
var checksystem_service_1 = require("../../../services/checksystem.service");
var startWith_1 = require("rxjs/operators/startWith");
var map_1 = require("rxjs/operators/map");
var State = /** @class */ (function () {
    function State(countryid, countryname, createat, isid, iso, isothree, ispassport, isrighttoworkcountry, isvisa, sstatus, updateat) {
        this.countryid = countryid;
        this.countryname = countryname;
        this.createat = createat;
        this.isid = isid;
        this.iso = iso;
        this.isothree = isothree;
        this.ispassport = ispassport;
        this.isrighttoworkcountry = isrighttoworkcountry;
        this.isvisa = isvisa;
        this.sstatus = sstatus;
        this.updateat = updateat;
    }
    return State;
}());
exports.State = State;
var SearchbussinessamlComponent = /** @class */ (function () {
    function SearchbussinessamlComponent(modalService, formBuilder, amldetails, router, country, check, activatedRoute) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.amldetails = amldetails;
        this.router = router;
        this.country = country;
        this.check = check;
        this.activatedRoute = activatedRoute;
        this.searchrecord = false;
        this.countrySelectsString = '';
        this.businessDetail = new aml_model_1.AmlBussiness();
        this.defaultVal = '';
        this.checkedAll = false;
        this.issoe = true;
        this.issan = true;
        this.showdataset = true;
        this.countryerror = false;
        this.selectpeplist = [
            {
                "id": "PEP",
                "name": "PEP",
                "heading": "Politically exposed person",
                "description": "Any individual that is considered a Politically Exposed Person in their own capacity or by association."
            },
            {
                "id": "SAN",
                "name": "Sanctions",
                "heading": "Sanctions",
                "description": "Any individual or entity that is or has formerly been subject to sanctions at international, regional, or national levels."
            },
            {
                "id": "RRE",
                "name": "Reputational Risk Exposure",
                "heading": "Reputational Risk Exposure",
                "description": "Any individual or entity that has been reported in official or media sources to be involved in a crime that falls under one or more of the following categories – terrorism, financial crime and fraud, modern slavery, organised crime, bribery and corruption, and cybercrime."
            },
            {
                "id": "INS",
                "name": "Insolvency (UK & Ireland)",
                "heading": "Insolvency",
                "description": "Any person that has been declared as bankrupt or insolvent."
            },
            {
                "id": "POI",
                "name": "Profile of Interest",
                "heading": "Profile of Interest",
                "description": "Natural or legal persons that were profiled based on Complygate Risk Intelligence’s data model and can be linked to outdated red flags, such as expired PEP status, reputational risk, or regulatory measures no longer in force."
            },
            {
                "id": "REL",
                "name": "Regulatory Enforcement List",
                "heading": "Regulatory Enforcement List",
                "description": "Any individual or entity that has been fined or in some other way subject to action by any financial regulatory body or law enforcement authority. "
            },
            {
                "id": "SOE",
                "name": "State-Owned Enterprise",
                "heading": "State-Owned Enterprise",
                "description": "Profiles of companies in which the state exercises control or ownership."
            },
            {
                "id": "CUK",
                "name": "Company - UK",
                "heading": "State-Owned Enterprise",
                "description": "Profiles of companies in which the state exercises control or ownership."
            },
            {
                "id": "CG",
                "name": "Company - Global",
                "heading": "State-Owned Enterprise",
                "description": "Profiles of companies in which the state exercises control or ownership."
            }
        ];
        this.countryuserSelects = [];
        this.userSelects = [];
        this.suggestions = [{ "id": "PEP-FORMER", "name": "Former" }, { "id": "PEP-CURRENT", "name": "Current" }, { "id": "PEP-LINKED", "name": "Linked" }];
        this.peplist = [
            {
                "id": "PEP",
                "name": "PEP",
                "heading": "Politically exposed person",
                "description": "Any individual that is considered a Politically Exposed Person in their own capacity or by association."
            },
            {
                "id": "SAN",
                "name": "Sanctions",
                "heading": "Sanctions",
                "description": "Any individual or entity that is or has formerly been subject to sanctions at international, regional, or national levels."
            },
            {
                "id": "RRE",
                "name": "Reputational Risk Exposure",
                "heading": "Reputational Risk Exposure",
                "description": "Any individual or entity that has been reported in official or media sources to be involved in a crime that falls under one or more of the following categories – terrorism, financial crime and fraud, modern slavery, organised crime, bribery and corruption, and cybercrime."
            },
            {
                "id": "INS",
                "name": "Insolvency (UK & Ireland)",
                "heading": "Insolvency",
                "description": "Any person that has been declared as bankrupt or insolvent."
            },
            {
                "id": "POI",
                "name": "Profile of Interest",
                "heading": "Profile of Interest",
                "description": "Natural or legal persons that were profiled based on Complygate Risk Intelligence’s data model and can be linked to outdated red flags, such as expired PEP status, reputational risk, or regulatory measures no longer in force."
            },
            {
                "id": "REL",
                "name": "Regulatory Enforcement List",
                "heading": "Regulatory Enforcement List",
                "description": "Any individual or entity that has been fined or in some other way subject to action by any financial regulatory body or law enforcement authority. "
            },
            {
                "id": "SOE",
                "name": "State-Owned Enterprise",
                "heading": "State-Owned Enterprise",
                "description": "Profiles of companies in which the state exercises control or ownership."
            },
            {
                "id": "CUK",
                "name": "Company - UK",
                "heading": "State-Owned Enterprise",
                "description": "Profiles of companies in which the state exercises control or ownership."
            },
            {
                "id": "CG",
                "name": "Company - Global",
                "heading": "State-Owned Enterprise",
                "description": "Profiles of companies in which the state exercises control or ownership."
            }
        ];
        this.show = false;
        this.countryshow = false;
    }
    SearchbussinessamlComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.downloadtext = 'No Record Found <i class="fa fa-plus ml-2"></i>';
        this.type = this.activatedRoute.snapshot.paramMap.get("type");
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        // this.permission = permission[0];
        this.issoe = true;
        this.Threshold = 80;
        this.issan = true;
        this.countryerror = false;
        if (permission[0]['m96'] < 3) {
            this.router.navigate(['/permission-denied']);
        }
        if (this.type == 'aml') {
            this.historytext = 'View AML, PEPs & Sanction';
            this.atype = 1;
            if (permission[0]['m96'] < 3) {
                this.router.navigate(['/permission-denied']);
            }
        }
        else if (this.type == 'global') {
            this.historytext = 'View Global Due Diligence';
            this.atype = 4;
            if (permission[0]['m189'] < 3) {
                this.router.navigate(['/permission-denied']);
            }
        }
        this.error = false;
        this.buttoninsert = "Search";
        this.result = false;
        this.disableBtn = true;
        this.errormsg = '';
        this.defaultVal = "";
        this.bussinessformGroup = this.formBuilder.group({
            BusinessName: ['', forms_1.Validators.required],
            // Country: ['', Validators.required],
            stateCtrl: [''],
            soeSanctions: [],
            sanctionstatus: [''],
            Threshold: [''],
        });
        this.businessDetail = new aml_model_1.AmlBussiness();
        this.stateCtrl = new forms_1.FormControl();
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
            console.log('Coountry List', _this.countrylist);
            _this.states = country;
            _this.filteredStates = _this.stateCtrl.valueChanges
                .pipe(startWith_1.startWith(''), map_1.map(function (state) { return state ? _this.filterStates(state) : _this.states.slice(); }));
        });
        this.logid = this.activatedRoute.snapshot.paramMap.get("id");
        this.bindandselectlist();
        if (this.logid) {
            var val = { id: this.logid, atype: this.atype };
            this.amldetails.getCompanylogrecord(val).subscribe(function (searchRes) {
                var listmaster = JSON.parse(searchRes.searchresult);
                console.log('list Search', listmaster);
                if (searchRes.iscomplete === 0) {
                    if (listmaster.results.matchCount > 0) {
                        _this.result = true;
                        _this.searchresult = listmaster.results;
                    }
                    else {
                        localStorage.setItem('personalid', searchRes.id);
                        _this.router.navigate(['/search-business-aml-details', searchRes.id]);
                    }
                }
                else {
                    localStorage.setItem('personalid', searchRes.id);
                    _this.router.navigate(['/search-business-aml-details', searchRes.id]);
                }
            });
        }
        else {
            // this.amldetails.getCountries().subscribe((countrydata: any) => { 
            //   this.countrylist = countrydata;
            //   this.businessDetail.Country ="";
            // });
            this.lesssore = false;
            if (this.type == 'global') {
                var ts = { 'serviceid': 23 };
            }
            else {
                var ts = { 'serviceid': 8 };
            }
            // var ts  = { 'serviceid': 8};    
            this.check.getbillinginformation(ts).subscribe(function (remaindata) {
                // console.log(remaindata)
                if (remaindata.remiancheck <= 0) {
                    _this.lesssore = true;
                }
            });
        }
    };
    Object.defineProperty(SearchbussinessamlComponent.prototype, "f", {
        get: function () { return this.bussinessformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    SearchbussinessamlComponent.prototype.filterStates = function (name) {
        return this.states.filter(function (state) {
            return state.countryname.toLowerCase().indexOf(name.toLowerCase()) === 0;
        });
    };
    SearchbussinessamlComponent.prototype.clearpersonal = function () {
        this.submitted = false;
        // this.bussinessformGroup.reset();
        this.businessDetail = new aml_model_1.AmlBussiness();
        this.businessDetail.BusinessName = "";
        this.businessDetail.Country = "";
        this.businessDetail.Threshold = "";
        this.businessDetail.Postcode = "";
        this.businessDetail.Address = "";
        this.businessDetail.County = "";
        this.businessDetail.City = "";
        this.businessDetail.PEP = false;
        this.businessDetail.CurrentSanctions = false;
        this.businessDetail.DisqualifiedDirector = false;
        this.businessDetail.FinancialRegulator = false;
        this.businessDetail.Insolvency = false;
        this.businessDetail.LawEnforcement = false;
        this.businessDetail.AdverseMedia = false;
        this.businessDetail.PreviousSanctions = false;
        this.checkedAll = false;
    };
    SearchbussinessamlComponent.prototype.amllogredirect = function () {
        if (this.type == 'aml')
            this.router.navigate(['/check-aml-log-list', '1']);
        else if (this.type == 'global')
            this.router.navigate(['/check-aml-log-list', '4']);
    };
    SearchbussinessamlComponent.prototype.selectall = function (val) {
        if (val == true) {
            this.checkedAll = true;
            this.businessDetail.PEP = true;
            this.businessDetail.CurrentSanctions = true;
            this.businessDetail.DisqualifiedDirector = true;
            this.businessDetail.FinancialRegulator = true;
            this.businessDetail.Insolvency = true;
            this.businessDetail.LawEnforcement = true;
            this.businessDetail.AdverseMedia = true;
            this.businessDetail.PreviousSanctions = true;
        }
    };
    SearchbussinessamlComponent.prototype.getPosts = function (val) {
        var _this = this;
        var fakeobj = this.countrylist.filter(function (s) { return s.iso == val; });
        this.countryuserSelects.find(function (item) { return item.iso === val; }) ?
            this.countryuserSelects = this.countryuserSelects.filter(function (item) { return item.iso !== val; }) :
            this.countryuserSelects.push(fakeobj[0]);
        // this.countryuserSelects.push(s);
        if (this.countryuserSelects.length > 0) {
            this.countryerror = false;
        }
        this.countryshow = false;
        // this.stateCtrl = new FormControl('');
        this.stateCtrl = new forms_1.FormControl();
        this.filteredStates = this.stateCtrl.valueChanges
            .pipe(startWith_1.startWith(''), map_1.map(function (state) { return state ? _this.filterStates(state) : _this.states.slice(); }));
    };
    SearchbussinessamlComponent.prototype.selectallfalse = function (val) {
        if (val == false) {
            this.checkedAll = false;
        }
    };
    SearchbussinessamlComponent.prototype.onSubmit = function (val, type) {
        var _this = this;
        this.error = false;
        this.submitted = true;
        if (this.bussinessformGroup.invalid) {
            return;
        }
        var countrylist = [];
        this.countryuserSelects.forEach(function (element) {
            countrylist.push(element.iso);
        });
        if (countrylist.length == 0) {
            this.countryerror = true;
            return;
        }
        var pep = [];
        if (this.selectpeplist.length == 0) {
            this.error = true;
            return;
        }
        else {
            this.selectpeplist.forEach(function (el) {
                pep.push(el.id);
            });
        }
        if (!this.Threshold) {
            val.threshold = 50;
        }
        else {
            val.threshold = this.Threshold;
        }
        var dd = {
            name: val.BusinessName,
            countries: countrylist,
            threshold: val.threshold,
            gender: val.gender,
            datasets: pep,
            atype: this.atype
        };
        // if(val.PEP ==false && val.PreviousSanctions ==false && val.CurrentSanctions ==false && val.LawEnforcement ==false && val.FinancialRegulator ==false && val.Insolvency ==false && val.DisqualifiedDirector ==false &&  val.AdverseMedia ==false)
        // {
        //   this.error =true;
        //   return;
        // }
        if (type == 'first') {
            this.disableBtn = false;
            if (this.type == 'global') {
                var ts = { 'serviceid': 23 };
            }
            else if (this.type == 'adverse') {
                var ts = { 'serviceid': 22 };
            }
            else {
                var ts = { 'serviceid': 8 };
            }
            // var ts  = { 'serviceid': 8};   
            this.check.getbillinginformation(ts).subscribe(function (remaindata) {
                // console.log(remaindata)
                if (remaindata.remiancheck <= 0) {
                    _this.lesssore = true;
                    _this.modalService.open('upgrade-plan');
                }
                else {
                    _this.disableBtn = true;
                    _this.lesssore = false;
                    _this.modalService.open('pdf-payment-confirmation');
                }
            });
        }
        else if (type == 'payment') {
            // console.log(val);
            // var db= {};
            // db ={name: val.name, countries: val.countries};
            // if(val.houseNo != '')
            // {
            //   db['houseNo'] = val.houseNo;
            // }
            // if(val.postCode != '')
            // {
            //   db['postCode'] = val.postCode;
            // }
            // if(val.street != '')
            // {
            //   db['street'] = val.street;
            // }
            // if(val.city !=''){
            //   db['city'] = val.city;
            // }
            // console.log(db);
            // this.amldetails.getListofCompanyPreDefinedSearches().subscribe((searchRes: any) => {
            //   console.log(searchRes)
            // });
            this.disableBtn = false;
            this.searchrecord = true;
            this.result = true;
            setTimeout(function () {
                _this.result = false;
                // this.searchrecord =false;
                _this.amldetails.getCompanyNewSearch(dd).subscribe(function (searchRes) {
                    _this.errormsg = '';
                    if (searchRes.error == '') {
                        _this.searchrecord = false;
                        if (searchRes.result.matchCount > 0)
                            _this.result = true;
                        else
                            _this.router.navigate(['/search-business-aml-details', searchRes.id]);
                        _this.disableBtn = true;
                        _this.searchresult = searchRes.result;
                        _this.logid = searchRes.id;
                    }
                    else if (searchRes.error == 'amlfail') {
                        _this.errormsg = 'We have some issue in AML & PEPs check. Please contact Administrator';
                    }
                    else if (searchRes.error == 'paymentfail') {
                        _this.errormsg = 'Your payment fail. Please contact Administrator';
                    }
                    //   this.errormsg ='';
                    //   this.logid =searchRes.id;
                    //   if(searchRes.error == '')
                    //   {
                    //     if(searchRes.result.matchCount >0)
                    //     {
                    //       this.result =true;
                    //     }        
                    //   this.disableBtn =true;
                    // // localStorage.setItem('personalid', searchRes.id);    
                    // // this.router.navigate(['/buscreditsearchresult', searchRes.id]); 
                    //   console.log(searchRes.result);
                    //   this.searchresult = searchRes.result; 
                    // }
                    // else if(searchRes.error == 'amlfail')
                    //   {
                    //       this.errormsg ='We have some issue in AML & PEPs check. Please contact Administrator';
                    //   }else  if(searchRes.error == 'paymentfail')
                    //   {
                    //     this.errormsg ='Your payment fail. Please contact Administrator';
                    //   }
                });
            }, 9000);
        }
    };
    SearchbussinessamlComponent.prototype.onSubmitsearch = function (val, type) {
        var _this = this;
        this.error = false;
        this.submitted = true;
        // if (this.bussinessformGroup.invalid) {
        //   return;
        // }
        var countrylist = [];
        this.countryuserSelects.forEach(function (element) {
            countrylist.push(element.iso);
        });
        // if(val.PEP ==false && val.PreviousSanctions ==false && val.CurrentSanctions ==false && val.LawEnforcement ==false && val.FinancialRegulator ==false && val.Insolvency ==false && val.DisqualifiedDirector ==false &&  val.AdverseMedia ==false)
        // {
        //   this.error =true;
        //   return;
        // }
        if (type == 'first' && this.logid == 0) {
            this.disableBtn = false;
            var ts = { 'serviceid': 8 };
            this.check.getbillinginformation(ts).subscribe(function (remaindata) {
                // console.log(remaindata)
                if (remaindata.remiancheck <= 0) {
                    _this.lesssore = true;
                    _this.modalService.open('upgrade-plan');
                }
                else {
                    _this.disableBtn = true;
                    _this.lesssore = false;
                    _this.resourceId = val;
                    _this.modalService.open('pdf-payment-confirmation');
                }
            });
        }
        else if (type == 'payment' || this.logid != 0) {
            val.id = this.logid;
            this.router.navigate(['/search-business-aml-details', this.logid, val.resourceId]);
            // console.log(val);
            // var db= {};
            // db ={name: val.name, countries: val.countries};
            // if(val.houseNo != '')
            // {
            //   db['houseNo'] = val.houseNo;
            // }
            // if(val.postCode != '')
            // {
            //   db['postCode'] = val.postCode;
            // }
            // if(val.street != '')
            // {
            //   db['street'] = val.street;
            // }
            // if(val.city !=''){
            //   db['city'] = val.city;
            // }
            // console.log(db);
            // this.amldetails.getListofCompanyPreDefinedSearches().subscribe((searchRes: any) => {
            //   console.log(searchRes)
            // });
            /* Lock is iscomplete  */
            // this.disableBtn =false;
            //   this.amldetails.getCompanyNewSearchDetails(val).subscribe((searchRes: any) => { 
            //     console.log(searchRes);
            //     this.errormsg ='';
            //     if(searchRes.error == ''){
            //       localStorage.setItem('personalid', searchRes.id);    
            //      this.router.navigate(['/search-business-aml-details', searchRes.id]);    
            //       // this.searchresult = searchRes.result;
            //     }else if(searchRes.error == 'amlfail')
            //     {
            //         this.errormsg ='We have some issue in AML & PEPs check. Please contact Administrator';
            //     }else  if(searchRes.error == 'paymentfail')
            //     {
            //       this.errormsg ='Your payment fail. Please contact Administrator';
            //     }
            //   });
            /* End Locked complate */
        }
    };
    SearchbussinessamlComponent.prototype.checkpep = function (val) {
        return this.selectpeplist.findIndex(function (item) { return item.id === val.id; }) > -1;
    };
    SearchbussinessamlComponent.prototype.countryselectallfalse = function (chk, val) {
        if (chk == true) {
            this.selectpeplist.push(val);
        }
        else {
            this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== val.id; });
        }
        if (this.selectpeplist.findIndex(function (item) { return item.id === 'SOE'; }) > -1)
            this.issoe = true;
        else
            this.issoe = false;
        if (this.selectpeplist.findIndex(function (item) { return item.id === 'SAN'; }) > -1)
            this.issan = true;
        else
            this.issan = false;
    };
    SearchbussinessamlComponent.prototype.dataset = function (values) {
        var _this = this;
        this.datasetlist = "";
        values.forEach(function (element_use) {
            _this.peplist.forEach(function (element) {
                if (element_use == element.id) {
                    _this.datasetlist += element.name + ',';
                }
            });
        });
        this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1);
    };
    SearchbussinessamlComponent.prototype.countrysuggest = function () {
        this.show = false;
        if (this.countryshow) {
            this.countryshow = false;
        }
        else {
            this.countryshow = true;
        }
    };
    SearchbussinessamlComponent.prototype.iscountrySelected = function (s) {
        return this.countryuserSelects.findIndex(function (item) { return item.iso === s.iso; }) > -1 ? true : false;
    };
    SearchbussinessamlComponent.prototype.selectcountrySuggestion = function (s) {
        this.countryuserSelects.find(function (item) { return item.iso === s.iso; }) ?
            this.countryuserSelects = this.countryuserSelects.filter(function (item) { return item.iso !== s.iso; }) :
            this.countryuserSelects.push(s);
        this.countryshow = false;
    };
    SearchbussinessamlComponent.prototype.deletecountrySelects = function (s) {
        this.countryuserSelects = this.countryuserSelects.filter(function (item) { return item.iso !== s.iso; });
        this.countryshow = false;
    };
    SearchbussinessamlComponent.prototype.countryassignToNgModel = function () {
        var _this = this;
        this.countrySelectsString = '';
        this.countryuserSelects.map(function (item) { return _this.countrySelectsString += item.countryname + ' '; });
    };
    SearchbussinessamlComponent.prototype.openModal = function (id, data) {
        this.bussinessdetails = data;
        // console.log(data);
        this.buttoninsert = "Update";
        this.modalService.open(id);
    };
    SearchbussinessamlComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    /**************Start Data Set Design Setup****************/
    SearchbussinessamlComponent.prototype.choosedatabase = function () {
        this.showdataset = true;
    };
    SearchbussinessamlComponent.prototype.clearalldataset = function () {
        this.selectpeplist = [];
        this.issoe = false;
        this.issan = false;
    };
    SearchbussinessamlComponent.prototype.selectalldataset = function () {
        this.selectpeplist = [
            {
                "id": "PEP",
                "name": "PEP",
                "heading": "Politically exposed person",
                "description": "Any individual that is considered a Politically Exposed Person in their own capacity or by association."
            },
            {
                "id": "SAN",
                "name": "Sanctions",
                "heading": "Sanctions",
                "description": "Any individual or entity that is or has formerly been subject to sanctions at international, regional, or national levels."
            },
            {
                "id": "RRE",
                "name": "Reputational Risk Exposure",
                "heading": "Reputational Risk Exposure",
                "description": "Any individual or entity that has been reported in official or media sources to be involved in a crime that falls under one or more of the following categories – terrorism, financial crime and fraud, modern slavery, organised crime, bribery and corruption, and cybercrime."
            },
            {
                "id": "INS",
                "name": "Insolvency (UK & Ireland)",
                "heading": "Insolvency",
                "description": "Any person that has been declared as bankrupt or insolvent."
            },
            {
                "id": "POI",
                "name": "Profile of Interest",
                "heading": "Profile of Interest",
                "description": "Natural or legal persons that were profiled based on Complygate Risk Intelligence’s data model and can be linked to outdated red flags, such as expired PEP status, reputational risk, or regulatory measures no longer in force."
            },
            {
                "id": "DD",
                "name": "Disqualified Director",
                "heading": "Disqualified Director",
                "description": "Any individual that has been disqualified as acting as a director of a company."
            },
            {
                "id": "SOE",
                "name": "State-Owned Enterprise",
                "heading": "State-Owned Enterprise",
                "description": "Profiles of companies in which the state exercises control or ownership."
            },
            {
                "id": "OCP",
                "name": "Company House",
                "heading": "State-Owned Enterprise",
                "description": "Profiles of companies in which the state exercises control or ownership."
            }
        ];
        this.issoe = true;
        this.issan = true;
    };
    SearchbussinessamlComponent.prototype.closedataset = function () {
        this.showdataset = false;
    };
    /**************End Data Set Design Setup****************/
    SearchbussinessamlComponent.prototype.bindallpep = function (val) {
        if (val) {
            return this.suggestions.filter(function (d) { return val.includes(d.id); });
            ;
        }
        else
            return [];
        // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
    };
    SearchbussinessamlComponent.prototype.bindallcountry = function (val) {
        if (val) {
            return this.countrylist.filter(function (d) { return val.includes(d.iso); });
            ;
        }
        else
            return [];
        // return  this.bussinessdetails.evidences.filter(d => val.includes(d.evidenceId));
    };
    SearchbussinessamlComponent.prototype.norecordfound = function () {
        var _this = this;
        this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        this.amldetails.generatenorecordv3Pdf({ id: this.logid, atype: 1 }).subscribe(function (pdfRes) {
            console.log(pdfRes.response_data.Location);
            _this.url = pdfRes.response_data.Location;
            _this.downloadtext = 'No Record Found <i class="fa fa-plus ml-2"></i>';
        });
    };
    SearchbussinessamlComponent.prototype.bindandselectlist = function () {
        if (this.type == 'aml') {
            this.peplist = this.peplist.filter(function (item) { return item.id !== 'CUK'; });
            this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== 'CUK'; });
            this.peplist = this.peplist.filter(function (item) { return item.id !== 'CG'; });
            this.selectpeplist = this.selectpeplist.filter(function (item) { return item.id !== 'CG'; });
        }
    };
    return SearchbussinessamlComponent;
}());
exports.SearchbussinessamlComponent = SearchbussinessamlComponent;
