<div class="checkshow_btn">
  <button type="button" class="btn com_btn btn-primary no-cursor" *ngIf="checkcount">
    Total check <span class="badge badge-light">{{ checkcount.totalcheck }}</span>
  </button>
  <button type="button" class="btn com_btn btn-danger no-cursor"  *ngIf="checkcount">
    Used check<span class="badge badge-light">{{ checkcount.usedcheck }}</span>
  </button>
  <button type="button" class="btn com_btn btn-dark no-cursor"  *ngIf="checkcount">
    Remaining check <span class="badge badge-light">{{ checkcount.remiancheck }}</span>
  </button>
</div>
