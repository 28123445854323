<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
      <div class="tabs" id="tabs">
        <a [routerLink]="['/report-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true"
            class="fa fa-angle-left"></i> Back to Report Dashboard</a>
        <div class="clearfix"></div>
        <div class="tabs__content">
          <div class="tabs__content__item active-tab">
            <div class="content">
              <div class="row  mg-t-10">
                <div class="col-lg-6">
                  <h5 class="hedingcolor">Expense List</h5>
                </div>
                <div class="col-lg-6 d-flex justify-content-end mb-3">
                  <!-- <a (click)="exportAsXLSX();" class="float-right faright"><i class="fa fa-file-excel-o"></i></a> -->
                  <!-- <button type="submit" (click)="exportAsXLSX();" class="btn btn-primary com_btn">
                    Download <i class="ml-2 fa fa-file-excel-o"></i>
                  </button> -->
                  <a (click)="showcondition('list');" class="toggle_button faright"><i class="fa fa-list"></i>
                    <span  class="tooltip_show">List View</span>
                  </a>
                  <a (click)="showcondition('graph');" class="toggle_button faright"><i class="fa fa-bar-chart"></i>
                    <span  class="tooltip_show">Graph View</span>
                    </a>
                </div>
              </div>
              <div [style.display]="listdisplay">
                <div class="row topheader">
                  <div class="col-lg-2">
                    <select name="hstatus" class="form-control input-lg"
                      (change)="changeEmployeestatus($event.target.value)">
                      <option value="">Select Status</option>
                      <option value="1">Pending</option>
                      <option value="2">Approved</option>
                      <option value="3">Rejected</option>
                    </select>
                  </div>
                  <div class="col-lg-3">
                    <select name="employee" #employid (change)="changeEmployee($event.target.value)"
                      class="form-control input-lg">
                      <option value="">Select Employee</option>
                      <option *ngFor="let emp of employeelist" value="{{emp.employeemasterid}}"
                        [selected]="emp.employeemasterid==empid">
                        {{ emp.fname }} {{ emp.mname }} {{ emp.lname }} ( {{ emp.employeeid }})</option>
                    </select>
                  </div>
                  <div class="col-lg-3">
                    <select name="exptype" #expid (change)="changeExpenseType($event.target.value)"
                      class="form-control input-lg">
                      <option value="">Select Expense Type</option>
                      <option *ngFor="let exptyp of expenselist" value="{{exptyp.id}}" [selected]="exptyp.id==exptypid">
                        {{ exptyp.typename }}</option>
                    </select>
                  </div>
                  <div class="col-lg-2">
                    <select name="empmonth" #empmonth (change)="changeEmployeeMonth($event.target.value)"
                      class="form-control input-lg">
                      <option value="" [selected]="employeemonth==''">Select Month</option> 
                      <option value="1" [selected]="employeemonth=='1'">January</option>
                      <option value="2" [selected]="employeemonth=='2'">February</option>
                      <option value="3" [selected]="employeemonth=='3'">March</option>
                      <option value="4" [selected]="employeemonth=='4'">April</option>
                      <option value="5" [selected]="employeemonth=='5'">May</option>
                      <option value="6" [selected]="employeemonth=='6'">June</option>
                      <option value="7" [selected]="employeemonth=='7'">July</option>
                      <option value="8" [selected]="employeemonth=='8'">August</option>
                      <option value="9" [selected]="employeemonth=='9'">September</option>
                      <option value="10" [selected]="employeemonth=='10'">October</option>
                      <option value="11" [selected]="employeemonth=='11'">November</option>
                      <option value="12" [selected]="employeemonth=='12'">December</option>
                    </select>
                  </div>
                  <div class="col-lg-2">
                    <select name="empyear" #empyear (change)="changeEmployeeYear($event.target.value)"
                      class="form-control input-lg" ngModel="{{employeeyear}}">
                      <option value="{{y}}" *ngFor="let y of yearList">{{ y }}</option>
                    </select>
                  </div>
                </div>
                <div class="job_list_section idproof_request_section visa_request_section"
                  *ngFor="let expense of data;  let i=index">
                  <div class="job_detils_date">
                    <div class="visa_user_pic">
                      <img *ngIf="expense.imagename" src="{{expense.imagename}}" class="img width100" alt="{{ expense.fname }} {{ expense.mname }} {{ expense.lname}}">
                      <img *ngIf="expense.gender == true && (expense.imagename == null || expense.imagename =='') " src="../../../../../assets/img/user_pic_leave.png" class="img width100" alt="{{ expense.fname }} {{ expense.mname }} {{ expense.lname}}">
                      <img *ngIf="expense.gender == false && (expense.imagename == null || expense.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ expense.fname }} {{ expense.mname }} {{ expense.lname}}">
                    </div>
                  </div>
                  <div class="job_detils_date width20">
                    <div class="visa_user_details">
                      <h4>{{expense.fname}} {{expense.mname}} {{expense.lname}}
                      </h4>
                      <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"></i>
                        {{ expense.emailid }}
                      </p>
                      <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i> {{ expense.phonenumber }}
                      </p>
                      <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i>NI Number
                        {{ expense.ninumber }} </p>
                      <span *ngIf="expense.nationality"
                        class="badge badge-success">{{ expense.nationality.countryname }} </span>
                    </div>
                  </div>
                  <div class="job_detils_date width15">
                    <p class="job_address">Total Amount</p>
                    <p class="job_profile">{{expense.totalexpense}} {{expense.currency}}</p>
                  </div>
                  <div class="job_detils_date width15">
                    <p class="job_address">Pending Amount</p>
                    <p class="job_profile">{{expense.pendingexpense}} {{expense.currency}}</p>
                  </div>
                  <div class="job_detils_date width7">
                    <p class="job_address">Approved Amount</p>
                    <p class="job_profile">{{expense.approvedexpense}} {{expense.currency}}</p>
                  </div>
                  <div class="job_detils_date width7">
                    <p class="job_address">Rejected Amount</p>
                    <p class="job_profile">{{expense.rejectexpense}} {{expense.currency}}</p>
                  </div>
                  <div class="job_detils_date width7">
                    <p class="job_address ">
                      <a [routerLink]="['/viewexpense/'+expense.employeemasterid]" target="_blank" [queryParams]="{ type: exptype, status: hstatus, year: employeeyear, month: employeemonth}" class="btn btn-info com_btn"> 
                        <i class="fa fa-eye"></i> View 
                      </a>
                    </p>
                  </div>
                </div>
                <div class="job_list_section visa_request_section" *ngIf="record == '0'">
                  <div class="job_detils_date">
                      <p class="job_profile">No Record Found</p>
                  </div>
                </div>        
                
                <div class="row">
                  <div class="col-lg-12 text-right">
                    <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)">
                    </jw-pagination>
                  </div>
                </div>
              </div>
              <div [style.display]="graphdisplay">
                <app-expchart></app-expchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<jw-modal id="note-leave-report" class="modal " role="dialog ">
  <div role="document" class="formgroup ">
    <div class="modal-content ">
      <div class="modal-header ">
        <h5 class="modal-title ">Notes</h5>
        <button type="button " class="close " data-dismiss="modal " name="close " aria-label="Close "
          (click)="closeModal( 'note-leave-report'); ">
          <span aria-hidden="true ">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="container ">
          <div class="job_profile">
            <div class="">
              <p><b>Note :</b> {{notedetails}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer ">
        <button type="button " class="btn btn-secondary com_btn " data-dismiss="modal "
          (click)="closeModal( 'note-leave-report');">Close</button>
      </div>
    </div>
  </div>
</jw-modal>