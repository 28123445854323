"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var mypassport_service_1 = require("../../../services/mypassport.service");
var modal_service_1 = require("../../../_services/modal.service");
var router_1 = require("@angular/router");
var country_service_1 = require("../../../services/country.service");
var forms_1 = require("@angular/forms");
var alert_service_1 = require("../../../services/alert.service");
var project_service_1 = require("../../../services/project.service");
var client_service_1 = require("../../../services/client.service");
var employee_service_1 = require("../../../services/employee.service");
var department_service_1 = require("../../../services/department.service");
var division_service_1 = require("../../../services/division.service");
var company_service_1 = require("../../../services/company.service");
var otherinformation_model_1 = require("../../../models/employment/otherinformation.model");
var wemployee_model_1 = require("../../../models/employment/wemployee.model");
var client_model_1 = require("../../../models/client/client.model");
var project_model_1 = require("../../../models/project/project.model");
var MycurrentemploymentComponent = /** @class */ (function () {
    function MycurrentemploymentComponent(formBuilder, data, country, modalService, activatedRoute, alerts, project, client, employeeService, departmentService, divisionService, companyService) {
        this.formBuilder = formBuilder;
        this.data = data;
        this.country = country;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.alerts = alerts;
        this.project = project;
        this.client = client;
        this.employeeService = employeeService;
        this.departmentService = departmentService;
        this.divisionService = divisionService;
        this.companyService = companyService;
        this.defaultVal = "";
        this.filterItems = [
            {
                name: 'All Passport Details',
                id: 0,
                selected: true
            },
            {
                name: 'Accepted',
                id: 2,
                selected: false
            },
            {
                name: 'Rejected',
                id: 3,
                selected: false
            },
            {
                name: 'Waiting for review',
                id: 1,
                selected: false
            }
        ];
    }
    MycurrentemploymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.empid = this.activatedRoute.snapshot.paramMap.get("id");
        this.otherinfoGroup = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            locationid: ['', forms_1.Validators.required],
            divisionid: ['', forms_1.Validators.required],
            departmentid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            projectid: [''],
            clientid: [''],
            reportto: ['', forms_1.Validators.required],
            isclientcontact: ['', forms_1.Validators.required]
        });
        this.onChanges();
        this.otherDetail = new otherinformation_model_1.Otherinfo();
        this.otherDetail.reportingperson = new wemployee_model_1.Wemployee();
        this.otherDetail.client = new client_model_1.Client();
        this.otherDetail.location = new otherinformation_model_1.Clocation();
        this.otherDetail.division = new otherinformation_model_1.Division();
        this.otherDetail.department = new otherinformation_model_1.Department();
        this.otherDetail.project = new project_model_1.Project();
        var id = localStorage.getItem('userToken');
        this.Userid = localStorage.getItem('userid');
        var data = { 'userid': this.Userid, 'employeeid': this.empid };
        this.employeeService.getOtherInfobyadmin(data).subscribe(function (data) {
            _this.otherDetail = data;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.employeeService.getTerminationInfobyadmin(data).subscribe(function (data) {
            // console.log(data);
            _this.terminationDetail = data;
        });
        this.employeeService.getlistofworkingemployee().subscribe(function (employeedata) {
            _this.wemployeelist = employeedata;
        });
        var sstatustype = { 'status': true, 'id': this.Userid };
        this.client.getclientlist(sstatustype).subscribe(function (clientdetails) {
            _this.clientlist = clientdetails;
        });
        this.departmentService.getdepartmentlistbyadmin(sstatustype).subscribe(function (data) {
            // console.log(data);
            _this.departmentlist = data;
        });
        this.companyService.getemployeecontracttypelist().subscribe(function (data) {
            _this.contracttypelist = data;
        });
        this.companyService.getcompanylocation().subscribe(function (data) {
            _this.companylocationlist = data;
        });
        this.divisionService.getdivisionlistbyadmin(sstatustype).subscribe(function (data) {
            _this.divisionlist = data;
        });
        this.errMessage = '';
    };
    MycurrentemploymentComponent.prototype.getProjectList = function (clientid) {
        var _this = this;
        this.project.getprojectlistbyclient({ "clientid": clientid, 'id': this.Userid, 'status': true }).subscribe(function (projectdetails) {
            _this.projectlist = projectdetails;
        });
    };
    MycurrentemploymentComponent.prototype.onChangeDate = function (event) {
    };
    MycurrentemploymentComponent.prototype.onChanges = function () {
        var _this = this;
        this.formattedMessage = '';
        this.otherinfoGroup.valueChanges.subscribe(function (val) {
            _this.formattedMessage = 'Hello, My name is and my email is .I would like to tell you that .';
        });
    };
    Object.defineProperty(MycurrentemploymentComponent.prototype, "f", {
        get: function () { return this.otherinfoGroup.controls; },
        enumerable: true,
        configurable: true
    });
    MycurrentemploymentComponent.prototype.onSubmit = function (formcurrentemp, id) {
        var _this = this;
        // var formcurrentemp = value3.value;
        this.submitted = true;
        if (this.otherinfoGroup.invalid) {
            return;
        }
        this.disableBtn = false;
        formcurrentemp.userid = this.Userid;
        this.employeeService.employeeotherinfoUpdatebyadmin(formcurrentemp).subscribe(function (data) {
            if (data.susses == true) {
                _this.messageSuccess = 'Other Information Updated Successfully.';
                _this.alerts.success(_this.messageSuccess, false);
                _this.modalService.close(id);
                var data1 = { 'userid': _this.Userid, 'employeeid': _this.empid };
                _this.employeeService.getOtherInfobyadmin(data1).subscribe(function (data) {
                    return _this.otherDetail = data;
                });
            }
            else {
                _this.errMessage = 'Error. Not authorised.';
            }
        });
    };
    MycurrentemploymentComponent.prototype.onDelete = function (empid) {
        var _this = this;
        if (confirm("Are you sure you want to delete?")) {
            var data1 = { 'id': empid };
            this.data.deletePassport(data1).subscribe(function (data) {
                var data1 = { 'userid': _this.Userid, 'employeeid': _this.empid };
                _this.employeeService.getOtherInfobyadmin(data1).subscribe(function (data) {
                    return _this.otherDetail = data;
                });
            });
        }
    };
    MycurrentemploymentComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.disableBtn = true;
        this.otherDetail = new otherinformation_model_1.Otherinfo();
        this.otherDetail.reportingperson = new wemployee_model_1.Wemployee();
        // this.otherDetail.employee = new Profile();
        this.otherDetail.client = new client_model_1.Client();
        this.otherDetail.location = new otherinformation_model_1.Clocation();
        this.otherDetail.division = new otherinformation_model_1.Division();
        this.otherDetail.department = new otherinformation_model_1.Department();
        this.otherDetail.project = new project_model_1.Project();
        this.buttoninsert = "Submit";
        this.otherDetail.employeeid = this.empid;
        this.otherDetail.id = "0";
        this.otherDetail.locationid = '';
        this.otherDetail.divisionid = '';
        this.otherDetail.departmentid = '';
        this.otherDetail.projectid = '';
        this.otherDetail.clientid = '';
        this.otherDetail.reportto = '';
        this.otherDetail.isclientcontact = '';
        this.modalService.open(id);
    };
    MycurrentemploymentComponent.prototype.openModal = function (id, data) {
        this.disableBtn = true;
        this.otherDetail = new otherinformation_model_1.Otherinfo();
        this.otherDetail.reportingperson = new wemployee_model_1.Wemployee();
        this.otherDetail.client = new client_model_1.Client();
        this.otherDetail.location = new otherinformation_model_1.Clocation();
        this.otherDetail.division = new otherinformation_model_1.Division();
        this.otherDetail.department = new otherinformation_model_1.Department();
        this.otherDetail.project = new project_model_1.Project();
        this.buttoninsert = "Update";
        this.errMessage = '';
        this.otherDetail = data;
        if (data.isclientcontact == true) {
            this.otherDetail.isclientcontact = "1";
        }
        else if (data.isclientcontact == false) {
            this.otherDetail.isclientcontact = "0";
        }
        // console.log(this.otherDetail);
        this.modalService.open(id);
    };
    MycurrentemploymentComponent.prototype.closeModal = function (id) {
        var _this = this;
        // this.otherDetail = new Otherinfo();
        // this.otherDetail.reportingperson = new Wemployee();
        // this.otherDetail.client = new Client();
        // this.otherDetail.location = new Clocation();
        // this.otherDetail.division = new Division();
        // this.otherDetail.department = new Department();
        // this.otherDetail.project = new Project();
        var data1 = { 'userid': this.Userid, 'employeeid': this.empid };
        this.employeeService.getOtherInfobyadmin(data1).subscribe(function (data) {
            _this.otherDetail = data;
        });
        this.modalService.close(id);
    };
    MycurrentemploymentComponent.prototype.modalOpen = function (data) {
        this.disableBtn = true;
        this.otherDetail = new otherinformation_model_1.Otherinfo();
        this.otherDetail.reportingperson = new wemployee_model_1.Wemployee();
        this.otherDetail.client = new client_model_1.Client();
        this.otherDetail.location = new otherinformation_model_1.Clocation();
        this.otherDetail.division = new otherinformation_model_1.Division();
        this.otherDetail.department = new otherinformation_model_1.Department();
        this.otherDetail.project = new project_model_1.Project();
        this.otherDetail = data;
    };
    return MycurrentemploymentComponent;
}());
exports.MycurrentemploymentComponent = MycurrentemploymentComponent;
