"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var PersonaltypeService = /** @class */ (function () {
    function PersonaltypeService(commonService) {
        this.commonService = commonService;
    }
    PersonaltypeService.prototype.getpersonaltypelistbyadmin = function () {
        return this.commonService.formpostOnlyid('/company/personaltypelistbyadmin');
    };
    PersonaltypeService.prototype.settingpersonaltypeUpdate = function (value) {
        return this.commonService.formpost('/company/personaltypeupdatebyadmin', value);
    };
    PersonaltypeService.prototype.personaltypeUpdateChangeStatus = function (value) {
        return this.commonService.formpost('/company/personaltypeupdatechangestatus', value);
    };
    PersonaltypeService.prototype.getactivepersonaltypelist = function () {
        return this.commonService.formpostOnlyid('/company/activepersonaltypelist');
    };
    PersonaltypeService.prototype.getdrivinglicenselist = function () {
        return this.commonService.formpostOnlyid('/company/drivinglicenselist');
    };
    PersonaltypeService.ngInjectableDef = i0.defineInjectable({ factory: function PersonaltypeService_Factory() { return new PersonaltypeService(i0.inject(i1.CommonService)); }, token: PersonaltypeService, providedIn: "root" });
    return PersonaltypeService;
}());
exports.PersonaltypeService = PersonaltypeService;
