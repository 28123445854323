import { Component, OnInit } from '@angular/core';
import { CandidateService } from '../../../services/candidate.service';
import { JobService } from '../../../services/job.service';
import { ModalService } from '../../../_services/modal.service';
import { UploadFileService } from './../../../services/upload-file.service';
import { AlertService } from '../../../services/alert.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-candidatedocument',
  templateUrl: './candidatedocument.component.html',
  styleUrls: ['./candidatedocument.component.css']
})
export class CandidatedocumentComponent implements OnInit {
  candidatelist: any;
  candidatedocumentlist: any;
  joblist: any;
  Userid: any;
  jobid: any;
  candidateid: any;
  selectedFiles: FileList;
  iframecandidatesrc: any;
  imgcandidatesrc: any;
  docperm: any;
  permission: any;

  constructor( 
    private sanitizer: DomSanitizer, 
    private job: JobService,
    private router: Router,
    private candidateService: CandidateService,
    private uploadService: UploadFileService, 
    private modalService: ModalService,
    private alerts: AlertService
    ) { }

  ngOnInit() 
  {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.docperm = this.permission.m8;

    this.candidateService.getcandidatebycompanyList().subscribe((employeedata: any) => {
      this.candidatelist = employeedata;
    }); 

    this.job.getjobList().subscribe((data: any) => {
      this.joblist = data;
    });
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  onChange(newValue) {    
    this.jobid = newValue;
    this.Userid = localStorage.getItem('userid');
    this.candidateService.getcandidateList({"id": this.Userid, "jobid": this.jobid}).subscribe((employeedata: any) => {
      this.candidatelist = employeedata;
    }); 
  } 

  bindallcandidatedocument(id: string, candidateformid: any) {  
    this.candidateid = candidateformid;

    // console.log(candidateformid);
    this.Userid = localStorage.getItem('userid');
    this.candidateService.getdocumentlist({"id": this.Userid, "candidateid": candidateformid}).subscribe((candidatedocument: any) => {
      this.candidatedocumentlist = candidatedocument;      
    }); 
    this.modalService.open(id);
  }

  deleteFile(id: string)
  {
    var deleteData = {};
    
    deleteData = { 'id': id, 'candidateid': this.candidateid };
    
    if (confirm("Are you sure you want to delete?")) {
      this.candidateService.deletecandidatedocumentFile(deleteData).subscribe((data: any) => {
        this.candidateService.getdocumentlist({"id": this.Userid, "candidateid": this.candidateid}).subscribe((candidatedocument: any) => {
          this.candidatedocumentlist = candidatedocument;      
        }); 
      });
    }
  }

  selectFile(event) {
    var files = event.target.files;
    var selectedFiles = files[0];
    this.selectedFiles = selectedFiles;

    const file = this.selectedFiles;
    var imagepath = this.uploadService.uploadfile(file);
    var documentname = selectedFiles.name.substring(0,5);
    var forupdate = { 'documentname': documentname, 'id': "0", 'documentimageurl': imagepath, 'candidateid': this.candidateid, 'userid': this.Userid };
    this.candidateService.candidatedocumentupdatebyadmin(forupdate).subscribe((data: any) => 
    {
      this.candidateService.getdocumentlist({"id": this.Userid, "candidateid": this.candidateid}).subscribe((candidatedocument: any) => {
        this.candidatedocumentlist = candidatedocument;      
      }); 
      // setTimeout(() => this.overviewData.imagename = imagepath, 2000);
    });
    this.alerts.success('Candidate document uploaded successfully.',true); 
  }

  openModalcandidate5(id: string, data: any) {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgcandidatesrc = true;
      this.iframecandidatesrc = data;
    }
    else {
      this.imgcandidatesrc = false;
      this.iframecandidatesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  closeModal(id: string){
    this.modalService.close(id);
  }

}
