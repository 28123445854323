"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var country_service_1 = require("../../../services/country.service");
var myvisa_service_1 = require("../../../services/myvisa.service");
var modal_service_1 = require("../../../_services/modal.service");
var forms_1 = require("@angular/forms");
var visa_model_1 = require("../../../models/visa/visa.model");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var platform_browser_1 = require("@angular/platform-browser");
// import { Router } from '@angular/router';
var router_1 = require("@angular/router");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var alert_service_1 = require("../../../services/alert.service");
var VisalistComponent = /** @class */ (function () {
    function VisalistComponent(sanitizer, formBuilder, uploadService, data, country, modalService, router, activatedRoute, alerts) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.data = data;
        this.country = country;
        this.modalService = modalService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.alerts = alerts;
        this.defaultVal = "";
        this.filterItems = [
            {
                name: 'All',
                id: 0,
                selected: true
            },
            {
                name: 'Accepted',
                id: 2,
                selected: false
            },
            {
                name: 'Rejected',
                id: 3,
                selected: false
            },
            {
                name: 'Pending Approval',
                id: 1,
                selected: false
            }
        ];
    }
    VisalistComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.empid = this.activatedRoute.snapshot.paramMap.get("id");
        this.visapopForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            visanumber: ['', forms_1.Validators.required],
            visatype: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            visaexpiry: ['', forms_1.Validators.required],
            visastart: ['', forms_1.Validators.required],
            iscurrent: ['']
        });
        this.filerequirederror = false;
        this.filename = 'Choose file';
        this.filebackname = 'Choose file';
        this.visaDetail = new visa_model_1.Visa();
        var id = localStorage.getItem('userToken');
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.empid, 'cstatus': [1, 2, 3] };
        this.data.getvisaAllListbyadmin(data).subscribe(function (data) {
            _this.visalistbyadmin = data;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.listcheck = this.filterItems;
    };
    VisalistComponent.prototype.onDelete = function (id) {
        var _this = this;
        var data1 = { 'id': id };
        if (confirm("Are you sure you want to delete?")) {
            this.data.deletevisa(data1).subscribe(function (data) {
                var sstatus;
                for (var _i = 0, _a = _this.filterItems; _i < _a.length; _i++) {
                    var c = _a[_i];
                    if (c.selected == true) {
                        if (c.id == 0) {
                            sstatus = [1, 2, 3];
                        }
                        else {
                            sstatus = [c.id];
                        }
                    }
                }
                var data2 = { 'id': _this.empid, 'cstatus': sstatus };
                _this.data.getvisaAllListbyadmin(data2).subscribe(function (data) {
                    _this.visalistbyadmin = data;
                });
                _this.alerts.success('Visa delete successfully.', true);
            });
        }
    };
    VisalistComponent.prototype.onlistchange = function (type1, idval) {
        var _this = this;
        var sstatus;
        for (var _i = 0, _a = this.filterItems; _i < _a.length; _i++) {
            var c = _a[_i];
            if (c.id == idval)
                c.selected = true;
            else
                c.selected = false;
        }
        if (idval == 0 || type1 == false) {
            sstatus = [1, 2, 3];
        }
        else {
            sstatus = [idval];
        }
        var data = { 'id': this.empid, 'cstatus': sstatus };
        this.data.getvisaAllListbyadmin(data).subscribe(function (data) {
            _this.visalistbyadmin = data;
        });
    };
    Object.defineProperty(VisalistComponent.prototype, "f", {
        get: function () {
            return this.visapopForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    VisalistComponent.prototype.onSubmit = function (formvisa, id) {
        var _this = this;
        // var formvisa = value3.value;
        this.submitted = true;
        if (this.visapopForm.invalid) {
            if (formvisa.id == '0') {
                if (!this.selectedFiles)
                    this.filerequirederror = true;
                // if(!this.selectedbackFiles)
                //   this.filebackrequirederror = true;
            }
            return;
        }
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.fileformaterror = true;
                this.filesizeerror = false;
                return;
            }
            else {
                this.filerequirederror = false;
                this.fileformaterror = false;
                this.filesizeerror = false;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            formvisa.imagename = imagepath;
        }
        else {
            if (formvisa.id == '0') {
                this.filerequirederror = true;
                return;
            }
            formvisa.imagename = this.visaDetail.visadocname;
        }
        if (this.selectedbackFiles) {
            this.file = this.selectedbackFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filebacksizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.filebackformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            formvisa.imagebackname = imagepath;
        }
        else {
            // if(formvisa.id == '0')
            // {
            //   this.filebackrequirederror = true;
            //   return;
            // }
            if (formvisa.id == '0') {
                formvisa.imagebackname = '';
            }
            else {
                formvisa.imagebackname = this.visaDetail.visabackdocname;
            }
        }
        formvisa.createby = this.Userid;
        formvisa.updateby = this.Userid;
        var sd = new Date(formvisa.visastart);
        formvisa.visastart = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        var ed = new Date(formvisa.visaexpiry);
        formvisa.visaexpiry = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        this.disableBtn = false;
        this.data.myvisadetailsUpdatebyadmin(formvisa).subscribe(function (data) {
            var sstatus;
            for (var _i = 0, _a = _this.filterItems; _i < _a.length; _i++) {
                var c = _a[_i];
                if (c.selected == true) {
                    if (c.id == 0) {
                        sstatus = [1, 2, 3];
                    }
                    else {
                        sstatus = [c.id];
                    }
                }
            }
            var data1 = { 'id': _this.empid, 'cstatus': sstatus };
            _this.data.getvisaAllListbyadmin(data1).subscribe(function (data) {
                _this.visalistbyadmin = data;
            });
        });
        this.filename = "Choose file";
        this.filebackname = "Choose file";
        this.alerts.success('Visa Info Updated Successfully', true);
        this.modalService.close(id);
    };
    VisalistComponent.prototype.visaselectfile = function (event) {
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.fileformaterror = true;
        }
        else {
            this.fileformaterror = false;
            this.filesizeerror = false;
            // this.selectedFiles = files[0]; 
        }
    };
    VisalistComponent.prototype.visaselectbackfile = function (event) {
        var files = event.target.files;
        this.filebackname = files[0].name;
        this.file = files[0]; //this.selectedbackFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filebacksizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.filebackformaterror = true;
            this.filebacksizeerror = false;
        }
        else {
            this.filebackformaterror = false;
            this.filebacksizeerror = false;
            this.selectedbackFiles = files[0];
        }
    };
    VisalistComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.disableBtn = true;
        this.filerequirederror = false;
        this.filebackrequirederror = false;
        // this.selectFile = null;
        // this.selectbackFile = null;
        this.buttoninsert = "Submit";
        this.visaDetail = new visa_model_1.Visa();
        this.visaDetail.employeeid = this.empid;
        this.visaDetail.employeevisadeatilid = "0";
        // this.visaDetail.visaexpiry ='';
        // this.visaDetail.visastart = '';
        // this.visaDetail.visatype = '';
        this.visaDetail.nationalityid = '';
        this.filename = "Choose file";
        this.filebackname = "Choose file";
        this.modalService.open(id);
        this.visaDetail.iscurrent = "0";
    };
    VisalistComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    VisalistComponent.prototype.openModalvisa3 = function (id, data, backimg) {
        this.iframevisasrc = '';
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1].toLowerCase();
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgvisasrc = true;
            this.iframevisasrc = data;
        }
        else {
            this.imgvisasrc = false;
            this.iframevisasrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        if (backimg) {
            var fullpathArray = backimg.split('.');
            var typeoffile = fullpathArray[fullpathArray.length - 1];
            if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
                this.imgvisabacksrc = true;
                this.iframevisabacksrc = backimg;
            }
            else {
                this.imgvisabacksrc = false;
                this.iframevisabacksrc = this.getSafeUrl("https://docs.google.com/gview?url=" + backimg + "&embedded=true");
            }
        }
        else {
            this.imgvisabacksrc = false;
            this.iframevisabacksrc = '';
        }
        this.modalService.open(id);
    };
    VisalistComponent.prototype.openModal = function (id, data) {
        this.disableBtn = true;
        // this.selectFile =null;
        // this.selectbackFile =null;
        this.visaDetail = data;
        this.buttoninsert = "Update";
        this.modalService.open(id);
        this.visaDetail = data;
        if (data.iscurrent) {
            this.visaDetail.iscurrent = "1";
        }
        else {
            this.visaDetail.iscurrent = "0";
        }
    };
    VisalistComponent.prototype.closeModal = function (id) {
        var _this = this;
        this.visa = new visa_model_1.Visa();
        this.visaDetail = new visa_model_1.Visa();
        var data = { 'id': this.empid, 'cstatus': [1, 2, 3] };
        this.data.getvisaAllListbyadmin(data).subscribe(function (data) {
            _this.visalistbyadmin = data;
        });
        this.modalService.close(id);
    };
    return VisalistComponent;
}());
exports.VisalistComponent = VisalistComponent;
