<div class="bg">
  <app-header></app-header>

  <div class="section-wrapper p-0">
    <div class="admin_dashboard_show">
      <div class="ceee">
        <div class="cardmenu">
        
            <a class="card_box_lit" [routerLink]="['/employeereport']"  >
              <div class="samebox">
                <i class="lnr lnr-users"></i>
                <div class="menu_content">
                    <h4>Employee</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
                
                
            </a>
         
        
            <a class="card_box_lit" [routerLink]="['/report/leave-report-list']"  >
              <div class="samebox">
                <i class="lnr lnr-pencil"></i>
                <div class="menu_content">
                    <h4>Leave</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
                
                
            </a>
         
         
            <a class="card_box_lit" [routerLink]="['/expreport']" >
              <div class="samebox">
                <i class="lnr lnr-gift"></i>
                <div class="menu_content">
                    <h4>Expense</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
                
                
            </a>
         
         
            <a class="card_box_lit" [routerLink]="['/timesheetreport']">
              <div class="samebox">
                <i class="lnr lnr-calendar-full"></i>
                <div class="menu_content">
                    <h4>Timesheet</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
               
                
            </a>
         
       
            <a class="card_box_lit" [routerLink]="['/assetreport']" >
              <div class="samebox">
                <i class="lnr lnr-frame-expand"></i>
                <div class="menu_content">
                    <h4>Asset</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
                
              </div>
                
            </a>
         
         
        </div>
      </div>
   
</div>


    <div class="container">
      <div class="tabs" id="tabs">

        <div class="tabs__sontent">
          <div class="tab2__wcontent__item active-tab" id="jan">
            <div class="content">
              



             
      </div>
    </div>
  </div>
</div>
</div>
</div>


<!-- <div class="dashboard-wrapper">
    <a  [routerLink]="['/employeereport']" class="homeset">
  <div class="dashboard-box">
    <h2>Employee</h2>
    <p class="dash-circle"><i class="fa fa-user" ></i></p>  
    <p class="dash-btm">          
      <i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i>
    </p>
   </div>
  </a>

   <a  [routerLink]="['/report/leave-report-list']"  class="homeset">
   <div class="dashboard-box">
    <h2 >Leave</h2>
      <p class="dash-circle" style="color:#fff;">
        <i class="fa fa-pencil" ></i>
      </p>
      <p class="dash-btm "><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
    
    </div></a> 

    <a  [routerLink]="['/expreport']"  class="homeset">
     <div class="dashboard-box">
        <h2>Expense</h2>
        <p class="dash-circle"><i class="fa fa-gbp"></i></p>
        <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
       </div>
      </a>
      <a  [routerLink]="['/timesheetreport']"  class="homeset">
       <div class="dashboard-box">
          <h2>Timesheet</h2>
          <p class="dash-circle"><i class="fa fa-list-ul" ></i></p>
          <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
         </div>
        </a>
        <a  [routerLink]="['/assetreport']"  class="homeset">
          <div class="dashboard-box">
             <h2>Asset</h2>
             <p class="dash-circle"><i class="fa fa-briefcase" ></i></p>
             <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
            </div>
           </a>
  </div> -->
</div>

<!-- 
<style>
    html {
      background: url(../../../assets/img/bg.png) no-repeat;
      background-position-x: 0%;
      background-position-y: 0%;
      background-size: auto auto;
      background-size: cover;
      background-position: 100%;
    }
  </style>
  <div class="bg">
    <app-header></app-header>
  
    <div class="section-wrapper">
      <div class="container">
        <div class="tabs" id="tabs">
  
          <div class="tabs__content">
            <div class="tabs__content__item active-tab" id="jan">
              <div class="content">
                <div class="row row-sm">
                  <div class="col-lg-12" style="margin-top:10px;">
                    &nbsp;
                  </div>
                </div>
                <mat-tab-group>
                  <mat-tab label="Employee">
                    <ng-template matTabContent>
                      <app-employeereport></app-employeereport>
                    </ng-template>
                  </mat-tab>
                  <mat-tab label="Leave">
                    <ng-template matTabContent>
                      <app-adminleavereport></app-adminleavereport>
                    </ng-template>
                  </mat-tab>
                  <mat-tab label="Expense">
                    <ng-template matTabContent>
                      <app-expreport></app-expreport>
                    </ng-template>
                  </mat-tab>
                  <mat-tab label="Timesheet">
                      <ng-template matTabContent>
                        <app-timesheetreport></app-timesheetreport>
                      </ng-template>
                    </mat-tab>
                  <mat-tab label="Asset">
                      <ng-template matTabContent>
                        <app-assetreport></app-assetreport>
                      </ng-template>
                    </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->