"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_model_1 = require("../../models/employee.model");
var country_service_1 = require("../../services/country.service");
var employee_service_1 = require("../../services/employee.service");
var job_service_1 = require("../../services/job.service");
var excel_service_1 = require("../../services/excel.service");
var visa_service_1 = require("../../services/visa.service");
var passport_service_1 = require("../../services/passport.service");
var employeement_service_1 = require("../../services/employeement.service");
var cos_service_1 = require("../../services/cos.service");
var leave_service_1 = require("../../services/leave.service");
var timesheet_service_1 = require("../../services/timesheet.service");
var expense_service_1 = require("../../services/expense.service");
var graphreport_service_1 = require("../../services/graphreport.service");
var CanvasJS = require("./../../admin/canvasjs.min");
var EmployeereportComponent = /** @class */ (function () {
    function EmployeereportComponent(excelService, formBuilder, country, employee, jobser, visareport, passportreport, employeementreport, cosreport, leave, timesheet, expense, graphReportService) {
        this.excelService = excelService;
        this.formBuilder = formBuilder;
        this.country = country;
        this.employee = employee;
        this.jobser = jobser;
        this.visareport = visareport;
        this.passportreport = passportreport;
        this.employeementreport = employeementreport;
        this.cosreport = cosreport;
        this.leave = leave;
        this.timesheet = timesheet;
        this.expense = expense;
        this.graphReportService = graphReportService;
        this.employeeDetail = new employee_model_1.Employee();
        this.exceldata = [];
    }
    EmployeereportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.graphdisplay = "block";
        this.listdisplay = "none";
        this.display = "none";
        this.EmployeeForm = this.formBuilder.group({
            fname: [''],
            lname: [''],
            emailid: [''],
            phonenumber: [''],
            jobid: [''],
            nationalityid: [''],
            ninumber: [''],
            searctext: ['']
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        var value3 = "{}";
        this.employee.getemployeelist(value3).subscribe(function (listdetails) {
            _this.data = listdetails;
            _this.record = _this.data.length;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
            // console.log(this.data);
            var maleArray = [];
            var femaleArray = [];
            var totalArray = [];
            listdetails.forEach(function (value) {
                totalArray.push(value.employeemasterid);
                if (value.gender == true) {
                    maleArray.push(value.employeemasterid);
                }
                else if (value.gender == false) {
                    femaleArray.push(value.employeemasterid);
                }
            });
            var chart = new CanvasJS.Chart("chartContainer", {
                animationEnabled: true,
                title: {
                    text: "Gender Profile"
                },
                data: [{
                        type: "pie",
                        startAngle: 240,
                        yValueFormatString: "##0.00\"%\"",
                        indexLabel: "{label} {y}",
                        dataPoints: [
                            { y: (maleArray.length / listdetails.length) * 100, label: "Male" },
                            { y: (femaleArray.length / listdetails.length) * 100, label: "Female" },
                        ]
                    }]
            });
            chart.render();
        });
        this.jobser.getjoblist().subscribe(function (jobdetails) {
            _this.joblist = jobdetails;
        });
        this.graphReportService.getemployeeagetotal().subscribe(function (departmentdata) {
            _this.departmentdatalist = departmentdata;
            var malegraphdataarray = [];
            var femalegraphdataarray = [];
            var totalgraphdataarray = [];
            var tage18 = 0;
            var tage25 = 0;
            var tage35 = 0;
            var tage45 = 0;
            var tage55 = 0;
            var age18 = 0;
            var age25 = 0;
            var age35 = 0;
            var age45 = 0;
            var age55 = 0;
            var fage18 = 0;
            var fage25 = 0;
            var fage35 = 0;
            var fage45 = 0;
            var fage55 = 0;
            departmentdata.forEach(function (obj, index) {
                if (obj.gender == true) {
                    switch (true) {
                        case (obj.agetotal >= 17 && obj.agetotal <= 25):
                            age18 = age18 + 1;
                            tage18 = tage18 + 1;
                            break;
                        case (obj.agetotal >= 26 && obj.agetotal <= 35):
                            age25 = age25 + 1;
                            tage25 = tage25 + 1;
                            break;
                        case (obj.agetotal >= 36 && obj.agetotal <= 45):
                            age35 = age35 + 1;
                            tage35 = tage35 + 1;
                            break;
                        case (obj.agetotal >= 46 && obj.agetotal <= 55):
                            age45 = age45 + 1;
                            tage45 = tage45 + 1;
                            break;
                        case (obj.agetotal >= 56 && obj.agetotal <= 75):
                            age55 = age55 + 1;
                            tage55 = tage55 + 1;
                            break;
                        default:
                    }
                }
                else {
                    switch (true) {
                        case (obj.agetotal >= 17 && obj.agetotal <= 25):
                            fage18 = fage18 + 1;
                            tage18 = tage18 + 1;
                            break;
                        case (obj.agetotal >= 26 && obj.agetotal <= 35):
                            fage25 = fage25 + 1;
                            tage25 = tage25 + 1;
                            break;
                        case (obj.agetotal >= 36 && obj.agetotal <= 45):
                            fage35 = fage35 + 1;
                            tage35 = tage35 + 1;
                            break;
                        case (obj.agetotal >= 46 && obj.agetotal <= 55):
                            fage45 = fage45 + 1;
                            tage45 = tage45 + 1;
                            break;
                        case (obj.agetotal >= 56 && obj.agetotal <= 75):
                            fage55 = fage55 + 1;
                            tage55 = tage55 + 1;
                            break;
                        default:
                    }
                }
            });
            totalgraphdataarray.push({ x: 1, y: tage18, label: "Below 25" });
            totalgraphdataarray.push({ x: 2, y: tage25, label: "26-35" });
            totalgraphdataarray.push({ x: 3, y: tage35, label: "36-45" });
            totalgraphdataarray.push({ x: 4, y: tage45, label: "46-55" });
            totalgraphdataarray.push({ x: 5, y: tage55, label: "Above 55" });
            malegraphdataarray.push({ x: 1, y: age18, label: "Below 25" });
            malegraphdataarray.push({ x: 2, y: age25, label: "26-35" });
            malegraphdataarray.push({ x: 3, y: age35, label: "36-45" });
            malegraphdataarray.push({ x: 4, y: age45, label: "46-55" });
            malegraphdataarray.push({ x: 5, y: age55, label: "Above 55" });
            femalegraphdataarray.push({ x: 1, y: fage18, label: "Below 25" });
            femalegraphdataarray.push({ x: 2, y: fage25, label: "26-35" });
            femalegraphdataarray.push({ x: 3, y: fage35, label: "36-45" });
            femalegraphdataarray.push({ x: 4, y: fage45, label: "46-55" });
            femalegraphdataarray.push({ x: 5, y: fage55, label: "Above 55" });
            _this.listage = [
                {
                    typename: "Below 25",
                    malecount: age18,
                    femalecount: fage18,
                    totalcount: age18 + fage18
                },
                {
                    typename: "26-35",
                    malecount: age25,
                    femalecount: fage25,
                    totalcount: age25 + fage25
                }, {
                    typename: "36-45",
                    malecount: age35,
                    femalecount: fage35,
                    totalcount: age35 + fage35
                }, {
                    typename: "46-55",
                    malecount: age45,
                    femalecount: fage45,
                    totalcount: age45 + fage45
                }, {
                    typename: "Above 55",
                    malecount: age55,
                    femalecount: fage55,
                    totalcount: age55 + fage55
                }
            ];
            var data = [
                {
                    type: "stackedColumn",
                    showInLegend: true,
                    color: "#696661",
                    name: "Employee",
                    yValueFormatString: "#0",
                    dataPoints: totalgraphdataarray
                }
                // {
                //   type: "stackedColumn",
                //   showInLegend: true,
                //   color: "#696661",
                //   name: "Male",
                //   yValueFormatString: "#0",
                //   dataPoints: malegraphdataarray
                // },
                // {
                //   type: "stackedColumn",
                //   showInLegend: true,
                //   color: "#B6B1A8",
                //   yValueFormatString: "#0",
                //   name: "Female",
                //   dataPoints: femalegraphdataarray
                // }
            ];
            // this.departmentreportage(data);
        });
        this.graphReportService.getdepartmentgraphList().subscribe(function (departmentdata) {
            _this.departmentdatalist = departmentdata;
            var totalemparray = [];
            var malegraphdataarray = [];
            var femalegraphdataarray = [];
            departmentdata.forEach(function (obj, index) {
                totalemparray.push({ x: index + 1, y: obj.count, label: obj.typename });
                // malegraphdataarray.push({ x: index + 1, y: obj.mcount, label: obj.typename });
                // femalegraphdataarray.push({ x: index + 1, y: obj.fcount, label: obj.typename });
            });
            var data = [
                {
                    type: "stackedBar",
                    name: "Employee",
                    showInLegend: "true",
                    xValueFormatString: "DD, MMM",
                    yValueFormatString: "#0",
                    dataPoints: totalemparray
                }
                // {
                //   type: "stackedBar",
                //   name: "Male",
                //   showInLegend: "true",
                //   xValueFormatString: "DD, MMM",
                //   yValueFormatString: "#0",
                //   dataPoints: malegraphdataarray
                // },
                // {
                //   type: "stackedBar",
                //   name: "Female",
                //   showInLegend: "true",
                //   xValueFormatString: "DD, MMM",
                //   yValueFormatString: "#0",
                //   dataPoints: femalegraphdataarray
                // }
            ];
            _this.departmentreport(data);
        });
    };
    EmployeereportComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    EmployeereportComponent.prototype.showcondition = function (type) {
        if (type == 'list') {
            this.graphdisplay = "none";
            this.listdisplay = "block";
        }
        else {
            this.listdisplay = "none";
            this.graphdisplay = "block";
        }
    };
    EmployeereportComponent.prototype.departmentreportage = function (data) {
        var chart = new CanvasJS.Chart("chartContainer5", {
            animationEnabled: true,
            title: {
                text: "Google - Consolidated Quarterly Revenue",
                fontFamily: "arial black",
                fontColor: "#695A42"
            },
            axisX: {
                interval: 1,
                intervalType: "year"
            },
            axisY: {
                valueFormatString: "#0",
                gridColor: "#B6B1A8",
                tickColor: "#B6B1A8"
            },
            toolTip: {
                shared: true,
                content: toolTipContent
            },
            data: data
        });
        chart.render();
        function toolTipContent(e) {
            var str = "";
            var total = 0;
            var str2, str3;
            for (var i = 0; i < e.entries.length; i++) {
                var str1 = "<span style= \"color:" + e.entries[i].dataSeries.color + "\"> " + e.entries[i].dataSeries.name + "</span>: $<strong>" + e.entries[i].dataPoint.y + "</strong>bn<br/>";
                total = e.entries[i].dataPoint.y + total;
                str = str.concat(str1);
            }
            str2 = "<span style = \"color:DodgerBlue;\"><strong>" + (e.entries[0].dataPoint.x).getFullYear() + "</strong></span><br/>";
            total = Math.round(total * 100) / 100;
            str3 = "<span style = \"color:Tomato\">Total:</span><strong> $" + total + "</strong>bn<br/>";
            return (str2.concat(str)).concat(str3);
        }
    };
    EmployeereportComponent.prototype.departmentreport = function (data) {
        var chart = new CanvasJS.Chart("chartContainer2", {
            animationEnabled: true,
            title: {
                text: "Department Employee Ratio"
            },
            axisX: {
                valueFormatString: "DDD"
            },
            axisY: {
                prefix: ""
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                itemclick: toggleDataSeries
            },
            data: data
        });
        chart.render();
        function toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            }
            else {
                e.dataSeries.visible = true;
            }
            chart.render();
        }
    };
    EmployeereportComponent.prototype.exportAsXLSX = function () {
        var _this = this;
        this.exceldata = [];
        this.data.forEach(function (obj) {
            _this.employeedata = {
                "Name": obj.fname + " " + obj.mname + " " + obj.lname,
                "Email": obj.emailid,
                "Phone Number": obj.phonenumber,
                "NI Number": obj.ninumber,
                "Nationality": obj.nationality.countryname,
                "Country of Residence": obj.residence.countryname,
                "Country of Birth": obj.countryofbirth.countryname,
                "Place of Birth": obj.placeofbirth
            };
            _this.exceldata.push(_this.employeedata);
        });
        this.excelService.exportAsExcelFile(this.exceldata, 'Employee_Report');
    };
    EmployeereportComponent.prototype.onexcelt = function () {
        var _this = this;
        this.display = "block";
        var value3 = "{}";
        this.employee.getemployeelist(value3).subscribe(function (listdetails) {
            if (listdetails)
                _this.excelService.employeeexcel(listdetails);
            _this.visareport.getvisalist(value3).subscribe(function (visadetails) {
                if (visadetails)
                    _this.excelService.visaexcel(visadetails);
                _this.passportreport.getpassportlist(value3).subscribe(function (passportdetails) {
                    if (passportdetails)
                        _this.excelService.passportexcel(passportdetails);
                    _this.employeementreport.getemployementlist(value3).subscribe(function (employementetails) {
                        if (employementetails)
                            _this.excelService.employementexcel(employementetails);
                        _this.cosreport.getcoslist(value3).subscribe(function (cosdetails) {
                            if (cosdetails)
                                _this.excelService.cosexcel(cosdetails);
                            // this.leave.leavereport(value3).subscribe((leavereport: any) => {
                            //   if (leavereport)
                            //     this.excelService.leaveexcel(leavereport);
                            //   this.timesheet.gettimesheetList(value3).subscribe((timesheetDetails: any) => {
                            //     if (timesheetDetails)
                            //       this.excelService.timesheetexcel(timesheetDetails);
                            //     this.expense.getexpenseList(value3).subscribe((expenseDetails: any) => {
                            //       if (expenseDetails)
                            //         this.excelService.expenseexcel(expenseDetails);
                            _this.display = "none";
                            _this.excelService.exportAsExcelFileAll('Employee_Report');
                            //     });
                            //   });
                            // });
                        });
                    });
                });
            });
        });
    };
    EmployeereportComponent.prototype.onSubmit = function (value3) {
        var _this = this;
        this.employee.getemployeelist(value3).subscribe(function (listdetails) {
            _this.data = listdetails;
        });
    };
    return EmployeereportComponent;
}());
exports.EmployeereportComponent = EmployeereportComponent;
