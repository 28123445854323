<div class="bg">
    <app-header></app-header>

    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__content">
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row row-sm topheader">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Location List <a *ngIf="locationperm > 1" (click)="openModal2('custom-location-1')" class="mainheading_btn"> + Add New</a></h5>
                                </div>
                                <div class="col-lg-6">
                                    <div class="input-group">
                                        <input [(ngModel)]="searchText" placeholder="Search.." class="form-control">
                                    </div> 
                                </div>
                            </div>
                            <ng-container *ngIf="( datalist | filter: {passportnumber: searchText,passportissue: searchText,passportexpiry: searchText }) as pageOfItems"> 
                            
                            
                            </ng-container>
                            <div class="job_list_section employee_list_section loction_section" *ngFor="let location of data | filter: {pincode: searchText,address1: searchText,address2: searchText,statename: searchText };  let i=index">
                                <div class="job_detils_date width22">
                                    <p class="job_address">Address</p>
                                    <h5 class="job_profile font_17">{{location.address1}} </h5>
                                    <p class="email colordarkgray" *ngIf="location.address2 != ''">{{location.address2}}</p>
                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address"> Location Type</p>
                                    <p class="job_profile">{{location.companylocationtype.companytypename}}</p>
                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address"> County</p>
                                    <p class="job_profile">{{location.statename}}</p>
                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address"> Country</p>
                                    <p class="job_profile">{{location.country.countryname}}</p>
                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address"> Post Code</p>
                                    <p class="job_profile">{{location.pincode}}</p>
                                </div>

                                <div class="job_detils_date visa_btn">
                                    <div class="job_address d-flex aligncenter">
                                        <button type="submit" class="btn btn-primary com_btn" *ngIf="locationperm > 2" (click)="onDelete(location)"><i class="lnr lnr-trash"></i></button>
                                        <button type="submit" class="btn btn-danger com_btn" *ngIf="locationperm > 2" (click)="openModal('custom-location-1',location)"><i class="lnr lnr-pencil"></i></button>
                                        <!-- <button type="submit" class="btn btn-secondary com_btn" (click)="openModal('custom-passport-1',passport)"><i class="fa fa-trash-o"></i></button> -->
                                        <div class="onoffswitch" *ngIf="(!location.isdefault) && (locationperm > 2)">
                                            <label class="onoffswitch-label" for="skill{{i+1}}" class="switch">
                                                <input type="checkbox" name="{{ location.isdefault }}" type="checkbox" [checked]="location.isdefault" (change)="onChangeStatus($event, location)" class="onoffswitch-checkbox" id="skill{{i+1}}" >
                                                <span class="slider round"></span>
                                              </label>
                                        </div>
                                        <label *ngIf="location.isdefault" class="currentshow">Current</label>
                                    </div>
                                </div>
                            </div>
                            <div class="job_list_section visa_request_section" *ngIf="record == 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
</div>

<jw-modal id="custom-location-1" role="dialog" class="modal">
    <form [formGroup]="locationformGroup" #companylocationForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(companylocationForm.value,'custom-location-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" id="id" [(ngModel)]="locationDetail.companylocationdetailsid" class="form-control" name="id">
                    <h5 class="modal-title" id="popupLabel">Location Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-location-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Location Type *</label>
                                <select style="width:100%;" formControlName="companytypeid" name="companytypeid" id="companytypeid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.companytypeid.errors }" [(ngModel)]="locationDetail.companytypeid">
                  <option [value]="defaultVal">Select Location Type </option>
                  <option *ngFor="let locationtype of companytype" [value]="locationtype.locationcompanytypesid">
                    {{ locationtype.companytypename }}</option>
                </select>
                                <div *ngIf="submitted && f.companytypeid.errors" class="invalid-feedback">
                                    <div *ngIf="f.companytypeid.errors.required">Please select location type</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Post Code*</label>
                                <input type="text" formControlName="pincode" id="pincode" name="pincode" class="form-control pincode" [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }" [(ngModel)]="locationDetail.pincode" (input)="onSearchChange($event.target.value)" matInput
                                    [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="pincode">
                                    <mat-option *ngFor="let option of options" [value]="option" (onSelectionChange)="updatecode($event, option)">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                    <div *ngIf="f.pincode.errors.required">Please enter post code </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Address Line 1*</label>
                                <input type="text" formControlName="address1" name="address1" id="address1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" [(ngModel)]="locationDetail.address1">
                                <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
                                    <div *ngIf="f.address1.errors.required">Please enter address</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Address Line 2 </label>
                                <input type="text" formControlName="address2" name="address2" id="address2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" [(ngModel)]="locationDetail.address2">
                                <div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
                                    <div *ngIf="f.address2.errors.required">Please enter address</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>City*</label>
                                <input type="text" formControlName="cityname" name="cityname" id="cityname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.cityname.errors }" [(ngModel)]="locationDetail.cityname">
                                <div *ngIf="submitted && f.cityname.errors" class="invalid-feedback">
                                    <div *ngIf="f.cityname.errors.required">Please enter city </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>County*</label>
                                <input type="text" formControlName="statename" name="statename" id="statename" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.statename.errors }" [(ngModel)]="locationDetail.statename">
                                <!-- <select style="width:100%;" formControlName="statename" name="statename" id="statename" class="form-control input-lg"
                  [ngClass]="{ 'is-invalid': submitted && f.stateid.errors }" ngModel="{{ locationDetail.stateid }}">
                  <option value="">Select County </option>
                  <option *ngFor="let county of countylist" value='{{ county.stateid }}' [selected]="county.stateid == locationDetail.stateid">{{
                    county.statename }}</option>
                </select> -->
                                <div *ngIf="submitted && f.statename.errors" class="invalid-feedback">
                                    <div *ngIf="f.statename.errors.required">Please select county</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label>Country*</label>
                                <select style="width:100%;" formControlName="countryid" name="countryid" id="countryid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.countryid.errors }" [(ngModel)]="locationDetail.countryid">
                  <option [value]="">Select Country </option>
                  <option *ngFor="let country of countrylist" [value]="country.countryid">{{ country.countryname }}</option>
                </select>
                                <div *ngIf="submitted && f.countryid.errors" class="invalid-feedback">
                                    <div *ngIf="f.countryid.errors.required">Please select country</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-location-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>