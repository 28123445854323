import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'app-upgradepaymentsuccess',
  templateUrl: './upgradepaymentsuccess.component.html',
  styleUrls: ['./upgradepaymentsuccess.component.css']
})
export class UpgradepaymentsuccessComponent implements OnInit {



  ctype='';
  planid:any;

  constructor(private router: Router,private activatedRoute: ActivatedRoute, public paymentService: PaymentService,) { }

  ngOnInit() {  
    
    
    this.planid = this.activatedRoute.snapshot.paramMap.get("planid");    
    this.ctype = this.activatedRoute.snapshot.paramMap.get("id");

    var data = {'token': this.ctype,planid: this.planid}




    this.paymentService.upgradeuserpaymentByCustomer(data).subscribe((data: any) => 
    {  

      if(data.success == true)
      {
        localStorage.removeItem('ctype');
        localStorage.setItem('ctype', '1' );   
        localStorage.setItem('isverify', "1");
        this.router.navigate(['/check-dashboard']);         
      }else
      {  localStorage.setItem('isverify', "1");
        this.router.navigate(['/check-dashboard']);         
      }
    });

    // setTimeout(() => {
    //   this.ctype = localStorage.getItem('ctype');      
    //   if (this.ctype == '0' ) 
    //   {
    //     this.router.navigate(['choose-plan']);
    //   }
    //  else
    //  {
    //   this.router.navigate(['login']);
    //  }
    //   // this.router.navigate(['/dashboard']);
    // }, 9000);
  
  //5s
  }
}