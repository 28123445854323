import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';


@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private commonService: CommonService) { }
  getcountrylist(): Observable<any> 
  {
    return this.commonService.formpostwithouttoken('/employee/countrylist',{id:1});   
  }

  getcountrylistbyid(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/countrybyid',data);   
  }
  getstatelist(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/statebyid',data);
  }
  getsoclist(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/soclist');   
  }
}
