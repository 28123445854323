"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("./../environments/environment");
// @Injectable()
var AppGlobals = /** @class */ (function () {
    function AppGlobals() {
    }
    AppGlobals.formpostss = function (arg0, data) {
        throw new Error("Method not implemented");
    };
    // public static API_ENDPOINT = 'http://192.168.1.5:8080';
    AppGlobals.API_ENDPOINT = environment_1.environment.APIEndpoint; // 'http://localhost:8080';
    AppGlobals.SCREENING_API_ENDPOINT = 'http://localhost:3000';
    AppGlobals.API_STRIPE = environment_1.environment.stripkey;
    AppGlobals.API_AML = environment_1.environment.amlurl;
    AppGlobals.API_AMLKEY = environment_1.environment.amlkey;
    AppGlobals.TIME_VALUES = [
        { "key": 0, "value": "00:00" },
        { "key": 1, "value": "01:00" },
        { "key": 2, "value": "02:00" },
        { "key": 3, "value": "03:00" },
        { "key": 4, "value": "04:00" },
        { "key": 5, "value": "05:00" },
        { "key": 6, "value": "06:00" },
        { "key": 7, "value": "07:00" },
        { "key": 8, "value": "08:00" },
        { "key": 9, "value": "09:00" },
        { "key": 10, "value": "10:00" },
        { "key": 11, "value": "11:00" },
        { "key": 12, "value": "12:00" },
        { "key": 13, "value": "13:00" },
        { "key": 14, "value": "14:00" },
        { "key": 15, "value": "15:00" },
        { "key": 16, "value": "16:00" },
        { "key": 17, "value": "17:00" },
        { "key": 18, "value": "18:00" },
        { "key": 19, "value": "19:00" },
        { "key": 20, "value": "20:00" },
        { "key": 21, "value": "21:00" },
        { "key": 22, "value": "22:00" },
        { "key": 23, "value": "23:00" }
    ];
    AppGlobals.YEAR_VALUES = [
        2020,
        2021,
        2022,
        2023,
        2024,
        2025
    ];
    AppGlobals.CURRENCIES = [
        { "currency": "GBP", "symbol": "£" },
        { "currency": "USD", "symbol": "$" },
        { "currency": "EUR", "symbol": "€" },
        { "currency": "INR", "symbol": "₹" }
    ];
    AppGlobals.MONTHS = [
        { "key": 1, "value": "January" },
        { "key": 2, "value": "February" },
        { "key": 3, "value": "March" },
        { "key": 4, "value": "April" },
        { "key": 5, "value": "May" },
        { "key": 6, "value": "June" },
        { "key": 7, "value": "July" },
        { "key": 8, "value": "August" },
        { "key": 9, "value": "September" },
        { "key": 10, "value": "October" },
        { "key": 11, "value": "November" },
        { "key": 12, "value": "December" }
    ];
    return AppGlobals;
}());
exports.AppGlobals = AppGlobals;
