"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// import { EmployeeMaster } from './../models/employee_master';
// import { HeaderComponent } from './user/header/header.component';
var core_1 = require("@angular/core");
var mycos_service_1 = require("../services/mycos.service");
var platform_browser_1 = require("@angular/platform-browser");
var modal_service_1 = require("../_services/modal.service");
var MycosComponent = /** @class */ (function () {
    function MycosComponent(data, sanitizer, modalService) {
        this.data = data;
        this.sanitizer = sanitizer;
        this.modalService = modalService;
        this.cosdetails = null;
    }
    MycosComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.data.getcosList().subscribe(function (data) {
            _this.cosdetails = data;
        });
    };
    MycosComponent.prototype.openModal_cosdocument = function (id, data) {
        this.iframecossrc = '';
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgcossrc = true;
            this.iframecossrc = data;
        }
        else {
            this.imgcossrc = false;
            this.iframecossrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    MycosComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    MycosComponent.prototype.closeModal = function (id) {
        // this.employeementDetail = new Cos();
        this.modalService.close(id);
    };
    return MycosComponent;
}());
exports.MycosComponent = MycosComponent;
