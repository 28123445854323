<!-- <style>
  .input-file-container {
    position: relative;
    width: 225px;
  }

  .js .input-file-trigger {
    display: block;
    padding: 14px 45px;
    background: #39D2B4;
    color: #fff;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
  }

  .js .input-file {
    position: absolute;
    top: 0;
    left: 0;
    width: 225px;
    opacity: 0;
    padding: 14px 0;
    cursor: pointer;
  }

  .js .input-file:hover+.input-file-trigger,
  .js .input-file:focus+.input-file-trigger,
  .js .input-file-trigger:hover,
  .js .input-file-trigger:focus {
    background: #34495E;
    color: #39D2B4;
  }
</style> -->
<div class="overviewsshow">
    <div class="header">
        <h1 class="headprofile mt-0">Compensation</h1>
        <a (click)="openModal2('custom-compensation-info-1')" class="addscreensuccess">Add Compensation</a>
    </div>
    <div class="row">
        <div class="col-lg-6" *ngFor="let compens of compensationlist">
            <div class="border-box">
                <div class="addhead header mb-3">
                    <h4>{{compens.nameofbank}}</h4>
                    <div class="icons_box_section" >
                        <a (click)="openModal('custom-compensation-info-1',compens)" class="editprime "><i
                            class="lnr lnr-pencil"></i></a>
                            <a (click)="onDelete(compens.id)" class="deletered ">
                                <i class="lnr lnr-trash"></i></a>
                    </div>
                </div>
                <div class="overview_details emp">
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3">
                            <span>Account Name</span>
                            <p>{{compens.accountname}}</p>
                        </div>
                        <div class="detailsshow width50 ">
                            <span>Sort Code</span>
                            <p>{{compens.sortcode}}</p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3">
                            <span>Account Number</span>
                            <p>{{compens.accountnumber}}</p>
                        </div>
                        <div class="detailsshow width50">
                            <span>IBAN</span>
                            <p> {{compens.iban}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="job_list_section visa_request_section" *ngIf="!compensationlist?.length  > false">
        <div class="job_detils_date">
            <p class="job_profile">No Record Found</p>
        </div>
    </div> 
</div>

<!-- <div class="row">
    <div class="col-lg-7">
        <h1 class="headprofile">Compensation</h1>
    </div>
    <div class="col-lg-5 float-right">
        <h6 class="text-right float-right buttonprofile">
            <a (click)="openModal2('custom-compensation-info-1')" class="addscreensuccess">+ Add Compensation</a></h6>
    </div>

</div> -->

<!-- 
<div class="row row-sm mt-4 ml-0 mr-0">
  <div class="col-lg-12">
    <h6 class="text-right float-right">
      <a (click)="openModal2('custom-compensation-info-1')" class="addscreensuccess">+ Add New</a></h6>
  </div>
</div> -->


<!-- <div class="row row-sm">

    <div class="col-lg-4" *ngIf="!compensationlist?.length  > false">
        <div class="billed-to">
            No Record Found
        </div>
    </div>

    <div class="col-lg-4" *ngFor="let compens of compensationlist">
        <div class="billed-to compeninfo">
            <h6 class="tx-gray-800">Name of Bank: {{compens.nameofbank}}</h6>
            <p><b>Account Name: </b>{{compens.accountname}}</p>
            <p><b>Sort Code: </b>{{compens.sortcode}}</p>
            <p><b>Account Number: </b>{{compens.accountnumber}}</p>
            <p><b>IBAN: </b> {{compens.iban}} &nbsp;</p>
            
            <div class="con_icons">
                <a (click)="openModal('custom-compensation-info-1',compens)" class="lsview"><i
                class="fa fa-pencil mg-r-10"></i>&nbsp;</a>
                <a (click)="onDelete(compens.id)" class="lsview">
                    <i class="fa fa-trash-o mg-l"></i></a>
            </div>

        </div>
    </div>
</div> -->

<jw-modal id="custom-compensation-info-1" class="modal" role="dialog">
    <form [formGroup]="compensationformGroup" #compensationForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(compensationForm.value,'custom-compensation-info-1')" *ngIf="compensationDetail">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="compensationDetail.id">
                    <input type="hidden" formControlName="employeeid" class="form-control" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="compensationDetail.employeeid">
                    <h5 class="modal-title" id="popupLabel">Compensation Info</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-compensation-info-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Name of Bank*</label>
                                <input type="text" formControlName="nameofbank" class="" name="nameofbank" id="nameofbank" 
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nameofbank.errors }" 
                                    [(ngModel)]="compensationDetail.nameofbank" maxlength="50">
                                <div *ngIf="submitted && f.nameofbank.errors" class="invalid-feedback">
                                    <div *ngIf="f.nameofbank.errors.required">Please enter name of bank </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Account Name*</label>
                                <input type="text" formControlName="accountname" class="" name="accountname" id="accountname" 
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nameofbank.errors }" 
                                    [(ngModel)]="compensationDetail.accountname"  maxlength="50">
                                <div *ngIf="submitted && f.accountname.errors" class="invalid-feedback">
                                    <div *ngIf="f.accountname.errors.required">Please enter account name</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Sort Code*</label>
                                <input type="text" formControlName="sortcode" class="" name="sortcode" id="sortcode" 
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nameofbank.errors }" 
                                    [(ngModel)]="compensationDetail.sortcode" maxlength="20">
                                <div *ngIf="submitted && f.sortcode.errors" class="invalid-feedback">
                                    <div *ngIf="f.sortcode.errors.required">Please enter sort code</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Account Number*</label>
                                <input type="text" formControlName="accountnumber" name="accountnumber" id="accountnumber" 
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nameofbank.errors }" 
                                    [(ngModel)]="compensationDetail.accountnumber" maxlength="20">
                                <div *ngIf="submitted && f.accountnumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.accountnumber.errors.required">Please enter account number</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>IBAN</label>
                                <input type="text" formControlName="iban" id="iban" name="iban" class="form-control" 
                                [(ngModel)]="compensationDetail.iban" maxlength="20">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-compensation-info-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>