"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// import { EmployeeMaster } from './../models/employee_master';
// import { HeaderComponent } from './user/header/header.component';
var core_1 = require("@angular/core");
var mypassport_service_1 = require("../../../services/mypassport.service");
var modal_service_1 = require("../../../_services/modal.service");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var passport_model_1 = require("../../../models/passport/passport.model");
var country_service_1 = require("../../../services/country.service");
var forms_1 = require("@angular/forms");
var alert_service_1 = require("../../../services/alert.service");
var aicenter_service_1 = require("../../../services/aicenter.service");
var PassportrequestComponent = /** @class */ (function () {
    function PassportrequestComponent(sanitizer, formBuilder, uploadService, data, country, aicenter, modalService, router, alerts) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.data = data;
        this.country = country;
        this.aicenter = aicenter;
        this.modalService = modalService;
        this.router = router;
        this.alerts = alerts;
        this.aipayment = false;
        this.filterItems = [
            {
                name: 'All Passport Details',
                id: 0,
                selected: true
            },
            {
                name: 'Accepted',
                id: 2,
                selected: false
            },
            {
                name: 'Rejected',
                id: 3,
                selected: false
            },
            {
                name: 'Waiting for review',
                id: 1,
                selected: false
            }
        ];
    }
    PassportrequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.requestperm = this.permission.m15;
        this.passformGroup = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            passportnumber: ['', forms_1.Validators.required],
            placeofissue: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            passportissue: ['', forms_1.Validators.required],
            passportexpiry: ['', forms_1.Validators.required],
            iscurrent: [''],
        });
        this.aicenter.getcountpayment().subscribe(function (data) {
            _this.aipayment = data.payment;
        });
        this.onChanges();
        this.filename = 'Choose file';
        this.filebackname = 'Choose file';
        var id = localStorage.getItem('userToken');
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'cstatus': [1] };
        this.data.getallpassportListbyadmin(data).subscribe(function (data) {
            // this.passportlist = data;
            _this.record = data.length;
            _this.datalist = data;
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.items = data;
            }
        });
        this.listcheck = this.filterItems;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.passportDetail = new passport_model_1.Passport();
        this.passportDetail.sstatus = 0;
    };
    PassportrequestComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    PassportrequestComponent.prototype.onChangeDate = function (event) {
    };
    PassportrequestComponent.prototype.onChanges = function () {
        var _this = this;
        this.formattedMessage = '';
        this.passformGroup.valueChanges.subscribe(function (val) {
            _this.formattedMessage = 'Hello, My name is and my email is .I would like to tell you that .';
        });
    };
    PassportrequestComponent.prototype.onlistchange = function (type1, idval) {
        var _this = this;
        var userid = localStorage.getItem('userid');
        var data = { 'id': userid, 'cstatus': [1] };
        this.data.getallpassportListbyadmin(data).subscribe(function (data) {
            _this.passportlist = data;
        });
    };
    Object.defineProperty(PassportrequestComponent.prototype, "f", {
        get: function () { return this.passformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    PassportrequestComponent.prototype.onSubmit = function (value3, id, cstatus) {
        var _this = this;
        this.submitted = true;
        if (this.passformGroup.invalid) {
            return;
        }
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagename = imagepath;
        }
        else {
            value3.imagename = this.passportDetail.passportdocname;
        }
        if (this.selectedbackFiles) {
            this.file = this.selectedbackFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagebackname = imagepath;
        }
        else {
            if (value3.id == '0') {
                value3.imagebackname = '';
            }
            else {
                value3.imagebackname = this.passportDetail.passportdocname;
            }
        }
        var error = 0;
        if (value3.passportnumber == '') {
            this.passnumbererror = false;
            error++;
        }
        if (value3.nationalityid == '') {
            this.nationalityerror = false;
            error++;
        }
        if (value3.passportissue == null) {
            this.passportdateoriginerror = false;
            error++;
        }
        if (error > 0) {
            return false;
        }
        this.disableBtn = false;
        value3.cstatus = cstatus;
        // value3.createdby = cstatus;
        value3.createby = this.Userid;
        value3.updateby = this.Userid;
        this.data.mypassportdetailsUpdatebyadmin(value3).subscribe(function (data) {
            _this.submitted = false;
            var userid = localStorage.getItem('userid');
            var data1 = { 'id': userid, 'cstatus': [1] };
            _this.data.getallpassportListbyadmin(data1).subscribe(function (data) {
                _this.record = data.length;
                _this.datalist = data;
                if (_this.record < 30) {
                    _this.pageOfItems = data;
                }
                else {
                    _this.items = data;
                }
            });
        });
        //this.alerts.setDefaults('timeout',0);
        this.messageSuccess = 'Passport details updated successfully.';
        this.alerts.success(this.messageSuccess, false);
        this.filename = "Choose file";
        this.filebackname = 'Choose file';
        this.modalService.close(id);
    };
    PassportrequestComponent.prototype.selectFile = function (event) {
        var files = event.target.files;
        this.filename = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.fileformaterror = true;
            this.filesizeerror = false;
        }
        else {
            this.filesizeerror = false;
            this.fileformaterror = false;
            this.filereuirederror = false;
            this.imagesecond = this.uploadService.uploadfile(this.file);
            this.selectedFiles = files[0];
        }
    };
    PassportrequestComponent.prototype.selectbackFile = function (event) {
        var files = event.target.files;
        this.filebackname = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filebacksizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.filebackformaterror = true;
            this.filebacksizeerror = false;
        }
        else {
            this.filebacksizeerror = false;
            this.filebackformaterror = false;
            this.imagesecond = this.uploadService.uploadfile(this.file);
            this.selectedbackFiles = files[0];
        }
    };
    PassportrequestComponent.prototype.onDelete = function (empid) {
        var _this = this;
        if (confirm("Are you sure you want to delete?")) {
            var data1 = { 'id': empid };
            this.data.deletePassport(data1).subscribe(function (data) {
                var sstatus;
                for (var _i = 0, _a = _this.filterItems; _i < _a.length; _i++) {
                    var c = _a[_i];
                    if (c.selected == true) {
                        if (c.id == 0) {
                            sstatus = [1, 2, 3];
                        }
                        else {
                            sstatus = [c.id];
                        }
                    }
                }
                var userid = localStorage.getItem('userid');
                var data1 = { 'id': userid, 'cstatus': sstatus };
                _this.data.getallpassportListbyadmin(data1).subscribe(function (data) {
                    _this.alerts.success('Passport deleted successfully.', true);
                    _this.passportlist = data;
                });
            });
        }
    };
    PassportrequestComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.disableBtn = true;
        this.passportDetail = new passport_model_1.Passport();
        this.buttoninsert = "Submit";
        this.filename = "Choose file";
        this.filebackname = 'Choose file';
        this.Userid = localStorage.getItem('userid');
        this.passportDetail.employeeid = this.Userid;
        this.passportDetail.employeepassdeatilid = "0";
        this.passportDetail.iscurrent = "0";
        this.passformGroup.reset(this.passportDetail);
        // this.passportDetail.passportnumber = '';
        // this.passportDetail.nationalityid = '';
        // this.passportDetail.passportissue = '';
        // this.passportDetail.passportexpiry = '';
        // this.passportDetail.placeofissue = '';
        this.modalService.open(id);
    };
    PassportrequestComponent.prototype.openModal = function (id, data) {
        this.submitted = false;
        this.disableBtn = true;
        this.passportDetail = new passport_model_1.Passport();
        this.filename = "Choose file";
        this.filebackname = 'Choose file';
        this.buttoninsert = "Update";
        this.passportDetail = data;
        this.modalService.open(id);
        if (data.iscurrent) {
            this.passportDetail.iscurrent = "1";
        }
        else {
            this.passportDetail.iscurrent = "0";
        }
        this.passformGroup.reset(this.passportDetail);
    };
    PassportrequestComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    PassportrequestComponent.prototype.openModal3 = function (id, data, backimg) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        if (backimg) {
            var fullpathArray = backimg.split('.');
            var typeoffile = fullpathArray[fullpathArray.length - 1];
            if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
                this.imgpassportbacksrc = true;
                this.iframepassportbacksrc = backimg;
            }
            else {
                this.imgpassportbacksrc = false;
                this.iframepassportbacksrc = this.getSafeUrl("https://docs.google.com/gview?url=" + backimg + "&embedded=true");
            }
        }
        else {
            this.imgpassportbacksrc = false;
            this.iframepassportbacksrc = '';
        }
        this.modalService.open(id);
    };
    PassportrequestComponent.prototype.closeModal = function (id) {
        var _this = this;
        this.passportDetail = new passport_model_1.Passport();
        var userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'cstatus': [1] };
        this.data.getallpassportListbyadmin(data).subscribe(function (data) {
            _this.record = data.length;
            _this.datalist = data;
            if (_this.record < 30) {
                _this.pageOfItems = data;
            }
            else {
                _this.items = data;
            }
        });
        this.modalService.close(id);
    };
    PassportrequestComponent.prototype.modalOpen = function (data) {
        this.disableBtn = true;
        this.passportDetail = new passport_model_1.Passport();
        this.passportDetail = data;
    };
    return PassportrequestComponent;
}());
exports.PassportrequestComponent = PassportrequestComponent;
