
<div class="check_verification" *ngIf="token_expired">
  <div class="wizadlineshow">
      <div class="wizard_details">
          <div class="container">
              <div class="main-banner">
                  <div class="banner-form">
                      <div class="row">
                          <div class="col-sm-12">
                              <div class="tankushow">
                                  <div class="detailstext">
                                      <h1 class="tokenfont">Token Expired</h1>
                                      <p></p>
                                  </div>
                                  <img alt="shape" src="../../assets/img/thanks.png">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<style>
.wizadlineshow
{
  width: 90%;
  margin: 40px auto;
}
.wizard_img_box
{
  width: 100%;
}
.docvisapass .check_support
{width: 100%;}
</style>

<div  class="s12 white docvisapass" *ngIf="!token_expired">
  <div class="check_verification">
    <div class="wizadlineshow">

      <div class="row"  >
        <div class="col-sm-12 text-center">
          <img src="{{logo}}">

        </div>
      </div>



      <div class="wizard_img_box">
        <ul class="wizard_ul">
          <li *ngFor="let step of steps; let last = last" class="col-md-4  step" [ngClass]="{'last' : last }">
            <span class="" [class.active]="step.active" [class.valid]="step.valid">{{step.name}}</span>
          </li>
        </ul>
      </div>

      <div id="wizard-1" *ngFor="let step of steps" [hidden]="!step.active"    class="wizard_details">
        <div class="container">
          <div class="main-banner">
              <div class="overviewsshow check_support mt-4">
                  <div class="row"  *ngIf="step.name != 'Done'">
                    <div class="col-sm-12">
                        <div class="header">
                            <h1 class="headprofile mt-0 mb-0">Add Referee Details</h1>
                        </div>
                        <hr/>
                    </div>
                  </div>

                  <ng-container *ngIf="step.name=='Reference Request'">
                    <div *ngIf="referencerequestinfo && referencecompanyinfo && refereedetailinfo" class="row">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <h4>Hi {{referencerequestinfo.candidatename}},</h4>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <p>{{referencecompanyinfo.companyname}} has requested for your most recent
                          {{referencerequestinfo.numberofreferee}} <ng-container *ngIf="referencerequestinfo.numberofreferee >1"> references </ng-container> <ng-container *ngIf="referencerequestinfo.numberofreferee ==1"> reference </ng-container> via Complygate.</p>
                      </div>
                      <div class="margin col-xl-12 col-lg-12 col-md-12 col-sm-12 bottom-30">
                        <div class="card mt-10">
                          <div class="card-header text-dark font-weight-bold">
                            Message from {{referencecompanyinfo.companyname}}
                          </div>
                          <div class="card-body" style="min-height: 100px; height:100px; padding:10px 10px; overflow: scroll;" >
                            <p class="card-text text-black">{{referencerequestinfo.candidatemessage}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row " *ngIf="refereedetailinfo?.length > 0" style="margin-top: 50px;">
                      <div class="col-lg-12">
                        <h5  class="hedingcolor">Referee Response Status</h5>
                        <div class="widget-body card-responsive row">
                          <div class="job_list_section  white_box_shadow pro_licence_details admin_pro_footer col-lg-12" *ngFor="let refereeinfo of refereedetailinfo;let i = index">
                            <div class="pro_footer_content">
                              <label>Name</label>
                              <p class="link">
                                <i title="Awaiting Reference" *ngIf="refereeinfo.isresponded==null"
                                  class="fa fa-clock-o"></i>
                                <i title="Reference Received" *ngIf="refereeinfo.isresponded==1"
                                  class="fa fa-check"></i>{{refereeinfo.refereename}}
                              </p>
                            </div>
                            <div class="pro_footer_content">
                              <label>Email</label>
                              <p> {{refereeinfo.refereeemail}} </p>
                            </div>
                            <div class="pro_footer_content">
                              <label>Company</label>
                              <p>{{ refereeinfo.refereecompanyname }}</p>
                            </div>
                            <div class="pro_footer_content">
                              <label>Job Title</label>
                              <p>{{ refereeinfo.candidatejobtitle }}</p>
                            </div>
                            <div class="pro_footer_content">
                              <label><i class="fa fa-calendar" aria-hidden="true"></i>  Job Start Date</label>
                              <p>{{ refereeinfo.jobstartdate | date }}</p>
                            </div>
                            <div class="pro_footer_content">
                              <label><i class="fa fa-calendar" aria-hidden="true"></i>  Job End Date</label>
                              <p>{{ refereeinfo.jobenddate | date }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" *ngIf="steps[0].active && intrefereefilleddetailcount<=refereefilleddetailcount">
                      <div class="col-lg-12">
                        <div class="form-group noticereg">
                           <input type="checkbox" [(ngModel)]="btndisable" (change)="consert($event)">    I consent to the referees I provide being contacted to obtain my employment/personal references and I accept the
                          <a href="https://www.complygate.co.uk/assets/pdf/Conditions_of_Use.pdf" target="_blank"> Terms and Conditions.</a>
                     Please read our
                          <a href="https://www.complygate.co.uk/assets/pdf/client_privacy_policy2.pdf" target="_blank">Privacy Policy.</a>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="step.name=='Referee Submission'">
                    <form  [formGroup]="refereesFormGroup" class="text-center">
                      <div class="text-center mb-2">
                        <label class="text-dark" for="exampleSelect1"> Please enter the details for
                          <b> Referee #{{intrefereefilleddetailcount}}</b>
                        </label>
                      </div>
                      <div class="row bottom-30 text-left">
                        <div class="col-md-3"></div>
                          <div class="col-md-12">
                            <div class="">
                              <div class="row">
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleSelect1">This Referee is/was my:<span class="text-danger">*</span></label>
                                    <select class="form-control" formControlName="refereedesignation" id="exampleSelect1" [ngClass]="{
                                      'is-invalid': submit && refree.refereedesignation.errors
                                    }">
                                    <option value="">Select Referee Role</option>
                                      <option *ngFor="let designation of getdesignations;let i = index" [value]="designation.designationname">
                                        {{designation.designationname}}</option>
                                    </select>
                                    <div *ngIf="submit && refree.refereedesignation.errors" class="invalid-feedback">
                                      <span class="help-block text-danger">
                                        Please enter referee designation
                                      </span>
                                    </div>
                                  </div>
                                  </div> <div class="col-sm-6">

                                  <div class="form-group">
                                    <label for="exampleSelect1">Name:<span class="text-danger">*</span></label>
                                    <input formControlName="refereename" type="text" placeholder="Name and Surname of Referee"
                                      [ngClass]="{'is-invalid': submit && refree.refereename.errors}"
                                      class="form-control" id="input-5">
                                      <div *ngIf="submit && refree.refereename.errors" class="invalid-feedback">
                                        <span class="help-block text-danger">
                                          Please enter referee name
                                        </span>
                                      </div>
                                  </div>
                                </div> <div class="col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleSelect1">Email:<span class="text-danger">*</span></label>
                                    <input (blur)="validateemail()" formControlName="refereeemail" type="email"
                                      [ngClass]="{'is-invalid': submit && refree.refereeemail.errors}"
                                      placeholder="Email of Referee" class="form-control" id="input-5">
                                    <div *ngIf="submit && refree.refereeemail.errors" class="invalid-feedback">
                                      <span class="help-block text-danger">
                                        Please enter referee email
                                      </span>
                                    </div>
                                    <div class="help-block text-danger"
                                      *ngIf="!isvalidemail ||( submit && refree.refereeemail?.dirty && refree.refereeemail?.invalid)">
                                      Please enter valid email
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleSelect1">Phone Number:<span class="text-danger">*</span></label>
                                    <international-phone-number class="form-control phonecontrol"
                                        formControlName="refereephone"
                                        [ngClass]="{ 'is-invalid': submitted && refree.refereephone.errors }"
                                        placeholder="Enter Mobile number"
                                        (input)="validatenumber($event.target.value,'mobile')" [maxlength]="20"
                                        [defaultCountry]="'gb'" #refereephone name="refereephone">
                                    </international-phone-number>
                                    <div *ngIf="submit && refree.refereephone.errors" class="invalid-feedback">
                                      <span class="help-block text-danger">
                                        Please enter referee phone number
                                      </span>
                                    </div>
                                    <!-- <span *ngIf="mobilecheck===false" style="color:red">Invalid format</span>
                                    <span *ngIf="mobilecheck===true" style="color:green">Valid format</span> -->

                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleSelect1">Company Name:<span class="text-danger">*</span></label>
                                    <input formControlName="refereecompanyname" type="text"
                                      placeholder="Name of your previous employer*" class="form-control" id="input-5"
                                      [ngClass]="{'is-invalid': submit && refree.refereecompanyname.errors}">
                                    <div *ngIf="submit && refree.refereecompanyname.errors" class="invalid-feedback">
                                      <span class="help-block text-danger">
                                        Please enter referee company name
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label for="exampleSelect1">Job Role of Candidate:<span class="text-danger">*</span></label>
                                    <input formControlName="candidatejobtitle" type="text" placeholder='My job role '
                                      [ngClass]="{'is-invalid': submit && refree.candidatejobtitle.errors}"
                                      class="form-control" id="input-5">
                                    <div *ngIf="submit && refree.candidatejobtitle.errors" class="invalid-feedback">
                                      <span class="help-block text-danger">Please enter your job role</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div class="row">
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label for="input-12 text-left">I started working here:<span
                                        class="text-danger">*</span></label>
                                    <mat-form-field class="datecontrol">
                                      <input matInput [matDatepicker]="picker1" name="jobstartdate" id="jobstartdate" ngClass="{ 'is-invalid': submitted && refree.jobstartdate.errors }" formControlName="jobstartdate" class="form-control" [max]="dateToday">
                                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                      <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="submit && refree.jobstartdate.errors" class="invalid-feedback">
                                      <span class="help-block text-danger">Please select a date</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-6" [hidden]="fnlast">
                                  <div class="form-group mb-1">
                                    <label for="input-12 text-left">I finished working here:</label>
                                    <mat-form-field class="datecontrol">
                                      <input matInput [matDatepicker]="picker2" name="jobenddate" id="jobenddate" [ngClass]="{ 'is-invalid': submitted && f.jobenddate.errors }" formControlName="jobenddate" class="form-control noborder-invalid" [max]="dateToday" [min]="refereesFormGroup.controls.jobstartdate.value" >
                                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                      <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                  </div>
                                  <div class="form-group">
                                    <div *ngIf="submit && refree.jobenddate.errors" class="invalid-feedback">
                                      <span class="help-block text-danger">
                                        Please select a date</span>
                                    </div>
                                    <div class="help-block text-danger" *ngIf="refree.errors">
                                      {{ refree.errors?.dates }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12">
                                  <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                      <input type="checkbox" formControlName="currentjob" class="custom-control-input"
                                        id="checkboxRemember"  (change)="consertemail($event)">
                                      <label class="custom-control-label" for="checkboxRemember">Current</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- <button *ngIf="steps[steps.length-1].active && addconfirmbutton == 'Add'"
                                class="btn addrefereesbtn col-lg-12 col-12 mt-2" (click)="confirm()">{{addconfirmbutton}} &nbsp;<i
                                  class="fa fa-check arrow"></i></button> -->
                              <!-- <button type="button" (click)="addItem()">Add Item</button> -->
                            </div>
                          </div>
                      </div>
                    </form>
                  </ng-container>
                  <ng-container *ngIf="step.name=='Done'">
                    <div class="check_verification">
                      <div class="wizadlineshow">
                          <div class="wizard_details">
                              <div class="container">
                                  <div class="main-banner">
                                      <div class="banner-form">
                                        <div class="row">
                                          <div class="col-sm-12">
                                            <div class="tankushow">
                                              <div class="detailstext">
                                                  <h1 class="tokenfont">Thank you for completing the details.</h1>
                                              </div>
                                              <img alt="shape" src="../../../assets/img/thanks_new.png">
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                    <!-- <div class="personal_form"  >
                      <div class="modal-body"  >
                        <div class="container">
                          <div class="main-banner">
                            <div class=" ">
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="tankushow">
                                    <div class="detailstext">
                                        <h1>Thank you for completing the referee</h1>
                                    </div>
                                    <img alt="shape" src="../../../assets/img/thanks_new.png">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </ng-container>

                  <div *ngIf="referencerequestinfo && referencecompanyinfo" class="text-center">
                    <button *ngIf="steps[0].active && intrefereefilleddetailcount<=refereefilleddetailcount" [disabled]="!btndisable"  class="btn com_btn btn-success small_btn" (click)="next()">Add Referees</button>
                    <button *ngIf="steps[1].active && addconfirmbutton === 'Save Referee'" class="btn com_btn btn-success small_btn"
                      (click)="confirm()">{{addconfirmbutton}} </button>
                    <button *ngIf="addconfirmbutton == 'Done'" class="btn com_btn btn-success small_btn" data-toggle="modal"
                      data-target="#noheader-modal" (click)="confirm()">{{addconfirmbutton}}</button>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>












