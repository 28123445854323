"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var ExpenseService = /** @class */ (function () {
    function ExpenseService(commonService) {
        this.commonService = commonService;
    }
    ExpenseService.prototype.getemployeeexpenselist = function () {
        return this.commonService.formpostOnlyid('/employee/expenselist');
    };
    ExpenseService.prototype.getemployeeexpensereport = function (data) {
        return this.commonService.formpost('/employee/expenselistreport', data);
    };
    ExpenseService.prototype.getemployeeexpensereportrequest = function (data) {
        return this.commonService.formpost('/employee/expenselistreportrequest', data);
    };
    ExpenseService.prototype.getemployeeexpensereportrequestbyadmin = function (data) {
        return this.commonService.formpost('/employee/expenselistreportrequestbyadmin', data);
    };
    ExpenseService.prototype.getexpensetypelist = function (data) {
        return this.commonService.formpost('/expensetype', data);
    };
    ExpenseService.prototype.getprojectlist = function (data) {
        return this.commonService.formpost('/projectlist', data);
    };
    ExpenseService.prototype.expensepaidby = function () {
        return this.commonService.formpostOnlyid('/expensepaidby');
    };
    ExpenseService.prototype.expenseapplyupdate = function (value) {
        return this.commonService.formpost('/employee/expenseapply', value);
    };
    ExpenseService.prototype.getexpensedata = function (value) {
        return this.commonService.formpost('/employee/expensebyid', value);
    };
    ExpenseService.prototype.getexpensedatabydate = function (data) {
        return this.commonService.formpost('/employee/expenselistbydate', data);
    };
    ExpenseService.prototype.deleteexpense = function (value) {
        return this.commonService.formpost('/employee/expensedelete', value);
    };
    ExpenseService.prototype.statusexpense = function (value) {
        return this.commonService.formpost('/employee/expensestatus', value);
    };
    ExpenseService.prototype.getexpenseType = function (data) {
        return this.commonService.formpost('/company/expenselist', data);
    };
    ExpenseService.prototype.settinghourtypeUpdate = function (value) {
        return this.commonService.formpost('/expensetypeupdatebyadmin', value);
    };
    ExpenseService.prototype.getexpenseList = function (data) {
        return this.commonService.formpost('/employee/employeeexpensereport', data);
    };
    ExpenseService.prototype.expensetypeUpdateChangeStatus = function (value) {
        return this.commonService.formpost('/company/expensetypeupdatechangestatus', value);
    };
    ExpenseService.ngInjectableDef = i0.defineInjectable({ factory: function ExpenseService_Factory() { return new ExpenseService(i0.inject(i1.CommonService)); }, token: ExpenseService, providedIn: "root" });
    return ExpenseService;
}());
exports.ExpenseService = ExpenseService;
