import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-upgradepaymentcancel',
  templateUrl: './upgradepaymentcancel.component.html',
  styleUrls: ['./upgradepaymentcancel.component.css']
})
export class UpgradepaymentcancelComponent implements OnInit {


  ctype='';
  constructor(private router: Router) { }

  ngOnInit() {    
    setTimeout(() => {
      this.ctype = localStorage.getItem('ctype');      
    //   if (this.ctype == '0' ) 
    //   {
    //     this.router.navigate(['upgrade-your-membership']);
    //   }
    //  else{
      this.router.navigate(['login']);
    //  }
      // this.router.navigate(['/dashboard']);
  }, 9000);  //5s
  }
}