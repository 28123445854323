"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var AddemployeeComponent = /** @class */ (function () {
    function AddemployeeComponent() {
    }
    AddemployeeComponent.prototype.ngOnInit = function () {
    };
    return AddemployeeComponent;
}());
exports.AddemployeeComponent = AddemployeeComponent;
