"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var plan_service_1 = require("../../services/plan.service");
var payment_service_1 = require("../../services/payment.service");
var plan_model_1 = require("../../models/plan.model");
var modal_service_1 = require("../../_services/modal.service");
var router_1 = require("@angular/router");
var stripe_angular_1 = require("stripe-angular");
var alert_service_1 = require("../../services/alert.service");
var user_service_1 = require("../../services/user.service");
var app_global_1 = require("../../app.global");
var address_model_1 = require("../../models/address/address.model");
var country_service_1 = require("../../services/country.service");
var platform_browser_1 = require("@angular/platform-browser");
var company_service_1 = require("../../services/company.service");
var PlandetailsComponent = /** @class */ (function () {
    function PlandetailsComponent(document, formBuilder, modalService, router, alerts, plan, country, companyService, userService, StripeScriptTag, paymentService, sanitizer) {
        this.document = document;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.router = router;
        this.alerts = alerts;
        this.plan = plan;
        this.country = country;
        this.companyService = companyService;
        this.userService = userService;
        this.StripeScriptTag = StripeScriptTag;
        this.paymentService = paymentService;
        this.sanitizer = sanitizer;
        this.planDetail = new plan_model_1.Plan();
        this.address = new address_model_1.Address();
        this.paymentSubmit = 0;
        this.publishableKey = app_global_1.AppGlobals.API_STRIPE;
        // private publishableKey:string = "pk_live_vE86NSZoPYH0MsJIdsYfQ8L6";
        this.paymentloading = false;
        this.paymentaut = true;
        this.iframepaymentautsrc = '';
    }
    PlandetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.StripeScriptTag.setPublishableKey(this.publishableKey);
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        else {
            this.userService.checkaccesstokenbyid(userid).subscribe(function (data) {
                if (data.status == false) {
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('userid');
                    localStorage.removeItem('Name');
                    localStorage.removeItem('isline');
                    localStorage.removeItem('planid');
                    localStorage.removeItem('ctype');
                    localStorage.removeItem('isemail');
                    localStorage.removeItem('ismobile');
                    localStorage.removeItem('isadmin');
                    localStorage.removeItem('ispermission');
                    localStorage.removeItem('logoname');
                    localStorage.removeItem('struser');
                    localStorage.removeItem('menu');
                    _this.router.navigate(['/login']);
                }
            });
        }
        this.cpnumber = '';
        this.plantype = 0;
        this.planformGroup = this.formBuilder.group({
            coupnsnumber: ['', forms_1.Validators.required]
        });
        this.addpopForm = this.formBuilder.group({
            cardname: ['', forms_1.Validators.required],
            address1: ['', forms_1.Validators.required],
            address2: [''],
            cityname: ['', forms_1.Validators.compose([forms_1.Validators.required,])],
            statename: ['', forms_1.Validators.required],
            pincode: ['', forms_1.Validators.required],
            stateid: [''],
            taxid: [''],
            countryid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])]
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.addresslistDetail = new address_model_1.Address();
        this.addresslistDetail.countryid = "70";
        var isemail = localStorage.getItem('isemail');
        var ismobile = localStorage.getItem('ismobile');
        var struser = localStorage.getItem('struser');
        this.ctype = localStorage.getItem('ctype');
        var planid = localStorage.getItem('planid');
        if (isemail == 'false' && ismobile == 'false') {
            this.router.navigate(['/otp']);
        }
        else if (planid != '0' && this.ctype == '0') {
            this.router.navigate(['dashboard']);
        }
        else if (struser == '' && this.ctype == '1') {
            this.router.navigate(['payment-confirmation']);
        }
        else if (this.ctype == '2' && struser != '') {
            this.router.navigate(['check-dashboard']);
        }
        else if (struser != '' && this.ctype == '3') {
            this.router.navigate(['reference-dashboard']);
        }
        else if (planid != '0') {
            this.router.navigate(['dashboard']);
        }
        else if (struser == '' && this.ctype == '2') {
            this.router.navigate(['payment-confirmation']);
        }
        // var isemail = localStorage.getItem('isemail');
        // var planid = localStorage.getItem('planid');
        // this.ctype = localStorage.getItem('ctype');
        // console.log('plan id', planid);
        // if(!!planid)    
        // {
        //   planid = '0';
        // }
        // if (isemail == null || isemail == '0' || isemail == 'false') {
        //   this.router.navigate(['/otp']);
        // }    
        // else if (planid != null && planid != '0' && this.ctype == '0') {
        //   this.router.navigate(['/dashboard']);
        // }
        // else if (planid != null && planid != '0' && this.ctype == '2') {
        //   console.log('plan id 1', planid);
        //    this.router.navigate(['/check-dashboard']);
        // }
        // else if(this.ctype == '1' )
        // {
        //   console.log('plan id 2', planid);
        //    this.router.navigate(['/check-dashboard']);
        // }
        var type = { "plantype": '0', 'checktype': '0' };
        if (this.ctype == 2) {
            type.checktype = '1';
        }
        this.plan.getplanlist(type).subscribe(function (data) {
            _this.planlist = data;
        });
    };
    Object.defineProperty(PlandetailsComponent.prototype, "g", {
        get: function () { return this.addpopForm.controls; },
        enumerable: true,
        configurable: true
    });
    PlandetailsComponent.prototype.toggle = function () {
    };
    Object.defineProperty(PlandetailsComponent.prototype, "f", {
        get: function () { return this.planformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    PlandetailsComponent.prototype.onSubmit = function (settingexpensetypedata, id) {
        var _this = this;
        this.submitted = true;
        if (this.planformGroup.invalid) {
            return;
        }
        this.plan.checkcounpons(settingexpensetypedata).subscribe(function (data) {
            if (data.success == 'success') {
                if (data.lenght != 0) {
                    _this.totaluser = data.totaluser;
                    _this.totalused = data.totalused;
                    if ((_this.totaluser - _this.totalused) != 0) {
                        _this.message = "Coupons applied successfully";
                        _this.cpnumber = settingexpensetypedata.coupnsnumber;
                        var db = { "couponsnumber": settingexpensetypedata.coupnsnumber, 'plantype': _this.plantype, 'checktype': '0' };
                        if (_this.ctype == 2) {
                            db.checktype = '1';
                        }
                        _this.plan.getplanlistwithcoupons(db).subscribe(function (data4) {
                            _this.planlist = data4;
                            _this.modalService.close(id);
                        });
                    }
                    else {
                        _this.cpnumber = '';
                        _this.message = "Coupons limit expired";
                    }
                }
            }
            else {
                _this.cpnumber = '';
                // const type = { "plantype": this.plantype  };
                var type = { "plantype": '0', 'checktype': '0' };
                if (_this.ctype == 2) {
                    type.checktype = '1';
                }
                _this.plan.getplanlist(type).subscribe(function (data) {
                    _this.planlist = data;
                });
                _this.message = "Invalid coupon details. Please try another coupon.";
                _this.planformGroup.reset();
                _this.submitted = false;
            }
        });
    };
    PlandetailsComponent.prototype.onStripeInvalid = function (error) {
        // console.log('Validation Error', error)
    };
    PlandetailsComponent.prototype.removecoupons = function () {
        var _this = this;
        this.cpnumber = '';
        this.message = '';
        this.planformGroup.reset();
        // const type = { "plantype": this.plantype  };
        var type = { "plantype": '0', 'checktype': '0' };
        if (this.ctype == 2) {
            type.checktype = '1';
        }
        this.plan.getplanlist(type).subscribe(function (data) {
            _this.planlist = data;
        });
        this.modalService.close('custom-coup-1');
    };
    PlandetailsComponent.prototype.purchasepayment = function (formvalue, id) {
        var _this = this;
        this.submitted = true;
        if (this.addpopForm.invalid) {
            return;
        }
        localStorage.setItem('tempplanid', this.planid);
        this.stptoken = '';
        var planupdate = { "planid": this.stripeplanid };
        // console.log('Stripe token', token);
        var data = {
            "token": '',
            "cmonth": '',
            "cyear": '',
            "cardnumber": '',
            "tokenid": '',
            "cardname": '',
            "country": '',
            "cstatus": "1",
            "planid": this.stripeplanid,
            "address1": formvalue.address1,
            "address2": formvalue.address2,
            "cityname": formvalue.cityname,
            "statename": formvalue.statename,
            "countryid": formvalue.countryid,
            "postcode": formvalue.pincode,
            "taxid": formvalue.taxid,
            "cardholdername": formvalue.cardname,
            "coupons": this.cpnumber
        };
        if (this.paymentSubmit == 0) {
            this.paymentService.createCustomer(data).subscribe(function (data) {
                localStorage.setItem('tempplanid', _this.planid);
                _this.document.location.href = data.url;
            });
        }
        this.paymentSubmit = 1;
    };
    PlandetailsComponent.prototype.setStripeToken = function (token, formvalue, id) {
        var _this = this;
        this.submitted = true;
        if (this.addpopForm.invalid) {
            return;
        }
        localStorage.setItem('tempplanid', this.planid);
        this.stptoken = token.id;
        var planupdate = { "planid": this.stripeplanid };
        // console.log('Stripe token', token);
        var data = {
            "token": token.id,
            "cmonth": token.card.exp_month,
            "cyear": token.card.exp_year,
            "cardnumber": token.card.last4,
            "tokenid": token.card.id,
            "cardname": token.card.brand,
            "country": token.card.country,
            "cstatus": "1",
            "planid": this.stripeplanid,
            "address1": formvalue.address1,
            "address2": formvalue.address2,
            "cityname": formvalue.cityname,
            "statename": formvalue.statename,
            "countryid": formvalue.countryid,
            "postcode": formvalue.pincode,
            "taxid": formvalue.taxid,
            "cardholdername": formvalue.cardname,
            "coupons": this.cpnumber
        };
        if (this.paymentSubmit == 0) {
            this.paymentService.createCustomer(data).subscribe(function (data) {
                localStorage.setItem('tempplanid', _this.planid);
                _this.document.location.href = data.url;
                // this.router.navigate([data.url]);
                // console.log(data);
                // if (data.status == "success") {
                //   this.alerts.success("Plan successfully created", true);
                //   localStorage.setItem('planid', this.planid);
                //   localStorage.setItem('struser', data.struser);
                //   localStorage.setItem('isemail', "true");
                //   this.router.navigate(['/successful-payment']);
                //   this.modalService.close(id);
                // }
                // else {
                //   console.log(data.url);
                //   this.iframepaymentautsrc = this.getSafeUrl(data.url);
                //   this.modalService.open('custom-payment-25');
                //   var xy=null;
                //   var refreshId =  setInterval(() => {
                //     var data = { 'invoiceid': data.paymentint };
                //     this.companyService.getpaymentintent(data).subscribe((invoiceData: any) => {
                //       console.log(invoiceData);
                //       console.log(invoiceData.status);
                //       if(invoiceData.status != 'requires_source_action' && invoiceData.status != 'processing')
                //         {
                //           if(invoiceData.status =='succeeded')
                //           {
                //             this.modalService.close('custom-payment-1'); 
                //             this.modalService.close('custom-payment-2'); 
                //             this.paymentSubmit=0;
                //             this.paymentloading = false;
                //             clearInterval(refreshId);
                //           }
                //       }                      
                //     });       
                //   }, 5000);  
                //   this.paymentSubmit = 0;
                //   this.alerts.error("something went wrong "+ data.message, true);
                // }   
            });
        }
        this.paymentSubmit = 1;
    };
    PlandetailsComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    PlandetailsComponent.prototype.selectPlanType = function (event) {
        // console.log(event);
        var _this = this;
        this.plantype = event;
        if (this.cpnumber != '') {
            var dd = { 'coupnsnumber': this.cpnumber };
            this.plan.checkcounpons(dd).subscribe(function (data) {
                if (data.success == 'success') {
                    if (data.lenght != 0) {
                        _this.totaluser = data.totaluser;
                        _this.totalused = data.totalused;
                        if ((_this.totaluser - _this.totalused) != 0) {
                            _this.message = "Coupons applied successfully";
                            // this.cpnumber = settingexpensetypedata.coupnsnumber;
                            var db = { "couponsnumber": _this.cpnumber, 'plantype': _this.plantype };
                            _this.plan.getplanlistwithcoupons(db).subscribe(function (data4) {
                                _this.planlist = data4;
                            });
                        }
                    }
                }
            });
        }
        else {
            var type = { "plantype": event };
            this.plan.getplanlist(type).subscribe(function (data) {
                _this.planlist = data;
            });
        }
    };
    PlandetailsComponent.prototype.onStripeError = function (error) {
        console.error('Stripe error', error);
    };
    PlandetailsComponent.prototype.updateyourplan = function (stripeplanid, id, planid) {
        // console.log(planid);
        this.stripeplanid = stripeplanid;
        this.planid = planid;
        this.modalService.open(id);
    };
    PlandetailsComponent.prototype.openModal = function (id) {
        this.buttoninsert = "Apply Coupons";
        this.modalService.open(id);
    };
    PlandetailsComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    PlandetailsComponent.prototype.logout = function () {
        var _this = this;
        if (confirm("Are you sure you want to sign out?")) {
            this.userService.logout().subscribe(function (data) {
                localStorage.removeItem('userToken');
                localStorage.removeItem('userid');
                localStorage.removeItem('Name');
                localStorage.removeItem('isline');
                localStorage.removeItem('planid');
                localStorage.removeItem('ctype');
                localStorage.removeItem('isemail');
                localStorage.removeItem('ismobile');
                localStorage.removeItem('isadmin');
                localStorage.removeItem('ispermission');
                localStorage.removeItem('logoname');
                localStorage.removeItem('struser');
                localStorage.removeItem('menu');
                _this.router.navigate(['/login']);
            });
        }
    };
    return PlandetailsComponent;
}());
exports.PlandetailsComponent = PlandetailsComponent;
