<!-- <app-header></app-header> -->
<div widget class="card">
  <div class="card-header">
    <span></span>
  </div>
  <div class="card-body widget-body">
    <div class="row bottom-15 ">
      <div class="col-md-12">
        <div>
          <div class="row bottom-15 ">
            <div class="col-md-6 reference ">
              <a ><i class="fa fa-angle-left margin-5"></i>Back to
                timeline</a>
              <h1 class="mt-3">Reference report <span
                  class="text-muted"><b>Ramu</b></span></h1>
              <p><b>Job Title:</b> <span>ui</span></p>
              <div style="display: flex;">

                <p> <b>Start date:</b> 11/2/2021</p>
                <span class="margin-5">|</span>
                <p><b>End date:</b><span> 5/8/2022</span></p>
              </div>
            </div>
            <div class="col-md-6 ">
              <div class="d-inline-block float-right">
                <button type="button"
                  class="btn btn-outline-secondary btn-rounded transition bottom-30">Professional
                  reference</button>
                <div class="row download mt-5">
                  <a style="cursor: pointer;">  <img src="../../../../../assets/img/downlaods.png" class="downloadss">Download reference report</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row tabs-section" style="background-color: rgb(249, 249, 251);">
            <div class="col-lg-12 col-12 bottom-30" style="background-color: rgb(249, 249, 251);">
              <div class="clearfix">
                <ul class="nav nav-tabs top  w-100p">
                  <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#details">Details</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#questions">Questions</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#comments">Comments</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#verification">Verification</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#fraud-detection">Fraud Detection</a>
                  </li>
                </ul>
              </div>
              <div class="tab-content top">
                <div class="tab-pane active" id="details">
                  <h3>Referee Details</h3>
                  <p class="details">Details submitted on 28 June 2021 at 2:49 pm</p>
                  <div class="row mt-3 details-section">
                    <div class="col-md-3 mt-3">
                      <div class="d-inline-block ">
                        <img class="rounded-circle img-fluid" src="assets/img/users/default.jpg"
                          alt="Rounded circle image">
                        <h4 class="mt-1">refsfbgdhg</h4>
                        <p class="muted">ui</p>
                        <p class="muted mb-3">xceedavgf}</p>
                      </div>
                    </div>
                    <div class="col-md-1 separator"></div>
                    <div class="col-lg-4 mt-5 candidate-details">
                      <div>
                        <p class="muted"> Relationship to candidate</p>
                        <p>gfgfgc</p>
                      </div>
                      <div>
                        <p class="muted"> Known candidate for</p>
                        <p>1 year 5 months</p>
                      </div>
                    </div>
                    <div class="col-lg-4 mt-5">
                      <div>
                        <p class="muted">Email</p>
                        <p>Swrddf@gmail.com</p>
                      </div>
                      <div>
                        <p class="muted"> Phone</p>
                        <p>56456456454</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="questions">
                  <h3>Questions</h3>
                  <p class="muted">These are the questions you asked the referee about the candidate.</p>

                  <div class="question-div" style="height: auto;">
                    <ul style="height: auto;">
                      <li
                       class="mb-3">
                        <div >
                          <div>
                            <p><img src="../../../../../assets/img/clocks.png" class="clocks"></p>
                          </div>
                          <div class="question-ans">
                            <p>gddfhgfhg</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                </div>
                <div class="tab-pane" id="comments">
                  <h3>Comments</h3>
                  <p class="muted">
                    Any additional comments left by the referee about a candidate will appear here. These
                    are
                    separate from the questions asked in a question profile and can provide extra
                    information
                    about a candidate.
                  </p>
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 bottom-30 typography">
                      <div
                      >
                        <div >
                          <blockquote class="blockquote">
                            <p class="mb-0"> <i class="fa fa-quote-left"></i>

                              <i class="fa fa-quote-right"></i>
                            </p>
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="verification">
                  <h3>Verification</h3>
                  <p class="muted">
                    We use Yoti and LinkedIn to verify our referees. If a referee has used one of these
                    verification methods, they will appear here. Toggle between the two options.
                  </p>
                  <div class="verification">
                    <div class="row">
                      <!-- [ngClass]="{'active': selectedItem === i}" -->
                      <div class="col-md-2 verify-method">
                        <div role="button" class="button " >
                          <p>Verified by </p>
                          <img src="assets/img/app/linkedIn.PNG" />
                        </div>
                        <div >
                          <p>Verified by </p>
                          <img src="assets/img/app/yoti.PNG" />
                        </div>
                      </div>
                      <div class="col-md-8 mt-5">
                        <div >
                          <p class="muted mt-3">Referee has not verified with LinkedIn</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="fraud-detection">
                  <h3>Fraud Detection</h3>
                  <p class="muted">We use enhanced methods to detect the validity of a reference, however,
                    these
                    are warnings and should be reviewed accordingly.</p>
                  <p class="muted">
                    <b>IP detection</b> can detect the location of a referee, upon reference completion.
                  </p>
                  <p class="muted">
                    <b>Browser session detection</b> can detect same browser of a referee, upon reference
                    completion.
                  </p>
                  <p class="muted">
                    <b>Details amended</b> can detect any discrepancies between the details the candidate
                    provided
                    and
                    the details a referee provided.
                  </p>
                  <div class="row mt-3 details-section">
                    <div class="col-md-2 mb-3 mt-3 mr-5">
                      <div class="d-inline-block text-center ">
                        <img src="assets/img/app/fraud-detection.PNG" class="img-fluid"
                          alt="fraud detection" />
                      </div>
                    </div>
                    <div class="col-md-1 separator"></div>
                    <div class="col-lg-2 mt-3 candidate-details">

                    </div>
                    <div class="col-lg-2 mt-3 candidate-details">

                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>
      </div>
    </div>



  </div>
</div>

