export class Companyadverse {
    companycode: number;
    companyname: string;
    safenumber: string;
    vatnumber: string;
    phoneNo: string;
    housenumber: string;
    Street: string;
    city: string;
    postcode: string;
    companystatus: string;
    previousName: string;
}