"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var VisaService = /** @class */ (function () {
    function VisaService(commonService) {
        this.commonService = commonService;
    }
    VisaService.prototype.getvisalist = function (data) {
        return this.commonService.formpost('/employee/visareport', data);
    };
    VisaService.ngInjectableDef = i0.defineInjectable({ factory: function VisaService_Factory() { return new VisaService(i0.inject(i1.CommonService)); }, token: VisaService, providedIn: "root" });
    return VisaService;
}());
exports.VisaService = VisaService;
