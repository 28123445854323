import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class GraphreportService {

  constructor(private commonService: CommonService) { }

  getdepartmentgraphList(): Observable<any> {
    return this.commonService.formpostOnlyid('/graph/companydepartmentgraphreport');
  }


  getemployeeagetotal(): Observable<any> {
    return this.commonService.formpostOnlyid('/graph/companyagereport');
  }
  

  getleavegraphList(data: any): Observable<any> {
    return this.commonService.formpost('/graph/leavegraphreport', data);
  }

  getemployeeleavegraph(data: any): Observable<any> {
    return this.commonService.formpost('/graph/employeeleavegraphreport', data);
  }

  getexpensegraphList(data: any): Observable<any> {
    return this.commonService.formpost('/graph/expensegraphreport', data);
  }

  monthlyexpensetypereport(data: any): Observable<any> {
    return this.commonService.formpost('/graph/monthlyexpensegraphreport', data);
  }

  gethourgraphList(data: any): Observable<any> {
    return this.commonService.formpost('/graph/timesheetgraphreport', data);
  }

  monthlyhourtypereport(data: any): Observable<any> {
    return this.commonService.formpost('/graph/monthlytimesheetgraphreport', data);
  }

}
