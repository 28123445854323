import { Component, OnInit } from '@angular/core';
import { LeaveService } from '../../services/leave.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../_services/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../services/alert.service';
import { CustomerService } from '../../services/customer.service';
import { Router } from '@angular/router';
import { AppGlobals } from '../../app.global';

@Component({
  selector: 'app-leaveapproval',
  templateUrl: './leaveapproval.component.html',
  styleUrls: ['./leaveapproval.component.css']
})
export class LeaveapprovalComponent implements OnInit {
  data: any [];
  Userid: any;
  month: any;
  year: any;disableBtn:boolean;
  remarkformGroup: FormGroup;
  yearmonth: any;
  classactive: string;
  remarksubmit = false;
  remarksholidayid: any;
  remarksofleave: any;
  employeelist: any;
  employeeid: any;
  isline: any;
  holidayremarks: any;
  record: any;
  hstatus: any;
  childnodes: any;
  notedetails : any;
  monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];
  yearList: any;

  constructor(private dataset: LeaveService, 
    private router: Router,private formBuilder: FormBuilder, private dataservice: LeaveService, private modalService: ModalService,private alerts: AlertService, private customer: CustomerService) { }

  ngOnInit() {
    this.yearList = AppGlobals.YEAR_VALUES;

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }


    this.Userid = localStorage.getItem('userid');
    this.hstatus ='';
    this.month = (new Date()).getMonth()+1;
    this.year = (new Date()).getFullYear();
    this.isline = localStorage.getItem('isline'); 
    this.remarkformGroup = this.formBuilder.group({
      remarksholidayid: ['', Validators.required],
      holidayremark: ['', Validators.required],
      isline: ['']
    });

    this.yearmonth = this.monthNames[this.month -1 ] +' '+ (new Date()).getFullYear();
    this.classactive =((new Date()).getMonth()+1).toString();   
    this.customer.getchildemployee({j:1}).subscribe((data: any) => {
      this.employeelist = data;
    });

    this.customer.getchildemployee({'id': this.Userid}).subscribe((data: any) => {
      this.childnodes = data;
    });
    this.disableBtn = true;
    this.bindalldata();    
  }

  get g() 
  {
    return this.remarkformGroup.controls;
  }

  onChange(newValue) {    
    this.employeeid = newValue;
    this.bindalldata();
  }
  onChangestatus(newValue) {    
    this.hstatus = newValue;
    this.bindalldata();
  }
  bindalldata()
  {
    const data = { 'id': this.Userid, 'month': this.month, 'year': this.year, 'employeeid': this.employeeid, 'hstatus': this.hstatus };
    this.dataset.getemployeeleaveapprovedreport(data).subscribe((data: any) => {
      this.record = data.length;
        this.data = data;
    });
  }

  closemessageModal(id: string) {
    this.modalService.close(id);
    // this.showAllEvents('event-viewer',this.remarksholidayid);   
  }
  remarksmodel(id: number, modelid: string, closemodelid: string) {
    this.remarksubmit = false;
    this.Userid = localStorage.getItem('userid');
    const leaveData = { 'id': id };
    this.remarksholidayid = id;
    this.dataservice.getleaveremarks(leaveData).subscribe((remarksData: any) => {
      this.remarksofleave = remarksData;
      this.holidayremarks ='';
    });
    this.modalService.open(modelid);
    this.modalService.close(closemodelid);
  }

  onSubmitRemark(remark, popid) {    
    this.remarksubmit = true;
    var jj='';
    if (this.remarkformGroup.invalid) {      
      return;
    }   
    this.dataservice.addnewremark(remark.form.value).subscribe((data: any) => {
      jj= remark.form.value.remarksholidayid;
      const leaveData = { 'id': remark.form.value.remarksholidayid };
      this.dataservice.getleaveremarks(leaveData).subscribe((remarksData: any) => {
       // remark.resetForm();
        this.remarksubmit = false;
        this.remarksofleave = remarksData;
        this.remarksholidayid=jj;
      });      
      this.bindalldata();
    });
     this.alerts.success('Note sent successfully', false);
    this.closeModal(popid);
    // this.bindalldatabymonth();

   // this.showAllEvents('event-viewer', this.remarksholidayid);
  }

  onStatus(id: number, status: number) { 
    const deleteData = { 'id': id, 'hstatus': status, 'userid': this.Userid, 'statusdate': new Date() };
    var msg = '';
    if (status == 2) {
      msg = 'Are you sure you want to approve?';
    } else {
      msg = 'Are you sure you want to reject?';
    }
    if (confirm(msg)) {
      this.dataset.statusleave(deleteData).subscribe((data: any) => {
        this.bindalldata();
      });
    }
  }

  onyearSubmit(year) {
    this.year = year;
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ this.year ;
    this.bindalldata();
   // this.modalService.close(id);
  }

  yearpop(id: string){
    this.modalService.open(id);
  }
  openModalnote (data:any,id:any){
    this.notedetails = data;
    this.modalService.open(id);
  }
  closeModal(id: string){
    this.modalService.close(id);
  }

  getDataByMonth(mon)
  {
    this.classactive =mon;
    this.month = mon;
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ this.year ;
    this.bindalldata();
  }

  getDataByYear(yea)
  {
    this.year = yea;
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ this.year ;
    this.bindalldata();   
     // const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    // this.dataset.getemployeeleavereport(data).subscribe((data: any) => {
    //  this.data = data;
    // });
  }
}