import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PlanService } from '../../../services/plan.service';
import { PaymentService } from '../../../services/payment.service';
import { Plan } from '../../../models/plan.model';
import { ModalService } from '../../../_services/modal.service';
import { Router } from '@angular/router';
import { StripeScriptTag } from "stripe-angular";
import { StripeToken } from "stripe-angular";
import { AlertService } from '../../../services/alert.service';
import { UserService } from '../../../services/user.service';
import { CompanyService } from '../../../services/company.service';
import { AppGlobals } from '../../../app.global';
import { Address } from '../../../models/address/address.model';

import { CountryService } from '../../../services/country.service';


@Component({
  selector: 'app-paymentmethod',
  templateUrl: './paymentmethod.component.html',
  styleUrls: ['./paymentmethod.component.css']
})
export class PaymentmethodComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private modalService: ModalService,
    private router: Router,
    private alerts: AlertService,
    public companyService: CompanyService,
    private country: CountryService, 
    public StripeScriptTag: StripeScriptTag, public paymentService: PaymentService) { }

private publishableKey: string =AppGlobals.API_STRIPE;
currentprice:any;
cardlist: any;
companyData:any;
cardvalue: any;
extraData: any;
defaultcard:any;
address = new Address();
invalidError: any;
addresslistDetail: any;
addpopForm: FormGroup;
countrylist:any;

  ngOnInit() {


    this.router.navigate(['check-payment-method']);
    this.StripeScriptTag.setPublishableKey(this.publishableKey);

    
    this.addpopForm = this.formBuilder.group({ 
      cardname: ['', Validators.required],   
      address1: ['', Validators.required],
      address2: [''],
      cityname: ['', Validators.compose([Validators.required,])],   
      statename: ['', Validators.required],
      pincode: ['', Validators.required],     
      stateid: [''], 
      taxid: [''],       
      countryid: ['', Validators.compose([Validators.required, Validators.min(1)])]
    });


    this.addresslistDetail = new Address();
    this.addresslistDetail.countryid = "70";

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.companyService.getcompanyData().subscribe((data: any) => {
      this.currentprice = data.plan.prices;
      this.companyData = data;
      // console.log(this.companyData);
    });
    
    this.companyService.gettotalcards().subscribe((data: any) => { 

      this.defaultcard = data.customer.default_source;          
      this.cardlist =data.card;
      // this.cardlist = data;



     });
  
  }

  
  get g() { return this.addpopForm.controls; }
  removeCard(id:any)
  {
    this.cardvalue = {"id": id} ;
//  console.log(id);
    this.paymentService.removeCard({ "id": id }).subscribe((data: any) => {
      // console.log(data);
      this.companyService.gettotalcards().subscribe((data: any) => {
        
          this.cardlist = data;
      })
        // this.cardlist = data.data;
    })
  }


  setStripeToken(token: StripeToken,formvalue:any, id: any) {
    var data = {      
      "id": token.id,
      "customer": this.companyData.stuser,
      "cmonth": token.card.exp_month,
      "cyear": token.card.exp_year,
      "cardnumber": token.card.last4,
      "tokenid": token.card.id,
      "cardname": token.card.brand,
      "country": token.card.country,
      "cstatus": "1", 
      "cardholdername": formvalue.cardname,     
      "address1": formvalue.address1,
      "address2": formvalue.address2,
      "cityname": formvalue.cityname,
      "statename": formvalue.statename,
      "countryid": formvalue.countryid,
      "postcode": formvalue.pincode,
      "taxid": formvalue.taxid
    };

    // console.log(data);
    this.modalService.close(id);
    this.paymentService.addnewCard(data).subscribe((data: any) => {
      if (data.success == "success") {
        this.paymentService.getTotalCards().subscribe((data: any) => {
          this.alerts.success("Card has been successfully added.", true);
          this.cardlist = data;
        });

      }
    });
   // console.log(token.card);
  }

  
  onStripeError(error: Error) {
    console.error('Stripe error', error)
  }


  closeModal(id: string) {
    this.modalService.close(id);
  }

  updateyourplan(id: string) {
    this.modalService.open(id);
  }
}
