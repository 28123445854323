"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./checkcountshow.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./checkcountshow.component");
var i4 = require("@angular/router");
var i5 = require("../../services/checksystem.service");
var styles_CheckcountshowComponent = [i0.styles];
var RenderType_CheckcountshowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckcountshowComponent, data: {} });
exports.RenderType_CheckcountshowComponent = RenderType_CheckcountshowComponent;
function View_CheckcountshowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn com_btn btn-primary no-cursor"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Total check "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "badge badge-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkcount.totalcheck; _ck(_v, 3, 0, currVal_0); }); }
function View_CheckcountshowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn com_btn btn-danger no-cursor"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Used check"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "badge badge-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkcount.usedcheck; _ck(_v, 3, 0, currVal_0); }); }
function View_CheckcountshowComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn com_btn btn-dark no-cursor"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Remaining check "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "badge badge-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkcount.remiancheck; _ck(_v, 3, 0, currVal_0); }); }
function View_CheckcountshowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "checkshow_btn"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckcountshowComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckcountshowComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckcountshowComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkcount; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.checkcount; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.checkcount; _ck(_v, 6, 0, currVal_2); }, null); }
exports.View_CheckcountshowComponent_0 = View_CheckcountshowComponent_0;
function View_CheckcountshowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-checkcountshow", [], null, null, null, View_CheckcountshowComponent_0, RenderType_CheckcountshowComponent)), i1.ɵdid(1, 114688, null, 0, i3.CheckcountshowComponent, [i4.Router, i5.ChecksystemService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CheckcountshowComponent_Host_0 = View_CheckcountshowComponent_Host_0;
var CheckcountshowComponentNgFactory = i1.ɵccf("app-checkcountshow", i3.CheckcountshowComponent, View_CheckcountshowComponent_Host_0, {}, {}, []);
exports.CheckcountshowComponentNgFactory = CheckcountshowComponentNgFactory;
