<div class="bg">
    <app-header></app-header>
  
  
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <!-- <a [routerLink]="['/request/request-list']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Request List</a> -->
                <div class="clearfix"></div>
                <div class="tabs__content">
  
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row row-sm topheader">                            
                                <div class="col-lg-5 text-right">
                                  <app-checkcountshow></app-checkcountshow>
                                  <!-- <div class="aligncenter justify-content-start width">
                                    <button type="submit" class="btn btn-primary com_btn width35">Visa</button>
                                    <button type="submit" class="btn btn-primary ml-2 mr-2 com_btn width35">Passport</button>
                                    <button type="submit" class="btn btn-danger com_btn width35">Driving Licence</button>
                                  </div>                                   -->
                                </div>
                                <div class="col-lg-7 text-right">
                                  <div class="dflexflex mb-mm justify-content-end align-items-center">
                                    <a class="boxbtn mainheading_btn ml-1"  (click)="uploadByAdmin('')" > + Add New Applicant </a>                                   
                                    <button class="btn com_btn btn-success"[routerLink]="['/check-employee-list/document']"  type="button">
                                      <span class="lnr lnr-history pr-1">                                        
                                      </span> Back to Applicant List 
                                  </button>
                                
  
                                  </div>                             
                              </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 aligncenter mb-3">
                                   
                                      <h5 class="hedingcolor">Driving Licence Verification History</h5>
                                    <input type="text" [(ngModel)]="searchText" class="form-control width35" placeholder="Search">
                                </div>                               
                            </div>
                            <div class="browser-screen-loading-content" *ngIf="!pageOfItems" >
                                <div class="loading-dots dark-gray">
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>                                
                                    <i></i>
                                </div>
                                <p>Loading</p>
                            </div>
                            <ng-container *ngIf="( datalist | filter: {idnumber: searchText,docexpiry: searchText}) as pageOfItems"> 
                                <div class="job_list_section idproof_request_section visa_request_section  pb-2 pt-2 oddeven" *ngFor="let idproofs of pageOfItems; let i=index ;">
                                    <!-- <div class="job_detils_date ">
                                        <div class="visa_user_pic">
                                            <img src="{{employee.imagename}}" class="img width100">
                                            <img *ngIf="idproofs.employee.imagename" src="{{idproofs.employee.imagename}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                            <img *ngIf="idproofs.employee.gender == true && (idproofs.employee.imagename == null || idproofs.employee.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                            <img *ngIf="idproofs.employee.gender == false && (idproofs.employee.imagename == null || idproofs.employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                        </div>
                                    </div> -->
                                    <div class="job_detils_date width18">
                                        <div class="visa_user_details">
                                            <p class="job_address">Name</p>
                                            <h4>{{idproofs.employee.fname}} {{idproofs.employee.mname}} {{idproofs.employee.lname}}</h4>
                                            <!-- <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"></i> {{ idproofs.employee.emailid }}</p> -->
                                            <!-- <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i> {{ idproofs.employee.phonenumber }}</p> -->
                                            <!-- <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i> NI Number {{ idproofs.employee.ninumber }} </p> -->
                                            <!-- <span *ngIf="idproofs.employee.nationality" class="badge badge-success">{{ idproofs.employee.nationality.countryname }} </span> -->
                                        </div>
                                    </div>
                                    <div class="job_detils_date width10">
                                        <p class="job_address">Phone Number</p>
                                        <p class="job_profile">  {{ idproofs.employee.phonenumber }}</p>
                                    </div>
                                    <div class="job_detils_date width10">
                                        <p class="job_address">ID Proof Number</p>
                                        <p class="job_profile">{{idproofs.idnumber}}</p>
                                    </div>
                                    <div class="job_detils_date width10">
                                        <p class="job_address">ID Type</p>
                                        <p class="job_profile">{{idproofs.documenttype.documentname}}</p>
                                    </div>
                                    <div class="job_detils_date width10">
                                        <p class="job_address">End Date</p>
                                        <p class="job_profile">{{idproofs.docexpiry | date: 'dd MMM yyyy'}}</p>
                                    </div>
                                    <div class="job_detils_date width10">
                                        <p class="job_address">Check Date</p>
                                        <p class="job_profile">{{idproofs.createat | date: 'dd MMM yyyy'}}</p>
                                    </div>
                                    <div class="job_detils_date width10">
                                        <button type="button" class="btn btn-primary com_btn" [innerHtml]="downloaddltext[i]" (click)="downloadDrivingLicence(idproofs.employeeidproofdeatilid, i)"></button>
                                    </div>
                                    <div class="job_detils_date aligncenter width15 verify_img">
                                        <!-- <div class="visa_user_pic front">
                                          
                                            <img *ngIf="idproofs.employee.imagename" src="{{idproofs.employee.imagename}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                           
                                        </div> -->
                                        <div class="fshow">
                                            <div class="visa_user_pic back">
                                                <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                                <a (click)="openModal3('pdf-viewer-idproof',idproofs.iddocName)">  <img *ngIf="idproofs.iddocName" src="{{idproofs.iddocName}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}"></a>
                                                <img *ngIf="!idproofs.iddocName" src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                            </div>
                                            <div class="tooltip_visa back"><p>Front image</p></div>
                                        </div>
                                        <div class="fshow">
                                            <div class="visa_user_pic selfie">
                                                <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                                <a (click)="openModal3('pdf-viewer-idproof',idproofs.idbackdocName)">   <img *ngIf="idproofs.idbackdocName" src="{{idproofs.idbackdocName}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}"></a>
                                                <img *ngIf="!idproofs.idbackdocName" src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                            </div>
                                            <div class="tooltip_visa selfie"><p>Back image</p></div>
                                        </div>
                                        <div class="fshow">
                                            <div class="visa_user_pic selfie">
                                                <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                                <a (click)="openModal3('pdf-viewer-idproof',idproofs.idprofileimage)">  <img *ngIf="idproofs.idprofileimage" src="{{idproofs.idprofileimage}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}"></a>
                                                <img *ngIf="!idproofs.idprofileimage" src="../../../../../assets/img/icon/no_img.jpg" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                            </div>
                                            <div class="tooltip_visa selfie"><p>Selfie</p></div>
                                        </div>
                                        </div>
    
                                    <div class="width100 aligncenter">
                                        <div class="pull-left">                                 
                                            <p class="badge badge-warning mb-0" *ngIf="idproofs.isaiverify == '1'  "><i class="fa fa-hourglass-end"></i> Awaiting Authorisation </p>
                                            <p class="badge badge-success mb-0" *ngIf="idproofs.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                            <p class="badge badge-error mb-0" *ngIf="idproofs.isaiverify == '3'"><i class="fa fa-close"></i> Rejected by AI </p>
                                        </div> 
    <!--                                     
                                        <p  class="job_profile green_text m-0">Approved By AI </p> -->
                                        <!-- <div class="text-right">
                                            <p class="mb-0" *ngIf="aipayment ==false"> Re-verify the ID using Complygate AI. <a [routerLink]="['/idproofaidetails',idproofs.employeeidproofdeatilid]">Click Here</a></p>
                                            <p class="mb-0" *ngIf="aipayment ==true"> You have exhausted the ID verification. To continue using it please <a [routerLink]="['/check-add-credit']">Click Here</a></p>
                                           
                                        </div> -->
                                    </div>
                                  
                                </div>
                                <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                    <div class="job_detils_date">
                                        <p class="job_profile">No Record Found</p>
                                    </div>
                                </div>
                            </ng-container> 
                          
<!--   
                            <div class="job_list_section visa_request_section" *ngIf="record == 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div>
  
  
  
  
  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  
  
  
  <jw-modal id="custom-idproof-1" role="dialog" class="modal">
  
    <form [formGroup]="idproofpopForm" #idproofForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(idproofForm.value,'custom-idproof-1','1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
  
                    <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="idproofDetail.employeeid">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="idproofDetail.employeeidproofdeatilid">
  
                    <h5 class="modal-title" id="popupLabel">ID Proof Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-idproof-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>ID Proof No.*</label>
                                <input type="idnumber" formControlName="idnumber" name="idnumber" id="idnumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.idnumber.errors }" [(ngModel)]="idproofDetail.idnumber">
                                <div *ngIf="submitted && f.idnumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.idnumber.errors.required">Please Enter ID Proof No.</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>ID Proof Type*</label>
                                <select style="width:100%;" formControlName="idtype" name="idtype" id="idtype" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.idtype.errors }" [(ngModel)]="idproofDetail.idtype">
                                    <option [value]="defaultVal">Select ID Proof Type </option>
                                    <option *ngFor="let ad3 of documenttype" [value]="ad3.id">{{ ad3.documentname }}</option>
  
                                    
                                  </select>
                                <div *ngIf="submitted && f.idtype.errors" class="invalid-feedback">
                                    <div *ngIf="f.idtype.errors.required">Please Select ID Proof Type</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
  
                            <div class="form-group col-lg-6">
                                <label>Expiry Date*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker2" id="docexpiry" name="docexpiry" formControlName="docexpiry" [ngClass]="{ 'is-invalid': submitted && f.docexpiry.errors }" class="form-control noborder-invalid datecontrol" [(ngModel)]="idproofDetail.docexpiry">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
  
                                </mat-form-field>
                                <div *ngIf="submitted && f.docexpiry.errors" class="invalid-feedback">
                                    <div *ngIf="f.docexpiry.errors.required">Please Choose Expiry Date</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                              <label>ID Proof Issue Country*</label>
                              <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [(ngModel)]="idproofDetail.nationalityid">
                              <option [value]="defaultVal">Select ID Proof  Issue Country </option>
                              <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                              </select>
                              <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                  <div *ngIf="f.nationalityid.errors.required">Please Select ID Proof Issue Country</div>
                              </div>
                          </div>
                        </div>
                        <div class="row">
                           
                            <div class="col-lg-6">
                              <label>Front Side ID Proof Image Upload*</label>
                              <div class="input-group mb-3" (click)="file.click()">
                                  <div class="custom-file">
                                      <input type="file" #file (change)="selectFile($event)">
                                      <label class="custom-file-label">{{filename}}</label>
                                  </div>
                              </div>
                              <div class="invalid-feedback ng-star-inserted">
                                  <div class="" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                  <div class="" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png)</div>
                              </div>
                          </div>
                          <div class="col-lg-6">
                              <label>Back Side ID Proof Image Upload*</label>
                              <div class="input-group mb-3" (click)="fileback.click()">
                                  <div class="custom-file">
                                      <input type="file" #fileback (change)="selectbackFile($event)">
                                      <label class="custom-file-label">{{filebackname}}</label>
                                  </div>
                              </div>
                              <div class="invalid-feedback ng-star-inserted">
                                  <div class="" *ngIf="filebacksizeerror == true">File size must be less than 2 MB</div>
                                  <div class="" *ngIf="filebacksizeerror == true">Please choose a valid file format (jpg, png)</div>
                              </div>
                          </div>
                        </div>
  
                    </div>
                </div>
                <div class="modal-footer">
  
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(idproofForm.value,'custom-idproof-1', '1')" value="{{buttoninsert}}" />
                    <!-- <input type="submit" class="btn btn-success com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(idproofForm.value,'custom-idproof-1', '2')" value="Update & Approve" />
                    <input type="submit" class="btn btn-danger com_btn" name="updateform" [disabled]="!disableBtn" (click)="onSubmit(idproofForm.value,'custom-idproof-1', '3')" value="Reject" /> -->
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-idproof-1');" />
  
  
  
                </div>
            </div>
        </div>
    </form>
  </jw-modal>
  
  
  
  <jw-modal id="pdf-viewer-idproof" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-viewer-idproof');">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="container">
  
                    <div class="row">
                        <div class="col-lg-12">
  
                            <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="600" frameborder="0"></iframe>
                            <img *ngIf="imgsrc == true" [src]="iframesrc" width="100%" />
                        </div>
                    </div>
  
                </div>
            </div>
  
        </div>
    </div>
  </jw-modal>


  
<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">         
            <div class="modal-body">
                <div class="container text-center">
                  <div class="info_mess">
                      <div class="para">
                              <h4 class="mb-2">No credits available</h4>
                              <p class="mb-0 font20"> There are no credits available to perform this action. Please add more credits.</p>
  
                          </div>                          
                      </div>
                </div>
            </div>
            <div class="modal-footer text-center">
              <input type="button" class="btn btn-primary com_btn" name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
              <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
                <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
            </div>
        </div>
    </div>
  </jw-modal>
  