"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var graphreport_service_1 = require("../../../services/graphreport.service");
var employee_service_1 = require("../../../services/employee.service");
var CanvasJS = require("./../../../admin/canvasjs.min");
var date_fns_1 = require("date-fns");
var app_global_1 = require("../../../app.global");
var LeavechartComponent = /** @class */ (function () {
    function LeavechartComponent(employee, graphReportService) {
        this.employee = employee;
        this.graphReportService = graphReportService;
    }
    LeavechartComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        this.employeemonth = date_fns_1.getMonth(new Date()) + 1;
        this.employeeyear = date_fns_1.getYear(new Date());
        this.hstatus = '1';
        this.empid = '';
        var value3 = "{}";
        this.employee.getemployeelist(value3).subscribe(function (listdetails) {
            _this.employeelist = listdetails;
        });
        var value3 = "{}";
        this.employee.getemployeelist(value3).subscribe(function (listdetails) {
            _this.data = listdetails;
        });
        this.leavetypereport();
        this.malefemalereport();
    };
    LeavechartComponent.prototype.leavetypereport = function () {
        var _this = this;
        this.graphReportService.getemployeeleavegraph({ 'month': this.employeemonth, 'year': this.employeeyear, 'employeeid': this.empid, 'hstatus': this.hstatus }).subscribe(function (employeeleavedata) {
            _this.employeeleavedata = employeeleavedata;
            var graphdataarray = [];
            employeeleavedata.forEach(function (obj, index) {
                graphdataarray.push({ y: obj.count, label: obj.typename });
            });
            _this.getemployeeleavegraph(graphdataarray);
        });
    };
    LeavechartComponent.prototype.malefemalereport = function () {
        var _this = this;
        this.graphReportService.getleavegraphList({ 'month': this.employeemonth, 'year': this.employeeyear, 'employeeid': this.empid, 'hstatus': this.hstatus }).subscribe(function (leavedata) {
            _this.leavedatalist = leavedata;
            // console.log(leavedata);
            var totalgraphdataarray = [];
            var malegraphdataarray = [];
            var femalegraphdataarray = [];
            leavedata.forEach(function (obj, index) {
                totalgraphdataarray.push({ x: index + 1, y: obj.count, label: obj.typename });
                // malegraphdataarray.push({ x: index + 1, y: obj.mcount, label: obj.typename });
                // femalegraphdataarray.push({ x: index + 1, y: obj.fcount, label: obj.typename });
            });
            var data = [
                {
                    type: "stackedBar",
                    name: "Employee",
                    showInLegend: "true",
                    xValueFormatString: "DD, MMM",
                    yValueFormatString: "#0",
                    dataPoints: totalgraphdataarray
                }
                // {
                //   type: "stackedBar",
                //   name: "Male",
                //   showInLegend: "true",
                //   xValueFormatString: "DD, MMM",
                //   yValueFormatString: "#0",
                //   dataPoints: malegraphdataarray
                // },
                // {
                //   type: "stackedBar",
                //   name: "Female",
                //   showInLegend: "true",
                //   xValueFormatString: "DD, MMM",
                //   yValueFormatString: "#0",
                //   dataPoints: femalegraphdataarray
                // }
            ];
            _this.companyleavegraphreport(data);
        });
    };
    LeavechartComponent.prototype.companyleavegraphreport = function (data) {
        var chart = new CanvasJS.Chart("chartContainer2", {
            animationEnabled: true,
            title: {
                text: ""
            },
            axisX: {
                valueFormatString: "DDD"
            },
            axisY: {
                prefix: ""
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                itemclick: toggleDataSeries
            },
            data: data
        });
        chart.render();
        function toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            }
            else {
                e.dataSeries.visible = true;
            }
            chart.render();
        }
    };
    LeavechartComponent.prototype.getemployeeleavegraph = function (data) {
        var chart = new CanvasJS.Chart("chartContainer", {
            animationEnabled: true,
            exportEnabled: true,
            title: {
                text: ""
            },
            data: [{
                    type: "column",
                    dataPoints: data
                }]
        });
        chart.render();
    };
    LeavechartComponent.prototype.changeEmployee = function (id) {
        this.empid = id;
        this.leavetypereport();
        this.malefemalereport();
    };
    LeavechartComponent.prototype.changeEmployeeMonth = function (month) {
        this.employeemonth = month;
        this.leavetypereport();
        this.malefemalereport();
    };
    LeavechartComponent.prototype.changeEmployeeYear = function (year) {
        this.employeeyear = year;
        this.leavetypereport();
        this.malefemalereport();
    };
    LeavechartComponent.prototype.changeEmployeestatus = function (hstatus) {
        this.hstatus = hstatus;
        this.leavetypereport();
        this.malefemalereport();
    };
    return LeavechartComponent;
}());
exports.LeavechartComponent = LeavechartComponent;
