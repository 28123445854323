<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <a [routerLink]="['/report-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Report Dashboard</a>
                <div class="clearfix"></div>
                <div class="tabs__content">
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="row  mg-t-10">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Leave List</h5>
                                </div> 
                                <div class="col-lg-6 d-flex justify-content-end mb-3">
                                  <!-- <button type="submit" (click)="exportAsXLSX();" class="btn btn-primary com_btn">
                                    Download <i class="ml-2 fa fa-file-excel-o"></i>
                                  </button> -->
                                  <a (click)="showcondition('list');"  class="faright toggle_button"><i class="fa fa-list"></i>
                                    <span  class="tooltip_show">List View</span>
                                  </a>
                                  <a (click)="showcondition('graph');" class="faright toggle_button"><i class="fa fa-bar-chart"></i>
                                    <span  class="tooltip_show">Graph View</span>
                                  </a>
                                </div>
                                <!-- <div class="col-lg-6">
                                    <a (click)="exportAsXLSX();" class="float-right faright"><i class="fa fa-file-excel-o"></i></a>
                                    <a (click)="showcondition('list');" class="float-right faright"><i class="fa fa-list mg-b-10"></i></a>
                                    <a (click)="showcondition('graph');" class="float-right faright"><i class="fa fa-bar-chart mg-b-10"></i></a>
                                </div> -->
                            </div>
                            <div [style.display]="listdisplay">
                                <div class="row topheader d-flex justify-content-end">
                                  <div class="col-sm-12">
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <select name="hstatus" class="form-control input-lg" (change)="changeEmployeestatus($event.target.value)">
                                          <option value="">Select Status</option>
                                          <option value="1">Pending</option>
                                          <option value="2">Approved</option>
                                          <option value="3">Rejected</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3">
                                      <select name="employee" #employid (change)="changeEmployee($event.target.value)"
                                        class="form-control input-lg">
                                        <option value="">Select Employee</option>
                                        <option *ngFor="let emp of employeelist" value="{{emp.employeemasterid}}"
                                          [selected]="emp.employeemasterid==empid">
                                          {{ emp.fname }} {{ emp.mname }} {{ emp.lname }} ( {{ emp.employeeid }})</option>
                                      </select>
                                    </div>
                                    
                                    <div class="col-lg-3">
                                   <select name="empmonth" #empmonth (change)="changeEmployeeMonth($event.target.value)" class="form-control input-lg">
                                        <option value="">Select Month</option>
                                        <option value="1" [selected]="employeemonth=='1'">January</option>
                                        <option value="2" [selected]="employeemonth=='2'">February</option>
                                        <option value="3" [selected]="employeemonth=='3'">March</option>
                                        <option value="4" [selected]="employeemonth=='4'">April</option>
                                        <option value="5" [selected]="employeemonth=='5'">May</option>
                                        <option value="6" [selected]="employeemonth=='6'">June</option>
                                        <option value="7" [selected]="employeemonth=='7'">July</option>
                                        <option value="8" [selected]="employeemonth=='8'">August</option>
                                        <option value="9" [selected]="employeemonth=='9'">September</option>
                                        <option value="10" [selected]="employeemonth=='10'">October</option>
                                        <option value="11" [selected]="employeemonth=='11'">November</option>
                                        <option value="12" [selected]="employeemonth=='12'">December</option>       
                                      </select>
                                    </div>
                                    <div class="col-lg-3">
                                        <select name="empyear" #empyear (change)="changeEmployeeYear($event.target.value)" [value]="employeeyear" class="form-control input-lg">
                                          <option value="{{y}}" *ngFor="let y of yearList">{{ y }}</option>
                                        </select>
                                    </div>
                                    </div>
                                  </div>                                
                                  <div class="clearfix"></div>                                   
                                </div>
                                <!-- <form [formGroup]="EmployeeForm" #employeeForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(employeeForm.value)">
      <div class="row row-sm topheader">
        <div class="col-lg-6">
          &nbsp;
        </div>
        <div class="col-lg-3">
          <select name="hstatus" formControlName="hstatus" class="form-control input-lg" id="hstatus">
            <option value="">Select Status</option>
            <option value="1">Pending</option>
            <option value="2">Approved</option>
            <option value="3">Rejected</option>
          </select>
        </div>
        <div class="col-lg-3">
          <input [(ngModel)]="searchText" formControlName="searctext" placeholder="Search.." class="form-control">
        </div>
      </div> 
    </form>-->

                                <!-- <app-topmenu class="row"></app-topmenu> -->
                                <!-- <div class="row row-sm">
    <div class="col-lg-12">

      <form [formGroup]="EmployeeForm" #employeeForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(employeeForm.value)">
        <div class="row row-sm">
          <div class="form-group col-lg-2">
            <label>Eamil</label>
            <input type="input" formControlName="emailid" class="" name="emailid" id="fname" class="form-control" />
          </div>
          <div class="form-group col-lg-2">
            <label>Phone Number</label>
            <input type="input" formControlName="phonenumber" class="" name="phonenumber" id="phonenumber" class="form-control" />
          </div>
          <div class="form-group col-lg-2">
            <label>NI Number</label>
            <input type="input" formControlName="ninumber" class="" name="ninumber" id="ninumber" class="form-control" />
          </div>
          <div class="form-group col-lg-2">
            <label>Title</label>
            <input type="input" formControlName="title" class="" name="title" id="title" class="form-control" />
          </div>
          <div class="form-group col-lg-2">
            <label>Min Start Date *</label>
            <mat-form-field class="datecontrol">
              <input matInput [matDatepicker]="picker2" id="minstartdate" formControlName="minstartdate" name="minstartdate" />
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group col-lg-2">
            <label>Max Start Date *</label>
            <mat-form-field class="datecontrol">
              <input matInput [matDatepicker]="picker3" id="maxstartdate" formControlName="maxstartdate" name="maxstartdate" />
              <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-datepicker #picker3></mat-datepicker>
            </mat-form-field>
          </div>

        </div>
        <div class="row row-sm">
          <div class="form-group col-lg-2">
            <label>Min End Date *</label>
            <mat-form-field class="datecontrol">
              <input matInput [matDatepicker]="picker4" id="minenddate" formControlName="minenddate" name="minenddate" />
              <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
              <mat-datepicker #picker4></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group col-lg-2">
            <label>Max End Date *</label>
            <mat-form-field class="datecontrol">
              <input matInput [matDatepicker]="picker5" id="maxenddate" formControlName="maxenddate" name="maxenddate" />
              <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
              <mat-datepicker #picker5></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group col-lg-2">
            <label>First Name</label>
            <input type="input" formControlName="fname" class="" name="fname" id="fname" class="form-control" />
          </div>
          <div class="form-group col-lg-2">
            <label>Status</label>
            <select name="hstatus" formControlName="hstatus" class="form-control input-lg" id="hstatus">
              <option value="">Select Status</option>
              <option value="1">Pending</option>
              <option value="2">Approved</option>
              <option value="3">Rejected</option>
            </select>
          </div>

        </div>
        <div class="row row-sm">
          <div class="form-group col-lg-2">
            <input type="submit" class="btn btn-primary" name="updateform" value="Search" />
          </div>
        </div>
      </form>
    </div>
  </div> -->

                                <div class="job_list_section idproof_request_section visa_request_section" *ngFor="let leave of data;  let i=index">
                                    <div class="job_detils_date">
                                        <div class="visa_user_pic">
                                            <img *ngIf="leave.imagename" src="{{leave.imagename}}" class="img width100" alt="{{ leave.fname }} {{ leave.mname }} {{ leave.lname}}">
                                            <img *ngIf="leave.gender == true && (leave.imagename == null || leave.imagename =='') " src="../../../../../assets/img/user_pic_leave.png" class="img width100" alt="{{ leave.fname }} {{ leave.mname }} {{ leave.lname}}">
                                            <img *ngIf="leave.gender == false && (leave.imagename == null || leave.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ leave.fname }} {{ leave.mname }} {{ leave.lname}}">
                                        </div>
                                    </div>
                                    <div class="job_detils_date width20">
                                        <div class="visa_user_details">
                                            <h4> {{leave.fname}} {{leave.mname}} {{leave.lname}}</h4>
                                            <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"></i> {{ leave.emailid }}
                                            </p>
                                            <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i>{{ leave.phonenumber }}</p>
                                            <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i>NI number {{ leave.ninumber }} </p>
                                            <span *ngIf="leave.nationality" class="badge badge-success">{{ leave.nationality.countryname }}</span>
                                        </div>
                                    </div>
                                    <div class="job_detils_date width10">
                                        <p class="job_address">Total Leaves</p>
                                        <p class="job_profile"> {{leave.totalleave}}</p>
                                    </div>
                                    <!-- <div class="job_detils_date width22">

                                        <div class="job_profile" *ngIf="leave.remarkdetails.length > 0">
                                            <div class="dsfsadf">
                                                <p *ngFor="let rd of leave.remarkdetails "><b>Note :</b> {{ rd.holidayremark }}</p>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="job_detils_date width10 ">
                                        <p class="job_address ">Pending Leaves</p>
                                        <p class="job_profile "> {{ leave.pendingleave}} </p>
                                    </div>
                                    <div class="job_detils_date width10 ">
                                        <p class="job_address ">Approved Leaves</p>
                                        <p class="job_profile "> {{leave.approvedleave}}</p>
                                    </div>
                                    <div class="job_detils_date width10 ">
                                        <p class="job_address ">Rejected Leaves</p>
                                        <p class="job_profile ">{{leave.rejectleave}}</p>
                                    </div>
                                    <div class="job_detils_date width7">
                                        <p class="job_address ">
                                            <a [routerLink]="['/viewleaves/'+leave.employeemasterid]" target="_blank" [queryParams]="{ status: hstatus, year: employeeyear, month: employeemonth }" class="btn btn-info com_btn"> 
                                              <i class="fa fa-eye"></i> View 
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-lg-4 text-left " *ngIf="record==0 ">
                                        <div class="billed-to ">No Record Found </div>
                                    </div>
                                    <div class="col-lg-12 text-right ">
                                        <jw-pagination [items]="items " [pageSize]="30 " [maxPages]="10 " (changePage)="onChangePage($event) "></jw-pagination>
                                    </div>
                                </div>
                            </div>
                            <div class="row " [style.display]="graphdisplay ">
                                <div class="col-lg-12 ">
                                    <app-leavechart></app-leavechart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<jw-modal id="note-leave-report" class="modal " role="dialog ">
    <div role="document" class="formgroup ">
        <div class="modal-content ">
            <div class="modal-header ">
                <h5 class="modal-title ">Notes</h5>
                <button type="button " class="close " data-dismiss="modal " name="close " aria-label="Close " (click)="closeModal( 'note-leave-report'); ">
                  <span aria-hidden="true ">&times;</span>
                  </button>
            </div>
            <div class="modal-body ">
                <div class="container ">
                    <div class="job_profile">
                        <div class="">
                            <p *ngFor="let rd of notedetails "><b>Note :</b> {{ rd.holidayremark }}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer ">
                <button type="button " class="btn btn-secondary com_btn " data-dismiss="modal " (click)="closeModal( 'note-leave-report');">Close</button>
            </div>
        </div>
    </div>
</jw-modal>