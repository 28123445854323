"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var user_service_1 = require("../../services/user.service");
var forms_1 = require("@angular/forms");
var user_model_1 = require("../../models/user.model");
var alert_service_1 = require("../../services/alert.service");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var ForgotRequestpasswordComponent = /** @class */ (function () {
    function ForgotRequestpasswordComponent(formBuilder, userService, router, alerts) {
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.router = router;
        this.alerts = alerts;
        this.submitted = false;
        this.isLoginError = false;
        this.msg = null;
        this.user = new user_model_1.User();
    }
    ForgotRequestpasswordComponent.prototype.ngOnInit = function () {
        this.registerForm = this.formBuilder.group({
            username: ['', [forms_1.Validators.required]]
        });
    };
    Object.defineProperty(ForgotRequestpasswordComponent.prototype, "f", {
        get: function () { return this.registerForm.controls; },
        enumerable: true,
        configurable: true
    });
    ForgotRequestpasswordComponent.prototype.forgotRequest = function (email) {
        var _this = this;
        console.log('user name - ', email);
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        // tslint:disable-next-line:max-line-length
        this.userService.forgotRequest(email).subscribe(function (data4) {
            if (data4.success == 'success') {
                _this.alerts.success('Request successful', true);
                _this.router.navigate(['/login']);
            }
            else {
                _this.isLoginError = true;
                _this.alerts.error('This username is not registered with us', true);
                _this.msg = '';
                _this.error = ' This username is not registered with us';
            }
            //   this.router.navigate(['forgotRequest']);
            // this.msg = 'The mail has been successfully sent';
            _this.error = '';
        }, function (err) {
            _this.isLoginError = true;
            _this.alerts.error('This email is not registered with us', true);
            _this.msg = '';
            _this.error = ' This email is not registered with us';
        });
    };
    return ForgotRequestpasswordComponent;
}());
exports.ForgotRequestpasswordComponent = ForgotRequestpasswordComponent;
