import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../_services/modal.service'; 
  import { StripeScriptTag } from "stripe-angular";
import { StripeToken } from "stripe-angular";
import { CheckserviceService} from '../../services/checkservice.service';
import { AppGlobals } from '../../app.global';
import { CompanyService } from '../../services/company.service';

@Component({
  selector: 'app-checkpaynow',
  templateUrl: './checkpaynow.component.html',
  styleUrls: ['./checkpaynow.component.css']
})
export class CheckpaynowComponent implements OnInit {

  private publishableKey: string = AppGlobals.API_STRIPE;
  paynow = false;
  stptoken: any;
  paymentDone: any = false;
  invalidError:any;
  extraData:any;
  
  constructor(
    private modalService: ModalService,
    public StripeScriptTag: StripeScriptTag,
    private companyService: CompanyService,
    private checkService: CheckserviceService
  ) { }

  ngOnInit() {
    this.StripeScriptTag.setPublishableKey(this.publishableKey);
    this.companyService.getcompanyData().subscribe((data: any) => {
      if(data.stuser)
      {
        this.paymentDone = true;
      }
    });
  }

  setStripeToken(token: StripeToken, id: any) {
    this.paynow = true;
    this.stptoken = token.id;
    var amunt =parseFloat('2');
    var data = {
      "token": token.id,
      "cmonth": token.card.exp_month,
      "cyear": token.card.exp_year,
      "cardnumber": token.card.last4,
      "tokenid": token.card.id,
      "cardname": token.card.brand,
      "country": token.card.country,
      "cstatus": "1",
      "amount": amunt
    };

    this.checkService.registereduserpayment(data).subscribe((payment3: any) => {
      if(payment3.success == true)
      {
        this.closeModal('custom-payment-1');
      }
      
    });
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  paymentstripe(id){
    this.paynow = false;
    this.modalService.open(id);
  }

  onStripeError(error: Error) {
    console.error('Stripe error', error)
  }


}
