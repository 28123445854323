"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var country_service_1 = require("../../../services/country.service");
var myvisa_service_1 = require("../../../services/myvisa.service");
var modal_service_1 = require("../../../_services/modal.service");
var forms_1 = require("@angular/forms");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var alert_service_1 = require("../../../services/alert.service");
var aicenter_service_1 = require("../../../services/aicenter.service");
var myidproof_service_1 = require("../../../services/myidproof.service");
var mypassport_service_1 = require("../../../services/mypassport.service");
var employee_service_1 = require("../../../services/employee.service");
var AilogComponent = /** @class */ (function () {
    function AilogComponent(sanitizer, formBuilder, uploadService, visaService, idproofService, country, modalService, aicenter, passdata, router, employee, alerts) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.visaService = visaService;
        this.idproofService = idproofService;
        this.country = country;
        this.modalService = modalService;
        this.aicenter = aicenter;
        this.passdata = passdata;
        this.router = router;
        this.employee = employee;
        this.alerts = alerts;
        this.aipayment = false;
    }
    AilogComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.requestperm = this.permission.m15;
        this.documenttype = '';
        var id = localStorage.getItem('userToken');
        this.Userid = localStorage.getItem('userid');
        var value3 = "{}";
        this.employee.getemployeelist(value3).subscribe(function (listdetails) {
            _this.employeelist = listdetails;
        });
        this.aicenter.getcountpayment().subscribe(function (data) {
            _this.aipayment = data.payment;
        });
        this.loadalldata();
    };
    AilogComponent.prototype.loadalldata = function () {
        var _this = this;
        var data;
        if (this.empid != '') {
            data = { 'documenttype': 'v', employeeid: this.empid };
        }
        else {
            data = { 'documenttype': 'v' };
        }
        this.aicenter.getAilogType(data).subscribe(function (data) {
            _this.recordvisa = data.length;
            if (_this.recordvisa < 30) {
                _this.pageOfItemsvisa = data;
            }
            else {
                _this.itemsvisa = data;
            }
        });
        var data2;
        if (this.empid != '') {
            data2 = { 'documenttype': 'p', employeeid: this.empid };
        }
        else {
            data2 = { 'documenttype': 'p' };
        }
        this.aicenter.getAilogType(data2).subscribe(function (data) {
            // console.log(data);
            // this.passportlist = data;
            _this.recordpassport = data.length;
            if (_this.recordpassport < 30) {
                _this.pageOfItemspassport = data;
            }
            else {
                _this.itemspassport = data;
            }
        });
        var data3;
        if (this.empid != '') {
            data3 = { 'documenttype': 'id', employeeid: this.empid };
        }
        else {
            data3 = { 'documenttype': 'id' };
        }
        this.aicenter.getAilogType(data3).subscribe(function (data) {
            _this.recordid = data.length;
            if (_this.recordid < 30) {
                _this.pageOfItemsid = data;
            }
            else {
                _this.itemsid = data;
            }
        });
    };
    AilogComponent.prototype.changeDocumentType = function (id) {
        this.documenttype = id;
    };
    AilogComponent.prototype.changeEmployee = function (id) {
        this.empid = id;
        this.loadalldata();
    };
    AilogComponent.prototype.onChangePagevisa = function (pageOfItems) {
        // update current page of items
        this.pageOfItemsvisa = pageOfItems;
    };
    AilogComponent.prototype.onChangePagepassport = function (pageOfItems) {
        // update current page of items
        this.pageOfItemspassport = pageOfItems;
    };
    AilogComponent.prototype.onChangePageid = function (pageOfItems) {
        // update current page of items
        this.pageOfItemsid = pageOfItems;
    };
    AilogComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    AilogComponent.prototype.openModal3 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    AilogComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return AilogComponent;
}());
exports.AilogComponent = AilogComponent;
