"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ApprovalComponent = /** @class */ (function () {
    function ApprovalComponent() {
    }
    ApprovalComponent.prototype.ngOnInit = function () {
    };
    return ApprovalComponent;
}());
exports.ApprovalComponent = ApprovalComponent;
