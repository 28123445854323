"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var company_service_1 = require("../../../services/company.service");
//import * as jspdf from 'jspdf';        
// import html2canvas from 'html2canvas-proxy';  
var InvoicedetailsComponent = /** @class */ (function () {
    function InvoicedetailsComponent(company, router, activatedRoute) {
        this.company = company;
        this.router = router;
        this.activatedRoute = activatedRoute;
    }
    InvoicedetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.invoiceid = this.activatedRoute.snapshot.paramMap.get("invoiceid");
        this.timestamp = function (epoch) {
            return (epoch * 1000);
        };
        this.company.getcompanydefaultlocation().subscribe(function (locationlist) {
            _this.companylocationlist = locationlist;
        });
        var data = { 'invoiceid': this.invoiceid };
        this.company.getinvoiceretrive(data).subscribe(function (locationlist) {
            // console.log(locationlist);     
            _this.invoicelist = locationlist;
        });
    };
    InvoicedetailsComponent.prototype.captureScreen = function () {
        var data = document.getElementById('contentToConvert');
        // console.log(data);
        // html2canvas(data).then(canvas => {  
        //   // Few necessary setting options  
        //   var imgWidth = 208;   
        //   var pageHeight = 295;    
        //   var imgHeight = canvas.height * imgWidth / canvas.width;  
        //   var heightLeft = imgHeight;  
        //   const contentDataURL = canvas.toDataURL('image/png')  
        //   let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
        //   var position = 0;  
        //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
        //   pdf.save('MYPdf.pdf'); // Generated PDF   
        // });  
    };
    return InvoicedetailsComponent;
}());
exports.InvoicedetailsComponent = InvoicedetailsComponent;
