export const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};
export class Rota {
  employeerotaid: number;
  employeeId: number;
  userId: number;
  title: number;
  locationId: number;
  starttime: string;
  endtime: string;
  startDate: Date;
  endDate: Date;
  rstatus: boolean;
}