<div class="job_assment">
    <h5>Applicant Document List</h5>
    <button *ngIf="docperm > 1" (click)="file.click()" type="submit" class="btn btn-primary">Upload Document </button>

    <input type="file" accept="image/*"  #file (change)="selectFile($event)" style="display:none;" />


</div>
<div class="import_image_section">
    <div class="import_file_img" *ngFor="let doc of applicantDetailmain.document">
        <div class="import_img">
            <a href="{{doc.documentimageurl}}">
                <i class="fa fa-file-image-o" aria-hidden="true">
                   
                </i>
            </a>
        </div>
        <div class="import_file_name">
            <p>{{doc.documentname}}</p>
        </div>
        <div class="import_right">
            <div class="check">
                <a (click)="opendocumnentname(doc.candidatedocumentdetailsid,doc.candidateid)">
                    <i class="fa fa-edit" aria-hidden="true"></i>
                    <div class="tooltip_visa">
                        <p>Edit</p>
                    </div>
                </a>

            </div>
        </div>
        <div class="clearfix"></div>
        <div class="import_footer">
            <div class="footer_imp_le">
                <!-- <p>File Size :<span>284 KB</span></p> -->
            </div>
            <div class="footer_imp_ri">
                <i class="fa fa-file-image-o" *ngIf="docperm > 0"  (click)="openModalcandidate5('pdf-image-document-viewer', doc.documentimageurl)">
                    <div class="tooltip_visa">
                        <p>View Image</p>
                    </div>
                </i>
                <i class="fa fa-trash" *ngIf="docperm > 2" (click)="deleteFile(doc.candidatedocumentdetailsid, doc.candidateid)">
                    <div class="tooltip_visa">
                        <p>Delete</p>
                    </div>
                </i>
                <a  *ngIf="docperm > 0" href="{{doc.documentimageurl}}"><i class="fa fa-download">
                    <div class="tooltip_visa">
                        <p>Download</p>
                    </div>
                </i>
                   
                </a>
            </div>
            <div class="clearfix"></div>

        </div>
    </div>
</div>
<div class="job_list_section visa_request_section" *ngIf="!applicantDetailmain.document">
    <div class="job_detils_date">
        <p class="job_profile"> No Document Found</p>
    </div>
</div>      




<jw-modal id="employee-doc-name-update" class="modal name_rename" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">

            <div class="modal-body">
                <div class="container">
                    <form [formGroup]="documentGroup" #documentForm="ngForm" class="s12 white" (ngSubmit)="onRenameDocumentSubmit(documentForm.value)" *ngIf="documentnamelist">


                        <div class="row row-sm mg-t-10">
                            <label>Document Name *</label>
                            <input type="hidden" id="candidatedocumentdetailsid" name="candidatedocumentdetailsid" formControlName="candidatedocumentdetailsid" [(ngModel)]="documentnamelist.candidatedocumentdetailsid" />
                            <input type="hidden" id="candidateid" name="candidateid" formControlName="candidateid" [(ngModel)]="documentnamelist.candidateid" />
                            <input type="text" formControlName="documentname" name="documentname" id="documentname" [ngClass]="{ 'is-invalid': submitted && f.documentname.errors }" class="form-control" [(ngModel)]="documentnamelist.documentname" maxlength="100">
                            <div *ngIf="submitted && f.documentname.errors" class="invalid-feedback">
                                <div *ngIf="f.documentname.errors.required">Please Enter Document Name</div>
                            </div>
                        </div>
                        <div class="row row-sm float-right">
                            <button type="submit" class="btn btn-primary com_btn btn_space">Save</button>
                            <button type="button" class="btn btn-secondary com_btn" (click)="closedocumentModal('employee-doc-name-update');">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</jw-modal>


<jw-modal id="pdf-image-document-viewer" class="modal" role="dialog">
    <div role="document" class="formgroup">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-document-viewer');">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
  
            <div class="row">
              <div class="col-lg-12">
  
                <iframe *ngIf="imgcandidatesrc == false" [src]="iframecandidatesrc" width="100%" height="500" frameborder="0"></iframe>
                <img *ngIf="imgcandidatesrc == true" [src]="iframecandidatesrc" style="width:100%;" />
              </div>
            </div>
  
          </div>
        </div>
  
      </div>
    </div>
  </jw-modal>