import { Component, OnInit } from '@angular/core';
import { CountryService } from '../services/country.service';
import { MyvisaService } from '../services/myvisa.service';
import { ModalService } from '../_services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Visa } from '../models/visa/visa.model';
import { UploadFileService } from './../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-myvisa',
  templateUrl: './myvisa.component.html',
  styleUrls: ['./myvisa.component.css']
})

export class MyvisaComponent implements OnInit {
  visalist: any[] = null;
  visaDetail: any;
  countrylist: any;
  Userid: any;
  listcheck: any;
  selectedFiles: FileList;
  iframevisasrc: any;
  imgvisasrc: any;
  iframevisabacksrc: any;
  imgvisabacksrc: any;
  buttoninsert: any;
  filerequirederror: any;
  filesizeerror: any;
  fileformaterror: any;
  file: any;
  filename: any;
  defaultVal: any = "";
  filebackrequirederror: any;
  filebacksizeerror: any;
  filebackformaterror: any;
  selectedbackFiles: FileList;
  filebackname: any;
  filterItems = [
    {
      name: 'All',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Pending Approval',
      id: 1,
      selected: false
    }
  ]
  submitted: any;
  visapopForm: FormGroup;
  visa: Visa;

  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private data: MyvisaService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private router: Router,
    private alerts: AlertService
  ) { }

  ngOnInit() {
    this.createformgroup();
    this.filename = 'Choose file';
    this.filebackname = 'Choose file';
    this.visaDetail = new Visa();
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    this.data.getvisaAllList(data).subscribe((data: any) => {
      this.visalist = data;
    });
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.listcheck = this.filterItems;
  }

  createformgroup()
  {
    this.visapopForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      visanumber: ['', Validators.required],
      visatype: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      visaexpiry: ['', Validators.required],
      visastart: ['', Validators.required],
      iscurrent: ['']
    });
  }

  onDelete(id: number) {
    const data1 = { 'id': id, 'userid': this.Userid };
    if (confirm("Are you sure you want to delete?")) {
      this.data.deletevisa(data1).subscribe((data: any) => {
        var sstatus: any;
        for (let c of this.filterItems) {
          if (c.selected == true) {
            if (c.id == 0) {
              sstatus = [1, 2, 3];
            }
            else {
              sstatus = [c.id];
            }
          }
        }
        const data2 = { 'id': this.Userid, 'cstatus': sstatus };
        this.data.getvisaAllList(data2).subscribe((data: any) => {
          this.visalist = data;
        });
        this.alerts.success('Visa deleted successfully', true);
      });
    }
  }

  onlistchange(type1: any, idval: number) {
    var sstatus: any;
    for (let c of this.filterItems) {
      if (c.id == idval)
        c.selected = true;
      else
        c.selected = false;
    }
    
    if (idval == 0 || type1 == false) {
      sstatus = [1, 2, 3];
    }
    else {
      sstatus = [idval];
    }
    var userid = localStorage.getItem('userid');
    const data = { 'id': userid, 'cstatus': sstatus };
    this.data.getvisaAllList(data).subscribe((data: any) => {
      this.visalist = data;
    });
  }

  get f() {
    return this.visapopForm.controls;
  }
  
  onSubmit(value3: any, id: string) {
    this.submitted = true;
    if (this.visapopForm.invalid) {
      if(!this.selectedFiles)
        this.filerequirederror = true;
      // if(!this.selectedbackFiles)
      //   this.filebackrequirederror = true;
      return;
    }
    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
       else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.fileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.imagename = imagepath;
    }
    else {
      if(value3.id == '0')
      {
        this.filerequirederror = true;
        return;
      }
      value3.imagename = this.visaDetail.visadocname;
    }

    if (this.selectedbackFiles) {
      this.file = this.selectedbackFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filebacksizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.filebackformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.imagebackname = imagepath;
    }
    else {
      // if(value3.id == '0')
      // {
      //   this.filebackrequirederror = true;
      //   return;
      // }
        if(value3.id == '0')
        {
         value3.imagebackname = '';
         }
       else {
        value3.imagebackname = this.visaDetail.visabackdocname;
       }
     
    }


    
    const sd = new Date(value3.visastart);
    value3.visastart = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    const ed = new Date(value3.visaexpiry);
    value3.visaexpiry = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());


    this.data.myvisadetailsUpdate(value3).subscribe((data: any) => {
      var sstatus: any;
      for (let c of this.filterItems) {
        if (c.selected == true) {
          if (c.id == 0) {
            sstatus = [1, 2, 3];
          }
          else {
            sstatus = [c.id];
          }
        }
      }
      var userid = localStorage.getItem('userid');
      const data1 = { 'id': userid, 'cstatus': sstatus };
      this.data.getvisaAllList(data1).subscribe((data: any) => {
        this.visalist = data; 
      });
    });
    this.filename = "Choose file";
    this.alerts.success('Visa updated successfully', true);    
    // this.visaDetail = new Visa();
    this.modalService.close(id);
  }

  selectFile(event)
   {
    this.filesizeerror = false; 
    this.filerequirederror =false; 
    this.fileformaterror = false;
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.fileformaterror = true;  
    }else{
      this.selectedFiles = files[0];
    }
  }

  selectbackFile(event)
   {
    this.filebackrequirederror =false; 
    this.filebacksizeerror = false;  
    this.filebackformaterror = false;
    var files = event.target.files;
    this.filebackname = files[0].name;
    this.selectedbackFiles = this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filebacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filebackformaterror = true;    this.filebacksizeerror = false; 
    }else{
      this.selectedbackFiles = files[0];
    }
  }

  openModal2(id: string) 
  {
    this.filesizeerror = false; 
    this.filerequirederror =false; 
    this.fileformaterror = false;
    this.filebackrequirederror =false; 
    this.filebacksizeerror = false;  
    this.filebackformaterror = false;
    // this.createformgroup();
    this.submitted = false;
    this.buttoninsert = "Submit";
    this.visaDetail = new Visa();    
    this.Userid = localStorage.getItem('userid');
    this.visaDetail.employeeid = this.Userid;
    this.visaDetail.employeevisadeatilid = "0";  
    this.visaDetail.nationalityid = "70";
    this.visaDetail.iscurrent = "0";
    // this.visaDetail.visaexpiry ='';
    // this.visaDetail.visastart = '';
    // this.visaDetail.visatype = '';
    // this.visaDetail.nationalityid = '';
    this.filename = "Choose file"; 
    this.filebackname = "Choose file";    
    this.modalService.open(id);
  }

  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModal3(id: string, data: any, backimg: any ) 
  {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgvisasrc = true;
      this.iframevisasrc = data;
    }
    else {
      this.imgvisasrc = false;
      this.iframevisasrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    if(backimg)
    {
      var fullpathArray = backimg.split('.');
      var typeoffile = fullpathArray[fullpathArray.length - 1];
      if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
        this.imgvisabacksrc = true;
        this.iframevisabacksrc = backimg;
      }
      else {
        this.imgvisabacksrc = false;
        this.iframevisabacksrc = this.getSafeUrl("https://docs.google.com/gview?url=" + backimg + "&embedded=true");
        
      }
    }
    else {
      this.imgvisabacksrc = false;
      this.iframevisabacksrc = '';
    }
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.filesizeerror = false; 
    this.filerequirederror =false; 
    this.fileformaterror = false;
    this.filebackrequirederror =false; 
    this.filebacksizeerror = false;  
    this.filebackformaterror = false;
    this.submitted = false;
    this.filename = "Choose file"; 
    this.filebackname = "Choose file";   
    this.visaDetail = new Visa();  
    // console.log(data);   
    this.visaDetail = data;
    if(data.iscurrent){
      this.visaDetail.iscurrent = "1";
    } else{
      this.visaDetail.iscurrent = "0";
    }
    this.buttoninsert = "Update";
    this.modalService.open(id);
  }

  closeModal(id: string) 
  {
    this.visa = new Visa();
    // this.visaDetail = new Visa();
    // const data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    // this.data.getvisaAllList(data).subscribe((data: any) => {
    //   this.visalist = data;
    // });
    this.modalService.close(id);
  }
}