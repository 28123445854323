import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(private commonService: CommonService) { }

  
  getdepartmentlistbyadmin(data): Observable<any> 
  {
    return this.commonService.formpost('/company/departmentlistbyadmin',data);   
  }  

  settingdepartmentUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/company/departmentupdatebyadmin', value);
  }

  departmentUpdateChangeStatus(value:any): Observable<any> 
  {
    return this.commonService.formpost('/company/departmentupdatechangestatus',value);
  }

}
