import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private commonService: CommonService) { }

 
  
  getprojectlist(data): Observable<any> {
    return this.commonService.formpost('/projectlist',data);
  }

  getprojectlistbyclient(data:any): Observable<any> {
    return this.commonService.formpost('/projectlistbyclient', data);
  }

  projectdetailsUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/projectupdate', value);
  }

  projectdetailsUpdatestatusbyadmin(value: any): Observable<any> {
    return this.commonService.formpost('/projectupdatestatusbyadmin', value);
  }

  deleteProject(value: any): Observable<any> {
    return this.commonService.formpost('/projectdeletebyadmin', value);
  }

}
