"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var contact_service_1 = require("../../../services/contact.service");
var modal_service_1 = require("../../../_services/modal.service");
var address_model_1 = require("../../../models/address/address.model");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var common_service_1 = require("./../../../services/common.service");
var employee_model_1 = require("../../../models/employee.model");
var country_service_1 = require("../../../services/country.service");
var forms_1 = require("@angular/forms");
var alert_service_1 = require("../../../services/alert.service");
var ContactinfoComponent = /** @class */ (function () {
    function ContactinfoComponent(commonService, sanitizer, formBuilder, data, country, modalService, activatedRoute, alerts) {
        this.commonService = commonService;
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.data = data;
        this.country = country;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.alerts = alerts;
        this.address = new address_model_1.Address();
        this.options = [];
        this.defaultVal = "";
    }
    ContactinfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.empid = this.activatedRoute.snapshot.paramMap.get("id");
        this.contactformGroup = this.formBuilder.group({
            id: [''],
            employeeid: ['', forms_1.Validators.required],
            kinname: [''],
            employeerelation: [''],
            workphone: ['', forms_1.Validators.required],
            // mobile: ['', Validators.required],
            homephone: [''],
            workemail: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            personalemail: ['', forms_1.Validators.email],
            kinmobile: [''],
            kinemail: ['', forms_1.Validators.email],
            telephone: [''],
            kinaddress1: [''],
            kinaddress2: [''],
            kinpincode: [''],
            kincityname: [''],
            kincountyname: [''],
            kincountryid: ['']
        });
        this.onChanges();
        this.contactDetail = new employee_model_1.Contactinfo();
        this.filename = 'Choose file';
        this.Userid = localStorage.getItem('userid');
        var data = { 'userid': this.Userid, 'employeeid': this.empid };
        this.data.getContactInfobyadmin(data).subscribe(function (data) {
            // console.log(data);
            if (data != null) {
                _this.contactDetail = data;
            }
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
    };
    ContactinfoComponent.prototype.onChanges = function () {
        var _this = this;
        this.formattedMessage = '';
        this.contactformGroup.valueChanges.subscribe(function (val) {
            _this.formattedMessage = 'Hello, My name is and my email is .I would like to tell you that .';
        });
    };
    Object.defineProperty(ContactinfoComponent.prototype, "f", {
        get: function () { return this.contactformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    ContactinfoComponent.prototype.onSubmit = function (formcontact, id) {
        var _this = this;
        // var formcontact = value3.value;
        this.submitted = true;
        // console.log(this.contactformGroup);
        if (this.contactformGroup.invalid) {
            return;
        }
        if (formcontact.kinpincode != '' && formcontact.kinpincode != undefined) {
            var pinc = formcontact.kinpincode.split(',');
            formcontact.kinpincode = pinc[pinc.length - 1];
        }
        this.disableBtn = false;
        formcontact.userid = this.Userid;
        this.data.employeeContactinfoUpdatebyadmin(formcontact).subscribe(function (data) {
            var data1 = { 'userid': _this.Userid, 'employeeid': _this.empid };
            // value3.reset();
            _this.data.getContactInfobyadmin(data1).subscribe(function (data) {
                return _this.contactDetail = data;
            });
        });
        this.messageSuccess = 'Contact Info Updated successfully.';
        this.alerts.success(this.messageSuccess, false);
        this.modalService.close(id);
    };
    ContactinfoComponent.prototype.selectFile = function (event) {
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
    };
    ContactinfoComponent.prototype.onSearchChange = function (searchValue) {
        var _this = this;
        if (searchValue.length > 4) {
            var newArray = [];
            this.commonService.getAddress(searchValue).subscribe(function (data) {
                var newdata;
                newdata = data;
                newdata.addresses.forEach(function (value) {
                    newArray.push(value + "," + searchValue);
                });
                _this.options = newArray;
            });
        }
        else {
            this.options = [];
        }
    };
    ContactinfoComponent.prototype.displayFn = function (value) {
        if (value != null) {
            var res = value.split(",");
            if (res.length > 6) {
                return res[7];
            }
            else {
                return value;
            }
        }
    };
    ContactinfoComponent.prototype.updatecode = function (evt, response) {
        res = [];
        if (evt.source.selected) {
            var res = response.split(",");
            this.contactDetail.kinaddress1 = res[0];
            this.contactDetail.kinaddress2 = res[1];
            this.contactDetail.kincityname = res[5];
            this.contactDetail.kinpincode = res[7];
            this.contactDetail.kincountyname = res[6];
        }
    };
    ContactinfoComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'workphone') {
                _this.workphonecheck = getdata.valid;
            }
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'homephone') {
                _this.homephonecheck = getdata.valid;
            }
        });
    };
    ContactinfoComponent.prototype.openModal2 = function (id) {
        this.disableBtn = true;
        this.submitted = false;
        this.contactDetail = new employee_model_1.Contactinfo();
        this.buttoninsert = "Submit";
        this.contactDetail.employeeid = this.empid;
        this.contactDetail.kincountryid = "";
        this.contactDetail.id = "0";
        this.modalService.open(id);
    };
    ContactinfoComponent.prototype.openModal = function (id, data) {
        this.disableBtn = true;
        this.buttoninsert = "Update";
        this.contactDetail = data;
        if ((this.contactDetail.kincountryid == '') || (this.contactDetail.kincountryid == null)) {
            this.contactDetail.kincountryid = '';
        }
        this.modalService.open(id);
    };
    ContactinfoComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    ContactinfoComponent.prototype.openModal3 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    ContactinfoComponent.prototype.closeModal = function (id) {
        // this.passportDetail = new Passport();
        // const data = { 'userid': this.Userid, 'employeeid': this.empid };
        // this.data.getContactInfobyadmin(data).subscribe((data: any) => {
        //   this.contactDetail = data;
        // });
        this.modalService.close(id);
    };
    return ContactinfoComponent;
}());
exports.ContactinfoComponent = ContactinfoComponent;
