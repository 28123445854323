"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var aicenter_service_1 = require("./../../services/aicenter.service");
var alert_service_1 = require("../../services/alert.service");
var modal_service_1 = require("../../_services/modal.service");
var DocumentapproverejectComponent = /** @class */ (function () {
    function DocumentapproverejectComponent(activatedRoute, aiservice, alerts, modalService) {
        this.activatedRoute = activatedRoute;
        this.aiservice = aiservice;
        this.alerts = alerts;
        this.modalService = modalService;
        this.mode = '';
        this.buttonsb = 0;
    }
    DocumentapproverejectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.buttonshow = 'no';
        this.id = this.activatedRoute.snapshot.paramMap.get("id");
        this.aiservice.getDocumentImages({ 'id': this.id }).subscribe(function (response) {
            // this.aiservice.getDocumentImages({'id': this.id}).subscribe(function(response){
            _this.buttonshow = 'Yes';
            _this.mode = response.documentty;
            _this.vs = response.document;
            console.log(response.success);
            // if(response.success){
            _this.data = response;
            _this.profile = response.profile;
            _this.frontimage = response.frontimage;
            _this.backimage = response.backimage;
            // this.buttonshow = true;
            _this.token_expired = response.dstatus;
            console.log(_this.profile);
            console.log(_this.frontimage);
            console.log(_this.backimage);
            // } else{
            //   this.buttonshow = false;
            // }
        });
    };
    DocumentapproverejectComponent.prototype.approvedoc = function () {
        var _this = this;
        this.aiservice.approveAiDocument({ 'jumioIdScanReference': this.id, 'verificationStatus': 'APPROVED_VERIFIED' }).subscribe(function (data) {
            // this.aiservice.approveAiDocument({'jumioIdScanReference': this.id, 'verificationStatus': 'APPROVED_VERIFIED'}).subscribe(function(data){
            _this.token_expired = 2;
            console.log(data);
            _this.alerts.success("Document has been approved successfully");
        });
    };
    DocumentapproverejectComponent.prototype.rejectdoc = function () {
        var _this = this;
        this.aiservice.approveAiDocument({ 'jumioIdScanReference': this.id, 'verificationStatus': '' }).subscribe(function (data) {
            // this.aiservice.approveAiDocument({'jumioIdScanReference': this.id, 'verificationStatus': ''}).subscribe(function(data){
            _this.token_expired = 3;
            _this.alerts.success("Document has been rejected successfully");
            console.log(data);
        });
    };
    DocumentapproverejectComponent.prototype.openModalidproof = function (backimg) {
        this.imgidproofsrc = true;
        this.iframeidproofsrc = backimg;
        this.modalService.open('pdf-image-viewer-myidproofsystem');
    };
    DocumentapproverejectComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return DocumentapproverejectComponent;
}());
exports.DocumentapproverejectComponent = DocumentapproverejectComponent;
