import { HeaderComponent } from '../../user/header/header.component';
// import { EmployeeMaster } from './../models/employee_master';
// import { HeaderComponent } from './user/header/header.component';
import { Component, OnInit } from '@angular/core';
import { MypassportService } from '../../services/mypassport.service';
import { ModalService } from '../../_services/modal.service';

import { FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { UploadFileService } from './../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ChecksystemService } from '../../services/checksystem.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Passport } from '../../models/passport/passport.model';
import { CountryService } from '../../services/country.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { AicenterService } from '../../services/aicenter.service';

@Component({
  selector: 'app-checkidentitylist',
  templateUrl: './checkidentitylist.component.html',
  styleUrls: ['./checkidentitylist.component.css']
})
export class CheckidentitylistComponent implements OnInit {

  searchText:any;
  form: FormGroup;
  datalist:any;
  passformGroup: FormGroup;
  passportlist: any[];
  statuslist: any;
  passportDetail: any;//= new Passport();  
  countrylist: any[];
  Userid: any;
  listcheck: any;
  myfile: any;
  file: any;
  selectedFiles: FileList;
  passnumbererror: any;
  nationalityerror: any;
  passportdateoriginerror: any;
  iframesrc: any;
  imgsrc: any;
  buttoninsert: any;
  filesizeerror: any;
  fileformaterror: any;
  formattedMessage: any;
  messageSuccess:any;
  filename: any;
  disableBtn: any;
  permission: any;
  requestperm: any;
  record:number;
  ramainingChecks:any;
  items: Array<any>;
  
  pageOfItems: Array<any>;
  aipayment: boolean =false;
  downloadpassporttext: any;
  
  
  filereuirederror: any;
  defaultVal: any = "";
  passcount: any =0;
  filebacksizeerror: any;
  filebackformaterror: any;
  selectedbackFiles: FileList;
  filebackname: any;
  imagefirst: any;
  imagesecond: any;

  filterItems = [
    {
      name: 'All Passport Details',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Waiting for review',
      id: 1,
      selected: false
    }
  ]
  submitted: boolean;

  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private data: MypassportService, 
    private country: CountryService,     
    private aicenter:AicenterService,
    private modalService: ModalService, 
    private router: Router, 
    private alerts: AlertService,
    private check: ChecksystemService
  ) { }

  ngOnInit() {
    // this.downloadpassporttext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
    this.passformGroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      passportnumber: ['', Validators.required],
      placeofissue: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      passportissue: ['', Validators.required],
      passportexpiry: ['', Validators.required],
      iscurrent: [''],
    });
    

    this.check.getbillinginformation({ 'serviceid': 7 }).subscribe((remaindata: any) => { 
      this.ramainingChecks = remaindata;
    });


    
    this.onChanges();
	
    this.filename = 'Choose file';this.filebackname = 'Choose file';
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'cstatus': [1, 2, 3], 'isrighttowork': 0, 'isaiverify': [1, 2, 3] };
    this.data.getallpassportListbyadmin(data).subscribe((data: any) => {
      // this.passportlist = data;
      this.record  = data.length;
      this.datalist = data;
      this.downloadpassporttext = new Array(data.length);
      for(var i=0;i<data.length;i++)
      {
       this.downloadpassporttext[i] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
      }
      if(this.record < 30){
        this.pageOfItems = data;
        console.log(this.pageOfItems);
      }
      else{
           this.items =data;
      }
    });

    this.listcheck = this.filterItems;
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.passportDetail = new Passport();
    this.passportDetail.sstatus = 0;
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}

  onChangeDate(event: any)
  {
   
  }
  
  onChanges(): void {
    this.formattedMessage = '';
    this.passformGroup.valueChanges.subscribe(val => {
      this.formattedMessage = 'Hello, My name is and my email is .I would like to tell you that .';
    });
  }

  onlistchange(type1: any, idval: number) {
    var sstatus: any;
    for (let c of this.filterItems) {
      if (c.id == idval)
        c.selected = true;
      else
        c.selected = false;
    }

    if (idval == 0 || type1 == false) {
      sstatus = [1, 2, 3];
    }
    else {
      sstatus = [idval];
    }

    var userid = localStorage.getItem('userid');
    const data = { 'id': userid, 'cstatus': sstatus };
    this.data.getallpassportListbyadmin(data).subscribe((data: any) => {
      this.passportlist = data;
      
    });

  }

  get f() { return this.passformGroup.controls; }

  onSubmit(value3: any, id: string, cstatus: string) {
    this.submitted = true;
    if (this.passformGroup.invalid) {
      return;
    }

   
    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
        this.fileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.imagename = imagepath;
    }
    else {
      value3.imagename = this.passportDetail.passportdocname;
    }
    if(value3.imagename =='' || value3.imagename == null)
    {
      this.filereuirederror =true;
      return;
    }

    if (this.selectedbackFiles) {
      this.file = this.selectedbackFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
        this.fileformaterror = true;
        return;
      }
      var imagepath =  this.uploadService.uploadfile(this.file);
      value3.imagebackname = imagepath;
    }
    else {
      value3.imagebackname = this.passportDetail.passportdocname;
    }

    var error = 0;
    if (value3.passportnumber == '') {
      this.passnumbererror = false;
      error++;
    }
    if (value3.nationalityid == '') {
      this.nationalityerror = false;
      error++;
    }
    if (value3.passportissue == null) {
      this.passportdateoriginerror = false;
      error++;
    }
    if (error > 0) {
      return false;
    }
    this.disableBtn = false;
    value3.cstatus = cstatus;
    // value3.createdby = cstatus;
    value3.createby = this.Userid;
    value3.updateby = this.Userid;
    this.data.mypassportdetailsUpdatebyadmin(value3).subscribe((data: any) => {
      var sstatus: any;
      for (let c of this.filterItems) {
        if (c.selected == true) {
          if (c.id == 0) {
            sstatus = [1, 2, 3];
          }
          else {
            sstatus = [c.id];
          }
        }
      }

      var userid = localStorage.getItem('userid');
      const data1 = { 'id': userid, 'cstatus': sstatus };
      this.data.getallpassportListbyadmin(data1).subscribe((data: any) => {
        
        return this.passportlist = data;
        
      });

    });
    //this.alerts.setDefaults('timeout',0);
    this.messageSuccess = 'Passport details updated successfully.'; 
    this.alerts.success(this.messageSuccess, false);
    this.filename = "Choose file";
    this.modalService.close(id);
  }

  selectFile(event)
   {
   var files = event.target.files;
    this.filename = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.fileformaterror = true;  this.filesizeerror = false;      
    }else{
      this.filesizeerror = false;  
      this.fileformaterror = false;
      this.filereuirederror =false;
      this.imagesecond =  this.uploadService.uploadfile(this.file);
      this.selectedFiles = files[0];
    }
  }

  selectbackFile(event)
   {
    var files = event.target.files;
    this.filebackname = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filebacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filebackformaterror = true;    this.filebacksizeerror = false; 
    }else{this.filebacksizeerror = false;  
      this.filebackformaterror = false;
     this.imagesecond =  this.uploadService.uploadfile(this.file);
      this.selectedbackFiles = files[0];
    }
  }


  onDelete(empid: number) {
    if (confirm("Are you sure you want to delete?")) {
      const data1 = { 'id': empid };
      this.data.deletePassport(data1).subscribe((data: any) => {

        var sstatus: any;
        for (let c of this.filterItems) {
          if (c.selected == true) {
            if (c.id == 0) {
              sstatus = [1, 2, 3];
            }
            else {
              sstatus = [c.id];
            }
          }
        }
        var userid = localStorage.getItem('userid');
        const data1 = { 'id': userid, 'cstatus': sstatus };
        this.data.getallpassportListbyadmin(data1).subscribe((data: any) => {

          this.alerts.success('Passport deleted successfully.', true);


          this.passportlist = data;


          
        });
      })
    }
  }



  openModal2(id: string) {
    this.submitted = false;
    this.disableBtn = true;
    this.passportDetail = new Passport();
    this.buttoninsert = "Submit";
    this.filename = "Choose file";this.filebackname = 'Choose file';
    this.Userid = localStorage.getItem('userid');
    this.passportDetail.employeeid = this.Userid;
    this.passportDetail.employeepassdeatilid = "0";
    // this.passportDetail.passportnumber = '';
    // this.passportDetail.nationalityid = '';
    // this.passportDetail.passportissue = '';
    // this.passportDetail.passportexpiry = '';
    // this.passportDetail.placeofissue = '';
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.submitted = false;
    this.disableBtn = true;
    this.passportDetail = new Passport();
    this.filename = "Choose file";this.filebackname = 'Choose file';
    this.buttoninsert = "Update";
    this.passportDetail = data;
    this.modalService.open(id);
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModal3(id: string, data: any) {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.passportDetail = new Passport();
    var userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    this.data.getallpassportListbyadmin(data).subscribe((data: any) => {
      this.passportlist = data;
    });
    this.modalService.close(id);
  }

  modalOpen(data: any) {
    this.disableBtn = true;
    this.passportDetail = new Passport();
    this.passportDetail = data;
  }

  uploadByAdmin(empid)
  {
    if(this.ramainingChecks && this.ramainingChecks.remiancheck < 1)
    {
      this.modalService.open('upgrade-plan');
    }
    else
    {
      this.router.navigate(['/check-add-applicant', 'passport']);
    }
  }

  downloadPassport(passporthistoryid, ind)
  {
    console.log(passporthistoryid);
    this.downloadpassporttext[ind] = 'Loading <i class="fa fa-spinner fa-spin"></i>';
    this.data.downloadPassport({'passportid': passporthistoryid}).subscribe((data: any) => {     
      this.downloadpassporttext[ind] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
      window.open(data.response_data.Location, "_blank");
      console.log(data);
    });
  }

}
