import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Component, OnInit, NgModule } from '@angular/core';
import { MyaddressService } from '../services/myaddress.service';
// import { ModalService } from '../../services/modal.service';
import { ModalService } from '../_services/modal.service';
import { CountryService } from '../services/country.service';
import { CommonService } from '../services/common.service';
// import { AlertPromise } from 'selenium-webdriver';
import { Address } from '../models/address/address.model';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { MatOptionSelectionChange } from '@angular/material';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { truncate } from 'fs';
import { AlertService } from '../services/alert.service';
@NgModule({
  imports: [
    InternationalPhoneNumberModule,
  ],
})

@Component({
  selector: 'app-myaddress',
  templateUrl: './myaddress.component.html',
  styleUrls: ['./myaddress.component.css']
})

export class MyaddressComponent implements OnInit {
  address = new Address();
  addresslist: any[] =null;
  addressdata: any[] =null;
  addressDetail: any;
  countrylist: any[];
  Userid: any;
  buttoninsert:any;
  listcheck: any;
  mobilecheck: any;
  phonecheck: any;
  statelist: any[];
  users: any[];
  initialpin: string;
  addresslistDetail: any;
  countrylistjson: any[];
  address2 = 0;
  myControl = new FormControl();
  options = [];
  currentdatevalue: any;
  movedoutdateerror: any;
  filterItems = []
  addpopForm: FormGroup;
  submitted: any;
  currentDate = new Date();
  defaultVal: any = "";

  constructor(
    private formBuilder: FormBuilder, 
    private data: MyaddressService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private commonService: CommonService, 
    private http: HttpClient,
    private router: Router,
    private alerts: AlertService
  ) { }

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    this.currentdatevalue = true;
    this.addpopForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      cityname: ['', Validators.compose([Validators.required,])],
      //  mobilenumber: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{10}")])],
     mobilenumber: [''],
     movedin: ['', Validators.required],
     movedout: [''],
     statename: ['', Validators.required],
      pincode: ['', Validators.required],
      iscurrent: [''],
      stateid: [''],
      // phonenumber: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{10}")])],
      phonenumber: [''],
      countryid: ['', Validators.compose([Validators.required, Validators.min(1)])]
    });
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
    this.data.getemploymenttList(data).subscribe((data: any) => {
      this.addresslist = data;
      this.addressdata = data;
    });
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.listcheck = this.filterItems;
  }

  get f() { return this.addpopForm.controls; }
  onSubmit(value3: any, id: string) 
  {
    this.submitted = true;
    this.movedoutdateerror = "";
    //console.log(value3);
    if(!value3.iscurrent)
    {
      value3.iscurrent= false;
    }
    if(value3.iscurrent)
    {
      value3.iscurrent= true;
    }
    if(!value3.iscurrent && !value3.movedout)
    { 
      this.movedoutdateerror = "Please select Moved Out Date";
    }
    if (this.addpopForm.invalid || this.movedoutdateerror != "") 
    {      
      return;
    }
    var pinc = value3.pincode.split(',');
    value3.pincode = pinc[pinc.length - 1];
    if((value3.iscurrent == true) )
    {      
      this.data.myaddresschangecurrent(value3).subscribe((data: any) => {
      });      
    }

    
    
    const sd = new Date(value3.movedin);
    value3.movedin = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());

    if(value3.movedout)
    {
      const ed = new Date(value3.movedout);
      value3.movedout = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
    }


    this.data.myaddressdetailsUpdate(value3).subscribe((data: any) => {
      this.Userid = localStorage.getItem('userid');
      const data1 = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
      this.data.getemploymenttList(data1).subscribe((data: any) => {
        const data2 = { 'id': data.countryid };
        this.country.getstatelist(data2).subscribe((state: any) => {
          this.statelist = state
        });
        this.addressdata = data;
        this.addresslist = data;
      });
    });
     this.alerts.success('Address updated successfully',true);
    this.modalService.close(id);
    // this.addresslistDetail = new Address();
  }
  
  number(input: FormControl) {
    const isnumber = input.value > 0;
    return isnumber ? null : true;
  }

  onSelectCountry(country_id: number) {
    const data = { 'id': country_id };
    this.country.getstatelist(data).subscribe((state: any) => {
      return this.statelist = state
    });
  }

  onlistchange(type1: any, idval: number) {
    var sstatus: any;
    for (let c of this.filterItems) {
      if (c.id == idval)
        c.selected = true;
      else
        c.selected = false;
    }
    if (idval == 0 || type1 == false) {
      sstatus = [1, 2, 3];
    }
    else {
      sstatus = [idval];
    }
    var userid = localStorage.getItem('userid');
    const data = { 'id': userid, 'cstatus': sstatus };
    this.data.getemploymenttList(data).subscribe((data: any) => {
      this.addresslist = data;
      this.addressdata = data;
    });
  }

  onSearchChange(searchValue) {
    if (searchValue.length > 4) {
      var newArray = [];
      this.commonService.getAddress(searchValue).subscribe((data: any) => {  
        var newdata: any;
        newdata = data;
        newdata.addresses.forEach(function (value) {
          newArray.push(value + "," + searchValue);
        });
        this.options = newArray;            
      });
    }
    else {
      this.options = [];
    }
  }

  displayFn(value: any) {
    if (value != null) {
      var res = value.split(",");
      if (res.length > 6) {
        return res[7];
      }
      else {
        return value;
      }
    }
  }

  updatecode(evt: MatOptionSelectionChange, response) {
    res = [];
    if (evt.source.selected) {
      var res = response.split(",");
      this.addresslistDetail.address1 = res[0];
      this.addresslistDetail.address2 = res[1];
      this.addresslistDetail.cityname = res[5];
      this.addresslistDetail.pincode = res[7];
      this.addresslistDetail.statename = res[6];
      // const data = { 'id': this.addresslistDetail.countryid };
      // this.country.getstatelist(data).subscribe((state: any) => {
      //   for (var i = 0; i < state.length; i++) {
      //     if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
      //       var inde = state[i]['stateid'];
      //     }
      //   }
      //   this.addresslistDetail.stateid = inde;
      //   this.statelist = state;
      // });
    }
  }

  onDelete(empid: number)
  {
    if (confirm("Are you sure you want to delete?")) {
      const data1 = { 'id': empid };
      this.data.myaddressDelete(data1).subscribe((data: any) => {
        var sstatus: any;
        for (let c of this.filterItems) {
          if (c.selected == true) {
            if (c.id == 0) {
              sstatus = [1, 2, 3];
            }
            else {
              sstatus = [c.id];
            }
          }
        }
        var userid = localStorage.getItem('userid');
        const data1 = { 'id': userid, 'cstatus': sstatus };
        this.data.getemploymenttList(data1).subscribe((data: any) => {
          return this.addressdata = data;
        });
      })
    }
  }

  currentaddresschange(eve,addressid)
  {    
    if(eve.target.value==1)
    {
      this.addresslistDetail.movedout = "";
      this.currentdatevalue = false;
    }
    else{  
      this.currentdatevalue = true;
    }
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  openModal(id: string, data: any) {
    if(data.iscurrent == true)
    {
     // data.iscurrent = 1;
      this.currentdatevalue = false;
    }
    else
    {
     // data.iscurrent = 0;
      this.currentdatevalue = true;
    }
    this.movedoutdateerror = "";
    this.buttoninsert = "Update";
    this.addresslistDetail = data;   
    if(this.addresslistDetail.iscurrent === true)
    {
      this.addresslistDetail.iscurrent = 1;
    }
    if(this.addresslistDetail.iscurrent === false)
    {
      this.addresslistDetail.iscurrent = 0;
    }
    const data2 = { 'id': data.countryid };
    this.country.getstatelist(data2).subscribe((state: any) => {
      this.statelist = state
    });
    this.modalService.open(id);
  }

  openModal2(id: string) {
    this.movedoutdateerror = "";
    this.submitted = false;
    this.addpopForm.invalid == false;
    this.addresslistDetail = new Address();
    this.buttoninsert = "Submit";
    this.Userid = localStorage.getItem('userid');
    this.addresslistDetail.employeeid = this.Userid;
    this.addresslistDetail.employeeaddresdeatilid = "0";
    this.addresslistDetail.countryid = "";
    this.addresslistDetail.iscurrent = '1';
    this.currentdatevalue = false;
    // this.addresslistDetail.address1 ='';
    // this.addresslistDetail.address2 ='';
    // this.addresslistDetail.pincode ='';
    // this.addresslistDetail.cityname ='';
    // this.addresslistDetail.stateid ='';
    // this.addresslistDetail.phonenumber ='';
    // this.addresslistDetail.mobilenumber ='';
    this.modalService.open(id);
  }

  closeModal(id: string) {
    // this.addresslistDetail = new Address();
    this.Userid = localStorage.getItem('userid');
      const data1 = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
      this.data.getemploymenttList(data1).subscribe((data: any) => {
        const data2 = { 'id': data.countryid };
        this.country.getstatelist(data2).subscribe((state: any) => {
          this.statelist = state
        });
        this.addressdata = data;
        this.addresslist = data;
      });
      // this.addresslistDetail = new Address();
    this.modalService.close(id);
  }

  modalOpen(data: any) {
    this.addresslistDetail = data;
  }

  retFalse(e)
  {
    return false;
  }
}