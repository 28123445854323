"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var user_service_1 = require("../services/user.service");
var modal_service_1 = require("../_services/modal.service");
var user_model_1 = require("../models/user.model");
var forms_1 = require("@angular/forms");
var customer_service_1 = require("../services/customer.service");
var alert_service_1 = require("../services/alert.service");
var common_service_1 = require("../services/common.service");
var permission_service_1 = require("../services/permission.service");
var SignupComponent = /** @class */ (function () {
    function SignupComponent(formBuilder, userService, modalService, router, activatedRoute, alerts, csservices, commonService, permissiondetails) {
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.modalService = modalService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.alerts = alerts;
        this.csservices = csservices;
        this.commonService = commonService;
        this.permissiondetails = permissiondetails;
        this.submitted = false;
        this.isLoginError = false;
        this.user = new user_model_1.User();
        this.access_token = '';
        this.userid = '';
        this.name = '';
        this.otp = '';
        this.isline = '';
        this.error = '';
        this.planid = '';
        this.isemail = '';
    }
    SignupComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (userid) {
            this.router.navigate(['/dashboard']);
        }
        this.username = '';
        var type = this.activatedRoute.snapshot.paramMap.get("id");
        if (type) {
            if (type == 'hr-software') {
                this.type = 0;
            }
            else if (type == 'compliance-centre') {
                this.type = 1;
            }
            else if (type == 'bussiness-credit-score-report') {
                this.type = 1;
                // this.stage =1;
                // this.type = 2;
            }
            else if (type == 'automated-reference') {
                // this.type = 3;
                this.type = 1;
            }
            this.stage = 2;
            if (type == 'bussiness-credit-score-report') {
                this.type = 1;
                // this.stage =1;
                // this.type = 2;
            }
        }
        else {
            this.stage = 1;
        }
        this.registerForm = this.formBuilder.group({
            email: ['', [forms_1.Validators.required, forms_1.Validators.email]],
            password: ['', [forms_1.Validators.required, forms_1.Validators.minLength(8), forms_1.Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
            confirmpassword: ['', [forms_1.Validators.required, forms_1.Validators.minLength(8), forms_1.Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
            mobilenumber: ['', forms_1.Validators.required],
            companyname: ['', forms_1.Validators.required],
            username: ['', forms_1.Validators.required],
            firstname: ['', forms_1.Validators.required],
            lastname: ['', forms_1.Validators.required],
            chkTermCndition: [false, forms_1.Validators.required],
            chkcont: [false, forms_1.Validators.required]
        }, { validator: this.checkPasswords });
        this.permissiondetails.bindallmenu().subscribe(function (menudata) {
            //console.log(menudata);
            var permissionstring = "[{";
            var perarray = [];
            menudata.forEach(function (valuemenu) {
                perarray.push('"' + valuemenu.clname + '": 3');
            });
            var perstr = perarray.join();
            permissionstring = permissionstring + perstr + "}]";
            _this.permissionstring = permissionstring;
        });
    };
    SignupComponent.prototype.checkPasswords = function (group) {
        var pass = group.controls.password.value;
        var confirmPass = group.controls.confirmpassword.value;
        return pass === confirmPass ? null : { notSame: true };
    };
    Object.defineProperty(SignupComponent.prototype, "f", {
        get: function () { return this.registerForm.controls; },
        enumerable: true,
        configurable: true
    });
    SignupComponent.prototype.newregistation = function (id) {
        this.stage = 2;
        this.type = id;
    };
    SignupComponent.prototype.newbackregistation = function () {
        this.stage = 1;
    };
    SignupComponent.prototype.onUserNameOutEvent = function (event) {
        var _this = this;
        if (event.target.value == '') {
            this.username = '';
        }
        else {
            this.username = '';
            var dbvalue = { 'username': event.target.value };
            this.userService.isUserRegisterd(event.target.value).subscribe(function (data) {
                // this.userService.isUserRegisterd(event.target.value).subscribe((data2: any) => {
                if (data.sussess == 'false') {
                    _this.username = 'false';
                }
                else {
                    _this.username = 'true';
                }
            });
        }
    };
    SignupComponent.prototype.onEmailOutEvent = function (event) {
        var _this = this;
        if (event.target.value == '') {
            this.emailcheck = '';
        }
        else {
            this.emailcheck = '';
            var dbvalue = { 'email': event.target.value };
            this.userService.isEmailRegisterd(event.target.value).subscribe(function (data) {
                if (data.sussess == 'false') {
                    _this.emailcheck = 'false';
                }
                else {
                    _this.emailcheck = 'true';
                }
            });
        }
    };
    SignupComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.user.permissionstring = this.permissionstring;
        if (this.type == 1) {
            this.user.planid = 1;
            this.user.ctype = 1;
            this.user.isemail = true;
        }
        else if (this.type == 2) {
            this.user.planid = 0;
            this.user.ctype = 2;
        }
        else if (this.type == 3) {
            this.user.planid = 1;
            this.user.ctype = 3;
        }
        this.userService.userjoinnow(this.user).subscribe(function (data2) {
            if (data2.sussess == 'true') {
                _this.userService.userAuthentication(_this.user).subscribe(function (data) {
                    if (data.sussess == 'true') {
                        localStorage.setItem('userToken', _this.access_token);
                        localStorage.setItem('userid', _this.userid);
                        localStorage.setItem('Name', _this.name);
                        localStorage.setItem('isline', _this.isline);
                        localStorage.setItem('planid', _this.planid);
                        localStorage.setItem('isemail', _this.isemail);
                        localStorage.setItem('isadmin', data.isadmin);
                        localStorage.setItem('ispermission', data.ispermission);
                        localStorage.setItem('logoname', data.logourl);
                        localStorage.setItem('struser', '');
                        localStorage.setItem('menu', '0');
                        localStorage.setItem('ctype', _this.type);
                        if (data.sussess == 'true') {
                            localStorage.setItem('userToken', data.id);
                            localStorage.setItem('userid', data.userid);
                            localStorage.setItem('emptype', data.emptype);
                            localStorage.setItem('isline', data.isline);
                            localStorage.setItem('planid', data.planid);
                            localStorage.setItem('isemail', data.isemail);
                            localStorage.setItem('ismobile', data.ismobile);
                            localStorage.setItem('isadmin', data.isadmin);
                            localStorage.setItem('ispermission', data.ispermission);
                            localStorage.setItem('logoname', data.logourl);
                            localStorage.setItem('struser', data.struser);
                            localStorage.setItem('menu', '0');
                            localStorage.setItem('ctype', data.ctype);
                            // console.log(data);
                            if (data.isemail == false) {
                                localStorage.setItem('otp', data.otp);
                            }
                            if (data.ismobile == false) {
                                localStorage.setItem('otpmobile', data.otpmobile);
                            }
                            _this.csservices.getOverview().subscribe(function (data6) {
                                var lname = '';
                                if (data6.lname != null) {
                                    lname = data6.lname;
                                }
                                localStorage.setItem('Name', data6.fname + ' ' + lname);
                                _this.alerts.success('Welcome to Complygate', true);
                                _this.router.navigate(['/' + data.url]);
                            });
                        }
                        else {
                            _this.alerts.error(data.error, true);
                        }
                    }
                });
            }
            else {
                _this.alerts.error(data2.error, true);
            }
        }, function (err) {
            _this.isLoginError = true;
        });
    };
    SignupComponent.prototype.openModal = function (id) {
        this.modalService.open(id);
    };
    SignupComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    SignupComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    return SignupComponent;
}());
exports.SignupComponent = SignupComponent;
