"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var taxdefaulter_service_1 = require("../../services/taxdefaulter.service");
var alert_service_1 = require("../../services/alert.service");
var router_1 = require("@angular/router");
var modal_service_1 = require("../../_services/modal.service");
var checksystem_service_1 = require("../../services/checksystem.service");
var TaxdefaultComponent = /** @class */ (function () {
    function TaxdefaultComponent(router, formBuilder, taxService, alerts, check, modalService) {
        this.router = router;
        this.formBuilder = formBuilder;
        this.taxService = taxService;
        this.alerts = alerts;
        this.check = check;
        this.modalService = modalService;
        this.getRemainingChecks = new core_1.EventEmitter();
        this.defaultVal = "";
    }
    TaxdefaultComponent.prototype.ngOnInit = function () {
        var _this = this;
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        // this.permission = permission[0];
        if (permission[0]['m134'] < 1) {
            this.router.navigate(['/permission-denied']);
        }
        this.submitted = false;
        this.createformgroup();
        this.listview = false;
        this.lesssore = false;
        this.submitted = false;
        var ts = { 'serviceid': 9 };
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            _this.remainingcheck = remaindata;
            if (remaindata.remiancheck <= 0) {
                _this.lesssore = true;
            }
        });
        this.getRemainingCheck();
    };
    Object.defineProperty(TaxdefaultComponent.prototype, "f", {
        get: function () {
            return this.taxFormgroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    TaxdefaultComponent.prototype.createformgroup = function () {
        this.taxFormgroup = this.formBuilder.group({
            bussinessname: ['', forms_1.Validators.required],
            businesstrade: ['', forms_1.Validators.required]
        });
    };
    TaxdefaultComponent.prototype.onSubmit = function (supportFormValues, id) {
        var _this = this;
        this.submitted = true;
        this.disabled = false;
        if (this.taxFormgroup.invalid) {
            return;
        }
        this.disabled = true;
        // this.submitted = true;
        this.taxService.gettaxdefaulter(supportFormValues).subscribe(function (data) {
            _this.pageOfItems = data;
            if (data.length == 0) {
                _this.record = 0;
            }
            else {
                _this.record = 2;
            }
            _this.listview = true;
            _this.submitted = false;
            _this.createformgroup();
        });
    };
    TaxdefaultComponent.prototype.getRemainingCheck = function () {
        var _this = this;
        var ts = { 'serviceid': 8 };
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            _this.checkcount = remaindata;
        });
    };
    TaxdefaultComponent.prototype.clearData = function () {
        this.disabled = false;
        this.submitted = false;
        this.createformgroup();
    };
    TaxdefaultComponent.prototype.newSearch = function () {
        var _this = this;
        this.disabled = false;
        var ts = { 'serviceid': 9 };
        this.check.getbillinginformation(ts).subscribe(function (remaindata) {
            _this.remainingcheck = remaindata;
            if (remaindata.remiancheck <= 0) {
                _this.lesssore = true;
            }
            _this.listview = false;
        });
    };
    TaxdefaultComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    TaxdefaultComponent.prototype.sendrequest = function (employeeid, id) {
        if (this.remainingcheck && this.remainingcheck.remiancheck < 1) {
            this.modalService.open('upgrade-plan');
        }
        else {
            this.modalService.open(id);
            this.employeeid = employeeid;
        }
    };
    TaxdefaultComponent.prototype.sendrequestcharge = function (employeeid, id) {
        var _this = this;
        var ts = { id: employeeid };
        this.taxService.gettaxdefaulterbyid(ts).subscribe(function (data) {
            if (data.remiancheck == 0) {
                _this.modalService.open('upgrade-plan');
            }
            else {
                _this.getRemainingCheck();
                _this.getRemainingChecks.emit();
                _this.modalService.open('max-defaulter-bussiness');
                _this.companytype = data;
            }
            _this.modalService.close(id);
        });
    };
    return TaxdefaultComponent;
}());
exports.TaxdefaultComponent = TaxdefaultComponent;
