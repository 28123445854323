"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var CountryService = /** @class */ (function () {
    function CountryService(commonService) {
        this.commonService = commonService;
    }
    CountryService.prototype.getcountrylist = function () {
        return this.commonService.formpostwithouttoken('/employee/countrylist', { id: 1 });
    };
    CountryService.prototype.getcountrylistbyid = function (data) {
        return this.commonService.formpost('/employee/countrybyid', data);
    };
    CountryService.prototype.getstatelist = function (data) {
        return this.commonService.formpost('/employee/statebyid', data);
    };
    CountryService.prototype.getsoclist = function () {
        return this.commonService.formpostOnlyid('/soclist');
    };
    CountryService.ngInjectableDef = i0.defineInjectable({ factory: function CountryService_Factory() { return new CountryService(i0.inject(i1.CommonService)); }, token: CountryService, providedIn: "root" });
    return CountryService;
}());
exports.CountryService = CountryService;
