import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { Visa } from '../../models/visa/visa.model';
import { Options,Referencesetting } from '../../models/reference/ReferenceDetails.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AmlService } from '../../services/aml.service';
import { Router } from '@angular/router';
import { ReferenceService } from '../../services/reference.service';
import { ChecksystemService } from '../../services/checksystem.service';
import { CommonService } from '../../services/common.service';
import { AlertService } from '../../services/alert.service';
import { EmployeeService } from '../../services/employee.service';
import { isThisMinute } from 'date-fns';
import { bool } from 'aws-sdk/clients/signer';



@Component({
  selector: 'app-refsetting',
  templateUrl: './refsetting.component.html',
  styleUrls: ['./refsetting.component.css']
})
export class RefsettingComponent implements OnInit {


  iseamil:boolean =false;
  issms:boolean =false;
  isemailtime:any;
  emailday:any;
  issmstime:any;
  smsday:any;
  addpopForm: FormGroup;
  submitted:boolean =false;
  ref = new Referencesetting();
  Message:any ='';
  smsdiv:boolean =false;
  emaildiv:boolean = false;
  hidediv:boolean =true;

  options = [
    new Options(0, 'Once' ),
    new Options(1, 'Everyday' ),
    new Options(2, 'Every Other Day' )  
 ];
  

  constructor(private check: ChecksystemService,
    private formBuilder: FormBuilder,private alerts: AlertService,
    private reference: ReferenceService ) { }

  ngOnInit() {

    this.addpopForm = this.formBuilder.group({
      isemail: [''],
      issms: [''],
      isemailtime: [''],
      emailday: [''],      
      issmstime: [''],
      smsday: ['']
    });

   

    
    this.reference.getrefereesetting().subscribe((referencelist: any) => { 

      if(referencelist)
      {
        this.ref.isemail  =referencelist.isemail;
        this.ref.issms =referencelist.issms;     


        if(referencelist.issms)
        this.smsdiv = false;
      else
        this.smsdiv = true;

        if(referencelist.isemail)
        this.emaildiv = false;
      else
        this.emaildiv = true;

        this.ref.isemailtime  =referencelist.isemailtime;
        this.ref.emailday =referencelist.emailday;
        this.ref.issmstime  =referencelist.issmstime;
        this.ref.smsday =referencelist.smsday;
    }
    });

  }

  counter(i: number) {
    return new Array(i);
}

hidedivform(){
  this.hidediv =false;
}

consertsms(values:any):void {  
  if(values.currentTarget.checked)
    this.smsdiv = false;
  else
    this.smsdiv = true;
}

consertemail(values:any):void {
  if(values.currentTarget.checked)
    this.emaildiv = false;
  else
    this.emaildiv = true;
}

onSubmit(refform,id)
{
  console.log('Ref Setting Details', refform);
  this.reference.postrefereesetting(refform).subscribe((referencelist: any) => { 

    this.Message = 'Notification setting updated successfully.'

    this.alerts.success('Notification setting updated successfully.', true);

    // this.ref.issmstime  =2;
    // this.ref.isemailtime =2;      
  });


}

}
