import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ModalService } from '../../../_services/modal.service';
import { JobService } from '../../../services/job.service';
import { CandidateService } from '../../../services/candidate.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Jobs } from '../../../models/jobs/jobs.model';
import { Candidate } from '../../../models/jobs/candidate.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';



@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {
  Userid: any;
  jobid: any;
  data: any;

  skillFormGroup: FormGroup;
  submitted: boolean;

  disableBtn: any;
  public searchText: string;
  textskill: string;
  
  constructor(private modalService: ModalService,private router: Router,private formBuilder: FormBuilder, private job: JobService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }


    this.submitted = false;
    
    this.skillFormGroup = this.formBuilder.group({
      jobid: ['', Validators.required],     
      skill: ['', Validators.required]
    });


    this.jobid = this.activatedRoute.snapshot.paramMap.get("jobid");
    this.Userid = localStorage.getItem('userid');
    this.job.getjobskillList({"id": this.Userid, "jobid": this.jobid}).subscribe((data: any) => {
      
      this.data = data;
    });
  }

  get f() { return this.skillFormGroup.controls; }


  onSubmit(skill,modelid)
  {
    // console.log(this.skillFormGroup);
    this.submitted = true;
    
    if (this.skillFormGroup.invalid) {
      return;
    }
    // var skill = skill.form.value;
    var value = [];
    var skillInsert = '';
    var skillCheck = false;
    var skillarray = skill.skill.split("\n");
    skillarray.forEach(element => {
      skillInsert = element.trim();
      skillCheck = false;
      if(skillInsert != '')
      {
        this.data.forEach(sk => {
          if(sk.skillname.toLowerCase() == skillInsert)
          {
            skillCheck = true;
          }
        });
        if(!skillCheck)
        {
          value.push({ 'skillname': skillInsert, 'jobid': skill.jobid });
        }
      }
    });
    this.disableBtn = false;
    this.job.addnewskill({'data': value}).subscribe((data: any) => {
      this.job.getjobskillList({"id": this.Userid, "jobid": this.jobid}).subscribe((data: any) => {
        // skill.resetForm();        
        this.data = data;
        this.modalService.close(modelid);
      });
    });
   
  }

  openModal2(id: string) 
  { 
    this.submitted = false;
    this.disableBtn = true;
    this.skillFormGroup.reset({"skill":"","jobid":this.jobid});
    this.modalService.open(id);
  }

  closeModal(id: string) 
  { 
    this.modalService.close(id);
  }

  onDelete(id: number) {
    const deleteData = { 'id': id };
    if (confirm("Are you sure you want to delete?")) {
      this.job.deleteskill(deleteData).subscribe((data: any) => {
        this.job.getjobskillList({"id": this.Userid, "jobid": this.jobid}).subscribe((data: any) => {
        
          this.data = data;
        });
      });
    }
  }

}
