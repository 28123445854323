"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var myidproof_service_1 = require("../../services/myidproof.service");
var country_service_1 = require("../../services/country.service");
var forms_1 = require("@angular/forms");
var idproof_model_1 = require("../../models/idproof/idproof.model");
var upload_file_service_1 = require("./../../services/upload-file.service");
var alert_service_1 = require("../../services/alert.service");
var ngx_webcam_1 = require("ngx-webcam");
var platform_browser_1 = require("@angular/platform-browser");
var rxjs_1 = require("rxjs");
var router_1 = require("@angular/router");
var checksystem_service_1 = require("../../services/checksystem.service");
var app_global_1 = require("../../app.global");
var myvisa_service_1 = require("../../services/myvisa.service");
var Subscription_1 = require("rxjs/Subscription");
require("rxjs/add/observable/timer");
var EmployeecheckdocumentComponent = /** @class */ (function () {
    function EmployeecheckdocumentComponent(formBuilder, uploadService, iddetails, visaService, datePipe, alerts, country, sanitizer, check, activatedRoute) {
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.iddetails = iddetails;
        this.visaService = visaService;
        this.datePipe = datePipe;
        this.alerts = alerts;
        this.country = country;
        this.sanitizer = sanitizer;
        this.check = check;
        this.activatedRoute = activatedRoute;
        this.wizard = 0;
        this.token_expired = false;
        this.is_rtw = 0;
        this.showloader = false;
        this.showWebcam = true;
        this.allowCameraSwitch = true;
        this.multipleWebcamsAvailable = false;
        this.videoOptions = {};
        this.errors = [];
        // latest snapshot
        this.webcamImage = null;
        // webcam snapshot trigger
        this.trigger = new rxjs_1.Subject();
        // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
        this.nextWebcam = new rxjs_1.Subject();
        this.filterItems = [
            {
                name: 'All Visa Details',
                id: 0,
                selected: true
            },
            {
                name: 'Accepted',
                id: 2,
                selected: false
            },
            {
                name: 'Rejected',
                id: 3,
                selected: false
            },
            {
                name: 'Waiting for review',
                id: 1,
                selected: false
            }
        ];
    }
    EmployeecheckdocumentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.submitted = false;
        this.disableBtn = true;
        this.buttoninsert = "Submit";
        this.idproofDetail = new idproof_model_1.Idproof();
        this.idproofpopForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            idnumber: ['', forms_1.Validators.required],
            idtype: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            docexpiry: ['', forms_1.Validators.required]
        });
        this.accesstoken = this.activatedRoute.snapshot.paramMap.get("access_token");
        this.activatedRoute.queryParams.subscribe(function (params) {
            _this.accesstoken = params['access_token'];
            _this.is_rtw = params['is_rtw'];
            localStorage.setItem('userToken', _this.accesstoken);
            _this.check.gettokensystem().subscribe(function (data) {
                if (data.sussess == 'true') {
                    localStorage.setItem('userid', data.userid);
                    localStorage.setItem('emptype', data.emptype);
                    localStorage.setItem('isline', data.isline);
                    localStorage.setItem('planid', data.planid);
                    localStorage.setItem('isemail', data.isemail);
                    localStorage.setItem('isadmin', data.isadmin);
                    localStorage.setItem('ispermission', data.ispermission);
                    localStorage.setItem('logoname', data.logourl);
                    localStorage.setItem('ctype', data.ctype);
                    var id = localStorage.getItem('userToken');
                    _this.Userid = localStorage.getItem('userid');
                    var data2 = { 'id': _this.Userid, 'cstatus': [1, 2, 3] };
                    if (data.logourl == "" || _this.logo == null) {
                        _this.logo = "assets/img/logo.png";
                    }
                    else {
                        _this.logo = data.logourl;
                    }
                    _this.idproofDetail.employeeid = data.userid;
                    _this.employeemasterid = data.userid;
                    _this.wizard = 0;
                    _this.idproofDetail.nationalityid = "";
                    _this.Userid = localStorage.getItem('userid');
                    var dd = localStorage.getItem('userid');
                    _this.idproofDetail.nationalityid = "";
                    _this.idproofDetail.employeeidproofdeatilid = "0";
                    _this.idproofDetail.idtype = 17;
                    _this.filename = "Choose file";
                    _this.profilepic = "Choose file";
                    _this.filebackname = 'Choose file';
                }
                else {
                    _this.token_expired = true;
                }
            });
        });
        this.getCountries();
    };
    EmployeecheckdocumentComponent.prototype.ngOnDestroy = function () {
        if (this.subscription && this.subscription instanceof Subscription_1.Subscription) {
            this.subscription.unsubscribe();
        }
    };
    EmployeecheckdocumentComponent.prototype.getCountries = function () {
        var _this = this;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
    };
    Object.defineProperty(EmployeecheckdocumentComponent.prototype, "f", {
        get: function () {
            return this.idproofpopForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    EmployeecheckdocumentComponent.prototype.toggle = function (id) {
        var _this = this;
        if (id == 1) {
            this.submitted = true;
            // console.log(this.idproofpopForm);
            if (this.idproofpopForm.invalid) {
                return;
            }
        }
        if (id == 2) {
            if (this.webcamImage) {
                var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
                setTimeout(function () { return _this.frontimage = imagepath; }, 5000);
                // console.log(this.imgsrc);
                this.webcamImage = null;
            }
        }
        else if (id == 3) {
            if (this.webcamImage) {
                var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
                setTimeout(function () { return _this.backimage = imagepath; }, 5000);
                // console.log(this.imgsrc);
                this.webcamImage = null;
            }
        }
        else if (id == 4) {
            var imagepath = this.uploadService.uploadfilebase64(this.webcamImage);
            setTimeout(function () { return _this.profileimage = imagepath; }, 5000);
            this.webcamImage = null;
            this.setTimer();
        }
        this.wizard = id;
    };
    EmployeecheckdocumentComponent.prototype.toggle2 = function (id) {
        // if(id == 1){
        //   this.wizard2 = false;
        //   this.wizard1 = true;
        //   this.wizard3 = false;
        // }
        // if(id == 2){
        //   this.wizard3 = false;
        //   this.wizard1 = false;
        //   this.wizard2 = true;
        // }
    };
    EmployeecheckdocumentComponent.prototype.onSubmit = function (value3, id, stype) {
        var _this = this;
        this.submitted = true;
        this.disableBtn = false;
        this.wizard = 6;
        value3.imagename = this.frontimage;
        value3.imagebackname = this.backimage;
        value3.idprofileimage = this.profileimage;
        value3.docexpiry = this.datePipe.transform(new Date(value3.docexpiry), "dd MMM yyyy");
        value3.isrighttowork = this.is_rtw;
        value3.stype = stype;
        value3.createby = this.Userid;
        this.iddetails.myidproofdetailsUpdate(value3).subscribe(function (datamain) {
            var data3 = { "employeeidproofdeatilid": datamain.employeeidproofdeatilid };
            _this.iddetails.idproofdetailsbyidproofid(data3).subscribe(function (data) {
                var countryiso = data.nationality.isothree;
                var idtype = 'DRIVING_LICENSE'; //data.documenttype.documentcode;     
                var visalog = {
                    employeeid: _this.employeemasterid,
                    documentid: datamain.employeeidproofdeatilid,
                    documenturl: value3.imagename,
                    documenttype: 'id'
                };
                _this.visaService.visaailogupdate(visalog).subscribe(function (dbvisaresult) {
                    if (dbvisaresult.success == '1') {
                        var data = {
                            merchantIdScanReference: dbvisaresult.logid,
                            country: countryiso,
                            idType: idtype,
                            customerId: _this.employeemasterid,
                            is_rtw: _this.is_rtw,
                            callbackUrl: app_global_1.AppGlobals.API_ENDPOINT + "/api/aiconfirmation",
                            documenturl: value3.imagename,
                            documentbackurl: value3.imagebackname,
                            imageurl: value3.idprofileimage
                        };
                        _this.visaService.visadocumentupdate(data).subscribe(function (data5) {
                            if (data5.success == true) {
                                _this.check.deleteAccessToken().subscribe(function (tokenData) {
                                    if (tokenData.success) {
                                        localStorage.removeItem('userToken');
                                        localStorage.removeItem('userid');
                                        localStorage.removeItem('emptype');
                                        localStorage.removeItem('isline');
                                        localStorage.removeItem('planid');
                                        localStorage.removeItem('isemail');
                                        localStorage.removeItem('isadmin');
                                        localStorage.removeItem('ispermission');
                                        localStorage.removeItem('logoname');
                                        localStorage.removeItem('ctype');
                                        _this.wizard = 5;
                                    }
                                });
                            }
                            else {
                                _this.wizard = 4;
                                _this.alerts.error("something went wrong please try again");
                            }
                        });
                    }
                    else {
                        _this.wizard = 4;
                    }
                });
            });
        });
    };
    EmployeecheckdocumentComponent.prototype.setTimer = function () {
        var _this = this;
        this.showloader = true;
        this.timer = rxjs_1.Observable.timer(5000);
        this.subscription = this.timer.subscribe(function () {
            _this.showloader = false;
        });
    };
    EmployeecheckdocumentComponent.prototype.sanitize = function (url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    };
    EmployeecheckdocumentComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.fileformaterror = true;
        }
        else {
            this.fileformaterror = false;
            this.filesizeerror = false;
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.imgURL = reader.result;
            };
            // this.selectedFiles = files[0]; 
        }
    };
    EmployeecheckdocumentComponent.prototype.selectbackFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filebackname = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filebacksizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.filebackformaterror = true;
            this.filebacksizeerror = false;
        }
        else {
            this.filebackformaterror = false;
            this.filebacksizeerror = false;
            this.selectedbackFiles = files[0];
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.imgbackURL = reader.result;
            };
        }
    };
    EmployeecheckdocumentComponent.prototype.selectprofile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.profilepic = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.pro_pic_size_error = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.pro_pic_format_error = true;
            this.pro_pic_size_error = false;
        }
        else {
            this.pro_pic_format_error = false;
            this.pro_pic_size_error = false;
            this.selected_profile_image = files[0];
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.profile_pic_url = reader.result;
            };
        }
    };
    EmployeecheckdocumentComponent.prototype.triggerSnapshot = function () {
        this.trigger.next();
    };
    EmployeecheckdocumentComponent.prototype.triggerSnapshotreload = function () {
        this.webcamImage = null;
        //this.trigger.next();
    };
    EmployeecheckdocumentComponent.prototype.toggleWebcam = function () {
        this.showWebcam = !this.showWebcam;
    };
    EmployeecheckdocumentComponent.prototype.handleInitError = function (error) {
        this.errors.push(error);
    };
    EmployeecheckdocumentComponent.prototype.showNextWebcam = function (directionOrDeviceId) {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    };
    EmployeecheckdocumentComponent.prototype.handleImage = function (webcamImage) {
        console.info('received webcam image', webcamImage);
        // var imagepath = this.uploadService.uploadfilebase64(webcamImage);
        // this.imgsrc = imagepath;
        // console.log(imagepath);
        // let reader = new FileReader();
        //     // handle data
        //     reader.onload = event => {       
        //       console.log(event.target)
        //       if (event.target.result) {
        //         this.file.base64String = event.target.result;  
        //       }
        //     };
        //     reader.readAsDataURL(blob);
        // console.log(this.imgsrc);
        this.webcamImage = webcamImage;
    };
    EmployeecheckdocumentComponent.prototype.cameraWasSwitched = function (deviceId) {
        console.log('active device: ' + deviceId);
        this.deviceId = deviceId;
    };
    Object.defineProperty(EmployeecheckdocumentComponent.prototype, "triggerObservable", {
        get: function () {
            return this.trigger.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeecheckdocumentComponent.prototype, "nextWebcamObservable", {
        get: function () {
            return this.nextWebcam.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return EmployeecheckdocumentComponent;
}());
exports.EmployeecheckdocumentComponent = EmployeecheckdocumentComponent;
