import { Component, OnInit } from '@angular/core';
import { WorklistService } from './../../services/worklist.service';
import { ModalService } from '../../_services/modal.service';
import { AppGlobals } from '../../app.global';

@Component({
  selector: 'app-worklistreport',
  templateUrl: './worklistreport.component.html',
  styleUrls: ['./worklistreport.component.css']
})

export class WorklistreportComponent implements OnInit {
  items: Array<any>;
  pageOfItems: Array<any>;

  notedetails: any;
  data: any[];
  Userid: any;
  month: any;
  year: any;
  yearmonth: any;
  classactive: string;
  record: any;
  monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];
  yearList: any;

  constructor(private dataset: WorklistService, private modalService: ModalService) { }

  ngOnInit() {
    this.yearList = AppGlobals.YEAR_VALUES;
    this.month = (new Date()).getMonth();
    console.log(this.month);
    this.year = (new Date()).getFullYear();
    this.yearmonth = this.monthNames[this.month] + ' ' + this.year;
    this.classactive = ((new Date()).getMonth() + 1).toString();
    this.bindalldata();
  }

  bindalldata() {
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    this.dataset.getemployeeworklistreport(data).subscribe((data: any) => {
      this.record = data.length;
      if (this.record < 30) {
        this.pageOfItems = data;
      }
      else {
        this.items = data;
      }
      // this.data = data;
    });
  }

  onyearSubmit(year) {
    this.year = year;
    var month = this.month;
    console.log(month);
    this.yearmonth = this.monthNames[month] + ' ' + this.year;
    this.bindalldata();
    // this.modalService.close(id);
  }

  yearpop(id: string) {
    this.modalService.open(id);
  }

  getDataByMonth(mon) {
    // console.log(this.year);
    this.classactive = mon;
    this.month = mon - 1;
    this.yearmonth = this.monthNames[this.month] + ' ' + this.year;
    this.bindalldata();
    // const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    // this.dataset.getemployeeworklistreport(data).subscribe((data: any) => {
    //  this.data = data;
    // });
  }

  openModalnote(data: any, id: any) {
    this.notedetails = data;
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  getDataByYear(yea) {
    this.year = yea;
    this.yearmonth = this.monthNames[this.month - 1] + ' ' + yea;
    this.bindalldata();
    // const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    // this.dataset.getemployeeworklistreport(data).subscribe((data: any) => {
    //  this.data = data;
    // });
  }
}