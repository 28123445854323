"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".declinereferencerequest[_ngcontent-%COMP%]{padding:30px}.declinereferencerequest[_ngcontent-%COMP%]   h4[_ngcontent-%COMP%]{margin:20px 20px 20px 0}.centeraligndecline[_ngcontent-%COMP%]{margin-right:0;height:calc(100vh - 70px);display:flex;align-items:center}.declogo[_ngcontent-%COMP%]{text-align:center;margin-bottom:20px}\n/*# sourceMappingURL=userdeclinereference.component.css.map*/"];
exports.styles = styles;
