"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../_services/modal.service");
var aml_model_1 = require("../../../models/aml/aml.model");
var forms_1 = require("@angular/forms");
var aml_service_1 = require("../../../services/aml.service");
var router_1 = require("@angular/router");
var PersonalcreditComponent = /** @class */ (function () {
    function PersonalcreditComponent(modalService, formBuilder, amldetails, router) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.amldetails = amldetails;
        this.router = router;
        this.businessDetail = new aml_model_1.Aml();
        this.defaultVal = '';
        this.tc = true;
    }
    PersonalcreditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.error = false;
        this.disableBtn = true;
        this.errormsg = '';
        this.buttoninsert = "Search";
        this.result = false;
        this.defaultVal = '';
        this.personalformGroup = this.formBuilder.group({
            Forename: ['', forms_1.Validators.required],
            Country: ['', forms_1.Validators.required],
            Middlename: [''],
            Threshold: [''],
            Surname: [''],
            Postcode: [''],
            Address: [''],
            DateOfBirth: [''],
            YearOfBirth: [''],
            County: [''],
            City: [''],
            PEP: [''],
            PreviousSanctions: [''],
            CurrentSanctions: [''],
            LawEnforcement: [''],
            FinancialRegulator: [''],
            Insolvency: [''],
            DisqualifiedDirector: [''],
            AdverseMedia: ['']
        });
        this.Userid = localStorage.getItem('userid');
        // this.tc =false;
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.assetperm = this.permission.m16;
        var personalid = localStorage.getItem('personalid');
        if (!personalid && personalid != null) {
            this.router.navigate(['/creditsearchresult', personalid]);
        }
        this.amldetails.getCountries().subscribe(function (countrydata) {
            // console.log(countrydata);
            _this.countrylist = countrydata;
        });
    };
    Object.defineProperty(PersonalcreditComponent.prototype, "f", {
        get: function () { return this.personalformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    PersonalcreditComponent.prototype.selectall = function (val) {
        this.businessDetail.PEP = true;
        this.businessDetail.CurrentSanctions = true;
        this.businessDetail.DisqualifiedDirector = true;
        this.businessDetail.FinancialRegulator = true;
        this.businessDetail.Insolvency = true;
        this.businessDetail.LawEnforcement = true;
        this.businessDetail.AdverseMedia = true;
        this.businessDetail.PreviousSanctions = true;
    };
    PersonalcreditComponent.prototype.dataset = function (values) {
        this.datasetlist = "";
        if (values.isPEP == true) {
            this.datasetlist = "PEP,";
        }
        if (values.isAdverseMedia == true) {
            this.datasetlist += " Adverse Media,";
        }
        if (values.isDisqualifiedDirector == true) {
            this.datasetlist += " Disqualified Director (UK Only),";
        }
        if (values.isFinancialregulator == true) {
            this.datasetlist += " Financial Regulator,";
        }
        if (values.isInsolvent == true) {
            this.datasetlist += " Insolvency (UK & Ireland),";
        }
        if (values.isLawEnforcement == true) {
            this.datasetlist += " Law Enforcement,";
        }
        if (values.isSanctionsCurrent == true) {
            this.datasetlist += " Sanction - Current,";
        }
        if (values.isSanctionsPrevious == true) {
            this.datasetlist += " Sanction - Previous,";
        }
        this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1);
    };
    PersonalcreditComponent.prototype.clearpersonal = function () {
        this.personalformGroup.reset();
        this.businessDetail = new aml_model_1.Aml();
        this.businessDetail.Forename = "";
        this.businessDetail.Country = "";
        this.businessDetail.Middlename = "";
        this.businessDetail.Threshold = "";
        this.businessDetail.Surname = "";
        this.businessDetail.Postcode = "";
        this.businessDetail.Address = "";
        this.businessDetail.DateOfBirth = "";
        this.businessDetail.YearOfBirth = "";
        this.businessDetail.County = "";
        this.businessDetail.City = "";
        this.businessDetail.PEP = false;
        this.businessDetail.CurrentSanctions = false;
        this.businessDetail.DisqualifiedDirector = false;
        this.businessDetail.FinancialRegulator = false;
        this.businessDetail.Insolvency = false;
        this.businessDetail.LawEnforcement = false;
        this.businessDetail.AdverseMedia = false;
        this.businessDetail.PreviousSanctions = false;
    };
    PersonalcreditComponent.prototype.oldsearch = function () {
    };
    PersonalcreditComponent.prototype.onSubmit = function (val, type) {
        var _this = this;
        this.error = false;
        this.submitted = true;
        if (this.personalformGroup.invalid) {
            return;
        }
        if (val.PEP == false && val.PreviousSanctions == false && val.CurrentSanctions == false && val.LawEnforcement == false && val.FinancialRegulator == false && val.Insolvency == false && val.DisqualifiedDirector == false && val.AdverseMedia == false) {
            this.error = true;
            return;
        }
        if (type == 'first') {
            this.modalService.open('pdf-payment-confirmation');
            this.disableBtn = true;
        }
        else if (type == 'payment') {
            this.amldetails.getPersonalSearch(val).subscribe(function (searchRes) {
                _this.errormsg = '';
                if (searchRes.error == '') {
                    if (searchRes.recordsFound > 0) {
                        _this.result = true;
                    }
                    _this.disableBtn = true;
                    localStorage.setItem('personalid', searchRes.id);
                    _this.router.navigate(['/creditsearchresult', searchRes.id]);
                    _this.searchresult = searchRes;
                }
                else if (searchRes.error == 'amlfail') {
                    _this.errormsg = 'We have some issue in AML & PEPs check. Please contact Administrator';
                }
                else if (searchRes.error == 'paymentfail') {
                    _this.errormsg = 'Your payment fail. Please contact Administrator';
                }
            });
        }
    };
    PersonalcreditComponent.prototype.openModal = function (id, data) {
        this.bussinessdetails = data;
        // console.log(data);
        this.buttoninsert = "Update";
        this.modalService.open(id);
    };
    PersonalcreditComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return PersonalcreditComponent;
}());
exports.PersonalcreditComponent = PersonalcreditComponent;
