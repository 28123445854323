"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_service_1 = require("../../../services/employee.service");
var modal_service_1 = require("../../../_services/modal.service");
var profile_model_1 = require("../../../models/overview/profile.model");
var country_service_1 = require("../../../services/country.service");
var common_service_1 = require("../../../services/common.service");
var job_service_1 = require("../../../services/job.service");
var candidate_service_1 = require("../../../services/candidate.service");
var user_service_1 = require("../../../services/user.service");
var permission_service_1 = require("../../../services/permission.service");
var personaltype_service_1 = require("../../../services/personaltype.service");
var company_service_1 = require("../../../services/company.service");
var alert_service_1 = require("../../../services/alert.service");
var checksystem_service_1 = require("../../../services/checksystem.service");
var router_1 = require("@angular/router");
var CheckaddemployeeComponent = /** @class */ (function () {
    function CheckaddemployeeComponent(userService, employeeService, country, modalService, commonService, formBuilder, jobdetails, candidatedetails, permissiondetails, dltypeService, companyService, alerts, check, route, router) {
        var _this = this;
        this.userService = userService;
        this.employeeService = employeeService;
        this.country = country;
        this.modalService = modalService;
        this.commonService = commonService;
        this.formBuilder = formBuilder;
        this.jobdetails = jobdetails;
        this.candidatedetails = candidatedetails;
        this.permissiondetails = permissiondetails;
        this.dltypeService = dltypeService;
        this.companyService = companyService;
        this.alerts = alerts;
        this.check = check;
        this.route = route;
        this.router = router;
        this.mindob = new Date();
        this.maxdob = new Date();
        this.emailexist = false;
        this.empidexist = false;
        this.isFocus = true;
        route.params.subscribe(function (params) {
            _this.emptype = params['type'];
            console.log(_this.emptype);
        });
    }
    CheckaddemployeeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.disableBtn = true;
        this.defaultVal = '';
        this.username = '';
        this.employeeForm = this.formBuilder.group({
            fname: ['', forms_1.Validators.required],
            mname: [''],
            lname: ['', forms_1.Validators.required],
            // username: ['', Validators.required],
            empid: [''],
            gender: ['', forms_1.Validators.required],
            maritalstatus: [''],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            phonenumber: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            nationalityid: [''],
            residenceid: [''],
            dateofbirth: [''],
            hiredate: [''],
            emprole: ['']
        });
        this.mindob.setDate(this.mindob.getDate() - 23741);
        this.maxdob.setDate(this.maxdob.getDate() - 574);
        this.permissiondetails.bindallmenu().subscribe(function (menudata) {
            var permissionstring = "[{";
            var perarray = [];
            menudata.forEach(function (valuemenu) {
                perarray.push('"' + valuemenu.clname + '": 0');
            });
            var perstr = perarray.join();
            permissionstring = permissionstring + perstr + "}]";
            _this.permissionstring = permissionstring;
        });
        this.overviewData = new profile_model_1.Profile();
        this.overviewData.gender = "1";
        this.overviewData.maritalstatus = "0";
        this.phonecheck = "";
        this.overviewData.jobid = "";
        this.overviewData.candidateid = "";
        this.overviewData.nationalityid = "";
        this.overviewData.residenceid = "";
        this.overviewData.phonenumber = "+44";
        this.overviewData.countryofbirthid = "";
        this.overviewData.ismail = false;
        this.isFocus = false;
    };
    Object.defineProperty(CheckaddemployeeComponent.prototype, "f", {
        get: function () { return this.employeeForm.controls; },
        enumerable: true,
        configurable: true
    });
    CheckaddemployeeComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    CheckaddemployeeComponent.prototype.onFocusOutEvent = function (event) {
        var _this = this;
        this.userService.isCompanyEmailRegisterd(event.target.value).subscribe(function (data) {
            if (data.sussess == 'success') {
                _this.emailexist = true;
                _this.disableBtn = false;
                _this.isFocus = true;
                _this.empleeid = data.employeeid;
                _this.modalService.open('alreadyadd-request');
            }
            else {
                _this.disableBtn = true;
            }
        });
    };
    CheckaddemployeeComponent.prototype.onUserNameOutEvent = function (event) {
        var _this = this;
        if (event.target.value == '') {
            this.username = '';
        }
        else {
            console.log('rahul kumar tanwar');
            this.username = '';
            var dbvalue = { 'username': event.target.value };
            this.userService.isUserRegisterd(event.target.value).subscribe(function (data) {
                // this.userService.isUserRegisterd(event.target.value).subscribe((data2: any) => {
                if (data.sussess == 'false') {
                    _this.username = 'false';
                }
                else {
                    _this.username = 'true';
                }
            });
        }
    };
    CheckaddemployeeComponent.prototype.focusFunction = function () {
        this.emailexist = false;
    };
    CheckaddemployeeComponent.prototype.onAddEmployeeSubmit = function (employeeform1, modelid) {
        var _this = this;
        this.submitted = true;
        if (this.employeeForm.invalid) {
            return;
        }
        this.disableBtn = false;
        employeeform1.id = this.Userid;
        employeeform1.permission = this.permissionstring;
        // this.userService.isCompanyEmailRegisterd(employeeform1.emailid).subscribe((data2: any) => {
        //   if (data2.sussess == 'success') {
        //     this.username = 'true';
        //     this.disableBtn = true;
        //   }else{
        //     this.username = '';
        this.userService.isCompanyEmailRegisterd(employeeform1.emailid).subscribe(function (data) {
            if (data.sussess == 'success') {
                _this.emailexist = true;
                _this.disableBtn = true;
            }
            else {
                _this.disableBtn = false;
                _this.employeeService.checkemployeecreate(employeeform1).subscribe(function (dataw) {
                    if (dataw.susses == 'true') {
                        if (_this.emptype == 'visa') {
                            _this.router.navigate(['/check-ai-visa-admin', dataw.employeemasterid]);
                        }
                        else if (_this.emptype == 'passport') {
                            _this.router.navigate(['/check-ai-passport-admin', dataw.employeemasterid]);
                        }
                        else if (_this.emptype == 'document') {
                            _this.router.navigate(['/check-ai-document-admin', dataw.employeemasterid]);
                        }
                        else if (_this.emptype == 'basic-dbs') {
                            _this.router.navigate(['/dbs/basic']);
                        }
                        else if (_this.emptype == 'standard-dbs') {
                            _this.router.navigate(['/dbs/standard']);
                        }
                        else if (_this.emptype == 'enhanced-dbs') {
                            _this.router.navigate(['/dbs/enhanced']);
                        }
                    }
                    else {
                        _this.alerts.error('Somthing went wrong. please try again after sometime');
                    }
                });
            }
        });
        //   }
        // });
    };
    CheckaddemployeeComponent.prototype.addbio = function () {
        if (this.emptype == 'visa') {
            this.router.navigate(['/check-ai-visa-admin', this.empleeid]);
        }
        else if (this.emptype == 'passport') {
            this.router.navigate(['/check-ai-passport-admin', this.empleeid]);
        }
        else if (this.emptype == 'document') {
            this.router.navigate(['/check-ai-document-admin', this.empleeid]);
        }
        else if (this.emptype == 'basic-dbs') {
            this.router.navigate(['/dbs/basic']);
        }
        else if (this.emptype == 'standard-dbs') {
            this.router.navigate(['/dbs/standard']);
        }
        else if (this.emptype == 'enhanced-dbs') {
            this.router.navigate(['/dbs/enhanced']);
        }
    };
    CheckaddemployeeComponent.prototype.sendrequest = function (employeeid, id) {
        var _this = this;
        var ts = { employeeid: employeeid, requesttype: this.emptype };
        this.check.genratetokenforemployee(ts).subscribe(function (remaindata) {
            _this.alerts.success('Application Request Sent Successfully');
            _this.modalService.close(id);
        });
    };
    CheckaddemployeeComponent.prototype.closeModal = function (id) {
        this.disableBtn = true;
        this.overviewData.email = '';
        this.modalService.close(id);
    };
    return CheckaddemployeeComponent;
}());
exports.CheckaddemployeeComponent = CheckaddemployeeComponent;
