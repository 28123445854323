"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var reference_service_1 = require("../../services/reference.service");
var company_service_1 = require("../../services/company.service");
var helper_service_1 = require("../../services/helper.service");
var common_service_1 = require("../../services/common.service");
var checksystem_service_1 = require("../../services/checksystem.service");
// import {} from 'rxjs';
var UseraddreferenceComponent = /** @class */ (function () {
    function UseraddreferenceComponent(formBuilder, commonService, activatedRoute, referencingmoduleService, company, helperService, check, zone) {
        this.formBuilder = formBuilder;
        this.commonService = commonService;
        this.activatedRoute = activatedRoute;
        this.referencingmoduleService = referencingmoduleService;
        this.company = company;
        this.helperService = helperService;
        this.check = check;
        this.zone = zone;
        this.isvalidemail = true;
        this.token_expired = false;
        this.submit = false;
        this.dateToday = new Date();
        this.fnlast = false;
        this.btndisable = false;
        this.details = {};
        // this.helperService.determineLocalIp();
        this.steps = [
            { name: 'Reference Request', icon: 'fa-info-circle', active: true, valid: false, hasError: false },
            { name: 'Referee Submission', icon: 'fa-user-o', active: false, valid: false, hasError: false },
            { name: 'Done' }
        ];
    }
    UseraddreferenceComponent.prototype.ngOnInit = function () {
        //this.determineLocalIp() 
        // this.helperService.determineLocalIp();  
        var _this = this;
        // console.log(this.activatedRoute.snapshot.queryParams['token']);
        // console.log(this.activatedRoute.snapshot.queryParams['cd']);
        // console.log(this.activatedRoute.snapshot.queryParams['ud']);
        // console.log(this.activatedRoute.snapshot.queryParams['rd']);
        this.check.getIPAddress().subscribe(function (data) {
            console.log('system IP address', data.ip);
            sessionStorage.setItem('LOCAL_IP', data.ip);
        });
        this.token = this.activatedRoute.snapshot.queryParams['token'];
        this.rd = this.activatedRoute.snapshot.queryParams['rd'];
        this.cd = this.activatedRoute.snapshot.queryParams['cd'];
        localStorage.setItem('userToken1', this.token);
        this.check.gettokensystem().subscribe(function (data) {
            console.log(data);
            if (data.sussess == 'true') {
                // localStorage.setItem('refereeuser', data);
                // localStorage.setItem('userid', data.userid);
                // localStorage.setItem('emptype', data.emptype);
                // localStorage.setItem('isline', data.isline);
                // localStorage.setItem('planid', data.planid);
                // localStorage.setItem('isemail', data.isemail);
                // localStorage.setItem('isadmin', data.isadmin);
                // localStorage.setItem('ispermission', data.ispermission);
                // localStorage.setItem('logoname', data.logourl);
                // localStorage.setItem('ctype', data.ctype);
                // const id = localStorage.getItem('userToken');
                // this.Userid = localStorage.getItem('userid');
                _this.Userid = data.userid;
                if (data.logourl == "" || data.logourl == null) {
                    _this.logo = "assets/img/logo.png";
                }
                else {
                    _this.logo = data.logourl;
                }
                _this.employeemasterid = data.userid;
            }
            else {
                _this.token_expired = true;
            }
        });
        this.referencing = 0;
        this.addconfirmbutton = 'Save Referee';
        this.refereesFormGroup = this.formBuilder.group({
            candidateip: null,
            referencerequestid: null,
            token: null,
            companyid: null,
            userid: null,
            referencerequest: '',
            candidatename: '',
            candidateemail: '',
            companyname: '',
            refereename: ['', [forms_1.Validators.required, forms_1.Validators.pattern('^[a-zA-Z ]*$')]],
            refereedesignation: ['', [forms_1.Validators.required]],
            refereeemail: ['', [forms_1.Validators.required, forms_1.Validators.email]],
            refereephone: ['', [forms_1.Validators.required, forms_1.Validators.pattern("^[\+0-9]*$")]],
            refereecompanyname: ['', [forms_1.Validators.required]],
            candidatejobtitle: ['', [forms_1.Validators.required]],
            jobstartdate: [null, [forms_1.Validators.required]],
            jobenddate: '',
            currentjob: '',
            isreadonly: '',
            status: '',
            createdon: new Date(),
            candidatemac: this.token
        }); //, { validator: this.dateLessThan('jobstartdate', 'jobenddate') });
        this.loadData();
        this.intrefereefilleddetailcount = 1;
        this.createrefereerequest = [];
        localStorage.setItem('addrefereeseq', this.token);
    };
    UseraddreferenceComponent.prototype.consertemail = function (values) {
        if (values.currentTarget.checked)
            this.fnlast = true;
        else
            this.fnlast = false;
    };
    Object.defineProperty(UseraddreferenceComponent.prototype, "refree", {
        get: function () {
            return this.refereesFormGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    UseraddreferenceComponent.prototype.consert = function (values) {
        console.log(values.currentTarget.checked);
        if (values.currentTarget.checked)
            this.btndisable = true;
        else
            this.btndisable = false;
    };
    UseraddreferenceComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
        });
    };
    //referencerequestinfo:any;
    //referencecompanyinfo:any;
    UseraddreferenceComponent.prototype.loadData = function () {
        // this.referencingmoduleService.getip().subscribe((response: any)=>{
        //   this.candidateip = response;     
        // });
        var _this = this;
        this.referencingmoduleService.getdesignations().subscribe(function (response) {
            _this.getdesignations = response;
        });
        var data = { token: this.token, rd: this.rd };
        this.referencingmoduleService.getreferencerequest(this.rd).subscribe(function (response) {
            _this.referencerequestinfo = response[0];
            _this.refereefilleddetailcount = _this.referencerequestinfo.numberofreferee;
            //this.addconfirmbutton='Submit';
            //this.selectedtemplate = response[0].id;
            //this.referencingmoduleService.getquestiontemplatedetails(this.questionTemplates[0].id).subscribe((response1)=>{
            //    this.questionTemplatesdetails = response1;
            //  })
        });
        var data1 = { token: this.token, cd: this.cd };
        this.company.getcompanydetailsbyid({ token: this.token }).subscribe(function (response) {
            _this.referencecompanyinfo = response;
            //this.selectedtemplate = response[0].id;
            //this.referencingmoduleService.getquestiontemplatedetails(this.questionTemplates[0].id).subscribe((response1)=>{
            //    this.questionTemplatesdetails = response1;
            //  })
        });
        this.referencingmoduleService.getrefereedetail('referencerequestid,' + this.rd).subscribe(function (response) {
            _this.refereedetailinfo = response;
            _this.intrefereefilleddetailcount += response.length;
            for (var i = 0; i < response.length; i++) {
                if (response.isresponded != null || response.isresponded != 1)
                    _this.referencing += 1;
            }
            for (var i = 0; i < _this.refereedetailinfo.length; i++) {
                if (_this.refereedetailinfo[i].jobstartdate) {
                    _this.refereedetailinfo[i].jobstartdate = _this.refereedetailinfo[i].jobstartdate.split('T')[0];
                }
                if (_this.refereedetailinfo[i].jobenddate) {
                    _this.refereedetailinfo[i].jobenddate = _this.refereedetailinfo[i].jobenddate.split('T')[0];
                }
            }
            //this.selectedtemplate = response[0].id;
            //this.referencingmoduleService.getquestiontemplatedetails(this.questionTemplates[0].id).subscribe((response1)=>{
            //    this.questionTemplatesdetails = response1;
            //  })
        });
        console.log(this.steps);
    };
    UseraddreferenceComponent.prototype.next = function () {
        var accountForm = this.accountForm;
        var personalForm = this.personalForm;
        var paymentForm = this.paymentForm;
        if (this.steps[this.steps.length - 1].active)
            return false;
        this.steps.some(function (step, index, steps) {
            if (index < steps.length - 1) {
                if (step.active) {
                    if (step.name == 'Reference Request') {
                        step.active = false;
                        step.valid = true;
                        steps[index + 1].active = true;
                        return true;
                    }
                    if (step.name == 'Referee Submission') {
                        step.active = false;
                        step.valid = true;
                        steps[index + 1].active = true;
                        return true;
                    }
                    if (step.name == 'Done') {
                        step.active = false;
                        step.valid = true;
                        steps[index + 1].active = true;
                        return true;
                    }
                }
            }
        });
    };
    UseraddreferenceComponent.prototype.confirm = function () {
        var _this = this;
        this.submit = true;
        console.log(this.refereesFormGroup);
        if (this.refereesFormGroup.invalid) {
            return;
        }
        if (this.intrefereefilleddetailcount == this.refereefilleddetailcount) {
            this.steps[0].active = true;
        }
        if (this.isvalidemail)
            if (this.intrefereefilleddetailcount < this.refereefilleddetailcount) {
                this.intrefereefilleddetailcount += 1;
                this.intrefereefilleddetailcount == this.refereefilleddetailcount ? this.addconfirmbutton = 'Done' : null;
                // if(this.intrefereefilleddetailcount  == this.refereefilleddetailcount){
                //   this.steps[0].active = false;
                // }
                this.refereesFormGroup.value.companyid = this.cd;
                this.refereesFormGroup.value.referencerequestid = this.rd;
                this.refereesFormGroup.value.candidatename = this.referencerequestinfo.candidatename;
                this.refereesFormGroup.value.candidateemail = this.referencerequestinfo.candidateemail;
                this.refereesFormGroup.value.companyname = this.referencecompanyinfo.companyname;
                this.refereesFormGroup.value.candidateip = sessionStorage.getItem('LOCAL_IP');
                this.refereesFormGroup.value.createdon = new Date();
                this.refereesFormGroup.value.candidatemac = this.token;
                this.createrefereerequest.push(this.refereesFormGroup.value);
                var sd = new Date(this.refereesFormGroup.value.jobstartdate);
                this.refereesFormGroup.value.jobstartdate = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
                if (this.refereesFormGroup.value.jobenddate) {
                    var ed = new Date(this.refereesFormGroup.value.jobenddate);
                    this.refereesFormGroup.value.jobenddate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
                }
                console.log('Start Date');
                console.log('End Date');
                this.referencingmoduleService.createreferee(this.createrefereerequest).subscribe(function (response) {
                    console.log(response);
                });
                this.refereesFormGroup.reset();
                this.submit = false;
                this.fnlast = false;
                this.createrefereerequest = [];
                // if(this.intrefereefilleddetailcount  == this.refereefilleddetailcount){
                //   this.steps[2].active = true;
                //   this.steps[2].valid = true;
                // }
            }
            else {
                // this.addItem();
                this.refereesFormGroup.value.companyid = this.cd;
                this.refereesFormGroup.value.referencerequestid = this.rd;
                this.refereesFormGroup.value.candidatename = this.referencerequestinfo.candidatename;
                this.refereesFormGroup.value.candidateemail = this.referencerequestinfo.candidateemail;
                this.refereesFormGroup.value.companyname = this.referencecompanyinfo.companyname;
                this.refereesFormGroup.value.candidateip = sessionStorage.getItem('LOCAL_IP');
                this.refereesFormGroup.value.createdon = new Date();
                this.refereesFormGroup.value.candidatemac = this.token;
                this.createrefereerequest.push(this.refereesFormGroup.value);
                this.steps.forEach(function (step) { return step.valid = false; });
                this.steps.forEach(function (step) { return step.active = false; });
                //console.log(JSON.stringify(this.createrefereerequest.value));
                this.addconfirmbutton = 'Submited';
                var sd = new Date(this.refereesFormGroup.value.jobstartdate);
                this.refereesFormGroup.value.jobstartdate = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
                if (this.refereesFormGroup.value.jobenddate) {
                    var ed = new Date(this.refereesFormGroup.value.jobenddate);
                    this.refereesFormGroup.value.jobenddate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
                }
                console.log('Start Date');
                console.log('End Date');
                //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.createrefereerequest, null, 4));
                this.referencingmoduleService.createreferee(this.createrefereerequest).subscribe(function (response) {
                    _this.steps[2].active = true;
                    _this.steps[2].valid = true;
                    _this.fnlast = false;
                    console.log(response);
                });
                this.createrefereerequest = [];
                this.submit = false;
                // this.next()
            }
    };
    UseraddreferenceComponent.prototype.dateLessThan = function (from, to) {
        return function (group) {
            var f = group.controls[from];
            var t = group.controls[to];
            if (f.value > t.value) {
                return {
                    dates: "Finish Date should be greater than start date"
                };
            }
            return {};
        };
    };
    // addItem(): void {
    //     this.items = this.refereesFormGroup.get('items') as FormArray;
    //     this.items.push(this.createItem());
    //   }
    UseraddreferenceComponent.prototype.goToTC = function () {
        window.open("http://localhost:4200/termandconditionrefmod", "_blank");
    };
    UseraddreferenceComponent.prototype.goToPP = function () {
        window.open("http://localhost:4200/privacypolicyrefmod", "_blank");
    };
    UseraddreferenceComponent.prototype.endDateValidation = function (date) {
        var d1 = new Date("12/12/2017");
        var d2 = new Date(date);
        alert(date);
    };
    UseraddreferenceComponent.prototype.validateemail = function () {
        this.isvalidemail = true;
        // this.check.validateemail(this.refereesFormGroup.value.refereeemail).subscribe((response) => {
        //   if(response.status === "valid")
        //   this.isvalidemail = true;
        //   else
        //   this.isvalidemail = false;
        // })
    };
    return UseraddreferenceComponent;
}());
exports.UseraddreferenceComponent = UseraddreferenceComponent;
