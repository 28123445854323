"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var DbsService = /** @class */ (function () {
    function DbsService(commonService) {
        this.commonService = commonService;
    }
    DbsService.prototype.createdbs = function (data) {
        return this.commonService.formpost('/check/createdbsrecord', data);
    };
    DbsService.prototype.getalldbsListbyadmin = function (value) {
        return this.commonService.formpost('/dbs/allemployeedbslistbyadmin', value);
    };
    DbsService.ngInjectableDef = i0.defineInjectable({ factory: function DbsService_Factory() { return new DbsService(i0.inject(i1.CommonService)); }, token: DbsService, providedIn: "root" });
    return DbsService;
}());
exports.DbsService = DbsService;
