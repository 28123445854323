import { AlertService } from '../services/alert.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

@Component({
    selector: 'app-alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;
    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe(message => {
            this.message = message;
            setTimeout(()=>{
                this.message = '';
              }, 3000);
        }); 
    }
    onclose(){
        this.message = '';
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}