"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var worklist_service_1 = require("./../../../services/worklist.service");
var modal_service_1 = require("../../../_services/modal.service");
var customer_service_1 = require("../../../services/customer.service");
var router_1 = require("@angular/router");
var app_global_1 = require("../../../app.global");
var AdmintimesheetrequestComponent = /** @class */ (function () {
    function AdmintimesheetrequestComponent(dataset, modalService, router, customer) {
        this.dataset = dataset;
        this.modalService = modalService;
        this.router = router;
        this.customer = customer;
        this.checkedAll = false;
        this.monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];
    }
    AdmintimesheetrequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.requestperm = this.permission.m15;
        this.hstatus = '';
        this.month = (new Date()).getMonth();
        this.year = (new Date()).getFullYear();
        this.yearmonth = this.monthNames[this.month] + ' ' + (new Date()).getFullYear();
        this.classactive = ((new Date()).getMonth() + 1).toString();
        this.customer.getEmployeeDetailsbyadmin().subscribe(function (data) {
            _this.employeelist = data;
        });
        this.bindalldata();
    };
    AdmintimesheetrequestComponent.prototype.selectall = function (val) {
        var _this = this;
        if (val) {
            this.data.forEach(function (element, index) {
                _this.data[index].isChecked = true;
            });
            // document.getElementsByClassName('activity').checked = true; 
        }
        else {
            this.data.forEach(function (element, index) {
                _this.data[index].isChecked = false;
            });
        }
    };
    AdmintimesheetrequestComponent.prototype.selectTimesheet = function (val) {
        var _this = this;
        if (val.checked) {
            this.checkedAll = true;
            this.data.forEach(function (element, index) {
                if (!element.isChecked) {
                    _this.checkedAll = false;
                }
                if (val.value == element.employeehourapplyid) {
                    _this.data[index].isChecked = true;
                }
            });
        }
        else {
            this.checkedAll = false;
            this.data.forEach(function (element, index) {
                if (val.value == element.employeehourapplyid) {
                    _this.data[index].isChecked = false;
                }
            });
        }
    };
    AdmintimesheetrequestComponent.prototype.approveAllTimesheet = function (status) {
        var _this = this;
        var activityIds = [];
        this.data.forEach(function (element) {
            if (element.isChecked) {
                activityIds.push(element.employeehourapplyid);
            }
        });
        if (activityIds.length) {
            var changeData = { 'ids': activityIds, 'hstatus': status, 'userid': this.Userid, 'statusdate': new Date() };
            var msg = '';
            if (status == 2) {
                msg = 'Are you sure you want to Approve?';
            }
            else {
                msg = 'Are you sure you want to Reject?';
            }
            if (confirm(msg)) {
                this.dataset.changeAllActivityStatus(changeData).subscribe(function (data) {
                    _this.bindalldata();
                });
            }
        }
        else {
            this.modalService.open('check-plan');
        }
    };
    AdmintimesheetrequestComponent.prototype.onChange = function (newValue) {
        this.employeeid = newValue;
        this.bindalldata();
    };
    AdmintimesheetrequestComponent.prototype.onChangestatus = function (newValue) {
        this.hstatus = newValue;
        this.bindalldata();
    };
    AdmintimesheetrequestComponent.prototype.bindalldata = function () {
        var _this = this;
        this.data = [];
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'month': this.month, 'year': this.year, 'employeeid': this.employeeid, 'hstatus': this.hstatus };
        this.dataset.getemployeeworklistreportrequestbyadmin(data).subscribe(function (data) {
            _this.record = data.activities.length;
            // this.data = data;
            var leavefound = false;
            data.activities.forEach(function (act) {
                leavefound = false;
                data.leaves.forEach(function (leave, lindex) {
                    if ((act.employeeid == leave.employeeid) && (leave.startdate <= act.startdate) && (leave.enddate >= act.startdate)) {
                        leavefound = true;
                    }
                });
                act.leavefound = leavefound;
                act.isChecked = false;
                _this.data.push(act);
            });
            console.log('testing data', _this.data);
            _this.record = _this.data.length;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    AdmintimesheetrequestComponent.prototype.onStatus = function (id, status) {
        var _this = this;
        var deleteData = { 'id': id, 'hstatus': status, 'userid': this.Userid, 'statusdate': new Date() };
        var msg = '';
        if (status == 2) {
            msg = 'Are you sure you want to Approve?';
        }
        else {
            msg = 'Are you sure you want to Reject?';
        }
        if (confirm(msg)) {
            this.dataset.statusactivity(deleteData).subscribe(function (data) {
                _this.bindalldata();
            });
        }
    };
    AdmintimesheetrequestComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    AdmintimesheetrequestComponent.prototype.onyearSubmit = function (year) {
        this.year = year;
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + this.year;
        this.bindalldata();
        // this.modalService.close(id);
    };
    AdmintimesheetrequestComponent.prototype.openModalnote = function (data, id) {
        this.notedetails = data;
        this.modalService.open(id);
    };
    AdmintimesheetrequestComponent.prototype.yearpop = function (id) {
        this.modalService.open(id);
    };
    AdmintimesheetrequestComponent.prototype.getDataByMonth = function (mon) {
        this.classactive = mon;
        this.month = mon - 1;
        this.yearmonth = this.monthNames[this.month] + ' ' + this.year;
        this.bindalldata();
        // const data = { 'id': this.Userid, 'month': this.month, 'year': this.year, 'employeeid': this.employeeid, 'hstatus': this.hstatus };
        // this.dataset.getemployeeworklistreportrequestbyadmin(data).subscribe((data: any) => {
        //   this.data = data;
        //   this.record = this.data.length;
        //   if(this.record < 30){
        //     this.pageOfItems = this.data;
        //   }
        //   else{
        //        this.items =this.data;
        //   }
        // });
    };
    AdmintimesheetrequestComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    AdmintimesheetrequestComponent.prototype.getDataByYear = function (yea) {
        var _this = this;
        this.year = yea;
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + yea;
        var data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
        this.dataset.getemployeeworklistreportrequestbyadmin(data).subscribe(function (data) {
            _this.data = data;
            _this.record = _this.data.length;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    return AdmintimesheetrequestComponent;
}());
exports.AdmintimesheetrequestComponent = AdmintimesheetrequestComponent;
