<style>
  .invalid-feedback {
      display: block !important;
  }
</style>
<div class="bg">
  <app-header></app-header>


  <style>
      .leftbox {
          top: -30px !important;
          left: 30px !important;
      }
  </style>
  <div class="card-activities">

      <div class="media-list">


          <div class="media">
              <div class="activity-icon">
                  <i class="icon ion-image"></i>
              </div>
          </div>
          <div class="media">
              <div class="activity-icon">
                  <i class="icon ion-image"></i>
              </div>
          </div>
      </div>
  </div>

  <div class="section-wrapper">
      <div class="container">
          <div class="tabs" id="tabs">
         
              <div class="tabs__content">

                  <div class="tabs__content__item active-tab">
                      <div class="content">
                          <div class="row">
                              <div class="col-lg-12">
                                  <ul class="tabs__head montharea">
                                      <li><a [ngClass]="{'active': classactive == '0'}" (click)="addMonthsOfYear(0);">January </a></li>
                                      <li><a [ngClass]="{'active': classactive == '1'}" (click)="addMonthsOfYear(1);">February </a></li>
                                      <li><a [ngClass]="{'active': classactive == '2'}" (click)="addMonthsOfYear(2);">March </a></li>
                                      <li><a [ngClass]="{'active': classactive == '3'}" (click)="addMonthsOfYear(3);">April </a></li>
                                      <li><a [ngClass]="{'active': classactive == '4'}" (click)="addMonthsOfYear(4);">May</a></li>
                                      <li><a [ngClass]="{'active': classactive == '5'}" (click)="addMonthsOfYear(5);">June </a></li>
                                      <li><a [ngClass]="{'active': classactive == '6'}" (click)="addMonthsOfYear(6);">July </a></li>
                                      <li><a [ngClass]="{'active': classactive == '7'}" (click)="addMonthsOfYear(7);">August </a></li>
                                      <li><a [ngClass]="{'active': classactive == '8'}" (click)="addMonthsOfYear(8);">September </a></li>
                                      <li><a [ngClass]="{'active': classactive == '9'}" (click)="addMonthsOfYear(9);">October </a></li>
                                      <li><a [ngClass]="{'active': classactive == '10'}" (click)="addMonthsOfYear(10);">November </a></li>
                                      <li><a [ngClass]="{'active': classactive == '11'}" (click)="addMonthsOfYear(11);">December </a></li>
                                  </ul>
                              </div>

                          </div>
                          <div class="row row-sm rota">
                              <div class="col-lg-12">
                                
                                <div class="row text-center mb-3">
                                  <div class="col-md-4 text-left">
                                    <div class="btn-group">
                                      <div
                                        class="btn btn-primary rotacenter"
                                        mwlCalendarPreviousView
                                        [view]="view"
                                        [(viewDate)]="viewDate"
                                        (viewDateChange)="viewDateChange.next(viewDate)"
                                      >
                                      <span class="lnr lnr-arrow-left mr-2"></span> Previous
                                      </div>
                                      <div
                                        class="btn btn-outline-secondary"
                                        mwlCalendarToday
                                        [(viewDate)]="viewDate"
                                        (viewDateChange)="viewDateChange.next(viewDate)"
                                      >
                                        Today
                                      </div> 
                                      <div
                                        class="btn btn-primary rotacenter"
                                        mwlCalendarNextView
                                        [view]="view"
                                        [(viewDate)]="viewDate"
                                        (viewDateChange)="viewDateChange.next(viewDate)"
                                      >
                                        Next <span class="lnr lnr-arrow-right ml-2"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <h3 class="rotatextheading">{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</h3>
                                    <h3 class="rotatextheading">Rota for {{ employeeName }}</h3>
                                    <!-- <label>snapDraggedEvents</label>
                                    <input type="checkbox" [(ngModel)]="snapDraggedEvents"> -->
                                  
                                  </div>
                                  <div class="col-md-4 text-right">
                                    <div class="btn-group">
                                      <div
                                        class="btn btn-primary"
                                        (click)="updatemonth('month')"
                                        [class.active]="view === 'month'"
                                      >
                                        Month
                                      </div>
                                      <div
                                        class="btn btn-primary"
                                        (click)="updatemonth('week')"
                                        [class.active]="view === 'week'"
                                      >
                                        Week
                                      </div>
                                      <div
                                        class="btn btn-primary"
                                        (click)="updatemonth('day')"
                                        [class.active]="view === 'day'"
                                      >
                                        Day
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <ng-template #modalContent let-close="close">
                                  <div class="modal-header">
                                      <h5 class="modal-title">Create Rota</h5>
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <div class="modal-body" style="padding:0 30px;">
                                      <form [formGroup]="rotaformGroup" #rotaForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(rotaForm.value,'modalContent')" *ngIf="rota">
                                          <input type="hidden" formControlName="id" class="form-control" name="id" id="id" [(ngModel)]="rota.employeerotaid">
                                          <input type="hidden" formControlName="userId" class="form-control" required name="userId" id="userId" [(ngModel)]="rota.userId">
                                          <input type="hidden" formControlName="employeeId" class="form-control" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="rota.employeeId">
                                          <div class="formgroup">
                                              <div class="row row-sm mg-t-10">
                                                  <label class="d-flex justify-content-between"><span>Title*</span> </label>
                                                  <input type="text" formControlName="title" name="title" id="title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" class="form-control" [(ngModel)]="rota.title" [maxlength]="100" placeholder="Enter title here">
                                                  <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                                      <div *ngIf="f.title.errors.required">Please enter title</div>
                                                      <div *ngIf="!f.title.errors.required && f.title.errors.minlength">Minimum 3 characters required</div>
                                                  </div>
                                              </div>

                                              <div class="row row-sm mg-t-10">
                                                <label class="d-flex justify-content-between"><span>Start Time*</span> </label>
                                                <select formControlName="starttime" name="starttime" id="starttime" [ngClass]="{ 'is-invalid': submitted && f.starttime.errors }" class="form-control" [(ngModel)]="rota.starttime">
                                                  <option *ngFor="let tval of timeValues" value="{{ tval.key }}">{{ tval.value }}</option>
                                                </select>
                                                <div *ngIf="submitted && f.starttime.errors" class="invalid-feedback">
                                                    <div *ngIf="f.starttime.errors.required">Please enter start time</div>
                                                </div>
                                              </div>

                                              <div class="row row-sm mg-t-10">
                                                <label class="d-flex justify-content-between"><span>End Time*</span> </label>
                                                <select formControlName="endtime" name="endtime" id="endtime" [ngClass]="{ 'is-invalid': submitted && f.endtime.errors }" class="form-control" [(ngModel)]="rota.endtime">
                                                  <option *ngFor="let tval of timeValues" [disabled]="rotaformGroup.controls.starttime.value>=tval.key" value="{{ tval.key }}">{{ tval.value }}</option>
                                                </select>
                                                <div *ngIf="submitted && f.endtime.errors" class="invalid-feedback">
                                                    <div *ngIf="f.endtime.errors.required">Please enter end time</div>
                                                </div>
                                              </div>

                                              <div class="row row-sm mg-t-10">
                                                  <label>Start Date*</label>
                                                  <mat-form-field class="datecontrol">
                                                      <input matInput [matDatepicker]="picker" class="form-control" [disabled]="rota.employeerotaid!=0" (dateChange)="onDateChange($event)" [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }" [min]="minstartDate" formControlName="startDate" id="start" name="start" [(ngModel)]="rota.startDate">
                                                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                      <mat-datepicker #picker></mat-datepicker>
                                                  </mat-form-field>
                                                  <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
                                                      <div *ngIf="f.startDate.errors.required">Please choose start date </div>
                                                  </div>
                                              </div>

                                              <div class="row row-sm mg-t-10">
                                                <label>End Date*</label>
                                                <mat-form-field class="datecontrol">
                                                    <input matInput [matDatepicker]="picker2" class="form-control" [disabled]="rota.employeerotaid!=0" [ngClass]="{ 'is-invalid': submitted && f.endDate.errors }" formControlName="endDate" id="end" name="end" [(ngModel)]="rota.endDate" [min]="rotaformGroup.controls.startDate.value">
                                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker2></mat-datepicker>
                                                </mat-form-field>
                                                <div *ngIf="submitted && f.endDate.errors" class="invalid-feedback">
                                                    <div *ngIf="f.endDate.errors.required">Please choose end date</div>
                                                </div>
                                              </div>

                                              <div class="row row-sm mg-t-10">
                                                <label>Office Location*</label>
                                                <select class="form-control" name="locationid" id="locationid" [(ngModel)]="rota.locationId" [ngClass]="{ 'is-invalid': submitted && f.locationId.errors }" formControlName="locationId">
                                                  <option value="">Select Office Location</option>
                                                  <option *ngFor="let ltype of locationlist" value="{{ ltype.companylocationdetailsid }}">{{ltype.address1 }} - {{ltype.cityname }}, {{ltype.statename }}</option>

                                                </select>
                                                <div *ngIf="submitted && f.locationId.errors" class="invalid-feedback">
                                                    <div *ngIf="f.locationId.errors.required">Please select location</div>
                                                </div>
                                              </div>
                                              
                                              <div class="modal-footer row mg-t-10">
                                                  <div class="row invalid-feedback" *ngIf="timeslotError">
                                                      <div>{{timeSlotErrorMessage}}</div>
                                                  </div>
                                                  <div class="row">
                                                      <button type="submit" class="btn btn-primary com_btn" [disabled]="!disableBtn">Save</button>
                                                      <button type="button" class="btn btn-secondary com_btn" data-dismiss="modal" id="closeModal" (click)="close()">Close</button>
                                                      <button *ngIf="(rota.employeeholidayapplyid != 0) && (rota.hstatus == '1')" type="button" class="btn btn-danger com_btn" (click)="onDelete(rota.employeeholidayapplyid)">Delete</button>
                                                  </div>
                                              </div>

                                          </div>
                                      </form>
                                  </div>
                              </ng-template>




<div [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="'month'"
    [viewDate]="viewDate"
    [events]="allRotas"
    [activeDayIsOpen]="false"
    [refresh]="refresh"
    [cellTemplate]="customCellTemplate"
    (eventTimesChanged)="eventTimesChanged($event)"
  >

  </mwl-calendar-month-view>
  <ng-template #customCellTemplate let-day="day" let-locale="locale">
    <div class="cal-cell-top-add">
        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
        <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
        <span class="cal-day-number-add" *ngIf="day.date >= leaveavailabledate" style="border: 1px solid; border-radius: 5px;margin-right: 5px;margin-top: 5px;" (click)="addNewRota('Add',event,day.date)"><i class="fa fa-fw fa-plus"></i></span>
    </div>
    <div *ngIf="day.events.length > 0">
        <div *ngFor="let event of day.events; let i = index" [attr.data-index]="i">
            <div class="boxrelative" *ngIf="i < 2">
                <div class="cal-events">
                    <div class="cal-courseevent" [ngClass]="event.Type" [style.background-color]="event.color.primary" [style.color]="event.color.secondary">
                        <small> <a (click)="showAllEvents('event-viewer',day.date)">{{ event.title | slice:0:20 }}{{event.title.length > 20 ? '...' : ''}}</a>
<!-- <i class="fa fa-fw fa-pencil" (click)="editEvent('Edited',event)"></i> --></small>
                    </div>
                </div>
            </div>
            <div *ngIf="i == 2">
                <small *ngIf="day.events.length > 2" class="exdot" (click)="showAllEvents('event-viewer',day.date)"><i class="fa fa-fw fa-circle"></i><i class="fa fa-fw fa-circle"></i><i class="fa fa-fw fa-circle"></i></small>
            </div>
        </div>
    </div>
</ng-template>
  <mwl-calendar-week-view
    *ngSwitchCase="'week'"
    [viewDate]="viewDate"
    [events]="allRotas"
    [refresh]="refresh"
    [dayStartHour]="dayStartHour"
    (eventTimesChanged)="eventTimesChanged($event)"
    (beforeViewRender)="beforeWeekViewRender($event)"
    [snapDraggedEvents]="snapDraggedEvents"
  >
  </mwl-calendar-week-view>
  <mwl-calendar-day-view
    *ngSwitchCase="'day'"
    [viewDate]="viewDate"
    [events]="allRotas"
    [refresh]="refresh"
    [dayStartHour]="dayStartHour"
    (eventTimesChanged)="eventTimesChanged($event)"
    (beforeViewRender)="beforeDayViewRender($event)"
    [snapDraggedEvents]="snapDraggedEvents"
  >
  </mwl-calendar-day-view>
</div>


                              </div>
                          </div>



                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<jw-modal id="event-viewer" class="modal listofwork" role="dialog">
  <div role="document" class="formgroup">
      <div class="bd-example-modal-lg">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title" id="myLargeModalLabel">Rota</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" (click)="closeModal('event-viewer');">×</span>
                  </button>
                  </div>
                  <div class="modal-body">
                      <div class="container">
                          

                          <div class="leave_apply_section">
                              <div class="white_box_shadow leave_width" *ngFor="let lv of allRotaList">
                                  <div class="leave_app_head">
                                      <div class="heading width80 mb-3">
                                          <!-- <span class="leave_type">{{ lv.holidaytype.typename }} </span> -->
                                          <h4>{{ lv.title }} </h4>
                                      </div>
                                      <!-- <span class="btn btn-danger" *ngIf="lv.hstatus == '3'"><i class="fa fa-clock-o" aria-hidden="true"></i><span>Rejected</span> </span> -->
                                      <span class="btn btn-sucess" *ngIf="lv.rstatus == '1'"><i class="fa fa-clock-o" aria-hidden="true"></i><span>Approved</span></span>
                                      <!-- <span class="btn btn-sucess" *ngIf="lv.hstatus == '2'"><i class="fa fa-clock-o" aria-hidden="true"></i><span>Approved</span></span> -->
                                  </div>
                                  <div class="leave_details_sec">
                                      <div class="leave_icon">
                                          <i class="fa fa-calendar" aria-hidden="true"></i>
                                      </div>
                                      <p class="leavefrom"><span>Rota From :</span> {{lv.starttime}} - {{lv.endtime}}</p>
                                  </div>
                                  <!-- <div class="leave_description">
                                      <p *ngFor="let rem of lv.remarkdetails">
                                          <i> {{ rem.holidayremark }} 
                                              <div class="note_footer">
                                                  <span>{{rem.createat | date: 'dd MMM yyyy'}}</span>  
                                                  <span><b *ngIf="rem.comuser">{{rem.comuser.fname}} {{rem.comuser.mname}} {{rem.comuser.lname}} </b></span> 
                                              </div>
                                          </i>
                                      </p>
                                  </div> -->
                                  <div class="leave_edit_cane_btn float-right">
                                      <!-- <button (click)="editEventOfList('Edited',lv.employeerotaid, 'event-viewer')" type="submit" class="btn btn-danger com_btn" *ngIf="lv.rstatus == '1'">Edit Rota</button> -->
                                      <!-- <button (click)="remarksmodel(lv.employeerotaid, 'remarks-viewer', 'event-viewer');" type="submit" class="btn btn-primary com_btn" *ngIf="lv.hstatus == '1'">Add Comment</button> -->
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</jw-modal>
