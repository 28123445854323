"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var AdminsettingComponent = /** @class */ (function () {
    function AdminsettingComponent(router) {
        this.router = router;
    }
    AdminsettingComponent.prototype.ngOnInit = function () {
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.settingperm = this.permission.m81;
    };
    return AdminsettingComponent;
}());
exports.AdminsettingComponent = AdminsettingComponent;
