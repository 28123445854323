"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var modal_service_1 = require("../../../_services/modal.service");
var common_service_1 = require("../../../services/common.service");
var country_service_1 = require("../../../services/country.service");
var job_service_1 = require("../../../services/job.service");
var forms_1 = require("@angular/forms");
var jobs_model_1 = require("../../../models/jobs/jobs.model");
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
var platform_browser_1 = require("@angular/platform-browser");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var country_model_1 = require("./../../../models/address/country.model");
var Soc_model_1 = require("./../../../models/jobs/Soc.model");
var alert_service_1 = require("../../../services/alert.service");
var JobdetailsComponent = /** @class */ (function () {
    function JobdetailsComponent(sanitizer, commonService, modalService, job, formBuilder, activatedRoute, country, router, alerts, uploadService) {
        this.sanitizer = sanitizer;
        this.commonService = commonService;
        this.modalService = modalService;
        this.job = job;
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.country = country;
        this.router = router;
        this.alerts = alerts;
        this.uploadService = uploadService;
        this.jobDetail = new jobs_model_1.Jobs();
        this.options = [];
        this.submitted = false;
    }
    JobdetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        this.jobid = this.activatedRoute.snapshot.paramMap.get("jobid");
        this.error1 = false;
        this.error2 = false;
        this.error3 = false;
        this.error4 = false;
        this.jobDetail.country = new country_model_1.Country();
        this.jobDetail.soc = new Soc_model_1.Soc();
        this.jobformGroup = this.formBuilder.group({
            jobmasterid: [''],
            urldocumentid: [''],
            jobtittle: ['', forms_1.Validators.required],
            jobdate: [''],
            closedate: [''],
            contactname: ['', forms_1.Validators.required],
            // amountpaid: ['', Validators.compose([Validators.required, Validators.pattern(/^([0-9].[0-9]\d|[1-9]\d*)?$/)])],
            contactrole: ['', forms_1.Validators.required],
            // expensetypeid: ['', Validators.compose([Validators.required, Validators.min(1)])],
            joburl1: ['', forms_1.Validators.required],
            joburl2: ['', forms_1.Validators.required],
            joburl3: [''],
            joburl4: [''],
            salary: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            maxsalary: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            socid: ['', forms_1.Validators.required],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            phonenumber: ['', forms_1.Validators.compose([forms_1.Validators.required])],
            postalcode: ['', forms_1.Validators.required],
            address1: ['', forms_1.Validators.required],
            address2: [''],
            cityname: ['', forms_1.Validators.required],
            countyid: ['', forms_1.Validators.required],
            countryid: ['', forms_1.Validators.required],
            qualification: [''],
            addposition: ['', forms_1.Validators.required],
            stypeid: ['', forms_1.Validators.required],
            jobdescreption: ['']
        });
        this.jobscreenformGroup = this.formBuilder.group({
            id: [''],
            jobmasterid: ['', forms_1.Validators.required],
            screenshotdayid: ['', forms_1.Validators.required],
            joburl1: [''],
            joburl2: [''],
            joburl3: [''],
            joburl4: ['']
        });
        this.filename1 = 'Choose file';
        this.filename2 = 'Choose file';
        this.filename3 = 'Choose file';
        this.filename4 = 'Choose file';
        this.Userid = localStorage.getItem('userid');
        this.job.getjobById({ "id": this.Userid, "jobid": this.jobid }).subscribe(function (data) {
            _this.jobDetail = data;
            // console.log(this.jobDetail);
        });
        this.job.getjobskillList({ "id": this.Userid, "jobid": this.jobid }).subscribe(function (data) {
            _this.skilldata = data;
        });
        this.buttoninsert = "Add New Job";
        this.country.getsoclist().subscribe(function (socdata) {
            _this.socdata = socdata;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        var countrydata = { 'id': this.jobDetail.countryid };
        this.country.getstatelist(countrydata).subscribe(function (state) {
            _this.countylist = state;
        });
        this.job.getjobList().subscribe(function (data) {
            _this.data = data;
        });
    };
    JobdetailsComponent.prototype.onDelete = function (id) {
        var _this = this;
        var deleteData = { 'id': id };
        if (confirm("Are you sure you want to delete?")) {
            this.job.deleteskill(deleteData).subscribe(function (data) {
                _this.job.getjobskillList({ "id": _this.Userid, "jobid": _this.jobid }).subscribe(function (data) {
                    _this.skilldata = data;
                });
            });
        }
    };
    Object.defineProperty(JobdetailsComponent.prototype, "f", {
        get: function () { return this.jobformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobdetailsComponent.prototype, "g", {
        get: function () { return this.jobscreenformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    JobdetailsComponent.prototype.onSubmit = function (jobformdata, id) {
        var _this = this;
        this.submitted = true;
        if (this.jobformGroup.invalid) {
            return;
        }
        jobformdata.userid = this.Userid;
        if (jobformdata.joburl1 != '') {
            jobformdata.file1 = this.file1;
        }
        if (jobformdata.joburl2 != '') {
            jobformdata.file2 = this.file2;
        }
        if (jobformdata.joburl3 != '') {
            jobformdata.file3 = this.file3;
        }
        if (jobformdata.joburl4 != '') {
            jobformdata.file4 = this.file4;
        }
        this.disableBtn = false;
        this.job.jobdetailsUpdate(jobformdata).subscribe(function (response) {
            _this.job.getjobList().subscribe(function (data) {
                _this.data = data;
                _this.modalService.close(id);
            });
        });
    };
    JobdetailsComponent.prototype.addJobScreen = function (jobscreenshotformdata, id) {
        var _this = this;
        this.submitted = true;
        if (this.jobscreenformGroup.invalid) {
            return;
        }
        if (jobscreenshotformdata.joburl1 != '') {
            jobscreenshotformdata.file1 = this.file1;
        }
        if (jobscreenshotformdata.joburl2 != '') {
            jobscreenshotformdata.file2 = this.file2;
        }
        if (jobscreenshotformdata.joburl3 != '') {
            jobscreenshotformdata.file3 = this.file3;
        }
        if (jobscreenshotformdata.joburl4 != '') {
            jobscreenshotformdata.file4 = this.file4;
        }
        this.job.jobscreenshotdetailsUpdate(jobscreenshotformdata).subscribe(function (response) {
            _this.job.getjobById({ "id": _this.Userid, "jobid": response.jobmasterid }).subscribe(function (data) {
                _this.jobDetail = data;
                _this.modalService.close(id);
            });
        });
    };
    JobdetailsComponent.prototype.onscreenchange = function (jobscreensortid) {
        var _this = this;
        this.job.checkscreensort({ "id": this.Userid, "screenid": jobscreensortid, "jobid": this.jobid }).subscribe(function (datascreen) {
            if (datascreen.length == 0) {
                _this.screenshotid = '';
            }
            else {
                _this.screenshotid = datascreen[0].id;
            }
            // this.file1 = "";
            // this.file2 = "";
            // this.file3 = "";
            // this.file4 = "";
            // this.filename1 ='Choose file';
            // this.filename2 ='Choose file';
            // this.filename3 ='Choose file';
            // this.filename4 ='Choose file';
        });
    };
    JobdetailsComponent.prototype.changeLabelName = function (lbl, val) {
        document.getElementById(lbl).innerHTML = val;
    };
    JobdetailsComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'mobile') {
                _this.mobilecheck = getdata.valid;
            }
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    JobdetailsComponent.prototype.onSearchChange = function (searchValue) {
        var _this = this;
        if (searchValue.length > 4) {
            var newArray = [];
            this.commonService.getAddress(searchValue).subscribe(function (data) {
                var newdata;
                newdata = data;
                newdata.addresses.forEach(function (value) {
                    newArray.push(value + "," + searchValue);
                });
                _this.options = newArray;
            });
        }
        else {
            this.options = [];
        }
    };
    JobdetailsComponent.prototype.displayFn = function (value) {
        if (value != null) {
            var res = value.split(",");
            if (res.length > 6) {
                return res[7];
            }
            else {
                return value;
            }
        }
    };
    JobdetailsComponent.prototype.updatecode = function (evt, response) {
        var _this = this;
        res = [];
        if (evt.source.selected) {
            var res = response.split(",");
            this.jobDetail.address1 = res[0];
            this.jobDetail.address2 = res[1];
            this.jobDetail.cityname = res[5];
            this.jobDetail.postalcode = res[7];
            var data = { 'id': this.jobDetail.countryid };
            this.country.getstatelist(data).subscribe(function (state) {
                for (var i = 0; i < state.length; i++) {
                    if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
                        var inde = state[i]['stateid'];
                    }
                }
                _this.jobDetail.countyid = inde;
                _this.countylist = state;
            });
        }
    };
    JobdetailsComponent.prototype.transform = function (html) {
        return this.sanitizer.bypassSecurityTrustStyle(html);
    };
    JobdetailsComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    JobdetailsComponent.prototype.selectFile = function (event, field) {
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        this.filesizeerror1 = false;
        this.filesizeerror2 = false;
        this.filesizeerror3 = false;
        this.filesizeerror4 = false;
        this.error1 = false;
        this.error2 = false;
        this.error3 = false;
        this.error4 = false;
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                if (field == 'file1') {
                    this.filesizeerror1 = true;
                }
                else if (field == 'file2') {
                    this.filesizeerror2 = true;
                }
                else if (field == 'file3') {
                    this.filesizeerror3 = true;
                }
                else if (field == 'file4') {
                    this.filesizeerror4 = true;
                }
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
                if (field == 'file1') {
                    this.error1 = true;
                }
                else if (field == 'file2') {
                    this.error2 = true;
                }
                else if (field == 'file3') {
                    this.error3 = true;
                }
                else if (field == 'file4') {
                    this.error4 = true;
                }
                return;
            }
        }
        var file = this.selectedFiles;
        var imagepath = this.uploadService.uploadfile(file);
        if (field == 'file1') {
            this.file1 = imagepath;
            this.filename1 = files[0].name;
        }
        if (field == 'file2') {
            this.file2 = imagepath;
            this.filename2 = files[0].name;
        }
        if (field == 'file3') {
            this.file3 = imagepath;
            this.filename3 = files[0].name;
        }
        if (field == 'file4') {
            this.file4 = imagepath;
            this.filename4 = files[0].name;
        }
    };
    JobdetailsComponent.prototype.openModal = function (jobid, id) {
        var _this = this;
        this.buttoninsert = "Update";
        this.disableBtn = true;
        this.job.getjobById({ "id": this.Userid, "jobid": jobid }).subscribe(function (data) {
            data.screen.forEach(function (element) {
                if (element.screenshotdayid == '1') {
                    _this.jobDetail.urldocumentid = element.id;
                }
            });
            _this.jobDetail = data;
            // this.jobDetail.jobdescreption =  this.transform(data.jobdescreption);
        });
        this.modalService.open(id);
    };
    JobdetailsComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.disableBtn = true;
        this.buttoninsert = "Add New Job";
        this.jobDetail = new jobs_model_1.Jobs();
        this.modalService.open(id);
    };
    JobdetailsComponent.prototype.openModal3 = function (jobid, id) {
        var _this = this;
        this.buttoninsert = "Update Job";
        this.disableBtn = true;
        this.job.getjobById({ "id": this.Userid, "jobid": jobid }).subscribe(function (data) {
            _this.jobDetail = data;
        });
        this.modalService.open(id);
    };
    JobdetailsComponent.prototype.openModal4 = function (screenshotid, id, screenumber) {
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.screenshotid = screenshotid;
        this.file1 = "";
        this.file2 = "";
        this.file3 = "";
        this.file4 = "";
        this.filesizeerror1 = false;
        this.filesizeerror2 = false;
        this.filesizeerror3 = false;
        this.filesizeerror4 = false;
        this.error1 = false;
        this.error2 = false;
        this.error3 = false;
        this.error4 = false;
        this.filename1 = 'Choose file';
        this.filename2 = 'Choose file';
        this.filename3 = 'Choose file';
        this.filename4 = 'Choose file';
        if (screenumber != '') {
            this.disableday = true;
        }
        else {
            this.disableday = false;
        }
        this.screenumber = screenumber;
        this.modalService.open(id);
    };
    JobdetailsComponent.prototype.openModal5 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    JobdetailsComponent.prototype.deleteFile = function (jobid, id, documenturl) {
        var _this = this;
        var deleteData = {};
        if (documenturl == 'document1') {
            deleteData = { 'id': id, 'urldocument1': null };
        }
        else if (documenturl == 'document2') {
            deleteData = { 'id': id, 'urldocument2': null };
        }
        else if (documenturl == 'document3') {
            deleteData = { 'id': id, 'urldocument3': null };
        }
        else if (documenturl == 'document4') {
            deleteData = { 'id': id, 'urldocument4': null };
        }
        if (confirm("Are you sure you want to delete?")) {
            this.job.deleteFile(deleteData).subscribe(function (data) {
                _this.job.getjobById({ "id": _this.Userid, "jobid": jobid }).subscribe(function (data) {
                    _this.alerts.success('Document deleted successfully', true);
                    _this.jobDetail = data;
                });
            });
        }
    };
    JobdetailsComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return JobdetailsComponent;
}());
exports.JobdetailsComponent = JobdetailsComponent;
