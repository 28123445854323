<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper payment">
    <div class="container">
      <div class="tabs" id="tabs">
        <div class="tabs__sontent">
          <app-invioce-shared></app-invioce-shared>
          <div class="clearfix"></div>
          <div class="invoice_contentfff">
            <div class="row text-center">

            </div>
            <div class="mt-4">


              <div class="container">
                <div class="main-banner">
                    <div class="banner-form thankbgsss">


              <div class="row">
                <div class="col-sm-12">
                    <div class="tankushow" *ngIf="success">

                        <img alt="shape" src="../../../assets/img/thankcheck.png">
                        <div class="detailstext">
                            <h4>Payment Successful!</h4>
                            <p>Your order has been successfully processed. Please return to the checks you have purchased and start using them instantly.</p>
                            <h1>Thank You!</h1>
                        </div>
                    </div>


                    <div class="tankushow" *ngIf="!success">

                      <img alt="shape" src="../../../assets/img/thankcheck.png">
                      <div class="detailstext">
                          <h4>Payment Failed !</h4>
                          <p>Your order has been successfully processed. Please return to the checks you have purchased and start using them instantly.</p>
                          <h1>Thank You!</h1>
                      </div>
                  </div>
                </div>
            </div>



          </div>
           
        </div>
      </div>

            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>