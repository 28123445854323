import { Component, OnInit, NgModule, ViewEncapsulation, HostListener } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { EmployeeService } from '../../../services/employee.service';
import { CustomerService } from '../../../services/customer.service';

import { ModalService } from '../../../_services/modal.service';
import { Nationality } from '../../../models/overview/nationality.model';
import { Permanentaddress } from '../../../models/overview/permanentaddress.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Profile,Termination } from '../../../models/overview/profile.model';
import { AlertService } from '../../../services/alert.service';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { CountryService } from '../../../services/country.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadFileService } from './../../../services/upload-file.service';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { MatOptionSelectionChange } from '@angular/material';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'app-checkemployeedetails',
  templateUrl: './checkemployeedetails.component.html',
  styleUrls: ['./checkemployeedetails.component.css']
})
export class CheckemployeedetailsComponent implements OnInit {

  public navbarOpen = false;
  public clicked = false;
  _el: any;
  location: any;
  layoutClass: string;
  sectionHeights = [];
  tab: any =1;
  empltype: any = false;
  terminationDetail: any;
  overviewData: any;
  buttoninsert:any;
  countrylist: any;
  statelist: any[];
  OverForm: FormGroup;
  permanetForm: FormGroup;
  mobilecheck: any;
  phonecheck: any;
  options = [];
  mindob = new Date();
  maxdob = new Date();
  selectedFiles: FileList;
  prfileImage: any;
  submitted: boolean;
  Userid: string;
  addressdata: any;
  addresslist: any;
  paddress: any;
  employement: any;
  visalist: any;
  empid: any;
  disableBtn: any;
  defaultVal: any = "";
  file: any;
  filesizeerror: any;
  fileformaterror: any;
  terminationForm: FormGroup;
  overview1: any ;
  current1: any;
  department1: any;
  overemployeement1: any;
  overaddress1 : any;
  overcompen1 : any;
  overcontactinfo1: any;
  overcos1 : any;
  overvisa1: any;
  overpassport1: any;
  otherDetail: any;

  overidproof1:any;
  constructor(
    private formBuilder: FormBuilder, 
    private activatedRoute: ActivatedRoute, 
    private uploadService: UploadFileService, 
    private data: CustomerService, 
    private country: CountryService,     
    private employeeService: EmployeeService, 
    private modalService: ModalService, 
    private commonService: CommonService, 
    private alerts: AlertService
  ) { }

  ngOnInit() {
    this.terminationDetail = new Termination();
    this.empid = this.activatedRoute.snapshot.paramMap.get("id");
    this.Userid = localStorage.getItem('userid');
    this.mindob.setDate(this.mindob.getDate() - 23741);
    this.maxdob.setDate(this.maxdob.getDate() - 574);
    this.OverForm = this.formBuilder.group({
      id: ['', Validators.required],
      //  employeeid: ['', Validators.required],
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      gender: ['', Validators.required],
      hiredate: ['', Validators.required],
      employeeid: ['', Validators.required],
      placeofbirth: ['', Validators.required],
      maritalstatus: ['', Validators.required],
      ninumber: ['', Validators.required],
      emprole: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      residenceid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      countryofbirthid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      dateofbirth: ['', Validators.required],
    });
    
    this.terminationForm = this.formBuilder.group({
      employeeid: ['', Validators.required],
      terminationdate: ['', Validators.required],
      terminationreason: ['', Validators.required],
      oktorehire: ['', Validators.required],
      regrettermination: ['', Validators.required]
    });

    this.permanetForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      userid: [''],
      addressid1: ['', Validators.required],
      addressid2: [''],
      cityname: ['', Validators.compose([Validators.required])],
      mobilenumber: [''],
      pincode: ['', Validators.required],
      statename: ['', Validators.compose([Validators.required, Validators.min(1)])],
      phonenumber: [''],
      countryid: ['', Validators.compose([Validators.required, Validators.min(1)])]
    });

    this.overviewData = new Profile();
    this.paddress = new Permanentaddress();
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.data.getOverviewbyadmin({'id': this.empid}).subscribe((data: any) => {

      this.empltype =  data.emptype;
      this.overviewData = data;
    });

    this.data.getPaddressbyadmin({'id': this.empid}).subscribe((data: any) => {
      
      if(data.addressid1 == null)
      {
        this.buttoninsert = "Submit";
      }
      else
      {
        this.buttoninsert = "Update";
      }
      
      this.paddress = data;
    });
    
    // this.Userid = localStorage.getItem('userid');    
    // const data3 = { 'userid': this.Userid };
    // this.data.getOtherInfo(data3).subscribe((data: any) => {
    //   this.employement = data;
    // });

    const data5 = { 'userid': this.Userid, 'employeeid': this.empid };
    this.data.getOtherInfobyadmin(data5).subscribe((data: any) => {               
      this.employement = data;
    });


    const data = { 'userid': this.empid };    
    this.data.getOtherInfo(data).subscribe((data: any) => {
      this.otherDetail = data;
    });


    this.data.getEmploymentbyadmin({'id': this.empid}).subscribe((data: any) => {
      this.employement = data;
    });



    this.data.getVisabyadmin({'id': this.empid}).subscribe((data: any) => {
      this.visalist = data;      
    });

  }

  get g() { return this.terminationForm.controls; }

  openModelTerminateactive(empid: string,terminateid: any, id: string)
  {
    this.terminationDetail = new Termination();
    this.submitted = false;

    var dd = {termid: terminateid}
    this.employeeService.employeeViewTermination(dd).subscribe((data: any) => {
      this.terminationDetail.employeeid = empid;
      if(data.oktorehire == true)
        this.terminationDetail.oktorehire = "1";
      else
        this.terminationDetail.oktorehire = "0";

      if(data.oktorehire == true)
        this.terminationDetail.regrettermination = "1";
      else
      this.terminationDetail.regrettermination = "0";

      this.terminationDetail.terminationdate = data.terminationdate;
      this.terminationDetail.terminationreason = data.terminationreason;
      this.modalService.open(id);
    });
  }

  openModelTerminate(empid: string, id: string)
  {
    this.terminationDetail = new Termination();
    this.submitted = false;
    this.terminationDetail.employeeid = empid;
    this.terminationDetail.oktorehire = "";
    this.terminationDetail.regrettermination = "";
    this.modalService.open(id);
  } 

  onSelectCountry(country_id: number) {
    const data = { 'id': country_id };
    this.country.getstatelist(data).subscribe((state: any) => {
      return this.statelist = state
    });
  }

  onSearchChange(searchValue) {
    if (searchValue.length > 4) {
      var newArray = [];
      this.commonService.getAddress(searchValue).subscribe((data: any) => {
        var newdata: any;
        newdata = data;
        newdata.addresses.forEach(function (value) {
          newArray.push(value + "," + searchValue);
        });
        this.options = newArray;
      });
    }
    else {
      this.options = [];
    }
  }

  displayFn(value: any) {
    if (value != null) {
      var res = value.split(",");
      if (res.length > 6) {
        return res[7];
      }
      else {
        return value;
      }
    }
  }

  updatecode(evt: MatOptionSelectionChange, response) {
    res = [];
    if (evt.source.selected) {
      var res = response.split(",");
      this.paddress.addressid1 = res[0];
      this.paddress.addressid2 = res[1];
      this.paddress.cityname = res[5];
      this.paddress.pincode = res[7];
      this.paddress.statename = res[6];
    }
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  openModal(id: string, data: any) {    
    this.submitted = false;
    this.disableBtn = true;
    this.overviewData = new Profile();
    if(data.nationalityid == null)
    {
      data.nationalityid = "";
    }
    if(data.residenceid == null)
    {
      data.residenceid = "";
    }
    if(data.countryofbirthid == null)
    {
      data.countryofbirthid = "";
    }
    // console.log(data);
    this.overviewData = data;
    this.modalService.open(id);
  }

  openModal2(id: string, data: any) {
    this.submitted = false;
    this.disableBtn = true;
    this.paddress = new Permanentaddress();
    this.buttoninsert = "Update";
    this.paddress = data;
    // this.paddress.employeeid = this.empid;
    // console.log(this.paddress.countryid);
    if((this.paddress.countryid == '') || (this.paddress.countryid == null))
    {
      this.paddress.countryid = '';
    }
    this.mobilecheck = "";
    this.phonecheck = "";
    this.paddress.userid = this.Userid;
    this.modalService.open(id);
  }

  selectFile(event) {
    var files = event.target.files;
    var selectedFiles = files[0];
    this.selectedFiles = selectedFiles;
    
    
    this.fileformaterror = false;
    this.filesizeerror = false;
    
    this.file = this.selectedFiles;
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;
      return;
    }
    else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png") && (filetype[filetype.length - 1].toLowerCase() != "gif") && (filetype[filetype.length - 1].toLowerCase() != "tif") && (filetype[filetype.length - 1].toLowerCase() != "pdf") && (filetype[filetype.length - 1].toLowerCase() != "doc") && (filetype[filetype.length - 1].toLowerCase() != "docx") && (filetype[filetype.length - 1].toLowerCase() != "msword")) {
      this.fileformaterror = true;
      // console.log(filetype[filetype.length - 1]);
      return;
    }
    const file = this.selectedFiles;
    var imagepath = this.uploadService.uploadfile(file);
    var forupdate = { 'imagename': imagepath, 'id': this.empid };
    this.data.updateemployeeimage(forupdate).subscribe((data: any) => 
    {
      setTimeout(() => this.overviewData.imagename = imagepath, 2000);
    });
    this.alerts.success('Profile picture updated successfully.',true); 
  }


  get f() { return this.OverForm.controls; }

  onSubmit(value3: any, id: any) {
    this.submitted = true;
    
    if (this.OverForm.invalid) {
      return;
    }
    this.disableBtn = false;
    this.data.updateemployeedetalsbyadmin(value3).subscribe((data: any) => {
      this.data.getOverviewbyadmin({'id': this.empid}).subscribe((data: any) => {
        this.overviewData = data;
      });
    });

    this.alerts.success('Profile updated successfully.',true); 
    this.modalService.close(id);
  }

  get h() { return this.permanetForm.controls; }

  onSubmit2(value3: any, id: any) {
    this.submitted = true;
    //console.log(this.permanetForm);
    if (this.permanetForm.invalid) {
      return;
    }
    var pinc = value3.pincode.split(',');
    value3.pincode = pinc[pinc.length - 1];
    value3.userid = this.Userid;
    this.data.permanentadddetalsbyadmin(value3).subscribe((data: any) => {
      this.data.getPaddressbyadmin({'id': this.empid}).subscribe((data: any) => {
        this.paddress = data;
      });
    });
    this.alerts.success('Permanent Address updated successfully.', true);
    this.modalService.close(id);
  }

  closeModalOverview(id: string) {
    this.overviewData = new Profile();
    // this.paddress = new Permanentaddress();
    // this.country.getcountrylist().subscribe((country: any) => {
    //   this.countrylist = country;
    // });

    this.data.getOverviewbyadmin({'id': this.empid}).subscribe((data: any) => {
      if(data.addressid1 == null)
      {this.buttoninsert = "Submit";}
      else
      {this.buttoninsert = "Update";}
      this.overviewData = data;
    });

    // this.data.getPaddress().subscribe((data: any) => {
    //   this.paddress = data;
    // });
    this.modalService.close(id);
  }

  
  closeModalTerminate(id: string) {
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.modalService.close(id);
  }

  closeModalPaddress(id: string) {
    this.modalService.close(id);
  }

  
  onSubmitTermination(terminationform: any, modelid: any) {
    this.submitted = true;
    if (this.terminationForm.invalid) {
      return;
    }

    this.employeeService.employeeSubmitTermination(terminationform).subscribe((data: any) => {

      this.alerts.success('Employee has been terminated successfully');
      this.modalService.close(modelid);
    
    });
  }


}
