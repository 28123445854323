export class Project { 
  emailid: string;
  clientid: any = "";
  phonenumber: string;
  companyid: any;
  clietdesignation: string;
  alteremailid: string;
  projectid: string;
  projectname: string;
  weburl: string;
  contactperson: string;
  contactrole: string;
  mobilenumber: string;
}