"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var app_global_1 = require("../app.global");
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var http_2 = require("@angular/http");
// import { Observable } from 'rxjs/Observable';
require("rxjs/add/operator/map");
var CommonService = /** @class */ (function () {
    function CommonService(http, ht) {
        this.http = http;
        this.ht = ht;
    }
    CommonService.prototype.ngOnInit = function () {
        // subscribe to router event
    };
    CommonService.prototype.formpostOnlyid = function (url) {
        var access_token = localStorage.getItem('userToken');
        console.log(access_token);
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': access_token
        });
        var userid = localStorage.getItem('userid');
        var data = { 'id': userid };
        return this.http.post(app_global_1.AppGlobals.API_ENDPOINT + url, data, { headers: reqHeader });
    };
    CommonService.prototype.formpostOnlyidbytoken = function (url) {
        var access_token = localStorage.getItem('userToken1');
        console.log(access_token);
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': access_token
        });
        var userid = localStorage.getItem('userid');
        var data = { 'id': userid };
        return this.http.post(app_global_1.AppGlobals.API_ENDPOINT + url, data, { headers: reqHeader });
    };
    CommonService.prototype.formget = function (url) {
        var access_token = localStorage.getItem('userToken');
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': access_token
        });
        return this.http.get(app_global_1.AppGlobals.API_ENDPOINT + url, { headers: reqHeader });
    };
    CommonService.prototype.refereeformget = function (url) {
        var access_token = localStorage.getItem('userToken1');
        if (!access_token) {
            access_token = localStorage.getItem('userToken');
        }
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': access_token
        });
        return this.http.get(app_global_1.AppGlobals.API_ENDPOINT + url, { headers: reqHeader });
    };
    CommonService.prototype.formgetwithouttoken = function (url) {
        var access_token = localStorage.getItem('userToken');
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json'
        });
        return this.http.get(app_global_1.AppGlobals.API_ENDPOINT + url, { headers: reqHeader });
    };
    CommonService.prototype.formpostForAI = function (url, data) {
        // var headers = new Headers();
        // headers.append('Access-Control-Allow-Origin', '*');
        // headers.append('Authorization', 'Basic ZWY5ZTU5MDctNjg1My00NmYzLWExN2ItZjgxNDRhZTg2ZGZlOkVZcng3Ymxvdm9lc0VBUkZHcVFnWHNua0NhRXpTWm9V');
        var reqHeader = new http_2.Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Origin': '*',
            'User-Agent': 'Complygate MyApp/v1.0',
            'secure': 'true',
            'Authorization': 'Basic ZWY5ZTU5MDctNjg1My00NmYzLWExN2ItZjgxNDRhZTg2ZGZlOkVZcng3Ymxvdm9lc0VBUkZHcVFnWHNua0NhRXpTWm9V'
        });
        //reqHeader.append('Access-Control-Allow-Origin: *', 'utf-8');
        data = JSON.stringify(data);
        // console.log(data);
        // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', "Req header")
        // console.log(reqHeader);
        return this.ht.post(url, data, { headers: reqHeader });
    };
    CommonService.prototype.formgetForAML = function (url) {
        var access_token = localStorage.getItem('userTokenAML');
        // console.log('Tanwar kumar');
        // console.log(access_token);
        var reqHeader = new http_1.HttpHeaders({
            'Accept': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'GET',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'User-Agent': 'Complygate',
            'secure': 'false',
            'apiKey': app_global_1.AppGlobals.API_AMLKEY
        });
        // data =JSON.stringify(data);
        // console.log(data);
        return this.http.get(app_global_1.AppGlobals.API_AML + url, { headers: reqHeader });
    };
    CommonService.prototype.formgetForAMLParam = function (url, dataparam) {
        var access_token = localStorage.getItem('userTokenAML');
        // console.log('Tanwar kumar');
        // console.log(access_token);
        var reqHeader = new http_1.HttpHeaders({
            'Accept': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'GET',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'User-Agent': 'Complygate',
            'secure': 'false',
            'apiKey': app_global_1.AppGlobals.API_AMLKEY
        });
        // data =JSON.stringify(data);
        // console.log(data);
        return this.http.get(app_global_1.AppGlobals.API_AML + url, { params: dataparam, headers: reqHeader });
    };
    CommonService.prototype.formpostForAML = function (url, data) {
        var access_token = localStorage.getItem('userTokenAML');
        var reqHeader = new http_1.HttpHeaders({
            'Accept': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'User-Agent': 'Complygate',
            'secure': 'false',
            'apiKey': app_global_1.AppGlobals.API_AMLKEY
        });
        data = JSON.stringify(data);
        // console.log(data);
        return this.http.post(app_global_1.AppGlobals.API_AML + url, data, { headers: reqHeader });
    };
    CommonService.prototype.formpostForWithoutToken = function (url, data) {
        var reqHeader = new http_1.HttpHeaders({
            'Accept': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'User-Agent': 'Complygate',
            'secure': 'false'
        });
        data = JSON.stringify(data);
        // console.log(data);
        return this.http.post(app_global_1.AppGlobals.API_AML + url, data, { headers: reqHeader });
    };
    CommonService.prototype.formpostForWithoutTokenForrighttowork = function (url, data) {
        var reqHeader = new http_1.HttpHeaders({
            'Accept': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'User-Agent': 'Complygate',
            'secure': 'false'
        });
        data = JSON.stringify(data);
        // console.log(data);
        return this.http.post(app_global_1.AppGlobals.API_ENDPOINT + url, data, { headers: reqHeader });
    };
    CommonService.prototype.formpost = function (url, data) {
        var access_token = localStorage.getItem('userToken');
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': access_token
        });
        return this.http.post(app_global_1.AppGlobals.API_ENDPOINT + url, data, { headers: reqHeader });
    };
    CommonService.prototype.refereeformpost = function (url, data) {
        var access_token = localStorage.getItem('userToken1');
        if (!access_token) {
            access_token = localStorage.getItem('userToken');
        }
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': access_token
        });
        return this.http.post(app_global_1.AppGlobals.API_ENDPOINT + url, data, { headers: reqHeader });
    };
    CommonService.prototype.formpostwithouttoken = function (url, data) {
        var access_token = localStorage.getItem('userToken');
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json'
        });
        return this.http.post(app_global_1.AppGlobals.API_ENDPOINT + url, data, { headers: reqHeader });
    };
    CommonService.prototype.formpatch = function (url, data) {
        var access_token = localStorage.getItem('userToken');
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': access_token
        });
        return this.http.patch(app_global_1.AppGlobals.API_ENDPOINT + url, data, { headers: reqHeader });
    };
    CommonService.prototype.refereeformpatch = function (url, data) {
        var access_token = localStorage.getItem('userToken1');
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': access_token
        });
        return this.http.patch(app_global_1.AppGlobals.API_ENDPOINT + url, data, { headers: reqHeader });
    };
    CommonService.prototype.formpatchwithouttoken = function (url, data) {
        var access_token = localStorage.getItem('userToken');
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json'
        });
        return this.http.patch(app_global_1.AppGlobals.API_ENDPOINT + url, data, { headers: reqHeader });
    };
    CommonService.prototype.getAddress = function (searchValue) {
        return this.http.get("https://api.getaddress.io/find/" + searchValue + "?api-key=nvy3UeN_uUawvYdusmIq_w11408");
    };
    CommonService.prototype.getAI = function (searchValue) {
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': "GET, POST, DELETE, PUT"
        });
        return this.http.post("http://127.0.0.1:5000/data", searchValue, { headers: reqHeader });
    };
    CommonService.prototype.validateMobileNumber = function (phone_number) {
        var access_key = '60b58a7264636db801b671f61690f0ad';
        return this.http.get("https://apilayer.net/api/validate?access_key=" + access_key + "&number=" + phone_number);
    };
    CommonService.prototype.changecurrentaddress = function (url, data) {
        var access_token = localStorage.getItem('userToken');
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': 'Bearer' + access_token
        });
        return this.http.post(app_global_1.AppGlobals.API_ENDPOINT + url, data, { headers: reqHeader });
    };
    CommonService.prototype.formpostreferencingapi = function (url, data) {
        var access_token = localStorage.getItem('userToken');
        var reqHeader = new http_1.HttpHeaders({ 'Content-Type': 'application/json'
        });
        return this.http.post(app_global_1.AppGlobals.SCREENING_API_ENDPOINT + url, data, { headers: reqHeader });
    };
    return CommonService;
}());
exports.CommonService = CommonService;
