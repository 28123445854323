<style>
  .input-file-container {
    position: relative;
    width: 225px;
  }

  .js .input-file-trigger {
    display: block;
    padding: 14px 45px;
    background: #39D2B4;
    color: #fff;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
  }

  .js .input-file {
    position: absolute;
    top: 0;
    left: 0;
    width: 225px;
    opacity: 0;
    padding: 14px 0;
    cursor: pointer;
  }

  .js .input-file:hover+.input-file-trigger,
  .js .input-file:focus+.input-file-trigger,
  .js .input-file-trigger:hover,
  .js .input-file-trigger:focus {
    background: #34495E;
    color: #39D2B4;
  }
</style>

<div class="bg">
<app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
      <div class="tabs" id="tabs">
        <div class="tabs__content">
          <div class="tabs__content__item active-tab">
            <div class="content">
              <app-topmenu class="row"></app-topmenu>
              <div class="row row-sm">
                <div class="col-lg-12">
                  <form [formGroup]="EmployeeForm" #employeeForm="ngForm" class="s12 white"
                    (ngSubmit)="onSubmit(employeeForm.value)">
                    <div class="row row-sm">
                      <div class="form-group col-lg-2">
                        <label>Email</label>
                        <input type="input" formControlName="emailid" class="" name="emailid" id="fname"
                          class="form-control" />
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Phone Number</label>
                        <input type="input" formControlName="phonenumber" class="" name="phonenumber" id="phonenumber"
                          class="form-control" />
                      </div>
                      <div class="form-group col-lg-2">
                        <label>NI Number</label>
                        <input type="input" formControlName="ninumber" class="" name="ninumber" id="ninumber"
                          class="form-control" />
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Passport Number</label>
                        <input type="input" formControlName="passportnumber" class="" name="passportnumber"
                          id="passportnumber" class="form-control" />
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Min Start Date*</label>
                        <mat-form-field class="datecontrol">
                          <input matInput [matDatepicker]="picker2" id="minpassportissue"
                            formControlName="minpassportissue" name="minpassportissue" />
                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                          <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Max Start Date*</label>
                        <mat-form-field class="datecontrol">
                          <input matInput [matDatepicker]="picker3" id="maxpassportissue"
                            formControlName="maxpassportissue" name="maxpassportissue" />
                          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                          <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row row-sm">
                      <div class="form-group col-lg-2">
                        <label>Min End Date*</label>
                        <mat-form-field class="datecontrol">
                          <input matInput [matDatepicker]="picker4" id="minpassportexpiry"
                            formControlName="minpassportexpiry" name="minpassportexpiry" />
                          <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                          <mat-datepicker #picker4></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Max End Date*</label>
                        <mat-form-field class="datecontrol">
                          <input matInput [matDatepicker]="picker5" id="maxpassportexpiry"
                            formControlName="maxpassportexpiry" name="maxpassportexpiry" />
                          <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                          <mat-datepicker #picker5></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Nationality</label>
                        <select name="nationalityid" formControlName="nationalityid" class="form-control input-lg"
                          id="nationalityid">
                          <option value="">Select Nationality</option>
                          <option *ngFor="let ad3 of countrylist" value="{{ ad3.countryid}}">{{
                              ad3.countryname }}</option>
                        </select>
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Current</label>
                        <select name="iscurrent" formControlName="iscurrent" class="form-control input-lg"
                          id="iscurrent">
                          <option value="">All Data</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div class="form-group col-lg-2">
                        <label>Status</label>
                        <select name="cstatus" formControlName="cstatus" class="form-control input-lg" id="cstatus">
                          <option value="">Select Status</option>
                          <option value="1">Pending</option>
                          <option value="2">Approved</option>
                          <option value="3">Rejected</option>
                        </select>
                      </div>
                    </div>
                    <div class="row row-sm">
                      <div class="form-group col-lg-2">
                        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                        <input type="submit" class="btn btn-primary" name="updateform" value="Search" />
                      </div>
                    </div>
                  </form>
                </div><!-- col-8 -->
              </div>

              <div class="row row-sm  mg-b-10">
                <div class="col-lg-12">
                  <a (click)="exportAsXLSX()" class="float-right"><i class="fa fa-file-excel-o"
                      style="font-size:30px;color:blue"></i></a>
                </div>
              </div>

              <div class="row row-sm">
                <div class="col-lg-12">
                  <table class="table table-striped tbreport" [mfData]="data" #mf="mfDataTable" [mfRowsOnPage]="10">
                    <thead>
                      <tr>
                        <th>
                          <mfDefaultSorter by="id">ID</mfDefaultSorter>
                        </th>
                        <th>
                          <mfDefaultSorter by="employee.fname">Name</mfDefaultSorter>
                        </th>
                        <th>
                          <mfDefaultSorter by="passportnumber">Passport Number</mfDefaultSorter>
                        </th>
                        <th>
                          <mfDefaultSorter by="passportnumber">Nationality</mfDefaultSorter>
                        </th>

                        <th>
                          <mfDefaultSorter by="passportissue">Start Date</mfDefaultSorter>
                        </th>
                        <th>
                          <mfDefaultSorter by="passportexpiry">End Date</mfDefaultSorter>
                        </th>
                        <th>
                          <mfDefaultSorter by="iscurrent">Is Current</mfDefaultSorter>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let passport of mf.data;  let i=index" [className]='passport.exclass'>
                        <td>{{ i +1 }}</td>
                        <td>
                          <div class="tooltip5"> {{passport.employee.fname}} {{passport.employee.mname}}
                            {{passport.employee.lname}}
                            <div class="tooltiptext">
                              <div class="dsfsadf">
                                <p><b>Email :</b> {{ passport.employee.emailid }}</p>
                                <p><b>Phone :</b> {{ passport.employee.phonenumber }}</p>
                                <p><b>NI Number :</b> {{ passport.employee.ninumber }}</p>
                                <p><b>Nationality :</b> {{ passport.employee.nationality.countryname }}</p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="tooltip5"> {{passport.passportnumber}}
                          </div>
                        </td>
                        <td>{{passport.nationality.countryname}}</td>
                        <td>{{passport.passportissue | date: 'dd MMM yyyy'}}</td>
                        <td>
                          <div class="tooltip5"> {{passport.passportexpiry | date: 'dd MMM yyyy'}}
                          </div>
                        </td>
                        <td>{{passport.iscurrent}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="7">
                          <mfBootstrapPaginator [rowsOnPageSet]="[10,15,20]"></mfBootstrapPaginator>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>