"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./confirmationapproval.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/router");
var i3 = require("@angular/common");
var i4 = require("./confirmationapproval.component");
var i5 = require("../../services/company.service");
var i6 = require("../../services/user.service");
var styles_ConfirmationapprovalComponent = [i0.styles];
var RenderType_ConfirmationapprovalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationapprovalComponent, data: {} });
exports.RenderType_ConfirmationapprovalComponent = RenderType_ConfirmationapprovalComponent;
function View_ConfirmationapprovalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "signin-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "new_login"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "login_section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [["class", "login_logo"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", ""], ["src", "/assets/img/logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["class", "header-notification float-right"], ["data-toggle", "dropdown"], ["href", "#"], ["title", "Logout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fa fa-power-off"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "signin-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "login_form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Under Review"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your account is under review. Please wait for the approval."])), (_l()(), i1.ɵeld(15, 0, null, null, 7, "div", [["class", "graphic_section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 6, "div", [["class", "slogan"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Welcome To "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Complygate"])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Commit. Control. Comply"])), (_l()(), i1.ɵeld(23, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = _ck(_v, 5, 0, "/"); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
exports.View_ConfirmationapprovalComponent_0 = View_ConfirmationapprovalComponent_0;
function View_ConfirmationapprovalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmationapproval", [], null, null, null, View_ConfirmationapprovalComponent_0, RenderType_ConfirmationapprovalComponent)), i1.ɵdid(1, 114688, null, 0, i4.ConfirmationapprovalComponent, [i5.CompanyService, i2.Router, i6.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ConfirmationapprovalComponent_Host_0 = View_ConfirmationapprovalComponent_Host_0;
var ConfirmationapprovalComponentNgFactory = i1.ɵccf("app-confirmationapproval", i4.ConfirmationapprovalComponent, View_ConfirmationapprovalComponent_Host_0, {}, {}, []);
exports.ConfirmationapprovalComponentNgFactory = ConfirmationapprovalComponentNgFactory;
