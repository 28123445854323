import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactinformation',
  templateUrl: './contactinformation.component.html',
  styleUrls: ['./contactinformation.component.css']
})
export class ContactinformationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
