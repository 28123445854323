import { Nationality } from './nationality.model';
import { Employee } from '../employee.model';

export class Visa {
  employeeid: number;
  employeevisadeatilid: number;
  visanumber: string;
  visatype: string;
  visastart: Date = new Date();
  visaexpiry: Date = new Date();
  visadocname: string;
  visabackdocname: string;
  nationality: Nationality;
  stype: number;
  iscurrent: boolean;
  employee: Employee;
}