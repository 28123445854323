"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./modal.component");
var i2 = require("../_services/modal.service");
var styles_ModalComponent = [];
var RenderType_ModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalComponent, data: {} });
exports.RenderType_ModalComponent = RenderType_ModalComponent;
function View_ModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "jw-modal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "jw-modal-body"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "jw-modal-background"]], null, null, null, null, null))], null, null); }
exports.View_ModalComponent_0 = View_ModalComponent_0;
function View_ModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "jw-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i0.ɵdid(1, 245760, null, 0, i1.ModalComponent, [i2.ModalService, i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ModalComponent_Host_0 = View_ModalComponent_Host_0;
var ModalComponentNgFactory = i0.ɵccf("jw-modal", i1.ModalComponent, View_ModalComponent_Host_0, { id: "id" }, {}, ["*"]);
exports.ModalComponentNgFactory = ModalComponentNgFactory;
