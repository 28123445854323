<div class="bg">
  <app-header></app-header>

  <div class="section-wrapper p-0">
    <div class="admin_dashboard_show">
      <div class="ceee">
        <div class="cardmenu">
         
            <a class="card_box_lit" [routerLink]="['/request/visa-request-list']">
              <div class="samebox">
                <i class="lnr lnr-license"></i>
                <div class="menu_content">
                    <h4>Visa Request</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
              
                
            </a>
          
         
            <a class="card_box_lit" [routerLink]="['/request/passport-request-list']" >
              <div class="samebox">
                <i class="lnr lnr-earth"></i>
                <div class="menu_content">
                    <h4>Passport Request</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
               
                
            </a>
         
         
            <a class="card_box_lit" [routerLink]="['/request/addressproof-request-list']">
              <div class="samebox">
                <i class="lnr lnr-book"></i>
                <div class="menu_content">
                    <h4>Address Proof Request</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
               
                
            </a>
          
        
            <a class="card_box_lit" [routerLink]="['/request/leave-request-list']">
              <div class="samebox">
                <i class="lnr lnr-pencil"></i>
                <div class="menu_content">
                    <h4>Leave Request</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
              
                
            </a>

            <a class="card_box_lit" [routerLink]="['/request/leave-sick-request-list']">
              <div class="samebox">
                <i class="lnr lnr-pencil"></i>
                <div class="menu_content">
                    <h4>Sick Leave Request</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
            </a>
          
         
            <a class="card_box_lit" [routerLink]="['/request/expense-request-list']">
              <div class="samebox">
                <i class="lnr lnr-gift"></i>
                <div class="menu_content">
                    <h4>Expense Request</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
               
                
            </a>
         
         
            <a class="card_box_lit" [routerLink]="['/request/timesheet-request-list']">
              <div class="samebox">
                <i class="lnr lnr-calendar-full"></i>
                <div class="menu_content">
                    <h4>Timesheet Request</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
                
                
            </a>
         
      </div>
      </div>
      <!-- <div class="row">
        <a  [routerLink]="['/request/visa-request-list']" class="homeset">
          <div class="dashboard-box">
            <h2>Visa Request</h2>
            <p class="dash-circle"><i class="fa fa-user" ></i></p>  
            <p class="dash-btm">          
              <i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i>
            </p>
           </div>
          </a>
          <a  [routerLink]="['/request/passport-request-list']"  class="homeset">
            <div class="dashboard-box">
               <h2>Passport Request</h2>
               <p class="dash-circle"><i class="fa fa-briefcase" ></i></p>
               <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
              </div>
             </a>
        
             <a  [routerLink]="['/request/idproof-request-list']"  class="homeset">
              <div class="dashboard-box">
                 <h2>Address Proof Request</h2>
                 <p class="dash-circle"><i class="fa fa-briefcase" ></i></p>
                 <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
                </div>
            </a>
        
           <a  [routerLink]="['/request/leave-request-list']"  class="homeset">
           <div class="dashboard-box">
            <h2 >Leave Request</h2>
              <p class="dash-circle" style="color:#fff;">
                <i class="fa fa-pencil" ></i>
              </p>
              <p class="dash-btm "><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
            
            </div></a> 
        
            <a  [routerLink]="['/request/expense-request-list']"  class="homeset">
             <div class="dashboard-box">
                <h2>Expense Request</h2>
                <p class="dash-circle"><i class="fa fa-gbp"></i></p>
                <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
               </div>
              </a>
              <a  [routerLink]="['/request/timesheet-request-list']"  class="homeset">
               <div class="dashboard-box">
                  <h2>Timesheet Request</h2>
                  <p class="dash-circle"><i class="fa fa-list-ul" ></i></p>
                  <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
                 </div>
                </a>
      </div> -->
    </div>
    <div class="container">
      <div class="tabs" id="tabs">

        <div class="tabs__sontent">
          <div class="tab2__wcontent__item active-tab" id="jan">
            <div class="content">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>