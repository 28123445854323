"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var visa_model_1 = require("../../../../../models/visa/visa.model");
var upload_file_service_1 = require("../../../../../services/upload-file.service");
var employee_model_1 = require("../../../../../models/employee.model");
var fileupload_service_1 = require("../../../../../services/fileupload.service");
var myvisa_service_1 = require("../../../../../services/myvisa.service");
var alert_service_1 = require("../../../../../services/alert.service");
var EmployeeaidetailComponent = /** @class */ (function () {
    function EmployeeaidetailComponent(sanitizer, formBuilder, visaService, activatedRoute, filupload, uploadService, alerts, router) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.visaService = visaService;
        this.activatedRoute = activatedRoute;
        this.filupload = filupload;
        this.uploadService = uploadService;
        this.alerts = alerts;
        this.router = router;
        this.visadetails = new visa_model_1.Visa();
        this.employee = new employee_model_1.Employee();
    }
    EmployeeaidetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.requestperm = this.permission.m15;
        this.ctype = parseInt(localStorage.getItem('ctype'));
        this.employeevisadeatilid = this.activatedRoute.snapshot.paramMap.get("visaid");
        this.data3 = { "employeevisadeatilid": this.employeevisadeatilid };
        this.visaService.visadetailsbyvisaid(this.data3).subscribe(function (data) {
            _this.employeemasterid = data.employee.employeemasterid;
            _this.visadetails = data;
            _this.record = data.length;
        });
    };
    EmployeeaidetailComponent.prototype.selectFile = function (event, type) {
        var _this = this;
        type = parseInt(type);
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        var file = this.selectedFiles;
        this.fileformaterror = false;
        this.filesizeerror = false;
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
            this.alerts.success('File size must be less than 2 MB.', true);
            return;
        }
        else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png")) {
            this.fileformaterror = true;
            this.alerts.success('Please choose a valid file format (jpg, png,ttf,gif)', true);
            return;
        }
        var imagepath = this.uploadService.uploadfile(file);
        if (type == 0) {
            this.visadetails.visadocname = '../../../../../../../assets/img/loadings.gif';
        }
        else if (type == 1) {
            this.visadetails.visabackdocname = '../../../../../../../assets/img/loadings.gif';
        }
        var dbimage = { "id": this.employeevisadeatilid, "imagename": imagepath, "employeid": this.employeemasterid, itype: type };
        this.visaService.visaupdateForAI(dbimage).subscribe(function (data) {
            if (type == 0) {
                setTimeout(function () { return _this.visadetails.visadocname = imagepath; }, 3000);
            }
            else if (type == 1) {
                setTimeout(function () { return _this.visadetails.visabackdocname = imagepath; }, 3000);
            }
        });
    };
    return EmployeeaidetailComponent;
}());
exports.EmployeeaidetailComponent = EmployeeaidetailComponent;
