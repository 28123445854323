import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { CommonService } from '../../../services/common.service';
import { CountryService } from '../../../services/country.service';
import { CompanyService } from '../../../services/company.service';
import { JobService } from '../../../services/job.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Jobs } from '../../../models/jobs/jobs.model';
import { MatOptionSelectionChange } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFileService } from '../../../services/upload-file.service';
import { Country } from '../../../models/address/country.model';
import { Companyprofile, License, Plan, Countcompanydata } from "../../../models/company/companyprofile.model";
import { AlertService } from '../../../services/alert.service';
import { Router } from '@angular/router';
import { EmployeeService } from '../../../services/employee.service';
import { GraphreportService } from '../../../services/graphreport.service';


@Component({
  selector: 'app-billinginformation',
  templateUrl: './billinginformation.component.html',
  styleUrls: ['./billinginformation.component.css']
})
export class BillinginformationComponent implements OnInit {

  permission: any;
  profileperm: any;
  companylocationlist: any;
  Userid: any;
  companytypelist: any;
  companyprofileDetail = new Companyprofile();
  companyplanDetails: any;
  countcompanydata = new Countcompanydata();
  weburl:any;
  ctype:any;
  currentDate = new Date();
  planStartDate = new Date();
  planRenewDate = new Date();
  planExpiredOn = new Date();


  constructor( private sanitizer: DomSanitizer, 
    private commonService: CommonService, 
    private modalService: ModalService, 
    private employeeService: EmployeeService, 
    private graphService: GraphreportService, 
    private formBuilder: FormBuilder,
    private country: CountryService,
    private uploadService: UploadFileService,
    private company: CompanyService,
    private router: Router,
    private alerts: AlertService) { }

  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    this.ctype  = parseInt(localStorage.getItem('ctype'));


    console.log(this.ctype);
    // if(ctype ==1){
    //   this.router.navigate(['/check-dashboard']);
    // }

    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.profileperm = this.permission.m91;

    this.companyprofileDetail  = new Companyprofile();
    this.companyprofileDetail.license  = new License();
    this.companyprofileDetail.plan  = new Plan();
    this.countcompanydata = new Countcompanydata();
    this.Userid = localStorage.getItem('userid');


    this.company.getcompanylocation().subscribe((locationlist: any) => {      
      this.companylocationlist = locationlist;      
    });

    this.company.getcountCompanyData().subscribe((countcompanydata: any) => {
      this.countcompanydata = countcompanydata;
    });

    this.company.getnextbillingdate().subscribe((countcompanydata3: any) => {
    //  console.log(countcompanydata3);
    });
    
    
    this.company.getcompanyData().subscribe((companydata: any) => {

      
      
      this.companyprofileDetail = companydata;
      if(companydata.websiteurl)
        this.weburl =companydata.websiteurl.replace('https://','').replace('http://','');
      else
        this.weburl ='';
      
      if(!this.companyprofileDetail.license)
      {
        this.companyprofileDetail.license  = new License();
      }
    });

    
    this.company.getplanDetails().subscribe((plandata: any) => {
      var firstDateArray = [];
      var lastDateArray = [];
      var min = 0;
      var max = 0;
      var idArray = [];
      plandata.forEach(function (value) {
        idArray.push(value.planuserdetailid);
        firstDateArray[value.planuserdetailid] = value.joiningdate;
        lastDateArray[value.planuserdetailid] = value.expireddate;
      });
      min = Math.min.apply(null, idArray);
      max = Math.max.apply(null, idArray);
      this.planStartDate = new Date(firstDateArray[min]);
      var year = this.planStartDate.getFullYear();
      var month = this.planStartDate.getMonth();
      var day = this.planStartDate.getDate();
      this.planRenewDate = new Date(year + 2, month, day);
      this.planExpiredOn = new Date(lastDateArray[max]);
      this.companyplanDetails = plandata;
    });

  }

}
