<div class="bg">
    <app-header></app-header>

    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
              <div class="backbutton">
                <!-- <h4 class="headprofile float-left" style="padding-left: 26px;">{{ jobDetail.jobtittle }}</h4> -->
                <a class="backlist mb-4 mr-0 " [routerLink]="['/listjob']">
                    <i class="fa fa-angle-left" aria-hidden="true"></i> Back to Job List

                </a>
            </div>
              <div class="overviewsshow">
                  <div class="overview_details width100">
                      <div class="alignbbb">
                        <h3 class="card-profile-name">{{ jobDetail.jobtittle }}</h3>
                      </div>
                      <hr class="mt-0"/>
                     
                        <div class="profile_dtailsshow mb-3"  *ngIf="jobDetail">
                            <div class="detailsshow width25">
                                <span>Contact Name</span>
                                <p class="pr-3">{{jobDetail.contactname}}</p>
                            </div>
                            <div class="detailsshow width25">
                              <span>Email</span>
                              <p class="pr-3">{{jobDetail.emailid}}</p>
                            </div>
                            <div class="detailsshow width25">
                              <span>Phone No</span>
                              <p class="pr-3">{{jobDetail.phonenumber}}</p>
                            </div>
                            <div class="detailsshow width25">
                              <span>Job Created On</span>
                              <p>{{jobDetail.jobdate | date: 'dd MMM yyyy'}}</p>
                            </div>
                            <div class="detailsshow width25">
                            <span>Closing Date</span>
                            <p>{{jobDetail.closedate | date: 'dd MMM yyyy'}}</p>
                            </div>
                            <div class="detailsshow width25">
                              <span>Qualification</span>
                              <p class="pr-3">{{jobDetail.qualification}}</p>
                            </div>
                            <div class="detailsshow width25">
                              <span>Salary</span>
                              <p class="text-danger"><b>£ {{jobDetail.salary}} - £ {{jobDetail.maxsalary}}</b></p>
                            </div>
                        </div>
                        <div class="profile_dtailsshow">
                            <div class="detailsshow width70">
                              <!-- <span>Address</span> -->
                                <p><i class="lnr lnr-map-marker"></i> {{jobDetail.address1}}, {{jobDetail.address2}}, {{jobDetail.cityname}} ,{{jobDetail.countyname}} {{jobDetail.country.countryname}} - {{jobDetail.postalcode}} </p>
                            </div>
                        </div>
                      
                    
                  </div>
              </div>

              
                <!-- <div class="location_section" *ngIf="jobDetail">
                    <div class="location_box jobwidth">
                      
                        <div class="location_list">
                            <p class="location_head">Job Created On</p>
                            <p class="location_text">{{jobDetail.jobdate | date: 'dd MMM yyyy'}}</p>
                        </div>
                        <div class="location_list">
                            <p class="location_head">Closing Date</p>
                            <p class="location_text">{{jobDetail.closedate | date: 'dd MMM yyyy'}}</p>
                        </div>
                        <div class="location_list">
                            <p class="location_head">Qualification</p>
                            <p class="location_text">{{jobDetail.qualification}}</p>
                        </div>
                    </div>

                    <div class="location_box jobwidth">
                        <div class="location_list">
                            <p class="location_head">Email</p>
                            <p class="location_text">{{jobDetail.emailid}}</p>
                        </div>
                        <div class="location_list">
                            <p class="location_head">Contact Name</p>
                            <p class="location_text">{{jobDetail.contactname}}</p>
                        </div>
                        <div class="location_list">
                            <p class="location_head">Phone No</p>
                            <p class="location_text">{{jobDetail.phonenumber}}</p>
                        </div>
                        <div class="location_list">
                            <p class="location_head">Salary</p>
                            <p class="location_text">£ {{jobDetail.salary}} - £ {{jobDetail.maxsalary}}</p>
                        </div>
                    </div>
                    <div class="location_box jobwidth">
                        <div class="location_list">
                            <p class="location_head">Job Address 1</p>
                            <p class="location_text">{{jobDetail.address1}}</p>
                        </div>
                        <div class="location_list">
                            <p class="location_head">Job Address 2</p>
                            <p class="location_text">{{jobDetail.address2}}</p>
                        </div>
                        <div class="location_list">
                            <p class="location_head">City</p>
                            <p class="location_text">{{jobDetail.cityname}} ,{{jobDetail.countyname}} </p>
                        </div>
                        <div class="location_list">
                            <p class="location_head">Country & Zip</p>
                            <p class="location_text">{{jobDetail.country.countryname}} - {{jobDetail.postalcode}}</p>
                        </div>
                    </div>
                </div> -->





                <div class="tabws__content">


                    <div class="job_details mb-4 mr-0 ml-0">
                        <p><b>SOC Code: </b>({{jobDetail.soc.socnumber}}) {{jobDetail.soc.socname}}</p>
                        <div [innerHTML]="jobDetail.jobdescreption"></div>
                    </div>

                    <div class="job_details mb-4 mr-0 ml-0">
                        <div class="job_skills_set">
                            <div class="skill_list">
                              <div class="colmElmProType" *ngFor="let skill of skilldata;  let i=index">
                                  <label class="d-flex skillbg">
                                    {{skill.skillname}}
                                    <i class="fa fa-close" (click)="onDelete(skill.jobskilldetailid)"></i>
                                  </label>
                                  
                              </div>
                              <div class="job_list_section visa_request_section width100" *ngIf="skilldata == 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                              </div>   
                            </div>
                        </div>
                        <!-- <div class="row">
                          <div class="col-lg-3" *ngFor="let skill of skilldata;  let i=index">
                              <div class="job_list_section idproof_request_section visa_request_section">


                                  <div class="job_detils_date width75">
                                      <p class="job_address">Job SKills</p>
                                      <p class="job_profile">{{skill.skillname}}</p>
                                  </div>

                                  <div class="job_detils_date width25">
                                      <p class="job_address ">
                                          <button type="submit" class="btn btn-danger com_btn" (click)="onDelete(skill.jobskilldetailid)"> <i class="fa fa-trash"></i></button>
                                      </p>

                                  </div>
                              </div>
                          </div>
                      </div> -->
                    </div>

                    <!-- <div class="overviewsshow">
                        <div class="header" *ngFor="let screen of jobDetail.screen">
                          <h2 class="screen_heading" *ngIf="screen.screenshotdayid=='1'">Screenshot Day 1</h2> <button type="button" class="addscreensuccess" (click)="openModal4(screen.id,'custom-add-job-screen', screen.screenshotdayid);">Add Job
                            Screenshot</button>
                                      <h2 class="screen_heading" *ngIf="screen.screenshotdayid=='2'">Screenshot Day 15 </h2><button type="button" class="addscreensuccess" (click)="openModal4(screen.id,'custom-add-job-screen', screen.screenshotdayid);">Add Job
                              Screenshot</button>
                                      <h2 class="screen_heading" *ngIf="screen.screenshotdayid=='3'">Screenshot Day 30 </h2><button type="button" class="addscreensuccess" (click)="openModal4(screen.id,'custom-add-job-screen', screen.screenshotdayid);">Add Job
                              Screenshot</button>
                                      <h2 class="screen_heading" *ngIf="screen.screenshotdayid=='4'">Screenshot Day 45 </h2><button type="button" class="addscreensuccess" (click)="openModal4(screen.id,'custom-add-job-screen', screen.screenshotdayid);">Add Job
                              Screenshot</button>
                        </div>

                        <div class="row"></div>
                    </div> -->
                    <div class="screenshort overviewsshow" *ngFor="let screen of jobDetail.screen">
                        <div >
                            <div>
                                <h2 class="screen_heading  mt-0" *ngIf="screen.screenshotdayid=='1'">Screenshot Day 1 <button type="button" class="addscreensuccess" (click)="openModal4(screen.id,'custom-add-job-screen', screen.screenshotdayid);">Add Job
                      Screenshot</button></h2>
                               
                                <h2 class="screen_heading mt-0" *ngIf="screen.screenshotdayid=='2'">Screenshot Day 15 <button type="button" class="addscreensuccess" (click)="openModal4(screen.id,'custom-add-job-screen', screen.screenshotdayid);">Add Job
                        Screenshot</button></h2>
                                
                                <h2 class="screen_heading mt-0" *ngIf="screen.screenshotdayid=='3'">Screenshot Day 30 <button type="button" class="addscreensuccess" (click)="openModal4(screen.id,'custom-add-job-screen', screen.screenshotdayid);">Add Job
                        Screenshot</button></h2>
                               
                                <h2 class="screen_heading mt-0" *ngIf="screen.screenshotdayid=='4'">Screenshot Day 45 <button type="button" class="addscreensuccess" (click)="openModal4(screen.id,'custom-add-job-screen', screen.screenshotdayid);">Add Job
                        Screenshot</button></h2>
                              
                            </div>






                            <div class="import_image_section">
                                <div class="import_file_img" *ngIf="screen.urldocument1!=null">
                                    <div class="import_img">
                                        <i class="fa fa-file-image-o" aria-hidden="true"></i>
                                    </div>
                                    <div class="import_file_name">
                                        <p>{{jobDetail.joburl1 | slice:0:100 }}</p>



                                    </div>
                                    <div class="import_right">
                                        <div class="check">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="import_footer">
                                        <div class="footer_imp_le">
                                            <!-- <p>File Size :<span>284 KB</span></p> -->
                                        </div>
                                        <div class="footer_imp_ri">
                                            <i class="fa fa-file-image-o" (click)="openModal5('pdf-image-viewer', screen.urldocument1)"></i>
                                            <i class="fa fa-trash" (click)="deleteFile(jobDetail.jobmasterid, screen.id, 'document1')"></i>
                                            <a href="{{screen.urldocument1}}"><i class="fa fa-download"></i></a>
                                        </div>
                                        <div class="clearfix"></div>

                                    </div>
                                </div>

                                <div class="import_file_img" *ngIf="screen.urldocument2!=null">
                                    <div class="import_img">
                                        <i class="fa fa-file-image-o" aria-hidden="true"></i>
                                    </div>
                                    <div class="import_file_name">
                                        <p>{{  jobDetail.joburl2 | slice:0:100  }}</p>
                                    </div>
                                    <div class="import_right">
                                        <div class="check">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="import_footer">
                                        <div class="footer_imp_le">
                                            <!-- <p>File Size :<span>284 KB</span></p> -->
                                        </div>
                                        <div class="footer_imp_ri">
                                            <i class="fa fa-file-image-o" (click)="openModal5('pdf-image-viewer', screen.urldocument2)"></i>
                                            <i class="fa fa-trash" (click)="deleteFile(jobDetail.jobmasterid, screen.id, 'document2')"></i>
                                            <a href="{{screen.urldocument2}}"><i class="fa fa-download"></i></a>
                                        </div>
                                        <div class="clearfix"></div>

                                    </div>
                                </div>

                                <div class="import_file_img" *ngIf="screen.urldocument3 != null">
                                    <div class="import_img">
                                        <i class="fa fa-file-image-o" aria-hidden="true"></i>
                                    </div>
                                    <div class="import_file_name">
                                        <p>{{  jobDetail.joburl3 | slice:0:100  }}</p>
                                    </div>
                                    <div class="import_right">
                                        <div class="check">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="import_footer">
                                        <div class="footer_imp_le">
                                            <!-- <p>File Size :<span>284 KB</span></p> -->
                                        </div>
                                        <div class="footer_imp_ri">
                                            <i class="fa fa-file-image-o" (click)="openModal5('pdf-image-viewer', screen.urldocument3)"></i>
                                            <i class="fa fa-trash" (click)="deleteFile(jobDetail.jobmasterid, screen.id, 'document3')"></i>
                                            <a href="{{screen.urldocument3}}"><i class="fa fa-download"></i></a>
                                        </div>
                                        <div class="clearfix"></div>

                                    </div>
                                </div>
                                <div class="import_file_img" *ngIf="screen.urldocument4!=null">
                                    <div class="import_img">
                                        <i class="fa fa-file-image-o" aria-hidden="true"></i>
                                    </div>
                                    <div class="import_file_name">
                                        <p>{{  jobDetail.joburl4 | slice:0:100  }}</p>
                                    </div>
                                    <div class="import_right">
                                        <div class="check">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="import_footer">
                                        <div class="footer_imp_le">
                                            <!-- <p>File Size :<span>284 KB</span></p> -->
                                        </div>
                                        <div class="footer_imp_ri">
                                            <i class="fa fa-file-image-o" (click)="openModal5('pdf-image-viewer', screen.urldocument4)"></i>
                                            <i class="fa fa-trash" (click)="deleteFile(jobDetail.jobmasterid, screen.id, 'document4')"></i>
                                            <a href="{{screen.urldocument4}}"><i class="fa fa-download"></i></a>
                                        </div>
                                        <div class="clearfix"></div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="job_list_section visa_request_section width100" *ngIf="jobDetail.screen == 0">
                          <div class="job_detils_date">
                              <p class="job_profile">No Record Found</p>
                          </div>
                      </div>       
                        <!-- <div *ngIf="jobDetail.screen == 0">
                          No Recode Found
                      </div> -->
                    </div>

                </div>
            </div>
        </div>


        <!-- 
  <jw-modal id="custom-visa-1" role="dialog" class="modal">

    <form [formGroup]="jobformGroup" #jobForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(jobForm.value,'custom-visa-1')">
      <div role="document" class="formgroup">
        <div class="modal-content">
          <div class="modal-header">

            <input type="hidden" formControlName="jobmasterid" class="form-control" name="jobmasterid" id="jobmasterid"
              ngModel="{{jobDetail.jobmasterid}}">
            <input type="hidden" formControlName="urldocumentid" class="form-control" name="urldocumentid" id="urldocumentid"
              ngModel="{{jobDetail.urldocumentid}}">

            <h5 class="modal-title" id="popupLabel">Job Details</h5>
            <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-visa-1');">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <div class="form-group col-lg-12">
                  <label>Job Title *</label>
                  <input type="text" formControlName="jobtittle" class="" name="jobtittle" id="jobtittle" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.jobtittle.errors }" ngModel="{{ jobDetail.jobtittle }}">
                  <div *ngIf="submitted && f.jobtittle.errors" class="invalid-feedback">
                    <div *ngIf="f.jobtittle.errors.required">Please Enter Job Title</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label>Job Posted Date *</label>
                  <mat-form-field class="datecontrol">
                    <input matInput [matDatepicker]="picker1" name="jobdate" id="jobdate" [ngClass]="{ 'is-invalid': submitted && f.jobdate.errors }"
                      formControlName="jobdate" class="form-control" [max]="jobformGroup.controls.closedate.value"
                      ngModel='{{ jobDetail.jobdate }}'>
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                  <div *ngIf="submitted && f.jobdate.errors" class="invalid-feedback">
                    <div *ngIf="f.jobdate.errors.required">Please Choose Job Posted Date</div>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label>Closing Date *</label>
                  <mat-form-field class="datecontrol">
                    <input matInput [matDatepicker]="picker2" name="closedate" id="closedate" [ngClass]="{ 'is-invalid': submitted && f.closedate.errors }"
                      formControlName="closedate" class="form-control" [min]="jobformGroup.controls.jobdate.value"
                      ngModel='{{ jobDetail.closedate }}'>
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                  <div *ngIf="submitted && f.closedate.errors" class="invalid-feedback">
                    <div *ngIf="f.closedate.errors.required">Please Choose Closing Date</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-lg-6">
                  <label>Contact Name *</label>
                  <input type="text" formControlName="contactname" class="" name="contactname" id="contactname" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.contactname.errors }" ngModel="{{ jobDetail.contactname }}">
                  <div *ngIf="submitted && f.contactname.errors" class="invalid-feedback">
                    <div *ngIf="f.contactname.errors.required">Please Enter Contact Name.</div>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label>Role*</label>
                  <input type="text" formControlName="contactrole" class="" name="contactrole" id="contactrole" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.contactrole.errors }" ngModel="{{ jobDetail.contactrole }}"
                    ngModel="{{ jobDetail.contactrole }}">
                  <div *ngIf="submitted && f.contactrole.errors" class="invalid-feedback">
                    <div *ngIf="f.contactrole.errors.required">Please Enter Role. </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-lg-6">
                  <label>Contact Email *</label>
                  <input type="email" formControlName="emailid" class="" name="emailid" id="emailid" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }" ngModel="{{ jobDetail.emailid }}"
                    ngModel="{{ jobDetail.emailid }}">
                  <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                    <div *ngIf="f.emailid.errors.required">Please Enter Contact Email </div>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label>Phone Number *</label>
                  <international-phone-number formControlName="phonenumber" class="form-control phonecontrol" [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }"
                    [(ngModel)]="jobDetail.phonenumber" placeholder="Enter mobile number" (input)="validatenumber($event.target.value,'phone')"
                    [maxlength]="20" [required]="true" #phonenumber name="phonenumber"></international-phone-number>
                  <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                    <div *ngIf="f.phonenumber.errors.required">Please Enter Phone Number</div>
                  </div>
                  <span *ngIf="phonecheck==false" style="color:red">Invalid format</span>
                  <span *ngIf="phonecheck==true" style="color:green">Valid format</span>

                </div>
              </div>


              <div class="row">
                <div class="form-group col-lg-4">
                  <label>Job URL 1 *</label>
                  <input type="text" formControlName="joburl1" class="" name="joburl1" id="joburl1" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.joburl1.errors }" ngModel="{{ jobDetail.joburl1 }}"
                    ngModel="{{ jobDetail.joburl1 }}">

                  <div *ngIf="submitted && f.joburl1.errors" class="invalid-feedback">
                    <div *ngIf="f.joburl1.errors.required">Please Enter Job URL 1 </div>
                  </div>
                </div>
                <div class="form-group col-lg-2 fpupload" (click)="file1.click()">
                  <label>&nbsp;</label>
                  <div class="bootstrap-filestyle input-group">
                    <input type="file" #file1 (change)="selectFile($event,'file1')" />
                    <label class="btn btn-secondary ">
                      <span class="buttonText">Choose file</span>
                    </label>
                  </div>
                </div>


                <div class="form-group col-lg-4">
                  <label>Job URL 2 *</label>
                  <input type="text" formControlName="joburl2" class="" name="joburl2" id="joburl2" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.joburl2.errors }" ngModel="{{ jobDetail.joburl2 }}"
                    ngModel="{{ jobDetail.joburl2 }}">

                  <div *ngIf="submitted && f.joburl2.errors" class="invalid-feedback">
                    <div *ngIf="f.joburl2.errors.required">Please Enter Job URL 2 </div>
                  </div>
                </div>
                <div class="form-group col-lg-2 fpupload" (click)="file2.click()">
                  <label>&nbsp;</label>
                  <div class="bootstrap-filestyle input-group">
                    <input type="file" #file2 (change)="selectFile($event,'file2')" />
                    <label class="btn btn-secondary ">
                      <span class="buttonText">Choose file</span>
                    </label>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="form-group col-lg-4">
                  <label>Job URL 3</label>
                  <input type="text" formControlName="joburl3" class="" name="joburl3" id="joburl3" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.joburl3.errors }" ngModel="{{ jobDetail.joburl3 }}"
                    ngModel="{{ jobDetail.joburl3 }}">

                  <div *ngIf="submitted && f.joburl3.errors" class="invalid-feedback">
                    <div *ngIf="f.joburl3.errors.required">Please Enter Job URL 3 </div>
                  </div>
                </div>
                <div class="form-group col-lg-2 fpupload" (click)="file3.click()">
                  <label>&nbsp;</label>
                  <div class="bootstrap-filestyle input-group">
                    <input type="file" #file3 (change)="selectFile($event,'file3')" />
                    <label class="btn btn-secondary ">
                      <span class="buttonText">Choose file</span>
                    </label>
                  </div>
                </div>

                <div class="form-group col-lg-4">
                  <label>Job URL 4</label>
                  <input type="text" formControlName="joburl4" class="" name="joburl4" id="joburl4" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.joburl4.errors }" ngModel="{{ jobDetail.joburl4 }}"
                    ngModel="{{ jobDetail.joburl4 }}">

                  <div *ngIf="submitted && f.joburl4.errors" class="invalid-feedback">
                    <div *ngIf="f.joburl4.errors.required">Please Enter Job URL 4 </div>
                  </div>
                </div>
                <div class="form-group col-lg-2 fpupload" (click)="file4.click()">
                  <label>&nbsp;</label>
                  <div class="bootstrap-filestyle input-group">
                    <input type="file" #file4 (change)="selectFile($event,'file4')" />
                    <label class="btn btn-secondary ">
                      <span class="buttonText">Choose file</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-6">
                  <label for="example-text-input" class="col-sm-12 col-form-label">Salary (£) <span>*</span></label>
                  <div class="row" style="margin-left:0px;">
                    <div class="col-sm-3" style="padding-left:0px;">
                      <input name="salary" formControlName="salary" ngModel="{{ jobDetail.salary }}" [ngClass]="{ 'is-invalid': submitted && f.salary.errors }"
                        type="text" maxlength="7" id="salary" class="form-control nb" required="" data-parsley-type="number"
                        placeholder="Min Salary">
                      <div *ngIf="submitted && f.salary.errors" class="invalid-feedback">
                        <div *ngIf="f.salary.errors.required">Please Enter Minimum Salary </div>
                      </div>
                    </div>
                    <div class="col-sm-3  m-l-10">
                      <input name="maxsalary" formControlName="maxsalary" ngModel="{{ jobDetail.maxsalary }}" type="text"
                        maxlength="7" id="maxsalary" class="form-control nb" required="" data-parsley-type="number"
                        placeholder="Max Salary">
                      <div *ngIf="submitted && f.maxsalary.errors" class="invalid-feedback">
                        <div *ngIf="f.maxsalary.errors.required">Please Enter Maximum Salary </div>
                      </div>
                    </div>
                    <div class="col-sm-5  m-l-10">
                      <select name="stypeid" formControlName="stypeid" id="stypeid" class="form-control" required="">
                        <option value="">Select Salary Type</option>
                        <option value="1" [selected]="jobDetail.stypeid=='1'">Per Month</option>
                        <option value="2" [selected]="jobDetail.stypeid=='2'">Per Year</option>
                      </select>
                      <div *ngIf="submitted && f.stypeid.errors" class="invalid-feedback">
                        <div *ngIf="f.stypeid.errors.required">Please Select Salary Type </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6" style="display:none;">
                  <label>Hours of Work Per Week <span>*</span></label>
                  <input name="ctl00$ContentPlaceHolder1$txtHoursofWorks" type="text" value="10" maxlength="2" id="ContentPlaceHolder1_txtHoursofWorks"
                    class="form-control nb" required="" data-parsley-type="number">
                </div>
                <div class="col-sm-6">
                  <label>SOC Code <span>*</span></label>
                  <select style="width:100%;" formControlName="socid" name="socid" id="socid" class="form-control input-lg"
                    [ngClass]="{ 'is-invalid': submitted && f.socid.errors }" ngModel="{{ jobDetail.socid }}">
                    <option value="">Select SOC Code </option>
                    <option *ngFor="let soc of socdata" value='{{ soc.socid }}' [selected]="soc.socid == jobDetail.socid">
                      ({{ soc.socnumber }}){{ soc.socname }}</option>
                  </select>
                  <div *ngIf="submitted && f.socid.errors" class="invalid-feedback">
                    <div *ngIf="f.socid.errors.required">Please Select SOC Code</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-lg-6">
                  <label>Postcode *</label>
                  <input type="pincode" formControlName="postalcode" id="postalcode" name="postalcode" class="form-control pincode"
                    [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" ngModel='{{ jobDetail.postalcode }}'
                    (input)="onSearchChange($event.target.value)" matInput [matAutocomplete]="auto">
                  <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                    <div *ngIf="f.postalcode.errors.required">Please Enter Postcode</div>
                  </div>
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="pincode">
                    <mat-option *ngFor="let option of options" [value]="option" (onSelectionChange)="updatecode($event, option)">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </div>
                <div class="form-group col-lg-6">
                  <label>Address Line 1 *</label>
                  <input type="text" formControlName="address1" class="" name="address1" id="address1" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" ngModel="{{ jobDetail.address1 }}"
                    ngModel="{{ jobDetail.address1 }}">
                  <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
                    <div *ngIf="f.address1.errors.required">Please Enter Address Line 1</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-lg-6">
                  <label>Address Line 2 </label>
                  <input type="text" formControlName="address2" class="" name="address2" id="address2" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" ngModel="{{ jobDetail.address2 }}"
                    ngModel="{{ jobDetail.address2 }}">
                  <div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
                    <div *ngIf="f.address2.errors.required">Please Enter Address Line 2 </div>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label>City *</label>
                  <input type="text" formControlName="cityname" class="" name="cityname" id="cityname" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.cityname.errors }" ngModel="{{ jobDetail.cityname }}"
                    ngModel="{{ jobDetail.cityname }}">
                  <div *ngIf="submitted && f.cityname.errors" class="invalid-feedback">
                    <div *ngIf="f.cityname.errors.required">Please Enter City </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-lg-6">
                  <label>County *</label>
                  <select style="width:100%;" formControlName="countyid" name="countyid" id="countyid" class="form-control input-lg"
                    [ngClass]="{ 'is-invalid': submitted && f.countyid.errors }" ngModel="{{ jobDetail.countyid }}">
                    <option value="">Select County </option>
                    <option *ngFor="let county of countylist" value='{{ county.stateid }}' [selected]="county.stateid == jobDetail.countyid">{{
                      county.statename }}</option>
                  </select>
                  <div *ngIf="submitted && f.countyid.errors" class="invalid-feedback">
                    <div *ngIf="f.countyid.errors.required">Please Select County</div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <label>Country *</label>
                  <select style="width:100%;" disabled formControlName="countryid" name="countryid" id="countryid"
                    class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.countryid.errors }" ngModel="{{ jobDetail.countryid }}">
                    <option value="">Select Country </option>
                    <option *ngFor="let country of countrylist" value='{{ country.countryid }}' [selected]="country.countryid == jobDetail.countryid">{{
                      country.countryname }}</option>
                  </select>
                  <div *ngIf="submitted && f.countryid.errors" class="invalid-feedback">
                    <div *ngIf="f.countryid.errors.required">Please Select Country</div>
                  </div>

                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label>Qualification</label>
                  <input type="text" formControlName="qualification" class="" name="qualification" id="qualification"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.qualification.errors }" ngModel="{{ jobDetail.qualification }}"
                    ngModel="{{ jobDetail.qualification }}">
                  <div *ngIf="submitted && f.qualification.errors" class="invalid-feedback">
                    <div *ngIf="f.qualification.errors.required">Please Enter Qualification </div>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label>Number of Positions *</label>
                  <select style="width:100%;" formControlName="addposition" name="addposition" id="addposition" class="form-control input-lg"
                    [ngClass]="{ 'is-invalid': submitted && f.addposition.errors }" ngModel="{{ jobDetail.addposition }}">
                    <option value="">Select Position </option>
                    <option value='1' [selected]="jobDetail.addposition == '1'">1</option>
                    <option value='2' [selected]="jobDetail.addposition == '2'">2</option>
                    <option value='3' [selected]="jobDetail.addposition == '3'">3</option>
                    <option value='4' [selected]="jobDetail.addposition == '4'">4</option>
                    <option value='5' [selected]="jobDetail.addposition == '5'">5</option>
                    <option value='6' [selected]="jobDetail.addposition == '6'">6</option>
                    <option value='7' [selected]="jobDetail.addposition == '7'">7</option>
                    <option value='8' [selected]="jobDetail.addposition == '8'">8</option>
                    <option value='9' [selected]="jobDetail.addposition == '9'">9</option>
                    <option value='10' [selected]="jobDetail.addposition == '10'">10</option>
                  </select>
                  <div *ngIf="submitted && f.addposition.errors" class="invalid-feedback">
                    <div *ngIf="f.addposition.errors.required">Please Select Number of Positions </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-lg-12">
                  <label>Job Description</label>
                   <ckeditor [editor]="Editor" #jobdescreption formControlName="jobdescreption" [(ngModel)]="jobDetail.jobdescreption"
                    [style.height.px]="250"></ckeditor> 
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <input type="submit" class="btn btn-primary" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
            <input type="button" class="btn btn-primary" name="updateform" value="Cancel" (click)="closeModal('custom-visa-1');" />
          </div>
        </div>
      </div>
    </form>
  </jw-modal> -->




        <jw-modal id="custom-add-job-screen" role="dialog" class="modal">
            <form [formGroup]="jobscreenformGroup" #jobscreenForm="ngForm" class="s12 white" (ngSubmit)="addJobScreen(jobscreenForm.value,'custom-add-job-screen')">
                <input type="hidden" formControlName="jobmasterid" class="form-control" name="jobmasterid" id="jobmasterid" ngModel="{{jobDetail.jobmasterid}}">
                <input type="hidden" formControlName="id" class="form-control" name="id" id="id" ngModel="{{ screenshotid }}">
                <div role="document" class="formgroup">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="popupLabel">Job Screenshot

                                <!-- <button type="button" (click)="closeModal('custom-add-job-screen');">Back</button> -->

                            </h5>
                            <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-add-job-screen');">
              <span aria-hidden="true">&times;</span>
            </button>
                        </div>
                        <div class="modal-body">
                            <div class="container">
                                <div class="row" *ngIf="jobDetail">
                                    <div class="form-group col-lg-6">
                                        <label>Select Screenshot Day*</label>
                                        <select style="width:100%;" ngModel="{{ screenumber }}" disabled="{{disableday}}" formControlName="screenshotdayid" name="screenshotdayid" id="screenshotdayid" class="form-control input-lg" (change)="onscreenchange($event.target.value)">
                    <option value="">Select Screenshot Day </option>
                    <option value='1' [selected]="screenumber == '1'">Day 1</option>
                    <option value='2' [selected]="screenumber == '2'">Day 15</option>
                    <option value='3' [selected]="screenumber == '3'">Day 30</option>
                    <option value='4' [selected]="screenumber == '4'">Day 45</option>
                  </select>
                                        <div *ngIf="submitted && g.screenshotdayid.errors" class="invalid-feedback">
                                            <div *ngIf="g.screenshotdayid.errors.required">Please Select Screenshot Day</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="jobDetail">
                                    <div class="form-group col-lg-12" *ngIf="jobDetail.joburl1 !=''  && jobDetail.joburl1 !=null">
                                        <div class="row">
                                            <div class="form-group col-lg-6">
                                                <input type="text" class="form-control" formControlName="joburl1" ngModel="{{ jobDetail.joburl1 }}" name="joburl1" disabled />
                                            </div>
                                            <div class="form-group col-lg-6">
                                                <div class="custom-file text-truncate" (click)="file1.click()">
                                                    <input type="file" #file1 (change)="selectFile($event,'file1')" />
                                                    <label class="custom-file-label">{{ filename1 }}</label>
                                                </div>
                                                <div class="invalid-feedback" *ngIf="filesizeerror1 == true">File size must be less than 2 MB</div>
                                                <div class="invalid-feedback" *ngIf="error1 == true">Please choose a valid file format (jpg, png, tif, gif, pdf, doc)</div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-lg-12" *ngIf="jobDetail.joburl2 !='' && jobDetail.joburl2 !=null">
                                        <div class="row">
                                            <div class="form-group col-lg-6">
                                                <input type="text" class="form-control" formControlName="joburl2" ngModel="{{ jobDetail.joburl2 }}" name="joburl2" disabled>
                                            </div>
                                            <div class="form-group col-lg-6">
                                                <div class="custom-file text-truncate" (click)="file2.click()">
                                                    <input type="file" #file2 (change)="selectFile($event,'file2')" />
                                                    <label class="custom-file-label">{{ filename2 }}</label>
                                                </div>
                                                <div class="invalid-feedback" *ngIf="filesizeerror2 == true">File size must be less than 2 MB</div>
                                                <div class="invalid-feedback" *ngIf="error2 == true">Please choose a valid file format (jpg, png, tif, gif, pdf, doc)</div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-lg-12" *ngIf="jobDetail.joburl3 !=''  && jobDetail.joburl3 !=null ">
                                        <div class="row">
                                            <div class="form-group col-lg-6">
                                                <input type="text" class="form-control" formControlName="joburl3" ngModel="{{ jobDetail.joburl3 }}" name="joburl3" disabled>
                                            </div>
                                            <div class="form-group col-lg-6">
                                                <div class="custom-file text-truncate" (click)="file3.click()">
                                                    <input type="file" #file3 (change)="selectFile($event,'file3')">
                                                    <label class="custom-file-label">{{ filename3 }}</label>
                                                </div>
                                                <div class="invalid-feedback" *ngIf="filesizeerror3 == true">File size must be less than 2 MB</div>
                                                <div class="invalid-feedback" *ngIf="error3 == true">Please choose a valid file format (jpg, png, tif, gif, pdf, doc)</div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-lg-12" *ngIf="jobDetail.joburl4 !=''   && jobDetail.joburl4 !=null ">
                                        <div class="row">
                                            <div class="form-group col-lg-6">
                                                <input type="text" class="form-control" formControlName="joburl4" ngModel="{{ jobDetail.joburl4 }}" name="joburl4" disabled>
                                            </div>
                                            <div class="form-group col-lg-6">
                                                <div class="custom-file text-truncate" (click)="file4.click()">
                                                    <input type="file" #file4 (change)="selectFile($event,'file4')">
                                                    <label class="custom-file-label">{{ filename4 }}</label>
                                                </div>
                                                <div class="invalid-feedback" *ngIf="filesizeerror4 == true">File size must be less than 2 MB</div>
                                                <div class="invalid-feedback" *ngIf="error4 == true">Please choose a valid file format (jpg, png, tif, gif, pdf, doc)</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="jobDetail">
                                    <div class="form-group col-lg-12">
                                        <!-- <p><i class="fa fa-file-image-o" (click)="openModal5('pdf-image-viewer', doc.documentimageurl)"></i> 
                          <i class="fa fa-trash" (click)="deleteFile(doc.candidatedocumentdetailsid, doc.candidateid)"></i>
                          <a href="{{doc.documentimageurl}}"><i class="fa fa-download" ></i></a></p> -->
                                    </div>
                                </div>
                                <div class="row" *ngIf="!jobDetail">
                                    <div class="form-group col-lg-3">
                                        No Job Screen Found
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <input type="submit" class="btn btn-primary com_btn" name="updateform" value="{{buttoninsert}}" />
                            <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-add-job-screen');" />
                        </div>
                    </div>
                </div>
            </form>
        </jw-modal>

        <jw-modal id="pdf-image-viewer" class="modal" role="dialog">
            <div role="document" class="formgroup">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer');">
            <span aria-hidden="true">&times;</span>
          </button>
                    </div>
                    <div class="modal-body">
                        <div class="container">

                            <div class="row">
                                <div class="col-lg-12">

                                    <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="500" frameborder="0"></iframe>
                                    <img *ngIf="imgsrc == true" [src]="iframesrc" style="width:100%;" />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </jw-modal>