"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var fcasearch_service_1 = require("../../services/fcasearch.service");
var router_1 = require("@angular/router");
var RegisterfcadetailsComponent = /** @class */ (function () {
    function RegisterfcadetailsComponent(formBuilder, fcaService, activatedRoute) {
        this.formBuilder = formBuilder;
        this.fcaService = fcaService;
        this.activatedRoute = activatedRoute;
        this.submitted = false;
    }
    RegisterfcadetailsComponent.prototype.ngOnInit = function () {
        this.registerForm = this.formBuilder.group({
            companyname: ['', forms_1.Validators.required],
            postcode: [''],
            searchtype: ['', forms_1.Validators.required]
        });
        this.onSubmit();
    };
    Object.defineProperty(RegisterfcadetailsComponent.prototype, "f", {
        get: function () { return this.registerForm.controls; },
        enumerable: true,
        configurable: true
    });
    RegisterfcadetailsComponent.prototype.onSubmit = function () {
        this.search = this.activatedRoute.snapshot.paramMap.get("id");
        var data = { companyname: this.search };
        // this.fcaService.getFCAFirmcommonSearch(this.search).subscribe((data2: any) => {
        //   this.fcaService.getFCAFirmDetails(data).subscribe((data2: any) => {
        //     this.companydetails =data2;
        //   console.log('hum1',data2);
        //   this.fcaService.getFCAFirmName(data).subscribe((data2: any) => {
        //     console.log('Firm Name',data2);
        //   });
        //   this.fcaService.getFCAFirmIndividuals(data).subscribe((data2: any) => {
        //     this.Induviduals = data2;
        //     console.log('Firm Induviduals',data2);
        //   });
        //   this.fcaService.getFCAFirmRequirements(data).subscribe((data2: any) => {
        //     console.log('Firm Requirements',data2);
        //   });
        //   this.fcaService.getFCAFirmPermission(data).subscribe((data2: any) => {
        //     console.log('Firm Permission',data2);
        //   });
        //   this.fcaService.getFCAFirmPassport(data).subscribe((data2: any) => {
        //     console.log('Firm Passport',data2);
        //   });
        //   this.fcaService.getFCAFirmRegulators(data).subscribe((data2: any) => {
        //     this.regulator = data2;
        //     console.log('Firm Regulators',data2);
        //   });
        //   this.fcaService.getFCAFirmAddress(data).subscribe((data2: any) => {
        //     this.filmaddress = data2;
        //     console.log('Firm Address Master', data2);
        //   });
        //   this.fcaService.getFCAFirmAppointedRepresentative(data).subscribe((data2: any) => {
        //     this.Appointed = data2;
        //     console.log('Firm Appointed Representative',data2);
        //   });
        //   this.fcaService.getFCAFirmWaivers(data).subscribe((data2: any) => {
        //     console.log('Firm Waivers',data2);
        //   });
        //   this.fcaService.getFCAFirmExclusions(data).subscribe((data2: any) => {
        //     console.log('Firm Exclusions',data2);
        //   });
        //   this.fcaService.getFCAFirmDisciplinaryHistory(data).subscribe((data2: any) => {
        //     console.log(data2);
        //   });
        // });
        // });
        this.fcaService.IndividualControlledFunctions(data).subscribe(function (data2) {
            console.log('Individual Controlled Functions', data2);
        });
        this.fcaService.IndividualDetailsFunctions(data).subscribe(function (data2) {
            console.log('Individual Details', data2);
        });
    };
    return RegisterfcadetailsComponent;
}());
exports.RegisterfcadetailsComponent = RegisterfcadetailsComponent;
