<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__content">
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="space_em row">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Employee List
                                        <a class="mainheading_btn " *ngIf="empperm > 1"
                                            (click)="openModal2('custom-employee')"> + Add New </a>
                                    </h5>
                                </div> 
                                <div class="col-lg-4">
                                    <div class="input-group">
                                        <input [(ngModel)]="searchText" placeholder="Search.." class="form-control">
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="( datalist | filter: {fname: searchText,mname: searchText,lname: searchText,emailid: searchText,countryname: searchText }) as pageOfItems"> 
                                <div class="job_list_section employee_list_section" *ngFor="let employee of pageOfItems; let i=index">
                                    <div class="job_detils_date">
                                        <div class="visa_user_pic">
                                            <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                            <img *ngIf="employee.imagename" src="{{employee.imagename}}"
                                                class="img width100"
                                                alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                            <img *ngIf="employee.gender == true && (employee.imagename == null || employee.imagename =='') "
                                                src="../../../../../assets/img/user_pic_emp.png" class="img width100"
                                                alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                            <img *ngIf="employee.gender == false && (employee.imagename == null || employee.imagename =='')"
                                                src="../../../../../assets/img/fe.png" class="img width100"
                                                alt="{{ employee.fname }} {{ employee.mname }} {{ employee.lname}}">
                                        </div>
    
                                    </div>
                                    <div class="job_detils_date width22">
                                        <h5 class="job_profile font_17">{{employee.fname}} {{employee.mname}}
                                            {{employee.lname}}</h5>
                                        <p class="email"><i aria-hidden="true" class="fa fa-envelope-o"></i>
                                            {{employee.emailid}}</p>
                                        <p class="phone"><i aria-hidden="true" class="fa fa-phone"></i>
                                            {{employee.phonenumber}}</p>
                                    </div>
                                    <div class="job_detils_date width15">
                                        <p class="job_address">NI Number</p>
                                        <p class="job_profile text-primary">{{employee.ninumber}}</p>
                                    </div>
                                    <div class="job_detils_date width15">
                                        <p class="job_address">Employee ID</p>
                                        <p class="job_profile"> {{employee.employeeid}}</p>
                                    </div>
                                    <div class="job_detils_date width15">
                                        <p class="job_address">Nationality</p>
                                        <p class="badge badge-danger" *ngIf="employee.nationality">
                                            {{employee.nationality.countryname}} </p>
                                    </div>
                                    <div class="job_detils_date  width20 d-flex aligncenter">
                                        <a type="submit" class="btn btn-primary com_btn" *ngIf="empperm > 2"
                                            [routerLink]="'/employeedetails/'+ employee.employeemasterid">View Details</a>

                                            <a type="submit" class="btn btn-primary com_btn text-white" *ngIf="empperm > 2" (click)="resendwelcomemail(employee.employeemasterid)"
                                                >Resend Welcome Mail</a>
                                        <!-- <div class="dropdown">
                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Action <i class="fa fa-angle-down" aria-hidden="true"></i>
                                                </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" *ngIf="empperm > 2" [routerLink]="'/employeedetails/'+ employee.employeemasterid">View Details</a>
                                                <a class="dropdown-item" *ngIf="(empperm > 2) && (employee.isadmin ==false) && (employee.terminiationid == null || employee.terminiationid == '') " (click)="openModelTerminate(employee.employeemasterid, 'custom-terminate-1')">Terminate</a>
                                            </div>
                                        </div> -->
                                        <!-- <div class="onoffswitch" *ngIf="(employee.isadmin == false) && (empperm > 2)">
                                            <label class="onoffswitch-label" for="skill{{i+1}}" class="switch">
                                                <input type="checkbox"  name="{{ employee.estatus }}" [checked]="employee.estatus" id="skill{{i+1}}" (change)="onChangeStatus($event, employee)">
                                                <span class="slider round"></span>
                                              </label>
                                        </div> -->
                                        <!-- <input type="checkbox" name="{{ employee.estatus }}" [checked]="employee.estatus" id="skill{{i+1}}" (change)="onChangeStatus($event, employee)" class="onoffswitch-checkbox">
                                            <label class="onoffswitch-label" for="skill{{i+1}}">
                                                    <span class="onoffswitch-inner"></span>
                                                    <span class="onoffswitch-switch"></span>
                                                </label> -->
                                    </div>
                                </div>
                                <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                    <div class="job_detils_date">
                                        <p class="job_profile">No Record Found</p>
                                    </div>
                                </div>
                            </ng-container>
                           
                            <div class="job_list_section visa_request_section" *ngIf="record == 0">
                                <div class="job_detils_date">
                                    <div class="billed-to">No Record Found </div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10"
                                        (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<jw-modal id="custom-employee" class="modal" role="dialog">
    <form [formGroup]="employeeForm" #overviewForm="ngForm" class="s12 white"
        (ngSubmit)="onSubmit(overviewForm.value,'custom-employee')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="popupLabel">Employee Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                        (click)="closeModal('custom-employee');">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>Select Job </label>
                                <select style="width:100%;" name="jobid" formControlName="jobid"
                                    class="form-control input-lg" (change)="onSelectjob($event.target.value)" id="jobid"
                                    [ngClass]="{ 'is-invalid': submitted && f.jobid.errors }"
                                    [(ngModel)]="overviewData.jobid">
                                    <option [value]="defaultVal">Select Job</option>
                                    <option *ngFor="let ad3 of joblist" [value]="ad3.jobmasterid">{{ ad3.jobtittle }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f.jobid.errors" class="invalid-feedback">
                                    <div *ngIf="f.jobid.errors.required">Please select job</div>
                                </div>
                            </div>

                            <div class="form-group col-lg-4">
                                <label>Employee ID*</label>
                                <input type="text" formControlName="empid" name="empid" id="empid" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.empid.errors }"
                                    [(ngModel)]="overviewData.employeeid">
                                <div *ngIf="submitted && f.empid.errors" class="invalid-feedback">
                                    <div *ngIf="f.empid.errors.required">Please enter employee id</div>
                                </div>
                                <div *ngIf="empidexist" class="invalid-feedback">
                                    <div>This employee id already exists</div>
                                </div>
                            </div>

                            <div class="form-group col-lg-4">
                                <label>Select Candidate</label>
                                <select style="width:100%;" name="candidateid" formControlName="candidateid"
                                    class="form-control input-lg" id="candidateid"
                                    [ngClass]="{ 'is-invalid': submitted && f.candidateid.errors }"
                                    (change)="onSelectCandidate($event.target.value)"
                                    [(ngModel)]="overviewData.candidateid">
                                    <option [value]="defaultVal">Select Candidate</option>
                                    <option *ngFor="let ad3 of candidatelist" [value]="ad3.candidatemasterid">
                                        {{ ad3.fname }} {{ ad3.mname }} {{ ad3.lname }}</option>
                                </select>
                                <div *ngIf="submitted && f.candidateid.errors" class="invalid-feedback">
                                    <div *ngIf="f.candidateid.errors.required">Please select candidate</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>First Name*</label>
                                <input type="fname" formControlName="fname" name="fname" id="fname" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.fname.errors }"
                                    [(ngModel)]="overviewData.fname" maxlength="50">
                                <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                                    <div *ngIf="f.fname.errors.required">Please enter first name</div>
                                </div>
                            </div>

                            <div class="form-group col-lg-4">
                                <label>Middle Name</label>
                                <input type="mname" formControlName="mname" name="mname" id="mname" class="form-control"
                                    [(ngModel)]="overviewData.mname" maxlength="50">
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Last Name*</label>
                                <input type="lname" formControlName="lname" name="lname" id="lname"
                                    class="form-control input-lg"
                                    [ngClass]="{ 'is-invalid': submitted && f.lname.errors }"
                                    [(ngModel)]="overviewData.lname" maxlength="50">
                                <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                                    <div *ngIf="f.lname.errors.required">Please enter last name</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>Email*</label>
                                <input type="emailid"  (focusout)="onFocusOutEvent($event)"  (focus)="focusFunction()" maxlength="100"
                                    formControlName="emailid" name="emailid" id="emailid" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }"
                                    [(ngModel)]="overviewData.emailid">
                                <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                                    <div *ngIf="f.emailid.errors.required">Please enter email </div>
                                </div>
                                <div class="invalid-feedback">
                                    <div class="error" *ngIf="emailexist">This email is already registered</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Username*</label>
                                <input type="text" (focusout)="onUserNameOutEvent($event)" maxlength="100"
                                    formControlName="username" name="username" id="username" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                    [(ngModel)]="overviewData.username">
                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required">Please enter username </div>
                                </div>
                                <div class="invalid-feedback">
                                    <div class="error" *ngIf="usernameexist">This username is already registered</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Mobile Number*</label>
                                <international-phone-number formControlName="phonenumber"
                                    class="form-control phonecontrol"
                                    [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }"
                                    [(ngModel)]="overviewData.phonenumber" placeholder="Enter mobile number"
                                    (input)="validatenumber($event.target.value,'phone')" [maxlength]="20"
                                    [required]="true" #mobilenumber name="phonenumber"></international-phone-number>
                                <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.phonenumber.errors.required">Please enter mobile number</div>
                                </div>
                                <span *ngIf="phonecheck===false" style="color:red">Invalid format</span>
                                <span *ngIf="phonecheck===true" style="color:green">Valid format</span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>Date of Birth*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [min]="mindob" [max]="maxdob" [matDatepicker]="picker2"
                                        id="dateofbirth" [ngClass]="{ 'is-invalid': submitted && f.dateofbirth.errors }"
                                        name="dateofbirth" formControlName="dateofbirth"
                                        class="form-control noborder-invalid" [(ngModel)]="overviewData.dateofbirth">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.dateofbirth.errors" class="invalid-feedback">
                                    <div *ngIf="f.dateofbirth.errors.required">Please choose date of birth</div>
                                </div>
                            </div>

                            <div class="form-group col-lg-4">
                                <label>Hire Date*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker3" id="hiredate"
                                        [ngClass]="{ 'is-invalid': submitted && f.hiredate.errors }" name="hiredate"
                                        formControlName="hiredate" class="form-control noborder-invalid"
                                        [(ngModel)]="overviewData.hiredate">
                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.hiredate.errors" class="invalid-feedback">
                                    <div *ngIf="f.hiredate.errors.required">Please choose hire date</div>
                                </div>
                            </div>

                            <div class="form-group col-lg-4">
                                <label>NI Number*</label>
                                <input type="ninumber" formControlName="ninumber" name="ninumber" id="ninumber"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.ninumber.errors }"
                                    [(ngModel)]="overviewData.ninumber" maxlength="30">
                                <div *ngIf="submitted && f.ninumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.ninumber.errors.required">Please enter NI number</div>
                                </div>
                            </div>

                        </div>

                       
                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>Gender </label>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-lg-6 col-6">
                                            <input type="radio" name="gender" formControlName="gender"
                                                [(ngModel)]='overviewData.gender' value="1"
                                                [checked]="overviewData.gender === true" /> Male
                                        </div>
                                        <div class="col-lg-6 col-6">
                                            <input type="radio" name="gender" formControlName="gender"
                                                [(ngModel)]='overviewData.gender' value="0"
                                                [checked]="overviewData.gender === false" /> Female
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="form-group col-lg-4">
                                <label>Marital Status</label>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-lg-6 col-6">
                                            <input type="radio" name="maritalstatus" formControlName="maritalstatus"
                                                [(ngModel)]='overviewData.maritalstatus' value="1"
                                                [checked]="overviewData.maritalstatus === true"> Married
                                        </div>
                                        <div class="col-lg-6 col-6">
                                            <input type="radio" name="maritalstatus" formControlName="maritalstatus"
                                                [(ngModel)]='overviewData.maritalstatus' value="0"
                                                [checked]="overviewData.maritalstatus === false"> Single
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Job Role*</label>
                                <input type="text" formControlName="emprole" name="emprole" id="emprole"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emprole.errors }"
                                    [(ngModel)]="overviewData.emprole" maxlength="70">
                                <div *ngIf="submitted && f.emprole.errors" class="invalid-feedback">
                                    <div *ngIf="f.emprole.errors.required">Please enter job role</div>
                                </div>
                            </div>
                        </div>
                       
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Nationality* </label>
                                <select style="width:100%;" name="nationalityid" formControlName="nationalityid"
                                    class="form-control input-lg" id="nationalityid"
                                    [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }"
                                    [(ngModel)]="overviewData.nationalityid">
                                    <option [value]="defaultVal">Select Nationality</option>
                                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">
                                        {{ ad3.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                    <div *ngIf="f.nationalityid.errors.required">Please select nationality</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Country of Residence*</label>
                                <select style="width:100%;" name="residenceid" formControlName="residenceid"
                                    class="form-control input-lg" id="residenceid"
                                    [ngClass]="{ 'is-invalid': submitted && f.residenceid.errors }"
                                    [(ngModel)]="overviewData.residenceid">
                                    <option [value]="defaultVal">Select Country of Residence</option>
                                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">
                                        {{ ad3.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && f.residenceid.errors" class="invalid-feedback">
                                    <div *ngIf="f.residenceid.errors.required">Please select country of residence</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Country of Birth*</label>
                                <select style="width:100%;" name="countryofbirthid" formControlName="countryofbirthid"
                                    id="countryofbirthid"
                                    [ngClass]="{ 'is-invalid': submitted && f.countryofbirthid.errors }"
                                    class="form-control input-lg" [(ngModel)]="overviewData.countryofbirthid">
                                    <option [value]="defaultVal">Select Country of Birth</option>
                                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">
                                        {{ ad3.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && f.countryofbirthid.errors" class="invalid-feedback">
                                    <div *ngIf="f.countryofbirthid.errors.required">Please select country of birth</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Place of Birth*</label>
                                <input type="text" formControlName="placeofbirth" id="placeofbirth" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.placeofbirth.errors }" name="placeofbirth"
                                    [(ngModel)]="overviewData.placeofbirth">
                                <div *ngIf="submitted && f.placeofbirth.errors" class="invalid-feedback">
                                    <div *ngIf="f.placeofbirth.errors.required">Please enter place of birth</div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Country of Birth*</label>
                                <select style="width:100%;" name="countryofbirthid" formControlName="countryofbirthid"
                                    id="countryofbirthid"
                                    [ngClass]="{ 'is-invalid': submitted && f.countryofbirthid.errors }"
                                    class="form-control input-lg" [(ngModel)]="overviewData.countryofbirthid">
                                    <option [value]="defaultVal">Select Country of Birth</option>
                                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">
                                        {{ ad3.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && f.countryofbirthid.errors" class="invalid-feedback">
                                    <div *ngIf="f.countryofbirthid.errors.required">Please select country of birth</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Place of Birth*</label>
                                <input type="text" formControlName="placeofbirth" id="placeofbirth" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.placeofbirth.errors }" name="placeofbirth"
                                    [(ngModel)]="overviewData.placeofbirth">
                                <div *ngIf="submitted && f.placeofbirth.errors" class="invalid-feedback">
                                    <div *ngIf="f.placeofbirth.errors.required">Please enter place of birth</div>
                                </div>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Total Leaves({{currentyear}})*</label>
                                <input type="text" formControlName="currentyearleave" id="currentyearleave" class="form-control"
                                     name="currentyearleave" maxlength="3" [ngClass]="{ 'is-invalid': submitted && f.currentyearleave.errors }">
                                <div *ngIf="submitted && f.currentyearleave.errors" class="invalid-feedback">
                                    <div *ngIf="f.currentyearleave.errors.required">Please enter the total leaves for {{currentyear}}.</div>
                                    <div *ngIf="f.currentyearleave.errors.pattern">Total leaves should be a number.</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Total Leaves({{nextyear}})*</label>
                                <input type="text" formControlName="nextyearleave" id="nextyearleave" class="form-control"
                                     name="nextyearleave" maxlength="3" [ngClass]="{ 'is-invalid': submitted && f.nextyearleave.errors }">
                                <div *ngIf="submitted && f.nextyearleave.errors" class="invalid-feedback">
                                    <div *ngIf="f.nextyearleave.errors.required">Please enter the total leaves for {{nextyear}}.</div>
                                    <div *ngIf="f.nextyearleave.errors.pattern">Total leaves should be a number.</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label>Send welcome email </label>
                                <div class="active-inactive employee_list_section">
                                    <div class="onoffswitch">
                                      <label for="ismail" class="switch ml-0">
                                        <input type="checkbox" formControlName="ismail" name="ismail"
                                        class="onoffswitch-checkbox" id="ismail" [checked]="ismail"
                                        [(ngModel)]='overviewData.ismail'>
                                          <span class="slider round"></span>
                                        </label> 
                                    </div>
                                  
                                  </div>

                                <!-- <div class="onoffswitch">
                                    <input type="checkbox" formControlName="ismail" name="ismail"
                                        class="onoffswitch-checkbox" id="ismail" [checked]="ismail"
                                        [(ngModel)]='overviewData.ismail'>
                                    <label class="onoffswitch-label" for="ismail">
                                        <span class="onoffswitch-inner"></span>
                                        <span class="onoffswitch-switch"></span>
                                    </label>
                                </div> -->
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn"
                        value="Add New Employee" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel"
                        (click)="closeModal('custom-employee');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>

<jw-modal id="custom-terminate-1" role="dialog" class="modal">
    <form [formGroup]="terminationForm" #terminationform="ngForm" class="s12 white"
        (ngSubmit)="onSubmitTermination(terminationform.value,'custom-terminate-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid"
                        id="employeeid" [(ngModel)]="terminationDetail.employeeid">
                    <h5 class="modal-title" id="popupLabel">Termination Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                        (click)="closeModal('custom-terminate-1');">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Termination Date </label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker4" id="terminationdate"
                                        [ngClass]="{ 'is-invalid': submitted && g.terminationdate.errors }"
                                        name="terminationdate" formControlName="terminationdate"
                                        class="form-control noborder-invalid"
                                        [(ngModel)]="terminationDetail.terminationdate">
                                    <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                    <mat-datepicker #picker4></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && g.terminationdate.errors" class="invalid-feedback">
                                    <div *ngIf="g.terminationdate.errors.required">Please choose termination date</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Termination Reason*</label>
                                <input type="text" formControlName="terminationreason" name="terminationreason"
                                    id="terminationreason" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && g.terminationreason.errors }"
                                    [(ngModel)]="terminationDetail.terminationreason">
                                <div *ngIf="submitted && g.terminationreason.errors" class="invalid-feedback">
                                    <div *ngIf="g.terminationreason.errors.required">Please enter termination reason
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Eligible For Rehire*</label>
                                <select style="width:100%;" formControlName="oktorehire" name="oktorehire"
                                    id="oktorehire" class="form-control input-lg"
                                    [(ngModel)]="terminationDetail.oktorehire"
                                    [ngClass]="{ 'is-invalid': submitted && g.oktorehire.errors }">
                                    <option [value]="defaultVal">Select eligible for rehire</option>
                                    <option [value]="1">Yes </option>
                                    <option [value]="0">No</option>
                                </select>
                                <div *ngIf="submitted && g.oktorehire.errors" class="invalid-feedback">
                                    <div *ngIf="g.oktorehire.errors.required">Please select employement status</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Regret Termination*</label>
                                <select style="width:100%;" formControlName="regrettermination" name="regrettermination"
                                    id="regrettermination" class="form-control input-lg"
                                    [ngClass]="{ 'is-invalid': submitted && g.regrettermination.errors }"
                                    [(ngModel)]="terminationDetail.regrettermination">
                                    <option [value]="defaultVal">Select regret termination</option>
                                    <option [value]="1">Yes </option>
                                    <option [value]="0">No</option>
                                </select>
                                <div *ngIf="submitted && g.regrettermination.errors" class="invalid-feedback">
                                    <div *ngIf="g.regrettermination.errors.required">Please select regret termination
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Terminate" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel"
                        (click)="closeModal('custom-terminate-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>

<jw-modal id="upgrade-plan" role="dialog" class="modal">
    <div role="document" class="formgroup">
        <div class="modal-content"> 
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                    (click)="closeModal('upgrade-plan');">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">You have exceeded the limit to add new employee. Please upgrade your plan.</div>
                </div>
            </div>
            <div class="modal-footer">
                <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" />
            </div>
        </div>
    </div>
</jw-modal>