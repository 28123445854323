"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Working = /** @class */ (function () {
    function Working() {
        this.startdate = new Date();
        this.contracttypeid = "";
    }
    return Working;
}());
exports.Working = Working;
var Flexi = /** @class */ (function () {
    function Flexi() {
        this.startdate = "";
        this.enddate = "";
    }
    return Flexi;
}());
exports.Flexi = Flexi;
