import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private commonService: CommonService) { }

  getjobList(): Observable<any> {
    return this.commonService.formpostOnlyid('/joblist');
  }

  getjobById(value: any): Observable<any> {
    return this.commonService.formpost('/jobbyid', value);
  }
  checkscreensort(value: any): Observable<any> {
    return this.commonService.formpost('/checkscreensortid', value);
  }

  getjobskillList(value: any): Observable<any> {
    return this.commonService.formpost('/jobskills', value);
  }

  deleteskill(value: any): Observable<any> {
    return this.commonService.formpost('/deleteskill', value);
  }

  addnewskill(value: any): Observable<any> {
    return this.commonService.formpost('/addnewskill', value);
  }

  jobdetailsUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/updatejobdetails', value);
  }

  jobscreenshotdetailsUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/updatejobscreendetails', value);
  }

  getjoblist(): Observable<any> {
    return this.commonService.formget('/company/joblist');
  }

  deleteFile(value: any): Observable<any> {
    return this.commonService.formpost('/deletedocumentfile', value);
  }

}
