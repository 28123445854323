import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  [x: string]: any;
  private modals: any[] = [];
  constructor(
    private commonService: CommonService
  ) {}

  getPaddress(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/employeehomeaddress');    
  }

  getPaddressbyadmin(value:any): Observable<any> {
    return this.commonService.formpost('/employee/employeehomeaddressbyadmin',value);    
  }
  getpassportListnew(value:any): Observable<any> {
    return this.commonService.formpost('/employee/employeepassportlistnew',value);      
  }

  getOtherInfobyadmin(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/getemployeeotherinfobyadmin',data);   
  }
  


  getloginbytokencheck(): Observable<any> {
    return this.commonService.formpostOnlyid('/login-check-system');    
  }


  getOverview(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/employeedetails');    
  }
  getOverviewbyadmin(value:any): Observable<any> {
    return this.commonService.formpost('/employee/employeedetailsbyadmin',value);    
  }

  getOverviewRighttoworkbyadmin(value:any): Observable<any> {
    return this.commonService.formpost('/employee/righttoworkemployeedetailsbyadmin',value);    
  }

  


  employeedetailsrotasystem(value:any): Observable<any> {
    return this.commonService.formpost('/employee/employeedetailsrotasystem',value);    
  }



  getLineEmployeeDetails(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/employeelinemanager');    
  }

  getchildemployee(data: any): Observable<any> {
    return this.commonService.formpost('/employee/getchildemployee', data);    
  }

  getLineEmployeeDetailsbyadmin(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/employeelinemanagerbyadmin');    
  }

  getEmployeeDetailsbyadmin(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/listemployee');    
  }
  getOtherInfo(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/getemployeeotherinfo',data);   
  }


  updatepassword(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/update-password');    
  }
  getEmployment(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/currentworkingdetails');    
  }

  getEmploymentbyadmin(value:any): Observable<any> {
    return this.commonService.formpost('/employee/currentworkingdetailsbyadmin',value);    
  }

  getAddress(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/currentaddressdetails');    
  }

  getAddressbyadmin(value:any): Observable<any> {
    return this.commonService.formpost('/employee/currentaddressdetailsbyadmin',value);    
  }

  getVisa(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/employeevisadetails');
  }

  getVisabyadmin(value:any): Observable<any> {
    return this.commonService.formpost('/employee/employeevisadetailsbyadmin',value);
  }

  updateemployeedetals(value:any): Observable<any> {
    return this.commonService.formpost('/employee/employeepersonaldetailsupdate',value);    
  }

  updateemployeedetalsbyadmin(value:any): Observable<any> {
    return this.commonService.formpost('/employee/employeepersonaldetailsupdatebyadmin',value);    
  }
  
  permanentadddetals(value:any): Observable<any> {
    return this.commonService.formpost('/employee/updateeeraddress',value);    
  }

  permanentadddetalsbyadmin(value:any): Observable<any> {
    return this.commonService.formpost('/employee/updateeeraddressbyadmin',value);    
  }
  
  updateemployeeimage(value:any): Observable<any> {
    return this.commonService.formpost('/employee/employeeimageupdate',value);    
  }
  getPassport(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/employeepassportdetails');    
  }
  getPassportbyadmin(value:any): Observable<any> {
    return this.commonService.formpost('/employee/employeepassportdetailsbyadmin',value);    
  }
}
