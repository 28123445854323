import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MyidproofService } from '../../../services/myidproof.service';
import { CountryService } from '../../../services/country.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadFileService } from './../../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Idproof } from '../../../models/idproof/idproof.model';
import { ActivatedRoute } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { MyvisaService } from '../../../services/myvisa.service';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-checkaidocument',
  templateUrl: './checkaidocument.component.html',
  styleUrls: ['./checkaidocument.component.css']
})

export class CheckaidocumentComponent implements OnInit 
{

  logo: any;
  wizard:any = 0;

  @Input() items: Array<any>;
  searchText:any;
  pageOfItems: Array<any>;
  record: any;
  visalist: any[];
  idproofDetail: any;
  countrylist: any;
  Userid: any;
  empid: any;
  listcheck: any;
  selectedFiles: FileList;
  iframesrc: any;
  filevisareuirederror:any;
  filebackvisareuirederror:any;
  documenttype: any;
  imgsrc: any;
  idproofpopForm: FormGroup;
  buttoninsert: any;
  filesizeerror: any;
  fileformaterror: any;
  file: any;
  filename: any;
  profilepic: any;
  pro_pic_size_error: any;
  pro_pic_format_error: any;
  profile_pic_url: any;
  ff:any;
  imgbackURL:any;
  public imagePath;
  imgURL: any;
  token_expired: any = false;
  disableBtn: any;  
  aipayment: boolean =false;  
  filebacksizeerror: any;
  filebackformaterror: any;
  selectedbackFiles: FileList;
  selected_profile_image: FileList;
  filebackname: any;
  submitted: any;
  permission: any;
  requestperm: any;
  accesstoken: string;
  defaultVal: any = "";

  filedocumentreuirederror:any;
  filedocumentsizeerror:any;
  filedocumentbackreuirederror:any;
  filedocumentbackbacksizeerror:any;
  

  
  filterItems = [
    {
      name: 'All Visa Details',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Waiting for review',
      id: 1,
      selected: false
    }
  ]
  constructor(
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private datePipe: DatePipe,
    private data: MyidproofService, 
    private country: CountryService, private visaService: MyvisaService, 
    private sanitizer:DomSanitizer,
    private alerts: AlertService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.buttoninsert = "Submit";
    this.disableBtn = true;

    this.activatedRoute.params.subscribe(params => {
      this.empid = params['id'];
      
    });
    this.idproofDetail = new Idproof();
    this.getCountries();

    this.idproofpopForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      idnumber: ['', Validators.required],
      idtype: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      docexpiry: ['', Validators.required]
    });

    this.submitted = false;
    this.disableBtn = true;
    this.buttoninsert = "Submit";
    this.idproofDetail = new Idproof();
    this.idproofDetail.nationalityid = "";     
    this.Userid = localStorage.getItem('userid');
    this.idproofDetail.employeeid = this.empid;//this.Userid;
    this.idproofDetail.employeeidproofdeatilid = "0";
    this.idproofDetail.idtype = 17;
    this.filename = "Choose file";
    this.profilepic = "Choose file";    
    this.filebackname = 'Choose file';
    
  }

  getCountries()
  {
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
  }
  
  get f() {
    return this.idproofpopForm.controls;
  }

  toggle(id) {
    if(id ==1){
      this.submitted = true;
      console.log(this.idproofpopForm);
      if (this.idproofpopForm.invalid) {
        return;
      }
    }
    this.wizard = id;
  }

  toggle2(id) {
    // if(id == 1){
    //   this.wizard2 = false;
    //   this.wizard1 = true;
    //   this.wizard3 = false;
    // }
    // if(id == 2){
    //   this.wizard3 = false;
    //   this.wizard1 = false;
    //   this.wizard2 = true;
    // }
  }

  onSubmit(value3: any, id: string, stype: string) {
    
    this.submitted = true;
    this.fileformaterror = false;

    if (this.idproofpopForm.invalid) {
      return;
    }
    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") ) {
        this.fileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.imagename = imagepath;
    }
    else {
      value3.imagename = this.idproofDetail.iddocName;
    }

    if (this.selectedbackFiles) {
      this.file = this.selectedbackFiles;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
        this.fileformaterror = true;
        return;
      }
      var imagepath =  this.uploadService.uploadfile(this.file);
      value3.imagebackname = imagepath;
    }
    else {
      value3.imagebackname = this.idproofDetail.idbackdocName;
    }

    if(value3.imagename ==''){
      this.filevisareuirederror = true;
      return;
    }

    if(value3.imagebackname==''){
      this.filebackvisareuirederror = true;
      return;
    }    

    if (this.selected_profile_image) {
      this.file = this.selected_profile_image;
      var filetype = this.file.type.split('/');
      if (this.file.size > 2000 * 1024) {
        this.filebacksizeerror = true;
        return;
      }
      else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
        this.filebackformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.idprofileimage = imagepath;
    }
    else {
      value3.idprofileimage = this.idproofDetail.documentprofileimage;
    }

    value3.docexpiry = this.datePipe.transform(new Date(value3.docexpiry),"dd MMM yyyy");
    value3.createby = this.Userid;
    value3.updateby = this.Userid;
    


    this.wizard = 6;
    this.data.myidproofdetailsUpdatebyadmin(value3).subscribe((datamain: any) => {     
      var data3 = {"employeeidproofdeatilid": datamain.employeeidproofdeatilid}
      this.data.idproofdetailsbyidproofid(data3).subscribe((data: any) => {      
        var countryiso = data.nationality.isothree;
        var idtype = 'DRIVING_LICENSE';//data.documenttype.documentcode;     
        var visalog = {
          employeeid: this.empid,
          documentid: datamain.employeeidproofdeatilid,
          documenturl: value3.imagename,
          documenttype: 'id'
        };
        this.visaService.visaailogupdate(visalog).subscribe((dbvisaresult: any) => {        
          if(dbvisaresult.success =='1'){
            var data = { 
              merchantIdScanReference:dbvisaresult.logid, 
              country : countryiso, 
              idType :idtype, 
              customerId:  this.empid, 
              is_rtw: 0,
              callbackUrl: AppGlobals.API_ENDPOINT +"/api/aiconfirmation",
              documenturl: value3.imagename,
              documentbackurl: value3.imagebackname, 
              imageurl: value3.idprofileimage 
            };
            this.visaService.visadocumentupdate(data).subscribe((data5: any) => {
              if(data5.success == true){
                this.wizard = 5;
              }else {
                this.wizard = 4;
                this.alerts.error("something went wrong please try again");
              }          
            });
          }else{
            this.wizard = 4; this.alerts.error("something went wrong please try again");
          }
        });
      }); 
      
    
    
    
    });
    

  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  selectFile(event)
  {
    var files = event.target.files;
    this.filename = files[0].name;
    this.selectedFiles = files[0];
    this.file = this.selectedFiles;
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filesizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.fileformaterror = true;    
    }else {
      this.fileformaterror = false;
      this.filesizeerror = false;
    
      var reader = new FileReader();
      this.imagePath = event.target.files;
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.imgURL = reader.result; 
      }
    }
  }

  selectbackFile(event)
  {
    var files = event.target.files;
    this.filebackname = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.filebacksizeerror = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.filebackformaterror = true;
      this.filebacksizeerror = false;    
    }
    else
    {
      this.filebackformaterror = false;
      this.filebacksizeerror = false;   
      this.selectedbackFiles = files[0]; 
      var reader = new FileReader();
      this.imagePath = event.target.files;
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.imgbackURL = reader.result; 
      }    
    }
  }

  selectprofile(event)
  {
    var files = event.target.files;
    this.profilepic = files[0].name;
    this.file = files[0];
    var filetype = this.file.type.split('/');
    if (this.file.size > 2000 * 1024) {
      this.pro_pic_size_error = true;    
    }
    else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") )  {
      this.pro_pic_format_error = true;
      this.pro_pic_size_error = false;    
    }
    else
    {
      this.pro_pic_format_error = false;
      this.pro_pic_size_error = false;   
      this.selected_profile_image = files[0]; 
      var reader = new FileReader();
      this.imagePath = event.target.files;
      reader.readAsDataURL(event.target.files[0]); 
      reader.onload = (_event) => { 
        this.profile_pic_url = reader.result; 
      }    
    }
  }

}
