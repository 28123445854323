import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-directorcompany',
  templateUrl: './directorcompany.component.html',
  styleUrls: ['./directorcompany.component.css']
})
export class DirectorcompanyComponent implements OnInit {

  @Input()
  company: any;

  constructor() { }

  ngOnInit() {
  }

}
