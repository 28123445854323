import { Component, OnInit } from '@angular/core';
import { ExpenseService } from './../../../services/expense.service';
import { ModalService } from '../../../_services/modal.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { CustomerService } from '../../../services/customer.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';

@Component({
  selector: 'app-adminexpenserequest',
  templateUrl: './adminexpenserequest.component.html',
  styleUrls: ['./adminexpenserequest.component.css']
})
export class AdminexpenserequestComponent implements OnInit {
  data: any[];
  notedetails:any;
  Userid: any;
  month: any;
  year: any;
  isSelected = false;
  yearmonth: any;
  classactive: string;
  selectedAll: any;
  employeeid: any;
  imgsrc: any;
  iframesrc: any;
  employeelist: any;
  record: any;
  hstatus: any;
  // passformGroup: FormGroup;
  yearList: any;
  
  items: Array<any>;
  
  pageOfItems: Array<any>;
  form: FormGroup;
  monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];
  permission: any;
  requestperm: any;

  orders = [
    { id: 100, name: 'order 1' },
    { id: 200, name: 'order 2' },
    { id: 300, name: 'order 3' },
    { id: 400, name: 'order 4' }
  ];

  constructor(
    private sanitizer: DomSanitizer, 
    private dataset: ExpenseService, 
    private formBuilder: FormBuilder, 
    private modalService: ModalService, 
    private router: Router,
    private customer: CustomerService
  ) {


    const controls = this.orders.map(c => new FormControl(false));
    controls[0].setValue(true); // Set the first checkbox to true (checked)

    this.form = this.formBuilder.group({
      orders: new FormArray(controls)
    });
  }

  ngOnInit() { this.yearList = AppGlobals.YEAR_VALUES;
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;
    // this.passformGroup = this.formBuilder.group({
    //   employeeexpenseapplyid: [''],

    // });
    this.hstatus = '';
    this.Userid = localStorage.getItem('userid');


    this.month = (new Date()).getMonth() + 1;
    this.year = (new Date()).getFullYear();

    this.yearmonth = this.monthNames[this.month - 1] + ' ' + (new Date()).getFullYear();

    this.classactive = ((new Date()).getMonth() + 1).toString();


    this.customer.getEmployeeDetailsbyadmin().subscribe((data: any) => {

      this.employeelist = data;
     
    });

    this.bindalldata();
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  openModalnote (data:any,id:any){
    this.notedetails = data;
    this.modalService.open(id);
  }
  onChange(newValue) {
    this.employeeid = newValue;
    this.bindalldata();
  }
  onChangestatus(newValue) {
    this.hstatus = newValue;
    this.bindalldata();
  }
  selectAll() {
    for (var i = 0; i < this.data.length; i++) {
      this.data[i].selected = this.selectedAll;
    }
  }
  checkIfAllSelected() {
    this.selectedAll = this.data.every(function (item: any) {
      return item.selected == true;
    })
  }
  submit() {

  }
  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModal3(id: string, data: any) {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  bindalldata() {
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'month': this.month, 'year': this.year, 'employeeid': this.employeeid, 'hstatus': this.hstatus };
    this.dataset.getemployeeexpensereportrequestbyadmin(data).subscribe((data: any) => {

      this.record = data.length;
      this.data = data;
      this.record = this.data.length;

      if(this.record < 30){
        this.pageOfItems = this.data;
      }
      else{
           this.items =this.data;
      }
    });
  }

  getDataByMonth(mon) {
    this.classactive = mon;
    this.month = mon;
    this.yearmonth = this.monthNames[mon - 1] + ' ' + (new Date()).getFullYear();

    this.bindalldata();
  }

  onyearSubmit(year) {
    this.year = year;
    this.yearmonth = this.monthNames[this.month - 1] + ' ' + this.year;

    this.bindalldata();
    // this.modalService.close(id);
  }

  yearpop(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }


  onStatus(id: number, status: number) {


    const deleteData = { 'id': id, 'hstatus': status, 'userid': this.Userid, 'statusdate': new Date() };
    var msg = '';
    if (status == 2) {
      msg = 'Are you sure you want to Approve?';

    } else {
      msg = 'Are you sure you want to Reject?';
    }

    if (confirm(msg)) {
      this.dataset.statusexpense(deleteData).subscribe((data: any) => {
        this.bindalldata();
      });
    }

  }

  getDataByYear(yea) {
    this.yearmonth = this.monthNames[this.month - 1] + ' ' + yea;

    this.bindalldata();
  }

}


