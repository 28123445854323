import { HeaderComponent } from './../header/header.component';
import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { Passport } from '../../models/passport/passport.model';
import { ModalService } from '../../_services/modal.service';
import { CountryService } from '../../services/country.service';
import { UploadFileService } from './../../services/upload-file.service';
import { MypassportService } from '../../services/mypassport.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { UploadFileService } from '../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from '../../services/alert.service';

// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';

@Component({
  selector: 'app-passport',
  templateUrl: './passport.component.html',
  styleUrls: ['./passport.component.css']
})

export class PassportComponent implements OnInit {
  passport = new Passport;
  countrylist: any;
  file: any;
  passportDetail: any;
  ReForm: FormGroup;
  submitted: boolean;
  selectedFiles: FileList;
  filesizeerror: any;
  fileformaterror: any;
  iframesrc: any;
  imgsrc: any;
  Userid: any;
  listcheck: any;
  myfile: any;
  filename: any;
  defaultVal: any = "";
  // :any;
  // selectedFiles: FileLifilest;

  constructor(
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private uploadService: UploadFileService,
    private data: CustomerService,
    private country: CountryService,
    private modalService: ModalService,
    private data2: MypassportService,
    private alerts: AlertService
  ) { }

  ngOnInit() {
    this.ReForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      passportnumber: ['', Validators.required],
      placeofissue: ['', Validators.required],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      passportissue: ['', Validators.required],
      passportexpiry: ['', Validators.required],
    });
    this.filename = 'Choose file';
    this.data.getPassport().subscribe((data: any) => {
      this.passport = data;
    });

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.passportDetail = new Passport();
    this.passportDetail.status = 0;
  }

  get f() { return this.ReForm.controls; }

  onSubmit(value3: any, id: string) {
    this.submitted = true;
    if (this.ReForm.invalid) {
      return;
    }

    if (this.selectedFiles) {
      this.file = this.selectedFiles;
      var filetype = this.file.type.split('/');
      var typeoffile = filetype[filetype.length - 1];
      if (this.file.size > 2000 * 1024) {
        this.filesizeerror = true;
        return;
      }
      else if ((typeoffile != "jpeg") && (typeoffile != "jpg") && (typeoffile != "png") && (typeoffile != "gif") && (typeoffile != "tif") && (typeoffile != "pdf") && (typeoffile != "doc") && (typeoffile != "docx")) {
        this.fileformaterror = true;
        return;
      }
      var imagepath = this.uploadService.uploadfile(this.file);
      value3.imagename = imagepath;
    }
    else {
      value3.imagename = this.passportDetail.imagename;
    }
    this.data2.mypassportdetailsUpdate(value3).subscribe((data: any) => {
      var userid = localStorage.getItem('userid');
      this.data.getPassport().subscribe((data: any) => {
        this.passport = data;



      });
    });
    this.alerts.success('Passport details updated successfully', true);
    this.modalService.close(id);
  }
  selectFile(event) {
    var files = event.target.files;
    this.filename = files[0].name;
    //var selectedFiles = files[0];
    //this.selectedFiles = selectedFiles;
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModal3(id: string, data: any) {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    //  alert(typeoffile+'asdf '+data);

    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.passportDetail = new Passport();
    this.passportDetail = data;
    this.modalService.open(id);

  }

  closeModal(id: string) {
    this.data.getPassport().subscribe((data: any) => {
      this.passport = data;
    });
    this.modalService.close(id);
  }

  modalOpen(data: any) {
    this.passportDetail = data;
  }
}