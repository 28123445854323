"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// import { HIGH_CONTRAST_MODE_ACTIVE_CSS_CLASS } from '@angular/cdk/a11y/high-contrast-mode/high-contrast-mode-detector';
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var company_service_1 = require("../../services/company.service");
var reference_service_1 = require("../../services/reference.service");
var helper_service_1 = require("../../services/helper.service");
var checksystem_service_1 = require("../../services/checksystem.service");
var ReferenceresponseComponent = /** @class */ (function () {
    // blob:BlobPart;
    function ReferenceresponseComponent(formBuilder, activatedRoute, referencingmoduleService, company, check, helperService) {
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.referencingmoduleService = referencingmoduleService;
        this.company = company;
        this.check = check;
        this.helperService = helperService;
        this.token_expired = false;
        this.submit = false;
        this.btndisable = false;
        this.submit2 = false;
        this.formsubmitted = false;
        this.details = {};
        this.steps = [
            { name: 'Information', icon: 'fa-info-circle', active: true, valid: false, hasError: false },
            { name: 'About You', icon: 'fa-user-o', active: false, valid: false, hasError: false },
            { name: 'About Reference', icon: 'fa-user-o', active: false, valid: false, hasError: false }
        ];
    }
    ReferenceresponseComponent.prototype.ngOnInit = function () {
        this.check.getIPAddress().subscribe(function (data) {
            sessionStorage.setItem('LOCAL_IP', data.ip);
        });
        // this.helperService.determineLocalIp();
        this.token = this.activatedRoute.snapshot.queryParams['token'];
        // this.rd=this.activatedRoute.snapshot.queryParams['rd'];
        this.cd = this.activatedRoute.snapshot.queryParams['cd'];
        // this.ud=this.activatedRoute.snapshot.queryParams['ud'];
        this.referencing = 0;
        this.addconfirmbutton = 'Add';
        // this.refereesFormGroup = this.formBuilder.group({
        //     referencerequestid: '',
        //     token: '',
        //     companyid: '',
        //     userid: '',
        //     rederencerequest: '',
        //     items: this.formBuilder.array([ this.createItem() ])
        //   });
        this.refereesFormGroup = this.formBuilder.group({
            refereedesignationcheck: ['', [forms_1.Validators.required]],
            refereephonecheck: ['', [forms_1.Validators.required]],
            candidatedesignationcheck: ['', [forms_1.Validators.required]],
            modifiedon: new Date(),
            refereemac: localStorage.getItem('addrefereeseq'),
            refereeip: sessionStorage.getItem('LOCAL_IP')
        });
        // console.log(this.refereesFormGroup);
        this.refereesresponseFormGroup = this.formBuilder.group({
            companyid: '',
            companyname: this.rd,
            candidatename: this.rd,
            candidateemail: this.rd,
            refereename: this.rd,
            refereeemail: this.rd,
            referencerequestid: '',
            refereeid: '',
            refereenamecheck: ['', forms_1.Validators.required],
            companynamecheck: ['', forms_1.Validators.required],
            candidatedesignationcheck: ['', forms_1.Validators.required],
            jobstartdatecheck: ['', forms_1.Validators.required],
            jobenddatecheck: [''],
            currentjob: '',
            refereesresponsedetails: this.formBuilder.array([])
        });
        // console.log("first");
        this.loadData();
        this.intrefereefilleddetailcount = 1;
        this.createrefereerequest = [];
        // console.log("second");
    };
    ReferenceresponseComponent.prototype.addrefereesresponsedetailFormGroup = function (questiontemplate) {
        return this.formBuilder.group({
            question: questiontemplate.question,
            questiontype: questiontemplate.questiontype,
            questionoptions: "Select," + questiontemplate.questionoptions,
            required: questiontemplate.required,
            response: [null, questiontemplate.required == 'Yes' ? forms_1.Validators.required : null]
        });
    };
    //referencerequestinfo:any;
    //referencecompanyinfo:any;
    ReferenceresponseComponent.prototype.loadData = function () {
        var _this = this;
        var datanew = { 'token': this.token, 'cd': this.cd };
        this.referencingmoduleService.getdesignations().subscribe(function (response) {
            // console.log(response);
            _this.getdesignations = response;
        });
        this.referencingmoduleService.getreferee(datanew).subscribe(function (response) {
            console.log(response);
            if (!response.success) {
                _this.token_expired = true;
            }
            else {
                var companyinfo = response.company;
                if (companyinfo.logoname == "" || companyinfo.logoname == null) {
                    _this.logo = "assets/img/logo.png";
                }
                else {
                    _this.logo = companyinfo.logoname;
                }
                _this.referencerequestinfo = response.referencerequest;
                _this.questionTemplatesdetails = response.questiontemplate;
                ;
                _this.addquestiontoformgroup(_this.questionTemplatesdetails);
                _this.referencecompanyinfo = response.company;
                _this.refereefilleddetailcount = 1;
                _this.addconfirmbutton = 'Submit';
                // this.getdesignations = response.designations;     
                _this.refereedetailinfo = response.referee;
                if (_this.refereedetailinfo.jobstartdate) {
                    _this.refereedetailinfo.jobstartdate = _this.refereedetailinfo.jobstartdate.split('T')[0];
                }
                if (_this.refereedetailinfo.jobenddate) {
                    _this.refereedetailinfo.jobenddate = _this.refereedetailinfo.jobenddate.split('T')[0];
                }
                _this.refereesresponseFormGroup.get("refereenamecheck").patchValue(_this.refereedetailinfo.refereename);
                _this.refereesresponseFormGroup.get("companynamecheck").patchValue(_this.refereedetailinfo.refereecompanyname);
                _this.refereesresponseFormGroup.get("candidatedesignationcheck").patchValue(_this.refereedetailinfo.candidatejobtitle);
                _this.refereesresponseFormGroup.get("jobstartdatecheck").patchValue(_this.refereedetailinfo.jobstartdate);
                _this.refereesresponseFormGroup.get("jobenddatecheck").patchValue(_this.refereedetailinfo.jobenddate);
            }
        });
    };
    ReferenceresponseComponent.prototype.consert = function (values) {
        console.log(values.currentTarget.checked);
        if (values.currentTarget.checked)
            this.btndisable = true;
        else
            this.btndisable = false;
    };
    ReferenceresponseComponent.prototype.fileChange = function (input) {
        var reader = new FileReader();
        if (input.files.length) {
            this.file = input.files[0].name;
        }
        this.uploadFile(input);
    };
    ReferenceresponseComponent.prototype.removeFile = function () {
        this.file = '';
    };
    ReferenceresponseComponent.prototype.uploadFile = function (e) {
        var file = e.files[0];
        if (file) {
            // //this.fileName = file.name;
            // const formData = new FormData();
            // formData.append("file", file);
            // this.referencingmoduleService.referencemodeluploadfile(formData).subscribe((response: any)=>{
            // });
            //this.helperService.uploadfile(file);
            //this.fileName = file.name;
            var formData = new FormData();
            formData.append("file", file, 'bulkreferencerequestsample.csv');
            //formData.append("key", "jj");
            // this.referencingmoduleService.uploadfile(formData,this.token).subscribe((response: any)=>{
            //   console.log('hi');
            // });
        }
    };
    ReferenceresponseComponent.prototype.downloadfile = function () {
        // this.referencingmoduleService.referencemodelgetuploadedfile("a.pdf").subscribe((data) => {
        //   //this.blob = new Blob([data], {type: 'application/pdf'});
        //   var downloadURL = window.URL.createObjectURL(data);
        //   var link = document.createElement('a');
        //   link.href = downloadURL;
        //   link.download = "a.pdf";
        //   link.click();
        // });
    };
    ReferenceresponseComponent.prototype.addquestiontoformgroup = function (questionTemplatesdetailslocal) {
        for (var i = 0; i < questionTemplatesdetailslocal.length; i++) {
            this.refereesresponseFormGroup.get('refereesresponsedetails').push(this.addrefereesresponsedetailFormGroup(questionTemplatesdetailslocal[i]));
        }
        var remarktemplate = {
            question: "Remark",
            questiontype: 'long',
            questionoptions: '',
            required: "No",
            response: '',
        };
        this.refereesresponseFormGroup.get('refereesresponsedetails').push(this.addrefereesresponsedetailFormGroup(remarktemplate));
    };
    ReferenceresponseComponent.prototype.next = function (currentstep) {
        var _this = this;
        var accountForm = this.accountForm;
        var personalForm = this.personalForm;
        var paymentForm = this.paymentForm;
        if (this.steps[this.steps.length - 1].active)
            return false;
        // console.log(this.refereesFormGroup);
        this.steps.forEach(function (step, index, steps) {
            // console.log(steps);
            // if(index < steps.length-1){
            if (step.active) {
                if (currentstep == 'Information') {
                    // console.log(this.refereesFormGroup);
                    _this.steps[0].active = false;
                    _this.steps[0].valid = true;
                    _this.steps[1].active = true;
                    return true;
                }
                if (currentstep == 'About You') {
                    // console.log(this.refereesFormGroup);
                    _this.submit = true;
                    if (_this.refereesFormGroup.invalid) {
                        return;
                    }
                    _this.submit = false;
                    _this.steps[1].active = false;
                    _this.steps[1].valid = true;
                    _this.steps[2].active = true;
                    return true;
                }
                if (currentstep == 'About Reference') {
                    _this.steps[index].active = false;
                    _this.steps[index].valid = true;
                    // this.steps[index+1].active=true;
                    return true;
                }
            }
            // }   
        });
    };
    ReferenceresponseComponent.prototype.confirm = function () {
        // this.refereesFormGroup = this.formBuilder.group({
        //   referencerequestid: null,  
        //   refereeid: null,       
        //   refereedesignationcheck: ['', [Validators.required]],
        //   refereephonecheck: ['', [Validators.required]],
        //   candidatedesignationcheck: ['', [Validators.required]]
        // });      
        var _this = this;
        // this.refereesresponseFormGroup 
        this.submit2 = true;
        if (this.refereesresponseFormGroup.invalid) {
            return;
        }
        this.refereesFormGroup.value.refereeip = sessionStorage.getItem('LOCAL_IP');
        var data = { ud: this.cd, value: this.refereesFormGroup.value };
        // console.log(this.refereesresponseFormGroup);
        this.referencingmoduleService.updatereferee(data).subscribe(function (response) {
            console.log(response);
        });
        this.refereesresponseFormGroup.value.companyid = this.referencecompanyinfo.companyid;
        this.refereesresponseFormGroup.value.referencerequestid = this.referencerequestinfo.id;
        this.refereesresponseFormGroup.value.refereeid = this.refereedetailinfo.id;
        this.refereesresponseFormGroup.value.companyname = this.referencecompanyinfo.companyname;
        this.refereesresponseFormGroup.value.candidatename = this.referencerequestinfo.candidatename;
        this.refereesresponseFormGroup.value.candidateemail = this.referencerequestinfo.candidateemail;
        this.refereesresponseFormGroup.value.refereename = this.refereedetailinfo.refereename;
        this.refereesresponseFormGroup.value.refereeemail = this.refereedetailinfo.refereeemail;
        // console.log('ref value', this.refereesresponseFormGroup.value);
        this.referencingmoduleService.createrefereeresponse(this.refereesresponseFormGroup.value).subscribe(function (response) {
            if (response) {
                var tokendelete = { ud: _this.cd, value: { tmptoken: '' } };
                _this.referencingmoduleService.updatereferee(tokendelete).subscribe(function (response) {
                    _this.steps[2].active = false;
                    _this.formsubmitted = true;
                });
            }
            console.log(response);
        });
    };
    ReferenceresponseComponent.prototype.dateLessThan = function (from, to) {
        return function (group) {
            var f = group.controls[from];
            var t = group.controls[to];
            if (f.value > t.value) {
                return {
                    dates: "Finish Date should be greater than start date"
                };
            }
            return {};
        };
    };
    // addItem(): void {
    //     this.items = this.refereesFormGroup.get('items') as FormArray;
    //     this.items.push(this.createItem());
    //   }
    ReferenceresponseComponent.prototype.goToTC = function () {
        window.open("http://localhost:4200/termandconditionrefmod", "_blank");
    };
    ReferenceresponseComponent.prototype.goToPP = function () {
        window.open("http://localhost:4200/privacypolicyrefmod", "_blank");
    };
    ReferenceresponseComponent.prototype.endDateValidation = function (date) {
        var d1 = new Date("12/12/2017");
        var d2 = new Date(date);
        alert(date);
    };
    ReferenceresponseComponent.prototype.stringtoarrar = function (string, delimiter) {
        return string.split(delimiter);
    };
    return ReferenceresponseComponent;
}());
exports.ReferenceresponseComponent = ReferenceresponseComponent;
