import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../../services/payment.service';

@Component({
  selector: 'app-unlimitedchecksuccess',
  templateUrl: './unlimitedchecksuccess.component.html',
  styleUrls: ['./unlimitedchecksuccess.component.css']
})
export class UnlimitedchecksuccessComponent implements OnInit {


  ctype='';
  planid:any;

  constructor(private router: Router,private activatedRoute: ActivatedRoute, public paymentService: PaymentService,) { }

  ngOnInit() {  
    
    
    this.planid = this.activatedRoute.snapshot.paramMap.get("planid");    
    this.ctype = this.activatedRoute.snapshot.paramMap.get("id");

    var data = {'token': this.ctype,planid: this.planid}
    this.paymentService.upgradeuserpaymentBysuccess(data).subscribe((data: any) => 
    {  
      // localStorage.setItem('planid', '1');
      // localStorage.setItem('struser', data.struser);
      // localStorage.setItem('isemail', "true");
      this.ctype = localStorage.getItem('ctype');      
      if(this.ctype == '3')
        this.router.navigate(['/reference-dashboard']);
      else         
        this.router.navigate(['/check-dashboard']);

    });

    setTimeout(() => {
      this.ctype = localStorage.getItem('ctype');      
      if (this.ctype == '0' ) 
      {
        this.router.navigate(['choose-plan']);
      }
     else
     {
      this.router.navigate(['choose-plan']);
     }
      // this.router.navigate(['/dashboard']);
    }, 9000);
  
  //5s
  }
}