"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CompanyadvertgroupComponent = /** @class */ (function () {
    function CompanyadvertgroupComponent() {
    }
    CompanyadvertgroupComponent.prototype.ngOnInit = function () {
    };
    return CompanyadvertgroupComponent;
}());
exports.CompanyadvertgroupComponent = CompanyadvertgroupComponent;
