"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./notpermission.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/router");
var i3 = require("@angular/common");
var i4 = require("./notpermission.component");
var styles_NotpermissionComponent = [i0.styles];
var RenderType_NotpermissionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotpermissionComponent, data: {} });
exports.RenderType_NotpermissionComponent = RenderType_NotpermissionComponent;
function View_NotpermissionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["lk"])), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "not_found"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "page404"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "content404 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["403"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sorry, You don't have permissoin of this page."])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [["class", "btn btn-danger"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(10, 1), (_l()(), i1.ɵted(-1, null, ["Go To Home"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 10, 0, "/dashboard"); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).target; var currVal_1 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_0, currVal_1); }); }
exports.View_NotpermissionComponent_0 = View_NotpermissionComponent_0;
function View_NotpermissionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notpermission", [], null, null, null, View_NotpermissionComponent_0, RenderType_NotpermissionComponent)), i1.ɵdid(1, 114688, null, 0, i4.NotpermissionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_NotpermissionComponent_Host_0 = View_NotpermissionComponent_Host_0;
var NotpermissionComponentNgFactory = i1.ɵccf("app-notpermission", i4.NotpermissionComponent, View_NotpermissionComponent_Host_0, {}, {}, []);
exports.NotpermissionComponentNgFactory = NotpermissionComponentNgFactory;
