import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class DbsService {

  constructor(private commonService: CommonService) { }

  createdbs(data): Observable<any> 
  {
    return this.commonService.formpost('/check/createdbsrecord',data);   
  }

  getalldbsListbyadmin(value: any): Observable<any> {
    return this.commonService.formpost('/dbs/allemployeedbslistbyadmin',value);      
  }
}
