"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var AddjobsComponent = /** @class */ (function () {
    function AddjobsComponent() {
    }
    AddjobsComponent.prototype.ngOnInit = function () {
    };
    return AddjobsComponent;
}());
exports.AddjobsComponent = AddjobsComponent;
