"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var UnsupportedCountryComponent = /** @class */ (function () {
    function UnsupportedCountryComponent() {
    }
    UnsupportedCountryComponent.prototype.ngOnInit = function () {
    };
    return UnsupportedCountryComponent;
}());
exports.UnsupportedCountryComponent = UnsupportedCountryComponent;
