import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { LeaveService } from '../../services/leave.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Leave } from '../../models/leave/leave.model';
import { Router } from '@angular/router';


@Component({
  selector: 'app-leavelimit',
  templateUrl: './leavelimit.component.html',
  styleUrls: ['./leavelimit.component.css']
})

export class LeavelimitComponent implements OnInit {
  Userid: any;
  constructor(private modal: NgbModal, private router: Router,private data: LeaveService) { }
  leavetypelist: any[];
  ngOnInit() {
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }
    
    this.Userid = localStorage.getItem('userid');
    const sstatustype = { 'status': '1', 'id': this.Userid } 
    this.data.getleavetypelist(sstatustype).subscribe((leavelist: any) => {      
      this.leavetypelist = leavelist;
    }); 
  }
}