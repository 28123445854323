"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var modal_service_1 = require("../_services/modal.service");
var contact_service_1 = require("../services/contact.service");
var checksystem_service_1 = require("../services/checksystem.service");
var alert_service_1 = require("../services/alert.service");
var router_1 = require("@angular/router");
var user_service_1 = require("../services/user.service");
var checkservice_service_1 = require("../services/checkservice.service");
var ChecksystemComponent = /** @class */ (function () {
    function ChecksystemComponent(modalService, formBuilder, check, contactService, alerts, router, userService, checkService, route) {
        var _this = this;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.check = check;
        this.contactService = contactService;
        this.alerts = alerts;
        this.router = router;
        this.userService = userService;
        this.checkService = checkService;
        this.route = route;
        this.defaultVal = "";
        this.dashboard = 1;
        this.identity = 0;
        this.aml = 0;
        this.amlPepCheck = 0;
        this.globalpepcheck = 0;
        this.identityCheck = 0;
        this.rtw = 0;
        this.dvla = 0;
        this.refcredit = 0;
        this.taxDefaulter = 0;
        this.adverseCredit = 0;
        this.basicDbs = 0;
        this.standardDbs = 0;
        this.enhancedDbs = 0;
        this.dircredit = 0;
        this.fcacredit = 0;
        this.admindetails = false;
        this.companycredit = 0;
        this.basicniDbs = 0;
        this.standardniDbs = 0;
        this.enhancedniDbs = 0;
        this.socilmedia = 0;
        this.basicSDbs = 0;
        this.standardSDbs = 0;
        this.enhancedSDbs = 0;
        this.advesemedia = 0;
        route.params.subscribe(function (params) {
            if (params['id']) {
                _this.dashboard = params['id'];
            }
            else {
                _this.dashboard = 1;
            }
        });
    }
    ChecksystemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.checkService.selectrighttoworkbyadmin().subscribe(function (righttoworkadmin) {
            if (righttoworkadmin.success == 'pass') {
                _this.admindetails = true;
            }
        });
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        else {
            this.userService.checkaccesstokenbyid(userid).subscribe(function (data) {
                if (data.status == false) {
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('userid');
                    localStorage.removeItem('Name');
                    localStorage.removeItem('isline');
                    localStorage.removeItem('planid');
                    localStorage.removeItem('ctype');
                    localStorage.removeItem('isemail');
                    localStorage.removeItem('ismobile');
                    localStorage.removeItem('isadmin');
                    localStorage.removeItem('ispermission');
                    localStorage.removeItem('logoname');
                    localStorage.removeItem('struser');
                    localStorage.removeItem('menu');
                    _this.router.navigate(['/login']);
                }
            });
        }
        this.ctype = parseInt(localStorage.getItem('ctype'));
        if (this.ctype === 3) {
            this.router.navigate(['/reference-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        console.log('this persimon master', this.permission);
        this.nrSelect = "";
        this.createformgroup();
        this.amlPepCheck = this.getRemainingChecks(8);
        this.globalpepcheck = this.getRemainingChecks(23);
        this.identityCheck = this.getRemainingChecks(7);
        this.rtw = this.getRemainingChecks(4);
        this.taxDefaulter = this.getRemainingChecks(9);
        this.adverseCredit = this.getRemainingChecks(6);
        this.basicDbs = this.getRemainingChecks(2);
        this.standardDbs = this.getRemainingChecks(3);
        this.enhancedDbs = this.getRemainingChecks(1);
        this.dvla = this.getRemainingChecks(5);
        this.companycredit = this.getRemainingChecks(10);
        this.refcredit = this.getRemainingChecks(11);
        this.dircredit = this.getRemainingChecks(12);
        this.fcacredit = this.getRemainingChecks(13);
        this.basicniDbs = this.getRemainingChecks(14);
        this.standardniDbs = this.getRemainingChecks(15);
        this.enhancedniDbs = this.getRemainingChecks(16);
        this.basicSDbs = this.getRemainingChecks(17);
        this.standardSDbs = this.getRemainingChecks(18);
        this.enhancedSDbs = this.getRemainingChecks(19);
        this.advesemedia = this.getRemainingChecks(22);
        this.socilmedia = this.getRemainingChecks(21);
    };
    ChecksystemComponent.prototype.getRemainingChecks = function (service_id) {
        var _this = this;
        this.check.getbillinginformation({ 'serviceid': service_id }).subscribe(function (remaindata) {
            if (service_id == 8)
                _this.amlPepCheck = remaindata.remiancheck;
            if (service_id == 7)
                _this.identityCheck = remaindata.remiancheck;
            if (service_id == 4)
                _this.rtw = remaindata.remiancheck;
            if (service_id == 9)
                _this.taxDefaulter = remaindata.remiancheck;
            if (service_id == 6)
                _this.adverseCredit = remaindata.remiancheck;
            if (service_id == 2)
                _this.basicDbs = remaindata.remiancheck;
            if (service_id == 3)
                _this.standardDbs = remaindata.remiancheck;
            if (service_id == 1)
                _this.enhancedDbs = remaindata.remiancheck;
            if (service_id == 5)
                _this.dvla = remaindata.remiancheck;
            if (service_id == 10)
                _this.companycredit = remaindata.remiancheck;
            if (service_id == 11)
                _this.refcredit = remaindata.remiancheck;
            if (service_id == 12)
                _this.dircredit = remaindata.remiancheck;
            if (service_id == 13)
                _this.fcacredit = remaindata.remiancheck;
            if (service_id == 14)
                _this.basicniDbs = remaindata.remiancheck;
            if (service_id == 15)
                _this.standardniDbs = remaindata.remiancheck;
            if (service_id == 16)
                _this.enhancedniDbs = remaindata.remiancheck;
            if (service_id == 17)
                _this.basicSDbs = remaindata.remiancheck;
            if (service_id == 18)
                _this.standardSDbs = remaindata.remiancheck;
            if (service_id == 19)
                _this.enhancedSDbs = remaindata.remiancheck;
            if (service_id == 22)
                _this.advesemedia = remaindata.remiancheck;
            if (service_id == 21)
                _this.socilmedia = remaindata.remiancheck;
            if (service_id == 23)
                _this.globalpepcheck = remaindata.remiancheck;
        });
    };
    Object.defineProperty(ChecksystemComponent.prototype, "f", {
        get: function () {
            return this.visapopForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    ChecksystemComponent.prototype.createformgroup = function () {
        this.visapopForm = this.formBuilder.group({
            fname: ['', forms_1.Validators.required],
            bussinessemail: ['', forms_1.Validators.required],
            phonenumber: ['', forms_1.Validators.required],
            querytype: ['', forms_1.Validators.required],
            subject: ['', forms_1.Validators.required],
            message: ['', forms_1.Validators.required]
        });
    };
    ChecksystemComponent.prototype.dashboardchange = function (id) {
        this.dashboard = id;
    };
    ChecksystemComponent.prototype.onSubmit = function (value3, id) {
        var _this = this;
        this.submitted = true;
        if (this.visapopForm.invalid) {
            return;
        }
        value3.mailertype = 'Contact Us';
        this.contactService.contactus(value3).subscribe(function (data) {
            _this.alerts.success("Query submitted successfully", false);
            _this.modalService.close(id);
        });
    };
    ChecksystemComponent.prototype.openModalMastersystem = function (id) {
        this.visapopForm.reset();
        this.submitted = false;
        this.modalService.open(id);
    };
    ChecksystemComponent.prototype.closeModal = function (id) {
        this.visapopForm.reset();
        this.submitted = false;
        this.modalService.close(id);
    };
    return ChecksystemComponent;
}());
exports.ChecksystemComponent = ChecksystemComponent;
