"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var status = /** @class */ (function () {
    function status() {
    }
    return status;
}());
exports.status = status;
var candidatenotes = /** @class */ (function () {
    function candidatenotes() {
    }
    return candidatenotes;
}());
exports.candidatenotes = candidatenotes;
var Candidate = /** @class */ (function () {
    function Candidate() {
    }
    return Candidate;
}());
exports.Candidate = Candidate;
var Document = /** @class */ (function () {
    function Document() {
    }
    return Document;
}());
exports.Document = Document;
