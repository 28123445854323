<app-header>
</app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">

            <a [routerLink]="['/check-personal-aml-peps-sanction']" *ngIf="logid == 0" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Personal AML, PEPs & Sanction</a>
            <a [routerLink]="['/check-business-aml-peps-sanction']" *ngIf="logid == 1" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Business AML, PEPs & Sanction</a>
          
            <div class="clearfix"></div>
            <div class="tabs__content">
                <div class="tabs__content__item active-tab">
                    <div class="content" *ngIf="atype == true">
                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Personal AML, PEPs & Sanction  Log </h5>
                            </div>

                            <!-- <div class="col-lg-6">
                              <div class="input-group">
                                  <input placeholder="Search.." class="form-control">
                              </div>

                          </div> -->
                        </div>
                        <div *ngFor="let personal of searchresult">



    
                        {{ outputnewset(personal.searchcondition)}}


                        <ng-container *ngIf="personal.apiversion ==0">

                        <div class="credit_data_showlog" >
                            <div class="job_list_section visa_request_section">

                                <div class="job_detils_date width13">
                                    <div class="visa_user_details ">
                                        <p class="job_address">Full Name {{personal.apiversion}}</p>

                                        <h4><a [routerLink]="['/creditsearchresult',personal.id]">{{ searchresultnew.Forename }} {{ searchresultnew.Middlename }} {{ searchresultnew.Surname }}</a></h4>
                                        <!-- <p class="phone text-success"> <b>Male</b></p>
                                    <p class="ninumber text-primary">Id - 5555 </p> -->
                                        <!-- <span class="badge badge-danger">Indian</span> -->
                                    </div>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">Year of Birth</p>
                                    <p class="job_profile">{{ searchresultnew.YearOfBirth }}</p>
                                    <p class="job_profile" *ngIf="searchresultnew.YearOfBirth == ''"> N/A</p>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">Town/City</p>

                                    <p class="job_profile">{{ searchresultnew.City }}</p>
                                    <p class="job_profile" *ngIf="searchresultnew.City == ''"> N/A</p>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">County/State</p>

                                    <p class="job_profile">{{ searchresultnew.County }}</p>
                                    <p class="job_profile" *ngIf="searchresultnew.County == ''"> N/A</p>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">Post/Post Code</p>

                                    <p class="job_profile">{{ searchresultnew.Postcode }}</p>
                                    <p class="job_profile" *ngIf="searchresultnew.Postcode == ''"> N/A</p>
                                </div>
                                <!-- <div class="job_detils_date text-center">
                                <p class="job_address">PEP Level</p>
                                <span class="badge badge-success">1</span>
                            </div> -->
                                <div class="job_detils_date ">
                                    <p class="job_address">Date of Birth</p>
                                    <p class="job_profile">{{ searchresultnew.DateOfBirth }}</p>
                                    <p class="job_profile" *ngIf="searchresultnew.DateOfBirth == ''"> N/A</p>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">Country</p>
                                    <p class="job_profile text-danger"><b>{{ searchresultnew.Country }}</b></p>
                                    <p class="job_profile" *ngIf="searchresultnew.Country == ''"> N/A</p>
                                </div>
                                <!-- <div class="job_detils_date aligncenter">

                                <div id="container" class="green_text">
                                    <div class="dot"></div>
                                    <div class="pulse"></div>

                                </div>
                                <p class="job_profile green_text">200 </p>


                            </div> -->



                            </div>
                            <div class="rowshw">
                                <p class="job_address">Data Set</p>
                                <div class="datasetshow">
                                    {{dataset(searchresultnew)}}
                                    <ul [innerHTML]="datasetlist"></ul>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container *ngIf="personal.apiversion ==1">

                        <div class="credit_data_showlog" >
                            <div class="job_list_section visa_request_section">

                                <div class="job_detils_date width13">
                                    <div class="visa_user_details ">
                                        <p class="job_address">Full Name</p>

                                        <h4><a [routerLink]="['/creditsearchresult',personal.id]">{{ searchresultnew.name }} </a></h4>
                                        <!-- <p class="phone text-success"> <b>Male</b></p>
                                    <p class="ninumber text-primary">Id - 5555 </p> -->
                                        <!-- <span class="badge badge-danger">Indian</span> -->
                                    </div>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">Year of Birth</p>
                                    <p class="job_profile" *ngIf="searchresultnew.datesOfBirth != ''">{{ searchresultnew.datesOfBirth | date: 'yyyy'}}</p>
                                    <p class="job_profile" *ngIf="searchresultnew.datesOfBirth == ''"> N/A</p>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">Gender</p>

                                    <p class="job_profile">{{ searchresultnew.Male }}</p>
                                    <p class="job_profile" *ngIf="searchresultnew.Male == ''"> N/A</p>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">score</p>

                                    <p class="job_profile">{{ searchresultnew.score }}</p>
                                    <p class="job_profile" *ngIf="searchresultnew.score == ''"> N/A</p>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">Resource ID</p>

                                    <p class="job_profile">{{ searchresultnew.qrCode }}</p>
                                    <p class="job_profile" *ngIf="searchresultnew.qrCode == ''"> N/A</p>
                                </div>
                                <!-- <div class="job_detils_date text-center">
                                <p class="job_address">PEP Level</p>
                                <span class="badge badge-success">1</span>
                            </div> -->
                                <div class="job_detils_date ">
                                    <p class="job_address">Date of Birth</p>
                                    <p class="job_profile" *ngIf="searchresultnew.DateOfBirth != ''">{{ searchresultnew.DateOfBirth | date: 'dd MMM yyyy' }}</p>
                                    <p class="job_profile" *ngIf="searchresultnew.DateOfBirth == ''"> N/A</p>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">Country</p>
                                    <p class="job_profile text-danger" *ngFor="let cn of searchresultnew.Country.split(',')"><b>{{ cn }}</b></p>
                                    <p class="job_profile" *ngIf="searchresultnew.Country == ''"> N/A</p>
                                </div>
                                <!-- <div class="job_detils_date aligncenter">

                                <div id="container" class="green_text">
                                    <div class="dot"></div>
                                    <div class="pulse"></div>

                                </div>
                                <p class="job_profile green_text">200 </p>


                            </div> -->



                            </div>
                            <div class="rowshw">
                                <p class="job_address">Data Set</p>
                                <div class="datasetshow">
                                    
                                    <ul  >
                                        <li *ngFor="let cn of searchresultnew.datasets.split(',')">{{cn}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </ng-container>


                        </div> 
                        
                      
                    </div>


                    <!-- <p class="job_profile"  *ngIf="!searchresult"> N/A</p> -->

                        <div class="browser-screen-loading-content" *ngIf="!searchresult" >
                            <div class="loading-dots dark-gray">
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>                                
                                <i></i>
                            </div>
                            <p>Loading</p>
                        </div>

                    <div class="content" *ngIf="atype == false">
                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Business  AML, PEPs & Sanction  Log </h5>
                            </div>

                            <!-- <div class="col-lg-6">
                              <div class="input-group">
                                  <input placeholder="Search.." class="form-control">
                              </div>

                          </div> -->
                        </div>



<div *ngFor="let personal of searchresult">
    
    {{ outputnewBussinessset(personal.searchcondition)}}

                        <div class="credit_data_showlog" >
                            <div class="job_list_section visa_request_section">

                                <div class="job_detils_date width13">
                                    <div class="visa_user_details ">
                                        <p class="job_address">Business Name</p>

                                        <h4><a  [routerLink]="['/buscreditsearchresult',personal.id]">{{ searchresultnew.BusinessName }} </a></h4>
                                        <p class="job_profile" *ngIf="searchresultnew.BusinessName == '' "> N/A</p>
                                        <!-- <p class="phone text-success"> <b>Male</b></p>
                                    <p class="ninumber text-primary">Id - 5555 </p> -->
                                        <!-- <span class="badge badge-danger">Indian</span> -->
                                    </div>
                                </div>
                                <!-- <div class="job_detils_date ">
                                    <p class="job_address">Year of Birth</p>

                                    <p class="job_profile">{{ searchresultnew.YearOfBirth }}</p>
                                </div> -->
                                <div class="job_detils_date ">
                                    <p class="job_address">Town/City</p>

                                    <p class="job_profile">{{ searchresultnew.City }}</p>
                                    <p class="job_profile" *ngIf="searchresultnew.City == ''"> N/A</p>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">County/State</p>

                                    <p class="job_profile">{{ searchresultnew.County }}</p>
                                    <p class="job_profile" *ngIf="searchresultnew.County == '' "> N/A</p>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">Post/Post Code</p>

                                    <p class="job_profile">{{ searchresultnew.Postcode }}</p>
                                    <p class="job_profile" *ngIf="searchresultnew.Postcode == '' "> N/A</p>
                                </div>
                                <!-- <div class="job_detils_date text-center">
                                <p class="job_address">PEP Level</p>
                                <span class="badge badge-success">1</span>
                            </div> -->
                                <div class="job_detils_date ">
                                    <p class="job_address">Address</p>
                                    <p class="job_profile">{{ searchresultnew.Address }}</p>
                                    <p class="job_profile" *ngIf="searchresultnew.Address == '' "> N/A</p>
                                </div>
                                <div class="job_detils_date ">
                                    <p class="job_address">Country</p>
                                    <p class="job_profile text-danger"><b>{{ searchresultnew.Country }}</b></p>
                                    <p class="job_profile" *ngIf="searchresultnew.Country == '' "> N/A</p>
                                </div>
                                <!-- <div class="job_detils_date aligncenter">

                                <div id="container" class="green_text">
                                    <div class="dot"></div>
                                    <div class="pulse"></div>

                                </div>
                                <p class="job_profile green_text">200 </p>


                            </div> -->



                            </div>
                            <div class="rowshw">
                                <p class="job_address">Data Set</p>
                                <div class="datasetshow">
                                    {{dataset(searchresultnew)}}
                                    <ul [innerHTML]="datasetlist"></ul>
                                    
                                </div>
                            </div>
                        </div>
                    </div>                  

                    <div class="job_list_section visa_request_section" *ngIf="searchresult == 0">
                        <div class="job_detils_date">
                            <p class="job_profile">No Record Found</p>
                        </div>
                    </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>