<app-header></app-header>
<div class="container">
  <div class="overviewsshow check_support mt-4">
      <div class="header">
        <h1 class="headprofile mt-0 mb-0">Support</h1>
      </div>
      <hr/>
      <form [formGroup]="supportFormgroup" #supportForm="ngForm" class="s12 white" (ngSubmit)="onSubmit(supportForm.value,'Em_support')">
      <div class="formgroup">
        <div class="row row-sm mg-t-10">
            <div class="col-sm-6 mb-2 ">
                <label class="labelresize mb-0">Full Name*</label>
              
            <input type="text" formControlName="fname" name="fname" id="fname"  class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" [maxlength]="100" placeholder="Enter Full Name">
            <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                <div *ngIf="f.fname.errors.required">Please Enter Full Name</div>
                <div *ngIf="!f.fname.errors.required && f.fname.errors.minlength">Minimum 3 characters Required</div>
            </div>  
            </div>
            <div class="col-sm-6 mb-2">
                <label class="labelresize mb-0">Business Email*</label>
              
                <input type="text" formControlName="bussinessemail" name="bussinessemail" id="bussinessemail"  [ngClass]="{ 'is-invalid': submitted && f.bussinessemail.errors }" class="form-control"  [maxlength]="100" placeholder="Enter Business Email">
                 <div *ngIf="submitted && f.bussinessemail.errors" class="invalid-feedback">
                    <div *ngIf="f.bussinessemail.errors.required">Please Enter Business Email</div>
                    <!-- <div *ngIf="!f.bussinessemail.errors.required && f.bussinessemail.errors.minlength">Minimum 3 characters Required</div> -->
                </div> 
            </div>
            <div class="col-sm-6 mb-2">
                <label class="labelresize mb-0">Phone Number*</label>
              
                <input type="text" formControlName="phonenumber" name="phonenumber" id="phonenumber"  class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" [maxlength]="100" placeholder="Enter Phone Number">
                 <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                    <div *ngIf="f.phonenumber.errors.required">Please Enter Phone Number</div>
                    <!-- <div *ngIf="!f.phonenumber.errors.required && f.phonenumber.errors.minlength">Minimum 3 characters Required</div> -->
                </div> 
            </div>
            <div class="col-sm-6 mb-2">
                <label class="mb-0 labelresize">Query Type*</label>
                <select class="form-control"  name="querytype" id="querytype"   formControlName="querytype"  [ngClass]="{ 'is-invalid': submitted && f.querytype.errors }"> 
                <option value="" [selected]="true">Select Query Type</option>
                <option value="Technical Support" >Technical Support</option>
                <option value="Billing Support" >Billing Support</option>
                <option value="Application Support" >Application Support</option>
                <option value="Others" >Others</option>

                <!-- <option *ngFor="let ltype of leavetypelist" [value]="ltype.id">{{ ltype.typename }}</option> -->
                </select>
                 <div *ngIf="submitted && f.querytype.errors" class="invalid-feedback">
                    <div *ngIf="f.querytype.errors.required">Please Select Query Type</div>
                </div> 
            </div>
            <div class="col-sm-12 mb-2">
                <label class="labelresize mb-0">Subject*</label>
              
                <input type="text" formControlName="subject" name="subject" id="subject" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" [maxlength]="100" placeholder="Enter Subject">
                 <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                    <div *ngIf="f.subject.errors.required">Please Enter Your Subject</div>
                    <!-- <div *ngIf="!f.subject.errors.required && f.subject.errors.minlength">Minimum 3 characters Required</div> -->
                </div> 
            </div>
            <div class="col-sm-12">
                <label class="mb-0 labelresize">Your Message*</label>
                <textarea   class="form-control" formControlName="message"  required name="message" id="message"  [ngClass]="{ 'is-invalid': submitted && f.message.errors }" maxlength="500" placeholder="Enter remarks here"></textarea>
                <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                    <div *ngIf="f.message.errors.required">Please Enter Your Message</div>
                </div> 
            </div>


        </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-lg-12 mt-0 text-right">
         <button  type="submit" class="btn btn-primary com_btn" [disabled]="submitted"> Submit</button>
        <button type="button" class="btn btn-secondary com_btn" (click)="clearData()" data-dismiss="modal">Clear</button> 
      </div>
  </div>
  </form>

  </div>
</div>

