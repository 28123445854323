export class Activity {
    id: number;
    employeehourapplyid: number;
    title: string;
    ahour: number;
    employeeid: number;
    projectid: number;
    locationid: number;
    hourtype: number;
    start: string;
    end: string;
    hourremark: string;
    hourremarkid: number;
  }