"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Client = /** @class */ (function () {
    function Client() {
        this.companyname = "";
        this.countryid = "";
        this.companytypeid = "";
    }
    return Client;
}());
exports.Client = Client;
