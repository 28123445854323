"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var mypassport_service_1 = require("../../../services/mypassport.service");
var country_service_1 = require("../../../services/country.service");
var forms_1 = require("@angular/forms");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var platform_browser_1 = require("@angular/platform-browser");
var passport_model_1 = require("../../../models/passport/passport.model");
var router_1 = require("@angular/router");
var myvisa_service_1 = require("../../../services/myvisa.service");
var alert_service_1 = require("../../../services/alert.service");
var app_global_1 = require("../../../app.global");
var router_2 = require("@angular/router");
var CheckaipassportComponent = /** @class */ (function () {
    function CheckaipassportComponent(formBuilder, uploadService, data, datePipe, country, sanitizer, alerts, visaService, activatedRoute, router) {
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.data = data;
        this.datePipe = datePipe;
        this.country = country;
        this.sanitizer = sanitizer;
        this.alerts = alerts;
        this.visaService = visaService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.wizard = 0;
        this.defaultVal = "";
        this.submitted = false;
        this.is_rtw = 0;
        this.filterItems = [
            {
                name: 'All Visa Details',
                id: 0,
                selected: true
            },
            {
                name: 'Accepted',
                id: 2,
                selected: false
            },
            {
                name: 'Rejected',
                id: 3,
                selected: false
            },
            {
                name: 'Waiting for review',
                id: 1,
                selected: false
            }
        ];
    }
    CheckaipassportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.activatedRoute.queryParams.subscribe(function (params) {
            if (params['is_rtw']) {
                _this.is_rtw = params['is_rtw'];
            }
        });
        this.activatedRoute.params.subscribe(function (params) {
            _this.empid = params['id'];
            _this.employeemasterid = params['id'];
        });
        // console.log(this.is_rtw!=0 || this.is_rtw!=1);
        if (this.is_rtw == 0 || this.is_rtw == 1) {
        }
        else {
            this.router.navigate(['/loginqq']);
        }
        this.getCountries();
        this.passportDetail = new passport_model_1.Passport();
        this.passformGroup = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            passportnumber: ['', forms_1.Validators.required],
            placeofissue: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            passportissue: ['', forms_1.Validators.required],
            passportexpiry: ['', forms_1.Validators.required],
            iscurrent: [''],
        });
        this.passportDetail.sstatus = 0;
        this.submitted = false;
        this.disableBtn = true;
        this.buttoninsert = "Submit";
        this.passportDetail.nationalityid = "";
        this.Userid = localStorage.getItem('userid');
        // this.employeemasterid = this.Userid;
        var dd = this.Userid; // localStorage.getItem('userid');
        this.passportDetail.employeeid = this.empid; //this.Userid;
        this.passportDetail.employeepassdeatilid = "0";
        this.filename = "Choose file";
        this.profilepic = "Choose file";
        this.filebackname = 'Choose file';
    };
    CheckaipassportComponent.prototype.getCountries = function () {
        var _this = this;
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
            console.log(_this.countrylist);
        });
    };
    Object.defineProperty(CheckaipassportComponent.prototype, "f", {
        get: function () {
            return this.passformGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    CheckaipassportComponent.prototype.toggle = function (id) {
        if (id == 1) {
            this.submitted = true;
            console.log(this.passformGroup);
            if (this.passformGroup.invalid) {
                return;
            }
        }
        this.wizard = id;
    };
    CheckaipassportComponent.prototype.toggle_skip = function (id) {
        this.imgbackURL = null;
        this.wizard = id;
    };
    CheckaipassportComponent.prototype.toggle2 = function (id) {
        // if(id == 1){
        //   this.wizard2 = false;
        //   this.wizard1 = true;
        //   this.wizard3 = false;
        // }
        // if(id == 2){
        //   this.wizard3 = false;
        //   this.wizard1 = false;
        //   this.wizard2 = true;
        // }
    };
    CheckaipassportComponent.prototype.onSubmit = function (formpassport, id, stype) {
        var _this = this;
        this.submitted = true;
        if (this.passformGroup.invalid) {
            return;
        }
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            formpassport.imagename = imagepath;
        }
        else {
            formpassport.imagename = this.passportDetail.passportdocname;
        }
        if (formpassport.imagename == '' || formpassport.imagename == null) {
            this.filereuirederror = true;
            return;
        }
        if (this.selectedbackFiles) {
            this.file = this.selectedbackFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath2 = this.uploadService.uploadfile(this.file);
            formpassport.imagebackname = imagepath2;
        }
        else {
            formpassport.imagebackname = this.passportDetail.passportdocname;
        }
        if (this.selected_profile_image) {
            this.file = this.selected_profile_image;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filebacksizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.filebackformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            formpassport.passportprofileimage = imagepath;
        }
        else {
            formpassport.passportprofileimage = this.passportDetail.passportprofileimage;
        }
        var error = 0;
        if (formpassport.passportnumber == '') {
            this.passnumbererror = false;
            error++;
        }
        if (formpassport.nationalityid == '') {
            this.nationalityerror = false;
            error++;
        }
        if (formpassport.passportissue == null) {
            this.passportdateoriginerror = false;
            error++;
        }
        if (error > 0) {
            return false;
        }
        formpassport.passportissue = this.datePipe.transform(new Date(formpassport.passportissue), "dd MMM yyyy");
        formpassport.passportexpiry = this.datePipe.transform(new Date(formpassport.passportexpiry), "dd MMM yyyy");
        formpassport.createby = this.Userid;
        formpassport.updateby = this.Userid;
        formpassport.isrighttowork = this.is_rtw;
        this.disableBtn = false;
        this.wizard = 6;
        this.data.mypassportdetailsUpdatebyadmin(formpassport).subscribe(function (datamain) {
            var data3 = { "employeepassdeatilid": datamain.employeepassdeatilid };
            var cid = { id: formpassport.countryid };
            _this.country.getcountrylistbyid(cid).subscribe(function (countrydata) {
                var countryiso = countrydata.isothree;
                var idtype = 'PASSPORT';
                var visalog = {
                    employeeid: _this.employeemasterid,
                    documentid: datamain.employeepassdeatilid,
                    documenturl: formpassport.imagename,
                    documenttype: 'p'
                };
                _this.visaService.visaailogupdate(visalog).subscribe(function (dbvisaresult) {
                    if (dbvisaresult.success == '1') {
                        var data = {
                            merchantIdScanReference: dbvisaresult.logid,
                            country: countryiso,
                            idType: idtype,
                            customerId: _this.employeemasterid,
                            is_rtw: _this.is_rtw,
                            callbackUrl: app_global_1.AppGlobals.API_ENDPOINT + "/api/aiconfirmation",
                            documenturl: formpassport.imagename,
                            documentbackurl: formpassport.imagebackname,
                            imageurl: formpassport.passportprofileimage
                        };
                        _this.visaService.visadocumentupdate(data).subscribe(function (data5) {
                            if (data5.success == true) {
                                _this.wizard = 5;
                            }
                            else {
                                _this.wizard = 4;
                                _this.alerts.error("something went wrong please try again");
                            }
                        });
                    }
                    else {
                        _this.wizard = 4;
                        _this.alerts.error("something went wrong please try again");
                    }
                });
            });
        });
        this.filename = "Choose file";
    };
    CheckaipassportComponent.prototype.sanitize = function (url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    };
    CheckaipassportComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.fileformaterror = true;
        }
        else {
            this.fileformaterror = false;
            this.filesizeerror = false;
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.imgURL = reader.result;
            };
        }
    };
    CheckaipassportComponent.prototype.selectbackFile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.filebackname = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filebacksizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.filebackformaterror = true;
            this.filebacksizeerror = false;
        }
        else {
            this.filebackformaterror = false;
            this.filebacksizeerror = false;
            this.selectedbackFiles = files[0];
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.imgbackURL = reader.result;
            };
        }
    };
    CheckaipassportComponent.prototype.selectprofile = function (event) {
        var _this = this;
        var files = event.target.files;
        this.profilepic = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.pro_pic_size_error = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.pro_pic_format_error = true;
            this.pro_pic_size_error = false;
        }
        else {
            this.pro_pic_format_error = false;
            this.pro_pic_size_error = false;
            this.selected_profile_image = files[0];
            var reader = new FileReader();
            this.imagePath = event.target.files;
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (_event) {
                _this.profile_pic_url = reader.result;
            };
        }
    };
    return CheckaipassportComponent;
}());
exports.CheckaipassportComponent = CheckaipassportComponent;
