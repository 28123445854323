"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var LeaveService = /** @class */ (function () {
    function LeaveService(commonService) {
        this.commonService = commonService;
    }
    LeaveService.prototype.getemployeeleave = function () {
        return this.commonService.formpostOnlyid('/employee/holiday');
    };
    LeaveService.prototype.getemployeeleavesick = function (data) {
        return this.commonService.formpost('/employee/holiday', data);
    };
    LeaveService.prototype.getemployeeleavecount = function (data) {
        return this.commonService.formpost('/employee/holidaycountbyyear', data);
    };
    LeaveService.prototype.getemployeeleavereport = function (data) {
        return this.commonService.formpost('/employee/holidayreport', data);
    };
    LeaveService.prototype.getemployeeleaveapprovedreport = function (data) {
        return this.commonService.formpost('/employee/holidayreportrequest', data);
    };
    LeaveService.prototype.getemployeeleaveapprovedreportbyadmin = function (data) {
        return this.commonService.formpost('/employee/holidayreportrequestbyadmin', data);
    };
    LeaveService.prototype.getleavetypelist = function (data) {
        return this.commonService.formpost('/holidaytype', data);
    };
    LeaveService.prototype.settingleavetypeUpdate = function (value) {
        return this.commonService.formpost('/holidaytypeupdatebyadmin', value);
    };
    LeaveService.prototype.myleaveApplyUpdate = function (value) {
        return this.commonService.formpost('/employee/apply-leave', value);
    };
    LeaveService.prototype.getleavedatabydate = function (value) {
        return this.commonService.formpost('/employee/leavelistbydate', value);
    };
    LeaveService.prototype.checkleaveondate = function (value) {
        return this.commonService.formpost('/employee/checkleaveofuserbydate', value);
    };
    LeaveService.prototype.getLeaveData = function (value) {
        return this.commonService.formpost('/employee/holidaybyid', value);
    };
    LeaveService.prototype.getleaveremarks = function (value) {
        return this.commonService.formpost('/employee/remarksbyholiday', value);
    };
    LeaveService.prototype.getTblemployeemasterList = function () {
        return this.commonService.formget('/api/Tblemployeemasters');
    };
    LeaveService.prototype.deleteleave = function (value) {
        return this.commonService.formpost('/employee/leavedelete', value);
    };
    LeaveService.prototype.statusleave = function (value) {
        return this.commonService.formpost('/employee/leavestatus', value);
    };
    LeaveService.prototype.addnewremark = function (value) {
        return this.commonService.formpost('/employee/addnewremark', value);
    };
    LeaveService.prototype.checkpendingleave = function (value) {
        return this.commonService.formpost('/employee/checkpendingleave', value);
    };
    LeaveService.prototype.leavetypeUpdateChangeStatus = function (value) {
        return this.commonService.formpost('/company/leavetypeupdatechangestatus', value);
    };
    /// report admin
    LeaveService.prototype.leavereport = function (data) {
        return this.commonService.formpost('/employee/employeeleavereport', data);
    };
    LeaveService.prototype.getholidaylist = function () {
        return this.commonService.formget('/company/holidaytype');
    };
    LeaveService.ngInjectableDef = i0.defineInjectable({ factory: function LeaveService_Factory() { return new LeaveService(i0.inject(i1.CommonService)); }, token: LeaveService, providedIn: "root" });
    return LeaveService;
}());
exports.LeaveService = LeaveService;
