"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var CheckserviceService = /** @class */ (function () {
    function CheckserviceService(commonService) {
        this.commonService = commonService;
    }
    CheckserviceService.prototype.rtwquestionlist = function () {
        return this.commonService.formpostOnlyid('/check/questionlist');
    };
    CheckserviceService.prototype.rtwquestionbyid = function (data) {
        return this.commonService.formpost('/check/questionlist', data);
    };
    CheckserviceService.prototype.getAllServices = function () {
        return this.commonService.formpostOnlyid('/api/getCheckServices');
    };
    CheckserviceService.prototype.getstripedefaultcard = function () {
        return this.commonService.formpostOnlyid('/stripe/getdefaultcard');
    };
    CheckserviceService.prototype.orderpayment = function (data) {
        return this.commonService.formpost('/check/orderpayment', data);
    };
    CheckserviceService.prototype.completeorder = function (data) {
        return this.commonService.formpost('/check/completeorder', data);
    };
    CheckserviceService.prototype.checkrestripecustomer = function () {
        return this.commonService.formpostOnlyid('/check/stripecustomercheck');
    };
    CheckserviceService.prototype.registereduserpayment = function (data) {
        return this.commonService.formpost('/check/registereduserpayment', data);
    };
    CheckserviceService.prototype.registereduserpayment_new = function (data) {
        return this.commonService.formpost('/check/registereduserpayment_new', data);
    };
    CheckserviceService.prototype.upgradeuserpayment = function (data) {
        return this.commonService.formpost('/check/upgradeuserpayment', data);
    };
    CheckserviceService.prototype.subscripepackagepayment = function (data) {
        return this.commonService.formpost('/check/subscribepackage', data);
    };
    CheckserviceService.prototype.subscripepackagepaymentbystripe = function (data) {
        return this.commonService.formpost('/check/subscribepackagebystripe', data);
    };
    CheckserviceService.prototype.checkrepayment = function (data) {
        return this.commonService.formpost('/check/repayment', data);
    };
    CheckserviceService.prototype.addUpdateAdverseCredit = function (data) {
        return this.commonService.formpost('/company/searchtest', data);
    };
    CheckserviceService.prototype.checkhistory = function (data) {
        return this.commonService.formpost('/company/adversesearchhistory', data);
    };
    CheckserviceService.prototype.adversecredithistorydownload = function (data) {
        return this.commonService.formpost('/company/downloadadversecreditreport', data);
    };
    CheckserviceService.prototype.adversecredithistoryview = function (data) {
        return this.commonService.formpost('/company/viewadversecreditreport', data);
    };
    CheckserviceService.prototype.allquestion = function (data) {
        return this.commonService.formpost('/question/allquestion', data);
    };
    CheckserviceService.prototype.insertnewrighttowork = function (data) {
        return this.commonService.formpost('/employee/righttoworklog', data);
    };
    CheckserviceService.prototype.updaterighttowork = function (data) {
        return this.commonService.formpost('/employee/updaterighttoworklog', data);
    };
    CheckserviceService.prototype.updatenewrighttowork = function (data) {
        return this.commonService.refereeformpost('/employee/updatelogsystem', data);
    };
    CheckserviceService.prototype.selectrighttoworkbyadmin = function () {
        return this.commonService.formpostOnlyid('/admin/righttoworkadminpermission');
    };
    CheckserviceService.prototype.selectrighttowork = function (data) {
        return this.commonService.formpost('/employee/righttoworkbyid', data);
    };
    CheckserviceService.prototype.getRightToWorkLogById = function (data) {
        return this.commonService.refereeformpost('/employee/righttoworklogbyid', data);
    };
    CheckserviceService.prototype.resedapplicantrequest = function (data) {
        return this.commonService.formpost('/employee/applicantrequestsendagain', data);
    };
    CheckserviceService.prototype.resedapplicantrequestwidthdocumentupdatebysuperadmin = function (data) {
        return this.commonService.formpost('/company/righttoworkresendrequestbysuperadmin', data);
    };
    CheckserviceService.prototype.resedapplicantrequestwidthdocumentupdate = function (data) {
        return this.commonService.formpost('/company/righttoworkresendrequest', data);
    };
    CheckserviceService.prototype.getrighttoworktoken = function (data) {
        return this.commonService.formpostForWithoutTokenForrighttowork('/employee/getrighttoworktoken', data);
    };
    CheckserviceService.prototype.getrighttoworkhistorybyemployeeid = function (data) {
        return this.commonService.formpost('/employee/righttoworkhistorybyid', data);
    };
    CheckserviceService.prototype.getrighttoworkhistorybyemployeeadmin = function (data) {
        return this.commonService.formpost('/employee/righttoworkhistorybyadmin', data);
    };
    CheckserviceService.prototype.getrighttoworkdetailsbylogid = function (data) {
        return this.commonService.formpost('/employee/righttoworkdetailsbylogid', data);
    };
    CheckserviceService.prototype.getrighttoworkdetailsbylogidsuperadmin = function (data) {
        return this.commonService.formpost('/employee/righttoworkdetailsbylogidbysuperadmin', data);
    };
    CheckserviceService.prototype.downloadRightToWorkLog = function (data) {
        return this.commonService.formpost('/download/downloadrighttowork', data);
    };
    CheckserviceService.prototype.checkCoupon = function (data) {
        return this.commonService.formpost('/payment/checkcoupon', data);
    };
    /** Screening APIs */
    CheckserviceService.prototype.checkqrtoken = function (data) {
        return this.commonService.formpostreferencingapi('/screening/api/checkqrtoken', data);
    };
    CheckserviceService.prototype.updatescreeningpassport = function (data) {
        return this.commonService.formpostreferencingapi('/screening/api/updatepassport', data);
    };
    CheckserviceService.prototype.updatescreeningbrp = function (data) {
        return this.commonService.formpostreferencingapi('/screening/api/updatebrp', data);
    };
    CheckserviceService.prototype.updatescreeningdl = function (data) {
        return this.commonService.formpostreferencingapi('/screening/api/updatedl', data);
    };
    CheckserviceService.ngInjectableDef = i0.defineInjectable({ factory: function CheckserviceService_Factory() { return new CheckserviceService(i0.inject(i1.CommonService)); }, token: CheckserviceService, providedIn: "root" });
    return CheckserviceService;
}());
exports.CheckserviceService = CheckserviceService;
