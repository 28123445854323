"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var customer_service_1 = require("../../services/customer.service");
var visa_model_1 = require("../../models/visa/visa.model");
var modal_service_1 = require("../../_services/modal.service");
var country_service_1 = require("../../services/country.service");
var myvisa_service_1 = require("../../services/myvisa.service");
var forms_1 = require("@angular/forms");
var platform_browser_1 = require("@angular/platform-browser");
var upload_file_service_1 = require("./../../services/upload-file.service");
var alert_service_1 = require("../../services/alert.service");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var VisaComponent = /** @class */ (function () {
    function VisaComponent(sanitizer, uploadService, formBuilder, data, country, modalService, data2, alerts) {
        this.sanitizer = sanitizer;
        this.uploadService = uploadService;
        this.formBuilder = formBuilder;
        this.data = data;
        this.country = country;
        this.modalService = modalService;
        this.data2 = data2;
        this.alerts = alerts;
        this.visa = new visa_model_1.Visa();
        this.visaDetail = new visa_model_1.Visa();
        this.defaultValue = "";
    }
    VisaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.VisaForm = this.formBuilder.group({
            employeeid: ['', forms_1.Validators.required],
            id: ['', forms_1.Validators.required],
            visanumber: ['', forms_1.Validators.required],
            visatype: ['', forms_1.Validators.required],
            // countryid: ['', Validators.compose([Validators.required, Validators.min(1)])],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            visaexpiry: ['', forms_1.Validators.required],
            visastart: ['', forms_1.Validators.required],
        });
        this.filename = 'Choose file';
        var id = localStorage.getItem('userToken');
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
        this.data.getVisa().subscribe(function (data) {
            _this.visa = data;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
    };
    Object.defineProperty(VisaComponent.prototype, "f", {
        get: function () { return this.VisaForm.controls; },
        enumerable: true,
        configurable: true
    });
    VisaComponent.prototype.onSubmit = function (value3, id) {
        var _this = this;
        this.submitted = true;
        if (this.VisaForm.invalid) {
            return;
        }
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            var typeoffile = filetype[filetype.length - 1];
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((typeoffile != "jpeg") && (typeoffile != "jpg") && (typeoffile != "png") && (typeoffile != "gif") && (typeoffile != "tif") && (typeoffile != "pdf") && (typeoffile != "doc") && (typeoffile != "docx")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagename = imagepath;
        }
        else {
            value3.imagename = this.visaDetail.visadocname;
        }
        this.data2.myvisadetailsUpdate(value3).subscribe(function (data) {
            var userid = localStorage.getItem('userid');
            _this.data.getVisa().subscribe(function (data) {
                _this.visa = data;
            });
        });
        this.alerts.success('Visa updated successfully', true);
        this.modalService.close(id);
    };
    VisaComponent.prototype.selectFile = function (event) {
        var files = event.target.files;
        this.filename = files[0].name;
    };
    VisaComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    VisaComponent.prototype.openModal3 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    VisaComponent.prototype.openModal = function (id, data) {
        this.visaDetail = new visa_model_1.Visa();
        this.visaDetail = data;
        this.modalService.open(id);
    };
    VisaComponent.prototype.closeModal = function (id) {
        var _this = this;
        this.visa = new visa_model_1.Visa();
        //  const id = localStorage.getItem('userToken');
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
        this.data.getVisa().subscribe(function (data1) {
            _this.visa = data1;
        });
        this.modalService.close(id);
    };
    VisaComponent.prototype.modalOpen = function (data) {
        this.visaDetail = data;
    };
    return VisaComponent;
}());
exports.VisaComponent = VisaComponent;
