import { Component, OnInit, NgZone } from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators, FormArray} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import { ReferenceService } from '../../services/reference.service';
import { CompanyService } from '../../services/company.service';
import { HelperService } from '../../services/helper.service';
import { CommonService } from '../../services/common.service';
import { ChecksystemService } from '../../services/checksystem.service';
// import {} from 'rxjs';

@Component({
  selector: 'app-useraddreference',
  templateUrl: './useraddreference.component.html',
  styleUrls: ['./useraddreference.component.css']
})
export class UseraddreferenceComponent implements OnInit {

  isvalidemail:boolean = true;
  addconfirmbutton:string;
  refereesFormGroup: FormGroup;
  employeemasterid:any;
//   items: FormArray;
  refereefilleddetailcount:number;
  intrefereefilleddetailcount:number;
  createrefereerequest:any;
  getdesignations:any;
  Userid: any;
  logo:any;
  token_expired: any = false;
  referencerequestinfo:any;
  referencecompanyinfo:any;
  refereedetailinfo:any;
  token:any;
  rd:string;
  cd:string;
  referencing:number;
  submit: any = false; mobilecheck: any;
  dateToday = new Date();
  fnlast:boolean = false;
  btndisable: boolean =false;
  

  constructor(
      private formBuilder: FormBuilder,
      private commonService: CommonService, 
      private activatedRoute:ActivatedRoute,
      private referencingmoduleService:ReferenceService,
      private company :CompanyService,
      private helperService: HelperService,
      private check: ChecksystemService,
      private zone: NgZone
 ) { 
    // this.helperService.determineLocalIp();
      this.steps = [
        {name: 'Reference Request', icon: 'fa-info-circle', active: true, valid: false, hasError:false },
        {name: 'Referee Submission', icon: 'fa-user-o', active: false, valid: false, hasError:false },
        {name: 'Done'}
      ]
      
  }

  ngOnInit() {
    //this.determineLocalIp() 
    // this.helperService.determineLocalIp();  



    // console.log(this.activatedRoute.snapshot.queryParams['token']);
    // console.log(this.activatedRoute.snapshot.queryParams['cd']);
    // console.log(this.activatedRoute.snapshot.queryParams['ud']);
    // console.log(this.activatedRoute.snapshot.queryParams['rd']);


    this.check.getIPAddress().subscribe((data: any) => {       
      console.log('system IP address',data.ip )
      sessionStorage.setItem('LOCAL_IP', data.ip);

    });
    
    this.token=this.activatedRoute.snapshot.queryParams['token']
    this.rd=this.activatedRoute.snapshot.queryParams['rd'];
    this.cd=this.activatedRoute.snapshot.queryParams['cd'];
    localStorage.setItem('userToken1',this.token);

    this.check.gettokensystem().subscribe((data: any) => { 
      console.log(data);
      if (data.sussess == 'true') {
        // localStorage.setItem('refereeuser', data);
        // localStorage.setItem('userid', data.userid);
        // localStorage.setItem('emptype', data.emptype);
        // localStorage.setItem('isline', data.isline);
        // localStorage.setItem('planid', data.planid);
        // localStorage.setItem('isemail', data.isemail);
        // localStorage.setItem('isadmin', data.isadmin);
        // localStorage.setItem('ispermission', data.ispermission);
        // localStorage.setItem('logoname', data.logourl);
        // localStorage.setItem('ctype', data.ctype);
        // const id = localStorage.getItem('userToken');
        // this.Userid = localStorage.getItem('userid');
        this.Userid = data.userid;

        
       
        if (data.logourl == "" || data.logourl == null)  {
          this.logo = "assets/img/logo.png";
        }else
        {
          this.logo =data.logourl;
        }        
        this.employeemasterid = data.userid;
      }
      else
      {
        this.token_expired = true;
      }
    });

    this.referencing = 0;
    this.addconfirmbutton='Save Referee';
   
    this.refereesFormGroup = this.formBuilder.group({
        candidateip:null,
        referencerequestid: null,
        token: null,
        companyid: null,
        userid: null,
        referencerequest: '',
        candidatename: '',
        candidateemail: '',
        companyname: '',
        refereename: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
        refereedesignation: ['', [Validators.required]],
        refereeemail: ['', [Validators.required, Validators.email]],
        refereephone: ['', [Validators.required, Validators.pattern("^[\+0-9]*$")]],
        refereecompanyname: ['', [Validators.required]],
        candidatejobtitle: ['', [Validators.required]],
        jobstartdate: [null, [Validators.required]],
        jobenddate: '',
        currentjob:'',
        isreadonly:'',
        status:'',
        createdon:new Date(),
        candidatemac:this.token
      });//, { validator: this.dateLessThan('jobstartdate', 'jobenddate') });

      this.loadData();
      
      this.intrefereefilleddetailcount=1;
      this.createrefereerequest=[];
      localStorage.setItem('addrefereeseq',this.token);
  }



  
consertemail(values:any):void {
  if(values.currentTarget.checked)
    this.fnlast = true;
  else
    this.fnlast = false;
}


  get refree(){
    return this.refereesFormGroup.controls;
  }

  consert(values:any):void {
    console.log(values.currentTarget.checked);

    if(values.currentTarget.checked)
      this.btndisable = true;
    else
      this.btndisable = false;
  }

  
  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      
    });
  }

  //referencerequestinfo:any;
  //referencecompanyinfo:any;
  loadData(){
    // this.referencingmoduleService.getip().subscribe((response: any)=>{
    //   this.candidateip = response;     
    // });

    this.referencingmoduleService.getdesignations().subscribe((response: any)=>{
      this.getdesignations = response;     
    });

    let data = {token: this.token, rd: this.rd}
    
    this.referencingmoduleService.getreferencerequest(this.rd).subscribe((response: any)=>{
      this.referencerequestinfo = response[0];
      this.refereefilleddetailcount=this.referencerequestinfo.numberofreferee;
      //this.addconfirmbutton='Submit';
      //this.selectedtemplate = response[0].id;
      //this.referencingmoduleService.getquestiontemplatedetails(this.questionTemplates[0].id).subscribe((response1)=>{
      //    this.questionTemplatesdetails = response1;
      //  })
    });


    let data1 = {token: this.token, cd: this.cd}
    this.company.getcompanydetailsbyid({token: this.token}).subscribe((response: any)=>{
     
      this.referencecompanyinfo = response;
      //this.selectedtemplate = response[0].id;
      //this.referencingmoduleService.getquestiontemplatedetails(this.questionTemplates[0].id).subscribe((response1)=>{
      //    this.questionTemplatesdetails = response1;
      //  })
    });


    this.referencingmoduleService.getrefereedetail('referencerequestid,'+this.rd).subscribe((response: any)=>{
      this.refereedetailinfo = response;
      
      this.intrefereefilleddetailcount += response.length;
      for(let i=0;i<response.length;i++){
        if(response.isresponded != null || response.isresponded != 1)
         this.referencing += 1;
         
      }

      for(let i=0;i<this.refereedetailinfo.length;i++){
        if(this.refereedetailinfo[i].jobstartdate){
          this.refereedetailinfo[i].jobstartdate =      this.refereedetailinfo[i].jobstartdate.split('T')[0];
        }
        if(this.refereedetailinfo[i].jobenddate){
          this.refereedetailinfo[i].jobenddate =      this.refereedetailinfo[i].jobenddate.split('T')[0];
        }
        
        
      }
      //this.selectedtemplate = response[0].id;
      //this.referencingmoduleService.getquestiontemplatedetails(this.questionTemplates[0].id).subscribe((response1)=>{
      //    this.questionTemplatesdetails = response1;
      //  })
    });

   console.log(this.steps)
  }




  



  

//   createItem(): FormGroup {
//     return this.formBuilder.group({
//         refereename:'',
//         refereedesignation:'',
//         refereeemail:'',
//         refereephone:'',
//         refereecompanyname:'',
//         candidatejobtitle:'',
//         jobstartdate:'',
//         jobenddate:'',
//         isreadonly:'',
//         status:''
//     });
//   }


  

  public steps:any[];
    public accountForm:FormGroup;
    public personalForm:FormGroup;
    public paymentForm:FormGroup;
    public details:any = {};
    public showConfirm:boolean;

   

    public next(){
        let accountForm = this.accountForm;
        let personalForm = this.personalForm;
        let paymentForm = this.paymentForm;

        if(this.steps[this.steps.length-1].active)
            return false;
            
        this.steps.some(function (step, index, steps) {
            if(index < steps.length-1){
                if(step.active){
                    if(step.name=='Reference Request'){
                       
                            step.active = false;
                            step.valid = true;
                            steps[index+1].active=true;
                            return true;
                                            
                    }
                    if(step.name=='Referee Submission'){
                       
                            step.active = false;
                            step.valid = true;
                            steps[index+1].active=true;
                            return true;
                                         
                    }
                    if(step.name=='Done'){
                       
                            step.active = false;
                            step.valid = true;
                            steps[index+1].active=true;
                            return true;
                                           
                    }
                }
            }   
        });

     
    }

   

    public confirm(){
      this.submit = true;
      
      console.log(this.refereesFormGroup);
      if (this.refereesFormGroup.invalid) {
        return;
      }
      if(this.intrefereefilleddetailcount == this.refereefilleddetailcount){
        this.steps[0].active = true;
      }
      if(this.isvalidemail)
        if(this.intrefereefilleddetailcount < this.refereefilleddetailcount)
        {
            this.intrefereefilleddetailcount+=1;
            this.intrefereefilleddetailcount  == this.refereefilleddetailcount ? this.addconfirmbutton='Done': null;
            // if(this.intrefereefilleddetailcount  == this.refereefilleddetailcount){
            //   this.steps[0].active = false;
            // }
            this.refereesFormGroup.value.companyid = this.cd;
            this.refereesFormGroup.value.referencerequestid = this.rd;
            this.refereesFormGroup.value.candidatename = this.referencerequestinfo.candidatename;
            this.refereesFormGroup.value.candidateemail = this.referencerequestinfo.candidateemail;
            this.refereesFormGroup.value.companyname = this.referencecompanyinfo.companyname;
            this.refereesFormGroup.value.candidateip = sessionStorage.getItem('LOCAL_IP');
            this.refereesFormGroup.value.createdon = new Date();
            this.refereesFormGroup.value.candidatemac =this.token ;
            this.createrefereerequest.push(this.refereesFormGroup.value);




            
            const sd = new Date(this.refereesFormGroup.value.jobstartdate);
            this.refereesFormGroup.value.jobstartdate = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());

            if(this.refereesFormGroup.value.jobenddate)
            {
              const ed = new Date(this.refereesFormGroup.value.jobenddate);
              this.refereesFormGroup.value.jobenddate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
            }

            console.log('Start Date');

            console.log('End Date');
            
            this.referencingmoduleService.createreferee(this.createrefereerequest).subscribe((response: any)=>{        
              console.log(response)
             });
            this.refereesFormGroup.reset();
            this.submit = false;
            this.fnlast = false;
            this.createrefereerequest=[];
            // if(this.intrefereefilleddetailcount  == this.refereefilleddetailcount){
            //   this.steps[2].active = true;
            //   this.steps[2].valid = true;
            // }
        } else{
        // this.addItem();
        this.refereesFormGroup.value.companyid = this.cd;
        this.refereesFormGroup.value.referencerequestid = this.rd;
        this.refereesFormGroup.value.candidatename = this.referencerequestinfo.candidatename;
        this.refereesFormGroup.value.candidateemail = this.referencerequestinfo.candidateemail;
        this.refereesFormGroup.value.companyname = this.referencecompanyinfo.companyname;
        this.refereesFormGroup.value.candidateip = sessionStorage.getItem('LOCAL_IP');
        this.refereesFormGroup.value.createdon = new Date();
        this.refereesFormGroup.value.candidatemac =this.token ;
        this.createrefereerequest.push(this.refereesFormGroup.value);
        this.steps.forEach(step => step.valid = false);
        this.steps.forEach(step => step.active = false);
        //console.log(JSON.stringify(this.createrefereerequest.value));
        this.addconfirmbutton='Submited'

        const sd = new Date(this.refereesFormGroup.value.jobstartdate);
        this.refereesFormGroup.value.jobstartdate = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());

        if(this.refereesFormGroup.value.jobenddate)
        {
          const ed = new Date(this.refereesFormGroup.value.jobenddate);
          this.refereesFormGroup.value.jobenddate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        }

        console.log('Start Date');

        console.log('End Date');
        
        //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.createrefereerequest, null, 4));
        this.referencingmoduleService.createreferee(this.createrefereerequest).subscribe((response: any)=>{        
          this.steps[2].active = true;
          this.steps[2].valid = true;
          this.fnlast = false;
          console.log(response)
        });
        this.createrefereerequest=[];
        this.submit = false;
        // this.next()
        }
    }


    dateLessThan(from: string, to: string) {
      return (group: FormGroup): { [key: string]: any } => {
        let f = group.controls[from];
        let t = group.controls[to];
        if (f.value > t.value) {
          return {
            dates: "Finish Date should be greater than start date"
          };
        }
        return {};
      }
    }


    

    // addItem(): void {
    //     this.items = this.refereesFormGroup.get('items') as FormArray;
    //     this.items.push(this.createItem());
    //   }
    

    goToTC(){
        window.open("http://localhost:4200/termandconditionrefmod", "_blank");
    }

    goToPP(){
        window.open("http://localhost:4200/privacypolicyrefmod", "_blank");
    }

    endDateValidation(date) {
      let d1 = new Date("12/12/2017");
      let d2 = new Date(date);
  
  
      alert(date)
    }

    validateemail(){

      this.isvalidemail = true;

      // this.check.validateemail(this.refereesFormGroup.value.refereeemail).subscribe((response) => {
      //   if(response.status === "valid")
      //   this.isvalidemail = true;
      //   else
      //   this.isvalidemail = false;
      // })
    }


}
