import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../models/user.model';
import { AlertService } from '../../services/alert.service';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';

@Component({
  selector: 'app-forgot-requestpassword',
  templateUrl: './forgot-requestpassword.component.html',
  styleUrls: ['./forgot-requestpassword.component.css']
})

export class ForgotRequestpasswordComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  isLoginError = false;
  msg: string = null;
  user = new User();
  error: string;

  constructor(private formBuilder: FormBuilder, private userService: UserService, private router: Router, private alerts: AlertService) { }
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      username: ['', [Validators.required]]
    });
  }

  get f() { return this.registerForm.controls; }
  forgotRequest(email) {

    console.log('user name - ',email);

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    // tslint:disable-next-line:max-line-length
    this.userService.forgotRequest(email).subscribe((data4: any) => {
      if (data4.success == 'success') {
        this.alerts.success('Request successful', true);
        this.router.navigate(['/login']);
      }
      else {
        this.isLoginError = true;
        this.alerts.error('This username is not registered with us', true);
        this.msg = '';
        this.error = ' This username is not registered with us';
      }
      //   this.router.navigate(['forgotRequest']);
      // this.msg = 'The mail has been successfully sent';
      this.error = '';
    },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
        this.alerts.error('This email is not registered with us', true);
        this.msg = '';
        this.error = ' This email is not registered with us';
      });
  }
}