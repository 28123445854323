"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var country_service_1 = require("../../../services/country.service");
var myvisa_service_1 = require("../../../services/myvisa.service");
var modal_service_1 = require("../../../_services/modal.service");
var forms_1 = require("@angular/forms");
var visa_model_1 = require("../../../models/visa/visa.model");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var platform_browser_1 = require("@angular/platform-browser");
// import { Router } from '@angular/router';
var checksystem_service_1 = require("../../../services/checksystem.service");
var router_1 = require("@angular/router");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var alert_service_1 = require("../../../services/alert.service");
var CheckvisaComponent = /** @class */ (function () {
    function CheckvisaComponent(sanitizer, formBuilder, uploadService, data, country, modalService, activatedRoute, alerts, check, router) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.data = data;
        this.country = country;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.alerts = alerts;
        this.check = check;
        this.router = router;
        this.aipayment = false;
        this.defaultVal = "";
        this.filterItems = [
            {
                name: 'All Visa Details',
                id: 0,
                selected: true
            },
            {
                name: 'Accepted',
                id: 2,
                selected: false
            },
            {
                name: 'Rejected',
                id: 3,
                selected: false
            },
            {
                name: 'Waiting for review',
                id: 1,
                selected: false
            }
        ];
    }
    CheckvisaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.empid = this.activatedRoute.snapshot.paramMap.get("id");
        this.visapopForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            visanumber: ['', forms_1.Validators.required],
            visatype: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            visaexpiry: ['', forms_1.Validators.required],
            visastart: ['', forms_1.Validators.required],
            iscurrent: ['']
        });
        this.filebackvisareuirederror = false;
        this.filevisareuirederror = false;
        this.filereuirederror = false;
        this.filevisaname = 'Choose file';
        this.filevisabackname = 'Choose file';
        this.visaDetail = new visa_model_1.Visa();
        this.check.getbillinginformation({ 'serviceid': 7 }).subscribe(function (remaindata) {
            _this.ramainingChecks = remaindata;
        });
        var id = localStorage.getItem('userToken');
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.empid, 'cstatus': [1, 2, 3] };
        console.log(this.empid);
        this.data.getvisaAllListbyadmin(data).subscribe(function (data) {
            _this.visalistbyadmin = data;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.listcheck = this.filterItems;
    };
    CheckvisaComponent.prototype.onDelete = function (id) {
        var _this = this;
        var data1 = { 'id': id };
        if (confirm("Are you sure you want to delete?")) {
            this.data.deletevisa(data1).subscribe(function (data) {
                var sstatus;
                for (var _i = 0, _a = _this.filterItems; _i < _a.length; _i++) {
                    var c = _a[_i];
                    if (c.selected == true) {
                        if (c.id == 0) {
                            sstatus = [1, 2, 3];
                        }
                        else {
                            sstatus = [c.id];
                        }
                    }
                }
                var data2 = { 'id': _this.empid, 'cstatus': sstatus };
                _this.data.getvisaAllListbyadmin(data2).subscribe(function (data) {
                    _this.visalistbyadmin = data;
                });
                _this.alerts.success('Visa delete successfully.', true);
            });
        }
    };
    CheckvisaComponent.prototype.onlistchange = function (type1, idval) {
        var _this = this;
        var sstatus;
        for (var _i = 0, _a = this.filterItems; _i < _a.length; _i++) {
            var c = _a[_i];
            if (c.id == idval)
                c.selected = true;
            else
                c.selected = false;
        }
        if (idval == 0 || type1 == false) {
            sstatus = [1, 2, 3];
        }
        else {
            sstatus = [idval];
        }
        var data = { 'id': this.empid, 'cstatus': sstatus };
        this.data.getvisaAllListbyadmin(data).subscribe(function (data) {
            _this.visalistbyadmin = data;
        });
    };
    Object.defineProperty(CheckvisaComponent.prototype, "f", {
        get: function () {
            return this.visapopForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    CheckvisaComponent.prototype.onSubmit = function (formvisa, id) {
        var _this = this;
        // var formvisa = value3.value;
        this.submitted = true;
        if (this.visapopForm.invalid) {
            return;
        }
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.fileformaterror = true;
                this.filesizeerror = false;
                return;
            }
            else {
                this.filereuirederror = false;
                this.fileformaterror = false;
                this.filesizeerror = false;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            formvisa.imagename = imagepath;
        }
        else {
            formvisa.imagename = this.visaDetail.visadocname;
        }
        if (this.selectedvisabackFiles) {
            this.file = this.selectedvisabackFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filevisabacksizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.filevisabackformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            formvisa.imagebackname = imagepath;
        }
        else {
            formvisa.imagebackname = this.visaDetail.visabackdocname;
        }
        console.log("rahul", formvisa.imagebackname);
        console.log("Tanwar", formvisa.imagename);
        if (formvisa.imagebackname == '') {
            this.filebackvisareuirederror = true;
            return;
        }
        if (formvisa.imagename == '') {
            this.filevisareuirederror = true;
            return;
        }
        formvisa.createby = this.Userid;
        formvisa.updateby = this.Userid;
        this.disableBtn = false;
        this.data.myvisadetailsUpdatebyadmin(formvisa).subscribe(function (data) {
            var sstatus;
            for (var _i = 0, _a = _this.filterItems; _i < _a.length; _i++) {
                var c = _a[_i];
                if (c.selected == true) {
                    if (c.id == 0) {
                        sstatus = [1, 2, 3];
                    }
                    else {
                        sstatus = [c.id];
                    }
                }
            }
            var data1 = { 'id': _this.empid, 'cstatus': sstatus };
            _this.data.getvisaAllListbyadmin(data1).subscribe(function (data) {
                _this.visalistbyadmin = data;
            });
        });
        this.filename = "Choose file";
        this.filevisabackname = "Choose file";
        this.alerts.success('Visa update successfully.', true);
        this.modalService.close(id);
    };
    CheckvisaComponent.prototype.selectVisaFileupload = function (event) {
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.fileformaterror = true;
        }
        else {
            this.fileformaterror = false;
            this.filesizeerror = false;
            this.filevisareuirederror = false;
            this.filevisaname = this.filename; //files[0]; 
        }
    };
    CheckvisaComponent.prototype.selectvisabackFileupload = function (event) {
        var files = event.target.files;
        this.filevisabackname = files[0].name;
        this.file = files[0]; //this.selectedvisabackFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filevisabacksizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.filevisabackformaterror = true;
            this.filevisabacksizeerror = false;
        }
        else {
            this.filevisabackformaterror = false;
            this.filevisabacksizeerror = false;
            this.filebackvisareuirederror = false;
            this.selectedvisabackFiles = files[0];
        }
    };
    CheckvisaComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.disableBtn = true;
        this.filereuirederror = false;
        this.selectVisaFile = null;
        this.selectvisabackFile = null;
        this.buttoninsert = "Submit";
        this.visaDetail = new visa_model_1.Visa();
        this.visaDetail.employeeid = this.empid;
        this.visaDetail.employeevisadeatilid = "0";
        this.visaDetail.nationalityid = '';
        this.filevisaname = "Choose file";
        this.filevisabackname = "Choose file";
        this.modalService.open(id);
    };
    CheckvisaComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    CheckvisaComponent.prototype.openModalvisa3 = function (id, data) {
        this.iframevisasrc = '';
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgvisasrc = true;
            this.iframevisasrc = data;
        }
        else {
            this.imgvisasrc = false;
            this.iframevisasrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    CheckvisaComponent.prototype.openModal = function (id, data) {
        this.disableBtn = true;
        this.selectVisaFile = null;
        this.selectvisabackFile = null;
        this.visaDetail = data;
        this.filevisaname = "Choose file";
        this.filevisabackname = "Choose file";
        this.buttoninsert = "Update";
        this.modalService.open(id);
    };
    CheckvisaComponent.prototype.closeModal = function (id) {
        var _this = this;
        this.visa = new visa_model_1.Visa();
        this.visaDetail = new visa_model_1.Visa();
        var data = { 'id': this.empid, 'cstatus': [1, 2, 3] };
        this.data.getvisaAllListbyadmin(data).subscribe(function (data) {
            _this.visalistbyadmin = data;
        });
        this.modalService.close(id);
    };
    CheckvisaComponent.prototype.uploadByAdmin = function (emp_type, type, empid) {
        if (this.ramainingChecks && this.ramainingChecks.remiancheck < 1) {
            this.modalService.open('upgrade-plan');
        }
        else {
            if (type == 'upload_now') {
                if (emp_type == 'visa') {
                    this.router.navigate(['/check-ai-visa-admin', empid]);
                }
            }
        }
    };
    return CheckvisaComponent;
}());
exports.CheckvisaComponent = CheckvisaComponent;
