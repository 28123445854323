import { Component, OnInit } from '@angular/core';
import { MypassportService } from '../../../services/mypassport.service';
import { ModalService } from '../../../_services/modal.service';
import { UploadFileService } from './../../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CountryService } from '../../../services/country.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../services/alert.service';
import { ProjectService } from '../../../services/project.service';
import { ClientService } from '../../../services/client.service';
import { EmployeeService } from '../../../services/employee.service';
import { DepartmentService } from '../../../services/department.service';
import { DivisionService } from '../../../services/division.service';
import { CompanyService } from '../../../services/company.service';
import { Otherinfo, Department, Division, Clocation } from '../../../models/employment/otherinformation.model';
import { Wemployee } from '../../../models/employment/wemployee.model';
import { Client } from '../../../models/client/client.model';
import { Project } from '../../../models/project/project.model';

@Component({
  selector: 'app-mycurrentemployment',
  templateUrl: './mycurrentemployment.component.html',
  styleUrls: ['./mycurrentemployment.component.css']
})
export class MycurrentemploymentComponent implements OnInit {

  form: FormGroup;
  otherinfoGroup: FormGroup;
  passportlist: any[];
  wemployeelist: any;
  clientlist: any;
  departmentlist: any;
  companylocationlist: any;
  divisionlist: any;
  contracttypelist: any;
  countrylist: any[];
  Userid: any;
  buttoninsert: any;
  filesizeerror: any;
  fileformaterror: any;
  formattedMessage: any;
  messageSuccess:any;
  filename: any;
  empid: any;
  projectlist: any;
  otherDetail: any;
  disableBtn: any;
  terminationDetail: any;
  defaultVal: any = "";

  errMessage: any;

  filterItems = [
    {
      name: 'All Passport Details',
      id: 0,
      selected: true
    },
    {
      name: 'Accepted',
      id: 2,
      selected: false
    },
    {
      name: 'Rejected',
      id: 3,
      selected: false
    },
    {
      name: 'Waiting for review',
      id: 1,
      selected: false
    }
  ]
  submitted: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private data: MypassportService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private activatedRoute: ActivatedRoute,
    private alerts: AlertService,
    private project: ProjectService, 
    private client: ClientService,
    private employeeService: EmployeeService,
    private departmentService: DepartmentService,
    private divisionService: DivisionService,
    private companyService: CompanyService
    ) { }
    
  ngOnInit() {
    this.empid = this.activatedRoute.snapshot.paramMap.get("id");
    this.otherinfoGroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      locationid: ['', Validators.required],
      divisionid: ['', Validators.required],
      departmentid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      projectid: [''],
      clientid: [''],
      reportto: ['', Validators.required],
      isclientcontact: ['', Validators.required]
    });

    this.onChanges();
    this.otherDetail = new Otherinfo();
    this.otherDetail.reportingperson = new Wemployee();
    this.otherDetail.client = new Client();
    this.otherDetail.location = new Clocation();
    this.otherDetail.division = new Division();
    this.otherDetail.department = new Department();
    this.otherDetail.project = new Project();
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
    const data = { 'userid': this.Userid, 'employeeid': this.empid };

    this.employeeService.getOtherInfobyadmin(data).subscribe((data: any) => {
      this.otherDetail = data;
    });

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.employeeService.getTerminationInfobyadmin(data).subscribe((data: any) => {
      // console.log(data);
      this.terminationDetail = data;
    });

    this.employeeService.getlistofworkingemployee().subscribe((employeedata: any) => {
      this.wemployeelist = employeedata;
    });

    
    const sstatustype = { 'status': true, 'id': this.Userid } 
    this.client.getclientlist(sstatustype).subscribe((clientdetails: any) => {
      this.clientlist = clientdetails;
    });

    
    this.departmentService.getdepartmentlistbyadmin(sstatustype).subscribe((data: any) => {
      // console.log(data);
      this.departmentlist = data;
    });

    this.companyService.getemployeecontracttypelist().subscribe((data: any) => {
      this.contracttypelist = data;
    });

    this.companyService.getcompanylocation().subscribe((data: any) => {
      this.companylocationlist = data;
    });

    this.divisionService.getdivisionlistbyadmin(sstatustype).subscribe((data: any) => {
      this.divisionlist = data;
    });

    this.errMessage ='';
  }

  

  getProjectList(clientid: any)
  {
     
    this.project.getprojectlistbyclient({"clientid": clientid, 'id': this.Userid, 'status': true}).subscribe((projectdetails: any) => {    
      this.projectlist = projectdetails;
    });
  }

  onChangeDate(event: any)
  {
    
  }
  
  onChanges(): void {
    this.formattedMessage = '';
    this.otherinfoGroup.valueChanges.subscribe(val => {
      this.formattedMessage = 'Hello, My name is and my email is .I would like to tell you that .';
    });
  }

  get f() { return this.otherinfoGroup.controls; }
  onSubmit(formcurrentemp: any, id: string) {
    // var formcurrentemp = value3.value;
    this.submitted = true;
    if (this.otherinfoGroup.invalid) {
      return;
    }
    this.disableBtn = false;
    formcurrentemp.userid = this.Userid;
    this.employeeService.employeeotherinfoUpdatebyadmin(formcurrentemp).subscribe((data: any) => {

      if(data.susses == true)
      {
        this.messageSuccess = 'Other Information Updated Successfully.'; 
        this.alerts.success(this.messageSuccess, false);
        this.modalService.close(id);

        const data1 = { 'userid': this.Userid, 'employeeid': this.empid };
        this.employeeService.getOtherInfobyadmin(data1).subscribe((data: any) => {
          return this.otherDetail = data;
        });
      }else{
        this.errMessage ='Error. Not authorised.';
      }

    });

   
  }

  onDelete(empid: number) {
    if (confirm("Are you sure you want to delete?")) {
      const data1 = { 'id': empid };
      this.data.deletePassport(data1).subscribe((data: any) => {
        const data1 = { 'userid': this.Userid, 'employeeid': this.empid };
        this.employeeService.getOtherInfobyadmin(data1).subscribe((data: any) => {
          return this.otherDetail = data;
        });
      })
    }
  }

  openModal2(id: string) {
    this.submitted = false;
    this.disableBtn = true;
    this.otherDetail = new Otherinfo();
    this.otherDetail.reportingperson = new Wemployee();
    // this.otherDetail.employee = new Profile();
    this.otherDetail.client = new Client();
    this.otherDetail.location = new Clocation();
    this.otherDetail.division = new Division();
    this.otherDetail.department = new Department();
    this.otherDetail.project = new Project();
    this.buttoninsert = "Submit";
    this.otherDetail.employeeid = this.empid;
    this.otherDetail.id = "0";
    this.otherDetail.locationid = '';
    this.otherDetail.divisionid = '';
    this.otherDetail.departmentid = '';
    this.otherDetail.projectid = '';
    this.otherDetail.clientid = '';
    this.otherDetail.reportto = '';
    this.otherDetail.isclientcontact = '';
    this.modalService.open(id);
  }

  openModal(id: string, data: any) {
    this.disableBtn = true;
    this.otherDetail = new Otherinfo();
    this.otherDetail.reportingperson = new Wemployee();
    this.otherDetail.client = new Client();
    this.otherDetail.location = new Clocation();
    this.otherDetail.division = new Division();
    this.otherDetail.department = new Department();
    this.otherDetail.project = new Project();
    this.buttoninsert = "Update"; this.errMessage ='';
    this.otherDetail = data;
    if(data.isclientcontact == true)
    {
      this.otherDetail.isclientcontact = "1";
    }
    else if(data.isclientcontact == false)
    {
      this.otherDetail.isclientcontact = "0";
    }
    // console.log(this.otherDetail);
    this.modalService.open(id);
  }

  closeModal(id: string) {
    // this.otherDetail = new Otherinfo();
    // this.otherDetail.reportingperson = new Wemployee();
    // this.otherDetail.client = new Client();
    // this.otherDetail.location = new Clocation();
    // this.otherDetail.division = new Division();
    // this.otherDetail.department = new Department();
    // this.otherDetail.project = new Project();
    const data1 = { 'userid': this.Userid, 'employeeid': this.empid };
    this.employeeService.getOtherInfobyadmin(data1).subscribe((data: any) => {
      this.otherDetail = data;
    });
    this.modalService.close(id);
  }

  modalOpen(data: any) {
    this.disableBtn = true;
    this.otherDetail = new Otherinfo();
    this.otherDetail.reportingperson = new Wemployee();
    this.otherDetail.client = new Client();
    this.otherDetail.location = new Clocation();
    this.otherDetail.division = new Division();
    this.otherDetail.department = new Department();
    this.otherDetail.project = new Project();
    this.otherDetail = data;
  }

}
