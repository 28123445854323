<app-header>
</app-header>
<div  class="section-wrapper">
    <div   class="container">
        <div   class="tabs" id="tabs">
        <a [routerLink]="['/ai-dashboard']" class="backlist  mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to AI Dashboard</a>
        <div class="clearfix"></div>
    </div>
    </div>
</div>
<div class="aiverification_section aimain">
    <div class="ai_content_section">
        <div class="ai_content">
            <div class="ai_wizard">
                <app-aiwizard></app-aiwizard>
                <div class="content clearfix">
                    <div class="admin_pro_section aidetail">
                        <div class="cseec">
                            <div class="admin_com_logo">
                                <img src=" ../../../../../assets/img/fe.png ">
                            </div>
                        </div>
                        <div class="albox width100">
                            <div class="aliii">
                                <div class="ai_admin_details width28">
                                    <span class="subheading">Name</span>
                                    <span class="heading">Abhishek Sahay</span>
                                </div>
                                <div class="ai_admin_details width40">
                                    <span class="subheading">Email</span>
                                    <span class="heading">Abhishek.Sahay@103gmail.com</span>
                                </div>
                                <div class="ai_admin_details width22">
                                    <span class="subheading">Phone</span>
                                    <span class="heading">+91 9874 5855 478</span>
                                </div>
                            </div>
                            <div class="aliii">
                                <div class="ai_admin_details width28">
                                    <span class="subheading">NI Number</span>
                                    <span class="heading">YES6589</span>
                                </div>
                                <div class="ai_admin_details width40">
                                    <span class="subheading">Employee ID</span>
                                    <span class="heading">Yes001</span>
                                </div>
                                <div class="ai_admin_details width22">
                                    <span class="subheading">Nationality</span>
                                    <span class="heading badge badge-danger">Indian</span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="btnai">
                            <button type="text" class="btn btn-primary">EMP - YES58485</button>
                            <button type="text" class="btn btn-danger">IN - YES58485</button>
                        </div> -->
                    </div>
                    <div class="white_box_shadow pro_plan_details admin_pro_footer width50">
                        <h4>Visa Detail <span>RJ8057958</span> </h4>
                        <div class="pro_footer_content">
                            <label><i  aria-hidden="true" class="fa fa-user-o"></i> Visa Type Name</label>
                            <p class="ng-star-inserted">Tier 2 General </p>
                        </div>
                        <div class="pro_footer_content">
                            <label><i  aria-hidden="true" class="fa fa-calendar"></i> Visa issue Country</label>
                            <p>UK</p>
                        </div>
                        <div class="pro_footer_content">
                            <label><i aria-hidden="true" class="fa fa-calendar"></i> Start Date</label>
                            <p> Jul 13, 2019</p>
                        </div>
                        <div class="pro_footer_content">
                            <label><i aria-hidden="true" class="fa fa-calendar"></i> Expiry Date</label>
                            <p>Dec 7, 2020</p>
                        </div>
                    </div>
                    <div class="white_box_shadow pro_plan_details admin_pro_footer width50 visaimg rmovepadd">
                        <img src=" ../../../../../assets/img/visa.jpg" class="width100 ">
                        <div class="overlay_bg">
                            <button type="submit" class="btn btn-primary com_btn">Change Visa</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 text-right">
                            <p>Acceptable file type: JPEG/PNG., Max. file size: 3MB</p>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="ai_wizard_btn">
                        <button type="submit" class="btn btn-danger com_btn float-left">Previous</button>
                        <button type="submit" class="btn btn-secondary com_btn float-right">Next</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>