import { Component, OnInit } from '@angular/core';
import { AmlService } from '../../services/aml.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-admincreditsafe',
  templateUrl: './admincreditsafe.component.html',
  styleUrls: ['./admincreditsafe.component.css']
})
export class AdmincreditsafeComponent implements OnInit {
  Userid: any; 
  permission:  any;
  assetperm: any;
  tc:boolean=true;

  constructor( private amldetails: AmlService,
    private router: Router) { }

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    this.Userid = localStorage.getItem('userid');
    // this.tc =false;
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.assetperm = this.permission.m16;

    // this.amldetails.getAuthenticatedetails().subscribe((data: any) => { 

    //   if(data){
    //     var db = {username: data.username, password: data.pwd}

    //     this.amldetails.getAuthenticate(db).subscribe((datatoken: any) => {

         
    //       var dt = new Date();
    //       dt.setMinutes( dt.getMinutes() + 55);
    //       localStorage.setItem('userTokenAML', datatoken.token);
    //       if(dt <= data.updateat){
    //       console.log(dt);
    //       if(datatoken)          
    //       {
    //         var db2 = { tokennumber: datatoken.token}
    //         this.amldetails.updateAuthenticatedetails(db2).subscribe((datatoken3: any) => {

    //         });
    //         this.tc =true;
            
    //       }
    //       else
    //       {
    //         this.tc =false;
    //       }
    //     }else{
    //      // localStorage.setItem('userTokenAML', data.token_number);
    //     }


    //     });




    //   } 
    // });

  }

}
