"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../_services/modal.service");
var division_service_1 = require("../../../services/division.service");
var forms_1 = require("@angular/forms");
var division_model_1 = require("../../../models/setting/division.model");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var SettingdivisionComponent = /** @class */ (function () {
    function SettingdivisionComponent(sanitizer, modalService, divisionService, router, formBuilder) {
        this.sanitizer = sanitizer;
        this.modalService = modalService;
        this.divisionService = divisionService;
        this.router = router;
        this.formBuilder = formBuilder;
        this.divisionDetail = new division_model_1.Division();
        this.options = [];
        this.submitted = false;
    }
    SettingdivisionComponent.prototype.ngOnInit = function () {
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.settingperm = this.permission.m81;
        this.Userid = localStorage.getItem('userid');
        this.divisionDetail = new division_model_1.Division();
        this.divisionformGroup = this.formBuilder.group({
            id: [''],
            typename: ['', forms_1.Validators.required]
        });
        this.AllData();
    };
    SettingdivisionComponent.prototype.AllData = function () {
        var _this = this;
        var sstatustype = { 'status': false, 'id': this.Userid };
        this.divisionService.getdivisionlistbyadmin(sstatustype).subscribe(function (data) {
            _this.data = data;
            _this.record = _this.data.length;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    Object.defineProperty(SettingdivisionComponent.prototype, "f", {
        get: function () { return this.divisionformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    SettingdivisionComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    SettingdivisionComponent.prototype.onSubmit = function (formdivision, id) {
        var _this = this;
        // var formdivision = divisiondata.value;
        this.submitted = true;
        if (this.divisionformGroup.invalid) {
            return;
        }
        this.disableBtn = false;
        formdivision.userid = this.Userid;
        this.divisionService.settingdivisionUpdate(formdivision).subscribe(function (response) {
            _this.AllData();
            _this.modalService.close(id);
        });
    };
    SettingdivisionComponent.prototype.onChangeStatus = function (evt, division) {
        var _this = this;
        var cstatus;
        if (evt.target.checked) {
            cstatus = '1';
        }
        else if (!evt.target.checked) {
            cstatus = '0';
        }
        this.divisionService.divisionUpdateChangeStatus({ 'userid': this.Userid, 'id': division.id, 'cstatus': evt.target.checked }).subscribe(function (data) {
            _this.AllData();
        });
    };
    SettingdivisionComponent.prototype.openModal = function (id, data) {
        this.buttoninsert = "Update";
        this.disableBtn = true;
        this.divisionDetail = data;
        this.divisionformGroup.reset(this.divisionDetail);
        this.modalService.open(id);
    };
    SettingdivisionComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        this.divisionDetail = new division_model_1.Division();
        this.divisionDetail.id = "0";
        this.divisionformGroup.reset(this.divisionDetail);
        this.modalService.open(id);
    };
    SettingdivisionComponent.prototype.closeModal = function (id) {
        this.AllData();
        this.modalService.close(id);
    };
    return SettingdivisionComponent;
}());
exports.SettingdivisionComponent = SettingdivisionComponent;
