
<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper" (scroll)="onWindowScroll($event)"> 
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__wcontent">
                    <div class="tabs2__content__item active-tab" id="jan">
                        <div class="content">
                            <!-- <ul class="tabs__head profile_he" id="employeetopmenu">
                                <li><a (click)='onmousetopbottom("overview",1)'
                                        [className]="tab==1 ? 'active-link' : 'inactive'">Overview</a></li>
                                <li><a (click)='onmousetopbottom("overemployment",2)'
                                        [className]="tab==2 ? 'active-link' : 'inactive'">Employment</a></li>
                                <li><a (click)='onmousetopbottom("overaddress",3)'
                                        [className]="tab==3 ? 'active-link' : 'inactive'">Address </a></li>
                                <li><a (click)='onmousetopbottom("overcompensation",4)'
                                        [className]="tab==4 ? 'active-link' : 'inactive'">Compensation </a></li>
                                <li><a (click)='onmousetopbottom("overcontactinfo",5)'
                                        [className]="tab==5 ? 'active-link' : 'inactive'">Contact </a></li>
                                <li><a (click)='onmousetopbottom("overcos",6)' *ngIf="empltype == 'false'"
                                        [className]="tab==6 ? 'active-link' : 'inactive'">COS</a></li>
                                <li><a (click)='onmousetopbottom("overvisa",7)' *ngIf="empltype == 'false'"
                                        [className]="tab==7 ? 'active-link' : 'inactive'">Visa</a></li>
                                <li><a (click)='onmousetopbottom("overpassport",8)' *ngIf="empltype == 'false'"
                                        [className]="tab==8 ? 'active-link' : 'inactive'">Passport</a></li>
                                <li><a (click)='onmousetopbottom("overidproof",9)' *ngIf="empltype == 'false'"
                                        [className]="tab==9 ? 'active-link' : 'inactive'">Address Proof</a></li>
                            </ul> -->
                            <div class="user_profle_section">
                                <div class="profile_box">
                                    <div id="overview">
                                        <div class="overviewsshow">
                                            <div class="cseec">
                                                <div class="admin_com_logo">
                                                    <div class="picture_chnge">
                                                        <!-- <i class="fa fa-camera"></i> -->
                                                        <!-- <input type="file" accept="image/*" (change)="selectFile($event)" style="display:none;" #file> -->
                                                        <input type="file" accept="image/*" (change)="selectFile($event)"
                                                            style="display:none;" #file>
                                                        <a target="_blank" (click)="file.click()" class="cameraicon"><i
                                                                class="fa fa-camera"></i></a>
                                                    </div>
                                                    <!-- <img src="../../../../../assets/img/user_pic_emp.png"> -->
                                                    <img alt="" [src]="overviewData.imagename"
                                                        *ngIf="overviewData.imagename !='' && overviewData.imagename !=null" />
                                                    <img alt="" src="../../../assets/img/no-image-icon-hi.png"
                                                        *ngIf="overviewData.imagename =='' || overviewData.imagename == null" />
                                                </div>
                                            </div>
                                            <div class="overview_details">
                                                <div class="headdd width100">
                                                    <h3 class="card-profile-name d-flex justify-content-center text-left">{{ overviewData.fname}}
                                                        {{ overviewData.mname }} {{overviewData.lname}}&nbsp;
                                                        <span
                                                            *ngIf="overviewData.employeeid != null">({{overviewData.employeeid}})</span>&nbsp;
                                                        <i class="lnr lnr-pencil"
                                                            (click)="openModal('custom-overview-1',overviewData)"
                                                            aria-hidden="true"></i>
                                                        </h3>
                                                        <div class="buttonprofile mt-2 mb-2"
                                                        *ngIf="paddress.addressid1 == '' || paddress.addressid1 == null">
                                                        <a (click)="openModal2('custom-overview-2',paddress)"
                                                            class="addscreensuccess"> + Permanent Address</a>
                                                        </div>
                                                </div>
                                                
                                                <div class="alignbbb">
                                                   
                                                </div>
                                                <hr class="mt-0" />
                                                <div class="profile_dtailsshow mb-3">
                                                    <div class="detailsshow">
                                                        <span>Email</span>
                                                        <p>{{ overviewData.emailid }}</p>
                                                    </div>
                                                    <div class="detailsshow ">
                                                        <span>Mobile</span>
                                                        <p> {{ overviewData.phonenumber }}</p>
                                                    </div>
                                                    <div class="detailsshow ">
                                                        <span>Date of Birth</span>
                                                        <p>{{ overviewData.dateofbirth | date: 'dd MMM yyyy' }}</p>
                                                    </div>
                                                    <div class="detailsshow">
                                                        <span>Gender</span>
                                                        <p>
                                                            <ng-container *ngIf="overviewData.gender==true">
                                                                Male
                                                            </ng-container>
                                                            <ng-container *ngIf="overviewData.gender==false">
                                                                Female
                                                            </ng-container>
                                                        </p>
                                                    </div>
                                                    <div class="detailsshow">
                                                        <span>Hire Date</span>
                                                        <p>{{ overviewData.hiredate | date: 'dd MMM yyyy'}} </p>
                                                    </div>
                                                    <div class="detailsshow">
                                                        <span>Place of Birth</span>
                                                        <p>{{ overviewData.placeofbirth }}</p>
                                                    </div>
                                                </div>
                                                <div class="profile_dtailsshow">
                                                    <div class="detailsshow">
                                                        <span>Marital Status</span>
                                                        <p>
                                                            <ng-container *ngIf="overviewData.maritalstatus==true">
                                                                Married
                                                            </ng-container>
                                                            <ng-container *ngIf="overviewData.maritalstatus==false">
                                                                Single
                                                            </ng-container>
                                                        </p>
                                                    </div>
                                                    <div class="detailsshow ">
                                                        <span>Country of Residence</span>
                                                        <p>{{ overviewData?.residence?.countryname}}</p>
                                                    </div>
                                                    <div class="detailsshow">
                                                        <span>Country of Birth</span>
                                                        <p>{{ overviewData?.countryofbirth?.countryname }}</p>
                                                    </div>
                                                    <div class="detailsshow">
                                                        <span>Nationality</span>
                                                        <p class="badge badge-danger">
                                                            {{ overviewData?.nationality?.countryname }}</p>
                                                    </div>
                                                    <div class="detailsshow">
                                                        <span>NI Number</span>
                                                        <p>{{ overviewData.ninumber }} </p>
                                                    </div>
                                                </div>
                                                <div class="alignbbb  mt-2">
                                                    <h3 class="card-profile-name text-left">Permanent Address <i
                                                            class="lnr lnr-pencil"
                                                            (click)="openModal2('custom-overview-2',paddress)"
                                                            aria-hidden="true"></i></h3>
                                                </div>
                                                <hr class="mt-0" />
                                                <div class="profile_dtailsshow">
                                                    <div class="detailsshow width100">
                                                        <p><i class="lnr lnr-map-marker"></i>{{ paddress.addressid1 }},
                                                            {{ paddress.addressid2 }}, {{ paddress.cityname }},
                                                            {{ paddress.statename }}, {{ paddress?.country?.countryname }} -
                                                            {{ paddress.pincode }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                                <div class="details_box">
                                    <div class="overviewsshow pb-0 pt-0 pr-0 pl-0">
                                        <mat-tab-group [@.disabled]="true">
                                            <!-- <mat-tab label="Overview">
                                                <ng-template matTabContent>
                                                    <app-applicantdocument></app-applicantdocument>
                                                </ng-template>
                                            </mat-tab> -->
                                            <mat-tab label="Employment">
                                                <ng-template matTabContent>
                                                    <app-myemployment></app-myemployment>
                                                </ng-template>
                                            </mat-tab>
                                            <mat-tab label="Address">
                                                <ng-template matTabContent>
                                                    <app-myaddress></app-myaddress>
                                                </ng-template>
                                            </mat-tab>
                                            <mat-tab label="Compensation">
                                                <ng-template matTabContent>
                                                    <app-mycompensation></app-mycompensation>
                                                </ng-template>
                                            </mat-tab>
                                            <mat-tab label="Contact">
                                                <ng-template matTabContent>
                                                    <app-mycontactinfo></app-mycontactinfo>
                                                </ng-template>
                                            </mat-tab>
                                            <mat-tab label="COS">
                                                <ng-template matTabContent>
                                                    <app-mycos></app-mycos>
                                                </ng-template>
                                            </mat-tab>
                                            <mat-tab label="Visa">
                                                <ng-template matTabContent>
                                                    <app-myvisa></app-myvisa>
                                                </ng-template>
                                            </mat-tab>
                                            <mat-tab label="Passport">
                                                <ng-template matTabContent>
                                                    <app-mypassport></app-mypassport>
                                                </ng-template>
                                            </mat-tab>
                                            <mat-tab label="Address Proof">
                                                <ng-template matTabContent>
                                                    <app-myidproof></app-myidproof>
                                                </ng-template>
                                            </mat-tab>
                                        </mat-tab-group>
                                        <!-- <ul class="tabs__head profile_he" id="employeetopmenu">
                                            <li><a (click)='onmousetopbottom("overview",1)'
                                                    [className]="tab==1 ? 'active-link' : 'inactive'">Overview</a></li>
                                            <li><a (click)='onmousetopbottom("overemployment",2)'
                                                    [className]="tab==2 ? 'active-link' : 'inactive'">Employment</a></li>
                                            <li><a (click)='onmousetopbottom("overaddress",3)'
                                                    [className]="tab==3 ? 'active-link' : 'inactive'">Address </a></li>
                                            <li><a (click)='onmousetopbottom("overcompensation",4)'
                                                    [className]="tab==4 ? 'active-link' : 'inactive'">Compensation </a></li>
                                            <li><a (click)='onmousetopbottom("overcontactinfo",5)'
                                                    [className]="tab==5 ? 'active-link' : 'inactive'">Contact </a></li>
                                            <li><a (click)='onmousetopbottom("overcos",6)' *ngIf="empltype == 'false'"
                                                    [className]="tab==6 ? 'active-link' : 'inactive'">COS</a></li>
                                            <li><a (click)='onmousetopbottom("overvisa",7)' *ngIf="empltype == 'false'"
                                                    [className]="tab==7 ? 'active-link' : 'inactive'">Visa</a></li>
                                            <li><a (click)='onmousetopbottom("overpassport",8)' *ngIf="empltype == 'false'"
                                                    [className]="tab==8 ? 'active-link' : 'inactive'">Passport</a></li>
                                            <li><a (click)='onmousetopbottom("overidproof",9)' *ngIf="empltype == 'false'"
                                                    [className]="tab==9 ? 'active-link' : 'inactive'">Address Proof</a></li>
                                        </ul> -->
                                    </div>
                                    <!-- <div id="overemployment">
                                      
                                    </div>
                                    <div id="overaddress">
                                      
                                    </div>
                                    <div id="overcompensation">
                                      
                                    </div>
                                    <div id="overcontactinfo">
                                       
                                    </div>
                                    <div id="overcos">
                                      
                                    </div>
                                    <div id="overvisa">
                                       
                                    </div>
                                    <div id="overpassport">
                                      
                                    </div>
                                    <div id="overidproof">
                                        
                                    </div> -->
                                </div>
                            </div>
                            

                           
                            <div style="height: 50px;">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<jw-modal id="custom-overview-1" class="modal" role="dialog">
    <form [formGroup]="OverForm" #overviewForm="ngForm" class="s12 white"
        (ngSubmit)="onOverviewSubmit(overviewForm.value,'custom-overview-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <!-- <ngx-intl-tel-input ></ngx-intl-tel-input> -->
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id"
                        [(ngModel)]="overviewFormData.employeemasterid">
                    <!-- <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" ngModel="{{overviewData.employeemasterid}}">  -->
                    <h5 class="modal-title" id="popupLabel">Personal Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                        (click)="closeModal('custom-overview-1');">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>First Name*</label>
                                <input type="fname" formControlName="fname" name="fname" id="fname" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && u.fname.errors }"
                                    [ngModel]="overviewFormData.fname" [maxlength]="30">
                                <div *ngIf="submitted && u.fname.errors" class="invalid-feedback">
                                    <div *ngIf="u.fname.errors.required">Please enter first name</div>
                                    <div *ngIf="u.fname.errors.minlength">First Name must be at least 3 characters long</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Middle Name</label>
                                <input type="mname" formControlName="mname" name="mname" id="mname" class="form-control"
                                    [ngModel]="overviewFormData.mname" [maxlength]="30">
                                <!-- <div *ngIf="submitted && u.mname.errors" class="invalid-feedback">
                  <div *ngIf="u.mname.errors.required">Please enter the middle name</div>
                </div> -->
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Last Name*</label>
                                <input type="lname" formControlName="lname" name="lname" id="lname"
                                    class="form-control input-lg"
                                    [ngClass]="{ 'is-invalid': submitted && u.lname.errors }"
                                    [ngModel]="overviewFormData.lname" [maxlength]="30">
                                <div *ngIf="submitted && u.lname.errors" class="invalid-feedback">
                                    <div *ngIf="u.lname.errors.required">Please enter last name</div>
                                    <div *ngIf="u.fname.errors.minlength">First Name must be at least 3 characters long</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>Date of Birth*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [min]="mindob" [max]="maxdob" [matDatepicker]="picker2"
                                        id="dateofbirth" [ngClass]="{ 'is-invalid': submitted && u.dateofbirth.errors }"
                                        name="dateofbirth" formControlName="dateofbirth"
                                        class="form-control noborder-invalid" (keydown)="onKeydown($event)"
                                        [ngModel]="overviewFormData.dateofbirth">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && u.dateofbirth.errors" class="invalid-feedback">
                                    <div *ngIf="u.dateofbirth.errors.required">Please choose date of birth</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Hire Date </label>
                                <input type="text" formControlName="hiredate" name="hiredate" id="hiredate"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && u.ninumber.errors }"
                                    [ngModel]="overviewFormData.hiredate | date: 'dd MMM yyyy'" disabled>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>NI Number</label>
                                <input type="text" formControlName="ninumber" class="" name="ninumber" id="ninumber"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && u.ninumber.errors }"
                                    disabled [(ngModel)]="overviewFormData.ninumber">
                                <div *ngIf="submitted && u.ninumber.errors" class="invalid-feedback">
                                    <div *ngIf="u.ninumber.errors.required">Please enter NI number</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>Gender</label>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-lg-6 col-6">
                                            <input type="radio" name="gender" formControlName="gender"
                                                [ngModel]='overviewFormData.gender' value="1"/> Male
                                        </div>
                                        <div class="col-lg-6 col-6">
                                            <input type="radio" name="gender" formControlName="gender"
                                                [ngModel]='overviewFormData.gender' value="0"/> Female
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Marital Status</label>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-lg-6 col-6">
                                            <input type="radio" name="maritalstatus" formControlName="maritalstatus"
                                                [ngModel]='overviewFormData.maritalstatus' value="1"> Married
                                        </div>
                                        <div class="col-lg-6 col-6">
                                            <input type="radio" name="maritalstatus" formControlName="maritalstatus"
                                                [ngModel]='overviewFormData.maritalstatus' value="0"> Single
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Employee ID</label>
                                <input type="text" formControlName="employeeid" name="employeeid" id="employeeid"
                                    [maxlength]="30" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && u.employeeid.errors }"
                                    [(ngModel)]="overviewFormData.employeeid" disabled>
                                <div *ngIf="submitted && u.employeeid.errors" class="invalid-feedback">
                                    <div *ngIf="u.employeeid.errors.required">Please enter employee id</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Nationality* </label>
                                <select style="width:100%;" name="nationalityid" formControlName="nationalityid"
                                    class="form-control input-lg"
                                    [ngClass]="{ 'is-invalid': submitted && u.nationalityid.errors }" id="nationalityid"
                                    [ngModel]="overviewFormData.nationalityid">
                                    <option [value]="defaultValue">Select Country</option>
                                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">
                                        {{ ad3.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && u.nationalityid.errors" class="invalid-feedback">
                                    <div *ngIf="u.nationalityid.errors.required">Please select nationality</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Country of Residence*</label>
                                <select style="width:100%;" name="residenceid" formControlName="residenceid"
                                    id="residenceid" class="form-control input-lg"
                                    [ngClass]="{ 'is-invalid': submitted && u.residenceid.errors }"
                                    [ngModel]="overviewFormData.residenceid">
                                    <option [value]="defaultValue">Select Country</option>
                                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">
                                        {{ ad3.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && u.residenceid.errors" class="invalid-feedback">
                                    <div *ngIf="u.residenceid.errors.required">Please select country of residence</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Country of Birth*</label>
                                <select style="width:100%;" name="countryofbirthid" formControlName="countryofbirthid"
                                    [maxlength]="30" class="form-control input-lg"
                                    [ngClass]="{ 'is-invalid': submitted && u.countryofbirthid.errors }"
                                    (change)="onSelectCountry($event.target.value)"
                                    [ngModel]="overviewFormData.countryofbirthid" id="countryofbirthid">
                                    <option [value]="defaultValue">Select Country</option>
                                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">
                                        {{ ad3.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && u.countryofbirthid.errors" class="invalid-feedback">
                                    <div *ngIf="u.countryofbirthid.errors.required">Please select country of birth</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label> Place of Birth*</label>
                                <input type="placeofbirth" formControlName="placeofbirth" id="placeofbirth"
                                    class="form-control" [maxlength]="30"
                                    [ngClass]="{ 'is-invalid': submitted && u.placeofbirth.errors }" name="placeofbirth"
                                    [ngModel]="overviewFormData.placeofbirth">
                                <div *ngIf="submitted && u.placeofbirth.errors" class="invalid-feedback">
                                    <div *ngIf="u.placeofbirth.errors.required">Please enter place of birth</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group modal-footer">
                    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Update" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel"
                        (click)="closeModal('custom-overview-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>

<jw-modal id="custom-overview-2" class="modal" role="dialog">
    <form [formGroup]="permanetForm" #permanentForm="ngForm" class="s12 white"
        (ngSubmit)="onSubmit2(permanentForm.value,'custom-overview-2')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id"
                        ngModel="{{paddress.id}}" />
                    <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid"
                        id="employeeid" ngModel="{{paddress.employeeid}}">
                    <h5 class="modal-title" id="popupLabel">Permanent Address Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                        (click)="closeModal('custom-overview-2');">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <!-- <div class="row">
              <div class="form-group col-lg-6">
                <label>Mobile Number *</label>
                <international-phone-number formControlName="mobilenumber" class="form-control phonecontrol" [ngClass]="{ 'is-invalid': submitted && h.mobilenumber.errors }" [(ngModel)]="paddress.mobilenumber" placeholder="Enter mobile number"
                  (input)="validatenumber($event.target.value,'mobile')" [maxlength]="20" 
                  [required]="true" #mobilenumber name="mobilenumber"></international-phone-number>
                  <div *ngIf="submitted && h.mobilenumber.errors" class="invalid-feedback">
                    <div *ngIf="h.mobilenumber.errors.required">Please Enter Mobile Number</div>
                  </div>
                  
                <span *ngIf="mobilecheck==false" style="color:red">Invalid format</span>
                <span *ngIf="mobilecheck==true" style="color:green">Valid format</span>
  
              </div>
              <div class="form-group col-lg-6">
                <label>Phone Number </label>
                <international-phone-number formControlName="phonenumber" class="form-control phonecontrol" [ngClass]="{ 'is-invalid': submitted && h.phonenumber.errors }" [(ngModel)]="paddress.phonenumber"  placeholder="Enter phone number"
                  (input)="validatenumber($event.target.value,'phone')" [maxlength]="20" 
                   #phonenumber name="phonenumber"></international-phone-number>
                  <div *ngIf="submitted && h.phonenumber.errors" class="invalid-feedback">
                    <div *ngIf="h.phonenumber.errors.required">Please Enter Phone Number</div>
                  </div>
                <span *ngIf="phonecheck==false" style="color:red">Invalid format</span>
                <span *ngIf="phonecheck==true" style="color:green">Valid format</span>
                
              </div>
            </div> -->

                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Postcode*</label>
                                <input type="pincode" formControlName="pincode" class="" id="pincode" name="pincode"
                                    class="form-control pincode"
                                    [ngClass]="{ 'is-invalid': submitted && h.pincode.errors }"
                                    ngModel='{{ paddress.pincode }}' (input)="onSearchChange($event.target.value)"
                                    value='{{ paddress.pincode }}' matInput [matAutocomplete]="auto">
                                <div *ngIf="submitted && h.pincode.errors" class="invalid-feedback">
                                    <div *ngIf="h.pincode.errors.required">Please enter postcode</div>
                                </div>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                    <mat-option *ngFor="let option of options" [value]="option"
                                        (onSelectionChange)="updatecode($event, option)">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Address Line 1*</label>
                                <input type="addressid1" formControlName="addressid1" class="" name="addressid1"
                                    id="addressid1" [ngClass]="{ 'is-invalid': submitted && h.addressid1.errors }"
                                    class="form-control" ngModel="{{ paddress.addressid1 }}"
                                    value="{{ paddress.addressid1 }}" [maxlength]="60">
                                <div *ngIf="submitted && h.addressid1.errors" class="invalid-feedback">
                                    <div *ngIf="h.addressid1.errors.required">Please enter address</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Address Line 2</label>
                                <input type="addressid2" formControlName="addressid2" class="" name="addressid2"
                                    id="addressid2" class="form-control" ngModel="{{ paddress.addressid2 }}"
                                    value="{{ paddress.addressid2 }}" [maxlength]="60">
                            </div>
                            <div class="form-group col-lg-6">
                                <label>City*</label>
                                <input type="cityname" formControlName="cityname" class="" name="cityname" id="cityname"
                                    [ngClass]="{ 'is-invalid': submitted && h.cityname.errors }" class="form-control"
                                    ngModel='{{ paddress.cityname }}' value='{{ paddress.cityname}}' [maxlength]="60">
                                <div *ngIf="submitted && h.cityname.errors" class="invalid-feedback">
                                    <div *ngIf="h.cityname.errors.required">Please enter city</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>County Name*</label>
                                <input type="cityname" formControlName="statename" class="" name="statename"
                                    id="statename" [ngClass]="{ 'is-invalid': submitted && h.statename.errors }"
                                    class="form-control" ngModel='{{ paddress.statename }}'
                                    value='{{ paddress.statename}}' [maxlength]="60" />
                                <div *ngIf="submitted && h.statename.errors" class="invalid-feedback">
                                    <div *ngIf="h.statename.errors.required">Please enter county</div>
                                    <!-- <div *ngIf="h.stateid.errors.required">state is required</div> -->
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Country*</label>
                                <select style="width:100%;" formControlName="countryid" name="countryid"
                                    class="form-control input-lg" id="countryid"
                                    [ngClass]="{ 'is-invalid': submitted && h.countryid.errors }"
                                    (change)="onSelectCountry($event.target.value)" ngModel="{{ paddress.countryid }}">
                                    <option value="">Select Country</option>
                                    <option *ngFor="let ad3 of countrylist" value="{{ ad3.countryid}}"
                                        [selected]="ad3.countryid == paddress.countryid">{{
                    ad3.countryname }}</option>
                                </select>
                                <div *ngIf="submitted && h.countryid.errors" class="invalid-feedback">
                                    <div *ngIf="h.countryid.errors.required">Please select country</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel"
                        (click)="closeModal('custom-overview-2');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>