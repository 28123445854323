"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var forms_1 = require("@angular/forms");
var employee_model_1 = require("../../../models/employee.model");
var country_service_1 = require("../../../services/country.service");
var employee_service_1 = require("../../../services/employee.service");
var employeement_service_1 = require("../../../services/employeement.service");
var job_service_1 = require("../../../services/job.service");
var modal_service_1 = require("../../../_services/modal.service");
var project_service_1 = require("../../../services/project.service");
var expense_service_1 = require("../../../services/expense.service");
var excel_service_1 = require("../../../services/excel.service");
var app_global_1 = require("../../../app.global");
var date_fns_1 = require("date-fns");
var router_1 = require("@angular/router");
var ViewexpensereportComponent = /** @class */ (function () {
    function ViewexpensereportComponent(excelService, formBuilder, project, expense, country, jobser, employee, employeementreport, datePipe, route, modalService) {
        var _this = this;
        this.excelService = excelService;
        this.formBuilder = formBuilder;
        this.project = project;
        this.expense = expense;
        this.country = country;
        this.jobser = jobser;
        this.employee = employee;
        this.employeementreport = employeementreport;
        this.datePipe = datePipe;
        this.route = route;
        this.modalService = modalService;
        this.employeeDetail = new employee_model_1.Employee();
        this.exceldata = [];
        route.params.subscribe(function (params) {
            if (params['id']) {
                _this.empid = params['id'];
            }
        });
        route.queryParams.subscribe(function (params) {
            _this.employeemonth = params['month'];
            _this.employeeyear = params['year'];
            _this.hstatus = params['status'];
            _this.exptype = params['type'];
        });
    }
    ViewexpensereportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.employeemonth = date_fns_1.getMonth(new Date()) + 1;
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        this.employeeyear = date_fns_1.getYear(new Date());
        this.graphdisplay = "block";
        this.listdisplay = "none";
        this.EmployeeForm = this.formBuilder.group({
            emailid: [''],
            phonenumber: [''],
            ninumber: [''],
            minstartdate: [''],
            maxstartdate: [''],
            minenddate: [''],
            maxenddate: [''],
            expensetypeid: [''],
            minamount: [''],
            maxamount: [''],
            projectid: [''],
            fname: [''],
            hstatus: [''],
            searctext: ['']
        });
        // var values3 = {};
        // this.expense.getexpenseList(values3).subscribe((expenseDetails: any) => {
        //   this.data = expenseDetails;
        // });
        this.getexpensereport();
        this.Userid = localStorage.getItem('userid');
        var sstatustype = { 'status': true, 'id': this.Userid };
        this.project.getprojectlist(sstatustype).subscribe(function (projectdetails) {
            _this.projectlist = projectdetails;
        });
        var value3 = "{}";
        this.employee.getemployeelist(value3).subscribe(function (listdetails) {
            _this.employeelist = listdetails;
        });
        this.expense.getexpenseType(sstatustype).subscribe(function (expensedetails) {
            _this.expenselist = expensedetails;
        });
    };
    ViewexpensereportComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    ViewexpensereportComponent.prototype.showcondition = function (type) {
        if (type == 'list') {
            this.graphdisplay = "none";
            this.listdisplay = "block";
        }
        else {
            this.listdisplay = "none";
            this.graphdisplay = "block";
        }
    };
    ViewexpensereportComponent.prototype.exportAsXLSX = function () {
        var _this = this;
        this.exceldata = [];
        this.data.forEach(function (obj) {
            if (obj.paidby == '1') {
                var paidby = "Company Paid";
            }
            else if (obj.paidby == '2') {
                paidby = "Client Paid";
            }
            else if (obj.paidby == '3') {
                paidby = "Employee Paid";
            }
            var typename = "";
            if (obj.expensetype) {
                typename = obj.expensetype.typename;
            }
            _this.employeeexpensedata = {
                "Name": obj.employee.fname + " " + obj.employee.lname,
                "Project": obj.project.projectname,
                "Expense Type": typename,
                "Paid By": paidby,
                "Date": _this.datePipe.transform(new Date(obj.startdate), "dd MMM yyyy"),
                "Currency Type": obj.currency,
                "Amount": obj.amountpaid,
                "Location": obj.locationname
            };
            _this.exceldata.push(_this.employeeexpensedata);
        });
        this.excelService.exportAsExcelFile(this.exceldata, 'sample');
    };
    ViewexpensereportComponent.prototype.onSubmit = function (value3) {
    };
    ViewexpensereportComponent.prototype.getexpensereport = function () {
        var _this = this;
        this.expense.getexpenseList({ 'year': this.employeeyear, 'month': this.employeemonth, 'employeeid': this.empid, 'hstatus': this.hstatus, 'expensetypeid': this.exptype }).subscribe(function (expenseDetails) {
            _this.record = expenseDetails.length;
            _this.data = expenseDetails;
            _this.record = _this.data.length;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    ViewexpensereportComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    ViewexpensereportComponent.prototype.openModalnote = function (data, id) {
        this.notedetails = data;
        this.modalService.open(id);
    };
    ViewexpensereportComponent.prototype.changeEmployee = function (id) {
        this.empid = id;
        this.getexpensereport();
    };
    ViewexpensereportComponent.prototype.changeEmployeeMonth = function (month) {
        this.employeemonth = month;
        this.getexpensereport();
    };
    ViewexpensereportComponent.prototype.changeEmployeeYear = function (year) {
        this.employeeyear = year;
        this.getexpensereport();
    };
    ViewexpensereportComponent.prototype.changeEmployeestatus = function (hstatus) {
        this.hstatus = hstatus;
        this.getexpensereport();
    };
    ViewexpensereportComponent.prototype.changeExpenseType = function (exptype) {
        this.exptype = exptype;
        this.getexpensereport();
    };
    return ViewexpensereportComponent;
}());
exports.ViewexpensereportComponent = ViewexpensereportComponent;
