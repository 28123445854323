"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var payment_service_1 = require("../../services/payment.service");
var UpgradepaymentsuccessComponent = /** @class */ (function () {
    function UpgradepaymentsuccessComponent(router, activatedRoute, paymentService) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.paymentService = paymentService;
        this.ctype = '';
    }
    UpgradepaymentsuccessComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.planid = this.activatedRoute.snapshot.paramMap.get("planid");
        this.ctype = this.activatedRoute.snapshot.paramMap.get("id");
        var data = { 'token': this.ctype, planid: this.planid };
        this.paymentService.upgradeuserpaymentByCustomer(data).subscribe(function (data) {
            if (data.success == true) {
                localStorage.removeItem('ctype');
                localStorage.setItem('ctype', '1');
                localStorage.setItem('isverify', "1");
                _this.router.navigate(['/check-dashboard']);
            }
            else {
                localStorage.setItem('isverify', "1");
                _this.router.navigate(['/check-dashboard']);
            }
        });
        // setTimeout(() => {
        //   this.ctype = localStorage.getItem('ctype');      
        //   if (this.ctype == '0' ) 
        //   {
        //     this.router.navigate(['choose-plan']);
        //   }
        //  else
        //  {
        //   this.router.navigate(['login']);
        //  }
        //   // this.router.navigate(['/dashboard']);
        // }, 9000);
        //5s
    };
    return UpgradepaymentsuccessComponent;
}());
exports.UpgradepaymentsuccessComponent = UpgradepaymentsuccessComponent;
