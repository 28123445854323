// import { HIGH_CONTRAST_MODE_ACTIVE_CSS_CLASS } from '@angular/cdk/a11y/high-contrast-mode/high-contrast-mode-detector';
import { Component, OnInit } from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators, FormArray} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import { CompanyService } from '../../services/company.service';
import { ReferenceService } from '../../services/reference.service';
import { HelperService } from '../../services/helper.service';

import { ChecksystemService } from '../../services/checksystem.service';

@Component({
  selector: 'app-referenceresponse',
  templateUrl: './referenceresponse.component.html',
  styleUrls: ['./referenceresponse.component.css']
})
export class ReferenceresponseComponent implements OnInit {

  token_expired:any = false;
  addconfirmbutton:string;
  refereesFormGroup: FormGroup;
  refereesresponseFormGroup: FormGroup;
  getdesignations:any;
  // items: FormArray;
  refereefilleddetailcount:number;
  intrefereefilleddetailcount:number;
  createrefereerequest:any;
  questionTemplatesdetails:any;
  referencerequestinfo:any;
  referencecompanyinfo:any;
  refereedetailinfo:any;
  token:any;
  rd:string;
  cd:string;
  ud:string;
  logo:any;
  referencing:number;
  file:any;
  submit: any = false;
  
  btndisable: boolean =false;
  submit2: any = false;
  formsubmitted: any = false;
  // blob:BlobPart;
  constructor(
    private formBuilder: FormBuilder, 
    private activatedRoute:ActivatedRoute, 
    private referencingmoduleService:ReferenceService,
    private company :CompanyService,
    private check: ChecksystemService,
    private helperService:HelperService
  ) { 
    this.steps = [
      {name: 'Information', icon: 'fa-info-circle', active: true, valid: false, hasError:false },
      {name: 'About You', icon: 'fa-user-o', active: false, valid: false, hasError:false },
      {name: 'About Reference', icon: 'fa-user-o', active: false, valid: false, hasError:false }
    ]
  }

  ngOnInit() {
    this.check.getIPAddress().subscribe((data: any) => { 
      
      sessionStorage.setItem('LOCAL_IP', data.ip);

    });
    // this.helperService.determineLocalIp();
    this.token=this.activatedRoute.snapshot.queryParams['token'];
    // this.rd=this.activatedRoute.snapshot.queryParams['rd'];
    this.cd=this.activatedRoute.snapshot.queryParams['cd'];
    // this.ud=this.activatedRoute.snapshot.queryParams['ud'];
    this.referencing = 0;
    this.addconfirmbutton='Add';
    // this.refereesFormGroup = this.formBuilder.group({
    //     referencerequestid: '',
    //     token: '',
    //     companyid: '',
    //     userid: '',
    //     rederencerequest: '',
    //     items: this.formBuilder.array([ this.createItem() ])
    //   });
      this.refereesFormGroup = this.formBuilder.group({     
        refereedesignationcheck: ['', [Validators.required]],
        refereephonecheck: ['', [Validators.required]],
        candidatedesignationcheck: ['', [Validators.required]],
        modifiedon:new Date(),
        refereemac:localStorage.getItem('addrefereeseq'),
        refereeip: sessionStorage.getItem('LOCAL_IP')
      });      
      // console.log(this.refereesFormGroup);

      this.refereesresponseFormGroup = this.formBuilder.group({  
        companyid: '',
        companyname:this.rd,
        candidatename:this.rd,
        candidateemail:this.rd,
        refereename:this.rd,
        refereeemail:this.rd,
        referencerequestid: '',
        refereeid:'',
        refereenamecheck: ['', Validators.required],
        companynamecheck: ['', Validators.required],
        candidatedesignationcheck: ['', Validators.required],
        jobstartdatecheck: ['', Validators.required],
        jobenddatecheck: [''],
        currentjob: '',
        refereesresponsedetails: this.formBuilder.array([])  
      });  
      // console.log("first");
      this.loadData();
      this.intrefereefilleddetailcount=1;
      this.createrefereerequest=[];
      // console.log("second");
  }

  addrefereesresponsedetailFormGroup(questiontemplate): FormGroup {  
    return this.formBuilder.group({       
      question:questiontemplate.question,
      questiontype:questiontemplate.questiontype,
      questionoptions:"Select,"+questiontemplate.questionoptions,
      required:questiontemplate.required,
      response:[null, questiontemplate.required == 'Yes' ? Validators.required : null]
    });  
  } 

  //referencerequestinfo:any;
  //referencecompanyinfo:any;
  loadData(){
    let datanew = { 'token': this.token ,'cd': this.cd}
   
    this.referencingmoduleService.getdesignations().subscribe((response: any)=>{
      // console.log(response);
      this.getdesignations = response;     
    });
    
    this.referencingmoduleService.getreferee(datanew).subscribe((response: any)=>{
      console.log(response);
      if(!response.success){
        this.token_expired = true;
      } else{
        let companyinfo = response.company;

        if (companyinfo.logoname == "" || companyinfo.logoname == null) 
        {
          this.logo = "assets/img/logo.png";
        } 
        else
        {
          this.logo =companyinfo.logoname;
        } 

        this.referencerequestinfo = response.referencerequest;
        this.questionTemplatesdetails = response.questiontemplate;    ;
        this.addquestiontoformgroup(this.questionTemplatesdetails); 
        this.referencecompanyinfo = response.company;
        this.refereefilleddetailcount=1;
        this.addconfirmbutton='Submit';
        // this.getdesignations = response.designations;     
        this.refereedetailinfo = response.referee;
        
        if(this.refereedetailinfo.jobstartdate){
          this.refereedetailinfo.jobstartdate =      this.refereedetailinfo.jobstartdate.split('T')[0];
        }
        if(this.refereedetailinfo.jobenddate){
          this.refereedetailinfo.jobenddate =      this.refereedetailinfo.jobenddate.split('T')[0];
        }

        this.refereesresponseFormGroup.get("refereenamecheck").patchValue(this.refereedetailinfo.refereename);
        this.refereesresponseFormGroup.get("companynamecheck").patchValue(this.refereedetailinfo.refereecompanyname);
        this.refereesresponseFormGroup.get("candidatedesignationcheck").patchValue(this.refereedetailinfo.candidatejobtitle);
        this.refereesresponseFormGroup.get("jobstartdatecheck").patchValue(this.refereedetailinfo.jobstartdate);
        this.refereesresponseFormGroup.get("jobenddatecheck").patchValue(this.refereedetailinfo.jobenddate);
      }
    });
  }


  
  consert(values:any):void {
    console.log(values.currentTarget.checked);

    if(values.currentTarget.checked)
      this.btndisable = true;
    else
      this.btndisable = false;
  }
  
  fileChange(input){
    const reader = new FileReader();
    if (input.files.length) {       
        this.file = input.files[0].name;            
    }
    this.uploadFile(input)
  }

  removeFile():void{
      this.file = '';
  }

  uploadFile(e) {
    const file:File = e.files[0];
    if (file) {

      // //this.fileName = file.name;

      // const formData = new FormData();

      // formData.append("file", file);
      
      // this.referencingmoduleService.referencemodeluploadfile(formData).subscribe((response: any)=>{

      // });

      //this.helperService.uploadfile(file);


      //this.fileName = file.name;

      
      const formData = new FormData();

      formData.append("file", file ,'bulkreferencerequestsample.csv');
      //formData.append("key", "jj");
      

      // this.referencingmoduleService.uploadfile(formData,this.token).subscribe((response: any)=>{
      //   console.log('hi');
      // });
    }
  }

  downloadfile(){
    // this.referencingmoduleService.referencemodelgetuploadedfile("a.pdf").subscribe((data) => {

    //   //this.blob = new Blob([data], {type: 'application/pdf'});
    
    //   var downloadURL = window.URL.createObjectURL(data);
    //   var link = document.createElement('a');
    //   link.href = downloadURL;
    //   link.download = "a.pdf";
    //   link.click();
    
    // });
  }

  addquestiontoformgroup(questionTemplatesdetailslocal){
    for(let i=0;i<questionTemplatesdetailslocal.length;i++){
      (<FormArray>this.refereesresponseFormGroup.get('refereesresponsedetails')).push(this.addrefereesresponsedetailFormGroup(questionTemplatesdetailslocal[i]));
    }

    let remarktemplate={
      question:"Remark",
      questiontype:'long',
      questionoptions:'',
      required:"No",
      response:'',
    };
    (<FormArray>this.refereesresponseFormGroup.get('refereesresponsedetails')).push(this.addrefereesresponsedetailFormGroup(remarktemplate)); 
  }

  public steps:any[];
  public accountForm:FormGroup;
  public personalForm:FormGroup;
  public paymentForm:FormGroup;
  public details:any = {};
  public showConfirm:boolean;

  public next(currentstep){
    let accountForm = this.accountForm;
    let personalForm = this.personalForm;
    let paymentForm = this.paymentForm;
    if(this.steps[this.steps.length-1].active)
      return false;
      // console.log(this.refereesFormGroup);
    this.steps.forEach((step, index, steps) => {
      // console.log(steps);
      // if(index < steps.length-1){
        if(step.active){
          if(currentstep=='Information'){
            // console.log(this.refereesFormGroup);
            this.steps[0].active = false;
            this.steps[0].valid = true;
            this.steps[1].active=true;
            return true;
          }
          if(currentstep=='About You'){                     
            // console.log(this.refereesFormGroup);
            this.submit = true;
            if(this.refereesFormGroup.invalid){
              return;
            }
            this.submit = false;
            this.steps[1].active = false;
            this.steps[1].valid = true;
            this.steps[2].active=true;
            return true;
          }
          if(currentstep=='About Reference'){
            
            this.steps[index].active = false;
            this.steps[index].valid = true;
            // this.steps[index+1].active=true;
            return true;
          }
        }
      // }   
    });
  }

  public confirm(){
    // this.refereesFormGroup = this.formBuilder.group({
    //   referencerequestid: null,  
    //   refereeid: null,       
    //   refereedesignationcheck: ['', [Validators.required]],
    //   refereephonecheck: ['', [Validators.required]],
    //   candidatedesignationcheck: ['', [Validators.required]]
    // });      

    // this.refereesresponseFormGroup 
    this.submit2 = true;
    if(this.refereesresponseFormGroup.invalid){
      return;
    }
    this.refereesFormGroup.value.refereeip = sessionStorage.getItem('LOCAL_IP');

    let data ={ ud: this.cd, value:  this.refereesFormGroup.value}
// console.log(this.refereesresponseFormGroup);

    this.referencingmoduleService.updatereferee(data).subscribe((response: any)=>{        
      console.log(response)
    });

    this.refereesresponseFormGroup.value.companyid = this.referencecompanyinfo.companyid;
    this.refereesresponseFormGroup.value.referencerequestid = this.referencerequestinfo.id;
    this.refereesresponseFormGroup.value.refereeid = this.refereedetailinfo.id;
    this.refereesresponseFormGroup.value.companyname = this.referencecompanyinfo.companyname;
    this.refereesresponseFormGroup.value.candidatename = this.referencerequestinfo.candidatename;
    this.refereesresponseFormGroup.value.candidateemail = this.referencerequestinfo.candidateemail;
    this.refereesresponseFormGroup.value.refereename = this.refereedetailinfo.refereename;
    this.refereesresponseFormGroup.value.refereeemail = this.refereedetailinfo.refereeemail;
    // console.log('ref value', this.refereesresponseFormGroup.value);
    this.referencingmoduleService.createrefereeresponse(this.refereesresponseFormGroup.value).subscribe((response: any)=>{        
      if(response){
        let tokendelete ={ ud: this.cd, value: {tmptoken: ''}};
        this.referencingmoduleService.updatereferee(tokendelete).subscribe((response: any)=>{ 
          this.steps[2].active=false;
          this.formsubmitted = true;
        });
      }
      console.log(response);
    });
  }


  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value > t.value) {
        return {
          dates: "Finish Date should be greater than start date"
        };
      }
      return {};
    }
  }

  // addItem(): void {
  //     this.items = this.refereesFormGroup.get('items') as FormArray;
  //     this.items.push(this.createItem());
  //   }
  

  goToTC(){
    window.open("http://localhost:4200/termandconditionrefmod", "_blank");
  }

  goToPP(){
    window.open("http://localhost:4200/privacypolicyrefmod", "_blank");
  }

  endDateValidation(date) {
    let d1 = new Date("12/12/2017");
    let d2 = new Date(date);
    alert(date)
  }

  stringtoarrar(string,delimiter){
    return string.split(delimiter);
  }


}
