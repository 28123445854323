"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var ContactService = /** @class */ (function () {
    function ContactService(commonService) {
        this.commonService = commonService;
    }
    ContactService.prototype.getContactInfobyadmin = function (data) {
        return this.commonService.formpost('/employee/getemployeecontactinfobyadmin', data);
    };
    ContactService.prototype.getContactInfo = function (data) {
        return this.commonService.formpost('/employee/getemployeecontactinfo', data);
    };
    ContactService.prototype.contactus = function (data) {
        return this.commonService.formpost('/contact-us-download', data);
    };
    ContactService.prototype.employeeContactinfoUpdatebyadmin = function (value) {
        return this.commonService.formpost('/employee/employeecontactinfoupdatebyadmin', value);
    };
    ContactService.prototype.employeeContactinfoUpdate = function (value) {
        return this.commonService.formpost('/employee/employeecontactinfoupdate', value);
    };
    ContactService.ngInjectableDef = i0.defineInjectable({ factory: function ContactService_Factory() { return new ContactService(i0.inject(i1.CommonService)); }, token: ContactService, providedIn: "root" });
    return ContactService;
}());
exports.ContactService = ContactService;
