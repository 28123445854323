"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Passport = /** @class */ (function () {
    function Passport() {
        this.passportissue = new Date();
        this.passportexpiry = new Date();
    }
    return Passport;
}());
exports.Passport = Passport;
