import { Component, OnInit, Input, Output,EventEmitter,SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ModalService } from '../../../_services/modal.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../services/alert.service';
import { ChecksystemService } from '../../../services/checksystem.service';
import { DbsService } from '../../../services/dbs.service';
import { Profile } from '../../../models/overview/profile.model';
import { EmployeeService } from '../../../services/employee.service';
import { UserService } from '../../../services/user.service';
import { PermissionService } from '../../../services/permission.service';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'app-dbs-history',
  templateUrl: './dbs-history.component.html',
  styleUrls: ['./dbs-history.component.css']
})
export class DbsHistoryComponent implements OnInit {
  @Input() items: Array<any>;
  employeeForm: FormGroup;
  searchText:any;
  pageOfItems: Array<any>;
  record: any;
  Userid: any;
  listcheck: any;
  data: any;
  disableBtn: any;
  ramainingChecks:any;
  submitted: any;
  permission: any;
  requestperm: any;
  h1tag: any = "";
  dbstype:any;
  overviewData: any;
  permissionstring: any;
  emailexist = false;
  dbsid: any;
  employeeid: any;
  samecompany:any;
  remainingcheck: any;
  phonecheck: any;
  datalist:any;
  
  constructor(
    private userService: UserService, 
    private employeeService: EmployeeService, 
    private modalService: ModalService, 
    private formBuilder: FormBuilder, 
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private check: ChecksystemService,
    private permissiondetails: PermissionService,
    private alerts: AlertService,    
    private dbsService: DbsService
  ) { 
    route.params.subscribe(params => {
      this.dbstype = params['type'];
      if(this.dbstype == 'basic')
      {
        this.h1tag = "Basic";
      }
      else if(this.dbstype == 'standard')
      {
        this.h1tag = "Standard";
      }
      else if(this.dbstype == 'enhanced')
      {
        this.h1tag = "Enhanced";
      }     
      else if(this.dbstype == 'access-ni-basic')
      {
       
        this.h1tag = "AccessNI Basic";
      }
      else if(this.dbstype == 'access-ni-standard')
      {
        
        this.h1tag = "AccessNI Standard";
      }  
      else if(this.dbstype == 'access-ni-enhanced')
      {
        
        this.h1tag = "AccessNI Enhanced";
      }
      else if(this.dbstype == 'scotland-basic-disclosure')
      {
       
        this.h1tag = "Scotland Basic Disclosure";
      }  
      else if(this.dbstype == 'scotland-standard-disclosure')
      {
        
        this.h1tag = "Scotland Standard Disclosure";
      }
      else if(this.dbstype == 'scotland-enhanced-disclosure')
      {
       
        this.h1tag = "Scotland Enhanced Disclosure";
      }  
     
    });
  }

  ngOnInit() {
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;
    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');

    this.employeeForm = this.formBuilder.group({
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      empid: [''],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: ['', Validators.compose([Validators.required])]
    });

    this.overviewData = new Profile();
    this.overviewData.phonenumber = "+44";

    if(this.dbstype == 'basic'){
      this.dbsid = 2;
    }else if(this.dbstype == 'standard'){
      this.dbsid = 3;
    }else if(this.dbstype == 'enhanced'){
      this.dbsid = 1;
    }
    else if(this.dbstype == 'access-ni-basic')
    {
      this.dbsid = 14;
      this.h1tag = "AccessNI Basic";
    }
    else if(this.dbstype == 'access-ni-standard')
    {
      this.dbsid = 15;
      this.h1tag = "AccessNI Standard";
    }  
    else if(this.dbstype == 'access-ni-enhanced')
    {
      this.dbsid = 16;
      this.h1tag = "AccessNI Enhanced";
    }
    else if(this.dbstype == 'scotland-basic-disclosure')
    {
      this.dbsid = 17;
      this.h1tag = "Scotland Basic Disclosure";
    }  
    else if(this.dbstype == 'scotland-standard-disclosure')
    {
      this.dbsid = 18;
      this.h1tag = "Scotland Standard Disclosure";
    }
    else if(this.dbstype == 'scotland-enhanced-disclosure')
    {
      this.dbsid = 19;
      this.h1tag = "Scotland Enhanced Disclosure";
    }  

    this.check.getbillinginformation({ 'serviceid': this.dbsid }).subscribe((remaindata: any) => { 
      this.remainingcheck = remaindata;
    });
    
    this.check.getbillinginformation({ 'serviceid': this.dbsid }).subscribe((remaindata: any) => { 
      this.ramainingChecks = remaindata;
    });

    this.getAllDbsHistory();

    this.permissiondetails.bindallmenu().subscribe((menudata: any) => {
      var permissionstring = "[{";
      var perarray = [];
      menudata.forEach(function (valuemenu) {
        perarray.push('"' + valuemenu.clname + '": 0');
      });
      
      var perstr = perarray.join();
      permissionstring = permissionstring + perstr + "}]";
      this.permissionstring = permissionstring;
    });
  }

  getAllDbsHistory()
  {
    const data = { 'dbstype': this.dbsid };
    this.dbsService.getalldbsListbyadmin(data).subscribe((data: any) => {     
      this.record  = data.length;
      this.pageOfItems = data;
      this.datalist = data;
      if(this.record < 30){
        this.pageOfItems = data;
      }
      else{
           this.items =data;
      }
    });
  }

  get f() { return this.employeeForm.controls; }

  onAddEmployeeSubmit(employeeform1: any, modelid: any) {
    this.submitted = true;console.log(this.employeeForm);
    if (this.employeeForm.invalid) {
      return;
    }

    this.disableBtn = false;
    
    employeeform1.id = this.Userid;
    employeeform1.permission = this.permissionstring;
    this.userService.isEmailRegisterd(employeeform1.emailid).subscribe((data: any) => {
      if (data.sussess == 'success') {
        this.emailexist = true; 
        this.disableBtn = true;
      }
      else {       
          this.disableBtn = false;
          this.employeeService.checkemployeecreate(employeeform1).subscribe((dataw: any) => {
            if(dataw.susses == 'true'){
              this.modalService.close(modelid);
              // this.modalService.open('sendrequestcharge');
              this.sendrequest(dataw.employeemasterid, 'sendrequestcharge');
              this.employeeid = dataw.employeemasterid;
          }else
          { 
            this.alerts.error('Somthing went wrong. please try again after sometime');}
          });
      }
    });
  }

  sendrequestcharge(employeeid, id)
  {
    var ts = {employeeid: employeeid, requestid: this.dbsid}
    this.dbsService.createdbs(ts).subscribe((remaindata: any) => { 
      this.getAllDbsHistory();
      this.alerts.success(this.h1tag +' Request Sent Successfully');
      this.modalService.close(id);
    });
  }

  sendrequest(employeeid, id)
  {
    if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
    {
      this.modalService.open('upgrade-plan');
    }
    else
    {
      this.modalService.open(id);
      this.employeeid = employeeid;
    }
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
     
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  focusFunction() {
    this.emailexist = false;
  }

  onFocusOutEvent(event: any)
  {
    this.userService.isEmailRegisterdCheckForAdmin(event.target.value).subscribe((data: any) => {
      if (data.sussess == 'success') {
        this.emailexist = true; 
        this.disableBtn = false;
        this.employeeid = data.employeeid;
        if(data.samecompany)
        {
          this.samecompany = true;
        }
        // this.isFocus = true;
        // this.modalService.open('alreadyadd-request');
      }else
      {
        this.disableBtn = true;        
      }      
    });    
  }
  
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  closeModal(id: string) 
  {
    this.modalService.close(id);
  }

  uploadByAdmin(dbs, model_id)
  {
    if(this.ramainingChecks && this.ramainingChecks.remiancheck < 1)
    {
      this.modalService.open('upgrade-plan');
    }
    else
    {
      this.modalService.open(model_id);
      // this.router.navigate(['/check-add-applicant', this.dbstype+'-dbs']);   
    }
  }

}
