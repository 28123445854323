"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var date_fns_1 = require("date-fns");
var Subject_1 = require("rxjs/Subject");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var angular_calendar_1 = require("angular-calendar");
var expense_service_1 = require("../services/expense.service");
var expense_model_1 = require("../models/expense/expense.model");
var leave_service_1 = require("../services/leave.service");
var modal_service_1 = require("../_services/modal.service");
var upload_file_service_1 = require("./../services/upload-file.service");
var platform_browser_1 = require("@angular/platform-browser");
var customer_service_1 = require("../services/customer.service");
var router_1 = require("@angular/router");
var app_global_1 = require("../app.global");
var common_1 = require("@angular/common");
var ExpenseComponent = /** @class */ (function () {
    function ExpenseComponent(customer, router, modal, datePipe, sanitizer, leaveService, data, uploadService, formBuilder, modalService) {
        this.customer = customer;
        this.router = router;
        this.modal = modal;
        this.datePipe = datePipe;
        this.sanitizer = sanitizer;
        this.leaveService = leaveService;
        this.data = data;
        this.uploadService = uploadService;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.events = [];
        this.submitted = false;
        this.leaveavailabledate = new Date();
        this.editMsg = "";
        this.findLeave = false;
        this.view = angular_calendar_1.CalendarView.Month;
        this.CalendarView = angular_calendar_1.CalendarView;
        this.viewDate = new Date();
        this.refresh = new Subject_1.Subject();
        this.activeDayIsOpen = true;
    }
    ExpenseComponent.prototype.ngOnInit = function () {
        this.currencies = app_global_1.AppGlobals.CURRENCIES;
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        // this.leaveavailabledate.setDate(this.leaveavailabledate.getDate() - 31);
        this.leaveavailabledate.setDate(this.leaveavailabledate.getDate());
        this.filename = 'Choose file';
        this.classactive = (new Date()).getMonth().toString();
        this.year = (new Date()).getFullYear();
        this.formvalidation();
        this.bindalldata();
    };
    ExpenseComponent.prototype.formvalidation = function () {
        this.expenseformGroup = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            projectid: ['', forms_1.Validators.required],
            amountpaid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.pattern(/^[.\d]+$/), forms_1.Validators.max(99999), forms_1.Validators.min(1)])],
            paidby: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            expensetypeid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            locationname: ['', forms_1.Validators.required],
            currency: ['', forms_1.Validators.required],
            start: [''],
            end: [''],
            expenseremark: ['', forms_1.Validators.required],
            expenseremarksid: ['', forms_1.Validators.required]
        });
    };
    ExpenseComponent.prototype.fromreset = function () {
        this.expenseformGroup = this.formBuilder.group({
            id: [''],
            employeeid: [''],
            projectid: [''],
            amountpaid: [''],
            paidby: [''],
            expensetypeid: [''],
            locationname: [''],
            currency: [''],
            start: [''],
            end: [''],
            expenseremark: [''],
            expenseremarksid: ['']
        });
    };
    ExpenseComponent.prototype.bindalldata = function () {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        var sstatustype = { 'status': true, 'id': this.Userid };
        this.data.getexpensetypelist(sstatustype).subscribe(function (expenselist) {
            _this.expensetypelist = expenselist;
        });
        this.data.getemployeeexpenselist().subscribe(function (data) {
            data.forEach(function (element) {
                _this.events.push({
                    id: element.employeeexpenseapplyid,
                    title: element.amountpaid + '-' + element.project.projectname,
                    start: new Date(element.startdate),
                    end: new Date(element.enddate),
                    color: { primary: element.hcolor, secondary: element.fcolor },
                    draggable: true,
                    allDay: element.edit,
                    resizable: {
                        beforeStart: true,
                        afterEnd: true
                    }
                });
                _this.refresh.next();
            });
        });
        this.data.getprojectlist(sstatustype).subscribe(function (projectlist) {
            _this.projectlist = projectlist;
        });
    };
    Object.defineProperty(ExpenseComponent.prototype, "f", {
        get: function () {
            return this.expenseformGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    ExpenseComponent.prototype.onSubmit = function (expense, id) {
        var _this = this;
        this.submitted = true;
        this.editMsg = "";
        if (this.expenseformGroup.invalid) {
            return;
        }
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.name.split('.');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            expense.imagename = imagepath;
        }
        else {
            expense.imagename = this.expense.imagename;
        }
        this.disableBtn = false;
        this.data.expenseapplyupdate(expense).subscribe(function (data) {
            _this.events = [];
            _this.filename = 'Choose file';
            _this.selectedFiles = null;
            document.getElementById('closeModal').click();
            _this.bindalldata();
            _this.formvalidation();
        });
    };
    ExpenseComponent.prototype.selectFile = function (event) {
        this.filesizeerror = false;
        this.fileformaterror = false;
        var files = event.target.files;
        this.filename = files[0].name;
        this.selectedFiles = files[0];
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.name.split('.');
            console.log(this.file);
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
                this.filesizeerror = false;
                this.fileformaterror = true;
                return;
            }
            else {
                this.filesizeerror = false;
                this.fileformaterror = false;
            }
        }
        else {
            this.filesizeerror = false;
            this.fileformaterror = false;
        }
    };
    ExpenseComponent.prototype.dayClicked = function (_a) {
        var date = _a.date, events = _a.events;
        if (date_fns_1.isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            if ((date_fns_1.isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0) {
                this.activeDayIsOpen = false;
            }
            else {
                this.activeDayIsOpen = true;
            }
        }
    };
    ExpenseComponent.prototype.onyearSubmit = function (leave) {
        this.year = leave;
        var date = this.viewDate;
        date = date_fns_1.setYear(date, this.year);
        this.viewDate = date;
        // this.modalService.close(id);
    };
    ExpenseComponent.prototype.addMonthsOfYear = function (inc) {
        this.classactive = inc;
        var date = this.viewDate;
        date = date_fns_1.setMonth(date, inc);
        date = date_fns_1.setYear(date, this.year);
        this.viewDate = date;
        this.events = [];
        this.bindalldata();
    };
    ExpenseComponent.prototype.formatDate = function (date) {
        var monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        return day + ' ' + monthNames[monthIndex] + ' ' + year;
    };
    ExpenseComponent.prototype.eventTimesChanged = function (_a) {
        var event = _a.event, newStart = _a.newStart, newEnd = _a.newEnd;
        event.start = newStart;
        event.end = newEnd;
        this.handleEvent('Dropped or resized', event);
        this.refresh.next();
    };
    ExpenseComponent.prototype.handleEvent = function (action, event) {
        this.modalData = { event: event, action: action };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    ExpenseComponent.prototype.openlinemanagerModal = function (id) {
        var _this = this;
        this.customer.getLineEmployeeDetails().subscribe(function (lsmanager) {
            _this.linemanager = lsmanager;
        });
        this.modalService.open(id);
    };
    ExpenseComponent.prototype.onDelete = function (id) {
        var _this = this;
        var deleteData = { 'id': id };
        if (confirm("Are you sure you want to delete?")) {
            this.data.deleteexpense(deleteData).subscribe(function (data) {
                _this.events = [];
                document.getElementById('closeModal').click();
                _this.bindalldata();
            });
        }
    };
    ExpenseComponent.prototype.addNewEvent = function (action, event) {
        this.editMsg = "";
        this.submitted = false;
        this.disableBtn = true;
        this.selectedFiles = null;
        this.expense = new expense_model_1.Expense();
        this.Userid = localStorage.getItem('userid');
        this.expense.employeeid = this.Userid;
        this.expense.start = new Date();
        this.expense.end = new Date();
        this.headerdate = this.formatDate(new Date());
        this.expense.currency = 'GBP';
        this.expense.employeeexpenseapplyid = '0';
        this.expense.expenseremarksid = '0';
        this.modalData = { action: action, event: event };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    ExpenseComponent.prototype.addEvent = function (action, event, start) {
        var _this = this;
        this.editMsg = "";
        this.findLeave = false;
        this.Userid = localStorage.getItem('userid');
        var databydate = { 'id': this.Userid, 'exid': this.datePipe.transform(new Date(start), "dd MMM yyyy") };
        this.leaveService.checkleaveondate(databydate).subscribe(function (userdata) {
            if (userdata.length) {
                _this.findLeave = true;
            }
        });
        this.submitted = false;
        this.disableBtn = true;
        this.selectedFiles = null;
        this.expense = new expense_model_1.Expense();
        this.expense.employeeid = this.Userid;
        this.expense.start = new Date(start);
        this.expense.end = new Date(start);
        this.headerdate = this.formatDate(new Date(start));
        this.expense.currency = 'GBP';
        this.expense.employeeexpenseapplyid = '0';
        this.expense.expenseremarksid = '0';
        this.modalData = { action: action, event: event };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    ExpenseComponent.prototype.editEvent = function (action, event) {
        var _this = this;
        this.submitted = false;
        this.findLeave = false;
        this.editMsg = "";
        this.disableBtn = true;
        this.expense = new expense_model_1.Expense();
        this.Userid = localStorage.getItem('userid');
        this.expense.employeeid = this.Userid;
        var employeeexpenseapplyid = event.id;
        this.data.getexpensedata({ 'id': employeeexpenseapplyid }).subscribe(function (data) {
            _this.expense.title = data.title;
            _this.expense.projectid = data.projectid;
            _this.expense.expensetypeid = data.expensetypeid;
            _this.expense.amountpaid = data.amountpaid;
            _this.expense.start = new Date(data.startdate);
            _this.expense.end = new Date(data.enddate);
            _this.expense.employeeexpenseapplyid = data.employeeexpenseapplyid;
            _this.headerdate = _this.formatDate(new Date(data.startdate));
            _this.expense.locationname = data.locationname;
            _this.expense.expenseremarksid = data.expenseremarksid;
            _this.expense.expenseremark = data.remark.expenseremark;
            _this.expense.hourtypeid = data.hourtypeid;
            _this.expense.currency = data.currency;
            _this.expense.paidby = data.paidby;
            _this.expense.imagename = data.imagename;
            _this.expense.hstatus = data.hstatus;
            if (data.hstatus != 1) {
                _this.disableBtn = false;
                _this.editMsg = "You can not edit this expense";
            }
            var databydate = { 'id': _this.Userid, 'exid': _this.datePipe.transform(new Date(data.startdate), "dd MMM yyyy") };
            _this.leaveService.checkleaveondate(databydate).subscribe(function (userdata) {
                if (userdata.length) {
                    _this.findLeave = true;
                }
            });
        });
        this.modalData = { action: action, event: event };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    ExpenseComponent.prototype.editEventOfList = function (action, id, alleventpopid, event) {
        var _this = this;
        this.submitted = false;
        this.editMsg = "";
        this.disableBtn = true;
        this.expense = new expense_model_1.Expense();
        this.Userid = localStorage.getItem('userid');
        this.expense.employeeid = this.Userid;
        var employeeexpenseapplyid = id;
        this.data.getexpensedata({ 'id': employeeexpenseapplyid }).subscribe(function (data) {
            _this.expense.title = data.title;
            _this.expense.projectid = data.projectid;
            _this.expense.expensetypeid = data.expensetypeid;
            _this.expense.amountpaid = data.amountpaid;
            _this.expense.start = new Date(data.startdate);
            _this.expense.end = new Date(data.enddate);
            _this.expense.employeeexpenseapplyid = data.employeeexpenseapplyid;
            _this.headerdate = _this.formatDate(new Date(data.startdate));
            _this.expense.locationname = data.locationname;
            _this.expense.expenseremarksid = data.expenseremarksid;
            _this.expense.expenseremark = data.remark.expenseremark;
            _this.expense.currency = data.currency;
            _this.expense.hourtype = data.hourtypeid;
            _this.expense.paidby = data.paidby;
            _this.expense.hstatus = data.hstatus;
        });
        this.closeModal(alleventpopid);
        this.modalData = { action: action, event: event };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    ExpenseComponent.prototype.showAllEvents = function (id, exid) {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        var databydate = { 'id': this.Userid, 'exid': exid };
        this.data.getexpensedatabydate(databydate).subscribe(function (userdata) {
            _this.dayexpense = userdata;
        });
        this.modalService.open(id);
    };
    ExpenseComponent.prototype.openVisaExpenceModal3 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgexpencesrc = true;
            this.iframeexpensesrc = data;
        }
        else {
            this.imgexpencesrc = false;
            this.iframeexpensesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    ExpenseComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    ExpenseComponent.prototype.yearpop = function (id) {
        this.modalService.open(id);
    };
    ExpenseComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return ExpenseComponent;
}());
exports.ExpenseComponent = ExpenseComponent;
