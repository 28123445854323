import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { AmlBussiness,Aml,adverst } from '../../../models/aml/aml.model';
import { AmlService } from '../../../services/aml.service';

import { CheckserviceService } from '../../../services/checkservice.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-adversecredithistory',
  templateUrl: './adversecredithistory.component.html',
  styleUrls: ['./adversecredithistory.component.css']
})
export class AdversecredithistoryComponent implements OnInit {

  result: boolean;
  searchresult: any;
  searchresultnew: any;
  atype:boolean=false;
  logid:any;
  datasetlist: any;
  downloadtext: any;
  selectedpage: number = 1;

  
  unassignedCases: any = [];

  constructor( 
    private amldetails: AmlService,    
    private router: Router,    
    private checkService: CheckserviceService, 
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.unassignedCases= [];
    var masterarray= [];
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }
    var tt={}
    this.checkService.checkhistory(tt).subscribe((searchRes: any) => {  

      console.log('Search System', searchRes);


      this.downloadtext = new Array(searchRes.length);
      for(var i=0;i<searchRes.length;i++)
      {
        this.downloadtext[i] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
      }    
      this.searchresult = searchRes;//searchRes;  
      
      
      this.unassignedCases = this.paginator(this.searchresult,this.selectedpage,50);

      console.log('Search System', this.unassignedCases);

    }); 
    
    

  }


  
  paginator(data, current_page, per_page_items) {

    
    console.log(data);


    let items;
   
    items = data
    let page = current_page || 1,
    per_page = per_page_items || 50,
    offset = (page - 1) * per_page,
  
    paginatedItems = items.slice(offset).slice(0, per_page_items),
    total_pages = Math.ceil(items.length / per_page);
  
    return {
      page: page,
      perpage: per_page,
      prepage: page - 1 ? page - 1 : null,
      nextpage: (total_pages > page) ? page + 1 : null,
      total: items.length,
      totalpages: total_pages,
      data: paginatedItems,
      totalpagesarray : Array(total_pages).fill(null).map((x,i)=>i+1)
    };
  }

  selectpage(pagenumber){
    this.selectedpage = pagenumber    
      this.unassignedCases = this.paginator(this.searchresult,this.selectedpage,50);   
  }


  downloadAdverseCreditHistory(historyid: any, ind){
    this.downloadtext[ind] = 'Loading <i class="fa fa-spinner fa-spin"></i>';
    this.checkService.adversecredithistorydownload({"id":historyid}).subscribe((searchRes: any) => {  
      this.downloadtext[ind] = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
      window.open(searchRes.response_data.Location, "_blank");
      console.log(searchRes); 
    });
  }

  outputnewset(d){
    var listmaster =JSON.parse(d);  
    this.searchresultnew = new adverst();
    this.searchresultnew = listmaster;
  }

  outputnewBussinessset(d){
    var listmaster =JSON.parse(d);  
    this.searchresultnew = new AmlBussiness();
    this.searchresultnew = listmaster;
  }

  // viewHistory(historyid: any){
  //   this.router.navigate(['/adverse-credit-history-view/'+historyid]);
  // }

}
