import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-aiwizard',
  templateUrl: './aiwizard.component.html',
  styleUrls: ['./aiwizard.component.css']
})
export class AiwizardComponent implements OnInit {
  employeevisadeatilid: any;
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    
    this.employeevisadeatilid = this.activatedRoute.snapshot.paramMap.get("visaid");


  }

  block()
  {return;}

}
