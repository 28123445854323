



import { Component, OnInit, Input, Output,EventEmitter,SimpleChanges } from '@angular/core';
import { CountryService } from '../../../services/country.service';
import { MyvisaService } from '../../../services/myvisa.service';
import { ModalService } from '../../../_services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Visa } from '../../../models/visa/visa.model';
import { UploadFileService } from './../../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertService } from '../../../services/alert.service';
import { AicenterService } from '../../../services/aicenter.service';
import { MyidproofService } from '../../../services/myidproof.service';
import { MypassportService } from '../../../services/mypassport.service';

import { EmployeeService } from '../../../services/employee.service';

@Component({
  selector: 'app-ailog',
  templateUrl: './ailog.component.html',
  styleUrls: ['./ailog.component.css']
})
export class AilogComponent implements OnInit {


  @Input() itemsvisa: Array<any>;
  @Input() itemspassport: Array<any>;
  @Input() itemsid: Array<any>;


  pageOfItemsvisa: Array<any>;
  pageOfItemspassport: Array<any>;
  pageOfItemsid: Array<any>;

  recordpassport: any;
  recordvisa: any;
  recordid: any;
  employeelist:any;
  documenttype: any;
  visalist: any[];
  visaDetail: any;
  countrylist: any;
  Userid: any;
  listcheck: any;
  selectedFiles: FileList;
  iframesrc: any;
  imgsrc: any;
  buttoninsert: any;
  filesizeerror: any;
  fileformaterror: any;
  file: any;
  filename: any;
  data: any;
  empid:any;
  disableBtn: any;
  passportlist: any[];  
  aipayment: boolean =false;
  submitted: any;
  visapopForm: FormGroup;
  visa: Visa;
  permission: any;
  requestperm: any;


  constructor(
    private sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder, 
    private uploadService: UploadFileService, 
    private visaService: MyvisaService, 
    private idproofService: MyidproofService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private aicenter:AicenterService,
    private passdata:MypassportService,
    private router: Router,
    private employee: EmployeeService,
    private alerts: AlertService
    ) { }

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.requestperm = this.permission.m15;


    this.documenttype ='';

    const id = localStorage.getItem('userToken');
    this.Userid = localStorage.getItem('userid');
   
    
    var value3 = "{}";
    this.employee.getemployeelist(value3).subscribe((listdetails: any) => {
      this.employeelist = listdetails;
    });

    this.aicenter.getcountpayment().subscribe((data: any) => {     
      this.aipayment = data.payment;
    });

    this.loadalldata();

    
  }


  loadalldata(){

    var data;
    if(this.empid !=''){
       data = { 'documenttype': 'v', employeeid: this.empid };
    }
    else {
      data = { 'documenttype': 'v' };
    }

    this.aicenter.getAilogType(data).subscribe((data: any) => {
      this.recordvisa  = data.length;
      if(this.recordvisa < 30){
        this.pageOfItemsvisa = data;
      }
      else{
           this.itemsvisa =data;
      }
    });


    
    var data2;
    if(this.empid !=''){
       data2 = { 'documenttype': 'p', employeeid: this.empid };
    }
    else {
      data2 = { 'documenttype': 'p' };
    }

    
    this.aicenter.getAilogType(data2).subscribe((data: any) => {

      // console.log(data);
      // this.passportlist = data;
      this.recordpassport  = data.length;
      if(this.recordpassport < 30){
        this.pageOfItemspassport = data;
      }
      else{
           this.itemspassport =data;
      }      
    });


    
    var data3;
    if(this.empid !=''){
      data3 = { 'documenttype': 'id', employeeid: this.empid };
    }
    else {
      data3 = { 'documenttype': 'id' };
    }

    this.aicenter.getAilogType(data3).subscribe((data: any) => {  
      this.recordid  = data.length;
      if(this.recordid < 30){
        this.pageOfItemsid = data;
      }
      else{
           this.itemsid =data;
      }
    });
  }

  changeDocumentType(id: any)
  {
    this.documenttype = id;    
  }
  changeEmployee(id: any) {
    this.empid = id;
    this.loadalldata();
  }
  
  onChangePagevisa(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItemsvisa = pageOfItems;
  }
  
  onChangePagepassport(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItemspassport = pageOfItems;
  }
  
  onChangePageid(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItemsid = pageOfItems;
  }


  getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  openModal3(id: string, data: any) 
  {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  closeModal(id: string) 
  {    
    this.modalService.close(id);
  }

}
