"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var expense_service_1 = require("./../../services/expense.service");
var modal_service_1 = require("../../_services/modal.service");
var forms_1 = require("@angular/forms");
var customer_service_1 = require("../../services/customer.service");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var app_global_1 = require("../../app.global");
var ExpenseapprovalComponent = /** @class */ (function () {
    function ExpenseapprovalComponent(sanitizer, router, dataset, formBuilder, modalService, customer) {
        this.sanitizer = sanitizer;
        this.router = router;
        this.dataset = dataset;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.customer = customer;
        this.isSelected = false;
        this.monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];
        this.orders = [
            { id: 100, name: 'order 1' },
            { id: 200, name: 'order 2' },
            { id: 300, name: 'order 3' },
            { id: 400, name: 'order 4' }
        ];
        var controls = this.orders.map(function (c) { return new forms_1.FormControl(false); });
        controls[0].setValue(true); // Set the first checkbox to true (checked)
        this.form = this.formBuilder.group({
            orders: new forms_1.FormArray(controls)
        });
    }
    ExpenseapprovalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        // this.passformGroup = this.formBuilder.group({
        //   employeeexpenseapplyid: [''],
        // });
        this.hstatus = '';
        this.Userid = localStorage.getItem('userid');
        this.month = (new Date()).getMonth() + 1;
        this.year = (new Date()).getFullYear();
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + (new Date()).getFullYear();
        this.classactive = ((new Date()).getMonth() + 1).toString();
        this.customer.getchildemployee({ j: 1 }).subscribe(function (data) {
            _this.employeelist = data;
        });
        // this.customer.getEmployeeDetailsbyadmin().subscribe((data: any) => {
        //   this.employeelist = data;
        // });
        this.bindalldata();
    };
    ExpenseapprovalComponent.prototype.openModalnote = function (data, id) {
        this.notedetails = data;
        this.modalService.open(id);
    };
    ExpenseapprovalComponent.prototype.onChange = function (newValue) {
        this.employeeid = newValue;
        this.bindalldata();
    };
    ExpenseapprovalComponent.prototype.onChangestatus = function (newValue) {
        this.hstatus = newValue;
        this.bindalldata();
    };
    ExpenseapprovalComponent.prototype.selectAll = function () {
        for (var i = 0; i < this.data.length; i++) {
            this.data[i].selected = this.selectedAll;
        }
    };
    ExpenseapprovalComponent.prototype.checkIfAllSelected = function () {
        this.selectedAll = this.data.every(function (item) {
            return item.selected == true;
        });
    };
    ExpenseapprovalComponent.prototype.submit = function () {
        // const selectedCountries = this.orders.filter( (country) => country.checked );
        // you could use an EventEmitter and emit the selected values here, or send them to another API with some service
        // console.log (selectedCountries);
    };
    ExpenseapprovalComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    ExpenseapprovalComponent.prototype.openModal3 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgsrc = true;
            this.iframesrc = data;
        }
        else {
            this.imgsrc = false;
            this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    ExpenseapprovalComponent.prototype.bindalldata = function () {
        var _this = this;
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'month': this.month, 'year': this.year, 'employeeid': this.employeeid, 'hstatus': this.hstatus };
        this.dataset.getemployeeexpensereportrequest(data).subscribe(function (data) {
            _this.record = data.length;
            _this.data = data;
        });
    };
    ExpenseapprovalComponent.prototype.getDataByMonth = function (mon) {
        this.classactive = mon;
        this.month = mon;
        this.yearmonth = this.monthNames[mon - 1] + ' ' + this.year; // (new Date()).getFullYear();
        // this.year =  this.monthNames[this.month -1 ] +' '+ this.year;
        this.bindalldata();
    };
    ExpenseapprovalComponent.prototype.onyearSubmit = function (year) {
        this.year = year;
        // this.year =  this.monthNames[this.month -1 ] +' '+ leave.year;
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + this.year;
        // this.year = leave.year;
        this.bindalldata();
        // this.modalService.close(id);
    };
    ExpenseapprovalComponent.prototype.yearpop = function (id) {
        this.modalService.open(id);
    };
    ExpenseapprovalComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    ExpenseapprovalComponent.prototype.onStatus = function (id, status) {
        var _this = this;
        var deleteData = { 'id': id, 'hstatus': status, 'userid': this.Userid, 'statusdate': new Date() };
        var msg = '';
        if (status == 2) {
            msg = 'Are you sure you want to Approve?';
        }
        else {
            msg = 'Are you sure you want to Reject?';
        }
        if (confirm(msg)) {
            this.dataset.statusexpense(deleteData).subscribe(function (data) {
                _this.bindalldata();
            });
        }
    };
    ExpenseapprovalComponent.prototype.getDataByYear = function (yea) {
        this.yearmonth = this.monthNames[this.month - 1] + ' ' + yea; //(new Date()).getFullYear();
        // this.year =  this.monthNames[this.month -1 ] +' '+ yea;
        // this.year = yea;
        this.bindalldata();
        // const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
        // this.dataset.getemployeeexpensereport(data).subscribe((data: any) => {
        //   this.data = data;
        // });
    };
    return ExpenseapprovalComponent;
}());
exports.ExpenseapprovalComponent = ExpenseapprovalComponent;
