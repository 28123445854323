"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var EmployeeService = /** @class */ (function () {
    function EmployeeService(commonService) {
        this.commonService = commonService;
    }
    EmployeeService.prototype.getlistofemployee = function () {
        return this.commonService.formpostOnlyid('/employee/listemployee');
    };
    EmployeeService.prototype.getlistofemployeeForCheck = function () {
        return this.commonService.formpostOnlyid('/employee/listemployeeforcheck');
    };
    EmployeeService.prototype.getlistofemployeerigttowork = function () {
        return this.commonService.formpostOnlyid('/employee/listemployeerighttowork');
    };
    EmployeeService.prototype.ssoUpdatestatusbyadmin = function (value) {
        return this.commonService.formpost('/ssoupdatestatusbyadmin', value);
    };
    EmployeeService.prototype.getListOfEmployeeWithTotalLeave = function (postdata) {
        return this.commonService.formpost('/employee/listemployee', postdata);
    };
    EmployeeService.prototype.getEmployeeTotalLeaves = function (postdata) {
        return this.commonService.formpost('/employee/total-leave', postdata);
    };
    EmployeeService.prototype.getlistofworkingemployee = function () {
        return this.commonService.formpostOnlyid('/employee/listworkingemployee');
    };
    EmployeeService.prototype.employeewelcomeemail = function (value) {
        return this.commonService.formpost('/employee/resendwelcomemail', value);
    };
    EmployeeService.prototype.employeeUpdateForm1 = function (value) {
        return this.commonService.formpost('/employee/employeeupdateform1', value);
    };
    EmployeeService.prototype.checkemployeecreate = function (value) {
        return this.commonService.formpost('/employee/checkemployeecreate', value);
    };
    EmployeeService.prototype.saveEmployeeTotalLeaves = function (data) {
        return this.commonService.formpost('/employee/save-employee-leaves', data);
    };
    EmployeeService.prototype.employeeUpdateChangeStatus = function (value) {
        return this.commonService.formpost('/employee/employeeupdatechangestatus', value);
    };
    EmployeeService.prototype.getdocumentlist = function (value) {
        return this.commonService.formpost('/employee/documentlistbyemployee', value);
    };
    EmployeeService.prototype.employeedocumentupdatebyadmin = function (value) {
        return this.commonService.formpost('/employee/employeedocumentupdate', value);
    };
    EmployeeService.prototype.deleteemployeedocumentFile = function (value) {
        return this.commonService.formpost('/employee/employeedocumentdelete', value);
    };
    EmployeeService.prototype.getemployeedocumentbyid = function (value) {
        return this.commonService.formpost('/employee/employeedocument', value);
    };
    EmployeeService.prototype.updateemployeedocumentbyid = function (value) {
        return this.commonService.formpost('/employee/employeeupdatedocumentname', value);
    };
    EmployeeService.prototype.getemployeelist = function (data) {
        return this.commonService.formpost('/employee/employeereport', data);
    };
    // getemployeedeparmentgraph(): Observable<any> 
    // {
    //   return this.commonService.formpostOnlyid('/employee/employeereport');   
    // }
    EmployeeService.prototype.employeeSubmitTermination = function (data) {
        return this.commonService.formpost('/employee/employeetermination', data);
    };
    EmployeeService.prototype.employeeRemoveTermination = function (data) {
        return this.commonService.formpost('/employee/removeemployeetermination', data);
    };
    EmployeeService.prototype.employeeViewTermination = function (data) {
        return this.commonService.formpost('/employee/viewemployeetermination', data);
    };
    EmployeeService.prototype.employeeTerminationHistory = function (data) {
        return this.commonService.formpost('/employee/getemployeeterminationhistory', data);
    };
    EmployeeService.prototype.getOtherInfobyadmin = function (data) {
        return this.commonService.formpost('/employee/getemployeeotherinfobyadmin', data);
    };
    EmployeeService.prototype.getTerminationInfobyadmin = function (data) {
        return this.commonService.formpost('/employee/getemployeeterminationinfobyadmin', data);
    };
    EmployeeService.prototype.getOtherInfo = function (data) {
        return this.commonService.formpost('/employee/getemployeeotherinfo', data);
    };
    EmployeeService.prototype.employeeotherinfoUpdatebyadmin = function (value) {
        return this.commonService.formpost('/employee/employeeotherinfoupdate', value);
    };
    EmployeeService.ngInjectableDef = i0.defineInjectable({ factory: function EmployeeService_Factory() { return new EmployeeService(i0.inject(i1.CommonService)); }, token: EmployeeService, providedIn: "root" });
    return EmployeeService;
}());
exports.EmployeeService = EmployeeService;
