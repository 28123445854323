"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var CustomerService = /** @class */ (function () {
    function CustomerService(commonService) {
        this.commonService = commonService;
        this.modals = [];
    }
    CustomerService.prototype.getPaddress = function () {
        return this.commonService.formpostOnlyid('/employee/employeehomeaddress');
    };
    CustomerService.prototype.getPaddressbyadmin = function (value) {
        return this.commonService.formpost('/employee/employeehomeaddressbyadmin', value);
    };
    CustomerService.prototype.getpassportListnew = function (value) {
        return this.commonService.formpost('/employee/employeepassportlistnew', value);
    };
    CustomerService.prototype.getOtherInfobyadmin = function (data) {
        return this.commonService.formpost('/employee/getemployeeotherinfobyadmin', data);
    };
    CustomerService.prototype.getloginbytokencheck = function () {
        return this.commonService.formpostOnlyid('/login-check-system');
    };
    CustomerService.prototype.getOverview = function () {
        return this.commonService.formpostOnlyid('/employee/employeedetails');
    };
    CustomerService.prototype.getOverviewbyadmin = function (value) {
        return this.commonService.formpost('/employee/employeedetailsbyadmin', value);
    };
    CustomerService.prototype.getOverviewRighttoworkbyadmin = function (value) {
        return this.commonService.formpost('/employee/righttoworkemployeedetailsbyadmin', value);
    };
    CustomerService.prototype.employeedetailsrotasystem = function (value) {
        return this.commonService.formpost('/employee/employeedetailsrotasystem', value);
    };
    CustomerService.prototype.getLineEmployeeDetails = function () {
        return this.commonService.formpostOnlyid('/employee/employeelinemanager');
    };
    CustomerService.prototype.getchildemployee = function (data) {
        return this.commonService.formpost('/employee/getchildemployee', data);
    };
    CustomerService.prototype.getLineEmployeeDetailsbyadmin = function () {
        return this.commonService.formpostOnlyid('/employee/employeelinemanagerbyadmin');
    };
    CustomerService.prototype.getEmployeeDetailsbyadmin = function () {
        return this.commonService.formpostOnlyid('/employee/listemployee');
    };
    CustomerService.prototype.getOtherInfo = function (data) {
        return this.commonService.formpost('/employee/getemployeeotherinfo', data);
    };
    CustomerService.prototype.updatepassword = function () {
        return this.commonService.formpostOnlyid('/employee/update-password');
    };
    CustomerService.prototype.getEmployment = function () {
        return this.commonService.formpostOnlyid('/employee/currentworkingdetails');
    };
    CustomerService.prototype.getEmploymentbyadmin = function (value) {
        return this.commonService.formpost('/employee/currentworkingdetailsbyadmin', value);
    };
    CustomerService.prototype.getAddress = function () {
        return this.commonService.formpostOnlyid('/employee/currentaddressdetails');
    };
    CustomerService.prototype.getAddressbyadmin = function (value) {
        return this.commonService.formpost('/employee/currentaddressdetailsbyadmin', value);
    };
    CustomerService.prototype.getVisa = function () {
        return this.commonService.formpostOnlyid('/employee/employeevisadetails');
    };
    CustomerService.prototype.getVisabyadmin = function (value) {
        return this.commonService.formpost('/employee/employeevisadetailsbyadmin', value);
    };
    CustomerService.prototype.updateemployeedetals = function (value) {
        return this.commonService.formpost('/employee/employeepersonaldetailsupdate', value);
    };
    CustomerService.prototype.updateemployeedetalsbyadmin = function (value) {
        return this.commonService.formpost('/employee/employeepersonaldetailsupdatebyadmin', value);
    };
    CustomerService.prototype.permanentadddetals = function (value) {
        return this.commonService.formpost('/employee/updateeeraddress', value);
    };
    CustomerService.prototype.permanentadddetalsbyadmin = function (value) {
        return this.commonService.formpost('/employee/updateeeraddressbyadmin', value);
    };
    CustomerService.prototype.updateemployeeimage = function (value) {
        return this.commonService.formpost('/employee/employeeimageupdate', value);
    };
    CustomerService.prototype.getPassport = function () {
        return this.commonService.formpostOnlyid('/employee/employeepassportdetails');
    };
    CustomerService.prototype.getPassportbyadmin = function (value) {
        return this.commonService.formpost('/employee/employeepassportdetailsbyadmin', value);
    };
    CustomerService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerService_Factory() { return new CustomerService(i0.inject(i1.CommonService)); }, token: CustomerService, providedIn: "root" });
    return CustomerService;
}());
exports.CustomerService = CustomerService;
