"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Employee = /** @class */ (function () {
    function Employee() {
    }
    return Employee;
}());
exports.Employee = Employee;
var Contactinfo = /** @class */ (function () {
    function Contactinfo() {
        this.id = "0";
    }
    return Contactinfo;
}());
exports.Contactinfo = Contactinfo;
var Otherinfo = /** @class */ (function () {
    function Otherinfo() {
    }
    return Otherinfo;
}());
exports.Otherinfo = Otherinfo;
