<app-header></app-header>

<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <div class="tabs__sontent">
                <app-invioce-shared></app-invioce-shared>
                <div class="float-right invoice_btn_back">
                    <a class="backlist" [routerLink]="['/my-billing']"><i  aria-hidden="true" class="fa fa-angle-left"></i> Back to Invoice List </a>
                </div>
                <div class="clearfix"></div>
                <div class="card mt-4 mb-4 ">
                    <div class="card-body p-0">
                        <div class="float-right invoice_btn">
                            <!-- <button type="submit" class="btn btn-primary">View Invoice</button> -->
                            <!-- <button type="submit" class="btn btn-danger" (click)="captureScreen()">Download Invoice</button>
                             <button type="submit" class="btn btn-warning">Send to Email</button> -->
                        </div>
                        <div class="clearfix"></div>
                        <div id="contentToConvert">
                            <div class="row p-2 pr-4 pl-4 pt-3">
                                <div class="col-md-6">
                                    <img src="https://complygate.s3.amazonaws.com/dev/employee/2/1588290438622_MartinSantiago.png">
                                </div>
                                <div class="col-md-6 text-right billinfodd">
                                    <h4>Complygate Limited</h4>
                                    <p class="text-muted mb-1">4th Floor Colmore Gate,  2-6 Colmore Row</p>
                                    <p class="text-muted mb-1">Birmingham, Uk - B3 2QD</p>
                                    <p class="text-muted mb-1">VAT: GB 311 1059 64</p>
                                </div>
                            </div>
                            <hr>
                            <div class="row p-2 pr-5 pl-5 pt-3">
                                <div class="invoice_detailsshow">
                                        <div class="payconfirm"  *ngIf="companylocationlist">
                                            <h4 class="head">Client Information</h4>
                                            <p class="mb-1">{{companylocationlist.address1}} </p>
                                            <p *ngIf="companylocationlist.address2 !=''">{{ companylocationlist.address2 }}</p>
                                            <p class="mb-1">{{ companylocationlist.cityname }}, {{ companylocationlist.statename }}</p>
                                            <p class="mb-1">{{ companylocationlist.country.countryname }} - {{ companylocationlist.pincode }}</p>
                                        </div>
                                         <div class="invioceidshow"  *ngIf="invoicelist">
                                            <p class="mb-1"><span class="invoinumber">{{invoicelist.number}}</span></p>
                                            <p class="mb-1"> <span class="inviodate">{{ timestamp(invoicelist.created) | date: 'dd MMM yyyy' }}</span></p>
                                            <p class="mb-1" *ngIf="invoicelist.paid == true"> <span class="paid badge badge-danger"> Paid</span></p>
                                            <p class="mb-1" *ngIf="invoicelist.paid == false"><span class="unpaid badge badge-danger"> Unpaid</span></p>
                                        </div>
                                </div>
                            </div>

                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table tablebor">
                                            <thead>
                                                <tr>
                                                    <td class="weff"><strong>Item Name</strong></td>
                                                    <td class="text-xs-left width_table"><strong>Item Price</strong></td>
                                                    <td class="text-xs-left width_table"><strong>Item Quantity</strong></td>
                                                    <td class="text-right width_table"><strong>Total</strong></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ invoicelist.lines.data[0].plan.name}}</td>
                                                    <td class="text-xs-left">£ {{invoicelist.amount_due*.01}}</td>
                                                    <td class="text-xs-left">1</td>
                                                    <td class="text-right">£ {{invoicelist.amount_due*.01}}</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td class="text-xs-left">&nbsp;</td>
                                                    <td class="text-xs-left">&nbsp;</td>
                                                    <td class="text-right">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td class="text-xs-left">&nbsp;</td>
                                                    <td class="text-xs-left">&nbsp;</td>
                                                    <td class="text-right">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td class="text-xs-center"></td>
                                                    <td class="text-xs-center"></td>
                                                    <td class="text-xs-right"></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td class="text-xs-center"></td>
                                                    <td class="text-xs-center"></td>
                                                    <td class="text-xs-right"></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td class="text-xs-left"></td>
                                                    <td class="text-xs-left"></td>
                                                    <td class="text-xs-right"></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td class="text-xs-left"></td>
                                                    <td class="text-xs-left"></td>
                                                    <td class="text-xs-right"></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td class="text-xs-left"></td>
                                                    <td class="text-xs-left"></td>
                                                    <td class="text-xs-right"></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td class="text-xs-left"></td>
                                                    <td class="text-xs-left"></td>
                                                    <td class="text-xs-right"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="row pt-3">
                                        <div class="col-md-8">
                                            &nbsp;
                                            <!-- <div class="card-header colorchange">Other Comments</div>
                                          <div class="card-block boder_card p-4">
                                              <ul>
                                                  <li>What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's </li>
                                                  <li>simply dummy text of the printing and typesetting industry Lorem I </li>
                                                  <li>Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has </li>
                                                  <li>If you have any questions about this invoice, Please Contact </li>
                                              </ul>
                                          </div> -->

                                        </div>
                                        <div class="col-md-4">
                                            <table class="table tbreport">
                                                <tr>
                                                    <td ><strong>Subtotal</strong></td>
                                                    <td style="font-weight: bold;">£ {{invoicelist.amount_due*.01}}.00</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Tax</strong></td>
                                                    <td style="font-weight: bold;">00.00</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Total</strong></td>
                                                    <td style="font-weight: bold;">£ {{invoicelist.amount_due*.01}}.00</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <!-- <tr>
                                    <td class="emptyrow text-left"><strong>Taxable</strong></td>
                                    <td class="emptyrow text-right" colspan="2">$20</td>
                                </tr>
                                 <tr>
                                    <td class="emptyrow text-left"><strong>Tax Rate</strong></td>
                                    <td class="emptyrow text-right" colspan="2">6.20%</td>
                                </tr>
                                 <tr>
                                    <td class="emptyrow text-left"><strong>Tax Due</strong></td>
                                    <td class="emptyrow text-right" colspan="2">$21.56</td>
                                </tr>
                                <tr>
                                    <td class="emptyrow text-left"><strong>Other</strong></td>
                                    <td class="emptyrow text-right" colspan="2">-</td>
                                </tr>-->
                                    <!-- <tr> 
                                    <td class="emptyrow text-left"><strong class="bold">Total</strong></td>
                                    <td class="emptyrow text-right" colspan="2"><strong class="bold">£ {{invoicelist.amount_due*.01}}.00</strong></td>
                                </tr> -->

                                    <!-- </table> -->
                            </div>
                            <div class="bg-dark text-white p-4 mt-6">
                                <p class="text-center query">If you have any questions about this invoice, please contact </p>
                                <p class="text-center emphna"><span>info@complygate.co.uk</span> </p>
                                <p class="text-center emphna">+44 121 655 0311</p>
                                <h4 class="text-center"><i>Thank You For Your Business</i></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>