
<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper mt-1">
      <div class="container">
<form [formGroup]="passformGroup" #passportForm="ngForm" class="s12 white docvisapass" *ngIf="passportDetail">

<div class="check_verification">
    <div class="wizadlineshow mt-2">
        <div class="wizard_img_box"  *ngIf="wizard != 5">
            <div class="wizard_img" [class.active]="wizard == 1 || wizard == 2 || wizard == 3 || wizard == 4 || wizard == 5">
                <img src="../../../../../assets/img/icon/1214.png">
                <!-- <img class="widthset" [src]="imgURL" *ngIf="imgURL"> -->
            </div>
            <div class="wizard_img" [class.active]="wizard == 2 || wizard == 3 || wizard == 4 || wizard == 5">
                <img src="../../../../../assets/img/icon/1214.png">
                <!-- <img class="widthset" [src]="imgbackURL" *ngIf="imgbackURL">   -->
            </div>
            <div class="wizard_img"  [class.active]="wizard == 3 || wizard == 4 || wizard == 5"> 
                <img src="../../../../../assets/img/icon/1231.png">
                <!-- <img class="widthuser" [src]="profile_pic_url" *ngIf="profile_pic_url" > -->
            </div>
            <div class="wizard_img"  [class.active]="wizard == 4 || wizard == 5"> 
                <img src="../../../../../assets/img/icon/1111.png" >                               
            </div>
        </div>
      <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" ngModel="{{passportDetail.employeepassdeatilid}}">
      <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" ngModel="{{passportDetail.employeeid}}">

        <div id="wizard-1" *ngIf="wizard == 0" class="wizard_details">
            <div class="container">
                <div class="main-banner">
                    <div class="overviewsshow check_support mt-1">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="header">
                                    <h1 class="headprofile mt-0 mb-0">Add Passport Details</h1>                                   
                                </div>
                                <hr/>
                            </div>
                            <div class="col-sm-12">
                              <div class="row">
                                <div class="form-group col-lg-6">
                                    <label>Passport No.*</label>
                                    <input type="text" formControlName="passportnumber" name="passportnumber" 
                                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.passportnumber.errors }" 
                                        [(ngModel)]="passportDetail.passportnumber" id="passportnumber" maxlength="50"
                                        placeholder="Passport No.">
                                    <div *ngIf="submitted && f.passportnumber.errors" class="invalid-feedback">
                                        <div *ngIf="f.passportnumber.errors.required">Please enter passport no.</div>
                                    </div>
                                </div>
                                <div class="form-group col-lg-6">
                                    <label>Nationality*</label>
                                    <select style="width:100%;" formControlName="nationalityid" name="nationalityid" id="nationalityid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }" [(ngModel)]="passportDetail.nationalityid">
                                    <option [value]="defaultVal">Select Country</option>
                                    <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
                                    </select>
                                    <div *ngIf="submitted && f.nationalityid.errors" class="invalid-feedback">
                                        <div *ngIf="f.nationalityid.errors.required">Please select nationality</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
    
                                <div class="form-group col-lg-6">
                                    <label>Passport Issue Date*</label>
                                    <mat-form-field class="datecontrol" [ngClass]="{ 'is-invalid': submitted && f.passportissue.errors }">
                                        <input matInput [matDatepicker]="picker1" name="passportissue" id="passportissue" formControlName="passportissue" class="form-control" [(ngModel)]="passportDetail.passportissue">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="submitted && f.passportissue.errors" class="invalid-feedback">
                                        <div *ngIf="f.passportissue.errors.required">Please choose passport issue date</div>
                                    </div>
                                </div>
    
                                <div class="form-group col-lg-6">
                                    <label>Passport Expiry Date*</label>
                                    <mat-form-field class="datecontrol" [ngClass]="{ 'is-invalid': submitted && f.passportexpiry.errors }">
                                        <input class="form-control" matInput [matDatepicker]="picker2" id="passportexpiry" formControlName="passportexpiry" name="passportexpiry" [min]="passformGroup.controls.passportissue.value" [(ngModel)]="passportDetail.passportexpiry">
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="submitted && f.passportexpiry.errors" class="invalid-feedback">
                                        <div *ngIf="f.passportexpiry.errors.required">Please choose passport expiry date</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-lg-6">
                                    <label>Place of Issue of Passport*</label>
                                    <input type="text" formControlName="placeofissue" id="placeofissue" 
                                        name="placeofissue" class="form-control" maxlength="50"
                                        [ngClass]="{ 'is-invalid': submitted && f.placeofissue.errors }" 
                                        [(ngModel)]="passportDetail.placeofissue" placeholder="Place of Issue of Passport">
                                    <div *ngIf="submitted && f.placeofissue.errors" class="invalid-feedback">
                                        <div *ngIf="f.placeofissue.errors.required">Please enter place of issue of passport</div>
                                    </div>
                                </div>                               
                            </div>
                            <hr/>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 aligncenter">
                                        <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                        <div class="next text-right">
                                            <button type="button" class="btn btn-primary com_btn" (click)="toggle('1')">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="wizard-2"  *ngIf="wizard == 1"  class="wizard_details">
            <div class="container">
                <div class="main-banner">
                    <div class="overviewsshow check_support mt-1">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="header">
                                    <h1 class="headprofile mt-0 mb-0">Upload Front Side Passport Image</h1>                                    
                                </div>
                                <hr/>
                            </div>

                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="imgcent col-lg-12 col-md-12 text-center" *ngIf="imgURL">
                                        <img class="width50 p-4" [src]="imgURL"  style="max-height: 300px;" >
                                    </div>
                                </div>
                                <div class="row">                             
                                    <div class="col-lg-12">
                                        <div class="input-group" (click)="file.click()">                                     
                                            <div class="custom-file checkvisa">
                                                <input type="file" #file (change)="selectFile($event)">
                                                <span class="lnr lnr-upload"></span>
                                                <label class="custom-file-label">
                                                    Browse Image
                                                </label>
                                            </div>
                                        </div>
                                        <div class="invalid-feedback" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                        <div class="invalid-feedback" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png)</div>
                                        
                                    </div>
                                </div>
                            </div>

                          <!-- <div class="col-sm-6">
                                <button type="submit" class="btn btn-success camerashap"><span class="lnr lnr-camera"></span> <span class="text">Capture Your Pic</span></button>
                          </div>  -->
                        </div>
                      
                        <hr/>
                        <div class="row">                          
                            <div class="col-lg-12 col-md-12 text-right aligncenter">
                                <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                <div class="next">
                                    <button type="submit" (click)="toggle('0')" class="btn btn-dark mr-2 com_btn">Previous</button>
                                    <button type="button" class="btn btn-primary com_btn" [disabled]="(imgURL) ? false : true" (click)="toggle('2')">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="wizard-2"  *ngIf="wizard == 2"  class="wizard_details">
            <div class="container">
                <div class="main-banner">
                    <div class="overviewsshow check_support mt-1">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="header">
                                    <h1 class="headprofile mt-0 mb-0">Upload Back Side Passport Image</h1>
                                </div>
                                <hr/>
                            </div>
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="imgcent col-lg-12 col-md-12 text-center" *ngIf="imgbackURL">
                                        <img class="width50 p-4" [src]="imgbackURL"  >
                                    </div>   
                                </div>
                                <div class="row">                             
                                    <div class="col-lg-12">
                                        <div class="input-group" (click)="file1.click()">                                     
                                            <div class="custom-file checkvisa">
                                                <input type="file" #file1 (change)="selectbackFile($event)">
                                                <span class="lnr lnr-upload"></span>
                                                <label class="custom-file-label">
                                                    Browse Image
                                                </label>
                                            </div>
                                        </div>
                                        <div class="invalid-feedback" *ngIf="filebacksizeerror == true">File size must be less than 2 MB</div>
                                        <div class="invalid-feedback" *ngIf="filebackformaterror == true">Please choose a valid file format (jpg, png)</div>
                                        
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-6">
                                <button type="submit" class="btn btn-success camerashap"><span class="lnr lnr-camera"></span> <span class="text">Capture Your Pic</span></button>
                            </div> -->
                        </div>
                     
                        <hr/>
                        <div class="row">                          
                            <div class="col-lg-12 col-md-12 text-right aligncenter">
                                <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                <div class="next">
                                    <button type="submit" (click)="toggle('1')" class="btn btn-dark mr-2 com_btn">Previous</button>
                                    <button type="submit" (click)="toggle_skip('3')" class="btn btn-dark mr-2 com_btn">Skip</button>
                                    <button type="button" class="btn btn-primary com_btn" [disabled]="(imgbackURL) ? false : true" (click)="toggle('3')">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="wizard-23"  *ngIf="wizard == 3"  class="wizard_details">
            <div class="container">
                <div class="main-banner">
                    <div class="overviewsshow check_support mt-1">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="header">
                                    <h1 class="headprofile mt-0 mb-0">Upload Selfie</h1>
                                </div>
                                <hr/>
                            </div>
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="imgcent col-lg-12 col-md-12 text-center" *ngIf="profile_pic_url">
                                        <img class="width50 p-4" [src]="profile_pic_url"  >
                                    </div>  
                                </div>
                                <div class="row">                             
                                    <div class="col-lg-12">
                                        <div class="input-group" (click)="pro_pic.click()">                                     
                                            <div class="custom-file checkvisa">
                                                <input type="file" #pro_pic (change)="selectprofile($event)">
                                                <span class="lnr lnr-upload"></span>
                                                <label class="custom-file-label">
                                                    Browse Image
                                                </label>
                                            </div>
                                        </div>
                                        <div class="invalid-feedback" *ngIf="pro_pic_size_error == true">File size must be less than 2 MB</div>
                                        <div class="invalid-feedback" *ngIf="pro_pic_format_error == true">Please choose a valid file format (jpg, png)</div>
                                        
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-6">
                                <button type="submit" class="btn btn-success camerashap"><span class="lnr lnr-camera"></span> <span class="text">Capture Your Pic</span></button>
                            </div> -->
                        </div>                        
                        <hr/>
                        <div class="row">                    
                            <div class="col-lg-12 col-md-12 text-right aligncenter">
                                <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                <div class="next">
                                    <button type="submit" (click)="toggle('2')" class="btn btn-dark mr-2 com_btn">Previous</button>
                                    <button type="button" class="btn btn-primary com_btn" [disabled]="(profile_pic_url) ? false: true" (click)="toggle('4')">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="wizard-23"  *ngIf="wizard == 4"  class="wizard_details">
            <div class="container">
                <div class="main-banner">
                    <div class="overviewsshow check_support mt-1">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="header">
                                    <h1 class="headprofile mt-0 mb-0">Documents Uploaded Successfully</h1>                                   
                                </div>
                                <hr/>
                            </div>                                          
                            <div class="col-sm-12">
                                <div class="uploaded_img">
                                    <div class="img_sec">
                                        <img class="p-4" [src]="imgURL">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </div>
                                    <div class="img_sec">
                                        <img class="p-4" [src]="imgbackURL">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </div>
                                    <div class="img_sec">
                                        <img class="p-4 userself" [src]="profile_pic_url">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>                      
                        <hr/>
                        <div class="row">                          
                            <div class="col-lg-12 col-md-12 text-right aligncenter">
                                <p class="powered"><a href="https://www.complygate.co.uk/" target="_blank">Powered by Complygate</a></p>
                                <div class="next">
                                    <button type="submit" (click)="toggle('3')" class="btn btn-dark mr-2 mt-0 com_btn">Previous</button>
                                    <button type="button" class="btn btn-primary com_btn"
                                    [disabled]="(profile_pic_url) ? false: true" (click)="onSubmit(passportForm.value)">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="wizard-4"  *ngIf="wizard == 5" class="wizard_details">
            <div class="container">
                <div class="main-banner">

                    <div class="overviewsshow check_support mt-1 p-0">
                        <div class="banner-form thankbgsss">
                            <div class="row">
                                <div class="col-sm-12">
                                  <div class="tankushow">                                 
                                      <img alt="shape" src="../../../../../assets/img/thankcheck.png">
                                      <div class="detailstext">
                                          <h4>Passport Uploaded Successfully</h4>
                                          <p>You do not need to take any further action.</p>
                                              <h1>Thank You!</h1>
                                      </div>
                                  </div>
                                   
                                </div>
                                <!-- <div class="next">
                                    <button type="submit" (click)="toggle('2')" class="btn btn-dark mr-3">Previous</button>
                                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" (click)="onSubmit(visaForm.value)"/>
                                </div> -->
                            </div>
                            </div>                        
                      </div>                               
                </div>
            </div>
        </div>
        <div id="wizard-5"  *ngIf="wizard == 6" class="wizard_details">
            <div class="container">
                <div class="main-banner">
                    <div class="overviewsshow check_support mt-2">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="browser-screen-loading-content" >
                                    <div class="loading-dots dark-gray">
                                        <i></i>
                                        <i></i>
                                        <i></i>
                                        <i></i>
                                        <i></i>
                                        <i></i>                                
                                        <i></i>
                                    </div>
                                    <p>Loading</p>
                                </div>
                            </div>
                            <!-- <div class="next">
                                <button type="submit" (click)="toggle('2')" class="btn btn-dark mr-3">Previous</button>
                                <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" (click)="onSubmit(visaForm.value)"/>
                            </div> -->
                        </div>
                    </div>               
                </div>
            </div>
        </div>
    </div>
</div>
</form>
</div>
</div>
</div>