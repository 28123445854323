<div class="thanku">
    <app-header>
    </app-header>
    <div class="ai_info">
        <div class="info">
            <p>Our AI is processing your request.</p>
            <p>We'll revert to you as soon as possible over email</p>
            <p> Thanks.</p>
            <!-- <p> Thanks.</p> -->
            <button type="submit"  [routerLink]="['/ai-dashboard']"  *ngIf="ctype ==0" class="btn btn-primary com_btn"><span><i class="fa fa-angle-double-left" aria-hidden="true"></i></span> CONTINUE </button>
            <button type="submit"  [routerLink]="['/check-id-verification']" *ngIf="ctype ==1" class="btn btn-primary com_btn"><span><i class="fa fa-angle-double-left" aria-hidden="true"></i></span> CONTINUE </button>
            <div class="magnifying-container-sm">
                <div class="magnifying">
                    <div class="handle"></div>
                    <div class="middle"></div>
                    <div class="top"></div>
                </div>
                <img src="../../../../../assets/img/visa2.png" style="width: 100px;">
            </div>
            <div class="progress progg">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
            </div>
        </div>
    </div>
</div>