import { Component, OnInit } from '@angular/core';
import { LeaveService } from '../../services/leave.service';
import { ModalService } from '../../_services/modal.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-leavereport',
  templateUrl: './leavereport.component.html',
  styleUrls: ['./leavereport.component.css']
})

export class LeavereportComponent implements OnInit {
  data: any [];
  Userid: any;
  month: any;
  year: any;
  yearmonth: any;
  record: any;
  classactive: string;
  notedetails : any;
  items: Array<any>;
  pageOfItems: Array<any>;
  monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  constructor(private dataset: LeaveService,private router: Router,
    private modalService: ModalService) { }

  ngOnInit() {

    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }


    this.month = (new Date()).getMonth()+1;
    this.year = (new Date()).getFullYear();
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ (new Date()).getFullYear();
    this.classactive =((new Date()).getMonth()+1).toString();    
    this.bindalldata();
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  bindalldata()
  {
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    this.dataset.getemployeeleavereport(data).subscribe((data: any) => {
      this.record = data.length;
      if(this.record < 10){
        this.pageOfItems = data;
      }
      else{
           this.items =data;
      }
      // this.data = data;
    });
  }

  onyearSubmit(leave) {
    this.year = leave;
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ this.year ;
    this.bindalldata();
   // this.modalService.close(id);
  }

  yearpop(id: string){
    this.modalService.open(id);
  }

  closeModal(id: string){
    this.modalService.close(id);
  }

  getDataByMonth(mon)
  {
    this.classactive =mon;
    this.month = mon;
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ this.year ;
    this.bindalldata();
  }
  openModalnote (data:any,id:any){
    this.notedetails = data;
    this.modalService.open(id);
  }

  getDataByYear(yea)
  {    
    this.year = yea;
    this.yearmonth = this.monthNames[this.month -1 ] +' '+ this.year ;
    const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    this.dataset.getemployeeleavereport(data).subscribe((data: any) => {
      this.record = data.length;
      if(this.record < 10){
        this.pageOfItems = data;
      }
      else{
           this.items =data;
      }
      //this.data = data;
    });
  }
}