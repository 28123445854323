import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AicenterService } from './../../services/aicenter.service';
import { AlertService } from '../../services/alert.service';
import { ModalService } from '../../_services/modal.service';

@Component({
  selector: 'app-documentapprovereject',
  templateUrl: './documentapprovereject.component.html',
  styleUrls: ['./documentapprovereject.component.css']
})
export class DocumentapproverejectComponent implements OnInit {

  id: any;
  profile: any;
  frontimage: any;
  data:any;
  backimage: any;
  buttonshow: any;
  mode:any=''; imgidproofsrc: any;
  vs:any;iframeidproofsrc:any;
  buttonsb:any=0;
  token_expired:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private aiservice: AicenterService,
    private alerts: AlertService,    
    private modalService: ModalService,
  ) { }

  ngOnInit() {

    this.buttonshow = 'no';
    this.id = this.activatedRoute.snapshot.paramMap.get("id");

    this.aiservice.getDocumentImages({'id': this.id}).subscribe((response: any) => { 

    // this.aiservice.getDocumentImages({'id': this.id}).subscribe(function(response){

       this.buttonshow = 'Yes';

       this.mode =response.documentty;
      this.vs = response.document;

      console.log(response.success);
      // if(response.success){
        this.data = response;
       
        this.profile = response.profile;
        this.frontimage = response.frontimage;
        this.backimage = response.backimage;
        // this.buttonshow = true;
       this.token_expired = response.dstatus;


        console.log(this.profile);
        console.log(this.frontimage);
        console.log(this.backimage);
      // } else{
      //   this.buttonshow = false;
      // }
    });
  }

  approvedoc(){

    this.aiservice.approveAiDocument({'jumioIdScanReference': this.id, 'verificationStatus': 'APPROVED_VERIFIED'}).subscribe((data: any) => { 
    
    // this.aiservice.approveAiDocument({'jumioIdScanReference': this.id, 'verificationStatus': 'APPROVED_VERIFIED'}).subscribe(function(data){
     
      this.token_expired = 2;
      console.log(data); 
       this.alerts.success("Document has been approved successfully"); 
    });
   
  }

  rejectdoc(){

    this.aiservice.approveAiDocument({'jumioIdScanReference': this.id, 'verificationStatus': ''}).subscribe((data: any) => { 
   

    // this.aiservice.approveAiDocument({'jumioIdScanReference': this.id, 'verificationStatus': ''}).subscribe(function(data){
     
      this.token_expired = 3;
       this.alerts.success("Document has been rejected successfully");
      console.log(data);
    });
   
  }

  openModalidproof(backimg:any) 
  {
        this.imgidproofsrc = true;
        this.iframeidproofsrc = backimg;
        this.modalService.open('pdf-image-viewer-myidproofsystem');
  }
  closeModal(id: string) 
  {   
    this.modalService.close(id);
  }
}
