"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var checksystem_service_1 = require("../../services/checksystem.service");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var plan_service_1 = require("../../services/plan.service");
var payment_service_1 = require("../../services/payment.service");
var plan_model_1 = require("../../models/plan.model");
var modal_service_1 = require("../../_services/modal.service");
var stripe_angular_1 = require("stripe-angular");
var alert_service_1 = require("../../services/alert.service");
var user_service_1 = require("../../services/user.service");
var company_service_1 = require("../../services/company.service");
var app_global_1 = require("../../app.global");
var CheckbillingComponent = /** @class */ (function () {
    function CheckbillingComponent(check, router, formBuilder, modalService, alerts, plan, userService, companyService, StripeScriptTag, paymentService) {
        this.check = check;
        this.router = router;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.alerts = alerts;
        this.plan = plan;
        this.userService = userService;
        this.companyService = companyService;
        this.StripeScriptTag = StripeScriptTag;
        this.paymentService = paymentService;
        this.publishableKey = app_global_1.AppGlobals.API_STRIPE;
        this.planDetail = new plan_model_1.Plan();
    }
    CheckbillingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.downloadcheckinvoicetext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var isadmin = localStorage.getItem('isadmin');
        if (isadmin != 'true') {
            this.router.navigate(['/permission-denied']);
        }
        this.check.getAllInvoice().subscribe(function (data) {
            _this.record = data.invoicelist.length;
            _this.pageOfItems = data.invoicelist;
            _this.companyLocation = data.companylocation;
            _this.companyInfo = data.company;
            if (data.invoicelist.length) {
                _this.checkInvoiceDetails = data.invoicelist[0];
            }
            if (_this.record < 5) {
                _this.pageOfItems = data.invoicelist;
            }
            else {
                _this.items = data.invoicelist;
            }
            // console.log(data);
            // this.invoicelist =data;
        });
        this.StripeScriptTag.setPublishableKey(this.publishableKey);
        this.timestamp = function (epoch) {
            return (epoch * 1000);
        };
        // this.planformGroup = this.formBuilder.group({
        //   coupnsnumber: ['', Validators.required]
        // });
        var isemail = localStorage.getItem('isemail');
        this.planid = localStorage.getItem('planid');
        // this.companyService.getinvoicelist().subscribe((data: any) => {        
        //     this.invoicelist = data;
        //     // console.log(this.invoicelist);
        //    });
        this.companyService.gettotalcards().subscribe(function (data) {
            _this.cardlist = data;
        });
        //       this.companyService.getnextbillingdate().subscribe((data: any) => {
        //            this.billinglist = data;
        //           });
    };
    CheckbillingComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    CheckbillingComponent.prototype.getCheckInvoiceData = function (invoiceid) {
        var _this = this;
        // this.checkInvoiceDetails = undefined;
        this.downloadcheckinvoicetext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
        var data = { 'invoiceid': invoiceid };
        this.check.getAllInvoicedetails(data).subscribe(function (invoiceData) {
            _this.checkInvoiceDetails = invoiceData;
        });
    };
    CheckbillingComponent.prototype.downloadCheckInvoice = function (invoiceid) {
        var _this = this;
        this.downloadcheckinvoicetext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        var data = { 'invoiceid': invoiceid };
        this.check.downloadCheckInvoice(data).subscribe(function (invoiceData) {
            _this.downloadCheckInvoiceData = invoiceData;
            _this.downloadcheckinvoicetext = 'Download <i class="fa fa-file-pdf-o ml-2"></i>';
            window.open(_this.downloadCheckInvoiceData.response_data.Location, "_blank");
        });
    };
    return CheckbillingComponent;
}());
exports.CheckbillingComponent = CheckbillingComponent;
