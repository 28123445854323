<div class="bg">
    <app-header></app-header>
    <div class="section-wrapper">
        <div class="container">
            <div class="tabs" id="tabs">
                <div class="tabs__content">
                    <div class="tabs__content__item active-tab">
                        <div class="content">
                            <div class="space_em row">
                                <div class="col-lg-6">
                                    <h5 class="hedingcolor">Asset List <a (click)="openModal2('custom-asset-1')" class="mainheading_btn " *ngIf="assetperm > 1"> + Add New </a></h5>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group">
                                        <input [(ngModel)]="searchText" placeholder="Search.." class="form-control">
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="( datalist |  filter:{serialnumber: searchText,assetdescption: searchText,categoryname: searchText }) as pageOfItems">
                      
                        

                            <div class="job_list_section visa_request_section" *ngFor="let asset of pageOfItems;  let i=index">
                                <div class="job_detils_date width22">
                                    <div class="visa_user_details ">
                                        <h4>{{asset.employee.fname}} {{asset.employee.mname}} {{asset.employee.lname}}</h4>
                                        <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"> </i>{{ asset.employee.emailid }}
                                        </p>
                                        <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i> {{ asset.employee.phonenumber }}</p>
                                        <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i>{{ asset.employee.ninumber }} </p>
                                        <span class="badge badge-success" *ngIf="asset.employee.nationality">{{ asset.employee.nationality.countryname }}</span>
                                    </div>
                                </div>
                                <div class="job_detils_date asslist width13">
                                    <p class="job_profile">Serial Number</p>
                                    <p class="job_address">{{(asset.serialnumber)?asset.serialnumber:'NA'}}</p>
                                </div>
                                <div class="job_detils_date asslist width20">
                                    <p class="job_profile">Description</p>
                                    <p class="job_address pr-2">{{ asset.assetdescption }}</p>
                                </div>
                                <div class="job_detils_date asslist width10">
                                    <p class="job_profile">Category Name</p>
                                    <p class="job_address" *ngIf="asset.category">{{ asset.category.categoryname }}</p>
                                    <p class="job_address" *ngIf="!asset.category"></p>
                                </div>
                                <div class="job_detils_date asslist width10">
                                    <p class="job_profile">Allocation Date</p>
                                    <p class="job_address"> {{ asset.statdate | date: 'dd MMM yyyy' }}</p>

                                </div>
                                <div class="job_detils_date asslist width10">
                                    <p class="job_profile">Date Returned</p>
                                    <p class="job_address" *ngIf="!asset.enddate">No Data</p>
                                    <p class="job_address" *ngIf="asset.enddate"> {{ asset.enddate | date: 'dd MMM yyyy' }}</p>
                                </div>
                                <div class="job_detils_date asslist visa_btn buttonwidth">
                                    <p class="job_address">
                                        <button type="submit" class="btn btn-primary com_btn" *ngIf="assetperm > 2" (click)="openModal('custom-asset-1',asset)">
                                            <div class="tooltip_visa">
                                                <p >Edit</p>
                                            </div>
                                            <i class="fa fa-pencil"></i>
                                        </button>
                                    </p>
                                </div>
                            </div>

                            <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>
                        </ng-container>
                            <div class="job_list_section visa_request_section" *ngIf="record === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>


                            <!-- <div class="row">
                                <div class="col-lg-12 text-right">
                                    <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<jw-modal id="custom-asset-1" role="dialog" class="modal">
    <form [formGroup]="assetForm" #Assetform="ngForm" class="s12 white" (ngSubmit)="onSubmit(Assetform.value,'custom-asset-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" formControlName="id" class="form-control" name="id" id="id" [(ngModel)]="assetDetail.id">
                    <h5 class="modal-title" id="popupLabel">Asset Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-asset-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Employee Name*</label>
                                <select style="width:100%;" formControlName="employeeid" name="employeeid" id="employeeid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.employeeid.errors }" [(ngModel)]="assetDetail.employeeid">
                                    <option [value]="emptyValue" >Select Employee Name </option>
                                    <option *ngFor="let emp of employeelist" [value]="emp.employeemasterid">
                                    {{ emp.fname }} {{ emp.mname }} {{ emp.lname }}</option>
                                </select>
                                <div *ngIf="submitted && f.employeeid.errors" class="invalid-feedback">
                                    <div *ngIf="f.employeeid.errors.required">Please select employee name</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Asset Category*</label>
                                <select style="width:100%;" formControlName="categoryid" name="categoryid" id="categoryid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.categoryid.errors }" [(ngModel)]="assetDetail.categoryid">
                                    <option [value]="emptyValue">Select Asset Category </option>
                                    <option *ngFor="let ass of assetcategorylist" [value]="ass.id">{{ ass.categoryname }}</option>
                                </select>
                                <div *ngIf="submitted && f.categoryid.errors" class="invalid-feedback">
                                    <div *ngIf="f.categoryid.errors.required">Please select asset category</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Asset Description*</label>
                                <input type="text" formControlName="assetdescption"   [maxlength]="100" name="assetdescption" id="assetdescption" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.assetdescption.errors }" [ngModel]="assetDetail.assetdescption" [maxlength]="50">
                                <div *ngIf="submitted && f.assetdescption.errors" class="invalid-feedback">
                                    <div *ngIf="f.assetdescption.errors.required">Please enter asset description</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Serial Number </label>
                                <input type="text" formControlName="serialnumber" [maxlength]="30" name="serialnumber" id="serialnumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.serialnumber.errors }" [ngModel]="assetDetail.serialnumber">
                                <div *ngIf="submitted && f.serialnumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.serialnumber.errors.required">Please enter serial number</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Allocation Date*</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker1" name="statdate" id="statdate" class="form-control noborder-invalid" [ngClass]="{ 'is-invalid': submitted && f.statdate.errors }" formControlName="statdate" [ngModel]="assetDetail.statdate">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.statdate.errors" class="invalid-feedback">
                                    <div *ngIf="f.statdate.errors.required">Please choose allocation date</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Return Date </label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker2" formControlName="enddate" class="form-control datecontrol noborder-invalid" id="enddate" [ngClass]="{ 'is-invalid': submitted && f.enddate.errors }" [min]="assetForm.controls.statdate.value" name="enddate" [ngModel]="assetDetail.enddate">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>

                                </mat-form-field>
                                <div *ngIf="submitted && f.enddate.errors" class="invalid-feedback">
                                    <div *ngIf="f.enddate.errors.required">Please choose returned date</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />
                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-asset-1');" />
                </div>
            </div>
        </div>
    </form>
</jw-modal>