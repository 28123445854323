import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unsupported-country',
  templateUrl: './unsupported-country.component.html',
  styleUrls: ['./unsupported-country.component.css']
})

export class UnsupportedCountryComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}