<style>
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
  .modal-footer {border-top: 0px;}

</style>



<div class="signin-wrapper" *ngIf="ctype == 1">
  <div class="new_login">
    <div class="login_section">
      <a class="login_logo"  [routerLink]="['/']" ><img src="/assets/img/logo.png" alt=""></a>
      <a class="header-notification float-right" data-toggle="dropdown" (click)="logout()" title="Logout"> <i
          class="fa fa-power-off"></i> </a>
      <div class="signin-box">
        <div class="login_form">
          <p>Please pay £36.00 (tax excl.) your one time sign up fee.</p>

            <p>Once the payment is made, your Complygate account will be activated & ready to use.</p>
            
          <button (click)="paymentstripe('custom-payment-1')" class="btn btn-primary btn-block btn-signin">Pay Now</button>
        </div>       
      </div>
    </div>
    <div class="graphic_section">
      <div class="slogan">
        <h4>Welcome To <b>Complygate</b></h4>
        <span>Commit. Control. Comply</span>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>


<div class="signin-wrapper" *ngIf="ctype == 2">
  <div class="new_login">
    <div class="login_section">
      <a class="login_logo"  [routerLink]="['/']" ><img src="/assets/img/logo.png" alt=""></a>
      <a class="header-notification float-right" data-toggle="dropdown" (click)="logout()" title="Logout"> <i
          class="fa fa-power-off"></i> </a>
      <div class="signin-box">
        <div class="login_form">
          <p>Please add your billing details to claim one Free Business Credit Report.</p>

          <p>Once the billing information is added, your Complygate account will be activated & ready to use.</p>
            
          <button (click)="paymentstripe('custom-payment-2')" class="btn btn-primary btn-block btn-signin">Pay Now</button>
        </div>       
      </div>
    </div>
    <div class="graphic_section">
      <div class="slogan">
        <h4>Welcome To <b>Complygate</b></h4>
        <span>Commit. Control. Comply</span>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>

<div class="signin-wrapper" *ngIf="ctype == 3">
  <div class="new_login">
    <div class="login_section">
      <a class="login_logo"  [routerLink]="['/']" ><img src="/assets/img/logo.png" alt=""></a>
      <a class="header-notification float-right" data-toggle="dropdown" (click)="logout()" title="Logout"> <i
          class="fa fa-power-off"></i> </a>
      <div class="signin-box">
        <div class="login_form">
          <p>Please add your billing details to claim Free referencing for 10 candidates.</p>

          <p>Once the billing information is added, your Complygate account will be activated & ready to use.</p>
            
          <button (click)="paymentstripe('custom-payment-2')" class="btn btn-primary btn-block btn-signin">Pay Now</button>
        </div>       
      </div>
    </div>
    <div class="graphic_section">
      <div class="slogan">
        <h4>Welcome To <b>Complygate</b></h4>
        <span>Commit. Control. Comply</span>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>



<jw-modal id="custom-payment-2" role="dialog" class="modal paycut">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <h5 class="modal-title" id="popupLabel">Payment</h5>
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
          (click)="closeModal('custom-payment-1');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <div *ngIf="invalidError" class="errormessa">
        {{ invalidError.message }}
      </div>



      <form [formGroup]="addpopForm" #addresslistForm="ngForm" class="s12 padding453 white"  *ngIf="addresslistDetail">
         
         <div class="row">
           <div class="col-sm-12">
              <h5 class="billing">Billing Information</h5>
              <hr/>
           </div>
           <div class="col-sm-12">
              <div class="row">                
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">Card Holder Name*</label>
                  <input type="text" formControlName="cardname" name="cardname" id="cardname" 
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && g.cardname.errors }" required 
                      [ngModel]="addresslistDetail.cardname" maxlength="60">
                  <div *ngIf="submitted && g.cardname.errors" class="invalid-feedback">
                      <div *ngIf="g.cardname.errors.required">Please enter card holder name</div>
                  </div>
                </div>
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">Address Line 1*</label>
                  <input type="address1" formControlName="address1" name="address1" id="address1" 
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && g.address1.errors }" required 
                      [ngModel]="addresslistDetail.address1" maxlength="60">
                  <div *ngIf="submitted && g.address1.errors" class="invalid-feedback">
                      <div *ngIf="g.address1.errors.required">Please enter address line 1</div>
                  </div>
                </div>
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">Address Line 2 </label>
                  <input type="address2" formControlName="address2" name="address2" id="address2" 
                      class="form-control" [ngModel]="addresslistDetail.address2" maxlength="60">
                </div>
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">City*</label>
                  <input type="text" formControlName="cityname" name="cityname" class="form-control" id="cityname" maxlength="30"
                      [ngClass]="{ 'is-invalid': submitted && g.cityname.errors }" required [ngModel]="addresslistDetail.cityname">
                  <div *ngIf="submitted && g.cityname.errors" class="invalid-feedback">
                      <div *ngIf="g.cityname.errors.required">Please enter city</div>
                  </div>
                </div>
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">County*</label>
                  <input type="text" formControlName="statename" name="statename" id="statename" class="form-control" 
                      [ngClass]="{ 'is-invalid': submitted && g.statename.errors }" required 
                      [ngModel]="addresslistDetail.statename" maxlength="30">
                  <div *ngIf="submitted && g.statename.errors" class="invalid-feedback">
                      <div *ngIf="g.statename.errors.required">Please enter county</div>
                  </div>
              </div>
              <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">Postcode*</label>
                <input type="text" formControlName="pincode" name="pincode" class="form-control" id="pincode" maxlength="10"
                    [ngClass]="{ 'is-invalid': submitted && g.pincode.errors }" required [ngModel]="addresslistDetail.pincode">
                <div *ngIf="submitted && g.pincode.errors" class="invalid-feedback">
                    <div *ngIf="g.pincode.errors.required">Please enter postcode</div>
                </div>
              </div>
              <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">Country*</label>
                <select style="width:100%;" formControlName="countryid" class="form-control input-lg" id="countryid" name="countryid" [ngClass]="{ 'is-invalid': submitted && g.countryid.errors }"  [ngModel]="addresslistDetail.countryid">
                  <option [value]="defaultVal">Select Country</option>
                  <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
              </select>
                <div *ngIf="submitted && g.countryid.errors" class="invalid-feedback">
                    <div *ngIf="g.countryid.errors.required">Please select country</div>
                </div>
            </div>
            <div class="form-group mb-2 col-lg-6 llb">
              <label class="mb-0">Tax ID</label>
              <input type="text" formControlName="taxid" name="taxid" class="form-control" id="taxid" maxlength="10"
                   [ngModel]="addresslistDetail.taxid">            
              </div>  
              </div>
           </div>
           <div class="col-sm-12">
            <div class="payment_strip_new payment_strip ">
              <label>Please enter your card details</label>                
              <stripe-card #stripeCard (catch)="onStripeError($event)" [(invalid)]="invalidError"
              (tokenChange)="setStripeToken($event,addresslistForm.value, 'custom-payment-1')"></stripe-card>
            </div>
            <div class="optionss" *ngIf="!editCard">
              <img src="assets/img/paycard.png">     
            </div>
           
           </div>
         </div>


        
        

      <div class="modal-footer">
        <button type="button" class="btn btn-primary com_btn" (click)="stripeCard.createToken(extraData)">Pay Now</button>
        <button type="button" class="btn btn-dark com_btn" (click)="closeModal('custom-payment-2');">Cancel</button>
      </div>


      <div class="browser-screen-loading-content" *ngIf="paymentloading" >
        <div class="loading-dots dark-gray">
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
        </div>
        <p>Please do not refresh or close the browser while we process your order.</p>
    </div>
    </form>


      
     
    </div>
  </div>
</jw-modal>

<style>
  .payment_strip_new {
    border: 1px solid #ddd;
    padding: 25px 13px;
    border-radius: 5px 5px;
    background: none;
    box-shadow:0;
    margin-top: 20px;
}
</style>


<jw-modal id="custom-payment-1" role="dialog" class="modal paycut">
  <div role="document" class="formgroup">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <h5 class="modal-title" id="popupLabel">Payment</h5>
        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
          (click)="closeModal('custom-payment-1');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <div *ngIf="invalidError" class="errormessa">
        {{ invalidError.message }}
      </div>



      <form [formGroup]="addpopForm" #addresslistForm="ngForm" (ngSubmit)="update_payment(addresslistForm.value)" class="s12 padding453 white"  *ngIf="addresslistDetail && !paymentloading"  >
         
         <div class="row">
           <div class="col-sm-12">
              <h5 class="billing">Billing Information</h5>
              <hr/>
           </div>
           <div class="col-sm-7">
              <div class="row">                
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">Card Holder Name*</label>
                  <input type="text" formControlName="cardname" name="cardname" id="cardname" 
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && g.cardname.errors }" required 
                      [ngModel]="addresslistDetail.cardname" maxlength="60">
                  <div *ngIf="submitted && g.cardname.errors" class="invalid-feedback">
                      <div *ngIf="g.cardname.errors.required">Please enter card holder name</div>
                  </div>
                </div>
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">Address Line 1*</label>
                  <input type="address1" formControlName="address1" name="address1" id="address1" 
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && g.address1.errors }" required 
                      [ngModel]="addresslistDetail.address1" maxlength="60">
                  <div *ngIf="submitted && g.address1.errors" class="invalid-feedback">
                      <div *ngIf="g.address1.errors.required">Please enter address line 1</div>
                  </div>
                </div>
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">Address Line 2 </label>
                  <input type="address2" formControlName="address2" name="address2" id="address2" 
                      class="form-control" [ngModel]="addresslistDetail.address2" maxlength="60">
                </div>
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">City*</label>
                  <input type="text" formControlName="cityname" name="cityname" class="form-control" id="cityname" maxlength="30"
                      [ngClass]="{ 'is-invalid': submitted && g.cityname.errors }" required [ngModel]="addresslistDetail.cityname">
                  <div *ngIf="submitted && g.cityname.errors" class="invalid-feedback">
                      <div *ngIf="g.cityname.errors.required">Please enter city</div>
                  </div>
                </div>
                <div class="form-group mb-2 col-lg-6 llb">
                  <label class="mb-0">County</label>
                  <input type="text" formControlName="statename" name="statename" id="statename" class="form-control" 
                      [ngClass]="{ 'is-invalid': submitted && g.statename.errors }" required 
                      [ngModel]="addresslistDetail.statename" maxlength="30">
                  <!-- <div *ngIf="submitted && g.statename.errors" class="invalid-feedback">
                      <div *ngIf="g.statename.errors.required">Please enter county</div>
                  </div> -->
              </div>
              <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">Postcode*</label>
                <input type="text" formControlName="pincode" name="pincode" class="form-control" id="pincode" maxlength="10"
                    [ngClass]="{ 'is-invalid': submitted && g.pincode.errors }" required [ngModel]="addresslistDetail.pincode">
                <div *ngIf="submitted && g.pincode.errors" class="invalid-feedback">
                    <div *ngIf="g.pincode.errors.required">Please enter postcode</div>
                </div>
              </div>
              <div class="form-group mb-2 col-lg-6 llb">
                <label class="mb-0">Country*</label>
                <select style="width:100%;" formControlName="countryid" class="form-control input-lg" id="countryid" name="countryid" [ngClass]="{ 'is-invalid': submitted && g.countryid.errors }"  [ngModel]="addresslistDetail.countryid">
                  <option [value]="defaultVal">Select Country</option>
                  <option *ngFor="let ad3 of countrylist" [value]="ad3.countryid">{{ ad3.countryname }}</option>
              </select>
                <div *ngIf="submitted && g.countryid.errors" class="invalid-feedback">
                    <div *ngIf="g.countryid.errors.required">Please select country</div>
                </div>
            </div>
            <div class="form-group mb-2 col-lg-6 llb">
              <label class="mb-0">Tax ID</label>
              <input type="text" formControlName="taxid" name="taxid" class="form-control" id="taxid" maxlength="10"
                   [ngModel]="addresslistDetail.taxid">            
              </div>  
              </div>
           </div>
           <div class="col-sm-5">
            
             <div class="coupon_box_code" >
              <p *ngIf="couponerror" class="couponerror">Coupon Code invalid</p>
              <p *ngIf="coupon_applied" class="couponerror_green">Coupon Applied successfully</p>
               <div class="copon_delps" style="display: none;">
                <input type="text" formControlName="coupon" class="form-control norlbordr" placeholder="Enter Coupon code">              
                <button type="button" *ngIf="!coupon_applied" class="btn btn-primary" (click)="checkCoupon(addpopForm.controls.coupon.value)">Apply</button>
                <button type="button" *ngIf="coupon_applied" class="btn btn-danger" (click)="cancelCoupon()">Cancel</button>
              </div>
            
              
            
              <div class="paymentpay_option">
                <p>Payment Amount</p>
                <p>£36.00</p>
              </div>
              <div *ngIf="coupon_applied" class="paymentpay_option">
                <p>Coupon Discount  </p>
                <p>£{{discount/100 | number : '1.2-2'}}</p>
              </div>

              <div class="paymentpay_option" *ngIf="coupon_applied == false">
                <p>VAT Amount</p>
                <p>£6.00</p>   
              </div>
              <div class="paymentpay_option" *ngIf="coupon_applied == true">
                <p>VAT Amount</p>
                <p>£3.00</p>   
              </div>


              <div class="paymentpay_option">
                <p>Total Payable Amount  </p>
                <p>£{{(coupon_applied)?((36.00-discount/100)+3 | number : '1.2-2'):43.20}}</p>
              </div>
              
          </div>  
            <!-- <div class="payment_strip">
              <label>Please enter your card details</label>                
              <stripe-card #stripeCard (catch)="onStripeError($event)" [(invalid)]="invalidError"
              (tokenChange)="setStripeToken($event,addresslistForm.value, 'custom-payment-1')"></stripe-card>
            </div> -->

          

            <div class="optionss" *ngIf="!editCard">
              <img src="assets/img/paycard.png">     
            </div>
           
           </div>



         </div>


        
        

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary com_btn" >Pay Now</button>
        <button type="button" class="btn btn-dark com_btn" (click)="closeModal('custom-payment-1');">Cancel</button>
      </div>


        
    </form>

<div class="s12 padding453 white" *ngIf="paymentloading" style="min-height: 200px;">
    <div class="row"  >
      <div class="col-sm-12" style="margin-top: 100px;">
        <div class="browser-screen-loading-content" >
          <div class="loading-dots dark-gray">
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
          </div>
          <p>Please do not refresh or close the browser while we process your order.</p>
      </div>
        </div>
    </div>
</div>

      
     
    </div>
  </div>
</jw-modal>


<div class="clearfix">&nbsp;</div>
<!-- <div class="browser-screen-loading-content" *ngIf="paymentloading" >
    <div class="loading-dots dark-gray">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
    </div>
    <p>Please do not refresh or close the browser while we process your order.</p>
</div> -->



<jw-modal id="custom-payment-25" role="dialog" class="modal">
  <div role="document" class="formgroup" *ngIf="iframepaymentautsrc">
      <div class="modal-content">
          <div class="modal-body p-0">
              <div class="container">
                <div class="info_mess">
                    <div class="para">
                            <h4 class="mb-2">Please do not refresh or close the browser while we process your order.</h4>
                            <!-- <div class="card_strip_option">                                 -->
                              <iframe  [src]="iframepaymentautsrc" width="900" height="450" frameborder="0" style="width:900px"></iframe>
                          <!-- </div>                           -->
                        </div>
                    </div>
              </div>
          </div>          
      </div>
  </div>
</jw-modal>