import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { CustomerService } from '../../services/customer.service';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})

export class ForgotpasswordComponent implements OnInit {
  user = new User();
  forgotPassword: FormGroup;
  isLoginError = false;
  submitted = false;
  msg: string = null;
  access_token = '';
  error: string;
  passwordForm: FormGroup;
  accesstoken: string;
  checktoken: any;
  // confirmerror: string;
  constructor(private formBuilder: FormBuilder, private csdata: CustomerService, private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute, private alerts: AlertService) {
  }

  ngOnInit() {
    this.forgotPassword = this.formBuilder.group({
      newPassword: [null, [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      confirmPassword: [null, [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]]
    }, { validator: this.checkPasswords });

    this.accesstoken = this.activatedRoute.snapshot.paramMap.get("access_token");
    this.activatedRoute.queryParams.subscribe(params => {
      this.accesstoken = params['access_token'];
      this.userService.checkaccesstoken(this.accesstoken).subscribe((data: any) => {
        console.log(this.accesstoken);
        if (data == null) {
          this.alerts.error('Invalid token details', true);
         // console.log(this.accesstoken);
          this.checktoken = false;
        } else {
          this.checktoken = true;
        }
      });
      //  this.page = +params['page'] || 0;
    });
    // this.alerts.success(this.accesstoken);
    //  console.log(this.accesstoken +'rahul');
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.newPassword.value;
    let confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true }
  }

  get f() { return this.forgotPassword.controls; }

  forgotpassword(value2: any) {
    this.submitted = true;
    if (this.forgotPassword.invalid) {
      return;
    }

    var newPassword = value2.newPassword;
    this.access_token = this.activatedRoute.snapshot.paramMap.get("access_token");
    this.activatedRoute.queryParams.subscribe(params => {
      this.access_token = params['access_token'];
      this.userService.forgotPassword(newPassword, this.access_token).subscribe((data: any) => {

        if(data.success == 'true'){
        // this.csdata.updatepassword().subscribe((data2: any) => {
        this.alerts.success('Password has been reset successfully', true);
        this.router.navigate(['/login']);
        this.msg = 'Password has been reset successfully';
        }
        else
        {
          this.alerts.error('Unable to update password', true);
         // this.router.navigate(['/login']);
          this.msg = 'Unable to update password';
        }
        // });
      },
        (err: HttpErrorResponse) => {
          this.isLoginError = true;
          this.alerts.error('Unable to update password' + err, true);
          // this.error = 'Unable to Update Password?';
        });
    });
  }
}