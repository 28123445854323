import { Component, OnInit, Input, Output,EventEmitter,SimpleChanges  } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { CommonService } from '../../services/common.service';
import { CountryService } from '../../services/country.service';
import { JobService } from '../../services/job.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Jobs, Screen } from '../../models/jobs/jobs.model';
import { MatOptionSelectionChange } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFileService } from './../../services/upload-file.service';
import { Country } from './../../models/address/country.model';
import { Soc } from "./../../models/jobs/Soc.model";
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { DISABLED } from '@angular/forms/src/model';
import { Router } from '@angular/router';


// import {paginate} from 'jw-paginate';
// import * as paginate from 'jw-paginate';
// import  paginate = require('jw-paginate');
@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {

  @Input() items: Array<any>;
  @Output() changePage = new EventEmitter<any>(true);
  @Input() initialPage = 1;
  @Input() pageSize = 30;
  @Input() maxPages = 10;
  pager: any = {};
  pageOfItems: Array<any>;

  br1: any;
  br2: any;
  br3: any;
  br4: any;

  mainawaiting: number=0;
  mainreview: number=0;
  maincon: number=0;
  mainhire: number=0;

  displayedColumns = ['jobtittle'];
  Userid: any;
  joblist: any;
  datalist: any;
  jobDetail = new Jobs(); 
  jobformGroup: FormGroup;
  jobscreenformGroup: FormGroup;
  public Editor = ClassicEditor;
  mobilecheck: any;
  phonecheck: any;
  socdata: any;
  countrylist: any;
  countylist: any;
  options = [];
  buttoninsert: any;
  submitted: boolean = false;
  selectedFiles: FileList;
  filename: any;
  file1: any;
  file2: any;
  file3: any;
  file4: any;
  screenumber: any;
  screenshotid:any;
  disableday: boolean;
  iframesrc: any;
  imgsrc: any;
  screen = new Screen();
  public searchText: string;
  disableBtn: any;
  permission: any;
  jobperm: any;
  dateToday = new Date();
  defaultVal: any = "";
  record: number;
  jobst:boolean = false;

  constructor(
    private sanitizer: DomSanitizer, 
    private commonService: CommonService, 
    private modalService: ModalService, 
    private job: JobService, 
    private formBuilder: FormBuilder,
    private country: CountryService,
    private router: Router,
    private uploadService: UploadFileService
    ) { }

  ngOnInit() {


    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }


    this.createformgroup();
    this.jobDetail = new Jobs();
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.jobperm = this.permission.m7;
    this.mobilecheck='';
    this.phonecheck= '';
    this.jobDetail.country = new Country();
    this.screen = new Screen();
    this.jobDetail.soc = new Soc();
    
    this.br1 ='Choose file';
    this.br2 ='Choose file';
    this.br3 ='Choose file';
    this.br4 ='Choose file';


    this.jobscreenformGroup = this.formBuilder.group({
      id: [''],
      jobmasterid: ['', Validators.required],
      screenshotdayid: ['', Validators.required],
      joburl1: [''],
      joburl2: [''],
      joburl3: [''],
      joburl4: ['']
    });
    this.buttoninsert = "Add New Job";
    this.country.getsoclist().subscribe((socdata: any) => {
      this.socdata = socdata;
    });
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    const countrydata = { 'id': this.jobDetail.countryid };
    this.country.getstatelist(countrydata).subscribe((state: any) => {
     
      this.countylist = state;
    });
    this.Userid = localStorage.getItem('userid');
    this.job.getjobList().subscribe((data: any) => {
      this.record  = data.length;
      this.datalist = data;
      this.pageOfItems = data;
      if(this.record < 30){
        this.pageOfItems = data;
      }
      else{
        this.items =data;
      }
    });


    
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}

updatestatus(status: number){
  if(status ==1)
  {
    this.mainawaiting = this.mainawaiting +1;
  }else if(status==2)
  {this.mainreview = this.mainreview +1;}
  else if(status==5)
  { this.maincon = this.maincon +1;}
  else if(status==7)
  {this.mainhire = this.mainhire +1;}

}

resetall(){
  this.mainawaiting =0;
  this.mainhire =0;this.maincon =0;this.mainreview =0;
}




  createformgroup()
  {
    this.jobformGroup = this.formBuilder.group({
      jobmasterid: [''],
      urldocumentid: [''],
      jobtittle: ['', Validators.required],
      jobdate: ['', Validators.required],
      closedate: ['', Validators.required],
      contactname: ['', Validators.required],
      // amountpaid: ['', Validators.compose([Validators.required, Validators.pattern(/^([0-9].[0-9]\d|[1-9]\d*)?$/)])],
      contactrole: ['', Validators.required],
      // expensetypeid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      joburl1: [''],
      joburl2: [''],
      joburl3: [''],
      joburl4: [''],
      salary: ['', Validators.compose([Validators.required, Validators.pattern(/^[.\d]+$/), Validators.min(1)])],
      maxsalary: ['', Validators.compose([Validators.required, Validators.pattern(/^[.\d]+$/), Validators.min(1)])],
      socid: ['', Validators.required],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: ['', Validators.compose([Validators.required])],
      postalcode: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      cityname: ['', Validators.required],
      countyname: ['', Validators.required],
      countryid: ['',Validators.required],
      qualification: [''],
      addposition: ['', Validators.required],
      stypeid: ['', Validators.required],
      jobdescreption: ['']
    });
  }

  get f() { return this.jobformGroup.controls; }
  
  get g() { return this.jobscreenformGroup.controls; }

  
  onSubmit(formjob: any, id: string)
  {
    // var formjob = jobformdata.value;
    console.log(this.jobformGroup);
    this.submitted =true;
    if (this.jobformGroup.invalid) {
      return;
    }
    var pinc = formjob.postalcode.split(',');
    formjob.postalcode = pinc[pinc.length - 1];

    formjob.userid = this.Userid;
    
    if(formjob.joburl1 != '')
    {
      formjob.file1 = this.file1;
    }
    if(formjob.joburl2 != '')
    {
      formjob.file2 = this.file2;
    }
    if(formjob.joburl3 != '')
    {
      formjob.file3 = this.file3;
    }
    if(formjob.joburl4 != '')
    {
      formjob.file4 = this.file4;
    }
    // console.log(jobformdata);
    this.disableBtn = false;




    const sd = new Date(formjob.jobdate);
    formjob.jobdate = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    const ed = new Date(formjob.closedate);
    formjob.closedate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());



    this.job.jobdetailsUpdate(formjob).subscribe((response: any) => {
      this.job.getjobList().subscribe((data: any) => {
        // jobformdata.reset();
        this.record = data.length;
        
        this.jobDetail.countryid = 70;
        this.submitted = false;
        this.datalist = data;
        if(this.record < 30){
          this.pageOfItems = data;
        }
        else{
             this.items =data;
        }
        this.modalService.close(id);
      });
    });
  }

  addJobScreen(jobscreenshotformdata: any, id: string)
  {
    
    this.submitted =true;
    if (this.jobscreenformGroup.invalid) {
      return;
    }
    if(jobscreenshotformdata.joburl1 != '')
    {
      jobscreenshotformdata.file1 = this.file1;
    }
    if(jobscreenshotformdata.joburl2 != '')
    {
      jobscreenshotformdata.file2 = this.file2;
    }
    if(jobscreenshotformdata.joburl3 != '')
    {
      jobscreenshotformdata.file3 = this.file3;
    }
    if(jobscreenshotformdata.joburl4 != '')
    {
      jobscreenshotformdata.file4 = this.file4;
    }
    
    this.job.jobscreenshotdetailsUpdate(jobscreenshotformdata).subscribe((response: any) => {
      this.job.getjobById({"id": this.Userid, "jobid": response.jobmasterid}).subscribe((data: any) => {
        this.jobDetail = data;
        this.modalService.close(id);
      });
      
    });

  }
  jobstatus(datejob: any){
  let newDate = new Date(datejob);
  let currentdate = new Date();
  if(currentdate <= newDate)
  {
    this.jobst =true;
    return true;

  }else{this.jobst = false;return false;
  }

}
  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  onSearchChange(searchValue) {
    if (searchValue.length > 4) {
      var newArray = [];
      this.commonService.getAddress(searchValue).subscribe((data: any) => {
        var newdata: any;
        newdata = data;
        newdata.addresses.forEach(function (value) {
          newArray.push(value + "," + searchValue);
        });
        this.options = newArray;
      });
    }
    else {
      this.options = [];
    }
  }

  displayFn(value: any) {
    if (value != null) {
      var res = value.split(",");
      if (res.length > 6) {
        return res[7];
      }
      else {
        return value;
      }
    }
  }

  updatecode(evt: MatOptionSelectionChange, response) {
    res = [];
    if (evt.source.selected) {
      var res = response.split(",");
      this.jobDetail.address1 = res[0];
      this.jobDetail.address2 = res[1];
      this.jobDetail.cityname = res[5];
      this.jobDetail.postalcode = res[7];
      this.jobDetail.countyname = res[6];

      // const data = { 'id': this.jobDetail.countryid };
      // this.country.getstatelist(data).subscribe((state: any) => {
      //   for (var i = 0; i < state.length; i++) {
      //     if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
      //       var inde = state[i]['stateid'];
      //     }
      //   }
      //   this.jobDetail.countyid = inde;
      //   this.countylist = state;
      // });
    }
  }

  transform(html) {
    return this.sanitizer.bypassSecurityTrustStyle(html);
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  selectFile(event,field) {
    var files = event.target.files;
    var selectedFiles = files[0];
    this.selectedFiles = selectedFiles;
    const file = this.selectedFiles;

    var imagepath = this.uploadService.uploadfile(file);
   
    if(field == 'file1')
    {
      this.file1 = imagepath;
      this.br1 ='Choose file (1)';
    }
    if(field == 'file2')
    {
      this.file2 = imagepath;
      this.br2 ='Choose file (1)';
    }
    if(field == 'file3')
    {
      this.file3 = imagepath;
      this.br3 ='Choose file (1)';
    }
    if(field == 'file4')
    {
      this.file4 = imagepath;
      this.br4 ='Choose file (1)';
    }

  }

  openModal(jobid: any, id: string) 
  { 
    this.buttoninsert = "Update";
    this.disableBtn = true;
    this.jobDetail = new Jobs();   
    this.job.getjobById({"id": this.Userid, "jobid": jobid}).subscribe((data: any) => {
      this.jobDetail = data;
     // console.log(this.jobDetail);
      // this.jobDetail.countryid = '';
      data.screen.forEach(element =>{
        if(element.screenshotdayid == '1')
        {
          this.jobDetail.urldocumentid = element.id;
        }
      });
      
      this.modalService.open(id);

      // this.jobDetail.jobdescreption =  this.transform(data.jobdescreption);
    });
    
  }

  openModal2(id: string) 
  { 
    
    this.submitted = false;
    // this.createformgroup();
    // console.log(this.submitted);
    this.disableBtn = true;
    this.buttoninsert = "Add New Job";
    this.jobDetail = new Jobs();
    // console.log(this.jobDetail);
    // this.jobDetail.jobtittle = "";
    // this.jobDetail.jobdate = "";
    // this.jobDetail.closedate = "";
    // this.jobDetail.contactname = "";
    // this.jobDetail.contactrole = ""; 
    // this.jobDetail.phonenumber = "";
    // this.jobDetail.joburl1 = "";
    // this.jobDetail.joburl2 = "";
    // this.jobDetail.joburl3 = "";
    // this.jobDetail.joburl4 = "";
    // this.jobDetail.emailid = "";
    this.jobDetail.countryid = 70;
    // this.jobDetail.jobdescreption = "";
    this.mobilecheck = '';
    this.phonecheck = '';
    this.jobformGroup.reset(this.jobDetail);
    // this.submitted = false;
    this.modalService.open(id);
  }

  openModal3(jobid: string, id: string) 
  { 
    this.buttoninsert = "Update Job";
    this.job.getjobById({"id": this.Userid, "jobid": jobid}).subscribe((data: any) => {
      
      this.jobDetail = data;
    });
    this.modalService.open(id);
  }

  openModal4(screenshotid: string, id: string, screenumber: string) 
  { 
    this.buttoninsert = "Submit";
    this.screenshotid = screenshotid;
    this.file1 = "";
    this.file2 = "";
    this.file3 = "";
    this.file4 = "";
    if(screenumber != '')
    {
      this.disableday = true;
    }
    else
    {
      this.disableday = false;
    }
    this.screenumber = screenumber;
    this.modalService.open(id);
  }

  openModal5(id: string, data: any) {
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
      this.imgsrc = true;
      this.iframesrc = data;
    }
    else {
      this.imgsrc = false;
      this.iframesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
    }
    this.modalService.open(id);
  }

  deleteFile(jobid: string, id: string, documenturl: string)
  {
    var deleteData = {};
    if(documenturl == 'document1')
    {
      deleteData = { 'id': id, 'urldocument1': null };
    }
    else if(documenturl == 'document2')
    {
      deleteData = { 'id': id, 'urldocument2': null };
    }
    else if(documenturl == 'document3')
    {
      deleteData = { 'id': id, 'urldocument3': null };
    }
    else if(documenturl == 'document4')
    {
      deleteData = { 'id': id, 'urldocument4': null };
    }
    
    if (confirm("Are you sure you want to delete?")) {
      this.job.deleteFile(deleteData).subscribe((data: any) => {
        this.job.getjobById({"id": this.Userid, "jobid": jobid}).subscribe((data: any) => {
         
          this.jobDetail = data;
        });
      });
    }
  }

  closeModal(id: string) 
  { 
    this.modalService.close(id);
  }

}
