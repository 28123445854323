import { Nationality } from './visa/nationality.model';

export class Employee {
    employeeid: number;
    fname: string;
    mname: string;
    lname: string;
    emailid: string;
    phonenumber: string;
    jobid: number;
    nationalityid: number;
    ninumber: string;
    imagename: string;
    nationality:Nationality;
}

export class Contactinfo {
    id: string = "0";
    employeeid: number;
    workphone: string;
    mobile: string;
    homephone: string;
    workemail: string;
    personalemail: string;
    kinmobile: string;
    kinemail: string;
    telephone: string;
    kinaddress1: string;
    kinaddress2: string;
    kinpincode: string;
    kincityname: string;
    kincountyname: string;
    kincountryid: string;
}

export class Otherinfo {
    employeeid: number;   
    locationid: any;
    divisionid: any;
    departmentid: any;
    projectid: any;
    clientid: any;
    isclientcontact: any;
    reportto: number;
}