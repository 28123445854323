"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_service_1 = require("../services/common.service");
var i0 = require("@angular/core");
var i1 = require("./common.service");
var JobService = /** @class */ (function () {
    function JobService(commonService) {
        this.commonService = commonService;
    }
    JobService.prototype.getjobList = function () {
        return this.commonService.formpostOnlyid('/joblist');
    };
    JobService.prototype.getjobById = function (value) {
        return this.commonService.formpost('/jobbyid', value);
    };
    JobService.prototype.checkscreensort = function (value) {
        return this.commonService.formpost('/checkscreensortid', value);
    };
    JobService.prototype.getjobskillList = function (value) {
        return this.commonService.formpost('/jobskills', value);
    };
    JobService.prototype.deleteskill = function (value) {
        return this.commonService.formpost('/deleteskill', value);
    };
    JobService.prototype.addnewskill = function (value) {
        return this.commonService.formpost('/addnewskill', value);
    };
    JobService.prototype.jobdetailsUpdate = function (value) {
        return this.commonService.formpost('/updatejobdetails', value);
    };
    JobService.prototype.jobscreenshotdetailsUpdate = function (value) {
        return this.commonService.formpost('/updatejobscreendetails', value);
    };
    JobService.prototype.getjoblist = function () {
        return this.commonService.formget('/company/joblist');
    };
    JobService.prototype.deleteFile = function (value) {
        return this.commonService.formpost('/deletedocumentfile', value);
    };
    JobService.ngInjectableDef = i0.defineInjectable({ factory: function JobService_Factory() { return new JobService(i0.inject(i1.CommonService)); }, token: JobService, providedIn: "root" });
    return JobService;
}());
exports.JobService = JobService;
