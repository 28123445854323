"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ReferenceDetails_model_1 = require("../../models/reference/ReferenceDetails.model");
var forms_1 = require("@angular/forms");
var reference_service_1 = require("../../services/reference.service");
var checksystem_service_1 = require("../../services/checksystem.service");
var alert_service_1 = require("../../services/alert.service");
var RefsettingComponent = /** @class */ (function () {
    function RefsettingComponent(check, formBuilder, alerts, reference) {
        this.check = check;
        this.formBuilder = formBuilder;
        this.alerts = alerts;
        this.reference = reference;
        this.iseamil = false;
        this.issms = false;
        this.submitted = false;
        this.ref = new ReferenceDetails_model_1.Referencesetting();
        this.Message = '';
        this.smsdiv = false;
        this.emaildiv = false;
        this.hidediv = true;
        this.options = [
            new ReferenceDetails_model_1.Options(0, 'Once'),
            new ReferenceDetails_model_1.Options(1, 'Everyday'),
            new ReferenceDetails_model_1.Options(2, 'Every Other Day')
        ];
    }
    RefsettingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.addpopForm = this.formBuilder.group({
            isemail: [''],
            issms: [''],
            isemailtime: [''],
            emailday: [''],
            issmstime: [''],
            smsday: ['']
        });
        this.reference.getrefereesetting().subscribe(function (referencelist) {
            if (referencelist) {
                _this.ref.isemail = referencelist.isemail;
                _this.ref.issms = referencelist.issms;
                if (referencelist.issms)
                    _this.smsdiv = false;
                else
                    _this.smsdiv = true;
                if (referencelist.isemail)
                    _this.emaildiv = false;
                else
                    _this.emaildiv = true;
                _this.ref.isemailtime = referencelist.isemailtime;
                _this.ref.emailday = referencelist.emailday;
                _this.ref.issmstime = referencelist.issmstime;
                _this.ref.smsday = referencelist.smsday;
            }
        });
    };
    RefsettingComponent.prototype.counter = function (i) {
        return new Array(i);
    };
    RefsettingComponent.prototype.hidedivform = function () {
        this.hidediv = false;
    };
    RefsettingComponent.prototype.consertsms = function (values) {
        if (values.currentTarget.checked)
            this.smsdiv = false;
        else
            this.smsdiv = true;
    };
    RefsettingComponent.prototype.consertemail = function (values) {
        if (values.currentTarget.checked)
            this.emaildiv = false;
        else
            this.emaildiv = true;
    };
    RefsettingComponent.prototype.onSubmit = function (refform, id) {
        var _this = this;
        console.log('Ref Setting Details', refform);
        this.reference.postrefereesetting(refform).subscribe(function (referencelist) {
            _this.Message = 'Notification setting updated successfully.';
            _this.alerts.success('Notification setting updated successfully.', true);
            // this.ref.issmstime  =2;
            // this.ref.isemailtime =2;      
        });
    };
    return RefsettingComponent;
}());
exports.RefsettingComponent = RefsettingComponent;
