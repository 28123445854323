import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class MyemploymentService {
  constructor(private userService: CommonService) { }
  
  getemploymenttList(): Observable<any> {
    return this.userService.formpostOnlyid('/employee/workinglist');
  }
  getemploymenttListbyadmin(value: any): Observable<any> {
    return this.userService.formpost('/employee/workinglistbyadmin',value);
  }

  myemploymentdetailsUpdatebyadmin(value:any): Observable<any> {
    return  this.userService.formpost('/employee/employmentupdatebyadmin',value);
  }
}