"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var EmploymentinformationComponent = /** @class */ (function () {
    function EmploymentinformationComponent() {
    }
    EmploymentinformationComponent.prototype.ngOnInit = function () {
    };
    return EmploymentinformationComponent;
}());
exports.EmploymentinformationComponent = EmploymentinformationComponent;
