<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
      <div class="container">
          <div class="tabs" id="tabs">
              <a [routerLink]="['/report-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Report Dashboard</a>
              <div class="clearfix"></div>
              <div class="tabs__content">
                  <div class="tabs__content__item active-tab">
                      <div class="content">
                          <div class="row  mg-t-10">
                              <div class="col-lg-6">
                                  <h5 class="hedingcolor">Leave List</h5>
                              </div> 
                              <div class="col-lg-6 d-flex justify-content-end mb-3">
                                <!-- <button type="submit" (click)="exportAsXLSX();" class="btn btn-primary com_btn">
                                  Download <i class="ml-2 fa fa-file-excel-o"></i>
                                </button>
                                <a (click)="showcondition('list');"  class="faright toggle_button"><i class="fa fa-list"></i>
                                  <span  class="tooltip_show">List View</span>
                                </a>
                                <a (click)="showcondition('graph');" class="faright toggle_button"><i class="fa fa-bar-chart"></i>
                                  <span  class="tooltip_show">Graph View</span>
                                </a> -->
                              </div>
                          </div>
                          <div>
                              <div class="row topheader d-flex justify-content-end">
                                <div class="col-sm-6">
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <select name="hstatus" class="form-control input-lg" (change)="changeEmployeestatus($event.target.value)">
                                        <option value="">Select Status</option>
                                        <option value="1">Pending</option>
                                        <option value="2">Approved</option>
                                        <option value="3">Rejected</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                    <select name="employee" #employid (change)="changeEmployee($event.target.value)"
                                      class="form-control input-lg">
                                      <option value="">Select Employee</option>
                                      <option *ngFor="let emp of employeelist" value="{{emp.employeemasterid}}"
                                        [selected]="emp.employeemasterid==empid">
                                        {{ emp.fname }} {{ emp.mname }} {{ emp.lname }} ( {{ emp.employeeid }})</option>
                                    </select>
                                  </div>
                                  
                                  <div class="col-lg-3">
                                 <select name="empmonth" #empmonth (change)="changeEmployeeMonth($event.target.value)" class="form-control input-lg">
                                      <option value="">Select Month</option>
                                      <option value="1" [selected]="employeemonth=='1'">January</option>
                                      <option value="2" [selected]="employeemonth=='2'">February</option>
                                      <option value="3" [selected]="employeemonth=='3'">March</option>
                                      <option value="4" [selected]="employeemonth=='4'">April</option>
                                      <option value="5" [selected]="employeemonth=='5'">May</option>
                                      <option value="6" [selected]="employeemonth=='6'">June</option>
                                      <option value="7" [selected]="employeemonth=='7'">July</option>
                                      <option value="8" [selected]="employeemonth=='8'">August</option>
                                      <option value="9" [selected]="employeemonth=='9'">September</option>
                                      <option value="10" [selected]="employeemonth=='10'">October</option>
                                      <option value="11" [selected]="employeemonth=='11'">November</option>
                                      <option value="12" [selected]="employeemonth=='12'">December</option>       
                                    </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select name="empyear" #empyear (change)="changeEmployeeYear($event.target.value)" [value]="employeeyear" class="form-control input-lg">
                                        <option value="{{y}}" *ngFor="let y of yearList">{{ y }}</option>
                                      </select>
                                  </div>
                                  </div>
                                </div>                                
                                <div class="clearfix"></div>                                   
                              </div>
                              <div class="job_list_section idproof_request_section visa_request_section" *ngFor="let leave of pageOfItems | filter: {fname: searchText,lname: searchText,emailid: searchText,phonenumber: searchText, ninumber: searchText,placeofbirth: searchText };  let i=index">
                                  <div class="job_detils_date">
                                      <div class="visa_user_pic">
                                          <img *ngIf="leave.employee.imagename" src="{{leave.employee.imagename}}" class="img width100" alt="{{ leave.employee.fname }} {{ leave.employee.mname }} {{ leave.employee.lname}}">
                                          <img *ngIf="leave.employee.gender == true && (leave.employee.imagename == null || leave.employee.imagename =='') " src="../../../../../assets/img/user_pic_leave.png" class="img width100" alt="{{ leave.employee.fname }} {{ leave.mname }} {{ leave.employee.lname}}">
                                          <img *ngIf="leave.employee.gender == false && (leave.employee.imagename == null || leave.employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ leave.employee.fname }} {{ leave.employee.mname }} {{ leave.employee.lname}}">
                                      </div>
                                  </div>
                                  <div class="job_detils_date width20">
                                      <div class="visa_user_details">
                                          <h4> {{leave.employee.fname}} {{leave.employee.mname}} {{leave.employee.lname}}</h4>
                                          <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"></i> {{ leave.employee.emailid }}
                                          </p>
                                          <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i>{{ leave.employee.phonenumber }}</p>
                                          <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i>NI number {{ leave.employee.ninumber }} </p>
                                          <span *ngIf="leave.employee.nationality" class="badge badge-success">{{ leave.employee.nationality.countryname }}</span>
                                      </div>
                                  </div>
                                  <div class="job_detils_date width10">
                                      <p class="job_address">Title</p>
                                      <p class="job_profile"> {{leave.title}}</p>
                                      <p class="job_profile" *ngIf="!leave.title">
                                        <span>N/A</span>
                                      </p>
                                  </div>
                                  <!-- <div class="job_detils_date width22">

                                      <div class="job_profile" *ngIf="leave.remarkdetails.length > 0">
                                          <div class="dsfsadf">
                                              <p *ngFor="let rd of leave.remarkdetails "><b>Note :</b> {{ rd.holidayremark }}</p>
                                          </div>
                                      </div>
                                  </div> -->
                                  <div class="job_detils_date width10 ">
                                      <p class="job_address ">Leave Type</p>
                                      <p class="job_profile "> {{ leave.holidaytype != undefined ? leave.holidaytype.typename : 'NA'}} </p>
                                  </div>
                                  <div class="job_detils_date width10 ">
                                      <p class="job_address ">Start Date</p>
                                      <p class="job_profile "> {{leave.startdate | date: 'dd MMM yyyy'}}</p>
                                      <p class="job_profile" *ngIf="!leave.startdate">
                                        <span>N/A</span>
                                      </p>
                                  </div>
                                  <div class="job_detils_date width10 ">
                                      <p class="job_address ">End Date</p>
                                      <p class="job_profile ">{{leave.enddate | date: 'dd MMM yyyy'}}</p>
                                      <p class="job_profile" *ngIf="!leave.enddate">
                                        <span>N/A</span>
                                      </p>
                                  </div>
                                  <div class="job_detils_date width7">
                                      <p class="job_address ">
                                          <button type="submit" class="btn btn-info com_btn" (click)="openModalnote(leave.remarkdetails,'note-leave-report')"> 
                                            <div class="tooltip_visa width73px">
                                              <p>Message </p>
                                            </div>
                                            <i class="fa fa-comments-o "></i>
                                          </button>
                                      </p>
                                  </div>
                              </div>
                              <div class="row ">
                                  <div class="col-lg-4 text-left " *ngIf="record==0 ">
                                      <div class="billed-to ">No Record Found </div>
                                  </div>
                                  <div class="col-lg-12 text-right ">
                                      <jw-pagination [items]="items " [pageSize]="30 " [maxPages]="10 " (changePage)="onChangePage($event) "></jw-pagination>
                                  </div>
                              </div>
                          </div>
                          <!-- <div class="row " [style.display]="graphdisplay ">
                              <div class="col-lg-12 ">
                                  <app-leavechart></app-leavechart>
                              </div>
                          </div> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<jw-modal id="note-leave-report" class="modal " role="dialog ">
  <div role="document" class="formgroup ">
      <div class="modal-content ">
          <div class="modal-header ">
              <h5 class="modal-title ">Notes</h5>
              <button type="button " class="close " data-dismiss="modal " name="close " aria-label="Close " (click)="closeModal( 'note-leave-report'); ">
                <span aria-hidden="true ">&times;</span>
                </button>
          </div>
          <div class="modal-body ">
              <div class="container ">
                  <div class="job_profile">
                      <div class="">
                          <p *ngFor="let rd of notedetails "><b>Note :</b> {{ rd.holidayremark }}</p>
                      </div>
                  </div>

              </div>
          </div>
          <div class="modal-footer ">
              <button type="button " class="btn btn-secondary com_btn " data-dismiss="modal " (click)="closeModal( 'note-leave-report');">Close</button>
          </div>
      </div>
  </div>
</jw-modal>