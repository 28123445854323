<app-header></app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <!-- <a [routerLink]="['/admincreditsafe']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to AML, PEPs & Sanction Dashboard</a> -->
          
            <div class="clearfix"></div>
            
            <div class="tabs__content">

                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="row row-sm topheader">                             
                            <div class="col-lg-5 col-md-7 col-sm-12">
                                <div>
                                    <app-checkcountshow></app-checkcountshow>    
                                </div>                                
                            </div>
                            <div class="col-lg-7 col-md-5 text-right">
                              <div class="dflexflex mb-mm justify-content-end align-items-center">
                                <button type="button" class="btn com_btn btn-success" (click)="amllogredirect()"><span class="lnr lnr-history"></span> View AML, PEPs & Sanction History</button>                               
                                                          
                              </div>                             
                          </div>
                            <!-- <div class="col-lg-7 text-right">
                                <div class="dflexflex mb-mm justify-content-end align-items-center">
                                    <a class="card_box_lit" [routerLink]="['/check-personal-aml-peps-sanction']">
                                        <button type="button" class="btn com_btn btn-success">Personal AML, PEPs & Sanction
                                        </button>
                                    </a>                     
                                </div>                             
                            </div>  -->
                        </div>
                        <div class="row"  *ngIf="result==false">
                            <div class="col-sm-12 aligncenter mb-3 mt-3">
                                <h5 class="hedingcolor">Business Details </h5>                                 
                             </div>  
                        </div>
                        <div class="row"  *ngIf="result==true">
                            <div class="col-sm-6 aligncenter mb-3 mt-3">
                                <h5 class="hedingcolor">Business Search Details </h5>                                 
                             </div> 
                             

                             <div class="col-lg-6">
                                <a type="button" (click)="norecordfound()" style="color: #fff;" [innerHtml]="downloadtext" target="_blank" class="btn btn-primary com_btn float-right" >No Record Found <i class="fa fa-Plus ml-2"></i></a>
                                <a type="button" href="{{ url }}" *ngIf="url"   target="_blank" class="btn btn-primary com_btn float-right" >Download <i class="fa fa-file-pdf-o ml-2"></i></a>
                             </div>
                        </div>
                        
                        
                        <div class="personal_form topheader" *ngIf="result==false">



<!-- 
                          <div class="multicolorg">

                            
                            <span class="light_blue">PEP</span>
                            <span class="light_orange">SAN</span>
                            <span class="light_purple">RRE</span>
                            <span class="light_asian">INS</span>
                            <span class="light_green">DD</span>
                            <span class="dark_blue">POI</span>
                            <span class="light_pink">REL</span>
                            <span> Choose Database</span> 
                        </div>
                        <div class="row radio_section">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1"><b>Datasets to search</b></label>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <label class="checkbox_credit">Select All
                                    <input type="checkbox" [checked]="checkedAll" (change)="selectall($event.target.checked)" >
                                    <span class="checkmark"></span>
                                </label>
                            </div>

                            <div class="col-sm-3 col-md-4 col-lg-3" *ngFor="let user of peplist">
                                <label class="checkbox_credit">{{user.name}}
                                    <input type="checkbox"  [checked]="selectpeplist.indexOf(user.id) >= 0"  (change)="countryselectallfalse($event.target.checked,user.id)"  name="PEP" id="PEP" class="form-control" aria-describedby="emailHelp" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>



                        </div> -->




                            <form [formGroup]="bussinessformGroup" #bussinessForm="ngForm" class="s12 white">

                                <!-- <input type="hidden" formControlName="Threshold" name="Threshold" id="Threshold" class="form-control" aria-describedby="emailHelp" placeholder="Business Name" [ngClass]="{ 'is-invalid': submitted && f.Threshold.errors }" [(ngModel)]="businessDetail.Threshold" -->
                                <!-- /> -->


                                
                            
                            <div class="row">
                                <div class="col-sm-12">                                    
                                        <div class="multicolorg">
                                            <span *ngFor="let dataset of selectpeplist" class="has-tooltip north">{{dataset.id}}
                                                <span class="tooltip">
                                                    <h4>{{dataset.heading}}</h4>
                                                    <p>{{dataset.description}}</p>
                                                </span>
                                            </span>
                                            <button class="btn com_btn btn-dark"  type="button" (click)="choosedatabase()" [disabled]="showdataset">Choose Datasets</button>                                                                                        
                                            <div *ngIf="showdataset" class="ml-2">  
                                                <button class="btn com_btn btn-success"  type="button" (click)="selectalldataset()" [disabled]="selectpeplist.length ==7" style="margin-right:10px;">Select All</button>                                            
                                                <button class="btn com_btn btn-danger"  type="button" (click)="clearalldataset()" [disabled]="selectpeplist.length ==0">Clear All</button>                                                                                                               
                                           </div>
                                        </div>
                                </div>                               
                            </div>

                           

                            <div class="row radio_section" *ngIf="showdataset" style="margin-top:20px ;">
                                <div class="col-sm-11">
                                    <div class="row">
                                        <div class="col-sm-3 col-md-4 col-lg-3" *ngFor="let user of peplist">
                                            <label class="checkbox_credit postion_rel">{{user.name}}
                                                <!-- <span class="tool-tip">
                                                    <h4>{{user.heading}}</h4>
                                                    <p>{{user.description}}</p>
                                                </span> -->
                                                <input type="checkbox"  [checked]="checkpep(user)"  (change)="countryselectallfalse($event.target.checked,user)"  name="PEP" id="PEP" class="form-control" aria-describedby="emailHelp" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1"> 
                                    <button type="button"(click)="closedataset()" class="btn com_btn btn-success">Close</button>                                    
                                </div>
                            </div>



                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Business Name*</label>
                                            <input type="text" formControlName="BusinessName" name="BusinessName" id="BusinessName" class="form-control" aria-describedby="emailHelp" placeholder="Business Name" [ngClass]="{ 'is-invalid': submitted && f.BusinessName.errors }" [(ngModel)]="businessDetail.BusinessName">
                                            <div *ngIf="submitted && f.BusinessName.errors" class="invalid-feedback">
                                                <div *ngIf="f.BusinessName.errors.required">Please enter business name</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                      <div class="form-group ddd">
                                          <label for="exampleInputEmail1">Country or Nationality*</label>

                                          <mat-form-field class="example-full-width">
                                            <input name="Country" id="Country" matInput placeholder="Country" aria-label="Country" [matAutocomplete]="auto" [formControl]="stateCtrl">
                                            <span class="lnr lnr-chevron-down alignarrow"></span>
                                            <mat-autocomplete #auto="matAutocomplete"  (optionSelected)='getPosts($event.option.value)'>
                                                <ng-container *ngFor="let state of filteredStates | async" >
                                                    <mat-option [value]="state.iso"  *ngIf="!iscountrySelected(state) && state.iso !=''" >                                                
                                                        <span>{{ state.countryname }}</span>
                                                    </mat-option>
                                                </ng-container>
                                            </mat-autocomplete>
                                          </mat-form-field>
  
  
                                          <div class="autocomplete">
                                              <!-- <div class="chips-input-container">
                                                  <input name="Country" type="text" placeholder="Country or Nationality" (click)="countrysuggest()" class="form-control" readonly />
                                                  <span class="lnr lnr-chevron-down"></span>
                                              </div>     -->
                                              <div class="userflex">
                                                  <div class="user-chip" *ngFor="let user of countryuserSelects">
                                                      {{user.countryname}}
                                                      <span (click)="deletecountrySelects(user)" class="delete-icon-chip">&#10006;</span>
                                                  </div>
      
                                              </div>
                                            
                                              <div class="autocomplete-items" *ngIf="countryshow" >
                                                  <ng-container *ngFor="let s of countrylist">
                                                      <div *ngIf="!iscountrySelected(s) && s.iso !=''"  [ngClass]="iscountrySelected(s) ? 'selected-suggestion' : ''" (click)="selectcountrySuggestion(s)">{{ s.countryname }}</div>
                                                  </ng-container>
                                              </div>
                                             
                                          </div> 


                                            <div *ngIf="countryerror" class="invalid-feedback">
                                                    <div >Please select atleast one country</div>
                                            </div>
                                      </div>
                                    </div>

                                  




                                    <!-- <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Town/City</label>
                                            <input type="text" formControlName="City" name="City" id="City" class="form-control" aria-describedby="emailHelp" placeholder="City Name" [ngClass]="{ 'is-invalid': submitted && f.City.errors }" [(ngModel)]="businessDetail.City">

                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">County/State</label>
                                            <input type="text" formControlName="County" name="County" id="County" class="form-control" aria-describedby="emailHelp" placeholder="County / State" [ngClass]="{ 'is-invalid': submitted && f.County.errors }" [(ngModel)]="businessDetail.County">

                                          
                                        </div>
                                    </div> -->


                                </div>

                                <div class="row">




                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Matching Threshold Percentage</label>

                                            <div class="row">
                                                <div class="col-sm-9">
                                                    <mat-slider min="50" formControlName="Threshold"  name="Threshold" id="Threshold"  max="100" step="1" value="50" [(ngModel)]="Threshold" style="width: 100%;"></mat-slider>
                                                </div>
                                                <div class="col-sm-3">
                                                    <input type="text" formControlName="Threshold" name="Threshold" id="Threshold" class="form-control"  placeholder="" [ngClass]="{ 'is-invalid': submitted && f.Threshold.errors }" readonly [(ngModel)]="Threshold"  value="80">
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                  <!-- <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Matching Threshold Percentage</label>
                                        <input type="text" formControlName="Threshold" name="Threshold" id="Threshold" class="form-control" aria-describedby="emailHelp" placeholder="Matching Threshold Percentage" [ngClass]="{ 'is-invalid': submitted && f.Threshold.errors }" [(ngModel)]="businessDetail.Threshold">
                                    </div>
                                </div> -->

                                  <div class="col-sm-4"   *ngIf="issan">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Sanction Status</label>
                                        <select class="form-control" formControlName="sanctionstatus" [ngClass]="{ 'is-invalid': submitted && f.sanctionstatus.errors }" [(ngModel)]="businessDetail.sanctionstatus" name="sanctionstatus" id="sanctionstatus" placeholder="Select Sanction">
                                          <option value="SAN" selected>Any</option>
                                          <option value="SAN-CURRENT">Current</option>
                                          <option value="SAN-FORMER">Former</option>
                                        </select>                                            
                                    </div>
                                </div>


                                <div class="col-sm-4" *ngIf="issoe" >
                                  <div class="form-group">
                                      <label for="exampleInputEmail1">SOE Status</label>
                                      <select class="form-control" formControlName="sanctionstatus" [ngClass]="{ 'is-invalid': submitted && f.sanctionstatus.errors }" [(ngModel)]="businessDetail.soeSanctions" name="soeSanctions" id="soeSanctions" placeholder="Select SOE Sanction">
                                        <option value="SAN" selected>Any</option>
                                        <option value="SAN-CURRENT">Current</option>
                                        <option value="SAN-FORMER">Former</option>
                                      </select>                                            
                                  </div>
                              </div>

                                    <!-- <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Post / Zip Code</label>
                                            <input type="text" formControlName="Postcode" name="Postcode" id="Postcode" class="form-control" aria-describedby="emailHelp" placeholder="Post / Zip Code" [ngClass]="{ 'is-invalid': submitted && f.Postcode.errors }" [(ngModel)]="businessDetail.Postcode">

                                        </div>
                                    </div> -->

                                   


                                    <!-- <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Country*</label>
                                            <select class="form-control" formControlName="Country" [ngClass]="{ 'is-invalid': submitted && f.Country.errors }" [(ngModel)]="businessDetail.Country" name="Country" id="Country" placeholder="Select Country">
                                                <option [value]="defaultVal">Select Country</option>
                                                <option *ngFor="let cmp of countrylist" [value]="cmp.name">
                                                    {{ cmp.name }} </option>
                                            </select>
                                            <div *ngIf="submitted && f.Country.errors" class="invalid-feedback">
                                                <div *ngIf="f.Country.errors.required">Please select country</div>
                                            </div>
                                        </div>
                                    </div> -->


                                    <!-- <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address</label>
                                            <input type="text" formControlName="Address" name="Address" id="Address" class="form-control" aria-describedby="emailHelp" placeholder="Address" [ngClass]="{ 'is-invalid': submitted && f.Address.errors }" [(ngModel)]="businessDetail.Address">

                                        </div>
                                    </div> -->
                                </div>
                                <div class="row">

                                    <!-- <div class="col-sm-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Address 2</label>
                                        <input type="text" formControlName="houseNo" name="houseNo" id="houseNo" class="form-control"   aria-describedby="emailHelp" placeholder="Address 2"  [ngClass]="{ 'is-invalid': submitted && f.houseNo.errors }" [(ngModel)]="businessDetail.houseNo">

                                    </div>
                                </div> -->

                                </div>
                                <!-- <div class="row radio_section">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1"><b>Datasets to search</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="checkbox_credit">Select All
                                        <input type="checkbox" [checked]="checkedAll" (change)="selectall($event.target.checked)" >
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit">PEP
                                        <input type="checkbox" formControlName="PEP" name="PEP" id="PEP" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.PEP.errors }" [(ngModel)]="businessDetail.PEP" (change)="selectallfalse($event.target.checked)">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Sanction - Current
                                        <input type="checkbox" formControlName="CurrentSanctions" name="CurrentSanctions" id="CurrentSanctions" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.CurrentSanctions.errors }"  (change)="selectallfalse($event.target.checked)" [(ngModel)]="businessDetail.CurrentSanctions" >
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Sanction - Previous
                                        <input type="checkbox" formControlName="PreviousSanctions" name="PreviousSanctions" id="PreviousSanctions" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.PreviousSanctions.errors }"  (change)="selectallfalse($event.target.checked)"  [(ngModel)]="businessDetail.PreviousSanctions">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Financial Regulator
                                        <input type="checkbox" formControlName="FinancialRegulator" name="FinancialRegulator" id="FinancialRegulator" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.FinancialRegulator.errors }"  (change)="selectallfalse($event.target.checked)"  [(ngModel)]="businessDetail.FinancialRegulator">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Law Enforcement
                                        <input type="checkbox" formControlName="LawEnforcement" name="LawEnforcement" id="LawEnforcement" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.LawEnforcement.errors }"  (change)="selectallfalse($event.target.checked)"  [(ngModel)]="businessDetail.LawEnforcement">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit">  Disqualified Director (UK Only)
                                        <input type="checkbox" formControlName="DisqualifiedDirector" name="DisqualifiedDirector" id="DisqualifiedDirector" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.DisqualifiedDirector.errors }"  (change)="selectallfalse($event.target.checked)"  [(ngModel)]="businessDetail.DisqualifiedDirector">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit">   Insolvency (UK & Ireland)
                                        <input type="checkbox" formControlName="Insolvency" name="Insolvency" id="Insolvency" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.Insolvency.errors }"   (change)="selectallfalse($event.target.checked)" [(ngModel)]="businessDetail.Insolvency">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                    <div class="col-sm-3 col-md-4 col-lg-3">
                                        <label class="checkbox_credit"> Adverse Media
                                        <input type="checkbox" formControlName="AdverseMedia" name="AdverseMedia" id="AdverseMedia" class="form-control" aria-describedby="emailHelp" placeholder="Address 1" [ngClass]="{ 'is-invalid': submitted && f.AdverseMedia.errors }"   (change)="selectallfalse($event.target.checked)" [(ngModel)]="businessDetail.AdverseMedia">
                                        <span class="checkmark"></span>
                                      </label>

                                    </div>
                                </div>
                                 -->
                                <div class="row radio_section" *ngIf="error ==true">
                                    <div class="col-sm-12 text-right invalid-feedback">
                                        <label class="error" >Please select at least one dataset</label>
                                    </div>
                                </div>
                                <hr/>
                                <div class="row">
                                    <div class="col-sm-6 text-left">
                                        <span *ngIf="lesssore">
                                            Sorry, You don't have any credits. Please <a [routerLink]="['/check-add-credit']">Click Here</a> to add more.
                                        </span>
                                    </div>
                                    
                                    <div class="col-sm-12 text-right">
                                        <input type="submit" class="btn com_btn btn-primary"  *ngIf="!lesssore" (click)="onSubmit(bussinessForm.value,'first')"  [disabled]="!disableBtn"  name="updateform" value="{{buttoninsert}}" />

                                        <!-- <a [routerLink]="['/buscreditsearchresult']" class="btn com_btn btn-primary"><i class="fa fa-search"> </i> Search</a> -->
                                        <button type="button" class="btn com_btn btn-secondary" (click)="clearpersonal()">Clear</button>
                                    </div>
                                </div>



                                

                            </form>


                            <jw-modal id="pdf-payment-confirmation" class="modal sendrequestcharge" role="dialog">

                                <div role="document" class="formgroup">
                             
                                    <div class="modal-content">
    
                             
                                        <div class="modal-body text-center">
                             
                                            <div class="container">
                             
                             
                             
                                                <div class="row">
                             
                                                    <div class="col-lg-12 spaceset">
                                                        <div class="check">
                                                            <span class="lnr lnr-checkmark-circle"></span>
                                                        </div>
                                                        <!-- <p class="textinfoma">Per Click Charge <span class="charge">£5</span> </p> -->
                                                        <!-- <p class="text-center short">Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs</p> -->
                                                        <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
                                                        <p class="">{{errormsg}}</p>
                             
                                                    </div>
                                                    <div class="text-center m-auto">
                                                        <button type="submit"  class="btn com_btn btn-primary" (click)="onSubmit(bussinessForm.value,'payment')"  [disabled]="!disableBtn" *ngIf="lesssore == false" name="updateform" value="{{buttoninsert}}" > Confirm</button>
                                                        <button type="submit"  class="btn com_btn btn-secondary"(click)="closeModal('pdf-payment-confirmation');" > Cancel</button>
                                                    </div>
                                                   
                             
                             
                                                </div>
                             
                             
                             
                             
                             
                                            </div>
                             
                                        </div>
                             
                                       
                             
                                    </div>
                             
                                </div>
                             
                             </jw-modal>

                          
                        </div>



                      


                        <div class="credit_data_show" *ngIf="result==true">
                            <ng-container *ngIf="searchresult">
                                <div class="job_list_section visa_request_section" *ngFor="let company of searchresult.matches">

                                    <div class="job_detils_date width10">
                                        <p class="job_address">ID</p>
                                        <p class="ninumber text-primary">{{company.qrCode}} </p>
                                    </div>
                                    <div class="job_detils_date width35">
                                        <div class="visa_user_details ">
                                            <p class="job_address">Business Name</p>
                                            <h4>{{ company.name}}</h4>
                                        </div>
                                    </div>
                                    <div class="job_detils_date width20">
                                        <p class="job_address">Data Set</p>
                                        <!-- {{dataset(company.business)}} -->
                                        <p class="job_profile text-danger multicolorg" >
                                        <ng-container *ngFor="let dd of  company.datasets">
                                            <span>  {{dd}}</span> 
                                        </ng-container>
                                    </p>
                                    
                                    </div>

                                    <div class="job_detils_date aligncenter width10">
                                        <div id="container" class="green_text">
                                            <div class="dot"></div>
                                            <div class="pulse"></div>

                                        </div>
                                        <p class="job_profile green_text">{{ company.score}} </p>
                                    </div>
                                    <div class="job_detils_date width15">
                                        <p class="job_address">Country</p>
                        
                        
                                        <!-- <p class="job_profile text-danger" *ngIf="personal.searchcondition.countries.length <=1">
                                            <b>
                                                <ng-container *ngFor="let cn of bindallcountry(personal.searchcondition.countries)">
                                                    {{cn.countryname}}
                                                </ng-container>
                                            </b>
                                        </p>  -->
                                        <div class="flex-d">
                                            <div class="dropdown">
                                                <button  *ngIf="company.countries.length !=0" class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Country {{company.countries.length}} <span class="lnr lnr-chevron-down"></span>
                                                </button>

                                                <button *ngIf="company.countries.length ==0" class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton_null" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                  NA 
                                                </button>

                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <p *ngFor="let cn of  bindallcountry(company.countries)">{{cn.countryname}}</p> 
                                                
                                                </div>
                                            </div>
                                            <button type="submit" class="btn btn-primary com_btn"  (click)="onSubmitsearch(company,'first')" ><i class="fa fa-file-image-o"></i></button>
                                        </div>
                                    
                                        
                                        
                                    </div>
                                
                            
                                </div>
                            </ng-container>



                        </div>


                    </div>


                    <div class="overflow_box_show" *ngIf="searchrecord">
                        <div class="rels">
                            <div class="loadercenterimg">  <img src="../../../../../assets/img/loader.gif" /> </div>
                            
                            <h2 class="codee" data-text="We are searching 5000+ databases to get the best result for you.">We are searching 5000+ databases to get the best result for you.</h2>
                        </div>
                  </div> 
                  
                </div>
            </div>
        </div>
    </div>
</div>


<jw-modal id="bussiness-pdf-viewer-visa" class="modal" role="dialog">
    <div role="document" class="formgroup" *ngIf="bussinessdetails">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{bussinessdetails.business.businessName}}</h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('bussiness-pdf-viewer-visa');">
        <span aria-hidden="true">&times;</span>
      </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-2">
                            <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                        </div>
                        <div class="col-lg-10  d-flex">
                            <div class="category justify-content-between align-items-center text-center d-flex width100">
                                <div class="catog_box">
                                    <p>PEP</p>
                                    <span>9</span>
                                </div>

                                <div class="catog_box">
                                    <p>Adverse Media</p>
                                    <span>11</span>
                                </div>
                                <div class="catog_box">
                                    <p>IDV</p>
                                    <span>9</span>
                                </div>
                                <div class="catog_box">
                                    <p>Law Enforcement</p>
                                    <span>2</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <hr/>
                    <div class="all_detials">
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">
                                    <div class="catog_box width50">
                                        <p>Business Name</p>
                                        <span>{{bussinessdetails.business.businessName}}</span>
                                    </div>
                                    <div class="catog_box width50">
                                        <p>Alias</p>
                                        <p *ngFor="let companyaliases of bussinessdetails.person.aliases;">
                                            {{companyaliases.businessName}}
                                        </p>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box width50">
                                        <p>Telephone</p>
                                        <span>{{bussinessdetails.telephoneNumber}}</span>
                                    </div>
                                    <div class="catog_box width50">
                                        <p>Website </p>
                                        <span>{{bussinessdetails.website}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">


                                    <div class="catog_box width50">
                                        <p>Addresses</p>
                                        <p *ngFor="let companyaddress of bussinessdetails.business.addresses;">
                                            {{companyaddress.address1}}, {{ companyaddress.city }} {{ companyaddress.county }}- {{ companyaddress.postcode}}
                                        </p>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>

                        <div class="row">

                            <div class="col-lg-12  d-flex">
                                <div class="category justify-content-between align-items-center d-flex width100">

                                    <div class="catog_box">
                                        <span>Reference Id</span>
                                    </div>
                                    <div class="catog_box">
                                        <span>{{bussinessdetails.business.id }}</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <hr/>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary com_btn"> Remove from review list</button>
                <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</jw-modal>



    
<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
      <div class="modal-content">         
        <div class="modal-body">
          <div class="container text-center">
            <div class="info_mess">
              <div class="para">
                <h4 class="mb-2">No credits available</h4>
                <p class="mb-0 font20"> There are no credits available to perform this action. Please add more credits.</p>
              </div>                          
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <input type="button" class="btn btn-primary com_btn" name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
          <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
        </div>
      </div>
    </div>
  </jw-modal>