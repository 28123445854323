<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
      <div class="container">
          <div class="tabs" id="tabs">
              <div class="tabs__content">
                  <div class="tabs__content__item active-tab">
                      <div class="content">
                          <div class="row row-sm topheader">                            
                              <div class="col-lg-5 text-right">
                                <app-checkcountshow></app-checkcountshow>
                              </div>
                              <div class="col-lg-7 text-right">
                                <div class="dflexflex mb-mm justify-content-end align-items-center">
                                    <button *ngIf="permission.m101>1" class="boxbtn mainheading_btn ml-1" (click)="uploadByAdmin(emptype, 'add_new', '')"> + Add New Applicant </button>
                                  <!-- <a class="boxbtn mainheading_btn ml-1"  [routerLink]="['/check-add-applicant', emptype]" > + Add New Applicant </a>                                    -->
                                  <button class="btn com_btn btn-success small_btn" *ngIf="emptype == 'visa'" [routerLink]="['/check-visa-history']"  type="button">
                                    <span class="lnr lnr-history pr-1">                                        
                                    </span> BRP Verification History 
                                </button>
                                <button class="btn com_btn btn-success small_btn" *ngIf="emptype == 'passport'" [routerLink]="['/check-passport-history']"  type="button">
                                    <span class="lnr lnr-history pr-1">                                        
                                    </span> Passport Verification History 
                                </button>
                                <button class="btn com_btn btn-success small_btn" *ngIf="emptype == 'document'" [routerLink]="['/check-document-history']"  type="button">
                                    <span class="lnr lnr-history pr-1">                                        
                                    </span> Driving Licence Verification History 
                                </button>

                                </div>                             
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-12 aligncenter mb-3 justify-content-between">
                              <h5 class="hedingcolor">{{ h1tag }} Verification</h5>                              
                              <input type="text" [(ngModel)]="searchText" class="form-control width35" placeholder="Search">
                            </div>
                        </div> 
                        <div class="browser-screen-loading-content" *ngIf="!pageOfItems" >
                            <div class="loading-dots dark-gray">
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>                                
                                <i></i>
                            </div>
                            <p>Loading</p>
                        </div>
                         <ng-container *ngIf="( datalist | filter: {employeeid: searchText, emprole: searchText, fname: searchText, mname: searchText, lname: searchText, emailid: searchText, phonenumber: searchText }) as pageOfItems"> 
                                 <div class="job_list_section employee_list_section pb-2 pt-2 oddeven" *ngFor="let employee of pageOfItems;  let i=index">
                              
                                    <div class="job_detils_date width25">
                                        <p class="job_address">Name</p>
                                        <h5 class="job_profile font_17">{{employee.fname}} {{employee.mname}}
                                            {{employee.lname}}</h5>
                                        <!-- <p class="email"><i aria-hidden="true" class="fa fa-envelope-o"></i>
                                            {{employee.emailid}}</p>
                                        <p class="phone"><i aria-hidden="true" class="fa fa-phone"></i>
                                            {{employee.phonenumber}}</p> -->
                                    </div>
                                    <div class="job_detils_date width22">
                                        <p class="job_address">Email</p>
                                        <p  class="job_profile">{{employee.emailid}}</p>
                                            <!-- <p class="job_profile text-primary"  *ngIf="employee.gender==true"> Male</p>                                
                                        <p class="job_profile text-primary" *ngIf="employee.gender==false"> Female</p> -->
                                    </div>
                                    <div class="job_detils_date width15">
                                        <p class="job_address">Phone Number</p>
                                        <p class="job_profile">{{employee.phonenumber}}</p>
                                        
                                            <!-- <p class="job_profile" *ngIf="employee.maritalstatus==true">  Married </p>

                                        
                                            <p class="job_profile" *ngIf="employee.maritalstatus==false">  Single </p> -->

                                        

                                        
                                    </div>
                                    <!-- <div class="job_detils_date width15">
                                        <p class="job_address">Entry Date</p>
                                        <p class="badge badge-danger">
                                            {{employee.createat | date}} </p>
                                    </div> -->

                                    <div class="job_detils_date  width25 justify-content-end d-flex aligncenter">
                                        <!-- <button (click)="sendZoomLink(employee.emailid)">send zoom link</button> -->                                   
                                            <button class="btn btn-primary com_btn small_btn" *ngIf="permission.m101>1" (click)="sendrequest(employee.employeemasterid,'sendrequestcharge','admin_upload')">Upload Now</button>
                                            <a  class="btn btn-warning com_btn small_btn" *ngIf="permission.m101>1" (click)="sendrequest(employee.employeemasterid,'sendrequestcharge','')" >Send Request To Applicant</a>                               
                                            <!-- <a  class="btn btn-primary com_btn" *ngIf="emptype == 'visa'" [routerLink]="['/check-ai-visa-admin',employee.employeemasterid]">Upload Now</a>  
                                            <a  class="btn btn-primary com_btn"  *ngIf="emptype == 'passport'"  [routerLink]="['/check-ai-passport-admin',employee.employeemasterid]">Upload Now</a>  
                                            <a  class="btn btn-primary com_btn"  *ngIf="emptype == 'document'" [routerLink]="['/check-ai-document-admin',employee.employeemasterid]">Upload Now</a>   -->
                                        </div>                               
                                    
                                </div>
                                 <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>  
                               
                         </ng-container>
                            <!-- <div class="job_list_section visa_request_section"*ngIf="record === 0">
                                <div class="job_detils_date">
                                    <p class="job_profile">No Record Found</p>
                                </div>
                            </div>       -->
                          <!-- <div class="row">
                              <div class="col-lg-12 text-right">
                                  <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10"
                                      (changePage)="onChangePage($event)"></jw-pagination>
                              </div>
                          </div> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


<jw-modal id="custom-terminate-1" role="dialog" class="modal">
  <form [formGroup]="terminationForm" #terminationform="ngForm" class="s12 white"
      (ngSubmit)="onSubmitTermination(terminationform.value,'custom-terminate-1')">
      <div role="document" class="formgroup">
          <div class="modal-content">
              <div class="modal-header">
                  <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid"
                      id="employeeid" [(ngModel)]="terminationDetail.employeeid">
                  <h5 class="modal-title" id="popupLabel">Termination Details</h5>
                  <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                      (click)="closeModal('custom-terminate-1');">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                  <div class="container">
                      <div class="row">
                          <div class="form-group col-lg-6">
                              <label>Termination Date </label>
                              <mat-form-field class="datecontrol">
                                  <input matInput [matDatepicker]="picker4" id="terminationdate"
                                      [ngClass]="{ 'is-invalid': submitted && g.terminationdate.errors }"
                                      name="terminationdate" formControlName="terminationdate"
                                      class="form-control noborder-invalid"
                                      [(ngModel)]="terminationDetail.terminationdate">
                                  <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                  <mat-datepicker #picker4></mat-datepicker>
                              </mat-form-field>
                              <div *ngIf="submitted && g.terminationdate.errors" class="invalid-feedback">
                                  <div *ngIf="g.terminationdate.errors.required">Please choose termination date</div>
                              </div>
                          </div>
                          <div class="form-group col-lg-6">
                              <label>Termination Reason*</label>
                              <input type="text" formControlName="terminationreason" name="terminationreason"
                                  id="terminationreason" class="form-control"
                                  [ngClass]="{ 'is-invalid': submitted && g.terminationreason.errors }"
                                  [(ngModel)]="terminationDetail.terminationreason">
                              <div *ngIf="submitted && g.terminationreason.errors" class="invalid-feedback">
                                  <div *ngIf="g.terminationreason.errors.required">Please enter termination reason
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="form-group col-lg-6">
                              <label>Eligible For Rehire*</label>
                              <select style="width:100%;" formControlName="oktorehire" name="oktorehire"
                                  id="oktorehire" class="form-control input-lg"
                                  [(ngModel)]="terminationDetail.oktorehire"
                                  [ngClass]="{ 'is-invalid': submitted && g.oktorehire.errors }">
                                  <option [value]="defaultVal">Select eligible for rehire</option>
                                  <option [value]="1">Yes </option>
                                  <option [value]="0">No</option>
                              </select>
                              <div *ngIf="submitted && g.oktorehire.errors" class="invalid-feedback">
                                  <div *ngIf="g.oktorehire.errors.required">Please select employement status</div>
                              </div>
                          </div>
                          <div class="form-group col-lg-6">
                              <label>Regret Termination*</label>
                              <select style="width:100%;" formControlName="regrettermination" name="regrettermination"
                                  id="regrettermination" class="form-control input-lg"
                                  [ngClass]="{ 'is-invalid': submitted && g.regrettermination.errors }"
                                  [(ngModel)]="terminationDetail.regrettermination">
                                  <option [value]="defaultVal">Select regret termination</option>
                                  <option [value]="1">Yes </option>
                                  <option [value]="0">No</option>
                              </select>
                              <div *ngIf="submitted && g.regrettermination.errors" class="invalid-feedback">
                                  <div *ngIf="g.regrettermination.errors.required">Please select regret termination
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Terminate" />
                  <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel"
                      (click)="closeModal('custom-terminate-1');" />
              </div>
          </div>
      </div>
  </form>
</jw-modal>

<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
  <div role="document" class="formgroup">
      <div class="modal-content">         
          <div class="modal-body">
              <div class="container text-center">
                <div class="info_mess">
                    <div class="para">
                            <h4 class="mb-2">No credits available</h4>
                            <p class="mb-0 font20"> There are no credits available to perform this action. Please add more credits.</p>

                        </div>                          
                    </div>
              </div>
          </div>
          <div class="modal-footer text-center">
            <input type="button" class="btn btn-primary com_btn" name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
            <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
              <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
          </div>
      </div>
  </div>
</jw-modal>




<jw-modal id="sendrequestcharge" role="dialog" class="modal sendrequestcharge">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-body p-4">
                <div class="container text-center">
                    <div class="info_mess">
                        <div class="para">
                            <h4 class="mb-2">You will now be charged. The fee will be deducted from your available balance.</h4>
                            <p class="mb-0 font20" *ngIf="employeereq" >The employee will receive an email and they need to upload the document.</p>
                            <p class="mb-0 font20" *ngIf="!employeereq" >Please confirm to proceed with uploading all the required documents.</p>
                        </div>                          
                    </div>
                    
                </div>
            </div>
            <div class="modal-footer text-center">
                <input type="submit" class="btn btn-primary com_btn" *ngIf="!employeereq" name="updateform" value="Confirm"  (click)="uploadByAdmin(emptype, 'upload_now', employeeid)"  />
                <input type="submit" class="btn btn-primary com_btn" *ngIf="employeereq" name="updateform" value="Confirm"  (click)="sendrequestcharge(employeeid,'sendrequestcharge')"  />
                <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('sendrequestcharge');"/>
            </div>
        </div>
    </div>
</jw-modal>


<jw-modal id="send-request" role="dialog" class="modal">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                    (click)="closeModal('upgrade-plan');">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                          <div class="form-group col-lg-6">
                                <div class="row">You have exceeded the limit to request to Right to check or AI verification. Please add more credit go to billing section.</div>
                            </div>
                    </div>


                    <div class="row">
                        <div class="form-group col-lg-6">
                              <div class="row">You have exceeded the limit to request to Right to check or AI verification. Please add more credit go to billing section.</div>
                          </div>
                  </div>

                 </div>
            </div>
            <div class="modal-footer">
                <input type="button" class="btn btn-primary" name="updateform" value="Send Request" />
            </div>
        </div>
    </div>
  </jw-modal>