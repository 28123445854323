export class CheckInvoice { 
    id: string = "";
    createat: string = "";
    prices: string = "";
    tax: string = "";
    gtotal: string = "";
    companydetails: CompanyDetails;
  }

  export class CompanyDetails { 
    stuser: string = "";
    clientname: string = "";
  }