import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class ZoomService {

  constructor(private commonService: CommonService) { }

  sendZoomLink(value: any): Observable<any> {
    return this.commonService.formpost('/zoomlink', value);
  }

  sendZoomLinkWithIdentity(value: any): Observable<any> {
    return this.commonService.formpost('/zoomlinkwithidentity', value);
  }
}
