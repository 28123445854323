"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var user_model_1 = require("../../models/user.model");
var user_service_1 = require("../../services/user.service");
var alert_service_1 = require("../../services/alert.service");
var modal_service_1 = require("../../_services/modal.service");
var app_global_1 = require("../../app.global");
var stripe_angular_1 = require("stripe-angular");
var checkservice_service_1 = require("../../services/checkservice.service");
var router_1 = require("@angular/router");
var company_service_1 = require("../../services/company.service");
var address_model_1 = require("../../models/address/address.model");
var country_service_1 = require("../../services/country.service");
var platform_browser_1 = require("@angular/platform-browser");
var PayverificationComponent = /** @class */ (function () {
    function PayverificationComponent(document, router, alerts, userService, modalService, companyService, country, StripeScriptTag, formBuilder, checkService, sanitizer) {
        this.document = document;
        this.router = router;
        this.alerts = alerts;
        this.userService = userService;
        this.modalService = modalService;
        this.companyService = companyService;
        this.country = country;
        this.StripeScriptTag = StripeScriptTag;
        this.formBuilder = formBuilder;
        this.checkService = checkService;
        this.sanitizer = sanitizer;
        this.show = false;
        this.buttonName = 'Show';
        this.submitted = false;
        this.user = new user_model_1.User();
        this.access_token = '';
        this.paymentloading = false;
        this.userid = '';
        this.name = '';
        this.isline = '';
        this.planid = '';
        this.paymentaut = true;
        this.iframepaymentautsrc = '';
        this.isemail = '';
        this.address = new address_model_1.Address();
        this.isLoginError = false;
        this.wizard1 = false;
        this.wizard2 = false;
        this.publishableKey = app_global_1.AppGlobals.API_STRIPE;
        this.tab1 = false;
        this.paynow = false;
        this.changetoggle = false;
        this.coupon_applied = false;
        this.couponerror = false;
        this.paymentSubmit = 0;
        this.defaultVal = '';
    }
    PayverificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        else {
            this.userService.checkaccesstokenbyid(userid).subscribe(function (data) {
                if (data.status == false) {
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('userid');
                    localStorage.removeItem('Name');
                    localStorage.removeItem('isline');
                    localStorage.removeItem('planid');
                    localStorage.removeItem('ctype');
                    localStorage.removeItem('isemail');
                    localStorage.removeItem('ismobile');
                    localStorage.removeItem('isadmin');
                    localStorage.removeItem('ispermission');
                    localStorage.removeItem('logoname');
                    localStorage.removeItem('struser');
                    localStorage.removeItem('menu');
                    localStorage.removeItem('isverify');
                    _this.router.navigate(['/login']);
                }
            });
        }
        // localStorage.removeItem('struser');
        var isemail = localStorage.getItem('isemail');
        var ismobile = localStorage.getItem('ismobile');
        var struser = localStorage.getItem('struser');
        var isverify = localStorage.getItem('isverify');
        this.ctype = parseInt(localStorage.getItem('ctype'));
        var planid = parseInt(localStorage.getItem('planid'));
        if (isemail == 'false' && ismobile == 'false') {
            this.router.navigate(['/otp']);
        }
        else if (planid == 0 && this.ctype == 0 && struser == '') {
            this.router.navigate(['choose-plan']);
        }
        else if (struser != '' && this.ctype == 1 && isverify == '1') {
            this.router.navigate(['check-dashboard']);
        }
        else if (struser != '' && this.ctype == 2) {
            this.router.navigate(['check-dashboard']);
        }
        else if (struser != '' && this.ctype == 3) {
            this.router.navigate(['check-dashboard']);
        }
        // else if(struser != '' && isverify == true)
        // {
        //   this.router.navigate(['check-dashboard']);
        // }
        // this.ctype  = parseInt(localStorage.getItem('ctype'));
        // if(this.ctype ==1)
        // {
        //   this.companyService.getcompanydetails().subscribe((data: any) => {
        //     if (data.verfiedforcheck == false && data.stuser)
        //     { 
        //       this.router.navigate(['/approval-confirmation']);          
        //     }
        //   });
        // }
        this.errormsg = '';
        this.StripeScriptTag.setPublishableKey(this.publishableKey);
        this.wizard1 = true;
        this.tab1 = true;
        this.addpopForm = this.formBuilder.group({
            cardname: ['', forms_1.Validators.required],
            address1: ['', forms_1.Validators.required],
            address2: [''],
            cityname: ['', forms_1.Validators.compose([forms_1.Validators.required,])],
            statename: ['', forms_1.Validators.required],
            pincode: ['', forms_1.Validators.required],
            stateid: [''],
            taxid: [''],
            coupon: [''],
            countryid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])]
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.addresslistDetail = new address_model_1.Address();
        this.addresslistDetail.countryid = "70";
        this.checkService.getAllServices().subscribe(function (data) {
            _this.pricecheck = data;
        });
    };
    PayverificationComponent.prototype.toTop = function (id) {
        document.getElementById("content").scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    };
    PayverificationComponent.prototype.checkPasswords = function (group) {
        var pass = group.controls.password.value;
        var confirmPass = group.controls.confirmpassword.value;
        return pass === confirmPass ? null : { notSame: true };
    };
    Object.defineProperty(PayverificationComponent.prototype, "f", {
        get: function () { return this.registerForm.controls; },
        enumerable: true,
        configurable: true
    });
    /**
   * Submit function to register new company user and set cookies
   * @author Fareed Aarif
   * @createat 17/10/2020
   */
    PayverificationComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.user.isemail = true;
        this.user.planid = 1;
        this.user.ctype = 1;
        // this.user.permissionstring = this.permissionstring;
        this.userService.userjoinnow(this.user).subscribe(function (data2) {
            if (data2.sussess == 'true') {
                _this.userService.userAuthentication(_this.user).subscribe(function (data) {
                    if (data.sussess == 'true') {
                        localStorage.setItem('Token', _this.access_token);
                        if (data.sussess == 'true') {
                            localStorage.setItem('Token', data.id);
                            // if (data.isemail == false) {
                            //   // localStorage.setItem('otp', data.otp);
                            // }
                            _this.errormsg = '';
                            _this.toggle('1');
                        }
                        else {
                            _this.alerts.error(data.error, true);
                        }
                    }
                });
            }
            else {
                _this.modalService.open('custom-errormsg-1');
                _this.errormsg = data2.error;
                _this.alerts.error(data2.error, true);
            }
        }, function (err) {
            _this.isLoginError = true;
        });
    };
    PayverificationComponent.prototype.scroll = function (el) {
        el.scrollIntoView({ behavior: 'smooth' });
    };
    PayverificationComponent.prototype.toggle = function (id) {
        if (id == 1) {
            this.wizard2 = true;
            this.wizard1 = false;
            this.router.navigate(['/approval-confirmation']);
        }
        this.show = !this.show;
        if (this.show)
            this.buttonName = "Hide";
        else
            this.buttonName = "Show";
    };
    PayverificationComponent.prototype.update_payment = function (formvalue) {
        var _this = this;
        this.submitted = true;
        if (this.addpopForm.invalid) {
            return;
        }
        var country = '';
        this.countrylist.forEach(function (element) {
            if (formvalue.countryid == element.countryid) {
                country = element.countryname;
            }
        });
        this.paynow = true;
        // this.stptoken = token.id;
        var amunt = parseFloat('36.00');
        var data = {
            // "token": token.id,
            // "cmonth": token.card.exp_month,
            // "cyear": token.card.exp_year,
            // "cardnumber": token.card.last4,
            // "tokenid": token.card.id,
            // "cardname": token.card.brand,
            // "country": token.card.country,
            "cstatus": "1",
            "amount": amunt,
            "address1": formvalue.address1,
            "address2": formvalue.address2,
            "cityname": formvalue.cityname,
            "statename": formvalue.statename,
            "countryid": formvalue.countryid,
            "cardcountry": country,
            "postcode": formvalue.pincode,
            "couponcode": formvalue.coupon,
            "taxid": formvalue.taxid,
            "cardholdername": formvalue.cardname
        };
        if (this.paymentSubmit == 0) {
            this.paymentloading = true;
            this.checkService.registereduserpayment_new(data).subscribe(function (payment3) {
                if (payment3.success == true) {
                    localStorage.setItem('struser', payment3.struser);
                    _this.paymentloading = false;
                    _this.toggle('1');
                    _this.wizard2 = true;
                    _this.wizard1 = false;
                    if (_this.ctype == '1')
                        _this.router.navigate(['/check-dashboard']);
                    else if (_this.ctype == '2')
                        _this.router.navigate(['/check-dashboard']);
                    else if (_this.ctype == '3')
                        _this.router.navigate(['/reference-dashboard']);
                }
                else if (payment3.success == false) {
                    if (payment3.url) {
                        _this.document.location.href = payment3.url;
                    }
                    else {
                        _this.paynow = false;
                        _this.wizard2 = true;
                        _this.paymentaut = false;
                        _this.paymentloading = false;
                        console.log(payment3.url);
                        _this.iframepaymentautsrc = _this.getSafeUrl(payment3.url);
                        _this.modalService.open('custom-payment-25');
                        var xy = null;
                        var refreshId = setInterval(function () {
                            var data = { 'invoiceid': payment3.paymentint };
                            _this.companyService.getpaymentintent(data).subscribe(function (invoiceData) {
                                console.log(invoiceData);
                                console.log(invoiceData.status);
                                if (invoiceData.status != 'requires_source_action' && invoiceData.status != 'processing') {
                                    if (invoiceData.status == 'succeeded') {
                                        _this.companyService.Creditchecksecurecard(data).subscribe(function (invoiceData) {
                                            _this.paymentSubmit = 0;
                                            _this.paymentloading = false;
                                            _this.toggle('1');
                                            _this.wizard2 = true;
                                            _this.wizard1 = false;
                                            //   if(this.ctype == '1')
                                            //     this.router.navigate(['/check-dashboard']);
                                            //  else if(this.ctype == '2')
                                            //     this.router.navigate(['/check-dashboard']);
                                            //  else if(this.ctype == '3')
                                            //     this.router.navigate(['/reference-dashboard']);
                                        });
                                        clearInterval(refreshId);
                                    }
                                }
                            });
                        }, 5000);
                    }
                }
                else {
                    _this.paymentSubmit = 0;
                    _this.paymentloading = false;
                    _this.alerts.error("The payment method has failed. Please try again after 30 seconds.");
                }
            });
        }
        this.paymentSubmit = 1;
    };
    PayverificationComponent.prototype.setStripeToken = function (token, formvalue, id) {
        var _this = this;
        this.submitted = true;
        if (this.addpopForm.invalid) {
            return;
        }
        var country = '';
        this.countrylist.forEach(function (element) {
            if (formvalue.countryid == element.countryid) {
                country = element.countryname;
            }
        });
        this.paynow = true;
        this.stptoken = token.id;
        var amunt = parseFloat('36.00');
        var data = {
            "token": token.id,
            "cmonth": token.card.exp_month,
            "cyear": token.card.exp_year,
            "cardnumber": token.card.last4,
            "tokenid": token.card.id,
            "cardname": token.card.brand,
            "country": token.card.country,
            "cstatus": "1",
            "amount": amunt,
            "address1": formvalue.address1,
            "address2": formvalue.address2,
            "cityname": formvalue.cityname,
            "statename": formvalue.statename,
            "countryid": formvalue.countryid,
            "cardcountry": country,
            "postcode": formvalue.pincode,
            "couponcode": formvalue.coupon,
            "taxid": formvalue.taxid,
            "cardholdername": formvalue.cardname
        };
        if (this.paymentSubmit == 0) {
            this.paymentloading = true;
            this.checkService.registereduserpayment(data).subscribe(function (payment3) {
                if (payment3.success == true) {
                    localStorage.setItem('struser', payment3.struser);
                    _this.paymentloading = false;
                    _this.toggle('1');
                    _this.wizard2 = true;
                    _this.wizard1 = false;
                    if (_this.ctype == '1')
                        _this.router.navigate(['/check-dashboard']);
                    else if (_this.ctype == '2')
                        _this.router.navigate(['/check-dashboard']);
                    else if (_this.ctype == '3')
                        _this.router.navigate(['/reference-dashboard']);
                }
                else if (payment3.success == false) {
                    if (payment3.url) {
                        _this.document.location.href = payment3.url;
                    }
                    else {
                        _this.paynow = false;
                        _this.wizard2 = true;
                        _this.paymentaut = false;
                        _this.paymentloading = false;
                        console.log(payment3.url);
                        _this.iframepaymentautsrc = _this.getSafeUrl(payment3.url);
                        _this.modalService.open('custom-payment-25');
                        var xy = null;
                        var refreshId = setInterval(function () {
                            var data = { 'invoiceid': payment3.paymentint };
                            _this.companyService.getpaymentintent(data).subscribe(function (invoiceData) {
                                console.log(invoiceData);
                                console.log(invoiceData.status);
                                if (invoiceData.status != 'requires_source_action' && invoiceData.status != 'processing') {
                                    if (invoiceData.status == 'succeeded') {
                                        _this.companyService.Creditchecksecurecard(data).subscribe(function (invoiceData) {
                                            _this.paymentSubmit = 0;
                                            _this.paymentloading = false;
                                            _this.toggle('1');
                                            _this.wizard2 = true;
                                            _this.wizard1 = false;
                                            //   if(this.ctype == '1')
                                            //     this.router.navigate(['/check-dashboard']);
                                            //  else if(this.ctype == '2')
                                            //     this.router.navigate(['/check-dashboard']);
                                            //  else if(this.ctype == '3')
                                            //     this.router.navigate(['/reference-dashboard']);
                                        });
                                        clearInterval(refreshId);
                                    }
                                }
                            });
                        }, 5000);
                    }
                }
                else {
                    _this.paymentSubmit = 0;
                    _this.paymentloading = false;
                    _this.alerts.error("The payment method has failed. Please try again after 30 seconds.");
                }
            });
        }
        this.paymentSubmit = 1;
    };
    Object.defineProperty(PayverificationComponent.prototype, "g", {
        get: function () { return this.addpopForm.controls; },
        enumerable: true,
        configurable: true
    });
    PayverificationComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    PayverificationComponent.prototype.openModal = function (id) {
        this.addresslistDetail = new address_model_1.Address();
        this.addresslistDetail.countryid = "70";
        this.modalService.open(id);
    };
    PayverificationComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    PayverificationComponent.prototype.paymentstripe = function (id) {
        this.paynow = false;
        this.modalService.open(id);
    };
    PayverificationComponent.prototype.checkCoupon = function (coupon) {
        var _this = this;
        this.checkService.checkCoupon({ couponcode: coupon }).subscribe(function (couponResp) {
            if (couponResp.success) {
                _this.coupon_applied = true;
                _this.couponerror = false;
                _this.discount = couponResp.amountoff;
            }
            else {
                _this.coupon_applied = false;
                _this.couponerror = true;
            }
        });
        console.log(coupon);
    };
    Object.defineProperty(PayverificationComponent.prototype, "coupon", {
        get: function () { return this.addpopForm.get('coupon'); },
        enumerable: true,
        configurable: true
    });
    PayverificationComponent.prototype.cancelCoupon = function () {
        this.coupon.reset();
        this.coupon_applied = false;
    };
    PayverificationComponent.prototype.onStripeError = function (error) {
        console.error('Stripe error', error);
    };
    PayverificationComponent.prototype.logout = function () {
        var _this = this;
        if (confirm("Are you sure you want to sign out?")) {
            this.userService.logout().subscribe(function (data) {
                localStorage.removeItem('userToken');
                localStorage.removeItem('userid');
                localStorage.removeItem('Name');
                localStorage.removeItem('isline');
                localStorage.removeItem('planid');
                localStorage.removeItem('ctype');
                localStorage.removeItem('isemail');
                localStorage.removeItem('ismobile');
                localStorage.removeItem('isadmin');
                localStorage.removeItem('ispermission');
                localStorage.removeItem('logoname');
                localStorage.removeItem('struser');
                localStorage.removeItem('menu');
                localStorage.removeItem('isverify');
                _this.router.navigate(['/login']);
            });
            ;
        }
    };
    return PayverificationComponent;
}());
exports.PayverificationComponent = PayverificationComponent;
