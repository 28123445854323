import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invioce-shared',
  templateUrl: './invioce-shared.component.html',
  styleUrls: ['./invioce-shared.component.css']
})
export class InvioceSharedComponent implements OnInit {

  ctype: any;
  constructor(private router: Router) { }

  ngOnInit() {
    
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

     this.ctype  = parseInt(localStorage.getItem('ctype'));

     console.log('ctype check system', this.ctype);
    // if(this.ctype ==1){
    //   this.router.navigate(['/check-dashboard']);
    // }
  }

}
