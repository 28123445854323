"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../_services/modal.service");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var expensetype_model_1 = require("../../../models/expense/expensetype.model");
var leave_service_1 = require("../../../services/leave.service");
var SettingleavetypeComponent = /** @class */ (function () {
    function SettingleavetypeComponent(modalService, formBuilder, router, leaveser) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.router = router;
        this.leaveser = leaveser;
        this.hourDetail = new expensetype_model_1.Holidaytype();
        this.options = [];
        this.submitted = false;
    }
    SettingleavetypeComponent.prototype.ngOnInit = function () {
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.settingperm = this.permission.m81;
        this.Userid = localStorage.getItem('userid');
        this.hourDetail = new expensetype_model_1.Holidaytype();
        this.getleavetypeformgroup();
        this.AllData();
    };
    SettingleavetypeComponent.prototype.AllData = function () {
        var _this = this;
        var sstatustype = { 'status': false, 'id': this.Userid };
        this.leaveser.getleavetypelist(sstatustype).subscribe(function (leavetypelist) {
            _this.data = leavetypelist;
            _this.record = _this.data.length;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
    };
    SettingleavetypeComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    SettingleavetypeComponent.prototype.getleavetypeformgroup = function () {
        this.hourformGroup = this.formBuilder.group({
            id: [''],
            typename: ['', forms_1.Validators.required],
            noofholiday: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.pattern(/^[.\d]+$/), forms_1.Validators.max(365), forms_1.Validators.min(1)])],
            htype: ['']
        });
    };
    Object.defineProperty(SettingleavetypeComponent.prototype, "f", {
        get: function () { return this.hourformGroup.controls; },
        enumerable: true,
        configurable: true
    });
    SettingleavetypeComponent.prototype.onSubmit = function (formleave, id) {
        var _this = this;
        // var formleave = settingleavetypedata.value;
        this.submitted = true;
        if (this.hourformGroup.invalid) {
            return;
        }
        this.disableBtn = false;
        formleave.userid = this.Userid;
        this.leaveser.settingleavetypeUpdate(formleave).subscribe(function (response) {
            _this.AllData();
            _this.modalService.close(id);
        });
    };
    SettingleavetypeComponent.prototype.onChangeStatus = function (evt, leavetype) {
        var _this = this;
        var cstatus;
        if (evt.target.checked) {
            cstatus = '1';
        }
        else if (!evt.target.checked) {
            cstatus = '0';
        }
        this.leaveser.leavetypeUpdateChangeStatus({ 'userid': this.Userid, 'id': leavetype.id, 'cstatus': evt.target.checked }).subscribe(function (data) {
            _this.AllData();
        });
    };
    SettingleavetypeComponent.prototype.openModal = function (id, data) {
        this.buttoninsert = "Update";
        this.disableBtn = true;
        this.hourDetail = data;
        this.hourformGroup.reset(this.hourDetail);
        this.modalService.open(id);
    };
    SettingleavetypeComponent.prototype.openModal2 = function (id) {
        this.submitted = false;
        this.buttoninsert = "Submit";
        this.disableBtn = true;
        // this.getleavetypeformgroup();
        this.hourDetail = new expensetype_model_1.Holidaytype();
        this.hourDetail.id = 0;
        this.hourDetail.htype = "1";
        this.hourformGroup.reset(this.hourDetail);
        // console.log(this.hourDetail);
        this.modalService.open(id);
    };
    SettingleavetypeComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return SettingleavetypeComponent;
}());
exports.SettingleavetypeComponent = SettingleavetypeComponent;
