
<app-header></app-header>
<div class="section-wrapper">
  <div class="container">
    <a [routerLink]="['/reference-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to  Automated Reference Check</a>
    <div class="clearfix"></div>
    <div class="tabs" id="tabs"   >
      <div class="clearfix"></div>
      <div class="tabs__content">
        <div class="tabs__content__item active-tab">
          <div class="content">
            <div class="topheader">
              <ng-container *ngIf="stage==3">
                <div class="row row-sm topheader">
                  <div class="col-lg-5">
                    <div>
                      <app-checkcountshow></app-checkcountshow>                                   
                    </div>                                    
                  </div>                            
                  <div class="col-lg-7 text-right">
                    <div class="dflexflex mb-mm justify-content-end align-items-center">                                      
                      <button type="button" class="btn com_btn btn-success" (click)="newrequest()">Create New Reference</button> 
                    </div>                             
                  </div>                                                              
                </div>
                <div class="row">
                  <div class="col-sm-8 aligncenter mb-3 mt-3">
                    <h5 class="hedingcolor">Create New Reference Request </h5>                                 
                  </div>
                  <div class="col-lg-4">
                    <div class="input-group">
                      <input [(ngModel)]="searchText" placeholder="Search.." class="form-control">
                    </div>
                  </div>
                </div>
                <div class="browser-screen-loading-content" *ngIf="!pageOfItems" >
                  <div class="loading-dots dark-gray">
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>                                
                    <i></i>
                  </div>
                  <p>Loading</p>
                </div>
                <ng-container *ngIf="( datalist | filter: {employeeid: searchText, emprole: searchText, fname: searchText, mname: searchText, lname: searchText, emailid: searchText, phonenumber: searchText }) as pageOfItems"> 
                  <div class="job_list_section employee_list_section pb-2 pt-2 oddeven" *ngFor="let employee of pageOfItems;  let i=index">
                    <div class="job_detils_date width25">
                      <p class="job_address">Name</p>
                      <h5 class="job_profile font_17">{{employee.fname}} {{employee.mname}}
                          {{employee.lname}}</h5>
                      <!-- <p class="email"><i aria-hidden="true" class="fa fa-envelope-o"></i>
                          {{employee.emailid}}</p>
                      <p class="phone"><i aria-hidden="true" class="fa fa-phone"></i>
                          {{employee.phonenumber}}</p> -->
                    </div>
                    <div class="job_detils_date width22">
                      <p class="job_address">Email</p>
                      <p  class="job_profile">{{employee.emailid}}</p>
                          <!-- <p class="job_profile text-primary"  *ngIf="employee.gender==true"> Male</p>                                
                      <p class="job_profile text-primary" *ngIf="employee.gender==false"> Female</p> -->
                    </div>
                    <div class="job_detils_date width15">
                      <p class="job_address">Phone Number</p>
                      <p class="job_profile">{{employee.phonenumber}}</p>
                      <!-- <p class="job_profile" *ngIf="employee.maritalstatus==true">  Married </p>
                      <p class="job_profile" *ngIf="employee.maritalstatus==false">  Single </p> -->
                    </div>
                    <!-- <div class="job_detils_date width15">
                        <p class="job_address">Entry Date</p>
                        <p class="badge badge-danger">
                            {{employee.createat | date}} </p>
                    </div> -->
                    <div class="job_detils_date  width25 justify-content-end d-flex aligncenter">
                      <!-- <button (click)="sendZoomLink(employee.emailid)">send zoom link</button> -->                                   
                      <a [routerLink]="['/reference-status']" [queryParams]="{ud: employee.employeemasterid}" >   <button class="btn btn-primary com_btn small_btn" *ngIf="employee.reference.length >0"   >Reference Status</button></a>
                      <a  class="btn btn-warning com_btn small_btn"   (click)="createnewrequest(employee)" >Create New Reference </a>                               
                      <!-- <a  class="btn btn-primary com_btn" *ngIf="emptype == 'visa'" [routerLink]="['/check-ai-visa-admin',employee.employeemasterid]">Upload Now</a>  
                      <a  class="btn btn-primary com_btn"  *ngIf="emptype == 'passport'"  [routerLink]="['/check-ai-passport-admin',employee.employeemasterid]">Upload Now</a>  
                      <a  class="btn btn-primary com_btn"  *ngIf="emptype == 'document'" [routerLink]="['/check-ai-document-admin',employee.employeemasterid]">Upload Now</a>   -->
                    </div>
                  </div>
                  <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                    <div class="job_detils_date">
                      <p class="job_profile">No Record Found</p>
                    </div>
                  </div>
                </ng-container>
              </ng-container>





              
              <ng-container *ngIf="stage==0">
                <div class="row row-sm topheader">
                  <div class="col-lg-5">
                    <div>
                      <app-checkcountshow></app-checkcountshow>                                   
                    </div>                                    
                  </div>
                  <div class="col-lg-7 text-right">
                    <div class="dflexflex mb-mm justify-content-end align-items-center">                                         
                      <button type="button" class="btn com_btn btn-success" (click)="existingapplicent()">Applicant List </button>                                                            
                    </div>                             
                  </div>                                                              
                </div>
                <div class="row">
                  <div class="col-sm-12 aligncenter mb-3 mt-3">
                    <h5 class="hedingcolor">Create New Reference Request </h5>                                 
                  </div>                               
                </div>
                <!-- (ngSubmit)="onSubmit(bussinessForm.value,'Master')" -->
                <div class="personal_form"  >
                  <form class="s12 white" [formGroup]="createNewRequestFormGroup" novalidate (ngSubmit)="createNewRequest()">
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label for="input-5">Candidate Name<span class="text-danger">*</span></label>
                        <input type="text" formControlName="candidatename" class="form-control form-control-rounded"
                        id="input-5">
                        <div *ngIf="submit && f.candidatename.errors">
                          <span class="help-block text-danger">
                            Please enter candidate name
                          </span>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="input-5">Candidate Email<span class="text-danger">*</span></label>
                        <input type="text"  (focusout)="validateemail($event)"   formControlName="candidateemail" class="form-control form-control-rounded" id="input-5">
                        <div class="help-block text-danger" *ngIf="submit && f.candidateemail.errors">Please enter email
                        </div>
                        <div class="help-block text-danger" *ngIf="submit && createNewRequestFormGroup.controls.candidateemail?.dirty && createNewRequestFormGroup.controls.candidateemail?.invalid">Please enter valid email
                        </div> 
                        <div class="help-block text-danger" *ngIf="!isvalidemail">This applicant already exists in your account. </div> 
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-6"> 
                        <div class="input-group">
                          <label for="input-5" class="width100">Candidate Phone<span class="text-danger">*</span></label>

                          <international-phone-number class="form-control phonecontrol" formControlName="candidatephone"
                          placeholder="Enter Mobile number"
                          (input)="validatenumber($event.target.value,'phone')" [maxlength]="20" [defaultCountry]="'gb'" #candidatephone name="candidatephone">
                          </international-phone-number>
                          <div class="input-group-append"> 
                            <span class="input-group-text">
                              <i class="fa fa-phone" aria-hidden="true"></i>
                            </span>
                          </div>
                          <div *ngIf="submit && f.candidatephone.errors" class="invalid-feedback">
                            <div *ngIf="f.candidatephone.errors.required">Please enter candidate phone number</div>
                          </div>
                          <span *ngIf="phonecheck==false" style="color:red; width: 100%; font-size: 80%;">Invalid Format</span>
                          <span *ngIf="phonecheck==true" style="color:green; width: 100%; font-size: 80%;">Valid Format</span>
                          <!-- <input type="text" formControlName="candidatephone" class="form-control form-control-rounded"
                              id="input-5">
                          <div *ngIf="submit && f.candidatephone.errors">
                              <span class="help-block text-danger">
                                Please enter candidate phone number
                              </span>
                          </div> -->
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="input-5">Candidate Position<span class="text-danger">*</span></label>
                          <input type="text" formControlName="position" class="form-control form-control-rounded" id="input-5">
                          <div *ngIf="submit && f.position.errors">
                            <span class="help-block text-danger">
                              Please enter candidate position
                            </span>
                          </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label for="exampleSelect1">Question Profile<span class="text-danger">*</span></label>
                        <select formControlName="questionprofile" class="form-control" id="exampleSelect1">
                          <option value="">Select Question Profile</option>
                          <option *ngFor="let questionTemplatesList of questionTemplatesLists"
                            [ngValue]="questionTemplatesList.id" [selected]="questionTemplatesList.id === '0'">
                            {{questionTemplatesList.templatename}}</option>
                        </select>
                        <div *ngIf="submit && f.questionprofile.errors">
                          <span class="help-block text-danger">
                            Please enter question profile
                          </span>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="exampleSelect1">References Required<span class="text-danger">*</span></label>
                        <select formControlName="referencerequest" class="form-control" id="exampleSelect1">
                          <option value="">Select reference required</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                        </select>
                        <div *ngIf="submit && f.referencerequest.errors">
                          <span class="help-block text-danger">
                            Please enter references required
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-12">
                        <label for="input-5">Candidate Message</label>
                        <textarea rows="5" cols="5" formControlName="candidatemessage" class="form-control form-control-rounded"
                        id="input-5"></textarea>
                        <!-- <input formControlName="candidatemessage" type="text" class="form-control form-control-rounded" id="input-5"> -->                            
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 text-left">
                        <span *ngIf="lesssore">Sorry, You don't have any credits. Please  <a [routerLink]="['/check-add-credit']">Click Here</a> to add more.</span>
                      </div>
                      <div class="col-sm-6 text-right">
                        <input type="submit"  class="btn com_btn btn-primary"  [disabled]="!disableBtn"   *ngIf="!lesssore" name="updateform" value="Send Request" />
                        <button type="button" class="btn com_btn btn-secondary" (click)="onClear()">Clear</button>
                      </div>
                    </div>
                  </form>
                </div>
              </ng-container>
              <ng-container *ngIf="stage==1">
                <div class="personal_form">
                  <div class="modal-body">
                    <div class="container">
                      <div class="main-banner">
                        <div>
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="tankushow">
                                <div class="detailstext">
                                    <h1>Applicant reference request sent</h1>
                                    <!-- <p>Please organise a video call with the applicant and check the documents over the call before asking them to upload it.</p> -->
                                    <button class="btn com_btn btn-success small_btn" (click)="newrequest()" type="button"> New Reference Request </button>
                                    <a class="btn com_btn btn-success small_btn"  [routerLink]="['/reference-status']" >Reference Status</a>
                                </div>
                                <img alt="shape" src="../../../assets/img/thanks_new.png">
                              </div>
                            </div>
                          </div>
                        </div>               
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<style>
  .detailstext .btn{ display: inline-block;}
</style>
<jw-modal id="upgrade-plan" role="dialog" class="modal sendrequestcharge">
  <div role="document" class="formgroup">
    <div class="modal-content">         
      <div class="modal-body">
        <div class="container text-center">
          <div class="info_mess">
            <div class="para">
              <h4 class="mb-2">No credits available</h4>
              <p class="mb-0 font20">There are no credits available to perform this action. Please add more credits.</p>
            </div>                          
          </div>
        </div>
      </div>
      <div class="modal-footer text-center">   
        <input type="button" class="btn btn-primary com_btn " name="updateform" value="Add credits"  [routerLink]="['/check-add-credit']"  />
        <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('upgrade-plan');"/>
        <!-- <input type="button" class="btn btn-primary" name="updateform" value="Upgrade Plan" /> -->
      </div>
    </div>
  </div>
</jw-modal>