"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_service_1 = require("../../../services/employee.service");
var modal_service_1 = require("../../../_services/modal.service");
var permission_service_1 = require("../../../services/permission.service");
var alert_service_1 = require("../../../services/alert.service");
var customer_service_1 = require("../../../services/customer.service");
var router_1 = require("@angular/router");
var ManagepermissionComponent = /** @class */ (function () {
    function ManagepermissionComponent(employeeService, modalService, activatedRoute, customerser, router, permissiondetails, alerts) {
        this.employeeService = employeeService;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.customerser = customerser;
        this.router = router;
        this.permissiondetails = permissiondetails;
        this.alerts = alerts;
        this.th = [];
        this.emailexist = false;
    }
    ManagepermissionComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        this.ctype = parseInt(localStorage.getItem('ctype'));
        // if(ctype ==1){
        //   this.router.navigate(['/check-dashboard']);
        // }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        // console.log(permission);
        this.permissions = permission[0];
        this.userperm = this.permissions.m2;
        this.empid = this.activatedRoute.snapshot.paramMap.get("adminid");
        this.customerser.getOverviewbyadmin({ 'id': this.empid }).subscribe(function (data2) {
            var permission3 = data2.permission;
            _this.overviewData = data2;
            _this.permission = data2.permission;
            _this.emplyeeid = _this.empid;
            _this.permissiondetails.bindallmenu().subscribe(function (menudata) {
                console.log(menudata);
                menudata.forEach(function (valuemenu) {
                    valuemenu.submenu.forEach(function (submenudata, index) {
                        var tg;
                        tg = JSON.parse(permission3);
                        tg.forEach(function (value4) {
                            var kdd = valuemenu.clname;
                            if (value4[kdd] == submenudata.mright) {
                                valuemenu.submenu[index].ch = true;
                            }
                            else {
                                valuemenu.submenu[index].ch = false;
                            }
                        });
                    });
                });
                _this.menulist = menudata;
            });
        });
    };
    ManagepermissionComponent.prototype.onchange = function (ind, subm) {
        var tg;
        tg = JSON.parse(this.permission.toString());
        tg.forEach(function (value4) {
            value4[ind] = subm;
        });
        var starry = JSON.stringify(tg);
        var dt = { "permission": starry, "employeeid": this.emplyeeid };
        this.permission = starry;
        console.log('This permison master', dt);
        this.permissiondetails.updateemployeepermission(dt).subscribe(function (employeedata) {
        });
    };
    ManagepermissionComponent.prototype.bindallmenu = function () {
        var _this = this;
        this.employeeService.getlistofemployee().subscribe(function (employeedata) {
            employeedata.forEach(function (value) {
                var tg;
                tg = JSON.parse(value.permission.toString());
                tg.forEach(function (value4) {
                });
            });
            _this.data = employeedata;
        });
    };
    Object.defineProperty(ManagepermissionComponent.prototype, "f", {
        get: function () { return this.employeeForm.controls; },
        enumerable: true,
        configurable: true
    });
    ManagepermissionComponent.prototype.onSubmit = function (employeeform1, modelid) {
    };
    ManagepermissionComponent.prototype.addadmin = function (id) {
        var _this = this;
        if (confirm("Are you sure you want to add this user in admin list?")) {
            var prm = { "employeeid": id, "id": this.Userid, "isadmin": true };
            this.permissiondetails.updateisadmin(prm).subscribe(function (employeedata) {
                _this.alerts.success('User successfully updated as admin.', true);
                _this.bindallmenu();
            });
        }
    };
    ManagepermissionComponent.prototype.removeadmin = function (id) {
        var _this = this;
        if (confirm("Are you sure you want to remove this user from admin list?")) {
            var prm = { "employeeid": id, "id": this.Userid, "isadmin": false };
            this.permissiondetails.updateisadmin(prm).subscribe(function (employeedata) {
                _this.alerts.success('User successfully removed as admin.', true);
                _this.bindallmenu();
            });
        }
    };
    ManagepermissionComponent.prototype.openModal = function (id, employee, employeeid) {
        var _this = this;
        this.submitted = false;
        this.permission = employee.permission;
        this.emplyeeid = employeeid;
        this.permissiondetails.bindallmenu().subscribe(function (menudata) {
            menudata.forEach(function (valuemenu) {
                valuemenu.submenu.forEach(function (submenudata, index) {
                    var tg;
                    tg = JSON.parse(employee.permission.toString());
                    tg.forEach(function (value4) {
                        var kdd = valuemenu.clname;
                        if (value4[kdd] == submenudata.mright) {
                            valuemenu.submenu[index].ch = true;
                        }
                        else {
                            valuemenu.submenu[index].ch = false;
                        }
                    });
                });
            });
            _this.menulist = menudata;
            // console.log(this.menulist);
        });
        this.modalService.open(id);
    };
    ManagepermissionComponent.prototype.closeModal = function (id) {
        var _this = this;
        this.employeeService.getlistofemployee().subscribe(function (employeedata) {
            _this.data = employeedata;
            _this.modalService.close(id);
        });
    };
    return ManagepermissionComponent;
}());
exports.ManagepermissionComponent = ManagepermissionComponent;
