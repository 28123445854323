"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var candidate_service_1 = require("../../../services/candidate.service");
var job_service_1 = require("../../../services/job.service");
var modal_service_1 = require("../../../_services/modal.service");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var alert_service_1 = require("../../../services/alert.service");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var CandidatedocumentComponent = /** @class */ (function () {
    function CandidatedocumentComponent(sanitizer, job, router, candidateService, uploadService, modalService, alerts) {
        this.sanitizer = sanitizer;
        this.job = job;
        this.router = router;
        this.candidateService = candidateService;
        this.uploadService = uploadService;
        this.modalService = modalService;
        this.alerts = alerts;
    }
    CandidatedocumentComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.docperm = this.permission.m8;
        this.candidateService.getcandidatebycompanyList().subscribe(function (employeedata) {
            _this.candidatelist = employeedata;
        });
        this.job.getjobList().subscribe(function (data) {
            _this.joblist = data;
        });
    };
    CandidatedocumentComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    CandidatedocumentComponent.prototype.onChange = function (newValue) {
        var _this = this;
        this.jobid = newValue;
        this.Userid = localStorage.getItem('userid');
        this.candidateService.getcandidateList({ "id": this.Userid, "jobid": this.jobid }).subscribe(function (employeedata) {
            _this.candidatelist = employeedata;
        });
    };
    CandidatedocumentComponent.prototype.bindallcandidatedocument = function (id, candidateformid) {
        var _this = this;
        this.candidateid = candidateformid;
        // console.log(candidateformid);
        this.Userid = localStorage.getItem('userid');
        this.candidateService.getdocumentlist({ "id": this.Userid, "candidateid": candidateformid }).subscribe(function (candidatedocument) {
            _this.candidatedocumentlist = candidatedocument;
        });
        this.modalService.open(id);
    };
    CandidatedocumentComponent.prototype.deleteFile = function (id) {
        var _this = this;
        var deleteData = {};
        deleteData = { 'id': id, 'candidateid': this.candidateid };
        if (confirm("Are you sure you want to delete?")) {
            this.candidateService.deletecandidatedocumentFile(deleteData).subscribe(function (data) {
                _this.candidateService.getdocumentlist({ "id": _this.Userid, "candidateid": _this.candidateid }).subscribe(function (candidatedocument) {
                    _this.candidatedocumentlist = candidatedocument;
                });
            });
        }
    };
    CandidatedocumentComponent.prototype.selectFile = function (event) {
        var _this = this;
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        var file = this.selectedFiles;
        var imagepath = this.uploadService.uploadfile(file);
        var documentname = selectedFiles.name.substring(0, 5);
        var forupdate = { 'documentname': documentname, 'id': "0", 'documentimageurl': imagepath, 'candidateid': this.candidateid, 'userid': this.Userid };
        this.candidateService.candidatedocumentupdatebyadmin(forupdate).subscribe(function (data) {
            _this.candidateService.getdocumentlist({ "id": _this.Userid, "candidateid": _this.candidateid }).subscribe(function (candidatedocument) {
                _this.candidatedocumentlist = candidatedocument;
            });
            // setTimeout(() => this.overviewData.imagename = imagepath, 2000);
        });
        this.alerts.success('Candidate document uploaded successfully.', true);
    };
    CandidatedocumentComponent.prototype.openModalcandidate5 = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgcandidatesrc = true;
            this.iframecandidatesrc = data;
        }
        else {
            this.imgcandidatesrc = false;
            this.iframecandidatesrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    CandidatedocumentComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return CandidatedocumentComponent;
}());
exports.CandidatedocumentComponent = CandidatedocumentComponent;
