import { HeaderComponent } from './../header/header.component';
import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { ModalService } from '../../_services/modal.service';
import { Address } from '../../models/address/address.model';
import { CountryService } from '../../services/country.service';
import { MyaddressService } from '../../services/myaddress.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from './../../services/common.service';
import { MatOptionSelectionChange } from '@angular/material';
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})

export class AddressComponent implements OnInit {
  address = new Address();
  countrylist: any;
  addresslistDetail: any;
  statelist: any[];
  addpopForm: FormGroup;
  submitted: boolean;
  options = [];
  mobilecheck: any;
  phonecheck: any;
  defaultVal: any = "";

  constructor(private commonService: CommonService, private formBuilder: FormBuilder, private data: CustomerService, private country: CountryService, private modalService: ModalService, private data2: MyaddressService, private alerts: AlertService) { }

  ngOnInit() {
    this.addpopForm = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      cityname: ['', Validators.compose([Validators.required,])],
      stateid: [''],
      countryid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      pincode: ['', Validators.compose([Validators.required,])],
      mobilenumber: [''],
      // mobilenumber: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{10}")])],
      phonenumber: [''],
      // phonenumber: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{10}")])],
      statename: ['', Validators.required]
    });

    this.addresslistDetail = new Address();
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.data.getAddress().subscribe((data: any) => {
      // this.addresslistDetail.country_id = data.countryid;
      // console.log(data);
      this.address = data;
      if (data) {
        this.addresslistDetail.country_id = data.countryid;
      }
    });

    const data = { 'id': this.addresslistDetail.country_id };
    this.country.getstatelist(data).subscribe((state: any) => {
      return this.statelist = state
    });
  }
  get f() { return this.addpopForm.controls; }

  onSubmit(value3: any, id: any) {
    this.submitted = true;
    var pinc = value3.pincode.split(',');
    value3.pincode = pinc[pinc.length - 1];
    if (this.addpopForm.invalid) {
      return;
    }

    this.data2.myaddressdetailsUpdate(value3).subscribe((data: any) => {
      this.data.getAddress().subscribe((data: any) => {
        this.address = data;
      });
      //  this.msg = "success";
    });
    this.alerts.success('Address updated successfully', true);
    this.modalService.close(id);
  }

  onSearchChange(searchValue) {
    if (searchValue.length > 4) {
      var newArray = [];
      this.commonService.getAddress(searchValue).subscribe((data: any) => {
        var newdata: any;
        newdata = data;
        newdata.addresses.forEach(function (value) {
          newArray.push(value + "," + searchValue);
        });
        this.options = newArray;
      });
    }
    else {
      this.options = [];
    }
  }

  displayFn(value: any) {
    if (value != null) {
      var res = value.split(",");
      if (res.length > 6) {
        return res[7];
      }
      else {
        return value;
      }
    }
  }

  updatecode(evt: MatOptionSelectionChange, response) {
    res = [];
    if (evt.source.selected) {
      var res = response.split(",");
      this.addresslistDetail.address1 = res[0];
      this.addresslistDetail.address2 = res[1];
      this.addresslistDetail.cityname = res[5];
      this.addresslistDetail.pincode = res[7];
      this.addresslistDetail.statename = res[6];
      // const data = { 'id': this.addresslistDetail.countryid };
      // this.country.getstatelist(data).subscribe((state: any) => {
      //   for (var i = 0; i < state.length; i++) {
      //     if (state[i]['statename'].trim().toLowerCase() == res[6].trim().toLowerCase()) {
      //       var inde = state[i]['stateid'];
      //     }
      //   }
      //   this.addresslistDetail.stateid = inde;
      //   this.statelist = state;
      // });
    }
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
      if (type == 'mobile') {
        this.mobilecheck = getdata.valid;
      }
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  onSelectCountry(country_id: number) {
    const data = { 'id': country_id };
    this.country.getstatelist(data).subscribe((state: any) => {
      return this.statelist = state
    });
  }

  openModal6(id: string, data: any) {
    this.modalService.open(id);
    this.addresslistDetail = new Address();
    this.addresslistDetail = data;
  }

  closeModal(id: string) {
    // this.addresslistDetail = new Address();
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.modalService.close(id);
  }

  modalOpen(data: any) {
    this.addresslistDetail = new Address();
    this.addresslistDetail = data;
  }
}