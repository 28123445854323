"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../../_services/modal.service");
var aml_service_1 = require("../../../services/aml.service");
var router_1 = require("@angular/router");
var BuscreditsearchresultComponent = /** @class */ (function () {
    function BuscreditsearchresultComponent(modalService, amldetails, activatedRoute) {
        this.modalService = modalService;
        this.amldetails = amldetails;
        this.activatedRoute = activatedRoute;
        this.tPEPs = 0;
        this.tCurrentSanctions = 0;
        this.tDisqualifiedDirector = 0;
        this.tFinancialRegulator = 0;
        this.tInsolvency = 0;
        this.tLawEnforcement = 0;
        this.tAdverseMedia = 0;
        this.tPreviousSanctions = 0;
        this.PEPs = [];
        this.CurrentSanctions = [];
        this.DisqualifiedDirector = [];
        this.FinancialRegulator = [];
        this.Insolvency = [];
        this.LawEnforcement = [];
        this.AdverseMedia = [];
        this.PreviousSanctions = [];
    }
    BuscreditsearchresultComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.logid = this.activatedRoute.snapshot.paramMap.get("id");
        this.url = '';
        this.downloadtext = 'No Record Found <i class="fa fa-plus ml-2"></i>';
        this.ctype = parseInt(localStorage.getItem('ctype'));
        var val = { id: this.logid, atype: 1 };
        this.amldetails.getCompanylogrecord(val).subscribe(function (searchRes) {
            var listmaster = JSON.parse(searchRes.searchresult);
            if (listmaster.recordsFound > 0) {
                _this.result = true;
            }
            // console.log(listmaster);
            _this.searchresult = listmaster;
        });
    };
    BuscreditsearchresultComponent.prototype.norecordfound = function () {
        var _this = this;
        this.downloadtext = 'Loading <i class="fa fa-spinner fa-spin"></i>';
        this.amldetails.generatenorecordPdf({ id: this.logid, atype: 1 }).subscribe(function (pdfRes) {
            console.log(pdfRes.response_data.Location);
            _this.url = pdfRes.response_data.Location;
            _this.downloadtext = 'No Record Found <i class="fa fa-plus ml-2"></i>';
        });
    };
    BuscreditsearchresultComponent.prototype.dataset = function (values) {
        this.datasetlist = "";
        if (values.isPEP == true) {
            this.datasetlist = "PEP,";
        }
        if (values.isAdverseMedia == true) {
            this.datasetlist += " Adverse Media,";
        }
        if (values.isDisqualifiedDirector == true) {
            this.datasetlist += " Disqualified Director (UK Only),";
        }
        if (values.isFinancialregulator == true) {
            this.datasetlist += " Financial Regulator,";
        }
        if (values.isInsolvent == true) {
            this.datasetlist += " Insolvency (UK & Ireland),";
        }
        if (values.isLawEnforcement == true) {
            this.datasetlist += " Law Enforcement,";
        }
        if (values.isSanctionsCurrent == true) {
            this.datasetlist += " Sanction - Current,";
        }
        if (values.isSanctionsPrevious == true) {
            this.datasetlist += " Sanction - Previous,";
        }
        this.datasetlist = this.datasetlist.substring(0, this.datasetlist.length - 1);
    };
    BuscreditsearchresultComponent.prototype.openModalMastersystem = function (id, data) {
        this.AdverseMedia = [];
        this.PreviousSanctions = [];
        this.PEPs = [];
        this.LawEnforcement = [];
        this.CurrentSanctions = [];
        this.FinancialRegulator = [];
        this.DisqualifiedDirector = [];
        this.Insolvency = [];
        var rAdverseMedia = [];
        var rPreviousSanctions = [];
        var rPEPs = [];
        var rLawEnforcement = [];
        var rCurrentSanctions = [];
        var rFinancialRegulator = [];
        var rDisqualifiedDirector = [];
        var rInsolvency = [];
        this.bussinessdetails = data;
        // console.log(data);
        this.buttoninsert = "Update";
        this.modalService.open(id);
        data.business.articles.forEach(function (entry2) {
            // console.log("Second Type Development", entry2);
            entry2.categories.forEach(function (entry3) {
                if (entry3.name == 'Adverse Media') {
                    // this.tAdverseMedia =this.tAdverseMedia +1;
                    rAdverseMedia.push(entry2);
                }
                else if (entry3.name == 'PEP') {
                    // this.tPEPs =this.tPEPs +1;
                    rPEPs.push(entry2);
                }
                else if (entry3.name == 'Corporate/Business') {
                    // this.tLawEnforcement =this.tLawEnforcement +1;
                    rPreviousSanctions.push(entry2);
                }
                else if (entry3.name == 'Law Enforcement') {
                    // this.tLawEnforcement =this.tLawEnforcement +1;
                    rLawEnforcement.push(entry2);
                }
                else if (entry3.name == 'Sanction') {
                    // this.tCurrentSanctions =this.tCurrentSanctions +1;
                    rCurrentSanctions.push(entry2);
                }
                else if (entry3.name == 'Financial Regulator') {
                    // this.tFinancialRegulator =this.tFinancialRegulator +1;
                    rFinancialRegulator.push(entry2);
                }
                else if (entry3.name == 'Disqualified Director') {
                    // this.tDisqualifiedDirector =this.tDisqualifiedDirector +1;
                    rDisqualifiedDirector.push(entry2);
                }
                else if (entry3.name == 'Insolvent') {
                    // this.tInsolvency =this.tInsolvency +1;
                    rInsolvency.push(entry2);
                }
                // console.log(entry3.name); 
            });
        });
        this.AdverseMedia = rAdverseMedia;
        this.PreviousSanctions = rPreviousSanctions;
        this.PEPs = rPEPs;
        this.LawEnforcement = rLawEnforcement;
        this.CurrentSanctions = rCurrentSanctions;
        this.FinancialRegulator = rFinancialRegulator;
        this.DisqualifiedDirector = rDisqualifiedDirector;
        this.Insolvency = rInsolvency;
    };
    BuscreditsearchresultComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return BuscreditsearchresultComponent;
}());
exports.BuscreditsearchresultComponent = BuscreditsearchresultComponent;
