import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../services/common.service';



@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private commonService: CommonService) { }
  
  getlistofemployee(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/listemployee'); 
  }


  getlistofemployeeForCheck(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/listemployeeforcheck'); 
  }


  getlistofemployeerigttowork(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/listemployeerighttowork'); 
  }


  ssoUpdatestatusbyadmin(value: any): Observable<any> {
    return this.commonService.formpost('/ssoupdatestatusbyadmin', value);
  }

  getListOfEmployeeWithTotalLeave(postdata): Observable<any> {
    return this.commonService.formpost('/employee/listemployee', postdata); 
  }

  getEmployeeTotalLeaves(postdata): Observable<any> {
    return this.commonService.formpost('/employee/total-leave', postdata); 
  }

  getlistofworkingemployee(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/listworkingemployee'); 
  }
  employeewelcomeemail(value:any): Observable<any> 
  {
    return this.commonService.formpost('/employee/resendwelcomemail',value);
  }
  employeeUpdateForm1(value:any): Observable<any> 
  {
    return this.commonService.formpost('/employee/employeeupdateform1',value);
  }

  checkemployeecreate(value:any): Observable<any> 
  {
    return this.commonService.formpost('/employee/checkemployeecreate',value);
  }
  
  saveEmployeeTotalLeaves(data:any): Observable<any>
  {
    return this.commonService.formpost('/employee/save-employee-leaves',data);
  }

  employeeUpdateChangeStatus(value:any): Observable<any> 
  {
    return this.commonService.formpost('/employee/employeeupdatechangestatus',value);
  }

  getdocumentlist(value:any): Observable<any> 
  {
    return this.commonService.formpost('/employee/documentlistbyemployee',value);
  }

  employeedocumentupdatebyadmin(value:any): Observable<any> 
  {
    return this.commonService.formpost('/employee/employeedocumentupdate',value);
  }

  deleteemployeedocumentFile(value: any): Observable<any> {
    return this.commonService.formpost('/employee/employeedocumentdelete', value);
  }
  


  getemployeedocumentbyid(value: any): Observable<any> {
    return this.commonService.formpost('/employee/employeedocument', value);
  }

  updateemployeedocumentbyid(value: any): Observable<any> {
    return this.commonService.formpost('/employee/employeeupdatedocumentname', value);
  }

  getemployeelist(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/employeereport',data);   
  }

  // getemployeedeparmentgraph(): Observable<any> 
  // {
  //   return this.commonService.formpostOnlyid('/employee/employeereport');   
  // }

  employeeSubmitTermination(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/employeetermination',data);   
  }

  employeeRemoveTermination(data): Observable<any>
  {
    return this.commonService.formpost('/employee/removeemployeetermination',data); 
  }

  employeeViewTermination(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/viewemployeetermination',data);   
  }

  employeeTerminationHistory(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/getemployeeterminationhistory',data);   
  }

  getOtherInfobyadmin(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/getemployeeotherinfobyadmin',data);   
  }

  getTerminationInfobyadmin(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/getemployeeterminationinfobyadmin',data);   
  }

  getOtherInfo(data): Observable<any> 
  {
    return this.commonService.formpost('/employee/getemployeeotherinfo',data);   
  }

  employeeotherinfoUpdatebyadmin(value:any): Observable<any> 
  {
    return this.commonService.formpost('/employee/employeeotherinfoupdate',value);
  }

}
