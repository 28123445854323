<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
      <div class="container">
          <div class="tabs" id="tabs">
              <div class="tabs__content">
                  <div class="tabs__content__item active-tab">
                      <div class="content">
                          <div class="space_em row">
                              <div class="col-lg-9">
                                  <h5 class="hedingcolor">Termination Reason List
                                      <a class="mainheading_btn" (click)="addTerminationReason()"> + Add New </a>
                                  </h5>
                              </div>
                              <div class="col-lg-3">
                                  <div class="input-group">
                                      <input [(ngModel)]="searchText" placeholder="Search.." class="form-control width100">
                                  </div>
                              </div>
                             
                          </div>
                          <ng-container *ngIf="( data |  filter:{fname: searchText,mname: searchText,lname: searchText,emailid: searchText, countryname: searchText, ninumber: searchText, employeeid: searchText}) as pageOfItems">
                          <div class="job_list_section employee_list_section"
                              *ngFor="let ter_reas of pageOfItems;  let i=index">
                              
                              <div class="job_detils_date width22">
                                  <h5 class="job_profile font_17">{{ter_reas.title}}</h5>
                              </div>
                              <div class="job_detils_date width15">
                                  <p class="job_address">Parent Category</p>
                                  <p class="job_profile text-primary">{{(ter_reas.parentcategory)? ter_reas.parentcategory.title:'As Parent'}}</p>
                              </div>
                              <div class="job_detils_date width10">
                                <p class="job_address">Status</p>
                                <p class="job_profile"> {{(ter_reas.status)?'Active':'Inactive'}}</p>
                            </div>
                              <div class="job_detils_date  d-flex aligncenter">
                                  <div class="job_address">
                                    <button type="button" class="btn btn-primary com_btn" (click)="editTerminationReason(ter_reas)">
                                      <div class="tooltip_visa">
                                          <p >Edit</p>
                                      </div>											
                                      <i class="fa fa-pencil"></i>
                                    </button>
                                  </div>
                              </div>
                          </div>
                          <div class="job_list_section visa_request_section" *ngIf="pageOfItems.length === 0">
                            <div class="job_detils_date">
                                <p class="job_profile">No Record Found</p>
                            </div>
                        </div>        
                            
                        </ng-container>
                        <div class="row" *ngIf="record > 30">
                            <div class="col-lg-12 text-right">
                                <jw-pagination [items]="pageOfItems" [pageSize]="30" [maxPages]="10"
                                    (changePage)="onChangePage($event)"></jw-pagination>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<jw-modal id="termination-reason-model" role="dialog" class="modal popupaligncenter">
  <form [formGroup]="terminationReasonForm" #categoryform="ngForm" class="s12 white"
      (ngSubmit)="onSubmitTerminationReason(categoryform.value,'termination-reason-model')">
      <div role="document" class="formgroup">
          <div class="modal-content">
              <!-- <div class="modal-header">
                  <input type="hidden" formControlName="id" class="form-control" required name="id"
                      id="id" [(ngModel)]="trDetail.id">
                  <h5 class="modal-title" id="popupLabel">Termination Reason</h5>
                  <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close"
                      (click)="closeModal('termination-reason-model');">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div> -->
              <div class="modal-body">
                  <div class="container">
                      <div class="row">
                          
                          <div class="form-group col-lg-6">
                              <label>Title*</label>
                              <input type="text" formControlName="title" name="title"
                                  id="title" class="form-control"
                                  [ngClass]="{ 'is-invalid': submitted && g.title.errors }"
                                  [ngModel]="trDetail.title">
                              <div *ngIf="submitted && g.title.errors" class="invalid-feedback">
                                  <div *ngIf="g.title.errors.required">Please enter the title
                                  </div>
                              </div>
                          </div>
                          <div class="form-group col-lg-6">
                            <label>Category</label>
                            <select name="parentid" formControlName="parentid" id="parentid" [ngModel]="trDetail.parentid" class="form-control">
                              <option value="0">Leave As Parent</option>
                              <option value="{{pcat.id}}" *ngFor="let pcat of selectOptions">{{pcat.title}}</option>
                            </select>
                        </div>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Save" />
                  <input type="button" class="btn btn-secondary com_btn" name="cancel" value="Cancel"
                      (click)="closeModal('termination-reason-model');" />
              </div>
          </div>
      </div>
  </form>
</jw-modal>

