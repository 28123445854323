"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var user_service_1 = require("../../services/user.service");
var user_model_1 = require("../../models/user.model");
var common_service_1 = require("../../services/common.service");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var forms_1 = require("@angular/forms");
var customer_service_1 = require("../../services/customer.service");
var company_service_1 = require("../../services/company.service");
var alert_service_1 = require("../../services/alert.service");
// import { User} from '../../shared/user.model';
// import { EmailValidator } from '@angular/forms';
var UpdatemobileComponent = /** @class */ (function () {
    function UpdatemobileComponent(formBuilder, userService, router, alerts, commonService, csservices, company) {
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.router = router;
        this.alerts = alerts;
        this.commonService = commonService;
        this.csservices = csservices;
        this.company = company;
        this.submitted = false;
        this.isLoginError = false;
        // loading: boolean;
        this.user = new user_model_1.User();
        this.access_token = '';
        this.userid = '';
        this.name = '';
        this.isline = '';
        this.planid = '';
        this.error = '';
    }
    UpdatemobileComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        else {
            this.userService.checkaccesstokenbyid(userid).subscribe(function (data) {
                if (data.status == false) {
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('userid');
                    localStorage.removeItem('Name');
                    localStorage.removeItem('isline');
                    localStorage.removeItem('planid');
                    localStorage.removeItem('ctype');
                    localStorage.removeItem('isemail');
                    localStorage.removeItem('ismobile');
                    localStorage.removeItem('isadmin');
                    localStorage.removeItem('ispermission');
                    localStorage.removeItem('logoname');
                    localStorage.removeItem('struser');
                    localStorage.removeItem('menu');
                    _this.router.navigate(['/login']);
                }
            });
        }
        this.registerForm = this.formBuilder.group({
            mobilenumber: ['', forms_1.Validators.required]
        });
        this.user.mobilenumber = "+44";
        this.show = false;
        this.success = true;
        this.company.getcompanydetails().subscribe(function (data) {
            console.log(data.phonenumber);
            _this.mobilenumber2 = data.phonenumber;
            var ismobile = localStorage.getItem('ismobile');
            console.log(ismobile);
            if (ismobile == "true" || ismobile == "1") {
                localStorage.setItem('ismobile', "true");
                _this.router.navigate(['/choose-plan']);
            }
        });
    };
    Object.defineProperty(UpdatemobileComponent.prototype, "f", {
        get: function () { return this.registerForm.controls; },
        enumerable: true,
        configurable: true
    });
    UpdatemobileComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        console.log(this.user);
        this.company.mobileupdate(this.user).subscribe(function (data) {
            if (data.status == "success") {
                _this.company.resendmobileotp().subscribe(function (data) {
                    localStorage.setItem('otpmobile', data.otp);
                    _this.showsuccess = true;
                    _this.success = false;
                    _this.router.navigate(['/otp']);
                });
            }
            else {
                _this.showsuccess = false;
                _this.success = true;
                _this.alerts.error("This mobille number already exists. Please try another mobile number.");
                // this.show = true;
            }
        }, function (err) {
            _this.show = true;
        });
    };
    UpdatemobileComponent.prototype.resedotp = function () {
        var _this = this;
        this.submitted = false;
        this.show = false;
        this.company.resendmobileotp().subscribe(function (data) {
            localStorage.setItem('otpmobile', data.otp);
            _this.show = false;
            _this.router.navigate(['/otp']);
        }, function (err) {
            _this.isLoginError = true;
        });
    };
    UpdatemobileComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    UpdatemobileComponent.prototype.logout = function () {
        var _this = this;
        if (confirm("Are you sure you want to sign out?")) {
            this.userService.logout().subscribe(function (data) {
                localStorage.removeItem('userToken');
                localStorage.removeItem('userid');
                localStorage.removeItem('Name');
                localStorage.removeItem('isline');
                localStorage.removeItem('planid');
                localStorage.removeItem('ctype');
                localStorage.removeItem('isemail');
                localStorage.removeItem('ismobile');
                localStorage.removeItem('isadmin');
                localStorage.removeItem('ispermission');
                localStorage.removeItem('logoname');
                localStorage.removeItem('struser');
                localStorage.removeItem('menu');
                _this.router.navigate(['/login']);
            });
        }
    };
    return UpdatemobileComponent;
}());
exports.UpdatemobileComponent = UpdatemobileComponent;
