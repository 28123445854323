import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private commonService: CommonService) { }

  checkPlan(value:any): Observable<any> {
    return this.commonService.formpost('/company/checkcompanyplan',value);
  }

  getcompanyType(): Observable<any> {
    return this.commonService.formpostOnlyid('/company/companytype');
  }

  getcompanylocation(): Observable<any> {
    return this.commonService.formpostOnlyid('/companylocation');
  }

  getcompanydetails(): Observable<any> {
    return this.commonService.formpostOnlyid('/company/companydetails');
  }

  getcompanydetailsbyid(data): Observable<any> {
    return this.commonService.refereeformpost('/company/companydetailsbyid', data);
  }
  
  getinvoiceretrive(value: any): Observable<any> {
    return this.commonService.formpost('/invoiceretrieve', value);
  }

  getpaymentintent(value: any): Observable<any> {
    return this.commonService.formpost('/paymentintetretrive', value);
  }
  Creditchecksecurecard(value: any): Observable<any> {
    return this.commonService.formpost('/check/Creditchecksecurecard', value);
  }
  
  

  deleteinvoice(value: any): Observable<any> {
    return this.commonService.formpost('/deleteinvoice', value);
  }

  downloadInvoice(value: any): Observable<any> {
    return this.commonService.formpost('/downloadinvoice', value);
  }

  getcompanydefaultlocation(): Observable<any> {
    return this.commonService.formpostOnlyid('/companydefaultlocation');
  }


  getcompanylocationtype(): Observable<any> {
    return this.commonService.formpostOnlyid('/locationtype');
  }

  companylocationdetailsUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/company/companylocationupdate', value);
  }

  companylocationDeletebyadmin(value:any): Observable<any> {
    return  this.commonService.formpost('/company/deletecompanylocationbyadmin',value);
  }

  companylocationUpdatestatusbyadmin(value: any): Observable<any> {
    return this.commonService.formpost('/company/companylocationupdatestatusbyadmin', value);
  }

  emailupdate(value: any): Observable<any> {
    return this.commonService.formpost('/company/emailupdate',value);
  }

  mobileupdate(value: any): Observable<any> {
    return this.commonService.formpost('/company/mobileupdate',value);
  }

  otpupdate(): Observable<any> {
    return this.commonService.formpostOnlyid('/company/otpupdate');
  }

  mobileotpupdate(): Observable<any> {
    return this.commonService.formpostOnlyid('/company/otpmobileupdate');
  }

  resendotp(): Observable<any> {
    return this.commonService.formpostOnlyid('/company/resendotp');
  }

  resendmobileotp(): Observable<any> {
    return this.commonService.formpostOnlyid('/company/resendmobileotp');
  }
  getcompanyData(): Observable<any> {
    return this.commonService.formpostOnlyid('/company/companydata');
  }

  gettotalcards(): Observable<any> {
    return this.commonService.formpostOnlyid('/gettotalcards');
  }

  getnextbillingdate(): Observable<any> {
    return this.commonService.formpostOnlyid('/nextbillingdate');
  }

  
  getnextcompanyinformationserviview(): Observable<any> {
    return this.commonService.formpostOnlyid('/companyservicies');
  }

  getinvoicelist(): Observable<any> {
    return this.commonService.formpostOnlyid('/getallinvoice');
  }

  
  sendinvoicesend(value: any): Observable<any> {
    return this.commonService.formpost('/sendinvoice',value);
  }

  checkstripecustomer(): Observable<any> {
    return this.commonService.formpostOnlyid('/checkstripecustomer');
  }
  

  getcountCompanyData(): Observable<any> {
    return this.commonService.formpostOnlyid('/company/totalcompanycount');
  }

  getplanDetails(): Observable<any> {
    return this.commonService.formpostOnlyid('/company/companyPlanDetails');
  }

  companyprofileUpdate(value: any): Observable<any> {
    return this.commonService.formpost('/company/companyprofileupdatebyadmin', value);
  }

  updatecompanyimage(value:any): Observable<any> {
    return this.commonService.formpost('/company/companylogoupdate',value);    
  }

  checkcompanyemployeeid(value:any): Observable<any> {
    return this.commonService.formpost('/company/checkcompanyemployeeid',value);    
  }

  getemployeecontracttypelist(): Observable<any> {
    return this.commonService.formpostOnlyid('/employeecontracttypelist');
  }


}
