import { AccessToken } from './../shared/sdk/models/BaseModels';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '../app.global';
import { CommonService } from '../services/common.service';


@Injectable({
  providedIn: 'root'
})
export class MypassportService {
  [x: string]: any;
  private modals: any[] = [];
  constructor(private commonService: CommonService) {}

  getpassportList(): Observable<any> {
    return this.commonService.formpostOnlyid('/employee/employeepassportlist');      
  }
  getpassportListnew(value:any): Observable<any> {
    return this.commonService.formpost('/employee/employeepassportlistnew',value);      
  }
  getpassportListnewbyadmin(value:any): Observable<any> {
    return this.commonService.formpost('/employee/employeepassportlistnewbyadmin',value);      
  }

  getallpassportListbyadmin(value:any): Observable<any> {
    return this.commonService.formpost('/employee/employeeallpassportlistbyadmin',value);      
  }

  downloadPassport(value: any): Observable<any> {
    return this.commonService.formpost('/employee/downloadpassport',value); 
  }

  getpassportApprove(): Observable<any> {
    return  this.commonService.formpostOnlyid('/employee/employeepassportapprove');
  }

  getpassportRejected(): Observable<any> {
    return  this.commonService.formpostOnlyid('/employee/employeepassportrejected');
  }

  mypassportdetailsUpdatefile(value:any): Observable<any> {
    return  this.commonService.formpost('/fileuploadFortesting',value);
  }
  mypassportdetailsUpdate(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/passportupdate',value);
  }

  mypassportdetailsUpdaterighttowork(value:any): Observable<any> {
    return  this.commonService.refereeformpost('/employee/passportupdaterighttowork',value);
  }


  mypassportdetailsUpdatebyadmin(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/passportupdatebyadmin',value);
  }
  deletePassport(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/deletePassport',value);
  }

  mypassportdetailsCreate(value:any): Observable<any> {
    return  this.commonService.formpost('/employee/insertpassportdetails',value);
  }
 

  
  passportdetailsbypassportid(value: any): Observable<any> {
    return this.commonService.formpost('/employee/passportdetailsbypassportid',value);      
  }
  passportupdateForAI(value: any): Observable<any> {
    return this.commonService.formpost('/employee/passportupdateAIDetailsbyadmin',value);      
  }  
  passportaipaymentstaus(value: any): Observable<any> {
    return this.commonService.formpost('/employee/checkpassportaipaymentstatus',value);      
  }
}