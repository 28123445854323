"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Aml = /** @class */ (function () {
    function Aml() {
        this.Middlename = '';
        this.Surname = '';
        this.DateOfBirth = '';
        this.YearOfBirth = '';
        this.Address = '';
        this.PEP = false;
        this.PreviousSanctions = false;
        this.CurrentSanctions = false;
        this.LawEnforcement = false;
        this.FinancialRegulator = false;
        this.Insolvency = false;
        this.DisqualifiedDirector = false;
        this.AdverseMedia = false;
        this.Threshold = 20;
        this.Country = '';
        this.County = '';
        this.City = '';
        this.Postcode = '';
    }
    return Aml;
}());
exports.Aml = Aml;
var AmlBussiness = /** @class */ (function () {
    function AmlBussiness() {
        this.BusinessName = '';
        this.Country = '';
        this.County = '';
        this.City = '';
        this.Postcode = '';
        this.Address = '';
        this.PEP = false;
        this.PreviousSanctions = false;
        this.CurrentSanctions = false;
        this.LawEnforcement = false;
        this.FinancialRegulator = false;
        this.Insolvency = false;
        this.DisqualifiedDirector = false;
        this.AdverseMedia = false;
        this.Threshold = 20;
    }
    return AmlBussiness;
}());
exports.AmlBussiness = AmlBussiness;
var amldetailsclass = /** @class */ (function () {
    function amldetailsclass() {
    }
    return amldetailsclass;
}());
exports.amldetailsclass = amldetailsclass;
var person = /** @class */ (function () {
    function person() {
        this.Middlename = '';
        this.Surname = '';
        this.DateOfBirth = '';
        this.YearOfBirth = '';
        this.Address = '';
        this.Threshold = 20;
        this.Country = '';
        this.County = '';
        this.City = '';
        this.id = '';
        this.Postcode = '';
        this.imageURL = '';
    }
    return person;
}());
exports.person = person;
var aliases = /** @class */ (function () {
    function aliases() {
        this.Middlename = '';
        this.Surname = '';
    }
    return aliases;
}());
exports.aliases = aliases;
var addresses = /** @class */ (function () {
    function addresses() {
        this.address1 = '';
        this.address2 = '';
        this.address3 = '';
        this.address4 = '';
        this.city = '';
        this.county = '';
        this.postcode = '';
    }
    return addresses;
}());
exports.addresses = addresses;
var country = /** @class */ (function () {
    function country() {
        this.name = '';
    }
    return country;
}());
exports.country = country;
var politicalPositions = /** @class */ (function () {
    function politicalPositions() {
        this.description = '';
    }
    return politicalPositions;
}());
exports.politicalPositions = politicalPositions;
var notes = /** @class */ (function () {
    function notes() {
        this.text = '';
    }
    return notes;
}());
exports.notes = notes;
var adverst = /** @class */ (function () {
    function adverst() {
        this.Middlename = '';
        this.forename = '';
        this.sirname = '';
        this.postcode = '';
        this.flatnumber = '';
        this.street = '';
        this.town = '';
        this.locality = '';
    }
    return adverst;
}());
exports.adverst = adverst;
