"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_service_1 = require("../../_services/modal.service");
var stripe_angular_1 = require("stripe-angular");
var checkservice_service_1 = require("../../services/checkservice.service");
var app_global_1 = require("../../app.global");
var company_service_1 = require("../../services/company.service");
var CheckpaynowComponent = /** @class */ (function () {
    function CheckpaynowComponent(modalService, StripeScriptTag, companyService, checkService) {
        this.modalService = modalService;
        this.StripeScriptTag = StripeScriptTag;
        this.companyService = companyService;
        this.checkService = checkService;
        this.publishableKey = app_global_1.AppGlobals.API_STRIPE;
        this.paynow = false;
        this.paymentDone = false;
    }
    CheckpaynowComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.StripeScriptTag.setPublishableKey(this.publishableKey);
        this.companyService.getcompanyData().subscribe(function (data) {
            if (data.stuser) {
                _this.paymentDone = true;
            }
        });
    };
    CheckpaynowComponent.prototype.setStripeToken = function (token, id) {
        var _this = this;
        this.paynow = true;
        this.stptoken = token.id;
        var amunt = parseFloat('2');
        var data = {
            "token": token.id,
            "cmonth": token.card.exp_month,
            "cyear": token.card.exp_year,
            "cardnumber": token.card.last4,
            "tokenid": token.card.id,
            "cardname": token.card.brand,
            "country": token.card.country,
            "cstatus": "1",
            "amount": amunt
        };
        this.checkService.registereduserpayment(data).subscribe(function (payment3) {
            if (payment3.success == true) {
                _this.closeModal('custom-payment-1');
            }
        });
    };
    CheckpaynowComponent.prototype.openModal = function (id) {
        this.modalService.open(id);
    };
    CheckpaynowComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    CheckpaynowComponent.prototype.paymentstripe = function (id) {
        this.paynow = false;
        this.modalService.open(id);
    };
    CheckpaynowComponent.prototype.onStripeError = function (error) {
        console.error('Stripe error', error);
    };
    return CheckpaynowComponent;
}());
exports.CheckpaynowComponent = CheckpaynowComponent;
