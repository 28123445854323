"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var country_service_1 = require("../../../services/country.service");
var myidproof_service_1 = require("../../../services/myidproof.service");
var modal_service_1 = require("../../../_services/modal.service");
var forms_1 = require("@angular/forms");
var idproof_model_1 = require("../../../models/idproof/idproof.model");
var upload_file_service_1 = require("./../../../services/upload-file.service");
var platform_browser_1 = require("@angular/platform-browser");
// import { Router } from '@angular/router';
var router_1 = require("@angular/router");
// import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
var alert_service_1 = require("../../../services/alert.service");
var checksystem_service_1 = require("../../../services/checksystem.service");
var CheckdocumentComponent = /** @class */ (function () {
    function CheckdocumentComponent(sanitizer, formBuilder, uploadService, data, country, modalService, router, alerts, check) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.uploadService = uploadService;
        this.data = data;
        this.country = country;
        this.modalService = modalService;
        this.router = router;
        this.alerts = alerts;
        this.check = check;
        this.idprooflist = null;
        this.defaultVal = "";
        this.filterItems = [
            {
                name: 'All Address Proof',
                id: 0,
                selected: true
            },
            {
                name: 'Accepted',
                id: 2,
                selected: false
            },
            {
                name: 'Rejected',
                id: 3,
                selected: false
            },
            {
                name: 'Waiting for review',
                id: 1,
                selected: false
            }
        ];
        this.aipayment = false;
    }
    CheckdocumentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.defaultVal = "";
        this.createformgroup();
        this.filedocumentname = 'Choose file';
        this.filedocumentbackname = 'Choose file';
        this.idproofDetail = new idproof_model_1.Idproof();
        var id = localStorage.getItem('userToken');
        this.Userid = localStorage.getItem('userid');
        var data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
        this.data.getidproofAllList(data).subscribe(function (data) {
            _this.idprooflist = data;
        });
        this.check.getbillinginformation({ 'serviceid': 7 }).subscribe(function (remaindata) {
            _this.ramainingChecks = remaindata;
        });
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.data.iddocumenttype().subscribe(function (documenttype) {
            _this.documenttype = documenttype;
        });
        this.listcheck = this.filterItems;
    };
    CheckdocumentComponent.prototype.createformgroup = function () {
        this.idproofpopForm = this.formBuilder.group({
            id: ['', forms_1.Validators.required],
            employeeid: ['', forms_1.Validators.required],
            idnumber: ['', forms_1.Validators.required],
            idtype: ['', forms_1.Validators.required],
            nationalityid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.min(1)])],
            docexpiry: ['', forms_1.Validators.required]
        });
    };
    CheckdocumentComponent.prototype.onDelete = function (id) {
        var _this = this;
        var data1 = { 'id': id, 'userid': this.Userid };
        if (confirm("Are you sure you want to delete?")) {
            this.data.deleteidproof(data1).subscribe(function (data) {
                var sstatus;
                for (var _i = 0, _a = _this.filterItems; _i < _a.length; _i++) {
                    var c = _a[_i];
                    if (c.selected == true) {
                        if (c.id == 0) {
                            sstatus = [1, 2, 3];
                        }
                        else {
                            sstatus = [c.id];
                        }
                    }
                }
                var data2 = { 'id': _this.Userid, 'cstatus': sstatus };
                _this.data.getidproofAllList(data2).subscribe(function (data) {
                    _this.idprooflist = data;
                });
                _this.alerts.success('Address Proof Deleted Successfully.', true);
            });
        }
    };
    CheckdocumentComponent.prototype.onlistchange = function (type1, idval) {
        var _this = this;
        var sstatus;
        for (var _i = 0, _a = this.filterItems; _i < _a.length; _i++) {
            var c = _a[_i];
            if (c.id == idval)
                c.selected = true;
            else
                c.selected = false;
        }
        if (idval == 0 || type1 == false) {
            sstatus = [1, 2, 3];
        }
        else {
            sstatus = [idval];
        }
        var userid = localStorage.getItem('userid');
        var data = { 'id': userid, 'cstatus': sstatus };
        this.data.getidproofAllList(data).subscribe(function (data) {
            _this.idprooflist = data;
        });
    };
    Object.defineProperty(CheckdocumentComponent.prototype, "f", {
        get: function () {
            return this.idproofpopForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    CheckdocumentComponent.prototype.onSubmit = function (value3, id) {
        var _this = this;
        this.submitted = true;
        this.fileformaterror = false;
        this.filevisareuirederror = false;
        this.filebackvisareuirederror = false;
        if (this.idproofpopForm.invalid) {
            return;
        }
        if (this.selectedFiles) {
            this.file = this.selectedFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagename = imagepath;
        }
        else {
            value3.imagename = this.idproofDetail.iddocName;
        }
        if (this.selectedbackFiles) {
            this.file = this.selectedbackFiles;
            var filetype = this.file.type.split('/');
            if (this.file.size > 2000 * 1024) {
                this.filesizeerror = true;
                return;
            }
            else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png") && (filetype[filetype.length - 1] != "gif") && (filetype[filetype.length - 1] != "tif") && (filetype[filetype.length - 1] != "pdf") && (filetype[filetype.length - 1] != "doc") && (filetype[filetype.length - 1] != "docx")) {
                this.fileformaterror = true;
                return;
            }
            var imagepath = this.uploadService.uploadfile(this.file);
            value3.imagebackname = imagepath;
        }
        else {
            value3.imagebackname = this.idproofDetail.idbackdocName;
        }
        if (value3.imagename == '') {
            this.filevisareuirederror = true;
            return;
        }
        if (value3.imagebackname == '') {
            this.filebackvisareuirederror = true;
            return;
        }
        this.data.myidproofdetailsUpdate(value3).subscribe(function (data) {
            var sstatus;
            for (var _i = 0, _a = _this.filterItems; _i < _a.length; _i++) {
                var c = _a[_i];
                if (c.selected == true) {
                    if (c.id == 0) {
                        sstatus = [1, 2, 3];
                    }
                    else {
                        sstatus = [c.id];
                    }
                }
            }
            var userid = localStorage.getItem('userid');
            var data1 = { 'id': userid, 'cstatus': sstatus };
            _this.data.getidproofAllList(data1).subscribe(function (data) {
                _this.idprooflist = data;
            });
        });
        this.filedocumentname = "Choose file";
        this.alerts.success('ID Proof updated successfully.', true);
        // this.idproofDetail = new idproof();
        this.modalService.close(id);
    };
    CheckdocumentComponent.prototype.selectdocumentFileupload = function (event) {
        var files = event.target.files;
        this.filedocumentname = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.fileformaterror = true;
            this.filesizeerror = false;
        }
        else {
            this.filesizeerror = false;
            this.fileformaterror = false;
            this.filereuirederror = false;
            this.imagesecond = this.uploadService.uploadfile(this.file);
            this.selectedFiles = files[0];
        }
    };
    CheckdocumentComponent.prototype.selectdocumentbackFileupload = function (event) {
        var files = event.target.files;
        this.filedocumentbackname = files[0].name;
        this.file = files[0];
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filebacksizeerror = true;
        }
        else if ((filetype[filetype.length - 1] != "jpeg") && (filetype[filetype.length - 1] != "jpg") && (filetype[filetype.length - 1] != "png")) {
            this.filebackformaterror = true;
            this.filebacksizeerror = false;
        }
        else {
            this.filebacksizeerror = false;
            this.filebackformaterror = false;
            this.imagesecond = this.uploadService.uploadfile(this.file);
            this.selectedbackFiles = files[0];
        }
    };
    // selectFile(event)
    //  {
    //   var files = event.target.files;
    //   this.filedocumentname = files[0].name;
    //   this.selectedFiles = files[0];
    // }
    CheckdocumentComponent.prototype.openModal2 = function (id) {
        // this.createformgroup();
        this.submitted = false;
        this.buttoninsert = "Submit";
        this.selectedFiles = null;
        this.fileformaterror = false;
        this.idproofDetail = new idproof_model_1.Idproof();
        this.Userid = localStorage.getItem('userid');
        this.idproofDetail.employeeid = this.Userid;
        this.idproofDetail.employeeidproofdeatilid = "0";
        this.idproofDetail.nationalityid = "70";
        this.idproofDetail.idtype = '';
        this.filedocumentname = "Choose file";
        this.filedocumentbackname = 'Choose file';
        this.modalService.open(id);
    };
    CheckdocumentComponent.prototype.getSafeUrl = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    CheckdocumentComponent.prototype.openModalidproof = function (id, data) {
        var fullpathArray = data.split('.');
        var typeoffile = fullpathArray[fullpathArray.length - 1];
        if ((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif')) {
            this.imgidproofsrc = true;
            this.iframeidproofsrc = data;
        }
        else {
            this.imgidproofsrc = false;
            this.iframeidproofsrc = this.getSafeUrl("https://docs.google.com/gview?url=" + data + "&embedded=true");
        }
        this.modalService.open(id);
    };
    CheckdocumentComponent.prototype.openModal = function (id, data) {
        this.submitted = false;
        this.selectedFiles = null;
        this.fileformaterror = false;
        this.idproofDetail = new idproof_model_1.Idproof();
        // console.log(data);  
        this.idproofDetail = data;
        this.buttoninsert = "Update";
        this.filedocumentname = "Choose file";
        this.filedocumentbackname = 'Choose file';
        this.modalService.open(id);
    };
    CheckdocumentComponent.prototype.closeModal = function (id) {
        var _this = this;
        this.idproof = new idproof_model_1.Idproof();
        // this.idproofDetail = new idproof();
        var data = { 'id': this.Userid, 'cstatus': [1, 2, 3] };
        this.data.getidproofAllList(data).subscribe(function (data) {
            _this.idprooflist = data;
        });
        this.modalService.close(id);
    };
    CheckdocumentComponent.prototype.uploadByAdmin = function (emp_type, type, empid) {
        if (this.ramainingChecks && this.ramainingChecks.remiancheck < 1) {
            this.modalService.open('upgrade-plan');
        }
        else {
            if (type == 'upload_now') {
                if (emp_type == 'visa') {
                    this.router.navigate(['/check-ai-visa-admin', empid]);
                }
            }
        }
    };
    return CheckdocumentComponent;
}());
exports.CheckdocumentComponent = CheckdocumentComponent;
