import { HeaderComponent } from '../user/header/header.component';
// import { EmployeeMaster } from './../models/employee_master';
// import { HeaderComponent } from './user/header/header.component';
import { Component, OnInit } from '@angular/core';
import { MycosService } from '../services/mycos.service';

import { DomSanitizer } from '@angular/platform-browser';


import { ModalService } from '../_services/modal.service';

@Component({
  selector: 'app-mycos',
  templateUrl: './mycos.component.html',
  styleUrls: ['./mycos.component.css']
})

export class MycosComponent implements OnInit {
  cosdetails: any[] = null;

  
  filename: any;
  filesizeerror: any;
  fileformaterror: any;
  file: any;
  cosDetails: any;
  
  empid: any;
  buttoninsert: any;
  Userid: any;
  selectedFiles: FileList;
  imgcossrc: boolean;
  disableBtn: any;
  iframecossrc: any;
  isfile: any;
  imagepath: any;

  constructor(private data: MycosService,    
    private sanitizer: DomSanitizer,
    private modalService: ModalService, ) { }

  ngOnInit() {
    this.data.getcosList().subscribe((data: any) => {
      this.cosdetails = data;
    });
  }

  
  openModal_cosdocument(id: string, data: any) {
    this.iframecossrc = '';
    var fullpathArray = data.split('.');
    var typeoffile = fullpathArray[fullpathArray.length - 1];
    if((typeoffile == 'jpeg') || (typeoffile == 'jpg') || (typeoffile == 'png') || (typeoffile == 'gif') || (typeoffile == 'tif'))
    {
     this.imgcossrc = true;
     this.iframecossrc = data; 
    }
    else{
     this.imgcossrc = false;
     this.iframecossrc = this.getSafeUrl("https://docs.google.com/gview?url="+data+"&embedded=true");
    }
     this.modalService.open(id);
   }
   
  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }


  
  closeModal(id: string) {
    // this.employeementDetail = new Cos();
    
    this.modalService.close(id);
  }
}