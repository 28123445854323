import { Injectable } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class DirectorService {

  constructor(
    private commonService: CommonService
  ) { }

  getdirectordata(data): Observable<any> 
  {
    return this.commonService.formpost('/director/getdirectordata',data);   
  }

  getcompanydata(data): Observable<any> 
  {
    return this.commonService.formpost('/director/getcompanydata',data);   
  }


  getNorecordcompanydata(data): Observable<any> 
  {
    return this.commonService.formpost('/director/downloadnorecorddirector',data);   
  }


  searchdirector(data): Observable<any>
  {
    return this.commonService.formpost('/director/searchdirector',data);  
  }

  // getcompanyimages(data): Observable<any> 
  // {
  //   return this.commonService.formpost('/company/getimages',data);   
  // }

  getdirectorhistory(): Observable<any> 
  {
    return this.commonService.formpostOnlyid('/director/getdirectorhistory');   
  }

  getdirectorhistoryview(data): Observable<any> 
  {
    return this.commonService.formpost('/director/getdirectorhistoryview',data);   
  }

  downloaddirectordata(data): Observable<any> 
  {
    return this.commonService.formpost('/director/downloaddirector',data);   
  }

}
