"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var idproof_model_1 = require("../../models/idproof/idproof.model");
var upload_file_service_1 = require("../../services/upload-file.service");
var employee_model_1 = require("../../models/employee.model");
var fileupload_service_1 = require("../../services/fileupload.service");
var myidproof_service_1 = require("../../services/myidproof.service");
var alert_service_1 = require("../../services/alert.service");
var IdaiverifyComponent = /** @class */ (function () {
    function IdaiverifyComponent(sanitizer, formBuilder, idproofService, activatedRoute, filupload, uploadService, alerts, router) {
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.idproofService = idproofService;
        this.activatedRoute = activatedRoute;
        this.filupload = filupload;
        this.uploadService = uploadService;
        this.alerts = alerts;
        this.router = router;
        this.idproofdetails = new idproof_model_1.Idproof();
        this.employee = new employee_model_1.Employee();
    }
    IdaiverifyComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userid = localStorage.getItem('userid');
        if (!userid) {
            this.router.navigate(['/login']);
        }
        var ctype = parseInt(localStorage.getItem('ctype'));
        if (ctype == 1) {
            this.router.navigate(['/check-dashboard']);
        }
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        this.permission = permission[0];
        this.requestperm = this.permission.m15;
        this.ctype = parseInt(localStorage.getItem('ctype'));
        this.employeeidproofdeatilid = this.activatedRoute.snapshot.paramMap.get("idproofid");
        this.data3 = { "employeeidproofdeatilid": this.employeeidproofdeatilid };
        this.idproofService.idproofdetailsbyidproofid(this.data3).subscribe(function (data) {
            _this.employeemasterid = data.employee.employeemasterid;
            _this.idproofdetails = data;
            _this.record = data.length;
        });
    };
    IdaiverifyComponent.prototype.selectFile = function (event, type) {
        var _this = this;
        type = parseInt(type);
        var files = event.target.files;
        var selectedFiles = files[0];
        this.selectedFiles = selectedFiles;
        var file = this.selectedFiles;
        this.fileformaterror = false;
        this.filesizeerror = false;
        this.file = this.selectedFiles;
        var filetype = this.file.type.split('/');
        if (this.file.size > 2000 * 1024) {
            this.filesizeerror = true;
            this.alerts.success('File size must be less than 2 MB.', true);
            return;
        }
        else if ((filetype[filetype.length - 1].toLowerCase() != "jpeg") && (filetype[filetype.length - 1].toLowerCase() != "jpg") && (filetype[filetype.length - 1].toLowerCase() != "png")) {
            this.fileformaterror = true;
            this.alerts.success('Please choose a valid file format (jpg, png,ttf,gif)', true);
            return;
        }
        var imagepath = this.uploadService.uploadfile(file);
        if (type == 0) {
            this.idproofdetails.iddocName = '../../../../../../../assets/img/loadings.gif';
        }
        else if (type == 1) {
            this.idproofdetails.idbackdocName = '../../../../../../../assets/img/loadings.gif';
        }
        // this.idproofdetails.iddocName = '../../../../../../assets/img/loadings.gif';
        // this.idproofdetails.iddocName = imagepath;
        var dbimage = { "id": this.employeeidproofdeatilid, "imagename": imagepath, "employeid": this.employeemasterid, 'ttype': type };
        this.idproofService.idproofupdateForAI(dbimage).subscribe(function (data) {
            if (type == 0) {
                setTimeout(function () { return _this.idproofdetails.iddocName = imagepath; }, 3000);
            }
            else if (type == 1) {
                setTimeout(function () { return _this.idproofdetails.idbackdocName = imagepath; }, 3000);
            }
            // setTimeout(() => this.idproofdetails.iddocName  = imagepath, 3000);        
        });
    };
    return IdaiverifyComponent;
}());
exports.IdaiverifyComponent = IdaiverifyComponent;
