import { Component, OnInit, Output, EventEmitter,Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { checkbiling } from '../../models/plan.model';
import { ChecksystemService } from '../../services/checksystem.service';
import { StripeScriptTag } from "stripe-angular";
import { AppGlobals } from '../../app.global';
import { CheckserviceService} from '../../services/checkservice.service';
import { ModalService } from '../../_services/modal.service';
import { StripeToken } from "stripe-angular";
import { DomSanitizer } from '@angular/platform-browser';
// import { Stripe , loadStripe } from "@stripe/stripe-js";
import { CompanyService } from '../../services/company.service';
import { CountryService } from '../../services/country.service';
import { Address } from '../../models/address/address.model';
import { PaymentService } from '../../services/payment.service';
import { AlertService } from '../../services/alert.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  @Output("getRemainingChecks")
  getRemainingChecks: EventEmitter<any> = new EventEmitter();
  iscustomer:boolean =false;
  errormsg:any;
  remainitem = new checkbiling();
  tab1:any = false;
  tab2:any = false;
  tab3:any = false;
  tab4:any = false;
  wizard1:any = false;
  wizard2:any = false;
  wizard3:any = false;
  wizard4:any = false;
  paymentaut:any =true;
  iframepaymentautsrc:any ='';
  services: any[];
  serviceArray: any = [];
  serviceObjectArray: any = [];
  gtotal: any = 0;
  tax: any = 0;
  tax_rate: any = 20;
  paynow = false;
  stptoken: any;
  leaves: any;
  showAddCheck: any = false;
  pricecheck:any;
  paymentloading:any =false;
  changetoggle:any;
  isviewcart:any;
  invalidError:any;
  onStripeError:any;
  extraData:any;
  countrylist: any;
  serviceArraycredit: any;
  paymentDone: any = false;
  defaultcard:any;
  addresslistDetail:any;
  private publishableKey: string = AppGlobals.API_STRIPE;
  addpopForm: FormGroup;
  cardlist: any;
  customerData: any;
  selectedCard: any;
  cvvError: any = false;
  submitted: any = false;
  display:any;
  paymentcheck: any = false;

  ctype: number;


  constructor(@Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    public StripeScriptTag: StripeScriptTag,
    private check: ChecksystemService,
    private checkService: CheckserviceService,
    private modalService: ModalService,
    private companyService: CompanyService,
    public paymentService: PaymentService,
    private alerts: AlertService,
    private country: CountryService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {

console.log('select All check');

    // this.iframepaymentautsrc = this.getSafeUrl('http://localhost:4200/check-add-credit');
    this.errormsg ='';
    this.StripeScriptTag.setPublishableKey(this.publishableKey);
    this.wizard1 = true;
    this.tab1 = true;
    this.isviewcart = false;
    this.paymentloading = false;
    this.changetoggle = false;
    this.getRemainingCheckss();
    this.remainitem = new checkbiling();
    this.checkService.getAllServices().subscribe((services: any) => {
      this.services = services;

      this.selectedService();      
      console.log('services list',this.services);

      this.ctype  = parseInt(localStorage.getItem('ctype'));      
      if(this.ctype === 3)
      { 
          this.onFilterChange(11);
          this.isviewcart = true;
      }
      else if(this.ctype ===2)
      {
        // this.onFilterChange(10);
        this.isviewcart = true;
      }
    });

    

    this.addpopForm = this.formBuilder.group({
      name: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      cityname: ['', Validators.compose([Validators.required,])],   
      statename: ['', Validators.required],
      pincode: ['', Validators.required],     
      stateid: [''], 
      taxid: [''],       
      countryid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      cardid: [''],
      customerid: ['']
    });

    


    this.companyService.gettotalcards().subscribe((data: any) => {
      data.card.forEach(card => {
        if(card.id==data.customer.default_source){
          this.defaultcard = card;
        }
      });
      this.cardlist = data.card;
      this.customerData = data.customer;
    });


    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    
    this.addresslistDetail = new Address();    
    this.addresslistDetail.countryid ='70';

    
   
    
  }

  viewcart(){
    this.isviewcart = true;
  }

  viewcheck(){
    this.paymentcheck = false;
    if(this.ctype === 3){ 
      this.wizard3 = false;
      this.onFilterChange(11);
      this.isviewcart = true;
    } else{
      this.selectedService();
      this.isviewcart = false;
      this.toggle(1);
    }
    
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
       if (!pattern.test(inputChar)) {
          event.preventDefault();
      }
  }

  getRemainingCheckss()
  {
    this.companyService.getcompanyData().subscribe((data: any) => {
      if(data.stuser)
      {
        this.paymentDone = true;
      }
    });

    this.companyService.getcompanyData().subscribe((data: any) => {
      if(data.verifiedforcheck==1)
      {
        this.showAddCheck = true;
      }
    });
    var ts ={};
    this.check.getAllServices().subscribe((data: any) => { 
      this.serviceArraycredit = data;

      console.log('system', data);


      this.leaves = []; 
      this.serviceArraycredit.forEach(element => {
        ts  = { 'serviceid': element.id };
        this.check.getbillinginformation(ts).subscribe((remaindata: any) => {



          
          this.leaves.push({
            orderby: element.orderby,
            id: element.id,            
            servicename: element.servicename,
            totalcheck: remaindata.totalcheck,
            usedcheck: remaindata.usedcheck,
            remiancheck: remaindata.remiancheck,
            displayicon: element.displayicon
          });

          console.log('leaves', data);
          
          this.leaves.sort((a,b)=> {return a.orderby>b.orderby });  
        });
      });
    });
  }

  get g() { return this.addpopForm.controls; }

  selectedService()
  {

    this.services.forEach(element => {
      if(this.serviceArray.indexOf(element.id) > -1)
      {
        var exist = false;
        this.serviceObjectArray.forEach(checkEle => {
          if(checkEle.id==element.id)
          {
            exist = true;
          }
        });
        if(!exist){
          element.quantity = 1;
          element.total = element.prices;
          if(element.servicefee>0){
            element.taxable_amount = element.servicefee;
          } else{
            element.taxable_amount = element.prices;
          }
          this.tax = this.tax +(element.taxable_amount)*this.tax_rate/100;
          this.gtotal = this.gtotal+element.total;
          this.serviceObjectArray.push(element);
        }
      }
      else{
        this.serviceObjectArray.forEach((checkEle, index) => {
          if(checkEle.id==element.id)
          {
            this.tax = this.tax -(checkEle.taxable_amount*checkEle.quantity)*this.tax_rate/100;
            this.gtotal = this.gtotal-((checkEle.prices)*checkEle.quantity);
            this.serviceObjectArray.splice(index, 1);
          }
        });
      }

      if(this.serviceObjectArray.length ==0){
        this.wizard1 = true;
        this.isviewcart = false;
      }
    });
    // console.log(this.serviceObjectArray);
    
  }

  onFilterChange(eve: any)
  {
    const index = this.serviceArray.indexOf(Number(eve), 0);
    if (index > -1) {
      this.serviceArray.splice(index, 1);
    }
    this.serviceArray.push(Number(eve));
    // if(eve.target.checked == false)
    // // {
    //   const index = this.serviceArray.indexOf(Number(eve), 0);
    //   if (index > -1) {
    //     this.serviceArray.splice(index, 1);
    //   }
    // }
    // else
    // {
     
    // }
    this.selectedService();
  }

  onDeleteFilter(id)
  {
    // const ele = <HTMLInputElement> document.getElementById("inlineRadio"+id);
    // ele.checked = false;
    const index = this.serviceArray.indexOf(Number(id), 0);
    this.serviceArray.splice(index, 1);
    this.selectedService();
  }

  inputVal(qty,id)
  {
    this.serviceObjectArray.forEach((checkEle, index) => {
      if(checkEle.id==id)
      {
        if(qty==''||qty==0)
        {
          qty = 1;
        }
        this.tax = this.tax - this.serviceObjectArray[index].taxable_amount*this.serviceObjectArray[index].quantity*this.tax_rate/100;
        this.serviceObjectArray[index].quantity=qty;
        this.gtotal = this.gtotal-this.serviceObjectArray[index].total;
        this.serviceObjectArray[index].total = this.serviceObjectArray[index].quantity * this.serviceObjectArray[index].prices;
        this.tax = this.tax + this.serviceObjectArray[index].taxable_amount*this.serviceObjectArray[index].quantity*this.tax_rate/100;
        this.gtotal = this.gtotal+this.serviceObjectArray[index].total;
      }
    });
  }

  inc_qty(id)
  {
    this.serviceObjectArray.forEach((checkEle, index) => {
      if(checkEle.id==id)
      {
        this.serviceObjectArray[index].quantity++;
        this.tax = this.tax +(this.serviceObjectArray[index].taxable_amount)*this.tax_rate/100;
        this.serviceObjectArray[index].total = this.serviceObjectArray[index].quantity * this.serviceObjectArray[index].prices;
        this.gtotal = this.gtotal+this.serviceObjectArray[index].prices;
      }
    });
  }

  dec_qty(id)
  {
    this.serviceObjectArray.forEach((checkEle, index) => {
      if(checkEle.id==id)
      {
        this.serviceObjectArray[index].quantity--;
        this.tax = this.tax -(this.serviceObjectArray[index].taxable_amount)*this.tax_rate/100;
        this.serviceObjectArray[index].total = this.serviceObjectArray[index].quantity * this.serviceObjectArray[index].prices;
        this.gtotal = this.gtotal-this.serviceObjectArray[index].prices;
      }
    });
  }

  toggle(id) {
    if(id == 1){
      this.wizard2 = false;
      this.wizard1 = true;
      this.wizard3 = false;this.wizard4 = false;
      this.checkService.getAllServices().subscribe((services: any) => {
        this.services = services;
        this.selectedService();
      });
    }
    if(id == 2){
      this.wizard2 = true;
      this.wizard1 = false;
      this.wizard3 = false;this.wizard4 = false;
    }
    if(id == 3){
      this.wizard3 = true;
      this.wizard1 = false;
      this.wizard2 = false; this.wizard4 = false;
    } 
    if(id == 4){
      this.wizard3 = false;
      this.wizard4 = true;
      this.wizard1 = false;
      this.wizard2 = false;
    }
  }

  toggle2(id) {
    if(id == 1){
      this.wizard2 = false;
      this.wizard1 = true;
      this.wizard3 = false;
    }
    if(id == 2){
      this.wizard3 = false;
      this.wizard1 = false;
      this.wizard2 = true;
    }
  }

  // async sessPay(id){
  //   const stripe = await this.stripePromise;
  //   stripe.redirectToCheckout({
  //           sessionId: id
  //         }).then(function (result) {
  //           console.log(result);
  //         });
  // }

   paymentstripe(id, conID, cvv)
   {
     
    if(cvv.length != 3){
      this.cvvError = true;
      return;
    }
    if(this.paynow == false)
    {
      this.modalService.close(conID);

      this.wizard1 = false;
      this.wizard2 = false;
      this.wizard3 = false;
      this.paymentcheck = true;
      this.paymentloading = true;
      
      this.paynow = false;

      this.checkService.checkrestripecustomer().subscribe((payment: any) => {

        if(payment.success == true){

          var default_card;
          if(this.defaultcard)
          {
            default_card = this.defaultcard.id
          }

            this.checkService.checkrepayment({"cardid":default_card, "cvv": cvv, "amount": parseFloat(this.gtotal+ this.tax),"products":this.serviceObjectArray,"tax_rate": this.tax_rate}).subscribe((payment3: any) => {
            if(payment3.success == false)
            {

              localStorage.setItem('productstring', JSON.stringify(this.serviceObjectArray));
              this.document.location.href = payment3.url;
              this.paynow = false;
              this.iscustomer = true;
              this.wizard2 = true;
              this.paymentcheck = false;
              this.paymentaut = false;
              this.paymentloading = false;              
            }
           
              // var card = payment3.card;
              // var saveOrder = {
              //   "token": "",
              //   "data": this.serviceObjectArray,
              //   "prices": this.gtotal,
              //   "tax": this.tax,
              //   "cityname": this.defaultcard.address_city,
              //   "country": this.defaultcard.address_country,
              //   "address1": this.defaultcard.address_line1,
              //   "address2": this.defaultcard.address_line2,
              //   "statename": this.defaultcard.address_state,
              //   "postcode": this.defaultcard.address_zip,
              //   "brand": this.defaultcard.brand,
              //   "last4": this.defaultcard.last4,
              //   "expmonth": this.defaultcard.exp_month,
              //   "expyear": this.defaultcard.exp_year,
              //   "fullname": this.defaultcard.name
              // };
              // this.checkService.completeorder(saveOrder).subscribe((payment: any) => {
              //   this.tax = 0;
              //   this.gtotal = 0;
              //   this.getRemainingChecks.emit();
              //   this.serviceArray =[];
              //   this.getRemainingCheckss();
              //   this.serviceObjectArray = [];
              //   this.modalService.close(conID);
              //   this.toggle('3'); 
              //   this.paymentloading = false;
              // });
            
          });
        }
        else
        {
          this.iscustomer = false;
          this.modalService.open(id);
        }
      });
    }
  }

  setStripeToken(token: StripeToken,formvalue:any, id: any) {
    if(this.paynow == false)
    {
      this.submitted = true;
      if(this.addpopForm.invalid){
        return;
      }
      var country = '';
      this.countrylist.forEach(element => {
        if(formvalue.countryid == element.countryid){
          country = element.countryname;
        }
      });

      // this.wizard1 = false;
      // this.wizard2 = false;
      // this.wizard3 = false;
      // this.paymentloading = true;
      // this.paynow = true;
      this.stptoken = token.id;
      var amunt =parseFloat(this.gtotal+ this.tax);
      var data = {
        "id": token.id,
        "customer": this.customerData.id,
        "cmonth": token.card.exp_month,
        "cyear": token.card.exp_year,
        "cardnumber": token.card.last4,
        "tokenid": token.card.id,
        "cardname": token.card.brand,
        "country": token.card.country,
        "cstatus": "1",
        "cardholdername": formvalue.name,
        "address1": formvalue.address1,
        "address2": formvalue.address2,
        "cityname": formvalue.cityname,
        "statename": formvalue.statename,
        "cardcountry": country,
        "postcode": formvalue.pincode,
        "taxid": formvalue.taxid,
        "amount": amunt
      };

      this.paymentService.addnewCard(data).subscribe((newCardData: any) => {
        if (newCardData.success == "success") {
          this.companyService.gettotalcards().subscribe((data: any) => {
            // console.log(data);
            data.card.forEach(card => {
              if(card.id==newCardData.data.id){
                this.defaultcard = card;
              }
            });
            this.alerts.success("Card has been successfully added.", true);
            this.cardlist = data.card;          
            this.modalService.close(id);
          });
        }
        else
        {          
          this.alerts.error(newCardData.message, true);
        }
      });

      // this.checkService.orderpayment(data).subscribe((payment3: any) => {
      //   console.log(payment3);
      //   if(payment3.success == true)
      //   {
      //     var card = payment3.card;
      //     var saveOrder = {
      //       "token": "",
      //       "data": this.serviceObjectArray,
      //       "prices": this.gtotal,
      //       "tax": this.tax,
      //       "cityname": card.address_city,
      //       "country": card.address_country,
      //       "address1": card.address_line1,
      //       "address2": card.address_line2,
      //       "statename": card.address_state,
      //       "postcode": card.address_zip,
      //       "brand": card.brand,
      //       "last4": card.last4,
      //       "expmonth": card.exp_month,
      //       "expyear": card.exp_year,
      //       "fullname": card.name
      //     };
      //     this.checkService.completeorder(saveOrder).subscribe((payment: any) => {
      //       this.getRemainingChecks.emit();
      //       this.serviceObjectArray = [];
      //       this.serviceArray =[];
      //         this.toggle('3'); this.paymentloading = false;
      //         this.closeModal('custom-payment-1');
      //     });
      //   }
      // });
    }

  }

 checkinovoice(id)
 {
  var data = { 'invoiceid': id };
  this.companyService.getinvoiceretrive(data).subscribe((invoiceData: any) => {
    console.log(invoiceData); 
    return  invoiceData;          
  });
 }

 checkpaymentintent(id)
 {
  var data = { 'invoiceid': id };
  this.companyService.getpaymentintent(data).subscribe((invoiceData: any) => {
    console.log(invoiceData); 
    return  invoiceData;          
  });
 }



getSafeUrl(url)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  Confirmmeassage(id:string)  
  {
   

    if(this.paynow == false)
    {     
      this.wizard1 = false;
      this.wizard2 = false;
      this.wizard3 = false;
      this.paymentcheck = true;
      this.paymentloading = true;      
      this.paynow = true;

      this.checkService.checkrestripecustomer().subscribe((payment: any) => {

        if(payment.success == true){
          var default_card;
          if(this.defaultcard)
          {
            default_card = this.defaultcard.id
          }
            this.checkService.checkrepayment({"cardid": default_card, "cvv": '123', "amount": parseFloat(this.gtotal+ this.tax),"products":this.serviceObjectArray,"tax_rate": this.tax_rate}).subscribe((payment3: any) => {
            if(payment3.success == false)
            {localStorage.setItem('productstring', JSON.stringify(this.serviceObjectArray));
              this.document.location.href = payment3.url;
              this.paynow = false;
              this.iscustomer = true;
              this.wizard2 = true;
              this.paymentcheck = false;
              this.paymentaut = false;
              this.paymentloading = false;              
            }           
          });
        }
        else
        {
          this.iscustomer = false;
          this.modalService.open(id);
        }
      });
    }
  }

  onCheckCard(cardid){
    this.cardlist.forEach(card => {
      if(card.id==cardid){       
        this.defaultcard = card;
        // this.paymentService.defaultCardset({ "cardid": cardid }).subscribe((data: any) => {
            
        // });        
      }
    });
  }

  inputcvv(){
    this.cvvError = false;
  }

  addNewCard(id: any){
    this.submitted = false;
    this.display = 'block';
    this.addpopForm.reset({
      name: '',
      address1: '',
      address2: '',
      cityname: '',   
      statename: '',
      pincode: '',     
      taxid: (this.customerData.metadata)?this.customerData.metadata.taxid:'',       
      countryid: 70,
      cardid: '',
      customerid: ''
    });
    this.modalService.open(id);
  }

}
