import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CompanyadvertService } from '../../services/companyadvert.service';
import { Companyadverse } from '../../models/companyadverse/companyadverse.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as CanvasJS from './../../admin/canvasjs.min';


@Component({
  selector: 'app-companyadvertgroup',
  templateUrl: './companyadvertgroup.component.html',
  styleUrls: ['./companyadvertgroup.component.css']
})
export class CompanyadvertgroupComponent implements OnInit {

  @Input()
  company: any;

  constructor()  { }

  ngOnInit() {
  }

}
