"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Wemployee = /** @class */ (function () {
    function Wemployee() {
    }
    return Wemployee;
}());
exports.Wemployee = Wemployee;
var Otherinfo = /** @class */ (function () {
    function Otherinfo() {
    }
    return Otherinfo;
}());
exports.Otherinfo = Otherinfo;
var Clocation = /** @class */ (function () {
    function Clocation() {
    }
    return Clocation;
}());
exports.Clocation = Clocation;
var Division = /** @class */ (function () {
    function Division() {
    }
    return Division;
}());
exports.Division = Division;
var Department = /** @class */ (function () {
    function Department() {
    }
    return Department;
}());
exports.Department = Department;
