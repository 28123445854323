"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var forms_1 = require("@angular/forms");
var employee_model_1 = require("../../models/employee.model");
var country_service_1 = require("../../services/country.service");
var employee_service_1 = require("../../services/employee.service");
var employeement_service_1 = require("../../services/employeement.service");
var job_service_1 = require("../../services/job.service");
var project_service_1 = require("../../services/project.service");
var timesheet_service_1 = require("../../services/timesheet.service");
var excel_service_1 = require("../../services/excel.service");
var date_fns_1 = require("date-fns");
var modal_service_1 = require("../../_services/modal.service");
var app_global_1 = require("../../app.global");
var worklist_service_1 = require("../../services/worklist.service");
var TimesheetreportComponent = /** @class */ (function () {
    function TimesheetreportComponent(excelService, formBuilder, project, timesheet, country, jobser, employee, modalService, listdata, datePipe, employeementreport) {
        this.excelService = excelService;
        this.formBuilder = formBuilder;
        this.project = project;
        this.timesheet = timesheet;
        this.country = country;
        this.jobser = jobser;
        this.employee = employee;
        this.modalService = modalService;
        this.listdata = listdata;
        this.datePipe = datePipe;
        this.employeementreport = employeementreport;
        this.employeeDetail = new employee_model_1.Employee();
        this.exceldata = [];
    }
    TimesheetreportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.yearList = app_global_1.AppGlobals.YEAR_VALUES;
        this.listdisplay = "none";
        this.graphdisplay = "block";
        this.EmployeeForm = this.formBuilder.group({
            emailid: [''],
            phonenumber: [''],
            ninumber: [''],
            minstartdate: [''],
            maxstartdate: [''],
            minenddate: [''],
            maxenddate: [''],
            hourtypeid: [''],
            minhour: [''],
            maxhour: [''],
            projectid: [''],
            fname: [''],
            hstatus: [''],
            searcText: ['']
        });
        this.employeemonth = date_fns_1.getMonth(new Date()) + 1;
        this.employeeyear = date_fns_1.getYear(new Date());
        this.hstatus = '';
        var value3 = "{}";
        this.timesheetdetails();
        this.timesheet.getloactionlist().subscribe(function (locationlistdetails) {
            _this.locationlist = locationlistdetails;
            _this.record = _this.data.length;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
        this.Userid = localStorage.getItem('userid');
        var sstatustype = { 'status': true, 'id': this.Userid };
        this.project.getprojectlist(sstatustype).subscribe(function (projectdetails) {
            _this.projectlist = projectdetails;
        });
        var value3 = "{}";
        this.employee.getemployeelist(value3).subscribe(function (listdetails) {
            _this.employeelist = listdetails;
        });
        this.listdata.gethourtypelist({ 'status': true, 'id': this.Userid }).subscribe(function (expensedetails) {
            _this.expenselist = expensedetails;
        });
    };
    TimesheetreportComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    TimesheetreportComponent.prototype.showcondition = function (type) {
        // console.log(this.data);
        if (type == 'list') {
            this.graphdisplay = "none";
            this.listdisplay = "block";
        }
        else {
            this.listdisplay = "none";
            this.graphdisplay = "block";
        }
    };
    TimesheetreportComponent.prototype.exportAsXLSX = function () {
        var _this = this;
        this.exceldata = [];
        this.data.forEach(function (obj) {
            _this.employeetimesheetdata = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "Hours": obj.ahour,
                "Hour Type": obj.hourtype.typename,
                "Project": obj.project.projectname,
                "Location": obj.location.address1,
                "Date": _this.datePipe.transform(new Date(obj.startdate), "dd MMM yyyy")
            };
            _this.exceldata.push(_this.employeetimesheetdata);
        });
        this.excelService.exportAsExcelFile(this.exceldata, 'sample');
    };
    TimesheetreportComponent.prototype.onSubmit = function (value3) {
    };
    TimesheetreportComponent.prototype.timesheetdetails = function () {
        var _this = this;
        this.data = [];
        var empcheck = true;
        this.timesheet.gettimesheetList({ 'month': this.employeemonth, 'year': this.employeeyear, 'employeeid': this.empid, 'hstatus': this.hstatus, 'hourtypeid': this.exptype }).subscribe(function (timesheetDetails) {
            _this.record = timesheetDetails.length;
            timesheetDetails.forEach(function (timesheet) {
                empcheck = true;
                _this.data.forEach(function (emp, index) {
                    if (timesheet.employeeid == emp.employeemasterid) {
                        empcheck = false;
                        _this.data[index].timesheetrecords.push(timesheet);
                        if (timesheet.hstatus == '1') {
                            _this.data[index].pendinghours += timesheet.ahour;
                        }
                        else if (timesheet.hstatus == '2') {
                            _this.data[index].approvedhours += timesheet.ahour;
                        }
                        else if (timesheet.hstatus == '3') {
                            _this.data[index].rejecthours += timesheet.ahour;
                        }
                        _this.data[index].totalhours += timesheet.ahour;
                    }
                });
                if (empcheck) {
                    timesheet.employee.timesheetrecords = [timesheet];
                    timesheet.employee.pendinghours = 0;
                    timesheet.employee.approvedhours = 0;
                    timesheet.employee.rejecthours = 0;
                    if (timesheet.hstatus == '1') {
                        timesheet.employee.pendinghours += timesheet.ahour;
                    }
                    else if (timesheet.hstatus == '2') {
                        timesheet.employee.approvedhours += timesheet.ahour;
                    }
                    else if (timesheet.hstatus == '3') {
                        timesheet.employee.rejecthours += timesheet.ahour;
                    }
                    timesheet.employee.totalhours = timesheet.ahour;
                    _this.data.push(timesheet.employee);
                }
            });
            // console.log(this.data);
            // this.data = timesheetDetails;
        });
    };
    TimesheetreportComponent.prototype.openModalnote = function (data, id) {
        this.notedetails = data;
        this.modalService.open(id);
    };
    TimesheetreportComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    TimesheetreportComponent.prototype.changeEmployee = function (id) {
        this.empid = id;
        this.timesheetdetails();
    };
    TimesheetreportComponent.prototype.changeEmployeeMonth = function (month) {
        this.employeemonth = month;
        this.timesheetdetails();
    };
    TimesheetreportComponent.prototype.changeEmployeeYear = function (year) {
        this.employeeyear = year;
        this.timesheetdetails();
    };
    TimesheetreportComponent.prototype.changeEmployeestatus = function (hstatus) {
        this.hstatus = hstatus;
        this.timesheetdetails();
    };
    TimesheetreportComponent.prototype.changeExpenseType = function (exptype) {
        this.exptype = exptype;
        this.timesheetdetails();
    };
    return TimesheetreportComponent;
}());
exports.TimesheetreportComponent = TimesheetreportComponent;
