"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".subtab_gg[_ngcontent-%COMP%]   .mat-tab-header[_ngcontent-%COMP%]   .mat-tab-label-container[_ngcontent-%COMP%]   .mat-tab-list[_ngcontent-%COMP%]   .mat-tab-labels[_ngcontent-%COMP%]   .mat-tab-label.mat-tab-label-active[_ngcontent-%COMP%]{background:#333;color:#fff!important}.subtab_gg[_ngcontent-%COMP%]   .mat-tab-header[_ngcontent-%COMP%]   .mat-tab-label-container[_ngcontent-%COMP%]   .mat-tab-list[_ngcontent-%COMP%]   .mat-tab-labels[_ngcontent-%COMP%]   .mat-tab-label[_ngcontent-%COMP%]{border:#333;color:#333!important}.multicolorg[_ngcontent-%COMP%]{margin-bottom:20px}.multicolorg[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{padding:5px 10px;margin-right:10px;border-radius:7px;font-size:12px}.multicolorg[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]:nth-child(1){background:#d0e2ff}.multicolorg[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]:nth-child(2){background:#f2e2e2}.multicolorg[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]:nth-child(3){background:#e8daff}.multicolorg[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]:nth-child(4){background:#bae6ff}.multicolorg[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]:nth-child(5){background:#9ef0f0}.multicolorg[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]:nth-child(6){background:#0043ce}.multicolorg[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]:nth-child(7){background:#ffd6e8}\n/*# sourceMappingURL=searchpersonalamldetails.component.css.map*/"];
exports.styles = styles;
