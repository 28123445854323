"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var excel_service_1 = require("../../services/excel.service");
var JobreportComponent = /** @class */ (function () {
    function JobreportComponent(excelService) {
        this.excelService = excelService;
    }
    JobreportComponent.prototype.ngOnInit = function () {
    };
    JobreportComponent.prototype.exportAsXLSX = function () {
        this.excelService.exportAsExcelFile(this.data, 'sample');
    };
    return JobreportComponent;
}());
exports.JobreportComponent = JobreportComponent;
