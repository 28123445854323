"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var checkservice_service_1 = require("../../../services/checkservice.service");
var common_1 = require("@angular/common");
var RighToWorkHistoryListAdminComponent = /** @class */ (function () {
    function RighToWorkHistoryListAdminComponent(activatedRoute, checkService, router, datePipe) {
        this.activatedRoute = activatedRoute;
        this.checkService = checkService;
        this.router = router;
        this.datePipe = datePipe;
    }
    RighToWorkHistoryListAdminComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.checkService.selectrighttoworkbyadmin().subscribe(function (righttoworkadmin) {
            if (righttoworkadmin.success == 'fail') {
                _this.router.navigate(['/right-to-work-app-history-list', 0]);
            }
        });
        this.empid = this.activatedRoute.snapshot.paramMap.get("empid");
        var data = { employeeid: this.empid };
        this.checkService.getrighttoworkhistorybyemployeeadmin(data).subscribe(function (righttoworklog) {
            _this.listemployee = righttoworklog;
            console.log(righttoworklog);
        });
    };
    RighToWorkHistoryListAdminComponent.prototype.completerightowork = function (logid) {
        localStorage.SetItem('logid', logid);
        this.router.navigate(['/right-to-work-app-history']);
    };
    RighToWorkHistoryListAdminComponent.prototype.shouldDisplayDate = function (date) {
        if (this.datePipe.transform(date, 'yyyy-MM-dd') >= this.datePipe.transform('10/02/2022', 'yyyy-MM-dd')) {
            return true;
        }
        return false;
    };
    RighToWorkHistoryListAdminComponent.prototype.viewemployeerighttoworkhistory = function (empid) {
        localStorage.removeItem('logid');
        this.router.navigate(['/right-to-work-app-history', empid]);
    };
    RighToWorkHistoryListAdminComponent.prototype.completerighttoworkhistory = function (logid, empid) {
        localStorage.setItem('logid', logid);
        // localStorage.SetItem('logid',logid); //localStorage.removeItem('logid');
        this.router.navigate(['/right-to-work-app-admin', empid]);
    };
    return RighToWorkHistoryListAdminComponent;
}());
exports.RighToWorkHistoryListAdminComponent = RighToWorkHistoryListAdminComponent;
