import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EmployeeService } from '../../../services/employee.service';
import { ModalService } from '../../../_services/modal.service';
import { Profile, Termination } from '../../../models/overview/profile.model';
import { CountryService } from '../../../services/country.service';
import { CommonService } from '../../../services/common.service';
import { JobService } from '../../../services/job.service';
import { CandidateService } from '../../../services/candidate.service';
import { UserService } from '../../../services/user.service';
import { PermissionService } from '../../../services/permission.service';
import { PersonaltypeService } from '../../../services/personaltype.service';
import { CompanyService } from '../../../services/company.service';
import { AlertService } from '../../../services/alert.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-listemployee',
  templateUrl: './listemployee.component.html',
  styleUrls: ['./listemployee.component.css']
})
export class ListemployeeComponent implements OnInit {
  data: any;
  submitted: any; 
  employeeForm: FormGroup;
  terminationForm: FormGroup;
  overviewData: any;
  terminationDetail: any;
  countrylist: any;
  dltypelist: any;
  datalist:any;
  dllist: any;
  joblist: any;
  candidatelist: any;
  Userid: any;
  th = [];
  ismail: any;
  emailexist = false;
  public searchText: string;
  permissionstring: any;
  mindob = new Date();
  maxdob = new Date();
  phonecheck: any;
  empidexist = false;  
  usernameexist = false;
  disableBtn: any;
  permission: any;
  empperm: any;
  defaultVal: any = "";
  record:number;
  currentyear: any;
  items: Array<any>;
  nextyear: any;
  pageOfItems: Array<any>;

  constructor(
    private userService: UserService, 
    private employeeService: EmployeeService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private commonService: CommonService,
    private formBuilder: FormBuilder, 
    private jobdetails: JobService, 
    private router: Router,
    private candidatedetails: CandidateService,
    private permissiondetails: PermissionService,
    private dltypeService: PersonaltypeService,
    private companyService: CompanyService,
    private alerts: AlertService
    ) { }

  ngOnInit() {
    this.currentyear = (new Date()).getFullYear();
    this.nextyear = (new Date()).getFullYear()+1;
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype ==1){
      this.router.navigate(['/check-dashboard']);
    }

    this.Userid = localStorage.getItem('userid');

    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    this.permission = permission[0];
    this.empperm = this.permission.m16;
    this.ismail = false;

    this.mindob.setDate(this.mindob.getDate() - 23741);
    this.maxdob.setDate(this.maxdob.getDate() - 574);

    this.employeeForm = this.formBuilder.group({
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      empid: ['', Validators.required],
      gender: ['', Validators.required],
      placeofbirth: ['', Validators.required],
      maritalstatus: ['', Validators.required],
      ninumber: ['', Validators.required],
      username: ['', Validators.required],
      jobid: [''],
      candidateid: [''],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: ['', Validators.compose([Validators.required])],
      nationalityid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      residenceid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      countryofbirthid: ['', Validators.compose([Validators.required, Validators.min(1)])],
      dateofbirth: ['', Validators.required],
      hiredate: ['', Validators.required],
      emprole: ['', Validators.required],
      ismail: [''],
      currentyearleave: ['',  Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(1)])],
      nextyearleave: ['',  Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(1)])]
    });

    this.terminationForm = this.formBuilder.group({
      employeeid: ['', Validators.required],
      terminationdate: ['', Validators.required],
      terminationreason: ['', Validators.required],
      oktorehire: ['', Validators.required],
      regrettermination: ['', Validators.required]
    });

    this.overviewData = new Profile();
    this.terminationDetail = new Termination();

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.jobdetails.getjobList().subscribe((jobdetails: any) => {
      this.joblist = jobdetails;
    });

    this.dltypeService.getactivepersonaltypelist().subscribe((dltypes: any) => {
      this.dltypelist = dltypes;
    });

    this.dltypeService.getdrivinglicenselist().subscribe((dlicense: any) => {
      this.dllist = dlicense;
    });

    this.permissiondetails.bindallmenu().subscribe((menudata: any) => {
      var permissionstring = "[{";
      var perarray = [];
      menudata.forEach(function (valuemenu) {
        perarray.push('"' + valuemenu.clname + '": 0');
      });
      
      var perstr = perarray.join();
      permissionstring = permissionstring + perstr + "}]";
      this.permissionstring = permissionstring;
    });

    this.getAllEmployees();

  }

  getAllEmployees()
  {
    this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
      // employeedata.forEach(function (value) {
      //   var tg;
      //   tg = JSON.parse(value.permission.toString());
      //   tg.forEach(function (value4) {
      //   });
      // });
      this.data = employeedata;
      this.record = this.data.length;
      this.datalist = this.data;
      if(this.record < 30){
        this.pageOfItems = this.data;
      }
      else{
           this.items =this.data;
      }     
    });
  }
  
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  get f() { return this.employeeForm.controls; }

  get g() { return this.terminationForm.controls; }


  resendwelcomemail(employeeid: any){

    var data ={ 'employeeid': employeeid}
    this.employeeService.employeewelcomeemail(data).subscribe((data: any) => {

      if(data.susses =='true')
      {
         this.alerts.success('Welcome email resend successful.');
      }else
      {
        this.alerts.error('Something went wrong. Please try again later.');
      }
    });


  }

  onSubmit(employeeform1: any, modelid: any) {
    this.submitted = true;
    if (this.employeeForm.invalid) {
      return;
    }
    this.disableBtn = false;
    
    employeeform1.id = this.Userid;
    employeeform1.permission = this.permissionstring;



    
    const sd = new Date(employeeform1.dateofbirth);
    employeeform1.dateofbirth = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    const ed = new Date(employeeform1.hiredate);
    employeeform1.hiredate = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());



    this.userService.isCompanyEmailRegisterd(employeeform1.emailid).subscribe((data: any) => {
      if (data.sussess == 'success') {
        this.emailexist = true; 
        this.disableBtn = true;
      }
      else 
      {

        this.userService.isUserRegisterd(employeeform1.username).subscribe((data: any) => {

          if (data.sussess == 'success') {
            this.usernameexist = true; 
            this.disableBtn = true;
          }
          else
          {
            this.companyService.checkcompanyemployeeid({'empid': employeeform1.empid}).subscribe((checkempid: any) => {
          if(checkempid.sussess == 'success')
          {
            this.empidexist = true; 
            this.disableBtn = true;
          }
          else
          {
            this.disableBtn = false;
            this.employeeService.employeeUpdateForm1(employeeform1).subscribe((data: any) => {
              if(employeeform1.currentyearleave != '')
              {
                var currentYearLeaveData = {
                  'id': 0,
                  'employeeid': data.data.employeemasterid,
                  'leavecount': employeeform1.currentyearleave,
                  'payrolyear': this.currentyear,
                  'userid': this.Userid
                };
                this.employeeService.saveEmployeeTotalLeaves(currentYearLeaveData).subscribe((data: any) => {

                });
              }
              if(employeeform1.nextyearleave != '')
              {
                var nextYearLeaveData = {
                  'id': 0,
                  'employeeid': data.data.employeemasterid,
                  'leavecount': employeeform1.nextyearleave,
                  'payrolyear': this.nextyear,
                  'userid': this.Userid
                };
                this.employeeService.saveEmployeeTotalLeaves(nextYearLeaveData).subscribe((data: any) => {

                });
              }
              this.getAllEmployees();
              // this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
              //   this.data = employeedata;
                this.modalService.close(modelid);
              // });
            });
          }
          
        });
      }
      });
      }
    });
  }

  onUserNameOutEvent(event: any)
  {  
    if(event.target.value == '')
    {
        this.usernameexist =false;
    }else
    {
      this.usernameexist =false;
      var dbvalue = { 'username': event.target.value}
      this.userService.isUserRegisterd(event.target.value).subscribe((data: any) => {
    // this.userService.isUserRegisterd(event.target.value).subscribe((data2: any) => {

      if (data.sussess == 'false') {
        this.usernameexist = false;
      }else
      {
        this.usernameexist = true;
      }
    });
  }
  }
  onSubmitTermination(terminationform: any, modelid: any) {
    this.submitted = true;
    if (this.terminationForm.invalid) {
      return;
    }

    this.employeeService.employeeSubmitTermination(terminationform).subscribe((data: any) => {
      this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
        this.data = employeedata;
        this.employeeService.getlistofemployee().subscribe((employeedata: any) => {
          employeedata.forEach(function (value) {
            var tg;
            tg = JSON.parse(value.permission.toString());
            tg.forEach(function (value4) {
            });
          });
          this.data = employeedata;
          this.record = this.data.length;
    
          if(this.record < 30){
            this.pageOfItems = this.data;
          }
          else{
               this.items =this.data;
          }          
          this.alerts.success('Employee has been terminated successfully');

          this.modalService.close(modelid);
        });        
      });
    });
  }


  
  onFocusOutEvent(event: any)
  {

    console.log('email id',event.target.value);
    this.userService.isCompanyEmailRegisterd(event.target.value).subscribe((data: any) => {
      if (data.sussess == 'success') {
        this.emailexist = true;                 
      }else
      {
        this.emailexist = false;                 
      }      
    });    
  }


  onChangeStatus(evt: any, emp: any)
  {
    var status;
    if(evt.target.checked)
    {
      status = '1';
    }
    else if(!evt.target.checked)
    {
      status = '0';
    }
    this.employeeService.employeeUpdateChangeStatus({'userid': this.Userid, 'id': emp.employeemasterid, 'estatus': evt.target.checked}).subscribe((data: any) => {
      if(status =='1')
      {
        this.alerts.success('Employee has been activated successfully');
      }else if(status == '0')
      {
        this.alerts.error('Employee has been deactivated successfully');
      }
    });
  }

  focusFunction() {
    this.emailexist = false;
  }

  isEmailUnique(control: FormControl) {
    const q = new Promise((resolve, reject) => {
      this.userService.isEmailRegisterd(control.value).subscribe(() => {
        resolve(null);
      }, () => { resolve({ 'isEmailUnique': true }); });

    });
    return q;
  }

  onSelectjob(job_id: number) {
    const data = { 'jobid': job_id, 'id': this.Userid };
    this.candidatedetails.getselectedcandidate(data).subscribe((candidatedetails: any) => {
      return this.candidatelist = candidatedetails
    });
  }

  onSelectCandidate(candidateid: any) {
    const data = { 'candidateid': candidateid, 'id': this.Userid };
    this.candidatedetails.getselectedcandidatebyid(data).subscribe((candidatedetails: any) => {
      this.overviewData.fname = candidatedetails.fname;
      this.overviewData.mname = candidatedetails.mname;
      this.overviewData.lname = candidatedetails.lname;
      this.overviewData.phonenumber = candidatedetails.phonenumber;
      this.overviewData.emailid = candidatedetails.emailid;
      // return this.candidatelist = candidatedetails
    });
  }

  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
     
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  openModal2(id: string) {
    this.submitted = false;
    this.disableBtn = true;
    this.overviewData = new Profile();
    this.overviewData.gender = "1";
    this.overviewData.maritalstatus = "0";
    this.phonecheck = "";
    this.overviewData.jobid = "";
    this.overviewData.candidateid = "";
    this.overviewData.nationalityid = "";
    this.overviewData.residenceid = "";
    this.overviewData.countryofbirthid = "";
    this.overviewData.ismail = false;
    
    this.companyService.checkPlan({'userid': this.Userid}).subscribe((checkemployeecount: any) => {
      if(checkemployeecount.success == "true")
      {
        // console.log(this.overviewData);
        this.modalService.open(id);
      }
      else
      {
        this.modalService.open("upgrade-plan");
      }
    });
    
  }

  closeModal(id: string) {
    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });
    this.modalService.close(id);
  }

  openModelTerminate(empid: string, id: string)
  {
    this.submitted = false;
    this.terminationDetail.employeeid = empid;
    this.terminationDetail.oktorehire = "";
    this.terminationDetail.regrettermination = "";
    this.modalService.open(id);
  }

}
