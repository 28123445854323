<app-header>
</app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">

           <a style="cursor: pointer;" (click)="amllogredirect()" class="backlist mb-4 mr-0"><i aria-hidden="true" class="fa fa-angle-left"></i> Search Results</a>

            <div class="clearfix"></div>
            <div class="tabs__content" *ngIf="bussinessdetails">

                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="row row-sm topheader">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Business Details </h5>
                                <!-- <a href="{{ url }}" target="_blank" >Download</a> -->
                            </div>

                            <div class="col-lg-6">
                                <a type="submit" href="{{ url }}" *ngIf="url != ''"   target="_blank" class="btn btn-primary com_btn float-right" >Download <i class="fa fa-file-pdf-o ml-2"></i></a>
                             
                                 <!-- <a type="submit" (click)="loaddatasystem()"      target="_blank" class="btn btn-primary com_btn float-right" >Download Now<i class="fa fa-file-pdf-o ml-2"></i></a>  -->
                             
                                <!-- <a href="{{url}}" target="_blank">view document</a> -->
                                
                          </div>
                        </div>


                        <div class="job_tab" *ngIf="bussinessdetails.business">
                            <mat-tab-group>
                                <mat-tab label="Details">
                                    <ng-template matTabContent>
                                        <div class="strip_show mt-3"  *ngIf="bussinessdetails.business">
                                            <div class="row mr-0 ml-0">
                                           
                                                <!-- <div class="col-lg-2 mobilecentersh">
                                                    <div class="circle_credit_img">
                                                        <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                                                    </div>
                                                   
                                                    
                                                </div> -->
                                                <div class="col-lg-11 d-flex align-items-center ">
                                                    <div class="category flex-wrap justify-content-start align-items-center text-center d-flex width100">
                                                        <div class="catog_box" *ngIf="PEPs.length>0">
                                                            <p>PEP</p>
                                                            <span>{{PEPs.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="LawEnforcement.length>0">
                                                            <p>Law Enforcement</p>
                                                            <span>{{LawEnforcement.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="AdverseMedia.length>0">
                                                            <p>Adverse Media</p>
                                                            <span>{{AdverseMedia.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="idv.length>0">
                                                            <p>IDV</p>
                                                            <span>{{idv.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="FinancialRegulator.length>0">
                                                            <p>Financial Regulator</p>
                                                            <span>{{FinancialRegulator.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="DisqualifiedDirector.length>0">
                                                            <p>Disqualified Director</p>
                                                            <span>{{DisqualifiedDirector.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="Insolvency.length>0">
                                                            <p>Insolvency</p>
                                                            <span>{{Insolvency.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="CurrentSanctions.length>0">
                                                            <p>Sanctions</p>
                                                            <span>{{CurrentSanctions.length}}</span>
                                                        </div>
                                                        <div class="catog_box" *ngIf="PreviousSanctions.length>0">
                                                            <p>Corporate/Business</p>
                                                            <span>{{PreviousSanctions.length}}</span>
                                                        </div>
                                                    </div>
                        
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="row mr-0 ml-0">
                                                
                                               
                                                <div class="col-sm-12">
                        
                                               
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                        <div class="job_detils_date width70">
                                                            <p class="job_address">Business Name</p>   
                                                                <h5 class="job_profile">{{bussinessdetails.business.businessName}}</h5>
                                                                <p class="job_profile" *ngIf="!bussinessdetails.business.businessName"> N/A</p>
                                                            
                                                        
                                                        </div>
                                                       
                                                       
                                                        
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                        <div class="job_detils_date width30">
                                                            <p class="job_address">Telephone</p>                           
                                                            <p class="job_profile">  {{bussinessdetails.business.telephoneNumber}}
                                                                <!-- <span *ngIf="bussinessdetails.person.telephoneNumber != ''">    (Home) </span> -->
                                                                        
                                                            </p>
                                                            <p class="job_profile" *ngIf="!bussinessdetails.business.telephoneNumber"> N/A</p>
                                                        </div>
                                                        <div class="job_detils_date width30">
                                                            <p class="job_address">Website</p>     
                                                            <p class="job_profile"> {{bussinessdetails.business.website}}</p>
                                                            <p class="job_profile" *ngIf="!bussinessdetails.business.website"> N/A</p>
                                                                <!-- <span *ngIf="bussinessdetails.person.mobileNumber != ''">(Mob)</span></p> -->
                                                        </div>
                                                       
                                                    
                                                    
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                        <div class="job_detils_date width70">
                                                            <p class="job_address">Address(es)   </p>                           
                                                            <p class="job_profile" *ngFor="let companyaddress of bussinessdetails.business.addresses;">  {{companyaddress.address1}} {{companyaddress.address2}} {{companyaddress.address3}}  {{ companyaddress.city }} {{ companyaddress.county }} {{ companyaddress.country.name }} {{ companyaddress.postcode}}</p>
                                                        </div>
                                                        <p class="job_profile" *ngIf="bussinessdetails.business.addresses == 0"> N/A</p>
                                                    </div>
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                        <div class="job_detils_date width100">
                                                            <p class="job_address">Alias </p>                           
                                                            <p class="job_profile" >
                                                                <span class="aliesname" *ngFor="let companyaliases of bussinessdetails.business.aliases;"> {{companyaliases.businessName}}</span>
                                                             </p>
                                                             <p class="job_profile" *ngIf="bussinessdetails.business.aliases == 0"> N/A</p>
                                                        </div>
                                                    
                                                    </div>

                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                        <div class="job_detils_date width100">
                                                            <p class="job_address">Notes </p>                           
                                                            <div class="job_profile" >
                                                                <p class="" *ngFor="let companyaliases of bussinessdetails.business.notes;"> 
                                                                    {{companyaliases.text}}</p>
                                                                </div>
                                                             <p class="job_profile" *ngIf="bussinessdetails.business.notes == 0"> N/A</p>
                                                        </div>
                                                    
                                                    </div>
                                                   
                                                   
                                                    <div class="job_list_section visa_request_section innerdetailsshow width100">
                                                    
                                                    
                                                        <div class="job_detils_date width100">
                                                            <p class="job_address">Reference Id </p>                           
                                                            <p class="job_profile mt-1" >
                        
                                                                <span class="text-success"><b>{{bussinessdetails.business.id }}</b></span>
                                                            </p>
                                                        </div>
                                                    
                                                    </div>
                                                 </div>
                                            </div>
                                        </div>
                                        <!-- <div class="credit_data_show" *ngIf="bussinessdetails.business" >
                                            <div class="row mr-0 ml-0 pr-4 pl-4 pt-4">
                                                <div class="col-lg-2">
                                                    <img src="{{ bussinessdetails.business.imageURL }}" class="img width100" *ngIf="bussinessdetails.business.imageURL != '' || bussinessdetails.business.imageURL != null">
                                                    <img src="../../../../../assets/img/user_pic_emp.png" class="img width100" *ngIf="bussinessdetails.business.imageURL == '' || bussinessdetails.business.imageURL == null">
                                                   
                                                </div>
                                                <div class="col-lg-10  d-flex">
                                                    <div class="category align-items-center text-center d-flex width100">
                                                        <div class="catog_box fourbox" *ngIf="PEPs.length>0">
                                                            <p>PEP</p>
                                                            <span>{{PEPs.length}}</span>
                                                        </div>
                                                        <div class="catog_box fourbox" *ngIf="LawEnforcement.length>0">
                                                            <p>Law Enforcement</p>
                                                            <span>{{LawEnforcement.length}}</span>
                                                        </div>
                                                        <div class="catog_box fourbox" *ngIf="AdverseMedia.length>0">
                                                            <p>Adverse Media</p>
                                                            <span>{{AdverseMedia.length}}</span>
                                                        </div>
                                                        <div class="catog_box fourbox" *ngIf="PreviousSanctions.length>0">
                                                            <p>IDV</p>
                                                            <span>{{PreviousSanctions.length}}</span>
                                                        </div>
                                                        <div class="catog_box fourbox" *ngIf="FinancialRegulator.length>0">
                                                            <p>Financial Regulator</p>
                                                            <span>{{FinancialRegulator.length}}</span>
                                                        </div>
                                                        <div class="catog_box fourbox" *ngIf="DisqualifiedDirector.length>0">
                                                            <p>Disqualified Director</p>
                                                            <span>{{DisqualifiedDirector.length}}</span>
                                                        </div>
                                                        <div class="catog_box fourbox" *ngIf="Insolvency.length>0">
                                                            <p>Insolvency</p>
                                                            <span>{{Insolvency.length}}</span>
                                                        </div>
                                                        <div class="catog_box fourbox" *ngIf="CurrentSanctions.length>0">
                                                            <p>Sanctions</p>
                                                            <span>{{CurrentSanctions.length}}</span>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="all_detials p-4">
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">
                                                            <div class="catog_box width50">
                                                                <p>Business Name</p>
                                                                <h4 class="text-danger">{{bussinessdetails.business.businessName}}</h4>
                                                            </div>
                                                            <div class="catog_box width50">
                                                                <p>Alias</p>
                                                                <p *ngFor="let companyaliases of bussinessdetails.business.aliases;">
                                                                    {{companyaliases.businessName}}
                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="catog_box width50">
                                                                <p>Telephone</p>
                                                                <span>{{bussinessdetails.telephoneNumber}}</span>
                                                            </div>
                                                            <div class="catog_box width50">
                                                                <p>Website </p>
                                                                <a href="#">{{bussinessdetails.website}}</a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">


                                                            <div class="catog_box width50">
                                                                <p>Addresses</p>
                                                                <p *ngFor="let companyaddress of bussinessdetails.business.addresses;">
                                                                    {{companyaddress.address1}}, {{ companyaddress.city }} {{ companyaddress.county }}- {{ companyaddress.postcode}}
                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>

                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="catog_box">
                                                                <p>Reference Id</p>
                                                            </div>
                                                            <div class="catog_box">
                                                                <span class="text-info"><b>{{bussinessdetails.business.id }}</b></span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                            </div>

                                        </div> -->
                                        


                                    </ng-template>
                                </mat-tab>
                                <mat-tab *ngIf="bussinessdetails.business" label="Businesses ({{bussinessdetails.business.linkedBusinesses.length}})">
                                    <ng-template matTabContent>


                                        <div class="job_list_section visa_request_section" *ngFor="let linkbussiness of bussinessdetails.business.linkedBusinesses">


                                            <div class="job_detils_date width22">
                                                <div class="visa_user_details ">
                                                    <p class="job_address">Business Name</p>
                                                    <h4 class="text-danger">{{ linkbussiness.businessName }}</h4>
                                                </div>
                                            </div>
                                            <div class="job_detils_date width50">
                                                <p class="job_address">Connection</p>
                                                <p class="job_profile">{{ linkbussiness.linkDescription }}</p>
                                            </div>



                                        </div>

                                        <div class="job_list_section employee_list_section leave_section"  *ngIf="bussinessdetails.business.linkedBusinesses.length <=0">
                                            <div class="job_detils_date">
                                                <p class="job_profile">
                                                    No Record Found
                                                </p> 
                                            </div>                                                                                       
                                        </div>
                                        <hr class="line" />

                                    </ng-template>
                                </mat-tab>

                                <ng-container *ngIf="bussinessdetails.business">
                                <mat-tab label="Linked Persons  ({{bussinessdetails.business.linkedPersons.length}})">
                                    <ng-template matTabContent>

                                        
                                        <div class="job_list_section visa_request_section"  *ngFor="let linkperson of bussinessdetails.business.linkedPersons">

                                            <div class="job_detils_date width13">
                                                <p class="job_address">Name</p>
                                                <p class="ninumber text-danger"><b>{{ linkperson.name }}</b></p>
                                            </div>
                                            <div class="job_detils_date width15">
                                                <div class="visa_user_details ">
                                                    <p class="job_address">Position </p>
                                                    <p class="job_profile">{{ linkperson.position }}</p>
                                                </div>
                                            </div>
                                            <!-- <div class="job_detils_date width13">
                                                <p class="job_address">Datasets</p>
                                                <p class="job_profile">PEP</p>
                                            </div>
                                            <div class="job_detils_date width7 text-center">
                                                <p class="job_address">PEP Level</p>
                                                <span class="badge badge-success">1</span>
                                            </div> -->



                                            <div class="job_detils_date width7">

                                                <p class="job_address">
                                                    <!-- <button type="submit" class="btn btn-danger com_btn" (click)="openModal('linkperson_view')"><i class="fa fa-file-image-o"></i></button> -->
                                                    <!-- <button type="submit" class="btn btn-danger com_btn" ><i class="fa fa-pencil"></i></button>
                                          <button type="submit" class="btn btn-secondary com_btn"><i class="fa fa-trash-o"></i></button> -->
                                                </p>
                                            </div>



                                        </div>

                                        <div class="job_list_section employee_list_section leave_section"  *ngIf="bussinessdetails.business.linkedPersons.length <=0">
                                            <div class="job_detils_date">
                                                <p class="job_profile">
                                                    No Record Found
                                                </p> 
                                            </div>                                                                                       
                                        </div>

                                        <hr class="line" />


                                    </ng-template>
                                </mat-tab></ng-container>
                                <mat-tab label="Documents  ({{bussinessdetails.business.articles.length}})">
                                    <ng-template matTabContent>
                                        <div class="job_list_section visa_request_section"  *ngFor="let document of bussinessdetails.business.articles">

                                            <div class="job_detils_date width13">
                                                <p class="job_address">Name</p>
                                                <p class="ninumber text-primary">{{urlfind( document.originalURL)}}
                                                </p>
                                            </div>
                                            <div class="job_detils_date width15">
                                                <div class="visa_user_details">
                                                    <p class="job_address">Category</p>
                                                    <p class="job_profile" > <span *ngFor="let category of document.categories"> {{ category.name }}</span> </p>
                                                </div>
                                            </div>
                                            <div class="job_detils_date width13">
                                                <p class="job_address">Creation Date</p>
                                                <p class="job_profile">{{ document.dateCollected }}</p>
                                            </div>




                                            <div class="job_detils_date width7">

                                                <p class="job_address" *ngIf="document.c6URL" >
                                                    <button type="submit" class="btn btn-danger com_btn" (click)="opendocumentlink('pdf-image-viewer-complete-document', document.c6URL)"><i class="fa fa-file-text-o" aria-hidden="true"></i>
                                                    </button>
                                                </p>
                                          <!-- <button type="submit" class="btn btn-secondary com_btn"><i class="fa fa-trash-o"></i></button>  -->
                                                

                                                <button type="submit" class="btn btn-danger com_btn" *ngIf="document.snippet" (click)="openModal('document_view',document.snippet,document.originalURL )" ><i class="fa fa-file-text-o"></i></button>
                                            </div>



                                        </div>

                                        <div class="job_list_section employee_list_section leave_section"  *ngIf="bussinessdetails.business.articles.length <=0">
                                            <div class="job_detils_date">
                                                <p class="job_profile">
                                                    No Record Found
                                                </p> 
                                            </div>                                                                                       
                                        </div>
                                        <hr class="line" />


                                    </ng-template>
                                </mat-tab>
                            </mat-tab-group>
                        </div>

                        <jw-modal id="document_view" class="modal" role="dialog">
                            <div role="document" class="formgroup" *ngIf="snippet">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <!-- <h5 class="modal-title">{{}}</h5> -->
                                        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('document_view');">
                                <span aria-hidden="true">&times;</span>
                              </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="container">


                                            <div class="all_detials">
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">
                                                            <div class="catog_box width50">
                                                                <p><b>Title</b></p>
                                                                <span>{{snippet.title}}</span>
                                                            </div>
                                                            <div class="catog_box width50">
                                                                <p><b>Keywords</b></p>
                                                                <span *ngFor="let category of snippet.keywordsMatched">{{category}}</span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="">
                                                                <p><b>Summary</b></p>
                                                                <p>{{snippet.summary}}</p>
                                                            </div>
                                                            <!-- <div class="catog_box width100">
                                                                <p>OriginalURL</p>
                                                                <span *ngIf="originalURL">{{originalURL}}</span>
                                                            </div> -->


                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="catog_box width50">
                                                                <p><b>Original URL</b></p>
                                                                <span><a href="{{originalURL}}" target="_blank">{{originalURL}}</a></span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>

                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="catog_box">
                                                                <span></span>
                                                            </div>
                                                            <div class="catog_box">
                                                                <span><i class="fa fa-question-circle" aria-hidden="true"></i>
                                                                   Disclaimer</span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <!-- <button type="button" class="btn btn-primary com_btn"> Remove from review list</button> -->
                                        <button type="button" class="btn btn-secondary com_btn" (click)="closeModal('document_view');" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </jw-modal>
                        <jw-modal id="business_view" class="modal" role="dialog">
                            <div role="document" class="formgroup">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Deloitte Consulting LLP</h5>
                                        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('business_view');">
                              <span aria-hidden="true">&times;</span>
                            </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="container">

                                            <div class="row">
                                                <div class="col-lg-2">
                                                    <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                                                </div>
                                                <div class="col-lg-10  d-flex">
                                                    <div class="category align-items-center text-center d-flex width100">
                                                        <div class="catog_box fourbox">
                                                            <p>PEP</p>
                                                            <span>9</span>
                                                        </div>

                                                        <div class="catog_box fourbox">
                                                            <p>Law Enforcement</p>
                                                            <span>2</span>
                                                        </div>
                                                        <div class="catog_box fourbox">
                                                            <p>Corporate</p>
                                                            <span>1</span>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="all_detials">
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">
                                                            <div class="catog_box width50">
                                                                <p>Business Name</p>
                                                                <span>Deloitte Consulting LLP</span>
                                                            </div>
                                                            <div class="catog_box width50">
                                                                <p>Alias</p>
                                                                <span>Deloitte Consulting LLP., Deloitte Consulting, Deloitte Consulting Liability Partnership</span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="catog_box width50">
                                                                <p>Addresses</p>
                                                                <span>Santa Ana California United States America  </span>
                                                            </div>
                                                            <div class="catog_box width50">
                                                                <p>Website </p>
                                                                <span>www.monitor.com</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>


                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="catog_box">
                                                                <span>Reference Id</span>
                                                            </div>
                                                            <div class="catog_box">
                                                                <span>539818</span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger com_btn"> Add to review list</button>
                                        <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </jw-modal>
                        <jw-modal id="linkperson_view" class="modal" role="dialog">
                            <div role="document" class="formgroup">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Tagg Romney</h5>
                                        <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('linkperson_view');">
                              <span aria-hidden="true">&times;</span>
                            </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="container">

                                            <div class="row">
                                                <div class="col-lg-2">
                                                    <img src="../../../../../assets/img/user_pic_emp.png" class="img width100">
                                                </div>
                                                <div class="col-lg-10  d-flex">
                                                    <div class="category align-items-center text-center d-flex width100">
                                                        <div class="catog_box fourbox">
                                                            <p>PEP</p>
                                                            <span>4</span>
                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="all_detials">
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">
                                                            <div class="catog_box width22">
                                                                <p>Person Name</p>
                                                                <span>Tagg Romney</span>
                                                            </div>
                                                            <div class="catog_box width7">
                                                                <p>Gender</p>
                                                                <span>Male</span>
                                                            </div>
                                                            <div class="catog_box width50">
                                                                <p>Address</p>
                                                                <span>Belmont Massachusetts United States Of America</span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="catog_box width100">
                                                                <p>Note</p>
                                                                <!-- <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</span> -->
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>


                                                <div class="row">

                                                    <div class="col-lg-12  d-flex">
                                                        <div class="category justify-content-between align-items-center d-flex width100">

                                                            <div class="catog_box">
                                                                <span>Reference Id</span>
                                                            </div>
                                                            <div class="catog_box">
                                                                <span>202979</span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr/>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger com_btn"> Add to review list</button>
                                        <button type="button" class="btn btn-secondary com_btn " data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </jw-modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<jw-modal id="pdf-image-viewer-complete-document" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer-complete-document');">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-12">

                            <iframe *ngIf="imgvisasrc == false" [src]="iframevisasrc" width="100%" height="600" frameborder="0"></iframe>
                            <img *ngIf="imgvisasrc == true" [src]="iframevisasrc" width="100%" />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</jw-modal>