export class Wemployee {
  fname: string;
  mname: string;
  lname: string;
  gender: boolean;
}
export class Otherinfo {
    id: string;
    employeeid: string;
    reportto: string;
    wemployee: Wemployee;
    isclientcontact: boolean;
    clientid: string;
    projectid: string;
    departmentid: string;
    divisionid: string;
    locationid: string;
  }

export class Clocation 
{  
  companylocationdetailsid: string;
  employeeid: number;
  employeeaddresdeatilid: number;
  address1: string;
  address2: string;
  cityname: string;
  pincode: string;
  stateid: number;
  countryid: number;
  companytypeid: string;
}

  export class Division {
    id: string;
    typename: string;
  }

  export class Department {
    id: string;
    typename: string;
  }