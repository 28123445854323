<div class="overviewsshow">
    <div class="header">
        <h1 class="headprofile mt-0">Employment</h1>
        <a (click)="openModal2('custom-employment-1')" class="addscreensuccess">Add Employment</a>
    </div>
    <div class="row">
        <div class="col-lg-6" *ngFor="let employee2 of employeedetails">
            <div class="border-box">
                <div class="overview_details emp">
                    <div class="profile_dtailsshow visastaus mb-3">
                        <div class="detailsshow">
                            <span class="badge badge-success" *ngIf="employee2.iscurrent == true"><i class="fa fa-check" aria-hidden="true"></i> Current</span>
                         
                           
                        </div>
                        <div class="icons_box_section">
                            <a (click)="openModal('custom-employment-1',employee2)" class="editprime">
                                <i class="lnr lnr-pencil"></i></a>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width100 pr-3">
                            <p><b>{{employee2.emprole}} ({{employee2.totalExp}})</b></p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3" *ngIf="empltype == false">
                            <span>Line Manager</span>
                            <p>{{employee2.wemployee.fname }} {{employee2.wemployee.mname }} {{employee2.wemployee.lname }}</p>
                        </div>
                        <div class="detailsshow width50 pr-3" >
                            <span>Hours per week</span>
                            <p>{{employee2.hoursofworks}}</p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        <div class="detailsshow width50 pr-3">
                            <span>Salary per Annum</span>
                            <p>{{employee2.salary}}</p>
                        </div>
                        <div class="detailsshow width50 pr-3" *ngIf="!employee2.flexi">
                            <span>Flexi Work</span>
                            <p>No</p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        
                        <div class="detailsshow width100 pr-3" *ngIf="employee2.flexi">
                            <span>Flexi Work</span>
                            <p>{{employee2.flexi.startdate| date: 'dd MMM yyyy'}} To
                                {{employee2.flexi.enddate| date: 'dd MMM yyyy'}}</p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        
                        <div class="detailsshow width50 pr-3" *ngIf="employee2.employmentcontract != null">
                            <span>Employment Contract</span>
                            <p>   <a (click)="openModalemployement3('pdf-image-viewer',employee2.employmentcontract)">
                                <i class="fa fa-file-image-o"></i>
                            </a></p>
                        </div>
                        <div class="detailsshow width50  pr-3" *ngIf="employee2.rwork != null">
                            <span>Right To Work</span>
                            <p>   <a (click)="openModalemployement3('pdf-image-viewer',employee2.rwork)">
                                <i class="fa fa-file-image-o "></i>
                            </a></p>
                        </div>
                    </div>
                    <div class="profile_dtailsshow">
                        
                        <div class="detailsshow width50 pr-3">
                            <span>Start Date</span>
                            <p>  {{employee2.startdate | date: 'dd MMM yyyy'}}</p>
                        </div>
                        <div class="detailsshow width50 pr-3"*ngIf="employee2.enddate != null && employee2.enddate != ''">
                            <span>End Date </span>
                            <p>  <ng-container *ngIf="employee2.iscurrent==false">
                                      {{employee2.enddate| date: 'dd MMM yyyy'}}
                                    </ng-container>
                                    
                            </p>
                            <p *ngIf="employee2.enddate == null || employee2.enddate == ''"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    <div class="job_list_section visa_request_section" *ngIf="!employeedetails?.length  > false">
        <div class="job_detils_date">
            <p class="job_profile">No Record Found</p>
        </div>
    </div> 
</div>

<!-- <div class="row">
    <div class="col-lg-7">
        <h1 class="headprofile">Employment</h1>
    </div>
    <div class="col-lg-5 float-right">
        <h6 class="text-right float-right buttonprofile">
            <a (click)="openModal2('custom-employment-1')" class="addscreensuccess">+ Add Employment</a></h6>
    </div>
</div> -->

<!-- 
<div class="row row-sm  mt-4 ml-0 mr-0">
  <div class="col-lg-12">
    <h6 class="text-right float-right">
      <a (click)="openModal2('custom-employment-1')" class="addscreensuccess">+ Add New</a></h6>
  </div>
</div> -->
<jw-modal id="custom-employment-1" role="dialog" class="modal">

    <form [formGroup]="employmentForm" #employmentform="ngForm" class="s12 white" (ngSubmit)="onSubmit(employmentform.value,'custom-employment-1')">
        <div role="document" class="formgroup">
            <div class="modal-content">
                <div class="modal-header">

                    <input type="hidden" formControlName="employeeid" class="form-control" required name="employeeid" id="employeeid" [(ngModel)]="employeementDetail.employeeid">
                    <input type="hidden" formControlName="id" class="form-control" required name="id" id="id" [(ngModel)]="employeementDetail.employeeworkingdetailsid">
                    <input type="hidden" formControlName="flexiid" class="form-control" name="flexiid" id="flexiid" [(ngModel)]="employeementDetail.flexi.id">

                    <h5 class="modal-title" id="popupLabel">Employment Details</h5>
                    <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('custom-employment-1');">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="container">

                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>Role *</label>
                                <input type="emprole" formControlName="emprole" class="" name="emprole" id="emprole" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emprole.errors }" [(ngModel)]="employeementDetail.emprole">
                                <div *ngIf="submitted && f.emprole.errors" class="invalid-feedback">
                                    <div *ngIf="f.emprole.errors.required">Please enter role of employment </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Contract Type *</label>
                                <select style="width:100%;" formControlName="contracttypeid" name="contracttypeid" id="contracttypeid" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.contracttypeid.errors }" [(ngModel)]="employeementDetail.contracttypeid">
                                    <option [value]="defaultVal">Select Contract Type</option>
                                    <option *ngFor="let ctyp of contracttypelist" [value]="ctyp.id">{{ ctyp.contractname }}</option>
                                </select>
                                <div *ngIf="submitted && f.contracttypeid.errors" class="invalid-feedback">
                                    <div *ngIf="f.contracttypeid.errors.required">Please select contract type</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>Hours of Work Per Week *</label>
                                <input type="hoursofworks" formControlName="hoursofworks" class="" name="hoursofworks" id="hoursofworks" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.hoursofworks.errors }" [(ngModel)]="employeementDetail.hoursofworks">
                                <div *ngIf="submitted && f.hoursofworks.errors" class="invalid-feedback">
                                    <div *ngIf="f.hoursofworks.errors.required">Please enter hours of work per week </div>
                                    <div *ngIf="f.hoursofworks.errors.pattern">Hours should be numbers </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>Salary Per Annum (£) *</label>
                                <input type="salary" formControlName="salary" class="" name="salary" id="salary" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.salary.errors }" [(ngModel)]="employeementDetail.salary">
                                <div *ngIf="submitted && f.salary.errors" class="invalid-feedback">
                                    <div *ngIf="f.salary.errors.required">Please enter salary per annum</div>
                                    <div *ngIf="f.salary.errors.pattern">Salary should be numbers </div>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <label>{{empcontext}}</label>
                                <div class="input-group mb-3" (click)="file1.click()">
                                    <div class="custom-file">
                                        <input type="file" #file1 (change)="selectFile($event,'file1')">
                                        <label class="custom-file-label">{{filename1}}</label>
                                    </div>
                                </div>
                                <div *ngIf="filesconemerror == true" class="invalid-feedback">
                                    <div>Please select employment contract</div>
                                </div>


                                <!-- <div class="alert-danger" *ngIf="filesconemerror == true">Please Select Employment Contract</div> -->
                                <div class="alert-danger" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                <div class="alert-danger" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png, tif, gif, pdf, doc)
                                </div>
                            </div>


                            <div class="col-lg-4">
                                <label>{{emprwordtext}}</label>
                                <div class="input-group mb-3" (click)="file2.click()">
                                    <div class="custom-file">
                                        <input type="file" #file2 (change)="selectFile($event,'file2')">
                                        <label class="custom-file-label">{{filename2}}</label>
                                    </div>
                                </div>
                                <div *ngIf="filesworkemerror == true" class="invalid-feedback">
                                    <div>Please Select Right to work</div>
                                </div>
                                <!-- <div class="alert-danger" *ngIf="filesworkemerror == true">Please Select Right to work</div> -->
                                <div class="alert-danger" *ngIf="filesizeerror == true">File size must be less than 2 MB</div>
                                <div class="alert-danger" *ngIf="fileformaterror == true">Please choose a valid file format (jpg, png, tif, gif, pdf, doc)
                                </div>
                            </div>
                        </div>



                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>Start Date *</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker6" name="startdate" id="startdate" [ngClass]="{ 'is-invalid': submitted && f.startdate.errors }" formControlName="startdate" class="form-control" [(ngModel)]="employeementDetail.startdate">
                                    <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                                    <mat-datepicker #picker6></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && f.startdate.errors" class="invalid-feedback">
                                    <div *ngIf="f.startdate.errors.required">Please choose start date</div>
                                </div>
                            </div>

                            <div class="form-group col-lg-4">
                                <label>Is this the Current Employment?</label>
                                <select style="width:100%;" formControlName="iscurrent" name="iscurrent" id="iscurrent" (change)="currentemploymentchange($event)" class="form-control input-lg" [ngClass]="{ 'is-invalid': submitted && f.iscurrent.errors }" [(ngModel)]="employeementDetail.iscurrent">
                                    <option [value]="1">Yes</option>
                                    <option [value]="0">No</option>
                                </select>
                            </div>

                            <div class="form-group col-lg-4" *ngIf="iscurrentEmployment">
                                <label>Expiry Date *</label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker7" id="enddate" [ngClass]="{ 'is-invalid': submitted && f.enddate.errors }" name="enddate" formControlName="enddate" [min]="employmentForm.controls.startdate.value" class="form-control  datecontrol" [(ngModel)]="employeementDetail.enddate">
                                    <mat-datepicker-toggle matSuffix [for]="picker7"></mat-datepicker-toggle>
                                    <mat-datepicker #picker7></mat-datepicker>

                                </mat-form-field>
                                <div *ngIf="submitted && empenddateerror" class="invalid-feedback">
                                    <div *ngIf="empenddateerror">Please choose expiry date</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-4">
                                <label>Flexi Work *</label>
                                <select style="width:100%;" formControlName="flexiworkid" name="flexiworkid" id="flexiworkid" class="form-control input-lg" (change)="flexichange($event, employeementDetail.employeeworkingdetailsid)" [ngClass]="{ 'is-invalid': submitted && f.flexiworkid.errors }"
                                    [(ngModel)]="employeementDetail.flexiworkid">
                                    <option [value]="">-- Select --</option>
                                    <option [value]="1">Yes </option>
                                    <option [value]="0">No</option>
                                </select>
                                <div *ngIf="submitted && f.flexiworkid.errors" class="invalid-feedback">
                                    <div *ngIf="f.flexiworkid.errors.required">Please select flexi work</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4" *ngIf="currentvalue">
                                <label>Flexi work start date </label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker4" id="flexistartdate" [ngClass]="{ 'is-invalid': submitted && f.flexistartdate.errors }" name="flexistartdate" formControlName="flexistartdate" class="form-control" [(ngModel)]="employeementDetail.flexi.startdate"
                                        (focus)="focuser1()">
                                    <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                    <mat-datepicker #picker4></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="submitted && flexistartdateerror" class="invalid-feedback">
                                    <div *ngIf="flexistartdateerror">Please choose flexi work start date</div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4" *ngIf="currentvalue">
                                <label>Flexi work end date </label>
                                <mat-form-field class="datecontrol">
                                    <input matInput [matDatepicker]="picker5" id="flexienddate" [ngClass]="{ 'is-invalid': submitted && f.flexienddate.errors }" name="flexienddate" formControlName="flexienddate" class="form-control" [(ngModel)]="employeementDetail.flexi.enddate" (focus)="focuser2()">
                                    <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                                    <mat-datepicker #picker5></mat-datepicker>
                                </mat-form-field>
                                <!-- <div *ngIf="submitted && flexienddateerror" class="invalid-feedback">
                                    <div *ngIf="flexienddateerror">Please choose Flexi Work End Date</div>
                                </div> -->
                            </div>
                        </div>



                    </div>
                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary com_btn" name="updateform" [disabled]="!disableBtn" value="{{buttoninsert}}" />

                    <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel" (click)="closeModal('custom-employment-1');" />

                </div>
            </div>
        </div>
    </form>
</jw-modal>

<jw-modal id="pdf-image-viewer" class="modal" role="dialog">
    <div role="document" class="formgroup">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-image-viewer');">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row">

                        <div class="col-lg-12">
                            <iframe *ngIf="imgempsrc == false" [src]="iframeempsrc" width="100%" height="600" frameborder="0"></iframe>
                            <img *ngIf="imgempsrc == true" [src]="iframeempsrc" width="100%" />
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</jw-modal>