"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var forms_1 = require("@angular/forms");
var employee_model_1 = require("../../models/employee.model");
var country_service_1 = require("../../services/country.service");
var employee_service_1 = require("../../services/employee.service");
var employeement_service_1 = require("../../services/employeement.service");
var job_service_1 = require("../../services/job.service");
var asset_service_1 = require("../../services/asset.service");
var client_service_1 = require("../../services/client.service");
var excel_service_1 = require("../../services/excel.service");
var AssetreportComponent = /** @class */ (function () {
    function AssetreportComponent(excelService, formBuilder, asset, client, country, jobser, employee, employeementreport, datePipe) {
        this.excelService = excelService;
        this.formBuilder = formBuilder;
        this.asset = asset;
        this.client = client;
        this.country = country;
        this.jobser = jobser;
        this.employee = employee;
        this.employeementreport = employeementreport;
        this.datePipe = datePipe;
        this.employeeDetail = new employee_model_1.Employee();
        this.exceldata = [];
    }
    AssetreportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.EmployeeForm = this.formBuilder.group({
            emailid: [''],
            phonenumber: [''],
            ninumber: [''],
            minstartdate: [''],
            maxstartdate: [''],
            minenddate: [''],
            maxenddate: [''],
            categoryid: [''],
            serialnumber: [''],
            fname: [''],
            searcText: ['']
        });
        var values3 = {};
        this.asset.getassetreport(values3).subscribe(function (assetlist) {
            _this.record = assetlist.length;
            _this.data = assetlist;
            _this.record = _this.data.length;
            _this.datalist = _this.data;
            if (_this.record < 30) {
                _this.pageOfItems = _this.data;
            }
            else {
                _this.items = _this.data;
            }
        });
        this.Userid = localStorage.getItem('userid');
        var sstatustype = { 'status': true, 'id': this.Userid };
        this.asset.getassetcategory(sstatustype).subscribe(function (assetcategory) {
            _this.assetcategory = assetcategory;
        });
    };
    AssetreportComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    AssetreportComponent.prototype.exportAsXLSX = function () {
        var _this = this;
        this.exceldata = [];
        this.data.forEach(function (obj) {
            _this.employeeassetdata = {
                "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
                "Serial Number": obj.serialnumber,
                "Category Name": obj.categoryid.categoryname,
                "Allocation Date": _this.datePipe.transform(new Date(obj.statdate), "dd MMM yyyy"),
                "Submission Date": (obj.enddate) ? _this.datePipe.transform(new Date(obj.enddate), "dd MMM yyyy") : '',
                "Description": obj.assetdescption
            };
            _this.exceldata.push(_this.employeeassetdata);
        });
        this.excelService.exportAsExcelFile(this.exceldata, 'Asset_Report');
    };
    AssetreportComponent.prototype.onSubmit = function (value3) {
        var _this = this;
        this.asset.getassetreport(value3).subscribe(function (assetlist) {
            _this.record = assetlist.length;
            _this.data = assetlist;
        });
    };
    return AssetreportComponent;
}());
exports.AssetreportComponent = AssetreportComponent;
