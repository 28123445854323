<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
      <div class="container">
          <div class="tabs" id="tabs">
              <div class="tabs__wcontent">
                  <div class="tabs2__content__item active-tab">
                      <div class="content">                         
                          <div class="">
                            <div class="profile_box">
                                <div id="overview">
                                    <div class="overviewsshow">
                                        <div class="cseec">
                                            <div class="admin_com_logo">
                                                <!-- <div class="picture_chnge">                                                   
                                                    <input type="file" accept="image/*" style="display:none;" #file>
                                                    <a target="_blank"  class="cameraicon"><i class="fa fa-camera"></i></a>
                                                </div> -->
                                                <img src="../../../../../assets/img/user_pic_emp.png">
                                                <!-- <img alt="" [src]="overviewData.imagename"
                                                    *ngIf="overviewData.imagename !='' && overviewData.imagename !=null" />
                                                <img alt="" src="../../../assets/img/no-image-icon-hi.png"
                                                    *ngIf="overviewData.imagename =='' || overviewData.imagename == null" /> -->
                                            </div>
                                        </div>
                                        <div class="overview_details">
                                            <div class="alignbbb">
                                                <div class="headdd width100">
                                                    <h3 class="card-profile-name text-left">Ben Maxwell Santiago  &nbsp;
                                                        <span>ED10065</span>
                                                      </h3>                                                        
                                                    </div>
                                               
                                            </div>
                                            <hr class="mt-0" />
                                            <div class="profile_dtailsshow mb-3">
                                                <div class="detailsshow width25">
                                                    <span>Email</span>
                                                    <p>rahul21@mailinator.com</p>
                                                </div>
                                                <div class="detailsshow width20">
                                                    <span>Mobile</span>
                                                    <p> +919350283562 </p>
                                                </div>
                                                
                                                <div class="detailsshow width20">
                                                    <span>Gender</span>
                                                    <p>
                                                        <ng-container>
                                                            Male
                                                        </ng-container>                                                       
                                                    </p>
                                                </div>
                                                <div class="detailsshow width20">
                                                    <span>Hire Date</span>
                                                    <p>05 Mar 2019</p>
                                                </div>
                                                <div class="detailsshow width20 ">
                                                    <span>NI Number</span>
                                                    <p>5649851562</p>
                                                </div>                                            
                                            </div>
                                         
                                            <!-- <div class="alignbbb  mt-2">
                                                <h3 class="card-profile-name text-left">Permanent Address 
                                                   
                                                    </h3>
                                            </div>
                                            <hr class="mt-0" />
                                            <div class="profile_dtailsshow">
                                                <div class="detailsshow width100">
                                                    <p><i class="lnr lnr-map-marker"></i>A - 22585, Street Main, Near Medical Store, London, United kingdom, Antigua & Barbuda - 210011</p>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>                                
                                </div>
                            </div>
                            <div class="numaric_wizard">
                                <ul>
                                    <li class="active">
                                        <span>1</span>
                                    </li>
                                    <li>
                                      <span>2</span>
                                  </li>
                                  <li >
                                      <span>3</span>
                                  </li>
                                  <li>
                                      <span>4</span>
                                  </li>
                                  <li>
                                      <span>5</span>
                                  </li>
                                  <li>
                                      <span>6</span>
                                  </li>
                                  <li>
                                      <span>7</span>
                                  </li>
                                </ul>
                            </div>
                            <div class="details_box">
                              <div class="overviewsshow pb-4 pt-4">                                
                                <div class="row">
                                  <div class="col-lg-6">
                                    <h5 class="hedingcolor"> <a class="mainheading_btn ml-0" (click)="openModal2('custom-employee')"> + Add New </a></h5>                                                                   
                                  </div> 
                                  <div class="col-lg-6 text-right">
                                    <div class="form-inline d-flex justify-content-end">                                  
                                        <select class="form-control yearselect">
                                            <option>2018</option>
                                            <option>2019</option>
                                            <option>2020</option>
                                            <option>2021</option>
                                        </select>
                                    </div>                                
                                  </div> 
                                </div>
                             
                                <!-- <div class="row">
                                    <div class="col-sm-12">
                                      <div class="job_destails_secrtion">
                                          <div class="job_view_pre_nex">
                                            <button  class="btn btn-primary" type="submit">
                                              <i  aria-hidden="true" class="fa fa-arrow-left"></i>
                                              Previous Objective
                                            </button>
                                            <button class="btn btn-primary" type="submit">
                                              Next Objective
                                              <i aria-hidden="true" class="fa fa-arrow-right"></i>
                                            </button>
                                          </div>
                                      </div>
                                    </div>
                                  </div>                            -->
                                  <div class="row row-sm">
                                    <div class="col-lg-12">
                                      <h3 class="quarter hedingcolor">Objective List</h3>
                                      <div class="feedback_box"> 
                                          <div class="feedback">                                              
                                              <div class="feedback_details">
                                                  <div class="user_pic">
                                                      C
                                                  </div>
                                                <p class="details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                              </div>
                                             
                                              <div class="comment_box mt-4">
                                                  <div class="row">
                                                    <div class="col-sm-7">
                                                        <div class="form-group">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 1">
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 2">
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 3">
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 4">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-5">
                                                         <div class="form-group">
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Employee Comments" rows="8"></textarea>
                                                    
                                                    </div>
                                                  </div>
                                                  
                                                </div>                                                 
                                                  <!-- <div class="text-right">
                                                      <button type="submit" class="btn com_btn btn-primary"> Send</button>
                                                  </div> -->
                                              </div>
                                              <!-- <div class="name">
                                                  <div class="feedback_name">
                                                      <span class="text-warning">Manager</span>
                                                  </div>
                                                  <div class="write_name">
                                                      <span class="name_show">Chris M</span><span class="date_show">12/12/2021 12:00 PM</span>
                                                  </div>
                                                  
                                              </div> -->
                                          </div> 
                                          <div class="feedback">                                              
                                            <div class="feedback_details">
                                                <div class="user_pic">
                                                    C
                                                </div>
                                              <p class="details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                           
                                            <div class="comment_box mt-4">
                                                <div class="row">
                                                  <div class="col-sm-7">
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 1">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 2">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 3">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 4">
                                                      </div>
                                                  </div>
                                                  <div class="col-sm-5">
                                                       <div class="form-group">
                                                      <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Employee Comments" rows="8"></textarea>
                                                  
                                                  </div>
                                                </div>
                                                
                                              </div>                                                 
                                                <!-- <div class="text-right">
                                                    <button type="submit" class="btn com_btn btn-primary"> Send</button>
                                                </div> -->
                                            </div>
                                            <!-- <div class="name">
                                                <div class="feedback_name">
                                                    <span class="text-warning">Manager</span>
                                                </div>
                                                <div class="write_name">
                                                    <span class="name_show">Chris M</span><span class="date_show">12/12/2021 12:00 PM</span>
                                                </div>
                                                
                                            </div> -->
                                        </div>   
                                        <div class="feedback">                                              
                                            <div class="feedback_details">
                                                <div class="user_pic">
                                                    C
                                                </div>
                                              <p class="details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                           
                                            <div class="comment_box mt-4">
                                                <div class="row">
                                                  <div class="col-sm-7">
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 1">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 2">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 3">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 4">
                                                      </div>
                                                  </div>
                                                  <div class="col-sm-5">
                                                       <div class="form-group">
                                                      <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Employee Comments" rows="8"></textarea>
                                                  
                                                  </div>
                                                </div>
                                                
                                              </div>                                                 
                                                <!-- <div class="text-right">
                                                    <button type="submit" class="btn com_btn btn-primary"> Send</button>
                                                </div> -->
                                            </div>
                                            <!-- <div class="name">
                                                <div class="feedback_name">
                                                    <span class="text-warning">Manager</span>
                                                </div>
                                                <div class="write_name">
                                                    <span class="name_show">Chris M</span><span class="date_show">12/12/2021 12:00 PM</span>
                                                </div>
                                                
                                            </div> -->
                                        </div>   
                                        <div class="feedback">                                              
                                            <div class="feedback_details">
                                                <div class="user_pic">
                                                    C
                                                </div>
                                              <p class="details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                           
                                            <div class="comment_box mt-4">
                                                <div class="row">
                                                  <div class="col-sm-7">
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 1">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 2">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 3">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 4">
                                                      </div>
                                                  </div>
                                                  <div class="col-sm-5">
                                                       <div class="form-group">
                                                      <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Employee Comments" rows="8"></textarea>
                                                  
                                                  </div>
                                                </div>
                                                
                                              </div>                                                 
                                                <!-- <div class="text-right">
                                                    <button type="submit" class="btn com_btn btn-primary"> Send</button>
                                                </div> -->
                                            </div>
                                            <!-- <div class="name">
                                                <div class="feedback_name">
                                                    <span class="text-warning">Manager</span>
                                                </div>
                                                <div class="write_name">
                                                    <span class="name_show">Chris M</span><span class="date_show">12/12/2021 12:00 PM</span>
                                                </div>
                                                
                                            </div> -->
                                        </div>   
                                        <div class="feedback">                                              
                                            <div class="feedback_details">
                                                <div class="user_pic">
                                                    C
                                                </div>
                                              <p class="details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                           
                                            <div class="comment_box mt-4">
                                                <div class="row">
                                                  <div class="col-sm-7">
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 1">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 2">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 3">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 4">
                                                      </div>
                                                  </div>
                                                  <div class="col-sm-5">
                                                       <div class="form-group">
                                                      <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Employee Comments" rows="8"></textarea>
                                                  
                                                  </div>
                                                </div>
                                                
                                              </div>                                                 
                                                <!-- <div class="text-right">
                                                    <button type="submit" class="btn com_btn btn-primary"> Send</button>
                                                </div> -->
                                            </div>
                                            <!-- <div class="name">
                                                <div class="feedback_name">
                                                    <span class="text-warning">Manager</span>
                                                </div>
                                                <div class="write_name">
                                                    <span class="name_show">Chris M</span><span class="date_show">12/12/2021 12:00 PM</span>
                                                </div>
                                                
                                            </div> -->
                                        </div>   
                                        <div class="feedback">                                              
                                            <div class="feedback_details">
                                                <div class="user_pic">
                                                    C
                                                </div>
                                              <p class="details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                           
                                            <div class="comment_box mt-4">
                                                <div class="row">
                                                  <div class="col-sm-7">
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 1">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 2">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 3">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 4">
                                                      </div>
                                                  </div>
                                                  <div class="col-sm-5">
                                                       <div class="form-group">
                                                      <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Employee Comments" rows="8"></textarea>
                                                  
                                                  </div>
                                                </div>
                                                
                                              </div>                                                 
                                                <!-- <div class="text-right">
                                                    <button type="submit" class="btn com_btn btn-primary"> Send</button>
                                                </div> -->
                                            </div>
                                            <!-- <div class="name">
                                                <div class="feedback_name">
                                                    <span class="text-warning">Manager</span>
                                                </div>
                                                <div class="write_name">
                                                    <span class="name_show">Chris M</span><span class="date_show">12/12/2021 12:00 PM</span>
                                                </div>
                                                
                                            </div> -->
                                        </div>   
                                        <div class="feedback">                                              
                                            <div class="feedback_details">
                                                <div class="user_pic">
                                                    C
                                                </div>
                                              <p class="details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                           
                                            <div class="comment_box mt-4">
                                                <div class="row">
                                                  <div class="col-sm-7">
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 1">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 2">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 3">
                                                      </div>
                                                      <div class="form-group">
                                                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Quarter 4">
                                                      </div>
                                                  </div>
                                                  <div class="col-sm-5">
                                                       <div class="form-group">
                                                      <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Employee Comments" rows="8"></textarea>
                                                  
                                                  </div>
                                                </div>
                                                
                                              </div>                                                 
                                                <!-- <div class="text-right">
                                                    <button type="submit" class="btn com_btn btn-primary"> Send</button>
                                                </div> -->
                                            </div>
                                            <!-- <div class="name">
                                                <div class="feedback_name">
                                                    <span class="text-warning">Manager</span>
                                                </div>
                                                <div class="write_name">
                                                    <span class="name_show">Chris M</span><span class="date_show">12/12/2021 12:00 PM</span>
                                                </div>
                                                
                                            </div> -->
                                        </div>                                           
                                      </div>
                                      <hr/>
                                      <div class="button_prima text-right">
                                          <button type="submit" class="btn btn-success com_btn">Submit</button>
                                      </div>
                                    
                                    </div>  
                                                                
                                  </div>
                                <!-- <div class="row row-sm">
                                  <div class="col-lg-12">
                                                    <div class="job_list_section visa_request_section">
                                                    <div class="job_detils_date asslist width15">
                                                        <p class="job_profile">Serial Number</p>
                                                        <p class="job_address">#51652</p>
                                                    </div>
                                                    <div class="job_detils_date asslist width50">
                                                        <p class="job_profile">Objective</p>
                                                        <p class="job_address">Lorem Ipsum is simply dummy text </p>
                                                    </div>
                                                    <div class="job_detils_date asslist width10">
                                                        <p class="job_profile">Allocation Year</p>
                                                        <p class="job_address">2021</p>
                                                    </div>
                                                    <div class="job_detils_date visa_btn no-width text-right">
                                                        <div class="job_address">
                                                            <button class="btn btn-primary com_btn" (click)="openModal2('custom-employee')" type="submit">
                                                            <div class="tooltip_visa">
                                                                <p>Edit</p>
                                                            </div>
                                                            <i class="fa fa-pencil"></i>
                                                            </button>
                                                            <button class="btn btn-danger com_btn" (click)="openModal2('custom-employee')" type="submit">
                                                                <div class="tooltip_visa">
                                                                <p>Delete</p>
                                                                </div>
                                                                <i class="fa fa-trash"></i>
                                                            </button>
                                                            <button class="btn btn-warning com_btn" type="submit" [routerLink]="['/feedback']">
                                                                Feedback
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div class="job_list_section visa_request_section">
                                                    <div class="job_detils_date asslist width15">
                                                        <p class="job_profile">Serial Number</p>
                                                        <p class="job_address">#51652</p>
                                                    </div>
                                                    <div class="job_detils_date asslist width50">
                                                        <p class="job_profile">Objective</p>
                                                        <p class="job_address">Lorem Ipsum is simply dummy text </p>
                                                    </div>
                                                    <div class="job_detils_date asslist width10">
                                                        <p class="job_profile">Allocation Year</p>
                                                        <p class="job_address">2021</p>
                                                    </div>
                                                    <div class="job_detils_date visa_btn no-width text-right">
                                                        <div class="job_address">
                                                        <button class="btn btn-primary com_btn" (click)="openModal2('custom-employee')" type="submit">
                                                            <div class="tooltip_visa">
                                                            <p>Edit</p>
                                                            </div>
                                                            <i class="fa fa-pencil"></i>
                                                        </button>
                                                        <button class="btn btn-danger com_btn" (click)="openModal2('custom-employee')"type="submit">
                                                            <div class="tooltip_visa">
                                                            <p>Delete</p>
                                                            </div>
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                        <button class="btn btn-warning com_btn" type="submit" [routerLink]="['/feedback']">
                                                            Feedback
                                                        </button>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div class="job_list_section visa_request_section">
                                                <div class="job_detils_date asslist width15">
                                                    <p class="job_profile">Serial Number</p>
                                                    <p class="job_address">#51652</p>
                                                </div>
                                                <div class="job_detils_date asslist width50">
                                                    <p class="job_profile">Objective</p>
                                                    <p class="job_address">Lorem Ipsum is simply dummy text </p>
                                                </div>
                                                <div class="job_detils_date asslist width10">
                                                    <p class="job_profile">Allocation Year</p>
                                                    <p class="job_address">2021</p>
                                                </div>
                                                <div class="job_detils_date visa_btn no-width text-right">
                                                    <div class="job_address">
                                                        <button class="btn btn-primary com_btn" (click)="openModal2('custom-employee')" type="submit">
                                                        <div class="tooltip_visa">
                                                            <p>Edit</p>
                                                        </div>
                                                        <i class="fa fa-pencil"></i>
                                                        </button>
                                                        <button class="btn btn-danger com_btn" (click)="openModal2('custom-employee')"type="submit">
                                                            <div class="tooltip_visa">
                                                            <p>Delete</p>
                                                            </div>
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                        <button class="btn btn-warning com_btn" type="submit" [routerLink]="['/feedback']">
                                                            Feedback
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> 
                            
                                            <div class="job_list_section visa_request_section">
                                                <div class="job_detils_date asslist width15">
                                                    <p class="job_profile">Serial Number</p>
                                                    <p class="job_address">#51652</p>
                                                </div>
                                                <div class="job_detils_date asslist width50">
                                                    <p class="job_profile">Objective</p>
                                                    <p class="job_address">Lorem Ipsum is simply dummy text </p>
                                                </div>
                                                <div class="job_detils_date asslist width10">
                                                    <p class="job_profile">Allocation Year</p>
                                                    <p class="job_address">2021</p>
                                                </div>
                                                <div class="job_detils_date visa_btn no-width text-right">
                                                    <div class="job_address">
                                                    <button class="btn btn-primary com_btn" (click)="openModal2('custom-employee')" type="submit">
                                                        <div class="tooltip_visa">
                                                        <p>Edit</p>
                                                        </div>
                                                        <i class="fa fa-pencil"></i>
                                                    </button>
                                                    <button class="btn btn-danger com_btn" (click)="openModal2('custom-employee')"type="submit">
                                                        <div class="tooltip_visa">
                                                        <p>Delete</p>
                                                        </div>
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                    <button class="btn btn-warning com_btn" type="submit" [routerLink]="['/feedback']">
                                                        Feedback
                                                    </button>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div class="job_list_section visa_request_section">
                                            <div class="job_detils_date asslist width15">
                                                <p class="job_profile">Serial Number</p>
                                                <p class="job_address">#51652</p>
                                            </div>
                                            <div class="job_detils_date asslist width50">
                                                <p class="job_profile">Objective</p>
                                                <p class="job_address">Lorem Ipsum is simply dummy text </p>
                                            </div>
                                            <div class="job_detils_date asslist width10">
                                                <p class="job_profile">Allocation Year</p>
                                                <p class="job_address">2021</p>
                                            </div>
                                            <div class="job_detils_date visa_btn no-width text-right">
                                                <div class="job_address">
                                                    <button class="btn btn-primary com_btn" (click)="openModal2('custom-employee')" type="submit">
                                                    <div class="tooltip_visa">
                                                        <p>Edit</p>
                                                    </div>
                                                    <i class="fa fa-pencil"></i>
                                                    </button>
                                                    <button class="btn btn-danger com_btn" (click)="openModal2('custom-employee')"type="submit">
                                                        <div class="tooltip_visa">
                                                        <p>Delete</p>
                                                        </div>
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                    <button class="btn btn-warning com_btn" type="submit" [routerLink]="['/feedback']">
                                                        Feedback
                                                    </button>
                                                </div>
                                            </div>
                                        </div> 
                                        <div class="job_list_section visa_request_section">
                                            <div class="job_detils_date asslist width15">
                                                <p class="job_profile">Serial Number</p>
                                                <p class="job_address">#51652</p>
                                            </div>
                                            <div class="job_detils_date asslist width50">
                                                <p class="job_profile">Objective</p>
                                                <p class="job_address">Lorem Ipsum is simply dummy text </p>
                                            </div>
                                            <div class="job_detils_date asslist width10">
                                                <p class="job_profile">Allocation Year</p>
                                                <p class="job_address">2021</p>
                                            </div>
                                            <div class="job_detils_date visa_btn no-width text-right">
                                                <div class="job_address">
                                                <button class="btn btn-primary com_btn" (click)="openModal2('custom-employee')"type="submit">
                                                    <div class="tooltip_visa">
                                                    <p>Edit</p>
                                                    </div>
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                                <button class="btn btn-danger com_btn" (click)="openModal2('custom-employee')"type="submit">
                                                    <div class="tooltip_visa">
                                                    <p>Delete</p>
                                                    </div>
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                                <button class="btn btn-warning com_btn" type="submit" [routerLink]="['/feedback']">
                                                    Feedback
                                                </button>
                                                </div>
                                            </div>
                                        </div> 
                      
                                      <div class="job_list_section visa_request_section" *ngIf="record == 0">
                                          <div class="job_detils_date">
                                              <p class="job_profile">No Record Found</p>
                                          </div>
                                      </div>
                                  </div>  
                                  <div class="footerbtn text-right mr-3 mt-2 width100">
                                      <button type="submit" class="com_btn btn btn-success">Seal</button>
                                  </div>                               
                                </div> -->
                                <div class="row">
                                    
                                    <div class="col-lg-12 text-right"  *ngIf="record > 30">
                                      <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<jw-modal id="custom-employee" class="modal" role="dialog">
  <form [formGroup]="employeeForm" #overviewForm="ngForm" class="s12 white">
      <div role="document" class="formgroup">
          <div class="modal-content">             
              <div class="modal-body">
                  <div class="container">
                      <div class="row">                      
                          <div class="form-group col-lg-12">
                              <label>Objective*</label>
                              <textarea type="text" formControlName="empid" name="empid" id="empid" class="form-control"
                                  [ngClass]="{ 'is-invalid': submitted && f.empid.errors }"
                                ></textarea>
                              <div *ngIf="submitted && f.empid.errors" class="invalid-feedback">
                                  <div *ngIf="f.empid.errors.required">Please enter Objective</div>
                              </div>
                              <div *ngIf="empidexist" class="invalid-feedback">
                                  <div>This employee id already exists</div>
                              </div>
                          </div>
                          <div class="form-group col-lg-12">
                            <label>Allocation Year*</label>
                            <select style="width:100%;" name="jobid" formControlName="jobid"
                                    class="form-control input-lg" (change)="onSelectjob($event.target.value)" id="jobid"
                                    [ngClass]="{ 'is-invalid': submitted && f.nationalityid.errors }"
                                  >
                                    <option >Select Year</option>
                                    <option >2018</option>
                                    <option >2019</option>
                                    <option >2020</option>
                                    <option >2021</option>
                                </select>
                        </div>
                      </div>    
                  </div>
              </div>
              <div class="form-group modal-footer">
                  <input type="submit" class="btn btn-primary com_btn" name="updateform" value="Add Objective" />
                  <input type="button" class="btn btn-secondary com_btn" name="updateform" value="Cancel"
                      (click)="closeModal('custom-employee');" />
              </div>
          </div>
      </div>
  </form>
</jw-modal>