<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
      <div class="container">
          <div class="tabs" id="tabs">
            <a [routerLink]="['/ai-dashboard']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to AI Dashboard</a>
              <div class="clearfix"></div>
              <div class="tabs__content">
                  <div class="tabs__content__item active-tab">
                      <div class="content">
                          <div class="space_em row">
                              <div class="col-lg-6">
                                  <h5 class="hedingcolor">ID proof List
                                  </h5>
                              </div>
                              <div class="col-lg-3">
                                <select name="employee" #employid (change)="changeEmployee($event.target.value)" class="form-control input-lg">
        <option value="">Select Employee</option>
      <option *ngFor="let emp of employeelist" value="{{emp.employeemasterid}}" [selected]="emp.employeemasterid==empid">
        {{ emp.fname }} {{ emp.mname }} {{ emp.lname }} ( {{ emp.employeeid }})</option>
    </select>
                            </div>
                          </div>
                          <div class="job_list_section idproof_request_section visa_request_section pb-2" *ngFor="let idproofs of pageOfItems;">
                              <div class="job_detils_date">
                                <div class="visa_user_pic">
                                    <!-- <img src="{{employee.imagename}}" class="img width100"> -->
                                    <img *ngIf="idproofs.employee.imagename" src="{{idproofs.employee.imagename}}" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                    <img *ngIf="idproofs.employee.gender == true && (idproofs.employee.imagename == null || idproofs.employee.imagename =='') " src="../../../../../assets/img/user_pic_emp.png" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                    <img *ngIf="idproofs.employee.gender == false && (idproofs.employee.imagename == null || idproofs.employee.imagename =='')" src="../../../../../assets/img/fe.png" class="img width100" alt="{{ idproofs.employee.fname }} {{ idproofs.employee.mname }} {{ idproofs.employee.lname}}">
                                </div>
                              </div>
                              <div class="job_detils_date width22">
                                  <div class="visa_user_details">
                                      <h4>{{idproofs.employee.fname}} {{idproofs.employee.mname}} {{idproofs.employee.lname}}</h4>
                                      <p class="email"><i class="fa fa-envelope-o" aria-hidden="true"></i> {{ idproofs.employee.emailid }}
                                      </p>
                                      <p class="phone"><i class="fa fa-phone" aria-hidden="true"></i> {{ idproofs.employee.phonenumber }}</p>
                                      <p class="ninumber"><i class="fa fa-bars" aria-hidden="true"></i> NI Number {{ idproofs.employee.ninumber }} </p>
                                      <span *ngIf="idproofs.employee.nationality" class="badge badge-success">{{ idproofs.employee.nationality.countryname }} </span>
                                  </div>
                              </div>
                              <div class="job_detils_date width15">
                                  <p class="job_address">ID Proof Number</p>
                                  <p class="job_profile">{{idproofs.idnumber}}</p>
                              </div>
                              <div class="job_detils_date width20">
                                  <p class="job_address">ID Type</p>
                                  <p class="job_profile">{{idproofs.documenttype.documentname}}</p>
                              </div>
                              <div class="job_detils_date width20">
                                  <p class="job_address">End Date</p>
                                  <p class="job_profile">{{idproofs.docexpiry | date: 'dd MMM yyyy'}}</p>
                              </div>

                              <div class="job_detils_date visa_btn viewbtnsow mob">

                                  <p class="job_address">
                                      <button type="submit" class="btn btn-primary com_btn" (click)="openModal3('pdf-viewer-idproof',idproofs.iddocName)" *ngIf="idproofs.iddocName"><i class="fa fa-file-image-o"></i></button>
                                      <!-- <button type="submit" class="btn btn-danger com_btn" (click)="openModal('custom-idproof-1',idproofs)"><i class="fa fa-pencil"></i></button>
                                      <button type="submit" class="btn btn-secondary com_btn" (click)="onDelete(idproofs.employeeidproofdeatilid)"><i class="fa fa-trash-o"></i></button> -->
                                  </p>
                              </div>
                              <div class="width100">
                                  <hr class="mb-2 mt-2"/>
                              </div>
                              

                              <div class="width100">                                   
                                  <div class="pull-left">                                 
                                      <p class="badge badge-warning mb-0" *ngIf="idproofs.isaiverify == '1'"><i class="fa fa-hourglass-end"></i> Awaiting Authorisation </p>
                                      <p class="badge badge-success mb-0" *ngIf="idproofs.isaiverify == '2'"><i class="fa fa-check"></i> Approved by AI </p>
                                      <p class="badge badge-error mb-0" *ngIf="idproofs.isaiverify == '3'"><i class="fa fa-close"></i> Fradulent ID. Rejected by AI </p>
                                  </div>                                   
                                  <div class="pull-right">
                                      <p class="mb-0" *ngIf="aipayment ==false && idproofs.nationality.isid == true  && idproofs.isaiverify != '1'"> Verify the ID using Complygate AI.  <a  [routerLink]="['/idproofaidetails',idproofs.employeeidproofdeatilid]" >Click Here</a></p>                                        
                                      <p class="mb-0" *ngIf="aipayment ==true && idproofs.nationality.isid == true  && idproofs.isaiverify != '1'"> You have exhausted the free AI ID verification. To continue using it please  <a  [routerLink]="['/passportaidetails',idproofs.employeeidproofdeatilid]" >Click Here</a></p>                                          
                                      <p class="mb-0" *ngIf="idproofs.nationality.isid == false"> The AI verification functionality for this country will be available soon.</p>                                                                                                                                                                                            
                                  </div>
                              </div>
                          </div>

                          <div class="job_list_section visa_request_section" *ngIf="record == 0">
                            <div class="job_detils_date">
                                <p class="job_profile">No Record Found</p>
                            </div>
                        </div>
                          <div class="row">

                              <div class="col-lg-12 text-right">
                                  <jw-pagination [items]="items" [pageSize]="30" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>





<jw-modal id="pdf-viewer-idproof" class="modal" role="dialog">
  <div role="document" class="formgroup">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" name="close" aria-label="Close" (click)="closeModal('pdf-viewer-idproof');">
        <span aria-hidden="true">&times;</span>
      </button>
          </div>
          <div class="modal-body">
              <div class="container">

                  <div class="row">
                      <div class="col-lg-12">

                          <iframe *ngIf="imgsrc == false" [src]="iframesrc" width="100%" height="600" frameborder="0"></iframe>
                          <img *ngIf="imgsrc == true" [src]="iframesrc" width="100%" />
                      </div>
                  </div>

              </div>
          </div>

      </div>
  </div>
</jw-modal>