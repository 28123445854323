<div class="content">
  <app-topmenu class="row"></app-topmenu>
  <div class="row row-sm">
    <div class="col-lg-12">
      <form [formGroup]="EmployeeForm" #employeeForm="ngForm" class="s12 white"
        (ngSubmit)="onSubmit(employeeForm.value)">
        <div class="row row-sm">
          <div class="form-group col-lg-2">
            <label>First Name</label>
            <input type="input" formControlName="fname" class="" name="fname" id="fname" class="form-control" />
          </div>
          <div class="form-group col-lg-2">
            <label>Last Name</label>
            <input type="input" formControlName="lname" class="" name="lname" id="fname" class="form-control" />
          </div>
          <div class="form-group col-lg-2">
            <label>Email</label>
            <input type="input" formControlName="emailid" class="" name="emailid" id="fname" class="form-control" />
          </div>
          <div class="form-group col-lg-2">
            <label>Phone Number</label>
            <input type="input" formControlName="phonenumber" class="" name="phonenumber" id="phonenumber"
              class="form-control" />
          </div>
          <div class="form-group col-lg-2">
            <label>Job List</label>
            <!-- <ngx-select-dropdown ></ngx-select-dropdown> -->
            <select name="jobid" formControlName="jobid" class="form-control input-lg" id="jobid">
              <option value="">Select Job</option>
              <option *ngFor="let ad3 of joblist" value="{{ ad3.jobmasterid}}">{{ ad3.jobtittle }}</option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label>Status</label>
            <!-- <ngx-select-dropdown ></ngx-select-dropdown> -->
            <select name="cstatus" formControlName="cstatus" class="form-control input-lg" id="cstatus">
              <option value="">Select Status</option>
              <option *ngFor="let ad3 of statuslist" value="{{ ad3.candidatestatusmasterid}}">{{ ad3.statusname }}
              </option>
            </select>
          </div>
        </div>
        <div class="row row-sm">
          <div class="form-group col-lg-2">
            <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
            <input type="submit" class="btn btn-primary" name="updateform" value="Search" />
          </div>
        </div>
      </form>
    </div><!-- col-8 -->
  </div>
  <div class="row row-sm  mg-b-10">
    <div class="col-lg-12">
      <a (click)="exportAsXLSX()" class="float-right"><i class="fa fa-file-excel-o"
          style="font-size:30px;color:blue"></i></a>
    </div>
  </div>
  <div class="row row-sm">
    <div class="col-lg-12">
      <table class="table table-striped tbreport" [mfData]="data" #mf="mfDataTable" [mfRowsOnPage]="10">
        <thead>
          <tr>
            <th>
              <mfDefaultSorter by="id">ID</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="fname">Name</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="emailid">Email ID</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="phonenumber">Phone Number</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="job.jobtittle">Job Title</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="job.joburl1">Job Url</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="smarks">Marks</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="status.statusname">Marks</mfDefaultSorter>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let applicant of mf.data;  let i=index">
            <td>{{ i +1 }}</td>
            <td>
              {{applicant.fname}} {{applicant.mname}} {{applicant.lname}}
            </td>
            <td>
              <div class="tooltip5"> {{applicant.emailid}}
              </div>
            </td>
            <td>{{applicant.phonenumber}}</td>
            <td>{{applicant.job.jobtittle}}</td>
            <td>
              <span *ngIf="applicant.joburlid == '0'"> {{applicant.job.joburl1}} </span>
              <span *ngIf="applicant.joburlid == '1'"> {{applicant.job.joburl2}} </span>
              <span *ngIf="applicant.joburlid == '2'"> {{applicant.job.joburl3}} </span>
              <span *ngIf="applicant.joburlid == '3'"> {{applicant.job.joburl4}} </span>
            </td>
            <td>{{applicant.smarks}}</td>
            <td>{{applicant.status.statusname}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="8">
              <mfBootstrapPaginator [rowsOnPageSet]="[10,15,20]"></mfBootstrapPaginator>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>