<div class="bg">
  <app-header></app-header>

  <div class="section-wrapper p-0">
    <div class="admin_dashboard_show">
      <div class="ceee">
        <div class="cardmenu">
         
            <a class="card_box_lit"  [routerLink]="['/setting/leave-type-list']" >
              <div class="samebox">
                <i class="lnr lnr-history"></i>
                <div class="menu_content">
                    <h4>Leave Type</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
                
                
            </a>
         
         
            <a class="card_box_lit" [routerLink]="['/setting/hour-type-list']"  >
              <div class="samebox">
                <i class="lnr lnr-clock"></i>
                <div class="menu_content">
                    <h4>Hour Type</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>                             
            </a>
         
         
            <a class="card_box_lit" [routerLink]="['/setting/expense-type-list']">
              <div class="samebox">
                <i class="lnr lnr-gift"></i>
                <div class="menu_content">
                    <h4>Expense Type</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
            </a>
          
          
            <a class="card_box_lit" [routerLink]="['/setting/asset-category-list']">
              <div class="samebox">
                <i class="lnr lnr-frame-expand"></i>
                <div class="menu_content">
                    <h4>Asset Category</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>                
              </div>               
            </a>
         
          
            <a class="card_box_lit" [routerLink]="['/setting/department-type-list']">
              <div class="samebox">
                <i class="lnr lnr-apartment"></i>
                <div class="menu_content">
                    <h4>Department</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
            </a>
         
          
            <a class="card_box_lit" [routerLink]="['/setting/division-type-list']">
              <div class="samebox">
                <i class="lnr lnr-inbox"></i>
                <div class="menu_content">
                    <h4>Division</h4>
                    <!-- <span>lorem ipsum lorem ipsum</span> -->
                </div>
              </div>
            </a>
         
        </div>
      </div>
      <!-- <div class="row">

          <a  [routerLink]="['/setting/leave-type-list']"  class="homeset">
            <div class="dashboard-box">
            <h2 >Leave Type</h2>
              <p class="dash-circle" style="color:#fff;">
                <i class="fa fa-pencil" ></i>
              </p>
              <p class="dash-btm "><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
            
            </div></a> 

            <a  [routerLink]="['/setting/hour-type-list']" class="homeset">
          <div class="dashboard-box">
            <h2>Hour Type</h2>
            <p class="dash-circle"><i class="fa fa-user" ></i></p>  
            <p class="dash-btm">          
              <i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i>
            </p>
          </div>
          </a>
          <a  [routerLink]="['/setting/expense-type-list']"  class="homeset">
            <div class="dashboard-box">
              <h2>Expense Type</h2>
              <p class="dash-circle"><i class="fa fa-briefcase" ></i></p>
              <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
              </div>
            </a>
          

            <a  [routerLink]="['/setting/asset-category-list']"  class="homeset">
            <div class="dashboard-box">
                <h2>Asset Category</h2>
                <p class="dash-circle"><i class="fa fa-gbp"></i></p>
                <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
              </div>
              </a>
              <a  [routerLink]="['/setting/department-type-list']"  class="homeset">
              <div class="dashboard-box">
                  <h2>Department</h2>
                  <p class="dash-circle"><i class="fa fa-list-ul" ></i></p>
                  <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
                </div>
                </a>
                <a  [routerLink]="['/setting/division-type-list']"  class="homeset">
                  <div class="dashboard-box">
                    <h2>Division</h2>
                    <p class="dash-circle"><i class="fa fa-list-ul" ></i></p>
                    <p class="dash-btm"><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i><i class="fa fa-circle" ></i></p>
                    </div>
                  </a>
</div> -->
</div>
    <div class="container">
      <div class="tabs" id="tabs">
        <div class="tabs__sontent">
          <div class="tab2__wcontent__item active-tab" id="jan">
            <div class="content">  
      </div>
    </div>
  </div>
</div>
</div>
</div>