import { State } from '../address/state.model';
import { Country } from '../address/country.model';
import { Address } from '../address/address.model';
import { Companytype } from '../company/companytype.model';

export class Client { 
  address1: string;
  address2: string;
  cityname: string;
  emailid: string;
  companyname: string = "";
  clientid: any;
  phonenumber: string;
  sphonenumber: string;
  clientname: string;
  countryid: string = "";
  pincode: any;
  statename: string;
  logoname: string;
  companytypeid: any = "";
  companyid: any;
  websiteurl: string;
  clietdesignation: string;
  secondryemailid: string;
  Address: Address;
  country: Country;
  state: State;
  companytype: Companytype;
}