import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Employee } from '../../models/employee.model';
import { CountryService } from '../../services/country.service';
import { EmployeeService } from '../../services/employee.service';
import { CosService } from '../../services/cos.service';
import { ExcelService } from '../../services/excel.service';

@Component({
  selector: 'app-cosreport',
  templateUrl: './cosreport.component.html',
  styleUrls: ['./cosreport.component.css']
})

export class CosreportComponent implements OnInit {
  EmployeeForm: FormGroup;
  employeeDetail = new Employee();
  employeelist: any;
  countrylist: any;
  coslist: any;
  data: any;
  exceldata = [];
  employeecosdata: any;

  constructor(private excelService: ExcelService, private formBuilder: FormBuilder, private country: CountryService, private employee: EmployeeService, private cosreport: CosService) { }

  ngOnInit() {
    this.EmployeeForm = this.formBuilder.group({
      emailid: [''],
      phonenumber: [''],
      ninumber: [''],
      mincosstartdate: [''],
      maxcosstartdate: [''],
      mincosexpirydate: [''],
      maxcosexpirydate: [''],
      cosnumber: [''],
      iscurrent: ['']
    });
    var values3 = {};
    this.cosreport.getcoslist(values3).subscribe((cosdetails: any) => {
      this.data = cosdetails;
    });
  }

  exportAsXLSX(): void {
    this.exceldata = [];
    this.data.forEach(obj => {
      if (obj.iscurrent == false) {
        var current = "No";
      }
      else if (obj.iscurrent == true) {
        current = "Yes";
      }
      this.employeecosdata = {
        "Name": obj.employee.fname + " " + obj.employee.mname + " " + obj.employee.lname,
        "COS Number": obj.cosnumber,
        "Start Date": obj.cosstartdate,
        "End Date": obj.cosexpirydate,
        "Is Current": current
      };
      this.exceldata.push(this.employeecosdata);
    });
    this.excelService.exportAsExcelFile(this.exceldata, 'COS_Report');
  }
  
  onSubmit(value3: any) {
    this.cosreport.getcoslist(value3).subscribe((cosdetails: any) => {
      this.data = cosdetails;
    });
  }
}